/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kontaktperson, RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import type {FunctionType, SearchResultEntry} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktpersonen: '<',
        onKontaktSelected: '&',
    },
    template: require('./dvb-load-or-create-kontaktperson.html'),
    controllerAs: 'vm',
};

export class DvbLoadOrCreateKontaktperson implements angular.IController {
    public static $inject: readonly string[] = ['kontaktpersonService'];

    public kontaktpersonen!: Kontaktperson[];

    public onKontaktSelected!: FunctionType;

    public kontakt: RelationshipWithKontaktperson | null = null;

    public constructor(
        private readonly kontaktpersonService: KontaktpersonService,
    ) {
    }

    public loadKontaktFromExistingKontaktperson(kontaktpersonSearchItem: SearchResultEntry): void {
        this.fetchKontakt(kontaktpersonSearchItem)
            .then(relWithKontakt => this.notifyKontaktSelected(relWithKontakt));
    }

    public initNewKontakt(): void {
        const kontakt = new RelationshipWithKontaktperson();
        kontakt.kontaktperson = new Kontaktperson();
        kontakt.relationship.createSonstigerKontakt();
        this.notifyKontaktSelected(kontakt);
    }

    public notifyKontaktSelected(kontakt: RelationshipWithKontaktperson): void {
        this.kontakt = kontakt;

        if (TypeUtil.isFunction(this.onKontaktSelected)) {
            this.onKontaktSelected({kontakt});
        }
    }

    public fetchKontakt(kontaktpersonSearchItem: SearchResultEntry): angular.IPromise<RelationshipWithKontaktperson> {
        return this.kontaktpersonService.get(kontaktpersonSearchItem.id, {includes: '(adressen)'})
            .then(kontaktperson => {
                const kontakt = new RelationshipWithKontaktperson();
                kontakt.kontaktperson = kontaktperson;
                kontakt.kontaktpersonId = kontaktperson.id;

                return kontakt;
            });
    }
}

componentConfig.controller = DvbLoadOrCreateKontaktperson;

angular.module('kitAdmin').component('dvbLoadOrCreateKontaktperson', componentConfig);

