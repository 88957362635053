/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KitaKonfigurationTexte, Translation} from '@dv/kitadmin/models';
import {hasOwnPropertyGuarded, RechnungStatusText} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        form: '^form',
    },
    bindings: {
        texte: '<',
    },
    template: require('./dvb-kita-faktura-texte-form.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaTexteForm {
    public static $inject: readonly string[] = [];

    public texte!: { [index: string]: KitaKonfigurationTexte };
    public form!: angular.IFormController;

    public rechnungStatusText: typeof RechnungStatusText = RechnungStatusText;
    public statuses: string[] = Object.keys(RechnungStatusText);
    public availableStatuses: { [index: string]: boolean } = {};
    public showAdd: boolean = true;

    public $onInit(): void {
        if (!hasOwnPropertyGuarded(this.texte, RechnungStatusText.STANDARD)) {
            this.addTexte(RechnungStatusText.STANDARD);
        }
        // add all available statuses
        this.statuses.forEach(status => {
            this.availableStatuses[status] = !hasOwnPropertyGuarded(this.texte, status);
        });

        this.updateShowAdd();
    }

    public addTexte(status: string): void {
        if (!status) {
            return;
        }
        this.availableStatuses[status] = false;
        this.texte[status] = new KitaKonfigurationTexte(null, new Translation(), new Translation());

        this.updateShowAdd();
    }

    public removeTexte(status: string): void {
        this.availableStatuses[status] = true;
        delete this.texte[status];

        this.updateShowAdd();
    }

    private updateShowAdd(): void {
        this.showAdd =
            Object.keys(this.availableStatuses).map(status => this.availableStatuses[status]).some(state => state);
    }
}

componentConfig.controller = DvbKitaFakturaTexteForm;
angular.module('kitAdmin').component('dvbKitaFakturaTexteForm', componentConfig);
