/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {LimitedAdresse} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {checkPresent, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {MandantConfigurationService} from '../../../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        onSave: '&',
        onCancel: '&',
    },
    template: require('./dvb-betreuungsperson-umzug.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungspersonUmzug implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['mandantConfigurationService', 'errorService'];

    public onSave!: (data: { adresse: LimitedAdresse }) => angular.IPromise<any>;
    public onCancel!: FunctionType;

    public isLoading: boolean = false;
    public limitedAdresse: LimitedAdresse | null = null;

    public constructor(
        private mandantConfigurationService: MandantConfigurationService,
        private errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.mandantConfigurationService.createDefaultLimitedAdresse().then(limitedAdresse => {
            this.limitedAdresse = limitedAdresse;
        });
    }

    public submit(form: angular.IFormController): void {
        const limitedAdresse = checkPresent(this.limitedAdresse);
        const valid = limitedAdresse.adresse.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        if (!form.$valid || !valid || isPresent(form.$pending)) {
            return;
        }

        this.isLoading = true;

        this.onSave({adresse: limitedAdresse}).finally(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbBetreuungspersonUmzug;
angular.module('kitAdmin').component('dvbBetreuungspersonUmzug', componentConfig);
