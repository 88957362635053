/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExtraPlatz} from '@dv/kitadmin/models';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class ExtraPlatzService {
    public static $inject: readonly string[] = ['$http'];

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/extraplaetze`;

    public constructor(private $http: angular.IHttpService) {
    }

    public modifyExtraPlaetze(
        kindId: string,
        extraPlaetze: { newPlaetze: ExtraPlatz[]; deletedPlaetze: string[] },
        kindAbweichungsMeldungId?: string,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${ExtraPlatzService.baseUrl}/modify`;

        const newPlaetze = extraPlaetze.newPlaetze.map(platz => platz.toRestObject());

        return this.$http.post(url, {
                kindId,
                newPlaetze,
                deletedPlaetze: extraPlaetze.deletedPlaetze,
                kindAbweichungsMeldungId,
            },
        );
    }
}
