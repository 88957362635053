<div class="dvb-kita-faktura-rechnungen-status-filter">
    <ul class="filter-option-list list-inline" ng-class="{'hidden-print': !vm.statesRow0}">
        <li ng-repeat="status in vm.statesRow0" ng-class="{'hidden-print': !vm.filter.states[status]}">
            <button class="btn label label-default label-radio"
                    type="button"
                    data-ng-model="vm.filter.states[status]"
                    ng-click="vm.onUpdate()"
                    uib-btn-checkbox
                    data-translate="{{'FAKTURA.RECHNUNG_FILTER.RECHNUNG_FILTER_' + status}}">
            </button>
        </li>
    </ul>
    <ul class="filter-option-list list-inline" ng-class="{'hidden-print': !vm.statesRow1}">
        <li ng-repeat="status in vm.statesRow1" ng-class="{'hidden-print': !vm.filter.states[status]}">
            <button class="btn label label-default label-radio"
                    type="button"
                    data-ng-model="vm.filter.states[status]"
                    ng-click="vm.onUpdate()"
                    uib-btn-checkbox
                    data-translate="{{'FAKTURA.RECHNUNG_FILTER.RECHNUNG_FILTER_' + status}}">
            </button>
        </li>
    </ul>
</div>
