<div class="dvb-kita-offene-posten">

    <div class="filter-bar big-abstand-bottom">
        <div class="row">
            <div class="col-sm-6 col-md-3 small-abstand-top">
                <dvb-datepicker-text-field ng-model="vm.zahlungenGueltigBis"
                                           ng-change="vm.loadOffenePosten()"
                                           ng-attr-placeholder="{{'COMMON.ZAHLUNGEN_BIS' | translate}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-sm-6 col-md-3 small-abstand-top">
                <dvb-datepicker-text-field ng-model="vm.periodeGueltigBis"
                                           ng-change="vm.loadOffenePosten()"
                                           dvb-end-of-month-parser
                                           ng-attr-placeholder="{{'COMMON.LEISTUNGSPERIODE_BIS' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-7 big-abstand-bottom offene-posten" dvb-accordions>
            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading"></dvlib-spinner>

            <div ng-if="!vm.isLoading && vm.kitaOffenePosten.kontaktpersonOffenePosten.length === 0">
                <p data-translate="COMMON.KEINE_OFFENE_POSTEN_VORHANDEN"></p>
            </div>

            <dvb-accordion
                ng-repeat="kontaktpersonOffenePosten in vm.kitaOffenePosten.kontaktpersonOffenePosten | limitTo: vm.maxNumberPerPage : ((vm.currentPage - 1) * vm.maxNumberPerPage)"
                on-select="vm.onAccordionUpdate($index, selected)"
                is-first="$first">
                <panel-header class="full-width-block">
                    <div class="row">
                        <div class="col-xs-4">
                            <p ng-bind="kontaktpersonOffenePosten.kontaktperson.getDisplayName()"></p>
                        </div>
                        <div class="col-xs-5">
                            <p ng-if="vm.selectedPosten[$index]">
                                <a ui-sref="base.kontaktperson.offenePosten({kontaktpersonId: kontaktpersonOffenePosten.kontaktperson.id})"
                                   ng-click="$event.stopPropagation()"
                                   data-translate="KINDERORT.NAV_OFFENE_POSTEN_ANZEIGEN"></a>
                            </p>
                        </div>
                        <div class="col-xs-3">
                            <p class="text-right"
                               data-translate="COMMON.BETRAG_VALUE"
                               data-translate-value-betrag="{{kontaktpersonOffenePosten.offenePosten[0].saldo | number:'2'}}"></p>
                        </div>
                    </div>
                </panel-header>
                <panel-content>
                    <div class="row">
                        <dvb-kontaktperson-offene-posten-liste is-loading="vm.isLoading"
                                                               offene-posten="kontaktpersonOffenePosten.offenePosten">
                        </dvb-kontaktperson-offene-posten-liste>
                    </div>
                </panel-content>
            </dvb-accordion>

            <ul uib-pagination
                ng-if="!vm.isLoading && vm.kitaOffenePosten.kontaktpersonOffenePosten.length > vm.maxNumberPerPage"
                class="pagination-sm normal-abstand-top"
                total-items="vm.kitaOffenePosten.kontaktpersonOffenePosten.length"
                items-per-page="vm.maxNumberPerPage"
                max-size="7"
                boundary-links="true"
                force-ellipses="true"
                previous-text="&#xf104;"
                next-text="&#xf105;"
                first-text="&#xf048;"
                last-text="&#xf051;"
                ng-model="vm.currentPage"
                ng-change="vm.selectedPosten = []">
            </ul>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <h2 data-translate="KINDERORT.KITA_SALDO"></h2>

            <p ng-if="!vm.isLoading">
                <strong data-translate="COMMON.BETRAG_VALUE"
                        data-translate-value-betrag="{{vm.kitaOffenePosten.saldo | number:'2'}}">
                </strong>
            </p>
        </div>

    </div>
</div>
