<div class="dvb-anwesenheits-soll-verbrauch-progress small-abstand-bottom">

    <uib-progressbar max="vm.soll"
                     value="vm.verbrauch || 0"
                     type="success">
    </uib-progressbar>
    <div class="progress-legend">
        <strong data-translate="KIND.ANWESENHEITS_SOLL.VERBRAUCH"
           data-translate-value-ist="{{vm.verbrauch || 0}}"
           data-translate-value-soll="{{vm.soll}}"></strong>
        <strong ng-bind="vm.type"></strong>
    </div>

</div>
