/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, KinderOrt} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';

export class Zuweisung {

    public constructor(
        public readonly kinderOrte: Persisted<KinderOrt>[],
        public readonly kind: Persisted<Kind>,
    ) {
    }
}
