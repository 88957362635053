<div class="dvb-rechnung-restbetrag">
    <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>

    <div ng-if="vm.showRestbetrag()">

        <h2 data-translate="FAKTURA.AUSSTEHENDER_BETRAG" ng-if="vm.ausstehenderBetrag >= 0"></h2>
        <h2 data-translate="FAKTURA.UEBERSCHUESSIGER_BETRAG" ng-if="vm.ausstehenderBetrag < 0"></h2>

        <strong ng-if="vm.ausstehenderBetrag != null"
                data-translate="COMMON.BETRAG_VALUE"
                data-translate-value-betrag="{{vm.ausstehenderBetrag | abs | number: 2}}">
        </strong>

        <div dvb-auth
             require-permission="{{'kita:manage:' + vm.rechnung.kitaId}}"
             require-condition="vm.rechnung.status === 'UEBERTRAGEN' && vm.rechnung.dtype === 'DVB'">

            <h2 data-translate="COMMON.AKTIONEN" class="big-abstand-top"></h2>

            <div class="btn-group"
                 uib-dropdown
                 data-keyboard-nav
                 ng-if="vm.ausstehenderBetrag < 0">
                <button type="button"
                        class="btn btn-link btn-margin btn-link-dropdown dropdown-toggle"
                        uib-dropdown-toggle>
                    <span data-translate="FAKTURA.UEBERSCHUSS_AUSGLEICHEN"></span>
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li>
                        <a ui-sref="base.rechnung.zahlung-ausgleichen.vorauszahlung({ausstehenderBetrag: vm.ausstehenderBetrag})"
                           data-translate="COMMON.VORAUSZAHLUNG">
                        </a>
                    </li>
                    <li>
                        <a ui-sref="base.rechnung.zahlung-ausgleichen.rueckerstattung({ausstehenderBetrag: vm.ausstehenderBetrag})"
                           data-translate="COMMON.RUECKERSTATTUNG">
                        </a>
                    </li>
                    <li>
                        <a ui-sref="base.rechnung.zahlung-ausgleichen.rechnung({ausstehenderBetrag: vm.ausstehenderBetrag})"
                           data-translate="COMMON.RECHNUNG.SINGULAR">
                        </a>
                    </li>
                </ul>
            </div>

            <p>
                <a ui-sref="base.rechnung.zahlungen-erfassen({ betrag: vm.ausstehenderBetrag > 0 ? vm.ausstehenderBetrag : null })">
                    + <span data-translate="FAKTURA.ZAHLUNG_ERFASSEN"></span>
                </a>
            </p>

        </div>
    </div>
</div>
