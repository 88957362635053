/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {ChartData} from 'chart.js';
import type moment from 'moment';
import type {StyleVariableService} from '../../../../common/service/style-variable.service';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import {DataSet} from '../dvb-kita-chart/DataSet';
import type {ChartComponent} from '../service/ChartContext';
import {ChartContext} from '../service/ChartContext';
import {ChartUtil} from '../service/ChartUtil';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        controllingReport: '<',
        firstOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-chart-stunden.html'),
    controllerAs: 'vm',
};

export class DvbKitaChartStunden implements angular.IController, ChartComponent {
    public static $inject: readonly string[] = [
        'styleVariableService',
        '$translate',
        '$element',
        '$scope',
    ];

    private static readonly Y_AXIS_MARGIN_PCT = 0.1;

    public controllingReport!: ControllingReport;
    public firstOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public chart: Chart | null = null;
    public chartData?: ChartData = undefined;
    public maxValue: number = 0;
    public minValue: number = 0;

    public constructor(
        public styleVariableService: StyleVariableService,
        private $translate: angular.translate.ITranslateService,
        private $element: angular.IAugmentedJQuery,
        public $scope: angular.IScope,
    ) {
    }

    public $onInit(): void {
        this.populateDatasets();

        // timeout is used to avoid a timing issue, when displaying charts in tabs
        setTimeout(() => {
            this.initChart();
        });
    }

    private initChart(): void {
        const canvas = this.$element.find('#kita-chart-stunden')[0];
        const chartContext = new ChartContext(this, this.chartData!, canvas);

        const options = ChartUtil.createOptions(
            chartContext,
            this.styleVariableService,
            this.maxValue,
            this.minValue,
            this.$translate);

        this.chart = ChartUtil.createChart(options, chartContext);
    }

    private populateDatasets(): void {

        const soll = new DataSet(
            this.$translate.instant('COMMON.STUNDEN_CHART.SOLL_STUNDEN'),
            this.styleVariableService.getColorGuide());

        const effektiv = new DataSet(
            this.$translate.instant('COMMON.STUNDEN_CHART.EFFEKTIV_STUNDEN'),
            this.styleVariableService.getColorMainNormal());

        let maxValue = 1;
        let minValue = 0;
        const mondays = DvbDateUtil.createMondaysForYear(this.firstOfWeek.isoWeekYear());

        // Fill sets with values
        mondays.forEach(aMoment => {
            const monthlyFigures = DvbDateUtil.getEntityOn(this.controllingReport.monthlyFigures, aMoment);

            if (!monthlyFigures) {
                return;
            }

            soll.data.push({x: aMoment, y: monthlyFigures.sollStunden});
            effektiv.data.push({x: aMoment, y: monthlyFigures.effektivStunden});

            maxValue = Math.max(
                maxValue,
                monthlyFigures.sollStunden || 0,
                monthlyFigures.effektivStunden || 0);
            minValue = Math.min(
                minValue,
                monthlyFigures.sollStunden || 0,
                monthlyFigures.effektivStunden || 0);
        });

        this.chartData = {};
        this.chartData.datasets = [];

        const max = Math.round(maxValue + maxValue * DvbKitaChartStunden.Y_AXIS_MARGIN_PCT);
        this.maxValue = Math.ceil(max / 10) * 10;
        this.minValue = Math.round(Math.max(0, minValue - minValue * DvbKitaChartStunden.Y_AXIS_MARGIN_PCT));

        this.chartData.datasets.push(soll);
        this.chartData.datasets.push(effektiv);
    }
}

componentConfig.controller = DvbKitaChartStunden;
angular.module('kitAdmin').component('dvbKitaChartStunden', componentConfig);
