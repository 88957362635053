<div class="dvb-monatsblatt-stunden-kontingent">
    <p>
        <dvb-platz-typ-name kontingent-id="vm.stundenKontingent.stundenKontingent.kontingentId"></dvb-platz-typ-name>
        <br>
        <strong data-translate="FRAKTION.MONATSBLATT.PERIODE"
                data-translate-value-von="{{vm.stundenKontingent.stundenKontingent.gueltigAb | amDateFormat: 'DD.MM.YYYY'}}"
                data-translate-value-bis="{{vm.stundenKontingent.stundenKontingent.gueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
        </strong>
    </p>

    <uib-progressbar value="vm.progress" type="{{vm.type}}"></uib-progressbar>

    <div class="text-center small-abstand-top">
        <strong class="center-block"
                data-translate="FRAKTION.MONATSBLATT.VERBUCHTE_STUNDEN_ANZAHL"
                data-translate-value-verbucht="{{vm.availableStunden | roundTo:2 }}"
                data-translate-value-gesamt="{{vm.stundenKontingent.stundenKontingent.stunden | roundTo:2 }}">
        </strong>
        <span class="center-block" data-translate="FRAKTION.MONATSBLATT.STUNDEN_VERFUEGBAR"></span>
    </div>
</div>
