/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ParameterKinderOrte, ReportState} from '../../models';
import {ERR_VALIDATION, ParameterKinderOrteKontaktpersonen} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<',
    },
    template: require('./dvb-report-aktuelle-offene-posten.html'),
    controllerAs: 'vm',
};

export class DvbReportAktuelleOffenePosten implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public parameterKinderOrte?: ParameterKinderOrte;

    public parameterKinderOrteKontaktpersonen: ParameterKinderOrteKontaktpersonen =
        new ParameterKinderOrteKontaktpersonen();
    public zahlungenGueltigBis: moment.Moment | null = null;
    public periodeGueltigBis: moment.Moment | null = null;
    public abgewickeltePostenAnzeigen: boolean = false;
    public aktuelleOffenePostenReportForm?: angular.IFormController;
    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        angular.extend(this.parameterKinderOrteKontaktpersonen, this.parameterKinderOrte);
    }

    public updateState(): void {
        this.reportService.updateStateParams(this.parameterKinderOrteKontaktpersonen);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.aktuelleOffenePostenReportForm!.$valid
            && this.parameterKinderOrteKontaktpersonen.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        const params = {
            zahlungenGueltigBis: this.zahlungenGueltigBis!,
            periodeGueltigBis: this.periodeGueltigBis!,
            abgewickeltePostenAnzeigen: this.abgewickeltePostenAnzeigen,
            ignoreZahlungenForRechAfterPeriodeBis: true,
            reportTemplate: this.reportTemplate,
        };

        return this.reportService.getTempBlobForOffenePostenKitaReport(
            angular.copy(this.parameterKinderOrteKontaktpersonen),
            params,
        );
    }
}

componentConfig.controller = DvbReportAktuelleOffenePosten;
angular.module('kitAdmin').component('dvbReportAktuelleOffenePosten', componentConfig);
