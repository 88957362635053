/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {CustomFieldConfiguration} from '../models';

export class CustomFieldConfigurationService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/reporting/customfieldconfiguration`;

    public constructor(private $http: angular.IHttpService) {
    }

    public create(customFieldConfiguration: CustomFieldConfiguration): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(CustomFieldConfigurationService.BASE_URL, customFieldConfiguration.toRestObject());
    }

    public delete(customFieldConfiguration: CustomFieldConfiguration): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${CustomFieldConfigurationService.BASE_URL}/${
                encodeURIComponent(checkPresent(customFieldConfiguration.id))}`;

        return this.$http.delete(url);
    }

    public getAll(): angular.IPromise<CustomFieldConfiguration[]> {

        return DvbRestUtilAngularJS.getModelsArray(
                CustomFieldConfigurationService.BASE_URL,
                CustomFieldConfiguration,
                'items',
                {},
                true);
    }
}
