<div class="dvb-fixposition-erfassen row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2 data-translate="{{vm.headerKey}}"></h2>

        <form name="fixPositionErfassenForm"
              ng-submit="!fixPositionErfassenForm.$pending && vm.submit(fixPositionErfassenForm)"
              class="dvb-form"
              novalidate>
            <div class="form-section-minus-normal-abstand">

                <div class="row">
                    <div class="col-md-6">
                        <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                                      required
                                                      ng-model="vm.kitaSearchResultEntry"
                                                      ng-change="vm.setKitaId()"
                                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                                      as-col="true">
                        </dvb-search-get-single-entity>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.fixPosition.leistungsDatum"
                                                   required
                                                   ng-attr-placeholder="{{'COMMON.DATUM' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                    <div class="col-md-6">
                        <select uib-tooltip="{{'KIND.SELECT_LEISTUNGSART' | translate}}"
                                tooltip-placement="top-left"
                                class="form-control"
                                ng-options="'KIND.LEISTUNGSART.LEISTUNGSART_' + option | translate for option in vm.leistungsart"
                                ng-model="vm.fixPosition.leistungsArt"
                                required>
                            <option value=""
                                    class="hide"
                                    disabled
                                    selected
                                    data-translate="KIND.SELECT_LEISTUNGSART">
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <input type="text"
                               class="form-control"
                               maxlength="1000"
                               ng-attr-placeholder="{{'COMMON.BEZEICHNUNG' | translate}}"
                               uib-tooltip="{{'COMMON.BEZEICHNUNG' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.fixPosition.bezeichnung"
                               required>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <input name="anzahl"
                               ng-change="vm.updateTotalBetrag()"
                               ng-pattern="/^\d*(\.\d{0,3})?$/"
                               type="number"
                               class="form-control"
                               min="0.001"
                               ng-attr-placeholder="{{'COMMON.ANZAHL' | translate}}: 1"
                               uib-tooltip="{{'COMMON.ANZAHL' | translate}}: 1"
                               tooltip-placement="top-left"
                               ng-model="vm.fixPosition.anzahl">
                    </div>
                    <div class="col-md-6">
                        <input name="betrag"
                               ng-change="vm.updateTotalBetrag()"
                               type="number"
                               class="form-control"
                               ng-attr-placeholder="{{'COMMON.BETRAG_CHF' | translate}}"
                               uib-tooltip="{{'COMMON.BETRAG_CHF' | translate}}"
                               tooltip-placement="top-left"
                               dvb-currency-value
                               dvb-min-exclusive="0"
                               ng-model="vm.fixPosition.betragProEinheit"
                               required>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-md-offset-6 text-right">
                        <strong data-translate="COMMON.TOTAL_CHF"
                                data-translate-value-chf="{{vm.fixPosition.totalBetrag | number: 2}}">
                        </strong>
                    </div>
                </div>

                <div class="row normal-abstand-top">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <dvb-loading-button name="save"
                                                    type="submit"
                                                    is-loading="fixPositionErfassenForm.$pending || vm.isLoading">
                                    <span data-translate="{{vm.submitKey}}"></span>
                                </dvb-loading-button>
                            </li>
                            <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
