/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, Kontingente} from '@dv/kitadmin/models';
import {VertraglichesPensum} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import type {BetreuungsfaktorWithStichtag} from './BetreuungsfaktorWithStichtag';
import type {Zuweisung} from './Zuweisung';

/**
 * Model für die Zuweisung eines Kindes bei einem KinderOrt.
 */
export class KinderOrtZuweisung {
    public readonly kontingente: Kontingente[];
    public betreuungsfaktoren: BetreuungsfaktorWithStichtag[] = [];

    public constructor(
        public readonly kinderOrt: Persisted<KinderOrt>,
        public readonly zuweisung: Zuweisung,
    ) {
        this.kontingente = kinderOrt.getKontingente();
        this.vertraglichesPensumComparator = VertraglichesPensum.createComparator(this.kontingente);
    }

    public vertraglichesPensumComparator: (a: VertraglichesPensum, b: VertraglichesPensum) => number = () => 0;
}
