/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AnwesenheitCustomField} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

export class AnwesenheitCustomFieldService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/anwesenheitcustomfields`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getAll(config?: angular.IRequestShortcutConfig): angular.IPromise<AnwesenheitCustomField[]> {

        return DvbRestUtilAngularJS.getModelsArray(
            AnwesenheitCustomFieldService.BASE_URL,
            AnwesenheitCustomField,
            'anwesenheitCustomFields',
            {},
            !!config?.cache,
            config);
    }

    public update(
        customFields: AnwesenheitCustomField[],
        config?: angular.IRequestShortcutConfig,
    ): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const body = {
            anwesenheitCustomFields: customFields.map(customField => customField.toRestObject()),
        };

        return this.$http.post(AnwesenheitCustomFieldService.BASE_URL, body, config);
    }

    public createLinkedField(
        checkInCustomFieldId: EntityId,
        anwesenheitCustomFieldId: string | null,
        config?: angular.IRequestShortcutConfig,
    ): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const matrixParam = DvbRestUtil.encodeMatrixParams({anwesenheitCustomFieldId});

        return this.$http.post(`${AnwesenheitCustomFieldService.BASE_URL}/linked/${checkInCustomFieldId}${matrixParam}`,
            {}, config);
    }
}
