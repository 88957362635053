@if (state) {
    <dv-sort-list-dialog
        [state]="state"
        [templateRef]="item"
        (dialogClose)="closed.emit()">
    </dv-sort-list-dialog>

    <ng-template [dvlibSortListTemplate]="state.items" let-item #item>
        <span>{{ item.name }}</span>
    </ng-template>
}
