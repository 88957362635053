<div class="dvb-kita-kinder-aenderungen">
    <div class="panel panel-default panel-list-container big-abstand-top">
        <div class="panel-heading" data-translate="KINDERORT.AKTUELLE_AENDERUNGEN">
        </div>
        <div class="panel-body-footer" dvb-inside-scroll>
            <div class="panel-body">
                <ul class="panel-list">
                    <li class="list-item" ng-show="vm.pagination.isLoading && vm.pagination.items.length === 0">
                        <dvlib-spinner [show]="vm.pagination.isLoading"></dvlib-spinner>
                    </li>
                    <li class="list-item"
                        ng-if="!vm.pagination.isLoading && vm.pagination.items.length === 0"
                        data-translate="KINDERORT.KEINE_AENDERUNGEN_VORHANDEN"></li>
                    <li ng-repeat="change in vm.pagination.items">
                        <a ui-sref="base.kind.betreuung({kindId: change.kindId})" class="list-item">
                            <dvb-display-controlling-change change="change">
                            </dvb-display-controlling-change>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="panel-footer">
                <ul class="list-inline list-inline-row">
                    <li ng-if="vm.pagination.canShowMore">
                        <button class="btn btn-link btn-margin list-item"
                                type="button"
                                ng-click="vm.loadKinderAenderungen()">
                            <span data-translate="KINDERORT.MEHR_ANZEIGEN"></span>
                            <dvlib-spinner [show]="vm.pagination.loadingMore"></dvlib-spinner>
                        </button>
                    </li>
                    <li ng-if="vm.pagination.canShowLess">
                        <button class="btn btn-link btn-margin list-item"
                                type="button"
                                ng-click="vm.pagination.less()">
                            <span data-translate="KINDERORT.WENIGER_ANZEIGEN"></span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
