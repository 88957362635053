<div class="dvb-report-template-konfiguration dvb-form">

    <div class="row form-row">
        <div class="col-md-7">
            <h2 data-translate="REPORT.TEMPLATE.KONFIGURATION"></h2>

            <div class="row">
                <div class="col-md-12">
                    <select class="form-control"
                            ng-change="vm.loadTemplates(vm.report.key)"
                            ng-model="vm.report"
                            ng-options="report as (report.titleKey | translate) for report in vm.reports | orderBy:'titleKey | translate'"
                            tooltip-placement="top-left"
                            uib-tooltip="{{'KINDERORT.LAYOUT' | translate}}">
                    </select>
                </div>
            </div>

            <form class="section-template-upload"
                  ng-if="vm.report"
                  name="vm.submitTemplateForm"
                  ng-submit="vm.submit()"
                  novalidate>

                <div class="row">
                    <div class="col-md-12">
                        <input type="text"
                               name="templateName"
                               dvb-capitalize-first-letter
                               class="form-control"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               uib-tooltip="{{'COMMON.NAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.template.name"
                               required>
                    </div>
                </div>

                <p>
                    <dvb-loading-button as-link="true"
                                        spinner-right="true"
                                        is-loading="vm.ctxDefaultTemplate.isLoading"
                                        on-click="vm.downloadDefaultTemplate(vm.report.key)">
                        <span data-translate="REPORT.TEMPLATE.DEFAULT_HERUNTERLADEN"></span>
                    </dvb-loading-button>
                </p>

                <div class="row normal-abstand-top">
                    <div class="col-md-12">
                        <dvb-file-upload label-upload="REPORT.TEMPLATE.HOCHLADEN"
                                         ng-model="vm.tempBlob"
                                         required>
                        </dvb-file-upload>
                    </div>
                </div>

                <div class="row normal-abstand-top">
                    <div class="col-xs-12">
                           <textarea ng-model="vm.template.bemerkung"
                                     class="form-control form-section-minus-normal-abstand"
                                     msd-elastic
                                     ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                     uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                                     tooltip-placement="top-left"
                                     maxlength="5000"
                                     rows="6"></textarea>
                    </div>
                </div>

                <dvb-submit-cancel-buttons is-loading="vm.isLoading" on-cancel="vm.cancel()">
                </dvb-submit-cancel-buttons>
            </form>
        </div>

        <div class="col-md-4 col-md-offset-1" ng-if="vm.report">
            <h2 data-translate="REPORT.TEMPLATE.ERSTELLTE_KONFIGURATIONEN"></h2>
            <p ng-if="vm.erfassteReportTemplates.length === 0" data-translate="REPORT.TEMPLATE.KEINE"></p>
            <dvb-collapsible-panel ng-repeat="reportTemplate in vm.erfassteReportTemplates | orderBy: 'name'"
                                   ng-init="workingCopy = (reportTemplate | copy)"
                                   is-collapsed="true">
                <panel-header>
                    <p>
                        <dvb-report-template-outdated-icon report-state="vm.report" report-template="reportTemplate">
                        </dvb-report-template-outdated-icon>
                        <span ng-bind="reportTemplate.name"></span>
                    </p>
                </panel-header>
                <panel-content>
                    <div ng-if="!vm.editMode[reportTemplate.id]">
                        <p>
                            <dvb-report-template-outdated-icon report-state="vm.report"
                                                               report-template="reportTemplate">
                            </dvb-report-template-outdated-icon>
                            <span ng-bind="reportTemplate.name"></span>
                        </p>
                        <p class="nl2br" ng-bind="reportTemplate.bemerkung"></p>
                    </div>
                    <div ng-if="vm.editMode[reportTemplate.id]">
                        <input type="text"
                               name="templateName"
                               dvb-capitalize-first-letter
                               class="form-control"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               uib-tooltip="{{'COMMON.NAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="workingCopy.name"
                               required>
                        <textarea ng-model="workingCopy.bemerkung"
                                  class="form-control form-section-minus-normal-abstand"
                                  msd-elastic
                                  ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                  uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                                  tooltip-placement="top-left"
                                  maxlength="5000"
                                  rows="6">
                        </textarea>
                    </div>
                    <ul class="list-inline normal-abstand-top">
                        <li ng-if="!vm.editMode[reportTemplate.id]">
                            <button type="button"
                                    class="btn btn-link"
                                    ng-click="vm.editMode[reportTemplate.id] = true"
                                    data-translate="COMMON.BEARBEITEN">
                            </button>
                        </li>
                        <li ng-if="vm.editMode[reportTemplate.id]">
                            <dvb-loading-button type="button"
                                                is-loading="vm.ctxExistingTemplate.isLoading"
                                                on-click="vm.update(workingCopy)">
                                <span data-translate="COMMON.SPEICHERN"></span>
                            </dvb-loading-button>
                        </li>
                        <li ng-if="vm.editMode[reportTemplate.id]">
                            <button type="button"
                                    class="btn btn-link"
                                    ng-click="vm.editMode[reportTemplate.id] = false"
                                    data-translate="COMMON.ABBRECHEN">
                            </button>
                        </li>
                        <li ng-if="!vm.editMode[reportTemplate.id]">
                            <dvb-loading-button as-link="true"
                                                is-loading="vm.ctxExistingTemplate.isLoading"
                                                on-click="vm.downloadExistingTemplate(reportTemplate.id)">
                                <span data-translate="REPORT.TEMPLATE.HERUNTERLADEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li ng-if="!vm.editMode[reportTemplate.id]">
                            <button type="button"
                                    class="btn btn-link"
                                    ng-click="vm.deleteTemplate(reportTemplate)"
                                    data-translate="REPORT.TEMPLATE.LOESCHEN">
                            </button>
                        </li>
                    </ul>
                </panel-content>
            </dvb-collapsible-panel>
        </div>
    </div>
</div>
