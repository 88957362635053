<div class="dvb-transaktion-zuweisen-filter big-abstand-bottom">
    <div class="filter-bar normal-abstand-top normal-abstand-bottom">
        <div class="filter-list">
            <div class="filter">
                <ul class="filter-option-list">
                    <li>
                        <a ui-sref="^.vorgeschlagen({id: vm.bankStatementEntry.id})"
                           data-translate="FAKTURA.KONTENABGLEICH.VORGESCHLAGEN"
                           class="btn label label-default label-radio"
                           ui-sref-active="active">
                        </a>
                    </li>
                    <li>
                        <a ui-sref="^.alle({id: vm.bankStatementEntry.id})"
                           data-translate="COMMON.ALLE"
                           class="btn label label-default label-radio"
                           ui-sref-active="active">
                        </a>
                    </li>
                </ul>
            </div>
            <div class="normal-abstand-left">
                <dvb-period-selector ng-model="vm.filter.gueltigkeit"
                                     ng-change="vm.onUpdate()">
                </dvb-period-selector>
            </div>
            <div class="pull-right text-right">
                <dvb-filter-toggler is-collapsed="vm.collapsed"
                                    show-reset="vm.filter.isFiltering()"
                                    on-reset="vm.onReset()"
                                    on-toggle="vm.collapsed = !vm.collapsed">
                </dvb-filter-toggler>
            </div>
        </div>

        <div class="filter-list small-abstand-top">
            <div class="filter">
                <div data-translate="COMMON.FILTER_STATUS" class="filter-label"></div>
                <ul class="filter-list">
                    <li>
                        <dvb-kita-faktura-rechnungen-status-filter
                            filter="vm.filter"
                            on-update="vm.onUpdate()">
                        </dvb-kita-faktura-rechnungen-status-filter>
                    </li>
                </ul>
            </div>
        </div>

        <div class="filter-list small-abstand-top" ng-if="(vm.filter.kitas | filter:{sticky:true}).length > 0">
            <div class="filter">
                <div
                    data-translate="COMMON.KINDERORT.PLURAL"
                    class="filter-label">
                </div>
                <ul class="filter-option-list">
                    <li ng-repeat="filter in vm.filter.kitas | filter:{sticky:true} | orderBy: 'name'">
                        <dvb-filter-option label="filter.name"
                                           is-selected="filter.selected"
                                           closeable="true"
                                           on-close="vm.filterOptionHelper.onClose(filter)"
                                           on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                        </dvb-filter-option>
                    </li>
                </ul>
            </div>
        </div>

        <div class="filter-list small-abstand-top"
             ng-if="(vm.filter.kontaktpersonen | filter:{sticky:true}).length > 0">
            <div class="filter">
                <div
                    data-translate="COMMON.KONTAKTPERSONEN"
                    class="filter-label">
                </div>
                <ul class="filter-option-list">
                    <li ng-repeat="filter in vm.filter.kontaktpersonen | filter:{sticky:true} | orderBy: 'name'">
                        <dvb-filter-option label="filter.name"
                                           is-selected="filter.selected"
                                           closeable="true"
                                           on-close="vm.filterOptionHelper.remove(vm.filter.kontaktpersonen, filter)"
                                           on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                        </dvb-filter-option>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div uib-collapse="vm.collapsed">
        <div class="row">
            <div class="filter-list col-md-12">
                <div class="filter" ng-if="(vm.filter.kitas | filter:{sticky:false}).length > 0">
                    <div data-translate="COMMON.KINDERORT.PLURAL" class="filter-label"></div>
                    <ul class="filter-option-list">
                        <li ng-repeat="filter in vm.filter.kitas | filter:{sticky:false} | orderBy: 'name'">
                            <dvb-filter-option label="filter.name"
                                               is-selected="filter.selected"
                                               on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row small-abstand-top">
            <div class="col-md-4 col-md-offset-8">
                <dvb-search-on-click-entity add-label-key="COMMON.KONTAKTPERSON_AUSWAEHLEN"
                                            entity-to-search="KONTAKTPERSON"
                                            on-select="vm.filterOptionHelper.addFromSearchResult(vm.filter.kontaktpersonen, item)"
                                            on-select-clear="true"
                                            disabled-entries="vm.filter.kontaktpersonen"
                                            placeholder-key="COMMON.KONTAKTPERSON.SINGULAR">
                </dvb-search-on-click-entity>
            </div>
        </div>
    </div>
</div>
