/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StundenKontingent} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        stundenKontingent: '<',
    },
    template: require('./dvb-stunden-kontingent-title.html'),
    controllerAs: 'vm',
};

export class DvbStundenKontingentTitle implements angular.IController {
    public static $inject: readonly string[] = [];

    public stundenKontingent!: StundenKontingent;

    public today: moment.Moment = DvbDateUtil.today();
}

componentConfig.controller = DvbStundenKontingentTitle;
angular.module('kitAdmin').component('dvbStundenKontingentTitle', componentConfig);
