/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {Ausbildung} from '../../../anstellung/models/Ausbildung';
import type {AusbildungService} from '../../../anstellung/service/ausbildungService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        ausbildungen: '<',
    },
    template: require('./dvb-ausbildungen-main.html'),
    controllerAs: 'vm',
};

export class DvbAusbildungenMain implements angular.IController {

    public static $inject: readonly string[] = [
        'ausbildungService',
        'errorService',
        '$state',
    ];

    public ausbildungen: Ausbildung[] = [];

    public editMode: boolean = false;
    public isLoading: boolean = false;

    private deletedAusbildungen: string[] = [];

    public constructor(
        private readonly ausbildungService: AusbildungService,
        private readonly errorService: ErrorService,
        private readonly $state: StateService,
    ) {
    }

    public edit(): void {
        this.editMode = true;
    }

    public cancel(): void {
        this.$state.reload();
        this.editMode = false;
    }

    public delete(child: Ausbildung): void {
        DvbUtil.removeFromArray(child, this.ausbildungen);
        this.storeDeletedAusbildungIds(child.findChildIds());
    }

    public storeDeletedAusbildungIds(ausbildungIds: string[] | null): void {
        if (ausbildungIds === null) {
            return;
        }

        ausbildungIds.forEach(id => this.deletedAusbildungen.push(id));
    }

    public ausbildungHinzufuegen(): void {
        this.ausbildungen.push(new Ausbildung());
    }

    public initFirstAusbildung(): void {
        this.edit();
        this.ausbildungHinzufuegen();
    }

    public submit(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        const ausbildungList = this.ausbildungen.flatMap(aus => aus.flatten());
        const namesUnique = ausbildungList.length === DvbUtil.uniqueByMapper(ausbildungList, a => a.name).length;

        this.errorService.handleValidationError(namesUnique, 'PERSONAL.ERROR.DUPLICATE_AUSBILDUNGSNAME');
        if (!form.$valid || !namesUnique) {
            return;
        }

        this.isLoading = true;
        this.ausbildungService.saveAusbildungen(this.ausbildungen, this.deletedAusbildungen).then(() => {
            this.$state.reload();
            this.editMode = false;
        }).finally(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbAusbildungenMain;
angular.module('kitAdmin').component('dvbAusbildungenMain', componentConfig);
