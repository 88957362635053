/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {BringAbholZeitenAccess} from '../models/bring-abhol-zeiten/BringAbholZeitenAccess';
import type {BringAbholZeitenParams} from '../models/bring-abhol-zeiten/BringAbholZeitenParams';
import {BringAbholZeitenState} from '../models/bring-abhol-zeiten/BringAbholZeitenState';
import type {CreateBringAbholZeitenAccess} from '../models/bring-abhol-zeiten/CreateBringAbholZeitenAccess';

export class BringAbholZeitenService {
    public static $inject: readonly string[] = ['$http'];

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/communication/bring-abhol-zeiten`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public getBringAbholZeitenAccess(
        kindId: string,
        kinderOrtId: string,
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
    ): angular.IPromise<BringAbholZeitenAccess> {

        const url = `${BringAbholZeitenService.baseUrl
        }/${encodeURIComponent(kinderOrtId)}/${encodeURIComponent(kindId)}`;

        const matrixParams = {
            gueltigAb: DvbRestUtil.momentToLocalDate(gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(gueltigBis),
        };

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, BringAbholZeitenAccess, matrixParams)
            .then(checkPresent);
    }

    public getBringAbholZeitenManagement(
        kinderOrtId: string,
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
    ): angular.IPromise<BringAbholZeitenState> {

        const url = `${BringAbholZeitenService.baseUrl}/${encodeURIComponent(kinderOrtId)}`;

        const matrixParams = {
            includes: '(kontakte.fields(kontaktperson, relationship))',
            gueltigAb: DvbRestUtil.momentToLocalDate(gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(gueltigBis),
        };

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, BringAbholZeitenState, matrixParams)
            .then(checkPresent);
    }

    public createBringAbholZeitenAccess(params: CreateBringAbholZeitenAccess): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(BringAbholZeitenService.baseUrl, params.toRestObject());
    }

    public updateBringAbholZeitenAccess(params: CreateBringAbholZeitenAccess): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.put(BringAbholZeitenService.baseUrl, params.toRestObject());
    }

    public withdrawBringAbholZeitenAccess(params: BringAbholZeitenParams): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(`${BringAbholZeitenService.baseUrl}/withdraw`, params.toRestObject());
    }

    public confirmBringAbholZeiten(params: BringAbholZeitenParams): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(`${BringAbholZeitenService.baseUrl}/confirm`, params.toRestObject());
    }
}
