/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {BetreuungsZeitraum, checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {RestCache} from '@dv/kitadmin/models';

export class BetreuungsZeitraumService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/betreuungszeitraeume`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public get(betreuungsZeitraumId: string, params?: RestCache): angular.IPromise<Persisted<BetreuungsZeitraum>> {

        return DvbRestUtilAngularJS.getModelByIdAndParams(BetreuungsZeitraumService.BASE_URL,
            BetreuungsZeitraum,
            betreuungsZeitraumId,
            params)
            .then(checkPresent);
    }

    public update(betreuungsZeitraum: Persisted<BetreuungsZeitraum>): angular.IHttpPromise<void> {
        const url = `${BetreuungsZeitraumService.BASE_URL}/${encodeURIComponent(betreuungsZeitraum.id)}`;

        return this.$http.put(url, betreuungsZeitraum.toRestObject());
    }
}
