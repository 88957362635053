<div class="dvb-kita-faktura-header filter-bar">
    <ul class="list-inline normal-abstand-bottom">
        <li>
            <dvb-period-selector ng-model="vm.filter.gueltigkeit"
                                 ng-change="vm.onUpdate()">
            </dvb-period-selector>
        </li>
        <li class="pull-right text-right hidden-print" ng-if="vm.hasKitaRechnungskonfiguration">
            <p>
                <a href="" ng-click="vm.createRechnungsLaufPdf()" ng-disabled="vm.isDownloading">
                    <dvlib-spinner [show]="vm.isDownloading"></dvlib-spinner>
                    <span data-translate="KINDERORT.RECHNUNGSLAUF_PDF_ERSTELLEN"></span>
                </a>
            </p>
            <div class="btn-group floating-text" uib-dropdown data-keyboard-nav dvb-auth require-role="MANDANT_ADMIN">
                <dvb-loading-button type="button"
                                    is-loading="vm.isFakeEsrDownloading"
                                    dropdown="true"
                                    as-link="true"
                                    uib-dropdown-toggle>
                    <span data-translate="KINDERORT.FAKE_KONTOAUSZUG"></span>
                    <span class="caret"></span>
                </dvb-loading-button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li>
                        <a href="" ng-click="vm.createFakeEsrFile('ESR3')" ng-disabled="vm.isFakeEsrDownloading">
                            <span data-translate="KINDERORT.FAKE_ESR3_KONTOAUSZUG"></span>
                        </a>
                    </li>
                    <li>
                        <a href="" ng-click="vm.createFakeEsrFile('ESR4')" ng-disabled="vm.isFakeEsrDownloading">
                            <span data-translate="KINDERORT.FAKE_ESR4_KONTOAUSZUG"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    <ul class="filter-list"
        ng-class="{'hidden-print': vm.filter.getRequestedStates().length === 0 && !vm.filter.abgelaufen}">
        <li ng-class="{'hidden-print': vm.filter.getRequestedStates().length === 0}">
            <div data-translate="COMMON.FILTER_STATUS"
                 class="filter-label"
                 ng-class="{'hidden-print': vm.filter.getRequestedStates().length === 0}"></div>
            <dvb-kita-faktura-rechnungen-status-filter
                filter="vm.filter"
                on-update="vm.onUpdate()">
            </dvb-kita-faktura-rechnungen-status-filter>
        </li>
        <li ng-class="{'hidden-print': !vm.filter.abgelaufen}">
            <div data-translate="KINDERORT.FILTER_STATUS_FRIST"
                 class="filter-label"
                 ng-class="{'hidden-print': !vm.filter.abgelaufen}"></div>
            <dvb-kita-faktura-rechnungen-frist-filter
                filter="vm.filter"
                on-update="vm.onUpdate()">
            </dvb-kita-faktura-rechnungen-frist-filter>
        </li>
    </ul>
</div>
