/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeVerfuegung, Kind} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted, SearchResultEntry} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, switchMap, take} from 'rxjs';
import type {KindService} from '../../../../common/service/rest/kind/kindService';
import {VERFUEGUNG_OVERVIEW_STATE} from '../../../kibon-states';
import type {KibonVerfuegungenService} from '../../../service/kibonVerfuegungenService';
import type {
    KibonEntityMatchingDialogModel,
} from '../../kibon-entity-matching-dialog/kibon-entity-matching-dialog.component';
import {
    KibonEntityMatchingDialogComponent,
} from '../../kibon-entity-matching-dialog/kibon-entity-matching-dialog.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        verfuegung: '<',
    },
    template: require('./dvb-kibon-verfuegung-matching.html'),
    controllerAs: 'vm',
};

export class DvbKibonVerfuegungMatching implements angular.IController {
    public static $inject: readonly string[] = [
        'dialogService',
        'kindService',
        '$state',
        'kibonVerfuegungenService',
    ];

    public verfuegung!: KibonExchangeVerfuegung;

    public kindSearchResult: SearchResultEntry | null = null;

    public constructor(
        private dialogService: DialogService,
        private kindService: KindService,
        private $state: StateService,
        private verfuegungenService: KibonVerfuegungenService,
    ) {
    }

    public assignSearchResult(formValid: boolean): void {
        if (!formValid || !this.kindSearchResult) {
            return;
        }

        this.kindService.get(this.kindSearchResult.id, {
            includes: '(kontakte.fields(kontaktperson, relationship))',
        }).then(kind => this.assign(kind));
    }

    private assign(kind: Persisted<Kind>): void {
        const dialogModel: KibonEntityMatchingDialogModel = {
            entity: this.verfuegung,
            kind,
            header: 'KIBON.VERFUEGUNGEN.ASSIGN_CONFIRMATION_HEADER',
            entityDataHeader: 'KIBON.VERFUEGUNGEN.VERFUEGUNGS_DATEN',
            confirm: () => this.doAssign$(kind),
        };

        this.dialogService.openDialog(KibonEntityMatchingDialogComponent, dialogModel);
    }

    private doAssign$(kind: Persisted<Kind>): Observable<unknown> {
        return from(this.verfuegungenService.assignKibonVerfuegung(this.verfuegung.id, kind.id)).pipe(
            take(1),
            switchMap(() => this.$state.go(VERFUEGUNG_OVERVIEW_STATE.name, {}, {reload: true})));
    }
}

componentConfig.controller = DvbKibonVerfuegungMatching;
angular.module('kitAdmin').component('dvbKibonVerfuegungMatching', componentConfig);
