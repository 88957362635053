<div class="dvb-kapazitaet">
    <div class="action-header">
        <dvb-kapazitaet-header wochen-kapazitaet="vm.kapazitaetsAenderung.wochenKapazitaet"></dvb-kapazitaet-header>
        <div class="actions">
            <ng-transclude></ng-transclude>
        </div>
    </div>
    <div class="group-container">
        <div class="container-item" ng-class="{'normal-abstand-bottom':vm.showMaxPlaetze()}">
            <div class="description">
                <p data-translate="COMMON.KAPAZITAET_PLAETZE_WITH_VALUE"
                   data-translate-value-plaetze="{{vm.kapazitaetsAenderung.wochenKapazitaet.anzahlPlaetze}}">
                </p>
            </div>

            <dvb-wochenplan class="small"
                            distinct-betreuungs-zeitraeume="vm.wochenplan.distinctZeitraeume"
                            week-days="vm.enabledDays"
                            interactive="false"
                            zeitraum-felder="vm.kapazitaetsAenderung.zeitraumFelderPlaetze"
                            show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
            </dvb-wochenplan>
        </div>

        <div class="container-item" ng-if="vm.showMaxPlaetze()">
            <div class="description">
                <p data-translate="COMMON.KAPAZITAET_MAX_PLAETZE_WITH_VALUE"
                   data-translate-value-plaetze="{{vm.kapazitaetsAenderung.wochenKapazitaet.maxPlaetze}}">
                </p>
            </div>

            <dvb-wochenplan class="small"
                            distinct-betreuungs-zeitraeume="vm.wochenplan.distinctZeitraeume"
                            week-days="vm.enabledDays"
                            interactive="false"
                            zeitraum-felder="vm.kapazitaetsAenderung.zeitraumFelderMaxPlaetze"
                            show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
            </dvb-wochenplan>
        </div>
    </div>
</div>
