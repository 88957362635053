/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {AnwesenheitsZeitConstraint} from '@dv/kitadmin/models';
import type {AnwesenheitsZeitConstraintType} from '@dv/shared/backend/model/anwesenheits-zeit-constraint-type';
import {checkPresent, DayOfWeek, DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kinderort-anwesenheit-settings.html'),
    controllerAs: 'vm',
};

export class DvbKinderortAnwesenheitSettings implements angular.IController {
    public static $inject: readonly string[] = ['kinderOrtService'];

    private static readonly TIME_RANGE_SEPARATOR: string = '-';
    private static readonly SEPARATOR: string = ', ';

    public kinderOrt!: KinderOrt;

    public dayOfWeek: typeof DayOfWeek = DayOfWeek;

    public anwesenheitsZeitConstraintsByWeekDay: { [dayOfWeek: string]: { [type: string]: string } } = {};

    public constructor(
            private kinderOrtService: KinderOrtService,
    ) {
    }

    public $onChanges(): void {
        this.anwesenheitsZeitConstraintsByWeekDay = {};
        this.kinderOrt.anwesenheitsZeitConstraints.forEach(constraint => {
            if (!this.anwesenheitsZeitConstraintsByWeekDay[constraint.dayOfWeek]) {
                this.anwesenheitsZeitConstraintsByWeekDay[constraint.dayOfWeek] = {};
            }

            let value = this.anwesenheitsZeitConstraintsByWeekDay[constraint.dayOfWeek][constraint.type];
            value = value ? value + DvbKinderortAnwesenheitSettings.SEPARATOR : '';

            this.anwesenheitsZeitConstraintsByWeekDay[constraint.dayOfWeek][constraint.type] =
                    value +
                    DvbRestUtil.momentTolocaleHHMMTime(constraint.von)! +
                    DvbKinderortAnwesenheitSettings.TIME_RANGE_SEPARATOR +
                    DvbRestUtil.momentTolocaleHHMMTime(constraint.bis)!;
        });
    }

    public toDayOfWeekMoment(dayOfWeek: DayOfWeek): moment.Moment {
        return DvbDateUtil.getDayOfWeekMoment(dayOfWeek, moment());
    }

    public saveAnwesenheitsZeitSettings(): void {
        const newRanges: AnwesenheitsZeitConstraint[] = [];

        Object.keys(this.anwesenheitsZeitConstraintsByWeekDay).forEach(dayOfWeek => {
            Object.keys(this.anwesenheitsZeitConstraintsByWeekDay[dayOfWeek]).forEach(type => {

                const stringValue = this.anwesenheitsZeitConstraintsByWeekDay[dayOfWeek][type];
                stringValue.split(',')
                        .map(rangeString => rangeString.trim())
                        .forEach(rangeString => {

                            const rangeValues = rangeString.split(DvbKinderortAnwesenheitSettings.TIME_RANGE_SEPARATOR);
                            const from = DvbRestUtil.localeHHMMTimeToMoment(rangeValues[0]);
                            const to = DvbRestUtil.localeHHMMTimeToMoment(rangeValues[1]);

                            if (from && to) {
                                newRanges.push(new AnwesenheitsZeitConstraint(
                                        type as AnwesenheitsZeitConstraintType,
                                        dayOfWeek as DayOfWeek,
                                        DvbRestUtil.localeHHMMTimeToMoment(rangeValues[0]),
                                        DvbRestUtil.localeHHMMTimeToMoment(rangeValues[1])));
                            }
                        });
            });
        });

        const kinderOrtId = checkPresent(this.kinderOrt.id);
        this.kinderOrtService.updateAnwesenheitsZeitSettings(kinderOrtId, this.kinderOrt.maxDailyHours, newRanges);
    }

    public isValidAnwesenheitsZeit(param?: string | null): boolean {
        if (!param) {
            return true;
        }

        return param.split(',')
                .map(rangeString => rangeString.trim())
                .every(rangeString => {
                    const rangeValues = rangeString.split(DvbKinderortAnwesenheitSettings.TIME_RANGE_SEPARATOR);
                    const from = DvbRestUtil.localeHHMMTimeToMoment(rangeValues[0]);
                    const to = DvbRestUtil.localeHHMMTimeToMoment(rangeValues[1]);

                    return DvbDateUtil.isValidMoment(from) && DvbDateUtil.isValidMoment(to) && !from.isAfter(to);
                });
    }
}

componentConfig.controller = DvbKinderortAnwesenheitSettings;
angular.module('kitAdmin').component('dvbKinderortAnwesenheitSettings', componentConfig);
