/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum BedarfCssClasses {
    AGESTELLTE_DEFICIT = 'maximum-ueberschritten',
    AGESTELLTE_SURPLUS = 'contrast-alt',
}

export enum TimeLineCssBadgeClass {
    BEDARF_OK = 'badge-success',
    BEDARF_SURPLUS = 'badge-warning',
    BEDARF_DEFICIT = 'badge-danger',
    BEDARF_OVERWRITTEN = 'badge-neutral',
}
