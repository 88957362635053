<div class="dvb-anmeldung-kontakte">
    <div class="form-section" name="hauptkontakt">
        <div ng-if="vm.hauptkontakt === null">
            <h2 data-translate="KONTAKTPERSON.HAUPTKONTAKT"></h2>

            <dvb-load-or-create-kontaktperson on-kontakt-selected="vm.addKontaktAsHauptkontakt(kontakt)"
                                              kontaktpersonen="vm.kontaktpersonen">
            </dvb-load-or-create-kontaktperson>
        </div>

        <div ng-if="vm.hauptkontakt !== null">
            <dvb-kind-kontakt-erfassen
                kontakt="vm.hauptkontakt"
                uneditable-adresse="vm.uneditableAdresse"
                adress-datum="vm.adressDatum"
                show-rechnungsempfaenger-form="vm.showRechnungsAufteilungForm()"
                on-create-rechnungsempfaenger="vm.createRechnungsempfaenger(vm.hauptkontakt)"
                on-delete-rechnungsempfaenger="vm.deleteRechnungsempfaenger(vm.hauptkontakt)"
                on-rechnungsempfaenger-value-changed="vm.changeRechnungsempfaengerValue(vm.hauptkontakt)"
                on-remove-kontakt="vm.removeHauptkontakt()">
            </dvb-kind-kontakt-erfassen>
        </div>

        <p class="error-message"
           ng-show="vm.hauptkontakt === null"
           data-translate="ERRORS.ERR_REQUIRED_KONTAKTPERSON">
        </p>
    </div>

    <div class="form-section" ng-show="vm.hauptkontakt && vm.kontakte.length === 1">

        <h2 data-translate="COMMON.KONTAKT"></h2>

        <dvb-load-or-create-kontaktperson on-kontakt-selected="vm.addKontakt(kontakt)"
                                          kontaktpersonen="vm.kontaktpersonen">
        </dvb-load-or-create-kontaktperson>
    </div>

    <div class="form-section"
         ng-show="vm.hauptkontakt && vm.kontakte.length > 1"
         ng-repeat="kontakt in (nonHauptkontakte = (vm.kontakte | filter:vm.isNotHauptkontakt))">

        <dvb-kind-kontakt-erfassen
            kontakt="kontakt"
            uneditable-adresse="vm.uneditableAdresse"
            adress-datum="vm.adressDatum"
            show-rechnungsempfaenger-form="vm.showRechnungsAufteilungForm()"
            on-create-rechnungsempfaenger="vm.createRechnungsempfaenger(kontakt)"
            on-delete-rechnungsempfaenger="vm.deleteRechnungsempfaenger(kontakt)"
            on-rechnungsempfaenger-value-changed="vm.changeRechnungsempfaengerValue(kontakt)"
            on-set-as-hauptkontakt="vm.setAsHauptkontakt(kontakt)"
            on-remove-kontakt="vm.onRemoveKontakt({kontakt: kontakt})">
        </dvb-kind-kontakt-erfassen>

        <div class="normal-abstand-top" ng-if="$last">
            <dvb-load-or-create-kontaktperson on-kontakt-selected="vm.addKontakt(kontakt)"
                                              kontaktpersonen="vm.kontaktpersonen">
            </dvb-load-or-create-kontaktperson>
        </div>
    </div>
</div>
