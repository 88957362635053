<div class="dvb-report-external-anmeldungen">
    <h2 data-translate="REPORT.EXTERNAL_ANMELDUNGEN.TITLE"></h2>

    <form name="vm.externeAnmeldungenReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <div class="row form-row">
            <div class="col-xs-12">
                <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                           ng-change="vm.updateState()"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-xs-12">
                <dvb-datepicker-text-field ng-model="vm.gueltigBis"
                                           ng-change="vm.updateState()"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
