/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Termin} from '@dv/kitadmin/models';
import type {RestLimited} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

export class TerminService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/personal/termin`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public create(termin: Termin): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = TerminService.BASE_URL;

        return this.$http.post(url, termin.toRestObject());
    }

    public update(termin: Termin): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${TerminService.BASE_URL}/${encodeURIComponent(checkPresent(termin.id))}`;

        return this.$http.put(url, termin.toRestObject());
    }

    public delete(terminId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${TerminService.BASE_URL}/${encodeURIComponent(terminId)}`);
    }

    public getForAngestellte(angestellteId: string, params: RestLimited): angular.IPromise<Termin[]> {
        const url = `${TerminService.BASE_URL}/angestellte/${encodeURIComponent(angestellteId)}`;
        const matrixParams = DvbRestUtil.setGueltigkeitParams({}, params);

        return DvbRestUtilAngularJS.getModelsArray(url, Termin, 'items', matrixParams, false);
    }

    public getForKinderOrt(kinderOrtId: string, params: RestLimited): angular.IPromise<Termin[]> {
        const url = `${TerminService.BASE_URL}/kinderOrt/${encodeURIComponent(kinderOrtId)}`;
        const matrixParams = DvbRestUtil.setGueltigkeitParams({}, params);

        return DvbRestUtilAngularJS.getModelsArray(url, Termin, 'items', matrixParams, false);
    }
}
