<div class="dvb-kind-kita-zuweisen" ng-class="{'full-collapse': vm.isFullyCollapsible}">

    <h2 ng-if="vm.showKinderOrtName && vm.model.aktuelleGruppen.length > 0"
        ng-bind="vm.kinderOrt.getDisplayName()">
    </h2>

    <div dvb-auth
         require-permission="module:anwesenheit"
         require-condition="vm.model.aktuelleGruppen.length > 0"
         class="normal-abstand-bottom">
        <!-- Show simple link without Gruppen selection -->
        <div ng-if="vm.model.getGruppenWithSelectedZeitraumFeld().length === 1">
            <p>
                <a href=""
                   ng-click="vm.goToMonat({kinderOrtId: vm.kinderOrt.id, fraktionId: vm.model.getGruppenWithSelectedZeitraumFeld()[0]})">
                    <span data-translate="KIND.MONATSBELEGUNG.ANWESENHEIT_ERFASSEN"></span>
                </a>
            </p>
        </div>

        <!-- Show Gruppen selection -->
        <div ng-if="vm.model.getGruppenWithSelectedZeitraumFeld().length !== 1">
            <div class="btn-group"
                 uib-dropdown
                 data-keyboard-nav>
                <button type="button"
                        class="btn btn-link btn-link-dropdown dropdown-toggle floating-text"
                        uib-dropdown-toggle>
                    <span data-translate="KIND.MONATSBELEGUNG.ANWESENHEIT_ERFASSEN"></span>
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li ng-repeat="gruppe in vm.model.aktuelleGruppen">
                        <a href=""
                           ng-click="vm.goToMonat({kinderOrtId: vm.kinderOrt.id, fraktionId: gruppe.id})"
                           ng-bind="gruppe.getDisplayName()">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Gruppen -->
    <div ng-repeat="gruppe in vm.model.aktuelleGruppen"
         class="gruppen-wochen-belegung"
         ng-class="{'uncollapsed': vm.fullyCollapsedGroups[gruppe.id] === false}">

        <dvb-wochen-belegung name="{{gruppe.getDisplayName()}}"
                             gruppe="gruppe"
                             first-of-week="vm.model.zuweisungAb"
                             wochen-plaetze="vm.model.gruppenWochenBelegungByGruppe[gruppe.id].plaetze"
                             show-freie-plaetze="false"
                             show-belegte-plaetze="true"
                             interactive="true"
                             distinct-betreuungs-zeitraeume="gruppe.wochenplan.distinctZeitraeume"
                             week-days="vm.model.weekDays"
                             is-fully-collapsible="vm.isFullyCollapsible"
                             on-zeitraum-feld-clicked="vm.onZeitraumFeldClicked(zeitraumFeld, event, modifier, gruppe)"
                             on-collapse="vm.setFullyCollapsed(isCollapsed, gruppe.id)"
                             zeitraum-felder="vm.model.zeitraumFelderByGruppe[gruppe.id]">
        </dvb-wochen-belegung>

        <div ng-if="!vm.fullyCollapsedGroups[gruppe.id]">
            <dvb-zuweisen-zeiten fraktion="gruppe"
                                 week-days="vm.model.weekDays"
                                 anwesenheiten="vm.model.anwesenheitenByGruppe[gruppe.id]"
                                 zeitraum-felder="vm.model.zeitraumFelderByGruppe[gruppe.id]"
                                 anwesenheit-fix="vm.showAnwesenheiten">
            </dvb-zuweisen-zeiten>

            <dvb-vertragliches-pensum kontingente="vm.kontingente"
                                      vertragliche-pensen="vm.model.vertraglichePensenProGruppe[gruppe.id]">
            </dvb-vertragliches-pensum>

            <div ng-if="vm.model.bemerkungProGruppe[gruppe.id] !== undefined" class="normal-abstand-top">
                <textarea ng-model="vm.model.bemerkungProGruppe[gruppe.id]"
                          ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                          uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                          tooltip-placement="top-left"
                          class="form-control"
                          msd-elastic
                          maxlength="1000"
                          rows="1">
                </textarea>
            </div>
        </div>
    </div>

    <!-- Popover fuer das setzen einer neuen Belegung -->
    <dvb-zuweisen-popover kita="vm.kinderOrt"
                          zuweisung-ab="vm.model.zuweisungAb"
                          element-id="vm.kinderOrt.id"
                          set-subventioniert="vm.setSubventioniert(subventioniertesKontingent)"
                          set-privat="vm.setPrivat()"
                          set-firma="vm.setFirma(firmenKontingent)">
    </dvb-zuweisen-popover>
</div>
