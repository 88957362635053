<span class="dvb-rechnung-status"
      ng-if="vm.status.msgKey"
      uib-tooltip="{{vm.status.msgKey | translate}}"
      tooltip-placement="top-left">

    <span ng-if="vm.isLoading">
        <dvlib-spinner></dvlib-spinner>
    </span>

    <span ng-if="vm.status.icon && !vm.isLoading">
        <i ng-class="vm.status.icon"></i>
    </span>
</span>
