/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AngestellteService} from '@dv/shared/backend/api/angestellte.service';
import type {IDatasource} from 'ngx-ui-scroll';
import type {Settings} from 'vscroll/dist/typings/interfaces';
import {AngestellteKorrespondenz} from '../../../communication/models/AngestellteKorrespondenz';
import type {Angestellte} from '../models/Angestellte';

/**
 * DataSource for loading JaxAngestellteKorrespondenz for the ui-scroll directive.
 * Requires the start-index of ui-scroll to be set to 0.
 */
export class AngestellteKorrespondenzScrollDataSource implements IDatasource<AngestellteKorrespondenz> {

    public settings: Settings = {
        startIndex: 0,
        bufferSize: 20,
        padding: 1,
    };

    public constructor(
        private readonly angestellteService: AngestellteService,
        private readonly angestellte: Angestellte,
    ) {
    }

    public get = (
        index: number,
        count: number,
        success: (korrespondenz: AngestellteKorrespondenz[]) => any,
    ): void => {
        // Even with a ui-scroll start-index of 0, scrolling up will attempt to fetch records with negative index
        // --> ensure a positive index and adjust the count accordingly
        const realIndex = Math.max(0, index);
        const realCount = index < 0 ? index + count : count;

        if (realCount <= 0) {
            success([]);

            return;
        }

        const params = {
            top: realCount,
            skip: realIndex,
        };

        this.angestellteService.getKorrespondenz$({angestellteId: this.angestellte.id!, korrespondenz: params})
            .subscribe({
                next: korrespondenz => {
                    success(korrespondenz.items.map(item => AngestellteKorrespondenz.apiResponseTransformer(item)));
                    if (this.settings.maxIndex && korrespondenz.count) {
                        this.settings.maxIndex += korrespondenz.count;
                    } else {
                        this.settings.maxIndex = korrespondenz.count;
                    }
                },
            });

    };
}
