/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {DvbUtil} from '@dv/shared/code';
import type {FunctionType} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        icon: '@',
        labelTooltip: '@',
        isEnabled: '<',
        onDragSuccess: '&',
        onNewFileDragSuccess: '&',
    },
    template: require('./dvb-draggable-dropzone.html'),
    controllerAs: 'vm',
};

export class DvbDraggableDropzone implements angular.IController {
    public static $inject: readonly string[] = [
        '$element',
        '$scope',
        '$attrs',
    ];

    public onDragSuccess!: FunctionType;
    public onNewFileDragSuccess!: FunctionType;
    public isDisabled: boolean = false;
    public labelTooltip: string = '';
    private counter: number = 0;
    private classDropzone: string = '.dvb-draggable-dropzone';
    private classActive: string = 'active';

    public constructor(
        private $element: angular.IAugmentedJQuery,
        private $scope: angular.IScope,
        private $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
        this.$element.on('dragenter', (e: JQuery.DragEventBase) => {
            this.dragEnter(e);
        });
        this.$element.on('dragover', (e: JQuery.DragEventBase) => {
            this.dragOver(e);
        });
        this.$element.on('drop', (e: JQuery.DragEventBase) => {
            this.drop(e);
        });
        this.$element.on('dragleave', (e: JQuery.DragEventBase) => {
            this.dragLeave(e);
        });
    }

    public dragEnter(event: JQuery.DragEventBase): void {
        if (this.isDisabled) {
            return;
        }
        event.preventDefault();
        // highlight potential drop target when the draggable element enters it
        this.counter++;

        this.getDropzoneElement().addClass(this.classActive);
    }

    public dragOver(event: JQuery.DragEventBase): void {
        if (this.isDisabled) {
            return;
        }
        // prevent default to allow drop
        event.preventDefault();
    }

    public dragLeave(event: JQuery.DragEventBase): void {
        if (this.isDisabled) {
            return;
        }
        event.preventDefault();
        this.counter--;
        if (this.counter === 0) {
            this.getDropzoneElement().removeClass(this.classActive);
        }
    }

    public drop(event: JQuery.DragEventBase): void {
        if (this.isDisabled) {
            return;
        }
        event.preventDefault();

        this.getDropzoneElement().removeClass(this.classActive);
        this.counter = 0;
        const dataTransfer = event?.originalEvent?.dataTransfer;
        const data = dataTransfer?.getData('text');
        const files = dataTransfer?.items;
        if (data) {
            this.onDragSuccess({id: data});
        } else if (DvbUtil.isNotEmptyArray(files)) {
            // TODO: KIT-4218 Hochladen, falls Files in die Dropuone vom Desktop hochgeladen werden
            this.onNewFileDragSuccess({files});
        }

        // trigger Angular to pick up the modified item array and render the state
        this.$scope.$apply();
    }

    private getDropzoneElement(): angular.IAugmentedJQuery {
        return this.$element.find(this.classDropzone);
    }
}

componentConfig.controller = DvbDraggableDropzone;
angular.module('kitAdmin').component('dvbDraggableDropzone', componentConfig);
