/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Gebuehr, Kontaktperson, Rueckerstattung, Vorauszahlung} from '@dv/kitadmin/models';
import {
    checkGebuehr,
    checkRueckerstattung,
    checkVorauszahlung,
    createKontaktpersonZahlung,
    isGebuehr,
    KontaktpersonZahlungType,
} from '@dv/kitadmin/models';
import {GebuehrType} from '@dv/shared/backend/model/gebuehr-type';
import type {Persisted} from '@dv/shared/code';
import {
    checkPersisted,
    checkPresent,
    DvbDateUtil,
    DvbUtil,
    isPresent,
    LogFactory,
    SearchResultEntry,
} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {finalize, firstValueFrom, take} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {KontaktpersonenZahlungService} from '../../../common/service/rest/kontaktpersonen-zahlung.service';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const LOG = LogFactory.createLog('DvbKontaktpersonFakturaZahlung');

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
        gebuehrId: '<?',
        rechnungId: '<?',
        kitaName: '<?',
        editMode: '<?',
        dtype: '@',
    },
    template: require('./dvb-kontaktperson-faktura-zahlung.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonFakturaZahlung implements angular.IController {
    public static $inject = [
        'kontaktpersonService',
        '$state',
        'errorService',
        'dvbStateService',
        'kontaktpersonenZahlungService',
        '$translate',
    ];

    public kontaktperson!: Persisted<Kontaktperson>;
    public dtype!: KontaktpersonZahlungType;
    public gebuehrId!: string | null;
    public rechnungId!: string | null;
    public gebuehr: Persisted<Gebuehr> | null = null;
    public editMode!: boolean | null;
    public kitaName!: string | null;

    public isLoading: boolean = false;
    public kontaktpersonZahlung: Vorauszahlung | Rueckerstattung | Gebuehr | null = null;
    public kitaSearchResultEntry: SearchResultEntry | null = null;

    public gebuehrTypes: GebuehrType[] = Object.values(GebuehrType);
    public bezeichnungPlaceholder?: string;

    private readonly gebuehrTypeBezeichnung: Record<GebuehrType, string>;

    public constructor(
        private readonly kontaktpersonService: KontaktpersonService,
        private readonly $state: StateService,
        private readonly errorService: ErrorService,
        private readonly dvbStateService: DvbStateService,
        private readonly kontaktpersonenZahlungService: KontaktpersonenZahlungService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
        const entries: [GebuehrType, string][] = this.gebuehrTypes
            .map(type => [type, this.$translate.instant(`COMMON.GEBUEHR.${type}`)]);
        this.gebuehrTypeBezeichnung = Object.fromEntries(entries) as Record<GebuehrType, string>;
    }

    public $onInit(): void {
        this.onGebuehrTypeChange(this.gebuehrTypes[0]);
        if (this.gebuehrId) {
            this.initWithGebuehr(this.gebuehrId);
        } else {
            this.initKontaktpersonZahlung();
        }
    }

    public submit(form: angular.IFormController): void {
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_INCOMPLETE_FORM');
        const isValidBetrag = form.betrag?.$error &&
            !form.betrag.$error.required &&
            !form.betrag.$error.currencyValue &&
            !form.betrag.$error.dvbMinExclusive;
        this.errorService.handleValidationError(isValidBetrag, 'ERRORS.ERR_CHF');

        if (isValidBetrag && !form.$valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');
        }

        if (!form.$valid) {
            return;
        }

        this.isLoading = true;

        this.persist()
            .then(() => this.gebuehr?.isAssignedDepot() && isPresent(this.rechnungId) ?
                this.$state.go('base.rechnung.uebersicht', {rechnungId: this.rechnungId}) :
                this.$state.go('base.kontaktperson.offenePosten'))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public updateKita(): void {
        checkPresent(this.kontaktpersonZahlung).kitaId = this.kitaSearchResultEntry?.id ?? null;
    }

    public goBack(): angular.IPromise<unknown> {
        this.errorService.clearAll();

        return this.dvbStateService.goToPreviousState();
    }

    public onGebuehrTypeChange(gebuehrType?: GebuehrType | null): void {
        const type = gebuehrType ?
            this.gebuehrTypeBezeichnung[gebuehrType] :
            undefined;

        this.bezeichnungPlaceholder = type ?
            this.$translate.instant('COMMON.GEBUEHR.BEZEICHNUNG_TYPE', {type}) :
            this.$translate.instant('COMMON.BEZEICHNUNG');

        if (this.isDefaultBezeichnung(this.kontaktpersonZahlung)) {
            this.kontaktpersonZahlung.bezeichnung = null;
        }
    }

    private initKontaktpersonZahlung(gebuehr?: Gebuehr): void {
        this.kontaktpersonZahlung = createKontaktpersonZahlung(this.dtype);
        this.kontaktpersonZahlung.datum = DvbDateUtil.today();
        if (gebuehr) {
            this.kontaktpersonZahlung.betrag = gebuehr.betrag;
            this.kontaktpersonZahlung.kitaId = gebuehr.kitaId;
            if (isGebuehr(this.kontaktpersonZahlung)) {
                this.kontaktpersonZahlung.depotBereitsEingefordert = gebuehr.depotBereitsEingefordert;
                this.kontaktpersonZahlung.bezeichnung = gebuehr.bezeichnung;
                this.onGebuehrTypeChange(gebuehr.gebuehrType);
            }
        }
    }

    private initWithGebuehr(gebuehrId: string): void {
        this.isLoading = true;

        this.kontaktpersonenZahlungService.getGebuehr$(gebuehrId)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                    this.onGebuehrTypeChange(this.gebuehr?.gebuehrType);
                }),
            )
            .subscribe({
                    next: gebuehr => {
                        this.gebuehr = gebuehr;
                        if (this.gebuehr.kitaId && this.kitaName) {
                            this.kitaSearchResultEntry = new SearchResultEntry('KITA', this.gebuehr.kitaId, this.kitaName);
                        }
                        if (this.editMode) {
                            this.kontaktpersonZahlung = angular.copy(this.gebuehr);
                        } else if (this.dtype === KontaktpersonZahlungType.VORAUSZAHLUNG ||
                            this.dtype === KontaktpersonZahlungType.RUECKERSTATTUNG) {
                            this.initKontaktpersonZahlung(this.gebuehr);
                        }
                    },
                    error: err => LOG.error(`failed to get Gebuehr ${gebuehrId}`, err),
                },
            );
    }

    private persist(): angular.IPromise<unknown> {
        switch (this.dtype) {
            case KontaktpersonZahlungType.RUECKERSTATTUNG: {
                const rueckerstattung = checkRueckerstattung(this.kontaktpersonZahlung);
                rueckerstattung.gebuehrId = this.gebuehr?.id ?? null;

                return this.kontaktpersonService.createRueckerstattung(this.kontaktperson.id, rueckerstattung);
            }
            case KontaktpersonZahlungType.VORAUSZAHLUNG: {
                const vorauszahlung = checkVorauszahlung(this.kontaktpersonZahlung);
                vorauszahlung.gebuehrId = this.gebuehr?.id ?? null;

                return this.kontaktpersonService.createVorauszahlung(this.kontaktperson.id, vorauszahlung);
            }
            case KontaktpersonZahlungType.GEBUEHR: {
                const gebuehr = checkGebuehr(angular.copy(this.kontaktpersonZahlung));
                if (DvbUtil.isEmptyString(gebuehr.bezeichnung)) {
                    gebuehr.bezeichnung = checkPresent(this.gebuehrTypeBezeichnung[checkPresent(gebuehr.gebuehrType)]);
                }

                return this.editMode ?
                    // TODO: remove firstValueFrom when KontaktpersonService is migrated to Angular.
                    firstValueFrom(this.kontaktpersonenZahlungService.updateGebuehr$(checkPersisted(gebuehr))) :
                    this.kontaktpersonService.createKontaktpersonGebuehr(this.kontaktperson.id, gebuehr);
            }
            default:
                throw new Error(`Unsupported KontaktpersonZahlungType: ${String(this.dtype)}`);
        }
    }

    private isDefaultBezeichnung(gebuehr: unknown): gebuehr is Gebuehr {
        if (!isGebuehr(this.kontaktpersonZahlung) || !this.kontaktpersonZahlung.bezeichnung) {
            return false;
        }

        return Object.values(this.gebuehrTypeBezeichnung).includes(this.kontaktpersonZahlung.bezeichnung);
    }
}

componentConfig.controller = DvbKontaktpersonFakturaZahlung;
angular.module('kitAdmin').component('dvbKontaktpersonFakturaZahlung', componentConfig);
