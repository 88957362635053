<div class="dvb-kontenabgleich-main">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{'FAKTURA.KONTENABGLEICH.KONTENABGLEICH' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="FAKTURA.KONTENABGLEICH.KONTENABGLEICH">
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.kontenabgleich.transaktionen"
                           data-translate="FAKTURA.KONTENABGLEICH.NAV_TRANSAKTIONEN">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.kontenabgleich.kontoauszuege"
                           data-translate="FAKTURA.KONTENABGLEICH.NAV_KONTOAUSZUEGE">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
