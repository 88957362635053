
<div class="dvb-maintenance-delete-angestellte">
	<h2 data-translate="ADMINISTRATION.MTNC_DELETE_ANGESTELLTE"></h2>

	<form class="dvb-form"
		  name="deleteAngestellteForm"
		  ng-submit="vm.deleteAngestellte(deleteAngestellteForm)"
		  novalidate>
		<div class="row">
			<div class="col-xs-6">
				<dvb-search-list entity-to-search="ANGESTELLTE"
								 ng-model="vm.angestellteToDelete"
								 placeholder-key="PERSONAL.ANGESTELLTE.AUSWAEHLEN"
								 mandant-filter="vm.mandantFilter"
								 ng-required="vm.angestellteToDelete.size === 0">
				</dvb-search-list>
			</div>
		</div>

		<div class="selected" ng-repeat="angestellte in vm.angestellteToDelete">
			<div class="row">
				<div class="col-xs-3">
					<p ng-bind="angestellte.id"></p>
				</div>
				<div class="col-xs-3">
					<p ng-bind="angestellte.text"></p>
				</div>
			</div>
		</div>

		<dvb-loading-button type="submit" is-loading="vm.isLoading">
			<span data-translate="COMMON.LOESCHEN"></span>
		</dvb-loading-button>
	</form>
</div>
