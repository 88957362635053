/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        ausstehenderBetrag: '<',
    },
    template: require('./dvb-zahlung-ausgleichen-rechnung.html'),
    controllerAs: 'vm',
};

export class DvbZahlungAusgleichenRechnung implements angular.IController {

    public static $inject: readonly string[] = [
        'fakturaService',
        '$q',
    ];

    public rechnung!: Rechnung;
    public ausstehenderBetrag: number | null = null;

    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.TRANSAKTION;
    public rechnungen: Rechnung[] = [];
    public isLoading: boolean = true;

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        private readonly fakturaService: FakturaService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.loadRechnungen();
    }

    private loadRechnungen(): void {
        this.timeout = this.$q.defer();

        const config = {timeout: this.timeout.promise};
        const options = {
            id: checkPresent(this.rechnung.id),
            kontaktpersonId: checkPresent(this.rechnung.kontaktpersonId),
            kitaId: checkPresent(this.rechnung.kitaId),
        };

        this.fakturaService.getRechnungenToAssignZahlung(options, config).then(container => {
            this.rechnungen = container.items;

            this.isLoading = false;
        }).catch(err => {
            if (!DvbRestUtil.isRequestCancelled(err)) {
                this.isLoading = false;
            }
        });
    }
}

componentConfig.controller = DvbZahlungAusgleichenRechnung;
angular.module('kitAdmin').component('dvbZahlungAusgleichenRechnung', componentConfig);
