<div class="dvb-ausbildung-node">
    <div class="custom-node" ui-tree-handle>
        <label class="no-margin">
            <input type="text"
                   placeholder="{{'PERSONAL.AUSBILDUNGEN.SINGULAR' | translate}}"
                   dvb-focus-if="vm.ausbildung.id === null"
                   class="form-control no-margin input-sm"
                   ng-model="vm.ausbildung.name"
                   max-length="255"
                   required
                   data-nodrag>
        </label>
        <div class="pull-right">
            <button type="button" class="btn btn-link small-abstand-right floating-text" ng-click="vm.new()" data-nodrag>+</button>
            <button type="button"
                    class="btn btn-link floating-text"
                    ng-click="vm.onDelete()"
                    data-translate="COMMON.LOESCHEN"
                    data-nodrag>
            </button>
        </div>
    </div>
    <div ui-tree-nodes ng-model="vm.ausbildung.children">
        <dvb-ausbildung-node ui-tree-node
                             ng-repeat="child in vm.ausbildung.children"
                             ausbildung="child"
                             on-delete="vm.delete(child)"
                             on-deleted="vm.onDeleted({ausbildungIds})">
        </dvb-ausbildung-node>
    </div>
</div>
