/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import {StateConfiguration} from '@dv/shared/code';
import * as states from '../communication-states';

export class CommunicationState extends StateConfiguration<string> {

    public static readonly STATES: { [index: string]: Readonly<CommunicationState> } = {
        BRING_ABHOLZEITEN: CommunicationState.communicationFactory(
            'BRING_ABHOLZEITEN', states.BRING_ABHOLZEITEN, PERMISSION.MODULE.ANWESENHEIT),
        STEUERBESCHEINIGUNG: CommunicationState.communicationFactory(
            'STEUERBESCHEINIGUNG', states.STEUERBESCHEINIGUNG, PERMISSION.MODULE.FAKTURA),
        BULK_EMAILS: CommunicationState.communicationFactory(
            'BULK_EMAILS', states.BULK_EMAILS),
    };

    private static communicationFactory(key: string, state: string, permission?: string): Readonly<CommunicationState> {
        return new CommunicationState(
            'COMMUNICATION.',
            `${states.BASE_CONFIGURATION_STATE.name}.`,
            key,
            state,
            permission);
    }
}
