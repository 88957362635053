/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isNullish, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {DynamicZeitraumFeld} from '../../../personal/model/DynamicZeitraumFeld';
import type {DayOfWeek, IZeitraum} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        zeitraumFelder: '<',
        weekDays: '<',
        distinctZeitraeume: '<?',
        small: '<?',
    },
    template: require('./dvb-wochen-zeitplan.html'),
    controllerAs: 'vm',
};

export class DvbWochenZeitplan implements angular.IController {

    public static $inject: readonly string[] = [];

    // 18px for 2 lines of size tiny text + 3 px margin
    public static readonly ZEITRAUM_MIN_HEIGHT = 21;

    public zeitraumFelder: { [day: string]: DynamicZeitraumFeld[] } = {};
    public weekDays: DayOfWeek[] = [];
    public distinctZeitraeume: IZeitraum[] | null = null;
    public small: boolean = false;

    public planHeight: number | null = null;

    public $onChanges(_onChangesObj: angular.IOnChangesObject): void {

        if (isNullish(this.zeitraumFelder)) {
            return;
        }

        this.calcPlanHeight();
    }

    public printDayOfWeek(dayOfWeek: DayOfWeek): string {
        return DvbDateUtil.getDayOfWeekMoment(dayOfWeek, moment()).format('dd');
    }

    /**
     * Calculate the required plan height, with which the smalles selected zeitraumFeld has ZEITRAUM_MIN_HEIGHT.
     */
    private calcPlanHeight(): void {

        if (isNullish(this.distinctZeitraeume)) {
            this.distinctZeitraeume = Object.values(this.zeitraumFelder).flat().map(z => z.zeitraum);
        }

        const startTime = moment.min(this.distinctZeitraeume.map(zeitraum => zeitraum.von!));
        const endTime = moment.max(this.distinctZeitraeume.map(zeitraum => zeitraum.bis!));
        const totalDuration = DvbDateUtil.getTimeDiff(startTime, endTime);

        const minSelectedDuration = Math.min(...Object.values(this.zeitraumFelder).flat()
            .filter(feld => feld.selected)
            .map(feld => feld.zeitraum.durationInMinutes));

        const minuteHeight = DvbWochenZeitplan.ZEITRAUM_MIN_HEIGHT / (minSelectedDuration || 0);
        this.planHeight = minuteHeight * totalDuration;

        // add zeitraum height for week day header row
        this.planHeight += DvbWochenZeitplan.ZEITRAUM_MIN_HEIGHT;
    }
}

componentConfig.controller = DvbWochenZeitplan;
angular.module('kitAdmin').component('dvbWochenZeitplan', componentConfig);
