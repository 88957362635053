/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UploadTempBlob} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';

export class BlobUploadService {
    public static $inject: readonly string[] = ['Upload', '$http', '$q', '$window'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/blobs/temp`;

    public constructor(
        private uploadService: angular.angularFileUpload.IUploadService,
        private $http: angular.IHttpService,
        private $q: angular.IQService,
        private $window: angular.IWindowService,
    ) {
    }

    private static hasFileTooLargeError(response?: { args?: string }): boolean {
        return !!response && Array.isArray(response.args) && response.args.includes(
            'java.io.IOException: UT000020: Connection terminated as request was larger than ');
    }

    public uploadBlob(
        tempBlob: UploadTempBlob,
        resizeOptions?: angular.angularFileUpload.FileResizeOptions,
    ): angular.IPromise<UploadTempBlob> {
        const deferred = this.$q.defer<UploadTempBlob>();

        const maxProgress = 100;

        const promise = resizeOptions
            ? this.resizeImage(checkPresent(tempBlob.file), resizeOptions)
            : this.$q.resolve(tempBlob.file);

        promise.then(file => this.uploadService.upload<{ args: string }>({
            url: BlobUploadService.BASE_URL,
            method: 'POST',
            data: {
                file: this.uploadService.rename(checkPresent(file), encodeURIComponent(checkPresent(file).name)),
            },
        })).then(response => {
            // Die Response-Entity ist vom Typ Temp-Blob. Es wird jedoch nur die ID benoetigt
            tempBlob.id = (response.data as any).id;
            tempBlob.progress = maxProgress; // nur zur Sicherheit
            deferred.resolve(tempBlob);
        }, response => {
            tempBlob.progress = -1;
            tempBlob.errorMessage = BlobUploadService.hasFileTooLargeError(response) ?
                'ERRORS.ERR_FILE_TOO_LARGE' :
                'ERRORS.ERR_UPLOAD_FAILED';

            deferred.reject(tempBlob);
        }, evt => {
            const progressPercentage = evt.loaded * maxProgress / evt.total;
            tempBlob.filename = evt.config.data.file.name;
            tempBlob.mimeType = evt.config.data.file.type;
            tempBlob.progress = progressPercentage;

            deferred.notify(tempBlob);
        });

        return deferred.promise;
    }

    private resizeImage(
        file: File,
        resizeOptions: angular.angularFileUpload.FileResizeOptions,
    ): angular.IPromise<File> {
        const imageDefer = this.$q.defer<File>();
        const img = new this.$window.Image();

        img.onload = (): void => {
            if ((resizeOptions.width && img.width > resizeOptions.width) ??
                (resizeOptions.height && img.height > resizeOptions.height)) {
                // resizing
                imageDefer.resolve(this.uploadService.resize(file, resizeOptions));
            } else {
                // not resizing
                imageDefer.resolve(file);
            }
        };

        img.src = this.$window.URL.createObjectURL(file);

        return imageDefer.promise;
    }
}
