/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import {FirmenKontingentValue, Kita, Tarif} from '@dv/kitadmin/models';
import type {FunctionType, Persisted, SearchResultEntry} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitas: '<initKitas',
        tarif: '<initTarif',
        onSaveFirmenKontingentValue: '&',
        onResetFirmenKontingentValue: '&',
        titleTranslationKey: '@',
        plaetzePlaceholderTranslationKey: '@',
    },
    template: require('./dvb-create-firmen-kontingent-value.html'),
    controllerAs: 'vm',
};

export class DvbCreateFirmenKontingentValue implements angular.IController {

    public static $inject: readonly string[] = ['errorService'];

    public kitas!: Persisted<KinderOrt>[];
    public tarif!: Persisted<Tarif>;
    public onSaveFirmenKontingentValue?: (data: { firmenKontingentValue: FirmenKontingentValue }) => unknown;
    public onResetFirmenKontingentValue?: FunctionType;
    public titleTranslationKey!: string;
    public plaetzePlaceholderTranslationKey!: string;

    public firmenKontingentValue: FirmenKontingentValue = new FirmenKontingentValue();
    public isLoading: boolean = false;
    public tarifSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.firmenKontingentValue = new FirmenKontingentValue();
        this.kitas.forEach(kita => this.firmenKontingentValue.addKita(kita));
        this.firmenKontingentValue.setTarif(this.tarif);
    }

    public save(): void {
        const isValid = this.firmenKontingentValue.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.VALUE_REQUIRED');
        this.errorService.handleValidationError(this.isKitaSet(this.firmenKontingentValue), 'ERRORS.ERR_REQUIRED_KITA');
        if (!isValid || !this.isKitaSet(this.firmenKontingentValue)) {
            return;
        }
        if (TypeUtil.isFunction(this.onSaveFirmenKontingentValue)) {
            this.onSaveFirmenKontingentValue({firmenKontingentValue: this.firmenKontingentValue});
        }
    }

    public reset(): void {
        this.firmenKontingentValue.gueltigAb = null;
        this.$onInit();
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_REQUIRED_KITA');
        if (TypeUtil.isFunction(this.onResetFirmenKontingentValue)) {
            this.onResetFirmenKontingentValue();
        }
    }

    public updateTarif(): void {
        let tarif = null;
        if (this.tarifSearchResultEntry) {
            tarif = new Tarif();
            tarif.id = this.tarifSearchResultEntry.id;
            tarif.name = this.tarifSearchResultEntry.text;
        }
        this.firmenKontingentValue.setTarif(tarif);
    }

    public removeKita(firmenKontingentValue: FirmenKontingentValue, kita: KinderOrt): void {
        firmenKontingentValue.removeKita(kita);
    }

    public addKita(firmenKontingentValue: FirmenKontingentValue, kitaItem: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = kitaItem.text;
        kita.id = kitaItem.id;
        firmenKontingentValue.addKita(kita);
    }

    public isKitaSet(firmenKontingentValue: FirmenKontingentValue): boolean {
        return firmenKontingentValue.isKitaSet();
    }
}

componentConfig.controller = DvbCreateFirmenKontingentValue;
angular.module('kitAdmin').component('dvbCreateFirmenKontingentValue', componentConfig);
