/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifValue} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifId: '<',
        tarifValues: '<',
        atLeastOne: '<',
        onUpdate: '&',
        onDelete: '&',
        onRevert: '&',
    },
    template: require('./dvb-tarif-verlauf.html'),
    controllerAs: 'vm',
};

export class DvbTarifVerlauf implements angular.IController {

    public tarifId!: string;
    public tarifValues: TarifValue[] = [];
    public atLeastOne: boolean = false;
    public onUpdate!: (param: { tarifValue: TarifValue }) => unknown;
    public onDelete!: (param: { tarifValue: TarifValue }) => unknown;
    public onRevert!: (param: { tarifValue: TarifValue }) => unknown;

    public today = DvbDateUtil.today();

    public $onInit(): void {
        this.today = DvbDateUtil.today();
    }

    public isTarifValueBeendet(tarifValue: TarifValue): boolean {
        return !DvbDateUtil.isEndOfTime(tarifValue.gueltigBis);
    }

    public isDeleteAllowed(): boolean {
        return this.tarifValues.length > 1 || !this.atLeastOne;
    }
}

componentConfig.controller = DvbTarifVerlauf;
angular.module('kitAdmin').component('dvbTarifVerlauf', componentConfig);
