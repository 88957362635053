/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AuthEventType} from '@dv/shared/authentication/model';
import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import {EXPIRED_STATE} from '../../../authentication/authentication-states';
import type {AuthEventService} from '../../../authentication/service/auth-event.service';
import type {AuthService} from '../../../authentication/service/authService';
import {HAS_TOKEN_LOGIN_PARAM} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

export function attemptTokenLoginHook($transitions: TransitionService): void {
    $transitions.onBefore(HAS_TOKEN_LOGIN_PARAM, attemptTokenLogin, {priority: OnBeforePriorities.TOKEN_LOGIN});
}

function attemptTokenLogin(transition: Transition): HookResult {
    const authEventService = transition.injector().get<AuthEventService>('authEventService');
    const authService = transition.injector().get<AuthService>('authService');

    return Promise.resolve(authService.tokenLogin(transition.params().loginToken)
        .catch(err => {
            console.error('Token login failed', err);
            authEventService.sendEvent({type: AuthEventType.notAuthorised});

            // redirect to landing page
            return transition.router.stateService.target(EXPIRED_STATE.name, {
                toState: transition.to(),
                toParams: transition.targetState().params(),
            });
        }));
}
