/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode, RechnungsStatus} from '@dv/kitadmin/models';
import type {Persisted, RechnungsRevisionError} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnungen: '<',
        mode: '<?',
        preSelectedRechnungId: '<?',
        selection: '<?',
        errors: '<?',
        showVersandartBadge: '<?',
    },
    template: require('./dvb-rechnungen-list.html'),
    controllerAs: 'vm',
};

export class DvbRechnungenList implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'fakturaService',
    ];

    public rechnungen!: Persisted<Rechnung>[];
    public mode?: RechnungDisplayMode;
    public preSelectedRechnungId?: string;
    public selection?: { [rechnungId: string]: boolean };
    public errors: { [rechnungId: string]: RechnungsRevisionError[] } = {};
    public showVersandartBadge?: boolean;

    public refreshedStatuses: { [rechnungsRevisionId: string]: RechnungsStatus } = {};
    public freshStatusLoading: { [rechnungsRevisionId: string]: boolean } = {};

    public preSelectedFunction?: (rechnung: Rechnung) => boolean;

    public constructor(
        private readonly fakturaService: FakturaService,
    ) {
    }

    public $onInit(): void {
        this.mode ??= RechnungDisplayMode.modes.KINDERORT;

        this.preSelectedFunction = this.mode === RechnungDisplayMode.modes.REVISION ?
            this.preselectBasedOnRevisionId :
            this.preselectBasedOnRechnungId;
    }

    public refreshStatus(selected: boolean, rechnung: Persisted<Rechnung>): void {
        if (!selected || !rechnung || !this.mode?.refreshStatus) {
            return;
        }
        const rechnungsRevisionId = checkPresent(rechnung.rechnungsRevisionId);

        this.freshStatusLoading[rechnungsRevisionId] = true;
        const params = {idList: [rechnung.id]};

        this.fakturaService.getRechnungsStatus(params).then(statusResponse => {
            const status = statusResponse.statuses[rechnung.id];
            this.refreshedStatuses[rechnungsRevisionId] = RechnungsStatus.EXTERNAL_STATUS[status];
        }).finally(() => {
            this.freshStatusLoading[rechnungsRevisionId] = false;
        });
    }

    private preselectBasedOnRechnungId: (rechnung: Rechnung) => boolean = rechnung => {
        return this.preSelectedRechnungId === rechnung.id;
    };

    private preselectBasedOnRevisionId: (rechnung: Rechnung) => boolean = rechnung => {
        return this.preSelectedRechnungId === rechnung.rechnungsRevisionId;
    };
}

componentConfig.controller = DvbRechnungenList;
angular.module('kitAdmin').component('dvbRechnungenList', componentConfig);
