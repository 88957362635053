<div class="dvb-rechnung-versand-status">
    <p ng-if="vm.latestEmail && vm.latestEmail.emailVersandStatus === vm.emailVersandStatus.SUCCESS"
       class="small-abstand-bottom">
        <span data-translate="COMMON.EMAIL_AM"
              data-translate-value-datum="{{vm.latestEmail.timestampErstellt | amDateFormat:'D.M.YYYY HH:mm:ss'}}">
        </span>
        <i class="custom-icon custom-icon-zugewiesen smaller-abstand-left"
           uib-tooltip="{{vm.latestEmail.empfaengerAdresse}}"
           tooltip-placement="top-left">
        </i>
    </p>
    <p ng-if="!vm.latestEmail || vm.latestEmail.emailVersandStatus !== vm.emailVersandStatus.SUCCESS"
       class="small-abstand-bottom"
       data-translate="COMMON.VERSAND_PER"
       data-translate-value-versandart="{{'COMMON.VERSANDART_' + vm.rechnung.versandartRechnung | translate}}">
    </p>
    <button type="button"
            class="btn btn-link"
            dvb-auth
            require-permission="{{'kita:manage:' + vm.rechnung.kitaId}}"
            ng-click="vm.sendRechnungEmail()"
            data-translate="COMMON.RECHNUNG_PER_EMAIL_SENDEN">
    </button>
</div>
