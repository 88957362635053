/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import type {IPrincipal} from '@dv/shared/authentication/model';
import {Principal} from '@dv/shared/authentication/model';
import {JaxAuthAccessElement} from '@dv/shared/backend/model/jax-auth-access-element';
import {checkPresent} from '@dv/shared/code';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {CONFIG} from '../../../config';
import type {UserCredentials} from '../types/UserCredentials';

const API = `${CONFIG.restBackend}/api/v1/auth`;

@Injectable({
    providedIn: 'root',
})
export class AuthHttpService {

    public constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    public usernamePasswordLogin$(userCredentials: UserCredentials): Observable<IPrincipal> {
        const params: HttpParams = new HttpParams()
            .set('username', checkPresent(userCredentials.username))
            .set('password', checkPresent(userCredentials.password));

        return this.httpClient.post<JaxAuthAccessElement>(`${API}/login`, params).pipe(
            map(Principal.apiResponseTransformer),
        );
    }

    public tokenLogin$(token: string): Observable<IPrincipal> {
        const params: HttpParams = new HttpParams()
            .set('token', token);

        return this.httpClient.post<JaxAuthAccessElement>(`${API}/token-login`, params).pipe(
            map(Principal.apiResponseTransformer),
        );
    }

    public oidcLogin$(): Observable<IPrincipal> {
        return this.httpClient.get<JaxAuthAccessElement>(`${API}/oidc`).pipe(
            map(Principal.apiResponseTransformer),
        );
    }

    public logout$(): Observable<unknown> {
        return this.httpClient.post(`${API}/logout`, null);
    }
}
