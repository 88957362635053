/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IsoObject, Nullish} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {DvbIsoLocale} from '../../directive/dvb-iso-locale/dvb-iso-locale';
import TriggeredEvent = JQuery.TriggeredEvent;

type Scope = angular.IScope & { placeholder: string; labelText: string };

dvbClick2editIsoLocale.$inject = ['$compile', '$parse'];

function dvbClick2editIsoLocale($compile: angular.ICompileService, $parse: angular.IParseService): angular.IDirective {
    //noinspection UnnecessaryLocalVariableJS
    const directive = {
        restrict: 'AE',
        require: {
            dvbIsoLocale: 'dvbIsoLocale',
            ngModelCtrl: 'ngModel',
        },
        link,
    };

    return directive;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function link(scope: angular.IScope, element: JQuery, attrs: angular.IAttributes, ctrl: any): void {
        const dvbIsoLocale: DvbIsoLocale = ctrl.dvbIsoLocale;
        const ngModelCtrl: angular.INgModelController = ctrl.ngModelCtrl;

        ngModelCtrl.$formatters.push(modelValue => updateLastSavedModelValue(modelValue));
        ngModelCtrl.$parsers.push(value => updateLabelText(value));
        ngModelCtrl.$viewChangeListeners.push(() => submit());

        // Der Scope, den wir hier erhalten ist nicht der isolated-scope von dvbIsoLocale, sondern deren parent
        // scope. Wir erstellen uns deshalb einen eigenen scope fuer das zu generierende label.
        const dvbClick2editIsoLocaleScope: Scope = scope.$new(true) as unknown as Scope;
        dvbClick2editIsoLocaleScope.placeholder = '';
        dvbClick2editIsoLocaleScope.labelText = '';
        // since we create a new scope, we have to clean it up as well.
        //   -> destroy the new localScope, when the parent scope is destoyed.
        const offDestroy = scope.$on('$destroy', () => {
            dvbClick2editIsoLocaleScope.$destroy();
        });
        dvbClick2editIsoLocaleScope.$on('$destroy', offDestroy);

        attrs.$observe<string>('placeholder', newValue => {
            dvbClick2editIsoLocaleScope.placeholder = newValue ?? '';
        });

        const input = element.find('input');
        const label = $compile('<label class="form-control click2edit" ng-class="{ \'is-empty\': !labelText }">' +
            '<span ng-bind="labelText || placeholder"></span>' +
            '</label>')(dvbClick2editIsoLocaleScope);
        element.append(label);

        label.on('click', displayInput);
        input.on('keydown', handleKeyEvents);
        input.on('blur', submitWithEmptyValue);

        let isDisabled = false;
        attrs.$observe('disabled', value => {
            isDisabled = !!value;
        });

        let lastSavedModelValue: IsoObject | Nullish;
        const submitHandler = $parse(attrs.onSubmit);

        function displayInput(): void {
            if (isDisabled) {
                return;
            }

            if (angular.element('.dvb-click2edit-open').length === 0) {
                element.addClass('dvb-click2edit-open');
                input.show();
                input.trigger('focus');
                label.hide();
                element.find('ul').show();
            }
        }

        function displayLabel(): void {
            element.removeClass('dvb-click2edit-open');
            input.hide();
            label.show();
            element.find('ul').hide();
        }

        displayLabel();

        // eslint-disable-next-line complexity
        function submit(allowEmptyValue?: boolean): void {
            if (ngModelCtrl.$viewValue === null) {
                scope.$evalAsync(() => {
                    element.removeClass('has-error');
                });
            }

            const isValidIsoObject = dvbIsoLocale.isValidIsoObject(ngModelCtrl.$modelValue);
            const isValidModelValue = isValidIsoObject || ngModelCtrl.$modelValue === null && allowEmptyValue;

            if (isValidModelValue && isValid()) {
                if (TypeUtil.isFunction(submitHandler) && !angular.equals(lastSavedModelValue,
                    ngModelCtrl.$modelValue)) {

                    submitHandler(scope, {isoObject: ngModelCtrl.$modelValue});
                    lastSavedModelValue = ngModelCtrl.$modelValue;

                    if (allowEmptyValue && ngModelCtrl.$modelValue === null) {
                        dvbIsoLocale.backupModelValueFormatter(null);
                    }
                }
                removeErrorAndShowLabel();
            } else if (!input.is(':focus')) {
                element.addClass('has-error');
                input.trigger('focus');
                input.trigger('select');
            }
        }

        function removeErrorAndShowLabel(): void {
            scope.$evalAsync(() => {
                element.removeClass('has-error');
                displayLabel();
            });
        }

        function isValid(): boolean {
            let valid: unknown = true;

            if (TypeUtil.isFunction(attrs.isValid)) {
                valid = attrs.isValid({param: ngModelCtrl.$modelValue});
            }

            return typeof valid === 'boolean' ? valid : true;
        }

        function updateLastSavedModelValue(modelValue: IsoObject | Nullish): IsoObject | Nullish {
            updateLabelText(modelValue);
            if (dvbIsoLocale.isValidIsoObject(modelValue) || modelValue === null) {
                lastSavedModelValue = modelValue;
            }

            return modelValue;
        }

        function updateLabelText(isoObject: IsoObject | Nullish): IsoObject | Nullish {
            dvbClick2editIsoLocaleScope.labelText = dvbIsoLocale.isValidIsoObject(isoObject) ?
                isoObject.displayName :
                '';

            return isoObject;
        }

        function handleKeyEvents(event: TriggeredEvent): void {
            if (event.key === 'Enter') {
                input.trigger('blur');
            }
            if (event.key === 'Escape' && lastSavedModelValue === null) {
                input.trigger('blur');
            }
        }

        function submitWithEmptyValue(): void {
            submit(true);
        }
    }
}

angular.module('kitAdmin').directive('dvbClick2editIsoLocale', dvbClick2editIsoLocale);
