<div class="dvb-kinderkontaktliste-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.kinderkontaktlisteReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-parameter-kinderkontaktliste default-stichtag="vm.defaultStichtag"
                                          parameter-kinderkontaktliste="vm.parameterKinderkontaktliste"
                                          on-change="vm.updateState()">
        </dvb-parameter-kinderkontaktliste>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
