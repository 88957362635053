<div class="dvb-missing-geschlecht-or-sprache row">
    <div class="col-md-7 big-abstand-bottom">
        <div ng-repeat="entry in vm.displayedKontaktperonEntries">
            <dvb-update-geschlecht-or-sprache entry="entry"
                                              on-update-sprache="vm.onUpdateSprache(entry, sprache)"
                                              on-update-geschlecht="vm.onUpdateGeschlecht(entry, geschlecht)">
            </dvb-update-geschlecht-or-sprache>
        </div>
        <div class="normal-abstand-top">
            <ul uib-pagination
                total-items="vm.kontaktpersonEntries.length"
                class="pagination-sm normal-abstand-top"
                ng-if="vm.kontaktpersonEntries.length > vm.entriesPerPage"
                items-per-page="vm.entriesPerPage"
                max-size="7"
                boundary-links="true"
                force-ellipses="true"
                previous-text="&#xf104;"
                next-text="&#xf105;"
                first-text="&#xf048;"
                last-text="&#xf051;"
                ng-model="vm.currentPage"
                ng-change="vm.onUpdatePage()"></ul>
        </div>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <form name="geschlechtOrSpracheForm" novalidate>
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           name="removeCompleted"
                           ng-change="vm.onRemoveCompleted()"
                           ng-model="vm.removeCompleteEntry">
                    <span data-translate="DATA-INTEGRITY.REMOVE_COMPLETED"></span>
                </label>
            </div>
        </form>
    </div>
</div>
