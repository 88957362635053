/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AsyncResponse, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {AsyncResponseService} from '../../common/service/rest/asyncResponseService';
import {MonatsblattApiSendStatus} from '../models/MonatsblattApiSendStatus';

export class MonatsBlattApiService {
    public static $inject: readonly string[] = ['$http', 'asyncResponseService'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/monatsblattapi`;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly asyncResponseService: AsyncResponseService,
    ) {
    }

    public sendFraktion(fraktionId: string, gueltigAb: moment.Moment): angular.IPromise<unknown> {

        const url = `${MonatsBlattApiService.BASE_URL}/betreuungsperson/${fraktionId}`
            + `/period/${DvbRestUtil.momentToLocalDate(gueltigAb)}`;

        return this.$http.post(url, {});

    }

    public sendForKinderOrt(kinderOrtId: string, gueltigAb: moment.Moment): angular.IPromise<unknown> {

        const url = `${MonatsBlattApiService.BASE_URL}/kinderort/${kinderOrtId}`
            + `/period/${DvbRestUtil.momentToLocalDate(gueltigAb)}`;

        return DvbRestUtilAngularJS.postAndGetModel(url, {}, AsyncResponse).then(response =>
            this.asyncResponseService.startPolling(response.id));
    }

    public getStates(
        kinderOrtId: string,
        gueltigAb: moment.Moment,
    ): angular.IPromise<MonatsblattApiSendStatus[]> {

        const url = `${MonatsBlattApiService.BASE_URL}/kinderort/${kinderOrtId}`
            + `/period/${DvbRestUtil.momentToLocalDate(gueltigAb)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, MonatsblattApiSendStatus, 'items');
    }

    public getState(fraktionId: string, gueltigAb: moment.Moment): angular.IPromise<MonatsblattApiSendStatus | null> {
        const url = `${MonatsBlattApiService.BASE_URL}/fraktion/${fraktionId}`
            + `/period/${DvbRestUtil.momentToLocalDate(gueltigAb)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, MonatsblattApiSendStatus);
    }
}
