/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import type {EmailVersandProperties} from '@dv/kitadmin/models';
import {Kontaktperson} from '@dv/kitadmin/models';
import type {
    BulkEmailCommunicationServiceGetFilteredBulkEmailsRequestParams,
} from '@dv/shared/backend/api/bulk-email-communication.service';
import {BulkEmailCommunicationService} from '@dv/shared/backend/api/bulk-email-communication.service';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {DvbRestUtil, PageContainer} from '@dv/shared/code';
import {PASS_THROUGH_DVB_ERRORS} from '@dv/shared/errors/http-interceptor';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import type {ParameterKinderkontaktliste} from '../../report/models';
import type {BulkEmail} from '../models/bulkemails/BulkEmail';
import {BulkEmailInfo} from '../models/bulkemails/BulkEmailInfo';

@Injectable({
    providedIn: 'root',
})
export class BulkEmailService {

    public constructor(
        private api: BulkEmailCommunicationService,
    ) {
    }

    public validateSender$(properties: EmailVersandProperties): Observable<unknown> {
        const body = {jaxEmailVersandProperties: properties.toRestObject()};
        const context = new HttpContext().set(PASS_THROUGH_DVB_ERRORS, true);

        return this.api.validate$(body, undefined, undefined, {context});
    }

    public getKontaktpersonen$(
        ParameterKinderkontaktliste: ParameterKinderkontaktliste,
    ): Observable<Kontaktperson[]> {
        const body = {jaxParameterKinderkontaktliste: ParameterKinderkontaktliste.toRestObject()};

        return this.api.getKontaktpersonen$(body).pipe(
            map(values => DvbRestUtil.transformArray(values.kontaktpersonen, Kontaktperson)),
        );
    }

    public getReceivers$(emailContentId: EntityId): Observable<Kontaktperson[]> {
        return this.api.getReceivers$({emailContentId}).pipe(
            map(values => DvbRestUtil.transformArray(values.kontaktpersonen, Kontaktperson)),
        );
    }

    public getFilteredBulkEmails$(
        params: BulkEmailCommunicationServiceGetFilteredBulkEmailsRequestParams,
    ): Observable<PageContainer<BulkEmailInfo>> {

        return this.api.getFilteredBulkEmails$(params).pipe(
            map(page => PageContainer.apiResponseTransformer(BulkEmailInfo, page)),
        );
    }

    public sendBulkEmails$(bulkEmail: BulkEmail): Observable<unknown> {
        return this.api.sendBulkEmails$({jaxBulkEmail: bulkEmail.toRestObject()});
    }

    public sendTestEmails$(bulkEmail: BulkEmail): Observable<unknown> {
        return this.api.sendTestEmail$({jaxBulkEmail: bulkEmail.toRestObject()});
    }
}
