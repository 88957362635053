/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktion, ZeitraumFeld} from '@dv/kitadmin/models';
import {stringUnion} from '@dv/shared/code';
import angular from 'angular';
import ClickEvent = JQuery.ClickEvent;
import KeyDownEvent = JQuery.KeyDownEvent;

const POPOVER_POSITIONS = stringUnion('bottom', 'right', 'top', 'left');

type PopoverPositions = typeof POPOVER_POSITIONS.type;

export class ZuweisungPopoverHelper {

    private static popoverSelector: Readonly<string> = '.popover-zuweisung';

    public popoverZuweisungContent: { zeitraumFeld: ZeitraumFeld | null; gruppe: KinderOrtFraktion | null } = {
        zeitraumFeld: null,
        gruppe: null,
    };

    private clickListener?: (event: ClickEvent) => void;
    private keydownListener?: (event: KeyDownEvent) => void;
    private zuweisungsPopoverElement?: JQLite;

    /**
     * @param idSelector if present, adds the given string as an ID to the element selector. Necessary when there are
     *     multiple zuweisen popovers on the same page.
     */
    public constructor(
        private $document: angular.IDocumentService,
        private $scope: angular.IScope,
        private idSelector: string | null = null,
    ) {
    }

    private static getPopoverPosition(
        parentElement: JQuery<Element>,
        popoverElement: JQuery<Element>,
    ): { top: number; left: number; position: PopoverPositions } {
        const parentPos = parentElement.offset();

        if (!parentPos) {
            return {top: 0, left: 0, position: 'bottom'};
        }

        const height = parentElement.height() ?? 0;
        const width = parentElement.width() ?? 0;
        const popoverWidth = popoverElement.width() ?? 0;

        const left = parentPos.left + width / 2 - popoverWidth / 2;
        if (left >= 0) {
            // left edge fits -> display below the parent element
            return {
                top: parentPos.top + height,
                left,
                position: 'bottom',
            };
        }

        const popoverHeight = popoverElement.height() ?? 0;

        // left edge is cut off -> display to the right of the parent element
        return {
            top: parentPos.top + height / 2 - popoverHeight / 2,
            left: parentPos.left + width,
            position: 'right',
        };
    }

    /**
     * Sets up a click listener to cancel the popover when the user clicks somewhere else on the document.
     */
    public init(): void {
        this.clickListener = (): void => this.cancelPopover();
        this.keydownListener = event => {
            if (event.key === 'Escape') {
                this.cancelPopover();
            }
        };
        this.$document.on('click', this.clickListener);
        this.$document.on('keydown', this.keydownListener);
    }

    /**
     * Unregisters the click handler added on init.
     */
    public destroy(): void {
        if (this.clickListener) {
            this.$document.off('click', this.clickListener);
        }
        if (this.keydownListener) {
            this.$document.off('keydown', this.keydownListener);
        }

        if (this.zuweisungsPopoverElement) {
            this.zuweisungsPopoverElement.remove();
        }
    }

    /**
     * Opens the popover correctly positioned to the parent element.
     *
     * @param parentElement
     */
    public open(parentElement: JQuery<Element>): void {
        this.zuweisungsPopoverElement ??= this.getPopover();

        const popoverPosition = ZuweisungPopoverHelper.getPopoverPosition(parentElement, this.zuweisungsPopoverElement);
        this.zuweisungsPopoverElement.css({top: popoverPosition.top, left: popoverPosition.left});
        this.zuweisungsPopoverElement.removeClass(POPOVER_POSITIONS.values);
        this.zuweisungsPopoverElement.addClass(popoverPosition.position);
        this.zuweisungsPopoverElement.show();
    }

    /**
     * Cancel the selection and close the popover.
     */
    public cancelPopover(): void {
        if (this.zuweisungsPopoverElement?.is(':visible') && this.popoverZuweisungContent.zeitraumFeld) {
            this.$scope.$evalAsync(() => {
                // Forcing a scope apply here so it actually goes inactive without waiting for the next digest cycle.
                if (this.popoverZuweisungContent.zeitraumFeld) {
                    this.popoverZuweisungContent.zeitraumFeld.active = false;
                }
            });
        }

        this.closePopover();
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Closes the popover but does not manipulate the field.
     */
    public closePopover(): void {
        if (this.zuweisungsPopoverElement) {
            this.zuweisungsPopoverElement.hide();
        }
    }

    /**
     * Determines if the is an open / visible popover.
     */
    public isOpen(): boolean {
        return !!this.zuweisungsPopoverElement && this.zuweisungsPopoverElement.is(':visible');
    }

    private getPopover(): JQuery {
        const selector = (this.idSelector ? `#${this.idSelector}` : '') + ZuweisungPopoverHelper.popoverSelector;

        return angular.element(selector).appendTo('body');
    }
}
