<div class="dvb-existing-extra-plaetze">

    <div class="row big-abstand-bottom"
         ng-if="vm.plaetze.length === 0 && !vm.isLoading">
        <div class="col-md-12" data-translate="KIND.EXTRA_DAY.NO_EXTRA_DAYS"></div>
    </div>

    <dvlib-spinner ng-if="vm.isLoading" class="centered-spinner"></dvlib-spinner>

    <!-- die Sortierung ist nicht konsitent: in dvb-betreuungsverlauf ist es nach Fraktion DisplayName, hier nach ID. Wird ignoriert, da mehrere Fraktionen bei ExtraPlätzen vermutlich kaum vorkommt. -->
    <dvb-collapsible-panel class="extra-platz-entry"
                           ng-repeat="platz in vm.plaetze | orderBy: ['-affectedDay', 'fraktionId', 'extraPlatzType']"
                           is-collapsed="!platz.isNewPlatz() && !platz.isModified()"
                           ng-init="weekDays=vm.getEnabledDays(platz)">


        <panel-header>
            <dvb-temp-extra-platz-header platz="platz">
            </dvb-temp-extra-platz-header>
        </panel-header>

        <panel-content>
            <dvb-temp-extra-platz-header platz="platz">
            </dvb-temp-extra-platz-header>
            <div class="platz-details">

                <div class="buttons-flex">
                    <div class="actions">
                        <button class="btn btn-link"
                                type="button"
                                data-translate="COMMON.LOESCHEN"
                                ng-click="vm.removePlatz({platz: platz})"
                                ng-disabled="platz.isRemoved() || platz.disabled">
                        </button>
                    </div>
                </div>

                <div class="details-flex">

                    <div ng-bind="vm.getFraktion(platz.fraktionId).getDisplayName()"></div>

                    <select class="form-control platz-type small-abstand-top"
                            ng-model="platz.extraPlatzCategory"
                            ng-options="type as ('KIND.EXTRA_DAY.CATEGORY.TYPES.' + type | translate) for type in vm.extraPlatzCategories"
                            ng-change="vm.extraPlatzUpdated({platz})"
                            ng-disabled="platz.disabled"
                            required
                            tooltip-placement="top-left"
                            uib-tooltip="{{'KIND.EXTRA_DAY.CATEGORY.TYP_AUSWAEHLEN' | translate}}">
                        <option class="hide"
                                data-translate="KIND.EXTRA_DAY.CATEGORY.TYP_AUSWAEHLEN"
                                disabled
                                ng-value="null"
                                selected>
                        </option>
                    </select>
                </div>

                <div class="wochenplan-flex">
                    <dvb-wochenplan
                        class="small"
                        distinct-betreuungs-zeitraeume="vm.getFraktion(platz.fraktionId).wochenplan.distinctZeitraeume"
                        interactive="false"
                        show-null-value-as-plain="true"
                        week-days="weekDays"
                        zeitraum-felder="platz.isRemoved() ? platz.getFelder() : platz.getUnremoved()"
                        show-betreuungs-zeitraum-name="vm.getFraktion(platz.fraktionId).wochenplan.showBetreuungsZeitraumName">
                    </dvb-wochenplan>
                </div>

            </div>
        </panel-content>

    </dvb-collapsible-panel>

</div>
