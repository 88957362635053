<div class="dvb-firma-kontingent-value-header">
    <div class="history-columns">

        <p class="history-column-left">
            <dvb-firma-kontingent-value-title
                firmen-kontingent-value="vm.firmenKontingentValue">
            </dvb-firma-kontingent-value-title>
        </p>

        <p class="history-column-middle"
           data-translate="COMMON.FIRMENPLATZ.MF"
           data-translate-value-count="{{vm.firmenKontingentValue.plaetze}}"
           data-translate-value-showcount="true"
           data-translate-interpolation="messageformat">
        </p>

        <p class="history-column-right"
           ng-if="vm.firmenKontingentValue.kitas && vm.firmenKontingentValue.kitas.length > 1"
           data-translate="COMMON.KINDERORT.MF"
           data-translate-value-count="{{vm.firmenKontingentValue.kitas.length}}"
           data-translate-value-showcount="true"
           data-translate-interpolation="messageformat"></p>
        <p class="history-column-right"
           ng-if="vm.firmenKontingentValue.kitas && vm.firmenKontingentValue.kitas.length == 1"
           ng-bind="vm.firmenKontingentValue.kitas[0].name">
        </p>

    </div>
</div>
