/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {MissingGeschlechtOrSprache, TempBlob} from '@dv/kitadmin/models';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class DataIntegrityService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/dataintegrity`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getTempBlobForMalformedPhoneNumbers(): angular.IPromise<TempBlob> {
        const url = `${DataIntegrityService.BASE_URL}/malformedPhoneNumbers`;

        return this.$http.post(url, {})
            .then(response => DvbRestUtilAngularJS.mapIfData(response, r => TempBlob.apiResponseTransformer(r.data)));
    }

    public getKontaktpersonenMissingGeschlechtOrSprache(): angular.IPromise<MissingGeschlechtOrSprache[]> {
        const url = `${DataIntegrityService.BASE_URL}/missingGeschlechtOrSprache`;

        return DvbRestUtilAngularJS.getModelsArray(url, MissingGeschlechtOrSprache, 'missingGeschlechtOrSprache');
    }
}
