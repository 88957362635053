/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrtFraktion, WochenKapazitaet, Wochenplan} from '@dv/kitadmin/models';
import {BetreuungsPerson, Gruppe, KinderOrtFraktionType, KinderOrtType} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbUtil, END_OF_TIME} from '@dv/shared/code';
import type {StateService, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';
import type {KapazitaetService} from '../../../service/kapazitaetService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenplaene: '<',
        dtype: '@',
    },
    template: require('./dvb-fraktion-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbFraktionErstellen implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'kapazitaetService',
        'kinderOrtService',
        '$uiRouterGlobals',
        'errorService',
        'dvbStateService',
        '$state',
    ];

    public wochenplaene!: readonly Wochenplan[];
    public dtype!: KinderOrtFraktionType;

    public selectedWochenplan: Wochenplan | null = null;
    public fraktion: KinderOrtFraktion | null = null;
    public kinderOrtDType: KinderOrtType | null = null;
    public wochenKapazitaet: WochenKapazitaet | null = null;
    public isLoading: boolean = false;
    public gueltigAb: moment.Moment | null = null;
    public kinderOrtSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
        private kapazitaetService: KapazitaetService,
        private kinderOrtService: KinderOrtService,
        private $uiRouterGlobals: UIRouterGlobals,
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
        private $state: StateService,
    ) {
    }

    public $onInit(): void {
        this.initSelectedWochenplan();
        this.initFraktion();
        this.initKinderOrtSearchResultEntry();
        this.kapazitaetService.clearAllErrors();
    }

    public updateWochenKapazitaet(updatedWochenKapazitaet: WochenKapazitaet): void {
        this.wochenKapazitaet = updatedWochenKapazitaet;
    }

    public validateAndCreateFraktion(formIsValid: boolean): void {
        if (!this.wochenKapazitaet) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        // wochenKapazitaet gueltigAb muss hier gesetzt werden, da das input Feld nur das Datum der Fraktion
        // aktualisiert
        this.wochenKapazitaet.gueltigAb = this.gueltigAb;
        this.wochenKapazitaet.gueltigBis = END_OF_TIME;

        const isValid = this.isFraktionComplete(formIsValid) && this.wochenKapazitaet.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return;
        }

        const wochenplan = checkPresent(this.selectedWochenplan);

        const fraktion = checkPresent(this.fraktion);
        const kinderOrtId = checkPresent(this.kinderOrtSearchResultEntry).id;
        const wochenplanId = checkPresent(wochenplan.id);

        this.isLoading = true;

        this.kinderOrtService.createFraktion(kinderOrtId, fraktion, wochenplanId, this.wochenKapazitaet)
            .then(response => {
                this.kapazitaetService.clearAllErrors();

                return this.$state.go(`base.fraktion.${fraktion.dtype.toLowerCase()}.profil`, {
                    id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response),
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public isFraktionComplete(formIsValid: boolean): boolean {
        return formIsValid
            && !!this.fraktion
            && this.fraktion.isValid()
            && !!this.kinderOrtSearchResultEntry
            && !!this.kinderOrtSearchResultEntry.id;
    }

    public goBack(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    private initSelectedWochenplan(): void {
        if (this.wochenplaene.length <= 0) {
            return;
        }

        this.selectedWochenplan = DvbUtil.findFirst(this.wochenplaene, wochenplan => wochenplan.isDefault)
            ?? this.wochenplaene[0];
    }

    private initFraktion(): void {
        switch (this.dtype) {
            case KinderOrtFraktionType.BETREUUNGS_PERSON:
                this.fraktion = new BetreuungsPerson();
                this.kinderOrtDType = KinderOrtType.TAGES_ELTERN;
                break;
            case KinderOrtFraktionType.GRUPPE:
                this.fraktion = new Gruppe();
                this.kinderOrtDType = KinderOrtType.KITA;
                break;
            default:
                throw new Error(`Could not initialize fraktion with dtype: ${JSON.stringify(this.dtype)}`);
        }
    }

    private initKinderOrtSearchResultEntry(): void {
        if (!this.$uiRouterGlobals.params.kitaId) {
            return;
        }

        this.kinderOrtService.get(this.$uiRouterGlobals.params.kitaId).then(resultKita => {
            this.kinderOrtSearchResultEntry = checkPresent(resultKita).toSearchResultEntry();
        });
    }
}

componentConfig.controller = DvbFraktionErstellen;
angular.module('kitAdmin').component('dvbFraktionErstellen', componentConfig);
