/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import {AddressMode} from '@dv/shared/backend/model/address-mode';
import {VersandartRechnung} from '@dv/shared/backend/model/versandart-rechnung';
import {Geschlecht} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    template: require('./dvb-kontaktperson-stammdaten-form.html'),
    controllerAs: 'vm',
    require: {
        form: '^^form',
    },
    bindings: {
        kontakt: '<',
        uneditableAdresse: '<?',
        adressDatum: '<',
    },
};

export class DvbKontaktpersonStammdatenForm implements angular.IController {
    public static $inject: readonly string[] = ['mandantConfigurationService'];

    public kontakt!: RelationshipWithKontaktperson;
    public uneditableAdresse?: boolean;
    public adressDatum!: moment.Moment;
    public geschlechte: readonly Geschlecht[] = [Geschlecht.WEIBLICH, Geschlecht.MAENNLICH, Geschlecht.DIVERS];
    public addressModes: readonly AddressMode[] = Object.values(AddressMode);
    public versandartEmail: boolean = false;

    public constructor(
        private mandantConfigurationService: MandantConfigurationService,
    ) {
    }

    public $onInit(): void {
        if (this.kontakt.relationship.isHauptkontakt()){
            this.kontakt.kontaktperson!.addressMode = AddressMode.OWN;
        }
        this.kontakt.kontaktperson!.versandartRechnung = VersandartRechnung.POST;
    }

    public $onChanges(): void {
        if (this.kontakt.kontaktperson?.addressMode === AddressMode.HAUPTKONTAKT &&
            this.kontakt.relationship.isHauptkontakt()) {
            this.kontakt.kontaktperson.addressMode = AddressMode.OWN;
        }
    }

    public changeKontaktAdresse(): void {
        const limitedAdressen = this.kontakt.kontaktperson!.adressen;
        const mode = this.kontakt.kontaktperson!.addressMode;

        if (mode === AddressMode.HAUPTKONTAKT || mode === AddressMode.NONE) {
            limitedAdressen.splice(0);

            return;
        }

        this.mandantConfigurationService.createDefaultLimitedAdresse(this.adressDatum)
            .then(adresse => {
                limitedAdressen.push(adresse);
            });
    }

    public updateVersandart(): void {
        this.kontakt.kontaktperson!.versandartRechnung = this.versandartEmail ?
            VersandartRechnung.EMAIL :
            VersandartRechnung.POST;
    }

    public isAddressRequired(): boolean {
        return this.kontakt.relationship.isAdresseRequired();
    }
}

componentConfig.controller = DvbKontaktpersonStammdatenForm;
angular.module('kitAdmin').component('dvbKontaktpersonStammdatenForm', componentConfig);
