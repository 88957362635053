/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, Mandant, RechnungsLauf} from '@dv/kitadmin/models';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        mandant: '<',
        periode: '<',
    },
    template: require('./dvb-kita-rechnungslauf.html'),
    controllerAs: 'vm',
};

export class DvbKitaRechnungslauf implements angular.IController {

    public static $inject: readonly string[] = [];

    public kita!: KinderOrt;
    public mandant!: Mandant;
    public periode: moment.Moment | null = null;

    public showFinish: boolean = false;
    public rechnungsLauf: RechnungsLauf | null = null;

    public onSubmit(rechnungsLauf: RechnungsLauf): void {
        this.rechnungsLauf = rechnungsLauf;
        this.showFinish = true;
    }
}

componentConfig.controller = DvbKitaRechnungslauf;
angular.module('kitAdmin').component('dvbKitaRechnungslauf', componentConfig);
