<div class="dvb-zahlung-heading row">
    <div class="col-xs-4">
        <span ng-bind="vm.zahlung.datum | amDateFormat: 'DD.MM.YYYY'"></span>
    </div>
    <div class="col-xs-5">
        <span data-translate="FAKTURA.{{vm.zahlung.type}}"></span>
        <span ng-if="vm.originTitleKey" class="badge small-abstand-left" data-translate="{{vm.originTitleKey}}"></span>
    </div>
    <div class="col-xs-3 text-right">
        <strong data-translate="COMMON.BETRAG_VALUE"
                data-translate-value-betrag="{{vm.zahlung.betrag | number: 2}}">
        </strong>
    </div>
</div>
