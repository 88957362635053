<div>
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-home header-icon"
               uib-tooltip="{{kita.titel || ('COMMON.KINDERORT.SINGULAR' | translate)}}"
               tooltip-placement="top-left">
            </i>
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{kita.getDisplayName()}}" tooltip-placement="top-left">
                    <span class="visible-xl-inline" ng-bind="kita.titel"></span>
                    <span ng-bind="kita.name"></span>
                </h1>
                <h2 ng-if="$uiRouterGlobals.current.data.subtitle && $uiRouterGlobals.current.data.subtitle.length > 0"
                    class="subtitle"
                    data-translate="{{$uiRouterGlobals.current.data.subtitle}}">
                </h2>

                <div class="header-dropdown" uib-dropdown>
                    <a href="" class="header-box-attachment dropdown-toggle" uib-dropdown-toggle>
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="base.kinderort.profil"
                               data-translate="COMMON.NAV_PROFIL"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{externalAnmeldungStates.EXTERNAL_ANMELDUNGEN_STATE.data.permission({id: kita.id})}}">
                            <a ui-sref="{{externalAnmeldungStates.EXTERNAL_ANMELDUNGEN_STATE.name}}"
                               data-translate="KINDERORT.NAV_EXTERNAL_ANMELDUNGEN"></a>
                        </li>
                        <!-- AbweichugsMeldung -->
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{kindAbweichungsMeldungState.data.permission({id: kita.id})}}">
                            <a ui-sref="{{kindAbweichungsMeldungState.name}}"
                               data-translate="KINDERORT.NAV_KIND_ABWEICHUNGS_MELDUNGEN"></a>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{'kita:control:' + kita.id}}">
                            <a ui-sref="{{settingsStates.KIBON_MUTATIONSLAUF_STATE.name}}"
                               data-translate="KINDERORT.NAV_KIBON_MUTATIONSLAUF"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{ settingsStates.LOHN_API_STATE.data.permission({id: kita.id}) }}"
                            require-role="{{settingsStates.LOHN_API_STATE.data.role}}">
                            <a ui-sref="{{settingsStates.LOHN_API_STATE.name}}"
                               data-translate="KINDERORT.NAV_LOHN"></a>
                        </li>
                        <li ng-if="hasPersonalStates" class="divider"></li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{personalStates.PERSONALPLANUNG_STATE.data.permission({id: kita.id})}}">
                            <a ui-sref="{{personalStates.PERSONALPLANUNG_STATE.name}}"
                               data-translate="COMMON.NAV_PERSONALPLANUNG"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{kinderortWorkTimeControllingStates.KINDERORT_WORK_TIME_CONTROLLING_STATE.data.permission}}">
                            <a ui-sref="{{kinderortWorkTimeControllingStates.KINDERORT_WORK_TIME_CONTROLLING_STATE.name}}"
                               data-translate="COMMON.NAV_WORK_TIME_CONTROLLING"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{[ personalStates.TERMIN_STATE.data.permission({id: kita.id}) , 'module:personalplanung' ]}}">
                            <a ui-sref="{{personalStates.TERMIN_STATE.name}}"
                               data-translate="PERSONAL.TERMIN.KINDERORT_TERMIN"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{personalStates.BETREUUNGS_SCHLUESSEL_STATE.data.permission({id: kita.id})}}">
                            <a ui-sref="{{personalStates.BETREUUNGS_SCHLUESSEL_STATE.name}}"
                               data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.SINGULAR"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{personalStates.DIENST_ADMINISTRATION_STATE.data.permission({id: kita.id})}}">
                            <a ui-sref="{{personalStates.DIENST_ADMINISTRATION_STATE.name}}"
                               data-translate="KINDERORT.NAV_DIENST_ADMINISTRATION"></a>
                        </li>
                        <li class="divider"></li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{'kita:control:' + kita.id}}">
                            <a ui-sref="{{settingsStates.TARIFZUWEISUNG_STATE.name}}"
                               data-translate="KINDERORT.NAV_TARIFE"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="module:anwesenheit"
                            require-condition="hasMandantSchliesstage">
                            <a ui-sref="{{settingsStates.SCHLIESSTAGE_STATE.name}}"
                               data-translate="COMMON.NAV_SCHLIESSTAGE"></a>
                        </li>
                        <li ui-sref-active-eq="active" ng-if="hasMandantBetreuungsfaktorRegeln">
                            <a ui-sref="{{settingsStates.BETREUUNGSFAKTORREGELN_STATE.name}}"
                               data-translate="COMMON.NAV_BETREUUNGSFAKTOR_REGELN"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{['module:faktura', 'kita:control:' + kita.id]}}">
                            <a ui-sref="{{settingsStates.FAKTURA_KONFIGURATION_STATE.name}}"
                               data-translate="KINDERORT.NAV_RECHNUNGSKONFIGURATION"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{'kita:control:' + kita.id}}">
                            <a ui-sref="{{settingsStates.VEREINBARUNGS_CONFIGS_STATE.name}}"
                               data-translate="KINDERORT.NAV_VEREINBARUNGSKONFIGURATION"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{'personal:administrate:' + kita.id}}">
                            <a ui-sref="{{personalStates.PERSONAL_KONFIGURATION_STATE.name}}"
                               data-translate="KINDERORT.NAV_PERSONAL_KONFIGURATION"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-role="SUPPORT_ADMIN">
                            <a ui-sref="{{settingsStates.ANWESENHEITS_ZEIT_STATE.name}}"
                               data-translate="KIND.MONATSBELEGUNG.SETTINGS"></a>
                        </li>
                        <li ui-sref-active-eq="active"
                            dvb-auth
                            require-permission="{{groupAdminEinstellungenState.data.permission({id: kita.id})}}">
                            <a ui-sref="{{groupAdminEinstellungenState.name}}"
                               data-translate="KINDERORT.NAV_KINDERORT_GROUP_ADMIN_EINSTELLUNGEN"></a>
                        </li>
                    </ul>
                </div>

                <dvlib-date-switcher ng-show="showDatePicker()"
                                     class="normal-abstand-left form-linker"
                                     mode="week"
                                     (debounced-date-change)="weekPickerDateChanged()"
                                     [(date)]="weekPicker.firstOfWeek"
                                     [gueltig-ab]="weekPicker.gueltigAb"
                                     [gueltig-bis]="weekPicker.gueltigBis">
                </dvlib-date-switcher>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.kinderort.uebersicht" data-translate="COMMON.NAV_UEBERSICHT"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{'kita:control:' + kita.id}}"
                        require-condition="wartelisteCount > 0">
                        <a ui-sref="base.kinderort.kinder.warteliste" data-translate="KINDERORT.NAV_WARTELISTE"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{'kita:view:' + kita.id}}">
                        <a ui-sref="base.kinderort.kinder.belegung" data-translate="KINDERORT.NAV_BELEGUNG"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{['module:faktura', 'kita:control:' + kita.id]}}">
                        <a ui-sref="base.kinderort.faktura.overview" data-translate="COMMON.NAV_FAKTURA"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{['module:faktura', 'kita:control:' + kita.id]}}">
                        <a ui-sref="base.kinderort.offeneposten" data-translate="COMMON.NAV_OFFENE_POSTEN"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
