<div class="dvb-kita-faktura-texte-form">
    <div ng-repeat="status in vm.statuses">
        <div ng-if="vm.texte.hasOwnProperty(status)">
            <h4 ng-bind="'KINDERORT.RECHNUNG_STATUS_TEXT.RECHNUNG_STATUS_TEXT_' + status | translate"></h4>

            <dvb-translatable>
                <textarea ng-model="vm.texte[status].einfuehrung.texts[$dvbLang]"
                          dvb-translatable-input
                          class="form-control"
                          msd-elastic
                          maxlength="1000"
                          ng-attr-placeholder="{{'KINDERORT.EINFUEHRUNG' | translate}}"
                          uib-tooltip="{{$dvbLangTranslated}}"
                          tooltip-placement="top-left">
                </textarea>
            </dvb-translatable>

            <dvb-translatable>
                <textarea ng-model="vm.texte[status].konditionen.texts[$dvbLang]"
                          dvb-translatable-input
                          class="form-control"
                          msd-elastic
                          maxlength="1000"
                          ng-attr-placeholder="{{'KINDERORT.KONDITIONEN' | translate}}"
                          uib-tooltip="{{$dvbLangTranslated}}"
                          tooltip-placement="top-left">
                </textarea>
            </dvb-translatable>

            <div class="normal-abstand-bottom">
                <a href=""
                   ng-click="vm.removeTexte(status)"
                   data-translate="COMMON.LOESCHEN"
                   ng-if="status !== vm.rechnungStatusText.STANDARD">
                </a>
            </div>
        </div>
    </div>

    <div class="row form-row" ng-if="vm.showAdd">
        <div class="col-xs-12">
            <div class="btn-group" uib-dropdown data-keyboard-nav>
                <button type="button"
                        class="btn btn-link btn-margin btn-link-dropdown dropdown-toggle"
                        uib-dropdown-toggle>
                    <span data-translate="KINDERORT.NEUE_TEXTE"></span>
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li ng-repeat="(status, show) in vm.availableStatuses">
                        <a href=""
                           ng-if="show"
                           data-translate="{{'KINDERORT.RECHNUNG_STATUS_TEXT.RECHNUNG_STATUS_TEXT_' + status}}"
                           ng-click="vm.addTexte(status)">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
