/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        rechnungen: '<',
        revisionId: '<?',
    },
    template: require('./dvb-rechnungs-revisionen.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsRevisionen implements angular.IController {

    public rechnungen: Persisted<Rechnung>[] = [];
    public revisionId?: string;

    public isLoading: boolean = false;
    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.REVISION;

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.rechnungen) {
            this.rechnungen = changes.rechnungen.currentValue;
        }
    }
}

componentConfig.controller = DvbRechnungsRevisionen;
angular.module('kitAdmin').component('dvbRechnungsRevisionen', componentConfig);
