<div class="dvb-schliesstage-form dvb-form">
    <form name="schliesstagForm"
          ng-submit="vm.submit(schliesstagForm)"
          novalidate>
        <div class="row">
            <div class="col-md-12">
                <input type="text"
                       class="form-control"
                       maxlength="255"
                       ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                       uib-tooltip="{{'COMMON.NAME' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.schliesstage.name"
                       required/>
            </div>
        </div>

        <div class="row small-abstand-bottom">
            <div class="col-md-12">
                <h3 data-translate="COMMON.SCHLIESSTAGE.TITLE_SCHLIESSTAG_DATE_RANGES"></h3>
            </div>
        </div>

        <div class="row"
             ng-repeat="dateRange in vm.schliesstage.schliesstagDateRanges">
            <div class="col-xs-10">
                <div class="row">
                    <div class="col-xs-6">
                        <dvb-datepicker-text-field
                            ng-model="dateRange.gueltigAb"
                            ng-attr-placeholder="{{'COMMON.VON' | translate }}"
                            required>
                        </dvb-datepicker-text-field>
                        <p>{{dateRange.von}}</p>
                    </div>
                    <div class="col-xs-6">
                        <dvb-datepicker-text-field
                            ng-model="dateRange.gueltigBis"
                            ng-attr-placeholder="{{'COMMON.SCHLIESSTAGE.BIS' | translate }}">
                        </dvb-datepicker-text-field>
                        <p>{{dateRange.bis}}</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-2">
                <p class="form-linker">
                    <a href=""
                       ng-click="vm.removeSchliesstagDateRange(dateRange)"
                       data-translate="COMMON.LOESCHEN">
                    </a>
                </p>
            </div>
        </div>

        <div class="row from-row">
            <div class="col-md-12">
                <a href="" ng-click="vm.addSchliesstagDateRange()">
                    + <span data-translate="COMMON.SCHLIESSTAGE.SCHLIESSTAG_DATE_RANGE_HINZUFUEGEN"></span>
                </a>
            </div>
        </div>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   on-cancel="vm.onCancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
