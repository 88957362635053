/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ContextualAdresse} from '@dv/kitadmin/models';
import {Adresse} from '@dv/shared/code';
import angular from 'angular';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        contextualAdresse: '<',
        allowNone: '<?',
        orgRequired: '<?',
    },
    template: require('./dvb-contextual-adresse.html'),
    controllerAs: 'vm',
};

export class DvbContextualAdresse implements angular.IController {
    public static $inject: readonly string[] = [
        'mandantConfigurationService',
    ];

    public contextualAdresse!: ContextualAdresse;
    public allowNone?: boolean;
    public orgRequired: boolean = false;

    public adresseContext = ContextualAdresse.ADRESSE_CONTEXT;

    public constructor(
        private readonly mandantConfigurationService: MandantConfigurationService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.allowNone) {
            this.allowNone = changes.allowNone.currentValue === undefined ? false : changes.allowNone.currentValue;
        }
    }

    public onContextChange(): void {
        this.contextualAdresse.adresseHeader = '';
        this.contextualAdresse.adresseZusatz = '';

        if (this.contextualAdresse.context === ContextualAdresse.ADRESSE_CONTEXT.CUSTOM) {
            this.contextualAdresse.adresse = new Adresse();
            this.mandantConfigurationService.getDefaultCountry().then(country => {
                this.contextualAdresse.adresse!.land = country;
            });
        } else {
            this.contextualAdresse.adresse = null;
        }
    }
}

componentConfig.controller = DvbContextualAdresse;
angular.module('kitAdmin').component('dvbContextualAdresse', componentConfig);
