/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Termin} from '@dv/kitadmin/models';
import {ServiceContainer, ZeitraumFeld} from '@dv/kitadmin/models';
import {TerminWorkTimeType} from '@dv/shared/backend/model/termin-work-time-type';
import type {DayOfWeek, ITimeRange, IZeitraum} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil, isPresent, TimeRangeUtil, Zeitraum} from '@dv/shared/code';
import moment from 'moment';
import type {StyleVariableService} from '../../common/service/style-variable.service';
import {ZeitraumFeldSection} from './ZeitraumFeldSection';

export type IColoredZeitraum = IZeitraum & { color?: string };

/**
 * A dynamically created ZeitraumFeld for any arbitrary Zeitraum.
 * Not necessarily reflecting any persisted predefined Zeitraum but rather generated based on e.g. all the adjacent
 * assigned dienste of an angestellte on a day.
 */
export class DynamicZeitraumFeld extends ZeitraumFeld {

    private static readonly SPACER_COLOR = 'transparent';
    private static readonly ABSENZ_DESCRIPTION_MAX_LENGTH = 20;

    /**
     * Sub sections of this ZeitraumFeld, e.g. for included termine that have to be visualized.
     */
    public sections: ZeitraumFeldSection[] = [];

    public text: string | null = null;

    public constructor(
        zeitraum: IZeitraum,
        dayOfWeek: DayOfWeek,
    ) {
        super(zeitraum, dayOfWeek);
        this.initText();
    }

    /**
     * Adds the termin ranges as sections to this DynamicZeitraumFeld.
     */
    public addSections(termine: Termin[], styleVariableService: StyleVariableService, date: moment.Moment): void {
        this.sections = [];
        this.initText();

        if (termine.length === 0) {
            return;
        }

        termine.sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);
        const filteredTermine = termine.filter(termin => termin.affectsZeitraum(this.zeitraum, date));

        if (filteredTermine.length === 0) {
            return;
        }

        const terminRanges = this.createTerminRanges(filteredTermine, date);
        const spacedTerminRanges = this.createdTerminRangesWithSpacers(terminRanges, styleVariableService);

        // spacer before
        if (spacedTerminRanges[0].von?.isAfter(this.zeitraum.von)) {
            this.sections.push(new ZeitraumFeldSection(this.zeitraum.von,
                spacedTerminRanges[0].von,
                DynamicZeitraumFeld.SPACER_COLOR));
        }

        spacedTerminRanges.forEach(absenzRange => {
            this.sections.push(new ZeitraumFeldSection(moment(absenzRange.von),
                moment(absenzRange.bis),
                absenzRange.color));
        });

        // spacer after
        const lastAbsenz = spacedTerminRanges[spacedTerminRanges.length - 1];
        if (lastAbsenz.bis!.isBefore(this.zeitraum.bis)) {
            this.sections.push(new ZeitraumFeldSection(moment(lastAbsenz.bis),
                moment(this.zeitraum.bis),
                DynamicZeitraumFeld.SPACER_COLOR));
        }
    }

    private createTerminRanges(termine: Termin[], date: moment.Moment): (ITimeRange & { prio?: boolean })[] {
        let terminText = `\n${ServiceContainer.$translate.instant('PERSONAL.TERMIN.TITLE')}:`;

        const terminRanges = termine.map(termin => {
            const range: (ITimeRange & { prio?: boolean }) | null = termin.toTimeRange(date);
            if (range === null) {
                return null;
            }
            range.prio = TerminWorkTimeType.DEDUCT_FROM_PLANNED_TIME === termin.terminType?.workTimeType
                || !!termin.terminType?.deductFromBedarfsrechnung;

            // limit the sections start and end time based on the zeitraums times
            range.von = DvbDateUtil.getLaterTime(range.von!, this.zeitraum.von!);
            range.bis = DvbDateUtil.getEarlierTime(range.bis!, this.zeitraum.bis!);

            terminText += `\n${termin.displayRange(date)}`;
            if (termin.bemerkung) {
                terminText +=
                    `: ${DvbUtil.truncate(termin.bemerkung, DynamicZeitraumFeld.ABSENZ_DESCRIPTION_MAX_LENGTH)}`;
            }

            return range;
        }).filter(isPresent);

        if (terminRanges.length > 0) {
            this.text += terminText;
        }

        return terminRanges;
    }

    private initText(): void {
        this.text = `${this.zeitraum.von!.format('HH:mm')}-${this.zeitraum.bis!.format('HH:mm')}`;
    }

    /**
     * Merges the given Zeitraeume. Gaps between the given ranges are filled with spacers.
     * The Zeitraeume are differentiated by prio
     *
     * @return the merged Zeitraume. Elements can be differentiated by color
     */
    private createdTerminRangesWithSpacers(
        ranges: (ITimeRange & { prio?: boolean })[],
        styleVariableService: StyleVariableService,
    ): IColoredZeitraum[] {

        // Separate Termine by prio and merge them
        const absenzen = ranges.filter(tr => !!tr.prio);
        const mergedAbsenzen = TimeRangeUtil.mergeOverlappingTimeRanges(absenzen);

        const termine = ranges.filter(tr => !tr.prio);
        const mergedTermine = TimeRangeUtil.mergeOverlappingTimeRanges(termine);

        // Remove absenzen from termine as they have a priority
        const mergedTermineWithoutAbsenzen = TimeRangeUtil.timeRangesWithoutToRemoves(mergedTermine, mergedAbsenzen);

        // get Colors and assign them to the corresponding Termine
        const colorAbsenz = styleVariableService.getColorContrastNormal();
        const colorTermin = styleVariableService.getColorContrastAltNormal();

        const coloredAbsenzen = mergedAbsenzen.map(item =>
            this.createColoredZeitraum(item.von!, item.bis!, colorAbsenz));
        const coloredTermine = mergedTermineWithoutAbsenzen.map(item =>
            this.createColoredZeitraum(item.von!, item.bis!, colorTermin));

        // Merge and fill the remaining gaps
        const allTermineWithGaps = coloredAbsenzen.concat(coloredTermine);
        allTermineWithGaps.sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);

        const allTermine: IColoredZeitraum[] = [];

        for (const termin of allTermineWithGaps) {
            if (allTermine.length === 0) {
                allTermine.push(termin);
                continue;
            }

            const last = allTermine[allTermine.length - 1];
            if (!last.bis?.isSame(termin.von)) {
                allTermine.push(this.createColoredZeitraum(last.bis!, termin.von!, DynamicZeitraumFeld.SPACER_COLOR));
            }

            allTermine.push(termin);
        }

        return allTermine;
    }

    private createColoredZeitraum(von: moment.Moment, bis: moment.Moment, color: string): IColoredZeitraum {
        const feld = new Zeitraum(moment(von), moment(bis)) as IColoredZeitraum;
        feld.color = color;

        return feld;
    }
}
