<div class="dvb-betreuungsverlauf-extra-plaetze">

    <dvb-belegung belegung="vm.entry.wochenBelegung"
                  extra-plaetze="vm.entry.extraPlaetze"
                  title-key="'KIND.EXTRA_DAY.WITH_RANGE'">
        <ul class="list-inline hidden-print"
            dvb-auth
            require-condition="vm.showAbweichungenBearbeiten"
            require-permission="kita:view_plus:general">
            <li>
                <a ui-sref="base.kind.extra-platz({startDate: vm.entry.gueltigAb, kindId: vm.kindId})"
                   data-translate="KIND.EXTRA_DAY.EDIT_MF"
                   data-translate-value-count="vm.entry.extraPlaetze.length"
                   data-translate-interpolation="messageformat">
                </a>
            </li>
        </ul>
    </dvb-belegung>

</div>
