<div class="dvb-kita-faktura-small-invoice-konfiguration-form dvb-form big-abstand-bottom">
    <form name="smallInvoiceKitaKonfigurationForm" novalidate>

        <div class="form-section" ng-if="vm.editMode">
            <p data-translate="KINDERORT.KONFIGURATION_EDIT_WARNUNG"></p>
        </div>

        <div class="form-section">
            <h3>
                <span data-translate="KINDERORT.ZAHLUNGSKONTO" class="smaller-abstand-right"></span>
                <a href=""
                   ng-show="!vm.loadingKonten"
                   ng-click="vm.reloadKonten()"
                   class="fa fa-refresh"
                   uib-tooltip="{{'KINDERORT.ZAHLUNGSKONTO_AKTUALISIEREN' | translate}}"
                   tooltip-placement="top-left">
                </a>
                <dvlib-spinner [show]="vm.loadingKonten"></dvlib-spinner>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <select class="form-control"
                            ng-model="vm.konfiguration.bankKontoId"
                            ng-options="konto.id as konto.title for konto in vm.bankKonten | orderBy:'title'">
                        <option value=""
                                data-translate="{{vm.loadingKonten ? 'FAKTURA.AKTUALISIERE' : 'KINDERORT.KEIN_KONTO'}}">
                        </option>
                    </select>
                </div>
            </div>

            <h3>
                <span data-translate="COMMON.EINZAHLUNGSSCHEIN" class="smaller-abstand-right"></span>
                <a href=""
                   ng-show="!vm.loadingEinzahlungsscheine"
                   ng-click="vm.reloadEinzahlungsscheine()"
                   class="fa fa-refresh"
                   uib-tooltip="{{'KINDERORT.EINZAHLUNGSSCHEINE_AKTUALISIEREN' | translate}}"
                   tooltip-placement="top-left">
                </a>
                <dvlib-spinner [show]="vm.loadingEinzahlungsscheine"></dvlib-spinner>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <select class="form-control"
                            ng-model="vm.konfiguration.esrId"
                            ng-options="esr.id as esr.name for esr in vm.einzahlungsscheine | orderBy:'name'">
                        <option value=""
                                data-translate="{{vm.loadingEinzahlungsscheine ?
                                  'FAKTURA.AKTUALISIERE' :
                                  'KINDERORT.KEIN_EINZAHLUNGSSCHEIN'}}">
                        </option>
                    </select>
                </div>
            </div>

            <h3>
                <span data-translate="KINDERORT.LAYOUT" class="smaller-abstand-right"></span>
                <a href=""
                   ng-show="!vm.loadingLayouts"
                   ng-click="vm.reloadLayouts()"
                   class="fa fa-refresh"
                   uib-tooltip="{{'KINDERORT.LAYOUTS_AKTUALISIEREN' | translate}}"
                   tooltip-placement="top-left">
                </a>
                <dvlib-spinner [show]="vm.loadingLayouts"></dvlib-spinner>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <select class="form-control"
                            ng-model="vm.konfiguration.layoutId"
                            ng-options="layout.id as layout.name for layout in vm.layouts | orderBy:'name'">
                        <option value=""
                                data-translate="{{vm.loadingLayouts ? 'FAKTURA.AKTUALISIERE' : 'KINDERORT.KEIN_LAYOUT'}}">
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="KINDERORT.MASSENVERSAND"></span>
            </h3>

            <div class="row form-row" ng-if="!vm.showMassenversand">
                <div class="col-xs-12">
                    <a href="" ng-click="vm.showMassenversand = true">
                        + <span data-translate="KINDERORT.PP_FRANKIERUNG_HINZUFUEGEN"></span>
                    </a>
                </div>
            </div>
            <div class="row form-row" ng-if="vm.showMassenversand">
                <div class="col-md-6">
                    <input type="text"
                           ng-model="vm.konfiguration.massenversandText"
                           ng-attr-placeholder="{{'KINDERORT.TEXT_MASSENVERSAND' | translate}}"
                           uib-tooltip="{{'KINDERORT.TEXT_MASSENVERSAND' | translate}}"
                           tooltip-placement="top-left"
                           class="form-control"
                           ng-required="vm.showMassenversand"
                           maxlength="255">
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="number"
                           min="0"
                           ng-attr-placeholder="{{'FAKTURA.ABSTAND_LINKS_MM' | translate}}"
                           uib-tooltip="{{'FAKTURA.ABSTAND_LINKS_MM' | translate}}"
                           tooltip-placement="top-left"
                           class="form-control"
                           ng-required="vm.showMassenversand"
                           ng-model="vm.konfiguration.massenversandLinks">
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="number"
                           min="0"
                           ng-attr-placeholder="{{'FAKTURA.ABSTAND_OBEN_MM' | translate}}"
                           uib-tooltip="{{'FAKTURA.ABSTAND_OBEN_MM' | translate}}"
                           tooltip-placement="top-left"
                           class="form-control"
                           ng-required="vm.showMassenversand"
                           ng-model="vm.konfiguration.massenversandOben">
                </div>
                <div class="col-xs-12">
                    <a href="" ng-click="vm.deleteMassenversand()" data-translate="COMMON.LOESCHEN"></a>
                </div>
            </div>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="KINDERORT.DEFINIERTE_TEXTE"></span>
            </h3>

            <p class="normal-abstand-bottom" data-translate="KINDERORT.DEFINIERTE_TEXTE_ERKLAERUNG"></p>

            <dvb-kita-faktura-texte-form texte="vm.konfiguration.texte">
            </dvb-kita-faktura-texte-form>
        </div>
    </form>
</div>
