/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontingente, VertraglichesPensum} from '@dv/kitadmin/models';
import {PensumType} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontingente: '<',
        vertraglichePensen: '<',
    },
    template: require('./dvb-vertragliches-pensum.html'),
    controllerAs: 'vm',
};

export class DvbVertraglichesPensum implements angular.IController {
    public static $inject: readonly string[] = ['$translate'];

    public kontingente: Kontingente[] = [];
    public vertraglichePensen?: VertraglichesPensum[];

    public constructor(private readonly $translate: angular.translate.ITranslateService) {
    }

    public getTitle(vertraglichesPensum: VertraglichesPensum): string {
        const translationKey = `KIND.ZUWEISUNG.VERTRAGLICHE_STUNDEN${
            PensumType.PERCENTAGE === vertraglichesPensum.type ? '.PERCENTAGE' : ''}`;

        if (vertraglichesPensum.kontingentId === null) {
            return this.$translate.instant(`${translationKey}.PRIVAT`);
        }

        const kontingent = checkPresent(this.kontingente.find(k => k.id === vertraglichesPensum.kontingentId));
        if (kontingent.isSubventioniertesKontingent()) {
            return this.$translate.instant(`${translationKey}.SUBVENTIONERT`);
        }

        if (kontingent.isFirmenKontingent()) {
            return this.$translate.instant(`${translationKey}.FIRMA`,
                {name: kontingent.getDisplayName()});
        }

        throw new Error(`not implemented for Kontingent of type ${(kontingent as unknown as Kontingente).type}`);
    }
}

componentConfig.controller = DvbVertraglichesPensum;
angular.module('kitAdmin').component('dvbVertraglichesPensum', componentConfig);
