/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Environment} from '@dv/kitadmin/models';
import {serviceContainer} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import {IDP_HINT_QUERY_PARAM} from '@dv/shared/authentication/oidc';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type angular from 'angular';
import {ANGULAR_JS_MODULE} from '../angularjs-module';
import {COMPONENT_CONFIG as dvbNavigation} from './component/navigation/dvb-navigation';
import {config} from './config/configure';
import {DIRECTIVE as dvbDownload} from './directive/dvb-download/dvb-download';
import {runBlock as init} from './run/init';
import {titleRun as routerTitle} from './run/routerTitle';

// Router Debugger: Inserts a graph of all states and a transition history into the page.
// Comment in the following line to enable it.
// ANGULAR_JS_MODULE.run(['$uiRouter', visualizer]);

ANGULAR_JS_MODULE
    .config(config)
    .config(baseConfig)
    .run(serviceContainer)
    .run(init)
    .run(routerTitle)
    .component('dvbNavigation', dvbNavigation)
    .directive('dvbDownload', () => dvbDownload)
;

baseConfig.$inject = ['$stateProvider', '$compileProvider', 'ENVIRONMENT'];

function baseConfig(
    $stateProvider: StateProvider,
    $compileProvider: angular.ICompileProvider,
    ENVIRONMENT: Environment,
): void {
    $stateProvider.state('base', {
        abstract: true,
        url: `?${IDP_HINT_QUERY_PARAM}`,
        params: {
            idp: undefined,
        },
        component: 'appRoot',
        data: {
            permission: PERMISSION.SHARED.EMPLOYEE_VIEW_ANY,
        },
    });

    // Enable / Disable debug info in DOM depending on environment. Disabling it brings a performance boost.
    $compileProvider.debugInfoEnabled(ENVIRONMENT.DEBUG_INFO_ENABLED);

    // Disabled HTML compiler features that work with comments (performance boost)
    $compileProvider.commentDirectivesEnabled(false);

    // Disable CSS class directives (performance boost)
    $compileProvider.cssClassDirectivesEnabled(false);
}
