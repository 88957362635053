/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameter, TarifParameterHistoryEntry} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifParameterHistoryEntry: '=',
        tarifParameter: '=',
        relevantParameterIds: '<',
        onUpdate: '&',
        onDelete: '&',
    },
    template: require('./dvb-kind-tarife-parameter-content.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeParameterContent implements angular.IController {
    public static $inject: readonly string[] = [];

    public tarifParameterHistoryEntry!: TarifParameterHistoryEntry;
    public tarifParameter!: TarifParameter;
    public relevantParameterIds: string[] = [];

    public onUpdate!: FunctionType;
    public onDelete!: FunctionType;

    public editMode: boolean = false;

    public update(entry: TarifParameterHistoryEntry): void {
        this.editMode = false;
        this.onUpdate({tarifParameterHistoryEntry: entry});
    }
}

componentConfig.controller = DvbKindTarifeParameterContent;
angular.module('kitAdmin').component('dvbKindTarifeParameterContent', componentConfig);
