/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktionId, Termin} from '@dv/kitadmin/models';
import {ZeitraumUtil} from '@dv/kitadmin/models';
import type {DayOfWeek, IZeitraum, Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import type {KinderOrtZeitraumFilterModel, ZeitraumIdsByFraktion} from './KinderOrtZeitraumFilterModel';
import type {KinderOrtZeitraumMap} from './ZeitraumFilterController';

export type ZeitraumFilterConfig = {
    visibleWhenEmptyUserModel: boolean;
};

export class ZeitraumFilter<T extends IZeitraum> {

    public relevantFraktionIds: KinderOrtFraktionId[] = [];

    public constructor(
        private readonly $q: angular.IQService,
        private readonly zeitraumResolver: (id: string) => angular.IPromise<Persisted<T>>,
    ) {
    }

    private static getZeitraumIds(object: any, fraktionId: string, dayOfWeek: string): string[] {
        if (!(fraktionId in object)) {
            return [];
        }

        if (!(dayOfWeek in object[fraktionId])) {
            return [];
        }

        return object[fraktionId][dayOfWeek];
    }

    public isFilterActive(filterModel: KinderOrtZeitraumMap<T>): boolean {
        return DvbUtil.isNotEmptyArray(this.getRelevantKinderOrtProp(filterModel)?.selectedWeekDays);
    }

    public clearRelevant(filterModel: KinderOrtZeitraumMap<T>): void {
        const relevant = this.getRelevantKinderOrtProp(filterModel);
        if (relevant) {
            this.clear(relevant);
        }
    }

    public clear(filterModel: KinderOrtZeitraumFilterModel<T>): void {
        filterModel.selectedWeekDays = [];
        filterModel.fraktionProps = {};
        filterModel.zeitraeume = {
            MONDAY: [],
            TUESDAY: [],
            WEDNESDAY: [],
            THURSDAY: [],
            FRIDAY: [],
            SATURDAY: [],
            SUNDAY: [],
        };
    }

    public filterRelevantByKinderOrteAndFraktionen(
        filterModel: KinderOrtZeitraumMap<T>,
        userModel: ZeitraumIdsByFraktion,
        config: ZeitraumFilterConfig,
    ): angular.IPromise<boolean> {

        if (Object.keys(userModel).length === 0 && config.visibleWhenEmptyUserModel) {
            return this.$q.resolve(true);
        }

        const kinderOrtProp = this.getRelevantKinderOrtProp(filterModel);
        if (!kinderOrtProp) {
            return this.$q.resolve(true);
        }

        return this.filterByKinderOrteAndFraktionen(kinderOrtProp, userModel);
    }

    public hasTermin(
        filterModel: KinderOrtZeitraumMap<T>,
        userModel: Termin[],
        firstOfWeek: moment.Moment,
    ): boolean {

        const kinderOrtProp = this.getRelevantKinderOrtProp(filterModel);
        if (!kinderOrtProp) {
            return false;
        }

        if (this.hasAbsenzForSelectedKinderOrtDienste(kinderOrtProp, firstOfWeek, userModel)) {
            return true;
        }

        return this.hasAbsenzForSelectedFraktionDienste(kinderOrtProp, firstOfWeek, userModel);
    }

    private hasAbsenzForSelectedFraktionDienste(
        kinderOrtProp: KinderOrtZeitraumFilterModel<T>,
        firstOfWeek: moment.Moment,
        userModel: Termin[],
    ): boolean {

        let fraktionDiensteSelected = false;

        const absenzForFraktionen = DvbUtil.keys(kinderOrtProp.fraktionProps).every(fraktionId =>
            DvbUtil.keys(kinderOrtProp.fraktionProps[fraktionId])
                .filter(dayOfWeek => DvbUtil.isNotEmptyArray(kinderOrtProp.fraktionProps[fraktionId]![dayOfWeek]))
                .every(dayOfWeek => {
                    fraktionDiensteSelected = true;
                    const zeitraeume: T[] = kinderOrtProp.fraktionProps[fraktionId]![dayOfWeek];

                    return this.hasAbsenzForDayOfWeekAndAllZeitraeume(dayOfWeek, firstOfWeek, zeitraeume, userModel);
                }),
        );

        return fraktionDiensteSelected && absenzForFraktionen;
    }

    private hasAbsenzForSelectedKinderOrtDienste(
        kinderOrtProp: KinderOrtZeitraumFilterModel<T>,
        firstOfWeek: moment.Moment,
        userModel: Termin[],
    ): boolean {

        let kinderOrtDiensteSelected = false;
        const absenzForKinderOrtDienst = DvbUtil.keys(kinderOrtProp.zeitraeume)
            .filter(dayOfWeek => DvbUtil.isNotEmptyArray(kinderOrtProp.zeitraeume[dayOfWeek]))
            .every(dayOfWeek => {
                kinderOrtDiensteSelected = true;
                const zeitraeume = kinderOrtProp.zeitraeume[dayOfWeek]!;

                return this.hasAbsenzForDayOfWeekAndAllZeitraeume(dayOfWeek, firstOfWeek, zeitraeume, userModel);
            });

        return kinderOrtDiensteSelected && absenzForKinderOrtDienst;
    }

    private hasAbsenzForDayOfWeekAndAllZeitraeume(
        dayOfWeek: DayOfWeek,
        firstOfWeek: moment.Moment,
        filterZeitraeume: T[],
        userModel: Termin[],
    ): boolean {

        const date = DvbDateUtil.getDayOfWeekMoment(dayOfWeek, firstOfWeek);
        const absenzenForDate = DvbDateUtil.getEntitiesIn(userModel, date, date);
        if (absenzenForDate.length === 0) {
            return false;
        }

        return filterZeitraeume
            .every(zeitraum => absenzenForDate.some(absenz => absenz.affectsZeitraum(zeitraum, date)));
    }

    private filterByKinderOrteAndFraktionen(
        filterModel: KinderOrtZeitraumFilterModel<T>,
        userModel: ZeitraumIdsByFraktion,
    ): angular.IPromise<boolean> {

        const promises = this.filterByKinderOrtZeitraeume(filterModel, userModel)
            .concat(this.filterByFraktionZeitraeume(filterModel, userModel));

        return this.$q.all(promises)
            .then(() => true)
            .catch(() => this.$q.reject(false));
    }

    private filterByKinderOrtZeitraeume(
        filterModel: KinderOrtZeitraumFilterModel<T>,
        userModel: ZeitraumIdsByFraktion,
    ): angular.IPromise<boolean>[] {

        const userModelFraktionIds = Object.keys(userModel)
            .filter(id => this.relevantFraktionIds.length === 0 || this.relevantFraktionIds.includes(id));

        return DvbUtil.keys(filterModel.zeitraeume)
            .filter(dayOfWeek => DvbUtil.isNotEmptyArray(filterModel.zeitraeume[dayOfWeek]))
            .map(dayOfWeek => {
                const candidateZeitraeumeIds: string[] = [];

                userModelFraktionIds
                    .filter(fraktionId => DvbUtil.isNotEmptyArray(userModel[fraktionId][dayOfWeek]))
                    .forEach(fraktionId => {
                        const ids = userModel[fraktionId][dayOfWeek]!;
                        candidateZeitraeumeIds.push(...ids);
                    });

                return this.fetchZeitraeume(candidateZeitraeumeIds).then(candidateZeitraeume => {
                    if (this.someZeitraumMatches(filterModel.zeitraeume[dayOfWeek]!, candidateZeitraeume)) {
                        return true;
                    }

                    return this.$q.reject(false);
                });
            });
    }

    private filterByFraktionZeitraeume(
        filterModel: KinderOrtZeitraumFilterModel<T>,
        userModel: ZeitraumIdsByFraktion,
    ): angular.IPromise<boolean>[] {

        const filterFraktionIds = Object.keys(filterModel.fraktionProps)
            .filter(id => !DvbUtil.isNotEmptyArray(this.relevantFraktionIds)
                || this.relevantFraktionIds.includes(id) && filterModel.fraktionProps[id]);

        const promises = [];
        for (let i = 0, len = filterFraktionIds.length; i < len; i++) {
            const fraktionId = filterFraktionIds[i];
            const fraktionZeitraum = filterModel.fraktionProps[fraktionId]!;
            const weekdays = DvbUtil.keys(fraktionZeitraum);

            for (let j = 0, lenWeekDays = weekdays.length; j < lenWeekDays; j++) {
                const dayOfWeek = weekdays[j];
                const zeitraeumeOfDay = fraktionZeitraum[dayOfWeek];
                if (!DvbUtil.isNotEmptyArray(zeitraeumeOfDay)) {
                    // keine Zeitraeume an diesem Wochentang im Filter definiert -> weiter suchen
                    continue;
                }

                const zeitraeumeIds = ZeitraumFilter.getZeitraumIds(userModel, fraktionId, dayOfWeek);
                if (zeitraeumeIds.length === 0) {
                    promises.push(this.$q.reject(false));
                }
                const promise = this.compareZeitraeume(zeitraeumeOfDay, zeitraeumeIds)
                    .then(result => {
                        if (!result) {
                            return this.$q.reject(false);
                        }

                        return true;
                    });
                promises.push(promise);
            }
        }

        return promises;
    }

    private getRelevantKinderOrtProp(filterModel: KinderOrtZeitraumMap<T>)
        : KinderOrtZeitraumFilterModel<T> | undefined {
        return filterModel.kinderOrtProps[filterModel.relevant];
    }

    private compareZeitraeume(zeitraeume: T[], zeitraumCandidateIds: string[]): angular.IPromise<boolean> {
        return this.fetchZeitraeume(zeitraumCandidateIds)
            .then(zeitraumCandiates => this.allZeitraeumeMatch(zeitraeume, zeitraumCandiates));
    }

    private someZeitraumMatches(filterZeitraeume: T[], candidateZeitraeume: T[]): boolean {
        return filterZeitraeume.some(fz => this.hasZeitraumOverlap(fz, candidateZeitraeume));
    }

    private allZeitraeumeMatch(filterZeitraeume: T[], candidateZeitraeume: T[]): boolean {
        return filterZeitraeume.every(fz => this.hasZeitraumOverlap(fz, candidateZeitraeume));
    }

    private hasZeitraumOverlap(filterZeitraum: T, candidateZeitraeume: T[]): boolean {
        return candidateZeitraeume.some(cz => ZeitraumUtil.hasZeitraeumeOverlapping(filterZeitraum, cz));
    }

    private fetchZeitraeume(ids: string[]): angular.IPromise<T[]> {
        const unique = DvbUtil.uniqueArray(ids);

        return this.$q.all(unique.map(id => this.zeitraumResolver(id)));
    }
}
