/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Betreuungsfaktor} from '@dv/kitadmin/models';
import type {IChangesObject} from 'angular';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        faktor: '<',
        showKinderOrte: '<?',
    },
    template: require('./dvb-betreuungsfaktor-icon.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsfaktorIcon implements angular.IController {
    public static $inject: readonly string[] = [];

    public faktor!: Betreuungsfaktor;
    public showKinderOrte: boolean = false;

    public displayText: string = '';
    public tooltipValue: string = '';

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const faktorChange: IChangesObject<Betreuungsfaktor> = onChangesObj.faktor;
        const showKinderOrteChange: IChangesObject<boolean> = onChangesObj.showKinderOrte;

        if (faktorChange) {
            const faktor = faktorChange.currentValue;
            this.formatFaktor(faktor.betreuungsfaktor);
        }

        if (faktorChange || showKinderOrteChange) {
            this.getTooltipValue();
        }
    }

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    private formatFaktor(faktor: number): void {
        if (faktor >= 99.5) {
            this.displayText = '>';

            return;
        }

        if (faktor >= 9.995) {
            this.displayText = String(Math.floor(faktor));

            return;
        }

        if (faktor < 1 && (faktor * 100) % 10 > 0) {
            this.displayText = `.${Math.floor(faktor * 100)}`;

            return;
        }

        this.displayText = String(faktor);
    }

    private getTooltipValue(): void {
        if (this.showKinderOrte) {
            const kinderOrte = this.faktor.kitas.map(kinderOrt => kinderOrt.getDisplayName()).join(', ');
            this.tooltipValue = `${this.faktor.betreuungsfaktor} - ${kinderOrte}`;
        } else {
            this.tooltipValue = String(this.faktor.betreuungsfaktor);
        }
    }

    /* eslint-enable @typescript-eslint/no-magic-numbers */
}

componentConfig.controller = DvbBetreuungsfaktorIcon;
angular.module('kitAdmin').component('dvbBetreuungsfaktorIcon', componentConfig);
