/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {DateLimitation, FormContext, Persisted} from '@dv/shared/code';
import {checkPersisted, isPersisted, LogFactory} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {finalize, firstValueFrom, take} from 'rxjs';
import {directiveSubSink} from '../../../../common/directive/directive-scope-subsink';
import type {AnwesenheitsSollService} from '../../anwesenheits-soll.service';
import {AnwesenheitsSoll} from '../../model/AnwesenheitsSoll';

const LOG = LogFactory.createLog('AnwesenheitsSollComponent');

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
    },
    template: require('./dvb-anwesenheits-soll.html'),
    controllerAs: 'vm',
};

export class DvbAnwesenheitsSoll {
    public static $inject: readonly string[] = ['$scope', 'anwesenheitsSollService', 'authStore', '$state'];

    public kind!: Persisted<Kind>;

    public anwesenheitsSoll: AnwesenheitsSoll[] = [];
    public newAnwesenheitsSoll: AnwesenheitsSoll = new AnwesenheitsSoll();
    public dateLimitation: DateLimitation = 'endOfMonth';

    public enableEditMode: boolean = false;
    public isLoading: boolean = false;
    public formContext?: FormContext;

    public constructor(
        private readonly $scope: angular.IScope,
        private readonly anwesenheitsSollService: AnwesenheitsSollService,
        private readonly authStore: AuthStore,
        private readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        this.isLoading = true;
        const subSink = directiveSubSink(this.$scope);
        subSink.sink = this.anwesenheitsSollService.getForKind$(this.kind.id)
            .pipe(
                finalize(() => this.isLoading = false),
            )
            .subscribe({
                next: soll => this.anwesenheitsSoll = soll,
                error: err => LOG.error(`could not get AnwesenheitsSoll for Kind ${this.kind.id}`, err),
            });

        this.enableEditMode = this.authStore.hasPermission(PERMISSION.KITA.MANAGE_ANY);
    }

    public save(soll: AnwesenheitsSoll, context?: FormContext): void {
        this.isLoading = true;
        context?.startLoading();

        const observable$: Observable<unknown> = isPersisted(soll) ?
            this.anwesenheitsSollService.update$(soll) :
            this.anwesenheitsSollService.create$(this.kind.id, soll);
        observable$.pipe(
            take(1),
            finalize(() => {
                this.isLoading = false;
            }),
        ).subscribe({
            next: () => this.$state.reload(),
            error: err => LOG.error('failed to save AnwesenheitsSoll', err),
            complete: () => context?.close(),
        });
    }

    public remove(soll: AnwesenheitsSoll): Promise<unknown> {
        this.isLoading = true;

        return firstValueFrom(this.anwesenheitsSollService.delete$(checkPersisted(soll)))
            .then(() => {
                this.$state.reload();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbAnwesenheitsSoll;
angular.module('kitAdmin').component('dvbAnwesenheitsSoll', componentConfig);
