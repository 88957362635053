import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {UpgradeModule} from '@angular/upgrade/static';
import {AnalyticsService, bootstrapAngularJsApp} from '@dv/kit-admin/lib';
import {setTheme} from 'ngx-bootstrap/utils';

@Component({
    selector: 'dv-app',
    standalone: true,
    imports: [],
    template: '', // all content is loaded via angularjs ui-view
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

    public constructor(
        private readonly upgrade: UpgradeModule,
        private readonly injector: Injector,
    ) {
    }

    public ngOnInit(): void {
        bootstrapAngularJsApp(this.upgrade);
        setTheme('bs3');

        // inject analyticsService here instead of in constructor, because it needs access to angularjs services, which
        // are only available after the above upgrade bootstrapping.
        const analyticsService = this.injector.get(AnalyticsService);
        analyticsService.init();
    }
}
