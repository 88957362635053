/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung, RechnungsStatus} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted, RechnungsRevisionError} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        mode: '<',
        freshStatus: '<?',
        freshStatusLoading: '<?',
        selection: '<?',
        errors: '<?',
        showVersandartBadge: '<?',
    },
    template: require('./dvb-rechnung-heading.html'),
    controllerAs: 'vm',
};

export class DvbRechnungHeading {
    public static $inject: readonly string[] = [];

    public rechnung!: Persisted<Rechnung>[];
    public mode!: RechnungDisplayMode;
    public freshStatus?: RechnungsStatus;
    public freshStatusLoading?: boolean;
    public selection?: { [rechnungId: string]: boolean };
    public errors: RechnungsRevisionError[] = [];
    public showVersandartBadge?: boolean;

    public displayModes = RechnungDisplayMode.modes;
}

componentConfig.controller = DvbRechnungHeading;
angular.module('kitAdmin').component('dvbRechnungHeading', componentConfig);
