/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import {Kita, TagesEltern} from '@dv/kitadmin/models';
import type {Persisted, SearchResultEntry} from '@dv/shared/code';
import {checkPersisted, DvbUtil, isPersisted} from '@dv/shared/code';
import angular from 'angular';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import {EmailTemplate} from '../../models/EmailTemplate';
import type {EmailTemplateService} from '../../service/email-template-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        emailTemplate: '<?',
    },
    template: require('./dvb-email-template-form.html'),
    controllerAs: 'vm',
};

export class DvbEmailTemplateForm implements angular.IController {
    public static $inject: readonly string[] = [
        'emailTemplateService',
        'dvbStateService',
        'errorService',
    ];

    public emailTemplate: EmailTemplate = new EmailTemplate();
    public isLoading: boolean = false;
    public disableAlleKinderOrte: boolean = false;

    public constructor(
        private emailTemplateService: EmailTemplateService,
        private dvbStateService: DvbStateService,
        private errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        if (!this.emailTemplate) {
            this.emailTemplate = new EmailTemplate();
        }

        this.disableAlleKinderOrte = !this.emailTemplateService.allowAlleKinderOrte(this.emailTemplate);
    }

    public submit(form: angular.IFormController): void {
        const isValid = form.$valid && DvbUtil.isNotEmptyString(this.emailTemplate.content);
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const isKinderOrtValid = this.emailTemplate.alleKinderOrte || this.emailTemplate.kinderOrte.length > 0;
        this.errorService.handleValidationError(isKinderOrtValid, 'ERRORS.ERR_REQUIRED_KITA');

        if (!isValid || !isKinderOrtValid) {
            return;
        }
        const promise = isPersisted(this.emailTemplate) ?
            this.emailTemplateService.update(this.emailTemplate) :
            this.emailTemplateService.create(this.emailTemplate);

        promise.then(() => this.goBack());

    }

    public goBack(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    public addKinderOrt(item: SearchResultEntry): void {
        this.emailTemplate.kinderOrte.push(this.createKinderOrt(item));
    }

    public removeKinderOrt(kinderOrt: KinderOrt): void {
        DvbUtil.removeFromArray(kinderOrt, this.emailTemplate.kinderOrte);
    }

    private createKinderOrt(item: SearchResultEntry): Persisted<KinderOrt> {
        const kinderOrt = item.entity === 'TAGES_ELTERN' ? new TagesEltern() : new Kita();

        kinderOrt.name = item.text;
        kinderOrt.id = item.id;

        return checkPersisted(kinderOrt);
    }
}

componentConfig.controller = DvbEmailTemplateForm;
angular.module('kitAdmin').component('dvbEmailTemplateForm', componentConfig);
