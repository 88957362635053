<div class="dvb-mandanten-overview row">
    <div class="col-md-7 big-abstand-bottom">
        <h2 data-translate="ADMINISTRATION.MANDANTEN"></h2>

        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       ng-model="vm.searchText"
                       ng-change="vm.filterMandanten()"
                       ng-attr-placeholder="{{'ADMINISTRATION.FILTER_BY_NAME' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.FILTER_BY_NAME' | translate}}"
                       tooltip-placement="top-left"
                       class="form-control">
            </div>
        </div>

        <div class="big-abstand-bottom" dvb-accordions>
            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading"></dvlib-spinner>

            <dvb-accordion is-first="$first"
                           ng-repeat="mandant in vm.filteredMandanten | limitTo: vm.maxNumberPerPage : ((vm.currentPage - 1) * vm.maxNumberPerPage)">
                <panel-header class="full-width-block">
                    <div class="layout-row items-center row">
                        <div class="col-xs-3">
                            <span ng-bind="mandant.name"></span>
                        </div>
                        <div class="col-xs-2">
                            <span data-translate="{{'MANDANT.SUBSCRIPTION_MODEL.' + mandant.subscriptionModel}}"></span>
                        </div>
                        <div class="col-xs-3 text-nowrap">
                            <span ng-bind="mandant.timestampErstellt | amDateFormat:'D.M.YYYY HH:mm:ss'"></span>
                        </div>
                        <div class="col-xs-4 text-right text-nowrap">
                            <span ng-bind="vm.getAttributes(mandant)"></span>
                        </div>
                    </div>
                </panel-header>
                <panel-content>
                    <dvb-mandant-profil mandant="mandant"></dvb-mandant-profil>

                    <div class="big-abstand-top">
                        <dvb-mandant-rechnung-config config="mandant.mandantRechnungConfig"
                                                     mandant="mandant">
                        </dvb-mandant-rechnung-config>
                    </div>
                </panel-content>
            </dvb-accordion>

            <ul uib-pagination
                ng-if="!vm.isLoading && vm.filteredMandanten.length > vm.maxNumberPerPage"
                class="pagination-sm normal-abstand-top"
                total-items="vm.filteredMandanten.length"
                items-per-page="vm.maxNumberPerPage"
                max-size="7"
                boundary-links="true"
                force-ellipses="true"
                previous-text="&#xf104;"
                next-text="&#xf105;"
                first-text="&#xf048;"
                last-text="&#xf051;"
                ng-model="vm.currentPage">
            </ul>
        </div>
    </div>
    <div class="col-md-4 col-md-offset-1">
        <div>
            <h2 data-translate="COMMON.AKTIONEN"></h2>
        </div>
        <p>
            <a href="" ng-click="vm.getCSVExport()" ng-disabled="vm.isDownloading">
                <span data-translate="DATA-INTEGRITY.EMAIL_EXPORT_DOWNLOAD"></span>
                <dvlib-spinner [show]="vm.isDownloading"></dvlib-spinner>
            </a>
        </p>

        <p ng-if="vm.showNoDataAvailable" data-translate="ERRORS.ERR_KEINE_BENUTZER"></p>
    </div>
</div>
