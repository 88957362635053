<div class="dvb-filter-main">
    <div class="filter-bar big-abstand-bottom">
        <div class="row">
            <div class="col-md-9">
                <dvb-filter-list filter="vm.defaultFilter"></dvb-filter-list>

                <div>
                    <dvb-filter-list filter="vm.toggleFilter" option-filter="'sticky'"></dvb-filter-list>
                </div>
            </div>
            <div class="col-md-3 text-right">
                <dvb-filter-toggler collapsible="vm.isCollapsible()"
                                    is-collapsed="vm.filterCollapsed"
                                    show-reset="vm.isFilterSet()"
                                    on-reset="vm.reset()"
                                    on-toggle="vm.filterCollapsed = !vm.filterCollapsed">
                </dvb-filter-toggler>
            </div>
        </div>
    </div>

    <div class="hidden-print" uib-collapse="vm.filterCollapsed">
        <div class="row">
            <div class="col-md-12">
                <dvb-filter-list filter="vm.toggleFilter"
                                 option-filter="'nonSticky'">
                </dvb-filter-list>
            </div>
        </div>
    </div>
</div>
