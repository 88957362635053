/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsStatusType} from '@dv/kitadmin/models';
import {RechnungsStatus} from '@dv/kitadmin/models';
import type {IRestModel, MatrixParams} from '@dv/shared/code';
import {DvbDateUtil, DvbUriUtil, DvbUtil, Gueltigkeit} from '@dv/shared/code';
import {FilterOption} from './shared/FilterOption';

export class RechnungenFilter implements IRestModel {

    public constructor(
        public gueltigkeit: Gueltigkeit = new Gueltigkeit(),
        public states: { [k in RechnungsStatusType]?: boolean } = {},
        public kitas: FilterOption[] = [],
        public kontaktpersonen: FilterOption[] = [],
        public abgelaufen: boolean = false,
    ) {
    }

    public static fromUriParam(params: string): RechnungenFilter {
        const obj = DvbUriUtil.applyUriToObject<any>(params, {});
        const rechnungenFilter = new RechnungenFilter();

        rechnungenFilter.gueltigkeit = Gueltigkeit.fromUriParam(obj.gueltigkeit);
        rechnungenFilter.states = DvbUriUtil.paramToBooleanMap(obj.states);
        rechnungenFilter.abgelaufen = obj.abgelaufen;

        rechnungenFilter.kitas = obj.kitas ?
            DvbUriUtil.paramToArray(obj.kitas).map(o => FilterOption.fromUriParam(o)) :
            [];

        rechnungenFilter.kontaktpersonen = obj.kontaktpersonen ?
            DvbUriUtil.paramToArray(obj.kontaktpersonen).map(o => FilterOption.fromUriParam(o)) :
            [];

        return rechnungenFilter;
    }

    /**
     * @param includeGueltigkeit falls TRUE, wird auch die Gueltigkeit berücksichtigt
     */
    public isFiltering(includeGueltigkeit: boolean = false): boolean {
        if (this.abgelaufen) {
            return true;
        }

        if (includeGueltigkeit) {
            if (DvbDateUtil.isValidMoment(this.gueltigkeit.gueltigAb)) {
                return true;
            }

            if (DvbDateUtil.isValidMoment(this.gueltigkeit.gueltigAb)) {
                return true;
            }
        }

        if (this.getRequestedStates().length > 0) {
            return true;
        }

        if (FilterOption.getSelectedIds(this.kontaktpersonen).length > 0) {
            return true;
        }

        return FilterOption.getSelectedIds(this.kitas).length > 0;
    }

    /**
     * @param includeGueltigkeit falls TRUE, wird auch die Gueltigkeit resettet
     */
    public reset(includeGueltigkeit: boolean = false): void {
        if (includeGueltigkeit) {
            this.gueltigkeit.gueltigAb = null;
            this.gueltigkeit.gueltigBis = null;
        }

        this.states = {};
        FilterOption.unselect(this.kitas);
        FilterOption.unselect(this.kontaktpersonen);
    }

    public getRequestedStates(): RechnungsStatusType[] {
        const requestedStates: RechnungsStatusType[] = [];

        DvbUtil.keys(this.states)
            .filter(filterState => this.states[filterState])
            .forEach(filterState => {
                RechnungsStatus.getExternalStates(filterState).forEach(extStates => requestedStates.push(extStates));
            });

        return DvbUtil.uniqueArray(requestedStates);
    }

    public toRestObject(): MatrixParams {
        const result = this.gueltigkeit.toRestObject();

        if (this.kitas.length > 0) {
            result.kitaIds = FilterOption.getSelectedIds(this.kitas);
        }

        if (this.kontaktpersonen.length > 0) {
            result.kontaktpersonenIds = FilterOption.getSelectedIds(this.kontaktpersonen);
        }

        if (Object.keys(this.states).length > 0) {
            result.states = this.getRequestedStates();
        }

        result.abgelaufen = this.abgelaufen;

        return result;
    }

    public toUriParam(): string {
        const kitas = DvbUriUtil.arrayToUriParam(this.kitas.map(o => o.toUriParam()));
        const kontaktpersonen = DvbUriUtil.arrayToUriParam(this.kontaktpersonen.map(o => o.toUriParam()));

        const data: any = {
            gueltigkeit: this.gueltigkeit.toUriParam(),
            states: DvbUriUtil.booleanMapToUriParam(this.states),
            abgelaufen: this.abgelaufen,
        };

        // not adding kitas when empty to avoid polluting the param string
        if (kitas.length > 0) {
            data.kitas = kitas;
        }

        // not adding kontaktpersonen when empty to avoid polluting the param string
        if (kontaktpersonen.length > 0) {
            data.kontaktpersonen = kontaktpersonen;
        }

        return DvbUriUtil.plainObjectToUriParam(data);
    }
}
