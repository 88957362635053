/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout, KinderOrt, Kontaktperson} from '@dv/kitadmin/models';
import type {LoadingContext} from '@dv/shared/code';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {SteuerbescheinigungStructure} from '../../../report/models';
import {ParameterSteuerbescheinigungen} from '../../../report/models';
import type {ReportService} from '../../../report/service/reportService';
import type {SteuerbescheinigungenState} from '../../models/steuerbescheinigungen/SteuerbescheinigungenState';
import type {SteuerbescheinigungenService} from '../../service/steuerbescheinigungen-service';

const componentConfig: angular.IComponentOptions = {
    require: {dvbDownloadCtrl: '^dvbDownload'},
    transclude: false,
    bindings: {
        kinderOrt: '<',
        gueltigAb: '<',
        dokumentLayout: '<',
        structure: '<',
        steuerbescheinigungState: '<',
    },
    template: require('./dvb-steuerbescheinigung-management.html'),
    controllerAs: 'vm',
};

export class DvbSteuerbescheinigungManagement implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        '$state',
        'dvbStateService',
        'steuerbescheinigungenService',
        'reportService',
    ];

    public kinderOrt!: KinderOrt;
    public dokumentLayout: DokumentLayout | null = null;
    public structure: SteuerbescheinigungStructure = 'MONTHLY';
    public gueltigAb!: moment.Moment;
    public preSelection!: Kontaktperson[];
    public steuerbescheinigungState!: SteuerbescheinigungenState;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private $state: StateService,
        private dvbStateService: DvbStateService,
        private steuerbescheinigungenService: SteuerbescheinigungenService,
        private reportService: ReportService,
    ) {
    }

    public release(context: LoadingContext, selected: Kontaktperson[]): void {
        context.startLoading();

        this.steuerbescheinigungenService.sendSteuerbescheinigung(
            this.dokumentLayout,
            this.kinderOrt,
            this.gueltigAb,
            selected,
            this.structure)
            .then(() => this.$state.reload())
            .finally(() => context.finishLoading());
    }

    public download(kontaktpersonen: Kontaktperson[]): angular.IPromise<unknown> {

        const params = new ParameterSteuerbescheinigungen(
            kontaktpersonen.map(DvbUtil.mapToIdChecked),
            checkPresent(this.kinderOrt.id),
            this.dokumentLayout ? checkPresent(this.dokumentLayout.id) : null,
            this.gueltigAb.year(),
            this.structure,
        );

        return this.reportService.getTempBlobForSteuerbescheinigungen(params)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob));
    }

    public goBack(): void {
        this.dvbStateService.goToPreviousState();
    }

    public $onInit(): void {
        this.preSelection = this.steuerbescheinigungState.readyToSend.filter(e => e.versandartRechnung === 'EMAIL');
    }
}

componentConfig.controller = DvbSteuerbescheinigungManagement;
angular.module('kitAdmin').component('dvbSteuerbescheinigungManagement', componentConfig);
