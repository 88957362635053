/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TempBlob} from '@dv/kitadmin/models';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import {ERR_VALIDATION} from '../../models';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        onSubmit: '&',
    },
    template: require('./dvb-report-download.html'),
    controllerAs: 'vm',
};

export class DvbReportDownload implements angular.IController {
    public static $inject: readonly string[] = [
        '$q',
    ];

    public loadingReport: boolean = false;

    private onSubmit!: () => (angular.IPromise<TempBlob> | null);

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly $q: angular.IQService,
    ) {
    }

    public download(): void {
        const promise = this.onSubmit();

        if (!promise) {
            // validation failed, stop download
            return;
        }

        this.loadingReport = true;
        promise.then(tempBlob => {
            this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
        }).catch(error => {
            if (error === ERR_VALIDATION) {
                return this.$q.resolve();
            }

            return this.$q.reject(error);
        }).finally(() => {
            this.loadingReport = false;
        });
    }
}

componentConfig.controller = DvbReportDownload;
angular.module('kitAdmin').component('dvbReportDownload', componentConfig);
