/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Ng2StateDeclaration} from '@uirouter/angular';
import {BASE_STATE_NAME} from './schulung-states';

export const SCHULUNG_FEATURE = {
    name: `${BASE_STATE_NAME}.**`,
    url: '/schulung',
    loadChildren: () => import('./schulung.module').then(m => m.SchulungModule),
} satisfies Ng2StateDeclaration;
