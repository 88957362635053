/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import {BEGIN_OF_TIME, checkPresent, TypeUtil} from '@dv/shared/code';
import type angular from 'angular';
import moment from 'moment';
import {from, take} from 'rxjs';
import type {DialogService} from '../../dialog/dialog.service';
import type {IOnSelected} from './AccordionContext';
import {AccordionContext} from './AccordionContext';
import type {DvbAccordions} from './dvb-accordions';

const componentConfig: angular.IComponentOptions = {
    require: {dvbAccordions: '^dvbAccordions'},
    transclude: {
        header: 'panelHeader',
        content: '?panelContent',
        edit: '?editContent',
        footer: '?panelFooter',
    },
    bindings: {
        onSubmit: '&',
        onDelete: '&',
        onCancel: '&',
        onSelect: '&',
        isFirst: '<',
        enableContent: '<?',
        enableDelete: '<?',
        enableEdit: '<?',
        showContentDisabled: '<?',
        entityName: '<?',
        isSelected: '<?',
        removeToggledDom: '<?',
    },
    template: require('./dvb-accordion.html'),
    controllerAs: 'vm',
};

export class DvbAccordion implements angular.IController {
    public static $inject: readonly string[] = ['dialogService', '$transclude', '$attrs', '$window', '$q'];

    public onSubmit!: (data: { context: AccordionContext; form: angular.IFormController }) => void;
    public onDelete!: FunctionType;
    public onCancel!: FunctionType;
    public onSelect!: (info: IOnSelected) => void;

    public dvbAccordions!: DvbAccordions;
    // changes the styling to have a top border
    public isFirst!: boolean;
    // enableContent enables the content panel
    public enableContent?: boolean;
    // enables the delete button
    public enableDelete?: boolean;
    // enables the edit button
    public enableEdit?: boolean;
    // do or don't show the content panel
    public showContentDisabled?: boolean;
    // name of entity to display when invoking delete dialog
    public entityName?: string;
    // when changed to true, the accordion gets selected
    public isSelected?: boolean;
    // when true, the content of collapsed elements gets removed from the dom
    public removeToggledDom: boolean = false;

    public context: AccordionContext = new AccordionContext();
    public isDisabled: boolean = false;

    private windowBlurred: boolean = false;
    private lastSelectTime: moment.Moment = BEGIN_OF_TIME;

    private collapseOnBlurFunc: () => void = this.collapseOnBlur.bind(this);
    private restoreWindowBlurFunc: () => void = this.restoreWindowBlur.bind(this);

    public constructor(
        private readonly dialogService: DialogService,
        private readonly $transclude: angular.ITranscludeFunction,
        private readonly $attrs: angular.IAttributes,
        private readonly $window: angular.IWindowService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.context.onSelect = this.onSelect;
        this.dvbAccordions.register(this.context);
        this.enableContent ??= true;

        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });

        this.$window.addEventListener('blur', this.collapseOnBlurFunc);
        this.$window.addEventListener('focus', this.restoreWindowBlurFunc);
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.isSelected && !!changes.isSelected.currentValue && this.context.isCollapsed) {
            this.select();
        }
    }

    public $onDestroy(): void {
        this.dvbAccordions.unregister(this.context);
        this.$window.removeEventListener('blur', this.collapseOnBlurFunc);
        this.$window.removeEventListener('focus', this.restoreWindowBlurFunc);
    }

    public select(): void {
        if (this.windowBlurred) {
            // Window was blurred and just now focusud. We don't want to act on that event.
            this.windowBlurred = false;

            return;
        }

        if (!this.showContentDisabled && this.isDisabled) {
            return;
        }

        const minDelay = 300;

        // Debounce from click and focus event
        if (Math.abs(moment.duration(this.lastSelectTime.diff(moment())).asMilliseconds()) < minDelay) {
            return;
        }

        this.lastSelectTime = moment();
        this.dvbAccordions.select(this.context);
    }

    public submit(form: angular.IFormController): void {
        if (TypeUtil.isFunction(this.onSubmit)) {
            this.context.isLoading = true;
            this.onSubmit({context: this.context, form});
        }
    }

    public cancel(): void {
        this.context.isEditMode = false;
        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel({context: this.context});
        }
    }

    public deleteEntity(): void {
        this.dialogService.openDeleteDialog({
            entityText: checkPresent(this.entityName),
            confirm: () => from(this.$q.when(this.onDelete({context: this.context})))
                .pipe(take(1)),
        });
    }

    public isSlotFilled(name: string): boolean {
        return this.$transclude.isSlotFilled(name);
    }

    private collapseOnBlur(): void {
        this.windowBlurred = true;
    }

    private restoreWindowBlur(): void {
        const ms = 300;
        setTimeout(() => {
            this.windowBlurred = false;
        }, ms);
    }
}

componentConfig.controller = DvbAccordion;

export const dvbAccordionComponentConfig = componentConfig;
