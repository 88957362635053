<div>
    <ul class="uib-datepicker-popup dvb-calendar dropdown-menu uib-position-measure"
        style="display: block;"
        dropdown-nested
        ng-if="isOpen"
        ng-keydown="keydown($event)"
        ng-click="$event.stopPropagation()">
        <li ng-transclude></li>
        <li ng-if="showButtonBar" class="dvb-button-bar">
          <span class="btn-group pull-left">
            <button type="button"
                    class="btn btn-sm btn-info uib-datepicker-current"
                    ng-click="select('today', $event)"
                    ng-disabled="isDisabled('today')"
                    data-translate="COMMON.TODAY"></button>
          </span>
        </li>
    </ul>
</div>
