<div class="dvb-kita-betreuungsfaktor-regel-form dvb-form">

    <form name="kitaBetreuungsregelZuweisenForm"
          ng-submit="vm.submit(kitaBetreuungsregelZuweisenForm.$valid)"
          novalidate>

        <div class="row" ng-if="vm.showGueltigAb">
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.kitaBetreuungsfaktorRegel.gueltigAb"
                                           required
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-kita-betreuungsfaktor-regel-auswahl kita-betreuungsfaktor-regel="vm.kitaBetreuungsfaktorRegel">
        </dvb-kita-betreuungsfaktor-regel-auswahl>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   submit-label="KINDERORT.KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISEN"
                                   on-cancel="vm.cancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
