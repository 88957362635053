/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import angular from 'angular';
import type {ParameterKinderOrte, ReportState} from '../../models';
import {ERR_VALIDATION} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<',
    },
    template: require('./dvb-report-kontaktperson-zahlungen.html'),
    controllerAs: 'vm',
};

export class DvbReportKontaktpersonZahlungen implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public parameterKinderOrte?: ParameterKinderOrte;

    public includePayedRueckerstattungen: boolean = false;
    public kontaktpersonZahlungenReportForm?: angular.IFormController;
    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public updateState(): void {
        this.reportService.updateStateParams(this.parameterKinderOrte!);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.kontaktpersonZahlungenReportForm!.$valid
            && this.parameterKinderOrte!.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        const params = {
            reportTemplate: this.reportTemplate,
            includePayedRueckerstattungen: this.includePayedRueckerstattungen,
        };

        return this.reportService.getTempBlobForKontaktpersonenZahlungen(this.parameterKinderOrte!, params);
    }
}

componentConfig.controller = DvbReportKontaktpersonZahlungen;
angular.module('kitAdmin').component('dvbReportKontaktpersonZahlungen', componentConfig);
