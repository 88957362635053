<div class="dvb-tarif-erstellen row dvb-form">
    <div class="col-md-6 col-md-offset-3">
        <h1 data-translate="COMMON.TARIF.ERSTELLEN"></h1>

        <form name="neuerTarifForm" ng-submit="vm.validateAndSaveTarif()" novalidate>
            <div class="form-section-minus-normal-abstand">
                <div class="row normal-abstand-top">
                    <div class="col-md-12">
                        <h2 data-translate="TARIF.TARIF_TYPE_WAEHLEN"></h2>
                        <div class="radio" ng-repeat="type in vm.tariftypes">
                            <label>
                                <input type="radio"
                                       name="tarifType"
                                       value="{{type}}"
                                       ng-model="vm.tarif.dtype"
                                       ng-click="vm.valueChanged(type)">
                                <span data-translate="{{'TARIF.TYPE.' + type}}"></span>
                            </label>
                        </div>

                        <div class="checkbox normal-abstand-top">
                            <label>
                                <input type="checkbox"
                                       value="true"
                                       ng-model="vm.tarif.nullTarif">
                                <span data-translate="TARIF.NULL_TARIF"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row normal-abstand-top">
                    <div class="col-md-12">
                        <h2 data-translate="TARIF.NAME_EINGEBEN"></h2>
                        <input type="text"
                               name="name"
                               class="form-control"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               uib-tooltip="{{'COMMON.NAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.tarif.name"
                               required>
                    </div>
                </div>

                <div ng-if="!vm.tarif.nullTarif">
                    <div class="parameters normal-abstand-top">
                        <h2 data-translate="TARIF.TARIF_PARAMETER"></h2>
                        <div class="row" ng-repeat="parameter in vm.tarif.parameters">
                            <dvb-tarif-parameter-input tarif-parameter="parameter"
                                                       existing-tarif-parameters="vm.existingTarifParameters">
                            </dvb-tarif-parameter-input>

                            <div class="col-md-2">
                                <p class="form-linker">
                                    <a href="" ng-click="vm.removeParameter(parameter)"
                                       data-translate="COMMON.LOESCHEN"></a>
                                </p>
                            </div>
                        </div>

                        <div class="row form-row">
                            <div class="col-md-12">
                                <a href="" ng-click="vm.addParameter()">
                                    + <span data-translate="TARIF.PARAMETER_HINZUFUEGEN"></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col-md-12">
                            <h2 data-translate="TARIF.EXCEL_TEMPLATE_HERUNTERLADEN"></h2>
                            <a href=""
                               data-translate="TARIF.TARIF_DOWNLOAD_LABEL"
                               ng-click="vm.downloadTarifVorlage()">
                            </a>
                            <dvlib-spinner [show]="vm.loadingTemplate"></dvlib-spinner>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col-md-12">
                            <h2 data-translate="TARIF.EXCEL_DATEI_HOCHLADEN"></h2>
                            <dvb-file-upload label-upload="TARIF.TARIF_HOCHLADEN_LABEL"
                                             ng-model="vm.tarifValue.template"
                                             required>
                            </dvb-file-upload>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col-md-12">
                            <h2 data-translate="TARIF.OPTIONEN"></h2>
                            <dvb-tarif-value-manual-positions-total tarif-value="vm.tarifValue">
                            </dvb-tarif-value-manual-positions-total>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit"
                                                name="save"
                                                is-loading="vm.isLoading">
                                <span data-translate="COMMON.TARIF.ERSTELLEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
