/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isNullish} from '@dv/shared/code';
import type {HookMatchCriteria} from '@uirouter/core';
import {AUTHENTICATION_STATES} from '../../../authentication/authentication-states';
import {EXTERNAL_ANMELDUNG_STATE} from '../../../external/external-states';

export const ANY_STATE: HookMatchCriteria = {};

export const REQUIRE_NO_LOGIN = [
    ...Object.values(AUTHENTICATION_STATES).map(s => s.name),
    EXTERNAL_ANMELDUNG_STATE.name,
];

export const IS_REQUIRING_LOGIN: HookMatchCriteria = {
    to: (state): boolean => {
        if (isNullish(state)) {
            // app start. We first need to know the proper target state to evaluate authentication
            return false;
        }

        if (REQUIRE_NO_LOGIN.includes(state.name)) {
            // some states are public
            return false;
        }

        // lazy loaded states must bypass authentication checks in order to load the lazy loaded module
        return !state.name?.endsWith('.**');
    },
};

// when the app loads initially, e.g. when we are not yet on any state
export const IS_NO_STATE: HookMatchCriteria = {
    from: (state): boolean => !!state && !state.name,
};

export const HAS_TOKEN_LOGIN_PARAM: HookMatchCriteria = {
    to: (state): boolean => !!state && !!state.params.loginToken,
};
