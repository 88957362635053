/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BetreuungsGutschein, KiTaxVerfuegung} from '@dv/kitadmin/models';
import type {RestLimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class BetreuungsGutscheinService {

    public static $inject: readonly string[] = ['$http'];

    public static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/betreuungsgutscheine`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public deleteBetreuungsGutschein(betreuungsGutscheinId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BetreuungsGutscheinService.BASE_URL}/${encodeURIComponent(betreuungsGutscheinId)}`;

        return this.$http.delete(url);
    }

    public importFromKiTax(kindId: string, tempBlobId: string): angular.IPromise<KiTaxVerfuegung> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsGutscheinService.BASE_URL}/kitax/${encodeURIComponent(kindId)}/` +
            `${encodeURIComponent(tempBlobId)}`;

        return DvbRestUtilAngularJS.postAndGetModel(url, undefined, KiTaxVerfuegung);
    }

    public commitKiTaxImport(kindId: string, kinderOrtId: string, tempBlobId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsGutscheinService.BASE_URL}/kitax/commit/` +
            `${encodeURIComponent(kindId)}/${encodeURIComponent(kinderOrtId)}/${encodeURIComponent(tempBlobId)}`;

        return this.$http.post(url, {});
    }

    public findBetreuungsGutscheine(
        kindId: string,
        kinderOrtId: string,
        params?: RestLimited,
    ): angular.IPromise<BetreuungsGutschein[]> {
        const matrixParams = DvbRestUtil.setGueltigkeitParams({}, params);
        const url = `${BetreuungsGutscheinService.BASE_URL}/${encodeURIComponent(kindId)}/` +
            `${encodeURIComponent(kinderOrtId)}${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, BetreuungsGutschein, 'betreuungsGutscheine', {});
    }

    public deleteKiTaxVerfuegung(kiTaxId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BetreuungsGutscheinService.BASE_URL}/kitax/${encodeURIComponent(kiTaxId)}`;

        return this.$http.delete(url);
    }
}
