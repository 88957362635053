/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KitaBetreuungsfaktorRegel} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaBetreuungsfaktorRegel: '<',
        showGueltigAb: '<?',
        onSubmit: '&',
        onCancel: '&',
        isLoading: '<',
    },
    template: require('./dvb-kita-betreuungsfaktor-regel-form.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsfaktorRegelForm implements angular.IController {

    public static $inject: readonly string[] = ['errorService'];

    public kitaBetreuungsfaktorRegel!: KitaBetreuungsfaktorRegel;
    public showGueltigAb: boolean = false;
    public onSubmit!: (params: { kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel }) => unknown;
    public onCancel!: FunctionType;
    public isLoading: boolean = false;

    public constructor(private readonly errorService: ErrorService) {
    }

    public cancel(): void {
        this.errorService.clearAll();

        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }

    public submit(isValid: boolean): void {
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return;
        }

        if (TypeUtil.isFunction(this.onSubmit)) {
            this.onSubmit({kitaBetreuungsfaktorRegel: this.kitaBetreuungsfaktorRegel});
        }
    }
}

componentConfig.controller = DvbKitaBetreuungsfaktorRegelForm;
angular.module('kitAdmin').component('dvbKitaBetreuungsfaktorRegelForm', componentConfig);
