<div class="dvb-personalplanung-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.personalPlanungReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT"
                                      ng-change="vm.updateKinderOrt()"
                                      ng-model="vm.kinderOrt"
                                      ng-required="!vm.gruppeSearchResultEntry.id"
                                      ng-show="!vm.gruppeSearchResultEntry.id"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
        </dvb-search-get-single-entity>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT_FRAKTION"
                                      ng-model="vm.gruppeSearchResultEntry"
                                      ng-required="!vm.kinderOrt"
                                      ng-show="!vm.kinderOrt"
                                      placeholder-key="COMMON.GRUPPE.AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>


        <div class="row">
            <div ng-if="!vm.monthly" class="col-xs-12">
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.gueltigAb"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.gueltigBis"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
            <div ng-if="vm.monthly" class="col-xs-12">
                <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                           dvb-begin-of-month
                                           custom-options="{minMode: 'month'}"
                                           label-date-format="'MMMM YYYY'"
                                           format="'MMMM YYYY'"
                                           ng-attr-placeholder="{{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'MMMM YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div class="checkbox">
            <label>
                <input ng-model="vm.mitTermine"
                       type="checkbox">
                <span data-translate="REPORT.PERSONALPLANUNG.INCLUDE_TERMINE"></span>
            </label>
        </div>

        <div class="checkbox">
            <label>
                <input ng-model="vm.mitKinderOrtSchluessel"
                       type="checkbox">
                <span data-translate="REPORT.PERSONALPLANUNG.INCLUDE_KINDERORT_SCHLUESSEL"></span>
            </label>
        </div>

        <div class="checkbox">
            <label>
                <input ng-model="vm.mitTagesinfos"
                       type="checkbox">
                <span data-translate="REPORT.PERSONALPLANUNG.INCLUDE_TAGESINFOS"></span>
            </label>
        </div>

        <div ng-If="!vm.monthly" class="checkbox">
            <label>
                <input ng-model="vm.mitPausen"
                       type="checkbox">
                <span data-translate="REPORT.PERSONALPLANUNG.INCLUDE_PAUSEN"></span>
            </label>
        </div>

        <div class="normal-abstand-top">
            <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
        </div>
    </form>
</div>
