<div class="dvb-kind-kibon row">
    <div class="col-md-7">
        <div dvb-accordions allow-multiple="true" ng-if="vm.isShowingGenerationResult" class="big-abstand-bottom">
            <h2 data-translate="KIBON.MUTATION.GENERATION_RESULTS"></h2>

            <div ng-repeat="result in vm.generationResults"
                 ng-init="error = result.isFailed() ? result.toDvbError() : null">
                <dvb-accordion is-first="$first">
                    <panel-header class="full-width-block">
                        <div class="layout-row items-center row">
                            <div class="col-xs-4 col-xs-offset-5">
                               <span class="badge"
                                     ng-class="{'warning': error}"
                                     data-translate="KIBON.MUTATION.GENERTION_RESULT_MF"
                                     data-translate-value-success="{{!error}}"
                                     data-translate-interpolation="messageformat">
                               </span>
                            </div>
                            <div class="col-xs-3 text-right">
                                <span ng-bind="result.ctx.refNr"></span>
                            </div>
                        </div>
                    </panel-header>
                    <panel-content>
                        <dvb-kibon-betreuung-detail ng-if="!error"
                                                    betreuung="result.generatedBetreuung">
                        </dvb-kibon-betreuung-detail>

                        <dvlib-error ng-if="error" [error]="error"></dvlib-error>
                    </panel-content>
                </dvb-accordion>
            </div>
        </div>

        <dvb-kibon-betreuungen-kind kind="vm.kind" refresh="vm.isRefreshing"></dvb-kibon-betreuungen-kind>
    </div>
    <div class="col-md-4 col-md-offset-1 hidden-print" dvb-auth require-permission="kita:manage:general">
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <dvb-loading-button as-link="true"
                            is-loading="!!vm.isRefreshing"
                            class="floating-text"
                            on-click="vm.refresh()"
                            tooltip-placement="top-left"
                            type="button">
            <span data-translate="KIBON.MUTATION.NEU_GENERIEREN"></span>
        </dvb-loading-button>
        <dvb-loading-button as-link="true"
                            class="floating-text"
                            is-loading="vm.isLoadingKibonRefNr"
                            on-click="vm.manageIgnoredRefNumbers()"
                            tooltip-placement="top-left"
                            type="button">
            <span data-translate="KIBON.MUTATION.IGNORIERTE_REF_NR_VERWALTEN"></span>
        </dvb-loading-button>
    </div>

    <!-- This is a hidden toggle for the generation results -->
    <a accesskey="r" ng-click="vm.isShowingGenerationResult = !vm.isShowingGenerationResult"></a>
</div>
