/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktionId, KinderOrtId, Termin, ZeitraumFeld} from '@dv/kitadmin/models';
import type {DayOfWeek, IZeitraum} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {FilterModelController} from '../shared/FilterModelController';
import type {IsFilterActive} from '../shared/MicroFilterAttribute';
import {MicroFilterAttribute} from '../shared/MicroFilterAttribute';
import type {ZeitraumIdsByFraktion} from './KinderOrtZeitraumFilterModel';
import {KinderOrtZeitraumFilterModel} from './KinderOrtZeitraumFilterModel';
import type {ZeitraumFilter, ZeitraumFilterConfig} from './ZeitraumFilter';

export type KinderOrtZeitraumMap<T extends IZeitraum> = {
    relevant: KinderOrtId;
    kinderOrtProps: { [kinderOrtId: KinderOrtId]: KinderOrtZeitraumFilterModel<T> | undefined };
};

export class ZeitraumFilterController<T extends IZeitraum> extends FilterModelController
    <KinderOrtZeitraumMap<T>, ZeitraumIdsByFraktion> {

    public config: ZeitraumFilterConfig = {visibleWhenEmptyUserModel: false};

    public constructor(
        private zeitraumFilter: ZeitraumFilter<T>,
        initialValues: KinderOrtZeitraumMap<T> = {relevant: '', kinderOrtProps: {}},
        filter: IsFilterActive<KinderOrtZeitraumMap<T>> = model => zeitraumFilter.isFilterActive(model),
    ) {
        super(new MicroFilterAttribute(initialValues, filter));
    }

    public hasChanged(): void {
        this.notifyChangeListeners();
    }

    public setRelevantKinderOrtId(id?: KinderOrtId): void {
        this.model.filterModel.relevant = id ?? '';
        if (id) {
            this.getOrCreateKinderOrtProps(id);
        }
        this.isActive = this.model.isFilterSet();
    }

    public setRelevantFraktionIds(fraktionIds: KinderOrtFraktionId[]): void {
        this.zeitraumFilter.relevantFraktionIds = fraktionIds;
    }

    public updateZeitraumFeldFilter(
        kinderOrtId: KinderOrtId,
        fraktionId: KinderOrtFraktionId,
        zeitraumFeld: ZeitraumFeld<T>,
    ): void {
        this.withKinderOrt(kinderOrtId, model => {
            return zeitraumFeld.selected ?
                model.addFraktionZeitraum(fraktionId, zeitraumFeld.dayOfWeek, zeitraumFeld.zeitraum) :
                model.removeFraktionZeitraum(fraktionId, zeitraumFeld.dayOfWeek, zeitraumFeld.zeitraum);
        });
    }

    public updateKinderOrtZeitraumFeldFilter(kinderOrtId: KinderOrtId, zeitraumFeld: ZeitraumFeld<T>): void {
        this.withKinderOrt(kinderOrtId, model => {
            return zeitraumFeld.selected ?
                model.addKinderOrtZeitraum(zeitraumFeld.dayOfWeek, zeitraumFeld.zeitraum) :
                model.removeKinderOrtZeitraum(zeitraumFeld.dayOfWeek, zeitraumFeld.zeitraum);
        });
    }

    public initKinderOrtZeitraumFelder(kinderOrtId: KinderOrtId, zeitraumFelder: ZeitraumFeld[]): void {
        this.getOrCreateKinderOrtProps(kinderOrtId).initKinderOrtZeitraumFelder(zeitraumFelder);
    }

    public initFraktionZeitraumFelder(
        kinderOrtId: KinderOrtId,
        fraktionId: KinderOrtFraktionId,
        zeitraumFelder: ZeitraumFeld[],
    ): void {
        this.getOrCreateKinderOrtProps(kinderOrtId).initFraktionZeitraumFelder(fraktionId, zeitraumFelder);
    }

    public hasSelectedKinderOrtZeitraeume(kinderOrtId: KinderOrtId): boolean {
        return this.hasKinderOrtModel(kinderOrtId, model => model.hasSelectedKinderOrtZeitraeume());
    }

    public hasSelectedFraktionZeitraeume(kinderOrtId: KinderOrtId, fraktionId: KinderOrtFraktionId): boolean {
        return this.hasKinderOrtModel(kinderOrtId, model => model.hasSelectedFraktionZeitraeume(fraktionId));
    }

    public getFraktionZeitraeumeCopy(kinderOrtId: KinderOrtId, fraktionId: KinderOrtFraktionId, day: DayOfWeek): T[] {
        return this.when(kinderOrtId, [], model => model.getFraktionZeitraeumeCopy(fraktionId, day));
    }

    public isFilteredTag(kinderOrtId: KinderOrtId, dayOfWeek: DayOfWeek): boolean {
        return this.hasKinderOrtModel(kinderOrtId, model => model.getSelectedWeekDays().includes(dayOfWeek));
    }

    public reset(): void {
        this.zeitraumFilter.clearRelevant(this.model.filterModel);
        this.zeitraumFilter.relevantFraktionIds = [];
        this.isActive = this.model.isFilterSet();
        this.notifyChangeListeners();
    }

    public filterAcceptsUserModel(
        userModel: ZeitraumIdsByFraktion,
        config?: ZeitraumFilterConfig,
    ): angular.IPromise<boolean> {
        return this.zeitraumFilter.filterRelevantByKinderOrteAndFraktionen(this.model.filterModel,
            userModel,
            config ?? this.config);
    }

    public filterAcceptsTermine(
        userModel: Termin[],
        firstOfWeek: moment.Moment,
    ): boolean {
        return this.zeitraumFilter.hasTermin(this.model.filterModel, userModel, firstOfWeek);
    }

    /**
     * executes the function with KinderOrt specific KinderOrtZeitraumFilterModel - but only when it exists.
     * Expects the function to return TRUE, when the controller should call the "hasChanged" method.
     */
    private withKinderOrt(kinderOrtId: KinderOrtId, func: (model: KinderOrtZeitraumFilterModel<T>) => boolean): void {
        const model = this.model.filterModel.kinderOrtProps[kinderOrtId];
        if (!model) {
            return;
        }

        const hasChanged = func(model);

        if (hasChanged) {
            this.hasChanged();
        }
    }

    private hasKinderOrtModel(
        kinderOrtId: KinderOrtId,
        func: (model: KinderOrtZeitraumFilterModel<T>) => boolean,
    ): boolean {
        return this.when(kinderOrtId, false, func);
    }

    private when<TR>(
        kinderOrtId: KinderOrtId,
        defaultValue: TR,
        func: (model: KinderOrtZeitraumFilterModel<T>) => TR,
    ): TR {
        const model = this.model.filterModel.kinderOrtProps[kinderOrtId];
        if (!model) {
            return defaultValue;
        }

        return func(model);
    }

    private getOrCreateKinderOrtProps(kinderOrtId: KinderOrtId): KinderOrtZeitraumFilterModel<T> {
        this.model.filterModel.kinderOrtProps[kinderOrtId] ??= new KinderOrtZeitraumFilterModel(kinderOrtId);

        return this.model.filterModel.kinderOrtProps[kinderOrtId];
    }
}
