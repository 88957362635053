/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {Benutzer} from '../../../benutzer/model/Benutzer';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
    },
    template: require('./dvb-dashboard-empty.html'),
    controllerAs: 'vm',
};

export class DvbDashboardEmpty implements angular.IController {
    public static $inject: readonly string[] = [];
    public benutzer!: Benutzer;
}

componentConfig.controller = DvbDashboardEmpty;
angular.module('kitAdmin').component('dvbDashboardEmpty', componentConfig);
