/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {WochenKapazitaet} from '@dv/kitadmin/models';
import {DvbDateUtil, END_OF_TIME} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenKapazitaet: '<',
    },
    template: require('./dvb-kapazitaet-header.html'),
    controllerAs: 'vm',
};

export class DvbKapazitaetHeader implements angular.IController {
    public wochenKapazitaet!: WochenKapazitaet;

    public kapazitaetTitle: string | null = null;
    public temporalPrepositionKey: string | null = null;

    private today = DvbDateUtil.today();

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.wochenKapazitaet) {
            this.kapazitaetTitle = this.getKapazitaetTitle();
            this.temporalPrepositionKey = this.getTemporalPrepositionKey();
        }
    }

    public getKapazitaetTitle(): string | null {
        return DvbDateUtil.isGueltigOn(this.wochenKapazitaet, this.today) ?
            'COMMON.AKTUELLE_PLAETZE_MIT_DATUMPREP' :
            'COMMON.PLAETZE_MIT_DATUMPREP';
    }

    public getTemporalPrepositionKey(): string | null {
        return DvbDateUtil.getTemporalPrepositionKey({
            gueltigAb: this.wochenKapazitaet.gueltigAb,
            gueltigBis: END_OF_TIME,
        }, this.today);
    }
}

componentConfig.controller = DvbKapazitaetHeader;
angular.module('kitAdmin').component('dvbKapazitaetHeader', componentConfig);
