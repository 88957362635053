<div class="dvb-external-anmeldung row dvb-form">

    <div class="col-md-6 col-md-offset-3">

        <h1 data-translate="KIND.ANMELDUNG"></h1>

        <form name="anmeldeForm"
              ng-submit="vm.validateAndSaveAnmeldung(anmeldeForm)"
              novalidate>

            <dvb-wizard steps="vm.wizardSteps" on-step-changed="vm.stepChanged(step)">
                <step0>
                    <dvb-external-anmeldung-kinder
                        ng-if="vm.setup"
                        anmeldung="vm.anmeldung"
                        kinder="vm.kinder"
                        setup="vm.setup"
                        on-add-child="vm.addChild()"
                        on-kinder-orte-changed="vm.kinderOrteChanged(kind)">
                    </dvb-external-anmeldung-kinder>
                </step0>
                <step1>
                    <dvb-external-anmeldung-kontakte
                        kontakte="vm.anmeldung.externalKontaktpersonen"
                        on-add-kontakt="vm.addKontaktperson()"
                        on-remove-kontakt="vm.removeKontakt(kontakt)">
                    </dvb-external-anmeldung-kontakte>
                </step1>
                <step2>
                    <dvb-external-anmeldung-conclusion
                        anmeldung="vm.anmeldung"
                        kinder="vm.kinder"
                        setup="vm.setup"
                        show-error-link="vm.errorLinkVisible"
                        navigate-to-error="vm.navigateToError()">
                    </dvb-external-anmeldung-conclusion>
                </step2>
            </dvb-wizard>
        </form>
    </div>

    <dvb-external-anmeldung-privacy-confirmation
        on-accept="vm.acceptPrivacyPolicy()"
        visible="vm.privacyPolicyVisible">
    </dvb-external-anmeldung-privacy-confirmation>
</div>
