<div class="dvb-rechnung-stornieren-confirm modal dvb-modal-window">
    <div class="dvb-modal-container">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <form name="stornierenForm" ng-submit="vm.stornieren(stornierenForm)" novalidate>
                    <div class="modal-body" dvb-download>
                        <h1 data-translate="{{vm.rechnungen.length === 1 ?
                  'FAKTURA.RECHNUNG_STORNIEREN' :
                  'FAKTURA.RECHNUNG_STORNIEREN_BETROFFENE_RECHNUNGEN'}}">
                        </h1>

                        <dvb-rechnungen rechnungen="vm.rechnungen"></dvb-rechnungen>
                    </div>
                    <div class="modal-footer big-abstand-top">
                        <div>
                            <ul class="list-inline">
                                <li>
                                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                                        <span data-translate="FAKTURA.STORNIEREN"></span>
                                    </dvb-loading-button>
                                </li>
                                <li ng-class="{'invisible': vm.isLoading}">
                                    <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.cancel()"></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
