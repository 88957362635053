/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, inject, input, output, Signal} from '@angular/core';
import {NonNullableFormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {FormGroupSpec} from '@dv/shared/angular';
import {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import {TerminWorkTimeType} from '@dv/shared/backend/model/termin-work-time-type';
import {checkPresent} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

interface TerminTypeForm {
    name: string;
    workTimeType: TerminWorkTimeType;
    deductFromBedarfsrechnung: boolean;
}

@Component({
    selector: 'dv-termin-type-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TooltipModule,
        TranslateModule,
        SubmitCancelButtonsComponent,
    ],
    templateUrl: './termin-type-form.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminTypeFormComponent {

    public terminType = input<JaxTerminType>();
    public isLoading = input<boolean>(false);

    public readonly save = output<JaxTerminType>();
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    public readonly cancel = output<void>();

    public workTimeTypes: TerminWorkTimeType[] = [
        TerminWorkTimeType.WORK_TIME,
        TerminWorkTimeType.NO_WORK_TIME,
        TerminWorkTimeType.DEDUCT_FROM_PLANNED_TIME,
    ];

    private readonly errorService = inject(ErrorService);
    private readonly fb = inject(NonNullableFormBuilder);

    public form: Signal<FormGroupSpec<TerminTypeForm>> = computed(() => {
        const terminType = this.terminType();

        return this.fb.group({
            name: this.fb.control(terminType?.name ?? '', [Validators.required, Validators.minLength(1)]),
            workTimeType:
                this.fb.control(terminType?.workTimeType ?? TerminWorkTimeType.NO_WORK_TIME, Validators.required),
            deductFromBedarfsrechnung: this.fb.control(!!terminType?.deductFromBedarfsrechnung, Validators.required),
        });
    });

    public submitForm(): void {
        this.errorService.clearAll();
        if (!this.form().valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const {name, workTimeType, deductFromBedarfsrechnung} = this.form().value;
        this.save.emit({
            id: this.terminType()?.id,
            name: checkPresent(name),
            workTimeType: checkPresent(workTimeType),
            deductFromBedarfsrechnung: !!deductFromBedarfsrechnung,
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancel.emit();
    }
}
