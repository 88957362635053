<div class="dvb-report-steuerbescheinigung-structure">
    <h3 data-translate="REPORT.STEUERBESCHEINIGUNGEN.STRUCTURE_TITLE"></h3>
    <div class="radio">
        <label>
            <input type="radio"
                   value="MONTHLY"
                   id="MONTHLY"
                   ng-model="vm.structure"
                   required>
            <span data-translate="REPORT.STEUERBESCHEINIGUNGEN.STRUCTURE_MONTHLY"></span>
        </label>
    </div>
    <div class="radio">
        <label>
            <input type="radio"
                   value="LEISTUNGSART"
                   id="LEISTUNGSART"
                   ng-model="vm.structure"
                   required>
            <span data-translate="REPORT.STEUERBESCHEINIGUNGEN.STRUCTURE_LEISTUNGSART"></span>
        </label>
    </div>
</div>
