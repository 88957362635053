<div class="dvb-page">
    <dvlib-spinner class="centered-spinner" [show]="!!vm.isLoading && !vm.hasItems"></dvlib-spinner>

    <div ng-if="!vm.isLoading && !vm.hasItems">
        <p data-translate="COMMON.KEINE_ENTITY_VORHANDEN"
           data-translate-value-entity-name="{{vm.entityName | translate:{}:'messageformat'}}">
        </p>
    </div>

    <ng-transclude class="spinner-container" ng-class="{'spinning': vm.isLoading && vm.hasItems}"></ng-transclude>

    <div class="overlay-spinner" ng-show="!!vm.isLoading && vm.hasItems">
        <dvlib-spinner></dvlib-spinner>
    </div>
</div>
