/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {ReportState} from '../../models';
import {ERR_VALIDATION} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kind: '<?',
        gueltigAb: '<?',
    },
    template: require('./dvb-stundenvergleich-soll-ist-report.html'),
    controllerAs: 'vm',
};

export class DvbStundenvergleichSollIstReport implements angular.IController {

    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public kind?: SearchResultEntry;
    public gueltigAb!: moment.Moment;

    public readonly stundenvergleichReportForm?: angular.IFormController;
    public reportTemplate: ReportTemplate | null = null;
    public isLoading: boolean = false;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.gueltigAb = DvbDateUtil.startOfMonth(this.gueltigAb ? this.gueltigAb : DvbDateUtil.today());
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.stundenvergleichReportForm!.$valid && isPresent(this.kind) && this.gueltigAb.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        this.isLoading = true;

        const endOfMonth = DvbDateUtil.endOfMonth(moment(this.gueltigAb));
        const params = {
            gueltigAb: this.gueltigAb,
            gueltigBis: endOfMonth,
            reportTemplate: this.reportTemplate,
        };

        return this.reportService.getTempBlobForStundenvergleichReport(
            this.kind!.id,
            params,
        ).finally(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbStundenvergleichSollIstReport;
angular.module('kitAdmin').component('dvbStundenvergleichSollIstReport', componentConfig);
