/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AuthEventType} from '@dv/shared/authentication/model';
import {LogFactory} from '@dv/shared/code';
import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {filter, tap} from 'rxjs';
import {directiveSubSink} from '../../common/directive/directive-scope-subsink';
import {LOGIN_STATE} from '../authentication-states';
import type {AuthEventService} from '../service/auth-event.service';
import {withTypes} from '../service/auth-event.service';

angular.module('kitAdmin').directive('dvbLoginDialog', dvbLoginDialog);

const LOG = LogFactory.createLog('dvbLoginDialog');

dvbLoginDialog.$inject = ['$uiRouterGlobals', 'authEventService'];

function dvbLoginDialog(
    $uiRouterGlobals: UIRouterGlobals,
    authEventService: AuthEventService,
): angular.IDirective<{ visible: boolean } & angular.IScope> {
    return {
        restrict: 'E',
        template: '<div ng-if="visible"><dvb-login></dvb-login></div>',
        link: (scope): void => {
            const showDialog = (): void => {
                if ($uiRouterGlobals.current.name === LOGIN_STATE.name) {
                    // Already on Login Page -> do not show another dialog
                    return;
                }

                scope.visible = true;
            };

            // initialise hidden
            scope.visible = false;

            const loginDialogTrigger$ = authEventService.authEvent$.pipe(
                filter(withTypes([AuthEventType.requestLogin, AuthEventType.logoutSuccess])),
            );

            // change state based on events
            const subSink = directiveSubSink(scope);
            subSink.sink = loginDialogTrigger$.pipe(
                tap(() => showDialog()),
            ).subscribe();
            subSink.sink = authEventService.loginSuccess$.subscribe({
                next: () => {
                    scope.visible = false;
                },
                error: error => LOG.error('failed to subscribe to AuthEvents.loginSuccess', error),
            });
        },
    };
}
