<div class="dvb-angestellte-stammdaten row">
    <div class="col-md-7">
        <h2 data-translate="PERSONAL.ANGESTELLTE.TITLE"></h2>

        <div class="row">
            <div class="col-md-6">
                <dvb-editable-label is-valid="vm.isRequired(param)"
                                    ng-model="vm.angestellte.familienName"
                                    maxlength="255"
                                    ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                    ng-change="vm.save()"
                                    dvb-auth
                                    require-permission="shared:manage_users:general"
                                    on-unauthorised-disable>
                </dvb-editable-label>
            </div>
            <div class="col-md-6">
                <dvb-editable-label is-valid="vm.isRequired(param)"
                                    ng-model="vm.angestellte.vorName"
                                    maxlength="255"
                                    ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                    ng-change="vm.save()"
                                    dvb-auth
                                    require-permission="shared:manage_users:general"
                                    on-unauthorised-disable>
                </dvb-editable-label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field
                    click-to-edit="true"
                    ng-model="vm.angestellte.geburtsTag"
                    on-submit="vm.save()"
                    on-unauthorised-disable
                    ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}"
                    dvb-auth
                    require-permission="shared:manage_users:general">
                </dvb-datepicker-text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dvb-editable-label ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                                    maxlength="100"
                                    ng-model="vm.angestellte.telefon"
                                    ng-change="vm.saveTelephone()"
                                    dvb-auth
                                    require-permission="shared:manage_users:general"
                                    on-unauthorised-disable>
                </dvb-editable-label>
            </div>
            <div class="col-md-6">
                <dvb-editable-label ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                                    type="email"
                                    maxlength="255"
                                    ng-model="vm.angestellte.email"
                                    is-email="true"
                                    ng-change="vm.save()"
                                    dvb-auth
                                    require-permission="shared:manage_users:general"
                                    on-unauthorised-disable>
                </dvb-editable-label>
            </div>
        </div>

        <div ng-if="vm.angestellte.adresse">
            <dvb-adresse ng-click="vm.editAdresse()"
                         adresse="vm.angestellte.adresse"
                         display-type="long"
                         on-save="vm.saveAdresse(adresse)"
                         on-cancel="vm.cancelEditAdresse()"
                         click-to-edit="true"
                         dvb-auth
                         require-permission="shared:manage_users:general"
                         on-unauthorised-disable
                         show-edit-mode="vm.isAdresseEditMode">
            </dvb-adresse>
        </div>

        <ul class="list-inline normal-abstand-top" dvb-auth require-permission="shared:manage_users:general">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.delete()"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </li>
            <li ng-if="!vm.angestellte.adresse">
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.addAddress()"
                        data-translate="PERSONAL.ANGESTELLTE.ADD_ADDRESS">
                </button>
            </li>
        </ul>
    </div>

    <div class="col-md-4 col-md-offset-1 dvb-form">
        <div dvb-auth require-permission="shared:manage_users:general" require-condition="!vm.benutzer" class="form-section">
            <h2 data-translate="COMMON.AKTIONEN" class="small-abstand-bottom"></h2>

            <dvb-search-on-click-entity add-label-key="PERSONAL.ANGESTELLTE.ASSIGN_USER"
                                        entity-to-search="BENUTZER"
                                        on-select="vm.assignUser(item)"
                                        on-select-clear="true"
                                        placeholder-key="PERSONAL.ANGESTELLTE.ASSIGN_USER">
            </dvb-search-on-click-entity>
        </div>

        <div ng-if="vm.benutzer" class="form-section">
            <h2 data-translate="COMMON.BENUTZER.SINGULAR" class="small-abstand-bottom"></h2>

            <div class="form-row row selected-entity">
                <div class="col-md-9">
                    <a class="entity"
                       ui-sref="{{vm.benutzerStates.BENUTZER_PROFILE_STATE.name + '({id: ' + vm.angestellte.benutzerId + '})'}}"
                       ng-bind="vm.benutzer.getDisplayName()">
                    </a>
                </div>
                <div class="col-md-3 edit hidden-print" dvb-auth require-permission="shared:manage_users:general">
                    <button class="btn btn-link"
                            data-translate="COMMON.LOESCHEN"
                            ng-click="vm.removeUser()">
                    </button>
                </div>
            </div>
        </div>

        <div dvb-auth require-permission="feature:stundenerfassung_tfo:general">
            <h2 data-translate="COMMON.BETREUUNGS_PERSON.PLURAL" class="small-abstand-bottom"></h2>

            <p ng-if="vm.angestellte.betreuungsPersonen.length === 0"
               data-translate="COMMON.BETREUUNGS_PERSON.KEINE"></p>

            <div class="row selected-entity" ng-repeat="betreuungsPerson in vm.angestellte.betreuungsPersonen">
                <div class="col-xs-9 truncate"
                     uib-tooltip="{{betreuungsPerson.kita.getDisplayName()}}"
                     tooltip-placement="top-left">
                    <a dvb-auth
                       require-permission="{{'kita:view:' + betreuungsPerson.kita.id}}"
                       on-unauthorised-disable
                       class="entity"
                       ng-bind="betreuungsPerson.getDisplayName()"
                       ui-sref="base.fraktion.betreuungs_person.profil({id: betreuungsPerson.id})">
                    </a>
                    <span ng-bind="betreuungsPerson.kita.getDisplayName()"></span>
                </div>
                <div class="col-md-3 edit hidden-print" dvb-auth require-permission="shared:manage_users:general">
                    <button type="button"
                            class="btn btn-link"
                            data-translate="COMMON.LOESCHEN"
                            ng-click="vm.removeBetreuungsPerson(betreuungsPerson)">
                    </button>
                </div>
            </div>
            <div dvb-auth require-permission="shared:manage_users:general">
                <dvb-search-on-click-entity add-label-key="COMMON.BETREUUNGS_PERSON.ASSIGN"
                                            entity-to-search="BETREUUNGS_PERSON"
                                            on-select="vm.addBetreuungsPerson(item)"
                                            on-select-clear="true"
                                            placeholder-key="COMMON.BETREUUNGS_PERSON.ASSIGN"
                                            disabled-entries="vm.angestellte.betreuungsPersonen">
                </dvb-search-on-click-entity>
            </div>
        </div>
    </div>
</div>
