/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {ContextualAdresse} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        contextualAdresse: '<',
        allowNone: '<?',
        onUpdate: '&',
    },
    template: require('./dvb-editable-contextual-adresse.html'),
    controllerAs: 'vm',
};

export class DvbEditableContextualAdresse implements angular.IController {
    public static $inject: readonly string[] = ['errorService', '$attrs'];

    public contextualAdresse!: ContextualAdresse;
    public allowNone?: boolean;
    public onUpdate!: (val: { contextualAdresse: ContextualAdresse }) => void;

    public editMode: boolean = false;
    public isDisabled: boolean = false;
    public translationKey?: string;

    private workingCopyContextualAdresse?: ContextualAdresse;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.contextualAdresse) {
            this.workingCopyContextualAdresse = angular.copy(changes.contextualAdresse.currentValue);
            this.translationKey = changes.contextualAdresse.currentValue.getTranslationKey();
        }
    }

    public edit(): void {
        if (this.isDisabled) {
            return;
        }

        this.workingCopyContextualAdresse = angular.copy(this.contextualAdresse);
        this.editMode = true;
    }

    public update(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid || form.$pending) {
            return;
        }

        this.onUpdate({contextualAdresse: this.workingCopyContextualAdresse!});
        this.editMode = false;
    }

    public cancel(): void {
        this.editMode = false;
        this.errorService.clearAll();
    }
}

componentConfig.controller = DvbEditableContextualAdresse;
angular.module('kitAdmin').component('dvbEditableContextualAdresse', componentConfig);
