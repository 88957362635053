/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {Kontaktperson} from '@dv/kitadmin/models';
import {DisplayNamePipe, trackById} from '@dv/shared/angular';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {BsModalRef} from 'ngx-bootstrap/modal';

export interface BulkEmailEmpfaengerDialogModel {
    kontaktpersonen: Kontaktperson[];
}

@Component({
    selector: 'dv-bulk-email-empfaenger-dialog',
    templateUrl: './bulk-email-empfaenger-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateModule, UIRouterModule, DisplayNamePipe],
})
export class BulkEmailEmpfaengerDialogComponent implements BulkEmailEmpfaengerDialogModel {

    @Input({required: true}) public kontaktpersonen: Kontaktperson[] = [];

    public trackById = trackById;

    public constructor(
        private bsModalRef: BsModalRef,
    ) {
    }

    public hide(): void {
        this.bsModalRef.hide();
    }
}

