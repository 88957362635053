<div class="dvb-stunden-blaetter">

    <div class="header">
        <h2 data-translate="PERSONAL.STUNDENBLAETTER.PLURAL"></h2>

        <dvlib-date-switcher mode="month"
                             class="normal-abstand-left"
                             [(date)]="vm.filter.gueltigAb"
                             (debounced-date-change)="vm.reloadItems()"
                             [is-disabled]="vm.isLoading">
        </dvlib-date-switcher>
    </div>

    <div class="filter-list filter-bar">
        <div class="filter">
            <div data-translate="COMMON.FILTER_STATUS" class="filter-label"></div>
            <ul class="filter-option-list" ng-class="{'hidden-print': !vm.filter.isFiltering()}">
                <li ng-repeat="status in vm.states" ng-class="{'hidden-print': !vm.filter.states[status]}">
                    <button class="btn label label-default label-radio"
                            type="button"
                            ng-model="vm.filter.states[status]"
                            ng-click="vm.reloadItems()"
                            uib-tooltip="{{'PERSONAL.STUNDENBLAETTER.STATE.' + status | translate}}"
                            tooltip-placement="top-left"
                            uib-btn-checkbox
                            data-translate="{{'PERSONAL.STUNDENBLAETTER.STATE.' + status}}">
                    </button>
                </li>
            </ul>
        </div>
        <div class="pull-right text-right">
            <dvb-filter-toggler is-collapsed="vm.collapsed"
                                show-reset="vm.filter.isFiltering()"
                                on-reset="vm.onReset()"
                                on-toggle="vm.collapsed = !vm.collapsed">
            </dvb-filter-toggler>
        </div>
    </div>

    <div class="filter-list small-abstand-top" ng-if="(vm.filter.kinderOrte | filter:{sticky:true}).length > 0">
        <div class="filter">
            <div
                data-translate="COMMON.KINDERORT.PLURAL"
                class="filter-label">
            </div>
            <ul class="filter-option-list">
                <li ng-repeat="filter in vm.filter.kinderOrte | filter:{sticky:true} | orderBy: 'name'">
                    <dvb-filter-option label="filter.name"
                                       is-selected="filter.selected"
                                       closeable="true"
                                       on-close="vm.filterOptionHelper.remove(vm.filter.kinderOrte, filter)"
                                       on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                    </dvb-filter-option>
                </li>
            </ul>
        </div>
    </div>

    <div uib-collapse="vm.collapsed">
        <div class="row small-abstand-top">
            <div class="col-md-4 col-md-offset-8">
                <dvb-search-on-click-entity add-label-key="COMMON.KINDERORT.AUSWAEHLEN"
                                            entity-to-search="KINDERORT"
                                            on-select="vm.filterOptionHelper.addFromSearchResult(vm.filter.kinderOrte, item)"
                                            on-select-clear="true"
                                            disabled-entries="vm.filter.kinderOrte"
                                            placeholder-key="COMMON.KINDERORT.SINGULAR">
                </dvb-search-on-click-entity>
            </div>
        </div>
    </div>

    <div class="row big-abstand-top">
        <div class="col-md-7 big-abstand-bottom">
            <dvb-pages items="vm.pageContainer.items"
                       total-items="vm.pageContainer.count"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'PERSONAL.STUNDENBLAETTER.MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                <page-items dvb-accordions>

                    <dvb-accordion ng-repeat="entity in vm.pageContainer.items"
                                   is-first="$first"
                                   ng-disabled="true"
                                   show-content-disabled="false">

                        <panel-header class="full-width-block">
                            <div class="layout-row items-center row">
                                <div class="col-xs-7 col-sm-5">
                                    <a ui-sref="{{vm.stundenblattState.name + '({id: entity.betreuungsPerson.angestellteId, datum: vm.filter.gueltigAb })'}}"
                                       ng-bind="entity.betreuungsPerson.getDisplayName()">
                                    </a>
                                </div>
                                <div class="col-xs-5 col-sm-3">
                                    <span class="badge"
                                          data-translate="{{ 'PERSONAL.STUNDENBLAETTER.STATE.' + entity.status}}">
                                    </span>
                                </div>
                                <div class="hidden-xs col-sm-4">
                                    <span class="badge pull-right"
                                          ng-bind="entity.betreuungsPerson.kita.getDisplayName()"></span>
                                </div>
                            </div>
                        </panel-header>
                    </dvb-accordion>
                </page-items>
            </dvb-pages>
        </div>

        <div class="col-md-4 col-md-offset-1 hidden-print">
            <ul class="list-inline">
                <li ng-repeat="status in vm.states">
                    <h3 class="normal-abstand-top" data-translate="{{'PERSONAL.STUNDENBLAETTER.STATE.' + status}}"></h3>
                    <p data-translate="{{'PERSONAL.STUNDENBLAETTER.DESCRIPTION.' + status}}"></p>
                </li>
            </ul>
        </div>
    </div>

</div>
