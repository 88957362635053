<div class="dvb-anwesenheits-soll-verbrauch">
    <h2 data-translate="KIND.ANWESENHEITS_SOLL.TITLE" class="small-abstand-bottom"></h2>

    <div class="small-abstand-bottom">
        <strong data-translate="KIND.ANWESENHEITS_SOLL.PERIOD"
                data-translate-value-von="{{vm.soll.gueltigAb | amDateFormat:'D.M.YYYY'}}"
                data-translate-value-bis="{{vm.soll.gueltigBis | amDateFormat:'D.M.YYYY'}}">
        </strong>
    </div>

    <dvb-anwesenheits-soll-verbrauch-progress ng-if="vm.soll.tage"
                                              soll="vm.soll.tage"
                                              verbrauch="vm.verbrauch.tage"
                                              type="'KIND.ANWESENHEITS_SOLL.TAGE' | translate">
    </dvb-anwesenheits-soll-verbrauch-progress>

    <dvb-anwesenheits-soll-verbrauch-progress ng-if="vm.soll.halbtage"
                                              soll="vm.soll.halbtage"
                                              verbrauch="vm.verbrauch.halbtage"
                                              type="'KIND.ANWESENHEITS_SOLL.HALBTAGE' | translate">
    </dvb-anwesenheits-soll-verbrauch-progress>

    <dvb-anwesenheits-soll-verbrauch-progress ng-if="vm.soll.fruehbetreuungen"
                                              soll="vm.soll.fruehbetreuungen"
                                              verbrauch="vm.verbrauch.fruehbetreuungen"
                                              type="'KIND.ANWESENHEITS_SOLL.FRUEHBETREUUNGEN' | translate">
    </dvb-anwesenheits-soll-verbrauch-progress>

    <dvb-anwesenheits-soll-verbrauch-progress ng-if="vm.soll.mittage"
                                              soll="vm.soll.mittage"
                                              verbrauch="vm.verbrauch.mittage"
                                              type="'KIND.ANWESENHEITS_SOLL.MITTAGE' | translate">
    </dvb-anwesenheits-soll-verbrauch-progress>

    <dvb-anwesenheits-soll-verbrauch-progress ng-if="vm.soll.halbeNachmittage"
                                              soll="vm.soll.halbeNachmittage"
                                              verbrauch="vm.verbrauch.halbeNachmittage"
                                              type="'KIND.ANWESENHEITS_SOLL.NACHMITTAGE' | translate">
    </dvb-anwesenheits-soll-verbrauch-progress>

    <div class="normal-abstand-top">
        <p data-translate="KIND.ANWESENHEITS_SOLL.WARNING"></p>
    </div>

</div>
