<div class="dvb-angestellte-kind-betreuung-erfassung">
    <h2 data-translate="PERSONAL.STUNDENERFASSUNG.TITLE"></h2>

    <div class="filter-bar filter-flex">
        <ul class="list-inline inline-block small-abstand-top">
            <li>
                <span data-translate="PERSONAL.STUNDENERFASSUNG.MONAT"></span>
            </li>
            <li>
                <dvlib-date-switcher mode="month"
                                     (debounced-date-change)="vm.onMonthChange()"
                                     [(date)]="vm.periode">
                </dvlib-date-switcher>
            </li>
            <li>
                <span class="badge" data-translate="{{ 'PERSONAL.STUNDENBLAETTER.STATE.' + vm.monthlyData.status}}"></span>
            </li>
        </ul>
        <div class="inline-block actions">
            <dvlib-spinner class="small-abstand-right" [show]="vm.isLoading"></dvlib-spinner>
            <button type="button"
                    class="btn btn-link"
                    ng-disabled="vm.isLoading"
                    ng-click="vm.downloadSummary()">
                <i class="fa fa-download"></i>
            </button>
            <button type="button"
                    class="btn btn-link small-abstand-left"
                    ng-disabled="vm.isLoading"
                    ng-click="vm.showSummary()"
                    data-translate="PERSONAL.STUNDENERFASSUNG.MONTH_SUMMARY">
            </button>
            <button dvb-auth
                    require-permission="{{'angestellte:update:' + vm.angestellte.id}}"
                    require-condition="vm.monthlyData.status === 'OFFEN'"
                    type="button"
                    class="btn btn-primary small-abstand-left"
                    ng-disabled="vm.isLoading"
                    ng-click="vm.freigeben()"
                    data-translate="PERSONAL.STUNDENERFASSUNG.FREIGEBEN">
            </button>
            <div class="inline-block" dvb-auth require-permission="kita:manage:general">
                <button ng-if="vm.monthlyData.status !== 'OFFEN'"
                        type="button"
                        class="btn btn-link small-abstand-left"
                        ng-disabled="vm.isLoading"
                        ng-click="vm.openStatus()"
                        data-translate="PERSONAL.STUNDENERFASSUNG.REOPEN">
                </button>
                <button ng-if="vm.monthlyData.status === 'OFFEN' || vm.monthlyData.status === 'FREIGEGEBEN'"
                        type="button"
                        class="btn btn-primary small-abstand-left"
                        ng-disabled="vm.isLoading"
                        ng-click="vm.korrigiert()"
                        data-translate="PERSONAL.STUNDENERFASSUNG.KORRIGIERT">
                </button>
                <button ng-if="vm.monthlyData.status === 'KORRIGIERT' || vm.monthlyData.status === 'BESTAETIGT'"
                        type="button"
                        class="btn btn-primary small-abstand-left"
                        ng-disabled="vm.isLoading"
                        ng-click="vm.bestaetigen()"
                        data-translate="PERSONAL.STUNDENERFASSUNG.BESTAETIGEN">
                </button>
            </div>
        </div>
    </div>

    <div class="row big-abstand-top">
        <div class="col-md-4 big-abstand-bottom">
            <div ng-if="vm.selectedDate"
                 class="dvb-calendar calendar-wide no-switching"
                 uib-datepicker
                 ng-model="vm.selectedDate"
                 ng-change="vm.onDateChange()"
                 datepicker-options="vm.dateOptions">
            </div>
        </div>

        <div class="col-md-7 col-md-offset-1" dvb-accordions>

            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading && vm.entries.length === 0"></dvlib-spinner>

            <p ng-if="!vm.isLoading && vm.entries.length === 0"
               data-translate="PERSONAL.STUNDENERFASSUNG.KEINE_EINTRAEGE">
            </p>

            <dvb-accordion is-first="false" ng-repeat="entry in vm.entries">
                <panel-header class="full-width-block floating-text">
                    <div class="layout-row items-center row">
                        <div class="col-xs-9" ng-bind="entry.kind.getDisplayName()"></div>
                        <div class="col-xs-3">
                            <span data-translate="COMMON.STUNDEN"
                                  data-translate-value-hours="{{entry.totalStunden.getDisplayValue() | roundTo:2}}"
                                  tooltip-placement="top"
                                  uib-tooltip="{{'PERSONAL.STUNDENERFASSUNG.STUNDEN' | translate}}">
                            </span>
                        </div>
                    </div>
                </panel-header>
                <panel-content>
                    <form class="dvb-form ng-submitted" novalidate autocomplete="off">
                        <div ng-repeat="fraktionEntry in entry.tagesDaten"
                             ng-init="fraktionId = fraktionEntry.kinderOrtFraktionId">

                            <h3 ng-if="entry.tagesDaten.length > 1"
                                class="small-abstand-bottom"
                                ng-class="{'normal-abstand-top': !$first}"
                                ng-bind="vm.betreuungsPersonen[fraktionId].kita.getDisplayName()">
                            </h3>
                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-8 korrektur-input">
                                            <label data-translate="PERSONAL.STUNDENERFASSUNG.STUNDEN"></label>

                                            <input class="form-control"
                                                   type="number"
                                                   min="0"
                                                   ng-change="vm.update(fraktionEntry, entry); vm.updateTagesStunden(entry)"
                                                   ng-model="fraktionEntry.stunden[vm.korrekturChoice]"
                                                   ng-model-options="{ updateOn: 'change blur' }"
                                                   ng-disabled="fraktionEntry.vonBisZeiten.length > 0 || vm.readonly[fraktionId]"
                                                   dvb-ignore-mouse-wheel
                                                   tooltip-placement="top-left"
                                                   uib-tooltip="{{'PERSONAL.STUNDENERFASSUNG.STUNDEN' | translate}}">

                                            <div ng-if="vm.korrekturChoice == 'current' && fraktionEntry.stunden.showDifference()"
                                                 class="korrektur-original"
                                                 data-translate="PERSONAL.STUNDENERFASSUNG.ORIGINAL_MF"
                                                 data-translate-interpolation="messageformat"
                                                 data-translate-values="{original: fraktionEntry.stunden.original}">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" ng-if="fraktionEntry.vonBisZeiten[vm.korrekturChoice].length > 0">
                                        <div class="col-xs-4 col-sm-4">
                                            <label data-translate="COMMON.VON"></label>
                                        </div>
                                        <div class="col-xs-4 col-sm-4">
                                            <label data-translate="COMMON.BIS"></label>
                                        </div>
                                    </div>

                                    <div ng-form="vonBisZeitenForm"
                                         class="row"
                                         ng-repeat="vonBisZeit in fraktionEntry.vonBisZeiten[vm.korrekturChoice] | orderBy: 'von'">
                                        <div class="col-xs-4 col-sm-4">
                                            <input class="form-control"
                                                   name="von"
                                                   ng-change="vm.updateStunden(fraktionEntry, entry)"
                                                   ng-model="vonBisZeit.von"
                                                   ng-disabled="vm.readonly[fraktionId]"
                                                   dvb-time-input
                                                   dvb-is-valid="vonBisZeit"
                                                   dependent-control="vonBisZeitenForm.bis"
                                                   ng-model-options="{ updateOn: 'change', allowInvalid: true }"
                                                   tooltip-placement="top-left"
                                                   uib-tooltip="{{'COMMON.VON' | translate}}">
                                        </div>
                                        <div class="col-xs-4 col-sm-4">
                                            <input class="form-control"
                                                   name="bis"
                                                   ng-change="vm.updateStunden(fraktionEntry, entry)"
                                                   ng-model="vonBisZeit.bis"
                                                   ng-disabled="vm.readonly[fraktionId]"
                                                   dvb-time-input
                                                   dvb-is-valid="vonBisZeit"
                                                   dependent-control="vonBisZeitenForm.von"
                                                   ng-model-options="{ updateOn: 'change', allowInvalid: true }"
                                                   tooltip-placement="top-left"
                                                   uib-tooltip="{{'COMMON.BIS' | translate}}">
                                        </div>
                                        <div ng-if="!vm.readonly[fraktionId]" class="col-xs-4 col-sm-4 text-left">
                                            <button type="button"
                                                    class="btn btn-link form-linker"
                                                    data-translate="COMMON.LOESCHEN"
                                                    ng-click="vm.removeVonBisZeit(fraktionEntry, entry, $index)">
                                            </button>
                                        </div>
                                    </div>
                                    <button ng-if="!vm.readonly[fraktionId]"
                                            type="button"
                                            class="btn btn-link form-section-minus-normal-abstand"
                                            ng-click="vm.createNewVonBisZeit(fraktionEntry)">
                                        + <span data-translate="PERSONAL.STUNDENERFASSUNG.ADD_TIMES"></span>
                                    </button>
                                </div>
                                <div class="col-xs-12 col-sm-6"
                                     ng-if="vm.korrekturChoice === 'current' && fraktionEntry.vonBisZeiten['original'].length > 0">
                                    <p data-translate="PERSONAL.STUNDENERFASSUNG.ORIGINAL_ZEITEN"></p>
                                    <ul class="korrektur-zeiten-list">
                                        <li ng-repeat="vonBisZeit in fraktionEntry.vonBisZeiten['original'] | orderBy: 'von'"
                                            data-translate="COMMON.GUELTIGKEIT_VON_BIS"
                                            data-translate-value-date-ab="{{vonBisZeit.von.format('LT')}}"
                                            data-translate-value-date-bis="{{vonBisZeit.bis.format('LT')}}">
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="row normal-abstand-top">
                                <div class="col-xs-12 col-sm-4 korrektur-input"
                                     ng-repeat="tarifParam in fraktionEntry.tarifParams">
                                    <label class="truncate" ng-bind="tarifParam.name"></label>
                                    <input class="form-control"
                                           type="number"
                                           ng-change="vm.update(fraktionEntry, entry, tarifParam.id)"
                                           ng-model="fraktionEntry.spesenByTarifParamId[tarifParam.id][vm.korrekturChoice]"
                                           ng-model-options="{ updateOn: 'change blur' }"
                                           ng-disabled="vm.readonly[fraktionId]"
                                           dvb-ignore-mouse-wheel
                                           tooltip-placement="top-left"
                                           uib-tooltip="{{tarifParam.name}}">

                                    <div ng-if="vm.korrekturChoice == 'current' && fraktionEntry.spesenByTarifParamId[tarifParam.id].showDifference()"
                                         class="korrektur-original"
                                         data-translate="PERSONAL.STUNDENERFASSUNG.ORIGINAL_MF"
                                         data-translate-interpolation="messageformat"
                                         data-translate-values="{original: fraktionEntry.spesenByTarifParamId[tarifParam.id].original}">
                                    </div>
                                </div>
                            </div>

                            <div class="row small-abstand-top"
                                 ng-if="vm.monthlyData.status === 'OFFEN' || fraktionEntry.bemerkung.original.length > 0">
                                <div class="col-md-12">
                                    <label class="truncate"
                                           data-translate="PERSONAL.STUNDENERFASSUNG.ERFASSUNGS_BEMERKUNG"></label>
                                    <textarea ng-model="fraktionEntry.bemerkung.original"
                                              class="form-control"
                                              msd-elastic
                                              uib-tooltip="{{'PERSONAL.STUNDENERFASSUNG.ERFASSUNGS_BEMERKUNG' | translate}}"
                                              tooltip-placement="top-left"
                                              rows="1"
                                              ng-disabled="vm.readonly[fraktionId] || vm.monthlyData.status !== 'OFFEN'"
                                              ng-change="vm.updateBemerkung(fraktionEntry, entry)">
                                    </textarea>
                                </div>
                            </div>

                            <div class="row small-abstand-top"
                                 ng-if="vm.monthlyData.status !== 'OFFEN' || fraktionEntry.bemerkung.current.length > 0">
                                <div class="col-md-12">
                                    <label class="truncate"
                                           data-translate="PERSONAL.STUNDENERFASSUNG.KORREKTUR_BEMERKUNG"></label>
                                    <textarea ng-model="fraktionEntry.bemerkung.current"
                                              class="form-control"
                                              msd-elastic
                                              uib-tooltip="{{'PERSONAL.STUNDENERFASSUNG.KORREKTUR_BEMERKUNG' | translate}}"
                                              tooltip-placement="top-left"
                                              rows="1"
                                              ng-disabled="vm.readonly[fraktionId] || vm.monthlyData.status === 'OFFEN'"
                                              ng-change="vm.updateBemerkung(fraktionEntry, entry)">
                                    </textarea>
                                </div>
                            </div>

                            <p dvb-auth
                               require-permission="{{'kita:control:' + vm.betreuungsPersonen[fraktionId].kita.id}}">
                                <a ui-sref="base.fraktion.betreuungs_person.monatsblatt({id: fraktionId, periode: vm.periode, kindId: entry.kind.id})"
                                   data-translate="PERSONAL.STUNDENERFASSUNG.NAV_TO_MONATSBLATT">
                                </a>
                            </p>
                        </div>
                    </form>
                </panel-content>
            </dvb-accordion>

            <div class="row normal-abstand-top" ng-if="vm.childAddingAllowed && vm.additionalKinder.length > 0">
                <div class="col-md-6">
                    <button ng-if="!vm.childAddingMode"
                            type="button"
                            class="btn btn-link"
                            ng-click="vm.childAddingMode = true">
                        + <span data-translate="PERSONAL.STUNDENERFASSUNG.ABWEICHUNG"></span>
                    </button>

                    <div ng-if="vm.childAddingMode">
                        <select class="form-control no-margin"
                                ng-model="vm.selectedAdditionalKind"
                                ng-options="kind as kind.getDisplayName() for kind in vm.additionalKinder">
                        </select>

                        <ul class="list-inline">
                            <li>
                                <button type="button"
                                        class="btn btn-link normal-abstand-top"
                                        ng-click="vm.addChild(vm.selectedAdditionalKind)"
                                        data-translate="PERSONAL.STUNDENERFASSUNG.ABWEICHUNG"
                                        ng-disabled="!vm.selectedAdditionalKind">
                                </button>
                            </li>
                            <li>
                                <button type="button"
                                        class="btn btn-link normal-abstand-top"
                                        ng-click="vm.childAddingMode = false; vm.selectedAdditionalKind = null;"
                                        data-translate="COMMON.ABBRECHEN">
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
