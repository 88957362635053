/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {Ausbildung} from '../../anstellung/models/Ausbildung';
import type {AusbildungNodeCheckboxFormModel} from './ausbildung-node-checkbox.model';

export function ausbildungToAusbildungFormModel(
    ausbildung: Ausbildung,
    selectedAusbildungen: EntityId[],
): AusbildungNodeCheckboxFormModel {
    const selected = selectedAusbildungen.includes(checkPresent(ausbildung.id));

    return {
        id: checkPresent(ausbildung.id),
        name: checkPresent(ausbildung.name),
        selected,
        children: ausbildung.children.map(a => ausbildungToAusbildungFormModel(a, selectedAusbildungen)),
    };
}

export function getSelectedAusbildungen(ausbildungen: AusbildungNodeCheckboxFormModel[]): EntityId[] {
    const result: string[] = [];
    ausbildungen.forEach(a => {
        if (a.selected) {
            result.push(a.id);
        }
        if (DvbUtil.isNotEmptyArray(a.children)) {
            result.push(...getSelectedAusbildungen(a.children));
        }
    });

    return result;
}
