/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import type {NamedEntityType} from '@dv/shared/code';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import type {Notiz} from './models/Notiz';
import type {NotizService} from './service/notiz-service';

/* eslint-disable sonarjs/no-duplicate-string */

export const NOTIZ_BASE_STATE = {
    name: 'base.notiz',
    url: '/notiz',
    abstract: true,
    data: {
        permission: {oneOf: [PERMISSION.KITA.MANAGE_ANY, PERMISSION.PERSONAL.MANAGE_ANY]},
    },
} satisfies Ng1StateDeclaration;

export const NOTIZ_EDIT_STATE = {
    name: `${NOTIZ_BASE_STATE.name}.edit`,
    url: '/{notizId:[0-9]+}',
    template: '<dvb-notiz-erstellen-form existing-notiz="$resolve.notiz"></dvb-notiz-erstellen-form>',
    resolve: {
        notiz: [
            'notizService', '$transition$',
            (
                notizService: NotizService,
                $transition$: Transition,
            ): angular.IPromise<Notiz> =>
                notizService.get($transition$.params().notizId, {includes: '(anhaenge)'}),
        ],
    },
    data: NOTIZ_BASE_STATE.data,
} satisfies Ng1StateDeclaration;
export const NOTIZ_CREATE_STATE = {
    name: `${NOTIZ_BASE_STATE.name}.create`,
    url: '/{entityType:[A-Z_]+}/{entityId:[0-9]+}',
    template: '<dvb-notiz-erstellen-form ' +
        'entity-id="$resolve.entityId" ' +
        'entity-type="$resolve.entityType"></dvb-notiz-erstellen-form>',
    resolve: {
        entityId: [
            '$transition$',
            ($transition$: Transition): string | undefined => $transition$.params().entityId,
        ],
        entityType: [
            '$transition$',
            ($transition$: Transition): NamedEntityType | undefined => $transition$.params().entityType,
        ],
    },
    data: NOTIZ_BASE_STATE.data,
} satisfies Ng1StateDeclaration;

export const NOTIZ_STATES = {
    NOTIZ_BASE_STATE,
    NOTIZ_EDIT_STATE,
    NOTIZ_CREATE_STATE,
};
