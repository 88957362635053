<div class="dvb-kontaktperson-overview row">
    <div class="col-md-7 big-abstand-bottom dvb-form">
        <div class="form-section-minus-normal-abstand">
            <h2 data-translate="KONTAKTPERSON.KONTAKTPERSON_STAMMDATEN"></h2>


            <div class="row" ng-if="vm.kontaktperson.isFirma">
                <div class="col-md-6">
                    <dvb-editable-label ng-attr-placeholder="{{'COMMON.ORGANISATION' | translate}}"
                                        maxlength="255"
                                        ng-model="vm.kontaktperson.organisation"
                                        is-valid="vm.isOrgValid(param)"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row hidden-print">
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isNameValid(param)"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                        ng-model="vm.kontaktperson.familienName"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isNameValid(param)"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                        ng-model="vm.kontaktperson.vorName"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div ng-repeat="geschlecht in vm.geschlechte">
                        <div class="radio">
                            <label>
                                <input type="radio"
                                       name="geschlecht"
                                       value="{{geschlecht}}"
                                       ng-model="vm.kontaktperson.geschlecht"
                                       ng-change="vm.saveBasisData()"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       require-condition="geschlecht !== 'UNBEKANNT'"
                                       on-unauthorised-disable>
                                <span data-translate="COMMON.{{geschlecht}}"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kontaktperson.telefon}">
                    <dvb-editable-label is-valid="vm.isRequiredForHauptkontakt(param)"
                                        ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                                        maxlength="100"
                                        ng-model="vm.kontaktperson.telefon"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kontaktperson.mobile}">
                    <dvb-editable-label is-valid="vm.isRequiredForHauptkontaktMobile(param)"
                                        ng-attr-placeholder="{{'COMMON.MOBILE_NR' | translate}}"
                                        maxlength="100"
                                        ng-model="vm.kontaktperson.mobile"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kontaktperson.geschaeft}">
                    <dvb-editable-label ng-attr-placeholder="{{'COMMON.GESCHAEFT_NR' | translate}}"
                                        maxlength="100"
                                        ng-model="vm.kontaktperson.geschaeft"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kontaktperson.email}">
                    <dvb-editable-label ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                                        type="email"
                                        maxlength="255"
                                        ng-model="vm.kontaktperson.email"
                                        is-email="true"
                                        ng-change="vm.updateEmail()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   ng-model="vm.versandartEmail"
                                   ng-change="vm.updateVersandart()"
                                   dvb-auth
                                   require-permission="kita:manage:general"
                                   require-condition="vm.kontaktperson.email"
                                   on-unauthorised-disable>
                            <span data-translate="COMMON.KORRESPONDENZ_PER_EMAIL"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kontaktperson.korrespondenzsprache}">
                    <dvb-iso-locale ng-model="vm.kontaktperson.korrespondenzsprache"
                                    on-submit="vm.kontaktperson.korrespondenzsprache = isoObject; vm.saveBasisData()"
                                    dvb-click2edit-iso-locale
                                    type="LANGUAGE"
                                    ng-attr-placeholder="{{'COMMON.KORRESPONDENZSPRACHE' | translate}}"
                                    dvb-auth
                                    require-permission="kita:manage:general"
                                    on-unauthorised-disable>
                    </dvb-iso-locale>
                </div>
                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   ng-model="vm.kontaktperson.mahnsperre"
                                   ng-change="vm.saveBasisData()"
                                   dvb-auth
                                   require-permission="kita:manage:general"
                                   on-unauthorised-disable>
                            <span data-translate="KONTAKTPERSON.MAHNSPERRE"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label ng-attr-placeholder="{{'KONTAKTPERSON.IBAN' | translate}}"
                                        type="text"
                                        maxlength="100"
                                        ng-model="vm.kontaktperson.iban"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label ng-attr-placeholder="{{'FAKTURA.KONTO.BIC' | translate}}"
                                        type="text"
                                        maxlength="100"
                                        ng-model="vm.kontaktperson.bic"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>

            <dvb-custom-field-values custom-field-values="vm.kontaktperson.customFieldValues"
                                     dvb-auth
                                     require-permission="kita:manage:general"
                                     on-unauthorised-disable>
            </dvb-custom-field-values>
        </div>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <h2 data-translate="KONTAKTPERSON.TITLE_KINDER"></h2>

        <div class="form-section normal-abstand-bottom" ng-repeat="kind in vm.kinder">
            <div class="row small-abstand-bottom">
                <div class="col-md-12">
                    <a ui-sref="base.kind.profil({kindId:kind.id})"
                       class="entity"
                       ng-bind="kind.getDisplayName()"></a>,
                    <span ng-bind="kind.getDisplayGeburtsdatum()"></span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <ul class="list-unstyled">
                        <li ng-if="vm.kontaktperson.findRelationshipWithKindId(kind.id).isHauptkontakt()">
                            <i class="fa fa-check"></i> <span data-translate="KONTAKTPERSON.HAUPTKONTAKT"></span>
                        </li>
                        <li ng-if="vm.kontaktperson.findRelationshipWithKindId(kind.id).isErziehungsberechtigter()">
                            <i class="fa fa-check"></i>
                            <span data-translate="KONTAKTPERSON.ERZIEHUNGSBERECHTIGTER"></span>
                        </li>
                        <li ng-if="vm.kontaktperson.findRelationshipWithKindId(kind.id).isRechnungsempfaenger()">
                            <i class="fa fa-check"></i> <span data-translate="KONTAKTPERSON.RECHNUNGSEMPFAENGER"></span>
                        </li>
                        <li ng-if="vm.kontaktperson.findRelationshipWithKindId(kind.id).isAbholberechtigt()">
                            <i class="fa fa-check"></i> <span data-translate="KONTAKTPERSON.ABHOLBERECHTIGT"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
