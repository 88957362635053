/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Leistungsrechnung} from '@dv/kitadmin/models';
import type moment from 'moment';

export class LeistungsrechnungenByMonth {
    public constructor(
        public startOfMonth: moment.Moment,
        public leistungsrechnungen: Leistungsrechnung[] = [],
    ) {
    }
}
