/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxAngestellteZuweisungen} from '@dv/shared/backend/model/jax-angestellte-zuweisungen';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import type {CalendarEvent} from '../../calendar/timeline/model/CalendarEvent';
import type {CalendarResource} from '../../calendar/timeline/model/CalendarResource';
import {ZUWEISUNG_STATE} from '../anstellung/anstellung-states';
import type {Angestellte} from '../anstellung/models/Angestellte';
import {AngestellteZuweisung} from './AngestellteZuweisung';

export class AngestellteZuweisungen implements CalendarResource {

    public readonly link: string = ZUWEISUNG_STATE.name;
    public readonly linkParams;
    public readonly springer: boolean;

    public events: CalendarEvent[] = [];
    public inMultipleGroups?: boolean;

    public constructor(
        public angestellte: Persisted<Angestellte>,
        public zuweisungen: AngestellteZuweisung[] = [],
    ) {
        this.linkParams = {id: angestellte.id};
        this.springer = this.angestellte.anstellungen.some(
            anstellung => anstellung.anstellungStandorte.some(aso => aso.vertretungsperson));
    }

    public static apiResponseTransformer(
        data: JaxAngestellteZuweisungen,
        angestellte: Angestellte,
    ): AngestellteZuweisungen {
        const zuweisungen = DvbRestUtil.transformArray(data.zuweisungen, AngestellteZuweisung);

        return new AngestellteZuweisungen(checkPersisted(angestellte), zuweisungen);
    }

    public getDisplayName(): string {
        return this.angestellte.getDisplayName();
    }

    public getIcons(): { icon: string; description: string }[] {
        return this.springer ?
            [{icon: 'custom-icon custom-icon-chess-knight', description: 'PERSONAL.ANSTELLUNG.VERTRETUNGSPERSON'}] :
            [];
    }

    public get id(): string {
        return this.angestellte.id;
    }
}
