/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IExternalAnmeldungService} from '@dv/kitadmin/models';
import {AnmeldungAdresseHousehold, ExternalAnmeldung, Wochenplan} from '@dv/kitadmin/models';
import type {PageContainer, Persisted, RestInclude, RestPaginated} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {ExternalAnmeldungFilter} from '../model/ExternalAnmeldungFilter';

export class ExternalAnmeldungService implements IExternalAnmeldungService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/externalanmeldung`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getExternalAnmeldungenByKinderOrt(
        kinderOrtId: string,
        filter: ExternalAnmeldungFilter,
        params: RestPaginated,
        config: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<ExternalAnmeldung>> {

        const matrixParams = {
            ...params,
        };
        const url = `${ExternalAnmeldungService.BASE_URL}/kinderort/${encodeURIComponent(kinderOrtId)}`;

        return DvbRestUtilAngularJS.postAndGetPagedItems(
            url,
            filter.toRestObject(),
            ExternalAnmeldung,
            matrixParams,
            config);
    }

    public get(id: string, params?: RestInclude): angular.IPromise<Persisted<ExternalAnmeldung>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(
            ExternalAnmeldungService.BASE_URL,
            ExternalAnmeldung,
            id,
            params)
            .then(checkPersisted);
    }

    public findAnmeldungAdresseHousehold(externalAnmeldungId: string): angular.IPromise<AnmeldungAdresseHousehold[]> {
        const url = `${ExternalAnmeldungService.BASE_URL}/${encodeURIComponent(externalAnmeldungId)}/matches`;

        return DvbRestUtilAngularJS.getPagedItems(url, AnmeldungAdresseHousehold).then(page => page.items);
    }

    public getWochenplan(externalAnmeldungId: string): angular.IPromise<Wochenplan | null> {
        const url = `${ExternalAnmeldungService.BASE_URL}/${encodeURIComponent(externalAnmeldungId)}/wochenplan`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Wochenplan);
    }

    public assign(anmeldungId: string, kindId: string): angular.IHttpPromise<void> {
        const url = `${ExternalAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/assign/${
            encodeURIComponent(kindId)}`;

        return this.$http.put(url, {});
    }

    public unAssign(externalAnmeldungId: string): angular.IHttpPromise<void> {
        const url = `${ExternalAnmeldungService.BASE_URL}/${encodeURIComponent(externalAnmeldungId)}/assign/`;

        return this.$http.delete(url, {});
    }

    public delete(externalAnmeldungId: string, deleteAssignedKind: boolean): angular.IHttpPromise<void> {
        const matrixParams = DvbRestUtil.encodeMatrixParams({deleteAssignedKind});

        const url = `${ExternalAnmeldungService.BASE_URL}/delete/${encodeURIComponent(externalAnmeldungId)}${matrixParams}`;

        return this.$http.delete(url, {});
    }
}
