<div class="dvb-mandant-erstellen">
    <div class="row dvb-form">
        <div class="col-md-6 col-md-offset-3">
            <h1 data-translate="COMMON.MANDANT.ERSTELLEN"></h1>

            <form name="mandantErstellenForm" ng-submit="vm.validateAndSaveMandant(mandantErstellenForm)" novalidate>
                <div class="form-section-minus-normal-abstand">
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text"
                                   name="mandantName"
                                   class="form-control"
                                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                                   tooltip-placement="top-left"
                                   maxlength="255"
                                   ng-model="vm.mandant.name"
                                   required>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col-md-6">
                            <dvb-subscription-model mandant="vm.mandant"></dvb-subscription-model>

                            <dvb-mandant-sprachauswahl mandant="vm.mandant"></dvb-mandant-sprachauswahl>
                        </div>
                        <div class="col-md-6">
                            <dvb-kitadmin-module mandant="vm.mandant"></dvb-kitadmin-module>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" ng-model="vm.isDemoMandant"/>
                                    <span data-translate="MANDANT.MANDANT_MIT_DEMODATEN"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           name="hasStandardWochenplan"
                                           ng-model="vm.hasStandardWochenplan">
                                    <span data-translate="MANDANT.MIT_STANDARD_WOCHENPLAN"></span>
                                </label>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <input type="number"
                                   name="warningInterval"
                                   class="form-control"
                                   dvb-integer
                                   min="0"
                                   max="370"
                                   step="1"
                                   ng-attr-placeholder="{{'MANDANT.WARNING_INTERVAL_IN_DAYS' | translate:{tage: vm.defaultWarningIntervalInDays} }}"
                                   uib-tooltip="{{'MANDANT.WARNING_INTERVAL_IN_DAYS' | translate:{tage: vm.defaultWarningIntervalInDays} }}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.mandant.warningIntervalInDays">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <dvb-datepicker-text-field ng-model="vm.mandant.abgerechnetBis"
                                                       required
                                                       dvb-end-of-prev-month
                                                       ng-attr-placeholder="{{'MANDANT.ABGERECHNET_BIS' | translate}}">
                            </dvb-datepicker-text-field>
                        </div>
                        <div class="col-md-6">
                            <input type="number"
                                   name="abrechnungVorausZusatzMonate"
                                   class="form-control"
                                   dvb-integer
                                   min="0"
                                   max="18"
                                   step="1"
                                   ng-attr-placeholder="{{'MANDANT.ABRECHNUNG_VORAUS_ZUSATZ_MONATE' | translate}}"
                                   uib-tooltip="{{'MANDANT.ABRECHNUNG_VORAUS_ZUSATZ_MONATE' | translate}}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.mandant.abrechnungVorausZusatzMonate"
                                   required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           name="oidcUserManagement"
                                           ng-model="vm.mandant.oidcUserManagement"
                                           ng-change="vm.updateMandant()">
                                    <span data-translate="AUTHENTICATION.OIDC_USER_MANAGEMENT"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-section-minus-normal-abstand">
                    <h2 data-translate="COMMON.ADRESSE"></h2>
                    <dvb-adresse-form ng-model="vm.mandant.adresse"></dvb-adresse-form>
                </div>

                <div class="form-section">
                    <ng-form name="supportAdminBenutzer">
                        <h2 data-translate="BENUTZER.SUPPORT_ADMIN"></h2>
                        <dvb-benutzer-form benutzer="vm.supportAdminBenutzer"
                                           allow-password-change="true">
                        </dvb-benutzer-form>
                    </ng-form>
                </div>
                <div class="form-section">
                    <ng-form name="adminBenutzer">
                        <h2 data-translate="BENUTZER.ADMIN"></h2>
                        <dvb-benutzer-form benutzer="vm.adminBenutzer"
                                           allow-password-change="true">
                        </dvb-benutzer-form>
                    </ng-form>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <dvb-loading-button name="mandantErstellen" type="submit" is-loading="vm.isLoading">
                                    <span data-translate="COMMON.MANDANT.ERSTELLEN"></span>
                                </dvb-loading-button>
                            </li>
                            <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
