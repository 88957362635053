/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Belegung, Kind} from '@dv/kitadmin/models';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {BelegungsService} from '../../service/rest/kind/belegungsService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        belegung: '<',
        kind: '<',
        showLineTop: '<',
        isDateClickable: '<?',
        onDateSelected: '&',
        onRevertAustritt: '&',
    },
    template: require('./dvb-kind-austritt-collapsible-panel.html'),
    controllerAs: 'vm',
};

export class DvbKindAustrittCollapsiblePanel {
    public static $inject: readonly string[] = ['belegungsService', '$state'];

    public belegung!: Belegung;
    public kind!: Kind;
    public showLineTop!: boolean;
    public isDateClickable?: boolean;
    public onDateSelected!: (param: { date: moment.Moment }) => void;
    public onRevertAustritt!: (param: { austrittsDatum: moment.Moment }) => void;

    public titleKey: string | null = null;

    public constructor(
        private belegungsService: BelegungsService,
        private $state: StateService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.belegung) {
            this.updateAustrittTitel(changes.belegung.currentValue.austrittProvisorisch);
        }
    }

    public revertAustritt(austrittsDatum: moment.Moment): void {
        this.onRevertAustritt({austrittsDatum});
    }

    public resetAustrittProvisorisch(belegung: Belegung): void {
        this.belegungsService.resetAustrittProvisorisch(belegung.id!).then(() => {
            this.$state.reload();
        });
    }

    private updateAustrittTitel(austrittProvisorisch: boolean): void {
        this.titleKey = austrittProvisorisch ?
            'KIND.KIND_AUSTRITT_PROVISORISCH_VERLAUF_TITLE' :
            'KIND.KIND_AUSTRITT_VERLAUF_TITLE';
    }
}

componentConfig.controller = DvbKindAustrittCollapsiblePanel;
angular.module('kitAdmin').component('dvbKindAustrittCollapsiblePanel', componentConfig);
