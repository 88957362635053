<div class="create-firmen-kontingent-value">

    <form name="createFirmenKontingentValueForm" ng-submit="vm.save(vm.firmenKontingentValue)" novalidate>

        <h2 data-translate="{{vm.titleTranslationKey}}"></h2>

        <div class="normal-abstand-top neue-kapazitaet">
            <div class="row">
                <div class="col-md-6">
                    <!-- Durch ng-if wird der Date Picker immer wieder neu initialisiert -->
                    <dvb-datepicker-text-field ng-model="vm.firmenKontingentValue.gueltigAb"
                                               required
                                               ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                    </dvb-datepicker-text-field>
                </div>
                <div class="col-md-6">
                    <input type="number"
                           class="form-control"
                           name="firmenKontingentValuePlaetze"
                           dvb-select-on-click
                           ng-attr-placeholder="{{vm.plaetzePlaceholderTranslationKey | translate}}"
                           uib-tooltip="{{vm.plaetzePlaceholderTranslationKey | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.firmenKontingentValue.plaetze"
                           required
                           ng-pattern="/^\d+(\.\d[05]?)?$/"
                           min="0">
                </div>
            </div>

            <div class="form-row" ng-show="vm.firmenKontingentValue.isKitaSet()">
                <div class="row selected-entity"
                     ng-repeat="kita in vm.firmenKontingentValue.kitas"
                     ng-init="searchResultEntry = kita.toSearchResultEntry()">
                    <div class="col-xs-8 entity">
                        <dvb-search-result-icon entry="searchResultEntry"></dvb-search-result-icon>
                        <span ng-bind="kita.getDisplayName()"></span>
                    </div>
                    <div class="col-xs-4 edit">
                        <a href=""
                           ng-click="vm.removeKita(vm.firmenKontingentValue, kita)"
                           data-translate="COMMON.LOESCHEN">
                        </a>
                    </div>
                </div>
            </div>
            <dvb-search-entity entity-to-search="KINDERORT"
                               on-select="vm.addKita(vm.firmenKontingentValue,item)"
                               on-select-clear="true"
                               placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                               disabled-entries="vm.firmenKontingentValue.kitas"
                               ng-class="{ 'ng-invalid' : !vm.firmenKontingentValue.isKitaSet() }">
            </dvb-search-entity>
            <dvb-search-get-single-entity entity-to-search="TARIF"
                                          ng-model="vm.tarifSearchResultEntry"
                                          ng-change="vm.updateTarif()"
                                          display-label="FIRMA.TITLE_TARIF"
                                          placeholder-key="COMMON.TARIF.AUSWAEHLEN">
            </dvb-search-get-single-entity>
        </div>
        <ul class="list-inline normal-abstand-top">
            <li>
                <button type="submit" class="btn btn-primary">
                    <span data-translate="COMMON.SPEICHERN"></span>
                </button>
            </li>
            <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.reset()"></a></li>
        </ul>
    </form>

</div>
