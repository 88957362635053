<div class="dvb-recalculate-controlling-kinderort">
    <h2 data-translate="ADMINISTRATION.MTNC_CONTROLLING_RECALCULATION"></h2>

    <form class="dvb-form"
          name="recalculateKinderOrtForm"
          ng-submit="vm.recalculateControllingData(recalculateKinderOrtForm)"
          novalidate>
        <div class="row">
            <div class="col-xs-6">

                <input type="text"
                       class="form-control"
                       ng-attr-placeholder="{{'COMMON.YEAR' | translate}}"
                       uib-tooltip="{{'COMMON.YEAR' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.year"
                       required>
            </div>
            <div class="col-xs-6">
                <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                              ng-model="vm.kinderOrt"
                                              required
                                              as-col="true"
                                              mandant-filter="vm.mandantFilter"
                                              display-label="COMMON.KINDERORT.SINGULAR"
                                              placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                </dvb-search-get-single-entity>
            </div>
        </div>

        <div class="normal-abstand-top">
            <ul class="list-inline">
                <li>
                    <!-- intentionally not a loading button, to test async handling -->
                    <button type="submit"
                            class="btn btn-primary"
                            data-translate="ADMINISTRATION.MTNC_CONTROLLING_RECALCULATION_CALC_ASYNC">
                    </button>
                </li>
                <li>
                    <!-- intentionally not a loading button, to test async handling -->
                    <button type="button"
                            class="btn"
                            ng-click="vm.getControllingData(recalculateKinderOrtForm)"
                            data-translate="ADMINISTRATION.MTNC_CONTROLLING_RECALCULATION_CALC_SYNC">
                    </button>
                </li>
                <li>
                    <!-- intentionally not a loading button, to test async handling -->
                    <button type="button"
                            class="btn"
                            ng-click="vm.deleteControllingData(recalculateKinderOrtForm)"
                            data-translate="COMMON.LOESCHEN">
                    </button>
                </li>
            </ul>
        </div>
    </form>
</div>
