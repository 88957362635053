<div class="row dvb-bring-abhol-zeiten-management">
    <div class="col-md-7">

        <div class="header">
            <div class="row header-row">
                <div class="col-xs-12 header-box">
                    <h1 data-translate="COMMUNICATION.BRING_ABHOLZEITEN.TITLE"
                        data-translate-value-date="{{vm.gueltigAb | amDateFormat:'MMMM YYYY'}}"></h1>
                </div>
            </div>
        </div>

        <dvb-communication-kind-incomplete-recipients
            class="full-width-block big-abstand-top"
            ng-if="vm.bringAbholState.emailMissing.length > 0"
            kinder="vm.bringAbholState.emailMissing"
            error="'ERRORS.ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL_SHORT'">
        </dvb-communication-kind-incomplete-recipients>

        <dvb-communication-kind-selection
            class="full-width-block big-abstand-top"
            ng-if="vm.bringAbholState.readyToSend.length > 0"
            kinder="vm.bringAbholState.readyToSend"
            title-mf="'COMMUNICATION.READY_TO_SEND.MF'"
            action-name-mf="'COMMUNICATION.SEND_EMAILS.MF'"
            on-submit="vm.release(kinder)">
        </dvb-communication-kind-selection>

        <dvb-communication-kind-selection
            class="full-width-block big-abstand-top"
            ng-if="vm.bringAbholState.withoutFlexiblePlaetze.length > 0"
            kinder="vm.bringAbholState.withoutFlexiblePlaetze"
            title-mf="'COMMUNICATION.BRING_ABHOLZEITEN.NO_FLEXIBLE_PLAETZE'"
            action-name-mf="'COMMUNICATION.BRING_ABHOLZEITEN.CREATE_MONATSBELEGUNG_MF'"
            on-submit="vm.createMonatsBelegungen(kinder)">
        </dvb-communication-kind-selection>

        <dvb-communication-kind-selection
            class="full-width-block big-abstand-top"
            ng-if="vm.bringAbholState.withoutFlexiblePlaetzeExistingMonatsBelegung.length > 0"
            kinder="vm.bringAbholState.withoutFlexiblePlaetzeExistingMonatsBelegung"
            title-mf="'COMMUNICATION.BRING_ABHOLZEITEN.NO_FLEXIBLE_PLAETZE_EXISTING_MONATSBELEGUNG'"
            action-name-mf="'COMMUNICATION.BRING_ABHOLZEITEN.CREATE_MONATSBELEGUNG_MF'"
            disable-actions="true">
        </dvb-communication-kind-selection>

        <dvb-communication-kind-sent
            class="full-width-block big-abstand-top"
            ng-if="vm.bringAbholState.withExistingEmails.length > 0"
            bring-abhol-zeiten-access="vm.bringAbholState.withExistingEmails"
            on-resend="vm.resend(kinder)"
            on-adjust-deadline="vm.adjustDeadline(kinder)"
            on-withdraw-from-parents="vm.withdrawFromParents(kinder)">
        </dvb-communication-kind-sent>

        <ul class="list-inline normal-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link cancel-button"
                        ng-click="vm.goBack()"
                        data-translate="COMMON.ABBRECHEN">
                </button>
            </li>
        </ul>

    </div>
</div>
