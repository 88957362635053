/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, RestCache} from '@dv/kitadmin/models';
import {KinderOrtTransformer, MonatsBelegung} from '@dv/kitadmin/models';
import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class MonatsBelegungRestService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/monatsbelegungen`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getMonatsBelegung(
        monatsBelegungId: string,
        params: RestInclude & RestCache,
    ): angular.IPromise<MonatsBelegung> {
        const url = MonatsBelegungRestService.BASE_URL;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, MonatsBelegung, monatsBelegungId, params)
            .then(checkPresent);
    }

    public deleteMonatsBelegung(monatsBelegungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MonatsBelegungRestService.BASE_URL}/${encodeURIComponent(monatsBelegungId)}`;

        return this.$http.delete(url);
    }

    public createMonatsBelegungen(
        gueltigAb: moment.Moment,
        kinderOrtId: string,
        kindIds: string[],
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MonatsBelegungRestService.BASE_URL}/createdefault`;

        return this.$http.post(url, {
            gueltigAb: DvbRestUtil.momentToLocalDate(gueltigAb),
            kinderOrtId,
            kindIds,
        });
    }

    public getKinderOrt(monatsBelegungId: string): angular.IPromise<Persisted<KinderOrt>> {
        const url = `${MonatsBelegungRestService.BASE_URL}/${encodeURIComponent(monatsBelegungId)}/kinderort`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KinderOrtTransformer.create())
            .then(checkPersisted);
    }
}
