/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DisplayTask, InternalRechnungsStatus} from '@dv/kitadmin/models';
import {Rechnung, RechnungDisplayMode, RechnungsStatus} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {
    DvbDateUtil,
    DvbError,
    DvbUtil,
    isPersisted,
    isRechnungslaufError,
    LogFactory,
    RechnungsLaufError,
} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import {catchError, EMPTY, from, of, take, tap} from 'rxjs';
import type {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';

import type {FakturaFristAendernDialogModel} from '../../faktura-frist-aendern/faktura-frist-aendern.component';
import {FakturaFristAendernComponent} from '../../faktura-frist-aendern/faktura-frist-aendern.component';
import type {
    RechnungGebuehrenBearbeitenDialogModel,
} from '../rechnung-gebuehren-bearbeiten/rechnung-gebuehren-bearbeiten.component';
import {
    RechnungGebuehrenBearbeitenComponent,
} from '../rechnung-gebuehren-bearbeiten/rechnung-gebuehren-bearbeiten.component';

const componentConfig: angular.IComponentOptions = {
    require: {dvbDownloadCtrl: '^dvbDownload'},
    transclude: false,
    bindings: {
        rechnung: '<',
    },
    template: require('./dvb-rechnung-uebersicht.html'),
    controllerAs: 'vm',
};

const LOG = LogFactory.createLog('DvbRechnungUebersicht');

export class DvbRechnungUebersicht implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'fakturaService',
        'errorService',
        'dialogService',
        '$translate',
    ];

    public rechnung!: Persisted<Rechnung>;

    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.UEBERSICHT;
    public status: typeof InternalRechnungsStatus = Rechnung.STATUS;
    public isDownloading: boolean = false;
    public isOriginalDownloading: boolean = false;
    public isRevisionenLoading: boolean = false;
    public isStatusChanging: boolean = false;
    public isDueDateDeviating: boolean = false;
    public availableStateTransitions: Readonly<RechnungsStatus>[] = [];
    public deliveryFailureTask: DisplayTask | null = null;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private $state: StateService,
        private fakturaService: FakturaService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(onChangesObj: IOnChangesObject): void {
        if (onChangesObj.rechnung) {
            this.availableStateTransitions = [];

            if (onChangesObj.rechnung.currentValue) {
                const currentValue: Persisted<Rechnung> = onChangesObj.rechnung.currentValue;

                // Stornieren soll immer sichtbar sein, auch wenn die Aktion nicht erlaubt ist:
                const storniert = [RechnungsStatus.EXTERNAL_STATUS.STORNIERT];
                const array = currentValue.stateTransitions.concat(storniert);
                this.availableStateTransitions = DvbUtil.uniqueArray(array);

                this.updateDueDateDeviation();

                if (currentValue.deliveryFailure && !this.deliveryFailureTask) {
                    this.fakturaService.getDeliveryFailureTask(currentValue.id).then(response => {
                        this.deliveryFailureTask = response;
                    });
                }
            }
        }
    }

    public downloadPdf(): void {
        this.downloadHelper(false);
    }

    public downloadOriginalPdf(): void {
        this.downloadHelper(true);
    }

    public downloadHelper(downloadOriginal: boolean): void {
        this.errorService.clearAll();

        if (downloadOriginal) {
            this.isOriginalDownloading = true;
        } else {
            this.isDownloading = true;
        }

        const blobPromise = downloadOriginal ?
            this.fakturaService.getTempBlobForRechnungsRevision(this.rechnung.rechnungsRevisionId!) :
            this.fakturaService.getTempBlobForRechnung(this.rechnung.id);

        blobPromise.then(tempBlob => {
            if (isPersisted(tempBlob)) {
                this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
            } else {
                this.errorService.addValidationError('ERRORS.ERR_DOWNLOAD');
            }
        }).catch(error => {
            this.errorService.addValidationError('ERRORS.ERR_DOWNLOAD');
            LOG.error('Konnte die Rechnung nicht herunterladen', error);
        }).finally(() => {
            if (downloadOriginal) {
                this.isOriginalDownloading = false;
            } else {
                this.isDownloading = false;
            }
        });
    }

    public changeState(state: Readonly<RechnungsStatus>): void {
        this.isStatusChanging = true;

        if (state === RechnungsStatus.EXTERNAL_STATUS.STORNIERT) {
            this.stornieren().finally(() => {
                this.isStatusChanging = false;
            });

            return;
        }

        this.fakturaService.changeRechnungsStatus(this.rechnung.id, state).then(() => {
            return this.$state.reload();
        }).finally(() => {
            this.isStatusChanging = false;
        });
    }

    public stornieren(): angular.IPromise<unknown> {
        return this.fakturaService.getRelatedRechnungen(this.rechnung.id).then(rechnungen => {
            const params = {
                rechnungId: this.rechnung.id,
                rechnungen,
            };

            this.$state.go('base.rechnung.stornieren', params);
        });
    }

    public saveBemerkung(): void {
        this.fakturaService.saveRechnungBemerkung(this.rechnung.id, this.rechnung.bemerkung);
    }

    public changeZahlungsFirst(): void {

        const dialogModel: FakturaFristAendernDialogModel = {
            rechnung: this.rechnung,
            confirm: zahlungsFrist => from(this.fakturaService.saveZahlungsFrist(this.rechnung.id, zahlungsFrist))
                .pipe(tap(() => {
                    this.rechnung.zahlungsFrist = zahlungsFrist;
                    this.updateDueDateDeviation();
                })),
        };
        this.dialogService.openDialog(FakturaFristAendernComponent, dialogModel);
    }

    public updateDueDateDeviation(): void {
        this.isDueDateDeviating = !DvbDateUtil.isMomentEquals(
            this.rechnung.faelligAm,
            this.rechnung.zahlungsFrist);
    }

    public editGebuehren(): void {

        const dialogModel: RechnungGebuehrenBearbeitenDialogModel = {
            rechnung: this.rechnung,
            confirm: customPositions =>
                from(this.fakturaService.saveCustomRechnungsPositionen(this.rechnung.id, customPositions)).pipe(
                    tap(() => this.$state.reload()),
                    catchError(error => {
                        if (error instanceof DvbError && isRechnungslaufError(error)) {
                            this.errorService.handleError(error);

                            const fakturaError = RechnungsLaufError.apiResponseTransformer(error.args.fakturaError);
                            const dvbErrors = fakturaError.toDvbErrors(this.$translate);
                            dvbErrors.forEach(dvbError => this.errorService.handleError(dvbError));

                            return of(EMPTY);
                        }

                        throw new Error(error);
                    }),
                ),
        };

        this.dialogService.openDialog(RechnungGebuehrenBearbeitenComponent, dialogModel);
    }

    public removeDeliveryFailureTask(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'FAKTURA.RECHNUNGS_REVISION_DELIVERY_FAILURE_TASK_LOESCHEN',
            confirm: () => from(this.fakturaService.removeRechnungDeliveryFailureTask(this.rechnung.id))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }
}

componentConfig.controller = DvbRechnungUebersicht;
angular.module('kitAdmin').component('dvbRechnungUebersicht', componentConfig);
