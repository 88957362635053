/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtId} from '@dv/kitadmin/models';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../config';
import {BetreuungsGutscheinService} from '../../common/service/rest/kind/betreuungsGutscheinService';

export class MaintenanceService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/auth/login/admin`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public purgeBatchJobs(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/purgejobs`);
    }

    public getAllBatchJobs(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/jobs`);
    }

    public getBatchJob(jobId: string): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/jobs/${encodeURIComponent(jobId)}`);
    }

    public startLeistungsrechnung(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/leistungsrechnung`);
    }

    public cleanupSchulungsMandanten(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/cleanupschulungsmandanten`);
    }

    public initDvbDemoData(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/demodata/DVBern`);
    }

    public initWtcDemoData(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/demodata/WorkTimeControlling`);
    }

    public archiveKinder(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/kinderarchival`);
    }

    public unarchiveKinder(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/batch/kinderunarchival`);
    }

    /**
     * Clears ControllingData for all years up to the given year (inclusive)
     */
    public clearAllControllingDataUpTo(year: number): angular.IHttpPromise<unknown> {
        const url = `${MaintenanceService.BASE_URL}/reloadControllingData/clear/${encodeURIComponent(year.toString())}`;

        return this.$http.get(url);
    }

    /**
     * Clears auth cache for all users.
     */
    public clearAuthCache(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/clearAuthCache`);
    }

    /**
     * Creates missing rechnungsUeberschussTasks.
     */
    public createRechnungsUeberschussTasks(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/migrations/createRechnungsUeberschussTasks`);
    }

    /**
     * Recalculates ControllingData for all Kitas for the given year.
     */
    public recalculateControllingData(year: number): angular.IHttpPromise<unknown> {
        const url = `${MaintenanceService.BASE_URL}/reloadControllingData/${encodeURIComponent(year.toString())}`;

        return this.$http.get(url);
    }

    /**
     * Recalculates ControllingData of the KinderOrt for the given year.
     */
    public recalculateKinderOrtControllingData(id: KinderOrtId, year: number): angular.IHttpPromise<unknown> {
        const url = `${MaintenanceService.BASE_URL}/reloadControllingData/` +
            `${encodeURIComponent(year.toString())}/${encodeURIComponent(id)}`;

        return this.$http.get(url);
    }

    /**
     * Delete ControllingData of the KinderOrt for the given year.
     */
    public deleteKinderOrtControllingData(id: KinderOrtId, year: number): angular.IHttpPromise<unknown> {
        const url = `${MaintenanceService.BASE_URL}/reloadControllingData/` +
            `${encodeURIComponent(year.toString())}/${encodeURIComponent(id)}`;

        return this.$http.delete(url);
    }

    /**
     * Delete MandantRechnungsPositionen in range.
     */
    public deleteRechnungsPositionen(
        kinderOrtId: KinderOrtId,
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
    ): angular.IHttpPromise<unknown> {
        const matrixParams = {
            gueltigAb: DvbRestUtil.momentToLocalDate(gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(gueltigBis),
        };

        const url = `${MaintenanceService.BASE_URL}/deleteMandantRechnungsPositionen/` +
            `${encodeURIComponent(kinderOrtId)}${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.delete(url);
    }

    public reindex(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/reindex`);
    }

    public matchKibonExchangeData(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${BetreuungsGutscheinService.BASE_URL}/kibonexchange/match`);
    }

    public shiroTestingBasics(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/shiro/basics`);
    }

    public shiroTestingSuperAdmin(blocking: boolean): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/shiro/superadmin?blocking=${blocking}`);
    }

    public cleanupBlobData(): angular.IHttpPromise<unknown> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/cleanupblobdata`);
    }

    public reloadKibonExchangeInstitutionen(): angular.IPromise<angular.IHttpResponse<unknown>> {
        return this.$http.get(`${MaintenanceService.BASE_URL}/reloadKibonExchangeInstitutionen`);
    }
}
