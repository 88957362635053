/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import {KitaTarifeZeitraum} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifeZuweisungen: '<',
        kita: '<',
    },
    template: require('./kita-tarife.html'),
    controllerAs: 'vm',
};

export class DvbKitaTarife implements angular.IController {

    public static $inject: readonly string[] = [
        'kinderOrtService',
        'dialogService',
        'errorService',
        '$state',
        '$translate',
    ];

    public tarifeZuweisungen!: KitaTarifeZeitraum[];
    public kita!: Persisted<KinderOrt>;

    public isLoading = false;
    public kitaTarifeZeitraum?: KitaTarifeZeitraum | null;

    public constructor(
        private readonly kinderOrtService: KinderOrtService,
        private readonly dialogService: DialogService,
        private readonly errorService: ErrorService,
        private readonly $state: StateService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public neueTarifeDefinieren(): void {
        this.kitaTarifeZeitraum = new KitaTarifeZeitraum();
        this.kitaTarifeZeitraum.initMissingAbrechnungsTarife();
        this.kitaTarifeZeitraum.kitaId = this.kita.id;
    }

    public terminateTarifZuweisung(): void {
        const beendigungAction = (endDate: moment.Moment): Observable<unknown> => {
            this.errorService.clearAll();

            return from(this.kinderOrtService.terminateTarifZuweisung(this.kita.id, endDate)
                .then(() => this.reloadTarifeZuweisungen())).pipe(take(1));
        };

        const title = this.$translate.instant(
            'KINDERORT.TARIF_ZUWEISUNG_BEENDEN_HEADING',
            {kitaname: this.kita.getDisplayName()},
        );

        this.dialogService.openDateDialog({
            title,
            confirm: endDate => {
                const valuesNachEndeDatum = this.tarifeZuweisungen.filter(tz => tz.gueltigAb?.isAfter(endDate));

                if (valuesNachEndeDatum.length) {
                    const dialog$ = new Subject<any>();
                    this.dialogService.openConfirmDialog({
                        title: 'KINDERORT.CONFIRM_TARIF_ZUWEISUNG_BEENDEN',
                        confirmActionText: 'COMMON.BEENDEN',
                        confirm: () => from(Promise.resolve(beendigungAction(endDate))).pipe(
                            tap(() => dialog$.next(null))),
                        cancel: () => dialog$.next(null),
                    });

                    return dialog$.pipe(take(1));
                } else {
                    return beendigungAction(endDate);
                }
            },
        });
    }

    public revertKitaTarifeZeitraum(kitaTarifeZeitraum: Persisted<KitaTarifeZeitraum>): void {
        this.errorService.clearAll();
        this.kinderOrtService.extendKitaTarifeZeitraum(kitaTarifeZeitraum.id)
            .then(() => this.reloadTarifeZuweisungen());
    }

    public reloadTarifeZuweisungen(): angular.IPromise<unknown> {
        this.kitaTarifeZeitraum = null;

        return this.kinderOrtService.getTarifeZuweisungen(this.kita.id).then(response => {
            this.tarifeZuweisungen = response;
        });
    }

    public saveKitaTarifeZeitraum(kitaTarifeZeitraum: Persisted<KitaTarifeZeitraum>): void {
        this.isLoading = true;
        this.kinderOrtService.createKitaTarifeZeitraum(kitaTarifeZeitraum)
            .then(() => this.reloadTarifeZuweisungen())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public showConfirmDeleteKitaTarifeModal(kitaTarifeZeitraum: Persisted<KitaTarifeZeitraum>): void {
        this.dialogService.openDeleteDialog({
            entityText: 'KINDERORT.KITA_TARIFZUWEISUNG',
            confirm: () => {
                this.errorService.clearAll();

                return from(this.kinderOrtService.deleteKitaTarifeZeitraum(kitaTarifeZeitraum.id))
                    .pipe(take(1), tap(() => this.$state.reload()));
            },
        });
    }

}

componentConfig.controller = DvbKitaTarife;
angular.module('kitAdmin').component('dvbKitaTarife', componentConfig);
