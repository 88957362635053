<div class="has-datepicker highlight-input-fields-when-invalid">
    <div class="datepicker"
         uib-datepicker-popup="dd.MM.yyyy"
         data-datepicker-append-to-body="false"
         data-datepicker-popup-template-url="dvb-datepicker-text-field-popup-template.html"
         data-datepicker-options="vm.dateOptions"
         data-ng-model="vm.dateObject"
         data-is-open="vm.opened"
         uib-tooltip="{{vm.placeholder}}"
         tooltip-placement="top-left">

        <label class="form-control click2edit"
               ng-class="{'is-empty' : !vm.dateString, 'input-sm': vm.smallInputs}"
               ng-bind="vm.getLabelText()">
        </label>

        <div class="dvb-datepicker-text-field input-group">
            <input type="text"
                   class="form-control"
                   ng-class="{'input-sm': vm.smallInputs}"
                   name="date"
                   ng-model="vm.dateString"
                   ng-disabled="vm.isDisabled"
                   ng-blur="vm.prefixYearDigits()"
                   ng-keyup="$event.keyCode === 13 && vm.prefixYearDigits()"
                   ng-attr-placeholder="{{vm.placeholder}}">

            <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default popup-btn"
                        ng-class="{'btn-sm': vm.smallInputs}"
                        ng-click="vm.openCalendar($event)"
                        ng-disabled="vm.isDisabled">
                    <i class="fa fa-calendar"></i>
                </button>
            </span>
        </div>
    </div>
</div>
