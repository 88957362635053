<div class="dvb-kind-tarife-betreuungsgutscheine-form">
    <form name="kindTarifeBetreuungsgutscheineForm"
          ng-submit="vm.onSubmit(kindTarifeBetreuungsgutscheineForm)"
          class="dvb-form"
          novalidate>
        <div class="history-columns">
            <div class="history-column-middle">
                <div class="row">
                    <div class="col-md-6">
                        <input type="text"
                               name="bemerkung"
                               class="form-control"
                               maxlength="1000"
                               ng-attr-placeholder="{{'KIND.IDENTIFIKATIONSNUMMER' | translate}}"
                               uib-tooltip="{{'KIND.IDENTIFIKATIONSNUMMER' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.betreuungsGutschein.bemerkung">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.betreuungsGutschein.gueltigAb"
                                                   name="gueltigAb"
                                                   required="true"
                                                   ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.betreuungsGutschein.gueltigBis"
                                                   name="gueltigBis"
                                                   required="true"
                                                   ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                                      required
                                                      ng-model="vm.kitaSearchResultEntry"
                                                      ng-change="vm.updateKita()"
                                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                                      as-col="true">
                        </dvb-search-get-single-entity>

                    </div>
                    <div class="col-md-6">
                        <input type="number"
                               name="bgPensumProzent"
                               min="0"
                               class="form-control"
                               ng-attr-placeholder="{{'KIND.BG_PENSUM_PROZENT' | translate}}"
                               uib-tooltip="{{'KIND.BG_PENSUM_PROZENT' | translate}}"
                               tooltip-placement="top-left"
                               dvb-betreuungs-gutschein-bg-pensum
                               required
                               ng-model="vm.betreuungsGutschein.bgPensumProzent">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <input type="number"
                               name="vollkosten"
                               class="form-control"
                               ng-attr-placeholder="{{'KIND.VOLLKOSTEN_GUTSCHEIN' | translate}}"
                               uib-tooltip="{{'KIND.VOLLKOSTEN_GUTSCHEIN' | translate}}"
                               tooltip-placement="top-left"
                               dvb-currency-value
                               dvb-min-exclusive="0"
                               required
                               ng-model="vm.betreuungsGutschein.vollkosten">
                    </div>

                    <div class="col-md-6">
                        <input type="number"
                               name="verguenstigterBetrag"
                               class="form-control"
                               ng-attr-placeholder="{{'KIND.BETRAG_GUTSCHEIN' | translate}}"
                               uib-tooltip="{{'KIND.BETRAG_GUTSCHEIN' | translate}}"
                               tooltip-placement="top-left"
                               dvb-currency-value
                               dvb-min-exclusive="0"
                               required
                               ng-model="vm.betreuungsGutschein.verguenstigterBetrag">
                    </div>
                </div>
            </div>
        </div>

        <ul class="list-inline normal-abstand-top">
            <li>
                <dvb-loading-button type="submit" is-loading="kindTarifeBetreuungsgutscheineForm.$pending">
                    <span data-translate="COMMON.SPEICHERN"></span>
                </dvb-loading-button>
            </li>
            <li>
                <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.cancel()"></a>
            </li>
        </ul>
    </form>
</div>
