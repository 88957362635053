/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IAttributes} from 'angular';
import angular from 'angular';
import {AUTHORISATION_STRATEGIES} from '../../../authorisation/strategy/authorisation-strategies';
import type {ITranslatableScope} from '../dvb-translatable/dvb-translatable';

angular.module('kitAdmin').directive('dvbTranslatableInput', dvbTranslatableInput);

function dvbTranslatableInput(): angular.IDirective<ITranslatableScope> {
    return {
        require: {
            ngModelCtrl: 'ngModel',
        },
        restrict: 'A',
        link: (scope: ITranslatableScope, _element, attrs, ctrl: any): void => {
            const ngModelCtrl = ctrl.ngModelCtrl;
            const formCtrl = scope.formCtrl;
            const changeListener = (): void => updateVisibility(scope, attrs, ngModelCtrl, formCtrl);

            scope.$watch('formCtrl.$submitted', changeListener);
            scope.$watch('$dvbSelectedLang', changeListener);
            ctrl.ngModelCtrl.$viewChangeListeners.push(changeListener);
        },
    };
}

function updateVisibility(
    scope: ITranslatableScope,
    attrs: IAttributes,
    ngModelCtrl: angular.INgModelController,
    formCtrl?: angular.IFormController,
): void {
    const show = scope.$dvbSelectedLang === scope.$dvbLang ||
        (formCtrl?.$submitted && ngModelCtrl.$invalid);

    if (show) {
        AUTHORISATION_STRATEGIES.showElement(attrs);
    } else {
        AUTHORISATION_STRATEGIES.hideElement(attrs);
    }
}
