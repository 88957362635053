/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatement, BankStatementEntry, BankStatementEntryFilter} from '@dv/kitadmin/models';
import {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import type {LoadingContext, PageContainer} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, hasOwnPropertyGuarded} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {BankStatementService} from '../../../service/bankStatementService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        bankStatements: '<',
        gueltigAb: '<?',
        gueltigBis: '<?',
        states: '<?',
    },
    template: require('./dvb-transaktionen.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionen implements angular.IController {

    public static $inject: readonly string[] = ['bankStatementService', '$q'];

    public bankStatements: BankStatement[] = [];
    public gueltigAb?: string;
    public gueltigBis?: string;
    public states?: BankStatementEntryStatus[];

    public isLoading: boolean = true;
    public itemsPerPage: number = 0;
    public currentPage: number = 1;
    public filter: BankStatementEntryFilter | null = null;
    public pageContainer: PageContainer<BankStatementEntry> | null = null;

    public isAutoAssignmentConfirmation: boolean = false;

    private timeout?: angular.IDeferred<any> = undefined;

    public constructor(
        private readonly bankStatementService: BankStatementService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.filter = this.bankStatementService.getBankStatementEntryFilter();
        // initialise the filter array
        this.filter.bankStatementIds = this.bankStatements.map(bankStatement => checkPresent(bankStatement.id));
        this.itemsPerPage = this.bankStatementService.getItemsPerPage();

        const gueltigkeit = this.filter.gueltigkeit;

        if (this.gueltigAb && this.gueltigBis) {
            // use filter specified via state params
            gueltigkeit.gueltigAb = DvbRestUtil.localDateToMoment(this.gueltigAb);
            gueltigkeit.gueltigBis = DvbRestUtil.localDateToMoment(this.gueltigBis);
        }

        gueltigkeit.setCurrentMonthWhenUndefined();

        if (Array.isArray(this.states)) {
            // first, disable all states that have been active previously
            this.filter.states = {};

            // reactivate those that have been set in the parameter
            this.states.filter(key =>
                // check for valid keys, because this value is exposed in the URL as a GET param
                hasOwnPropertyGuarded(BankStatementEntryStatus, key),
            ).forEach(key => {
                this.filter!.states[key] = true;
            });
        }

        this.reloadEntries();
    }

    public updatePage(page: number): void {
        this.currentPage = page;
        this.reloadEntries();
    }

    public onUpdateItemsPerPage(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;
        this.bankStatementService.setItemsPerPage(itemsPerPage);
        this.reloadEntries();
    }

    public reloadEntries(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;
        this.isAutoAssignmentConfirmation = false;

        const params = checkPresent(this.filter).toRestObject();
        params.top = this.itemsPerPage;
        params.skip = (this.currentPage - 1) * this.itemsPerPage;

        const config = {
            timeout: this.timeout.promise,
        };

        this.bankStatementService.getEntries(params, config).then(pageContainer => {
            this.pageContainer = pageContainer;
            this.isLoading = false;
        }).catch(err => {
            if (!DvbRestUtil.isRequestCancelled(err)) {
                this.isLoading = false;
            }
        });
    }

    public autoAssign(context: LoadingContext): void {
        context.startLoading();
        const params = this.filter?.gueltigkeit?.toRestObject();
        this.bankStatementService.autoAssignEntries(params)
            .then(autoAssigned => {
                this.pageContainer = autoAssigned;
                this.isAutoAssignmentConfirmation = true;
            })
            .finally(() => {
                this.isLoading = false;
                context.finishLoading();
            });
    }
}

componentConfig.controller = DvbTransaktionen;
angular.module('kitAdmin').component('dvbTransaktionen', componentConfig);
