<div class="dvb-dashboard-empty">

    <div class="row">
        <div class="col-md-4 col-md-offset-8">
            <dvb-bring-abhol-zeiten-access-list
                ng-if="(vm.benutzer.loginTokens && vm.benutzer.loginTokens.length > 0)"
                benutzer="vm.benutzer">
            </dvb-bring-abhol-zeiten-access-list>
        </div>
    </div>
</div>
