<div class="row dvb-form">
    <div class="col-md-6 col-md-offset-3">

        <h1 data-translate="COMMON.MENU_KIND_DIREKTZUWEISUNG"></h1>

        <form name="anmeldeForm"
              ng-submit="!anmeldeForm.$pending && vm.validateAndSaveKind(anmeldeForm.$valid)"
              novalidate>

            <dvb-anmeldung-kind default-anmelde-datum="vm.defaultAnmeldeDatum"
                                on-kind-created="vm.onKindCreated(kind)">
            </dvb-anmeldung-kind>

            <div class="form-section">
                <h2 data-translate="KIND.BETREUUNGSGRUND"></h2>

                <dvb-betreuungsgrund betreuungsgruende-ids="vm.kind.betreuungsGruendeIds"></dvb-betreuungsgrund>
            </div>

            <div class="form-section">
                <h2 data-translate="COMMON.KINDERORT.SINGULAR"></h2>

                <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                              required
                                              ng-model="vm.kitaSearchResultEntry"
                                              placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                </dvb-search-get-single-entity>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button name="kindDirektZuweisen"
                                                type="submit"
                                                is-loading="anmeldeForm.$pending || vm.isLoading">
                                <span data-translate="KIND.KIND_DIREKT_ZUWEISEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
