/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Translation} from '@dv/kitadmin/models';
import angular from 'angular';
import {getTextForLanguage} from './get-text-for-language';

angular.module('kitAdmin').filter('dvbI18n', dvbI18n);

function dvbI18n(): (input: Translation) => string {
    return (input): string => {
        return getTextForLanguage(input);
    };
}
