/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kontaktperson} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class KontaktpersonEmail {

    public constructor(
        public kontaktperson: Persisted<Kontaktperson>,
        public emailSent: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KontaktpersonEmail {
        const kontaktperson = Kontaktperson.apiResponseTransformer(data.kontaktperson);

        return new KontaktpersonEmail(
            kontaktperson,
            DvbRestUtil.localDateTimeToMoment(data.emailSent));
    }
}
