/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IsoTypes} from '@dv/kitadmin/models';
import {IsoLanguagesAndCountriesService} from '@dv/kitadmin/models';
import type {Nullish, IsoObject} from '@dv/shared/code';
import {hasOwnPropertyGuarded} from '@dv/shared/code';
import angular from 'angular';
import TriggeredEvent = JQuery.TriggeredEvent;

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        ngModelCtrl: '^ngModel',
        dvbIsoLocale: 'dvbIsoLocale',
    },
    bindings: {
        model: '=ngModel',
        type: '@',
        placeholder: '@?',
    },
    template: require('./dvb-iso-locale.html'),
    controllerAs: 'vm',
};

export class DvbIsoLocale implements angular.IController {

    public static $inject: readonly string[] = ['$element'];

    public model?: IsoObject | null;
    public type!: IsoTypes;
    public placeholder?: string;

    public ngModelCtrl!: angular.INgModelController;

    public isoObject?: IsoObject | null;
    public isoObjects: IsoObject[] = [];

    private originalIsoObject?: IsoObject | null;

    public constructor(
        $element: JQLite,
    ) {
        $element.addClass('highlight-input-fields-when-invalid');
    }

    public $onInit(): void {
        this.placeholder = this.placeholder ?? '';

        this.ngModelCtrl.$render = this.render.bind(this);
        this.ngModelCtrl.$formatters.push(this.backupModelValueFormatter.bind(this));
        this.ngModelCtrl.$parsers.push(value => value);
        this.ngModelCtrl.$validators.isoObject = value => {
            return value === null || this.isValidIsoObject(value);
        };
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.type) {
            this.fetchIsoObjects(changes.type.currentValue);
        }
    }

    public backupModelValueFormatter(modelValue: IsoObject | Nullish): IsoObject | Nullish {
        if (this.isValidIsoObject(modelValue) || modelValue === null) {
            this.originalIsoObject = modelValue;
        }

        return modelValue;
    }

    public isValidIsoObject(isoObject: unknown): isoObject is IsoObject {
        if (!isoObject || !hasOwnPropertyGuarded(isoObject, 'isoCode')) {
            return false;
        }

        return this.isoObjects.filter(isoObj => isoObj.isoCode === isoObject.isoCode).length === 1;
    }

    public onSelect($item: IsoObject, $model: IsoObject, _$label: string): void {
        this.originalIsoObject = $model;
        // onSelect ist scope.this.isoObject noch nicht korrekt gesetzt -> selected Wert ($model) direkt in das
        // model schreiben
        this.updateModel($model);
    }

    public onChange(): void {
        const isEmpty = angular.isString(this.isoObject) && this.isoObject.length === 0;
        const isValid = isEmpty || this.isValidIsoObject(this.isoObject);

        if (isEmpty) {
            this.updateModel(null);
        }
        if (!isValid) {
            this.updateModel(this.isoObject);
        }
    }

    public cancel(e?: TriggeredEvent): void {
        if (e?.key === 'Escape') {
            this.updateModel(this.originalIsoObject);
        }
    }

    public onFocus(e: TriggeredEvent): void {
        // Wenn man in das Textfeld klick gleich den ganzen Text auswaehlen
        e.target.select();
    }

    private render(): void {
        // Mache $viewValue im Template verfuegbar
        this.isoObject = this.ngModelCtrl.$viewValue;
    }

    private updateModel(newValue: IsoObject | Nullish): void {
        this.ngModelCtrl.$setViewValue(angular.copy(newValue));
        this.ngModelCtrl.$render();
    }

    private fetchIsoObjects(type: IsoTypes): void {
        IsoLanguagesAndCountriesService.getIsoObjectsByType(type).then(isoObjects => {
            this.isoObjects = isoObjects;
            // sicherstellen, dass das Model neu validiert wird, nachdem die IsoObjects geladen wurden
            this.updateModel(this.isoObject);
        });
    }
}

componentConfig.controller = DvbIsoLocale;
angular.module('kitAdmin').component('dvbIsoLocale', componentConfig);
