/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxParameterKitasKontaktpersonen} from '@dv/shared/backend/model/jax-parameter-kitas-kontaktpersonen';
import {DvbUtil, SearchResultEntry} from '@dv/shared/code';
import type moment from 'moment';
import {ParameterKinderOrte} from './ParameterKinderOrte';

export class ParameterKinderOrteKontaktpersonen extends ParameterKinderOrte {

    public constructor(
        alleKinderOrte: boolean = false,
        kinderOrte: SearchResultEntry[] = [],
        public kontaktpersonen: SearchResultEntry[] = [],
        stichtag: moment.Moment | null = null,
        year: number | null = null,
    ) {

        super(alleKinderOrte, kinderOrte, stichtag, year);
    }

    public override isValid(): boolean {
        return super.isValid() || DvbUtil.isNotEmptyArray(SearchResultEntry.getEnabled(this.kontaktpersonen));
    }

    public override toRestObject(): JaxParameterKitasKontaktpersonen {
        return Object.assign(super.toRestObject(), {
            kontaktpersonIds: Array.isArray(this.kontaktpersonen) ?
                SearchResultEntry.getEnabled(this.kontaktpersonen).map(DvbUtil.mapToIdChecked) :
                [],
        });
    }
}
