/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import {Kontaktperson, RelationshipWithKind, RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
    },
    template: require('./kind-neue-kontaktperson.html'),
    controllerAs: 'vm',
};

export class DvbKindNeueKontaktperson implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'kontaktpersonService', 'errorService'];

    public kind!: Persisted<Kind>;

    public kontakt = new RelationshipWithKontaktperson();
    public adressDatum = DvbDateUtil.today();
    public isLoading = false;

    public constructor(
        private readonly $state: StateService,
        private readonly kontaktpersonService: KontaktpersonService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.kontakt.kontaktperson = new Kontaktperson();
    }

    public validateAndSaveKontaktperson(formIsValid: boolean): void {
        this.errorService.handleValidationError(formIsValid, 'ERRORS.ERR_INCOMPLETE_KONTAKTPERSON');
        if (!formIsValid) {
            return;
        }

        const relationshipWithKind = new RelationshipWithKind();
        relationshipWithKind.kindId = this.kind.id;
        relationshipWithKind.relationship = this.kontakt.relationship;

        this.isLoading = true;

        this.kontaktpersonService.create(checkPresent(this.kontakt.kontaktperson), relationshipWithKind)
            .then(() => this.goToKindKontakte())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goToKindKontakte(): angular.IPromise<unknown> {
        return this.$state.go('base.kind.kontakt', {kindId: this.kind.id});
    }
}

componentConfig.controller = DvbKindNeueKontaktperson;
angular.module('kitAdmin').component('dvbKindNeueKontaktperson', componentConfig);
