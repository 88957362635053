<div class="dvb-stunden-kontingent-content">

    <div ng-if="!vm.formContext.editMode">
        <div class="history-columns">
            <p class="history-column-left">
                <dvb-stunden-kontingent-title stunden-kontingent="vm.stundenKontingent"></dvb-stunden-kontingent-title>
            </p>

            <div class="history-column-middle">
                <p ng-bind="vm.kinderOrt.getDisplayName()"></p>
                <p><dvb-platz-typ-name kontingent-id="vm.stundenKontingent.kontingentId"></dvb-platz-typ-name></p>
                <p data-translate="FRAKTION.MONATSBLATT.STUNDEN_ANZAHL"
                   data-translate-value-anzahl="{{vm.stundenKontingent.stunden}}">
                </p>
            </div>
        </div>

        <ul class="list-inline normal-abstand-top" dvb-auth require-permission="kita:manage:general">
            <li>
                <button class="btn btn-link" ng-click="vm.formContext.enableEditMode()">
                    <span data-translate="COMMON.BEARBEITEN"></span>
                </button>
            </li>
            <li>
                <dvb-loading-button is-loading="vm.formContext.isLoading"
                                    as-link="true"
                                    spinner-right="true"
                                    on-click="vm.onDelete({stundenKontingent: vm.stundenKontingent, formContext: vm.formContext})">
                    <span data-translate="COMMON.LOESCHEN"></span>
                </dvb-loading-button>
            </li>
        </ul>
    </div>

    <div ng-if="vm.formContext.editMode">
        <dvb-stunden-kontingent-form stunden-kontingent="vm.stundenKontingent"
                                     kind="vm.kind"
                                     form-context="vm.formContext"
                                     on-update="vm.onUpdate({stundenKontingent: stundenKontingent, formContext: vm.formContext})"
                                     on-cancel="vm.formContext.disableEditMode()">
        </dvb-stunden-kontingent-form>
    </div>
</div>
