<div ng-if="vm.visible" class="dvb-external-anmeldung-privacy-confirmation row">

    <div class="col-md-12 info">
        <span data-translate="EXTERNAL_ANMELDUNG.ACCEPT_PRIVACY_POLICY"></span>
        <span ng-bind-html="'AUTHENTICATION.PRIVACY_SUBTITLE' | translate"></span>
    </div>
    <div class="col-md-12">
        <ul class="list-inline big-abstand-left"><!-- warum big-abstand-left, wenn in scss file margin-left: 0? -->
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.decline()"
                        data-translate="COMMON.DECLINE">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-primary"
                        ng-click="vm.accept()"
                        data-translate="AUTHENTICATION.PRIVACY_CONFIRM">
                </button>
            </li>
        </ul>
    </div>
</div>
