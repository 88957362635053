<div class="dvb-voreinstellungen-external-anmeldung row">
    <div class="col-md-7 big-abstand-bottom dvb-form">
        <h2 data-translate="EXTERNAL_ANMELDUNG.CONFIG.TITLE"></h2>

        <div class="form-section-minus-normal-abstand">
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label maxlength="255"
                                        ng-change="vm.save()"
                                        ng-model="vm.workingCopy.googleTagManagerContainerId"
                                        ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.TAG_MANAGER_CONTAINER' | translate}}">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label ng-change="vm.save()"
                                        ng-model="vm.workingCopy.redirectUrl"
                                        dvb-protocol-prepender
                                        ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.REDIRECT_URL' | translate}}">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label ng-change="vm.save()"
                                        ng-model="vm.workingCopy.agbUrl"
                                        dvb-protocol-prepender
                                        ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.AGB_URL' | translate}}">
                    </dvb-editable-label>
                </div>
                <div class="col-md-5 layout-row">
                    <dvb-editable-label class="full-width-block"
                                        ng-change="vm.save()"
                                        ng-model="vm.workingCopy.replyTo"
                                        ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.REPLY_TO_EMAIL' | translate}}"
                                        is-email="true"
                                        type="email">
                    </dvb-editable-label>
                    <dv-spf-info [reply-to-address]="vm.workingCopy.replyTo">
                    </dv-spf-info>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label ng-change="vm.save()"
                                        ng-model="vm.workingCopy.infoEmail"
                                        ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.INFO_EMAIL' | translate}}"
                                        is-email="true"
                                        type="email">
                    </dvb-editable-label>
                </div>
            </div>
        </div>
        <div class="form-section-minus-normal-abstand">
            <dvb-translatable-editable-text-area translation="vm.workingCopy.bemerkungTranslation"
                                                 placeholder="'EXTERNAL_ANMELDUNG.CONFIG.BEMERKUNG' | translate"
                                                 on-submit="vm.save()">
            </dvb-translatable-editable-text-area>
        </div>
        <div class="form-section-minus-normal-abstand">
            <div class="row">
                <div class="col-md-6">
                    <div class="checkbox">
                        <label uib-tooltip="{{'EXTERNAL_ANMELDUNG.CONFIG.SHOW_PRIVATER_PLATZ' | translate}}"
                               tooltip-placement="top-left">
                            <input type="checkbox"
                                   value="true"
                                   ng-model="vm.workingCopy.showPrivaterPlatz"
                                   ng-change="vm.save()">
                            <span data-translate="EXTERNAL_ANMELDUNG.CONFIG.SHOW_PRIVATER_PLATZ"></span>
                        </label>
                    </div>
                    <div ng-if="vm.workingCopy.showPrivaterPlatz" class="checkbox-indent">
                        <dvb-translatable-editable-label translation="vm.workingCopy.privaterPlatzTranslation"
                                                         on-Submit="vm.save()"
                                                         max-length="255">
                        </dvb-translatable-editable-label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="checkbox">
                        <label uib-tooltip="{{'EXTERNAL_ANMELDUNG.CONFIG.SHOW_SUBVENTIONIERTER_PLATZ' | translate}}"
                               tooltip-placement="top-left">
                            <input type="checkbox"
                                   value="true"
                                   ng-model="vm.workingCopy.showSubventionierterPlatz"
                                   ng-change="vm.save()">
                            <span data-translate="EXTERNAL_ANMELDUNG.CONFIG.SHOW_SUBVENTIONIERTER_PLATZ"></span>
                        </label>
                    </div>
                    <div ng-if="vm.workingCopy.showSubventionierterPlatz" class="checkbox-indent">
                        <dvb-translatable-editable-label translation="vm.workingCopy.subventionierterPlatzTranslation"
                                                         on-Submit="vm.save()"
                                                         max-length="255">
                        </dvb-translatable-editable-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="checkbox">
                        <label uib-tooltip="{{'EXTERNAL_ANMELDUNG.CONFIG.SHOW_PENSUM' | translate}}"
                               tooltip-placement="top-left">
                            <input type="checkbox"
                                   value="true"
                                   ng-model="vm.workingCopy.showPensum"
                                   ng-change="vm.save()">
                            <span data-translate="EXTERNAL_ANMELDUNG.CONFIG.SHOW_PENSUM"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dvb-editable-text-area ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.CUSTOM_STYLE' | translate}}"
                                        ng-model="vm.workingCopy.customStyle"
                                        maxlength="-1"
                                        on-submit="vm.save()">
                </dvb-editable-text-area>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-md-offset-1">
        <dvb-voreinstellungen-external-anmeldung-wochenplan wochenplaene-external-anmeldung="vm.workingCopy.wochenplaene"
                                                            on-change="vm.save()">
        </dvb-voreinstellungen-external-anmeldung-wochenplan>
    </div>
</div>
