/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbUtil, EntitySelection} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinder: '<',
        titleMf: '<',
        actionNameMf: '<',
        disableActions: '<?',
        onSubmit: '&',
    },
    template: require('./dvb-communication-kind-selection.html'),
    controllerAs: 'vm',
};

export class DvbCommunicationKindSelection extends EntitySelection<Kind> implements angular.IController {
    public static override $inject: readonly string[] = [];

    public kinder!: Persisted<Kind>[];
    public titleMf!: string;
    public actionNameMf!: string;
    public disableActions: boolean = false;
    public onSubmit!: (val: { kinder: Persisted<Kind>[] }) => any;

    public submit(): void {
        const selected = this.getSelected(this.kinder);
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        this.onSubmit({kinder: this.getSelected(this.kinder)});
    }
}

componentConfig.controller = DvbCommunicationKindSelection;
angular.module('kitAdmin').component('dvbCommunicationKindSelection', componentConfig);
