/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Ng2StateDeclaration} from '@uirouter/angular';
import {KIND_ABWEICHUNGS_MELDUNGEN} from './kind-abweichungs-meldungen.states';

export const KIND_ABWEICHUNGS_MELDUNGEN_FEATURE = {
    name: `${KIND_ABWEICHUNGS_MELDUNGEN.name}.**`,
    url: KIND_ABWEICHUNGS_MELDUNGEN.url,
    loadChildren: () => import('./kind-abweichungs-meldungen.module').then(m => m.KindAbweichungsMeldungenModule),
} satisfies Ng2StateDeclaration;
