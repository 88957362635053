/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung, RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted, RechnungsRevisionError} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        rechnungen: '<',
        isLoading: '<?',
        mode: '<?',
        preSelectedRechnungId: '<?',
        selection: '<?',
        errors: '<?',
        showVersandartBadge: '<?',
    },
    template: require('./dvb-rechnungen.html'),
    controllerAs: 'vm',
};

export class DvbRechnungen {
    public static $inject: readonly string[] = [];

    public rechnungen!: Persisted<Rechnung>[];
    public isLoading?: boolean;
    public mode?: RechnungDisplayMode;
    public preSelectedRechnungId?: string;
    public selection?: { [rechnungId: string]: boolean };
    public errors: { [rechnungId: string]: RechnungsRevisionError[] } = {};
    public showVersandartBadge?: boolean;

    public selectAll(): void {
        this.setRechnungSelection(true);
    }

    public unselectAll(): void {
        this.setRechnungSelection(false);
    }

    private setRechnungSelection(selected: boolean): void {
        if (this.rechnungen.length > 0 && !this.selection) {
            this.selection = {};
        }

        this.rechnungen.forEach(rechnung => {
            this.selection![rechnung.id] = rechnung.kontaktperson?.mahnsperre ? false : selected;
        });
    }
}

componentConfig.controller = DvbRechnungen;
angular.module('kitAdmin').component('dvbRechnungen', componentConfig);
