<div class="dvb-kita-vereinbarungs-konfigurationen row">
    <div class="col-md-7">
        <h2 data-translate="COMMON.BETREUUNGSVEREINBARUNG.CONFIGS"></h2>

        <p ng-if="vm.konfigurationTypes.length === 0"
           class="normal-abstand-bottom"
           data-translate="COMMON.BETREUUNGSVEREINBARUNG.TYPE.NO_TYPES">
        </p>

        <div ng-repeat="configType in vm.konfigurationTypes"
             class="big-abstand-bottom">
            <div class="normal-abstand-bottom">
                <h3 data-translate="COMMON.BETREUUNGSVEREINBARUNG.TYPE.NAME"></h3>

                <dvb-translatable-editable-label translation="configType.name"
                                                 on-Submit="vm.saveConfigType(configType)"
                                                 max-length="255"
                                                 dvb-auth
                                                 require-role="ADMIN"
                                                 on-unauthorised-disable>
                </dvb-translatable-editable-label>
            </div>

            <p ng-if="configType.konfigurationen.length === 0"
               class="normal-abstand-bottom small-abstand-top"
               data-translate="COMMON.BETREUUNGSVEREINBARUNG.NO_CONFIGS">
            </p>
            <div ng-repeat="config in configType.konfigurationen | toArray:false | orderBy:'gueltigAb':true">
                <dvb-collapsible-panel limited-entity="config"
                                       expand-ab="vm.today"
                                       expand-bis="vm.today"
                                       is-first="$first">
                    <panel-header>
                        <p>
                            <dvb-gueltigkeit entity="config"></dvb-gueltigkeit>
                        </p>
                    </panel-header>
                    <panel-content>
                        <p>
                            <dvb-gueltigkeit entity="config"></dvb-gueltigkeit>
                        </p>
                        <div class="normal-abstand-top">
                            <dvb-kita-betreuungs-vereinbarungs-konfiguration kinder-ort="vm.kinderOrt"
                                                                             konfiguration="config"
                                                                             dokument-layouts="vm.dokumentLayouts">
                            </dvb-kita-betreuungs-vereinbarungs-konfiguration>
                        </div>

                        <ul class="list-inline normal-abstand-top"
                            dvb-auth
                            require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}">
                            <li>
                                <button class="btn btn-link"
                                        ng-click="vm.confirmDelete(config)"
                                        data-translate="COMMON.LOESCHEN">
                                </button>
                            </li>
                        </ul>
                    </panel-content>
                </dvb-collapsible-panel>


            </div>
            <ul class="normal-abstand-top list-inline"
                dvb-auth
                require-permission="{{'kita:administrate:' + vm.kita.id}}">
                <li ng-click="vm.newConfig(configType.id)">
                    <a href="" data-translate="COMMON.NEUE_KONFIGURATION"></a>
                </li>
                <li ng-click="vm.confirmDeleteType(configType)">
                    <a href="" data-translate="COMMON.LOESCHEN"></a>
                </li>
            </ul>
        </div>

        <ul class="list-inline" ng-hide="vm.newConfigType">
            <li>
                <button type="button" class="btn btn-primary" ng-click="vm.createNewConfigType()">
                    <span data-translate="COMMON.BETREUUNGSVEREINBARUNG.TYPE.CREATE"></span>
                </button>
            </li>
        </ul>

        <form name="newConfigTypeForm" ng-submit="vm.saveNewConfigType()" novalidate ng-if="vm.newConfigType">
            <h2 data-translate="COMMON.BETREUUNGSVEREINBARUNG.TYPE.CREATE"></h2>

            <h3 data-translate="COMMON.TITEL" class="small-abstand-bottom"></h3>

            <dvb-translatable>
                <input type="text"
                       class="form-control"
                       name="konfigurationTypeName"
                       ng-model="vm.newConfigType.name.texts[$dvbLang]"
                       dvb-translatable-input
                       placeholder="{{'LANGUAGE.NO_TEXT_DEFINED' | translate}}"
                       uib-tooltip="{{$dvbLangTranslated}}"
                       tooltip-placement="top-left"
                       maxlength="255">
            </dvb-translatable>

            <ul class="list-inline big-abstand-top">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="COMMON.SPEICHERN"></span>
                    </dvb-loading-button>
                </li>
                <li>
                    <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.resetNewConfigType()"></a>
                </li>
            </ul>
        </form>

    </div>
</div>
