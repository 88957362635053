<div class="row dvb-form">

    <div class="col-md-7 big-abstand-bottom">
        <h2 data-translate="KIND.WOHNADRESSE_KIND"></h2>

        <dvb-adresse-verlauf kind-id="vm.kind.id"
                             limited-adressen="vm.adressen"
                             on-delete-limited-adresse="vm.deleteLimitedAdresse(limitedAdresse)"
                             on-update-limited-adresse="vm.adresseKorrigieren(limitedAdresse, adresse)">
        </dvb-adresse-verlauf>

        <div class="big-abstand-top" ng-if="vm.showUmzug">
            <dvb-umzug kind-id="vm.kind.id"
                       on-cancel="vm.cancelUmzug()"
                       on-save="vm.saveUmzug(umzug, context)">
            </dvb-umzug>
        </div>

        <!-- main menu bar-->
        <div class="main-actions hidden-print" dvb-auth require-permission="kita:manage:general">
            <ul class="list-inline" ng-hide="vm.showUmzug">
                <li>
                    <button class="btn btn-primary"
                            ng-click="vm.showUmzug = true"
                            type="button">
                        <span data-translate="COMMON.UMZUG_ERFASSEN"></span>
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <div class="form-section">
            <h2 data-translate="KONTAKTPERSON.HAUPTKONTAKT"></h2>

            <dvb-kontaktperson-stammdaten-display kontaktperson="vm.hauptkontakt.kontaktperson">
            </dvb-kontaktperson-stammdaten-display>

            <div class="small-abstand-top">
                <dvb-erziehungsberechtigter-input
                    has-adresse="vm.hauptkontakt.hasAdresse()"
                    kind-kontakt-relationship="vm.hauptkontakt.relationship"
                    on-create-erziehungsberechtigter="vm.createErziehungsberechtigter(erziehungsberechtigter, vm.hauptkontakt)"
                    on-delete-erziehungsberechtigter="vm.removeErziehungsberechtigter(erziehungsberechtigter, vm.hauptkontakt)">
                </dvb-erziehungsberechtigter-input>

                <dvb-abholberechtigt-input
                    kind-kontakt-relationship="vm.hauptkontakt.relationship"
                    on-create-abholberechtigt="vm.createAbholberechtigt(abholberechtigt, vm.hauptkontakt)"
                    on-delete-abholberechtigt="vm.removeAbholberechtigt(abholberechtigt, vm.hauptkontakt)">
                </dvb-abholberechtigt-input>

                <dvb-rechnungsempfaenger-input
                    has-adresse="vm.hauptkontakt.hasAdresse()"
                    kind-kontakt-relationship="vm.hauptkontakt.relationship"
                    on-change="vm.changeRechnungsempfaenger()">
                    <dvb-rechnungsbetrag-display ng-if="vm.hauptkontakt.relationship.isRechnungsempfaenger()"
                                                 rechnungsempfaenger="vm.hauptkontakt.relationship.rechnungsempfaenger">
                    </dvb-rechnungsbetrag-display>
                </dvb-rechnungsempfaenger-input>
            </div>
        </div>

        <h2 data-translate="KIND.KONTAKTE"></h2>

        <div class="form-section"
             ng-repeat="kontakt in (nonHauptkontakte = (vm.kontakte | filter:vm.isNotHauptkontakt))">

            <dvb-kontaktperson-stammdaten-display kontaktperson="kontakt.kontaktperson">
            </dvb-kontaktperson-stammdaten-display>

            <div class="small-abstand-top">
                <dvb-erziehungsberechtigter-input
                    has-adresse="kontakt.hasAdresse()"
                    kind-kontakt-relationship="kontakt.relationship"
                    on-create-erziehungsberechtigter="vm.createErziehungsberechtigter(erziehungsberechtigter, kontakt)"
                    on-delete-erziehungsberechtigter="vm.removeErziehungsberechtigter(erziehungsberechtigter, kontakt)">
                </dvb-erziehungsberechtigter-input>

                <dvb-abholberechtigt-input
                    kind-kontakt-relationship="kontakt.relationship"
                    on-create-abholberechtigt="vm.createAbholberechtigt(abholberechtigt, kontakt)"
                    on-delete-abholberechtigt="vm.removeAbholberechtigt(abholberechtigt, kontakt)">
                </dvb-abholberechtigt-input>

                <dvb-rechnungsempfaenger-input
                    has-adresse="kontakt.hasAdresse()"
                    kind-kontakt-relationship="kontakt.relationship"
                    on-change="vm.changeRechnungsempfaenger()">
                    <dvb-rechnungsbetrag-display ng-if="kontakt.relationship.isRechnungsempfaenger()"
                                                 rechnungsempfaenger="kontakt.relationship.rechnungsempfaenger">
                    </dvb-rechnungsbetrag-display>
                </dvb-rechnungsempfaenger-input>
            </div>

            <div class="small-abstand-top hidden-print" dvb-auth require-permission="kita:manage:general">
                <p>
                    <a data-translate="KIND.DIESEN_KONTAKT_ALS_HAUPTKONTAKT_DEFINIEREN"
                       href=""
                       ng-click="vm.replaceHauptkontakt(kontakt)">
                    </a>
                </p>
                <p>
                    <a data-translate="KIND.KONTAKT_ENTFERNEN"
                       href=""
                       ng-click="vm.removeKontaktperson(kontakt)">
                    </a>
                </p>
            </div>
        </div>

        <p class="normal-abstand-bottom"
           data-translate="KIND.KEINE_WEITEREN_KONTAKTE"
           ng-if="vm.kontakte.length < 2">
        </p>

        <p class="hidden-print" dvb-auth require-permission="kita:manage:general">
            <a ui-sref="base.kind.neue-kontaktperson({kindId: vm.kind.id})">
                + <span data-translate="KIND.KONTAKTPERSON_ERFASSEN_LINK"></span>
            </a>
        </p>

        <p class="hidden-print" dvb-auth require-permission="kita:manage:general">
            <dvb-search-on-click-entity add-label-key="KIND.KONTAKTPERSON_HINZUFUEGEN"
                                        disabled-entries="vm.kontaktpersonen"
                                        entity-to-search="KONTAKTPERSON"
                                        on-select="vm.addSonstigerKontakt(item)"
                                        on-select-clear="true"
                                        placeholder-key="COMMON.KONTAKTPERSON_AUSWAEHLEN">
            </dvb-search-on-click-entity>
        </p>
    </div>
</div>
