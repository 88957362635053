<div class="dvb-einzahlungsschein-form">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="name"
                   class="form-control"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.einzahlungsschein.name">
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="kontoNummer"
                   class="form-control"
                   maxlength="26"
                   dvb-einzahlungsschein-konto
                   ng-attr-placeholder="{{'FAKTURA.QR-IBAN_KONTONUMMER' | translate}}"
                   uib-tooltip="{{'FAKTURA.QR-IBAN_KONTONUMMER' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.einzahlungsschein.kontoNummer">
        </div>
        <div class="col-md-4">
            <input type="text"
                   name="name"
                   class="form-control"
                   maxlength="14"
                   ng-pattern="/^\d*$/"
                   ng-attr-placeholder="{{'FAKTURA.ESR_AUSLIEFERUNGSNUMMER' | translate}}"
                   uib-tooltip="{{'FAKTURA.ESR_AUSLIEFERUNGSNUMMER' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.einzahlungsschein.auslieferungsnummer">
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <dvb-contextual-adresse contextual-adresse="vm.einzahlungsschein.contextualAdresse"
                                    org-required="true">
            </dvb-contextual-adresse>
        </div>
    </div>

    <div class="row form-row">
        <div class="col-md-6">
            <div class="checkbox">
                <label>
                    <input type="checkbox" value="true" ng-model="vm.showBank">
                    <span data-translate="FAKTURA.EINZAHLUNGSSCHEIN_BANK"></span>
                </label>
            </div>
        </div>
    </div>

    <div class="row" uib-collapse="!vm.showBank">
        <div class="col-md-6">
            <input type="text"
                   name="bankName"
                   class="form-control"
                   maxlength="100"
                   ng-attr-placeholder="{{'FAKTURA.BANK_NAME' | translate}}"
                   uib-tooltip="{{'FAKTURA.BANK_NAME' | translate}}"
                   tooltip-placement="top-left"
                   ng-required="vm.showBank"
                   ng-model="vm.einzahlungsschein.bankName">
        </div>
        <div class="col-md-6">
            <input type="text"
                   name="bankAdresszeile"
                   class="form-control"
                   maxlength="100"
                   ng-attr-placeholder="{{'FAKTURA.BANK_ADRESSZEILE' | translate}}"
                   uib-tooltip="{{'FAKTURA.BANK_ADRESSZEILE' | translate}}"
                   tooltip-placement="top-left"
                   ng-required="vm.showBank"
                   ng-model="vm.einzahlungsschein.bankAdresszeile">
        </div>
    </div>

    <h3 data-translate="FAKTURA.DRUCKPOSITIONIERUNG" class="normal-abstand-top"></h3>
    <div class="row small-abstand-top">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   ng-attr-placeholder="{{ ('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: 0}) }}"
                   uib-tooltip="{{ ('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: 0}) }}"
                   tooltip-placement="top-left"
                   ng-model="vm.einzahlungsschein.offsetLeft"
                   min="{{-vm.dokumentLayoutMaxMM}}"
                   max="{{vm.dokumentLayoutMaxMM}}">
        </div>
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   ng-attr-placeholder="{{ ('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: 0}) }}"
                   uib-tooltip="{{ ('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: 0}) }}"
                   tooltip-placement="top-left"
                   ng-model="vm.einzahlungsschein.offsetTop"
                   min="{{-vm.dokumentLayoutMaxMM}}"
                   max="{{vm.dokumentLayoutMaxMM}}">
        </div>
        <div class="col-md-6">
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           value="true"
                           ng-model="vm.einzahlungsschein.einzahlungsscheinNotOnPageOne">
                    <span data-translate="FAKTURA.EINZAHLUNGSSCHEIN_PAGE"></span>
                </label>
            </div>
        </div>
    </div>
</div>
