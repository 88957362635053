<form (ngSubmit)="submit(null)" novalidate>
    <div class="modal-body">
        <h1 [innerHTML]="title | translate"></h1>
        <div class="row">
            <div class="col-md-6">
                <h2 [translate]="'KIBON.REFERENZ_NUMMER_VERWALTEN.ZUGEWIESEN'"></h2>
                @for (refNr of remainingRefNumbers; track refNr) {
                    <div class="row floating-text">
                        <span class="tabular-nums">{{ refNr }}</span>
                        <button type="button"
                                class="btn btn-link small-abstand-left text-lowercase"
                                (click)="ignoreRefNr(refNr)"
                                [translate]="'COMMON.IGNORIEREN'">
                        </button>
                    </div>
                } @empty {
                    <span class="no-data" [translate]="'KIBON.REFERENZ_NUMMER_VERWALTEN.NO_DATA'"></span>
                }
            </div>
            <div class="visible-sm-block visible-xs-block big-abstand-bottom"></div>
            <div class="col-md-6">
                <h2 [translate]="'KIBON.REFERENZ_NUMMER_VERWALTEN.IGNORIERT'"></h2>
                @for (refNr of ignoredRefNumbers; track refNr.id) {
                    <div class="row floating-text">
                        <span class="tabular-nums">{{ refNr.refNr }}</span>
                        <button type="button"
                                class="btn btn-link small-abstand-left"
                                (click)="deleteIgnoredRefNr(refNr)"
                                [translate]="'KIBON.REFERENZ_NUMMER_VERWALTEN.REF_NR_IGNORIEREN_AUFHEBEN'">
                        </button>
                    </div>
                } @empty {
                    <span class="no-data" [translate]="'KIBON.REFERENZ_NUMMER_VERWALTEN.NO_DATA'"></span>
                }
            </div>
        </div>
    </div>
    <div class="modal-footer big-abstand-top">
        <button
            type="button"
            class="btn btn-link"
            [translate]="'COMMON.ABBRECHEN'"
            (click)="hide()">
        </button>
    </div>
</form>
