/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalKontaktperson} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        kontakte: '<',
        onAddKontakt: '&',
        onRemoveKontakt: '&',
    },
    template: require('./dvb-external-anmeldung-kontakte.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungKontakte implements angular.IController {
    public static $inject: readonly string[] = ['mandantConfigurationService'];

    public kontakte: (ExternalKontaktperson & { collapsed: boolean })[] = [];
    public onAddKontakt!: FunctionType;
    public onRemoveKontakt!: (params: { kontakt: ExternalKontaktperson }) => unknown;
}

componentConfig.controller = DvbExternalAnmeldungKontakte;
angular.module('kitAdmin').component('dvbExternalAnmeldungKontakte', componentConfig);
