/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {EmailVersandProperties} from '@dv/kitadmin/models';
import type {JaxBulkEmail} from '@dv/shared/backend/model/jax-bulk-email';
import type {IRestModel} from '@dv/shared/code';

export class BulkEmail extends EmailVersandProperties implements IRestModel<JaxBulkEmail> {

    public constructor(
        public entityIds: string[] = [],
        public emailSubject: string = '',
        public plainText: string = '',
        public html: string | null = null,
        public replyToName: string = '',
        public tempBlobIds: string[] = [],
        public dispositionNotification: boolean = false,
        public testEmailRecipient: string | null = null,
    ) {
        super(null, null, true);
    }

    public override toRestObject(): JaxBulkEmail {
        return {
            ...super.toRestObject(false),
            entityIds: this.entityIds,
            emailSubject: this.emailSubject,
            plainText: this.plainText,
            html: this.html ?? undefined,
            replyToName: this.replyToName,
            tempBlobIds: this.tempBlobIds,
            dispositionNotification: this.dispositionNotification,
            testEmailRecipient: this.testEmailRecipient ?? undefined,
        };
    }
}
