/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgStyle} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {trackByIdentity} from '@dv/shared/angular';
import type {CalendarEvent} from '../../model/CalendarEvent';
import type {CalendarResizeEvent} from '../../model/CalendarResizeEvent';
import {TimelineCalendarService} from '../../service/timeline-calendar.service';
import {TimelineEventComponent} from '../timeline-event/timeline-event.component';

@Component({
    selector: 'dv-timeline-events',
    standalone: true,
    imports: [
        TimelineEventComponent,
        NgStyle,
    ],
    templateUrl: './timeline-events.component.html',
    styleUrls: ['./timeline-events.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineEventsComponent {

    @Input({required: true}) public events!: CalendarEvent[];

    @Output() public readonly resizeEvent: EventEmitter<CalendarResizeEvent> = new EventEmitter();
    @Output() public readonly resizeComplete: EventEmitter<CalendarEvent> = new EventEmitter();
    @Output() public readonly deleteEvent: EventEmitter<CalendarEvent> = new EventEmitter();
    @Output() public readonly editEvent: EventEmitter<{
        element: Element;
        event: CalendarEvent;
    }> = new EventEmitter();

    public trackByIdentity = trackByIdentity;

    public constructor(
        public readonly timelineCalendarService: TimelineCalendarService,
    ) {
    }
}
