/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';
import type {KinderOrtSchliesstage} from '../../../../schliesstage/models/KinderOrtSchliesstage';
import type {Schliesstage} from '../../../../schliesstage/models/Schliesstage';
import type {SchliesstageService} from '../../../../schliesstage/service/schliesstageService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrtSchliesstage: '<',
        showGueltigAb: '<?',
        onSubmit: '&',
        onCancel: '&',
        isLoading: '<',
    },
    template: require('./dvb-kinder-ort-schliesstage-form.html'),
    controllerAs: 'vm',
};

export class DvbKinderOrtSchliesstageForm {
    public static $inject: readonly string[] = ['errorService', 'schliesstageService'];

    public kinderOrtSchliesstage!: KinderOrtSchliesstage;
    public showGueltigAb: boolean = false;
    public onSubmit!: FunctionType;
    public onCancel!: FunctionType;
    public isLoading!: boolean;

    public availableSchliesstage: Schliesstage[] = [];

    public constructor(
        private readonly errorService: ErrorService,
        private readonly schliesstageService: SchliesstageService,
    ) {
    }

    public $onInit(): void {
        this.schliesstageService.getAll().then(schliesstage => {
            this.availableSchliesstage = schliesstage;
        });
    }

    public cancel(): void {
        this.errorService.clearAll();

        this.onCancel();
    }

    public submit(isValid: boolean): void {
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return;
        }

        this.onSubmit({kinderOrtSchliesstage: this.kinderOrtSchliesstage});
    }
}

componentConfig.controller = DvbKinderOrtSchliesstageForm;
angular.module('kitAdmin').component('dvbKinderOrtSchliesstageForm', componentConfig);
