/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {IDisplayable, ILimited} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        entityHeading: 'entityHeading',
    },
    bindings: {
        entity: '<',
    },
    template: require('./dvb-limited-entity-heading.html'),
    controllerAs: 'vm',
};

export class DvbLimitedEntityHeading {
    public static $inject: readonly string[] = [];

    public entity!: ILimited & IDisplayable;
}

componentConfig.controller = DvbLimitedEntityHeading;
angular.module('kitAdmin').component('dvbLimitedEntityHeading', componentConfig);
