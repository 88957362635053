/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IEntityList, IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, TimeRangeUtil} from '@dv/shared/code';
import {BetreuungsSchluesselRegel} from './BetreuungsSchluesselRegel';

function byDayOfWeekThenByTimeThenByDienst(a: BetreuungsSchluesselRegel, b: BetreuungsSchluesselRegel): number {
    const delta = getDayOfWeekOrder(a.dayOfWeek) - getDayOfWeekOrder(b.dayOfWeek);

    if (delta === 0) {
        const byTimeRange = TimeRangeUtil.TIME_RANGE_COMPARATOR(a.vonBis, b.vonBis);
        if (byTimeRange === 0) {
            return (a.dienstId ?? '').localeCompare(b.dienstId ?? '');
        }

        return byTimeRange;
    }

    return delta;
}

function getDayOfWeekOrder(dayOfWeek: DayOfWeek | null): number {
    if (!dayOfWeek) {
        return -1;
    }

    return DvbDateUtil.getIsoWeekDayNumber(dayOfWeek);
}

export class BetreuungsSchluessel implements IPersistable, IRestModel, IEntityList {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public betreuungsSchluesselRegeln: BetreuungsSchluesselRegel[] = [],
        public editable: boolean | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsSchluessel {
        const result = new BetreuungsSchluessel(data.id, data.name, data.type);

        if (Array.isArray(data.betreuungsSchluesselRegeln)) {
            result.betreuungsSchluesselRegeln = data.betreuungsSchluesselRegeln.map((regel: any) =>
                BetreuungsSchluesselRegel.apiResponseTransformer(regel));
        }

        result.editable = data.editable;

        return result;
    }

    public sortRegeln(): void {
        this.betreuungsSchluesselRegeln.sort(byDayOfWeekThenByTimeThenByDienst);
        this.betreuungsSchluesselRegeln.forEach(r => r.sortRegeln());
    }

    public isValid(): boolean {
        return this.betreuungsSchluesselRegeln.length > 0 &&
            this.betreuungsSchluesselRegeln.every(regel => regel.isValid());
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            betreuungsSchluesselRegeln: this.betreuungsSchluesselRegeln.map(regel => regel.toRestObject()),
            editable: this.editable,
        };
    }
}
