/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MandantFeaturePermissions} from '@dv/kitadmin/models';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {MandantService} from '../../../common/service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-mandant-features.html'),
    controllerAs: 'vm',
};

type MandantFeaturePermissionsWithWorkingCopy =
    MandantFeaturePermissions
    & { workingCopy?: MandantFeaturePermissionsWithWorkingCopy };

export class DvbMandantFeatures implements angular.IController {
    public static $inject: readonly string[] = ['mandantService', '$filter'];

    public searchText: string = '';
    public mandantFeatures: MandantFeaturePermissionsWithWorkingCopy[] = [];
    public filteredMandantFeatures: MandantFeaturePermissionsWithWorkingCopy[] = [];

    public isLoading: boolean = false;
    public currentPage: number = 1;
    public readonly maxNumberPerPage: number = 10;
    public itemsPerPage: number = 10;

    public constructor(
        private readonly mandantService: MandantService,
        private readonly $filter: angular.IFilterService,
    ) {
    }

    private static mandantOrder(a: MandantFeaturePermissions, b: MandantFeaturePermissions): number {
        if (a.features.length > 0 && b.features.length === 0) {
            return -1;
        }

        if (a.features.length === 0 && b.features.length > 0) {
            return 1;
        }

        return DvbUtil.compareByDisplayName(a, b);
    }

    private static withWorkingCopy(
        model: MandantFeaturePermissionsWithWorkingCopy,
    ): MandantFeaturePermissionsWithWorkingCopy {

        model.workingCopy = angular.copy(model);

        // make sure to keep workingCopy hierarchy flat (1 level only)
        delete model.workingCopy.workingCopy;

        return model;
    }

    public $onInit(): void {
        this.reload();
    }

    public updatePage(page: number): void {
        this.currentPage = page;
    }

    public onUpdateItemsPerPage(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;
    }

    public filterMandanten(): void {
        this.filteredMandantFeatures = this.$filter('filter')(this.mandantFeatures, this.searchText);
        this.currentPage = 1;
    }

    public reload(): void {
        this.isLoading = true;
        this.mandantService.getFeatures()
            .then(mandantPermissions => {
                mandantPermissions.sort(DvbMandantFeatures.mandantOrder);
                this.mandantFeatures = mandantPermissions.map(p => DvbMandantFeatures.withWorkingCopy(p));
            })
            .finally(() => {
                this.filteredMandantFeatures = this.mandantFeatures;
                this.searchText = '';
                this.isLoading = false;
            });
    }

    public updateFeaturePermissions(
        model: MandantFeaturePermissionsWithWorkingCopy,
        context: AccordionContext,
    ): void {

        const edited = checkPresent(model.workingCopy);
        const newModel = DvbMandantFeatures.withWorkingCopy(edited);

        this.mandantService.updateMandantFeaturePermission(edited)
            .then(() => {
                DvbUtil.replaceArrayElement(model, newModel, this.filteredMandantFeatures);
                DvbUtil.replaceArrayElement(model, newModel, this.mandantFeatures);
            })
            .finally(() => context.completeSubmission());
    }

    public cancel(model: MandantFeaturePermissionsWithWorkingCopy): void {
        // reset working copy
        DvbMandantFeatures.withWorkingCopy(model);
    }
}

componentConfig.controller = DvbMandantFeatures;
angular.module('kitAdmin').component('dvbMandantFeatures', componentConfig);
