/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import moment from 'moment';
import {DEBOUNCE_TIME, Gueltigkeit, DvbDateUtil} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        gueltigkeit: '=ngModel',
    },
    template: require('./dvb-period-selector.html'),
    controllerAs: 'vm',
};

export class DvbPeriodSelector implements angular.IController {
    public static $inject: readonly string[] = ['$timeout'];

    public gueltigkeit!: Gueltigkeit;

    public currentGueltigkeit: Gueltigkeit | null = null;
    public gueltigAbEndOfMonth: moment.Moment | null = null;

    private ngModelCtrl!: angular.INgModelController;

    private lastChangedTimestamp: moment.Moment = moment();
    private updatePromise: angular.IPromise<unknown> | null = null;

    public constructor(
        private readonly $timeout: angular.ITimeoutService,
    ) {
    }

    public $onInit(): void {
        this.currentGueltigkeit = angular.copy(this.gueltigkeit);

        this.ngModelCtrl.$formatters.push(this.formatter.bind(this));
        this.ngModelCtrl.$parsers.push(this.parser.bind(this));
        this.ngModelCtrl.$render = this.render.bind(this);
    }

    public update():void {
        if (moment().diff(this.lastChangedTimestamp) > DEBOUNCE_TIME) {
            this.doUpdate();
        } else {
            if (this.updatePromise) {
                this.$timeout.cancel(this.updatePromise);
            }
            this.updatePromise = this.$timeout(this.doUpdate.bind(this), DEBOUNCE_TIME);
        }

        this.lastChangedTimestamp = moment();
    }

    private formatter(modelValue: Gueltigkeit): Gueltigkeit {
        const gueltigkeit = new Gueltigkeit();

        gueltigkeit.gueltigAb = DvbDateUtil.isValidMoment(modelValue.gueltigAb) ?
            moment(modelValue.gueltigAb) :
            DvbDateUtil.startOfMonth();

        gueltigkeit.gueltigBis = DvbDateUtil.isValidMoment(modelValue.gueltigBis) ?
            moment(modelValue.gueltigBis) :
            DvbDateUtil.endOfMonth();

        return gueltigkeit;
    }

    private parser(): void {
        this.updateGueltigAbEndOfMonth();

        return this.ngModelCtrl.$viewValue;
    }

    private render(): void {
        this.currentGueltigkeit = this.ngModelCtrl.$viewValue;

        this.updateGueltigAbEndOfMonth();
    }

    private updateGueltigAbEndOfMonth(): void {
        this.gueltigAbEndOfMonth = DvbDateUtil.endOfMonth(moment(this.currentGueltigkeit!.gueltigAb));
    }

    private doUpdate(): void {
        this.ngModelCtrl.$setViewValue(angular.copy(this.currentGueltigkeit));
    }
}

componentConfig.controller = DvbPeriodSelector;
angular.module('kitAdmin').component('dvbPeriodSelector', componentConfig);
