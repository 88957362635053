/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted} from '@dv/shared/code';
import angular from 'angular';
import {ANSTELLUNG_STATES} from '../../anstellung-states';
import type {Angestellte} from '../../models/Angestellte';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        angestellte: '<',
    },
    template: require('./dvb-angestellte-main.html'),
    controllerAs: 'vm',
};

export class DvbAngestellteMain implements angular.IController {
    public static $inject: readonly string[] = ['authStore'];

    public angestellte!: Persisted<Angestellte>;

    public anstellungenStates: typeof ANSTELLUNG_STATES = ANSTELLUNG_STATES;
    public hasPersonalPermission: boolean = false;
    public hasPersonalPlanungPermission: boolean = false;

    public constructor(private readonly authStore: AuthStore) {
    }

    public $onChanges(): void {
        this.hasPersonalPermission = this.authStore.hasAnyPermission([
            PERMISSION.PERSONAL.VIEW_ANY,
            PERMISSION.ANGESTELLTE.READ + this.angestellte.id,
        ]);

        this.hasPersonalPlanungPermission = this.authStore.hasPermission(PERMISSION.MODULE.PERSONALPLANUNG);
    }
}

componentConfig.controller = DvbAngestellteMain;
angular.module('kitAdmin').component('dvbAngestellteMain', componentConfig);
