/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MatchingState} from '@dv/shared/backend/model/matching-state';
import type {MatrixParams} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import type {FilterOption} from '../../filter/shared/FilterOption';
import {AbstractKibonFilter} from './AbstractKibonFilter';

export class VerfuegungenFilter extends AbstractKibonFilter {
    public static override $inject: readonly string[] = [];

    public constructor(
        kinderOrte: FilterOption[] = [],
        public states: { [k in MatchingState]?: boolean } = {},
        mandant?: FilterOption,
        showWithMissingInstitution: boolean = false,
        kindName?: string,
        refNr?: string,
        top?: number,
        skip?: number,
    ) {
        super(kinderOrte, mandant, showWithMissingInstitution, kindName, refNr, top, skip);
    }

    public override isFiltering(): boolean {
        if (this.getRequestedStates().length > 0) {
            return true;
        }

        return super.isFiltering();
    }

    public override reset(): void {
        this.states = {};
        super.reset();
    }

    public getRequestedStates(): MatchingState[] {
        return DvbUtil.keys(this.states)
            .filter(filterState => this.states[filterState]);
    }

    public override toRestObject(): MatrixParams {
        const result = super.toRestObject();

        const requestedStates = this.getRequestedStates();
        if (requestedStates.length > 0) {
            result.states = requestedStates;
        }

        return result;
    }
}
