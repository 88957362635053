<div class="dvb-transaktionen">

    <dvb-transaktionen-header bank-statements="vm.bankStatements"
                              filter="vm.filter"
                              on-update="vm.updatePage(1)"
                              on-auto-assign="vm.autoAssign(context)">
    </dvb-transaktionen-header>

    <div class="row">
        <div class="col-md-9 col-lg-7 big-abstand-bottom">

            <div ng-if="vm.isAutoAssignmentConfirmation" class="big-abstand-bottom">
                <h3 data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_AUTO_ASSIGN_MF"
                    data-translate-interpolation="messageformat"
                    data-translate-value-count="{{vm.pageContainer.count}}">
                </h3>

                <button type="button"
                        class="btn btn-link normal-abstand-top"
                        data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_REFRESH"
                        ng-click="vm.reloadEntries()">
                </button>
            </div>

            <dvb-pages current-page="vm.currentPage"
                       entity-name="'FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_MF'"
                       is-loading="vm.isLoading"
                       ng-if="!vm.isAutoAssignmentConfirmation || vm.pageContainer.count > 0"
                       items="vm.pageContainer.items"
                       items-per-page="vm.itemsPerPage"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)"
                       on-update-page="vm.updatePage(page)"
                       total-items="vm.pageContainer.count">
                <page-items dvb-accordions>
                    <dvb-accordion
                        enable-content="entry.status === 'ZUGEWIESEN' || entry.name || entry.iban || entry.adresse"
                        is-first="$first"
                        remove-toggled-dom="true"
                        ng-repeat="entry in vm.pageContainer.items">
                        <panel-header class="full-width-block">
                            <dvb-bank-statement-entry-heading entry="entry">
                            </dvb-bank-statement-entry-heading>
                        </panel-header>
                        <panel-content>
                            <dvb-bank-statement-entry-content entry="entry">
                            </dvb-bank-statement-entry-content>
                        </panel-content>
                        <panel-footer class="full-width-block">
                            <div class="row">
                                <div class="col-xs-offset-1 col-xs-11">
                                    <dvb-bank-statement-entry-footer entry="entry">
                                    </dvb-bank-statement-entry-footer>
                                </div>
                            </div>
                        </panel-footer>
                    </dvb-accordion>
                </page-items>
                <total-summary>
                    <dvb-list-summary>
                        <div class="row floating-text">
                            <div class="col-xs-5 col-sm-9 col-xs-offset-1">
                                <strong data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_TOTAL_BETRAG_MF"
                                        data-translate-interpolation="messageformat"
                                        data-translate-value-count="{{vm.pageContainer.count | number:'0'}}">
                                </strong>
                            </div>
                            <div class="col-xs-2 text-right text-nowrap">
                                <strong data-translate="COMMON.BETRAG_VALUE"
                                        data-translate-value-betrag="{{vm.pageContainer.summe | number:'2'}}">
                                </strong>
                            </div>
                        </div>
                    </dvb-list-summary>
                </total-summary>
            </dvb-pages>
        </div>
    </div>
</div>
