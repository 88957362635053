/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CustomFieldType, ICustomField} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        customField: '<',
        fieldTypes: '<?',
        showMandatory: '<?',
    },
    template: require('./dvb-voreinstellungen-custom-field-form.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenCustomFieldForm implements angular.IController {
    public static $inject: readonly string[] = [];

    public readonly customField!: ICustomField;

    // currently, only a limited set of display types is supported, see dvb-custom-field-values component
    public readonly fieldTypes: CustomFieldType[] = ['STRING', 'BOOLEAN'];

    public showMandatory: boolean = false;
}

componentConfig.controller = DvbVoreinstellungenCustomFieldForm;
angular.module('kitAdmin').component('dvbVoreinstellungenCustomFieldForm', componentConfig);
