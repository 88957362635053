/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnDestroy} from '@angular/core';
import {Injectable} from '@angular/core';
import type {IPrincipal} from '@dv/shared/authentication/model';
import {AppConfigKey} from '@dv/shared/backend/model/app-config-key';
import {checkPresent, isPresent, LogFactory} from '@dv/shared/code';
import {clearSentryUser, enableSentry, setSentryUser} from '@dv/shared/sentry';
import {combineLatest, filter} from 'rxjs';
import {SubSink} from 'subsink';
import {AppConfigService} from '../../common/service/rest/appConfigService';
import {AuthEventService} from './auth-event.service';
import {PrivacyPolicyService} from './privacy-policy.service';

const analyticsWindow = window as unknown as MatomoWindow;

const LOG = LogFactory.createLog('analytics-service');

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {

    private static readonly PIWIK_HEARTBEAT_TIMER = 60;

    private readonly subSink = new SubSink();

    private readonly privacyPolicyAccepted$ = this.privacyPolicyService.isPolicyAccepted$().pipe(
        filter(isAccepted => isAccepted),
    );

    private readonly analytics$ = combineLatest([
        this.privacyPolicyAccepted$,
        this.authEventService.authenticated$,
    ]);

    public constructor(
        private authEventService: AuthEventService,
        private privacyPolicyService: PrivacyPolicyService,
        private appConfigService: AppConfigService,
    ) {
    }

    public init(): void {
        this.subSink.sink = this.analytics$
            .subscribe({
                next: ([_accepted, authEvent]) => {
                    this.initAnalytics(checkPresent(authEvent.payload));
                },
                error: err => LOG.error('failed to initialize analytics', err),
            });

        this.subSink.sink = this.authEventService.logoutSuccess$
            .subscribe({
                next: () => this.resetUserTracking(),
                error: err => LOG.error('failed to reset analytics', err),
            });
    }

    public ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    public resetUserTracking(): void {
        clearSentryUser();
        this.setTrackingUserId();
    }

    /* eslint-disable no-underscore-dangle */
    private initAnalytics(principal: IPrincipal): void {

        // error tracking
        this.initErrorTracking(principal);

        // user tracking (piwik / matomo)
        if (analyticsWindow._paq) {
            // matomo already initialized
            this.setTrackingUserId(principal);

            return;
        }
        this.appConfigService.getValue(AppConfigKey.PIWIK_SITE_ID).then(siteId => {
            if (isPresent(siteId)) {
                this.initPiwik(siteId);
            }
        });
    }

    private initErrorTracking(principal: IPrincipal): void {
        setSentryUser(principal);
        enableSentry();
    }

    private initPiwik(siteId: string): void {
        const trackingUrl = 'https://matomo.dvbern.ch/';

        // create super global for Matomo
        analyticsWindow._paq = [
            ['setTrackerUrl', `${trackingUrl}matomo.php`],
            ['setSiteId', siteId],
            ['setDoNotTrack', true],
            ['setSecureCookie', true],
            ['enableHeartBeatTimer', AnalyticsService.PIWIK_HEARTBEAT_TIMER],
        ];

        this.setTrackingUserId();
        this.updateTrackingStateChange(window.location.href, '');

        const scriptElement = document.createElement('script');
        const firstScript = document.getElementsByTagName('script')[0];

        if (!firstScript?.parentNode) {
            return;
        }

        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.defer = true;
        scriptElement.src = `${trackingUrl}matomo.js`;
        firstScript.parentNode.insertBefore(scriptElement, firstScript);
    }

    /**
     * Update the Matomo super global variable to track page changes without actually routing.
     *
     * @see https://developer.matomo.org/guides/spa-tracking
     */
    private updateTrackingStateChange(newUrl: string, oldUrl: string): void {
        if (!analyticsWindow._paq) {
            return;
        }

        analyticsWindow._paq.push(['setReferrerUrl', oldUrl]);
        analyticsWindow._paq.push(['setCustomUrl', newUrl]);
        // as our document title is useless we just use part of the url as title
        const urlPrefix = '/';
        const hashPrefix = newUrl.indexOf(urlPrefix);
        const documentTitle = hashPrefix === -1
            ? window.document.title
            : newUrl.substring(hashPrefix + urlPrefix.length);

        analyticsWindow._paq.push(['setDocumentTitle', documentTitle]);

        // remove all previously assigned custom variables
        analyticsWindow._paq.push(['deleteCustomVariables', 'page']);
        analyticsWindow._paq.push(['trackPageView']);
    }

    private setTrackingUserId(principal?: IPrincipal): void {
        if (!analyticsWindow._paq) {
            return;
        }

        // get username, not using null as this produces an error in the Matomo script
        const username = principal?.username ? principal.username : '';

        analyticsWindow._paq.push(['setUserId', username]);
    }
}
