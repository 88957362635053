/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kontaktperson, UploadTempBlob} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {LoadingContext} from '@dv/shared/code';
import {DvbUtil, isNullish, isPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {switchMap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import {BulkEmail} from '../../models/bulkemails/BulkEmail';
import type {BulkEmailInfo} from '../../models/bulkemails/BulkEmailInfo';
import type {EmailTemplate} from '../../models/EmailTemplate';
import type {BulkEmailService} from '../../service/bulk-email.service';
import type {EmailTemplateService} from '../../service/email-template-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        allSelected: '<',
        kontaktpersonen: '<?',
        kinderOrtIds: '<?',
        fraktionIds: '<?',
        bulkEmailInfo: '<?',
    },
    template: require('./dvb-bulk-email-management.html'),
    controllerAs: 'vm',
};

export class DvbBulkEmailManagement implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'dvbStateService',
        'bulkEmailService',
        'errorService',
        'dialogService',
        'emailTemplateService',
    ];

    public allSelected!: boolean;
    public kontaktpersonen?: Kontaktperson[] = [];
    public kinderOrtIds: EntityId[] = [];
    public fraktionIds: EntityId[] = [];
    public bulkEmailInfo?: BulkEmailInfo;

    public emailTemplates: EmailTemplate[] = [];
    public bulkEmail: BulkEmail = new BulkEmail();
    public files: UploadTempBlob[] = [];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public maxTotalSize: number = 5 * 1024 * 1024;

    public constructor(
        private $state: StateService,
        private dvbStateService: DvbStateService,
        private communicationService: BulkEmailService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private emailTemplateService: EmailTemplateService,
    ) {
    }

    public $onInit(): void {
        this.kinderOrtIds ??= [];
        this.fraktionIds ??= [];

        if (this.kontaktpersonen?.length === 1) {
            this.allSelected = true;
        }

        if (this.bulkEmailInfo) {
            this.bulkEmail.emailSubject = this.bulkEmailInfo.emailSubject;
            this.bulkEmail.html = this.bulkEmailInfo.html;
            this.bulkEmail.plainText = this.bulkEmailInfo.plainText;
            this.bulkEmail.replyToName = this.bulkEmailInfo.replyToName;
            this.bulkEmail.replyToAddress = this.bulkEmailInfo.replyToAddress;
            this.allSelected = true;
        }

        this.emailTemplateService.getAllAllowed(this.kinderOrtIds, this.fraktionIds).then(pageContainer => {
            this.emailTemplates = pageContainer.items;
        });
    }

    public uiOnParamsChanged(newParams: any): void {
        this.kontaktpersonen = newParams.kontaktpersonen;
    }

    public sendEmail(_context: LoadingContext, kontaktpersonen: Kontaktperson[]): void {
        if (isNullish(this.kontaktpersonen) || this.kontaktpersonen.length === 0) {
            return;
        }

        if (this.buildMail()) {
            this.openDialog(kontaktpersonen);
        }
    }

    public sendTestMail(testEmailRecipient: string): void {

        const isEmailValid = isPresent(testEmailRecipient) || DvbUtil.isNotEmptyString(testEmailRecipient);
        this.errorService.handleValidationError(isEmailValid, 'ERRORS.ERR_EMAIL_REQUIRES_EMPFAENGER_ADRESSE');

        if (!isEmailValid || !this.buildMail()) {
            return;
        }

        this.bulkEmail.entityIds = [];
        this.bulkEmail.testEmailRecipient = testEmailRecipient;
        this.bulkEmail.tempBlobIds = this.files.map(DvbUtil.mapToIdChecked);

        this.communicationService.sendTestEmails$(this.bulkEmail).subscribe({
            next: () => this.errorService.handleSuccess('COMMUNICATION.EMAIL.TEST_MAIL_SUCCESS'),
        });
    }

    public buildMail(): boolean {
        if (this.bulkEmail.html) {
            const doc = new DOMParser().parseFromString(this.bulkEmail.html, 'text/html');
            this.bulkEmail.plainText = doc.body.textContent ?? '';
        }

        return this.validateEmail();
    }

    public goBack(): angular.IPromise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    public fillForm(emailTemplate: EmailTemplate): void {
        this.bulkEmail.emailSubject = emailTemplate.subject!;
        this.bulkEmail.html = emailTemplate.content!;
    }

    private validateEmail(): boolean {
        const isEmailValid = DvbUtil.isNotEmptyString(this.bulkEmail.plainText)
            && DvbUtil.isNotEmptyString(this.bulkEmail.emailSubject);

        this.errorService.handleValidationError(isEmailValid, 'ERRORS.ERR_EMAIL_REQUIRES_SUBJECT_AND_CONTENT');

        const isAbsenderValid = DvbUtil.isNotEmptyString(this.bulkEmail.replyToAddress)
            && DvbUtil.isNotEmptyString(this.bulkEmail.replyToName);

        this.errorService.handleValidationError(isAbsenderValid, 'ERRORS.ERR_EMAIL_REQUIRES_ABSENDER_ADRESSE');

        // the multi file upload component cannot check overal size of several files uploaded in batches
        // --> do that here
        const attachmentSize = this.files.reduce((a, b) => a + b.file!.size, 0);
        const attachmentSizeValid = attachmentSize <= this.maxTotalSize;
        this.errorService.handleValidationError(attachmentSizeValid, 'ERRORS.ERR_FILES_TOO_LARGE');

        const uploadValid = !this.files.some(a => a.errorMessage === 'ERRORS.ERR_UPLOAD_FAILED');
        this.errorService.handleValidationError(uploadValid, 'ERRORS.ERR_UPLOAD_FAILED_MULTI_FILE');

        return isEmailValid && isAbsenderValid && attachmentSizeValid && uploadValid;
    }

    private openDialog(kontaktpersonen: Kontaktperson[]): void {

        const confirm = (): Observable<unknown> => {
            this.bulkEmail.entityIds = kontaktpersonen.map(DvbUtil.mapToIdChecked);
            this.bulkEmail.tempBlobIds = this.files.map(DvbUtil.mapToIdChecked);

            return this.communicationService.sendBulkEmails$(this.bulkEmail).pipe(
                switchMap(() => this.$state.reload()));
        };

        this.dialogService.openConfirmDialog({
            title: 'COMMUNICATION.BULK_EMAILS.SEND_EMAIL',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm,
        });
    }
}

componentConfig.controller = DvbBulkEmailManagement;
angular.module('kitAdmin').component('dvbBulkEmailManagement', componentConfig);
