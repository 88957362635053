<div class="dvb-rechnungs-revision-error">
    <p>
        <i class="warning fa fa-exclamation-circle"
           uib-popover-template="'rechnungsRevisionErrorPopoverTemplate.html'"
           popover-title="{{'ERRORS.ERROR' | translate}}"
           popover-trigger="'mouseenter focus'"
           popover-placement="bottom"
           popover-append-to-body="true"></i>
    </p>
</div>
