/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FirmenKontingentValue} from '@dv/kitadmin/models';
import {Kita, Tarif} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firmenKontingentValue: '<',
    },
    template: require('./dvb-firma-kontingent-value-form.html'),
    controllerAs: 'vm',
};

export class DvbFirmaKontingentValueForm implements angular.IController {

    public firmenKontingentValue!: FirmenKontingentValue;

    public tarifSearchResultEntry: SearchResultEntry | null = null;

    public $onChanges(): void {
        this.tarifSearchResultEntry = this.firmenKontingentValue?.tarif ?
            this.firmenKontingentValue.tarif.toSearchResultEntry() :
            null;
    }

    public addKita(kinderOrt: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = kinderOrt.text;
        kita.id = kinderOrt.id;
        this.firmenKontingentValue.addKita(kita);
    }

    public removeKita(kitaItem: Kita): void {
        this.firmenKontingentValue.removeKita(kitaItem);
    }

    public updateTarif(): void {
        let tarif = null;
        if (this.tarifSearchResultEntry) {
            tarif = new Tarif();
            tarif.id = this.tarifSearchResultEntry.id;
            tarif.name = this.tarifSearchResultEntry.text;
        }
        this.firmenKontingentValue.setTarif(tarif);
    }
}

componentConfig.controller = DvbFirmaKontingentValueForm;
angular.module('kitAdmin').component('dvbFirmaKontingentValueForm', componentConfig);
