/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    BankStatement,
    BankStatementEntry,
    Einzahlungsschein,
    IRechnungsKonfiguration,
    Rechnung,
    RechnungsKonfiguration,
} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {OffenerPostenType} from '@dv/shared/backend/model/offener-posten-type';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition, UIRouter} from '@uirouter/core';
import type {IPromise} from 'angular';
import angular from 'angular';
import type moment from 'moment';
import {trans} from '../common/i18n/trans';
import type {DvbFakturaService} from '../common/service/rest/dvbFakturaService';
import type {FakturaService} from '../common/service/rest/fakturaService';
import type {MandantService} from '../common/service/rest/mandantService';
import type {BankStatementService} from './service/bankStatementService';

angular.module('kitAdmin')
    .config(fakturaConfig)
    .run(updateTemplates);

export * from './component/bank-statement/dvb-bank-statement-entry-assign/dvb-bank-statement-entry-assign';
export * from './component/bank-statement/dvb-bank-statement-entry-content/dvb-bank-statement-entry-content';
export * from './component/bank-statement/dvb-bank-statement-entry-footer/dvb-bank-statement-entry-footer';
export * from './component/bank-statement/dvb-bank-statement-entry-heading/dvb-bank-statement-entry-heading';
export * from './component/bank-statement/dvb-kontenabgleich-main/dvb-kontenabgleich-main';
export * from './component/bank-statement/dvb-konto-auszuege/dvb-konto-auszuege';
export * from './component/bank-statement/dvb-transaktionen-header/dvb-transaktionen-header';
export * from './component/bank-statement/dvb-transaktionen-import/dvb-transaktionen-import';
export * from './component/bank-statement/dvb-transaktionen/dvb-transaktionen';
export * from './component/bank-statement/zuweisung/dvb-transaktion-zuweisen-alle/dvb-transaktion-zuweisen-alle';
export * from './component/bank-statement/zuweisung/dvb-transaktion-zuweisen-filter/dvb-transaktion-zuweisen-filter';
export * from './component/bank-statement/zuweisung/dvb-transaktion-zuweisen-header/dvb-transaktion-zuweisen-header';
export *
    from './component/bank-statement/zuweisung/dvb-transaktion-zuweisen-vorgeschlagen/dvb-transaktion-zuweisen-vorgeschlagen';
export * from './component/bank-statement/zuweisung/dvb-transaktion-zuweisen/dvb-transaktion-zuweisen';
export * from './component/dvb-faktura-main/dvb-faktura-main';
export * from './component/einzahlungsschein/dvb-einzahlungsschein-form/dvb-einzahlungsschein-form';
export * from './component/einzahlungsschein/dvb-einzahlungsscheine/dvb-einzahlungsscheine';
export * from './component/konto/dvb-konten/dvb-konten';
export * from './component/konto/dvb-konto-form/dvb-konto-form';
export * from './component/rechnung/dvb-offene-posten-bemerkung-icon/dvb-offene-posten-bemerkung-icon';
export * from './component/rechnung/dvb-rechnung-detail/dvb-rechnung-detail';
export * from './component/rechnung/dvb-rechnung-heading-attribute/dvb-rechnung-heading-attribute';
export * from './component/rechnung/dvb-rechnung-heading-payment-due-icon/dvb-rechnung-heading-payment-due-icon';
export * from './component/rechnung/dvb-rechnung-heading/dvb-rechnung-heading';
export * from './component/rechnung/dvb-rechnung-main/dvb-rechnung-main';
export * from './component/rechnung/dvb-rechnung-position/dvb-rechnung-position';
export * from './component/rechnung/dvb-rechnung-preview/dvb-rechnung-preview';
export * from './component/rechnung/dvb-rechnung-status/dvb-rechnung-status';
export * from './component/rechnung/dvb-rechnung-stornieren-confirm/dvb-rechnung-stornieren-confirm';
export * from './component/rechnung/dvb-rechnung-task-icon/dvb-rechnung-task-icon';
export * from './component/rechnung/dvb-rechnung-ueberschuss-zuweisen/dvb-rechnung-ueberschuss-zuweisen';
export * from './component/rechnung/dvb-rechnung-uebersicht/dvb-rechnung-uebersicht';
export * from './component/rechnung/dvb-rechnung-versand-status/dvb-rechnung-versand-status';
export * from './component/rechnung/dvb-rechnungen-list/dvb-rechnungen-list';
export * from './component/rechnung/dvb-rechnungen/dvb-rechnungen';
export * from './component/rechnung/dvb-rechnungs-revision-error/dvb-rechnungs-revision-error';
export * from './component/rechnung/dvb-rechnungs-revisionen/dvb-rechnungs-revisionen';
export * from './component/rechnung/dvb-rechnungs-status-history/dvb-rechnungs-status-history';
export * from './component/rechnung/zahlung/dvb-rechnung-restbetrag/dvb-rechnung-restbetrag';
export * from './component/rechnung/zahlung/dvb-rechnung-zahlung-erfassen/dvb-rechnung-zahlung-erfassen';
export * from './component/rechnung/zahlung/dvb-rechnung-zahlungen/dvb-rechnung-zahlungen';
export * from './component/rechnung/zahlung/dvb-zahlung-ausgleichen-rechnung/dvb-zahlung-ausgleichen-rechnung';
export * from './component/rechnung/zahlung/dvb-zahlung-ausgleichen/dvb-zahlung-ausgleichen';
export * from './component/rechnung/zahlung/dvb-zahlung-heading/dvb-zahlung-heading';

export * from './filter/esr';
// Add New Component JS Above

fakturaConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function fakturaConfig($stateProvider: StateProvider, $uiRouterProvider: UIRouter): void {

    $stateProvider.state('base.rechnung', {
        url: '/rechnung/{rechnungId:[0-9]+}',
        abstract: true,
        template: '<dvb-rechnung-main rechnung="$resolve.rechnung"></dvb-rechnung-main>',
        resolve: {
            rechnung: [
                'fakturaService',
                '$transition$',
                (fakturaService: FakturaService, $transition$: Transition): angular.IPromise<Rechnung | null> => {

                    const params = {
                        includes: '(' +
                            'ausstehenderBetrag,' +
                            'kinder,' +
                            'folgeRechnung,' +
                            'vorgaengerRechnung,' +
                            'stateTransitions,' +
                            'stateHistory,' +
                            'emailVersandHistory,' +
                            'rechnungsLaufLock)',
                    };

                    return fakturaService.getRechnung($transition$.params().rechnungId, params);
                },
            ],
            $title: ['rechnung', (rechnung: Rechnung): string => rechnung.getDisplayName()],
        },
        data: {
            permission: PERMISSION.MODULE.FAKTURA,
        },
    });
    $stateProvider.state('base.rechnung.uebersicht', {
        url: '',
        template: '<dvb-rechnung-uebersicht rechnung="$resolve.rechnung"></dvb-rechnung-uebersicht>',
    });
    $stateProvider.state('base.rechnung.stornieren', {
        url: '/stornieren',
        params: {
            rechnungen: [],
        },
        component: 'dvbRechnungStornierenConfirm',
        resolve: {
            rechnungId: [
                '$transition$',
                ($transition$: Transition): angular.IPromise<string | null> => {
                    return $transition$.params().rechnungId;
                },
            ],
            rechnungen: [
                '$transition$',
                ($transition$: Transition): angular.IPromise<Rechnung[]> => {
                    return $transition$.params().rechnungen;
                },
            ],
        },
    });
    $stateProvider.state('base.rechnung.revisionen', {
        url: '/revisionen?{revisionId:[0-9]+}',
        component: 'dvbRechnungsRevisionen',
        resolve: {
            rechnungen: [
                'fakturaService',
                '$transition$',
                (fakturaService: FakturaService, $transition$: Transition): angular.IPromise<Rechnung[]> =>
                    fakturaService.getRechnungsRevisionen($transition$.params().rechnungId),
            ],
            revisionId: [
                '$transition$',
                ($transition$: Transition): string | undefined => $transition$.params().revisionId,
            ],
        },
    });

    zahlungenConfig($stateProvider);
    kontentabgleichConfig($stateProvider);
    mandantFakturaConfig($stateProvider);

    $uiRouterProvider.urlService.rules.when('/faktura', '/faktura/konfiguration');
    /* Add New States Above */
}

function zahlungenConfig($stateProvider: StateProvider): void {
    $stateProvider.state('base.rechnung.zahlung-ausgleichen', {
        url: '/ausgleichen',
        abstract: true,
        template: '<div ui-view></div>',
        params: {
            ausstehenderBetrag: null,
        },
        resolve: {
            ausstehenderBetrag: [
                '$transition$', 'fakturaService', (
                    $transition$: Transition,
                    fakturaService: FakturaService,
                ): number =>
                    // use provided value, if undefined, fetch it from backend
                    $transition$.params().ausstehenderBetrag ?
                        $transition$.params().ausstehenderBetrag :
                        fakturaService.getZahlungen($transition$.params().rechnungId)
                            .then(response => response?.ausstehenderBetrag),
            ],
            DTYPES: [(): typeof OffenerPostenType => OffenerPostenType],
        },
    });
    $stateProvider.state('base.rechnung.zahlung-ausgleichen.vorauszahlung', {
        url: '/vorauszahlung',
        template: '<dvb-zahlung-ausgleichen ' +
            'rechnung="$resolve.rechnung" ' +
            'ausstehender-betrag="$resolve.ausstehenderBetrag" ' +
            'zahlung-type="$resolve.DTYPES.VORAUSZAHLUNG"></dvb-zahlung-ausgleichen>',
    });
    $stateProvider.state('base.rechnung.zahlung-ausgleichen.rueckerstattung', {
        url: '/rueckerstattung',
        template: '<dvb-zahlung-ausgleichen ' +
            'rechnung="$resolve.rechnung" ' +
            'ausstehender-betrag="$resolve.ausstehenderBetrag" ' +
            'zahlung-type="$resolve.DTYPES.RUECKERSTATTUNG"></dvb-zahlung-ausgleichen>',
    });
    $stateProvider.state('base.rechnung.zahlung-ausgleichen.rechnung', {
        url: '/rechnung',
        template: '<dvb-zahlung-ausgleichen-rechnung ' +
            'rechnung="$resolve.rechnung" ' +
            'ausstehender-betrag="$resolve.ausstehenderBetrag"></dvb-zahlung-ausgleichen-rechnung>',
    });
    $stateProvider.state('base.rechnung.zahlung-ausgleichen.rechnung.confirm', {
        url: '/bestaetigen/{assigningRechnungId:[0-9]+}',
        component: 'dvbRechnungUeberschussZuweisen',
        resolve: {
            assignRechnung: [
                'fakturaService', '$transition$', (
                    fakturaService: FakturaService,
                    $transition$: Transition,
                ): angular.IPromise<Rechnung> => {

                    const params = {includes: '(ausstehenderBetrag)'};

                    return fakturaService.getRechnung($transition$.params().assigningRechnungId, params);
                },
            ],
        },
    });
    $stateProvider.state('base.rechnung.zahlungen', {
        url: '/zahlungen',
        template: '<dvb-rechnung-zahlungen rechnung="$resolve.rechnung"></dvb-rechnung-zahlungen>',
    });
    $stateProvider.state('base.rechnung.zahlungen-erfassen', {
        url: '/zahlungen/erfassen',
        params: {betrag: null},
        template: '<dvb-rechnung-zahlung-erfassen ' +
            'rechnung="$resolve.rechnung" ' +
            'betrag="$resolve.betrag"></dvb-rechnung-zahlung-erfassen>',
        resolve: {
            betrag: ['$transition$', ($transition$: Transition): number | null => $transition$.params().betrag],
        },
    });
}

function kontentabgleichConfig($stateProvider: StateProvider): void {
    $stateProvider.state('base.kontenabgleich', {
        url: '/kontenabgleich',
        abstract: true,
        template: '<dvb-kontenabgleich-main></dvb-kontenabgleich-main>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                    trans($translate, 'FAKTURA.KONTENABGLEICH.KONTENABGLEICH'),
            ],
        },
        data: {
            permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL_ANY],
        },
    });
    $stateProvider.state('base.kontenabgleich.transaktionen', {
        url: '/transaktionen?:bankStatementIds:states:gueltigAb:gueltigBis',
        component: 'dvbTransaktionen',
        resolve: {
            gueltigAb: ['$transition$', ($transition$: Transition): moment.Moment => $transition$.params().gueltigAb],
            gueltigBis: ['$transition$', ($transition$: Transition): moment.Moment => $transition$.params().gueltigBis],
            bankStatementIds: [
                '$transition$', ($transition$: Transition): EntityId[] => $transition$.params().bankStatementIds,
            ],
            states: [
                '$transition$', ($transition$: Transition): BankStatementEntryStatus[] => {
                    const states = $transition$.params().states;

                    return states && !Array.isArray(states) ? [states] : states;
                },
            ],
            bankStatements: [
                'bankStatementService', '$transition$', '$q',
                (
                    bankStatementService: BankStatementService,
                    $transition$: Transition,
                    $q: angular.IQService,
                ): angular.IPromise<BankStatement[]> =>
                    $transition$.params().bankStatementIds ?
                        bankStatementService.getBankStatementForIds($transition$.params().bankStatementIds) :
                        $q.resolve([]),
            ],
        },
    });
    $stateProvider.state('base.kontenabgleich.zuweisen', {
        abstract: true,
        url: '/zuweisen/{id:[0-9]+}',
        template: '<dvb-transaktion-zuweisen bank-statement-entry="$resolve.entry"></dvb-transaktion-zuweisen>',
        resolve: {
            entry: [
                'bankStatementService', '$transition$', (
                    bankStatementService: BankStatementService,
                    $transition$: Transition,
                ): angular.IPromise<BankStatementEntry | null> =>
                    bankStatementService.getBankStatementEntry($transition$.params().id),
            ],
        },
    });
    $stateProvider.state('base.kontenabgleich.zuweisen.vorgeschlagen', {
        url: '/zuweisen/{id:[0-9]+}/vorgeschlagen',
        template: '<dvb-transaktion-zuweisen-vorgeschlagen ' +
            'bank-statement-entry="$resolve.entry">' +
            '</dvb-transaktion-zuweisen-vorgeschlagen>',
    });

    const rechnung = [
        'fakturaService', '$transition$', (
            fakturaService: FakturaService,
            $transition$: Transition,
        ): angular.IPromise<Rechnung> => {
            const rechnungId = $transition$.params().assigningRechnungId;

            return fakturaService.getRechnung(rechnungId, {includes: '(ausstehenderBetrag)'});
        },
    ];

    $stateProvider.state('base.kontenabgleich.zuweisen.vorgeschlagen.confirm', {
        url: '/bestaetigen/{assigningRechnungId:[0-9]+}',
        component: 'dvbBankStatementEntryAssign',
        resolve: {
            rechnung,
        },
    });
    $stateProvider.state('base.kontenabgleich.zuweisen.alle', {
        url: '/zuweisen/{id:[0-9]+}/alle',
        template: '<dvb-transaktion-zuweisen-alle ' +
            'bank-statement-entry="$resolve.entry">' +
            '</dvb-transaktion-zuweisen-alle>',
    });
    $stateProvider.state('base.kontenabgleich.zuweisen.alle.confirm', {
        url: '/bestaetigen/{assigningRechnungId:[0-9]+}',
        component: 'dvbBankStatementEntryAssign',
        resolve: {
            rechnung,
        },
    });
    $stateProvider.state('base.kontenabgleich.import', {
        url: '/import',
        template: '<dvb-transaktionen-import></dvb-transaktionen-import>',
        data: {
            permission: PERMISSION.KITA.MANAGE_ANY,
        },
    });
    $stateProvider.state('base.kontenabgleich.kontoauszuege', {
        url: '/kontoauszuege',
        template: '<dvb-konto-auszuege></dvb-konto-auszuege>',
    });
}

function mandantFakturaConfig($stateProvider: StateProvider): void {
    $stateProvider.state('base.faktura', {
        url: '/faktura',
        abstract: true,
        template: '<dvb-faktura-main></dvb-faktura-main>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): IPromise<string> =>
                    trans($translate, 'FAKTURA.FAKTURAEINSTELLUNGEN'),
            ],
        },
    });
    $stateProvider.state('base.faktura.konfiguration', {
        url: '/konfiguration',
        template: '<dvb-rechnungs-konfigurationen konfigurationen="$resolve.konfigurationen">' +
            '</dvb-rechnungs-konfigurationen>',
        resolve: {
            konfigurationen: [
                'mandantService',
                (mandantService: MandantService): angular.IPromise<RechnungsKonfiguration<IRechnungsKonfiguration>[]> =>
                    mandantService.getRechnungsKonfigurationen(),
            ],
        },
        data: {
            permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL_ANY],
        },
    });
    $stateProvider.state('base.faktura.einzahlungsschein', {
        url: '/einzahlungsschein',
        template: '<dvb-einzahlungsscheine einzahlungsscheine="$resolve.einzahlungsscheine"></dvb-einzahlungsscheine>',
        resolve: {
            einzahlungsscheine: [
                'dvbFakturaService', (dvbFakturaService: DvbFakturaService): angular.IPromise<Einzahlungsschein[]> =>
                    dvbFakturaService.getAllEinzahlungsscheine(),
            ],
        },
        data: {
            permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL_ANY],
        },
    });
    $stateProvider.state('base.faktura.konto', {
        url: '/konto',
        component: 'dvbKonten',
        data: {
            permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL_ANY],
        },
    });
}

updateTemplates.$inject = ['$templateCache'];

function updateTemplates($templateCache: angular.ITemplateCacheService): void {
    $templateCache.put('rechnungsRevisionErrorPopoverTemplate.html',
        '<ul class="dvb-rechnungs-revision-error-list" ng-if="vm.errors.length > 0">' +
        '<li ng-repeat="error in vm.errors | orderBy:errorType" ' +
        'data-translate="{{error.errorType.msgKey}}" ' +
        'data-translate-values="error"></li>' +
        '</ul>');
    $templateCache.put('rechnungsdifferenzTooltipTemplate.html',
        '<div class="popover-body">' +
        '<p data-translate="FAKTURA.ES_GIBT_NEUE_LEISTUNGSRECHNUNG" ' +
        '   ng-if="vm.rechnung.rechnungsdifferenz"></p>' +
        '<p data-translate="{{' +
        'vm.rechnung.summe < 0 ? \'FAKTURA.NEGATIVES_RECHNUNGSTOTAL\' : \'FAKTURA.RECHNUNG_IST_UEBERBEZAHLT\'}}" ' +
        '   ng-if="vm.rechnung.rechnungsUeberschuss"></p>' +
        '<p data-translate="FAKTURA.RECHNUNGS_KONFIGURATION_DEAKTIVIERT" ' +
        '   ng-if="vm.rechnung.disabledRechnungsKonfiguration"></p>' +
        '<p data-translate="FAKTURA.RECHNUNGS_EMAIL_FEHLGESCHLAGEN" ' +
        '   data-translate-value-email="{{vm.failedEmail}}"' +
        '   data-translate-value-time="{{vm.failedTime | amDateFormat:\'D.M.YYYY HH:mm:ss\'}}"' +
        '   ng-if="vm.rechnung.deliveryFailure && vm.failedEmail"></p>' +
        '<p data-translate="FAKTURA.RECHNUNG_DELIVERY_FEHLER" ' +
        '   ng-if="vm.rechnung.deliveryFailure && !vm.failedEmail"></p>' +
        '</div>');
    $templateCache.put('zahlungsFristAbgelaufen.html',
        '<div class="popover-body">' +
        '<p data-translate="FAKTURA.MF_ZAHLUNGSFRIST_POPOVER_TEXT" ' +
        'data-translate-value-date-diff="{{vm.today.diff(vm.rechnung.zahlungsFrist, \'days\')}}" ' +
        'data-translate-interpolation="messageformat"></p>' +
        '</div>');

    $templateCache.put('showBemerkung.html',
        '<div class="popover-body" ng-bind="vm.offenerPosten.bemerkung"></div>');
}
