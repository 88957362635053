/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AnwesenheitsZeitConstraint} from '@dv/kitadmin/models';
import {DvbUtil, isNullish} from '@dv/shared/code';
import angular from 'angular';

angular.module('kitAdmin').directive('dvbAnwesenheitsZeitConstraint', dvbAnwesenheitsZeitConstraint);

type Scope = angular.IScope & { constraints: AnwesenheitsZeitConstraint[] };

type Directive = angular.IDirective<Scope, JQLite, angular.IAttributes, angular.INgModelController>;

/**
 * Checks whether a time inputs value matches any of the given AnwesenheitsZeitConstraints.
 */
function dvbAnwesenheitsZeitConstraint(): Directive {
    return {
        require: 'ngModel',
        scope: {
            constraints: '<',
        },
        restrict: 'A',
        link: (scope, _element, _attrs, ctrl?: angular.INgModelController): void => {

            const ngModel = ctrl!;
            const anwesenheitsZeitConstraints = scope.constraints;

            ngModel.$validators.anwesenheitsZeitConstraint = isValidAnwesenheitsZeit;

            function isValidAnwesenheitsZeit(modelValue: moment.Moment | undefined, _viewValue: any): boolean {
                if (isNullish(modelValue) || !DvbUtil.isNotEmptyArray(anwesenheitsZeitConstraints)) {
                    return true;
                }

                return anwesenheitsZeitConstraints.some(constraint =>
                    modelValue.isBetween(constraint.von, constraint.bis, undefined, '[]'));
            }
        },
    };
}
