<div class="dvb-administration-mandant-rechnung-detail">
    <div class="table-header">
        <div class="row">
            <div class="col-xs-6" data-translate="COMMON.POSITION"></div>
            <div class="col-xs-2 text-right" data-translate="COMMON.ANZAHL"></div>
            <div class="col-xs-2 text-right" data-translate="COMMON.BETRAG"></div>
            <div class="col-xs-2 text-right" data-translate="COMMON.TOTAL"></div>
        </div>
    </div>

    <div class="detail small-abstand-top">
        <div class="small-abstand-top"
             data-translate="ADMINISTRATION.KEINE_RECHNUNGS_POSITIONEN"
             ng-if="vm.rechnung.rechnungsPositionen.length === 0">
        </div>
        <div class="small-abstand-top" ng-if="vm.rechnung.kitaCount > 0">
            <strong data-translate="ADMINISTRATION.VERRECHENBAR_KINDERORT.MF"
                    data-translate-interpolation="messageformat"
                    data-translate-value-count="{{vm.rechnung.kitaCount}}"
                    data-translate-value-showcount="true"></strong>
        </div>
        <div ng-repeat="position in vm.rechnung.rechnungsPositionen | filter: {type : 'KITA', count : '!0'} | orderBy: 'text'">
            <dvb-administration-mandant-rechnungsposition rechnungs-position="position">
            </dvb-administration-mandant-rechnungsposition>
        </div>

        <div class="small-abstand-top"
             ng-if="vm.getNichtVerrechenbareKita().length > 0">
            <strong data-translate="ADMINISTRATION.NICHT_VERRECHENBAR_KINDERORT.MF"
                    data-translate-interpolation="messageformat"
                    data-translate-value-count="{{(vm.rechnung.rechnungsPositionen | filter: {type : 'KITA', count : '0'}).length}}"
                    data-translate-value-showcount="true"></strong>
        </div>
        <div ng-repeat="position in vm.getNichtVerrechenbareKita() | orderBy: 'text'">
            <dvb-administration-mandant-rechnungsposition rechnungs-position="position">
            </dvb-administration-mandant-rechnungsposition>
        </div>

        <div class="small-abstand-top" ng-if="vm.rechnung.tageselternCount > 0">
            <strong data-translate="COMMON.TAGES_ELTERN.MF"
                    data-translate-interpolation="messageformat"
                    data-translate-value-count="{{vm.rechnung.tageselternCount}}"
                    data-translate-value-showcount="true"></strong>
        </div>
        <div ng-repeat="position in vm.rechnung.rechnungsPositionen | filter: {type : 'TAGES_ELTERN'} | orderBy: 'text'">
            <dvb-administration-mandant-rechnungsposition rechnungs-position="position">
            </dvb-administration-mandant-rechnungsposition>
        </div>

        <div class="small-abstand-top" ng-if="vm.rechnung.customPositionen.length > 0 || vm.formContext.editMode">
            <strong data-translate="ADMINISTRATION.CUSTOM_RECHNUNG_POSITION"></strong>
        </div>
        <div ng-if="!vm.formContext.editMode"
             ng-repeat="position in vm.rechnung.customPositionen | orderBy: 'text'">
            <dvb-administration-mandant-rechnungsposition rechnungs-position="position" on-delete="vm.delete(position)">
            </dvb-administration-mandant-rechnungsposition>
        </div>

        <div class="small-abstand-top" ng-if="!vm.formContext.editMode">
            <button class="btn btn-primary"
                    data-translate="ADMINISTRATION.CUSTOM_RECHNUNG_POSITIONEN_ANPASSEN"
                    ng-click="vm.formContext.enableEditMode()"
                    type="button">
            </button>
        </div>

        <div class="small-abstand-top" ng-if="vm.formContext.editMode">
            <dvb-administration-custom-rechnung-position-form existing-custom-positionen="vm.rechnung.customPositionen"
                                                              form-context="vm.formContext"
                                                              on-cancel="vm.formContext.close()"
                                                              on-update="vm.saveAndUpdatePositionen(customPositionen)">
            </dvb-administration-custom-rechnung-position-form>
        </div>
    </div>
</div>
