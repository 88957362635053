/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import {FilterModelController} from '../shared/FilterModelController';
import type {IsFilterActive} from '../shared/MicroFilterAttribute';
import {MicroFilterAttribute} from '../shared/MicroFilterAttribute';

export abstract class SetFilterController<T, TUserModel = Iterable<T> | ArrayLike<T>>
    extends FilterModelController<Set<T>, TUserModel> {

    /**
     * Die Werte, welche vom Filter berücksichtigt werden sollen (e.g. die FilterOptions des aktuellen States).
     */
    protected relevantValues: Set<T>;

    public constructor(
        initialValues: Set<T>,
        filter?: IsFilterActive<Set<T>>,
    ) {
        super(new MicroFilterAttribute<Set<T>>(new Set(initialValues), _filterModel => true));
        this.model.isActive = filter ?? SetFilterController.defaultFilter(this);
        this.relevantValues = new Set(initialValues);
        this.isActive = this.model.isFilterSet();
    }

    private static defaultFilter<T, TUserModel>(ctrl: SetFilterController<T, TUserModel>): IsFilterActive<Set<T>> {
        return (filterModel: Set<T>) => {
            const filterValues = new Set(Array.from(filterModel.values())
                .filter(v => ctrl.relevantValues.has(v)));

            return filterValues.size > 0;
        };
    }

    public setRelevantValues(values: Iterable<T> | ArrayLike<T>): void {
        this.relevantValues = new Set(Array.from(values));
        this.isActive = this.model.isFilterSet();
    }

    public getRelevantSelectedValues(): Set<T> {
        return new Set([...this.model.filterModel].filter(value => this.relevantValues.has(value)));
    }

    public reset(): void {
        const hasChanged = Array.from(this.relevantValues.values())
            .map(v => this.model.filterModel.delete(v))
            .reduce((a, b) => a || b, false);

        if (hasChanged) {
            this.filterModelChanged();
        }
    }

    /**
     * isVisible und isActive berücksichtigen nur Werte, welche in dem "relevantValues" set definiert wurden.
     */
    public override isVisible(userModel?: TUserModel): boolean | angular.IPromise<boolean> {
        if (this.model.filterModel.size === 0 || userModel === undefined) {
            return true;
        }

        return this.filterAcceptsUserModel(userModel);
    }

    protected filterAcceptsUserModel(userModel: TUserModel): boolean | angular.IPromise<boolean> {
        if (!DvbUtil.isIterable<T>(userModel)) {
            throw new Error(`UserModel is not iterable: ${JSON.stringify(userModel)}`);
        }

        return Array.from(userModel)
            .some(userValue => this.model.filterModel.has(userValue) && this.relevantValues.has(userValue));
    }

    public abstract onSelect(valueToAdd: T): void;

    public abstract onRemove(valueToRemove: T): void;
}
