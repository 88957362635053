<div class="dvb-kinder-ort-schliesstage-form dvb-form">

    <form name="kinderOrtSchliesstageZuweisenForm"
          ng-submit="vm.submit(kinderOrtSchliesstageZuweisenForm.$valid)"
          novalidate>

        <div class="row" ng-if="vm.showGueltigAb">
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.kinderOrtSchliesstage.gueltigAb"
                                           required
                                           dvb-begin-of-month
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <select class="form-control"
                        uib-tooltip="{{'KINDERORT.SCHLIESSTAGE.AUSWAEHLEN' | translate}}"
                        ng-model="vm.kinderOrtSchliesstage.schliesstage"
                        ng-options="tage as tage.name for tage in vm.availableSchliesstage track by tage.id">
                    <option selected data-translate="COMMON.KEINE_AUSWAEHLEN" ng-value="null"></option>
                </select>
            </div>
        </div>

        <div class="normal-abstand-top">
            <ul class="list-inline">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="KINDERORT.SCHLIESSTAGE.ZUWEISEN"></span>
                    </dvb-loading-button>
                </li>
                <li>
                    <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.cancel()"></a>
                </li>
            </ul>
        </div>

    </form>
</div>
