/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, KiTaxVerfuegung, UploadTempBlob} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {BetreuungsGutscheinService} from '../../../../../common/service/rest/kind/betreuungsGutscheinService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
    },
    template: require('./dvb-kitax-import.html'),
    controllerAs: 'vm',
};

export class DvbKitaxImport implements angular.IController {

    public static $inject: readonly string[] = ['errorService', 'betreuungsGutscheinService'];

    public kind!: Kind;

    public isLoading: boolean = false;
    public isModeUpload: boolean = true;
    public file: UploadTempBlob | null = null;
    public verfuegung: KiTaxVerfuegung | null = null;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly betreuungsGutscheinService: BetreuungsGutscheinService,
    ) {
    }

    public onSubmit(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return;
        }

        this.errorService.clearErrorByMsgKey('ERRORS.ERR_INCOMPLETE_FORM');

        this.isLoading = true;
        const tempBlobId = checkPresent(checkPresent(this.file).id);

        this.betreuungsGutscheinService.importFromKiTax(checkPresent(this.kind.id), tempBlobId)
            .then(verfuegung => {
                this.isModeUpload = false;
                this.verfuegung = verfuegung;
            })
            .catch(() => {
                // nop
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbKitaxImport;
angular.module('kitAdmin').component('dvbKitaxImport', componentConfig);
