<span class="dvb-rechnung-heading-payment-due-icon">
    <span ng-if="vm.showFrist()">
        <i class="custom-icon custom-icon-hourglass"
           uib-popover-template="'zahlungsFristAbgelaufen.html'"
           popover-title="{{'FAKTURA.ZAHLUNGSFRIST_POPOVER_TITLE' | translate:'{datum: \'' + vm.rechnung.zahlungsFrist.format('D.M.YYYY') + '\'}'}}"
           popover-trigger="'mouseenter focus'"
           popover-placement="bottom"
           popover-append-to-body="true"></i>
    </span>
</span>
