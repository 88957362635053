/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Termin} from '@dv/kitadmin/models';
import {hasOwnPropertyGuarded, isNullish} from '@dv/shared/code';
import type {CalendarEvent} from '../../../../calendar/timeline/model/CalendarEvent';
import type {AngestellteZuweisung} from '../../../../personal/model/AngestellteZuweisung';
import {LayerType} from './LayerType';

export function isTerminEvent<T extends CalendarEvent>(
    event: T,
): event is T & { data: { termin: Termin } } {

    return event.layer === LayerType.TERMIN
        && hasOwnPropertyGuarded(event.data, 'termin');
}

export function isAngestellteZuweisungEvent<T extends CalendarEvent>(
    event: T,
): event is T & { data: { zuweisung: AngestellteZuweisung } } {

    return event.layer === LayerType.ZUWEISUNG
        && hasOwnPropertyGuarded(event.data, 'zuweisung');
}

export function resetToOriginalEvent(event: CalendarEvent, originalEvent?: CalendarEvent): void {
    if (isNullish(originalEvent)) {
        return;
    }

    event.spaceBefore = originalEvent.spaceBefore;
    event.spaceAfter = originalEvent.spaceAfter;
    event.completeDuration = originalEvent.completeDuration;
    event.von = originalEvent.von;
    event.bis = originalEvent.bis;
}
