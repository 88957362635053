import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {TarifrechnungPosition} from '@dv/kitadmin/models';
import {LeistungspositionComponent} from '../leistungsposition/leistungsposition.component';

@Component({
    selector: 'dv-kind-leistungen-positionen',
    standalone: true,
    imports: [CommonModule, LeistungspositionComponent],
    templateUrl: './kind-leistungen-positionen.component.html',
    styles: ':host { display: block; }',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KindLeistungenPositionenComponent {
    @Input({required: true}) public tarifrechnungPositionen: TarifrechnungPosition[] = [];
}
