/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType, SearchResultEntry} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import {FilterOption} from './FilterOption';

export class FilterOptionHelper {

    public constructor(public onChange: FunctionType = () => undefined) {
    }

    public onToggle(filter: FilterOption, selected: boolean): void {
        if (selected && !filter.sticky) {
            filter.sticky = true;
        }

        filter.selected = selected;

        this.onChange();
    }

    public onClose(filter: FilterOption): void {
        filter.sticky = false;
        filter.selected = false;

        this.onChange();
    }

    public addFromSearchResult(target: FilterOption[], item: SearchResultEntry): void {
        const filterOption = this.add(target, item.id, item.text);
        this.onToggle(filterOption, true);
    }

    public createFromSearchResult(item: SearchResultEntry): FilterOption {
        return new FilterOption(item.id, item.text, true, true);
    }

    public add(target: FilterOption[], id: string, label: string): FilterOption {
        const found = DvbUtil.findFirst(target, filterOption => filterOption.id === id);

        if (found) {
            // no need to add a duplicate
            return found;
        }

        const newFilterOption = new FilterOption(id, label, false, false);
        target.push(newFilterOption);

        return newFilterOption;
    }

    public remove(target: FilterOption[], filter: FilterOption): void {
        DvbUtil.removeFromArray(filter, target);
        this.onClose(filter);
    }
}
