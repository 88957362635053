<div class="dvb-kita-betreuungsfaktor-regel-verlauf">

    <div
        ng-repeat="kitaBetreuungsfaktorRegel in (sortedBetreuungsregeln = (vm.kitaBetreuungsfaktorRegeln | toArray:false | orderBy:'gueltigAb':true))">

        <!-- Beendete BetreuungsfaktorRegel -->
        <dvb-collapsible-panel
            ng-show="sortedBetreuungsregeln[$index-1].gueltigAb.diff(kitaBetreuungsfaktorRegel.gueltigBis, 'days') > 1 || $first && vm.isBetreuungsregelBeendet(kitaBetreuungsfaktorRegel)"
            is-first="$first"
            is-collapsed="true"
            dvb-auth
            require-permission="{{'kita:administrate:' + kitaBetreuungsfaktorRegel.kitaId}}"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{kitaBetreuungsfaktorRegel.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{kitaBetreuungsfaktorRegel.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.revert(kitaBetreuungsfaktorRegel)">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="kitaBetreuungsfaktorRegel"
                               is-first="$first && !vm.isBetreuungsregelBeendet(kitaBetreuungsfaktorRegel)"
                               expand-ab="vm.today"
                               expand-bis="vm.today"
                               dvb-auth
                               require-permission="{{'kita:administrate:' + kitaBetreuungsfaktorRegel.kitaId}}"
                               on-unauthorised-disable>
            <panel-header>
                <dvb-kita-betreuungsfaktor-regel-heading kita-betreuungsfaktor-regel="kitaBetreuungsfaktorRegel">
                </dvb-kita-betreuungsfaktor-regel-heading>
            </panel-header>
            <panel-content>
                <dvb-kita-betreuungsfaktor-regel-content
                    kita-betreuungsfaktor-regel="kitaBetreuungsfaktorRegel"
                    on-remove="vm.remove(kitaBetreuungsfaktorRegel)">
                </dvb-kita-betreuungsfaktor-regel-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
