/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import {Austritt, KindKontakteUtil} from '@dv/kitadmin/models';
import {DialogService} from '@dv/kitadmin/ui';
import type {DialogModel} from '@dv/shared/angular';
import {
    ButtonListComponent,
    DatepickerTextfieldComponent,
    LoadingButtonComponent,
} from '@dv/shared/angular';
import {Persisted} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {StateService} from '@uirouter/core';
import type angular from 'angular';
import type moment from 'moment';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {finalize, from} from 'rxjs';
import {KindService} from '../../../common/service/rest/kind/kindService';
import {BULK_EMAIL_MANAGEMENT_STATE} from '../../../communication/communication-states';

export interface KindAustrittDialogModel extends DialogModel {
    kind: Persisted<Kind>;
    kindService: KindService;
}

@Component({
    selector: 'dv-kind-austritt',
    templateUrl: './kind-austritt.component.html',
    styleUrls: ['./kind-austritt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        TranslateModule,
        LoadingButtonComponent,
        DatepickerTextfieldComponent,
        NgIf,
        TooltipModule,
        ButtonListComponent,
    ],
})
export class KindAustrittComponent implements KindAustrittDialogModel {

    @Input() public kind!: Persisted<Kind>;
    @Input() public kindService!: KindService;

    public austrittsDatum?: moment.Moment | null = null;
    public provisorisch: boolean = false;
    public mailBestaetigung: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly stateService: StateService,
    ) {
    }

    public submit(form: NgForm): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(!form.controls.austrittsDatum.errors?.required,
            'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(!form.controls.austrittsDatum.errors?.bsDate,
            'ERRORS.ERR_INVALID_DATE');

        if (form.invalid) {
            return;
        }

        this.isLoading = true;
        if (this.hasBelegungenNachAustritt()) {
            this.dialogService.openConfirmDialog({
                title: 'KIND.KIND_AUSTRITT_CONFIRM_BELEGUNGEN',
                confirmActionText: 'KIND.AUSTRITT',
                confirm: () => from(this.austrittAction()).pipe(finalize(() => this.hide())),
                cancel: () => this.hide(),
            });

            return;
        }

        this.austrittAction();
    }

    public hide(): void {
        this.bsModalRef.hide();
    }

    private hasBelegungenNachAustritt(): boolean {
        return this.kind.belegungen.some(belegung => belegung.gueltigAb?.isAfter(this.austrittsDatum));
    }

    private austrittAction(): angular.IPromise<unknown> {

        const austritt = new Austritt(this.austrittsDatum, this.provisorisch);
        const austrittPromise = this.kindService.austritt(this.kind.id, austritt);
        if (this.mailBestaetigung) {
            return austrittPromise
                .then(() => this.kindService.getAllRelationshipsWithKontaktpersonen(
                    this.kind.id,
                    {includes: '(kontaktperson,relationship)'}))
                .then(kontakte => {
                    const hauptkontakt =
                        KindKontakteUtil.findHauptkontaktRelationshipsWithKontaktperson(kontakte)[0].kontaktperson;

                    return this.stateService.go(BULK_EMAIL_MANAGEMENT_STATE.name, {kontaktpersonen: [hauptkontakt]})
                        .then(() => this.hide());
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        return austrittPromise
            .then(() => this.stateService.reload().then(() => this.hide()))
            .finally(() => {
                this.isLoading = false;
            });
    }
}
