/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gebuehr, Kontaktperson, Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from '../../../common/service/rest/dvbRestUtilAngularJS';
import type {FakturaService} from '../../../common/service/rest/fakturaService';
import type {RechnungenForKontaktpersonZahlung} from '../../../filter/RechnungenFilter-kontaktperson';
import {OFFENE_POSTEN_STATE} from '../../kontaktperson-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        depot: '<',
        kontaktperson: '<',
    },
    template: require('./dvb-depot-zuweisen-vorgeschlagen.html'),
    controllerAs: 'vm',
};

export class DvbDepotZuweisenVorgeschlagen implements angular.IOnInit {
    public static $inject: readonly string[] = ['fakturaService', '$q'];

    public depot!: Persisted<Gebuehr>;
    public kontaktperson!: Persisted<Kontaktperson>;

    public isLoading: boolean = false;
    public rechnungen: Rechnung[] = [];

    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.TRANSAKTION;
    public displayModes = RechnungDisplayMode.modes;

    public readonly OFFENE_POSTEN_STATE = OFFENE_POSTEN_STATE;

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly fakturaService: FakturaService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.loadRechnungen();
    }

    public loadRechnungen(): void {
        this.isLoading = true;
        DvbRestUtilAngularJS.cancelRequest(this.timeout);

        const options: RechnungenForKontaktpersonZahlung = {
            kontaktpersonId: checkPresent(this.kontaktperson.id),
            kitaId: checkPresent(this.depot.kitaId),
        };

        this.timeout = this.$q.defer();

        const config = {timeout: this.timeout.promise};
        this.fakturaService.getRechnungenToAssignZahlung(options, config)
            .then(pageContainer => {
                this.rechnungen = pageContainer.items;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }
}

componentConfig.controller = DvbDepotZuweisenVorgeschlagen;
angular.module('kitAdmin').component('dvbDepotZuweisenVorgeschlagen', componentConfig);
