/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').filter('roundTo', roundTo);

roundTo.$inject = ['numberFilter'];

function roundTo(numberFilter: angular.IFilterNumber): (value: number | string, maxDecimals: number) => string {

    return roundToFormatter;

    /**
     * Rounds a number to the fixed decimals given and then removes insignificant numbers (0).
     *
     * @example
     * 0.105 | roundTo:2 => 0.11
     * 0.1 | roundTo:2 => 0.1
     *
     * @param value
     * @param maxDecimals
     * @return formatted number
     */
    function roundToFormatter(value: any, maxDecimals: number): string {
        return numberFilter((Number(value) || 0)
            .toFixed(maxDecimals)
            .replace(/(?:\.0+|(\.\d+?)0+)$/, '$1'));
    }
}
