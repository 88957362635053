/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Erziehungsberechtigter, KindKontaktRelationship} from '@dv/kitadmin/models';
import {checkPresent, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        hasAdresse: '<',
        kindKontaktRelationship: '<',
        onCreateErziehungsberechtigter: '&',
        onDeleteErziehungsberechtigter: '&',
    },
    template: require('./dvb-erziehungsberechtigter-input.html'),
    controllerAs: 'vm',
};

export class DvbErziehungsberechtigterInput implements angular.IController, angular.IOnInit {

    public static $inject: readonly string[] = ['errorService', '$scope'];

    public hasAdresse!: boolean;
    public kindKontaktRelationship!: KindKontaktRelationship;
    public onCreateErziehungsberechtigter!: (data: { erziehungsberechtigter: Erziehungsberechtigter }) => void;
    public onDeleteErziehungsberechtigter!: (data: { erziehungsberechtigter: Erziehungsberechtigter }) => void;

    public isErziehungsberechtigter: boolean = false;

    public constructor(private errorService: ErrorService, $scope: angular.IScope) {
        $scope.$watch('vm.kindKontaktRelationship.erziehungsberechtigter', () => {
            this.isErziehungsberechtigter = this.kindKontaktRelationship.isErziehungsberechtigter();
        });
    }

    public $onInit(): void {
        this.isErziehungsberechtigter = this.kindKontaktRelationship.isErziehungsberechtigter();
    }

    public changeErziehungsberechtigter(): void {
        if (this.kindKontaktRelationship.isErziehungsberechtigter()) {
            this.deleteErziehungsberechtigter();

            return;
        }

        if (!this.hasAdresse) {
            this.errorService.addValidationError('ERRORS.ERR_ERZIEHUNGSBERECHTIGTER_REQUIRES_ADDRESS');
            this.isErziehungsberechtigter = false;

            return;
        }

        this.createErziehungsberechtigter();
    }

    private deleteErziehungsberechtigter(): void {
        const erziehungsberechtigter = checkPresent(this.kindKontaktRelationship.erziehungsberechtigter);
        this.kindKontaktRelationship.deleteErziehungsberechtigter();
        if (TypeUtil.isFunction(this.onDeleteErziehungsberechtigter)) {
            this.onDeleteErziehungsberechtigter({erziehungsberechtigter});
        }
    }

    private createErziehungsberechtigter(): void {
        this.kindKontaktRelationship.createErziehungsberechtigter();

        if (TypeUtil.isFunction(this.onCreateErziehungsberechtigter)) {
            const erziehungsberechtigter = checkPresent(this.kindKontaktRelationship.erziehungsberechtigter);
            this.onCreateErziehungsberechtigter({erziehungsberechtigter});
        }
    }
}

componentConfig.controller = DvbErziehungsberechtigterInput;
angular.module('kitAdmin').component('dvbErziehungsberechtigterInput', componentConfig);
