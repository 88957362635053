<div class="dvb-rechnung-detail" ng-class="{'flat-layout': vm.flatLayout}">
    <div class="detail detail-no-border" ng-if="vm.mode !== vm.displayModes.UEBERSICHT">
        <div class="row">
            <div class="col-xs-8">
                <span data-translate="COMMON.RECHNUNGSDATUM"></span>
                <span ng-bind="vm.rechnung.rechnungsDatum | amDateFormat: 'DD.MM.YYYY'"></span>
            </div>
            <div class="col-xs-4 text-right">
                <span data-translate="FAKTURA.FAELLIG_AM_DATUM"
                      data-translate-value-datum="{{vm.rechnung.faelligAm | amDateFormat: 'DD.MM.YYYY'}}">
                </span>
            </div>
        </div>
    </div>
    <div class="detail detail-positions" ng-class="{'no-rechnung-header': vm.mode === vm.displayModes.UEBERSICHT}">
        <dvb-rechnung-position position="position"
                               ng-repeat="position in vm.rechnung.kindPositionen">
        </dvb-rechnung-position>
        <dvb-rechnung-position position="position"
                               ng-repeat="position in vm.rechnung.customPositionen">
        </dvb-rechnung-position>
    </div>
    <div class="detail rechnungs-total">
        <span data-translate="COMMON.RECHNUNGS_TOTAL"></span>
        <span class="pull-right"
              data-translate="COMMON.BETRAG_VALUE"
              data-translate-value-betrag="{{vm.rechnung.summe | number: 2}}">
        </span>
    </div>
    <div class="detail amount-pending" ng-if="vm.showPending">
        <span data-translate="FAKTURA.AUSSTEHENDER_BETRAG"></span>
        <span class="pull-right"
              data-translate="COMMON.BETRAG_VALUE"
              data-translate-value-betrag="{{vm.rechnung.ausstehenderBetrag | number: 2}}">
        </span>
    </div>
    <div class="detail hidden-print"
         ng-if="vm.showRechnungAnsehen() || vm.rechnung.hasExternal && vm.mode === vm.displayModes.REVISION">
        <ul class="list-inline list-inline-row">
            <li ng-if="vm.rechnung.hasExternal && vm.mode === vm.displayModes.REVISION">
                <a href="" ng-click="vm.downloadPdf()" data-translate="FAKTURA.HERUNTERLADEN"></a>
                <dvlib-spinner [show]="vm.isDownloading"></dvlib-spinner>
            </li>
            <li ng-if="vm.mode === vm.displayModes.TRANSAKTION && vm.zuweisenStateExists()">
                <a ui-sref=".confirm({assigningRechnungId: vm.rechnung.id})"
                   data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTION_ZUWEISEN">
                </a>
            </li>
            <li ng-if="vm.showRechnungAnsehen()">
                <a ui-sref="base.rechnung.uebersicht({rechnungId: vm.rechnung.id})"
                   data-translate="FAKTURA.RECHNUNG_ANSEHEN">
                </a>
            </li>
        </ul>
    </div>
</div>
