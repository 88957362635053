/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class KibonExchangeBetreuungContext {

    public constructor(
            public kindId: string,
            public refNr: string,
            public gueltigAb: moment.Moment,
            public gueltigBis: moment.Moment,
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeBetreuungContext {
        return new KibonExchangeBetreuungContext(
                data.kindId,
                data.refNr,
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
        );
    }
}
