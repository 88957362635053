<div class="spinner-container" ng-class="{'spinning': vm.isLoading}">

    <div ng-if="vm.anwesenheitsSoll.length !== 0 || !vm.formContext.editMode" class="big-abstand-top">
        <h2 data-translate="KIND.ANWESENHEITS_SOLL.TITLE"></h2>

        <p ng-if="vm.anwesenheitsSoll.length === 0 && !vm.formContext.editMode"
           class="normal-abstand-bottom"
           data-translate="KIND.ANWESENHEITS_SOLL.NONE">
        </p>
    </div>

    <div ng-repeat="entry in vm.anwesenheitsSoll">
        <dvb-anwesenheits-soll-entry anwesenheits-soll="entry"
                                     previous-soll="vm.anwesenheitsSoll[$index - 1]"
                                     first="$first"
                                     enable-edit-mode="vm.enableEditMode"
                                     save="vm.save(soll, context)"
                                     remove="vm.remove(entry)"
                                     revert="vm.revert(entry)">
        </dvb-anwesenheits-soll-entry>
    </div>

    <dvb-limited-entity-assignment-controls ng-if="vm.enableEditMode && vm.anwesenheitsSoll"
                                            entities="vm.anwesenheitsSoll"
                                            translation-root="'KIND.ANWESENHEITS_SOLL'"
                                            date-limitation="vm.dateLimitation"
                                            hide-termination="true"
                                            on-save="vm.save(vm.newAnwesenheitsSoll, context)"
                                            on-new="vm.formContext = context"
                                            on-terminate="vm.terminate(endDate, mode)">
        <entity-form>
            <dvb-anwesenheits-soll-form anwesenheits-soll="vm.newAnwesenheitsSoll">
            </dvb-anwesenheits-soll-form>
        </entity-form>
    </dvb-limited-entity-assignment-controls>

    <div class="overlay-spinner" ng-show="!!vm.isLoading">
        <dvlib-spinner></dvlib-spinner>
    </div>
</div>
