/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';
import {DvbDateUtil} from '@dv/shared/code';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {WeeklyControllingFigures} from '../../../../report/models/WeeklyControllingFigures';

export abstract class ControllingReportComponent {

    public controllingReport: ControllingReport | null = null;
    public jahr: number | null = null;
    public firstOfWeek!: moment.Moment;

    // Timestamp der Daten vom Server (abhaengig vom aktuellen Jahr)
    private cacheKey: string | null = null;

    public $onChanges(): void {
        if (!this.controllingReport) {
            return;
        }

        this.updateWeeklyFigures(this.controllingReport);

        if (this.hasControllingReportChanged(this.controllingReport)) {
            this.setAverageControllingFigures(this.controllingReport);
        }
    }

    protected updateWeeklyFigures(report: ControllingReport): void {
        if (!this.controllingReport) {
            this.resetWeeklyFigures();

            return;
        }

        const stichtag = this.firstOfWeek.year() === this.jahr ?
            this.firstOfWeek :
            DvbDateUtil.startOfYear(moment(this.firstOfWeek)).add(1, 'year');
        const currentWeeklyFigures = DvbDateUtil.getEntityOn(report.weeklyFigures, stichtag);

        if (!currentWeeklyFigures) {
            this.resetWeeklyFigures();

            return;
        }

        this.setWeeklyFigures(currentWeeklyFigures);
    }

    private hasControllingReportChanged(controllingReport: ControllingReport): boolean {
        const cacheKeyServer = `${this.jahr}/${controllingReport.jahr}/${controllingReport.timestamp.unix()}`;

        if (this.cacheKey === cacheKeyServer) {
            return false;
        }
        this.cacheKey = cacheKeyServer;

        return true;
    }

    protected abstract setAverageControllingFigures(controllingReport: ControllingReport): void;

    protected abstract setWeeklyFigures(weeklyFigures: WeeklyControllingFigures): void;

    protected abstract resetWeeklyFigures(): void;
}
