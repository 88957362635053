/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameter, TarifParameterHistoryEntries, TarifParameterHistoryEntry} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kindParameter: '<',
        tarifParameter: '<',
        onUpdate: '&',
        onDelete: '&',
        onRevert: '&',
    },
    template: require('./dvb-kind-tarife-parameter.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeParameter implements angular.IController {
    public static $inject: readonly string[] = [];

    public kindParameter!: TarifParameterHistoryEntries;
    public tarifParameter!: TarifParameter;
    public onUpdate!: FunctionType;
    public onDelete!: FunctionType;
    public onRevert!: FunctionType;

    public today: moment.Moment = DvbDateUtil.today();

    public isEntryBeendet(entry: TarifParameterHistoryEntry): boolean {
        return !DvbDateUtil.isEndOfTime(entry.gueltigBis);
    }
}

componentConfig.controller = DvbKindTarifeParameter;
angular.module('kitAdmin').component('dvbKindTarifeParameter', componentConfig);
