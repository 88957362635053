<div class="row kindprofil dvb-form">
    <div class="col-md-7 big-abstand-bottom">
        <div class="form-section-minus-normal-abstand">
            <h2 class="hidden-print" data-translate="KIND.KIND_STAMMDATEN"></h2>

            <div class="row hidden-print">
                <div class="col-md-6">
                    <dvb-editable-label dvb-auth
                                        is-valid="vm.isValidName(param)"
                                        maxlength="255"
                                        ng-change="vm.saveBasisData()"
                                        ng-model="vm.kind.familienName"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label dvb-auth
                                        maxlength="255"
                                        ng-change="vm.saveBasisData()"
                                        ng-model="vm.kind.vorName"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" ng-if="!vm.kind.geburtsTag">
                    <dvb-datepicker-text-field
                        click-to-edit="true"
                        dvb-auth
                        is-valid="vm.isValidGeburtsTermin(param)"
                        ng-model="vm.kind.geburtsTermin"
                        on-submit="vm.saveBasisData()"
                        on-unauthorised-disable
                        ng-attr-placeholder="{{'KIND.GEBURTSTERMIN' | translate}}"
                        require-permission="kita:manage:general">
                    </dvb-datepicker-text-field>
                </div>
                <div class="col-md-6">
                    <dvb-datepicker-text-field
                        click-to-edit="true"
                        dvb-auth
                        is-valid="vm.isValidGeburtsTag(param)"
                        ng-model="vm.kind.geburtsTag"
                        on-submit="vm.saveBasisData()"
                        on-unauthorised-disable
                        ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}"
                        require-permission="kita:manage:general">
                    </dvb-datepicker-text-field>
                </div>
            </div>
            <div class="row small-abstand-bottom">
                <div class="col-md-6">
                    <dvb-datepicker-text-field click-to-edit="true"
                                               dvb-auth
                                               is-valid="vm.isValidAnmeldeDatum(param)"
                                               ng-model="vm.kind.anmeldeDatum"
                                               on-submit="vm.saveBasisData()"
                                               on-unauthorised-disable
                                               ng-attr-placeholder="{{'KIND.ANMELDEDATUM' | translate}}"
                                               require-permission="kita:manage:general">
                    </dvb-datepicker-text-field>
                </div>
            </div>
        </div>
        <div class="form-section-minus-normal-abstand">
            <div class="row form-row">
                <div class="col-md-4">
                    <div class="radio"
                         ng-repeat="geschlecht in vm.geschlecht">
                        <label>
                            <input dvb-auth
                                   name="geschlecht"
                                   ng-change="vm.saveBasisData()"
                                   ng-model="vm.kind.geschlecht"
                                   on-unauthorised-disable
                                   require-permission="kita:manage:general"
                                   type="radio"
                                   value="{{geschlecht}}">
                            <span data-translate="{{'COMMON.' + geschlecht + '_KIND'}}"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-section-minus-normal-abstand">
            <div class="row fake-click2edit">
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kind.nationalitaet}">
                    <dvb-iso-locale dvb-auth
                                    dvb-click2edit-iso-locale
                                    ng-model="vm.kind.nationalitaet"
                                    on-submit="vm.kind.nationalitaet = isoObject; vm.saveBasisData()"
                                    on-unauthorised-disable
                                    ng-attr-placeholder="{{'COMMON.NATIONALITAET' | translate}}"
                                    require-permission="kita:manage:general"
                                    type="COUNTRY">
                    </dvb-iso-locale>
                </div>
                <div class="col-md-6"
                     ng-class="{'hidden-print': !vm.kind.muttersprache}">
                    <dvb-iso-locale dvb-auth
                                    dvb-click2edit-iso-locale
                                    ng-model="vm.kind.muttersprache"
                                    on-submit="vm.kind.muttersprache = isoObject; vm.saveBasisData()"
                                    on-unauthorised-disable
                                    ng-attr-placeholder="{{'KIND.MUTTERSPRACHE' | translate}}"
                                    require-permission="kita:manage:general"
                                    type="LANGUAGE">
                    </dvb-iso-locale>
                </div>
            </div>
            <div class="row"
                 ng-class="{'hidden-print': !vm.kind.sozialversicherungsNummer}">
                <div class="col-md-6">
                    <dvb-editable-label dvb-auth
                                        maxlength="100"
                                        ng-change="vm.saveBasisData()"
                                        ng-model="vm.kind.sozialversicherungsNummer"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'KIND.SOZIALVERSICHERUNGSNUMMER' | translate}}"
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-xs-12">
                    <div class="checkbox">
                        <label>
                            <input dvb-auth
                                   type="checkbox"
                                   ng-model="vm.kind.platzbestaetigungDeactivated"
                                   ng-change="vm.saveBasisData()"
                                   on-unauthorised-disable
                                   require-permission="kita:manage:general">
                            <span data-translate="KIND.PLATZBESTAETIGUNG_DEAKTIVIEREN"></span>
                        </label>
                    </div>
                </div>
            </div>
            <dvb-custom-field-values custom-field-values="vm.kind.customFieldValues"
                                     dvb-auth
                                     require-permission="kita:manage:general"
                                     on-unauthorised-disable>
            </dvb-custom-field-values>
        </div>
        <div class="form-section-minus-normal-abstand"
             ng-class="{'hidden-print': !vm.kind.bemerkung}"
             dvb-auth require-permission="kita:control:general">
            <h2 data-translate="COMMON.BEMERKUNG"></h2>
            <dvb-editable-text-area dvb-auth
                                    maxlength="-1"
                                    ng-model="vm.kind.bemerkung"
                                    on-submit="vm.saveBasisData()"
                                    on-unauthorised-disable
                                    ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                    require-permission="kita:manage:general">
            </dvb-editable-text-area>
        </div>
        <div class="hidden-print">
            <a data-translate="COMMON.LOESCHEN"
               dvb-auth
               href=""
               ng-click="vm.confirmDeleteKind(vm.kind)" require-permission="kita:manage:general"></a>
        </div>
    </div>
    <div class="col-md-4 col-md-offset-1"
         ng-class="{'hidden-print': vm.kind.geschwister.length === 0}">

        <div class="form-section">
            <h2 data-translate="KIND.GESCHWISTER"></h2>

            <p data-translate="KIND.KEINE_GESCHWISTER" ng-if="vm.kind.geschwister.length === 0"></p>

            <div class="form-row">
                <div class="row selected-entity" ng-repeat="geschwister in vm.kind.geschwister | orderBy:'-geburtsTag'">
                    <div class="col-xs-9">
                        <a class="entity"
                           ng-bind="geschwister.getDisplayName()"
                           ui-sref="base.kind.profil({kindId: geschwister.id})">
                        </a>,
                        <span ng-bind="geschwister.getDisplayGeburtsdatum()"></span>
                    </div>
                    <div class="col-md-3 edit hidden-print" dvb-auth require-permission="kita:manage:general">
                        <a data-translate="COMMON.LOESCHEN" href="" ng-click="vm.removeGeschwister(geschwister)"></a>
                    </div>
                </div>
            </div>
            <div class="hidden-print" dvb-auth require-permission="kita:manage:general">
                <p>
                    <a ui-sref="base.anmeldung({geschwisterId: vm.kind.id})">
                        + <span data-translate="KIND.GESCHWISTER_ANMELDEN"></span>
                    </a>
                </p>
                <p>
                    <dvb-search-on-click-entity add-label-key="KIND.GESCHWISTER_HINZUFUEGEN"
                                                disabled-entries="vm.kind.geschwister"
                                                entity-to-search="KIND"
                                                expand-entity="true"
                                                filter-source="vm.filterGeschwister($source)"
                                                on-select="vm.addGeschwister(item)"
                                                on-select-clear="true"
                                                placeholder-key="KIND.GESCHWISTER_AUSWAEHLEN">
                    </dvb-search-on-click-entity>
                </p>
            </div>
        </div>

        <div class="form-section hidden-print" dvb-auth require-permission="kita:view:general">
            <h2 data-translate="KIND.ANHAENGE"></h2>

            <dvb-draggable-dropzone
                icon="fa-unlock"
                label-tooltip="KIND.ANHAENGE_ALLGEMEIN"
                on-drag-success="vm.updateAnhang(id, vm.confLevelAllgemein)"
                dvb-auth
                on-unauthorised-disable
                require-permission="kita:manage:general">
                <dvb-file-list allow-multiple="true"
                               files="vm.kind.anhaengeAllgemein"
                               label-file-upload="KIND.ANHANG_HOCHLADEN"
                               label-no-files="KIND.KEINE_ANHAENGE"
                               on-add-file="vm.addAnhang(file, vm.confLevelAllgemein)"
                               on-remove-file="vm.removeAnhang(file)"
                               on-upload-error="vm.handleUploadFailure(file)"
                               on-upload-success="vm.saveAnhang(file, vm.confLevelAllgemein)"
                               on-click="vm.downloadAnhang(file, vm.confLevelAllgemein)"
                               dvb-auth
                               on-unauthorised-disable
                               require-permission="kita:manage:general">
                </dvb-file-list>
            </dvb-draggable-dropzone>

            <div dvb-auth require-permission="kita:control:general">
                <dvb-draggable-dropzone
                    icon="fa-lock"
                    label-tooltip="KIND.ANHAENGE_VERTRAULICH"
                    on-drag-success="vm.updateAnhang(id, vm.confLevelVertraulich)"
                    dvb-auth
                    on-unauthorised-disable
                    require-permission="kita:manage:general">
                    <dvb-file-list allow-multiple="true"
                                   files="vm.kind.anhaengeVertraulich"
                                   label-file-upload="KIND.ANHANG_HOCHLADEN"
                                   label-no-files="KIND.KEINE_ANHAENGE"
                                   on-add-file="vm.addAnhang(file, vm.confLevelVertraulich)"
                                   on-remove-file="vm.removeAnhang(file)"
                                   on-upload-error="vm.handleUploadFailure(file)"
                                   on-upload-success="vm.saveAnhang(file, vm.confLevelVertraulich)"
                                   on-click="vm.downloadAnhang(file, vm.confLevelVertraulich)"
                                   dvb-auth
                                   on-unauthorised-disable
                                   require-permission="kita:manage:general">
                    </dvb-file-list>
                </dvb-draggable-dropzone>
            </div>
        </div>
    </div>
</div>
