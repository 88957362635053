/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaBetreuungsfaktorRegel} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaBetreuungsfaktorRegel: '<',
    },
    template: require('./dvb-kita-betreuungsfaktor-regel-heading.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsfaktorRegelHeading {
    public static $inject: readonly string[] = [];

    public kitaBetreuungsfaktorRegel!: KitaBetreuungsfaktorRegel;
}

componentConfig.controller = DvbKitaBetreuungsfaktorRegelHeading;
angular.module('kitAdmin').component('dvbKitaBetreuungsfaktorRegelHeading', componentConfig);
