<div class="search-input">
    <script type="text/ng-template" id="globalSearchPreviewTemplate.html">
        <a>
            <dvb-search-result-icon entry="match.model"></dvb-search-result-icon>
            <span class="search-result-label" ng-bind-html="match.label"></span>
            <span class="badge"
                  title="{{match.model.additionalInformation}}"
                  ng-bind="match.model.additionalInformation"
                  ng-if="match.model.additionalInformation">
            </span>
        </a>
    </script>

    <form name="searchInputForm" class="navbar-form" role="search" novalidate ng-submit="vm.goToGlobalSearch()">
        <div class="input-group">
            <input type="text"
                   class="form-control"
                   autocomplete="off"
                   name="searchInput"
                   ng-model="vm.searchInput"
                   accesskey="f"
                   aria-label="{{'SEARCH.GLOBAL_SEARCH' | translate}}"
                   maxlength="150"
                   ng-keyup="vm.onKeyup($event)"
                   uib-typeahead="result as result.text for result in vm.searchResults($viewValue)"
                   typeahead-on-select="vm.onSelect($item, $model, $label, $event)"
                   typeahead-should-select="vm.shouldSelect($event)"
                   typeahead-template-url="globalSearchPreviewTemplate.html"
                   typeahead-focus-first="true"
                   typeahead-loading="loadingSearchResults"
                   typeahead-min-length="2"
                   typeahead-wait-ms="0"
                   dvb-select-on-click>
            <div class="dropdown input-group-btn" uib-dropdown data-keyboard-nav auto-close="outsideClick">
                <button name="entity-filter"
                        class="btn btn-link header-box-attachment dropdown-toggle"
                        uib-dropdown-toggle>
                    <i ng-if="vm.searchEntityCount <= 0" class="fa fa-sliders"></i>
                    <dvb-pensum-icon class="small"
                                     value="vm.searchEntityCount"
                                     ng-if="vm.searchEntityCount > 0">
                    </dvb-pensum-icon>
                </button>
                <ul class="dropdown-menu search-entities" uib-dropdown-menu>
                    <li ui-sref-active-eq="active" class="floating-text" ng-repeat="entity in vm.searchEntities">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.checkedEntities[entity]"
                                       ng-change="vm.updateEntities()">
                                <span data-translate="{{'COMMON.' + entity + '.PLURAL'}}"></span>
                            </label>
                        </div>
                    </li>
                    <li ng-if="vm.searchEntityCount > 0">
                        <button type="button"
                                class="btn btn-link small-abstand-top"
                                ng-click="vm.resetSearchEntities()"
                                data-translate="COMMON.RESET_FILTER">
                        </button>
                    </li>
                </ul>
            </div>
            <span class="input-group-btn">
                <button class="btn btn-primary"
                        type="submit"
                        aria-label="{{'SEARCH.TOGGLE_SEARCH_MODE' | translate}}"
                        ng-click="vm.toggleSearch($event)">
                    <i class="fa"
                       ng-class="{'fa-search': !vm.searchInAllMandanten, 'fa-eye': vm.searchInAllMandanten}">
                    </i>
                </button>
            </span>
        </div>
    </form>
</div>
