<div class="dvb-kinderort-eroeffnen row dvb-form">
    <div class="col-md-6 col-md-offset-3">

        <h1 data-translate="COMMON.KINDERORT.ERSTELLEN"></h1>

        <form name="vm.neuerKinderortForm"
              ng-submit="!vm.neuerKinderortForm.$pending && vm.validateAndSaveKita(vm.neuerKinderortForm.$valid)"
              novalidate>

            <div class="form-section-minus-normal-abstand"
                 dvb-auth
                 require-permission="module:tageseltern">

                <h2 data-translate="KINDERORT.STRUKTUR_TITLE"></h2>

                <div class="row form-row">
                    <div class="col-md-12">
                        <div class="radio" ng-repeat="type in ['KITA', 'TAGES_ELTERN']">
                            <label>
                                <input type="radio"
                                       name="dtype"
                                       value="{{type}}"
                                       ng-model="vm.kinderOrt.dtype"
                                       ng-change="vm.updateDType()">
                                <span data-translate="{{'KINDERORT.STRUKTUR.' + type}}"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-section-minus-normal-abstand">

                <h2 data-translate="KINDERORT.STAMMDATEN"></h2>

                <div class="row">
                    <div class="col-md-6">
                        <input type="text"
                               name="kitaTitel"
                               class="form-control"
                               maxlength="100"
                               ng-attr-placeholder="{{'COMMON.TITEL' | translate }}"
                               uib-tooltip="{{'COMMON.TITEL' | translate }}"
                               tooltip-placement="top-left"
                               ng-model="vm.kinderOrt.titel">
                    </div>
                    <div class="col-md-6">
                        <input type="text"
                               name="kitaName"
                               class="form-control"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               uib-tooltip="{{'COMMON.NAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.kinderOrt.name"
                               required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.bewilligtePlaetze.gueltigAb"
                                                   required
                                                   ng-attr-placeholder="{{'KINDERORT.EROEFFNUNGSDATUM' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                </div>
            </div>

            <div ng-if="vm.kinderOrt.dtype === 'KITA'">
                <dvb-kita-eroeffnen bewilligte-plaetze="vm.bewilligtePlaetze"
                                    subventionierte-plaetze="vm.subventioniertAnzahl">
                </dvb-kita-eroeffnen>
            </div>

            <div ng-if="vm.kinderOrt.dtype === 'TAGES_ELTERN'">
                <dvb-tageseltern-eroeffnen subventionierte-stunden="vm.subventioniertAnzahl">
                </dvb-tageseltern-eroeffnen>
            </div>

            <div class="form-section-minus-normal-abstand">

                <h2 data-translate="COMMON.KONTAKT"></h2>

                <dvb-adresse-form ng-model="vm.kinderOrt.adresse"></dvb-adresse-form>

                <div class="row">
                    <div class="col-md-12">
                        <input type="text"
                               class="form-control"
                               name="kitaTelefon"
                               maxlength="255"
                               ng-model="vm.kinderOrt.telefon"
                               ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                               uib-tooltip="{{'COMMON.TELEFON' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <input type="email"
                               class="form-control"
                               name="kitaEmail"
                               ng-model="vm.kinderOrt.email"
                               ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                               uib-tooltip="{{'COMMON.E-MAIL' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <input type="url"
                               class="form-control"
                               name="kitaWebsite"
                               ng-model="vm.kinderOrt.website"
                               dvb-protocol-prepender
                               ng-attr-placeholder="{{'COMMON.WEBSITE' | translate}}"
                               uib-tooltip="{{'COMMON.WEBSITE' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>
            </div>

            <div class="form-section-minus-normal-abstand" ng-if="vm.hasTarife">

                <h2 data-translate="COMMON.TARIF.PLURAL"></h2>

                <div class="row form-row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" ng-model="vm.showTarifeAuswahl">
                                <span data-translate="KINDERORT.TARIFE_AUSWAEHLEN"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-row" uib-collapse="!vm.showTarifeAuswahl">
                    <dvb-kita-tarife-auswahl abrechnung-tarife="vm.tarifeZeitraum.abrechnungTarife">
                    </dvb-kita-tarife-auswahl>
                </div>
            </div>

            <div class="form-section-minus-normal-abstand" ng-if="vm.hasBetreuungsfaktorRegeln">

                <h2 data-translate="COMMON.BETREUUNGSFAKTOR_REGEL.SINGULAR"></h2>

                <div class="row form-row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" ng-model="vm.showKitaBetreuungsfaktorRegelAuswahl">
                                <span data-translate="KINDERORT.BETREUUNGSFAKTOR_REGEL_AUSWAEHLEN"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row form-row" uib-collapse="!vm.showKitaBetreuungsfaktorRegelAuswahl">
                    <div class="col-md-12">
                        <dvb-kita-betreuungsfaktor-regel-auswahl
                            kita-betreuungsfaktor-regel="vm.kinderOrtBetreuungsfaktorRegel">
                        </dvb-kita-betreuungsfaktor-regel-auswahl>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button name="save"
                                                type="submit"
                                                is-loading="vm.neuerKinderortForm.$pending || vm.isLoading">
                                <span data-translate="COMMON.KINDERORT.ERSTELLEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
