/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {AuthEventType} from '@dv/shared/authentication/model';
import type {StatePermission} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';
import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import type {AuthEventService} from '../../../authentication/service/auth-event.service';
import type {AuthService} from '../../../authentication/service/authService';
import {DASHBOARD_STATE, EMPTY_DASHBOARD_STATE} from '../../../dashboard/dashboard-state';
import {IS_REQUIRING_LOGIN} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

export function authorisationHook($transitions: TransitionService): void {
    $transitions.onBefore(IS_REQUIRING_LOGIN, checkAuthorisation, {priority: OnBeforePriorities.AUTHORISATION});
}

function checkAuthorisation(transition: Transition): HookResult {
    if (!transition.to().data) {
        // no checks needed
        return undefined;
    }

    const authStore = transition.injector().get<AuthStore>('authStore');

    if (isAuthorised(authStore, transition)) {
        return undefined;
    }

    if (transition.$to().name === DASHBOARD_STATE.name) {
        return transition.router.stateService.target(EMPTY_DASHBOARD_STATE.name);
    }

    // when unauthorised and not yet on a state, navigate to the login state
    if (transition.$from() === transition.router.stateRegistry.root()) {
        const authService = transition.injector().get<AuthService>('authService');

        return Promise.resolve(authService.logoutAndRedirectToLoginPage());
    }

    console.error('Unauthorised route state', transition.to());
    transition.injector().get<AuthEventService>('authEventService').sendEvent({type: AuthEventType.notAuthorised});

    return false;
}

function isAuthorised(authStore: AuthStore, transition: Transition): boolean {
    const data = transition.to().data;

    return hasRequiredRole(data.role) && hasRequiredPermission(data.permission, transition.params());

    function hasRequiredRole(role?: UserRole): boolean {
        return !role || authStore.hasRole(role);
    }

    function hasRequiredPermission<T>(permission: StatePermission<T>, toParams: T): boolean {
        if (!permission) {
            return true;
        }

        const extractedPermission = TypeUtil.isFunction(permission) ? permission(toParams) : permission;

        return authStore.isPermitted(extractedPermission);
    }
}
