<div class="dvb-termin-header row">
    <div class="col-lg-5 col-md-7 col-sm-8">
        <p>
            <i
               class="fa fa-calendar smaller-abstand-right"
               uib-tooltip="{{'PERSONAL.TERMIN.FOR_ANGESTELLTE' | translate}}"
               tooltip-placement="top-left">
            </i>
            <dvb-individual-gueltigkeit entity="vm.termin"></dvb-individual-gueltigkeit>
            <span ng-if="vm.termin.von || vm.termin.bis" class="small-abstand-left">
                <span ng-bind="vm.termin.von | amDateFormat: 'HH:mm'"></span>
                -
                <span ng-bind="vm.termin.bis | amDateFormat: 'HH:mm'"></span>
            </span>
            <i ng-if="vm.termin.wiederkehrend"
               class="fa fa-repeat small-abstand-left"
               uib-tooltip="{{'PERSONAL.TERMIN.WIEDERKEHRENDE' | translate}}"
               tooltip-placement="top-left"></i>
        </p>
    </div>
    <div class="col-md-3 col-sm-8">
        <p class="truncate" ng-bind="vm.termin.title || vm.termin.bemerkung"></p>
    </div>
    <div class="col-md-4 col-sm-8" ng-if="vm.kinderOrt">
        <div ng-if="vm.termin.alleAngestellte"
             data-translate="PERSONAL.TERMIN.ALLE_ANGESTELLTEN_AUSWAEHLEN"></div>
        <div ng-if="!vm.termin.alleAngestellte"
             ng-repeat="angestellteId in vm.termin.angestellteIds">
            <p class="truncate" ng-bind="vm.getAngestellteDisplayName(angestellteId)"></p>
        </div>
    </div>
</div>

