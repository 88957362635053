<div class="dvb-voreinstellungen-custom-fields row">
    <div class="col-md-7" dvb-accordions>
        <h2 data-translate="COMMON.CUSTOM_FIELD.PLURAL"></h2>

        <dvb-accordion is-first="$first" ng-repeat="namedEntityType in vm.namedEntityTypes">
            <panel-header class="full-width-block">
                <div class="row">
                    <div class="col-xs-6">
                        <p data-translate="{{'COMMON.' + namedEntityType +'.SINGULAR'}}"></p>
                    </div>
                    <div class="col-xs-6 text-right">
                        <p data-translate="COMMON.CUSTOM_FIELD.MF"
                           data-translate-value-count="{{vm.customFields[namedEntityType].length || 0}}"
                           data-translate-value-showcount="true"
                           data-translate-interpolation="messageformat">
                        </p>
                    </div>
                </div>
            </panel-header>
            <panel-content>
                <form name="customFieldsForm"
                      novalidate
                      ng-submit="vm.onSubmit(customFieldsForm.$valid, namedEntityType)">
                    <h3 data-translate="{{'COMMON.' + namedEntityType +'.SINGULAR'}}" class="small-abstand-bottom"></h3>
                    <div class="form-row row" ng-repeat="field in vm.customFields[namedEntityType]">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-10">
                                    <dvb-voreinstellungen-custom-field-form custom-field="field" show-mandatory="true">
                                    </dvb-voreinstellungen-custom-field-form>
                                </div>
                                <div class="col-md-2 edit form-linker">
                                    <button class="btn btn-link"
                                            type="button"
                                            ng-click="vm.onDelete(field)"
                                            data-translate="COMMON.LOESCHEN">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row normal-abstand-top big-abstand-bottom">
                        <div class="col-md-6">
                            <button class="btn btn-link"
                                    type="button"
                                    ng-click="vm.onAddField(namedEntityType)">
                                + <span data-translate="CUSTOM_FIELD.HINZUFUEGEN"></span>
                            </button>
                        </div>
                    </div>
                    <dv-custom-field-sort-item [state]="vm.sortState"
                                               (closed)="vm.showSortDialog$.next(false)">
                    </dv-custom-field-sort-item>
                    <ul class="list-inline normal-abstand-top">
                        <li>
                            <dvb-loading-button type="submit" is-loading="vm.isLoading">
                                <span data-translate="{{vm.submitLabel || 'COMMON.SPEICHERN'}}"></span>
                            </dvb-loading-button>
                        </li>
                        <li>
                            <button type="button"
                                    class="btn btn-link"
                                    ng-click="vm.sortFields(namedEntityType, customFieldsForm.$valid)"
                                    data-translate="CUSTOM_FIELD.SORTIEREN"
                                    ng-disabled="vm.isLoading || (vm.customFields[namedEntityType] || []).length < 2">
                            </button>
                        </li>
                    </ul>

                </form>
            </panel-content>
        </dvb-accordion>

        <dvb-voreinstellungen-anwesenheit-custom-fields></dvb-voreinstellungen-anwesenheit-custom-fields>

        <dvb-voreinstellungen-check-in-custom-fields dvb-auth
                                                     require-permission="module:group_admin">
        </dvb-voreinstellungen-check-in-custom-fields>

        <dvb-voreinstellungen-external-anmeldung-custom-fields></dvb-voreinstellungen-external-anmeldung-custom-fields>

    </div>
</div>
