<div class="dvb-communication-sent">

    <div class="panel panel-list-container">
        <div class="panel-heading"
             data-translate="COMMUNICATION.SENT.MF"
             data-translate-value-count="{{vm.kontaktpersonLastSentList.length}}"
             data-translate-value-showcount="true"
             data-translate-interpolation="messageformat"></div>
        <div class="panel-subtitle">
            <div class="row">
                <div class="col-xs-4" data-translate="COMMON.KONTAKT"></div>
                <div class="col-xs-4" data-translate="COMMON.VERSANDART_EMAIL"></div>
                <div class="col-xs-4" data-translate="COMMON.LAST_SENT_AT"></div>
            </div>
        </div>
        <div class="panel-list scrollable with-header">
            <div class="list-item"
                 ng-repeat="element in vm.kontaktpersonLastSentList | orderBy:vm.comparator">
                <div class="row">
                    <div class="col-xs-4">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.selected[element.kontaktperson.id]"
                                       ng-value="{{element.kontaktperson}}"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <a dvb-auth
                                   require-permission="kita:control:general"
                                   ui-sref="base.kontaktperson.profil({kontaktpersonId: element.kontaktperson.id})">
                                    <span ng-bind="element.kontaktperson.getDisplayName()"></span>
                                </a>
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-4">
                        <span ng-bind="element.kontaktperson.email"></span>
                    </div>
                    <div class="col-xs-4">
                        <span ng-bind="element.emailSent | amDateFormat:'D.M.YYYY HH:mm:ss'"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <ul dvb-auth require-permission="kita:manage:general"
            class="list-inline small-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectAll(vm.kontaktpersonen)"
                        data-translate="COMMON.ALLE_AUSWAEHLEN">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectNone()"
                        data-translate="COMMON.KEINE_AUSWAEHLEN">
                </button>
            </li>
        </ul>
        <ul class="list-inline normal-abstand-top">
            <li dvb-auth require-permission="kita:manage:general">
                <dvb-loading-button type="submit"
                                    is-loading="vm.loading"
                                    ng-disabled="vm.getSelectionCount() <= 0"
                                    on-click="vm.resend()">
                    <span data-translate="COMMUNICATION.RESEND_EMAILS.MF"
                          data-translate-value-count="{{vm.getSelectionCount()}}"
                          data-translate-value-showcount="true"
                          data-translate-interpolation="messageformat"></span>
                </dvb-loading-button>
            </li>
        </ul>
    </div>

</div>
