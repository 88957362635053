/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ZeitraumFeldIcon} from '@dv/shared/code';
import angular from 'angular';
import type {TempExtraPlatz} from '../../../temp-extra-platz/TempExtraPlatz';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        platz: '<',
    },
    template: require('./dvb-temp-extra-platz-header.html'),
    controllerAs: 'vm',
};

export class DvbTempExtraPlatzHeader implements angular.IController {
    public static $inject: readonly string[] = [];

    public platz!: TempExtraPlatz;

    public typeIcon: string = '';

    public $onChanges(): void {
        if (this.platz.isNewPlatz() || this.platz.isModified()) {
            this.typeIcon = '';

            return;
        }

        this.typeIcon = ZeitraumFeldIcon.BELEGT;
    }
}

componentConfig.controller = DvbTempExtraPlatzHeader;
angular.module('kitAdmin').component('dvbTempExtraPlatzHeader', componentConfig);
