<div class="dvb-voreinstellungen-main">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{'VOREINSTELLUNGEN.VOREINSTELLUNGEN' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="VOREINSTELLUNGEN.VOREINSTELLUNGEN"></h1>

                <div class="header-dropdown" dvb-auth require-permission="kita:betreuung_admin:*" uib-dropdown>
                    <a href="" class="header-box-attachment dropdown-toggle" uib-dropdown-toggle>
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="{{vm.voreinstellungenStates.VOREINSTELLUNGEN_EXTERNAL_ANMELDUNG_STATE.name}}"
                               data-translate="EXTERNAL_ANMELDUNG.CONFIG.TITLE">
                            </a>
                        </li>
                    </ul>
                </div>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.voreinstellungen.tarife({currentPage: 1})"
                           data-translate="COMMON.TARIF.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.voreinstellungen.dokumentlayouts({currentPage: 1})"
                           data-translate="COMMON.DOKUMENT_LAYOUT.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.voreinstellungen.betreuungsfaktorregeln({currentPage: 1})"
                           data-translate="COMMON.BETREUUNGSFAKTOR_REGEL.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="module:anwesenheit">
                        <a ui-sref="base.voreinstellungen.schliesstage"
                           data-translate="COMMON.SCHLIESSTAGE.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-role="SUPPORT_ADMIN">
                        <a ui-sref="base.voreinstellungen.felder"
                           data-translate="COMMON.CUSTOM_FIELD.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-role="SUPPORT_ADMIN">
                        <a ui-sref="base.voreinstellungen.wochenplaene"
                           data-translate="COMMON.WOCHENPLAN.PLURAL">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
