<div class="dvb-kibon-tagesschule-anmeldungen">
    <dvb-kibon-entity-matching-overview translation-keys="vm.translations"
                                        action-handler="vm.actionHandler"
                                        actions="vm.actions"
                                        filter="vm.filter"
                                        states="vm.states">
        <right-column>
            <dvb-kibon-maintenance dvb-auth
                                   require-role="MANDANT_ADMIN"
                                   on-query-api="vm.queryApi(params, formContext)">
            </dvb-kibon-maintenance>
        </right-column>
    </dvb-kibon-entity-matching-overview>
</div>
