<div class="dvb-kibon-verfuegungen">
    <dvb-kibon-entity-matching-overview translation-keys="vm.translations"
                                        action-handler="vm.actionHandler"
                                        actions="vm.actions"
                                        filter="vm.filter"
                                        states="vm.states"
                                        page-controller="vm.pageController = pageController">
        <right-column>
            <h2 data-translate="COMMON.AKTIONEN" class="big-abstand-top"></h2>
            <p>
                <button class="btn btn-link"
                        ng-click="vm.bulkIgnore()"
                        data-translate="KIBON.VERFUEGUNGEN.BULK_IGNORE">
                </button>
            </p>
            <p>
                <button class="btn btn-link"
                        ng-click="vm.bulkUnIgnore()"
                        data-translate="KIBON.VERFUEGUNGEN.BULK_UN_IGNORE">
                </button>
            </p>

            <div class="big-abstand-top">
                <dvb-kibon-maintenance dvb-auth
                                       require-role="MANDANT_ADMIN"
                                       on-query-api="vm.queryApi(params, formContext)">
                </dvb-kibon-maintenance>
            </div>
        </right-column>
    </dvb-kibon-entity-matching-overview>
</div>
