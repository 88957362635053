/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BewilligtePlaetze} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        bewilligtePlaetze: '<',
        subventioniertePlaetze: '<',
    },
    template: require('./dvb-kita-eroeffnen.html'),
    controllerAs: 'vm',
};

export class DvbKitaEroeffnen implements angular.IController {
    public static $inject: readonly string[] = [];

    public bewilligtePlaetze!: BewilligtePlaetze;
    public subventioniertePlaetze!: { anzahl: number | null };

    public showSubventioniertePlaetze: boolean = false;

    public updateSubventioniertePlaetze(): void {
        if (!this.showSubventioniertePlaetze) {
            this.subventioniertePlaetze.anzahl = null;
        }
    }
}

componentConfig.controller = DvbKitaEroeffnen;
angular.module('kitAdmin').component('dvbKitaEroeffnen', componentConfig);
