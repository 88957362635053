<div class="dvb-filter-list">
    <ul class="filter-list">
        <li ng-repeat="filter in vm.filter | filter: (vm.optionFilter == 'all' ? '' : (vm.optionFilter == 'sticky' ? {stickyOptions: true}: {nonStickyOptions: true}))">
            <div data-translate="{{filter.translationKey}}" class="filter-label"></div>
            <ul class="filter-option-list">
                <li ng-repeat="option in filter.options | filter: (vm.optionFilter == 'all' ? '' : {sticky: vm.optionFilter == 'sticky'})"
                    ng-switch="filter.type"
                    ng-class="{'hidden-print': !option.selected}">

                    <div ng-switch-when="DEFAULT">
                        <dvb-filter-option label="option.name"
                                           closeable="vm.optionFilter == 'sticky'"
                                           on-close="vm.closeOption(option, filter)"
                                           is-selected="option.selected"
                                           on-toggle="vm.onFilterChange(filter, option, isSelected)">
                        </dvb-filter-option>
                    </div>

                    <div ng-switch-when="POPOVER">
                        <dvb-filter-option-popover filter-model="option"
                                                   closeable="vm.optionFilter == 'sticky'"
                                                   on-close="vm.closeOption(option, filter)"
                                                   on-toggle="vm.onFilterChange(filter, option, isSelected)"
                                                   on-close-popover="vm.onFilterChange(filter, option, option.selected)">
                        </dvb-filter-option-popover>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>
