/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Termin} from '@dv/kitadmin/models';
import {checkPresent, ColorUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {CalendarEvent} from '../../../../../calendar/timeline/model/CalendarEvent';
import {calendarEventIdGenerator} from '../../../../../calendar/timeline/model/CalendarEvent';
import {LayerType} from '../LayerType';
import type {EventGueltigkeitService} from './event-gueltigkeit.service';

export function terminToCalendarEvent(
    termin: Termin,
    eventGueltigkeitService: EventGueltigkeitService,
    selectedDate?: moment.Moment,
): CalendarEvent {
    const terminTitle = termin.title ? termin.title : termin.terminType?.name;
    const displayName: string = terminTitle ?? termin.bemerkung ?? '';
    const eventRange = eventGueltigkeitService.toEventRange(termin, selectedDate);

    return {
        id: calendarEventIdGenerator(),
        data: {termin},
        ...eventRange.gueltigkeit,
        tooltip: `${eventRange.gueltigkeitTxt}\n${displayName}`,
        completeDuration: 0,
        spaceBefore: 0,
        spaceAfter: 0,
        backgroundColor: checkPresent(termin.backgroundColor),
        textColor: checkPresent(termin.textColor),
        hasHighLuminance: ColorUtil.hasHighLuminance(checkPresent(termin.backgroundColor)),
        getDisplayName: () => displayName,
        layer: LayerType.TERMIN,
        subEvents: [],
    };
}
