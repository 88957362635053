<div class="dvb-rechnungs-konfigurationen" dvb-accordions>
    <h2 data-translate="COMMON.RECHNUNGS_KONFIGURATIONEN"></h2>

    <p ng-if="vm.konfigurationen.length === 0"
       class="normal-abstand-bottom"
       data-translate="MANDANT.KEINE_RECHNUNGS_KONFIGURATIONEN">
    </p>

    <div ng-repeat="konfiguration in vm.konfigurationen | orderBy: 'name'"
         ng-init="workingCopy = (konfiguration | copy)">
        <dvb-accordion enable-edit="true"
                       enable-delete="true"
                       entity-name="'MANDANT.RECHNUNGS_KONFIGURATION' | translate"
                       on-submit="vm.onUpdate(workingCopy, konfiguration, context, form)"
                       on-cancel="vm.onCancelUpdate(workingCopy, konfiguration)"
                       on-delete="vm.onDelete(konfiguration)"
                       is-first="$first"
                       dvb-auth
                       require-permission="kita:betreuung_admin:*"
                       on-unauthorised-disable>
            <panel-header class="full-width-block">
                <div class="row">
                    <div class="col-xs-6">
                        <p>
                            <span ng-bind="konfiguration.name"></span>
                            <span data-translate="MANDANT.DISABLED"
                                  ng-if="konfiguration.status === vm.statusTypen.DISABLED"
                                  class="disabled-label">
                            </span>
                            <span data-translate="MANDANT.READ_ONLY"
                                  ng-if="konfiguration.status === vm.statusTypen.READ_ONLY"
                                  class="disabled-label">
                            </span>
                        </p>
                    </div>
                    <div class="col-xs-6 text-right">
                        <p data-translate="COMMON.{{konfiguration.dtype}}"></p>
                    </div>
                </div>
            </panel-header>
            <edit-content>
                <dvb-rechnungs-konfiguration-form konten="vm.konten" konfiguration="workingCopy">
                </dvb-rechnungs-konfiguration-form>
            </edit-content>
        </dvb-accordion>
    </div>

    <div class="big-abstand-top" dvb-auth require-permission="kita:betreuung_admin:*">
        <div class="btn-group" uib-dropdown ng-if="!vm.showNewForm" data-keyboard-nav>
            <button type="button" class="btn btn-primary dropdown-toggle" uib-dropdown-toggle>
                <span data-translate="COMMON.NEUE_KONFIGURATION"></span>
                <span class="caret"></span>
            </button>
            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                <li ng-click="vm.neueKonfiguration(konfiguration)" ng-repeat="konfiguration in vm.konfigurationenTypen">
                    <a href=""
                       data-translate="COMMON.NEUE_KONFIGURATION_NAME"
                       data-translate-value-name="{{'COMMON.' + konfiguration | translate}}"></a>
                </li>
            </ul>
        </div>

        <div ng-if="vm.showNewForm">
            <h2 data-translate="COMMON.NEUE_KONFIGURATION_NAME"
                data-translate-value-name="{{'COMMON.' + vm.konfiguration.dtype | translate}}"></h2>

            <form ng-submit="vm.onCreate(newKonfigurationForm)" name="newKonfigurationForm" novalidate>

                <dvb-rechnungs-konfiguration-form konten="vm.konten"
                                                  konfiguration="vm.konfiguration"
                                                  is-new="vm.showNewForm">
                </dvb-rechnungs-konfiguration-form>

                <div class="normal-abstand-top">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit" is-loading="vm.isLoading">
                                <span data-translate="COMMON.SPEICHERN"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.showNewForm = false"></a></li>
                    </ul>
                </div>

            </form>
        </div>
    </div>
</div>
