/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Gruppe} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {BetreuungsAngebotType} from '@dv/shared/backend/model/betreuungs-angebot-type';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {FraktionService} from '../../../../../common/service/rest/kinderort/fraktionService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        gruppe: '<',
    },
    template: require('./dvb-gruppe-stammdaten.html'),
    controllerAs: 'vm',
};

export class DvbGruppeStammdaten implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['errorService', 'fraktionService', '$state', 'authStore'];

    public gruppe!: Gruppe;

    public administrateKinderOrt: boolean = false;
    public betreuungsAngebotTypes: BetreuungsAngebotType[] = [
        BetreuungsAngebotType.HORT,
        BetreuungsAngebotType.KRIPPE,
        BetreuungsAngebotType.HORT_AND_KRIPPE,
    ];

    public constructor(
        private errorService: ErrorService,
        private fraktionService: FraktionService,
        private $state: StateService,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.administrateKinderOrt = this.authStore.hasPermission(`kita:administrate:${this.gruppe.kita?.id}`);
    }

    public saveBasisdaten(): angular.IPromise<unknown> {
        if (!this.gruppe.isValid()) {
            throw new Error('tried to update invalid gruppe');
        }

        this.errorService.clearAll();

        return this.fraktionService.update(this.gruppe).catch(() => this.$state.reload());
    }

    public isValidName(param: string): boolean {
        const valid = !!param && param.length > 0;
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_GRUPPENNAME');

        return valid;
    }
}

componentConfig.controller = DvbGruppeStammdaten;
angular.module('kitAdmin').component('dvbGruppeStammdaten', componentConfig);
