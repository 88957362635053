/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatement, BankStatementEntryFilter} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {checkPresent, DvbUtil, LoadingContext} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        filter: '<',
        bankStatements: '<',
        onUpdate: '&',
        onAutoAssign: '&',
    },
    template: require('./dvb-transaktionen-header.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionenHeader implements angular.IController {

    public static $inject: readonly string[] = [];

    public filter!: BankStatementEntryFilter;
    public bankStatements: BankStatement[] = [];
    public onUpdate!: FunctionType;
    public onAutoAssign!: (data: { context: LoadingContext }) => unknown;

    public selectedBankStatements: BankStatement[] = [];
    public loadingContext: LoadingContext = new LoadingContext();

    public $onInit(): void {
        this.selectedBankStatements = angular.copy(this.bankStatements);
    }

    public updateBankStatement(selected: boolean, bankStatementId: string): void {
        if (selected && !this.filter.bankStatementIds.includes(bankStatementId)) {
            this.filter.bankStatementIds.push(bankStatementId);
        } else if (!selected && this.filter.bankStatementIds.includes(bankStatementId)) {
            this.filter.bankStatementIds.splice(this.filter.bankStatementIds.indexOf(bankStatementId), 1);
        }
        this.onUpdate();
    }

    public clearBankStatement(bankStatement: BankStatement): void {
        DvbUtil.removeFromArray(bankStatement, this.selectedBankStatements);
        this.updateBankStatement(false, checkPresent(bankStatement.id));
    }

    public autoAssign(): void {
        this.onAutoAssign({context: this.loadingContext});
    }
}

componentConfig.controller = DvbTransaktionenHeader;
angular.module('kitAdmin').component('dvbTransaktionenHeader', componentConfig);
