<div class="container"
     [ngClass]="{'first-container': isFirst, 'last-container': isLast, 'compact-panel': compact}">
    <button class="folding-icon btn btn-link"
            (click)="isCollapsed = !isCollapsed; $event.preventDefault();"
            [hidden]="isDisabled">
        <dvlib-icon [iconName]="isCollapsed ? 'zugeklappt' : 'aufgeklappt'" iconWidth="fixed"/>
    </button>
    <div class="header-and-content">
        @if (isCollapsed || isDisabled) {
            <ng-content select="[panel-header]"/>
        }
        @if (!isCollapsed && !isDisabled) {
            <ng-content select="[panel-content]"/>
        }
    </div>
</div>
