/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BlobInfo} from '@dv/kitadmin/models';
import type {AngestellteKorrespondenzType} from '@dv/shared/backend/model/angestellte-korrespondenz-type';
import type {EmailVersandStatus, ILimited, IPersisted} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {ITimelineEntry} from '../../common/timeline/ITimelineEntry';

export class AngestellteKorrespondenz implements ITimelineEntry, IPersisted, ILimited {

    public constructor(
        public id: string,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public timestampErstellt: moment.Moment,
        public timestampMutiert: moment.Moment,
        public userErstellt: string,
        public userMutiert: string,
        public type: AngestellteKorrespondenzType,
        public angestellteId: string,
        public subject: string,
        public blobInfos: BlobInfo[] = [],
        public content: string | null = null,
        public emailVersandStatus: EmailVersandStatus | null = null,
        public empfaengerAdresse: string | null = null,
        public emailContentPlainText: string | null = null,
        public emailContentHTML: string | null = null,
        public replyToAdresse: string | null = null,
        public replyToName: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AngestellteKorrespondenz {
        const blobInfos =
            data.blobInfos ? data.blobInfos.map((blobInfo: any) => BlobInfo.apiResponseTransformer(blobInfo)) : [];

        return new AngestellteKorrespondenz(
            data.id,
            DvbRestUtil.localDateTimeToMoment(data.gueltigAb),
            DvbRestUtil.localDateTimeToMoment(data.gueltigBis),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampErstellt)),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampMutiert)),
            data.userErstellt,
            data.userMutiert,
            data.type,
            data.angestellteId,
            data.subject,
            blobInfos,
            data.content,
            data.emailVersandStatus,
            data.empfaengerAdresse,
            data.emailContentPlainText,
            data.emailContentHTML,
            data.replyToAdresse,
            data.replyToName,
        );
    }
}
