/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {CustomField} from '@dv/kitadmin/models';
import type {SortListDialogModel} from '@dv/kitadmin/ui';
import {checkPresent, DvbUtil, NamedEntityType, sortCustomFields} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {BehaviorSubject, finalize, from, tap} from 'rxjs';
import type {CustomFieldService} from '../../../common/service/rest/customFieldService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-voreinstellungen-custom-fields.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenCustomFields implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'errorService',
        'customFieldService',
        '$q',
    ];

    public customFields: { [key in NamedEntityType]?: CustomField[] } = {};

    public showSortDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public sortState?: SortListDialogModel<CustomField>;
    public showSortLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public isLoadingData: { [key in NamedEntityType]?: boolean } = {};
    public namedEntityTypes: typeof NamedEntityType = NamedEntityType;
    private endOfList: number = 0;

    public constructor(
        private errorService: ErrorService,
        private customFieldService: CustomFieldService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.loadCustomFields();
    }

    public onAddField(namedEntityType: NamedEntityType): void {
        const customField = new CustomField();
        customField.entityType = namedEntityType;

        if (!this.customFields[namedEntityType]) {
            this.customFields[namedEntityType] = [];
        }

        checkPresent(this.customFields[namedEntityType]).push(customField);
    }

    public onDelete(field: CustomField): void {
        DvbUtil.removeFromArray(field, this.customFields[checkPresent(field.entityType)] ?? []);
    }

    public sortFields(namedEntityType: NamedEntityType, formValid: boolean): void {
        const arr = this.customFields[namedEntityType] ?? [];
        this.sortState = this.sortFieldState(arr, formValid, namedEntityType);
        this.showSortDialog$.next(true);
    }

    public confirmSort = (
        customFields: CustomField[],
        formValid: boolean,
        namedEntityType: NamedEntityType,
    ): Observable<unknown> => {
        customFields.forEach((value, index) => {
            value.orderValue = index;
        });

        this.showSortLoading$.next(true);

        return from(this.onSubmit(formValid, namedEntityType)).pipe(
            tap(() => this.showSortDialog$.next(false)),
            finalize(() => this.showSortLoading$.next(false)),
        );
    };

    public onSubmit(formValid: boolean, namedEntityType: NamedEntityType): angular.IPromise<any> {
        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!formValid) {
            return this.$q.reject();
        }

        const customFields = this.customFields[namedEntityType] ?? [];

        const uniqueNames = DvbUtil.uniqueArray(customFields, customField => customField.name);
        const isNamesUnique = uniqueNames.length === customFields.length;
        this.errorService.handleValidationError(isNamesUnique, 'ERRORS.ERR_DUPLICATE_NAME');

        if (!isNamesUnique) {
            return this.$q.reject();
        }

        this.isLoadingData[namedEntityType] = true;

        const result = this.customFieldService.updateCustomFields(namedEntityType, customFields)
            .then(() => this.loadCustomFields(namedEntityType))
            .finally(() => {
                this.isLoadingData[namedEntityType] = false;
            });

        return result;
    }

    private loadCustomFields(namedEntityType?: NamedEntityType): angular.IPromise<any> {
        if (namedEntityType) {
            this.isLoadingData[namedEntityType] = true;
        } else {
            Object.values(NamedEntityType).forEach((t: NamedEntityType) => {
                this.isLoadingData[t] = true;
            });
        }

        this.customFields = {};

        return this.customFieldService.getAll().then(customFields => {
            customFields.forEach(customField => {
                const entityType = checkPresent(customField.entityType);
                const arr = this.customFields[entityType] ?? [];

                arr.push(customField);
                this.customFields[entityType] = arr;
            });

            DvbUtil.keys(this.customFields).forEach(key => {
                const orderValues = customFields.map(customField => customField.orderValue ?? 0);
                this.endOfList = orderValues.length ? Math.max(...orderValues) + 1 : 0;
                this.customFields[key] = this.customFields[key]?.sort(sortCustomFields);
            });

        }).finally(() => {
            this.isLoadingData = {};
        });
    }

    private sortFieldState(
        customFields: CustomField[],
        formValid: boolean,
        namedEntityType: NamedEntityType,
    ): SortListDialogModel<CustomField> {
        return {
            dialogTitleKey: 'CUSTOM_FIELD.SORTIEREN',
            items: customFields,
            confirm: values => this.confirmSort(values, formValid, namedEntityType)
                .pipe(tap(() => this.errorService.clearAll()))
                .subscribe(),
            cancel: () => {
                this.showSortDialog$.next(false);
                this.errorService.clearAll();
            },
            open$: this.showSortDialog$,
            isLoading$: this.showSortLoading$,
        };
    }
}

componentConfig.controller = DvbVoreinstellungenCustomFields;
angular.module('kitAdmin').component('dvbVoreinstellungenCustomFields', componentConfig);
