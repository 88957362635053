<div class="dvb-kita-tarife-auswahl">
    <div ng-repeat="abrechnungTarif in vm.abrechnungTarife | orderBy: 'typ.index'"
         ng-init="searchResultEntry = abrechnungTarif.tarif.toSearchResultEntry()">
        <dvb-search-get-single-entity entity-to-search="TARIF"
                                      filter-source="vm.filterTarif(abrechnungTarif.typ.tarifTypes, $source)"
                                      expand-entity="true"
                                      display-label="{{vm.getLabelTranslationKey(abrechnungTarif)}}"
                                      placeholder-key="{{vm.getPlaceholderTranslationKey(abrechnungTarif)}}"
                                      ng-model="searchResultEntry"
                                      ng-change="vm.updateTarif(abrechnungTarif, searchResultEntry)"
                                      as-col="true">
        </dvb-search-get-single-entity>
    </div>
</div>
