/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type KibonExchangeBetreuungStatus = 'NICHT_MUTIERBAR' | 'MUTIERBAR' | 'IGNORIERT';

export const KibonExchangeBetreuungStatus = {
    NICHT_MUTIERBAR: 'NICHT_MUTIERBAR' as KibonExchangeBetreuungStatus,
    MUTIERBAR: 'MUTIERBAR' as KibonExchangeBetreuungStatus,
    IGNORIERT: 'IGNORIERT' as KibonExchangeBetreuungStatus
};

