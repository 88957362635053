/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {Ausbildung} from '../../anstellung/models/Ausbildung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        ausbildung: '<',
    },
    template: require('./dvb-ausbildungen.html'),
    controllerAs: 'vm',
};

export class DvbAusbildungen implements angular.IController {
    public static $inject: readonly string[] = [];
    public ausbildung!: Ausbildung;
}

componentConfig.controller = DvbAusbildungen;
angular.module('kitAdmin').component('dvbAusbildungen', componentConfig);
