/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TempBlob} from '@dv/kitadmin/models';
import type angular from 'angular';
import type {KontaktKorrespondenz} from '../../../communication/models/KontaktKorrespondenz';
import type {KontaktKorrespondenzType} from '../../../communication/models/KontaktKorrespondenzType';
import type {KontaktKorrespondenzService} from '../../../communication/service/kontakt-korrespondenz-service';
import {NotizTimelineController} from '../../../notiz/component/dvb-named-entity-notizen/NotizTimelineController';
import type {ITimelineController} from '../../../notiz/models/ITimelineController';

export class KontaktKorrespondenzTimelineController implements ITimelineController<KontaktKorrespondenz> {

    public icon: { [k in KontaktKorrespondenzType]: string };
    public iconText: { [k in KontaktKorrespondenzType]: string };
    public tooltip: { [k in KontaktKorrespondenzType]: (item: KontaktKorrespondenz) => string };

    private notizController: NotizTimelineController;

    public constructor(
        private readonly kontaktKorrespondenzService: KontaktKorrespondenzService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
        this.notizController = new NotizTimelineController($translate);

        this.icon = {
            BRING_ABHOLZEITEN: '<i class="fa fa-child"></i>',
            BRING_ABHOLZEITEN_CONFIRM: '<i class="fa fa-child"></i>',
            BULK_EMAIL: '<i class="fa fa-envelope"></i>',
            NOTIZ: this.notizController.iconHTML,
            RECHNUNGS_REVISION: '<i class="fa fa-file-text-o"></i>',
            STEUERBESCHEINIGUNG: '<i class="fa fa-file-text-o"></i>',
        };
        this.iconText = {
            BRING_ABHOLZEITEN: this.$translate.instant('COMMUNICATION.BRING_ABHOLZEITEN.RELEASE'),
            BRING_ABHOLZEITEN_CONFIRM: this.$translate.instant('COMMUNICATION.BRING_ABHOLZEITEN.CONFIRMATION'),
            BULK_EMAIL: this.$translate.instant('COMMUNICATION.BULK_EMAILS.NAME'),
            NOTIZ: this.notizController.iconTooltipText,
            RECHNUNGS_REVISION: this.$translate.instant('COMMON.RECHNUNG.SINGULAR'),
            STEUERBESCHEINIGUNG: this.$translate.instant('COMMUNICATION.STEUERBESCHEINIGUNG.NAME'),
        };
        this.tooltip = {
            BRING_ABHOLZEITEN: item => this.getTooltipKontaktEmail(item),
            BRING_ABHOLZEITEN_CONFIRM: item => this.getTooltipKontaktEmail(item),
            BULK_EMAIL: item => this.getTooltipKontaktEmail(item),
            NOTIZ: item => this.notizController.getTooltipText(item),
            RECHNUNGS_REVISION: item => this.getTooltipKontaktEmail(item),
            STEUERBESCHEINIGUNG: item => this.getTooltipKontaktEmail(item),
        };
    }

    public getTimelineDate(entry: KontaktKorrespondenz): string {
        return entry.timestampErstellt.format('DD.MM.YYYY');
    }

    public getTimelineTime(entry: KontaktKorrespondenz): string {
        return entry.timestampErstellt.format('HH:mm');
    }

    public getAttachment(entryId: string, blobId: string): angular.IPromise<TempBlob> {
        return this.kontaktKorrespondenzService.getAttachment(entryId, blobId);
    }

    public getIconHTML(entry: KontaktKorrespondenz): string {
        return this.icon[entry.type];
    }

    public getIconTooltipText(entry: KontaktKorrespondenz): string {
        return this.iconText[entry.type];
    }

    public getTooltipText(entry: KontaktKorrespondenz): string {
        return this.tooltip[entry.type](entry);
    }

    private getTooltipKontaktEmail(kontaktKorrespondenz: KontaktKorrespondenz): string {
        const interpolateParams = {
            replyToName: kontaktKorrespondenz.replyToName,
            replyToAddr: kontaktKorrespondenz.replyToAdresse,
            to: kontaktKorrespondenz.empfaengerAdresse,
            sentBy: kontaktKorrespondenz.userErstellt,
        };

        return this.$translate.instant('COMMUNICATION.EMAIL.HEADER_INFO', interpolateParams);
    }
}
