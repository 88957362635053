/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnDestroy} from '@angular/core';
import {Injectable} from '@angular/core';
import type {Nullish} from '@dv/shared/code';
import {isNullish, isPresent} from '@dv/shared/code';

/**
 * Service for embedding google tag manager.
 */
@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerService implements OnDestroy {

    private scriptElem: HTMLScriptElement | null = null;

    public ngOnDestroy(): void {
        this.stopTracking();
    }

    public startTracking(containerId: string | Nullish): void {
        this.initializeTagManager(window as any, containerId);
    }

    public stopTracking(): void {
        if (isPresent(this.scriptElem)) {
            // reload to get rid of all the nasty things loaded by google tag manager
            window.location.reload();
        }
    }

    private initializeTagManager(
        window: Window & { dataLayer: Record<string, unknown> [] },
        containerId: string | Nullish,
    ): void {
        this.stopTracking();
        if (isNullish(containerId)) {
            return;
        }

        // copied from google tag manager, migrated to TS and slightly refactored
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        this.scriptElem = document.createElement('script');
        const firstScript = document.getElementsByTagName('script')[0];

        this.scriptElem.async = true;
        this.scriptElem.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}`;
        firstScript.parentNode!.insertBefore(this.scriptElem, firstScript);
    }
}
