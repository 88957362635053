<div class="dvb-transaktionen-header filter-bar big-abstand-bottom">
    <ul class="list-inline normal-abstand-bottom">
        <li>
            <dvb-period-selector ng-model="vm.filter.gueltigkeit"
                                 ng-change="vm.onUpdate()">
            </dvb-period-selector>
        </li>
        <li class="pull-right text-right" dvb-auth require-permission="kita:manage:general">
            <p>
                <a ui-sref="base.kontenabgleich.import" data-translate="FAKTURA.KONTENABGLEICH.DATEIEN_HOCHLADEN"></a>
            </p>
            <p>
                <dvb-loading-button type="button"
                                    is-loading="vm.loadingContext.isLoading"
                                    as-link="true"
                                    on-click="vm.autoAssign()">
                    <span data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_AUTOMATISCH_ZUWEISEN"></span>
                </dvb-loading-button>
            </p>
        </li>
    </ul>
    <ul class="filter-list">
        <li>
            <div data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTION_STATUS" class="filter-label"></div>
            <ul class="filter-option-list">
                <li>
                    <button type="button"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.ZUGEWIESEN"
                            ng-change="vm.onUpdate()"
                            data-translate="FAKTURA.KONTENABGLEICH.STATUS_ZUGEWIESEN">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.NICHT_ZUGEWIESEN"
                            ng-change="vm.onUpdate()"
                            data-translate="FAKTURA.KONTENABGLEICH.STATUS_NICHT_ZUGEWIESEN">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.IGNORIERT"
                            ng-change="vm.onUpdate()"
                            data-translate="FAKTURA.KONTENABGLEICH.STATUS_IGNORIERT">
                    </button>
                </li>
            </ul>
        </li>
    </ul>

    <div class="filter-list small-abstand-top" ng-if="vm.selectedBankStatements.length > 0">
        <div class="filter">
            <div data-translate="FAKTURA.KONTENABGLEICH.KONTOAUSZUG" class="filter-label"></div>
            <ul class="filter-option-list">
                <li ng-repeat="bankStatement in vm.selectedBankStatements">
                    <dvb-filter-option
                        label="'FAKTURA.KONTENABGLEICH.KONTOAUSZUG_VOM'
                            | translate:{datum: (bankStatement.einleseDatum
                            | amDateFormat:'DD.MM.YYYY'), anzahlTransaktionen: bankStatement.entryCount}"
                        is-selected="vm.filter.bankStatementIds.includes(bankStatement.id)"
                        closeable="true"
                        on-toggle="vm.updateBankStatement(isSelected, bankStatement.id)"
                        on-close="vm.clearBankStatement(bankStatement)">
                    </dvb-filter-option>
                </li>
            </ul>
        </div>
    </div>
</div>
