@if (title) {
    <h2 [translate]="title"></h2>
}
<form [formGroup]="form" (ngSubmit)="submitForm()">

    <div class="row form-row">
        @if (!event) {
            <div class="col-sm-6">
                <dv-search-entity [entityToSearch]="'KINDERORT'"
                                  [placeholder]="'COMMON.KINDERORT.AUSWAEHLEN' | translate"
                                  formControlName="kinderOrt"
                                  class="normal-abstand-bottom">
                </dv-search-entity>
            </div>
        }
        <div class="col-sm-6">
            <input type="time"
                   class="form-control"
                   formControlName="time"
                   [placeholder]="'CHECK_IN.STATUS.TIME' | translate"
                   [tooltip]="'CHECK_IN.STATUS.TIME' | translate">
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading"
                              (cancel)="handleCancel()">
    </dv-submit-cancel-buttons>
</form>

