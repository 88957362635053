<div class="dvb-bank-statement-entry-content">
    <div ng-if="vm.entry.status === 'ZUGEWIESEN'">
        <div class="assignment-icon">
            <p>
                <i class="fa"
                   ng-class="{
                 'fa-refresh': vm.entry.automaticAssignment,
                 'fa-user': !vm.entry.automaticAssignment
               }"
                   uib-tooltip="{{vm.getAssignmentTooltipText()}}"
                   tooltip-placement="top-left">
                </i>
            </p>
        </div>

        <div class="row">
            <div class="col-xs-4 col-xs-offset-1">
                <p>
                    <span data-translate="COMMON.RECHNUNGSDATUM"></span>:
                    <span ng-bind="vm.entry.rechnung.rechnungsDatum | amDateFormat: 'DD.MM.YYYY'"></span>
                </p>
                <p>
                    <span data-translate="COMMON.FAELLIG_AM"></span>:
                    <span ng-bind="vm.entry.rechnung.faelligAm | amDateFormat: 'DD.MM.YYYY'"></span>
                </p>
                <p>
                    <span data-translate="FAKTURA.KONTENABGLEICH.EINGELESEN_AM"></span>:
                    <span ng-bind="vm.entry.einleseDatum | amDateFormat: 'DD.MM.YYYY'"></span>
                </p>
            </div>

            <div class="col-xs-3">
                <p>
                     <span data-translate="FAKTURA.RECHNUNG_SEARCH_RESULT"
                           data-translate-value-nummer="{{vm.entry.rechnung.rechnungsNummer}}">
                     </span>
                </p>
                <p ng-bind="vm.entry.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'"></p>
                <p>
                    <span data-translate="FAKTURA.KONTENABGLEICH.RECHNUNGS_STATUS"></span>:
                    <span data-translate="{{vm.entry.rechnung.externalStatus.msgKey}}"></span>
                </p>
            </div>

            <div class="col-xs-4">
                <p>
                    <span data-translate="FAKTURA.KONTENABGLEICH.EMPFAENGER"></span>:
                    <span ng-bind="vm.entry.rechnung.kontaktpersonDisplayName"></span>
                </p>
                <p>
                    <span ng-bind="vm.entry.rechnung.kitaTitel || ('COMMON.KINDERORT.SINGULAR' | translate)"></span>:
                    <span ng-bind="vm.entry.rechnung.kitaName"></span>
                </p>
            </div>
        </div>
    </div>
    <div ng-if="vm.entry.status === 'NICHT_ZUGEWIESEN'">
        <div class="row">
            <div class="col-xs-4 col-xs-offset-1">
                <p ng-if="vm.entry.name" ng-bind="vm.entry.name"></p>
                <p ng-if="!vm.entry.name" data-translate="FAKTURA.KONTENABGLEICH.NAME_NOT_FOUND"></p>
                <dvlib-adresse-display ng-if="vm.entry.adresse"
                                       [adresse]="vm.entry.adresse"
                                       [display-type]="'long'">
                </dvlib-adresse-display>
            </div>
            <div class="col-xs-4 text-right">
                <p ng-if="vm.entry.iban" ng-bind="vm.entry.iban"></p>
            </div>
        </div>
    </div>
</div>
