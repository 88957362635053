/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, Kontaktperson, Mitzuegler} from '@dv/kitadmin/models';
import {Umzug} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Adresse, FunctionType, Persisted} from '@dv/shared/code';
import {DvbUtil, LoadingContext, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';
import type {AddressService} from '../../service/rest/addressService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktpersonId: '<',
        kindId: '<',
        onSave: '&',
        onCancel: '&',
    },
    template: require('./dvb-umzug.html'),
    controllerAs: 'vm',
};

export class DvbUmzug implements angular.IController {

    public static $inject: readonly string[] = ['errorService', 'addressService', 'mandantConfigurationService'];

    public kontaktpersonId!: EntityId;
    public kindId!: EntityId;
    public onSave!: (data: { umzug: Umzug; context: LoadingContext }) => unknown;
    public onCancel!: FunctionType;

    public umzug: Umzug = new Umzug();
    public mitzuegler: Mitzuegler | null = null;
    public loadingContext: LoadingContext = new LoadingContext();
    public isLoading: boolean = false;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly addressService: AddressService,
        private readonly mandantConfigurationService: MandantConfigurationService,
    ) {
    }

    public $onInit(): void {
        if (this.kindId && this.kontaktpersonId) {
            throw new Error('Expected kindId or kontaktpersonId, but not both');
        }

        this.umzug = new Umzug();
        this.mandantConfigurationService.createDefaultLimitedAdresse().then(adresse => {
            this.umzug.limitedAdresse = adresse;
        });
        this.loadReleatedPersonen();
    }

    public toggleKindMitzuegler(kind: Persisted<Kind>): void {
        if (this.isSelectedKind(kind)) {
            DvbUtil.removeFromArray(kind.id, this.umzug.kindIds);
        } else {
            this.umzug.kindIds.push(kind.id);
        }
    }

    public toggleKontaktpersonMitzuegler(kontaktperson: Persisted<Kontaktperson>): void {
        if (this.isSelectedKontaktperson(kontaktperson)) {
            DvbUtil.removeFromArray(kontaktperson.id, this.umzug.kontaktpersonIds);
        } else {
            this.umzug.kontaktpersonIds.push(kontaktperson.id);
        }
    }

    public isSelectedKontaktperson(kontaktperson: Persisted<Kontaktperson>): boolean {
        return this.umzug.kontaktpersonIds.includes(kontaktperson.id);
    }

    public isSelectedKind(kind: Persisted<Kind>): boolean {
        return this.umzug.kindIds.includes(kind.id);
    }

    public saveUmzug(): void {
        if (!this.isAdresseValid(this.umzug?.limitedAdresse?.adresse)) {
            return;
        }

        if (TypeUtil.isFunction(this.onSave)) {
            this.onSave({umzug: this.umzug, context: this.loadingContext});
        }
    }

    public cancelUmzug(): void {
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }

    public loadReleatedPersonen(): void {
        if (!this.umzug.limitedAdresse?.gueltigAb) {
            return;
        }

        // this.kontaktpersonId ist die id der zuegelnden Kontaktperson
        if (this.kontaktpersonId) {
            this.isLoading = true;
            this.addressService.getKontaktpersonMitzuegler(this.kontaktpersonId, this.umzug.limitedAdresse.gueltigAb)
                .then(m => this.setMitzuegler(m))
                .finally(() => {
                    this.umzug.kontaktpersonIds.push(this.kontaktpersonId);
                    this.isLoading = false;
                });

            return;
        }

        // this.kindId ist die id des zuegelnden Kindes
        if (this.kindId) {
            this.isLoading = true;
            this.addressService.getKindMitzuegler(this.kindId, this.umzug.limitedAdresse.gueltigAb)
                .then(m => this.setMitzuegler(m))
                .finally(() => {
                    this.umzug.kindIds.push(this.kindId);
                    this.isLoading = false;
                });
        }
    }

    private setMitzuegler(mitzuegler: Mitzuegler): void {
        this.umzug.kontaktpersonIds = mitzuegler.kontaktpersonen
            .filter(k => k.wohntAnSelberAdresse)
            .map(k => k.person.id);

        this.umzug.kindIds = mitzuegler.kinder
            .filter(k => k.wohntAnSelberAdresse)
            .map(k => k.person.id);

        this.mitzuegler = mitzuegler;
    }

    private isAdresseValid(adresse?: Adresse): boolean {
        const valid = adresse?.isValid() ?? false;
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }
}

componentConfig.controller = DvbUmzug;
angular.module('kitAdmin').component('dvbUmzug', componentConfig);
