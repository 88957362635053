/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const REFERENZNUMMER_LENGTH = 27;
const regex = /(\d{5})/g;

function formatEsr(input?: string): string {
    if (!input) {
        return '';
    }

    // pad left with 27 zeros
    const zeroPrefix = new Array(REFERENZNUMMER_LENGTH - input.length + 1).join('0');
    const padded = zeroPrefix + input;

    // reverse, add space after every 5th number, reverse again
    return padded.split('')
        .reverse()
        .join('')
        .replace(regex, '$1 ')
        .split('')
        .reverse()
        .join('');
}

function esr(): (input: string) => string {
    return formatEsr;
}

angular.module('kitAdmin').filter('esr', esr);
