<div class="header">
    <div class="row header-row">
        <div class="col-xs-12 header-box">
            <i class="fa fa-calendar header-icon"
               uib-tooltip="{{'COMMON.WOCHENPLAN.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>
            <h1 uib-tooltip="{{vm.wochenplan.name}}"
                tooltip-placement="top-left"
                ng-bind="vm.wochenplan.name">
            </h1>

            <ul class="list-inline header-breadcrumb">
                <li ui-sref-active="active">
                    <a ui-sref="base.wochenplan.profil"
                       data-translate="COMMON.NAV_PROFIL">
                    </a>
                </li>
                <li ui-sref-active="active"
                    dvb-auth
                    require-role="SUPPORT_ADMIN">
                    <a ui-sref="base.wochenplan.belegungseinheiten"
                       data-translate="COMMON.BELEGUNGS_EINHEIT.PLURAL">
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div ui-view></div>
</div>
