/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {KinderOrtType} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {ReportService} from '../../../../report/service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        firstOfWeek: '<',
        lastOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-uebersicht.html'),
    controllerAs: 'vm',
};

export class DvbKitaUebersicht implements angular.IController {

    public static $inject: readonly string[] = ['reportService', 'authStore'];

    public kita!: Persisted<KinderOrt>;
    public firstOfWeek!: moment.Moment;
    public lastOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public loading: boolean = true;
    public hasKitaViewPermission: boolean = false;
    public hasPersonalViewPermission: boolean = false;
    public hasMultipleTabs: boolean = false;

    public controllingReport: ControllingReport | null = null;

    public jahr: number | null = null;
    public activeTabIndex: number = 0;

    private initialized: boolean = false;

    public constructor(
        private readonly reportService: ReportService,
        private readonly authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.hasKitaViewPermission = this.authStore.hasPermission(PERMISSION.KITA.VIEW + this.kita.id);
        this.hasPersonalViewPermission = this.authStore.hasPermission(PERMISSION.PERSONAL.VIEW + this.kita.id);

        const tabVisibility = [
            this.kita.dtype === KinderOrtType.TAGES_ELTERN, // stunden tab
            this.hasKitaViewPermission, // plaetze tab
            this.hasPersonalViewPermission, // personal tab
        ];
        this.hasMultipleTabs = tabVisibility.filter(Boolean).length > 1;
        this.activeTabIndex = tabVisibility.indexOf(true);

        this.jahr = moment(this.firstOfWeek).isoWeekYear();
        this.loadData();
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.firstOfWeek) {
            this.onWeekChanged();
        }
    }

    private loadData(): void {
        if (!this.hasKitaViewPermission && !this.hasPersonalViewPermission) {
            this.loading = false;

            return;
        }

        this.reportService.getControlling({kinderOrtId: this.kita.id, year: checkPresent(this.jahr)})
            .then(controllingReport => {
                this.loading = false;
                this.controllingReport = controllingReport;
                this.initialized = true;
            });
    }

    private onWeekChanged(): void {
        if (this.jahr !== moment(this.firstOfWeek).isoWeekYear()) {
            this.loading = true;
            this.jahr = moment(this.firstOfWeek).isoWeekYear();
        }
        if (this.initialized) {
            this.loadData();
        }
    }
}

componentConfig.controller = DvbKitaUebersicht;
angular.module('kitAdmin').component('dvbKitaUebersicht', componentConfig);
