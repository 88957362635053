<div class="dvb-belegung-interval-header">
    <p ng-if="vm.noDate">
        <span data-translate="COMMON.AKTUELLE_BELEGUNG"></span>
    </p>

    <p ng-if="!vm.noDate">
        <span ng-bind="vm.belegungTitle"></span>
        <span ng-if="!vm.isDateClickable && !vm.titleKey && vm.belegungInterval.belegungsZustand"
              ng-bind="vm.belegungInterval.gueltigAb | amDateFormat: 'D.M.YYYY'"></span>
        <a href=""
           ng-if="vm.isDateClickable"
           ng-click="vm.onDateSelected({date: vm.belegungInterval.gueltigAb}); $event.stopPropagation();"
           ng-bind="vm.belegungInterval.gueltigAb | amDateFormat: 'D.M.YYYY'"></a>
    </p>
</div>
