<strong>{{ korrespondenz.subject }}</strong>

<dv-collapsible-panel [isLast]="true" [compact]="true">
    <div class="truncate" panel-header>
        {{ korrespondenz.emailContentPlainText || korrespondenz.content }}
    </div>

    <ng-container panel-content>
        @if (showEmailContentPlainText) {
            <div
                class="nl2br">
                {{ korrespondenz.emailContentPlainText }}
            </div>
        }
        @if (showEmailContentHTML) {
            <div
                [innerHTML]="korrespondenz.emailContentHTML">
            </div>
        }
    </ng-container>

</dv-collapsible-panel>
