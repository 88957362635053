/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum ReleaseType {
    RELEASE = 'RELEASE',
    RESEND = 'RESEND',
    ADJUST_DEADLINE = 'ADJUST_DEADLINE',
    WITHDRAW = 'WITHDRAW',
    CONFIRM = 'CONFIRM',
}
