/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SupportedLanguage} from '@dv/shared/code';
import {UserLanguageService} from '@dv/shared/angular';
import angular from 'angular';
import type {MandantService} from '../../service/rest/mandantService';

dvbTranslatable.$inject = ['$compile', 'mandantService'];

const template = `
<dvb-translatable-language ng-model="$dvbSelectedLang" supported-languages="supportedLanguages">
</dvb-translatable-language>

<div ng-repeat="$dvbLang in supportedLanguages"
     ng-init="$dvbLangTranslated = ('LANGUAGE.' + $dvbLang | translate)">
    <placeholder></placeholder>
</div>`;

/**
 * This directive provides some magic:
 * 1. It adds the language selection component dvb-translatable-language to initialise variable $dvbSelectedLang
 * 2. It repeats all supported languages
 * 3. It uses transclusion to fill each language block with the injected template
 * 4. It makes the variables $dvbSelectedLang and $dvbLang (current language) accessible inside the transcluded
 * template!
 *
 * Idea from: https://stackoverflow.com/a/32319422
 */
function dvbTranslatable(
    $compile: angular.ICompileService,
    mandantService: MandantService,
): angular.IDirective<ITranslatableScope> {

    return {
        require: {
            formCtrl: '?^form',
        },
        restrict: 'E',
        transclude: true,
        // scope: true creates a new inherited scope - making it possible to access properties defined above
        // dvb-translatable.
        scope: true,
        link: (scope: ITranslatableScope, element, _attrs, _ctrl: any, transclude): void => {

            mandantService.getMandantLanguges().then(languages => {
                scope.supportedLanguages = languages;
                scope.$dvbSelectedLang = UserLanguageService.getLocalStorageLanguage();
                // initialise $dvbLang to something, to avoid false positive warnings due to missing translations
                // ($dvbLang is replaced only after compilation of the transcluded template)
                scope.$dvbLang = UserLanguageService.getLocalStorageLanguage();
                scope.$dvbLangTranslated = '';

                // the transcluded scope cannot require parent controllers, hence we pass through the form controller
                // here
                scope.formCtrl = _ctrl.formCtrl;

                const templateEl = angular.element(template);

                transclude!(scope, clonedContent => {
                    templateEl.find('placeholder').replaceWith(clonedContent!);

                    $compile(templateEl)(scope, clonedTemplate => {
                        element.append(clonedTemplate!);
                    });
                });
            });
        },
    };
}

export interface ITranslatableScope extends angular.IScope {
    supportedLanguages: SupportedLanguage[];
    $dvbSelectedLang: SupportedLanguage;
    $dvbLang: SupportedLanguage;
    $dvbLangTranslated: string;
    formCtrl?: angular.IFormController;
}

angular.module('kitAdmin').directive('dvbTranslatable', dvbTranslatable);
