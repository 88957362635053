import {computed, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import type {KinderOrt} from '@dv/kitadmin/models';
import {KinderOrtFraktionTransformer, KinderOrtTransformer, Termin} from '@dv/kitadmin/models';
import {AngestellteZuweisungService} from '@dv/shared/backend/api/angestellte-zuweisung.service';
import {JaxAngestellteKinderOrtZuweisungen} from '@dv/shared/backend/model/jax-angestellte-kinder-ort-zuweisungen';
import {JaxAngestellteZuweisung} from '@dv/shared/backend/model/jax-angestellte-zuweisung';
import {JaxAngestellteZuweisungenByStandort} from '@dv/shared/backend/model/jax-angestellte-zuweisungen-by-standort';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbRestUtil, isPresent, LogFactory, TimeRangeUtil} from '@dv/shared/code';
import {shareState} from '@dv/shared/rxjs-utils';
import {Translator} from '@dv/shared/translator';
import type {Observable} from 'rxjs';
import {catchError, combineLatest, EMPTY, filter, map, switchMap, tap} from 'rxjs';
import type {CalendarEvent} from '../../../../calendar/timeline/model/CalendarEvent';
import type {CalendarGroup} from '../../../../calendar/timeline/model/CalendarGroup';
import type {CalendarResource} from '../../../../calendar/timeline/model/CalendarResource';
import type {LayerConfigByLayerIndex} from '../../../../calendar/timeline/model/LayerConfig';
import {
    EventGueltigkeitService,
} from '../../../../kinderort/component/personal/personalplanung/converter/event-gueltigkeit.service';
import {
    terminToCalendarEvent,
} from '../../../../kinderort/component/personal/personalplanung/converter/termin-to-calendar-event';
import {
    zuweisungZeitToCalendarEvent,
} from '../../../../kinderort/component/personal/personalplanung/converter/zuweisung-zeit-to-calendar-event';
import {LayerType} from '../../../../kinderort/component/personal/personalplanung/LayerType';
import {PersonalTimelineStore} from '../../../../kinderort/component/personal/service/personal-timeline.store';
import {Dienst} from '../../../konfiguration/Dienst';
import {AngestellteZuweisung} from '../../../model/AngestellteZuweisung';
import type {Angestellte} from '../../models/Angestellte';

const LOG = LogFactory.createLog('AngestellteZuweisungStore');

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class AngestellteZuweisungStore {

    private readonly timelineStore = inject(PersonalTimelineStore);
    private readonly zuweisungService = inject(AngestellteZuweisungService);
    private readonly translator = inject(Translator);
    private readonly eventGueltigkeitService = inject(EventGueltigkeitService);

    public angestellte = signal<Persisted<Angestellte> | undefined>(undefined);
    public groups = signal([] as CalendarGroup[]);

    public timelineLayerConfig = computed(() => {
        const mode = this.timelineStore.displayMode();
        const layerConfig: LayerConfigByLayerIndex = new Map();
        layerConfig.set(LayerType.ZUWEISUNG, {});
        layerConfig.set(LayerType.TERMIN, {});

        if (mode === 'day') {
            layerConfig.set(LayerType.PAUSEZEIT, {disableBorderRadius: true});
        }

        return layerConfig;
    });

    public zuweisungenParams = computed(() => {
        const angestellte = this.angestellte();
        const startDate = this.timelineStore.startDate();
        const endDate = this.timelineStore.endDate();

        if (!angestellte) {
            return undefined;
        }

        return {
            angestellteId: angestellte.id,
            angestellteIdMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(startDate),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(endDate),
            },
        };
    });

    public zuweisungenLoading = signal(false);
    public zuweisungen$: Observable<JaxAngestellteZuweisungenByStandort> = toObservable(this.zuweisungenParams).pipe(
        filter(isPresent),
        tap(() => {
            this.zuweisungenLoading.set(true);
            this.groups.update(groups => {
                const result = [...groups];
                result.flatMap(g => g.resources).forEach(r => {
                    r.events = [];
                });

                return result;
            });
        }),
        switchMap(params => this.zuweisungService.getAngestellteZuweisungen$(params).pipe(
            catchError(e => {
                LOG.error('could not load zuweisungen', e);

                return EMPTY;
            })),
        ),
        tap(() => this.zuweisungenLoading.set(false)),
        shareState());

    private dienste$ = this.zuweisungen$.pipe(
        map(zuweisungen => zuweisungen.dienste),
        map(dienste => DvbRestUtil.transformArray(dienste, Dienst).map(checkPersisted)),
        shareState(),
    );
    public dienste = toSignal(this.dienste$, {initialValue: [] as Persisted<Dienst>[]});

    public calendarGroups$: Observable<CalendarGroup[]> = combineLatest([this.zuweisungen$, this.dienste$]).pipe(
        map(([zuweisungen, dienste]) => this.toCalendarGroups(zuweisungen, dienste)),
    );

    public constructor() {
        this.calendarGroups$
            .pipe(takeUntilDestroyed())
            .subscribe({
                next: data => this.groups.set(data),
            });
    }

    private toCalendarGroups(
        zuweisungen: JaxAngestellteZuweisungenByStandort,
        dienste: Persisted<Dienst>[],
    ): CalendarGroup[] {
        const termine = zuweisungen.termine.map(Termin.apiResponseTransformer);
        const angestellteTermine = termine.filter(t => t.angestellteIds && !t.alleAngestellte && t.alleKinderOrte);

        const terminGroup = this.createTermineCalendarGroup(angestellteTermine);
        const termineGroups = isPresent(terminGroup) ? [terminGroup] : [];

        const zuweisungGroups = this.createZuweisungCalendarGroups(zuweisungen, termine, dienste);

        return [
            ...termineGroups,
            ...zuweisungGroups,
        ];
    }

    private createZuweisungCalendarGroups(
        zuweisungen: JaxAngestellteZuweisungenByStandort,
        termine: Termin[],
        dienste: Persisted<Dienst>[],
    ): CalendarGroup[] {
        return zuweisungen.kinderOrtZuweisungen.flatMap(zuweisung => {
            const kinderOrt = checkPersisted(KinderOrtTransformer.create().apiResponseTransformer(zuweisung.kinderOrt));
            const kinderOrtTermine = termine.filter(t => t.kinderOrtIds.includes(kinderOrt.id));
            const kinderOrtEvents = this.toCalendarEvents(zuweisung.kinderOrtZuweisungen, dienste, kinderOrtTermine);
            const fraktionResources = this.createFraktionResources(zuweisung, dienste);

            const resources = kinderOrtEvents.length > 0 || fraktionResources.length > 0 ?
                [this.createKinderOrtResource(kinderOrt, kinderOrtEvents), ...fraktionResources] :
                [];

            return {
                id: kinderOrt.id,
                getDisplayName: () => kinderOrt.getDisplayName(),
                resources,
                zuweisbar: false,
                extendable: false,
                sortable: false,
            };
        });
    }

    private createTermineCalendarGroup(angestellteTermine: Termin[]): CalendarGroup | undefined {
        if (angestellteTermine.length < 1) {
            return undefined;
        }

        return {
            id: 'ownTermineGroup',
            extendable: false,
            sortable: false,
            resources: [
                {
                    id: 'ownTermineResource',
                    events: angestellteTermine.map(t => terminToCalendarEvent(t,
                        this.eventGueltigkeitService,
                        this.timelineStore.selectedDate())),
                    getDisplayName: () => this.translator.instant('PERSONAL.TERMIN.OWN'),
                    getIcons: () => [],
                },
            ],
            getDisplayName: () => this.translator.instant('PERSONAL.TERMIN.PLURAL'),
        };
    }

    private createFraktionResources(
        zuweisung: JaxAngestellteKinderOrtZuweisungen,
        dienste: Persisted<Dienst>[],
    ): CalendarResource[] {
        return zuweisung.fraktionZuweisungen.map(fraktionZuweisung => {
            const fraktion = KinderOrtFraktionTransformer.create()
                .apiResponseTransformer(fraktionZuweisung.fraktion);
            const fraktionId = checkPresent(fraktionZuweisung.fraktion.id);

            const events = this.toCalendarEvents(fraktionZuweisung.zuweisungen, dienste, []);

            return {
                id: fraktionId,
                getDisplayName: () => fraktion.getDisplayName(),
                getIcons: () => [],
                linkParams: {},
                events,
            };
        });
    }

    private createKinderOrtResource(kinderOrt: KinderOrt, kinderOrtEvents: CalendarEvent[]): CalendarResource {
        return {
            id: 'kinderOrt',
            getDisplayName: () => kinderOrt.getDisplayName(),
            getIcons: () => [],
            link: '',
            linkParams: {},
            events: kinderOrtEvents,
        };
    }

    private toCalendarEvents(
        zuweisungen: JaxAngestellteZuweisung[],
        dienste: Persisted<Dienst>[],
        termine: Termin[],
    ): CalendarEvent[] {
        const selectedDate = this.timelineStore.displayMode() === 'day' ? this.timelineStore.selectedDate() : undefined;

        const zuweisungEvents = zuweisungen.map(AngestellteZuweisung.apiResponseTransformer)
            .flatMap(angestellteZuweisung =>
                angestellteZuweisung.zuweisungZeiten.map(zeit => {
                    const dienst = TimeRangeUtil.findMatching(dienste, zeit);

                    return zuweisungZeitToCalendarEvent(angestellteZuweisung,
                        zeit,
                        dienst,
                        this.eventGueltigkeitService,
                        selectedDate);
                }),
            );

        const terminEvents = termine
            .map(termin => terminToCalendarEvent(termin, this.eventGueltigkeitService, selectedDate));

        return [...zuweisungEvents, ...terminEvents];
    }
}
