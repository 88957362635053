<div class="dvb-file-list" ng-class="{'enabled' : !vm.isDisabled}">
    <div class="row form-row">
        <div class="col-xs-12">
            <span class="no-files"
                  data-translate="{{vm.labelNoFiles}}"
                  ng-if="vm.files.length === 0">
            </span>
        </div>
    </div>
    <dvb-draggable-element ng-repeat="file in vm.files | orderBy: 'filename'"
                           id="file.id"
                           class="draggable-element"
                           is-draggable="!vm.isDisabled"
                           on-drag-end="vm.dragEnd()">
        <div class="row selected-entity">
            <div class="col-xs-12 entity">
                <a href=""
                   ng-bind="file.filename"
                   ng-click="vm.onClick({file: file})"
                   draggable="false"
                   ng-if="!vm.disableDownload">
                </a>
                <span ng-bind="file.filename" ng-if="vm.disableDownload"></span>
                <uib-progressbar max="100"
                                 ng-show="file.isInProgress()"
                                 type="success"
                                 value="file.progress">
                </uib-progressbar>
            </div>
            <div class="col-xs-4 edit" ng-if="!vm.isDisabled">
                <a data-translate="COMMON.LOESCHEN" href="" ng-click="vm.onRemoveFile({file: file})"></a>
            </div>
        </div>
    </dvb-draggable-element>
    <div class="row" ng-if="!vm.isDisabled">
        <div class="col-xs-12 add separator">
            <a data-ngf-multiple="{{vm.allowMultiple}}"
               draggable="false"
               href=""
               ng-if="vm.allowMultiple"
               ngf-select="vm.uploadFiles($files)">
                + <span data-translate="{{vm.labelFileUpload}}"></span>
            </a>
        </div>
    </div>
</div>
