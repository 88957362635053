<div class="dvb-belegung-zeiten">
    <div ng-if="vm.hasBelegungZeiten">
        <div class="smaller-abstand-top">
            <p>
                <a href="" ng-click="vm.toggle()">
                    <i class="fa"
                       ng-class="{'fa-chevron-right': vm.isCollapsed, 'fa-chevron-down': !vm.isCollapsed}"></i>
                </a>
                <span data-translate="KIND.ZUWEISUNG.BELEGUNG_ZEIT_ADD"></span>
            </p>
        </div>
        <div uib-collapse="vm.isCollapsed">
            <div class="wochenplan">
                <div class="tag" ng-repeat="dayOfWeek in vm.weekDays">
                    <div>
                        <div ng-repeat="zeiten in vm.belegungZeiten[dayOfWeek]"
                             ng-class="{'smaller-abstand-bottom': !$last}">
                            <p class="small" ng-bind="zeiten.von.format('HH:mm')"></p>
                            <p class="small" ng-bind="zeiten.bis.format('HH:mm')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>