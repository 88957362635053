<div class="dvb-angestellte-anstellung-list">

    <div ng-repeat="anstellung in vm.anstellungen | orderBy: 'gueltigAb': true">
        <dvb-collapsible-panel is-first="$first"
                               limited-entity="anstellung"
                               expand-ab="vm.expandDate"
                               expand-bis="vm.expandDate">
            <panel-header>
                <p data-translate="PERSONAL.ANSTELLUNG.FROM"
                   data-translate-value-date="{{anstellung.gueltigAb | amDateFormat: 'D.M.YYYY'}}">
                </p>
            </panel-header>
            <panel-content>
                <dvb-anstellung-verlauf-item anstellung="anstellung">
                </dvb-anstellung-verlauf-item>
            </panel-content>
        </dvb-collapsible-panel>
    </div>

</div>
