/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {KibonExchangeModul} from './KibonExchangeModul';

export class KibonExchangeTagesschulModule implements ILimited {

    public constructor(
        public institutionId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public module: KibonExchangeModul[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeTagesschulModule {
        const result = new KibonExchangeTagesschulModule(
            data.institutionId,
            DvbRestUtil.localDateTimeToMoment(data.gueltigAb),
            DvbRestUtil.localDateTimeToMoment(data.gueltigBis),
            DvbRestUtil.transformArray(data.module, KibonExchangeModul),
        );

        return result;
    }
}
