/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type { ILimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class KibonExchangeEingewoehnungZeitabschnitt implements ILimited {
    public constructor(
        public eingewoehnungskosten: number,
        public gueltigAb: moment.Moment | null,
        public gueltigBis: moment.Moment | null,
    ){
    }

    public static apiResponseTransformer(data: any): KibonExchangeEingewoehnungZeitabschnitt {
        return new KibonExchangeEingewoehnungZeitabschnitt(
            parseFloat(data.eingewoehnungskosten),
            DvbRestUtil.localDateToMomentChecked(data.eingewoehnungAb),
            DvbRestUtil.localDateToMomentChecked(data.eingewoehnungBis),
        );
    }
}
