/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BlobInfo, UploadTempBlob} from '@dv/kitadmin/models';
import type {NamedEntityType} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, DvbUtil, isPresent} from '@dv/shared/code';
import moment from 'moment';
import type {ITimelineEntry} from '../../common/timeline/ITimelineEntry';

export class Notiz implements ITimelineEntry {

    public constructor(
        public id: string | null = null,
        public entityId: string = '',
        public entityType: NamedEntityType | null = null,
        public subject: string = '',
        public content: string = '',
        public anhaenge: UploadTempBlob[] = [],
        public removedBlobIds: string[] = [],
        public blobInfos: BlobInfo[] = [],
        public userErstellt: string = '',
        public timestampErstellt: moment.Moment | null = null,
        public timestampTimeline: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Notiz {
        const answer = new Notiz();
        answer.id = data.id;
        answer.entityId = data.entityId;
        answer.entityType = data.entityType;
        answer.subject = data.subject;
        answer.content = data.content;
        answer.userErstellt = data.userErstellt;
        answer.timestampErstellt = checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampErstellt));
        answer.timestampTimeline =
            data.timestampTimeline ? DvbRestUtil.localDateTimeToMoment(data.timestampTimeline) : null;

        if (Array.isArray(data.anhaenge)) {
            answer.anhaenge = data.anhaenge.map((a: any) => UploadTempBlob.apiResponseTransformer(a));
        }

        answer.blobInfos =
            data.blobInfos ? data.blobInfos.map((blobInfo: any) => BlobInfo.apiResponseTransformer(blobInfo)) : [];

        return answer;
    }

    public hasTimelineTime(): boolean {
        return isPresent(this.timestampTimeline)
            && !moment(this.timestampTimeline).startOf('day').isSame(this.timestampTimeline);
    }

    public toRestObject(): Record<string, unknown> {
        const timestampTimeline = this.timestampTimeline ?
            DvbRestUtil.momentToLocalDateTime(this.timestampTimeline) :
            null;

        return {
            id: this.id,
            entityId: this.entityId,
            entityType: this.entityType,
            subject: this.subject,
            content: this.content,
            timestampTimeline,
            anhaengeTempBlobIds: UploadTempBlob.getTempBlobIds(this.anhaenge),
            removeBlobIds: this.removedBlobIds,
        };
    }

    public removeAnhang(anhang: UploadTempBlob): void {
        if (anhang.id) {
            this.removedBlobIds.push(anhang.id);
        }
        DvbUtil.removeFromArray(anhang, this.anhaenge);
    }

    public addAnhang(anhang: UploadTempBlob): void {
        const existingAnhang = DvbUtil.findFirst(this.anhaenge, a => a.id === anhang.id);
        if (!existingAnhang) {
            this.anhaenge.push(anhang);
        }
    }
}
