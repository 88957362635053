/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {DayOfWeek, DvbUtil, isPersisted} from '@dv/shared/code';
import angular from 'angular';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {Ausbildung} from '../../anstellung/models/Ausbildung';
import {AusbildungsPersonalBedarf} from '../../betreuungs-schluessel/models/AusbildungsPersonalBedarf';
import type {BetreuungsSchluessel} from '../../betreuungs-schluessel/models/BetreuungsSchluessel';
import {BetreuungsSchluesselRegel} from '../../betreuungs-schluessel/models/BetreuungsSchluesselRegel';
import {BetreuungsSchluesselRegelBedarf} from '../../betreuungs-schluessel/models/BetreuungsSchluesselRegelBedarf';
import type {BetreuungsSchluesselService} from '../../betreuungs-schluessel/service/betreuungsSchluesselService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsSchluessel: '<',
        ausbildungen: '<',
    },
    template: require('./dvb-betreuungs-schluessel.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsSchluessel implements angular.IController {
    public static $inject: readonly string[] = ['errorService', 'betreuungsSchluesselService', 'dvbStateService'];

    public days: readonly DayOfWeek[] = Object.values(DayOfWeek);
    public betreuungsSchluessel!: BetreuungsSchluessel;
    public ausbildungen: Ausbildung[] = [];

    public ausbildungenFlat: Ausbildung[] = [];
    public isLoading: boolean = false;

    public constructor(
        private errorService: ErrorService,
        private betreuungsSchluesselService: BetreuungsSchluesselService,
        private dvbStateService: DvbStateService,
    ) {
    }

    public $onInit(): void {
        if (this.betreuungsSchluessel.betreuungsSchluesselRegeln.length === 0) {
            // add default rule for new BetreuungsSchluessel
            this.addRegel();
        }

        // init flat ausbildungen for dropdown
        this.ausbildungenFlat = [];
        this.flattenAusbildungen(this.ausbildungen);

        if (isPersisted(this.betreuungsSchluessel)) {
            this.initializeReferencedEntities();
        }
    }

    public addRegel(): void {
        const regel = new BetreuungsSchluesselRegel();
        this.addRegelBedarf(regel);
        this.betreuungsSchluessel.betreuungsSchluesselRegeln.push(regel);
    }

    public deleteRegel(regel: BetreuungsSchluesselRegel): void {
        DvbUtil.removeFromArray(regel, this.betreuungsSchluessel.betreuungsSchluesselRegeln);
    }

    public addRegelBedarf(regel: BetreuungsSchluesselRegel): void {
        const regelBedarf = new BetreuungsSchluesselRegelBedarf();
        this.addAusbildungsBedarf(regelBedarf);
        regel.betreuungsSchluesselRegelBedarf.push(regelBedarf);
    }

    public deleteRegelBedarf(regel: BetreuungsSchluesselRegel, regelBedarf: BetreuungsSchluesselRegelBedarf): void {
        DvbUtil.removeFromArray(regelBedarf, regel.betreuungsSchluesselRegelBedarf);
    }

    public addAusbildungsBedarf(regelBedarf: BetreuungsSchluesselRegelBedarf): void {
        regelBedarf.ausbildungsPersonalBedarf.push(new AusbildungsPersonalBedarf());
    }

    public deleteAusbildungsBedarf(
        ausbildungsBedarf: AusbildungsPersonalBedarf,
        regelBedarf: BetreuungsSchluesselRegelBedarf,
    ): void {
        DvbUtil.removeFromArray(ausbildungsBedarf, regelBedarf.ausbildungsPersonalBedarf);
    }

    public submit(formValid: boolean): void {
        const isValid = formValid && this.betreuungsSchluessel.isValid();
        this.errorService.clearAll();
        this.errorService.handleValidationError(
            isValid,
            'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return;
        }

        this.isLoading = true;
        const promise = isPersisted(this.betreuungsSchluessel) ?
            this.betreuungsSchluesselService.update(this.betreuungsSchluessel) :
            this.betreuungsSchluesselService.create(this.betreuungsSchluessel);

        promise.then(() => {
            this.dvbStateService.goToPreviousState();
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public cancel(): void {
        this.dvbStateService.goToPreviousState();
    }

    private flattenAusbildungen(ausbildungen: Ausbildung[]): void {
        ausbildungen.forEach(ausbildung => {
            this.ausbildungenFlat.push(ausbildung);

            if (ausbildung.children && ausbildung.children.length > 0) {
                this.flattenAusbildungen(ausbildung.children);
            }
        });
    }

    private initializeReferencedEntities(): void {
        if (!Array.isArray(this.betreuungsSchluessel.betreuungsSchluesselRegeln)) {
            return;
        }

        this.betreuungsSchluessel.betreuungsSchluesselRegeln.forEach(regel => {
            regel.betreuungsSchluesselRegelBedarf.forEach(regelBedarf => {
                regelBedarf.ausbildungsPersonalBedarf.forEach(ausbildungsBedarf => {
                    ausbildungsBedarf.ausbildung =
                        this.ausbildungenFlat.find(ausbildung => ausbildung.id === ausbildungsBedarf.ausbildungsId)
                        ?? null;
                });
            });
        });
    }
}

componentConfig.controller = DvbBetreuungsSchluessel;
angular.module('kitAdmin').component('dvbBetreuungsSchluessel', componentConfig);
