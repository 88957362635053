/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, RestCache, TarifType, TarifValue} from '@dv/kitadmin/models';
import {FirmenKontingent, KinderOrtTransformer, Tarif, TarifParameter, TempBlob} from '@dv/kitadmin/models';
import type {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {MatrixParams, RestInclude} from '@dv/shared/code';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class TarifService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/tarife`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getTarif(tarifId: string, params?: RestInclude): angular.IPromise<Tarif | null> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(TarifService.BASE_URL, Tarif, tarifId, params);
    }

    public getAllTarife(params?: RestInclude): angular.IPromise<Tarif[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(TarifService.BASE_URL, Tarif, 'tarife', matrixParams);
    }

    public getAllArchivedTarife(params?: RestInclude & RestCache): angular.IPromise<Tarif[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const url = `${TarifService.BASE_URL}/archived`;

        return DvbRestUtilAngularJS.getModelsArray(url, Tarif, 'tarife', matrixParams, !!params?.cache);
    }

    public getAllTarifParameters(dtype?: TarifParameterType): angular.IPromise<TarifParameter[]> {
        const url = `${TarifService.BASE_URL}/parameters`;

        return DvbRestUtilAngularJS.getModelsArray(url, TarifParameter, 'tarifParameters', {dtype});
    }

    public getNotDeletedTarifParameters(dtype?: TarifParameterType): angular.IPromise<TarifParameter[]> {
        const url = `${TarifService.BASE_URL}/parameters/notdeleted`;

        return DvbRestUtilAngularJS.getModelsArray(url, TarifParameter, 'tarifParameters', {dtype});
    }

    public createTarif(tarif: Tarif): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(TarifService.BASE_URL, tarif.toRestObject(true));
    }

    public createTarifValue(tarifId: string, tarifValue: TarifValue): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/values`;

        return this.$http.post(url, tarifValue.toRestObject());
    }

    public updateTarif(tarif: Tarif): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${TarifService.BASE_URL}/${encodeURIComponent(checkPresent(tarif.id))}`;

        return this.$http.put(url, tarif.toRestObject());
    }

    public deleteTarif(tarifId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}`);
    }

    public validateTarifDelete(tarifId: string): angular.IPromise<unknown> {
        return this.$http.get(`${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/validate/delete`);
    }

    /**
     * Verlaengert die Gueltigkeit des TarifValues bis zum naechst gueltigen TarifValue oder bis END_OF_TIME
     */
    public extendTarifValue(tarifId: string, valueId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/` +
            `values/${encodeURIComponent(valueId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    public deleteTarifValue(tarifId: string, valueId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/values/${encodeURIComponent(valueId)}`;

        return this.$http.delete(url);
    }

    public getTempBlobForTarifValue(tarifId: string, valueId: string): angular.IPromise<TempBlob> {
        const url = `${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/` +
            `values/${encodeURIComponent(valueId)}/download/`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob).then(checkPresent);
    }

    public updateParameterOrder(
        updateOrderObj: { items: { id: string; orderValue: number }[] },
    ): angular.IPromise<unknown> {
        const url = `${TarifService.BASE_URL}/parameters/order`;

        return this.$http.put(url, updateOrderObj);
    }

    public hasUniqueParameterNames(tarifParameters: TarifParameter[]): boolean {
        if (!Array.isArray(tarifParameters)) {
            throw new Error('hasUniqueParameterNames: expected input parameter of type Array');
        }

        const parameterNames = tarifParameters.map(tp => tp.name);
        const uniqueNames = DvbUtil.uniqueArray(parameterNames);

        return parameterNames.length === uniqueNames.length;
    }

    public removeParameterWithoutName(tarifParameters: TarifParameter[]): void {
        this.getParameterWithoutName(tarifParameters).forEach(tp => {
            DvbUtil.removeFromArray(tp, tarifParameters);
        });
    }

    public getParameterWithoutName(tarifParameters: TarifParameter[]): TarifParameter[] {
        if (!Array.isArray(tarifParameters)) {
            throw new Error('removeParameterWithoutName: expected input parameter of type Array');
        }

        return tarifParameters.filter(tp => !angular.isString(tp.name) || !tp.name.trim());
    }

    public hasParameterWithoutName(tarifParameters: TarifParameter[]): boolean {
        return this.getParameterWithoutName(tarifParameters).length > 0;
    }

    public hasMonatsBasierteParams(tarifParameters: TarifParameter[]): boolean {
        if (!Array.isArray(tarifParameters)) {
            throw new Error('removeParameterWithoutName: expected input parameter of type Array');
        }

        return tarifParameters.some(tp => tp.dtype === 'MONATS_BASIERT');
    }

    public getTempBlobForTarif(dtype: TarifType, title: string, params: string[]): angular.IPromise<TempBlob> {
        const matrixParams: MatrixParams = {};
        matrixParams.dtype = dtype;
        matrixParams.title = title;
        matrixParams.param = params;

        const url = `${TarifService.BASE_URL}/downloadDefaultTarifTemplate`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams).then(checkPresent);
    }

    public getKinderOrte<T extends KinderOrt>(
        tarifId: string,
        params?: RestInclude & RestCache,
    ): angular.IPromise<KinderOrt[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const transformer = KinderOrtTransformer.create<T>();

        return DvbRestUtilAngularJS.getModelsArray(
            `${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/kinderOrte/all`,
            transformer,
            'items',
            matrixParams);
    }

    public getFirmenKontingente(
        tarifId: string,
        params?: RestInclude & RestCache,
    ): angular.IPromise<FirmenKontingent[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(
            `${TarifService.BASE_URL}/${encodeURIComponent(tarifId)}/firmenkontingente/all`,
            FirmenKontingent,
            'firmenKontingente',
            matrixParams);
    }
}
