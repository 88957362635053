/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpEvent, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injector} from '@angular/core';
import {XSRF_HEADER_NAME} from '@dv/shared/authentication/model';
import {HttpCodes, LogFactory} from '@dv/shared/code';
import {ConfigService} from '@dv/shared/config';
import type {Observable} from 'rxjs';
import {catchError, throwError} from 'rxjs';

const LOG = LogFactory.createLog('authentication.interceptor');

export type ErrorHandlerStrategy = (error: HttpErrorResponse) => Observable<HttpEvent<unknown>>;

export type ErrorHandlerStrategyFactory = (
    req: HttpRequest<unknown>,
    next: (req: HttpRequest<unknown>) => Observable<HttpEvent<unknown>>,
    injector: Injector,
) => ErrorHandlerStrategy;

export function authenticationInterceptor(
    withUnauthenticatedStrategy: ErrorHandlerStrategyFactory,
    withForbiddenStrategy: ErrorHandlerStrategyFactory,
): HttpInterceptorFn {
    return (req, next) => {
        const configService = inject(ConfigService);
        const loginURLs = [
            `${configService.restBackend}/api/v1/auth/login`,
            `${configService.restBackend}/api/v1/auth/token-login`,
        ];
        const injector = inject(Injector);

        const forbiddenStrategy = withForbiddenStrategy(req, next, injector);

        function errorHandler$(error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
            // noinspection BlockStatementJS
            switch (error.status) {
                case HttpCodes.UNAUTHORIZED: {
                    LOG.trace(error.status, error.message, error.error);
                    if (error.url && loginURLs.some(urlEnding => error.url?.endsWith(urlEnding))) {
                        return throwError(() => error);
                    }

                    // Remove outdated XSRF-Token from request headers, valid XSRF-Token will be set by
                    // HTTP_XSRF_INTERCEPTOR
                    const retryRequest = req.clone({headers: req.headers.delete(XSRF_HEADER_NAME)});
                    const unauthenticatedStrategy = withUnauthenticatedStrategy(retryRequest, next, injector);

                    return unauthenticatedStrategy(error);
                }
                case HttpCodes.FORBIDDEN:
                    LOG.trace(error.status, error.message, error.error);

                    return forbiddenStrategy(error);
                default:
                    return throwError(() => error);
            }
        }

        return next(req).pipe(
            catchError((error: unknown) => error instanceof HttpErrorResponse ?
                errorHandler$(error) :
                throwError(() => error)),
        );
    };
}
