/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Belegung, ExtraPlatz} from '@dv/kitadmin/models';
import {BelegungInterval, Betreuungsfaktor, ExtraPlatzWochenBelegung} from '@dv/kitadmin/models';
import type {ILimited} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, isPresent} from '@dv/shared/code';
import moment from 'moment';

const NUM_WEEKDAYS = 7;

export class BetreuungsVerlaufService {
    public static $inject: readonly string[] = [];

    private static affects(platz: ExtraPlatz, entity: ILimited): boolean {
        return checkPresent(platz.affectedDay).isBetween(entity.gueltigAb, entity.gueltigBis, undefined, '[]');
    }

    private static createBelegungInterval(extraPlatzWochenBelegung: ExtraPlatzWochenBelegung): BelegungInterval {
        const interval =
            new BelegungInterval(extraPlatzWochenBelegung.gueltigAb, extraPlatzWochenBelegung.gueltigBis);
        interval.extraPlatzWochenBelegungen.push(extraPlatzWochenBelegung);

        return interval;
    }

    private static sortExtraPlatzWochenBelegungen(interval: BelegungInterval): void {
        interval.extraPlatzWochenBelegungen
            .sort((a, b) => b.gueltigAb.diff(a.gueltigAb));
    }

    /**
     * Builds an array of intervals based on kind belegungen. The intervals group belegungen from the same
     * monatsBelegung together. The created intervals are sorted by gueltigAb date.
     */
    public buildBelegungIntervals(
        kindBelegungen: Belegung[],
        extraPlaetze: ExtraPlatz[] = [],
        isAusgetreten: boolean = false,
        wochenBelegungen: Belegung[] = [],
    ): BelegungInterval[] {
        let monatsBelegungId: string | null = null;
        let belegungInterval: BelegungInterval | null = null;

        const intervals = kindBelegungen.slice()
            .sort((a, b) => checkPresent(b.gueltigAb).diff(checkPresent(a.gueltigAb)))
            .reduce((acc: BelegungInterval[], current) => {
                const id = current.monatsBelegungId;

                if (!monatsBelegungId || id !== monatsBelegungId || !belegungInterval) {
                    // noinspection ReuseOfLocalVariableJS
                    monatsBelegungId = id;
                    // noinspection ReuseOfLocalVariableJS
                    belegungInterval = new BelegungInterval(
                        checkPresent(current.gueltigAb),
                        checkPresent(current.gueltigBis),
                        current.belegungsZustand,
                        id);

                    if (current.betreuungsfaktor) {
                        belegungInterval.betreuungsfaktor = new Betreuungsfaktor(current.betreuungsfaktor);
                        belegungInterval.betreuungsfaktor.spezifisch = true;
                    }

                    if (current.kindergartenBelegung) {
                        belegungInterval.kindergartenBelegung = current.kindergartenBelegung;
                    }

                    if (current.bemerkung) {
                        belegungInterval.bemerkung = current.bemerkung;
                    }

                    acc.push(belegungInterval);
                }

                belegungInterval.belegungen.push(current);

                current.gruppenBelegungen.forEach(gb => belegungInterval!.targetFraktionIds.add(gb.gruppeId!));

                // Extend date range.
                //
                // Once all belegungen have been added, we can replicate the monatsBelegung date range without
                // actually fetching the monatsBelegung. And since we are working our ways from the future to the
                // past, we have to extend gueltigAb.
                belegungInterval.gueltigAb = moment.min(belegungInterval.gueltigAb, checkPresent(current.gueltigAb));

                return acc;
            }, []);

        this.addAustrittIntervals(kindBelegungen, isAusgetreten, intervals);
        this.addExtraPlaetzeToIntervals(intervals, extraPlaetze, wochenBelegungen);

        return intervals.sort((a, b) => b.gueltigAb.diff(a.gueltigAb));
    }

    private addAustrittIntervals(
        kindBelegungen: Belegung[],
        isAusgetreten: boolean,
        intervals: BelegungInterval[],
    ): void {
        const orderedBelegungen = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(kindBelegungen.slice());

        orderedBelegungen.forEach((current, index) => {
            const next = orderedBelegungen[index + 1];
            const isAustritt = index === orderedBelegungen.length - 1 ?
                isAusgetreten :
                next.gueltigAb!.diff(current.gueltigBis, 'days') > 1;

            if (!isAustritt) {
                return;
            }

            const austrittInterval = new BelegungInterval(
                current.gueltigBis!,
                current.gueltigBis!,
                null,
                null);
            austrittInterval.isAustritt = true;
            austrittInterval.belegungen.push(current);
            intervals.push(austrittInterval);
        });
    }

    private addExtraPlaetzeToIntervals(
        intervals: BelegungInterval[],
        extraPlaetze: ExtraPlatz[],
        wochenBelegungen: Belegung[],
    ): void {
        if (wochenBelegungen.length === 0) {
            return;
        }

        const belegungIntervals = intervals.filter(interval => !interval.isAustritt);

        wochenBelegungen
            .flatMap(wb => {
                const plaezteInWochenBelegung = extraPlaetze.filter(ep => BetreuungsVerlaufService.affects(ep, wb));
                if (plaezteInWochenBelegung.length === 0) {
                    return [];
                }

                const wbAb = wb.gueltigAb!;
                const wbBis = wb.gueltigBis!;
                if (wbBis.diff(wbAb, 'days') <= NUM_WEEKDAYS) {
                    return [
                        this.toExtraPlatzWochenBelegung(wb, wbAb, wbBis, plaezteInWochenBelegung, belegungIntervals),
                    ];
                }

                const weekMap: { [isoWeek: number]: ExtraPlatz[] } = {};
                plaezteInWochenBelegung.forEach(p => {
                    const isoWeek = p.affectedDay!.isoWeek();
                    weekMap[isoWeek] ??= [];
                    weekMap[isoWeek].push(p);
                });

                return Object.values(weekMap).flatMap(extraPlaetzeInSameWeek => {
                    const ab = DvbDateUtil.adjustToBeginOfWeek(moment(extraPlaetzeInSameWeek[0].affectedDay));
                    const bis = DvbDateUtil.adjustToEndOfWeek(moment(extraPlaetzeInSameWeek[0].affectedDay));

                    return this.toExtraPlatzWochenBelegung(wb, ab, bis, extraPlaetzeInSameWeek, belegungIntervals);
                });
            })
            .filter(isPresent)
            .map(extraWb => BetreuungsVerlaufService.createBelegungInterval(extraWb))
            .forEach(newInterval => {
                intervals.push(newInterval);
            });

        belegungIntervals.forEach(interval => BetreuungsVerlaufService.sortExtraPlatzWochenBelegungen(interval));
    }

    private toExtraPlatzWochenBelegung(
        wb: Belegung,
        wbAb: moment.Moment,
        wbBis: moment.Moment,
        plaezteInWochenBelegung: ExtraPlatz[],
        belegungIntervals: BelegungInterval[],
    ): ExtraPlatzWochenBelegung | null {

        const extraPlatzWochenBelegung = new ExtraPlatzWochenBelegung(wb, wbAb, wbBis, plaezteInWochenBelegung);
        const existingIntervals = DvbDateUtil.getEntitiesIn(belegungIntervals, wbAb, wbBis);
        if (existingIntervals.length === 0) {
            return extraPlatzWochenBelegung;
        }

        this.addToIntervals(existingIntervals, extraPlatzWochenBelegung);

        return null;
    }

    private addToIntervals(existingIntervals: BelegungInterval[], belegung: ExtraPlatzWochenBelegung): void {
        existingIntervals.forEach(interval => {
            interval.extraPlatzWochenBelegungen.push(belegung);
        });
    }
}
