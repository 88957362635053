/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, KinderOrt, StundenKontingent} from '@dv/kitadmin/models';
import {FormContext} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        stundenKontingent: '<',
        kind: '<',
        kinderOrt: '<',
        onUpdate: '&',
        onDelete: '&',
    },
    template: require('./dvb-stunden-kontingent-content.html'),
    controllerAs: 'vm',
};

export class DvbStundenKontingentContent implements angular.IController {
    public static $inject: readonly string[] = [];

    public stundenKontingent!: StundenKontingent;
    public kind!: Kind;
    public kinderOrt!: KinderOrt;
    public onUpdate!: (data: { stundenKontingent: StundenKontingent; formContext: FormContext }) => void;
    public onDelete!: (data: { stundenKontingent: StundenKontingent; formContext: FormContext }) => void;

    public formContext: FormContext = new FormContext();
}

componentConfig.controller = DvbStundenKontingentContent;
angular.module('kitAdmin').component('dvbStundenKontingentContent', componentConfig);
