/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import {checkPersisted} from '@dv/shared/code';
import type {Ausbildung} from '../anstellung/models/Ausbildung';

export class AusbildungUtil {
    /**
     * Stores ausbildungen and their children mapped to their id in the given ausbildungenById object.
     */
    public static mapAusbildungenById(
        ausbildung: Persisted<Ausbildung>,
        ausbildungenById: { [id: string]: Persisted<Ausbildung> },
    ): void {
        ausbildungenById[ausbildung.id] = ausbildung;

        if (!Array.isArray(ausbildung.children)) {
            return;
        }
        ausbildung.children.forEach(child => {
            AusbildungUtil.mapAusbildungenById(checkPersisted(child), ausbildungenById);
        });
    }
}
