/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DisplayTask} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        label: '@',
        popoverTitle: '@',
        warning: '<',
        tasks: '<',
        popoverDisable: '<',
    },
    template: require('./dvb-tasks-badge.html'),
    controllerAs: 'vm',
};

export class DvbTasksBadge {
    public static $inject: readonly string[] = [];

    public label!: string;
    public popoverTitle!: string;
    public warning!: boolean;
    public tasks!: DisplayTask[];
    public popoverDisable!: boolean;
}

componentConfig.controller = DvbTasksBadge;
angular.module('kitAdmin').component('dvbTasksBadge', componentConfig);
