/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {ILimited, IPersistable} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';

export enum MonatsblattApiCommunicationStatus {
    VERSANDT = 'VERSANDT',
    VERAENDERT = 'VERAENDERT',
    FAILED = 'FAILED',
}

export class MonatsblattApiSendStatus implements IPersistable, ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public betreuungsPersonId: string | null = null,
        public status: MonatsblattApiCommunicationStatus | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): MonatsblattApiSendStatus {
        return new MonatsblattApiSendStatus(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.betreuungsPersonId,
            data.status,
        );
    }
}
