/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitadminModul} from '@dv/shared/backend/model/kitadmin-modul';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        modules: '<',
    },
    template: require('./dvb-module-list.html'),
    controllerAs: 'vm',
};

export class DvbModuleList implements angular.IController {

    public static $inject: readonly string[] = ['$translate'];

    public modules: KitadminModul[] = [];

    public moduleList?: string;

    public constructor(
        public readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onInit(): void {
        this.moduleList = this.modules.length <= 0 ?
            this.$translate.instant('MANDANT.NO_MODULES') :
            this.modules.map(module => this.$translate.instant(`MANDANT.MODULE.${module}`)).join(', ');
    }
}

componentConfig.controller = DvbModuleList;
angular.module('kitAdmin').component('dvbModuleList', componentConfig);
