/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {BenutzerFormModel} from './benutzer-form.model';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
        allowPasswordChange: '<?',
        allowGroupAdmin: '<?',
    },
    template: require('./dvb-benutzer-form.html'),
    controllerAs: 'vm',
};

export class DvbBenutzerForm {

    public benutzer!: BenutzerFormModel;
    public allowPasswordChange: boolean = true;
    public allowGroupAdmin: boolean = false;
}

componentConfig.controller = DvbBenutzerForm;
angular.module('kitAdmin').component('dvbBenutzerForm', componentConfig);
