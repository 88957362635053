<div class="dvb-firma-stammdaten">
    <!-- STAMMDATEN -->
    <h2 data-translate="COMMON.PLATZART.PLATZART_STAMMDATEN"></h2>

    <div class="row">
        <div class="col-lg-9 col-md-12">
            <dvb-editable-label ng-model="vm.firma.name"
                                maxlength="255"
                                is-valid="vm.isRequired(param)"
                                ng-attr-placeholder="{{'COMMON.PLATZART.SINGULAR' | translate}}"
                                ng-change="vm.saveBasisData()"
                                dvb-auth
                                require-permission="kita:betreuung_admin:*"
                                on-unauthorised-disable>
            </dvb-editable-label>
        </div>
        <div class="col-lg-3 col-md-12">
            <dvb-editable-label ng-model="vm.firma.symbol"
                                maxlength="1"
                                minlength="1"
                                is-valid="vm.isRequired(param)"
                                ng-attr-placeholder="{{'COMMON.PLATZART.SYMBOL' | translate}}"
                                ng-change="vm.saveBasisData()"
                                dvb-auth
                                require-permission="kita:betreuung_admin:*"
                                on-unauthorised-disable>
            </dvb-editable-label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           ng-model="vm.firma.firmenPlatz"
                           ng-change="vm.saveFirmenPlatz()">
                    <span data-translate="COMMON.FIRMENPLATZ.SINGULAR"></span>
                </label>
            </div>
        </div>
        <div class="col-md-12" dvb-auth require-permission="module:bsv">
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           ng-model="vm.firma.bsvRelevant"
                           ng-change="vm.saveFirmenPlatz()">
                    <span data-translate="COMMON.FIRMENPLATZ.BSV_RELEVANT"></span>
                </label>
            </div>
        </div>
    </div>

    <div ng-if="!vm.firma.firmenPlatz">
        <button ng-if="!vm.isAddAlternativeKibonAbzugEnabled"
                class="btn btn-link form-linker"
                type="button"
                ng-click="vm.isAddAlternativeKibonAbzugEnabled = !isAddAlternativeKibonAbzugEnabled">
            + <span data-translate="COMMON.PLATZART.ALTERNATIVE_KIBON_ABZUG_DEFINIEREN"></span>
        </button>

        <div class="row" ng-if="vm.isAddAlternativeKibonAbzugEnabled">
            <div class="col-xs-6 col-lg-9">
                <dvb-editable-label ng-model="vm.firma.alternativeKibonAbzug"
                                    type="number"
                                    min="0"
                                    step=".01"
                                    max="20.00"
                                    ng-attr-placeholder="{{'COMMON.PLATZART.ALTERNATIVE_KIBON_ABZUG_DEFINIEREN' | translate}}"
                                    label="{{'KIBON_ABZUG.ABZUG_DISPLAY' | translate:{abzug: vm.firma.alternativeKibonAbzug} }}"
                                    is-valid="vm.isValidKibonAbzug(param)"
                                    ng-change="vm.saveBasisData()"
                                    dvb-auth
                                    require-permission="kita:betreuung_admin:*"
                                    on-unauthorised-disable>
                </dvb-editable-label>
            </div>
            <div class="col-xs-6 col-lg-3">
                <p class="form-linker">
                    <a href=""
                       ng-click="vm.removeKibonAbzugProTag()"
                       data-translate="COMMON.LOESCHEN">
                    </a>
                </p>
            </div>
        </div>
    </div>

    <div class="firmenKontakt" ng-if="vm.firma.firmenPlatz">
        <div ng-if="vm.firma.kontakt !== null">
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label ng-model="vm.firma.kontakt.familienName"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label ng-model="vm.firma.kontakt.vorName"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>

            <dvb-adresse ng-click="vm.isAdresseEditMode = true"
                         adresse="vm.firma.kontakt.adresse"
                         display-type="long"
                         on-save="vm.saveAdresse(adresse)"
                         click-to-edit="true"
                         show-edit-mode="vm.isAdresseEditMode"
                         dvb-auth
                         require-permission="kita:betreuung_admin:*"
                         on-unauthorised-disable>
            </dvb-adresse>

            <div class="row small-abstand-top">
                <div class="col-md-12">
                    <dvb-editable-label ng-model="vm.firma.kontakt.telefon"
                                        maxlength="100"
                                        is-valid="vm.isRequired(param)"
                                        ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <dvb-editable-label ng-model="vm.firma.kontakt.email"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                                        is-email="true"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <dvb-editable-label ng-model="vm.firma.kontakt.website"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.WEBSITE' | translate}}"
                                        is-link="true"
                                        ng-change="vm.saveBasisData()"
                                        dvb-protocol-prepender
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
        </div>
        <div ng-if="vm.firma.kontakt === null">
            <dvb-firmen-kontakt-erstellen firma="vm.firma"
                                          on-submit="vm.saveBasisData()"
                                          on-cancel="vm.cancel()">
            </dvb-firmen-kontakt-erstellen>
        </div>
    </div>

    <div dvb-auth
         require-permission="feature:external_anmeldung:general"
         class="normal-abstand-top">
        <h2 data-translate="EXTERNAL_ANMELDUNG.CONFIG.TITLE"></h2>

        <div class="checkbox">
            <label uib-tooltip="{{'COMMON.PLATZART.SHOW_ON_EXTERNE_ANMELDUNG' | translate}}"
                   tooltip-placement="top-left">
                <input type="checkbox"
                       value="false"
                       ng-model="vm.firma.externalAnmeldung"
                       ng-change="vm.saveBasisData()"
                       dvb-auth
                       require-permission="{{['kita:administrate:general', 'feature:external_anmeldung:general']}}"
                       on-unauthorised-disable>
                <span data-translate="COMMON.PLATZART.SHOW_ON_EXTERNE_ANMELDUNG"></span>
            </label>
        </div>
        <div ng-if="vm.firma.externalAnmeldung" class="checkbox-indent">
            <dvb-translatable-editable-label translation="vm.firma.externalAnmeldungName"
                                             on-Submit="vm.saveBasisData()"
                                             max-length="255"
                                             dvb-auth
                                             require-permission="{{['kita:administrate:general', 'feature:external_anmeldung:general']}}"
                                             on-unauthorised-disable>
            </dvb-translatable-editable-label>
        </div>
    </div>
</div>
