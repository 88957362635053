<div class="dvb-editable-select">

    <script type="text/ng-template" id="dvbEditableSelectTemplate.html">
        <a href="" ng-bind="match.label"></a>
    </script>

    <dvb-click2-edit edit-mode="vm.editMode">
        <label-part>
            <label class="form-control click2edit" ng-click="vm.edit()" ng-bind="vm.label"></label>
        </label-part>
        <content-part>
            <div class="input-group">
                <input type="text"
                       dvb-focus-if="vm.editMode"
                       autocomplete="off"
                       ng-keyup="vm.cancelEdit($event)"
                       ng-focus="vm.onFocus($event)"
                       ng-model="vm.workingCopy"
                       maxlength="100"
                       class="form-control"
                       ng-attr-placeholder="{{vm.label}}"
                       uib-typeahead="option as option.name for option in vm.options | filter: {name: $viewValue} | orderBy:'name'"
                       typeahead-focus-on-select="false"
                       typeahead-on-select="vm.onSelect($item, $model, $label, $event)"
                       typeahead-template-url="dvbEditableSelectTemplate.html"
                       typeahead-editable="false"
                       typeahead-select-on-exact="true"
                       typeahead-min-length="0"
                       typeahead-select-on-blur="false"
                       typeahead-focus-first="false">
                <div class="input-group-btn">
                    <button class="btn btn-default"
                            type="button"
                            ng-click="vm.clear()">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
        </content-part>
    </dvb-click2-edit>
</div>
