/**
 * The following features are still outstanding: popup delay, animation as a
 * function, placement as a function, inside, support for more triggers than
 * just mouse enter/leave, and selector delegatation.
 */
angular.module('ui.bootstrap.popover', ['ui.bootstrap.tooltip']).directive('uibPopoverTemplatePopup', function () {
  return {
    restrict: 'A',
    scope: {
      uibTitle: '@',
      contentExp: '&',
      originScope: '&'
    },
    templateUrl: 'uib/template/popover/popover-template.html'
  };
}).directive('uibPopoverTemplate', ['$uibTooltip', function ($uibTooltip) {
  return $uibTooltip('uibPopoverTemplate', 'popover', 'click', {
    useContentExp: true
  });
}]).directive('uibPopoverHtmlPopup', function () {
  return {
    restrict: 'A',
    scope: {
      contentExp: '&',
      uibTitle: '@'
    },
    templateUrl: 'uib/template/popover/popover-html.html'
  };
}).directive('uibPopoverHtml', ['$uibTooltip', function ($uibTooltip) {
  return $uibTooltip('uibPopoverHtml', 'popover', 'click', {
    useContentExp: true
  });
}]).directive('uibPopoverPopup', function () {
  return {
    restrict: 'A',
    scope: {
      uibTitle: '@',
      content: '@'
    },
    templateUrl: 'uib/template/popover/popover.html'
  };
}).directive('uibPopover', ['$uibTooltip', function ($uibTooltip) {
  return $uibTooltip('uibPopover', 'popover', 'click');
}]);