/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    Belegung,
    ExtraPlatz,
    FirmenKontingent,
    Kind,
    KinderOrt,
    KinderOrtFraktion,
    KinderOrtId,
    Kontingente,
    RestCache,
    RestTimeout,
    SubventioniertesKontingent,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import {ExtraPlatzCategory, ExtraPlatzType, ZeitraumUtil} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {JaxKindAbweichungsMeldung} from '@dv/shared/backend/model/jax-kind-abweichungs-meldung';
import type {DayOfWeek, Persisted, RestInclude, RestLimited, SearchResultEntry} from '@dv/shared/code';
import {
    BEGIN_OF_TIME,
    checkPresent,
    DvbDateUtil,
    DvbError,
    DvbRestUtil,
    DvbUtil,
    Gueltigkeit,
    isNullish,
} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {TempExtraPlatz} from 'src/app/temp-extra-platz/TempExtraPlatz';
import {ZeitraumFeldClickModifier} from '../../../common/directive/dvb-wochenplan/ZeitraumFeldClickModifier';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {ExtraPlatzService} from '../../../common/service/rest/kind/extraPlatzService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import {TempExtraPlatzBuilder} from '../../../common/service/tempExtraPlatzBuilder';
import {WochenplanUtil} from '../../../common/service/wochenplanUtil';
import {BewerbungStrategy} from '../../../kinderort/component/dvb-kita-kinder/BewerbungStrategy';
import {ExtraPlatzCreationUtil} from '../../service/extraPlatzCreationUtil';
import {ZuweisungPopoverHelper} from '../../zuweisung';
import ClickEvent = JQuery.ClickEvent;

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kindId: '<',
        startDate: '<?',
        meldung: '<?',
        tempExtraPlaetze: '<?',
    },
    template: require('./dvb-extra-platz-creation.html'),
    controllerAs: 'vm',
};

/* eslint-disable max-lines */
export class DvbExtraPlatzCreation implements angular.IController {
    public static $inject: readonly string[] = [
        'kindService',
        'kinderOrtService',
        'extraPlatzService',
        '$q',
        '$document',
        '$scope',
        '$state',
        'errorService',
        'dvbStateService',
        'fraktionService',
        'authStore',
    ];

    private static readonly WOCHENPLAN_INCLUDES: string = 'wochenplan.fields(' +
        'tagesplaene.fields(belegungsEinheiten.fields(zeitraumIds)), zeitraeume)';
    private static readonly GRP_INCLUDES: string = `gruppenWochenBelegungen.fields(wochenPlaetze),
    ${DvbExtraPlatzCreation.WOCHENPLAN_INCLUDES})`;

    public kindId!: string;
    public kind: Persisted<Kind> | null = null;
    public startDate: moment.Moment | null = null;
    public meldung?: Persisted<JaxKindAbweichungsMeldung>;
    public tempExtraPlaetze: ExtraPlatz[] = [];

    public firstOfWeek!: moment.Moment;
    public kinderOrtSearchResult: SearchResultEntry | null = null;
    public previousKinderOrtSearchResult: SearchResultEntry | null = null;
    public hasPermissionsForKinderOrt: boolean = true;

    public weekDays: DayOfWeek[] = [];
    public isLoading: boolean = false;
    public isSaving: boolean = false;
    public currentOrtFraktionen: Persisted<KinderOrtFraktion>[] = [];
    public currentPlaetzeFraktionen: Persisted<KinderOrtFraktion>[] = [];

    private kinderOrt: Persisted<KinderOrt> | null = null;
    private belegung: Belegung | null = null;
    private tempExtraPlatzBuilder: TempExtraPlatzBuilder = new TempExtraPlatzBuilder();

    private popoverHelper!: ZuweisungPopoverHelper;
    private lastSelectedKontingent: Kontingente | null = null;
    private extraPlatzCreationUtil!: ExtraPlatzCreationUtil;

    private timeout?: angular.IDeferred<any>;

    public constructor(
        private kindService: KindService,
        private kinderOrtService: KinderOrtService,
        private extraPlatzService: ExtraPlatzService,
        private $q: angular.IQService,
        private $document: angular.IDocumentService,
        private $scope: angular.IScope,
        private $state: StateService,
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
        private fraktionService: FraktionService,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.popoverHelper = new ZuweisungPopoverHelper(this.$document, this.$scope);
        this.popoverHelper.init();

        this.extraPlatzCreationUtil = new ExtraPlatzCreationUtil(this.tempExtraPlatzBuilder, this.popoverHelper);
    }

    public $onChanges(): void {
        this.firstOfWeek = DvbDateUtil.startOfWeek(this.startDate ? moment(this.startDate) : moment());
        const lastOfWeek = DvbDateUtil.endOfWeek(moment(this.firstOfWeek));

        this.tempExtraPlatzBuilder.gueltigkeit = new Gueltigkeit(
            DvbDateUtil.startOfMonth(moment(this.firstOfWeek)),
            DvbDateUtil.endOfMonth(lastOfWeek));
        this.tempExtraPlatzBuilder.initFilteredTempPlaetze();

        this.isLoading = true;
        this.initBelegung().then(() => {
            this.setKinderOrtFromBelegung().then(() => {
                this.isLoading = false;
            });
        });
    }

    public $onDestroy(): void {
        this.popoverHelper.destroy();
    }

    public kinderOrtChanged(): void {
        this.hasPermissionsForKinderOrt = true;

        this.initPermissions();
        this.initFraktionenAndBelegung();
    }

    public initFraktionenAndBelegung(): void {
        this.isLoading = true;
        this.initBelegung()
            .then(() => {
                this.currentOrtFraktionen = [];

                // Set the kita based on the belegung, when there is no explicit search result.
                // An available previous result means, that the result got deleted --> do not set it again in that case
                if (!this.previousKinderOrtSearchResult && !this.kinderOrtSearchResult) {
                    return this.setKinderOrtFromBelegung().then(() => {
                        this.isLoading = false;
                    });
                }

                this.previousKinderOrtSearchResult = this.kinderOrtSearchResult;

                if (!this.kinderOrtSearchResult || !this.hasPermissionsForKinderOrt) {
                    this.isLoading = false;

                    return this.$q.resolve();
                }

                return this.kinderOrtService.get(this.kinderOrtSearchResult.id, this.getFraktionenLoadingParams())
                    .then(kinderOrt => this.withAllKontingente(kinderOrt))
                    .then(kinderOrt => {
                        this.kinderOrt = kinderOrt;
                        this.initFraktionen(kinderOrt);
                        this.isLoading = false;
                    })
                    .catch(error => this.handleRequestCancellation(error));
            });
    }

    public onFeldClicked(
        zeitraumFeld: ZeitraumFeld,
        event: ClickEvent,
        modifier: ZeitraumFeldClickModifier,
        fraktion: Persisted<KinderOrtFraktion>,
    ): void {
        if (modifier === ZeitraumFeldClickModifier.LEFT) {
            this.onFeldLeftClicked(zeitraumFeld, event, fraktion);
        } else {
            this.onFeldRightClicked(zeitraumFeld, event, fraktion);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    public setPrivat(): void {
        const zeitraumFeld = this.selectFeldAndClosePopover(null);
        WochenplanUtil.setPrivat(zeitraumFeld);
    }

    public setSubventioniert(kontingent: SubventioniertesKontingent): void {
        const zeitraumFeld = this.selectFeldAndClosePopover(kontingent);
        WochenplanUtil.setSubventioniert(zeitraumFeld, kontingent);
    }

    // noinspection JSUnusedGlobalSymbols
    public setFirma(kontingent: FirmenKontingent): void {
        const zeitraumFeld = this.selectFeldAndClosePopover(kontingent);
        WochenplanUtil.setFirma(zeitraumFeld, kontingent);
    }

    // noinspection JSUnusedGlobalSymbols
    public removePlatz(platz: TempExtraPlatz): void {
        const felder = platz.getFelder().slice();

        // remove temp entries
        const removedAdditions = this.tempExtraPlatzBuilder.remove(platz);

        // make sure the zeitraumfelder of the absence as well as any removed additions are no longer selected
        this.extraPlatzCreationUtil.updateWochenplanAfterAbsenceRemoval(
            this.currentPlaetzeFraktionen,
            felder,
            platz.fraktionId,
            removedAdditions);
        const fraktion = this.currentPlaetzeFraktionen.find(f => f.id === platz.fraktionId);
        if (!fraktion) {
            return;
        }

        fraktion.wochenplan!.zeitraumFelder
            .filter(feld => felder.find(f => f.equals(feld)))
            .filter(feld => {
                const feldDate = DvbDateUtil.getDayOfWeekMoment(feld.dayOfWeek, this.firstOfWeek);

                return feldDate.isSame(platz.affectedDay);
            })
            .forEach(feld => {
                ZeitraumUtil.removeExtraPlatzSelection(feld, platz.extraPlatzType);
            });
    }

    public flagPlatzUpdated(platz: TempExtraPlatz): void {
        this.tempExtraPlatzBuilder.flagPlatzUpdated(platz);
    }

    public create(): void {
        this.isSaving = true;
        try {
            const extraPlaetze = this.tempExtraPlatzBuilder.create(
                this.currentPlaetzeFraktionen,
                checkPresent(this.kind));

            if (extraPlaetze.newPlaetze.length === 0 && extraPlaetze.deletedPlaetze.length === 0) {
                this.isSaving = false;
                this.cancel();

                return;
            }

            const affectedDays = extraPlaetze.newPlaetze.map(p => checkPresent(p.affectedDay));
            const expandAb = DvbUtil.isNotEmptyArray(affectedDays) ? moment.min(affectedDays) : undefined;
            const expandBis = DvbUtil.isNotEmptyArray(affectedDays) ? moment.max(affectedDays) : undefined;

            this.extraPlatzService.modifyExtraPlaetze(checkPresent(this.kind).id, extraPlaetze, this.meldung?.id)
                .then(() => {
                    if (isNullish(this.meldung)) {
                        this.$state.go('^.betreuung', {expandAb, expandBis}, {reload: true});
                    } else {
                        this.dvbStateService.goToPreviousState();
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                });

        } catch (err) {
            if (err) {
                if (err instanceof DvbError) {
                    this.errorService.handleError(err);
                    this.isSaving = false;
                } else {
                    throw err;
                }
            }
        }
    }

    public cancel(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    public initFilteredTempPlaetze(): void {
        this.tempExtraPlatzBuilder.initFilteredTempPlaetze();
    }

    private initBelegung(): angular.IPromise<unknown> {
        const params = {
            includes: '(extraPlatz.fields(belegungsEinheit.fields(zeitraumIds),belegungsEinheitId,kontingent),' +
                'kindWochenBelegungen.fields(gruppenBelegungen.fields(pensum,gruppeId,vertraglichePensen,' +
                'plaetze.fields(belegungsEinheitId,kontingentId))))',
            gueltigAb: this.firstOfWeek,
            gueltigBis: DvbDateUtil.endOfWeek(moment(this.firstOfWeek)),
        };

        return this.kindService.get(checkPresent(this.kindId), params).then(kind => {
            this.kind = kind;
            this.belegung = DvbDateUtil.getEntityOn(this.kind.kindWochenBelegungen, this.firstOfWeek);
        });
    }

    private onFeldLeftClicked(
        zeitraumFeld: ZeitraumFeld,
        event: ClickEvent,
        fraktion: Persisted<KinderOrtFraktion>,
    ): void {
        // by default, the selection state is already updated. We want to control that ourselfs --> reverse it
        zeitraumFeld.selected = !zeitraumFeld.selected;
        event.stopPropagation();

        this.extraPlatzCreationUtil.handleFeldClick(zeitraumFeld,
            fraktion,
            this.firstOfWeek,
            this.belegung,
            this.currentPlaetzeFraktionen,
            event);
    }

    private onFeldRightClicked(zeitraumFeld: ZeitraumFeld, event: ClickEvent, fraktion: KinderOrtFraktion): void {
        // cancel automatic selection
        zeitraumFeld.selected = !zeitraumFeld.selected;
        event.preventDefault();

        if (zeitraumFeld.selected) {
            return;
        }

        const fraktionenWithBelegungen = this.extraPlatzCreationUtil.findFraktionWithBelegungForFeld(
            this.belegung,
            fraktion,
            zeitraumFeld);
        if (!this.tempExtraPlatzBuilder.isAdditionAllowed(zeitraumFeld, fraktionenWithBelegungen, this.firstOfWeek)) {
            return;
        }
        this.popoverHelper.popoverZuweisungContent.zeitraumFeld = zeitraumFeld;
        this.popoverHelper.popoverZuweisungContent.gruppe = fraktion;

        if (this.lastSelectedKontingent) {
            if (this.lastSelectedKontingent.isSubventioniertesKontingent()) {
                this.setSubventioniert(this.lastSelectedKontingent);
            } else {
                this.setFirma(this.lastSelectedKontingent);
            }
        } else {
            this.setPrivat();
        }
    }

    /**
     * Close the popover, store and mark the field as selected.
     */
    private selectFeldAndClosePopover(kontingent: Kontingente | null): ZeitraumFeld {
        this.lastSelectedKontingent = kontingent;
        this.popoverHelper.cancelPopover();

        const zeitraumFeld = checkPresent(this.popoverHelper.popoverZuweisungContent.zeitraumFeld);
        const fraktion = this.popoverHelper.popoverZuweisungContent.gruppe!;
        zeitraumFeld.backup = angular.copy(zeitraumFeld);
        zeitraumFeld.selected = true;

        const template = new TempExtraPlatz(
            fraktion.id!,
            DvbDateUtil.getDayOfWeekMoment(zeitraumFeld.dayOfWeek, this.firstOfWeek),
            ExtraPlatzCategory.STANDARD,
            ExtraPlatzType.ADDITIONAL);

        const updatedFraktionen = this.tempExtraPlatzBuilder.addTempExtraPlatz(template, zeitraumFeld, kontingent);

        // adding a feld to the TempExtraPlatzBuilder removes any equal felder
        // --> ensure those equal felder are not marked as selected any more
        this.currentPlaetzeFraktionen
            .filter(f => updatedFraktionen.has(f.id) && f.id !== fraktion.id)
            .forEach(currentFraktion => {
                currentFraktion.wochenplan!.zeitraumFelder.forEach(currentFeld => {
                    if (zeitraumFeld.equals(currentFeld)) {
                        currentFeld.selected = false;
                        currentFeld.active = false;
                    }
                });
            });

        return zeitraumFeld;
    }

    private getFraktionenLoadingParams(): RestLimited & RestInclude & RestCache & RestTimeout {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();

        return {
            cache: true,
            timeout: this.timeout.promise,
            gueltigAb: this.firstOfWeek,
            gueltigBis: DvbDateUtil.endOfWeek(moment(this.firstOfWeek)),
            includes: `(gruppen.fields(${DvbExtraPlatzCreation.GRP_INCLUDES}))`,
        };
    }

    private withAllKontingente(kinderOrt: Persisted<KinderOrt>): angular.IPromise<Persisted<KinderOrt>> {
        if (!this.hasKontingentPrivileges(kinderOrt.id)) {
            return this.$q.resolve(kinderOrt);
        }

        return this.getKinderOrtWithKontingente(kinderOrt.id)
            .then(k => {
                kinderOrt.firmenKontingente = k.firmenKontingente;
                kinderOrt.subventioniertesKontingent = k.subventioniertesKontingent;

                return kinderOrt;
            });
    }

    private getKinderOrtWithKontingente(kinderOrtId: KinderOrtId): angular.IPromise<Persisted<KinderOrt>> {
        return this.kinderOrtService.get(kinderOrtId, {cache: true, includes: '(kontingente)'});
    }

    private initFraktionen(kinderOrt: Persisted<KinderOrt>): void {
        const currentFraktionen = DvbDateUtil.getEntitiesIn(
            kinderOrt.gruppen,
            this.firstOfWeek,
            DvbDateUtil.endOfWeek(moment(this.firstOfWeek)));

        this.currentOrtFraktionen = DvbUtil.orderByDisplayName(currentFraktionen);
        this.weekDays = ZeitraumUtil.getWeekDaysFromGruppen(this.currentOrtFraktionen);

        const fraktionMap: { [key: string]: KinderOrtFraktion } = {};

        const kontingente = kinderOrt.getKontingente();
        const showKontingente = this.hasKontingentPrivileges(kinderOrt.id);
        const allowEdit = this.isEditAllowed(kinderOrt.id);

        this.currentOrtFraktionen.forEach(fraktion => {

            fraktionMap[fraktion.id] = fraktion;
            const strategy = new BewerbungStrategy();
            strategy.capacityOnly =
                this.authStore.hasPermission(`${PERMISSION.FEATURE.GRP_WEEK_CAPACITY}:${fraktion.kinderOrtId}`);

            ZeitraumUtil.setBelegungToZeitraumFelder(
                // we only load a single week --> there should be a single one
                fraktion.gruppenWochenBelegungen[0].betreuungsZeitraumBelegung,
                fraktion.wochenplan!.zeitraumFelder,
                strategy);

            this.initGruppenBelegung(fraktion, kontingente, showKontingente);
            this.tempExtraPlatzBuilder.initExistingExtraPlaetze(
                checkPresent(this.kind).extraPlaetze.concat(this.tempExtraPlaetze),
                fraktion,
                kontingente,
                allowEdit,
                showKontingente,
                this.firstOfWeek);
        });

        this.initAdditionalFraktionen(fraktionMap);
    }

    /**
     * Initialize the fraktionen of extra plaetze that are not part of the current kinderOrt
     *
     * @param fraktionMap a map of the fraktionen which have already been initialized
     */
    private initAdditionalFraktionen(fraktionMap: { [p: string]: KinderOrtFraktion }): void {
        this.currentPlaetzeFraktionen = this.currentOrtFraktionen.slice();

        const existingPlatzFraktionIds = checkPresent(this.kind).extraPlaetze
            .filter(platz => !fraktionMap[platz.kinderOrtFraktionId!])
            .map(platz => platz.kinderOrtFraktionId!);
        const tempPlatzFraktionIds = this.tempExtraPlatzBuilder.getTempPlatzFraktionen()
            .filter(fraktionId => !fraktionMap[fraktionId]);

        const additionalFraktionen = new Set(existingPlatzFraktionIds.concat(tempPlatzFraktionIds));

        const params = {cache: true};

        additionalFraktionen.forEach(fraktionId => {
            this.fraktionService.getWithWochenplan(fraktionId, params).then(fraktion => {
                const kinderOrtId = checkPresent(fraktion.kinderOrtId);
                const showKontingente = this.hasKontingentPrivileges(kinderOrtId);
                const kontingentePromise = showKontingente ?
                    this.getKinderOrtWithKontingente(kinderOrtId).then(k => k.getKontingente()) :
                    this.$q.resolve([]);

                kontingentePromise.then(kontingente => {
                    this.tempExtraPlatzBuilder.initExistingExtraPlaetze(
                        checkPresent(this.kind).extraPlaetze,
                        fraktion,
                        kontingente,
                        this.isEditAllowed(kinderOrtId),
                        showKontingente,
                        BEGIN_OF_TIME);
                    this.currentPlaetzeFraktionen.push(fraktion);
                });
            });
        });
    }

    private initGruppenBelegung(
        fraktion: KinderOrtFraktion,
        kontingente: Kontingente[],
        showKontingente: boolean,
    ): void {
        if (!this.belegung) {
            return;
        }

        const gruppenBelegung = DvbUtil.findFirst(this.belegung.gruppenBelegungen, gb => gb.gruppeId === fraktion.id);
        if (!gruppenBelegung) {
            return;
        }

        const icon = ZeitraumUtil.belegungsZustandToZeitraumFeldIcon(this.belegung.belegungsZustand);
        const wochenplan = fraktion.wochenplan!;

        WochenplanUtil.setGruppenBelegungToWochenplan(wochenplan, gruppenBelegung, kontingente, showKontingente, icon);

    }

    private setKinderOrtFromBelegung(): angular.IPromise<any> {
        if (!this.belegung?.gruppenBelegungen
            || this.belegung.gruppenBelegungen.length === 0
            || this.kinderOrtSearchResult) {
            return this.$q.resolve();
        }

        const params = this.getFraktionenLoadingParams();

        // find any KinderOrt with edit permissions - otherwise pick the first kinderOrt available
        const kinderOrtPromises = DvbUtil.uniqueArray(this.belegung.gruppenBelegungen.map(gb => gb.gruppeId!))
            .map(fraktionId => this.fraktionService.getKinderOrt(fraktionId, params));

        return this.$q.all(kinderOrtPromises).then(kinderOrte =>
            kinderOrte.find(kinderOrt => this.isEditAllowed(kinderOrt.id)) ?? kinderOrte[0],
        )
            .then(kinderOrt => this.withAllKontingente(kinderOrt))
            .then(kinderOrt => {
                this.kinderOrt = kinderOrt;
                this.kinderOrtSearchResult = this.kinderOrt.toSearchResultEntry();
                this.previousKinderOrtSearchResult = this.kinderOrtSearchResult;
                this.initPermissions();
                this.initFraktionen(kinderOrt);

                return kinderOrt;
            })
            .catch(error => this.handleRequestCancellation(error));
    }

    private handleRequestCancellation(error: any): angular.IPromise<any> {
        if (DvbRestUtil.isRequestCancelled(error)) {
            return this.$q.resolve();
        }
        this.isLoading = false;

        return this.$q.reject(error);
    }

    private hasKontingentPrivileges(kinderOrtId: string): boolean {
        return this.authStore.hasPermission(PERMISSION.KITA.VIEW_PLATZ_KONTINGENT_TYPE + kinderOrtId);
    }

    private isEditAllowed(kinderOrtId: string): boolean {
        return this.authStore.hasPermission(PERMISSION.KITA.MODIFY_EXTRA_PLATZ + kinderOrtId);
    }

    private initPermissions(): void {
        if (!this.kinderOrtSearchResult) {
            return;
        }

        this.hasPermissionsForKinderOrt = this.isEditAllowed(this.kinderOrtSearchResult.id);
    }
}

componentConfig.controller = DvbExtraPlatzCreation;
angular.module('kitAdmin').component('dvbExtraPlatzCreation', componentConfig);
