/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {LogFactory} from '@dv/shared/code';
import angular from 'angular';
import type {AuthEventService} from '../../authentication/service/auth-event.service';
import {directiveSubSink} from '../../common/directive/directive-scope-subsink';
import type {AuthorisationController} from './dvb-auth';

angular.module('kitAdmin').directive('requireRole', requireRole);

const LOG = LogFactory.createLog('requireRole');

requireRole.$inject = ['authStore', 'authEventService'];

function requireRole(authStore: AuthStore, authEventService: AuthEventService): angular.IDirective {
    return {
        restrict: 'A',
        require: 'dvbAuth',
        link: (scope, _element, attrs, ctrl: any): void => {
            const authCtrl: AuthorisationController = ctrl;

            authCtrl.grantRole(authStore.hasRole(attrs.requireRole));

            const subSink = directiveSubSink(scope);
            subSink.sink = authEventService.loginSuccess$.subscribe({
                next: () => authCtrl.grantRole(authStore.hasRole(attrs.requireRole)),
                error: error => LOG.error('failed to subscribe to AuthEvents.loginSuccess', error),
            });
        },
    };
}
