/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPersisted} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../../config';
import {Anstellung} from '../models/Anstellung';

export class AnstellungService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/anstellungen`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public get(anstellungId: string, params?: RestInclude): angular.IPromise<Persisted<Anstellung>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(AnstellungService.BASE_URL, Anstellung, anstellungId, params)
            .then(checkPersisted);
    }

    public delete(anstellungId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${AnstellungService.BASE_URL}/${encodeURIComponent(anstellungId)}`;

        return this.$http.delete(url);
    }

    public extend(id: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${AnstellungService.BASE_URL}/${encodeURIComponent(id)}/gueltigbis`;

        return this.$http.delete(url);
    }
}
