/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject} from '@angular/core';
import type {ErrorHandlerStrategyFactory} from '@dv/shared/authentication/http-interceptor';
import {AuthEventType} from '@dv/shared/authentication/model';
import {throwError} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {AuthEventService} from './auth-event.service';

export function withRetryOnLoginSuccess(): ErrorHandlerStrategyFactory {
    return (req, next, injector) => {
        const authEventService = injector.get(AuthEventService);

        return () => {
            authEventService.sendEvent({type: AuthEventType.notAuthenticated});

            return authEventService.loginSuccess$.pipe(
                take(1),
                switchMap(_ => next(req)),
            );
        };
    };
}

export function withNotifyNotAuthorised(): ErrorHandlerStrategyFactory {
    return () => {
        const authEventService = inject(AuthEventService);

        return error => {
            authEventService.sendEvent({type: AuthEventType.notAuthorised});

            return throwError(() => error);
        };
    };
}
