<div class="dvb-incomplete-recipients">

    <div class="panel panel-list-container">
        <div class="panel-heading"
             data-translate="COMMUNICATION.POST_RECIPIENTS"
             data-translate-value-count="{{vm.kontaktpersonen.length}}"></div>
        <div class="panel-list scrollable with-header">
            <div class="list-item"
                 ng-repeat="kontaktperson in vm.kontaktpersonen | orderBy:['familienName', 'vorName']">
                <div class="row floating-text">
                    <div class="col-xs-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.selected[kontaktperson.id]"
                                       ng-value="{{kontaktperson.id}}"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <a ui-sref="base.kontaktperson.profil({kontaktpersonId: kontaktperson.id})">
                                    <span ng-bind="kontaktperson.getDisplayName()"></span>
                                </a>
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <ul dvb-auth require-permission="kita:manage:general"
            class="list-inline small-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectAll(vm.kontaktpersonen)"
                        data-translate="COMMON.ALLE_AUSWAEHLEN">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectNone()"
                        data-translate="COMMON.KEINE_AUSWAEHLEN">
                </button>
            </li>
        </ul>
        <ul class="list-inline normal-abstand-top">
            <li>
                <dvb-loading-button type="button"
                                    is-loading="vm.isLoading"
                                    ng-disabled="vm.getSelectionCount() <= 0"
                                    on-click="vm.submit()">
                    <span data-translate="FAKTURA.HERUNTERLADEN"></span>
                </dvb-loading-button>
            </li>
        </ul>
    </div>

</div>
