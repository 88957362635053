/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type moment from 'moment';
import type {ILimited, FormContext, FunctionType} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        entityHeading: 'entityHeading',
        entityReadOnly: 'entityReadOnly',
        entityForm: '?entityForm',
    },
    bindings: {
        entity: '<',
        enableEditMode: '<?',
        previousEntity: '<?',
        first: '<',
        translationRoot: '<',
        enableActions: '<?',
        onRemove: '&',
        onRevert: '&',
        onSubmit: '&',
        onEdit: '&?',
    },
    template: require('./dvb-limited-entity-verlauf.html'),
    controllerAs: 'vm',
};

export class DvbLimitedEntityVerlauf implements angular.IController {
    public static $inject: readonly string[] = [];

    public entity!: ILimited;
    public enableEditMode?: boolean = true;
    public previousEntity?: ILimited | null = null;
    public first: boolean = false;
    public translationRoot!: string;
    public enableActions: boolean = true;
    public onRemove!: FunctionType;
    public onRevert!: FunctionType;
    public onSubmit!: (data: {context: FormContext}) => unknown;
    public onEdit!: FunctionType;

    public today: moment.Moment = DvbDateUtil.today();
    public isTerminated: boolean = false;

    public $onChanges(): void {
        this.isTerminated = !DvbDateUtil.isEndOfTime(this.entity.gueltigBis);
    }

    public revert(): void {
        this.onRevert();
    }

    public remove(): unknown {
        return this.onRemove();
    }

    public submit(context: FormContext): unknown {
        return this.onSubmit({context});
    }
}

componentConfig.controller = DvbLimitedEntityVerlauf;
angular.module('kitAdmin').component('dvbLimitedEntityVerlauf', componentConfig);
