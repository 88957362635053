/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FunctionType} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {
    KinderOrtSchliesstageService,
} from '../../../../common/service/rest/kinderort/kinderOrtSchliesstageService';
import type {KinderOrtSchliesstage} from '../../../../schliesstage/models/KinderOrtSchliesstage';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrtSchliesstage: '<',
        onRemove: '&',
    },
    template: require('./dvb-kinder-ort-schliesstage-content.html'),
    controllerAs: 'vm',
};

export class DvbKinderOrtSchliesstageContent {
    public static $inject: readonly string[] = ['kinderOrtSchliesstageService', 'errorService'];

    public kinderOrtSchliesstage!: KinderOrtSchliesstage;
    public onRemove!: FunctionType;

    public editMode: boolean = false;
    public workingCopyKinderOrtSchliesstage: KinderOrtSchliesstage | null = null;
    public isLoading: boolean = false;

    public constructor(
        public readonly kinderOrtSchliesstageService: KinderOrtSchliesstageService,
        public readonly errorService: ErrorService,
    ) {
    }

    public edit(): void {
        this.workingCopyKinderOrtSchliesstage = angular.copy(this.kinderOrtSchliesstage);
        this.editMode = true;
    }

    public submit(): void {
        if (angular.equals(this.workingCopyKinderOrtSchliesstage, this.kinderOrtSchliesstage)) {
            this.cancel();

            return;
        }

        this.isLoading = true;
        this.kinderOrtSchliesstageService.create(checkPresent(this.workingCopyKinderOrtSchliesstage))
            .then(() => {
                this.editMode = false;
                angular.copy(this.workingCopyKinderOrtSchliesstage, this.kinderOrtSchliesstage);
            }).finally(() => {
            this.isLoading = false;
        });
    }

    public cancel(): void {
        this.editMode = false;
    }

    public remove(): void {
        this.errorService.clearAll();
        this.editMode = false;

        this.onRemove(this.kinderOrtSchliesstage);
    }
}

componentConfig.controller = DvbKinderOrtSchliesstageContent;
angular.module('kitAdmin').component('dvbKinderOrtSchliesstageContent', componentConfig);
