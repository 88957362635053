/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CalculatedStundenKontingent} from '@dv/kitadmin/models';
import {BootstrapStyleType} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        stundenKontingent: '<',
    },
    template: require('./dvb-monatsblatt-stunden-kontingent.html'),
    controllerAs: 'vm',
};

export class DvbMonatsblattStundenKontingent implements angular.IOnChanges {
    public static $inject: readonly string[] = [];

    public stundenKontingent!: CalculatedStundenKontingent;

    public progress: number | null = null;
    public availableStunden: number | null = null;
    public type?: BootstrapStyleType;

    public $onChanges(changes: angular.IOnChangesObject): void {

        if (!changes.stundenKontingent) {
            return;
        }

        this.stundenKontingent = changes.stundenKontingent.currentValue;
        const percentFactor = 100;
        const maxStunden = this.stundenKontingent.stundenKontingent.stunden;
        const divider = maxStunden || 1;
        const subtractor = maxStunden ?? 0;
        this.availableStunden = subtractor - this.stundenKontingent.verbuchteStunden;
        this.progress = this.availableStunden < 0
            ? percentFactor
            : percentFactor - (this.stundenKontingent.verbuchteStunden / divider) * percentFactor;
        this.type = this.availableStunden < 0 ? BootstrapStyleType.DANGER : BootstrapStyleType.SUCCESS;
    }

}

componentConfig.controller = DvbMonatsblattStundenKontingent;
angular.module('kitAdmin').component('dvbMonatsblattStundenKontingent', componentConfig);
