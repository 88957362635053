/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Pensum, PENSUM_TYPE_SYMBOL, PensumType} from '@dv/kitadmin/models';
import type {MinMaxFilterProperty} from '../../kinderort/service/kinderFilter-models/MinMaxFilterProperty';
import type {IPopoverFilterProperty, PopoverType} from './IPopoverFilterProperty';

export class PopoverFilterPropertyPensum implements IPopoverFilterProperty<MinMaxFilterProperty | undefined, Pensum> {

    private static readonly DEFAULT_PENSUM_FILTER: number = 40;

    public selected: boolean = false;
    public type: PopoverType = 'pensum';

    public min: number;
    public max: number;

    public constructor(
        private readonly pensumType: PensumType = PensumType.HOURS,
        min: number | null = Number.NEGATIVE_INFINITY,
        max: number | null = PopoverFilterPropertyPensum.DEFAULT_PENSUM_FILTER,
    ) {
        this.min = min ?? Number.NEGATIVE_INFINITY;
        this.max = max ?? Number.POSITIVE_INFINITY;
    }

    public acceptUserModel(modelValue?: MinMaxFilterProperty): boolean {
        if (!modelValue) {
            return false;
        }

        return modelValue.min <= this.max && modelValue.max >= this.min;
    }

    public createWorkingCopy(): Pensum {
        const result = new Pensum();
        result.von = isFinite(this.min) ? this.min : null;
        result.bis = isFinite(this.max) ? this.max : null;
        result.type = this.pensumType;

        return result;
    }

    public onChange(pensum: Pensum): void {
        pensum.update();
        this.min = pensum.von ?? Number.NEGATIVE_INFINITY;
        this.max = pensum.bis ?? Number.POSITIVE_INFINITY;
    }

    public getLabel(): string {
        const symbol = PENSUM_TYPE_SYMBOL[this.pensumType];

        const von = this.min;
        const bis = this.max;
        if (isFinite(von)) {
            return isFinite(bis) ? `${von} - ${bis}${symbol}` : `>= ${von}${symbol}`;
        }

        return isFinite(bis) ? `<= ${bis}${symbol}` : symbol;
    }

}
