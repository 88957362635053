/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import type {Kontaktperson} from '@dv/kitadmin/models';
import {KibonExchangeEntity, Kind} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase, SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {DisplayNamePipe, DvLocalDateFormatPipe} from '@dv/shared/angular';
import {checkPresent} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

export interface KibonEntityMatchingDialogModel extends CompletableDialogModel<null> {
    entity: KibonExchangeEntity;
    kind: Kind;
    header: string;
    entityDataHeader: string;
}

@Component({
    selector: 'dv-kibon-entity-matching-dialog',
    templateUrl: './kibon-entity-matching-dialog.component.html',
    styleUrls: ['./kibon-entity-matching-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        TooltipModule,
        DvLocalDateFormatPipe,
        SubmitCancelButtonsComponent,
        DisplayNamePipe,
    ],
})
export class KibonEntityMatchingDialogComponent extends CompletableDialogBase<null>
    implements KibonEntityMatchingDialogModel, OnInit {

    @Input() public kind!: Kind;
    @Input() public entity!: KibonExchangeEntity;
    @Input() public header!: string;
    @Input() public entityDataHeader!: string;

    public kinderKontakt: Kontaktperson | null = null;
    public mismatchingKontaktNames: string = '';

    public kindNameMismatch: boolean = false;
    public kontaktNameMismatch: boolean = false;
    public birthdateMismatch: boolean = false;

    public ngOnInit(): void {

        const matchingKontakte = this.kind.kontakte.find(k =>
            this.hasSameNameAsEntity(checkPresent(k.kontaktperson)));
        if (matchingKontakte) {
            this.kinderKontakt = matchingKontakte.kontaktperson;
        } else {
            this.mismatchingKontaktNames = this.kind.kontakte
                .map(kontakt => kontakt.kontaktperson!.getDisplayName())
                .join(', ');
        }
        this.kontaktNameMismatch = !matchingKontakte;

        this.kindNameMismatch = this.kind.vorName !== this.entity.kind.vorName ||
            this.kind.familienName !== this.entity.kind.familienName;
        this.birthdateMismatch = this.entity.kind.geburtsdatum!.diff(this.kind.geburtsTag, 'days', true) !== 0;
    }

    private hasSameNameAsEntity(kontaktperson: Kontaktperson): boolean {
        return kontaktperson.vorName === this.entity.gesuchsteller.vorName
            && kontaktperson.familienName === this.entity.gesuchsteller.familienName;
    }
}
