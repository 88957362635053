/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry} from '@dv/kitadmin/models';
import {BankStatementEntryMatch, RechnungDisplayMode} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {BankStatementService} from '../../../../service/bankStatementService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        bankStatementEntry: '<',
    },
    template: require('./dvb-transaktion-zuweisen-vorgeschlagen.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionZuweisenVorgeschlagen implements angular.IOnInit {
    public static $inject: readonly string[] = ['bankStatementService'];

    public bankStatementEntry!: BankStatementEntry;

    public matchTypes: readonly BankStatementEntryMatch[] = [];
    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.TRANSAKTION;

    public constructor(private bankStatementService: BankStatementService) {
    }

    public $onInit(): void {
        const params = {includes: '(nestedIncludes.fields(ausstehenderBetrag,emailVersandHistory))'};
        this.matchTypes = BankStatementEntryMatch.matchTypes;
        this.matchTypes.forEach(type => {
            // since BankStatementEntryMatch.matchTypes is a singleton, the data is shared across
            // bankStatementEntries we have to make sure to reset rechnungen
            type.rechnungen = [];
            type.loading = true;
            const bankStatementEntryId = checkPresent(this.bankStatementEntry.id);
            this.bankStatementService.getMatches(bankStatementEntryId, type.key, params)
                .then(matches => {
                    type.rechnungen = matches.items;
                })
                .finally(() => {
                    type.recomputePages();
                    type.loading = false;
                });
        });
    }
}

componentConfig.controller = DvbTransaktionZuweisenVorgeschlagen;
angular.module('kitAdmin').component('dvbTransaktionZuweisenVorgeschlagen', componentConfig);
