/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CustomFieldValue} from '@dv/kitadmin/models';
import {CustomField} from '@dv/kitadmin/models';
import type {NamedEntityType} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class CustomFieldService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/customfields`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getAll(
        type?: NamedEntityType,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<CustomField[]> {

        return DvbRestUtilAngularJS.getModelsArray(
            CustomFieldService.BASE_URL,
            CustomField,
            'customFields',
            type ? {type} : {},
            !!config?.cache,
            config);
    }

    public updateCustomFields(
        type: NamedEntityType,
        customFields: CustomField[],
        config?: angular.IRequestShortcutConfig,
    ): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const body = {
            customFields: customFields.map(customField => customField.toRestObject()),
        };

        return this.$http.post(`${CustomFieldService.BASE_URL}/${type}`, body, config);
    }

    public saveValue(customFieldValue: CustomFieldValue): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${CustomFieldService.BASE_URL}/${encodeURIComponent(customFieldValue.customField.id!)}/value`;

        return this.$http.put(url, customFieldValue.toRestObject());
    }

    public saveValues(customFieldValues: CustomFieldValue[]): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${CustomFieldService.BASE_URL}/values`;
        const data = {values: customFieldValues.map(value => value.toRestObject())};

        return this.$http.post(url, data);
    }

}
