import type {BelegungsEinheit, Wochenplan} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        einheit: '<',
        wochenplan: '<',
    },
    template: require('./dvb-belegungs-einheit-form.html'),
    controllerAs: 'vm',
};

export class DvbBelegungsEinheitForm implements angular.IController {

    public einheit!: BelegungsEinheit;
    public wochenplan!: Wochenplan;

    public selectedZeitraeume: { [zeitraumId: string]: boolean } = {};

    public $onChanges(): void {
        this.einheit.zeitraumIds.forEach(zeitraumId => {
            this.selectedZeitraeume[zeitraumId] = true;
        });
    }

    public toggleZeitraum(): void {
        this.einheit.zeitraumIds = Object.keys(this.selectedZeitraeume).filter(key => this.selectedZeitraeume[key]);
    }
}

componentConfig.controller = DvbBelegungsEinheitForm;
angular.module('kitAdmin').component('dvbBelegungsEinheitForm', componentConfig);
