/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Wochenplan} from '@dv/kitadmin/models';
import {ZeitraumUtil} from '@dv/kitadmin/models';
import type {DayOfWeek, Persisted} from '@dv/shared/code';
import angular from 'angular';
import type {KapazitaetAenderung} from '../../../fraktion/models/KapazitaetAenderung';
import type {KapazitaetService} from '../../../fraktion/service/kapazitaetService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        kapazitaetsAenderung: '<',
        wochenplan: '<',
    },
    template: require('./dvb-kapazitaet.html'),
    controllerAs: 'vm',
};

export class DvbKapazitaet implements angular.IController {

    public static $inject: readonly string[] = ['kapazitaetService'];

    public kapazitaetsAenderung!: KapazitaetAenderung;
    public wochenplan!: Persisted<Wochenplan>;

    public isCollapsed = true;
    public enabledDays: DayOfWeek[] = [];

    public constructor(
        private readonly kapazitaetService: KapazitaetService,
    ) {
    }

    public $onInit(): void {
        this.enabledDays = ZeitraumUtil.getWeekDaysFromWochenplan(this.wochenplan);
    }

    public showMaxPlaetze(): boolean {
        return !this.kapazitaetService.hasEqualZeitraumFeldValues(
            this.kapazitaetsAenderung.zeitraumFelderPlaetze,
            this.kapazitaetsAenderung.zeitraumFelderMaxPlaetze);
    }
}

componentConfig.controller = DvbKapazitaet;
angular.module('kitAdmin').component('dvbKapazitaet', componentConfig);
