<div class="dvb-rechnungen-list"
     dvb-accordions
     allow-multiple="vm.mode.allowMultiple">
    <dvb-accordion ng-repeat="rechnung in vm.rechnungen"
                   on-select="vm.refreshStatus(selected, rechnung)"
                   is-selected="vm.preSelectedFunction(rechnung)"
                   is-first="$first">
        <panel-header class="full-width-block">
            <dvb-rechnung-heading rechnung="rechnung"
                                  class="full-width-block"
                                  mode="vm.mode"
                                  fresh-status="vm.refreshedStatuses[rechnung.rechnungsRevisionId]"
                                  fresh-status-loading="vm.freshStatusLoading[rechnung.rechnungsRevisionId]"
                                  selection="vm.selection"
                                  errors="vm.errors[rechnung.id]"
                                  show-versandart-badge="vm.showVersandartBadge">
            </dvb-rechnung-heading>
        </panel-header>
        <panel-content>
            <dvb-rechnung-detail rechnung="rechnung"
                                 mode="vm.mode"
                                 is-first="$index === 0">
            </dvb-rechnung-detail>
        </panel-content>
    </dvb-accordion>
</div>
