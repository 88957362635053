<div class="dvb-benutzer-form">
    <form name="benutzer-form" novalidate>
        <div class="form-section-minus-normal-abstand">
            <div class="row">
                <div ng-class="{'col-md-6': vm.allowGroupAdmin, 'col-md-12': !vm.allowGroupAdmin}">
                    <input type="email"
                           class="form-control"
                           name="username"
                           ng-model="vm.benutzer.username"
                           ng-attr-placeholder="{{'BENUTZER.USERNAME' | translate}}"
                           uib-tooltip="{{'BENUTZER.USERNAME' | translate}}"
                           tooltip-placement="top-left"
                           maxlength="255"
                           required>
                </div>
                <div class="col-md-6" ng-if="vm.allowGroupAdmin">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" ng-model="vm.benutzer.groupAdminUser">
                            <span data-translate="COMMON.BENUTZER.GROUP_ADMIN"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <input type="text"
                           name="familienName"
                           dvb-capitalize-first-letter
                           class="form-control"
                           ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                           uib-tooltip="{{'COMMON.NAME' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.benutzer.familienName"
                           maxlength="255"
                           required>
                </div>
                <div class="col-md-6">
                    <input type="text"
                           name="vorName"
                           dvb-capitalize-first-letter
                           class="form-control"
                           ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                           uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.benutzer.vorName"
                           maxlength="255"
                           ng-if="!vm.benutzer.groupAdminUser"
                           required>

                        <dvb-search-get-single-entity ng-if="vm.benutzer.groupAdminUser"
                                                      entity-to-search="KINDERORT"
                                                      required
                                                      ng-model="vm.benutzer.kinderOrt"
                                                      display-label="COMMON.KINDERORT.SINGULAR"
                                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                        </dvb-search-get-single-entity>
                </div>
            </div>

            <dvb-new-password ng-if="vm.allowPasswordChange && !vm.benutzer.groupAdminUser"
                              name="newPassword"
                              ng-model="vm.benutzer.password">
            </dvb-new-password>

        </div>
    </form>
</div>
