/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BelegteEinheit, RestCache} from '@dv/kitadmin/models';
import {Bewerbung, ServiceContainer} from '@dv/kitadmin/models';
import type {JaxAdresse} from '@dv/shared/backend/model/jax-adresse';
import type {RestInclude} from '@dv/shared/code';
import {Adresse, checkPresent, DvbRestUtil, HttpCodes} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class BewerbungenService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/bewerbungen`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    /**
     * @return alle offenen Bewerbungen
     */
    public getAll(params?: RestInclude): angular.IPromise<Bewerbung[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(BewerbungenService.BASE_URL, Bewerbung, 'bewerbungen', matrixParams);
    }

    public get(bewerbungId: string, params?: RestCache & RestInclude): angular.IPromise<Bewerbung | null> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(BewerbungenService.BASE_URL, Bewerbung, bewerbungId, params);
    }

    /**
     * Get Adresse by bewerbung id.
     */
    public getAktuelleAdresse(bewerbungId: string, params?: RestInclude & RestCache): angular.IPromise<Adresse> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}/aktuelleadresse`;

        return this.$http.get<JaxAdresse>(url + DvbRestUtil.encodeMatrixParams(matrixParams))
            .then(response => {
                if (response.status === HttpCodes.NO_CONTENT) {
                    ServiceContainer.$q.reject(response);
                }

                return Adresse.apiResponseTransformer(response.data);
            });
    }

    public update(bewerbung: Bewerbung): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(checkPresent(bewerbung.id))}`;

        return this.$http.put(url, bewerbung.toRestObject(false));
    }

    public deleteBewerbung(bewerbungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}`);
    }

    public addKita(bewerbungId: string, kitaId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}/kitas`;

        return this.$http.put(url, kitaId);
    }

    public removeKita(bewerbungId: string, kitaId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}/kitas/` +
            `${encodeURIComponent(kitaId)}`;

        return this.$http.delete(url);
    }

    public addFirma(bewerbungId: string, firmaId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}/firmen`;

        return this.$http.put(url, firmaId);
    }

    public removeFirma(bewerbungId: string, firmaId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}/firmen/` +
            `${encodeURIComponent(firmaId)}`;

        return this.$http.delete(url);
    }

    public setBetreuungsWuensche(bewerbungId: string, belegteEinheiten: BelegteEinheit[]): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BewerbungenService.BASE_URL}/${encodeURIComponent(bewerbungId)}/betreuungswuensche`;

        return this.$http.post(url, belegteEinheiten);
    }
}
