<div class="translatable-editable-text-area">
    <dvb-translatable-language ng-model="vm.language"></dvb-translatable-language>

    <div class="row">
        <div class="col-md-12">
            <dvb-editable-text-area ng-attr-placeholder="{{vm.placeholder}}"
                                    ng-model="vm.translation.texts[vm.language]"
                                    maxlength="{{vm.maxLength}}"
                                    on-submit="vm.onSubmit()"
                                    ng-disabled="vm.disabled">
            </dvb-editable-text-area>
        </div>
    </div>
</div>
