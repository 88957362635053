<div class="radio" ng-repeat="prioritaet in vm.prioritaeten">
    <label>
        <input type="radio"
               value="{{prioritaet}}"
               ng-model="vm.prioritaet"
               name="prioritaet"
               ng-value="prioritaet"
               ng-change="vm.onChange({prioritaet: vm.prioritaet})"
               required
               dvb-auth
               require-permission="kita:manage:general"
               on-unauthorised-disable>
        <span data-translate="COMMON.PRIORITAET" data-translate-value-prioritaet="{{prioritaet}}"></span>
    </label>
</div>
