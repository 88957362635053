/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsoLanguagesAndCountriesService, LimitedAdresse} from '@dv/kitadmin/models';
import type {Country} from '@dv/shared/code';
import {Adresse, DvbDateUtil, END_OF_TIME} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';

export class MandantConfigurationService {

    public getDefaultCountry(): angular.IPromise<Country> {
        return IsoLanguagesAndCountriesService.getCountry('CH').then(country => country!);
    }

    public createDefaultAdresse(): angular.IPromise<Adresse> {
        const adr = new Adresse();

        return this.getDefaultCountry().then(country => {
            adr.land = country;

            return adr;
        });
    }

    public createDefaultLimitedAdresse(gueltigAb?: moment.Moment): angular.IPromise<LimitedAdresse> {
        const gueltigAbValid = DvbDateUtil.isValidMoment(gueltigAb) ? gueltigAb : null;
        const limitedAdresse = new LimitedAdresse(null, gueltigAbValid, END_OF_TIME);

        return this.createDefaultAdresse().then(adresse => {
            limitedAdresse.adresse = adresse;

            return limitedAdresse;
        });
    }
}
