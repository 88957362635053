/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ElementRef} from '@angular/core';
import {DvbDateUtil, isPresent} from '@dv/shared/code';
import moment from 'moment';
import type {CalendarEvent} from '../model/CalendarEvent';
import type {LayerConfigByLayerIndex} from '../model/LayerConfig';
import type {TimelineCalendarService} from './timeline-calendar.service';
import type {TimelineCalculationStrategy} from './TimelineCalculationStrategy';

/**
 * Creates day based placement of calendar events. Meaning CalendarEvents span the entire day width.
 */
export class TimelineCalculationDayBasedStrategy implements TimelineCalculationStrategy {

    public calculateSpace(
        events: CalendarEvent[][],
        _startTime: number,
        _endTime: number,
        startDate: moment.Moment,
        endDate: moment.Moment,
    ): CalendarEvent[][] {
        return events.map(rowEvent => rowEvent.map((event, index) => {

            const spaceBefore = index === 0
                ? DvbDateUtil.dayDiff(startDate, event.gueltigAb)
                : DvbDateUtil.dayDiff(rowEvent[index - 1].gueltigBis, event.gueltigAb) - 1;
            const spaceAfter = index === rowEvent.length - 1 ? DvbDateUtil.dayDiff(event.gueltigBis, endDate) : 0;
            const overlap = DvbDateUtil.getOverlap({
                gueltigAb: event.gueltigAb,
                gueltigBis: event.gueltigBis,
            }, {gueltigAb: startDate, gueltigBis: endDate});
            event.spaceBefore = spaceBefore > 0 ? spaceBefore : 0;
            event.spaceAfter = spaceAfter > 0 ? spaceAfter : 0;
            event.completeDuration = isPresent(overlap)
                ? DvbDateUtil.dayDiff(overlap.gueltigAb!, overlap.gueltigBis!) + 1
                : 0;

            return event;
        }));
    }

    public splitIntoRows(events: CalendarEvent[], layerConfig: LayerConfigByLayerIndex): CalendarEvent[][] {

        const rows: CalendarEvent[][] = [];
        const sortedEvents = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(events);
        sortedEvents.forEach(event => {
            let added = false;
            const noRowSplitting = layerConfig.get(event.layer)?.noRowSplitting;
            for (const row of rows) {
                const relevantRowEvents = row.filter(e => layerConfig.get(e.layer)?.noRowSplitting !== true);
                const isAnyIntersec = noRowSplitting ? false : DvbDateUtil.intersectsAny(event, relevantRowEvents);
                const linkedEventInRow = isPresent(event.linkedEvent) && row.some(e => e.id === event.linkedEvent);

                if (linkedEventInRow || !isAnyIntersec) {
                    row.push(event);
                    added = true;
                    break;
                }
            }

            if (added) {
                return;
            }
            rows.push([event]);
        });

        return rows;
    }

    public limitEvents(
        events: CalendarEvent[],
        _startTime: number,
        _endTime: number,
        startDate: moment.Moment,
        endDate: moment.Moment,
        layerConfig: LayerConfigByLayerIndex,
    ): CalendarEvent[] {
        return events
            .filter(event => layerConfig.get(event.layer))
            .filter(event => DvbDateUtil.intersects(event, {
                gueltigAb: startDate,
                gueltigBis: endDate,
            }));
    }

    public calculateDropDate(
        event: DragEvent,
        service: TimelineCalendarService,
        timelineElem: ElementRef,
        headerElem: ElementRef,
    ): moment.Moment {

        const dayWidth = this.determineDayWidth(service, headerElem);
        const scrollLeft: number = timelineElem.nativeElement.scrollLeft;
        const dropX = event.clientX + scrollLeft;
        const headerX = timelineElem.nativeElement.offsetLeft;

        const daysToAdd = Math.floor((dropX - headerX) / dayWidth);

        return moment(service.startDate()).add(daysToAdd, 'days');
    }

    private determineDayWidth(service: TimelineCalendarService, headerElem: ElementRef): number {
        const headerWidth = headerElem.nativeElement.scrollWidth;

        return headerWidth / (service.dayDiff() + 1);
    }
}
