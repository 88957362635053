<div class="dvb-kita-tarife-verlauf">
    <div
        ng-repeat="tarifeZeitraum in (sortedTarifeZeitraeume = (vm.tarifeZuweisungen | toArray:false | orderBy:'gueltigAb':true))">

        <!-- Beendeter tarifeZeitraum -->
        <dvb-collapsible-panel
            ng-show="sortedTarifeZeitraeume[$index-1].gueltigAb.diff(tarifeZeitraum.gueltigBis, 'days') > 1  || $first && vm.isTarifeZeitraumBeendet(tarifeZeitraum)"
            is-first="$first"
            is-collapsed="true"
            dvb-auth
            require-permission="{{'kita:administrate:' + vm.kitaId}}"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{tarifeZeitraum.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{tarifeZeitraum.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.revertKitaTarifeZeitraum(tarifeZeitraum)">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="tarifeZeitraum"
                               is-first="$first && !vm.isTarifeZeitraumBeendet(tarifeZeitraum)"
                               expand-ab="vm.today"
                               expand-bis="vm.today"
                               dvb-auth
                               require-permission="{{'kita:administrate:' + vm.kitaId}}"
                               on-unauthorised-disable>
            <panel-header>
                <dvb-kita-tarife-verlauf-header kita-tarife-zeitraum="tarifeZeitraum">
                </dvb-kita-tarife-verlauf-header>
            </panel-header>
            <panel-content>
                <dvb-kita-tarife-verlauf-content kita-tarife-zeitraum="tarifeZeitraum"
                                                 on-delete="vm.onDelete({kitaTarifeZeitraum: kitaTarifeZeitraum})">
                </dvb-kita-tarife-verlauf-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
