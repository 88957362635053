/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PageContainer} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {BetreuungAnfrageFilter} from '../models/BetreuungAnfrageFilter';
import {BetreuungAnfrageProcessingResult} from '../models/BetreuungAnfrageProcessingResult';
import {KibonExchangeBetreuungAnfrage} from '../models/KibonExchangeBetreuungAnfrage';
import type {KibonQueryApiService, QueryKibonExchangeApiQueryapiParameter} from './kibonQueryApiService';

export class BetreuungAnfrageService implements KibonQueryApiService {
    public static $inject: readonly string[] = ['$http'];

    public static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kibon/betreuunganfragen`;

    public constructor(private $http: angular.IHttpService) {
    }

    public queryExchangeService(params: Readonly<QueryKibonExchangeApiQueryapiParameter>): angular.IPromise<unknown> {
        const url = `${BetreuungAnfrageService.BASE_URL}/queryapi${DvbRestUtil.encodeMatrixParams(params)}`;

        return this.$http.get(url);
    }

    public getFiltered(
        filter?: BetreuungAnfrageFilter,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<KibonExchangeBetreuungAnfrage>> {

        return DvbRestUtilAngularJS.getPagedItems(BetreuungAnfrageService.BASE_URL,
            KibonExchangeBetreuungAnfrage,
            filter?.toRestObject(),
            config);
    }

    public assign(anfrageId: string, kindId: string): angular.IPromise<BetreuungAnfrageProcessingResult> {
        const url = `${BetreuungAnfrageService.BASE_URL}/${encodeURIComponent(anfrageId)}/assign/${
            encodeURIComponent(kindId)}`;

        return DvbRestUtilAngularJS.putAndGetModel(url, {}, BetreuungAnfrageProcessingResult);
    }

    public unAssign(anfrageId: string): angular.IHttpPromise<void> {
        const url = `${BetreuungAnfrageService.BASE_URL}/${encodeURIComponent(anfrageId)}/assign`;

        return this.$http.delete(url);
    }

    public ignore(anfrageId: string): angular.IHttpPromise<void> {
        const url = `${BetreuungAnfrageService.BASE_URL}/${encodeURIComponent(anfrageId)}/ignore`;

        return this.$http.put(url, {});
    }

    public unIgnore(anfrageId: string): angular.IHttpPromise<void> {
        const url = `${BetreuungAnfrageService.BASE_URL}/${encodeURIComponent(anfrageId)}/ignore`;

        return this.$http.delete(url);
    }
}
