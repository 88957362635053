/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PipeTransform} from '@angular/core';
import {inject, Pipe} from '@angular/core';
import {Translator} from '@dv/shared/translator';
import angular from 'angular';

angular.module('kitAdmin').filter('dvbEnumeration', dvbEnumeration);

function dvbEnumeration($translate: angular.translate.ITranslateService): (
    input: string[],
    inclusive: boolean,
) => string {
    return (input, inclusive?): string => {
        return getEnumerationText(input, $translate, inclusive);
    };
}

dvbEnumeration.$inject = ['$translate'];

@Pipe({name: 'dvbEnumeration', standalone: true})
export class DvbEnumerationPipe implements PipeTransform {

    private translator = inject(Translator);

    public transform(input: string[], inclusive?: boolean): string {
        return getEnumerationText(input, this.translator, inclusive);
    }
}

function getEnumerationText(input: string[], translator: Translator, inclusive: boolean = false): string {
    const andOrSeparatorKey: string = inclusive ? 'COMMON.AND' : 'COMMON.OR';
    const andOrSeparator = input.length > 1 ? ` ${translator.instant(andOrSeparatorKey)} ` : '';

    return input.slice(0, -1).join(', ') + andOrSeparator + input.slice(-1).join('');
}
