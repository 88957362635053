<form name="rueckerstattungDialogForm" #dateDialogForm="ngForm" (ngSubmit)="submitForm(dateDialogForm)" novalidate>
    <div class="modal-body">
        <h1 [translate]="'COMMON.RUECKERSTATTUNGSBETRAG_AUSZAHLEN'"></h1>
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <dvlib-datepicker-textfield [(ngModel)]="auszahlungsDatum"
                                            name="auszahlungsDatum"
                                            [placeholder]="'COMMON.AUSZAHLUNGSDATUM' | translate"
                                            required>
                </dvlib-datepicker-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-md-offset-3 small-abstand-top text-left">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" name="generatePainFile" [(ngModel)]="generatePainFile">
                        <span [translate]="'COMMON.AUSZAHLUNG_PAINFILE_GENERIEREN'"></span>
                    </label>
                </div>
            </div>
        </div>
        @if (generatePainFile) {
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <textarea [(ngModel)]="zahlungszweck"
                              name="zahlungszweck"
                              [placeholder]="'COMMON.AUSZAHLUNG_ZAHLUNGSZWECK' | translate"
                              [tooltip]="'COMMON.AUSZAHLUNG_ZAHLUNGSZWECK' | translate"
                              dvlibElasticTextInput
                              maxlength="140"
                              required>
                    </textarea>
                </div>
            </div>
        }
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'confirm'"
                                  [isLoading]="isLoading$ | async">
                <span [translate]="'COMMON.AUSZAHLUNG_BESTAETIGEN'"></span>
            </dvlib-loading-button>
            <button dvlibButton="link-button" (click)="hide()">
                <span [translate]="'COMMON.ABBRECHEN'"></span>
            </button>
        </dvlib-button-list>
    </div>
</form>
