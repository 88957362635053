/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DokumentLayout, UploadTempBlob} from '@dv/kitadmin/models';
import {ImageBlob} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import {DASHBOARD_STATE} from '../../../dashboard/dashboard-state';
import type {DokumentlayoutService} from '../../service/dokumentlayoutService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        dokumentLayout: '<',
    },
    template: require('./dvb-dokumentlayout-profil.html'),
    controllerAs: 'vm',
};

export class DvbDokumentlayoutProfil implements angular.IController {
    public static $inject: readonly string[] = [
        'dokumentlayoutService',
        'dialogService',
        'errorService',
        '$state',
        '$q',
    ];

    public dokumentLayout!: DokumentLayout;
    public workingCopy: DokumentLayout = angular.copy(this.dokumentLayout);
    public editMode: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private dokumentlayoutService: DokumentlayoutService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private $state: StateService,
        private $q: angular.IQService,
    ) {
    }

    private static getImageUploadBlob(dokumentLayout: DokumentLayout): UploadTempBlob | null {
        if (!dokumentLayout.logo?.image?.id) {
            return null;
        }

        return dokumentLayout.logo.image;
    }

    public edit(): void {
        this.editMode = true;
        this.workingCopy = angular.copy(this.dokumentLayout);
    }

    public cancel(): void {
        this.editMode = false;
    }

    public submit(form: angular.IFormController): void {
        const dokumentLayoutWithDefaults = angular.copy(this.workingCopy);
        dokumentLayoutWithDefaults.applyDefaults();

        const valid = form.$valid && dokumentLayoutWithDefaults.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        this.isLoading = true;

        this.dokumentlayoutService.updateDokumentLayout(dokumentLayoutWithDefaults).then(() => {
            angular.extend(this.dokumentLayout, dokumentLayoutWithDefaults);
            this.editMode = false;

            const uploadBlob = DvbDokumentlayoutProfil.getImageUploadBlob(dokumentLayoutWithDefaults);

            if (!uploadBlob) {
                return this.$q.resolve();
            }

            return ImageBlob.readFromUploadBlob(uploadBlob)
                .then((imageBlob: ImageBlob) => {
                    checkPresent(this.dokumentLayout.logo).image = imageBlob;
                });
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public confirmDelete(): void {
        this.dialogService.openDeleteDialog({
            entityText: checkPresent(this.dokumentLayout.name),
            confirm: () => from(this.dokumentlayoutService.deleteDokumentLayout(checkPresent(this.dokumentLayout.id)))
                .pipe(take(1), tap(() => this.$state.go(DASHBOARD_STATE.name))),
        });
    }

}

componentConfig.controller = DvbDokumentlayoutProfil;
angular.module('kitAdmin').component('dvbDokumentlayoutProfil', componentConfig);
