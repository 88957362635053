/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import type {Adresse} from '@dv/shared/code';
import angular from 'angular';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kinderort-kontakt.html'),
    controllerAs: 'vm',
};

export class DvbKinderortKontakt implements angular.IController {
    public static $inject: readonly string[] = ['errorService', 'kinderOrtService'];

    public kinderOrt!: KinderOrt;

    public isAdresseEditMode: boolean = false;

    public constructor(
        private errorService: ErrorService,
        private kinderOrtService: KinderOrtService,
    ) {
    }

    public saveBasisData(): void {
        if (this.kinderOrt.isValid()) {
            this.errorService.clearAll();
            this.kinderOrtService.update(this.kinderOrt);
        } else {
            throw new Error('Kinderort invalid when updating');
        }
    }

    public isNameValid(name: string): boolean {
        return !!name && name.length > 0;
    }

    public adresseSpeichern(neueAdresse: Adresse): void {
        if (angular.equals(this.kinderOrt.adresse, neueAdresse)) {
            return;
        }

        const origAdresse = this.kinderOrt.adresse;
        this.kinderOrt.adresse = neueAdresse;
        this.kinderOrtService.update(this.kinderOrt).catch(error => {
            this.kinderOrt.adresse = origAdresse;
            console.error('Adresse konnte nicht gespeichert werden', error);
        });
    }
}

componentConfig.controller = DvbKinderortKontakt;
angular.module('kitAdmin').component('dvbKinderortKontakt', componentConfig);
