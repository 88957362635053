<form #form="ngForm" (ngSubmit)="submitForm(form)" class="dvb-form">
    <div class="row">
        <div class="col-md-6">
            <input
                type="time"
                class="form-control"
                name="von"
                [(ngModel)]="model.von"
                validVon
                [dateStart]="event.von"
                [dateEnd]="event.bis"
                [timeEnd]="model.bis"
                [placeholder]="'PERSONAL.TERMIN.VON' | translate"
                [tooltip]="'PERSONAL.TERMIN.VON' | translate" />
        </div>
        <div class="col-md-6">
            <input
                type="time"
                class="form-control"
                name="bis"
                [(ngModel)]="model.bis"
                validBis
                [dateStart]="event.von"
                [dateEnd]="event.bis"
                [timeStart]="model.von"
                [placeholder]="'PERSONAL.TERMIN.BIS' | translate"
                [tooltip]="'PERSONAL.TERMIN.BIS' | translate" />
        </div>
    </div>

    <dvlib-button-list class="small-abstand-top">
        <button dvlibButton="link-button" type="submit">
            {{'COMMON.SPEICHERN' | translate}}
        </button>

        <button dvlibButton="link-button" (click)="cancel()">
            {{'COMMON.ABBRECHEN' | translate}}
        </button>
    </dvlib-button-list>
</form>
