/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {AUTHENTICATION_STATES} from './authentication-states';

export * from './component/dvb-login/dvb-login';
export * from './directive/dvb-login-dialog';
// Add New Component JS Above

angular.module('kitAdmin').config(authenticationConfig);

authenticationConfig.$inject = ['$stateProvider'];

function authenticationConfig($stateProvider: StateProvider): void {
    Object.values(AUTHENTICATION_STATES).forEach(state => $stateProvider.state(state));
}
