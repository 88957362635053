/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldungKind} from '@dv/kitadmin/models';
import angular from 'angular';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        kind: '<',
        index: '<',
    },
    template: require('./dvb-external-anmeldung-kind.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungKind implements angular.IController {
    public static $inject: readonly string[] = ['mandantConfigurationService'];

    public kind!: ExternalAnmeldungKind;
    public index!: number;

    public constructor(
        private readonly mandantConfigurationService: MandantConfigurationService,
    ) {
        this.mandantConfigurationService.createDefaultAdresse().then(adresse => {
            this.kind.adresse = adresse;
        });
    }
}

componentConfig.controller = DvbExternalAnmeldungKind;
angular.module('kitAdmin').component('dvbExternalAnmeldungKind', componentConfig);
