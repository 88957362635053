<div class="dvb-vertragliches-pensum normal-abstand-top">
    <div ng-class="{'small-abstand-top': !$first}" ng-repeat="vertraglichesPensum in vm.vertraglichePensen"
         ng-init="title = vm.getTitle(vertraglichesPensum)">
        <span class="floating-text ng-scope"
              ng-if="vm.vertraglichePensen.length  > 1"
              ng-bind="title">
        </span>
        <input type="number"
               ng-model="vertraglichesPensum.value"
               min="0"
               class="form-control no-margin"
               ng-attr-placeholder="{{title}}"
               uib-tooltip="{{title}}"
               tooltip-placement="top-left">
    </div>
</div>
