<div class="dvb-tagesschul-anmeldung-confirmation-overview">

    <div class="row">
        <div class="col-md-6">
            <strong>
                <dvb-kibon-ref-nummer kibon-ref-nr-info="vm.anmeldung"></dvb-kibon-ref-nummer>
            </strong>
            <p ng-bind="vm.anmeldung.institution.getDisplayName()"></p>
            <p>
                <span data-translate="KIBON.TAGESSCHULE_ANMELDUNG.EINTRITTSDATUM"></span>
                <span ng-bind="vm.anmeldung.eintrittsdatum | amDateFormat: 'D.M.YYYY'"></span>
            </p>
            <p>
                <span data-translate="KIBON.TAGESSCHULE_ANMELDUNG.PLAN_KLASSE"></span>:
                <span ng-bind="vm.anmeldung.planKlasse"></span>
            </p>
        </div>
        <div class="col-md-6">
            <dvb-wochenplan class="small"
                            distinct-betreuungs-zeitraeume="vm.tagesschulModule.module"
                            week-days="vm.weekDays"
                            show-betreuungs-zeitraum-name="true"
                            interactive="false"
                            show-null-value-as-plain="true"
                            zeitraum-felder="vm.zeitraumFelder">
            </dvb-wochenplan>
        </div>
    </div>
    <div class="row normal-abstand-top">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.anmeldung.abweichungZweitesSemester" ng-disabled="true">
                    <span data-translate="KIBON.TAGESSCHULE_ANMELDUNG.ABWEICHUNG_SEMESTER_2"></span>
                </label>
                <p ng-if="vm.anmeldung.bemerkung"
                   class="small-abstand-top normal-abstand-bottom"
                   ng-bind="vm.anmeldung.bemerkung">
                </p>
            </div>
        </div>
    </div>

</div>
