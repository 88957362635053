/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtId, Leistungsrechnung} from '@dv/kitadmin/models';
import {checkPresent, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {LeistungsrechnungenByMonth} from './LeistungsrechnungenByMonth';
import type {KinderOrtService} from './rest/kinderort/kinderOrtService';

export class LeistungenService {
    public static $inject: readonly string[] = ['kinderOrtService', '$q'];

    public constructor(
        private kinderOrtService: KinderOrtService,
        private $q: angular.IQService,
    ) {
    }

    public createLeistungsrechnungenByMonths(
        leistungsrechnungen: Leistungsrechnung[],
        year: number,
    ): LeistungsrechnungenByMonth[] {
        const leistungsrechnungenByMonth = this.initLeistungsrechnungenByMonth(year);

        leistungsrechnungen.forEach(leistungsrechnung => {
            const found = this.getLeistungsrechnungMonthlyByMonat(
                leistungsrechnungenByMonth, leistungsrechnung.gueltigAb);

            if (found) {
                found.leistungsrechnungen.push(leistungsrechnung);
            }
        });

        return leistungsrechnungenByMonth;
    }

    public initLeistungsrechnungenByMonth(year: number): LeistungsrechnungenByMonth[] {
        return DvbDateUtil.createMonthsForYear(year)
            .map(startOfMonth => new LeistungsrechnungenByMonth(startOfMonth));
    }

    public getLeistungsrechnungMonthlyByMonat(
        leistungsrechnungenByMonth: LeistungsrechnungenByMonth[],
        startOfMonth: moment.Moment | null,
    ): LeistungsrechnungenByMonth | null {
        const result = leistungsrechnungenByMonth.filter(
            lr => DvbDateUtil.isMomentEquals(lr.startOfMonth, startOfMonth));

        return result.length > 0 ? result[0] : null;
    }

    public fetchKitaNamen(leistungsrechnungen: Leistungsrechnung[]): angular.IPromise<{ [k in KinderOrtId]: string }> {
        const kitaIds = DvbUtil.uniqueArray(leistungsrechnungen
            .map(leistungsrechnung => checkPresent(leistungsrechnung.kitaId)));

        const promises = kitaIds
            .map(kitaId => this.kinderOrtService.get(kitaId, {cache: true}));

        return this.$q.all(promises).then(kitas => {
            const kitaNamen: { [k in KinderOrtId]: string } = {};

            kitas.forEach(kita => {
                kitaNamen[kita.id] = kita.getDisplayName();
            });

            return kitaNamen;
        });
    }
}
