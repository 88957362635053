/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {EingewoehnungPosition, FixPosition, Kind, KinderOrtId, Leistungsrechnung} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {
    KinderEingewoehnungPositionenService,
} from '@dv/shared/backend/api/kinder-eingewoehnung-positionen.service';
import type {DvbError, Nullish, Persisted} from '@dv/shared/code';
import {BEGIN_OF_TIME, checkPresent, DvbDateUtil, DvbRestUtil, DvbUtil, END_OF_TIME} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import moment from 'moment';
import {from, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {LeistungenService} from '../../../common/service/leistungenService';
import type {LeistungsrechnungenByMonth} from '../../../common/service/LeistungsrechnungenByMonth';
import type {FixPositionenService} from '../../../common/service/rest/kind/fixPositionenService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import {SimpleKibonRefNrInfo} from '../../../kibon/models/SimpleKibonRefNrInfo';
import type {MutationBadgeInfoService} from '../../../kibon/service/mutationBadgeInfoService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        year: '<?',
        month: '<?',
    },
    template: require('./dvb-kind-leistungen.html'),
    controllerAs: 'vm',
};

export class DvbKindLeistungen implements angular.IController {

    public static $inject: readonly string[] = [
        'leistungenService',
        'kindService',
        'fixPositionenService',
        'kinderEingewoehnungPositionenService',
        '$state',
        '$q',
        '$translate',
        'dialogService',
        'errorService',
        'mutationBadgeInfoService',
    ];

    public kind!: Persisted<Kind>;
    public year: number | Nullish;
    public month: number | Nullish;

    public selectedStartOfMonth: moment.Moment | null = null;
    public kitaNamen: { [k in KinderOrtId]: string } = {};
    public isLoading: boolean = false;

    public leistungsrechnungenWithResults: Leistungsrechnung[] = [];
    public activeLeistungsrechnungen: number[] = [];
    public totalLeistungen: number | null = null;

    public isShowingDownloads: boolean = false;
    public leistungsjahr: moment.Moment = DvbDateUtil.startOfYear();
    public gueltigAb: moment.Moment = BEGIN_OF_TIME;
    public gueltigBis: moment.Moment = END_OF_TIME;
    public leistungsrechnungenMonthly: LeistungsrechnungenByMonth[] = [];
    public mutationBadgeRefNrs: SimpleKibonRefNrInfo[] = [];

    private timeout?: angular.IDeferred<any>;

    public constructor(
        private readonly leistungenService: LeistungenService,
        private readonly kindService: KindService,
        private readonly fixPositionenService: FixPositionenService,
        private readonly eingewoehnungPositionenService: KinderEingewoehnungPositionenService,
        private readonly $state: StateService,
        private readonly $q: angular.IQService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly dialogService: DialogService,
        private readonly errorService: ErrorService,
        private readonly mutationBadgeInfoService: MutationBadgeInfoService,
    ) {
    }

    private static isMonthNumber(value: unknown): boolean {
        return DvbUtil.isInteger(value) && 0 < value && value <= DvbDateUtil.NUMBER_OF_MONTHS;
    }

    public $onInit(): void {
        this.loadData();
    }

    public $onChanges(changes: IOnChangesObject): void {
        if (changes.year?.currentValue) {
            const year = moment().year(changes.year.currentValue);
            if (DvbDateUtil.isValidMoment(year)) {
                this.leistungsjahr = DvbDateUtil.startOfYear(year);
            }
        }

        if (!changes.month?.currentValue) {

            return;
        }

        const monthNumber = parseInt(changes.month.currentValue, 10);
        if (DvbKindLeistungen.isMonthNumber(monthNumber)) {
            this.selectedStartOfMonth = DvbDateUtil.startOfMonth(moment(this.leistungsjahr).month(monthNumber - 1));
        }
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public onSelectMonth(startOfMonth: moment.Moment): void {
        this.selectedStartOfMonth = this.selectedStartOfMonth?.isSame(startOfMonth) ? null : startOfMonth;
    }

    public deleteFixPosition(fixPosition: FixPosition): void {
        const entityText = this.$translate.instant('KIND.FIX_POSITION_TEXT', {
            type: this.$translate.instant(`KIND.FIX_POSITION_TYP_${fixPosition.typ}`),
            datum: fixPosition.leistungsDatum!.format('DD. MMM YYYY'),
        });

        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(this.fixPositionenService.deleteFixPosition(checkPresent(fixPosition.id)))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public deleteEingewoehnungPosition(eingewoehnungPosition: EingewoehnungPosition): void {
        const entityText = this.$translate.instant('KIND.EINGEWOEHNUNG_POSITION.DELETE_TITLE', {
            gueltigAb: eingewoehnungPosition.gueltigAb!.format('DD.MM.'),
            gueltigBis: eingewoehnungPosition.gueltigBis!.format('DD.MM.'),
        });

        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(this.eingewoehnungPositionenService.deleteEingewoehnungPosition$({
                eingewoehnungPositionId: eingewoehnungPosition.id!,
            }))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public deleteMutationBadgesForRefNr(simpleRefNr: SimpleKibonRefNrInfo): void {
        const refNr = simpleRefNr.getRefNr();
        const title = this.$translate.instant(
            'COMMON.LEISTUNGSRECHNUNG_WARNINGS.PENDENTE_VERFUEGUNG_MELDUNG.DELETE_FOR_REF_NR',
            {refNr});
        const subtitle = this.$translate.instant(
            'COMMON.LEISTUNGSRECHNUNG_WARNINGS.PENDENTE_VERFUEGUNG_MELDUNG.DELETE_FOR_REF_NR_INFO');
        const confirmActionText = this.$translate.instant('COMMON.LOESCHEN');

        this.dialogService.openConfirmDialog({
            title,
            subtitle,
            confirmActionText,
            confirm: () => from(this.mutationBadgeInfoService.deleteForKind(this.kind.id, refNr))
                .pipe(take(1), tap(() => this.loadData())),
        });
    }

    public deleteMutationBadge(leistungsrechnungId: string): void {
        this.dialogService.openConfirmDialog({
            title: 'COMMON.LEISTUNGSRECHNUNG_WARNINGS.PENDENTE_VERFUEGUNG_MELDUNG.BADGE_MONAT_LOESCHEN',
            confirm: () => from(this.mutationBadgeInfoService.deleteForLeistungsrechnung(leistungsrechnungId))
                .pipe(take(1), tap(() => this.loadData())),
        });
    }

    public loadData(): void {
        const year = this.leistungsjahr.year();

        if (!year || year < BEGIN_OF_TIME.year() || year > END_OF_TIME.year()) {
            this.errorService.handleValidationError(false, 'ERRORS.ERR_INVALID_JAHR');

            return;
        }

        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();

        this.isLoading = true;
        this.leistungsrechnungenMonthly = [];
        this.kitaNamen = {};

        const startJahr = DvbDateUtil.startOfYear(moment(year, 'YYYY'));
        const endeJahr = DvbDateUtil.endOfYear(moment(year, 'YYYY'));

        const config = {timeout: this.timeout.promise};
        this.loadLeistungsrechnungen(startJahr, endeJahr, year, config);
        this.loadMutationBadgeInfos(startJahr, endeJahr, config);
    }

    private loadLeistungsrechnungen(
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
        year: number,
        config: { timeout: angular.IPromise<any> },
    ): void {
        const params = {
            gueltigAb,
            gueltigBis,
            includes: '(warnings)',
        };

        this.kindService.getLeistungsrechnungen(this.kind.id, params, config)
            .then(leistungsrechnungen => {
                const leistungsrechnungenByMonths = this.leistungenService
                    .createLeistungsrechnungenByMonths(leistungsrechnungen, year)
                    .filter(lrm => this.showLeistungenProMonat(lrm));

                return this.leistungenService.fetchKitaNamen(leistungsrechnungen)
                    .then(kitaNamen => {
                        this.leistungsrechnungenMonthly = leistungsrechnungenByMonths;
                        this.kitaNamen = kitaNamen;
                        this.isLoading = false;
                    });
            })
            .catch((error: DvbError) => {
                if (DvbRestUtil.isRequestCancelled(error)) {
                    this.leistungsrechnungenMonthly = [];
                    this.kitaNamen = {};

                    return this.$q.resolve();
                }
                console.error('Leistungsrechnungen können nicht ermittelt werden', error);
                this.isLoading = false;

                return this.$q.reject(error);
            });
    }

    private loadMutationBadgeInfos(
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
        config: { timeout: angular.IPromise<any> },
    ): void {
        const params = {gueltigAb, gueltigBis};

        this.mutationBadgeRefNrs = [];

        this.mutationBadgeInfoService.getForKind(this.kind.id, params, config)
            .then(refNrs => {
                refNrs.sort((a, b) => a.localeCompare(b));

                this.mutationBadgeRefNrs = refNrs.map(refNr => new SimpleKibonRefNrInfo(refNr));
            })
            .catch((error: DvbError) => {
                if (DvbRestUtil.isRequestCancelled(error)) {
                    this.mutationBadgeRefNrs = [];

                    return this.$q.resolve();
                }
                console.error('Leistungsrechnungen können nicht ermittelt werden', error);
                this.isLoading = false;

                return this.$q.reject(error);
            });
    }

    // noinspection JSMethodCanBeStatic
    private showLeistungenProMonat(leistungsrechnungMonthly: LeistungsrechnungenByMonth): boolean {
        if (leistungsrechnungMonthly.leistungsrechnungen.length === 0) {
            return false;
        }

        return leistungsrechnungMonthly.leistungsrechnungen.some(l => l.isDisplayable());
    }
}

componentConfig.controller = DvbKindLeistungen;
angular.module('kitAdmin').component('dvbKindLeistungen', componentConfig);
