<div class="dvb-kitax-import row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2 data-translate="KIND.KITAX.MF_VERFUEGUNG"
            data-translate-value-type="{{vm.verfuegung.schemaVersion.type}}"
            data-translate-interpolation="messageformat"></h2>

        <form ng-if="vm.isModeUpload"
              ng-submit="vm.onSubmit(uploadKiTaxForm)"
              name="uploadKiTaxForm"
              novalidate
              class="dvb-form">
            <div class="form-row row">
                <div class="col-xs-12">
                    <dvb-file-upload label-upload="COMMON.DATEI_IMPORTIEREN"
                                     ng-model="vm.file"
                                     required>
                    </dvb-file-upload>
                </div>
            </div>

            <div class="form-row row big-abstand-top">
                <div class="col-xs-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit" is-loading="vm.isLoading">
                                <span data-translate="KIND.KITAX.DATEN_UEBERPRUEFEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li>
                            <a ui-sref="base.kind.tarife.uebersicht" data-translate="COMMON.ABBRECHEN"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </form>

        <dvb-kitax-import-preview ng-if="!vm.isModeUpload"
                                  verfuegung="vm.verfuegung"
                                  kind="vm.kind">
        </dvb-kitax-import-preview>
    </div>
</div>
