/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {KibonRefNrService} from '@dv/shared/backend/api/kibon-ref-nr.service';
import {type Persisted} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {finalize, of, tap} from 'rxjs';
import type {KibonGenerationContextResult} from '../../models/KibonGenerationContextResult';
import type {KibonExchangeBetreuungService} from '../../service/kibonExchangeBetreuungService';
import type {KibonIgnoreRefNrDialogModel} from '../kibon-ignore-ref-nr-dialog/kibon-ignore-ref-nr-dialog.component';
import {KibonIgnoreRefNrDialogComponent} from '../kibon-ignore-ref-nr-dialog/kibon-ignore-ref-nr-dialog.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
    },
    template: require('./dvb-kind-kibon.html'),
    controllerAs: 'vm',
};

export class DvbKindKibon implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'kibonExchangeBetreuungService',
        'errorService',
        'dialogService',
        'kibonRefNrService',
    ];

    public kind!: Persisted<Kind>;

    public isLoadingKibonRefNr: boolean = false;
    public isRefreshing: boolean = false;
    public isShowingGenerationResult: boolean = false;
    public generationResults: KibonGenerationContextResult[] = [];

    public constructor(
        private readonly $state: StateService,
        private readonly kibonService: KibonExchangeBetreuungService,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly kibonRefNrService: KibonRefNrService,
    ) {
    }

    public refresh(): void {
        this.isRefreshing = true;
        this.kibonService.refreshKind(this.kind.id)
            .then(generationResults => {
                generationResults
                    .filter(r => r.isFailed())
                    .map(f => f.toDvbError())
                    .forEach(error => this.errorService.handleError(error));

                this.generationResults = generationResults;
            })
            .finally(() => {
                this.isRefreshing = false;
            });
    }

    public manageIgnoredRefNumbers(): void {
        this.isLoadingKibonRefNr = true;
        this.kibonRefNrService.getForKind$({
            kindId: this.kind.id,
        }).pipe(
            tap(refNumbers => {
                const options: KibonIgnoreRefNrDialogModel<null> = {
                    title: 'KIBON.REFERENZ_NUMMER_VERWALTEN.HEADER',
                    kindId: this.kind.id,
                    remainingRefNumbers: refNumbers.remainingRefNumbers.sort((a, b) => b.localeCompare(a)),
                    ignoredRefNumbers: refNumbers.ignoredRefNumbers.sort((a, b) => b.refNr.localeCompare(a.refNr)),
                    confirm: () => of(this.$state.reload()),
                };

                this.dialogService.openDialog(KibonIgnoreRefNrDialogComponent, options);
            }),
            finalize(() => {
                this.isLoadingKibonRefNr = false;
            }),
        ).subscribe();
    }
}

componentConfig.controller = DvbKindKibon;
angular.module('kitAdmin').component('dvbKindKibon', componentConfig);
