/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Belegung,
    BetreuungsVereinbarungsKonfigurationType,
    ExtraPlatz,
    GruppenBelegung,
    KinderOrt,
    KinderOrtFraktion,
    KinderOrtFraktionId,
    KinderOrtId,
    Kontingente,
    KontingentId,
    VertraglichesPensum,
} from '@dv/kitadmin/models';
import {Betreuungsfaktor, PensumType, ZeitraumUtil} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {DayOfWeek, FunctionType, Persisted} from '@dv/shared/code';
import {checkPresent, DvbUtil, KindergartenBelegung, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import {from, take} from 'rxjs';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {
    BetreuungsVereinbarungsTypeSelectDialogModel,
} from '../../component/betreuungs-vereinbarungs-type-select/betreuungs-vereinbarungs-type-select.component';
import {
    BetreuungsVereinbarungsTypeSelectComponent,
} from '../../component/betreuungs-vereinbarungs-type-select/betreuungs-vereinbarungs-type-select.component';
import type {BelegungsService} from '../../service/rest/kind/belegungsService';
import type {
    BetreuungsVereinbarungsKonfigurationService,
} from '../../service/rest/kinderort/betreuungsVereinbarungsKonfigurationService';
import type {FraktionService} from '../../service/rest/kinderort/fraktionService';
import type {KinderOrtService} from '../../service/rest/kinderort/kinderOrtService';
import {WochenplanUtil} from '../../service/wochenplanUtil';

const directive: angular.IDirective = {
    restrict: 'E',
    replace: true,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    scope: {
        belegung: '<',
        extraPlaetze: '<?',
        noDate: '<?',
        isDateClickable: '<?',
        showBetreuungsVereinbarung: '<?',
        showBemerkungen: '<?',
        hideIcons: '<?',
        titleKey: '<?',
        onDateSelected: '&',
        onInitialisationCompleted: '&',
    },
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    template: require('./dvb-belegung.html'),
};

export class DvbBelegung implements angular.IController {

    public static $inject: readonly string[] = [
        'fraktionService',
        'kinderOrtService',
        'belegungsService',
        'authStore',
        '$q',
        '$translate',
        'betreuungsVereinbarungsKonfigurationService',
        'dialogService',
        '$filter',
    ];

    public belegung!: Belegung;
    public extraPlaetze: ExtraPlatz[] = [];
    public noDate?: boolean;
    public isDateClickable?: boolean;
    public showBetreuungsVereinbarung?: boolean;
    public showBemerkungen?: boolean;
    public hideIcons?: boolean;
    public titleKey?: string;
    public onDateSelected?: FunctionType;
    public onInitialisationCompleted?: FunctionType;

    public isCollapsed: boolean = true;
    public isLoadingBetreuungsVereinbarung: boolean = false;
    public hasCustomFieldValue: boolean = false;
    public betreuungsfaktor: Betreuungsfaktor | null = null;
    public enabledDays: DayOfWeek[] = [];

    public gruppen: { [k in KinderOrtFraktionId]: KinderOrtFraktion } = {};
    public kitas: { [k in KinderOrtId]: Persisted<KinderOrt> } = {};
    private kontingente: { [k in KontingentId]: Kontingente } = {};

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly fraktionService: FraktionService,
        private readonly kinderOrtService: KinderOrtService,
        private readonly belegungsService: BelegungsService,
        private readonly authStore: AuthStore,
        private readonly $q: angular.IQService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly betreuungsVereinbarungsKonfigurationService: BetreuungsVereinbarungsKonfigurationService,
        private readonly dialogService: DialogService,
        private readonly $filter: angular.IFilterService,
    ) {
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.belegung) {
            this.initBelegung();
        }
    }

    public getGruppe(gruppenBelegung: GruppenBelegung): KinderOrtFraktion | undefined {
        return this.gruppen[checkPresent(gruppenBelegung.gruppeId)];
    }

    public getKita(gruppenBelegung: GruppenBelegung): KinderOrt | undefined {
        return this.kitas[checkPresent(gruppenBelegung.gruppeId)];
    }

    public getKitas(): KinderOrt[] {
        return DvbUtil.uniqueByMapper(Object.values(this.kitas), DvbUtil.mapToId)
            .filter(kinderOrt => this.authStore.hasPermission(PERMISSION.KITA.CONTROL + checkPresent(kinderOrt.id)));
    }

    public showKindergarten(): boolean {
        return this.belegung.kindergartenBelegung !== KindergartenBelegung.KEINE;
    }

    public getBetreuungsVereinbarungType(kitaId: KinderOrtId): void {
        this.betreuungsVereinbarungsKonfigurationService.getAllTypes(kitaId)
            .then(types => types.length <= 1 ?
                this.getBetreuungsVereinbarung(kitaId, types[0]?.id ?? null) :
                this.openVereinbarungsTypeDialog(kitaId, types),
            );
    }

    public getVertraglichesPensumSuffix(vertraglichesPensum: VertraglichesPensum): string {
        const translationKey = `KIND.ZUWEISUNG.VERTRAGLICHE_STUNDEN${
            PensumType.PERCENTAGE === vertraglichesPensum.type ? '.PERCENTAGE' : ''}`;

        if (!vertraglichesPensum.kontingentId) {
            return this.$translate.instant(`${translationKey}.DISPLAY_PRIVAT`, {
                value: vertraglichesPensum.value,
            });
        }

        const kontingent = this.kontingente[vertraglichesPensum.kontingentId];
        if (!kontingent) {
            return '';
        }

        if (kontingent.isSubventioniertesKontingent()) {
            return this.$translate.instant(`${translationKey}.DISPLAY_SUBVENTIONIERT`, {
                value: vertraglichesPensum.value,
            });
        }

        if (kontingent.isFirmenKontingent()) {
            return this.$translate.instant(`${translationKey}.DISPLAY_FIRMA`, {
                name: kontingent.getDisplayName(),
                value: vertraglichesPensum.value,
            });
        }

        throw new Error(`not implemented for Kontingent of type ${(kontingent as unknown as Kontingente).type}`);
    }

    private getBetreuungsVereinbarung(
        kitaId: KinderOrtId,
        betreuungsvereinbarungTypeId: string | null,
    ): angular.IPromise<any> {
        this.isLoadingBetreuungsVereinbarung = true;

        return this.belegungsService.getBetreuungsVereinbarung(
            checkPresent(this.belegung.id),
            kitaId,
            betreuungsvereinbarungTypeId)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => {
                this.isLoadingBetreuungsVereinbarung = false;
            });
    }

    private openVereinbarungsTypeDialog(kitaId: KinderOrtId, types: BetreuungsVereinbarungsKonfigurationType[]): void {
        const orderedTypes = this.$filter('orderBy')(types, 'name | dvbI18n');

        const dialogModel: BetreuungsVereinbarungsTypeSelectDialogModel = {
            selectedType: orderedTypes[0],
            types: orderedTypes,
            confirm: type => from(this.getBetreuungsVereinbarung(kitaId, type.id)).pipe(take(1)),
        };

        this.dialogService.openDialog(BetreuungsVereinbarungsTypeSelectComponent, dialogModel);
    }

    private initBelegung(): void {
        this.gruppen = {};
        this.kitas = {};
        this.kontingente = {};
        this.betreuungsfaktor = null;
        const initialisationCompletedCallback = this.onInitialisationCompleted;
        if (!this.belegung) {
            if (TypeUtil.isFunction(initialisationCompletedCallback)) {
                initialisationCompletedCallback();
            }

            return;
        }

        this.hasCustomFieldValue = this.belegung.customFieldValues.some(value => value.id !== null);

        if (this.belegung.betreuungsfaktor) {
            this.betreuungsfaktor = new Betreuungsfaktor(this.belegung.betreuungsfaktor);
            this.betreuungsfaktor.spezifisch = true;
        }

        const promises: angular.IPromise<unknown>[] = [];

        this.belegung.gruppenBelegungen.forEach(gruppenBelegung => {
            const promise = {
                gruppe: this.fetchGruppe(gruppenBelegung),
                kontingente: this.fetchKontingente(gruppenBelegung, this.extraPlaetze),
                kita: this.fetchKita(gruppenBelegung),
            };

            const all = this.$q.all(promise).then(data => {
                const wochenplan = checkPresent(data.gruppe.wochenplan);
                const kontingente = data.kontingente;
                const showKontingente = this.showKontingente(data.kita);

                data.kontingente.forEach(kontingent => {
                    this.kontingente[checkPresent(kontingent.id)] = kontingent;
                });

                WochenplanUtil.setGruppenBelegungToWochenplan(wochenplan, gruppenBelegung,
                    kontingente, showKontingente);

                const relevantExtraPlaetze = this.extraPlaetze.filter(p => p.kinderOrtFraktionId === data.gruppe.id!);
                WochenplanUtil.markExtraPlaetzeOnWochenplan(wochenplan,
                    relevantExtraPlaetze,
                    kontingente,
                    showKontingente);
            });

            promises.push(all);
        });

        const promise = this.$q.all(promises).then(result => {
            this.updateWeekdays();
            this.belegung.gruppenBelegungen.sort((a, b) => this.compare(a, b));

            return result;
        });

        if (TypeUtil.isFunction(initialisationCompletedCallback)) {
            promise.then(() => initialisationCompletedCallback());
        }
    }

    private compare(a: GruppenBelegung, b: GruppenBelegung): number {
        const compareByKinderOrt = DvbUtil.compareByDisplayName(this.getKita(a), this.getKita(b));

        if (compareByKinderOrt === 0) {
            return DvbUtil.compareByDisplayName(this.getGruppe(a), this.getGruppe(b));
        }

        return compareByKinderOrt;
    }

    private updateWeekdays(): void {
        this.enabledDays = ZeitraumUtil.getWeekDaysFromGruppen(Object.values(this.gruppen));
    }

    private fetchGruppe(gruppenBelegung: GruppenBelegung): angular.IPromise<KinderOrtFraktion> {
        if (gruppenBelegung.gruppe) {
            this.$q.resolve(gruppenBelegung.gruppe);
        }

        const gruppeId = checkPresent(gruppenBelegung.gruppeId);

        if (gruppeId in this.gruppen) {
            return this.$q.resolve(this.gruppen[gruppeId]);
        }

        return this.fraktionService.getWithWochenplan(gruppeId, {cache: true})
            .then(gruppe => {
                this.gruppen[gruppeId] = gruppe;

                return gruppe;
            });
    }

    private fetchKontingente(
        gruppenBelegung: GruppenBelegung,
        extraPlaetze: ExtraPlatz[],
    ): angular.IPromise<Kontingente[]> {
        const plaetze = gruppenBelegung.plaetze.concat(extraPlaetze);
        const kontingentIds = DvbUtil.uniqueArray(
            plaetze.filter(p => p.hasKontingent())
                .map(p => checkPresent(p.kontingentId)));

        const params = {cache: true, includes: '(firma)'};
        const promises = kontingentIds.map(id => this.kinderOrtService.getKontingent(id, params));

        if (promises.length === 0) {
            return this.$q.resolve([]);
        }

        return this.$q.all(promises);
    }

    private fetchKita(gruppenBelegung: GruppenBelegung): angular.IPromise<Persisted<KinderOrt>> {
        const gruppeId = checkPresent(gruppenBelegung.gruppeId);

        if (gruppeId in this.kitas) {
            return this.$q.resolve(this.kitas[gruppeId]);
        }

        const params = {cache: true};

        return this.fraktionService.getKinderOrt(gruppeId, params)
            .then(kinderOrt => {
                this.kitas[gruppeId] = kinderOrt;

                return kinderOrt;
            });
    }

    private showKontingente(kinderOrt: Persisted<KinderOrt>): boolean {
        return this.authStore.hasPermission(PERMISSION.KITA.VIEW_PLATZ_KONTINGENT_TYPE + kinderOrt.id);
    }
}

directive.controller = DvbBelegung;
angular.module('kitAdmin').directive('dvbBelegung', () => directive);
