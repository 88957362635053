/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {hasOwnPropertyGuarded} from '@dv/shared/code';

angular.module('kitAdmin').directive('dvbCapitalizeFirstLetter', dvbCapitalizeFirstLetter);

function dvbCapitalizeFirstLetter(): angular.IDirective {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (_scope, element, _attrs, controller: any) => {

            let corrected = false; // Auto capitalized is switched of, after an input is corrected once
            let maxLength = 0;

            controller.$parsers.push((value: string) => {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                if (!corrected && value.length < 3 && value.length > maxLength) {
                    if (value.length > maxLength) {
                        maxLength = value.length;
                    }
                    const transformedInput = capitalizeValue(value);
                    if (transformedInput !== value && isCursorAtEndOfText(value)) {
                        controller.$setViewValue(transformedInput);
                        controller.$render();
                        corrected = true;

                        return transformedInput;
                    }
                }

                return value;
            });

            function isCursorAtEndOfText(text: string): boolean {
                const el = element[0];

                return hasOwnPropertyGuarded(el, 'selectionStart')
                    && hasOwnPropertyGuarded(el, 'selectionEnd')
                    && el.selectionStart === el.selectionEnd
                    && el.selectionStart === text.length;
            }

            function capitalizeValue(value: string): string {
                const reg = /([^\W_]+[^\s-]*)/;

                return value ? value.replace(reg, replacer) : '';
            }

            function replacer(txt: string): string {
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
            }
        },
    };
}
