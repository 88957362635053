/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {ExternalAnmeldungConfig, WochenplanExternalAnmeldung} from '@dv/kitadmin/models';
import type {NoContent, Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

export class ExternalAnmeldungConfigService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/externalanmeldung/config`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getCurrentConfig(): angular.IPromise<ExternalAnmeldungConfig | NoContent> {
        const url = `${ExternalAnmeldungConfigService.BASE_URL}/current`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, ExternalAnmeldungConfig);
    }

    public createConfig(config: ExternalAnmeldungConfig): angular.IPromise<ExternalAnmeldungConfig> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${ExternalAnmeldungConfigService.BASE_URL}`;

        return DvbRestUtilAngularJS.postAndGetModel(url, config.toRestObject(), ExternalAnmeldungConfig);
    }

    public updateConfig(config: Persisted<ExternalAnmeldungConfig>): angular.IPromise<ExternalAnmeldungConfig> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${ExternalAnmeldungConfigService.BASE_URL}/${encodeURIComponent(config.id)}`;

        return DvbRestUtilAngularJS.putAndGetModel(url, config.toRestObject(), ExternalAnmeldungConfig);
    }

    public getKinderOrtWochenplaene(kinderOrt: Persisted<KinderOrt>): angular.IPromise<WochenplanExternalAnmeldung[]> {
        const url = `${ExternalAnmeldungConfigService.BASE_URL}/kinderort/${kinderOrt.id}/wochenplaene`;

        return DvbRestUtilAngularJS.getModelsArray(url, WochenplanExternalAnmeldung, 'items');
    }

    public updateKinderOrtWochenplaene(
        kinderOrt: Persisted<KinderOrt>,
        plaene: WochenplanExternalAnmeldung[],
    ): angular.IPromise<WochenplanExternalAnmeldung[]> {

        const url = `${ExternalAnmeldungConfigService.BASE_URL}/kinderort/${kinderOrt.id}/wochenplaene`;
        const data = {wochenplaene: plaene.map(p => p.toRestObject())};

        return this.$http.put<{ items: unknown[] }>(url, data).then(result =>
            DvbRestUtil.transformArray(result.data.items, WochenplanExternalAnmeldung));
    }
}
