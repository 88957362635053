<div class="kinderliste">

    <div class="eintrag"
         ng-click="vm.selectedKindChanged(vm.kind); vm.loaded = true;"
         ng-class="{'selected-eintrag': vm.selectedKindId === vm.kind.id}">

        <!-- STATUS ICON -->
        <div class="status-icon">
            <dvb-belegungs-zustand-icon belegungs-zustand="vm.status">
            </dvb-belegungs-zustand-icon>
            <span ng-if="vm.status === 'WARTELISTE'"
                  class="custom-icon custom-icon-warteliste"
                  uib-tooltip="{{'COMMON.ZUWEISUNG_IST_WARTELISTE' | translate}}"
                  tooltip-placement="top-left">
            </span>
        </div>

        <!-- NAME -->
        <strong class="status-und-name" ng-bind="vm.kind.getDisplayName()"></strong>

        <!-- HINWEIS ICONS -->
        <div class="icons">
            <ul class="inline-icons no-margin" ng-show="!vm.isBetreuungsfaktorLoading">
                <li class="betreuungsfaktor" ng-if="vm.betreuungsfaktor && vm.betreuungsfaktor.betreuungsfaktor !== 1">
                    <dvb-betreuungsfaktor-icon faktor="vm.betreuungsfaktor"></dvb-betreuungsfaktor-icon>
                </li>
                <li ng-show="vm.kind.hasGeschwister()">
                    <i class="fa fa-chain"
                       uib-tooltip="{{'COMMON.ZUWEISUNG_HAT_GESCHWISTER' | translate}}"
                       tooltip-placement="top-left">
                    </i>
                </li>
            </ul>
        </div>

        <!-- PENSUM -->
        <div class="pensum text-left" ng-if="vm.listModel === 'belegung'">
            <span ng-bind="(vm.belegung.kitaPensen[vm.kitaId] || 0) | number:0"></span>%
        </div>
        <div class="pensum text-left"
             ng-if="vm.listModel === 'bewerbung'"
             ng-bind="vm.kind.bewerbung.displayPensum()">
        </div>

        <!-- PLAETZE -->
        <div class="plaetze">
            <dvb-platz-typen class="inline-icons no-margin"
                             dvb-auth
                             require-permission="{{'kita:control:' + vm.kitaId}}"
                             platz-typen="vm.platzTypen">
            </dvb-platz-typen>
        </div>

        <!-- KINDERGARTEN -->
        <div class="kindergarten">
            <ul class="list-inline no-margin">
                <li ng-if="vm.showKindergarten()">
                    <dvb-kindergarten-icon kindergarten-belegung="{{vm.kindergartenBelegung}}"></dvb-kindergarten-icon>
                </li>
            </ul>
        </div>

        <!-- TAGE -->
        <div class="tage">
            <div class="tag"
                 ng-repeat="dayOfWeek in vm.weekDays"
                 ng-bind="vm.getDayMoment(dayOfWeek) | amDateFormat: 'dd'"
                 ng-class="{ 'selected-tag' : vm.isSelectedTag(dayOfWeek),
                 'filtered-tag' : vm.isFilteredTag(dayOfWeek),
                 'hidden-print': !vm.isSelectedTag(dayOfWeek)}">
            </div>
        </div>

        <!-- HOVER -->
        <div class="hover-only">
            <div class="geburtstag"
                 ng-show="vm.kind.geburtsTag"
                 data-translate="COMMON.KIND_GEBOREN"
                 data-translate-value-gebdatum="{{vm.kind.geburtsTag | amDateFormat: 'D.M.YYYY'}}">
            </div>
            <div class="geburtstag"
                 ng-hide="vm.kind.geburtsTag"
                 data-translate="COMMON.KIND_GEBOREN"
                 data-translate-value-gebdatum="{{vm.kind.geburtsTermin | amDateFormat: 'D.M.YYYY'}}">
            </div>
            <div class="prio"
                 ng-show="vm.kind.bewerbung"
                 uib-tooltip="{{'COMMON.PRIORITAET' | translate:{prioritaet: vm.kind.bewerbung.prioritaet} }}"
                 tooltip-placement="top-left"
                 data-translate="KINDERORT.PRIORITAET_SHORT"
                 data-translate-value-prioritaet="{{vm.kind.bewerbung.prioritaet}}">
            </div>
        </div>
    </div>
    <div class="detail"
         uib-collapse="vm.selectedKindId !== vm.kind.id || !vm.isInitialised()"
         ng-if="vm.selectedKindId === vm.kind.id || vm.loaded">

        <!-- BETREUUNGSWUNSCH -->
        <dvb-collapsible-panel ng-if="vm.kind.bewerbung" is-collapsed="false">
            <panel-header>
                <dvb-betreuungswunsch-header bewerbung="vm.kind.bewerbung"
                                             on-date-selected="vm.onDateSelected({date: vm.kind.bewerbung.gewuenschteBetreuungAb}); $event.stopPropagation();">
                </dvb-betreuungswunsch-header>
            </panel-header>
            <panel-content>
                <dvb-betreuungswunsch anmeldedatum="vm.kind.anmeldeDatum"
                                      bemerkung="{{vm.kind.bemerkung}}"
                                      bewerbung="vm.kind.bewerbung"
                                      wochenplan="vm.wochenplanBewerbung"
                                      on-date-selected="vm.onDateSelected({date: vm.kind.bewerbung.gewuenschteBetreuungAb}); $event.stopPropagation();">
                </dvb-betreuungswunsch>
            </panel-content>
        </dvb-collapsible-panel>

        <!-- DIE BELEGUNG(EN)-->
        <dvb-collapsible-panel ng-if="vm.belegung" limited-entity="vm.belegung" is-collapsed="false">
            <panel-header>
                <dvb-belegung-interval-header is-date-clickable="true"
                                              belegung-interval="vm.belegung"
                                              no-date="vm.hideBelegungDate"
                                              on-date-selected="vm.onDateSelected({date: vm.belegung.gueltigAb}); $event.stopPropagation();">
                </dvb-belegung-interval-header>
            </panel-header>
            <panel-content>
                <dvb-belegung belegung="vm.belegung"
                              extra-plaetze="vm.extraPlaetze"
                              is-date-clickable="true"
                              no-date="vm.hideBelegungDate"
                              show-bemerkungen="true"
                              on-initialisation-completed="vm.belegungInitialised = true"
                              on-date-selected="vm.onDateSelected({date: vm.belegung.gueltigAb}); $event.stopPropagation();">
                </dvb-belegung>
            </panel-content>
        </dvb-collapsible-panel>

        <div class="kind-actions hidden-print">
            <ul class="list-inline no-margin">
                <li dvb-auth require-permission="{{'kita:manage:' + vm.kitaId}}">
                    <button type="button"
                            class="btn btn-primary"
                            ng-click="vm.onKindZuweisen({kind: vm.kind, belegung: vm.belegung})"
                            data-translate="{{vm.zuweisenTranslationKey}}">
                    </button>
                </li>
                <li>
                    <a ui-sref="base.kind.profil({kindId: vm.kind.id})" data-translate="KINDERORT.PROFIL_ANSEHEN"></a>
                </li>
            </ul>
        </div>
    </div>
</div>
