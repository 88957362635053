/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type { EnvironmentProviders} from '@angular/core';
import {APP_INITIALIZER, ErrorHandler, inject, makeEnvironmentProviders} from '@angular/core';
import {Router} from '@angular/router';
import {LogFactory} from '@dv/shared/code';
import {createErrorHandler, TraceService} from '@sentry/angular-ivy';

const LOG = LogFactory.createLog('sentry');

export function provideSentry(): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: ErrorHandler, useValue: createErrorHandler({showDialog: false})},
        {provide: TraceService, deps: [Router]},
        {provide: APP_INITIALIZER, useFactory: initSentryTracing, multi: true},
    ]);
}

function initSentryTracing(): () => void {
    const traceService = inject(TraceService);

    return () => () => {
        LOG.debug('sentry tracing initialized', traceService);
    };
}
