<div class="dvb-kontaktperson-faktura-zahlung row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2 data-translate="{{'KONTAKTPERSON.' + vm.dtype + '_ERFASSEN'}}"></h2>

        <form name="zahlungErfassenForm"
              ng-submit="!zahlungErfassenForm.$pending && vm.submit(zahlungErfassenForm)"
              class="dvb-form"
              novalidate>
            <div class="form-section-minus-normal-abstand">

                <div class="row">
                    <div class="col-md-6">
                        <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                                      required
                                                      ng-disabled="vm.gebuehr.isAssignedDepot()"
                                                      ng-model="vm.kitaSearchResultEntry"
                                                      ng-change="vm.updateKita()"
                                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                                      as-col="true">
                        </dvb-search-get-single-entity>
                    </div>
                    <div class="col-md-6" ng-if="vm.dtype === 'GEBUEHR'">
                        <select class="form-control"
                                ng-model="vm.kontaktpersonZahlung.gebuehrType"
                                ng-change="vm.onGebuehrTypeChange(vm.kontaktpersonZahlung.gebuehrType)"
                                ng-disabled="vm.gebuehr.isAssignedDepot()"
                                required
                                ng-options="type as ('KONTAKTPERSON.GEBUEHR_TYPE.' + type | translate) for type in vm.gebuehrTypes"
                                tooltip-placement="top-left"
                                uib-tooltip="{{'KONTAKTPERSON.GEBUEHR_TYPE_AUSWAEHLEN'| translate}}">
                            <option value=""
                                    class="hide"
                                    disabled
                                    selected
                                    data-translate="KONTAKTPERSON.GEBUEHR_TYPE_TITLE">
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.kontaktpersonZahlung.datum"
                                                   required
                                                   ng-disabled="vm.gebuehr.isAssignedDepot()"
                                                   ng-attr-placeholder="{{'COMMON.DATUM' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                    <div class="col-md-6">
                        <input type="number"
                               name="betrag"
                               class="form-control"
                               ng-attr-placeholder="{{'COMMON.BETRAG_CHF' | translate}}"
                               uib-tooltip="{{'COMMON.BETRAG_CHF' | translate}}"
                               tooltip-placement="top-left"
                               dvb-currency-value
                               dvb-min-exclusive="0"
                               ng-disabled="vm.gebuehr.isAssignedDepot()"
                               ng-model="vm.kontaktpersonZahlung.betrag"
                               required>
                    </div>
                </div>

                <div class="row" ng-if="vm.dtype === 'GEBUEHR'">
                    <div class="col-md-12">
                        <input type="text"
                               class="form-control"
                               maxlength="1000"
                               ng-attr-placeholder="{{vm.kontaktpersonZahlung.bezeichnung ? ('COMMON.BEZEICHNUNG' | translate) : vm.bezeichnungPlaceholder}}"
                               uib-tooltip="{{'COMMON.BEZEICHNUNG' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.kontaktpersonZahlung.bezeichnung">
                    </div>

                    <div class="col-md-12" ng-if="vm.kontaktpersonZahlung.gebuehrType === 'DEPOT'">
                        <label>
                            <input type="checkbox"
                                   ng-model="vm.kontaktpersonZahlung.depotBereitsEingefordert"
                                   ng-disabled="vm.gebuehr.isAssignedDepot()">
                            <span data-translate="KONTAKTPERSON.DEPOT_BEREITS_EINGEFORDERT"></span>
                        </label>
                    </div>
                </div>

                <div class="row big-abstand-top">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <dvb-loading-button type="submit"
                                                    is-loading="zahlungErfassenForm.$pending || vm.isLoading">
                                    <span data-translate="{{'KONTAKTPERSON.' + vm.dtype + '_ERFASSEN'}}"></span>
                                </dvb-loading-button>
                            </li>
                            <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
