<div class="dvb-kita-uebersicht">

    <div class="graph-placeholder" ng-if="vm.loading">
        <dvlib-spinner class="centered-spinner"></dvlib-spinner>
    </div>

    <div ng-if="!vm.loading && vm.hasMultipleTabs" uib-tabset active="vm.activeTabIndex">
        <div ng-if="vm.kita.dtype === 'TAGES_ELTERN'"
             uib-tab
             index="0"
             heading="{{'FRAKTION.MONATSBLATT.STUNDEN' | translate}}">
            <dvb-kita-uebersicht-stunden ng-if="vm.activeTabIndex == 0"
                                         kinder-ort="vm.kita"
                                         controlling-report="vm.controllingReport"
                                         jahr="vm.jahr"
                                         first-of-week="vm.firstOfWeek"
                                         last-of-week="vm.lastOfWeek"
                                         on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
            </dvb-kita-uebersicht-stunden>
        </div>
        <div uib-tab index="1" heading="{{'KINDERORT.PLAETZE' | translate}}">
            <div class="normal-abstand-top">
                <dvb-kita-uebersicht-betreung ng-if="vm.activeTabIndex == 1"
                                              kinder-ort="vm.kita"
                                              controlling-report="vm.controllingReport"
                                              jahr="vm.jahr"
                                              first-of-week="vm.firstOfWeek"
                                              last-of-week="vm.lastOfWeek"
                                              on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
                </dvb-kita-uebersicht-betreung>
            </div>
        </div>
        <div ng-if="vm.hasPersonalViewPermission" uib-tab index="2" heading="{{'PERSONAL.TITLE' | translate}}">
            <dvb-kita-uebersicht-personal ng-if="vm.activeTabIndex == 2"
                                          kinder-ort="vm.kita"
                                          controlling-report="vm.controllingReport"
                                          jahr="vm.jahr"
                                          first-of-week="vm.firstOfWeek"
                                          last-of-week="vm.lastOfWeek"
                                          on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
            </dvb-kita-uebersicht-personal>
        </div>
    </div>

    <div ng-if="!vm.loading && !vm.hasMultipleTabs">
        <dvb-kita-uebersicht-betreung dvb-auth
                                      require-permission="{{'kita:view:' + vm.kita.id}}"
                                      kinder-ort="vm.kita"
                                      controlling-report="vm.controllingReport"
                                      jahr="vm.jahr"
                                      first-of-week="vm.firstOfWeek"
                                      on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
        </dvb-kita-uebersicht-betreung>
        <dvb-kita-uebersicht-personal dvb-auth
                                      require-permission="{{'personal:view:' + vm.kita.id}}"
                                      controlling-report="vm.controllingReport"
                                      jahr="vm.jahr"
                                      first-of-week="vm.firstOfWeek"
                                      on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
        </dvb-kita-uebersicht-personal>
    </div>

</div>
