/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import {DvbDateUtil, Gueltigkeit} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ReportState} from '../../models';
import {ERR_VALIDATION} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        gueltigAb: '<',
        gueltigBis: '<',
    },
    template: require('./dvb-kennzahlen-report.html'),
    controllerAs: 'vm',
};

export class DvbKennzahlenReport implements angular.IController {

    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public gueltigAb?: moment.Moment;
    public gueltigBis?: moment.Moment;

    public readonly defaultGueltigAb: moment.Moment = DvbDateUtil.today();
    public readonly defaultGueltigBis: moment.Moment = DvbDateUtil.today();
    public readonly kennzahlenReportForm?: angular.IFormController;

    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public updateState(): void {
        this.reportService.updateStateParams({
            gueltigAb: this.gueltigAb,
            gueltigBis: this.gueltigBis,
        });
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.kennzahlenReportForm!.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        const gueltigAb = this.gueltigAb ?? this.defaultGueltigAb;
        const gueltigBis = this.gueltigBis ?? this.defaultGueltigBis;
        const gueltigkeit = new Gueltigkeit(gueltigAb, gueltigBis);

        const validGueltigkeit = gueltigkeit.isValid();
        this.errorService.handleValidationError(validGueltigkeit, 'ERRORS.ERR_INVALID_DATES');
        if (!validGueltigkeit) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.reportService.getTempBlobForKennzahlenReport(gueltigkeit, this.reportTemplate);
    }
}

componentConfig.controller = DvbKennzahlenReport;
angular.module('kitAdmin').component('dvbKennzahlenReport', componentConfig);
