<div class="dvb-maintenance-email-test">
    <h2 data-translate="ADMINISTRATION.EMAIL_TEST"></h2>
    <form novalidate ng-submit="vm.sendTestEmail(testEmailForm)" name="testEmailForm">
        <div class="row">
            <div class="col-xs-6">
                <input type="email"
                       class="form-control"
                       ng-attr-placeholder="{{'ADMINISTRATION.EMAIL_EMPFAENGER' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.EMAIL_EMPFAENGER' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.recipient"
                       required>
            </div>
            <div class="col-xs-6">
                <input type="text"
                       class="form-control"
                       ng-attr-placeholder="{{'ADMINISTRATION.EMAIL_BETREFF' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.EMAIL_BETREFF' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.subject"
                       required>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <dvb-search-get-single-entity entity-to-search="RECHNUNG"
                                              ng-model="vm.rechnungSearchResultEntry"
                                              placeholder-key="ADMINISTRATION.EMAIL_OPTIONALE_RECHNUNG">
                </dvb-search-get-single-entity>
            </div>
        </div>
        <div class="normal-abstand-top">
            <dvb-loading-button type="submit" is-loading="vm.isLoading">
                <span data-translate="ADMINISTRATION.EMAIL_SENDEN"></span>
            </dvb-loading-button>
        </div>
    </form>
</div>
