/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameterValue} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifParameterValues: '<',
    },
    template: require('./dvb-kind-tarife-parameter-repeat.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeParameterRepeat implements angular.IController {

    public tarifParameterValues: TarifParameterValue[] = [];
}

componentConfig.controller = DvbKindTarifeParameterRepeat;
angular.module('kitAdmin').component('dvbKindTarifeParameterRepeat', componentConfig);
