/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Rechnung, RechnungDisplayMode} from '@dv/kitadmin/models';
import {checkPresent, isNullish, isPresent} from '@dv/shared/code';
import type {StateService, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import type {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        rechnung: '<',
        mode: '<?',
        isFirst: '<?',
        flatLayout: '<?',
    },
    template: require('./dvb-rechnung-detail.html'),
    controllerAs: 'vm',
};

export class DvbRechnungDetail implements angular.IController {

    public static $inject: readonly string[] = ['fakturaService', '$state', '$uiRouterGlobals'];

    public rechnung!: Rechnung;
    public mode: RechnungDisplayMode | null = null;
    public isFirst: boolean = false;
    public flatLayout: boolean = false;

    public isDownloading: boolean = false;
    public displayModes: Readonly<{ [index: string]: RechnungDisplayMode }> = RechnungDisplayMode.modes;
    public showPending: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    private readonly statesWithoutRechnungAnsehen: string[] = [
        'base.rechnung.uebersicht',
        'base.rechnung.revisionen',
        'base.kontenabgleich.zuweisen.vorgeschlagen.confirm',
        'base.kontenabgleich.zuweisen.alle.confirm',
    ];

    public constructor(
        private fakturaService: FakturaService,
        private $state: StateService,
        private $uiRouterGlobals: UIRouterGlobals,
    ) {
    }

    public $onChanges(): void {
        this.showPending = this.initShowPending();
    }

    public showRechnungAnsehen(): boolean {
        return this.rechnung.status !== Rechnung.STATUS.ERFASST &&
            !this.statesWithoutRechnungAnsehen.includes(this.$uiRouterGlobals.current.name!);
    }

    public downloadPdf(): void {
        this.isDownloading = true;

        const promise = this.mode === this.displayModes.REVISION && !this.isFirst ?
            this.fakturaService.getTempBlobForRechnungsRevision(checkPresent(this.rechnung.rechnungsRevisionId)) :
            this.fakturaService.getTempBlobForRechnung(checkPresent(this.rechnung.id));

        promise.then(tempBlob => {
            this.dvbDownloadCtrl.downloadFileByUrl(checkPresent(tempBlob));
        }).finally(() => {
            this.isDownloading = false;
        });
    }

    public zuweisenStateExists(): boolean {
        return !!this.$state.href('.confirm', {assigningRechnungId: this.rechnung.id});
    }

    private initShowPending(): boolean {
        if (isNullish(this.rechnung.ausstehenderBetrag) || isNullish(this.mode)) {
            return false;
        }

        if (this.mode === RechnungDisplayMode.modes.TRANSAKTION) {
            return false;
        }

        if (!this.flatLayout) {
            return true;
        }

        return isPresent(this.rechnung.ausstehenderBetrag) &&
            this.rechnung.summe !== this.rechnung.ausstehenderBetrag;
    }
}

componentConfig.controller = DvbRechnungDetail;
angular.module('kitAdmin').component('dvbRechnungDetail', componentConfig);
