<div class="dvb-mandant-rechnung-config">
    <h2 data-translate="MANDANT.RECHNUNG_CONFIG.TITLE"></h2>
    <div class="row normal-abstand-top">
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.config.verrechnungAb"
                                       click-to-edit="true"
                                       on-submit="vm.saveConfig()"
                                       dvb-begin-of-month
                                       ng-attr-placeholder="{{'MANDANT.RECHNUNG_CONFIG.VERRECHNUNG_AB' | translate}}">
            </dvb-datepicker-text-field>
        </div>
        <div class="col-md-6">
            <dvb-editable-label type="number"
                                ng-attr-placeholder="{{'MANDANT.RECHNUNG_CONFIG.ANZAHL_RABATT' | translate}}"
                                label="{{'MANDANT.RECHNUNG_CONFIG.ANZAHL_RABATT' | translate: {count: vm.config.anzahlRabatt} }}"
                                ng-model="vm.config.anzahlRabatt"
                                ng-change="vm.saveConfig()">
            </dvb-editable-label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <dvb-editable-label type="number"
                                ng-change="vm.saveConfig()"
                                ng-model="vm.config.pauschalPreis"
                                ng-attr-placeholder="{{'MANDANT.RECHNUNG_CONFIG.PAUSCHAL_PREIS' | translate}}"
                                label="{{'MANDANT.RECHNUNG_CONFIG.PAUSCHAL_PREIS' | translate: {count: vm.config.pauschalPreis} }}">
            </dvb-editable-label>
        </div>
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.config.pauschalPreisBis"
                                       click-to-edit="true"
                                       on-submit="vm.saveConfig()"
                                       dvb-end-of-month-parser
                                       ng-attr-placeholder="{{'MANDANT.RECHNUNG_CONFIG.PAUSCHAL_PREIS_BIS' | translate}}">
            </dvb-datepicker-text-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <dvb-editable-label type="number"
                                ng-change="vm.saveConfig()"
                                ng-model="vm.config.rabattPct"
                                ng-attr-placeholder="{{'MANDANT.RECHNUNG_CONFIG.KINDERORT_RABATT_PCT' | translate}}"
                                label="{{'MANDANT.RECHNUNG_CONFIG.KINDERORT_RABATT_PCT' | translate: {count: vm.config.rabattPct} }}">
            </dvb-editable-label>
        </div>
    </div>

    <h3 class="normal-abstand-top" data-translate="MANDANT.RECHNUNG_CONFIG.POSITIONS_TITLE"></h3>
    <div class="row">
        <div class="col-md-12">
            <div ng-if="!vm.positionFormContext.editMode"
                 ng-repeat="position in vm.config.positions">
                <dvb-administration-mandant-rechnungsposition rechnungs-position="position"
                                                              on-delete="vm.delete(position)">
                </dvb-administration-mandant-rechnungsposition>
            </div>

            <div class="small-abstand-top" ng-if="!vm.positionFormContext.editMode">
                <button class="btn btn-primary"
                        data-translate="ADMINISTRATION.CUSTOM_RECHNUNG_POSITIONEN_ANPASSEN"
                        ng-click="vm.positionFormContext.enableEditMode()"
                        type="button">
                </button>
            </div>

            <div class="small-abstand-top" ng-if="vm.positionFormContext.editMode">
                <dvb-administration-custom-rechnung-position-form existing-custom-positionen="vm.config.positions"
                                                                  form-context="vm.positionFormContext"
                                                                  on-cancel="vm.positionFormContext.close()"
                                                                  on-update="vm.updatePositions(customPositionen)"
                                                                  edit-all-positions="true">
                </dvb-administration-custom-rechnung-position-form>
            </div>
        </div>
    </div>
</div>
