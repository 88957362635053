<div class="kontaktperson-stammdaten-display">
    <div class="row">
        <div class="col-md-12">
            <p>
                <a ui-sref="base.kontaktperson.profil({kontaktpersonId : vm.kontaktperson.id})"
                   ng-bind="vm.kontaktperson.getDisplayName()">
                </a>
            </p>

            <p ng-if="vm.kontaktperson.organisation" ng-bind="vm.kontaktperson.organisation"></p>

            <dvlib-adresse-display ng-if="vm.adresse"
                                   [adresse]="vm.adresse"
                                   [display-type]="'short'">
            </dvlib-adresse-display>

            <p ng-if="vm.kontaktperson.telefon"
               data-translate="COMMON.TELEFON_MIT_NUM"
               data-translate-value-num="{{vm.kontaktperson.telefon}}">
            </p>

            <p ng-if="vm.kontaktperson.mobile"
               data-translate="COMMON.MOBILE_MIT_NUM"
               data-translate-value-num="{{vm.kontaktperson.mobile}}">
            </p>

            <p ng-if="vm.kontaktperson.geschaeft"
               data-translate="COMMON.GESCHAEFT_MIT_NUM"
               data-translate-value-num="{{vm.kontaktperson.geschaeft}}">
            </p>

            <p ng-if="vm.kontaktperson.email">
                <a ng-href="{{'mailto:' + vm.kontaktperson.email}}" ng-bind="vm.kontaktperson.email"></a>
            </p>
        </div>
    </div>
</div>
