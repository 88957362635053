/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktion} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        currentGruppe: '<',
        gruppen: '<',
        active: '<',

        selectGruppe: '&',
    },
    template: require('./dvb-monats-belegung-select-gruppe.html'),
    controllerAs: 'vm',
};

export class DvbMonatsBelegungSelectGruppe implements angular.IController {
    public static $inject: readonly string[] = [];

    public currentGruppe!: KinderOrtFraktion;
    public gruppen: KinderOrtFraktion[] = [];
    public active: boolean = true;

    public selectGruppe?: (gruppe: KinderOrtFraktion) => void;
}

componentConfig.controller = DvbMonatsBelegungSelectGruppe;
angular.module('kitAdmin').component('dvbMonatsBelegungSelectGruppe', componentConfig);
