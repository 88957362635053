/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {AbstractKontingentValue, AbstractLimitedPlaetze, KinderOrt} from '@dv/kitadmin/models';
import {
    BewilligtePlaetze,
    KinderOrtType,
    SubventioniertesKontingent,
    SubventioniertesKontingentValue,
} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateObject, StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';
import {DASHBOARD_STATE} from '../../../../dashboard/dashboard-state';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kinderort-kapazitaeten.html'),
    controllerAs: 'vm',
};

export class DvbKinderortKapazitaeten implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        '$translate',
        'errorService',
        'kinderOrtService',
        'dialogService',
    ];

    public kinderOrt!: KinderOrt;

    public today: moment.Moment = DvbDateUtil.today();

    public neueKontingentValueTitleKey: string | null = null;
    public neueKontingentValueErfassenKey: string | null = null;
    public neueKontingentValue: AbstractLimitedPlaetze<any> | null = null;
    public neueKontingentValueEditable: boolean | null = false;

    public constructor(
        private $state: StateService,
        private $translate: angular.translate.ITranslateService,
        private errorService: ErrorService,
        private kinderOrtService: KinderOrtService,
        private dialogService: DialogService,
    ) {
    }

    public neueBewilligtePlaetze(): void {
        this.neueKontingentValueErfassenKey = 'KINDERORT.ANZAHL_BEWILLIGTE_PLAETZE';
        this.neueKontingentValueTitleKey = `KINDERORT.BEWILLIGTE_PLAETZE.${this.kinderOrt.dtype}`;
        this.neueKontingentValue = new BewilligtePlaetze();
        this.neueKontingentValueEditable = KinderOrtType.KITA === this.kinderOrt.dtype;
    }

    public neuesSubventioniertesKontingentValue(): void {
        this.neueKontingentValueErfassenKey = `KINDERORT.SUBVENTIONIERTE_KAPAZITAET.${this.kinderOrt.dtype}.ANZAHL`;
        this.neueKontingentValueTitleKey = `KINDERORT.SUBVENTIONIERTE_KAPAZITAET.${this.kinderOrt.dtype}.NAME`;
        this.neueKontingentValue = new SubventioniertesKontingentValue();
        this.neueKontingentValueEditable = true;
    }

    public resetNewKontingentValue(): void {
        this.neueKontingentValue = null;
        this.neueKontingentValueErfassenKey = null;
        this.neueKontingentValueEditable = false;
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
    }

    public deleteBewilligtePlaetze(value: BewilligtePlaetze): angular.IPromise<unknown> {
        this.errorService.clearAll();

        return this.kinderOrtService.deleteBewilligtePlaetze(checkPresent(value.id))
            .then(() => this.$state.reload());
    }

    public updateBewilligtePlaetze(value: BewilligtePlaetze): void {
        this.errorService.clearAll();
        const bewilligtePlaetzeId = checkPresent(value.id);
        const plaetze = checkPresent(value.plaetze);

        this.kinderOrtService.updateBewilligtePlaetzePlaetze(bewilligtePlaetzeId, plaetze)
            .then(() => this.$state.reload());
    }

    public revertTerminateKita(): void {
        this.errorService.clearAll();
        this.kinderOrtService.revertTerminateKita(checkPresent(this.kinderOrt.id)).then(() => this.$state.reload());
    }

    public terminateKita(): void {
        const title = this.$translate.instant('KINDERORT.KITA_BEENDEN_HEADING', {
            kitaname: this.kinderOrt.getDisplayName(),
        });

        this.dialogService.openDateDialog({
            title,
            confirm: endeDatum => {
                this.errorService.clearAll();

                return from(this.kinderOrtService.terminateKita(checkPresent(this.kinderOrt.id), endeDatum)
                    .then(() => this.$state.reload())).pipe(take(1));
            },
        });
    }

    public isKontingentValueValid(kontingentValue: AbstractLimitedPlaetze<any>): boolean {
        const valid = kontingentValue.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public addBewilligtePlaetze(bewilligtePlaetze: BewilligtePlaetze): void {
        this.errorService.clearAll();
        this.kinderOrtService.createBewilligtePlaetze(checkPresent(this.kinderOrt.id), bewilligtePlaetze)
            .then(() => this.onNewKontingentValueSuccess());
    }

    public confirmDeleteKita(kinderOrt: KinderOrt): void {
        this.errorService.clearAll();

        this.kinderOrtService.validateKitaDelete(checkPresent(kinderOrt.id)).then(() => {
            this.showConfirmDeleteKitaModal(kinderOrt);
        });
    }

    public showConfirmDeleteKitaModal(kinderOrt: KinderOrt): void {
        this.dialogService.openDeleteDialog({
            entityText: kinderOrt.getDisplayName(),
            confirm: () => {
                this.errorService.clearAll();

                return from(this.kinderOrtService.deleteKita(checkPresent(kinderOrt.id)))
                    .pipe(take(1), tap(() => this.$state.go(DASHBOARD_STATE.name)));
            },
        });
    }

    public addSubventioniertesKontingentValue(subventioniertesKontingentValue: SubventioniertesKontingentValue): void {
        this.errorService.clearAll();
        const kontingent = checkPresent(this.kinderOrt.subventioniertesKontingent);
        const kontingentId = checkPresent(kontingent.id);

        this.kinderOrtService.createSubventioniertesKontingentValue(kontingentId,
            subventioniertesKontingentValue).then(() => {
            this.onNewKontingentValueSuccess();
        });
    }

    public terminateSubventioniertesKontingent(): void {
        const title = this.$translate.instant(
            `KINDERORT.SUBVENTIONIERTE_KAPAZITAET.${this.kinderOrt.dtype}.BEENDEN_DIALOG_HEADING`,
            {kitaname: this.kinderOrt.getDisplayName()},
        );

        this.dialogService.openDateDialog({
            title,
            confirm: endeDatum => {
                const subventioniertesKontingent = checkPresent(this.kinderOrt.subventioniertesKontingent);

                const valuesNachEndeDatum = subventioniertesKontingent.values
                    .filter(value => checkPresent(value.gueltigAb).isAfter(endeDatum));

                if (valuesNachEndeDatum.length) {
                    const confirm$ = new Subject<void>();
                    this.dialogService.openConfirmDialog({
                        title: 'KINDERORT.CONFIRM_KAPAZITAETEN_BEENDEN',
                        confirmActionText: 'COMMON.BEENDEN',
                        confirm: () => this.beendigungAction$(endeDatum).pipe(
                            tap(() => confirm$.next())),
                        cancel: () => confirm$.next(),
                    });

                    return confirm$.pipe(take(1));
                }

                return this.beendigungAction$(endeDatum);
            },
        });
    }

    public saveKontingentValue<T extends AbstractKontingentValue<any>>(
        form: angular.IFormController,
        kontingentValue: T,
    ): void {

        const valid = form.$valid && this.isKontingentValueValid(kontingentValue);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        if (kontingentValue instanceof SubventioniertesKontingentValue) {
            if (this.kinderOrt.subventioniertesKontingent) {
                this.addSubventioniertesKontingentValue(kontingentValue);
            } else {
                this.createSubventioniertesKontingent(kontingentValue);
            }

            return;
        }

        if (kontingentValue instanceof BewilligtePlaetze) {
            this.addBewilligtePlaetze(kontingentValue);

            return;
        }

        // unexpected error
        throw new Error(`Unrecognised KontingentValue ${JSON.stringify(kontingentValue)}`);
    }

    public createSubventioniertesKontingent(kontingentValue: SubventioniertesKontingentValue): void {
        const subventioniertesKontingent = new SubventioniertesKontingent(this.kinderOrt.id);
        this.kinderOrt.subventioniertesKontingent = subventioniertesKontingent;
        subventioniertesKontingent.values.push(kontingentValue);

        this.errorService.clearAll();
        const kitaId = checkPresent(this.kinderOrt.id);

        this.kinderOrtService.createSubventioniertesKontingent(kitaId, subventioniertesKontingent)
            .then(() => this.onNewKontingentValueSuccess());
    }

    public onNewKontingentValueSuccess(): Promise<StateObject> {
        this.resetNewKontingentValue();

        return this.$state.reload();
    }

    public updateSubventioniertesKontingentValue(value: SubventioniertesKontingentValue): angular.IPromise<unknown> {
        this.errorService.clearAll();
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const prozentPunkteProWoche = checkPresent(value.plaetze) * 100;
        const valueId = checkPresent(value.id);

        return this.kinderOrtService.updateSubventioniertesKontingentValueProzentPunkte(valueId, prozentPunkteProWoche)
            .then(() => this.$state.reload());
    }

    public deleteSubventioniertesKontingentValue(value: SubventioniertesKontingentValue): angular.IPromise<unknown> {
        this.errorService.clearAll();

        return this.kinderOrtService.deleteSubventioniertesKontingentValue(checkPresent(value.id)).then(() => {
            checkPresent(this.kinderOrt.subventioniertesKontingent).removeKontingentValue(value);

            return this.$state.reload();
        });
    }

    public revertSubventioniertesKontingentValue(value: SubventioniertesKontingentValue): angular.IPromise<unknown> {
        this.errorService.clearAll();

        return this.kinderOrtService.extendSubventioniertesKontingentValue(checkPresent(value.id))
            .then(() => this.$state.reload());
    }

    private beendigungAction$(endeDatum: moment.Moment): Observable<unknown> {
        const subventioniertesKontingent = checkPresent(this.kinderOrt.subventioniertesKontingent);
        const kontingentId = checkPresent(subventioniertesKontingent.id);
        this.errorService.clearAll();

        return from(this.kinderOrtService.terminateKontingent(kontingentId, endeDatum)
            .then(() => this.$state.reload())).pipe(take(1));
    }
}

componentConfig.controller = DvbKinderortKapazitaeten;
angular.module('kitAdmin').component('dvbKinderortKapazitaeten', componentConfig);
