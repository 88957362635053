/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, TagesschuleGesuchsteller} from '@dv/kitadmin/models';
import {KindKontakteUtil, Kontaktperson, RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Adresse, LoadingContext, Persisted, SearchResultEntry} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, take} from 'rxjs';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {
    KibonEntityMatchingDialogModel,
} from '../../../kibon/component/kibon-entity-matching-dialog/kibon-entity-matching-dialog.component';
import {
    KibonEntityMatchingDialogComponent,
} from '../../../kibon/component/kibon-entity-matching-dialog/kibon-entity-matching-dialog.component';
import type {KibonExchangeTagesschuleAnmeldung} from '../../../kibon/models/KibonExchangeTagesschuleAnmeldung';
import {TagesschuleAnmeldungState} from '../../../kibon/models/KibonExchangeTagesschuleAnmeldung';
import type {TagesschuleAnmeldungService} from '../../../kibon/service/tagesschuleAnmeldungService';
import {AbstractAnmeldungUebernahme} from '../../models/AbstractAnmeldungUebernahme';
import type {KindValidationService} from '../../service/kindValidationService';
import {ZUWEISEN_MULTIPLE_SIMPLE_STATE} from '../../zuweisung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tagesschulAnmeldung: '<',
    },
    template: require('./dvb-anmeldung-tagesschule.html'),
    controllerAs: 'vm',
};

export class DvbAnmeldungTagesschule extends AbstractAnmeldungUebernahme<KibonExchangeTagesschuleAnmeldung>
    implements angular.IController {

    public static override $inject: readonly string[] = [
        '$state',
        'kindService',
        'errorService',
        'kindValidationService',
        'dialogService',
        'tagesschuleAnmeldungService',
    ];

    public tagesschulAnmeldung!: KibonExchangeTagesschuleAnmeldung;

    public tagesschuleAnmeldungState: typeof TagesschuleAnmeldungState = TagesschuleAnmeldungState;

    public constructor(
        private readonly $state: StateService,
        kindService: KindService,
        errorService: ErrorService,
        kindValidationService: KindValidationService,
        dialogService: DialogService,
        private readonly tagesschuleAnmeldungService: TagesschuleAnmeldungService,
    ) {
        super(tagesschuleAnmeldungService, kindService, kindValidationService, errorService, dialogService);
    }

    public onKindCreated(kind: Kind): void {
        this.kind = kind;
        this.tagesschuleAnmeldungService.initializeKind(kind, this.tagesschulAnmeldung.kind);

        const adresse = this.selectedHousehold?.adresse
            ?? angular.copy(this.tagesschulAnmeldung.gesuchsteller.kibonAddress);
        if (!this.selectedHousehold && adresse) {
            // create a new address
            adresse.id = null;
        }

        const hauptKontaktperson =
            this.selectedHousehold?.matchingGesuchsteller[this.tagesschulAnmeldung.gesuchsteller.id!]
            ?? this.createKontaktperson(this.tagesschulAnmeldung.gesuchsteller, adresse);
        const hauptKontakt = this.createHauptkontaktRelationship(hauptKontaktperson);
        kind.addKontakt(hauptKontakt);
        KindKontakteUtil.recalculateProzent(kind.kontakte, hauptKontakt);

        if (this.tagesschulAnmeldung.gesuchsteller2) {
            const kontaktperson = this.selectedHousehold?.matchingGesuchsteller[this.tagesschulAnmeldung
                    .gesuchsteller2.id!]
                ?? this.createKontaktperson(this.tagesschulAnmeldung.gesuchsteller2, adresse);
            kind.addKontakt(this.createKontaktRelationship(kontaktperson));
        }

        if (this.selectedHousehold) {
            this.uneditableAdresse = true;

            this.selectedHousehold.kontakteAtAdresse.forEach(k => {
                kind.addKontakt(this.createSonstigerKontaktRelationship(k));
            });

            this.selectedHousehold.relatedKinder.forEach(k => {
                kind.addGeschwister(k);
            });
        }
    }

    public useExistingKind(item: SearchResultEntry): void {
        const params = {
            includes: '(kontakte.fields(kontaktperson.fields(adressen), relationship))',
        };

        this.kindService.get(item.id, params)
            .then(kind => this.confirmAssignment(kind));
    }

    protected confirmAssignment(kind: Persisted<Kind>): void {

        const dialogModel: KibonEntityMatchingDialogModel = {
            entity: this.tagesschulAnmeldung,
            kind,
            header: 'KIBON.TAGESSCHULE_ANMELDUNG.ASSIGN_CONFIRMATION_HEADER',
            entityDataHeader: 'KIBON.TAGESSCHULE_ANMELDUNG.ASSIGN_DATEN',
            confirm: () => from(this.assignKindAndChangeState(kind.id)).pipe(take(1)),
        };

        this.dialogService.openDialog(KibonEntityMatchingDialogComponent, dialogModel);
    }

    protected assignKindAndChangeState(kindId: string, ctx?: LoadingContext): angular.IPromise<unknown> {
        ctx?.startLoading();

        return this.tagesschuleAnmeldungService.assign(this.tagesschulAnmeldung.id, kindId)
            .then(() => this.goToAnmeldungBestaetigen(kindId))
            .finally(() => ctx?.finishLoading());
    }

    protected getExternalAnmeldung(): KibonExchangeTagesschuleAnmeldung {
        return this.tagesschulAnmeldung;
    }

    private goToAnmeldungBestaetigen(kindId: string): angular.IPromise<unknown> {
        const anmeldung = this.tagesschulAnmeldung;

        return this.$state.go(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, {
            tagesschulAnmeldungId: anmeldung.id,
            tagesschulAnmeldung: anmeldung,
            kindId,
            kinderOrtIds: [checkPresent(anmeldung.kinderOrtId)],
        });
    }

    private createKontaktperson(gesuchsteller: TagesschuleGesuchsteller, adresse?: Adresse | null): Kontaktperson {
        const kp = new Kontaktperson();
        this.tagesschuleAnmeldungService.initializeKontaktperonWithAnmeldungKontakt(kp, gesuchsteller, adresse);

        return kp;
    }

    private createHauptkontaktRelationship(kontaktperson: Kontaktperson): RelationshipWithKontaktperson {
        const hauptKontakt = new RelationshipWithKontaktperson();
        hauptKontakt.kontaktperson = kontaktperson;
        hauptKontakt.kontaktpersonId = kontaktperson.id;
        hauptKontakt.relationship.createHauptkontakt();
        hauptKontakt.relationship.createRechnungsempfaenger();
        hauptKontakt.relationship.createErziehungsberechtigter();
        hauptKontakt.relationship.createAbholberechtigt();

        return hauptKontakt;
    }

    private createKontaktRelationship(kontaktperson: Kontaktperson): RelationshipWithKontaktperson {
        const kontakt = new RelationshipWithKontaktperson();
        kontakt.kontaktperson = kontaktperson;
        kontakt.kontaktpersonId = kontaktperson.id;
        kontakt.relationship.createErziehungsberechtigter();
        kontakt.relationship.createAbholberechtigt();

        return kontakt;
    }
}

componentConfig.controller = DvbAnmeldungTagesschule;
angular.module('kitAdmin').component('dvbAnmeldungTagesschule', componentConfig);
