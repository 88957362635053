<div class="dvb-mandant-profil row">
    <div class="col-md-7">
        <form class="form-section-minus-normal-abstand">
            <h2 data-translate="MANDANT.KONFIGURATION"></h2>

            <div class="row form-row">
                <div class="col-md-6">
                    <dvb-subscription-model mandant="vm.mandant"
                                            on-change="vm.updateMandant()"
                                            is-disabled="!vm.isMandantAdmin">
                    </dvb-subscription-model>
                </div>
                <div class="col-md-6">
                    <dvb-kitadmin-module mandant="vm.mandant"
                                         on-change="vm.updateMandant()"
                                         is-disabled="!vm.isMandantAdmin">
                    </dvb-kitadmin-module>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-md-6" dvb-auth require-role="MANDANT_ADMIN">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   name="resettable"
                                   ng-model="vm.mandant.resettable"
                                   ng-change="vm.updateMandant()"
                                   dvb-auth
                                   require-role="MANDANT_ADMIN"
                                   on-unauthorised-disable>
                            <span data-translate="COMMON.RESETTABLE"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label type="number"
                                        ng-attr-placeholder="{{'MANDANT.WARNING_INTERVAL_IN_DAYS' | translate:{tage: vm.mandant.warningIntervalInDays} }}"
                                        label="{{'MANDANT.WARNING_INTERVAL_IN_DAYS' | translate:{tage: vm.mandant.warningIntervalInDays} }}"
                                        ng-model="vm.mandant.warningIntervalInDays"
                                        min="0"
                                        max="370"
                                        step="1"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable
                                        is-valid="vm.isInteger(param)"
                                        ng-change="vm.updateMandant()">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6" dvb-auth require-role="MANDANT_ADMIN">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   name="disabled"
                                   ng-model="vm.mandant.disabled"
                                   ng-change="vm.disable()"
                                   dvb-auth
                                   require-role="MANDANT_ADMIN"
                                   on-unauthorised-disable>
                            <span data-translate="MANDANT.DISABLED"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.mandant.abgerechnetBis"
                                               click-to-edit="true"
                                               dvb-auth
                                               require-permission="kita:betreuung_admin:*"
                                               on-unauthorised-disable
                                               on-submit="vm.updateMandant()"
                                               is-valid="vm.isValidMoment(param)"
                                               required
                                               dvb-end-of-prev-month
                                               ng-attr-placeholder="{{'MANDANT.ABGERECHNET_BIS' | translate}}">
                    </dvb-datepicker-text-field>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label type="number"
                                        ng-attr-placeholder="{{'MANDANT.ABRECHNUNG_VORAUS_ZUSATZ_MONATE' | translate}}"
                                        label="{{'MANDANT.ABRECHNUNG_VORAUS_ZUSATZ_MONATE_VALUE' | translate:{monate: vm.mandant.abrechnungVorausZusatzMonate} }}"
                                        ng-model="vm.mandant.abrechnungVorausZusatzMonate"
                                        min="0"
                                        max="18"
                                        step="1"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable
                                        is-valid="vm.isInteger(param)"
                                        ng-change="vm.updateMandant()">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6" dvb-auth require-role="MANDANT_ADMIN">
                    <dvb-editable-label is-valid="vm.isNotEmptyString(param) || param === null"
                                        maxlength="100"
                                        ng-attr-placeholder="{{'AUTHENTICATION.IDP_HINT' | translate}}"
                                        ng-model="vm.mandant.idpHint"
                                        dvb-auth
                                        require-permission="{{'mandant:update:' + vm.mandant.id}}"
                                        on-unauthorised-disable
                                        ng-change="vm.updateMandant()">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6" dvb-auth require-role="MANDANT_ADMIN">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   name="oidcUserManagement"
                                   ng-model="vm.mandant.oidcUserManagement"
                                   ng-change="vm.updateMandant()">
                            <span data-translate="AUTHENTICATION.OIDC_USER_MANAGEMENT"></span>
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-4 col-md-offset-1">
        <div class="title">
            <h2 data-translate="COMMON.KONTAKT"></h2>
        </div>

        <div class="row">
            <div class="col-md-6">
                <dvb-editable-label is-valid="vm.isNotEmptyString(param)"
                                    maxlength="255"
                                    ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                    ng-model="vm.mandant.name"
                                    dvb-auth
                                    require-permission="{{'mandant:update:' + vm.mandant.id}}"
                                    on-unauthorised-disable
                                    ng-change="vm.updateMandant()">
                </dvb-editable-label>
            </div>
        </div>

        <dvb-adresse ng-click="vm.editAdresse()"
                     adresse="vm.mandant.adresse"
                     display-type="long"
                     on-save="vm.saveAdresse(adresse)"
                     click-to-edit="true"
                     dvb-auth
                     require-permission="{{'mandant:update:' + vm.mandant.id}}"
                     on-unauthorised-disable
                     show-edit-mode="vm.isAdresseEditMode">
        </dvb-adresse>

        <dvb-editable-contextual-adresse contextual-adresse="vm.mandant.contextualAdresse"
                                         allow-none="false"
                                         on-update="vm.saveContextualAdresse(contextualAdresse)"
                                         uib-tooltip="{{'MANDANT.KORRESPONDENZ_ADRESSE' | translate}}"
                                         tooltip-placement="top-left"
                                         dvb-auth
                                         require-permission="{{'mandant:update:' + vm.mandant.id}}"
                                         on-unauthorised-disable>
        </dvb-editable-contextual-adresse>

        <dvb-mandant-sprachauswahl mandant="vm.mandant"
                                   on-update="vm.updateMandant()">
        </dvb-mandant-sprachauswahl>

        <dv-mandant-config ng-if="vm.hasControllingModulePermission" />
    </div>
</div>
