import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {trackByIdentity} from '@dv/shared/angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {Badge} from '../../model/Badge';

@Component({
    selector: 'dv-timeline-badges',
    standalone: true,
    imports: [
        TooltipModule,
        NgClass,
    ],
    templateUrl: './timeline-badges.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineBadgesComponent {

    @Input({required: true}) public badges: Badge[] = [];
    protected readonly trackByIdentity = trackByIdentity;
}
