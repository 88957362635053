/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';
import type {KinderOrtService} from '../../../../../common/service/rest/kinderort/kinderOrtService';
import type {RechnungenFilter} from '../../../../../filter/RechnungenFilter';
import {FilterOptionHelper} from '../../../../../filter/shared/FilterOptionHelper';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        filter: '<',
        bankStatementEntry: '<',
        onUpdate: '&',
    },
    template: require('./dvb-transaktion-zuweisen-filter.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionZuweisenFilter implements angular.IController {

    public static $inject: readonly string[] = ['kinderOrtService'];

    public filter!: RechnungenFilter;
    public bankStatementEntry!: BankStatementEntry;
    public onUpdate!: FunctionType;

    public collapsed: boolean = true;
    public filterOptionHelper: FilterOptionHelper = new FilterOptionHelper();

    public constructor(
        private readonly kinderOrtService: KinderOrtService,
    ) {
    }

    public $onInit(): void {
        this.filterOptionHelper = new FilterOptionHelper(this.onUpdate);

        this.kinderOrtService.getAll().then(kitas => {
            kitas.forEach(kita => this.filterOptionHelper.add(this.filter.kitas, kita.id!, kita.getDisplayName()));
        });
    }

    public onReset(): void {
        this.filter.reset();
        this.onUpdate();
    }
}

componentConfig.controller = DvbTransaktionZuweisenFilter;
angular.module('kitAdmin').component('dvbTransaktionZuweisenFilter', componentConfig);
