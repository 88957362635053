/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ControllingChange, KinderOrtFraktion, Kita} from '@dv/kitadmin/models';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import type moment from 'moment';
import type {DvbPagination} from '../../../../common/directive/dvb-pagination';
import type {FraktionService} from '../../../../common/service/rest/kinderort/fraktionService';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        gruppe: '<?',
        gueltigAb: '<',
    },
    require: {
        pagination: 'dvbPagination',
    },
    template: require('./dvb-kita-kinder-aenderungen.html'),
    controllerAs: 'vm',
};

export class DvbKitaKinderAenderungen implements angular.IController {
    public static $inject: readonly string[] = ['kinderOrtService', 'fraktionService'];

    public readonly pagination!: DvbPagination<ControllingChange>;

    public kita?: Kita;
    public gruppe?: KinderOrtFraktion;
    public gueltigAb!: moment.Moment;

    public constructor(private kinderOrtService: KinderOrtService, private fraktionService: FraktionService) {
    }

    public $onChanges(changes: IOnChangesObject): void {
        if (changes.gueltigAb) {
            this.pagination.reset();
            this.loadKinderAenderungen();
        }
    }

    public loadKinderAenderungen(): void {
        if (!DvbDateUtil.isValidMoment(this.gueltigAb)) {
            return;
        }

        this.pagination.more((params, config) => this.gruppe ?
            this.fraktionService.getKinderAenderungen(checkPresent(this.gruppe.id), this.gueltigAb, params, config) :
            this.kinderOrtService.getKinderAenderungen(checkPresent(this.kita!.id), this.gueltigAb, params, config),
        );
    }
}

componentConfig.controller = DvbKitaKinderAenderungen;
angular.module('kitAdmin').component('dvbKitaKinderAenderungen', componentConfig);
