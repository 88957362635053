<div class="dvb-kind-header header">
    <div class="row header-row header-row-kind">
        <i class="fa fa-child header-icon"
           uib-tooltip="{{'COMMON.KIND.SINGULAR' | translate}}"
           tooltip-placement="top-left">
        </i>
        <div class="col-xs-12 header-box">

            <h1 ng-bind="vm.kind.getDisplayName()"
                uib-tooltip="{{vm.kind.getDisplayName()}}"
                tooltip-placement="top-left">
            </h1>

            <ul class="list-inline header-icons" ng-show="vm.isBetreuungsfaktorLoading">
                <li>
                    <dvlib-spinner></dvlib-spinner>
                </li>
            </ul>
            <ul class="list-inline header-icons" ng-show="!vm.isBetreuungsfaktorLoading">
                <li ng-if="vm.belegungsZustand">
                    <dvb-belegungs-zustand-icon belegungs-zustand="vm.belegungsZustand">
                    </dvb-belegungs-zustand-icon>
                </li>
                <li ng-if="vm.kind.bewerbung">
                    <span class="custom-icon custom-icon-warteliste"
                          uib-tooltip="{{'COMMON.ZUWEISUNG_IST_WARTELISTE' | translate}}"
                          tooltip-placement="top-left">
                    </span>
                </li>
                <li class="betreuungsfaktor"
                    ng-repeat="faktor in vm.betreuungsfaktoren | filter: vm.isBetreuungsFaktorNotOne">
                    <dvb-betreuungsfaktor-icon faktor="faktor"></dvb-betreuungsfaktor-icon>
                </li>
                <li ng-if="vm.kind.hasGeschwister()">
                    <i class="fa fa-chain"
                       uib-tooltip="{{'COMMON.ZUWEISUNG_HAT_GESCHWISTER' | translate}}"
                       tooltip-placement="top-left">
                    </i>
                </li>
                <li ng-if="vm.showPlatzTypen">
                    <dvb-platz-typen platz-typen="vm.platzTypen"></dvb-platz-typen>
                </li>
                <li ng-if="vm.isKindergarten()">
                    <dvb-kindergarten-icon kindergarten-belegung="{{vm.getKindergarten()}}"></dvb-kindergarten-icon>
                </li>
            </ul>

            <span class="layout-spacer"></span>

            <ul class="list-inline header-breadcrumb">
                <li ui-sref-active="active">
                    <a ui-sref="base.kind.profil" data-translate="COMMON.PROFIL"></a>
                </li>
                <li ui-sref-active="active">
                    <a ui-sref="base.kind.kontakt" data-translate="COMMON.KONTAKT"></a>
                </li>
                <li ui-sref-active="active">
                    <a ui-sref="base.kind.betreuung" data-translate="KIND.BETREUUNG"></a>
                </li>
                <li dvb-auth require-permission="kita:control:general" ui-sref-active="active">
                    <a ui-sref="base.kind.tarife.uebersicht" data-translate="COMMON.TARIF.PLURAL"></a>
                </li>
                <li dvb-auth require-permission="kita:control:general" ui-sref-active="active">
                    <a ui-sref="base.kind.kibon" data-translate="KIBON.KIBON"></a>
                </li>
                <li dvb-auth require-permission="kita:control:general" ui-sref-active="active">
                    <a ui-sref="base.kind.leistungen" data-translate="KIND.LEISTUNGEN.LEISTUNGEN"></a>
                </li>
            </ul>
        </div>
    </div>
</div>
