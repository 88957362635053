/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit, Signal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, inject, Input} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import type {Kind} from '@dv/kitadmin/models';
import type {ItemWithEntity} from '@dv/kitadmin/ui';
import {CrudAccordionComponent, CrudAccordionTemplateDirective} from '@dv/kitadmin/ui';
import {ButtonListComponent, DateSwitcherComponent, DvBackendLocalDateTimeFormatPipe} from '@dv/shared/angular';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {JaxKindCheckInEvent} from '@dv/shared/backend/model/jax-kind-check-in-event';
import {DvbDateUtil, Persisted} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import type {UiOnParamsChanged} from '@uirouter/angular';
import {StateService} from '@uirouter/core';
import {TAGESINFO_STATE} from '../../kind-states';
import {TagesinfoFormComponent} from '../tagesinfo-form/tagesinfo-form.component';
import {TagesinfoStateService} from './tagesinfo.store';

@Component({
    selector: 'dv-tagesinfo',
    standalone: true,
    imports: [
        TranslateModule,
        DvBackendLocalDateTimeFormatPipe,
        TagesinfoFormComponent,
        DateSwitcherComponent,
        CrudAccordionComponent,
        CrudAccordionTemplateDirective,
        ButtonListComponent,
    ],
    templateUrl: './tagesinfo.component.html',
    styles: [':host {display: block;}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagesinfoComponent implements OnInit, UiOnParamsChanged {
    @Input({required: true}) public kind!: Persisted<Kind>;
    @Input({required: true}) public datum!: BackendLocalDate;

    public service = inject(TagesinfoStateService);
    private stateService = inject(StateService);

    public isLoading = toSignal(this.service.store.itemsIsLoading$, {requireSync: true});
    public isEmpty = toSignal(this.service.store.itemsIsEmpty$, {requireSync: true});
    public createMode = toSignal(this.service.store.itemsShowCreateMode$, {requireSync: true});
    public showDeleteDialog = toSignal(this.service.store.itemsShowDeleteDialog$, {requireSync: true});
    public items: Signal<ItemWithEntity<Persisted<JaxKindCheckInEvent>>[]> =
        toSignal(this.service.store.itemsAll$, {requireSync: true});

    public lastState = toSignal(this.service.store.lastState$, {requireSync: true});
    public nextState = toSignal(this.service.store.nextState$, {requireSync: true});
    public nextStateText = computed(() => {
        return this.nextState() === 'ANGEKOMMEN' ? 'CHECK_IN.BUTTON.CHECK-IN' : 'CHECK_IN.BUTTON.CHECK-OUT';
    });

    public ngOnInit(): void {
        this.service.store.init({kindId: this.kind.id, datum: this.datum});
    }

    public uiOnParamsChanged(newParams: Record<string, unknown>): void {
        if (DvbDateUtil.isValidBackendLocalDate(newParams.datum)) {
            this.service.datum$.next(newParams.datum);
        }
    }

    public changeSelectedDay(datum: BackendLocalDate): void {
        this.stateService.go(TAGESINFO_STATE.name, {datum});
    }
}
