<div class="dvb-report-template-selector" ng-if="vm.reportTemplates.length">
    <dvb-report-template-outdated-icon class="version-icon"
                                       report-state="vm.reportState"
                                       report-template="vm.selectedReportTemplate">
    </dvb-report-template-outdated-icon>
    <select class="form-control"
            ng-change="vm.onSelectionChange({reportTemplate: vm.selectedReportTemplate})"
            ng-model="vm.selectedReportTemplate"
            ng-options="report as report.name for report in vm.reportTemplates | orderBy:'name'"
            tooltip-placement="top-left"
            uib-tooltip="{{'REPORT.TEMPLATE.TEMPLATE' | translate}}">
        <option data-translate="REPORT.TEMPLATE.DEFAULT" ng-value="null"></option>
    </select>
</div>
