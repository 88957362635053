/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KibonExchangeInstitution} from '@dv/kitadmin/models';
import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPersisted} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../config';
import {DvbRestUtilAngularJS} from '../../common/service/rest/dvbRestUtilAngularJS';

export class KibonInstitutionenService {

    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kibon/insitutionen`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    /**
     * Fetches all KibonExchange institutions that could not be matched to a kitAdmin KinderOrt.
     */
    public getInstitutionen(params?: RestInclude): angular.IPromise<Persisted<KibonExchangeInstitution>[]> {
        const url = `${KibonInstitutionenService.BASE_URL}`;
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(url, KibonExchangeInstitution, 'institutionen', matrixParams, true)
            .then(institutionen => institutionen.map(checkPersisted));
    }

    /**
     * Assigns a KibonExchange institution to a kitAdmin KinderOrt.
     *
     * @param institutionId the ID of the institution used internally by kitAdmin (not the external ID)
     * @param kinderOrtId the kinderOrt ID
     */
    public assignKibonInstitution(institutionId: string, kinderOrtId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KibonInstitutionenService.BASE_URL}/` +
            `${encodeURIComponent(institutionId)}/assign/${encodeURIComponent(kinderOrtId)}`;

        return this.$http.put(url, {});
    }

    /**
     * Removes an assigned KinderOrt from the KibonExchange institution.
     */
    public unassignKibonInstitution(institutionId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KibonInstitutionenService.BASE_URL}/` +
            `${encodeURIComponent(institutionId)}/unassign`;

        return this.$http.put(url, {});
    }
}
