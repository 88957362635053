/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxParameterKitas} from '@dv/shared/backend/model/jax-parameter-kitas';
import type {IRestModel, IValidable} from '@dv/shared/code';
import {DvbRestUtil, DvbUtil, SearchResultEntry} from '@dv/shared/code';
import type moment from 'moment';

export class ParameterKinderOrte implements IValidable, IRestModel<JaxParameterKitas> {
    public constructor(
        public alleKinderOrte: boolean = false,
        public kinderOrte: SearchResultEntry[] = [],
        public stichtag: moment.Moment | null = null,
        public year: number | null = null,
    ) {
    }

    public isValid(): boolean {
        return this.alleKinderOrte || this.hasKinderOrte();
    }

    public toRestObject(): JaxParameterKitas {
        return {
            alleKitas: this.alleKinderOrte,
            jahr: this.year ? this.year : undefined,
            stichtag: DvbRestUtil.momentToLocalDate(this.stichtag) ?? undefined,
            kitaIds: !this.alleKinderOrte && Array.isArray(this.kinderOrte) ?
                this.getEnabledKinderOrte().map(DvbUtil.mapToIdChecked) :
                [],
        };
    }

    public getEnabledKinderOrte(): readonly SearchResultEntry[] {
        return SearchResultEntry.getEnabled(this.kinderOrte);
    }

    public hasKinderOrte(): boolean {
        return DvbUtil.isNotEmptyArray(this.getEnabledKinderOrte());
    }
}
