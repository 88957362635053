/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Belegung,
    BetreuungsfaktorByKindId,
    GruppenWochenBelegung,
    Kind,
    KinderOrt,
    KinderOrtFraktion,
    KinderOrtFraktionId,
    KitaWochenBelegung,
    Kontingente,
    Wochenplan,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import {ZeitraumUtil} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {BetreuungsZeitraum, DayOfWeek, Persisted} from '@dv/shared/code';
import {DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type {StateService, UIRouterGlobals} from '@uirouter/core';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import type {
    KitaBetreuungsfaktorRegelService,
} from '../../../common/service/rest/kinderort/kitaBetreuungsfaktorRegelService';
import type {ZeitraumFilterController} from '../../../filter/zeitraum/ZeitraumFilterController';
import {MONAT_ZUWEISEN_STATE, ZUWEISEN_MULTIPLE_SIMPLE_STATE} from '../../../kind/zuweisung/kind-zuweisen-states';
import type {KindFilterModel} from '../../service/kinderFilter-models/KindFilterModel';
import type {KinderFilterService} from '../../service/kinderFilterService';
import type {KinderOrtWocheService} from '../../service/kinderOrtWocheService';
import {BelegungStrategy} from './BelegungStrategy';
import {BewerbungStrategy} from './BewerbungStrategy';
import type {KinderListeStrategy} from './KinderListeStrategy';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        wochenplanBewerbung: '<',
        firstOfWeek: '<',
        lastOfWeek: '<',
        onDateSelected: '&',
    },
    template: require('./dvb-kita-kinder.html'),
    controllerAs: 'vm',
};

const defaultCollapseThreshold = 6;

export class DvbKitaKinder implements angular.IController {

    public static $inject: readonly string[] = [
        '$scope',
        '$state',
        '$uiRouterGlobals',
        '$q',
        'kinderOrtWocheService',
        'kinderFilterService',
        'kinderOrtService',
        'kitaBetreuungsfaktorRegelService',
        'fraktionService',
        'authStore',
    ];

    private static readonly INCREASE_TO_INCREMENT: number = 15;
    private static readonly DEFAULT_LIMIT_TO: number = 20;

    public wochenplanBewerbung!: Wochenplan;
    public kita!: Persisted<KinderOrt>;
    public firstOfWeek!: moment.Moment;
    public lastOfWeek!: moment.Moment;
    public onDateSelected?: (param: { date: moment.Moment }) => any;

    public kinderListeStrategy: KinderListeStrategy;

    public gruppenIds: KinderOrtFraktionId[] = [];
    public selectedGruppen: KinderOrtFraktionId[] = [];
    public aktuelleGruppen: Persisted<KinderOrtFraktion>[] = [];
    public visibleGruppen: Persisted<KinderOrtFraktion>[] = [];
    public kitaKontingente: Kontingente[] = [];
    public weekDays: DayOfWeek[] = [];

    public kinder: (Kind & { visible: boolean; filterModel: KindFilterModel })[] = [];
    public selectedKindId: string | null = null;
    public loading: boolean = true;
    public betreuungsfaktoren: BetreuungsfaktorByKindId = {};
    public betreuungsfaktorenLoading: boolean = true;
    public limitTo: number = DvbKitaKinder.DEFAULT_LIMIT_TO;
    public visibleKinderCount: number = 0;
    public zeitraumFelderByGruppe: { [gruppeId: string]: ZeitraumFeld[] } = {};
    public gruppenWochenBelegungByGruppe: { [gruppeId: string]: GruppenWochenBelegung } = {};
    public kitaWochenBelegung: KitaWochenBelegung | null = null;
    public kitaDistinctZeitraeume: BetreuungsZeitraum[] = [];
    public kitaZeitraumFelder: ZeitraumFeld[] = [];

    public isFullyCollapsible: boolean = false;

    private fullCollapseThreshhold: number = defaultCollapseThreshold;

    private readonly zeitraeumeCtrl: ZeitraumFilterController<BetreuungsZeitraum>;

    public constructor(
        private $scope: angular.IScope & { firstOfWeek: moment.Moment },
        private $state: StateService,
        private $uiRouterGlobals: UIRouterGlobals,
        private $q: angular.IQService,
        private kinderOrtWocheService: KinderOrtWocheService,
        private kinderFilterService: KinderFilterService,
        private kinderOrtService: KinderOrtService,
        private kitaBetreuungsfaktorRegelService: KitaBetreuungsfaktorRegelService,
        private fraktionService: FraktionService,
        private authStore: AuthStore,
    ) {
        this.kinderListeStrategy = this.$uiRouterGlobals.current.name === 'base.kinderort.kinder.warteliste' ?
            new BewerbungStrategy() :
            new BelegungStrategy();

        this.zeitraeumeCtrl = this.kinderFilterService.filterControllers.wochenplanZeitraeumeCtrl;

        this.$scope.$on('filterModelChanged', () => {
            this.applyFilterOrResetIfNotSet();
            this.selectedGruppen = this.kinderFilterService.getGruppenIds();
            this.initVisibleGruppen();
        });
        this.selectedGruppen = this.kinderFilterService.getGruppenIds();
    }

    public $onChanges(onChangesObj: IOnChangesObject): void {
        if (onChangesObj.kita) {
            this.zeitraeumeCtrl.setRelevantKinderOrtId(this.kita.id);
            this.gruppenIds = this.kita.gruppen.map(DvbUtil.mapToIdChecked);
            this.kitaKontingente = this.kita.getKontingente();
        }

        if (onChangesObj.firstOfWeek) {
            this.aktuelleGruppen = this.kita.getGruppenGueltigOn(this.firstOfWeek);
            this.weekDays = ZeitraumUtil.getWeekDaysFromGruppen(this.aktuelleGruppen);
            this.initVisibleGruppen();
        }

        this.kinderListeStrategy.capacityOnly =
            this.authStore.hasPermission(`${PERMISSION.FEATURE.GRP_WEEK_CAPACITY}:${this.kita.id}`);

        this.init();
    }

    public updateZeitraumFeldFilter(
        zeitraumFeld: ZeitraumFeld<BetreuungsZeitraum>,
        gruppe: Persisted<KinderOrtFraktion>,
    ): void {
        this.zeitraeumeCtrl.updateZeitraumFeldFilter(this.kita.id, gruppe.id, zeitraumFeld);
    }

    public updateKinderOrtZeitraumFeldFilter(zeitraumFeld: ZeitraumFeld<BetreuungsZeitraum>): void {
        this.zeitraeumeCtrl.updateKinderOrtZeitraumFeldFilter(this.kita.id, zeitraumFeld);
    }

    public increaseLimitTo(): void {
        this.limitTo += DvbKitaKinder.INCREASE_TO_INCREMENT;
    }

    public isShowMoreLinkVisible(): boolean {
        return this.limitTo < this.visibleKinderCount && !this.loading;
    }

    public selectedKindChanged(selectedKind: Kind): void {
        this.selectedKindId = selectedKind.id === this.selectedKindId ?
            null :
            selectedKind.id;
    }

    public openZuweisung(kind: Kind, belegung?: Belegung): void {
        if (!belegung) {
            this.goToZuweisung(kind, [this.kita.id]);

            return;
        }

        this.fraktionService.fetchKinderOrteForBelegung(belegung)
            .then(kinderOrte => kinderOrte.map(DvbUtil.mapToIdChecked))
            .then(ids => {
                if (belegung.monatsBelegungId) {
                    this.goToMonatZuweisung(kind, ids[0], this.fraktionService.getFraktionIdsForBelegung(belegung)[0]);

                    return;
                }
                this.goToZuweisung(kind, ids);
            });
    }

    private goToZuweisung(kind: Kind, kinderOrtIds: string[]): void {
        this.$state.go(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, {
            kinderOrtIds,
            kindId: kind.id,
            zuweisungAb: DvbRestUtil.momentToLocalDate(this.firstOfWeek),
        });
    }

    private goToMonatZuweisung(kind: Kind, kinderOrtId: string, gruppeId: string): void {
        this.$state.go(MONAT_ZUWEISEN_STATE.name, {
            kindId: kind.id,
            kinderOrtId,
            gruppeId,
            zuweisungAb: DvbRestUtil.momentToLocalDate(this.firstOfWeek),
        });
    }

    private init(): void {
        this.loadKinder();
        this.initZeitraumFelder();
    }

    private unsetSelectedGruppenZeitraumfelder(): void {
        Object.values(this.zeitraumFelderByGruppe).forEach(zeitraumFelder => {
            zeitraumFelder.forEach(zeitraumFeld => {
                zeitraumFeld.selected = false;
            });
        });
    }

    private unsetSelectedKitaZeitraumfelder(): void {
        this.kitaZeitraumFelder.forEach(zeitraumFeld => {
            zeitraumFeld.selected = false;
        });
    }

    private applyFilterOrResetIfNotSet(): void {
        if (this.applyFilterIfSet()) {
            return;
        }
        this.unsetSelectedGruppenZeitraumfelder();
        this.unsetSelectedKitaZeitraumfelder();
        this.showAll();
    }

    /**
     * @return {boolean} TRUE when filter set, FALSE otherwise
     */
    private applyFilterIfSet(): boolean {
        if (this.kinderFilterService.isFilterSet(this.kita.id)) {
            this.applyFilter();

            return true;
        }
        this.visibleKinderCount = this.kinder.length;
        this.kinderListeStrategy.updatePensumTotal(this.kinder, this.firstOfWeek, this.kita.id);

        return false;
    }

    private applyFilter(): void {
        const params = {
            stichtag: this.firstOfWeek,
            kitaId: this.kita.id,
            gruppenIds: this.gruppenIds,
        };
        let visibleKinderCount = 0;
        const promises = this.kinder
            .map(kind => this.kinderFilterService.isVisible(kind.filterModel || {}, params)
                .then(() => {
                    kind.visible = true;
                    visibleKinderCount++;
                }).catch(() => {
                    kind.visible = false;
                }));
        this.$q.all(promises).then(() => {
            this.visibleKinderCount = visibleKinderCount;
            this.kinderListeStrategy.updatePensumTotal(this.kinder, this.firstOfWeek, this.kita.id);
        });
    }

    private showAll(): void {
        this.kinder.forEach(kind => {
            kind.visible = true;
        });
        this.visibleKinderCount = this.kinder.length;
        this.kinderListeStrategy.updatePensumTotal(this.kinder, this.firstOfWeek, this.kita.id);
    }

    private initZeitraumFelder(): void {
        if (!this.kita || !Array.isArray(this.kita.gruppen)) {
            return;
        }

        if (this.aktuelleGruppen.length === 0) {
            return;
        }

        this.aktuelleGruppen.forEach(gruppe => {
            if (!gruppe.wochenplan || !gruppe.id) {
                return;
            }
            // immer eine Kopie verwenden, damit der Wochenplan die ZeitraumFelder aktualisiert.
            // Führt leider zu einem hässlichen Flackern.
            const zeitraumFelder = angular.copy(gruppe.wochenplan.zeitraumFelder);
            // bestehende ZeitraumFeld-Filter markieren & nicht mehr existierende Zeitraeume aus dem Filter
            // entfernen
            this.zeitraeumeCtrl.initFraktionZeitraumFelder(this.kita.id, gruppe.id, zeitraumFelder);
            this.zeitraumFelderByGruppe[gruppe.id] = zeitraumFelder;
        });

        this.initKitaZeitraeumFelder();

        const params = {
            cache: true,
            includes: '(gruppenWochenBelegungen.fields(wochenPlaetze, verfuegbarkaeit))',
            timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
        };
        this.kinderOrtService.getKitaWochenBelegung(this.kita.id, this.firstOfWeek, params)
            .then(kitaWochenBelegung => this.setKitaWochenBelegungToZeitraumFelder(kitaWochenBelegung))
            .catch(DvbRestUtilAngularJS.handleRequestCancellation.bind(DvbRestUtilAngularJS));
    }

    private initKitaZeitraeumFelder(): void {
        const zeitreaumeByDayOfWeek = ZeitraumUtil.mergeTagesplaene(this.aktuelleGruppen);

        this.kitaDistinctZeitraeume =
            DvbUtil.uniqueArray(Array.from(zeitreaumeByDayOfWeek.values()).flat(), DvbUtil.mapToId);
        this.kitaZeitraumFelder = ZeitraumUtil.createZeitraumFelder(zeitreaumeByDayOfWeek);

        this.zeitraeumeCtrl.initKinderOrtZeitraumFelder(this.kita.id, this.kitaZeitraumFelder);
    }

    private initVisibleGruppen(): void {
        this.visibleGruppen = this.aktuelleGruppen
            .filter(g => this.selectedGruppen.length === 0 || this.selectedGruppen.includes(g.id));
        DvbUtil.orderByDisplayName(this.visibleGruppen);

        this.isFullyCollapsible = this.visibleGruppen.length > this.fullCollapseThreshhold;
    }

    private setKitaWochenBelegungToZeitraumFelder(kitaWochenBelegung: KitaWochenBelegung): void {

        kitaWochenBelegung.gruppenWochenBelegungen.forEach(gruppenWochenBelegung => {
            const zeitraumFelder = this.zeitraumFelderByGruppe[gruppenWochenBelegung.gruppeId!];
            this.gruppenWochenBelegungByGruppe[gruppenWochenBelegung.gruppeId!] = gruppenWochenBelegung;
            ZeitraumUtil.setBelegungToZeitraumFelder(
                gruppenWochenBelegung.betreuungsZeitraumBelegung,
                zeitraumFelder,
                this.kinderListeStrategy);
        });

        this.kitaWochenBelegung = kitaWochenBelegung;
        ZeitraumUtil.setBelegungToZeitraumFelder(
            kitaWochenBelegung.betreuungsZeitraumBelegung,
            this.kitaZeitraumFelder,
            this.kinderListeStrategy);
    }

    private handleKinderResponse(kinder: Kind[]): angular.IPromise<Kind[]> {
        if (!Array.isArray(kinder)) {
            this.kinder = [];

            return this.$q.resolve([]);
        }

        this.kinder = kinder.map(kind => kind as Kind & { visible: boolean; filterModel: KindFilterModel });
        const promises = this.kinder.map(kind => {
            const params = {
                kind,
                kitaKontingente: this.kitaKontingente,
                gruppenIds: this.gruppenIds,
                firstOfWeek: this.firstOfWeek,
            };

            return this.kinderListeStrategy.createKindFilterModel(params)
                .then(model => Object.assign(kind, {filterModel: model, visible: true}));
        });

        return this.$q.all(promises);
    }

    private refreshBetreuungsfaktoren(): void {
        const kinderIdList = this.kinder.map(DvbUtil.mapToIdChecked);

        if (kinderIdList.length === 0) {
            return;
        }

        this.betreuungsfaktorenLoading = true;
        const params = {
            stichtag: this.firstOfWeek,
            timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
        };

        this.kitaBetreuungsfaktorRegelService.queryKinderBetreuungsfaktoren(this.kita.id, kinderIdList, params)
            .then(kinderBetreuungsfaktoren => {
                this.betreuungsfaktoren = kinderBetreuungsfaktoren.faktoren;
            })
            .catch(DvbRestUtilAngularJS.handleRequestCancellation.bind(DvbRestUtilAngularJS))
            .finally(() => {
                this.betreuungsfaktorenLoading = false;
            });
    }

    private loadKinder(): void {
        if (this.kinderListeStrategy.isLoaded(this.$scope)) {
            this.refreshKinder();
            this.kinder.forEach(kind => {
                kind.filterModel.updateStatus(this.firstOfWeek);
            });
        } else {
            this.loading = true;
            const belegungIncludes = 'pensum, customFieldValues, gruppenBelegungen.fields(' +
                'pensum,gruppeId,plaetze.fields(belegungsEinheitId,kontingentId),vertraglichePensen,anwesenheit)';

            const includes = `(geschwister,belegungen.fields(${belegungIncludes})` +
                'bewerbungen.fields(firmenIds, firmen, kitas, belegteEinheiten.fields(belegungsEinheitenIds))' +
                `kindWochenBelegungen.fields(kitaPensen, ${belegungIncludes}),` +
                'extraPlatz.fields(belegungsEinheit.fields(zeitraumIds),kontingentId))';

            const params = {
                kitaId: this.kita.id,
                includes,
                gueltigAb: this.firstOfWeek,
                gueltigBis: this.lastOfWeek,
                timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
            };
            this.kinderListeStrategy.loadKinder(this.kinderOrtService, params)
                .then(kinder => this.handleKinderResponse(kinder))
                .then(() => {
                    this.refreshKinder();
                    this.loading = false;
                })
                .catch(DvbRestUtilAngularJS.handleRequestCancellation.bind(DvbRestUtilAngularJS));
        }
    }

    private refreshKinder(): void {
        this.applyFilterIfSet();
        // Even if the children do not have to be reloaded, the Betreuungsfaktoren should be refreshed.
        this.refreshBetreuungsfaktoren();
    }
}

componentConfig.controller = DvbKitaKinder;
angular.module('kitAdmin').component('dvbKitaKinder', componentConfig);
