/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MandantRechnungConfig} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Persisted} from '@dv/shared/code';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {CONFIG} from '../../../config';

@Injectable({
    providedIn: 'root',
})
export class MandantRechnungConfigService {
    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/mandantrechnungconfig`;

    public constructor(
        private http: HttpClient,
    ) {
    }

    public create$(config: MandantRechnungConfig, mandantId: string): Observable<MandantRechnungConfig> {
        const url = `${MandantRechnungConfigService.BASE_URL}/mandant/${mandantId}`;

        return this.http.post<unknown>(url, config.toRestObject()).pipe(
            map(MandantRechnungConfig.apiResponseTransformer));
    }

    public update$(config: Persisted<MandantRechnungConfig>): Observable<MandantRechnungConfig> {
        const url = `${MandantRechnungConfigService.BASE_URL}/${config.id}`;

        return this.http.put<unknown>(url, config.toRestObject()).pipe(
            map(MandantRechnungConfig.apiResponseTransformer));
    }

    public deletePosition$(configId: EntityId, positionId: EntityId): Observable<unknown> {
        const url = `${MandantRechnungConfigService.BASE_URL}/${configId}/positions/${positionId}`;

        return this.http.delete<unknown>(url);
    }
}
