/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {ReportState} from '../../models';
import {ERR_VALIDATION, ParameterFruehSpaetDienstReport} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        stichtag: '<?',
        preSelectableKinderOrt: '<?',
    },
    template: require('./dvb-frueh-spaet-dienst-report.html'),
    controllerAs: 'vm',
};

export class DvbFruehSpaetDienstReport implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['errorService', 'reportService', '$q'];

    public readonly reportState!: ReportState;
    public stichtag?: moment.Moment;
    public kinderOrt?: SearchResultEntry;
    public preSelectableKinderOrt?: SearchResultEntry;

    public defaultDate: moment.Moment = DvbDateUtil.today();
    public defaultFruehBis: moment.Moment = moment('08:30', 'HH:mm', true);
    public defaultSpaetVon: moment.Moment = moment('17:00', 'HH:mm', true);

    public params: ParameterFruehSpaetDienstReport = new ParameterFruehSpaetDienstReport();

    public reportTemplate: ReportTemplate | null = null;

    public readonly fruehSpaetDienstReportForm?: angular.IFormController;

    public constructor(
        private errorService: ErrorService,
        private reportService: ReportService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.params.stichtag = this.stichtag ?? null;

        if (this.preSelectableKinderOrt && !this.kinderOrt) {
            this.kinderOrt = this.preSelectableKinderOrt;
        }
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateStichtag(): void {
        this.reportService.updateStateParams({
            stichtag: this.params.stichtag,
        });
    }

    public submit(): angular.IPromise<TempBlob> {
        this.errorService.clearAll();

        const paramsCopy = angular.copy(this.params);

        paramsCopy.stichtag = paramsCopy.stichtag ?? this.defaultDate;
        paramsCopy.fruehDienstBis = paramsCopy.fruehDienstBis ?? this.defaultFruehBis;
        paramsCopy.spaetDienstVon = paramsCopy.spaetDienstVon ?? this.defaultSpaetVon;
        paramsCopy.kinderOrtId = this.kinderOrt ? this.kinderOrt.id : null;

        const isValid = this.fruehSpaetDienstReportForm!.$valid && paramsCopy.isValid();

        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.reportService.getTempBlobForFruehSpaetDienst(paramsCopy, {reportTemplate: this.reportTemplate});
    }
}

componentConfig.controller = DvbFruehSpaetDienstReport;
angular.module('kitAdmin').component('dvbFruehSpaetDienstReport', componentConfig);
