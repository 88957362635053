<div class="dvb-betreuungsverlauf">

    <!-- BELEGUNGEN -->
    <div ng-repeat="belegungInterval in vm.belegungIntervals">
        <!-- AUSTRITT -->
        <dvb-kind-austritt-collapsible-panel
            ng-if="belegungInterval.isAustritt"
            belegung="belegungInterval.belegungen[0]"
            kind="vm.kind"
            show-line-top="vm.belegungIntervals[0] === belegungInterval && vm.showLineTop && vm.isAusgetreten"
            is-date-clickable="vm.isDateClickable"
            on-date-selected="vm.onDateSelected({date: date})"
            on-revert-austritt="vm.onRevertAustritt(austrittsDatum)">
        </dvb-kind-austritt-collapsible-panel>

        <!-- DIE EIGENTLICHE(N) BELEGUNG(EN)-->
        <dvb-collapsible-panel ng-if="!belegungInterval.isAustritt"
                               expand-ab="vm.expandAb"
                               expand-bis="vm.expandBis"
                               is-first="$first && vm.showLineTop && !vm.isAusgetreten"
                               limited-entity="belegungInterval">
            <panel-header>
                <dvb-belegung-interval-header belegung-interval="belegungInterval"
                                              is-date-clickable="vm.isDateClickable"
                                              on-date-selected="vm.onDateSelected({date: date})">
                </dvb-belegung-interval-header>
            </panel-header>
            <panel-content>
                <dvb-belegung ng-repeat="belegung in belegungInterval.belegungen"
                              ng-class="{'normal-abstand-top': !$first}"
                              belegung="belegung"
                              hide-icons="belegungInterval.isMonatsbelegung"
                              is-date-clickable="vm.isDateClickable"
                              on-date-selected="vm.onDateSelected({date: date})"
                              show-bemerkungen="!belegungInterval.isMonatsbelegung"
                              show-betreuungs-vereinbarung="!belegungInterval.isMonatsbelegung && vm.showBetreuungsVereinbarung">
                    <ul class="list-inline hidden-print"
                        require-condition="!belegungInterval.isMonatsbelegung"
                        dvb-auth
                        require-permission="kita:manage:general">
                        <li ng-if="vm.onEditBelegung">
                            <a data-translate="COMMON.BEARBEITEN"
                               href=""
                               ng-click="vm.onEditBelegung({belegung: belegung})"></a>
                        </li>
                        <li>
                            <a data-translate="COMMON.LOESCHEN"
                               href=""
                               ng-click="vm.confirmDeleteSingleBelegung(belegung)"
                               ng-if="vm.showDelete"></a>
                        </li>
                    </ul>
                </dvb-belegung>

                <dvb-betreuungsverlauf-extra-plaetze ng-if="belegungInterval.belegungen.length === 0"
                                                     ng-repeat="entry in belegungInterval.extraPlatzWochenBelegungen"
                                                     kind-id="vm.kind.id"
                                                     show-abweichungen-bearbeiten="vm.showAbweichungenBearbeiten"
                                                     entry="entry">
                </dvb-betreuungsverlauf-extra-plaetze>

                <dvb-collapsible-panel ng-if="belegungInterval.belegungen.length > 0"
                                       ng-repeat="entry in belegungInterval.extraPlatzWochenBelegungen"
                                       expand-ab="vm.expandAb"
                                       expand-bis="vm.expandBis"
                                       is-first="false"
                                       is-last="$last"
                                       limited-entity="entry.wochenBelegung">
                    <panel-header>
                        <dvb-belegung-interval-header belegung-interval="entry"
                                                      title-key="'KIND.EXTRA_DAY.WITH_RANGE'">
                        </dvb-belegung-interval-header>
                    </panel-header>
                    <panel-content>
                        <dvb-betreuungsverlauf-extra-plaetze entry="entry"
                                                             kind-id="vm.kind.id"
                                                             show-abweichungen-bearbeiten="vm.showAbweichungenBearbeiten">
                        </dvb-betreuungsverlauf-extra-plaetze>
                    </panel-content>
                </dvb-collapsible-panel>

                <!-- Footer if there is more than one interval -->
                <div class="interval-footer normal-abstand-top"
                     ng-if="belegungInterval.isMonatsbelegung">
                    <ul class="list-inline full-width-inline-list interval-footer-column interval-footer-column-left"
                        dvb-auth
                        require-permission="kita:manage:general">
                        <li ng-if="vm.onEditBelegung && belegungInterval.targetGruppeId !== null">
                            <a data-translate="COMMON.BEARBEITEN"
                               href=""
                               ng-click="vm.goToMonatsbelegung(belegungInterval)"></a>
                        </li>
                        <li>
                            <a data-translate="COMMON.LOESCHEN"
                               href=""
                               ng-click="vm.confirmDeleteMonatsBeleguung(belegungInterval)"
                               ng-if="vm.showDelete">
                            </a>
                        </li>
                    </ul>

                    <ul class="inline-icons full-width-inline-list interval-footer-column interval-footer-column-right">
                        <li ng-if="belegungInterval.kindergartenBelegung !== vm.kindergartenBelegung.KEINE">
                            <dvb-kindergarten-icon
                                kindergarten-belegung="{{belegungInterval.kindergartenBelegung}}">
                            </dvb-kindergarten-icon>
                        </li>
                        <li ng-if="belegungInterval.betreuungsfaktor">
                            <dvb-betreuungsfaktor-icon faktor="belegungInterval.betreuungsfaktor">
                            </dvb-betreuungsfaktor-icon>
                        </li>
                        <li class="truncate"
                            ng-bind="belegungInterval.bemerkung"
                            ng-if="belegungInterval.bemerkung"
                            uib-tooltip="{{belegungInterval.bemerkung}}"
                            tooltip-placement="top-left">
                        </li>
                    </ul>
                </div>
            </panel-content>
        </dvb-collapsible-panel>
    </div>

    <!-- ANMELDUNG-->
    <div class="collapsible-panel-container"
         ng-if="vm.showAnmeldeDatum"
         ng-class="{'first-container': vm.showLineTop && vm.belegungIntervals.length === 0}">
        <p class="collapsible-panel-body">
            <span data-translate="KIND.ANMELDUNG_AM"></span>
            <span ng-bind="vm.kind.anmeldeDatum | amDateFormat: 'D.M.YYYY'" ng-if="vm.isDateClickable === false"></span>
            <a href=""
               ng-if="vm.isDateClickable !== false"
               ng-bind="vm.kind.anmeldeDatum | amDateFormat: 'D.M.YYYY'"
               ng-click="vm.onDateSelected({date: vm.kind.anmeldeDatum});">
            </a>
        </p>
    </div>
</div>
