/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPresent, EntitySelection} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {KibonExchangeBetreuung} from '../../models/KibonExchangeBetreuung';
import type {KibonExchangeBetreuungRefreshJob} from '../../models/KibonExchangeBetreuungRefreshJob';
import type {KibonExchangeBetreuungService} from '../../service/kibonExchangeBetreuungService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kibon-mutationslauf.html'),
    controllerAs: 'vm',
};

const betreuungenParams: RestInclude = {includes: '(kind, leistungs_rechnung_task, comments)'};
const hundred = 100;

export class DvbKibonMutationslauf extends EntitySelection<KibonExchangeBetreuung> implements angular.IController {
    public static override $inject: readonly string[] = [
        'kibonExchangeBetreuungService',
        'dvbStateService',
        'errorService',
        'dialogService',
    ];

    public kinderOrt!: Persisted<KinderOrt>;

    public betreuungen: KibonExchangeBetreuung[] = [];
    public isRefreshing: { [kindId: string]: boolean } = {};
    public isKinderOrtRefreshing: boolean = false;
    public refreshJob: KibonExchangeBetreuungRefreshJob | null = null;
    public progressValue: number = 0;

    public isLoading: boolean = false;
    public itemsPerPage: number = 10;
    public currentPage: number = 1;

    private refetchedForKinderIds: string[] = [];

    public constructor(
        private readonly kibonService: KibonExchangeBetreuungService,
        private readonly dvbStateService: DvbStateService,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
    ) {
        super();
    }

    public $onChanges(): void {
        this.initBetreuungen();
    }

    public override selectAll(): void {
        super.selectAll(this.betreuungen.filter(b => b.isMutierbar()));
    }

    public submit(): void {
        this.errorService.clearAll();
        const selected = this.getSelected(this.betreuungen);

        this.kibonService.sendConfirmation(
            selected,
            () => from(this.kibonService.sendForKinderOrt(this.kinderOrt.id, selected))
                .pipe(take(1), tap(() => this.initBetreuungen())));
    }

    public ignore(betreuung: KibonExchangeBetreuung): void {
        this.errorService.clearAll();

        this.kibonService.ignoreBetreuungen(
            [betreuung],
            () => from(this.kibonService.ignore([betreuung]))
                .pipe(take(1), tap(() => this.initBetreuungen())));
    }

    public cancel(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    public refreshKind(kindId: string): void {
        this.isRefreshing[kindId] = true;
        this.kibonService.refreshKind(kindId)
            .then(() => this.initBetreuungen())
            .finally(() => {
                delete this.isRefreshing[kindId];
            });
    }

    public refresh(): void {
        const title = 'KIBON.MUTATION.NEU_GENERIEREN';
        const subtitle = 'KIBON.MUTATION.NEU_GENERIEREN_CONFIRM_TEXT';

        const confirm = (): Observable<string> => from(this.kibonService.refreshKinderOrt(this.kinderOrt.id))
            .pipe(take(1), tap(jobId => this.refreshWithPolling(jobId)));

        this.dialogService.openConfirmDialog({
            title,
            subtitle,
            confirm,
        });
    }

    private refreshWithPolling(jobId: string): void {
        this.isKinderOrtRefreshing = true;
        this.progressValue = 0;
        this.betreuungen = [];
        this.refetchedForKinderIds = [];

        this.kibonService.startBetreuungRefreshPolling(this.kinderOrt.id, jobId)
            .then(
                () => this.stopRefresh(),
                () => this.stopRefresh(),
                job => this.updateRefreshStatus(job),
            );
    }

    private updateRefreshStatus(job: KibonExchangeBetreuungRefreshJob): void {
        this.refreshJob ??= job;
        this.refreshJob.completedKinderIds = job.completedKinderIds;

        const newKindIds = this.refreshJob.completedKinderIds
            .filter(id => !this.refetchedForKinderIds.includes(id));

        // removing betreuungen of refreshed Kinder
        this.betreuungen = this.betreuungen.filter(b => !newKindIds.includes(checkPresent(b.kind).id));

        // getting new betreuungen for refreshed Kinder
        newKindIds.forEach(id => this.kibonService.getRefreshed(this.kinderOrt.id, id, betreuungenParams)
            .then(result => this.betreuungen.push(...result)));
        this.refetchedForKinderIds.push(...newKindIds);

        this.progressValue = job.completedKinderIds.length / job.affectedKindCount * hundred;

        if (this.refreshJob.status === 'FINISHED') {
            this.stopRefresh();
        }
    }

    private stopRefresh(): void {
        this.refreshJob = null;
        this.isKinderOrtRefreshing = false;
    }

    private initBetreuungen(): void {
        this.kibonService.findRunningRefreshJob(this.kinderOrt.id)
            .then(runningJob => {
                if (runningJob) {
                    this.refreshWithPolling(runningJob.id);

                    return;
                }

                // only when there is no running job, we allow fetching all betreuungen
                this.isLoading = true;
                this.kibonService.getForKinderOrt(this.kinderOrt.id, betreuungenParams).then(betreuungen => {
                    this.betreuungen = betreuungen;
                }).finally(() => {
                    this.isLoading = false;
                });
            });
    }
}

componentConfig.controller = DvbKibonMutationslauf;
angular.module('kitAdmin').component('dvbKibonMutationslauf', componentConfig);
