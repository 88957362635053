/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FirmenKontingentValue} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firmenKontingentValue: '<',
    },
    template: require('./dvb-firma-kontingent-value-content.html'),
    controllerAs: 'vm',
};

export class DvbFirmaKontingentValueContent {

    public static $inject: readonly string[] = ['$state', 'dialogService', 'kinderOrtService', 'errorService'];

    public firmenKontingentValue!: Persisted<FirmenKontingentValue>;
    public editMode: boolean = false;
    public isLoading: boolean = false;
    public editFirmenKontingentValue: FirmenKontingentValue | null = null;

    public constructor(
        private readonly $state: StateService,
        private readonly dialogService: DialogService,
        private readonly kinderOrtService: KinderOrtService,
        private readonly errorService: ErrorService,
    ) {
    }

    public deleteFirmenKontingentValue(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'FIRMA.KONTINGENT_PLAETZE',
            confirm: () => from(this.kinderOrtService.deleteFirmenKontingentValue(this.firmenKontingentValue.id))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public onSubmit(form: angular.IFormController): void {
        const isKitaValid = this.editFirmenKontingentValue!.isKitaSet();
        this.errorService.handleValidationError(isKitaValid, 'ERRORS.ERR_REQUIRED_KITA');
        if (!isKitaValid) {
            return;
        }

        const isValid = form.$valid && this.editFirmenKontingentValue!.isValid() && isKitaValid;
        this.errorService.handleValidationError(isValid, 'ERRORS.VALUE_REQUIRED');
        if (!isValid) {
            return;
        }

        this.isLoading = true;
        this.kinderOrtService.updateFirmenKontingentValue(this.editFirmenKontingentValue!).then(() => {
            this.firmenKontingentValue = checkPersisted(this.editFirmenKontingentValue);
        }).finally(() => {
            this.isLoading = false;
            this.editMode = false;
        });
    }

    public edit(): void {
        this.editFirmenKontingentValue = angular.copy(this.firmenKontingentValue);
        this.editMode = true;
    }
}

componentConfig.controller = DvbFirmaKontingentValueContent;
angular.module('kitAdmin').component('dvbFirmaKontingentValueContent', componentConfig);

