/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import angular from 'angular';
import type moment from 'moment';
import type {AdministrationService} from '../../service/administrationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-recalculate-mandant-rechnung.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceRecalculateMandantRechnung {
    public static $inject: readonly string[] = [
        'administrationService',
        'errorService',
    ];

    public stichtag: moment.Moment | null = null;

    public isLoading: boolean = false;

    public constructor(
        private readonly adminService: AdministrationService,
        private readonly errorService: ErrorService,
    ) {
    }

    public recalculateMandantRechnungForm(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid || !this.stichtag) {
            return;
        }

        this.isLoading = true;
        this.adminService.recalculateMandantRechnung(this.stichtag)
            .finally(() => {
                this.isLoading = false;
            });
    }

}

componentConfig.controller = DvbMaintenanceRecalculateMandantRechnung;
angular.module('kitAdmin').component('dvbMaintenanceRecalculateMandantRechnung', componentConfig);
