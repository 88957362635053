<div class="dvb-fraktion-kapazitaet-form">
    <div class="form-section">
        <div class="row">
            <div class="col-md-6" ng-if="vm.displayMode === 'EDITABLE-DATE'">
                <!-- Durch ng-if wird der Date Picker immer wieder neu initialisiert -->
                <dvb-datepicker-text-field ng-model="vm.wochenKapazitaet.gueltigAb"
                                           required
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-md-6" ng-if="vm.displayMode === 'READ-ONLY-DATE'">
                <dvb-kapazitaet-header wochen-kapazitaet="vm.wochenKapazitaet"></dvb-kapazitaet-header>
            </div>
            <div class="col-md-6">
                <input type="number"
                       name="anzahl-plaetze"
                       class="form-control"
                       ng-attr-placeholder="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
                       uib-tooltip="{{'COMMON.ANZAHL_PLAETZE' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.wochenKapazitaet.anzahlPlaetze"
                       required
                       ng-blur="vm.setAnzahlToZeitraumFelder(vm.wochenKapazitaet.anzahlPlaetze, vm.zeitraumFelder)"
                       min="0"
                       ng-keydown="vm.blurOnEnter($event)"
                       ng-focus="vm.backupAnzahlPlaetze = vm.wochenKapazitaet.anzahlPlaetze"
                       dvb-select-on-click>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-12">
                <dvb-wochenplan distinct-betreuungs-zeitraeume="vm.wochenplan.distinctZeitraeume"
                                week-days="vm.enabledDays"
                                is-editable="true"
                                on-zeitraum-feld-edited="vm.onZeitraumFeldEdited(zeitraumFeld)"
                                zeitraum-felder="vm.zeitraumFelder"
                                show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
                </dvb-wochenplan>
            </div>
        </div>
    </div>

    <div class="form-section-minus-normal-abstand">
        <div class="row" ng-class="{'form-row' : vm.hasMaximalePlaetze}">
            <div class="col-md-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               ng-model="vm.hasMaximalePlaetze"
                               ng-change="vm.onToggleHasMaxPlaetze()">
                        <span data-translate="FRAKTION.MAXIMALE_PLAETZE_FESTLEGEN"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row" ng-show="vm.hasMaximalePlaetze">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       name="maxPlaetze"
                       ng-attr-placeholder="{{'FRAKTION.ANZAHL_MAX_PLAETZE' | translate}}"
                       uib-tooltip="{{'FRAKTION.ANZAHL_MAX_PLAETZE' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.wochenKapazitaet.maxPlaetze"
                       ng-required="vm.hasMaximalePlaetze"
                       ng-blur="vm.setAnzahlToZeitraumFelder(vm.wochenKapazitaet.maxPlaetze, vm.zeitraumFelderMax)"
                       min="0"
                       ng-keydown="vm.blurOnEnter($event)"
                       ng-focus="vm.backupMaxPlaetze = vm.wochenKapazitaet.maxPlaetze"
                       dvb-select-on-click>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-12">
                <dvb-wochenplan ng-show="vm.hasMaximalePlaetze"
                                distinct-betreuungs-zeitraeume="vm.wochenplan.distinctZeitraeume"
                                week-days="vm.enabledDays"
                                on-zeitraum-feld-edited="vm.onZeitraumFeldEdited(zeitraumFeld)"
                                is-editable="true"
                                zeitraum-felder="vm.zeitraumFelderMax"
                                show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
                </dvb-wochenplan>
            </div>
        </div>
    </div>
</div>
