/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Wohnadresse} from '@dv/kitadmin/models';
import type {Adresse, DvbError} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, DvbUtil, ErrorType} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from '../../../service/rest/dvbRestUtilAngularJS';

const componentConfig: angular.IComponentOptions = {
    require: {ngModelCtrl: '^ngModel'},
    bindings: {
        isAddressRequired: '<?',
    },
    template: require('./dvb-adresse-form.html'),
    controllerAs: 'vm',
};

export class DvbAdresseForm implements angular.IController {
    public static $inject: readonly string[] = [
        'addressService',
        '$q',
        'errorService',
        '$translate',
    ];

    public isAddressRequired: boolean = true;

    public adresse: Adresse | null = null;
    private ngModelCtrl!: angular.INgModelController;

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        public readonly addressService: any,
        public readonly $q: any,
        public readonly errorService: any,
        public readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onInit(): void {
        this.ngModelCtrl.$parsers.push(this.parseCountry.bind(this));
        this.ngModelCtrl.$asyncValidators.adresse = this.validateAdresse.bind(this);
        this.ngModelCtrl.$render = this.render.bind(this);
        if (this.isAddressRequired === undefined) {
            this.isAddressRequired = true;
        }
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public updateModel(): void {
        // Triggere Parsers -> Validators -> write to $modelValue Pipeline
        // Damit dies bei Objekten ausgefuehrt wird muss eine Kopie des Objekts uebergeben werden
        this.ngModelCtrl.$setViewValue(angular.copy(this.adresse));

        // Sicherstellen, dass im Template der aktuelle (validierte & korrigierte) $viewValue angezeigt wird
        this.ngModelCtrl.$render();
    }

    private render(): void {
        // Mache $viewValue im Template verfuegbar
        this.adresse = this.ngModelCtrl.$viewValue;
    }

    private parseCountry(viewValue: Adresse): Adresse {
        if (!this.addressService.isAdresseSearchable(viewValue)) {
            viewValue.externalId = null;
            viewValue.gemeinde = null;
        }

        return viewValue;
    }

    private validateAdresse(_modelValue: Adresse, viewValue: Adresse): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);

        this.timeout = this.$q.defer();

        if (!viewValue) {
            return this.$q.when(true);
        }

        if (!viewValue.land || !this.addressService.isSwitzerland(viewValue.land) && !viewValue.isValid()) {
            // Der $q.reject() verursacht, dass der ngModel-Input-Wert (ausserhalb der Direktive) undefined wird
            // (AngularJS Standardverhalten bei invalidem model)!
            return this.$q.reject();
        }

        if (!this.addressService.isAdresseSearchable(viewValue)) {
            // Validierung noch nicht triggern, wenn nicht genuegend Daten eigegeben wurden
            return this.$q.when(true);
        }

        const config = {timeout: this.timeout!.promise};

        return this.addressService.searchWohnadressen(viewValue, config).then((wohnadressen: Wohnadresse[]) => {
            this.handleSearchResults(wohnadressen);
        }).catch((error: DvbError) => {

            if (DvbRestUtil.isRequestCancelled(error)) {
                return this.$q.resolve();
            }

            // Falls das API nicht verfügbar ist, gehen wir davon aus, dass die UserDaten korrekt sind, löschen
            // aber Gemeine und externalId
            viewValue.externalId = null;
            viewValue.gemeinde = null;

            // Ersetze den generischen internen Fehler mit einer Adresse-spezifischen Fehlermeldung
            if (error?.type && error.type === ErrorType.INTERNAL) {
                this.errorService.clearErrorByMsgKey(error.msgKey);
                this.errorService.addValidationError('ERRORS.ERR_ADRESSE_VALIDATION_UNAVAILABLE');
            }

            return this.$q.when(true);
        });
    }

    /**
     * @param {Array.<Wohnadresse>} wohnadressen - alle relevanten (Such-)adressfelder stimmen für alle
     * wohnadressen überein, fehlt z.B. die Hausnummer im Suchtext, könnten wir n-Wohnadressen mit allen
     * gefundenen Hausnummern (zu dieser Strasse und PLZ/Ort ) im Wohnungsregister bekommen
     */
    private handleSearchResults(wohnadressen: Wohnadresse[]): void {
        if (wohnadressen.length === 1) {
            this.correctAdresse('externalId', wohnadressen[0].externalId);
        } else {
            // too many possibilities
            this.correctAdresse('externalId', null);
        }

        const gemeinden = DvbUtil.uniqueArray(wohnadressen.map(this.mapToGemeinde.bind(this)));
        if (gemeinden.length === 1) {
            this.correctAdresse('gemeinde', gemeinden[0]);
        } else {
            // Gemeinde wird immer aktualisiert; kein User input
            this.correctAdresse('gemeinde', null);
        }

        const orte = DvbUtil.uniqueArray(wohnadressen.map(this.mapToOrt.bind(this)));
        if (!this.ngModelCtrl.$viewValue.ort && orte.length === 1) {
            this.correctAdresse('ort', orte[0]);
        }
    }

    private mapToGemeinde(wohnadresse: Wohnadresse): string {
        return checkPresent(wohnadresse.gemeinde);
    }

    private mapToOrt(wohnadresse: Wohnadresse): string {
        return checkPresent(wohnadresse.ort);
    }

    /**
     * Updates $viewValue programatically -> to see the changes in the template call ngModel.$render()
     */
    private correctAdresse(property: string, value: string | null): void {
        if (this.ngModelCtrl.$viewValue[property] !== value) {
            this.ngModelCtrl.$viewValue[property] = value;
        }
    }
}

componentConfig.controller = DvbAdresseForm;
angular.module('kitAdmin').component('dvbAdresseForm', componentConfig);
