/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaTarifeZeitraum} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaTarifeZeitraum: '<',
    },
    template: require('./dvb-kita-tarife-verlauf-header.html'),
    controllerAs: 'vm',
};

export class DvbKitaTarifeVerlaufHeader {
    public static $inject: readonly string[] = ['$translate'];

    public kitaTarifeZeitraum!: KitaTarifeZeitraum;

    public constructor(public $translate: angular.translate.ITranslateService) {
    }

    public getTypList(): string {
        return this.kitaTarifeZeitraum.getCleanCopy().abrechnungTarife
            .map(kitaAbrechnungTarif => kitaAbrechnungTarif.typ)
            .sort(abrechnungTyp => abrechnungTyp.index)
            .map(abrechnungTyp => this.$translate.instant(`COMMON.${abrechnungTyp.name}`))
            .join(', ');
    }
}

componentConfig.controller = DvbKitaTarifeVerlaufHeader;

angular.module('kitAdmin').component('dvbKitaTarifeVerlaufHeader', componentConfig);
