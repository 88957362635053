/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UpgradeModule} from '@angular/upgrade/static';
import type {Environment} from '@dv/kitadmin/models';
import type {UrlService} from '@uirouter/core';
import angular from 'angular';
import {ANGULAR_JS_MODULE} from './angularjs-module';
import {initErrorTracking} from './errors/errorTracking';

export function initAngularJs(ENVIRONMENT: Environment): void {
    (window as any).angular = angular;
    initErrorTracking(ENVIRONMENT);

    // Using AngularJS config block, call `deferIntercept()`.
    // This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
    ANGULAR_JS_MODULE.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);
    // provide the environment to the AngularJS module
    ANGULAR_JS_MODULE.constant('ENVIRONMENT', ENVIRONMENT);

    // Show ui-router-visualizer
    // ANGULAR_JS_MODULE.run(['$uiRouter', visualizer]);
}

export function bootstrapAngularJsApp(upgrade: UpgradeModule): void {
    upgrade.bootstrap(document.body, [ANGULAR_JS_MODULE.name], {strictDi: true, csp: 'no-inline-style'});
}
