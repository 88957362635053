/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        isLoading: '<',
        asLink: '<?',
        dropdown: '<?',
        margin: '<?',
        spinnerRight: '<?',
        type: '@?',
        onClick: '&?',
    },
    template: require('./dvb-loading-button.html'),
    controllerAs: 'vm',
};

export class DvbLoadingButton implements angular.IController {

    public static $inject: readonly string[] = ['$timeout', '$attrs'];

    private static readonly DOUBLE_CLICK_DELAY: number = 300;

    public isLoading!: boolean;
    public asLink: boolean = false;
    public dropdown: boolean = false;
    public margin: boolean = false;
    public spinnerRight: boolean = false;
    public type?: string;
    public onClick?: (data: { event: Event }) => unknown;

    public isDisabled: boolean = false;

    private ignoreEvent: boolean = false;

    public constructor(
        private readonly $timeout: angular.ITimeoutService,
        private readonly $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.isLoading) {
            this.isLoading = changes.isLoading.currentValue ?? false;
        }

        if (changes.type) {
            this.type = changes.type.currentValue ?? 'button';
        }
    }

    public preventDoubleClick($event: Event): void {
        if (this.ignoreEvent) {
            return;
        }

        this.ignoreEvent = true;
        if (TypeUtil.isFunction(this.onClick)) {
            this.onClick({event: $event});
        }

        this.$timeout(() => {
            this.ignoreEvent = false;
        }, DvbLoadingButton.DOUBLE_CLICK_DELAY, false);
    }
}

componentConfig.controller = DvbLoadingButton;
angular.module('kitAdmin').component('dvbLoadingButton', componentConfig);
