import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'dv-kita-work-time-controlling',
    standalone: true,
    imports: [],
    templateUrl: './kita-work-time-controlling.component.html',
    styles: `
    :host {
      display: block;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KitaWorkTimeControllingComponent {
}
