<div class="dvb-kitas-selection">
    <form name="kitasSelectionForm" novalidate>
        <div class="row form-row">
            <div class="col-xs-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               ng-model="vm.parameterKinderOrte.alleKinderOrte"
                               ng-change="vm.onChange()">
                        <span data-translate="COMMON.ALLE_KINDERORTE"></span>
                    </label>
                </div>
            </div>
        </div>


        <dvb-multi-select ng-if="!vm.parameterKinderOrte.alleKinderOrte"
                          ng-model="vm.selectedKinderOrte"
                          options="vm.availableKinderOrtDisplayables"
                          on-change="vm.kinderOrtSelectionChanged()"
                          placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                          ng-required="vm.isSelectionRequired">
        </dvb-multi-select>
    </form>
</div>
