/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    bindings: {},
    controllerAs: 'vm',
    template: '<i class="fa fa-times"' +
        ' uib-tooltip="{{\'COMMON.LOESCHEN\' | translate}}"' +
        ' tooltip-placement="top-left"></i>',
};

export class DvbDeleteIcon {
    public static $inject: readonly string[] = [];
}

componentConfig.controller = DvbDeleteIcon;
angular.module('kitAdmin').component('dvbDeleteIcon', componentConfig);
