<div class="dvb-betreuungsperson-erstellen">
    <div class="form-section">
        <div class="row">
            <div class="col-md-6">
                <input autocomplete="family-name"
                       class="form-control"
                       maxlength="255"
                       ng-model="vm.fraktion.familienName"
                       ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                       required
                       tooltip-placement="top-left"
                       type="text"
                       uib-tooltip="{{'COMMON.NAME' | translate}}">
            </div>
            <div class="col-md-6">
                <input autocomplete="given-name"
                       class="form-control"
                       maxlength="255"
                       ng-model="vm.fraktion.vorName"
                       ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                       required
                       tooltip-placement="top-left"
                       type="text"
                       uib-tooltip="{{'COMMON.VORNAME' | translate}}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.fraktion.geburtsTag"
                                           ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-md-6">
                <dvb-iso-locale ng-model="vm.fraktion.nationalitaet"
                                ng-attr-placeholder="{{'COMMON.NATIONALITAET' | translate}}"
                                type="COUNTRY">
                </dvb-iso-locale>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            <textarea class="form-control form-section-minus-normal-abstand"
                      maxlength="5000"
                      ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                      ng-model="vm.fraktion.bemerkung"
                      rows="5"
                      tooltip-placement="top-left"
                      uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"></textarea>
            </div>
        </div>
    </div>

    <div class="form-section">
        <h2 data-translate="COMMON.KONTAKT"></h2>

        <dvb-adresse-form ng-model="vm.limitedAdresse.adresse"></dvb-adresse-form>

        <div class="row">
            <div class="col-md-6">
                <input autocomplete="tel"
                       class="form-control"
                       maxlength="100"
                       ng-model="vm.fraktion.telefon"
                       ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                       tooltip-placement="top-left"
                       type="text"
                       uib-tooltip="{{'COMMON.TELEFON' | translate}}">
            </div>
            <div class="col-md-6">
                <input autocomplete="tel"
                       class="form-control"
                       maxlength="100"
                       ng-model="vm.fraktion.mobile"
                       ng-attr-placeholder="{{'COMMON.MOBILE_NR' | translate}}"
                       tooltip-placement="top-left"
                       type="text"
                       uib-tooltip="{{'COMMON.MOBILE_NR' | translate}}">
            </div>
            <div class="col-md-6">
                <input autocomplete="tel"
                       class="form-control"
                       maxlength="100"
                       ng-model="vm.fraktion.geschaeft"
                       ng-attr-placeholder="{{'COMMON.GESCHAEFT_NR' | translate}}"
                       tooltip-placement="top-left"
                       type="text"
                       uib-tooltip="{{'COMMON.GESCHAEFT_NR' | translate}}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <input autocomplete="email"
                       class="form-control"
                       maxlength="255"
                       name="kontaktEmail"
                       ng-model="vm.fraktion.email"
                       ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                       tooltip-placement="top-left"
                       type="email"
                       uib-tooltip="{{'COMMON.E-MAIL' | translate}}">
            </div>
        </div>
        <div class="row form-row">
            <div ng-class="{'col-md-6': vm.form.$submitted, 'col-md-12': !vm.form.$submitted}">
                <div class="radio" ng-repeat="geschlecht in vm.geschlechte">
                    <label>
                        <input ng-model="vm.fraktion.geschlecht"
                               required
                               type="radio"
                               value="{{geschlecht}}">
                        <span data-translate="COMMON.{{geschlecht}}"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-6" ng-show="vm.form.$submitted && !vm.fraktion.geschlecht">
                <p class="error-message"
                   data-translate="ERRORS.ERR_REQUIRED_GESCHLECHT"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dvb-iso-locale ng-model="vm.fraktion.language"
                                ng-attr-placeholder="{{'COMMON.KORRESPONDENZSPRACHE' | translate}}"
                                required
                                type="LANGUAGE">
                </dvb-iso-locale>
            </div>
        </div>
    </div>
</div>
