/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestInclude, RestPaginated} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {AbstractPagesController} from '@dv/kitadmin/models';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {Benutzer} from '../../../benutzer/model/Benutzer';
import type {AdministrationService} from '../../service/administrationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-active-users.html'),
    controllerAs: 'vm',
};

export class DvbActiveUsers extends AbstractPagesController<Benutzer> implements angular.IController {

    public static override $inject: readonly string[] = ['$q', 'administrationService'];

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly $q: angular.IQService,
        private readonly administrationService: AdministrationService,
    ) {
        super();
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const params: RestPaginated & RestInclude = {
            includes: '(mandant)',
        };
        this.setPagionationValues(params);
        const config = {timeout: this.timeout.promise};

        this.administrationService.getActiveUsers(params, config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }
}

componentConfig.controller = DvbActiveUsers;
angular.module('kitAdmin').component('dvbActiveUsers', componentConfig);
