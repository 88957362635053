<div class="dvb-filter-option">
    <div class="btn label label-radio label-default"
         ng-model="vm.isSelected"
         ng-change="vm.onToggle({isSelected: vm.isSelected})"
         uib-btn-checkbox>

        <span ng-bind="vm.label"></span>

        <ng-transclude></ng-transclude>

        <i ng-if="vm.closeable"
           ng-click="vm.onClose(); $event.stopPropagation()"
           class="fa fa-times small-abstand-left hidden-print">
        </i>
    </div>
</div>
