<div class="dvb-tarif-value-manual-positions-total">
    <div class="radio">
        <label>
            <input type="radio" ng-value="true" ng-model="vm.tarifValue.manualPositionsTotal">
            <span data-translate="TARIF.POSITIONS_TOTAL_MANUAL"></span>
        </label>
    </div>
    <div class="radio">
        <label>
            <input type="radio" ng-value="false" ng-model="vm.tarifValue.manualPositionsTotal">
            <span data-translate="TARIF.POSITIONS_TOTAL_AUTO"></span>
        </label>
    </div>
</div>
