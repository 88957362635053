<div class="dvb-betreuungsperson-kontakt">
    <h2 data-translate="BETREUUNGS_PERSON.WOHNADRESSE"></h2>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <dvb-adresse-verlauf limited-adressen="vm.betreuungsPerson.adressen"
                                 on-update-limited-adresse="vm.updateLimitedAdresse(limitedAdresse, adresse)"
                                 on-delete-limited-adresse="vm.deleteLimitedAdresse(limitedAdresse)">
            </dvb-adresse-verlauf>

            <div class="main-actions hidden-print" dvb-auth require-permission="kita:manage:general">
                <ul class="list-inline" ng-hide="vm.showUmzug">
                    <li>
                        <button type="button"
                                class="btn btn-primary"
                                ng-click="vm.showUmzug = true">
                            <span data-translate="COMMON.UMZUG_ERFASSEN"></span>
                        </button>
                    </li>
                </ul>
            </div>

            <div class="big-abstand-top" ng-if="vm.showUmzug">
                <dvb-betreuungsperson-umzug on-save="vm.createAdresse(adresse)"
                                            on-cancel="vm.showUmzug = false">
                </dvb-betreuungsperson-umzug>
            </div>
        </div>
    </div>
</div>
