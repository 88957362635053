/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';
import type {Filter} from '../../../filter/shared/Filter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        defaultFilter: '<',
        toggleFilter: '<',
        isFilterSet: '&',
        onReset: '&',
    },
    template: require('./dvb-filter-main.html'),
    controllerAs: 'vm',
};

export class DvbFilterMain {
    public static $inject: readonly string[] = [];

    public defaultFilter!: Filter[];
    public toggleFilter!: Filter[];
    public isFilterSet!: FunctionType;
    public onReset!: FunctionType;

    public filterCollapsed: boolean = true;

    public isCollapsible(): boolean {
        return this.toggleFilter.some(filter => filter.nonStickyOptions);
    }

    public reset(): void {
        this.defaultFilter.forEach(f => f.reset());
        this.toggleFilter.forEach(f => f.reset());
        this.onReset();
    }

}

componentConfig.controller = DvbFilterMain;
angular.module('kitAdmin').component('dvbFilterMain', componentConfig);
