<div class="dv-angestellte-stundenerfassung-summary-entry">

    <h2 class="normal-abstand-top">
        <span class="small-abstand-left">{{ kind | displayName }}</span>
    </h2>

    <div class="table-responsive">
        <table class="table text-nowrap text-right">
            <thead>
            <tr>
                <th [translate]="'COMMON.DATUM'"></th>
                <th [translate]="'PERSONAL.STUNDENERFASSUNG.STUNDEN'"></th>
                @for (param of tarifParams; track trackById($index, param)) {
                    <th>{{ param.name }}</th>
                }
            </tr>
            </thead>
            <tbody>
                @for (entry of cleanedBetreuungen; track trackByIdentity($index, entry)) {
                    <tr>
                        <td>{{ entry.stichtag.format('D.M.YYYY') }}</td>
                        <td>{{ entry.stunden.getValue() }}</td>
                        @for (param of tarifParams; track trackById($index, param)) {
                            <td
                                ng-init="entryParam = entry.spesenByTarifParamId[param.id]">
                                @if (entry.spesenByTarifParamId[param.id]) {
                                    <span>
                                        {{ entry.spesenByTarifParamId[param.id].getDisplayValue() }}
                                    </span>
                                }
                            </td>
                        }
                    </tr>
                }
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td>{{ hourSum }}</td>
                @for (param of tarifParams; track trackById($index, param)) {
                    <td>{{ paramSums[param.id] || 0 }}</td>
                }
            </tr>
            </tfoot>
        </table>
    </div>

    @for (entry of betreuungWithBemerkung; track trackById($index, entry)) {
        <div class="row bemerkung">
            <div class="col-md-2">
                <p class="smaller-abstand-left">{{ entry.stichtag.format('D.M.YYYY') }}</p>
            </div>
            <div class="col-md-10">
                @if (entry.hasBemerkungOriginal()) {
                    <p
                        [tooltip]="'PERSONAL.STUNDENERFASSUNG.ERFASSUNGS_BEMERKUNG' | translate">
                        <i class="fa fa-comments-o"></i>
                        <span class="smaller-abstand-left">{{ entry.bemerkung.original }}</span>
                    </p>
                }

                @if (entry.hasBemerkungCurrent()) {
                    <p
                        [tooltip]="'PERSONAL.STUNDENERFASSUNG.KORREKTUR_BEMERKUNG' | translate">
                        <i class="fa fa-comments-o fa-flip-horizontal"></i>
                        <span class="smaller-abstand-left">{{ entry.bemerkung.current }}</span>
                    </p>
                }
            </div>
        </div>
    }

    @if (vertraglicheStunden) {
        <p class="vertragliche-stunden small-abstand-top smaller-abstand-left">
            <span [translate]="'PERSONAL.STUNDENERFASSUNG.VERTRAGLICHE_STUNDEN'"
                  [translateParams]="{stunden: vertraglicheStunden}">
            </span>
            @if (hourSum < vertraglicheStunden) {
                <i class="fa fa-exclamation-triangle smaller-abstand-left"
                   [tooltip]="'PERSONAL.STUNDENERFASSUNG.VERTRAGLICHE_STUNDEN_MISMATCH' | translate">
                </i>
            }
        </p>
    }
</div>
