<div class="dvb-kinder-ort-external-anmeldungen row">

    <div class="row">
        <div class="col-md-7">
            <h2 class="inline-block" data-translate="KINDERORT.NAV_EXTERNAL_ANMELDUNGEN"></h2>
            <div class="filter-list filter-bar">
                <div class="filter">
                    <ul class="filter-option-list">
                        <li>
                            <button class="btn label label-default label-radio"
                                    type="button"
                                    ng-model="vm.filter.showAssigned"
                                    ng-click="vm.reloadItems()"
                                    uib-btn-checkbox
                                    data-translate="EXTERNAL_ANMELDUNG.ASSIGNED">
                            </button>
                        </li>
                        <li>
                            <button class="btn label label-default label-radio"
                                    type="button"
                                    ng-model="vm.filter.showUnassigned"
                                    ng-click="vm.reloadItems()"
                                    uib-btn-checkbox
                                    data-translate="EXTERNAL_ANMELDUNG.UNASSIGNED">
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="filter">
                    <div data-translate="EXTERNAL_ANMELDUNG.ANMELDE_DATUM" class="filter-label"></div>
                    <ul class="filter-option-list">
                        <li>
                            <dvb-filter-option-popover filter-model="vm.filter.erfassungsDatumFilter"
                                                       closeable="false"
                                                       on-toggle="vm.reloadItems()"
                                                       on-close-popover="vm.applyAnmeldeFilter()">
                            </dvb-filter-option-popover>
                        </li>

                    </ul>
                </div>

                <div class="filter">
                    <div data-translate="EXTERNAL_ANMELDUNG.BETREUUNG_AB" class="filter-label"></div>
                    <ul class="filter-option-list">
                        <li>
                            <dvb-filter-option-popover filter-model="vm.filter.eintrittsDatumFilter"
                                                       closeable="false"
                                                       on-toggle="vm.reloadItems()"
                                                       on-close-popover="vm.applyBetreuungFilter()">
                            </dvb-filter-option-popover>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="big-abstand-top">
                <dvb-pages items="vm.pageContainer.items"
                           total-items="vm.pageContainer.count"
                           is-loading="vm.isLoading"
                           items-per-page="vm.itemsPerPage"
                           current-page="vm.currentPage"
                           entity-name="'COMMON.EXTERNAL_ANMELDUNG.MF'"
                           on-update-page="vm.updatePage(page)"
                           on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                    <page-items dvb-accordions>
                        <dvb-accordion ng-repeat="anmeldung in vm.pageContainer.items"
                                       is-first="$first"
                                       show-content-disabled="false">

                            <panel-header class="full-width-block">
                                <div class="row">
                                    <div class="col-md-2">
                                        <strong ng-bind="anmeldung.getDisplayName()"></strong>
                                    </div>
                                    <div class="col-md-4">
                                <span data-translate="EXTERNAL_ANMELDUNG.BETREUUNG_AB"
                                      data-translate-value-date="{{anmeldung.gewuenschteBetreuungAb | amDateFormat:'D.M.YYYY'}}">
                                </span>
                                        <span data-translate="EXTERNAL_ANMELDUNG.ANMELDE_DATUM"
                                              data-translate-value-date="{{anmeldung.anmeldeDatum | amDateFormat:'D.M.YYYY'}}">
                                </span>
                                    </div>
                                    <div class="col-md-4 truncate">
                                <span ng-repeat="kinderOrt in anmeldung.kinderOrte"
                                      ng-bind="kinderOrt.getDisplayName() + ($last ? '' : ', ')"></span>
                                    </div>
                                    <div class="col-md-2" ng-if="anmeldung.assignedKind">
                                        <a ui-sref="base.kind.profil({kindId:anmeldung.assignedKindId})">
                                            <span ng-bind="anmeldung.assignedKind.getDisplayName()"></span>
                                        </a>
                                    </div>
                                </div>
                            </panel-header>
                            <panel-content>
                                <div class="row"
                                     ng-repeat="kontaktperson in anmeldung.externalKontaktpersonen | filter: {hauptkontakt: true}">
                                    <div class="col-md-6">
                                        <p ng-bind="kontaktperson.getDisplayName()">
                                        </p>

                                        <p ng-if="kontaktperson.organisation" ng-bind="kontaktperson.organisation"></p>

                                        <p ng-if="kontaktperson.telefon"
                                           data-translate="COMMON.TELEFON_MIT_NUM"
                                           data-translate-value-num="{{kontaktperson.telefon}}"></p>

                                        <p ng-if="kontaktperson.mobile"
                                           data-translate="COMMON.MOBILE_MIT_NUM"
                                           data-translate-value-num="{{kontaktperson.mobile}}"></p>

                                        <p ng-if="kontaktperson.geschaeft"
                                           data-translate="COMMON.GESCHAEFT_MIT_NUM"
                                           data-translate-value-num="{{kontaktperson.geschaeft}}"></p>

                                        <p ng-if="kontaktperson.email">
                                            <a ng-href="{{'mailto:' + kontaktperson.email}}"
                                               ng-bind="kontaktperson.email"></a>
                                        </p>
                                    </div>
                                </div>
                            </panel-content>
                            <panel-footer dvb-auth require-permission="kita:manage:general">
                                <ul class="list-inline small-abstand-top small-abstand-bottom">
                                    <li>
                                        <button type="button" class="btn btn-link" ng-click="vm.uebernehmen(anmeldung)">
                                            <span data-translate="EXTERNAL_ANMELDUNG.DETAIL.NAME"></span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-link" ng-click="vm.loeschen(anmeldung)">
                                            <span data-translate="COMMON.LOESCHEN"></span>
                                        </button>
                                    </li>
                                </ul>
                            </panel-footer>
                        </dvb-accordion>
                    </page-items>
                </dvb-pages>
            </div>
        </div>

        <div class="col-md-4 col-md-offset-1" dvb-auth require-role="ADMIN">
            <dvb-voreinstellungen-external-anmeldung-wochenplan wochenplaene-external-anmeldung="vm.wochenplaeneExternalAnmeldung"
                                                                on-change="vm.saveWochenplaene()">
            </dvb-voreinstellungen-external-anmeldung-wochenplan>
        </div>
    </div>
</div>
