/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KitaBetreuungsfaktorRegel} from '@dv/kitadmin/models';
import {checkPresent, END_OF_TIME, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {
    KitaBetreuungsfaktorRegelService,
} from '../../../../common/service/rest/kinderort/kitaBetreuungsfaktorRegelService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaBetreuungsfaktorRegel: '<',
        onRemove: '&',
    },
    template: require('./dvb-kita-betreuungsfaktor-regel-content.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsfaktorRegelContent implements angular.IController {

    public static $inject: readonly string[] = ['errorService', 'kitaBetreuungsfaktorRegelService'];

    public kitaBetreuungsfaktorRegel!: KitaBetreuungsfaktorRegel;
    public onRemove!: (params: { kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel }) => any;

    public endOfTime: moment.Moment = END_OF_TIME;
    public editMode: boolean = false;
    public isLoading: boolean = false;
    public workingCopyKitaBetreuungsfaktorRegel?: KitaBetreuungsfaktorRegel;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly kitaBetreuungsfaktorRegelService: KitaBetreuungsfaktorRegelService,
    ) {
    }

    public edit(): void {
        this.workingCopyKitaBetreuungsfaktorRegel = angular.copy(this.kitaBetreuungsfaktorRegel);
        this.editMode = true;
    }

    public submit(): void {
        if (angular.equals(this.workingCopyKitaBetreuungsfaktorRegel, this.kitaBetreuungsfaktorRegel)) {
            this.cancel();

            return;
        }

        this.isLoading = true;
        const regel = checkPresent(this.workingCopyKitaBetreuungsfaktorRegel);
        this.kitaBetreuungsfaktorRegelService.createKitaBetreuungsfaktorRegel(regel)
            .then(() => {
                this.editMode = false;
                angular.copy(this.workingCopyKitaBetreuungsfaktorRegel, this.kitaBetreuungsfaktorRegel);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.editMode = false;
    }

    public remove(): void {
        this.errorService.clearAll();
        this.editMode = false;

        if (TypeUtil.isFunction(this.onRemove)) {
            this.onRemove({kitaBetreuungsfaktorRegel: this.kitaBetreuungsfaktorRegel});
        }
    }
}

componentConfig.controller = DvbKitaBetreuungsfaktorRegelContent;
angular.module('kitAdmin').component('dvbKitaBetreuungsfaktorRegelContent', componentConfig);
