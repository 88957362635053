<div class="dvb-kontaktperson-main">
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-male header-icon"
               uib-tooltip="{{'COMMON.KONTAKTPERSON.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>
            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{ vm.kontaktperson.getDisplayName() + '\n' + ('KONTAKTPERSON.KUNDEN_NUMMER' | translate:{nummer: vm.kontaktperson.kundenNummer})}}"
                    tooltip-placement="top-left">
                    <span ng-bind="vm.kontaktperson.getDisplayName()"></span>
                    <span class="header-box-attachment"
                          data-translate="KONTAKTPERSON.KUNDEN_NUMMER"
                          data-translate-value-nummer="{{vm.kontaktperson.kundenNummer}}"></span>
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.kontaktperson.profil" data-translate="COMMON.NAV_PROFIL"></a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.kontaktperson.kontakt" data-translate="COMMON.KONTAKT"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{['module:faktura', 'kita:control:general']}}">
                        <a ui-sref="base.kontaktperson.faktura" data-translate="COMMON.NAV_FAKTURA"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{['module:faktura', 'kita:control:general']}}">
                        <a ui-sref="base.kontaktperson.offenePosten" data-translate="COMMON.NAV_OFFENE_POSTEN"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{['module:kommunikation', 'kita:control:general']}}">
                        <a ui-sref="base.kontaktperson.communication.correspondence">
                            <span data-translate="COMMON.NAV_CORRESPONDENCE"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div ui-view></div>
</div>
