/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import {checkPresent, DvbRestUtil, TimeRange} from '@dv/shared/code';
import type moment from 'moment';

export class ZuweisungZeit extends TimeRange {

    public constructor(
        von: moment.Moment | null,
        bis: moment.Moment | null,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public displayName: string,
    ) {
        super(von, bis);
    }

    public getDisplayName(): string {
        return this.displayName;
    }

    public override toRestObject(): JaxTimeRange {
        return {
            von: checkPresent(DvbRestUtil.momentTolocaleHHMMTime(this.von)),
            bis: checkPresent(DvbRestUtil.momentTolocaleHHMMTime(this.bis)),
        };
    }
}
