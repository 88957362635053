<div class="dvb-subscription-model">
    <div class="radio">
        <label>
            <input type="radio"
                   name="subscriptionModel"
                   ng-disabled="vm.isDisabled"
                   ng-change="vm.onChange()"
                   value="{{vm.subscriptionModels.PREMIUM}}"
                   ng-model="vm.mandant.subscriptionModel"
                   required>
            <span data-translate="MANDANT.SUBSCRIPTION_MODEL.PREMIUM"></span>
            <span class="radio-help-block"
                  data-translate="MANDANT.SUBSCRIPTION_MODEL.PREMIUM_ERKLAERT"></span>
        </label>
    </div>
    <div class="radio">
        <label>
            <input type="radio"
                   name="subscriptionModel"
                   ng-disabled="vm.isDisabled"
                   ng-change="vm.onChange()"
                   value="{{vm.subscriptionModels.LIGHT}}"
                   ng-model="vm.mandant.subscriptionModel"
                   required>
            <span data-translate="MANDANT.SUBSCRIPTION_MODEL.LIGHT"></span>
            <span class="radio-help-block"
                  data-translate="MANDANT.SUBSCRIPTION_MODEL.LIGHT_ERKLAERT"></span>
        </label>
    </div>
    <div class="radio" ng-if="vm.isMandantAdmin || vm.mandant.subscriptionModel === vm.subscriptionModels.FREE">
        <label>
            <input type="radio"
                   name="subscriptionModel"
                   ng-disabled="vm.isDisabled"
                   ng-change="vm.onChange()"
                   value="{{vm.subscriptionModels.FREE}}"
                   ng-model="vm.mandant.subscriptionModel"
                   required>
            <span data-translate="MANDANT.SUBSCRIPTION_MODEL.FREE"></span>
        </label>
    </div>
</div>
