<div class="dvb-maintenance-kontakt-iban-migration">
    <h2 data-translate="ADMINISTRATION.MTNC_MIGRATE_KONTAKT_IBAN"></h2>

    <form class="dvb-form"
          name="ibanMigrationForm"
          ng-submit="vm.migrate(ibanMigrationForm)"
          novalidate>
        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       name="customFieldName"
                       class="form-control"
                       ng-attr-placeholder="{{'ADMINISTRATION.MTNC_MIGRATE_KONTAKT_IBAN_CUSTOM_FIELD' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.MTNC_MIGRATE_KONTAKT_IBAN_CUSTOM_FIELD' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.customFieldName"
                       maxlength="255"
                       required>
            </div>
            <div class="col-md-6">
                <dvb-search-get-single-entity entity-to-search="MANDANT"
                                              ng-model="vm.mandant"
                                              mandant-filter="vm.mandantFilter"
                                              required
                                              as-col="true"
                                              display-label="COMMON.MANDANT.SINGULAR"
                                              placeholder-key="COMMON.MANDANT.SINGULAR">
                </dvb-search-get-single-entity>
            </div>
        </div>

        <dvb-loading-button type="submit" is-loading="vm.isLoading">
            <span data-translate="ADMINISTRATION.MTNC_MIGRATE"></span>
        </dvb-loading-button>
    </form>
</div>
