/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {DvLocalDateFormatPipe, LoadingButtonComponent, trackByIdentity} from '@dv/shared/angular';
import {TerminUpdateMode} from '@dv/shared/backend/model/termin-update-mode';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'dv-termin-update-mode-dialog',
    templateUrl: './termin-update-mode-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        LoadingButtonComponent,
        SubmitCancelButtonsComponent,
        DvLocalDateFormatPipe,
    ],
})
export class TerminUpdateModeDialogComponent {

    @Input({required: true}) public title!: string;
    @Input() public submitLabel?: string;

    @Output() public readonly submitMode: EventEmitter<TerminUpdateMode> = new EventEmitter();
    @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();

    protected readonly trackByIdentity = trackByIdentity;

    public readonly modes: TerminUpdateMode[] = [
        TerminUpdateMode.ALL,
        TerminUpdateMode.ALL_FUTURE,
        TerminUpdateMode.SINGLE,
    ];
    public updateMode: TerminUpdateMode = TerminUpdateMode.SINGLE;

    public submitForm(): void {
        this.submitMode.emit(this.updateMode);
    }
}
