<div class="dvb-custom-field-configuration-selector" ng-if="vm.customFieldConfigurations.length > 0">
    <select class="form-control"
            ng-change="vm.onSelectionChange({customFieldConfiguration: vm.selectedCustomFieldConfiguration})"
            ng-model="vm.selectedCustomFieldConfiguration"
            ng-options="config as config.name for config in vm.customFieldConfigurations | orderBy: 'name'"
            tooltip-placement="top-left"
            uib-tooltip="{{'REPORT.CUSTOM_FIELD_CONFIGURATION.TITLE'| translate}}">
        <option data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.DEFAULT" ng-value="null"></option>
    </select>

</div>