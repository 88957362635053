/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, IPersistable} from '@dv/shared/code';
import {checkPresent, DvbUriUtil} from '@dv/shared/code';

export class FilterOption<T extends string = string> implements IPersistable, IDisplayable {

    /**
     * @param id an ID that references a filter option
     * @param name the name of the filter
     * @param selected when TRUE, this FilterOption indicates an active filter
     * @param sticky when TRUE, this FilterOption is shown at the top-filter-bar (always visible)
     */
    public constructor(
        public id: T | null = null,
        public name?: string,
        public selected: boolean = false,
        public sticky: boolean = false,
    ) {
    }

    public static fromUriParam<T extends string>(params: string): FilterOption<T> {
        const filterOption = new FilterOption<T>();

        DvbUriUtil.applyUriToObject(params, filterOption);

        return filterOption;
    }

    public static isSelected<T extends string>(filterOption: FilterOption<T>): boolean {
        return filterOption.selected;
    }

    public static getSelectedIds<T extends string>(filterOptions: FilterOption<T>[]): T[] {
        return filterOptions.filter(FilterOption.isSelected)
            .map(o => checkPresent(o.id));
    }

    public static unselect<T extends string>(filterOptions: FilterOption<T>[]): void {
        filterOptions.forEach(filterOption => {
            filterOption.selected = false;
        });
    }

    public toUriParam(): string {
        return DvbUriUtil.plainObjectToUriParam(this);
    }

    public getDisplayName(): string {
        return this.name ?? '';
    }
}
