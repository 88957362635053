<ul class="dvb-kibon-exchange-entity-actions list-inline list-inline-row">
    <li ng-if="vm.showAssign">
        <dvb-search-on-click-entity add-label-key="COMMON.KIND.AUSWAEHLEN"
                                    entity-to-search="KIND"
                                    hide-add="true"
                                    on-select="vm.onAssign({item: item})"
                                    on-select-clear="true"
                                    on-toggle="vm.isSearchInputShown = searchShown"
                                    mandant-filter="vm.mandantFilter"
                                    placeholder-key="COMMON.KIND.SINGULAR">
        </dvb-search-on-click-entity>
    </li>

    <li ng-if="!vm.isSearchInputShown" ng-repeat="item in vm.filteredActions">
        <button class="btn btn-link"
                ng-if="item.action.actionType === vm.ACTION_TYPE.BUTTON"
                data-translate="{{item.translationKeys.button}}"
                ng-click="vm.onAction({action: item.action})">
        </button>
        <a ng-if="item.action.actionType === vm.ACTION_TYPE.NAVIGATION"
           ng-href="{{item.href}}"
           data-translate="{{item.translationKeys.button}}">
        </a>
    </li>
</ul>
