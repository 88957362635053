/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';
(window as any).moment = moment;

import 'angular';
import 'angular-moment';
import 'angular-animate';
import 'angular-sanitize';
import 'ng-file-upload/dist/ng-file-upload'; // eslint-disable-line import/no-internal-modules
import 'angular-elastic';
import 'angular-toarrayfilter';
import 'angular-ui-scroll';
import 'angular-ui-tree';

// region Bootstrap
/* eslint-disable import/no-internal-modules */
import 'bootstrap/js/transition.js';
import 'bootstrap/js/alert.js';
import 'bootstrap/js/button.js';
import 'bootstrap/js/collapse.js';
import 'bootstrap/js/dropdown.js';
import 'bootstrap/js/modal.js';
import 'bootstrap/js/tooltip.js';
import 'bootstrap/js/popover.js';
import 'bootstrap/js/tab.js';
/* eslint-enable import/no-internal-modules */
// endregion

// region i18n
import 'angular-cookies';
import 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-handler-log';
import 'angular-i18n/angular-locale_de-ch.js'; // eslint-disable-line import/no-internal-modules
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
// endregion

import 'broadcast-channel';
