/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kind, Kontaktperson} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BringAbholZeitenAccess} from './BringAbholZeitenAccess';

export class KindBringAbholZeitenAccess extends BringAbholZeitenAccess {

    public constructor(
        public kind: Persisted<Kind>,
        public kontaktperson: Persisted<Kontaktperson>,
        public contactUserTimestampMutiert: moment.Moment | null = null,
        readAccessUntil: moment.Moment | null = null,
        writeAccessUntil: moment.Moment | null = null,
    ) {
        super(readAccessUntil, writeAccessUntil);
    }

    public static override apiResponseTransformer(data: any): KindBringAbholZeitenAccess {
        const bringAbholAccess = BringAbholZeitenAccess.apiResponseTransformer(data);
        const kind = Kind.apiResponseTransformer(data.kind);
        const kontaktperson = Kontaktperson.apiResponseTransformer(data.kontaktperson);
        const contactUserTimestampMutiert = DvbRestUtil.localDateTimeToMoment(data.contactUserTimestampMutiert);

        return new KindBringAbholZeitenAccess(
            kind,
            kontaktperson,
            contactUserTimestampMutiert,
            bringAbholAccess.readAccessUntil,
            bringAbholAccess.writeAccessUntil);
    }
}
