<div class="timeline-entry-details">
    <div class="date-time">
        <span>{{ timelineDate }}</span>
        <span class="smaller-abstand-left">{{ timelineTime }}</span>
        <i class="fa fa-ellipsis-h" [tooltip]="tootlipText"></i>
        @if (timelineEntry.blobInfos.length > 0) {
            <dv-attachments-download [timelineEntry]="timelineEntry" [timelineService]="timelineService"/>
        }
    </div>

    <ng-content/>
</div>
