<div class="dvb-tarif-parameter-input">

    <script type="text/ng-template" id="paramTypeBadgeTooltipTemplate.html">
        <ul class="popover-list">
            <li ng-repeat="type in vm.tarifParamTypes" data-translate="{{'TARIF.PARAM_DESCRIPTION.' + type}}"></li>
        </ul>
    </script>

    <div class="col-md-7 entity">
        <input type="text"
               name="parameter[]"
               class="form-control"
               ng-attr-placeholder="{{'TARIF.NAME_PARAMETER' | translate}}"
               uib-tooltip="{{'TARIF.NAME_PARAMETER' | translate}}"
               tooltip-placement="top-left"
               maxlength="255"
               ng-model="vm.tarifParameter.name"
               ng-change="vm.updateParameterSelection()"
               typeahead-editable="true"
               typeahead-select-on-exact="true"
               typeahead-on-select="vm.onSelectExistingParameter($item, $model, $label, $event, parameter)"
               uib-typeahead="param as param.name for param in vm.existingTarifParameters | filter:{name:$viewValue}">
    </div>
    <div class="col-md-3 entity">
        <label>
            <select class="form-control"
                    ng-model="vm.tarifParameter.dtype"
                    ng-change="vm.updateParameterSelection()"
                    ng-options="type as ('TARIF.PARAM_TYPE.' + type | translate) for type in vm.tarifParamTypes"
                    ng-disabled="vm.tarifParameter.id != null"
                    uib-popover-template="'paramTypeBadgeTooltipTemplate.html'"
                    popover-title="{{('TARIF.PARAM_TYP' | translate)}}"
                    popover-trigger="'mouseenter focus'"
                    popover-placement="top"
                    popover-append-to-body="true">
            </select>
        </label>
    </div>
</div>
