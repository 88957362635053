/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ZeitraumFeld} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import angular from 'angular';
import type {KibonExchangeTagesschuleAnmeldung} from '../../../kibon/models/KibonExchangeTagesschuleAnmeldung';
import type {KibonExchangeTagesschulModule} from '../../../kibon/models/KibonExchangeTagesschulModule';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anmeldung: '<',
        zeitraumFelder: '<',
        tagesschulModule: '<',
        weekDays: '<',
    },
    template: require('./dvb-tagesschul-anmeldung-confirmation-overview.html'),
    controllerAs: 'vm',
};

export class DvbTagesschulAnmeldungConfirmationOverview implements angular.IController {
    public static $inject: readonly string[] = [];

    public anmeldung!: KibonExchangeTagesschuleAnmeldung;
    public zeitraumFelder!: ZeitraumFeld[];
    public tagesschulModule!: KibonExchangeTagesschulModule;
    public weekDays!: DayOfWeek[];
}

componentConfig.controller = DvbTagesschulAnmeldungConfirmationOverview;
angular.module('kitAdmin').component('dvbTagesschulAnmeldungConfirmationOverview', componentConfig);
