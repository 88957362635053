<div class="row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2>{{ 'KIND.EINGEWOEHNUNG_POSITION.ERFASSEN' | translate }}</h2>
        <form [formGroup]="form" (ngSubmit)="submitForm()" class="dvb-form">
            <div class="row">
                <div class="col-md-6">
                    <dv-search-entity entityToSearch="KINDERORT"
                                      [placeholder]="'COMMON.KINDERORT.AUSWAEHLEN' | translate"
                                      formControlName="kinderOrt"
                                      class="normal-abstand-bottom">
                    </dv-search-entity>
                </div>
                <div class="col-md-6 text-center">
                    <dvlib-date-switcher class="small-abstand-top"
                                         [date]="form.controls.leistungsMonatAb.defaultValue"
                                         (dateChange)="leistungsMonatDateChanged($event)"
                                         mode="month"
                                         [convertToFirstOfMode]="true">
                    </dvlib-date-switcher>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-md-6">
                    <dvlib-datepicker-textfield name="gueltigAb"
                                                formControlName="gueltigAb"
                                                required
                                                [placeholder]="'KIND.EINGEWOEHNUNG_POSITION.VON' | translate">
                    </dvlib-datepicker-textfield>
                </div>
                <div class="col-md-6">
                    <dvlib-datepicker-textfield name="gueltigBis"
                                                formControlName="gueltigBis"
                                                required
                                                [placeholder]="'KIND.EINGEWOEHNUNG_POSITION.BIS' | translate">
                    </dvlib-datepicker-textfield>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <input type="number"
                           min="1"
                           class="form-control"
                           formControlName="days"
                           [attr.aria-label]="'KIND.EINGEWOEHNUNG_POSITION.ANZAHL_TAGE' | translate"
                           [placeholder]="'KIND.EINGEWOEHNUNG_POSITION.ANZAHL_TAGE' | translate"
                           [tooltip]="'KIND.EINGEWOEHNUNG_POSITION.ANZAHL_TAGE' | translate">
                </div>
                <div class="col-md-6">
                    <input type="number"
                           class="form-control"
                           min="1"
                           formControlName="totalBetrag"
                           [attr.aria-label]="'COMMON.BETRAG_CHF' | translate"
                           [placeholder]="'COMMON.BETRAG_CHF' | translate"
                           [tooltip]="'COMMON.BETRAG_CHF' | translate"
                           required>
                </div>
            </div>
            <dv-submit-cancel-buttons [isLoading]="isLoading()"
                                      (cancel)="handleCancel()"
                                      [submitLabel]="'KIND.EINGEWOEHNUNG_POSITION.ERFASSEN' | translate">
            </dv-submit-cancel-buttons>
        </form>
    </div>
</div>
