/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import {RechnungsStatus} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    require: {dvbDownloadCtrl: '^dvbDownload'},
    transclude: false,
    bindings: {
        rechnungId: '<',
        rechnungen: '<',
    },
    template: require('./dvb-rechnung-stornieren-confirm.html'),
    controllerAs: 'vm',
};

export class DvbRechnungStornierenConfirm {
    public static $inject: readonly string[] = ['$state', 'fakturaService', 'dvbStateService', 'authStore'];

    public rechnungId!: string;
    public rechnungen: Rechnung[] = [];

    public isLoading: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private $state: StateService,
        private fakturaService: FakturaService,
        private dvbStateService: DvbStateService,
        private authStore: AuthStore,
    ) {
    }

    public stornieren(): void {
        this.isLoading = true;

        this.fakturaService.changeRechnungsStatus(this.rechnungId, RechnungsStatus.EXTERNAL_STATUS.STORNIERT)
            .then(() => this.conditionalGemoWinExport())
            .then(() => this.$state.go('base.rechnung.uebersicht', undefined, {reload: true}))
            .catch(() => {
                // nop
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.dvbStateService.goToPreviousState();
    }

    private conditionalGemoWinExport(): angular.IPromise<unknown> {
        const permission = `${PERMISSION.FEATURE.GEMO_WIN_EXPORT_RECHNUNGEN_LANGENTHAL}:${this.rechnungen[0].kitaId}`;

        return this.authStore.hasPermission(permission) ?
            this.gemoWinFileDownload() :
            Promise.resolve();
    }

    private gemoWinFileDownload(): angular.IPromise<unknown> {
        return this.fakturaService.getTempBlobForGemoWinRechnung(this.rechnungId)
            .then(tempBlob => {
                if (tempBlob) {
                    this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
                }
            });
    }
}

componentConfig.controller = DvbRechnungStornierenConfirm;
angular.module('kitAdmin').component('dvbRechnungStornierenConfirm', componentConfig);
