/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UIRouter} from '@uirouter/core';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {VOREINSTELLUNGEN_STATES} from './voreinstellungen-states';

angular.module('kitAdmin').config(voreinstellungenConfig);

export * from './component/dvb-tarif-parameter/dvb-tarif-parameter';
export * from './component/dvb-voreinstellungen-custom-field-form/dvb-voreinstellungen-custom-field-form';
export * from './component/dvb-voreinstellungen-custom-fields/dvb-voreinstellungen-custom-fields';
export * from './component/dvb-voreinstellungen-anwesenheit-custom-fields/dvb-voreinstellungen-anwesenheit-custom-fields';
export * from './component/dvb-voreinstellungen-check-in-custom-fields/dvb-voreinstellungen-check-in-custom-fields';
export * from './component/dvb-voreinstellungen-external-anmeldung/dvb-voreinstellungen-external-anmeldung';
export * from './component/dvb-voreinstellungen-external-anmeldung-custom-fields/dvb-voreinstellungen-external-anmeldung-custom-fields';
export * from './component/dvb-voreinstellungen-external-anmeldung-wochenplan/dvb-voreinstellungen-external-anmeldung-wochenplan';
export * from './component/dvb-voreinstellungen-main/dvb-voreinstellungen-main';
// Add New Component JS Above

voreinstellungenConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function voreinstellungenConfig($stateProvider: StateProvider, $uiRouterProvider: UIRouter): void {

    Object.values(VOREINSTELLUNGEN_STATES).forEach(state => $stateProvider.state(state));

    // Redirect to the default child state
    $uiRouterProvider.urlService.rules.when('/voreinstellungen', '/voreinstellungen/tarife');
}
