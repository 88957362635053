/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KinderOrtFraktion, WochenPlaetze, ZeitraumFeld} from '@dv/kitadmin/models';
import type {BetreuungsZeitraum, DayOfWeek} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ZeitraumFeldClickEventCallback} from '../../directive/dvb-wochenplan/ZeitraumFeldClickEvent';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        name: '@',
        kita: '<',
        gruppe: '<',
        firstOfWeek: '<',
        wochenPlaetze: '<',
        showFreiePlaetze: '<',
        showBelegtePlaetze: '<',
        interactive: '<',
        distinctBetreuungsZeitraeume: '<',
        weekDays: '<',
        zeitraumFelder: '<',
        isFullyCollapsible: '<?',
        collapsedByDefault: '<?',
        onZeitraumFeldClicked: '&',
        onCollapse: '&',
    },
    template: require('./dvb-wochen-belegung.html'),
    controllerAs: 'vm',
};

export class DvbWochenBelegung implements angular.IController {
    public static $inject: readonly string[] = [];

    public name!: string;
    public kita?: KinderOrt;
    public gruppe?: KinderOrtFraktion;
    public firstOfWeek!: moment.Moment;
    public wochenPlaetze!: WochenPlaetze;
    public showFreiePlaetze: boolean = false;
    public showBelegtePlaetze: boolean = false;
    public interactive?: boolean = false;
    public distinctBetreuungsZeitraeume: BetreuungsZeitraum[] = [];
    public weekDays: DayOfWeek[] = [];
    public zeitraumFelder: ZeitraumFeld[] = [];
    public isFullyCollapsible: boolean = false;
    public collapsedByDefault: boolean = true;

    public onZeitraumFeldClicked!: ZeitraumFeldClickEventCallback;
    public onCollapse!: (params: { isCollapsed: boolean }) => any;

    public isCollapsed: boolean = true;
    // True once the collapse animation is done and the content can be removed
    public collapseComplete: boolean = true;

    public showBetreuungsZeitraumName: boolean = false;

    public icon?: string;

    public $onChanges(): void {
        if (this.gruppe) {
            this.showBetreuungsZeitraumName = this.gruppe.wochenplan!.showBetreuungsZeitraumName;
        } else if (this.kita) {
            this.showBetreuungsZeitraumName = !!this.kita.gruppen.some(t => t.wochenplan!.showBetreuungsZeitraumName);
        } else {
            this.showBetreuungsZeitraumName = false;
        }

        this.isCollapsed = this.collapsedByDefault;
        this.collapseComplete = this.collapsedByDefault;

        if (!this.zeitraumFelder) {
            return;
        }

        const uniqueIcons = DvbUtil.uniqueArray(this.zeitraumFelder.filter(zf => zf.selected).map(zf => zf.icon));
        this.icon = uniqueIcons.join(' ');

        if (!this.icon || !this.isFullyCollapsible) {
            return;
        }
        this.isCollapsed = false;
        this.collapseComplete = false;
        this.onCollapse({isCollapsed: this.isCollapsed});
    }

    public toggle(): void {
        this.collapseComplete = false;
        this.isCollapsed = !this.isCollapsed;
    }
}

componentConfig.controller = DvbWochenBelegung;
angular.module('kitAdmin').component('dvbWochenBelegung', componentConfig);
