/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BetreuungsVereinbarungsKonfigurationType} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent, trackById} from '@dv/shared/angular';
import {TranslateModule} from '@ngx-translate/core';
import {DvbI18nPipe} from '../../filter/dvbI18n.pipe';

export interface BetreuungsVereinbarungsTypeSelectDialogModel
    extends CompletableDialogModel<BetreuungsVereinbarungsKonfigurationType> {

    types: BetreuungsVereinbarungsKonfigurationType[];
    selectedType: BetreuungsVereinbarungsKonfigurationType;
}

@Component({
    selector: 'dv-betreuungs-vereinbarungs-type-select',
    templateUrl: './betreuungs-vereinbarungs-type-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslateModule, FormsModule, LoadingButtonComponent, DvbI18nPipe, ButtonListComponent],
})
export class BetreuungsVereinbarungsTypeSelectComponent
    extends CompletableDialogBase<BetreuungsVereinbarungsKonfigurationType>
    implements BetreuungsVereinbarungsTypeSelectDialogModel {

    @Input() public types!: BetreuungsVereinbarungsKonfigurationType[];
    @Input() public selectedType!: BetreuungsVereinbarungsKonfigurationType;

    public trackById = trackById;

    public submitForm(): void {
        this.submit(this.selectedType);
    }
}
