/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FormContext} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import {TAGESSCHULE_ANMELDUNG, ZUWEISEN_MULTIPLE_SIMPLE_STATE} from '../../../kind/zuweisung';
import type {KibonExchangeEntityAction} from '../../models/KibonExchangeEntityAction';
import {KibonExchangeEntityButtonAction, KibonExchangeNavigationAction} from '../../models/KibonExchangeEntityAction';
import type {KibonExchangeTagesschuleAnmeldung} from '../../models/KibonExchangeTagesschuleAnmeldung';
import {TagesschuleAnmeldungState} from '../../models/KibonExchangeTagesschuleAnmeldung';
import {TagesschuleAnmeldungFilter} from '../../models/TagesschuleAnmeldungFilter';
import type {QueryKibonExchangeApiQueryapiParameter} from '../../service/kibonQueryApiService';
import type {TagesschuleAnmeldungService} from '../../service/tagesschuleAnmeldungService';
import type {TranslationKeyMap} from '../dvb-kibon-entity-matching-overview/dvb-kibon-entity-matching-overview';
import type {KibonExchangeActionHandler} from '../dvb-kibon-entity-matching-overview/KibonEntityActionHandler';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-kibon-tagesschule-anmeldungen.html'),
    controllerAs: 'vm',
};

export class DvbKibonTagesschuleAnmeldungen implements angular.IController {

    public static $inject: readonly string[] = [
        'tagesschuleAnmeldungService',
    ];

    public actionHandler: KibonExchangeActionHandler<KibonExchangeTagesschuleAnmeldung, unknown>;
    public filter: TagesschuleAnmeldungFilter = new TagesschuleAnmeldungFilter();
    public states: TagesschuleAnmeldungState[] = Object.values(TagesschuleAnmeldungState);
    public translations: TranslationKeyMap = {
        pageHeader: 'KIBON.TAGESSCHULE_ANMELDUNG.HEADER',
        statePrefix: 'KIBON.TAGESSCHULE_ANMELDUNG.STATE.',
        stateTooltipPrefix: 'KIBON.TAGESSCHULE_ANMELDUNG.STATE_INFO.',
        kibonExportAm: 'KIBON.TAGESSCHULE_ANMELDUNG.ANGEMELDET_AM',
        assignConfirmationHeader: '',
        assignEntityDataHeader: 'KIBON.TAGESSCHULE_ANMELDUNG.ASSIGN_DATEN',
        entityNameMF: 'KIBON.TAGESSCHULE_ANMELDUNG.MF',
        matchingHeader: '',
        matchingText: '',
    };

    public actions: KibonExchangeEntityAction<KibonExchangeTagesschuleAnmeldung>[] = [
        new KibonExchangeNavigationAction(
            TAGESSCHULE_ANMELDUNG.name,
            anmeldung => ({tagesschulAnmeldungId: anmeldung.id}),
            _anmeldung => true,
            anmeldung => ({
                button: anmeldung.assignedKindId ?
                    'KIBON.TAGESSCHULE_ANMELDUNG.PROFILE' :
                    'COMMON.ZUWEISEN',
            }),
        ),
        new KibonExchangeNavigationAction(
            ZUWEISEN_MULTIPLE_SIMPLE_STATE.name,
            anmeldung => ({
                tagesschulAnmeldungId: anmeldung.id,
                tagesschulAnmeldung: anmeldung,
                kindId: anmeldung.assignedKindId,
                kinderOrtIds: [checkPresent(anmeldung.kinderOrtId)],
            }),
            anmeldung => anmeldung.showUebernehmen(),
            _anmeldung => ({button: 'KIBON.TAGESSCHULE_ANMELDUNG.ANMELDEN'}),
        ),
        new KibonExchangeEntityButtonAction(
            anmeldung => this.tagesschuleAnmeldungService.unAssign(anmeldung.id),
            anmeldung => anmeldung.canUnAssign(),
            _anmeldung => ({
                button: 'COMMON.ZUWEISUNG_AUFHEBEN',
                confirmation: 'KIBON.TAGESSCHULE_ANMELDUNG.ZUWEISUNG_AUFHEBEN',
            }),
        ),
        new KibonExchangeEntityButtonAction(
            anmeldung => this.tagesschuleAnmeldungService.ignore(anmeldung.id),
            anmeldung => anmeldung.canIgnore(),
            _anmeldung => ({button: 'COMMON.IGNORIEREN', confirmation: 'KIBON.TAGESSCHULE_ANMELDUNG.IGNORIEREN'}),
        ),
        new KibonExchangeEntityButtonAction(
            anmeldung => this.tagesschuleAnmeldungService.unIgnore(anmeldung.id),
            anmeldung => anmeldung.canUnIgnore(),
            _anmeldung => ({
                button: 'COMMON.UNIGNORIEREN',
                confirmation: 'KIBON.TAGESSCHULE_ANMELDUNG.NICHT_IGNORIEREN',
            }),
        ),
        new KibonExchangeEntityButtonAction(
            anmeldung => this.tagesschuleAnmeldungService.decline(anmeldung.id),
            anmeldung => anmeldung.canDecline(),
            _anmeldung => ({button: 'COMMON.DECLINE', confirmation: 'KIBON.TAGESSCHULE_ANMELDUNG.DECLINE'}),
        ),
    ];

    public constructor(
        private readonly tagesschuleAnmeldungService: TagesschuleAnmeldungService,
    ) {
        this.actionHandler = {
            getFiltered: config => this.tagesschuleAnmeldungService.getFiltered(this.filter, config),
        };
    }

    public queryApi(params: QueryKibonExchangeApiQueryapiParameter, formContext: FormContext): void {
        formContext.startLoading();
        this.tagesschuleAnmeldungService.queryExchangeService(params)
            .finally(() => formContext.finishLoading());
    }
}

componentConfig.controller = DvbKibonTagesschuleAnmeldungen;
angular.module('kitAdmin').component('dvbKibonTagesschuleAnmeldungen', componentConfig);
