<div class="dvb-kontaktperson-offene-posten-liste">
    <dvlib-spinner class="centered-spinner" [show]="vm.isLoading"></dvlib-spinner>

    <div ng-if="!vm.isLoading && vm.offenePosten.length === 0">
        <p data-translate="COMMON.KEINE_OFFENE_POSTEN_VORHANDEN"></p>
    </div>

    <div class="table-header" ng-show="!vm.isLoading && vm.offenePosten.length > 0">
        <div class="row">
            <div class="col-xs-2 col-xs-offset-6 text-right" data-translate="COMMON.SOLL"></div>
            <div class="col-xs-2 text-right" data-translate="COMMON.HABEN"></div>
            <div class="col-xs-2 text-right" data-translate="COMMON.SALDO"></div>
        </div>
    </div>

    <div ng-repeat="offenerPosten in vm.offenePosten" class="offener-posten">
        <div class="collapsible-panel-container"
             ng-click="vm.selectPosten(offenerPosten, $index)"
             ng-class="{'posten-selectable': vm.hasPostenOptions(offenerPosten),
                        'posten-selected': vm.selectedPosten === $index}">

            <div class="col-xs-2">
                <p>
                    <a ui-sref="base.rechnung.uebersicht({rechnungId: offenerPosten.rechnung.id})"
                       ng-if="offenerPosten.dtype === 'RECHNUNGS_REVISION' || offenerPosten.dtype === 'EINZAHLUNG'"
                       data-translate="COMMON.RECHNUNG_NR"
                       data-translate-value-nummer="{{offenerPosten.rechnung.rechnungsNummer}}">
                    </a>
                    <a ui-sref="base.rechnung.revisionen({rechnungId: offenerPosten.rechnung.id, revisionId: offenerPosten.rechnung.rechnungsRevisionId})"
                       ng-if="offenerPosten.rechnung && offenerPosten.dtype === 'GEBUEHR'"
                       data-translate="COMMON.RECHNUNG_NR"
                       data-translate-value-nummer="{{offenerPosten.rechnung.rechnungsNummer}}">
                    </a>
                    <br ng-if="offenerPosten.rechnung.rechnungsNummer">
                    <span ng-bind="offenerPosten.datum | amDateFormat:'D.M.YYYY'"></span>
                </p>
            </div>

            <div class="col-xs-4">
                <p>
                    <strong class="smaller-abstand-right">
                        <dvb-offener-posten-text offener-posten="offenerPosten"></dvb-offener-posten-text>
                    </strong>
                    <dvb-rechnung-heading-payment-due-icon ng-if="offenerPosten.rechnung"
                                                           rechnung="offenerPosten.rechnung">
                    </dvb-rechnung-heading-payment-due-icon>
                    <dvb-offene-posten-bemerkung-icon offener-posten="offenerPosten">
                    </dvb-offene-posten-bemerkung-icon>
                </p>
            </div>
            <div class="col-xs-2 text-right">
                <p ng-bind="offenerPosten.soll | number:'2'"></p>
            </div>
            <div class="col-xs-2 text-right">
                <p ng-bind="offenerPosten.haben | number:'2'"></p>
            </div>
            <div class="col-xs-2 text-right">
                <p>
                    <strong ng-bind="offenerPosten.saldo | number:'2'"></strong>
                </p>
            </div>
        </div>

        <div uib-collapse="vm.selectedPosten !== $index" ng-if="vm.interactive && vm.hasPostenOptions(offenerPosten)">
            <div class="collapsible-panel-container posten-selected">
                <div class="col-xs-12">
                    <ul class="list-inline no-margin">
                        <li ng-if="offenerPosten.isVorauszahlung()">
                            <dvb-loading-button type="button"
                                                as-link="true"
                                                is-loading="vm.isPostenOptionLoading"
                                                on-click="vm.deleteVorauszahlung(offenerPosten.vorauszahlung)">
                                <span data-translate="COMMON.LOESCHEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li ng-if="vm.canPayoutRueckerstattung(offenerPosten)">
                            <a href=""
                               ng-click="vm.rueckerstattungAuszahlen(offenerPosten.rueckerstattung)"
                               data-translate="COMMON.AUSZAHLEN"></a>
                        </li>
                        <li ng-if="offenerPosten.isRueckerstattung()">
                            <dvb-loading-button type="button"
                                                as-link="true"
                                                spinner-right="true"
                                                is-loading="vm.isPostenOptionLoading"
                                                on-click="vm.deleteRueckerstattung(offenerPosten.rueckerstattung)">
                                <span data-translate="{{vm.getRueckerstattungLoeschenText(offenerPosten)}}"></span>
                            </dvb-loading-button>
                        </li>
                        <li ng-if="offenerPosten.isDeletableGebuehr()">
                            <dvb-loading-button type="button"
                                                as-link="true"
                                                is-loading="vm.isPostenOptionLoading"
                                                on-click="vm.deleteGebuehr(offenerPosten.gebuehr)">
                                <span data-translate="COMMON.LOESCHEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li ng-if="offenerPosten.isEditableGebuehr()">
                            <a data-translate="COMMON.BEARBEITEN"
                               ui-sref="base.kontaktperson.gebuehr({editMode: true, gebuehrId: offenerPosten.gebuehr.id, kitaName: offenerPosten.kitaName, rechnungId: offenerPosten.rechnung.id})">
                            </a>
                        </li>
                        <li ng-if="offenerPosten.isRueckerstattbareGebuehr()">
                            <a ui-sref="base.kontaktperson.vorauszahlung({gebuehrId: offenerPosten.gebuehr.id, kitaName: offenerPosten.kitaName})"
                               data-translate="COMMON.GEBUEHR.DEPOT_TO_VORAUSZAHLUNG">
                            </a>
                        </li>
                        <li ng-if="offenerPosten.isRueckerstattbareGebuehr()">
                            <a ui-sref="base.kontaktperson.rueckerstattung({gebuehrId: offenerPosten.gebuehr.id, kitaName: offenerPosten.kitaName})"
                               data-translate="COMMON.GEBUEHR.DEPOT_TO_RUECKERSTATTUNG"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
