/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeEntity} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entity: '<',
    },
    template: require('./dvb-kibon-entity-info.html'),
    controllerAs: 'vm',
};

export class DvbKibonEntityInfo implements angular.IController {
    public static $inject: readonly string[] = [];

    public entity!: KibonExchangeEntity;
}

componentConfig.controller = DvbKibonEntityInfo;
angular.module('kitAdmin').component('dvbKibonEntityInfo', componentConfig);
