/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntitySearchType, IPersistable, SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import type {MandantSearchFilter} from '../../model/MandantSearchFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        entityToSearch: '@',
        placeholderKey: '@',
        placeholderTranslated: '<?',
        displayLabel: '@',
        asCol: '<?',
        selectedEntity: '=ngModel',
        expandEntity: '<?',
        disabledEntries: '<?',
        filterSource: '&?',
        mandantFilter: '<?',
    },
    template: require('./dvb-search-get-single-entity.html'),
    controllerAs: 'vm',
};

export class DvbSearchGetSingleEntity implements angular.IController {

    public static $inject: readonly string[] = ['$attrs', '$element'];

    public entityToSearch!: EntitySearchType;
    public placeholderKey!: string;
    public placeholderTranslated?: boolean;
    public displayLabel!: string;
    public asCol?: boolean;
    public selectedEntity?: SearchResultEntry;
    public expandEntity?: boolean;
    public disabledEntries?: IPersistable[];
    public filterSource?: (props: { $source: any }) => boolean;
    public mandantFilter?: MandantSearchFilter;

    public ngModel!: angular.INgModelController;
    public readOnly: boolean = false;

    public constructor(
        $attrs: angular.IAttributes,
        $element: JQLite,
    ) {
        $attrs.$observe('disabled', value => {
            this.readOnly = !!value;
        });
        $element.addClass('highlight-input-fields-when-invalid');
    }

    public onSelect(item: SearchResultEntry): void {
        this.ngModel.$setViewValue(item);
    }

    public onRemove(): void {
        this.ngModel.$setViewValue(null);
    }
}

componentConfig.controller = DvbSearchGetSingleEntity;
angular.module('kitAdmin').component('dvbSearchGetSingleEntity', componentConfig);
