/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KontaktKorrespondezFilterType} from './KontaktKorrespondenzFilterType';

export class KontaktKorrespondenzFilter {

    public constructor(
        public states: { [index in KontaktKorrespondezFilterType]?: boolean } = {},
    ) {
    }

    public getStateArray(): KontaktKorrespondezFilterType[] {
        const stateArray = Object.keys(KontaktKorrespondezFilterType)
            .filter(key => this.states[key as KontaktKorrespondezFilterType])
            .map(key => key as KontaktKorrespondezFilterType);

        if (stateArray.length === 0) {
            // none selected means the same as everything is selected
            return Object.values(KontaktKorrespondezFilterType);
        }

        return stateArray;
    }
}
