/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';
import type {ILimited, IValidable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';

export class ParameterGeburtstagslisteKinder implements IValidable, ILimited {
    public static $inject: readonly string[] = [];

    public constructor(
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public kinderOrtId: string | null = null,
        public gruppeId: string | null = null,
    ) {
    }

    public isValid(): boolean {
        return !((this.kinderOrtId && this.gruppeId) ?? (!this.kinderOrtId && !this.gruppeId));
    }

    public toRestObject(): Record<string, unknown> {
        const gueltigAb = this.gueltigAb ? this.gueltigAb : DvbDateUtil.today();
        const gueltigBis = this.gueltigBis ?
            this.gueltigBis : moment(gueltigAb).add(1, 'y').add(-1, 'd');

        return {
            gueltigAb: DvbRestUtil.momentToLocalDate(gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(gueltigBis),
            kitaId: this.kinderOrtId,
            gruppeId: this.gruppeId,
        };
    }
}
