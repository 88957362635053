<div class="dvb-betreuungs-schluessel-assignment-form">

    <div ng-if="!vm.disableGueltigkeit" class="row">
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.entity.gueltigAb"
                                       required
                                       ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
            </dvb-datepicker-text-field>
        </div>
    </div>

    <div class="row">
        <div ng-if="vm.betreuungsSchluessel.length === 0" class="col-md-12">
            <p data-translate="FRAKTION.BETREUUNGS_SCHLUESSEL.NONE_FOR_FRAKTION"></p>
        </div>
        <div ng-if="vm.betreuungsSchluessel.length > 0" class="col-md-6">
            <select class="form-control"
                    uib-tooltip="{{'FRAKTION.BETREUUNGS_SCHLUESSEL.AUSWAEHLEN' | translate}}"
                    ng-model="vm.entity.betreuungsSchluessel"
                    ng-options="schluessel as schluessel.name for schluessel in vm.betreuungsSchluessel track by schluessel.id"
                    required>
                <option value=""
                        class="hide"
                        disabled
                        selected
                        data-translate="FRAKTION.BETREUUNGS_SCHLUESSEL.AUSWAEHLEN">
                </option>
            </select>
        </div>
    </div>

</div>
