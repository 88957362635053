/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SmallInvoiceKitaKonfiguration} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '<',
    },
    template: require('./dvb-kita-faktura-small-invoice-content.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaSmallInvoiceContent {
    public static $inject: readonly string[] = [];

    public konfiguration!: SmallInvoiceKitaKonfiguration;
}

componentConfig.controller = DvbKitaFakturaSmallInvoiceContent;
angular.module('kitAdmin').component('dvbKitaFakturaSmallInvoiceContent', componentConfig);
