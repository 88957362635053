<div class="dvb-dokumentlayout-form">
    <div class="row">
        <div class="col-md-12">
            <input type="text"
                   name="name"
                   class="form-control"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.dokumentLayout.name">
        </div>
    </div>

    <div class="radio">
        <label>
            <input type="radio" ng-value="false" ng-model="vm.dokumentLayout.isKompakt" ng-change="vm.updateModus()">
            <span data-translate="DOKUMENT_LAYOUT.MODE_NORMAL"></span>
        </label>
    </div>
    <div class="radio">
        <label>
            <input type="radio" ng-value="true" ng-model="vm.dokumentLayout.isKompakt" ng-change="vm.updateModus()">
            <span data-translate="DOKUMENT_LAYOUT.MODE_KOMPAKT"></span>
        </label>
    </div>
    <h3 class="normal-abstand-top">
        <label>
            <input type="checkbox" ng-change="vm.updatePageMargin()" ng-model="vm.hasPageMargin">
            <span data-translate="FAKTURA.SEITENRAENDER"></span>
        </label>
    </h3>

    <div ng-if="vm.hasPageMargin">
        <div class="row small-abstand-top">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.LEFT})}}"
                       uib-tooltip="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.LEFT})}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.pageMargin.left"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}">
            </div>
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.TOP})}}"
                       uib-tooltip="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.TOP})}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.pageMargin.top"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{('FAKTURA.ABSTAND_RECHTS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.RIGHT})}}"
                       uib-tooltip="{{('FAKTURA.ABSTAND_RECHTS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.RIGHT})}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.pageMargin.right"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}">
            </div>
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{('FAKTURA.ABSTAND_UNTEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.BOTTOM})}}"
                       uib-tooltip="{{('FAKTURA.ABSTAND_UNTEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.PAGE_MARGIN.BOTTOM})}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.pageMargin.bottom"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}">
            </div>
        </div>
    </div>

    <h3 class="normal-abstand-top">
        <label>
            <input type="checkbox" ng-change="vm.updateLogo()" ng-model="vm.hasLogo">
            <span data-translate="FAKTURA.LOGO"></span>
        </label>
    </h3>

    <div ng-if="vm.hasLogo" class="normal-abstand-top">
        <div class="normal-abstand-bottom">
            <dvb-image-upload ng-model="vm.dokumentLayout.logo.image">
            </dvb-image-upload>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{'FAKTURA.BREITE' | translate}}"
                       uib-tooltip="{{'FAKTURA.BREITE' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.logo.width"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}"
                       required>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{'FAKTURA.ABSTAND_LINKS_MM' | translate}}"
                       uib-tooltip="{{'FAKTURA.ABSTAND_LINKS_MM' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.logo.left"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}"
                       required>
            </div>
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{'FAKTURA.ABSTAND_OBEN_MM' | translate}}"
                       uib-tooltip="{{'FAKTURA.ABSTAND_OBEN_MM' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.dokumentLayout.logo.top"
                       step="1"
                       min="0"
                       max="{{vm.maxMM}}"
                       required>
            </div>
        </div>
    </div>

    <h3 class="normal-abstand-top">
        <label>
            <input type="checkbox" ng-change="vm.updateReceiverAddress()" ng-model="vm.hasReceiverAddress">
            <span data-translate="FAKTURA.EMPFAENGERADRESSE"></span>
        </label>
    </h3>

    <div ng-if="vm.hasReceiverAddress" class="row small-abstand-top">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   dvb-integer
                   ng-attr-placeholder="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.RECEIVER_ADDRESS.LEFT})}}"
                   uib-tooltip="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.RECEIVER_ADDRESS.LEFT})}}"
                   tooltip-placement="top-left"
                   ng-model="vm.dokumentLayout.receiverAddress.left"
                   step="1"
                   min="0"
                   max="{{vm.maxMM}}">
        </div>
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   dvb-integer
                   ng-attr-placeholder="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.RECEIVER_ADDRESS.TOP})}}"
                   uib-tooltip="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.RECEIVER_ADDRESS.TOP})}}"
                   tooltip-placement="top-left"
                   ng-model="vm.dokumentLayout.receiverAddress.top"
                   step="1"
                   min="0"
                   max="{{vm.maxMM}}">
        </div>
    </div>

    <h3 class="normal-abstand-top">
        <label>
            <input type="checkbox" ng-change="vm.updateSenderAddress()" ng-model="vm.hasSenderAddress">
            <span data-translate="COMMUNICATION.EMAIL.ABSENDER_ADRESSE"></span>
        </label>
    </h3>

    <div ng-if="vm.hasSenderAddress" class="row small-abstand-top">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   dvb-integer
                   ng-attr-placeholder="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.SENDER_ADDRESS.LEFT})}}"
                   uib-tooltip="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.SENDER_ADDRESS.LEFT})}}"
                   tooltip-placement="top-left"
                   ng-model="vm.dokumentLayout.senderAddress.left"
                   step="1"
                   min="0"
                   max="{{vm.maxMM}}">
        </div>
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   dvb-integer
                   ng-attr-placeholder="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.SENDER_ADDRESS.TOP})}}"
                   uib-tooltip="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.SENDER_ADDRESS.TOP})}}"
                   tooltip-placement="top-left"
                   ng-model="vm.dokumentLayout.senderAddress.top"
                   step="1"
                   min="0"
                   max="{{vm.maxMM}}">
        </div>
    </div>

    <h3 class="normal-abstand-top" data-translate="KINDERORT.MASSENVERSAND"></h3>
    <div ng-if="!vm.hasMassenversand" class="row small-abstand-top">
        <div class="col-xs-12">
            <button type="button"
                    class="btn btn-link"
                    ng-click="vm.hasMassenversand = true"
                    data-translate="KINDERORT.PP_FRANKIERUNG_HINZUFUEGEN">
            </button>
        </div>
    </div>
    <div ng-if="vm.hasMassenversand" class="row small-abstand-top">
        <div class="col-md-6">
            <input type="text"
                   ng-model="vm.dokumentLayout.massenversandText"
                   ng-attr-placeholder="{{'KINDERORT.TEXT_MASSENVERSAND' | translate}}"
                   uib-tooltip="{{'KINDERORT.TEXT_MASSENVERSAND' | translate}}"
                   tooltip-placement="top-left"
                   class="form-control"
                   ng-required="vm.hasMassenversand"
                   maxlength="255">
        </div>
        <div class="col-md-6">
            <p class="form-linker">
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.deleteMassenversand()"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </p>
        </div>
    </div>

    <h3 class="normal-abstand-top">
        <label>
            <input type="checkbox" ng-change="vm.updateFooter()" ng-model="vm.hasFooter">
            <span data-translate="DOKUMENT_LAYOUT.FUSSZEILE"></span>
        </label>
    </h3>

    <div ng-if="vm.hasFooter" class="row small-abstand-top">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   dvb-integer
                   ng-attr-placeholder="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.FOOTER.LEFT})}}"
                   uib-tooltip="{{('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.FOOTER.LEFT})}}"
                   tooltip-placement="top-left"
                   ng-model="vm.dokumentLayout.footer.left"
                   step="1"
                   min="0"
                   max="{{vm.maxMM}}">
        </div>
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   dvb-integer
                   ng-attr-placeholder="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.FOOTER.TOP})}}"
                   uib-tooltip="{{('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.defaults.FOOTER.TOP})}}"
                   tooltip-placement="top-left"
                   ng-model="vm.dokumentLayout.footer.top"
                   step="1"
                   min="0"
                   max="{{vm.maxMM}}">
        </div>
    </div>

    <h3 class="normal-abstand-top"
        data-translate="COMMON.DOKUMENT_LAYOUT.STEUERBESCHEINIGUNG_ALTERNATIV">
    </h3>
    <dvb-translatable>
        <input type="text"
               class="form-control"
               ng-model="vm.dokumentLayout.steuerbescheinigungAlternativ.texts[$dvbLang]"
               dvb-translatable-input
               placeholder="{{'LANGUAGE.NO_TEXT_DEFINED' | translate}}"
               uib-tooltip="{{$dvbLangTranslated}}"
               tooltip-placement="top-left"
               maxlength="255">
    </dvb-translatable>

    <h3 class="normal-abstand-top"
        data-translate="COMMON.DOKUMENT_LAYOUT.STEUERBESCHEINIGUNG_BEMERKUNG">
    </h3>
    <dvb-translatable>
        <textarea ng-model="vm.dokumentLayout.steuerbescheinigungBemerkung.texts[$dvbLang]"
                  dvb-translatable-input
                  class="form-control"
                  msd-elastic
                  ng-attr-placeholder="{{'LANGUAGE.NO_TEXT_DEFINED' | translate}}"
                  uib-tooltip="{{$dvbLangTranslated}}"
                  tooltip-placement="top-left">
        </textarea>
    </dvb-translatable>

    <p class="normal-abstand-top">
        <dvb-loading-button type="button" as-link="true" is-loading="vm.isLoading" on-click="vm.downloadPreview()">
            <span data-translate="FAKTURA.VORSCHAU_ANZEIGEN"></span>
        </dvb-loading-button>
    </p>
</div>
