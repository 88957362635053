<div class="dvb-betreuungsfaktor-altersbedingt row">
    <div class="col-xs-6">
        <input type="number"
               name="vonMonate"
               class="form-control"
               dvb-integer
               min="1"
               step="1"
               ng-attr-placeholder="{{'BETREUUNGSFAKTORREGEL.VON_MONATEN_LABEL' | translate}}"
               uib-tooltip="{{'BETREUUNGSFAKTORREGEL.VON_MONATEN_LABEL' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.altersBedingterBetreuungsfaktor.vonMonate"
               ng-if="vm.altersBedingterBetreuungsfaktor.vonMonate != 0"
               required>
        <input type="text"
               name="vonMonate"
               class="form-control"
               ng-attr-placeholder="{{'BETREUUNGSFAKTORREGEL.AB_GEBURT' | translate}}"
               uib-tooltip="{{'BETREUUNGSFAKTORREGEL.AB_GEBURT' | translate}}"
               tooltip-placement="top-left"
               ng-if="vm.altersBedingterBetreuungsfaktor.vonMonate == 0"
               disabled>
    </div>
    <div class="col-xs-6">
        <input type="number"
               name="betreuungsfaktor"
               class="form-control negativ-abstand-top"
               min="0.01"
               step=".01"
               ng-attr-placeholder="{{'BETREUUNGSFAKTORREGEL.FAKTOR' | translate}}"
               uib-tooltip="{{'BETREUUNGSFAKTORREGEL.FAKTOR' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.altersBedingterBetreuungsfaktor.betreuungsfaktor"
               ng-pattern="/^\d*(\.\d{0,2})?$/"
               required>
    </div>

</div>
