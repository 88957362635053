/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ImageBlob} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        imageBlob: '<',
    },
    template: require('./dvb-image-blob-preview.html'),
    controllerAs: 'vm',
};

export class DvbImagePreview implements angular.IController {
    public static $inject: readonly string[] = [];

    public imageBlob!: ImageBlob;

    public uri: string | null = null;

    private static getDataUri(imageBlob: ImageBlob): string | null {
        if (!imageBlob?.base64) {
            return null;
        }

        return `data:${imageBlob.mimeType};base64,${imageBlob.base64}`;
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.imageBlob) {
            this.uri = DvbImagePreview.getDataUri(changes.imageBlob.currentValue);
        }
    }
}

componentConfig.controller = DvbImagePreview;
angular.module('kitAdmin').component('dvbImageBlobPreview', componentConfig);
