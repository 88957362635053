<div class="dvb-tagesschul-anmeldung-confirmation">

    <h2 ng-if="!vm.confirmed" data-translate="KIBON.TAGESSCHULE_ANMELDUNG.KIBON_ANMELDUNG"></h2>
    <h2 ng-if="vm.confirmed" data-translate="KIBON.TAGESSCHULE_ANMELDUNG.CONFIRMED_KIBON_ANMELDUNG"></h2>
    <form ng-submit="vm.confirmAnmeldung(anmeldungConfirmationForm)"
          name="anmeldungConfirmationForm"
          class="dvb-form"
          novalidate>

        <dvb-tagesschul-anmeldung-confirmation-overview anmeldung="vm.confirmed ? vm.workingCopy : vm.anmeldung"
                                                        zeitraum-felder="vm.confirmed ? vm.editedZeitraumFelder : vm.zeitraumFelder"
                                                        tagesschul-module="vm.tagesschulModule"
                                                        week-days="vm.weekDays">
        </dvb-tagesschul-anmeldung-confirmation-overview>

        <div class="row normal-abstand-top" ng-if="vm.editing && !vm.confirmed">
            <div class="col-md-12 normal-abstand-bottom">
                <h3 data-translate="KIBON.TAGESSCHULE_ANMELDUNG.MODIFY"></h3>
            </div>
            <div class="col-md-6">
                <dvb-datepicker-text-field name="eintrittsdatum"
                                           ng-model="vm.workingCopy.eintrittsdatum"
                                           required
                                           autofocus
                                           ng-attr-placeholder="{{'KIBON.TAGESSCHULE_ANMELDUNG.EINTRITTSDATUM' | translate}}">
                </dvb-datepicker-text-field>

                <input type="text"
                       class="form-control no-margin"
                       uib-tooltip="{{'KIBON.TAGESSCHULE_ANMELDUNG.PLAN_KLASSE' | translate}}"
                       tooltip-placement="top-left"
                       placeholder="{{'KIBON.TAGESSCHULE_ANMELDUNG.PLAN_KLASSE' | translate}}"
                       ng-model="vm.workingCopy.planKlasse">
            </div>
            <div class="col-md-6">
                <dvb-wochenplan class="small"
                                distinct-betreuungs-zeitraeume="vm.tagesschulModule.module"
                                week-days="vm.weekDays"
                                show-betreuungs-zeitraum-name="true"
                                interactive="true"
                                show-null-value-as-plain="true"
                                zeitraum-felder="vm.editedZeitraumFelder"
                                on-zeitraum-feld-clicked="vm.onFeldClicked(zeitraumFeld, event, modifier)">
                </dvb-wochenplan>
            </div>
            <div class="col-md-12">
                <div class="checkbox">
                    <label class="normal-abstand-top">
                        <input type="checkbox" ng-model="vm.workingCopy.abweichungZweitesSemester">
                        <span data-translate="KIBON.TAGESSCHULE_ANMELDUNG.ABWEICHUNG_SEMESTER_2"></span>
                    </label>
                </div>

                <textarea ng-model="vm.workingCopy.bemerkung"
                          ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                          uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                          tooltip-placement="top-left"
                          class="form-control normal-abstand-top"
                          msd-elastic
                          maxlength="1000"
                          rows="1">
                </textarea>
            </div>
        </div>

        <ul ng-if="!vm.confirmed && !vm.readOnly" class="normal-abstand-top list-inline">
            <li>
                <dvb-loading-button type="submit" is-loading="vm.isLoading">
                    <span data-translate="KIBON.TAGESSCHULE_ANMELDUNG.BESTAETIGEN"></span>
                </dvb-loading-button>
            </li>
            <li ng-if="!vm.editing">
                <button type="button" class="btn btn-link" ng-click="vm.editing = true">
                    <span data-translate="COMMON.BEARBEITEN"></span>
                </button>
            </li>
            <li ng-if="vm.editing">
                <button type="button" class="btn btn-link" ng-click="vm.editing = false">
                    <span data-translate="COMMON.ABBRECHEN"></span>
                </button>
            </li>
        </ul>
    </form>

    <div id="confirmationPopover" class="dvb-zuweisen-popover popover popover-zuweisung">
        <div class="arrow"></div>
        <div class="popover-content">
            <ul class="list-unstyled text-left no-margin">
                <li>
                    <a href=""
                       ng-click="vm.setKontingent(vm.weeklyKontingent)"
                       ng-bind="vm.weeklyKontingent.getDisplayName()">
                    </a>
                </li>
                <li>
                    <a href=""
                       ng-click="vm.setKontingent(vm.alternatingKontingent)"
                       ng-bind="vm.alternatingKontingent.getDisplayName()">
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>
