/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AppConfig, AppConfigEntry} from '@dv/kitadmin/models';
import {AppConfigKey} from '@dv/shared/backend/model/app-config-key';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {AppConfigService} from '../../../common/service/rest/appConfigService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-config.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceConfig implements angular.IController {

    public static $inject: readonly string[] = ['appConfigService'];

    public isLoading: boolean = false;
    public entries?: AppConfigEntry[];

    private backup?: AppConfigEntry[];

    public constructor(
        private readonly appConfigService: AppConfigService,
    ) {
    }

    public $onInit(): void {
        const map = DvbUtil.mapValues<AppConfigKey, AppConfigEntry>(AppConfigKey, k => new AppConfigEntry(k));
        // add all values to the existing keys
        this.appConfigService.getConfig().then(config => {
            config.entries.forEach(entry => {
                map.set(entry.key, entry);
            });

            this.entries = Array.from(map.values());
            // create backup so we can reset later
            this.backup = angular.copy(this.entries);
        });
    }

    public submit(): void {
        if (!this.entries) {
            return;
        }

        this.isLoading = true;

        const config = new AppConfig();

        config.entries = this.entries;

        this.appConfigService.saveConfig(config).finally(() => {
            this.isLoading = false;
            this.$onInit();
        });
    }

    public reset(): void {
        this.entries = this.backup ?
            angular.copy(this.backup) :
            DvbUtil.mapToValuesArray<AppConfigKey, AppConfigEntry>(AppConfigKey, k => new AppConfigEntry(k));
    }
}

componentConfig.controller = DvbMaintenanceConfig;
angular.module('kitAdmin').component('dvbMaintenanceConfig', componentConfig);
