<div class="dvb-kibon-verfuegung-matching-assign">

    <div class="row">
        <div class="col-md-9 col-lg-7">

            <h2>
                <span data-translate="KIBON.VERFUEGUNGEN.HEADER"></span>
                <span data-translate="COMMON.GUELTIGKEIT_VON_BIS"
                      data-translate-value-date-ab="{{vm.verfuegung.gueltigAb | amDateFormat:'D.M.YYYY'}}"
                      data-translate-value-date-bis="{{vm.verfuegung.gueltigBis | amDateFormat:'D.M.YYYY'}}"></span>
            </h2>

            <dvb-kibon-entity-info entity="vm.verfuegung"></dvb-kibon-entity-info>

            <div class="row">
                <div class="col-md-12 normal-abstand-bottom normal-abstand-top">
                    <dvb-kitax-betreuungsgutscheine
                        betreuungs-gutscheine="vm.verfuegung.betreuungsGutscheine"
                        schema-version="vm.verfuegung.schemaVersion">
                    </dvb-kitax-betreuungsgutscheine>
                </div>
            </div>

        </div>

        <div class="col-md-3 col-lg-offset-1 col-lg-4">

            <h2 data-translate="COMMON.ZUWEISEN"></h2>

            <form name="assignmentForm"
                  class="dvb-form"
                  ng-submit="vm.assignSearchResult(assignmentForm.$valid)">

                <div class="form-row">
                    <dvb-search-get-single-entity entity-to-search="KIND"
                                                  ng-model="vm.kindSearchResult"
                                                  as-col="true"
                                                  on-select-clear="true"
                                                  placeholder-key="COMMON.KIND.SINGULAR"
                                                  required>
                    </dvb-search-get-single-entity>
                </div>

                <button type="submit"
                        class="btn btn-primary normal-abstand-top"
                        data-translate="COMMON.ZUWEISEN"
                        ng-disabled="!vm.kindSearchResult">
                </button>
            </form>
        </div>
    </div>
</div>
