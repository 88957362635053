/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {IDirectiveLinkFn} from 'angular';

angular.module('kitAdmin').directive('dvbFocusOut', dvbFocusOut);

dvbFocusOut.$inject = ['$parse', '$rootScope'];

function dvbFocusOut($parse: angular.IParseService, $rootScope: angular.IRootScopeService): angular.IDirective {
    return {
        restrict: 'A',
        compile: (_$element, attr): IDirectiveLinkFn => {
            const directiveName = 'dvbFocusOut';
            const fn = $parse(attr[directiveName]);

            return (scope, element): void => {
                element.on('focusout', event => {
                    const callback = (): void => {
                        fn(scope, {$event: event});
                    };

                    if ($rootScope.$$phase) {
                        scope.$evalAsync(callback);
                    } else {
                        scope.$apply(callback);
                    }
                });
            };
        },
    };
}
