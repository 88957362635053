/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Firma, FirmenKontingent} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {TargetState, Transition, UIRouter} from '@uirouter/core';
import type {IPromise} from 'angular';
import angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {FirmaService} from '../common/service/rest/firmaService';

angular.module('kitAdmin').config(firmaConfig);

firmaConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

export * from './dvb-firma-erstellen/dvb-firma-erstellen';
export * from './dvb-firma-main/dvb-firma-main';
export * from './dvb-firma-profil/dvb-firma-profil';
export * from './dvb-firma-stammdaten/dvb-firma-stammdaten';
export * from './dvb-firmen-kontakt-erstellen/dvb-firmen-kontakt-erstellen';
export * from './dvb-firmen-plaetze-verlauf/dvb-firmen-plaetze-verlauf';
export * from './firma-kontingent-value/dvb-create-firmen-kontingent-value/dvb-create-firmen-kontingent-value';
export * from './firma-kontingent-value/dvb-firma-kontingent-value-content/dvb-firma-kontingent-value-content';
export * from './firma-kontingent-value/dvb-firma-kontingent-value-form/dvb-firma-kontingent-value-form';
export * from './firma-kontingent-value/dvb-firma-kontingent-value-header/dvb-firma-kontingent-value-header';
export * from './firma-kontingent-value/dvb-firma-kontingent-value-title/dvb-firma-kontingent-value-title';
// Add New Component JS Above

firmaConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function firmaConfig($stateProvider: StateProvider, $uiRouterProvider: UIRouter): void {
    $stateProvider.state('base.firma', {
        abstract: true,
        url: '/firma/{id:[0-9]+}',
        template: '<dvb-firma-main firma="$resolve.firma"></dvb-firma-main>',
        resolve: {
            firma: [
                'firmaService', '$transition$',
                (firmaService: FirmaService, $transition$: Transition): angular.IPromise<Firma | null> =>
                    firmaService.get($transition$.params().id, {cache: true}),
            ],
            $title: ['firma', (firma: Firma): string => firma.getDisplayName()],
        },
        data: {
            permission: PERMISSION.KITA.VIEW_ANY,
        },
    });
    $stateProvider.state('base.firma.profil', {
        url: '/profil',
        template: '<dvb-firma-profil firma="$resolve.firma" firmen-kontingente="$resolve.firmenKontingente">' +
            '</dvb-firma-main>',
        resolve: {
            firmenKontingente: [
                'firmaService', '$transition$',
                (firmaService: FirmaService, $transition$: Transition): angular.IPromise<FirmenKontingent[]> =>
                    firmaService.getFirmenKontingenteWithKitas($transition$.params().id, {
                        cache: false,
                        includes: '(firma,values.fields(tarif))',
                    }),
            ],
        },
    });
    $stateProvider.state('base.firma-erstellen', {
        url: '/firma/firma-erstellen',
        template: '<dvb-firma-erstellen></dvb-firma-erstellen>',
        data: {
            permission: PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN,
        },
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): IPromise<string> =>
                    trans($translate, 'COMMON.PLATZART.ERSTELLEN'),
            ],
        },
    });
    $stateProvider.state('base.firmenkontingent', {
        url: '/firmenkontingent/{id:[0-9]+}',
        resolve: {
            firmenKontingent: [
                'firmaService', '$transition$',
                (firmaService: FirmaService, $transition$: Transition): angular.IPromise<FirmenKontingent> =>
                    firmaService.getFirmenKontingent($transition$.params().id, {cache: true}),
            ],
        },
        data: {
            permission: PERMISSION.KITA.VIEW_ANY,
        },
        redirectTo: ($transition$): Promise<TargetState> => $transition$.injector()
            .getAsync<FirmenKontingent>('firmenKontingent')
            .then(kontingent => kontingent.firmaId)
            .then(id => $transition$.router.stateService.target('base.firma.profil', {id})),

    });

    /* Add New States Above */

    // Redirect to the default child state
    $uiRouterProvider.urlService.rules.when('/firma/:firmaId', '/firma/:firmaId/profil');
}
