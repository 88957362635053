/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {
    KinderOrtSchliesstageService,
} from '../../../../common/service/rest/kinderort/kinderOrtSchliesstageService';
import {KinderOrtSchliesstage} from '../../../../schliesstage/models/KinderOrtSchliesstage';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrtSchliesstage: '<',
        kinderOrtId: '<',
        kinderOrtName: '<',
    },
    template: require('./dvb-kinder-ort-schliesstage.html'),
    controllerAs: 'vm',
};

export class DvbKinderOrtSchliesstage {
    public static $inject: readonly string[] = [
        'kinderOrtSchliesstageService',
        'dialogService',
        'errorService',
        '$state',
        '$translate',
    ];

    public kinderOrtSchliesstage!: KinderOrtSchliesstage[];
    public kinderOrtId!: string;
    public kinderOrtName!: string;

    public kinderOrtSchliesstageNeu: KinderOrtSchliesstage | null = null;
    public isLoading: boolean = false;
    public showNewForm: boolean = false;

    public constructor(
        private readonly kinderOrtSchliesstageService: KinderOrtSchliesstageService,
        private readonly dialogService: DialogService,
        private readonly errorService: ErrorService,
        private readonly $state: StateService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public hideForm(): void {
        this.showNewForm = false;
        this.kinderOrtSchliesstageNeu = null;
    }

    public neueSchliesstageDefinieren(): void {
        this.showNewForm = true;
        this.kinderOrtSchliesstageNeu = new KinderOrtSchliesstage();
        this.kinderOrtSchliesstageNeu.kinderOrtId = this.kinderOrtId;
    }

    public terminateSchliesstage(): void {
        const beendigungAction = (endeDatum: moment.Moment): Observable<unknown> => {
            this.errorService.clearAll();

            return from(this.kinderOrtSchliesstageService.terminateKinderOrtSchliesstage(this.kinderOrtId, endeDatum)
                .then(() => this.reloadSchliesstage())).pipe(take(1));
        };

        this.dialogService.openDateDialog({
            title: this.$translate.instant('KINDERORT.SCHLIESSTAGE.ZUWEISUNG_BEENDEN_HEADING', {
                kinderOrtName: this.kinderOrtName,
            }),
            dateLimitation: 'endOfMonth',
            confirm: endDate => {
                const valuesNachEndeDatum = this.kinderOrtSchliesstage.filter(
                    (kinderOrtSchliesstage: KinderOrtSchliesstage) =>
                        kinderOrtSchliesstage.gueltigAb?.isAfter(endDate));

                if (!valuesNachEndeDatum.length) {
                    return beendigungAction(endDate);
                }

                const confirm$ = new Subject<void>();
                this.dialogService.openConfirmDialog({
                    title: 'KINDERORT.SCHLIESSTAGE.CONFIRM_BEENDEN',
                    confirmActionText: 'COMMON.BEENDEN',
                    confirm: () => from(Promise.resolve(beendigungAction(endDate))).pipe(tap(() => confirm$.next())),
                    cancel: () => confirm$.next(),
                });

                return confirm$.pipe(take(1));
            },
        });
    }

    public revertSchliesstage(kinderOrtSchliesstage: KinderOrtSchliesstage): void {
        this.errorService.clearAll();

        this.kinderOrtSchliesstageService.extendKinderOrtSchliesstage(checkPresent(kinderOrtSchliesstage.id))
            .then(() => this.reloadSchliesstage());
    }

    public saveSchliesstage(kinderOrtSchliesstage: KinderOrtSchliesstage): void {
        this.isLoading = true;
        kinderOrtSchliesstage.kinderOrtId = this.kinderOrtId;
        this.kinderOrtSchliesstageService.create(kinderOrtSchliesstage)
            .then(() => this.reloadSchliesstage())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public showConfirmDeleteSchliesstage(kinderOrtSchliesstage: KinderOrtSchliesstage): void {
        this.dialogService.openDeleteDialog({
            entityText: 'KINDERORT.SCHLIESSTAGE.ZUWEISUNG',
            confirm: () => {
                this.errorService.clearAll();

                return from(this.kinderOrtSchliesstageService.delete(checkPresent(kinderOrtSchliesstage.id)))
                    .pipe(take(1), tap(() => this.$state.reload()));
            },
        });
    }

    private reloadSchliesstage(): angular.IPromise<any> {
        this.hideForm();

        return this.kinderOrtSchliesstageService.getAllByKinderOrt(this.kinderOrtId)
            .then((kinderOrtSchliesstage: KinderOrtSchliesstage[]) => {
                this.kinderOrtSchliesstage = kinderOrtSchliesstage;
            });
    }
}

componentConfig.controller = DvbKinderOrtSchliesstage;
angular.module('kitAdmin').component('dvbKinderOrtSchliesstage', componentConfig);
