<div class="dvb-betreuungsfaktor-regel dvb-form">

    <h3 data-translate="BETREUUNGSFAKTORREGEL.TITLE_KINDERGARTENBEDINGTER_BETREUUNGSFAKTOR"></h3>

    <div class="row form-row">
        <div class="col-xs-12">
            <p data-translate="BETREUUNGSFAKTORREGEL.KINDERGARTEN_FAKTOR_DISPLAY"
               data-translate-value-faktor="{{vm.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor}}"
               data-translate-value-type="I"
               ng-if="vm.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor">
            </p>
            <p data-translate="BETREUUNGSFAKTORREGEL.KINDERGARTEN_FAKTOR_DISPLAY"
               data-translate-value-faktor="{{vm.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor}}"
               data-translate-value-type="II"
               ng-if="vm.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor">
            </p>
            <p data-translate="BETREUUNGSFAKTORREGEL.SCHULE_FAKTOR_DISPLAY"
               data-translate-value-faktor="{{vm.betreuungsfaktorRegel.schuleBetreuungsfaktor}}"
               ng-if="vm.betreuungsfaktorRegel.schuleBetreuungsfaktor">
            </p>
            <p data-translate="BETREUUNGSFAKTORREGEL.KEIN_FAKTOR_DEFINIERT"
               ng-if="!vm.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor && !vm.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor && !vm.betreuungsfaktorRegel.schuleBetreuungsfaktor">
            </p>
        </div>
    </div>

    <h3 ng-class="{'small-abstand-top': vm.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor ||vm.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor }"
        data-translate="BETREUUNGSFAKTORREGEL.TITLE_ALTERSBEDINGTE_BETREUUNGSFAKTOREN">
    </h3>

    <div class="row">
        <div class="col-xs-12">
            <p data-translate="BETREUUNGSFAKTORREGEL.KEIN_FAKTOR_DEFINIERT"
               ng-if="vm.betreuungsfaktorRegel.altersBedingteBetreuungsfaktoren.length === 0">
            </p>
        </div>
    </div>

    <div
        ng-repeat="altersBedingterBetreuungsfaktor in vm.betreuungsfaktorRegel.altersBedingteBetreuungsfaktoren | orderBy: 'vonMonate'"
        class="row">
        <div class="col-xs-5">
            <p ng-if="altersBedingterBetreuungsfaktor.vonMonate > 0"
               data-translate="BETREUUNGSFAKTORREGEL.AB_MONATE_DISPLAY"
               data-translate-value-display="{{altersBedingterBetreuungsfaktor.vonMonate}}">
            </p>
            <p ng-if="altersBedingterBetreuungsfaktor.vonMonate == 0"
               data-translate="BETREUUNGSFAKTORREGEL.AB_GEBURT">
            </p>
        </div>
        <div class="col-xs-5">
            <p data-translate="BETREUUNGSFAKTORREGEL.FAKTOR_DISPLAY"
               data-translate-value-faktor="{{altersBedingterBetreuungsfaktor.betreuungsfaktor}}">
            </p>
        </div>
    </div>


    <h3 data-translate="KIBON_ABZUG.TITLE" class="normal-abstand-top"></h3>

    <p data-translate="KIBON_ABZUG.KEIN_ABZUG_DEFINIERT"
       ng-if="!vm.betreuungsfaktorRegel.kibonAbzugProTag">
    </p>

    <div class="row" ng-if="vm.betreuungsfaktorRegel.kibonAbzugProTag">
        <div class="col-xs-5">
            <p data-translate="KIBON_ABZUG.ABZUG_DISPLAY"
               data-translate-value-abzug="{{vm.betreuungsfaktorRegel.kibonAbzugProTag}}"
               ng-if="vm.betreuungsfaktorRegel.kibonAbzugProTag">
            </p>
        </div>
        <div class="col-xs-7" ng-if="vm.betreuungsfaktorRegel.kibonAbzugProTag">
            <div class="checkbox floating-text no-margin">
                <label>
                    <input ng-disabled="true"
                           ng-model="vm.betreuungsfaktorRegel.nachmittagsKibonAbzug"
                           type="checkbox">
                    <span data-translate="KIBON_ABZUG.NACHMITTAGS_ABZUG"></span>
                </label>
            </div>
        </div>
    </div>

    <h3 data-translate="KIBON_ABZUG.KIBON_INTERN_KG_PAUSCHAL_ABZUG" class="normal-abstand-top"></h3>

    <p data-translate="KIBON_ABZUG.KEIN_ABZUG_DEFINIERT"
       ng-if="!vm.betreuungsfaktorRegel.kibonInternKgPauschalAbzug">
    </p>

    <p ng-if="vm.betreuungsfaktorRegel.kibonInternKgPauschalAbzug"
       data-translate="KIBON_ABZUG.ABZUG_DISPLAY"
       data-translate-value-abzug="{{vm.betreuungsfaktorRegel.kibonInternKgPauschalAbzug}}">
    </p>
</div>
