<div class="bewerbung">
    <div class="action-header" ng-hide="vm.hideActionHeader">
        <dvb-betreuungswunsch-header bewerbung="vm.bewerbung"
                                     on-date-selected="vm.onDateSelected({date: vm.bewerbung.gewuenschteBetreuungAb}); $event.stopPropagation();">
        </dvb-betreuungswunsch-header>
        <div class="actions">
            <ng-transclude></ng-transclude>
        </div>
    </div>
    <div class="group-container">
        <div class="container-item">
            <div class="description">
                <p ng-repeat="kita in vm.bewerbung.kitas"
                   ng-bind="kita.getDisplayName()">
                </p>

                <div class="pensum-plaetze-und-kindergarten">
                    <div class="pensum" ng-bind="vm.bewerbung.pensum.display()"></div>
                    <div class="plaetze">
                        <dvb-platz-typen ng-if="vm.showPlatzTypen" platz-typen="vm.platzTypen"></dvb-platz-typen>
                    </div>
                    <div class="kindergarten">
                        <dvb-kindergarten-icon ng-if="vm.bewerbung.kindergarten"
                                               kindergarten-belegung="{{vm.kindergartenBelegung}}">
                        </dvb-kindergarten-icon>
                    </div>
                </div>
            </div>
            <dvb-wochenplan class="small"
                            distinct-betreuungs-zeitraeume="vm.wochenplan.distinctZeitraeume"
                            week-days="vm.enabledDays"
                            interactive="false"
                            show-null-value-as-plain="true"
                            zeitraum-felder="vm.zeitraumFelder"
                            show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName">
            </dvb-wochenplan>

        </div>
        <div class="container-item bemerkung" ng-if="vm.bemerkung">
            <p ng-bind="vm.bemerkung"></p>
        </div>
        <div class="container-item anmeldedatum" ng-if="vm.anmeldedatum">
            <span data-translate="COMMON.ANMELDEDATUM_MIT_DATUM"
                  data-translate-value-datum="{{vm.anmeldedatum | amDateFormat: 'D.M.YYYY'}}">
            </span>
        </div>
        <div class="container-item gemeinde" ng-if="vm.politischeGemeinde">
            <span data-translate="COMMON.GEMEINDE" data-translate-value-gemeinde="{{vm.politischeGemeinde}}"></span>
        </div>
    </div>
</div>
