/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestInclude, RestLimited} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../../config';
import {KinderOrtSchliesstage} from '../../../../schliesstage/models/KinderOrtSchliesstage';
import {SchliesstagDateRange} from '../../../../schliesstage/models/SchliesstagDateRange';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class KinderOrtSchliesstageService {
    public static $inject: readonly string[] = ['$http'];

    private static baseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kinderortschliesstage`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public getAllByKinderOrt(kinderOrtId: string): angular.IPromise<KinderOrtSchliesstage[]> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtSchliesstageService.baseUrl}/kinderOrt/${encodeURIComponent(kinderOrtId)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, KinderOrtSchliesstage, 'items');
    }

    public create(kinderOrtSchliesstage: KinderOrtSchliesstage): angular.IPromise<unknown> {
        const kinderOrtId = checkPresent(kinderOrtSchliesstage.kinderOrtId);

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtSchliesstageService.baseUrl}/${encodeURIComponent(kinderOrtId)}`;

        return this.$http.post(url, kinderOrtSchliesstage.toRestObject());
    }

    public delete(kinderOrtSchliesstageId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtSchliesstageService.baseUrl}/${encodeURIComponent(kinderOrtSchliesstageId)}`;

        return this.$http.delete(url);
    }

    public extendKinderOrtSchliesstage(kinderOrtSchliesstageId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtSchliesstageService.baseUrl}/${encodeURIComponent(kinderOrtSchliesstageId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    public terminateKinderOrtSchliesstage(kinderOrtId: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtSchliesstageService.baseUrl}/kinderOrt/${encodeURIComponent(kinderOrtId)}/enddate`;

        return this.$http.put(url, {date: DvbRestUtil.momentToLocalDate(endDate)});
    }

    public getForRange(
        kinderOrtId: string,
        params: RestLimited & RestInclude,
    ): angular.IPromise<SchliesstagDateRange[]> {
        DvbRestUtilAngularJS.clearHttpCache();

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${KinderOrtSchliesstageService.baseUrl}/${
            encodeURIComponent(kinderOrtId)}/schliesstageindaterange`;

        return DvbRestUtilAngularJS.getModelsArray(url, SchliesstagDateRange, 'items', matrixParams);
    }

}
