/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import {idFromLocation} from '@dv/shared/angular';
import type {KontoService} from '@dv/shared/backend/api/konto.service';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import type {Persisted} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {finalize, firstValueFrom, map, switchMap} from 'rxjs';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    template: require('./dvb-konten.html'),
    controllerAs: 'vm',
};

export class DvbKonten implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'kontoService', 'errorService'];

    public konten: JaxKonto[] = [];
    public konto: JaxKonto | null = null;
    public showNewForm: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private readonly $state: StateService,
        private readonly kontoService: KontoService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.kontoService.getAll$().subscribe({
            next: data => {
                this.konten = data.items;
            },
        });
    }

    public onUpdate(
        workingCopyKonto: JaxKonto,
        konto: JaxKonto,
        context: AccordionContext,
        form: angular.IFormController,
    ): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            context.cancelSubmission();

            return;
        }

        const copywithDefaults = angular.copy(workingCopyKonto);

        this.kontoService.update$({
            id: copywithDefaults.id!,
            jaxKonto: copywithDefaults,
        }).pipe(
            finalize(() => {
                context.cancelSubmission();
            }),
        ).subscribe({
            next: () => {
                angular.extend(konto, copywithDefaults);
                context.completeSubmission();
            },
        });
    }

    public onCancelUpdate(workingCopyKonto: JaxKonto, konto: JaxKonto): void {
        angular.extend(workingCopyKonto, konto);
    }

    public onDelete(konto: Persisted<JaxKonto>): Promise<unknown> {
        // eslint-disable-next-line no-underscore-dangle
        const observable$ = this.kontoService._delete$({id: konto.id})
            .pipe(switchMap(() => this.$state.reload()));

        return firstValueFrom(observable$);
    }

    public initNewForm(): void {
        this.konto = {
            bezeichnung: '',
            owner: '',
            iban: '',
            bic: '',
        };
        this.showNewForm = true;
    }

    public createKonto(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return;
        }

        const copywithDefaults = angular.copy(checkPresent(this.konto));

        this.isLoading = true;

        this.kontoService.create$({jaxKonto: copywithDefaults}, 'response').pipe(
            finalize(() => {
                this.isLoading = false;
            }),
            map(idFromLocation),
        ).subscribe({
            next: id => {
                copywithDefaults.id = id;
                this.konten.push(copywithDefaults);
                this.showNewForm = false;
            },
        });
    }
}

componentConfig.controller = DvbKonten;
angular.module('kitAdmin').component('dvbKonten', componentConfig);
