/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt, KinderOrtId, Rechnung, RechnungId, RechnungsStatus} from '@dv/kitadmin/models';
import {EmailVersandProperties, Mahnlauf, RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted, RechnungsRevisionError} from '@dv/shared/code';
import {
    checkPresent,
    DvbDateUtil,
    DvbError,
    DvbRestUtil,
    isPresent,
    isRechnungslaufError,
    RECHNUNG_FILTER_STATES,
    RechnungsLaufError,
} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {DvbDownload} from '../../../../../base/directive/dvb-download/dvb-download';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';
import type {KitaFakturaService} from '../../../../../common/service/rest/kinderort/kitaFakturaService';
import type {RechnungenFilter} from '../../../../../filter/RechnungenFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        kita: '<',
        status: '<',
        filter: '<',
    },
    template: require('./dvb-kita-mahnlauf.html'),
    controllerAs: 'vm',
};

export class DvbKitaMahnlauf implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'kitaFakturaService',
        'errorService',
        'fakturaService',
        '$q',
    ];

    public kita!: Persisted<KinderOrt>;
    public status!: RechnungsStatus;
    public filter!: RechnungenFilter;
    public dvbDownloadCtrl!: DvbDownload;

    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.TRANSAKTION;
    public rechnungen: Persisted<Rechnung>[] = [];
    public isLoading: boolean = false;
    public isSubmitting: boolean = false;
    public zahlungsFrist: moment.Moment | null = null;

    public showGebuehr: boolean = false;
    public gebuehrText: string | null = null;
    public gebuehrBetrag: number | null = null;
    public activateDownload: boolean = false;
    public selectedRechnungen: { [rechnungId: string]: boolean } = {};
    public errors: { [rechnungId: string]: RechnungsRevisionError[] } = {};
    public emailVersandProperties: EmailVersandProperties = new EmailVersandProperties();

    public mahngebuehrLocked: boolean = true;

    private timeout?: angular.IDeferred<any> = undefined;

    public constructor(
        private readonly $state: StateService,
        private readonly kitaFakturaService: KitaFakturaService,
        private readonly errorService: ErrorService,
        private readonly fakturaService: FakturaService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.isLoading = true;
        this.emailVersandProperties.replyToAddress = this.kita.email;

        // If the states object is empty, enable all states
        if (Object.keys(this.filter.states).length === 0) {
            Object.values(RECHNUNG_FILTER_STATES)
                .map(state => state.name)
                .forEach(state => {
                    this.filter.states[state] = true;
                });
        }

        // Disable BEZAHLT and STORNIERT states because they cannot be mahned.
        this.filter.states[RECHNUNG_FILTER_STATES.BEZAHLT.name] = false;
        this.filter.states[RECHNUNG_FILTER_STATES.STORNIERT.name] = false;

        const params = this.filter.toRestObject();
        params.includes = '(nestedIncludes.fields(ausstehenderBetrag,emailVersandHistory,kontaktperson))';

        this.kitaFakturaService.getRechnungen(this.kita.id, params).then(kitaRechnungen => {
            this.rechnungen = kitaRechnungen.rechnungen;
            this.initSelectedRechnungsIds(this.rechnungen);
            this.checkRechnungsLaufLock();
        }).finally(() => {
            this.isLoading = false;
        });
    }

    /**
     * Goes back to Kita Faktura.
     */
    public cancel(): void {
        this.$state.go('base.kinderort.faktura.overview');
    }

    /**
     * Reset gebuehr when the checkbox is toggled. When it opens again the content is not there anymore.
     */
    public resetGebuehr(): void {
        this.gebuehrText = null;
        this.gebuehrBetrag = null;
    }

    public submit(form: angular.IFormController): void {
        this.errorService.handleValidationError(!form.$error.required, 'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(DvbDateUtil.isValidMoment(this.zahlungsFrist),
            'ERRORS.ERR_INVALID_DATE');

        if (!form.$valid) {
            return;
        }

        this.isSubmitting = true;

        const mahnlauf = new Mahnlauf(
            this.status,
            this.getSelectedRechnungsIds(),
            checkPresent(this.zahlungsFrist),
            this.gebuehrText,
            this.gebuehrBetrag,
            this.emailVersandProperties,
        );

        this.timeout = this.$q.defer();

        this.fakturaService.executeMahnlauf(mahnlauf, {timeout: this.timeout.promise})
            .then(() => {
                if (!this.activateDownload) {
                    return this.goToOverview();
                }

                return this.downloadPdf(this.kita.id, this.getSelectedRechnungsIds())
                    .finally(() => this.goToOverview());
            })
            .catch(error => {
                if (DvbRestUtil.isRequestCancelled(error)) {
                    return this.$q.resolve();
                }

                if (error instanceof DvbError && isRechnungslaufError(error)) {
                    this.errorService.handleError(error);

                    const fakturaError = RechnungsLaufError.apiResponseTransformer(error.args.fakturaError);
                    this.handleFakturaError(fakturaError);

                    return this.$q.resolve();
                }

                return this.$q.reject(error);
            })
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    private handleFakturaError(error: RechnungsLaufError): void {
        this.rechnungen = this.rechnungen.filter(rechnung => this.errorAffectsRechnung(error, rechnung));
        this.initSelectedRechnungsIds(this.rechnungen);
        this.errors = error.mapErrorsByRechnung();
    }

    private errorAffectsRechnung(error: RechnungsLaufError, rechnung: Rechnung): boolean {
        return error.failedRechnungsUebermittlungsEinheiten
            .some(ueError => ueError.causes.some(revisionError => rechnung.id === revisionError.rechnungsId));
    }

    private initSelectedRechnungsIds(rechnungen: Persisted<Rechnung>[]): void {
        this.selectedRechnungen = {};
        rechnungen.forEach(rechnung => {
            this.selectedRechnungen[rechnung.id] = !rechnung.kontaktperson?.mahnsperre;
        });
    }

    private getSelectedRechnungsIds(): RechnungId[] {
        return Object.keys(this.selectedRechnungen).filter(id => this.selectedRechnungen[id]);
    }

    /**
     * We use the filter object to navigate back to Kita -> Faktura with a preset filter that will show the
     * invoices we just manipulated.
     */
    private goToOverview(): angular.IPromise<any> {
        this.filter.states = {};
        if (this.status.filterState) {
            this.filter.states[this.status.filterState] = true;
        }
        this.filter.abgelaufen = false;

        return this.$state.go('base.kinderort.faktura.overview', {filter: this.filter.toUriParam()});
    }

    /**
     * Download a zip file containing all PDFs that we just modified.
     */
    private downloadPdf(kinderOrtId: KinderOrtId, selectedIds: string[]): angular.IPromise<void> {
        return this.kitaFakturaService.getTempBlobForZipRechnungenPDF(kinderOrtId, selectedIds)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob));
    }

    private checkRechnungsLaufLock(): void {
        this.mahngebuehrLocked =
            isPresent(this.rechnungen.find(rech => this.kita.rechnungsLaufLockedBefore?.isAfter(rech.gueltigAb)));
    }
}

componentConfig.controller = DvbKitaMahnlauf;
angular.module('kitAdmin').component('dvbKitaMahnlauf', componentConfig);
