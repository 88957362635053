/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {DataIntegrityService} from '../../../common/service/rest/dataIntegrityService';
import {DvbRestUtil} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {},
    template: require('./dvb-malformed-phone-numbers.html'),
    controllerAs: 'vm',
};

export class DvbMalformedPhoneNumbers implements angular.IController {

    public static $inject: readonly string[] = ['dataIntegrityService', '$q'];

    public isDownloading: boolean = false;
    public showNoDataAvailable: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly dataIntegrityService: DataIntegrityService,
        private readonly $q: angular.IQService,
    ) {
    }

    public getMalformedPhoneNumbers(): void {
        this.isDownloading = true;

        this.dataIntegrityService.getTempBlobForMalformedPhoneNumbers()
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .catch(error => {
                if (DvbRestUtil.hasNoData(error)) {
                    this.showNoDataAvailable = true;

                    return this.$q.resolve();
                }

                return this.$q.reject(error);
            })
            .finally(() => {
                this.isDownloading = false;
            });
    }
}

componentConfig.controller = DvbMalformedPhoneNumbers;
angular.module('kitAdmin').component('dvbMalformedPhoneNumbers', componentConfig);
