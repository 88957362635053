/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Translation} from '@dv/kitadmin/models';
import type {UserLanguageService} from '@dv/shared/angular';
import type {SupportedLanguage} from '@dv/shared/code';
import {DvbUtil, LogFactory} from '@dv/shared/code';
import angular from 'angular';
import {directiveSubSink} from '../../directive/directive-scope-subsink';

const LOG = LogFactory.createLog('DvbTranslatableLabel');

const componentConfig: angular.IComponentOptions = {
    bindings: {
        translation: '<',
        truncate: '<?',
        prewrap: '<?',
    },
    template: require('./dvb-translatable-label.html'),
    controllerAs: 'vm',
};

export class DvbTranslatableLabel implements angular.IController {
    public static $inject: readonly string[] = ['$scope', 'userLanguageService'];

    public translation!: Translation;
    public truncate: boolean = false;
    public prewrap: boolean = false;

    public language: SupportedLanguage = 'DE';

    public constructor(
        private readonly $scope: angular.IScope,
        userLanguageService: UserLanguageService,
    ) {
        const subSink = directiveSubSink($scope);
        subSink.sink = userLanguageService.userLanguage$
            .subscribe({
                next: language => this.language = language,
                error: (err: unknown) => LOG.error(err),
            });
    }

    public isLanguageEmpty(lang: SupportedLanguage): boolean {
        return DvbUtil.isNotEmptyString(this.translation.texts[lang]);
    }
}

componentConfig.controller = DvbTranslatableLabel;
angular.module('kitAdmin').component('dvbTranslatableLabel', componentConfig);
