<form name="emailMasssenVersandBestaetigenForm"
      #emailMasssenVersandBestaetigenForm="ngForm"
      novalidate
      (ngSubmit)="submit(emailMasssenVersandBestaetigenForm)">
    <div class="modal-body">
        <h1 [translate]="'COMMON.RECHNUNGEN_PER_EMAIL_SENDEN'"
            [translateParams]="{anzahl: kitaRechnungen?.stats!.count}"></h1>

        <div class="row big-abstand-bottom">
            <div class="col-md-6 col-md-offset-3 layout-row">
                <input class="form-control"
                       type="email"
                       [name]="'replyToAddress'"
                       [(ngModel)]="emailVersandProperties.replyToAddress"
                       [placeholder]="'COMMON.ANTWORT_ADRESSE' | translate"
                       [tooltip]="'COMMON.ANTWORT_ADRESSE' | translate"
                       required>
                <dv-spf-info [replyToAddress]="emailVersandProperties.replyToAddress"/>
            </div>
        </div>
        @if (hasMissingEmail || failed.length) {
            <div class="error-container">
                <h3 class="normal-abstand-bottom"
                    [translate]="'FAKTURA.MASSENAKTIONEN.ERROR_EMAIL_SUCCESS_MF'"
                    [translateParams]="{count: successCount}">
                </h3>

                @if (failed.length > 0) {
                    <div class="big-abstand-bottom">
                        <h3 [translate]="'FAKTURA.MASSENAKTIONEN.ERROR_EMAIL_FAILED'"></h3>

                        @for (rechnung of failed; track trackById($index, rechnung)) {
                            <p>
                                <a [uiSref]="'base.rechnung.uebersicht'"
                                   [uiParams]="{ rechnungId: rechnung.id }"
                                   href
                                   (click)="hide()"
                                   (keydown)="hide()"
                                   [translate]="'COMMON.RECHNUNG_NR'"
                                   [translateParams]="{nummer: rechnung.rechnungsNummer}">
                                </a>&nbsp;
                                <span>{{ rechnung.kontaktpersonDisplayName }}</span>
                            </p>
                        }
                    </div>
                }

                @if (hasMissingEmail) {
                    <div class="big-abstand-bottom">
                        <h3 [translate]="'FAKTURA.MASSENAKTIONEN.ERROR_MISSING_EMAIL'"></h3>

                        @for (entry of missingEmail | keyvalue; track trackByKey($index, entry)) {
                            <p>
                                <a [uiSref]="'base.kontaktperson.profil'"
                                   [uiParams]="{ kontaktpersonId: entry.key }"
                                   href
                                   (click)="hide()"
                                   (keydown)="hide()"
                                   [textContent]="entry.value[0].kontaktpersonDisplayName">
                                </a>&nbsp;
                                @for (r of entry.value; track trackById($index, r)) {
                                    <span [translate]="'COMMON.RECHNUNG_NR'"
                                          [translateParams]="{nummer: r.rechnungsNummer}">
                                    </span>
                                }
                            </p>
                        }
                    </div>
                }
            </div>
        }
    </div>
    <div class="modal-footer">
        <dvlib-button-list>
            <dvlib-loading-button [isLoading]="isLoading" [type]="'submit'">
                <span [translate]="'ADMINISTRATION.EMAIL_SENDEN'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    (click)="hide()"
                    [translate]="'COMMON.ABBRECHEN'">
            </button>
        </dvlib-button-list>
    </div>
</form>


