<div class="betreuungswunsch-header">
    <p ng-if="vm.bewerbung.gewuenschteBetreuungAb">
        <span data-translate="COMMON.BETREUUNGSWUNSCH_AB"></span>
        <a href=""
           ng-click="vm.onDateSelected({date: vm.bewerbung.gewuenschteBetreuungAb}); $event.stopPropagation();"
           ng-bind="vm.bewerbung.gewuenschteBetreuungAb | amDateFormat: 'D.M.YYYY'"></a>
    </p>

    <p ng-if="!vm.bewerbung.gewuenschteBetreuungAb">
        <span data-translate="COMMON.BETREUUNGSWUNSCH"></span>
    </p>
</div>
