/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SortListDialogComponent, SortListDialogModel} from '@dv/kitadmin/ui';
import {DialogComponent, SortListComponent, SortListTemplateDirective} from '@dv/shared/angular';
import type {ICustomField} from '@dv/shared/code';

@Component({
    selector: 'dv-custom-field-sort-item',
    standalone: true,
    imports: [
        SortListComponent,
        SortListDialogComponent,
        DialogComponent,
        SortListTemplateDirective,
    ],
    templateUrl: './custom-field-sort-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldSortItemComponent {

    @Input() public state?: SortListDialogModel<ICustomField>;
    @Output() public readonly closed: EventEmitter<void> = new EventEmitter<void>();
}
