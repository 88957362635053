/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').directive('dvbIgnoreMouseWheel', dvbIgnoreMouseWheel);

/**
 * Prevents altering number inputs value with the scroll wheel, by disabling scrolling as long as the input is focused.
 */
function dvbIgnoreMouseWheel(): angular.IDirective {
    return {
        restrict: 'A',
        link: (_scope, element, _attrs) => {

            const scrollDisabler: (event: JQuery.TriggeredEvent) => void = event => {
                event.preventDefault();
            };

            element.on('focus', _focusEvent => {
                element.on('wheel', scrollDisabler);
            });

            element.on('blur', _blurEvent => {
                element.off('wheel', scrollDisabler);
            });
        },
    };
}
