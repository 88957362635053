<div class="dvb-active-users row">
    <div class="col-md-7 big-abstand-bottom">

        <h2 data-translate="COMMON.BENUTZER.MF" data-translate-interpolation="messageformat"></h2>

        <dvb-pages items="vm.pageContainer.items"
                   total-items="vm.pageContainer.count"
                   is-loading="vm.isLoading"
                   items-per-page="vm.itemsPerPage"
                   current-page="vm.currentPage"
                   entity-name="'COMMON.BENUTZER.MF'"
                   on-update-page="vm.updatePage(page)"
                   on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
            <page-items dvb-accordions>
                <dvb-accordion ng-repeat="item in vm.pageContainer.items"
                               is-first="$first"
                               ng-disabled="true"
                               show-content-disabled="false">
                    <panel-header class="full-width-block">
                        <div class="row item-header">
                            <div class="col-xs-4">
                                <span ng-bind="item.getDisplayName()"></span>
                            </div>
                            <div class="col-xs-4 text-right" ng-if="item.mandant">
                                <span ng-bind="item.mandant.name"></span>
                            </div>
                            <div class="col-xs-4 text-right">
                                <span ng-bind="item.lastLoginTime | amDateFormat:'D.M.YYYY HH:mm:ss'"></span>
                            </div>
                        </div>
                    </panel-header>
                </dvb-accordion>
            </page-items>
            <total-summary>
                <dvb-list-summary>
                    <div class="floating-text">
                        <strong data-translate="COMMON.BENUTZER.MF"
                                data-translate-value-count="{{vm.pageContainer.count}}"
                                data-translate-value-showcount="true"
                                data-translate-interpolation="messageformat">
                        </strong>
                    </div>
                </dvb-list-summary>
            </total-summary>
        </dvb-pages>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <!-- STAMMDATEN -->
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <button class="btn btn-link" data-translate="ADMINISTRATION.USERS.REFRESH" ng-click="vm.reloadItems()"></button>
    </div>
</div>
