<div class="dvb-adresse">
    <div ng-if="vm.isDisabled || !vm.showEditMode"
         ng-class="{'click-to-edit-adresse': vm.clickToEdit && !vm.isDisabled}">
        <dvlib-adresse-display [adresse]="vm.adresse" [display-type]="vm.displayType"></dvlib-adresse-display>
    </div>
    <div ng-if="!vm.isDisabled && vm.showEditMode" class="small-abstand-top">
        <form name="updateAdresseForm"
              ng-submit="updateAdresseForm.$valid && !updateAdresseForm.$pending && vm.saveAdresse()" novalidate>
            <dvb-adresse-form class="form-row" ng-model="vm.editedAdresse"></dvb-adresse-form>

            <div class="row normal-abstand-top">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit" is-loading="updateAdresseForm.$pending">
                                <span data-translate="COMMON.SPEICHERN"></span>
                            </dvb-loading-button>
                        </li>
                        <li>
                            <a href="" ng-click="vm.showEditMode = false; $event.stopPropagation(); vm.cancel()"><span
                                data-translate="COMMON.ABBRECHEN"></span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
