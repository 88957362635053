/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {DvbDateUtil, DvbUtil, TypeUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {ParameterKinderkontaktliste} from '../../../report/models/ParameterKinderkontaktliste';
import * as states from '../../communication-states';
import type {BulkEmailService} from '../../service/bulk-email.service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        showCancelBtn: '<?',
        onCancel: '&?',
    },
    template: require('./dvb-bulk-email-form.html'),
    controllerAs: 'vm',
};

export class DvbBulkEmailForm implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'errorService',
        'bulkEmailService',
    ];

    public showCancelBtn: boolean = false;
    public onCancel?: () => void;

    public parameterKinderkontaktliste: ParameterKinderkontaktliste = new ParameterKinderkontaktliste();
    public defaultStichtag: moment.Moment = DvbDateUtil.today();
    public isLoading: boolean = false;

    public constructor(
        private $state: StateService,
        private errorService: ErrorService,
        private bulkEmailService: BulkEmailService,
    ) {
    }

    public submit(form: angular.IFormController): void {
        const isValid = form.$valid && this.parameterKinderkontaktliste.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!isValid) {
            return;
        }

        this.isLoading = true;
        this.bulkEmailService.getKontaktpersonen$(this.parameterKinderkontaktliste).subscribe({
            next: kontakte => {
                if (kontakte.length === 0) {
                    this.errorService.handleValidationError(false, 'ERRORS.ERR_ONE_KONTAKT_REQUIRED');

                    return undefined;
                }

                const kinderOrtIds = this.parameterKinderkontaktliste.alleKinderOrte ?
                    [] :
                    this.parameterKinderkontaktliste.kinderOrte.map(DvbUtil.mapToIdChecked);
                const fraktionIds = this.parameterKinderkontaktliste.alleKinderOrte ?
                    [] :
                    this.parameterKinderkontaktliste.gruppen.map(DvbUtil.mapToIdChecked);

                return this.$state.go(states.BULK_EMAIL_MANAGEMENT_STATE.name,
                    {kontaktpersonen: kontakte, kinderOrtIds, fraktionIds});
            },
            complete: () => {
                this.isLoading = false;
            },
        });
    }

    public cancel(): void {
        if (this.showCancelBtn && TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }
}

componentConfig.controller = DvbBulkEmailForm;
angular.module('kitAdmin').component('dvbBulkEmailForm', componentConfig);
