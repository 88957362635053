/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, LoadingContext, TerminationMode} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {BETREUUNGS_SCHLUESSEL_STATE} from '../../../personal-states';
import type {ILimitedEntityAssignmentController} from '../../models/ILimitedEntityAssignmentController';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        controller: '<',
    },
    template: require('./dvb-betreuungs-schluessel-assignment.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsSchluesselAssignment implements angular.IController {
    public static $inject: readonly string[] = ['$state'];

    public controller!: ILimitedEntityAssignmentController<IPersistable, ILimited, IPersistable>;

    public sortedEntities: ILimited[] = [];
    public newAssignment: ILimited | null = null;
    public schluesselState = BETREUUNGS_SCHLUESSEL_STATE;

    public constructor(
        private $state: StateService,
    ) {
    }

    public $onChanges(): void {
        this.sortedEntities = DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(this.controller.assigned);
        this.newAssignment = this.controller.getNewAssignmentEntity();
    }

    public save(assignment: ILimited, context?: LoadingContext): void {
        this.controller.create(assignment).then(() => {
            if (context) {
                context.finishLoading();
            }
            this.$state.reload();
        });
    }

    public remove(assignment: ILimited): angular.IPromise<any> {
        return this.controller.remove(assignment).then(() => {
            this.$state.reload();
        });
    }

    public terminate(endDate: moment.Moment, mode: TerminationMode): angular.IPromise<any> {
        return this.controller.terminate(endDate, mode)
            .then(() => this.$state.reload());
    }

    public revert(assignment: ILimited): void {
        this.controller.extend(assignment).then(() => {
            this.$state.reload();
        });
    }
}

componentConfig.controller = DvbBetreuungsSchluesselAssignment;
angular.module('kitAdmin').component('dvbBetreuungsSchluesselAssignment', componentConfig);
