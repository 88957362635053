<div class="dvb-kibon-entity-info">

    <div class="normal-abstand-bottom">
        <dvb-kibon-ref-nummer kibon-ref-nr-info="vm.entity">
        </dvb-kibon-ref-nummer>
    </div>

    <div class="row">
        <div class="col-sm-6 normal-abstand-bottom">
            <h3 data-translate="COMMON.KIND.SINGULAR"></h3>
            <p ng-bind="vm.entity.kind.getDisplayName()"></p>
            <p data-translate="COMMON.GEBURTSTAG_MIT_DATUM"
               data-translate-value-date="{{vm.entity.kind.geburtsdatum | amDateFormat:'D.M.YYYY'}}"></p>
        </div>
        <div class="col-sm-6 normal-abstand-bottom">
            <h3 data-translate="KIBON.VERFUEGUNGEN.GESUCHSTELLER"></h3>
            <p ng-bind="vm.entity.gesuchsteller.getDisplayName()"></p>
            <p ng-if="vm.entity.gesuchsteller.email" ng-bind="vm.entity.gesuchsteller.email"></p>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6 normal-abstand-bottom">
            <h3 data-translate="KIBON.VERFUEGUNGEN.INSTITUTION_TRAEGERSCHAFT"></h3>
            <p ng-bind="vm.entity.institution.name"></p>
            <p ng-bind="vm.entity.institution.traegerschaft"></p>
        </div>
        <div class="col-sm-6 normal-abstand-bottom">
            <h3 data-translate="COMMON.ADRESSE_KINDERORT"></h3>
            <dvlib-adresse-display [adresse]="vm.entity.institution.adresse"
                                   [display-type]="'long'">
            </dvlib-adresse-display>
        </div>
    </div>

</div>
