/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, END_OF_TIME, isPresent} from '@dv/shared/code';

export class PersonalKonfiguration implements IPersistable, IRestModel, ILimited {

    public constructor(
        public id: string | null = null,
        public kinderOrtId: string | null = null,
        public weeklyHours: number | null = null,
        public fteHours: number | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = END_OF_TIME,
        public arbeitszeitVon: moment.Moment | null = null,
        public arbeitszeitBis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): PersonalKonfiguration {
        return new PersonalKonfiguration(
            data.id,
            data.kinderOrtId,
            data.weeklyHours,
            data.fteHours,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            DvbRestUtil.localeHHMMTimeToMoment(data.arbeitszeitVon),
            DvbRestUtil.localeHHMMTimeToMoment(data.arbeitszeitBis));
    }

    public toRestObject(): any {
        return {
            id: this.id,
            kinderOrtId: this.kinderOrtId,
            weeklyHours: this.weeklyHours,
            fteHours: this.fteHours,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            arbeitszeitVon: DvbRestUtil.momentTolocaleHHMMTime(this.arbeitszeitVon),
            arbeitszeitBis: DvbRestUtil.momentTolocaleHHMMTime(this.arbeitszeitBis),
        };
    }

    public isValid(): boolean {
        return DvbDateUtil.isValidMoment(this.gueltigAb)
            && isPresent(this.weeklyHours)
            && isPresent(this.fteHours)
            && this.isArbeitszeitValid();
    }

    public isArbeitszeitValid(): boolean {
        return DvbDateUtil.isValidMoment(this.arbeitszeitVon)
            && DvbDateUtil.isValidMoment(this.arbeitszeitBis)
            && DvbDateUtil.isTimeBefore(this.arbeitszeitVon, this.arbeitszeitBis);
    }
}
