<div class="filter">
    <div class="zeitraum">
        <div class="mode">
            {{ 'COMMON.DATE_RANGE' | translate }}
            <div class="btn-group small-abstand-left"
                 btnRadioGroup
                 [ngModel]="displayMode"
                 (ngModelChange)="displayModeChange.emit($event)">
                <label class="btn label label-radio label-default"
                       btnRadio="day"
                       tabindex="0"
                       role="button"
                       data-testid="select-day-mode"
                       [translate]="'COMMON.DAY'">
                </label>
                <label class="btn label label-radio label-default"
                       btnRadio="week"
                       tabindex="0"
                       role="button"
                       data-testid="select-week-mode"
                       [translate]="'COMMON.WEEK'">
                </label>
                <label class="btn label label-radio label-default"
                       btnRadio="month"
                       tabindex="0"
                       role="button"
                       data-testid="select-month-mode"
                       [translate]="'COMMON.MONTH'">
                </label>
            </div>
        </div>
        <dvlib-date-switcher class="normal-abstand-left"
                             [date]="selectedDate"
                             (debouncedDateChange)="selectedDateChange.emit($event)"
                             [mode]="displayMode"
                             [convertToFirstOfMode]="false">
        </dvlib-date-switcher>
    </div>
    @if (showFilterList()) {
        <dv-filter-list [filter]="filterList()"/>
    }
    @if (showTerminFilter) {
        <div class="termin-filter checkbox">
            <label>
                <input type="checkbox"
                       [checked]="filterBedarfsRelevanteTermine"
                       (change)="filterBedarfsRelevanteTermineChange.emit(!filterBedarfsRelevanteTermine)"/>
                {{ 'PERSONAL.FILTER.TERMIN.BEDARFSRELEVANT' | translate }}
            </label>
        </div>
    }
</div>
