/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {ExternalAnmeldungCustomField} from '@dv/kitadmin/models';
import type {SortListDialogModel} from '@dv/kitadmin/ui';
import type {CustomFieldType} from '@dv/shared/code';
import {CUSTOM_FIELD_TYPE, DvbUtil, NamedEntityType, sortCustomFields} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {BehaviorSubject, finalize, from, tap} from 'rxjs';
import type {ExternalAnmeldungCustomFieldService} from '../../../external/service/externalAnmeldungCustomFieldService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-voreinstellungen-external-anmeldung-custom-fields.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenExternalAnmeldungCustomFields implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['errorService', 'externalAnmeldungCustomFieldService', '$q'];

    public customFields: ExternalAnmeldungCustomField[] = [];
    public isLoading: boolean = false;

    public readonly fieldTypes: CustomFieldType[] = CUSTOM_FIELD_TYPE.values;
    public readonly namedEntityTypes: NamedEntityType[] = [NamedEntityType.KIND, NamedEntityType.KONTAKTPERSON];
    public showSortDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public sortState?: SortListDialogModel<ExternalAnmeldungCustomField>;
    public showSortLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private endOfList: number = 0;

    public constructor(
        private errorService: ErrorService,
        private externalAnmeldungCustomFieldService: ExternalAnmeldungCustomFieldService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.loadCustomFields();
    }

    public onAddField(): void {
        this.customFields.push(new ExternalAnmeldungCustomField());
    }

    public onDelete(field: ExternalAnmeldungCustomField): void {
        DvbUtil.removeFromArray(field, this.customFields);
    }

    public onSubmit(form: angular.IFormController): angular.IPromise<any> {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return this.$q.reject();
        }

        const uniqueNames = DvbUtil.uniqueArray(this.customFields, customField => customField.name);
        const isNamesUnique = uniqueNames.length === this.customFields.length;
        this.errorService.handleValidationError(isNamesUnique, 'ERRORS.ERR_DUPLICATE_NAME');

        if (!isNamesUnique) {
            return this.$q.reject();
        }

        this.isLoading = true;

        return this.externalAnmeldungCustomFieldService.update(this.customFields)
            .then(() => this.loadCustomFields())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public sortFields(form: angular.IFormController): void {
        this.sortState = this.sortFieldState(form);
        this.showSortDialog$.next(true);
    }

    public confirmSort = (customFields: ExternalAnmeldungCustomField[], form: angular.IFormController):
        Observable<unknown> => {
        customFields.forEach((value, index) => {
            value.orderValue = index;
        });

        this.showSortLoading$.next(true);

        return from(this.onSubmit(form)).pipe(
            tap(() => this.showSortDialog$.next(false)),
            finalize(() => this.showSortLoading$.next(false)),
        );
    };

    private sortFieldState(form: angular.IFormController): SortListDialogModel<ExternalAnmeldungCustomField> {
        return {
            dialogTitleKey: 'CUSTOM_FIELD.SORTIEREN',
            items: this.customFields,
            confirm: values => this.confirmSort(values, form).pipe(tap(() => this.errorService.clearAll())).subscribe(),
            cancel: () => {
                this.showSortDialog$.next(false);
                this.errorService.clearAll();
            },
            open$: this.showSortDialog$,
            isLoading$: this.showSortLoading$,
        };
    }

    private loadCustomFields(): angular.IPromise<any> {
        this.customFields = [];

        return this.externalAnmeldungCustomFieldService.getAll().then(customFields => {
            const orderValues = customFields.map(customField => customField.orderValue ?? 0);
            this.endOfList = orderValues.length ? Math.max(...orderValues) + 1 : 0;
            this.customFields = customFields.sort(sortCustomFields);
        }).finally(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbVoreinstellungenExternalAnmeldungCustomFields;
angular.module('kitAdmin').component('dvbVoreinstellungenExternalAnmeldungCustomFields', componentConfig);
