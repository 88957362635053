<div class="translatable-editable-label">
    <div ng-repeat="lang in vm.supportedLanguages">
        <dvb-editable-label ng-attr-placeholder="{{'LANGUAGE.' + lang | translate}}"
                            maxlength="{{vm.maxLength}}"
                            ng-model="vm.translation.texts[lang]"
                            ng-change="vm.onSubmit()"
                            ng-disabled="vm.disabled">
        </dvb-editable-label>
    </div>
</div>
