<div class="dvb-rechnung-main">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-file-text-o header-icon"
               uib-tooltip="{{'COMMON.RECHNUNG.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>
            <div class="col-xs-12 header-box">

                <h1>
                    <dvb-rechnung-heading-attribute rechnung="vm.rechnung"
                                                    show-status="true"
                                                    class="pull-right small-abstand-left">
                    </dvb-rechnung-heading-attribute>

                    <span data-translate="FAKTURA.RECHNUNG_PERDIODE_NR"
                          data-translate-value-nummer="{{vm.rechnung.rechnungsNummer}}"
                          data-translate-value-periode="{{vm.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'}}">
                    </span>
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.rechnung.uebersicht" data-translate="COMMON.NAV_UEBERSICHT"></a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.rechnung.zahlungen" data-translate="FAKTURA.NAV_ZAHLUNGEN"></a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.rechnung.revisionen" data-translate="FAKTURA.NAV_REVISIONEN"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
