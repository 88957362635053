<div class="dvb-report-depots">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.reportDepotsForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-kitas-selection parameter-kinder-orte="vm.parameterKinderOrte"
                             on-change="vm.updateState()">
        </dvb-kitas-selection>

        <dvb-datepicker-text-field ng-model="vm.stichtag"
                                   ng-attr-placeholder="{{'REPORT.STICHTAG' | translate}}">
        </dvb-datepicker-text-field>

        <div class="form-row">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.includeErstattet">
                    <span data-translate="REPORT.DEPOTS.INCLUDE_ERSTATTET"></span>
                </label>
            </div>
        </div>

        <div ng-if="vm.includeErstattet" class="row form-row">
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.erstattetVon"
                                           ng-attr-placeholder="{{'REPORT.DEPOTS.ERSTATTET_VON' | translate}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.erstattetBis"
                                           ng-attr-placeholder="{{'REPORT.DEPOTS.ERSTATTET_BIS' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
