/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {Ausbildung} from '../../anstellung/models/Ausbildung';
import type {AusbildungsPersonalBedarf} from '../../betreuungs-schluessel/models/AusbildungsPersonalBedarf';
import type {BetreuungsSchluessel} from '../../betreuungs-schluessel/models/BetreuungsSchluessel';
import type {BetreuungsSchluesselRegel} from '../../betreuungs-schluessel/models/BetreuungsSchluesselRegel';
import type {BetreuungsSchluesselRegelBedarf} from '../../betreuungs-schluessel/models/BetreuungsSchluesselRegelBedarf';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsSchluessel: '<',
        betreuungsSchluesselRegel: '<',
        ausbildungen: '<',
        onDeleteRegelBedarf: '&',
        onDeleteAusbildungsBedarf: '&',
        onAddAusbildungsBedarf: '&',
    },
    template: require('./dvb-betreuungs-schluessel-regel.html'),
    controllerAs: 'vm',
};

/**
 * Dumb presentational component to reduce complexity in DvbBetreuungsSchluessel.
 */
export class DvbBetreuungsSchluesselRegel implements angular.IController {
    public static $inject: readonly string[] = ['authStore'];

    public betreuungsSchluessel!: BetreuungsSchluessel;
    public betreuungsSchluesselRegel!: BetreuungsSchluesselRegel;
    public ausbildungen: Ausbildung[] = [];

    public onDeleteRegelBedarf!: (data: { regelBedarf: BetreuungsSchluesselRegelBedarf }) => void;
    public onDeleteAusbildungsBedarf!:
        (data: { bedarf: AusbildungsPersonalBedarf; regelBedarf: BetreuungsSchluesselRegelBedarf }) => void;
    public onAddAusbildungsBedarf!: (data: { regelBedarf: BetreuungsSchluesselRegelBedarf }) => void;

    public constructor(private readonly authStore: AuthStore){}

    public isTimeRangeRequired(): boolean {
        return isPresent(this.betreuungsSchluesselRegel.vonBis.von) ||
            isPresent(this.betreuungsSchluesselRegel.vonBis.bis);
    }
}

componentConfig.controller = DvbBetreuungsSchluesselRegel;
angular.module('kitAdmin').component('dvbBetreuungsSchluesselRegel', componentConfig);
