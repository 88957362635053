/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {FormContext, FunctionType} from '@dv/shared/code';
import type {AnwesenheitsSoll} from '../../model/AnwesenheitsSoll';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anwesenheitsSoll: '<',
        previousSoll: '<',
        first: '<',
        enableEditMode: '<',
        save: '&',
        remove: '&',
        revert: '&',
    },
    template: require('./dvb-anwesenheits-soll-entry.html'),
    controllerAs: 'vm',
};

export class DvbAnwesenheitsSollEntry {
    public static $inject: readonly string[] = [];

    public anwesenheitsSoll!: AnwesenheitsSoll;
    public previousSoll!: AnwesenheitsSoll;
    public first!: boolean;
    public enableEditMode: boolean = false;
    public save!: (data: { soll: AnwesenheitsSoll; context: FormContext }) => void;
    public remove!: FunctionType;
    public revert!: FunctionType;

    public workingCopy: AnwesenheitsSoll | null = null;

    public $onInit(): void {
        this.workingCopy = this.anwesenheitsSoll.copy();
    }

    public saveWorkingCopy(context: FormContext): void {
        this.save({soll: this.workingCopy!, context});
    }
}

componentConfig.controller = DvbAnwesenheitsSollEntry;
angular.module('kitAdmin').component('dvbAnwesenheitsSollEntry', componentConfig);
