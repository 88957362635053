/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ClientNotification} from '@dv/kitadmin/models';
import type {PageContainer} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from '../rest/dvbRestUtilAngularJS';

export class NotificationService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/notification`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    /**
     * Allows to register a listener for "notification" server sent events.
     */
    public addNotificationlistener(_callback: (message: string) => void): void {
        // const sse = new EventSource(`${NotificationService.BASE_URL}/subscribe`);
        //
        // sse.addEventListener('notification', event => {
        //     callback(event.data);
        // });
    }

    public getNotifications(
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<ClientNotification>> {

        return DvbRestUtilAngularJS.getPagedItems(NotificationService.BASE_URL, ClientNotification, {}, config);
    }

    public create(notification: ClientNotification): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(NotificationService.BASE_URL, notification.toRestObject());
    }

    public delete(notificationId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${NotificationService.BASE_URL}/${encodeURIComponent(notificationId)}`;

        return this.$http.delete(url);
    }
}
