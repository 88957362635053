import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {toObservable} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import type {Termin} from '@dv/kitadmin/models';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {SpinnerComponent, trackById, trackByIdentity} from '@dv/shared/angular';
import {AngestellteService} from '@dv/shared/backend/api/angestellte.service';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {TerminUpdateMode} from '@dv/shared/backend/model/termin-update-mode';
import {TerminWorkTimeType} from '@dv/shared/backend/model/termin-work-time-type';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {catchError, combineLatest, filter, map, of, switchMap} from 'rxjs';
import {ANGESTELLTE_PROFIL_STATE} from 'src/app/personal/anstellung/anstellung-states';
import {Angestellte} from '../../../../../personal/anstellung/models/Angestellte';
import {TerminUpdateModeDialogData} from '../personalplanung-termine.store';

@Component({
    selector: 'dv-termin-delete-dialog',
    standalone: true,
    imports: [
        CommonModule,
        SubmitCancelButtonsComponent,
        SpinnerComponent,
        FormsModule,
        TranslateModule,
        UIRouterModule,
    ],
    templateUrl: './termin-delete-dialog.component.html',
    styleUrls: ['./termin-delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminDeleteDialogComponent {
    private translator = inject(Translator);
    private angestellteService = inject(AngestellteService);

    private _dialogData = signal<TerminUpdateModeDialogData>({open: true});

    public termin = computed(() => this._dialogData().termin);
    public title = computed(() => this.getTitle(this.entity(), this.termin()));
    public subtitle = computed(() => this.getSubtitle(this.entity(), this.termin()));
    public kinderOrtCount = computed(() => this.termin()?.kinderOrtIds.length ?? 0);
    public allKinderOrte = computed(() => this.termin()?.alleKinderOrte ?? false);
    public entity = computed(() =>
        TerminWorkTimeType.DEDUCT_FROM_PLANNED_TIME === this.termin()?.terminType?.workTimeType ?
            'PERSONAL.TERMIN.ABWESENHEIT' :
            'PERSONAL.TERMIN.SINGULAR');

    public readonly modes: TerminUpdateMode[] = [
        TerminUpdateMode.ALL,
        TerminUpdateMode.ALL_FUTURE,
        TerminUpdateMode.SINGLE,
    ];
    public updateMode: TerminUpdateMode = TerminUpdateMode.SINGLE;
    public readonly angestellteState = ANGESTELLTE_PROFIL_STATE;

    public readonly trackByIdentity = trackByIdentity;
    public readonly trackById = trackById;

    @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();
    @Output() public readonly delete: EventEmitter<{
        termin: Termin;
        mode: TerminUpdateMode;
        updateDate: BackendLocalDate;
    }> = new EventEmitter();

    public angestellte$ = toObservable(this._dialogData).pipe(
        filter(dialogData => dialogData.open),
        switchMap(dialogData => {
            if (dialogData.termin?.angestellteIds.length && dialogData.termin.angestellteIds.length > 1) {
                const observables = dialogData.termin?.alleKinderOrte
                    ? [this.angestellteService.getAll$({angestellte: {}})]
                    : dialogData.termin.kinderOrtIds.map(kinderOrtId => {
                        return this.angestellteService.getAll$({
                            angestellte: {kinderOrtId},
                        });
                    });

                return combineLatest(observables).pipe(
                    map(results => {
                        return dialogData.termin?.angestellteIds
                            .map(id => {
                                return Angestellte.apiResponseTransformer(results
                                    .flatMap(r => r.items)
                                    .find(angestellter => angestellter.id === id));
                            });
                    }),
                    catchError(() => []),
                );
            }

            return of([]);
        }),
    );

    @Input({required: true})
    public set dialogData(data: TerminUpdateModeDialogData) {
        this._dialogData.set(data);
    }

    public submitForm(): void {
        const updateDate = DvbRestUtil.momentToLocalDateChecked(this._dialogData().date);
        this.delete.emit({termin: checkPresent(this.termin()), mode: this.updateMode, updateDate});
    }

    private getTitle(entity: string, termin?: Termin): string {
        if (!termin) {
            return '';
        }

        return termin.alleAngestellte ?
            this.translator.instant('PERSONAL.TERMIN.DELETE_TITLE_ALL', {
                entity: this.translator.instant(entity),
            }) : this.translator.instant('PERSONAL.TERMIN.DELETE_TITLE', {
                entity: this.translator.instant(entity),
            });
    }

    private getSubtitle(entity: string, termin?: Termin): string {
        if (!termin) {
            return '';
        }

        if (termin.alleAngestellte) {
            return this.translator.instant('PERSONAL.TERMIN.DELETE_ALL_SUBTITLE', {
                entity: this.translator.instant(entity),
            });
        }

        if (termin.angestellteIds.length > 1) {
            return this.translator.instant('PERSONAL.TERMIN.DELETE_SOME_SUBTITLE', {
                entity: this.translator.instant(entity),
            });
        }

        return '';
    }
}
