/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        id: '<',
        isDraggable: '<',
    },
    template: require('./dvb-draggable-element.html'),
    controllerAs: 'vm',
};

export class DvbDraggableElement implements angular.IController {
    public static $inject: readonly string[] = [
        '$element',
    ];

    public id: string = '';
    public isDraggable: boolean = true;

    public constructor(
        private $element: angular.IAugmentedJQuery,
    ) {
    }

    public $onInit(): void {
        if (!this.isDraggable) {
            return;
        }
        this.$element.on('dragstart', (e: JQuery.DragStartEvent) => {
            this.dragStart(e);
        });
        this.$element.on('dragend', (e: JQuery.DragEndEvent) => {
            this.dragEnd(e);
        });
    }

    public dragStart(event: JQuery.DragEventBase): void {
        this.setOpacity(event, '0.5');
        const originalEvent = event.originalEvent;
        originalEvent?.dataTransfer!.setData('text', this.id);
    }

    public dragEnd(event: JQuery.DragEventBase): void {
        this.setOpacity(event, '');
    }

    private setOpacity(event: JQuery.DragEventBase, opacity: string): void {
        // store a ref on the dragged element
        const dragged = event.target as HTMLElement;
        // reset the transparency
        dragged.style.opacity = opacity;
    }
}

componentConfig.controller = DvbDraggableElement;
angular.module('kitAdmin').component('dvbDraggableElement', componentConfig);
