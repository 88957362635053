<div class="dvb-filter-toggler hidden-print">
    <p>
        <a href="" ng-show="vm.showReset" ng-click="vm.onReset()" class="normal-abstand-right">
            <i class="fa fa-share"></i>
            <span data-translate="COMMON.RESET_FILTER"></span>
        </a>
        <a ng-if="vm.collapsible" href="" ng-click="vm.onToggle()">
            <span ng-show="vm.isCollapsed">
                <span data-translate="COMMON.ADD_FILTER"></span>
                <i class="fa fa-plus"></i>
            </span>
            <span ng-show="!vm.isCollapsed">
                <span data-translate="COMMON.CLOSE_FILTER"></span>
                <i class="fa fa-times"></i>
            </span>
        </a>
    </p>
</div>
