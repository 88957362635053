<div class="dvb-schliesstage dvb-form">

    <div class="row form-row">
        <div class="col-md-7">
            <label>
                <span ng-bind="vm.schliesstage.name"></span>
            </label>
        </div>
    </div>

    <h3 data-translate="COMMON.SCHLIESSTAGE.TITLE_SCHLIESSTAG_DATE_RANGES"></h3>

    <div class="row">
        <div class="col-xs-12">
            <p ng-if="vm.schliesstage.schliesstagDateRanges.length === 0"
               data-translate="COMMON.SCHLIESSTAGE.KEIN_ZEITRAUM_DEFINIERT">
            </p>
        </div>
    </div>

    <div ng-repeat="dateRange in vm.schliesstage.schliesstagDateRanges | orderBy: 'gueltigAb'"
         class="row">
        <div class="col-xs-10">
            <p data-translate="COMMON.DATERANGE"
               data-translate-value-von="{{dateRange.gueltigAb | amDateFormat: 'DD.MM.YYYY'}}"
               data-translate-value-bis="{{dateRange.gueltigBis | amDateFormat: 'DD.MM.YYYY'}}"></p>
        </div>
    </div>
</div>
