<div class="dvb-period-selector">
    <ul class="list-inline">
        <li data-translate="COMMON.VON"></li>
        <li>
            <dvb-date-switcher mode="month"
                               ng-model="vm.currentGueltigkeit.gueltigAb"
                               ng-change="vm.update()"
                               gueltig-bis="vm.currentGueltigkeit.gueltigBis"
                               display-format="DD. MMM YYYY"
                               debounce="0">
            </dvb-date-switcher>
        </li>
        <li data-translate="COMMON.BIS"></li>
        <li>
            <dvb-date-switcher mode="month"
                               ng-model="vm.currentGueltigkeit.gueltigBis"
                               ng-change="vm.update()"
                               gueltig-ab="vm.gueltigAbEndOfMonth"
                               dvb-end-of-month-formatter
                               dvb-end-of-month-parser
                               display-format="DD. MMM YYYY"
                               debounce="0">
            </dvb-date-switcher>
        </li>
    </ul>
</div>
