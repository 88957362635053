/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {UploadTempBlob} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {BlobUploadService} from '../../service/rest/blobUploadService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        files: '=',
        allowMultiple: '<',
        disableDownload: '<',
        labelFileUpload: '@',
        labelNoFiles: '@',
        onAddFile: '&',
        onRemoveFile: '&',
        onUploadSuccess: '&',
        onUploadError: '&',
        onClick: '&',
    },
    template: require('./dvb-file-list.html'),
    controllerAs: 'vm',
};

class DvbFileList implements angular.IController {
    public static $inject: readonly string[] = [
        'blobUploadService',
        'errorService',
        '$attrs',
        '$element',
        '$scope',
    ];

    public files: UploadTempBlob[] | null = null;
    public allowMultiple: boolean = true;
    public disableDownload: boolean = false;
    public labelFileUpload: string = '';
    public labelNoFiles: string = '';
    public isDisabled: boolean = false;
    public onAddFile!: FunctionType;
    public onRemoveFile!: FunctionType;
    public onUploadSuccess!: FunctionType;
    public onUploadError!: FunctionType;
    public onClick!: FunctionType;

    public constructor(
        private blobUploadService: BlobUploadService,
        private errorService: ErrorService,
        private $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
    }

    public uploadFiles(files: any[]): void {
        files.forEach(file => {
            const uploadTempBlob = new UploadTempBlob();
            uploadTempBlob.file = file;
            if (TypeUtil.isFunction(this.onAddFile)) {
                this.onAddFile({file: uploadTempBlob});
            }
            this.uploadFile(uploadTempBlob);
        });
    }

    private uploadFile(tempBlob: UploadTempBlob): void {
        this.blobUploadService.uploadBlob(tempBlob).then(() => {
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_FILE_TOO_LARGE');
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_UPLOAD_FAILED');

            if (TypeUtil.isFunction(this.onUploadSuccess)) {
                this.onUploadSuccess({file: tempBlob});
            }
        }).catch(responseFile => {
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_BADREQUEST');
            this.errorService.addValidationError(responseFile.errorMessage);

            if (TypeUtil.isFunction(this.onUploadError)) {
                this.onUploadError({file: tempBlob});
            }
        });
    }

}

componentConfig.controller = DvbFileList;
angular.module('kitAdmin').component('dvbFileList', componentConfig);
