/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Environment} from '@dv/kitadmin/models';
import {DvbUtil, hasOwnPropertyGuarded, isDvbError, isPresent} from '@dv/shared/code';
import {initSentry, isSentryEnabled} from '@dv/shared/sentry';
import type {EventHint} from '@sentry/angular-ivy';
import type {ErrorEvent} from '@sentry/types/types/event';
import type angular from 'angular';
import {AngularJsIntegration} from './sentry/sentry-angularjs';

export function initErrorTracking(ENVIRONMENT: Environment): void {
    initSentry({
        dsn: ENVIRONMENT.SENTRY_DSN,
        version: ENVIRONMENT.VERSION,
        environment: ENVIRONMENT.SENTRY_ENVIRONMENT,
        tracesSampleRate: ENVIRONMENT.SENTRY_TRACES_SAMPLE_RATE,
        integrations: [new AngularJsIntegration(ENVIRONMENT)],
        beforeSend: shouldSendSentryCallback,
    });
}

function hasStringMessage(data: unknown): data is { message: string } {
    return hasOwnPropertyGuarded(data, 'message') && typeof data.message === 'string';
}

/**
 * Decides if the sentry event should be reported.
 */
function shouldSendSentryCallback(data: ErrorEvent, hint: EventHint): ErrorEvent | null {
    if (!isSentryEnabled()) {
        return null;
    }

    const hasMessage = hasStringMessage(data);
    if (hasMessage && data.message.includes('Website currently not available')) {
        // backend redeploying
        return null;
    }

    // If the message starts with the message from AngularJS about unhandled rejections, we try to parse the error
    // object. Sadly there is no other way than parsing strings to get to it. If you know a way, feel free to change
    // this.
    const possiblyUnhandledRejectionMessage = 'Possibly unhandled rejection: ';

    if (!DvbUtil.isNotEmptyString(hint.originalException)) {
        return data;
    }

    if (!hint.originalException.startsWith(possiblyUnhandledRejectionMessage)) {
        return data;
    }

    const text = hint.originalException.substring(possiblyUnhandledRejectionMessage.length);

    if (!text) {
        return data;
    }

    try {
        const errorObj = JSON.parse(text);

        if (isIHttpResponse(errorObj)) {
            // If the error is a angular.IHttpResponse<T>, then we do not want a sentry callback to happen.
            // These errors appear when a request had a timeout, was aborted or the network failed (e.g. there is no
            // Internet connection)
            return null;
        }

        // Do not send callback if the error resembles a DvbError.
        // If it is a DvbError, we have handled it already and got an event
        // on the backend or simply don't care about it.
        return isDvbError(errorObj) ? null : data;
    } catch {
        return data;
    }
}

function isIHttpResponse<T>(errorObj: unknown): errorObj is angular.IHttpResponse<T> {
    return typeof errorObj === 'object'
        && isPresent(errorObj)
        && hasOwnPropertyGuarded(errorObj, 'config')
        && hasOwnPropertyGuarded(errorObj, 'xhrStatus');
}
