<div class="dvb-load-or-create-kontaktperson">
    <div class="row form-row">
        <div class="col-md-12">
            <dvb-search-on-click-entity add-label-key="KIND.KONTAKTPERSON_HINZUFUEGEN"
                                        entity-to-search="KONTAKTPERSON"
                                        on-select="vm.loadKontaktFromExistingKontaktperson(item)"
                                        on-select-clear="true"
                                        disabled-entries="vm.kontaktpersonen"
                                        placeholder-key="COMMON.KONTAKTPERSON_AUSWAEHLEN">
            </dvb-search-on-click-entity>
        </div>
    </div>
    <div class="row form-row">
        <div class="col-md-12">
            <a href=""
               ng-click="vm.initNewKontakt()">
                + <span data-translate="KIND.KONTAKTPERSON_ERFASSEN_LINK"></span>
            </a>
        </div>
    </div>
</div>
