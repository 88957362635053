/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import type {Translation} from '@dv/kitadmin/models';
import {getTextForLanguage} from './get-text-for-language';

@Pipe({
    name: 'dvbI18n',
    standalone: true,
})
export class DvbI18nPipe implements PipeTransform {

    public transform(value: Translation): string {
        return getTextForLanguage(value);
    }
}
