/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {DATA_INTEGRITY_STATES} from './data-integrity-states';

angular.module('kitAdmin').config(dataIntegrityConfig);

export * from './component/dvb-data-integrity/dvb-data-integrity';
export * from './component/dvb-malformed-phone-numbers/dvb-malformed-phone-numbers';
export * from './component/dvb-missing-geschlecht-or-sprache/dvb-missing-geschlecht-or-sprache';
export * from './component/dvb-update-geschlecht-or-sprache/dvb-update-geschlecht-or-sprache';
// Add New Component JS Above

dataIntegrityConfig.$inject = ['$stateProvider'];

function dataIntegrityConfig($stateProvider: StateProvider): void {
    Object.values(DATA_INTEGRITY_STATES).forEach(state => $stateProvider.state(state));
}
