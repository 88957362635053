<form name="changePassword"
      class="dvb-change-password"
      ng-submit="vm.validateAndSubmitPassword(changePassword.$valid)"
      novalidate>

    <label ng-hide="vm.formContext.editMode"
           ng-click="vm.togglePasswordForm()"
           class="form-control click2edit">*******</label>

    <div ng-if="vm.formContext.editMode">
        <input type="password"
               class="form-control input-large current-password-field"
               maxlength="255"
               ng-model="vm.password.currentPassword"
               ng-attr-placeholder="{{'BENUTZER.CURRENT_PASSWORD' | translate}}"
               uib-tooltip="{{'BENUTZER.CURRENT_PASSWORD' | translate}}"
               tooltip-placement="top-left"
               required>

        <input type="password"
               class="form-control input-large"
               maxlength="255"
               ng-model="vm.password.newPassword"
               ng-attr-placeholder="{{'BENUTZER.NEW_PASSWORD' | translate}}"
               uib-tooltip="{{'BENUTZER.NEW_PASSWORD' | translate}}"
               tooltip-placement="top-left"
               required>

        <input type="password"
               class="form-control input-large"
               maxlength="255"
               ng-model="vm.password.confirmedPassword"
               ng-attr-placeholder="{{'BENUTZER.CONFIRM_PASSWORD' | translate}}"
               uib-tooltip="{{'BENUTZER.CONFIRM_PASSWORD' | translate}}"
               tooltip-placement="top-left"
               required>

        <dvb-submit-cancel-buttons is-loading="vm.formContext.isLoading"
                                   submit-label="BENUTZER.PASSWORD_AENDERN"
                                   on-cancel="vm.togglePasswordForm()">
        </dvb-submit-cancel-buttons>
    </div>
</form>
