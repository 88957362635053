/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, Kontaktperson, LimitedAdresse} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Adresse} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import {from, take} from 'rxjs';
import type {AddressService} from '../../../service/rest/addressService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        limitedAdressen: '<',
        kontaktpersonId: '<',
        kindId: '<',
        onUpdateLimitedAdresse: '&',
        onDeleteLimitedAdresse: '&',
    },
    template: require('./dvb-adresse-verlauf.html'),
    controllerAs: 'vm',
};

type MitbewohnerVM = {
    kinder: Kind[];
    kontaktpersonen: Kontaktperson[];
    showMitbewohner: boolean;
};

function toMitbewohner(kinder: Kind[], kontaktpersonen: Kontaktperson[]): MitbewohnerVM {
    return {
        kinder: DvbUtil.orderByDisplayName(kinder),
        kontaktpersonen: DvbUtil.orderByDisplayName(kontaktpersonen),
        showMitbewohner: kinder.length > 0 || kontaktpersonen.length > 0,
    };
}

export class DvbAdresseVerlauf implements angular.IController {

    public static $inject: readonly string[] = ['errorService', 'addressService', 'dialogService', '$q'];

    public limitedAdressen: LimitedAdresse[] = [];
    public kontaktpersonId!: EntityId;
    public kindId!: EntityId;
    public onUpdateLimitedAdresse!: (data: { limitedAdresse: LimitedAdresse; adresse: Adresse }) => unknown;
    public onDeleteLimitedAdresse!: (data: { limitedAdresse: LimitedAdresse }) => unknown;

    public today = DvbDateUtil.today();
    public mitbewohner: { [limitedAdresseId: EntityId]: MitbewohnerVM } = {};

    public constructor(
        private readonly errorService: ErrorService,
        private readonly addressService: AddressService,
        private readonly dialogService: DialogService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (this.kindId && this.kontaktpersonId) {
            throw new Error('Expected kindId or kontaktpersonId, but not both');
        }

        this.mitbewohner = {};
        if (Array.isArray(this.limitedAdressen)) {
            this.limitedAdressen.forEach(l => this.getMitbewohner(l));
        }
    }

    public getMitbewohner(limitedAdresse: LimitedAdresse): void {
        const params = {
            gueltigAb: limitedAdresse.gueltigAb,
            gueltigBis: limitedAdresse.gueltigBis,
        };
        this.addressService.getMitbewohner(checkPresent(limitedAdresse.adresse.id), params).then(m => {
            const kinder = m.kinder.filter(k => k.id !== this.kindId);
            const kontaktpersonen = m.kontaktpersonen.filter(k => k.id !== this.kontaktpersonId);
            this.mitbewohner[checkPresent(limitedAdresse.id)] = toMitbewohner(kinder, kontaktpersonen);
        });
    }

    public getLimitedAdresseTranslationKey(limitedAdresse: LimitedAdresse): string {
        return DvbDateUtil.isGueltigOn(limitedAdresse, this.today) ?
            'COMMON.AKTUELLE_WOHNADRESSE_MIT_DATUMPREP' :
            'COMMON.WOHNADRESSE_MIT_DATUMPREP';
    }

    public temporalPrepositionKey(limitedAdresse: LimitedAdresse): string | null {
        return DvbDateUtil.getTemporalPrepositionKey(limitedAdresse, this.today);
    }

    public showConfirmDeleteAdresseModal(limitedAdresse: LimitedAdresse): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.ADRESSE',
            confirm: () => from(this.deleteAdresse(limitedAdresse))
                .pipe(take(1)),
        });
    }

    public deleteAdresse(limitedAdresse: LimitedAdresse): angular.IPromise<unknown> {
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
        if (TypeUtil.isFunction(this.onDeleteLimitedAdresse)) {
            return this.$q.when(this.onDeleteLimitedAdresse({limitedAdresse}));
        }

        return this.$q.resolve();
    }

    public onSaveAdresse(adresse: Adresse, limitedAdresse: LimitedAdresse): void {
        if (angular.equals(limitedAdresse.adresse, adresse)) {
            return;
        }

        if (TypeUtil.isFunction(this.onUpdateLimitedAdresse)) {
            this.onUpdateLimitedAdresse({limitedAdresse, adresse});
        }
    }
}

componentConfig.controller = DvbAdresseVerlauf;
angular.module('kitAdmin').component('dvbAdresseVerlauf', componentConfig);
