<div class="dvb-date-switcher">
    <ul class="list-inline header-date">
        <li class="hidden-print">
            <a href="" ng-show="vm.hasPrevious()" ng-click="vm.setPrevious()"><i class="fa fa-chevron-left"></i></a>
        </li>
        <li class="has-datepicker date-navigation"
            ng-class="{
              'year-width': vm.mode === 'year',
              'week-width': vm.mode === 'week',
              'month-width': vm.mode === 'month',
              'day-width': vm.mode === 'day'
            }">

            <a href="" ng-click="vm.toggleDatePicker()">
                <span ng-if="vm.displayFormat"
                      ng-bind="vm.ngModelCtrl.$viewValue | amDateFormat: vm.displayFormat">
                </span>
                <span ng-if="!vm.displayFormat" ng-switch="vm.mode">
                    <span ng-switch-when="week"
                          data-translate="COMMON.SHORT_KALENDERWOCHE_VON_BIS"
                          data-translate-value-date-ab="{{vm.ngModelCtrl.$viewValue | amDateFormat: 'W[, ]DD. MMM'}}"
                          data-translate-value-date-bis="{{vm.getLastOfWeek() | amDateFormat: 'DD. MMM YYYY'}}">
                    </span>
                    <span ng-switch-when="year"
                          ng-bind="vm.ngModelCtrl.$viewValue | amDateFormat: 'YYYY'">
                    </span>
                    <span ng-switch-when="month"
                          ng-bind="vm.ngModelCtrl.$viewValue | amDateFormat: 'MMMM YYYY'">
                    </span>
                    <span ng-switch-when="day"
                          ng-bind="vm.ngModelCtrl.$viewValue | amDateFormat: 'dd, DD. MMM YYYY'">
                    </span>
                </span>
            </a>

            <input type="text"
                   style="display:none;"
                   uib-datepicker-popup="dd.MM.yyyy"
                   data-datepicker-append-to-body="false"
                   data-datepicker-popup-template-url="dvb-datepicker-text-field-popup-template.html"
                   data-datepicker-show-button-bar="false"
                   ng-change="vm.onDateChanged()"
                   ng-model="vm.datePicker.date"
                   data-is-open="vm.datePicker.isOpen"
                   data-datepicker-options="vm.datePickerOptions">
        </li>
        <li class="hidden-print">
            <a href="" ng-show="vm.hasNext()" ng-click="vm.setNext()"><i class="fa fa-chevron-right"></i></a>
        </li>
    </ul>
</div>
