/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Environment} from '@dv/kitadmin/models';
import {SHARED_TRANSLATIONS} from '@dv/shared/angular';
import {XSRF_COOKIE_NAME, XSRF_HEADER_NAME} from '@dv/shared/authentication/model';
import {SUPPORTED_LOCALES} from '@dv/shared/code';
import type {UIRouter} from '@uirouter/core';
import type angular from 'angular';
import {CONFIG} from '../../../config';
import {DASHBOARD_STATE} from '../../dashboard/dashboard-state';
import {addCustomUrlParameterTypes} from './url-parameter-types/urlParameterTypes';

config.$inject = ['$locationProvider', '$uiRouterProvider', '$httpProvider', '$translateProvider', 'ENVIRONMENT'];

export function config(
    $locationProvider: angular.ILocationProvider,
    $uiRouterProvider: UIRouter,
    $httpProvider: angular.IHttpProvider,
    $translateProvider: angular.translate.ITranslateProvider,
    ENVIRONMENT: Environment,
): void {

    $locationProvider.html5Mode(true);

    // Inject auth token into each request
    $httpProvider.interceptors.push('authHttpInterceptor', 'httpErrorInterceptor', 'versionInterceptor');
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.xsrfHeaderName = XSRF_HEADER_NAME;
    $httpProvider.defaults.xsrfCookieName = XSRF_COOKIE_NAME;

    // Redirect unmatched URLs to /dashboard
    $uiRouterProvider.urlService.rules.otherwise(() => {
        // since we're not on any state, any query parameters must be fetched from the url directly
        const params = $uiRouterProvider.locationService.search();
        $uiRouterProvider.stateService.go(DASHBOARD_STATE.name, params);
    });

    ENVIRONMENT.uiRouterTracing.forEach(category => $uiRouterProvider.trace.enable(category));
    addCustomUrlParameterTypes($uiRouterProvider.urlService.config);

    // In case you have issues with double-escaped parameters, check out this issue:
    // https://github.com/angular-translate/angular-translate/issues/1101
    $translateProvider
        .useSanitizeValueStrategy('escapeParameters')
        .addInterpolation('$translateMessageFormatInterpolation')
        .translations('de-CH', SHARED_TRANSLATIONS.de)
        .translations('fr-CH', SHARED_TRANSLATIONS.fr)
        .fallbackLanguage(CONFIG.defaultLocale)
        .preferredLanguage(CONFIG.defaultLocale)
        .useMissingTranslationHandlerLog()
        .useLocalStorage();

    $translateProvider.registerAvailableLanguageKeys(SUPPORTED_LOCALES.values, {
        'de_*': 'de-CH',
        'fr_*': 'fr-CH',
    });
}
