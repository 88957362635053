/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtId, RestCache} from '@dv/kitadmin/models';
import type {
    MatrixParams,
    PageContainer,
    Persisted,
    RestInclude,
    RestLimited,
    RestPaginated,
    TerminationMode,
} from '@dv/shared/code';
import {checkPersisted, checkPresent, Displayable, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../../config';
import {AngestellteKorrespondenz} from '../../../communication/models/AngestellteKorrespondenz';
import type {KinderOrtBedarf} from '../../bedarf/models/KinderOrtBedarf';
import {Angestellte} from '../models/Angestellte';
import {Anstellung} from '../models/Anstellung';

type RestParameters = RestInclude & RestLimited & angular.IRequestShortcutConfig;

export class AngestellteService {
    public static $inject: readonly string[] = ['$http', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/angestellte`;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly $q: angular.IQService,
    ) {
    }

    public get(angestellteId: string, params?: RestInclude & RestCache): angular.IPromise<Persisted<Angestellte>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(
            AngestellteService.BASE_URL,
            Angestellte,
            angestellteId,
            params,
        ).then(checkPersisted);
    }

    /**
     * @return the kinderOrtBedarfs bezugspersonen as displayable angestellte
     */
    public getAsDisplayableFromBedarf(
        kinderOrtBedarf: KinderOrtBedarf,
    ): angular.IPromise<{ [id: string]: Displayable }> {
        const bezugsPersonenIds = kinderOrtBedarf.fraktionsBedarf
            .flatMap(f => f.dienstBedarf)
            .flatMap(b => b.bezugsPersonIds);

        const angestellteIds = DvbUtil.uniqueArray(bezugsPersonenIds);
        const config = {cache: true};

        return this.$q.all(angestellteIds.map(id => this.getAsDisplayable(id, config)))
            .then(displayable => {
                const angestellteById: { [id: string]: Displayable } = {};
                displayable.forEach(d => angestellteById[d.id] = d);

                return angestellteById;
            });
    }

    public getAsDisplayable(
        angestellteId: string,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Displayable> {
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/displayable`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Displayable, {}, config)
            .then(checkPresent);
    }

    public getAll(params?: RestParameters): angular.IPromise<Angestellte[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        return DvbRestUtilAngularJS.getPagedItems(AngestellteService.BASE_URL, Angestellte, matrixParams, params)
            .then(response => response.items);
    }

    public getForKinderOrt(kinderOrtId: KinderOrtId, params?: RestParameters): angular.IPromise<Angestellte[]> {

        const matrixParams: MatrixParams = {kinderOrtId};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        if (params?.includes) {
            matrixParams.includes = params.includes;
        }

        return DvbRestUtilAngularJS.getPagedItems(AngestellteService.BASE_URL, Angestellte, matrixParams, params)
            .then(response => response.items);
    }

    public getForKinderOrtWithZuweisungen(
        kinderOrtId: KinderOrtId,
        params?: RestParameters,
    ): angular.IPromise<Angestellte[]> {

        const matrixParams: MatrixParams = {kinderOrtId};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        if (params?.includes) {
            matrixParams.includes = params.includes;
        }

        const url = `${AngestellteService.BASE_URL}/zugewiesen`;

        return DvbRestUtilAngularJS.getPagedItems(url, Angestellte, matrixParams, params)
            .then(response => response.items);
    }

    public create(angestellte: Angestellte): angular.IPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(AngestellteService.BASE_URL, angestellte.toRestObject());
    }

    public update(angestellte: Angestellte): angular.IPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();
        const angestellteId = checkPresent(angestellte.id);
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}`;

        return this.$http.put(url, angestellte.toRestObject());
    }

    public delete(angestellteId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}`;

        return this.$http.delete(url);
    }

    public createAnstellung(angestellteId: string, anstellung: Anstellung): angular.IPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/anstellung`;

        return this.$http.post(url, anstellung.toRestObject());
    }

    public getAnstellungen(
        angestellteId: string,
        params?: angular.IRequestShortcutConfig & RestInclude & RestCache,
    ): angular.IPromise<Anstellung[]> {
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/anstellung`;

        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(url,
            Anstellung,
            'anstellungen',
            matrixParams,
            !!params?.cache,
            params);
    }

    public terminateAnstellung(
        angestellteId: string,
        endDate: moment.Moment,
        mode: TerminationMode,
    ): angular.IPromise<unknown> {

        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/anstellung/enddate`;

        return DvbRestUtilAngularJS.terminate(url, endDate, mode);
    }

    public removeBenutzer(angestellteId: string | null): angular.IPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(checkPresent(angestellteId))}/benutzer`;

        return this.$http.delete(url);
    }

    public addBetreuungsPerson(angestellteId: string, fraktionId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/` +
            `betreuungsperson/${encodeURIComponent(fraktionId)}`;

        return this.$http.put(url, {});
    }

    public removeBetreuungsPerson(angestellteId: string, fraktionId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/` +
            `betreuungsperson/${encodeURIComponent(fraktionId)}`;

        return this.$http.delete(url);
    }

    public getKorrespondenz(
        angestellteId: string,
        params?: RestPaginated,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<AngestellteKorrespondenz>> {
        const url = `${AngestellteService.BASE_URL}/${encodeURIComponent(angestellteId)}/korrespondenz`;
        const matrixParams = {...params};

        return DvbRestUtilAngularJS.getPagedItems(url, AngestellteKorrespondenz, matrixParams, config);
    }
}
