/* eslint-disable max-lines */
/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    AnwesenheitCustomField,
    Belegung,
    BelegungenWithFraktionen,
    BelegungMaxGueltigkeit,
    Kind,
    KinderOrt,
    KinderOrtFraktion,
    KinderOrtId,
    MonatsBelegung,
} from '@dv/kitadmin/models';
import {isFirmenKontingent, PlatzTypen} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted, RestInclude, RestLimited} from '@dv/shared/code';
import {
    BelegungsZustand,
    checkPresent,
    DvbDateUtil,
    DvbRestUtil,
    DvbUtil,
    isPresent,
    LogFactory,
} from '@dv/shared/code';
import {shareState} from '@dv/shared/rxjs-utils';
import type {StateService, TransitionOptions, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import type {Observable, Subscription} from 'rxjs';
import {
    combineLatest,
    finalize,
    forkJoin,
    from,
    lastValueFrom,
    map,
    mergeMap,
    of,
    ReplaySubject,
    Subject,
    switchMap,
    take,
    tap,
} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {MonatsBelegungRestService} from '../../../common/service/rest/kind/monatsBelegungRestService';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import type {KinderOrtSchliesstageService} from '../../../common/service/rest/kinderort/kinderOrtSchliesstageService';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import type {BringAbholZeitenAccess} from '../../../communication/models/bring-abhol-zeiten/BringAbholZeitenAccess';
import {ReleaseType} from '../../../communication/models/ReleaseType';
import type {BringAbholZeitenService} from '../../../communication/service/bring-abhol-zeiten-service';
import type {AngestellteService} from '../../../personal/anstellung/service/angestellteService';
import type {SchliesstagDateRange} from '../../../schliesstage/models/SchliesstagDateRange';
import type {AnwesenheitsSollService} from '../../anwesenheitssoll/anwesenheits-soll.service';
import type {AnwesenheitsSollVerbrauch} from '../../anwesenheitssoll/model/AnwesenheitsSollVerbrauch';
import type {MonatsBelegungService} from '../../service/monatsBelegungService';
import type {ZuweisenUtil} from '../../service/zuweisenUtil';
import type {MonatsBelegungInputRow} from '../dvb-monats-belegung/MonatsBelegungInputRow';
import {ZuweisenFormModel} from '../dvb-zuweisen-form/ZuweisenFormModel';
import {ZuweisungPopoverHelper} from '../zuweisung-popover-helper';
import type {
    BringAbholZeitenReleaseDialogModel,
} from './bring-abhol-zeiten-release/bring-abhol-zeiten-release.component';
import {BringAbholZeitenReleaseComponent} from './bring-abhol-zeiten-release/bring-abhol-zeiten-release.component';

const LOG = LogFactory.createLog('DvbKindMonatZuweisen');

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        kita: '<',
        gruppe: '<',
        zuweisungAb: '<',
        customFields: '<',
    },
    template: require('./dvb-kind-monat-zuweisen.html'),
    controllerAs: 'vm',
};

export class DvbKindMonatZuweisen implements angular.IOnInit, angular.IController, angular.IOnDestroy {

    public static $inject: readonly string[] = [
        'errorService',
        'dvbStateService',
        '$state',
        'kinderOrtService',
        'kindService',
        'monatsBelegungService',
        'fraktionService',
        '$document',
        '$scope',
        'zuweisenUtil',
        '$q',
        '$translate',
        'dialogService',
        'monatsBelegungRestService',
        '$uiRouterGlobals',
        'bringAbholZeitenService',
        'kinderOrtSchliesstageService',
        'angestellteService',
        'authStore',
        'anwesenheitsSollService',
    ];

    public kind!: Persisted<Kind>;
    public kita!: Persisted<KinderOrt>;
    public gruppe!: KinderOrtFraktion;
    public zuweisungAb!: moment.Moment;
    public customFields: AnwesenheitCustomField[] = [];

    public isLoading: boolean = false;
    public zuweisenFormModel: ZuweisenFormModel = new ZuweisenFormModel();
    public inputRows: MonatsBelegungInputRow[] = [];
    public popoverHelper!: ZuweisungPopoverHelper;

    public bringAbholZeitenAccess?: BringAbholZeitenAccess | null;
    public accessControlText: string = '';
    public accessControlDate: moment.Moment | null = null;
    public releaseForParentAllowed: boolean = false;

    public anwesenheitsSollVerbrauch: AnwesenheitsSollVerbrauch | null = null;

    private startOfMonth!: moment.Moment;
    private endOfMonth!: moment.Moment;

    private monatChangeSource$ = new ReplaySubject<{
        startOfMonth: moment.Moment;
        endOfMonth: moment.Moment;
    }>(1);

    private selectedKinderOrt$ = new ReplaySubject<Persisted<KinderOrt>>(1);
    private belegungen$ = new ReplaySubject<Belegung[]>(1);
    private belegungenInMonth$ = combineLatest([this.belegungen$, this.monatChangeSource$]).pipe(
        map(([b, {startOfMonth, endOfMonth}]) => DvbDateUtil.getEntitiesIn(b, startOfMonth, endOfMonth)),
    );

    private affectedKinderOrte$ = combineLatest([
        this.belegungenInMonth$,
        this.selectedKinderOrt$.pipe(map(k => k.id)),
    ]).pipe(
        switchMap(([belegungen, selectedId]) => {
            if (belegungen.length === 0) {
                return of([selectedId]);
            }

            return from(this.fraktionService.fetchKinderOrteForBelegungen(belegungen)).pipe(
                map(kinderOrte => kinderOrte.map(DvbUtil.mapToIdChecked)),
                map(ids => ids.includes(selectedId) ? ids : [selectedId, ...ids]),
            );
        }),
        shareState(),
    );

    private gruppen$ = combineLatest([
        this.selectedKinderOrt$,
        this.belegungenInMonth$,
        this.monatChangeSource$,
    ]).pipe(
        mergeMap(([kinderOrt, belegungen, {startOfMonth, endOfMonth}]) => {
            const gruppenKita = DvbDateUtil.getEntitiesIn(kinderOrt.gruppen, startOfMonth, endOfMonth);
            if (belegungen.length === 0) {
                return of(gruppenKita);
            }

            const gruppenIdsBelegung = DvbUtil.uniqueArray(
                belegungen.flatMap(b => b.gruppenBelegungen.map(g => checkPresent(g.gruppeId))),
            );
            const gruppenFromBelegungen$ = forkJoin(
                gruppenIdsBelegung.map(id => this.fraktionService.getWithWochenplan(id, {cache: true})),
            );

            return gruppenFromBelegungen$.pipe(
                map(gb => DvbUtil.uniqueArray([...gruppenKita, ...gb], DvbUtil.mapToId)),
            );
        }),
        shareState(),
    );

    private schliesstage$ = combineLatest([
        this.affectedKinderOrte$,
        this.monatChangeSource$,
    ]).pipe(
        mergeMap(([kinderOrtIds, {startOfMonth, endOfMonth}]) => {
            const params = {gueltigAb: startOfMonth, gueltigBis: endOfMonth};

            return forkJoin(kinderOrtIds.map(id => from(this.kinderOrtSchliesstageService.getForRange(id, params)).pipe(
                map(schliesstage => ({kinderOrtId: id, schliesstage})),
            )));
        }),
        map(results => results.reduce((result, {kinderOrtId, schliesstage}) => result.set(kinderOrtId,
            schliesstage), new Map<KinderOrtId, SchliesstagDateRange[]>())),
        shareState(),
    );

    private inputRows$: Observable<MonatsBelegungInputRow[]> = combineLatest([
        this.monatChangeSource$,
        this.selectedKinderOrt$,
        this.gruppen$,
        this.schliesstage$,
    ]).pipe(
        map(([{startOfMonth}, selectedKita, gruppen, schliesstagDateRangesByKinderOrtId]) => {
            return this.monatsBelegungService.initInputRows(
                selectedKita,
                this.gruppe,
                gruppen,
                startOfMonth,
                this.customFields,
                schliesstagDateRangesByKinderOrtId);
        }),
    );

    private inputRowsWithPlaetze$ = combineLatest([
        this.monatChangeSource$,
        this.affectedKinderOrte$,
        this.inputRows$,
    ]).pipe(
        mergeMap(([{startOfMonth, endOfMonth}, ids, inputRows]) => {
            const params = {gueltigAb: startOfMonth, gueltigBis: endOfMonth};

            return forkJoin(
                ids.map(id => from(this.kinderOrtService.getGruppenWochenKapazitaeten(id, params)).pipe(
                        tap(gwb => {
                            inputRows.forEach(inputRow => inputRow.initBetreuungsZeitraumBelegungen(gwb));
                        }),
                    ),
                )).pipe(map(() => inputRows));
        }),
    );

    private initMonatsBelegung$ = this.monatChangeSource$.pipe(
        tap(() => {
            this.isLoading = true;
            this.inputRows = [];
        }),
        tap(() => this.loadAnwesenheitsSoll()),
        switchMap(() => this.inputRowsWithPlaetze$),
        switchMap(inputRows => from(this.loadBelegungen(inputRows))),
        tap(inputRows => {
            this.inputRows = inputRows;
        }),
        tap(() => this.loadBringAbholZeitenAccess()),
        tap(() => this.loadKindKontakte()),
        tap(() => {
            this.isLoading = false;
        }),
    );

    private subscription?: Subscription;

    public constructor(
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
        private $state: StateService,
        private kinderOrtService: KinderOrtService,
        private kindService: KindService,
        private monatsBelegungService: MonatsBelegungService,
        private fraktionService: FraktionService,
        private $document: angular.IDocumentService,
        private $scope: angular.IScope,
        private zuweisenUtil: ZuweisenUtil,
        private $q: angular.IQService,
        private $translate: angular.translate.ITranslateService,
        private dialogService: DialogService,
        private monatsBelegungRestService: MonatsBelegungRestService,
        private $uiRouterGlobals: UIRouterGlobals,
        private bringAbholZeitenService: BringAbholZeitenService,
        private kinderOrtSchliesstageService: KinderOrtSchliesstageService,
        private angestellteService: AngestellteService,
        private authStore: AuthStore,
        private anwesenheitsSollService: AnwesenheitsSollService,
    ) {
    }

    public $onInit(): void {
        this.popoverHelper = new ZuweisungPopoverHelper(this.$document, this.$scope);
        this.selectedKinderOrt$.next(this.kita);
        this.belegungen$.next(this.kind.belegungen);
        this.subscription = this.initMonatsBelegung$.subscribe();

        this.changeMonth();
    }

    public $onDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public changeMonth(): void {
        this.releaseForParentAllowed = moment().isSameOrBefore(this.zuweisungAb, 'month');

        const zuweisungAbSerialized = DvbRestUtil.momentToLocalDate(this.zuweisungAb);
        if (this.$uiRouterGlobals.params.zuweisungAb !== zuweisungAbSerialized) {

            // If the state params do not reflect the current date, we replace the current state.
            // Replacing the state only by changing the dynamic zuweisungAb parameter does not exit/reenter this state,
            // meaning this component will not be reloaded --> continue reloading and do not return here!
            const options: TransitionOptions = {location: 'replace', notify: true};
            this.$state.go('.', {zuweisungAb: zuweisungAbSerialized}, options);
        }

        const startOfMonth = DvbDateUtil.startOfMonth(this.zuweisungAb);
        const endOfMonth = DvbDateUtil.endOfMonth(moment(this.zuweisungAb));
        this.startOfMonth = startOfMonth;
        this.endOfMonth = endOfMonth;

        this.monatChangeSource$.next({startOfMonth, endOfMonth});
    }

    public zuweisen(): void {
        this.popoverHelper.closePopover();

        this.isLoading = true;
        this.errorService.clearAll();

        this.zuweisenUtil.validateZuweisung<MonatsBelegung>(this.zuweisungAb, this.kind, () =>
            this.monatsBelegungService.createMonatsBelegung(
                this.inputRows,
                this.startOfMonth,
                this.endOfMonth,
                this.zuweisenFormModel))
            .then(monatsBelegung => {
                const id = checkPresent(this.kind.id);

                // TODO dieses Performance Improvement kann leider zu ungültigen checks führen
                // Beispielsweise wenn eine Gruppe oder ein Kontingent mitten im Monat beendet/eröffnet wird
                // https://support.dvbern.ch/browse/KIT-3556
                const checkMonatsBelegung = this.monatsBelegungService.createGueltigkeitCheckBelegung(monatsBelegung);

                return this.kindService.getMaxGueltigBisForBelegung(id, checkMonatsBelegung)
                    .then(maxGueltigkeit => this.confirmGueltigkeit(monatsBelegung, maxGueltigkeit));
            })
            .catch(() => {
                // nop - rejected promise only signifies that we should not proceed, errors have been shown
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goBack(): Promise<unknown> {
        this.errorService.clearAll();

        return this.dvbStateService.goToPreviousState();
    }

    public releaseForParent(): void {
        this.openReleaseDialog(ReleaseType.RELEASE);
    }

    public resendForParent(): void {
        this.openReleaseDialog(ReleaseType.RESEND);
    }

    public adjustParentDeadline(): void {
        this.openReleaseDialog(ReleaseType.ADJUST_DEADLINE);
    }

    public withdrawFromParent(): void {
        this.openReleaseDialog(ReleaseType.WITHDRAW);
    }

    public doEmptyAnwesenheitsZeiten(): void {
        this.monatsBelegungService.doEmptyAnwesenheitsZeiten(this.inputRows);
    }

    private loadBringAbholZeitenAccess(): angular.IPromise<void> {
        return this.bringAbholZeitenService.getBringAbholZeitenAccess(this.kind.id,
            this.kita.id,
            this.startOfMonth,
            this.endOfMonth)
            .then(access => {
                this.initAccess(access);
            });
    }

    private initAccess(bringAbholZeitenAccess: BringAbholZeitenAccess): void {
        this.bringAbholZeitenAccess = bringAbholZeitenAccess;

        if (bringAbholZeitenAccess.hasWriteAccess()) {
            this.accessControlText = 'KIND.MONATSBELEGUNG.WRITE_UNTIL';
            this.accessControlDate = bringAbholZeitenAccess.writeAccessUntil;

            return;
        }

        this.accessControlText = 'KIND.MONATSBELEGUNG.READ_UNTIL';
        this.accessControlDate = bringAbholZeitenAccess.readAccessUntil;
    }

    private loadBelegungen(inputRows: MonatsBelegungInputRow[]): angular.IPromise<MonatsBelegungInputRow[]> {
        const id = checkPresent(this.kind.id);

        const params: RestInclude & RestLimited = {
            gueltigAb: this.startOfMonth,
            gueltigBis: this.endOfMonth,
            includes: '(gruppenBelegungen.fields(' +
                'gruppeId,vertraglichePensen,plaetze.fields(' +
                'kontingent.fields(firma),kontingentId,belegungsEinheit.fields(zeitraumIds))))',
        };

        // could probably by replaced with this.gruppen$ and this.belegungenInMonth$ - but then we probably don't have
        // all firmen...
        return this.kindService.getBelegungenWithFraktionen(id, params).then((belegungen: BelegungenWithFraktionen) => {
            if (belegungen.belegungen.length === 0) {
                return this.$q.resolve();
            }

            const sortedBelegungen = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(belegungen.belegungen);
            const belegung = sortedBelegungen[0];
            const firmen = this.kita.firmenKontingente.map(kontingent => kontingent.firma!);
            const firmenFromBelegungen = sortedBelegungen.flatMap(b => b.gruppenBelegungen)
                .flatMap(gb => gb.plaetze)
                .flatMap(p => p.kontingent)
                .filter(isPresent)
                .filter(isFirmenKontingent)
                .map(fk => checkPresent(fk.firma));

            const uniqueFirmen = DvbUtil.uniqueArray([...firmen, ...firmenFromBelegungen], DvbUtil.mapToId);

            const defaultPlatzTypen = this.monatsBelegungService.getDefaultPlatzTypen(sortedBelegungen, uniqueFirmen);

            inputRows.forEach(inputRow => inputRow.applyBelegungen(
                sortedBelegungen,
                uniqueFirmen,
                defaultPlatzTypen,
                belegungen.fraktionen));
            this.setBelegungProperties(belegung, defaultPlatzTypen);

            if (!belegung.monatsBelegungId) {
                return this.$q.resolve();
            }

            const monatsBelegungParams: RestInclude = {
                includes: '(anwesenheitsZeiten.fields(customFields),parentDefaults.fields(firma),customFields)',
            };

            return this.monatsBelegungRestService.getMonatsBelegung(belegung.monatsBelegungId, monatsBelegungParams)
                .then(monatsBelegung => {
                    this.monatsBelegungService.setAnwesenheitsZeiten(monatsBelegung.anwesenheitsZeiten, inputRows);
                    this.monatsBelegungService.setCustomFieldValues(monatsBelegung.customFieldValuesPerDate, inputRows);

                    this.zuweisenFormModel.standardVertraglichesPensum = monatsBelegung.standardVertraglichesPensum;
                    this.zuweisenFormModel.standardPlatzTypen =
                        PlatzTypen.fromKontingent(monatsBelegung.standardKontingent);
                });

        }).then(() => inputRows);
    }

    private setBelegungProperties(belegung: Belegung | null, defaultPlatzTypen: PlatzTypen): void {
        this.zuweisenFormModel =
            belegung ? ZuweisenFormModel.from(belegung, this.angestellteService) : new ZuweisenFormModel();
        this.zuweisenFormModel.standardPlatzTypen.from(defaultPlatzTypen);
    }

    private confirmGueltigkeit(
        monatsBelegung: MonatsBelegung,
        belegungMaxGueltigkeit: BelegungMaxGueltigkeit,
    ): angular.IPromise<boolean> {

        const maxGueltigkeit = belegungMaxGueltigkeit.maxGueltigBis;

        const shortenedBelegungen = this.monatsBelegungService
            .shortenBelegungen(monatsBelegung.belegungen, maxGueltigkeit);

        if (!this.monatsBelegungService.hasShortened(monatsBelegung.belegungen, shortenedBelegungen)) {
            // keine Änderung des belegung.gueltigBis
            return lastValueFrom(this.createMonatsBelegung$(monatsBelegung));
        }

        const title = this.$translate.instant('KIND.ZUWEISUNG.ZUWEISUNG_CONFIRM_ADJUSTED_GUELTIGKEIT', {
            gueltigbis: maxGueltigkeit.format('l'),
        });
        const confirm$: Subject<boolean> = new Subject();
        const confirm = (): Observable<unknown> => {
            monatsBelegung.belegungen = shortenedBelegungen;
            monatsBelegung.anwesenheitsZeiten = monatsBelegung.anwesenheitsZeiten.filter(anwesenheitsZeit =>
                checkPresent(anwesenheitsZeit.datum).isSameOrBefore(maxGueltigkeit));

            return this.createMonatsBelegung$(monatsBelegung)
                .pipe(tap(() => confirm$.next(true)));
        };

        this.dialogService.openConfirmDialog({
            title,
            subtitle: belegungMaxGueltigkeit.message,
            confirm,
            cancel: () => confirm$.next(false),
        });

        return lastValueFrom(confirm$.pipe(take(1)));
    }

    private createMonatsBelegung$(monatsBelegung: MonatsBelegung): Observable<any> {

        const create = (): Observable<any> =>
            from(this.kindService.updateMonatsBelegung(checkPresent(this.kind.id), monatsBelegung)).pipe(
                take(1),
                // reload, um die Belegungen neu zu laden
                switchMap(() => from(this.$state.reload())),
                switchMap(() => monatsBelegung.status === BelegungsZustand.BELEGT && this.kind.bewerbung ?
                    this.zuweisenUtil.showSuccessDeleteBewerbungModal$(this.kind)
                        .pipe(finalize(() => this.goBack())) : this.goBack(),
                ));

        if (BelegungsZustand.BELEGT === monatsBelegung.status
            && checkPresent(this.bringAbholZeitenAccess).hasReadAccess()) {

            return create().pipe(
                tap(() => this.openReleaseDialog(ReleaseType.CONFIRM)),
            );
        }

        return create();
    }

    private openReleaseDialog(type: ReleaseType): void {

        const dialogModel: BringAbholZeitenReleaseDialogModel = {
            kinder: [this.kind],
            kinderOrt: this.kita,
            type,
            periodFrom: this.startOfMonth,
            periodTo: this.endOfMonth,
            deadline: this.bringAbholZeitenAccess ? this.bringAbholZeitenAccess.writeAccessUntil : null,
            bringAbholZeitenService: this.bringAbholZeitenService,
        };

        this.dialogService.openDialog(BringAbholZeitenReleaseComponent, dialogModel);
    }

    private loadAnwesenheitsSoll(): void {
        if (this.authStore.hasAnyPermission([
            `${PERMISSION.FEATURE.ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA}:${this.kita.id}`,
            `${PERMISSION.FEATURE.ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS}:${this.kita.id}`,
        ])) {
            this.anwesenheitsSollService.getVerbrauch$(this.kind.id, this.kita.id, this.zuweisungAb)
                .pipe(take(1))
                .subscribe({
                    next: verbrauch => this.anwesenheitsSollVerbrauch = verbrauch,
                    error: err => LOG.error(`Could not get AnwesenheitssSoll verbrauch for Kind ${
                        this.kind.id}, KinderOrt ${this.kita.id} at ${this.zuweisungAb.toISOString()}`, err),
                });
        }
    }

    private loadKindKontakte(): void {
        // load the kontakte so we can display the recipient email when granting access to the main contact
        const params = {includes: '(kontaktperson,relationship)'};
        this.kindService.getAllRelationshipsWithKontaktpersonen(this.kind.id, params).then(kontakte => {
            this.kind.kontakte = kontakte;
        });
    }
}

componentConfig.controller = DvbKindMonatZuweisen;

angular.module('kitAdmin').component('dvbKindMonatZuweisen', componentConfig);
