/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsPerson, LimitedAdresse} from '@dv/kitadmin/models';
import {DvbDateUtil, Geschlecht} from '@dv/shared/code';
import angular from 'angular';
import type {MandantConfigurationService} from '../../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        form: '^form',
    },
    bindings: {
        fraktion: '<',
    },
    template: require('./dvb-betreuungsperson-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungspersonErstellen implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['mandantConfigurationService'];

    public fraktion!: BetreuungsPerson;
    public limitedAdresse?: LimitedAdresse;
    public geschlechte: readonly Geschlecht[] = [Geschlecht.WEIBLICH, Geschlecht.MAENNLICH, Geschlecht.DIVERS];
    public form!: angular.IFormController;

    public constructor(private mandantConfigurationService: MandantConfigurationService) {
    }

    public $onInit(): void {
        this.mandantConfigurationService.createDefaultLimitedAdresse(DvbDateUtil.today()).then(adresse => {
            this.limitedAdresse = adresse;
            this.fraktion.adressen.push(this.limitedAdresse);
        });
    }
}

componentConfig.controller = DvbBetreuungspersonErstellen;
angular.module('kitAdmin').component('dvbBetreuungspersonErstellen', componentConfig);
