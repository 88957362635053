/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {KitaBetreuungsfaktorRegel} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {
    KitaBetreuungsfaktorRegelService,
} from '../../../../common/service/rest/kinderort/kitaBetreuungsfaktorRegelService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaBetreuungsfaktorRegeln: '<',
        kitaId: '<',
        kitaName: '<',
    },
    template: require('./dvb-kita-betreuungsfaktor-regeln.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsfaktorRegeln {
    public static $inject: readonly string[] = [
        'kitaBetreuungsfaktorRegelService',
        'dialogService',
        'errorService',
        '$state',
        '$translate',
    ];

    public kitaBetreuungsfaktorRegeln!: KitaBetreuungsfaktorRegel[];
    public kitaId!: string;
    public kitaName!: string;

    public kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel | null = null;
    public isLoading: boolean = false;
    public showNewForm: boolean = false;

    public constructor(
        private kitaBetreuungsfaktorRegelService: KitaBetreuungsfaktorRegelService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private $state: StateService,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public hideForm(): void {
        this.showNewForm = false;
        this.kitaBetreuungsfaktorRegel = null;
    }

    public neueBetreuungsregelDefinieren(): void {
        this.showNewForm = true;
        this.kitaBetreuungsfaktorRegel = new KitaBetreuungsfaktorRegel();
        this.kitaBetreuungsfaktorRegel.kitaId = this.kitaId;
    }

    public terminateBetreuungsregel(): void {
        const beendigungAction = (endeDatum: moment.Moment): Observable<unknown> => {
            this.errorService.clearAll();

            return from(this.kitaBetreuungsfaktorRegelService.terminateKitaBetreuungsfaktorRegel(this.kitaId, endeDatum)
                .then(() => this.reloadBetreuungsregeln())).pipe(take(1));
        };

        this.dialogService.openDateDialog({
            title: this.$translate.instant('KINDERORT.KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN_HEADING', {
                kitaname: this.kitaName,
            }),
            confirm: endeDatum => {
                const valuesNachEndeDatum = this.kitaBetreuungsfaktorRegeln
                    .filter(kitaBetreuungsfaktorRegel => kitaBetreuungsfaktorRegel.gueltigAb?.isAfter(endeDatum));

                if (!valuesNachEndeDatum.length) {
                    return beendigungAction(endeDatum);
                }

                const confirm$ = new Subject<void>();
                this.dialogService.openConfirmDialog({
                    title: 'KINDERORT.CONFIRM_KITA_BETREUUNGSFAKTOR_REGEL_ZUWEISUNG_BEENDEN',
                    confirmActionText: 'COMMON.BEENDEN',
                    confirm: () => from(Promise.resolve(beendigungAction(endeDatum))).pipe(
                        take(1),
                        tap(() => confirm$.next())),
                    cancel: () => confirm$.next(),
                });

                return confirm$.pipe(take(1));
            },
        });
    }

    public revertBetreuungsregel(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel): void {
        this.errorService.clearAll();

        const id = checkPresent(kitaBetreuungsfaktorRegel.id);

        this.kitaBetreuungsfaktorRegelService.extendKitaBetreuungsfaktorRegel(id)
            .then(() => this.reloadBetreuungsregeln());
    }

    public saveBetreuungsregel(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel): void {
        this.isLoading = true;
        kitaBetreuungsfaktorRegel.kitaId = this.kitaId;
        this.kitaBetreuungsfaktorRegelService.createKitaBetreuungsfaktorRegel(kitaBetreuungsfaktorRegel)
            .then(() => this.reloadBetreuungsregeln())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public showConfirmDeleteBetreuungsregelModal(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel): void {
        this.dialogService.openDeleteDialog({
            entityText: 'KINDERORT.KITA_BETREUUNGSFAKTOR_REGEL',
            confirm: () => {
                this.errorService.clearAll();

                return from(this.kitaBetreuungsfaktorRegelService.deleteKitaBetreuungsfaktorRegel(checkPresent(
                    kitaBetreuungsfaktorRegel.id)))
                    .pipe(take(1), tap(() => this.$state.reload()));
            },
        });
    }

    private reloadBetreuungsregeln(): angular.IPromise<any> {
        this.hideForm();

        return this.kitaBetreuungsfaktorRegelService.getKitaBetreuungsfaktorRegeln(this.kitaId)
            .then((kitaBetreuungsfaktorRegeln: KitaBetreuungsfaktorRegel[]) => {
                this.kitaBetreuungsfaktorRegeln = kitaBetreuungsfaktorRegeln;
            });
    }
}

componentConfig.controller = DvbKitaBetreuungsfaktorRegeln;
angular.module('kitAdmin').component('dvbKitaBetreuungsfaktorRegeln', componentConfig);
