import {computed, inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import type {KinderOrt} from '@dv/kitadmin/models';
import {Termin} from '@dv/kitadmin/models';
import {apiStore} from '@dv/shared/backend/api-util';
import {TerminTypeService} from '@dv/shared/backend/api/termin-type.service';
import {TerminService} from '@dv/shared/backend/api/termin.service';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import {TerminUpdateMode} from '@dv/shared/backend/model/termin-update-mode';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import moment from 'moment';
import type {BsModalRef} from 'ngx-bootstrap/modal';
import {map, Subject} from 'rxjs';
import type {CalendarEvent} from '../../../../calendar/timeline/model/CalendarEvent';
import type {CalendarGroupDropEvent} from '../../../../calendar/timeline/model/CalendarGroupDropEvent';
import type {
    CalendarGroupResizeCompleteEvent,
} from '../../../../calendar/timeline/model/CalendarGroupResizeCompleteEvent';
import {AngestellteZuweisungen} from '../../../../personal/model/AngestellteZuweisungen';
import {isTerminEvent} from './calendar-event-util';

export const defaultTerminColor = '#dc3545';
export const terminDragData = 'termin';

interface TerminEditDialogData {
    open: boolean;
    date?: moment.Moment;
    recurring?: boolean;
}

export interface TerminUpdateModeDialogData {
    open: boolean;
    title?: string;
    submitLabel?: string;
    date?: moment.Moment;
    termin?: Termin;
    originalEvent?: CalendarEvent;
    angestellteId?: string;
    action?: 'delete' | 'update';
}

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class PersonalplanungTermineStore {
    private readonly api = inject(TerminService);
    private readonly terminTypeService = inject(TerminTypeService);

    /**
     * Notifies of termin changes.
     */
    public termineChanged: Subject<void> = new Subject();
    public newTermin: Termin = new Termin();
    public terminDialog: WritableSignal<TerminEditDialogData> = signal({open: false});
    public terminUpdateModeDialog: WritableSignal<TerminUpdateModeDialogData> = signal({open: false});
    public terminDeleteDialog: WritableSignal<TerminUpdateModeDialogData> = signal({open: false});
    public terminTypes: Signal<JaxTerminType[]> = toSignal(
        this.terminTypeService.getAll$().pipe(map(data => data.items)),
        {initialValue: []},
    );
    public defaultTerminType = computed(() => this.terminTypes().find(type => type.defaultType) ?? null);
    public addStore = apiStore(this.api.create$.bind(this.api), () =>
        this.terminDialog.set({open: false}),
    );
    // eslint-disable-next-line no-underscore-dangle
    public deleteStore = apiStore(this.api._delete$.bind(this.api), () => {
        this.deleteDialogRef?.hide();
        this.closeUpdateModeDialog();
    });
    public updateStore = apiStore(this.api.update$.bind(this.api), () => this.terminDialog.set({open: false}));

    private deleteDialogRef: BsModalRef<any> | undefined;

    public openTerminDialog(
        {resource, date}: CalendarGroupDropEvent,
        kinderOrt: KinderOrt | undefined,
    ): void {
        if (!(resource instanceof AngestellteZuweisungen)) {
            throw new Error(`assigning dienste is not supported for ${JSON.stringify(resource)}`);
        }

        const setTime = date.hour() !== 0 || date.minute() !== 0;

        const newTermin = new Termin();
        newTermin.alleKinderOrte = true;
        newTermin.kinderOrtIds = kinderOrt ? [kinderOrt.id!] : [];
        newTermin.backgroundColor = defaultTerminColor;
        newTermin.textColor = '#ffffff';
        newTermin.hasHighLuminance = false;
        newTermin.angestellteIds = [resource.angestellte.id];
        newTermin.von = setTime ? moment(date) : null;
        newTermin.bis = setTime ? moment(date).add(1, 'hours') : null;
        newTermin.gueltigAb = moment(date);
        newTermin.gueltigBis = moment(date);
        newTermin.terminType = this.defaultTerminType();

        this.newTermin = newTermin;

        this.terminDialog.set({open: true});
    }

    public openEditTerminDialog(event: CalendarEvent): void {
        if (isTerminEvent(event)) {
            this.newTermin = event.data.termin.parent ?? event.data.termin;
            this.terminDialog.set({
                open: true,
                date: event.gueltigAb,
                recurring: event.data.termin.parent?.wiederkehrend,
            });
        }
    }

    public addOrUpdateTermin(termin: Termin): void {
        if (termin.id) {
            if (this.terminDialog().recurring) {
                this.terminUpdateModeDialog.set({
                    open: true,
                    title: 'PERSONAL.TERMIN.EDIT_UPDATE_MODE',
                    date: this.terminDialog().date,
                    termin,
                    action: 'update',
                });

                return;
            }

            this.updateStore.source$.next({
                terminId: termin.id,
                jaxTermin: termin.toRestObject(),
                terminIdMatrix: {},
            });

            return;
        }

        this.addStore.source$.next({
            jaxTermin: termin.toRestObject(),
        });
    }

    public openTerminDeleteDialog(termin: Termin, angestellteId: string, deleteDate: moment.Moment): void {
        this.terminDeleteDialog.set({
            open: true,
            termin: termin.parent?.wiederkehrend ? termin.parent : termin,
            angestellteId,
            date: deleteDate,
            action: 'delete',
        });
    }

    public closeTerminDeleteDialog(): void {
        this.terminDeleteDialog.set({open: false});
    }

    public deleteTermin({termin, mode, updateDate}: {
        termin: Termin;
        mode: TerminUpdateMode;
        updateDate: BackendLocalDate;
    }): void {
        this.deleteStore.source$.next({
            terminId: checkPresent(termin.id),
            terminIdMatrix: {mode, updateDate},
        });

        this.closeTerminDeleteDialog();
    }

    public update(params: CalendarGroupResizeCompleteEvent): void {
        const event = params.event;
        if (!isTerminEvent(event)) {
            return;
        }
        const termin = event.data.termin;

        if (termin.parent?.wiederkehrend) {
            this.updateTerminGueltigkeit(termin.parent, event);
            this.terminUpdateModeDialog.set({
                open: true,
                title: 'PERSONAL.TERMIN.EDIT_UPDATE_MODE',
                date: event.gueltigAb,
                termin: checkPresent(termin.parent),
                originalEvent: params.originalEvent,
                action: 'update',
            });

            return;
        }

        this.updateTerminGueltigkeit(termin, event);

        this.updateStore.source$.next({
            terminId: checkPresent(termin.id),
            jaxTermin: termin.toRestObject(),
            terminIdMatrix: {},
        });
    }

    private updateTerminGueltigkeit(termin: Termin, event: CalendarEvent): void {
        termin.gueltigAb = event.gueltigAb;
        termin.gueltigBis = event.gueltigBis;
        termin.von = event.von;
        termin.bis = event.bis;
    }

    public submitUpdateMode(mode: TerminUpdateMode): void {
        const dialogData = this.terminUpdateModeDialog();
        if (dialogData.action === 'delete') {
            this.deleteStore.source$.next({
                terminId: checkPresent(checkPresent(dialogData.termin).parent).id,
                terminIdMatrix: {
                    mode,
                    updateDate: DvbRestUtil.momentToLocalDateChecked(dialogData.date),
                },
            });
        }

        if (dialogData.action === 'update') {
            this.updateStore.source$.next({
                terminId: checkPresent(checkPresent(dialogData.termin).id),
                jaxTermin: checkPresent(dialogData.termin).toRestObject(),
                terminIdMatrix: {
                    mode,
                    updateDate: DvbRestUtil.momentToLocalDateChecked(dialogData.date),
                },
            });
        }

        this.closeUpdateModeDialog();
    }

    public closeUpdateModeDialog(reset: boolean = false): void {
        const dialogData = this.terminUpdateModeDialog();
        if (reset && dialogData.termin && dialogData.originalEvent) {
            this.updateTerminGueltigkeit(dialogData.termin, dialogData.originalEvent);
            this.termineChanged.next();
        }

        this.terminUpdateModeDialog.set({open: false});
    }
}
