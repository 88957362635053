/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {AnwesenheitsSoll} from '../../model/AnwesenheitsSoll';
import type {AnwesenheitsSollVerbrauch} from '../../model/AnwesenheitsSollVerbrauch';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anwesenheitsSollVerbrauch: '<',
    },
    template: require('./dvb-anwesenheits-soll-verbrauch.html'),
    controllerAs: 'vm',
};

export class DvbAnwesenheitsSollVerbrauch implements angular.IController {
    public static $inject: readonly string[] = [];

    public anwesenheitsSollVerbrauch!: AnwesenheitsSollVerbrauch;

    public soll: AnwesenheitsSoll | null = null;
    public verbrauch: AnwesenheitsSoll | null = null;

    public $onChanges(): void {
        // shortcut access for template
        this.soll = this.anwesenheitsSollVerbrauch.soll;
        this.verbrauch = this.anwesenheitsSollVerbrauch.verbrauch;
    }
}

componentConfig.controller = DvbAnwesenheitsSollVerbrauch;
angular.module('kitAdmin').component('dvbAnwesenheitsSollVerbrauch', componentConfig);
