/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KinderOrtFraktion} from '@dv/kitadmin/models';
import {AbstractPagesController} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted, RestLimited, RestPaginated} from '@dv/shared/code';
import {DvbRestUtil, Gueltigkeit} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {finalize, from, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import type {MonatsblattApiSendStatus} from '../../../fraktion/models/MonatsblattApiSendStatus';
import type {MonatsBlattApiService} from '../../../fraktion/service/monatsBlattApiService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kinder-ort-lohn.html'),
    controllerAs: 'vm',
};

export class DvbKinderOrtLohn extends AbstractPagesController<KinderOrtFraktion> implements angular.IController {

    public static override $inject: readonly string[] = [
        'monatsBlattApiService',
        'dialogService',
        'kinderOrtService',
        '$q',
        '$translate',
    ];

    public kinderOrt!: Persisted<KinderOrt>;

    public month: moment.Moment = moment();
    public fraktionen: KinderOrtFraktion[] = [];
    public stateByFraktion: { [fraktionId: string]: MonatsblattApiSendStatus } = {};
    public isSending: boolean = false;

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly monatsBlattApiService: MonatsBlattApiService,
        private readonly dialogService: DialogService,
        private readonly kinderOrtService: KinderOrtService,
        private readonly $q: angular.IQService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
        super();
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.kinderOrt && !onChangesObj.kinderOrt.isFirstChange()) {
            this.reloadItems();
        }
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const config = {timeout: this.timeout.promise};

        const params: RestLimited & RestPaginated = new Gueltigkeit(moment(this.month).startOf('month'),
            moment(this.month).endOf('month'));
        this.setPagionationValues(params);

        this.kinderOrtService.getBelegteFraktionen(this.kinderOrt.id, params, config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });

        this.stateByFraktion = {};

        this.monatsBlattApiService.getStates(this.kinderOrt.id, this.month)
            .then(states => states.forEach(state => {
                    this.stateByFraktion[state.betreuungsPersonId!] = state;
                }),
            );
    }

    public sendMonth(): void {

        const confirm = (): Observable<unknown> => {
            this.isSending = true;

            return from(this.monatsBlattApiService.sendForKinderOrt(this.kinderOrt.id, this.month)).pipe(
                take(1),
                tap(() => {
                    this.reloadItems();
                }),
                finalize(() => {
                    this.isSending = false;
                }));
        };

        const monthYear = this.month?.format('MMMM YYYY');
        this.dialogService.openConfirmDialog({
            title: this.$translate.instant('FRAKTION.MONATSBLATT.API.SEND_TITLE', {monthYear}),
            subtitle: this.$translate.instant('FRAKTION.MONATSBLATT.API.SEND_INFO_KINDERORT',
                {name: this.kinderOrt.getDisplayName()}),
            confirm,
        });
    }
}

componentConfig.controller = DvbKinderOrtLohn;
angular.module('kitAdmin').component('dvbKinderOrtLohn', componentConfig);
