<div class="dvb-erziehungsberechtigter-input">
    <div class="checkbox">
        <label>
            <input type="checkbox"
                   name="erziehungsberechtigter"
                   ng-model="vm.isErziehungsberechtigter"
                   ng-change="vm.changeErziehungsberechtigter()"
                   dvb-auth
                   require-permission="kita:manage:general"
                   on-unauthorised-disable>
            <span data-translate="KONTAKTPERSON.ERZIEHUNGSBERECHTIGTER"></span>
        </label>
    </div>
</div>
