<div class="dvb-betreuungs-schluessel-regel">

    <div class="row">
        <div class="col-md-2">
            <input type="text"
                   class="form-control time-input"
                   ng-model="vm.betreuungsSchluesselRegel.vonBis.von"
                   dvb-select-on-click
                   dvb-time-input
                   dvb-moment
                   maxlength="10"
                   ng-required="vm.isTimeRangeRequired()"
                   ng-attr-placeholder="{{'COMMON.VON' | translate}}"
                   uib-tooltip="{{'COMMON.VON' | translate}}"
                   tooltip-placement="top-left">
        </div>
        <div class="col-md-2">
            <input type="text"
                   ng-model="vm.betreuungsSchluesselRegel.vonBis.bis"
                   class="form-control time-input"
                   dvb-select-on-click
                   dvb-time-input
                   dvb-moment
                   maxlength="10"
                   ng-required="vm.isTimeRangeRequired()"
                   ng-attr-placeholder="{{'COMMON.BIS' | translate}}"
                   uib-tooltip="{{'COMMON.BIS' | translate}}"
                   tooltip-placement="top-left">
        </div>
    </div>

    <div ng-repeat="regelBedarf in vm.betreuungsSchluesselRegel.betreuungsSchluesselRegelBedarf"
         class="border-top small-abstand-top">
        <div class="row normal-abstand-top">
            <div class="col-md-5">
                <input type="number"
                       class="form-control"
                       ng-model="regelBedarf.fromPlaetze"
                       ng-attr-placeholder="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.VON_PLAETZE' | translate}}"
                       uib-tooltip="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.VON_PLAETZE' | translate}}"
                       tooltip-placement="top-left"
                       ng-required="regelBedarf.fromKinder == null">
            </div>
            <div class="col-md-5">
                <input type="number"
                       class="form-control"
                       ng-model="regelBedarf.fromKinder"
                       ng-attr-placeholder="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.VON_KINDER' | translate}}"
                       uib-tooltip="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.VON_KINDER' | translate}}"
                       tooltip-placement="top-left"
                       ng-required="regelBedarf.fromPlaetze == null">
            </div>
            <div class="col-md-2">
                <button type="button"
                        class="btn btn-link form-linker"
                        data-translate="COMMON.LOESCHEN"
                        ng-click="vm.onDeleteRegelBedarf({regelBedarf})">
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div ng-repeat="bedarf in regelBedarf.ausbildungsPersonalBedarf" class="row">
                    <div class="col-md-4 col-md-offset-1">
                        <select class="form-control"
                                uib-tooltip="{{'PERSONAL.ANSTELLUNG.EDUCATION' | translate}}"
                                tooltip-placement="top-left"
                                ng-model="bedarf.ausbildung"
                                ng-attr-placeholder="{{'PERSONAL.ANSTELLUNG.EDUCATION' | translate}}"
                                ng-options="ausbildung as ausbildung.name for ausbildung in vm.ausbildungen | orderBy:'name'"
                                required>
                            <option value=""
                                    class="hide"
                                    disabled
                                    selected
                                    data-translate="PERSONAL.ANSTELLUNG.EDUCATION">
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <input type="number"
                               class="form-control"
                               ng-model="bedarf.bedarfCount"
                               ng-attr-placeholder="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.BEDARF' | translate}}"
                               uib-tooltip="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.BEDARF' | translate}}"
                               tooltip-placement="top-left"
                               required>
                    </div>
                    <div class="col-md-2">
                        <button type="button"
                                class="btn btn-link form-linker"
                                data-translate="COMMON.LOESCHEN"
                                ng-click="vm.onDeleteAusbildungsBedarf({bedarf, regelBedarf})">
                        </button>
                    </div>
                </div>
                <div class="row small-abstand-bottom">
                    <div class="col-md-11 col-md-offset-1">
                        <button type="button"
                                class="btn btn-link"
                                ng-click="vm.onAddAusbildungsBedarf({regelBedarf})">
                            +
                            <span data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.ADD_AUSBILDUNGS_BEDARF"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
