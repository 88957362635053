/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {WochenKapazitaet, ZeitraumFeld} from '@dv/kitadmin/models';
import {Kapazitaet} from '@dv/kitadmin/models';
import {DvbUtil, isNullish} from '@dv/shared/code';
import {ZeitraumErrorTypes} from '../models/ZeitraumErrorTypes';
import {ZeitraumFeldError} from '../models/ZeitraumFeldError';

interface Validation {
    maxKleinerAlsAnzError: boolean;
    anzEmptyMaxNotError: boolean;
}

export class KapazitaetService {
    public static $inject: readonly string[] = [];

    private static invalidArgumentException: Error = new Error(
        'zeitraumFelderAnz parameter does not match zeitraumFelderMax parameter');

    private zeitraumErrors: ZeitraumFeldError[] = [];

    public addZeitraumFeldError(zeitraumFeld: ZeitraumFeld): ZeitraumFeldError {
        const zfError = new ZeitraumFeldError(zeitraumFeld);
        this.zeitraumErrors.push(zfError);

        return zfError;
    }

    public findZeitraumFeldError(zeitraumFeld: ZeitraumFeld): ZeitraumFeldError | null {
        const found = this.zeitraumErrors.filter(zfError => zfError.zeitraumFeld === zeitraumFeld);

        return found.length > 0 ? found[0] : null;
    }

    public getOrCreateZeitraumFeldError(zeitraumFeld: ZeitraumFeld): ZeitraumFeldError {
        const zfe = this.findZeitraumFeldError(zeitraumFeld);

        return zfe ?? this.addZeitraumFeldError(zeitraumFeld);
    }

    public setZfError(zeitraumFeld: ZeitraumFeld, errortype: ZeitraumErrorTypes): void {
        this.getOrCreateZeitraumFeldError(zeitraumFeld).errors[errortype] = true;
    }

    public unsetZfError(zeitraumFeld: ZeitraumFeld, errortype: ZeitraumErrorTypes): void {
        this.getOrCreateZeitraumFeldError(zeitraumFeld).errors[errortype] = false;
    }

    public updateError(isError: boolean, zeitraumFeld: ZeitraumFeld, errortype: ZeitraumErrorTypes): void {
        if (isError) {
            this.setZfError(zeitraumFeld, errortype);
        } else {
            this.unsetZfError(zeitraumFeld, errortype);
        }
    }

    public setKapazitaetValueError(isError: boolean, zeitraumFeld: ZeitraumFeld): void {
        this.updateError(isError, zeitraumFeld, ZeitraumErrorTypes.INVALID_KAPAZITAETS_VALUE);
    }

    public setValueToSmallError(isError: boolean, zeitraumFeld: ZeitraumFeld): void {
        this.updateError(isError, zeitraumFeld, ZeitraumErrorTypes.VALUE_TOO_SMALL);
    }

    public setInvalidPlaetzeByDay(isError: boolean, zeitraumFeld: ZeitraumFeld): void {
        this.updateError(isError, zeitraumFeld, ZeitraumErrorTypes.INVALID_PLAETZE_BY_DAY);
    }

    public setMissingAnzahlValue(isError: boolean, zeitraumFeld: ZeitraumFeld): void {
        this.updateError(isError, zeitraumFeld, ZeitraumErrorTypes.HAS_MISSING_ANZ_VALUE);
    }

    public clearErrors(zeitraumFeld: ZeitraumFeld): void {
        const zfe = this.findZeitraumFeldError(zeitraumFeld);
        if (zfe) {
            zfe.errors = {};
        }
    }

    public clearAllErrors(): void {
        this.zeitraumErrors = [];
    }

    public hasError(zeitraumFeld: ZeitraumFeld): boolean {
        const zfe = this.findZeitraumFeldError(zeitraumFeld);

        if (zfe === null) {
            return false;
        }

        return Object.values(zfe.errors).some(Boolean);
    }

    /**
     * Methode zum ueberpruefen ob ein ZeitraumFeld zu einer Kapazitaet gehoert:
     * gleiche zeitraum.id/betreuungsZeitraumId und gleicher dayOfWeek
     */
    public zeitraumFeldLikeKapazitaet(zeitraumFeld: ZeitraumFeld, kapazitaet: Kapazitaet): boolean {
        if (!zeitraumFeld?.zeitraum || !kapazitaet) {
            return false;
        }

        return zeitraumFeld.zeitraum.id === kapazitaet.betreuungsZeitraumId &&
            zeitraumFeld.dayOfWeek === kapazitaet.dayOfWeek;
    }

    public isValidKapazitaetValue(value: string | null): boolean {
        if (isNullish(value)) {
            return false;
        }

        const reg = /^(^$|0|[1-9]\d*)$/; // leer, 0 oder Zahlen

        return reg.test(value);
    }

    /**
     * Wenn die Kapazitaeten bereits existieren werden sie aktualisiert, anderenfalls erstellt.
     * Mit isMax wird unterschieden, ob die maxPlaetze oder die plaetze der Kapazitaeten erstellt/aktualisiert
     * werden.
     */
    public createOrUpdateKapazitaeten(
        isMax: boolean,
        zeitraumFelder: ZeitraumFeld[],
        kapazitaeten: Kapazitaet[],
    ): Kapazitaet[] {
        if (!Array.isArray(kapazitaeten) || kapazitaeten.length <= 0) {
            return this.createKapazitaetenFromZeitraumFelder(isMax, zeitraumFelder);
        }

        // update
        zeitraumFelder.forEach(zeitraumFeld =>
            kapazitaeten.filter(kapazitaet =>
                this.zeitraumFeldLikeKapazitaet(zeitraumFeld, kapazitaet)).forEach(kapazitaet => {
                const value = this.isValidKapazitaetValue(zeitraumFeld.value) ? zeitraumFeld.value : null;
                kapazitaet.setPlaetze(isMax, value);
            }));

        return kapazitaeten;
    }

    /**
     * Setzt die WochenKapazitaet in Zeitraumfelder.
     */
    public setWochenKapazitaetToZeitraumFelder(
        zeitraumFelder: ZeitraumFeld[],
        wochenKapazitaet: WochenKapazitaet,
        isMax: boolean,
    ): void {
        if (!wochenKapazitaet || !Array.isArray(wochenKapazitaet.kapazitaeten)) {
            return;
        }

        zeitraumFelder.forEach(z => {
            const kapazitaet = wochenKapazitaet.kapazitaeten
                .filter(k => k.dayOfWeek === z.dayOfWeek && k.betreuungsZeitraumId === z.zeitraum.id);

            if (kapazitaet.length !== 1) {
                return;
            }

            z.value = isMax ?
                kapazitaet[0].maxPlaetzeToZeitraumFeldValue() :
                kapazitaet[0].plaetzeToZeitraumFeldValue();
            z.selected = z.value !== '';
        });
    }

    /**
     * Wenn die Kapazitaeten bereits existieren werden sie aktualisiert, anderenfalls erstellt.
     * Mit isMax wird unterschieden, ob die maxPlaetze oder die plaetze der Kapazitaeten erstellt/aktualisiert
     * werden.
     */
    public createKapazitaetenFromZeitraumFelder(isMax: boolean, zeitraumFelder: ZeitraumFeld[]): Kapazitaet[] {
        const kapazitaeten: Kapazitaet[] = [];
        zeitraumFelder.forEach(zeitraumFeld => {
            const kapazitaet = new Kapazitaet();
            kapazitaet.dayOfWeek = zeitraumFeld.dayOfWeek;
            kapazitaet.betreuungsZeitraumId = zeitraumFeld.zeitraum.id;

            if (DvbUtil.isLikeNotNegativeInteger(zeitraumFeld.value)) {
                kapazitaet.setPlaetze(isMax, zeitraumFeld.value);
            }

            kapazitaeten.push(kapazitaet);
        });

        return kapazitaeten;
    }

    /**
     * Zwei Fehler werden validiert:
     * - Error, wenn Max-ZeitraumFeld-Value kleiner ist als Anzahl-ZeitraumFeld-Value
     * - Error, wenn Anzahl-ZeitraumFeld-Value leer ist, aber Max-ZeitraumFeld-Value einen Wert hat
     * zurueckgegeben wird ein Objekt mit 2 boolean-Values (maxKleinerAlsAnzError & anzEmptyMaxNotError)
     * diese sind jeweils true, wenn einer von beiden Fehlern mind. einmal aufgetreten ist.
     */
    public validateAnzKleinerAlsMaxAndNotEmpty(
        zeitraumFelderAnz: ZeitraumFeld[],
        zeitraumFelderMax: ZeitraumFeld[],
    ): Validation {
        if (!Array.isArray(zeitraumFelderAnz) ||
            !Array.isArray(zeitraumFelderMax) ||
            zeitraumFelderAnz.length !== zeitraumFelderMax.length) {

            throw KapazitaetService.invalidArgumentException;
        }

        const validationObj = {
            maxKleinerAlsAnzError: false,
            anzEmptyMaxNotError: false,
        };

        zeitraumFelderAnz.forEach(zfAnz => {
            // Entsprechendes ZeitraumFeld der "Max. Anzahl Plaetze" herausfiltern
            const foundZeitraumFelder = zeitraumFelderMax
                .filter(feld => feld.dayOfWeek === zfAnz.dayOfWeek && feld.zeitraum.id === zfAnz.zeitraum.id);

            if (foundZeitraumFelder.length !== 1) {
                throw KapazitaetService.invalidArgumentException;
            }

            const zfMax = foundZeitraumFelder[0];
            this.validate(zfAnz, zfMax, validationObj);
        });

        return validationObj;
    }

    public hasEqualZeitraumFeldValues(zeitraumFelderAnz: ZeitraumFeld[], zeitraumFelderMax: ZeitraumFeld[]): boolean {
        let isMaxEqualAnz = true;
        zeitraumFelderAnz.forEach(zfAnz => {
            const foundZeitraumFelder = zeitraumFelderMax
                .filter(feld => feld.dayOfWeek === zfAnz.dayOfWeek && feld.zeitraum.id === zfAnz.zeitraum.id);

            if (foundZeitraumFelder.length !== 1) {
                throw KapazitaetService.invalidArgumentException;
            }

            const zfMax = foundZeitraumFelder[0];
            if (zfMax.value !== zfAnz.value) {
                isMaxEqualAnz = false;
            }

        });

        return isMaxEqualAnz;
    }

    private validate(zfAnz: ZeitraumFeld, zfMax: ZeitraumFeld, validationObj: Validation): void {
        const isMaxTooSmall = DvbUtil.isLikeNotNegativeInteger(zfAnz.value) &&
            DvbUtil.isLikeNotNegativeInteger(zfMax.value) &&
            parseInt(zfMax.value || '', 10) < parseInt(zfAnz.value || '', 10);

        const hasMissingMaxValue = DvbUtil.isLikeNotNegativeInteger(zfAnz.value) &&
            !DvbUtil.isLikeNotNegativeInteger(zfMax.value);

        const isMaxKleinerAlsPlaetze = isMaxTooSmall || hasMissingMaxValue;

        if (isMaxKleinerAlsPlaetze) {
            validationObj.maxKleinerAlsAnzError = true;
        }

        this.setValueToSmallError(isMaxKleinerAlsPlaetze, zfMax);

        const hasMissingAnzValue = !DvbUtil.isLikeNotNegativeInteger(zfAnz.value) &&
            DvbUtil.isLikeNotNegativeInteger(zfMax.value);

        if (hasMissingAnzValue) {
            validationObj.anzEmptyMaxNotError = true;
        }

        this.setMissingAnzahlValue(hasMissingAnzValue, zfMax);
    }
}
