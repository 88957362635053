/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AuthEventType} from '@dv/shared/authentication/model';
import {DvbUtil, HttpCodes} from '@dv/shared/code';
import type angular from 'angular';
import type {OAuthService} from 'angular-oauth2-oidc';
import {CONFIG} from '../../../config';
import type {AuthEventService} from './auth-event.service';
import type {HttpBuffer} from './httpBuffer';

const loginURLs = [
    `${CONFIG.restBackend}/api/v1/auth/login`,
    `${CONFIG.restBackend}/api/v1/auth/token-login`,
];

export class AuthHttpInterceptor implements angular.IHttpInterceptor {
    public static $inject: readonly string[] = ['$q', 'httpBuffer', 'oauthService', 'authEventService'];

    public constructor(
        private readonly $q: angular.IQService,
        private readonly httpBuffer: HttpBuffer,
        private readonly oauthService: OAuthService,
        private readonly authEventService: AuthEventService,
    ) {
        this.responseError = this.responseError.bind(this);
        this.request = this.request.bind(this);
    }

    public request(config: angular.IRequestConfig): angular.IRequestConfig | angular.IPromise<angular.IRequestConfig> {
        const accessToken = this.oauthService.getAccessToken();
        if (config.url.startsWith(CONFIG.restBackend) && DvbUtil.isNotEmptyString(accessToken)) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            config.headers = Object.assign(config.headers ?? {}, {Authorization: `Bearer ${accessToken}`});

            return config;
        }

        return config;
    }

    /**
     * Broadcasts {@link AuthEventType} based on the HTTP Response status codes 401, 403, 419 and 440.
     */
    public responseError<T>(response: angular.IHttpResponse<T>): angular.IPromise<T> {
        switch (response.status) {
            case HttpCodes.UNAUTHORIZED: {
                // exclude requests from the login form
                if (response.config && loginURLs.includes(response.config.url)) {
                    return this.$q.reject(response);
                }
                // all requests that failed due to notAuthenticated are appended to httpBuffer.
                // Use httpBuffer.retryAll to submit them.
                const deferred = this.$q.defer<T>();
                this.httpBuffer.append(response.config, deferred);
                this.authEventService.sendEvent({type: AuthEventType.notAuthenticated});

                return deferred.promise;
            }
            case HttpCodes.FORBIDDEN:
                this.authEventService.sendEvent({type: AuthEventType.notAuthorised});

                return this.$q.reject(response);
            default:
                return this.$q.reject(response);
        }
    }
}
