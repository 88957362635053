/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TerminationMode} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {FraktionBetreuungsSchluessel} from '../../personal/betreuungs-schluessel/models/FraktionBetreuungsSchluessel';

export class FraktionBetreuungsSchluesselService {
    public static $inject: readonly string[] = ['$http'];

    private static baseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/fraktionbetreuungsschluessel`;

    public constructor(
            private $http: angular.IHttpService,
    ) {
    }

    public getAllByFraktion(fraktionId: string): angular.IPromise<FraktionBetreuungsSchluessel[]> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionBetreuungsSchluesselService.baseUrl}/fraktion/${encodeURIComponent(fraktionId)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, FraktionBetreuungsSchluessel, 'items');
    }

    public create(fraktionBetreuungsSchluessel: FraktionBetreuungsSchluessel): angular.IPromise<unknown> {
        const fraktionId = checkPresent(fraktionBetreuungsSchluessel.fraktionId);

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionBetreuungsSchluesselService.baseUrl}/fraktion/${encodeURIComponent(fraktionId)}`;

        return this.$http.post(url, fraktionBetreuungsSchluessel.toRestObject());
    }

    public delete(fraktionBetreuungsSchluesselId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionBetreuungsSchluesselService.baseUrl}/${encodeURIComponent(fraktionBetreuungsSchluesselId)}`;

        return this.$http.delete(url);
    }

    public extend(fraktionBetreuungsSchluesselId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionBetreuungsSchluesselService.baseUrl}/${encodeURIComponent(fraktionBetreuungsSchluesselId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    public terminate(fraktionId: string, endDate: moment.Moment, mode: TerminationMode): angular.IPromise<unknown> {
        const url = `${FraktionBetreuungsSchluesselService.baseUrl}/fraktion/${encodeURIComponent(fraktionId)}/enddate`;

        return DvbRestUtilAngularJS.terminate(url, endDate, mode);
    }
}
