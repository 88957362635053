/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {ImageBlob} from '@dv/kitadmin/models';
import {UploadTempBlob} from '@dv/kitadmin/models';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {BlobUploadService} from '../../service/rest/blobUploadService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tempBlob: '=ngModel',
        labelUpload: '@',
        allowMultiple: '<',
        maxSize: '<?',
        maxTotalSize: '<?',
        allowedExtensions: '<?',
    },
    require: {ngModelCtrl: 'ngModel'},
    template: require('./dvb-multiple-files-upload.html'),
    controllerAs: 'vm',
};

export type NGFile = File & { $error: string; $errorMessages: { [key: string]: boolean }; $errorParam: any };

export class DvbMultipleFilesUpload implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['blobUploadService', 'errorService', '$q'];

    public allowMultiple: boolean = false;
    public maxSize: string = '20MB';
    public maxTotalSize: string = '200MB';
    public allowedExtensions: string = '.v11,.esr,.xml,.txt';

    private ngModelCtrl!: angular.INgModelController;

    public constructor(
        private blobUploadService: BlobUploadService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    private static formatter(modelValues?: UploadTempBlob[]): UploadTempBlob[] {
        return modelValues ?? [];
    }

    private static uploadSuccess(modelValues?: (UploadTempBlob | ImageBlob)[]): boolean {
        if (!modelValues || modelValues.length === 0) {
            // no value is ok (use "required" if you need a value)
            return true;
        }

        // Validation based on TempBlob Id.
        return modelValues.some(modelValue => !modelValue.id);
    }

    private static isEmpty(viewValues: UploadTempBlob[]): boolean {
        // The standard undefined check is not enough for is-required, because viewValue is always a UploadTempBlob.
        return !viewValues || viewValues.length === 0 || viewValues.some(viewValue => !viewValue.id);
    }

    public $onInit(): void {
        this.ngModelCtrl.$formatters.push(DvbMultipleFilesUpload.formatter);
        this.ngModelCtrl.$validators.uploadSuccess = DvbMultipleFilesUpload.uploadSuccess;
        this.ngModelCtrl.$isEmpty = DvbMultipleFilesUpload.isEmpty;
    }

    public uploadFiles(
        files: NGFile[],
        $invalidFiles: NGFile[],
        resizeOptions: angular.angularFileUpload.FileResizeOptions,
    ): void {
        const valid = this.validate($invalidFiles);

        if (!files || !valid) {
            return;
        }

        this.$q.all(files.map((file: NGFile) => {
            const tempBlob = new UploadTempBlob();
            tempBlob.file = file;
            this.ngModelCtrl.$viewValue.push(tempBlob);

            return this.blobUploadService.uploadBlob(tempBlob, resizeOptions);
        })).then(() => {
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_FILE_TOO_LARGE');
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_FILES_TOO_LARGE');
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_UPLOAD_FAILED');
        }).catch(response => {
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_BADREQUEST');
            this.errorService.addValidationError(response.errorMessage);
        });
    }

    public onRemoveFile(tempBlob: UploadTempBlob): void {
        DvbUtil.removeFromArray(DvbUtil.findFirst(this.ngModelCtrl.$viewValue,
            (blob: UploadTempBlob) => blob.filename === tempBlob.filename),
            this.ngModelCtrl.$viewValue);
    }

    // eslint-disable-next-line complexity
    private validate($invalidFiles: NGFile[]): boolean {
        let valid = true;
        const fileNotValid = 'ERRORS.ERR_FILE_NOT_VALID';

        const errorKeyMsgKeyMap = {
            maxFiles: 'ERRORS.ERR_UPLOAD_FAILED',
            maxSize: 'ERRORS.ERR_FILE_TOO_LARGE',
            maxTotalSize: 'ERRORS.ERR_FILES_TOO_LARGE',
            pattern: 'ERRORS.ERR_FILE_FORMAT_NOT_ALLOWED',
            minHeight: 'ERRORS.ERR_IMAGE_SMALL_HEIGHT',
            maxHeight: 'ERRORS.ERR_IMAGE_LARGE_HEIGHT',
            minWidth: 'ERRORS.ERR_IMAGE_SMALL_WIDTH',
            maxWidth: 'ERRORS.ERR_IMAGE_LARGE_WIDTH',
            ratio: 'ERRORS.ERR_IMAGE_RATIO',
            minRatio: 'ERRORS.ERR_IMAGE_SMALL_RATIO',
            maxRatio: 'ERRORS.ERR_IMAGE_LARGE_RATIO',
            dimensions: 'ERRORS.ERR_IMAGE_DIMENSIONS',
            maxDuration: fileNotValid,
            duration: fileNotValid,
            validateFn: fileNotValid,
            validateAsyncFn: fileNotValid,
        };

        Object.entries(errorKeyMsgKeyMap).forEach(([errorKey, msgKey]) => {
            valid = valid && this.handleError($invalidFiles, errorKey, msgKey);
        });

        return valid;
    }

    private handleError(files: NGFile[], errorKey: string, msgKey: string): boolean {
        const errorFile = this.hasFileError(files, errorKey);

        if (!errorFile) {
            this.errorService.clearErrorByMsgKey(msgKey);

            return true;
        }

        this.errorService.handleValidationError(false, msgKey, {value: errorFile.$errorParam});

        return false;
    }

    private hasFileError(files: NGFile[], errorKey: string): NGFile | null {
        return DvbUtil.findFirst(files, file => file.$error === errorKey);
    }
}

componentConfig.controller = DvbMultipleFilesUpload;
angular.module('kitAdmin').component('dvbMultipleFilesUpload', componentConfig);
