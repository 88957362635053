/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {ChartData} from 'chart.js';
import type moment from 'moment';
import type {StyleVariableService} from '../../../../common/service/style-variable.service';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {ChartComponent} from '../service/ChartContext';
import {ChartContext} from '../service/ChartContext';
import {ChartUtil} from '../service/ChartUtil';
import {DataSet} from './DataSet';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        controllingReport: '<',
        firstOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-chart.html'),
    controllerAs: 'vm',
};

export class DvbKitaChart implements angular.IController, ChartComponent {

    public static $inject: readonly string[] = [
        'styleVariableService',
        '$scope',
        '$translate',
        '$element',
        'authStore',
    ];

    private static readonly Y_AXIS_MARGIN: number = 5;

    public controllingReport!: ControllingReport;
    public firstOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public chartContext: ChartContext | null = null;
    public maxValue: number = 0;
    public chartData?: ChartData = undefined;

    public constructor(
        public styleVariableService: StyleVariableService,
        public $scope: angular.IScope,
        private $translate: angular.translate.ITranslateService,
        private $element: angular.IAugmentedJQuery,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        if (!this.authStore.hasPermission(PERMISSION.KITA.VIEW_ANY)) {
            return;
        }

        this.populateDatasets();

        // timeout is used to avoid a timing issue, when displaying charts in tabs
        setTimeout(() => {
            this.initChart();
        }, 0);
    }

    private initChart(): void {
        const canvas = this.$element.find('#kita-chart')[0];
        this.chartContext = new ChartContext(this, this.chartData!, canvas);

        const options = ChartUtil.createOptions(
            this.chartContext,
            this.styleVariableService,
            this.maxValue,
            0,
            this.$translate);

        ChartUtil.createChart(options, this.chartContext);
    }

    private populateDatasets(): void {
        const bewilligtePlaetze = new DataSet(
            this.$translate.instant('KINDERORT.BEWILLIGTE_PLAETZE.KITA'),
            this.styleVariableService.getColorContrast());

        const maximalePlaetze = new DataSet(
            this.$translate.instant('KINDERORT.MAXIMALE_PLAETZE'),
            this.styleVariableService.getColorContrastNormal());

        const plaetze = new DataSet(
            this.$translate.instant('KINDERORT.PLAETZE'),
            this.styleVariableService.getColorGuide());

        const belegtePlaetze = new DataSet(
            this.$translate.instant('COMMON.KAPAZITAET_BELEGTE_PLAETZE'),
            this.styleVariableService.getColorMainNormal());

        let maxValue = 0;
        const mondays = DvbDateUtil.createMondaysForYear(this.firstOfWeek.isoWeekYear());

        // Fill sets with values
        mondays.forEach(aMoment => {
            const weeklyFigure = DvbDateUtil.getEntityOn(this.controllingReport.weeklyFigures, aMoment);

            if (!weeklyFigure) {
                return;
            }

            bewilligtePlaetze.data.push({x: aMoment, y: weeklyFigure.kitaBewilligtePlaetze});
            belegtePlaetze.data.push({x: aMoment, y: weeklyFigure.kitaBelegung});
            plaetze.data.push({x: aMoment, y: weeklyFigure.kitaPlaetze});
            maximalePlaetze.data.push({x: aMoment, y: weeklyFigure.kitaMaxPlaetze});
            maxValue = Math.max(
                maxValue,
                weeklyFigure.kitaBewilligtePlaetze,
                weeklyFigure.kitaBelegung,
                weeklyFigure.kitaPlaetze,
                weeklyFigure.kitaMaxPlaetze);
        });

        this.chartData = {};
        this.chartData.datasets = [];
        this.maxValue = maxValue + DvbKitaChart.Y_AXIS_MARGIN;

        // Bewilligte Plaetze should only be added if it's at any point greater than 0
        if (bewilligtePlaetze.data.some(datum => datum.y > 0)) {
            this.chartData.datasets.push(bewilligtePlaetze);
        }

        this.chartData.datasets.push(maximalePlaetze);
        this.chartData.datasets.push(plaetze);
        this.chartData.datasets.push(belegtePlaetze);
    }
}

componentConfig.controller = DvbKitaChart;
angular.module('kitAdmin').component('dvbKitaChart', componentConfig);
