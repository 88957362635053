/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FirmenKontingent, KinderOrt, Tarif, TarifParameter} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {displayableComparator} from '@dv/shared/code';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition} from '@uirouter/core';
import angular from 'angular';
import type {TarifService} from '../common/service/rest/tarifService';

angular.module('kitAdmin').config(tarifConfig);

export * from './component/dvb-tarif-erstellen/dvb-tarif-erstellen';
export * from './component/dvb-tarif-main/dvb-tarif-main';
export * from './component/dvb-tarif-parameter-input/dvb-tarif-parameter-input';
export * from './component/dvb-tarif-value-form/dvb-tarif-value-form';
export * from './component/dvb-tarif-value-manual-positions-total/dvb-tarif-value-manual-positions-total';
export * from './component/dvb-tarif-verlauf/dvb-tarif-verlauf';
export * from './component/dvb-tarif-verlauf-content/dvb-tarif-verlauf-content';
export * from './component/dvb-tarif-verlauf-header/dvb-tarif-verlauf-header';
// Add New Component JS Above

tarifConfig.$inject = ['$stateProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function tarifConfig($stateProvider: StateProvider): void {

    $stateProvider.state('base.tarif-erstellen', {
        url: '/tarif-erstellen',
        template: '<dvb-tarif-erstellen existing-tarif-parameters="$resolve.tarifParameters"></dvb-tarif-erstellen>',
        resolve: {
            tarifParameters: [
                'tarifService',
                (tarifService: TarifService): angular.IPromise<TarifParameter[]> =>
                    tarifService.getAllTarifParameters().then(
                        (parameters: TarifParameter[]): TarifParameter[] => {
                            parameters.sort(displayableComparator);

                            return parameters;
                        }),
            ],
        },
        data: {
            permission: PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN,
        },
    });
    $stateProvider.state('base.tarif', {
        url: '/tarif/{id:[0-9]+}',
        template: '<dvb-tarif-main ' +
            'tarif="$resolve.tarif" ' +
            'kinder-orte="$resolve.kinderOrte" ' +
            'firmen-kontingente="$resolve.firmenKontingente"></dvb-tarif-main>',
        resolve: {
            tarif: [
                'tarifService', '$transition$',
                (tarifService: TarifService, $transition$: Transition): angular.IPromise<Tarif | null> =>
                    tarifService.getTarif($transition$.params().id, {
                        includes: '(values,parameters)',
                    }),
            ],
            kinderOrte: [
                'tarifService', '$transition$',
                (tarifService: TarifService, $transition$: Transition): angular.IPromise<KinderOrt[]> =>
                    tarifService.getKinderOrte($transition$.params().id, {}),
            ],
            firmenKontingente: [
                'tarifService', '$transition$',
                (tarifService: TarifService, $transition$: Transition): angular.IPromise<FirmenKontingent[]> =>
                    tarifService.getFirmenKontingente($transition$.params().id, {}),
            ],
            $title: ['tarif', (tarif: Tarif): string => tarif.getDisplayName()],
        },
        data: {
            permission: PERMISSION.KITA.CONTROL_ANY,
        },
    });
    /* Add New States Above */

}
