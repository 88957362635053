/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel} from '@dv/shared/code';

export type SteuerbescheinigungStructure = 'MONTHLY' | 'LEISTUNGSART';

export class ParameterSteuerbescheinigungen implements IRestModel {
    public constructor(
        public kontaktpersonenIds: string[] = [],
        public kinderOrtId: string | null = null,
        public dokumentLayoutId: string | null = null,
        public jahr: number = -1,
        public structure: SteuerbescheinigungStructure = 'MONTHLY',
    ) {
    }

    public toRestObject(): Record<string, unknown> {
        return {
            kontaktpersonenIds: this.kontaktpersonenIds,
            kinderOrtId: this.kinderOrtId,
            dokumentLayoutId: this.dokumentLayoutId,
            jahr: this.jahr,
            structure: this.structure,
        };
    }
}
