/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FirmenKontingent, Kind, KinderOrt, SubventioniertesKontingent} from '@dv/kitadmin/models';
import {StundenKontingent} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, FormContext, isNullish} from '@dv/shared/code';
import angular from 'angular';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        title: '?titleColumn',
    },
    bindings: {
        stundenKontingent: '<?',
        kind: '<',
        formContext: '<?',
        onUpdate: '&',
        onCancel: '&',
    },
    template: require('./dvb-stunden-kontingent-form.html'),
    controllerAs: 'vm',
};

export class DvbStundenKontingentForm implements angular.IOnInit {
    public static $inject: readonly string[] = ['errorService', 'kinderOrtService'];

    public stundenKontingent?: StundenKontingent;
    public kind!: Kind;
    public formContext!: FormContext;
    public onUpdate!: (data: { stundenKontingent: StundenKontingent; formContext: FormContext }) => void;
    public onCancel!: () => void;

    public workingCopy: StundenKontingent = new StundenKontingent();
    public kitaSearchResultEntry: SearchResultEntry | null = null;
    public kinderOrt: KinderOrt | null = null;
    public isNewEntity: boolean = true;
    public subventioniertesKontingent: SubventioniertesKontingent | null = null;
    public firmenKontingente: FirmenKontingent[] = [];

    public constructor(private errorService: ErrorService, private kinderOrtService: KinderOrtService) {
    }

    public $onInit(): void {
        this.formContext = this.formContext || new FormContext();
        this.isNewEntity = isNullish(this.stundenKontingent) || this.stundenKontingent.id === null;
        this.workingCopy = this.isNewEntity ?
            new StundenKontingent(null, this.kind.id) :
            angular.copy(checkPresent(this.stundenKontingent), this.workingCopy);

        if (this.isNewEntity || !this.workingCopy.kinderOrtId) {
            return;
        }

        this.fetchKinderOrt(this.workingCopy.kinderOrtId)
            .then(kinderOrt => {
                this.kitaSearchResultEntry = kinderOrt.toSearchResultEntry();
                this.setKinderOrt(kinderOrt);
            });
    }

    public updateKinderOrt(): void {
        if (!this.kitaSearchResultEntry?.id) {
            return;
        }

        this.workingCopy.kinderOrtId = this.kitaSearchResultEntry.id;
        this.fetchKinderOrt(this.kitaSearchResultEntry.id)
            .then(kinderOrt => this.setKinderOrt(kinderOrt));
    }

    public onSubmit(): void {
        const isValid = this.workingCopy.isValid();

        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const isValidDates = this.workingCopy.gueltigAb!.isSameOrBefore(this.workingCopy.gueltigBis);
        this.errorService.handleValidationError(isValidDates, 'ERRORS.ERR_INVALID_DATES');

        if (!isValid || !isValidDates) {
            return;
        }

        if (angular.equals(this.workingCopy, this.stundenKontingent)) {
            this.onCancel();

            return;
        }

        this.onUpdate({stundenKontingent: this.workingCopy, formContext: this.formContext});
    }

    public initKontingente(): void {
        const stichtag = this.workingCopy.gueltigAb;
        if (this.kinderOrt && DvbDateUtil.isValidMoment(stichtag)) {
            this.subventioniertesKontingent = this.kinderOrt.getSubventioniertesKontingent(stichtag);
            this.firmenKontingente = this.kinderOrt.getFirmenKontingente(stichtag);
        } else {
            this.subventioniertesKontingent = null;
            this.firmenKontingente = [];
            this.workingCopy.kontingentId = null;
        }
    }

    private setKinderOrt(kinderOrt: KinderOrt): void {
        this.kinderOrt = kinderOrt;

        this.initKontingente();
    }

    private fetchKinderOrt(id: string): angular.IPromise<KinderOrt> {
        return this.kinderOrtService.get(id, {includes: '(kontingente)', cache: true});
    }
}

componentConfig.controller = DvbStundenKontingentForm;
angular.module('kitAdmin').component('dvbStundenKontingentForm', componentConfig);
