/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Firma} from '@dv/kitadmin/models';
import type {Adresse} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {FirmaService} from '../../common/service/rest/firmaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firma: '<',
    },
    template: require('./dvb-firma-stammdaten.html'),
    controllerAs: 'vm',
};

const MAX_PERCENTAGE_PER_DAY = 20;

export class DvbFirmaStammdaten implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['errorService', 'firmaService'];

    public firma!: Firma;
    public isAddAlternativeKibonAbzugEnabled: boolean = false;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly firmaService: FirmaService,
    ) {
    }

    public $onInit(): void {
        this.isAddAlternativeKibonAbzugEnabled = !this.firma.firmenPlatz && this.firma.alternativeKibonAbzug !== null;
    }

    public isRequired(param: string): boolean {
        const valid = param !== '';
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public isValidKibonAbzug(param: number): boolean {
        return DvbUtil.isDecimal(param) && param >= 0 && param <= MAX_PERCENTAGE_PER_DAY;
    }

    public removeKibonAbzugProTag(): void {
        this.firma.alternativeKibonAbzug = null;
        this.saveBasisData();
        this.isAddAlternativeKibonAbzugEnabled = false;
    }

    public saveFirmenPlatz(): void {
        if (this.firma.firmenPlatz && this.firma.kontakt === null) {
            return;
        }

        this.saveBasisData();
    }

    public cancel(): void {
        if (this.firma.isValid()) {
            return;
        }

        // Cancel on FirmenKontakt should not work as FirmenPlatz requires contakt details
        this.errorService.handleValidationError(this.firma.isValid(),
            'ERRORS.ERR_FIRMA_REQUIES_ADDRESS_NAME_AND_TELEFON');
    }

    public saveAdresse(neueAdresse: Adresse): void {
        if (!this.firma.kontakt) {
            return;
        }

        if (this.firma.kontakt && angular.equals(this.firma.kontakt.adresse, neueAdresse)) {
            return;
        }

        const origAdresse = this.firma.kontakt.adresse;
        this.firma.kontakt.adresse = neueAdresse;
        this.firmaService.update(this.firma).catch(error => {
            this.firma.kontakt!.adresse = origAdresse;
            console.error('Adresse konnte nicht gespeichert werden', error);
        });
    }

    public saveBasisData(): void {
        this.errorService.clearAll();

        if (!this.firma.isValid()) {
            this.errorService.handleValidationError(this.firma.isValid(),
                'ERRORS.ERR_FIRMA_REQUIES_ADDRESS_NAME_AND_TELEFON');

            return;
        }

        this.firmaService.update(this.firma);
    }
}

componentConfig.controller = DvbFirmaStammdaten;
angular.module('kitAdmin').component('dvbFirmaStammdaten', componentConfig);
