/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {SetFilterController} from './SetFilterController';

export class MultiSelectFilterController<T> extends SetFilterController<T> {

    public onSelect(valueToAdd: T): void {
        if (this.model.filterModel.has(valueToAdd)) {
            return;
        }

        this.model.filterModel.add(valueToAdd);
        this.notifyChangeListeners();
    }

    public onRemove(valueToRemove: T): void {
        if (this.model.filterModel.delete(valueToRemove)) {
            this.notifyChangeListeners();
        }
    }
}
