/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import type {StateOrName, StateService, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {CommunicationState} from '../../models/CommunicationState';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-communication.html'),
    controllerAs: 'vm',
};

export class DvbCommunication implements angular.IController {
    public static $inject: readonly string[] = ['$state', '$uiRouterGlobals', 'authStore'];

    public communicationTypes: readonly CommunicationState[] = [];

    public constructor(
        private $state: StateService,
        private $uiRouterGlobals: UIRouterGlobals,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.communicationTypes = Object.values(CommunicationState.STATES)
            .filter(s => this.authStore.isPermitted(s.permission));
    }

    public getCurrentStateName(): string | undefined {
        return this.$uiRouterGlobals.current.name;
    }

    public goTo(state: StateOrName): void {
        this.$state.go(state);
    }
}

componentConfig.controller = DvbCommunication;
angular.module('kitAdmin').component('dvbCommunication', componentConfig);
