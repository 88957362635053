<form name="updateModeForm" (ngSubmit)="submitForm()" novalidate>
    <div class="modal-body">
        <h1>{{ title | translate }}</h1>

        <div class="row">
            <div class="col-md-6 col-md-offset-3 text-left">
                @for (mode of modes; track trackByIdentity($index, mode)) {
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   name="{{mode}}"
                                   value="{{mode}}"
                                   [(ngModel)]="updateMode"
                                   required>
                            <span [translate]="'PERSONAL.TERMIN.UPDATE_MODE.' + mode"></span>
                        </label>
                    </div>
                }
            </div>
        </div>
    </div>

    <div class="modal-footer big-abstand-top">
        <dv-submit-cancel-buttons [submitLabel]="submitLabel" (cancel)="cancel.emit()"/>
    </div>
</form>
