/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {COMMUNICATION_STATES} from './communication-states';

angular.module('kitAdmin').config(communicationConfig);

export * from './component/dvb-bring-abhol-zeiten-form/dvb-bring-abhol-zeiten-form';
export * from './component/dvb-bring-abhol-zeiten-management/dvb-bring-abhol-zeiten-management';
export * from './component/dvb-bulk-email-form/dvb-bulk-email-form';
export * from './component/dvb-bulk-email-management/dvb-bulk-email-management';
export * from './component/dvb-bulk-email-history/dvb-bulk-email-history';
export * from './component/dvb-communication/dvb-communication';
export * from './component/dvb-communication-header/dvb-communication-header';
export * from './component/dvb-communication-kind-incomplete-recipients/dvb-communication-kind-incomplete-recipients';
export * from './component/dvb-communication-kind-selection/dvb-communication-kind-selection';
export * from './component/dvb-communication-kind-sent/dvb-communication-kind-sent';
export * from './component/dvb-communication-post-recipients/dvb-communication-post-recipients';
export * from './component/dvb-communication-ready-to-send/dvb-communication-ready-to-send';
export * from './component/dvb-communication-sent/dvb-communication-sent';
export * from './component/dvb-email-template/dvb-email-template';
export * from './component/dvb-email-template-form/dvb-email-template-form';
export * from './component/dvb-steuerbescheinigung-form/dvb-steuerbescheinigung-form';
export * from './component/dvb-steuerbescheinigung-management/dvb-steuerbescheinigung-management';
export * from './component/dvb-test-email/dvb-test-email';
// Add New Component JS Above

communicationConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

function communicationConfig($stateProvider: StateProvider): void {
    Object.values(COMMUNICATION_STATES).forEach(state => $stateProvider.state(state));
}
