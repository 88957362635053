/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BaseRechnungsPosition, Mandant} from '@dv/kitadmin/models';
import {MandantRechnungConfig, MandantRechnungConfigPosition} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbUtil, FormContext, isPersisted, LogFactory} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {take} from 'rxjs';
import type {MandantRechnungConfigService} from '../../service/mandantRechnungConfigService';

const LOG = LogFactory.createLog('DvbMandantRechnungConfig');

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        mandant: '<',
        config: '<?',
    },
    template: require('./dvb-mandant-rechnung-config.html'),
    controllerAs: 'vm',
};

export class DvbMandantRechnungConfig implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'mandantRechnungConfigService', 'errorService'];

    public mandant!: Persisted<Mandant>;
    public config!: MandantRechnungConfig;
    public positionFormContext: FormContext = new FormContext();

    public constructor(
        private readonly $state: StateService,
        private readonly mandantRechnungConfigService: MandantRechnungConfigService,
    ) {
    }

    public $onChanges(): void {
        if (!this.config) {
            this.config = new MandantRechnungConfig();
        }
    }

    public saveConfig(): void {
        const observable$ = isPersisted(this.config) ?
            this.mandantRechnungConfigService.update$(this.config) :
            this.mandantRechnungConfigService.create$(this.config, this.mandant.id);

        observable$.pipe(take(1)).subscribe({
            next: update => {
                this.config = update;
                this.positionFormContext.close();
            },
            error: err => LOG.error('failed to save config', err),
        });
    }

    public updatePositions(customPositions: BaseRechnungsPosition[]): void {
        this.config.positions = customPositions.map(pos => {
            if (pos instanceof MandantRechnungConfigPosition) {
                return pos;
            }

            return new MandantRechnungConfigPosition(null, pos.text, pos.count, pos.cost);
        });
        this.saveConfig();
    }

    public delete(position: Persisted<MandantRechnungConfigPosition>): void {
        const configId = checkPresent(this.config.id);
        this.mandantRechnungConfigService.deletePosition$(configId, position.id).pipe(
            take(1),
        ).subscribe({
            next: () => DvbUtil.removeFromArray(position, this.config.positions),
            error: err => LOG.error(`failed to delete MandantRechnungConfigPosition for config ${configId}`, err),
        });
    }
}

componentConfig.controller = DvbMandantRechnungConfig;
angular.module('kitAdmin').component('dvbMandantRechnungConfig', componentConfig);
