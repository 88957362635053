/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IKibonRefNrInfo} from '@dv/kitadmin/models';

export class SimpleKibonRefNrInfo implements IKibonRefNrInfo {

    public constructor(
        private readonly refNr: string,
    ) {
    }

    public static apiResponseTransformer(data: string): SimpleKibonRefNrInfo {
        return new SimpleKibonRefNrInfo(data);
    }

    public getRefNr(): string {
        return this.refNr;
    }
}
