<div class="dvb-fixposition row small-abstand-bottom">
    <div class="col-xs-4">
        <p class="tarifrechnung-result-title">
            <span data-translate="{{'KIND.FIX_POSITION_TYP_' + vm.fixPosition.typ}}"></span>
            <a href=""
               class="fix-position-loeschen pull-right"
               ng-click="vm.onDelete({fixPosition: vm.fixPosition})"
               data-translate="COMMON.LOESCHEN"
               dvb-auth
               require-permission="kita:manage:general">
            </a>
            <br>
            <span ng-bind="vm.fixPosition.leistungsDatum | amDateFormat: 'DD.MM.'"></span>,
            <span ng-bind="vm.kitaName"></span>
        </p>
    </div>

    <div class="col-xs-8">
        <dv-leistungsposition [leistungs-position]="vm.fixPosition"></dv-leistungsposition>
    </div>
</div>
