<h2 [translate]="terminType() ? 'PERSONAL.TERMIN.TYPE.EDIT' : 'PERSONAL.TERMIN.TYPE.NEW'"></h2>
<form [formGroup]="form()" (ngSubmit)="submitForm()">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   formControlName="name"
                   [maxlength]="255"
                   [placeholder]="'PERSONAL.TERMIN.TYPE.NAME' | translate"
                   [tooltip]="'PERSONAL.TERMIN.TYPE.NAME' | translate"
                   [attr.aria-label]="'PERSONAL.TERMIN.TYPE.NAME' | translate">
        </div>
    </div>
    <div class="row normal-abstand-bottom">
        <div class="col-md-6">
            <fieldset>
                <legend><h3>{{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE_TITLE' | translate }}</h3></legend>
                @for (type of workTimeTypes; track type) {
                    <div class="radio">
                        <label>
                            <input type="radio" formControlName="workTimeType" [value]="type">
                            <span [translate]="'PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE.' + type"></span>
                        </label>
                    </div>
                }
            </fieldset>
        </div>
        <div class="col-md-6">
            <fieldset>
                <legend><h3>{{ 'PERSONAL.TERMIN.TYPE.BEDARFSRECHNUNG_TITLE' | translate }}</h3></legend>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" value="true" formControlName="deductFromBedarfsrechnung">
                        <span translate="PERSONAL.TERMIN.TYPE.DEDUCT_FROM_BEDARFSRECHNUNG"></span>
                    </label>
                </div>
            </fieldset>
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading()" (cancel)="handleCancel()">
    </dv-submit-cancel-buttons>
</form>
