<div class="dvb-betreuungsfaktorregel-erstellen">
    <div class="row dvb-form">
        <div class="col-md-6 col-md-offset-3">
            <h1 data-translate="COMMON.BETREUUNGSFAKTOR_REGEL.ERSTELLEN"></h1>
            <form name="erstellenForm" ng-submit="vm.submit()" novalidate>
                <div class="form-section-minus-normal-abstand">
                    <div class="row">
                        <div class="col-md-10">
                            <input type="text"
                                   class="form-control"
                                   maxlength="255"
                                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.betreuungsfaktorRegel.name"
                                   required/>
                        </div>
                    </div>

                    <dvb-betreuungsfaktor-regel-form
                        betreuungsfaktor-regel="vm.betreuungsfaktorRegel"
                        on-submit="vm.submit()"
                        on-cancel="vm.cancel()"
                        is-loading="vm.isLoading">
                    </dvb-betreuungsfaktor-regel-form>
                </div>
            </form>
        </div>
    </div>
</div>
