/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FirmenKontingentValue} from '@dv/kitadmin/models';
import {DvbDateUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firmenKontingentValues: '<',
        onRevertValue: '&',
    },
    template: require('./dvb-firmen-plaetze-verlauf.html'),
    controllerAs: 'vm',
};

export class DvbFirmenPlaetzeVerlauf implements angular.IController {

    public static $inject: readonly string[] = ['errorService'];

    public firmenKontingentValues: FirmenKontingentValue[] = [];
    public onRevertValue?: (data: { value: FirmenKontingentValue }) => unknown;

    public today = DvbDateUtil.today();

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public revertFirmenKontingentValue(firmenKontingentValue: FirmenKontingentValue): void {
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
        if (TypeUtil.isFunction(this.onRevertValue)) {
            this.onRevertValue({value: firmenKontingentValue});
        }
    }

    public isFirmenKontingentValueBeendet(firmenKontingentValue: FirmenKontingentValue): boolean {
        return !DvbDateUtil.isEndOfTime(firmenKontingentValue.gueltigBis);
    }
}

componentConfig.controller = DvbFirmenPlaetzeVerlauf;
angular.module('kitAdmin').component('dvbFirmenPlaetzeVerlauf', componentConfig);
