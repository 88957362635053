/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition} from '@uirouter/core';
import type {IPromise} from 'angular';
import angular from 'angular';
import type {DokumentlayoutService} from './service/dokumentlayoutService';

angular.module('kitAdmin').config(dokumentlayoutConfig);

export * from './component/dvb-dokumentlayout-content/dvb-dokumentlayout-content';
export * from './component/dvb-dokumentlayout-erstellen/dvb-dokumentlayout-erstellen';
export * from './component/dvb-dokumentlayout-form/dvb-dokumentlayout-form';
export * from './component/dvb-dokumentlayout-profil/dvb-dokumentlayout-profil';
// Add New Component JS Above

dokumentlayoutConfig.$inject = ['$stateProvider'];

function dokumentlayoutConfig($stateProvider: StateProvider): void {

    $stateProvider.state('base.dokumentlayout', {
        url: '/dokumentlayout/{id:[0-9]+}',
        template: '<dvb-dokumentlayout-profil dokument-layout="$resolve.dokumentLayout"></dvb-dokumentlayout-profil>',
        resolve: {
            dokumentLayout: [
                'dokumentlayoutService', '$transition$',
                (
                    dokumentlayoutService: DokumentlayoutService,
                    $transition$: Transition,
                ): IPromise<DokumentLayout | null> => dokumentlayoutService.getDokumentLayout($transition$.params().id),
            ],
        },
        data: {
            permission: PERMISSION.KITA.VIEW_ANY,
        },
    });

    $stateProvider.state('base.dokumentlayout-erstellen', {
        url: '/dokumentlayout-erstellen',
        template: '<dvb-dokumentlayout-erstellen></dvb-dokumentlayout-erstellen>',
        data: {
            permission: PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN,
        },
    });
    /* Add New States Above */

}
