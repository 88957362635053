/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {MonthlyControllingFigures} from '../../../../report/models/MonthlyControllingFigures';
import {ControllingReportMonthlyComponent} from '../model/ControllingReportMonthlyComponent';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        controllingReport: '<?',
        jahr: '<',
        firstOfWeek: '<',
        lastOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-uebersicht-stunden.html'),
    controllerAs: 'vm',
};

export class DvbKitaUebersichtStunden extends ControllingReportMonthlyComponent implements angular.IController {
    public static override $inject: readonly string[] = [];

    public kinderOrt!: KinderOrt;
    public lastOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public sollStunden: number | null = null;
    public effektivStunden: number | null = null;

    public avgSollStunden: number | null = null;
    public avgEffektivStunden: number | null = null;
    public forecastStunden: number | null = null;

    protected setMonthlyFigures(monthlyFigures: MonthlyControllingFigures): void {
        this.sollStunden = monthlyFigures.sollStunden;
        this.effektivStunden = monthlyFigures.effektivStunden;
    }

    protected resetMonthlyFigures(): void {
        this.sollStunden = null;
        this.effektivStunden = null;
    }

    protected setAverageControllingFigures(controllingReport: ControllingReport): void {
        if (!controllingReport?.monthlyAverage) {
            this.resetAverageControllingFigures();

            return;
        }

        this.avgSollStunden = isPresent(controllingReport.monthlyAverage.sollStunden) ?
            controllingReport.monthlyAverage?.sollStunden : 0;
        this.avgEffektivStunden = isPresent(controllingReport.monthlyAverage.effektivStunden) ?
            controllingReport.monthlyAverage?.effektivStunden : 0;
        this.forecastStunden = isPresent(controllingReport.monthlyAverage.forecast) ?
            controllingReport.monthlyAverage?.forecast : 0;
    }

    private resetAverageControllingFigures(): void {
        this.avgEffektivStunden = 0;
        this.avgSollStunden = 0;
        this.forecastStunden = 0;
    }
}

componentConfig.controller = DvbKitaUebersichtStunden;
angular.module('kitAdmin').component('dvbKitaUebersichtStunden', componentConfig);
