/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable} from '@angular/core';
import {idFromLocation, splitIdRequestSources, toIdRequestSource} from '@dv/shared/angular';
import {
    CheckInEventService,
    CheckInEventServiceCreateEventRequestParams,
} from '@dv/shared/backend/api/check-in-event.service';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxKindCheckInEvent} from '@dv/shared/backend/model/jax-kind-check-in-event';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbRestUtil} from '@dv/shared/code';
import {adapt} from '@state-adapt/angular';
import {Source, splitRequestSources, toRequestSource, toSource} from '@state-adapt/rxjs';
import {combineLatest, concatMap, map, merge, Subject, switchMap, withLatestFrom} from 'rxjs';
import type {TagesinfoSaveEvent} from '../tagesinfo-form/tagesinfo-form.component';
import {initialState, tagesInfoAdapter} from './tagesinfo.adapter';

export type UpdateEvent = {
    event: Persisted<JaxKindCheckInEvent>;
    time: BackendLocalTimeHHMM;
};

@Injectable({
    providedIn: 'root',
})
export class TagesinfoStateService {

    public readonly datum$ = new Source<BackendLocalDate>('[TagesInfo] datum');
    public readonly createCheckIn$ = new Source<TagesinfoSaveEvent>(
        '[TagesInfo] create check in event');
    public readonly deleteCheckIn$ = new Source<EntityId>(
        '[TagesInfo] delete check in event');
    public readonly confirmDeleteCheckIn$ = new Subject<void>();
    public readonly restoreCheckIn$ = new Source<Persisted<JaxKindCheckInEvent>>(
        '[TagesInfo] restore check in event');
    public readonly updateCheckIn$ = new Source<UpdateEvent>('[TagesInfo] update check in event');

    public store = adapt(initialState(), {
        path: '[TagesInfo]',
        adapter: tagesInfoAdapter,
        sources: store => {
            const checkInEventService = inject(CheckInEventService);

            const deleteSource$ = this.confirmDeleteCheckIn$.pipe(
                withLatestFrom(this.deleteCheckIn$),
                map(([, {payload}]) => payload),
                toSource('[TagesInfo] confirm delete check in event'),
            );

            const deleteRequest = splitIdRequestSources('[TagesInfo API] delete', deleteSource$.pipe(
                concatMap(({payload}) => checkInEventService.deleteEvent$({kindCheckInEventId: payload}).pipe(
                    toIdRequestSource('[TagesInfo API] delete', payload),
                )),
            ));

            const createRequest = splitRequestSources('[TagesInfo API] create', this.createCheckIn$.pipe(
                withLatestFrom(store.kindId$, store.nextState$, store.datum$),
                concatMap(([{payload}, kindId, status, datum]) => {
                    const eventTimestamp = DvbRestUtil.toBackendZonedDateTime(payload.time, datum);
                    const requestParams = {
                        kindId,
                        kinderOrtId: checkPresent(payload.kinderOrt?.id),
                        jaxKindCheckInEvent: {status, eventTimestamp},
                    } satisfies CheckInEventServiceCreateEventRequestParams;

                    return checkInEventService.createEvent$(requestParams, 'response').pipe(
                        map((response): Persisted<JaxKindCheckInEvent> => {
                            const id = idFromLocation(response);

                            return {...requestParams.jaxKindCheckInEvent, id};
                        }),
                        toRequestSource('[TagesInfo API] create'),
                    );
                }),
            ));

            const restoreRequest = splitIdRequestSources('[TagesInfo API] restore', this.restoreCheckIn$.pipe(
                concatMap(({payload}) => checkInEventService.restoreEvent$({kindCheckInEventId: payload.id}).pipe(
                    map((): Persisted<JaxKindCheckInEvent> => {
                        const copy = {...payload};
                        copy.timestampGeloescht = undefined;

                        return copy;
                    }),
                    toIdRequestSource('[TagesInfo API] restore', payload.id),
                )),
            ));

            const updateRequest = splitIdRequestSources('[TagesInfo API] update', this.updateCheckIn$.pipe(
                withLatestFrom(store.datum$),
                concatMap(([{payload}, datum]) => checkInEventService.updateEvent$({
                        update: {time: payload.time},
                        kindCheckInEventId: payload.event.id,
                    },
                ).pipe(
                    map((): Persisted<JaxKindCheckInEvent> => ({
                        ...payload.event,
                        eventTimestamp: DvbRestUtil.toBackendZonedDateTime(payload.time, datum),
                    })),
                    toIdRequestSource('[TagesInfo API] update', payload.event.id),
                )),
            ));

            const getAllParams$ = combineLatest([
                store.kindId$,
                store.datum$.pipe(map(stichtag => ({stichtag}))),
            ]).pipe(
                map(([kindId, kindIdMatrix]) => ({kindId, kindIdMatrix})),
            );

            const reload$ = merge(
                getAllParams$,
                createRequest.success$,
                deleteRequest.success$,
            ).pipe(
                withLatestFrom(getAllParams$),
                map(([, payload]) => payload),
            );

            const getAllRequest = splitRequestSources('[TagesInfo API] getAll', reload$.pipe(
                switchMap(payload => checkInEventService.getAll$(payload).pipe(
                    map(response => response.status.map(s => checkPersisted(s))),
                    toRequestSource('[TagesInfo API] getAll'),
                )),
            ));

            return {
                setDatum: this.datum$,
                setItemsIsLoadingTrue: [
                    this.datum$,
                    this.createCheckIn$,
                    deleteSource$,
                ],
                setItemsIsLoadingFalse: [
                    createRequest.error$,
                    getAllRequest.success$,
                    getAllRequest.error$,
                    deleteRequest.success$,
                    deleteRequest.error$,
                ],
                addItems: createRequest.success$,
                updateItems: [
                    updateRequest.success$,
                    restoreRequest.success$,
                ],
                deleteItems: deleteRequest.success$,
                errorItems: [
                    updateRequest.error$,
                    deleteRequest.error$,
                    restoreRequest.error$,
                ],
                setItemsItemIsLoadingTrue: [
                    this.updateCheckIn$.pipe(
                        map(({payload}) => payload.event.id),
                        toSource('[TagesInfo] update'),
                    ),
                    this.restoreCheckIn$.pipe(
                        map(({payload}) => payload.id),
                        toSource('[TagesInfo] restore')),
                ],
                setItemsShowCreateModeFalse: createRequest.success$,
                setItemsShowDeleteDialogTrue: this.deleteCheckIn$,
                setItemsShowDeleteDialogFalse: deleteRequest.success$,
                initItemsWithEntities: getAllRequest.success$,
            };
        },
    });

    // the following methods are only here because typescript inference in angular templates doesn't work well in
    // intellij. It looks like the methods don't exist, even though they do.
    public setIsLoadingTrue(): void {
        this.store.setItemsIsLoadingTrue();
    }

    public enableCreateMode(): void {
        this.store.setItemsShowCreateModeTrue();
    }

    public disableCreateMode(): void {
        this.store.setItemsShowCreateModeFalse();
    }

    public hideDeleteDialog(): void {
        this.store.setItemsShowDeleteDialogFalse();
    }

    public setReadonlyMode(entity: Persisted<JaxKindCheckInEvent>): void {
        this.store.setItemsReadonlyMode(entity.id);
    }

    public setEditMode(entity: Persisted<JaxKindCheckInEvent>): void {
        this.store.setItemsEditMode(entity.id);
    }

    public toggleExpanded(entity: Persisted<JaxKindCheckInEvent>): void {
        this.store.toggleItemsExpanded(entity.id);
    }
}
