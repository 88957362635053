/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameter} from '@dv/kitadmin/models';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifParameter: '<',
        existingTarifParameters: '<?',
    },
    template: require('./dvb-tarif-parameter-input.html'),
    controllerAs: 'vm',
};

export class DvbTarifParameterInput implements angular.IController {

    public tarifParameter!: TarifParameter;
    public existingTarifParameter?: TarifParameter[] = [];

    public tarifParamTypes = Object.values(TarifParameterType);

    public updateParameterSelection(): void {
        this.tarifParameter.id = null;
    }

    public onSelectExistingParameter($item: unknown, $model: TarifParameter): void {
        this.tarifParameter.id = $model.id;
        this.tarifParameter.dtype = $model.dtype;
        this.tarifParameter.name = $model.name;
    }
}

componentConfig.controller = DvbTarifParameterInput;
angular.module('kitAdmin').component('dvbTarifParameterInput', componentConfig);
