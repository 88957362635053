/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KindKontaktRelationship} from '@dv/kitadmin/models';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        hasAdresse: '<',
        kindKontaktRelationship: '<',
        onChange: '&',
    },
    template: require('./dvb-rechnungsempfaenger-input.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsempfaengerInput implements angular.IController {
    public static $inject: readonly string[] = [
        '$scope',
        'errorService',
    ];

    public hasAdresse!: boolean;
    public kindKontaktRelationship!: KindKontaktRelationship;
    public onChange!: () => void;

    public isRechnungsempfaenger?: boolean;

    public constructor(
        private readonly $scope: angular.IScope,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.isRechnungsempfaenger = this.kindKontaktRelationship.isRechnungsempfaenger();
        this.$scope.$watch('vm.kindKontaktRelationship.rechnungsempfaenger', () => {
            this.isRechnungsempfaenger = this.kindKontaktRelationship.isRechnungsempfaenger();
        });
    }

    public changeRechnungsempfaenger(): void {
        if (!this.kindKontaktRelationship.isRechnungsempfaenger() && !this.hasAdresse) {
            this.errorService.addValidationError('ERRORS.ERR_RECHNUNGSEMPFAENGER_REQUIRES_ADDRESS');
            this.isRechnungsempfaenger = false;

            return;
        }
        this.isRechnungsempfaenger = this.kindKontaktRelationship.isRechnungsempfaenger();

        if (TypeUtil.isFunction(this.onChange)) {
            this.onChange();
        }
    }

}

componentConfig.controller = DvbRechnungsempfaengerInput;
angular.module('kitAdmin').component('dvbRechnungsempfaengerInput', componentConfig);
