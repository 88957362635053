/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';
import type {NamedEntityType} from '@dv/shared/code';
import type {Notiz} from '../models/Notiz';
import type {NotizService} from './notiz-service';

/**
 * DataSource for loading NamedEntityKorrespondenzScrollDataSource for the ui-scroll directive.
 * Requires the start-index of ui-scroll to be set to 0.
 */
export class NamedEntityKorrespondenzScrollDataSource
    implements angular.ui.IScrollDatasource<Notiz> {

    public maxIndex: number | null = null;
    public minIndex: number = 0;

    public constructor(
        private notizService: NotizService,
        public entityId: string,
        public entityType: NamedEntityType,
    ) {
    }

    public get(index: number, count: number, success: (notiz: Notiz[]) => any): void {
        // Even with a ui-scroll start-index of 0, scrolling up will attempt to fetch records with negative index
        // --> ensure a positive index and adjust the count accordingly
        const realIndex = Math.max(0, index);
        const realCount = index < 0 ? index + count : count;

        if (realCount <= 0) {
            success([]);

            return;
        }

        const params = {
            top: realCount,
            skip: realIndex,
            includes: '(user_erstellt,blob_infos)',
        };

        this.notizService.getNotizen(this.entityId, this.entityType, params)
            .then(notizen => {
                success(notizen.items);
                this.maxIndex = notizen.count;
            });
    }
}
