<div class="dvb-zahlung-ausgleichen-rechnung big-abstand-top">
    <div class="row">
        <div class="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
            <h2 data-translate="FAKTURA.RECHNUNG_AUFTEILEN"
                data-translate-value-betrag="{{-vm.ausstehenderBetrag}}">
            </h2>
            <dvb-rechnungen rechnungen="vm.rechnungen"
                            is-loading="vm.isLoading"
                            mode="vm.mode">
            </dvb-rechnungen>
        </div>
    </div>

    <div class="row big-abstand-top">
        <div class="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
            <p>
                <a ui-sref="base.rechnung.zahlungen" data-translate="COMMON.ABBRECHEN"></a>
            </p>
        </div>
    </div>

    <!-- Confirm dialog view -->
    <div ui-view></div>
</div>
