/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';
import type {ChartData, ChartPoint} from 'chart.js';
import moment from 'moment';
import type {StyleVariableService} from '../../../../common/service/style-variable.service';

/* eslint-disable no-underscore-dangle */

/**
 * Defines attributes that must be present on a chart component, which uses a ChartContext.
 */
export interface ChartComponent {
    $scope: angular.IScope;
    styleVariableService: StyleVariableService;

    onSetWeek: (param: { newFirstOfWeek: moment.Moment }) => any;
}

/**
 * A chart's context.
 * Used for event handling and custom functionality like the tooltip position or the vertical line.
 */
export class ChartContext {

    public chart: Chart | null = null;
    public lastIndex?: number;
    public ctx2d: CanvasRenderingContext2D;

    public constructor(
        public chartComponent: ChartComponent,
        public chartData: ChartData,
        public canvas: HTMLElement,
    ) {
        this.ctx2d = (canvas as HTMLCanvasElement).getContext('2d')!;
    }

    public onMouseLeave(): void {
        // clear index line and rerender
        this.lastIndex = undefined;
        if (this.chart) {
            this.chart.render();
        }
    }

    public onHover(event: MouseEvent): void {
        // set last index where the mouse was last
        // this will be used on next draw call to draw an index line
        this.lastIndex = this.getActivePointIndex(event);
    }

    public updateWeek(event: MouseEvent): void {
        const index = this.getActivePointIndex(event);

        if (!index) {
            return;
        }

        const dataSet = this.chartData.datasets![0];
        // inp ist eigentlich bereits ein moment, wird aber von den Typings nicht unterstützt
        const inp = (dataSet.data![index]! as ChartPoint).x;
        const date = moment(inp);

        // muss $evalAsync nutzen, da der Click Angular nicht bekannt ist und somit nicht immer ein Digest cycle
        // getriggert wird.
        this.chartComponent.$scope.$evalAsync(() => {
            this.chartComponent.onSetWeek({newFirstOfWeek: date});
        });
    }

    public drawIndexLine = (chartInstance: Chart): void => {
        if (!this.lastIndex) {
            return;
        }

        const data = chartInstance.getDatasetMeta(0).data;
        const metaDatum = data[this.lastIndex];
        if (!metaDatum) {
            return;
        }

        const model = metaDatum._model;
        const chartArea = chartInstance.chartArea;
        const x = model.x;

        this.ctx2d.beginPath();
        this.ctx2d.strokeStyle = this.chartComponent.styleVariableService.getColorMainNormal();
        this.ctx2d.moveTo(x, chartArea.top);
        this.ctx2d.lineTo(x, chartArea.bottom);
        this.ctx2d.stroke();
    };

    private getActivePointIndex(evt: MouseEvent): number | undefined {
        const activePoints = (this.chart! as any).getElementsAtEventForMode(evt, 'index', {intersect: false});
        const dataSet = this.chartData.datasets![0];

        if (!activePoints[0] || !dataSet?.data![activePoints[0]._index]) {
            return undefined;
        }

        return activePoints[0]._index;
    }
}
