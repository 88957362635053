/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, KinderOrt} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {MonatsBelegungRestService} from '../../../common/service/rest/kind/monatsBelegungRestService';
import type {BringAbholZeitenReleaseDialogModel} from '../../../kind/zuweisung';
import {BringAbholZeitenReleaseComponent} from '../../../kind/zuweisung';
import type {BringAbholZeitenState} from '../../models/bring-abhol-zeiten/BringAbholZeitenState';
import {ReleaseType} from '../../models/ReleaseType';
import type {BringAbholZeitenService} from '../../service/bring-abhol-zeiten-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        gueltigAb: '<',
        bringAbholState: '<',
    },
    template: require('./dvb-bring-abhol-zeiten-management.html'),
    controllerAs: 'vm',
};

export class DvbBringAbholZeitenManagement implements angular.IController {
    public static $inject: readonly string[] = [
        'dialogService',
        'dvbStateService',
        'monatsBelegungRestService',
        'bringAbholZeitenService',
        '$state',
        '$translate',
    ];

    public kinderOrt!: Persisted<KinderOrt>;
    public gueltigAb!: moment.Moment;
    public bringAbholState!: BringAbholZeitenState;

    public isLoadingMonatsBelegungen: boolean = false;

    public constructor(
        private dialogService: DialogService,
        private dvbStateService: DvbStateService,
        private monatsBelegungRestService: MonatsBelegungRestService,
        private bringAbholZeitenService: BringAbholZeitenService,
        private $state: StateService,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public release(kinder: Persisted<Kind>[]): void {
        this.openDialog(kinder, ReleaseType.RELEASE);
    }

    public resend(kinder: Persisted<Kind>[]): void {
        this.openDialog(kinder, ReleaseType.RESEND);
    }

    public adjustDeadline(kinder: Persisted<Kind>[]): void {
        this.openDialog(kinder, ReleaseType.ADJUST_DEADLINE);
    }

    public withdrawFromParents(kinder: Persisted<Kind>[]): void {
        this.openDialog(kinder, ReleaseType.WITHDRAW);
    }

    public goBack(): void {
        this.dvbStateService.goToPreviousState();
    }

    public createMonatsBelegungen(kinder: Persisted<Kind>[]): void {
        const title = this.$translate.instant('COMMUNICATION.BRING_ABHOLZEITEN.CREATE_MONATSBELEGUNG_MF',
            {count: kinder.length, showcount: true},
            'messageformat');

        const confirm = (): Observable<unknown> => from(this.monatsBelegungRestService.createMonatsBelegungen(
            this.gueltigAb, this.kinderOrt.id, kinder.map(kind => kind.id))
            .then(() => this.$state.reload()));

        this.dialogService.openConfirmDialog({
            title,
            confirm,
        });
    }

    private openDialog(kinder: Persisted<Kind>[], type: ReleaseType): void {

        const dialogModel: BringAbholZeitenReleaseDialogModel = {
            kinder,
            kinderOrt: this.kinderOrt,
            kinderOrtExclusive: true,
            type,
            periodFrom: DvbDateUtil.startOfMonth(moment(this.gueltigAb)),
            periodTo: DvbDateUtil.endOfMonth(moment(this.gueltigAb)),
            deadline: moment.max(this.bringAbholState.withExistingEmails.map(access => access.writeAccessUntil!)),
            bringAbholZeitenService: this.bringAbholZeitenService,
        };

        this.dialogService.openDialog(BringAbholZeitenReleaseComponent, dialogModel);
    }
}

componentConfig.controller = DvbBringAbholZeitenManagement;
angular.module('kitAdmin').component('dvbBringAbholZeitenManagement', componentConfig);
