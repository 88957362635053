/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import {KinderOrt} from '@dv/kitadmin/models';
import type {DialogModel} from '@dv/shared/angular';
import {
    ButtonListComponent,
    DatepickerTextfieldComponent,
    DvLocalDateFormatPipe,
    ElasticTextInputDirective,
    LoadingButtonComponent,
} from '@dv/shared/angular';
import {checkPresent, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SpfInfoComponent} from '../../../../common/component/spf-info/spf-info.component';
import {BringAbholZeitenParams} from '../../../../communication/models/bring-abhol-zeiten/BringAbholZeitenParams';
import {
    CreateBringAbholZeitenAccess,
} from '../../../../communication/models/bring-abhol-zeiten/CreateBringAbholZeitenAccess';
import {ReleaseType} from '../../../../communication/models/ReleaseType';
import {BringAbholZeitenService} from '../../../../communication/service/bring-abhol-zeiten-service';

export interface BringAbholZeitenReleaseDialogModel extends DialogModel {
    kinder: Kind[];
    kinderOrt: KinderOrt;
    kinderOrtExclusive?: boolean;
    type: ReleaseType;
    periodFrom: moment.Moment;
    periodTo: moment.Moment;
    deadline: moment.Moment | null;
    bringAbholZeitenService: BringAbholZeitenService;
}

enum WithdrawType {
    READONLY = 0,
    FULL = 1,
}

@Component({
    selector: 'dv-bring-abhol-zeiten-release',
    templateUrl: './bring-abhol-zeiten-release.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        TranslateModule,
        TooltipModule,
        LoadingButtonComponent,
        DatepickerTextfieldComponent,
        DvLocalDateFormatPipe,
        SpfInfoComponent,
        ElasticTextInputDirective,
        ButtonListComponent,
    ],
})
export class BringAbholZeitenReleaseComponent implements BringAbholZeitenReleaseDialogModel, OnInit {

    @Input() public readonly kinder!: Kind[];
    @Input() public readonly kinderOrt!: KinderOrt;
    @Input() public readonly kinderOrtExclusive: boolean = false;
    @Input() public readonly type!: ReleaseType;
    @Input() public readonly periodFrom!: moment.Moment;
    @Input() public readonly periodTo!: moment.Moment;
    @Input() public readonly deadline!: moment.Moment | null;

    // inject the service once migrated
    @Input() public readonly bringAbholZeitenService!: BringAbholZeitenService;

    public readonly releaseType: typeof ReleaseType = ReleaseType;
    public readonly withdrawType: typeof WithdrawType = WithdrawType;

    public isLoading: boolean = false;

    public selectedWithdrawType: WithdrawType = WithdrawType.READONLY;
    public recipientAddress: string | null = null;

    public model: CreateBringAbholZeitenAccess = new CreateBringAbholZeitenAccess(
        DvbDateUtil.today(),
        DvbDateUtil.today(),
        '-1',
        false);

    private typesWithDeadlineAdjustment: ReleaseType[] = [
        ReleaseType.RELEASE,
        ReleaseType.ADJUST_DEADLINE,
    ];

    public constructor(
        private bsModalRef: BsModalRef,
        private stateService: StateService,
        private errorService: ErrorService,
    ) {
    }

    public ngOnInit(): void {
        this.model =
            new CreateBringAbholZeitenAccess(
                this.periodFrom,
                this.periodTo,
                checkPresent(this.kinderOrt.id),
                this.kinderOrtExclusive);

        this.model.replyToAddress = this.kinderOrt.email;

        if (this.kinder.length === 1) {
            const hauptkontakt = this.kinder[0].findAnyHauptkontaktRelationship();
            this.recipientAddress = hauptkontakt ? hauptkontakt.kontaktperson!.email : null;
        } else {
            this.recipientAddress = null;
        }

        // set the new deadline to the value of the previous one, unless we specifically want to set a new deadline
        this.model.deadline = this.deadline && ReleaseType.ADJUST_DEADLINE !== this.type ?
            moment(this.deadline) :
            null;

        if (ReleaseType.WITHDRAW !== this.type) {
            return;
        }

        // access will be withdrawn --> set deadline in the past
        this.model.deadline = moment().subtract(1, 'days');
        if (!this.allowDisablingWriteAccess()) {
            this.selectedWithdrawType = WithdrawType.FULL;
        }
    }

    public submitForm(sendEmail: boolean = true): void {

        const valid = this.handleValidation();
        if (!valid) {
            return;
        }

        this.isLoading = true;

        this.submitToBackend(sendEmail)
            .then(() => this.stateService.reload())
            .then(() => {
                this.bsModalRef.hide();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public getHeader(): string {
        switch (this.type) {
            case ReleaseType.RESEND:
                return 'KIND.MONATSBELEGUNG.RESEND_HEADER_MF';
            case ReleaseType.ADJUST_DEADLINE:
                return 'KIND.MONATSBELEGUNG.ADJUST_DEADLINE_HEADER_MF';
            case ReleaseType.WITHDRAW:
                return 'KIND.MONATSBELEGUNG.WITHDRAW_HEADER';
            case ReleaseType.CONFIRM:
                return 'KIND.MONATSBELEGUNG.CONFIRM_HEADER';
            default:
                return 'KIND.MONATSBELEGUNG.RELEASE_HEADER_MF';
        }
    }

    public allowDeadlineModification(): boolean {
        return this.typesWithDeadlineAdjustment.includes(this.type);
    }

    public allowDisablingWriteAccess(): boolean {
        return moment().isSameOrBefore(this.deadline);
    }

    public getKindName(): string {
        if (this.kinder.length === 1) {
            return this.kinder[0].getDisplayName();
        }

        return '';
    }

    public hide(): void {
        this.bsModalRef.hide();
    }

    private handleValidation(): boolean {
        this.errorService.clearAll();
        if (this.type !== ReleaseType.WITHDRAW) {
            const valid = DvbDateUtil.isValidMoment(this.model.deadline);
            this.errorService.handleValidationError(valid,
                'ERRORS.ERR_INCOMPLETE_FORM');

            return valid;
        }

        return true;
    }

    private submitToBackend(sendEmail: boolean): angular.IHttpPromise<unknown> {
        const model = angular.copy(this.model);
        // apply default values
        model.updateData = true;
        model.sendEmails = sendEmail;
        model.replyToAddress = model.replyToAddress ?? this.kinderOrt.email;
        model.kindIds = this.getKindIds();

        // deadlines are inclusive -> set the time to the max
        if (model.deadline) {
            model.deadline = model.deadline.endOf('day');
        }

        switch (this.type) {
            case ReleaseType.RELEASE:
                return this.bringAbholZeitenService.createBringAbholZeitenAccess(model);

            case ReleaseType.RESEND:
                model.updateData = false;

                return this.bringAbholZeitenService.updateBringAbholZeitenAccess(model);

            case ReleaseType.ADJUST_DEADLINE:
                return this.bringAbholZeitenService.updateBringAbholZeitenAccess(model);

            case ReleaseType.CONFIRM:
                return this.bringAbholZeitenService.confirmBringAbholZeiten(BringAbholZeitenParams.copy(model));

            case ReleaseType.WITHDRAW:
                model.sendEmails = false;

                return WithdrawType.FULL === this.selectedWithdrawType
                    ? this.bringAbholZeitenService.withdrawBringAbholZeitenAccess(BringAbholZeitenParams.copy(model))
                    : this.bringAbholZeitenService.updateBringAbholZeitenAccess(model);

            default:
                throw new Error(`no action defined for ${JSON.stringify(this.type)}`);
        }
    }

    private getKindIds(): string[] {
        return this.kinder.map(DvbUtil.mapToIdChecked);
    }
}
