<div class="dvb-limited-entity-form dvb-form">

    <form name="limitedEntityForm" ng-submit="vm.submit(limitedEntityForm.$valid)" novalidate>

        <div ng-transclude="entityForm"></div>

        <div class="normal-abstand-top">
            <ul class="list-inline">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="{{vm.translationRoot + '.ZUWEISEN'}}"></span>
                    </dvb-loading-button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            data-translate="COMMON.ABBRECHEN"
                            ng-click="vm.cancel()">
                    </button>
                </li>
            </ul>
        </div>

    </form>
</div>
