/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ZeitraumFeld} from '@dv/kitadmin/models';
import type {BetreuungsZeitraum, DayOfWeek, IZeitraum, IZeitraumCollection} from '@dv/shared/code';
import {DvbDateUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {ZeitraumFeldClickEventCallback} from './ZeitraumFeldClickEvent';
import {ZeitraumFeldClickModifier} from './ZeitraumFeldClickModifier';

const directive: angular.IDirective<angular.IScope, JQuery, angular.IAttributes, DvbWochenplan> = {
    restrict: 'E',
    replace: true,
    scope: {
        zeitraumFelder: '<',
        weekDays: '<',
        weekDayTitles: '<?',
        distinctBetreuungsZeitraeume: '<',
        interactive: '<',
        isEditable: '<',
        showNullValueAsPlain: '<', // TODO workaround, soll generischer für den wochenplan sein
        showBetreuungsZeitraumName: '<', // shows zeitraum namen on-hover
        showZeiten: '<?', // shows BetreuungsZeitraum von & bis as 1st column
        showNames: '<?', // shows BetreuungsZeitraum name as 1st column
        selectableZeitraumCollections: '<',
        onZeitraumFeldEdited: '&',
        onZeitraumFeldClicked: '&',
    },
    controllerAs: 'vm',
    bindToController: true,
    template: require('./dvb-wochenplan.html'),
    link,
};

function link(_scope: angular.IScope, element: JQuery, attrs: angular.IAttributes, ctrl?: DvbWochenplan): void {
    element.on('keypress', event => {
        if (event.key !== 'Enter') {
            return;
        }
        const input = element.find('input:focus');
        event.preventDefault();
        input.trigger('blur');
    });
    attrs.$observe('disabled', value => {
        ctrl!.isDisabled = !!value;
    });
}

// TODO es wäre gut einen Belegungsplan zu haben, der die ausgewählten BelegungsEinheiten selbst validiert, mit
// einem ngModel [{BelegungsEinheitId, Wochentag}]. Dieser sollte dann für Bewerbung und Belegung funktionieren
export class DvbWochenplan implements angular.IController {

    public static $inject: readonly string[] = [];

    /**
     * Model der anzuzeigenden Zeitraum Felder
     */
    public zeitraumFelder?: ZeitraumFeld[] = [];
    public weekDays: DayOfWeek[] = [];
    public weekDayTitles?: { [k in DayOfWeek]?: string };
    /**
     * BetreuungsZeitraeume, welche über alle Tage existieren (entspricht Zeilen)
     */
    public distinctBetreuungsZeitraeume: IZeitraum[] = [];
    /**
     * TRUE falls, der Wochenplan nur statisch angezeigt wird (keine Selektion moeglich)
     */
    public interactive!: boolean;
    /**
     * TRUE, wenn der Wochenplan editierbar sein soll --> input felder
     */
    public isEditable!: boolean;
    /**
     * TRUE, wenn Eintraege trotz null-value im Zeitraumfeld optisch als buchbar angezeigt werden sollen
     */
    public showNullValueAsPlain!: boolean;
    public showBetreuungsZeitraumName!: boolean;
    public showZeiten: boolean = false;
    public showNames: boolean = false;

    /**
     * Collections of Zeitraeume which can be used to instantly select the included Zeitraeume on the wochenplan.
     */
    public selectableZeitraumCollections: IZeitraumCollection[] = [];

    /**
     * wird aufgerufen, wenn das Feld editiert wurde
     */
    public onZeitraumFeldEdited!: (params: { zeitraumFeld: ZeitraumFeld }) => unknown;

    /**
     * Wird aufgerufen, wenn auf ein ZeitraumFeld geklickt wird
     */
    public onZeitraumFeldClicked!: ZeitraumFeldClickEventCallback;

    public isDisabled: boolean = false;

    public activeWeekDays: { [day: string]: boolean } = {};

    // Cache fuer getCurrentField
    private zeitraumFeld: ZeitraumFeld | null = null;

    private sortedZeitraumFelder: ZeitraumFeld[] = [];

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.zeitraumFelder) {

            // create an array of the zeitraumFelder in the order in which they are displayed
            this.sortedZeitraumFelder = (this.zeitraumFelder ?? []).sort((a, b) => {
                const dayA = DvbDateUtil.getIsoWeekDayNumber(a.dayOfWeek);
                const dayB = DvbDateUtil.getIsoWeekDayNumber(b.dayOfWeek);
                const dayDiff = dayA - dayB;
                if (dayDiff !== 0) {
                    return dayDiff;
                }

                return this.distinctBetreuungsZeitraeume.indexOf(a.zeitraum) -
                    this.distinctBetreuungsZeitraeume.indexOf(b.zeitraum);
            });

            this.activeWeekDays = {};
            this.sortedZeitraumFelder.forEach(zf => this.activeWeekDays[zf.dayOfWeek] = true);
        }
    }

    public getCurrentField(dayOfWeek: DayOfWeek, zeitraum: IZeitraum): ZeitraumFeld | null {

        if (this.zeitraumFeld && zeitraum.isSame(this.zeitraumFeld, dayOfWeek)) {
            return this.zeitraumFeld;
        }

        const results = (this.zeitraumFelder ?? []).filter(feld => zeitraum.isSame(feld, dayOfWeek));

        if (results && results.length === 1) {
            this.zeitraumFeld = results[0];

            return results[0];
        }

        this.zeitraumFeld = null;

        return null;
    }

    public printDayOfWeek(dayOfWeek: DayOfWeek): string {
        return this.weekDayTitles?.[dayOfWeek] ?? DvbDateUtil.getDayOfWeekMoment(dayOfWeek, moment()).format('dd');
    }

    public toggleZeitraumFeldSelection(zeitraumFeld: ZeitraumFeld, $event: MouseEvent): void {
        this.toggleFeld(zeitraumFeld, $event, false);
    }

    public rightClick(zeitraumFeld: ZeitraumFeld, $event: MouseEvent): void {
        this.toggleFeld(zeitraumFeld, $event, true);
    }

    public mouseDown($event: MouseEvent): void {
        if ($event.shiftKey) {
            // We use shift key for selecting multiple zeitraumfelder.
            // Prevent default on mouse down prevents additionally selecting all the text
            $event.preventDefault();
        }
    }

    public showZeitraumName(dayOfWeek: DayOfWeek, zeitraum: BetreuungsZeitraum): boolean {
        return this.showBetreuungsZeitraumName
            && !this.isDisabled
            && this.getCurrentField(dayOfWeek, zeitraum) !== null
            && (!this.getCurrentField(dayOfWeek, zeitraum)!.value || this.interactive || this.isEditable);
    }

    public selectZeitraumCollection(collection: IZeitraumCollection, dayOfWeek: DayOfWeek): void {
        this.sortedZeitraumFelder.filter(zf => zf.dayOfWeek === dayOfWeek)
            .forEach(zf => {
                const inCollection = collection.getZeitraumIds().includes(zf.zeitraum.id!);
                if (zf.selected !== inCollection) {
                    this.toggleFeld(zf, null, false);
                }
            });
    }

    private isToggleAllowed(zeitraumFeld: ZeitraumFeld): boolean {
        if (this.isEditable) {
            return false;
        }

        return !this.isDisabled && this.interactive && !!zeitraumFeld;
    }

    private toggleFeld(zeitraumFeld: ZeitraumFeld, $event: MouseEvent | null, rightClick: boolean): void {
        if (!this.isToggleAllowed(zeitraumFeld)) {
            return;
        }

        zeitraumFeld.selected = !zeitraumFeld.selected;

        if ($event?.shiftKey && zeitraumFeld.selected) {
            this.shiftSelect($event, zeitraumFeld);

            return;
        }
        if (rightClick) {
            this.notifyZeitraumFeldClick(zeitraumFeld, $event, ZeitraumFeldClickModifier.RIGHT);
        } else {
            this.notifyZeitraumFeldClick(zeitraumFeld, $event, ZeitraumFeldClickModifier.LEFT);
        }
    }

    /**
     * Also select any zeitraeume between the current zeitraumFeld and the "closest" already selected feld
     */
    private shiftSelect($event: MouseEvent, zeitraumFeld: ZeitraumFeld): void {

        const feldIndex = this.sortedZeitraumFelder.indexOf(zeitraumFeld);
        let shiftSelectionOccured = false;

        for (let i = feldIndex - 1; i >= 0; i--) {
            if (this.sortedZeitraumFelder[i].selected) {
                for (let j = i + 1; j < feldIndex; j++) {
                    shiftSelectionOccured = true;
                    this.sortedZeitraumFelder[j].selected = true;
                    // always notify right click so that e.g. platzarten of a previous selection are reused
                    this.notifyZeitraumFeldClick(this.sortedZeitraumFelder[j], $event, ZeitraumFeldClickModifier.SHIFT);
                }

                break;
            }
        }
        if (shiftSelectionOccured) {
            // also notify for the feld that was clicked
            this.notifyZeitraumFeldClick(zeitraumFeld, $event, ZeitraumFeldClickModifier.SHIFT);
        }
    }

    private notifyZeitraumFeldClick(
        zeitraumFeld: ZeitraumFeld,
        $event: MouseEvent | null,
        modifier: ZeitraumFeldClickModifier,
    ): void {
        if (TypeUtil.isFunction(this.onZeitraumFeldClicked)) {
            this.onZeitraumFeldClicked({zeitraumFeld, event: $event, modifier});
        }
    }
}

directive.controller = DvbWochenplan;
angular.module('kitAdmin').directive('dvbWochenplan', () => directive);
