/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ZeitraumFeld} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import {AusbildungsPersonalBedarf} from '../../betreuungs-schluessel/models/AusbildungsPersonalBedarf';

export class DienstBedarf {

    public constructor(
        public dienstId: string | null = null,
        public wochentag: DayOfWeek | null = null,
        public ausbildungsBedarf: AusbildungsPersonalBedarf[] = [],
        public maxBelegtePlaetze: number | null = null,
        public totalChildCount: number | null = null,
        public bezugsPersonIds: string[] = [],
        public fromKinderOrtRegel: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): DienstBedarf {

        const result = new DienstBedarf(data.dienstId, data.wochentag);
        result.maxBelegtePlaetze = data.maxBelegtePlaetze;
        result.totalChildCount = data.totalChildCount;
        result.bezugsPersonIds = data.bezugsPersonIds ?? [];
        result.fromKinderOrtRegel = data.fromKinderOrtRegel;

        result.ausbildungsBedarf = DvbRestUtil.transformArray(data.ausbildungsBedarf, AusbildungsPersonalBedarf);

        return result;
    }

    public affects(zf: ZeitraumFeld): boolean {
        return this.wochentag === zf.dayOfWeek && this.dienstId === zf.zeitraum.id;
    }
}
