<div class="dvb-konten" dvb-accordions>
    <h2 data-translate="FAKTURA.KONTO.KONTEN"></h2>

    <p ng-if="vm.konten.length === 0"
       class="normal-abstand-bottom"
       data-translate="FAKTURA.KONTO.KEINE_KONTEN">
    </p>

    <div ng-repeat="konto in vm.konten | orderBy: 'name'" ng-init="workingCopy = (konto | copy)">
        <dvb-accordion enable-edit="true"
                       enable-delete="true"
                       show-content-disabled="true"
                       entity-name="'COMMON.KONTO' | translate"
                       is-first="$first"
                       dvb-auth
                       require-permission="{{['kita:administrate:general', 'module:faktura']}}"
                       on-unauthorised-disable
                       on-submit="vm.onUpdate(workingCopy, konto, context, form)"
                       on-cancel="vm.onCancelUpdate(workingCopy, konto)"
                       on-delete="vm.onDelete(konto)">

            <panel-header class="full-width-block">
                <div class="row">
                    <div class="col-xs-6">
                        <p ng-bind="konto.bezeichnung"></p>
                    </div>
                    <div class="col-xs-6 text-right">
                        <p ng-bind="konto.iban"></p>
                    </div>
                </div>
            </panel-header>
            <edit-content>
                <dvb-konto-form konto="workingCopy">
                </dvb-konto-form>
            </edit-content>
        </dvb-accordion>
    </div>

    <div class="big-abstand-top" dvb-auth require-permission="{{['kita:administrate:general', 'module:faktura']}}">
        <button type="button"
                class="btn btn-primary"
                ng-show="!vm.showNewForm"
                ng-click="vm.initNewForm()">
            <span data-translate="FAKTURA.KONTO.NEU"></span>
        </button>

        <div ng-if="vm.showNewForm">
            <h2 data-translate="FAKTURA.KONTO.NEU"></h2>

            <form name="kontoForm" ng-submit="vm.createKonto(kontoForm)" novalidate autocomplete="off">
                <dvb-konto-form konto="vm.konto"></dvb-konto-form>
                <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                           on-cancel="vm.showNewForm = false">
                </dvb-submit-cancel-buttons>
            </form>
        </div>
    </div>
</div>
