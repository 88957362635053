/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {Notiz} from '../../models/Notiz';
import {NOTIZ_EDIT_STATE} from '../../notiz-states';
import type {NotizService} from '../../service/notiz-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        notiz: '<',
    },
    template: require('./dvb-notiz-timeline-details.html'),
    controllerAs: 'vm',
};

export class DvbNotizTimelineDetails implements angular.IController {
    public static $inject: readonly string[] = ['notizService', '$state', 'dialogService'];

    public notiz!: Persisted<Notiz>;

    public notizEditState = NOTIZ_EDIT_STATE;

    public constructor(
        private readonly notizService: NotizService,
        private readonly $state: StateService,
        private readonly dialogService: DialogService,
    ) {
    }

    public confirmDeleteNotiz(notiz: Persisted<Notiz>): void {

        this.dialogService.openDeleteDialog({
            entityText: 'NOTIZ.NAME',
            confirm: () => from(this.notizService.delete(checkPresent(notiz.id)))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }
}

componentConfig.controller = DvbNotizTimelineDetails;
angular.module('kitAdmin').component('dvbNotizTimelineDetails', componentConfig);
