/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {IDvbError} from '@dv/shared/code';
import {DvbError} from '@dv/shared/code';
import angular from 'angular';
import type {AuthService} from '../../../authentication/service/authService';
import {directiveSubSink} from '../../../common/directive/directive-scope-subsink';

const directive: angular.IDirective = {
    restrict: 'E',
    replace: true,
    scope: {},
    template: require('./dvb-error-messages.html'),
    bindToController: true,
    controllerAs: 'vm',
};

export class DvbErrorMessages {
    public static $inject: readonly string[] = ['$element', '$scope', 'errorService', 'authService'];

    public errors: IDvbError[] = [];
    public successes: IDvbError[] = [];

    public constructor(
        private readonly $element: angular.IAugmentedJQuery,
        private readonly $scope: angular.IScope,
        private readonly errorService: ErrorService,
        private readonly authService: AuthService,
    ) {
        const subSink = directiveSubSink($scope);
        subSink.sink = errorService.getErrors$()
            .subscribe({
                next: errors => {
                    this.setErrors(errors);
                },
            });
    }

    public setErrors(errors: IDvbError[]): void {
        this.clearErrors();
        errors.forEach(error => {
            if (DvbError.isSuccess(error)) {
                this.successes.push(error);
            } else {
                this.errors.push(error);
            }
        });
    }

    public clearErrors(): void {
        this.errors = [];
        this.successes = [];
    }

    public onClear(): void {
        this.errorService.clearAll();
    }

    public isInDialog(): boolean {
        const angularJsDialogOpen = angular.element('.dvb-modal-window:not(.in-remove)').length > 0;
        const angularDialogOpen = angular.element('.dv-modal-window:not(.in-remove)').length > 0;

        return angularJsDialogOpen || angularDialogOpen;
    }

    public isAuthenticated(): boolean {
        return this.authService.isAuthenticated();
    }
}

directive.controller = DvbErrorMessages;
angular.module('kitAdmin').directive('dvbErrorMessages', () => directive);
