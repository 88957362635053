/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';
import {CustomHttpParameterCodec} from '../encoder';

import type {AdministrationResourceGetMandantRechnungenByIDsPdfParameter} from '@dv/shared/backend/model/administration-resource-get-mandant-rechnungen-by-ids-pdf-parameter';
import type {AdministrationResourceRecalculateMandantRechnungMandantrechnungParameter} from '@dv/shared/backend/model/administration-resource-recalculate-mandant-rechnung-mandantrechnung-parameter';
import type {AngestellteResourceGetAnstellungenAnstellungParameter} from '@dv/shared/backend/model/angestellte-resource-get-anstellungen-anstellung-parameter';
import type {AngestellteResourceGetKorrespondenzKorrespondenzParameter} from '@dv/shared/backend/model/angestellte-resource-get-korrespondenz-korrespondenz-parameter';
import type {AngestellteZuweisungResourceGetAngestellteZuweisungenAngestellteIdMatrixParameter} from '@dv/shared/backend/model/angestellte-zuweisung-resource-get-angestellte-zuweisungen-angestellte-id-matrix-parameter';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {BringAbholZeitenCommunicationResourceGetBringAbholzeitenStateKinderOrtIdMatrixParameter} from '@dv/shared/backend/model/bring-abhol-zeiten-communication-resource-get-bring-abholzeiten-state-kinder-ort-id-matrix-parameter';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {FakturaResourceGetRechnungenRechnungenParameter} from '@dv/shared/backend/model/faktura-resource-get-rechnungen-rechnungen-parameter';
import type {FirmenResourceGetAllFirmenKontingenteKontingenteParameter} from '@dv/shared/backend/model/firmen-resource-get-all-firmen-kontingente-kontingente-parameter';
import type {JaxBetreuungsPersonNew} from '@dv/shared/backend/model/jax-betreuungs-person-new';
import type {JaxBewilligtePlaetze} from '@dv/shared/backend/model/jax-bewilligte-plaetze';
import type {JaxControllingChanges} from '@dv/shared/backend/model/jax-controlling-changes';
import type {JaxCount} from '@dv/shared/backend/model/jax-count';
import type {JaxFakturaStatuses} from '@dv/shared/backend/model/jax-faktura-statuses';
import type {JaxGruppeNew} from '@dv/shared/backend/model/jax-gruppe-new';
import type {JaxGruppenWochenBelegungen} from '@dv/shared/backend/model/jax-gruppen-wochen-belegungen';
import type {JaxIds} from '@dv/shared/backend/model/jax-ids';
import type {JaxKinder} from '@dv/shared/backend/model/jax-kinder';
import type {JaxKinderBetreuungsfaktoren} from '@dv/shared/backend/model/jax-kinder-betreuungsfaktoren';
import type {JaxKinderOrt} from '@dv/shared/backend/model/jax-kinder-ort';
import type {JaxKinderOrtAnwesenheitsZeitSettings} from '@dv/shared/backend/model/jax-kinder-ort-anwesenheits-zeit-settings';
import type {JaxKinderOrtNew} from '@dv/shared/backend/model/jax-kinder-ort-new';
import type {JaxKinderOrtStundenbasierteTarife} from '@dv/shared/backend/model/jax-kinder-ort-stundenbasierte-tarife';
import type {JaxKitaBetreuungsfaktorRegel} from '@dv/shared/backend/model/jax-kita-betreuungsfaktor-regel';
import type {JaxKitaBetreuungsfaktorRegeln} from '@dv/shared/backend/model/jax-kita-betreuungsfaktor-regeln';
import type {JaxKitaOffenePosten} from '@dv/shared/backend/model/jax-kita-offene-posten';
import type {JaxKitaRechnungsKonfigurationEditStats} from '@dv/shared/backend/model/jax-kita-rechnungs-konfiguration-edit-stats';
import type {JaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration} from '@dv/shared/backend/model/jax-kita-rechnungs-konfiguration-jax-dvb-kita-konfiguration';
import type {JaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration} from '@dv/shared/backend/model/jax-kita-rechnungs-konfiguration-jax-small-invoice-kita-konfiguration';
import type {JaxKitaRechnungsKonfigurationObject} from '@dv/shared/backend/model/jax-kita-rechnungs-konfiguration-object';
import type {JaxKitaRechnungsKonfigurationen} from '@dv/shared/backend/model/jax-kita-rechnungs-konfigurationen';
import type {JaxKitaTarifeZeitraeume} from '@dv/shared/backend/model/jax-kita-tarife-zeitraeume';
import type {JaxKitaTarifeZeitraum} from '@dv/shared/backend/model/jax-kita-tarife-zeitraum';
import type {JaxKitaWochenBelegung} from '@dv/shared/backend/model/jax-kita-wochen-belegung';
import type {JaxLocalDate} from '@dv/shared/backend/model/jax-local-date';
import type {JaxPageContainerJaxKinderOrt} from '@dv/shared/backend/model/jax-page-container-jax-kinder-ort';
import type {JaxPageContainerJaxKinderOrtFraktion} from '@dv/shared/backend/model/jax-page-container-jax-kinder-ort-fraktion';
import type {JaxPageContainerJaxRechnung} from '@dv/shared/backend/model/jax-page-container-jax-rechnung';
import type {JaxRechnungsLaufPDF} from '@dv/shared/backend/model/jax-rechnungs-lauf-pdf';
import type {JaxSubventioniertesKontingent} from '@dv/shared/backend/model/jax-subventioniertes-kontingent';
import type {KinderOrtResourceGetBelegteFraktionenBelegtefraktionenParameter} from '@dv/shared/backend/model/kinder-ort-resource-get-belegte-fraktionen-belegtefraktionen-parameter';
import type {KinderOrtResourceGetKitaWochenBelegungKitawochenbelegungParameter} from '@dv/shared/backend/model/kinder-ort-resource-get-kita-wochen-belegung-kitawochenbelegung-parameter';
import type {KinderOrtResourceGetMultipleKinderOrteMultipleParameter} from '@dv/shared/backend/model/kinder-ort-resource-get-multiple-kinder-orte-multiple-parameter';
import type {KinderOrtResourceGetRechnungenZippedFilterParameter} from '@dv/shared/backend/model/kinder-ort-resource-get-rechnungen-zipped-filter-parameter';
import type {KinderOrtResourceGetUnsettledOffenePostenUnsettledParameter} from '@dv/shared/backend/model/kinder-ort-resource-get-unsettled-offene-posten-unsettled-parameter';
import type {KinderResourceGetBetreuungsfaktorKinderOrtIdMatrixParameter} from '@dv/shared/backend/model/kinder-resource-get-betreuungsfaktor-kinder-ort-id-matrix-parameter';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';

export interface KinderOrteServiceAddNewBewilligtePlaetzeRequestParams {
    kinderOrtId: EntityId;
    jaxBewilligtePlaetze?: JaxBewilligtePlaetze;
}

export interface KinderOrteServiceCreateBetreuungsPersonRequestParams {
    kinderOrtId: EntityId;
    jaxBetreuungsPersonNew?: JaxBetreuungsPersonNew;
}

export interface KinderOrteServiceCreateDvbKitaRechnungsKonfigurationRequestParams {
    kinderOrtId: EntityId;
    jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration?: JaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration;
}

export interface KinderOrteServiceCreateGruppeRequestParams {
    kinderOrtId: EntityId;
    jaxGruppeNew?: JaxGruppeNew;
}

export interface KinderOrteServiceCreateKinderOrtRequestParams {
    jaxKinderOrtNew?: JaxKinderOrtNew;
}

export interface KinderOrteServiceCreateKitaBetreuungsfaktorRegelRequestParams {
    kinderOrtId: EntityId;
    jaxKitaBetreuungsfaktorRegel?: JaxKitaBetreuungsfaktorRegel;
}

export interface KinderOrteServiceCreateKitaTarifeZeitraumRequestParams {
    kinderOrtId: EntityId;
    jaxKitaTarifeZeitraum?: JaxKitaTarifeZeitraum;
}

export interface KinderOrteServiceCreateSmallInvoiceKitaKonfigurationRequestParams {
    kinderOrtId: EntityId;
    jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration?: JaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration;
}

export interface KinderOrteServiceCreateSubventioniertesKontingentRequestParams {
    kinderOrtId: EntityId;
    jaxSubventioniertesKontingent?: JaxSubventioniertesKontingent;
}

export interface KinderOrteServiceDeleteKinderOrtRequestParams {
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceDeleteKitaRechnungsKonfigurationenRequestParams {
    rechnungsKonfigurationId: EntityId;
}

export interface KinderOrteServiceGetAffectedRechnungenByRechnungsKonfigurationRequestParams {
    rechnungsKonfigurationId: EntityId;
    jaxKitaRechnungsKonfigurationObject?: JaxKitaRechnungsKonfigurationObject;
}

export interface KinderOrteServiceGetAllKinderOrteRequestParams {
    kinderorte: AngestellteResourceGetAnstellungenAnstellungParameter;
}

export interface KinderOrteServiceGetBelegteFraktionenRequestParams {
    belegtefraktionen: KinderOrtResourceGetBelegteFraktionenBelegtefraktionenParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetBewerbungenCountRequestParams {
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetFakturaStatusesRequestParams {
    faktura: AngestellteZuweisungResourceGetAngestellteZuweisungenAngestellteIdMatrixParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetGruppenWochenBelegungRequestParams {
    gruppenwochenbelegungen: BringAbholZeitenCommunicationResourceGetBringAbholzeitenStateKinderOrtIdMatrixParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetGruppenWochenKapazitaetenRequestParams {
    gruppenwochenkapazitaeten: BringAbholZeitenCommunicationResourceGetBringAbholzeitenStateKinderOrtIdMatrixParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetKinderAenderungenRequestParams {
    gueltigAb: BackendLocalDate;
    gueltigAbMatrix: AngestellteResourceGetKorrespondenzKorrespondenzParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetKinderBetreuungsfaktorenRequestParams {
    betreuungsfaktoren: KinderResourceGetBetreuungsfaktorKinderOrtIdMatrixParameter;
    kinderOrtId: EntityId;
    jaxIds?: JaxIds;
}

export interface KinderOrteServiceGetKinderOrtRequestParams {
    kinderOrtId: EntityId;
    kinderOrtIdMatrix: FirmenResourceGetAllFirmenKontingenteKontingenteParameter;
}

export interface KinderOrteServiceGetKinderOrtStundenbasierteTarifeOfferRequestParams {
    kinderOrtId: EntityId;
    stundenbasiertetarife: AdministrationResourceRecalculateMandantRechnungMandantrechnungParameter;
}

export interface KinderOrteServiceGetKinderWithBelegungenRequestParams {
    kinder: FirmenResourceGetAllFirmenKontingenteKontingenteParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetKinderWithBewerbungenRequestParams {
    kinder: AngestellteResourceGetAnstellungenAnstellungParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetKitaBetreuungsfaktorRegelnRequestParams {
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetKitaRechnungsKonfigurationenRequestParams {
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetKitaWochenBelegungRequestParams {
    kinderOrtId: EntityId;
    kitawochenbelegung: KinderOrtResourceGetKitaWochenBelegungKitawochenbelegungParameter;
}

export interface KinderOrteServiceGetMultipleKinderOrteRequestParams {
    multiple: KinderOrtResourceGetMultipleKinderOrteMultipleParameter;
}

export interface KinderOrteServiceGetRechnungenByKinderOrtIDRequestParams {
    kinderOrtId: EntityId;
    rechnungen: FakturaResourceGetRechnungenRechnungenParameter;
}

export interface KinderOrteServiceGetRechnungenZippedRequestParams {
    filter: KinderOrtResourceGetRechnungenZippedFilterParameter;
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetRechnungenZippedByIDsRequestParams {
    kinderOrtId: EntityId;
    pdf: AdministrationResourceGetMandantRechnungenByIDsPdfParameter;
    jaxRechnungsLaufPDF?: JaxRechnungsLaufPDF;
}

export interface KinderOrteServiceGetTarifeZuweisungenRequestParams {
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceGetUnsettledOffenePostenRequestParams {
    kinderOrtId: EntityId;
    unsettled: KinderOrtResourceGetUnsettledOffenePostenUnsettledParameter;
}

export interface KinderOrteServiceRevertTerminateKitaRequestParams {
    kinderOrtId: EntityId;
}

export interface KinderOrteServiceTerminateKitaRequestParams {
    kinderOrtId: EntityId;
    jaxLocalDate?: JaxLocalDate;
}

export interface KinderOrteServiceTerminateKitaBetreuungsfaktorRegelRequestParams {
    kinderOrtId: EntityId;
    jaxLocalDate?: JaxLocalDate;
}

export interface KinderOrteServiceTerminateTarifeZuweisungenRequestParams {
    kinderOrtId: EntityId;
    jaxLocalDate?: JaxLocalDate;
}

export interface KinderOrteServiceUpdateAnwesenheitsZeitSettingsRequestParams {
    kinderOrtId: EntityId;
    jaxKinderOrtAnwesenheitsZeitSettings?: JaxKinderOrtAnwesenheitsZeitSettings;
}

export interface KinderOrteServiceUpdateDvbKitaRechnungsKonfigurationRequestParams {
    rechnungsKonfigurationId: EntityId;
    jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration?: JaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration;
}

export interface KinderOrteServiceUpdateKinderOrtRequestParams {
    kinderOrtId: EntityId;
    jaxKinderOrt?: JaxKinderOrt;
}

export interface KinderOrteServiceUpdateSmallInvoiceKitaKonfigurationRequestParams {
    rechnungsKonfigurationId: EntityId;
    jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration?: JaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration;
}

export interface KinderOrteServiceValidateDeleteKitaRequestParams {
    kinderOrtId: EntityId;
}

@Injectable({
  providedIn: 'root'
})
export class KinderOrteService {

    protected basePath = '/kitadmin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && !(value instanceof Date)) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // noinspection AssignmentResultUsedJS
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                // noinspection AssignmentResultUsedJS
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public addNewBewilligtePlaetze$(requestParameters: KinderOrteServiceAddNewBewilligtePlaetzeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public addNewBewilligtePlaetze$(requestParameters: KinderOrteServiceAddNewBewilligtePlaetzeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public addNewBewilligtePlaetze$(requestParameters: KinderOrteServiceAddNewBewilligtePlaetzeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public addNewBewilligtePlaetze$(requestParameters: KinderOrteServiceAddNewBewilligtePlaetzeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling addNewBewilligtePlaetze$.');
        }
        const jaxBewilligtePlaetze = requestParameters.jaxBewilligtePlaetze;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/bewilligteplaetze`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxBewilligtePlaetze,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createBetreuungsPerson$(requestParameters: KinderOrteServiceCreateBetreuungsPersonRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createBetreuungsPerson$(requestParameters: KinderOrteServiceCreateBetreuungsPersonRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createBetreuungsPerson$(requestParameters: KinderOrteServiceCreateBetreuungsPersonRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createBetreuungsPerson$(requestParameters: KinderOrteServiceCreateBetreuungsPersonRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createBetreuungsPerson$.');
        }
        const jaxBetreuungsPersonNew = requestParameters.jaxBetreuungsPersonNew;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/betreuungs_person`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxBetreuungsPersonNew,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceCreateDvbKitaRechnungsKonfigurationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceCreateDvbKitaRechnungsKonfigurationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceCreateDvbKitaRechnungsKonfigurationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceCreateDvbKitaRechnungsKonfigurationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createDvbKitaRechnungsKonfiguration$.');
        }
        const jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration = requestParameters.jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/rechnungskonfigurationen/dvb`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createGruppe$(requestParameters: KinderOrteServiceCreateGruppeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createGruppe$(requestParameters: KinderOrteServiceCreateGruppeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createGruppe$(requestParameters: KinderOrteServiceCreateGruppeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createGruppe$(requestParameters: KinderOrteServiceCreateGruppeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createGruppe$.');
        }
        const jaxGruppeNew = requestParameters.jaxGruppeNew;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/gruppe`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxGruppeNew,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createKinderOrt$(requestParameters: KinderOrteServiceCreateKinderOrtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createKinderOrt$(requestParameters: KinderOrteServiceCreateKinderOrtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createKinderOrt$(requestParameters: KinderOrteServiceCreateKinderOrtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createKinderOrt$(requestParameters: KinderOrteServiceCreateKinderOrtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const jaxKinderOrtNew = requestParameters.jaxKinderOrtNew;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKinderOrtNew,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceCreateKitaBetreuungsfaktorRegelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceCreateKitaBetreuungsfaktorRegelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceCreateKitaBetreuungsfaktorRegelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceCreateKitaBetreuungsfaktorRegelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createKitaBetreuungsfaktorRegel$.');
        }
        const jaxKitaBetreuungsfaktorRegel = requestParameters.jaxKitaBetreuungsfaktorRegel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/betreuungsfaktorregeln`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaBetreuungsfaktorRegel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createKitaTarifeZeitraum$(requestParameters: KinderOrteServiceCreateKitaTarifeZeitraumRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createKitaTarifeZeitraum$(requestParameters: KinderOrteServiceCreateKitaTarifeZeitraumRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createKitaTarifeZeitraum$(requestParameters: KinderOrteServiceCreateKitaTarifeZeitraumRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createKitaTarifeZeitraum$(requestParameters: KinderOrteServiceCreateKitaTarifeZeitraumRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createKitaTarifeZeitraum$.');
        }
        const jaxKitaTarifeZeitraum = requestParameters.jaxKitaTarifeZeitraum;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/tarife`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaTarifeZeitraum,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceCreateSmallInvoiceKitaKonfigurationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceCreateSmallInvoiceKitaKonfigurationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceCreateSmallInvoiceKitaKonfigurationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceCreateSmallInvoiceKitaKonfigurationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createSmallInvoiceKitaKonfiguration$.');
        }
        const jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration = requestParameters.jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/rechnungskonfigurationen/smallinvoice`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createSubventioniertesKontingent$(requestParameters: KinderOrteServiceCreateSubventioniertesKontingentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createSubventioniertesKontingent$(requestParameters: KinderOrteServiceCreateSubventioniertesKontingentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createSubventioniertesKontingent$(requestParameters: KinderOrteServiceCreateSubventioniertesKontingentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createSubventioniertesKontingent$(requestParameters: KinderOrteServiceCreateSubventioniertesKontingentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createSubventioniertesKontingent$.');
        }
        const jaxSubventioniertesKontingent = requestParameters.jaxSubventioniertesKontingent;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/subventioniertekontingente`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxSubventioniertesKontingent,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public deleteKinderOrt$(requestParameters: KinderOrteServiceDeleteKinderOrtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteKinderOrt$(requestParameters: KinderOrteServiceDeleteKinderOrtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteKinderOrt$(requestParameters: KinderOrteServiceDeleteKinderOrtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteKinderOrt$(requestParameters: KinderOrteServiceDeleteKinderOrtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling deleteKinderOrt$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public deleteKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceDeleteKitaRechnungsKonfigurationenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceDeleteKitaRechnungsKonfigurationenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceDeleteKitaRechnungsKonfigurationenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceDeleteKitaRechnungsKonfigurationenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const rechnungsKonfigurationId = requestParameters.rechnungsKonfigurationId;
        if (rechnungsKonfigurationId === null || rechnungsKonfigurationId === undefined) {
            throw new Error('Required parameter rechnungsKonfigurationId was null or undefined when calling deleteKitaRechnungsKonfigurationen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/rechnungskonfigurationen/${this.configuration.encodeParam({name: "rechnungsKonfigurationId", value: rechnungsKonfigurationId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getAffectedRechnungenByRechnungsKonfiguration$(requestParameters: KinderOrteServiceGetAffectedRechnungenByRechnungsKonfigurationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKitaRechnungsKonfigurationEditStats>;
    public getAffectedRechnungenByRechnungsKonfiguration$(requestParameters: KinderOrteServiceGetAffectedRechnungenByRechnungsKonfigurationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKitaRechnungsKonfigurationEditStats>>;
    public getAffectedRechnungenByRechnungsKonfiguration$(requestParameters: KinderOrteServiceGetAffectedRechnungenByRechnungsKonfigurationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKitaRechnungsKonfigurationEditStats>>;
    public getAffectedRechnungenByRechnungsKonfiguration$(requestParameters: KinderOrteServiceGetAffectedRechnungenByRechnungsKonfigurationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const rechnungsKonfigurationId = requestParameters.rechnungsKonfigurationId;
        if (rechnungsKonfigurationId === null || rechnungsKonfigurationId === undefined) {
            throw new Error('Required parameter rechnungsKonfigurationId was null or undefined when calling getAffectedRechnungenByRechnungsKonfiguration$.');
        }
        const jaxKitaRechnungsKonfigurationObject = requestParameters.jaxKitaRechnungsKonfigurationObject;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/rechnungskonfigurationen/${this.configuration.encodeParam({name: "rechnungsKonfigurationId", value: rechnungsKonfigurationId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/affected`;
        return this.httpClient.request<JaxKitaRechnungsKonfigurationEditStats>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaRechnungsKonfigurationObject,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getAllKinderOrte$(requestParameters: KinderOrteServiceGetAllKinderOrteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxPageContainerJaxKinderOrt>;
    public getAllKinderOrte$(requestParameters: KinderOrteServiceGetAllKinderOrteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxPageContainerJaxKinderOrt>>;
    public getAllKinderOrte$(requestParameters: KinderOrteServiceGetAllKinderOrteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxPageContainerJaxKinderOrt>>;
    public getAllKinderOrte$(requestParameters: KinderOrteServiceGetAllKinderOrteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderorte = requestParameters.kinderorte;
        if (kinderorte === null || kinderorte === undefined) {
            throw new Error('Required parameter kinderorte was null or undefined when calling getAllKinderOrte$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte${this.configuration.encodeParam({name: "kinderorte", value: kinderorte, in: "path", style: "matrix", explode: true, dataType: "AngestellteResourceGetAnstellungenAnstellungParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxPageContainerJaxKinderOrt>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getBelegteFraktionen$(requestParameters: KinderOrteServiceGetBelegteFraktionenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxPageContainerJaxKinderOrtFraktion>;
    public getBelegteFraktionen$(requestParameters: KinderOrteServiceGetBelegteFraktionenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxPageContainerJaxKinderOrtFraktion>>;
    public getBelegteFraktionen$(requestParameters: KinderOrteServiceGetBelegteFraktionenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxPageContainerJaxKinderOrtFraktion>>;
    public getBelegteFraktionen$(requestParameters: KinderOrteServiceGetBelegteFraktionenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const belegtefraktionen = requestParameters.belegtefraktionen;
        if (belegtefraktionen === null || belegtefraktionen === undefined) {
            throw new Error('Required parameter belegtefraktionen was null or undefined when calling getBelegteFraktionen$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getBelegteFraktionen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/belegtefraktionen${this.configuration.encodeParam({name: "belegtefraktionen", value: belegtefraktionen, in: "path", style: "matrix", explode: true, dataType: "KinderOrtResourceGetBelegteFraktionenBelegtefraktionenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxPageContainerJaxKinderOrtFraktion>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getBewerbungenCount$(requestParameters: KinderOrteServiceGetBewerbungenCountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxCount>;
    public getBewerbungenCount$(requestParameters: KinderOrteServiceGetBewerbungenCountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxCount>>;
    public getBewerbungenCount$(requestParameters: KinderOrteServiceGetBewerbungenCountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxCount>>;
    public getBewerbungenCount$(requestParameters: KinderOrteServiceGetBewerbungenCountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getBewerbungenCount$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/bewerbungen/count`;
        return this.httpClient.request<JaxCount>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getFakturaStatuses$(requestParameters: KinderOrteServiceGetFakturaStatusesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxFakturaStatuses>;
    public getFakturaStatuses$(requestParameters: KinderOrteServiceGetFakturaStatusesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxFakturaStatuses>>;
    public getFakturaStatuses$(requestParameters: KinderOrteServiceGetFakturaStatusesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxFakturaStatuses>>;
    public getFakturaStatuses$(requestParameters: KinderOrteServiceGetFakturaStatusesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const faktura = requestParameters.faktura;
        if (faktura === null || faktura === undefined) {
            throw new Error('Required parameter faktura was null or undefined when calling getFakturaStatuses$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getFakturaStatuses$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/faktura${this.configuration.encodeParam({name: "faktura", value: faktura, in: "path", style: "matrix", explode: true, dataType: "AngestellteZuweisungResourceGetAngestellteZuweisungenAngestellteIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxFakturaStatuses>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getGruppenWochenBelegung$(requestParameters: KinderOrteServiceGetGruppenWochenBelegungRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxGruppenWochenBelegungen>;
    public getGruppenWochenBelegung$(requestParameters: KinderOrteServiceGetGruppenWochenBelegungRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxGruppenWochenBelegungen>>;
    public getGruppenWochenBelegung$(requestParameters: KinderOrteServiceGetGruppenWochenBelegungRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxGruppenWochenBelegungen>>;
    public getGruppenWochenBelegung$(requestParameters: KinderOrteServiceGetGruppenWochenBelegungRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const gruppenwochenbelegungen = requestParameters.gruppenwochenbelegungen;
        if (gruppenwochenbelegungen === null || gruppenwochenbelegungen === undefined) {
            throw new Error('Required parameter gruppenwochenbelegungen was null or undefined when calling getGruppenWochenBelegung$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getGruppenWochenBelegung$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/belegung/gruppenwochenbelegungen${this.configuration.encodeParam({name: "gruppenwochenbelegungen", value: gruppenwochenbelegungen, in: "path", style: "matrix", explode: true, dataType: "BringAbholZeitenCommunicationResourceGetBringAbholzeitenStateKinderOrtIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxGruppenWochenBelegungen>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getGruppenWochenKapazitaeten$(requestParameters: KinderOrteServiceGetGruppenWochenKapazitaetenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxGruppenWochenBelegungen>;
    public getGruppenWochenKapazitaeten$(requestParameters: KinderOrteServiceGetGruppenWochenKapazitaetenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxGruppenWochenBelegungen>>;
    public getGruppenWochenKapazitaeten$(requestParameters: KinderOrteServiceGetGruppenWochenKapazitaetenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxGruppenWochenBelegungen>>;
    public getGruppenWochenKapazitaeten$(requestParameters: KinderOrteServiceGetGruppenWochenKapazitaetenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const gruppenwochenkapazitaeten = requestParameters.gruppenwochenkapazitaeten;
        if (gruppenwochenkapazitaeten === null || gruppenwochenkapazitaeten === undefined) {
            throw new Error('Required parameter gruppenwochenkapazitaeten was null or undefined when calling getGruppenWochenKapazitaeten$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getGruppenWochenKapazitaeten$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/gruppenwochenkapazitaeten${this.configuration.encodeParam({name: "gruppenwochenkapazitaeten", value: gruppenwochenkapazitaeten, in: "path", style: "matrix", explode: true, dataType: "BringAbholZeitenCommunicationResourceGetBringAbholzeitenStateKinderOrtIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxGruppenWochenBelegungen>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderAenderungen$(requestParameters: KinderOrteServiceGetKinderAenderungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxControllingChanges>;
    public getKinderAenderungen$(requestParameters: KinderOrteServiceGetKinderAenderungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxControllingChanges>>;
    public getKinderAenderungen$(requestParameters: KinderOrteServiceGetKinderAenderungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxControllingChanges>>;
    public getKinderAenderungen$(requestParameters: KinderOrteServiceGetKinderAenderungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const gueltigAb = requestParameters.gueltigAb;
        if (gueltigAb === null || gueltigAb === undefined) {
            throw new Error('Required parameter gueltigAb was null or undefined when calling getKinderAenderungen$.');
        }
        const gueltigAbMatrix = requestParameters.gueltigAbMatrix;
        if (gueltigAbMatrix === null || gueltigAbMatrix === undefined) {
            throw new Error('Required parameter gueltigAbMatrix was null or undefined when calling getKinderAenderungen$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderAenderungen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/kinderaenderungen/${this.configuration.encodeParam({name: "gueltigAb", value: gueltigAb, in: "path", style: "simple", explode: false, dataType: "BackendLocalDate", dataFormat: "date"})}${this.configuration.encodeParam({name: "gueltigAbMatrix", value: gueltigAbMatrix, in: "path", style: "matrix", explode: true, dataType: "AngestellteResourceGetKorrespondenzKorrespondenzParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxControllingChanges>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderBetreuungsfaktoren$(requestParameters: KinderOrteServiceGetKinderBetreuungsfaktorenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKinderBetreuungsfaktoren>;
    public getKinderBetreuungsfaktoren$(requestParameters: KinderOrteServiceGetKinderBetreuungsfaktorenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKinderBetreuungsfaktoren>>;
    public getKinderBetreuungsfaktoren$(requestParameters: KinderOrteServiceGetKinderBetreuungsfaktorenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKinderBetreuungsfaktoren>>;
    public getKinderBetreuungsfaktoren$(requestParameters: KinderOrteServiceGetKinderBetreuungsfaktorenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const betreuungsfaktoren = requestParameters.betreuungsfaktoren;
        if (betreuungsfaktoren === null || betreuungsfaktoren === undefined) {
            throw new Error('Required parameter betreuungsfaktoren was null or undefined when calling getKinderBetreuungsfaktoren$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderBetreuungsfaktoren$.');
        }
        const jaxIds = requestParameters.jaxIds;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/betreuungsfaktoren${this.configuration.encodeParam({name: "betreuungsfaktoren", value: betreuungsfaktoren, in: "path", style: "matrix", explode: true, dataType: "KinderResourceGetBetreuungsfaktorKinderOrtIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKinderBetreuungsfaktoren>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxIds,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderOrt$(requestParameters: KinderOrteServiceGetKinderOrtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKinderOrt>;
    public getKinderOrt$(requestParameters: KinderOrteServiceGetKinderOrtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKinderOrt>>;
    public getKinderOrt$(requestParameters: KinderOrteServiceGetKinderOrtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKinderOrt>>;
    public getKinderOrt$(requestParameters: KinderOrteServiceGetKinderOrtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderOrt$.');
        }
        const kinderOrtIdMatrix = requestParameters.kinderOrtIdMatrix;
        if (kinderOrtIdMatrix === null || kinderOrtIdMatrix === undefined) {
            throw new Error('Required parameter kinderOrtIdMatrix was null or undefined when calling getKinderOrt$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}${this.configuration.encodeParam({name: "kinderOrtIdMatrix", value: kinderOrtIdMatrix, in: "path", style: "matrix", explode: true, dataType: "FirmenResourceGetAllFirmenKontingenteKontingenteParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKinderOrt>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderOrtStundenbasierteTarifeOffer$(requestParameters: KinderOrteServiceGetKinderOrtStundenbasierteTarifeOfferRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKinderOrtStundenbasierteTarife>;
    public getKinderOrtStundenbasierteTarifeOffer$(requestParameters: KinderOrteServiceGetKinderOrtStundenbasierteTarifeOfferRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKinderOrtStundenbasierteTarife>>;
    public getKinderOrtStundenbasierteTarifeOffer$(requestParameters: KinderOrteServiceGetKinderOrtStundenbasierteTarifeOfferRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKinderOrtStundenbasierteTarife>>;
    public getKinderOrtStundenbasierteTarifeOffer$(requestParameters: KinderOrteServiceGetKinderOrtStundenbasierteTarifeOfferRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderOrtStundenbasierteTarifeOffer$.');
        }
        const stundenbasiertetarife = requestParameters.stundenbasiertetarife;
        if (stundenbasiertetarife === null || stundenbasiertetarife === undefined) {
            throw new Error('Required parameter stundenbasiertetarife was null or undefined when calling getKinderOrtStundenbasierteTarifeOffer$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/stundenbasiertetarife${this.configuration.encodeParam({name: "stundenbasiertetarife", value: stundenbasiertetarife, in: "path", style: "matrix", explode: true, dataType: "AdministrationResourceRecalculateMandantRechnungMandantrechnungParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKinderOrtStundenbasierteTarife>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderWithBelegungen$(requestParameters: KinderOrteServiceGetKinderWithBelegungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKinder>;
    public getKinderWithBelegungen$(requestParameters: KinderOrteServiceGetKinderWithBelegungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKinder>>;
    public getKinderWithBelegungen$(requestParameters: KinderOrteServiceGetKinderWithBelegungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKinder>>;
    public getKinderWithBelegungen$(requestParameters: KinderOrteServiceGetKinderWithBelegungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinder = requestParameters.kinder;
        if (kinder === null || kinder === undefined) {
            throw new Error('Required parameter kinder was null or undefined when calling getKinderWithBelegungen$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderWithBelegungen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinder${this.configuration.encodeParam({name: "kinder", value: kinder, in: "path", style: "matrix", explode: true, dataType: "FirmenResourceGetAllFirmenKontingenteKontingenteParameter", dataFormat: undefined})}orte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/belegungen/kinder`;
        return this.httpClient.request<JaxKinder>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKinderWithBewerbungen$(requestParameters: KinderOrteServiceGetKinderWithBewerbungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKinder>;
    public getKinderWithBewerbungen$(requestParameters: KinderOrteServiceGetKinderWithBewerbungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKinder>>;
    public getKinderWithBewerbungen$(requestParameters: KinderOrteServiceGetKinderWithBewerbungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKinder>>;
    public getKinderWithBewerbungen$(requestParameters: KinderOrteServiceGetKinderWithBewerbungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinder = requestParameters.kinder;
        if (kinder === null || kinder === undefined) {
            throw new Error('Required parameter kinder was null or undefined when calling getKinderWithBewerbungen$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKinderWithBewerbungen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinder${this.configuration.encodeParam({name: "kinder", value: kinder, in: "path", style: "matrix", explode: true, dataType: "AngestellteResourceGetAnstellungenAnstellungParameter", dataFormat: undefined})}orte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/bewerbungen/kinder`;
        return this.httpClient.request<JaxKinder>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKitaBetreuungsfaktorRegeln$(requestParameters: KinderOrteServiceGetKitaBetreuungsfaktorRegelnRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKitaBetreuungsfaktorRegeln>;
    public getKitaBetreuungsfaktorRegeln$(requestParameters: KinderOrteServiceGetKitaBetreuungsfaktorRegelnRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKitaBetreuungsfaktorRegeln>>;
    public getKitaBetreuungsfaktorRegeln$(requestParameters: KinderOrteServiceGetKitaBetreuungsfaktorRegelnRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKitaBetreuungsfaktorRegeln>>;
    public getKitaBetreuungsfaktorRegeln$(requestParameters: KinderOrteServiceGetKitaBetreuungsfaktorRegelnRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKitaBetreuungsfaktorRegeln$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/betreuungsfaktorregeln`;
        return this.httpClient.request<JaxKitaBetreuungsfaktorRegeln>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceGetKitaRechnungsKonfigurationenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKitaRechnungsKonfigurationen>;
    public getKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceGetKitaRechnungsKonfigurationenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKitaRechnungsKonfigurationen>>;
    public getKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceGetKitaRechnungsKonfigurationenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKitaRechnungsKonfigurationen>>;
    public getKitaRechnungsKonfigurationen$(requestParameters: KinderOrteServiceGetKitaRechnungsKonfigurationenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKitaRechnungsKonfigurationen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/rechnungskonfigurationen`;
        return this.httpClient.request<JaxKitaRechnungsKonfigurationen>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getKitaWochenBelegung$(requestParameters: KinderOrteServiceGetKitaWochenBelegungRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKitaWochenBelegung>;
    public getKitaWochenBelegung$(requestParameters: KinderOrteServiceGetKitaWochenBelegungRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKitaWochenBelegung>>;
    public getKitaWochenBelegung$(requestParameters: KinderOrteServiceGetKitaWochenBelegungRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKitaWochenBelegung>>;
    public getKitaWochenBelegung$(requestParameters: KinderOrteServiceGetKitaWochenBelegungRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getKitaWochenBelegung$.');
        }
        const kitawochenbelegung = requestParameters.kitawochenbelegung;
        if (kitawochenbelegung === null || kitawochenbelegung === undefined) {
            throw new Error('Required parameter kitawochenbelegung was null or undefined when calling getKitaWochenBelegung$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/belegung/kitawochenbelegung${this.configuration.encodeParam({name: "kitawochenbelegung", value: kitawochenbelegung, in: "path", style: "matrix", explode: true, dataType: "KinderOrtResourceGetKitaWochenBelegungKitawochenbelegungParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKitaWochenBelegung>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getMultipleKinderOrte$(requestParameters: KinderOrteServiceGetMultipleKinderOrteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxPageContainerJaxKinderOrt>;
    public getMultipleKinderOrte$(requestParameters: KinderOrteServiceGetMultipleKinderOrteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxPageContainerJaxKinderOrt>>;
    public getMultipleKinderOrte$(requestParameters: KinderOrteServiceGetMultipleKinderOrteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxPageContainerJaxKinderOrt>>;
    public getMultipleKinderOrte$(requestParameters: KinderOrteServiceGetMultipleKinderOrteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const multiple = requestParameters.multiple;
        if (multiple === null || multiple === undefined) {
            throw new Error('Required parameter multiple was null or undefined when calling getMultipleKinderOrte$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/multiple${this.configuration.encodeParam({name: "multiple", value: multiple, in: "path", style: "matrix", explode: true, dataType: "KinderOrtResourceGetMultipleKinderOrteMultipleParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxPageContainerJaxKinderOrt>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getRechnungenByKinderOrtID$(requestParameters: KinderOrteServiceGetRechnungenByKinderOrtIDRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxPageContainerJaxRechnung>;
    public getRechnungenByKinderOrtID$(requestParameters: KinderOrteServiceGetRechnungenByKinderOrtIDRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxPageContainerJaxRechnung>>;
    public getRechnungenByKinderOrtID$(requestParameters: KinderOrteServiceGetRechnungenByKinderOrtIDRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxPageContainerJaxRechnung>>;
    public getRechnungenByKinderOrtID$(requestParameters: KinderOrteServiceGetRechnungenByKinderOrtIDRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getRechnungenByKinderOrtID$.');
        }
        const rechnungen = requestParameters.rechnungen;
        if (rechnungen === null || rechnungen === undefined) {
            throw new Error('Required parameter rechnungen was null or undefined when calling getRechnungenByKinderOrtID$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/rechnungen${this.configuration.encodeParam({name: "rechnungen", value: rechnungen, in: "path", style: "matrix", explode: true, dataType: "FakturaResourceGetRechnungenRechnungenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxPageContainerJaxRechnung>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getRechnungenZipped$(requestParameters: KinderOrteServiceGetRechnungenZippedRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getRechnungenZipped$(requestParameters: KinderOrteServiceGetRechnungenZippedRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getRechnungenZipped$(requestParameters: KinderOrteServiceGetRechnungenZippedRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getRechnungenZipped$(requestParameters: KinderOrteServiceGetRechnungenZippedRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const filter = requestParameters.filter;
        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getRechnungenZipped$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getRechnungenZipped$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/rechnungen/pdf/filter${this.configuration.encodeParam({name: "filter", value: filter, in: "path", style: "matrix", explode: true, dataType: "KinderOrtResourceGetRechnungenZippedFilterParameter", dataFormat: undefined})}`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getRechnungenZippedByIDs$(requestParameters: KinderOrteServiceGetRechnungenZippedByIDsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getRechnungenZippedByIDs$(requestParameters: KinderOrteServiceGetRechnungenZippedByIDsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getRechnungenZippedByIDs$(requestParameters: KinderOrteServiceGetRechnungenZippedByIDsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getRechnungenZippedByIDs$(requestParameters: KinderOrteServiceGetRechnungenZippedByIDsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getRechnungenZippedByIDs$.');
        }
        const pdf = requestParameters.pdf;
        if (pdf === null || pdf === undefined) {
            throw new Error('Required parameter pdf was null or undefined when calling getRechnungenZippedByIDs$.');
        }
        const jaxRechnungsLaufPDF = requestParameters.jaxRechnungsLaufPDF;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/rechnungen/pdf${this.configuration.encodeParam({name: "pdf", value: pdf, in: "path", style: "matrix", explode: true, dataType: "AdministrationResourceGetMandantRechnungenByIDsPdfParameter", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxRechnungsLaufPDF,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getTarifeZuweisungen$(requestParameters: KinderOrteServiceGetTarifeZuweisungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKitaTarifeZeitraeume>;
    public getTarifeZuweisungen$(requestParameters: KinderOrteServiceGetTarifeZuweisungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKitaTarifeZeitraeume>>;
    public getTarifeZuweisungen$(requestParameters: KinderOrteServiceGetTarifeZuweisungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKitaTarifeZeitraeume>>;
    public getTarifeZuweisungen$(requestParameters: KinderOrteServiceGetTarifeZuweisungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getTarifeZuweisungen$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/tarife`;
        return this.httpClient.request<JaxKitaTarifeZeitraeume>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getUnsettledOffenePosten$(requestParameters: KinderOrteServiceGetUnsettledOffenePostenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKitaOffenePosten>;
    public getUnsettledOffenePosten$(requestParameters: KinderOrteServiceGetUnsettledOffenePostenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKitaOffenePosten>>;
    public getUnsettledOffenePosten$(requestParameters: KinderOrteServiceGetUnsettledOffenePostenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKitaOffenePosten>>;
    public getUnsettledOffenePosten$(requestParameters: KinderOrteServiceGetUnsettledOffenePostenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getUnsettledOffenePosten$.');
        }
        const unsettled = requestParameters.unsettled;
        if (unsettled === null || unsettled === undefined) {
            throw new Error('Required parameter unsettled was null or undefined when calling getUnsettledOffenePosten$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/offeneposten/unsettled${this.configuration.encodeParam({name: "unsettled", value: unsettled, in: "path", style: "matrix", explode: true, dataType: "KinderOrtResourceGetUnsettledOffenePostenUnsettledParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKitaOffenePosten>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public revertTerminateKita$(requestParameters: KinderOrteServiceRevertTerminateKitaRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public revertTerminateKita$(requestParameters: KinderOrteServiceRevertTerminateKitaRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public revertTerminateKita$(requestParameters: KinderOrteServiceRevertTerminateKitaRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public revertTerminateKita$(requestParameters: KinderOrteServiceRevertTerminateKitaRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling revertTerminateKita$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/enddate`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public terminateKita$(requestParameters: KinderOrteServiceTerminateKitaRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public terminateKita$(requestParameters: KinderOrteServiceTerminateKitaRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public terminateKita$(requestParameters: KinderOrteServiceTerminateKitaRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public terminateKita$(requestParameters: KinderOrteServiceTerminateKitaRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling terminateKita$.');
        }
        const jaxLocalDate = requestParameters.jaxLocalDate;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/enddate`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxLocalDate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public terminateKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceTerminateKitaBetreuungsfaktorRegelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public terminateKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceTerminateKitaBetreuungsfaktorRegelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public terminateKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceTerminateKitaBetreuungsfaktorRegelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public terminateKitaBetreuungsfaktorRegel$(requestParameters: KinderOrteServiceTerminateKitaBetreuungsfaktorRegelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling terminateKitaBetreuungsfaktorRegel$.');
        }
        const jaxLocalDate = requestParameters.jaxLocalDate;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/betreuungsfaktorregeln/enddate`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxLocalDate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public terminateTarifeZuweisungen$(requestParameters: KinderOrteServiceTerminateTarifeZuweisungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public terminateTarifeZuweisungen$(requestParameters: KinderOrteServiceTerminateTarifeZuweisungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public terminateTarifeZuweisungen$(requestParameters: KinderOrteServiceTerminateTarifeZuweisungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public terminateTarifeZuweisungen$(requestParameters: KinderOrteServiceTerminateTarifeZuweisungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling terminateTarifeZuweisungen$.');
        }
        const jaxLocalDate = requestParameters.jaxLocalDate;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/tarife/enddate`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxLocalDate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public updateAnwesenheitsZeitSettings$(requestParameters: KinderOrteServiceUpdateAnwesenheitsZeitSettingsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateAnwesenheitsZeitSettings$(requestParameters: KinderOrteServiceUpdateAnwesenheitsZeitSettingsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateAnwesenheitsZeitSettings$(requestParameters: KinderOrteServiceUpdateAnwesenheitsZeitSettingsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateAnwesenheitsZeitSettings$(requestParameters: KinderOrteServiceUpdateAnwesenheitsZeitSettingsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling updateAnwesenheitsZeitSettings$.');
        }
        const jaxKinderOrtAnwesenheitsZeitSettings = requestParameters.jaxKinderOrtAnwesenheitsZeitSettings;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/anwesenheitszeitsettings`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKinderOrtAnwesenheitsZeitSettings,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public updateDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceUpdateDvbKitaRechnungsKonfigurationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceUpdateDvbKitaRechnungsKonfigurationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceUpdateDvbKitaRechnungsKonfigurationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateDvbKitaRechnungsKonfiguration$(requestParameters: KinderOrteServiceUpdateDvbKitaRechnungsKonfigurationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const rechnungsKonfigurationId = requestParameters.rechnungsKonfigurationId;
        if (rechnungsKonfigurationId === null || rechnungsKonfigurationId === undefined) {
            throw new Error('Required parameter rechnungsKonfigurationId was null or undefined when calling updateDvbKitaRechnungsKonfiguration$.');
        }
        const jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration = requestParameters.jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/rechnungskonfigurationen/${this.configuration.encodeParam({name: "rechnungsKonfigurationId", value: rechnungsKonfigurationId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/dvb`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaRechnungsKonfigurationJaxDvbKitaKonfiguration,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public updateKinderOrt$(requestParameters: KinderOrteServiceUpdateKinderOrtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateKinderOrt$(requestParameters: KinderOrteServiceUpdateKinderOrtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateKinderOrt$(requestParameters: KinderOrteServiceUpdateKinderOrtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateKinderOrt$(requestParameters: KinderOrteServiceUpdateKinderOrtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling updateKinderOrt$.');
        }
        const jaxKinderOrt = requestParameters.jaxKinderOrt;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKinderOrt,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public updateSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceUpdateSmallInvoiceKitaKonfigurationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceUpdateSmallInvoiceKitaKonfigurationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceUpdateSmallInvoiceKitaKonfigurationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateSmallInvoiceKitaKonfiguration$(requestParameters: KinderOrteServiceUpdateSmallInvoiceKitaKonfigurationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const rechnungsKonfigurationId = requestParameters.rechnungsKonfigurationId;
        if (rechnungsKonfigurationId === null || rechnungsKonfigurationId === undefined) {
            throw new Error('Required parameter rechnungsKonfigurationId was null or undefined when calling updateSmallInvoiceKitaKonfiguration$.');
        }
        const jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration = requestParameters.jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/rechnungskonfigurationen/${this.configuration.encodeParam({name: "rechnungsKonfigurationId", value: rechnungsKonfigurationId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/smallinvoice`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKitaRechnungsKonfigurationJaxSmallInvoiceKitaKonfiguration,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public validateDeleteKita$(requestParameters: KinderOrteServiceValidateDeleteKitaRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public validateDeleteKita$(requestParameters: KinderOrteServiceValidateDeleteKitaRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public validateDeleteKita$(requestParameters: KinderOrteServiceValidateDeleteKitaRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public validateDeleteKita$(requestParameters: KinderOrteServiceValidateDeleteKitaRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling validateDeleteKita$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/kinderorte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}/validate/delete`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

}
