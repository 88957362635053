/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {Einzahlungsschein, getType} from '@dv/kitadmin/models';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {AuthorisationService} from '../../../../authorisation/service/authorisation.service';
import type {DvbFakturaService} from '../../../../common/service/rest/dvbFakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        einzahlungsscheine: '<',
    },
    template: require('./dvb-einzahlungsscheine.html'),
    controllerAs: 'vm',
};

export class DvbEinzahlungsscheine implements angular.IController {

    public static $inject: readonly string[] = ['dvbFakturaService', 'errorService', 'authorisationService'];

    public einzahlungsscheine: Persisted<Einzahlungsschein>[] = [];
    public einzahlungsschein: Einzahlungsschein | null = null;
    public showNewForm: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private readonly dvbFakturaService: DvbFakturaService,
        private readonly errorService: ErrorService,
        private readonly authorisationService: AuthorisationService,
    ) {
    }

    public onUpdate(
        workingCopyEinzahlungsschein: Einzahlungsschein,
        einzahlungsschein: Persisted<Einzahlungsschein>,
        context: AccordionContext,
        form: angular.IFormController,
    ): void {
        const copywithDefaults = angular.copy(workingCopyEinzahlungsschein);
        copywithDefaults.applyDefaults();
        if (!this.isValidEinzahlungsschein(form, copywithDefaults)) {
            context.cancelSubmission();

            return;
        }

        copywithDefaults.type = getType(copywithDefaults.kontoNummer);

        this.dvbFakturaService.updateEinzahlungsschein(copywithDefaults).then(() => {
            angular.extend(einzahlungsschein, copywithDefaults);
        }).then(() => {
            context.completeSubmission();
        }).finally(() => {
            context.cancelSubmission();
        });
    }

    public onCancelUpdate(workingCopyEinzahlungsschein: Einzahlungsschein, einzahlungsschein: Einzahlungsschein): void {
        angular.extend(workingCopyEinzahlungsschein, einzahlungsschein);
    }

    public onDelete(einzahlungsschein: Persisted<Einzahlungsschein>): angular.IPromise<void> {
        return this.dvbFakturaService.deleteEinzahlungsschein(einzahlungsschein.id).then(() => {
            DvbUtil.removeFromArray(einzahlungsschein, this.einzahlungsscheine);
        });
    }

    public initNewForm(): void {
        this.einzahlungsschein = new Einzahlungsschein();
        this.showNewForm = true;
    }

    public createEinzahlungsschein(form: angular.IFormController): void {
        const copywithDefaults = angular.copy(checkPresent(this.einzahlungsschein));
        copywithDefaults.applyDefaults();

        if (!this.isValidEinzahlungsschein(form, copywithDefaults)) {
            return;
        }

        copywithDefaults.type = getType(copywithDefaults.kontoNummer);

        this.isLoading = true;

        this.dvbFakturaService.createEinzahlungsschein(copywithDefaults).then(response => {
            copywithDefaults.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);
            this.einzahlungsscheine.push(checkPersisted(copywithDefaults));
            this.showNewForm = false;
        }).catch(() => {
            if (this.authorisationService.isMandantAdmin()) {
                this.errorService.handleValidationError(false, 'ERRORS.PRIORITIZE_JIRA_TASK', {task: 'KIT-112'});
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }

    private isValidEinzahlungsschein(form: angular.IFormController, einzahlungsschein: Einzahlungsschein): boolean {
        const valid = form.$valid && einzahlungsschein?.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        return valid;
    }
}

componentConfig.controller = DvbEinzahlungsscheine;
angular.module('kitAdmin').component('dvbEinzahlungsscheine', componentConfig);
