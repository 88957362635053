/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaTarifeZeitraum} from '@dv/kitadmin/models';
import type {Nullish, Persisted} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {KinderOrtService} from 'src/app/common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaTarifeZeitraum: '<',
        onDelete: '&',
    },
    template: require('./dvb-kita-tarife-verlauf-content.html'),
    controllerAs: 'vm',
};

export class DvbKitaTarifeVerlaufContent implements angular.IController {

    public static $inject: readonly string[] = ['kinderOrtService'];

    public kitaTarifeZeitraum!: KitaTarifeZeitraum;
    public onDelete?: (data: { kitaTarifeZeitraum: Persisted<KitaTarifeZeitraum> }) => unknown;

    public editableKitaTarifeZeitraum: KitaTarifeZeitraum | Nullish = null;
    public isLoading = false;

    public constructor(
        private readonly kinderOrtService: KinderOrtService,
    ) {
    }

    public setEditMode(mode: boolean): void {
        if (mode) {
            this.editableKitaTarifeZeitraum = angular.copy(this.kitaTarifeZeitraum);
            this.editableKitaTarifeZeitraum.initMissingAbrechnungsTarife();
        } else {
            this.editableKitaTarifeZeitraum = null;
        }
    }

    public submit(): void {
        const kitaTarifeZeitraum = checkPresent(this.editableKitaTarifeZeitraum).getCleanCopy();
        if (angular.equals(kitaTarifeZeitraum, this.kitaTarifeZeitraum)) {
            this.setEditMode(false);

            return;
        }

        this.isLoading = true;
        this.kinderOrtService.createKitaTarifeZeitraum(kitaTarifeZeitraum).then(() => {
            angular.copy(kitaTarifeZeitraum, this.kitaTarifeZeitraum);
            this.setEditMode(false);
        }).finally(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbKitaTarifeVerlaufContent;
angular.module('kitAdmin').component('dvbKitaTarifeVerlaufContent', componentConfig);
