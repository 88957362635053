/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Comments, ServiceContainer} from '@dv/kitadmin/models';
import type {ZeitabschnittComparison} from '@dv/shared/backend/model/zeitabschnitt-comparison';
import type {Zeiteinheit} from '@dv/shared/backend/model/zeiteinheit';
import type {ILimited, IPersisted} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type {IFilterNumber} from 'angular';
import type moment from 'moment';
import {BetreuungZeitabschnittDisplayValues} from './BetreuungZeitabschnittDisplayValues';
import {BetreuungZeitabschnittValues} from './BetreuungZeitabschnittValues';
import {KibonExchangeEingewoehnungZeitabschnitt} from './KibonExchangeEingewoehnungZeitabschnitt';

/**
 * when effective and monthly values differ, they are formatted as: EFFECTIVE (MONTHLY)¹
 */
function format(effective: number, monthly: number): string {
    return effective === monthly ?
            `${roundTo2(effective)}` :
            `${roundTo2(effective)} (${roundTo2(monthly)})\u00B9`;
}

function roundTo2(value: string | number): string {
    return ServiceContainer.$filter<IFilterNumber>('roundTo')(value, 2);
}

export class KibonExchangeBetreuungZeitabschnitt implements IPersisted, ILimited {

    private _displayValue?: BetreuungZeitabschnittDisplayValues;

    public constructor(
            public id: string,
            public gueltigAb: moment.Moment,
            public gueltigBis: moment.Moment,
            public pensumUnit: Zeiteinheit,
            public tarifProHauptmahlzeit: number,
            public tarifProNebenmahlzeit: number,
            public effective: BetreuungZeitabschnittValues,
            public monthly: BetreuungZeitabschnittValues | null,
            public mutationState: ZeitabschnittComparison,
            public hasMahlzeiten: boolean,
            public comments: Comments[],
            public kibonExchangeEingewoehnungZeitabschnitt: KibonExchangeEingewoehnungZeitabschnitt | null = null,
    ) {
    }

    /**
     * Lazy initialisation of display formatted values
     */
    public get displayValue(): BetreuungZeitabschnittDisplayValues {
        if (!this._displayValue) {
            const monthly = this.monthly;
            const effective = this.effective;

            this._displayValue = monthly ?
                    new BetreuungZeitabschnittDisplayValues(
                            format(effective.betreuungskosten, monthly.betreuungskosten),
                            format(effective.betreuungspensum, monthly.betreuungspensum),
                            format(effective.anzahlHauptmahlzeiten, monthly.anzahlHauptmahlzeiten),
                            format(effective.anzahlNebenmahlzeiten, monthly.anzahlNebenmahlzeiten),
                    ) :
                    new BetreuungZeitabschnittDisplayValues(
                            `${roundTo2(effective.betreuungskosten)}`,
                            `${roundTo2(effective.betreuungspensum)}`,
                            `${roundTo2(effective.anzahlHauptmahlzeiten)}`,
                            `${roundTo2(effective.anzahlNebenmahlzeiten)}`,
                    );
        }

        return this._displayValue;
    }

    public static apiResponseTransformer(data: any): KibonExchangeBetreuungZeitabschnitt {
        const monthly = data.monthly ?
                BetreuungZeitabschnittValues.apiResponseTransformer(data.monthly) :
                null;

        const effective = BetreuungZeitabschnittValues.apiResponseTransformer(data.effective);
        const hasMahlzeiten = effective.anzahlHauptmahlzeiten > 0 || effective.anzahlNebenmahlzeiten > 0;

        const comments = Array.isArray(data.comments) ?
                data.comments.map((c: any) => Comments.apiResponseTransformer(c)) :
                [];

        const eingewoehnung = data.eingewoehnungZeitabschnitt ?
            KibonExchangeEingewoehnungZeitabschnitt.apiResponseTransformer(
                data.eingewoehnungZeitabschnitt) : null;

        return new KibonExchangeBetreuungZeitabschnitt(
                data.id,
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
                data.pensumUnit,
                parseFloat(data.tarifProHauptmahlzeit),
                parseFloat(data.tarifProNebenmahlzeit),
                effective,
                monthly,
                data.mutationState,
                hasMahlzeiten,
                comments,
                eingewoehnung,
        );
    }
}
