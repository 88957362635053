<dvb-limited-entity-verlauf entity="vm.anwesenheitsSoll"
                           previous-entity="vm.previousSoll"
                           first="vm.first"
                           translation-root="'KIND.ANWESENHEITS_SOLL'"
                           enable-edit-mode="vm.enableEditMode"
                           enable-actions="vm.enableEditMode"
                           on-remove="vm.remove()"
                           on-submit="vm.saveWorkingCopy(context)"
                           on-revert="vm.revert()">
    <entity-form>
        <dvb-anwesenheits-soll-form anwesenheits-soll="vm.workingCopy">
        </dvb-anwesenheits-soll-form>
    </entity-form>
    <entity-heading>
        <dvb-limited-entity-heading entity="vm.anwesenheitsSoll">
            <entity-heading>
                <dvb-anwesenheits-soll-header anwesenheits-soll="vm.anwesenheitsSoll"></dvb-anwesenheits-soll-header>
            </entity-heading>
        </dvb-limited-entity-heading>
    </entity-heading>
    <entity-read-only>
        <dvb-limited-entity-heading entity="vm.anwesenheitsSoll">
            <entity-heading>
                <dvb-anwesenheits-soll-header anwesenheits-soll="vm.anwesenheitsSoll"></dvb-anwesenheits-soll-header>
            </entity-heading>
        </dvb-limited-entity-heading>
        <p class="normal-abstand-top" ng-bind="vm.anwesenheitsSoll.bemerkung"></p>
    </entity-read-only>
</dvb-limited-entity-verlauf>
