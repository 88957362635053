/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AbstractPagesController, KibonExchangeVerfuegung} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {MatchingState} from '@dv/shared/backend/model/matching-state';
import type {FormContext} from '@dv/shared/code';
import {checkPresent, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import type {BetreuungsGutscheinService} from '../../../common/service/rest/kind/betreuungsGutscheinService';
import type {KibonExchangeEntityAction} from '../../models/KibonExchangeEntityAction';
import {KibonExchangeEntityButtonAction, KibonExchangeNavigationAction} from '../../models/KibonExchangeEntityAction';
import {VerfuegungenFilter} from '../../models/VerfuegungenFilter';
import type {QueryKibonExchangeApiQueryapiParameter} from '../../service/kibonQueryApiService';
import type {KibonVerfuegungenService} from '../../service/kibonVerfuegungenService';
import type {TranslationKeyMap} from '../dvb-kibon-entity-matching-overview/dvb-kibon-entity-matching-overview';
import type {KibonExchangeActionHandler} from '../dvb-kibon-entity-matching-overview/KibonEntityActionHandler';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-kibon-verfuegung.html'),
    controllerAs: 'vm',
};

export class DvbKibonVerfuegung implements angular.IController {

    public static $inject: readonly string[] = [
        'kibonVerfuegungenService',
        'betreuungsGutscheinService',
        'dialogService',
        '$translate',
    ];

    public actionHandler: KibonExchangeActionHandler<KibonExchangeVerfuegung, unknown>;
    public filter: VerfuegungenFilter = new VerfuegungenFilter();
    public states: MatchingState[] = [
        MatchingState.OPEN,
        MatchingState.MATCHED,
        MatchingState.MANUALLY_ASSIGNED,
        MatchingState.REMOVED,
        MatchingState.IGNORED,
    ];
    public translations: TranslationKeyMap = {
        pageHeader: 'KIBON.VERFUEGUNGEN.HEADER',
        statePrefix: 'KIBON.VERFUEGUNGEN.STATE.',
        stateTooltipPrefix: 'KIBON.VERFUEGUNGEN.STATE_INFO.',
        kibonExportAm: 'KIBON.VERFUEGUNGEN.VERFUEGUNG_VOM',
        assignConfirmationHeader: 'KIBON.VERFUEGUNGEN.ASSIGN_CONFIRMATION_HEADER',
        assignEntityDataHeader: '', // TODO
        entityNameMF: 'KIBON.VERFUEGUNGEN.MF',
        matchingHeader: 'KIBON.VERFUEGUNGEN.MATCHING_HEADER',
        matchingText: 'KIBON.VERFUEGUNGEN.MATCHING_TEXT',
    };

    public pageController?: AbstractPagesController<KibonExchangeVerfuegung>;

    public actions: KibonExchangeEntityAction<KibonExchangeVerfuegung>[] = [
        new KibonExchangeEntityButtonAction(
            entity => this.betreuungsGutscheinService.deleteKiTaxVerfuegung(checkPresent(entity.kiTaxId)),
            entity => entity.canUnAssign(),
            _entity => ({button: 'COMMON.ZUWEISUNG_AUFHEBEN', confirmation: 'COMMON.ZUWEISUNG_AUFHEBEN'}),
        ),
        new KibonExchangeEntityButtonAction(
            entity => this.verfuegungenService.ignoreKibonVerfuegung(entity.id),
            entity => entity.canIgnore(),
            _entity => ({button: 'COMMON.IGNORIEREN', confirmation: 'KIBON.VERFUEGUNGEN.IGNORE_TITLE'}),
        ),
        new KibonExchangeEntityButtonAction(
            entity => this.verfuegungenService.unIgnoreKibonVerfuegung(entity.id),
            entity => entity.canUnIgnore(),
            _entity => ({button: 'COMMON.UNIGNORIEREN', confirmation: 'COMMON.UNIGNORIEREN'}),
        ),
        new KibonExchangeNavigationAction(
            'base.kind.kibon',
            entity => ({kindId: entity.assignedKindId}),
            entity => isPresent(entity.assignedKindId),
            _entity => ({button: 'COMMON.KIND.ANSEHEN'}),
        ),
    ];

    public constructor(
        private readonly verfuegungenService: KibonVerfuegungenService,
        private readonly betreuungsGutscheinService: BetreuungsGutscheinService,
        private readonly dialogService: DialogService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {

        this.actionHandler = {
            doAssign: (entity, kind) => this.verfuegungenService.assignKibonVerfuegung(entity.id, kind.id),
            onAssigned: _result => undefined,
            getFiltered: config => this.verfuegungenService.getFilteredVerfuegungen(this.filter, config),
        };
    }

    public bulkUnIgnore(): void {
        const title = this.$translate.instant('KIBON.VERFUEGUNGEN.BULK_UN_IGNORE');
        const subtitle = this.getSubtitle('KIBON.VERFUEGUNGEN.BULK_UN_IGNORE_CONFIRM_');

        const confirm = (): Observable<unknown> => from(this.verfuegungenService.bulkUnIgnoreVerfuegungen(this.filter))
            .pipe(take(1), tap(() => this.pageController?.reloadItems()));

        this.dialogService.openConfirmDialog({title, subtitle, confirm});
    }

    public bulkIgnore(): void {
        const title = this.$translate.instant('KIBON.VERFUEGUNGEN.BULK_IGNORE');
        const subtitle = this.getSubtitle('KIBON.VERFUEGUNGEN.BULK_IGNORE_CONFIRM_');

        const confirm = (): Observable<unknown> => from(this.verfuegungenService.bulkIgnoreVerfuegungen(this.filter))
            .pipe(take(1), tap(() => this.pageController?.reloadItems()));

        this.dialogService.openConfirmDialog({title, subtitle, confirm});
    }

    public queryApi(params: QueryKibonExchangeApiQueryapiParameter, formContext: FormContext): void {
        formContext.startLoading();
        this.verfuegungenService.queryExchangeService(params)
            .finally(() => formContext.finishLoading());
    }

    private getSubtitle(translationRoot: string): string {
        if (this.filter.gueltigkeit.selected) {
            return this.$translate.instant(`${translationRoot}PERIODE`);
        }

        return this.$translate.instant(`${translationRoot}ALLE`);
    }
}

componentConfig.controller = DvbKibonVerfuegung;
angular.module('kitAdmin').component('dvbKibonVerfuegung', componentConfig);
