/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CustomFieldType} from '@dv/shared/code';
import {FilterOption} from '../../../filter/shared/FilterOption';

export class CustomFieldFilterOption extends FilterOption {

    public constructor(
        id: string | null,
        name: string,
        public fieldType: CustomFieldType,
    ) {
        super(id, name, false, false);
    }
}
