<div class="dvb-betreuungsfaktor-icon"
     uib-tooltip="{{'COMMON.'+ (vm.faktor.spezifisch ? 'SPEZIFISCHER_BETREUUNGSFAKTOR_WERT': 'BETREUUNGSFAKTOR_WERT') | translate:{'wert': vm.tooltipValue} }}"
     tooltip-placement="top-left">
    <svg class="betreuungsfaktor-icon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" r="256" class="background-circle"></circle>
        <text x="256"
              y="360"
              font-size="280"
              fill="#fff"
              text-anchor="middle"
              font-weight="bold"
              ng-bind="vm.displayText">
        </text>
    </svg>
</div>
