/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {WochenPlaetze} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenPlaetze: '<',
        showFreiePlaetze: '<',
        showBelegtePlaetze: '<',
    },
    template: require('./dvb-wochen-plaetze.html'),
    controllerAs: 'vm',
};

export class DvbWochenPlaetze {
    public static $inject: readonly string[] = [];

    public wochenPlaetze!: WochenPlaetze;
    public showFreiePlaetze!: boolean;
    public showBelegtePlaetze!: boolean;
}

componentConfig.controller = DvbWochenPlaetze;
angular.module('kitAdmin').component('dvbWochenPlaetze', componentConfig);
