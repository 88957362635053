/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldung, KinderOrt, WochenplanExternalAnmeldung} from '@dv/kitadmin/models';
import {AbstractPagesController} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import type {Persisted, RestInclude, RestPaginated} from '@dv/shared/code';
import {DvbRestUtil, Gueltigkeit} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {ExternalAnmeldungFilter} from '../../../external/model/ExternalAnmeldungFilter';
import type {ExternalAnmeldungConfigService} from '../../../external/service/externalAnmeldungConfigService';
import type {ExternalAnmeldungService} from '../../../external/service/externalAnmeldungService';
import {PopoverFilterOption} from '../../../filter/popover/PopoverFilterOption';
import {PopoverFilterPropertyGueltigkeit} from '../../../filter/popover/PopoverFilterPropertyGueltigkeit';
import {EXTERNAL_ANMELDUNG_UEBERNEHMEN_STATE} from '../../../kind/zuweisung';
import type {
    ExternalAnmeldungLoeschenDialogModel,
} from '../external-anmeldung-loeschen-form/external-anmeldung-loeschen.component';
import {
    ExternalAnmeldungLoeschenComponent,
} from '../external-anmeldung-loeschen-form/external-anmeldung-loeschen.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kinder-ort-external-anmeldungen.html'),
    controllerAs: 'vm',
};

export class DvbKinderOrtExternalAnmeldungen extends AbstractPagesController<ExternalAnmeldung>
    implements angular.IController {

    public static override $inject: readonly string[] = [
        'externalAnmeldungService',
        'externalAnmeldungConfigService',
        '$q',
        '$state',
        'authStore',
        'dialogService',
    ];

    public kinderOrt!: Persisted<KinderOrt>;

    public filter: ExternalAnmeldungFilter = new ExternalAnmeldungFilter(
        false,
        true,
        new PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>(
            'erfassungsDatum',
            new PopoverFilterPropertyGueltigkeit(new Gueltigkeit(moment().startOf('year'), null))),
        new PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>(
            'eintrittsDatum',
            new PopoverFilterPropertyGueltigkeit(new Gueltigkeit(null, moment().endOf('year')))));

    public wochenplaeneExternalAnmeldung: WochenplanExternalAnmeldung[] = [];

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly externalAnmeldungService: ExternalAnmeldungService,
        private readonly externalAnmeldungConfigService: ExternalAnmeldungConfigService,
        private readonly $q: angular.IQService,
        private readonly $state: StateService,
        private readonly authStore: AuthStore,
        private readonly dialogService: DialogService,
    ) {
        super();
    }

    public override $onInit(): void {
        super.$onInit();
        if (this.authStore.hasRole(UserRole.ADMIN)) {
            this.externalAnmeldungConfigService.getKinderOrtWochenplaene(this.kinderOrt).then(plaene => {
                this.wochenplaeneExternalAnmeldung = plaene;
            });
        }
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.kinderOrt && !onChangesObj.kinderOrt.isFirstChange()) {
            this.reloadItems();
        }
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const config = {timeout: this.timeout.promise};

        const params: RestPaginated & RestInclude = {
            includes: '(kind)',
        };
        this.setPagionationValues(params);

        this.externalAnmeldungService.getExternalAnmeldungenByKinderOrt(this.kinderOrt.id, this.filter, params, config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }

    public uebernehmen(anmeldung: Persisted<ExternalAnmeldung>): void {
        this.$state.go(EXTERNAL_ANMELDUNG_UEBERNEHMEN_STATE.name, {externalAnmeldungId: anmeldung.id});
    }

    public loeschen(anmeldung: Persisted<ExternalAnmeldung>): void {
        const dialogModel: ExternalAnmeldungLoeschenDialogModel = {
            anmeldung,
            confirm: (deleteChild: boolean): Observable<unknown> => this.deleteAnmeldung(anmeldung, deleteChild),
        };

        this.dialogService.openDialog(ExternalAnmeldungLoeschenComponent, dialogModel);
    }

    public applyAnmeldeFilter(): void {
        this.filter.erfassungsDatumFilter.selected = true;
        this.reloadItems();

    }

    public applyBetreuungFilter(): void {
        this.filter.eintrittsDatumFilter.selected = true;
        this.reloadItems();
    }

    public saveWochenplaene(): void {
        this.externalAnmeldungConfigService.updateKinderOrtWochenplaene(
            this.kinderOrt,
            this.wochenplaeneExternalAnmeldung)
            .then(plaene => {
                this.wochenplaeneExternalAnmeldung = plaene;
            });
    }

    private deleteAnmeldung(anmeldung: Persisted<ExternalAnmeldung>, deleteKind: boolean): Observable<unknown> {
        return from(this.externalAnmeldungService.delete(anmeldung.id, deleteKind).then(() => this.reloadItems()));
    }
}

componentConfig.controller = DvbKinderOrtExternalAnmeldungen;
angular.module('kitAdmin').component('dvbKinderOrtExternalAnmeldungen', componentConfig);
