<form class="dvb-bulk-email-management dvb-form" name="bulkEmailManagement" novalidate>

    <div class="header">
        <div class="row header-row">
            <div class="col-md-5 header-box">
                <h1 data-translate="COMMUNICATION.BULK_EMAILS.TITLE"
                    data-translate-value-date="{{this.gueltigAb | amDateFormat:'YYYY'}}"></h1>
            </div>
            <div class="col-md-2 text-right">
                <div uib-dropdown>
                    <dvb-loading-button as-link="true"
                                        type="button"
                                        uib-dropdown-toggle
                                        ng-disabled="vm.emailTemplates.length < 1"
                                        class="btn">
                        <span data-translate="COMMUNICATION.EMAIL_TEMPLATE.SINGULAR"></span>
                        <span class="caret" id="caret"></span>
                    </dvb-loading-button>

                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li ng-repeat="template in vm.emailTemplates">
                            <a href=""
                               ng-click="vm.fillForm(template)"
                               ng-bind="template.name">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <input type="text"
                   class="form-control"
                   ng-model="vm.bulkEmail.emailSubject"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}"
                   uib-tooltip="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}"
                   tooltip-placement="top-left"
                   required>

            <dv-wysiwyg-editor class="normal-abstand-top"
                                [(content)]="vm.bulkEmail.html"
                                [placeholder-str]="'{{'COMMUNICATION.EMAIL.CONTENT' | translate}}'">
            </dv-wysiwyg-editor>

            <div class="row form-row normal-abstand-top">
                <div class="col-md-6 layout-row">
                    <input type="email"
                           class="form-control"
                           ng-model="vm.bulkEmail.replyToAddress"
                           ng-attr-placeholder="{{'COMMUNICATION.EMAIL.ABSENDER_ADRESSE' | translate}}"
                           uib-tooltip="{{'COMMUNICATION.EMAIL.ABSENDER_ADRESSE' | translate}}"
                           tooltip-placement="top-left"
                           required>
                    <dv-spf-info [reply-to-address]="vm.bulkEmail.replyToAddress">
                    </dv-spf-info>
                </div>

                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           ng-model="vm.bulkEmail.replyToName"
                           maxlength="255"
                           ng-attr-placeholder="{{'COMMUNICATION.ABSENDER_NAME' | translate}}"
                           uib-tooltip="{{'COMMUNICATION.ABSENDER_NAME' | translate}}"
                           tooltip-placement="top-left"
                           required>
                </div>
            </div>

            <div class="row normal-abstand-bottom">
                <div class="col-md-6">
                    <label>
                        <input type="checkbox"
                               ng-model="vm.bulkEmail.dispositionNotification">
                        <span data-translate="COMMUNICATION.BULK_EMAILS.DISPOSITION_NOTIFICATION"></span>
                    </label>
                </div>
            </div>

            <dvb-multiple-files-upload label-upload="COMMON.DATEIEN_IMPORTIEREN"
                                       ng-model="vm.files"
                                       allow-multiple="true"
                                       max-size="'5MB'"
                                       max-total-size="vm.maxTotalSize"
                                       allowed-extensions="'*'">
            </dvb-multiple-files-upload>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <dvb-communication-ready-to-send ng-if="vm.kontaktpersonen.length > 0"
                                             class="full-width-block ready-to-send"
                                             kontaktpersonen="vm.kontaktpersonen"
                                             show-cancel-btn="true"
                                             on-submit="vm.sendEmail(context, selected)"
                                             on-cancel="vm.goBack()"
                                             all-selected="vm.allSelected">
            </dvb-communication-ready-to-send>

            <dvb-bulk-email-form ng-if="!(vm.kontaktpersonen.length > 0)"
                                 show-cancel-btn="true"
                                 on-cancel="vm.goBack()">
            </dvb-bulk-email-form>

            <dvb-test-email on-submit="vm.sendTestMail(empfaengerEmailAdresse)">
            </dvb-test-email>

        </div>
    </div>
</form>
