/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {enableProdMode} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {initAngularJs} from '@dv/kit-admin/lib';
import {LogFactory} from '@dv/shared/code';
import type {UrlService} from '@uirouter/core';
import {UIRouter} from '@uirouter/core';
import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';
import {ENVIRONMENT} from './environments/environment';

LogFactory.init(ENVIRONMENT.minLogSeverity, ENVIRONMENT.traceEnabledFor);

initAngularJs(ENVIRONMENT);

if (ENVIRONMENT.production) {
    enableProdMode();
}

// Manually bootstrap the Angular app
bootstrapApplication(AppComponent, appConfig).then(platformRef => {
    // Initialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

    // FYI: there is way to run UIRouter within NGZone, in case some initialisation issues arise.
    // https://github.com/ui-router/angular-hybrid/issues/340
    // Instruct UIRouter to listen to URL changes
    // platformRef.injector.get<NgZone>(NgZone).run(() => {
    // Instruct UIRouter to listen to URL changes
    urlService.listen();
    urlService.sync();
    // });
});
