/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {ImageBlob, UploadTempBlob} from '@dv/kitadmin/models';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {BlobUploadService} from '../../service/rest/blobUploadService';
import type {NGFile} from '../dvb-multiple-files-upload/dvb-multiple-files-upload';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        tempBlob: '=ngModel',
        labelUpload: '@',
    },
    template: require('./dvb-file-upload.html'),
    controllerAs: 'vm',
};

export class DvbFileUpload implements angular.IController {
    public static $inject: readonly string[] = [
        'blobUploadService',
        'errorService',
    ];

    public tempBlob!: UploadTempBlob | null;
    public labelUpload!: string;
    public ngModelCtrl!: angular.INgModelController;

    public constructor(
        private readonly blobUploadService: BlobUploadService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.ngModelCtrl.$formatters.push(this.formatter.bind(this));
        this.ngModelCtrl.$validators.uploadSuccess = this.uploadSuccess.bind(this);
        this.ngModelCtrl.$isEmpty = this.isEmpty.bind(this);
    }

    public upload(
        file: NGFile,
        $invalidFiles: NGFile[],
        resizeOptions?: angular.angularFileUpload.FileResizeOptions,
    ): void {
        const valid = this.validate($invalidFiles);

        if (!file || !valid) {
            return;
        }

        this.ngModelCtrl.$viewValue.file = file;

        this.blobUploadService.uploadBlob(this.ngModelCtrl.$viewValue, resizeOptions).then(() => {
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_FILE_TOO_LARGE');
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_UPLOAD_FAILED');
            this.ngModelCtrl.$setViewValue(angular.copy(this.ngModelCtrl.$viewValue));
        }).catch(response => {
            this.errorService.clearErrorByMsgKey('ERRORS.ERR_BADREQUEST');
            this.errorService.addValidationError(response.errorMessage);
        });
    }

    public reset(): void {
        // manually changing the ng-model binding triggers the formatter pipeline again - just like a change from
        // the outside.
        this.tempBlob = null;
    }

    // eslint-disable-next-line complexity
    private validate($invalidFiles: NGFile[]): boolean {
        let valid = true;

        valid = valid && this.handleError($invalidFiles, 'maxFiles', 'ERRORS.ERR_UPLOAD_FAILED');
        valid = valid && this.handleError($invalidFiles, 'pattern', 'ERRORS.ERR_FILE_FORMAT_NOT_ALLOWED');
        valid = valid && this.handleError($invalidFiles, 'minHeight', 'ERRORS.ERR_IMAGE_SMALL_HEIGHT');
        valid = valid && this.handleError($invalidFiles, 'maxHeight', 'ERRORS.ERR_IMAGE_LARGE_HEIGHT');
        valid = valid && this.handleError($invalidFiles, 'minWidth', 'ERRORS.ERR_IMAGE_SMALL_WIDTH');
        valid = valid && this.handleError($invalidFiles, 'maxWidth', 'ERRORS.ERR_IMAGE_LARGE_WIDTH');
        valid = valid && this.handleError($invalidFiles, 'ratio', 'ERRORS.ERR_IMAGE_RATIO');
        valid = valid && this.handleError($invalidFiles, 'minRatio', 'ERRORS.ERR_IMAGE_SMALL_RATIO');
        valid = valid && this.handleError($invalidFiles, 'maxRatio', 'ERRORS.ERR_IMAGE_LARGE_RATIO');
        valid = valid && this.handleError($invalidFiles, 'dimensions', 'ERRORS.ERR_IMAGE_DIMENSIONS');
        const errorFileNotValid = 'ERRORS.ERR_FILE_NOT_VALID';
        valid = valid && this.handleError($invalidFiles, 'maxDuration', errorFileNotValid);
        valid = valid && this.handleError($invalidFiles, 'duration', errorFileNotValid);
        valid = valid && this.handleError($invalidFiles, 'validateFn', errorFileNotValid);
        valid = valid && this.handleError($invalidFiles, 'validateAsyncFn', errorFileNotValid);

        return valid;
    }

    private handleError(files: NGFile[], errorKey: string, msgKey: string): boolean {
        const errorFile = this.hasFileError(files, errorKey);

        if (!errorFile) {
            this.errorService.clearErrorByMsgKey(msgKey);

            return true;
        }

        this.errorService.handleValidationError(false, msgKey, {value: errorFile.$errorParam});

        return false;
    }

    private hasFileError(files: NGFile[], errorKey: string): NGFile | null {
        return DvbUtil.findFirst(files, file => {
            return file.$error === errorKey;
        });
    }

    private formatter(modelValue?: UploadTempBlob): UploadTempBlob {
        return modelValue ?? new UploadTempBlob();
    }

    private uploadSuccess(modelValue?: UploadTempBlob): boolean {
        if (!modelValue) {
            // no value is ok (use "required" if you need a value)
            return true;
        }

        // Validation based on TempBlob Id.
        return !!(modelValue && (modelValue.id ?? (modelValue instanceof ImageBlob && modelValue.base64)));
    }

    private isEmpty(viewValue: UploadTempBlob): boolean {
        // The standard undefined check is not enough for is-required, because viewValue is always a UploadTempBlob.
        return !viewValue?.id;
    }
}

componentConfig.controller = DvbFileUpload;
angular.module('kitAdmin').component('dvbFileUpload', componentConfig);
angular.module('kitAdmin').controller('DvbFileUpload', DvbFileUpload);
