/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TempBlob} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class LeistungsrechnungService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/tarifrechnungresults`;

    public constructor(private $http: angular.IHttpService) {
    }

    public getTempBlobForTarifrechnungResult(tarifrechnungResultId: string): angular.IPromise<TempBlob> {

        const url = `${LeistungsrechnungService.BASE_URL}/` +
            `${encodeURIComponent(tarifrechnungResultId)}/download/`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob).then(checkPresent);
    }

    public getPlatzConfirmationTempBlob(tarifrechnungResultId: string): angular.IPromise<any> {

        const url = `${LeistungsrechnungService.BASE_URL}/platzConfirmation/` +
            `${encodeURIComponent(tarifrechnungResultId)}`;

        return this.$http.get(url).then(confirmation => {
            // download the data as a file
            const link = document.createElement('a');
            link.download = `platz-confirmation_${tarifrechnungResultId}.json`;
            const data = `text/json;charset=utf-8,${JSON.stringify(confirmation.data, null, 2)}`;
            link.href = `data:${data}`;
            link.click();
        });
    }
}
