<div class="dvb-depot-zuweisen-vorgeschlagen">

    <div class="row">
        <div class="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
            <h2 data-translate="COMMON.GEBUEHR.DEPOT_MIT_RECHUNG_ERSTATTEN"
                data-translate-value-betrag="{{vm.depot.betrag}}">
            </h2>
            <dvb-rechnungen rechnungen="vm.rechnungen"
                            is-loading="vm.isLoading"
                            mode="vm.mode">
            </dvb-rechnungen>
        </div>
    </div>

    <div class="row big-abstand-top">
        <div class="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
            <p>
                <a ui-sref="{{vm.OFFENE_POSTEN_STATE.name}}" data-translate="COMMON.ABBRECHEN"></a>
            </p>
        </div>
    </div>

    <!-- Confirm dialog view -->
    <div ui-view></div>
</div>

