<div class="items-center row">
    <div class="col-xs-8">
        <input type="text"
               name="belegungsEinheitName"
               dvb-capitalize-first-letter
               class="form-control"
               maxlength="255"
               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
               uib-tooltip="{{'COMMON.NAME' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.einheit.name"
               required>
    </div>
    <div class="col-xs-2">
        <input type="number"
               name="belegungsEinheitPrio"
               class="form-control"
               ng-attr-placeholder="{{'COMMON.PRIORITAET' | translate}}"
               uib-tooltip="{{'COMMON.PRIORITAET' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.einheit.prio"
               min="0"
               step="1"
               required>
    </div>
    <div class="col-xs-2">
        <input type="number"
               name="belegungsEinheitProzent"
               class="form-control"
               min="0"
               max="100"
               ng-pattern="/^\d*(\.\d{0,2})?$/"
               ng-attr-placeholder="{{'COMMON.PROZENTPUNKTE' | translate}}"
               uib-tooltip="{{'COMMON.PROZENTPUNKTE' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.einheit.prozentPunkte"
               required>
    </div>
</div>
<div class="items-center row">
    <div class="col-xs-8">
        <div ng-repeat="zeitraum in vm.wochenplan.distinctZeitraeume" class="checkbox">
            <label>
                <input type="checkbox"
                       ng-model="vm.selectedZeitraeume[zeitraum.id]"
                       ng-change="vm.toggleZeitraum()"/>
                <span ng-bind="zeitraum.name"></span>
            </label>
        </div>
    </div>
</div>
