<div class="rechnung-zuweisen-confirm modal dvb-modal-window">
    <div class="dvb-modal-container">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">


                <div class="modal-body" dvb-download>
                    <h1 data-translate="FAKTURA.CONFIRM_RECHNUNG_ZUWEISEN"
                        data-translate-value-betrag="{{vm.betrag}}"></h1>

                    <div class="text-left">
                        <div class="normal-abstand-top">
                            <dvb-rechnungen rechnungen="[vm.assignRechnung]"
                                            mode="vm.mode">
                            </dvb-rechnungen>
                        </div>
                    </div>
                    <form name="ueberschussAusgleichenRechnungForm"
                          ng-submit="!ueberschussAusgleichenRechnungForm.$pending &&
                         vm.submit(ueberschussAusgleichenRechnungForm)"
                          class="dvb-form big-abstand-top"
                          novalidate>
                        <div class="row">
                            <div class="col-md-4 col-md-offset-4">
                                <dvb-datepicker-text-field ng-model="vm.date"
                                                           required
                                                           ng-attr-placeholder="{{'COMMON.DATUM' | translate}}">
                                </dvb-datepicker-text-field>
                            </div>
                        </div>

                        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                                   submit-label="COMMON.FORTFAHREN"
                                                   on-cancel="vm.cancel()">
                        </dvb-submit-cancel-buttons>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
