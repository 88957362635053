/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifValue} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifId: '=',
        tarifValue: '=',
        deleteAllowed: '=',
        onUpdate: '&',
        onDelete: '&',
    },
    template: require('./dvb-tarif-verlauf-content.html'),
    controllerAs: 'vm',
};

export class DvbTarifVerlaufContent implements angular.IController {
    public static $inject: readonly string[] = [];

    public tarifId!: string;
    public tarifValue!: TarifValue;
    public deleteAllowed!: boolean;
    public onUpdate!: () => void;
    public onDelete!: () => void;

    public isEditMode: boolean = false;
}

componentConfig.controller = DvbTarifVerlaufContent;
angular.module('kitAdmin').component('dvbTarifVerlaufContent', componentConfig);
