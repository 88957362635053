<div class="dvb-ready-to-send">

    <div class="panel panel-list-container">
        <div class="panel-heading"
             data-translate="{{vm.titleMf}}"
             data-translate-value-count="{{vm.kinder.length}}"
             data-translate-value-showcount="true"
             data-translate-interpolation="messageformat"></div>
        <div class="panel-subtitle">
            <div class="row">
                <div class="col-xs-6" data-translate="COMMON.KIND.SINGULAR"></div>
                <div class="col-xs-6" data-translate="KONTAKTPERSON.HAUPTKONTAKT"></div>
            </div>
        </div>
        <div class="panel-list scrollable with-header">
            <div class="list-item"
                 ng-repeat="kind in vm.kinder | orderBy:['familienName', 'vorName']"
                 ng-init="kontaktperson = kind.findAnyHauptkontaktRelationship().kontaktperson">
                <div class="row">
                    <div class="col-xs-6">
                        <div class="checkbox floating-text" ng-if="!vm.disableActions">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.selected[kind.id]"
                                       ng-value="{{kind.id}}"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <span ng-bind="kind.getDisplayName()"></span>
                            </label>
                        </div>
                        <p ng-if="vm.disableActions" ng-bind="kind.getDisplayName()"></p>
                    </div>
                    <div class="col-xs-6">
                        <p ng-bind="kontaktperson.getDisplayName()"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions" ng-if="!vm.disableActions">
        <ul dvb-auth require-permission="kita:manage:general"
            class="list-inline small-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectAll(vm.kinder)"
                        data-translate="COMMON.ALLE_AUSWAEHLEN">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectNone()"
                        data-translate="COMMON.KEINE_AUSWAEHLEN">
                </button>
            </li>
        </ul>
        <ul class="list-inline normal-abstand-top">
            <li dvb-auth require-permission="kita:manage:general">
                <button class="btn btn-primary"
                        ng-click="vm.submit()"
                        data-translate="{{vm.actionNameMf}}"
                        data-translate-value-count="{{vm.getSelectionCount()}}"
                        data-translate-value-showcount="true"
                        data-translate-interpolation="messageformat">
                </button>
            </li>
        </ul>
    </div>

</div>
