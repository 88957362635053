/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isPresent} from '@dv/shared/code';
import angular from 'angular';

type SupportedAttrs = 'maxlength' | 'minlength' | 'pattern' | 'step' | 'min' | 'max';

export type SupportedInputTypes = 'text' | 'email' | 'number';

export class DvbEditableLabel implements angular.IOnInit {
    public static $inject: readonly string[] = [];

    public ngModel!: angular.INgModelController;
    public dvbEditableLabelForm!: angular.IFormController;

    public placeholder?: string;
    public label?: string;
    public isValid?: (val: { param: string }) => boolean;
    public type?: SupportedInputTypes;
    public isEmail?: boolean;
    public isLink?: boolean;
    public removeErrorAndShowLabel!: () => void;
    public showError!: () => void;

    public attrs: { [attr in SupportedAttrs]?: number | string } = {};

    public $onInit(): void {
        if (this.isLink && this.isEmail) {
            throw new Error('dvb-editable-label cannot be a link and an email at the same time');
        }
    }

    public updateModel(): void {
        if (angular.equals(this.ngModel.$viewValue, this.ngModel.$modelValue)) {
            this.removeErrorAndShowLabel();

            return;
        }

        const value = this.type === 'number' && isPresent(this.ngModel.$viewValue) ?
            parseFloat(this.ngModel.$viewValue) :
            this.ngModel.$viewValue;

        if (this.isValidInternal(value)) {
            this.ngModel.$commitViewValue();
            this.removeErrorAndShowLabel();
        } else {
            this.showError();
        }
    }

    public submitOrReset(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            // Reset on ESC
            this.ngModel.$rollbackViewValue();
            this.ngModel.$setViewValue(this.ngModel.$modelValue);
            // Remove error message
            this.isValidInternal(this.ngModel.$modelValue);

            this.removeErrorAndShowLabel();
            event.preventDefault();
        } else if (event.key === 'Enter') {
            // Trigger Submit on Enter
            (event.target as HTMLInputElement).blur();
            event.preventDefault();
        }
    }

    public isValidInternal(newValue: string): boolean {
        if (!this.dvbEditableLabelForm.$valid) {
            return false;
        }

        return this.isValid ? this.isValid({param: newValue}) : true;
    }
}

function setAttributes(controller: DvbEditableLabel, attrs: angular.IAttributes): void {
    switch (controller.type) {
        case 'text':
        case 'email': {
            const maxLength = 1000;
            controller.attrs.maxlength = attrs.maxlength ?? maxLength;
            controller.attrs.minlength = attrs.minlength ?? 0;
            controller.attrs.pattern = attrs.pattern;
            break;
        }
        case 'number':
            controller.attrs.pattern = attrs.pattern;
            controller.attrs.step = attrs.step;
            controller.attrs.min = attrs.min;
            controller.attrs.max = attrs.max;
            break;
        default:
        // nop
    }
}

dvbEditableLabel.$inject = ['$timeout'];

function dvbEditableLabel($timeout: angular.ITimeoutService): angular.IDirective {
    return {
        restrict: 'E',
        replace: true,
        require: {ngModel: 'ngModel', dvbEditableLabel: 'dvbEditableLabel'},
        scope: {
            model: '=ngModel',
            placeholder: '@?',
            label: '@?',
            isValid: '&?',
            type: '<?',
            isEmail: '<?',
            isLink: '<?',
        },
        controller: DvbEditableLabel,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dvb-editable-label.html'),
        link: (scope, element, attrs, controllers: any): void => {
            const controller = controllers.dvbEditableLabel as DvbEditableLabel;
            controller.type = attrs.type ?? 'text';
            controller.removeErrorAndShowLabel = removeErrorAndShowLabel;
            controller.showError = showError;

            let label: JQLite;
            let textField: JQLite;

            init();

            function init(): void {
                if (controller.type === 'number') {
                    // Make sure numbers are not converted to strings.
                    // (viewValue is always a string, even in number fields)
                    controller.ngModel.$parsers.push(customNumberParser);
                }

                let isDisabled = false;

                attrs.$observe('disabled', value => {
                    isDisabled = !!value;
                });

                $timeout(() => {
                    // The input elements are only available after AngularJS processed the ng-if directives.
                    // Thus, we need to wrap our code in a $timeout.
                    label = element.find('label');
                    textField = element.find('input');

                    setAttributes(controller, attrs);

                    // Trigger Submit on blur
                    textField.on('blur', () => {
                        controller.updateModel();
                    });

                    // Click on Label triggers edit mode
                    label.on('click', event => {
                        if (isDisabled || event.target instanceof HTMLAnchorElement) {
                            return;
                        }

                        if (angular.element('.dvb-click2edit-open').length !== 0) {
                            return;
                        }

                        displayInput();
                        textField.trigger('focus');
                        textField.trigger('select');
                    });

                    displayLabel();
                });
            }

            function displayLabel(): void {
                element.removeClass('dvb-click2edit-open');
                textField.hide();
                label.show();
            }

            function displayInput(): void {
                element.addClass('dvb-click2edit-open');
                label.hide();
                textField.show();
            }

            function removeErrorAndShowLabel(): void {
                scope.$evalAsync(() => {
                    element.removeClass('has-error');
                    displayLabel();
                });
            }

            function showError(): void {
                scope.$evalAsync(() => {
                    element.addClass('has-error');
                    textField.trigger('focus');
                    textField.trigger('select');
                });
            }

            /**
             * parseFloat(null) returns NaN which is not a value we want to display.
             * This parser prevents NaN values.
             */
            function customNumberParser(value: any): number {
                return value ? parseFloat(value) : value;
            }
        },
    };
}

angular.module('kitAdmin').directive('dvbEditableLabel', dvbEditableLabel);
