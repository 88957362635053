/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DeployedVersionsService} from '@dv/shared/angular';
import {DeployedVersions, TypeUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../config';

export class VersionInterceptor implements angular.IHttpInterceptor {
    public static $inject: readonly string[] = ['deployedVersionsService'];

    public constructor(
        private readonly deployedVersionsService: DeployedVersionsService,
    ) {
        this.response = this.response.bind(this);
    }

    public response<T>(response: angular.IHttpResponse<T>): angular.IHttpResponse<T> {
        if (TypeUtil.isFunction(response.headers) &&
            response.config &&
            response.config.url.startsWith(CONFIG.restBackend) &&
            !response.config.cache) {

            const newVersion = DeployedVersions.parse(
                this.deployedVersionsService.getFrontendVersion(),
                headerName => response.headers(headerName),
            );

            this.deployedVersionsService.setDeployedVersions(newVersion);
        }

        return response;
    }
}
