<div class="dvb-temp-extra-platz-header" ng-class="{'removed': vm.platz.isRemoved()}">
    <div class="row">
        <div class="col-md-4" ng-bind="vm.platz.affectedDay | amDateFormat: 'ddd, DD.MM.YYYY'"></div>
        <div class="col-md-4">
            <span data-translate="KIND.EXTRA_DAY.TYPE.{{vm.platz.extraPlatzType}}"></span>
            <span ng-bind-html="vm.typeIcon"></span>
        </div>
        <div class="col-md-4" data-translate="{{'KIND.EXTRA_DAY.CATEGORY.TYPES.' + vm.platz.extraPlatzCategory}}"></div>
    </div>
</div>
