<div class="dvb-angestellte-main">
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-user-o header-icon"
               uib-tooltip="{{'PERSONAL.ANGESTELLTE.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>

            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{vm.angestellte.getDisplayName()}}" ng-bind="vm.angestellte.getDisplayName()"
                    tooltip-placement="top-left">
                </h1>

                <div class="header-dropdown" ng-if="vm.hasPersonalPermission" uib-dropdown>
                    <a href="" class="header-box-attachment dropdown-toggle" uib-dropdown-toggle>
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="{{vm.anstellungenStates.ANSTELLUNGEN_VERLAUF_STATE.name}}"
                               data-translate="PERSONAL.ANSTELLUNG.TITLE"></a>
                        </li>
                    </ul>
                </div>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="{{vm.anstellungenStates.ANGESTELLTE_PROFIL_STATE.name}}"
                           data-translate="COMMON.NAV_PROFIL"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{vm.anstellungenStates.STUNDENERFASSUNG_STATE.data.permission({id: vm.angestellte.id})}}"
                        require-condition="vm.angestellte.betreuungsPersonen.length > 0">
                        <a ui-sref="{{vm.anstellungenStates.STUNDENERFASSUNG_STATE.name}}"
                           data-translate="PERSONAL.STUNDENERFASSUNG.TITLE"></a>
                    </li>
                    <li ui-sref-active-eq="active" ng-if="vm.hasPersonalPermission && vm.hasPersonalPlanungPermission">
                        <a ui-sref="{{vm.anstellungenStates.ZUWEISUNG_STATE.name}}"
                           data-translate="PERSONAL.ANGESTELLTE_ZUWEISUNG.TITLE"></a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="module:personalplanung"
                        require-condition="vm.hasPersonalPermission">
                        <a ui-sref="{{vm.anstellungenStates.TERMIN_STATE.name}}"
                           data-translate="PERSONAL.TERMIN.TITLE"></a>
                    </li>
                    <li ui-sref-active-eq="active" ng-if="vm.hasPersonalPermission">
                        <a ui-sref="{{vm.anstellungenStates.KORRESPONDENZ_STATE.name}}"
                           data-translate="PERSONAL.KORRESPONDENZ.TITLE"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div ui-view></div>
</div>
