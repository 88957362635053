<div class="dvb-bring-abhol-zeiten-form dvb-form">

    <h2 data-translate="COMMUNICATION.BRING_ABHOLZEITEN.TITLE"></h2>

    <form name="bringAbholForm" novalidate ng-submit="vm.submit(bringAbholForm.$valid)">
        <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                      as-col="true"
                                      ng-model="vm.kinderOrtSearchResult"
                                      ng-change="vm.updateState()"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>

        <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                   dvb-begin-of-month
                                   custom-options="{minMode: 'month'}"
                                   label-date-format="'MMMM YYYY'"
                                   format="'MMMM YYYY'"
                                   ng-change="vm.updateState()"
                                   ng-attr-placeholder="{{'COMMON.MONTH' | translate}}{{vm.gueltigAb ? '' : (': ' + (vm.defaultDate| amDateFormat: 'MMMM YYYY'))}}">
        </dvb-datepicker-text-field>

        <dvb-loading-button type="submit" is-loading="vm.isLoading" class="big-abstand-top">
            <span data-translate="COMMUNICATION.SELECT_RECIPIENTS"></span>
        </dvb-loading-button>

    </form>

</div>
