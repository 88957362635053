<div class="dvb-wochenplan-profil">
    <div class="row">
        <div class="col-md-7">
            <dvb-wochenplan
                distinct-betreuungs-zeitraeume="vm.wochenplan.distinctZeitraeume"
                interactive="true"
                show-null-value-as-plain="true"
                week-days="vm.enabledDays"
                zeitraum-felder="vm.wochenplan.zeitraumFelder"
                on-zeitraum-feld-clicked="vm.onZeitraumFeldSelected(zeitraumFeld)"
                show-betreuungs-zeitraum-name="true">
            </dvb-wochenplan>

            <div class="row big-abstand-top normal-abstand-bottom">
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isRequired(param)"
                                        ng-model="vm.wochenplan.name"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                        ng-change="vm.updateWochenplan()">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   ng-model="vm.wochenplan.showBetreuungsZeitraumName"
                                   ng-change="vm.updateWochenplan()">
                            <span data-translate="WOCHENPLAN.SHOW_ZEITRAUM_NAME"></span>
                        </label>
                    </div>
                </div>
            </div>

            <dvb-zeitraum-form betreuungs-zeitraum="vm.activeZeitraumFeld.zeitraum"
                               ng-show="vm.activeZeitraumFeld"
                               on-cancel="vm.onBetreuungsZeitraumCancel()"
                               on-submit="vm.onBetreuungsZeitraumSubmit(zeitraum, context)">
            </dvb-zeitraum-form>
        </div>
    </div>

    <ul class="list-inline big-abstand-top">
        <li>
            <button type="button"
                    class="btn btn-link"
                    ng-click="vm.delete()"
                    data-translate="COMMON.LOESCHEN">
            </button>
        </li>
    </ul>
</div>
