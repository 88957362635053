<div class="dvb-kinder-ort-schliesstage-heading">
    <div class="history-columns">
        <div class="history-column-left">
            <p>
                <dvb-gueltigkeit entity="vm.kinderOrtSchliesstage"></dvb-gueltigkeit>
            </p>
        </div>

        <div class="history-column-middle">
            <p ng-if="vm.kinderOrtSchliesstage.schliesstage"
               ng-bind="vm.kinderOrtSchliesstage.schliesstage.name"></p>
            <p ng-if="!vm.kinderOrtSchliesstage.schliesstage"
               data-translate="KINDERORT.SCHLIESSTAGE.KEINE_ZUWEISUNG"></p>
        </div>
    </div>
</div>
