/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Firma} from '@dv/kitadmin/models';
import {FirmenKontingent, FirmenKontingentValue, Kita, Tarif} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Nullish, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil, END_OF_TIME} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {FirmaService} from '../../common/service/rest/firmaService';
import type {KinderOrtService} from '../../common/service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firma: '<',
        firmenKontingente: '<',
    },
    template: require('./dvb-firma-profil.html'),
    controllerAs: 'vm',
};

export class DvbFirmaProfil implements angular.IController {
    public static $inject: readonly string[] = [
        '$q',
        '$state',
        '$filter',
        '$translate',
        'errorService',
        'firmaService',
        'dialogService',
        'kinderOrtService',
    ];

    public firma: Firma | null = null;
    public firmenKontingente: FirmenKontingent[] = [];

    public today: moment.Moment = DvbDateUtil.today();
    public isLoadingSaveNeuesFirmenKontingent: boolean = false;
    public faktoren: { [k in 'weekly' | 'pensum']?: number | Nullish } & { show?: boolean } = {};
    public isLoading: boolean = false;
    public showCreateFirmenKontingentValue: { [key: string]: boolean } = {};

    public neuesFirmenKontingent: FirmenKontingent | null = null;
    public neuerFirmenKontingentValue: FirmenKontingentValue | null = null;
    public tarifSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
        private $q: angular.IQService,
        private $state: StateService,
        private $filter: angular.IFilterService,
        private $translate: angular.translate.ITranslateService,
        private errorService: ErrorService,
        private firmaService: FirmaService,
        private dialogService: DialogService,
        private kinderOrtService: KinderOrtService,
    ) {
    }

    public editFaktoren(firmenKontingent: FirmenKontingent): void {
        this.faktoren = {
            weekly: firmenKontingent.weeklyPlatzFrequency,
            pensum: firmenKontingent.pensumFaktor,
            show: true,
        };
    }

    public resetFaktoren(): void {
        this.faktoren = {};
    }

    public updateTarif(firmenKontingentValue: FirmenKontingentValue): void {
        let tarif = null;
        if (this.tarifSearchResultEntry) {
            tarif = new Tarif();
            tarif.id = this.tarifSearchResultEntry.id;
            tarif.name = this.tarifSearchResultEntry.text;
        }
        firmenKontingentValue.setTarif(tarif);
    }

    public deleteFirma(firma: Firma): angular.IPromise<unknown> {
        if (!firma.id) {
            return this.$q.reject();
        }

        return this.firmaService.deleteFirma(firma.id).then(() => {
            this.firma = null;

            return this.goBack();
        });
    }

    public saveFirmenKontingent(firmenKontingent: FirmenKontingent): void {
        this.errorService.clearAll();
        if (this.faktoren.show) {
            firmenKontingent.weeklyPlatzFrequency = this.faktoren.weekly ?? null;
            firmenKontingent.pensumFaktor = this.faktoren.pensum ?? null;
        }
        if (firmenKontingent.isValid()) {
            this.isLoading = true;
            this.firmaService.updateFirmenKontingent(firmenKontingent).finally(() => {
                this.isLoading = false;
                this.faktoren = {};
            });
        }
    }

    public isRequired(param: string): boolean {
        const valid = param !== '';
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public confirmDeleteFirma(): void {
        if (!this.firma?.id) {
            return;
        }

        this.firmaService.validateFirmaDelete(this.firma.id).then(() => {
            this.dialogService.openDeleteDialog({
                entityText: this.firma!.name!,
                confirm: () => from(this.deleteFirma(this.firma!))
                    .pipe(take(1)),
            });
        });
    }

    public terminateFirmenKontingent(kontingent: FirmenKontingent): void {
        const title = this.$translate.instant('FIRMA.FIRMEN_KONTINGENT_BEENDEN_HEADING', {
            name: kontingent.name,
        });

        this.dialogService.openDateDialog({
            title,
            confirm: endeDatum => {
                const hasValuesNachEndeDatum = kontingent.values
                    .some((value: FirmenKontingentValue) => value.gueltigAb!.isAfter(endeDatum));

                if (!hasValuesNachEndeDatum) {
                    return from(this.beendigungAction(endeDatum, kontingent)).pipe(take(1));
                }

                return this.confirmValuesAfterEnddatumDeletion$(endeDatum, kontingent);
            },
        });
    }

    public revertFirmenKontingentValue(value: FirmenKontingentValue): void {
        this.kinderOrtService.extendFirmenKontingentValue(value.id!)
            .then(() => this.reloadFirmenKontingente());
    }

    public addKita(firmenKontingentValue: FirmenKontingentValue, kitaItem: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = kitaItem.text;
        kita.id = kitaItem.id;
        firmenKontingentValue.addKita(kita);
    }

    public getActiveOrLatestKitasOfKontingent(firmenKontingent: FirmenKontingent): Kita[] {
        const entityOn = DvbDateUtil.getEntityOn(firmenKontingent.values, this.today);
        if (entityOn) {
            return entityOn.kitas;
        }

        const values = this.$filter('orderBy')(firmenKontingent.values, 'gueltigAb');

        return values[values.length - 1].kitas;
    }

    public getActiveOrLatestTarifOfKontingent(firmenKontingent: FirmenKontingent): Tarif | null {
        const entityOn = DvbDateUtil.getEntityOn(firmenKontingent.values, this.today);
        if (entityOn) {
            return entityOn.tarif;
        }

        const values = this.$filter('orderBy')(firmenKontingent.values, 'gueltigAb');

        return values[values.length - 1].tarif;
    }

    public neuesFirmenKontingentErfassen(): void {
        this.neuesFirmenKontingent = new FirmenKontingent();
        this.neuesFirmenKontingent.firma = this.firma;
        this.neuerFirmenKontingentValue = new FirmenKontingentValue();
        this.neuerFirmenKontingentValue.gueltigBis = END_OF_TIME;
        this.neuesFirmenKontingent.values = [this.neuerFirmenKontingentValue];
    }

    public saveNeuesFirmenKontingent(): void {
        this.errorService.clearAll();

        if (!this.firma?.id ||
            !this.neuesFirmenKontingent?.isValid() ||
            !this.neuerFirmenKontingentValue?.isValid() ||
            !this.neuerFirmenKontingentValue?.isKitaSet()) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        this.isLoadingSaveNeuesFirmenKontingent = true;

        this.firmaService.createFirmenKontingent(this.firma.id, this.neuesFirmenKontingent).then(() => {
            this.resetNewFirmenKontingent();

            return this.reloadFirmenKontingente();
        }).finally(() => {
            this.isLoadingSaveNeuesFirmenKontingent = false;
        });
    }

    public resetNewFirmenKontingent(): void {
        this.neuesFirmenKontingent = null;
        this.neuerFirmenKontingentValue = null;
    }

    public saveFirmenKontingentValue(
        firmenKontingentValue: FirmenKontingentValue,
        firmenKontingent: FirmenKontingent,
    ): void {
        this.errorService.clearAll();
        this.kinderOrtService.createFirmenKontingentValue(firmenKontingent.id!, firmenKontingentValue).then(() => {
            this.reloadFirmenKontingente();
        });
    }

    private reloadFirmenKontingente(): angular.IPromise<void> {
        const params = {
            cache: false,
            includes: '(firma,values.fields(tarif))',
        };

        return this.firmaService.getFirmenKontingenteWithKitas(this.firma!.id!, params)
            .then((data: FirmenKontingent[]) => {
                this.firmenKontingente = data;
            });
    }

    private beendigungAction(endeDatum: moment.Moment, kontingent: FirmenKontingent): angular.IPromise<void> {
        return this.kinderOrtService.terminateKontingent(kontingent.id!, endeDatum)
            .then(() => this.reloadFirmenKontingente());
    }

    private goBack(): Promise<unknown> {
        return this.$state.go('base.dashboard.default');
    }

    private confirmValuesAfterEnddatumDeletion$(
        endeDatum: moment.Moment,
        kontingent: FirmenKontingent,
    ): Observable<unknown> {

        const dialogSubject$ = new Subject();
        const confirm = (): Observable<unknown> => from(this.beendigungAction(endeDatum, kontingent))
            .pipe(take(1), tap(() => dialogSubject$.next(null)));

        this.dialogService.openConfirmDialog({
            title: 'FIRMA.CONFIRM_FIRMEN_KONTINGENT_BEENDEN',
            confirmActionText: 'COMMON.BEENDEN',
            confirm,
            cancel: () => dialogSubject$.next(null),
        });

        return dialogSubject$.pipe(take(1));
    }
}

componentConfig.controller = DvbFirmaProfil;
angular.module('kitAdmin').component('dvbFirmaProfil', componentConfig);
