/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ControllingChange} from '@dv/kitadmin/models';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {MonthlyControllingFigures} from './MonthlyControllingFigures';
import {WeeklyControllingFigures} from './WeeklyControllingFigures';

export class ControllingReport {
    public static $inject: readonly string[] = [];

    public constructor(
            public kitaId: string,
            public kitaName: string,
            public jahr: number,
            public timestamp: moment.Moment,
            public average: WeeklyControllingFigures | null = null,
            public changes: ControllingChange[] = [],
            public weeklyFigures: WeeklyControllingFigures[] = [],
            public monthlyAverage: MonthlyControllingFigures | null = null,
            public monthlyFigures: MonthlyControllingFigures[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): ControllingReport {
        const answer = new ControllingReport(
                data.kitaId,
                data.kitaName,
                data.jahr,
                checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestamp)));

        if (data.average) {
            answer.average = WeeklyControllingFigures.apiResponseTransformer(data.average);
        }

        if (data.monthlyAverage) {
            answer.monthlyAverage = MonthlyControllingFigures.apiResponseTransformer(data.monthlyAverage);
        }

        answer.changes = DvbRestUtil.transformArray(data.changes, ControllingChange);
        answer.weeklyFigures = DvbRestUtil.transformArray(data.weeklyFigures, WeeklyControllingFigures);
        answer.monthlyFigures = DvbRestUtil.transformArray(data.monthlyFigures, MonthlyControllingFigures);

        return answer;
    }
}
