/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * Transition Hooks can use different priorities.
 * Hooks with higher priority run before lower priority.
 */
export enum OnBeforePriorities {
    TRANSLATIONS = 100,
    AUTH_READINESS = 95,
    CLEAR_ERRORS = 90,
    TOKEN_LOGIN = 70,
    AUTHENTICATION = 50,
    PRIVACY_CONSENT = 25,
    AUTHORISATION = 10,
}
