/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BetreuungsVereinbarungsKonfiguration, DokumentLayout, KinderOrt} from '@dv/kitadmin/models';
import {BetreuungsVereinbarungsKonfigurationType} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {from, take, tap} from 'rxjs';
import type {
    BetreuungsVereinbarungsKonfigurationService,
} from '../../../common/service/rest/kinderort/betreuungsVereinbarungsKonfigurationService';
import * as settingsStates from '../../kinderort-settings-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        konfigurationTypes: '<',
        dokumentLayouts: '<',
    },
    template: require('./dvb-kita-vereinbarungs-konfigurationen.html'),
    controllerAs: 'vm',
};

export class DvbKitaVereinbarungsKonfigurationen implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'betreuungsVereinbarungsKonfigurationService',
        'dialogService',
        'errorService',
    ];

    public kinderOrt!: KinderOrt;
    public konfigurationTypes: BetreuungsVereinbarungsKonfigurationType[] = [];
    public konfigurationen: BetreuungsVereinbarungsKonfiguration[] = [];
    public dokumentLayouts: DokumentLayout[] = [];

    public newConfigType: BetreuungsVereinbarungsKonfigurationType | null = null;
    public isLoading: boolean = false;

    public today: moment.Moment = DvbDateUtil.today();

    public constructor(
        private $state: StateService,
        private betreuungsVereinbarungsKonfigurationService: BetreuungsVereinbarungsKonfigurationService,
        private dialogService: DialogService,
        private errorService: ErrorService,
    ) {
    }

    public $onChanges(): void {
        if (this.konfigurationTypes.length > 0) {
            this.konfigurationen = this.konfigurationTypes[0].konfigurationen;
        }
    }

    public newConfig(typeId: string): void {
        this.$state.go(settingsStates.VEREINBARUNGS_CONFIG_CREATION_STATE.name, {typeId});
    }

    public confirmDelete(konfiguration: Persisted<BetreuungsVereinbarungsKonfiguration>): void {
        this.dialogService.openDeleteDialog({
            confirm: () => from(this.betreuungsVereinbarungsKonfigurationService.delete(konfiguration.id))
                .pipe(take(1), tap(() => this.$state.reload())),
            entityText: 'COMMON.BETREUUNGSVEREINBARUNG.CONFIG',
        });
    }

    public isRequired(param: string): boolean {
        const valid = param !== '';
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public saveConfigType(type: BetreuungsVereinbarungsKonfigurationType): void {
        this.errorService.clearAll();
        if (type.isValid()) {
            this.betreuungsVereinbarungsKonfigurationService.updateType(type);
        }
    }

    public createNewConfigType(): void {
        this.newConfigType = new BetreuungsVereinbarungsKonfigurationType();
    }

    public saveNewConfigType(): void {
        this.errorService.clearAll();

        if (!this.newConfigType?.isValid()) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        this.isLoading = true;

        this.betreuungsVereinbarungsKonfigurationService.createType(this.kinderOrt.id!, this.newConfigType)
            .then(() => {
                // // this.resetNeuenKonfigurationsType();
                this.$state.reload();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public confirmDeleteType(konfigurationType: Persisted<BetreuungsVereinbarungsKonfigurationType>): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.BETREUUNGSVEREINBARUNG.TYPE.NAME',
            confirm: () => from(this.betreuungsVereinbarungsKonfigurationService.deleteType(konfigurationType.id))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public resetNewConfigType(): void {
        this.newConfigType = null;
    }

}

componentConfig.controller = DvbKitaVereinbarungsKonfigurationen;
angular.module('kitAdmin').component('dvbKitaVereinbarungsKonfigurationen', componentConfig);
