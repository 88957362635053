/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';

/**
 * Pre-defined available configurable behaviours of directive `requires-role` and `requires-permission`
 */
export const AUTHORISATION_STRATEGIES = {
    enableElement(this: void, $attrs: angular.IAttributes): void {
        $attrs.$set('disabled', false);
    },
    disableElement(this: void, $attrs: angular.IAttributes): void {
        $attrs.$set('disabled', true);
    },
    showElement(this: void, $attrs: angular.IAttributes): void {
        $attrs.$removeClass('ng-hide');
    },
    hideElement(this: void, $attrs: angular.IAttributes): void {
        $attrs.$addClass('ng-hide');
    },
};
