/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entry: '<',
    },
    template: require('./dvb-bank-statement-entry-heading.html'),
    controllerAs: 'vm',
};

export class DvbBankStatementEntryHeading {
    public static $inject: readonly string[] = [];

    public entry!: BankStatementEntry;
}

componentConfig.controller = DvbBankStatementEntryHeading;
angular.module('kitAdmin').component('dvbBankStatementEntryHeading', componentConfig);
