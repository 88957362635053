/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DialogService} from '@dv/kitadmin/ui';
import type {DateLimitation, IDisplayable, ILimited} from '@dv/shared/code';
import {FormContext, TerminationMode, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, of, take} from 'rxjs';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        entityForm: '?entityForm',
    },
    bindings: {
        entities: '<',
        assignedEntity: '<?',
        translationRoot: '<',
        dateLimitation: '<?',
        disableNewForm: '<?',
        hideTermination: '<?',
        onSave: '&',
        onTerminate: '&',
        onNew: '&?',
    },
    template: require('./dvb-limited-entity-assignment-controls.html'),
    controllerAs: 'vm',
};

export class DvbLimitedEntityAssignmentControls implements angular.IController {

    public static $inject: readonly string[] = ['dialogService', 'errorService', '$translate'];

    public entities: ILimited[] = [];
    public assignedEntity?: IDisplayable;
    public translationRoot!: string;
    public dateLimitation?: DateLimitation;
    public disableNewForm: boolean = false;

    public onSave!: (data: { context: FormContext }) => unknown;
    public onTerminate!: (data: { endDate: moment.Moment; mode: TerminationMode }) => angular.IPromise<unknown>;
    public onNew?: (data: { context: FormContext }) => unknown;

    public formContext: FormContext = new FormContext();

    public constructor(
        private readonly dialogService: DialogService,
        private readonly errorService: ErrorService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public defineNew(): void {
        if (!this.disableNewForm) {
            this.formContext.enableEditMode();
        }

        if (TypeUtil.isFunction(this.onNew)) {
            this.onNew({context: this.formContext});
        }
    }

    public terminate(): void {
        const terminateAction = (endDate: moment.Moment, mode: TerminationMode): Observable<unknown> => {
            this.errorService.clearAll();

            return from(this.onTerminate({endDate, mode})).pipe(take(1));
        };

        this.dialogService.openDateDialog({
            title: this.$translate.instant(`${this.translationRoot}.ZUWEISUNG_BEENDEN_HEADING`, {
                assignedEntity: this.assignedEntity?.getDisplayName(),
            }),
            dateLimitation: this.dateLimitation,
            confirm: endDate => {
                const valuesAfterEndDate = this.entities.filter(entity => entity.gueltigAb?.isAfter(endDate));

                if (!valuesAfterEndDate.length) {
                    return terminateAction(endDate, TerminationMode.TERMINATE);
                }

                this.dialogService.openTerminateDialog({
                    translationRoot: this.translationRoot,
                    endDate,
                    confirm: (data: { date: moment.Moment; mode: TerminationMode }) =>
                        from(terminateAction(data.date, data.mode))
                            .pipe(take(1)),
                });

                // closes the date dialog (a new, independent, terminate dialog is open above)
                return of(null);
            },
        });
    }

    public save(): void {
        this.formContext.startLoading();
        this.onSave({context: this.formContext});
    }
}

componentConfig.controller = DvbLimitedEntityAssignmentControls;
angular.module('kitAdmin').component('dvbLimitedEntityAssignmentControls', componentConfig);
