/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeEntity} from '@dv/kitadmin/models';
import type {FunctionType, SearchResultEntry} from '@dv/shared/code';
import {hasOwnPropertyGuarded} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {
    KibonExchangeEntityAction,
    KibonExchangeEntityActionTranslations,
} from '../../models/KibonExchangeEntityAction';
import {ActionType, KibonExchangeNavigationAction} from '../../models/KibonExchangeEntityAction';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entity: '<',
        actions: '<',
        onAssign: '&',
        onAction: '&',
    },
    template: require('./dvb-kibon-exchange-entity-actions.html'),
    controllerAs: 'vm',
};

export class DvbKibonExchangeEntityActions implements angular.IController {

    public static $inject: readonly string[] = ['$state'];

    public entity!: KibonExchangeEntity;
    public actions: KibonExchangeEntityAction<KibonExchangeEntity>[] = [];
    public onAssign!: (params: { item: SearchResultEntry }) => void;
    public onAction!: (val: { action: FunctionType }) => void;

    public filteredActions: {
        action: KibonExchangeEntityAction<KibonExchangeEntity>;
        translationKeys: KibonExchangeEntityActionTranslations;
        href?: string;
    }[] = [];
    public readonly ACTION_TYPE = ActionType;

    public showAssign: boolean = false;
    public isSearchInputShown: boolean = false;

    public constructor(
        private readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        this.showAssign = this.entity.canAssign();
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.actions) {
            const filteredActions = this.actions.filter(action => action.show(this.entity));
            this.filteredActions = filteredActions.map(action => {
                const translationKeys: KibonExchangeEntityActionTranslations = action.translationKeys(this.entity);
                if (hasOwnPropertyGuarded(action, 'confirmationRequired')
                    && action.confirmationRequired
                    && !translationKeys.confirmation) {
                    throw new Error('Confirmation translation key required for action that requires a confirmation.');
                }

                const href = action instanceof KibonExchangeNavigationAction ?
                    this.$state.href(action.state, action.params(this.entity)) :
                    undefined;

                return {action, translationKeys, href};
            });
        }
    }
}

componentConfig.controller = DvbKibonExchangeEntityActions;
angular.module('kitAdmin').component('dvbKibonExchangeEntityActions', componentConfig);
