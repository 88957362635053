/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * original source:
 * https://github.com/getsentry/sentry-javascript/blob/c518955b774e5bbc36e7f1a22410703469911a2a/packages/integrations/src/angular.ts
 *
 * Slightly adapted to our code style and replaced some deprecated dependencies.
 */

import type {Environment} from '@dv/kitadmin/models';
import type {Event, EventProcessor, Hub, Integration} from '@sentry/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {GLOBAL_OBJ, logger} from '@sentry/utils';

// See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
const angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

/**
 * AngularJS integration
 *
 * Provides an $exceptionHandler for AngularJS
 */
export class AngularJsIntegration implements Integration {
    /**
     * @inheritDoc
     */
    public static id: string = 'AngularJS';

    /**
     * moduleName used in Angular's DI resolution algorithm
     */
    public static moduleName: string = 'ngSentry';

    /**
     * @inheritDoc
     */
    public name: string = AngularJsIntegration.id;

    /**
     * Angular's instance
     */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly _angular: any;

    /**
     * ngSentry module instance
     */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly _module: any;

    /**
     * Returns current hub.
     */
    private _getCurrentHub?: () => Hub;

    /**
     * @inheritDoc
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public constructor(ENVIRONMENT: Environment, options: { angular?: any } = {}) {
        const isDebugBuild = ENVIRONMENT.DEBUG_INFO_ENABLED;

        if (isDebugBuild) {
            logger.log(
                'You are still using the Angular integration, consider moving to @sentry/angular');
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
        this._angular = options.angular ?? (GLOBAL_OBJ as any).angular;

        if (!this._angular) {
            if (isDebugBuild) {
                logger.error('AngularIntegration is missing an Angular instance');
            }

            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this._module = this._angular.module(AngularJsIntegration.moduleName, []);
    }

    /**
     * @inheritDoc
     */
    public setupOnce(_: (callback: EventProcessor) => void, getCurrentHub: () => Hub): void {
        if (!this._module) {
            return;
        }

        this._getCurrentHub = getCurrentHub;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this._module.config([
            '$provide',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ($provide: any): void => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                $provide.decorator('$exceptionHandler', ['$delegate', this.$exceptionHandlerDecorator.bind(this)]);
            },
        ]);
    }

    /**
     * Angular's exceptionHandler for Sentry integration
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private $exceptionHandlerDecorator($delegate: any): any {
        return (exception: Error, cause?: string): void => {
            const hub = this._getCurrentHub?.();

            if (hub?.getIntegration(AngularJsIntegration)) {
                hub.withScope(scope => {
                    if (cause) {
                        scope.setExtra('cause', cause);
                    }

                    scope.addEventProcessor((event: Event) => {
                        const ex = event.exception?.values?.[0];

                        if (ex) {
                            const matches = angularPattern.exec(ex.value ?? '');

                            if (matches) {
                                // This type now becomes something like: $rootScope:inprog
                                ex.type = matches[1];
                                ex.value = matches[2];
                                event.message = `${ex.type}: ${ex.value}`;
                                // auto set a new tag specifically for the angular error url
                                event.extra = {
                                    ...event.extra,
                                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                    angularDocs: matches[3].substring(0, 250),
                                };
                            }
                        }

                        return event;
                    });

                    hub.captureException(exception);
                });
            }
            $delegate(exception, cause);
        };
    }
}
