<div class="dvb-transaktion-zuweisen-vorgeschlagen">

    <div class="filter-bar normal-abstand-top big-abstand-bottom">
        <ul class="filter-option-list">
            <li>
                <a ui-sref="^.vorgeschlagen({id: vm.bankStatementEntry.id})"
                   data-translate="FAKTURA.KONTENABGLEICH.VORGESCHLAGEN"
                   class="btn label label-default label-radio"
                   ui-sref-active="active">
                </a>
            </li>
            <li>
                <a ui-sref="^.alle({id: vm.bankStatementEntry.id})"
                   data-translate="COMMON.ALLE"
                   class="btn label label-default label-radio"
                   ui-sref-active="active">
                </a>
            </li>
        </ul>
    </div>

    <div class="row">
        <div class="col-md-7">
            <div
                ng-if="(vm.matchTypes | filter: {loading: false, rechnungen: []}).length === 0 && (vm.matchTypes | filter: {loading: true}).length === 0">
                <p data-translate="FAKTURA.KEINE_VORSCHLAEGE"></p>
            </div>

            <div ng-repeat="type in vm.matchTypes"
                 class="big-abstand-bottom"
                 ng-if="type.rechnungen.length > 0 || type.loading">

                <h4 data-translate="{{type.translationKey}}"
                    data-translate-values="type.translationArgsFn(type)"
                    class="normal-abstand-bottom">
                </h4>

                <dvb-pages items="type.rechnungen"
                           total-items="type.rechnungen.length"
                           is-loading="type.loading"
                           items-per-page="type.itemsPerPage"
                           current-page="type.currentPage"
                           entity-name="'COMMON.RECHNUNG.MF'"
                           on-update-page="type.currentPage = page"
                           on-update-items-per-page="type.itemsPerPage = itemsPerPage; type.recomputePages()">
                    <page-items>
                        <dvb-rechnungen-list rechnungen="type.pages[type.currentPage - 1]"
                                             mode="vm.mode">
                        </dvb-rechnungen-list>
                    </page-items>
                </dvb-pages>
            </div>
        </div>
    </div>

    <!-- Confirm dialog view -->
    <div ui-view></div>

</div>
