<div class="dvb-stundenvergleich-soll-ist-report">
	<h2 data-translate="{{vm.reportState.titleKey}}"></h2>

	<form name="vm.stundenvergleichReportForm" novalidate>

		<dvb-report-template-selector
				report-state="vm.reportState"
				on-selection-change="vm.reportTemplate = reportTemplate">
		</dvb-report-template-selector>

		<dvb-search-get-single-entity as-col="true"
									  entity-to-search="KIND"
									  ng-model="vm.kind"
									  placeholder-key="COMMON.KIND.AUSWAEHLEN">
		</dvb-search-get-single-entity>

		<dvlib-date-switcher mode="month"
							 class="small-abstand-left normal-abstand-bottom"
							 [(date)]="vm.gueltigAb"
							 [is-disabled]="vm.isLoading">
		</dvlib-date-switcher>

		<dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
	</form>
</div>
