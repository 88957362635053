/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import * as kibonStates from '../../kibon-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-kibon-main.html'),
    controllerAs: 'vm',
};

export class DvbKibonMain implements angular.IController {
    public static $inject: readonly string[] = [];

    public states: typeof kibonStates = kibonStates;
}

componentConfig.controller = DvbKibonMain;
angular.module('kitAdmin').component('dvbKibonMain', componentConfig);
