<div class="dvb-dokumentlayout-content">

    <div ng-if="vm.dokumentLayout.hasOnlyDefaults()" data-translate="FAKTURA.NUR_DEFAULTS"></div>

    <div class="row normal-abstand-bottom normal-abstand-top">
        <div class="col-md-12">
            <h3 data-translate="COMMON.NAME"></h3>
            <span ng-bind="vm.dokumentLayout.name"></span>
        </div>
    </div>

    <div ng-if="vm.dokumentLayout.isKompakt">
        <h3 data-translate="DOKUMENT_LAYOUT.MODE_KOMPAKT"></h3>
    </div>

    <div ng-if="vm.dokumentLayout.pageMargin !== null">
        <h3 data-translate="FAKTURA.SEITENRAENDER"></h3>
        <div class="row">
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.pageMargin.left})">
            </span>
            </div>
            <div class="col-md-6">
             <span
                 ng-bind="('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.pageMargin.top})">
             </span>
            </div>
        </div>
        <div class="row small-abstand-top">
            <div class="col-md-6">
             <span
                 ng-bind="('FAKTURA.ABSTAND_RECHTS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.pageMargin.right})">
             </span>
            </div>
            <div class="col-md-6">
             <span
                 ng-bind="('FAKTURA.ABSTAND_UNTEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.pageMargin.bottom})">
             </span>
            </div>
        </div>
    </div>

    <div ng-if="vm.dokumentLayout.logo !== null">
        <h3 class="normal-abstand-top" data-translate="FAKTURA.LOGO"></h3>

        <div class="normal-abstand-top">
            <div class="row normal-abstand-bottom">
                <div class="col-md-6">
                    <dvb-image-blob-preview image-blob="vm.dokumentLayout.logo.image"></dvb-image-blob-preview>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                 <span
                     ng-bind="('FAKTURA.BREITE' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.logo.width})">
                 </span>
                </div>
            </div>
            <div class="row small-abstand-top">
                <div class="col-md-6">
                 <span
                     ng-bind="('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.logo.left})">
                 </span>
                </div>
                <div class="col-md-6">
                 <span
                     ng-bind="('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.logo.top})">
                 </span>
                </div>
            </div>
        </div>
    </div>

    <div ng-if="vm.dokumentLayout.receiverAddress !== null">
        <h3 class="normal-abstand-top">
            <label>
                <span data-translate="FAKTURA.EMPFAENGERADRESSE"></span>
            </label>
        </h3>

        <div class="row small-abstand-top">
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.receiverAddress.left})">
            </span>
            </div>
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.receiverAddress.top})">
            </span>
            </div>
        </div>
    </div>

    <div ng-if="vm.dokumentLayout.senderAddress !== null">
        <h3 class="normal-abstand-top">
            <label>
                <span data-translate="COMMUNICATION.EMAIL.ABSENDER_ADRESSE"></span>
            </label>
        </h3>

        <div class="row small-abstand-top">
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.senderAddress.left})">
            </span>
            </div>
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.senderAddress.top})">
            </span>
            </div>
        </div>
    </div>

    <div ng-if="vm.dokumentLayout.massenversandText !== null">
        <label class="normal-abstand-top">
            <h3 data-translate="KINDERORT.TEXT_MASSENVERSAND"></h3>
            <span ng-bind="vm.dokumentLayout.massenversandText"></span>
        </label>
    </div>

    <div ng-if="vm.dokumentLayout.footer !== null">
        <h3 class="normal-abstand-top">
            <label>
                <span data-translate="DOKUMENT_LAYOUT.FUSSZEILE"></span>
            </label>
        </h3>

        <div class="row small-abstand-top">
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.footer.left})">
            </span>
            </div>
            <div class="col-md-6">
            <span
                ng-bind="('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: vm.dokumentLayout.footer.top})">
            </span>
            </div>
        </div>
    </div>

    <div ng-if="vm.dokumentLayout.steuerbescheinigungAlternativ">
        <h3 class="normal-abstand-top" data-translate="COMMON.DOKUMENT_LAYOUT.STEUERBESCHEINIGUNG_ALTERNATIV"></h3>
        <dvb-translatable-label translation="vm.dokumentLayout.steuerbescheinigungAlternativ"></dvb-translatable-label>
    </div>

    <div ng-if="vm.dokumentLayout.steuerbescheinigungBemerkung">
        <h3 class="normal-abstand-top" data-translate="COMMON.DOKUMENT_LAYOUT.STEUERBESCHEINIGUNG_BEMERKUNG"></h3>
        <dvb-translatable-label translation="vm.dokumentLayout.steuerbescheinigungBemerkung"
                                prewrap="true">
        </dvb-translatable-label>
    </div>

    <p class="normal-abstand-top">
        <dvb-loading-button type="button" as-link="true" is-loading="vm.isLoading" on-click="vm.downloadPreview(event)">
            <span data-translate="FAKTURA.VORSCHAU_ANZEIGEN"></span>
        </dvb-loading-button>
    </p>
</div>
