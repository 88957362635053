/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IAppConfigService} from '@dv/kitadmin/models';
import {AuthState, LogFactory} from '@dv/shared/code';
import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import {firstValueFrom, switchMap} from 'rxjs';
import type {AuthService} from '../../../authentication/service/authService';
import {IS_NO_STATE} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

const LOG = LogFactory.createLog('authReadinessHook');

export function authReadinessHook($transitions: TransitionService): void {
    $transitions.onBefore(IS_NO_STATE, authReadinessCallback, {priority: OnBeforePriorities.AUTH_READINESS});
}

function authReadinessCallback(transition: Transition): HookResult {
    const authService = transition.injector().get<AuthService>('authService');
    const appConfigService = transition.injector().get<IAppConfigService>('appConfigService');

    const readiness$ = authService.currentAuthState$().pipe(
        switchMap(state => {
            if (state === AuthState.UNDEFINED) {
                const idpHint = transition.params().idp;
                LOG.trace('bootstraping authentication routine', idpHint);
                appConfigService.getConfig()
                    .then(config => authService.initWithConfig(config, idpHint));
            }

            return authService.authReady$();
        }),
    );

    return firstValueFrom(readiness$).then(() => {
        return true;
    });
}
