<div class="dvb-betreuungsperson-main">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-female header-icon"
               uib-tooltip="{{'COMMON.BETREUUNGS_PERSON.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>

            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{vm.betreuungsPerson.getDisplayName()}}"
                    tooltip-placement="top-left"
                    ng-bind="vm.betreuungsPerson.getDisplayName()">
                </h1>

                <span class="layout-spacer"></span>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active="active">
                        <a ui-sref="base.fraktion.betreuungs_person.profil"
                           data-translate="COMMON.PROFIL">
                        </a>
                    </li>
                    <li ui-sref-active="active"
                        dvb-auth
                        require-permission="{{'kita:view:' + vm.betreuungsPerson.kita.id}}">
                        <a ui-sref="base.fraktion.betreuungs_person.kontakt"
                           data-translate="COMMON.KONTAKT">
                        </a>
                    </li>
                    <li ui-sref-active="active"
                        dvb-auth
                        require-permission="{{'kita:view:' + vm.betreuungsPerson.kita.id}}">
                        <a ui-sref="base.fraktion.betreuungs_person.kapazitaet"
                           data-translate="FRAKTION.KAPAZITAET">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{'kita:control:' + vm.betreuungsPerson.kita.id}}"
                        require-condition="vm.betreuungsPerson.kita.hasMonatsBlatt">
                        <a ui-sref="base.fraktion.betreuungs_person.monatsblatt"
                           data-translate="FRAKTION.NAV.MONATSBLATT">
                        </a>
                    </li>
                    <li ui-sref-active="active">
                        <a ui-sref="base.fraktion.betreuungs_person.notizen"
                           data-translate="NOTIZ.PLURAL">
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>

    <ui-view></ui-view>
</div>
