/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, Kontaktperson, TagesschuleAnmeldungKind, TagesschuleGesuchsteller} from '@dv/kitadmin/models';
import {AnmeldungAdresseHousehold, LimitedAdresse} from '@dv/kitadmin/models';
import type {Adresse, PageContainer, RestLimited} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {KibonExchangeTagesschuleAnmeldung} from '../models/KibonExchangeTagesschuleAnmeldung';
import type {KibonExchangeTagesschuleConfirmation} from '../models/KibonExchangeTagesschuleConfirmation';
import {KibonExchangeTagesschulModule} from '../models/KibonExchangeTagesschulModule';
import type {TagesschuleAnmeldungFilter} from '../models/TagesschuleAnmeldungFilter';
import type {KibonQueryApiService, QueryKibonExchangeApiQueryapiParameter} from './kibonQueryApiService';

export class TagesschuleAnmeldungService implements KibonQueryApiService {
    public static $inject: readonly string[] = ['$http'];

    public static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kibon/tagesschule/anmeldungen`;

    public constructor(private readonly $http: angular.IHttpService) {
    }

    public queryExchangeService(params: Readonly<QueryKibonExchangeApiQueryapiParameter>): angular.IPromise<unknown> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/queryapi${DvbRestUtil.encodeMatrixParams(params)}`;

        return this.$http.get(url);
    }

    public get(id: string): angular.IPromise<KibonExchangeTagesschuleAnmeldung> {
        const url = TagesschuleAnmeldungService.BASE_URL;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, KibonExchangeTagesschuleAnmeldung, id)
            .then(checkPresent);
    }

    public getFiltered(
        filter?: TagesschuleAnmeldungFilter,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<KibonExchangeTagesschuleAnmeldung>> {

        return DvbRestUtilAngularJS.getPagedItems(TagesschuleAnmeldungService.BASE_URL,
            KibonExchangeTagesschuleAnmeldung,
            filter?.toRestObject(),
            config);
    }

    public assign(anmeldungId: string, kindId: string): angular.IHttpPromise<void> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/assign/${
            encodeURIComponent(kindId)}`;

        return this.$http.put(url, {});
    }

    public unAssign(anmeldungId: string): angular.IHttpPromise<void> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/assign`;

        return this.$http.delete(url);
    }

    public ignore(anmeldungId: string): angular.IHttpPromise<void> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/ignore`;

        return this.$http.put(url, {});
    }

    public decline(anmeldungId: string): angular.IHttpPromise<void> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/decline`;

        return this.$http.put(url, {});
    }

    public unIgnore(anmeldungId: string): angular.IHttpPromise<void> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/ignore`;

        return this.$http.delete(url);
    }

    public getModule(institutionId: string, params: RestLimited): angular.IPromise<KibonExchangeTagesschulModule> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/module`;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, KibonExchangeTagesschulModule, institutionId, params)
            .then(checkPresent);
    }

    public confirm(confirmation: KibonExchangeTagesschuleConfirmation): angular.IHttpPromise<void> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(confirmation.id)}/confirm`;

        return this.$http.post(url, confirmation.toRestObject());
    }

    public findAnmeldungAdresseHousehold(anmeldungId: string): angular.IPromise<AnmeldungAdresseHousehold[]> {
        const url = `${TagesschuleAnmeldungService.BASE_URL}/${encodeURIComponent(anmeldungId)}/matches`;

        return DvbRestUtilAngularJS.getPagedItems(url, AnmeldungAdresseHousehold).then(page => page.items);
    }

    public initializeKind(kind: Kind, anmeldungKind: TagesschuleAnmeldungKind): void {
        kind.vorName = anmeldungKind.vorName;
        kind.familienName = anmeldungKind.familienName;
        kind.geburtsTag = anmeldungKind.geburtsdatum;
        kind.geschlecht = anmeldungKind.geschlecht;
    }

    public initializeKontaktperonWithAnmeldungKontakt(
        kontaktperson: Kontaktperson,
        anmeldungKontakt: TagesschuleGesuchsteller,
        adresse?: Adresse | null,
    ): void {
        kontaktperson.vorName = anmeldungKontakt.vorName;
        kontaktperson.familienName = anmeldungKontakt.familienName;
        kontaktperson.email = anmeldungKontakt.email;
        kontaktperson.geschlecht = anmeldungKontakt.geschlecht;
        kontaktperson.telefon = anmeldungKontakt.telefon;
        kontaktperson.mobile = anmeldungKontakt.mobile;

        if (adresse) {
            const limitedAdresse = new LimitedAdresse(null, null, null, adresse);
            kontaktperson.adressen.push(limitedAdresse);
        }
    }
}
