/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {InjectionToken} from '@angular/core';
import {BroadcastService} from '@dv/shared/angular';

export type PrincipalChanged = 'PrincipalChanged';
export const KITADMIN_BROADCAST_SERVICE_TOKEN = new InjectionToken<BroadcastService<PrincipalChanged>>(
    'kitAdminBroadcastService',
    {factory: () => new BroadcastService('kitAdmin')});
