/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';
import {CustomHttpParameterCodec} from '../encoder';

import type {AdministrationResourceGetMandantRechnungenRechnungenParameter} from '@dv/shared/backend/model/administration-resource-get-mandant-rechnungen-rechnungen-parameter';
import type {CheckInEventResourceGetAllKindIdMatrixParameter} from '@dv/shared/backend/model/check-in-event-resource-get-all-kind-id-matrix-parameter';
import type {CheckInEventResourceUpdateEventUpdateParameter} from '@dv/shared/backend/model/check-in-event-resource-update-event-update-parameter';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxKindAbgeholtEvent} from '@dv/shared/backend/model/jax-kind-abgeholt-event';
import type {JaxKindAbwesendEvent} from '@dv/shared/backend/model/jax-kind-abwesend-event';
import type {JaxKindAngekommenEvent} from '@dv/shared/backend/model/jax-kind-angekommen-event';
import type {JaxKindCheckInEvent} from '@dv/shared/backend/model/jax-kind-check-in-event';
import type {JaxKindCheckInEventList} from '@dv/shared/backend/model/jax-kind-check-in-event-list';
import type {JaxKindPausiertEvent} from '@dv/shared/backend/model/jax-kind-pausiert-event';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';

export interface CheckInEventServiceCreateEventRequestParams {
    kindId: EntityId;
    kinderOrtId: EntityId;
    jaxKindCheckInEvent?: JaxKindCheckInEvent;
}

export interface CheckInEventServiceDeleteEventRequestParams {
    kindCheckInEventId: EntityId;
}

export interface CheckInEventServiceGetAllRequestParams {
    kindId: EntityId;
    kindIdMatrix: CheckInEventResourceGetAllKindIdMatrixParameter;
}

export interface CheckInEventServiceGetForKindAndOrtRequestParams {
    kindId: EntityId;
    kindIdMatrix: AdministrationResourceGetMandantRechnungenRechnungenParameter;
    kinderOrtId: EntityId;
}

export interface CheckInEventServiceKindAbgeholtRequestParams {
    kindId: EntityId;
    kinderOrtId: EntityId;
    jaxKindAbgeholtEvent?: JaxKindAbgeholtEvent;
}

export interface CheckInEventServiceKindAbwesendRequestParams {
    kindId: EntityId;
    kinderOrtId: EntityId;
    jaxKindAbwesendEvent?: JaxKindAbwesendEvent;
}

export interface CheckInEventServiceKindAngekommenRequestParams {
    kindId: EntityId;
    kinderOrtId: EntityId;
    jaxKindAngekommenEvent?: JaxKindAngekommenEvent;
}

export interface CheckInEventServiceKindPausiertRequestParams {
    kindId: EntityId;
    kinderOrtId: EntityId;
    jaxKindPausiertEvent?: JaxKindPausiertEvent;
}

export interface CheckInEventServiceRestoreEventRequestParams {
    kindCheckInEventId: EntityId;
}

export interface CheckInEventServiceUpdateEventRequestParams {
    kindCheckInEventId: EntityId;
    update: CheckInEventResourceUpdateEventUpdateParameter;
}

@Injectable({
  providedIn: 'root'
})
export class CheckInEventService {

    protected basePath = '/kitadmin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && !(value instanceof Date)) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // noinspection AssignmentResultUsedJS
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                // noinspection AssignmentResultUsedJS
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public createEvent$(requestParameters: CheckInEventServiceCreateEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createEvent$(requestParameters: CheckInEventServiceCreateEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createEvent$(requestParameters: CheckInEventServiceCreateEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createEvent$(requestParameters: CheckInEventServiceCreateEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling createEvent$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling createEvent$.');
        }
        const jaxKindCheckInEvent = requestParameters.jaxKindCheckInEvent;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKindCheckInEvent,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public deleteEvent$(requestParameters: CheckInEventServiceDeleteEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteEvent$(requestParameters: CheckInEventServiceDeleteEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteEvent$(requestParameters: CheckInEventServiceDeleteEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteEvent$(requestParameters: CheckInEventServiceDeleteEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindCheckInEventId = requestParameters.kindCheckInEventId;
        if (kindCheckInEventId === null || kindCheckInEventId === undefined) {
            throw new Error('Required parameter kindCheckInEventId was null or undefined when calling deleteEvent$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/${this.configuration.encodeParam({name: "kindCheckInEventId", value: kindCheckInEventId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getAll$(requestParameters: CheckInEventServiceGetAllRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKindCheckInEventList>;
    public getAll$(requestParameters: CheckInEventServiceGetAllRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKindCheckInEventList>>;
    public getAll$(requestParameters: CheckInEventServiceGetAllRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKindCheckInEventList>>;
    public getAll$(requestParameters: CheckInEventServiceGetAllRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling getAll$.');
        }
        const kindIdMatrix = requestParameters.kindIdMatrix;
        if (kindIdMatrix === null || kindIdMatrix === undefined) {
            throw new Error('Required parameter kindIdMatrix was null or undefined when calling getAll$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}${this.configuration.encodeParam({name: "kindIdMatrix", value: kindIdMatrix, in: "path", style: "matrix", explode: true, dataType: "CheckInEventResourceGetAllKindIdMatrixParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKindCheckInEventList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getForKindAndOrt$(requestParameters: CheckInEventServiceGetForKindAndOrtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKindCheckInEventList>;
    public getForKindAndOrt$(requestParameters: CheckInEventServiceGetForKindAndOrtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKindCheckInEventList>>;
    public getForKindAndOrt$(requestParameters: CheckInEventServiceGetForKindAndOrtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKindCheckInEventList>>;
    public getForKindAndOrt$(requestParameters: CheckInEventServiceGetForKindAndOrtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling getForKindAndOrt$.');
        }
        const kindIdMatrix = requestParameters.kindIdMatrix;
        if (kindIdMatrix === null || kindIdMatrix === undefined) {
            throw new Error('Required parameter kindIdMatrix was null or undefined when calling getForKindAndOrt$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getForKindAndOrt$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}${this.configuration.encodeParam({name: "kindIdMatrix", value: kindIdMatrix, in: "path", style: "matrix", explode: true, dataType: "AdministrationResourceGetMandantRechnungenRechnungenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKindCheckInEventList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public kindAbgeholt$(requestParameters: CheckInEventServiceKindAbgeholtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKindCheckInEvent>;
    public kindAbgeholt$(requestParameters: CheckInEventServiceKindAbgeholtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKindCheckInEvent>>;
    public kindAbgeholt$(requestParameters: CheckInEventServiceKindAbgeholtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKindCheckInEvent>>;
    public kindAbgeholt$(requestParameters: CheckInEventServiceKindAbgeholtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling kindAbgeholt$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling kindAbgeholt$.');
        }
        const jaxKindAbgeholtEvent = requestParameters.jaxKindAbgeholtEvent;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/abgeholt/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKindCheckInEvent>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKindAbgeholtEvent,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public kindAbwesend$(requestParameters: CheckInEventServiceKindAbwesendRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKindCheckInEvent>;
    public kindAbwesend$(requestParameters: CheckInEventServiceKindAbwesendRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKindCheckInEvent>>;
    public kindAbwesend$(requestParameters: CheckInEventServiceKindAbwesendRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKindCheckInEvent>>;
    public kindAbwesend$(requestParameters: CheckInEventServiceKindAbwesendRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling kindAbwesend$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling kindAbwesend$.');
        }
        const jaxKindAbwesendEvent = requestParameters.jaxKindAbwesendEvent;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/abwesend/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKindCheckInEvent>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKindAbwesendEvent,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public kindAngekommen$(requestParameters: CheckInEventServiceKindAngekommenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKindCheckInEvent>;
    public kindAngekommen$(requestParameters: CheckInEventServiceKindAngekommenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKindCheckInEvent>>;
    public kindAngekommen$(requestParameters: CheckInEventServiceKindAngekommenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKindCheckInEvent>>;
    public kindAngekommen$(requestParameters: CheckInEventServiceKindAngekommenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling kindAngekommen$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling kindAngekommen$.');
        }
        const jaxKindAngekommenEvent = requestParameters.jaxKindAngekommenEvent;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/angekommen/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKindCheckInEvent>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKindAngekommenEvent,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public kindPausiert$(requestParameters: CheckInEventServiceKindPausiertRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxKindCheckInEvent>;
    public kindPausiert$(requestParameters: CheckInEventServiceKindPausiertRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxKindCheckInEvent>>;
    public kindPausiert$(requestParameters: CheckInEventServiceKindPausiertRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxKindCheckInEvent>>;
    public kindPausiert$(requestParameters: CheckInEventServiceKindPausiertRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindId = requestParameters.kindId;
        if (kindId === null || kindId === undefined) {
            throw new Error('Required parameter kindId was null or undefined when calling kindPausiert$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling kindPausiert$.');
        }
        const jaxKindPausiertEvent = requestParameters.jaxKindPausiertEvent;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/pausiert/kinderort/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/kind/${this.configuration.encodeParam({name: "kindId", value: kindId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}`;
        return this.httpClient.request<JaxKindCheckInEvent>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxKindPausiertEvent,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public restoreEvent$(requestParameters: CheckInEventServiceRestoreEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public restoreEvent$(requestParameters: CheckInEventServiceRestoreEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public restoreEvent$(requestParameters: CheckInEventServiceRestoreEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public restoreEvent$(requestParameters: CheckInEventServiceRestoreEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindCheckInEventId = requestParameters.kindCheckInEventId;
        if (kindCheckInEventId === null || kindCheckInEventId === undefined) {
            throw new Error('Required parameter kindCheckInEventId was null or undefined when calling restoreEvent$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/${this.configuration.encodeParam({name: "kindCheckInEventId", value: kindCheckInEventId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/restore`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public updateEvent$(requestParameters: CheckInEventServiceUpdateEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateEvent$(requestParameters: CheckInEventServiceUpdateEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateEvent$(requestParameters: CheckInEventServiceUpdateEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateEvent$(requestParameters: CheckInEventServiceUpdateEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kindCheckInEventId = requestParameters.kindCheckInEventId;
        if (kindCheckInEventId === null || kindCheckInEventId === undefined) {
            throw new Error('Required parameter kindCheckInEventId was null or undefined when calling updateEvent$.');
        }
        const update = requestParameters.update;
        if (update === null || update === undefined) {
            throw new Error('Required parameter update was null or undefined when calling updateEvent$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/checkin/event/${this.configuration.encodeParam({name: "kindCheckInEventId", value: kindCheckInEventId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: undefined})}/update${this.configuration.encodeParam({name: "update", value: update, in: "path", style: "matrix", explode: true, dataType: "CheckInEventResourceUpdateEventUpdateParameter", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

}
