<div class="dvb-firma-kontingent-value-content">
    <form name="editFirmenKontingentValueForm" ng-submit="vm.onSubmit(editFirmenKontingentValueForm)" novalidate>
        <div class="history-columns">

            <p class="history-column-left">
                <dvb-firma-kontingent-value-title firmen-kontingent-value="vm.firmenKontingentValue">
                </dvb-firma-kontingent-value-title>
            </p>

            <div class="history-column-middle">
                <div ng-if="!vm.editMode">
                    <div>
                        <div class="row form-row">
                            <div class="col-md-10">
                                <p data-translate="COMMON.FIRMENPLATZ.MF"
                                   data-translate-value-count="{{vm.firmenKontingentValue.plaetze}}"
                                   data-translate-value-showcount="true"
                                   data-translate-interpolation="messageformat"></p>
                            </div>
                        </div>

                        <div ng-show="vm.firmenKontingentValue.kitas && vm.firmenKontingentValue.kitas.length > 0">
                            <div class="row" ng-repeat="kita in vm.firmenKontingentValue.kitas | orderBy:'name'">
                                <div class="col-md-10">
                                    <p ng-bind="kita.getDisplayName()"></p>
                                </div>
                            </div>
                        </div>

                        <div class="row normal-abstand-top"
                             dvb-auth
                             require-permission="kita:control:general"
                             require-condition="vm.firmenKontingentValue.tarif">
                            <div class="col-md-10">
                                <p data-translate="FIRMA.TITLE_TARIF"
                                   data-translate-value-tarifname="{{vm.firmenKontingentValue.tarif.name}}"></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div ng-if="vm.editMode">
                    <dvb-firma-kontingent-value-form firmen-kontingent-value="vm.editFirmenKontingentValue">
                    </dvb-firma-kontingent-value-form>
                </div>
            </div>
            <div class="history-column-right">
                <!-- SPACER -->
            </div>
        </div>

        <div ng-if="vm.editMode">
            <ul class="list-inline normal-abstand-top">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="COMMON.SPEICHERN"></span>
                    </dvb-loading-button>
                </li>
                <li>
                    <a href="" ng-click="vm.editMode = false"><span
                        data-translate="COMMON.ABBRECHEN"></span></a>
                </li>
            </ul>
        </div>
    </form>

    <div dvb-auth require-permission="kita:betreuung_admin:*" require-condition="!vm.editMode">
        <ul class="list-inline normal-abstand-top">
            <li>
                <a href="" ng-click="vm.edit()">
                    <span data-translate="COMMON.BEARBEITEN"></span>
                </a>
            </li>
            <li>
                <a href="" ng-click="vm.deleteFirmenKontingentValue(firmenKontingentValue)">
                    <span data-translate="COMMON.LOESCHEN"></span>
                </a>
            </li>
        </ul>
    </div>
</div>
