/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ServiceContainer} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil, isPresent, TimeRangeUtil} from '@dv/shared/code';
import moment from 'moment';
import type {Badge} from '../../calendar/timeline/model/Badge';
import type {Ausbildung} from '../anstellung/models/Ausbildung';
import type {KinderOrtTimeRangeBedarf} from '../bedarf/models/KinderOrtTimeRangeBedarf';
import type {AusbildungsPersonalBedarf} from '../betreuungs-schluessel/models/AusbildungsPersonalBedarf';
import {TimeLineCssBadgeClass} from '../model/BedarfCssClasses';

export type TopLevelBedarf = {
    assigned: number;
    required: number;
};

export type BedarfByAusbildung = {
    ausbildungId: string;
    ausbildungName: string;
    assigned: number;
    required: number;
    missing: number;
};

export class PersonalZeitraumUtil {

    /**
     * Creates an array of Badge objects from a given KinderOrtTimeRangeBedarf object, filtered by a given fraktion.
     * @param bedarf - The KinderOrtTimeRangeBedarf object to create badges from.
     * @param fraktionId - The fraktion id to filter the bedarf by.
     * @param ausbildungen - An array of Ausbildung objects.
     * @param ausbildungenById - An object containing Ausbildung objects indexed by their IDs.
     * @returns An array of Badge objects.
     */
    public static getInfoBadgesFromTimeRangeBedarf(
        bedarf: KinderOrtTimeRangeBedarf,
        fraktionId: EntityId | undefined,
        ausbildungen: Ausbildung[],
        ausbildungenById: { [p: string]: Persisted<Ausbildung> },
    ): Badge[] {
        const isFraktionsBedarf = fraktionId !== undefined;
        const dailyBedarf = isFraktionsBedarf ? bedarf.fraktionsBedarf
            .filter(fraktionsBedarf => fraktionsBedarf.fraktionsId === fraktionId)
            .flatMap(fraktionsBedarf => fraktionsBedarf.dailyBedarf) : bedarf.dailyBedarf;

        return dailyBedarf.map(timeRangeBedarf => {
            const matchingBedarf = bedarf.dailyBedarf.filter(b => b.date.isSame(timeRangeBedarf.date)
                && TimeRangeUtil.isOverlapping(b.timeRange, timeRangeBedarf.timeRange));
            const hasKinderOrtRegel = matchingBedarf.some(b => b.fromKinderOrtRegel);
            const isOverwritten = (hasKinderOrtRegel && isFraktionsBedarf) || (!hasKinderOrtRegel
                && !isFraktionsBedarf);

            const topLevelBedarf = this.getTopLevelBedarf(timeRangeBedarf.ausbildungsBedarf);

            const bedarfByAusbildung = this.getBedarfByAusbildung(
                timeRangeBedarf.ausbildungsBedarf,
                ausbildungen,
                ausbildungenById);

            return {
                tooltip: this.getTooltipAddition(
                    timeRangeBedarf.maxConcurrentChildCount,
                    timeRangeBedarf.maxBelegtePlaetze,
                    bedarfByAusbildung,
                ),
                cssClass: `badge-bedarf ${this.getBadgeCssClass(
                    topLevelBedarf,
                    bedarfByAusbildung,
                    isOverwritten,
                )}`,
                value: `${topLevelBedarf.assigned}/${topLevelBedarf.required}`,
                date: DvbDateUtil.setTime(
                    moment(timeRangeBedarf.date),
                    timeRangeBedarf.timeRange.von ?? DvbDateUtil.today(),
                ),
            };
        });
    }

    private static getTopLevelBedarf(ausbildungsBedarf: AusbildungsPersonalBedarf[]): TopLevelBedarf {
        let bedarfSum = 0;
        const assigned: Set<string> = new Set<string>();
        let assignedSum = 0;

        ausbildungsBedarf.forEach(bedarf => {
            bedarfSum += bedarf.bedarfCount ?? 0;
            Object.keys(bedarf.assignedAngestellte)
                .filter(angestellteId => !assigned.has(angestellteId))
                .forEach(angestellteId => {
                    assigned.add(angestellteId);
                    assignedSum += bedarf.assignedAngestellte[angestellteId];
                });
        });

        return {required: bedarfSum, assigned: assignedSum};
    }

    private static getBadgeCssClass(
        topLevelBedarf: TopLevelBedarf,
        ausbildungenBedarf: BedarfByAusbildung[],
        isOverwritten: boolean,
    ): string {
        if (isOverwritten) {
            return TimeLineCssBadgeClass.BEDARF_OVERWRITTEN;
        }

        if (topLevelBedarf.assigned > topLevelBedarf.required) {
            return TimeLineCssBadgeClass.BEDARF_SURPLUS;
        }

        if (topLevelBedarf.assigned < topLevelBedarf.required || ausbildungenBedarf.some(a => a.missing > 0)) {
            return TimeLineCssBadgeClass.BEDARF_DEFICIT;
        }

        if (topLevelBedarf.assigned === 0 && topLevelBedarf.required === 0) {
            return '';
        }

        return TimeLineCssBadgeClass.BEDARF_OK;
    }

    /**
     * This function alters the dienstBedarfs assigned count while calculating the covering of required ausbildungen.
     * Meaning it should only be called once all other logic related to the count of assigned employees has been
     * executed.
     */
    private static getBedarfByAusbildung(
        rangeAusbildungsBedarf: AusbildungsPersonalBedarf[],
        ausbildungen: Ausbildung[],
        ausbildungenById: { [p: string]: Persisted<Ausbildung> },
    ): BedarfByAusbildung[] {

        const sortedBedarf: AusbildungsPersonalBedarf[] = [];
        ausbildungen.forEach(ausbildung => {
            this.ausbildungPostOrderSort(ausbildung, rangeAusbildungsBedarf, sortedBedarf);
        });

        const consideredAngestellte: string[] = [];
        const bedarfByAusbildung: BedarfByAusbildung[] = sortedBedarf.map(bedarf => {
            if (!bedarf.bedarfCount || bedarf.bedarfCount <= 0) {
                // assigned employees but no specific bedarf
                return null;
            }

            const ausbildungsInfo = {
                remainingBedarf: bedarf.bedarfCount,
                ausbildungsId: bedarf.ausbildungsId!,
                assignedAngestellte: [],
            };
            const bedarfNotCovered = this.findAssigned(
                ausbildungsInfo,
                bedarf,
                rangeAusbildungsBedarf,
                ausbildungenById,
                consideredAngestellte);
            consideredAngestellte.push(...ausbildungsInfo.assignedAngestellte);

            return {
                ausbildungId: bedarf.ausbildungsId!,
                ausbildungName: bedarf.ausbildungsName ?? '',
                required: bedarf.bedarfCount,
                assigned: bedarf.bedarfCount - bedarfNotCovered,
                missing: bedarfNotCovered,
            };
        })
            .filter(isPresent);

        this.addLeftoverZugewiesene(bedarfByAusbildung, rangeAusbildungsBedarf, consideredAngestellte);

        return bedarfByAusbildung;
    }

    /**
     * Looks through the dienstBedarf and adds any assigned angestellte not part of consideredAngestellte to
     * bedarfByAusbildung.
     *
     * Used to display angestellte that are assigned but not required to cover the bedarf.
     */
    private static addLeftoverZugewiesene(
        bedarfByAusbildung: BedarfByAusbildung[],
        rangeAusbildungsBedarf: AusbildungsPersonalBedarf[],
        consideredAngestellte: string[],
    ): void {

        rangeAusbildungsBedarf.filter(bedarf => Object.keys(bedarf.assignedAngestellte).length > 0)
            .forEach(bedarf => {
                const leftoverAngestellte = Object.keys(bedarf.assignedAngestellte)
                    .filter(id => !consideredAngestellte.includes(id));

                if (leftoverAngestellte.length < 1) {
                    return;
                }

                consideredAngestellte.push(...leftoverAngestellte);
                const relevantBedarfByAusbildung = bedarfByAusbildung.find(value =>
                    value.ausbildungId === bedarf.ausbildungsId);

                if (relevantBedarfByAusbildung) {
                    relevantBedarfByAusbildung.assigned += leftoverAngestellte.length;
                } else {
                    bedarfByAusbildung.push({
                        ausbildungId: bedarf.ausbildungsId!,
                        ausbildungName: bedarf.ausbildungsName ?? '',
                        required: 0,
                        assigned: leftoverAngestellte.length,
                        missing: 0,
                    });
                }
            });
    }

    /**
     * Subtracts assigned employees from the given dienstBedarf based on the given bedarfCount and ausbildungsBedarf.
     *
     * @param ausbildungsInfo information regarding the ausbildung for which we are covering the bedarf
     * @param ausbildungsBedarf the ausbildungsBedarf of the ausbidung in ausbildungsInfo, or the bedarf of one of its
     *     children that could be used to cover it
     * @param rangeAusbildungsBedarf contains all the bedarf definitions of the current time-range
     * @param ausbildungenById all available ausbildungen mapped by id, used for more efficient access based on ID
     * @param consideredAngestellte all Angestellte that have been assigned once previously
     * @return the amount of required but not assigned employees.
     */
    private static findAssigned(
        ausbildungsInfo: { remainingBedarf: number; ausbildungsId: string; assignedAngestellte: string[] },
        ausbildungsBedarf: AusbildungsPersonalBedarf,
        rangeAusbildungsBedarf: AusbildungsPersonalBedarf[],
        ausbildungenById: { [id: string]: Persisted<Ausbildung> },
        consideredAngestellte: string[],
    ): number {

        // cover bedarf with assigned employes of equal ausbildung
        ausbildungsInfo.remainingBedarf =
            this.subtractAssigned(ausbildungsBedarf, ausbildungsInfo, consideredAngestellte);
        if (ausbildungsInfo.remainingBedarf <= 0) {
            return 0;
        }

        // cover remaining bedarf with employees of an ausbildung that is a child of the ausbildungsBedarfs ausbildung
        const childAusbildungBedarfList = this.findChildAusbildungsBedarf(
            ausbildungsBedarf,
            rangeAusbildungsBedarf,
            ausbildungenById);

        if (!DvbUtil.isNotEmptyArray(childAusbildungBedarfList)) {
            // no children that could cover the bedarf
            return ausbildungsInfo.remainingBedarf;
        }

        childAusbildungBedarfList.forEach(childAusbildungsBedarf => {
            ausbildungsInfo.remainingBedarf =
                this.findAssigned(ausbildungsInfo,
                    childAusbildungsBedarf,
                    rangeAusbildungsBedarf,
                    ausbildungenById,
                    consideredAngestellte);
            if (ausbildungsInfo.remainingBedarf <= 0) {
                return;
            }
        });

        return ausbildungsInfo.remainingBedarf;
    }

    private static subtractAssigned(
        ausbildungsBedarf: AusbildungsPersonalBedarf,
        ausbildungsInfo: { remainingBedarf: number; ausbildungsId: string; assignedAngestellte: string[] },
        consideredAngestellte: string[],
    ): number {

        // Make sure that a single angestellte is used at most once for the bedarf of an anstellung, even if the
        // angestellte has multiple of the ausbildungs child ausbildungen
        // --> check that the angestellte is not already part of assignedAngestellte or consideredAngestellte
        // (in case there was no bedarf for given ausbildung) and update assignedAngestellte
        // with the angestellte used

        const usedAngestellte = Object.keys(ausbildungsBedarf.assignedAngestellte)
            .filter(id => !ausbildungsInfo.assignedAngestellte.includes(id))
            .filter(id => !consideredAngestellte.includes(id))
            .slice(0, ausbildungsInfo.remainingBedarf);

        const remainingBedarf = Math.max(0, ausbildungsInfo.remainingBedarf - usedAngestellte.length);

        ausbildungsInfo.assignedAngestellte = ausbildungsInfo.assignedAngestellte.concat(usedAngestellte);

        Object.keys(ausbildungsBedarf.assignedAngestellte)
            .filter(assigned => !usedAngestellte.includes(assigned))
            .forEach(a => {
                ausbildungsBedarf.assignedAngestellte[a] = 1;
            });

        return remainingBedarf;
    }

    /**
     * Looks for an AusbildungsBedarf in dienstBedarf that, by its ausbildung, is a child of the given
     * ausbildungsBedarfs ausbildung.
     * If no bedarf found in children, go look recursively for children of the children,
     * until the tree is exhausted or a match is found.
     */
    private static findChildAusbildungsBedarf(
        ausbildungsBedarf: AusbildungsPersonalBedarf,
        rangeAusbildungsBedarf: AusbildungsPersonalBedarf[],
        ausbildungenById: { [id: string]: Persisted<Ausbildung> },
    ): AusbildungsPersonalBedarf[] {

        if (!DvbUtil.isNotEmptyArray(ausbildungenById[ausbildungsBedarf.ausbildungsId!].children)) {
            // no children, we reached a leaf
            return [];
        }

        return ausbildungenById[ausbildungsBedarf.ausbildungsId!].children
            .map(child => this.recursiveFind(rangeAusbildungsBedarf, child, ausbildungenById))
            .filter(isPresent);
    }

    private static recursiveFind(
        rangeAusbildungsBedarf: AusbildungsPersonalBedarf[],
        ausbildung: Ausbildung,
        ausbildungenById: { [id: string]: Persisted<Ausbildung> },
    ): AusbildungsPersonalBedarf | undefined {
        const foundMatchingBedarf = rangeAusbildungsBedarf.find(ab =>
            ab.ausbildungsId === ausbildung.id);

        if (foundMatchingBedarf) {
            return foundMatchingBedarf;
        }

        if (!DvbUtil.isNotEmptyArray(ausbildung.children)) {
            // no children, we reached a leaf
            return;
        }

        return ausbildung.children
            .map(child => this.recursiveFind(rangeAusbildungsBedarf, child, ausbildungenById))
            .find(isPresent);
    }

    /**
     * We want to traverse the ausbildungs bedarf in depth first post-order in relation to associated ausbildungen.
     *
     * For the reasoning, consider this example:
     *
     * - Ausbildung A with children AA and AB.
     * - 1 required employee of ausbildung A, one required employee of ausbildung AA
     * - 1 assigned employee with ausbildung AA, 1 assigned with AB
     *
     * If we cover the requirement of A with the employee of ausbildung AA, the requirement for AA is no longer met.
     * Yet employee with AB is not used.
     * Depth first post-order guarantees that we consider employees for the bedarf of their "closest" ausbildung first,
     * resolving this issue.
     */
    private static ausbildungPostOrderSort(
        node: Ausbildung,
        bedarf: AusbildungsPersonalBedarf[],
        sortedBedarf: AusbildungsPersonalBedarf[],
    ): void {
        if (DvbUtil.isNotEmptyArray(node.children)) {
            node.children.forEach(child => {
                this.ausbildungPostOrderSort(child, bedarf, sortedBedarf);
            });
        }

        const associatedBedarf = bedarf.find(ab => ab.ausbildungsId === node.id);
        if (associatedBedarf) {
            sortedBedarf.push(associatedBedarf);
        }
    }

    private static getTooltipAddition(
        childCount: number | null,
        belegtePlaetze: number | null,
        bedarfByAusbildung: BedarfByAusbildung[],
    ): string {
        const lines = bedarfByAusbildung
            .map(a => ServiceContainer.$translate.instant('PERSONAL.AUSBILDUNGEN.REQUIRED', a));

        const kindCount = ServiceContainer.$translate.instant('PERSONAL.BETREUUNGS_SCHLUESSEL.CHILD_COUNTS_MF',
            {children: childCount, plaetze: belegtePlaetze},
            'messageformat');
        lines.push(kindCount);

        return lines.join('\n');
    }
}
