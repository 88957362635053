<div class="dvb-maintenance-timeout-test">

    <div>
        <h2 data-translate="ADMINISTRATION.TIMEOUT_TESTING.TITLE"></h2>
    </div>

    <form name="timeoutForm" ng-submit="vm.testTimeout(timeoutForm)" novalidate>
        <div class="row">
            <div class="col-xs-4">
                <input type="number"
                       min="0"
                       max="720"
                       class="form-control"
                       ng-model="vm.seconds"
                       required
                       ng-disabled="vm.isLoading"
                       ng-attr-placeholder="{{'ADMINISTRATION.TIMEOUT_TESTING.SERVER_REQUEST_DURATION' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.TIMEOUT_TESTING.SERVER_REQUEST_DURATION' | translate}}">
            </div>
        </div>

        <div class="radio">
            <label>
                <input type="radio"
                       value="PROMISE"
                       ng-model="vm.timeoutType"
                       ng-disabled="vm.isLoading">
                <span data-translate="ADMINISTRATION.TIMEOUT_TESTING.PROMISE"></span>
            </label>
            <label>
                <input type="radio"
                       value="SECONDS"
                       ng-model="vm.timeoutType"
                       ng-disabled="vm.isLoading">
                <span data-translate="ADMINISTRATION.TIMEOUT_TESTING.SEKUNDEN"></span>
            </label>
        </div>

        <div class="row" ng-if="vm.timeoutType === 'SECONDS'">
            <div class="col-xs-4">
                <input type="number"
                       min="0"
                       max="720"
                       class="form-control"
                       ng-model="vm.timeoutSeconds"
                       ng-disabled="vm.isLoading"
                       ng-attr-placeholder="{{'ADMINISTRATION.TIMEOUT_TESTING.CLIENT_REQUEST_TIMEOUT' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.TIMEOUT_TESTING.CLIENT_REQUEST_TIMEOUT' | translate}}">
            </div>
        </div>

        <dvb-loading-button type="submit"
                            is-loading="vm.isLoading"
                            class="normal-abstand-top">
            <span data-translate="ADMINISTRATION.TIMEOUT_TESTING.REQUEST_START"></span>
        </dvb-loading-button>

        <button class="btn btn-primary normal-abstand-top"
                type="button"
                ng-click="vm.abortRequest()"
                ng-show="vm.isLoading && vm.timeoutType === 'PROMISE'"
                data-translate="ADMINISTRATION.TIMEOUT_TESTING.REQUEST_ABORT">
        </button>
    </form>
</div>
