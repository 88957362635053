<div class="row">
    <div class="col-md-12">
        <strong ng-bind="vm.notiz.subject"></strong>
    </div>
</div>

<dvb-collapsible-panel compact="true">
    <panel-header>
        <div class="truncate" ng-bind="vm.notiz.content"></div>
    </panel-header>
    <panel-content>
        <div class="nl2br" ng-bind="vm.notiz.content"></div>

        <ul class="list-inline normal-abstand-top"
            dvb-auth
            require-permission="{{vm.notizEditState.data.permission}}">
            <li>
                <a data-translate="COMMON.BEARBEITEN"
                   ui-sref="{{vm.notizEditState.name + '({notizId: vm.notiz.id})'}}">
                </a>
            </li>
            <li>
                <button class="btn btn-link"
                        data-translate="COMMON.LOESCHEN"
                        ng-click="vm.confirmDeleteNotiz(vm.notiz)">
                </button>
            </li>
        </ul>
    </panel-content>
</dvb-collapsible-panel>
