/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {Observable} from 'rxjs';
import {distinctUntilChanged, ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PrivacyPolicyService {

    private readonly privacyPolicyAccepted$;

    public constructor() {
        this.privacyPolicyAccepted$ = new ReplaySubject<boolean>(1);
    }

    public acceptPrivacyPolicy(): void {
        this.privacyPolicyAccepted$.next(true);
    }

    public setPolicyAccepted(accepted: boolean): void {
        this.privacyPolicyAccepted$.next(accepted);
    }

    /**
     * @return an observable of whether the user has accepted the privacy policy.
     */
    public isPolicyAccepted$(): Observable<boolean> {
        return this.privacyPolicyAccepted$.pipe(
            distinctUntilChanged(),
        );
    }
}
