/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {CustomField} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent, NamedEntityType} from '@dv/shared/code';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {CustomFieldService} from '../../../common/service/rest/customFieldService';
import {CustomFieldConfiguration} from '../../models';
import type {CustomFieldConfigurationService} from '../../service/customFieldConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    template: require('./dvb-custom-field-configuration.html'),
    controllerAs: 'vm',
};

type SelectableCustomField = CustomField & { selected?: boolean };

export class DvbCustomFieldConfiguration implements angular.IController {
    public static $inject: readonly string[] = [
        'errorService',
        'customFieldConfigurationService',
        'customFieldService',
        '$translate',
        'dialogService',
    ];

    public customFieldConfigurations: CustomFieldConfiguration[] = [];

    public customFieldConfiguration: CustomFieldConfiguration = new CustomFieldConfiguration();
    public customFieldsByNamedEntityType: { [key in NamedEntityType]?: SelectableCustomField[] } = {};
    public usedNamedEntities: NamedEntityType[] = [NamedEntityType.KIND, NamedEntityType.BELEGUNG];

    public isLoading: boolean = false;
    public submitCustomFieldConfigurationForm!: angular.IFormController;

    public constructor(
        private errorService: ErrorService,
        private customFieldConfigurationService: CustomFieldConfigurationService,
        private customFieldService: CustomFieldService,
        private $translate: angular.translate.ITranslateService,
        private dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        this.customFieldConfigurationService.getAll()
            .then(customFieldConfigurations => {
                this.customFieldConfigurations = customFieldConfigurations;
            });
        this.usedNamedEntities.forEach(type => {
            this.customFieldService.getAll(type).then(customFields => {
                this.customFieldsByNamedEntityType[type] = customFields;
            });
        });
    }

    public submit(): void {
        const config = checkPresent(this.customFieldConfiguration);

        this.updateCustomFieldsOnCustomFieldConfiguration();

        const valid = config.isValid();

        this.errorService.clearAll();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        this.isLoading = true;
        this.customFieldConfigurationService.create(config)
            .then(() => this.clearAndReload())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public delete(configuration: CustomFieldConfiguration): void {
        const entityText = this.$translate.instant(
            'REPORT.CUSTOM_FIELD_CONFIGURATION.KONFIGURATION_NAME',
            {name: configuration.name});
        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(this.customFieldConfigurationService.delete(configuration))
                .pipe(take(1), tap(() => this.clearAndReload())),
        });

    }

    private clearAndReload(): void {
        this.errorService.clearAll();
        this.customFieldConfiguration = new CustomFieldConfiguration();

        // reset form
        this.submitCustomFieldConfigurationForm.$setPristine();
        this.submitCustomFieldConfigurationForm.$setUntouched();

        // reload Configurations
        this.customFieldConfigurationService.getAll()
            .then(customFieldConfigurations => {
                this.customFieldConfigurations = customFieldConfigurations;
            });
    }

    private updateCustomFieldsOnCustomFieldConfiguration(): void {
        const customFields: CustomField[] = [];
        this.usedNamedEntities.forEach(namedEntity => {
            checkPresent(this.customFieldsByNamedEntityType[namedEntity]).forEach(customField => {
                if (customField.selected) {
                    customFields.push(customField as CustomField);
                }
            });
        });

        this.customFieldConfiguration.customFieldConfigEntries = customFields;
    }
}

componentConfig.controller = DvbCustomFieldConfiguration;
angular.module('kitAdmin').component('dvbCustomFieldConfiguration', componentConfig);
