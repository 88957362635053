<div class="dvb-kita-betreuungsfaktor-regel-heading">
    <div class="history-columns">
        <div class="history-column-left">
            <p>
                <dvb-gueltigkeit entity="vm.kitaBetreuungsfaktorRegel"></dvb-gueltigkeit>
            </p>
        </div>

        <div class="history-column-middle">
            <p ng-bind="vm.kitaBetreuungsfaktorRegel.regel.name"></p>
        </div>

    </div>
</div>
