/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AggragatedEntitySearchTypes, IPersistable, SearchResultEntry} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {MandantSearchFilter} from '../../model/MandantSearchFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entityToSearch: '@',
        onSelectClear: '<',
        placeholderKey: '@',
        addLabelKey: '@',
        selectEntity: '&onSelect',
        onToggle: '&',
        expandEntity: '<?',
        filterSource: '&?',
        disabledEntries: '<?',
        mandantFilter: '<?',
        hideAdd: '<?',
    },
    template: require('./dvb-search-on-click-entity.html'),
    controllerAs: 'vm',
};

export class DvbSearchOnClickEntity implements angular.IController {

    public static $inject: readonly string[] = ['$document', '$timeout', '$element', '$scope'];

    public entityToSearch!: AggragatedEntitySearchTypes;
    public onSelectClear!: boolean | string;
    public placeholderKey!: string;
    public addLabelKey!: string;
    public selectEntity!: (props: { item: SearchResultEntry }) => unknown;
    public onToggle!: string;
    public expandEntity?: boolean;
    public filterSource?: (props: { $source: any }) => boolean;
    public disabledEntries?: IPersistable[];
    public mandantFilter?: MandantSearchFilter;
    public hideAdd?: boolean;

    public isShown: boolean = false;
    public textField?: HTMLTextAreaElement = undefined;

    private clickListener?: (event: Event) => void;

    public constructor(
        private readonly $document: angular.IDocumentService,
        private readonly $timeout: angular.ITimeoutService,
        private readonly $element: JQLite,
        private readonly $scope: angular.IScope,
    ) {
    }

    public $onInit(): void {
        if (typeof this.onSelectClear === 'string') {
            this.onSelectClear = this.$scope.$eval(this.onSelectClear);
        }

        this.$element.on('keydown', 'input', event => {
            // Reset on ESC, Tab or accesskey shortcut
            if (event.key === 'Escape' || event.key === 'Tab' || (event.altKey && event.shiftKey)) {
                this.hide();
            }
        });
    }

    public $onDestroy(): void {
        this.removeEventListeners();
    }

    public addItem($item: SearchResultEntry): void {
        if (!$item) {
            return;
        }
        if (TypeUtil.isFunction(this.selectEntity)) {
            this.selectEntity({item: $item});
        }
        this.hide();
    }

    public show(): void {
        this.isShown = true;

        if (TypeUtil.isFunction(this.onToggle)) {
            this.onToggle({searchShown: true});
        }

        // add the listener in the next cycle, otherwise it will be triggered immediately and thus hide the
        // input directly
        this.$timeout(() => {
            // add click handler
            this.clickListener = e => this.clickHandler(e);
            this.$document.get()[0].addEventListener('click', this.clickListener);
        });
    }

    public hide(): void {
        this.$timeout(() => {
            this.isShown = false;

            if (TypeUtil.isFunction(this.onToggle)) {
                this.onToggle({searchShown: false});
            }

            this.removeEventListeners();
        });
    }

    private clickHandler(event: Event): void {
        if (this.$element.get(0)!.contains(event.target as unknown as Node)) {
            // target is descendant, don't do anything
            return;
        }

        // target is not descendant, thus the user defocused the component -> hide it
        this.hide();
    }

    private removeEventListeners(): void {
        if (this.clickListener) {
            this.$document.get()[0].removeEventListener('click', this.clickListener);
        }
    }
}

componentConfig.controller = DvbSearchOnClickEntity;
angular.module('kitAdmin').component('dvbSearchOnClickEntity', componentConfig);
