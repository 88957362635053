/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        labelPart: 'labelPart',
        contentPart: 'contentPart',
    },
    bindings: {
        editMode: '<',
    },
    template: require('./dvb-click-2-edit.html'),
    controllerAs: 'vm',
};

export class DvbClick2Edit implements angular.IController {
    public static $inject: readonly string[] = [];

    public editMode!: boolean;
}

componentConfig.controller = DvbClick2Edit;
angular.module('kitAdmin').component('dvbClick2Edit', componentConfig);
