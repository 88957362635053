<div class="dvb-kita-tarife-verlauf-header">
    <div class="history-columns">
        <div class="history-column-left">
            <p>
                <dvb-gueltigkeit entity="vm.kitaTarifeZeitraum"></dvb-gueltigkeit>
            </p>
        </div>

        <div class="history-column-middle">
            <p ng-bind="vm.getTypList()"></p>
        </div>
    </div>
</div>
