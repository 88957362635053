/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {LimitedAdresse, Umzug} from '@dv/kitadmin/models';
import {Mitbewohner, Mitzuegler, Wohnadresse} from '@dv/kitadmin/models';
import type {Adresse, RestInclude, RestLimited, IsoObject} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, hasOwnPropertyGuarded} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class AddressService {
    public static $inject: readonly string[] = ['$http', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/adressen`;
    private static readonly BASE_GEOADMIN_URL: string = `${CONFIG.restBackend}/api/v1/adressen/geoadmin`;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly $q: angular.IQService,
    ) {
    }

    public korrigiereAdresse(adresse: Adresse): angular.IPromise<unknown> {
        const url = `${AddressService.BASE_URL}/${encodeURIComponent(checkPresent(adresse.id))}`;

        return this.$http.put(url, adresse.toRestObject());
    }

    public zuegeln(umzug: Umzug): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(AddressService.BASE_URL, umzug.toRestObject());
    }

    public updateAdresse(kontaktpersonId: string, address: LimitedAdresse): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${AddressService.BASE_URL}/kontaktperson/${encodeURIComponent(kontaktpersonId)}/`;

        return this.$http.post(url, address.toRestObject());
    }

    public getKindMitzuegler(kindId: string, datum: moment.Moment): angular.IPromise<Mitzuegler> {
        const url = `${AddressService.BASE_URL}/kind/${encodeURIComponent(kindId)}/` +
            `mitzuegler/${DvbRestUtil.momentToLocalDate(datum)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Mitzuegler).then(checkPresent);
    }

    public getKontaktpersonMitzuegler(
        kontaktpersonId: string,
        datum: moment.Moment,
    ): angular.IPromise<Mitzuegler> {
        const url = `${AddressService.BASE_URL}/kontaktperson/${encodeURIComponent(kontaktpersonId)}/` +
            `mitzuegler/${DvbRestUtil.momentToLocalDate(datum)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Mitzuegler).then(checkPresent);
    }

    public getMitbewohner(adresseId: string, params?: RestInclude & RestLimited): angular.IPromise<Mitbewohner> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const url = `${AddressService.BASE_URL}/${encodeURIComponent(adresseId)}/mitbewohner`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Mitbewohner, matrixParams).then(checkPresent);
    }

    public isSwitzerland(country: IsoObject): boolean {
        return country && hasOwnPropertyGuarded(country, 'isoCode') && country.isoCode === 'CH';
    }

    /**
     * fuzzy = true heisst Adressfeld(er) passen nicht exakt, z.B.:
     * - Tippfehler Sonenweg statt Sonnenweg
     * - Abkürzung von Strassennamen : Nussbaumstr statt Nussbaumstrasse (Backend liefert hier Nussbaumstrasse)
     */
    public searchWohnadressen(
        adresse: Adresse,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Wohnadresse[]> {
        if (!this.isAdresseSearchable(adresse)) {
            return this.$q.reject(new Error('Adresse does not fulfill search criteria'));
        }

        const url = `${AddressService.BASE_GEOADMIN_URL}/wohnadressen/${encodeURIComponent(this.searchText(adresse))}`;

        return DvbRestUtilAngularJS.getModelsArray(url, Wohnadresse, 'wohnadressen', {}, true, config);
    }

    public isAdresseSearchable(adresse: Adresse): boolean {
        if (!adresse.land || adresse.land.isoCode !== 'CH') {
            return false;
        }

        if (!this.isStringGreaterThan(adresse.strasse, 2)) {
            return false;
        }

        const plzLength = 3;
        const ortLength = 1;

        return this.isStringGreaterThan(adresse.plz, plzLength) || this.isStringGreaterThan(adresse.ort, ortLength);
    }

    public isStringGreaterThan(val: string | null, length: number): boolean {
        return angular.isString(val) && val.length > length;
    }

    public searchText(adresse: Adresse): string {
        const searchProperties = [adresse.strasse, adresse.hausnummer, adresse.plz, adresse.ort];

        return searchProperties
            .map(prop => typeof prop === 'string' ? prop.trim() : '')
            .filter(Boolean)
            .join(' ');
    }
}
