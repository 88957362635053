/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type TerminWorkTimeType = 'WORK_TIME' | 'NO_WORK_TIME' | 'DEDUCT_FROM_PLANNED_TIME';

export const TerminWorkTimeType = {
    WORK_TIME: 'WORK_TIME' as TerminWorkTimeType,
    NO_WORK_TIME: 'NO_WORK_TIME' as TerminWorkTimeType,
    DEDUCT_FROM_PLANNED_TIME: 'DEDUCT_FROM_PLANNED_TIME' as TerminWorkTimeType
};

