/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil} from '@dv/shared/code';
import moment from 'moment';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {MonthlyControllingFigures} from '../../../../report/models/MonthlyControllingFigures';
import type {WeeklyControllingFigures} from '../../../../report/models/WeeklyControllingFigures';
import {ControllingReportComponent} from './ControllingReportComponent';

export abstract class ControllingReportMonthlyComponent extends ControllingReportComponent {

    public override $onChanges(): void {
        super.$onChanges();
        if (!this.controllingReport) {
            return;
        }

        this.updateMonthlyFigures(this.controllingReport);
    }

    protected updateMonthlyFigures(report: ControllingReport): void {
        if (!this.controllingReport) {
            this.resetMonthlyFigures();

            return;
        }

        const stichtag = this.firstOfWeek.year() === this.jahr ?
            this.firstOfWeek :
            DvbDateUtil.startOfYear(moment(this.firstOfWeek)).add(1, 'year');
        const currentMonthlyFigures = DvbDateUtil.getEntityOn(report.monthlyFigures, stichtag);

        if (!currentMonthlyFigures) {
            this.resetMonthlyFigures();

            return;
        }

        this.setMonthlyFigures(currentMonthlyFigures);
    }

    protected setWeeklyFigures(_weeklyFigures: WeeklyControllingFigures): void {
        // nop
    }

    protected resetWeeklyFigures(): void {
        // nop;
    }

    protected abstract setMonthlyFigures(monthlyFigures: MonthlyControllingFigures): void;

    protected abstract resetMonthlyFigures(): void;
}
