/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson, LimitedAdresse, Umzug} from '@dv/kitadmin/models';
import type {Adresse, LoadingContext} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateObject, StateService} from '@uirouter/core';
import angular from 'angular';
import type {AddressService} from '../../../common/service/rest/addressService';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
    },
    template: require('./dvb-kontaktperson-kontakt.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonKontakt implements angular.IController {

    public static $inject: readonly string[] = [
        'mandantConfigurationService',
        'kontaktpersonService',
        'addressService',
        '$state',
    ];

    public kontaktperson!: Kontaktperson;
    public showUmzug: boolean = false;
    public newLimitedAdresse: LimitedAdresse | null = null;

    public constructor(
        private readonly mandantConfigurationService: MandantConfigurationService,
        private readonly kontaktpersonService: KontaktpersonService,
        private readonly addressService: AddressService,
        private readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        if (this.kontaktperson.adressen.length !== 0) {
            return;
        }

        const adressDatum = DvbDateUtil.today();

        this.mandantConfigurationService.createDefaultLimitedAdresse(adressDatum)
            .then(adresse => {
                this.newLimitedAdresse = adresse;
            });
    }

    public addAddress(): void {
        this.addressService.updateAdresse(this.kontaktperson.id!, this.newLimitedAdresse!)
            .then(() => this.kontaktpersonService.get(this.kontaktperson.id!, {includes: '(adressen)'}))
            .then(updatedKontaktperson => {
                this.kontaktperson.adressen = updatedKontaktperson.adressen;
                this.newLimitedAdresse = null;
            });
    }

    public adresseKorrigieren(limitedAdresse: LimitedAdresse, newAdresse: Adresse): void {
        this.addressService.korrigiereAdresse(newAdresse).then(() => {
            limitedAdresse.adresse = newAdresse;
        });
    }

    public deleteLimitedAdresse(limitedAdresse: LimitedAdresse): angular.IPromise<StateObject> {
        return this.kontaktpersonService.deleteAdresse(this.kontaktperson.id!, limitedAdresse.id!)
            .then(() => this.$state.reload());
    }

    public saveUmzug(umzug: Umzug, context: LoadingContext): angular.IPromise<StateObject> {
        context.startLoading();

        return this.addressService.zuegeln(umzug)
            .then(() => {
                this.showUmzug = false;

                return this.$state.reload();
            })
            .finally(() => {
                context.finishLoading();
            });
    }

    public cancelUmzug(): void {
        this.showUmzug = false;
    }
}

componentConfig.controller = DvbKontaktpersonKontakt;
angular.module('kitAdmin').component('dvbKontaktpersonKontakt', componentConfig);
