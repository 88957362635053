/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ILimited, ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ReportState} from '../../models';
import {ERR_VALIDATION, ParameterGeburtstagslisteKinder} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
    },
    template: require('./dvb-geburtstage-report.html'),
    controllerAs: 'vm',
};

export class DvbGeburtstageReport implements angular.IController, ILimited {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public kinderOrt?: SearchResultEntry;

    public readonly geburtstageReportForm?: angular.IFormController;
    public parameterGeburtstagslisteKinder: ParameterGeburtstagslisteKinder = new ParameterGeburtstagslisteKinder();
    public readonly defaultStichtag: moment.Moment = DvbDateUtil.today();
    public readonly defaultGueltigAb: moment.Moment = DvbDateUtil.startOfMonth();
    public readonly defaultGueltigBis: moment.Moment = DvbDateUtil.endOfMonth();
    public gruppeSearchResultEntry?: SearchResultEntry;
    public reportTemplate: ReportTemplate | null = null;

    public stichtag: moment.Moment | null = null;

    public withDateRange: boolean = false;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateFraktion(): void {
        this.parameterGeburtstagslisteKinder.gruppeId =
            this.gruppeSearchResultEntry ? this.gruppeSearchResultEntry.id : null;
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        this.errorService.clearAll();
        this.parameterGeburtstagslisteKinder.kinderOrtId = this.kinderOrt ? this.kinderOrt.id : null;
        const isValid = this.geburtstageReportForm!.$valid && this.parameterGeburtstagslisteKinder.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_KITA_UND_GRUPPE');

        this.setDateRange();

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.reportService.getTempBlobForGeburtstagslisteKinderReport(
            this.parameterGeburtstagslisteKinder,
            {reportTemplate: this.reportTemplate},
        );
    }

    private setDateRange(): void {
        if (this.withDateRange) {
            this.parameterGeburtstagslisteKinder.gueltigAb = this.gueltigAb ?? this.defaultGueltigAb;
            this.parameterGeburtstagslisteKinder.gueltigBis = this.gueltigBis ?? this.defaultGueltigBis;
        } else {
            this.parameterGeburtstagslisteKinder.gueltigAb = this.stichtag ?? this.defaultStichtag;
            this.parameterGeburtstagslisteKinder.gueltigBis = null;
        }
    }
}

componentConfig.controller = DvbGeburtstageReport;
angular.module('kitAdmin').component('dvbGeburtstageReport', componentConfig);
