<div class="dvb-gruppe-stammdaten">
    <div class="title">
        <h2 data-translate="COMMON.PROFIL"></h2>
    </div>

    <div class="row small-abstand-bottom">
        <div class="col-md-12">
            <dvb-editable-label is-valid="vm.isValidName(param)"
                                maxlength="255"
                                ng-attr-placeholder="{{'FRAKTION.NAME' | translate}}"
                                ng-model="vm.gruppe.name"
                                ng-change="vm.saveBasisdaten()"
                                ng-disabled="!vm.administrateKinderOrt">
            </dvb-editable-label>
        </div>
        <div class="col-md-12">
            <select class="form-control select"
                    ng-class="{'interactable-select': vm.administrateKinderOrt}"
                    uib-tooltip="{{'BETREUUNGS_ANGEBOT.NAME' | translate}}"
                    tooltip-placement="top-left"
                    ng-disabled="!vm.administrateKinderOrt"
                    ng-change="vm.saveBasisdaten()"
                    ng-model="vm.gruppe.betreuungsAngebotType"
                    ng-options="type as ('BETREUUNGS_ANGEBOT.' + type | translate) for type in vm.betreuungsAngebotTypes">
                <option ng-value="null" data-translate="BETREUUNGS_ANGEBOT.NONE"></option>
            </select>
        </div>
    </div>

    <div class="row form-row">
        <div class="col-md-12">
            <a ui-sref="base.kinderort.profil({id: vm.gruppe.kita.id})"
               ng-bind="vm.gruppe.kita.getDisplayName()">
            </a>
        </div>
    </div>

    <dvb-kinderort-fraktionen kinder-ort="vm.gruppe.kita"
                              selected-fraktion="vm.gruppe">
    </dvb-kinderort-fraktionen>
</div>
