/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PlatzTyp} from '@dv/kitadmin/models';
import angular from 'angular';
import type {KinderOrtService} from '../../service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontingentId: '<',
    },
    template: require('./dvb-platz-typ-name.html'),
    controllerAs: 'vm',
};

export class DvbPlatzTypName implements angular.IController {
    public static $inject: readonly string[] = ['kinderOrtService', '$translate'];

    public kontingentId: PlatzTyp = null;

    public name?: string;

    public constructor(
        private readonly kinderOrtService: KinderOrtService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.kontingentId) {
            this.updateName(onChangesObj.kontingentId.currentValue);
        }
    }

    private updateName(id: PlatzTyp): void {
        if (id === null) {
            this.name = this.$translate.instant('COMMON.PRIVAT');

            return;
        }

        this.kinderOrtService.getKontingent(id, {cache: true, includes: '(firma)'})
            .then(kontingent => {
                if (kontingent.isFirmenKontingent()) {
                    const params = {name: kontingent.getDisplayName()};
                    this.name = this.$translate.instant('COMMON.FIRMENKONTINGENT.TITLE', params);
                } else {
                    this.name = this.$translate.instant('COMMON.SUBVENTIONIERT');
                }
            });
    }
}

componentConfig.controller = DvbPlatzTypName;
angular.module('kitAdmin').component('dvbPlatzTypName', componentConfig);
