/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {WritableSignal} from '@angular/core';
import type {KinderOrtFraktion} from '@dv/kitadmin/models';
import {KinderOrtFraktionTransformer} from '@dv/kitadmin/models';
import type {JaxFraktionZuweisungen} from '@dv/shared/backend/model/jax-fraktion-zuweisungen';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent} from '@dv/shared/code';
import type {Badge} from '../../calendar/timeline/model/Badge';
import type {CalendarGroup} from '../../calendar/timeline/model/CalendarGroup';
import type {CalendarResource} from '../../calendar/timeline/model/CalendarResource';
import type {Angestellte} from '../anstellung/models/Angestellte';
import {AngestellteZuweisungen} from './AngestellteZuweisungen';

export class FraktionZuweisungen implements CalendarGroup {

    public constructor(
        public fraktion: Persisted<KinderOrtFraktion>,
        public angestellteZuweisungen: AngestellteZuweisungen[] = [],
        public extendable: boolean = true,
        public sortable: boolean = false,
        public infoBadges?: WritableSignal<Badge[]>,
    ) {
    }

    public static apiResponseTransformer(
        data: JaxFraktionZuweisungen,
        angestellteMap: { [id: string]: Angestellte },
    ): FraktionZuweisungen {
        const fraktion = checkPersisted(KinderOrtFraktionTransformer.create().apiResponseTransformer(data.fraktion));
        const zuweisungen = data.angestellteZuweisungen.map(jax => {
            const angestellte = checkPresent(angestellteMap[jax.angestellteId]);

            return AngestellteZuweisungen.apiResponseTransformer(jax, angestellte);
        });

        return new FraktionZuweisungen(fraktion, zuweisungen, true);
    }

    public getDisplayName(): string {
        return this.fraktion.getDisplayName();
    }

    public get resources(): CalendarResource[] {
        return this.angestellteZuweisungen;
    }

    public get id(): string {
        return this.fraktion.id;
    }
}
