/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import {ConfidentialityLevel, Kind} from '@dv/kitadmin/models';
import type {FunctionType, SearchResultEntry} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        defaultAnmeldeDatum: '<',
        uneditableAdresse: '<?',
        geschwisterId: '@',
        onKindCreated: '&',
    },
    template: require('./dvb-anmeldung-kind.html'),
    controllerAs: 'vm',
};

export class DvbAnmeldungKind implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['kindService', 'mandantConfigurationService'];

    public defaultAnmeldeDatum!: moment.Moment;
    public geschwisterId!: string;
    public uneditableAdresse?: boolean;
    public onKindCreated!: FunctionType;

    public kind: Kind = new Kind();
    public wohnsitzWieHauptkontakt: boolean = true;

    public confLevelVertraulich: ConfidentialityLevel = ConfidentialityLevel.VERTRAULICH;
    public confLevelAllgemein: ConfidentialityLevel = ConfidentialityLevel.ALLGEMEIN;

    public constructor(
        private readonly kindService: KindService,
        private readonly mandantConfigurationService: MandantConfigurationService,
    ) {
    }

    public $onInit(): void {
        if (this.geschwisterId) {
            const params = {
                includes: '(kontakte.fields(kontaktperson.fields(adressen), relationship), geschwister)',
            };
            this.kindService.get(this.geschwisterId, params)
                .then(geschwister => this.reuseDataFromGeschwister(geschwister));
        }

        if (TypeUtil.isFunction(this.onKindCreated)) {
            this.onKindCreated({kind: this.kind});
        }
    }

    public addKontakt(k: RelationshipWithKontaktperson): void {
        this.kind.addErziehungsberechtigtForFirstTwoKontakte(k);
        this.kind.addKontakt(k);
    }

    public addGeschwister(kindItem: SearchResultEntry): void {
        if (this.hasGeschwister(kindItem.id)) {
            return;
        }

        const params = {includes: '(kontakte.fields(kontaktperson.fields(adressen), relationship), geschwister)'};
        this.kindService.get(kindItem.id, params).then(geschwister => {
            if (this.kind.geschwister.length === 0) {
                this.reuseDataFromGeschwister(geschwister);
            } else {
                this.kind.addGeschwister(geschwister);
            }
        });
    }

    public changeWohnsitz(): void {
        if (this.kind.adressen.length === 1) {
            this.kind.adressen.splice(0);
        } else {
            this.mandantConfigurationService.createDefaultLimitedAdresse(this.defaultAnmeldeDatum)
                .then(adresse => {
                    this.kind.adressen.push(adresse);
                });
        }
    }

    public updateAnhang(anhangId: string, confLvl: ConfidentialityLevel): void {
        const fromAnhaenge =
            ConfidentialityLevel.ALLGEMEIN === confLvl ? this.kind.anhaengeVertraulich : this.kind.anhaengeAllgemein;
        const toAnhaenge =
            ConfidentialityLevel.ALLGEMEIN === confLvl ? this.kind.anhaengeAllgemein : this.kind.anhaengeVertraulich;
        if (toAnhaenge.some(a => a.id === anhangId)) {
            return;
        }

        toAnhaenge.push(...fromAnhaenge.splice(fromAnhaenge.findIndex(a => a.id === anhangId), 1));
    }

    private reuseDataFromGeschwister(geschwister: Kind): void {
        this.kind.addGeschwister(geschwister);

        geschwister.geschwister.forEach(other => {
            this.kind.addGeschwister(other);
        });
        geschwister.kontakte.sort((a, b) => this.addKontakteOrder(a, b));
        geschwister.kontakte.forEach(k => this.addKontakt(k));

        // Copy the array to force angular to update
        this.kind.kontakte = this.kind.kontakte.slice(0);
    }

    private hasGeschwister(geschwisterId: string): boolean {
        const geschwister = this.kind.geschwister.filter(g => {
            return g.id === geschwisterId;
        });

        return geschwister.length > 0;
    }

    private addKontakteOrder(a: RelationshipWithKontaktperson, b: RelationshipWithKontaktperson): number {
        // Zuerst Erziehungsberechtigte Kontakte, weil der 2. Kontakt automatisch zum Erziehungsberechtigten
        // wird.
        if (a.relationship.isErziehungsberechtigter() && !b.relationship.isErziehungsberechtigter()) {
            return -1;
        }

        // Kontakte ohne Adresse benachteiligen, da sie nicht Erziehungsberechtigte sein dürfen
        if (a.hasAdresse() && !b.hasAdresse()) {
            return -1;
        }

        return 0;
    }
}

componentConfig.controller = DvbAnmeldungKind;

angular.module('kitAdmin').component('dvbAnmeldungKind', componentConfig);
