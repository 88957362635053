<div class="dvb-kitax-betreuungsgutscheine table-responsive">
    <table class="table text-nowrap text-right">
        <thead>
        <tr>
            <th data-translate="COMMON.DATE_RANGE"></th>
            <th ng-if="vm.schemaVersion.isKiBon" data-translate="KIND.BG_ANSPRUCH"></th>
            <th data-translate="KIND.BG_PENSUM"></th>
            <th data-translate="KIND.VOLLKOSTEN_GUTSCHEIN_SHORT"></th>
            <th data-translate="KIND.BETRAG_GUTSCHEIN_SHORT"></th>
            <th ng-if="vm.schemaVersion.isKiBon" data-translate="KIND.ELTERNBEITRAG_SHORT"></th>
            <th ng-if="vm.schemaVersion.isKiBon"
                data-translate="KIND.BETRAG_VERGUENSTIGT_SHORT"></th>
        </tr>
        </thead>
        <tbody>
        <tr ng-repeat="betreuungsGutschein in vm.betreuungsGutscheine">
            <td ng-bind="betreuungsGutschein | dvbLimitedMonth"></td>
            <td ng-if="vm.schemaVersion.isKiBon">
                <span ng-if="betreuungsGutschein.anspruchProzent"
                      uib-tooltip="{{'KIND.BG_PENSUM_ZEITEINHEIT_TOOLTIP_MF' | translate:{prozent: betreuungsGutschein.anspruchProzent, pensumUnit: betreuungsGutschein.zeiteinheit, zeiteinheiten: betreuungsGutschein.anspruchsberechtigteAnzahlZeiteinheiten}:'messageformat' }}"
                      tooltip-placement="top-left"
                      data-translate="KIND.BG_PENSUM_ZEITEINHEIT_MF"
                      data-translate-value-prozent="{{betreuungsGutschein.anspruchProzent}}"
                      data-translate-value-pensum-unit="{{betreuungsGutschein.zeiteinheit}}"
                      data-translate-value-zeiteinheiten="{{betreuungsGutschein.anspruchsberechtigteAnzahlZeiteinheiten}}"
                      data-translate-interpolation="messageformat">
                </span>
            </td>
            <td>
                <span uib-tooltip="{{'KIND.BG_PENSUM_ZEITEINHEIT_TOOLTIP_MF' | translate:{prozent: betreuungsGutschein.bgPensumProzent, pensumUnit: betreuungsGutschein.zeiteinheit, zeiteinheiten: betreuungsGutschein.verfuegteAnzahlZeiteinheiten}:'messageformat' }}"
                      tooltip-placement="top-left"
                      data-translate="KIND.BG_PENSUM_ZEITEINHEIT_MF"
                      data-translate-value-prozent="{{betreuungsGutschein.bgPensumProzent}}"
                      data-translate-value-pensum-unit="{{betreuungsGutschein.zeiteinheit}}"
                      data-translate-value-zeiteinheiten="{{betreuungsGutschein.verfuegteAnzahlZeiteinheiten}}"
                      data-translate-interpolation="messageformat">
                </span>
            </td>
            <td data-translate="COMMON.BETRAG_VALUE"
                data-translate-value-betrag="{{betreuungsGutschein.vollkosten | number:'2'}}">
            </td>
            <td ng-if="vm.schemaVersion.isKiBon"
                data-translate="COMMON.BETRAG_VALUE"
                data-translate-value-betrag="{{betreuungsGutschein.ausgestellterGutschein | number:'2'}}">
            </td>
            <td ng-if="vm.schemaVersion.isKiBon"
                data-translate="COMMON.BETRAG_VALUE"
                data-translate-value-betrag="{{betreuungsGutschein.minimalerElternbeitrag | number:'2'}}">
            </td>
            <td data-translate="COMMON.BETRAG_VALUE"
                data-translate-value-betrag="{{betreuungsGutschein.verguenstigterBetrag | number:'2'}}">
            </td>
        </tr>
        </tbody>
    </table>
</div>
