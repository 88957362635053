<div class="dvb-tarif-parameter">
    <h2 data-translate="VOREINSTELLUNGEN.PARAMETER_SORTIERUNG"></h2>

    <div class="row">
        <div class="col-md-7 normal-abstand-bottom">
            <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>

            <div class="normal-abstand-bottom" ng-repeat="type in vm.paramTypes">
                <div ng-if="vm.tarifParameters[type].length > 0">
                    <h3 data-translate="{{'TARIF.PARAM_TYPE.' + type}}"></h3>
                    <dv-tarif-parameter-sort [items]="vm.tarifParameters[type]" (items-change)="vm.save($event)">
                    </dv-tarif-parameter-sort>
                </div>
            </div>
        </div>
    </div>
</div>
