<div class="dvb-external-anmeldung-kind">
    <dvb-external-anmeldung-kind-stammdaten class="form-section-minus-normal-abstand"
                                            kind="vm.kind"
                                            index="vm.index">
    </dvb-external-anmeldung-kind-stammdaten>

    <div ng-if="vm.kind.customFieldValues.length > 0 && vm.kind.form.showStammdaten" class="normal-abstand-bottom">
        <dvb-custom-field-values custom-field-values="vm.kind.customFieldValues"
                                 click-to-edit="false">
        </dvb-custom-field-values>
    </div>

    <div class="row normal-abstand-top" ng-click="vm.kind.form.showAddress = !vm.kind.form.showAddress">
        <div class="col-md-12">
            <a href="" class="pull-left smaller-abstand-right">
                <i class="fa"
                   ng-class="{
                   'fa-chevron-right': !vm.kind.form.showAddress,
                   'fa-chevron-down': vm.kind.form.showAddress
                   }">
                </i>
            </a>
            <h2 class="pull-left"
                data-translate="EXTERNAL_ANMELDUNG.ADDRESS_KIND"
                data-translate-value-name="{{vm.kind.getDisplayName()}}">
            </h2>
        </div>
    </div>

    <div uib-collapse="!vm.kind.form.showAddress">
        <dvb-adresse-form ng-if="!vm.kind.adressWieHauptkontakt"
                          ng-model="vm.kind.adresse">
        </dvb-adresse-form>

        <div class="row form-row">
            <div class="col-md-12">
                <div class="checkbox">
                    <label>
                        <input ng-model="vm.kind.adressWieHauptkontakt"
                               type="checkbox">
                        <span data-translate="KIND.KIND_WOHNADRESSE_GLEICH_WIE_HAUPTKONTAKT"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
