<form class="kind-austritt-form"
      name="kindAustrittForm"
      #kindAustrittForm="ngForm"
      (ngSubmit)="submit(kindAustrittForm)"
      novalidate>
    <div class="modal-body">
        <h1 [translate]="'KIND.KIND_AUSTRITT_HEADING'"></h1>

        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <dvlib-datepicker-textfield name="austrittsDatum"
                                            [(ngModel)]="austrittsDatum"
                                            required
                                            autofocus
                                            [placeholder]="'KIND.KIND_AUSTRITT_AUSTRITTSDATUM' | translate">
                </dvlib-datepicker-textfield>
            </div>
            <div class="col-md-6 col-md-offset-3 normal-abstand-top">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" value="false" name="provisorisch" [(ngModel)]="provisorisch">
                        <span [translate]="'COMMON.PROVISORISCH'"></span>
                    </label>
                </div>
                <div class="checkbox normal-abstand-top">
                    <label>
                        <input type="checkbox" name="mailBestaetigung" value="false" [(ngModel)]="mailBestaetigung">
                        <span [translate]="'COMMUNICATION.BULK_EMAILS.TERMINATION_MAIL'"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading">
                <span [translate]="'KIND.AUSTRITT'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    [translate]="'COMMON.ABBRECHEN'"
                    (click)="hide()">
            </button>
        </dvlib-button-list>
    </div>
</form>
