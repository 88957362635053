/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {ILimited, ITimeRange} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, TimeRangeUtil} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import type moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class EventGueltigkeitService {

    public constructor(
        private readonly translator: Translator,
    ) {
    }

    public toEventRange(
        entity: ILimited & ITimeRange,
        selectedDate?: moment.Moment,
    ): {
        gueltigkeit: {
            gueltigAb: moment.Moment;
            gueltigBis: moment.Moment;
            von: moment.Moment | null;
            bis: moment.Moment | null;
        };
        gueltigkeitTxt: string;
    } {
        const iTimeRange = selectedDate ?
            TimeRangeUtil.toTimeRangeAtLocalDate(entity, selectedDate) :
            {von: entity.von, bis: entity.bis};

        const gueltigAb = checkPresent(entity.gueltigAb);
        const gueltigBis = checkPresent(entity.gueltigBis);
        const gueltigkeit = {gueltigAb, gueltigBis, ...iTimeRange};
        const today = DvbDateUtil.today();

        return {
            gueltigkeitTxt: DvbDateUtil.getGueltigkeitTextWithOptionalTime(gueltigkeit, today, this.translator),
            gueltigkeit,
        };
    }
}
