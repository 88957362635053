/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {KontaktKorrespondenz} from '../../../communication/models/KontaktKorrespondenz';
import type {KontaktKorrespondenzType} from '../../../communication/models/KontaktKorrespondenzType';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        korrespondenz: '<',
    },
    template: require('./dvb-kontaktperson-correspondence-timeline-details.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonCorrespondenceTimelineDetails implements angular.IController {
    public static $inject: readonly string[] = [];

    public korrespondenz!: KontaktKorrespondenz;

    public showCommunicationType: boolean = false;
    public showContent: boolean = false;
    public showEmailContentPlainText: boolean = false;
    public showEmailContentHTML: boolean = false;
    public showNotiz: boolean = false;

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const entry = onChangesObj.korrespondenz.currentValue;
        if (!entry) {
            return;
        }

        const hasPreContentText = this.hasPreContentText(entry.type);
        this.showCommunicationType = entry.content && hasPreContentText;
        this.showContent = entry.content && !hasPreContentText;
        this.showEmailContentPlainText = !entry.emailContentHTML && entry.emailContentPlainText;
        this.showEmailContentHTML = entry.emailContentHTML;
        this.showNotiz = entry.type === 'NOTIZ';
    }

    private hasPreContentText(type: KontaktKorrespondenzType): boolean {
        return type === 'BRING_ABHOLZEITEN' || type === 'BRING_ABHOLZEITEN_CONFIRM';
    }
}

componentConfig.controller = DvbKontaktpersonCorrespondenceTimelineDetails;
angular.module('kitAdmin').component('dvbKontaktpersonCorrespondenceTimelineDetails', componentConfig);
