<div class="dvb-kind-tarife-parameter-repeat">
    <div class="row"
         ng-repeat="entry in vm.tarifParameterValues | orderBy:'parameter.orderValue'">
        <div class="col-md-8">
            <label>
                <span class="truncate" ng-bind="entry.parameter.name"></span>
                <i class="fa fa-download"
                   ng-if="entry.parameter.kibonParam"
                   uib-tooltip="{{'KIND.IMPORTED_PARAMETER' | translate}}"
                   tooltip-placement="top-left">
                </i>
            </label>
            <input type="number"
                   class="form-control"
                   ng-model="entry.value"
                   uib-tooltip="{{entry.parameter.name}}"
                   tooltip-placement="top-left">
        </div>
    </div>
</div>
