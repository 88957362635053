/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import type {Persisted, TerminationMode} from '@dv/shared/code';
import type angular from 'angular';
import type {
    KinderOrtBetreuungsSchluesselService,
} from '../../../kinderort/service/kinderOrtBetreuungsSchluesselService';
import type {BetreuungsSchluessel} from './BetreuungsSchluessel';
import type {ILimitedEntityAssignmentController} from './ILimitedEntityAssignmentController';
import {KinderOrtBetreuungsSchluessel} from './KinderOrtBetreuungsSchluessel';

export class BetreuungsSchluesselKinderOrtAssignmentController implements ILimitedEntityAssignmentController
    <KinderOrt, KinderOrtBetreuungsSchluessel, BetreuungsSchluessel> {

    public constructor(
        public entity: Persisted<KinderOrt>,
        public assigned: KinderOrtBetreuungsSchluessel[],
        public available: BetreuungsSchluessel[],
        public kinderOrtBetreuungsSchluesselService: KinderOrtBetreuungsSchluesselService,
    ) {
    }

    public getNewAssignmentEntity(): KinderOrtBetreuungsSchluessel {
        return new KinderOrtBetreuungsSchluessel(null, this.entity.id);
    }

    public create(assignment: KinderOrtBetreuungsSchluessel): angular.IPromise<unknown> {
        return this.kinderOrtBetreuungsSchluesselService.create(assignment);
    }

    public remove(assignment: KinderOrtBetreuungsSchluessel): any {
        return this.kinderOrtBetreuungsSchluesselService.delete(assignment.id!);
    }

    public terminate(endDate: moment.Moment, mode: TerminationMode): angular.IPromise<unknown> {
        return this.kinderOrtBetreuungsSchluesselService.terminate(this.entity.id, endDate, mode);
    }

    public extend(assignment: KinderOrtBetreuungsSchluessel): angular.IPromise<unknown> {
        return this.kinderOrtBetreuungsSchluesselService.extend(assignment.id!);
    }
}
