/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BaseRechnungsPosition} from '@dv/kitadmin/models';
import {MandantRechnungsPosition} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnungsPosition: '<',
        onDelete: '&',
    },
    template: require('./dvb-administration-mandant-rechnungsposition.html'),
    controllerAs: 'vm',
};

export class DvbAdministrationMandantRechnungsposition {
    public static $inject: readonly string[] = [];

    public rechnungsPosition?: MandantRechnungsPosition | BaseRechnungsPosition;
    public onDelete!: () => void;

    public isDeletable: boolean = false;

    public $onChanges(): void {
        this.isDeletable = !(this.rechnungsPosition instanceof MandantRechnungsPosition);
    }
}

componentConfig.controller = DvbAdministrationMandantRechnungsposition;
angular.module('kitAdmin').component('dvbAdministrationMandantRechnungsposition', componentConfig);
