<div class="kinderort-anwesenheit-settings row">
    <div class="col-md-7">
        <h2 data-translate="KIND.MONATSBELEGUNG.SETTINGS"></h2>

        <dvb-editable-label ng-model="vm.kinderOrt.maxDailyHours"
                            type="number"
                            min="0"
                            step="1"
                            max="24"
                            ng-attr-placeholder="{{'KIND.MONATSBELEGUNG.MAX_DAILY_HOURS' | translate}}"
                            label="{{'KIND.MONATSBELEGUNG.MAX_DAILY_HOURS' | translate:{stunden: vm.kinderOrt.maxDailyHours} }}"
                            ng-change="vm.saveAnwesenheitsZeitSettings()">
        </dvb-editable-label>

        <div class="row small-abstand-top">
            <div class="col-md-2"></div>
            <div class="col-md-5" data-translate="KIND.MONATSBELEGUNG.ALLOWED_ARRIVAL_TIMES"></div>
            <div class="col-md-5" data-translate="KIND.MONATSBELEGUNG.ALLOWED_PICK_UP_TIMES"></div>
        </div>
        <div class="row entity" ng-repeat="day in vm.dayOfWeek" ng-init="dayOfWeekMoment = vm.toDayOfWeekMoment(day)">
            <div class="col-md-2">
                <p class="day-label" ng-bind="dayOfWeekMoment.format('dd')"></p>
            </div>
            <div class="col-md-5">
                <dvb-editable-label ng-attr-placeholder="{{'KIND.MONATSBELEGUNG.ALLOWED_TIMES_PLACEHOLDER' | translate}}"
                                    ng-model="vm.anwesenheitsZeitConstraintsByWeekDay[day]['BRING_ZEITEN']"
                                    is-valid="vm.isValidAnwesenheitsZeit(param)"
                                    ng-change="vm.saveAnwesenheitsZeitSettings()">
                </dvb-editable-label>
            </div>
            <div class="col-md-5">
                <dvb-editable-label ng-attr-placeholder="{{'KIND.MONATSBELEGUNG.ALLOWED_TIMES_PLACEHOLDER' | translate}}"
                                    ng-model="vm.anwesenheitsZeitConstraintsByWeekDay[day]['ABHOL_ZEITEN']"
                                    is-valid="vm.isValidAnwesenheitsZeit(param)"
                                    ng-change="vm.saveAnwesenheitsZeitSettings()">
                </dvb-editable-label>
            </div>
        </div>
    </div>
</div>
