<div class="dvb-mandant-feature-form dvb-form">
    <div ng-repeat="feature in vm.mandantFeatures.features" class="big-abstand-bottom">
        <div class="row selected-entity">
            <div class="col-xs-9 entity">
                <span data-translate="{{'ADMINISTRATION.MANDANT_FEATURES.TYPES.' + feature.featureType}}"></span>
            </div>
            <div class="col-xs-3 edit">
                <button type="button" class="btn btn-link"
                        ng-click="vm.removeFeature(feature)"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" ng-model="feature.allEnabled">
                        <span data-translate="ADMINISTRATION.MANDANT_FEATURES.ENABLE_FOR_ALL"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="row" ng-if="!feature.allEnabled">
            <div class="col-md-6">
                <div class="row selected-entity"
                     ng-repeat="kinderOrt in feature.kinderOrte"
                     ng-init="searchResultEntry = kinderOrt.toSearchResultEntry()">
                    <div class="col-xs-9 entity">
                        <a ui-sref="base.kinderort.profil({id: kinderOrt.id})">
                            <dvb-search-result-icon entry="searchResultEntry"></dvb-search-result-icon>
                            <span ng-bind="kinderOrt.getDisplayName()"></span>
                        </a>
                    </div>
                    <div class="col-xs-3 edit">
                        <button type="button" class="btn btn-link"
                                ng-click="vm.removeKinderOrt(feature, kinderOrt)"
                                data-translate="COMMON.LOESCHEN">
                        </button>
                    </div>
                </div>

                <dvb-search-on-click-entity add-label-key="COMMON.KINDERORT.AUSWAEHLEN"
                                            entity-to-search="KINDERORT"
                                            on-select="vm.addKinderOrt(feature, item)"
                                            on-select-clear="true"
                                            disabled-entries="feature.kinderOrte"
                                            placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                            mandant-filter="vm.mandantFilter">
                </dvb-search-on-click-entity>
            </div>
        </div>
    </div>

    <div ng-if="vm.availableFeatures.length > 0" class="big-abstand-bottom">
        <h3 class="normal-abstand-bottom" data-translate="ADMINISTRATION.MANDANT_FEATURES.AVAILABLE"></h3>

        <div ng-repeat="featureType in vm.availableFeatures">
            <div class="form-row">
                <button type="button" class="btn btn-link" ng-click="vm.addFeature(featureType)">
                    + <span data-translate="{{'ADMINISTRATION.MANDANT_FEATURES.TYPES.' + featureType}}"></span>
                </button>
            </div>
        </div>
    </div>
</div>
