<div class="dvb-custom-field-values">
    <div class="row">
        <div class="col-md-6" ng-repeat="customFieldValue in vm.visibleCustomFieldValues"
             ng-class="{'hidden-print': customFieldValue.value.value === null}">
            <div ng-switch="customFieldValue.customField.fieldType">
                <div ng-switch-when="STRING">
                    <dvb-editable-label ng-if="vm.clickToEdit"
                                        ng-attr-placeholder="{{customFieldValue.customField.getFieldName()}}"
                                        maxlength="255"
                                        ng-model="customFieldValue.value.value"
                                        ng-change="vm.update(customFieldValue)"
                                        ng-disabled="vm.isDisabled">
                    </dvb-editable-label>

                    <input ng-if="!vm.clickToEdit"
                           type="text"
                           class="form-control form-row"
                           ng-class="{'input-sm': vm.small}"
                           maxlength="255"
                           ng-attr-placeholder="{{customFieldValue.customField.getFieldName()}}"
                           uib-tooltip="{{customFieldValue.customField.getFieldName()}}"
                           tooltip-placement="top-left"
                           ng-model="customFieldValue.value.value"
                           ng-change="vm.update(customFieldValue)"
                           ng-disabled="vm.isDisabled">
                </div>

                <div class="checkbox" ng-switch-when="BOOLEAN">
                    <label ng-class="{'small': vm.small}">
                        <input type="checkbox"
                               ng-model="customFieldValue.value.value"
                               ng-change="vm.update(customFieldValue)"
                               ng-disabled="vm.isDisabled">
                        <span ng-bind="customFieldValue.customField.getFieldName()"></span>
                    </label>
                </div>

                <div ng-switch-when="SELECT" class="form-row" ng-class="{'small': vm.small}">
                    <label ng-if="customFieldValue.value.value === null"
                           ng-bind="customFieldValue.customField.getFieldName()"
                           class="select-label">
                    </label>
                    <select class="form-control"
                            ng-class="{'input-sm': vm.small}"
                            ng-model="customFieldValue.value.value"
                            ng-options="option for option in customFieldValue.customField.getSplitSelectValues()"
                            ng-change="vm.update(customFieldValue)"
                            ng-disabled="vm.isDisabled"
                            uib-tooltip="{{customFieldValue.customField.getFieldName()}}"
                            tooltip-placement="top-left">
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
