/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IKitaVendorKonfiguration, KinderOrt, RechnungsKonfiguration} from '@dv/kitadmin/models';
import {KitaRechnungsKonfiguration} from '@dv/kitadmin/models';
import type {KontoService} from '@dv/shared/backend/api/konto.service';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import type {Nullish, Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {KitaFakturaService} from 'src/app/common/service/rest/kinderort/kitaFakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        kitaKonfigurationen: '<',
        mandantKonfigurationen: '<',
    },
    template: require('./dvb-kita-faktura-konfigurationen.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaKonfigurationen implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'kitaFakturaService', 'kontoService'];

    public kita!: Persisted<KinderOrt>;
    public kitaKonfigurationen: Persisted<KitaRechnungsKonfiguration>[] = [];
    public mandantKonfigurationen: Persisted<RechnungsKonfiguration>[] = [];

    public today = DvbDateUtil.today();
    public showNewForm = false;
    public showNewButton = true;
    public konfiguration: KitaRechnungsKonfiguration | Nullish = null;
    public isLoading = false;
    public templateKonfiguration: IKitaVendorKonfiguration | Nullish = null;
    public konten: JaxKonto[] = [];

    public constructor(
        private readonly $state: StateService,
        private readonly kitaFakturaService: KitaFakturaService,
        private readonly kontoService: KontoService,
    ) {
    }

    public $onInit(): void {
        this.kontoService.getAll$().subscribe({
            next: data => {
                this.konten = data.items;
            },
        });
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.kitaKonfigurationen) {
            this.computeKonfigurationTemplate(changes.kitaKonfigurationen.currentValue);
        }
    }

    public neueKonfiguration(mandantKonfiguration: RechnungsKonfiguration): void {
        this.showNewButton = false;

        this.konfiguration = new KitaRechnungsKonfiguration();
        this.konfiguration.kitaId = this.kita.id;
        this.konfiguration.rechnungsKonfigurationId = mandantKonfiguration.id;
        this.konfiguration.dtype = mandantKonfiguration.dtype;
        this.konfiguration.gueltigAb = DvbDateUtil.startOfMonth();
        this.konfiguration.mandantKonfiguration = mandantKonfiguration;

        this.showNewForm = true;
    }

    public onSubmit(): void {
        this.isLoading = true;
        this.kitaFakturaService.createRechnungsKonfiguration(checkPresent(this.konfiguration)).then(() => {
            this.hideForm();
            this.$state.reload();
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public hideForm(): void {
        this.showNewButton = true;
        this.showNewForm = false;
    }

    private computeKonfigurationTemplate(konfigurationen: KitaRechnungsKonfiguration[]): void {
        if (konfigurationen.length === 0) {
            // reset template
            this.templateKonfiguration = null;

            return;
        }

        // sort configurations for later traversal
        const sortedKonfigurationen = DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(konfigurationen);

        // index of latest configuration that is valid before today's date
        let lastIndexBeforeToday = null;

        for (let i = 0; i < sortedKonfigurationen.length; i++) {
            if (DvbDateUtil.isGueltigOn(sortedKonfigurationen[i], this.today)) {
                // currently active configuration found
                this.templateKonfiguration = angular.copy(sortedKonfigurationen[i]);
                break;
            }

            if (checkPresent(sortedKonfigurationen[i].gueltigAb).isBefore(this.today)) {
                lastIndexBeforeToday = i;
            }
        }

        if (this.templateKonfiguration === null && lastIndexBeforeToday !== null) {
            // no current configuration available, we take the newest configuration
            // TODO neuste oder vorherige?
            this.templateKonfiguration = angular.copy(sortedKonfigurationen[sortedKonfigurationen.length - 1]);
        }
    }
}

componentConfig.controller = DvbKitaFakturaKonfigurationen;
angular.module('kitAdmin').component('dvbKitaFakturaKonfigurationen', componentConfig);
