<div class="dvb-kind-tarife-parameter-form">
    <form name="kindTarifeParameterForm" ng-submit="vm.onSubmit()" class="dvb-form" novalidate>
        <div class="history-columns">

            <p class="history-column-left"
               ng-transclude="title"
               ng-if="!vm.isNewEntity">
            </p>

            <div class="history-column-middle">
                <div class="row" ng-if="!vm.tarifParameterHistoryEntry.id">
                    <div class="col-md-4">
                        <dvb-datepicker-text-field ng-model="vm.tarifParameterHistoryEntry.gueltigAb"
                                                   required
                                                   ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                </div>
                <dvb-kind-tarife-parameter-repeat tarif-parameter-values="vm.relevantParamValues">
                </dvb-kind-tarife-parameter-repeat>

                <div class="normal-abstand-top normal-abstand-bottom" ng-click="vm.toggleIrrelevantParams()">
                    <a href="">
                        <i class="fa"
                           ng-class="{'fa-chevron-right': vm.irrelevantParamsCollapsed, 'fa-chevron-down': !vm.irrelevantParamsCollapsed}"></i>
                    </a>
                    <span role="button" data-translate="KIND.IRRELEVANT_PARAMETERS"></span>
                </div>
                <div uib-collapse="vm.irrelevantParamsCollapsed">
                    <dvb-kind-tarife-parameter-repeat tarif-parameter-values="vm.irrelevantParamValues">
                    </dvb-kind-tarife-parameter-repeat>
                </div>
            </div>
        </div>

        <dvb-submit-cancel-buttons is-loading="kindTarifeParameterForm.$pending"
                                   on-cancel="vm.onCancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
