/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';
import type {ChartOptions, Point} from 'chart.js';
import {Chart} from 'chart.js';
import type {StyleVariableService} from '../../../../common/service/style-variable.service';
import type {ChartContext} from './ChartContext';

/**
 * Utility for initializing charts.
 */
export class ChartUtil {

    public static createOptions(
        chartContext: ChartContext,
        styleVariableService: StyleVariableService,
        maxTickValue: number,
        minTickValue: number,
        $translate: angular.translate.ITranslateService,
    ): ChartOptions {

        // Refer to http://www.chartjs.org/docs/latest/ for docs
        return {
            legend: {
                display: false, // do not show legend
            },
            elements: {
                point: {
                    // do not show data points
                    radius: 0,
                    hoverRadius: 0,
                },
            },
            animation: {
                duration: 0, // disable inital animation
            },
            hover: {
                animationDuration: 0, // disable tooltip move animation
            },
            responsiveAnimationDuration: 0, // disable resize animation
            tooltips: {
                mode: 'index', // tooltips are always activated on hover and point to all datapoints in the week
                intersect: false, // mouse must not exactly be on point to trigger tooltip
                position: 'middle',
                displayColors: false, // hide dataset colors within tooltip
                backgroundColor: styleVariableService.getColorGuideBright(),
                titleFontColor: styleVariableService.getColorText(),
                bodyFontColor: styleVariableService.getColorText(),
            },
            events: [
                'mousemove', // update tooltip when moving mouse
                'mouseout', // remove tooltip when mouse is out
                'click', // enable click event
            ],
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            max: maxTickValue,
                            min: minTickValue,
                        },
                    },
                ],
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            unit: 'month',
                            isoWeekday: true,
                            displayFormats: {
                                month: 'MMM',
                            },
                            tooltipFormat: `[${$translate.instant('KINDERORT.SHORT_KALENDERWOCHE')}] w`,
                        },
                    },
                ],
            },
            onHover: chartContext.onHover.bind(chartContext),
            onClick: chartContext.updateWeek.bind(chartContext),
        };
    }

    public static createChart(
        options: ChartOptions,
        chartContext: ChartContext,
    ): Chart {

        const settings: Chart.ChartConfiguration = {
            type: 'line',
            data: chartContext.chartData,
            options,
            plugins: [{beforeDraw: chartContext.drawIndexLine}],
        };

        const chart = new Chart(chartContext.ctx2d, settings);

        const chartArea = chart.chartArea;

        const middlePosition = chartArea.top + (chartArea.bottom - chartArea.top) / 2;

        Chart.Tooltip.positioners.middle = (_chartElements, coordinates): Point => ({
            x: coordinates.x,
            y: middlePosition,
        });

        chartContext.chart = chart;

        return chart;
    }
}
