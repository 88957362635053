/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {Firma, FirmenKontakt, FirmenKontingent, FirmenKontingentValue, Kita, Tarif} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../common/service/dvbStateService';
import type {FirmaService} from '../../common/service/rest/firmaService';
import type {MandantConfigurationService} from '../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-firma-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbFirmaErstellen implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'errorService',
        'firmaService',
        'dvbStateService',
        'mandantConfigurationService',
    ];

    public isFirmenkontingentDefinieren: boolean = false;
    public isFirmenplatz: boolean = true;
    public firma: Firma = new Firma();
    public firmenkontingent: FirmenKontingent = new FirmenKontingent();
    public firmenKontingentValue: FirmenKontingentValue = new FirmenKontingentValue();
    public firmenKontakt: FirmenKontakt = new FirmenKontakt();
    public isLoading: boolean = false;
    public tarifSearchResultEntry: SearchResultEntry | null = null;
    public isAddAlternativeKibonAbzugEnabled: boolean = false;

    public constructor(
        private $state: StateService,
        private errorService: ErrorService,
        private firmaService: FirmaService,
        private dvbStateService: DvbStateService,
        private mandantConfigurationService: MandantConfigurationService,
    ) {
    }

    public $onInit(): void {
        this.firmenkontingent.values = [];
        this.firmenkontingent.values.push(this.firmenKontingentValue);
        this.firmenkontingent.values[0].kitas = [];
        this.mandantConfigurationService.getDefaultCountry().then(country => {
            this.firmenKontakt.adresse.land = country;
        });
        this.firma.symbol = 'F';
        if (this.firma.alternativeKibonAbzug && this.firma.firmenPlatz) {
            this.isAddAlternativeKibonAbzugEnabled = true;
        }
    }

    public addKita(item: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = item.text;
        kita.id = item.id;
        this.firmenKontingentValue.addKita(kita);
    }

    public isKitaSet(): boolean {
        return this.firmenKontingentValue.kitas.length > 0;
    }

    public onCancel(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    public setTarif(firmenKontingentValue: FirmenKontingentValue): void {
        let tarif: Tarif | null = null;
        if (this.tarifSearchResultEntry) {
            tarif = new Tarif();
            tarif.id = this.tarifSearchResultEntry.id;
            tarif.name = this.tarifSearchResultEntry.text;
        }
        firmenKontingentValue.setTarif(tarif);
    }

    public setFirmenPlatz(firmenPlatz: boolean): void {
        this.firma.firmenPlatz = firmenPlatz;
        if (firmenPlatz) {
            this.firma.symbol = 'F';
        }
    }

    public removeKibonAbzugProTag(): void {
        this.firma.alternativeKibonAbzug = null;
        this.isAddAlternativeKibonAbzugEnabled = false;
    }

    public validateAndCreateFirma(formIsValid: boolean): void {
        this.errorService.clearAll();

        const isFirmenKontingentValid = this.isKitaSet() &&
            this.firmenkontingent.isValid() &&
            this.firmenKontingentValue.isValid();

        this.firma.kontakt = this.firma.firmenPlatz ? this.firmenKontakt : null;

        if (!(formIsValid && this.firma.isValid() &&
            (!this.isFirmenkontingentDefinieren || isFirmenKontingentValid))) {
            this.errorService.handleValidationError(false, 'ERRORS.ERR_INCOMPLETE_FIRMA');

            return;
        }

        this.isLoading = true;

        const promise = this.isFirmenkontingentDefinieren ?
            this.firmaService.create(this.firma, this.firmenkontingent) :
            this.firmaService.create(this.firma);

        promise
            .then(response => ({id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response)}))
            .then(params => this.$state.go('base.firma.profil', params))
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbFirmaErstellen;
angular.module('kitAdmin').component('dvbFirmaErstellen', componentConfig);
