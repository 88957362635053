/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout, Einzahlungsschein} from '@dv/kitadmin/models';
import {DvbKitaRechnungsKonfiguration} from '@dv/kitadmin/models';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {Nullish} from '@dv/shared/code';
import angular from 'angular';
import type {DvbFakturaService} from '../../../../../common/service/rest/dvbFakturaService';
import type {DokumentlayoutService} from '../../../../../dokumentlayout/service/dokumentlayoutService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '=',
        templateKonfiguration: '<?',
        templateDtype: '<?',
        editMode: '<',
        konten: '<',
        konto: '=',
    },
    template: require('./dvb-kita-faktura-dvb-konfiguration-form.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaDvbKonfigurationForm {

    public static $inject: readonly string[] = ['dvbFakturaService', 'dokumentlayoutService'];

    public konfiguration!: DvbKitaRechnungsKonfiguration;
    public templateKonfiguration?: DvbKitaRechnungsKonfiguration;
    public templateDtype?: RechnungsKonfigurationType;
    public editMode!: boolean;
    public konten: JaxKonto[] = [];
    public konto: JaxKonto | Nullish;

    public einzahlungsscheine: Einzahlungsschein[] | null = null;
    public layouts: DokumentLayout[] | null = null;
    public loadingEinzahlungsscheine: boolean = false;
    public loadingLayouts: boolean = false;

    public constructor(
        private readonly dvbFakturaService: DvbFakturaService,
        private readonly dokumentlayoutService: DokumentlayoutService,
    ) {
    }

    public $onInit(): void {
        if (!this.konfiguration) {
            this.konfiguration = DvbKitaRechnungsKonfiguration.createNew();

            if (this.templateKonfiguration) {
                if (this.templateDtype === RechnungsKonfigurationType.DVB) {
                    this.konfiguration = this.templateKonfiguration;
                } else {
                    this.konfiguration.texte = this.templateKonfiguration.texte;
                }
            }
        }

        this.reloadEinzahlungsscheine();
        this.reloadLayouts();
    }

    public reloadEinzahlungsscheine(): void {
        this.loadingEinzahlungsscheine = true;
        this.dvbFakturaService.getAllEinzahlungsscheine().then(einzahlungsscheine => {
            this.einzahlungsscheine = einzahlungsscheine;
        }).finally(() => {
            this.loadingEinzahlungsscheine = false;
        });
    }

    public reloadLayouts(): void {
        this.loadingLayouts = true;
        this.dokumentlayoutService.getAllDokumentLayouts().then(layouts => {
            this.layouts = layouts;
        }).finally(() => {
            this.loadingLayouts = false;
        });
    }
}

componentConfig.controller = DvbKitaFakturaDvbKonfigurationForm;
angular.module('kitAdmin').component('dvbKitaFakturaDvbKonfigurationForm', componentConfig);
