/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {MandantRechnung} from '@dv/kitadmin/models';
import {MandantRechnungsPDF} from '@dv/kitadmin/models';
import {checkPresent, DvbDateUtil, DvbRestUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import {DvbRestUtilAngularJS} from '../../../common/service/rest/dvbRestUtilAngularJS';
import type {AdministrationService} from '../../service/administrationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        preSelectedRechnungIndex: '<?',
    },
    template: require('./dvb-administration-mandanten-abrechnung.html'),
    controllerAs: 'vm',
};

const MIDDLE_OF_MONTH = 15;

export class DvbAdministrationMandantenAbrechnung implements angular.IController {

    public static $inject: readonly string[] = ['administrationService', '$q', '$filter', 'errorService'];

    public preSelectedRechnungIndex?: number;
    public searchText: string = '';
    public mandantRechnungen: MandantRechnung[] = [];
    public filteredMandantRechnungen: MandantRechnung[] = [];
    public isLoading: boolean = false;
    public selectedRechnungIndex: number | null = null;
    public einkommen: number = 0;
    public kitaCount: number = 0;
    public tageselternCount: number = 0;
    public totalKitaCount: number = 0;
    public totalTageselternCount: number = 0;
    public coronaKinderOrteCharged: number = 0;
    public stichtag?: moment.Moment;
    public isDownloading: boolean = false;

    private timeout?: angular.IDeferred<unknown>;
    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly administrationService: AdministrationService,
        private readonly $q: angular.IQService,
        private readonly $filter: angular.IFilterService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public $onInit(): void {
        this.stichtag = this.initStichtag(DvbDateUtil.today());
        this.administrationService.countCoronaKinderOrtFees().then(count => {
            this.coronaKinderOrteCharged = count;
        });
        this.reloadRechnungen();
    }

    public filterMandanten(): void {
        this.filteredMandantRechnungen = this.$filter('filter')(this.mandantRechnungen, this.searchText);
    }

    public isPersisted(): boolean {
        return this.filteredMandantRechnungen.some(mandantRechnung => mandantRechnung.id !== null);

    }

    /**
     * @param initialDate
     * @return when before MIDDLE_OF_MONTH, load previous month, otherwise load this month
     */
    public initStichtag(initialDate: moment.Moment): moment.Moment {
        return initialDate.subtract(MIDDLE_OF_MONTH - 1, 'days');
    }

    public reloadRechnungen(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();

        this.isLoading = true;
        this.mandantRechnungen = [];
        this.filteredMandantRechnungen = [];
        this.einkommen = 0;
        const config = {timeout: this.timeout.promise};
        this.administrationService.getMandantRechnungen(checkPresent(this.stichtag).date(MIDDLE_OF_MONTH), {}, config)
            .then(mandantRechnungen => {
                this.mandantRechnungen = mandantRechnungen;
                this.filteredMandantRechnungen = this.$filter('filter')(mandantRechnungen, this.searchText);
                this.einkommen = 0;
                this.kitaCount = 0;
                this.tageselternCount = 0;
                this.totalKitaCount = 0;
                this.totalTageselternCount = 0;
                for (const rech of mandantRechnungen) {
                    this.einkommen += rech.summe ?? 0;
                    this.kitaCount += rech.kitaCount ?? 0;
                    this.tageselternCount += rech.tageselternCount ?? 0;
                    this.totalKitaCount += rech.totalKitaCount ?? 0;
                    this.totalTageselternCount += rech.totalTageselternCount ?? 0;
                }
            })
            .catch(error => {
                if (DvbRestUtil.isRequestCancelled(error)) {
                    return this.$q.resolve([]);
                }

                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.preSelectedRechnungIndex) {
            this.selectedRechnungIndex = changes.preSelectedRechnungIndex.currentValue;
        }
    }

    public select(index: number): void {
        if (this.selectedRechnungIndex === index) {
            this.selectedRechnungIndex = null;

            return;
        }
        this.selectedRechnungIndex = index;
    }

    public createRechnungsPdf(mandantRechnung: MandantRechnung | null): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.errorService.clearAll();
        this.timeout = this.$q.defer();

        this.isDownloading = true;
        const selectedIds: string[] = [];
        const id = mandantRechnung?.id;
        if (isPresent(id)) {
            selectedIds.push(id);
        } else {
            this.mandantRechnungen.forEach(rechnung => {
                if (rechnung.id) {
                    selectedIds.push(rechnung.id);
                }
            });
        }

        if (selectedIds.length === 0) {
            // Error:  No Rechnung found
            this.errorService.handleValidationError(false, 'ADMINISTRATION.ERR_INVALID_MANDANT_RECHNUNG');
            this.isDownloading = false;

            return;
        }

        const mandantRechnungsPDF = new MandantRechnungsPDF();
        mandantRechnungsPDF.rechnungsIds = selectedIds;

        this.administrationService.downloadMandantRechnungen(mandantRechnungsPDF)
            .then(tempBlob => {
                if (tempBlob) {
                    this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
                }
            })
            .finally(() => {
                this.isDownloading = false;
            });
    }
}

componentConfig.controller = DvbAdministrationMandantenAbrechnung;
angular.module('kitAdmin').component('dvbAdministrationMandantenAbrechnung', componentConfig);
