<div class="dvb-report-kindergartenkinder">
    <h2 data-translate="REPORT.KINDERGARTENKINDER.TITLE"></h2>

    <form name="kindergartenkinderReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-kitas-selection parameter-kinder-orte="vm.parameterKindergartenkinder"
                             on-change="vm.updateState()">
        </dvb-kitas-selection>

        <div class="row form-row">
            <div class="col-xs-12">
                <span data-translate="COMMON.KINDERGARTEN"></span>
                <div class="radio" ng-repeat="(type, value) in vm.availableLocations"
                     ng-init="vm.parameterKindergartenkinder.kindergartenLocations=vm.availableLocations['all']">
                    <label>
                        <input name="location"
                               type="radio"
                               ng-value="value"
                               ng-model="vm.parameterKindergartenkinder.kindergartenLocations"
                               required>
                        <span
                            data-translate="{{type === 'all' ? 'COMMON.BEIDE' :'KIND.ZUWEISUNG.KINDERGARTEN.' + type}}">
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-xs-12">
                <div class="radio" ng-repeat="(type, value) in vm.availableTypes"
                     ng-init="vm.parameterKindergartenkinder.kindergartenTypes=vm.availableTypes['all']">
                    <label>
                        <input name="type"
                               type="radio"
                               ng-value="value"
                               ng-model="vm.parameterKindergartenkinder.kindergartenTypes"
                               required>
                        <span
                            data-translate="{{type === 'all' ? 'COMMON.ALLE' : 'KIND.ZUWEISUNG.KINDERGARTEN.' + type}}">
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-xs-12">
                <dvb-datepicker-text-field ng-model="vm.parameterKindergartenkinder.stichtag"
                                           ng-attr-placeholder="{{'REPORT.STICHTAG' | translate}}: {{vm.parameterKindergartenkinder.stichtag || vm.defaultStichtag | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>
        <dvb-report-download on-submit="vm.onSubmit(kindergartenkinderReportForm)"></dvb-report-download>
    </form>

</div>
