<div class="col-md-7 dvb-bulk-email-history">

    <h2 data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.TITLE"></h2>

    <ul class="filter-list small-abstand-bottom">
        <li data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.SENT_DATE" class="filter-label"></li>
        <li>
            <dvb-filter-option-popover filter-model="vm.versandPeriodeFilter"
                                       closeable="false"
                                       on-toggle="vm.reloadItems()"
                                       on-close-popover="vm.applyVersandDatumFilter()">
            </dvb-filter-option-popover>
        </li>
        <li data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.SEARCH_TEXT"
            class="filter-label"></li>
        <li>
            <input type="text"
                   class="form-control input-sm no-margin"
                   ng-attr-placeholder="{{'COMMUNICATION.BULK_EMAILS.HISTORY.SEARCH_TEXT_SHORT' | translate}}"
                   uib-tooltip="{{'COMMUNICATION.BULK_EMAILS.HISTORY.SEARCH_TEXT' | translate}}"
                   tooltip-placement="top-left"
                   maxlength="100"
                   ng-model="vm.textSuche"
                   ng-model-options="{ debounce: 100 }"
                   ng-change="vm.reloadItems()">
        </li>
    </ul>

    <dvb-pages items="vm.pageContainer.items"
               entity-name="'COMMUNICATION.BULK_EMAILS.HISTORY.MF'"
               total-items="vm.pageContainer.count"
               is-loading="vm.isLoading"
               items-per-page="vm.itemsPerPage"
               current-page="vm.currentPage"
               on-update-page="vm.updatePage(page)"
               on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
        <page-items dvb-accordions>
            <dvb-accordion ng-repeat="bulkEmail in vm.pageContainer.items"
                           is-first="$first">
                <panel-header class="full-width-block">
                    <div class="layout-row items-center row">
                        <div class="col-sm-10">
                            <span ng-bind="bulkEmail.emailSubject"
                                  uib-tooltip="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}"
                                  uib-tooltip-placement="top-left">
                            </span>
                        </div>
                        <div class="col-sm-2">
                            <span ng-bind="bulkEmail.versandtAm | amDateFormat:'D.M.YYYY'"
                                  uib-tooltip="{{'COMMUNICATION.BULK_EMAILS.HISTORY.SENT_DATE' | translate}}"
                                  uib-tooltip-placement="top-left">
                            </span>
                        </div>
                    </div>
                </panel-header>

                <panel-content>
                    <div class="row">
                        <div class="col-sm-4">
                            <h3 data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.SENT_BY_USER"></h3>
                            <p ng-bind="bulkEmail.userErstellt.getDisplayName()"></p>
                        </div>
                        <div class="col-sm-8">
                            <h3 data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.ABSENDER_INFO"></h3>
                            <p ng-bind="bulkEmail.replyToAddress"></p>
                            <p ng-bind="bulkEmail.replyToName"></p>
                        </div>
                    </div>
                    <div class="row normal-abstand-top">
                        <div class="col-sm-12">
                            <h3 data-translate="COMMUNICATION.EMAIL.CONTENT"></h3>
                            <div ng-if="bulkEmail.html" class="truncate"
                                 ng-bind-html="bulkEmail.html">
                            </div>
                            <div ng-if="!bulkEmail.html" class="truncate"
                                 ng-bind="bulkEmail.plainText">
                            </div>
                        </div>
                    </div>
                </panel-content>

                <panel-footer>
                    <ul class="list-inline list-inline-row">
                        <li>
                            <button type="button"
                                    class="btn btn-link"
                                    ng-disabled="vm.loadingKontaktpersonen"
                                    ng-click="vm.openEmpfaengerDialog(bulkEmail)"
                                    data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.SEE_RECIPIENTS">
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    class="btn btn-link"
                                    ng-disabled="vm.loadingKontaktpersonen"
                                    ng-click="vm.sendEmails(bulkEmail)"
                                    data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.SEND_MAIL">
                            </button>
                        </li>
                        <li>
                            <dvlib-spinner [show]="vm.loadingKontaktpersonen"/>
                        </li>
                    </ul>
                </panel-footer>
            </dvb-accordion>
        </page-items>
    </dvb-pages>
</div>



