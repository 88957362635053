<div class="bank-statement-entry-assign modal dvb-modal-window">
    <div class="dvb-modal-container">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <div class="modal-body" dvb-download>
                    <h1 data-translate="FAKTURA.KONTENABGLEICH.CONFIRM_TRANSAKTION_ZUWEISEN"></h1>

                    <div class="text-left">
                        <div class="row no-margin">
                            <div class="col-xs-offset-1 col-xs-11">
                                <div class="row">
                                    <div class="col-xs-4">
                                        <p>
                                            <strong ng-bind="vm.bankStatementEntry.localDateTime | amDateFormat:'D. MMMM YYYY'">
                                            </strong>
                                        </p>
                                    </div>
                                    <div class="col-xs-6">
                                        <p>
                                            <strong ng-bind="vm.bankStatementEntry.referenzNummer | esr"></strong>
                                        </p>
                                    </div>
                                    <div class="col-xs-2 small-padding-right">
                                        <p class="pull-right">
                                            <strong data-translate="COMMON.BETRAG_VALUE"
                                                    data-translate-value-betrag="{{vm.bankStatementEntry.betrag | number:2}}">
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="normal-abstand-top">
                            <dvb-rechnungen-list rechnungen="[vm.rechnung]"
                                                 mode="vm.mode">
                            </dvb-rechnungen-list>
                        </div>

                        <div ng-if="vm.showWarning" class="normal-abstand-top">
                            <h3 data-translate="FAKTURA.HINWEIS"></h3>

                            <p data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTION_ZUWEISEN_WARNING"
                               data-translate-value-betrag="{{-vm.ausstehenderBetrag | number:2}}">
                            </p>
                        </div>
                    </div>

                    <form name="bankStatementEntryZuweisenForm"
                          ng-submit="!bankStatementEntryZuweisenForm.$pending && vm.submit()"
                          class="dvb-form big-abstand-top"
                          novalidate>
                        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                                   submit-label="COMMON.FORTFAHREN"
                                                   on-cancel="vm.goBack()">
                        </dvb-submit-cancel-buttons>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
