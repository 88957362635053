/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kind} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {KindBringAbholZeitenAccess} from './KindBringAbholZeitenAccess';

export class BringAbholZeitenState {

    public constructor(
        public emailMissing: Persisted<Kind>[] = [],
        public readyToSend: Persisted<Kind>[] = [],
        public withExistingEmails: KindBringAbholZeitenAccess[] = [],
        public withoutFlexiblePlaetze: Persisted<Kind>[] = [],
        public withoutFlexiblePlaetzeExistingMonatsBelegung: Persisted<Kind>[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BringAbholZeitenState {

        const emailMissing = data.emailMissing.map((d: any) => Kind.apiResponseTransformer(d));
        const readyToSend = data.readyToSend.map((d: any) => Kind.apiResponseTransformer(d));
        const withExistingEmail = data.withExistingEmails
            .map((d: any) => KindBringAbholZeitenAccess.apiResponseTransformer(d));
        const withoutFlexiblePlaetze = data.withoutFlexiblePlaetze
            .map((d: any) => Kind.apiResponseTransformer(d));
        const withoutFlexiblePlaetzeExistingMonatsBelegung = data.withoutFlexiblePlaetzeExistingMonatsBelegung
            .map((d: any) => Kind.apiResponseTransformer(d));

        return new BringAbholZeitenState(
            emailMissing,
            readyToSend,
            withExistingEmail,
            withoutFlexiblePlaetze,
            withoutFlexiblePlaetzeExistingMonatsBelegung);
    }
}
