/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export enum ZeitraumErrorTypes {
    HAS_MISSING_ANZ_VALUE = 'ERR_ANZ_EMPTY_MAX_NOT',
    VALUE_TOO_SMALL = 'ERR_VALUE_TOO_SMALL',
    INVALID_KAPAZITAETS_VALUE = 'ERR_PLAETZE_VALUE_UNGUELTIG',
    INVALID_PLAETZE_BY_DAY = 'PLAETZE_CALCULATION_FAILED',
}
