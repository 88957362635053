/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FirmenKontingentValue} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firmenKontingentValue: '<',
    },
    template: require('./dvb-firma-kontingent-value-title.html'),
    controllerAs: 'vm',
};

export class DvbFirmaKontingentValueTitle implements angular.IController {

    public firmenKontingentValue!: Persisted<FirmenKontingentValue>;

    public title: string = '';
    public preposition: string = '';
    public today: moment.Moment = DvbDateUtil.today();

    public $onChanges(changes: IOnChangesObject): void {
        if (changes.firmenKontingentValue) {
            this.title = DvbDateUtil.isGueltigOn(this.firmenKontingentValue, this.today) ?
                'COMMON.AKTUELLE_KAPAZITAET_MIT_DATUMPREP' :
                'COMMON.KAPAZITAET_MIT_DATUMPREP';
            this.preposition = DvbDateUtil.getTemporalPrepositionKey(this.firmenKontingentValue, this.today) ?? '';
        }
    }
}

componentConfig.controller = DvbFirmaKontingentValueTitle;
angular.module('kitAdmin').component('dvbFirmaKontingentValueTitle', componentConfig);

