/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import type {OffenerPostenType} from '@dv/shared/backend/model/offener-posten-type';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        zahlungType: '<',
        ausstehenderBetrag: '<',
        rechnung: '<',
    },
    template: require('./dvb-zahlung-ausgleichen.html'),
    controllerAs: 'vm',
};

export class DvbZahlungAusgleichen implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'fakturaService'];

    public zahlungType!: OffenerPostenType;
    public ausstehenderBetrag!: number;
    public rechnung!: Persisted<Rechnung>;

    public date: moment.Moment = DvbDateUtil.today();
    public isLoading: boolean = false;

    public constructor(
        private readonly $state: StateService,
        private readonly fakturaService: FakturaService,
    ) {
    }

    public $onInit(): void {
        if (!this.zahlungType) {
            throw new Error('Attribute zahlungType is required');
        }
    }

    public submit(): void {
        this.isLoading = true;

        const zahlung = this.fakturaService.initAusgleichszahlung(
            this.date,
            this.zahlungType,
            -this.ausstehenderBetrag,
            checkPresent(this.rechnung.kitaId));

        this.fakturaService.createZahlung(this.rechnung.id, zahlung)
            // reloading state so rechnung is refreshed -> the rechnungsUeberschussTask was removed
            .then(() => this.$state.reload())
            .then(() => this.goBack())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goBack(): void {
        this.$state.go('base.rechnung.zahlungen');
    }
}

componentConfig.controller = DvbZahlungAusgleichen;
angular.module('kitAdmin').component('dvbZahlungAusgleichen', componentConfig);
