/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CustomFieldFilterOption} from '../../service/kinderFilter-models/CustomFieldFilterOption';

export class CustomFieldsFilter {
    public filterOptions: CustomFieldFilterOption[] = [];

    public hasSelected(): boolean {
        return this.filterOptions.some(o => o.selected);
    }

    public hasSticky(): boolean {
        return this.filterOptions.some(o => o.sticky);
    }

    public hasUnsticky(): boolean {
        return this.filterOptions.some(o => !o.sticky);
    }
}
