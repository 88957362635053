/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import angular from 'angular';
import {SchliesstagDateRange} from '../../models/SchliesstagDateRange';
import type {Schliesstage} from '../../models/Schliesstage';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        schliesstage: '<',
        isLoading: '<',
        onSubmit: '&',
        onCancel: '&',
    },
    template: require('./dvb-schliesstage-form.html'),
    controllerAs: 'vm',
};

export class DvbSchliesstageForm {
    public static $inject: readonly string[] = ['errorService'];

    public schliesstage!: Schliesstage;
    public isLoading!: boolean;
    public onSubmit!: () => void;
    public onCancel!: () => void;

    public constructor(
        private errorService: ErrorService,
    ) {
    }

    public submit(form: angular.IFormController): void {
        if (this.validateSchliesstag(form)) {
            this.onSubmit();
        }
    }

    public addSchliesstagDateRange(): void {
        this.schliesstage.addSchliesstagDateRange(new SchliesstagDateRange());
    }

    public removeSchliesstagDateRange(schliesstagDateRange: SchliesstagDateRange): void {
        this.schliesstage.removeSchliesstagDateRange(schliesstagDateRange);
    }

    private validateSchliesstag(form: angular.IFormController): boolean {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const validSchliesstage = this.schliesstage.isValid();
        this.errorService.handleValidationError(validSchliesstage, 'ERRORS.ERR_SCHLIESSTAG_FORM');

        return form.$valid && validSchliesstage;
    }
}

componentConfig.controller = DvbSchliesstageForm;
angular.module('kitAdmin').component('dvbSchliesstageForm', componentConfig);
