/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AbstractPagesController} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {JaxBulkEmailFilter} from '@dv/shared/backend/model/jax-bulk-email-filter';
import type {RestPaginated} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, Gueltigkeit} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Subscription} from 'rxjs';
import {firstValueFrom} from 'rxjs';
import {PopoverFilterOption} from '../../../filter/popover/PopoverFilterOption';
import {PopoverFilterPropertyGueltigkeit} from '../../../filter/popover/PopoverFilterPropertyGueltigkeit';
import * as states from '../../communication-states';
import type {BulkEmailInfo} from '../../models/bulkemails/BulkEmailInfo';
import type {BulkEmailService} from '../../service/bulk-email.service';
import type {
    BulkEmailEmpfaengerDialogModel,
} from '../bulk-email-recipients-dialog/bulk-email-empfaenger-dialog.component';
import {
    BulkEmailEmpfaengerDialogComponent,
} from '../bulk-email-recipients-dialog/bulk-email-empfaenger-dialog.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    template: require('./dvb-bulk-email-history.html'),
    controllerAs: 'vm',
};

export class DvbBulkEmailHistory extends AbstractPagesController<BulkEmailInfo> implements angular.IController {

    public static override $inject: readonly string[] = [
        'bulkEmailService',
        'dialogService',
        '$state',
    ];

    public textSuche?: string;
    public versandPeriodeFilter = new PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>(
        'versandPeriodeFilter',
        new PopoverFilterPropertyGueltigkeit(new Gueltigkeit(DvbDateUtil.startOfYear(), DvbDateUtil.endOfYear())),
    );
    public loadingKontaktpersonen = false;

    public request?: Subscription;

    public constructor(
        private readonly bulkEmailService: BulkEmailService,
        private readonly dialogService: DialogService,
        private $state: StateService,
    ) {
        super();
    }

    public override $onInit(): void {
        this.reloadItems();
    }

    public reloadItems(): void {
        this.isLoading = true;

        const params: RestPaginated = {};
        this.setPagionationValues(params);

        if (this.request) {
            this.request.unsubscribe();
        }

        this.request = this.bulkEmailService.getFilteredBulkEmails$({
                jaxBulkEmailFilter: {textSuche: this.textSuche, ...this.getGueltigkeit()}, history: params,
            },
        ).subscribe({
            next: pageContainer => {
                this.pageContainer = pageContainer;
            },
            complete: () => {
                this.isLoading = false;
            },
        });
    }

    public applyVersandDatumFilter(): void {
        this.versandPeriodeFilter.selected = true;
        this.reloadItems();
    }

    public openEmpfaengerDialog(bulkEmail: BulkEmailInfo): void {
        this.loadingKontaktpersonen = true;
        firstValueFrom(this.bulkEmailService.getReceivers$(bulkEmail.id)).then(kontaktpersonen => {
            this.loadingKontaktpersonen = false;
            const dialogModel: BulkEmailEmpfaengerDialogModel = {kontaktpersonen};
            this.dialogService.openDialog(BulkEmailEmpfaengerDialogComponent, dialogModel);
        });
    }

    public sendEmails(bulkEmail: BulkEmailInfo): void {
        this.loadingKontaktpersonen = true;
        firstValueFrom(this.bulkEmailService.getReceivers$(bulkEmail.id)).then(kontaktpersonen => {
            this.loadingKontaktpersonen = false;
            this.$state.go(states.BULK_EMAIL_MANAGEMENT_STATE.name,
                {bulkEmailInfo: bulkEmail, kontaktpersonen});
        });
    }

    private getGueltigkeit(): Pick<JaxBulkEmailFilter, 'versandVon' | 'versandBis'> {
        if (!this.versandPeriodeFilter.selected) {
            return {};
        }

        const gueltigkeit = this.versandPeriodeFilter.popoverModel.gueltigkeit;
        const versandVon = DvbRestUtil.momentToLocalDate(gueltigkeit.gueltigAb) ?? undefined;
        const versandBis = DvbRestUtil.momentToLocalDate(gueltigkeit.gueltigBis) ?? undefined;

        return {versandVon, versandBis};
    }
}

componentConfig.controller = DvbBulkEmailHistory;
angular.module('kitAdmin').component('dvbBulkEmailHistory', componentConfig);
