<div class="dvb-kita-or-fraktion-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.kitaOrFraktionReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT"
                                      ng-change="vm.updateKinderOrt()"
                                      ng-model="vm.kinderOrt"
                                      ng-required="!vm.gruppeSearchResultEntry.id"
                                      ng-show="!vm.gruppeSearchResultEntry.id"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
        </dvb-search-get-single-entity>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT_FRAKTION"
                                      ng-model="vm.gruppeSearchResultEntry"
                                      ng-required="!vm.kinderOrt"
                                      ng-show="!vm.kinderOrt"
                                      placeholder-key="COMMON.GRUPPE.AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>


        <div class="row form-row">
            <div class="col-xs-12">
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.gueltigAb"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.gueltigBis"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
