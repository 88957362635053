<div class="dvb-benutzer-rechte row">
    <div class="col-md-7">

        <div class="big-abstand-bottom">
            <dvb-standard-recht benutzer="vm.benutzer"
                                kita-permissions="vm.kitaPermissions"
                                read-only="vm.isStandardReadOnly"
                                on-update="vm.updateBenutzer()">
            </dvb-standard-recht>
        </div>

        <div class="big-abstand-bottom">
            <dvb-kita-rechte benutzer="vm.benutzer"
                             kita-permissions="vm.kitaPermissions"
                             ng-if="!vm.hasPrivilegedRole"
                             read-only="vm.isKitaReadOnly">
            </dvb-kita-rechte>
        </div>

        <dvb-granular-kinder-ort-permissions benutzer="vm.benutzer"
                                             permissions="vm.granularKinderOrtPermissions"
                                             read-only="vm.isKitaReadOnly">
        </dvb-granular-kinder-ort-permissions>

    </div>
</div>
