<div class="row kindbetreuung dvb-form">

    <div class="col-md-7 big-abstand-bottom">
        <h2 data-translate="KIND.BETREUUNGSVERLAUF"></h2>

        <!-- BELEGUNGEN -->
        <dvb-betreuungsverlauf kind="vm.kind"
                               expand-ab="vm.expandAb"
                               expand-bis="vm.expandBis"
                               show-line-top="true"
                               is-date-clickable="false"
                               on-edit-belegung="vm.editBelegung(belegung.gueltigAb, belegung)"
                               on-belegung-removed="vm.onBelegungRemoved()">
        </dvb-betreuungsverlauf>

        <!-- Buttons Zuweisen/Austritt -->
        <ul class="list-inline flex-list big-abstand-top hidden-print"
            dvb-auth
            require-permission="kita:view_plus:general">

            <li dvb-auth
                require-permission="kita:manage:general"
                require-condition="vm.zuweisungsKitas && vm.zuweisungsKitas.length > 0">

                <div ng-if="vm.zuweisungsKitas.length > 1" class="btn-group" uib-dropdown data-keyboard-nav>
                    <button type="button"
                            class="btn btn-primary dropdown-toggle"
                            uib-dropdown-toggle>
                        <span ng-if="vm.kind.belegungen && vm.kind.belegungen.length > 0"
                              data-translate="COMMON.ZUWEISUNG_ANPASSEN"></span>
                        <span ng-if="!vm.kind.belegungen || vm.kind.belegungen.length === 0"
                              data-translate="COMMON.KIND_ZUWEISEN"></span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                        <li ng-click="vm.zuweisen(kita, null)" ng-repeat="kita in vm.zuweisungsKitas">
                            <a href="" ng-bind="kita.getDisplayName()"></a>
                        </li>
                        <li ng-click="vm.assignAll()">
                            <a href="" data-translate="COMMON.ALLE"></a>
                        </li>
                    </ul>
                </div>

                <div ng-if="vm.zuweisungsKitas.length === 1" class="btn-group" uib-dropdown>
                    <button type="button" class="btn btn-primary" ng-click="vm.zuweisen(vm.zuweisungsKitas[0])">
                        <span ng-if="vm.kind.belegungen && vm.kind.belegungen.length > 0"
                              data-translate="COMMON.ZUWEISUNG_ANPASSEN">
                        </span>
                        <span ng-if="!vm.kind.belegungen || vm.kind.belegungen.length === 0"
                              data-translate="COMMON.KIND_ZUWEISEN">
                        </span>
                    </button>
                </div>
            </li>

            <li dvb-auth require-permission="module:dynamischer_belegungsplan">
                <a ui-sref="base.kind.extra-platz"
                   data-translate="KIND.EXTRA_DAY.EDIT_MF"
                   data-translate-value-count="vm.kind.extraPlaetze.length"
                   data-translate-interpolation="messageformat">
                </a>
            </li>

            <li>
                <a href=""
                   dvb-auth
                   require-permission="kita:manage:general"
                   require-condition="vm.kind.belegungen.length > 0"
                   data-translate="KIND.AUSTRITT"
                   ng-click="vm.austritt()">
                </a>
            </li>
        </ul>

    </div>

    <div class="col-md-4 col-md-offset-1">
        <div dvb-auth require-permission="{{ vm.TAGESINFO_STATE.data.permission }}" class="big-abstand-bottom hidden-print">
            <h2 data-translate="CHECK_IN.TAGESINFO.SECTION.TAGESINFO"></h2>
            <a ui-sref="{{ vm.TAGESINFO_STATE.name }}" data-translate="CHECK_IN.TAGESINFO.NAV"></a>
        </div>

        <div dvb-auth require-permission="kita:control:general"
             class="big-abstand-bottom"
             ng-class="{'hidden-print': !vm.bewerbung}">
            <div class="wunsch-title">
                <h2 data-translate="COMMON.BETREUUNGSWUNSCH"></h2>
                <a href=""
                   class="subtitle"
                   dvb-auth
                   require-permission="kita:manage:general"
                   require-condition="vm.bewerbung"
                   ng-click="vm.confirmDeleteBewerbung(vm.bewerbung)"
                   data-translate="COMMON.LOESCHEN">
                </a>
            </div>

            <div ng-show="vm.bewerbung">
                <dvb-datepicker-text-field class="small-abstand-bottom"
                                           ng-model="vm.bewerbung.gewuenschteBetreuungAb"
                                           click-to-edit="true"
                                           on-submit="vm.updateBewerbung()"
                                           is-valid="vm.isValidBetreuungVonDatum(param)"
                                           ng-attr-placeholder="{{'KIND.BETREUUNG_AB' | translate}}"
                                           dvb-auth
                                           require-permission="kita:manage:general"
                                           on-unauthorised-disable>
                </dvb-datepicker-text-field>

                <div class="form-row">
                    <div class="row selected-entity"
                         ng-repeat="kita in vm.bewerbung.kitas"
                         ng-init="searchResultEntry = kita.toSearchResultEntry()">
                        <div class="col-xs-9 entity">
                            <a ui-sref="base.kinderort.kinder.warteliste({id: kita.id})">
                                <dvb-search-result-icon entry="searchResultEntry"></dvb-search-result-icon>
                                <span ng-bind="kita.getDisplayName()"></span>
                            </a>
                        </div>
                        <div class="col-xs-3 edit" dvb-auth require-permission="kita:manage:general">
                            <a href=""
                               ng-show="vm.bewerbung.kitas.length > 1"
                               ng-click="vm.removeKita(kita)"
                               data-translate="COMMON.LOESCHEN">
                            </a>
                        </div>
                    </div>

                    <div dvb-auth require-permission="kita:manage:general">
                        <dvb-search-on-click-entity add-label-key="KIND.KITA_HINZUFUEGEN"
                                                    entity-to-search="KINDERORT"
                                                    on-select="vm.addKita(item)"
                                                    on-select-clear="true"
                                                    disabled-entries="vm.bewerbung.kitas"
                                                    placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                        </dvb-search-on-click-entity>
                    </div>
                </div>

                <div class="form-row">
                    <h3 ng-bind="vm.wochenplan.getDisplayName()"></h3>
                    <dvb-wochenplan distinct-betreuungs-zeitraeume="vm.distinctZeitraeume"
                                    week-days="vm.enabledDays"
                                    interactive="true"
                                    show-null-value-as-plain="true"
                                    on-zeitraum-feld-clicked="vm.handleZeitraumFeldToggle(zeitraumFeld)"
                                    zeitraum-felder="vm.zeitraumFelder"
                                    show-betreuungs-zeitraum-name="vm.wochenplan.showBetreuungsZeitraumName"
                                    dvb-auth
                                    require-permission="kita:manage:general"
                                    on-unauthorised-disable>
                    </dvb-wochenplan>

                </div>
                <div class="form-row">
                    <dvb-editable-pensum ng-model="vm.bewerbung.pensum"
                                         on-submit="vm.updateBewerbung()"
                                         dvb-auth
                                         require-permission="kita:manage:general"
                                         on-unauthorised-disable>
                    </dvb-editable-pensum>
                </div>

                <div class="row" dvb-auth require-permission="kita:control:general">
                    <div class="col-xs-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="true"
                                       ng-model="vm.bewerbung.subventionierterPlatz"
                                       ng-change="vm.updateBewerbung()"
                                       dvb-auth require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <span data-translate="COMMON.SUBVENTIONIERTER_PLATZ"></span>
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="true"
                                       ng-model="vm.bewerbung.privaterPlatz"
                                       ng-change="vm.updateBewerbung()"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <span data-translate="COMMON.PRIVATER_PLATZ"></span>
                            </label>
                        </div>
                        <div class="checkbox" ng-repeat="firma in vm.bewerbung.firmenSelection">
                            <label>
                                <input type="checkbox"
                                       value="vm.firma.id"
                                       ng-model="firma.selected"
                                       ng-change="vm.toggleFirma(firma)"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <span data-translate="COMMON.PLATZART.TITLE"
                                      data-translate-value-name="{{firma.name}}"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-row" dvb-auth require-permission="kita:manage:general">
                    <dvb-search-on-click-entity entity-to-search="FIRMA"
                                                on-select="vm.addFirma(item)"
                                                on-select-clear="true"
                                                disabled-entries="vm.bewerbung.firmenSelection"
                                                add-label-key="COMMON.PLATZART.HINZUFUEGEN"
                                                placeholder-key="COMMON.PLATZART.AUSWAEHLEN">
                    </dvb-search-on-click-entity>
                </div>

                <div class="row form-row">
                    <div class="col-xs-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.bewerbung.kindergarten"
                                       ng-change="vm.updateBewerbung()"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <span data-translate="COMMON.KINDERGARTEN"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <dvb-bewerbung-prioritaet prioritaet="vm.bewerbung.prioritaet"
                                          on-change="vm.bewerbung.prioritaet = prioritaet; vm.updateBewerbung()"
                                          layout="column">
                </dvb-bewerbung-prioritaet>
            </div>

            <div ng-show="!vm.bewerbung">
                <div class="form-section-minus-normal-abstand row">
                    <div class="col-xs-12" data-translate="COMMON.KEIN_WUNSCH_OFFEN"></div>
                </div>

                <div class="form-section-minus-normal-abstand" dvb-auth require-permission="kita:manage:general">
                    <div class="form-row">
                        <a ui-sref="base.kind.neue-bewerbung({kindId: vm.kind.id})">
                            + <span data-translate="KIND.BETREUUNGSWUNSCH_HINZUFUEGEN"></span>
                        </a>
                    </div>
                    <div class="form-row">
                        <dvb-search-on-click-entity add-label-key="KIND.KIND_DIREKTZUWEISUNG_LINK"
                                                    entity-to-search="KINDERORT"
                                                    on-select="vm.kindDirektZuweisen(item)"
                                                    on-select-clear="true"
                                                    placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                        </dvb-search-on-click-entity>
                    </div>
                </div>
            </div>
        </div>

        <h2 data-translate="KIND.BETREUUNGSGRUND"></h2>

        <dvb-betreuungsgrund betreuungsgruende-ids="vm.kind.betreuungsGruendeIds"
                             on-change="vm.saveBetreuungsGruende()">
        </dvb-betreuungsgrund>
    </div>
</div>
