/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Platz} from '@dv/kitadmin/models';
import {Belegung, Kind} from '@dv/kitadmin/models';
import type {IDisplayable, Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, isPresent, Korrektur} from '@dv/shared/code';
import type moment from 'moment';
import {KindBetreuungErfassung} from './KindBetreuungErfassung';

export class KindMonatlicheStundenErfassung implements IDisplayable {

    // optional parameters for form
    public tagesDaten?: KindBetreuungErfassung[];
    public totalStunden?: Korrektur<number>;

    public constructor(
        public kind: Persisted<Kind>,
        public kindWochenBelegungen: Belegung[] = [],
        public betreuungen: KindBetreuungErfassung[],
        public vertraglicheStunden: number | null = null,
    ) {
    }

    public static hasKindBetreuungErfassung(kind: KindMonatlicheStundenErfassung, stichtag: moment.Moment): boolean {
        return kind.betreuungen.some(b => stichtag.isSame(b.stichtag));
    }

    public static hasKindBetreuungErfassungWithValue(
        kind: KindMonatlicheStundenErfassung,
        stichtag: moment.Moment,
    ): boolean {
        return kind.betreuungen.some(b => stichtag.isSame(b.stichtag) && b.stunden.hasValue());
    }

    public static hasBelegung(kind: KindMonatlicheStundenErfassung, stichtag: moment.Moment): boolean {
        const belegung = DvbDateUtil.getEntityOn(kind.kindWochenBelegungen, stichtag);

        return isPresent(belegung);
    }

    public static hasBelegungPlatz(kind: KindMonatlicheStundenErfassung, stichtag: moment.Moment): boolean {
        const belegung = DvbDateUtil.getEntityOn(kind.kindWochenBelegungen, stichtag);

        return isPresent(belegung) && this.hasPlatz(belegung, stichtag);
    }

    public static hasPlatz(belegung: Belegung, stichtag: moment.Moment): boolean {
        return belegung.gruppenBelegungen?.some(gb => gb.plaetze.some(p => this.hasPlatzAtStichtag(p, stichtag)));
    }

    public static hasPlatzAtStichtag(p: Platz, stichtag: moment.Moment): boolean {
        return DvbDateUtil.isSameDayOfWeek(p.wochentag!, stichtag);
    }

    public static apiResponseTransformer(data: any): KindMonatlicheStundenErfassung {
        return new KindMonatlicheStundenErfassung(
            Kind.apiResponseTransformer(data.kind),
            data.kindWochenBelegungen.map((b: any) => Belegung.apiResponseTransformer(b.belegung)),
            data.betreuungen.map((b: any) => KindBetreuungErfassung.apiResponseTransformer(b)),
            data.vertraglicheStunden,
        );
    }

    public isAffected(stichtag: moment.Moment): boolean {
        return KindMonatlicheStundenErfassung.hasKindBetreuungErfassung(this, stichtag)
            || KindMonatlicheStundenErfassung.hasBelegungPlatz(this, stichtag);
    }

    /**
     * Returns the KindBetreuungErfassung entries for the given date.
     * If there is existing data, it is returned, even when there is no longer a GruppenBelegung for it.
     * Automatically adds KindBetreuungErfassungen for any fraktion where a GruppenBelegung exists (where thus some
     * input data can be expected).
     */
    public getKindBetreuungErfassungen(stichtag: moment.Moment): KindBetreuungErfassung[] {
        const existing = this.betreuungen.filter(b => stichtag.isSame(b.stichtag));
        const existingFraktionIds = existing.map(e => e.kinderOrtFraktionId);

        const belegung = DvbDateUtil.getEntityOn(this.kindWochenBelegungen, stichtag);
        const belegungFraktionIds = (belegung?.gruppenBelegungen ?? []).map(gb => checkPresent(gb.gruppeId));
        const missingFraktionIds = belegungFraktionIds.filter(id => !existingFraktionIds.includes(id));

        return existing.concat(missingFraktionIds.map(id => new KindBetreuungErfassung(null, id, stichtag)));
    }

    /**
     * initializes the totalStunden based on the tagesdaten.
     */
    public updateTagesStunden(): void {
        this.totalStunden = (this.tagesDaten ?? [])
            .map(k => k.stunden)
            .reduce((accumulator, currentValue) => {
                if (isPresent(currentValue.current)) {
                    accumulator.current = (accumulator.current ?? 0) + currentValue.current;
                }

                if (isPresent(currentValue.original)) {
                    accumulator.original = (accumulator.original ?? 0) + currentValue.original;
                }

                return accumulator;
            }, new Korrektur());
    }

    public getDisplayName(): string {
        return this.kind.getDisplayName();
    }
}
