/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {ReportState} from '../../models';
import {ERR_VALIDATION, ParameterKinderOrte} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<?',
    },
    template: require('./dvb-report-depots.html'),
    controllerAs: 'vm',
};

export class DvbReportDepots {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public parameterKinderOrte: ParameterKinderOrte = new ParameterKinderOrte();
    public reportTemplate: ReportTemplate | null = null;

    public reportDepotsForm!: angular.IFormController;

    public stichtag: moment.Moment = moment();
    public includeErstattet: boolean = false;
    public erstattetVon: moment.Moment | null = null;
    public erstattetBis: moment.Moment | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        this.errorService.clearAll();
        const isValid = this.reportDepotsForm.$valid && this.parameterKinderOrte.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    private getDownloadPromise(): angular.IPromise<TempBlob> {
        const params = angular.copy(this.parameterKinderOrte);

        return this.reportService.getTempBlobForDepotsReport(params, {
            stichtag: this.stichtag,
            includeErstattet: this.includeErstattet,
            erstattetVon: this.erstattetVon,
            erstattetBis: this.erstattetBis,
            reportTemplate: this.reportTemplate,
        });
    }
}

componentConfig.controller = DvbReportDepots;
angular.module('kitAdmin').component('dvbReportDepots', componentConfig);
