/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {ErrorLevel, ErrorType, HttpCodes, HttpErrorUtil} from '@dv/shared/code';
import type angular from 'angular';

export class HttpErrorInterceptor implements angular.IHttpInterceptor {
    public static $inject: readonly string[] = ['$q', 'errorService'];

    public constructor(
        private readonly $q: angular.IQService,
        private readonly errorService: ErrorService,
    ) {
        this.responseError = this.responseError.bind(this);
    }

    /**
     * Determines if the response was aborted manually.
     */
    private static isAborted(response: Pick<angular.IHttpResponse<any>, 'status' | 'data' | 'xhrStatus'>): boolean {
        return response?.xhrStatus && response.xhrStatus === 'abort';
    }

    // eslint-disable-next-line complexity
    public responseError(response: Pick<angular.IHttpResponse<any>, 'status' | 'data' | 'xhrStatus'>): angular.IPromise<angular.IHttpResponse<any>> {

        switch (response.status) {
            case HttpCodes.BAD_REQUEST: {
                const error400 = HttpErrorUtil.toDvbError(
                    ErrorType.BADREQUEST, ErrorLevel.SEVERE, 'ERRORS.ERR_BADREQUEST',
                    response.data);
                this.errorService.handleError(error400);

                return this.$q.reject(error400);
            }
            case HttpCodes.NOT_FOUND: {
                const error404 = HttpErrorUtil.toDvbError(
                    ErrorType.INTERNAL, ErrorLevel.SEVERE, 'ERRORS.ERR_BADREQUEST',
                    response.data);
                this.errorService.handleError(error404);

                return this.$q.reject(error404);
            }
            case HttpCodes.INTERNAL_SERVER_ERROR: {
                const error500 = HttpErrorUtil.toDvbError(
                    ErrorType.INTERNAL, ErrorLevel.SEVERE, 'ERRORS.ERR_INTERNAL');
                this.errorService.handleError(error500);

                return this.$q.reject(error500);
            }
            case HttpCodes.CONFLICT: {
                // Momentan haben wir noch kein intelligentes Handling von Backend Fehlern.
                const error409 = HttpErrorUtil.toDvbError(
                    ErrorType.INTERNAL, ErrorLevel.SEVERE, 'ERRORS.ERR_INTERNAL',
                    response.data);
                this.errorService.handleError(error409);

                return this.$q.reject(error409);
            }
            case HttpCodes.SERVICE_UNAVAILABLE: {
                const error503 = HttpErrorUtil.toDvbError(
                    ErrorType.INTERNAL, ErrorLevel.SEVERE, 'ERRORS.ERR_SERVICE_UNAVAILABLE');
                this.errorService.handleError(error503);

                return this.$q.reject(error503);
            }
            case HttpCodes.UNKOWN:
            case HttpCodes.BAD_GATEWAY:
            case HttpCodes.GATEWAY_TIMEOUT: {
                if (HttpErrorInterceptor.isAborted(response)) {
                    // Requests that were purposefully aborted by app code should not produce error messages.
                    return this.$q.reject(HttpErrorUtil.toDvbError(
                        ErrorType.ABORT, ErrorLevel.INFO, 'ERRORS.ERR_REQUEST_ABORTED',
                        response.data));
                }

                const abortError = HttpErrorUtil.toDvbError(
                    ErrorType.BADREQUEST, ErrorLevel.SEVERE, 'ERRORS.ERR_REQUEST_ABORTED');
                this.errorService.handleError(abortError);

                return this.$q.reject(response);
            }
            default:
                return this.$q.reject(response);
        }
    }
}
