/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';

/**
 * Adapted from https://github.com/witoldsz/angular-http-auth
 */
export class HttpBuffer {
    public static $inject: readonly string[] = ['$injector'];

    /**
     * Holds all the requests, so they can be re-requested in future.
     */
    private buffer: { config: angular.IRequestConfig; deferred: angular.IDeferred<unknown> }[] = [];

    /**
     * Service initialized later because of circular dependency problem.
     */
    private $http?: angular.IHttpService;

    public constructor(private $injector: angular.auto.IInjectorService) {
    }

    /**
     * Appends HTTP request configuration object with deferred response attached to buffer.
     */
    public append(config: angular.IRequestConfig, deferred: angular.IDeferred<unknown>): void {
        this.buffer.push({
            config,
            deferred,
        });
    }

    /**
     * Abandon or reject (if reason provided) all the buffered requests.
     */
    public rejectAll(reason: unknown): void {
        if (reason) {
            for (const req of this.buffer) {
                req.deferred.reject(reason);
            }
        }

        this.buffer = [];
    }

    /**
     * Retries all the buffered requests clears the buffer.
     */
    public retryAll(updater: (config: angular.IRequestConfig) => angular.IRequestConfig): void {
        for (const req of this.buffer) {
            this.retryHttpRequest(updater(req.config), req.deferred);
        }

        this.buffer = [];
    }

    private retryHttpRequest(config: angular.IRequestConfig, deferred: angular.IDeferred<unknown>): void {
        this.$http = this.$http ?? this.$injector.get('$http');

        this.$http(config)
            .then((response: angular.IHttpResponse<unknown>) => deferred.resolve(response))
            .catch(error => deferred.reject(error));
    }
}
