/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DialogService} from '@dv/kitadmin/ui';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {BetreuungsSchluessel} from '../../betreuungs-schluessel/models/BetreuungsSchluessel';
import type {BetreuungsSchluesselService} from '../../betreuungs-schluessel/service/betreuungsSchluesselService';
import {BETREUUNGS_SCHLUESSEL_FORM_STATE} from '../../personal-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsSchluessel: '<',
    },
    template: require('./dvb-betreuungs-schluessel-overview.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsSchluesselOverview implements angular.IController {
    public static $inject: readonly string[] = [
        'betreuungsSchluesselService',
        'dvbStateService',
        'dialogService',
    ];

    public betreuungsSchluessel!: BetreuungsSchluessel;

    public editState = BETREUUNGS_SCHLUESSEL_FORM_STATE;

    public constructor(
        private betreuungsSchluesselService: BetreuungsSchluesselService,
        private dvbStateService: DvbStateService,
        private dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        this.betreuungsSchluessel.sortRegeln();
    }

    public delete(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'PERSONAL.BETREUUNGS_SCHLUESSEL.SINGULAR',
            confirm: () => from(this.betreuungsSchluesselService.delete(this.betreuungsSchluessel))
                .pipe(take(1), tap(() => this.dvbStateService.goToPreviousState())),
        });
    }
}

componentConfig.controller = DvbBetreuungsSchluesselOverview;
angular.module('kitAdmin').component('dvbBetreuungsSchluesselOverview', componentConfig);
