/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ExternalAnmeldungCustomField} from '@dv/kitadmin/models';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

export class ExternalAnmeldungCustomFieldService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/externalanmeldung/customfields`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getAll(config?: angular.IRequestShortcutConfig): angular.IPromise<ExternalAnmeldungCustomField[]> {

        return DvbRestUtilAngularJS.getModelsArray(
            ExternalAnmeldungCustomFieldService.BASE_URL,
            ExternalAnmeldungCustomField,
            'items',
            {},
            !!config?.cache,
            config);
    }

    public update(
        customFields: ExternalAnmeldungCustomField[],
        config?: angular.IRequestShortcutConfig,
    ): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const body = {
            customFields: customFields.map(customField => customField.toRestObject()),
        };

        return this.$http.post(ExternalAnmeldungCustomFieldService.BASE_URL, body, config);
    }
}
