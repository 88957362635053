<div class="dvb-fraktion-erstellen row dvb-form">
    <div class="col-md-6 col-md-offset-3">

        <h1 data-translate="{{'COMMON.' + vm.dtype + '.ERSTELLEN'}}"></h1>

        <form name="fraktionErstellenForm"
              ng-submit="vm.validateAndCreateFraktion(fraktionErstellenForm.$valid)"
              novalidate>

            <div class="form-section">
                <dvb-search-get-single-entity entity-to-search="{{vm.kinderOrtDType}}"
                                              required
                                              ng-model="vm.kinderOrtSearchResultEntry"
                                              placeholder-key="{{'COMMON.' + vm.kinderOrtDType + '.AUSWAEHLEN'}}">
                </dvb-search-get-single-entity>
            </div>

            <div ng-switch="vm.dtype">
                <dvb-gruppe-erstellen ng-switch-when="GRUPPE" fraktion="vm.fraktion">
                </dvb-gruppe-erstellen>
                <dvb-betreuungsperson-erstellen ng-switch-when="BETREUUNGS_PERSON" fraktion="vm.fraktion">
                </dvb-betreuungsperson-erstellen>
            </div>

            <h2 data-translate="FRAKTION.KONFIGURATION"></h2>

            <div class="row">
                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                               required
                                               ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                    </dvb-datepicker-text-field>
                </div>
            </div>

            <div class="form-section">
                <div class="row">
                    <div class="col-md-6">
                        <select class="form-control"
                                uib-tooltip="{{'COMMON.WOCHENPLAN.SINGULAR' | translate}}"
                                tooltip-placement="top-left"
                                ng-model="vm.selectedWochenplan"
                                ng-options="wochenplan as wochenplan.name for wochenplan in vm.wochenplaene | orderBy:'name'"
                                required>
                        </select>
                    </div>
                </div>
                <dvb-fraktion-kapazitaet-form on-wochen-kapazitaet-change="vm.updateWochenKapazitaet(wochenKapazitaet)"
                                              display-mode="NO-DATE"
                                              wochenplan="vm.selectedWochenplan">
                </dvb-fraktion-kapazitaet-form>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button name="save" type="submit" is-loading="vm.isLoading">
                                <span data-translate="{{'COMMON.' + vm.dtype + '.ERSTELLEN'}}"></span>
                            </dvb-loading-button>
                        </li>
                        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.goBack()"></a></li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
