/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Belegung, KinderOrtFraktion, KinderOrtFraktionId, ZeitraumFeld} from '@dv/kitadmin/models';
import {ExtraPlatzCategory, ExtraPlatzType, ZeitraumUtil} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {TempExtraPlatzBuilder} from '../../common/service/tempExtraPlatzBuilder';
import {TempExtraPlatz} from '../../temp-extra-platz/TempExtraPlatz';
import type {ZuweisungPopoverHelper} from '../zuweisung';
import ClickEvent = JQuery.ClickEvent;

/**
 * Utility class for handling clicks on zeitraumfelder and associated decision-making.
 */
export class ExtraPlatzCreationUtil {

    public constructor(
        private tempExtraPlatzBuilder: TempExtraPlatzBuilder,
        private popoverHelper: ZuweisungPopoverHelper,
    ) {
    }

    /**
     * Determines what has to be done with the click.
     * Meaning whether to create or remove a temp platz based on the feld, existing temp plaetze and belegungen.
     * Open the popover when necessary.
     * Determine the type of tempPlatz when a platz has to be added.
     */
    public handleFeldClick(
        zeitraumFeld: ZeitraumFeld,
        fraktion: Persisted<KinderOrtFraktion>,
        firstOfWeek: moment.Moment,
        belegung: Belegung | null,
        currentPlaetzeFraktionen: Persisted<KinderOrtFraktion>[],
        event: ClickEvent,
    ): void {
        // The wochenbelegung contains pre-existing extra plaetze!
        // So the feld being part of the belegung means we are dealing with removal of an additional platz or creating
        // an absence
        const fraktionenWithBelegungen = this.findFraktionWithBelegungForFeld(belegung, fraktion, zeitraumFeld);
        if (fraktionenWithBelegungen.includes(fraktion.id)) {
            const existingTempPlatz = this.tempExtraPlatzBuilder.getTempPlatz(fraktion.id, zeitraumFeld, firstOfWeek);
            if (existingTempPlatz) {
                // the feld is part of a pre-existing extra platz
                const unremoved = !!existingTempPlatz.getUnremoved().some(f => f.equals(zeitraumFeld));
                if (unremoved) {
                    // the feld is still existing --> remove it
                    this.removeZeitraumFeldSelection(zeitraumFeld,
                        fraktion,
                        firstOfWeek,
                        existingTempPlatz.extraPlatzType,
                        currentPlaetzeFraktionen);
                } else if (existingTempPlatz.extraPlatzType === ExtraPlatzType.ADDITIONAL) {
                    // the feld has already been removed --> add it again
                    this.openExtraPlatzPopover(zeitraumFeld, fraktion, event);
                } else if (existingTempPlatz.extraPlatzType === ExtraPlatzType.ABSENCE) {
                    // not sure a copy is needed, but it's keeping earlier behaviour
                    const copy = TempExtraPlatz.copy(existingTempPlatz);
                    this.tempExtraPlatzBuilder.addTempExtraPlatz(copy, zeitraumFeld, zeitraumFeld.kontingent);
                }

                return;
            }

            this.createAbwesenheit(fraktion, zeitraumFeld, firstOfWeek);

            return;
        }

        if (zeitraumFeld.selected) {

            if (this.tempExtraPlatzBuilder.isRemoved(fraktion.id, zeitraumFeld, firstOfWeek)) {
                // pre-existing absence that flagges for removal --> add it again
                this.createAbwesenheit(fraktion, zeitraumFeld, firstOfWeek);

                return;
            }

            // currently selected --> remove selection
            this.removeZeitraumFeldSelection(zeitraumFeld,
                fraktion,
                firstOfWeek,
                ExtraPlatzType.ABSENCE,
                currentPlaetzeFraktionen);

        } else if (this.tempExtraPlatzBuilder.isAdditionAllowed(zeitraumFeld, fraktionenWithBelegungen, firstOfWeek)) {
            // not selected --> select
            this.openExtraPlatzPopover(zeitraumFeld, fraktion, event);
        }
    }

    /**
     * Delivers the ids of the fraktionen in which the zeitraum feld is part of the belegung.
     * Being part meaning, that the feld has to be equal to a feld of the gruppenbelegung.
     */
    public findFraktionWithBelegungForFeld(
        belegung: Belegung | null,
        fraktion: KinderOrtFraktion,
        zeitraumFeld: ZeitraumFeld,
    ): KinderOrtFraktionId[] {
        if (!belegung) {
            return [];
        }

        const wochenplan = checkPresent(fraktion.wochenplan);

        return belegung.gruppenBelegungen
            .filter(grpBelegung => grpBelegung.plaetze.find(platz => ZeitraumUtil.findZeitraumFelder(wochenplan, platz)
                .find(feld => feld.equals(zeitraumFeld))))
            .map(gb => gb.gruppeId!);
    }

    /**
     * Removing absences with the TempExtraPlatzBuilder removes any equal felder of type ADDITIONAL
     * This function ensures, that those equal felder are not marked as selected on the wochenplan any more.
     *
     * @param currentPlaetzeFraktionen
     * @param removedAbsences the removed absences zeitraumFelder
     * @param removedAbsenceFraktionId ID of the fraktion of the removed absence
     * @param removed IDs of fraktionen for which additions go removed
     */
    public updateWochenplanAfterAbsenceRemoval(
        currentPlaetzeFraktionen: KinderOrtFraktion[],
        removedAbsences: ZeitraumFeld[],
        removedAbsenceFraktionId: KinderOrtFraktionId,
        removed: Set<KinderOrtFraktionId>,
    ): void {

        currentPlaetzeFraktionen
            .filter(f => removed.has(f.id!) && f.id !== removedAbsenceFraktionId)
            .flatMap(currentFraktion => checkPresent(currentFraktion.wochenplan).zeitraumFelder)
            .filter(currentFeld => removedAbsences.find(zf => zf.equals(currentFeld)))
            .forEach(currentFeld => {
                currentFeld.selected = false;
                currentFeld.active = false;
            });
    }

    private removeZeitraumFeldSelection(
        zeitraumFeld: ZeitraumFeld,
        fraktion: KinderOrtFraktion,
        firstOfWeek: moment.Moment,
        type: ExtraPlatzType,
        currentPlaetzeFraktionen: KinderOrtFraktion[],
    ): void {
        const removed = this.tempExtraPlatzBuilder.removeField(
            zeitraumFeld,
            fraktion.id!,
            firstOfWeek,
            type);
        angular.extend(zeitraumFeld, zeitraumFeld.backup);

        this.updateWochenplanAfterAbsenceRemoval(currentPlaetzeFraktionen, [zeitraumFeld], fraktion.id!, removed);
    }

    private openExtraPlatzPopover(
        zeitraumFeld: ZeitraumFeld,
        fraktion: KinderOrtFraktion,
        event: JQuery.ClickEvent,
    ): void {
        this.popoverHelper.popoverZuweisungContent.zeitraumFeld = zeitraumFeld;
        this.popoverHelper.popoverZuweisungContent.gruppe = fraktion;

        const parentElement = angular.element(event.target).closest('.feld');
        this.popoverHelper.open(parentElement);
    }

    private createAbwesenheit(
        fraktion: Persisted<KinderOrtFraktion>,
        zeitraumFeld: ZeitraumFeld,
        firstOfWeek: moment.Moment,
    ): void {

        const template = new TempExtraPlatz(
            fraktion.id,
            DvbDateUtil.getDayOfWeekMoment(zeitraumFeld.dayOfWeek, firstOfWeek),
            ExtraPlatzCategory.STANDARD,
            ExtraPlatzType.ABSENCE);

        this.tempExtraPlatzBuilder.addTempExtraPlatz(template, zeitraumFeld, zeitraumFeld.kontingent);
    }
}
