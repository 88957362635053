<div class="dvb-kind-tarife-betreuungsgutschein-header-kitax history-columns">
    <p class="history-column-left"
       data-translate="COMMON.PERIOD"
       data-translate-value-date-ab="{{vm.betreuungsGutscheinGroup.gueltigAb | amDateFormat:'YYYY'}}"
       data-translate-value-date-bis="{{vm.betreuungsGutscheinGroup.gueltigBis | amDateFormat:'YY'}}">
    </p>
    <div class="history-column-middle">
        <p class="layout-row">
            <span ng-if="vm.betreuungsGutscheinGroup.verfuegtAm"
                  data-translate="KIND.KITAX.MF_VERFUEGUNG_VOM"
                  data-translate-interpolation="messageformat"
                  data-translate-value-type="{{vm.betreuungsGutscheinGroup.schemaVersion.type}}"
                  data-translate-value-date="{{vm.betreuungsGutscheinGroup.verfuegtAm | amDateFormat:'D.M.YYYY HH:mm'}}">
            </span>
            <span ng-if="!vm.betreuungsGutscheinGroup.verfuegtAm"
                  data-translate="KIND.KITAX.MF_VERFUEGUNG"
                  data-translate-interpolation="messageformat"
                  data-translate-value-type="{{vm.betreuungsGutscheinGroup.schemaVersion.type}}">
            </span>
            <i ng-if="vm.betreuungsGutscheinGroup.auszahlungAnEltern"
               class="fa fa-info-circle floating-text"
               uib-tooltip="{{'KIND.KITAX.AUSZAHLUNG_AN_ELTERN' | translate}}"
               tooltip-placement="top-left">
            </i>
            <dvb-kibon-ref-nummer kibon-ref-nr-info="vm.betreuungsGutscheinGroup">
            </dvb-kibon-ref-nummer>
        </p>
        <ng-transclude ng-transclude-slot="middle"></ng-transclude>
    </div>
</div>
<ng-transclude ng-transclude-slot="bottom"></ng-transclude>
