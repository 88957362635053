/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {Geschlecht} from '@dv/shared/code';
import type {INameGeburtsdatumGeschlecht} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        person: '<',
    },
    template: require('./dvb-name-geburtstag-geschlecht.html'),
    controllerAs: 'vm',
};

export class DvbNameGeburtstagGeschlecht implements angular.IController {

    public person?: INameGeburtsdatumGeschlecht;

    public readonly geschlecht: typeof Geschlecht = Geschlecht;
}

componentConfig.controller = DvbNameGeburtstagGeschlecht;
angular.module('kitAdmin').component('dvbNameGeburtstagGeschlecht', componentConfig);
