/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRechnungsKonfiguration, RestCache} from '@dv/kitadmin/models';
import {BetreuungsfaktorRegel, Mandant, MandantFeaturePermissions, RechnungsKonfiguration} from '@dv/kitadmin/models';
import type {Persisted, RestInclude, SupportedLanguage} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import type {BenutzerFormModel} from '../../../benutzer/component/dvb-benutzer-form/benutzer-form.model';
import {benutzerFormModelToJaxBenutzer} from '../../../benutzer/component/dvb-benutzer-form/benutzer-form.util';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class MandantService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/mandanten`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public get(mandantId: string, params?: RestInclude & RestCache): angular.IPromise<Mandant | null> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(MandantService.BASE_URL, Mandant, mandantId, params);
    }

    public getOwnMandant(params?: RestInclude & RestCache): angular.IPromise<Persisted<Mandant>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(MandantService.BASE_URL, Mandant, 'current', params)
            .then(checkPersisted);
    }

    public getAll(params?: RestInclude): angular.IPromise<Mandant[]> {
        return DvbRestUtilAngularJS.getModelsArray(MandantService.BASE_URL, Mandant, 'mandanten', params);
    }

    public getFeatures(params?: RestInclude): angular.IPromise<MandantFeaturePermissions[]> {
        const url = `${MandantService.BASE_URL}/mandantfeatures`;

        return DvbRestUtilAngularJS.getModelsArray(url, MandantFeaturePermissions, 'featurePermissions', params);
    }

    public getMandantLanguges(): angular.IPromise<SupportedLanguage[]> {
        return this.getOwnMandant({cache: true})
            .then(mandant => {
                const allMandantLanguages = [mandant.defaultLanguage ?? 'DE'].concat(mandant.supportedLanguages);

                return DvbUtil.uniqueArray(allMandantLanguages);
            });
    }

    public createMandant(params: {
        hasStandardWochenplan: boolean;
        mandant: Mandant;
        supportAdminBenutzer: BenutzerFormModel;
        adminBenutzer: BenutzerFormModel;
        demoMandant: boolean;
    }): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(MandantService.BASE_URL, {
            hasStandardWochenplan: params.hasStandardWochenplan,
            mandant: params.mandant.toRestObject(),
            supportAdminBenutzer: benutzerFormModelToJaxBenutzer(params.supportAdminBenutzer),
            adminBenutzer: benutzerFormModelToJaxBenutzer(params.adminBenutzer),
            demoMandant: params.demoMandant,
        });
    }

    public update(mandant: Mandant): angular.IHttpPromise<void> {
        const id = checkPresent(mandant.id);
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MandantService.BASE_URL}/${encodeURIComponent(id)}`;

        return this.$http.put(url, mandant.toRestObject());
    }

    public disable(mandant: Mandant, setDisabled: boolean): angular.IHttpPromise<void> {
        const id = checkPresent(mandant.id);

        return this.$http.put(`${MandantService.BASE_URL}/${encodeURIComponent(id)}/disable`, {
            disabled: setDisabled,
        });
    }

    public getRechnungsKonfigurationen(
        params?: RestInclude,
    ): angular.IPromise<RechnungsKonfiguration<IRechnungsKonfiguration>[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const url = `${MandantService.BASE_URL}/rechnungskonfigurationen`;

        return DvbRestUtilAngularJS.getModelsArray(url, RechnungsKonfiguration, 'konfigurationen', matrixParams);
    }

    public updateRechnungsKonfiguration<T extends IRechnungsKonfiguration>(
        rechnungsKonfiguration: RechnungsKonfiguration<T>,
    ): angular.IHttpPromise<void> {
        const dtype = checkPresent(rechnungsKonfiguration.dtype);
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MandantService.BASE_URL}/rechnungskonfigurationen/` +
            `${dtype.toLowerCase()}/${rechnungsKonfiguration.id}`;

        return this.$http.put(url, rechnungsKonfiguration.toRestObject());
    }

    public createRechnungsKonfiguration<T extends IRechnungsKonfiguration>(
        rechnungsKonfiguration: RechnungsKonfiguration<T>,
    ): angular.IHttpPromise<void> {
        const dtype = checkPresent(rechnungsKonfiguration.dtype);
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(
            `${MandantService.BASE_URL}/rechnungskonfigurationen/${dtype.toLowerCase()}`,
            rechnungsKonfiguration.toRestObject());
    }

    public deleteRechnungsKonfiguration(konfigurationId: string): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MandantService.BASE_URL}/rechnungskonfigurationen/${encodeURIComponent(konfigurationId)}`;

        return this.$http.delete(url);
    }

    public createBetreuungsfaktorRegel(betreuungsfaktorRegel: BetreuungsfaktorRegel): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MandantService.BASE_URL}/betreuungsfaktorregeln`;

        return this.$http.post(url, betreuungsfaktorRegel.toRestObject());
    }

    public getAllBetreuungsfaktorRegeln(): angular.IPromise<BetreuungsfaktorRegel[]> {
        const url = `${MandantService.BASE_URL}/betreuungsfaktorregeln`;

        return DvbRestUtilAngularJS.getModelsArray(url, BetreuungsfaktorRegel, 'betreuungsfaktorRegeln');
    }

    public getBetreuuungsfaktorRegel(
        betreuungsfaktorRegelId: string,
        params: RestInclude & RestCache,
    ): angular.IPromise<BetreuungsfaktorRegel | null> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(`${MandantService.BASE_URL}/betreuungsfaktorregeln`,
            BetreuungsfaktorRegel,
            betreuungsfaktorRegelId,
            params);
    }

    public deleteBetreuungsfaktorRegel(betreuungsfaktorRegelId: string): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MandantService.BASE_URL}/betreuungsfaktorregeln/${encodeURIComponent(betreuungsfaktorRegelId)}`;

        return this.$http.delete(url);
    }

    public updateMandantFeaturePermission(featurePermission: MandantFeaturePermissions): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MandantService.BASE_URL}/featurePermissions/${encodeURIComponent(featurePermission.mandantId)}`;

        return this.$http.post(url, featurePermission.toRestObject());
    }
}
