/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Rechnung} from '@dv/kitadmin/models';
import {Zahlung, ZahlungType} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        betrag: '<?',
    },
    template: require('./dvb-rechnung-zahlung-erfassen.html'),
    controllerAs: 'vm',
};

export class DvbRechnungZahlungErfassen implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'errorService', 'fakturaService'];

    public rechnung!: Persisted<Rechnung>;
    public betrag?: number;

    public isLoading = false;
    public zahlung: Zahlung | null = null;
    public types: ZahlungType[] = [ZahlungType.BANKZAHLUNG, ZahlungType.ABSCHREIBUNG];

    public constructor(
        private readonly $state: StateService,
        private readonly errorService: ErrorService,
        private readonly fakturaService: FakturaService,
    ) {
    }

    public $onInit(): void {
        this.zahlung = new Zahlung();
        this.zahlung.dtype = this.rechnung.dtype;
        this.zahlung.type = ZahlungType.BANKZAHLUNG;
        this.zahlung.datum = DvbDateUtil.today();
        this.zahlung.betrag = this.betrag ?? null;
    }

    public submit(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return;
        }

        this.isLoading = true;

        this.fakturaService.createZahlung(this.rechnung.id, checkPresent(this.zahlung))
            .then(() => this.$state.go('base.rechnung.zahlungen', undefined, {reload: true}))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goBack(): angular.IPromise<unknown> {
        return this.$state.go('base.rechnung.zahlungen');
    }
}

componentConfig.controller = DvbRechnungZahlungErfassen;
angular.module('kitAdmin').component('dvbRechnungZahlungErfassen', componentConfig);
