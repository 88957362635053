/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldung, ExternalAnmeldungKind, ExternalAnmeldungSetup} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anmeldung: '<',
        kinder: '<',
        setup: '<?',
        showErrorLink: '<',
        navigateToError: '&',
    },
    template: require('./dvb-external-anmeldung-conclusion.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungConclusion implements angular.IController {
    public static $inject: readonly string[] = [];

    public anmeldung: ExternalAnmeldung[] = [];
    public kinder: ExternalAnmeldungKind[] = [];
    public setup?: ExternalAnmeldungSetup;
    public showErrorLink = false;
}

componentConfig.controller = DvbExternalAnmeldungConclusion;
angular.module('kitAdmin').component('dvbExternalAnmeldungConclusion', componentConfig);
