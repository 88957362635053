<div class="dvb-kita-rechnungslauf-finish-entry" ng-class="{'selected-entry': vm.isSelected}">
    <div class="collapsible-panel-container row"
         ng-class="{'first-container': vm.isFirst, 'entry-heading': vm.entry.verrechenbar}"
         ng-click="vm.selectEntry(vm.index, vm.entry)">

        <div class="col-xs-7">
            <div class="checkbox no-margin inline-block recipient">
                <label ng-click="$event.stopPropagation()">
                    <input type="checkbox"
                           class="smaller-abstand-right"
                           ng-model="vm.checkedEntry"
                           ng-disabled="!vm.entry.verrechenbar || vm.isDisabled">
                    <strong ng-bind="vm.empfaengerListLabel"
                            uib-tooltip="{{vm.empfaengerListLabel}}"
                            tooltip-placement="top-left">
                    </strong>
                </label>
            </div>
            <i class="fa fa-money vorauszahlung inline-block floating-text"
               ng-if="vm.showVorauszahlungen"
               uib-tooltip="{{'FAKTURA.VORAUSZAHLUNG_BETRAG_VORHANDEN' |
                    translate:{betrag: (vm.entry.empfaenger[0].vorauszahlungen | number: 2)} }}"
               tooltip-placement="top-left">
            </i>
            <i class="fa fa-info-circle inline-block floating-text"
               ng-if="vm.entry.hasAbweichungToPrevious()"
               uib-tooltip="{{vm.getAbweichungTranslation() | translate}}"
               tooltip-placement="top-left">
            </i>
            <dvb-versandart-badge ng-if="vm.entry.empfaenger.length === 1"
                                  class="pull-right"
                                  versandart="vm.entry.empfaenger[0].versandartRechnung"
                                  tooltip="vm.entry.empfaenger[0].email">
            </dvb-versandart-badge>
        </div>
        <div class="col-xs-3">
            <dvb-tasks-badge ng-if="!vm.hasError"
                             label="{{vm.taskBadgeLabel}}"
                             popover-title="{{'KINDERORT.RECHNUNG_NICHT_VERRECHENBAR_TOOLTIP' | translate}}"
                             popover-disable="vm.entry.verrechenbar"
                             tasks="vm.aggregatedTasks"
                             warning="!vm.entry.verrechenbar">
            </dvb-tasks-badge>
            <dvb-rechnungs-revision-error ng-if="vm.hasError"
                                          errors="vm.aggregatedErrors">
            </dvb-rechnungs-revision-error>
            <dvb-leistungsrechnung-warnings-badge warnings="vm.warnings" implicitly-known="KINDERORT">
            </dvb-leistungsrechnung-warnings-badge>
        </div>
        <div class="col-xs-2 text-right floating-text"
             ng-if="vm.entry.verrechenbar && vm.entry.empfaenger.length === 1">
            <strong data-translate="COMMON.BETRAG_VALUE"
                    data-translate-value-betrag="{{vm.entry.empfaenger[0].rechnung.summe | number: 2}}">
            </strong>
        </div>
    </div>

    <div uib-collapse="!vm.isSelected" class="row" ng-if="vm.isSelected && (vm.entry.verrechenbar || vm.entry.empfaenger.length > 1)">
        <div ng-repeat="empfaenger in vm.entry.empfaenger | orderBy:'kontaktpersonDisplayName'"
             class="col-xs-12">

            <dvb-kita-rechnungslauf-finish-kontaktperson-entry
                empfaenger="empfaenger"
                verrechenbar="vm.entry.verrechenbar"
                on-click="vm.selectKontaktperson($index)"
                is-single-entry="vm.entry.empfaenger.length === 1"
                is-selected="$index === vm.selectedKontaktpersonIndex">
            </dvb-kita-rechnungslauf-finish-kontaktperson-entry>

        </div>
    </div>
</div>
