/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {NamedEntityType} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';
import {NOTIZ_CREATE_STATE} from '../../notiz-states';
import {NamedEntityKorrespondenzScrollDataSource} from '../../service/namedEntityKorrespondenzScrollDataSource';
import type {NotizService} from '../../service/notiz-service';
import {NotizTimelineController} from './NotizTimelineController';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entityId: '<',
        entityType: '<',
    },
    template: require('./dvb-named-entity-notizen.html'),
    controllerAs: 'vm',
};

export class DvbNamedEntityNotizen implements angular.IController {
    public static $inject: readonly string[] = ['$translate', 'notizService'];

    public entityId?: string;
    public entityType?: NamedEntityType;

    public isLoading: boolean = true;
    public adapter?: angular.ui.IScrollAdapter;
    public timelineController?: NotizTimelineController;
    public dataSource?: NamedEntityKorrespondenzScrollDataSource;
    public notizCreateState = NOTIZ_CREATE_STATE;

    public constructor(
        private readonly $translate: angular.translate.ITranslateService,
        private readonly notizService: NotizService,
    ) {
    }

    public updateFilter(): void {
        if (isPresent(this.adapter)) {
            this.adapter.reload(0);
        }
    }

    public $onInit(): void {
        this.timelineController = new NotizTimelineController(this.$translate, this.notizService);
        this.dataSource = new NamedEntityKorrespondenzScrollDataSource(
            this.notizService,
            this.entityId!,
            this.entityType!);
    }
}

componentConfig.controller = DvbNamedEntityNotizen;
angular.module('kitAdmin').component('dvbNamedEntityNotizen', componentConfig);
