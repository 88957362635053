<div class="dvb-fraktion-kapazitaet">
    <h2 data-translate="COMMON.KAPAZITAET_PLAETZE"></h2>

    <!-- List of Kapazitaeten -->
    <div ng-repeat="aenderung in vm.kapazitaetsAenderungen">
        <dvb-collapsible-panel
            ng-show="(vm.kapazitaetsAenderungen[$index - 1].wochenKapazitaet.gueltigAb.diff(aenderung.wochenKapazitaet.gueltigBis, 'days') > 1 || $first) && aenderung.wochenKapazitaet.isBeendet()"
            is-first="$first && aenderung.wochenKapazitaet.isBeendet()"
            is-collapsed="true"
            dvb-auth
            require-permission="{{'kita:administrate:' + vm.fraktion.kita.id}}"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{aenderung.wochenKapazitaet.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{aenderung.wochenKapazitaet.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.revertBeenden(aenderung.wochenKapazitaet)">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel expand-ab="vm.today"
                               expand-bis="vm.today"
                               is-first="$first && !aenderung.wochenKapazitaet.isBeendet()"
                               limited-entity="aenderung.wochenKapazitaet">
            <panel-header>
                <dvb-kapazitaet-header wochen-kapazitaet="aenderung.wochenKapazitaet"></dvb-kapazitaet-header>
            </panel-header>
            <panel-content>
                <dvb-kapazitaet ng-if="!aenderung.isEditMode"
                                kapazitaets-aenderung="aenderung"
                                wochenplan="vm.fraktion.wochenplan">
                    <ul class="list-inline normal-abstand-top"
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.fraktion.kita.id}}">
                        <li>
                            <a href=""
                               ng-click="aenderung.isEditMode = true">
                                <span data-translate="COMMON.BEARBEITEN"></span>
                            </a>
                        </li>
                        <li ng-show="vm.kapazitaetsAenderungen.length > 1">
                            <a href=""
                               ng-click="vm.deleteWochenKapazitaet(aenderung.wochenKapazitaet)">
                                <span data-translate="COMMON.LOESCHEN"></span>
                            </a>
                        </li>
                    </ul>
                </dvb-kapazitaet>

                <div ng-if="aenderung.isEditMode">
                    <form name="editWochenKapazitaetForm"
                          ng-submit="vm.updateWochenKapazitaet(editWochenKapazitaetForm, aenderung)"
                          novalidate>
                        <dvb-fraktion-kapazitaet-form wochenplan="vm.fraktion.wochenplan"
                                                      wochen-kapazitaet="aenderung.editableWochenKapazitaet"
                                                      on-wochen-kapazitaet-change="aenderung.editableWochenKapazitaet = wochenKapazitaet"
                                                      display-mode="READ-ONLY-DATE">
                        </dvb-fraktion-kapazitaet-form>
                        <div class="main-actions">
                            <ul class="list-inline">
                                <li>
                                    <dvb-loading-button is-loading="vm.isLoading" type="submit">
                                        <span data-translate="COMMON.SPEICHERN"></span>
                                    </dvb-loading-button>
                                </li>
                                <li>
                                    <a href=""
                                       data-translate="COMMON.ABBRECHEN"
                                       ng-click="vm.resetUpdateWochenKapazitaet(aenderung)">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </panel-content>
        </dvb-collapsible-panel>
    </div>

    <!-- Action bar -->
    <div class="main-actions" dvb-auth require-permission="{{'kita:administrate:' + vm.fraktion.kita.id}}">
        <ul ng-show="vm.activeView === 'main'" class="list-inline">
            <li>
                <button type="button"
                        class="btn btn-primary"
                        ng-click="vm.activeView = 'neueWochenKapazitaet'">
                    <span data-translate="FRAKTION.PLAETZE_NEU_FESTLEGEN"></span>
                </button>
            </li>

            <li>
                <div class="form-section">
                    <a href=""
                       ng-click="vm.terminateFraktion()"
                       data-translate="{{'FRAKTION.' + vm.fraktion.dtype + '.BEENDEN'}}">
                    </a>
                </div>
            </li>
            <li>
                <div class="form-section">
                    <a href=""
                       ng-click="vm.confirmDeleteFraktion()"
                       data-translate="{{'FRAKTION.' + vm.fraktion.dtype + '.LOESCHEN'}}">
                    </a>
                </div>
            </li>
        </ul>
    </div>

    <!-- Neue WochenKapazitaet GUI -->
    <div class="big-abstand-top" ng-if="vm.activeView === 'neueWochenKapazitaet'">
        <form name="neueWochenKapazitaetForm"
              ng-submit="vm.saveWochenKapazitaet(neueWochenKapazitaetForm, vm.copyOfLatestWochenKapazitaet)"
              novalidate>
            <!-- Durch ng-if wird der Date Picker immer wieder neu initialisiert -->
            <h2 data-translate="COMMON.NEUE_KAPAZITAET"></h2>

            <div class="normal-abstand-top neue-kapazitaet">
                <dvb-fraktion-kapazitaet-form wochen-kapazitaet="vm.copyOfLatestWochenKapazitaet"
                                              wochenplan="vm.fraktion.wochenplan"
                                              display-mode="EDITABLE-DATE">>
                </dvb-fraktion-kapazitaet-form>
            </div>

            <div class="main-actions">
                <ul class="list-inline ">
                    <li>
                        <div class="btn-group">
                            <dvb-loading-button is-loading="vm.isLoading" type="submit">
                                <span data-translate="COMMON.NEUE_KAPAZITAET_SPEICHERN"></span>
                            </dvb-loading-button>
                        </div>
                    </li>
                    <li>
                        <a href=""
                           data-translate="COMMON.ABBRECHEN"
                           ng-click="vm.resetNewKontingentValue()">
                        </a>
                    </li>
                </ul>
            </div>
        </form>
    </div>
</div>
