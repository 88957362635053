/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {IPopoverFilterProperty} from '../../../filter/popover/IPopoverFilterProperty';
import type {PopoverFilterOption} from '../../../filter/popover/PopoverFilterOption';
import {DvbFilterOption} from '../dvb-filter-option/dvb-filter-option';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        filterModel: '<',
        editable: '<?',
        closeable: '<?',
        onClose: '&',
        onToggle: '&',
        onClosePopover: '&',
    },
    template: require('./dvb-filter-option-popover.html'),
    controllerAs: 'vm',
};

export class DvbFilterOptionPopover<T extends IPopoverFilterProperty<TModel, TPopoverModel>, TModel, TPopoverModel>
    extends DvbFilterOption {

    public filterModel!: PopoverFilterOption<T, TModel, TPopoverModel>;
    public editable: boolean = true;
    public onClosePopover!: FunctionType;
    // note: there are inherited properties
    // public isSelected!: boolean;
    // public label!: string;
    // public closeable?: boolean;
    // public onClose!: () => void;
    // public onToggle!: (params: {isSelected: boolean}) => void;

    public workingCopy: TPopoverModel | null = null;
    public isOpen: boolean = false;

    public $onChanges(): void {
        this.init();
    }

    public apply(): void {
        if (isPresent(this.workingCopy)) {
            this.filterModel.popoverModel.onChange(this.workingCopy);
        }

        this.isOpen = false;
        this.onClosePopover();
        this.init();
    }

    public toggle(): void {
        this.onToggle({isSelected: this.filterModel.selected});
    }

    private init(): void {
        this.workingCopy = this.filterModel.popoverModel.createWorkingCopy();
    }
}

componentConfig.controller = DvbFilterOptionPopover;
angular.module('kitAdmin').component('dvbFilterOptionPopover', componentConfig);
