/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Zahlung} from '@dv/kitadmin/models';
import {Rechnung} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        isLoading: '<',
        rechnung: '<',
        zahlungen: '<',
        ausstehenderBetrag: '<',
    },
    template: require('./dvb-rechnung-restbetrag.html'),
    controllerAs: 'vm',
};

export class DvbRechnungRestbetrag {
    public static $inject: readonly string[] = [];

    public isLoading!: boolean;
    public rechnung!: Rechnung;
    public zahlungen!: Zahlung[];
    public ausstehenderBetrag!: number;

    public showRestbetrag(): boolean {
        return !this.isLoading && (this.rechnung.status !== Rechnung.STATUS.STORNIERT || this.zahlungen.length !== 0);
    }
}

componentConfig.controller = DvbRechnungRestbetrag;
angular.module('kitAdmin').component('dvbRechnungRestbetrag', componentConfig);
