<div class="dvb-kita-faktura-texte-content row normal-abstand-top" ng-if="vm.hasTexte">
    <div class="col-xs-12">
        <p><strong data-translate="KINDERORT.DEFINIERTE_TEXTE"></strong>:</p>

        <dvb-translatable-language ng-model="vm.language"></dvb-translatable-language>

        <table class="table">
            <tbody ng-repeat="status in vm.texteStatuses"
                   ng-if="vm.konfiguration.texte.hasOwnProperty(status)">
            <tr ng-if="vm.konfiguration.texte[status].einfuehrung.hasTranslations()">
                <td rowspan="2" class="text-nowrap">
                    <strong data-translate="{{'KINDERORT.RECHNUNG_STATUS_TEXT.RECHNUNG_STATUS_TEXT_' + status}}"></strong>
                </td>
                <td data-translate="KINDERORT.EINFUEHRUNG"></td>
                <td class="block" ng-bind="vm.getTextOrDefault(vm.konfiguration.texte[status].einfuehrung)"
                    ng-class="{'placeholder-color': !vm.hasText(vm.konfiguration.texte[status].einfuehrung)}"></td>
            </tr>
            <tr ng-if="vm.konfiguration.texte[status].konditionen.hasTranslations()">
                <td rowspan="2"
                    class="text-nowrap"
                    ng-if="!vm.konfiguration.texte[status].einfuehrung.hasTranslations()">
                    <strong data-translate="{{'KINDERORT.RECHNUNG_STATUS_TEXT.RECHNUNG_STATUS_TEXT_' + status}}"></strong>
                </td>
                <td data-translate="KINDERORT.KONDITIONEN"></td>
                <td ng-bind="vm.getTextOrDefault(vm.konfiguration.texte[status].konditionen)"
                    ng-class="{'placeholder-color': !vm.hasText(vm.konfiguration.texte[status].konditionen)}"></td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
