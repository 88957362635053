/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FilterOption} from '../../../filter/shared/FilterOption';

export class AgeFilterOption extends FilterOption {

    /**
     * @param id an ID that references a filter option in kinderFilterService AVAILABLE_AGE_FILTERS
     * @param name the display name of the filter
     * @param from Filter the age from this age
     * @param to Filter the age to this age
     */
    public constructor(
        id: string,
        name: string,
        public from: number,
        public to: number,
    ) {
        super(id, name, false, true);
    }
}
