/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').directive('dvbMinExclusive', dvbMinExclusive);

function dvbMinExclusive(): angular.IDirective {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: (_scope, _element, attrs, ctrl: any): void => {
            const ngModel: angular.INgModelController = ctrl;

            const minExclusive = parseFloat(attrs.dvbMinExclusive);
            if (!angular.isNumber(minExclusive) || isNaN(minExclusive)) {
                throw new Error(`not a number: ${minExclusive}`);
            }

            ngModel.$validators.dvbMinExclusive = isLargerMinExclusive;

            function isLargerMinExclusive(_modelValue: any, viewValue: any): boolean {
                if (ngModel.$isEmpty(viewValue)) {
                    return true;
                }

                return parseFloat(viewValue) > minExclusive;
            }
        },
    };
}
