/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {Gueltigkeit, ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {ERR_VALIDATION, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        gueltigAb: '<?',
        gueltigBis: '<?',
        defaultGueltigkeit: '<?',
    },
    template: require('./dvb-kita-or-fraktion-report.html'),
    controllerAs: 'vm',
};

const defaultWorkHoursPerDay = 8;

export class DvbKitaOrFraktionReport implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public kinderOrt?: SearchResultEntry;
    public gueltigAb?: moment.Moment;
    public gueltigBis?: moment.Moment;
    public defaultGueltigkeit?: Gueltigkeit;

    public readonly kitaOrFraktionReportForm?: angular.IFormController;
    public defaultGueltigAb: moment.Moment = DvbDateUtil.startOfWeek();
    public defaultGueltigBis: moment.Moment = DvbDateUtil.today().add(1, 'years').add(-1, 'weeks').endOf('isoWeek');
    public gruppeSearchResultEntry?: SearchResultEntry;
    public hoursFullDayBetreuung: number = defaultWorkHoursPerDay;

    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.defaultGueltigkeit) {
            this.defaultGueltigAb = this.defaultGueltigkeit!.gueltigAb!;
            this.defaultGueltigBis = this.defaultGueltigkeit!.gueltigBis!;
        }
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateState(): void {
        this.reportService.updateStateParams({
            gueltigAb: this.gueltigAb,
            gueltigBis: this.gueltigBis,
        });
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const params = this.buildParams();

        this.errorService.clearAll();
        const isValid = this.kitaOrFraktionReportForm!.$valid && (params.fraktionId ?? params.kinderOrtId);
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_KITA_UND_GRUPPE');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        switch (this.reportState) {
            case ReportState.BETRIEB.ABWEICHUNGEN_KINDER:
                return this.reportService.getTempBlobForAbweichungenReport(params);
            default:
                throw new Error(`Report download not implemented: ${JSON.stringify(this.reportState)}`);
        }
    }

    private buildParams(): any {
        return {
            kinderOrtId: this.kinderOrt ? this.kinderOrt.id : undefined,
            fraktionId: this.gruppeSearchResultEntry ? this.gruppeSearchResultEntry.id : undefined,
            reportTemplate: this.reportTemplate,
            gueltigAb: this.gueltigAb ?? this.defaultGueltigAb,
            gueltigBis: this.gueltigBis ?? this.defaultGueltigBis,
        };
    }
}

componentConfig.controller = DvbKitaOrFraktionReport;
angular.module('kitAdmin').component('dvbKitaOrFraktionReport', componentConfig);
