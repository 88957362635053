/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FilterOption} from './FilterOption';

export enum FilterType {
    DEFAULT = 'DEFAULT',
    POPOVER = 'POPOVER',
}

/**
 * Base class for filters.
 * Contains information required to display a filter and its options.
 */
export abstract class Filter {

    public stickyOptions: boolean = false;
    public nonStickyOptions: boolean = true;

    protected constructor(
        public options: FilterOption[] = [],
        public translationKey: string,
        public type: FilterType = FilterType.DEFAULT,
    ) {
        this.initStickyFlags();
    }

    public reset(): void {
        FilterOption.unselect(this.options);
    }

    /**
     * Determines if the filter has any sticky options.
     * Call this whenever an options sticky value is updated to keep the sticky flag up to date.
     */
    public initStickyFlags(): void {
        this.stickyOptions = this.options.some(o => o.sticky);
        this.nonStickyOptions = this.options.some(o => !o.sticky);
    }

    /**
     * Initializes the filter based on the properties stored in the service.
     */
    public abstract init(): void;

    /**
     * Has to be called whenever the filters value changes.
     * The implementation has to modify the filter properties and notify the filter service.
     *
     * Call initSticky() from the implementation to keep the stickyOption flag up to date.
     */
    public abstract onChange(option: FilterOption): void;
}
