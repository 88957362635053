<div class="dvb-pages">
    <dvb-page items="vm.items" is-loading="vm.isLoading" entity-name="vm.entityName">
        <ng-transclude ng-transclude-slot="items"></ng-transclude>
    </dvb-page>

    <ng-transclude ng-transclude-slot="summary"
                   ng-if="!vm.isInitiallyLoading && vm.totalItems > 0">
    </ng-transclude>

    <div class="normal-abstand-top page-switcher"
         ng-if="!vm.isInitiallyLoading && vm.totalItems > vm.minItemsPerPage">

        <ul uib-pagination
            total-items="vm.totalItems"
            class="pagination-sm normal-abstand-top hidden-print"
            items-per-page="vm.itemsPerPage"
            max-size="vm.maxSize"
            boundary-links="true"
            force-ellipses="vm.forceEllipses"
            previous-text="&#xf104;"
            next-text="&#xf105;"
            first-text="&#xf048;"
            last-text="&#xf051;"
            ng-model="vm.currentPage"
            ng-change="vm.onUpdatePage({page: vm.currentPage})"></ul>

        <div class="items-per-page">
            <select class="form-control select-sm"
                    uib-tooltip="{{'COMMON.OBJEKTE_PRO_SEITE' | translate}}"
                    tooltip-placement="top-left"
                    ng-model="vm.itemsPerPage"
                    ng-options="option for option in vm.itemsPerPageOptions track by option"
                    ng-change="vm.onUpdateItemsPerPage({itemsPerPage: vm.itemsPerPage})">
            </select>
            /
            <span data-translate="{{vm.entityName}}"
                  data-translate-value-count="{{vm.totalItems}}"
                  data-translate-value-showcount="true"
                  data-translate-interpolation="messageformat">
            </span>

            <p class="pull-right visible-print-inline-block"
               data-translate="COMMON.AKTUELLE_SEITE"
               data-translate-value-page="{{vm.currentPage}}">
            </p>
        </div>
    </div>

</div>
