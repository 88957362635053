<div class="dvb-multi-select dvb-form">
    <div class="form-row">
        <div class="row selected-entity"
             ng-repeat="entity in vm.selected">
            <div class="col-xs-8 entity truncate">
                <span ng-bind="entity.displayName"></span>
            </div>
            <div class="col-xs-4 edit">
                <button class="btn btn-link"
                        type="button"
                        ng-click="vm.remove(entity)"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </div>
        </div>
    </div>
    <div class="search-input highlight-input-fields-when-invalid"
         ng-class="{'ng-invalid': vm.ngModelCtrl.$invalid}">
        <script type="text/ng-template" id="multiSelectPreviewTemplate.html">
            <a>
                <span class="search-result-label" ng-bind-html="match.label"></span>
                <span class="badge search"
                      title="{{match.model.badgeText}}"
                      ng-bind="match.model.badgeText"
                      ng-if="match.model.badgeText">
            </span>
            </a>
        </script>

        <form name="multiSelectForm" role="search" novalidate>
            <div class="input-group form-row">
                <input type="text"
                       class="form-control"
                       ng-attr-placeholder="{{vm.placeholderKey | translate}}"
                       uib-tooltip="{{vm.placeholderKey | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.selectInput"
                       maxlength="150"
                       uib-typeahead="option as option.displayName for option in vm.options | filter: {displayName: $viewValue, selected: '!true'}"
                       typeahead-on-select="vm.select($item)"
                       typeahead-template-url="multiSelectPreviewTemplate.html"
                       typeahead-min-length="0">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
            </div>
        </form>
    </div>
</div>
