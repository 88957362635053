/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gruppe, WochenKapazitaet} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        gruppe: '<',
        aktuelleWochenKapazitaet: '<',
    },
    template: require('./dvb-gruppe-header.html'),
    controllerAs: 'vm',
};

export class DvbGruppeHeader implements angular.IController {
    public static $inject: readonly string[] = ['authStore'];

    public gruppe!: Gruppe;
    public aktuelleWochenKapazitaet!: WochenKapazitaet | null;

    public hasPersonalPermission: boolean = false;

    public constructor(
        private readonly authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.hasPersonalPermission = this.authStore.hasPermission(PERMISSION.PERSONAL.VIEW_ANY);
    }

}

componentConfig.controller = DvbGruppeHeader;
angular.module('kitAdmin').component('dvbGruppeHeader', componentConfig);
