/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {RechnungFilterStateName} from '@dv/shared/code';
import {DvbUtil, RECHNUNG_FILTER_STATES} from '@dv/shared/code';
import {RechnungenFilter} from './RechnungenFilter';
import {FilterOption} from './shared/FilterOption';

export type RechnungenForKontaktpersonZahlung = {
    id?: EntityId;
    kontaktpersonId: EntityId;
    kitaId: EntityId;
};

export function rechnungenForKontaktpersonZahlung(options: RechnungenForKontaktpersonZahlung): RechnungenFilter {
    const filter = new RechnungenFilter();
    const kontaktpersonFilterOption = new FilterOption(options.kontaktpersonId, undefined, true, false);
    const kitaFilterOption = new FilterOption(options.kitaId, undefined, true, false);

    filter.kontaktpersonen = [kontaktpersonFilterOption];
    filter.kitas = [kitaFilterOption];

    DvbUtil.keys(RECHNUNG_FILTER_STATES)
        .filter(allowedStates)
        .forEach(state => {
            filter.states[state] = true;
        });

    return filter;
}

// Do not load bezahlt or storniert Rechnungen
function allowedStates(state: RechnungFilterStateName): boolean {
    return state !== RECHNUNG_FILTER_STATES.STORNIERT.name && state !== RECHNUNG_FILTER_STATES.BEZAHLT.name;
}

