<div class="dvb-kind-tarife-betreuungsgutscheine-header">
    <dvb-kind-tarife-betreuungsgutschein-header-kitax
        ng-if="!!vm.betreuungsGutscheinGroup.schemaVersion"
        betreuungs-gutschein-group="vm.betreuungsGutscheinGroup">
    </dvb-kind-tarife-betreuungsgutschein-header-kitax>

    <dvb-kind-tarife-betreuungsgutschein-header-manuell
        ng-if="!vm.betreuungsGutscheinGroup.schemaVersion"
        betreuungs-gutschein="vm.betreuungsGutscheinGroup.betreuungsGutscheine[0]"
        betreuungs-gutschein-group="vm.betreuungsGutscheinGroup">
    </dvb-kind-tarife-betreuungsgutschein-header-manuell>
</div>
