/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import angular from 'angular';
import type moment from 'moment';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {WeeklyControllingFigures} from '../../../../report/models/WeeklyControllingFigures';
import {ControllingReportComponent} from '../model/ControllingReportComponent';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        controllingReport: '<?',
        jahr: '<',
        firstOfWeek: '<',
        lastOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-uebersicht-personal.html'),
    controllerAs: 'vm',
};

export class DvbKitaUebersichtPersonal extends ControllingReportComponent implements angular.IController {
    public static override $inject: readonly string[] = [];

    public kinderOrt!: KinderOrt;
    public lastOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public personalBedarf: number | null = null;
    public personalZugewiesen: number | null = null;
    public personalAngestellt: number | null = null;

    public avgPersonalBedarf: number | null = null;
    public avgPersonalZugewiesen: number | null = null;
    public avgPersonalAngestellt: number | null = null;

    protected setWeeklyFigures(weeklyFigures: WeeklyControllingFigures): void {
        this.personalBedarf = weeklyFigures.personalBedarf;
        this.personalZugewiesen = weeklyFigures.personalZugewiesen;
        this.personalAngestellt = weeklyFigures.personalAngestellt;
    }

    protected resetWeeklyFigures(): void {
        this.personalBedarf = null;
        this.personalZugewiesen = null;
        this.personalAngestellt = null;
    }

    protected setAverageControllingFigures(controllingReport: ControllingReport): void {
        if (!controllingReport?.average) {
            this.resetAverageControllingFigures();

            return;
        }

        this.avgPersonalBedarf = controllingReport.average.personalBedarf;
        this.avgPersonalZugewiesen = controllingReport.average.personalZugewiesen;
        this.avgPersonalAngestellt = controllingReport.average.personalAngestellt;
    }

    private resetAverageControllingFigures(): void {
        this.avgPersonalBedarf = null;
        this.avgPersonalZugewiesen = null;
        this.avgPersonalAngestellt = null;
    }
}

componentConfig.controller = DvbKitaUebersichtPersonal;
angular.module('kitAdmin').component('dvbKitaUebersichtPersonal', componentConfig);
