<div class="dvb-benutzer-main">
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-user header-icon"
               uib-tooltip="{{'COMMON.BENUTZER.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>

            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{vm.benutzer.getDisplayName()}}" ng-bind="vm.benutzer.getDisplayName()"
                    tooltip-placement="top-left">
                </h1>

                <ul class="list-inline header-icons">
                    <li ng-if="vm.benutzer.isOidcUser()">
                        <span class="fa fa-shield"
                              uib-tooltip="{{'AUTHENTICATION.OIDC_USER' | translate}}"
                              tooltip-placement="top-left">
                        </span>
                    </li>
                </ul>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.benutzer.profil" data-translate="COMMON.NAV_PROFIL"></a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.benutzer.rechte" data-translate="BENUTZER.NAV_BENUTZERRECHTE"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div ui-view></div>
</div>
