/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CdkDragDrop} from '@angular/cdk/drag-drop';
import {CdkDrag, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import type {OnChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {
    ButtonListComponent,
    DvLocalDateFormatPipe,
    LoadingButtonComponent,
    SortListComponent,
    SortListTemplateDirective,
} from '@dv/shared/angular';
import {JaxPersonalSortOrder} from '@dv/shared/backend/model/jax-personal-sort-order';
import {checkPresent, isNullish} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import type {Angestellte} from 'src/app/app';
import {CalendarGroup} from '../../../../../calendar/timeline/model/CalendarGroup';
import type {AngestellteZuweisungen} from '../../../../../personal/model/AngestellteZuweisungen';

@Component({
    selector: 'dv-personal-sort-dialog',
    templateUrl: './personal-sort-dialog.component.html',
    styleUrls: ['./personal-sort-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        LoadingButtonComponent,
        SubmitCancelButtonsComponent,
        DvLocalDateFormatPipe,
        SortListComponent,
        SortListTemplateDirective,
        CdkDropList,
        CdkDrag,
        ButtonListComponent,
    ],
})
export class PersonalSortDialogComponent implements OnChanges {

    @Input({required: true}) public group!: CalendarGroup;
    @Input() public sortOrder: JaxPersonalSortOrder[] = [];
    @Input() public angestellte: Angestellte[] = [];

    @Output() public readonly submitOrder: EventEmitter<Angestellte[]> = new EventEmitter();
    @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();

    public sorted: Angestellte[] = [];
    public unsorted: Angestellte[] = [];

    public hasSortableItems = computed(() => this.sorted.length > 0 || this.unsorted.length > 0);

    public ngOnChanges(): void {
        this.sorted = this.sortOrder.sort((a, b) => a.orderValue! - b.orderValue!)
            .map(order => checkPresent(this.angestellte.find(a => a.id === order.angestellteId)));

        this.unsorted =
            this.group.resources.filter(a => isNullish(this.sortOrder.find(order => order.angestellteId === a.id)))
                .map(resource => (resource as AngestellteZuweisungen).angestellte);
    }

    public drop(event: CdkDragDrop<Angestellte[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }
}
