<div ng-if="!vm.showNotiz">
    <div class="row">
        <div class="col-md-12">
            <strong ng-if="!vm.korrespondenz.rechnungsId"
                    ng-bind="vm.korrespondenz.subject">
            </strong>
            <a ng-if="vm.korrespondenz.rechnungsId"
               ng-bind="vm.korrespondenz.subject"
               ui-sref="base.rechnung.uebersicht({rechnungId: vm.korrespondenz.rechnungsId})">
            </a>
        </div>
    </div>

    <dvb-collapsible-panel ng-if="vm.korrespondenz.emailContentPlainText || vm.korrespondenz.content"
                           limited-entity="korrespondenz"
                           compact="true">
        <panel-header>
            <div class="truncate"
                 ng-bind="vm.korrespondenz.emailContentPlainText || vm.korrespondenz.content">
            </div>
        </panel-header>
        <panel-content>
            <div ng-if="vm.showCommunicationType"
                 class="nl2br"
                 data-translate="COMMUNICATION.FOR.{{vm.korrespondenz.type}}"
                 data-translate-value-text="{{vm.korrespondenz.content}}">
            </div>
            <div ng-if="vm.showContent"
                 class="nl2br"
                 ng-bind="vm.korrespondenz.content">
            </div>
            <div ng-if="vm.showEmailContentPlainText"
                 class="nl2br"
                 ng-bind="vm.korrespondenz.emailContentPlainText">
            </div>
            <div ng-if="vm.showEmailContentHTML"
                 ng-bind-html="vm.korrespondenz.emailContentHTML">
            </div>
        </panel-content>
    </dvb-collapsible-panel>
</div>

<dvb-notiz-timeline-details ng-if="vm.showNotiz"
                            notiz="vm.korrespondenz">
</dvb-notiz-timeline-details>
