/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FunctionType} from '@dv/shared/code';
import {DvbDateUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';

function momentFromDateString(dateString: string, format: string): moment.Moment | null {
    const parsableFormats = ['D.M.YYYY', 'DD.MM.YYYY', 'D.MM.YYYY', 'DD.M.YYYY', format];

    return !dateString || dateString.trim() === '' ? null : moment(dateString, parsableFormats, true).startOf('day');
}

export class DvbDatepickerTextField implements angular.IController {

    public static $inject: readonly string[] = [];

    public dateMoment!: moment.Moment;
    public placeholder!: string;
    public clickToEdit!: boolean;
    public onSubmit?: FunctionType;
    public labelDateFormat?: string;
    public isValid?: (param: { param: moment.Moment | null }) => boolean;
    public isDisabled: boolean = false;
    public format: string = 'D.M.YYYY';
    public customOptions?: angular.ui.bootstrap.IDatepickerConfig;
    public smallInputs?: boolean;

    public ngModelCtrl!: angular.INgModelController;

    public opened: boolean = false;
    public dateOptions: angular.ui.bootstrap.IDatepickerConfig = {
        startingDay: 1,
        showWeeks: false,
    };
    public displayFormat: string = 'LL';
    public dateString: string = '';
    public dateObject?: Date;

    public $onInit(): void {
        this.format ??= 'D.M.YYYY';
        this.displayFormat = this.labelDateFormat ? this.labelDateFormat : 'LL';

        if (this.customOptions) {
            this.dateOptions = Object.assign(this.dateOptions, this.customOptions);
        }
    }

    public openCalendar(event: Event): void {
        if (this.clickToEdit) {
            // Da der Watcher im clickToEdit modus deaktiviert ist, muessen wir das Date des Datepickers selbst
            // aktualisieren.
            const newMoment = momentFromDateString(this.dateString, this.format);

            if (newMoment?.isValid()) {
                this.dateObject = newMoment.toDate();
            }
        }
        event.preventDefault();
        event.stopPropagation();
        this.opened = true;
    }

    public getLabelText(): string {
        return DvbDateUtil.isValidMoment(this.dateMoment) ?
            `${this.placeholder} ${this.dateMoment.format(this.displayFormat)}` :
            this.placeholder;
    }

    /**
     * This fills in a full digit year in dateString if the user didn't do it himself.
     * The year is prefixed by how many numbers weren't typed by the user. So if he entered 5, the current
     * decade is prefixed. If he entered 15, the current century is prefixed. If he didn't enter anything at
     * all but a digit limiter, the current year is prefixed.
     */
    public prefixYearDigits(): void {
        const parts = this.dateString.split(/[^\d]/);
        const yearString = new Date().getFullYear().toString();

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (parts.length !== 3 || parts[2].length >= yearString.length) {
            return;
        }

        const prefix = yearString.substring(0, Math.max(0, yearString.length - parts[2].length));
        parts[2] = prefix + parts[2];

        this.dateString = parts.join('.');
    }
}

dvbDatePickerTextField.$inject = ['errorService', '$document'];

// eslint-disable-next-line max-lines-per-function
function dvbDatePickerTextField(errorService: ErrorService, $document: angular.IDocumentService): angular.IDirective {

    const directive: angular.IDirective = {
        restrict: 'E',
        replace: true,
        require: {
            ngModelCtrl: 'ngModel',
            ctrl: 'dvbDatepickerTextField',
        },
        scope: {
            dateMoment: '=ngModel',
            placeholder: '@',
            clickToEdit: '<',
            onSubmit: '&?',
            labelDateFormat: '@',
            isValid: '&?',
            isDisabled: '<?',
            format: '<?',
            customOptions: '<?',
            smallInputs: '<?',
        },
        controller: DvbDatepickerTextField,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dvb-datepicker-text-field.html'),
    };

    // eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
    directive.link = (scope: angular.IScope, element: JQuery, attrs: angular.IAttributes, controllers: any): void => {
        const controller: DvbDatepickerTextField = controllers.ctrl;
        const ngModelCtrl: angular.INgModelController = controllers.ngModelCtrl;

        const label = element.find('label');
        const datepicker = element.find('.dvb-datepicker-text-field');
        const textField = element.find('input[type=text]');
        let forceReset = false;
        let isRequired = false;

        const button = element.find('button');
        button.css('border-bottom-right-radius', '4px');
        button.css('border-top-right-radius', '4px');

        attrs.$observe('disabled', value => {
            controller.isDisabled = !!value;
        });

        attrs.$observe('required', value => {
            isRequired = !!value;
        });

        /**
         * Click-To-Edit Logic
         */
        function displayLabel(): void {
            datepicker.hide();
            label.show();
            if (controller.clickToEdit) {
                element.removeClass('dvb-click2edit-open');
            }
        }

        function displayDatePicker(): void {
            label.hide();
            datepicker.show();
            if (controller.clickToEdit) {
                element.addClass('dvb-click2edit-open');
            }
        }

        function isValidByCallback(newMoment: moment.Moment | null): boolean {
            if (TypeUtil.isFunction(controller.isValid)) {
                const valid = controller.isValid({param: newMoment});

                return typeof valid === 'boolean' ? valid : true;
            }

            return true;
        }

        function hasInputChanged(newMoment: moment.Moment | null): boolean {
            return !(
                DvbDateUtil.isValidMoment(newMoment) &&
                DvbDateUtil.isValidMoment(controller.dateMoment) &&
                newMoment.isSame(controller.dateMoment)
            ) && !(
                newMoment === null &&
                controller.dateMoment === null
            );
        }

        function handleError(): void {
            element.addClass('has-error');
            textField.trigger('focus');
            textField.trigger('select');
        }

        function clearError(): void {
            element.removeClass('has-error');
            errorService.clearErrorByMsgKey('ERRORS.ERR_INVALID_DATE');
        }

        function tryToExitEditMode(): void {
            if (forceReset) {
                reset();
            } else {
                const newMoment = momentFromDateString(controller.dateString, controller.format);

                if (hasInputChanged(newMoment)) {
                    if (isNewMomentInvalid(newMoment)) {
                        errorService.addValidationError('ERRORS.ERR_INVALID_DATE');
                        handleError();

                        return;
                    }
                    if (isValidByCallback(newMoment)) {
                        submitChangedValue(newMoment!);
                    } else {
                        handleError();
                    }
                } else {
                    isValidByCallback(newMoment);
                    clearError();
                    displayLabel();
                }
            }
        }

        function isNewMomentInvalid(newMoment: moment.Moment | null): boolean {
            return isRequired && newMoment === null || (newMoment !== null && !DvbDateUtil.isValidMoment(newMoment));
        }

        function reset(): void {
            forceReset = false;
            controller.dateString =
                DvbDateUtil.isValidMoment(controller.dateMoment) ? controller.dateMoment.format(controller.format) : '';
            ngModelCtrl.$setViewValue(controller.dateMoment);
            clearError();
            ngModelCtrl.$render();
            displayLabel();
        }

        function submitChangedValue(newMoment: moment.Moment): void {
            ngModelCtrl.$setViewValue(newMoment);
            ngModelCtrl.$render();
            clearError();
            if (TypeUtil.isFunction(controller.onSubmit)) {
                controller.onSubmit();
            }
            displayLabel();
        }

        function formatter(modelValue: unknown): moment.Moment | null {
            return DvbDateUtil.isValidMoment(modelValue) ? moment(modelValue) : null;
        }

        function parser(viewValue: unknown): moment.Moment | null {
            if (!DvbDateUtil.isValidMoment(viewValue)) {
                return null;
            }

            const tmpMoment = moment(viewValue).startOf('day');

            return tmpMoment.isValid() ? tmpMoment : null;
        }

        if (controller.clickToEdit) {
            // Click on Label triggers edit mode
            label.on('click', () => {
                if (angular.element('.dvb-click2edit-open').length === 0 && !controller.isDisabled) {
                    displayDatePicker();
                    textField.trigger('focus');
                    textField.trigger('select');
                }
            });

            // Trigger Submit on blur
            textField.on('blur', () => {
                scope.$evalAsync(() => {
                    // set timeout is needed, because without it document.activeElement does not yet reference the
                    // button, but the documents body
                    setTimeout(() => {
                        const activeElem = ($document[0] as Document).activeElement;
                        if (activeElem && activeElem !== button[0]) {
                            tryToExitEditMode();
                        }
                    });
                });
            });

            /* eslint-disable @typescript-eslint/no-magic-numbers */
            textField.on('keydown', event => {
                if (event.key === 'Escape') { // Reset on ESC
                    if (isValidByCallback(controller.dateMoment)) { // Quick Fix to remove warnings...
                        forceReset = true;
                        textField.trigger('blur');
                    }
                } else if (event.key === 'Enter') { // Trigger blur on Enter
                    textField.trigger('blur');
                }
            });
            /* eslint-enable @typescript-eslint/no-magic-numbers */

            scope.$watch(() => {
                return controller.opened;
            }, (newValue, oldValue) => {
                if (oldValue && !newValue) {
                    // Wenn das Datepicker Popup schliesst, dann den Fokus wieder in das Textfeld setzen.
                    textField.trigger('fous');
                    textField.trigger('select');
                }
            });
        }

        // Activate the initial display mode
        if (controller.clickToEdit) {
            displayLabel();
        } else {
            displayDatePicker();
        }

        /**
         * ngModelController Logic
         */
        function isInputValid(): boolean {
            return DvbDateUtil.isValidMoment(ngModelCtrl.$viewValue) || ngModelCtrl.$viewValue === null;
        }

        // Formatters are functions that take the $modelValue and return a transformed $viewValue
        // (ngModelCtrl.$viewValue)
        ngModelCtrl.$formatters.push(formatter);

        // Do whatever is needed to display the $viewValue, e.g. simply setting some scope properties, or even
        // DOM manipulations. Will be called by the framework when the external ngModel changes
        ngModelCtrl.$render = () => {
            // Setzte eine Klasse ng-valid-moment bzw. ng-invalid-moment, je nach Rückgabewert von
            // isValidMoment()
            ngModelCtrl.$setValidity('moment', isInputValid());
            if (DvbDateUtil.isValidMoment(ngModelCtrl.$viewValue)) {
                controller.dateString = ngModelCtrl.$viewValue.format(controller.format);
                controller.dateObject = ngModelCtrl.$viewValue.toDate();
            }
        };

        // Convert a $viewValue into the $modelValue (used when ngModelCtrl.$setViewValue is called)
        ngModelCtrl.$parsers.push(parser);

        // Set the view value based on changes of the text-field
        if (!controller.clickToEdit) {
            // Im Click2Edit modus wollen wir den ModelValue nur aktualisieren, wenn er mit Enter bestaetigt
            // wurde
            scope.$watch('vm.dateString', (newDateString: string, oldDateString: string) => {
                if (newDateString === undefined) {
                    ngModelCtrl.$setViewValue(null);

                    return;
                }
                if (angular.equals(newDateString, '')) {
                    ngModelCtrl.$setViewValue(null);
                    ngModelCtrl.$render();

                    return;
                }
                if (oldDateString !== newDateString) {
                    const newValue = momentFromDateString(newDateString, controller.format);
                    if (!DvbDateUtil.isMomentEquals(newValue, ngModelCtrl.$modelValue) ||
                        !DvbDateUtil.isMomentEquals(newValue, ngModelCtrl.$viewValue)) {
                        ngModelCtrl.$setViewValue(newValue);
                        ngModelCtrl.$render();
                    }
                }
            });
        }

        // Set the dateString value based on changes in the datepicker.
        // The $viewValue should only be set through changes in the dateString, to avoid duplicated changes
        // events.
        scope.$watch('vm.dateObject', (newDateObject: Date, oldDateObject: Date) => {
            if (newDateObject !== undefined &&
                (oldDateObject === undefined || isDifferentFromViewValue(newDateObject))) {
                // modify the dateString, which in turn will trigger the water on vm.dateString
                controller.dateString = moment(newDateObject).format(controller.format);
            }
        });

        function isDifferentFromViewValue(newDateObject: Date): boolean {
            return newDateObject.toISOString() !== moment(ngModelCtrl.$viewValue).toISOString();
        }
    };

    return directive;
}

angular.module('kitAdmin').directive('dvbDatepickerTextField', dvbDatePickerTextField);
