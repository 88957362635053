/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRechnungsKonfiguration, KinderOrt, KitaRechnungsKonfiguration} from '@dv/kitadmin/models';
import {KitaRechnungen} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KitaFakturaService} from '../../../../common/service/rest/kinderort/kitaFakturaService';
import type {RechnungenFilter} from '../../../../filter/RechnungenFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        kitaKonfigurationen: '<',
        filter: '<?',
    },
    template: require('./dvb-kita-faktura.html'),
    controllerAs: 'vm',
};

export class DvbKitaFaktura implements angular.IController {
    public static $inject: readonly string[] = ['kitaFakturaService', '$q'];

    public kita!: Persisted<KinderOrt>;
    public kitaKonfigurationen: KitaRechnungsKonfiguration<IRechnungsKonfiguration>[] = [];
    public filter?: RechnungenFilter;

    public rechnungenProSeite: number = 0;
    public kitaRechnungen: KitaRechnungen = new KitaRechnungen();
    public currentPage: number = 1;
    public isLoading: boolean = true;

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        private readonly kitaFakturaService: KitaFakturaService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.timeout = this.$q.defer();

        if (this.filter) {
            this.kitaFakturaService.setRechnungenFilter(this.filter, this.kita.id);
        } else {
            this.filter = this.kitaFakturaService.getRechnungenFilter(this.kita.id);
        }

        this.filter.gueltigkeit.setCurrentMonthWhenUndefined();

        this.rechnungenProSeite = this.kitaFakturaService.getRechnungenProSeite();

        this.reloadRechnungen();
    }

    public updatePage(page: number): void {
        this.currentPage = page;
        this.reloadRechnungen();
    }

    public onUpdateRechnungenProSeite(rechnungenProSeite: number): void {
        this.rechnungenProSeite = rechnungenProSeite;
        this.kitaFakturaService.setRechnungenProSeite(rechnungenProSeite);
        this.reloadRechnungen();
    }

    public reloadRechnungen(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const params = this.filter ? this.filter.toRestObject() : {};
        params.top = this.rechnungenProSeite;
        params.skip = (this.currentPage - 1) * this.rechnungenProSeite;
        params.includes = '(summe,nestedIncludes.fields(emailVersandHistory,ausstehenderBetrag))';

        const config = {timeout: this.timeout.promise};

        this.kitaFakturaService.getRechnungen(this.kita.id, params, config).then(kitaRechnungen => {
            this.kitaRechnungen = kitaRechnungen;
            this.isLoading = false;
        }).catch(err => {
            if (!DvbRestUtil.isRequestCancelled(err)) {
                this.isLoading = false;
            }
        });
    }
}

componentConfig.controller = DvbKitaFaktura;
angular.module('kitAdmin').component('dvbKitaFaktura', componentConfig);
