/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind} from '@dv/kitadmin/models';
import {Geschlecht} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

export class DvbAnmeldungKindForm {
    public kind!: Kind;
    public defaultAnmeldeDatum!: moment.Moment;

    public geschlechte: readonly Geschlecht[] = Object.values(Geschlecht);
}

angular.module('kitAdmin').directive('dvbAnmeldungKindForm', dvbAnmeldungKindForm);

function dvbAnmeldungKindForm(): angular.IDirective {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            kind: '=',
            defaultAnmeldeDatum: '=',
        },
        template: require('./dvb-anmeldung-kind-form.html'),
        controller: DvbAnmeldungKindForm,
        controllerAs: 'vm',
        bindToController: true,
    };
}
