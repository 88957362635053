/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsGrund} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {BetreuungsgruendeService} from '../../../common/service/rest/kinderort/betreuungsgruendeService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsgruendeIds: '=',
        onChange: '&',
    },
    template: require('./dvb-betreuungsgrund.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsgrund implements angular.IOnInit {
    public static $inject: readonly string[] = ['betreuungsgruendeService'];

    public betreuungsgruendeIds!: string[];
    public onChange!: (val: { prioritaet: number }) => void;

    public betreuungsgruende: BetreuungsGrund[] = [];

    public constructor(private betreuungsgruendeService: BetreuungsgruendeService) {
    }

    public $onInit(): void {
        this.betreuungsgruendeService.getAll().then(betreuungsgruende => {
            this.betreuungsgruende = betreuungsgruende;
        });
    }

    public isChecked(key: string): boolean {
        return this.betreuungsgruendeIds.includes(key);
    }

    public valueChanged(value: BetreuungsGrund): void {

        const grundId = checkPresent(value.id);
        const index = this.betreuungsgruendeIds.indexOf(grundId);

        if (index > -1) {
            this.betreuungsgruendeIds.splice(index, 1);
        } else {
            this.betreuungsgruendeIds.push(grundId);
        }

        const maxPriority = 4;

        const priority = Math.min(maxPriority, ...this.betreuungsgruende
                .filter(b => this.betreuungsgruendeIds.includes(checkPresent(b.id)))
                .map(b => b.prioritaet ?? maxPriority));

        this.onChange({prioritaet: priority});
    }
}

componentConfig.controller = DvbBetreuungsgrund;
angular.module('kitAdmin').component('dvbBetreuungsgrund', componentConfig);
