/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IKibonRefNrInfo, KibonExchangeEntity} from '@dv/kitadmin/models';
import {KibonExchangeGesuchsteller, KibonExchangeInstitution, KibonExchangeKind} from '@dv/kitadmin/models';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export enum BetreuungAnfrageState {
    PENDING = 'PENDING',
    ASSIGNED = 'ASSIGNED',
    PREPARED = 'PREPARED',
    COMPLETED = 'COMPLETED',
    IGNORED = 'IGNORED',
}

export class KibonExchangeBetreuungAnfrage implements KibonExchangeEntity, IKibonRefNrInfo {

    public constructor(
        public id: string,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public refNr: string,
        public institution: KibonExchangeInstitution,
        public angefragtAm: moment.Moment,
        public timestampErstellt: moment.Moment,
        public kind: KibonExchangeKind,
        public gesuchsteller: KibonExchangeGesuchsteller,
        public betreuungsArt: string,
        public abgelehntVonGesuchsteller: boolean,
        public state: BetreuungAnfrageState,
        public assignedKindId: string | null = null,
        public assignable: boolean = false,
        public unassignable: boolean = false,
        public ignorable: boolean = false,
        public unignorable: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeBetreuungAnfrage {
        return new KibonExchangeBetreuungAnfrage(
            data.id,
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
            data.refNr,
            KibonExchangeInstitution.apiResponseTransformer(data.institution),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.angefragtAm)),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampErstellt)),
            KibonExchangeKind.apiResponseTransformer(data.kind),
            KibonExchangeGesuchsteller.apiResponseTransformer(data.gesuchsteller),
            data.betreuungsArt,
            data.abgelehntVonGesuchsteller,
            data.state,
            data.assignedKindId,
            data.assignable,
            data.unassignable,
            data.ignorable,
            data.unignorable,
        );
    }

    public get kibonExportAm(): moment.Moment {
        return this.angefragtAm;
    }

    public canAssign(): boolean {
        return this.assignable;
    }

    public canUnAssign(): boolean {
        return this.unassignable;
    }

    public canIgnore(): boolean {
        return this.ignorable;
    }

    public canUnIgnore(): boolean {
        return this.unignorable;
    }

    public canDecline(): boolean {
        return false;
    }

    public getRefNr(): string {
        return this.refNr;
    }

    public canAnmelden(): boolean {
        return false;
    }
}
