/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent, DvbError} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {BewerbungenService} from '../../common/service/rest/kind/bewerbungenService';

export class ZuweisenUtil {
    public static $inject: readonly string[] = [
        'errorService',
        '$q',
        'dialogService',
        'bewerbungenService',
    ];

    public constructor(
        private errorService: ErrorService,
        private $q: angular.IQService,
        private dialogService: DialogService,
        private bewerbungenService: BewerbungenService,
    ) {
    }

    public validateZuweisung<T>(
        zuweisungAb: moment.Moment | undefined | null,
        kind: Kind,
        builder: () => T,
    ): angular.IPromise<T> {

        this.errorService.clearErrorByMsgKey('ERRORS.ERR_REQUIRED_BELEGUNGSEINHEIT');
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_BELEGUNGSEINHEIT_CONFLICT');
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_NO_PLATZ_FOR_ZEITRAUMFELDER');
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_BELEGUNG_BEFORE_BIRTHDAY');

        if (!zuweisungAb) {
            this.errorService.addValidationError('KIND.ZUWEISUNG.KEIN_ZUWEISUNGSDATUM_AUSGEWAEHLT');

            return this.$q.reject();
        }

        const geburtstag = kind.geburtsTag ?? kind.geburtsTermin;

        if (!geburtstag || zuweisungAb.isBefore(geburtstag)) {
            this.errorService.addValidationError('ERRORS.ERR_BELEGUNG_BEFORE_BIRTHDAY');

            return this.$q.reject();
        }

        try {
            return this.$q.resolve(builder());
        } catch (err) {
            if (err instanceof DvbError) {
                this.errorService.handleError(err);

                return this.$q.reject();
            }

            throw err;
        }
    }

    public showSuccessDeleteBewerbungModal$(kind: Kind): Observable<boolean> {

        const bewerbungId = checkPresent(checkPresent(kind.bewerbung).id);

        const dialogResult$ = new Subject<boolean>();

        this.dialogService.openConfirmDialog({
            title: 'KIND.ZUWEISUNG.ZUWEISUNG_SUCCESS',
            confirmActionText: 'KIND.ZUWEISUNG.ZUWEISUNG_DELETE_BETREUUNGSWUNSCH',
            cancelActionText: 'KIND.ZUWEISUNG.ZUWEISUNG_KEEP_BETREUUNGSWUNSCH',
            confirm: () => from(this.bewerbungenService.deleteBewerbung(bewerbungId))
                .pipe(tap(() => {
                    kind.bewerbung = null;
                    dialogResult$.next(true);
                })),
            cancel: () => {
                dialogResult$.next(false);
            },
        });

        return dialogResult$.pipe(take(1));
    }
}
