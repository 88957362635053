/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FilterOption} from '../../../filter/shared/FilterOption';
import type {FilterProperties} from './FilterProperties';

/**
 * A FilterOption, specific for FilterProperties
 */
export class KindFilterOption extends FilterOption {

    public constructor(
        public override id: keyof FilterProperties,
        name: string,
        selected: boolean,
        sticky: boolean,
    ) {
        super(id, name, selected, sticky);
    }
}
