<div class="dvb-kita-faktura">

    <div class="row big-abstand-bottom">
        <div class="col-xs-12">
            <dvb-kita-faktura-header
                filter="vm.filter"
                kita="vm.kita"
                has-kita-rechnungskonfiguration="vm.kitaKonfigurationen.length > 0"
                on-update="vm.updatePage(1)">
            </dvb-kita-faktura-header>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">

            <dvb-pages items="vm.kitaRechnungen.rechnungen"
                       total-items="vm.kitaRechnungen.stats.count"
                       is-loading="vm.isLoading"
                       items-per-page="vm.rechnungenProSeite"
                       current-page="vm.currentPage"
                       entity-name="'COMMON.RECHNUNG.MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateRechnungenProSeite(itemsPerPage)">
                <page-items>
                    <dvb-rechnungen-list rechnungen="vm.kitaRechnungen.rechnungen">
                    </dvb-rechnungen-list>
                </page-items>
                <total-summary>
                    <dvb-list-summary>
                        <div class="row floating-text">
                            <div class="col-xs-5 col-sm-9 col-xs-offset-1">
                                <strong data-translate="KINDERORT.MF_RECHNUNGEN_TOTAL_BETRAG"
                                        data-translate-value-count="{{vm.kitaRechnungen.stats.count | number:'0'}}"
                                        data-translate-interpolation="messageformat">
                                </strong>
                            </div>
                            <div class="col-xs-2 text-right text-nowrap">
                                <strong data-translate="COMMON.BETRAG_VALUE"
                                        data-translate-value-betrag="{{vm.kitaRechnungen.stats.summe | number:'2'}}">
                                </strong>
                            </div>
                        </div>
                    </dvb-list-summary>
                </total-summary>
            </dvb-pages>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <dvb-kita-faktura-rechnungslauf
                kita="vm.kita"
                kita-konfigurationen="vm.kitaKonfigurationen"
                gueltig-ab="vm.filter.gueltigkeit.gueltigAb"
                gueltig-bis="vm.filter.gueltigkeit.gueltigBis">
            </dvb-kita-faktura-rechnungslauf>

            <dvb-kita-faktura-mahnlauf filter="vm.filter"
                                       total-items="vm.kitaRechnungen.stats.count"
                                       is-loading="vm.isLoading">
            </dvb-kita-faktura-mahnlauf>

            <dvb-kita-faktura-massenaktionen filter="vm.filter"
                                             kita="vm.kita"
                                             kita-rechnungen="vm.kitaRechnungen">
            </dvb-kita-faktura-massenaktionen>

        </div>
    </div>
</div>
