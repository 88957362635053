<div class="dvb-report-offene-posten">
    <h2 data-translate="REPORT.KONTAKTPERSONEN_ZAHLUNGEN.TITLE"></h2>

    <form name="vm.kontaktpersonZahlungenReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-kitas-selection parameter-kinder-orte="vm.parameterKinderOrte"
                             is-selection-required="!vm.parameterKinderOrte.isValid()"
                             on-change="vm.updateState()">
        </dvb-kitas-selection>

        <div class="checkbox">
            <label>
                <input type="checkbox"
                       ng-model="vm.includePayedRueckerstattungen">
                <span data-translate="REPORT.KONTAKTPERSONEN_ZAHLUNGEN.INCLUDE_PAYED_RUECKERSTATTUNGEN"></span>
            </label>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()" class="inline-block normal-abstand-top">
        </dvb-report-download>
    </form>
</div>
