/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsPerson, LimitedAdresse} from '@dv/kitadmin/models';
import type {Adresse} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {AddressService} from '../../../../../common/service/rest/addressService';
import type {FraktionService} from '../../../../../common/service/rest/kinderort/fraktionService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsPerson: '<',
    },
    template: require('./dvb-betreuungsperson-kontakt.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungspersonKontakt implements angular.IController {
    public static $inject: readonly string[] = ['fraktionService', 'addressService', '$state'];

    public betreuungsPerson!: Readonly<BetreuungsPerson>;

    public showUmzug: boolean = false;

    public constructor(
            private fraktionService: FraktionService,
            private addressService: AddressService,
            private $state: StateService,
    ) {
    }

    public updateLimitedAdresse(limitedAdresse: LimitedAdresse, adresse: Adresse): angular.IPromise<void> {
        return this.addressService.korrigiereAdresse(adresse).then(() => {
            limitedAdresse.adresse = adresse;
        });
    }

    public deleteLimitedAdresse(limitedAdresse: LimitedAdresse): angular.IPromise<unknown> {
        const betreuungsPersonId = checkPresent(this.betreuungsPerson.id);

        return this.fraktionService.deleteBetreuungsPersonAdresse(betreuungsPersonId, limitedAdresse)
                .then(() => this.$state.reload());
    }

    public createAdresse(adresse: LimitedAdresse): angular.IPromise<unknown> {
        const betreuungsPersonId = checkPresent(this.betreuungsPerson.id);

        return this.fraktionService.createBetreuungsPersonAdresse(betreuungsPersonId, adresse).finally(() => {
            this.showUmzug = false;

            return this.$state.reload();
        });
    }
}

componentConfig.controller = DvbBetreuungspersonKontakt;
angular.module('kitAdmin').component('dvbBetreuungspersonKontakt', componentConfig);
