/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BelegteEinheit, ExternalAnmeldung, ISelectedFirma, Kind} from '@dv/kitadmin/models';
import {Bewerbung, Firma, isBewerbungsplanError, Kita} from '@dv/kitadmin/models';
import type {Persisted, SearchResultEntry} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbBewerbungsplanService} from '../../../common/service/dvbBewerbungsplanService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {BetreuungsgruendeService} from '../../../common/service/rest/kinderort/betreuungsgruendeService';
import {AbstractBewerbungsplanController} from '../../models/AbstractBewerbungsplanController';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        wochenplan: '<',
        externalAnmeldung: '<?',
    },
    template: require('./dvb-kind-neue-bewerbung.html'),
    controllerAs: 'vm',
};

export class DvbKindNeueBewerbung extends AbstractBewerbungsplanController implements angular.IController {

    public static override $inject: readonly string[] = [
        '$scope',
        'kindService',
        'dvbBewerbungsplanService',
        'betreuungsgruendeService',
        '$state',
        'errorService',
        '$q',
    ];

    public kind!: Persisted<Kind>;
    public externalAnmeldung?: ExternalAnmeldung;

    public bewerbung = new Bewerbung();

    public firstOfWeek = DvbDateUtil.startOfWeek();

    public isLoading = false;

    public pensumAutomaticallyGenerated: boolean = false;

    public constructor(
        private readonly $scope: angular.IScope,
        private readonly kindService: KindService,
        private readonly dvbBewerbungsplanService: DvbBewerbungsplanService,
        private readonly betreuungsgruendeService: BetreuungsgruendeService,
        private readonly $state: StateService,
        errorService: ErrorService,
        private readonly $q: angular.IQService,
    ) {
        super(errorService);
    }

    public override $onInit(): void {
        super.$onInit();

        this.initWithExternalAnmeldung();

        this.betreuungsgruendeService.getAll()
            .then(betreuungsgruende => {
                this.bewerbung.prioritaet = 4;
                betreuungsgruende.forEach(betreuungsgrund => {
                    // eslint-disable-next-line sonarjs/no-collapsible-if
                    if (betreuungsgrund.prioritaet &&
                        betreuungsgrund.prioritaet > 0 &&
                        betreuungsgrund.prioritaet < this.bewerbung.prioritaet) {
                        if (this.kind.betreuungsGruendeIds.includes(betreuungsgrund.id)) {
                            this.bewerbung.prioritaet = betreuungsgrund.prioritaet;
                        }
                    }
                });
            });
    }

    public addFirmaToSelection(firmaItem: SearchResultEntry): void {
        const firma = checkPersisted(new Firma(firmaItem.id, firmaItem.text));
        const firmaSelected: ISelectedFirma = Object.assign(firma, {selected: true});
        this.bewerbung.addFirmaToSelection(firmaSelected);
    }

    public addKita(kitaItem: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = kitaItem.text;
        kita.id = kitaItem.id;
        this.bewerbung.addKita(kita);
    }

    public goBack(): angular.IPromise<unknown> {
        return this.$state.go('base.kind.betreuung', {kindId: this.kind.id});
    }

    public validateAndSaveBewerbung(formIsValid: boolean): angular.IPromise<unknown> {
        this.bewerbung.firmenSelection.filter(firma => {
            return firma.selected;
        }).forEach(firma => {
            this.bewerbung.addFirma(firma);
        });

        const isValid = formIsValid && this.bewerbung.isValid() && this.bewerbung.hasGewuenschteBetreuungAb();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_BEWERBUNG');

        let belegteEinheiten: BelegteEinheit[] | null;
        try {
            belegteEinheiten = this.dvbBewerbungsplanService.belegteEinheitenFromZeitraumFelder(
                this.wochenplan, this.zeitraumFelder);
        } catch (err) {
            if (isBewerbungsplanError(err)) {
                this.addBelegteEinheitenError(err);

                return this.$q.resolve();
            }
            throw err;
        }

        const isValidBelegteEinheiten = Array.isArray(belegteEinheiten) && belegteEinheiten.length > 0;
        this.errorService.handleValidationError(isValidBelegteEinheiten, 'ERRORS.ERR_REQUIRED_BELEGUNGSEINHEIT');

        if (isValid && isValidBelegteEinheiten) {
            this.bewerbung.belegteEinheiten = belegteEinheiten ?? [];
            this.bewerbung.wochenplanId = this.wochenplan.id;
            this.isLoading = true;

            return this.kindService.createBewerbung(this.kind.id, this.bewerbung).then(response => {
                this.bewerbung.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);
                this.kind.bewerbung = this.bewerbung;
                this.$scope.$emit('betreuungsfaktorChange');

                return this.goBack();
            }).finally(() => {
                this.isLoading = false;
            });
        }

        return this.$q.resolve();
    }

    private initWithExternalAnmeldung(): void {
        if (!this.externalAnmeldung) {
            return;
        }

        this.dvbBewerbungsplanService.setSelectionToZeitraumFelder(
            this.wochenplan,
            this.externalAnmeldung.belegteEinheiten,
            this.zeitraumFelder);

        this.externalAnmeldung.kinderOrte.forEach(kinderOrt => this.bewerbung.addKita(kinderOrt));
        this.bewerbung.pensum.von = this.externalAnmeldung.pensum.von;

        if (this.externalAnmeldung.pensum.bis === null) {
            this.bewerbung.pensum.bis = checkPresent(this.externalAnmeldung.pensum.von);
            this.pensumAutomaticallyGenerated = true;
        } else {
            this.bewerbung.pensum.bis = this.externalAnmeldung.pensum.bis;
        }

        this.bewerbung.subventionierterPlatz = this.externalAnmeldung.subventionierterPlatz;
        this.bewerbung.privaterPlatz = this.externalAnmeldung.privaterPlatz;
        this.bewerbung.gewuenschteBetreuungAb = this.externalAnmeldung.gewuenschteBetreuungAb;
        this.externalAnmeldung.platzarten.forEach(p =>
            this.bewerbung.addFirmaToSelection(Object.assign(checkPersisted(p), {selected: true})));
    }
}

componentConfig.controller = DvbKindNeueBewerbung;
angular.module('kitAdmin').component('dvbKindNeueBewerbung', componentConfig);
