/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IPersistable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, Zeitraum} from '@dv/shared/code';
import type moment from 'moment';

export enum KibonExchangeModulIntervall {
    WOECHENTLICH = 'WOECHENTLICH',
    ALLE_ZWEI_WOCHEN = 'ALLE_ZWEI_WOCHEN',
}

export class KibonExchangeModul extends Zeitraum implements IPersistable {

    public override durationInMinutes: number = 0;

    public constructor(
        public override id: string | null = null,
        public fremdId: string | null = null,
        public override name: string | null = null,
        von: moment.Moment,
        bis: moment.Moment,
        public wochentage: DayOfWeek[] = [],
        public erlaubteIntervalle: KibonExchangeModulIntervall[] = [],
    ) {
        super(von, bis);
    }

    public static apiResponseTransformer(data: any): KibonExchangeModul {
        return new KibonExchangeModul(
                data.id,
                data.fremdId,
                data.bezeichnungDE,
                checkPresent(DvbRestUtil.localeHHMMTimeToMoment(data.zeitVon)),
                checkPresent(DvbRestUtil.localeHHMMTimeToMoment(data.zeitBis)),
                data.wochentage,
                data.erlaubteIntervalle,
        );
    }
}
