/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameter} from '@dv/kitadmin/models';
import type {
    IPersistable,
    IRestModel,
    KorrekturObject,
    KorrekturType,
    Persisted,
    TimeRangeRestModel,
} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, isPresent, Korrektur, KORREKTUR_TYPE, TimeRange} from '@dv/shared/code';
import type moment from 'moment';

export class KindBetreuungErfassung implements IPersistable, IRestModel {

    // optional parameters for form
    public tarifParams?: Persisted<TarifParameter>[];

    public constructor(
        public id: string | null = null,
        public kinderOrtFraktionId: string,
        public stichtag: moment.Moment,
        public stunden: Korrektur<number> = new Korrektur(),
        public bemerkung: Korrektur<string> = new Korrektur(),
        public spesenByTarifParamId: { [tarifParameterId: string]: Korrektur<number> } = {},
        public vonBisZeiten: Korrektur<TimeRange[]> = new Korrektur([], []),
    ) {
    }

    public static apiResponseTransformer(data: any): KindBetreuungErfassung {
        const answer = new KindBetreuungErfassung(
            data.id,
            data.kinderOrtFraktionId,
            checkPresent(DvbRestUtil.localDateToMoment(data.stichtag)),
        );

        answer.stunden = Korrektur.apiResponseTransformer(data.stunden);
        answer.bemerkung = Korrektur.apiResponseTransformer(data.bemerkung);

        Object.entries(data.spesenByTarifParamId).forEach(([tarifParamId, spesen]) => {
            answer.spesenByTarifParamId[tarifParamId] = Korrektur.apiResponseTransformer(spesen);
        });

        Object.entries(data.vonBisZeiten).forEach(([korrekturType, vonBisZeiten]) => {
            if (Array.isArray(vonBisZeiten)) {
                answer.vonBisZeiten[KORREKTUR_TYPE.check(korrekturType.toLowerCase())] = vonBisZeiten
                    .map(TimeRange.apiResponseTransformer);
            }
        });

        return answer;
    }

    public toRestObject(): Record<string, unknown> {
        const spesenByTarifParamId: { [tarifParameterId: string]: KorrekturObject<number | null> } = {};
        Object.entries(this.spesenByTarifParamId).forEach(([tarifParamId, spesen]) => {
            if (spesen.hasValue()) {
                spesenByTarifParamId[tarifParamId] = spesen.toRestObject(a => a);
            }
        });

        const vonBisZeiten: { [k in Uppercase<KorrekturType>]: TimeRangeRestModel[] } = {ORIGINAL: [], CURRENT: []};
        Object.entries(this.vonBisZeiten).forEach(([korrekturType, timeRanges]) => {
            const key = KORREKTUR_TYPE.check(korrekturType).toUpperCase() as Uppercase<KorrekturType>;
            vonBisZeiten[key] = timeRanges.map(TimeRange.toApiTransformer)
                .filter(isPresent);
        });

        return {
            id: this.id,
            kinderOrtFraktionId: this.kinderOrtFraktionId,
            stichtag: DvbRestUtil.momentToLocalDate(this.stichtag),
            stunden: this.stunden.toRestObject(a => a),
            bemerkung: this.bemerkung.toRestObject(a => a),
            spesenByTarifParamId,
            vonBisZeiten,
        };
    }

    /**
     * Convenience method for the form. Binds tarifParameters to make them easily accessible and adds a Korrektur
     * entry to spesen for each not yet existing parameter.
     */
    public initSpesen(tarifParameter: Persisted<TarifParameter>[]): void {
        this.tarifParams = tarifParameter;

        tarifParameter.forEach(param => {
            this.spesenByTarifParamId[param.id] ??= new Korrektur();
        });
    }

    /**
     * @return true if stunden or spesen are set. vonBisZeiten are ignored with the assumption, that their sum is
     *     stored as stunden
     */
    public hasValue(): boolean {
        return this.stunden.hasValue()
            || Object.values(this.spesenByTarifParamId).some(spesen => spesen.hasValue());
    }

    public hasBemerkungOriginal(): boolean {
        return isPresent(this.bemerkung.original) && this.bemerkung.original.trim().length > 0;
    }

    public hasBemerkungCurrent(): boolean {
        return isPresent(this.bemerkung.current) && this.bemerkung.current.trim().length > 0;
    }
}
