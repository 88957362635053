/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    AnmeldungAdresseHousehold,
    ExternalHousehold,
    IExternalAnmeldung,
    IExternalAnmeldungService,
    Kind,
    Kontaktperson,
} from '@dv/kitadmin/models';
import {RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, isNullish} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {finalize, from, Subject, switchMap, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KindService} from '../../common/service/rest/kind/kindService';
import type {KindValidationService} from '../service/kindValidationService';

export abstract class AbstractAnmeldungUebernahme<T extends IExternalAnmeldung> {

    public isAssigned: boolean = false;
    public showAssigned: boolean = false;
    public showImport: boolean = false;
    public defaultAnmeldeDatum: moment.Moment = DvbDateUtil.today();
    public suggestedHouseholds: AnmeldungAdresseHousehold[] = [];
    public neuesKindErfassen: boolean = false;
    public selectedHousehold?: AnmeldungAdresseHousehold;
    public kind?: Kind;
    public isLoading: boolean = false;
    public anmeldung?: ExternalHousehold;
    public uneditableAdresse?: boolean;

    protected constructor(
        protected service: IExternalAnmeldungService,
        protected kindService: KindService,
        protected kindValidationService: KindValidationService,
        protected errorService: ErrorService,
        protected dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        this.defaultAnmeldeDatum = this.getExternalAnmeldung().getAnmeldeDatum() ?? DvbDateUtil.today();
        this.anmeldung = this.getExternalAnmeldung().toExternalHousehold();

        if (this.getExternalAnmeldung().assignedKindId) {
            this.isAssigned = true;
            this.isLoading = true;

            this.setKind();
        } else {
            this.service.findAnmeldungAdresseHousehold(this.getExternalAnmeldung().id!)
                .then(households => {
                    this.suggestedHouseholds = households;
                });
        }

        this.reset();
    }

    public reset(): void {
        this.showImport = this.getExternalAnmeldung().canImport();
        this.neuesKindErfassen = false;
        this.showAssigned = this.isAssigned;
        this.selectedHousehold = undefined;
    }

    public showKindErfassenForm(): void {
        this.showImport = false;
        this.showAssigned = false;
        this.neuesKindErfassen = true;
    }

    public assignSelectedKind(kind: Persisted<Kind>): void {
        if (isNullish(kind)) {
            this.errorService.handleValidationError(false, 'ERRORS.ERR_KIND_NOT_FIND');

            return;
        }

        this.confirmAssignment(kind);
    }

    public addToHousehold(household: AnmeldungAdresseHousehold): void {
        this.selectedHousehold = household;
        this.showKindErfassenForm();
    }

    public validateAndSaveKind(formIsValid: boolean): void {
        const kind = checkPresent(this.kind);
        const isValidKind = formIsValid && kind.isValid();
        const isValidRechnungsAufteilung = this.kindValidationService.hasValidRechnungsempfaenger(kind);
        const hasValidErziehungsberechtigte = this.kindValidationService.hasValidErziehungsberechtigte(kind);
        this.errorService.handleValidationError(isValidKind,
            'ERRORS.ERR_INCOMPLETE_ANMELDUNG');
        this.errorService.handleValidationError(isValidRechnungsAufteilung,
            'ERRORS.ERR_INVALID_RECHNUNGSAUFTEILUNG');
        this.errorService.handleValidationError(hasValidErziehungsberechtigte,
            'ERRORS.ERR_INVALID_ERZIEHUNGSBERECHTIGTE');

        if (isValidKind && isValidRechnungsAufteilung && hasValidErziehungsberechtigte) {
            this.isLoading = true;

            from(this.kindService.matchingKind(kind)).pipe(
                switchMap(kinder => kinder.length > 0 ? this.showConfirmSaveKindModal$() : this.saveKind()),
                finalize(() => {
                    this.isLoading = false;
                }),
            ).subscribe();
        }
    }

    public showConfirmSaveKindModal$(): Observable<boolean> {

        const confirm$ = new Subject<boolean>();
        this.dialogService.openConfirmDialog({
            title: 'KIND.KIND_WURDE_BEREITS_EINMAL_ERFASST',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm: () => {
                return from(this.saveKind())
                    .pipe(tap(() => confirm$.next(true)));
            },
            cancel: () => confirm$.next(false),
        });

        return confirm$.pipe(take(1));
    }

    protected createSonstigerKontaktRelationship(kontaktperson: Persisted<Kontaktperson>):
        RelationshipWithKontaktperson {

        const kontakt = new RelationshipWithKontaktperson();
        kontakt.kontaktperson = kontaktperson;
        kontakt.kontaktpersonId = kontaktperson.id;
        kontakt.relationship.createSonstigerKontakt();

        return kontakt;
    }

    private saveKind(): angular.IPromise<any> {
        const kind = checkPresent(this.kind);
        kind.anmeldeDatum = kind.anmeldeDatum ?? this.defaultAnmeldeDatum;

        return this.kindService.create(kind).then(response => {
            const kindId = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);

            return this.assignKindAndChangeState(kindId);
        });
    }

    private setKind(): void {
        if (this.getExternalAnmeldung().getAssignedKind()) {
            this.kind = checkPresent(this.getExternalAnmeldung().getAssignedKind());
            this.isLoading = false;

            return;
        }

        this.kindService.get(this.getExternalAnmeldung().assignedKindId!).then(kind => {
            this.kind = kind;
            this.isLoading = false;
        });
    }

    protected abstract confirmAssignment(kind: Persisted<Kind>): void;

    protected abstract assignKindAndChangeState(kindId: string): angular.IPromise<unknown>;

    protected abstract getExternalAnmeldung(): T;
}
