/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPersisted, checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../../config';
import {BetreuungsSchluessel} from '../models/BetreuungsSchluessel';

export class BetreuungsSchluesselService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/betreuungsschluessel`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public getAll(params?: RestInclude): angular.IPromise<BetreuungsSchluessel[]> {
        return DvbRestUtilAngularJS.getPagedItems(BetreuungsSchluesselService.BASE_URL, BetreuungsSchluessel, params)
            .then(response => response.items);
    }

    public get(id: string, params?: RestInclude): angular.IPromise<Persisted<BetreuungsSchluessel>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(
            BetreuungsSchluesselService.BASE_URL,
            BetreuungsSchluessel,
            id,
            params)
            .then(checkPersisted);
    }

    public create(betreuungsSchluessel: BetreuungsSchluessel): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = BetreuungsSchluesselService.BASE_URL;

        return this.$http.post(url, betreuungsSchluessel.toRestObject());
    }

    public update(betreuungsSchluessel: BetreuungsSchluessel): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsSchluesselService.BASE_URL}/${encodeURIComponent(checkPresent(betreuungsSchluessel.id))}`;

        return this.$http.put(url, betreuungsSchluessel.toRestObject());
    }

    public delete(betreuungsSchluessel: BetreuungsSchluessel): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BetreuungsSchluesselService.BASE_URL}/${encodeURIComponent(checkPresent(betreuungsSchluessel.id))}`;

        return this.$http.delete(url);
    }
}
