/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../config';

export class RefNrService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kibon/refnr`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getGemeindeName(gemeindeSequenceNr: string): angular.IPromise<string> {
        const url = `${RefNrService.BASE_URL}/gemeinde/${encodeURIComponent(gemeindeSequenceNr)}`;

        return this.$http.get(url, {cache: true})
            .then(response => DvbUtil.isNotEmptyString(response.data) ? response.data : '');
    }
}
