<div class="dvb-new-password">
    <div class="row">
        <div class="col-md-12">
            <input type="password"
                   class="form-control input-large"
                   name="password"
                   autocomplete="new-password"
                   ng-change="vm.onChange()"
                   ng-model="vm.ngModelCtrl.$viewValue.newPassword"
                   maxlength="255"
                   ng-attr-placeholder="{{'AUTHENTICATION.LOGIN_PASSWORD' | translate}}"
                   uib-tooltip="{{'AUTHENTICATION.LOGIN_PASSWORD' | translate}}"
                   tooltip-placement="top-left"
                   required>
        </div>
        <div class="col-md-12">
            <input type="password"
                   class="form-control input-large"
                   name="confirmedPassword"
                   ng-change="vm.onChange()"
                   ng-model="vm.ngModelCtrl.$viewValue.confirmedPassword"
                   maxlength="255"
                   ng-attr-placeholder="{{'BENUTZER.CONFIRM_PASSWORD' | translate}}"
                   uib-tooltip="{{'BENUTZER.CONFIRM_PASSWORD' | translate}}"
                   tooltip-placement="top-left"
                   required>
        </div>
    </div>
</div>
