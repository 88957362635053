/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {RechnungsStatus} from '@dv/kitadmin/models';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {RechnungenFilter} from '../../../../../filter/RechnungenFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        filter: '<',
        totalItems: '<',
        isLoading: '<',
    },
    template: require('./dvb-kita-faktura-mahnlauf.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaMahnlauf implements angular.IController {

    public static $inject: readonly string[] = ['$state'];

    public filter!: RechnungenFilter;
    public totalItems!: number;
    public isLoading: boolean = false;

    public mahnungStates: RechnungsStatus[] = [];

    public constructor(
        private readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        this.mahnungStates = [
            RechnungsStatus.EXTERNAL_STATUS.ERINNERT,
            RechnungsStatus.EXTERNAL_STATUS.MAHNUNG1,
            RechnungsStatus.EXTERNAL_STATUS.MAHNUNG2,
            RechnungsStatus.EXTERNAL_STATUS.MAHNUNG3,
            RechnungsStatus.EXTERNAL_STATUS.BETRIEBEN,
        ];
    }

    public startMahnlauf(status: RechnungsStatus): void {
        this.$state.go('base.kinderort.faktura.mahnlauf', {
            status: status.toRestObject().status,
            filter: this.filter.toUriParam(),
        });
    }
}

componentConfig.controller = DvbKitaFakturaMahnlauf;
angular.module('kitAdmin').component('dvbKitaFakturaMahnlauf', componentConfig);
