/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungAnfrageState} from './KibonExchangeBetreuungAnfrage';
import {KibonGenerationContextResult} from './KibonGenerationContextResult';

export class BetreuungAnfrageProcessingResult {

    public constructor(
        public state: BetreuungAnfrageState,
        public generationResult: KibonGenerationContextResult | null,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungAnfrageProcessingResult {
        return new BetreuungAnfrageProcessingResult(
            data.state,
            data.generationResult ? KibonGenerationContextResult.apiResponseTransformer(data.generationResult) : null,
        );
    }
}
