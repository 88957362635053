<div class="dvb-kita-faktura-dvb-content">
    <p>
        <strong data-translate="KINDERORT.RECHNUNG_RUNDUNGSWERT"></strong>:
        <span ng-bind="vm.konfiguration.totalBetragIncrement"></span>
    </p>

    <p>
        <strong data-translate="COMMUNICATION.EMAIL.ABSENDER_ADRESSE"></strong>:
        <span data-translate="{{'COMMON.ADRESSE_' + vm.konfiguration.absenderAdresse.context}}"></span>
    </p>

    <p>
        <strong data-translate="COMMON.EINZAHLUNGSSCHEIN"></strong>:
        <span ng-bind="vm.konfiguration.esrName || ('KINDERORT.KEIN_EINZAHLUNGSSCHEIN' | translate)"></span>
    </p>

    <p>
        <strong data-translate="KINDERORT.LAYOUT"></strong>:
        <span ng-bind="vm.konfiguration.layoutName || ('KINDERORT.KEIN_LAYOUT' | translate)"></span>
    </p>

    <p>
        <strong data-translate="FAKTURA.KONTO.RUECKZAHLUNGSKONTO"></strong>:
        <span ng-bind="vm.konto.bezeichnung || ('FAKTURA.KONTO.MANDANT_DEFAULT' | translate)"></span>
    </p>

    <ul class="list-inline small-abstand-top" ng-if="vm.konfiguration.massenversandText">
        <li>
            <strong data-translate="KINDERORT.MASSENVERSAND"></strong>:
            <span ng-bind="vm.konfiguration.massenversandText"></span>
        </li>
    </ul>

    <p ng-if="!!vm.konfiguration.zahlungsKonditionen.hasTranslations()" class="small-abstand-top">
        <strong data-translate="KINDERORT.ZAHLUNGS_ANGABEN"></strong>:<br>
        <dvb-translatable-label translation="vm.konfiguration.zahlungsKonditionen"></dvb-translatable-label>
    </p>

    <p ng-if="!!(vm.konfiguration.footer.hasTranslations())" class="small-abstand-top">
        <strong data-translate="DOKUMENT_LAYOUT.FUSSZEILE"></strong>:<br>
        <dvb-translatable-label translation="vm.konfiguration.footer"></dvb-translatable-label>
    </p>

    <dvb-kita-faktura-texte-content konfiguration="vm.konfiguration">
    </dvb-kita-faktura-texte-content>

    <p ng-if="vm.konfiguration.hideSummary">
        <strong data-translate="KINDERORT.KONFIGURATION_SUMMARY_AUSGEBLENDET"></strong>
    </p>

    <p ng-if="!vm.konfiguration.hideSummary && vm.konfiguration.hideCustomerNumber">
        <strong data-translate="KINDERORT.KONFIGURATION_KUNDENNR_AUSGEBLENDET"></strong>
    </p>

</div>
