/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxParameterKindergartenkinder} from '@dv/shared/backend/model/jax-parameter-kindergartenkinder';
import type {KindergartenLocation, KindergartenType, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type moment from 'moment';
import {ParameterKinderOrte} from './ParameterKinderOrte';

export class ParameterKindergartenkinder extends ParameterKinderOrte {

    public constructor(
        public override alleKinderOrte: boolean = true,
        public override kinderOrte: SearchResultEntry[] = [],
        public override stichtag: moment.Moment | null = null,
        public override year: number | null = null,
        public kindergartenTypes: KindergartenType[] = [],
        public kindergartenLocations: KindergartenLocation[] = [],
    ) {
        super(alleKinderOrte, kinderOrte, stichtag, year);
    }

    public override isValid(): boolean {
        return super.isValid()
            && DvbDateUtil.isValidMoment(this.stichtag)
            && this.kindergartenLocations.length > 0
            && this.kindergartenTypes.length > 0;
    }

    public override toRestObject(): JaxParameterKindergartenkinder {
        return {
            kindergartenTypes: this.kindergartenTypes,
            kindergartenLocations: this.kindergartenLocations,
            stichtag: DvbRestUtil.momentToLocalDate(this.stichtag) ?? undefined,
            alleKitas: this.alleKinderOrte,
            kitaIds: this.kinderOrte.map(DvbUtil.mapToIdChecked),
            jahr: this.year ?? undefined,
        };
    }
}
