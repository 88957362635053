/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class WeeklyFirmenKontingentControllingFigures {
    public static $inject: readonly string[] = [];

    public constructor(
        public firmenKontingentId: string,
        public plaetze: number,
        public belegungKontingent: number,
        public auslastungKontingentPct: number,
        public belegungKita: number,
    ) {
    }

    public static apiResponseTransformer(data: any): WeeklyFirmenKontingentControllingFigures {
        return new WeeklyFirmenKontingentControllingFigures(
            data.firmenKontingentId,
            parseFloat(data.plaetze),
            parseFloat(data.belegungKontingent),
            parseFloat(data.auslastungKontingentPct),
            parseFloat(data.belegungKita),
        );
    }
}
