/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TypeUtil} from '@dv/shared/code';
import type angular from 'angular';
import type {AccordionContext} from './AccordionContext';

const directive: angular.IDirective = {
    restrict: 'A',
    scope: {
        allowMultiple: '<',
    },
    controllerAs: 'vm',
    bindToController: true,
};

export class DvbAccordions {

    public allowMultiple!: boolean;

    private accordionContexts: AccordionContext[] = [];

    private static updateSelect(context: AccordionContext): void {
        if (TypeUtil.isFunction(context.onSelect)) {
            context.onSelect({selected: !context.isCollapsed, context});
        }
    }

    public register(accordionContext: AccordionContext): void {
        this.accordionContexts.push(accordionContext);
    }

    public unregister(accordionContext: AccordionContext): void {
        this.accordionContexts.splice(this.accordionContexts.indexOf(accordionContext), 1);
    }

    public select(accordionContext: AccordionContext): void {
        accordionContext.isCollapsed = !accordionContext.isCollapsed;
        DvbAccordions.updateSelect(accordionContext);

        if (this.allowMultiple) {
            return;
        }

        this.accordionContexts.filter(context => context !== accordionContext).forEach(context => {
            context.isCollapsed = true;
            DvbAccordions.updateSelect(context);
        });
    }
}

directive.controller = DvbAccordions;

export const dvbAccordionsDirective = directive;
