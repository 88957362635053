/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Persisted} from '@dv/shared/code';
import {Adresse} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {Benutzer} from '../../../../benutzer/model/Benutzer';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import {ANGESTELLTE_PROFIL_STATE} from '../../anstellung-states';
import {Angestellte} from '../../models/Angestellte';
import type {AngestellteService} from '../../service/angestellteService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<?',
    },
    template: require('./dvb-angestellte-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbAngestellteErstellen implements angular.IController {
    public static $inject: readonly string[] = ['errorService', 'angestellteService', 'dvbStateService', '$state'];

    public benutzer: Persisted<Benutzer> | null = null;

    public showAddress: boolean = false;
    public isLoading: boolean = false;
    public angestellte: Angestellte = new Angestellte();

    public constructor(
        private errorService: ErrorService,
        private angestellteService: AngestellteService,
        private dvbStateService: DvbStateService,
        private $state: StateService,
    ) {
    }

    public $onChanges(): void {
        if (this.benutzer) {
            this.angestellte = new Angestellte(null, this.benutzer.vorName, this.benutzer.familienName);
        }
    }

    public onSubmit(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!form.$valid) {
            return;
        }

        this.isLoading = true;
        if (this.benutzer) {
            this.angestellte.benutzerId = this.benutzer.id;
        }

        this.angestellteService.create(this.angestellte)
            .then(response => {
                const id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);

                return this.$state.go(ANGESTELLTE_PROFIL_STATE.name, {id});
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goBack(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    public toggleAddress(): void {
        if (this.showAddress) {
            this.showAddress = false;
            this.angestellte.adresse = null;
        } else {
            this.angestellte.adresse = new Adresse();
            this.showAddress = true;
        }
    }
}

componentConfig.controller = DvbAngestellteErstellen;
angular.module('kitAdmin').component('dvbAngestellteErstellen', componentConfig);
