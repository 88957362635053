<div class="dvb-contextual-adresse">
    <div class="row">
        <div class="col-md-6">
            <div class="radio">
                <label>
                    <input name="context"
                           type="radio"
                           required
                           ng-model="vm.contextualAdresse.context"
                           ng-value="vm.adresseContext.MANDANT"
                           ng-change="vm.onContextChange()">
                    <span data-translate="COMMON.ADRESSE_MANDANT"></span>
                </label>
            </div>
            <div class="radio">
                <label>
                    <input name="context"
                           type="radio"
                           required
                           ng-model="vm.contextualAdresse.context"
                           ng-value="vm.adresseContext.KINDERORT"
                           ng-change="vm.onContextChange()">
                    <span data-translate="COMMON.ADRESSE_KINDERORT"></span>
                </label>
            </div>
            <div class="radio" ng-if="vm.allowNone">
                <label>
                    <input name="context"
                           type="radio"
                           required
                           ng-model="vm.contextualAdresse.context"
                           ng-value="vm.adresseContext.NONE"
                           ng-change="vm.onContextChange()">
                    <span data-translate="COMMON.ADRESSE_NONE"></span>
                </label>
            </div>
            <div class="radio">
                <label>
                    <input name="context"
                           type="radio"
                           required
                           ng-model="vm.contextualAdresse.context"
                           ng-value="vm.adresseContext.CUSTOM"
                           ng-change="vm.onContextChange()">
                    <span data-translate="COMMON.ADRESSE_CUSTOM"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="row" ng-if="vm.adresseContext.CUSTOM === vm.contextualAdresse.context">
        <div class="col-md-12">
            <input type="text"
                   ng-required="{{vm.orgRequired && vm.adresseContext.CUSTOM === vm.contextualAdresse.context}}"
                   name="adresseHeader"
                   class="form-control"
                   maxlength="100"
                   ng-attr-placeholder="{{'COMMON.ORGANISATION' | translate}}"
                   uib-tooltip="{{'COMMON.ORGANISATION' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.contextualAdresse.adresseHeader">
        </div>

        <div class="col-md-12">
            <input type="text"
                   name="adresseZusatz"
                   class="form-control"
                   maxlength="100"
                   ng-attr-placeholder="{{'COMMON.ADRESSZUSATZ' | translate}}"
                   uib-tooltip="{{'COMMON.ADRESSZUSATZ' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.contextualAdresse.adresseZusatz">
        </div>

        <div class="col-md-12">
            <dvb-adresse-form ng-model="vm.contextualAdresse.adresse"></dvb-adresse-form>
        </div>
    </div>
</div>
