/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsLaufKontaktpersonEntry} from '@dv/kitadmin/models';
import type {FunctionType, RechnungsRevisionError} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        empfaenger: '<',
        isSelected: '<',
        verrechenbar: '<',
        isSingleEntry: '<',
        onClick: '&',
    },
    template: require('./dvb-kita-rechnungslauf-finish-kontaktperson-entry.html'),
    controllerAs: 'vm',
};

export class DvbKitaRechnungslaufFinishKontaktpersonEntry implements angular.IController {

    public static $inject: readonly string[] = [];

    public empfaenger!: RechnungsLaufKontaktpersonEntry;
    public isSelected: boolean = false;
    public verrechenbar: boolean = false;
    public isSingleEntry: boolean = false;
    public onClick?: FunctionType;

    public errors: RechnungsRevisionError[] = [];

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.empfaenger) {
            this.errors = changes.empfaenger.currentValue.error ? [changes.empfaenger.currentValue.error] : [];
        }
    }

    public getAbweichungTranslation(): string {

        if (this.empfaenger.abweichungRevisionsTotal && this.empfaenger.abweichungVormonat) {
            return 'FAKTURA.ABWEICHUNG_VORMONAT_REVISIONS_TOTAL';
        }
        if (this.empfaenger.abweichungRevisionsTotal) {
            return 'FAKTURA.ABWEICHUNG_REVISIONS_TOTAL';
        }
        if (this.empfaenger.abweichungVormonat) {
            return 'FAKTURA.ABWEICHUNG_VORMONAT';
        }

        return '';
    }
}

componentConfig.controller = DvbKitaRechnungslaufFinishKontaktpersonEntry;
angular.module('kitAdmin').component('dvbKitaRechnungslaufFinishKontaktpersonEntry', componentConfig);
