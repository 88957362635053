/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    Abholberechtigt,
    Erziehungsberechtigter,
    Kind,
    Kontaktperson,
    LimitedAdresse,
    Umzug,
} from '@dv/kitadmin/models';
import {RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Adresse, LoadingContext, Persisted, SearchResultEntry} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {catchError, EMPTY, from, of, take, tap, throwError} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {AddressService} from '../../../common/service/rest/addressService';
import type {KindKontaktService} from '../../../common/service/rest/kind/kindKontaktService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {KindRechnungsAufteilungDialogModel} from '../kind-rechnungsaufteilung/kind-rechnungs-aufteilung.component';
import {KindRechnungsAufteilungComponent} from '../kind-rechnungsaufteilung/kind-rechnungs-aufteilung.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        kontakte: '<',
        adressen: '<',
    },
    template: require('./dvb-kind-kontakt.html'),
    controllerAs: 'vm',
};

export class DvbKindKontakt implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'kindKontaktService',
        'kindService',
        'addressService',
        'errorService',
        'dialogService',
        '$translate',
    ];

    public kind!: Persisted<Kind>;
    public kontakte: RelationshipWithKontaktperson[] = [];
    public adressen: LimitedAdresse[] = [];

    public hauptkontakt: RelationshipWithKontaktperson | null = null;
    public kontaktpersonen: Kontaktperson[] = [];

    public showUmzug = false;

    public constructor(
        private $state: StateService,
        private kindKontaktService: KindKontaktService,
        private kindService: KindService,
        private addressService: AddressService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    private static kontaktOrder(kontakt: RelationshipWithKontaktperson): number {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        // noinspection IfStatementWithTooManyBranchesJS
        if (kontakt.relationship.hauptkontakt) {
            return 1;
        } else if (kontakt.relationship.erziehungsberechtigter) {
            return 2;
        } else if (kontakt.relationship.rechnungsempfaenger) {
            return 3;
        } else if (kontakt.relationship.abholberechtigt) {
            return 4;
        } else if (kontakt.relationship.sonstigerKontakt) {
            return 5;
        }

        return 6;
        /* eslint-enable @typescript-eslint/no-magic-numbers */
    }

    public $onChanges(): void {
        this.kind.adressen = this.adressen;
        this.kind.kontakte = this.kontakte;
        this.hauptkontakt = this.kind.findAnyHauptkontaktRelationship();
        this.updateKontaktpersonen();
    }

    public isNotHauptkontakt(kontakt: RelationshipWithKontaktperson): boolean {
        return !kontakt.relationship.isHauptkontakt();
    }

    public cancelUmzug(): void {
        this.showUmzug = false;
    }

    public changeRechnungsempfaenger(): void {

        const dialogModel: KindRechnungsAufteilungDialogModel = {
            kind: this.kind,
            confirm: () => of(EMPTY).pipe(tap(() => this.$state.reload())),
        };

        this.dialogService.openDialog(KindRechnungsAufteilungComponent, dialogModel);
    }

    public addSonstigerKontakt(kontaktpersonItem: SearchResultEntry): void {
        this.errorService.clearAll();

        const relationshipWithKontaktperson = new RelationshipWithKontaktperson();
        relationshipWithKontaktperson.kontaktpersonId = kontaktpersonItem.id;
        relationshipWithKontaktperson.relationship.createSonstigerKontakt();

        this.kindKontaktService.createSonstigerKontakt(this.kind.id, kontaktpersonItem.id).then(() => {
            const params = {includes: '(kontaktperson.fields(adressen), relationship)'};
            this.kindService.getRelationshipWithKontaktperson(this.kind.id, kontaktpersonItem.id, params)
                .then(response => {
                    this.kind.addKontakt(response);
                    this.updateKontaktpersonen();
                });
        });
    }

    public replaceHauptkontakt(kontakt: RelationshipWithKontaktperson): void {
        this.errorService.clearAll();

        if (!kontakt.kontaktperson?.hasPhoneNumber()) {
            this.errorService.addValidationError('ERRORS.ERR_HAUPTKONTAKT_REQUIRES_TELEPHONE');

            return;
        }

        if (!kontakt.hasAdresse()) {
            this.errorService.addValidationError('ERRORS.ERR_HAUPTKONTAKT_REQUIRES_ADDRESS');

            return;
        }

        const previousHauptkontakt = this.kind.findAnyHauptkontaktRelationship()!;
        this.kindKontaktService.replaceHauptkontakt(this.kind.id, kontakt.kontaktpersonId!).then(response => {
            previousHauptkontakt.relationship.deleteHauptkontakt();

            kontakt.relationship.createHauptkontakt();
            kontakt.relationship.hauptkontakt!.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);
            this.hauptkontakt = kontakt;
            this.updateKontaktpersonen();
        });
    }

    public createErziehungsberechtigter(
        erziehungsberechtigter: Erziehungsberechtigter,
        relationshipWithKontaktperson: RelationshipWithKontaktperson,
    ): void {
        this.errorService.clearAll();

        this.kindKontaktService.createErziehungsberechtigter(
            this.kind.id,
            relationshipWithKontaktperson.kontaktpersonId!)
            .then(response => {
                erziehungsberechtigter.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);
            })
            .catch(() => {
                relationshipWithKontaktperson.relationship.deleteErziehungsberechtigter();
            });
    }

    public removeErziehungsberechtigter(
        erziehungsberechtigter: Persisted<Erziehungsberechtigter>,
        relationshipWithKontaktperson: RelationshipWithKontaktperson,
    ): void {
        this.errorService.clearAll();

        this.kindKontaktService.removeErziehungsberechtigter(erziehungsberechtigter.id).then(() => {
            relationshipWithKontaktperson.relationship.deleteErziehungsberechtigter();
        });
    }

    public createAbholberechtigt(
        abholberechtigt: Abholberechtigt,
        relationshipWithKontaktperson: RelationshipWithKontaktperson,
    ): void {
        this.errorService.clearAll();

        this.kindKontaktService.createAbholberechtigt(this.kind.id,
            relationshipWithKontaktperson.kontaktpersonId!)
            .then(response => {
                abholberechtigt.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);
            })
            .catch(() => {
                relationshipWithKontaktperson.relationship.deleteAbholberechtigt();
            });
    }

    public removeAbholberechtigt(
        abholberechtigt: Persisted<Abholberechtigt>,
        relationshipWithKontaktperson: RelationshipWithKontaktperson,
    ): void {
        this.errorService.clearAll();

        this.kindKontaktService.removeAbholberechtigt(abholberechtigt.id).then(() => {
            relationshipWithKontaktperson.relationship.deleteAbholberechtigt();
        });
    }

    public removeKontaktperson(kontakt: RelationshipWithKontaktperson): void {
        const entityText = this.$translate.instant('COMMON.KONTAKTPERSON.TITLE', {
            name: kontakt.kontaktperson?.getDisplayName(),
        });

        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(this.kindKontaktService.removeRelationships(this.kind.id, kontakt.kontaktpersonId!))
                .pipe(
                    take(1),
                    tap(() => {
                        this.kind.removeKontakt(kontakt);
                        this.updateKontaktpersonen();
                    }),
                    catchError(error => {
                        this.errorService.handleValidationError(false, 'ERRORS.ERR_KONTAKT_NOT_REMOVED');

                        return throwError(() => error);
                    }),
                ),
        });
    }

    public adresseKorrigieren(limitedAdresse: LimitedAdresse, newAdresse: Adresse): void {
        this.errorService.clearAll();

        this.addressService.korrigiereAdresse(newAdresse).then(() => {
            limitedAdresse.adresse = newAdresse;
            this.$state.reload();
        });
    }

    public deleteLimitedAdresse(limitedAdresse: Persisted<LimitedAdresse>): angular.IPromise<unknown> {
        this.errorService.clearAll();

        return this.kindService.deleteAdresse(this.kind.id, limitedAdresse.id).then(() => {
            return this.$state.reload();
        });
    }

    public saveUmzug(umzug: Umzug, context: LoadingContext): angular.IPromise<unknown> {
        this.errorService.clearAll();
        context.startLoading();

        return this.addressService.zuegeln(umzug)
            .then(() => {
                this.showUmzug = false;

                return this.$state.reload();
            })
            .finally(() => {
                context.finishLoading();
            });
    }

    private updateKontaktpersonen(): void {
        this.kontaktpersonen = this.kontakte.map(kontakt => {
            return kontakt.kontaktperson!;
        });

        this.kind.kontakte.sort((a, b) => {
            let diff = DvbKindKontakt.kontaktOrder(a) - DvbKindKontakt.kontaktOrder(b);

            if (diff === 0) {
                diff = a.kontaktperson!.getDisplayName().localeCompare(b.kontaktperson!.getDisplayName());
            }

            return diff;
        });
    }
}

componentConfig.controller = DvbKindKontakt;
angular.module('kitAdmin').component('dvbKindKontakt', componentConfig);

