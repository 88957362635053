/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {VOREINSTELLUNGEN_STATES} from '../../voreinstellungen-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-voreinstellungen-main.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenMain implements angular.IController {
    public static $inject: readonly string[] = [];

    public voreinstellungenStates: typeof VOREINSTELLUNGEN_STATES = VOREINSTELLUNGEN_STATES;
}

componentConfig.controller = DvbVoreinstellungenMain;
angular.module('kitAdmin').component('dvbVoreinstellungenMain', componentConfig);
