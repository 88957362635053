/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {DayOfWeek} from '@dv/shared/code';
import type {BelegungZeitenByDayOfWeek} from '@dv/kitadmin/models';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        weekDays: '<',
        belegungZeiten: '<',
    },
    template: require('./dvb-belegung-zeiten.html'),
    controllerAs: 'vm',
};

export class DvbBelegungZeiten implements angular.IController {
    public static $inject: readonly string[] = [];

    public weekDays!: DayOfWeek[];
    public belegungZeiten!: BelegungZeitenByDayOfWeek;

    public hasBelegungZeiten: boolean = false;
    public isCollapsed: boolean = true;

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (!onChangesObj.belegungZeiten) {
            return;
        }

        this.hasBelegungZeiten = Object.values(this.belegungZeiten).length !== 0;
    }

    public toggle(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}

componentConfig.controller = DvbBelegungZeiten;
angular.module('kitAdmin').component('dvbBelegungZeiten', componentConfig);
