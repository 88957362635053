/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import type {IPromise} from 'angular';
import type moment from 'moment';

export class CommunicationStateService {
    public static $inject: readonly string[] = ['$state'];

    public constructor(
        private $state: StateService,
    ) {
    }

    public updateStateParams(
        kinderOrtSearchResult?: SearchResultEntry | null,
        gueltigAb?: moment.Moment | null,
        dokumentLayout?: DokumentLayout | null,
    ): IPromise<any> {

        return this.$state.go('.', {
            kinderOrtSearchResult,
            gueltigAb,
            dokumentLayout,
        });
    }
}
