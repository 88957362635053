/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxCreateBringAbholZeitenAccess} from '@dv/shared/backend/model/jax-create-bring-abhol-zeiten-access';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {BringAbholZeitenParams} from './BringAbholZeitenParams';

export class CreateBringAbholZeitenAccess extends BringAbholZeitenParams {

    public constructor(
        periodFrom: moment.Moment,
        periodTo: moment.Moment,
        kinderOrtId: string,
        kinderOrtExclusive: boolean,
        public deadline: moment.Moment | null = null,
        public updateData: boolean = true,
    ) {
        super(periodFrom, periodTo, kinderOrtId, kinderOrtExclusive);
    }

    public override toRestObject(): JaxCreateBringAbholZeitenAccess {
        return {
            ...super.toRestObject(),
            deadline: DvbRestUtil.momentToLocalDateTime(this.deadline) ?? undefined,
            updateData: this.updateData,
        };
    }
}
