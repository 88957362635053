/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import type {OnChangeListener} from './FilterModelChangeHandler';
import {FilterModelChangeHandler} from './FilterModelChangeHandler';
import type {MicroFilterAttribute} from './MicroFilterAttribute';

/**
 * Contains filter specific logic for a filter service.
 *
 * TUserModel defines the type of the Models attribute (i.e. AngestellteFilterModel.fraktionIds)
 */
export abstract class FilterModelController<T, TUserModel> {

    /**
     * TRUE bedeutet, dass der Fitler etwas tut. Dadurch weiss der filter-toggler, dass er den "Zurücksetzn" Button
     * anzeigen soll.
     */
    public isActive: boolean = false;

    protected constructor(
        public model: MicroFilterAttribute<T>,
        protected changeHandler: FilterModelChangeHandler = new FilterModelChangeHandler([]),
    ) {
        this.isActive = this.model.isFilterSet();
    }

    public addChangeListener(listener: OnChangeListener): void {
        this.changeHandler.listeners.push(listener);
    }

    public removeChangeListener(listener: OnChangeListener): void {
        DvbUtil.removeFromArray(listener, this.changeHandler.listeners);
    }

    public notifyChangeListeners(): void {
        this.filterModelChanged();
        this.changeHandler.notify();
    }

    public filterModelChanged(): void {
        this.isActive = this.model.isFilterSet();
        this.changeHandler.scheduleChangeNotification();
    }

    /**
     * @return true if the model (KindFilterModel or similar) may be visible based on this controller
     */
    public isVisible(userModel?: TUserModel): boolean | angular.IPromise<boolean> {
        if (!this.isActive) {
            return true;
        }

        return this.filterAcceptsUserModel(userModel);
    }

    /**
     * Restore the internal model to the original state
     */
    public abstract reset(): void;

    /**
     * @param userModel the model to check
     * @return true if this controller allows the model to be shown
     */
    protected abstract filterAcceptsUserModel(userModel?: TUserModel): boolean | angular.IPromise<boolean>;
}
