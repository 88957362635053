/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type moment from 'moment';
import {Benutzer} from '../../../benutzer/model/Benutzer';

export class BulkEmailInfo {

    public constructor(
        public id: EntityId = '',
        public emailSubject: string = '',
        public plainText: string = '',
        public html: string | null = null,
        public replyToName: string = '',
        public replyToAddress: string | null = null,
        public versandtAm: moment.Moment | null = null,
        public userErstellt: Benutzer | null = null,
    ) {

    }

    public static apiResponseTransformer(data: any): BulkEmailInfo {
        const result = new BulkEmailInfo();
        result.id = data.id;
        result.emailSubject = data.emailSubject;
        result.plainText = data.plainText;
        result.html = data.html;
        result.replyToName = data.replyToName;
        result.replyToAddress = data.replyToAddress;
        result.versandtAm = data.versandtAm;
        result.userErstellt = Benutzer.apiResponseTransformer(data.userErstellt);

        return result;
    }
}
