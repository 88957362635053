/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {FixPosition, FixPositionTyp} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent, LeistungsArt} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import type {FixPositionenService} from '../../../../common/service/rest/kind/fixPositionenService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        typ: '@',
        kindId: '<',
    },
    template: require('./dvb-fixposition-erfassen.html'),
    controllerAs: 'vm',
};

export class DvbFixpositionErfassen implements angular.IController {

    public static $inject: readonly string[] = [
        'fixPositionenService',
        'errorService',
        'dvbStateService',
        '$state',
    ];

    public typ!: FixPositionTyp;
    public kindId!: string;

    public fixPosition: FixPosition = new FixPosition();
    public headerKey: string = '';
    public submitKey: string = '';
    public negateBetrag: boolean = false;
    public readonly leistungsart: typeof LeistungsArt = LeistungsArt;
    public isLoading: boolean = false;
    public kitaSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
            public readonly fixPositionenService: FixPositionenService,
            public readonly errorService: ErrorService,
            public readonly dvbStateService: DvbStateService,
            public readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        this.updateTotalBetrag();
        // TODO die Kita soll vorselektiert werden, wenn das Kind nur in einer Kita eine Belegung hat
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.typ) {
            switch (changes.typ.currentValue) {
                case FixPositionTyp.GEBUEHR:
                    this.fixPosition.typ = FixPositionTyp.GEBUEHR;
                    this.headerKey = 'KIND.GEBUEHR_ERFASSEN';
                    this.submitKey = 'KIND.GEBUEHR_ERFASSEN';
                    this.negateBetrag = false;
                    break;
                case FixPositionTyp.GUTSCHRIFT:
                    this.fixPosition.typ = FixPositionTyp.GUTSCHRIFT;
                    this.headerKey = 'KIND.GUTSCHRIFT_ERFASSEN';
                    this.submitKey = 'KIND.GUTSCHRIFT_ERFASSEN';
                    this.negateBetrag = true;
                    break;
                default:
                    throw new Error(`Unknown FixPosition typ: ${JSON.stringify(changes.typ.currentValue)}`);
            }
        }

        if (changes.kindId) {
            this.fixPosition.kindId = changes.kindId.currentValue;
        }
    }

    public submit(form: angular.IFormController): void {
        this.errorService.clearAll();

        if (!this.isValid(form)) {
            return;
        }

        this.isLoading = true;

        if (!this.fixPosition.anzahl) {
            this.fixPosition.anzahl = 1;
        }

        const fixPosition = angular.copy(this.fixPosition);

        if (this.negateBetrag) {
            // Wir machen eine Kopie, damit das GUI nicht plötzlich negative Werte anzeigt
            fixPosition.betragProEinheit! *= -1;
        }

        this.fixPositionenService.createFixPosition(fixPosition)
                .then(() => {
                    const leistungsDatum = checkPresent(fixPosition.leistungsDatum);
                    const params = {
                        year: leistungsDatum.year(),
                        month: leistungsDatum.month() + 1,
                    };
                    this.$state.go('base.kind.leistungen', params);
                })
                .finally(() => {
                    this.isLoading = false;
                });
    }

    public setKitaId(): void {
        this.fixPosition.kitaId = this.kitaSearchResultEntry ? this.kitaSearchResultEntry.id : null;
    }

    public goBack(): void {
        this.errorService.clearAll();
        this.dvbStateService.goToPreviousState();
    }

    public updateTotalBetrag(): void {
        this.fixPosition.totalBetrag = (this.fixPosition.betragProEinheit ?? 0) * (this.fixPosition.anzahl ?? 1);
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    private isValid(form: angular.IFormController): boolean {
        const isValidBetrag = form.betrag?.$error &&
                !form.betrag.$error.required &&
                !form.betrag.$error.currencyValue &&
                !form.betrag.$error.dvbMinExclusive;

        this.errorService.handleValidationError(isValidBetrag, 'ERRORS.ERR_CHF');

        const isValidAnz = this.isValidAnzahl(form);

        if (isValidBetrag && isValidAnz && !form.$valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');
        }

        return isValidBetrag && isValidAnz && form.$valid;
    }

    private isValidAnzahl(form: angular.IFormController): boolean {
        const isValid = this.fixPosition.anzahl === 1 || (
                form.anzahl?.$error &&
                !form.anzahl.$error.min &&
                !form.anzahl.$error.pattern
        );
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_ANZAHL');

        return isValid;
    }
}

componentConfig.controller = DvbFixpositionErfassen;
angular.module('kitAdmin').component('dvbFixpositionErfassen', componentConfig);
