<div class="dvb-kinder-ort-lohn row">

    <div class="col-md-7">
        <div class="row">
            <div class="col-md-12">
                <h2 class="inline-block" data-translate="KINDERORT.NAV_LOHN"></h2>
                <dvlib-date-switcher mode="month"
                                     class="normal-abstand-left"
                                     [(date)]="vm.month"
                                     (debounced-date-change)="vm.reloadItems()">
                </dvlib-date-switcher>
            </div>
        </div>

        <dvb-pages items="vm.pageContainer.items"
                   total-items="vm.pageContainer.count"
                   is-loading="vm.isLoading"
                   items-per-page="vm.itemsPerPage"
                   current-page="vm.currentPage"
                   entity-name="'COMMON.BETREUUNGS_PERSON.MF'"
                   on-update-page="vm.updatePage(page)"
                   on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
            <page-items dvb-accordions>
                <dvb-accordion ng-repeat="fraktion in vm.pageContainer.items"
                               is-first="$first"
                               ng-disabled="true"
                               show-content-disabled="false">

                    <panel-header class="full-width-block">
                        <div class="layout-row items-center row">
                            <div class="col-md-8">
                                <a ui-sref="base.fraktion.betreuungs_person.monatsblatt({id: fraktion.id, periode: vm.month})"
                                   ng-bind="fraktion.getDisplayName()">
                                </a>
                            </div>
                            <div class="col-md-4 text-right">
                            <span ng-if="vm.stateByFraktion[fraktion.id]"
                                  class="badge"
                                  ng-class="{'warning': vm.stateByFraktion[fraktion.id].status !== 'VERSANDT'}"
                                  data-translate="{{'FRAKTION.MONATSBLATT.API.STATUS.' + vm.stateByFraktion[fraktion.id].status}}"
                                  uib-tooltip="{{'FRAKTION.MONATSBLATT.API.VERAENDERT_INFO_MF' | translate: {status: vm.stateByFraktion[fraktion.id].status}:'messageformat'}}"
                                  tooltip-placement="top-left">
                            </span>
                            </div>
                        </div>
                    </panel-header>
                </dvb-accordion>
            </page-items>
        </dvb-pages>

    </div>
    <div class="col-md-4 col-md-offset-1 hidden-print">
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <dvb-loading-button as-link="true"
                            is-loading="vm.isSending"
                            class="floating-text"
                            on-click="vm.sendMonth()"
                            tooltip-placement="top-left"
                            type="button">
            <span data-translate="FRAKTION.MONATSBLATT.API.SEND"></span>
        </dvb-loading-button>
    </div>

</div>
