/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnungsempfaenger} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnungsempfaenger: '<',
    },
    template: require('./dvb-rechnungsbetrag-display.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsbetragDisplay implements angular.IController {
    public static $inject: readonly string[] = [];

    public rechnungsempfaenger!: Rechnungsempfaenger;

    public translationKey: string = '';

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.rechnungsempfaenger) {
            this.translationKey = this.rechnungsempfaenger.isFixbetrag() ?
                'KIND.KINDKONTAKT_RECHNUNGBETRAG_FIXBETRAG' :
                'KIND.KINDKONTAKT_RECHNUNGBETRAG_PROZENT';
        }
    }

}

componentConfig.controller = DvbRechnungsbetragDisplay;
angular.module('kitAdmin').component('dvbRechnungsbetragDisplay', componentConfig);
