/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {LeistungsrechnungWarningElement} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        warnings: '<?',
        implicitlyKnown: '@?',
        enableDeletion: '<?',
        onDelete: '&?',
    },
    template: require('./dvb-leistungsrechnung-warnings-badge.html'),
    controllerAs: 'vm',
};

export class DvbLeistungsrechnungWarningsBadge {
    public static $inject: readonly string[] = ['$translate'];

    public warnings: LeistungsrechnungWarningElement[] = [];
    public implicitlyKnown: 'KIND' | 'KINDERORT' = 'KINDERORT';
    public enableDeletion: boolean = false;
    public onDelete?: FunctionType;

    public label: string | null = null;

    public constructor(private $translate: angular.translate.ITranslateService) {
    }

    public $onChanges(): void {
        this.initBadgeLabel();
    }

    private initBadgeLabel(): void {
        const warningCount = this.warnings.length;
        if (warningCount === 0) {
            this.label = null;

            return;
        }

        if (warningCount === 1) {
            const warningElem = this.warnings[0];
            const translationId = `COMMON.LEISTUNGSRECHNUNG_WARNINGS.${warningElem.leistungsrechnungWarning}.SHORT`;
            this.label = this.$translate.instant(translationId);

            return;
        }

        this.label = this.$translate.instant('FAKTURA.ACHTUNG');
    }
}

componentConfig.controller = DvbLeistungsrechnungWarningsBadge;
angular.module('kitAdmin').component('dvbLeistungsrechnungWarningsBadge', componentConfig);
