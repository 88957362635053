/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktion} from '@dv/kitadmin/models';
import {ExtraPlatzCategory, ZeitraumUtil} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {TempExtraPlatz} from '../../../temp-extra-platz/TempExtraPlatz';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        plaetze: '<',
        fraktionen: '<',
        isLoading: '<?',
        removePlatz: '&',
        extraPlatzUpdated: '&',
    },
    template: require('./dvb-temp-extra-plaetze.html'),
    controllerAs: 'vm',
};

export class DvbTempExtraPlaetze implements angular.IController {
    public static $inject: readonly string[] = [];

    public plaetze!: TempExtraPlatz[];
    public fraktionen!: KinderOrtFraktion[];
    public isLoading: boolean = false;
    public removePlatz!: (param: { platz: TempExtraPlatz }) => void;

    public readonly extraPlatzCategories: typeof ExtraPlatzCategory = ExtraPlatzCategory;

    public getFraktion(fraktionId: string): KinderOrtFraktion | undefined {
        return this.fraktionen.find(f => f.id === fraktionId);
    }

    public getEnabledDays(platz: TempExtraPlatz): DayOfWeek[] {
        return ZeitraumUtil.getWeekDaysFromWochenplan(checkPresent(this.getFraktion(platz.fraktionId)).wochenplan);
    }
}

componentConfig.controller = DvbTempExtraPlaetze;
angular.module('kitAdmin').component('dvbTempExtraPlaetze', componentConfig);
