<div class="dvb-betreuungs-schluessel-overview">
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-key header-icon"
               uib-tooltip="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>
            <div class="col-md-12 header-box">
                <h1 ng-bind="vm.betreuungsSchluessel.name"></h1>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7">
            <div ng-repeat="regel in vm.betreuungsSchluessel.betreuungsSchluesselRegeln"
                 class="row normal-abstand-top">
                <div class="col-md-12">
                    <h3 ng-bind="regel.dayOfWeek ? regel.getDayOfWeekString(regel.dayOfWeek) : ('PERSONAL.BETREUUNGS_SCHLUESSEL.DEFAULT_TAG' | translate)">
                    </h3>
                    <h3 ng-if="regel.vonBis.von"
                        data-translate="COMMON.DATERANGE"
                        data-translate-value-von="{{regel.vonBis.von | amDateFormat: 'HH:mm'}}"
                        data-translate-value-bis="{{regel.vonBis.bis | amDateFormat: 'HH:mm'}}"></h3>
                    <div ng-repeat="regelBedarf in regel.betreuungsSchluesselRegelBedarf"
                         class="row">
                        <div class="col-md-12">
                            <p data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.BEDARF_MF"
                               data-translate-value-type="{{regelBedarf.fromType}}"
                               data-translate-value-plaetze="{{regelBedarf.fromPlaetze}}"
                               data-translate-value-kinder="{{regelBedarf.fromKinder}}"
                               data-translate-interpolation="messageformat">
                            </p>

                            <div ng-repeat="ausbildungBedarf in regelBedarf.ausbildungsPersonalBedarf"
                                 class="normal-abstand-left">
                                <p>
                                    <span ng-bind="ausbildungBedarf.ausbildungsName"></span>:
                                    <span ng-bind="ausbildungBedarf.bedarfCount"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ul class="list-inline big-abstand-top"
                dvb-auth
                require-permission="personal:administrate:general"
                require-condition="vm.betreuungsSchluessel.editable">
                <li>
                    <a ui-sref="{{vm.editState.name + '({id: ' + vm.betreuungsSchluessel.id + ' })'}}"
                       class="align-middle"
                       data-translate="COMMON.BEARBEITEN">
                    </a>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.delete()"
                            data-translate="COMMON.LOESCHEN">
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
