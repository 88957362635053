<div class="dvb-search-on-click-entity">
    <a href=""
       ng-if="!vm.isShown"
       ng-click="vm.show()">
        <span ng-if="!vm.hideAdd">+</span>
        <span data-translate="{{vm.addLabelKey}}"></span>
    </a>

    <dvb-search-entity ng-if="vm.isShown"
                       focus="true"
                       entity-to-search="{{vm.entityToSearch}}"
                       on-select="vm.addItem(item)"
                       on-select-clear="vm.onSelectClear"
                       placeholder-key="{{vm.placeholderKey}}"
                       filter-source="vm.filterSource({$source: $source})"
                       expand-entity="vm.expandEntity"
                       disabled-entries="vm.disabledEntries"
                       mandant-filter="vm.mandantFilter">
    </dvb-search-entity>
</div>
