/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PlatzTyp} from '@dv/kitadmin/models';
import {FilterOption} from '../../../filter/shared/FilterOption';

export class PlaetzeFilterOption extends FilterOption {

    /**
     * @param id of the PlatzType
     * @param name the name of the PlatzType
     * @param plaetze amount of plaetze
     */
    public constructor(
        id: PlatzTyp,
        name: string,
        public plaetze: number | null = null,
    ) {
        super(id, name, false, false);
    }
}
