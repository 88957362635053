/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    DisplayTask,
    LeistungsrechnungWarning,
    RechnungsLaufEntry,
    RechnungsLaufKontaktpersonEntry,
} from '@dv/kitadmin/models';
import {LeistungsrechnungWarningElement} from '@dv/kitadmin/models';
import type {FunctionType, RechnungsRevisionError} from '@dv/shared/code';
import {DvbUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        checkedEntry: '=',
        entry: '<',
        faelligAm: '<',
        isSelected: '<',
        index: '<',
        isFirst: '<',
        kinderOrtName: '<',
        isDisabled: '<',
        onSelectEntry: '&',
    },
    template: require('./dvb-kita-rechnungslauf-finish-entry.html'),
    controllerAs: 'vm',
};

export class DvbKitaRechnungslaufFinishEntry implements angular.IController {

    public static $inject: readonly string[] = [];

    public entry!: RechnungsLaufEntry;
    public checkedEntry!: boolean;
    public faelligAm!: moment.Moment;
    public isSelected!: boolean;
    public index!: number;
    public isFirst!: boolean;
    public isDisabled!: boolean;
    public kinderOrtName!: string;
    public onSelectEntry!: FunctionType;

    public selectedKontaktpersonIndex?: number = undefined;
    public aggregatedTasks: DisplayTask[] = [];
    public hasError: boolean = false;
    public aggregatedErrors: RechnungsRevisionError[] = [];
    public showVorauszahlungen: boolean = false;
    public taskBadgeLabel?: string;
    public empfaengerListLabel?: string;
    public warnings: LeistungsrechnungWarningElement[] = [];

    private static getEmpfaengerList(entry: RechnungsLaufEntry): string {
        return entry.empfaenger.sort((a, b) => a.kontaktpersonDisplayName!.localeCompare(b.kontaktpersonDisplayName!))
            .map(r => r.kontaktpersonDisplayName)
            .join(', ');
    }

    private static getTaskBadgeLabel(entry: RechnungsLaufEntry): string {
        if (entry.verrechenbar) {
            return entry.rechnungsdifferenz ? 'FAKTURA.RECHNUNGSDIFFERENZ' : 'COMMON.VERRECHENBAR';
        }

        return 'COMMON.NICHT_VERRECHENBAR';
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (!changes.entry) {
            return;
        }

        const entry: RechnungsLaufEntry = changes.entry.currentValue;
        const empfaenger = entry.empfaenger;
        this.aggregatedTasks = DvbUtil.uniqueArray(empfaenger.flatMap(e => e.tasks), DvbUtil.mapToId);
        this.aggregatedErrors = empfaenger.filter(e => e.error !== null)
            .map(e => e.error!);
        this.hasError = this.aggregatedErrors.length > 0;
        this.showVorauszahlungen = empfaenger.length === 1 && empfaenger[0].vorauszahlungen > 0;
        this.taskBadgeLabel = DvbKitaRechnungslaufFinishEntry.getTaskBadgeLabel(entry);
        this.empfaengerListLabel = DvbKitaRechnungslaufFinishEntry.getEmpfaengerList(entry);

        this.aggregateWarnings(empfaenger);
    }

    public selectEntry(index: number, entry: RechnungsLaufEntry): void {
        if (!entry.verrechenbar) {
            return;
        }
        this.selectedKontaktpersonIndex = undefined;

        if (TypeUtil.isFunction(this.onSelectEntry)) {
            this.onSelectEntry({index});
        }
    }

    public selectKontaktperson(index: number): void {
        this.selectedKontaktpersonIndex = index === this.selectedKontaktpersonIndex ?
            undefined :
            index;
    }

    public getAbweichungTranslation(): string {
        const hasAbweichungRevisionsTotal = this.entry.hasAbweichungRevisionsTotal();
        const hasAbweichungVormonat = this.entry.hasAbweichungVormonat();

        if (hasAbweichungRevisionsTotal && hasAbweichungVormonat) {
            return 'FAKTURA.ABWEICHUNG_VORMONAT_REVISIONS_TOTAL';
        }
        if (hasAbweichungRevisionsTotal) {
            return 'FAKTURA.ABWEICHUNG_REVISIONS_TOTAL';
        }
        if (hasAbweichungVormonat) {
            return 'FAKTURA.ABWEICHUNG_VORMONAT';
        }

        return '';
    }

    /**
     * Aggregates the warnings to ensure, that the warnings are displayed only once, even when they are present for
     * multiple empfaenger.
     */
    private aggregateWarnings(empfaenger: RechnungsLaufKontaktpersonEntry[]): void {
        this.warnings = [];
        // we aggregate based on display name --> warnings for identically named children will be merged
        const warningsByChild: { [name: string]: LeistungsrechnungWarning[] } = {};
        empfaenger.forEach(e => {
            if (!e.warnings) {
                return;
            }
            Object.entries(e.warnings).forEach(([displayName, warnings]) => {
                if (!warningsByChild[displayName]) {
                    warningsByChild[displayName] = [];
                }
                warnings.forEach(warning => warningsByChild[displayName].push(warning));
            });
        });
        this.warnings = Object.entries(warningsByChild)
            .flatMap(([name, warnings]) => DvbUtil.uniqueArray(warnings)
                .map(warning => new LeistungsrechnungWarningElement(warning, name, this.kinderOrtName)));
    }
}

componentConfig.controller = DvbKitaRechnungslaufFinishEntry;
angular.module('kitAdmin').component('dvbKitaRechnungslaufFinishEntry', componentConfig);
