<div class="dvb-kita-uebersicht-personal">
    <div class="row normal-abstand-top">
        <div class="col-md-7">
            <dvb-kita-chart-personal controlling-report="vm.controllingReport"
                                     first-of-week="vm.firstOfWeek"
                                     on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
            </dvb-kita-chart-personal>
        </div>

        <div class="col-md-5">
            <div class="row">
                <div class="col-sm-offset-1 col-sm-5">
                    <h2 class="stats-header"
                        data-translate="KINDERORT.SHORT_KALENDERWOCHE_WITH_VALUE"
                        data-translate-value-kalenderwoche="{{vm.firstOfWeek | amDateFormat:'W'}}">
                    </h2>

                    <p class="stats-number chart-plaetze"
                       data-translate="PERSONAL.CHART.BEDARF_FTE"
                       data-translate-value-fte="{{vm.personalBedarf | number:1}}"
                       uib-tooltip="{{'PERSONAL.CHART.FTE_EXPLANATION' | translate}}"
                       tooltip-placement="top-left">
                    </p>
                    <p class="stats-number chart-maximale-plaetze"
                       data-translate="PERSONAL.CHART.ASSIGNED_FTE"
                       data-translate-value-fte="{{vm.personalZugewiesen | number:1}}"
                       uib-tooltip="{{'PERSONAL.CHART.FTE_EXPLANATION' | translate}}"
                       tooltip-placement="top-left">
                    </p>
                    <p class="stats-number chart-belegte-plaetze"
                       data-translate="PERSONAL.CHART.EMPLOYED_COUNT"
                       data-translate-value-count="{{vm.personalAngestellt | number:1}}">
                    </p>

                    <div class="btn-group normal-abstand-top">
                        <button type="button"
                                ui-sref="base.report.category.personal({
                                kinderOrte: [vm.kinderOrt.toSearchResultEntry()],
                                alleKinderOrte: false,
                                stichtag: vm.firstOfWeek,
                                stichtagAdresse: vm.firstOfWeek,
                                gueltigAb: vm.firstOfWeek,
                                gueltigBis: vm.lastOfWeek
                                })"
                                class="btn btn-primary normal-abstand-bottom"
                                data-translate="COMMON.REPORT_GENERIEREN">
                        </button>
                    </div>
                </div>

                <div class="col-sm-offset-1 col-sm-5">
                    <h2 class="stats-header"
                        data-translate="KINDERORT.DURCHSCHNITT_WITH_VALUE"
                        data-translate-value-jahr="{{vm.firstOfWeek | amDateFormat:'GGGG'}}">
                    </h2>

                    <p class="stats-number chart-plaetze"
                       data-translate="PERSONAL.CHART.BEDARF_FTE"
                       data-translate-value-fte="{{vm.avgPersonalBedarf | number:1}}"
                       uib-tooltip="{{'PERSONAL.CHART.FTE_EXPLANATION' | translate}}"
                       tooltip-placement="top-left">
                    </p>
                    <p class="stats-number chart-maximale-plaetze"
                       data-translate="PERSONAL.CHART.ASSIGNED_FTE"
                       data-translate-value-fte="{{vm.avgPersonalZugewiesen | number:1}}"
                       uib-tooltip="{{'PERSONAL.CHART.FTE_EXPLANATION' | translate}}"
                       tooltip-placement="top-left">
                    </p>
                    <p class="stats-number chart-belegte-plaetze"
                       data-translate="PERSONAL.CHART.EMPLOYED_COUNT"
                       data-translate-value-count="{{vm.avgPersonalAngestellt | number:1}}">
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
