<div class="belegungsplan-filter big-abstand-bottom">
    <div class="filter-bar normal-abstand-bottom">
        <div class="row">
            <div class="filter-list col-md-9">
                <div class="filter">
                    <!-- Default Filters -->
                    <div data-translate="KINDERORT.PLAETZE"
                         class="filter-label"
                         ng-class="{'hidden-print': !vm.subventioniertePlaetzeSelected && !vm.privatePlaetzeSelected}"></div>
                    <ul class="filter-option-list">
                        <!-- Private Plätze -->
                        <li ng-class="{'hidden-print': !vm.privatePlaetzeSelected}">
                            <dvb-filter-option label="'KINDERORT.FILTER_PRIVATE_PLAETZE' | translate"
                                               is-selected="vm.privatePlaetzeSelected"
                                               on-toggle="vm.onPrivatePlaetzeChange(isSelected)">
                            </dvb-filter-option>
                        </li>

                        <!-- Subventionierte Plätze -->
                        <li ng-class="{'hidden-print': !vm.subventioniertePlaetzeSelected}"
                            ng-if="vm.hasSubventioniertePlaetze">
                            <dvb-filter-option label="'KINDERORT.FILTER_SUBVENT_PLAETZE' | translate"
                                               ng-class="{'negative-anzahl' : vm.subventioniertePlaetze < 0}"
                                               is-selected="vm.subventioniertePlaetzeSelected"
                                               on-toggle="vm.onSubventioniertePlaetzeChange(isSelected)">
                            <span class="anzahl-plaetze">
                                <dvlib-spinner [show]="vm.subventioniertePlaetze === null"></dvlib-spinner>
                                <span ng-if="vm.subventioniertePlaetze !== null"
                                      ng-class="{'positiv-anzahl' : vm.subventioniertePlaetze > 0}"
                                      ng-bind="vm.subventioniertePlaetze | number:2">
                                </span>
                            </span>
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div class="filter">
                    <!-- Eintrittsdatum -->
                    <div data-translate="KINDERORT.VERFUEGBAR_AB"
                         class="filter-label"
                         ng-class="{'hidden-print': !vm.verfuegbarePlaetze}"></div>
                    <ul class="filter-option-list">
                        <li ng-class="{'hidden-print': !vm.verfuegbarePlaetze}">
                            <button class="btn label label-default label-radio verfuegbar-ab"
                                    type="button"
                                    data-ng-change="vm.onVerfuegbarePlaetzeChange()"
                                    data-ng-model="vm.verfuegbarePlaetze"
                                    ng-bind="vm.firstOfWeek | amDateFormat: 'DD. MMM YYYY'"
                                    uib-btn-checkbox>
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="filter">
                    <!-- Alter -->
                    <div data-translate="KINDERORT.ALTER"
                         class="filter-label"
                         ng-class="{'hidden-print': (vm.ageFilters | filter:{selected:true}).length === 0}"></div>
                    <ul class="filter-option-list">
                        <li ng-repeat="filter in vm.ageFilters" ng-class="{'hidden-print' : !filter.selected}">
                            <button class="btn alter label label-default label-radio"
                                    type="button"
                                    data-ng-change="vm.onAgeFilterChange()"
                                    data-ng-model="filter.selected"
                                    uib-btn-checkbox
                                    ng-bind="filter.name">
                            </button>
                        </li>
                    </ul>
                </div>

                <!-- show if stick filters selected-->
                <div class="contents"
                     ng-if="(vm.gruppenFilters | filter: {sticky:true}).length > 0 ||
                     (vm.firmenKontingentFilters | filter:{sticky:true}).length > 0 ||
                     vm.provisorisch.sticky ||
                     vm.angebot.sticky ||
                     vm.definitiv.sticky ||
                     vm.keineZuweisung.sticky ||
                     (vm.kindergartenFilter | filter:{sticky:true}).length > 0 ||
                     vm.pensumFilter.sticky ||
                     vm.customFieldsFilter.hasSticky() ||
                     vm.geschwisterFilter.sticky ||
                     (vm.showPrioFilter && (vm.prioritaetsFilter | filter:{sticky:true}).length > 0) ||
                     vm.anmeldeFilter.sticky">

                    <div ng-class="{'hidden-print': (vm.gruppenFilters | filter:{selected:true}).length === 0}"
                         ng-if="(vm.gruppenFilters | filter:{sticky:true}).length > 0"
                         class="filter">
                        <!-- ausgewählte Gruppen -->
                        <div data-translate="KINDERORT.FILTER_GRUPPEN" class="filter-label"></div>
                        <ul class="filter-option-list">
                            <li ng-repeat="filter in vm.gruppenFilters | filter:{sticky:true} | orderBy: 'name'"
                                ng-class="{'hidden-print' : !filter.selected}">
                                <dvb-filter-option label="filter.name"
                                                   is-selected="filter.selected"
                                                   closeable="true"
                                                   on-close="vm.closeFilterOption(filter, vm.setSelectedGruppen)"
                                                   on-toggle="vm.toggleFilterOption(filter, isSelected, vm.setSelectedGruppen)">
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>

                    <div ng-class="{'hidden-print': (vm.firmenKontingentFilters | filter:{selected:true}).length === 0}"
                         ng-if="(vm.firmenKontingentFilters | filter:{sticky:true}).length > 0"
                         class="filter">
                        <!-- ausgewählte Firmen Plätze -->
                        <div class="filter-label" data-translate="KINDERORT.FILTER_PLAETZE"></div>
                        <ul class="filter-option-list">
                            <li ng-repeat="filter in vm.firmenKontingentFilters | filter:{sticky:true} | orderBy: 'name'"
                                ng-class="{'hidden-print' : !filter.selected}">
                                <dvb-filter-option label="filter.name"
                                                   ng-class="{'negative-anzahl' : filter.plaetze < 0}"
                                                   is-selected="filter.selected"
                                                   closeable="true"
                                                   on-close="vm.closeFilterOption(filter, vm.setSelectedFirmenKontingent)"
                                                   on-toggle="vm.toggleFilterOption(filter, isSelected, vm.setSelectedFirmenKontingent)">
                            <span class="anzahl-plaetze">
                                <dvlib-spinner [show]="filter.plaetze === null"></dvlib-spinner>
                                <span ng-if="filter.plaetze !== null"
                                      ng-class="{'positiv-anzahl' : filter.plaetze > 0}"
                                      ng-bind="filter.plaetze | number:2">
                                </span>
                            </span>
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>

                    <div ng-class="{'hidden-print': !vm.provisorisch.selected &&
                                   !vm.angebot.selected &&
                                   !vm.definitiv.selected &&
                                   !vm.keineZuweisung.selected}" class="filter" ng-if="vm.provisorisch.sticky ||
                                   vm.angebot.sticky ||
                                   vm.definitiv.sticky ||
                                   vm.keineZuweisung.sticky">
                        <!-- Status -->
                        <div class="filter-label" data-translate="COMMON.FILTER_STATUS"></div>
                        <ul class="filter-option-list">
                            <li ng-if="vm.provisorisch.sticky"
                                ng-class="{'hidden-print' : !vm.provisorisch.selected}">
                                <dvb-filter-option label="'COMMON.ZUWEISUNG_IST_PROVISORISCH' | translate"
                                                   is-selected="vm.provisorisch.selected"
                                                   closeable="true"
                                                   on-close="vm.closeKindFilterOption(vm.provisorisch)"
                                                   on-toggle="vm.toggleKindFilterOption(vm.provisorisch, isSelected)">
                                </dvb-filter-option>
                            </li>
                            <li ng-if="vm.angebot.sticky"
                                ng-class="{'hidden-print' : !vm.angebot.selected}">
                                <dvb-filter-option label="'COMMON.ZUWEISUNG_IST_ANGEBOT' | translate"
                                                   is-selected="vm.angebot.selected"
                                                   closeable="true"
                                                   on-close="vm.closeKindFilterOption(vm.angebot)"
                                                   on-toggle="vm.toggleKindFilterOption(vm.angebot, isSelected)">
                                </dvb-filter-option>
                            </li>
                            <li ng-if="vm.definitiv.sticky"
                                ng-class="{'hidden-print' : !vm.definitiv.selected}">
                                <dvb-filter-option label="'COMMON.ZUWEISUNG_IST_DEFINITIV' | translate"
                                                   is-selected="vm.definitiv.selected"
                                                   closeable="true"
                                                   on-close="vm.closeKindFilterOption(vm.definitiv)"
                                                   on-toggle="vm.toggleKindFilterOption(vm.definitiv, isSelected)">
                                </dvb-filter-option>
                            </li>
                            <li ng-if="vm.keineZuweisung.sticky"
                                ng-class="{'hidden-print' : !vm.keineZuweisung.selected}">
                                <dvb-filter-option label="'COMMON.KEINE_ZUWEISUNG' | translate"
                                                   is-selected="vm.keineZuweisung.selected"
                                                   closeable="true"
                                                   on-close="vm.closeKindFilterOption(vm.keineZuweisung)"
                                                   on-toggle="vm.toggleKindFilterOption(vm.keineZuweisung, isSelected)">
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>

                    <div ng-class="{'hidden-print' : (vm.kindergartenFilter | filter:{selected:true}).length === 0}"
                         ng-if="(vm.kindergartenFilter | filter:{sticky:true}).length > 0 "
                         class="filter">
                        <!-- Kindergarten -->
                        <div data-translate="COMMON.KINDERGARTEN" class="filter-label"></div>
                        <ul class="filter-option-list">
                            <li ng-repeat="filter in vm.kindergartenFilter | filter:{sticky:true}"
                                ng-class="{'hidden-print': !filter.selected}">
                                <dvb-filter-option label="filter.name"
                                                   is-selected="filter.selected"
                                                   closeable="true"
                                                   on-close="vm.closeKindFilterOption(filter)"
                                                   on-toggle="vm.toggleKindFilterOption(filter, isSelected)">
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>

                    <div ng-if="vm.pensumFilter.sticky"
                         ng-class="{'hidden-print': !vm.pensumFilter.selected}"
                         class="filter">
                        <!-- Pensum Filter -->
                        <div data-translate="COMMON.FILTER_PENSUM" class="filter-label"></div>
                        <ul class=" filter-option-list">
                            <li ng-if="vm.pensumFilter.sticky"
                                ng-class="{'hidden-print': !vm.pensumFilter.selected}">
                                <dvb-filter-option-popover filter-model="vm.pensumFilter"
                                                           closeable="true"
                                                           on-close="vm.closeFilterOption(vm.pensumFilter, vm.setPensumFilter)"
                                                           on-toggle="vm.toggleFilterOption(vm.pensumFilter, isSelected, vm.setPensumFilter)"
                                                           on-close-popover="vm.setPensumFilter()">
                                </dvb-filter-option-popover>
                            </li>
                        </ul>
                    </div>

                    <div ng-if="vm.customFieldsFilter.hasSticky()"
                         ng-class="{'hidden-print' : !vm.customFieldsFilter.hasSelected()}"
                         class="filter">
                        <!-- custom fields -->
                        <div data-translate="COMMON.CUSTOM_FIELD.MF"
                             data-translate-value-count="{{vm.customFields.length}}"
                             data-translate-interpolation="messageformat"
                             class="filter-label">
                        </div>
                        <ul class="filter-option-list">
                            <li ng-repeat="filter in vm.customFieldsFilter.filterOptions | filter:{sticky:true}"
                                ng-class="{'hidden-print': !filter.selected}">
                                <dvb-filter-option label="filter.name"
                                                   is-selected="filter.selected"
                                                   closeable="true"
                                                   on-close="vm.closeFilterOption(filter, vm.setSelectedCustomFields)"
                                                   on-toggle="vm.toggleFilterOption(filter, isSelected, vm.setSelectedCustomFields)">
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>

                    <div ng-if="vm.geschwisterFilter.sticky"
                         ng-class="{'hidden-print': !vm.geschwisterFilter.selected}"
                         class="filter">
                        <!-- Geschwister -->
                        <div data-translate="COMMON.FILTER_GESCHWISTER" class="filter-label"></div>
                        <ul class="filter-option-list">
                            <li ng-if="vm.geschwisterFilter.sticky"
                                ng-class="{'hidden-print': !vm.geschwisterFilter.selected}">
                                <dvb-filter-option is-selected="vm.geschwisterFilter.selected"
                                                   closeable="true"
                                                   on-close="vm.closeKindFilterOption(vm.geschwisterFilter)"
                                                   on-toggle="vm.toggleKindFilterOption(vm.geschwisterFilter, isSelected)">
                                    <i class="fa fa-chain"></i>
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>

                    <div ng-if="vm.anmeldeFilter.sticky"
                         ng-class="{'hidden-print': !vm.anmeldeFilter.selected}"
                         class="filter">
                        <!-- Anmeldedatum -->
                        <div data-translate="COMMON.FILTER_ANMELDUNG" class="filter-label"></div>
                        <ul class="filter-option-list">
                            <li ng-if="vm.anmeldeFilter.sticky"
                                ng-class="{'hidden-print': !vm.anmeldeFilter.selected}">
                                <dvb-filter-option-popover filter-model="vm.anmeldeFilter"
                                                           closeable="true"
                                                           on-close="vm.closeFilterOption(vm.anmeldeFilter, vm.setAnmeldeFilter)"
                                                           on-toggle="vm.toggleFilterOption(vm.anmeldeFilter, isSelected, vm.setAnmeldeFilter)"
                                                           on-close-popover="vm.setAnmeldeFilter()">
                                </dvb-filter-option-popover>
                            </li>
                        </ul>
                    </div>

                    <div ng-if="vm.showPrioFilter && (vm.prioritaetsFilter | filter:{sticky:true}).length > 0"
                         ng-class="{'hidden-print': (vm.prioritaetsFilter | filter:{selected:true}).length === 0}"
                         class="filter">
                        <!-- Prio -->
                        <div data-translate="COMMON.FILTER_PRIORITAET" class="filter-label"></div>
                        <ul class="filter-option-list">
                            <li ng-if="vm.showPrioFilter"
                                ng-repeat="prioFilter in vm.prioritaetsFilter | filter:{sticky:true}"
                                ng-class="{'hidden-print': !prioFilter.selected}">
                                <dvb-filter-option label="prioFilter.name"
                                                   is-selected="prioFilter.selected"
                                                   closeable="true"
                                                   on-close="vm.closeFilterOption(prioFilter, vm.onPrioritaetFilterChange)"
                                                   on-toggle="vm.toggleFilterOption(prioFilter, isSelected, vm.onPrioritaetFilterChange)">
                                </dvb-filter-option>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-3 text-right">
                <dvb-filter-toggler is-collapsed="vm.filterCollapsed"
                                    show-reset="vm.isFilterSet()"
                                    on-toggle="vm.toggleCollapse()"
                                    on-reset="vm.resetFilter()">
                </dvb-filter-toggler>
            </div>
        </div>
    </div>

    <!-- FILTER:OPTIONS -->
    <div class="hidden-print" uib-collapse="vm.filterCollapsed">
        <div class="row">
            <div class="filter-list column col-md-12">
                <div ng-if="(vm.gruppenFilters | filter:{sticky:false}).length > 0" class="filter">
                    <div data-translate="KINDERORT.FILTER_GRUPPEN" class="filter-label"></div>
                    <ul class="filter-option-list">
                        <li ng-repeat="filter in vm.gruppenFilters | filter:{sticky:false} | orderBy: 'name'">
                            <dvb-filter-option label="filter.name"
                                               is-selected="filter.selected"
                                               on-toggle="vm.toggleSticky(filter)">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div ng-if="(vm.firmenKontingentFilters | filter:{sticky:false}).length > 0" class="filter">
                    <div data-translate="KINDERORT.FILTER_PLAETZE" class="filter-label"></div>
                    <ul
                        class="filter-option-list">
                        <li ng-repeat="filter in vm.firmenKontingentFilters | filter:{sticky:false} | orderBy: 'name'">
                            <dvb-filter-option label="filter.name"
                                               is-selected="filter.selected"
                                               ng-class="{'negative-anzahl' : filter.plaetze < 0}"
                                               on-toggle="vm.toggleSticky(filter)">
                            <span class="anzahl-plaetze">
                                <dvlib-spinner [show]="filter.plaetze === null"></dvlib-spinner>
                                <span ng-if="filter.plaetze !== null"
                                      ng-class="{'positiv-anzahl' : filter.plaetze > 0}"
                                      ng-bind="filter.plaetze | number:2">
                                </span>
                            </span>
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div ng-if="!vm.provisorisch.sticky ||
                           !vm.angebot.sticky ||
                           !vm.definitiv.sticky ||
                           !vm.keineZuweisung.sticky" class="filter">
                    <label data-translate="COMMON.FILTER_STATUS" class="filter-label"></label>
                    <ul
                        class="filter-option-list">
                        <li ng-if="!vm.provisorisch.sticky">
                            <dvb-filter-option label="vm.provisorisch.name"
                                               on-toggle="vm.toggleSticky(vm.provisorisch)">
                            </dvb-filter-option>
                        </li>
                        <li ng-if="!vm.angebot.sticky">
                            <dvb-filter-option label="vm.angebot.name"
                                               on-toggle="vm.toggleSticky(vm.angebot)">
                            </dvb-filter-option>
                        </li>
                        <li ng-if="!vm.definitiv.sticky">
                            <dvb-filter-option label="vm.definitiv.name"
                                               on-toggle="vm.toggleSticky(vm.definitiv)">
                            </dvb-filter-option>
                        </li>
                        <li ng-if="!vm.keineZuweisung.sticky">
                            <dvb-filter-option label="vm.keineZuweisung.name"
                                               on-toggle="vm.toggleSticky(vm.keineZuweisung)">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div class="filter" ng-if="(vm.kindergartenFilter | filter:{sticky:false}).length > 0">
                    <div data-translate="COMMON.KINDERGARTEN" class="filter-label"></div>
                    <ul class="filter-option-list">
                        <li ng-repeat="kindergartenFilter in vm.kindergartenFilter | filter: {sticky:false}">
                            <dvb-filter-option label="kindergartenFilter.name"
                                               is-selected="kindergartenFilter.selected"
                                               on-toggle="vm.toggleSticky(kindergartenFilter)">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div class="filter" ng-if="!vm.pensumFilter.sticky">
                    <!-- Pensum Filter -->
                    <div data-translate="COMMON.FILTER_PENSUM"
                         class="filter-label">
                    </div>
                    <ul class="filter-option-list">
                        <li>
                            <dvb-filter-option-popover editable="false"
                                                       filter-model="vm.pensumFilter"
                                                       on-toggle="vm.toggleSticky(vm.pensumFilter)"
                                                       on-close-popover="vm.setPensumFilter()">
                            </dvb-filter-option-popover>
                        </li>
                    </ul>
                </div>

                <div class="filter" ng-if="vm.customFieldsFilter.hasUnsticky()">
                    <!-- custom fields -->
                    <div data-translate="COMMON.CUSTOM_FIELD.MF"
                         data-translate-value-count="{{vm.customFields.length}}"
                         data-translate-interpolation="messageformat"
                         class="filter-label"></div>
                    <ul class="filter-option-list">
                        <li ng-repeat="customFieldFilter in vm.customFieldsFilter.filterOptions | filter:{sticky:false}">
                            <dvb-filter-option label="customFieldFilter.name"
                                               is-selected="customFieldFilter.selected"
                                               on-toggle="vm.toggleSticky(customFieldFilter)">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div class="filter" ng-if="!vm.geschwisterFilter.sticky">
                    <!-- geschwister -->
                    <div data-translate="COMMON.FILTER_GESCHWISTER"
                         class="filter-label">
                    </div>
                    <ul class="filter-option-list">
                        <li>
                            <dvb-filter-option is-selected="vm.geschwisterFilter.selected"
                                               on-toggle="vm.toggleSticky(vm.geschwisterFilter)">
                                <i class="fa fa-chain"></i>
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>

                <div ng-if="!vm.anmeldeFilter.sticky" class="filter">
                    <!-- anmeldedatum -->
                    <div data-translate="COMMON.FILTER_ANMELDUNG"
                         class="filter-label">
                    </div>
                    <ul class="filter-option-list">
                        <li>
                            <dvb-filter-option-popover editable="false"
                                                       filter-model="vm.anmeldeFilter"
                                                       on-toggle="vm.toggleSticky(vm.anmeldeFilter)"
                                                       on-close-popover="vm.setAnmeldeFilter()">
                            </dvb-filter-option-popover>
                        </li>
                    </ul>
                </div>

                <div ng-if="vm.showPrioFilter && (vm.prioritaetsFilter | filter:{sticky:false}).length > 0"
                     class="filter">
                    <!-- prio -->
                    <div data-translate="COMMON.FILTER_PRIORITAET"
                         class="filter-label">
                    </div>
                    <ul
                        class="filter-option-list ">
                        <li ng-repeat="prioFilter in vm.prioritaetsFilter | filter:{sticky:false}">
                            <dvb-filter-option label="prioFilter.name"
                                               is-selected="prioFilter.selected"
                                               on-toggle="vm.toggleSticky(prioFilter)">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
