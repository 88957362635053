<form name="vm.dvbEditableLabelForm" novalidate class="dvb-editable-label">
    <div uib-tooltip="{{vm.placeholder}}" tooltip-placement="top-left">
        <label class="form-control click2edit">
            <span ng-if="vm.ngModel.$modelValue !== null || vm.ngModel.$modelValue !== undefined">
                <a ng-if="vm.isLink"
                   ng-href="{{vm.ngModel.$modelValue}}"
                   ng-bind="vm.ngModel.$modelValue"
                   target="_blank"
                   rel="noopener">
                </a>
                <a ng-if="vm.isEmail"
                   ng-href="{{'mailto:' + vm.ngModel.$modelValue}}"
                   ng-bind="vm.ngModel.$modelValue"
                   target="_blank"
                   rel="noopener">
                </a>
            </span>

            <span ng-if="!vm.ngModel.$modelValue || (!vm.isLink && !vm.isEmail)"
                  ng-bind="vm.label || vm.ngModel.$modelValue || vm.placeholder;">
            </span>
        </label>

        <!-- The type attribute cannot be interpolated and understood for validation at the same time. -->
        <!-- Thus, we have to create separate elements for each supported type. -->

        <input class="form-control"
               ng-if="vm.type === 'text'"
               type="text"
               name="text"
               ng-model="vm.ngModel.$viewValue"
               ng-minlength="vm.attrs.minlength"
               ng-maxlength="vm.attrs.maxlength"
               ng-pattern="vm.attrs.pattern"
               ng-keydown="vm.submitOrReset($event)"
               ng-attr-placeholder="{{vm.placeholder}}">
        <input class="form-control"
               ng-if="vm.type === 'email'"
               type="email"
               name="email"
               ng-model="vm.ngModel.$viewValue"
               ng-minlength="vm.attrs.minlength"
               ng-maxlength="vm.attrs.maxlength"
               ng-pattern="vm.attrs.pattern"
               ng-keydown="vm.submitOrReset($event)"
               ng-attr-placeholder="{{vm.placeholder}}">
        <input class="form-control"
               ng-if="vm.type === 'number'"
               type="number"
               name="number"
               ng-model="vm.ngModel.$viewValue"
               ng-pattern="vm.attrs.pattern"
               ng-step="vm.attrs.step"
               ng-min="vm.attrs.min"
               ng-max="vm.attrs.max"
               ng-keydown="vm.submitOrReset($event)"
               ng-attr-placeholder="{{vm.placeholder}}">
    </div>
</form>
