/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TaskFilterCatergory} from '@dv/kitadmin/models';
import {DisplayTask, TASK_CATEGORY} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import type {PageContainer, RestPaginated} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import type {UserSettingsStore} from '../../../cache/service/cache/userSettingsStore';
import {TaskFilter} from '../../../dashboard/models/TaskFilter';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class TasksService {
    public static $inject: readonly string[] = [
        'userSettingsStore',
        'authStore',
    ];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/tasks`;

    public constructor(
        private userSettingsStore: UserSettingsStore,
        private authStore: AuthStore,
    ) {
    }

    public getAllFiltered(
        taskFilter: TaskFilter,
        params?: Partial<RestPaginated>,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<DisplayTask>> {
        const matrixParams = params ?? {};

        const data = taskFilter.toRestObject();

        return DvbRestUtilAngularJS.postAndGetPagedItems(
            TasksService.BASE_URL,
            data,
            DisplayTask,
            matrixParams,
            config);
    }

    public getTaskFilter(): TaskFilter {
        return this.userSettingsStore.getOrDefault('TASK_FILTER', this.initTaskFilter.bind(this));
    }

    private initTaskFilter(): TaskFilter {
        const filter = new TaskFilter();

        const categories: TaskFilterCatergory[] = Object.values(TASK_CATEGORY)
            .filter(category => this.authStore.isPermitted(category.permission))
            .map(category => category.super)
            .reduce<TaskFilterCatergory[]>((unique, item) =>
                unique.includes(item) ? unique : [...unique, item], []);

        filter.filterCategories = TaskFilter.generateCategoryFilterOptions(categories);

        return filter;
    }
}
