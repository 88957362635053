/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeVerfuegung, KinderOrt} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import {VERFUEGUNG_ASSIGNMENT_STATE} from '../../../kibon-states';
import type {KibonVerfuegungenService} from '../../../service/kibonVerfuegungenService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        verfuegungen: '<',
    },
    template: require('./dvb-kibon-verfuegung-overview.html'),
    controllerAs: 'vm',
};

export class DvbKibonVerfuegungOverview implements angular.IController {
    public static $inject: readonly string[] = ['$state', 'dialogService', 'kibonVerfuegungenService'];

    public verfuegungen: KibonExchangeVerfuegung[] = [];

    public currentPage: number = 1;
    public isLoading: boolean = false;
    public itemsPerPage: number = 10;

    public kinderOrtMapping: { [key: string]: KinderOrt } = {};

    public constructor(
        private $state: StateService,
        private dialogService: DialogService,
        private verfuegungenService: KibonVerfuegungenService,
    ) {
    }

    public updatePage(page: number): void {
        this.currentPage = page;
    }

    public onUpdateItemsPerPage(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;
    }

    public assign(verfuegung: KibonExchangeVerfuegung): void {
        this.$state.go(VERFUEGUNG_ASSIGNMENT_STATE.name, {id: verfuegung.id});
    }

    public ignore(verfuegung: KibonExchangeVerfuegung): void {

        const confirm = (): Observable<unknown> => from(this.verfuegungenService.ignoreKibonVerfuegung(verfuegung.id))
            .pipe(take(1), tap(() => this.$state.reload()));

        this.dialogService.openConfirmDialog({
            title: 'KIBON.VERFUEGUNGEN.IGNORE_TITLE',
            subtitle: 'KIBON.VERFUEGUNGEN.IGNORE_SUBTITLE',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm,
        });
    }
}

componentConfig.controller = DvbKibonVerfuegungOverview;
angular.module('kitAdmin').component('dvbKibonVerfuegungOverview', componentConfig);
