/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRestModel, MatrixParams, RestPaginated} from '@dv/shared/code';
import {BEGIN_OF_TIME, DvbDateUtil, DvbRestUtil, DvbUtil, END_OF_TIME, Gueltigkeit, isPresent} from '@dv/shared/code';
import moment from 'moment';
import {PopoverFilterOption} from '../../filter/popover/PopoverFilterOption';
import {PopoverFilterPropertyGueltigkeit} from '../../filter/popover/PopoverFilterPropertyGueltigkeit';
import {FilterOption} from '../../filter/shared/FilterOption';

export abstract class AbstractKibonFilter implements RestPaginated, IRestModel {

    protected constructor(
        public kinderOrte: FilterOption[] = [],
        public mandant?: FilterOption,
        public showWithMissingInstitution: boolean = false,
        public kindName?: string,
        public refNr?: string,
        public top?: number,
        public skip?: number,
        public gueltigkeit: PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit> =
            AbstractKibonFilter.defaultGueltigkeitFilter(),
    ) {
    }

    public static defaultGueltigkeitFilter()
        : PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit> {

        const today = DvbDateUtil.today();
        const august = 7;
        const startOfPeriode = moment(today).date(1).month(august);
        if (startOfPeriode.isAfter(today)) {
            startOfPeriode.subtract(1, 'year');
        }
        const gueltigkeit = new Gueltigkeit(startOfPeriode, null);

        return new PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>(
            'anmeldeDatum',
            new PopoverFilterPropertyGueltigkeit(gueltigkeit));
    }

    public isFiltering(): boolean {
        if (DvbUtil.isNotEmptyString(this.refNr)) {
            return true;
        }

        if (FilterOption.getSelectedIds(this.kinderOrte).length > 0) {
            return true;
        }

        if (this.showWithMissingInstitution) {
            return true;
        }

        if (this.gueltigkeit.selected) {
            return true;
        }

        return !!this.mandant?.selected;
    }

    public reset(): void {
        FilterOption.unselect(this.kinderOrte);
        if (isPresent(this.mandant)) {
            this.mandant.selected = false;
        }
        this.showWithMissingInstitution = false;
        this.gueltigkeit.selected = false;
        this.kindName = undefined;
        this.refNr = undefined;
        this.top = undefined;
        this.skip = undefined;
    }

    public toRestObject(): MatrixParams {
        const result: MatrixParams = {
            top: this.top,
            skip: this.skip,
        };

        if (this.kinderOrte.length > 0) {
            result.kinderOrtIds = FilterOption.getSelectedIds(this.kinderOrte);
        }

        if (this.mandant?.selected) {
            result.mandantId = this.mandant.id;
        }

        if (this.showWithMissingInstitution) {
            result.showWithMissingInstitution = true;
        }

        if (this.gueltigkeit.selected) {
            this.gueltigkeitToRestObject(result);
        }

        if (DvbUtil.isNotEmptyString(this.kindName)) {
            result.kindName = this.kindName;
        }

        if (DvbUtil.isNotEmptyString(this.refNr)) {
            result.refNr = this.refNr;
        }

        return result;
    }

    private gueltigkeitToRestObject(result: MatrixParams): void {
        const gueltigAb = this.gueltigkeit.popoverModel.gueltigkeit?.gueltigAb;
        if (gueltigAb && !DvbDateUtil.isMomentEquals(gueltigAb, BEGIN_OF_TIME)) {
            result.periodeAb = DvbRestUtil.momentToLocalDate(gueltigAb);
        }

        const gueltigBis = this.gueltigkeit.popoverModel.gueltigkeit?.gueltigBis;
        if (gueltigBis && !DvbDateUtil.isMomentEquals(gueltigBis, END_OF_TIME)) {
            result.periodeBis = DvbRestUtil.momentToLocalDate(gueltigBis);
        }
    }
}
