/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import type angular from 'angular';
import {IS_NO_STATE} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

export function waitForTranslationsHook($transitions: TransitionService): void {
    $transitions.onBefore(IS_NO_STATE, waitForTranslations, {priority: OnBeforePriorities.TRANSLATIONS});
}

function waitForTranslations(transition: Transition): HookResult {
    const $translate = transition.injector().get<angular.translate.ITranslateService>('$translate');

    return $translate.onReady() as HookResult;
}
