/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted, TerminationMode} from '@dv/shared/code';
import {DvbDateUtil, HttpCodes} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {EDIT_ANSTELLUNG_STATE, NEW_ANSTELLUNG_STATE} from '../../anstellung-states';
import type {Angestellte} from '../../models/Angestellte';
import type {Anstellung} from '../../models/Anstellung';
import type {AngestellteService} from '../../service/angestellteService';
import type {AnstellungService} from '../../service/anstellungService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        angestellte: '<',
        anstellungen: '<',
    },
    template: require('./dvb-angestellte-anstellung.html'),
    controllerAs: 'vm',
};

export class DvbAngestellteAnstellung implements angular.IController, angular.IOnChanges {
    public static $inject: readonly string[] = [
        '$state',
        'anstellungService',
        'angestellteService',
        'errorService',
        'authStore',
    ];

    public angestellte!: Persisted<Angestellte>;
    public anstellungen: Persisted<Anstellung>[] = [];

    public sortedAnstellungen: Persisted<Anstellung>[] = [];

    public hasEditPrivileges: boolean = false;

    public constructor(
        private readonly $state: StateService,
        private readonly anstellungService: AnstellungService,
        private readonly angestellteService: AngestellteService,
        private readonly errorService: ErrorService,
        private readonly authStore: AuthStore,
    ) {
    }

    public $onChanges(): void {
        this.sortedAnstellungen = DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(this.anstellungen);

        this.hasEditPrivileges = this.authStore.hasPermission(PERMISSION.PERSONAL.ADMINISTRATE_ANY);
    }

    public goToCreateAnstellung(): void {
        this.$state.go(NEW_ANSTELLUNG_STATE.name);
    }

    public deleteAnstellung(anstellung: Anstellung): angular.IPromise<unknown> {
       return this.anstellungService.delete(anstellung.id!)
            .then(() => this.$state.reload());
    }

    public goToEditAnstellung(anstellung: Anstellung): void {
        this.$state.go(EDIT_ANSTELLUNG_STATE.name, {anstellungId: anstellung.id});
    }

    public terminate(endDate: moment.Moment, mode: TerminationMode): angular.IPromise<any> {
        return this.angestellteService.terminateAnstellung(this.angestellte.id, endDate, mode)
            .then(() => this.$state.reload());
    }

    public revert(anstellung: Persisted<Anstellung>): void {
        this.anstellungService.extend(anstellung.id)
            .then(() => this.$state.reload())
            .catch(response => {
                if (response.status === HttpCodes.NOT_MODIFIED) {
                    this.errorService.addValidationError('ERRORS.ERR_REVERT_BEENDEN');

                    return;
                }

                throw response;
            });
    }
}

componentConfig.controller = DvbAngestellteAnstellung;
angular.module('kitAdmin').component('dvbAngestellteAnstellung', componentConfig);
