<div class="dvb-limited-entity-verlauf">

    <!-- terminated -->
    <dvb-collapsible-panel
        ng-show="(vm.previousEntity && vm.previousEntity.gueltigAb.diff(vm.entity.gueltigBis, 'days') > 1) || vm.first && vm.isTerminated"
        is-first="vm.first"
        is-collapsed="true">
        <panel-header>
            <p data-translate="COMMON.BEENDET_TITLE"
               data-translate-value-endedatum="{{vm.entity.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
        </panel-header>
        <panel-content>
            <p data-translate="COMMON.BEENDET_TITLE"
               data-translate-value-endedatum="{{vm.entity.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

            <button ng-if="vm.enableActions"
                    type="button"
                    class="btn btn-link normal-abstand-top"
                    data-translate="COMMON.BEENDEN_REVERT"
                    ng-click="vm.revert()">
            </button>
        </panel-content>
    </dvb-collapsible-panel>

    <dvb-collapsible-panel limited-entity="vm.entity"
                           is-first="vm.first && !vm.isTerminated"
                           expand-ab="vm.today"
                           expand-bis="vm.today">
        <panel-header>
            <ng-transclude ng-transclude-slot="entityHeading"></ng-transclude>
        </panel-header>
        <panel-content>
            <dvb-limited-entity-content entity="vm.entity"
                                        enable-edit-mode="vm.enableEditMode"
                                        translation-root="vm.translationRoot"
                                        enable-actions="vm.enableActions"
                                        on-edit="vm.onEdit()"
                                        on-submit="vm.submit(context)"
                                        on-remove="vm.remove()">
                <entity-form ng-transclude="entityForm"></entity-form>
                <entity-read-only ng-transclude="entityReadOnly"></entity-read-only>
            </dvb-limited-entity-content>
        </panel-content>
    </dvb-collapsible-panel>
</div>
