/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FakturaMassenaktionError, KinderOrt, KitaRechnungen} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import type {StateObject} from '@uirouter/core/lib/state/stateObject';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, tap} from 'rxjs';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';
import type {RechnungenFilter} from '../../../../filter/RechnungenFilter';
import type {
    KitaFakturaMassenaktionErrorDialogModel,
} from '../kita-faktura-massenaktion-error/kita-faktura-massenaktion-error.component';
import {
    KitaFakturaMassenaktionErrorComponent,
} from '../kita-faktura-massenaktion-error/kita-faktura-massenaktion-error.component';
import type {
    RechnungEmailMassenVersandBestaetigenDialogModel,
} from '../rechnung-email-massen-versand-bestaetigen/rechnung-email-massen-versand-bestaetigen.component';
import {
    RechnungEmailMassenVersandBestaetigenComponent,
} from '../rechnung-email-massen-versand-bestaetigen/rechnung-email-massen-versand-bestaetigen.component';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        kita: '<',
        filter: '<',
        kitaRechnungen: '<',
    },
    template: require('./dvb-kita-faktura-massenaktionen.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaMassenaktionen implements angular.IController {
    public static $inject: readonly string[] = [
        'dialogService',
        'fakturaService',
        '$state',
        '$translate',
    ];

    public kita!: Persisted<KinderOrt>;
    public filter!: RechnungenFilter;
    public kitaRechnungen: KitaRechnungen | undefined;

    public constructor(
        private readonly dialogService: DialogService,
        private readonly fakturaService: FakturaService,
        private readonly $state: StateService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public stornieren(): void {
        const params = this.filter.toRestObject();
        this.dialogService.openConfirmDialog({
            title: 'FAKTURA.RECHNUNGEN_STORNIEREN',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm: () => from(this.fakturaService.storniereFilteredRechnungen(this.kita.id, params)).pipe(
                tap(errors => this.handleStornierenResponse(errors)),
            ),
        });
    }

    public sendRechnungEmail(): void {
        const dialogModel: RechnungEmailMassenVersandBestaetigenDialogModel = {
            kitaRechnungen: this.kitaRechnungen,
            kita: this.kita,
            rechnungenFilter: this.filter,
            fakturaService: this.fakturaService,
        };

        this.dialogService.openDialog(RechnungEmailMassenVersandBestaetigenComponent, dialogModel);
    }

    private handleStornierenResponse(errors: FakturaMassenaktionError[]): void {
        if (!DvbUtil.isNotEmptyArray(errors)) {
            this.$state.reload();

            return;
        }

        errors.forEach(err => err.initErrorText(this.$translate));

        const reload = (): Observable<StateObject> => from(this.$state.reload());
        const dialogModel: KitaFakturaMassenaktionErrorDialogModel = {
            errors,
            confirm: reload,
            cancel: reload,
        };

        this.dialogService.openDialog(KitaFakturaMassenaktionErrorComponent, dialogModel);
    }
}

componentConfig.controller = DvbKitaFakturaMassenaktionen;
angular.module('kitAdmin').component('dvbKitaFakturaMassenaktionen', componentConfig);
