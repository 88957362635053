/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TempBlob} from '@dv/kitadmin/models';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export const BELEGUNG_INCLUDES =
    'extraPlatz.fields(belegungsEinheit.fields(zeitraumIds), belegungsEinheitId, kontingent),' +
    'belegungen.fields(customFieldValues, bezugsPersonen, gruppenBelegungen.fields(pensum, gruppeId, vertraglichePensen, anwesenheit, plaetze.fields(belegungsEinheitId, kontingentId)))';

export class BelegungsService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/belegungen`;

    public constructor(
        private $http: angular.IHttpService,
    ) {
    }

    public deleteBelegung(belegungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${BelegungsService.BASE_URL}/${encodeURIComponent(belegungId)}`);
    }

    public resetAustrittProvisorisch(belegungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${BelegungsService.BASE_URL}/${encodeURIComponent(belegungId)}/austrittprovisorisch/reset`;

        return this.$http.put(url, {});
    }

    public getBetreuungsVereinbarung(
        belegungId: string, kitaId: string,
        betreuungsvereinbarungTypeId: string | null,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<TempBlob> {
        const matrixParams = {kitaId, betreuungsvereinbarungTypeId};

        const url = `${BelegungsService.BASE_URL}/${encodeURIComponent(belegungId)}/` +
            `betreuungsvereinbarung${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return DvbRestUtilAngularJS.postAndGetModel(url, {}, TempBlob, {}, false, config);
    }
}
