/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SearchResultEntry} from '@dv/shared/code';
import type {IController} from 'angular';
import angular from 'angular';
import {getTitle} from '../../component/search-result-icon/search-result-icon-helpers';

const componentConfig: angular.IComponentOptions = {
    template: require('./dvb-search-result-icon.html'),
    bindings: {
        entry: '<',
    },
    controllerAs: 'vm',
};

export class DvbSearchResultIcon implements IController {
    public static $inject: readonly string[] = ['$translate'];

    public entry: SearchResultEntry | null = null;
    public title: string = '';

    public constructor(
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(): void {
        this.title = this.$translate.instant(getTitle(this.entry));
    }
}

componentConfig.controller = DvbSearchResultIcon;
angular.module('kitAdmin').component('dvbSearchResultIcon', componentConfig);
