<div class="dvb-dokument-layout">
    <div class="row">
        <div class="col-md-12">
            <dvlib-spinner [show]="vm.loadingLayouts"></dvlib-spinner>
            <select class="form-control"
                    ng-change="vm.onChange({dokumentLayout: vm.dokumentLayout})"
                    ng-if="!vm.loadingLayouts"
                    ng-model="vm.dokumentLayout"
                    ng-options="layout as layout.name for layout in vm.layouts | orderBy:'name' track by layout.id "
                    tooltip-placement="top-left"
                    uib-tooltip="{{'KINDERORT.LAYOUT' | translate}}">
                <option data-translate="KINDERORT.KEIN_LAYOUT" ng-value="null"></option>
            </select>
        </div>
    </div>
</div>
