<span class="dvb-rechnung-task-icon" ng-if="vm.showIcon">
    <i class="fa fa-exclamation-circle"
       uib-popover-template="'rechnungsdifferenzTooltipTemplate.html'"
       popover-title="{{vm.getTitleKey() | translate}}"
       popover-trigger="'mouseenter focus'"
       popover-placement="bottom"
       popover-append-to-body="true"
       tabindex="0">
    </i>
</span>
