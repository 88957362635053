/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import moment from 'moment';

angular.module('kitAdmin').directive('dvbTimeInput', dvbTimeInput);

const MAX_MINUTES = 60;
const MAX_MINUTES_SINGLE_DIGIT = 6;
const MIDNIGHT = 24;

function dvbTimeInput(): angular.IDirective {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (_scope, element, _attrs, ctrl: any): void => {
            ctrl.$parsers.unshift(parse);
            ctrl.$formatters.push(format);

            element.on('blur', () => {
                ctrl.$setViewValue(format(ctrl.$modelValue));
                ctrl.$render();
            });
        },
    };

    /**
     * Parses a value like '10:00' into a moment object. Understands many ways of typing in the time. Gracefully
     * falls back to defaults and eventually returns null when it cannot make out at all what time was meant to be
     * entered.
     */
    function parse(value: string): moment.Moment | undefined | null {
        if (!value) {
            return null;
        }

        const digits = value.replace(/[^\d]/g, '');

        if (digits.length === 0) {
            return undefined;
        }

        if (digits.length === 1) {
            const hour1digit = parseHour(digits);

            return toMoment(hour1digit, 0);
        }

        const firstDigit = digits.substring(0, 1);
        const firstDigitNumber = parseInt(firstDigit, 10);

        if (digits.length === 2) {
            const hour2digits = parseHour(digits);

            if (hour2digits !== null) {
                return toMoment(hour2digits, 0);
            }
        } else if (firstDigitNumber >= 0 && firstDigitNumber <= 2) {
            const hour = parseHour(digits.substring(0, 2));
            const maxMinuteDigits = 4;
            const minutes = parseMinutes(digits.substring(2, Math.min(digits.length, maxMinuteDigits)));

            return toMoment(hour, minutes);
        }

        const maxMinuteDigits = 3;
        const minutesAfter = parseMinutes(digits.substring(1, Math.min(digits.length, maxMinuteDigits)));

        return toMoment(parseHour(firstDigit), minutesAfter);
    }

    function parseHour(digits: string): number | null {
        const hour = parseInt(digits, 10);

        if (hour < MIDNIGHT) {
            return hour;
        }

        if (hour === MIDNIGHT) {
            return 0;
        }

        return null;
    }

    function parseMinutes(digits: string): number | null {
        const minutes = parseInt(digits, 10);

        if (minutes < MAX_MINUTES) {
            if (minutes < MAX_MINUTES_SINGLE_DIGIT && digits.length < 2) {
                // If the minutes are '5', '4', '3', '2', '1' or '0' we multiply thenfold
                // This results in 50, 40, 30 and so on
                return minutes * 10;
            }

            return minutes;
        }

        return null;
    }

    /**
     * @param {number|null} hour
     * @param {number|null} minutes
     * @return {moment.Moment|null}
     */
    function toMoment(hour: number | null, minutes: number | null): moment.Moment | undefined {
        if (hour === null || minutes === null) {
            return undefined;
        }

        return moment({hour, minute: minutes});
    }

    function format(time: moment.Moment): string | undefined {
        if (!time) {
            return undefined;
        }

        return time.format('LT');
    }
}
