/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gebuehr, Rechnung} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {switchMap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {KontaktpersonenZahlungService} from '../../../common/service/rest/kontaktpersonen-zahlung.service';
import {OFFENE_POSTEN_STATE} from '../../kontaktperson-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        depot: '<',
    },
    controllerAs: 'vm',
};

export class DvbDepotZuweisenConfirm implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'dialogService',
        'kontaktpersonenZahlungService',
        'dvbStateService',
        '$translate',
    ];

    public readonly rechnung!: Persisted<Rechnung>;
    public readonly depot!: Persisted<Gebuehr>;

    public constructor(
        private readonly $state: StateService,
        private readonly dialogService: DialogService,
        private readonly kontaktpersonenZahlungService: KontaktpersonenZahlungService,
        private readonly dvbStateService: DvbStateService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onInit(): void {
        const title = this.$translate.instant('COMMON.GEBUEHR.CONFIRM_DEPOT_MIT_RECHUNG_ERSTATTEN', {
            betrag: this.depot.betrag,
            rechnungPeriode: this.rechnung.gueltigAb?.format(DvbDateUtil.DATE_FORMAT),
        });

        this.dialogService.openDateDialog({
            title,
            placeholder: 'COMMON.AUSZAHLUNGSDATUM',
            confirmActionText: 'COMMON.AUSZAHLUNG_BESTAETIGEN',
            confirm: auszahlungsDatum => this.kontaktpersonenZahlungService.settleDepotWithRechnung$(
                this.depot.id,
                this.rechnung.id,
                auszahlungsDatum)
                .pipe(switchMap(() => this.$state.go(OFFENE_POSTEN_STATE.name))),
            cancel: () => this.dvbStateService.goToPreviousState(),
        });
    }
}

componentConfig.controller = DvbDepotZuweisenConfirm;
angular.module('kitAdmin').component('dvbDepotZuweisenConfirm', componentConfig);
