<div class="dvb-update-geschlecht-or-sprache row">
    <form class="form" novalidate="novalidate">
        <div class="col-md-3">
            <p>
                <a ui-sref="base.kontaktperson.profil({kontaktpersonId: vm.entry.kontaktpersonId})"
                   ng-bind="vm.entry.getDisplayName()">
                </a>
            </p>
        </div>

        <div class="col-md-5">
            <div class="radio" ng-repeat="geschlecht in vm.geschlechte">
                <label>
                    <input type="radio"
                           name="geschlecht"
                           value="{{geschlecht}}"
                           ng-model="vm.geschlecht"
                           ng-change="vm.onUpdateGeschlecht({data: vm.entry, geschlecht: vm.geschlecht})">
                    <span data-translate="COMMON.{{geschlecht}}"></span>
                </label>
            </div>
        </div>

        <div class="col-md-4">
            <dvb-iso-locale ng-model="vm.sprache"
                            on-submit="vm.sprache = isoObject; vm.onUpdateSprache({entry: vm.entry, sprache: vm.sprache})"
                            dvb-click2edit-iso-locale
                            type="LANGUAGE"
                            ng-attr-placeholder="{{'COMMON.KORRESPONDENZSPRACHE' | translate}}">
            </dvb-iso-locale>
        </div>
    </form>
</div>
