/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        soll: '<',
        verbrauch: '<',
        type: '<',
    },
    template: require('./dvb-anwesenheits-soll-verbrauch-progress.html'),
    controllerAs: 'vm',
};

export class DvbAnwesenheitsSollVerbrauchProgress implements angular.IController {
    public static $inject: readonly string[] = [];

    public soll!: number;
    public verbrauch: number | null = null;
    public type!: string;
}

componentConfig.controller = DvbAnwesenheitsSollVerbrauchProgress;
angular.module('kitAdmin').component('dvbAnwesenheitsSollVerbrauchProgress', componentConfig);
