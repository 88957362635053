/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktion, WochenKapazitaet, Wochenplan} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, NamedEntityType} from '@dv/shared/code';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition} from '@uirouter/core';
import type {IPromise} from 'angular';
import angular from 'angular';
import type moment from 'moment';
import {trans} from '../common/i18n/trans';
import type {FraktionService} from '../common/service/rest/kinderort/fraktionService';
import {WochenplanService} from '../common/service/rest/wochenplanService';
import type {BetreuungsSchluessel} from '../personal/betreuungs-schluessel/models/BetreuungsSchluessel';
import {
    BetreuungsSchluesselFraktionAssignmentController,
} from '../personal/betreuungs-schluessel/models/BetreuungsSchluesselFraktionAssignmentController';
import type {FraktionBetreuungsSchluessel} from '../personal/betreuungs-schluessel/models/FraktionBetreuungsSchluessel';
import type {BetreuungsSchluesselService} from '../personal/betreuungs-schluessel/service/betreuungsSchluesselService';
import type {FraktionBetreuungsSchluesselService} from './service/fraktionBetreuungsSchluesselService';

angular.module('kitAdmin').config(gruppeConfig);

export * from './component/dvb-fraktion-kapazitaet-form/dvb-fraktion-kapazitaet-form';
export * from './component/dvb-fraktion-monatsblatt/dvb-fraktion-monatsblatt';
export * from './component/dvb-monatsblatt-stunden-kontingent/dvb-monatsblatt-stunden-kontingent';
export * from './component/erstellen/dvb-betreuungsperson-erstellen/dvb-betreuungsperson-erstellen';
export * from './component/erstellen/dvb-fraktion-erstellen/dvb-fraktion-erstellen';
export * from './component/erstellen/dvb-gruppe-erstellen/dvb-gruppe-erstellen';
export * from './component/profil/betreuungsperson/dvb-betreuungsperson-kapazitaet/dvb-betreuungsperson-kapazitaet';
export * from './component/profil/betreuungsperson/dvb-betreuungsperson-kinder-list/dvb-betreuungsperson-kinder-list';
export * from './component/profil/betreuungsperson/dvb-betreuungsperson-kontakt/dvb-betreuungsperson-kontakt';
export * from './component/profil/betreuungsperson/dvb-betreuungsperson-main/dvb-betreuungsperson-main';
export * from './component/profil/betreuungsperson/dvb-betreuungsperson-profil/dvb-betreuungsperson-profil';
export * from './component/profil/betreuungsperson/dvb-betreuungsperson-umzug/dvb-betreuungsperson-umzug';
export * from './component/profil/dvb-fraktion-kapazitaet/dvb-fraktion-kapazitaet';
export * from './component/profil/gruppe/dvb-gruppe-header/dvb-gruppe-header';
export * from './component/profil/gruppe/dvb-gruppe-main/dvb-gruppe-main';
export * from './component/profil/gruppe/dvb-gruppe-profil/dvb-gruppe-profil';
export * from './component/profil/gruppe/dvb-gruppe-stammdaten/dvb-gruppe-stammdaten';

// Add New Component JS Above

gruppeConfig.$inject = ['$stateProvider'];

/* eslint-disable sonarjs/no-duplicate-string */

// eslint-disable-next-line max-lines-per-function
function gruppeConfig($stateProvider: StateProvider): void {

    $stateProvider.state('base.fraktion', {
        url: '/fraktion/{id:[0-9]+}',
        abstract: true,
        template: '<ui-view></ui-view>',
        resolve: {
            fraktion: [
                'fraktionService', '$transition$',
                (
                    fraktionService: FraktionService,
                    $transition$: Transition,
                ): angular.IPromise<Persisted<KinderOrtFraktion>> => {
                    const includes = '(wochenKapazitaeten,wochenplan.fields' +
                        `${WochenplanService.WOCHENPLAN_INCLUDES_DEEP},kita.fields(gruppen,monatsblatt)` +
                        ',customFieldValues, angestellteId)';

                    return fraktionService.get($transition$.params().id, {
                        includes,
                    });
                },
            ],
            aktuelleWochenKapazitaet: [
                'fraktion', (fraktion: KinderOrtFraktion): WochenKapazitaet | null =>
                    DvbDateUtil.getEntityOn(fraktion.wochenKapazitaeten, DvbDateUtil.today()),
            ],
            $title: ['fraktion', (fraktion: KinderOrtFraktion): string => fraktion.getDisplayName()],
        },
        data: {
            permission: {oneOf: [PERMISSION.KITA.VIEW_ANY, PERMISSION.PERSONAL.VIEW_ANY]},
        },
    });
    $stateProvider.state('base.fraktion.gruppe', {
        abstract: true,
        url: '/gruppe',
        template: '<dvb-gruppe-main gruppe="$resolve.fraktion"'
            + ' aktuelle-wochen-kapazitaet="$resolve.aktuelleWochenKapazitaet">'
            + '</dvb-gruppe-main>',
    });
    $stateProvider.state('base.fraktion.gruppe.profil', {
        url: '',
        template: '<dvb-gruppe-profil gruppe="$resolve.fraktion"></dvb-gruppe-profil>',
    });
    $stateProvider.state('base.fraktion.gruppe.monatsblatt', {
        url: '/monatsblatt',
        component: 'dvbFraktionMonatsblatt',
        params: {
            periode: null,
            kindId: undefined,
        },
        resolve: {
            periode: ['$transition$', ($transition$: Transition): moment.Moment => $transition$.params().periode],
            kindId: ['$transition$', ($transition$: Transition): string | undefined => $transition$.params().kindId],
        },
        data: {
            permission: PERMISSION.KITA.CONTROL_ANY,
        },
    });

    $stateProvider.state('base.fraktion.betreuungs_person', {
        abstract: true,
        url: '/betreuungsperson',
        template: '<dvb-betreuungsperson-main betreuungs-person="$resolve.fraktion" '
            + 'aktuelle-wochen-kapazitaet="$resolve.aktuelleWochenKapazitaet">'
            + '</dvb-betreuungsperson-main>',
    });
    $stateProvider.state('base.fraktion.betreuungs_person.profil', {
        url: '',
        template: '<dvb-betreuungsperson-profil betreuungs-person="$resolve.fraktion">'
            + '</dvb-betreuungsperson-profil>',
    });
    $stateProvider.state('base.fraktion.betreuungs_person.kontakt', {
        url: '/kontakt',
        template: '<dvb-betreuungsperson-kontakt betreuungs-person="$resolve.fraktion">'
            + '</dvb-betreuungsperson-kontakt>',
        data: {
            permission: PERMISSION.KITA.VIEW_ANY,
        },
    });
    $stateProvider.state('base.fraktion.betreuungs_person.kapazitaet', {
        url: '/kapazitaet',
        template: '<dvb-betreuungsperson-kapazitaet betreuungs-person="$resolve.fraktion">'
            + '</dvb-betreuungsperson-kapazitaet>',
        data: {
            permission: PERMISSION.KITA.VIEW_ANY,
        },
    });
    $stateProvider.state('base.fraktion.betreuungs_person.monatsblatt', {
        url: '/monatsblatt',
        component: 'dvbFraktionMonatsblatt',
        params: {
            periode: null,
            kindId: undefined,
        },
        resolve: {
            periode: ['$transition$', ($transition$: Transition): moment.Moment => $transition$.params().periode],
            kindId: ['$transition$', ($transition$: Transition): string | undefined => $transition$.params().kindId],
        },
        data: {
            permission: PERMISSION.KITA.CONTROL_ANY,
        },
    });

    $stateProvider.state('base.fraktion.betreuungs_person.notizen', {
        url: '/notizen',
        component: 'dvbNamedEntityNotizen',
        resolve: {
            entityType: () => NamedEntityType.BETREUUNGS_PERSON,
            entityId: ['$transition$', ($transition$: Transition): string => $transition$.params().id],
        },
    });

    $stateProvider.state('base.fraktion-erstellen', {
        url: '/fraktion/erstellen/?:kitaId',
        abstract: true,
        resolve: {
            wochenplaene: [
                'wochenplanService',
                (wochenplanService: WochenplanService): angular.IPromise<Wochenplan[]> =>
                    wochenplanService.getAllWochenplaeneDeep({cache: true}),
            ],
        },
        data: {
            permission: PERMISSION.KITA.ADMINISTRATE_ANY,
        },
    });
    $stateProvider.state('base.fraktion-erstellen.gruppe', {
        url: 'gruppe',
        template: '<dvb-fraktion-erstellen '
            + 'wochenplaene="$resolve.wochenplaene" '
            + 'dtype="GRUPPE">'
            + '</dvb-fraktion-erstellen>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): IPromise<string> =>
                    trans($translate, 'COMMON.GRUPPE.ERSTELLEN'),
            ],
        },
    });
    $stateProvider.state('base.fraktion-erstellen.betreuungs_person', {
        url: 'betreuungsperson',
        template: '<dvb-fraktion-erstellen '
            + 'wochenplaene="$resolve.wochenplaene" '
            + 'dtype="BETREUUNGS_PERSON">'
            + '</dvb-fraktion-erstellen>',
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): IPromise<string> =>
                    trans($translate, 'COMMON.BETREUUNGS_PERSON.ERSTELLEN'),
            ],
        },
    });

    $stateProvider.state('base.fraktion.gruppe.betreuungsschluessel', {
        url: '/betreuungsschluessel',
        component: 'dvbBetreuungsSchluesselAssignment',
        resolve: {
            fraktionBetreuungsSchluessel: [
                'fraktionBetreuungsSchluesselService', '$transition$',
                (
                    fraktionBetreuungsSchluesselService: FraktionBetreuungsSchluesselService,
                    $transition$: Transition,
                ): angular.IPromise<FraktionBetreuungsSchluessel[]> =>
                    fraktionBetreuungsSchluesselService.getAllByFraktion($transition$.params().id),
            ],
            betreuungsSchluessel: [
                'betreuungsSchluesselService', (
                    betreuungsSchluesselService: BetreuungsSchluesselService,
                ): angular.IPromise<BetreuungsSchluessel[]> =>
                    betreuungsSchluesselService.getAll(),
            ],
            controller: [
                'fraktionBetreuungsSchluesselService',
                'fraktionBetreuungsSchluessel',
                'betreuungsSchluessel',
                'fraktion',
                (
                    fraktionBetreuungsSchluesselService: FraktionBetreuungsSchluesselService,
                    fraktionBetreuungsSchluessel: FraktionBetreuungsSchluessel[],
                    betreuungsSchluessel: BetreuungsSchluessel[],
                    fraktion: Persisted<KinderOrtFraktion>,
                ): BetreuungsSchluesselFraktionAssignmentController =>
                    new BetreuungsSchluesselFraktionAssignmentController(
                        fraktion,
                        fraktionBetreuungsSchluessel,
                        betreuungsSchluessel,
                        fraktionBetreuungsSchluesselService),
            ],
        },
        data: {
            permission: PERMISSION.MODULE.PERSONALPLANUNG,
        },
    });
    /* Add New States Above */

}
