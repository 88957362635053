<div class="dvb-kita-eroeffnen">
    <div class="form-section-minus-normal-abstand">

        <h2 data-translate="KINDERORT.BEWILLIGTE_PLAETZE.KITA"></h2>

        <div class="row">
            <div class="col-md-6">
                <input type="number"
                       name="bewilligtePlaetze"
                       class="form-control"
                       ng-model="vm.bewilligtePlaetze.plaetze"
                       min="1"
                       ng-attr-placeholder="{{'KINDERORT.ANZAHL_BEWILLIGTE_PLAETZE' | translate}}"
                       uib-tooltip="{{'KINDERORT.ANZAHL_BEWILLIGTE_PLAETZE' | translate}}"
                       tooltip-placement="top-left"
                       required>
            </div>
        </div>
    </div>

    <div class="form-section-minus-normal-abstand">

        <h2 data-translate="KINDERORT.SUBVENTIONIERTE_KAPAZITAET.KITA.NAME"></h2>

        <div class="row form-row">
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               value="true"
                               ng-model="vm.showSubventioniertePlaetze"
                               ng-change="vm.updateSubventioniertePlaetze()">
                        <span data-translate="KINDERORT.SUBVENTIONIERTE_KAPAZITAET.KITA.NAME"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="row" uib-collapse="!vm.showSubventioniertePlaetze">
            <div class="col-md-6">
                <input type="number"
                       name="anzahlSubventioniertePlaetze"
                       class="form-control"
                       ng-model="vm.subventioniertePlaetze.anzahl"
                       min="1"
                       ng-attr-placeholder="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.KITA.ANZAHL' | translate}}"
                       uib-tooltip="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.KITA.ANZAHL' | translate}}"
                       tooltip-placement="top-left"
                       ng-required="vm.showSubventioniertePlaetze">
            </div>
        </div>
    </div>
</div>
