/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import {DvbDateUtil, KindergartenLocation, KindergartenType} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ParameterKinderOrte, ReportState} from '../../models';
import {ERR_VALIDATION, ParameterKindergartenkinder} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<',
    },
    template: require('./dvb-report-kindergartenkinder.html'),
    controllerAs: 'vm',
};

export class DvbReportKindergartenkinder implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public parameterKinderOrte?: ParameterKinderOrte;

    public readonly defaultStichtag: moment.Moment = DvbDateUtil.today();
    public readonly availableLocations: { [k in 'all' | KindergartenLocation]?: KindergartenLocation[] } = {};
    public readonly availableTypes: { [k in 'all' | KindergartenType]?: readonly KindergartenType[] } = {};
    public reportTemplate: ReportTemplate | null = null;
    public parameterKindergartenkinder: ParameterKindergartenkinder = new ParameterKindergartenkinder();

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (!this.parameterKinderOrte) {
            return;
        }

        const allKindergartenLocations = Object.values(KindergartenLocation);
        allKindergartenLocations.forEach((val: KindergartenLocation) => {
            this.availableLocations[val] = [val];
        });
        this.availableLocations.all = allKindergartenLocations;

        const allKindergartenTypes = Object.values(KindergartenType);
        allKindergartenTypes.forEach((val: KindergartenType) => {
            this.availableTypes[val] = [val];
        });
        this.availableTypes.all = allKindergartenTypes;

        angular.extend(this.parameterKindergartenkinder, this.parameterKinderOrte);
    }

    public onSubmit(form: angular.IFormController): angular.IPromise<TempBlob> {
        const copy = angular.copy(this.parameterKindergartenkinder);
        if (!copy.stichtag) {
            copy.stichtag = this.defaultStichtag;
        }

        this.errorService.clearAll();
        const isValid = form.$valid && copy.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.reportService.getTempBlobForKindergartenkinder(copy, {reportTemplate: this.reportTemplate});
    }

    public updateState(): void {
        this.reportService.updateStateParams(this.parameterKindergartenkinder);
    }
}

componentConfig.controller = DvbReportKindergartenkinder;
angular.module('kitAdmin').component('dvbReportKindergartenkinder', componentConfig);
