@if (hasNoContent()) {
    <p [translate]="'COMMON.GEBUEHR.DEPOT_NON'"></p>
}

@for (kitaDepots of gebuehrenByKinderOrtId(); track trackById($index, kitaDepots)) {
    <div>
        <h3>
            <a class="item-name"
               [uiSref]="'base.kinderort.faktura.overview'"
               [uiParams]="{id: kitaDepots.id}">
                {{ kinderOrteById[kitaDepots.id] | displayName }}
            </a>
        </h3>
        @for (depot of kitaDepots.value; track trackById($index, depot)) {
            <div class="small-abstand-left small-abstand-bottom">
                <p>
                    <strong>{{ depot.bezeichnung }}</strong>

                    @if (depot.rechnungsId) {
                        <span class="smaller-abstand-left smaller-abstand-right">-</span>
                        <a [uiSref]="'base.rechnung.revisionen'"
                           [uiParams]="{rechnungId: depot.rechnungsId, revisionId: depot.rechnungsRevisionId}"
                           [translate]="'COMMON.RECHNUNG_NR'"
                           [translateParams]="{nummer: depot.rechnungsNummer}">
                        </a>
                    }
                </p>

                <div class="btn-group" dropdown>
                    <button type="button"
                            class="btn btn-link btn-link-dropdown dropdown-toggle floating-text"
                            dropdownToggle>
                        <span>{{ (depot.betrag || undefined) | number: '1.2-2' }}</span>
                        &ngsp;
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" *dropdownMenu>
                        @if (depot.isDeletableGebuehr()) {
                            <li>
                                <button class="btn btn-link btn-link-dropdown"
                                        type="button"
                                        [translate]="'COMMON.LOESCHEN'"
                                        (click)="deleteGebuehr(depot)">
                                </button>
                            </li>
                        }
                        @if (depot.isEditableGebuehr()) {
                            <li>
                                <a [translate]="'COMMON.BEARBEITEN'"
                                   [uiSref]="kontaktpersonStates.GEBUEHR_STATE.name!"
                                   [uiParams]="{editMode: true, gebuehrId: depot.id, kitaName: kinderOrteById[kitaDepots.id] | displayName, rechnungId: depot.rechnungsId}">
                                </a>
                            </li>
                        }
                        @if (depot.isRueckerstattbareGebuehr()) {
                            <li>
                                <a [translate]="'COMMON.GEBUEHR.DEPOT_TO_VORAUSZAHLUNG'"
                                   [uiSref]="kontaktpersonStates.VORAUSZAHLUNG_STATE.name!"
                                   [uiParams]="{gebuehrId: depot.id, kitaName: kinderOrteById[kitaDepots.id] | displayName}">
                                </a>
                            </li>
                        }
                        @if (depot.isRueckerstattbareGebuehr()) {
                            <li>
                                <a [translate]="'COMMON.GEBUEHR.DEPOT_TO_RUECKERSTATTUNG'"
                                   [uiSref]="kontaktpersonStates.RUECKERSTATTUNG_STATE.name!"
                                   [uiParams]="{gebuehrId: depot.id, kitaName: kinderOrteById[kitaDepots.id] | displayName}">
                                </a>
                            </li>
                        }
                        @if (depot.isRueckerstattbareGebuehr()) {
                            <li>
                                <a [translate]="'COMMON.GEBUEHR.DEPOT_TO_RECHNUNG'"
                                   [uiSref]="kontaktpersonStates.DEPOT_RECHNUNG_ZUWEISEN_STATE.name!"
                                   [uiParams]="{depotId: depot.id}">
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        }
    </div>
}
