/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Belegung,
    Betreuungsfaktor,
    Bewerbung,
    ExtraPlatz,
    Kind,
    KinderOrtFraktionId,
    KinderOrtId,
    Kontingente,
    PlatzTypen,
} from '@dv/kitadmin/models';
import type {BetreuungsZeitraum, DayOfWeek, FunctionType} from '@dv/shared/code';
import {BelegungsZustand, checkPresent, DvbDateUtil, isNullish, KindergartenBelegung} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ZeitraumFilterController} from '../../../filter/zeitraum/ZeitraumFilterController';
import type {KindFilterModel} from '../../service/kinderFilter-models/KindFilterModel';
import type {KinderFilterService} from '../../service/kinderFilterService';

const directive: angular.IDirective = {
    restrict: 'E',
    replace: true,
    scope: {
        kind: '<',
        kitaId: '<',
        wochenplanBewerbung: '<',
        firstOfWeek: '<',
        lastOfWeek: '<',
        weekDays: '<',
        selectedKindId: '<?',
        gruppenIds: '<',
        kontingente: '<',
        betreuungsfaktor: '<',
        isBetreuungsfaktorLoading: '<',
        /**
         * @param {string} 'bewerbung'|'belegung'
         */
        listModel: '@',
        selectedKindChanged: '&',
        onKindZuweisen: '&',
        onDateSelected: '&',
    },
    template: require('./dvb-kinder-liste.html'),
    controllerAs: 'vm',
    bindToController: true,
};

export class DvbKinderListe {

    public static $inject: readonly string[] = ['kinderFilterService'];

    public kind!: Kind & { filterModel: KindFilterModel };
    public kitaId!: KinderOrtId;
    public wochenplanBewerbung!: Bewerbung;
    public firstOfWeek!: moment.Moment;
    public lastOfWeek!: moment.Moment;
    public weekDays!: DayOfWeek[];
    public selectedKindId: string | null = null;
    public gruppenIds: KinderOrtFraktionId[] = [];
    public kontingente: Kontingente[] = [];
    public betreuungsfaktor: Betreuungsfaktor | null = null;
    public isBetreuungsfaktorLoading!: boolean;

    public listModel?: 'bewerbung' | 'belegung';
    public selectedKindChanged?: FunctionType;
    public onKindZuweisen?: FunctionType;
    public onDateSelected?: FunctionType;

    public belegung: Belegung | null = null;
    public extraPlaetze: ExtraPlatz[] = [];
    public zuweisenTranslationKey: string | null = null;
    public kindergartenBelegung: KindergartenBelegung = KindergartenBelegung.KEINE;
    public belegungInitialised: boolean = false;
    public status: BelegungsZustand | 'WARTELISTE' | null = null;
    public platzTypen: PlatzTypen | null = null;

    public hideBelegungDate: boolean = true;

    private selectedDays: DayOfWeek[] = [];
    private bewerbungsstatus: BelegungsZustand | null = null;
    private belegungsstatus: BelegungsZustand | 'WARTELISTE' | null = null;
    private readonly zeitraumCtrl: ZeitraumFilterController<BetreuungsZeitraum>;

    public constructor(kinderFilterService: KinderFilterService) {
        this.zeitraumCtrl = kinderFilterService.filterControllers.wochenplanZeitraeumeCtrl;
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.firstOfWeek && !onChangesObj.firstOfWeek.isFirstChange()) {
            this.onChangeWeek();
        }
        if (this.betreuungsfaktor) {
            this.betreuungsfaktor.setSpezifisch(this.belegung);
        }
    }

    public $onInit(): void {
        this.initBelegung();

        if (this.listModel === 'bewerbung') {
            this.platzTypen = checkPresent(this.kind.bewerbung).platzTypen(this.kontingente);
            this.kindergartenBelegung = this.platzTypen.kindergarten;
            this.weekDays.forEach(dayOfWeek => {
                if (this.kind.bewerbung!.isSelectedTag(dayOfWeek)) {
                    this.selectedDays.push(dayOfWeek);
                }
            });
        }

        this.initStatus();
        this.zuweisenTranslationKey = this.belegung ? 'COMMON.ZUWEISUNG_ANPASSEN' : 'COMMON.KIND_ZUWEISEN';
    }

    public isInitialised(): boolean {
        return this.belegung ? this.belegungInitialised : true;
    }

    public onChangeWeek(): void {
        this.hideBelegungDate = true;
        if (!this.belegung || !DvbDateUtil.isGueltigOn(this.belegung, this.firstOfWeek)) {
            // init belegung
            this.initBelegung();
            this.initStatus();
        }
    }

    public showKindergarten(): boolean {
        return this.kindergartenBelegung && this.kindergartenBelegung !== KindergartenBelegung.KEINE;
    }

    public getDayMoment(dayOfWeek: DayOfWeek): moment.Moment {
        return DvbDateUtil.getDayOfWeekMoment(dayOfWeek, this.firstOfWeek);
    }

    public isSelectedTag(dayOfWeek: DayOfWeek): boolean {
        return this.selectedDays.includes(dayOfWeek);
    }

    public isFilteredTag(dayOfWeek: DayOfWeek): boolean {
        return this.zeitraumCtrl.isFilteredTag(this.kitaId, dayOfWeek);
    }

    private initBelegung(): void {
        const currentBelegung = DvbDateUtil.getEntityOn(this.kind.kindWochenBelegungen, this.firstOfWeek);
        if (currentBelegung) {
            this.initWithBelegung(currentBelegung);
            this.hideBelegungDate = true;

            return;
        }

        if (this.listModel === 'bewerbung') {
            const futureBelegung = this.getFutureBelegung();
            this.initWithBelegung(futureBelegung);
            this.hideBelegungDate = isNullish(futureBelegung);
        }
    }

    private getFutureBelegung(): Belegung | null {
        const futureBelegungen = this.kind.belegungen
            .filter(belegung => checkPresent(belegung.gueltigBis).isSameOrAfter(this.firstOfWeek));

        return futureBelegungen.length > 0 ?
            DvbDateUtil.sortLimitedEntitiesByGueltigAbAsc(futureBelegungen)[0] :
            null;
    }

    private initWithBelegung(belegung: Belegung | null): void {
        this.belegung = belegung;
        this.belegungsstatus = this.belegung?.belegungsZustand ?? null;
        this.extraPlaetze = this.kind.extraPlaetze
            .filter(p => p.affectedDay!.isBetween(this.firstOfWeek, this.lastOfWeek, 'day', '[]'));
        if (!this.belegung || this.listModel !== 'belegung') {
            return;
        }

        if (this.belegungsstatus === BelegungsZustand.BELEGT) {
            /*
             Bei einem definitiv zugewiesenen Kind, welches eine offene Bewerbung hat, wird das Warteliste
             Icon angezeigt. Alle anderen Belegungszustaende (Angebot/provisorisch) implizieren, dass das
             Kind noch auf der Warteliste ist.
             */
            this.belegungsstatus = this.kind.bewerbung ? 'WARTELISTE' : null;
        }
        this.platzTypen = this.belegung.platzTypen(this.kontingente, this.gruppenIds);
        this.kindergartenBelegung = this.platzTypen.kindergarten;
        this.weekDays.forEach(dayOfWeek => {
            if (this.belegung?.isSelectedTag(dayOfWeek, this.gruppenIds)) {
                this.selectedDays.push(dayOfWeek);
            }
        });
    }

    private initStatus(): void {
        this.bewerbungsstatus = this.getBewerbungsStatus();

        switch (this.listModel) {
            case 'bewerbung':
                this.status = this.bewerbungsstatus ?? this.belegungsstatus;

                return;
            case 'belegung':
                this.status = this.belegungsstatus;

                return;
            default:
                this.status = null;
        }
    }

    private getBewerbungsStatus(): BelegungsZustand | null {
        if (this.kind.filterModel.angebot) {
            return BelegungsZustand.ANGEBOT_ERSTELLT;
        }

        if (this.kind.filterModel.provisorisch) {
            return BelegungsZustand.PROVISORISCH;
        }

        if (this.kind.filterModel.belegt) {
            return BelegungsZustand.BELEGT;
        }

        return null;
    }
}

directive.controller = DvbKinderListe;
angular.module('kitAdmin').directive('dvbKinderListe', () => directive);
