/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import {RechnungsStatus} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {Moment} from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
    },
    template: require('./dvb-rechnung-heading-payment-due-icon.html'),
    controllerAs: 'vm',
};

export class DvbRechnungHeadingPaymentDueIcon implements angular.IController {
    public static $inject: readonly string[] = [];

    public today: Moment = DvbDateUtil.today();
    public rechnung!: Rechnung;

    public showFrist(): boolean {
        if (!this.rechnung.isNewestRevision) {
            return false;
        }

        const notBezahltOrStorniert =
            this.rechnung.externalStatus !== RechnungsStatus.EXTERNAL_STATUS.BEZAHLT &&
            this.rechnung.externalStatus !== RechnungsStatus.EXTERNAL_STATUS.STORNIERT;

        const zahlungsFristAbgelaufen = this.rechnung.zahlungsFrist!.isBefore(this.today);

        return notBezahltOrStorniert && zahlungsFristAbgelaufen;
    }
}

componentConfig.controller = DvbRechnungHeadingPaymentDueIcon;
angular.module('kitAdmin').component('dvbRechnungHeadingPaymentDueIcon', componentConfig);
