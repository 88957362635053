/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AnwesenheitCustomField,
    ExternalAnmeldung,
    KinderOrt,
    KinderOrtFraktion,
    Wochenplan,
} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import {DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {IInjectable, Transition} from '@uirouter/core';
import type angular from 'angular';
import type moment from 'moment';
import {trans} from '../../common/i18n/trans';
import type {KinderOrtService} from '../../common/service/rest/kinderort/kinderOrtService';
import type {WochenplanService} from '../../common/service/rest/wochenplanService';
import type {ExternalAnmeldungService} from '../../external/service/externalAnmeldungService';
import type {KibonExchangeTagesschuleAnmeldung} from '../../kibon/models/KibonExchangeTagesschuleAnmeldung';
import type {TagesschuleAnmeldungService} from '../../kibon/service/tagesschuleAnmeldungService';
import type {AnwesenheitCustomFieldService} from '../service/anwesenheitCustomFieldService';

export * from './ZuweisungAbController';

/* eslint-disable sonarjs/no-duplicate-string */
export const ZUWEISEN_STATE = {
    name: 'base.kind.zuweisen',
    url: '/zuweisen?:zuweisungAb:direktzuweisung',
    abstract: true,
    resolve: {
        direktzuweisung: [
            '$transition$', ($transition$: Transition): boolean =>
                !!$transition$.params().direktzuweisung,
        ],
        zuweisungAb: [
            '$transition$', ($transition$: Transition): moment.Moment | null =>
                $transition$.params().zuweisungAb
                && DvbRestUtil.localDateToMoment($transition$.params().zuweisungAb),
        ],
    },
    params: {
        zuweisungAb: {
            dynamic: true,
        },
    },
} satisfies Ng1StateDeclaration;

export const ZUWEISEN_SINGLE_STATE = {
    name: `${ZUWEISEN_STATE.name}.single`,
    abstract: true,
    url: '?:kinderOrtId',
    views: {
        'header@base.kind': {
            template: '<dvb-zuweisen-header ' +
                'kind="$resolve.kind" ' +
                'zuweisung-ab="zuweisungAb" ' +
                'kinder-orte="[$resolve.kinderOrt]"></dvb-zuweisen-header>',
            controller: 'ZuweisungAbController',
        },
        '': {
            template: '<ui-view></ui-view>',
        },
    },
    params: {
        kinderOrtId: '',
    },
    data: {
        permission(toParams: { kinderOrtId: string }): string {
            return PERMISSION.KITA.MANAGE + toParams.kinderOrtId;
        },
    },
    resolve: {
        kinderOrt: [
            'kinderOrtService', '$transition$', '$q', (
                kinderOrtService: KinderOrtService,
                $transition$: Transition,
                $q: angular.IQService,
            ): angular.IPromise<KinderOrt> => {
                if (!$transition$.params().kinderOrtId) {
                    return $q.reject();
                }

                return kinderOrtService.get($transition$.params().kinderOrtId, {
                    includes: kinderOrtService.getIncludesDeep(),
                    cache: true,
                });
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const ZUWEISEN_MULTIPLE_STATE = {
    name: `${ZUWEISEN_STATE.name}.multiple`,
    abstract: true,
    url: '?:kinderOrtIds',
    views: {
        'header@base.kind': {
            template: '<dvb-zuweisen-header ' +
                'kind="$resolve.kind" ' +
                'zuweisung-ab="zuweisungAb" ' +
                'kinder-orte="$resolve.kinderOrte"></dvb-zuweisen-header>',
            controller: 'ZuweisungAbController',
        },
        '': {
            template: '<ui-view></ui-view>',
        },
    },
    params: {
        kinderOrtIds: {
            array: true,
            value: [],
        },
    },
    data: {
        permission(toParams: { kinderOrtIds: string[] }): string | string[] {
            return toParams.kinderOrtIds ?
                toParams.kinderOrtIds.map(id => `${PERMISSION.KITA.MANAGE}${id}`) :
                PERMISSION.KITA.MANAGE_ANY;
        },
    },
    resolve: {
        kinderOrte: [
            'kinderOrtService', '$transition$', '$q', (
                kinderOrtService: KinderOrtService,
                $transition$: Transition,
                $q: angular.IQService,
            ): angular.IPromise<KinderOrt[] | null> => {
                const idList: string[] = $transition$.params().kinderOrtIds;
                if (!idList || idList.length === 0) {
                    return $q.reject();
                }

                return kinderOrtService.getMultiple({
                    idList,
                    includes: kinderOrtService.getIncludesDeep(),
                    cache: true,
                });
            },
        ],
    },
} satisfies Ng1StateDeclaration;

function tagesschulAnmeldungResolve(mandatory: boolean): IInjectable {
    return [
        'tagesschuleAnmeldungService', '$transition$', '$q', (
            tagesschuleAnmeldungService: TagesschuleAnmeldungService,
            $transition$: Transition,
            $q: angular.IQService,
        ): angular.IPromise<KibonExchangeTagesschuleAnmeldung | null> => {
            const anmeldung = $transition$.params().tagesschulAnmeldung;
            if (anmeldung) {
                return anmeldung;
            }

            const anmeldungId = $transition$.params().tagesschulAnmeldungId;
            if (anmeldungId) {
                return tagesschuleAnmeldungService.get(anmeldungId);
            }

            return mandatory ? $q.reject() : $q.resolve(null);
        },
    ];
}

export const ZUWEISEN_MULTIPLE_SIMPLE_STATE = {
    name: `${ZUWEISEN_MULTIPLE_STATE.name}.simple`,
    url: '?:tagesschulAnmeldungId',
    template: '<dvb-kind-zuweisen ' +
        'kind="$resolve.kind" ' +
        'kinder-orte="$resolve.kinderOrte" ' +
        'zuweisung-ab="$resolve.zuweisungAb" ' +
        'direktzuweisung="$resolve.direktzuweisung" ' +
        'tagesschul-anmeldung="$resolve.tagesschulAnmeldung"></dvb-kind-zuweisen>',
    params: {
        tagesschulAnmeldung: null,
    },
    resolve: {
        tagesschulAnmeldung: tagesschulAnmeldungResolve(false),
    },
} satisfies Ng1StateDeclaration;

export const MONAT_ZUWEISEN_STATE = {
    name: `${ZUWEISEN_SINGLE_STATE.name}.monat`,
    url: '/monat?:gruppeId',
    template: '<dvb-kind-monat-zuweisen ' +
        'kind="$resolve.kind" ' +
        'kita="$resolve.kinderOrt" ' +
        'gruppe="$resolve.gruppe" ' +
        'zuweisung-ab="$resolve.zuweisungAb" ' +
        'custom-fields="$resolve.anwesenheitcustomFields"></dvb-kind-monat-zuweisen>',
    resolve: {
        gruppe: [
            'kinderOrt', '$transition$', '$q', (kinderOrt: KinderOrt, $transition$: Transition, $q: angular.IQService)
                : angular.IPromise<KinderOrtFraktion | null> => {

                if (!$transition$.params().gruppeId) {
                    return $q.resolve(null);
                }

                return $q.resolve(DvbUtil.findFirst(kinderOrt.gruppen,
                    gruppe => !!gruppe && gruppe.id === $transition$.params().gruppeId));
            },
        ],
        anwesenheitcustomFields: [
            'anwesenheitCustomFieldService', (anwesenheitCustomFieldService: AnwesenheitCustomFieldService)
                : angular.IPromise<AnwesenheitCustomField[]> =>
                anwesenheitCustomFieldService.getAll({cache: true}),
        ],
    },
    data: {
        permission: PERMISSION.MODULE.ANWESENHEIT,
    },
} satisfies Ng1StateDeclaration;

export const TAGESSCHULE_ANMELDUNG = {
    name: 'base.anmeldung-tagesschule',
    url: '/anmeldung-tagesschule/{tagesschulAnmeldungId:[0-9]+}',
    component: 'dvbAnmeldungTagesschule',
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'KIBON.TAGESSCHULE_ANMELDUNG.HEADER'),
        ],
        tagesschulAnmeldung: tagesschulAnmeldungResolve(true),
    },
} satisfies Ng1StateDeclaration;

export const BEWERBUNG_STATE = {
    name: 'base.kind.neue-bewerbung',
    url: '/neue-bewerbung?:externalAnmeldungId',
    component: 'dvbKindNeueBewerbung',
    resolve: {
        wochenplan: [
            'wochenplanService', 'externalAnmeldungService', '$transition$',
            (
                wochenplanService: WochenplanService,
                externalAnmeldungService: ExternalAnmeldungService,
                $transition$: Transition,
            ): angular.IPromise<Wochenplan | null> => {
                if ($transition$.params().externalAnmeldung) {
                    return externalAnmeldungService.getWochenplan($transition$.params().externalAnmeldung.id);
                }
                if ($transition$.params().externalAnmeldungId) {
                    return externalAnmeldungService.getWochenplan($transition$.params().externalAnmeldungId);
                }

                return wochenplanService.getWochenplanAnmeldungDeep({cache: true});
            },
        ],
        externalAnmeldung: externalAnmeldungResolve(false),
    },
    params: {
        externalAnmeldung: null,
    },
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const EXTERNAL_ANMELDUNG_UEBERNEHMEN_STATE = {
    name: 'base.external-anmeldung-uebernehmen',
    url: '/external-anmeldung/{externalAnmeldungId:[0-9]+}',
    component: 'dvbExternalAnmeldungUebernahme',
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'EXTERNAL_ANMELDUNG.DETAIL.TITLE'),
        ],
        externalAnmeldung: externalAnmeldungResolve(true),
    },
} satisfies Ng1StateDeclaration;

function externalAnmeldungResolve(mandatory: boolean): IInjectable {
    return [
        'externalAnmeldungService', '$transition$', '$q', (
            externalAnmeldungService: ExternalAnmeldungService,
            $transition$: Transition,
            $q: angular.IQService,
        ): angular.IPromise<ExternalAnmeldung | null> => {
            const anmeldung = $transition$.params().externalAnmeldung;
            if (anmeldung) {
                return anmeldung;
            }

            const anmeldungId = $transition$.params().externalAnmeldungId;
            if (anmeldungId) {
                return externalAnmeldungService.get(anmeldungId, {includes: '(platzarten, kind.fields(bewerbungen))'});
            }

            return mandatory ? $q.reject() : $q.resolve(null);
        },
    ];
}

export const KIND_ZUWEISEN_STATES = {
    ZUWEISEN_STATE,
    ZUWEISEN_SINGLE_STATE,
    ZUWEISEN_MULTIPLE_STATE,
    ZUWEISEN_MULTIPLE_SIMPLE_STATE,
    MONAT_ZUWEISEN_STATE,
    TAGESSCHULE_ANMELDUNG,
    BEWERBUNG_STATE,
    EXTERNAL_ANMELDUNG_UEBERNEHMEN_STATE,
};
