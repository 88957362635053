/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BankStatementEntry, BankStatementImport, UploadTempBlob} from '@dv/kitadmin/models';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import Comparators from 'comparators';
import moment from 'moment';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import type {BankStatementService} from '../../../service/bankStatementService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-transaktionen-import.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionenImport implements angular.IController {

    public static $inject: readonly string[] = ['bankStatementService', 'errorService', 'dvbStateService'];

    public isLoading: boolean = false;
    public isDuplicateImportLoading: boolean = false;
    public isModeUpload: boolean = true;
    public files: UploadTempBlob[] = [];
    public bankStatementImports: BankStatementImport[] = [];
    public duplicates: BankStatementEntry[] = [];
    public selectedDuplicateIndices: { [index: string]: boolean } = {};
    public gueltigAbMin: moment.Moment | null = null;
    public gueltigBisMax: moment.Moment | null = null;
    public successfulAssignedImports: number = 0;
    public notAssignedImports: number = 0;
    public ignoredImports: number = 0;
    public totalEntries: number = 0;
    public totalBetrag: number = 0;

    public constructor(
        private readonly bankStatementService: BankStatementService,
        private readonly errorService: ErrorService,
        private readonly dvbStateService: DvbStateService,
    ) {
    }

    public getBankStatementIds(): string[] {
        return this.bankStatementImports.map(bankStatementimport =>
            checkPresent(bankStatementimport.bankStatementId));
    }

    public onSubmit(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const uploadValid = !this.files.some(a => a.errorMessage === 'ERRORS.ERR_UPLOAD_FAILED');
        this.errorService.handleValidationError(uploadValid, 'ERRORS.ERR_UPLOAD_FAILED_MULTI_FILE');

        if (!form.$valid || !uploadValid) {
            return;
        }

        this.isLoading = true;

        const fileIds = this.files.map(file => checkPresent(file.id));

        this.bankStatementService.importKontoauszug(fileIds).then(bankStatementImports => {
            this.isModeUpload = false;

            bankStatementImports.forEach(bankStatementImport => {

                this.bankStatementImports.push(bankStatementImport);

                bankStatementImport.duplicates.forEach(entry => {
                    this.duplicates.push(entry);
                });
            });

            this.calculateAggregates();

            this.duplicates.sort(Comparators.comparing(a => a.localDateTime!.valueOf()));
        }).catch(() => {
            // nop
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public importSelectedDuplicates(): void {
        const indices = Object.keys(this.selectedDuplicateIndices)
            .filter(key => this.selectedDuplicateIndices[key])
            .map(i => parseInt(i, 10));

        if (indices.length === 0) {
            return;
        }

        const entries = indices.map(i => this.duplicates[i]);

        this.isDuplicateImportLoading = true;

        this.bankStatementService.importDuplicates(entries).then(() => {

            this.updateBankStatementImport(entries);

            this.calculateAggregates();

            // reverse so we don't cut off the tail
            indices.sort((a, b) => b - a);
            // remove duplicates from list that we just imported
            indices.forEach(i => this.duplicates.splice(i, 1));

            // clear selection
            this.selectedDuplicateIndices = {};

        }).finally(() => {
            this.isDuplicateImportLoading = false;
        });
    }

    public goBack(): angular.IPromise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    /**
     * Update import stats with new data from entries.
     */
    private updateBankStatementImport(entries: BankStatementEntry[]): void {

        entries.forEach(entry => {
            const bankStatementImport = DvbUtil.findFirst(this.bankStatementImports,
                i => i.bankStatementId === entry.bankStatementId)!;

            // If the bank statement was previously empty, we can use the min date of the just imported entries.
            // If it was not empty, we use the minimum date of the just imported entries and the existing gueltigAb.
            const bankStatementHadNoEntries = bankStatementImport.totalEntries === 0;

            // Note: The same thing is computed in the backend and thus does not need to be sent.
            bankStatementImport.gueltigAb = bankStatementHadNoEntries ?
                entry.localDateTime :
                moment.min(bankStatementImport.gueltigAb!, entry.localDateTime!);
            bankStatementImport.gueltigBis = bankStatementHadNoEntries ?
                entry.localDateTime :
                moment.max(bankStatementImport.gueltigBis!, entry.localDateTime!);

            bankStatementImport.notAssignedImports! += 1;
            bankStatementImport.totalEntries! += 1;
            bankStatementImport.totalBetrag! += entry.betrag!;

            // Remove duplicate from bankStatementImport
            const duplicateIndex = bankStatementImport.duplicates.indexOf(entry);
            bankStatementImport.duplicates.splice(duplicateIndex, 1);
        });
    }

    /**
     * Update aggregate stats with data from updated bankStatementImports.
     */
    private calculateAggregates(): void {
        this.bankStatementImports.forEach(bankStatementImport => {
            this.gueltigAbMin = this.gueltigAbMin === null ?
                bankStatementImport.gueltigAb :
                moment.min(bankStatementImport.gueltigAb!, this.gueltigAbMin);

            this.gueltigBisMax = this.gueltigBisMax === null ?
                bankStatementImport.gueltigBis :
                moment.max(bankStatementImport.gueltigBis!, this.gueltigBisMax);

            this.successfulAssignedImports += bankStatementImport.successfulAssignedImports!;
            this.notAssignedImports += bankStatementImport.notAssignedImports!;
            this.ignoredImports += bankStatementImport.ignoredImports!;
            this.totalEntries += bankStatementImport.totalEntries!;
            this.totalBetrag += bankStatementImport.totalBetrag!;
        });
    }
}

componentConfig.controller = DvbTransaktionenImport;
angular.module('kitAdmin').component('dvbTransaktionenImport', componentConfig);
