/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {AnwesenheitCustomField, CheckInCustomField} from '@dv/kitadmin/models';
import type {DialogService, SortListDialogModel} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import type {CheckInCustomFieldService} from '@dv/shared/backend/api/check-in-custom-field.service';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {CustomFieldType, ICustomField, Persisted} from '@dv/shared/code';
import {checkPersisted, CUSTOM_FIELD_TYPE, DvbUtil, sortCustomFields} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {BehaviorSubject, finalize, from, map, switchMap, tap} from 'rxjs';
import type {AnwesenheitCustomFieldService} from '../../../kind/service/anwesenheitCustomFieldService';
import {CustomFieldLinkedComponent} from '../custom-field-linked/custom-field-linked.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-voreinstellungen-anwesenheit-custom-fields.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenAnwesenheitCustomFields implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'errorService',
        'anwesenheitCustomFieldService',
        'checkInCustomFieldService',
        'dialogService',
        '$q',
        'authStore',
    ];

    public customFields: AnwesenheitCustomField[] = [];
    public isLoading: boolean = false;

    public readonly fieldTypes: CustomFieldType[] = CUSTOM_FIELD_TYPE.values;
    public showSortDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public sortState?: SortListDialogModel<AnwesenheitCustomField>;
    public showSortLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private endOfList: number = 0;
    private checkInCustomFields: Persisted<CheckInCustomField>[] = [];
    private unassignedCheckInCustomFields: Persisted<CheckInCustomField>[] = [];

    public constructor(
        private errorService: ErrorService,
        private anwesenheitCustomFieldService: AnwesenheitCustomFieldService,
        private checkInCustomFieldService: CheckInCustomFieldService,
        private dialogService: DialogService,
        private $q: angular.IQService,
        private readonly authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        if (this.authStore.hasPermission('module:group_admin')) {
            this.loadCheckInCustomFields$().subscribe();
        }
        this.loadCustomFields();
    }

    public onAddField(): void {
        this.customFields.push(new AnwesenheitCustomField());
    }

    public onDelete(field: AnwesenheitCustomField): void {
        DvbUtil.removeFromArray(field, this.customFields);
    }

    public onSubmit(form: angular.IFormController): angular.IPromise<any> {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return this.$q.reject();
        }

        const uniqueNames = DvbUtil.uniqueArray(this.customFields, customField => customField.name);
        const isNamesUnique = uniqueNames.length === this.customFields.length;
        this.errorService.handleValidationError(isNamesUnique, 'ERRORS.ERR_DUPLICATE_NAME');

        if (!isNamesUnique) {
            return this.$q.reject();
        }

        this.isLoading = true;

        return this.anwesenheitCustomFieldService.update(this.customFields)
            .then(() => this.loadCustomFields())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public addLinkedField(customField?: AnwesenheitCustomField): void {
        const customFields: Persisted<ICustomField>[] = this.unassignedCheckInCustomFields
            .filter(unassigned => !customField || unassigned.fieldType === customField.fieldType);

        this.dialogService.openDialog(CustomFieldLinkedComponent, {
            customFields,
            confirm: (customFieldId: EntityId) => from(
                this.anwesenheitCustomFieldService.createLinkedField(customFieldId, customField?.id ?? null),
            ).pipe(
                switchMap(() => this.loadCustomFields()),
            ),
        });
    }

    public sortFields(form: angular.IFormController): void {
        this.sortState = this.sortFieldState(form);
        this.showSortDialog$.next(true);
    }

    public confirmSort = (customFields: AnwesenheitCustomField[], form: angular.IFormController):
        Observable<unknown> => {
        customFields.forEach((value, index) => {
            value.orderValue = index;
        });

        this.showSortLoading$.next(true);

        return from(this.onSubmit(form)).pipe(
            tap(() => this.showSortDialog$.next(false)),
            finalize(() => this.showSortLoading$.next(false)),
        );
    };

    private loadCustomFields(): angular.IPromise<any> {
        this.customFields = [];

        return this.anwesenheitCustomFieldService.getAll().then(customFields => {
            const orderValues = customFields.map(customField => customField.orderValue ?? 0);
            this.endOfList = orderValues.length ? Math.max(...orderValues) + 1 : 0;
            this.customFields = customFields.sort(sortCustomFields);
            this.filterCheckInCustomFields();
        }).finally(() => {
            this.isLoading = false;
        });
    }

    private sortFieldState(form: angular.IFormController): SortListDialogModel<AnwesenheitCustomField> {
        return {
            dialogTitleKey: 'CUSTOM_FIELD.SORTIEREN',
            items: this.customFields,
            confirm: values => this.confirmSort(values, form).pipe(tap(() => this.errorService.clearAll())).subscribe(),
            cancel: () => {
                this.showSortDialog$.next(false);
                this.errorService.clearAll();
            },
            open$: this.showSortDialog$,
            isLoading$: this.showSortLoading$,
        };
    }

    private loadCheckInCustomFields$(): Observable<unknown> {
        return this.checkInCustomFieldService.getCustomFields$().pipe(
            map(data => data.checkInCustomFields.map(CheckInCustomField.apiResponseTransformer)),
            map(fields => fields.sort(sortCustomFields)),
            tap(customFields => {
                this.checkInCustomFields = customFields.map(checkPersisted);
                this.filterCheckInCustomFields();
            }));
    }

    private filterCheckInCustomFields(): void {
        this.unassignedCheckInCustomFields = this.checkInCustomFields
            .filter(f => f.valuePerDay && !this.isLinkedWithAnwesenheitCustomField(f));
    }

    private isLinkedWithAnwesenheitCustomField(checkInField: Persisted<CheckInCustomField>): boolean {
        return this.customFields.some(field => field.checkInCustomField?.id === checkInField.id);
    }
}

componentConfig.controller = DvbVoreinstellungenAnwesenheitCustomFields;
angular.module('kitAdmin').component('dvbVoreinstellungenAnwesenheitCustomFields', componentConfig);
