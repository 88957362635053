<div class="dvb-rechnung-zahlungen">
    <div class="row">
        <div class="col-md-7 big-abstand-bottom" dvb-accordions>
            <h2 data-translate="FAKTURA.ERFASSTE_ZAHLUNGEN"></h2>
            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading && vm.zahlungen.length === 0"></dvlib-spinner>

            <div ng-if="!vm.isLoading && (vm.zahlungen.length === 0)">
                <p data-translate="FAKTURA.KEINE_ZAHLUNGEN_VORHANDEN"></p>
            </div>

            <dvb-accordion is-first="$first"
                           data-testid="zahlungAccordion"
                           ng-repeat="zahlung in vm.zahlungen | orderBy:['-datum', 'type', 'betrag']">
                <panel-header class="full-width-block">
                    <dvb-zahlung-heading zahlung="zahlung"></dvb-zahlung-heading>
                </panel-header>
                <panel-content>
                    <p ng-bind="zahlung.bemerkung || ('FAKTURA.KEINE_BEMERKUNG' | translate)"
                       class="nl2br">
                    </p>
                </panel-content>
                <panel-footer class="full-width-block">
                    <ul class="list-inline list-inline-row">
                        <li>
                            <button ng-if="!zahlung.ausgleichsVorauszahlung && !zahlung.ausgleichsRueckerstattung"
                                    type="button"
                                    class="btn btn-link"
                                    ng-click="vm.storniere(zahlung)"
                                    data-translate="FAKTURA.STORNIEREN">
                            </button>

                            <a ui-sref="base.kontaktperson.offenePosten({
                                 kontaktpersonId: vm.rechnung.kontaktpersonId,
                                 kontaktpersonZahlungId: zahlung.ausgleichsVorauszahlung.id,
                                 showAll: true
                               })"
                               ng-if="zahlung.ausgleichsVorauszahlung"
                               data-translate="FAKTURA.AUSGLEICH_VORAUSZAHLUNG_ANSEHEN">
                            </a>
                            <a ui-sref="base.kontaktperson.offenePosten({
                                 kontaktpersonId: vm.rechnung.kontaktpersonId,
                                 kontaktpersonZahlungId: zahlung.ausgleichsRueckerstattung.id,
                                 showAll: true
                               })"
                               ng-if="zahlung.ausgleichsRueckerstattung"
                               data-translate="FAKTURA.AUSGLEICH_RUECKERSTATTUNG_ANSEHEN">
                            </a>
                        </li>
                    </ul>
                </panel-footer>
            </dvb-accordion>
        </div>

        <div class="col-md-3 col-md-offset-1">
            <dvb-rechnung-restbetrag is-loading="vm.isLoading"
                                     rechnung="vm.rechnung"
                                     zahlungen="vm.zahlungen"
                                     ausstehender-betrag="vm.ausstehenderBetrag">
            </dvb-rechnung-restbetrag>
        </div>
    </div>
</div>
