/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
// @ts-expect-error doesn't find types of deep import
import collapse from 'angular-ui-bootstrap/src/collapse';
import {dvbAccordionComponentConfig} from './dvb-accordion';
import {dvbAccordionsDirective} from './dvb-accordions';

export const angularJsAccordionModule = angular.module('kitAdmin.ui.accordion', [collapse])
    .component('dvbAccordion', dvbAccordionComponentConfig)
    .directive('dvbAccordions', () => dvbAccordionsDirective)
;
