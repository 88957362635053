/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import {
    ExternalAnmeldung,
    ExternalKontaktperson,
    KindKontakteUtil,
    Kontaktperson,
    RelationshipWithKontaktperson,
} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted, SearchResultEntry} from '@dv/shared/code';
import {HttpCodes, isNullish} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from} from 'rxjs';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {ExternalAnmeldungService} from '../../../external/service/externalAnmeldungService';
import {AbstractAnmeldungUebernahme} from '../../models/AbstractAnmeldungUebernahme';
import type {KindValidationService} from '../../service/kindValidationService';
import {BEWERBUNG_STATE} from '../../zuweisung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        externalAnmeldung: '<',
    },
    template: require('./dvb-external-anmeldung-uebernahme.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungUebernahme extends AbstractAnmeldungUebernahme<ExternalAnmeldung>
    implements angular.IController {

    public static override $inject: readonly string[] = [
        '$state',
        'kindService',
        'errorService',
        'kindValidationService',
        'dialogService',
        'externalAnmeldungService',
    ];

    public externalAnmeldung!: Persisted<ExternalAnmeldung>;

    public constructor(
        private readonly $state: StateService,
        kindService: KindService,
        errorService: ErrorService,
        kindValidationService: KindValidationService,
        dialogService: DialogService,
        private readonly externalAnmeldungService: ExternalAnmeldungService,
    ) {
        super(externalAnmeldungService, kindService, kindValidationService, errorService, dialogService);
    }

    public onKindCreated(kind: Kind): void {
        this.kind = kind;
        ExternalAnmeldung.initializeKind(kind, this.externalAnmeldung);

        this.externalAnmeldung.externalKontaktpersonen?.forEach(anmeldungKontakt => {
            const kontakt = this.createKontaktWithRelationship(
                anmeldungKontakt,
                this.selectedHousehold?.matchingGesuchsteller[anmeldungKontakt.id!]);
            kind.addKontakt(kontakt);

            if (anmeldungKontakt.hauptkontakt) {
                KindKontakteUtil.recalculateProzent(kind.kontakte, kontakt);
            }
        });

        if (this.selectedHousehold) {
            this.uneditableAdresse = true;

            [...this.selectedHousehold.kontakteAtAdresse, ...this.selectedHousehold.kontakteAtOtherAdresse]
                .forEach(k => {
                    kind.addKontakt(this.createSonstigerKontaktRelationship(k));
                });

            this.selectedHousehold.relatedKinder.forEach(k => {
                kind.addGeschwister(k);
            });
        }
    }

    public unAssign(): void {
        if (isNullish(this.externalAnmeldung.assignedKindId)) {
            return;
        }
        this.isLoading = true;
        this.externalAnmeldungService.unAssign(this.externalAnmeldung.id)
            .then(() => this.$state.reload())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goToNeueBewerbung(kindId: string): angular.IPromise<unknown> {
        const anmeldung = this.externalAnmeldung;

        return this.$state.go(BEWERBUNG_STATE.name, {
            externalAnmeldungId: anmeldung.id,
            externalAnmeldung: anmeldung,
            kindId,
        });
    }

    protected confirmAssignment(kind: SearchResultEntry | Persisted<Kind>): void {
        this.externalAnmeldungService.assign(this.externalAnmeldung.id, kind.id)
            .then(() => this.kindService.getOffeneBewerbung(kind.id))
            .then(() => this.showConfirmForExistingBewerbung(kind.id))
            .catch(response => {
                if (response.status === HttpCodes.NO_CONTENT) {
                    this.goToNeueBewerbung(kind.id);
                }
            });
    }

    protected assignKindAndChangeState(kindId: string): angular.IPromise<unknown> {

        return this.externalAnmeldungService.assign(this.externalAnmeldung.id, kindId)
            .then(() => this.goToNeueBewerbung(kindId));
    }

    protected getExternalAnmeldung(): ExternalAnmeldung {
        return this.externalAnmeldung;
    }

    private showConfirmForExistingBewerbung(kindId: string): void {

        this.dialogService.openConfirmDialog({
            title: 'EXTERNAL_ANMELDUNG.BEWERBUNG.DUPLICATE',
            confirmActionText: 'EXTERNAL_ANMELDUNG.BEWERBUNG.BEWERBUNG_UEBERNEHMEN',
            cancelActionText: 'EXTERNAL_ANMELDUNG.BEWERBUNG.BEWERBUNG_NICHT_UEBERNEHMEN',
            confirm: () => from(this.goToNeueBewerbung(kindId)),
            cancel: () => this.$state.reload(),
        });
    }

    private createKontaktWithRelationship(externalKontaktperson: ExternalKontaktperson, kontaktperson?: Kontaktperson)
        : RelationshipWithKontaktperson {

        const relationshipKontakt = kontaktperson ??
            ExternalKontaktperson.initializeKontaktperson(new Kontaktperson(), externalKontaktperson);

        relationshipKontakt.externalKontaktId = externalKontaktperson.id;

        return this.createRelationship(relationshipKontakt, externalKontaktperson);
    }

    private createRelationship(kontaktPerson: Kontaktperson, anmeldungKontaktperson: ExternalKontaktperson)
        : RelationshipWithKontaktperson {
        const hauptKontakt = new RelationshipWithKontaktperson();
        hauptKontakt.kontaktperson = kontaktPerson;
        hauptKontakt.kontaktpersonId = kontaktPerson.id;
        hauptKontakt.relationship.createSonstigerKontakt();

        if (anmeldungKontaktperson.hauptkontakt) {
            hauptKontakt.relationship.createHauptkontakt();
            hauptKontakt.relationship.createRechnungsempfaenger();
        }
        if (anmeldungKontaktperson.erziehungsberechtigt) {
            hauptKontakt.relationship.createErziehungsberechtigter();
        }
        if (anmeldungKontaktperson.abholberechtigt) {
            hauptKontakt.relationship.createAbholberechtigt();
        }

        return hauptKontakt;
    }
}

componentConfig.controller = DvbExternalAnmeldungUebernahme;
angular.module('kitAdmin').component('dvbExternalAnmeldungUebernahme', componentConfig);
