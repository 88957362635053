/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {AbstractLimitedPlaetze, KinderOrt} from '@dv/kitadmin/models';
import {KinderOrtType} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {from, take} from 'rxjs';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        plaetzeLabel: '@',
        plaetzeValues: '<',
        atLeastOne: '<',
        minValue: '<?',
        onUpdateValue: '&',
        onDeleteValue: '&',
        onRevertValue: '&',
    },
    template: require('./dvb-plaetze-verlauf.html'),
    controllerAs: 'vm',
};

export type Platz<T> = AbstractLimitedPlaetze<T> & { isEditMode?: boolean };

export class DvbPlaetzeVerlauf<T> implements angular.IController {

    public static $inject: readonly string[] = ['errorService', 'dialogService', '$q'];

    public kita!: Persisted<KinderOrt>;
    public plaetzeLabel!: string;
    public plaetzeValues!: Platz<T>[];
    public atLeastOne!: boolean;
    public minValue?: number;
    public onUpdateValue!: (data: { value: Platz<T> }) => unknown;
    public onDeleteValue!: (data: { value: Platz<T> }) => unknown;
    public onRevertValue!: (data: { value: Platz<T> }) => unknown;

    public today: moment.Moment = DvbDateUtil.today();
    public updatedPlaetzeValue?: Platz<T>;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (!this.minValue) {
            this.minValue = 0;
        }
    }

    public plaetzeValueTitle(plaetzeValue: Platz<T>): string {
        return DvbDateUtil.isGueltigOn(plaetzeValue, this.today) ?
            'COMMON.AKTUELLE_KAPAZITAET_MIT_DATUMPREP' :
            'COMMON.KAPAZITAET_MIT_DATUMPREP';
    }

    public temporalPrepositionKey(plaetzeValue: Platz<T>): string {
        return DvbDateUtil.getTemporalPrepositionKey(plaetzeValue, this.today) ?? '';
    }

    public editPlaetzeValue(plaetzeValue: Platz<T>): void {
        this.updatedPlaetzeValue = angular.copy(plaetzeValue);
    }

    public revertPlaetzeValue(plaetzeValue: Platz<T>): void {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
        if (TypeUtil.isFunction(this.onRevertValue)) {
            this.onRevertValue({value: plaetzeValue});
            plaetzeValue.isEditMode = false;
        }
    }

    public deletePlaetzeValue(plaetzeValue: Platz<T>): void {
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
        this.dialogService.openConfirmDialog({
            title: 'COMMON.CONFIRM_DELETE',
            confirm: () => from(this.$q.when(this.onDeleteValue({value: plaetzeValue})))
                .pipe(take(1)),
        });
    }

    public updatePlaetzeValue(
        form: angular.IFormController,
        plaetzeValue: Platz<T>,
        newPlaetzeValue: Platz<T>,
    ): void {
        const valid = form.$valid && this.isKapazitaetValid(newPlaetzeValue);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        if (plaetzeValue.plaetze === newPlaetzeValue.plaetze) {
            plaetzeValue.isEditMode = false;

            return;
        }

        if (TypeUtil.isFunction(this.onUpdateValue)) {
            // Aktualisiere das Original
            plaetzeValue.plaetze = newPlaetzeValue.plaetze;
            plaetzeValue.isEditMode = false;
            this.onUpdateValue({value: plaetzeValue});
        }
    }

    public isPlaetzeValueBeendet(plaetzeValue: Platz<T>): boolean {
        return !DvbDateUtil.isEndOfTime(plaetzeValue.gueltigBis);
    }

    public isDeleteAllowed(): boolean {
        return this.plaetzeValues.length > 1 || !this.atLeastOne;
    }

    public disablePlaetzeEdit(plaetze: unknown): boolean {
        return (!angular.isNumber(plaetze) || isNaN(plaetze)) && KinderOrtType.KITA === this.kita.dtype;
    }

    private isKapazitaetValid(plaetzeValue: Platz<T>): boolean {
        const valid = plaetzeValue.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }
}

componentConfig.controller = DvbPlaetzeVerlauf;
angular.module('kitAdmin').component('dvbPlaetzeVerlauf', componentConfig);
