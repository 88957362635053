/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KitaRechnungsKonfiguration, RechnungsKonfiguration} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, END_OF_TIME} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {from, Subject, take, tap} from 'rxjs';
import type {KitaFakturaService} from '../../../../../common/service/rest/kinderort/kitaFakturaService';
import type {
    KitaRechnungsKonfigurationBearbeitenConfirmationDialogModel,
} from '../kita-rechnungs-konfiguration-bearbeiten-confirmation/kita-rechnungs-konfiguration-bearbeiten-confirmation.component';
import {
    KitaRechnungsKonfigurationBearbeitenConfirmationComponent,
} from '../kita-rechnungs-konfiguration-bearbeiten-confirmation/kita-rechnungs-konfiguration-bearbeiten-confirmation.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '<',
        mandantKonfigurationen: '<',
        konten: '<',
    },
    template: require('./dvb-kita-faktura-konfiguration-content.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaKonfigurationContent implements angular.IController {

    public static $inject: readonly string[] = [
        'kitaFakturaService',
        'errorService',
        'dialogService',
        '$state',
    ];

    public konfiguration!: Persisted<KitaRechnungsKonfiguration>;
    public mandantKonfigurationen!: RechnungsKonfiguration;
    public konten: JaxKonto[] = [];

    public workingCopyKonfiguration?: KitaRechnungsKonfiguration;
    public konfigurationenTypen = RechnungsKonfigurationType;
    public endOfTime: moment.Moment = END_OF_TIME;
    public editMode: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private readonly kitaFakturaService: KitaFakturaService,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly $state: StateService,
    ) {
    }

    public edit(): void {
        this.workingCopyKonfiguration = angular.copy(this.konfiguration);
        this.editMode = true;
    }

    public onSubmit(): void {
        this.isLoading = true;
        const workingCopy = checkPresent(this.workingCopyKonfiguration);

        this.kitaFakturaService.getAffectedRechnungenByRechnungsKonfiguration(workingCopy)
            .then(stats => {
                if (stats.totalRechnungenAffected <= 0) {
                    return this.updateRechnungsKonfiguration();
                }

                const dialogResult$ = new Subject<void>();
                const dialogModel: KitaRechnungsKonfigurationBearbeitenConfirmationDialogModel = {
                    stats,
                    confirm: () => from(this.updateRechnungsKonfiguration()).pipe(tap(() => dialogResult$.next())),
                    cancel: () => dialogResult$.next(),
                };

                this.dialogService.openDialog(KitaRechnungsKonfigurationBearbeitenConfirmationComponent, dialogModel);

                return dialogResult$.pipe(take(1));

            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public updateRechnungsKonfiguration(): angular.IPromise<unknown> {
        return this.kitaFakturaService.updateRechnungsKonfiguration(checkPresent(this.workingCopyKonfiguration))
            .then(() => {
                this.$state.reload();
                this.editMode = false;
            });
    }

    public onDelete(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'KINDERORT.KITA_RECHNUNGS_KONFIGURATION',
            confirm: () => from(this.kitaFakturaService.deleteKitaRechnungsKonfiguration(this.konfiguration.id))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public onCancel(): void {
        this.editMode = false;
    }
}

componentConfig.controller = DvbKitaFakturaKonfigurationContent;
angular.module('kitAdmin').component('dvbKitaFakturaKonfigurationContent', componentConfig);
