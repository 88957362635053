<div class="dvb-kita-rechnungslauf-finish-kontaktperson-entry">
    <div ng-if="vm.empfaenger.rechnung">
        <div class="collapsible-panel-container row entry-heading" ng-if="!vm.isSingleEntry" ng-click="vm.onClick()">
            <div class="col-xs-7">
                <p ng-bind="vm.empfaenger.kontaktpersonDisplayName"
                   class="inline-block recipient truncate"
                   uib-tooltip="{{vm.empfaenger.kontaktpersonDisplayName}}"
                   tooltip-placement="top-left">
                </p>

                <i class="fa fa-money vorauszahlung"
                   ng-if="vm.empfaenger.vorauszahlungen > 0"
                   uib-tooltip="{{'FAKTURA.VORAUSZAHLUNG_BETRAG_VORHANDEN' |
                        translate:{betrag: (vm.empfaenger.vorauszahlungen | number: 2)} }}"
                   tooltip-placement="top-left">
                </i>
                <i class="fa fa-info-circle"
                   ng-if="vm.empfaenger.abweichungVormonat || vm.empfaenger.abweichungRevisionsTotal"
                   uib-tooltip="{{vm.getAbweichungTranslation() | translate}}"
                   tooltip-placement="top-left">
                </i>

                <dvb-versandart-badge class="pull-right"
                                      versandart="vm.empfaenger.versandartRechnung"
                                      tooltip="vm.empfaenger.email">
                </dvb-versandart-badge>
            </div>
            <div class="col-xs-3">
                <dvb-tasks-badge ng-if="!vm.empfaenger.error"
                                 label="{{vm.empfaenger.rechnungsdifferenz ?
                                   'COMMON.RECHNUNGSDIFFERENZ' :
                                   'COMMON.VERRECHENBAR'}}"
                                 popover-disable="true">
                </dvb-tasks-badge>

                <dvb-rechnungs-revision-error ng-if="vm.empfaenger.error"
                                              errors="vm.errors">
                </dvb-rechnungs-revision-error>
            </div>
            <div class="col-xs-2 text-right rechnungs-total">
                <strong data-translate="COMMON.BETRAG_VALUE"
                        data-translate-value-betrag="{{vm.empfaenger.rechnung.summe | number: 2}}">
                </strong>
            </div>
        </div>
        <div uib-collapse="!vm.isSelected && !vm.isSingleEntry" class="row">
            <dvb-rechnung-detail rechnung="vm.empfaenger.rechnung"></dvb-rechnung-detail>
        </div>
    </div>

    <div ng-if="!vm.empfaenger.rechnung">
        <div class="collapsible-panel-container row">
            <div class="col-xs-9">
                <p ng-bind="vm.empfaenger.kontaktpersonDisplayName"></p>
            </div>
            <div class="col-xs-3">
                <dvb-tasks-badge label="COMMON.NICHT_VERRECHENBAR"
                                 popover-title="{{'KINDERORT.RECHNUNG_NICHT_VERRECHENBAR_TOOLTIP' | translate}}"
                                 tasks="vm.empfaenger.tasks"
                                 warning="true">
                </dvb-tasks-badge>
                <dvb-versandart-badge versandart="vm.empfaenger.versandartRechnung"
                                      tooltip="vm.empfaenger.email">
                </dvb-versandart-badge>
            </div>
        </div>
    </div>
</div>
