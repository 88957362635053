/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type moment from 'moment';
import {DvbDateUtil} from '@dv/shared/code';

angular.module('kitAdmin').directive('dvbBeginOfWeek', dvbBeginOfWeek);

function dvbBeginOfWeek(): angular.IDirective {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (_scope, _element, _attrs, ngModel: any): void => {
            ngModel.$parsers.unshift(adjustToBeginOfWeek);
        },
    };

    function adjustToBeginOfWeek(viewValue: moment.Moment): moment.Moment {
        return DvbDateUtil.adjustToBeginOfWeek(viewValue);
    }
}
