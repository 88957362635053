/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    BetreuungsVereinbarungsKonfigurationType,
    DokumentLayout,
    IRechnungsKonfiguration,
    KitaBetreuungsfaktorRegel,
    KitaRechnungsKonfiguration,
    KitaTarifeZeitraum,
    RechnungsKonfiguration,
} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import type {
    BetreuungsVereinbarungsKonfigurationService,
} from '../common/service/rest/kinderort/betreuungsVereinbarungsKonfigurationService';
import type {KinderOrtSchliesstageService} from '../common/service/rest/kinderort/kinderOrtSchliesstageService';
import type {KinderOrtService} from '../common/service/rest/kinderort/kinderOrtService';
import type {KitaBetreuungsfaktorRegelService} from '../common/service/rest/kinderort/kitaBetreuungsfaktorRegelService';
import type {KitaFakturaService} from '../common/service/rest/kinderort/kitaFakturaService';
import type {MandantService} from '../common/service/rest/mandantService';
import type {DokumentlayoutService} from '../dokumentlayout/service/dokumentlayoutService';
import type {KinderOrtSchliesstage} from '../schliesstage/models/KinderOrtSchliesstage';

/* eslint-disable sonarjs/no-duplicate-string */

export const TARIFZUWEISUNG_STATE = {
    name: 'base.kinderort.tarife',
    url: '/tarifezuweisungen',
    component: 'dvbKitaTarife',
    resolve: {
        tarifeZuweisungen: [
            'kinderOrtService', '$transition$', (
                kinderOrtService: KinderOrtService,
                $transition$: Transition,
            ): angular.IPromise<KitaTarifeZeitraum[]> =>

                kinderOrtService.getTarifeZuweisungen($transition$.params().id),
        ],
    },
    data: {
        subtitle: 'COMMON.TARIF.PLURAL',
        permission(toParams: { id: string }): string {
            return PERMISSION.KITA.CONTROL + toParams.id;
        },
    },
} satisfies Ng1StateDeclaration;

export const BETREUUNGSFAKTORREGELN_STATE = {
    name: 'base.kinderort.betreuungsfaktorregeln',
    url: '/betreuungsregeln',
    template: '<dvb-kita-betreuungsfaktor-regeln ' +
        'kita-betreuungsfaktor-regeln="$resolve.kitaBetreuungsfaktorRegeln" ' +
        'kita-id="$resolve.kinderOrt.id" ' +
        'kita-name="$resolve.kinderOrt.getDisplayName()"></dvb-kita-betreuungsfaktor-regeln>',
    resolve: {
        kitaBetreuungsfaktorRegeln: [
            'kitaBetreuungsfaktorRegelService', '$transition$',
            (
                kitaBetreuungsfaktorRegelService: KitaBetreuungsfaktorRegelService,
                $transition$: Transition,
            ): angular.IPromise<KitaBetreuungsfaktorRegel[]> =>
                kitaBetreuungsfaktorRegelService.getKitaBetreuungsfaktorRegeln($transition$.params().id),
        ],
    },
    data: {
        subtitle: 'COMMON.BETREUUNGSFAKTOR_REGEL.PLURAL',
        permission(toParams: { id: string }): string {
            return PERMISSION.KITA.VIEW + toParams.id;
        },
    },
} satisfies Ng1StateDeclaration;

export const SCHLIESSTAGE_STATE = {
    name: 'base.kinderort.schliesstage',
    url: '/schliesstage',
    template: '<dvb-kinder-ort-schliesstage ' +
        'kinder-ort-schliesstage="$resolve.kinderOrtSchliesstage" ' +
        'kinder-ort-id="$resolve.kinderOrt.id" ' +
        'kinder-ort-name="$resolve.kinderOrt.getDisplayName()"></dvb-kinder-ort-schliesstage>',
    resolve: {
        kinderOrtSchliesstage: [
            'kinderOrtSchliesstageService', '$transition$',
            (
                kinderOrtSchliesstageService: KinderOrtSchliesstageService,
                $transition$: Transition,
            ): angular.IPromise<KinderOrtSchliesstage[]> =>
                kinderOrtSchliesstageService.getAllByKinderOrt($transition$.params().id),
        ],
    },
    data: {
        subtitle: 'COMMON.SCHLIESSTAGE.PLURAL',
        permission(toParams: { id: string }): string[] {
            return [PERMISSION.MODULE.ANWESENHEIT, PERMISSION.KITA.VIEW + toParams.id];
        },
    },
} satisfies Ng1StateDeclaration;

export const FAKTURA_KONFIGURATION_STATE = {
    name: 'base.kinderort.faktura.konfiguration',
    url: '/konfiguration',
    template: '<dvb-kita-faktura-konfigurationen ' +
        'kita="$resolve.kinderOrt" ' +
        'kita-konfigurationen="$resolve.kitaKonfigurationen" ' +
        'mandant-konfigurationen="$resolve.mandantKonfigurationen"></dvb-kita-faktura-konfigurationen>',
    resolve: {
        kitaKonfigurationen: [
            'kitaFakturaService', '$transition$',
            (
                kitaFakturaService: KitaFakturaService,
                $transition$: Transition,
            ): angular.IPromise<KitaRechnungsKonfiguration<IRechnungsKonfiguration>[]> =>
                kitaFakturaService.getRechnungsKonfigurationen<IRechnungsKonfiguration>($transition$.params().id),
        ],
        mandantKonfigurationen: [
            'mandantService',
            (mandantService: MandantService): angular.IPromise<RechnungsKonfiguration<IRechnungsKonfiguration>[]> =>
                mandantService.getRechnungsKonfigurationen(),
        ],
    },
    data: {
        subtitle: 'KINDERORT.NAV_RECHNUNGSKONFIGURATION',
    },
} satisfies Ng1StateDeclaration;

export const KIBON_MUTATIONSLAUF_STATE = {
    name: 'base.kinderort.kibon-mutationslauf',
    url: '/kibon-meldungen',
    component: 'dvbKibonMutationslauf',
    data: {
        subtitle: 'KINDERORT.NAV_KIBON_MUTATIONSLAUF',
        permission(toParams: { id: string }): string {
            return PERMISSION.KITA.CONTROL + toParams.id;
        },
    },
} satisfies Ng1StateDeclaration;

export const VEREINBARUNGS_CONFIGS_STATE = {
    name: 'base.kinderort.vereinbarungskonfigurationen',
    url: '/vereinbarungskonfigurationen',
    template: '<dvb-kita-vereinbarungs-konfigurationen ' +
        'kinder-ort="$resolve.kinderOrt" ' +
        'konfiguration-types="$resolve.vereinbarungsKonfigurationTypes" ' +
        'dokument-layouts="$resolve.dokumentLayouts"></dvb-kita-vereinbarungs-konfigurationen>',
    resolve: {
        vereinbarungsKonfigurationTypes: [
            'betreuungsVereinbarungsKonfigurationService', '$transition$', (
                betreuungsVereinbarungsKonfigurationService: BetreuungsVereinbarungsKonfigurationService,
                $transition$: Transition,
            ): angular.IPromise<BetreuungsVereinbarungsKonfigurationType[]> =>
                betreuungsVereinbarungsKonfigurationService.getAllTypes($transition$.params().id),
        ],
        dokumentLayouts: [
            'dokumentlayoutService',
            (dokumentlayoutService: DokumentlayoutService): angular.IPromise<DokumentLayout[]> =>
                dokumentlayoutService.getAllDokumentLayouts(),
        ],
    },
    data: {
        subtitle: 'KINDERORT.NAV_VEREINBARUNGSKONFIGURATION',
    },
} satisfies Ng1StateDeclaration;

export const VEREINBARUNGS_CONFIG_CREATION_STATE = {
    name: 'base.kinderort.vereinbarungskonfiguration-creation',
    url: '/vereinbarungskonfiguration-erstellen/{typeId:[0-9]+}',
    template: '<dvb-kita-betreuungs-vereinbarungs-konfiguration-creation type-id="$resolve.typeId" ' +
        'kinder-ort="$resolve.kinderOrt">' +
        '</dvb-kita-betreuungs-vereinbarungs-konfiguration-creation>',
    data: {
        subtitle: 'KINDERORT.NAV_VEREINBARUNGSKONFIGURATION',
        permission(toParams: { id: string }): string {
            return PERMISSION.KITA.ADMINISTRATE + toParams.id;
        },
    },
    resolve: {
        typeId: [
            '$transition$', (
                $transition$: Transition,
            ): angular.IPromise<string> => $transition$.params().typeId,
        ],
    },
} satisfies Ng1StateDeclaration;

export const LOHN_API_STATE = {
    name: 'base.kinderort.lohn',
    url: '/lohn',
    component: 'dvbKinderOrtLohn',
    data: {
        subtitle: 'KINDERORT.NAV_LOHN',
        permission(toParams: { id: string }): RequiredPermission {
            return [
                {
                    oneOf: [
                        `${PERMISSION.FEATURE.MONATSBLATT_API_KIBE_PLUS}:${toParams.id}`,
                        `${PERMISSION.FEATURE.MONATSBLATT_API_NANNY_KIBE_PLUS}:${toParams.id}`,
                    ],
                },
            ];
        },
        role: UserRole.ADMIN,
    },
} satisfies Ng1StateDeclaration;

export const ANWESENHEITS_ZEIT_STATE = {
    name: 'base.kinderort.anwesenheits-zeit',
    url: '/anwesenheits-zeit',
    component: 'dvbKinderortAnwesenheitSettings',
    data: {
        subtitle: 'KIND.MONATSBELEGUNG.SETTINGS',
        role: UserRole.SUPPORT_ADMIN,
    },
} satisfies Ng1StateDeclaration;

export const KINDERORT_SETTING_STATES = {
    TARIFZUWEISUNG_STATE,
    BETREUUNGSFAKTORREGELN_STATE,
    SCHLIESSTAGE_STATE,
    FAKTURA_KONFIGURATION_STATE,
    KIBON_MUTATIONSLAUF_STATE,
    VEREINBARUNGS_CONFIGS_STATE,
    VEREINBARUNGS_CONFIG_CREATION_STATE,
    LOHN_API_STATE,
    ANWESENHEITS_ZEIT_STATE,
};
