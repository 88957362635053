<div class="dvb-kontaktperson-stammdaten-form">
    <form name="kontaktpersonStammdatenForm">
        <div class="row">
            <div class="col-md-12">
                <div class="row small-abstand-bottom">
                    <div class="col-md-6">
                        <div class="radio">
                            <label>
                                <input type="radio"
                                       name="isFirma"
                                       ng-value="false"
                                       ng-change="vm.kontakt.kontaktperson.updateOrganisation()"
                                       ng-model="vm.kontakt.kontaktperson.isFirma">
                                <span data-translate="COMMON.PRIVAT"></span>
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input type="radio"
                                       name="isFirma"
                                       ng-change="vm.updateOrganisation()"
                                       ng-value="true"
                                       ng-model="vm.kontakt.kontaktperson.isFirma">
                                <span data-translate="COMMON.FIRMA.SINGULAR"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" ng-if="vm.kontakt.kontaktperson.isFirma">
                        <input type="text"
                               class="form-control no-margin"
                               dvb-capitalize-first-letter
                               maxlength="255"
                               ng-model="vm.kontakt.kontaktperson.organisation"
                               ng-attr-placeholder="{{'COMMON.ORGANISATION' | translate}}"
                               uib-tooltip="{{'COMMON.ORGANISATION' | translate}}"
                               tooltip-placement="top-left"
                               ng-required="vm.kontakt.kontaktperson.isOrganisationRequired()">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <input type="text"
                               class="form-control"
                               dvb-capitalize-first-letter
                               ng-model="vm.kontakt.kontaktperson.familienName"
                               name="familienName"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               uib-tooltip="{{'COMMON.NAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-required="vm.kontakt.kontaktperson.isNameRequired()">
                    </div>
                    <div class="col-md-6">
                        <input type="text"
                               class="form-control"
                               dvb-capitalize-first-letter
                               ng-model="vm.kontakt.kontaktperson.vorName"
                               name="vorName"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                               uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-required="vm.kontakt.kontaktperson.isNameRequired()">
                    </div>
                </div>
                <div class="row form-row">
                    <div ng-class="{'col-md-6': vm.form.$submitted, 'col-md-12': !vm.form.$submitted}">
                        <div class="radio" ng-repeat="geschlecht in vm.geschlechte">
                            <label>
                                <input type="radio"
                                       name="geschlecht_{{$id}}"
                                       value="{{geschlecht}}"
                                       ng-model="vm.kontakt.kontaktperson.geschlecht"
                                       required>
                                <span data-translate="COMMON.{{geschlecht}}"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" ng-show="vm.form.$submitted && !vm.kontakt.kontaktperson.geschlecht">
                        <p class="error-message"
                           data-translate="ERRORS.ERR_REQUIRED_GESCHLECHT"></p>
                    </div>
                </div>
                <div ng-if="vm.uneditableAdresse" class="normal-abstand-bottom">

                    <dvlib-adresse-display [adresse]="vm.kontakt.kontaktperson.adressen[0].adresse"
                                           [display-type]="'short'">
                    </dvlib-adresse-display>
                </div>
                <dvb-adresse-form ng-if="vm.kontakt.kontaktperson.addressMode === 'OWN' && !vm.uneditableAdresse"
                                  ng-model="vm.kontakt.kontaktperson.adressen[0].adresse"
                                  is-address-required="true">
                </dvb-adresse-form>

                <div class="row form-row" ng-if="!vm.kontakt.relationship.isHauptkontakt()">
                    <div class="col-md-12">
                        <div class="radio" ng-repeat="addressMode in vm.addressModes">
                            <label>
                                <input type="radio"
                                       name="addressMode_{{$id}}"
                                       value="{{addressMode}}"
                                       ng-model="vm.kontakt.kontaktperson.addressMode"
                                       ng-change="vm.changeKontaktAdresse()"
                                       required>
                                <span data-translate="COMMON.ADDRESS_MODE.{{addressMode}}"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <input type="text"
                               class="form-control"
                               ng-model="vm.kontakt.kontaktperson.telefon"
                               maxlength="100"
                               ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                               uib-tooltip="{{'COMMON.TELEFON' | translate}}"
                               tooltip-placement="top-left"
                               ng-required="vm.kontakt.relationship.isHauptkontakt()
                               && !vm.kontakt.kontaktperson.mobile">
                    </div>
                    <div class="col-md-6">
                        <input type="text"
                               class="form-control"
                               ng-model="vm.kontakt.kontaktperson.mobile"
                               maxlength="100"
                               ng-attr-placeholder="{{'COMMON.MOBILE_NR' | translate}}"
                               uib-tooltip="{{'COMMON.MOBILE_NR' | translate}}"
                               tooltip-placement="top-left"
                               ng-required="vm.kontakt.relationship.isHauptkontakt()
                                && !vm.kontakt.kontaktperson.telefon">
                    </div>
                    <div class="col-md-6">
                        <input type="text"
                               class="form-control"
                               maxlength="100"
                               ng-model="vm.kontakt.kontaktperson.geschaeft"
                               ng-attr-placeholder="{{'COMMON.GESCHAEFT_NR' | translate}}"
                               uib-tooltip="{{'COMMON.GESCHAEFT_NR' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <input type="email"
                               class="form-control"
                               maxlength="255"
                               ng-model="vm.kontakt.kontaktperson.email"
                               ng-required="vm.versandartEmail"
                               ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                               uib-tooltip="{{'COMMON.E-MAIL' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.versandartEmail"
                                       ng-change="vm.updateVersandart()">
                                <span data-translate="COMMON.KORRESPONDENZ_PER_EMAIL"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <dvb-iso-locale ng-model="vm.kontakt.kontaktperson.korrespondenzsprache"
                                        type="LANGUAGE"
                                        ng-attr-placeholder="{{'COMMON.KORRESPONDENZSPRACHE' | translate}}">
                        </dvb-iso-locale>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
