<div class="kita-kinder">

    <!-- CONTENT -->
    <div class="row">

        <!-- KINDER -->
        <div class="kinder col-md-7 big-abstand-bottom">
            <dvlib-spinner class="centered-spinner" [show]="vm.loading"></dvlib-spinner>
            <div ng-repeat="kind in vm.kinder | filter:{visible:true} | orderBy: vm.kinderListeStrategy.orderBy | limitTo: vm.limitTo">
                <dvb-kinder-liste kind="kind"
                                  ng-if="!vm.loading"
                                  kita-id="vm.kita.id"
                                  list-model="{{vm.kinderListeStrategy.listModel}}"
                                  gruppen-ids="vm.gruppenIds"
                                  kontingente="vm.kitaKontingente"
                                  wochenplan-bewerbung="vm.wochenplanBewerbung"
                                  first-of-week="vm.firstOfWeek"
                                  last-of-week="vm.lastOfWeek"
                                  week-days="vm.weekDays"
                                  is-betreuungsfaktor-loading="vm.betreuungsfaktorenLoading"
                                  betreuungsfaktor="vm.betreuungsfaktoren[kind.id]"
                                  selected-kind-id="vm.selectedKindId"
                                  selected-kind-changed="vm.selectedKindChanged(kind)"
                                  on-kind-zuweisen="vm.openZuweisung(kind, belegung)"
                                  on-date-selected="vm.onDateSelected({date: date})">
                </dvb-kinder-liste>
            </div>
            <div class="normal-padding-top">
                <div class="pull-left" ng-show="vm.isShowMoreLinkVisible()">
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.increaseLimitTo()"
                            data-translate="KINDERORT.MEHR_ANZEIGEN">
                    </button>
                </div>
                <div class="pull-right" ng-show="!vm.loading && vm.visibleKinderCount > 0">
                    <span data-translate="KINDERORT.TOTAL_KINDER_MF"
                          data-translate-interpolation="messageformat"
                          data-translate-value-anzahl="{{vm.visibleKinderCount}}"
                          data-translate-value-summen-prozent="{{vm.kinderListeStrategy.pensumDisplay}}"
                          data-translate-value-vertraglich-prozent="{{vm.kinderListeStrategy.vertraglichesPensum ? vm.kinderListeStrategy.vertraglichesPensum : 0}}">
                    </span>
                </div>
            </div>
        </div>

        <!-- WOCHEN BELEGUNGEN -->
        <div class="col-md-4 col-md-offset-1"
             ng-if="vm.aktuelleGruppen.length > 0"
             ng-class="{'full-collapse': vm.isFullyCollapsible}">

            <h2 data-translate="{{vm.kinderListeStrategy.gruppenHeaderKey}}"></h2>

            <!-- Kita -->
            <dvb-wochen-belegung name="{{vm.kita.getDisplayName()}}"
                                 kita="vm.kita"
                                 first-of-week="vm.firstOfWeek"
                                 wochen-plaetze="vm.kitaWochenBelegung.plaetze"
                                 show-freie-plaetze="vm.kinderListeStrategy.isBewerbung"
                                 show-belegte-plaetze="vm.kinderListeStrategy.isBelegung"
                                 interactive="true"
                                 distinct-betreuungs-zeitraeume="vm.kitaDistinctZeitraeume"
                                 week-days="vm.weekDays"
                                 is-fully-collapsible="vm.isFullyCollapsible"
                                 on-zeitraum-feld-clicked="vm.updateKinderOrtZeitraumFeldFilter(zeitraumFeld)"
                                 zeitraum-felder="vm.kitaZeitraumFelder">
            </dvb-wochen-belegung>

            <!-- Gruppen -->
            <div ng-repeat="gruppe in vm.visibleGruppen" class="gruppen-wochen-belegung">

                <dvb-wochen-belegung name="{{gruppe.getDisplayName()}}"
                                     gruppe="gruppe"
                                     first-of-week="vm.firstOfWeek"
                                     wochen-plaetze="vm.gruppenWochenBelegungByGruppe[gruppe.id].plaetze"
                                     show-freie-plaetze="vm.kinderListeStrategy.isBewerbung"
                                     show-belegte-plaetze="vm.kinderListeStrategy.isBelegung"
                                     interactive="true"
                                     distinct-betreuungs-zeitraeume="gruppe.wochenplan.distinctZeitraeume"
                                     week-days="vm.weekDays"
                                     is-fully-collapsible="vm.isFullyCollapsible"
                                     on-zeitraum-feld-clicked="vm.updateZeitraumFeldFilter(zeitraumFeld, gruppe)"
                                     zeitraum-felder="vm.zeitraumFelderByGruppe[gruppe.id]">
                </dvb-wochen-belegung>
            </div>
        </div>
    </div>
</div>
