<div class="dvb-kita-rechnungslauf-finish col-md-8">
    <h2 data-translate="KINDERORT.RECHNUNGSLAUF_PERIODE" data-translate-value-periode="{{vm.getPeriode()}}"></h2>

    <form name="kitaRechnungslaufStartForm"
          ng-submit="vm.submit(kitaRechnungslaufStartForm)"
          novalidate>

        <div ng-show="vm.rechnungsLauf.entries.length === 0"
             class="collapsible-panel-container first-container">
            <div class="col-xs-12">
                <p data-translate="KINDERORT.KEINE_NICHT_VERRECHNETEN_RECHNUNGEN_VORHANDEN"></p>
            </div>
        </div>

        <div ng-repeat="entry in vm.rechnungsLauf.entries">
            <dvb-kita-rechnungslauf-finish-entry
                entry="entry"
                checked-entry="vm.ids[entry.rechnungsUebermittlungsEinheitId]"
                faellig-am="vm.rechnungsLauf.faelligAm"
                is-selected="vm.selectedEntryIndex === $index"
                index="$index"
                is-first="$first"
                is-disabled="vm.isLoading"
                kinder-ort-name="vm.kinderOrtName"
                on-select-entry="vm.selectEntry(index)">
            </dvb-kita-rechnungslauf-finish-entry>
        </div>

        <div ng-if="vm.rechnungsLauf.entries.length > 0">
            <div class="normal-abstand-top" ng-class="{'invisible': vm.isLoading}">
                <ul class="list-inline">
                    <li>
                        <a href=""
                           ng-click="vm.selectAllEntries()"
                           data-translate="KINDERORT.RECHNUNGSLAUF_SELECT_ALL_VERRECHENBAR">
                        </a>
                    </li>
                    <li>
                        <a href=""
                           ng-click="vm.unselectAllEntries()"
                           data-translate="KINDERORT.RECHNUNGSLAUF_DESELECT_ALL">
                        </a>
                    </li>
                </ul>
            </div>

            <div class="row normal-abstand-top">
                <div class="col-xs-12">
                    <dvb-email-versand-form email-versand-properties="vm.emailVersandProperties"
                                            is-disabled="vm.isLoading"
                                            show-diff-checkbox="vm.showLimitToRechnungenWithDiff"
                                            limit-emails-to-rechnungen-with-diff="vm.limitEmailsToRechnungenWithDiff"
                                            on-update-limit-emails-to-rechnungen-with-diff="vm.limitEmailsToRechnungenWithDiff = limitEmailsToRechnungenWithDiff">
                    </dvb-email-versand-form>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" ng-model="vm.createRechnungsLaufPdf">
                            <span data-translate="KINDERORT.RECHNUNGSLAUF_PDF_ERSTELLEN"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row" uib-collapse="!vm.createRechnungsLaufPdf || !vm.showLimitToRechnungenWithDiff">
                <div class="col-md-6">
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   ng-required="vm.createRechnungsLaufPdf"
                                   name="downloadAll"
                                   ng-value="false"
                                   ng-model="vm.limitPdfsToRechnungenWithDiff">
                            <span data-translate="KINDERORT.RECHNUNGSLAUF_ALLE_PDF_ERSTELLEN"></span>
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   ng-required="vm.createRechnungsLaufPdf"
                                   name="downloadWithDiff"
                                   ng-value="true"
                                   ng-model="vm.limitPdfsToRechnungenWithDiff">
                            <span data-translate="KINDERORT.RECHNUNGSLAUF_PDF_MIT_DIFF_ERSTELLEN"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="big-abstand-top">
            <ul class="list-inline">
                <li ng-if="vm.rechnungsLauf.entries.length > 0" ng-disabled="Object.keys(vm.ids).length > 0">
                    <dvb-loading-button is-loading="vm.isLoading" type="submit">
                        <span data-translate="KINDERORT.RECHNUNGSLAUF_BEENDEN"></span>
                    </dvb-loading-button>
                </li>
                <li ng-hide="vm.isLoading"><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.cancel()"></a></li>
            </ul>
        </div>

    </form>

</div>
