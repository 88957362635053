<div class="dvb-kitas-with-gueltigkeit-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.kitasWithGueltigkeitForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-kitas-selection parameter-kinder-orte="vm.parameterKinderOrteWithGueltigkeit"
                             on-change="vm.updateState()">
        </dvb-kitas-selection>

        <div class="row form-row">
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.parameterKinderOrteWithGueltigkeit.gueltigAb"
                                           ng-change="vm.updateState()"
                                           custom-options="vm.dateOptions"
                                           label-date-format="vm.dateFormat"
                                           format="vm.dateFormat"
                                           required
                                           ng-attr-placeholder="{{'COMMON.VON' | translate}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.parameterKinderOrteWithGueltigkeit.gueltigBis"
                                           ng-change="vm.updateState()"
                                           custom-options="vm.dateOptions"
                                           label-date-format="vm.dateFormat"
                                           format="vm.dateFormat"
                                           required
                                           ng-attr-placeholder="{{'COMMON.BIS' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
