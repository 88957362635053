/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntitySearchType} from '@dv/shared/code';
import {DvbUtil, ENTITY_SEARCH_TYPE, LogFactory, SearchResultEntry} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import type {IDirective} from 'angular';
import angular from 'angular';
import {firstValueFrom, map, tap} from 'rxjs';
import {SubSink} from 'subsink';
import type {AuthEventService} from '../../../authentication/service/auth-event.service';
import type {AuthorisationService} from '../../../authorisation/service/authorisation.service';
import type {SearchService} from '../../service/searchService';

const LOG = LogFactory.createLog('DvbSearchInput');

const directive: IDirective = {
    replace: true,
    restrict: 'E',
    scope: {},
    template: require('./dvb-search-input.html'),
    controllerAs: 'vm',
    bindToController: true,
    link: (scope, element, _attrs, ctrls: any): void => {
        const controller = ctrls as DvbSearchInput;

        // listen for ENTER
        element.on('keypress', event => {
            const dropdown = element.find('ul.typeahead-dropdown');
            if (event.key === 'Enter') {
                const input = element.find('input');
                if (DvbUtil.isEmptyString(input.val())) {
                    return;
                }
                event.preventDefault();
                // navigate to the global search results
                controller.goToGlobalSearch();
                // lose the focus on the input field
                input.trigger('blur');
                // hide the dropdown with the search results
                dropdown.hide();

                return;
            }

            // re-enable the dropdown
            if (!dropdown.is(':visible')) {
                dropdown.show();
            }
        });
    },
};

export class DvbSearchInput implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'searchService',
        '$translate',
        'authorisationService',
        '$timeout',
        '$element',
        'authEventService',
    ];

    public searchInput?: string | SearchResultEntry;
    public searchInAllMandanten: boolean = false;
    public searchResultEntries: SearchResultEntry[] = [];
    public searchEntities: EntitySearchType[] = ENTITY_SEARCH_TYPE.values
        .filter(value => value !== 'ANMELDUNG_KIND');
    public checkedEntities: { [key in EntitySearchType]?: boolean } = {};
    public searchEntityCount: number = 0;

    private sink = new SubSink();

    public constructor(
        private $state: StateService,
        private searchService: SearchService,
        private $translate: angular.translate.ITranslateService,
        private authorisationService: AuthorisationService,
        private $timeout: angular.ITimeoutService,
        private $element: angular.IAugmentedJQuery,
        authEventService: AuthEventService,
    ) {
        this.sink.add(authEventService.loginSuccess$
            .subscribe({
                next: () => this.$onInit(),
                error: error => LOG.error('failed to subscribe to AuthEvents.loginSuccess', error),
            }));
    }

    public $onInit(): void {
        this.searchInput = undefined;
        this.searchInAllMandanten = this.searchService.getSearchInAllMandanten();
        this.searchResultEntries = [];
        this.initSearchEntities();
    }

    public $onDestroy(): void {
        this.sink.unsubscribe();
    }

    public toggleSearch(event: JQuery.Event): void {
        if (!event.ctrlKey || !this.authorisationService.isMandantAdmin()) {
            return;
        }

        this.searchInAllMandanten = !this.searchInAllMandanten;
        this.searchService.setSearchInAllMandanten(this.searchInAllMandanten);

        // prevent navigation to global search
        event.preventDefault();

        this.retriggerSearch();
    }

    public searchResults(searchText: string): angular.IPromise<SearchResultEntry[]> {

        return firstValueFrom(this.searchService.searchGlobal$(searchText).pipe(
            map(entries => {
                const results = entries.slice(0, 10);
                if (results.length < entries.length) {
                    const searchResultEntry = new SearchResultEntry();
                    searchResultEntry.id = searchText;
                    searchResultEntry.entity = 'ALL';
                    searchResultEntry.text = this.$translate.instant('SEARCH.ALLE_RESULTATE');

                    results.push(searchResultEntry);
                }

                return results;
            }),
            tap(results => {
                this.searchResultEntries = results;
            })),
        );
    }

    public goToGlobalSearch(): void {
        if (typeof this.searchInput === 'object') {
            // der searchInput wurde vom TypeAhead-Widget
            // schon in ein passendes SearchResult-Objekt aus dem Backend umgewandelt
            this.searchService.redirectToEntity(this.searchInput.entity, this.searchInput.id, false);

            return;
        }

        this.$state.go('base.global', {searchText: this.searchInput});
    }

    public onKeyup($event?: KeyboardEvent): void {
        if ($event?.key === 'Tab') {
            this.$element.find('[name="entity-filter"]').trigger('focus');
        }
    }

    public shouldSelect($event?: KeyboardEvent): boolean {
        return $event?.key !== 'Tab';
    }

    public onSelect(
        $item?: SearchResultEntry,
        _$model?: SearchResultEntry,
        _$label?: string,
        $event?: KeyboardEvent | MouseEvent,
    ): void {
        if (!$item?.entity) {
            return;
        }

        const middleMouseButton = 4;
        const openInNewWindow = $event instanceof MouseEvent && $event.buttons === middleMouseButton || $event?.ctrlKey;
        this.searchService.redirectToEntity($item.entity, $item.id, openInNewWindow);
    }

    public updateEntities(): void {
        const selectedEntities = Object.entries(this.checkedEntities).filter(([_entity, selected]) => selected)
            .map(([entity, _selected]) => entity);

        this.searchEntityCount = selectedEntities.length;
        this.searchService.setSearchEntities(selectedEntities.join(','));
        this.retriggerSearch();
    }

    public resetSearchEntities(): void {
        this.searchEntities.forEach(entityType => {
            delete this.checkedEntities[entityType];
        });
        this.updateEntities();
    }

    private retriggerSearch(): void {
        const lastInput = this.searchInput;
        if (DvbUtil.isEmptyString(lastInput)) {
            return;
        }
        // re-trigger the search with the new setting
        this.$timeout(() => {
            const ngModelCtrl: angular.INgModelController = this.$element.find('[name="searchInput"]')
                .controller('ngModel');
            ngModelCtrl.$setViewValue(''); // replicate an on change event
            ngModelCtrl.$setViewValue(lastInput);
            ngModelCtrl.$render();
        });
    }

    private initSearchEntities(): void {
        this.searchEntityCount = 0;
        this.searchService.getSearchEntities()?.split(',').forEach(entity => {
            if (ENTITY_SEARCH_TYPE.guard(entity)) {
                this.checkedEntities[entity] = true;
                this.searchEntityCount++;
            }
        });
    }
}

directive.controller = DvbSearchInput;
angular.module('kitAdmin').directive('dvbSearchInput', () => directive);
