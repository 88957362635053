<div class="dvb-kita-betreuungsfaktor-regel-content">
    <div ng-if="!vm.editMode">

        <dvb-kita-betreuungsfaktor-regel-heading kita-betreuungsfaktor-regel="vm.kitaBetreuungsfaktorRegel">
        </dvb-kita-betreuungsfaktor-regel-heading>

        <ul class="list-inline normal-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.edit()"
                        data-translate="COMMON.BEARBEITEN">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.remove()"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </li>
        </ul>
    </div>

    <div ng-if="vm.editMode" class="history-columns">
        <div class="history-column-left">
            <p class="small-abstand-bottom">
                <dvb-gueltigkeit entity="vm.workingCopyKitaBetreuungsfaktorRegel"></dvb-gueltigkeit>
            </p>

            <dvb-kita-betreuungsfaktor-regel-form kita-betreuungsfaktor-regel="vm.workingCopyKitaBetreuungsfaktorRegel"
                                                  is-loading="vm.isLoading"
                                                  on-submit="vm.submit()"
                                                  on-cancel="vm.cancel()">
            </dvb-kita-betreuungsfaktor-regel-form>
        </div>
    </div>
</div>
