/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {DvbUtil} from '@dv/shared/code';

angular.module('kitAdmin').directive('dvbCurrencyValue', dvbCurrencyValue);

function dvbCurrencyValue(): angular.IDirective {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: (_scope, _element, _attrs, ctrl: any): void => {
            const ngModel: angular.INgModelController = ctrl;
            // TODO momentan werden nur CHF unterstützt. Weitere Währungen könnten über attrs definiert werden
            ngModel.$validators.currencyValue = isValidCurrencyValue;

            function isValidCurrencyValue(_modelValue: any, viewValue: any): boolean {
                return ngModel.$isEmpty(viewValue) ? true : DvbUtil.isCHF(parseFloat(viewValue));
            }
        },
    };
}
