/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';
import {KontaktKorrespondenzFilter} from '../../../communication/models/KontaktKorrespondenzFilter';
import type {KontaktKorrespondenzService} from '../../../communication/service/kontakt-korrespondenz-service';
import {KontaktKorrespondenzScrollDataSource} from '../../service/kontaktKorrespondenzScrollDataSource';
import {KontaktKorrespondenzTimelineController} from './KontaktKorrespondenzTimelineController';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
    },
    template: require('./dvb-kontaktperson-correspondence.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonCorrespondence implements angular.IController {
    public static $inject: readonly string[] = [
        '$translate',
        'kontaktKorrespondenzService',
        'kontaktpersonService',
    ];

    public kontaktperson!: Kontaktperson;

    public isLoading: boolean = true;
    public filter: KontaktKorrespondenzFilter = new KontaktKorrespondenzFilter();

    public adapter?: angular.ui.IScrollAdapter;
    public timelineController: KontaktKorrespondenzTimelineController;
    public dataSource: KontaktKorrespondenzScrollDataSource;

    public constructor(
        $translate: angular.translate.ITranslateService,
        kontaktKorrespondenzService: KontaktKorrespondenzService,
        kontaktpersonService: KontaktpersonService,
    ) {
        this.timelineController = new KontaktKorrespondenzTimelineController(kontaktKorrespondenzService, $translate);
        this.dataSource = new KontaktKorrespondenzScrollDataSource(
            kontaktpersonService,
            this.kontaktperson,
            this.filter);
    }

    public updateFilter(): void {
        if (isPresent(this.adapter)) {
            this.adapter.reload(0);
        }
    }

    public $onChanges(): void {
        this.dataSource.kontaktperson = this.kontaktperson;
    }
}

componentConfig.controller = DvbKontaktpersonCorrespondence;
angular.module('kitAdmin').component('dvbKontaktpersonCorrespondence', componentConfig);
