<div class="dvb-dashboard-favoriten">

    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <h1 data-translate="COMMON.FAVORITEN"></h1>

                <div ng-if="!vm.isAddFavoritFormVisible"
                     class="text-right">
                    <a href=""
                       class="small-abstand-left header-link"
                       ng-click="vm.isAddFavoritFormVisible = true">
                        + <span data-translate="DASHBOARD.FAVORIT_HINZUFUEGEN"></span>
                    </a>
                </div>

                <div ng-if="vm.isAddFavoritFormVisible"
                     class="add-favorit-form small-abstand-left">
                    <dvb-dashboard-favoriten-suche
                        on-select="vm.onSelect(item)"
                        on-cancel="vm.isAddFavoritFormVisible = false"
                        focus="vm.isAddFavoritFormVisible"
                        favorite-entities="vm.entities">
                    </dvb-dashboard-favoriten-suche>
                </div>
            </div>
        </div>
    </div>

    <p ng-show="vm.isLoading">
        <dvlib-spinner></dvlib-spinner>
    </p>
    <p ng-show="!vm.isLoading && !vm.favoriten.length"
       data-translate="DASHBOARD.KEINE_FAVORITEN">
    </p>

    <div class="favorit-types">
        <div class="big-abstand-bottom small-abstand-right"
             ng-repeat="favoritTyp in vm.getSelectedFavoritTypes()"
             data-ee="{{favoritTyp.entity}}">
            <h2 data-translate="{{favoritTyp.title}}"
                class="small-abstand-right">
            </h2>

            <a class="favorit label label-radio label-default active truncate"
               ng-repeat="favorit in vm.favoriten | filter:{namedEntity: {dtype: favoritTyp.entity}}:true | orderBy:'title'"
               ng-href="{{vm.getEntityHref(favorit)}}"
               uib-tooltip="{{favorit.title}}"
               tooltip-placement="top-left">
                <span ng-bind="favorit.title"></span>
                <span class="delete-favorit" ng-click="vm.deleteFavorit(favorit); $event.preventDefault()">
                    <dvb-delete-icon></dvb-delete-icon>
                </span>
            </a>
        </div>
    </div>
</div>
