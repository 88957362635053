/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {CustomField} from '@dv/kitadmin/models';
import {CheckInCustomField} from '@dv/kitadmin/models';
import type {DialogService, SortListDialogModel} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {CheckInCustomFieldService} from '@dv/shared/backend/api/check-in-custom-field.service';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {CustomFieldType, ICustomField, Persisted} from '@dv/shared/code';
import {
    checkPersisted,
    checkPresent,
    CUSTOM_FIELD_TYPE,
    DvbUtil,
    NamedEntityType,
    sortCustomFields,
} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {BehaviorSubject, EMPTY, finalize, map, of, switchMap, tap} from 'rxjs';
import type {CustomFieldService} from '../../../common/service/rest/customFieldService';
import {CustomFieldLinkedComponent} from '../custom-field-linked/custom-field-linked.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-voreinstellungen-check-in-custom-fields.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenCheckInCustomFields implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'errorService',
        'checkInCustomFieldService',
        'customFieldService',
        'authStore',
        'dialogService',
    ];

    public customFields: CheckInCustomField[] = [];
    public isLoading: boolean = false;

    public showSortDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public sortState?: SortListDialogModel<CheckInCustomField>;
    public showSortLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public customFieldsForm?: angular.IFormController;

    public readonly fieldTypes: CustomFieldType[] = CUSTOM_FIELD_TYPE.values;

    private kindCustomFields: Persisted<CustomField>[] = [];
    private unassignedKindCustomFields: Persisted<ICustomField>[] = [];
    private endOfList: number = 0;

    public constructor(
        private errorService: ErrorService,
        private checkInCustomFieldService: CheckInCustomFieldService,
        private customFieldService: CustomFieldService,
        private authStore: AuthStore,
        private dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        this.loadCustomFields$().subscribe();
        this.loadKindCustomFields();
    }

    public onAddField(): void {
        const checkInCustomField = new CheckInCustomField();
        checkInCustomField.orderValue = this.endOfList++;
        this.customFields.push(checkInCustomField);
    }

    public onDelete(field: CheckInCustomField): void {
        DvbUtil.removeFromArray(field, this.customFields);
    }

    public onSubmit(): void {
        this.submit$().subscribe();
    }

    public submit$(): Observable<unknown> {
        const form = checkPresent(this.customFieldsForm);
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return of(EMPTY);
        }

        const uniqueNames = DvbUtil.uniqueArray(this.customFields, customField => customField.name);
        const isNamesUnique = uniqueNames.length === this.customFields.length;
        this.errorService.handleValidationError(isNamesUnique, 'ERRORS.ERR_DUPLICATE_NAME');

        if (!isNamesUnique) {
            return of(EMPTY);
        }

        this.isLoading = true;

        return this.checkInCustomFieldService.updateCustomFields$({
            jaxCheckInCustomFields: {
                checkInCustomFields: this.customFields.flatMap(value => value.toJaxRestObject()),
            },
        }).pipe(
            switchMap(() => this.loadCustomFields$()),
            finalize(() => {
                this.isLoading = false;
            }),
        );
    }

    public addLinkedField(): void {
        this.dialogService.openDialog(CustomFieldLinkedComponent, {
            customFields: this.unassignedKindCustomFields,
            confirm: (customFieldId: EntityId) => this.checkInCustomFieldService.createLinkedField$({
                customFieldId, customFieldIdMatrix: {
                    orderValue: this.endOfList++,
                },
            })
                .pipe(
                    switchMap(() => this.loadCustomFields$()),
                ),
        });
    }

    public sortFields(): void {
        this.sortState = this.sortFieldState();
        this.showSortDialog$.next(true);
    }

    public confirmSort = (customFields: CheckInCustomField[]): Observable<unknown> => {
        customFields.forEach((value, index) => {
            value.orderValue = index;
        });

        this.showSortLoading$.next(true);

        return this.submit$().pipe(
            tap(() => this.showSortDialog$.next(false)),
            finalize(() => this.showSortLoading$.next(false)),
        );
    };

    private loadCustomFields$(): Observable<unknown> {
        this.customFields = [];
        if (!this.authStore.isPermitted(PERMISSION.MODULE.GROUP_ADMIN)) {
            return of(null);
        }

        return this.checkInCustomFieldService.getCustomFields$().pipe(
            map(data => data.checkInCustomFields.map(CheckInCustomField.apiResponseTransformer)),
            map(fields => fields.sort(sortCustomFields)),
            tap(customFields => {
                this.customFields = customFields;
                const orderValues = customFields.map(customField => customField.orderValue ?? 0);
                this.endOfList = orderValues.length ? Math.max(...orderValues) + 1 : 0;

                this.filterKindCustomFields();
            }),
            finalize(() => {
                this.isLoading = false;
            }),
        );
    }

    private loadKindCustomFields(): void {
        this.customFieldService.getAll(NamedEntityType.KIND).then(kindCustomFields => {
            this.kindCustomFields = kindCustomFields.map(checkPersisted);
            this.filterKindCustomFields();
        });
    }

    private filterKindCustomFields(): void {
        this.unassignedKindCustomFields = this.kindCustomFields.filter(f => !this.isLinkdWithCheckInCustomField(f));
    }

    private isLinkdWithCheckInCustomField(kindField: Persisted<CustomField>): boolean {
        return this.customFields.some(field => field.kindCustomField?.id === kindField.id);
    }

    private sortFieldState(): SortListDialogModel<CheckInCustomField> {
        return {
            dialogTitleKey: 'CUSTOM_FIELD.SORTIEREN',
            items: this.customFields,
            confirm: values => this.confirmSort(values).pipe(tap(() => this.errorService.clearAll())).subscribe(),
            cancel: () => {
                this.showSortDialog$.next(false);
                this.errorService.clearAll();
            },
            open$: this.showSortDialog$,
            isLoading$: this.showSortLoading$,
        };
    }
}

componentConfig.controller = DvbVoreinstellungenCheckInCustomFields;
angular.module('kitAdmin').component('dvbVoreinstellungenCheckInCustomFields', componentConfig);
