/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class KindKontaktService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kindkontakte`;
    private static readonly HAUPTKONTAKTE_BASE_URL: string = `${KindKontaktService.BASE_URL}/hauptkontakte`;
    private static readonly ERZIEHUNGSBERECHTIGTE_BASE_URL: string =
        `${KindKontaktService.BASE_URL}/erziehungsberechtigte`;
    private static readonly SONSTIGE_KONTAKTE_BASE_URL: string = `${KindKontaktService.BASE_URL}/sonstigekontakte`;
    private static readonly ABHOLBERECHTIGT_BASE_URL: string = `${KindKontaktService.BASE_URL}/abholberechtigt`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public replaceHauptkontakt(kindId: string, kontaktpersonId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = KindKontaktService.HAUPTKONTAKTE_BASE_URL;

        return this.$http.post(url, this.kindKontaktRestObject(kindId, kontaktpersonId));
    }

    public createErziehungsberechtigter(kindId: string, kontaktpersonId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = KindKontaktService.ERZIEHUNGSBERECHTIGTE_BASE_URL;

        return this.$http.post(url, this.kindKontaktRestObject(kindId, kontaktpersonId));
    }

    public removeErziehungsberechtigter(erziehungsberechtigterId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KindKontaktService.ERZIEHUNGSBERECHTIGTE_BASE_URL}/` +
            `${encodeURIComponent(erziehungsberechtigterId)}`;

        return this.$http.delete(url);
    }

    public createAbholberechtigt(kindId: string, kontaktpersonId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = KindKontaktService.ABHOLBERECHTIGT_BASE_URL;

        return this.$http.post(url, this.kindKontaktRestObject(kindId, kontaktpersonId));
    }

    public removeAbholberechtigt(abholberechtigtId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KindKontaktService.ABHOLBERECHTIGT_BASE_URL}/${encodeURIComponent(abholberechtigtId)}`;

        return this.$http.delete(url);
    }

    public createSonstigerKontakt(kindId: string, kontaktpersonId: string): angular.IHttpPromise<unknown> {
        const url = KindKontaktService.SONSTIGE_KONTAKTE_BASE_URL;

        return this.$http.post(url, this.kindKontaktRestObject(kindId, kontaktpersonId));
    }

    public removeRelationships(kindId: string, kontaktpersonId: string): angular.IHttpPromise<unknown> {
        const matrixParams = {kindId, kontaktpersonId};

        const url = KindKontaktService.BASE_URL + DvbRestUtil.encodeMatrixParams(matrixParams);

        return this.$http.delete(url);
    }

    private kindKontaktRestObject(
        kindId: string,
        kontaktpersonId: string,
    ): { kindId: string; kontaktpersonId: string } {

        return {
            kindId,
            kontaktpersonId,
        };
    }
}
