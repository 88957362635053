<div class="wochen-zeitplan" ng-class="{'small': vm.small}" ng-style="{'height': vm.planHeight}">

    <div ng-repeat="dayOfWeek in vm.weekDays" class="tag">
        <div class="header" ng-bind="vm.printDayOfWeek(dayOfWeek)"></div>
        <div ng-repeat="zeitraumFeld in vm.zeitraumFelder[dayOfWeek]"
             class="zeitraum"
             ng-class="zeitraumFeld.cssClass"
             ng-style="{'flex-grow': zeitraumFeld.zeitraum.durationInMinutes}">
            <div class="feld"
                 ng-class="{'selected': zeitraumFeld.selected}"
                 uib-tooltip="{{zeitraumFeld.text}}"
                 tooltip-enable="{{zeitraumFeld.selected}}">
                <div class="times">
                    <span class="von" ng-if="zeitraumFeld.selected" ng-bind="zeitraumFeld.zeitraum.von.format('HH:mm')"></span>
                    <span class="bis" ng-if="zeitraumFeld.selected" ng-bind="zeitraumFeld.zeitraum.bis.format('HH:mm')"></span>
                </div>
                <div class="sections">
                    <div ng-repeat="section in zeitraumFeld.sections"
                         ng-style="{'background-color': section.color, 'flex-grow': section.durationInMinutes}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
