/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalHousehold} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anmeldung: '<',
    },
    template: require('./dvb-anmeldung-kind-display.html'),
    controllerAs: 'vm',
};

export class DvbAnmeldungKindDisplay implements angular.IController {

    public static $inject: readonly string[] = ['$translate'];

    public anmeldung?: ExternalHousehold;

    public constructor(
        public readonly $translate: angular.translate.ITranslateService,
    ) {
    }
}

componentConfig.controller = DvbAnmeldungKindDisplay;
angular.module('kitAdmin').component('dvbAnmeldungKindDisplay', componentConfig);
