<div class="row">
    <div class="col-xs-2 text-right">
        <p>{{leistungsPosition.anzahl}}</p>
    </div>
    <div class="col-xs-6">
        <p>{{ leistungsPosition.bezeichnung }}</p>
    </div>
    <div class="col-xs-2 text-right">
        <p>{{ leistungsPosition.betragProEinheit | number:'1.2-2' }}</p>
    </div>
    <div class="col-xs-2 text-right total-betrag">
        <p>{{ leistungsPosition.totalBetrag | number:'1.2-2' }}</p>
    </div>
</div>
