/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isNullish, isPresent, TimeRangeUtil} from '@dv/shared/code';
import type {CalendarEvent} from '../model/CalendarEvent';

export function eventSorter(a: CalendarEvent, b: CalendarEvent): number {
    if (isPresent(a.linkedEvent) && isPresent(b.linkedEvent)) {
        return TimeRangeUtil.TIME_RANGE_COMPARATOR(a, b);
    }

    if (isPresent(a.linkedEvent) && isNullish(b.linkedEvent)) {
        return 1;
    }

    if (isNullish(a.linkedEvent) && isPresent(b.linkedEvent)) {
        return -1;
    }

    return TimeRangeUtil.TIME_RANGE_COMPARATOR(a, b);
}
