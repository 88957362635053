/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Abholberechtigt, KindKontaktRelationship} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kindKontaktRelationship: '<',
        onCreateAbholberechtigt: '&',
        onDeleteAbholberechtigt: '&',
    },
    template: require('./dvb-abholberechtigt-input.html'),
    controllerAs: 'vm',
};

export class DvbAbholberechtigtInput implements angular.IController {

    public static $inject: readonly string[] = ['$scope'];

    public kindKontaktRelationship!: KindKontaktRelationship;
    public onCreateAbholberechtigt!: (data: { abholberechtigt: Abholberechtigt }) => void;
    public onDeleteAbholberechtigt!: (data: { abholberechtigt: Abholberechtigt }) => void;

    public isAbholberechtigt: boolean = false;

    public constructor($scope: angular.IScope) {
        $scope.$watch('vm.kindKontaktRelationship.abholberechtigt', () => {
            this.isAbholberechtigt = this.kindKontaktRelationship.isAbholberechtigt();
        });
    }

    public $onInit(): void {
        this.isAbholberechtigt = this.kindKontaktRelationship.isAbholberechtigt();
    }

    public changeAbholberechtigt(): void {
        if (this.kindKontaktRelationship.isAbholberechtigt()) {
            this.deleteAbholberechtigt();
        } else {
            this.createAbholberechtigt();
        }
    }

    private deleteAbholberechtigt(): void {
        const abholberechtigt = checkPresent(this.kindKontaktRelationship.abholberechtigt);
        this.kindKontaktRelationship.deleteAbholberechtigt();
        this.onDeleteAbholberechtigt({abholberechtigt});
    }

    private createAbholberechtigt(): void {
        this.kindKontaktRelationship.createAbholberechtigt();
        const abholberechtigt = checkPresent(this.kindKontaktRelationship.abholberechtigt);
        this.onCreateAbholberechtigt({abholberechtigt});
    }
}

componentConfig.controller = DvbAbholberechtigtInput;
angular.module('kitAdmin').component('dvbAbholberechtigtInput', componentConfig);
