<div class="dvb-kind-leistungen">
    <div class="row">
        <div class="col-lg-7 big-abstand-bottom">
            <div class="row">
                <div class="col-md-12">
                    <h2 data-translate="KIND.LEISTUNGEN.LEISTUNGEN"></h2>

                    <dvlib-date-switcher mode="year"
                                         class="normal-abstand-left"
                                         [(date)]="vm.leistungsjahr"
                                         [gueltig-ab]="vm.gueltigAb"
                                         [gueltig-bis]="vm.gueltigBis"
                                         (debounced-date-change)="vm.loadData()">
                    </dvlib-date-switcher>
                </div>
            </div>

            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading"></dvlib-spinner>

            <div ng-if="vm.leistungsrechnungenMonthly.length === 0 && !vm.isLoading">
                <p data-translate="COMMON.KEINE_LEISTUNGEN"></p>
            </div>
            <div class="leistungen-pro-monate">
                <div ng-repeat="leistungsrechnungMonthly in vm.leistungsrechnungenMonthly">
                    <dvb-kind-leistungen-pro-monat
                        kind="vm.kind"
                        start-of-month="leistungsrechnungMonthly.startOfMonth"
                        selected-month="vm.selectedStartOfMonth"
                        on-select="vm.onSelectMonth(startOfMonth)"
                        on-delete-fix-position="vm.deleteFixPosition(fixPosition)"
                        on-delete-eingewoehnung-position="vm.deleteEingewoehnungPosition(eingewoehnungPosition)"
                        kita-namen="vm.kitaNamen"
                        show-download="vm.isShowingDownloads"
                        leistungsrechnungen="leistungsrechnungMonthly.leistungsrechnungen"
                        on-delete-mutation-badge="vm.deleteMutationBadge(leistungsrechnungId)">
                    </dvb-kind-leistungen-pro-monat>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-lg-offset-1 hidden-print">
            <div dvb-auth require-permission="kita:manage:general">
                <h2 data-translate="KIND.GUTSCHRIFTEN_UND_GEBUEHREN"></h2>

                <p>
                    <a ui-sref="base.kind.gutschriften">
                        + <span data-translate="KIND.GUTSCHRIFT_ERFASSEN"></span>
                    </a>
                </p>
                <p>
                    <a ui-sref="base.kind.gebuehren">
                        + <span data-translate="KIND.GEBUEHR_ERFASSEN"></span>
                    </a>
                </p>
                <p>
                    <a ui-sref="base.kind.eingewoehnung">
                        + <span data-translate="KIND.EINGEWOEHNUNG_POSITION.ERFASSEN"></span>
                    </a>
                </p>
            </div>

            <div class="normal-abstand-top">
                <dvb-kind-leistungen-rechnungen kind="vm.kind"
                                                selected-month="vm.selectedStartOfMonth"
                                                leistungsrechnungen-monthly="vm.leistungsrechnungenMonthly"
                                                kita-namen="vm.kitaNamen">
                </dvb-kind-leistungen-rechnungen>
            </div>

            <div class="big-abstand-top dvb-form" ng-if="vm.mutationBadgeRefNrs.length > 0">
                <h2 data-translate="COMMON.LEISTUNGSRECHNUNG_WARNINGS.PENDENTE_VERFUEGUNG_MELDUNG.BADGES_OVERVIEW_TITLE"></h2>
                <div class="row selected-entity" ng-repeat="refNr in vm.mutationBadgeRefNrs">
                    <div class="col-xs-8">
                        <dvb-kibon-ref-nummer kibon-ref-nr-info="refNr"></dvb-kibon-ref-nummer>
                    </div>
                    <div class="col-xs-4 edit">
                        <button type="button"
                                class="btn btn-link"
                                ng-click="vm.deleteMutationBadgesForRefNr(refNr)"
                                data-translate="COMMON.LOESCHEN">
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- This is a hidden toggle for the download links -->
    <a accesskey="r" ng-click="vm.isShowingDownloads = !vm.isShowingDownloads"></a>
</div>
