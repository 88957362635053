/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {Password} from '@dv/kitadmin/models';
import {FormContext} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        onSubmit: '&',
    },
    template: require('./dvb-change-password.html'),
    controllerAs: 'vm',
};

class DvbChangePassword implements angular.IController {
    public static $inject: readonly string[] = ['$timeout', 'errorService', '$element'];

    public onSubmit!: (params: { password: Password; formContext: FormContext }) => any;

    public formContext: FormContext = new FormContext();
    public password: Password = new Password();

    public constructor(
        private $timeout: angular.ITimeoutService,
        private errorService: ErrorService,
        private $element: JQLite,
    ) {
    }

    public togglePasswordForm(): void {
        if (!this.formContext.editMode) {
            this.$timeout(() => {
                this.$element.find('.current-password-field')[0].focus();
            }, 0);
        }
        this.formContext.editMode = !this.formContext.editMode;
    }

    public validateAndSubmitPassword(isFormValid: boolean): void {
        this.errorService.handleValidationError(isFormValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const strongPassword = Password.validate(this.password.newPassword);
        this.errorService.handleValidationError(strongPassword, 'ERRORS.ERR_STRONG_PASSWORD');

        const notIdentPassword = this.password.currentPassword !== this.password.newPassword;
        this.errorService.handleValidationError(notIdentPassword, 'ERRORS.ERR_IDENTICAL_PASSWORD');

        const confirmPassword = this.password.newPassword === this.password.confirmedPassword;
        this.errorService.handleValidationError(confirmPassword, 'ERRORS.ERR_DIFFERENT_PASSWORD');

        if (isFormValid && notIdentPassword && confirmPassword && strongPassword) {
            this.onSubmit({password: this.password, formContext: this.formContext});
        }
    }
}

componentConfig.controller = DvbChangePassword;
angular.module('kitAdmin').component('dvbChangePassword', componentConfig);
