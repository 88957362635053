/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import type moment from 'moment';
import {Schliesstage} from './Schliesstage';

export class KinderOrtSchliesstage implements IPersistable, IRestModel, ILimited {

    public constructor(
        public id: string | null = null,
        public kinderOrtId: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public schliesstageId: string | null = null,
        public schliesstage: Schliesstage | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KinderOrtSchliesstage {
        const gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);

        const kinderOrtSchliesstage = new KinderOrtSchliesstage(
            data.id,
            data.kinderOrtId,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            gueltigBis ?? END_OF_TIME,
            data.schliesstageId,
        );

        if (data.schliesstage) {
            kinderOrtSchliesstage.schliesstage = Schliesstage.apiResponseTransformer(data.schliesstage);
            kinderOrtSchliesstage.schliesstageId = kinderOrtSchliesstage.schliesstage.id;
        }

        return kinderOrtSchliesstage;
    }

    public isValid(): boolean {
        return this.kinderOrtId !== null &&
            DvbDateUtil.isValidMoment(this.gueltigAb) &&
            DvbDateUtil.isValidMoment(this.gueltigBis);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            kinderOrtId: this.kinderOrtId,
            schliesstageId: this.schliesstage?.id,
        };
    }
}
