/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {BlobInfo} from '@dv/kitadmin/models';
import {ButtonComponent, IconComponent, LoadingButtonComponent, trackByIdentity} from '@dv/shared/angular';
import {checkPresent} from '@dv/shared/code';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BehaviorSubject} from 'rxjs';
import {DownloadService} from '../../download/download.service';
import {ITimelineEntry} from '../ITimelineEntry';
import {ITimelineService} from '../ITimelineService';

@Component({
    selector: 'dv-attachments-download',
    standalone: true,
    imports: [CommonModule, BsDropdownModule, LoadingButtonComponent, IconComponent, ButtonComponent],
    templateUrl: './attachments-download.component.html',
    styleUrls: ['./attachments-download.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsDownloadComponent {
    @Input({required: true}) public timelineService!: ITimelineService<any>;
    @Input({required: true}) public timelineEntry!: ITimelineEntry;

    private readonly isLoadingSubject$ = new BehaviorSubject(false);
    public readonly isLoading$ = this.isLoadingSubject$.asObservable();

    public trackByIdentity = trackByIdentity;

    public constructor(
        private readonly downloadService: DownloadService,
    ) {
    }

    public downloadAttachment(blobInfo: BlobInfo): void {
        this.isLoadingSubject$.next(true);

        this.timelineService.getAttachment$(checkPresent(this.timelineEntry.id), blobInfo.id)
            .subscribe({
                next: tempBlob => this.downloadService.downloadFileByUrl(tempBlob),
                complete: () => {
                    this.isLoadingSubject$.next(false);
                },
            });
    }
}
