<div class="dvb-named-entity-notizen">
    <div class="row">

        <div class="col-md-7">
            <h2 data-translate="NOTIZ.PLURAL"></h2>

            <div class="dvb-timeline scroll-wrapper small-abstand-top">
                <div ui-scroll="notiz in vm.dataSource"
                     adapter="vm.adapter"
                     start-index="0"
                     buffer-size="20"
                     padding="1">
                    <div ng-if="notiz"
                         class="timeline-entry"
                         ng-class="{'last': vm.adapter.bufferLast.id === notiz.id}">
                        <dvb-timeline-entry-icon entry="notiz"
                                                 timeline-controller="vm.timelineController">
                        </dvb-timeline-entry-icon>
                        <dvb-timeline-entry-details class="full-width-block"
                                                    entry="notiz"
                                                    timeline-controller="vm.timelineController">
                            <dvb-notiz-timeline-details notiz="notiz"></dvb-notiz-timeline-details>
                        </dvb-timeline-entry-details>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-md-offset-1" dvb-auth require-permission="{{vm.notizCreateState.data.permissions}}">
            <h2 data-translate="COMMON.AKTIONEN"></h2>

            <p>
                <a ui-sref="{{vm.notizCreateState.name + '({entityId: vm.entityId, entityType: vm.entityType})'}}">
                    + <span data-translate="NOTIZ.CREATE_NOTIZ"></span></a>
            </p>
        </div>

    </div>
</div>
