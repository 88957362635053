/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').directive('dvbProtocolPrepender', dvbProtocolPrepender);

function dvbProtocolPrepender(): angular.IDirective {
    return {
        restrict: 'A',
        require: {ngModelCtrl: 'ngModel'},
        link(scope, element, attrs, ctrl: any) {
            ctrl.ngModelCtrl.$parsers.push(prependProtocol);
        },
    };

    function prependProtocol(viewValue: string): string {
        if (!viewValue) {
            return viewValue;
        }

        const protocolRegex = /^https?:\/\//g;

        const trimmedValue = viewValue.trim();

        if (!protocolRegex.test(trimmedValue)) {
            return `https://${trimmedValue}`;
        }

        return viewValue;
    }
}
