<div class="dvb-konto-form">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="bezeichnung"
                   class="form-control"
                   maxlength="255"
                   ng-attr-placeholder="{{'FAKTURA.KONTO.BEZEICHNUNG' | translate}}"
                   uib-tooltip="{{'FAKTURA.KONTO.BEZEICHNUNG' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.konto.bezeichnung">
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="owner"
                   class="form-control"
                   maxlength="255"
                   ng-attr-placeholder="{{'FAKTURA.KONTO.KONTOINHABER' | translate}}"
                   uib-tooltip="{{'FAKTURA.KONTO.KONTOINHABER' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.konto.owner">
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="iban"
                   class="form-control"
                   maxlength="26"
                   ng-attr-placeholder="{{'FAKTURA.KONTO.IBAN' | translate}}"
                   uib-tooltip="{{'FAKTURA.KONTO.IBAN' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.konto.iban">
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="bic"
                   class="form-control"
                   maxlength="11"
                   ng-attr-placeholder="{{'FAKTURA.KONTO.BIC' | translate}}"
                   uib-tooltip="{{'FAKTURA.KONTO.BIC' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.konto.bic">
        </div>
    </div>
</div>
