<div class="search-input dvb-search-entity">
    <div class="input-group form-row" [class.hidden]="!alwaysShowInput && ngControl?.control?.value">
        <input type="text"
               class="form-control"
               #textInput
               [formControl]="typeAheadControl"
               [placeholder]="placeholder"
               [attr.aria-label]="placeholder"
               [tooltip]="placeholder"
               [typeahead]="typeahead$"
               (typeaheadOnSelect)="selectSource.next($event)"
               (typeaheadOnBlur)="onTouched?.()"
               [optionsListTemplate]="searchPreviewTemplate"
               [typeaheadOptionField]="'text'"
               [typeaheadIsFirstItemActive]="hasText()"
               [typeaheadSelectFirstItem]="hasText()"
               [typeaheadMinLength]="0"
               [typeaheadWaitMs]="0">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
    </div>

    @if (ngControl?.control; as control) {
        @if (!alwaysShowInput && control.value) {
            <dv-search-entity-result
                [showRemoveButton]="true"
                [searchResultEntry]="control.value"
                [isDisabled]="control.disabled"
                (remove)="onRemove()">
            </dv-search-entity-result>
        }
    }
</div>

<ng-template #searchPreviewTemplate let-matches="matches" let-typeaheadTemplateMethods>
    <ul class="dropdown-menu">
        @for (match of matches; track trackById($index, match)) {
            <li [class.active]="typeaheadTemplateMethods.isActive(match)">
                @if (getItem(match); as item) {
                    <a href=""
                       (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
                       (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
                       [ngClass]="{'disabled': item.isDisabled}"
                       [attr.disabled]="item.isDisabled">
                        <dv-search-result-icon [entry]="item"/>
                        <span [innerHTML]="item.text"></span>
                        <!-- das CSS erlaubt nur 1 Badge: mehrere würden übereinander positioniert -->
                        @if (item.mandantName && !item.isDisabled) {
                            <span class="badge search" title="{{ item.mandantName }}">
                                {{ item.mandantName }}
                            </span>
                        }
                        @if (!item.mandantName && item.additionalInformation && !item.isDisabled) {
                            <span class="badge search" title="{{ item.additionalInformation }}">
                                {{ item.additionalInformation }}
                            </span>
                        }
                        @if (item.isDisabled) {
                            <span class="badge search"
                                  [translate]="'COMMON.HINZUGEFUEGT'"
                                  [tooltip]="'COMMON.BEREITS_HINZUGEFUEGT' | translate"
                                  [placement]="'bottom'">
                            </span>
                        }
                    </a>
                }
            </li>
        }
    </ul>
</ng-template>
