/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KeyValuePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    DayOfWeekFormComponent,
    formViewProvider,
    PrintDayOfWeekComponent,
    ValidBisDirective,
    ValidVonDirective,
} from '@dv/shared/angular';
import {DvbDateUtil} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DayOfWeekAnstellungen} from '../../../models/anstellung-form-model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-angestellte-anstellung-zeiten',
    standalone: true,
    imports: [
        KeyValuePipe,
        TooltipModule,
        ValidVonDirective,
        ReactiveFormsModule,
        FormsModule,
        ValidBisDirective,
        TranslateModule,
        PrintDayOfWeekComponent,
        DayOfWeekFormComponent,
    ],
    host: {
        '[class.wochenplan]': 'true',
    },
    templateUrl: './angestellte-anstellung-zeiten.component.html',
    styleUrls: ['./angestellte-anstellung-zeiten.component.scss'],
    viewProviders: [formViewProvider],
})

export class AngestellteAnstellungZeitenComponent {
    public readonly today = DvbDateUtil.today();

    @Input({required: true}) public dailyAnstellungen!: { [day: string]: DayOfWeekAnstellungen };

    public addVonBis(tag: string): void {
        this.dailyAnstellungen[tag].zeiten ??= [];
        this.dailyAnstellungen[tag].zeiten.push({});
        this.dailyAnstellungen[tag].nichtVerfuegbar = false;
    }

    public removeVonBis(tag: string, index: number): void {
        this.dailyAnstellungen[tag].zeiten.splice(index, 1);
    }

    public onCheckboxChange(tag: string): void {
        const element = this.dailyAnstellungen[tag];
        if (element.nichtVerfuegbar) {
            element.zeiten = [];
        }
    }
}
