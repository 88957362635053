/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AbstractKontaktpersonZahlung,
    CustomRechnungsPosition,
    EmailVersandProperties,
    FinishRechnungsLauf,
    Mahnlauf,
    RechnungsLauf,
    RechnungsStatus,
    RestCache,
    RestTimeout,
} from '@dv/kitadmin/models';
import {
    BulkRechnungEmail,
    DisplayTask,
    FakturaMassenaktionError,
    Rechnung,
    RechnungsLaufEntry,
    RechnungsStatusResponse,
    Rueckerstattung,
    TempBlob,
    Vorauszahlung,
    Zahlung,
    Zahlungen,
    ZahlungType,
} from '@dv/kitadmin/models';
import {OffenerPostenType} from '@dv/shared/backend/model/offener-posten-type';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {NoContent, Persisted, RestInclude, RestLimited, RestPaginated} from '@dv/shared/code';
import {AsyncResponse, checkPersisted, checkPresent, DvbRestUtil, isPresent, PageContainer} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../config';
import type {RechnungenFilter} from '../../../filter/RechnungenFilter';
import type {RechnungenForKontaktpersonZahlung} from '../../../filter/RechnungenFilter-kontaktperson';
import {rechnungenForKontaktpersonZahlung} from '../../../filter/RechnungenFilter-kontaktperson';
import type {AsyncResponseService} from './asyncResponseService';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class FakturaService {
    public static $inject: readonly string[] = ['asyncResponseService', '$http', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/faktura`;
    private static readonly RECHNUNGEN_URL: string = `${FakturaService.BASE_URL}/rechnungen`;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private static readonly REQUEST_TIMEOUT: number = 4 * 60 * 1000; // 4 minutes

    public constructor(
        private asyncResponseService: AsyncResponseService,
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) {
    }

    public createRechnungsLauf(
        rechnungsLauf: RechnungsLauf,
        params?: RestTimeout & RestInclude,
    ): angular.IPromise<RechnungsLaufEntry[]> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${FakturaService.BASE_URL}/rechnungslauf`;
        const matrixParams = {
            includes: params?.includes ? params.includes : undefined,
        };

        return DvbRestUtilAngularJS.postAndGetModel(
            url,
            rechnungsLauf.toRestObject(),
            AsyncResponse,
            matrixParams,
            false,
        ).then(response =>
            this.asyncResponseService.startPollingForModelArray(response.id, RechnungsLaufEntry, 'entries', params));
    }

    public finishRechnungsLauf(
        finishRechnungsLaufObj: FinishRechnungsLauf,
        params?: RestTimeout,
    ): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${FakturaService.BASE_URL}/rechnungslauf/finish`;

        return this.$http.post(url, finishRechnungsLaufObj.toRestObject(), {
            timeout: params?.timeout ? params.timeout : FakturaService.REQUEST_TIMEOUT,
        });
    }

    public getTempBlobForRechnung(rechnungId: string): angular.IPromise<TempBlob | NoContent> {
        return DvbRestUtilAngularJS.getModelByUrlAndParams(
            `${FakturaService.RECHNUNGEN_URL}/${rechnungId}/pdf`,
            TempBlob,
        );
    }

    public getTempBlobForRechnungsRevision(revisionId: string): angular.IPromise<TempBlob | NoContent> {
        const url = `${FakturaService.BASE_URL}/rechnungsrevisionen/${revisionId}/pdf`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob);
    }

    public getRechnung(rechnungId: string, params?: RestInclude & RestCache): angular.IPromise<Persisted<Rechnung>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(FakturaService.RECHNUNGEN_URL, Rechnung, rechnungId, params)
            .then(checkPersisted);
    }

    public getRechnungen(
        filter: RechnungenFilter,
        params?: RestPaginated & RestInclude,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<Rechnung>> {
        const matrixParams = angular.extend(filter.toRestObject(), params);

        return DvbRestUtilAngularJS.getPagedItems(FakturaService.RECHNUNGEN_URL, Rechnung, matrixParams, config);
    }

    public getRechnungenToAssignZahlung(
        options: RechnungenForKontaktpersonZahlung,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<Rechnung>> {
        const filter = rechnungenForKontaktpersonZahlung(options);
        const params = {
            includes: '(nestedIncludes.fields(ausstehenderBetrag,emailVersandHistory))',
        };

        return this.getRechnungen(filter, params, config).then(container => {
            container.items = container.items
                // for good measure: filter out current rechnung
                .filter(item => item.id !== options.id);

            return container;
        });
    }

    public changeRechnungsStatus(rechnungId: string, status: RechnungsStatus): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/externalstatus`;

        return this.$http.put(url, status.toRestObject());
    }

    public storniereFilteredRechnungen(
        kinderOrtId: string,
        params: RestLimited & {
            states?: string[];
            kitaIds?: string[];
            kontaktpersonenIds?: string[];
            abgelaufen?: boolean;
        },
    ): angular.IPromise<FakturaMassenaktionError[]> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${FakturaService.BASE_URL}/stornieren/${kinderOrtId}`;

        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return this.$http.put<{ [key: string]: FakturaMassenaktionError[] }>(
            url + DvbRestUtil.encodeMatrixParams(matrixParams), undefined)
            .then(response => {
                if (!Array.isArray(response.data.items)) {
                    return this.$q.resolve([]);
                }

                return response.data.items.map(err => FakturaMassenaktionError.apiResponseTransformer(err));
            });
    }

    public executeMahnlauf(mahnlauf: Mahnlauf, params?: RestTimeout): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FakturaService.BASE_URL}/mahnlauf`;

        return this.$http.post(url, mahnlauf.toRestObject(), {
            timeout: params?.timeout ? params.timeout : FakturaService.REQUEST_TIMEOUT,
        });
    }

    public getRelatedRechnungen(rechnungId: string): angular.IPromise<Rechnung[]> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}`;

        return DvbRestUtilAngularJS.getModelsArray(`${url}/related`, Rechnung, 'items');
    }

    public getRechnungsRevisionen(rechnungId: string): angular.IPromise<Rechnung[]> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}`;

        return DvbRestUtilAngularJS.getModelsArray(`${url}/revisionen`, Rechnung, 'items');
    }

    public getRechnungsStatus(matrixParams: { idList: string[] }): angular.IPromise<RechnungsStatusResponse> {
        const url = `${FakturaService.RECHNUNGEN_URL}/status`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, RechnungsStatusResponse, matrixParams)
            .then(checkPresent);
    }

    public saveCustomRechnungsPositionen(
        rechnungId: string,
        customPositionen: CustomRechnungsPosition[],
    ): angular.IPromise<unknown> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/positions/custom`;
        const restPositions = customPositionen.map(pos => pos.toRestObject());

        return this.$http.put(url, {positionen: restPositions});
    }

    public createZahlung(rechnungId: string, zahlung: Zahlung): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/zahlungen`;

        return this.$http.post(url, zahlung.toRestObject());
    }

    public storniereZahlung(zahlungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FakturaService.BASE_URL}/zahlungen/${encodeURIComponent(zahlungId)}`;

        return this.$http.delete(url);
    }

    public getZahlungen(
        rechnungId: string,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Zahlungen> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/zahlungen`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Zahlungen, {}, config)
            .then(checkPresent);
    }

    public saveRechnungBemerkung(rechnungId: string, bemerkung: string): angular.IPromise<unknown> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/bemerkung`;

        return this.$http.put(url, bemerkung);
    }

    public saveZahlungsFrist(rechnungId: string, zahlungsFrist: moment.Moment): angular.IPromise<unknown> {
        const payload = {
            date: DvbRestUtil.momentToLocalDate(zahlungsFrist),
        };
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/zahlungsfrist`;

        return this.$http.put(url, payload);
    }

    public bucheRechnungsUeberschussUm(
        rechnungId: string,
        auszugleichendeRechnungId: string,
        zahlung: Zahlung,
    ): angular.IPromise<unknown> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}` +
            `/ausgleichen/${encodeURIComponent(auszugleichendeRechnungId)}`;

        return this.$http.put(url, zahlung.toRestObject());
    }

    public initAusgleichszahlung(
        date: moment.Moment,
        zahlungsType: OffenerPostenType,
        uebertrag: number,
        kitaId: string,
    ): Zahlung {
        const zahlung = new Zahlung();
        zahlung.dtype = RechnungsKonfigurationType.DVB;
        zahlung.datum = date;
        zahlung.type = ZahlungType.AUSGLEICHSZAHLUNG;
        zahlung.betrag = -uebertrag;

        if (zahlungsType === OffenerPostenType.RUECKERSTATTUNG) {
            zahlung.ausgleichsRueckerstattung = this.applyKontaktpersonZahlung(new Rueckerstattung(),
                date,
                kitaId,
                uebertrag);
        } else if (zahlungsType === OffenerPostenType.VORAUSZAHLUNG) {
            zahlung.ausgleichsVorauszahlung = this.applyKontaktpersonZahlung(new Vorauszahlung(),
                date,
                kitaId,
                uebertrag);
        }

        return zahlung;
    }

    public applyKontaktpersonZahlung<T extends AbstractKontaktpersonZahlung>(
        kontaktpersonZahlung: T,
        date: moment.Moment,
        kitaId: string,
        uebertrag: number,
    ): T {
        kontaktpersonZahlung.kitaId = kitaId;
        kontaktpersonZahlung.datum = date;
        kontaktpersonZahlung.betrag = uebertrag;

        return kontaktpersonZahlung;
    }

    public sendRechnungEmail(
        rechnungId: string,
        emailVersandProperties: EmailVersandProperties,
    ): angular.IPromise<unknown> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/sendmail`;

        return this.$http.put(url, emailVersandProperties.toRestObject());
    }

    public sendFilteredRechnungen(
        kinderOrtId: string,
        emailVersandProperties: EmailVersandProperties,
        params: RechnungenFilter,
    ): angular.IPromise<BulkRechnungEmail[]> {

        const url = `${FakturaService.RECHNUNGEN_URL}/sendmassenmail/${kinderOrtId}`;
        const matrixParams = DvbRestUtil.toMatrixParams(params.toRestObject());

        return this.$http.put(url + DvbRestUtil.encodeMatrixParams(matrixParams), emailVersandProperties.toRestObject())
            .then(response => PageContainer.apiResponseTransformer(BulkRechnungEmail, response.data))
            .then(page => page.items);
    }

    public getDeliveryFailureTask(rechnungId: string): angular.IPromise<DisplayTask> {
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(rechnungId)}/deliveryfailuretask`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, DisplayTask)
            .then(checkPresent);
    }

    public removeRechnungDeliveryFailureTask(rechnungId: string): angular.IPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FakturaService.RECHNUNGEN_URL}/${encodeURIComponent(checkPresent(rechnungId))}/deliveryfailuretask`;

        return this.$http.delete(url);
    }

    public getTempBlobForGemoWinRechnung(rechnungId: string): angular.IPromise<TempBlob | NoContent> {
        return DvbRestUtilAngularJS.getModelByUrlAndParams(
            `${FakturaService.RECHNUNGEN_URL}/${rechnungId}/export/gemowin/storno`,
            TempBlob,
        );
    }
}
