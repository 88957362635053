<div class="dvb-rechnung-uebersicht">
    <div class="row">
        <div class="col-lg-7 big-abstand-bottom">
            <h2 data-translate="FAKTURA.RECHNUNGS_POSITIONEN" class="no-margin"></h2>

            <div class="row">
                <div class="col-xs-12">
                    <dvb-rechnung-detail rechnung="vm.rechnung"
                                         mode="vm.mode"
                                         flat-layout="true">
                    </dvb-rechnung-detail>
                </div>
            </div>
            <div class="row big-abstand-top">
                <div class="col-xs-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="button" is-loading="vm.isDownloading" ng-click="vm.downloadPdf()">
                                <span data-translate="FAKTURA.AKTUELLE_RECHNUNG_HERUNTERLADEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li>
                            <a href=""
                               ng-click="vm.downloadOriginalPdf()"
                               data-translate="FAKTURA.ORIGINAL_RECHNUNG_HERUNTERLADEN">
                            </a>
                            <dvlib-spinner [show]="vm.isOriginalDownloading"></dvlib-spinner>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row big-abstand-top">
                <div class="col-xs-12">
                    <h2 data-translate="COMMON.BEMERKUNG"></h2>
                    <dvb-editable-text-area ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                            ng-model="vm.rechnung.bemerkung"
                                            maxlength="1000"
                                            on-submit="vm.saveBemerkung()"
                                            dvb-auth
                                            require-permission="{{'kita:manage:' + vm.rechnung.kitaId}}"
                                            on-unauthorised-disable>
                    </dvb-editable-text-area>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-lg-offset-1">
            <div ng-class="{'normal-abstand-bottom': vm.rechnung.vorgaengerRechnung || vm.rechnung.folgeRechnung}">
                <h2 data-translate="FAKTURA.DETAILS"></h2>

                <p data-translate="FAKTURA.RECHNUNGSDATUM_DATUM"
                   data-translate-value-datum="{{vm.rechnung.rechnungsDatum | amDateFormat:'D.M.YYYY'}}"></p>
                <p data-translate="FAKTURA.FAELLIG_AM_DATUM_LABEL"
                   data-translate-value-datum="{{vm.rechnung.faelligAm | amDateFormat:'D.M.YYYY'}}"></p>
                <p ng-if="vm.isDueDateDeviating"
                   data-translate="FAKTURA.ZAHLUNGSFRIST_DATUM_LABEL"
                   data-translate-value-datum="{{vm.rechnung.zahlungsFrist | amDateFormat: 'D.M.YYYY'}}"></p>
                <p>
                    <span data-translate="FAKTURA.EMPFAENGER_LABEL"></span>
                    <a ui-sref="base.kontaktperson.profil({kontaktpersonId: {{vm.rechnung.kontaktpersonId}} })"
                       ng-bind="vm.rechnung.kontaktpersonDisplayName"></a>
                </p>
                <p>
                    <span ng-bind="vm.rechnung.kitaTitel || ('COMMON.KINDERORT.SINGULAR' | translate)"></span>:
                    <a ui-sref="base.kinderort.uebersicht({id: {{vm.rechnung.kitaId}} })"
                       ng-bind="vm.rechnung.kitaName"></a>
                </p>
                <p ng-if="vm.rechnung.kinder.length > 0">
                    <span data-translate="COMMON.KIND.MF"
                          data-translate-value-count="{{vm.rechnung.kinder.length}}"
                          data-translate-interpolation="messageformat">
                    </span>:
                    <a class="kind-list-element"
                       ui-sref="base.kind.profil({kindId: kind.id})"
                       ng-repeat="kind in vm.rechnung.kinder | orderBy:['familienName', 'vorName']"
                       ng-bind="kind.getDisplayName()">
                    </a>
                </p>
            </div>

            <div class="vorgaenger-rechnung" ng-if="vm.rechnung.vorgaengerRechnung">
                <p>
                    <span data-translate="FAKTURA.ERSETZT_RECHNUNG"></span>
                    <a ui-sref="base.rechnung.uebersicht({ rechnungId: vm.rechnung.vorgaengerRechnung.id })"
                       ng-bind="vm.rechnung.vorgaengerRechnung.rechnungsNummer">
                    </a>
                </p>
            </div>

            <div class="folge-rechnung" ng-if="vm.rechnung.folgeRechnung">
                <p>
                    <span data-translate="FAKTURA.IST_ERSETZTE_RECHNUNG"></span>
                    <a ui-sref="base.rechnung.uebersicht({ rechnungId: vm.rechnung.folgeRechnung.id })"
                       ng-bind="vm.rechnung.folgeRechnung.rechnungsNummer">
                    </a>
                </p>
            </div>

            <div class="big-abstand-top"
                 dvb-auth
                 require-permission="{{'kita:manage:' + vm.rechnung.kitaId}}"
                 require-condition="vm.rechnung.isStornierbar">
                <h2 data-translate="COMMON.AKTIONEN"></h2>

                <div class="btn-group" uib-dropdown data-keyboard-nav>
                    <dvb-loading-button type="button"
                                        is-loading="vm.isStatusChanging"
                                        dropdown="true"
                                        margin="true"
                                        as-link="true"
                                        uib-dropdown-toggle>
                        <span data-translate="FAKTURA.STATUS_AENDERN"></span>
                        <span class="caret"></span>
                    </dvb-loading-button>
                    <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                        <li ng-repeat="status in vm.availableStateTransitions">
                            <a href="" ng-click="vm.changeState(status)">
                                <i class="status-icon" ng-class="status.icon"></i>
                                <span data-translate="{{status.msgKey}}"></span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div>
                    <button type="button"
                            class="btn btn-link"
                            ng-if="vm.rechnung.status !== vm.STATUS.STORNIERT"
                            ng-click="vm.changeZahlungsFirst()"
                            data-translate="FAKTURA.ZAHLUNGSFRIST_AENDERN">
                    </button>
                </div>

                <div class="small-abstand-top">
                    <button type="button"
                            class="btn btn-link"
                            data-translate="FAKTURA.RECHNUNGSGEBUEHREN_BEARBEITEN"
                            ng-click="vm.editGebuehren()">
                    </button>
                </div>
            </div>
            <div class="big-abstand-top">
                <h2 data-translate="COMMON.VERSAND"></h2>

                <dvb-rechnung-versand-status rechnung="vm.rechnung">
                </dvb-rechnung-versand-status>
            </div>
            <div class="big-abstand-top">
                <h2 data-translate="FAKTURA.STATUS_VERLAUF"></h2>

                <dvb-rechnungs-status-history state-history="vm.rechnung.stateHistory">
                </dvb-rechnungs-status-history>
            </div>
            <div class="big-abstand-top" ng-if="vm.deliveryFailureTask">
                <h2 data-translate="FAKTURA.TASK_VERLAUF"></h2>

                <dv-display-task [task]="vm.deliveryFailureTask">
                </dv-display-task>
                <div class="small-abstand-top">
                    <a data-translate="COMMON.LOESCHEN"
                       href=""
                       ng-click="vm.removeDeliveryFailureTask()"></a>
                </div>
            </div>
        </div>
    </div>
</div>
