/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {EmailVersandProperties} from '@dv/kitadmin/models';
import type {SteuerbescheinigungStructure} from '../../../report/models/ParameterSteuerbescheinigungen';

export class SendSteuerbescheinigung extends EmailVersandProperties {

    public constructor(
        public jahr: number,
        public kinderOrtId: string,
        public dokumentLayoutId: string | null,
        public kontaktpersonenIds: string[] = [],
        public structure: SteuerbescheinigungStructure,
    ) {
        super(null, null, true);
    }

    public static copy(other: SendSteuerbescheinigung): SendSteuerbescheinigung {
        const result = new SendSteuerbescheinigung(other.jahr,
            other.kinderOrtId,
            other.dokumentLayoutId,
            other.kontaktpersonenIds,
            other.structure);

        result.sendEmails = other.sendEmails;
        result.replyToAddress = other.replyToAddress;
        result.bccAddress = other.bccAddress;

        return result;
    }

    public override toRestObject(): Record<string, unknown> {
        return {
            ...super.toRestObject(false),
            jahr: this.jahr,
            kinderOrtId: this.kinderOrtId,
            dokumentLayoutId: this.dokumentLayoutId,
            kontaktpersonenIds: this.kontaktpersonenIds,
            structure: this.structure,
        };
    }
}
