/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    CustomField,
    FirmenKontingent,
    KinderOrtFraktion,
    Kontingente,
    Pensum,
    SubventioniertesKontingent,
} from '@dv/kitadmin/models';
import type {IPersistable} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, Gueltigkeit} from '@dv/shared/code';
import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import {WochenplanUtil} from '../../../common/service/wochenplanUtil';
import {PopoverFilterOption} from '../../../filter/popover/PopoverFilterOption';
import type {PopoverFilterPropertyGueltigkeit} from '../../../filter/popover/PopoverFilterPropertyGueltigkeit';
import type {PopoverFilterPropertyPensum} from '../../../filter/popover/PopoverFilterPropertyPensum';
import {FilterOption} from '../../../filter/shared/FilterOption';
import type {AgeFilterOption} from '../../service/kinderFilter-models/AgeFilterOption';
import {CustomFieldFilterOption} from '../../service/kinderFilter-models/CustomFieldFilterOption';
import {BOOLEAN_FILTER_PROPS} from '../../service/kinderFilter-models/FilterProperties';
import {KindFilterOption} from '../../service/kinderFilter-models/KindFilterOption';
import {MinMaxFilterProperty} from '../../service/kinderFilter-models/MinMaxFilterProperty';
import {NumberFilterOption} from '../../service/kinderFilter-models/NumberFilterOption';
import {PlaetzeFilterOption} from '../../service/kinderFilter-models/PlaetzeFilterOption';
import type {KinderFilterService} from '../../service/kinderFilterService';
import type {KinderOrtWocheService} from '../../service/kinderOrtWocheService';
import {CustomFieldsFilter} from './CustomFieldsFilter';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        firmenKontingente: '<',
        subventioniertesKontingent: '<',
        gruppen: '<',
        kitaId: '<',
        firstOfWeek: '<',
        customFields: '<',
    },
    controllerAs: 'vm',
    template: require('./dvb-belegungsplan-filter.html'),
};

/* eslint-disable max-lines */
export class DvbBelegungsplanFilter {

    public static $inject: readonly string[] = [
        '$translate',
        '$q',
        'kinderOrtWocheService',
        'kinderFilterService',
        'kinderOrtService',
        '$scope',
        '$uiRouterGlobals',
    ];

    public firmenKontingente: FirmenKontingent[] = [];
    public subventioniertesKontingent: SubventioniertesKontingent | null = null;
    public gruppen: KinderOrtFraktion[] = [];
    public kitaId!: string;
    public firstOfWeek!: moment.Moment;
    public customFields: CustomField[] = [];

    public filterCollapsed: boolean = true;
    public privatePlaetzeSelected: boolean = false;
    public hasSubventioniertePlaetze: boolean = false;
    public subventioniertePlaetze: number | null = null;
    public subventioniertePlaetzeSelected: boolean = false;
    public verfuegbarePlaetze: boolean = false;
    public provisorisch: KindFilterOption | null = null;
    public angebot: KindFilterOption | null = null;
    public definitiv: KindFilterOption | null = null;
    public keineZuweisung: KindFilterOption | null = null;
    public firmenKontingentFilters: PlaetzeFilterOption[] = [];
    public gruppenFilters: FilterOption[] = [];
    public ageFilters: AgeFilterOption[] = [];
    public kindergartenFilter: KindFilterOption[] = [];
    public customFieldsFilter: CustomFieldsFilter = new CustomFieldsFilter();
    public geschwisterFilter: KindFilterOption | null = null;
    public pensumFilter: PopoverFilterOption<PopoverFilterPropertyPensum, MinMaxFilterProperty, Pensum> | null = null;
    public anmeldeFilter: PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit> | null = null;
    public prioritaetsFilter: NumberFilterOption[] = [];
    public showPrioFilter: boolean = false;

    public constructor(
        private $translate: angular.translate.ITranslateService,
        private $q: angular.IQService,
        private kinderOrtWocheService: KinderOrtWocheService,
        private kinderFilterService: KinderFilterService,
        private kinderOrtService: KinderOrtService,
        $scope: angular.IScope,
        $uiRouterGlobals: UIRouterGlobals,
    ) {

        $scope.$watch(() => $uiRouterGlobals.current.name, (newVal, _oldVal) => {
            this.showPrioFilter = newVal === 'base.kinderort.kinder.warteliste';
        });
    }

    private static hasWeekChanged(firstOfWeekChange: angular.IChangesObject<moment.Moment>): boolean {
        return firstOfWeekChange &&
            !firstOfWeekChange.isFirstChange() &&
            !DvbDateUtil.isMomentEquals(firstOfWeekChange.currentValue, firstOfWeekChange.previousValue);
    }

    /**
     * Sets Selected and Sticky to TRUE when referenceId is found in selectedIds.
     */
    private static updateSelectionAndStickyness(
        selectedIds: string[],
        filterOption: FilterOption,
        referenceId: string,
    ): void {
        if (selectedIds.includes(referenceId)) {
            filterOption.selected = true;
            filterOption.sticky = true;
        } else {
            filterOption.selected = false;
        }
    }

    public $onInit(): void {
        this.privatePlaetzeSelected = this.kinderFilterService.isPrivaterPlatz();
        this.initStatusFilter();
        this.initFirmenKontingente();
        this.initSubventioniertesKontingent();
        this.initGruppen();
        this.initAgeFilter();
        this.initVerfuegbarFilter();
        this.initKindergartenFilter();
        this.initCustomFieldFilter();
        this.initGeschwisterFilter();
        this.initAnmeldeFilter();
        this.initPensumFilter();
        this.initPrioritaetFilter();
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.gruppen && !changes.gruppen.isFirstChange()) {
            this.initGruppen();
        }

        if (changes.firmenKontingente && !changes.firmenKontingente.isFirstChange()) {
            this.initFirmenKontingente();
        }

        if (changes.subventioniertesKontingent && !changes.subventioniertesKontingent.isFirstChange()) {
            this.initSubventioniertesKontingent();
        }

        if (changes.customFields && !changes.customFields.isFirstChange()) {
            this.initCustomFieldFilter();
        }

        if (DvbBelegungsplanFilter.hasWeekChanged(changes.firstOfWeek)) {
            this.initGruppen();
            this.initFirmenKontingente();
            this.initSubventioniertesKontingent();
            this.initCustomFieldFilter();
        }
    }

    public toggleSticky<T extends FilterOption>(filterOption: T): void {
        filterOption.sticky = !filterOption.sticky;

        // Sticky FilterOptions are automatically selected, unsticky FilterOptions automatically deselected
        filterOption.selected = filterOption.sticky;

        // Manually update the FilterModel
        if (filterOption instanceof PlaetzeFilterOption && this.firmenKontingentFilters.includes(filterOption)) {
            this.setSelectedFirmenKontingent();
        } else if (this.gruppenFilters.includes(filterOption)) {
            this.setSelectedGruppen();
        } else if (filterOption instanceof CustomFieldFilterOption) {
            this.setSelectedCustomFields();
        } else if (filterOption.id === 'anmeldeDatum') {
            this.setAnmeldeFilter();
        } else if (filterOption.id === 'pensum') {
            this.setPensumFilter();
        } else if (filterOption instanceof NumberFilterOption) {
            this.onPrioritaetFilterChange();
        } else if (filterOption instanceof KindFilterOption) {
            this.onKindFilterOptionChange(filterOption);
        }
    }

    public isFilterSet(): boolean {
        return this.kinderFilterService.isFilterSet(this.kitaId);
    }

    public resetFilter(): void {
        this.kinderFilterService.resetFilter();
        this.privatePlaetzeSelected = false;
        this.subventioniertePlaetzeSelected = false;
        this.provisorisch!.selected = false;
        this.angebot!.selected = false;
        this.definitiv!.selected = false;
        this.keineZuweisung!.selected = false;
        this.geschwisterFilter!.selected = false;
        this.anmeldeFilter!.selected = false;
        this.pensumFilter!.selected = false;
        this.updateSelection(this.firmenKontingentFilters, false);
        this.updateSelection(this.gruppenFilters, false);
        this.updateSelection(this.ageFilters, false);
        this.updateSelection(this.kindergartenFilter, false);
        this.updateSelection(this.customFieldsFilter.filterOptions, false);
        this.updateSelection(this.prioritaetsFilter, false);
        this.initVerfuegbarFilter();
    }

    public toggleCollapse(): void {
        this.filterCollapsed = !this.filterCollapsed;
    }

    public toggleFilterOption(filter: FilterOption, selected: boolean, callback: () => void): void {
        filter.selected = selected;
        // called from the template --> use apply to get hold of this
        callback.apply(this);
    }

    public toggleKindFilterOption(filterOption: KindFilterOption, selected: boolean): void {
        this.toggleFilterOption(filterOption, selected, () => this.onKindFilterOptionChange(filterOption));
    }

    public closeFilterOption(filter: FilterOption, callback: () => void): void {
        filter.selected = false;
        filter.sticky = false;
        // called from the template --> use apply to get hold of this
        callback.apply(this);
    }

    public closeKindFilterOption(filter: KindFilterOption): void {
        this.closeFilterOption(filter, () => this.onKindFilterOptionChange(filter));
    }

    public onPrivatePlaetzeChange(selected: boolean): void {
        this.privatePlaetzeSelected = selected;
        this.kinderFilterService.setPrivaterPlatz(selected ? true : null);
    }

    public onSubventioniertePlaetzeChange(selected: boolean): void {
        this.subventioniertePlaetzeSelected = selected;
        this.kinderFilterService.setSubventioniert(selected ? true : null);
    }

    public onVerfuegbarePlaetzeChange(): void {
        this.kinderFilterService.setVerfuegbarAb(this.verfuegbarePlaetze);
    }

    public onAgeFilterChange(): void {
        const selectedAgeFilters = FilterOption.getSelectedIds(this.ageFilters);
        this.kinderFilterService.setAgeFilterIds(selectedAgeFilters);
    }

    public onPrioritaetFilterChange(): void {
        const selectedPrioritaetFilters = FilterOption.getSelectedIds(this.prioritaetsFilter);
        this.kinderFilterService.setPrioritaetFilterIds(selectedPrioritaetFilters);
    }

    public setSelectedFirmenKontingent(): void {
        const firmenKontingentIds = FilterOption.getSelectedIds(this.firmenKontingentFilters);
        this.kinderFilterService.setFirmenKontingentIds(firmenKontingentIds);
    }

    public setSelectedGruppen(): void {
        const gruppenIds = FilterOption.getSelectedIds(this.gruppenFilters);
        this.kinderFilterService.setGruppenIds(gruppenIds);
    }

    public setSelectedCustomFields(): void {
        const customFieldIds = FilterOption.getSelectedIds(this.customFieldsFilter.filterOptions);
        this.kinderFilterService.setBelegungCustomFieldIds(customFieldIds);
    }

    public setAnmeldeFilter(): void {
        const property = this.anmeldeFilter?.selected ?
            Gueltigkeit.copy(this.anmeldeFilter.popoverModel.gueltigkeit) :
            null;
        property?.toEndOfTime();
        this.kinderFilterService.setGueltigkeitFilterProperty('anmeldeDatum', property);
    }

    public setPensumFilter(): void {
        const property = this.pensumFilter?.selected && this.pensumFilter.popoverModel ?
            new MinMaxFilterProperty(this.pensumFilter.popoverModel.min, this.pensumFilter.popoverModel.max) :
            null;
        this.kinderFilterService.setMinMaxFilterProperty('pensum', property);
    }

    private onKindFilterOptionChange(filterOption: KindFilterOption): void {
        if (BOOLEAN_FILTER_PROPS.guard(filterOption.id)) {
            this.kinderFilterService.setBooleanFilterProperty(filterOption.id, filterOption.selected ? true : null);
        } else {
            throw new Error(`KindFilterOptionChange not implemented for FilterOption ${filterOption.id}/${filterOption.name}`);
        }
    }

    private initStatusFilter(): void {
        this.provisorisch = this.createBooleanKindFilterOption('provisorisch', 'COMMON.ZUWEISUNG_IST_PROVISORISCH');
        this.angebot = this.createBooleanKindFilterOption('angebot', 'COMMON.ZUWEISUNG_IST_ANGEBOT');
        this.definitiv = this.createBooleanKindFilterOption('definitiv', 'COMMON.ZUWEISUNG_IST_DEFINITIV');
        this.keineZuweisung = this.createBooleanKindFilterOption('keineZuweisung', 'COMMON.KEINE_ZUWEISUNG');
    }

    private initKindergartenFilter(): void {
        this.kindergartenFilter.push(this.createBooleanKindFilterOption('kg1',
            'KIND.ZUWEISUNG.KINDERGARTEN.ONE_SHORT'));
        this.kindergartenFilter.push(this.createBooleanKindFilterOption('kg2',
            'KIND.ZUWEISUNG.KINDERGARTEN.TWO_SHORT'));
        this.kindergartenFilter.push(this.createBooleanKindFilterOption('schule',
            'KIND.ZUWEISUNG.KINDERGARTEN.SCHULE_SHORT'));
        this.kindergartenFilter.push(this.createBooleanKindFilterOption(
            'kgIntern',
            'KIND.ZUWEISUNG.KINDERGARTEN.INTERN'));
        this.kindergartenFilter.push(this.createBooleanKindFilterOption(
            'kgExtern',
            'KIND.ZUWEISUNG.KINDERGARTEN.EXTERN'));
    }

    private initFirmenKontingente(): void {
        const firmenKontingente = this.getGueltigeFirmenKontingente();
        // entferne nicht mehr existierende Kontingente
        this.firmenKontingentFilters =
            this.removeObsoleteFilterOptions(this.firmenKontingentFilters, firmenKontingente);

        // neues Kontingent hinzufuegen
        this.entitiesWithoutFilterOption(this.firmenKontingentFilters, firmenKontingente)
            .map(firmenKontingent => new PlaetzeFilterOption(firmenKontingent.id, firmenKontingent.getDisplayName()))
            .forEach(filterOption => this.firmenKontingentFilters.push(filterOption));

        this.removeUnavailableFirmenKontingentFromKinderFilter();
        this.updateFirmenPlaezte();
        this.initialiseFirmenFiltersFromKinderFilter();
    }

    private initSubventioniertesKontingent(): void {
        this.hasSubventioniertePlaetze = !!this.subventioniertesKontingent &&
            this.subventioniertesKontingent.isGueltigOn(this.firstOfWeek);

        if (!this.hasSubventioniertePlaetze) {
            // deaktiviere den subventionierte Plaetze Filter
            this.kinderFilterService.setSubventioniert(null);

            return;
        }

        this.subventioniertePlaetzeSelected = this.kinderFilterService.isSubventioniert();
        this.updateSubventioniertePlaetze();
    }

    private initGruppen(): void {
        const gruppen = DvbDateUtil.getEntitiesOn(this.gruppen, this.firstOfWeek);
        // entferne nicht mehr existierende Gruppen
        this.gruppenFilters = this.removeObsoleteFilterOptions(this.gruppenFilters, gruppen);

        // neue Gruppen Filter Option hinzufuegen
        this.entitiesWithoutFilterOption(this.gruppenFilters, gruppen)
            .map(gruppe => new FilterOption(gruppe.id, gruppe.getDisplayName()))
            .forEach(filterOption => this.gruppenFilters.push(filterOption));

        this.removeUnavailableGruppenFromKinderFilter();
        this.initialiseGruppenFiltersFromKinderFilter();
    }

    private initAgeFilter(): void {
        this.ageFilters = this.kinderFilterService.getAvailableAgeFilters();
        const selectedAgeFilters = this.kinderFilterService.getAgeFilterIds();
        this.ageFilters.forEach(ageFilter => {
            ageFilter.selected = selectedAgeFilters.includes(ageFilter.id!);
        });
    }

    private initPrioritaetFilter(): void {
        this.prioritaetsFilter = this.kinderFilterService.getAvailablePrioritaetFilters();
        const selectedPrioFilterIds = this.kinderFilterService.getPrioritaetFilterIds();
        this.prioritaetsFilter.forEach(prioFilter => {
            DvbBelegungsplanFilter.updateSelectionAndStickyness(selectedPrioFilterIds, prioFilter, prioFilter.id);
        });
    }

    private initVerfuegbarFilter(): void {
        this.verfuegbarePlaetze = this.kinderFilterService.isVerfuegbarAb();
        this.kinderFilterService.setVerfuegbarAb(this.verfuegbarePlaetze);
    }

    private initGeschwisterFilter(): void {
        this.geschwisterFilter = this.createBooleanKindFilterOption('geschwister', 'COMMON.FILTER_GESCHWISTER');
    }

    private initAnmeldeFilter(): void {
        const ctrl = this.kinderFilterService.filterControllers.anmeldeDatum;
        this.anmeldeFilter = new PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>(
            'anmeldeDatum',
            ctrl.model.filterModel);
    }

    private initPensumFilter(): void {
        const ctrl = this.kinderFilterService.filterControllers.pensum;
        this.pensumFilter = new PopoverFilterOption<PopoverFilterPropertyPensum, MinMaxFilterProperty, Pensum>(
            'pensum',
            ctrl.model.filterModel);
    }

    private createBooleanKindFilterOption(
        key: typeof BOOLEAN_FILTER_PROPS.type,
        translationKey: string,
    ): KindFilterOption {

        const filterPropety = this.kinderFilterService.getBooleanFilterProperty(key);

        return new KindFilterOption(
            key,
            this.$translate.instant(translationKey),
            filterPropety,
            filterPropety);
    }

    private initCustomFieldFilter(): void {
        // entferne nicht mehr existierende CustomFelder
        const remaining = this.removeObsoleteFilterOptions(this.customFieldsFilter.filterOptions, this.customFields);

        // neue CustomField Filter Option hinzufuegen
        this.entitiesWithoutFilterOption(remaining, this.customFields)
            .map(customField => new CustomFieldFilterOption(customField.id, customField.name!, customField.fieldType!))
            .forEach(filterOption => remaining.push(filterOption));

        this.removeUnavailableCustomFieldsFromKinderFilter(remaining);
        this.initialiseCustomFieldFiltersFromKinderFilter(remaining);

        remaining.sort((a, b) => {
            if (a.fieldType === b.fieldType) {
                return DvbUtil.compareByDisplayName(a, b);
            }

            return a.fieldType.localeCompare(b.fieldType);
        });

        this.customFieldsFilter.filterOptions = remaining;
    }

    private removeObsoleteFilterOptions<T1 extends FilterOption, T2 extends IPersistable>(
        filterOptions: T1[],
        remainingEntities: T2[],
    ): T1[] {
        return filterOptions.filter(filterOption => remainingEntities.some(entity => entity.id === filterOption.id));
    }

    private entitiesWithoutFilterOption<T1 extends FilterOption, T2 extends IPersistable>(
        filterOptions: T1[],
        entities: T2[],
    ): T2[] {
        return entities.filter(entity => !filterOptions.some(filterOption => filterOption.id === entity.id));
    }

    private removeUnavailableGruppenFromKinderFilter(): void {
        const availableGruppenIds = this.gruppenFilters.map(DvbUtil.mapToIdChecked);

        const remainingSelectedGruppenIds = this.kinderFilterService.getGruppenIds()
            .filter(id => availableGruppenIds.includes(id));

        this.kinderFilterService.setGruppenIds(remainingSelectedGruppenIds);
    }

    private initialiseGruppenFiltersFromKinderFilter(): void {
        const selectedGruppenIds = this.kinderFilterService.getGruppenIds();
        this.gruppenFilters.forEach(filterOption => {
            DvbBelegungsplanFilter.updateSelectionAndStickyness(selectedGruppenIds, filterOption, filterOption.id!);
        });
    }

    private removeUnavailableCustomFieldsFromKinderFilter(customFieldFilters: FilterOption[]): void {
        const availableCustomFieldIds = customFieldFilters.map(DvbUtil.mapToIdChecked);

        const remainingSelectedCustomFieldIds = this.kinderFilterService.getBelegungCustomFieldIds()
            .filter(id => availableCustomFieldIds.includes(id));

        this.kinderFilterService.setBelegungCustomFieldIds(remainingSelectedCustomFieldIds);
    }

    private initialiseCustomFieldFiltersFromKinderFilter(customFieldFilters: FilterOption[]): void {
        const selectedCustomFieldIds = this.kinderFilterService.getBelegungCustomFieldIds();
        customFieldFilters.forEach(filterOption => {
            DvbBelegungsplanFilter.updateSelectionAndStickyness(selectedCustomFieldIds, filterOption, filterOption.id!);
        });
    }

    private getGueltigeFirmenKontingente(): FirmenKontingent[] {
        return this.firmenKontingente.filter(k => k.isGueltigOnWithKitaId(this.firstOfWeek, this.kitaId));
    }

    private removeUnavailableFirmenKontingentFromKinderFilter(): void {
        const selectedFirmenKontingentIds = this.kinderFilterService.getFirmenKontingentIds();
        const availableFirmenKontingentIds = this.getGueltigeFirmenKontingente()
            .map(firmenKontingent => checkPresent(firmenKontingent.id));

        const remainingSelectedFirmenKontingentIds = selectedFirmenKontingentIds
            .filter(id => availableFirmenKontingentIds.includes(id));

        this.kinderFilterService.setFirmenKontingentIds(remainingSelectedFirmenKontingentIds);
    }

    private updateFirmenPlaezte(): void {
        this.firmenKontingentFilters.forEach(filterOption => {
            filterOption.plaetze = null;
            const kontingent = this.getKontingentById(this.firmenKontingente, filterOption.id!);
            const params = {
                gueltigAb: this.firstOfWeek,
                gueltigBis: DvbDateUtil.endOfWeek(moment(this.firstOfWeek)),
                timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
            };

            this.kinderOrtService.getBelegteProzentPunkte(kontingent.id!, params)
                .then(belegteProzentPunkte => {
                    const plaetze = kontingent.getPlaetzeOn(this.firstOfWeek);
                    filterOption.plaetze = plaetze - DvbUtil.pctToFraction(belegteProzentPunkte);
                })
                .catch(DvbRestUtilAngularJS.handleRequestCancellation.bind(DvbRestUtilAngularJS));
        });
    }

    private initialiseFirmenFiltersFromKinderFilter(): void {
        const selectedFirmenKontingentIds = this.kinderFilterService.getFirmenKontingentIds();
        this.firmenKontingentFilters.forEach(filterOption => {
            DvbBelegungsplanFilter.updateSelectionAndStickyness(
                selectedFirmenKontingentIds,
                filterOption,
                filterOption.id!);
        });
    }

    private updateSubventioniertePlaetze(): void {
        this.subventioniertePlaetze = null;
        const endOfWeek = DvbDateUtil.endOfWeek(moment(this.firstOfWeek));
        const params = {
            gueltigAb: DvbDateUtil.startOfYear(moment(this.firstOfWeek)),
            gueltigBis: endOfWeek.year() > moment(this.firstOfWeek).year() ?
                endOfWeek :
                moment(this.firstOfWeek).endOf('year'),
            timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
        };

        const kontingentId = checkPresent(this.subventioniertesKontingent?.id);
        const promises = [];
        promises.push(this.kinderOrtService.getProzentPunkte(kontingentId, params));
        promises.push(this.kinderOrtService.getBelegteProzentPunkte(kontingentId, params));

        this.$q.all(promises)
            .then(responses => {
                const prozentpunkte = responses[0];
                const belegteProzentpunkte = responses[1];
                const freieProzentpunkte = WochenplanUtil.calcFreieSubventionierteProzent(
                    prozentpunkte,
                    belegteProzentpunkte,
                    this.firstOfWeek);
                this.subventioniertePlaetze = DvbUtil.pctToFraction(checkPresent(freieProzentpunkte));
            })
            .catch(err => DvbRestUtilAngularJS.handleRequestCancellation(err));
    }

    private getKontingentById<T extends Kontingente>(kontingente: T[], kontingentId: string): T {
        return checkPresent(DvbUtil.findFirst(kontingente, kontingent => kontingent.id === kontingentId));
    }

    private updateSelection(filterOptions: FilterOption[], selected: boolean): void {
        filterOptions.forEach(filterOption => {
            filterOption.selected = selected;
        });
    }
}

componentConfig.controller = DvbBelegungsplanFilter;
angular.module('kitAdmin').component('dvbBelegungsplanFilter', componentConfig);
