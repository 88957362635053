<div class="dvb-kibon-verfuegung-matching row">

    <div class="col-md-7 big-abstand-bottom">
        <h2 data-translate="KIBON.VERFUEGUNGEN.NOT_ASSIGNED"></h2>

        <dvb-pages items="vm.verfuegungen"
                   total-items="vm.verfuegungen.length"
                   is-loading="vm.isLoading"
                   items-per-page="vm.itemsPerPage"
                   current-page="vm.currentPage"
                   entity-name="'KIBON.VERFUEGUNGEN.MF'"
                   on-update-page="vm.updatePage(page)"
                   on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
            <page-items dvb-accordions>

                <dvb-accordion ng-repeat="entity in vm.verfuegungen | limitTo: vm.itemsPerPage : ((vm.currentPage - 1) * vm.itemsPerPage)"
                               remove-toggled-dom="true"
                               is-first="$first">

                    <panel-header class="full-width-block">
                        <div class="layout-row items-center row">
                             <div class="col-xs-1">
                                <i class="fa fa-info-circle"
                                    uib-tooltip="{{'KIND.KITAX.AUSZAHLUNG_AN_ELTERN' | translate}}"
                                    tooltip-placement="top-left"
                                    ng-if="entity.auszahlungAnEltern">
                                </i>
                            </div>
                            <div class="col-xs-4">
                                <dvb-kibon-ref-nummer kibon-ref-nr-info="entity">
                                </dvb-kibon-ref-nummer>
                            </div>
                            <div class="col-xs-3">
                                <span ng-bind="entity.kind.getDisplayName()"></span>
                            </div>
                            <div class="col-xs-4">
                                <span ng-bind="entity.institution.name"></span>
                            </div>
                        </div>
                    </panel-header>

                    <panel-content>
                        <div class="row">
                            <div class="col-sm-4 normal-abstand-bottom">
                                <h3 data-translate="KIBON.VERFUEGUNGEN.GESUCHSTELLER"></h3>
                                <p class="truncate"
                                   ng-bind="entity.gesuchsteller.getDisplayName()"
                                   uib-tooltip="{{entity.gesuchsteller.getDisplayName()}}"
                                   tooltip-placement="top-left">
                                </p>
                                <p class="truncate"
                                   ng-if="entity.gesuchsteller.email"
                                   ng-bind="entity.gesuchsteller.email"
                                   uib-tooltip="{{entity.gesuchsteller.email}}"
                                   tooltip-placement="top-left">
                                </p>
                            </div>
                            <div class="col-sm-4 normal-abstand-bottom">
                                <h3 data-translate="COMMON.KIND.SINGULAR"></h3>
                                <p class="truncate"
                                   ng-bind="entity.kind.getDisplayName()"
                                   uib-tooltip="{{entity.kind.getDisplayName()}}"
                                   tooltip-placement="top-left">
                                </p>
                                <p class="truncate"
                                   data-translate="COMMON.GEBURTSTAG_MIT_DATUM"
                                   data-translate-value-date="{{entity.kind.geburtsdatum | amDateFormat:'D.M.YYYY'}}"
                                   uib-tooltip="{{entity.kind.geburtsdatum | amDateFormat:'D.M.YYYY'}}"
                                   tooltip-placement="top-left">
                                </p>
                            </div>
                            <div class="col-sm-4 normal-abstand-bottom">
                                <h3 data-translate="COMMON.ADRESSE_KINDERORT"></h3>
                                <dvlib-adresse-display [adresse]="entity.institution.adresse" [display-type]="'short'">
                                </dvlib-adresse-display>
                            </div>

                            <div class="col-md-12 normal-abstand-bottom">
                                <dvb-kitax-betreuungsgutscheine betreuungs-gutscheine="entity.betreuungsGutscheine"
                                                                schema-version="entity.schemaVersion">
                                </dvb-kitax-betreuungsgutscheine>
                            </div>
                        </div>
                    </panel-content>

                    <panel-footer>
                        <ul class="list-inline list-inline-row">
                            <li>
                                <button class="btn btn-primary"
                                        data-translate="COMMON.ZUWEISEN"
                                        ng-click="vm.assign(entity)">
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-link"
                                        data-translate="COMMON.IGNORIEREN"
                                        ng-click="vm.ignore(entity)">
                                </button>
                            </li>
                        </ul>
                    </panel-footer>

                </dvb-accordion>
            </page-items>
        </dvb-pages>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <h2 data-translate="KIBON.VERFUEGUNGEN.MATCHING_HEADER"></h2>
        <p translate="KIBON.VERFUEGUNGEN.MATCHING_TEXT"></p>
    </div>

</div>
