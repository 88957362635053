<div class="dvb-kita-faktura-dvb-konfiguration-form">
    <form name="dvbKitaKonfigurationForm" novalidate>

        <div class="form-section" ng-if="vm.editMode">
            <p data-translate="KINDERORT.KONFIGURATION_EDIT_WARNUNG"></p>
        </div>

        <div class="form-section">
            <h3 data-translate="KINDERORT.RECHNUNG_RUNDUNGSWERT"></h3>
            <div class="row form-row">
                <div class="col-sm-6">
                    <input type="number"
                           class="form-control"
                           min="0.01"
                           step="0.01"
                           ng-attr-placeholder="{{'KINDERORT.RECHNUNG_RUNDUNGSWERT' | translate}}"
                           uib-tooltip="{{'KINDERORT.RECHNUNG_RUNDUNGSWERT_TOOLTIP' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.konfiguration.totalBetragIncrement"
                           ng-pattern="/^\d*(\.\d{0,2})?$/"
                           required>
                </div>
            </div>
        </div>

        <div class="form-section">
            <h3 data-translate="COMMUNICATION.EMAIL.ABSENDER_ADRESSE"></h3>
            <dvb-contextual-adresse contextual-adresse="vm.konfiguration.absenderAdresse"
                                    allow-none="true">
            </dvb-contextual-adresse>
        </div>

        <div class="form-section">
            <h3>
                <span data-translate="COMMON.EINZAHLUNGSSCHEIN" class="smaller-abstand-right"></span>
                <a href=""
                   ng-show="!vm.loadingEinzahlungsscheine"
                   ng-click="vm.reloadEinzahlungsscheine()"
                   class="fa fa-refresh"
                   uib-tooltip="{{'KINDERORT.EINZAHLUNGSSCHEINE_AKTUALISIEREN' | translate}}"
                   tooltip-placement="top-left">
                </a>
                <dvlib-spinner [show]="vm.loadingEinzahlungsscheine"></dvlib-spinner>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <select class="form-control"
                            ng-model="vm.konfiguration.esrId"
                            ng-options="esr.id as esr.name for esr in vm.einzahlungsscheine | orderBy: 'name'">
                        <option value=""
                                data-translate="{{vm.loadingEinzahlungsscheine ?
                                  'FAKTURA.AKTUALISIERE' :
                                  'KINDERORT.KEIN_EINZAHLUNGSSCHEIN'}}">
                        </option>
                    </select>
                </div>
            </div>

            <h3>
                <span data-translate="KINDERORT.LAYOUT" class="smaller-abstand-right"></span>
                <a href=""
                   ng-show="!vm.loadingLayouts"
                   ng-click="vm.reloadLayouts()"
                   class="fa fa-refresh"
                   uib-tooltip="{{'KINDERORT.LAYOUTS_AKTUALISIEREN' | translate}}"
                   tooltip-placement="top-left">
                </a>
                <dvlib-spinner [show]="vm.loadingLayouts"></dvlib-spinner>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <select class="form-control"
                            ng-model="vm.konfiguration.layoutId"
                            ng-options="layout.id as layout.name for layout in vm.layouts | orderBy:'name'">
                        <option value=""
                                data-translate="{{vm.loadingLayouts ?
                                  'FAKTURA.AKTUALISIERE' :
                                  'KINDERORT.KEIN_LAYOUT'}}">
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-section">
            <h3 data-translate="FAKTURA.KONTO.RUECKZAHLUNGSKONTO"></h3>
            <div class="row form-row">
                <div class="col-xs-12">
                    <select class="form-control"
                            uib-tooltip="{{'FAKTURA.KONTO.RUECKZAHLUNGSKONTO' | translate}}"
                            ng-model="vm.konto"
                            ng-options="konto as konto.bezeichnung for konto in vm.konten track by konto.id"
                            tooltip-placement="top-left">
                        <option ng-value="null" selected data-translate="FAKTURA.KONTO.MANDANT_DEFAULT"></option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="KINDERORT.ZAHLUNGS_ANGABEN"></span>
            </h3>
            <dvb-translatable>
                <textarea ng-model="vm.konfiguration.zahlungsKonditionen.texts[$dvbLang]"
                          dvb-translatable-input
                          class="form-control"
                          msd-elastic
                          maxlength="1000"
                          ng-attr-placeholder="{{'KINDERORT.ZAHLUNGS_ANGABEN' | translate}}"
                          uib-tooltip="{{$dvbLangTranslated}}"
                          tooltip-placement="top-left">
                </textarea>
            </dvb-translatable>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="DOKUMENT_LAYOUT.FUSSZEILE"></span>
            </h3>
            <dvb-translatable>
                 <textarea ng-model="vm.konfiguration.footer.texts[$dvbLang]"
                           dvb-translatable-input
                           class="form-control"
                           msd-elastic
                           maxlength="1000"
                           ng-attr-placeholder="{{'DOKUMENT_LAYOUT.FUSSZEILE'  | translate}}"
                           uib-tooltip="{{$dvbLangTranslated}}"
                           tooltip-placement="top-left">
                 </textarea>
            </dvb-translatable>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="KINDERORT.DEFINIERTE_TEXTE"></span>
            </h3>

            <p class="normal-abstand-bottom" data-translate="KINDERORT.DEFINIERTE_TEXTE_ERKLAERUNG"></p>

            <dvb-kita-faktura-texte-form texte="vm.konfiguration.texte">
            </dvb-kita-faktura-texte-form>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="KINDERORT.HEADER_KONFIGURATION"></span>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <label>
                        <input type="checkbox" ng-model="vm.konfiguration.hideSummary">
                        <span data-translate="KINDERORT.KONFIGURATION_SUMMARY_AUSBLENDEN"></span>
                    </label>
                </div>
            </div>
            <div class="row form-row" ng-if="!vm.konfiguration.hideSummary">
                <div class="col-xs-12">
                    <label>
                        <input type="checkbox" ng-model="vm.konfiguration.hideCustomerNumber">
                        <span data-translate="KINDERORT.KONFIGURATION_KUNDENNR_AUSBLENDEN"></span>
                    </label>
                </div>
            </div>
        </div>
    </form>
</div>
