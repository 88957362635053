/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type AppConfigKey = 'PIWIK_SITE_ID' | 'SENTRY_DSN' | 'SENTRY_ENVIRONMENT' | 'MAX_KIBON_REFRESH_JOBS';

export const AppConfigKey = {
    PIWIK_SITE_ID: 'PIWIK_SITE_ID' as AppConfigKey,
    SENTRY_DSN: 'SENTRY_DSN' as AppConfigKey,
    SENTRY_ENVIRONMENT: 'SENTRY_ENVIRONMENT' as AppConfigKey,
    MAX_KIBON_REFRESH_JOBS: 'MAX_KIBON_REFRESH_JOBS' as AppConfigKey
};

