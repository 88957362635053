<div class="dvb-kita-uebersicht-stunden">
    <div class="row normal-abstand-top">
        <div class="col-md-7">
            <dvb-kita-chart-stunden controlling-report="vm.controllingReport"
                                     first-of-week="vm.firstOfWeek"
                                     on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
            </dvb-kita-chart-stunden>
        </div>

        <div class="col-md-5">
            <div class="row">
                <div class="col-sm-offset-1 col-sm-11">
                    <h2 class="stats-header"
                        data-translate="COMMON.STUNDEN_CHART.DURCHSCHNITT_YEAR"
                        data-translate-value-jahr="{{vm.firstOfWeek | amDateFormat:'GGGG'}}">
                    </h2>
                    <p class="stats-number chart-plaetze"
                       data-translate="COMMON.STUNDEN_CHART.AVG_SOLL_STUNDEN"
                       data-translate-value-count="{{vm.avgSollStunden | roundTo:1}}">
                    </p>
                    <p class="stats-number chart-belegte-plaetze"
                       data-translate="COMMON.STUNDEN_CHART.AVG_EFFEKTIV_STUNDEN"
                       data-translate-value-count="{{vm.avgEffektivStunden | roundTo:1}}">
                    </p>
                    <p class="stats-number chart-forecast-stunden"
                       data-translate="COMMON.STUNDEN_CHART.FORECAST_STUNDEN"
                       data-translate-value-count="{{vm.forecastStunden | roundTo:1}}">
                    </p>

                    <div class="btn-group normal-abstand-top">
                        <button type="button"
                                ui-sref="base.report.category.betrieb({
                                kinderOrte: [vm.kinderOrt.toSearchResultEntry()],
                                alleKinderOrte: false,
                                stichtag: vm.firstOfWeek,
                                stichtagAdresse: vm.firstOfWeek,
                                gueltigAb: vm.firstOfWeek,
                                gueltigBis: vm.lastOfWeek
                                })"
                                class="btn btn-primary normal-abstand-bottom"
                                data-translate="COMMON.REPORT_GENERIEREN">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
