<div class="dvb-tarif-value-form">
    <form name="updateTarifValueForm"
          ng-submit="vm.updateTarifValue()"
          novalidate>

        <div ng-if="vm.withGueltigAb" class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.tarifValue.gueltigAb"
                                           required
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-file-upload label-upload="TARIF.TARIF_HOCHLADEN_LABEL"
                         ng-model="vm.tarifValue.template"
                         required>
        </dvb-file-upload>

        <div class="normal-abstand-top">
            <dvb-tarif-value-manual-positions-total tarif-value="vm.tarifValue">
            </dvb-tarif-value-manual-positions-total>
        </div>

        <div class="row big-abstand-top">
            <div class="col-xs-12">
                <h3 data-translate="COMMON.BEMERKUNG"></h3>
                <textarea ng-model="vm.tarifValue.bemerkung"
                          ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                          uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                          tooltip-placement="top-left"
                          class="form-control"
                          msd-elastic
                          maxlength="1000"
                          rows="1">
                </textarea>
            </div>
        </div>
        <dvb-submit-cancel-buttons is-loading="updateTarifValueForm.$pending"
                                   on-cancel="vm.cancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
