/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import {OffenerPostenType} from '@dv/shared/backend/model/offener-posten-type';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        assignRechnung: '<',
        ausstehenderBetrag: '<',
    },
    template: require('./dvb-rechnung-ueberschuss-zuweisen.html'),
    controllerAs: 'vm',
};

// TODO: Migrate to Dialog, once the template can be migrated to Angular
export class DvbRechnungUeberschussZuweisen implements angular.IController {

    public static $inject = ['$state', 'fakturaService', 'errorService'];

    public rechnung!: Persisted<Rechnung>;
    public assignRechnung!: Persisted<Rechnung>;
    public ausstehenderBetrag!: number;

    public betrag: number | null = null;
    public date: moment.Moment = DvbDateUtil.today();
    public readonly mode = RechnungDisplayMode.modes.TRANSAKTION;
    public isLoading: boolean = false;

    public constructor(
        private readonly $state: StateService,
        private readonly fakturaService: FakturaService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.betrag = Math.min(checkPresent(this.assignRechnung.ausstehenderBetrag), -this.ausstehenderBetrag);
    }

    public submit(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(!form.date.$error.required, 'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(!form.date.$error.moment, 'ERRORS.ERR_INVALID_DATE');

        if (form.$invalid) {
            return;
        }

        this.isLoading = true;

        const vorauszahlung = OffenerPostenType.VORAUSZAHLUNG;
        const kitaId = checkPresent(this.assignRechnung.kitaId);
        const zahlung = this.fakturaService.initAusgleichszahlung(this.date, vorauszahlung, this.betrag!, kitaId);

        this.fakturaService.bucheRechnungsUeberschussUm(this.rechnung.id, this.assignRechnung.id, zahlung)
            .then(() => this.$state.go('base.rechnung.zahlungen'))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.$state.go('^');
    }
}

componentConfig.controller = DvbRechnungUeberschussZuweisen;
angular.module('kitAdmin').component('dvbRechnungUeberschussZuweisen', componentConfig);
