<div class="dvb-dashboard">
    <div class="dashboard-container">
        <div class="row">
            <div class="col-md-7">
                <dvb-dashboard-favoriten on-update="vm.favoritenUpdated(favoriten)"></dvb-dashboard-favoriten>
            </div>
            <div class="col-md-4 col-md-offset-1">
                <dvb-dashboard-tasks load-tasks="vm.loadTasks"
                                     on-loading="vm.loadTasks = false"
                                     favoriten="vm.favoriten"
                                     dvb-pagination>
                </dvb-dashboard-tasks>
            </div>
        </div>
    </div>
</div>
