/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {IPersistable, SearchResultEntry} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        focus: '<',
        favoriteEntities: '<',
        onSelect: '&',
        onCancel: '&',
    },
    template: require('./dvb-dashboard-favoriten-suche.html'),
    controllerAs: 'vm',
};

export class DvbDashboardFavoritenSuche {
    public static $inject: readonly string[] = [];

    public focus!: boolean;
    public favoriteEntities!: IPersistable[];
    public onSelect!: (val: { item: SearchResultEntry }) => void;
    public onCancel!: () => void;
}

componentConfig.controller = DvbDashboardFavoritenSuche;
angular.module('kitAdmin').component('dvbDashboardFavoritenSuche', componentConfig);
