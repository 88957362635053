/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameterHistoryEntry} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        tarifParameterHistoryEntry: '<',
    },
    template: require('./dvb-kind-tarife-parameter-header.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeParameterHeader implements angular.IController {
    public static $inject: readonly string[] = [];

    public tarifParameterHistoryEntry!: TarifParameterHistoryEntry;

    public getValueList(): string {
        return this.tarifParameterHistoryEntry.values.map(v => v.parameter!.name!)
            .sort((a, b) => a.localeCompare(b))
            .join(', ');
    }
}

componentConfig.controller = DvbKindTarifeParameterHeader;
angular.module('kitAdmin').component('dvbKindTarifeParameterHeader', componentConfig);

