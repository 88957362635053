/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gueltigkeit} from '@dv/shared/code';
import {stringUnion} from '@dv/shared/code';
import type {MinMaxFilterProperty} from './MinMaxFilterProperty';

export const BOOLEAN_FILTER_PROPS = stringUnion(
    'subventionierterPlatz',
    'privaterPlatz',
    'provisorisch',
    'angebot',
    'definitiv',
    'keineZuweisung',
    'isVerfuegbarAb',
    'kg1',
    'kg2',
    'schule',
    'kgIntern',
    'kgExtern',
    'geschwister');

export const ID_ARRAY_FILTER_PROPS = stringUnion(
    'firmenKontingentIds',
    'gruppenIds',
    'ageFilterIds',
    'belegungCustomFields',
    'prioritaetFilterIds',
);

/**
 * Special case of boolean filter props. Must be true to be set.
 */
const BOOLEAN_TRUE_FILTER_PROPS = stringUnion('isVerfuegbarAb');

export const MIN_MAX_FILTER_PROPS = stringUnion('pensum');

export const GUELTIGKEIT_FILTER_PROPS = stringUnion('anmeldeDatum');

const NULL_WHEN_NOT_SET_PROPS = stringUnion(
    ...BOOLEAN_FILTER_PROPS.values.filter(prop => !BOOLEAN_TRUE_FILTER_PROPS.values.includes(prop as any)),
    ...MIN_MAX_FILTER_PROPS.values,
    ...GUELTIGKEIT_FILTER_PROPS.values);

export class FilterProperties
implements Record<typeof BOOLEAN_FILTER_PROPS.type, boolean | null>,
    Record<typeof ID_ARRAY_FILTER_PROPS.type, string[]>,
    Record<typeof MIN_MAX_FILTER_PROPS.type, MinMaxFilterProperty | null>,
    Record<typeof GUELTIGKEIT_FILTER_PROPS.type, Gueltigkeit | null> {

    public static $inject: readonly string[] = [];

    public constructor(
        public firmenKontingentIds: string[] = [],
        public subventionierterPlatz: boolean | null = null,
        public privaterPlatz: boolean | null = null,
        public gruppenIds: string[] = [],
        public provisorisch: boolean | null = null,
        public angebot: boolean | null = null,
        public definitiv: boolean | null = null,
        public keineZuweisung: boolean | null = null,
        public isVerfuegbarAb: boolean = false,
        public ageFilterIds: string[] = [],
        public prioritaetFilterIds: string[] = [],
        public kg1: boolean | null = null,
        public kg2: boolean | null = null,
        public schule: boolean | null = null,
        public kgIntern: boolean | null = null,
        public kgExtern: boolean | null = null,
        public belegungCustomFields: string[] = [],
        public geschwister: boolean | null = null,
        public pensum: MinMaxFilterProperty | null = null,
        public anmeldeDatum: Gueltigkeit | null = null,
    ) {
    }

    public isSet(): boolean {
        return ID_ARRAY_FILTER_PROPS.values.some(prop => (this[prop] as unknown[]).length > 0)
            || NULL_WHEN_NOT_SET_PROPS.values.some(prop => this[prop] !== null)
            || BOOLEAN_TRUE_FILTER_PROPS.values.some(prop => this[prop]);
    }
}
