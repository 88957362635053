/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EmailVersandProperties} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        emailVersandProperties: '<',
        isDisabled: '<',
        showDiffCheckbox: '<?',
        limitEmailsToRechnungenWithDiff: '<?',
        onUpdateLimitEmailsToRechnungenWithDiff: '&?',
    },
    template: require('./dvb-email-versand-form.html'),
    controllerAs: 'vm',
};

export class DvbEmailVersandForm {
    public static $inject: readonly string[] = [];

    public emailVersandProperties!: EmailVersandProperties;
    public isDisabled!: boolean;
    public showDiffCheckbox: boolean = false;
    public limitEmailsToRechnungenWithDiff?: boolean;
    public onUpdateLimitEmailsToRechnungenWithDiff?: (param: { limitEmailsToRechnungenWithDiff: boolean }) => unknown;
}

componentConfig.controller = DvbEmailVersandForm;
angular.module('kitAdmin').component('dvbEmailVersandForm', componentConfig);
