/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line max-classes-per-file
import type angular from 'angular';
import moment from 'moment';

// Let's keep this class private
class DebouncerImpl implements Debouncer {

    private updatePromise: angular.IPromise<unknown> | null = null;
    private lastUpdated: number = moment.now();
    private readonly debounceTime: number = 600;
    private readonly timeoutService: angular.ITimeoutService;

    public constructor(timeoutService: angular.ITimeoutService) {
        this.timeoutService = timeoutService;
    }

    public debounce<T>(fn: (...args: unknown[]) => T | angular.IPromise<T>): void {
        if (this.updatePromise && moment().diff(this.lastUpdated) < this.debounceTime) {
            this.timeoutService.cancel(this.updatePromise);
        }
        this.updatePromise = this.timeoutService(fn, this.debounceTime);
        this.lastUpdated = moment.now();
    }
}

export class DebounceService {
    public static $inject: readonly string[] = ['$timeout'];

    public constructor(private timeoutService: angular.ITimeoutService) {
    }

    public create(): Debouncer {
        return new DebouncerImpl(this.timeoutService);
    }
}

export interface Debouncer {
    debounce: <T>(fn: (...args: unknown[]) => T | angular.IPromise<T>) => void;
}
