/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ApplicationRole, Password} from '@dv/kitadmin/models';
import type {JaxNewGroupAdminBenutzer} from '@dv/shared/backend/model/jax-new-group-admin-benutzer';
import type {JaxUpdateBenutzer} from '@dv/shared/backend/model/jax-update-benutzer';
import {checkPresent} from '@dv/shared/code';
import type {BenutzerFormModel} from './benutzer-form.model';

export function createBenutzerFormModel(role = new ApplicationRole()): BenutzerFormModel {
    return {
        vorName: null,
        familienName: null,
        username: null,
        groupAdminUser: false,
        password: new Password(),
        role,
    };
}

export function benutzerFormModelToJaxBenutzer(model: BenutzerFormModel): JaxUpdateBenutzer {
    const username = checkPresent(model.username);
    const familienName = checkPresent(model.familienName);
    const vorName = checkPresent(model.vorName);
    const role = model.role.toRestObject();
    const password = model.password.newPassword ?? undefined;

    return {vorName, familienName, username, role, password};
}

export function benutzerFormModelToJaxNewGroupAdminBenutzer(model: BenutzerFormModel): JaxNewGroupAdminBenutzer {
    const username = checkPresent(model.username);
    const name = checkPresent(model.familienName);
    const kinderOrtId = checkPresent(model.kinderOrt?.id);

    return {username, name, kinderOrtId};
}

