<div class="dvb-kind-leistungen-download">
    <div class="row">
        <div class="col-xs-12 tarifrechnung-result-footer">
            <a href="" data-translate="KIND.LEISTUNGEN.TARIFRECHNUNG_RESULT_DOWNLOAD_LABEL"
               ng-click="vm.downloadTarifrechnungResult()"></a>
            <dvlib-spinner [show]="vm.loadingTarifrechnungResult"></dvlib-spinner>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 tarifrechnung-result-footer">
            <a href="" data-translate="KIND.LEISTUNGEN.PLATZ_CONFIRMATION_DOWNLOAD_LABEL"
               ng-click="vm.downloadPlatzConfirmation()"></a>
            <dvlib-spinner [show]="vm.loadingPlatzConfirmation"></dvlib-spinner>
        </div>
    </div>
</div>
