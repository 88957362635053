<div class="neuerbenutzermain">
    <div class="row dvb-form">
        <div class="col-md-6 col-md-offset-3">
            <h1 data-translate="COMMON.BENUTZER.ERSTELLEN"></h1>

            <form name="neuerBenutzerForm" ng-submit="vm.validateAndSaveBenutzer(neuerBenutzerForm)" novalidate>
                <dvb-benutzer-form benutzer="vm.model"
                                   allow-group-admin="true"
                                   allow-password-change="vm.allowPasswordChange">
                </dvb-benutzer-form>

                <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                           submit-label="COMMON.BENUTZER.ERSTELLEN"
                                           on-cancel="vm.goBack()">
                </dvb-submit-cancel-buttons>
            </form>
        </div>
    </div>
</div>
