<div class="dvb-kontaktperson-faktura row">
    <div class="col-md-7 big-abstand-bottom">
        <dvb-kontaktperson-rechnungen kontaktperson="vm.kontaktperson"
                                      pre-selected-rechnung-id="vm.rechnungId"
                                      year="vm.year">
        </dvb-kontaktperson-rechnungen>
    </div>
    <div class="col-md-4 col-md-offset-1 hidden-print">
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <p>
            <a ui-sref="base.report.category.faktura.steuerbescheinigungen({
            kontaktperson: vm.kontaktperson.toSearchResultEntry(),
            stichtag: vm.stichtag
            })"
               data-translate="KONTAKTPERSON.STEUERBESCHEINIGUNG_ERSTELLEN"></a>
        </p>
    </div>
</div>
