<div class="dvb-kinderort-personal-konfiguration">
    <div class="row">
        <div class="col-md-7">
            <dvb-personal-konfiguration konfigurationen="vm.konfigurationen"
                                        new-konfiguration="vm.newKonfiguration">
            </dvb-personal-konfiguration>
        </div>
        <div class="col-md-4 col-md-offset-1">
            <h2 data-translate="COMMON.AKTIONEN"></h2>
            <a ui-sref="{{vm.personalStates.KONFIGURATION_STATE.name}}"
               data-translate="PERSONAL.KONFIGURATION.TO_MANDANT_CONFIG">
            </a>
        </div>
    </div>
</div>
