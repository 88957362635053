<div class="dvb-ausbildungen-main">
    <div class="header">
        <h2 data-translate="PERSONAL.AUSBILDUNGEN.PLURAL"></h2>
    </div>

    <div class="row">
        <div class="col-md-7">
            <dvb-click2-edit edit-mode="vm.editMode">
                <label-part>
                    <div class="form-control click2edit multi-line"
                         ng-if="vm.ausbildungen.length > 0"
                         ng-click="vm.edit()">
                        <dvb-ausbildungen ausbildungen="vm.ausbildungen"
                                          ng-repeat="ausbildung in vm.ausbildungen"
                                          ausbildung="ausbildung">
                        </dvb-ausbildungen>
                    </div>
                </label-part>

                <content-part>
                    <form name="ausbildungenForm" ng-submit="vm.submit(ausbildungenForm)" novalidate>
                        <div ui-tree ng-if="vm.ausbildungen.length > 0">
                            <div ui-tree-nodes ng-model="vm.ausbildungen">
                                <dvb-ausbildung-node ng-repeat="ausbildung in vm.ausbildungen"
                                                     ui-tree-node
                                                     ausbildung="ausbildung"
                                                     on-delete="vm.delete(ausbildung)"
                                                     on-deleted="vm.storeDeletedAusbildungIds(ausbildungIds)">
                                </dvb-ausbildung-node>
                            </div>
                        </div>

                        <button type="button"
                                class="btn btn-link small-abstand-top normal-abstand-bottom"
                                ng-click="vm.ausbildungHinzufuegen()"
                                data-translate="PERSONAL.AUSBILDUNGEN.HINZUFUEGEN">
                        </button>

                        <dvb-submit-cancel-buttons
                            is-loading="vm.isLoading"
                            on-cancel="vm.cancel()">
                        </dvb-submit-cancel-buttons>
                    </form>
                </content-part>
            </dvb-click2-edit>
        </div>
    </div>
    <button ng-if="vm.ausbildungen.length === 0 && !vm.editMode"
            type="button"
            class="btn btn-link small-abstand-top normal-abstand-bottom"
            ng-click="vm.initFirstAusbildung()"
            data-translate="PERSONAL.AUSBILDUNGEN.HINZUFUEGEN">
    </button>
</div>

