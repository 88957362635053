/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DvbKitaRechnungsKonfiguration, Translation} from '@dv/kitadmin/models';
import {UserLanguageService} from '@dv/shared/angular';
import type {SupportedLanguage} from '@dv/shared/code';
import {hasOwnPropertyGuarded, RechnungStatusText} from '@dv/shared/code';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '<',
    },
    template: require('./dvb-kita-faktura-texte-content.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaTexteContent {
    public static $inject: readonly string[] = ['$translate'];

    public konfiguration!: DvbKitaRechnungsKonfiguration;

    public texteStatuses: string[] = Object.keys(RechnungStatusText);
    public hasTexte: boolean = false;

    public language: SupportedLanguage = UserLanguageService.getLocalStorageLanguage();

    public constructor(
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(changes: IOnChangesObject): void {
        if (changes.konfiguration) {
            this.hasTexte =
                changes.konfiguration.currentValue && this.hasNotEmptyText(changes.konfiguration.currentValue);
        }
    }

    public getTextOrDefault(translation: Translation): string {
        const result = translation.texts[this.language];

        return result || this.$translate.instant('LANGUAGE.NO_TEXT_DEFINED');
    }

    public hasText(translation: Translation): boolean {
        return !!translation.texts[this.language];
    }

    private hasNotEmptyText(konfiguration: DvbKitaRechnungsKonfiguration): boolean {
        if (!hasOwnPropertyGuarded(konfiguration, 'texte')) {
            return false;
        }

        const keys = Object.keys(konfiguration.texte);
        if (keys.length === 0) {
            return false;
        }

        return keys.some(key => {
            const texte = konfiguration.texte[key];

            return texte.einfuehrung?.hasTranslations() ?? texte.konditionen?.hasTranslations();
        });
    }
}

componentConfig.controller = DvbKitaFakturaTexteContent;
angular.module('kitAdmin').component('dvbKitaFakturaTexteContent', componentConfig);
