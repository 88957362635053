/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TempBlob} from '@dv/kitadmin/models';
import type {NamedEntityType, PageContainer, RestInclude, RestPaginated} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {Notiz} from '../models/Notiz';

export class NotizService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/notiz`;

    public constructor(private $http: angular.IHttpService) {
    }

    public create(notiz: Notiz): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = NotizService.BASE_URL;

        return this.$http.post(url, notiz.toRestObject());
    }

    public update(notiz: Notiz): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${NotizService.BASE_URL}/${encodeURIComponent(checkPresent(notiz.id))}`;

        return this.$http.put(url, notiz.toRestObject());
    }

    public delete(notizId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${NotizService.BASE_URL}/${encodeURIComponent(notizId)}`);
    }

    public get(notizId: string, params?: RestInclude): angular.IPromise<Notiz> {
        checkPresent(notizId);

        return DvbRestUtilAngularJS.getModelByIdAndParams(NotizService.BASE_URL, Notiz, notizId, params)
            .then(checkPresent);
    }

    public getNotizen(
        entityId: string,
        entityType: NamedEntityType,
        params?: Partial<RestPaginated>,
    ): angular.IPromise<PageContainer<Notiz>> {
        const url = `${NotizService.BASE_URL}/${entityType}/${encodeURIComponent(entityId)}/notizen`;
        const matrixParams = params;

        return DvbRestUtilAngularJS.getPagedItems(url, Notiz, matrixParams);

    }

    public getAttachment(
        notizId: string,
        blobId: string,
    ): angular.IPromise<TempBlob> {
        const url = `${NotizService.BASE_URL}/${notizId}/attachment/${blobId}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob).then(checkPresent);
    }
}
