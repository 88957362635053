/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestCache} from '@dv/kitadmin/models';
import {FixPosition} from '@dv/kitadmin/models';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class FixPositionenService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/fixpositionen`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public createFixPosition(fixPosition: FixPosition): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(FixPositionenService.BASE_URL, fixPosition.toRestObject());
    }

    public getFixPosition(fixPositionId: string, params?: RestCache): angular.IPromise<FixPosition | null> {
        DvbRestUtilAngularJS.clearHttpCache();

        return DvbRestUtilAngularJS.getModelByIdAndParams(
            FixPositionenService.BASE_URL,
            FixPosition,
            fixPositionId,
            params,
        );
    }

    public deleteFixPosition(fixPositionId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${FixPositionenService.BASE_URL}/${encodeURIComponent(fixPositionId)}`);
    }
}
