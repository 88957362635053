<dvb-navigation ng-show="!!isAuthenticated()"
                principal="getPrincipal()"
                logout-handler="logout()">
</dvb-navigation>

<nav ng-if="!isAuthenticated()"
     class="navbar navbar-default navbar-fixed-top navbar-inverse"
     ng-class="{ 'login': !isAuthenticated() }">
    <div class="kitadmin-content container">
        <div class="navbar-header">
            <a class="navbar-brand" href="/">
                <span class="sr-only">kitAdmin Home</span>
                <svg width="90px" viewBox="0 0 600 139" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#38424C"
                              d="M168.125 119.664h103.387V138H168.125zM271.827 103.094l-38.49-99.77h-26.66l-38.49 99.77h24.114l6.29-16.903h42.834l6.29 16.904h24.113zm-36.244-35.6h-31.15l15.575-42.93 15.575 42.93z"></path>
                        <path fill="#FFF"
                              d="M71.43 103.094l-29.505-39.49L70.53 30.848H47.168L19.76 63.007V3.323H.74v99.77h19.02V84.546l8.686-9.274 19.17 27.822H71.43zm19.986-80.026c6.29 0 11.382-5.085 11.382-11.368 0-6.282-5.092-11.218-11.382-11.218-6.14 0-11.233 4.936-11.233 11.218 0 6.283 5.092 11.368 11.233 11.368zM101 103.094V30.847H81.98v72.247H101zm44.1 1.795c7.938 0 13.03-2.095 15.875-4.638l-4.043-14.51c-1.05 1.197-3.745 2.244-6.59 2.244-4.194 0-6.59-3.44-6.59-8.077V47.45h14.677V30.847H143.75V11.102h-19.02v19.745H112.75V47.45h11.982v37.545c0 13.013 7.04 19.894 20.368 19.894zm201.557-1.796V3.324h-19.17V40.12c-5.69-7.478-13.78-11.068-22.315-11.068-18.422 0-32.05 14.36-32.05 37.844 0 24.232 13.928 37.993 32.05 37.993 8.836 0 16.624-3.89 22.315-11.07v9.274h19.17zm-35.495-15.108c-10.933 0-18.42-8.526-18.42-21.09 0-12.416 7.487-20.942 18.42-20.942 6.29 0 13.18 3.44 16.325 8.227v25.58c-3.145 4.786-10.034 8.226-16.325 8.226zm162.28 15.108V50.89c0-14.808-7.938-21.838-21.267-21.838-11.083 0-20.668 6.58-24.86 13.013-2.547-8.077-9.436-13.013-20.07-13.013-11.083 0-20.518 6.88-23.663 11.07v-9.275h-19.02v72.247h19.02V54.33c2.846-3.888 8.387-8.376 14.977-8.376 7.787 0 10.782 4.787 10.782 11.518v45.622h19.17V54.33c2.696-3.888 8.237-8.376 14.977-8.376 7.787 0 10.782 4.787 10.782 11.518v45.622h19.17zm29.272-80.026c6.29 0 11.383-5.085 11.383-11.368 0-6.282-5.093-11.218-11.383-11.218-6.14 0-11.232 4.936-11.232 11.218 0 6.283 5.092 11.368 11.232 11.368zm9.585 80.026V30.847h-19.02v72.247h19.02zm87.83 0V52.087c0-14.06-7.638-23.035-23.663-23.035-11.682 0-20.668 5.684-25.31 11.07v-9.275h-19.02v72.247h19.02V54.33c3.294-4.187 8.986-8.376 16.474-8.376 8.088 0 13.33 3.44 13.33 13.463v43.677h19.17z"></path>
                    </g>
                </svg>
            </a>
        </div>
    </div>
</nav>

<dvb-error-messages></dvb-error-messages>

<ui-view name="pageHeader"></ui-view>

<div class="kitadmin-content container" dvb-download>
    <ui-view></ui-view>
</div>

<!-- FIXME: lich: nur angezeigt, wenn authenticated, aber die class ist nur wenn !authenticated? -->
<footer ng-if="isAuthenticated()" class="footer" ng-class="{ 'login': !isAuthenticated() }">
    <dvb-version></dvb-version>
</footer>
