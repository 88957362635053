/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Betreuungsfaktor,
    KinderOrtFraktion,
    KinderOrtFraktionId,
    KitaWochenBelegung,
    Kontingente,
    PlatzTyp,
    ZeitraumFeld,
    ZeitraumFeldStrategy,
} from '@dv/kitadmin/models';
import {
    GruppenBelegung,
    KinderOrtFraktionType,
    PensumType,
    VertraglichesPensum,
    ZeitraumUtil,
} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {checkPresent, DayOfWeek, DvbDateUtil, DvbError, DvbUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KindService} from '../../common/service/rest/kind/kindService';
import type {KinderOrtService} from '../../common/service/rest/kinderort/kinderOrtService';
import {WochenplanUtil} from '../../common/service/wochenplanUtil';
import {BewerbungStrategy} from '../../kinderort/component/dvb-kita-kinder/BewerbungStrategy';
import type {KinderOrtWocheService} from '../../kinderort/service/kinderOrtWocheService';
import {BetreuungsfaktorWithStichtag} from '../zuweisung/dvb-kind-zuweisen/BetreuungsfaktorWithStichtag';
import type {KinderOrtZuweisung} from '../zuweisung/dvb-kind-zuweisen/KinderOrtZuweisung';
import type {KindKinderOrtZuweisung} from '../zuweisung/dvb-kind-zuweisen/KindKinderOrtZuweisung';

export class KindZuweisenService {
    public static $inject: readonly string[] = [
        '$q',
        'kinderOrtWocheService',
        'kinderOrtService',
        'kindService',
        'authStore',
    ];
    public constructor(
        private readonly $q: angular.IQService,
        private readonly kinderOrtWocheService: KinderOrtWocheService,
        private readonly kinderOrtService: KinderOrtService,
        private readonly kindService: KindService,
        private readonly authStore: AuthStore,
    ) {
    }

    private static showGruppenBelegungBemerkung(fraktion: KinderOrtFraktion): boolean {
        return fraktion.dtype === KinderOrtFraktionType.BETREUUNGS_PERSON;
    }

    private static addBemerkungsfeldProGruppe(fraktion: KinderOrtFraktion, model: KindKinderOrtZuweisung): void {
        if (!KindZuweisenService.showGruppenBelegungBemerkung(fraktion)) {
            return;
        }

        const kinderOrtFraktionId = checkPresent(fraktion.id);

        if (!model.bemerkungProGruppe[kinderOrtFraktionId]) {
            model.bemerkungProGruppe[kinderOrtFraktionId] = '';
        }
    }

    private static removeBemerkungsfeldProGruppe(
        kinderOrtFraktionId: KinderOrtFraktionId,
        model: KindKinderOrtZuweisung,
    ): void {
        delete model.bemerkungProGruppe[kinderOrtFraktionId];
    }

    public init(model: KindKinderOrtZuweisung): angular.IPromise<any> {
        if (!DvbDateUtil.isValidMoment(model.firstOfWeek) || !DvbDateUtil.isValidMoment(model.zuweisungAb)) {
            return this.$q.reject();
        }

        const kinderOrt = model.kinderOrtZuweisung.kinderOrt;
        model.aktuelleGruppen = DvbUtil.orderByDisplayName(kinderOrt.getGruppenGueltigOn(model.zuweisungAb));
        model.weekDays = ZeitraumUtil.getWeekDaysFromGruppen(model.aktuelleGruppen);

        const kinderOrtId = kinderOrt.id;
        const params = {
            cache: true,
            includes: '(gruppenWochenBelegungen.fields(wochenPlaetze, verfuegbarkaeit))',
            timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
        };

        return this.updateShowVertraglichesPensum(model)
            .then(() => this.kinderOrtService.getKitaWochenBelegung(kinderOrtId, model.firstOfWeek, params)
                .then(kwb => this.initZeitraumFelderWithKitaWochenBelegung(model, kwb)))
            .catch(DvbRestUtilAngularJS.handleRequestCancellation.bind(DvbRestUtilAngularJS));
    }

    public buildGruppenBelegungen(model: KindKinderOrtZuweisung): GruppenBelegung[] {
        const result: GruppenBelegung[] = [];

        model.aktuelleGruppen.forEach(gruppe => {
            const plaetze = ZeitraumUtil.buildPlaetze(
                gruppe,
                model.zeitraumFelderByGruppe[gruppe.id!],
                model.firstOfWeek);

            const fraktionId = checkPresent(gruppe.id);
            if (plaetze.length > 0) {
                const gruppenBelegung = new GruppenBelegung();
                gruppenBelegung.gruppeId = fraktionId;
                gruppenBelegung.plaetze = plaetze;
                gruppenBelegung.belegungZeiten = model.anwesenheitenByGruppe[fraktionId];

                const stundenArray = model.vertraglichePensenProGruppe[fraktionId];
                if (stundenArray) {
                    stundenArray.filter(vs => angular.isNumber(vs.value))
                        .forEach(vs => {
                            gruppenBelegung.vertraglichePensen.push(vs);
                        });
                }

                const bemerkungProGruppeElement = model.bemerkungProGruppe[fraktionId];
                if (isPresent(bemerkungProGruppeElement)) {
                    gruppenBelegung.bemerkung = bemerkungProGruppeElement;
                }

                result.push(gruppenBelegung);
            } else {
                const zeitraumFelderOfGruppe = model.zeitraumFelderByGruppe[fraktionId].filter(feld => feld.selected);

                if (zeitraumFelderOfGruppe.length > 0) {
                    // Das sollte eigentlich nicht vorkommen
                    throw DvbError.validationError('ERRORS.ERR_NO_PLATZ_FOR_ZEITRAUMFELDER', {
                        gruppe: gruppe.getDisplayName(),
                        zeitraumFelder: zeitraumFelderOfGruppe,
                    });
                }
            }
        });

        return result;
    }

    public addFieldsToGruppenBelegung(
        fraktion: KinderOrtFraktion,
        kontingentId: PlatzTyp,
        model: KindKinderOrtZuweisung,
    ): void {
        this.addVertraglicheStunden(fraktion, kontingentId, model);
        KindZuweisenService.addBemerkungsfeldProGruppe(fraktion, model);
    }

    /**
     * Increases Belegte Plätze
     *
     * @param {ZeitraumFeld} zeitraumFeld
     * @param {KinderOrtFraktion} kinderOrtFraktion the modified KinderOrtFraktion
     * @param {KindKinderOrtZuweisung} model
     */
    public addZeitraumFeldZuweisung(
        zeitraumFeld: ZeitraumFeld,
        kinderOrtFraktion: KinderOrtFraktion,
        model: KindKinderOrtZuweisung,
    ): void {
        this.updateBelegtePlaetze(zeitraumFeld, kinderOrtFraktion, model, true);
    }

    public removeZeitraumFeldZuweisung(
        zeitraumFeld: ZeitraumFeld,
        kinderOrtFraktion: KinderOrtFraktion,
        kontingent: Kontingente | null,
        model: KindKinderOrtZuweisung,
    ): void {
        // reduce belegte plaetze
        this.updateBelegtePlaetze(zeitraumFeld, kinderOrtFraktion, model, false);
        const selectedZeitraumFelder = model.zeitraumFelderByGruppe[kinderOrtFraktion.id!].filter(zf => zf.selected);
        const selectedKontingentZeitraumFelder = selectedZeitraumFelder.filter(zf => zf.kontingentEquals(kontingent));

        if (selectedZeitraumFelder.length === 0) {
            KindZuweisenService.removeBemerkungsfeldProGruppe(kinderOrtFraktion.id!, model);
        }

        if (selectedKontingentZeitraumFelder.length === 0) {
            this.removeVertraglichesPensum(kinderOrtFraktion.id!, kontingent ? kontingent.id : null, model);
        }
    }

    /**
     * @param {ZeitraumFeld} zeitraumFeld field, which got deselected
     * @param {KinderOrtFraktion} kinderOrtFraktion KinderOrtFraktion, which got deselected
     * @param {KindKinderOrtZuweisung} model
     */
    public undoZuweisung(
        zeitraumFeld: ZeitraumFeld,
        kinderOrtFraktion: KinderOrtFraktion,
        model: KindKinderOrtZuweisung,
    ): void {
        const kontingent = zeitraumFeld.kontingent;
        angular.extend(zeitraumFeld, zeitraumFeld.backup);
        zeitraumFeld.selected = false;
        zeitraumFeld.active = false;

        this.removeZeitraumFeldZuweisung(zeitraumFeld, kinderOrtFraktion, kontingent, model);
    }

    public isZuweisungAllowed(
        zeitraumFeld: ZeitraumFeld,
        fraktion: KinderOrtFraktion,
        model: KindKinderOrtZuweisung,
    ): boolean {
        // TODO sollte es moeglich sein, dass ein Kind, das diesen Platz bereits belegt hat, diesen wieder auswaehlen
        // kann?
        const fraktionId = checkPresent(fraktion.id);
        const bzb = ZeitraumUtil.findBetreuungsZeitraumBelegung(
            zeitraumFeld,
            model.gruppenWochenBelegungByGruppe[fraktionId].betreuungsZeitraumBelegung);

        return !!bzb && angular.isNumber(bzb.maxAnzahlPlaetze);
    }

    public initBelegungZeiten(model: KindKinderOrtZuweisung, fraktionId: string): void {
        model.anwesenheitenByGruppe[fraktionId] = {};
        Object.values(DayOfWeek)
            .forEach(dayOfWeek => {
                model.anwesenheitenByGruppe[fraktionId][dayOfWeek] = [];
            });
    }

    /**
     * @param {ZeitraumFeld} zeitraumFeld
     * @param {KinderOrtFraktion} kinderOrtFraktion the modified KinderOrtFraktion
     * @param {KindKinderOrtZuweisung} model
     * @param {boolean} isIncrease
     */
    private updateBelegtePlaetze(
        zeitraumFeld: ZeitraumFeld,
        kinderOrtFraktion: KinderOrtFraktion,
        model: KindKinderOrtZuweisung,
        isIncrease: boolean,
    ): void {
        const bzb = ZeitraumUtil.findBetreuungsZeitraumBelegung(
            zeitraumFeld,
            model.gruppenWochenBelegungByGruppe[kinderOrtFraktion.id!].betreuungsZeitraumBelegung);
        if (!bzb) {
            return;
        }

        const momentOfDay = DvbDateUtil.getDayOfWeekMoment(zeitraumFeld.dayOfWeek, model.firstOfWeek);

        this.getBetreuungsfaktor(model.kinderOrtZuweisung, momentOfDay).then(betreuungsfaktor => {
            if (isIncrease) {
                bzb.belegtePlaetze! += betreuungsfaktor.betreuungsfaktor;
                // Der gleiche Zeitraum soll nicht in zwei verschiedenen Gruppen gleichzeitig ausgewählt werden können.
                // Bei Tageseltern schon.
                if (KinderOrtFraktionType.GRUPPE === kinderOrtFraktion.dtype) {
                    this.unselectGleicheZeitraumfelder(zeitraumFeld, kinderOrtFraktion, model);
                }
            } else {
                bzb.belegtePlaetze! -= betreuungsfaktor.betreuungsfaktor;
            }

            ZeitraumUtil.setupZeitraumfeld(
                zeitraumFeld,
                bzb,
                this.getZeitraumFeldStrategy(checkPresent(kinderOrtFraktion.kinderOrtId)));
        });
    }

    /**
     * @param {ZeitraumFeld} zeitraumFeld
     * @param {KinderOrtFraktion} kinderOrtFraktion the modified KinderOrtFraktion
     * @param {KindKinderOrtZuweisung} model
     */
    private unselectGleicheZeitraumfelder(
        zeitraumFeld: ZeitraumFeld,
        kinderOrtFraktion: KinderOrtFraktion,
        model: KindKinderOrtZuweisung,
    ): void {
        model.aktuelleGruppen
            .filter(kof => kof.id! !== kinderOrtFraktion.id!)
            .forEach(autoUnselectedKinderOrtFraktion => {
                const zeitraumFeldOfGruppe = model.zeitraumFelderByGruppe[autoUnselectedKinderOrtFraktion.id!];
                const selectedField = zeitraumFeldOfGruppe?.filter(feld =>
                    feld.dayOfWeek === zeitraumFeld.dayOfWeek &&
                    feld.selected &&
                    feld.zeitraum.id === zeitraumFeld.zeitraum.id);

                if (selectedField && selectedField.length > 0) {
                    this.undoZuweisung(selectedField[0], autoUnselectedKinderOrtFraktion, model);
                }
            });
    }

    private updateShowVertraglichesPensum(zuweisung: KindKinderOrtZuweisung): angular.IPromise<void> {
        const kinderOrtId = zuweisung.kinderOrtZuweisung.kinderOrt.id;
        const config = {
            cache: true,
            timeout: this.kinderOrtWocheService.getHttpRequestTimeoutPromise(),
        };

        return this.kinderOrtService.getKinderOrtStundenbasierteTarifeOffer(kinderOrtId, zuweisung.zuweisungAb, config)
            .then(result => {
                zuweisung.stundenbasierteTarifeOffer = result;
            });
    }

    private initZeitraumFelderWithKitaWochenBelegung(
        model: KindKinderOrtZuweisung,
        kitaWochenBelegung: KitaWochenBelegung,
    ): void {
        const aktuelleBelegung = model.aktuelleBelegung;

        kitaWochenBelegung.gruppenWochenBelegungen.forEach(gruppenWochenBelegung => {
            const fraktionId = checkPresent(gruppenWochenBelegung.gruppeId);
            model.gruppenWochenBelegungByGruppe[fraktionId] = angular.copy(gruppenWochenBelegung);
        });

        model.anwesenheitenByGruppe = {};
        model.aktuelleGruppen.forEach(gruppe => {
            if (!gruppe.wochenplan) {
                return;
            }

            const wochenplan = gruppe.wochenplan.copyWithShallowTagesplaene();

            const fraktionId = checkPresent(gruppe.id);
            const gruppenWochenBelegungByGruppe = model.gruppenWochenBelegungByGruppe[fraktionId];
            ZeitraumUtil.setBelegungToZeitraumFelder(
                gruppenWochenBelegungByGruppe.betreuungsZeitraumBelegung,
                wochenplan.zeitraumFelder,
                this.getZeitraumFeldStrategy(checkPresent(kitaWochenBelegung.kitaId)));

            model.zeitraumFelderByGruppe[fraktionId] = wochenplan.zeitraumFelder;
            this.initBelegungZeiten(model, fraktionId);

            if (aktuelleBelegung === null) {
                return;
            }

            const gruppenBelegung = DvbUtil.findFirst(aktuelleBelegung.gruppenBelegungen,
                gb => gb.gruppeId === fraktionId);

            if (!gruppenBelegung) {
                return;
            }

            model.vertraglichePensenProGruppe[fraktionId] = angular.copy(gruppenBelegung.vertraglichePensen)
                .sort(model.kinderOrtZuweisung.vertraglichesPensumComparator);

            if (KindZuweisenService.showGruppenBelegungBemerkung(gruppe)) {
                model.bemerkungProGruppe[fraktionId] = gruppenBelegung.bemerkung;
            }

            this.initExistingBelegungZeiten(gruppenBelegung, model, fraktionId);

            const icon = ZeitraumUtil.belegungsZustandToZeitraumFeldIcon(aktuelleBelegung.belegungsZustand);

            const modifiedZeitraumFelder = WochenplanUtil.setGruppenBelegungToWochenplan(
                wochenplan,
                gruppenBelegung,
                model.kinderOrtZuweisung.kontingente,
                true,
                icon);

            modifiedZeitraumFelder.forEach(z => {
                z.backup = angular.copy(z);
                this.addFieldsToGruppenBelegung(gruppe, z.kontingent ? z.kontingent.id : null, model);
            });
        });
    }

    private initExistingBelegungZeiten(
        gruppenBelegung: GruppenBelegung,
        model: KindKinderOrtZuweisung,
        fraktionId: string,
    ): void {
        Object.values(gruppenBelegung.belegungZeiten).forEach(anwesenheiten => {
            if (!model.anwesenheitenByGruppe[fraktionId]) {
                model.anwesenheitenByGruppe[fraktionId] = {};
            }

            anwesenheiten.forEach(anwesenheit => {
                if (!model.anwesenheitenByGruppe[fraktionId][anwesenheit.dayOfWeek]) {
                    model.anwesenheitenByGruppe[fraktionId][anwesenheit.dayOfWeek] = [];
                }
                model.anwesenheitenByGruppe[fraktionId][anwesenheit.dayOfWeek].push(anwesenheit);
            });
        });
    }

    private addVertraglicheStunden(
        kinderOrtFraktion: KinderOrtFraktion,
        kontingentId: PlatzTyp,
        model: KindKinderOrtZuweisung,
    ): void {
        if (!Array.isArray(model.vertraglichePensenProGruppe[kinderOrtFraktion.id!])) {
            model.vertraglichePensenProGruppe[kinderOrtFraktion.id!] = [];
        }

        const stundenArray = checkPresent(model.vertraglichePensenProGruppe[kinderOrtFraktion.id!]);

        const found = stundenArray
            .find(vertraglichesPensum => vertraglichesPensum.kontingentId === kontingentId);

        if (found) {
            return;
        }

        if (!model.stundenbasierteTarifeOffer.get(kontingentId)) {
            this.addVertraglichesPensum(kinderOrtFraktion, kontingentId, stundenArray, model);

            // Tarif for this PlatzTyp is not stundenbasiert and vertragliches pensum is added if necessary
            return;
        }

        const vertraglichesPensum = new VertraglichesPensum(null, null, kontingentId);
        stundenArray.push(vertraglichesPensum);
        stundenArray.sort(model.kinderOrtZuweisung.vertraglichesPensumComparator);
    }

    private addVertraglichesPensum(
        kinderOrtFraktion: KinderOrtFraktion,
        kontingentId: string | null,
        stundenArray: VertraglichesPensum[],
        model: KindKinderOrtZuweisung,
    ): void {
        const kinderOrtId = checkPresent(kinderOrtFraktion.kinderOrtId);
        if (!this.authStore.hasPermission(`${PERMISSION.FEATURE.VERTRAGLICHES_PENSUM}:${kinderOrtId}`)) {
            return;
        }

        const vertraglichesPensum = new VertraglichesPensum(
            null,
            null,
            kontingentId,
            null,
            PensumType.PERCENTAGE);
        stundenArray.push(vertraglichesPensum);
        stundenArray.sort(model.kinderOrtZuweisung.vertraglichesPensumComparator);
    }

    private removeVertraglichesPensum(
        fraktionId: KinderOrtFraktionId,
        kontingentId: PlatzTyp,
        model: KindKinderOrtZuweisung,
    ): void {
        const stundenArray = model.vertraglichePensenProGruppe[fraktionId];
        if (!Array.isArray(stundenArray)) {
            return;
        }

        const found = stundenArray.find(vs => vs.kontingentId === kontingentId);
        if (found) {
            DvbUtil.removeFromArray(found, stundenArray);
        }
    }

    private getBetreuungsfaktor(
        kinderOrtZuweisung: KinderOrtZuweisung,
        stichtag: moment.Moment,
    ): angular.IPromise<Betreuungsfaktor> {

        const matches = kinderOrtZuweisung.betreuungsfaktoren
            .filter(betreuungsfaktor => betreuungsfaktor.stichtag.isSame(stichtag));

        if (matches.length > 0) {
            return this.$q.resolve(matches[0].betreuungsfaktor);
        }

        const params = {stichtag};
        const kindId = kinderOrtZuweisung.zuweisung.kind.id;
        const kinderOrtId = kinderOrtZuweisung.kinderOrt.id;

        return this.kindService.getBetreuungsfaktor(kindId, kinderOrtId, params)
            .then(faktor => {
                kinderOrtZuweisung.betreuungsfaktoren.push(new BetreuungsfaktorWithStichtag(faktor, stichtag));

                return faktor;
            });
    }

    private getZeitraumFeldStrategy(kinderOrtId: string): ZeitraumFeldStrategy {
        const zeitraumFeldStrategy = new BewerbungStrategy();
        zeitraumFeldStrategy.capacityOnly =
            this.authStore.hasPermission(`${PERMISSION.FEATURE.GRP_WEEK_CAPACITY}:${kinderOrtId}`);

        return zeitraumFeldStrategy;
    }
}
