/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    ExternalAnmeldungKind,
    ExternalAnmeldungSetup,
    Translation,
    WochenplanExternalAnmeldung,
    WochenplanModel,
} from '@dv/kitadmin/models';
import {ServiceContainer} from '@dv/kitadmin/models';
import type {DisplayableWithBadge, FunctionType, Nullish} from '@dv/shared/code';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import {getTextForLanguage} from '../../../common/filter/get-text-for-language';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        kind: '<',
        selectedWochenplan: '<?',
        setup: '<?',
        onKinderOrteChanged: '&',
    },
    template: require('./dvb-external-anmeldung-betreuung.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungBetreuung implements angular.IController {

    public static $inject: readonly string[] = [];

    public kind!: ExternalAnmeldungKind;
    public selectedWochenplan?: WochenplanExternalAnmeldung;
    public setup?: ExternalAnmeldungSetup;
    public onKinderOrteChanged!: FunctionType;

    public platzartenTranslated: string[] = [];
    public hasPlatzarten: boolean = false;

    public availableKinderOrte: DisplayableWithBadge[] = [];
    public wochenplanModel?: WochenplanModel;

    public $onChanges(changes: IOnChangesObject): void {
        if (changes.setup) {
            this.availableKinderOrte = this.setup?.getDisplayKinderOrte() ?? [];
        }
        if (changes.selectedWochenplan) {
            this.wochenplanChanged();
        }

        this.setPlatzartenTranslation();
    }

    public wochenplanChanged(): void {
        const selected = this.kind.selectedWochenplan;
        this.wochenplanModel = selected ? this.kind.getOrCreateWochenplanModel(selected) : undefined;
    }

    private setPlatzartenTranslation(): void {
        const platzarten = [];

        if (this.setup?.config?.showSubventionierterPlatz) {
            platzarten.push(this.translationOrFallback(this.setup.config.subventionierterPlatzTranslation,
                'EXTERNAL_ANMELDUNG.SUBVENTIONIERTER_PLATZ'));
        }

        if (this.setup?.config?.showPrivaterPlatz) {
            platzarten.push(this.translationOrFallback(this.setup.config.privaterPlatzTranslation,
                'COMMON.PRIVATER_PLATZ'));
        }

        const dynamicPlatzarten: string[] = this.setup?.platzarten.map(platzart =>
            (platzart.externalAnmeldungName ? getTextForLanguage(platzart.externalAnmeldungName) : platzart.name) ?? '',
        ).filter(value => value) ?? [];

        this.platzartenTranslated = platzarten.concat(dynamicPlatzarten);
        this.hasPlatzarten = this.platzartenTranslated.length > 0;
    }

    private translationOrFallback(privatPlatzTranslation: Translation | Nullish, fallbackKey: string): string {
        return privatPlatzTranslation?.hasTranslations() ? getTextForLanguage(privatPlatzTranslation) :
            ServiceContainer.$translate.instant(fallbackKey);
    }
}

componentConfig.controller = DvbExternalAnmeldungBetreuung;
angular.module('kitAdmin').component('dvbExternalAnmeldungBetreuung', componentConfig);
