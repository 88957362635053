<div class="dvb-editable-pensum">
    <label class="form-control click2edit"
           data-translate="COMMON.PENSUM"
           data-translate-value-pensum="{{vm.ngModel.$modelValue.display()}}">
    </label>

    <div class="edit-fields">
        <dvb-pensum pensum="vm.ngModel.$viewValue"></dvb-pensum>
    </div>
</div>
