<dvlib-day-of-week-form [templateRef]="anstellungZeitenTemplate" />

<ng-template #anstellungZeitenTemplate let-dayOfWeek let-index="index">
    <ng-container [ngModelGroup]="'daily-verfuegbarkeit-' + index">

        <div class="checkbox">
            <label>
                <input type="checkbox"
                       [(ngModel)]="dailyAnstellungen[dayOfWeek].nichtVerfuegbar"
                       (ngModelChange)="onCheckboxChange(dayOfWeek)"
                       name="nichtVerfuegbar">
                {{ 'PERSONAL.ANSTELLUNG.NICHT_VERFUEGBAR' | translate }}
            </label>
        </div>

        @for (range of dailyAnstellungen[dayOfWeek].zeiten; let zeitIdx = $index; track zeitIdx) {
            <fieldset class="daily-anstellung-zeit" [ngModelGroup]="'range-' + zeitIdx">
                @if (!range.von && !range.bis) {
                    <legend class="sr-only" translate="PERSONAL.ANSTELLUNG.ZEIT_FROM_TO_EMPTY">
                    </legend>
                } @else {
                    <legend class="sr-only"
                            translate="PERSONAL.ANSTELLUNG.ZEIT_FROM_TO"
                            [translateParams]="{from: range.von ?? '', to: range.bis ?? ''}">
                    </legend>
                }
                <div class="von-bis">
                    <input type="time"
                           class="form-control input-sm time-input text-center"
                           [attr.aria-label]="'PERSONAL.ANSTELLUNG.ZEIT_FROM' | translate"
                           [tooltip]="'PERSONAL.ANSTELLUNG.ZEIT_FROM' | translate"
                           [(ngModel)]="range.von"
                           name="von"
                           validVon
                           [disabled]="dailyAnstellungen[dayOfWeek].nichtVerfuegbar"
                           [dateStart]="today"
                           [dateEnd]="today"
                           [timeEnd]="range.bis"
                           [required]="!!range.bis">
                    <input type="time"
                           class="form-control input-sm time-input text-center bis"
                           [attr.aria-label]="'PERSONAL.ANSTELLUNG.ZEIT_TO' | translate"
                           [tooltip]="'PERSONAL.ANSTELLUNG.ZEIT_TO' | translate"
                           [(ngModel)]="range.bis"
                           name="bis"
                           validBis
                           [disabled]="dailyAnstellungen[dayOfWeek].nichtVerfuegbar"
                           [dateStart]="today"
                           [dateEnd]="today"
                           [timeStart]="range.von"
                           [required]="!!range.von">
                </div>
                <div class="remove-von-bis">
                    <button type="button"
                            class="btn btn-link floating-text"
                            [tooltip]="'PERSONAL.ANSTELLUNG.REMOVE_AVAILABILITY' | translate"
                            [attr.aria-label]="'PERSONAL.ANSTELLUNG.REMOVE_AVAILABILITY' | translate"
                            (click)="removeVonBis(dayOfWeek, zeitIdx)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </fieldset>
        }
        <button type="button"
                class="btn btn-link floating-text"
                [tooltip]="'PERSONAL.ANSTELLUNG.ADD_AVAILABILITY' | translate"
                [attr.aria-label]="'PERSONAL.ANSTELLUNG.ADD_AVAILABILITY' | translate"
                (click)="addVonBis(dayOfWeek)">+
        </button>
    </ng-container>
</ng-template>
