/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Wochenplan, ZeitraumFeld} from '@dv/kitadmin/models';
import {ZeitraumUtil} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {BetreuungsZeitraum, DayOfWeek, FormContext, Persisted} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {WochenplanService} from '../../../common/service/rest/wochenplanService';
import type {BetreuungsZeitraumService} from '../../service/betreuungsZeitraumService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenplan: '<',
    },
    template: require('./dvb-wochenplan-profil.html'),
    controllerAs: 'vm',
};

export class DvbWochenplanProfil implements angular.IController {
    public static $inject: readonly string[] = [
        'wochenplanService',
        'betreuungsZeitraumService',
        'errorService',
        'dvbStateService',
        'dialogService',
    ];

    public wochenplan!: Persisted<Wochenplan>;
    public enabledDays: DayOfWeek[] = [];
    public activeZeitraumFeld?: ZeitraumFeld;

    public constructor(
        private readonly wochenplanService: WochenplanService,
        private readonly betreuungsZeitraumService: BetreuungsZeitraumService,
        private readonly errorService: ErrorService,
        private readonly dvbStateService: DvbStateService,
        private readonly dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        this.enabledDays = ZeitraumUtil.getWeekDaysFromWochenplan(this.wochenplan);
    }

    public isRequired(param: any): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public updateWochenplan(): void {
        this.wochenplanService.update(this.wochenplan);
    }

    public onZeitraumFeldSelected(zeitraumFeld: ZeitraumFeld): void {
        this.unselectPreviousZeitraumFeld();

        if (this.activeZeitraumFeld === zeitraumFeld) {
            this.onBetreuungsZeitraumCancel();
        } else {
            this.activeZeitraumFeld = zeitraumFeld;
        }
    }

    public onBetreuungsZeitraumCancel(): void {
        if (this.activeZeitraumFeld) {
            this.activeZeitraumFeld.selected = false;
            this.activeZeitraumFeld = undefined;
        }
    }

    public onBetreuungsZeitraumSubmit(betreuungsZeitraum: Persisted<BetreuungsZeitraum>, context: FormContext): void {
        context.startLoading();

        this.betreuungsZeitraumService.update(betreuungsZeitraum)
            .then(() => this.applyBetreuungsZeitraumUpdate(betreuungsZeitraum))
            .then(() => this.onBetreuungsZeitraumCancel())
            .finally(() => context.finishLoading());
    }

    public delete(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.WOCHENPLAN.SINGULAR',
            confirm: () => from(this.wochenplanService.delete(this.wochenplan.id))
                .pipe(take(1), tap(() => this.dvbStateService.goToPreviousState())),
        });
    }

    private applyBetreuungsZeitraumUpdate(betreuungsZeitraum: Persisted<BetreuungsZeitraum>): void {
        this.wochenplan.distinctZeitraeume
            .filter(zeitraum => zeitraum.id === betreuungsZeitraum.id)
            .forEach(zeitraum => Object.assign(zeitraum, betreuungsZeitraum));

        this.wochenplan.zeitraumFelder
            .map(zeitraumFeld => zeitraumFeld.zeitraum)
            .filter(zeitraum => zeitraum.id === betreuungsZeitraum.id)
            .forEach(zeitraum => Object.assign(zeitraum, betreuungsZeitraum));
    }

    private unselectPreviousZeitraumFeld(): void {
        if (this.activeZeitraumFeld) {
            this.activeZeitraumFeld.selected = false;
        }
    }
}

componentConfig.controller = DvbWochenplanProfil;
angular.module('kitAdmin').component('dvbWochenplanProfil', componentConfig);
