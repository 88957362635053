/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontakt: '<',
        uneditableAdresse: '<?',
        adressDatum: '<',
        showRechnungsempfaengerForm: '<',
        onCreateRechnungsempfaenger: '&',
        onDeleteRechnungsempfaenger: '&',
        onRechnungsempfaengerValueChanged: '&',
        onRemoveKontakt: '&',
        onSetAsHauptkontakt: '&',
    },
    template: require('./dvb-kind-kontakt-erfassen.html'),
    controllerAs: 'vm',
};

export class DvbKindKontaktErfassen implements angular.IController {

    public kontakt!: RelationshipWithKontaktperson;
    public uneditableAdresse?: boolean;
    public adressDatum!: moment.Moment;
    public showRechnungsempfaengerForm!: boolean;

    public onCreateRechnungsempfaenger!: FunctionType;
    public onDeleteRechnungsempfaenger!: FunctionType;
    public onRechnungsempfaengerValueChanged!: FunctionType;
    public onRemoveKontakt!: FunctionType;
    public onSetAsHauptkontakt!: FunctionType;

    public changeRechnungsempfaenger(): void {
        if (this.kontakt.relationship.isRechnungsempfaenger()) {
            if (TypeUtil.isFunction(this.onDeleteRechnungsempfaenger)) {
                this.onDeleteRechnungsempfaenger();
            }
        } else if (TypeUtil.isFunction(this.onCreateRechnungsempfaenger)) {
            this.onCreateRechnungsempfaenger();
        }
    }

    public changeRechnungsempfaengerValue(): void {
        if (TypeUtil.isFunction(this.onRechnungsempfaengerValueChanged)) {
            this.onRechnungsempfaengerValueChanged();
        }
    }

    public setAbholberechtigt(): void {
        if (!this.kontakt.relationship.isAbholberechtigt()) {
            this.kontakt.relationship.createAbholberechtigt();
        }
    }
}

componentConfig.controller = DvbKindKontaktErfassen;

angular.module('kitAdmin').component('dvbKindKontaktErfassen', componentConfig);
