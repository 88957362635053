/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {hasOwnPropertyGuarded} from '@dv/shared/code';
import {AUTHORISATION_STRATEGIES} from '../strategy/authorisation-strategies';

const directive: angular.IDirective = {
    restrict: 'A',
    bindToController: {
        onUnauthorisedDisable: '@?',
        requireCondition: '<?',
    },
};

export class AuthorisationController implements angular.IController {
    public static $inject: readonly string[] = ['$attrs'];

    private isGranted: boolean = true;
    private isGrantedRole: boolean = true;
    private isGrantedPermissions: boolean = true;

    public constructor(private $attrs: angular.IAttributes) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.requireCondition && !changes.requireCondition.isFirstChange()) {
            this.updateGranted();
        }
    }

    public grantRole(granted: boolean): void {
        this.isGrantedRole = granted;
        this.updateGranted();
    }

    public grantPermissions(granted: boolean): void {
        this.isGrantedPermissions = granted;
        this.updateGranted();
    }

    private updateGranted(): void {
        this.isGranted = this.isGrantedRole && this.isGrantedPermissions;

        const conditionalGrant = hasOwnPropertyGuarded(this, 'requireCondition') ? this.requireCondition : true;

        const strategy = this.isGranted && conditionalGrant ?
            this.getAuthorisedAccessStrategy() :
            this.getUnauthorisedAccessStrategy();

        strategy(this.$attrs);
    }

    /**
     * Gets strategy for authorised access.
     */
    private getAuthorisedAccessStrategy(): ($attrs: angular.IAttributes) => void {
        return hasOwnPropertyGuarded(this, 'onUnauthorisedDisable') ?
            AUTHORISATION_STRATEGIES.enableElement :
            AUTHORISATION_STRATEGIES.showElement;
    }

    /**
     * Gets strategy for unauthorised access.
     */
    private getUnauthorisedAccessStrategy(): ($attrs: angular.IAttributes) => void {
        return hasOwnPropertyGuarded(this, 'onUnauthorisedDisable') ?
            AUTHORISATION_STRATEGIES.disableElement :
            AUTHORISATION_STRATEGIES.hideElement;
    }
}

directive.controller = AuthorisationController;
angular.module('kitAdmin').directive('dvbAuth', () => directive);
