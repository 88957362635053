/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {ILimited} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entity: '<',
    },
    template: require('./dvb-individual-gueltigkeit.html'),
    controllerAs: 'vm',
};

/**
 * Similar to DvbIndividualGueltigkeit. But meant for displaying ILimited objects which have a gueltigkeit that is not
 * part of a continuous list of entities. Meaning there is not at most one entity for the current date, intersections
 * are possible.
 */
export class DvbIndividualGueltigkeit implements angular.IController {
    public static $inject: readonly string[] = ['$translate'];

    public entity!: ILimited;

    public gueltigkeitText: string = '';

    public constructor(
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(): void {
        this.gueltigkeitText = DvbDateUtil.getGueltigkeitText(this.entity, DvbDateUtil.today(), this.$translate);
    }
}

componentConfig.controller = DvbIndividualGueltigkeit;
angular.module('kitAdmin').component('dvbIndividualGueltigkeit', componentConfig);
