<div class="dvb-anmeldung-kind-form">
    <h2 data-translate="KIND.KIND_STAMMDATEN"></h2>

    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="kindFamilienName"
                   dvb-capitalize-first-letter
                   class="form-control"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.kind.familienName"
                   required>
        </div>
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   name="kindVorName"
                   dvb-capitalize-first-letter
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                   uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.kind.vorName">
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.kind.geburtsTermin"
                                       ng-required="!vm.kind.isGeburtsDatumTerminSet()"
                                       ng-attr-placeholder="{{'KIND.GEBURTSTERMIN' | translate}}">
            </dvb-datepicker-text-field>
        </div>
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.kind.geburtsTag"
                                       ng-required="!vm.kind.isGeburtsDatumTerminSet()"
                                       ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}">
            </dvb-datepicker-text-field>
        </div>
    </div>
    <div class="row form-row">
        <div class="col-md-4">
            <div class="radio" ng-repeat="geschlecht in vm.geschlechte">
                <label>
                    <input type="radio"
                           name="geschlecht"
                           value="{{geschlecht}}"
                           ng-model="vm.kind.geschlecht">
                    <span data-translate="{{'COMMON.' + geschlecht + '_KIND'}}"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <dvb-iso-locale ng-model="vm.kind.nationalitaet"
                            type="COUNTRY"
                            ng-attr-placeholder="{{'COMMON.NATIONALITAET' | translate}}">
            </dvb-iso-locale>
        </div>
        <div class="col-md-6">
            <dvb-iso-locale ng-model="vm.kind.muttersprache"
                            type="LANGUAGE"
                            ng-attr-placeholder="{{'KIND.MUTTERSPRACHE' | translate}}">
            </dvb-iso-locale>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   name="sozialversicherungsNummer"
                   class="form-control"
                   ng-attr-placeholder="{{'KIND.SOZIALVERSICHERUNGSNUMMER' | translate}}"
                   uib-tooltip="{{'KIND.SOZIALVERSICHERUNGSNUMMER' | translate}}"
                   tooltip-placement="top-left"
                   maxlength="100"
                   ng-model="vm.kind.sozialversicherungsNummer"
                   ng-pattern="/^\d+[\d\.]+$/"><!-- FIXME korrekte validierung -->
        </div>
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.kind.anmeldeDatum"
                                       ng-attr-placeholder="{{'KIND.ANMELDEDATUM' | translate}}: {{vm.kind.anmeldeDatum || vm.defaultAnmeldeDatum | amDateFormat: 'DD.MM.YYYY'}}">
            </dvb-datepicker-text-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <textarea ng-model="vm.kind.bemerkung"
                      class="form-control form-section-minus-normal-abstand"
                      msd-elastic
                      ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                      uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                      tooltip-placement="top-left"
                      rows="6"></textarea>
        </div>
    </div>
</div>
