/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Signal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {UserLanguageService} from '@dv/shared/angular';
import {EditorComponent, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import type {RawEditorOptions} from 'tinymce';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-wysiwyg-editor',
    template: '<editor [init]="config()" [(ngModel)]="content" (ngModelChange)="contentChange.emit($event)"/>',
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    ],
    standalone: true,
    imports: [
        FormsModule,
        EditorComponent,
    ],
})
export class WysiwygEditorComponent {

    @Input()
    public set placeholderStr(placeholderStr: string) {
        this.placeholder.set(placeholderStr);
    }

    @Input()
    public content?: string;

    @Output()
    public readonly contentChange = new EventEmitter<string>();

    private placeholder = signal('');

    private userLanguageService = inject(UserLanguageService);
    private language = computed(() => this.userLanguageService.userLanguage() === 'FR' ? 'fr_FR' : 'de');

    public config: Signal<RawEditorOptions> = computed(() => {
        const language = this.language();
        const placeholder = this.placeholder();

        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            height: 375,
            menubar: false,
            statusbar: false,
            language,
            placeholder,
            plugins: 'lists',
            convert_unsafe_embeds: true, // https://github.com/tinymce/tinymce/security/advisories/GHSA-5359-pvf2-pw78
            toolbar: 'h1 h2 formatselect | bold italic underline | bullist numlist',
            block_formats: 'Normal=p; Header 1=h1; Header 2=h2; Header 3=h3',
            formats: {
                underline: {inline: 'u', exact: true},
            },
            // disable style="..." styling
            inline_styles: false,
            body_class: 'dvb-tinymce',
            // hacky way of overwriting styling inside tinymces editor iframe which does not work with our stylesheets
            content_style: 'body { font-family: proxima-nova, sans-serif; line-height: 1; font-size: 14px; color: #38424CFF }',
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    });
}
