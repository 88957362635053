/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UserLanguageService} from '@dv/shared/angular';
import {LogFactory} from '@dv/shared/code';
import type angular from 'angular';
import {switchMap} from 'rxjs';

const LOG = LogFactory.createLog('KitAdminAngularJsTranslationInitializer');

/**
 * Contains language based initializations of angularjs things.
 */
export class KitAdminAngularJsTranslationInitializer {
    public static $inject: readonly string[] = ['userLanguageService', '$translate'];

    public constructor(
        private readonly userLanguageService: UserLanguageService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {

    }

    public init(): void {
        // unclosed subscription because we are in a singleton angularjs service
        this.userLanguageService.userLocale$.pipe(
            switchMap(locale => this.$translate.use(locale)),
        ).subscribe({
            next: value => LOG.trace(`angular-translate language ${value}`),
            error: err => LOG.error('failed changing angular-translate language', err),
        });
    }
}
