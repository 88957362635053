/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {RestPaginated} from '@dv/shared/code';

angular.module('kitAdmin').directive('dvbPagination', dvbPagination);

export class DvbPagination<T> implements angular.IController {
    public static $inject: readonly string[] = ['$q'];

    private static readonly MAX_RESULTS: number = 5;

    public skip: number = 0;

    public canShowMore: boolean = false;
    public canShowLess: boolean = false;

    public items: T[] = [];
    public lastChange: number = 0;

    public isLoading: boolean = false;
    public loadingMore: boolean = false;

    private timeout?: angular.IDeferred<unknown> = undefined;

    public constructor(private $q: angular.IQService) {
    }

    public reset(): void {
        this.skip = 0;
        this.canShowLess = false;
        this.canShowMore = false;
        this.items = [];
        this.lastChange = 0;
    }

    public cancel(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public more(loader: (
        params: RestPaginated,
        config: angular.IRequestShortcutConfig,
    ) => angular.IPromise<T[]>): void {
        this.loadingMore = true;
        this.isLoading = true;

        // We add +1 to the 'top' parameter so we can figure out if there will be more elements available.
        // If the result contains more than MAX_RESULTS, we can show the More button. If exactly MAX_RESULTS or
        // less elements are returned, we can hide it.
        const params = {
            top: DvbPagination.MAX_RESULTS + 1,
            skip: this.skip,
        };

        this.skip += DvbPagination.MAX_RESULTS;

        this.cancel();
        this.timeout = this.$q.defer();

        const config = {
            timeout: this.timeout.promise,
        };

        loader(params, config)
            .then(changes => {
                const items = changes.slice(0, DvbPagination.MAX_RESULTS);
                this.items = this.items.concat(items);
                this.canShowMore = changes.length > DvbPagination.MAX_RESULTS;
                this.lastChange = items.length;
                this.updateCanShowLess();
            })
            .catch(DvbRestUtilAngularJS.handleRequestCancellation)
            .finally(() => {
                this.isLoading = false;
                this.loadingMore = false;
            });
    }

    public less(): void {
        if (this.items.length <= DvbPagination.MAX_RESULTS) {
            return;
        }

        this.items.splice(-this.lastChange, this.lastChange);
        this.skip -= DvbPagination.MAX_RESULTS;
        if (this.lastChange < DvbPagination.MAX_RESULTS) {
            this.lastChange = DvbPagination.MAX_RESULTS;
        }
        this.canShowMore = true;
        this.updateCanShowLess();
    }

    public $onDestroy(): void {
        this.cancel();
    }

    private updateCanShowLess(): void {
        this.canShowLess = this.items.length > DvbPagination.MAX_RESULTS;
    }
}

function dvbPagination(): angular.IDirective {
    return {
        restrict: 'A',
        controller: DvbPagination,
    };
}
