<div class="dvb-kita-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.kitaReportForm" novalidate>

        <dvb-report-template-selector ng-if="vm.showReportTemplate"
                                      report-state="vm.reportState"
                                      on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-custom-field-configuration-selector ng-if="vm.showCustomFieldConfiguration"
                                                 on-selection-change="vm.customFieldConfiguration = customFieldConfiguration">
        </dvb-custom-field-configuration-selector>

        <div class="row">
            <div class="col-xs-12">
                <dvb-search-get-single-entity as-col="true"
                                              entity-to-search="KINDERORT"
                                              ng-change="vm.updateKinderOrt()"
                                              ng-model="vm.kinderOrt"
                                              placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                              required>
                </dvb-search-get-single-entity>
            </div>
        </div>

        <div class="row" ng-show="vm.showStichtag">
            <div class="col-xs-12">
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.stichtag"
                                           ng-attr-placeholder="{{'REPORT.STICHTAG' | translate}}: {{vm.stichtag || vm.defaultStichtag | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div ng-show="vm.showDateRange">
            <dvb-datepicker-text-field ng-change="vm.updateState()"
                                       ng-model="vm.gueltigAb"
                                       dvb-begin-of-month="{{vm.limitDateRangeToMonths}}"
                                       ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
            </dvb-datepicker-text-field>
            <dvb-datepicker-text-field ng-change="vm.updateState()"
                                       ng-model="vm.gueltigBis"
                                       dvb-end-of-month-parser="{{vm.limitDateRangeToMonths}}"
                                       ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
            </dvb-datepicker-text-field>
        </div>

        <div ng-show="vm.showGueltigAb">
            <dvb-datepicker-text-field ng-change="vm.updateState()"
                                       ng-model="vm.gueltigAb"
                                       ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
            </dvb-datepicker-text-field>
        </div>

        <div class="row" ng-show="vm.showYear">
            <div class="col-xs-12">
                <input class="form-control"
                       dvb-integer
                       max="3000"
                       min="2000"
                       ng-change="vm.updateState()"
                       ng-model="vm.year"
                       ng-attr-placeholder="{{'COMMON.YEAR' | translate}}: {{vm.defaultStichtag | amDateFormat: 'YYYY'}}"
                       step="1"
                       tooltip-placement="top-left"
                       type="number"
                       uib-tooltip="{{'COMMON.YEAR' | translate}}: {{vm.year || (vm.defaultStichtag | amDateFormat: 'YYYY')}}">
            </div>
        </div>

        <div class="row form-row" ng-if="vm.showLayout">
            <div class="col-xs-12">
                <dvb-dokument-layout on-change="vm.layout = dokumentLayout">
                </dvb-dokument-layout>
            </div>
        </div>

        <div ng-if="vm.showIncludeExtraPlaetze" class="checkbox normal-abstand-bottom">
            <label>
                <input ng-model="vm.includeExtraPlaetze"
                       type="checkbox">
                <span data-translate="REPORT.BELEGUNGSPLAN.INCLUDE_EXTRA_PLAETZE"></span>
            </label>
        </div>

        <ng-transclude></ng-transclude>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
