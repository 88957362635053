/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import type {Adresse} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
        hideAdresse: '<?',
        adressDatum: '<?',
    },
    template: require('./dvb-kontaktperson-stammdaten-display.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonStammdatenDisplay implements angular.IController {
    public adresse: Adresse | null = null;

    public kontaktperson!: Kontaktperson;
    public hideAdresse?: boolean;
    public adressDatum?: moment.Moment;

    public $onInit(): void {
        if (this.hideAdresse) {
            return;
        }
        const aktuelleLimitedAdresse = this.kontaktperson.getAdresseOn(this.adressDatum);
        this.adresse = aktuelleLimitedAdresse ? aktuelleLimitedAdresse.adresse : null;
    }
}

componentConfig.controller = DvbKontaktpersonStammdatenDisplay;

angular.module('kitAdmin').component('dvbKontaktpersonStammdatenDisplay', componentConfig);
