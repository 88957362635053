<div class="dvb-loading-button">
    <button type="{{vm.type}}"
            class="btn"
            ng-class="{
              'btn-primary': !vm.asLink,
              'btn-link': vm.asLink,
              'btn-link-dropdown': vm.dropdown && vm.asLink,
              'dropdown-toggle': vm.dropdown,
              'btn-margin': vm.margin
            }"
            ng-click="vm.preventDoubleClick($event)"
            ng-disabled="vm.isLoading || vm.isDisabled">
        <div ng-if="vm.isLoading && !vm.spinnerRight" class="spinner">
            <dvlib-spinner [show]="vm.isLoading && !vm.spinnerRight"
                           ng-class="{ 'not-loading': !vm.isLoading }"></dvlib-spinner>
        </div>
        <ng-transclude></ng-transclude>
        <div ng-if="vm.isLoading && !!vm.spinnerRight" class="spinner-right">
            <dvlib-spinner [show]="vm.isLoading && !!vm.spinnerRight"
                           ng-class="{ 'not-loading': !vm.isLoading }">
            </dvlib-spinner>
    </button>
</div>
