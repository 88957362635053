<div class="dvb-plaetze-verlauf">
    <div
        ng-repeat="plaetzeValue in (sortedPlaetzeValues = (vm.plaetzeValues | toArray:false | orderBy:'gueltigAb':true))"
        ng-init="plaetzeValue.isEditMode = false">

        <!-- Beendeter plaetzeValue -->
        <dvb-collapsible-panel
            ng-show="sortedPlaetzeValues[$index-1].gueltigAb.diff(plaetzeValue.gueltigBis, 'days') > 1  || $first && vm.isPlaetzeValueBeendet(plaetzeValue)"
            is-first="$first"
            is-collapsed="true"
            dvb-auth
            require-permission="{{'kita:administrate:' + vm.kita.id}}"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{plaetzeValue.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{plaetzeValue.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.revertPlaetzeValue(plaetzeValue)"></a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="plaetzeValue"
                               is-first="$first && !vm.isPlaetzeValueBeendet(plaetzeValue)"
                               expand-ab="vm.today"
                               expand-bis="vm.today"
                               dvb-auth
                               require-permission="{{'kita:administrate:' + vm.kita.id}}"
                               require-condition="!vm.disablePlaetzeEdit(plaetzeValue.plaetze)"
                               on-unauthorised-disable>
            <panel-header class="plaetze-value">
                <div class="plaetze-value-title">
                    <p>
                        <span data-translate="{{vm.plaetzeValueTitle(plaetzeValue)}}"
                              data-translate-value-preposition="{{vm.temporalPrepositionKey(plaetzeValue) | translate}}"></span>
                        <span ng-bind="plaetzeValue.gueltigAb | amDateFormat:'D.M.YYYY'"></span>
                    </p>
                </div>

                <div class="plaetze-value-plaetze"
                     ng-if="plaetzeValue.plaetze && !vm.disablePlaetzeEdit(plaetzeValue.plaetze)">
                    <p data-translate="{{vm.plaetzeLabel}}"
                       data-translate-value-anzahl="{{plaetzeValue.plaetze}}">
                    </p>
                </div>
            </panel-header>
            <panel-content>
                <form name="updatePlaetzeValueForm"
                      ng-submit="vm.updatePlaetzeValue(updatePlaetzeValueForm, plaetzeValue, vm.updatedPlaetzeValue)"
                      novalidate>
                    <div ng-if="!plaetzeValue.isEditMode" class="plaetze-value">
                        <div class="plaetze-value-title">
                            <p>
                                <span data-translate="{{vm.plaetzeValueTitle(plaetzeValue)}}"
                                      data-translate-value-preposition="{{vm.temporalPrepositionKey(plaetzeValue) | translate}}"></span>
                                <span ng-bind="plaetzeValue.gueltigAb | amDateFormat: 'D.M.YYYY'"></span>
                            </p>

                            <ul class="list-inline normal-abstand-top"
                                ng-if="!vm.disablePlaetzeEdit(plaetzeValue.plaetze)">
                                <li ng-if="plaetzeValue.plaetze">
                                    <button type="button"
                                            class="btn btn-link"
                                            ng-click="plaetzeValue.isEditMode = true; vm.editPlaetzeValue(plaetzeValue)"
                                            data-translate="COMMON.BEARBEITEN">
                                    </button>
                                </li>
                                <li>
                                    <button type="button"
                                            class="btn btn-link"
                                            ng-show="vm.isDeleteAllowed() "
                                            ng-click="vm.deletePlaetzeValue(plaetzeValue)"
                                            data-translate="COMMON.LOESCHEN">
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="plaetze-value-plaetze"
                             ng-if="plaetzeValue.plaetze && !vm.disablePlaetzeEdit(plaetzeValue.plaetze)">
                            <p data-translate="{{vm.plaetzeLabel}}"
                               data-translate-value-anzahl="{{plaetzeValue.plaetze}}"></p>
                        </div>
                    </div>
                    <div ng-if="plaetzeValue.isEditMode" class="plaetze-value">
                        <div class="plaetze-value-title">
                            <p>
                                <span data-translate="{{vm.plaetzeValueTitle(plaetzeValue)}}"
                                      data-translate-value-preposition="{{vm.temporalPrepositionKey(plaetzeValue) | translate}}"></span>
                                <span ng-bind="plaetzeValue.gueltigAb | amDateFormat: 'D.M.YYYY'"></span>
                            </p>

                            <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                                       on-cancel="plaetzeValue.isEditMode = false">
                            </dvb-submit-cancel-buttons>
                        </div>
                        <div class="plaetze-value-plaetze">
                            <input type="number"
                                   name="updatedPlaetzeValue"
                                   dvb-select-on-click
                                   class="input-anzahl-plaetze-value form-control negativ-abstand-top"
                                   ng-attr-placeholder="{{'COMMON.ANZAHL' | translate}}"
                                   uib-tooltip="{{'COMMON.ANZAHL' | translate}}"
                                   tooltip-placement="top-left"
                                   ng-model="vm.updatedPlaetzeValue.plaetze"
                                   required
                                   ng-pattern="/^\d+(\.\d[05]?)?$/"
                                   min="{{vm.minValue}}">
                        </div>
                    </div>
                </form>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
