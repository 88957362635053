/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Translation} from '@dv/kitadmin/models';
import {UserLanguageService} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';

export function getTextForLanguage(translation: Translation): string {
    const language = UserLanguageService.getLocalStorageLanguage();
    const requestedValue = translation.texts[language];

    if (isPresent(requestedValue)) {
        return requestedValue;
    }

    const someValue = Object.values(translation.texts)
        .filter(isPresent);

    return someValue.length > 0 ? someValue[0] : '';
}
