<div class="dvb-administration-main">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{'COMMON.ADMINISTRATION' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="COMMON.ADMINISTRATION">
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.administration.mandanten"
                           data-translate="ADMINISTRATION.NAV_MANDANTEN_UEBERSICHT">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.administration.abrechnung"
                           data-translate="ADMINISTRATION.NAV_MANDANTEN_ABRECHNUNG">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.administration.maintenance"
                           data-translate="ADMINISTRATION.NAV_MANDANTEN_MAINTENANCE">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.administration.features"
                           data-translate="ADMINISTRATION.NAV_FEATURES">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.administration.users.active"
                           data-translate="ADMINISTRATION.NAV_USERS">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.administration.users.notification"
                           data-translate="ADMINISTRATION.NAV_NOTIFICATION">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
