/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {SmallInvoiceMandantKonfiguration} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '=',
    },
    template: require('./dvb-small-invoice-mandant-konfiguration-form.html'),
    controllerAs: 'vm',
};

export class DvbSmallInvoiceMandantKonfigurationForm implements angular.IController {
    public static $inject: readonly string[] = [];

    public konfiguration!: SmallInvoiceMandantKonfiguration;

    public isNew: boolean = false;

    public $onInit(): void {
        if (!this.konfiguration) {
            this.isNew = true;
            this.konfiguration = new SmallInvoiceMandantKonfiguration();
        }
    }
}

componentConfig.controller = DvbSmallInvoiceMandantKonfigurationForm;
angular.module('kitAdmin').component('dvbSmallInvoiceMandantKonfigurationForm', componentConfig);
