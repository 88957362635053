<form #releaseForm="ngForm" (ngSubmit)="submitForm()" class="dvb-form" novalidate>
    <div class="modal-body">
        <h1 [translate]="getHeader()"
            [translateParams]="{name: getKindName(), count: kinder.length}"></h1>

        <div class="row">
            <div class="col-md-6 col-md-offset-3">

                <div class="form-row">
                    <p [translate]="'KIND.MONATSBELEGUNG.AFFECTED_MONTH'"
                       [translateParams]="{month: periodFrom | dvLocalDateFormat: 'MMMM YYYY'}">
                    </p>
                    @if (releaseType.RELEASE === type && recipientAddress) {
                        <p>
                            <span [translate]="'FAKTURA.EMPFAENGER_LABEL'"></span>&nbsp;<span>{{ recipientAddress }}</span>
                        </p>
                    }
                    @if (deadline && releaseType.ADJUST_DEADLINE === type) {
                        <p
                            [translate]="'KIND.MONATSBELEGUNG.PREVIOUS_DEADLINE'"
                            [translateParams]="{date: deadline | dvLocalDateFormat: 'DD.MM.YYYY'}">
                        </p>
                    }
                </div>

                @if (releaseType.WITHDRAW !== type) {
                    <div class="form-row">
                        @if (allowDeadlineModification()) {
                            <dvlib-datepicker-textfield
                                [(ngModel)]="model.deadline"
                                name="deadline"
                                required
                                [placeholder]="(releaseType.ADJUST_DEADLINE === type ?
                                            'KIND.MONATSBELEGUNG.ENTER_NEW_DEADLINE' : 'KIND.MONATSBELEGUNG.ENTER_DEADLINE') | translate">
                            </dvlib-datepicker-textfield>
                        }
                    </div>

                    <div class="form-row">
                        <textarea name="customMailText"
                                  dvlibElasticTextInput
                                  [(ngModel)]="model.customMailText"
                                  [placeholder]="'COMMON.BEMERKUNG' | translate"
                                  [tooltip]="'COMMON.BEMERKUNG' | translate"
                                  maxlength="1000">
                        </textarea>
                    </div>

                    <div class="layout-row">
                        <input type="email"
                               class="form-control"
                               maxlength="255"
                               name="replyToAddress"
                               [(ngModel)]="model.replyToAddress"
                               [placeholder]="'COMMON.ANTWORT_ADRESSE' | translate"
                               [tooltip]="'COMMON.ANTWORT_ADRESSE' | translate">
                        <dv-spf-info [replyToAddress]="model.replyToAddress"></dv-spf-info>
                    </div>
                }

                @if (releaseType.WITHDRAW === type) {
                    <div class="form-row">
                        <div class="inline-block text-left">
                            <div class="radio">
                                <label>
                                    <input type="radio"
                                           name="withdrawType"
                                           [(ngModel)]="selectedWithdrawType"
                                           [value]="withdrawType.READONLY"
                                           [disabled]="!allowDisablingWriteAccess()">
                                    <span [translate]="'KIND.MONATSBELEGUNG.WITHDRAW_WRITE'"></span>
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio"
                                           name="withdrawType"
                                           [(ngModel)]="selectedWithdrawType"
                                           [value]="withdrawType.FULL">
                                    <span [translate]="'KIND.MONATSBELEGUNG.WITHDRAW_ALL'"
                                          [translateParams]="{month: periodFrom | dvLocalDateFormat: 'MMMM'}"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    </div>

    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading">
                        <span [translate]="releaseType.WITHDRAW === type ?
                            'COMMON.SPEICHERN' : 'ADMINISTRATION.EMAIL_SENDEN'"></span>
            </dvlib-loading-button>
            @if (releaseType.CONFIRM !== type) {
                <button type="button"
                        class="btn btn-link"
                        (click)="hide()"
                        [translate]="'COMMON.ABBRECHEN'">
                </button>
            }
            @if (releaseType.CONFIRM === type) {
                <button type="button"
                        class="btn btn-link"
                        (click)="submitForm(false)"
                        [translate]="'KIND.MONATSBELEGUNG.CONFIRM_WITHOUT_EMAIL'">
                </button>
            }
        </dvlib-button-list>
    </div>
</form>
