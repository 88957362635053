<div class="dvb-external-anmeldung-uebernahme">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <h1 data-translate="COMMON.EXTERNAL_ANMELDUNG.MF"
                    data-translate-value-count="1"
                    data-translate-interpolation="messageformat">
                </h1>
                <span class="layout-spacer"></span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">

            <div ng-if="vm.showAssigned">
                <p data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.BEREITS_ZUGEWIESEN"></p>

                <p>
                    <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>
                    <a ui-sref="base.kind.profil({kindId: vm.externalAnmeldung.assignedKindId})">
                        <span ng-bind="vm.kind.getDisplayName()"></span>
                    </a>
                </p>

                <div class="actions">
                    <ul class="list-inline">
                        <li>
                            <button type="button"
                                    ng-if="!vm.kind.bewerbung"
                                    class="btn btn-link normal-abstand-top"
                                    data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.BETREUUNGS_WUNSCH_ERFASSEN"
                                    ng-click="vm.goToNeueBewerbung(vm.externalAnmeldung.assignedKindId)">
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    class="btn btn-link normal-abstand-top"
                                    data-translate="COMMON.ZUWEISUNG_AUFHEBEN"
                                    ng-click="vm.unAssign()">
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div ng-if="vm.showImport">
                <div ng-if="!vm.isAssigned" dvb-accordions allow-multiple="true">

                    <h2 data-translate="EXTERNAL_ANMELDUNG.DETAIL.MATCHES"></h2>

                    <p data-translate="EXTERNAL_ANMELDUNG.DETAIL.MATCHES_INFO_MF"
                       data-translate-value-count="{{vm.suggestedHouseholds.length}}"
                       data-translate-interpolation="messageformat"
                       class="small-abstand-bottom">
                    </p>

                    <dvb-accordion ng-repeat="household in vm.suggestedHouseholds" is-first="$first">
                        <panel-header class="full-width-block">
                            <div class="layout-row items-center row">

                                <div class="col-xs-6">
                                    <span ng-bind="household.matchingKind.getDisplayName() || ('COMMON.ANMELDUNG_UEBERNEHMEN.NO_KIND_MATCH' | translate)"></span>
                                </div>
                                <div class="col-xs-6" ng-if="household.hasMatch()">
                                    <p ng-repeat="gesuchsteller in household.matchingGesuchsteller"
                                       ng-bind="gesuchsteller.getDisplayName()">
                                    </p>
                                </div>
                                <div class="col-xs-6" ng-if="!household.hasMatch()">
                                    <p data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.NO_GESUCHSTELLER_MATCH_MF"
                                       data-translate-interpolation="messageformat">
                                    </p>
                                    <p data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.FAMILIE"
                                       data-translate-value-name="{{household.familienNamen()}}">
                                    </p>
                                </div>
                            </div>
                        </panel-header>
                        <panel-content>
                            <div class="row">
                                <div class="col-md-6 normal-abstand-bottom">
                                    <div ng-if="household.matchingKind" class="normal-abstand-bottom">
                                        <h3 data-translate="COMMON.KIND.SINGULAR"></h3>
                                        <p>
                                            <a ui-sref="base.kind.profil({kindId: household.matchingKind.id})">
                                                <dvb-name-geburtstag-geschlecht person="household.matchingKind">
                                                </dvb-name-geburtstag-geschlecht>
                                            </a>
                                        </p>
                                    </div>
                                    <div ng-if="household.relatedKinder.length > 0"
                                         ng-class="{'normal-abstand-top': household.matchingKind}">
                                        <h3 data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.RELATED_KINDER"></h3>
                                        <p ng-repeat="kind in household.relatedKinder">
                                            <a ui-sref="base.kind.profil({kindId: kind.id})">
                                                <dvb-name-geburtstag-geschlecht person="kind">
                                                </dvb-name-geburtstag-geschlecht>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6"
                                     ng-if="household.kontakteAtAdresse.length > 0 || household.kontakteAtOtherAdresse.length > 0">
                                    <h3 ng-if="household.kontakteAtAdresse.length > 0"
                                        data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.KONTAKT_IN_HOUSEHOLD_MF"
                                        data-translate-value-count="{{household.kontakteAtAdresse.length}}"
                                        data-translate-interpolation="messageformat">
                                    </h3>
                                    <div ng-repeat="kontakt in household.kontakteAtAdresse"
                                         ng-class="{'normal-abstand-bottom': !$last}">
                                        <dvb-kontaktperson-stammdaten-display kontaktperson="kontakt"
                                                                              hide-adresse="true">
                                        </dvb-kontaktperson-stammdaten-display>
                                    </div>
                                    <h3 ng-if="household.kontakteAtOtherAdresse.length > 0"
                                        data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.KONTAKT_IN_OTHER_HOUSEHOLD_MF"
                                        data-translate-value-count="{{household.kontakteAtOtherAdresse.length}}"
                                        data-translate-interpolation="messageformat">
                                    </h3>
                                    <div ng-repeat="kontakt in household.kontakteAtOtherAdresse"
                                         ng-class="{'normal-abstand-bottom': !$last}">
                                        <dvb-kontaktperson-stammdaten-display kontaktperson="kontakt">
                                        </dvb-kontaktperson-stammdaten-display>
                                    </div>
                                </div>
                            </div>
                        </panel-content>
                        <panel-footer>
                            <panel-footer>
                                <button ng-if=" household.matchingKind"
                                        ng-click="vm.assignSelectedKind(household.matchingKind)"
                                        data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.ASSIGN_TO_KIND"
                                        data-translate-value-kind="{{household.matchingKind.getDisplayName()}}"
                                        class="btn btn-link">
                                </button>
                                <button ng-if="!household.matchingKind"
                                        ng-click="vm.addToHousehold(household)"
                                        data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.ADD_KIND_TO_HOUSEHOLD"
                                        class="btn btn-link">
                                </button>
                            </panel-footer>
                        </panel-footer>
                    </dvb-accordion>

                    <ul class="list-inline list-inline-row">
                        <li>
                            <dvb-search-on-click-entity add-label-key="COMMON.ANMELDUNG_UEBERNEHMEN.SEARCH"
                                                        entity-to-search="ANMELDUNG_KIND"
                                                        on-select="vm.confirmAssignment(item)"
                                                        on-select-clear="true"
                                                        on-toggle="vm.isSearchInputShown = searchShown"
                                                        placeholder-key="COMMON.KIND.SINGULAR">
                            </dvb-search-on-click-entity>
                        </li>
                        <li ng-if="!vm.isSearchInputShown">
                            <button class="btn btn-link"
                                    ng-click="vm.showKindErfassenForm()"
                                    data-translate="COMMON.ANMELDUNG_UEBERNEHMEN.NEUES_KIND_ERFASSEN">
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <form ng-if="vm.neuesKindErfassen"
                  name="anmeldeForm"
                  class="dvb-form"
                  ng-submit="!anmeldeForm.$pending && vm.validateAndSaveKind(anmeldeForm.$valid)"
                  novalidate>
                <dvb-anmeldung-kind default-anmelde-datum="vm.defaultAnmeldeDatum"
                                    uneditable-adresse="vm.uneditableAdresse"
                                    on-kind-created="vm.onKindCreated(kind)">
                </dvb-anmeldung-kind>

                <div class="form-section">
                    <h2 data-translate="KIND.BETREUUNGSGRUND"></h2>

                    <dvb-betreuungsgrund betreuungsgruende-ids="vm.kind.betreuungsGruendeIds"></dvb-betreuungsgrund>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <dvb-loading-button type="submit"
                                                    is-loading="anmeldeForm.$pending || vm.isLoading">
                                    <span data-translate="COMMON.SPEICHERN"></span>
                                </dvb-loading-button>
                            </li>
                            <li>
                                <button class="btn btn-link"
                                        ng-click="vm.reset()"
                                        data-translate="COMMON.ZURUECK">
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <h2 data-translate="COMMON.EXTERNAL_ANMELDUNG.MF"
                data-translate-value-count="1"
                data-translate-interpolation="messageformat"></h2>

            <dvb-anmeldung-kind-display anmeldung="vm.anmeldung"></dvb-anmeldung-kind-display>
        </div>
    </div>
</div>
