/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, KinderOrt} from '@dv/kitadmin/models';
import {Betreuungsfaktor} from '@dv/kitadmin/models';
import type {Persisted, RestInclude} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KindService} from '../../../common/service/rest/kind/kindService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        kinderOrte: '<',
        zuweisungAb: '<?',
    },
    template: require('./dvb-zuweisen-header.html'),
    controllerAs: 'vm',
};

export class DvbZuweisenHeader implements angular.IController {

    public static $inject: readonly string[] = ['kindService', '$q'];

    public kind!: Persisted<Kind>;
    public kinderOrte: Persisted<KinderOrt>[] = [];
    public zuweisungAb!: moment.Moment;

    public isBetreuungsfaktorLoading: boolean = false;
    public betreuungsfaktoren: Betreuungsfaktor[] = [];
    public kinderOrtNames: string = '';

    private timeout?: angular.IDeferred<any> = undefined;

    public constructor(private readonly kindService: KindService, private readonly $q: angular.IQService) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.zuweisungAb) {
            this.loadBetreuungsfaktoren();
        }

        this.kinderOrtNames = this.kinderOrte ? this.kinderOrte.map(k => k.getDisplayName()).join('\n') : '';
    }

    private loadBetreuungsfaktoren(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();

        this.isBetreuungsfaktorLoading = true;

        const params = {
            stichtag: this.zuweisungAb || DvbDateUtil.today(),
        };

        const config = {
            cache: true,
            timeout: this.timeout.promise,
        };

        this.$q.all(this.kinderOrte.map(kinderOrt => this.loadBetreuungsfaktor(kinderOrt, params, config)))
            .then(betreuungsfaktoren => {
                const grouped = Betreuungsfaktor.groupByFaktor(betreuungsfaktoren);
                // don't show factor when it is 1 for all KinderOrte
                this.betreuungsfaktoren = grouped.length === 1 && grouped[0].betreuungsfaktor === 1 ? [] : grouped;
            })
            .finally(() => {
                this.isBetreuungsfaktorLoading = false;
            });
    }

    private loadBetreuungsfaktor(kinderOrt: Persisted<KinderOrt>, params: RestInclude & {
        stichtag: moment.Moment;
    }, config?: angular.IRequestShortcutConfig): angular.IPromise<Betreuungsfaktor> {
        return this.kindService.getBetreuungsfaktor(this.kind.id, kinderOrt.id, params, config)
            .then(betreuungsfaktor => {
                // make sure the KinderOrt is set (we don't need get additional data via includes in this use case)
                betreuungsfaktor.kitas.push(kinderOrt);
                betreuungsfaktor.setSpezifischFromKind(this.kind, params.stichtag);

                return betreuungsfaktor;
            })
            .catch(err => {
                if (DvbRestUtil.isRequestCancelled(err)) {
                    return this.$q.resolve(new Betreuungsfaktor(0));
                }

                return this.$q.reject(err);
            });
    }
}

componentConfig.controller = DvbZuweisenHeader;
angular.module('kitAdmin').component('dvbZuweisenHeader', componentConfig);
