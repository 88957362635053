/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Adresse, FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        adresse: '=',
        showEditMode: '=',
        onSave: '&',
        onCancel: '&',
        displayType: '@',
        clickToEdit: '<?',
    },
    template: require('./dvb-adresse.html'),
    controllerAs: 'vm',
};

export class DvbAdresse {
    public static $inject: readonly string[] = [
        '$attrs',
        'errorService',
    ];

    public adresse!: Adresse;
    public showEditMode: boolean = false;
    public displayType!: 'short' | 'long';
    public onSave!: (val: { adresse: Adresse }) => void;
    public onCancel!: FunctionType;
    public clickToEdit?: boolean;

    public editedAdresse: Adresse | null = null;
    public isDisabled = false;

    public constructor(
        private $attrs: angular.IAttributes,
        public readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.showEditMode = this.showEditMode || false;
        this.editedAdresse = angular.copy(this.adresse);

        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.showEditMode) {
            if (changes.showEditMode.currentValue) {
                this.editAdresse();
            } else {
                this.showEditMode = false;
            }
        }
    }

    public saveAdresse(): void {
        if (!this.isAdresseValid(this.editedAdresse!)) {
            return;
        }

        this.showEditMode = false;
        if (angular.equals(this.adresse, this.editedAdresse)) {
            return;
        }

        if (TypeUtil.isFunction(this.onSave)) {
            this.onSave({adresse: this.editedAdresse!});
        }
    }

    public cancel(): void {
        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }

    private editAdresse(): void {
        this.showEditMode = true;

        // Folgende Zeile koennte man entfernen, um die letzte editierte Version zu haben.
        this.editedAdresse = angular.copy(this.adresse);
    }

    private isAdresseValid(adresse: Adresse): boolean {
        const valid = adresse.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }
}

componentConfig.controller = DvbAdresse;
angular.module('kitAdmin').component('dvbAdresse', componentConfig);

