/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ElementRef} from '@angular/core';
import {checkPresent, DvbDateUtil, isNullish, isPresent, TimeRangeUtil} from '@dv/shared/code';
import moment from 'moment';
import type {CalendarEvent} from '../model/CalendarEvent';
import type {LayerConfigByLayerIndex} from '../model/LayerConfig';
import {eventSorter} from './event-sort-util';
import type {TimelineCalendarService} from './timeline-calendar.service';
import type {TimelineCalculationStrategy} from './TimelineCalculationStrategy';

/**
 * Creates hour based placement of calendar events. Meaning CalendarEvents have a width and a position reflective of
 * their start time and duration.
 */
export class TimelineCalculationHourBasedStrategy implements TimelineCalculationStrategy {

    public calculateSpace(
        events: CalendarEvent[][],
        startTime: number,
        endTime: number,
        startDate: moment.Moment,
        endDate: moment.Moment,
    ): CalendarEvent[][] {
        const maxTime = endTime + DvbDateUtil.MINUTES_PER_HOUR;

        return events.map(row => row.map((event, index) => {
            const eventStartMinutes = event.gueltigAb.isSame(startDate, 'day') && isPresent(event.von) ?
                this.getDurationInMinutes(event.von) :
                startTime;

            event.spaceBefore = this.calculateSpaceBefore(index, eventStartMinutes, startTime, row);
            event.spaceAfter = this.calculateSpaceAfter(index, row, event, endDate, maxTime);
            event.completeDuration = this.calculateDuration(event, startDate, endDate, startTime, maxTime);

            if (event.subEvents.length) {
                this.calculateSpace(
                    [event.subEvents],
                    eventStartMinutes,
                    eventStartMinutes + event.completeDuration - DvbDateUtil.MINUTES_PER_HOUR,
                    event.gueltigAb,
                    event.gueltigBis,
                );
            }

            return event;
        }));
    }

    public splitIntoRows(events: CalendarEvent[], layerConfig: LayerConfigByLayerIndex): CalendarEvent[][] {
        const rows: CalendarEvent[][] = [];
        events.sort(eventSorter);

        events.forEach(event => {
            let added = false;
            const noRowSplitting = layerConfig.get(event.layer)?.noRowSplitting ?? false;
            for (const row of rows) {
                const relevantRowEvents = row.filter(z => layerConfig.get(z.layer)?.noRowSplitting !== true);

                const hasAnyOverlapping = noRowSplitting ?
                    false :
                    TimeRangeUtil.hasAnyOverlapping(relevantRowEvents, event);
                const linkedEventInRow = isPresent(event.linkedEvent) && row.some(e => e.id === event.linkedEvent);

                if (linkedEventInRow || !hasAnyOverlapping) {
                    row.push(event);
                    added = true;
                    break;
                }
            }

            if (added) {
                return;
            }
            rows.push([event]);
        });

        // now that we have the rows separated, sort by time-range exclusively
        rows.forEach(entry => {
            entry.sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);
        });

        return rows;
    }

    public limitEvents(
        events: CalendarEvent[],
        startTime: number,
        endTime: number,
        _startDate: moment.Moment,
        _endDate: moment.Moment,
        layerConfig: LayerConfigByLayerIndex,
    ): CalendarEvent[] {
        return events
            .filter(event => layerConfig.get(event.layer))
            .filter(event => {
                if (isNullish(event.von) || isNullish(event.bis)) {
                    return false;
                }

                const eventStartTime = this.getDurationInMinutes(event.von);
                const eventEndTime = this.getDurationInMinutes(event.bis);

                return !(eventEndTime < startTime || eventStartTime > endTime);
            });
    }

    public calculateDropDate(
        event: DragEvent,
        service: TimelineCalendarService,
        timelineElem: ElementRef,
        headerElem: ElementRef,
    ): moment.Moment {

        const minuteWidth = this.determineMinuteWidth(service, headerElem);
        const scrollLeft: number = timelineElem.nativeElement.scrollLeft;
        const dropX = event.clientX + scrollLeft;
        const headerX = timelineElem.nativeElement.offsetLeft;

        const minutesToAdd = Math.floor((dropX - headerX) / minuteWidth);
        const limitedMinutesToAdd = Math.round(minutesToAdd / service.resizeSteps()) * service.resizeSteps();

        return moment(service.startDate()).set('hour', service.startHour()).add(limitedMinutesToAdd, 'minutes');
    }

    private calculateSpaceAfter(
        index: number,
        row: CalendarEvent[],
        event: CalendarEvent,
        endDate: moment.Moment,
        maxTime: number,
    ): number {
        if (index !== row.length - 1) {
            return 0;
        }

        if (!event.gueltigBis.isSame(endDate, 'day')) {
            return 0;
        }

        if (!isPresent(event.bis)) {
            return 0;
        }

        const bis = this.getDurationInMinutes(event.bis);

        if (bis > maxTime) {
            return 0;
        }

        return maxTime - bis;
    }

    private calculateSpaceBefore(
        index: number,
        eventStartMinutes: number,
        startTime: number,
        row: CalendarEvent[],
    ): number {
        const spaceBefore = startTime < eventStartMinutes ? eventStartMinutes - startTime : 0;

        return index === 0
            ? spaceBefore
            : eventStartMinutes - this.getDurationInMinutes(checkPresent(row[index - 1].bis));
    }

    /**
     * @param event the event
     * @param startDate The start date of the scheduler
     * @param endDate The scheduler end date including end time!
     * @param startTime The scheduler start time in minutes from start date 00:00
     * @param maxTime the scheduler minutes to 23:59 form startDate 00:00
     */
    private calculateDuration(
        event: CalendarEvent,
        startDate: moment.Moment,
        endDate: moment.Moment,
        startTime: number,
        maxTime: number,
    ): number {
        const von = isPresent(event.von) ? Math.max(this.getDurationInMinutes(event.von), startTime) : startTime;
        const bis = isPresent(event.bis) ? Math.min(this.getDurationInMinutes(event.bis), maxTime) : maxTime;

        // the event starts and ends the same day
        if (event.gueltigAb.isSame(event.gueltigBis, 'day')) {
            return bis - von;
        }

        // the event does not start and end on this selected day
        if (!event.gueltigAb.isSame(startDate, 'day') && !event.gueltigBis.isSame(endDate, 'day')) {
            return maxTime - startTime;
        }

        // the event starts on this day
        if (event.gueltigAb.isSame(startDate, 'day')) {
            return maxTime - von;
        }

        return bis - startTime;
    }

    private getDurationInMinutes(dateTime: moment.Moment): number {
        return moment.duration(dateTime.format('HH:mm')).asMinutes();
    }

    private determineMinuteWidth(service: TimelineCalendarService, headerElem: ElementRef): number {
        const headerWidth = headerElem.nativeElement.scrollWidth;

        return headerWidth / service.durationMinutes();
    }
}
