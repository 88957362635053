/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt, PlatzTypen} from '@dv/kitadmin/models';
import {JaxBetreuungInFerienzeit} from '@dv/shared/backend/model/jax-betreuung-in-ferienzeit';
import type {FunctionType} from '@dv/shared/code';
import {BelegungsZustand, KindergartenType, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {ZuweisungPopoverHelper} from '../zuweisung-popover-helper';
import type {ZuweisenFormModel} from './ZuweisenFormModel';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        isLoading: '<',
        formModel: '<',
        kinderOrte: '<',
        zuweisungAb: '<?',
        showMonatsBelegungStandard: '<?',
        onZuweisen: '&',
        onCancel: '&',
    },
    template: require('./dvb-zuweisen-form.html'),
    controllerAs: 'vm',
};

export class DvbZuweisenForm implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['errorService', '$document', '$scope'];

    public isLoading: boolean = false;
    public formModel!: ZuweisenFormModel;
    public onZuweisen?: FunctionType;
    public onCancel?: FunctionType;

    public showMonatsBelegungStandard: boolean = false;
    public kinderOrte: KinderOrt[] = [];
    public zuweisungAb: moment.Moment | null = null;

    public popoverHelper!: ZuweisungPopoverHelper;

    public readonly belegungsZustand: typeof BelegungsZustand = BelegungsZustand;
    public readonly kindergartenType: typeof KindergartenType = KindergartenType;

    public kindergartenTypes: KindergartenType[] = Object.values(KindergartenType);
    public betreuungInFerienzeit: JaxBetreuungInFerienzeit[] = Object.values(JaxBetreuungInFerienzeit);

    public constructor(
        private readonly errorService: ErrorService,
        private readonly $document: angular.IDocumentService,
        private readonly $scope: angular.IScope,
    ) {
    }

    public $onInit(): void {
        this.popoverHelper = new ZuweisungPopoverHelper(this.$document, this.$scope, 'parentDefaultPlatzart');
        this.popoverHelper.init();
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    public zuweisen(belegungsZustand: BelegungsZustand, form: angular.IFormController): void {
        // ng-submit kann nicht implementiert werden wegen dem Dropdown, für die Validierung manuell submitten
        form.$setSubmitted();

        const validation = [
            this.validBetreuungsfaktor(form),
            this.validKindergarten(form),
            this.validEingewoehnung(),
        ];

        if (validation.some(v => !v)) {
            return;
        }

        this.formModel.belegungsZustand = belegungsZustand;

        if (TypeUtil.isFunction(this.onZuweisen)) {
            this.onZuweisen();
        }
    }

    public openPopover($event: JQuery.TriggeredEvent, _platzTypen: PlatzTypen): void {
        $event.stopPropagation();

        this.popoverHelper.open(angular.element($event.target).closest('button'));
    }

    private validBetreuungsfaktor(form: angular.IFormController): boolean {
        const validBetreuungsfaktor = !this.formModel.hasBetreuungsfaktor || form.betreuungsfaktor.$valid;
        this.errorService.handleValidationError(validBetreuungsfaktor, 'ERRORS.ERR_FAKTOR');

        return validBetreuungsfaktor;
    }

    private validKindergarten(form: angular.IFormController): boolean {
        const validKindergarten = !this.formModel.hasKindergarten || form.kgType.$valid && form.kgLocation.$valid;
        this.errorService.handleValidationError(validKindergarten, 'ERRORS.ERR_KINDERGARTEN_CHOICE');

        return validKindergarten;
    }

    private validEingewoehnung(): boolean {
        const validEingewoehnung = this.formModel.eingewoehnungPhase ?
            this.formModel.eingewoehnungRange?.isValid() || false :
            true;
        this.errorService.handleValidationError(validEingewoehnung, 'ERRORS.ERR_EINGEWOEHNUNG_VON_BIS');

        return validEingewoehnung;
    }
}

componentConfig.controller = DvbZuweisenForm;
angular.module('kitAdmin').component('dvbZuweisenForm', componentConfig);
