/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IKibonRefNrInfo} from '@dv/kitadmin/models';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {KibonExchangeModulAuswahl} from './KibonExchangeModulAuswahl';
import type {KibonExchangeTagesschuleAnmeldung} from './KibonExchangeTagesschuleAnmeldung';

export class KibonExchangeTagesschuleConfirmation implements IKibonRefNrInfo {

    public constructor(
        public id: string,
        public refNr: string,
        public modulAuswahl: KibonExchangeModulAuswahl[] = [],
        public eintrittsdatum: moment.Moment | null = null,
        public planKlasse: string | null = null,
        public abweichungZweitesSemester: boolean | null = null,
        public bemerkung: string | null = null,
        public unmodified: boolean | null = null,
    ) {
    }

    public static fromAnmeldung(anmeldung: KibonExchangeTagesschuleAnmeldung): KibonExchangeTagesschuleConfirmation {
        return new KibonExchangeTagesschuleConfirmation(
            anmeldung.id,
            anmeldung.refNr,
            angular.copy(anmeldung.modulAuswahl),
            moment(anmeldung.eintrittsdatum),
            anmeldung.planKlasse,
            anmeldung.abweichungZweitesSemester,
            anmeldung.bemerkung,
        );
    }

    public getRefNr(): string {
        return this.refNr;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            eintrittsdatum: DvbRestUtil.momentToLocalDate(this.eintrittsdatum),
            planKlasse: this.planKlasse,
            abweichungZweitesSemester: this.abweichungZweitesSemester,
            bemerkung: this.bemerkung,
            modulAuswahl: this.modulAuswahl.map(auswahl => auswahl.toRestObject()),
            unmodified: this.unmodified,
        };
    }
}
