/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExtraPlatzType, Kontingente, ZeitraumFeld} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import type moment from 'moment';
import {TempExtraPlatz} from './TempExtraPlatz';
import type {TempExtraZeitraumFeld} from './TempExtraZeitraumFeld';

/**
 * Class for holding information of temporarily created exra plaetze in the form of selected TempExtraPlatze for
 * specific dates.
 */
export class TempExtraPlaetzeByDate {

    public static readonly DAY_KEY_FORMAT: string = 'YYYY-MM-DD';

    /**
     * Map of days in the DAY_KEY_FORMAT to a list of ZeitraumFelder that are part of the temporary extra days.
     */
    private plaetzeByDate: { [dayKeyFormat: string]: { [type: string]: TempExtraPlatz } } = {};

    public constructor(
        public readonly fraktionId: string,
    ) {
    }

    /**
     * @param template
     * @param zeitraumFeld
     * @param existingPlatzId id of the existing ExtraPlatz this field belongs to.
     * Null by default, meaning there is no existing extra Platz
     * @param kontingent
     */
    public addZeitraumFeld(
        template: TempExtraPlatz,
        zeitraumFeld: ZeitraumFeld,
        existingPlatzId: string | null,
        kontingent: Kontingente | null,
    ): void {
        const key = this.getDayStr(zeitraumFeld, template.affectedDay);

        if (!this.plaetzeByDate[key]) {
            this.plaetzeByDate[key] = {};
        }
        if (!this.plaetzeByDate[key][template.extraPlatzType]) {
            this.plaetzeByDate[key][template.extraPlatzType] = template;
        }

        this.plaetzeByDate[key][template.extraPlatzType].addFeld(zeitraumFeld, existingPlatzId, kontingent);
        this.forcePlatzChangeDetection(key, template.extraPlatzType);
    }

    /**
     * @return true, if the feld was removed. False otherwise.
     */
    public removeZeitraumFeld(
        zeitraumFeld: ZeitraumFeld,
        firstOfWeek: moment.Moment,
        extraPlatzType: ExtraPlatzType,
    ): boolean {
        const key = this.getDayStr(zeitraumFeld, firstOfWeek);

        if (!this.plaetzeByDate[key]?.[extraPlatzType]) {
            return false;
        }

        const removed = this.plaetzeByDate[key][extraPlatzType].removeFeld(zeitraumFeld);
        if (this.plaetzeByDate[key][extraPlatzType].getFelder().length === 0) {
            delete this.plaetzeByDate[key][extraPlatzType];
        }
        this.forcePlatzChangeDetection(key, extraPlatzType);

        return removed;
    }

    /**
     * Removes all ZeitraumFelder at the given day key.
     */
    public removeByKey(dayKey: string, type: ExtraPlatzType): TempExtraZeitraumFeld[] {
        if (!this.plaetzeByDate[dayKey]?.[type]) {
            return [];
        }

        const removedFelder = this.plaetzeByDate[dayKey][type].getFelder();
        this.plaetzeByDate[dayKey][type].removeAllFelder();
        this.forcePlatzChangeDetection(dayKey, type);

        if (this.plaetzeByDate[dayKey][type].getFelder().length === 0) {
            delete this.plaetzeByDate[dayKey];
        }

        return removedFelder;
    }

    public setExtraPlatzUpdatedByKey(dayKey: string, type: ExtraPlatzType): void {
        if (!this.plaetzeByDate[dayKey]?.[type]) {
            return;
        }

        this.plaetzeByDate[dayKey][type].setExtraPlatzCategoryUpdated(true);
    }

    public getFeld(zeitraumFeld: ZeitraumFeld, firstOfWeek: moment.Moment): TempExtraZeitraumFeld | undefined {
        const key = this.getDayStr(zeitraumFeld, firstOfWeek);

        return this.getByKey(zeitraumFeld, key);
    }

    public getTempPlatz(zeitraumFeld: ZeitraumFeld, firstOfWeek: moment.Moment): TempExtraPlatz | undefined {
        const key = this.getDayStr(zeitraumFeld, firstOfWeek);
        if (!this.plaetzeByDate[key]) {
            return undefined;
        }

        return Object.values(this.plaetzeByDate[key])
            .find(platz => platz.getFelder().find(existing => existing.equals(zeitraumFeld)));
    }

    public contains(zeitraumFeld: ZeitraumFeld, firstOfWeek: moment.Moment): boolean {
        return !!this.getFeld(zeitraumFeld, firstOfWeek);
    }

    public getPlaetze(): { [dayKeyFormat: string]: { [type: string]: TempExtraPlatz } } {
        return this.plaetzeByDate;
    }

    private getByKey(zeitraumFeld: ZeitraumFeld, key: string): TempExtraZeitraumFeld | undefined {
        if (!this.plaetzeByDate[key]) {
            return undefined;
        }

        let result: TempExtraZeitraumFeld | undefined;
        Object.values(this.plaetzeByDate[key]).forEach(platz => {
            const currentResult = platz.getFelder().find(existing => existing.equals(zeitraumFeld));
            if (result && currentResult) {
                const stringified = JSON.stringify(zeitraumFeld);
                throw new Error(`Zeitraumfeld (${stringified}) on ${key} is part of multiple ExtraPlaetze.`);
            }
            result = currentResult;
        });

        return result;
    }

    private getDayStr(zeitraumFeld: ZeitraumFeld, firstOfWeek: moment.Moment): string {
        return DvbDateUtil.getDayOfWeekMoment(zeitraumFeld.dayOfWeek, firstOfWeek)
            .format(TempExtraPlaetzeByDate.DAY_KEY_FORMAT);
    }

    /**
     * Creates a new Object for the TempExtraPlatz associated to the given key.
     *
     * Used so the change detection is triggered for components that take TempExtraPlaetze as an input.
     */
    private forcePlatzChangeDetection(dayKey: string, type: string): void {
        if (!this.plaetzeByDate[dayKey]?.[type]) {
            return;
        }

        this.plaetzeByDate[dayKey][type] = TempExtraPlatz.copy(this.plaetzeByDate[dayKey][type]);
    }
}
