<div class="dvb-kitax-import-preview">
    <form ng-submit="vm.importVerfuegung(importVerfuegungForm)"
          name="importVerfuegungForm"
          class="dvb-form"
          novalidate>

        <div class="form-row row">
            <div class="col-xs-12">
                <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                              required
                                              ng-model="vm.kitaSearchResultEntry"
                                              placeholder-key="{{('COMMON.KINDERORT.AUSWAEHLEN' | translate) + ' (' + vm.verfuegung.importKitaName + ')'}}"
                                              placeholder-translated="true">
                </dvb-search-get-single-entity>
            </div>
        </div>

        <h3 data-translate="KIND.BETREUUNGSGUTSCHEIN"
            data-translate-value-kind="{{vm.verfuegung.importKindName}}">
        </h3>

        <div class="form-row">
            <dvb-kitax-betreuungsgutscheine betreuungs-gutscheine="vm.verfuegung.betreuungsGutscheine"
                                            schema-version="vm.verfuegung.schemaVersion">
            </dvb-kitax-betreuungsgutscheine>
        </div>

        <div class="form-row row big-abstand-top">
            <div class="col-xs-12">
                <ul class="list-inline">
                    <li>
                        <dvb-loading-button type="submit" is-loading="vm.isLoading">
                            <span data-translate="COMMON.IMPORTIEREN"></span>
                        </dvb-loading-button>
                    </li>
                    <li>
                        <a ui-sref="base.kind.tarife.uebersicht" data-translate="COMMON.ABBRECHEN"></a>
                    </li>
                </ul>
            </div>
        </div>
    </form>
</div>
