<div class="dvb-kibon-main">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{'KIBON.KIBON' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="KIBON.KIBON">
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active="active" dvb-auth require-role="SUPPORT_ADMIN">
                        <a ui-sref="{{vm.states.VERFUEGUNGEN_OVERVIEW_RELOADED_STATE.name}}"
                           data-translate="KIBON.NAV_ALLE_VERFUEGUNGEN">
                        </a>
                    </li>
                    <li ui-sref-active="active">
                        <a ui-sref="{{vm.states.VERFUEGUNG_OVERVIEW_STATE.name}}"
                           data-translate="KIBON.NAV_VERFUEGUNGEN">
                        </a>
                    </li>
                    <li ui-sref-active="active">
                        <a ui-sref="{{vm.states.ANFRAGEN_STATE.name}}"
                           data-translate="KIBON.NAV_ANFRAGEN">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth require-role="MANDANT_ADMIN">
                        <a ui-sref="{{vm.states.INSTITUTION_STATE.name}}"
                           data-translate="KIBON.NAV_INSTITUTIONEN">
                        </a>
                    </li>
                    <li ui-sref-active="active">
                        <a ui-sref="{{vm.states.TAGESSCHULE_ANMELDUNG_STATE.name}}"
                           data-translate="KIBON.NAV_TAGESSCHULE_ANMELDUNGEN">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
