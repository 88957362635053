<div class="dvb-kita-faktura-konfiguration-content">

    <div ng-if="!vm.editMode">
        <dvb-kita-faktura-konfiguration-heading konfiguration="vm.konfiguration"
                                                mandant-konfigurationen="vm.mandantKonfigurationen">
        </dvb-kita-faktura-konfiguration-heading>

        <div class="small-abstand-top" ng-switch="vm.konfiguration.dtype">
            <dvb-kita-faktura-small-invoice-content
                ng-switch-when="SMALLINVOICE"
                konfiguration="vm.konfiguration.vendorKonfiguration">
            </dvb-kita-faktura-small-invoice-content>
            <dvb-kita-faktura-dvb-content
                ng-switch-when="DVB"
                konfiguration="vm.konfiguration.vendorKonfiguration"
                konto="vm.konfiguration.konto">
            </dvb-kita-faktura-dvb-content>
        </div>

        <p ng-if="vm.konfiguration.bccActive">
            <strong data-translate="KINDERORT.KONFIGURATION_BCC_AKTIV"></strong>
        </p>

        <p ng-if="vm.konfiguration.hideTarifPosFraktionSuffix">
            <strong data-translate="KINDERORT.KONFIGURATION_TARIF_POS_FRAKTION_SUFFIX_AUSGEBLENDET"></strong>
        </p>

        <ul class="list-inline normal-abstand-top"
            dvb-auth
            require-permission="{{'kita:administrate:' + vm.konfiguration.kitaId}}">
            <li>
                <a href="" ng-click="vm.edit()" data-translate="COMMON.BEARBEITEN"></a>
            </li>
            <li>
                <a href="" ng-click="vm.onDelete()" data-translate="COMMON.LOESCHEN"></a>
            </li>
        </ul>
    </div>

    <div ng-if="vm.editMode">
        <div class="col-xs-12">
            <dvb-kita-faktura-konfiguration-form konfiguration="vm.workingCopyKonfiguration"
                                                 on-submit="vm.onSubmit()"
                                                 on-cancel="vm.onCancel()"
                                                 is-loading="vm.isLoading"
                                                 edit-mode="true"
                                                 konten="vm.konten">
            </dvb-kita-faktura-konfiguration-form>
        </div>
    </div>

</div>
