<div class="dvb-dokumentlayout-profil">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <i class="fa fa-arrows header-icon"
                   uib-tooltip="{{'COMMON.DOKUMENT_LAYOUT.SINGULAR' | translate}}"
                   tooltip-placement="top-left">
                </i>
                <h1 uib-tooltip="{{vm.dokumentLayout.name}}"
                    tooltip-placement="top-left"
                    ng-bind="vm.dokumentLayout.name">
                </h1>
                <span class="layout-spacer"></span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7">
            <dvb-click2-edit edit-mode="vm.editMode">
                <label-part>
                    <div class="form-control click2edit multi-line"
                         ng-click="vm.edit()"
                         dvb-auth
                         require-permission="kita:betreuung_admin:*"
                         on-unauthorised-disable>
                        <dvb-dokumentlayout-content dokument-layout="vm.dokumentLayout">
                        </dvb-dokumentlayout-content>
                    </div>
                </label-part>
                <content-part>
                    <form name="layoutForm" ng-submit="vm.submit(layoutForm)" novalidate>
                        <dvb-dokumentlayout-form dokument-layout="vm.workingCopy"></dvb-dokumentlayout-form>

                        <div class="big-abstand-top">
                            <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                                       on-cancel="vm.cancel()">
                            </dvb-submit-cancel-buttons>
                        </div>
                    </form>
                </content-part>
            </dvb-click2-edit>
            <div class="main-actions" dvb-auth require-permission="kita:betreuung_admin:*">
                <ul class="list-inline">
                    <li>
                        <div class="form-section">
                            <a href=""
                               ng-click="vm.confirmDelete()"
                               data-translate="DOKUMENT_LAYOUT.LOESCHEN"></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
