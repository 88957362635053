<form class="dvb-form" name="betreuungsZeitraumForm" ng-submit="vm.submit(betreuungsZeitraumForm)" novalidate>
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   ng-model="vm.workingCopy.name"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                   tooltip-placement="top-left"
                   required>
        </div>
        <div class="col-md-6" ng-if="vm.showVerpflegungen">
            <select class="form-control"
                    ng-model="vm.workingCopy.verpflegung"
                    ng-options="type as ('WOCHENPLAN.BETREUUNGS_ZEITRAUM.VERPFLEGUNGS_TYP.' + type | translate) for type in vm.verpflegungTypes"
                    tooltip-placement="top-left"
                    uib-tooltip="{{'WOCHENPLAN.BETREUUNGS_ZEITRAUM.VERPFLEGUNG' | translate}}">
                <option data-translate="WOCHENPLAN.BETREUUNGS_ZEITRAUM.VERPFLEGUNG_OHNE" ng-value="null"></option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   ng-model="vm.workingCopy.von"
                   dvb-select-on-click
                   dvb-time-input
                   dvb-moment
                   maxlength="10"
                   required
                   class="form-control time-input"
                   uib-tooltip="{{'COMMON.VON' | translate}}"
                   tooltip-placement="top-left">
        </div>
        <div class="col-md-6">
            <input type="text"
                   ng-model="vm.workingCopy.bis"
                   dvb-select-on-click
                   dvb-time-input
                   dvb-moment
                   maxlength="10"
                   required
                   class="form-control time-input"
                   uib-tooltip="{{'COMMON.BIS' | translate}}"
                   tooltip-placement="top-left">
        </div>
    </div>

    <dvb-submit-cancel-buttons on-cancel="vm.onCancel()"></dvb-submit-cancel-buttons>
</form>
