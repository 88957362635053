/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtFraktionId, KinderOrtId, ZeitraumFeld} from '@dv/kitadmin/models';
import type {DayOfWeek, IZeitraum} from '@dv/shared/code';
import {DvbUtil, hasOwnPropertyGuarded} from '@dv/shared/code';
import angular from 'angular';

export type ZeitraumIdsByFraktion<T = string> = { [key: KinderOrtFraktionId]: { [key in DayOfWeek]?: T[] } };

export type ByDayOfWeek<T> = { [key in DayOfWeek]?: T[] };

export type ZeitraeumeByFraktionAndDay<T extends IZeitraum>
    = { [key: KinderOrtFraktionId]: ByDayOfWeek<T> | undefined };

export class KinderOrtZeitraumFilterModel<T extends IZeitraum> {

    public constructor(
        public kinderOrtId: KinderOrtId,
        /**
         * Array aller Wochentage, welche via Zeiträume ausgewählt sind.
         */
        public selectedWeekDays: DayOfWeek[] = [],
        public zeitraeume: ByDayOfWeek<T> = {
            MONDAY: [],
            TUESDAY: [],
            WEDNESDAY: [],
            THURSDAY: [],
            FRIDAY: [],
            SATURDAY: [],
            SUNDAY: [],
        },
        public fraktionProps: ZeitraeumeByFraktionAndDay<T> = {},
    ) {
    }

    /**
     * @return true when added, false when already existing
     */
    private static addZeitraum<T extends IZeitraum>(
        zeitraeume: T[],
        zeitraum: T,
    ): boolean {

        if (zeitraeume.some(s => s.id === zeitraum.id)) {
            return false;
        }

        zeitraeume.push(zeitraum);

        return true;
    }

    /**
     * @return true when removed, false when not existing
     */
    private static removeZeitraum<T extends IZeitraum>(
        zeitraeume: T[],
        zeitraum: T,
    ): boolean {
        const lengthBefore = zeitraeume.length;
        zeitraeume.filter(bz => bz.id === zeitraum.id)
            .forEach(found => {
                DvbUtil.removeFromArray(found, zeitraeume);
            });

        const lengthAfter = zeitraeume.length;

        return lengthAfter !== lengthBefore;
    }

    private static findSelectedWeekDays<T extends IZeitraum>(
        zeitraeumeByDayOfWeek: ByDayOfWeek<T>,
    ): DayOfWeek[] {
        return DvbUtil.keys(zeitraeumeByDayOfWeek)
            .filter(dayOfWeek => DvbUtil.isNotEmptyArray(zeitraeumeByDayOfWeek[dayOfWeek]));
    }

    /**
     * Bestehende ZeitraumFeld-Filter markieren & nicht mehr existierende Zeitraeume aus dem Filter entfernen
     */
    public initFraktionZeitraumFelder(fraktionId: string, zeitraumFelder: ZeitraumFeld[]): void {
        const fraktionZeitraeume = this.getOrCreateZeitraeumeByFraktion(fraktionId);
        this.initZeitraumFelder(fraktionZeitraeume, zeitraumFelder);
    }

    /**
     * Bestehende ZeitraumFeld-Filter markieren & nicht mehr existierende Zeitraeume aus dem Filter entfernen
     */
    public initKinderOrtZeitraumFelder(zeitraumFelder: ZeitraumFeld[]): void {
        this.initZeitraumFelder(this.zeitraeume, zeitraumFelder);
    }

    public getFraktionZeitraeumeCopy(fraktionId: string, dayOfWeek: DayOfWeek): T[] {
        return angular.copy(this.getFraktionZeitraeume(fraktionId, dayOfWeek));
    }

    public getKinderOrtZeitraeumeCopy(dayOfWeek: DayOfWeek): T[] {
        return angular.copy(this.getKinderOrtZeitraeume(dayOfWeek));
    }

    public getSelectedWeekDays(): DayOfWeek[] {
        return this.selectedWeekDays;
    }

    /**
     * @return true when added, false when already existing
     */
    public addFraktionZeitraum(
        fraktionId: string,
        dayOfWeek: DayOfWeek,
        zeitraum: T,
    ): boolean {
        const zeitraeume = this.getFraktionZeitraeume(fraktionId, dayOfWeek);

        if (KinderOrtZeitraumFilterModel.addZeitraum(zeitraeume, zeitraum)) {
            this.updateSelectedWeekDays();

            return true;
        }

        return false;
    }

    /**
     * @return true when removed, false when not existing
     */
    public removeFraktionZeitraum(fraktionId: string, dayOfWeek: DayOfWeek, zeitraum: T): boolean {
        const zeitraeume = this.getFraktionZeitraeume(fraktionId, dayOfWeek);

        if (KinderOrtZeitraumFilterModel.removeZeitraum(zeitraeume, zeitraum)) {
            this.updateSelectedWeekDays();

            return true;
        }

        return false;
    }

    public getFraktionZeitraeume(gruppeId: string, dayOfWeek: DayOfWeek): T[] {
        const gruppenZeitraeume = this.getOrCreateZeitraeumeByFraktion(gruppeId);
        if (!hasOwnPropertyGuarded(gruppenZeitraeume, dayOfWeek)) {
            gruppenZeitraeume[dayOfWeek] = [];
        }

        return gruppenZeitraeume[dayOfWeek];
    }

    /**
     * @return true when added, false when already existing
     */
    public addKinderOrtZeitraum(dayOfWeek: DayOfWeek, zeitraum: T): boolean {
        const zeitraeume = this.getKinderOrtZeitraeume(dayOfWeek);

        if (KinderOrtZeitraumFilterModel.addZeitraum(zeitraeume, zeitraum)) {
            this.updateSelectedWeekDays();

            return true;
        }

        return false;
    }

    /**
     * @return true when added, false when already existing
     */
    public removeKinderOrtZeitraum(dayOfWeek: DayOfWeek, zeitraum: T): boolean {
        const zeitraeume = this.getKinderOrtZeitraeume(dayOfWeek);

        if (KinderOrtZeitraumFilterModel.removeZeitraum(zeitraeume, zeitraum)) {
            this.updateSelectedWeekDays();

            return true;
        }

        return false;
    }

    public getKinderOrtZeitraeume(dayOfWeek: DayOfWeek): T[] {
        const betreuungsZeitraeume = this.zeitraeume;

        if (!hasOwnPropertyGuarded(betreuungsZeitraeume, dayOfWeek)) {
            betreuungsZeitraeume[dayOfWeek] = [];
        }

        return betreuungsZeitraeume[dayOfWeek]!;
    }

    public getOrCreateZeitraeumeByFraktion(fraktionId: string): { [key in string]: T[] } {

        if (!Object.prototype.hasOwnProperty.call(this.fraktionProps, fraktionId)) {
            if (!DvbUtil.isNotEmptyString(fraktionId)) {
                throw new Error(`Not a valid Fraktion ID: ${JSON.stringify(fraktionId)}`);
            }

            this.fraktionProps[fraktionId] = {
                MONDAY: [],
                TUESDAY: [],
                WEDNESDAY: [],
                THURSDAY: [],
                FRIDAY: [],
                SATURDAY: [],
                SUNDAY: [],
            };
        }

        return this.fraktionProps[fraktionId]!;
    }

    public updateSelectedWeekDays(): void {
        this.selectedWeekDays = KinderOrtZeitraumFilterModel.findSelectedWeekDays(this.zeitraeume);

        // add selected week days from KinderOrtFraktionen
        Object.keys(this.fraktionProps).forEach(gruppeId => {
            KinderOrtZeitraumFilterModel.findSelectedWeekDays(this.fraktionProps[gruppeId] ?? {})
                .forEach(dayOfWeek => {
                    if (!this.selectedWeekDays.includes(dayOfWeek)) {
                        this.selectedWeekDays.push(dayOfWeek);
                    }
                });
        });
    }

    /**
     * Loescht alle BetreuungsZeitraeume
     */
    public clearFraktionenZeitraeume(): void {
        this.fraktionProps = {};
        this.updateSelectedWeekDays();
    }

    public hasSelectedKinderOrtZeitraeume(): boolean {
        return Object.values(this.zeitraeume)
            .some(zeitraeume => zeitraeume && zeitraeume.length > 0);
    }

    public hasSelectedFraktionZeitraeume(fraktionId: string): boolean {
        return Object.values(this.getOrCreateZeitraeumeByFraktion(fraktionId))
            .some(zeitraeume => zeitraeume && zeitraeume.length > 0);
    }

    protected initZeitraumFelder(
        filterZeitraeume: { [key: string]: T[] },
        zeitraumFelder: ZeitraumFeld[],
    ): void {
        angular.forEach(filterZeitraeume, (zeitraeume, dayOfWeek: DayOfWeek) => {
            const zeitraeumeIds = zeitraeume.map(z => z.id);

            const zeitraumFelderByDayOfWeek = zeitraumFelder.filter(zf => zf.dayOfWeek === dayOfWeek);
            const matches = zeitraumFelderByDayOfWeek.filter(zf => zeitraeumeIds.includes(zf.zeitraum.id));
            matches.forEach(zf => {
                zf.selected = true;
            });

            if (matches.length >= zeitraeume.length) {
                return;
            }

            const zeitraeumeIdsFromZeitraumFelder = zeitraumFelderByDayOfWeek.map(zf => zf.zeitraum.id);
            filterZeitraeume[dayOfWeek]
                .filter(bz => !zeitraeumeIdsFromZeitraumFelder.includes(bz.id))
                .forEach(bz => {
                    DvbUtil.removeFromArray(bz, filterZeitraeume[dayOfWeek]);
                });
        });
    }
}
