<div class="dvb-rechnung-heading row">
    <div class="col-xs-1"
         ng-if="vm.selection"
         ng-class="{'double-line-height': vm.mode === vm.displayModes.TRANSAKTION}">

        <div class="checkbox no-margin">
            <label uib-tooltip="{{'KONTAKTPERSON.MAHNSPERRE' | translate}}"
                   tooltip-enable="vm.rechnung.kontaktperson.mahnsperre"
                   tooltip-placement="top-left">
                <input type="checkbox"
                       ng-style="{'pointer-events': vm.rechnung.kontaktperson.mahnsperre ? 'none' : ''}"
                       ng-disabled="vm.rechnung.kontaktperson.mahnsperre"
                       ng-model="vm.selection[vm.rechnung.id]"
                       ng-click="$event.stopPropagation()">
            </label>
        </div>

    </div>
    <div class="col-xs-1" ng-class="{'double-line-height': vm.mode === vm.displayModes.TRANSAKTION}">
        <dvb-rechnung-status status="vm.freshStatus || vm.rechnung.externalStatus"
                             is-loading="vm.freshStatusLoading">
        </dvb-rechnung-status>
    </div>
    <div ng-class="vm.selection ? 'col-xs-10': 'col-xs-11'">
        <div class="row" ng-if="vm.mode === vm.displayModes.TRANSAKTION">
            <div class="col-xs-4">
                <p class="double-line">
                    <strong ng-bind="vm.rechnung.getKitaDisplayName()"></strong>
                    <br>
                    <span ng-bind="vm.rechnung.kontaktpersonDisplayName"></span>
                </p>
            </div>
            <div class="col-xs-4">
                <p class="double-line pull-left small-abstand-right">
                    <strong data-translate="COMMON.RECHNUNG_NR"
                            data-translate-value-nummer="{{vm.rechnung.rechnungsNummer}}">
                    </strong>
                    <br>
                    <span ng-bind="vm.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'"></span>
                </p>
                <p class="pull-right" ng-class="vm.showVersandartBadge ? 'double-line' : 'double-line-height'">
                    <dvb-rechnung-heading-attribute class="pull-right"
                                                    rechnung="vm.rechnung"
                                                    errors="vm.errors">
                    </dvb-rechnung-heading-attribute>

                    <span ng-if="vm.showVersandartBadge">
                        <br>

                        <dvb-versandart-badge class="pull-right"
                                              versandart="vm.rechnung.versandartRechnung"
                                              tooltip="vm.rechnung.kontaktpersonEmail">
                        </dvb-versandart-badge>
                    </span>
                </p>
            </div>
            <div class="col-xs-4 text-right text-nowrap">
                <p class="double-line">
                    <strong data-translate="COMMON.TOTAL_CHF"
                            data-translate-value-chf="{{vm.rechnung.summe | number: 2}}">
                        <!-- don't move <br> on a new line, because it adds a whitespace to the markup that break the
						text-right alignment -->
                    </strong><br>
                    <span data-translate="FAKTURA.AUSSTEHEND_CHF"
                          data-translate-value-chf="{{vm.rechnung.ausstehenderBetrag | number: 2}}"></span>
                </p>
            </div>
        </div>

        <div class="row" ng-if="vm.mode === vm.displayModes.KONTAKTPERSON">
            <div class="col-xs-6 col-sm-4">
                <p ng-bind="vm.rechnung.getKitaDisplayName()"></p>
            </div>
            <div class="col-xs-6 col-sm-5">
                 <span class="pull-left"
                       data-translate="FAKTURA.RECHNUNG_PERDIODE_NR"
                       data-translate-value-periode="{{vm.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'}}"
                       data-translate-value-nummer="{{vm.rechnung.rechnungsNummer}}">
                </span>
                <dvb-rechnung-heading-attribute class="pull-right"
                                                rechnung="vm.rechnung"
                                                errors="vm.errors">
                </dvb-rechnung-heading-attribute>
            </div>
            <div class="hidden-xs col-sm-3 text-right text-nowrap">
                <p data-translate="COMMON.BETRAG_VALUE"
                   data-translate-value-betrag="{{vm.rechnung.summe | number: 2}}">
                </p>
            </div>
        </div>

        <div class="row" ng-if="vm.mode === vm.displayModes.KINDERORT">
            <div class="col-xs-6 col-sm-4">
                <p ng-bind="vm.rechnung.kontaktpersonDisplayName"></p>
            </div>
            <div class="col-xs-6 col-sm-5">
                 <span class="pull-left"
                       data-translate="FAKTURA.RECHNUNG_PERDIODE_NR"
                       data-translate-value-periode="{{vm.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'}}"
                       data-translate-value-nummer="{{vm.rechnung.rechnungsNummer}}">
                </span>
                <dvb-rechnung-heading-attribute class="pull-right"
                                                rechnung="vm.rechnung"
                                                errors="vm.errors">
                </dvb-rechnung-heading-attribute>
            </div>
            <div class="hidden-xs col-sm-3 text-right text-nowrap">
                <p data-translate="COMMON.BETRAG_VALUE"
                   data-translate-value-betrag="{{vm.rechnung.summe | number: 2}}">
                </p>
            </div>
        </div>

        <div class="row" ng-if="vm.mode === vm.displayModes.REVISION">
            <div class="col-xs-6 col-sm-5">
                <span data-translate="FAKTURA.REVISION_ERSTELLT_AM_DATUM"
                      data-translate-value-datum="{{vm.rechnung.uebermittlungsTimestamp | amDateFormat:'D.M.YYYY HH:mm:ss'}}">
                </span>
            </div>
            <div class="col-xs-6 col-sm-5">
                <span ng-if="vm.rechnung.stornierungsTimestamp"
                      data-translate="FAKTURA.REVISION_STORNIERT_AM_DATUM"
                      data-translate-value-datum="{{vm.rechnung.stornierungsTimestamp | amDateFormat:'D.M.YYYY HH:mm:ss'}}">
                </span>
            </div>
            <div class="hidden-xs col-sm-2 text-right text-nowrap">
                <p data-translate="COMMON.BETRAG_VALUE"
                   data-translate-value-betrag="{{vm.rechnung.summe | number: 2}}">
                </p>
            </div>
        </div>
    </div>
</div>
