/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestCache} from '@dv/kitadmin/models';
import {DokumentLayout, TempBlob} from '@dv/kitadmin/models';
import type {RestInclude} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

export class DokumentlayoutService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/dokumentlayouts`;

        public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public createDokumentLayout(dokumentLayout: DokumentLayout): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(DokumentlayoutService.BASE_URL, dokumentLayout.toRestObject());
    }

    public getAllDokumentLayouts(): angular.IPromise<DokumentLayout[]> {
        return DvbRestUtilAngularJS.getModelsArray(DokumentlayoutService.BASE_URL,
            DokumentLayout,
            'dokumentLayouts',
            {},
            true);
    }

    public getDokumentLayout(
        dokumentLayoutId: string,
        params?: RestInclude & RestCache,
    ): angular.IPromise<DokumentLayout> {
        const url = DokumentlayoutService.BASE_URL;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, DokumentLayout, dokumentLayoutId, params)
            .then(checkPresent);
    }

    public updateDokumentLayout(dokumentLayout: DokumentLayout): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${DokumentlayoutService.BASE_URL}/${encodeURIComponent(checkPresent(dokumentLayout.id))}`;

        return this.$http.put(url, dokumentLayout.toRestObject());
    }

    public deleteDokumentLayout(id: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${DokumentlayoutService.BASE_URL}/${encodeURIComponent(id)}`);
    }

    public createLayoutPreview(dokumentLayout: DokumentLayout): angular.IPromise<TempBlob> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${DokumentlayoutService.BASE_URL}/preview`;

        return DvbRestUtilAngularJS.postAndGetModel(url, dokumentLayout.toRestObject(), TempBlob);
    }
}
