/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        items: 'pageItems',
        summary: '?totalSummary',
    },
    bindings: {
        items: '<',
        totalItems: '<',
        isLoading: '<',
        itemsPerPage: '<?',
        currentPage: '<',
        entityName: '<?',
        onUpdatePage: '&',
        onUpdateItemsPerPage: '&',
        maxSize: '<?',
        itemsPerPageOptions: '<?',
        forceEllipses: '<?',
    },
    template: require('./dvb-pages.html'),
    controllerAs: 'vm',
};

export class DvbPages<T> implements angular.IOnInit, angular.IOnChanges {
    public static $inject: readonly string[] = [];

    public readonly items!: T[];
    public readonly totalItems!: number;
    public readonly isLoading!: boolean;
    public itemsPerPage!: number;
    public readonly currentPage!: number;
    public readonly entityName?: string;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public maxSize: number = 7;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public itemsPerPageOptions: readonly number[] = [10, 25, 100, 200];
    public forceEllipses: boolean = true;

    public onUpdatePage!: (obj: { page: number }) => void;
    public onUpdateItemsPerPage!: (obj: { itemsPerPage: number }) => void;

    public minItemsPerPage: number = 0;
    public isInitiallyLoading: boolean = true;

    private isLoadingCounter: number = 0;

    public $onInit(): void {
        this.minItemsPerPage = Math.min(...this.itemsPerPageOptions);
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.itemsPerPage) {
            this.changeItemsPerPage(changes);
        }

        if (changes.items && !this.isInitiallyLoading) {
            this.changeCurrentPage();
        }

        if (changes.isLoading) {
            this.changeIsLoading(changes);
        }
    }

    private changeItemsPerPage(changes: angular.IOnChangesObject): void {
        this.itemsPerPage = changes.itemsPerPage.currentValue ?? this.minItemsPerPage;

        if (!this.itemsPerPageOptions.includes(this.itemsPerPage)) {
            // The user manipulated the query param manually, if he entered an illegal value, we reset.
            this.onUpdateItemsPerPage({itemsPerPage: this.itemsPerPageOptions[0]});
        }
    }

    private changeCurrentPage(): void {
        const pageCount = Math.ceil(this.totalItems / this.itemsPerPage);
        if (this.currentPage > pageCount && this.currentPage !== 1) {
            // currentPage exceeds available pages, reset to page 1
            this.onUpdatePage({page: 1});
        }
    }

    private changeIsLoading(changes: angular.IOnChangesObject): void {
        if (changes.isLoading.currentValue === false && changes.isLoading.isFirstChange()) {
            this.isInitiallyLoading = false;

            return;
        }

        if (changes.isLoading.currentValue === true) {
            this.isLoadingCounter++;
        }

        if (changes.isLoading.currentValue === false && this.isLoadingCounter === 1) {
            this.isInitiallyLoading = false;
            this.changeCurrentPage();
        }
    }
}

componentConfig.controller = DvbPages;
angular.module('kitAdmin').component('dvbPages', componentConfig);
