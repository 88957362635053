/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TempBlob} from '@dv/kitadmin/models';
import type {IPrincipal} from '@dv/shared/authentication/model';
import type angular from 'angular';
import type {AuthService} from '../../../authentication/service/authService';

interface ApplicationControllerScope extends angular.IScope {
    getPrincipal: () => IPrincipal;

    isAuthenticated: () => boolean;

    logout: () => Promise<void>;

    downloadFileByUrl: (blob: TempBlob) => void;
}

export class ApplicationController {
    public static $inject: readonly string[] = ['$scope', 'authService'];

    public constructor(
        private $scope: ApplicationControllerScope,
        private authService: AuthService,
    ) {
        this.$scope.getPrincipal = (): IPrincipal => this.authService.getPrincipal();
        this.$scope.isAuthenticated = (): boolean => this.authService.isAuthenticated();

        this.$scope.logout = async (): Promise<void> => {
            await authService.logoutAndGoToLoginPage();
        };

        this.$scope.downloadFileByUrl = (blob): void => {
            this.$scope.$broadcast('downloadFile', blob);
        };
    }
}

export const APP_ROOT_COMPONENT: angular.IComponentOptions = {
    template: require('./dvb-app-root.html'),
    controllerAs: undefined, // should migrate templates AND scope accessing children to 'vm' syntax.
    controller: ApplicationController,
};
