/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KinderBetreuungsfaktoren, KitaBetreuungsfaktorRegel} from '@dv/kitadmin/models';
import type {RestInclude} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class KitaBetreuungsfaktorRegelService {
    public static $inject: readonly string[] = ['$http'];

    private static kitaBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kinderorte`;
    private static kitaBetreuungsfaktorRegelBaseUrl: Readonly<string> =
        `${CONFIG.restBackend}/api/v1/kitabetreuungsfaktorregeln`;

    public constructor(private $http: angular.IHttpService) {
    }

    /**
     * Create KitaBetreuungsfaktorRegel for Kita
     */
    public createKitaBetreuungsfaktorRegel(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel)
        : angular.IPromise<unknown> {
        const kitaId = checkPresent(kitaBetreuungsfaktorRegel.kitaId);

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KitaBetreuungsfaktorRegelService.kitaBaseUrl}/${encodeURIComponent(kitaId)}` +
            '/betreuungsfaktorregeln';

        return this.$http.post(url, kitaBetreuungsfaktorRegel.toRestObject());
    }

    /**
     * Get all KitaBetreuungsfaktorRegel
     */
    public getKitaBetreuungsfaktorRegeln(kitaId: string): angular.IPromise<KitaBetreuungsfaktorRegel[]> {
        const url = `${KitaBetreuungsfaktorRegelService.kitaBaseUrl}/` +
            `${encodeURIComponent(kitaId)}/betreuungsfaktorregeln`;

        return DvbRestUtilAngularJS.getModelsArray(url, KitaBetreuungsfaktorRegel, 'kitaBetreuungsfaktorRegeln');
    }

    /**
     * Delete KitaBetreuungsfaktorRegel
     */
    public deleteKitaBetreuungsfaktorRegel(kitaBetreuungsfaktorRegelId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KitaBetreuungsfaktorRegelService.kitaBetreuungsfaktorRegelBaseUrl}/` +
            `${encodeURIComponent(kitaBetreuungsfaktorRegelId)}`;

        return this.$http.delete(url);
    }

    /**
     * Verlaengert die Gueltigkeit der zugewiesenen BetreuungsfaktorRegel bis zur naechst gueltigen zugewiesenen
     * BetreuungsfaktorRegel oder bis END_OF_TIME
     */
    public extendKitaBetreuungsfaktorRegel(kitaBetreuungsfaktorRegelId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KitaBetreuungsfaktorRegelService.kitaBetreuungsfaktorRegelBaseUrl}/` +
            `${encodeURIComponent(kitaBetreuungsfaktorRegelId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    public terminateKitaBetreuungsfaktorRegel(kitaId: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KitaBetreuungsfaktorRegelService.kitaBaseUrl}/` +
            `${encodeURIComponent(kitaId)}/betreuungsfaktorregeln/enddate`;

        return this.$http.put(url, {date: DvbRestUtil.momentToLocalDate(endDate)});
    }

    public queryKinderBetreuungsfaktoren(kitaId: string, kinderIdList: string[], params: RestInclude & {
        stichtag: moment.Moment;
    }, config?: angular.IRequestShortcutConfig): angular.IPromise<KinderBetreuungsfaktoren> {
        const url = `${KitaBetreuungsfaktorRegelService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/betreuungsfaktoren`;

        const matrixParams = {
            stichtag: DvbRestUtil.momentToLocalDate(params.stichtag),
            includes: params?.includes,
        };

        return this.$http.put(url + DvbRestUtil.encodeMatrixParams(matrixParams), {ids: kinderIdList}, config)
            .then(response => KinderBetreuungsfaktoren.apiResponseTransformer(response.data));
    }
}
