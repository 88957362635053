/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtId} from '@dv/kitadmin/models';
import {TarifParameter} from '@dv/kitadmin/models';
import type {ILimited, IPersistable, Persisted} from '@dv/shared/code';
import {
    BEGIN_OF_TIME,
    checkPersisted,
    checkPresent,
    DvbRestUtil,
    END_OF_TIME,
    orderValueThenDisplayabelComparator,
} from '@dv/shared/code';
import type moment from 'moment';
import {KindMonatlicheStundenErfassung} from './KindMonatlicheStundenErfassung';
import {MonatlicheStundenerfassungStatus} from './MonatlicheStundenerfassungStatus';

export class BetreuungsPersonMonatlicheStundenErfassung implements ILimited, IPersistable {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment = BEGIN_OF_TIME,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public status: MonatlicheStundenerfassungStatus = MonatlicheStundenerfassungStatus.OFFEN,
        public freigegebenDurch: string | null = null,
        public freigegebenDurchId: string | null = null,
        public freigegebenAm: moment.Moment | null = null,
        public tarifParameterByKinderOrtId: { [k in KinderOrtId]: Persisted<TarifParameter>[] } = {},
        public kinder: KindMonatlicheStundenErfassung[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsPersonMonatlicheStundenErfassung {
        const answer = new BetreuungsPersonMonatlicheStundenErfassung();

        answer.id = data.id;
        answer.gueltigAb = checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb));
        answer.gueltigBis = checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb));
        answer.status = data.status;
        answer.freigegebenDurch = data.freigegebenDurch;
        answer.freigegebenDurchId = data.freigegebenDurchId;
        answer.freigegebenAm = DvbRestUtil.localDateTimeToMoment(data.freigegebenAm);

        Object.entries(data.tarifParameterByKinderOrtId).forEach(([kinderOrtId, params]) => {
            if (!Array.isArray(params)) {
                return;
            }

            const tarifParameters = params.map(p => TarifParameter.apiResponseTransformer(p))
                .map(checkPersisted);
            tarifParameters.sort(orderValueThenDisplayabelComparator);
            answer.tarifParameterByKinderOrtId[kinderOrtId] = tarifParameters;
        });

        answer.kinder = data.kinder.map((k: any) => KindMonatlicheStundenErfassung.apiResponseTransformer(k));

        return answer;
    }
}
