/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {LeistungsTyp} from '@dv/kitadmin/models';
import {CalculatedStundenKontingent, Kind, Leistungsrechnung, Tarif, TarifParameterValue} from '@dv/kitadmin/models';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export type MonatsBlattEntryTarif = {
    gueltigAb: moment.Moment;
    gueltigBis: moment.Moment;
    tarif: Tarif;
    leistungsTyp: LeistungsTyp;
    kontingentId: string;
    stunden: number | null;
    parameterValues: TarifParameterValue[];
    zusatzFraktionBelegungId: string | null;
};

export class MonatsBlattEntry {

    public constructor(
        public kind: Kind,
        public leistungsrechnung: Leistungsrechnung,
        public tarife: MonatsBlattEntryTarif[],
        public stundenKontingente: CalculatedStundenKontingent[],
    ) {
    }

    public static apiResponseTransformer(data: any): MonatsBlattEntry {
        return new MonatsBlattEntry(
            Kind.apiResponseTransformer(data.kind),
            Leistungsrechnung.apiResponseTransformer(data.leistungsrechnung),
            data.tarife.map((dataTarif: any) => ({
                gueltigAb: DvbRestUtil.localDateToMoment(dataTarif.gueltigAb),
                gueltigBis: DvbRestUtil.localDateToMoment(dataTarif.gueltigBis),
                tarif: Tarif.apiResponseTransformer(dataTarif.tarif),
                leistungsTyp: dataTarif.leistungsTypDiscriminator,
                kontingentId: dataTarif.kontingentId,
                stunden: dataTarif.stunden,
                parameterValues: dataTarif.parameterValues
                    .map((paramValue: any) => TarifParameterValue.apiResponseTransformer(paramValue)),
                zusatzFraktionBelegungId: dataTarif.zusatzFraktionBelegungId,
            })),
            data.stundenKontingente
                .map((kontingent: any) => CalculatedStundenKontingent.apiResponseTransformer(kontingent)),
        );
    }
}
