/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil, isPresent, REPORT_TYPE} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {CustomFieldConfiguration, ParameterBelegungsplan} from '../../models';
import {ERR_VALIDATION, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        gueltigAb: '<?',
        gueltigBis: '<?',
        limitDateRangeToMonths: '<?',
    },
    template: require('./dvb-report-belegungsplan.html'),
    controllerAs: 'vm',
};

export class DvbReportBelegungsplan implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', 'authStore', '$q'];

    public readonly reportState!: ReportState;
    public limitDateRangeToMonths: boolean = false;
    public kinderOrt: SearchResultEntry | null = null;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment | null = null;

    public defaultStichtag: moment.Moment = DvbDateUtil.today();

    public defaultGueltigAb: moment.Moment = DvbDateUtil.today().startOf('month');
    public defaultGueltigBis: moment.Moment = DvbDateUtil.today().endOf('month');
    public gruppeSearchResultEntry: SearchResultEntry | null = null;

    public reportTemplate: ReportTemplate | null = null;
    public customFieldConfiguration: CustomFieldConfiguration | null = null;

    public includeExtraPlaetze: boolean = true;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private authStore: AuthStore,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (this.limitDateRangeToMonths) {
            this.defaultGueltigAb = DvbDateUtil.startOfMonth(DvbDateUtil.today());
            this.defaultGueltigBis = DvbDateUtil.endOfMonth(DvbDateUtil.today());
            this.gueltigAb = this.gueltigAb ? DvbDateUtil.startOfMonth(this.gueltigAb) : null;
            this.gueltigBis = this.gueltigBis ? DvbDateUtil.endOfMonth(this.gueltigBis) : null;
        }
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateState(): void {
        this.reportService.updateStateParams({
            gueltigAb: this.gueltigAb,
            gueltigBis: this.gueltigBis,
        });
    }

    public onSubmit(form: angular.IFormController): angular.IPromise<TempBlob> {
        this.errorService.clearAll();
        const formValid = form.$valid;
        const searchResultValid = isPresent(this.gruppeSearchResultEntry) || isPresent(this.kinderOrt);

        this.errorService.handleValidationError(searchResultValid, 'ERRORS.ERR_KITA_UND_GRUPPE');
        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!formValid || !searchResultValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        const params: ParameterBelegungsplan = {
            gueltigAb: this.gueltigAb ?? this.getDefaultGueltigAb(),
            gueltigBis: this.gueltigBis ?? this.getDefaultGueltigBis(),
            reportType: REPORT_TYPE.check(this.reportState.key),
            reportTemplate: this.reportTemplate,
            includeExtraPlaetze: this.includeExtraPlaetze,
            customFieldConfiguration: this.customFieldConfiguration,
        };

        return this.getDownloadPromise(params);
    }

    public getKinderOrtFraktionPlaceholderKey(): string {
        return this.authStore.hasPermission(PERMISSION.MODULE.TAGESELTERN) ?
            'COMMON.GRUPPE_ODER_BETREUUNGS_PERSON.AUSWAEHLEN' :
            'COMMON.GRUPPE.AUSWAEHLEN';
    }

    private getDownloadPromise(params: ParameterBelegungsplan): angular.IPromise<TempBlob> {
        if (this.kinderOrt) {
            return this.reportState === ReportState.CONTROLLING.BELEGUNGSPLAN_MONTHLY ?
                this.reportService.getTempBlobForBelegungsplanMonthlyKitaReport(this.kinderOrt.id, params) :
                this.reportService.getTempBlobForBelegungsplanKitaReport(this.kinderOrt.id, params);
        }

        if (this.gruppeSearchResultEntry) {
            return this.reportState === ReportState.CONTROLLING.BELEGUNGSPLAN_MONTHLY ?
                this.reportService.getTempBlobForBelegungsplanGruppeMonthlyReport(
                    this.gruppeSearchResultEntry.id, params) :
                this.reportService.getTempBlobForBelegungsplanGruppeReport(this.gruppeSearchResultEntry.id, params);
        }

        return this.$q.reject(ERR_VALIDATION);
    }

    private getDefaultGueltigAb(): moment.Moment {
        return this.reportState === ReportState.CONTROLLING.BELEGUNGSPLAN_MONTHLY ?
            this.defaultGueltigAb :
            this.defaultStichtag;
    }

    private getDefaultGueltigBis(): moment.Moment {
        return this.reportState === ReportState.CONTROLLING.BELEGUNGSPLAN_MONTHLY ?
            this.defaultGueltigBis :
            this.defaultStichtag;
    }
}

componentConfig.controller = DvbReportBelegungsplan;
angular.module('kitAdmin').component('dvbReportBelegungsplan', componentConfig);
