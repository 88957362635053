<div class="dvb-kita-faktura-mahnlauf hidden-print">
    <div class="big-abstand-bottom">

        <h2 data-translate="KINDERORT.MAHNLAUF"></h2>

        <p ng-if="vm.totalItems === 0 && !vm.isLoading" data-translate="KINDERORT.KEINE_RECHNUNGEN_AUSGEWAEHLT"></p>

        <div class="btn-group"
             uib-dropdown
             data-keyboard-nav
             ng-if="vm.totalItems > 0 && !vm.isLoading">

            <dvb-loading-button type="button"
                                is-loading="vm.isLoading"
                                dropdown="true"
                                margin="true"
                                as-link="true"
                                uib-dropdown-toggle>
                <span data-translate="KINDERORT.MAHNLAUF_ERSTELLEN"></span>
                <span class="caret"></span>
            </dvb-loading-button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                <li ng-repeat="status in vm.mahnungStates">
                    <a href="" ng-click="vm.startMahnlauf(status)">
                        <i class="status-icon" ng-class="status.icon"></i>
                        <span data-translate="{{status.msgKey}}"></span>
                    </a>
                </li>
            </ul>

        </div>
    </div>
</div>
