<div class="dvb-betreuungs-schluessel-assignment">

    <div class="row">
        <div class="col-md-7">

            <h2 data-translate="FRAKTION.BETREUUNGS_SCHLUESSEL.ZUGEWIESENE"></h2>

            <p ng-if="vm.sortedEntities.length === 0"
               class="normal-abstand-bottom"
               data-translate="FRAKTION.BETREUUNGS_SCHLUESSEL.KEINE_ZUWEISUNG">
            </p>

            <div ng-repeat="entity in vm.sortedEntities"
                 ng-init="workingCopy = (entity | copy)">
                <dvb-limited-entity-verlauf entity="entity"
                                            previous-entity="vm.controller.assigned[$index - 1]"
                                            first="$first"
                                            translation-root="'FRAKTION.BETREUUNGS_SCHLUESSEL'"
                                            on-remove="vm.remove(entity)"
                                            on-submit="vm.save(workingCopy)"
                                            on-revert="vm.revert(entity)">
                    <entity-form>
                        <dvb-betreuungs-schluessel-assignment-form entity="workingCopy"
                                                                   betreuungs-schluessel="vm.controller.available"
                                                                   disable-gueltigkeit="true">
                        </dvb-betreuungs-schluessel-assignment-form>
                    </entity-form>
                    <entity-heading>
                        <dvb-limited-entity-heading entity="entity">
                            <entity-heading>
                                <p ng-if="entity.betreuungsSchluessel">
                                    <a ui-sref="{{vm.schluesselState.name + '({id: entity.betreuungsSchluessel.id})'}}"
                                       ng-bind="entity.betreuungsSchluessel.name">
                                    </a>
                                </p>
                                <p ng-if="!entity.betreuungsSchluessel.name"
                                   data-translate="FRAKTION.BETREUUNGS_SCHLUESSEL.KEINE_ZUWEISUNG"></p>
                            </entity-heading>
                        </dvb-limited-entity-heading>
                    </entity-heading>
                    <entity-read-only>
                        <dvb-limited-entity-heading entity="entity">
                            <entity-heading>
                                <p ng-if="entity.betreuungsSchluessel">
                                    <a ui-sref="{{vm.schluesselState.name + '({id: entity.betreuungsSchluessel.id})'}}"
                                       ng-bind="entity.betreuungsSchluessel.name">
                                    </a>
                                </p>
                                <p ng-if="!entity.betreuungsSchluessel.name"
                                   data-translate="FRAKTION.BETREUUNGS_SCHLUESSEL.KEINE_ZUWEISUNG"></p>
                            </entity-heading>
                        </dvb-limited-entity-heading>
                    </entity-read-only>
                </dvb-limited-entity-verlauf>
            </div>

            <dvb-limited-entity-assignment-controls entities="vm.sortedEntities"
                                                    assigned-entity="vm.controller.entity"
                                                    translation-root="'FRAKTION.BETREUUNGS_SCHLUESSEL'"
                                                    on-save="vm.save(vm.newAssignment, context)"
                                                    on-terminate="vm.terminate(endDate, mode)">

                <entity-form>
                    <dvb-betreuungs-schluessel-assignment-form entity="vm.newAssignment"
                                                               betreuungs-schluessel="vm.controller.available">
                    </dvb-betreuungs-schluessel-assignment-form>
                </entity-form>
            </dvb-limited-entity-assignment-controls>

        </div>
    </div>

</div>
