<span class="dvb-offener-posten-text">
    <span ng-switch="vm.offenerPosten.dtype">
        <span ng-switch-when="VORAUSZAHLUNG" data-translate="COMMON.VORAUSZAHLUNG"></span>

        <span ng-switch-when="RECHNUNGS_REVISION">
            <span data-translate="KONTAKTPERSON.RECHNUNG_LABEL_PERIODE"
                  data-translate-value-periode="{{vm.offenerPosten.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'}}">
            </span>
        </span>

        <span ng-switch-when="VORAUSZAHLUNG_VERBUCHT"
              data-translate="COMMON.VORAUSZAHLUNG_VERBUCHT"
              data-translate-value-datum="{{vm.offenerPosten.vorauszahlung.datum | amDateFormat:'D.M.YYYY'}}">
        </span>

        <span ng-switch-when="EINZAHLUNG"
              data-translate="{{'KONTAKTPERSON.ZAHLUNG_' + vm.offenerPosten.zahlung.type}}"
              data-translate-value-periode="{{vm.offenerPosten.rechnung.gueltigAb | amDateFormat:'MMMM YYYY'}}">
        </span>

        <span ng-switch-when="RUECKERSTATTUNG" data-translate="COMMON.RUECKERSTATTUNG"></span>

        <span ng-switch-when="RUECKERSTATTUNG_AUSBEZAHLT"
              data-translate="COMMON.RUECKERSTATTUNG_AUSBEZAHLT"
              data-translate-value-datum="{{vm.offenerPosten.rueckerstattung.datum | amDateFormat:'D.M.YYYY'}}">
        </span>

        <span ng-switch-when="GEBUEHR"
              data-translate="{{'COMMON.GEBUEHR.' + vm.offenerPosten.gebuehr.gebuehrType}}"
              data-translate-value-datum="{{vm.offenerPosten.datum | amDateFormat:'D.M.YYYY'}}">
        </span>

        <span ng-switch-when="GEBUEHR_COMPENSATED"
              data-translate="{{'COMMON.GEBUEHR.' + vm.offenerPosten.gebuehr.gebuehrType + '_VERBUCHT'}}"
              data-translate-value-datum="{{vm.offenerPosten.datum | amDateFormat:'D.M.YYYY'}}">
        </span>

        <span ng-switch-default data-translate="{{vm.offenerPosten.dtype}}"></span>
    </span>
</span>
