/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {momentComparator} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
    },
    template: require('./dvb-rechnung-task-icon.html'),
    controllerAs: 'vm',
};

export class DvbRechnungTaskIcon implements angular.IController {
    public static $inject: readonly string [] = [];

    public rechnung!: Persisted<Rechnung>;

    public showIcon: boolean = false;
    public failedEmail?: string;
    public failedTime?: moment.Moment;

    public $onInit(): void {
        this.showIcon = this.rechnung.rechnungsdifferenz ||
            this.rechnung.rechnungsUeberschuss ||
            this.rechnung.disabledRechnungsKonfiguration ||
            this.rechnung.deliveryFailure;

        const versandHistory = this.rechnung.emails.slice();

        if (this.rechnung.deliveryFailure && versandHistory) {
            versandHistory.sort((a, b) => momentComparator(a.timestampErstellt, b.timestampErstellt));
            const entry = versandHistory[0];

            if (!entry) {
                return;
            }

            this.failedEmail = entry.empfaengerAdresse;
            this.failedTime = entry.timestampErstellt;
        }
    }

    public getTitleKey(): string {
        // count how many messages we have to display
        const messageCount = [
            this.rechnung.rechnungsdifferenz,
            this.rechnung.rechnungsUeberschuss,
            this.rechnung.disabledRechnungsKonfiguration,
            this.rechnung.deliveryFailure,
        ].filter(val => val).length;

        if (messageCount > 1) {
            return 'FAKTURA.ACHTUNG';
        }

        if (this.rechnung.rechnungsdifferenz) {
            return 'COMMON.RECHNUNGSDIFFERENZ';
        }

        if (this.rechnung.rechnungsUeberschuss) {
            return 'FAKTURA.RECHNUNGSUEBERSCHUSS';
        }

        if (this.rechnung.disabledRechnungsKonfiguration) {
            return 'MANDANT.DISABLED';
        }

        if (this.rechnung.deliveryFailure) {
            return 'FAKTURA.RECHNUNG_DELIVERY_FEHLER';
        }

        return '';
    }
}

componentConfig.controller = DvbRechnungTaskIcon;
angular.module('kitAdmin').component('dvbRechnungTaskIcon', componentConfig);
