/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Kontaktperson} from '@dv/kitadmin/models';
import {KontaktpersonEmail} from './KontaktpersonEmail';

export class SteuerbescheinigungenState {

    public constructor(
        public emailMissing: Kontaktperson[],
        public readyToSend: Kontaktperson[],
        public withExistingEmails: KontaktpersonEmail[],
    ) {
    }

    public static apiResponseTransformer(data: any): SteuerbescheinigungenState {

        const emailMissing = data.emailMissing.map((d: any) => Kontaktperson.apiResponseTransformer(d));
        const readyToSend = data.readyToSend.map((d: any) => Kontaktperson.apiResponseTransformer(d));
        const withExistingEmails = data.withExistingEmails
            .map((d: any) => KontaktpersonEmail.apiResponseTransformer(d));

        return new SteuerbescheinigungenState(emailMissing, readyToSend, withExistingEmails);
    }
}
