/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AbstractResource} from '@dv/shared/backend/model/abstract-resource';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAngestellteZuweisung} from '@dv/shared/backend/model/jax-angestellte-zuweisung';
import {checkPresent, DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import moment from 'moment';
import {PauseZeit} from './PauseZeit';
import {ZuweisungZeit} from './ZuweisungZeit';

export class AngestellteZuweisung implements AbstractResource {

    public constructor(
        public id?: EntityId,
        public angestellteId: EntityId | null = null,
        public kinderOrtId: EntityId | null = null,
        public fraktionId: EntityId | null = null,
        public date: moment.Moment | null = null,
        public zuweisungZeiten: ZuweisungZeit[] = [],
        public pauseZeiten: PauseZeit[] = [],
    ) {
    }

    public static apiResponseTransformer(data: JaxAngestellteZuweisung): AngestellteZuweisung {

        const date = checkPresent(DvbRestUtil.localDateToMoment(data.date));

        const zuweisungZeiten = data.zuweisungZeiten.map(zeit =>
            new ZuweisungZeit(
                DvbDateUtil.setTime(moment(date), checkPresent(DvbRestUtil.localeHHMMTimeToMoment(zeit.von))),
                DvbDateUtil.setTime(moment(date), checkPresent(DvbRestUtil.localeHHMMTimeToMoment(zeit.bis))),
                date,
                date,
                '',
            ));

        const pauseZeiten = data.pauseZeiten.map(pause =>
            new PauseZeit(
                DvbDateUtil.setTime(moment(date), checkPresent(DvbRestUtil.localeHHMMTimeToMoment(pause.von))),
                DvbDateUtil.setTime(moment(date), checkPresent(DvbRestUtil.localeHHMMTimeToMoment(pause.bis))),
                date,
                date,
                '',
            ));

        return new AngestellteZuweisung(data.id,
            data.angestellteId,
            data.kinderOrtId,
            data.fraktionId,
            date,
            zuweisungZeiten,
            pauseZeiten);
    }
}
