<div class="dvb-report-steuerbescheinigung">
    <h2 data-translate="REPORT.STEUERBESCHEINIGUNGEN.TITLE"></h2>

    <form name="vm.steuerbescheinigungReportForm" novalidate>
        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT"
                                      ng-change="vm.updateState()"
                                      ng-if="!vm.kontaktperson"
                                      ng-model="vm.kinderOrt"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>
        <dvb-search-get-single-entity as-col="true"
                                      display-label="COMMON.KONTAKTPERSON.SINGULAR"
                                      entity-to-search="KONTAKTPERSON"
                                      ng-if="!vm.kinderOrt"
                                      ng-model="vm.kontaktperson"
                                      placeholder-key="COMMON.KONTAKTPERSON_AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>

        <div class="row">
            <div class="col-md-12">
                <input class="form-control"
                       dvb-integer
                       max="3000"
                       min="2000"
                       ng-change="vm.updateState()"
                       ng-model="vm.year"
                       ng-attr-placeholder="{{'COMMON.YEAR' | translate}}: {{vm.defaultYear}}"
                       step="1"
                       tooltip-placement="top-left"
                       type="number"
                       uib-tooltip="{{'COMMON.YEAR' | translate}}: {{vm.year || vm.defaultYear}}">
            </div>
        </div>

        <div class="form-row">
            <dvb-dokument-layout on-change="vm.layout = dokumentLayout"></dvb-dokument-layout>
        </div>

        <div class="form-row">
            <dvb-report-steuerbescheinigung-structure structure="vm.structure"></dvb-report-steuerbescheinigung-structure>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
