/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import type {FunctionType, Persisted} from '@dv/shared/code';
import angular from 'angular';
import type {KitaFakturaService} from 'src/app/common/service/rest/kinderort/kitaFakturaService';
import type {BankStatementService} from 'src/app/faktura/service/bankStatementService';
import type {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import type {RechnungenFilter} from '../../../../filter/RechnungenFilter';
import {FilterOption} from '../../../../filter/shared/FilterOption';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        filter: '<',
        kita: '<',
        hasKitaRechnungskonfiguration: '<',
        onUpdate: '&',
    },
    template: require('./dvb-kita-faktura-header.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaHeader {

    public static $inject: readonly string[] = ['kitaFakturaService', 'bankStatementService', 'errorService'];

    public filter!: RechnungenFilter;
    public kita!: Persisted<KinderOrt>;
    public hasKitaRechnungskonfiguration: boolean = false;
    public onUpdate!: FunctionType;

    public dvbDownloadCtrl!: DvbDownload;

    public isDownloading = false;
    public isFakeEsrDownloading = false;

    public constructor(
        private readonly kitaFakturaService: KitaFakturaService,
        private readonly bankStatementService: BankStatementService,
        private readonly errorService: ErrorService,
    ) {
    }

    public createRechnungsLaufPdf(): void {
        this.isDownloading = true;

        const params = this.filter.toRestObject();

        this.kitaFakturaService.getTempBlobForMergedRechnungenPDFFilter(this.kita.id, params)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => {
                this.isDownloading = false;
            });
    }

    public createFakeEsrFile(type: string): void {
        this.isFakeEsrDownloading = true;

        const filter = angular.copy(this.filter);
        filter.kitas = [new FilterOption(this.kita.id, this.kita.getDisplayName(), true)];

        this.bankStatementService.fakeStatement(type, filter)
            .then(tempBlob => {
                if (!tempBlob?.id) {
                    this.errorService.handleValidationError(false, 'KINDERORT.KEINE_ESR_RECHNUNGEN');

                    return;
                }

                this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
            })
            .finally(() => {
                this.isFakeEsrDownloading = false;
            });
    }
}

componentConfig.controller = DvbKitaFakturaHeader;
angular.module('kitAdmin').component('dvbKitaFakturaHeader', componentConfig);
