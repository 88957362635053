/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DienstBedarf} from './DienstBedarf';
import {FraktionsBedarf} from './FraktionsBedarf';

export class KinderOrtBedarf {

    public constructor(
        public kinderOrtId: string | null = null,
        public dienstBedarf: DienstBedarf[] = [],
        public fraktionsBedarf: FraktionsBedarf[] = []) {
    }

    public static apiResponseTransformer(data: any): KinderOrtBedarf {
        const result = new KinderOrtBedarf(data.kinderOrtId);

        if (Array.isArray(data.dienstBedarf)) {
            result.dienstBedarf = data.dienstBedarf.map((dienstBedarf: any) =>
                DienstBedarf.apiResponseTransformer(dienstBedarf));
        }

        if (Array.isArray(data.fraktionsBedarf)) {
            result.fraktionsBedarf = data.fraktionsBedarf.map((fraktionsBedarf: any) =>
                FraktionsBedarf.apiResponseTransformer(fraktionsBedarf));
        }

        return result;
    }
}
