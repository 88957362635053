<div class="dvb-angestellte-stundenerfassung-summary">

    <div class="modal-body">
        <h2>{{ firstOfMonth.format('MMM YYYY') }}</h2>
        @for (kindEntry of monthlyData.kinder; track kindEntry.kind.id) {
            <dv-angestellte-stundenerfassung-summary-entry
                [kind]="kindEntry.kind"
                [betreuungen]="kindEntry.betreuungen"
                [vertraglicheStunden]="kindEntry.vertraglicheStunden"
                [tarifParamsByKinderOrt]="monthlyData.tarifParameterByKinderOrtId"
                [betreuungsPersonen]="betreuungsPersonen"
                [firstOfMonth]="firstOfMonth">
            </dv-angestellte-stundenerfassung-summary-entry>
        }
    </div>

    <div class="modal-footer">
        <dvlib-button-list class="normal-abstand-top normal-abstand-bottom">
            <button type="button"
                    class="btn btn-link"
                    (click)="downloadSummary()">
                <i class="fa fa-download"></i>
            </button>
            <button type="button"
                    class="btn btn-link small-abstand-left"
                    (click)="hide()"
                    [translate]="'COMMON.ABBRECHEN'">
            </button>
        </dvlib-button-list>
    </div>

</div>
