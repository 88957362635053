/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BEGIN_OF_TIME, checkPresent, DvbDateUtil, END_OF_TIME, Gueltigkeit} from '@dv/shared/code';
import type {IPopoverFilterProperty, PopoverType} from './IPopoverFilterProperty';

export class PopoverFilterPropertyGueltigkeit
    implements IPopoverFilterProperty<Gueltigkeit | undefined, Gueltigkeit> {

    public selected: boolean = false;
    public type: PopoverType = 'vonBis';

    public gueltigkeit: Gueltigkeit;

    private label: string = '';

    public constructor(
        gueltigkeit: Gueltigkeit | null,
    ) {
        this.gueltigkeit = gueltigkeit ?? new Gueltigkeit(BEGIN_OF_TIME, END_OF_TIME);
    }

    public acceptUserModel(modelValue?: Gueltigkeit): boolean {
        if (!modelValue) {
            return false;
        }

        return modelValue.gueltigAb!.isSameOrBefore(this.gueltigkeit.gueltigBis)
            && modelValue.gueltigBis!.isSameOrAfter(this.gueltigkeit.gueltigAb);
    }

    public createWorkingCopy(): Gueltigkeit {
        const result = checkPresent(Gueltigkeit.copy(this.gueltigkeit));
        result.setNullTimeLimits();

        return result;
    }

    public onChange(gueltigkeit: Gueltigkeit | null): void {
        this.gueltigkeit = gueltigkeit ?? new Gueltigkeit(BEGIN_OF_TIME, END_OF_TIME);
        this.initLabel();
    }

    public getLabel(): string {
        return this.label || this.initLabel();
    }

    private initLabel(): string {
        const model = checkPresent(Gueltigkeit.copy(this.gueltigkeit));
        const gueltigAb = model.gueltigAb;
        const gueltigBis = model.gueltigBis;

        const von = DvbDateUtil.isValidMoment(gueltigAb) && !DvbDateUtil.isBeginOfTime(gueltigAb) ?
            gueltigAb.format('DD. MMM YYYY') :
            '...';

        const bis = DvbDateUtil.isValidMoment(gueltigBis) && !DvbDateUtil.isEndOfTime(gueltigBis) ?
            gueltigBis.format('DD. MMM YYYY') :
            '...';

        this.label = `${von} - ${bis}`;

        return this.label;
    }
}
