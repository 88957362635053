/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Transition} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';

export class ZuweisungAbController implements angular.IController {

    public static $inject: readonly string[] = ['$scope', '$transition$'];

    public constructor(
        private readonly $scope: angular.IScope & {
            zuweisungAb: moment.Moment | null;
        },
        private readonly $transition$: Transition,
    ) {
        this.setZuweisungAb(this.$transition$.params().zuweisungAb ?? '');
    }

    public uiOnParamsChanged(newParams: any): void {
        if (newParams.zuweisungAb) {
            this.setZuweisungAb(newParams.zuweisungAb);
        }
    }

    private setZuweisungAb(newZuweisungAb: string): void {
        const zuweisungAb = DvbRestUtil.localDateToMoment(newZuweisungAb);
        if (!DvbDateUtil.isMomentEquals(this.$scope.zuweisungAb, zuweisungAb)) {
            this.$scope.zuweisungAb = zuweisungAb;
        }
    }
}

angular.module('kitAdmin').controller('ZuweisungAbController', ZuweisungAbController);
