<div class="dvb-limited-entity-content">
    <div ng-if="!vm.formContext.editMode">

        <ng-transclude ng-transclude-slot="entityReadOnly"></ng-transclude>

        <ul ng-if="vm.enableActions" class="list-inline normal-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.edit()"
                        data-translate="COMMON.BEARBEITEN">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.remove()"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </li>
        </ul>
    </div>

    <div ng-if="vm.formContext.editMode" class="history-columns">
        <div class="history-column-left">
            <p class="small-abstand-bottom">
                <dvb-gueltigkeit entity="vm.entity"></dvb-gueltigkeit>
            </p>

            <dvb-limited-entity-form entity="vm.entity"
                                     translation-root="vm.translationRoot"
                                     is-loading="vm.formContext.isLoading"
                                     on-submit="vm.submit()"
                                     on-cancel="vm.cancel()">
                <entity-form ng-transclude="entityForm"></entity-form>
            </dvb-limited-entity-form>
        </div>
    </div>
</div>
