/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import {ParameterKinderOrte} from './ParameterKinderOrte';

export class ParameterKinderOrteWithGueltigkeit extends ParameterKinderOrte {
    public constructor(
        public override alleKinderOrte: boolean = true,
        public override kinderOrte: SearchResultEntry[] = [],
        public override stichtag: moment.Moment | null = null,
        public override year: number | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
    ) {
        super(alleKinderOrte, kinderOrte, stichtag, year);
    }

    public override isValid(): boolean {
        return super.isValid()
            && DvbDateUtil.isValidMoment(this.gueltigAb)
            && DvbDateUtil.isValidMoment(this.gueltigBis);
    }
}
