/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxKinderOrtTimeRangeBedarf} from '@dv/shared/backend/model/jax-kinder-ort-time-range-bedarf';
import {DvbRestUtil} from '@dv/shared/code';
import {FraktionsTimeRangeBedarf} from './FraktionsTimeRangeBedarf';
import {TimeRangeBedarf} from './TimeRangeBedarf';

export class KinderOrtTimeRangeBedarf {

    public constructor(
        public kinderOrtId: string | null = null,
        public dailyBedarf: TimeRangeBedarf[] = [],
        public fraktionsBedarf: FraktionsTimeRangeBedarf[] = [],
    ) {
    }

    public static apiResponseTransformer(data: JaxKinderOrtTimeRangeBedarf): KinderOrtTimeRangeBedarf {
        const result = new KinderOrtTimeRangeBedarf(data.kinderOrtId);

        if (Array.isArray(data.dailyBedarf)) {
            result.dailyBedarf = DvbRestUtil.transformArray(data.dailyBedarf, TimeRangeBedarf);
        }

        if (Array.isArray(data.fraktionsBedarf)) {
            result.fraktionsBedarf = DvbRestUtil.transformArray(data.fraktionsBedarf, FraktionsTimeRangeBedarf);
        }

        return result;
    }
}
