/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {ReportState} from '../../models';
import type {ReportTemplate} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        reportTemplate: '<',
    },
    template: require('./dvb-report-template-outdated-icon.html'),
    controllerAs: 'vm',
};

export class DvbReportTemplateOutdatedIcon implements angular.IController {
    public static $inject: readonly string[] = [];

    public readonly reportState?: ReportState;
    public readonly reportTemplate?: ReportTemplate;

    public outdated: boolean = false;

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.reportState || onChangesObj.reportTemplate) {
            this.outdated = this.isOutdated();
        }
    }

    private isOutdated(): boolean {
        if (!this.reportState || !this.reportTemplate) {
            return false;
        }

        return this.reportState.templateVersion !== this.reportTemplate.templateVersion;
    }
}

componentConfig.controller = DvbReportTemplateOutdatedIcon;
angular.module('kitAdmin').component('dvbReportTemplateOutdatedIcon', componentConfig);
