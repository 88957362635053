/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KontaktEmail, Rechnung} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {EmailVersandStatus, momentComparator} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, switchMap} from 'rxjs';
import type {FakturaService} from '../../../../common/service/rest/fakturaService';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';
import type {
    RechnungEmailVersandBestaetigenDialogModel,
} from '../rechnung-email-versand-bestaetigen/rechnung-email-versand-bestaetigen.component';
import {
    RechnungEmailVersandBestaetigenComponent,
} from '../rechnung-email-versand-bestaetigen/rechnung-email-versand-bestaetigen.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
    },
    template: require('./dvb-rechnung-versand-status.html'),
    controllerAs: 'vm',
};

export class DvbRechnungVersandStatus implements angular.IController, angular.IOnInit {
    public static $inject: readonly string [] = [
        'dialogService',
        'errorService',
        'kinderOrtService',
        'fakturaService',
        '$state',
    ];

    public rechnung!: Persisted<Rechnung>;

    public latestEmail?: KontaktEmail;
    public emailVersandStatus: typeof EmailVersandStatus = EmailVersandStatus;

    public constructor(
        private readonly dialogService: DialogService,
        private readonly errorService: ErrorService,
        private readonly kinderOrtService: KinderOrtService,
        private readonly fakturaService: FakturaService,
        private readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        const emails = this.rechnung.emails;

        emails.sort((a, b) => momentComparator(a.timestampErstellt, b.timestampErstellt));

        this.latestEmail = emails[0];
    }

    public sendRechnungEmail(): void {
        if (!this.rechnung.kontaktpersonEmail) {
            this.errorService.handleValidationError(false, 'ERRORS.ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL');

            return;
        }

        this.kinderOrtService.get(this.rechnung.kitaId!, {cache: true}).then(kita => {

            const dialogModel: RechnungEmailVersandBestaetigenDialogModel = {
                rechnung: this.rechnung,
                kita,
                confirm: versandProperties =>
                    from(this.fakturaService.sendRechnungEmail(this.rechnung.id, versandProperties)).pipe(
                        switchMap(() => {
                            // reload state to refresh tasks and state of last email sent
                            return this.$state.reload();
                        })),
            };

            this.dialogService.openDialog(RechnungEmailVersandBestaetigenComponent, dialogModel);
        });
    }
}

componentConfig.controller = DvbRechnungVersandStatus;
angular.module('kitAdmin').component('dvbRechnungVersandStatus', componentConfig);
