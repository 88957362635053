/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import type {Selectable} from '@dv/shared/code';
import {checkPersisted, DisplayableWithBadge, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {ParameterKinderOrte} from '../../../report/models/ParameterKinderOrte';
import type {KinderOrtService} from '../../service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        parameterKinderOrte: '<',
        isSelectionRequired: '<?',
        onChange: '&',
    },
    template: require('./dvb-kitas-selection.html'),
    controllerAs: 'vm',
};

export class DvbKitasSelection implements angular.IController {
    public static $inject: readonly string[] = ['kinderOrtService'];

    public readonly parameterKinderOrte!: ParameterKinderOrte;
    public readonly isSelectionRequired: boolean = true;
    public onChange!: () => void;

    public availableKinderOrte: { [id: string]: KinderOrt } = {};
    public availableKinderOrtDisplayables: Selectable<DisplayableWithBadge>[] = [];
    public selectedKinderOrte: Selectable<DisplayableWithBadge>[] = [];

    public constructor(
        private kinderOrtService: KinderOrtService,
    ) {
    }

    public $onInit(): void {
        this.selectedKinderOrte = this.parameterKinderOrte.kinderOrte.map(k => new DisplayableWithBadge(k.id, k.text));
        this.kinderOrtService.getAll({cache: true})
            .then(k => k.map(checkPersisted))
            .then(kinderOrte => {
                this.availableKinderOrte = DvbUtil.mapById(kinderOrte);
                this.availableKinderOrtDisplayables =
                    kinderOrte.map(k => new DisplayableWithBadge(k.id, k.getDisplayName()));
            });
    }

    public kinderOrtSelectionChanged(): void {
        this.parameterKinderOrte.kinderOrte = this.selectedKinderOrte
            .map(displayable => this.availableKinderOrte[displayable.id].toSearchResultEntry());
        this.onChange();
    }
}

componentConfig.controller = DvbKitasSelection;
angular.module('kitAdmin').component('dvbKitasSelection', componentConfig);
