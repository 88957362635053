<div class="dvb-administration-mandanten row">

    <div class="col-md-7 big-abstand-bottom mandant-rechnungen">
        <div class="row">
            <div class="col-md-12">
                <h2 data-translate="COMMON.RECHNUNG.PLURAL"></h2>
                <dvlib-date-switcher mode="month"
                                     class="normal-abstand-left"
                                     [(date)]="vm.stichtag"
                                     (debounced-date-change)="vm.reloadRechnungen()">
                </dvlib-date-switcher>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       ng-model="vm.searchText"
                       ng-change="vm.filterMandanten()"
                       ng-attr-placeholder="{{'ADMINISTRATION.FILTER_BY_NAME' | translate}}"
                       uib-tooltip="{{'ADMINISTRATION.FILTER_BY_NAME' | translate}}"
                       tooltip-placement="top-left"
                       class="form-control">
            </div>
        </div>

        <div class="big-abstand-bottom" dvb-accordions>
            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading"></dvlib-spinner>

            <div ng-if="!vm.isLoading && (vm.mandantRechnungen.length === 0)"
                 class="collapsible-panel-container first-container">
                <p data-translate="ADMINISTRATION.KEINE_RECHNUNGEN_VORHANDEN"></p>
            </div>

            <dvb-accordion is-first="$first"
                           ng-repeat="rechnung in vm.filteredMandantRechnungen | orderBy: 'summe':true">
                <panel-header class="full-width-block">
                    <dvb-administration-mandant-rechnung-heading rechnung="rechnung">
                    </dvb-administration-mandant-rechnung-heading>
                </panel-header>
                <panel-content>
                    <dvb-administration-mandant-rechnung-detail rechnung="rechnung"
                                                                stichtag="vm.stichtag"
                                                                reload="vm.reloadRechnungen()">
                    </dvb-administration-mandant-rechnung-detail>
                </panel-content>
                <panel-footer class="full-width-block">
                    <div class="row">
                        <div class="col-xs-6">
                            <dvb-loading-button type="button"
                                                as-link="true"
                                                spinner-right="true"
                                                is-loading="vm.isDownloading"
                                                ng-disabled="!rechnung.id"
                                                on-click="vm.createRechnungsPdf(rechnung)">
                                <span data-translate="FAKTURA.AKTUELLE_RECHNUNG_HERUNTERLADEN"></span>
                            </dvb-loading-button>
                        </div>
                        <div class="col-xs-6">
                            <strong class="text-nowrap pull-right"
                                    data-translate="COMMON.TOTAL_CHF"
                                    data-translate-value-chf="{{rechnung.summe | number: 2}}">
                            </strong>
                        </div>
                    </div>
                </panel-footer>
            </dvb-accordion>
        </div>
    </div>

    <div class="col-md-3 col-md-offset-1">
        <h2 data-translate="ADMINISTRATION.MONATLICHES_EINKOMMEN"></h2>
        <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>

        <div ng-show="!vm.isLoading">
            <strong data-translate="COMMON.BETRAG_VALUE"
                    data-translate-value-betrag="{{vm.einkommen | number: 2}}">
            </strong>

            <h2 data-translate="ADMINISTRATION.VERRECHENBARE_KINDERORTE" class="big-abstand-top"></h2>
            <strong data-translate="ADMINISTRATION.ACTIVE_TOTAL"
                    data-translate-value-active="{{vm.kitaCount}}"
                    data-translate-value-total="{{vm.totalKitaCount}}">
            </strong>
            <strong data-translate="COMMON.KITA.MF"
                    data-translate-value-count="{{vm.kitaCount}}"
                    data-translate-value-showcount="false"
                    data-translate-interpolation="messageformat">
            </strong>

            <h2 data-translate="ADMINISTRATION.ACTIVE_TAGES_ELTERN" class="big-abstand-top"></h2>
            <strong data-translate="ADMINISTRATION.ACTIVE_TOTAL"
                    data-translate-value-active="{{vm.tageselternCount}}"
                    data-translate-value-total="{{vm.totalTageselternCount}}">
            </strong>
            <strong data-translate="COMMON.TAGES_ELTERN.MF"
                    data-translate-value-count="{{vm.tageselternCount}}"
                    data-translate-value-showcount="false"
                    data-translate-interpolation="messageformat">
            </strong>

            <h2 data-translate="ADMINISTRATION.CORONA_KINDER_ORT_FEES" class="big-abstand-top"></h2>
            <strong data-translate="COMMON.KINDERORT.MF"
                    data-translate-value-count="{{vm.coronaKinderOrteCharged}}"
                    data-translate-value-showcount="true"
                    data-translate-interpolation="messageformat">
            </strong>

            <div ng-if="vm.isPersisted()" class="big-abstand-top">
                <h2 data-translate="COMMON.RECHNUNG.PLURAL"></h2>

                <dvb-loading-button type="button"
                                    as-link="true"
                                    spinner-right="true"
                                    is-loading="vm.isDownloading"
                                    on-click="vm.createRechnungsPdf(null)">
                    <span data-translate="KINDERORT.RECHNUNGSLAUF_PDF_ERSTELLEN"></span>
                </dvb-loading-button>
            </div>
        </div>
    </div>

</div>
