<div class="dvb-kontaktperson-correspondence">
    <div class="row">
        <div class="filer-list col-md-7">
            <h2 data-translate="KONTAKTPERSON.CORRESPONDENCE.PAST"></h2>
            <ul class="filter-option-list">
                <li>
                    <button type="button"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.BULK_EMAIL"
                            ng-change="vm.updateFilter()"
                            data-translate="COMMUNICATION.BULK_EMAILS.NAME">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.RECHNUNG"
                            ng-change="vm.updateFilter()"
                            data-translate="COMMON.RECHNUNG.SINGULAR">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.STEUERBESCHEINIGUNG"
                            ng-change="vm.updateFilter()"
                            data-translate="COMMUNICATION.STEUERBESCHEINIGUNG.NAME">
                    </button>
                </li>
                <li>
                    <button type="button"
                            dvb-auth
                            require-permission="module:kommunikation"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.BRING_ABHOLZEITEN"
                            ng-change="vm.updateFilter()"
                            data-translate="COMMUNICATION.BRING_ABHOLZEITEN.PRESENCE">
                    </button>
                </li>
                <li>
                    <button type="button"
                            dvb-auth
                            require-permission="module:kommunikation"
                            class="btn label label-default label-radio"
                            uib-btn-checkbox
                            ng-model="vm.filter.states.NOTIZ"
                            ng-change="vm.updateFilter()"
                            data-translate="NOTIZ.NAME">
                    </button>
                </li>
            </ul>

            <div class="dvb-timeline scroll-wrapper small-abstand-top">
                <div ui-scroll="korrespondenz in vm.dataSource"
                     adapter="vm.adapter"
                     start-index="0"
                     buffer-size="20"
                     padding="1">
                    <div ng-if="korrespondenz"
                         class="timeline-entry"
                         ng-class="{'last': vm.adapter.bufferLast.id === korrespondenz.id}">
                        <dvb-timeline-entry-icon entry="korrespondenz"
                                                 timeline-controller="vm.timelineController">
                        </dvb-timeline-entry-icon>
                        <dvb-timeline-entry-details class="full-width-block"
                                                    entry="korrespondenz"
                                                    timeline-controller="vm.timelineController">
                            <dvb-kontaktperson-correspondence-timeline-details korrespondenz="korrespondenz">
                            </dvb-kontaktperson-correspondence-timeline-details>
                        </dvb-timeline-entry-details>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-md-offset-1" dvb-auth require-permission="kita:manage:general">
            <h2 data-translate="COMMON.AKTIONEN"></h2>

            <p>
                <a ui-sref="base.notiz.create({entityId: vm.kontaktperson.id, entityType: 'KONTAKTPERSON'})">
                    + <span data-translate="NOTIZ.CREATE_NOTIZ"></span>
                </a>
            </p>
            <p ng-if="vm.kontaktperson.email">
                <a ui-sref="base.kontaktperson.communication.email">
                    + <span data-translate="COMMUNICATION.BULK_EMAILS.TITLE"></span>
                </a>
            </p>
        </div>

    </div>
</div>
