<div class="dvb-fraktion-monatsblatt">

    <div class="filter-bar filter-flex">
        <ul class="list-inline">
            <li>
                <span data-translate="FRAKTION.MONATSBLATT.RECHNUNGSMONAT"></span>
            </li>
            <li>
                <dvlib-date-switcher mode="month"
                                     class="normal-abstand-left"
                                     [(date)]="vm.periode"
                                     (debounced-date-change)="vm.reload()">
                </dvlib-date-switcher>
            </li>
        </ul>
        <div dvb-auth
             require-permission="{{['kita:administrate:' + vm.fraktion.kinderOrtId, 'feature:monatsblatt_api_kibe_plus:general']}}"
             require-role="ADMIN">
            <span ng-if="vm.apiState"
                  class="badge small-abstand-right"
                  ng-class="{'warning': vm.apiState.status !== 'VERSANDT'}"
                  data-translate="{{'FRAKTION.MONATSBLATT.API.STATUS.' + vm.apiState.status}}"
                  uib-tooltip="{{'FRAKTION.MONATSBLATT.API.VERAENDERT_INFO_MF' | translate: {status: vm.apiState.status}:'messageformat'}}"
                  tooltip-placement="top-left">
            </span>
            <button type="button"
                    class="btn btn-link"
                    ng-disabled="vm.isLoading"
                    ng-click="vm.sendToApi()"
                    data-translate="FRAKTION.MONATSBLATT.API.SEND">
            </button>
        </div>
    </div>

    <div class="row big-abstand-top">

        <div class="col-md-7" dvb-accordions>

            <dvlib-spinner class="centered-spinner" [show]="vm.isLoading && vm.entries.length === 0"></dvlib-spinner>

            <div ng-if="!vm.isLoading && vm.entries.length === 0">
                <p data-translate="FRAKTION.MONATSBLATT.KEINE_EINTRAEGE"></p>
            </div>

            <dvb-accordion is-first="$first"
                           ng-repeat="entry in vm.entries"
                           is-selected="entry.kind.id === vm.kindId"
                           on-select="vm.selectedEntry(entry, selected)">
                <panel-header class="full-width-block floating-text">
                    <div class="row">
                        <div class="col-xs-6 accordion-height" ng-bind="entry.kind.getDisplayName()"></div>
                        <div class="col-xs-6 text-right">
                            <ul class="list-inline no-margin">
                                <li>
                                    <dvb-loading-button as-link="true"
                                                        is-loading="!!vm.isLeistungsRechnungLoading[entry.leistungsrechnung.id]"
                                                        on-click="event.stopPropagation(); vm.refreshLeistungsrechnung(entry)"
                                                        tooltip-placement="top-left"
                                                        type="button"
                                                        uib-tooltip="{{'FRAKTION.MONATSBLATT.LEISTUNGSRECHNUNG_AKTUALISIEREN' | translate}}">
                                        <i class="fa fa-refresh" ng-show="entry.dirty"></i>
                                        &nbsp;<!-- when there is no content, the position is off-->
                                    </dvb-loading-button>
                                </li>
                                <li>
                                    <dvb-tasks-badge label="COMMON.{{entry.leistungsrechnung.status}}"
                                                     ng-if="!vm.isLeistungsRechnungLoading[entry.leistungsrechnung.id]"
                                                     popover-title="{{'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_' + entry.leistungsrechnung.status + '_TOOLTIP' | translate}}"
                                                     tasks="entry.leistungsrechnung.tasks"
                                                     warning="!entry.leistungsrechnung.isVerrechenbar()">
                                    </dvb-tasks-badge>
                                </li>
                            </ul>
                        </div>
                    </div>
                </panel-header>
                <panel-content>
                    <div ng-repeat="tarifEntry in entry.tarife">
                        <form novalidate>
                            <fieldset ng-disabled="!!vm.isMonatsBlattEntryLoading[entry.leistungsrechnung.id]">
                                <div class="normal-abstand-bottom">
                                    <p>
                                        <strong>
                                            <span ng-if="tarifEntry.zusatzFraktionBelegungId"
                                                  data-translate="FRAKTION.MONATSBLATT.ZUSAETZLICHE_BELEGUNG"></span>
                                            <dvb-platz-typ-name kontingent-id="tarifEntry.kontingentId"
                                                                ng-if="tarifEntry.leistungsTyp === vm.leistungsTyp.FIRMEN_KONTINGENT">
                                            </dvb-platz-typ-name>
                                            <span data-translate="{{'FRAKTION.LEISTUNGS_TYP.' + tarifEntry.leistungsTyp}}"
                                                  ng-if="tarifEntry.leistungsTyp !== vm.leistungsTyp.FIRMEN_KONTINGENT">
                                            </span>
                                            <span ng-if="!tarifEntry.gueltigAb.isSame(tarifEntry.gueltigBis)"
                                                  ng-bind="tarifEntry.gueltigAb | amDateFormat:  'DD.MM.' + ' -'"></span>
                                            <span ng-bind="tarifEntry.gueltigBis| amDateFormat:  'DD.MM.'"></span>
                                        </strong>
                                    </p>
                                    <p class="normal-abstand-bottom"
                                       ng-bind="tarifEntry.tarif.getDisplayName()"></p>
                                    <div class="row" ng-if="tarifEntry.tarif.dtype === 'BETREUUNGS_STUNDEN'">
                                        <div class="col-xs-6">
                                            <label class="truncate"
                                                   data-translate="FRAKTION.MONATSBLATT.STUNDEN"></label>
                                            <input class="form-control"
                                                   dvb-auth
                                                   min="0"
                                                   ng-change="vm.updateStunden(entry, tarifEntry)"
                                                   ng-model="tarifEntry.stunden"
                                                   ng-model-options="{ updateOn: 'change blur' }"
                                                   on-unauthorised-disable
                                                   require-permission="{{'kita:manage:' + vm.fraktion.kita.id}}"
                                                   type="number"
                                                   uib-tooltip="{{'FRAKTION.MONATSBLATT.STUNDEN' | translate}}"
                                                   tooltip-placement="top-left">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xs-4"
                                             ng-init="val = vm.getParamValue(tarifEntry.parameterValues, param)"
                                             ng-repeat="param in tarifEntry.tarif.parameters | filter:{dtype: 'MONATS_BASIERT'} | orderBy:'orderValue'">
                                            <label class="truncate" ng-bind="param.name"></label>
                                            <input class="form-control"
                                                   dvb-auth
                                                   ng-change="vm.updateParamValue(entry, tarifEntry)"
                                                   ng-model="val.value"
                                                   ng-model-options="{ updateOn: 'change blur' }"
                                                   on-unauthorised-disable
                                                   require-permission="{{'kita:manage:' + vm.fraktion.kita.id}}"
                                                   type="number"
                                                   uib-tooltip="{{param.name}}"
                                                   tooltip-placement="top-left">
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </panel-content>
                <panel-footer>
                    <div ng-if="entry.showCreateZusatzBelegung">
                        <dvb-loading-button as-link="true"
                                            is-loading="!!vm.isMonatsBlattEntryLoading[entry.leistungsrechnung.id]"
                                            on-click="vm.addZusatzBelegung(entry)"
                                            spinner-right="true"
                                            tooltip-placement="top-left"
                                            type="button"
                                            uib-tooltip="{{'FRAKTION.MONATSBLATT.CREATE_ZUSAETZLICHE_BELEGUNG' | translate}}">
                            <span data-translate="FRAKTION.MONATSBLATT.CREATE_ZUSAETZLICHE_BELEGUNG"></span>
                        </dvb-loading-button>
                    </div>
                    <div ng-if="entry.zusatzFraktionBelegungId">
                        <dvb-loading-button as-link="true"
                                            is-loading="!!vm.isMonatsBlattEntryLoading[entry.leistungsrechnung.id]"
                                            on-click="vm.deleteZusatzBelegung(entry)"
                                            spinner-right="true"
                                            tooltip-placement="top-left"
                                            type="button"
                                            uib-tooltip="{{'FRAKTION.MONATSBLATT.DELETE_ZUSAETZLICHE_BELEGUNG' | translate}}">
                            <span data-translate="FRAKTION.MONATSBLATT.DELETE_ZUSAETZLICHE_BELEGUNG"></span>
                        </dvb-loading-button>
                    </div>
                </panel-footer>
            </dvb-accordion>
        </div>
        <!-- RIGHT Column -->
        <div class="col-md-4 col-md-offset-1" ng-if="vm.displayBelegung">
            <div class="big-abstand-bottom" ng-if="vm.currentEntry.stundenKontingente.length > 0">
                <h2 data-translate="KIND.STUNDENKONTINGENT"></h2>

                <div class="normal-abstand-bottom" ng-repeat="kontingent in vm.currentEntry.stundenKontingente">
                    <dvb-monatsblatt-stunden-kontingent stunden-kontingent="kontingent">
                    </dvb-monatsblatt-stunden-kontingent>
                </div>
            </div>

            <h2 data-translate="COMMON.AKTUELLE_BELEGUNG"></h2>
            <dvb-betreuungsverlauf expand-ab="vm.periode"
                                   expand-bis="vm.periodeBis"
                                   verlauf-ab="vm.periode"
                                   verlauf-bis="vm.periodeBis"
                                   is-date-clickable="false"
                                   kind="vm.currentEntry.kind"
                                   show-betreuungs-vereinbarung="false"
                                   show-anmelde-datum="false"
                                   show-abweichungen-bearbeiten="false"
                                   show-delete="false"
                                   show-line-top="false">
            </dvb-betreuungsverlauf>
        </div>
    </div>
</div>
