<div class="dvb-report-offene-posten">
    <h2 data-translate="REPORT.AKTUELLE_OFFENE_POSTEN.TITLE"></h2>

    <form name="vm.aktuelleOffenePostenReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-kitas-selection parameter-kinder-orte="vm.parameterKinderOrteKontaktpersonen"
                             is-selection-required="!vm.parameterKinderOrteKontaktpersonen.isValid()"
                             on-change="vm.updateState()">
        </dvb-kitas-selection>

        <dvb-search-list entity-to-search="KONTAKTPERSON"
                         ng-model="vm.parameterKinderOrteKontaktpersonen.kontaktpersonen"
                         on-added="vm.updateState()"
                         on-removed="vm.updateState()"
                         placeholder-key="COMMON.KONTAKTPERSON.AUSWAEHLEN"
                         ng-required="!vm.parameterKinderOrteKontaktpersonen.isValid()">
        </dvb-search-list>

        <dvb-datepicker-text-field ng-model="vm.zahlungenGueltigBis"
                                   ng-attr-placeholder="{{'COMMON.ZAHLUNGEN_BIS' | translate}}">
        </dvb-datepicker-text-field>

        <dvb-datepicker-text-field ng-model="vm.periodeGueltigBis"
                                   dvb-end-of-month-parser
                                   ng-attr-placeholder="{{'COMMON.LEISTUNGSPERIODE_BIS' | translate}}">
        </dvb-datepicker-text-field>

        <div class="checkbox">
            <label>
                <input type="checkbox"
                       ng-model="vm.abgewickeltePostenAnzeigen">
                <span data-translate="KONTAKTPERSON.ABGEWICKELTE_POSTEN_ANZEIGEN"></span>
            </label>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()" class="inline-block normal-abstand-top">
        </dvb-report-download>
    </form>
</div>
