@for (searchResultEntry of selectedEntries(); track trackById($index, searchResultEntry)) {
    <dv-search-entity-result
        class="selected-entries"
        [isDisabled]="isDisabled()"
        [searchResultEntry]="searchResultEntry"
        [showRemoveButton]="mutationAllowed()"
        (remove)="onRemove($event)">
    </dv-search-entity-result>
}

@if (showSearchInput()) {
    <dv-search-entity
        [entityToSearch]="entityToSearch"
        [onSelectClear]="true"
        [placeholder]="placeholder"
        [disabled]="isDisabled()"
        [disabledEntries]="selectedEntries()"
        [entitiesToSearchFrom]="entitiesToSearchFrom"
        (selectEntity)="onSelect($event)">
    </dv-search-entity>
}
