import {Directive, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[dvResourceHover]',
    standalone: true,
})
export class ResourceHoverDirective {

    @Input() public dvResourceHoverId: string | undefined;

    private hoveredElements: NodeListOf<HTMLElement> | [] = [];

    public constructor(
        private readonly renderer: Renderer2,
    ) {
    }

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        this.hoveredElements = document.querySelectorAll(`[data-resource-id="${this.dvResourceHoverId}"]`);
        this.hoveredElements.forEach(element => {
            this.renderer.addClass(element, 'resource-hover');
        });
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        this.hoveredElements.forEach(element => {
            this.renderer.removeClass(element, 'resource-hover');
        });
    }

}
