/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Persisted} from '@dv/shared/code';
import {EntitySelection, momentComparator} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import type {KibonExchangeBetreuung} from '../../models/KibonExchangeBetreuung';
import type {KibonExchangeBetreuungService} from '../../service/kibonExchangeBetreuungService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        refresh: '<',
    },
    template: require('./dvb-kibon-betreuungen-kind.html'),
    controllerAs: 'vm',
};

export class DvbKibonBetreuungenKind extends EntitySelection<KibonExchangeBetreuung> implements angular.IController {
    public static override $inject: readonly string[] = [
        'kibonExchangeBetreuungService',
        'errorService',
        'dialogService',
        'authStore',
    ];

    public kind!: Persisted<Kind>;

    public betreuungenSent: KibonExchangeBetreuung[] = [];
    public betreuungenPending: KibonExchangeBetreuung[] = [];
    public betreuungenIgnored: KibonExchangeBetreuung[] = [];
    // dvb-page requires at least 1 item to show an overlay spinner.
    // Furthermore, the "no entity available" text is handled in specific sub elements, and should come from dvb-page.
    public dummyPageItems: number[] = [1];
    public isLoading: boolean = false;
    public hasKitaManagePrivileges: boolean = false;

    public constructor(
        private readonly kibonService: KibonExchangeBetreuungService,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly authStore: AuthStore,
    ) {
        super();
    }

    private static uebermittlungsTimestampComparator(a: KibonExchangeBetreuung, b: KibonExchangeBetreuung): number {
        return momentComparator(a.uebermittlungsTimestamp!, b.uebermittlungsTimestamp!);
    }

    private static timestampMutiertComparator(a: KibonExchangeBetreuung, b: KibonExchangeBetreuung): number {
        return momentComparator(a.timestampMutiert!, b.timestampMutiert!);
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const refresh = onChangesObj.refresh;
        if (refresh.isFirstChange() || (refresh.currentValue === false && refresh.previousValue === true)) {
            this.initBetreuungen();
        } else if (refresh.currentValue === true && refresh.previousValue === false) {
            // start overlay spinner when refresh is started
            this.isLoading = true;
        }
        this.hasKitaManagePrivileges = this.authStore.hasPermission(PERMISSION.KITA.MANAGE);
    }

    public override selectAll(): void {
        super.selectAll(this.betreuungenPending.filter(b => b.isMutierbar() && !b.referenzNummerIgnoriert));
    }

    public submit(): void {
        this.errorService.clearAll();
        const selected = this.getSelected(this.betreuungenPending);

        this.kibonService.sendConfirmation(
            selected,
            () => from(this.kibonService.sendForKind(this.kind.id, selected))
                .pipe(take(1), tap(() => this.initBetreuungen())));
    }

    public ignore(betreuung: KibonExchangeBetreuung): void {
        this.errorService.clearAll();

        this.kibonService.ignoreBetreuungen(
            [betreuung],
            () => from(this.kibonService.ignore([betreuung]))
                .pipe(take(1), tap(() => this.initBetreuungen())),
        );
    }

    public unIgnore(betreuung: KibonExchangeBetreuung): void {
        this.errorService.clearAll();

        const confirm = (): Observable<unknown> => from(this.kibonService.unIgnore(betreuung.id))
            .pipe(take(1), tap(() => this.initBetreuungen()));

        this.dialogService.openConfirmDialog({
            title: 'KIBON.MUTATION.NICHT_IGNORIEREN',
            confirm,
        });
    }

    private initBetreuungen(): angular.IPromise<void> {
        this.isLoading = true;

        return this.kibonService.getForKind(this.kind.id, {includes: '(kinderOrt, leistungs_rechnung_task, comments)'})
            .then(betreuungen => {
                this.clearBetreuungen();

                betreuungen.filter(betreuung => betreuung.isSent())
                    .forEach(betreuung => this.betreuungenSent.push(betreuung));

                betreuungen.filter(betreuung => betreuung.isIgnored())
                    .forEach(betreuung => this.betreuungenIgnored.push(betreuung));

                betreuungen.filter(betreuung => betreuung.isPending())
                    .forEach(betreuung => this.betreuungenPending.push(betreuung));

                this.betreuungenSent.sort(DvbKibonBetreuungenKind.uebermittlungsTimestampComparator);
                this.betreuungenIgnored.sort(DvbKibonBetreuungenKind.timestampMutiertComparator);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private clearBetreuungen(): void {
        this.betreuungenPending = [];
        this.betreuungenSent = [];
        this.betreuungenIgnored = [];
    }
}

componentConfig.controller = DvbKibonBetreuungenKind;
angular.module('kitAdmin').component('dvbKibonBetreuungenKind', componentConfig);
