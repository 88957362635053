/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsfaktorRegel} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {Transition} from '@uirouter/core';
import angular from 'angular';
import type {MandantService} from '../common/service/rest/mandantService';

angular.module('kitAdmin').config(betreuungsfaktorregelConfig);

export * from './component/dvb-betreuungsfaktorregel-main/dvb-betreuungsfaktorregel-main';
export * from './component/dvb-betreuungsfaktorregel-erstellen/dvb-betreuungsfaktorregel-erstellen';
export * from './component/dvb-betreuungsfaktor-regel/dvb-betreuungsfaktor-regel';
export * from './component/dvb-betreuungsfaktor-altersbedingt/dvb-betreuungsfaktor-altersbedingt';
export * from './component/dvb-betreuungsfaktor-regel-form/dvb-betreuungsfaktor-regel-form';
// Add New Component JS Above

betreuungsfaktorregelConfig.$inject = ['$stateProvider'];

function betreuungsfaktorregelConfig($stateProvider: StateProvider): void {
    $stateProvider.state('base.betreuungsfaktorregel', {
        url: '/betreuungsfaktorregel/{id:[0-9]+}',
        template: '<dvb-betreuungsfaktorregel-main betreuungsfaktor-regel="$resolve.betreuungsfaktorRegel">' +
            '</dvb-betreuungsfaktorregel-main>',
        resolve: {
            betreuungsfaktorRegel: [
                'mandantService', '$transition$', (
                    mandantService: MandantService,
                    $transition$: Transition,
                ): angular.IPromise<BetreuungsfaktorRegel | null> =>
                    mandantService.getBetreuuungsfaktorRegel($transition$.params().id, {cache: true}),
            ],
            $title: [
                'betreuungsfaktorRegel', (betreuungsfaktorRegel: BetreuungsfaktorRegel): string =>
                    betreuungsfaktorRegel.getDisplayName(),
            ],
        },
        data: {
            permission: PERMISSION.KITA.VIEW_ANY,
        },
    });

    $stateProvider.state('base.betreuungsfaktorregel-erstellen', {
        url: '/betreuungsfaktorregel-erstellen',
        template: '<dvb-betreuungsfaktorregel-erstellen></dvb-betreuungsfaktorregel-erstellen>',
        data: {
            permission: PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN,
        },
    });
    /* Add New States Above */

}
