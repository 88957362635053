/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TaskFilterCatergory} from '@dv/kitadmin/models';
import {TASK_CATEGORY, TASK_FILTER_CATEGORY} from '@dv/kitadmin/models';
import type {IRestModel} from '@dv/shared/code';
import {FilterOption} from '../../filter/shared/FilterOption';

export class TaskFilter implements IRestModel {

    public constructor(
        public orte: FilterOption[] = [],
        public filterCategories: FilterOption<TaskFilterCatergory>[] = TaskFilter.generateCategoryFilterOptions(),
    ) {
    }

    public static generateCategoryFilterOptions(categories?: TaskFilterCatergory[])
        : FilterOption<TaskFilterCatergory>[] {
        return (categories ?? TASK_FILTER_CATEGORY.values)
            .map(category => new FilterOption(category, category, undefined, true));
    }

    public isFilterSet(): boolean {
        return [...this.orte, ...this.filterCategories].some(filter => filter.selected);
    }

    public reset(): void {
        FilterOption.unselect([...this.orte, ...this.filterCategories]);
    }

    public getSelectedCategories(): TaskFilterCatergory[] {
        return FilterOption.getSelectedIds(this.filterCategories);
    }

    public toRestObject(): Record<string, unknown> {
        const result: Record<string, unknown> = {};

        const selectedCategories = FilterOption.getSelectedIds(this.filterCategories);
        const taskCategories = Object.entries(TASK_CATEGORY)
            .filter(([_category, info]) => selectedCategories.some(superCategory => superCategory === info.super))
            .map(([_category, info]) => info.name);

        if (taskCategories.length > 0) {
            result.taskCategories = taskCategories;
        }

        const orteIds = FilterOption.getSelectedIds(this.orte);

        if (orteIds.length > 0) {
            result.namedEntityIds = orteIds;
        }

        return result;
    }

}
