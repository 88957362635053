/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout, KinderOrt, Kontaktperson} from '@dv/kitadmin/models';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {SteuerbescheinigungStructure} from '../../report/models';
import {SendSteuerbescheinigung} from '../models/steuerbescheinigungen/SendSteuerbescheinigung';
import {SteuerbescheinigungenState} from '../models/steuerbescheinigungen/SteuerbescheinigungenState';

export class SteuerbescheinigungenService {
    public static $inject: readonly string[] = ['$http'];

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/communication/steuerbescheinigungen`;

    public constructor(private $http: angular.IHttpService) {
    }

    public getSteuerbescheinigungManagement(
        kinderOrtId: string,
        jahr: number,
        dokumentLayoutId: string | null,
    ): angular.IPromise<SteuerbescheinigungenState> {
        const matrixParams = {
            jahr,
            dokumentLayoutId,
        };
        const url = `${SteuerbescheinigungenService.baseUrl}/${encodeURIComponent(kinderOrtId)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, SteuerbescheinigungenState, matrixParams)
            .then(checkPresent);
    }

    public sendSteuerbescheinigung(
        dokumentLayout: DokumentLayout | null,
        kinderOrt: KinderOrt,
        gueltigAb: moment.Moment,
        kontaktpersonen: Kontaktperson[],
        structure: SteuerbescheinigungStructure,
    ): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const steuerbescheinigung = new SendSteuerbescheinigung(
            gueltigAb.year(),
            kinderOrt.id!,
            dokumentLayout ? checkPresent(dokumentLayout.id) : null,
            kontaktpersonen.map(DvbUtil.mapToIdChecked),
            structure);

        return this.$http.post(SteuerbescheinigungenService.baseUrl, steuerbescheinigung.toRestObject());
    }
}
