<div class="dvb-steuerbescheinigung-form dvb-form">

    <h2 data-translate="COMMUNICATION.STEUERBESCHEINIGUNG.TITLE"></h2>

    <form name="steuerbescheinigungForm" novalidate ng-submit="vm.submit(steuerbescheinigungForm.$valid)">
        <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                      as-col="true"
                                      ng-model="vm.kinderOrtSearchResult"
                                      ng-change="vm.updateState()"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>

        <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                   custom-options="{minMode: 'year'}"
                                   label-date-format="'YYYY'"
                                   format="'YYYY'"
                                   ng-change="vm.updateState()"
                                   ng-attr-placeholder="{{'COMMON.YEAR' | translate}}{{vm.gueltigAb ? '' : (': ' + (vm.defaultDate| amDateFormat: 'YYYY'))}}">
        </dvb-datepicker-text-field>

        <dvb-dokument-layout dokument-layout="vm.dokumentLayout"
                             on-change="vm.updateDokumentLayout(dokumentLayout)">
        </dvb-dokument-layout>

        <div class="form-row">
            <dvb-report-steuerbescheinigung-structure structure="vm.structure"></dvb-report-steuerbescheinigung-structure>
        </div>

        <dvb-loading-button type="submit" is-loading="vm.isLoading" class="big-abstand-top">
            <span data-translate="COMMUNICATION.SELECT_RECIPIENTS"></span>
        </dvb-loading-button>

    </form>

</div>
