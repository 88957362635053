/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import type {Persisted, ReportType} from '@dv/shared/code';
import {checkPresent, FormContext, REPORT_TYPE, ReportTemplate} from '@dv/shared/code';
import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import {ReportState} from '../../models';
import type {ReportTemplateService} from '../../service/reportTemplateService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {},
    template: require('./dvb-report-template-konfiguration.html'),
    controllerAs: 'vm',
};

export class DvbReportTemplateKonfiguration implements angular.IController {
    public static $inject: readonly string[] = [
        '$uiRouterGlobals',
        'reportTemplateService',
        'dialogService',
        'errorService',
        '$translate',
        'authStore',
    ];

    public reports: ReportState[] = [];
    public isLoading: boolean = false;
    public readonly ctxDefaultTemplate: FormContext = new FormContext();
    public readonly ctxExistingTemplate: FormContext = new FormContext();
    public report: ReportState | null = null;
    public template: ReportTemplate | null = null;
    public erfassteReportTemplates: ReportTemplate[] = [];
    public tempBlob: TempBlob | null = null;

    public editMode: { [templateId: string]: boolean } = {};

    public submitTemplateForm!: angular.IFormController;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private $uiRouterGlobals: UIRouterGlobals,
        private reportTemplateService: ReportTemplateService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private $translate: angular.translate.ITranslateService,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.reports = this.getReports();
    }

    public loadTemplates(reportType: ReportType): angular.IPromise<void> {
        this.template = new ReportTemplate(reportType);

        return this.reportTemplateService.getAllByType(reportType)
            .then(templates => {
                this.erfassteReportTemplates = templates;
            });
    }

    public getCurrentStateName(): string | undefined {
        return this.$uiRouterGlobals.current.name;
    }

    public downloadExistingTemplate(templateId: string): void {
        this.downloadTemplate(() => this.reportTemplateService.getTempBlob(templateId), this.ctxExistingTemplate);
    }

    public downloadDefaultTemplate(reportType: ReportType): void {
        const supplier = (): angular.IPromise<TempBlob> => this.reportTemplateService.getTempBlobForDefault(reportType);
        this.downloadTemplate(supplier, this.ctxDefaultTemplate);
    }

    public cancel(): void {
        this.report = null;
    }

    public submit(): void {
        const template = checkPresent(this.template);
        const isValid = template.isValid() && !!this.tempBlob && !!this.tempBlob.id!;

        this.errorService.clearAll();
        this.errorService.handleValidationError(isValid, 'REPORT.TEMPLATE.ERR_INCOMPLETE');

        if (!isValid) {
            return;
        }

        template.blobId = this.tempBlob!.id!;

        this.isLoading = true;
        this.reportTemplateService.create(template)
            .then(() => this.clearAndReload())
            .finally(() => {
                this.isLoading = false;
            });
    }

    public update(template: Persisted<ReportTemplate>): void {
        this.ctxExistingTemplate.startLoading();
        this.reportTemplateService.update(template).then(() => {
            this.editMode[template.id] = false;
            this.reload().then(() => this.ctxExistingTemplate.finishLoading());
        });
    }

    public deleteTemplate(reportTemplate: ReportTemplate): void {
        const entityText = this.$translate.instant('REPORT.TEMPLATE.TEMPLATE_VALUE', {name: reportTemplate.name});

        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(this.reportTemplateService.delete(reportTemplate))
                .pipe(take(1), tap(() => this.clearAndReload())),
        });
    }

    private getReports(): ReportState[] {
        return Object.values(ReportState.BETRIEB)
            .concat(Object.values(ReportState.CONTROLLING))
            .concat(Object.values(ReportState.FAKTURA))
            .concat(Object.values(ReportState.PERSONAL))
            // only include types, for which we have a backend-equivalent
            .filter(reportState => REPORT_TYPE.guard(reportState.key))
            // types without excel version nr. do not support templates
            .filter(reportState => !!reportState.templateVersion)
            .filter(reportState => this.authStore.isPermitted(reportState.permission));
    }

    private clearAndReload(): void {
        this.errorService.clearAll();
        this.template = null;
        this.tempBlob = null;

        // reset form
        this.submitTemplateForm.$setPristine();
        this.submitTemplateForm.$setUntouched();

        this.reload();
    }

    private reload(): angular.IPromise<void> {
        return this.loadTemplates(checkPresent(this.report).asReportType());
    }

    private downloadTemplate(downloadSupplier: () => angular.IPromise<TempBlob>, formContext: FormContext): void {
        formContext.startLoading();

        downloadSupplier()
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => {
                formContext.finishLoading();
            });
    }
}

componentConfig.controller = DvbReportTemplateKonfiguration;
angular.module('kitAdmin').component('dvbReportTemplateKonfiguration', componentConfig);
