/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsZeitraumBelegung, Kind, Kontingente} from '@dv/kitadmin/models';
import {ExtraPlatzType, ServiceContainer} from '@dv/kitadmin/models';
import type {RestInclude, RestLimited} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type angular from 'angular';
import moment from 'moment';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import {KindFilterModel} from '../../service/kinderFilter-models/KindFilterModel';
import type {KinderListeStrategy} from './KinderListeStrategy';

const roundingMultiple = 100;

export class BelegungStrategy implements KinderListeStrategy {

    private kinderLoaded: moment.Moment | null = null;
    private kindFilterModelInitialized: moment.Moment | null = null;

    public constructor(
        public isBewerbung: boolean = false,
        public isBelegung: boolean = true,
        public gruppenHeaderKey: string = 'KINDERORT.TITLE_BELEGTE_PLAETZE',
        public listModel: string = 'belegung',
        public pensumDisplay: string = '0%',
        public vertraglichesPensum: number = 0,
        public capacityOnly: boolean = false,
    ) {
    }

    public orderBy(kind: Kind): string[] {
        return [kind.familienName ?? '', kind.vorName];
    }

    public getZeitraumFeldTitle(betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung): string {
        const keinePlaetzeValue = ServiceContainer.$translate.instant('COMMON.KAPAZITAET_KEINE_PLAETZE');
        const plaetzeValue = betreuungsZeitraumBelegung.anzahlPlaetze ?? keinePlaetzeValue;
        const maxPlaetzeValue = betreuungsZeitraumBelegung.maxAnzahlPlaetze ?? keinePlaetzeValue;

        if (this.capacityOnly) {
            return ServiceContainer.$translate.instant('KINDERORT.ZEITRAUMFELD_TITLE_CAPACITY', {
                plaetze: plaetzeValue,
                maxPlaetze: maxPlaetzeValue,
            });
        }

        const belegtePlaetzeValue = betreuungsZeitraumBelegung.maxAnzahlPlaetze === null ?
            betreuungsZeitraumBelegung.belegtePlaetze ?? keinePlaetzeValue :
            betreuungsZeitraumBelegung.belegtePlaetze;
        const kinderValue = betreuungsZeitraumBelegung.anzahlKinder ?? 0;

        if (moment.isMoment(betreuungsZeitraumBelegung.verfuegbarBis)) {
            return ServiceContainer.$translate.instant('KINDERORT.ZEITRAUMFELD_TITLE_BELEGT_LIMITED', {
                plaetze: plaetzeValue,
                maxPlaetze: maxPlaetzeValue,
                belegtePlaetze: belegtePlaetzeValue,
                kinder: kinderValue,
                verfuegbarBis: betreuungsZeitraumBelegung.verfuegbarBis.format('D.M.YYYY'),
            });
        }

        return ServiceContainer.$translate.instant('KINDERORT.ZEITRAUMFELD_TITLE_BELEGT', {
            plaetze: plaetzeValue,
            maxPlaetze: maxPlaetzeValue,
            belegtePlaetze: belegtePlaetzeValue,
            kinder: kinderValue,
        });
    }

    public getZeitraumFeldValue(betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung): string {
        if ((betreuungsZeitraumBelegung.maxAnzahlPlaetze === null && betreuungsZeitraumBelegung.belegtePlaetze === 0)
            || betreuungsZeitraumBelegung.belegtePlaetze === null) {

            // falls es keine Kapazitaet gibt und die Belegung 0 ist, dann zeige einen leeren String.
            return '';
        }

        if (this.capacityOnly) {
            // not empty string, to avoid 'no-value' crossed out background
            return ' ';
        }

        return String(Math.round(roundingMultiple * betreuungsZeitraumBelegung.belegtePlaetze) / roundingMultiple);
    }

    public createKindFilterModel(params: {
        kind: Kind;
        kitaKontingente: Kontingente[];
        gruppenIds: string[];
        firstOfWeek: moment.Moment;
    }): angular.IPromise<KindFilterModel> {
        const belegung = DvbDateUtil.getEntityOn(params.kind.kindWochenBelegungen, params.firstOfWeek);
        if (!belegung) {
            return ServiceContainer.$q.resolve(new KindFilterModel());
        }
        // additional extra plaetze are already included in the wochenbelegung.
        // All we have to do is remove absences from the wochenbelegung
        const lastOfWeek = DvbDateUtil.endOfWeek(moment(params.firstOfWeek));
        const absences = params.kind.extraPlaetze
            .filter(p => p.affectedDay!.isBetween(params.firstOfWeek, lastOfWeek, 'day', '[]'))
            .filter(p => ExtraPlatzType.ABSENCE === p.extraPlatzType);

        const verfuegbarAb = params.kind.bewerbung ? params.kind.bewerbung.gewuenschteBetreuungAb : null;

        return KindFilterModel.createFromBelegung(
            belegung,
            absences,
            params.kitaKontingente,
            params.gruppenIds,
            verfuegbarAb,
            params.kind,
        ).then(model => {
            this.kindFilterModelInitialized = params.firstOfWeek;

            return model;
        });
    }

    public loadKinder(
        kinderOrtService: KinderOrtService,
        params: RestInclude & RestLimited & angular.IRequestShortcutConfig & { kitaId: string },
    ): angular.IPromise<Kind[]> {

        // wenn die Woche gaendert wird, muessen die Belegungen neu geladen werden
        const currentLoaded = moment(checkPresent(params.gueltigAb));

        return kinderOrtService.getKinderWithBelegungen(params.kitaId, params)
            .then(kinder => {
                this.kinderLoaded = currentLoaded;

                return kinder;
            });
    }

    public isLoaded($scope: angular.IScope & { firstOfWeek: moment.Moment }): boolean {
        return DvbDateUtil.isMomentEquals(this.kinderLoaded, $scope.firstOfWeek)
            && DvbDateUtil.isMomentEquals(this.kindFilterModelInitialized, $scope.firstOfWeek);
    }

    public updatePensumTotal(kinder: Kind[], firstOfWeek: moment.Moment, kitaId: string): void {
        let pensumTotal = 0;
        let vertraglichesPensumTotal = 0;

        kinder.forEach(kind => {
            if (!(kind as any).visible) {
                return;
            }

            const belegung = DvbDateUtil.getEntityOn(kind.kindWochenBelegungen, firstOfWeek);

            if (!belegung) {
                return;
            }

            pensumTotal += belegung.kitaPensen[kitaId] || 0;
            vertraglichesPensumTotal += belegung.kitaVertraglichePensen[kitaId] || 0;
        });

        this.pensumDisplay = `${Math.round(pensumTotal * roundingMultiple) / roundingMultiple}%`;
        this.vertraglichesPensum = Math.round(vertraglichesPensumTotal * roundingMultiple) / roundingMultiple;
    }
}
