/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, KiTaxVerfuegung} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, lastValueFrom, Subject, take, tap} from 'rxjs';
import type {BetreuungsGutscheinService} from '../../../../../common/service/rest/kind/betreuungsGutscheinService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        verfuegung: '<',
        kind: '<',
    },
    template: require('./dvb-kitax-import-preview.html'),
    controllerAs: 'vm',
};

export class DvbKitaxImportPreview implements angular.IController {

    public static $inject: readonly string[] = [
        'errorService',
        'betreuungsGutscheinService',
        '$state',
        'dialogService',
        '$translate',
    ];

    public verfuegung!: KiTaxVerfuegung;
    public kind!: Kind;

    public kitaSearchResultEntry: SearchResultEntry | null = null;
    public isLoading: boolean = false;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly betreuungsGutscheinService: BetreuungsGutscheinService,
        private readonly $state: StateService,
        private readonly dialogService: DialogService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (!changes.verfuegung) {
            return;
        }

        this.kitaSearchResultEntry = changes.verfuegung.currentValue.kita ?
            changes.verfuegung.currentValue.kita.toSearchResultEntry() :
            null;
    }

    public importVerfuegung(form: angular.IFormController): void {
        if (!form.$valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');
        }

        if (!form.$valid) {
            return;
        }

        this.errorService.clearAll();

        this.isLoading = true;

        const kindId = checkPresent(this.kind.id);
        const kinderOrtId = checkPresent(this.kitaSearchResultEntry).id;
        const params = {
            gueltigAb: checkPresent(this.verfuegung.gueltigAb),
            gueltigBis: checkPresent(this.verfuegung.gueltigBis),
        };

        this.betreuungsGutscheinService.findBetreuungsGutscheine(kindId, kinderOrtId, params)
            .then(gutscheine => {
                if (gutscheine.length > 0) {
                    const title = this.$translate.instant(
                        'KIND.KITAX.KITAX_IMPORT_UEBERSCHREIBT_BETREUUNGSGUTSCHEINE', {
                            anzahl: gutscheine.length,
                        });

                    const dialog$ = new Subject<void>();
                    this.dialogService.openConfirmDialog({
                        title,
                        confirm: () => from(this.doImport()).pipe(tap(() => dialog$.next())),
                        cancel: () => dialog$.next(),
                    });

                    return lastValueFrom(dialog$.pipe(take(1)));
                }

                return this.doImport();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private doImport(): angular.IPromise<void> {
        const kindId = checkPresent(this.kind.id);
        const kinderOrtId = checkPresent(this.kitaSearchResultEntry).id;
        const tempBlobId = checkPresent(this.verfuegung.tempBlobId);

        return this.betreuungsGutscheinService.commitKiTaxImport(kindId, kinderOrtId, tempBlobId).then(() => {
            this.$state.go('base.kind.tarife.uebersicht');
        });
    }
}

componentConfig.controller = DvbKitaxImportPreview;
angular.module('kitAdmin').component('dvbKitaxImportPreview', componentConfig);
