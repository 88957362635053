/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnDestroy} from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    Output,
    signal,
    ViewChild,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
    ButtonComponent,
    DisplayNamePipe,
    LetDirective,
    SortListTemplateDirective,
    trackById,
} from '@dv/shared/angular';
import type {IDisplayable, IPersistable} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import type {Subscription} from 'rxjs';
import {filter, fromEvent, map, tap} from 'rxjs';
import {CalendarGroup} from '../../model/CalendarGroup';
import type {CalendarGroupResourceAddEvent} from '../../model/CalendarGroupResourceAddEvent';
import {CalendarTranslation} from '../../model/CalendarTranslation';

@Component({
    selector: 'dv-timeline-group-add-resource',
    standalone: true,
    imports: [
        FormsModule,
        TypeaheadModule,
        TranslateModule,
        ButtonComponent,
        DisplayNamePipe,
        SortListTemplateDirective,
        LetDirective,
        TooltipModule,
    ],
    templateUrl: './timeline-group-add-resource.component.html',
    styleUrls: ['./timeline-group-add-resource.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineGroupAddResourceComponent implements OnDestroy {
    @Input({required: true}) public group!: CalendarGroup;
    @Input({required: true}) public calendarTranslation!: CalendarTranslation;
    @Input() public availableResources: (IPersistable & IDisplayable)[] = [];

    @Output() public readonly resourceAdd: EventEmitter<CalendarGroupResourceAddEvent> = new EventEmitter();

    @ViewChild('input')
    public inputElem?: ElementRef;

    public elementRef = inject(ElementRef);

    public customSelectedResource: (IPersistable & IDisplayable) | undefined = undefined;
    public showInput = signal(false);

    public outsideClick$ = fromEvent(document, 'click').pipe(
        filter(e => e !== this.initiatingClick),
        map(e => e.target),
        filter(t => !this.elementRef.nativeElement.contains(t)),
    );
    public hideOnClickOutside$ = this.outsideClick$.pipe(
        tap(() => this.showInput.set(false)),
    );

    private initiatingClick?: Event;
    private subscription?: Subscription;

    public trackById = trackById;

    private showWatcher = effect(() => {
        if (this.showInput()) {
            this.subscription = this.hideOnClickOutside$.subscribe();
            setTimeout(() => {
                this.inputElem?.nativeElement.focus();
            });
        } else {
            this.subscription?.unsubscribe();
            this.customSelectedResource = undefined;
            if (this.inputElem?.nativeElement) {
                this.inputElem.nativeElement.value = '';
            }
        }
    });

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public show(event: Event): void {
        this.initiatingClick = event;
        event.preventDefault();
        this.showInput.set(true);
    }

    public hideOnEsc(event: Event): void {
        if (event instanceof KeyboardEvent && event.key === 'Escape') {
            this.showInput.set(false);
        }
    }

    public getSelectableResources(group: CalendarGroup): (IPersistable & IDisplayable)[] {
        const zugewiesen: string[] = group?.resources.map(resource => resource.id) || [];

        return zugewiesen.length ?
            this.availableResources.filter(resource => !zugewiesen.includes(resource.id!)) :
            this.availableResources;
    }

    public addResource(match: TypeaheadMatch, group: CalendarGroup): void {
        this.showInput.set(false);
        this.resourceAdd.emit({
            group,
            resource: match.item,
        });
    }
}
