<div class="dvb-external-anmeldung-kind-stammdaten">

    <div class="row" ng-click="vm.kind.form.showStammdaten = !vm.kind.form.showStammdaten">
        <div class="col-md-12">
            <a href="" class="pull-left smaller-abstand-right">
                <i class="fa"
                   ng-class="{
                   'fa-chevron-right': !vm.kind.form.showStammdaten,
                    'fa-chevron-down': vm.kind.form.showStammdaten
                   }">
                </i>
            </a>
            <h2 class="pull-left"
                data-translate="EXTERNAL_ANMELDUNG.STAMMDATEN_KIND"
                data-translate-value-name="{{vm.kind.getDisplayName()}}">
            </h2>
        </div>
    </div>

    <div uib-collapse="!vm.kind.form.showStammdaten">
        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       name="kindFamilienName"
                       dvb-capitalize-first-letter
                       class="form-control"
                       maxlength="255"
                       ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                       uib-tooltip="{{'COMMON.NAME' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.kind.familienName"
                       required>
            </div>
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       name="kindVorName"
                       dvb-capitalize-first-letter
                       maxlength="255"
                       ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                       uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.kind.vorName">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.kind.geburtsTermin"
                                           ng-required="!vm.kind.isGeburtsDatumTerminSet()"
                                           ng-attr-placeholder="{{'KIND.GEBURTSTERMIN' | translate}}">
                </dvb-datepicker-text-field>
            </div>
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.kind.geburtsTag"
                                           ng-required="!vm.kind.isGeburtsDatumTerminSet()"
                                           ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>
        <div class="row form-row">
            <div ng-class="{'col-md-6': vm.form.$submitted, 'col-md-12': !vm.form.$submitted}">
                <div class="radio" ng-repeat="geschlecht in vm.geschlechter">
                    <label>
                        <input type="radio"
                               name="geschlecht_{{vm.index}}"
                               value="{{geschlecht}}"
                               ng-model="vm.kind.geschlecht"
                               required>
                        <span data-translate="{{'EXTERNAL_ANMELDUNG.GESCHLECHT_KIND.' + geschlecht }}"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-6" ng-show="vm.form.$submitted && !vm.kind.geschlecht">
                <p class="error-message"
                   data-translate="ERRORS.ERR_REQUIRED_GESCHLECHT"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dvb-iso-locale ng-model="vm.kind.muttersprache"
                                type="LANGUAGE"
                                ng-attr-placeholder="{{'KIND.MUTTERSPRACHE' | translate}}">
                </dvb-iso-locale>
            </div>
        </div>
    </div>
</div>
