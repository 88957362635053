<div class="dvb-corona-rueckerstattung-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.reportForm" novalidate>

        <dvb-report-template-selector report-state="vm.reportState"
                                      on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <div class="form-row">
            <dvb-search-get-single-entity as-col="true"
                                          entity-to-search="KINDERORT"
                                          ng-change="vm.updateKinderOrt()"
                                          ng-model="vm.kinderOrtSearchResult"
                                          placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                          required>
            </dvb-search-get-single-entity>
        </div>

        <div class="form-row" ng-if="vm.showVerpflegung">
            <input class="form-control"
                   type="number"
                   step="1"
                   dvb-integer
                   max="100"
                   min="0"
                   ng-model="vm.verpflegungRefundPct"
                   ng-attr-placeholder="{{'REPORT.CORONA_REFUNDS.VERPFLEGUNG_REFUND_PCT' | translate}}: {{vm.defaultVerpflegungRefundPct}}%"
                   tooltip-placement="top-left"
                   uib-tooltip="{{'REPORT.CORONA_REFUNDS.VERPFLEGUNG_REFUND_PCT' | translate}}: {{vm.verpflegungRefundPct || vm.defaultVerpflegungRefundPct}}%">
        </div>

        <div class="form-row">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.createRefunds">
                    <span data-translate="REPORT.CORONA_REFUNDS.CREATE_REFUNDS"></span>
                </label>
            </div>
        </div>

        <div class="form-row">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.downloadPdfs">
                    <span data-translate="REPORT.CORONA_REFUNDS.DOWNLOAD_PDFS"></span>
                </label>
            </div>
        </div>

        <dvb-dokument-layout ng-if="vm.downloadPdfs" on-change="vm.layout = dokumentLayout"></dvb-dokument-layout>

        <input ng-if="vm.downloadPdfs"
               type="text"
               ng-model="vm.massenversandText"
               ng-attr-placeholder="{{'KINDERORT.TEXT_MASSENVERSAND' | translate}}"
               uib-tooltip="{{'KINDERORT.TEXT_MASSENVERSAND' | translate}}"
               tooltip-placement="top-left"
               class="form-control"
               maxlength="255">

        <div ng-if="vm.downloadPdfs" class="big-abstand-bottom">
            <div class="checkbox small-abstand-bottom">
                <label>
                    <input type="checkbox" ng-model="vm.addSignatures">
                    <span data-translate="REPORT.CORONA_REFUNDS.ADD_SIGNATURES"></span>
                </label>
            </div>

            <dvb-datepicker-text-field ng-model="vm.returnDate"
                                       ng-change="vm.updateDefaultTexts()"
                                       ng-attr-placeholder="{{'REPORT.CORONA_REFUNDS.RETURN_DATE' | translate}}">
            </dvb-datepicker-text-field>

            <textarea ng-model="vm.pdfIntroduction"
                      class="form-control"
                      msd-elastic
                      ng-attr-placeholder="{{'REPORT.CORONA_REFUNDS.PDF_INTRODUCTION' | translate}}"
                      uib-tooltip="{{'REPORT.CORONA_REFUNDS.PDF_INTRODUCTION' | translate}}"
                      tooltip-placement="top-left"
                      maxlength="5000"
                      rows="6">
            </textarea>
            <textarea ng-model="vm.pdfAdditionalText"
                      class="form-control"
                      msd-elastic
                      ng-attr-placeholder="{{'REPORT.CORONA_REFUNDS.PDF_ADDITIONAL_TEXT' | translate}}"
                      uib-tooltip="{{'REPORT.CORONA_REFUNDS.PDF_ADDITIONAL_TEXT' | translate}}"
                      tooltip-placement="top-left"
                      maxlength="5000"
                      rows="6">
            </textarea>
            <textarea ng-model="vm.pdfAdditionalTextNoAbsences"
                      class="form-control"
                      msd-elastic
                      ng-attr-placeholder="{{'REPORT.CORONA_REFUNDS.PDF_ADDITIONAL_TEXT_NO_ABSENCES' | translate}}"
                      uib-tooltip="{{'REPORT.CORONA_REFUNDS.PDF_ADDITIONAL_TEXT_NO_ABSENCES' | translate}}"
                      tooltip-placement="top-left"
                      maxlength="5000"
                      rows="6">
            </textarea>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>

        <div ng-if="vm.generatedReports.length > 0" class="big-abstand-top generated-reports">
            <div class="row">
                <div class="col-xs-1 floating-text">
                    <dvb-loading-button as-link="true"
                                        on-click="vm.refreshGeneratedReports()"
                                        tooltip-placement="top-left"
                                        type="button"
                                        uib-tooltip="{{'REPORT.GESPEICHERTE_AKTUALISIEREN' | translate}}">
                        <i class="fa fa-refresh" ng-show="!vm.isLoading"></i>
                        &nbsp;<!-- when there is no content, the position is off-->
                    </dvb-loading-button>
                </div>
                <div class="col-xs-11">
                    <p>
                        <strong data-translate="REPORT.GESPEICHERTE"></strong>
                        <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>
                    </p>
                </div>
            </div>
            <div class="form-row">
                <div class="row selected-entity"
                     ng-repeat="blobInfo in vm.generatedReports | orderBy:'filename'"
                     uib-tooltip="{{blobInfo.filename}}"
                     tooltip-placement="left">
                    <div class="col-xs-12">
                        <dvb-loading-button as-link="true"
                                            class="entity generated-report-button"
                                            is-loading="vm.isDownloading[blobInfo.id]"
                                            type="button"
                                            on-click="vm.downloadBlob(blobInfo.id)">
                            <span ng-bind="blobInfo.filename"></span>
                        </dvb-loading-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
