<div class="dvb-betreuungs-schluessel">
    <div class="header">
        <div class="row header-row">
            <div class="col-md-12">
                <h1 data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.SINGULAR"></h1>
            </div>
        </div>
    </div>

    <form name="schluesselForm" class="dvb-form" ng-submit="vm.submit(schluesselForm.$valid)" novalidate>
        <div class="row">
            <div class="col-md-7">

                <div class="row">
                    <div class="col-md-10">
                        <input type="text"
                               name="betreuungsSchluesselName"
                               class="form-control"
                               ng-model="vm.betreuungsSchluessel.name"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               required>
                    </div>
                </div>

                <h2 class="normal-abstand-top" data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.SCHLUESSEL"></h2>

                <div ng-repeat="regel in vm.betreuungsSchluessel.betreuungsSchluesselRegeln" class="border-top">
                    <div class="row">
                        <div class="col-md-10 small-abstand-top">
                            <select class="form-control"
                                    uib-tooltip="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.TAG' | translate}}"
                                    tooltip-placement="top-left"
                                    ng-model="regel.dayOfWeek"
                                    ng-attr-placeholder="{{'PERSONAL.BETREUUNGS_SCHLUESSEL.TAG' | translate}}"
                                    ng-options="day as (regel.getDayOfWeekString(day)) for day in vm.days">
                                <option ng-value="null"
                                        data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.DEFAULT_TAG"></option>
                            </select>
                        </div>

                        <div class="col-md-10 small-abstand-left">

                            <dvb-betreuungs-schluessel-regel betreuungs-schluessel="vm.betreuungsSchluessel"
                                                             betreuungs-schluessel-regel="regel"
                                                             ausbildungen="vm.ausbildungenFlat"
                                                             on-delete-regel-bedarf="vm.deleteRegelBedarf(regel, regelBedarf)"
                                                             on-delete-ausbildungs-bedarf="vm.deleteAusbildungsBedarf(bedarf, regelBedarf)"
                                                             on-add-ausbildungs-bedarf="vm.addAusbildungsBedarf(regelBedarf)">
                            </dvb-betreuungs-schluessel-regel>

                            <ul class="list-inline border-top small-abstand-top big-abstand-bottom">
                                <li>
                                    <button type="button"
                                            class="btn btn-link normal-abstand-top"
                                            ng-click="vm.addRegelBedarf(regel)">
                                        +
                                        <span data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.ADD_BEDARF"></span>
                                    </button>
                                </li>
                                <li ng-if="vm.betreuungsSchluessel.betreuungsSchluesselRegeln.length > 1">
                                    <button type="button"
                                            class="btn btn-link normal-abstand-top"
                                            ng-click="vm.deleteRegel(regel)"
                                            data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.DELETE_RULE">
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row big-abstand-bottom">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <button type="button"
                                        class="btn btn-link"
                                        ng-click="vm.addRegel()">
                                    + <span data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.ADD_RULE"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="normal-abstand-top">
                    <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                               on-cancel="vm.cancel()">
                    </dvb-submit-cancel-buttons>
                </div>

            </div>
        </div>
    </form>
</div>
