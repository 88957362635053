<ul class="list-inline dvb-translatable-language" ng-if="vm.supportedLanguages.length > 1">
    <li ng-repeat="lang in vm.supportedLanguages" class="list-item">
        <button type="button"
                class="btn btn-tab"
                ng-class="{'active': vm.ngModelCtrl.$viewValue === lang}"
                ng-click="vm.switchLanguage(lang); $event.stopPropagation()"
                data-translate="LANGUAGE.{{lang}}">
        </button>
    </li>
</ul>
