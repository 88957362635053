/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BelegteEinheit, ISelectedFirma, Kind} from '@dv/kitadmin/models';
import {Bewerbung, Firma, isBewerbungsplanError, Kita} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPersisted, DvbDateUtil} from '@dv/shared/code';
import type {StateService, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {finalize, from, Subject, switchMap, take} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbBewerbungsplanService} from '../../../common/service/dvbBewerbungsplanService';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import {AbstractBewerbungsplanController} from '../../models/AbstractBewerbungsplanController';
import type {KindValidationService} from '../../service/kindValidationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenplan: '<',
    },
    template: require('./dvb-anmeldung.html'),
    controllerAs: 'vm',
};

export class DvbAnmeldung extends AbstractBewerbungsplanController implements angular.IController {

    public static override $inject: readonly string[] = [
        '$state',
        '$uiRouterGlobals',
        'kindService',
        'errorService',
        'dvbBewerbungsplanService',
        'kindValidationService',
        'dvbStateService',
        'dialogService',
    ];

    public kind!: Kind;
    public geschwisterId: string | null;
    public defaultAnmeldeDatum: moment.Moment = DvbDateUtil.today();

    // Bewerbung
    public bewerbung = new Bewerbung();

    public isLoading: boolean = false;

    public constructor(
        private readonly $state: StateService,
        $uiRouterGlobals: UIRouterGlobals,
        private readonly kindService: KindService,
        errorService: ErrorService,
        private readonly dvbBewerbungsplanService: DvbBewerbungsplanService,
        private readonly kindValidationService: KindValidationService,
        private readonly dvbStateService: DvbStateService,
        private readonly dialogService: DialogService,
    ) {
        super(errorService);
        this.geschwisterId = $uiRouterGlobals.params.geschwisterId ?? null;
    }

    public onKindCreated(kind: Kind): void {
        this.kind = kind;
    }

    public betreuungsgruendeChanged(prioritaet: number): void {
        this.bewerbung.prioritaet = prioritaet;
    }

    // region bewerbung
    public addFirmaToSelection(firmaItem: SearchResultEntry): void {
        const firma = checkPersisted(new Firma(firmaItem.id, firmaItem.text));
        const firmaSelected: ISelectedFirma = Object.assign(firma, {selected: true});
        this.bewerbung.addFirmaToSelection(firmaSelected);
        this.toggleFirma(firmaSelected);
    }

    public toggleFirma(firma: ISelectedFirma): void {
        if (firma.selected) {
            this.bewerbung.addFirma(firma);
        } else {
            this.bewerbung.removeFirma(firma);
        }
    }

    public addKita(kitaItem: SearchResultEntry): void {
        const kita = new Kita();
        kita.name = kitaItem.text;
        kita.id = kitaItem.id;
        this.bewerbung.addKita(kita);
    }

    // endregion Bewerbun

    public goBack(): void {
        this.dvbStateService.goToPreviousState();
    }

    public validateAndSaveKind(formIsValid: boolean): void {
        let belegteEinheiten: BelegteEinheit[] | null;
        try {
            belegteEinheiten = this.dvbBewerbungsplanService.belegteEinheitenFromZeitraumFelder(
                this.wochenplan,
                this.zeitraumFelder);
        } catch (err) {
            if (isBewerbungsplanError(err)) {
                this.addBelegteEinheitenError(err);

                return;
            }
            throw err;
        }

        const isValidKind = this.isValidKindFunction(formIsValid);
        const isValidBelegteEinheiten = Array.isArray(belegteEinheiten) && belegteEinheiten.length > 0;
        const isValidRechnungsAufteilung = this.kindValidationService.hasValidRechnungsempfaenger(this.kind);
        const hasValidErziehungsberechtigte = this.kindValidationService.hasValidErziehungsberechtigte(this.kind);
        this.errorService.handleValidationError(isValidKind && isValidBelegteEinheiten,
            'ERRORS.ERR_INCOMPLETE_ANMELDUNG');
        this.errorService.handleValidationError(isValidBelegteEinheiten,
            'ERRORS.ERR_REQUIRED_BELEGUNGSEINHEIT');
        this.errorService.handleValidationError(isValidRechnungsAufteilung,
            'ERRORS.ERR_INVALID_RECHNUNGSAUFTEILUNG');
        this.errorService.handleValidationError(hasValidErziehungsberechtigte,
            'ERRORS.ERR_INVALID_ERZIEHUNGSBERECHTIGTE');

        if (isValidKind && isValidBelegteEinheiten && isValidRechnungsAufteilung && hasValidErziehungsberechtigte) {
            this.isLoading = true;
            from(this.kindService.matchingKind(this.kind)).pipe(
                switchMap(kinder => kinder.length > 0 ? this.showConfirmSaveKindModal$(belegteEinheiten ?? []) :
                    this.saveKind$(belegteEinheiten ?? [])),
                finalize(() => {
                    this.isLoading = false;
                }),
            ).subscribe();
        }
    }

    private saveKind$(belegteEinheiten: BelegteEinheit[]): Observable<unknown> {
        this.kind.anmeldeDatum ??= this.defaultAnmeldeDatum;
        this.bewerbung.belegteEinheiten = belegteEinheiten;
        this.bewerbung.wochenplanId = this.wochenplan.id;
        this.kind.bewerbung = this.bewerbung;

        return from(this.kindService.create(this.kind))
            .pipe(
                take(1),
                switchMap(response => from(this.$state.go(
                    'base.kind.profil',
                    {kindId: DvbRestUtilAngularJS.parseEntityIdFromResponse(response)}))));
    }

    private isValidKindFunction(formIsValid: boolean): boolean {
        return formIsValid &&
            this.kind.isValid() &&
            this.bewerbung.isValid() &&
            this.bewerbung.hasGewuenschteBetreuungAb();
    }

    private showConfirmSaveKindModal$(belegteEinheiten: BelegteEinheit[]): Observable<boolean> {

        const confirm$ = new Subject<boolean>();
        this.dialogService.openConfirmDialog({
            title: 'KIND.KIND_WURDE_BEREITS_EINMAL_ERFASST',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm: () => {
                confirm$.next(true);

                return this.saveKind$(belegteEinheiten);
            },
            cancel: () => confirm$.next(false),
        });

        return confirm$.pipe(take(1));
    }
}

componentConfig.controller = DvbAnmeldung;
angular.module('kitAdmin').component('dvbAnmeldung', componentConfig);
