<div class="dvb-kita-faktura-konfiguration-form dvb-form">
    <form name="kitaRechnungsKonfigurationForm"
          ng-submit="vm.submit(kitaRechnungsKonfigurationForm.$valid)"
          novalidate>

        <div class="row form-row">
            <div class="col-sm-6">
                <dvb-datepicker-text-field ng-model="vm.konfiguration.gueltigAb"
                                           dvb-begin-of-month
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}"
                                           required>
                </dvb-datepicker-text-field>
            </div>
            <div class="col-sm-6">
                <dvb-datepicker-text-field ng-model="vm.konfiguration.gueltigBis"
                                           dvb-end-of-month-parser
                                           ng-attr-placeholder="{{'FAKTURA.GUELTIG_BIS_OPTIONAL' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div ng-switch="vm.konfiguration.dtype">
            <dvb-kita-faktura-small-invoice-konfiguration-form
                ng-switch-when="SMALLINVOICE"
                edit-mode="vm.editMode"
                konfiguration="vm.konfiguration.vendorKonfiguration"
                template-konfiguration="vm.templateKonfiguration.vendorKonfiguration"
                mandant-konfiguration-id="vm.konfiguration.rechnungsKonfigurationId">
            </dvb-kita-faktura-small-invoice-konfiguration-form>
            <dvb-kita-faktura-dvb-konfiguration-form
                ng-switch-when="DVB"
                edit-mode="vm.editMode"
                konfiguration="vm.konfiguration.vendorKonfiguration"
                template-konfiguration="vm.templateKonfiguration.vendorKonfiguration"
                template-dtype="vm.templateKonfiguration.dtype"
                konto="vm.konfiguration.konto"
                konten="vm.konten">
            </dvb-kita-faktura-dvb-konfiguration-form>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="COMMON.VERSAND_PER"
                      data-translate-value-versandart="{{'COMMON.VERSANDART_EMAIL' | translate}}"></span>
            </h3>

            <div class="row form-row">
                <div class="col-xs-12">
                    <label>
                        <input type="checkbox" ng-model="vm.konfiguration.bccActive">
                        <span data-translate="KINDERORT.KONFIGURATION_BCC_AKTIVIEREN"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="form-section">
            <h3 class="small-abstand-bottom">
                <span data-translate="KINDERORT.TARIF_POS_KONFIGURATION"></span>
            </h3>
            <div class="row form-row">
                <div class="col-xs-12">
                    <label>
                        <input type="checkbox" ng-model="vm.konfiguration.hideTarifPosFraktionSuffix">
                        <span data-translate="KINDERORT.KONFIGURATION_TARIF_POS_FRAKTION_SUFFIX_AUSGEBLENDET"></span>
                    </label>
                </div>
            </div>
        </div>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   on-cancel="vm.cancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
