/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').directive('dvbInsideScroll', dvbInsideScroll);

/**
 * Putting this directive on a scrollable element will prevent the outside container from scrolling when the top or
 * bottom of this inside container is reached. This is particularly useful if the inside element is expandable with
 * a "Show more" button that extends the container but it's height is limited by max-height.
 */
function dvbInsideScroll(): angular.IDirective {
    return {
        restrict: 'A',
        link(scope, element: JQuery, _attrs, _ctrl) {
            element.on('wheel', (event: JQuery.TriggeredEvent) => {
                const scrollbarVisible = element.prop('scrollHeight') > (element.height() ?? 0);

                if (!scrollbarVisible) {
                    return;
                }

                const direction = -(event.originalEvent as WheelEvent).deltaY;

                const stop =
                    (direction > 0 && element.scrollTop() === 0) ||
                    (direction <= 0 && element.scrollTop() === element[0].scrollHeight - element[0].offsetHeight);

                if (stop) {
                    event.preventDefault();
                }
            });
        },
    };
}
