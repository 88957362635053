<div class="dvb-kita-rechnungslauf-erstellen row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <form name="kitaRechnungslaufForm"
              ng-submit="vm.submit(kitaRechnungslaufForm.$valid)"
              novalidate>

            <h2 data-translate="COMMON.RECHNUNGSLAUF"></h2>
            <dvlib-date-switcher mode="month"
                                 class="normal-abstand-left"
                                 [gueltig-ab]="vm.normalizedAbgerechnetBis"
                                 [(date)]="vm.rechnungsLauf.gueltigAb"
                                 [is-disabled]="vm.isLoading">
            </dvlib-date-switcher>

            <div class="row form-row">
                <div class="col-sm-5">
                    <label>
                        <span class="floating-text" data-translate="COMMON.RECHNUNGSDATUM"></span>
                        <dvb-datepicker-text-field ng-model="vm.rechnungsLauf.rechnungsDatum"
                                                   ng-attr-placeholder="{{'COMMON.RECHNUNGSDATUM' | translate}}"
                                                   required>
                        </dvb-datepicker-text-field>
                    </label>
                </div>
                <div class="col-sm-5">
                    <label>
                        <span class="floating-text" data-translate="COMMON.FAELLIG_AM"></span>
                        <dvb-datepicker-text-field ng-model="vm.rechnungsLauf.faelligAm"
                                                   ng-attr-placeholder="{{'COMMON.FAELLIG_AM' | translate}}"
                                                   required>
                        </dvb-datepicker-text-field>
                    </label>
                </div>
            </div>

            <div class="row form-row"
                 dvb-auth
                 require-permission="granular_kinder_ort:faktura:rechnungslauf:lock_override">
                <div class="col-sm-5">
                    <label>
                        <span class="floating-text" data-translate="FAKTURA.VALUTA_DATE"></span>
                        <dvb-datepicker-text-field ng-model="vm.rechnungsLauf.valutaDatum"
                                                   ng-attr-placeholder="{{'FAKTURA.VALUTA_DATE' | translate}}">
                        </dvb-datepicker-text-field>
                    </label>
                </div>
            </div>

            <div class="normal-abstand-top">
                <ul class="list-inline">
                    <li>
                        <dvb-loading-button is-loading="vm.isLoading" type="submit">
                            <span data-translate="KINDERORT.RECHNUNGSLAUF_STARTEN"></span>
                        </dvb-loading-button>
                    </li>
                    <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.cancel()"></a></li>
                </ul>
            </div>
        </form>
    </div>
</div>
