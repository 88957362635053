/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Zahlung} from '@dv/kitadmin/models';
import {ZahlungType} from '@dv/shared/backend/model/zahlung-type';
import type {Persisted} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        zahlung: '<',
    },
    template: require('./dvb-zahlung-heading.html'),
    controllerAs: 'vm',
};

export class DvbZahlungHeading implements angular.IController {

    public zahlung!: Persisted<Zahlung>;

    public originTitleKey = '';

    public $onInit(): void {
        if (this.zahlung.type !== ZahlungType.BANKZAHLUNG) {
            return;
        }

        if (this.zahlung.vorauszahlung) {
            this.originTitleKey = 'COMMON.VORAUSZAHLUNG';

            return;
        }

        if (this.zahlung.bankStatementEntry) {
            this.originTitleKey = 'FAKTURA.KONTENABGLEICH.KONTENABGLEICH';
        }
    }
}

componentConfig.controller = DvbZahlungHeading;
angular.module('kitAdmin').component('dvbZahlungHeading', componentConfig);
