/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KibonExchangeInstitution} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, FormContext} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import angular from 'angular';
import type {KibonInstitutionenService} from '../../service/kibonInstitutionenService';
import type {QueryKibonExchangeApiQueryapiParameter} from '../../service/kibonQueryApiService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        onQueryApi: '&',
    },
    template: require('./dvb-kibon-maintenance.html'),
    controllerAs: 'vm',
};

export class DvbKibonMaintenance implements angular.IController {
    public static $inject: readonly string[] = ['kibonInstitutionenService', 'errorService', 'authStore'];

    public onQueryApi!: (params: {
        params: QueryKibonExchangeApiQueryapiParameter;
        formContext: FormContext;
    }) => unknown;

    public model: QueryKibonExchangeApiQueryapiParameter = {};
    public formContext: FormContext = new FormContext();
    public institutionen: Persisted<KibonExchangeInstitution>[] = [];
    public selectedInstitution?: Persisted<KibonExchangeInstitution> | string;

    public constructor(
        private readonly institutionenService: KibonInstitutionenService,
        private readonly errorService: ErrorService,
        private readonly authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        if (this.authStore.hasRole(UserRole.MANDANT_ADMIN)) {
            this.initInstitutionen();
        }
    }

    public onSubmit(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (form.$invalid) {
            return;
        }

        this.onQueryApi({params: {...this.model}, formContext: this.formContext});
    }

    public selectInstitution(
        _$item: Persisted<KibonExchangeInstitution>,
        $model: Persisted<KibonExchangeInstitution>,
        _$label: string,
        _$event: JQuery.Event,
    ): void {
        this.model.institutionId = checkPresent($model.externalId);
    }

    public resetInstitution(): void {
        this.selectedInstitution = undefined;
        this.model.institutionId = undefined;
    }

    private initInstitutionen(): void {
        this.formContext.startLoading();
        this.institutionenService.getInstitutionen()
            .then(institutionen => {
                this.institutionen = institutionen;
            })
            .finally(() => this.formContext.finishLoading());
    }
}

componentConfig.controller = DvbKibonMaintenance;
angular.module('kitAdmin').component('dvbKibonMaintenance', componentConfig);
