/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {OidcService} from '@dv/shared/authentication/oidc';
import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {LOGIN_STATE} from '../../authentication-states';
import type {AuthService} from '../../service/authService';
import type {UserCredentials} from '../../types/UserCredentials';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-login.html'),
    controllerAs: 'vm',
};

export class DvbLogin implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        '$uiRouterGlobals',
        'authService',
        'errorService',
        'oidcService',
    ];

    public isLoading: boolean = false;
    public isLoginState: boolean = false;

    public userCredentials: UserCredentials = {
        username: null,
        password: null,
    };

    public constructor(
        private $uiRouterGlobals: UIRouterGlobals,
        private authService: AuthService,
        private errorService: ErrorService,
        private oidcService: OidcService,
    ) {
    }

    public $onInit(): void {
        this.isLoginState = this.$uiRouterGlobals.current.name === LOGIN_STATE.name;
    }

    public showIdpLogin(): boolean {
        return this.authService.isidpLoginEnabled();
    }

    public loginIdp(): void {
        this.oidcService.startIdpLogin();
    }

    public loginAction(formIsValid: boolean): void {
        this.errorService.handleValidationError(formIsValid, 'ERRORS.ERR_INCOMPLETE_LOGIN');

        if (!formIsValid) {
            return;
        }

        if (!this.userCredentials.username) {
            throw new Error('Logged in but no username present. Invalid state!');
        }

        this.isLoading = true;

        this.authService.login(this.userCredentials)
            .catch(() => {
                this.errorService.addValidationError('ERRORS.ERR_LOGIN');
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbLogin;
angular.module('kitAdmin').component('dvbLogin', componentConfig);
