<div class="dvb-tageseltern-eroeffnen">
    <div class="form-section-minus-normal-abstand">

        <h2 data-translate="KINDERORT.SUBVENTIONIERTE_KAPAZITAET.TAGES_ELTERN.NAME"></h2>

        <div class="row form-row">
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               value="true"
                               ng-model="vm.showSubventionierteStunden"
                               ng-change="vm.updateSubventionierteStunden()">
                        <span data-translate="KINDERORT.SUBVENTIONIERTE_KAPAZITAET.TAGES_ELTERN.NAME"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="row" uib-collapse="!vm.showSubventionierteStunden">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       ng-model="vm.subventionierteStunden.anzahl"
                       min="1"
                       ng-attr-placeholder="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.TAGES_ELTERN.ANZAHL' | translate}}"
                       uib-tooltip="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.TAGES_ELTERN.ANZAHL' | translate}}"
                       tooltip-placement="top-left"
                       ng-required="vm.showSubventionierteStunden">
            </div>
        </div>
    </div>
</div>
