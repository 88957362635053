/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TempBlob} from '@dv/kitadmin/models';
import type {
    MatrixParams,
    MatrixParamValue,
    ReportTemplate,
    ReportType,
    RestKinderOrtId,
    RestLimited,
    RestReportTemplate,
    RestYear,
    SearchResultEntry,
} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, HttpCodes, isPresent} from '@dv/shared/code';
import type {StateService, UIRouterGlobals} from '@uirouter/core';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {PersonalplanungReportParams} from '../component/dvb-personalplanung-report/dvb-personalplanung-report';
import type {
    CustomFieldConfiguration,
    ParameterBelegungsplan,
    ParameterFruehSpaetDienstReport,
    ParameterGeburtstagslisteKinder,
    ParameterKindergartenkinder,
    ParameterKinderkontaktliste,
    ParameterKinderOrte,
    ParameterKinderOrteWithGueltigkeit,
    ParameterSteuerbescheinigungen,
    ReportGenerationStatus,
} from '../models';
import {ControllingReport} from '../models';

type RestKinderOrtParams = RestKinderOrtId & RestLimited & RestReportTemplate;

const transactionTimeoutMinutes = 22;
const secondsPerMin = 60;
const milliesPerSecond = 1000;
const maxDurationInMillies = transactionTimeoutMinutes * secondsPerMin * milliesPerSecond;

type ReportGeneration = { status: ReportGenerationStatus; tempBlob: any };

/* eslint-disable max-lines */
export class ReportService {
    public static $inject: readonly string[] = ['$state', '$uiRouterGlobals', '$interval', '$q', '$http', '$rootScope'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/reporting`;
    private static readonly BETRIEB_URL: string = `${ReportService.BASE_URL}/betrieb`;
    private static readonly CONTROLLING_URL: string = `${ReportService.BASE_URL}/controlling`;
    private static readonly FAKTURA_URL: string = `${ReportService.BASE_URL}/faktura`;
    private static readonly PERSONAL_URL: string = `${ReportService.BASE_URL}/personal`;

    private static readonly REPORT_TIMEOUT: number = 240000;
    private static readonly TIMEOUT_CONFIG: { timeout: number } = {timeout: ReportService.REPORT_TIMEOUT};
    private static readonly POLLING_INTERVAL: number = 10000;
    private static readonly POLLING_INTERVAL_COUNT: number = maxDurationInMillies / ReportService.POLLING_INTERVAL;

    /**
     * Caches an ETag per URL.
     */
    private etagCache: { [index: string]: string } = {};

    /**
     * Caches a resolved request body per ETag.
     */
    private etagResponses: { [index: string]: ControllingReport } = {};

    public constructor(
        private $state: StateService,
        private $uiRouterGlobals: UIRouterGlobals,
        private $interval: angular.IIntervalService,
        private $q: angular.IQService,
        private $http: angular.IHttpService,
        private $rootScope: angular.IRootScopeService,
    ) {
    }

    private static toBelegungsplanMatrixParams(params: ParameterBelegungsplan): {
        reportType: ReportType;
        gueltigAb: string | null;
        gueltigBis: string | null;
        reportTemplate: string | null;
        customFieldConfiguration: string | null;
        includeExtraPlaetze: boolean;
    } {
        return {
            gueltigAb: DvbRestUtil.momentToLocalDate(params.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(params.gueltigBis),
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
            customFieldConfiguration: params.customFieldConfiguration ? params.customFieldConfiguration.id : null,
            reportType: params.reportType,
            includeExtraPlaetze: params.includeExtraPlaetze,
        };
    }

    private static createKinderOrtOrFraktionenUrlSuffix(params: RestKinderOrtParams & { fraktionIds: string }): string {
        const kinderOrtId = encodeURIComponent(params.kinderOrtId);

        if (params.kinderOrtId) {
            return `kinderort/${kinderOrtId}`;
        }

        return 'fraktionen';
    }

    private static createKinderOrtOrFraktionUrlSuffix(params: RestLimited & RestReportTemplate & {
        kinderOrtId?: string;
        fraktionId?: string;
    }): string {
        const kinderOrtId = params.kinderOrtId ? encodeURIComponent(params.kinderOrtId) : undefined;
        const fraktionId = params.fraktionId ? encodeURIComponent(params.fraktionId) : undefined;

        return `${params.kinderOrtId ? 'kinderort' : 'fraktion'}/${params.kinderOrtId ? kinderOrtId : fraktionId}`;
    }

    public updateParameterKinderOrt(kinderOrtSearchResultEntry?: SearchResultEntry | null): void {
        const kinderOrte = kinderOrtSearchResultEntry ? [kinderOrtSearchResultEntry] : [];

        this.updateStateParams({kinderOrte});
    }

    /**
     * Used to override stateParameters for pre-selection in report forms.
     */
    public updateStateParams(
        params: { [key: string]: any },
        state: string = '.',
        reloadState: boolean | string = false,
    ): void {

        if (!params) {
            return;
        }

        Object.assign(this.$uiRouterGlobals.params, params);

        this.$state.go(state, this.$uiRouterGlobals.params, {reload: reloadState});
    }

    public getControlling(parameter: RestYear & RestKinderOrtId): angular.IPromise<ControllingReport> {

        const matrixParams = {
            jahr: parameter.year,
            includes: '(weeklyFigures, monthlyFigures)',
        };

        const url = `${ReportService.CONTROLLING_URL}/${encodeURIComponent(parameter.kinderOrtId)}${
            DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        const etagRequest = this.etagCache[url];
        const promise = this.$http.get(url, {
            headers: {'if-none-match': etagRequest},
            timeout: ReportService.REPORT_TIMEOUT,
        });

        // schon hier abrufen, damit der cache 'threadsafe' bleibt
        const cachedResponse = this.etagResponses[url];

        return promise.then(response => {
            const controllingReport = ControllingReport.apiResponseTransformer(response.data);
            const responseETag = response.headers('etag');
            if (responseETag) {
                this.etagCache[url] = responseETag;
                this.etagResponses[url] = controllingReport;
            }

            return controllingReport;
        }).catch(response => {
            if (response.status === HttpCodes.NOT_MODIFIED) {
                return cachedResponse;
            }

            return this.$q.reject(response);
        });
    }

    public getTempBlobForKennzahlenReport(
        params: RestLimited,
        reportTemplate: ReportTemplate | null,
    ): angular.IPromise<TempBlob> {

        const matrixParams: Record<string, MatrixParamValue> = DvbRestUtil.toMatrixParams(params);
        matrixParams.reportTemplate = isPresent(reportTemplate) ? reportTemplate.id : null;

        const url = `${ReportService.CONTROLLING_URL}/excel/mandant/kennzahlen`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForExternalAnmeldungenReport(
        params: RestLimited,
        reportTemplate: ReportTemplate | null,
    ): angular.IPromise<TempBlob> {

        const matrixParams: Record<string, MatrixParamValue> = DvbRestUtil.toMatrixParams(params);
        matrixParams.reportTemplate = isPresent(reportTemplate) ? reportTemplate.id : null;

        const url = `${ReportService.CONTROLLING_URL}/excel/mandant/external-anmeldungen`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForControllingReport(
        params: RestReportTemplate & RestKinderOrtId & RestYear,
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
        };

        const url = `${ReportService.CONTROLLING_URL}/excel/kita/${encodeURIComponent(params.kinderOrtId)}` +
            `/controlling/jahresauswertung/${encodeURIComponent(params.year)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForStundenbasiertesControlling(params: RestReportTemplate & RestKinderOrtId & RestYear)
        : angular.IPromise<TempBlob> {
        const matrixParams = {
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
        };

        const url = `${ReportService.CONTROLLING_URL}/excel/tageseltern/${encodeURIComponent(params.kinderOrtId)}` +
            `/stundenbasiertes_controlling/jahresauswertung/${encodeURIComponent(params.year)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBetreuungsStundenKontrolle(params: RestKinderOrtParams): angular.IPromise<TempBlob> {

        const matrixParams = {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const url = `${ReportService.CONTROLLING_URL}/betreuungsstundenkontrolle/${params.kinderOrtId}${
            DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.get<string>(url, ReportService.TIMEOUT_CONFIG)
            .then(response => this.startReportPolling(response.data));
    }

    public getTempBlobForControllingMonthlyReport(
        parameterKinderOrte: ParameterKinderOrte,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        const url = `${ReportService.CONTROLLING_URL}/excel/controlling/monthly${
            DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post<string>(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => this.startReportPolling(response.data));
    }

    public getTempBlobForAktuelleAenderungenReport(
        params: RestReportTemplate & RestKinderOrtId & RestYear,
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/kita/${encodeURIComponent(params.kinderOrtId)}` +
            `/aenderungen/jahresauswertung/${encodeURIComponent(params.year)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanPlatzartReport(
        params: RestKinderOrtParams
            & { includeExtraPlaetze: boolean; customFieldConfiguration: CustomFieldConfiguration | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams: MatrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        matrixParams.includeExtraPlaetze = params.includeExtraPlaetze;
        matrixParams.customFieldConfiguration = params.customFieldConfiguration?.id;

        const url = `${ReportService.CONTROLLING_URL}/excel/kita/${encodeURIComponent(params.kinderOrtId)}/belegungsplan`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanKitaReport(
        kitaId: string,
        params: ParameterBelegungsplan,
    ): angular.IPromise<TempBlob> {

        const matrixParams = ReportService.toBelegungsplanMatrixParams(params);

        const url = `${ReportService.BETRIEB_URL}/excel/kita/${encodeURIComponent(kitaId)}/belegungsplan`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanMonthlyKitaReport(kitaId: string, params: ParameterBelegungsplan):
        angular.IPromise<TempBlob> {

        const matrixParams = ReportService.toBelegungsplanMatrixParams(params);

        const url = `${ReportService.CONTROLLING_URL}/excel/kita/${encodeURIComponent(kitaId)}/belegungsplan-monthly`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanGruppeReport(
        gruppeId: string,
        params: ParameterBelegungsplan,
    ): angular.IPromise<TempBlob> {

        const matrixParams = ReportService.toBelegungsplanMatrixParams(params);

        const url = `${ReportService.BETRIEB_URL}/excel/gruppe/${encodeURIComponent(gruppeId)}/belegungsplan`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanGruppeMonthlyReport(
        gruppeId: string,
        params: ParameterBelegungsplan,
    ): angular.IPromise<TempBlob> {

        const matrixParams = ReportService.toBelegungsplanMatrixParams(params);

        const url = `${ReportService.CONTROLLING_URL}/excel/gruppe/${encodeURIComponent(gruppeId)}/belegungsplan-monthly`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanDayBased(
        kitaId: string,
        firstOfWeek: moment.Moment,
        reportTemplateId: string | null | undefined,
        customFieldConfigurationId: string | null,
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            firstOfWeek: DvbRestUtil.momentToLocalDate(firstOfWeek),
            reportTemplate: reportTemplateId,
            customFieldConfiguration: customFieldConfigurationId,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/kita/${encodeURIComponent(kitaId)}/belegungsplan/daybased`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegungsplanDayBasedByFraktion(
        fraktionId: string,
        firstOfWeek: moment.Moment,
        reportTemplateId: string | null | undefined,
        customFieldConfigurationId: string | null,
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            firstOfWeek: DvbRestUtil.momentToLocalDate(firstOfWeek),
            reportTemplate: reportTemplateId,
            customFieldConfiguration: customFieldConfigurationId,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/fraktion/${encodeURIComponent(fraktionId)}/belegungsplan/daybased`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForKontaktpersonenZahlungen(
        parameterKinderOrte: ParameterKinderOrte,
        parameter: { reportTemplate: ReportTemplate | null; includePayedRueckerstattungen: boolean },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
            includePayedRueckerstattungen: parameter.includePayedRueckerstattungen,
        };

        const url = `${ReportService.FAKTURA_URL}/excel/kontaktpersonenzahlungen` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForWarteliste(
        parameterKinderOrte: ParameterKinderOrte,
        parameter: { reportTemplate: ReportTemplate | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.CONTROLLING_URL}/excel/warteliste` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForAdresslisteKinderKontakteReport(
        parameterKinderkontaktliste: ParameterKinderkontaktliste,
        parameter: { reportTemplate: ReportTemplate | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/adressliste/kinderkontakte` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url,
            parameterKinderkontaktliste.toRestObject(),
            ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForTelefonlisteKinderKontakteReport(
        parameterKinderkontaktliste: ParameterKinderkontaktliste,
        parameter: { reportTemplate: ReportTemplate | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/telefonliste/kinderkontakte` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url,
            parameterKinderkontaktliste.toRestObject(),
            ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForGeburtstagslisteKinderReport(
        parameterGeburtstagslisteKinder: ParameterGeburtstagslisteKinder,
        parameter: { reportTemplate: ReportTemplate | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/geburtstagsliste/kinder` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url,
            parameterGeburtstagslisteKinder.toRestObject(),
            ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForKindergartenkinder(
        parameterKindergartenkinder: ParameterKindergartenkinder,
        parameter: { reportTemplate: ReportTemplate | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/kindergartenkinder` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url, parameterKindergartenkinder.toRestObject())
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForFruehSpaetDienst(
        parameterFruehSpaetDienst: ParameterFruehSpaetDienstReport,
        parameter: { reportTemplate: ReportTemplate | null },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.BETRIEB_URL}/excel/fruehspaetdienst` +
            `${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url, parameterFruehSpaetDienst.toRestObject())
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForLeistungenFakturiertReport(
        parameterKinderOrte: ParameterKinderOrte,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        const url = `${ReportService.FAKTURA_URL}/excel/leistungen/fakturiert${DvbRestUtil.encodeMatrixParams(
            matrixParams)}`;

        return this.$http.post<string>(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => this.startReportPolling(response.data));
    }

    public getTempBlobForLeistungenKalkuliertReport(
        params: RestReportTemplate & RestKinderOrtId & RestYear,
    ): angular.IPromise<TempBlob> {

        const url = `${ReportService.CONTROLLING_URL}/excel/kita/${encodeURIComponent(params.kinderOrtId)}` +
            `/leistungen/kalkuliert/${encodeURIComponent(params.year)}`;

        const matrixParams = {
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
        };

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForStundenblattReport(params: RestKinderOrtParams): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const kinderOrtId = encodeURIComponent(params.kinderOrtId);
        const url = `${ReportService.CONTROLLING_URL}/excel/kita/${kinderOrtId}/stundenblatt`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBelegteProzentPunkteReport(params: RestKinderOrtParams): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${ReportService.CONTROLLING_URL}/excel/belegteprozentpunkte/${encodeURIComponent(params.kinderOrtId)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForTarifrechnungReportingKalkuliertReport(
        params: RestKinderOrtParams,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const kinderOrtId = encodeURIComponent(params.kinderOrtId);
        const url = `${ReportService.CONTROLLING_URL}/excel/tarifrechnungreporting/kalkuliert/${kinderOrtId}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBetreuungsgutscheineReport(
        parameterKinderOrteWithGueltigkeit: ParameterKinderOrteWithGueltigkeit,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, parameterKinderOrteWithGueltigkeit);

        const url = `${ReportService.CONTROLLING_URL}/excel/` +
            `betreuungsgutscheine${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url,
            parameterKinderOrteWithGueltigkeit.toRestObject(),
            ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForMonatsblattAuszugReport(
        params: RestKinderOrtId & { stichtag: moment.Moment; layoutId: string },
    ): angular.IPromise<TempBlob> {

        const matrixParams = {
            stichtag: DvbRestUtil.momentToLocalDate(params.stichtag),
            layoutId: params.layoutId,
        };

        const kinderOrtId = encodeURIComponent(params.kinderOrtId);
        const url = `${ReportService.CONTROLLING_URL}/zip/kinderort/${kinderOrtId}/monatsblaetter${
            DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.get<string>(url, ReportService.TIMEOUT_CONFIG)
            .then(response => this.startReportPolling(response.data));
    }

    public getTempBlobForZahlungseingaengeReport(
        parameterKinderOrteWithGueltigkeit: ParameterKinderOrteWithGueltigkeit,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, parameterKinderOrteWithGueltigkeit);

        const url = `${ReportService.FAKTURA_URL}/excel/` +
            `zahlungseingaenge${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url,
            parameterKinderOrteWithGueltigkeit.toRestObject(),
            ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForRechnungsGebuehrenReport(
        parameterKinderOrteWithGueltigkeit: ParameterKinderOrteWithGueltigkeit,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, parameterKinderOrteWithGueltigkeit);

        const url = `${ReportService.FAKTURA_URL}/excel/` +
            `rechnungsgebuehren${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url,
            parameterKinderOrteWithGueltigkeit.toRestObject(),
            ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForFinanzlastenausgleich(
        parameterKinderOrte: ParameterKinderOrte,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        const url = `${ReportService.CONTROLLING_URL}/excel/finanzlastenausgleich${DvbRestUtil.encodeMatrixParams(
            matrixParams)}`;

        return this.$http.post(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForKibonReport(
        parameterKinderOrte: ParameterKinderOrte,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        const url = `${ReportService.CONTROLLING_URL}/excel/kibonReport${DvbRestUtil.encodeMatrixParams(
            matrixParams)}`;

        return this.$http.post(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForOffenePostenKitaReport(
        parameterKinderOrte: ParameterKinderOrte,
        parameter: RestReportTemplate & {
            periodeGueltigBis: moment.Moment;
            zahlungenGueltigBis: moment.Moment;
            abgewickeltePostenAnzeigen: boolean;
            ignoreZahlungenForRechAfterPeriodeBis?: boolean;
        },
    ): angular.IPromise<TempBlob> {
        const matrixParams = {
            periodeGueltigBis: DvbRestUtil.momentToLocalDate(parameter.periodeGueltigBis),
            zahlungenGueltigBis: DvbRestUtil.momentToLocalDate(parameter.zahlungenGueltigBis),
            abgewickeltePostenAnzeigen: parameter.abgewickeltePostenAnzeigen,
            ignoreZahlungenForRechAfterPeriodeBis: parameter.ignoreZahlungenForRechAfterPeriodeBis,
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.FAKTURA_URL}/excel/` +
            `kita/offeneposten${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post<string>(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => this.startReportPolling(response.data));
    }

    public getTempBlobForSteuerbescheinigungen(parameter: ParameterSteuerbescheinigungen): angular.IPromise<TempBlob> {

        const url = `${ReportService.FAKTURA_URL}/steuerbescheinigungen`;

        return this.$http.post(url, parameter.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public getTempBlobForDepotsReport(
        parameterKinderOrte: ParameterKinderOrte,
        parameter: RestReportTemplate & {
            stichtag: moment.Moment;
            includeErstattet: boolean;
            erstattetVon: moment.Moment | null;
            erstattetBis: moment.Moment | null;
        },
    ): angular.IPromise<TempBlob> {
        const matrixParams = {
            stichtag: DvbRestUtil.momentToLocalDate(parameter.stichtag),
            includeErstattet: parameter.includeErstattet,
            erstattetVon: DvbRestUtil.momentToLocalDate(parameter.erstattetVon),
            erstattetBis: DvbRestUtil.momentToLocalDate(parameter.erstattetBis),
            reportTemplate: parameter.reportTemplate ? parameter.reportTemplate.id : null,
        };

        const url = `${ReportService.FAKTURA_URL}/excel/depots${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post(url, parameterKinderOrte.toRestObject(), ReportService.TIMEOUT_CONFIG)
            .then(response => TempBlob.apiResponseTransformer(response.data));
    }

    public hasCoronaKinderOrtFeeApproved(kinderOrtId: string): angular.IPromise<boolean> {
        const url = `${ReportService.CONTROLLING_URL}/feeapproval/coronarefund/${encodeURIComponent(kinderOrtId)}`;

        return this.$http.get<{ approved: boolean | null }>(url)
            .then(response => response.data.approved!);
    }

    public getTempBlobForCoronaRefundReport(
        kinderOrtId: string,
        parameterCoronaRefundReport: any,
        params: RestReportTemplate,
    ): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};

        const url = `${ReportService.CONTROLLING_URL}/excel/` +
            `coronarefund/${encodeURIComponent(kinderOrtId)}${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.post<string>(
            url,
            parameterCoronaRefundReport,
            ReportService.TIMEOUT_CONFIG)
            .then(response => this.startReportPolling(response.data));
    }

    public getTempBlobForBsvPraesenzkontrolleReport(
        params: RestKinderOrtParams & { fraktionIds: string; hoursFullDayBetreuung: number },
    ): angular.IPromise<TempBlob> {

        const matrixParams: any = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        matrixParams.hoursFullDayBetreuung = params.hoursFullDayBetreuung;
        matrixParams.fraktionIds = params.fraktionIds;

        const url = `${ReportService.CONTROLLING_URL}/excel/praesenzkontrolle/${
            ReportService.createKinderOrtOrFraktionenUrlSuffix(params)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForBsvPraesenzkontrolleKgReport(
        params: RestKinderOrtParams & { fraktionIds: string },
    ): angular.IPromise<TempBlob> {

        const matrixParams: any = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        matrixParams.fraktionIds = params.fraktionIds;

        const url = `${ReportService.CONTROLLING_URL}/excel/praesenzkontrollekg/${
            ReportService.createKinderOrtOrFraktionenUrlSuffix(params)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForAnwesenheitssoll(params: RestKinderOrtParams): angular.IPromise<TempBlob> {

        const matrixParams = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${ReportService.CONTROLLING_URL}/excel/kita/${encodeURIComponent(params.kinderOrtId)}/anwesenheitssoll`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForAbweichungenReport(
        params: RestKinderOrtParams & { fraktionId: string },
    ): angular.IPromise<TempBlob> {

        const matrixParams: any = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${ReportService.BETRIEB_URL}/excel/abweichungen/${
            ReportService.createKinderOrtOrFraktionUrlSuffix(params)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForPersonalReport(params: PersonalplanungReportParams): angular.IPromise<TempBlob> {
        const matrixParams = {
            mitTermine: params.mitTermine,
            mitKinderOrtSchluessel: params.mitKinderOrtSchluessel,
            mitTagesinfos: params.mitTagesinfos,
            mitPausen: params.mitPausen,
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
        };

        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${ReportService.PERSONAL_URL}/excel/personalplanung/${
            ReportService.createKinderOrtOrFraktionUrlSuffix(params)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForPersonalReportMonthly(params: PersonalplanungReportParams): angular.IPromise<TempBlob> {
        const matrixParams = {
            gueltigAb: DvbRestUtil.momentToLocalDate(params.gueltigAb),
            mitTermine: params.mitTermine,
            mitKinderOrtSchluessel: params.mitKinderOrtSchluessel,
            mitTagesinfos: params.mitTagesinfos,
            reportTemplate: params.reportTemplate ? params.reportTemplate.id : null,
        };

        const url = `${ReportService.PERSONAL_URL}/excel/personalplanung/${
            ReportService.createKinderOrtOrFraktionUrlSuffix(params)}/monthly`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    public getTempBlobForStundenvergleichReport(
        kindId: string,
        params: RestReportTemplate & RestLimited,
    ): angular.IPromise<TempBlob> {
        const matrixParams: any = params.reportTemplate ? {reportTemplate: params.reportTemplate.id} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${ReportService.CONTROLLING_URL}/excel/` +
            `stundenvergleich/${encodeURIComponent(kindId)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams, ReportService.TIMEOUT_CONFIG)
            .then(checkPresent);
    }

    private startReportPolling(blobId: string): angular.IPromise<TempBlob> {

        const url = `${ReportService.BASE_URL}/blobs/${encodeURIComponent(blobId)}/status`;
        const deferred = this.$q.defer<TempBlob>();

        const interval = this.$interval((intervalCount: number) => this.$http.get<ReportGeneration>(url)
            .then(response => this.handleReportGenerationResponse(response, interval, deferred, intervalCount))
            .catch(err => {
                this.$interval.cancel(interval);
                deferred.reject(err);
            }), ReportService.POLLING_INTERVAL, ReportService.POLLING_INTERVAL_COUNT);

        // kill the timer
        this.$rootScope.$on('$destroy', () => {
            this.$interval.cancel(interval);
        });

        return deferred.promise;
    }

    private handleReportGenerationResponse(
        response: angular.IHttpResponse<ReportGeneration>,
        interval: angular.IPromise<any>,
        deferred: angular.IDeferred<TempBlob>,
        intervalCount: number,
    ): void {
        switch (response.data.status) {
            case 'FAILED':
                this.$interval.cancel(interval);
                deferred.reject(response);
                break;
            case 'FINISHED':
                this.$interval.cancel(interval);
                deferred.resolve(TempBlob.apiResponseTransformer(response.data.tempBlob));
                break;
            case 'RUNNING':
                if (intervalCount === ReportService.POLLING_INTERVAL_COUNT) {
                    deferred.reject();
                }
                // nop
                break;
            default:
                throw new Error(`unknown response state ${JSON.stringify(response.data.status)}`);
        }
    }
}

// eslint-disable-next-line max-lines
