/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbUtil, EntitySelection, LoadingContext, TypeUtil} from '@dv/shared/code';
import type {IChangesObject} from 'angular';
import angular from 'angular';
import type {KontaktpersonEmail} from '../../models/steuerbescheinigungen/KontaktpersonEmail';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktpersonLastSentList: '<',
        onResend: '&',
    },
    template: require('./dvb-communication-sent.html'),
    controllerAs: 'vm',
};

export class DvbCommunicationSent extends EntitySelection<Kontaktperson> implements angular.IController {

    public kontaktpersonLastSentList!: KontaktpersonEmail[];
    public onResend?: (val: { context: LoadingContext; selected: Kontaktperson[] }) => any;

    public readonly loadingContext: LoadingContext = new LoadingContext();
    public kontaktpersonen: Persisted<Kontaktperson>[] = [];

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const list: IChangesObject<KontaktpersonEmail[]> = onChangesObj.kontaktpersonLastSentList;

        if (list) {
            this.kontaktpersonen = list.currentValue.map(element => element.kontaktperson);
        }
    }

    public resend(): void {
        const selected = this.getSelected(this.kontaktpersonen);
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        if (TypeUtil.isFunction(this.onResend)) {
            this.onResend({context: this.loadingContext, selected: this.getSelected(this.kontaktpersonen)});
        }
    }

    public comparator(element: KontaktpersonEmail): string {
        return element.kontaktperson.getDisplayName();
    }
}

componentConfig.controller = DvbCommunicationSent;
angular.module('kitAdmin').component('dvbCommunicationSent', componentConfig);
