<div class="dvb-maintenance-delete-rechnungs-positionen">
    <h2 data-translate="ADMINISTRATION.MTNC_DELETE_RECHUNGS_POSITIONEN"></h2>

    <form class="dvb-form"
          name="deleteRechnungsPositionenForm"
          ng-submit="vm.deleteMandantRechnungsPositionen(deleteRechnungsPositionenForm)"
          novalidate>
        <div class="row">
            <div class="col-xs-12">
                <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                              ng-model="vm.kinderOrt"
                                              required
                                              as-col="true"
                                              mandant-filter="vm.mandantFilter"
                                              display-label="COMMON.KINDERORT.SINGULAR"
                                              placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                </dvb-search-get-single-entity>
            </div>
        </div>
        <div class="row">
            <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                       dvb-begin-of-month
                                       ng-attr-placeholder="{{'COMMON.VON' | translate}}"
                                       required
                                       class="col-md-6">
            </dvb-datepicker-text-field>
            <dvb-datepicker-text-field ng-model="vm.gueltigBis"
                                       dvb-end-of-month-parser
                                       ng-attr-placeholder="{{'COMMON.BIS' | translate}}"
                                       required
                                       class="col-md-6">
            </dvb-datepicker-text-field>
        </div>

        <dvb-loading-button type="submit" is-loading="vm.isLoading">
            <span data-translate="COMMON.LOESCHEN"></span>
        </dvb-loading-button>
    </form>
</div>