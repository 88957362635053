/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ReportTemplate} from '@dv/shared/code';
import angular from 'angular';
import type {ReportState} from '../../models';
import type {ReportTemplateService} from '../../service/reportTemplateService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        onSelectionChange: '&',
    },
    template: require('./dvb-report-template-selector.html'),
    controllerAs: 'vm',
};

export class DvbReportTemplateSelector implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['reportTemplateService'];

    public readonly reportState!: ReportState;

    public reportTemplates: ReportTemplate[] = [];
    public selectedReportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportTemplateService: ReportTemplateService,
    ) {
    }

    public $onInit(): void {
        this.reportTemplateService.getAllByType(this.reportState.asReportType()).then(result => {
            this.reportTemplates = result;
        });
    }
}

componentConfig.controller = DvbReportTemplateSelector;
angular.module('kitAdmin').component('dvbReportTemplateSelector', componentConfig);
