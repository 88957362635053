/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import {Tarif, TarifParameter, TarifType, TarifValue} from '@dv/kitadmin/models';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import {BEGIN_OF_TIME} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {TarifService} from '../../../common/service/rest/tarifService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        existingTarifParameters: '<',
    },
    template: require('./dvb-tarif-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbTarifErstellen implements angular.IController {
    public static $inject: readonly string[] = ['dvbStateService', 'tarifService', 'errorService', '$state'];

    // noinspection JSUnusedGlobalSymbols
    public readonly existingTarifParameters!: TarifParameter[];
    public isLoading: boolean = false;

    public loadingTemplate: boolean = false;
    public tarif: Tarif = new Tarif();
    public tarifValue: TarifValue = new TarifValue(null, BEGIN_OF_TIME);
    public tariftypes: readonly TarifType[] = Object.values(TarifType);

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private dvbStateService: DvbStateService,
        private tarifService: TarifService,
        private errorService: ErrorService,
        private $state: StateService,
    ) {
    }

    public addParameter(): void {
        const parameter = new TarifParameter();
        if (this.tarif.dtype === TarifType.BETREUUNGS_STUNDEN) {
            parameter.dtype = TarifParameterType.MONATS_BASIERT;
        }

        this.tarif.addParameter(parameter);
    }

    public valueChanged(): void {
        this.tarifValue.template = null;
    }

    public removeParameter(parameter: TarifParameter): void {
        this.tarif.removeParameter(parameter);
    }

    public downloadTarifVorlage(): void {
        this.loadingTemplate = true;

        if (!this.isValidTarifParamAssignment()) {
            this.loadingTemplate = false;

            return;
        }

        const title = this.tarif.name ?? '???';
        const paramNames = this.tarif.parameters
            .filter(param => !!param.name)
            .map(param => param.name!);

        this.tarifService.getTempBlobForTarif(this.tarif.dtype, title, paramNames)
            .then((tempBlob: TempBlob) => {
                this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
                this.loadingTemplate = false;
            })
            .finally(() => {
                this.loadingTemplate = false;
            });
    }

    public validateAndSaveTarif(): void {
        if (this.tarif.nullTarif) {
            this.tarif.parameters = [];
            this.tarifValue.template = null;
        }
        const hasParameterWithoutName = this.tarifService.hasParameterWithoutName(this.tarif.parameters);
        this.errorService.handleValidationError(!hasParameterWithoutName,
            'ERRORS.ERR_TARIFPARAMETER_WITHOUT_NAMES');

        const hasUniqueParameterNames = this.tarifService.hasUniqueParameterNames(this.tarif.parameters);
        this.errorService.handleValidationError(hasUniqueParameterNames,
            'TARIF.ERRORS.ERR_DUPLICATED_TARIFPARAMETER_NAMES');

        const isTarifValid = this.tarif.isValid() && (this.tarif.nullTarif || this.tarifValue.isValid());
        this.errorService.handleValidationError(isTarifValid,
            'TARIF.ERRORS.ERR_INCOMPLETE_TARIF');

        const isValidTarifAssignment = this.isValidTarifParamAssignment();

        const isValid = isTarifValid && hasUniqueParameterNames && !hasParameterWithoutName && isValidTarifAssignment;

        if (!isValid) {
            return;
        }

        this.isLoading = true;
        this.tarif.values = [this.tarifValue];
        this.tarifService.createTarif(this.tarif)
            .then(response => this.$state.go('base.tarif', {
                id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response),
            }))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public goBack(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    private isValidTarifParamAssignment(): boolean {
        if (this.tarif.dtype !== TarifType.BETREUUNGS_UNABHAENGIG) {
            return true;
        }

        const hasMonatsBasierteParam = this.tarifService.hasMonatsBasierteParams(this.tarif.parameters);
        if (hasMonatsBasierteParam) {
            this.errorService.handleValidationError(false, 'ERRORS.ERR_UNABHAENGIG_TARIF_MIT_MONATS_BASIERTE_PARAM');
        }

        return !hasMonatsBasierteParam;
    }
}

componentConfig.controller = DvbTarifErstellen;
angular.module('kitAdmin').component('dvbTarifErstellen', componentConfig);
