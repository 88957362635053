/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    ExtraPlatzCategory,
    Kind,
    KinderOrtFraktion,
    KinderOrtFraktionId,
    Kontingente,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import {ExtraPlatz, ExtraPlatzType, ZeitraumUtil} from '@dv/kitadmin/models';
import {checkPresent, DvbDateUtil, Gueltigkeit} from '@dv/shared/code';
import moment from 'moment';
import {TempExtraPlaetzeByDate} from '../../temp-extra-platz/TempExtraPlaetzeByDate';
import {TempExtraPlatz} from '../../temp-extra-platz/TempExtraPlatz';
import type {TempExtraZeitraumFeld} from '../../temp-extra-platz/TempExtraZeitraumFeld';
import {WochenplanUtil} from './wochenplanUtil';

/**
 * Helper for managing temporary extra plaetze.
 */
export class TempExtraPlatzBuilder {

    /**
     * Gueltigkeit for filtering
     */
    public gueltigkeit: Gueltigkeit = new Gueltigkeit();

    /**
     * Temp plaetze filtered by the gueltigkeit.
     */
    public filteredTempPlaetze: TempExtraPlatz[] = [];

    private tempPlaetze: { [fraktionId: string]: TempExtraPlaetzeByDate } = {};

    /**
     * Utility for adding a field to the temporarily stored ones.
     * ZeitraumFelder equal to the added one for any KinderOrtFraktion will be removed.
     *
     * @return a Set of fraktions IDs in which an equal feld was removed.
     */
    public addTempExtraPlatz(
        template: TempExtraPlatz,
        zeitraumFeld: ZeitraumFeld,
        kontingent: Kontingente | null,
    ): Set<KinderOrtFraktionId> {
        const fraktionenWithRemovedFields = new Set<KinderOrtFraktionId>();
        if (template.extraPlatzType === ExtraPlatzType.ADDITIONAL) {
            // remove the feld if it is already selected somewhere on the same day
            this.removeAdditionsZeitraumFeld(zeitraumFeld, template.affectedDay, fraktionenWithRemovedFields);
        }

        if (!this.tempPlaetze[template.fraktionId]) {
            this.tempPlaetze[template.fraktionId] = new TempExtraPlaetzeByDate(template.fraktionId);
        }
        ZeitraumUtil.setExtraPlatzSelection(zeitraumFeld, template.extraPlatzType);

        this.tempPlaetze[template.fraktionId].addZeitraumFeld(template, zeitraumFeld, null, kontingent);
        this.initFilteredTempPlaetze();

        return fraktionenWithRemovedFields;
    }

    /**
     * Removes a ZeitraumFeld from the temporary extra days.
     *
     * @return Removing an absence removes additions on the same field in other groups.
     * a list of fraktions IDs in which fields got removed is returned.
     */
    public removeField(
        zeitraumFeld: ZeitraumFeld,
        fraktionId: KinderOrtFraktionId,
        firstOfWeek: moment.Moment,
        type: ExtraPlatzType,
    ): Set<KinderOrtFraktionId> {

        const fraktionenWithRemovedFields = this.removeAdditionsForFelderWhenAbsenceType(type,
            [zeitraumFeld],
            firstOfWeek);

        this.tempPlaetze[fraktionId].removeZeitraumFeld(zeitraumFeld, firstOfWeek, type);
        this.removeTempExtraPlatzEntry(fraktionId);
        this.initFilteredTempPlaetze();
        ZeitraumUtil.removeExtraPlatzSelection(zeitraumFeld, type);

        return fraktionenWithRemovedFields;
    }

    public remove(platz: TempExtraPlatz): Set<KinderOrtFraktionId> {
        const removed = this.tempPlaetze[platz.fraktionId].removeByKey(
            platz.affectedDay.format(TempExtraPlaetzeByDate.DAY_KEY_FORMAT),
            platz.extraPlatzType);

        const firstOfWeek = DvbDateUtil.startOfWeek(moment(platz.affectedDay));
        const fraktionenWithRemovedFields = this.removeAdditionsForFelderWhenAbsenceType(platz.extraPlatzType,
            removed,
            firstOfWeek);

        this.removeTempExtraPlatzEntry(platz.fraktionId);
        this.initFilteredTempPlaetze();

        return fraktionenWithRemovedFields;
    }

    public flagPlatzUpdated(platz: TempExtraPlatz): void {
        this.tempPlaetze[platz.fraktionId].setExtraPlatzUpdatedByKey(
            platz.affectedDay.format(TempExtraPlaetzeByDate.DAY_KEY_FORMAT),
            platz.extraPlatzType);
    }

    public contains(fraktionId: KinderOrtFraktionId, zeitraumFeld: ZeitraumFeld, firstOfWeek: moment.Moment): boolean {
        return this.tempPlaetze[fraktionId]?.contains(zeitraumFeld, firstOfWeek);
    }

    /**
     * True if the given zeitraumfeld for the given fraktion and first of week exists and is flagged as removed.
     */
    public isRemoved(fraktionId: KinderOrtFraktionId, zeitraumFeld: ZeitraumFeld, firstOfWeek: moment.Moment): boolean {
        const tempPlatz = this.getTempPlatz(fraktionId, zeitraumFeld, firstOfWeek);
        if (!tempPlatz) {
            return false;
        }
        const found = tempPlatz.getFelder().find(f => f.equals(zeitraumFeld));

        return !!found && found.removed;
    }

    public getTempPlatz(
        fraktionId: KinderOrtFraktionId,
        zeitraumFeld: ZeitraumFeld,
        firstOfWeek: moment.Moment,
    ): TempExtraPlatz | undefined {
        return this.tempPlaetze[fraktionId]?.getTempPlatz(zeitraumFeld, firstOfWeek);
    }

    /**
     * For the given fraktion and week, checks if there are any temporary or pre existing extra days.
     * If there are, sets them on the fraktions wochenplan.
     *
     * Further, stores all the childs existing extra days to be able to deal with the manipulation of those.
     */
    public initExistingExtraPlaetze(
        extraPlaetze: ExtraPlatz[],
        fraktion: KinderOrtFraktion,
        kontingente: Kontingente[],
        allowEdit: boolean,
        showKontingente: boolean,
        firstOfWeek: moment.Moment,
    ): void {

        const fraktionsPlaetze = extraPlaetze.filter(platz => platz.kinderOrtFraktionId === fraktion.id);
        const weekPlaetze = fraktionsPlaetze.filter(platz => platz.affectedDay!.isSame(firstOfWeek, 'week'));

        const tempExtraPlaetze = this.storePreExistingPlaetze(
            fraktion,
            fraktionsPlaetze,
            kontingente,
            allowEdit,
            showKontingente);

        // apply already existing extra days of currently visible week to wochenplan
        WochenplanUtil.setExtraPlaetzeToWochenplan(fraktion.wochenplan!, weekPlaetze, kontingente, showKontingente);

        // set kontingent and selection type for temporary days and select them
        fraktion.wochenplan!.zeitraumFelder.forEach(feld => {
            const tempFeld = tempExtraPlaetze.getFeld(feld, firstOfWeek);
            if (!tempFeld) {
                return;
            }

            const tempPlatz = checkPresent(tempExtraPlaetze.getTempPlatz(feld, firstOfWeek));
            if (tempFeld.removed) {
                ZeitraumUtil.removeExtraPlatzSelection(feld, tempPlatz.extraPlatzType);

                return;
            }

            ZeitraumUtil.setExtraPlatzSelection(feld, tempPlatz.extraPlatzType);
            if (ExtraPlatzType.ADDITIONAL === tempPlatz.extraPlatzType && showKontingente) {
                WochenplanUtil.setKontingent(feld, tempFeld.kontingent);
            }
        });

        this.initFilteredTempPlaetze();
    }

    /**
     * Creates {@link ExtraPlatz}e for the given temp extra days.
     */
    public create(
        fraktionen: KinderOrtFraktion[],
        kind: Kind,
    ): { newPlaetze: ExtraPlatz[]; deletedPlaetze: string[] } {

        const newPlaetze: ExtraPlatz[] = [];
        const deletedPlaetze = new Set<string>();

        // loop through the fraktionen
        Object.entries(this.tempPlaetze).forEach(([fraktionId, extraPlaetze]) => {
            const tempPlaetze = extraPlaetze.getPlaetze();
            const fraktion = checkPresent(fraktionen.find(f => f.id === fraktionId));

            // loop through the days
            Object.entries(tempPlaetze).forEach(([dayKey, tempPlatzTypes]) => {
                // loop through the ExtraPlatzTypes
                Object.values(tempPlatzTypes).forEach(tempPlatz => {
                    this.createForSingleTempPlatz(tempPlatz, kind, fraktion, dayKey, deletedPlaetze, newPlaetze);
                });
            });
        });

        return {newPlaetze, deletedPlaetze: Array.from(deletedPlaetze)};
    }

    /**
     * Initializes the filtered plaetze.
     *
     * Only has to be called, when either the gueltigkeit or the tempPlaetze are changed without the helpers knowledge.
     * Any changes made to those by the helper trigger this function themselves.
     */
    public initFilteredTempPlaetze(): void {
        this.filteredTempPlaetze = Object.values(this.tempPlaetze)
            .flatMap(extraPlaetzeByDate => Object.values(extraPlaetzeByDate.getPlaetze()))
            .flatMap(extraPlaetzeType => Object.values(extraPlaetzeType))
            .filter(platz => {
                // always display new or modified existing plaetze
                if (platz.isModified()) {
                    return true;
                }

                return platz.affectedDay.isBetween(
                    this.gueltigkeit.gueltigAb,
                    this.gueltigkeit.gueltigBis,
                    undefined,
                    '[]');
            });
    }

    /**
     * All fraktion IDs for which there are temp plätze
     */
    public getTempPlatzFraktionen(): string[] {
        return Object.keys(this.tempPlaetze);
    }

    /**
     * Determines whether it is allowed to add the given zeitraumFeld as an extraPlatz of type ADDITIONAL.
     * Only if all the fraktionen with belegungen have a temp platz for the field, adding is allowed.
     * An existing absence means that we can create an addition instead of the original belegung.
     * An existing additional platz will automatically be removed when adding the feld in another fraktion.
     *
     * Existing additional plaetze in another fraktion for the same feld can be an exception, when they may
     * not be edited. In that case, adding the same feld is not allowed, because the existing addition may not be
     * removed.
     *
     * @param zeitraumFeld
     * @param fraktionenWithBelegungen the IDs of fraktionen which have a wochenbelegung for the given feld.
     * @param firstOfWeek
     */
    public isAdditionAllowed(
        zeitraumFeld: ZeitraumFeld,
        fraktionenWithBelegungen: KinderOrtFraktionId[],
        firstOfWeek: moment.Moment,
    ): boolean {
        return fraktionenWithBelegungen.length === 0
            || fraktionenWithBelegungen.filter(id => {
                const tempPlatz = this.getTempPlatz(id, zeitraumFeld, firstOfWeek);
                if (ExtraPlatzType.ADDITIONAL === tempPlatz?.extraPlatzType && tempPlatz?.disabled) {
                    // disabled additions for the same feld may not be removed --> must not add a new addition
                    return false;
                }

                return tempPlatz;
            }).length === fraktionenWithBelegungen.length;
    }

    /**
     * Stores already existing plaetze
     */
    private storePreExistingPlaetze(
        fraktion: KinderOrtFraktion,
        plaetze: ExtraPlatz[],
        kontingente: Kontingente[],
        allowEdit: boolean,
        showKontingente: boolean,
    ): TempExtraPlaetzeByDate {

        const fraktionId = checkPresent(fraktion.id);
        const tempExtraPlaetze = this.tempPlaetze[fraktionId] || new TempExtraPlaetzeByDate(fraktionId);
        plaetze.forEach(platz => {

            const kontingent = platz.kontingentId ?
                checkPresent(kontingente.find(k => k.id === platz.kontingentId)) :
                null;

            const tempExtraPlatz = new TempExtraPlatz(
                fraktionId,
                moment(platz.affectedDay),
                checkPresent(platz.extraPlatzCategory),
                checkPresent(platz.extraPlatzType),
                !allowEdit,
                showKontingente);

            const felder = ZeitraumUtil.findZeitraumFelderFromWochenplan(
                fraktion.wochenplan!,
                checkPresent(platz.wochentag),
                checkPresent(platz.belegungsEinheitId));
            const firstOfAffectedWeek = DvbDateUtil.startOfWeek(moment(platz.affectedDay));

            felder.forEach(feld => {
                // Only add the feld if it does not yet exist.
                // Otherwise we might overwrite any changes that have already been made
                if (!tempExtraPlaetze.contains(feld, firstOfAffectedWeek)) {
                    tempExtraPlaetze.addZeitraumFeld(tempExtraPlatz, feld, platz.id, kontingent);
                }
            });
        });
        this.tempPlaetze[fraktionId] = tempExtraPlaetze;

        return tempExtraPlaetze;
    }

    private removeTempExtraPlatzEntry(fraktionId: KinderOrtFraktionId): void {
        if (Object.keys(this.tempPlaetze[fraktionId].getPlaetze()).length === 0) {
            delete this.tempPlaetze[fraktionId];
        }
    }

    private createForSingleTempPlatz(
        tempPlatz: TempExtraPlatz,
        kind: Kind,
        fraktion: KinderOrtFraktion,
        dayKey: string,
        deletedPlaetze: Set<string>,
        newPlaetze: ExtraPlatz[],
    ): void {
        if (tempPlatz.getFelder().every(feld => feld.existingPlatzId && !feld.removed) &&
            !tempPlatz.isExtraPlatzCategoryUpdated()) {
            return;
        }

        const fullyDeleted = tempPlatz.isRemoved();
        const fullyNew = tempPlatz.isNewPlatz();

        // when partially modified, delete the whole thing and create it new
        const partiallyModified = !fullyDeleted && !fullyNew;

        if (fullyDeleted || partiallyModified) {
            tempPlatz.getFelder()
                .filter(feld => feld.existingPlatzId)
                .forEach(feld => deletedPlaetze.add(feld.existingPlatzId!));
        }
        if (fullyNew || partiallyModified) {
            this.createNewExtraPlaetze(
                dayKey,
                fraktion,
                tempPlatz.getFelder(),
                kind,
                tempPlatz.extraPlatzCategory,
                tempPlatz.extraPlatzType,
            ).forEach(platz => newPlaetze.push(platz));
        }
    }

    private createNewExtraPlaetze(
        dayKey: string,
        fraktion: KinderOrtFraktion,
        felder: TempExtraZeitraumFeld[],
        kind: Kind,
        extraPlatzCategory: ExtraPlatzCategory,
        extraPlatzType: ExtraPlatzType,
    ): ExtraPlatz[] {

        const affectedDay = moment(dayKey, TempExtraPlaetzeByDate.DAY_KEY_FORMAT, true);
        const currentFirstOfWeek = DvbDateUtil.startOfWeek(moment(affectedDay));

        // make sure the felder are selected when necessary, otherwise ZeitraumUtil will ignore them
        felder.forEach(feld => {
            feld.selected = !feld.removed;
        });

        const plaetze = ZeitraumUtil.buildPlaetze(
            checkPresent(fraktion),
            felder,
            currentFirstOfWeek);

        return plaetze.map(platz => {
            const result = ExtraPlatz.fromPlatz(platz);
            result.kinderOrtFraktionId = fraktion.id;
            result.kindId = kind.id;
            result.affectedDay = affectedDay;
            result.extraPlatzCategory = extraPlatzCategory;
            result.extraPlatzType = extraPlatzType;

            return result;
        });
    }

    private removeAdditionsForFelderWhenAbsenceType(
        type: ExtraPlatzType,
        removedZeitraeume: ZeitraumFeld[],
        firstOfWeek: moment.Moment,
    ): Set<KinderOrtFraktionId> {

        return type === ExtraPlatzType.ABSENCE ?
            // remove any felder with ExtraPlatzType.ADDITIONAL present anywhere on the same day and field
            this.removeAdditionsForFelder(removedZeitraeume, firstOfWeek) :
            new Set<KinderOrtFraktionId>();
    }

    private removeAdditionsForFelder(
        zeitraumFelder: ZeitraumFeld[],
        firstOfWeek: moment.Moment,
    ): Set<KinderOrtFraktionId> {
        const fraktionenWithRemovedFields = new Set<KinderOrtFraktionId>();

        zeitraumFelder.forEach(zeitraumFeld => {
            this.removeAdditionsZeitraumFeld(zeitraumFeld, firstOfWeek, fraktionenWithRemovedFields);
        });

        return fraktionenWithRemovedFields;
    }

    /**
     * Remove any felder with ExtraPlatzType.ADDITIONAL present anywhere on the same day and field.
     */
    private removeAdditionsZeitraumFeld(
        zeitraumFeld: ZeitraumFeld,
        firstOfWeek: moment.Moment,
        fraktionenWithRemovedFields: Set<KinderOrtFraktionId>,
    ): void {
        Object.entries(this.tempPlaetze).forEach(([currentFraktionId, plaetze]) => {
            const removed = plaetze.removeZeitraumFeld(zeitraumFeld, firstOfWeek, ExtraPlatzType.ADDITIONAL);
            if (removed) {
                fraktionenWithRemovedFields.add(plaetze.fraktionId);
            }
            this.removeTempExtraPlatzEntry(currentFraktionId);
        });
    }
}
