/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BetreuungsGutschein} from '@dv/kitadmin/models';
import angular from 'angular';

angular.module('kitAdmin').directive('dvbBetreuungsGutscheinBgPensum', dvbBetreuungsGutscheinBgPensum);

function dvbBetreuungsGutscheinBgPensum(): angular.IDirective {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: (_scope, _element, _attrs, ngModel: any): void => {
            ngModel.$validators.betreuungsGutscheinBgPensum = isValidBgPensum;

            function isValidBgPensum(modelValue: number, viewValue: number): boolean {
                const value = modelValue || viewValue;

                return BetreuungsGutschein.isValidBgPensumProzent(value);
            }
        },
    };
}
