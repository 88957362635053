/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {CustomFieldConfiguration} from '../../models';
import type {CustomFieldConfigurationService} from '../../service/customFieldConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        onSelectionChange: '&',
    },
    template: require('./dvb-custom-field-configuration-selector.html'),
    controllerAs: 'vm',
};

export class DvbCustomFieldConfigurationSelector implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['customFieldConfigurationService'];

    public onSelectionChange!: (val: { customFieldConfiguration: CustomFieldConfiguration }) => void;

    public customFieldConfigurations: CustomFieldConfiguration[] = [];
    public selectedCustomFieldConfiguration: CustomFieldConfiguration | null = null;

    public constructor(
        private customFieldConfigurationService: CustomFieldConfigurationService,
    ) {
    }

    public $onInit(): void {
        this.customFieldConfigurationService.getAll().then(result => {
            this.customFieldConfigurations = result;
        });
    }
}

componentConfig.controller = DvbCustomFieldConfigurationSelector;
angular.module('kitAdmin').component('dvbCustomFieldConfigurationSelector', componentConfig);
