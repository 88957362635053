/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IExternalAnmeldung, IKibonRefNrInfo, KibonExchangeEntity, Kind} from '@dv/kitadmin/models';
import {
    ExternalHousehold,
    KibonExchangeInstitution,
    TagesschuleAnmeldungKind,
    TagesschuleGesuchsteller,
} from '@dv/kitadmin/models';
import {checkPresent, DvbRestUtil, isPresent} from '@dv/shared/code';
import type moment from 'moment';
import {KibonExchangeModulAuswahl} from './KibonExchangeModulAuswahl';

export enum TagesschuleAnmeldungState {
    PENDING = 'PENDING',
    ASSIGNED = 'ASSIGNED',
    COMPLETED = 'COMPLETED',
    IGNORED = 'IGNORED',
    ABGELEHNT = 'ABGELEHNT',
}

export enum KibonTagesschuleAnmeldungState {
    SCHULAMT_ANMELDUNG_ERFASST = 'SCHULAMT_ANMELDUNG_ERFASST',
    SCHULAMT_ANMELDUNG_AUSGELOEST = 'SCHULAMT_ANMELDUNG_AUSGELOEST',
    SCHULAMT_MODULE_AKZEPTIERT = 'SCHULAMT_MODULE_AKZEPTIERT',
    SCHULAMT_ANMELDUNG_UEBERNOMMEN = 'SCHULAMT_ANMELDUNG_UEBERNOMMEN',
    SCHULAMT_ANMELDUNG_ABGELEHNT = 'SCHULAMT_ANMELDUNG_ABGELEHNT',
    SCHULAMT_FALSCHE_INSTITUTION = 'SCHULAMT_FALSCHE_INSTITUTION',
    SCHULAMT_ANMELDUNG_STORNIERT = 'SCHULAMT_ANMELDUNG_STORNIERT',
}

export class KibonExchangeTagesschuleAnmeldung implements KibonExchangeEntity, IKibonRefNrInfo, IExternalAnmeldung {

    public constructor(
            public id: string,
            public gueltigAb: moment.Moment,
            public gueltigBis: moment.Moment,
            public refNr: string,
            public institution: KibonExchangeInstitution,
            public kinderOrtId: string | null = null,
            public angemeldetAm: moment.Moment,
            public timestampErstellt: moment.Moment,
            public kind: TagesschuleAnmeldungKind,
            public gesuchsteller: TagesschuleGesuchsteller,
            public gesuchsteller2: TagesschuleGesuchsteller | null,
            public state: TagesschuleAnmeldungState,
            public status: KibonTagesschuleAnmeldungState,
            public assignedKindId: string | null = null,
            public modulAuswahl: KibonExchangeModulAuswahl[] = [],
            public eintrittsdatum: moment.Moment | null = null,
            public planKlasse: string | null = null,
            public abweichungZweitesSemester: boolean | null = null,
            public bemerkung: string | null = null,
            public assignable: boolean = false,
            public unassignable: boolean = false,
            public ignorable: boolean = false,
            public unignorable: boolean = false,
            public declinable: boolean = false,
            public anmeldbar: boolean = false,
    ) {
    }

    public get kibonExportAm(): moment.Moment {
        return this.angemeldetAm;
    }

    public static apiResponseTransformer(data: any): KibonExchangeTagesschuleAnmeldung {
        return new KibonExchangeTagesschuleAnmeldung(
                data.id,
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
                data.refNr,
                KibonExchangeInstitution.apiResponseTransformer(data.institution),
                data.kinderOrtId,
                checkPresent(DvbRestUtil.localDateTimeToMoment(data.angemeldetAm)),
                checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampErstellt)),
                TagesschuleAnmeldungKind.apiResponseTransformer(data.kind),
                TagesschuleGesuchsteller.apiResponseTransformer(data.gesuchsteller),
                data.gesuchsteller2 === null ? null :
                        TagesschuleGesuchsteller.apiResponseTransformer(data.gesuchsteller2),
                data.state,
                data.status,
                data.assignedKindId,
                DvbRestUtil.transformArray(data.modulAuswahl, KibonExchangeModulAuswahl),
                DvbRestUtil.localDateToMoment(data.eintrittsdatum),
                data.planKlasse,
                data.abweichungZweitesSemester,
                data.bemerkung,
                data.assignable,
                data.unassignable,
                data.ignorable,
                data.unignorable,
                data.declinable,
                data.anmeldbar,
        );
    }

    public getAssignedKind(): Kind | null {
        return null;
    }

    public canAssign(): boolean {
        return false;
    }

    public canUnAssign(): boolean {
        return this.unassignable;
    }

    public canIgnore(): boolean {
        return this.ignorable;
    }

    public canUnIgnore(): boolean {
        return this.unignorable;
    }

    public canDecline(): boolean {
        return this.declinable;
    }

    public getRefNr(): string {
        return this.refNr;
    }

    public canAnmelden(): boolean {
        return this.anmeldbar;
    }

    public showUebernehmen(): boolean {
        return isPresent(this.assignedKindId) && this.canImport();
    }

    public canImport(): boolean {
        return this.state !== TagesschuleAnmeldungState.ABGELEHNT &&
                this.state !== TagesschuleAnmeldungState.IGNORED;
    }

    public toExternalHousehold(): ExternalHousehold {
        const anmeldungKind = this.kind.toAnmeldungKind();
        const gesuchsteller = this.gesuchsteller.toAnmeldungKontaktperson(true);
        const gesuchsteller2 = this.gesuchsteller2?.toAnmeldungKontaktperson(false);

        return new ExternalHousehold(anmeldungKind, [gesuchsteller, gesuchsteller2].filter(isPresent));
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            eintrittsdatum: DvbRestUtil.momentToLocalDate(this.eintrittsdatum),
            planKlasse: this.planKlasse,
            abweichungZweitesSemester: this.abweichungZweitesSemester,
            bemerkung: this.bemerkung,
            modulAuswahl: this.modulAuswahl.map(auswahl => auswahl.toRestObject()),
        };
    }

    public getAnmeldeDatum(): moment.Moment | null {
        return this.angemeldetAm;
    }
}
