/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import {RechnungsAufteilungType} from '@dv/kitadmin/models';

export class RechnungsAufteilungModel {

    public readonly valueBeforeTypeChange: Record<RechnungsAufteilungType, number | null> = {
        [RechnungsAufteilungType.PROZENT]: null,
        [RechnungsAufteilungType.FIXBETRAG]: null,
    };

    public constructor(
        public readonly kontaktperson: Kontaktperson,
        public type: RechnungsAufteilungType,
        public value: number | null,
    ) {
        this.valueBeforeTypeChange[type] = value;
    }
}
