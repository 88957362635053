/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt, Mandant} from '@dv/kitadmin/models';
import {RechnungsLauf} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        mandant: '<',
        periode: '<',
        onSubmit: '&',
    },
    template: require('./dvb-kita-rechnungslauf-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbKitaRechnungslaufErstellen implements angular.IController {

    public static $inject: readonly string[] = ['errorService', '$state', 'fakturaService', '$q'];

    public kita!: KinderOrt;
    public mandant!: Mandant;
    public periode: moment.Moment | null = null;
    public onSubmit!: (params: { rechnungsLauf: RechnungsLauf }) => any;

    public rechnungsLauf: RechnungsLauf = new RechnungsLauf();
    public normalizedAbgerechnetBis: moment.Moment | null = null;
    public isLoading: boolean = false;

    private timeout?: angular.IDeferred<any> = undefined;

    public constructor(
        private errorService: ErrorService,
        private $state: StateService,
        private fakturaService: FakturaService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.rechnungsLauf.kitaId = this.kita ? this.kita.id : null;
        this.rechnungsLauf.gueltigAb = this.periode ?? DvbDateUtil.startOfMonth();
        this.rechnungsLauf.rechnungsDatum = DvbDateUtil.today();
        this.rechnungsLauf.faelligAm = moment(this.rechnungsLauf.rechnungsDatum).add(1, 'months');

        this.normalizedAbgerechnetBis = DvbDateUtil.endOfMonth(moment(this.mandant.abgerechnetBis)).add(1, 'days');
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public submit(isValid: boolean): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        this.rechnungsLauf.gueltigBis = DvbDateUtil.endOfMonth(moment(this.rechnungsLauf.gueltigAb));

        this.errorService.handleValidationError(this.rechnungsLauf.isValid(),
            'ERRORS.ERR_KITA_RECHNUNGSLAUF_FAELLIG_AM_NACH_RECHNUNGS_DATUM');

        if (!isValid || !this.rechnungsLauf.isValid()) {
            return;
        }

        this.isLoading = true;

        this.timeout = this.$q.defer();
        const params = {
            timeout: this.timeout.promise,
            includes: '(warnings)',
        };

        this.fakturaService.createRechnungsLauf(this.rechnungsLauf, params)
            .then(response => {
                this.rechnungsLauf.entries = response;
                this.onSubmit({rechnungsLauf: this.rechnungsLauf});
            })
            .catch(err => DvbRestUtilAngularJS.handleRequestCancellation(err))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.errorService.clearAll();
        this.$state.go('base.kinderort.faktura.overview');
    }
}

componentConfig.controller = DvbKitaRechnungslaufErstellen;
angular.module('kitAdmin').component('dvbKitaRechnungslaufErstellen', componentConfig);
