/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {DvbUtil} from '@dv/shared/code';

angular.module('kitAdmin').directive('dvbInteger', dvbInteger);

function dvbInteger(): angular.IDirective {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (_scope, _element, _attrs, ngModel: any): void => {
            ngModel.$validators.integer = (value: any): boolean => {
                if (value === undefined || value === null) {
                    return true;
                }

                return DvbUtil.isInteger(value);
            };
        },
    };
}
