/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BlobInfo, DokumentLayout, KinderOrt, Mandant, TempBlob} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, stringUnion} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {finalize, from, lastValueFrom, of, Subject, switchMap, take} from 'rxjs';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import type {ReportState} from '../../models';
import {ERR_VALIDATION} from '../../models';
import type {ReportBlobService} from '../../service/reportBlobService';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        reportState: '<',
        kinderOrtSearchResult: '<?',
        preSelectableKinderOrt: '<?',
    },
    template: require('./dvb-corona-rueckerstattung-report.html'),
    controllerAs: 'vm',
};

const CORONA_REFUNDS_REPORTS = stringUnion('CORONA_REFUNDS', 'CORONA_REFUNDS_LUZERN', 'CORONA_REFUNDS_BERN_PRIVAT');

export class DvbCoronaRueckerstattungReport implements angular.IController {
    public static $inject: readonly string[] = [
        'reportService',
        'reportBlobService',
        'errorService',
        'kinderOrtService',
        'dialogService',
        '$translate',
        '$http',
        '$q',
    ];

    public readonly reportState!: ReportState;
    public kinderOrtSearchResult: SearchResultEntry | null = null;
    public preSelectableKinderOrt: SearchResultEntry | null = null;

    public kinderOrt: KinderOrt | null = null;
    public mandant!: Mandant;
    public reportTemplate: ReportTemplate | null = null;
    public verpflegungRefundPct: number | null = null;
    public defaultVerpflegungRefundPct: number = 0;

    public downloadPdfs: boolean = false;
    public addSignatures: boolean = true;
    public showVerpflegung: boolean = true;
    public pdfIntroduction: string = '';
    public pdfAdditionalText: string | null = null;
    public returnDate: moment.Moment | null = null;
    public pdfAdditionalTextNoAbsences: string | null = null;
    public massenversandText: string | null = null;

    public layout: DokumentLayout | null = null;

    public createRefunds: boolean = false;

    public generatedReports: BlobInfo[] = [];
    public isLoading: boolean = false;
    public isDownloading: { [reportBlobId: string]: boolean } = {};

    public readonly reportForm!: angular.IFormController;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private reportService: ReportService,
        private reportBlobService: ReportBlobService,
        private errorService: ErrorService,
        private kinderOrtService: KinderOrtService,
        private dialogService: DialogService,
        private $translate: angular.translate.ITranslateService,
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (this.preSelectableKinderOrt && !this.kinderOrt) {
            this.kinderOrtSearchResult = this.preSelectableKinderOrt;
        }

        const reportType = CORONA_REFUNDS_REPORTS.check(this.reportState.asReportType());
        this.pdfIntroduction = this.$translate.instant('REPORT.CORONA_REFUNDS.DEFAULTS.PDF_INTRODUCTION_MF',
            {reportType},
            'messageformat');
        this.showVerpflegung = this.reportState.asReportType() !== 'CORONA_REFUNDS_LUZERN';
        this.refreshGeneratedReports();
    }

    public $onChanges(): void {
        this.updateKinderOrt();
    }

    public refreshGeneratedReports(): void {
        this.isLoading = true;
        this.reportBlobService.getAllByType(this.reportState.asReportType())
            .then(blobInfo => {
                this.generatedReports = blobInfo;
                this.isLoading = false;
            });
    }

    public downloadBlob(id: string): void {
        this.isDownloading[id] = true;
        this.reportBlobService.getTempBlob(id)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => delete this.isDownloading[id]);
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrtSearchResult);

        if (!this.kinderOrtSearchResult) {
            return;
        }

        this.kinderOrtService.get(this.kinderOrtSearchResult.id, {includes: '(mandant)'}).then(kinderOrt => {
            this.kinderOrt = kinderOrt;
            this.updateDefaultTexts();
        });
    }

    public updateDefaultTexts(): void {
        this.pdfAdditionalText = this.returnDate ?
            this.defaultPdfAdditionalText('REPORT.CORONA_REFUNDS.DEFAULTS.PDF_ADDITIONAL_TXT_MF') :
            this.pdfAdditionalText;

        this.pdfAdditionalTextNoAbsences = this.returnDate ?
            this.defaultPdfAdditionalText('REPORT.CORONA_REFUNDS.DEFAULTS.PDF_ADDITIONAL_TXT_NO_ABSENCES_MF') :
            this.pdfAdditionalTextNoAbsences;
    }

    public updateState(): void {
        const params: { [key: string]: any } = {};

        this.reportService.updateStateParams(params);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.reportForm.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return lastValueFrom(this.withFeeApproval$().pipe(
            switchMap(() => this.getDownloadPromise()),
            take(1)));
    }

    private withFeeApproval$(): Observable<unknown> {
        if (!this.downloadPdfs && !this.createRefunds) {
            // only "free" functionality requested
            return of(null);
        }

        return from(this.reportService.hasCoronaKinderOrtFeeApproved(checkPresent(this.kinderOrt?.id))).pipe(
            switchMap(approved => approved ? of(null) : this.askForFeeApproval$()),
            take(1));
    }

    /**
     * resolves when approved, rejects otherwise
     */
    private askForFeeApproval$(): Observable<void> {

        const dialog$ = new Subject<void>();
        this.dialogService.openConfirmDialog({
            title: 'REPORT.CORONA_REFUNDS.FEE_APPROVAL',
            confirm: () => of(null).pipe(take(1), finalize(() => dialog$.next())),
            cancel: () => dialog$.error(null),
        });

        return dialog$;
    }

    private getDownloadPromise(): angular.IPromise<TempBlob> {
        const params = this.getParamsObject();

        return this.reportService.getTempBlobForCoronaRefundReport(
            checkPresent(this.kinderOrt).id!,
            params,
            {reportTemplate: this.reportTemplate})
            .finally(() => this.refreshGeneratedReports());
    }

    private getParamsObject(): any {
        return {
            verpflegungRefundPct: this.verpflegungRefundPct ?? this.defaultVerpflegungRefundPct,
            downloadPdfs: this.downloadPdfs,
            layoutId: this.layout ? this.layout.id : null,
            addSignatures: this.addSignatures,
            pdfIntroduction: this.pdfIntroduction,
            pdfAdditionalText: this.pdfAdditionalText,
            pdfAdditionalTextNoAbsences: this.pdfAdditionalTextNoAbsences,
            massenversandText: this.massenversandText,
            createRefunds: this.createRefunds,
            reportType: this.reportState.asReportType(),
        };
    }

    private defaultPdfAdditionalText(key: string): string {
        const kinderOrt = checkPresent(this.kinderOrt);
        const params = {
            email: kinderOrt.email,
            sender: kinderOrt.mandant?.name ?? kinderOrt.getDisplayName(),
            returnDate: checkPresent(this.returnDate).format('D. MMMM YYYY'),
        };

        return this.$translate.instant(key, params, 'messageformat');
    }
}

componentConfig.controller = DvbCoronaRueckerstattungReport;
angular.module('kitAdmin').component('dvbCoronaRueckerstattungReport', componentConfig);
