/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').directive('dvbFocusIf', dvbFocusIf);

function dvbFocusIf(): angular.IDirective {
    return {
        restrict: 'A',
        link: (scope, element, attrs): void => {
            const myElement = element[0];

            if (attrs.dvbFocusIf) {
                scope.$watch(attrs.dvbFocusIf, focusElement);
            } else {
                focusElement(true);
            }

            function focusElement(condition: boolean): void {
                if (condition) {
                    myElement.focus();
                }
            }
        },
    };
}
