/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MandantCustomPosition} from '@dv/kitadmin/models';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

export class MandantRechnungCustomPositionService {
    public static $inject: readonly string[] = ['$http'];
    public static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/mandantrechnungen/custompositions`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public overrideWithNewCustomPostionen(
        mandantId: string,
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
        customPositionen: MandantCustomPosition[],
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = MandantRechnungCustomPositionService.BASE_URL;

        const matrixParams = {mandantId};
        DvbRestUtil.setGueltigkeitParams(matrixParams, {gueltigAb, gueltigBis});

        const mandantCustomPositionen = {
            mandantCustomPositionen: customPositionen.map(cp => cp.toRestObject()),
        };

        return this.$http.post(url + DvbRestUtil.encodeMatrixParams(matrixParams), mandantCustomPositionen);
    }

    public deleteMandantCustomPosition(mandantCustomPositionId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${MandantRechnungCustomPositionService.BASE_URL}/${encodeURIComponent(mandantCustomPositionId)}`;

        return this.$http.delete(url);
    }
}
