/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    FakturaStatusPeriode,
    IRechnungsKonfiguration,
    KinderOrt,
    KitaRechnungsKonfiguration,
} from '@dv/kitadmin/models';
import {checkPresent, FakturaStatus} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {KitaFakturaService} from '../../../../../common/service/rest/kinderort/kitaFakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        kitaKonfigurationen: '<',
        gueltigAb: '<',
        gueltigBis: '<',
    },
    template: require('./dvb-kita-faktura-rechnungslauf.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaRechnungslauf implements angular.IController {

    public static $inject: readonly string[] = ['kitaFakturaService'];

    public kita!: KinderOrt;
    public kitaKonfigurationen!: KitaRechnungsKonfiguration<IRechnungsKonfiguration>[];
    public gueltigAb!: moment.Moment;
    public gueltigBis!: moment.Moment;

    public readonly fakturaStatus: typeof FakturaStatus = FakturaStatus;
    public isFakturaStatusesLoading: boolean = false;
    public isDownloading: boolean = false;
    public hasKitaRechnungskonfiguration: boolean = false;
    public fakturaStatuses: FakturaStatusPeriode[] = [];

    public constructor(private readonly kitaFakturaService: KitaFakturaService) {
    }

    public $onInit(): void {
        this.hasKitaRechnungskonfiguration = this.kitaKonfigurationen.length > 0;
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.gueltigAb || changes.gueltigBis) {
            this.loadFakturaStatuses();
        }
    }

    private loadFakturaStatuses(): void {
        this.fakturaStatuses = [];
        this.isFakturaStatusesLoading = true;
        const params = {
            gueltigAb: this.gueltigAb,
            gueltigBis: this.gueltigBis,
        };
        this.kitaFakturaService.getFakturaStatuses(checkPresent(this.kita.id), params)
            .then(statuses => {
                this.fakturaStatuses = statuses.statuses;
            })
            .finally(() => {
                this.isFakturaStatusesLoading = false;
            });
    }
}

componentConfig.controller = DvbKitaFakturaRechnungslauf;
angular.module('kitAdmin').component('dvbKitaFakturaRechnungslauf', componentConfig);
