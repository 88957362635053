<div class="dvb-angestellte-erstellen row">
    <form name="neueAngestellte"
          class=" dvb-form col-md-6 col-md-offset-3"
          ng-submit="vm.onSubmit(neueAngestellte)"
          novalidate>

        <h1 data-translate="PERSONAL.ANGESTELLTE.CREATE"></h1>

        <div class="form-section-minus-normal-abstand">
            <div class="row">
                <div class="col-md-6">
                    <input type="text"
                           dvb-capitalize-first-letter
                           class="form-control"
                           ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                           uib-tooltip="{{'COMMON.NAME' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.angestellte.familienName"
                           maxlength="255"
                           required>
                </div>
                <div class="col-md-6">
                    <input type="text"
                           dvb-capitalize-first-letter
                           class="form-control"
                           ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                           uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.angestellte.vorName"
                           maxlength="255"
                           required>
                </div>

                <div class="col-md-12">
                    <dvb-datepicker-text-field
                        ng-model="vm.angestellte.geburtsTag"
                        ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}">
                    </dvb-datepicker-text-field>
                </div>

                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           ng-model="vm.angestellte.telefon"
                           maxlength="100"
                           ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                           uib-tooltip="{{'COMMON.TELEFON' | translate}}"
                           tooltip-placement="top-left">
                </div>
                <div class="col-md-6">
                    <input type="email"
                           class="form-control"
                           maxlength="255"
                           ng-model="vm.angestellte.email"
                           ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                           uib-tooltip="{{'COMMON.E-MAIL' | translate}}"
                           tooltip-placement="top-left">
                </div>

            </div>

            <button type="button"
                    ng-if="!vm.showAddress"
                    class="btn btn-link"
                    ng-click="vm.toggleAddress()">
                + <span data-translate="PERSONAL.ANGESTELLTE.ADD_ADDRESS"></span>
            </button>
            <button type="button"
                    ng-if="vm.showAddress"
                    class="btn btn-link small-abstand-bottom"
                    ng-click="vm.toggleAddress()">
                - <span data-translate="PERSONAL.ANGESTELLTE.REMOVE_ADDRESS"></span>
            </button>
            <dvb-adresse-form ng-if="vm.showAddress"
                              ng-model="vm.angestellte.adresse"
                              is-address-required="vm.showAddress">
            </dvb-adresse-form>
        </div>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   submit-label="PERSONAL.ANGESTELLTE.CREATE"
                                   on-cancel="vm.goBack()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
