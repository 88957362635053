<div class="dvb-mandant-konfiguration-form dvb-form">
    <ng-form name="dvbMandantKonfigurationForm" novalidate>
        <div class="row">
            <div class="checkbox col-xs-12">
                <label>
                    <input type="checkbox" ng-model="vm.konfiguration.automaticPayments" ng-disabled="!vm.isNew">
                    <span data-translate="MANDANT.AUTOMATIC_PAYMENTS"></span>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="checkbox col-xs-12">
                <label>
                    <input type="checkbox" ng-model="vm.konfiguration.einzahlungsscheinOnAllPages">
                    <span data-translate="MANDANT.EINZAHLUNGSSCHEIN_ALL_PAGES"></span>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="checkbox col-xs-12">
                <label>
                    <input type="checkbox" ng-model="vm.konfiguration.individualFiles">
                    <span data-translate="MANDANT.INDIVIDUAL_FILES"></span>
                </label>
            </div>
        </div>
        <div class="row normal-abstand-top">
            <dvb-datepicker-text-field class="col-md-6" name="rechnungsLaufLockedBefore"
                                       ng-model="vm.konfiguration.rechnungsLaufLockedBefore"
                                       dvb-auth
                                       require-role="ADMIN"
                                       on-unauthorised-disable
                                       dvb-end-of-month-parser
                                       ng-attr-placeholder="{{'MANDANT.RECHNUNGSLAUF_LOCKED_BEFORE' | translate}}">
            </dvb-datepicker-text-field>
        </div>


        <div ng-if="vm.konten.length > 0" class="form-row small-abstand-top">
            <select class="form-control"
                    uib-tooltip="{{'FAKTURA.KONTO.RUECKZAHLUNGSKONTO' | translate}}"
                    ng-model="vm.konto"
                    ng-options="konto as konto.bezeichnung for konto in vm.konten track by konto.id"
                    tooltip-placement="top-left">
                <option ng-value="null" selected data-translate="FAKTURA.KONTO.KEIN_KONTO"></option>
            </select>
        </div>
    </ng-form>
</div>
