/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import {DvbRestUtil} from '@dv/shared/code';
import {DienstBedarf} from './DienstBedarf';

export class FraktionsBedarf {

    public constructor(
        public fraktionsId: string | null = null,
        public firstOfWeek: moment.Moment | null = null,
        public lastOfWeek: moment.Moment | null = null,
        public dienstBedarf: DienstBedarf[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): FraktionsBedarf {
        const result = new FraktionsBedarf(
            data.fraktionsId,
            DvbRestUtil.localDateToMoment(data.firstOfWeek),
            DvbRestUtil.localDateToMoment(data.lastOfWeek));

        if (Array.isArray(data.dienstBedarf)) {
            result.dienstBedarf = data.dienstBedarf.map((dienstBedarf: any) =>
                DienstBedarf.apiResponseTransformer(dienstBedarf));
        }

        return result;
    }
}
