<div ng-switch="vm.position.positionType" class="dvb-rechnung-position">
    <div ng-switch-when="SECTION">
        <div class="position-divider"></div>
        <div class="position-kind-container">
            <p ng-bind="vm.position.text" class="position-kind"></p>
        </div>
    </div>
    <div ng-switch-when="UEBERSCHRIFT">
        <div class="position-divider position-spacer"></div>
        <strong ng-bind="vm.position.text" class="position-ueberschrift"></strong>
    </div>
    <div ng-switch-when="BETRAG">
        <div class="position-betrag row">
            <div class="col-xs-2 text-right"><span ng-bind="vm.position.anzahl | number: 3"></span></div>
            <div class="col-xs-6"><span ng-bind="vm.position.text"></span></div>
            <div class="col-xs-2 text-right"><span ng-bind="vm.position.betrag | number: 2"></span></div>
            <div class="col-xs-2 text-right"><strong ng-bind="vm.position.summe | number: 2"></strong></div>
        </div>
    </div>
</div>
