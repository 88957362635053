/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type moment from 'moment';
import {ParameterKinderkontaktliste} from '../../../report/models/ParameterKinderkontaktliste';
import {KontaktType, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import type {IPersistable, SearchResultEntry} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        defaultStichtag: '<',
        parameterKinderkontaktliste: '<',
        onChange: '&',
    },
    template: require('./dvb-parameter-kinderkontaktliste.html'),
    controllerAs: 'vm',
};

export class DvbParameterKinderkontaktliste implements angular.IController {
    public static $inject: readonly string[] = [];

    public defaultStichtag: moment.Moment = DvbDateUtil.today();
    public defaultGueltigAb: moment.Moment = DvbDateUtil.startOfMonth();
    public defaultGueltigBis: moment.Moment = DvbDateUtil.endOfMonth();
    public parameterKinderkontaktliste: ParameterKinderkontaktliste = new ParameterKinderkontaktliste();

    public kontaktTypes: { value: KontaktType; selected: boolean }[] = [];

    public $onInit(): void {
        Object.values(KontaktType)
            .map(val => ({value: val, selected: this.hasKontaktType(val)}))
            .forEach(selectionType => this.kontaktTypes.push(selectionType));
    }

    public addFirma(firmaItem: SearchResultEntry): void {
        if (this.isNew(this.parameterKinderkontaktliste.firmen, firmaItem.id)) {
            this.parameterKinderkontaktliste.firmen.push(firmaItem);
        }
    }

    public toggleKontaktType(item: { value: KontaktType; selected: boolean }): void {
        if (item.selected) {
            this.parameterKinderkontaktliste.kontaktTypes.push(item.value);
        } else {
            DvbUtil.removeFromArray(item.value, this.parameterKinderkontaktliste.kontaktTypes);
        }
    }

    private isNew<T extends IPersistable>(list: T[], id: string): boolean {
        return !list.some(a => a.id === id);
    }

    private hasKontaktType(type: KontaktType): boolean {
        return this.parameterKinderkontaktliste.kontaktTypes.includes(type);
    }
}

componentConfig.controller = DvbParameterKinderkontaktliste;
angular.module('kitAdmin').component('dvbParameterKinderkontaktliste', componentConfig);
