/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {ContextualAdresse, DokumentLayout, KinderOrt} from '@dv/kitadmin/models';
import {BetreuungsVereinbarungsKonfiguration, KinderOrtType} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {isPersisted} from '@dv/shared/code';
import angular from 'angular';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {
    BetreuungsVereinbarungsKonfigurationService,
} from '../../../common/service/rest/kinderort/betreuungsVereinbarungsKonfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        typeId: '<',
        kinderOrt: '<',
    },
    template: require('./dvb-kita-betreuungs-vereinbarungs-konfiguration-creation.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsVereinbarungsKonfigurationCreation implements angular.IController {

    public static $inject: readonly string[] = [
        'betreuungsVereinbarungsKonfigurationService',
        'errorService',
        'dvbStateService',
    ];

    public typeId!: string;
    public kinderOrt!: Persisted<KinderOrt>;

    public konfiguration: BetreuungsVereinbarungsKonfiguration = BetreuungsVereinbarungsKonfiguration.createNew();
    public dokumentLayout: DokumentLayout | null = null;
    public showBetreuungBeiFamilie: boolean = false;

    public isLoading: boolean = false;

    public constructor(
        private betreuungsVereinbarungsKonfigurationService: BetreuungsVereinbarungsKonfigurationService,
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
    ) {
    }

    public $onInit(): void {
        if (this.kinderOrt.dtype === KinderOrtType.TAGES_ELTERN) {
            this.showBetreuungBeiFamilie = true;
        }
    }

    public saveContextualAdresse(contextualAdresse: ContextualAdresse): void {
        this.konfiguration.contextualAdresse = contextualAdresse;
    }

    public save(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.VALUE_REQUIRED');
        if (!form.$valid) {
            return;
        }

        this.isLoading = true;
        this.konfiguration.applyUnterschriftState();
        this.konfiguration.dokumentLayoutId = this.dokumentLayout?.id ?? null;

        const promise = isPersisted(this.konfiguration) ?
            this.betreuungsVereinbarungsKonfigurationService.update(this.konfiguration) :
            this.betreuungsVereinbarungsKonfigurationService.create(this.typeId, this.konfiguration);

        promise.then(() => {
            this.dvbStateService.goToPreviousState();
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public cancel(): void {
        this.dvbStateService.goToPreviousState();
    }
}

componentConfig.controller = DvbKitaBetreuungsVereinbarungsKonfigurationCreation;
angular.module('kitAdmin').component('dvbKitaBetreuungsVereinbarungsKonfigurationCreation', componentConfig);
