/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Password} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        password: '=ngModel',
    },
    template: require('./dvb-new-password.html'),
    controllerAs: 'vm',
};

class DvbNewPassword implements angular.IController {
    public static $inject: readonly string[] = ['$element'];

    public ngModelCtrl!: angular.INgModelController;
    public password?: Password | null;

    public constructor($element: JQLite) {
        $element.addClass('highlight-input-fields-when-invalid');
    }

    public $onInit(): void {
        this.ngModelCtrl.$validators.passwordConfirmation =
            (modelValue?: Password | null, viewValue?: Password | null): boolean => {
                const password = modelValue ?? viewValue;

                return !password || password.newPassword === password.confirmedPassword;
            };
        this.ngModelCtrl.$validators.passwordStrength =
            (modelValue?: Password | null, viewValue?: Password | null): boolean => {
                const password = modelValue ?? viewValue;

                return !!password && Password.validate(password.newPassword);
            };
    }

    public onChange(): void {
        this.ngModelCtrl.$setViewValue(angular.copy(this.ngModelCtrl.$viewValue));
    }
}

componentConfig.controller = DvbNewPassword;
angular.module('kitAdmin').component('dvbNewPassword', componentConfig);
