<div ng-repeat="byPlatzTyp in vm.history" class="big-abstand-bottom">
    <h2>
        <span data-translate="KIND.STUNDENKONTINGENTE"
              data-translate-value-kitaname="{{vm.kinderOrt.getDisplayName()}}">
        </span>
        <dvb-platz-typ-name kontingent-id="byPlatzTyp.platzTyp"></dvb-platz-typ-name>
    </h2>

    <div ng-repeat="entry in byPlatzTyp.stundenKontingente">
        <dvb-collapsible-panel limited-entity="entry"
                               is-first="$first"
                               expand-ab="vm.today"
                               expand-bis="vm.today">
            <panel-header>
                <div class="history-columns">
                    <div class="history-column-left">
                        <p>
                            <dvb-stunden-kontingent-title stunden-kontingent="entry"></dvb-stunden-kontingent-title>
                        </p>
                    </div>

                    <div class="history-column-middle">
                        <p data-translate="FRAKTION.MONATSBLATT.STUNDEN_ANZAHL"
                           data-translate-value-anzahl="{{entry.stunden}}">
                        </p>
                    </div>
                </div>
            </panel-header>

            <panel-content>
                <dvb-stunden-kontingent-content stunden-kontingent="entry"
                                                kind="vm.kind"
                                                kinder-ort="vm.kinderOrt"
                                                on-update="vm.update(stundenKontingent, entry, formContext)"
                                                on-delete="vm.confirmDeleteStundenKontingent(entry, byPlatzTyp, formContext)">
                </dvb-stunden-kontingent-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
