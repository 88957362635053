<div class="col-md-7 dvb-email-template">

    <h2 data-translate="COMMUNICATION.EMAIL_TEMPLATE.TITLE"></h2>

    <dvb-pages items="vm.pageContainer.items"
               entity-name="'COMMUNICATION.EMAIL_TEMPLATE.MF'"
               total-items="vm.pageContainer.count"
               is-loading="vm.isLoading"
               items-per-page="vm.itemsPerPage"
               current-page="vm.currentPage"
               on-update-page="vm.updatePage(page)"
               on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
        <page-items dvb-accordions>
            <dvb-accordion ng-repeat="emailTemplate in vm.pageContainer.items"
                           ng-init="notPermittedToModify = !vm.hasPermissionToModify(emailTemplate); kinderOrtNames = vm.getKinderOrtNames(emailTemplate)"
                           is-first="$first">
                <panel-header class="full-width-block">
                    <div class="layout-row items-center row">
                        <div class="col-sm-6 truncate">
                            <i ng-if="!emailTemplate.alleKinderOrte"
                               class="fa fa-home"
                               uib-tooltip="{{kinderOrtNames}}"
                               tooltip-placement="top-left"
                               tooltip-append-to-body="true">
                            </i>
                            <span ng-bind="emailTemplate.name"
                                  uib-tooltip="{{emailTemplate.name}}"
                                  tooltip-placement="top-left"
                                  tooltip-append-to-body="true">
                            </span>
                        </div>
                        <div class="col-sm-6 truncate"
                             uib-tooltip="{{emailTemplate.subject}}"
                             tooltip-placement="top-left">
                            <span ng-bind="emailTemplate.subject"></span>
                        </div>
                    </div>
                </panel-header>

                <panel-content>
                    <div class="row">
                        <div class="col-sm-4 normal-abstand-bottom email-content">
                            <span ng-bind-html="emailTemplate.content"></span>
                        </div>
                    </div>
                </panel-content>

                <panel-footer>
                    <ul class="list-inline">
                        <li>
                            <a ui-sref="{{vm.emailTemplateEditState.name + '({emailTemplate: emailTemplate, id: emailTemplate.id})' }}"
                               data-translate="COMMON.BEARBEITEN"
                               ng-disabled="notPermittedToModify">
                            </a>
                        </li>
                        <li>
                            <button type="button"
                                    class="btn btn-link"
                                    ng-click="vm.delete(emailTemplate.id)"
                                    data-translate="COMMON.LOESCHEN"
                                    ng-disabled="notPermittedToModify">
                            </button>
                        </li>
                    </ul>
                </panel-footer>
            </dvb-accordion>
        </page-items>
    </dvb-pages>
</div>
<div class="col-md-4 col-md-offset-1">
    <h2 data-translate="COMMON.AKTIONEN"></h2>
    <a ui-sref="{{vm.emailTemplateNewState.name}}" class="full-width-block normal-abstand-bottom">
        + <span data-translate="COMMUNICATION.EMAIL_TEMPLATE.CREATE"></span>
    </a>
</div>



