<div class="dvb-kita-uebersicht-betreung">

    <div class="row dvb-form">
        <div class="col-md-7 normal-abstand-bottom">
            <dvb-kita-chart controlling-report="vm.controllingReport"
                            first-of-week="vm.firstOfWeek"
                            on-set-week="vm.onSetWeek({newFirstOfWeek: newFirstOfWeek})">
            </dvb-kita-chart>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-sm-offset-1 col-sm-5">
                    <h2 class="stats-header"
                        data-translate="KINDERORT.SHORT_KALENDERWOCHE_WITH_VALUE"
                        data-translate-value-kalenderwoche="{{vm.firstOfWeek | amDateFormat:'W'}}"></h2>
                    <p ng-if="vm.kinderOrt.dtype === 'KITA'"
                       class="stats-number chart-bewilligte-plaetze"
                       data-translate="KINDERORT.BEWILLIGTE_PLAETZE_WITH_VALUE"
                       data-translate-value-anzahl="{{vm.bewilligtePlaetze | number:1}}"></p>
                    <p class="stats-number chart-maximale-plaetze"
                       data-translate="KINDERORT.MAXIMALE_PLAETZE_WITH_VALUE"
                       data-translate-value-maximale-plaetze="{{vm.maximalePlaetze | number:1}}"></p>
                    <p class="stats-number chart-plaetze"
                       data-translate="KINDERORT.PLAETZE_WITH_VALUE"
                       data-translate-value-plaetze="{{vm.plaetze | number:1}}"></p>
                    <p class="stats-number chart-belegte-plaetze"
                       data-translate="KINDERORT.BELEGTE_PLAETZE_WITH_VALUE"
                       data-translate-value-belegte-plaetze="{{vm.belegtePlaetze | number:1}}"></p>
                    <p class="stats-number-big"
                       data-translate="KINDERORT.AUSLASTUNG_WITH_VALUE"
                       data-translate-value-auslastung="{{vm.auslastungPct | number:1}}"></p>
                    <p ng-if="vm.kinderOrt.getSubventioniertesKontingent(vm.firstOfWeek)"
                       data-translate="KINDERORT.AUSLASTUNG_SUBVENTIONIERTE_PLAETZE_WITH_VALUE"
                       data-translate-value-auslastung="{{vm.auslastungSubventioniertPct | number:1}}"></p>

                    <div class="btn-group normal-abstand-top">
                        <button type="button"
                                ui-sref="base.report.category.betrieb({
                                kinderOrte: [vm.kinderOrt.toSearchResultEntry()],
                                alleKinderOrte: false,
                                stichtag: vm.firstOfWeek,
                                stichtagAdresse: vm.firstOfWeek,
                                gueltigAb: vm.firstOfWeek,
                                gueltigBis: vm.lastOfWeek
                                })"
                                class="btn btn-primary normal-abstand-bottom"
                                data-translate="COMMON.REPORT_GENERIEREN">
                        </button>
                    </div>
                </div>

                <div class="col-sm-offset-1 col-sm-5">
                    <h2 class="stats-header"
                        data-translate="KINDERORT.DURCHSCHNITT_WITH_VALUE"
                        data-translate-value-jahr="{{vm.firstOfWeek | amDateFormat:'GGGG'}}"></h2>
                    <p ng-if="vm.kinderOrt.dtype === 'KITA'"
                       class="stats-number chart-bewilligte-plaetze"
                       data-translate="KINDERORT.BEWILLIGTE_PLAETZE_WITH_VALUE"
                       data-translate-value-anzahl="{{vm.avgBewilligtePlaetze | number:1}}"></p>
                    <p class="stats-number chart-maximale-plaetze"
                       data-translate="KINDERORT.MAXIMALE_PLAETZE_WITH_VALUE"
                       data-translate-value-maximale-plaetze="{{vm.avgMaximalePlaetze | number:1}}"></p>
                    <p class="stats-number chart-plaetze"
                       data-translate="KINDERORT.PLAETZE_WITH_VALUE"
                       data-translate-value-plaetze="{{vm.avgPlaetze | number:1}}"></p>
                    <p class="stats-number chart-belegte-plaetze"
                       data-translate="KINDERORT.BELEGTE_PLAETZE_WITH_VALUE"
                       data-translate-value-belegte-plaetze="{{vm.avgBelegtePlaetze | number:1}}"></p>
                    <p class="stats-number-big"
                       data-translate="KINDERORT.AUSLASTUNG_WITH_VALUE"
                       data-translate-value-auslastung="{{vm.avgAuslastungPct | number:1}}"></p>
                    <p ng-if="vm.kinderOrt.getSubventioniertesKontingent(vm.firstOfWeek)"
                       data-translate="KINDERORT.AUSLASTUNG_SUBVENTIONIERTE_PLAETZE_WITH_VALUE"
                       data-translate-value-auslastung="{{vm.avgAuslastungSubventioniertPct | number:1}}"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="row big-abstand-top">
        <div class="col-md-7">
            <dvb-kita-kinder-aenderungen kita="vm.kinderOrt" gueltig-ab="vm.firstOfWeek" dvb-pagination>
            </dvb-kita-kinder-aenderungen>
        </div>
    </div>

</div>
