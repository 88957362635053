/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AnwesenheitsZeitConstraint,
    BewilligtePlaetze,
    FirmenKontingentValue,
    KinderOrt,
    KinderOrtFraktion,
    KitaBetreuungsfaktorRegel,
    Kontingente,
    PlatzTyp,
    RestCache,
    SubventioniertesKontingent,
    SubventioniertesKontingentValue,
    WochenKapazitaet,
} from '@dv/kitadmin/models';
import {
    ControllingChange,
    GruppenWochenBelegung,
    Kind,
    KinderOrtFraktionTransformer,
    KinderOrtTransformer,
    KitaTarifeZeitraum,
    KitaWochenBelegung,
    KontingentTransformer,
} from '@dv/kitadmin/models';
import type {PageContainer, Persisted, RestInclude, RestLimited, RestPaginated} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

/* eslint-disable max-lines */
export class KinderOrtService {
    public static $inject: readonly string[] = ['$http'];

    private static kinderOrtBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kinderorte`;
    private static kontingentBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kontingente`;
    private static firmenKontingentBaseUrl: Readonly<string> = `${KinderOrtService.kontingentBaseUrl}/firma/`;
    private static subventioniertesKontingentBaseUrl: Readonly<string> =
        `${KinderOrtService.kontingentBaseUrl}/subventioniert/`;
    private static bewilligtePlaetzeBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/bewilligteplaetze`;
    private static kontingentValueBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kontingentvalues`;
    private static firmenKontingentValueBaseUrl: Readonly<string> = `${KinderOrtService.kontingentValueBaseUrl}/firma/`;
    private static subventioniertesKontingentValueBaseUrl: Readonly<string> =
        `${KinderOrtService.kontingentValueBaseUrl}/subventioniert/`;
    private static kitaTarifeZeitraumBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kitatarifezeitraeume`;

    public constructor(private $http: angular.IHttpService) {
    }

    public getIncludesDeep(): string {
        return '(kontingente,' +
            'gruppen.fields(wochenplan.fields(tagesplaene.fields(belegungsEinheiten.fields(zeitraumIds)),' +
            'zeitraeume)),anwesenheitsZeitSettings,rechnungsLaufLock)';
    }

    /**
     * Get all KinderOrte.
     */
    public getAll<T extends KinderOrt>(params?: RestLimited & RestInclude & RestCache): angular.IPromise<KinderOrt[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const transformer = KinderOrtTransformer.create<T>();

        return DvbRestUtilAngularJS.getModelsArray(
            KinderOrtService.kinderOrtBaseUrl,
            transformer,
            'items',
            matrixParams,
            params?.cache === true,
        );
    }

    /**
     * Get KinderOrt by id.
     */
    public get<T extends KinderOrt>(
        kitaId: string,
        params?: RestLimited & RestInclude & RestCache,
    ): angular.IPromise<Persisted<T>> {

        const transformer = KinderOrtTransformer.create<T>();

        return DvbRestUtilAngularJS.getModelByIdAndParams(
            KinderOrtService.kinderOrtBaseUrl,
            transformer,
            kitaId,
            params,
        ).then(checkPersisted);
    }

    public getMultiple<T extends KinderOrt>(
        params: RestInclude & Partial<RestCache> & { idList: string[] },
    ): angular.IPromise<KinderOrt[]> {

        const url = `${KinderOrtService.kinderOrtBaseUrl}/multiple`;
        const transformer = KinderOrtTransformer.create<T>();

        return DvbRestUtilAngularJS.getModelsArray(url, transformer, 'items', params)
            .then(checkPresent);
    }

    public getKitaWochenBelegung(
        kitaId: string,
        firstOfWeek: moment.Moment,
        params?: RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<KitaWochenBelegung> {

        const matrixParams = {
            firstOfWeek: DvbRestUtil.momentToLocalDate(firstOfWeek),
            includes: params?.includes,
        };

        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/belegung/kitawochenbelegung`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KitaWochenBelegung, matrixParams, params)
            .then(checkPresent);
    }

    public getGruppenWochenBelegung(
        kitaId: string,
        params?: RestLimited & RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<GruppenWochenBelegung[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/belegung/gruppenwochenbelegungen`;

        return DvbRestUtilAngularJS.getModelsArray(url, GruppenWochenBelegung, 'gruppenWochenBelegungen', matrixParams,
            false, params);
    }

    public getGruppenWochenKapazitaeten(
        kitaId: string,
        params?: RestLimited & RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<GruppenWochenBelegung[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/gruppenwochenkapazitaeten`;

        return DvbRestUtilAngularJS.getModelsArray(url, GruppenWochenBelegung, 'gruppenWochenBelegungen', matrixParams,
            false, params);
    }

    public getKinderWithBelegungen(
        kitaId: string,
        params?: RestInclude & RestLimited & angular.IRequestShortcutConfig,
    ): angular.IPromise<Kind[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/belegungen/kinder`;

        return DvbRestUtilAngularJS.getModelsArray(url, Kind, 'kinder', matrixParams, false, params);
    }

    public getKinderWithBewerbungen(
        kitaId: string,
        params: RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<Kind[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/bewerbungen/kinder`;

        return DvbRestUtilAngularJS.getModelsArray(url, Kind, 'kinder', matrixParams, false, params);
    }

    public getOffeneBewerbungenCount(kinderOrtId: string): angular.IPromise<number> {
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/bewerbungen/count`;

        return this.$http.get<{ count: number }>(url).then(response => response.data.count);
    }

    public create(
        kinderOrt: KinderOrt,
        bewilligtePlaetze: BewilligtePlaetze,
        subventioniertePlaetze: number,
        kitaTarifeZeitraum?: KitaTarifeZeitraum | null,
        kitaBetreuungsfaktorRegel?: KitaBetreuungsfaktorRegel | null,
    ): angular.IHttpPromise<any> {

        const hundred = 100;

        const dto = {
            kinderOrt: kinderOrt.toRestObject(),
            bewilligtePlaetze: bewilligtePlaetze.toRestObject(),
            subventioniertePlaetze: subventioniertePlaetze > 0 ? subventioniertePlaetze * hundred : undefined,
            kitaTarifeZeitraum: kitaTarifeZeitraum ? kitaTarifeZeitraum.toRestObject() : undefined,
            kitaBetreuungsfaktorRegel: kitaBetreuungsfaktorRegel ? kitaBetreuungsfaktorRegel.toRestObject() : undefined,
        };

        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(KinderOrtService.kinderOrtBaseUrl, dto);
    }

    public update(kinderOrt: KinderOrt): angular.IPromise<unknown> {
        const id = checkPresent(kinderOrt.id);
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(id)}`;

        return this.$http.put(url, kinderOrt.toRestObject());
    }

    public deleteKita(kinderOrtId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}`);
    }

    public validateKitaDelete(kinderOrtId: string): angular.IPromise<unknown> {
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/validate/delete`;

        return this.$http.get(url);
    }

    public terminateKita(kinderOrtId: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/enddate`;

        return this.$http.put(url, {date: DvbRestUtil.momentToLocalDate(endDate)});
    }

    public revertTerminateKita(kinderOrtId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/enddate`);
    }

    public createFraktion(
        kinderOrtId: string,
        fraktion: KinderOrtFraktion,
        wochenplanId: string,
        wochenKapazitaet: WochenKapazitaet,
    ): angular.IHttpPromise<unknown> {

        const dto = {
            fraktion: fraktion.toRestObject(),
            wochenplanId,
            wochenKapazitaet: wochenKapazitaet.toRestObject(),
        };

        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/` +
            `${fraktion.dtype.toLowerCase()}`;

        return this.$http.post(url, dto);
    }

    public createBewilligtePlaetze(
        kinderOrtId: string,
        bewilligtePlaetze: BewilligtePlaetze,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/bewilligteplaetze`;

        return this.$http.post(url, bewilligtePlaetze.toRestObject());
    }

    /**
     * Create SubventioniertesKontingent for Kita
     */
    public createSubventioniertesKontingent(
        kitaId: string,
        subventioniertesKontingent: SubventioniertesKontingent,
    ): angular.IPromise<unknown> {

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/subventioniertekontingente`;

        return this.$http.post(url, subventioniertesKontingent.toRestObject());
    }

    /**
     * Update field plaetze for BewilligtePlaetze
     */
    public updateBewilligtePlaetzePlaetze(bewilligtePlaetzeId: string, plaetze: number): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.bewilligtePlaetzeBaseUrl}/${encodeURIComponent(bewilligtePlaetzeId)}/plaetze`;

        return this.$http.put(url, plaetze);
    }

    /**
     * Delete BewilligtePlaetze for Kita
     */
    public deleteBewilligtePlaetze(bewilligtePlaetzeId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.bewilligtePlaetzeBaseUrl}/${encodeURIComponent(bewilligtePlaetzeId)}`;

        return this.$http.delete(url);
    }

    /**
     * Get Kontingent by Id
     */
    public getKontingent(
        kontingentId: string,
        params: RestCache & RestLimited & RestInclude,
    ): angular.IPromise<Persisted<Kontingente>> {

        const url = KinderOrtService.kontingentBaseUrl;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, KontingentTransformer, kontingentId, params)
            .then(checkPersisted);
    }

    /**
     * Get BelegtePlaetze by Id
     *
     * @return belegte Plaetze in Prozent
     */
    public getBelegteProzentPunkte(
        kontingentId: string,
        params: RestLimited & angular.IRequestShortcutConfig,
    ): angular.IPromise<number> {
        const matrixParams = {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${KinderOrtService.kontingentBaseUrl}/${encodeURIComponent(kontingentId)}/` +
            `belegteprozentpunkte${DvbRestUtil.encodeMatrixParams(matrixParams)}`;
        const config = DvbRestUtilAngularJS.parseHttpRequestConfig(params);

        return this.$http.get<string>(url, config).then(response => parseInt(response.data, 10));
    }

    /**
     * @return definierte Prozentpunkte in Prozent
     */
    public getProzentPunkte(
        kontingentId: string,
        params: RestLimited & angular.IRequestShortcutConfig,
    ): angular.IPromise<number> {
        const matrixParams = {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${KinderOrtService.kontingentBaseUrl}/${encodeURIComponent(kontingentId)}/` +
            `prozentpunkte${DvbRestUtil.encodeMatrixParams(matrixParams)}`;
        const config = DvbRestUtilAngularJS.parseHttpRequestConfig(params);

        return this.$http.get<string>(url, config).then(response => parseInt(response.data, 10));
    }

    /**
     * Create FirmenKontingentValue for FirmenKontingent
     */
    public createFirmenKontingentValue(
        kontingentId: string,
        firmenKontingentValue: FirmenKontingentValue,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.firmenKontingentBaseUrl + encodeURIComponent(kontingentId)}/kontingentvalues`;

        return this.$http.post(url, firmenKontingentValue.toRestObject());
    }

    /**
     * Create SubventioniertesKontingentValue for SubventioniertesKontingent
     */
    public createSubventioniertesKontingentValue(
        kontingentId: string,
        subventioniertesKontingentValue: SubventioniertesKontingentValue,
    ): angular.IPromise<unknown> {

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.subventioniertesKontingentBaseUrl}` +
            `${encodeURIComponent(kontingentId)}/kontingentvalues`;

        return this.$http.post(url, subventioniertesKontingentValue.toRestObject());
    }

    public terminateKontingent(kontingentId: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kontingentBaseUrl}/${encodeURIComponent(kontingentId)}/enddate`;

        return this.$http.put(url, {date: DvbRestUtil.momentToLocalDate(endDate)});
    }

    /**
     * Update field prozentPunkteProWoche for SubventioniertesKontingentValue
     */
    public updateSubventioniertesKontingentValueProzentPunkte(
        subventioniertesKontingentValueId: string,
        prozentPunkteProWoche: number,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kontingentValueBaseUrl}/subventioniert/` +
            `${encodeURIComponent(subventioniertesKontingentValueId)}/prozentpunkteprowoche`;

        return this.$http.put(url, prozentPunkteProWoche);
    }

    public updateFirmenKontingentValue(firmenKontingentValue: FirmenKontingentValue): angular.IPromise<unknown> {
        const id = checkPresent(firmenKontingentValue.id);
        const payload = firmenKontingentValue.toRestObject();
        DvbRestUtilAngularJS.clearHttpCache();

        const url = KinderOrtService.firmenKontingentValueBaseUrl + encodeURIComponent(id);

        return this.$http.put(url, payload);
    }

    /**
     * Delete FirmenKontingentValue for FirmenKontingent
     */
    public deleteFirmenKontingentValue(firmenKontingentValueId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = KinderOrtService.firmenKontingentValueBaseUrl + encodeURIComponent(firmenKontingentValueId);

        return this.$http.delete(url);
    }

    /**
     * Delete SubventioniertesKontingentValue for SubventioniertesKontingent
     */
    public deleteSubventioniertesKontingentValue(subventioniertesKontingentValueId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = KinderOrtService.subventioniertesKontingentValueBaseUrl +
            encodeURIComponent(subventioniertesKontingentValueId);

        return this.$http.delete(url);
    }

    /**
     * Delete KitaTarifeZeitraum
     */
    public deleteKitaTarifeZeitraum(kitaTarifeZeitraumId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KinderOrtService.kitaTarifeZeitraumBaseUrl}/${encodeURIComponent(kitaTarifeZeitraumId)}`;

        return this.$http.delete(url);
    }

    /**
     * Verlaengert die Gueltigkeit der FirmenKontingentValue bis zum naechst gueltigen FirmenKontingentValue oder
     * bis END_OF_TIME
     */
    public extendFirmenKontingentValue(firmenKontingentValueId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KinderOrtService.firmenKontingentValueBaseUrl}` +
            `${encodeURIComponent(firmenKontingentValueId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    /**
     * Verlaengert die Gueltigkeit der SubventioniertesKontingentValue bis zum naechst gueltigen
     * SubventioniertesKontingentValue oder bis END_OF_TIME
     */
    public extendSubventioniertesKontingentValue(subventioniertesKontingentValueId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.subventioniertesKontingentValueBaseUrl}` +
            `${encodeURIComponent(subventioniertesKontingentValueId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    /**
     * Get all KitaTarifeZeitraum
     */
    public getTarifeZuweisungen(kitaId: string): angular.IPromise<KitaTarifeZeitraum[]> {
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/tarife`;

        return DvbRestUtilAngularJS.getModelsArray(url, KitaTarifeZeitraum, 'kitaTarifeZeitraeume');
    }

    public terminateTarifZuweisung(kitaId: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/tarife/enddate`;

        return this.$http.put(url, {date: DvbRestUtil.momentToLocalDate(endDate)});
    }

    /**
     * Verlaengert die Gueltigkeit des zugewiesenen Tarifs bis zum naechst gueltigen zugewiesenen Tarif oder bis
     * END_OF_TIME
     */
    public extendKitaTarifeZeitraum(kitaTarifeZeitraumId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kitaTarifeZeitraumBaseUrl}/` +
            `${encodeURIComponent(kitaTarifeZeitraumId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    /**
     * Create KitaTarifeZeitraum for Kita
     */
    public createKitaTarifeZeitraum(kitaTarifeZeitraum: KitaTarifeZeitraum): angular.IPromise<unknown> {
        const kitaId = checkPresent(kitaTarifeZeitraum.kitaId);
        DvbRestUtilAngularJS.clearHttpCache();
        const kinderOrtId = encodeURIComponent(kitaId);
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${kinderOrtId}/tarife`;

        return this.$http.post(url, kitaTarifeZeitraum.toRestObject());
    }

    public getKinderAenderungen(
        kitaId: string,
        gueltigAb: moment.Moment,
        params?: Partial<RestPaginated>,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<ControllingChange[]> {
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kitaId)}/` +
            `kinderaenderungen/${DvbRestUtil.momentToLocalDate(gueltigAb)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, ControllingChange, 'changes', params, true, config);
    }

    public getKinderOrtStundenbasierteTarifeOffer(
        kinderOrtId: string,
        stichtag: moment.Moment,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Map<PlatzTyp, boolean>> {
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/stundenbasiertetarife`;
        const paramMap = {stichtag: DvbRestUtil.momentToLocalDate(stichtag)};
        const matrixParams = DvbRestUtil.encodeMatrixParams(paramMap);

        return this.$http.get<any>(url + matrixParams, config)
            .then(response => {
                const result = new Map<PlatzTyp, boolean>();

                response.data.entries
                    .forEach((entry: any) => result.set(entry.kontingentId, entry.hasStundenbasierterTarif));

                return result;
            });
    }

    public updateAnwesenheitsZeitSettings(
        kinderOrtId: string,
        maxDailyHours: number | null,
        anwesenheitsZeitConstraints: AnwesenheitsZeitConstraint[],
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/anwesenheitszeitsettings`;

        return this.$http.put(url, {
            maxDailyHours,
            anwesenheitsZeitConstraints: anwesenheitsZeitConstraints.map(value => value.toRestObject()),
        });
    }

    public getBelegteFraktionen(
        kinderOrtId: string,
        params: RestLimited & RestPaginated,
        config: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<KinderOrtFraktion>> {

        const matrixParams = DvbRestUtil.toMatrixParams(params);
        const url = `${KinderOrtService.kinderOrtBaseUrl}/${encodeURIComponent(kinderOrtId)}/belegtefraktionen`;

        return DvbRestUtilAngularJS.getPagedItems(url, KinderOrtFraktionTransformer.create(), matrixParams, config);
    }
}

// eslint-disable-next-line max-lines
