/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Signal} from '@angular/core';
import {inject, Injectable, signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {apiStore} from '@dv/shared/backend/api-util';
import {
    PersonalplanEmailService,
    PersonalplanEmailServiceGetPersonalplanRecipientInfoRequestParams,
} from '@dv/shared/backend/api/personalplan-email.service';
import {
    JaxPersonalplanungEmailRecipientsInformation,
} from '@dv/shared/backend/model/jax-personalplanung-email-recipients-information';
import {map} from 'rxjs';
import {Angestellte} from '../../../../../personal/anstellung/models/Angestellte';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class PersonalplanungEmailStore {

    public api = inject(PersonalplanEmailService);

    public dialogOpen = signal(false);

    public sendStore = apiStore(this.api.sendPersonalplan$.bind(this.api), () => this.dialogOpen.set(false));
    public fetchRecipientsStore = apiStore<PersonalplanEmailServiceGetPersonalplanRecipientInfoRequestParams,
        JaxPersonalplanungEmailRecipientsInformation>(
        this.api.getPersonalplanRecipientInfo$.bind(this.api));

    public recipientInfo: Signal<{
        recipients: Angestellte[];
        missingEmail: Angestellte[];
    }> = toSignal(this.fetchRecipientsStore.request$.pipe(
        map(response => {
            return {
                recipients: response.recipients.map(Angestellte.apiResponseTransformer),
                missingEmail: response.angestellteWithMissingEmail.map(Angestellte.apiResponseTransformer),
            };
        }),
    ), {initialValue: {recipients: [], missingEmail: []}});
}
