/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {REPORT_STATES} from '../../report-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-report-main.html'),
    controllerAs: 'vm',
};

export class DvbReportMain implements angular.IController {
    public static $inject: readonly string[] = ['$uiRouterGlobals'];

    public reportStates: typeof REPORT_STATES = REPORT_STATES;

    public constructor(public $uiRouterGlobals: UIRouterGlobals) {
    }
}

componentConfig.controller = DvbReportMain;
angular.module('kitAdmin').component('dvbReportMain', componentConfig);
