/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Mandant} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {MandantService} from '../common/service/rest/mandantService';

/* eslint-disable sonarjs/no-duplicate-string */

export const ORGANISATION_BASE_STATE = {
    name: 'base.organisation',
    url: '/organisation?currentPage&itemsPerPage',
    params: {
        currentPage: {value: '1', squash: true},
        itemsPerPage: {value: '10', squash: true},
    },
    abstract: true,
    template: '<dvb-organisation-main></dvb-organisation-main>',
    resolve: {
        currentPage: ['$transition$', ($transition$: Transition): string => $transition$.params().currentPage],
        itemsPerPage: ['$transition$', ($transition$: Transition): string => $transition$.params().itemsPerPage],
        $title: [
            '$translate',
            ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.ORGANISATION'),
        ],
    },
    data: {
        permission: PERMISSION.SHARED.EMPLOYEE_VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;

export const MANDANT_STATE = {
    name: `${ORGANISATION_BASE_STATE.name}.mandant`,
    url: '',
    template: '<dvb-mandant-profil mandant="$resolve.mandant"></dvb-mandant-profil>',
    resolve: {
        mandant: [
            'mandantService', (mandantService: MandantService): angular.IPromise<Mandant | null> =>
                mandantService.getOwnMandant({includes: '(contextualAdresse,mandantRechnungConfig)'}),
        ],
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.MANDANT.SINGULAR'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const BENUTZER_STATE = {
    name: `${ORGANISATION_BASE_STATE.name}.benutzer`,
    url: '/benutzer',
    template: '<dvb-entity-list type="benutzer" ' +
        'title-key="COMMON.BENUTZER.MF" ' +
        'base-state="base.benutzer.profil" ' +
        'base-state-create="base.neuer-benutzer" ' +
        'current-page="$resolve.currentPage" ' +
        'items-per-page="$resolve.itemsPerPage" ' +
        'create-permission="shared:manage_users:general" ' +
        'create-link-title-key="COMMON.BENUTZER.ERSTELLEN"></dvb-entity-list>',
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.BENUTZER.PLURAL'),
        ],
    },
    data: {
        permission: {oneOf: [PERMISSION.KITA.VIEW_ANY, PERMISSION.PERSONAL.VIEW_ANY]},
    },
} satisfies Ng1StateDeclaration;

export const KINDER_ORTE_STATE = {
    name: `${ORGANISATION_BASE_STATE.name}.kitas`,
    url: '/kinderorte',
    template: '<dvb-entity-list type="kita" ' +
        'title-key="COMMON.KINDERORT.MF" ' +
        'base-state="base.kinderort.uebersicht" ' +
        'base-state-create="base.kinderort-erstellen" ' +
        'current-page="$resolve.currentPage" ' +
        'items-per-page="$resolve.itemsPerPage" ' +
        'create-link-title-key="COMMON.KINDERORT.ERSTELLEN"></dvb-entity-list>',
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.KINDERORT.PLURAL'),
        ],
    },
    data: {
        permission: {oneOf: [PERMISSION.KITA.VIEW_ANY, PERMISSION.PERSONAL.VIEW_ANY]},
    },
} satisfies Ng1StateDeclaration;

export const PLATZARTEN_STATE = {
    name: `${ORGANISATION_BASE_STATE.name}.platzarten`,
    url: '/platzarten',
    template: '<dvb-entity-list type="platzart" ' +
        'title-key="COMMON.PLATZART.MF" ' +
        'base-state="base.firma.profil" ' +
        'base-state-create="base.firma-erstellen" ' +
        'current-page="$resolve.currentPage" ' +
        'items-per-page="$resolve.itemsPerPage" ' +
        'create-link-title-key="COMMON.PLATZART.ERSTELLEN"></dvb-entity-list>',
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.PLATZART.PLURAL'),
        ],
    },
    data: {
        permission: PERMISSION.KITA.VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;

/* Add New States Above */

export const ORGANISATION_STATES = {
    ORGANISATION_BASE_STATE,
    MANDANT_STATE,
    BENUTZER_STATE,
    KINDER_ORTE_STATE,
    PLATZARTEN_STATE,
};
