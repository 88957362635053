<div class="dvb-external-anmeldung-betreuung normal-abstand-top">

    <div class="row" ng-click="vm.kind.form.showBetreuung = !vm.kind.form.showBetreuung">
        <div class="col-md-12">
            <a href="" class="pull-left smaller-abstand-right">
                <i class="fa"
                   ng-class="{
                   'fa-chevron-right': !vm.kind.form.showBetreuung,
                   'fa-chevron-down': vm.kind.form.showBetreuung
                   }">
                </i>
            </a>
            <h2 class="pull-left"
                data-translate="EXTERNAL_ANMELDUNG.BETREUUNG_KIND"
                data-translate-value-name="{{vm.kind.getDisplayName()}}">
            </h2>
        </div>
    </div>

    <div uib-collapse="!vm.kind.form.showBetreuung">
        <dvb-multi-select ng-model="vm.kind.selectedKinderOrte"
                          on-change="vm.onKinderOrteChanged()"
                          options="vm.availableKinderOrte"
                          placeholder-key="COMMON.STANDORT.AUSWAEHLEN"
                          required>
        </dvb-multi-select>

        <div class="row" ng-if="vm.setup.config.showPensum">
            <div class="col-md-3">
                <input type="number"
                       name="pensum"
                       class="form-control"
                       min="1"
                       ng-blur="vm.kind.pensum.update()"
                       ng-model="vm.kind.pensum.von"
                       ng-attr-placeholder="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                       uib-tooltip="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                       tooltip-placement="top-left"
                       required>
            </div>
            <div class="col-md-1 form-linker" data-translate="COMMON.BIS"></div>
            <div class="col-md-3">
                <input type="number"
                       min="1"
                       class="form-control"
                       ng-blur="vm.kind.pensum.update()"
                       ng-model="vm.kind.pensum.bis"
                       ng-attr-placeholder="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                       uib-tooltip="{{'COMMON.PENSUM_IN_PROZENT' | translate}}"
                       tooltip-placement="top-left"
                       required>
            </div>
        </div>
        <div ng-if="vm.kind.availableWochenplaene.length > 1" class="row form-row">
            <div class="col-md-12">
                <select class="form-control"
                        uib-tooltip="{{'EXTERNAL_ANMELDUNG.CONFIG.SELECT_WOCHENPLAN' | translate}}"
                        tooltip-placement="top-left"
                        ng-model="vm.kind.selectedWochenplan"
                        ng-change="vm.wochenplanChanged()"
                        ng-options="wochenplan as (wochenplan.nameTranslation | dvbI18n) for wochenplan in vm.kind.availableWochenplaene"
                        ng-disabled="!vm.kind.availableWochenplaene || vm.kind.availableWochenplaene.length < 1"
                        required>
                    <option ng-value="null"
                            data-translate="EXTERNAL_ANMELDUNG.CONFIG.SELECT_WOCHENPLAN"
                            selected
                            disabled>
                    </option>
                </select>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-12">
                <dvb-wochenplan ng-if="vm.wochenplanModel"
                                distinct-betreuungs-zeitraeume="vm.wochenplanModel.model.distinctZeitraeume"
                                week-days="vm.wochenplanModel.model.enabledDays"
                                interactive="true"
                                show-null-value-as-plain="true"
                                on-zeitraum-feld-clicked="vm.wochenplanModel.model.toggleZeitraumFeld(zeitraumFeld)"
                                zeitraum-felder="vm.wochenplanModel.model.zeitraumFelder"
                                show-zeiten="vm.wochenplanModel.wochenplan.displayType === 'ZEITRAUM_TIMES'"
                                show-names="vm.wochenplanModel.wochenplan.displayType === 'ZEITRAUM_NAMES'"
                                show-betreuungs-zeitraum-name="vm.wochenplanModel.model.wochenplan.showBetreuungsZeitraumName">
                </dvb-wochenplan>
                <p ng-if="vm.wochenplanModel.wochenplan.bemerkungTranslation.hasTranslations()"
                   class="normal-abstand-top"
                   ng-bind="vm.wochenplanModel.wochenplan.bemerkungTranslation | dvbI18n">
                </p>
            </div>
        </div>
        <div class="form-row">
            <div class="row">
                <div class="col-md-6">
                    <div ng-if="vm.setup.config.showSubventionierterPlatz" class="checkbox">
                        <label>
                            <input type="checkbox"
                                   value="true"
                                   ng-model="vm.kind.subventionierterPlatz"
                                   ng-required="vm.setup.config && !vm.kind.isPlatzArtSelected()">
                            <span ng-bind="(vm.setup.config.subventionierterPlatzTranslation | dvbI18n) || ('EXTERNAL_ANMELDUNG.SUBVENTIONIERTER_PLATZ' | translate)"></span>
                        </label>
                    </div>
                    <div ng-if="vm.setup.config.showPrivaterPlatz" class="checkbox">
                        <label>
                            <input type="checkbox"
                                   value="true"
                                   ng-model="vm.kind.privaterPlatz"
                                   ng-required="vm.setup.config && !vm.kind.isPlatzArtSelected()">
                            <span ng-bind="(vm.setup.config.privaterPlatzTranslation | dvbI18n) || ('COMMON.PRIVATER_PLATZ' | translate)"></span>
                        </label>
                    </div>
                    <div class="checkbox" ng-repeat="platzart in vm.setup.platzarten">
                        <label>
                            <input type="checkbox"
                                   ng-model="vm.kind.platzartSelection[platzart.id]"
                                   ng-required="vm.setup.config && !vm.kind.isPlatzArtSelected()">
                            <span ng-bind="(platzart.externalAnmeldungName | dvbI18n) || platzart.name"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-6" ng-if="vm.hasPlatzarten">
                    <span class="error-message"
                          ng-show="vm.setup.config && !vm.kind.isPlatzArtSelected()"
                          data-translate="ERRORS.ERR_REQUIRED_PLATZ_DYNAMIC"
                          data-translate-value-plaetze="{{vm.platzartenTranslated | dvbEnumeration}}"></span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.kind.gewuenschteBetreuungAb"
                                           ng-attr-placeholder="{{'KIND.EINTRITTSDATUM_AB' | translate}}"
                                           required>
                </dvb-datepicker-text-field>
            </div>
        </div>
    </div>
</div>
