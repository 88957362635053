/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {DokumentLayout, Translation} from '@dv/kitadmin/models';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {DokumentlayoutService} from '../../service/dokumentlayoutService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-dokumentlayout-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbDokumentlayoutErstellen implements angular.IController {
    public static $inject: readonly string[] = [
        'dokumentlayoutService',
        'errorService',
        'dvbStateService',
        '$state',
    ];

    public isLoading: boolean = false;
    public dokumentLayout: DokumentLayout;

    public constructor(
        private dokumentlayoutService: DokumentlayoutService,
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
        private $state: StateService,
    ) {
        this.dokumentLayout = new DokumentLayout();
        this.dokumentLayout.steuerbescheinigungAlternativ = new Translation();
        this.dokumentLayout.steuerbescheinigungBemerkung = new Translation();
    }

    public submit(form: angular.IFormController): void {
        const dokumentLayoutWithDefaults = angular.copy(this.dokumentLayout);
        dokumentLayoutWithDefaults.applyDefaults();

        const valid = form.$valid && dokumentLayoutWithDefaults.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        this.isLoading = true;

        this.dokumentlayoutService.createDokumentLayout(dokumentLayoutWithDefaults)
            .then((response: any) => this.$state.go('base.dokumentlayout',
                {id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response)}))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public onCancel(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }
}

componentConfig.controller = DvbDokumentlayoutErstellen;
angular.module('kitAdmin').component('dvbDokumentlayoutErstellen', componentConfig);
