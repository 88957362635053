<div class="dvb-pensum">
    <input type="number"
           class="form-control"
           ng-class="{'input-sm': vm.smallInputs}"
           ng-model="vm.pensum.von"
           ng-attr-placeholder="{{'COMMON.PENSUM_IN_SYMBOL_MF' | translate:{type: vm.pensum.type}:'messageformat'}}"
           uib-tooltip="{{'COMMON.PENSUM_IN_SYMBOL_MF' | translate: {type: vm.pensum.type}:'messageformat' }}"
           tooltip-placement="top-left"
           ng-attr-min="vm.minVal">
    <span class="form-linker" ng-class="{'small': vm.smallInputs}" data-translate="COMMON.BIS"></span>
    <input type="number"
           class="form-control"
           ng-class="{'input-sm': vm.smallInputs}"
           ng-model="vm.pensum.bis"
           ng-attr-placeholder="{{'COMMON.PENSUM_IN_SYMBOL_MF' | translate:{type: vm.pensum.type}:'messageformat'}}"
           uib-tooltip="{{'COMMON.PENSUM_IN_SYMBOL_MF' | translate: {type: vm.pensum.type}:'messageformat' }}"
           tooltip-placement="top-left"
           ng-attr-min="vm.minVal">
</div>
