/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import type {FunctionType, Persisted} from '@dv/shared/code';
import {DvbUtil, EntitySelection, LoadingContext, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktpersonen: '<',
        showCancelBtn: '<?',
        onSubmit: '&',
        onCancel: '&?',
        allSelected: '<',
    },
    template: require('./dvb-communication-ready-to-send.html'),
    controllerAs: 'vm',
};

export class DvbCommunicationReadyToSend extends EntitySelection<Kontaktperson> implements angular.IController {

    public static override $inject: readonly string[] = [];

    public kontaktpersonen: Persisted<Kontaktperson>[] = [];
    public showCancelBtn: boolean = false;
    public onSubmit?: (val: { context: LoadingContext; selected: Kontaktperson[] }) => any;
    public onCancel?: FunctionType;
    public allSelected!: boolean;

    public readonly loadingContext: LoadingContext = new LoadingContext();

    public $onInit(): void {
        if (this.allSelected) {
            this.selectAll(this.kontaktpersonen);
        }
    }

    public submit(): void {
        const selected = this.getSelected(this.kontaktpersonen);
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        if (TypeUtil.isFunction(this.onSubmit)) {
            this.onSubmit({context: this.loadingContext, selected: this.getSelected(this.kontaktpersonen)});
        }
    }

    public cancel(): void {
        if (this.showCancelBtn && TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }
}

componentConfig.controller = DvbCommunicationReadyToSend;
angular.module('kitAdmin').component('dvbCommunicationReadyToSend', componentConfig);
