/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {AbstractPagesController, ClientNotification} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {NotificationService} from '../../../common/service/notification/notificationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-notifications.html'),
    controllerAs: 'vm',
};

export class DvbNotifications extends AbstractPagesController<ClientNotification> implements angular.IController {
    public static override $inject: readonly string[] = ['notificationService', '$state', 'errorService', '$q'];

    public newNotification: ClientNotification = new ClientNotification();
    public newNotificationTime: moment.Moment | null = null;

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly notificationService: NotificationService,
        private readonly $state: StateService,
        private readonly errorService: ErrorService,
        private readonly $q: angular.IQService,
    ) {
        super();
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const config = {timeout: this.timeout.promise};

        this.notificationService.getNotifications(config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }

    public create(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return;
        }

        // apply time to newNotification
        if (this.newNotificationTime) {
            this.newNotification.dueDate!.set('hour', this.newNotificationTime.get('hour'));
            this.newNotification.dueDate!.set('minute', this.newNotificationTime.get('minute'));
        }

        this.isLoading = true;
        this.notificationService.create(this.newNotification)
            .then(() => this.$state.reload())
            .finally(() => this.isLoading = false);
    }

    public delete(notification: Persisted<ClientNotification>): void {
        this.isLoading = true;
        this.notificationService.delete(notification.id)
            .then(() => this.$state.reload())
            .finally(() => this.isLoading = false);
    }
}

componentConfig.controller = DvbNotifications;
angular.module('kitAdmin').component('dvbNotifications', componentConfig);
