<div class="modal-body">
    <h2 [translate]="'COMMUNICATION.BULK_EMAILS.HISTORY.RECIPIENTS'"></h2>

    @for (kontaktperson of kontaktpersonen; track trackById($index, kontaktperson)) {
        <div>
            <div class="small-abstand-top">
                <a [uiSref]="'base.kontaktperson.profil'"
                   [uiParams]="{kontaktpersonId: kontaktperson.id}"
                   href
                   (click)="hide()">
                    {{ kontaktperson | displayName }}
                </a>
                <p>{{ kontaktperson.email }}</p>
            </div>
        </div>
    }
</div>
<div class="modal-footer normal-abstand-top">
    <button type="button"
            class="btn btn-link"
            [translate]="'COMMON.ABBRECHEN'"
            (click)="hide()">
    </button>
</div>
