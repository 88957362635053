/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestCache} from '@dv/kitadmin/models';
import {
    BankStatement,
    BankStatementEntry,
    BankStatementEntryFilter,
    BankStatementImport,
    Rechnung,
    TempBlob,
} from '@dv/kitadmin/models';
import type {NoContent, PageContainer, RestInclude, RestLimited, RestPaginated} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, isPresent} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {UserSettingsStore} from '../../cache/service/cache/userSettingsStore';
import {RechnungenFilter} from '../../filter/RechnungenFilter';

export class BankStatementService {
    public static $inject: readonly string[] = ['userSettingsStore', '$http', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/faktura/bankstatements`;
    private static readonly ITEMS_PER_PAGE: number = 10;

    public constructor(
        private userSettingsStore: UserSettingsStore,
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) {
    }

    public getBankStatements(
        params?: RestLimited & RestPaginated,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<BankStatement>> {

        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return DvbRestUtilAngularJS.getPagedItems(BankStatementService.BASE_URL, BankStatement, matrixParams, config);
    }

    public getEntries(
        params?: RestLimited & RestPaginated & { states: string[] },
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<BankStatementEntry>> {

        const url = `${BankStatementService.BASE_URL}/entries`;
        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return DvbRestUtilAngularJS.getPagedItems(url, BankStatementEntry, matrixParams, config);
    }

    public autoAssignEntries(
        params?: RestLimited,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<BankStatementEntry>> {

        const url = `${BankStatementService.BASE_URL}/entries/assign`;
        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return DvbRestUtilAngularJS.postAndGetPagedItems(url, undefined, BankStatementEntry, matrixParams, config);
    }

    public getBankStatement(bankStatementId: string, params?: RestCache): angular.IPromise<BankStatement | null> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(
            BankStatementService.BASE_URL, BankStatement, bankStatementId, params,
        );
    }

    public deleteBankStatement(bankStatementId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${BankStatementService.BASE_URL}/${encodeURIComponent(bankStatementId)}`);
    }

    public getBankStatementForIds(bankStatementIds: string[] | string): angular.IPromise<BankStatement []> {
        const url = `${BankStatementService.BASE_URL}/bankStatementIds`;
        const matrixParams: any = {};
        matrixParams.bankStatementIds = [];
        if (typeof bankStatementIds !== 'string') {
            bankStatementIds.forEach(bankStatementId => matrixParams.bankStatementIds.push(bankStatementId));
        } else {
            matrixParams.bankStatementIds.push(bankStatementIds);
        }

        return DvbRestUtilAngularJS.getModelsArray(url, BankStatement, 'bankStatements', matrixParams);
    }

    public downloadBankStatement(bankStatementId: string): angular.IPromise<TempBlob> {
        const deferred = this.$q.defer<TempBlob>();
        const url = `${BankStatementService.BASE_URL}/${encodeURIComponent(bankStatementId)}/download`;

        this.$http.post(url, {}).then(response => {
            deferred.resolve(TempBlob.apiResponseTransformer(response.data));
        }).catch(error => {
            deferred.reject(error);
        });

        return deferred.promise;
    }

    public getBankStatementEntry(
        bankStatementEntryId: string,
        params?: RestCache,
    ): angular.IPromise<BankStatementEntry | null> {
        const url = `${BankStatementService.BASE_URL}/entries`;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, BankStatementEntry, bankStatementEntryId, params);
    }

    public importKontoauszug(tempBlobIds: string[]): angular.IPromise<BankStatementImport[]> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BankStatementService.BASE_URL}/import`;
        const matrixParams: any = {};
        matrixParams.tempBlobIds = [];
        tempBlobIds.forEach(tempBlobId => matrixParams.tempBlobIds.push(tempBlobId));

        return DvbRestUtilAngularJS.getModelsArray(url, BankStatementImport, 'bankStatementImports', matrixParams);
    }

    public importDuplicates(entries: BankStatementEntry[]): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BankStatementService.BASE_URL}/importDuplicates`;

        return this.$http.post(url, {entries: entries.map(entry => entry.toRestObject())});
    }

    public getMatches(
        bankStatementEntryId: string,
        type: string,
        params?: RestInclude,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<Rechnung>> {
        const url = `${BankStatementService.BASE_URL}/entries/` +
            `${encodeURIComponent(bankStatementEntryId)}/matches/${encodeURIComponent(type)}`;

        return DvbRestUtilAngularJS.getPagedItems(url, Rechnung, params, config);
    }

    public assignZahlung(bankStatementEntryId: string, rechnungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BankStatementService.BASE_URL}/entries/` +
            `${encodeURIComponent(bankStatementEntryId)}/assign/${encodeURIComponent(rechnungId)}`;

        return this.$http.post(url, {});
    }

    public ignoreZahlung(bankStatementEntryId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BankStatementService.BASE_URL}/entries/${encodeURIComponent(bankStatementEntryId)}/ignore`;

        return this.$http.post(url, {});
    }

    public resetZahlung(bankStatementEntryId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${BankStatementService.BASE_URL}/entries/${encodeURIComponent(bankStatementEntryId)}/reset`;

        return this.$http.post(url, {});
    }

    public getBankStatementEntryFilter(): BankStatementEntryFilter {
        return this.userSettingsStore.getOrDefault('BANK_STATEMENT_ENTRY_FILTER', () => new BankStatementEntryFilter());
    }

    public setBankStatementEntryFilter(filterParam: BankStatementEntryFilter): void {
        this.userSettingsStore.put('BANK_STATEMENT_ENTRY_FILTER', filterParam);
    }

    public getRechnungenFilter(): RechnungenFilter {
        return this.userSettingsStore.getOrDefault('BANK_STATEMENT_ENTRY_ZUWEISEN_RECHNUNGEN_FILTER',
            () => new RechnungenFilter());
    }

    public setRechnungenFilter(filterParam: RechnungenFilter): void {
        this.userSettingsStore.put('BANK_STATEMENT_ENTRY_ZUWEISEN_RECHNUNGEN_FILTER', filterParam);
    }

    public setItemsPerPage(itemsPerPage: number): void {
        this.userSettingsStore.put('BANK_STATEMENT_ENTRY_ITEMS_PER_PAGE', itemsPerPage);
    }

    public getItemsPerPage(): number {
        return this.userSettingsStore.getOrDefault('BANK_STATEMENT_ENTRY_ITEMS_PER_PAGE',
            () => BankStatementService.ITEMS_PER_PAGE);
    }

    public setItemsPerPageAlle(itemsPerPage: number): void {
        this.userSettingsStore.put('BANK_STATEMENT_ENTRY_ZUWEISEN_ITEMS_PER_PAGE', itemsPerPage);
    }

    public getItemsPerPageAlle(): number {
        return this.userSettingsStore.getOrDefault('BANK_STATEMENT_ENTRY_ZUWEISEN_ITEMS_PER_PAGE',
            () => BankStatementService.ITEMS_PER_PAGE);
    }

    public fakeStatement(
        type: string,
        filter: RechnungenFilter,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<TempBlob | NoContent> {
        const url = `${BankStatementService.BASE_URL}/fake/${encodeURIComponent(type)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, filter.toRestObject(), config);
    }

    public checkZuweisung(entry: BankStatementEntry, rechnung: Rechnung): boolean {
        const betrag = checkPresent(entry.betrag);
        const ausstehenderBetrag = checkPresent(rechnung.ausstehenderBetrag);

        return betrag <= ausstehenderBetrag;
    }

    public calcAusstehenderBetrag(entry: BankStatementEntry, rechnung: Rechnung): number {
        const betrag = checkPresent(entry.betrag);
        const ausstehenderBetrag = checkPresent(rechnung.ausstehenderBetrag);

        return ausstehenderBetrag - betrag;
    }
}
