/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {isNullish, isPresent} from '@dv/shared/code';
import {AusbildungsPersonalBedarf} from './AusbildungsPersonalBedarf';

export type FromType = 'BOTH' | 'PLAETZE' | 'KINDER' | 'NONE';

export class BetreuungsSchluesselRegelBedarf implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public _fromKinder: number | null = null,
        public _fromPlaetze: number | null = null,
        public fromType: FromType = 'NONE',
        public ausbildungsPersonalBedarf: AusbildungsPersonalBedarf[] = [],
    ) {
        this.updateFromType();
    }

    public get fromKinder(): number | null {
        return this._fromKinder;
    }

    public set fromKinder(value: number | null) {
        this._fromKinder = value;
        this.updateFromType();
    }

    public get fromPlaetze(): number | null {
        return this._fromPlaetze;
    }

    public set fromPlaetze(value: number | null) {
        this._fromPlaetze = value;
        this.updateFromType();
    }

    public static apiResponseTransformer(data: any): BetreuungsSchluesselRegelBedarf {
        const result = new BetreuungsSchluesselRegelBedarf(data.id, data.fromKinder, data.fromPlaetze);

        if (Array.isArray(data.ausbildungsPersonalBedarf)) {
            result.ausbildungsPersonalBedarf = data.ausbildungsPersonalBedarf.map((bedarf: any) =>
                AusbildungsPersonalBedarf.apiResponseTransformer(bedarf));
        }

        return result;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            fromKinder: this._fromKinder,
            fromPlaetze: this._fromPlaetze,
            ausbildungsPersonalBedarf: this.ausbildungsPersonalBedarf.map(bedarf => bedarf.toRestObject()),
        };
    }

    public isValid(): boolean {
        return this.ausbildungsPersonalBedarf.length > 0 &&
            this.ausbildungsPersonalBedarf.every(bedarf => bedarf.isValid());
    }

    private updateFromType(): void {
        this.fromType = this.getFromType();
    }

    private getFromType(): FromType {
        if (isPresent(this._fromKinder) && isPresent(this._fromPlaetze)) {
            return 'BOTH';
        }
        if (isPresent(this._fromKinder) && isNullish(this._fromPlaetze)) {
            return 'KINDER';
        }
        if (isNullish(this._fromKinder) && isPresent(this._fromPlaetze)) {
            return 'PLAETZE';
        }

        return 'NONE';
    }
}
