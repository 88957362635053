<div class="dvb-transaktion-zuweisen-alle">

    <dvb-transaktion-zuweisen-filter filter="vm.filter"
                                     bank-statement-entry="vm.bankStatementEntry"
                                     on-update="vm.updatePage(1)">
    </dvb-transaktion-zuweisen-filter>

    <div class="row" dvb-accordions>
        <div class="col-md-7">
            <dvb-pages items="vm.pageContainer.items"
                       total-items="vm.pageContainer.count"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'COMMON.RECHNUNG.MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                <page-items>
                    <dvb-rechnungen-list rechnungen="vm.pageContainer.items"
                                         mode="vm.mode">
                    </dvb-rechnungen-list>
                </page-items>
            </dvb-pages>
        </div>
    </div>

    <!-- Confirm dialog view -->
    <div ui-view></div>

</div>
