/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbError} from '@dv/shared/code';
import {KibonExchangeBetreuung} from './KibonExchangeBetreuung';
import {KibonExchangeBetreuungContext} from './KibonExchangeBetreuungContext';
import type {KibonProcessingConstraints} from './KibonProcessingConstraints';

export class KibonGenerationContextResult {

    public constructor(
        public ctx: KibonExchangeBetreuungContext,
        public violatedConstraints: KibonProcessingConstraints[],
        public generatedBetreuung: KibonExchangeBetreuung | null,
    ) {
    }

    public static apiResponseTransformer(data: any): KibonGenerationContextResult {
        return new KibonGenerationContextResult(
            KibonExchangeBetreuungContext.apiResponseTransformer(data.ctx),
            data.violatedConstraints,
            data.generatedBetreuung ? KibonExchangeBetreuung.apiResponseTransformer(data.generatedBetreuung) : null,
        );
    }

    public isFailed(): boolean {
        return this.violatedConstraints.length > 0;
    }

    public toDvbError(): DvbError {
        const args: Record<string, string | boolean> = {
            refNr: this.ctx.refNr,
        };
        this.violatedConstraints.forEach(val => {
            args[val] = true;
        });

        return DvbError.validationError('KIBON.MUTATION.KIBON_GENERATION_VIOLATION_MF', args, true, true);
    }
}
