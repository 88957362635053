<div class="row">
    <div class="col-md-7 big-abstand-bottom" dvb-accordions>
        <p data-translate="COMMUNICATION.NONE_AVAILABLE" ng-if="vm.communicationTypes.length === 0"></p>

        <dvb-accordion ng-repeat="comm in vm.communicationTypes"
                       on-select="selected && vm.goTo(comm.state)"
                       is-selected="comm.state === vm.getCurrentStateName()"
                       is-first="$first">
            <panel-header>
                <p data-translate="{{comm.titleKey}}"></p>
            </panel-header>
            <panel-content>
                <div class="floating-text-list">
                    <h3 class="small-abstand-top" data-translate="{{comm.purposeKey}}"></h3>
                </div>

                <div class="normal-abstand-top floating-text-list">
                    <div class="small-abstand-top" data-translate="{{comm.structureKey}}"></div>
                </div>
            </panel-content>
        </dvb-accordion>
    </div>
    <div class="col-md-4 col-md-offset-1">
        <ui-view></ui-view>
    </div>
</div>
