/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Firma} from '@dv/kitadmin/models';
import {FirmenKontakt} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        firma: '<',
        onSubmit: '&',
        onCancel: '&',
    },
    template: require('./dvb-firmen-kontakt-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbFirmenKontaktErstellen implements angular.IController {
    public static $inject: readonly string[] = ['errorService'];
    public firma!: Firma;

    public firmenKontakt: FirmenKontakt = new FirmenKontakt();
    public onSubmit!: () => void;
    public onCancel!: () => void;

    public constructor(private readonly errorService: ErrorService) {
    }

    public submit(form: angular.IFormController): void {
        if (!form.$valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        this.firma.kontakt = this.firmenKontakt;

        if (!this.firma.hasValidKontakt()) {
            this.errorService.addValidationError('ERRORS.ERR_FIRMA_REQUIES_ADDRESS_NAME_AND_TELEFON');
        }

        this.onSubmit();
    }
}

componentConfig.controller = DvbFirmenKontaktErstellen;
angular.module('kitAdmin').component('dvbFirmenKontaktErstellen', componentConfig);
