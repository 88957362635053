/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {AuthorizationProviderService} from '@dv/shared/angular';
import {AuthStore} from '@dv/shared/angular';
import type {Nullish, UnparsedPermission} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';
import type {Observable} from 'rxjs';
import {BehaviorSubject, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthorizationProviderJSAdapterService implements AuthorizationProviderService {

    private readonly loginSuccessTrigger$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private readonly authStore: AuthStore,
    ) {
    }

    public triggerLoginSuccess(): void {
        this.loginSuccessTrigger$.next();
    }

    public hasRole$(role: UserRole | Nullish): Observable<boolean> {
        return this.loginSuccessTrigger$.pipe(
            map(() => isPresent(role) && this.authStore.hasRole(role)),
        );
    }

    public hasPermission$(permissions: UnparsedPermission | Nullish): Observable<boolean> {
        return this.loginSuccessTrigger$.pipe(
            map(() => isPresent(permissions) && this.authStore.isPermitted(permissions)),
        );
    }
}
