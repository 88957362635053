/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gebuehr, Kind, Kontaktperson, Rechnung} from '@dv/kitadmin/models';
import {KontaktpersonZahlungType} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Persisted} from '@dv/shared/code';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {firstValueFrom} from 'rxjs';
import type {FakturaService} from '../common/service/rest/fakturaService';
import type {KontaktpersonenZahlungService} from '../common/service/rest/kontaktpersonen-zahlung.service';
import type {KontaktpersonService} from '../common/service/rest/kontaktpersonService';

/* eslint-disable sonarjs/no-duplicate-string */

export const BASE_STATE = {
    name: 'base.kontaktperson',
    url: '/kontaktperson/{kontaktpersonId:[0-9]+}',
    abstract: true,
    component: 'dvbKontaktpersonMain',
    resolve: {
        kontaktperson: [
            'kontaktpersonService', '$transition$',
            (
                kontaktpersonService: KontaktpersonService,
                $transition$: Transition,
            ): angular.IPromise<Kontaktperson | null> =>
                kontaktpersonService.get($transition$.params().kontaktpersonId, {
                    includes: '(kontakte.fields(kindId,relationship),adressen,customFieldValues)',
                }),
        ],
        $title: ['kontaktperson', (kontaktperson: Kontaktperson): string => kontaktperson.getDisplayName()],
    },
    data: {
        permission: PERMISSION.KITA.VIEW_ANY,
    },
} satisfies Ng1StateDeclaration;

export const PROFIL_STATE = {
    name: `${BASE_STATE.name}.profil`,
    url: '',
    component: 'dvbKontaktpersonOverview',
    resolve: {
        kinder: [
            'kontaktpersonService', '$transition$',
            (kontaktpersonService: KontaktpersonService, $transition$: Transition): angular.IPromise<Kind[]> =>
                kontaktpersonService.getKinder($transition$.params().kontaktpersonId),
        ],
    },
} satisfies Ng1StateDeclaration;

export const KONTAKT_STATE = {
    name: `${BASE_STATE.name}.kontakt`,
    url: '/kontakt',
    component: 'dvbKontaktpersonKontakt',
} satisfies Ng1StateDeclaration;

export const FAKTURA_STATE = {
    name: `${BASE_STATE.name}.faktura`,
    url: '/faktura/:year?{rechnungId:[0-9]+}',
    params: {
        year: '',
    },
    component: 'dvbKontaktpersonFaktura',
    resolve: {
        rechnungId: ['$transition$', ($transition$: Transition): EntityId => $transition$.params().rechnungId],
        year: [
            '$transition$',
            ($transition$: Transition): number | null => parseInt($transition$.params().year, 10) || null,
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL_ANY],
    },
} satisfies Ng1StateDeclaration;

export const VORAUSZAHLUNG_STATE = {
    name: `${BASE_STATE.name}.vorauszahlung`,
    url: '/vorauszahlung?{gebuehrId:[0-9]+}:kitaName',
    component: 'dvbKontaktpersonFakturaZahlung',
    resolve: {
        gebuehrId: ['$transition$', ($transition$: Transition): EntityId => $transition$.params().gebuehrId],
        kitaName: ['$transition$', ($transition$: Transition): string | null => $transition$.params().kitaName],
        dtype: [
            '$transition$',
            (_$transition$: Transition): KontaktpersonZahlungType => KontaktpersonZahlungType.VORAUSZAHLUNG,
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng1StateDeclaration;

export const RUECKERSTATTUNG_STATE = {
    name: `${BASE_STATE.name}.rueckerstattung`,
    url: '/rueckerstattung?{gebuehrId:[0-9]+}:kitaName',
    component: 'dvbKontaktpersonFakturaZahlung',
    resolve: {
        gebuehrId: ['$transition$', ($transition$: Transition): EntityId => $transition$.params().gebuehrId],
        kitaName: ['$transition$', ($transition$: Transition): string | null => $transition$.params().kitaName],
        dtype: [
            '$transition$',
            (_$transition$: Transition): KontaktpersonZahlungType => KontaktpersonZahlungType.RUECKERSTATTUNG,
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng1StateDeclaration;

export const ABSTRACT_DEBOT_STATE = {
    name: `${BASE_STATE.name}.depot`,
    url: '/depot/{depotId:[0-9]+}',
    component: 'uiView',
    abstract: true,
    resolve: {
        depot: [
            '$transition$',
            'kontaktpersonenZahlungService',
            (
                $transition$: Transition,
                kontaktpersonenZahlungService: KontaktpersonenZahlungService,
            ): angular.IPromise<Persisted<Gebuehr>> => firstValueFrom(
                kontaktpersonenZahlungService.getGebuehr$($transition$.params().depotId),
            ),
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng1StateDeclaration;

export const DEPOT_RECHNUNG_ZUWEISEN_STATE = {
    name: `${ABSTRACT_DEBOT_STATE.name}.zuweisen`,
    url: '/zuweisen',
    component: 'dvbDepotZuweisenVorgeschlagen',
} satisfies Ng1StateDeclaration;

export const DEPOT_RECHNUNG_ZUWEISEN_CONFIRM_STATE = {
    name: `${DEPOT_RECHNUNG_ZUWEISEN_STATE.name}.confirm`,
    url: '/bestaetigen/{assigningRechnungId:[0-9]+}',
    component: 'dvbDepotZuweisenConfirm',
    resolve: {
        rechnung: [
            '$transition$',
            'fakturaService',
            ($transition$: Transition, fakturaService: FakturaService): angular.IPromise<Persisted<Rechnung>> =>
                fakturaService.getRechnung($transition$.params().assigningRechnungId),
        ],
    },
} satisfies Ng1StateDeclaration;

export const GEBUEHR_STATE = {
    name: `${BASE_STATE.name}.gebuehr`,
    url: '/gebuehr?:editMode{gebuehrId:[0-9]+}:kitaName{rechnungId:[0-9]+}',
    component: 'dvbKontaktpersonFakturaZahlung',
    resolve: {
        editMode: ['$transition$', ($transition$: Transition): boolean | null => $transition$.params().editMode],
        kitaName: ['$transition$', ($transition$: Transition): string | null => $transition$.params().kitaName],
        gebuehrId: ['$transition$', ($transition$: Transition): string | null => $transition$.params().gebuehrId],
        rechnungId: ['$transition$', ($transition$: Transition): string | null => $transition$.params().rechnungId],
        dtype: [
            '$transition$',
            (_$transition$: Transition): KontaktpersonZahlungType => KontaktpersonZahlungType.GEBUEHR,
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng1StateDeclaration;

export const OFFENE_POSTEN_STATE = {
    name: `${BASE_STATE.name}.offenePosten`,
    url: '/offeneposten/?:showAll{kontaktpersonZahlungId:[0-9]+}',
    component: 'dvbKontaktpersonOffenePosten',
    resolve: {
        showAll: ['$transition$', ($transition$: Transition): boolean => !!$transition$.params().showAll],
        selectedKontaktpersonZahlungId: [
            '$transition$',
            ($transition$: Transition): string | null => $transition$.params().kontaktpersonZahlungId,
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.FAKTURA, PERMISSION.KITA.CONTROL_ANY],
    },
} satisfies Ng1StateDeclaration;

export const COMMUNICATION_STATE = {
    name: `${BASE_STATE.name}.communication`,
    abstract: true,
    url: '/korrespondenz',
    data: {
        permission: [PERMISSION.MODULE.KOMMUNIKATION, PERMISSION.KITA.CONTROL_ANY],
    },
} satisfies Ng1StateDeclaration;

export const CORRESPONDENCE_STATE = {
    name: `${COMMUNICATION_STATE.name}.correspondence`,
    url: '/',
    component: 'dvbKontaktpersonCorrespondence',
} satisfies Ng1StateDeclaration;

export const EMAIL_STATE = {
    name: `${COMMUNICATION_STATE.name}.email`,
    url: '/email',
    component: 'dvbBulkEmailManagement',
    params: {
        kontaktpersonen: {
            dynamic: true,
            array: true,
            value: [],
        },
    },
    redirectTo: $transition$ => {
        if ($transition$.params().kontaktpersonen.length === 1) {

            // avoid endless redirection
            return Promise.resolve();
        }

        // update the dynamic parameter value with the resolved kontaktperson
        return $transition$.injector().getAsync('kontaktperson')
            .then(kontaktperson => $transition$.targetState().withParams({kontaktpersonen: [kontaktperson]}));
    },
    resolve: {
        allSelected: () => true,
        kontaktpersonen: [
            '$transition$',
            ($transition$: Transition): Kontaktperson[] | undefined => $transition$.params().kontaktpersonen,
        ],
    },
} satisfies Ng1StateDeclaration;

export const KONTAKTPERSON_STATES = {
    BASE_STATE,
    PROFIL_STATE,
    KONTAKT_STATE,
    FAKTURA_STATE,
    VORAUSZAHLUNG_STATE,
    RUECKERSTATTUNG_STATE,
    ABSTRACT_DEBOT_STATE,
    DEPOT_RECHNUNG_ZUWEISEN_STATE,
    DEPOT_RECHNUNG_ZUWEISEN_CONFIRM_STATE,
    GEBUEHR_STATE,
    OFFENE_POSTEN_STATE,
    COMMUNICATION_STATE,
    CORRESPONDENCE_STATE,
    EMAIL_STATE,
};
