/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Zahlung} from '@dv/kitadmin/models';
import {Rechnung} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {Persisted} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
    },
    template: require('./dvb-rechnung-zahlungen.html'),
    controllerAs: 'vm',
};

export class DvbRechnungZahlungen implements angular.IController {

    public static $inject: readonly string[] = ['fakturaService', 'dialogService', '$state', 'errorService'];

    public readonly rechnung!: Persisted<Rechnung>;

    public ausstehenderBetrag?: number | null = null;
    public zahlungen: Persisted<Zahlung>[] = [];
    public isLoading: boolean = false;

    public constructor(
        private readonly fakturaService: FakturaService,
        private readonly dialogService: DialogService,
        private readonly $state: StateService,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        if (this.rechnung.disabledRechnungsKonfiguration) {
            this.ausstehenderBetrag = 0;

            return;
        }

        this.isLoading = true;

        this.fakturaService.getZahlungen(this.rechnung.id).then(response => {
            this.ausstehenderBetrag = response.ausstehenderBetrag;
            this.zahlungen = response.zahlungen;

            // If we discover that rechnungsUeberschuss is no longer present, patch the rechnung.
            if (this.rechnung.status !== Rechnung.STATUS.STORNIERT) {
                this.rechnung.rechnungsUeberschuss =
                    TypeUtil.isNumber(this.ausstehenderBetrag) ? this.ausstehenderBetrag < 0 : false;
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public storniere(zahlung: Persisted<Zahlung>): void {
        if (zahlung.dtype === RechnungsKonfigurationType.SMALLINVOICE) {
            this.errorService.addValidationError('ERRORS.SI_ERR_ZAHLUNG_STORNIEREN');

            return;
        }

        const confirm = (): Observable<unknown> => from(this.fakturaService.storniereZahlung(zahlung.id))
            .pipe(take(1), tap(() =>
                // need to refresh rechnungs status
                this.$state.reload(),
            ));

        this.dialogService.openConfirmDialog({
            title: 'FAKTURA.ZAHLUNG_STORNIEREN_CONFIRM',
            confirmActionText: 'FAKTURA.STORNIEREN',
            confirm,
        });
    }
}

componentConfig.controller = DvbRechnungZahlungen;
angular.module('kitAdmin').component('dvbRechnungZahlungen', componentConfig);
