/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrtId} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {NoContent, RestInclude, RestLimited} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type {Observable} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {KibonExchangeBetreuung} from '../models/KibonExchangeBetreuung';
import {KibonExchangeBetreuungRefreshJob} from '../models/KibonExchangeBetreuungRefreshJob';
import {KibonGenerationContextResult} from '../models/KibonGenerationContextResult';

const transactionTimeoutMinutes = 60;
const secondsPerMin = 60;
const milliesPerSecond = 1000;
const maxDurationInMillies = transactionTimeoutMinutes * secondsPerMin * milliesPerSecond;

export class KibonExchangeBetreuungService {
    public static $inject: readonly string[] = [
        '$http',
        'dialogService',
        '$translate',
        '$interval',
        '$q',
        '$rootScope',
    ];

    public static readonly POLLING_INTERVAL: number = 5000;
    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/kibon`;
    private static readonly POLLING_INTERVAL_COUNT: number
        = maxDurationInMillies / KibonExchangeBetreuungService.POLLING_INTERVAL;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly dialogService: DialogService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly $interval: angular.IIntervalService,
        private readonly $q: angular.IQService,
        private readonly $rootScope: angular.IRootScopeService,
    ) {
    }

    public getForKinderOrt(id: KinderOrtId, params?: RestInclude): angular.IPromise<KibonExchangeBetreuung[]> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kinderOrt/${encodeURIComponent(id)}/mutationen`;

        return DvbRestUtilAngularJS.getModelsArray(url, KibonExchangeBetreuung, 'betreuungen', params);
    }

    public sendForKinderOrt(id: KinderOrtId, betreuungen: KibonExchangeBetreuung[]): angular.IHttpPromise<void> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kinderOrt/${encodeURIComponent(id)}/mutationen`;
        const ids = betreuungen.map(b => b.id);

        return this.$http.post(url, {ids});
    }

    public getForKind(kindId: string, params?: RestInclude): angular.IPromise<KibonExchangeBetreuung[]> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kind/${encodeURIComponent(kindId)}/mutationen`;

        return DvbRestUtilAngularJS.getModelsArray(url, KibonExchangeBetreuung, 'betreuungen', params);
    }

    public sendConfirmation(
        betreuungen: KibonExchangeBetreuung[],
        sendMethod: () => Observable<unknown>,
    ): void {
        this.sendBetreuungenDialog(
            betreuungen,
            sendMethod,
            'KIBON.MUTATION.MELDEN_CONFIRM_TITLE_MF',
            'KIBON.MUTATION.MELDEN_CONFIRM_TEXT');
    }

    // eslint-disable-next-line sonarjs/no-identical-functions
    public ignoreBetreuungen(
        betreuungen: KibonExchangeBetreuung[],
        sendMethod: () => Observable<unknown>,
    ): void {
        this.sendBetreuungenDialog(
            betreuungen,
            sendMethod,
            'KIBON.MUTATION.MELDEN_IGNORE_TITLE_MF',
            'KIBON.MUTATION.MELDEN_IGNORE_TEXT');
    }

    public sendForKind(kindId: string, betreuungen: KibonExchangeBetreuung[]): angular.IHttpPromise<void> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kind/${encodeURIComponent(kindId)}/mutationen`;
        const ids = betreuungen.map(b => b.id);

        return this.$http.post(url, {ids});
    }

    public ignore(betreuungen: KibonExchangeBetreuung[]): angular.IHttpPromise<void> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/betreuungsmeldungen/ignore`;
        const ids = betreuungen.map(b => b.id);

        return this.$http.post(url, {ids});
    }

    public unIgnore(betreuungId: string): angular.IHttpPromise<void> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/betreuungsmeldungen/${encodeURIComponent(betreuungId)}/ignore`;

        return this.$http.delete(url);
    }

    public refreshKind(kindId: string, params?: RestLimited): angular.IPromise<KibonGenerationContextResult[]> {
        const matrixParams = {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const url = `${KibonExchangeBetreuungService.baseUrl}/kind/${encodeURIComponent(kindId)}/refresh`;

        return DvbRestUtilAngularJS.getPagedItems(url, KibonGenerationContextResult, matrixParams)
            .then(response => response.items);
    }

    /**
     * @return a promise with the refresh job id - can be used for #startBetreuungRefreshPolling
     */
    public refreshKinderOrt(kinderOrtId: string, params?: RestLimited): angular.IPromise<string> {
        const matrixParams = {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const encodedMatrixParams = DvbRestUtil.encodeMatrixParams(matrixParams);
        const url = `${KibonExchangeBetreuungService.baseUrl}/kinderOrt/${encodeURIComponent(kinderOrtId)}` +
            `/refresh${encodedMatrixParams}`;

        return this.$http.get(url)
            .then(response => DvbRestUtilAngularJS.parseEntityIdFromResponse(response));
    }

    public getRefreshed(
        kinderOrtId: string,
        kindId: string,
        params?: RestInclude,
    ): angular.IPromise<KibonExchangeBetreuung[]> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kinderOrt/${encodeURIComponent(kinderOrtId)}/refreshed/${
            encodeURIComponent(kindId)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, KibonExchangeBetreuung, 'betreuungen', params);
    }

    public findRunningRefreshJob(kinderOrtId: string): angular.IPromise<KibonExchangeBetreuungRefreshJob | NoContent> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kinderOrt/${encodeURIComponent(kinderOrtId)}/refresh/running`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KibonExchangeBetreuungRefreshJob);
    }

    /**
     * Use the notify handler of the returned promise to get the current instance of KibonExchangeBetreuungRefreshJob
     */
    public startBetreuungRefreshPolling(
        kinderOrtId: string,
        jobId: string,
    ): angular.IPromise<unknown> {
        const url = `${KibonExchangeBetreuungService.baseUrl}/kinderOrt/${encodeURIComponent(kinderOrtId)}/refresh/${
            encodeURIComponent(jobId)}`;

        const deferred = this.$q.defer();

        const interval = this.$interval(
            () => DvbRestUtilAngularJS.getModelByUrlAndParams(url, KibonExchangeBetreuungRefreshJob)
                .then(result => {
                    if (result && result.status === 'FINISHED') {
                        this.$interval.cancel(interval);
                    }

                    deferred.notify(result);
                })
                .catch(err => {
                    this.$interval.cancel(interval);
                    deferred.reject(err);
                }),
            KibonExchangeBetreuungService.POLLING_INTERVAL,
            KibonExchangeBetreuungService.POLLING_INTERVAL_COUNT,
        );

        // kill the timer
        this.$rootScope.$on('$destroy', () => {
            this.$interval.cancel(interval);
        });

        // when the timer is cancelled, we want to resolve the promise
        interval.catch(val => {
            if (val === 'canceled') {
                deferred.resolve();
            } else {
                throw val;
            }
        });

        return deferred.promise;
    }

    private sendBetreuungenDialog(
        betreuungen: KibonExchangeBetreuung[],
        sendMethod: () => Observable<unknown>,
        titleStr: string,
        subtitleStr: string,
    ): void {
        const titleParams = {count: betreuungen.length};
        const title = this.$translate.instant(titleStr, titleParams, 'messageformat');
        const subtitle = this.$translate.instant(subtitleStr);

        this.dialogService.openConfirmDialog({
            title,
            subtitle,
            confirm: () => sendMethod(),
        });
    }
}
