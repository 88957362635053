<div class="dvb-faktura-main">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{'FAKTURA.FAKTURAEINSTELLUNGEN' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="FAKTURA.FAKTURAEINSTELLUNGEN">
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.faktura.konfiguration"
                           data-translate="FAKTURA.NAV_KONFIGURATION">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.faktura.einzahlungsschein"
                           data-translate="FAKTURA.NAV_EINZAHLUNGSSCHEIN">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.faktura.konto"
                           data-translate="FAKTURA.NAV_KONTO">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->

    <div class="row">
        <div class="col-md-7" ui-view></div>
    </div>

</div>
