<div class="dvb-rechnungen">
    <dvb-page items="vm.rechnungen"
              is-loading="vm.isLoading"
              entity-name="'COMMON.RECHNUNG.MF'">

        <dvb-rechnungen-list rechnungen="vm.rechnungen"
                             mode="vm.mode"
                             pre-selected-rechnung-id="vm.preSelectedRechnungId"
                             selection="vm.selection"
                             errors="vm.errors"
                             show-versandart-badge="vm.showVersandartBadge">
        </dvb-rechnungen-list>

    </dvb-page>

    <ul class="list-inline normal-abstand-top" ng-if="vm.selection">
        <li>
            <button type="button"
                    class="btn btn-link"
                    data-translate="COMMON.ALLE_AUSWAEHLEN"
                    ng-click="vm.selectAll()">
            </button>
        </li>
        <li>
            <button type="button"
                    class="btn btn-link"
                    data-translate="COMMON.KEINE_AUSWAEHLEN"
                    ng-click="vm.unselectAll()">
            </button>
        </li>
    </ul>
</div>
