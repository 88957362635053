/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbMandantKonfiguration} from '@dv/kitadmin/models';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '=',
        konto: '=',
        konten: '<',
    },
    controllerAs: 'vm',
    template: require('./dvb-mandant-konfiguration-form.html'),
};

export class DvbMandantKonfigurationForm implements angular.IOnInit {
    public static $inject: readonly string[] = [];

    public konfiguration!: DvbMandantKonfiguration;
    public konto!: JaxKonto;
    public konten: JaxKonto[] = [];
    public isNew: boolean = false;

    public $onInit(): void {
        if (!this.konfiguration) {
            this.isNew = true;
            this.konfiguration = new DvbMandantKonfiguration();
        }
    }
}

componentConfig.controller = DvbMandantKonfigurationForm;
angular.module('kitAdmin').component('dvbMandantKonfigurationForm', componentConfig);
