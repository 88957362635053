<div class="selected-entity">
    <div class="entity truncate">
        <dv-search-result-icon [entry]="searchResultEntry"/>
        <span [translate]="'SEARCH.ENTITY_TITLE_MF'"
              [translateParams]="{text: searchResultEntry.text, entity: searchResultEntry.text}">
        </span>
    </div>
    <div class="edit">
        @if (showRemoveButton) {
            <button class="btn btn-link"
                    type="button"
                    (click)="remove.emit(searchResultEntry)"
                    [disabled]="isDisabled"
                    [translate]="'COMMON.LOESCHEN'">
            </button>
        }
    </div>
</div>
