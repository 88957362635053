<div class="dvb-kita-tarif-zuweisen dvb-form">

    <h2 data-translate="KINDERORT.NEUE_TARIF_ZUWEISUNG"></h2>

    <form name="kitaTarifZuweisenForm"
          ng-submit="vm.submit(kitaTarifZuweisenForm.$valid)"
          novalidate>

        <div class="row">
            <div class="col-xs-6">
                <dvb-datepicker-text-field ng-model="vm.kitaTarifeZeitraum.gueltigAb"
                                           required
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <dvb-kita-tarife-auswahl abrechnung-tarife="vm.kitaTarifeZeitraum.abrechnungTarife">
                </dvb-kita-tarife-auswahl>
            </div>
        </div>

        <div class="normal-abstand-top">
            <ul class="list-inline">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="KINDERORT.NEUE_TARIF_ZUWEISUNG_SPEICHERN"></span>
                    </dvb-loading-button>
                </li>
                <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.abbrechen()"></a></li>
            </ul>
        </div>

    </form>
</div>
