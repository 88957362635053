/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {UrlConfig} from '@uirouter/core';
import {BackendLocalDateParameter} from './BackendLocalDateParameter';
import {MomentDataParameter} from './MomentDataParameter';

export enum UrlParameterTypes {
    MOMENT_DATE = 'momentDate',
    BACKEND_LOCAL_DATE = 'backendLocalDate',
}

export function addCustomUrlParameterTypes(urlConfig: UrlConfig): void {
    urlConfig.type(UrlParameterTypes.MOMENT_DATE, new MomentDataParameter());
    urlConfig.type(UrlParameterTypes.BACKEND_LOCAL_DATE, new BackendLocalDateParameter());
}
