<div class="dvb-kita-tarife-verlauf-content">
    <div ng-if="!vm.editableKitaTarifeZeitraum">
        <div class="history-columns">
            <p class="history-column-left">
                <dvb-gueltigkeit entity="vm.kitaTarifeZeitraum"></dvb-gueltigkeit>
            </p>

            <div class="history-column-middle">
                <div ng-repeat="abrechnungTarif in vm.kitaTarifeZeitraum.abrechnungTarife | orderBy: 'typ.index'">
                    <div class="form-row row">
                        <div class="col-xs-12">
                            <p ng-show="abrechnungTarif.tarif">
                                <strong data-translate="TARIF.ABRECHNUNG_TYP.MF"
                                        data-translate-value-name="{{abrechnungTarif.typ.name}}"
                                        data-translate-interpolation="messageformat">
                                </strong>
                                <br>
                                <span ng-bind="abrechnungTarif.tarif.name"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <ul class="list-inline normal-abstand-top">
                <li>
                    <a href="" ng-click="vm.setEditMode(true)">
                        <span data-translate="COMMON.BEARBEITEN"></span>
                    </a>
                </li>
                <li>
                    <a href="" ng-click="vm.onDelete({kitaTarifeZeitraum: vm.kitaTarifeZeitraum})">
                        <span data-translate="COMMON.LOESCHEN"></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div ng-if="vm.editableKitaTarifeZeitraum">
        <form novalidate ng-submit="vm.submit()">
            <div class="history-columns">
                <p class="history-column-left">
                    <dvb-gueltigkeit entity="vm.kitaTarifeZeitraum"></dvb-gueltigkeit>
                </p>
                <div class="history-column-middle">
                    <dvb-kita-tarife-auswahl abrechnung-tarife="vm.editableKitaTarifeZeitraum.abrechnungTarife">
                    </dvb-kita-tarife-auswahl>
                </div>
            </div>
            <ul class="list-inline normal-abstand-top">
                <li>
                    <div class="btn-group">
                        <dvb-loading-button is-loading="vm.isLoading" type="submit">
                            <span data-translate="COMMON.SPEICHERN"></span>
                        </dvb-loading-button>
                    </div>
                </li>
                <li>
                    <a href="" ng-click="vm.setEditMode(false)">
                        <span data-translate="COMMON.ABBRECHEN"></span>
                    </a>
                </li>
            </ul>
        </form>
    </div>
</div>
