/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {PathNode, TransitionService} from '@uirouter/core';

const defaultDocumentTitle = document.title;

titleRun.$inject = ['$transitions', '$timeout'];

export function titleRun($transitions: TransitionService, $timeout: angular.ITimeoutService): void {
    $transitions.onSuccess({}, transition => {
        const pathNodes = transition.treeChanges().to;
        const title = getTitleFromNodes(pathNodes);
        const pageTitle = title ? `${title} - ${defaultDocumentTitle}` : defaultDocumentTitle;

        $timeout(() => {
            document.title = pageTitle || defaultDocumentTitle;
        });
    });
}

function getTitleFromNodes(pathNodes: PathNode[]): string {
    return pathNodes.flatMap(pathNode => pathNode.resolvables)
        .filter(resolvable => resolvable.token === '$title')
        .map(resolvable => resolvable.data)
        .filter(Boolean)
        .reverse()
        .reduce((a: string, b: string) => a ? `${a} - ${b}` : b, '');
}
