/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {CustomFieldValue} from '@dv/kitadmin/models';
import {CustomFieldValueNotNamed} from '@dv/kitadmin/models';
import type {FunctionType, ICustomFieldValue} from '@dv/shared/code';
import {ParamValue, sortCustomFieldValues, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {CustomFieldService} from '../../service/rest/customFieldService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        customFieldValues: '<',
        autoSave: '<?',
        clickToEdit: '<?',
        small: '<?',
        onChange: '&',
    },
    template: require('./dvb-custom-field-values.html'),
    controllerAs: 'vm',
};

export class DvbCustomFieldValues implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['customFieldService', '$attrs'];

    public readonly customFieldValues: ICustomFieldValue[] = [];
    public autoSave?: boolean;
    public clickToEdit: boolean = true;
    public small: boolean = false;
    public onChange?: FunctionType;

    public visibleCustomFieldValues: ICustomFieldValue[] = [];
    public isDisabled: boolean = false;

    public constructor(
        private readonly customFieldService: CustomFieldService,
        private readonly $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.autoSave ??= true;

        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
            this.initVisibleCustomFieldValues();
        });
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (!onChangesObj.customFieldValues) {
            return;
        }

        this.customFieldValues.forEach(customFieldValue => {
            customFieldValue.value = customFieldValue.value ||
                new ParamValue(null, customFieldValue.customField.getParamValueType());
        });
        this.initVisibleCustomFieldValues();
    }

    public update(customFieldValue: ICustomFieldValue): void {
        if (TypeUtil.isFunction(this.onChange)) {
            this.onChange();
        }

        if (!this.autoSave) {
            return;
        }

        if (customFieldValue instanceof CustomFieldValueNotNamed) {
            // anwesenheitszeit custom fields are not saved on edit
            return;
        }

        this.customFieldService.saveValue(customFieldValue as CustomFieldValue);
    }

    private initVisibleCustomFieldValues(): void {
        // if disabled, show only custom field values with actual values
        this.visibleCustomFieldValues = this.isDisabled ?
            this.customFieldValues.filter(customFieldValue => customFieldValue.value.value !== null) :
            this.customFieldValues;

        this.visibleCustomFieldValues.sort(sortCustomFieldValues);
    }
}

componentConfig.controller = DvbCustomFieldValues;
angular.module('kitAdmin').component('dvbCustomFieldValues', componentConfig);
