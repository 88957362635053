<div class="dvb-kind-leistungen-rechnungen">
    <div ng-if="vm.isRechnungenLoading || vm.hasRechnungen">
        <h2 data-translate="KIND.RECHNUNGEN_PERIODE"
            data-translate-value-periode="{{vm.selectedStartOfMonth | amDateFormat:'MMMM YYYY'}}"></h2>

        <dvlib-spinner [show]="vm.isRechnungenLoading"></dvlib-spinner>

        <div ng-show="!vm.isRechnungenLoading">
            <div ng-repeat="kitaId in vm.kitaIds" class="normal-abstand-bottom" ng-if="vm.rechnungen[kitaId]">
                <h3 ng-bind="vm.kitaNamen[kitaId]" ng-if="vm.kitaIds.length > 1" class="small-abstand-bottom"></h3>

                <div
                    ng-repeat="rechnung in vm.rechnungen[kitaId] | orderBy: ['kontaktpersonDisplayName', 'rechnungsNummer']"
                    class="small-abstand-bottom rechnung">
                    <dvb-rechnung-preview rechnung="rechnung"></dvb-rechnung-preview>
                </div>
            </div>
        </div>
    </div>
</div>

