<div class="dvb-wochen-belegung">
    <div class="name small-abstand-bottom">
        <h3>
            <a href="" ng-click="vm.toggle()">
                <i class="fa"
                   ng-class="{'fa-chevron-right': vm.isCollapsed, 'fa-chevron-down': !vm.isCollapsed}"></i>
            </a>
            <span ng-bind="vm.name"></span>
            <span ng-bind-html="vm.icon"></span>
        </h3>
    </div>

    <div ng-class="{'collapse': vm.isFullyCollapsible && vm.isCollapsed}"
         uib-collapse="vm.isCollapsed && vm.isFullyCollapsible"
         expanding="vm.onCollapse({isCollapsed: false})"
         collapsing="vm.onCollapse({isCollapsed: true})"
         collapsed="vm.collapseComplete = true">

        <div ng-if="!vm.collapseComplete"
             ng-class="{'collapse': vm.isCollapsed}" class="small-abstand-bottom"
             uib-collapse="vm.isCollapsed && !vm.isFullyCollapsible"
             collapsed="vm.collapseComplete = true">
            <dvb-wochen-plaetze wochen-plaetze="vm.wochenPlaetze"
                                show-freie-plaetze="vm.showFreiePlaetze"
                                show-belegte-plaetze="vm.showBelegtePlaetze">
            </dvb-wochen-plaetze>

            <dvb-kita-kinder-aenderungen kita="vm.kita"
                                         gueltig-ab="vm.firstOfWeek"
                                         gruppe="vm.gruppe"
                                         dvb-pagination>
            </dvb-kita-kinder-aenderungen>
        </div>

        <dvb-wochenplan ng-if="!vm.isCollapsed || !vm.isFullyCollapsible"
                        distinct-betreuungs-zeitraeume="vm.distinctBetreuungsZeitraeume"
                        week-days="vm.weekDays"
                        interactive="vm.interactive"
                        on-zeitraum-feld-clicked="vm.onZeitraumFeldClicked({zeitraumFeld: zeitraumFeld, event: event, modifier: modifier})"
                        zeitraum-felder="vm.zeitraumFelder"
                        show-betreuungs-zeitraum-name="vm.showBetreuungsZeitraumName">
        </dvb-wochenplan>
    </div>
</div>
