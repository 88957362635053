import {ZeitraumFeld} from '@dv/kitadmin/models';
import type {ISelectableZeitraum, ITimeRange} from '@dv/shared/code';
import {checkPresent, DayOfWeek, DvbDateUtil, DvbRestUtil, TimeRange, TimeRangeUtil, Zeitraum} from '@dv/shared/code';
import moment from 'moment';
import type {DailyAnstellung} from '../anstellung/models/DailyAnstellung';
import {DynamicZeitraumFeld} from '../model/DynamicZeitraumFeld';

export class AngestellteZuweisungUtil {

    public static readonly DEFAULT_MIN_WORK_TIME =
        new TimeRange(moment('06:00', 'HH:mm', true), moment('06:00', 'HH:mm', true));
    public static readonly DEFAULT_MAX_WORK_TIME =
        new TimeRange(moment('19:00', 'HH:mm', true), moment('19:00', 'HH:mm', true));

    /**
     * Creates ZeitraumFelder based on the given DailyAnstellungen.
     */
    public static anstellungZeitenToZeitraumFelder(
        dailyAnstellungen: DailyAnstellung[],
    ): { [day: string]: ZeitraumFeld[] } {

        const timeRanges = dailyAnstellungen.flatMap(da => da.anstellungZeiten)
            .map(zeitraum => new TimeRange(
                DvbRestUtil.localeHHMMTimeToMoment(zeitraum.von),
                DvbRestUtil.localeHHMMTimeToMoment(zeitraum.bis)));

        // default min/max times
        timeRanges.push(AngestellteZuweisungUtil.DEFAULT_MIN_WORK_TIME,
            AngestellteZuweisungUtil.DEFAULT_MAX_WORK_TIME);
        const sortedZeitraeume = timeRanges.sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);

        const felderByDay: { [day: string]: ZeitraumFeld[] } = {};

        dailyAnstellungen.forEach(da => {
            const dayOfWeek = checkPresent(da.dayOfWeek);
            const dienste = da.anstellungZeiten
                .map(zeitraum => new TimeRange(
                    DvbRestUtil.localeHHMMTimeToMoment(zeitraum.von),
                    DvbRestUtil.localeHHMMTimeToMoment(zeitraum.bis)))
                .sort(TimeRangeUtil.TIME_RANGE_COMPARATOR);
            const mergedZeitraeume = TimeRangeUtil.mergeTimeRanges(dienste)
                .map(zeitraum =>
                    AngestellteZuweisungUtil.selectableZeitraumToDynamicZeitraumFeld(zeitraum, dayOfWeek));

            this.addEnclosingSpacerZeitraeume(mergedZeitraeume, sortedZeitraeume, dayOfWeek);
            felderByDay[dayOfWeek] = mergedZeitraeume;

            if (da.nichtVerfuegbar) {
                felderByDay[dayOfWeek].forEach(zf => {
                    zf.cssClass = 'nicht-verfuegbar';
                });
            }
        });

        // empty felder for empty days
        Object.values(DayOfWeek).forEach(day => {
            if (!felderByDay[day]) {
                const fullDaySpacer = new ZeitraumFeld(
                    new Zeitraum(
                        moment(AngestellteZuweisungUtil.DEFAULT_MIN_WORK_TIME.von),
                        moment(AngestellteZuweisungUtil.DEFAULT_MAX_WORK_TIME.bis),
                    ),
                    day);

                felderByDay[day] = [fullDaySpacer];
            }
        });

        return felderByDay;
    }

    private static addEnclosingSpacerZeitraeume(
        mergedZeitraeume: DynamicZeitraumFeld[],
        sortedAvailableZeitraeume: ITimeRange[],
        dayOfWeek: DayOfWeek,
    ): void {

        const lastDienst = sortedAvailableZeitraeume[sortedAvailableZeitraeume.length - 1];

        // spacer for whole day
        if (mergedZeitraeume.length === 0) {
            const feld = this.createFullDaySpacer(sortedAvailableZeitraeume, dayOfWeek);
            mergedZeitraeume.push(feld);

            return;
        }

        // spacer before first existing zeitraum
        if (!DvbDateUtil.isTimeEqual(mergedZeitraeume[0].zeitraum.von!, sortedAvailableZeitraeume[0].von!)) {
            const feld = new DynamicZeitraumFeld(
                new Zeitraum(moment(sortedAvailableZeitraeume[0].von), moment(mergedZeitraeume[0].zeitraum.von)),
                dayOfWeek);
            mergedZeitraeume.unshift(feld);
        }

        // spacer after last existing zeitraum
        const lastMerged = mergedZeitraeume[mergedZeitraeume.length - 1];
        if (!DvbDateUtil.isTimeEqual(lastMerged.zeitraum.bis!, lastDienst.bis!)) {
            const feld = new DynamicZeitraumFeld(
                new Zeitraum(moment(lastMerged.zeitraum.bis), moment(lastDienst.bis)),
                dayOfWeek);
            mergedZeitraeume.push(feld);
        }
    }

    private static createFullDaySpacer(
        sortedDienste: ITimeRange[],
        dayOfWeek: DayOfWeek,
    ): DynamicZeitraumFeld {
        return new DynamicZeitraumFeld(
            new Zeitraum(moment(sortedDienste[0].von), moment(sortedDienste[sortedDienste.length - 1].bis)),
            dayOfWeek);
    }

    private static selectableZeitraumToDynamicZeitraumFeld(
        zeitraum: ISelectableZeitraum,
        dayOfWeek: DayOfWeek,
    ): DynamicZeitraumFeld {
        const result = new DynamicZeitraumFeld(zeitraum, dayOfWeek);
        result.selected = !!zeitraum.selected;

        return result;
    }
}
