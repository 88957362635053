/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Mandant} from '@dv/kitadmin/models';
import {SubscriptionModel} from '@dv/shared/code';
import angular from 'angular';
import type {AuthorisationService} from '../../../authorisation/service/authorisation.service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        mandant: '<',
        isDisabled: '<',
        onChange: '&',
    },
    template: require('./dvb-subscription-model.html'),
    controllerAs: 'vm',
};

export class DvbSubscriptionModel implements angular.IController {

    public static $inject: readonly string[] = ['authorisationService'];

    public mandant!: Mandant;
    public isDisabled: boolean = false;

    public subscriptionModels: typeof SubscriptionModel = SubscriptionModel;
    public isMandantAdmin: boolean = false;

    public constructor(
        private readonly authorisationService: AuthorisationService,
    ) {
    }

    public $onInit(): void {
        this.isMandantAdmin = this.authorisationService.isMandantAdmin();
    }
}

componentConfig.controller = DvbSubscriptionModel;
angular.module('kitAdmin').component('dvbSubscriptionModel', componentConfig);
