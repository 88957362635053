/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Termin} from '@dv/kitadmin/models';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {SearchResultEntry} from '@dv/shared/code';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        termin: '<',
        angestellte: '<',
    },
    template: require('./dvb-termin-header.html'),
    controllerAs: 'vm',
};

export class DvbTerminHeader implements angular.IController {
    public static $inject: readonly string[] = [];

    public termin!: Termin;
    public angestellte: SearchResultEntry[] = [];

    public getAngestellteDisplayName(angestellteId: string): string {
        const found = this.angestellte.find(entry => entry.id === angestellteId);

        return isPresent(found) ? found.text : '';
    }
}

componentConfig.controller = DvbTerminHeader;
angular.module('kitAdmin').component('dvbTerminHeader', componentConfig);
