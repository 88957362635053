/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ChartColor, ChartDataSets} from 'chart.js';

export class DataSet implements ChartDataSets {
    public data: any[]; // Typings von ChartPoint unterstützten Moment leider nicht, deshalb any[] statt ChartPoint[].
    public fill: boolean;
    public steppedLine: 'after';
    public borderWidth: number;
    public label: string;
    public borderColor: ChartColor;

    public constructor(label: string, borderColor: ChartColor) {
        this.data = [];
        this.fill = false;
        this.steppedLine = 'after';
        this.borderWidth = 1;
        this.label = label;
        this.borderColor = borderColor;
    }
}
