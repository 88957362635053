/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import {ANY_STATE} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

export function clearErrorsHook($transitions: TransitionService): void {
    $transitions.onBefore(ANY_STATE, clearErrors, {priority: OnBeforePriorities.CLEAR_ERRORS});
}

function clearErrors(transition: Transition): HookResult {
    const errorService = transition.injector().get<ErrorService>('errorService');

    errorService.clearAll();
}
