/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        header: 'panelHeader',
        content: 'panelContent',
    },
    bindings: {
        isFirst: '<',
        isLast: '<?',
        isCollapsed: '<?',
        limitedEntity: '<?',
        expandAb: '<?',
        expandBis: '<?',
        compact: '<?',
    },
    template: require('./dvb-collapsible-panel.html'),
    controllerAs: 'vm',
};

export class DvbCollapsiblePanel implements angular.IController {

    public static $inject: readonly string[] = ['$attrs'];

    public isFirst: boolean = false;
    public isLast?: boolean = false;
    public isCollapsed?: boolean = true;
    public limitedEntity?: ILimited;
    public expandAb?: moment.Moment;
    public expandBis?: moment.Moment;
    public compact?: boolean = false;

    public isDisabled: boolean = false;

    public constructor(
        private readonly $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
        this.setCollapsed();
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.expandAb && !changes.expandAb.isFirstChange() ||
            changes.expandBis && !changes.expandBis.isFirstChange() ||
            changes.limitedEntity && !changes.limitedEntity.isFirstChange()) {

            this.setCollapsed();
        }
    }

    public setCollapsed(): void {
        if (!DvbDateUtil.hasValidMoments(this.limitedEntity) ||
            !DvbDateUtil.isValidMoment(this.expandAb) ||
            !DvbDateUtil.isValidMoment(this.expandBis)) {

            return;
        }

        const gueltigkeit = {gueltigAb: this.expandAb, gueltigBis: this.expandBis};

        // falls die Gueltigkeit des LimitedEntities diese Woche betrifft, so soll das Panel automatisch
        // ausgeklappt werden
        if (this.isCollapsed && DvbDateUtil.getOverlap(gueltigkeit, this.limitedEntity)) {
            this.isCollapsed = false;
        }
    }
}

componentConfig.controller = DvbCollapsiblePanel;
angular.module('kitAdmin').component('dvbCollapsiblePanel', componentConfig);
