/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Filter} from '../shared/Filter';
import type {FilterOption} from '../shared/FilterOption';
import type {MultiSelectFilterController} from './MultiSelectFilterController';

/**
 * Used for filters that have multiple possible values (FilterOptions)
 */
export class MultiSelectFilter<T> extends Filter {

    public constructor(
        options: FilterOption[],
        translationKey: string,
        protected readonly controller: MultiSelectFilterController<T>,
    ) {
        super(options, translationKey);
        controller.setRelevantValues(options.map(o => o.id) as unknown as T[]);
    }

    public init(): void {
        this.options.forEach(option => {
            if (this.controller.model.filterModel.has(option.id as any)) {
                option.selected = true;
                option.sticky = true;
            } else {
                option.selected = false;
            }
        });

        this.initStickyFlags();
    }

    public onChange(option: FilterOption): void {
        if (option.selected) {
            this.controller.onSelect(option.id as any);
        } else {
            this.controller.onRemove(option.id as any);
        }
        this.initStickyFlags();
    }

    public override reset(): void {
        this.controller.reset();
        this.init();
    }
}
