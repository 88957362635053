/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxParameterKinderkontaktliste} from '@dv/shared/backend/model/jax-parameter-kinderkontaktliste';
import {DvbDateUtil, DvbRestUtil, DvbUtil, KontaktType, SearchResultEntry} from '@dv/shared/code';
import type moment from 'moment';
import {ParameterKinderOrte} from './ParameterKinderOrte';

export class ParameterKinderkontaktliste extends ParameterKinderOrte {

    public constructor(
        alleKinderOrte: boolean = true,
        kinderOrte: SearchResultEntry[] = [],
        stichtag: moment.Moment | null = null,
        year: number | null = null,
        public stichtagAdresse?: moment.Moment,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public allePlaetze: boolean = true,
        public subventionierterPlatz: boolean = false,
        public privaterPlatz: boolean = false,
        public alleKontakte: boolean = true,
        public useDateRange: boolean = false,
        public kontaktTypes: KontaktType[] = [],
        public gruppen: SearchResultEntry[] = [],
        public firmen: SearchResultEntry[] = [],
        public mitBelegung: boolean = true,
        public aufWarteliste: boolean = false,
    ) {
        super(alleKinderOrte, kinderOrte, stichtag, year);
    }

    public override isValid(): boolean {
        // super.isValid() enforces alleKinderOrte to be true or there to be a specifc kita.
        // We do not want that behavior here, having no kita but gruppen instead has to be accepted too
        return this.hasValidKinderOrtOrGruppe()
            && this.hasValidPlaetze()
            && this.hasValidKontaktTypes()
            && this.hasValidWartelisteConstelation()
            && this.hasValidAufWartelisteAndMitBelegung();
    }

    public hasValidKinderOrtOrGruppe(): boolean {
        return this.alleKinderOrte || this.hasKinderOrte() || this.hasGruppen();
    }

    public hasValidPlaetze(): boolean {
        return this.allePlaetze ||
            this.subventionierterPlatz ||
            this.privaterPlatz ||
            this.getEnabledFirmen().length > 0;
    }

    public hasValidKontaktTypes(): boolean {
        if (this.alleKontakte) {
            return true;
        }

        return this.kontaktTypes.length > 0;
    }

    public hasValidWartelisteConstelation(): boolean {
        return this.alleKinderOrte || (this.aufWarteliste ? !this.hasGruppen() : true);
    }

    public hasValidAufWartelisteAndMitBelegung(): boolean {
        return this.aufWarteliste || this.mitBelegung;
    }

    public hasGruppen(): boolean {
        return DvbUtil.isNotEmptyArray(this.getEnabledGruppen());
    }

    public getEnabledGruppen(): readonly SearchResultEntry[] {
        return SearchResultEntry.getEnabled(this.gruppen);
    }

    public getEnabledFirmen(): readonly SearchResultEntry[] {
        return SearchResultEntry.getEnabled(this.firmen);
    }

    public override toRestObject(): JaxParameterKinderkontaktliste {
        return Object.assign(super.toRestObject(), {
            stichtagAdresse: DvbRestUtil.momentToLocalDateChecked(this.stichtagAdresse ?? DvbDateUtil.today()),
            subventionierterPlatz: this.subventionierterPlatz,
            privaterPlatz: this.privaterPlatz,
            allePlaetze: this.allePlaetze,
            kontaktTypes: this.alleKontakte ? Object.values(KontaktType) : this.kontaktTypes,
            gruppenIds: this.alleKinderOrte ? [] : this.getEnabledGruppen().map(DvbUtil.mapToIdChecked),
            firmaIds: this.getEnabledFirmen().map(DvbUtil.mapToIdChecked),
            mitBelegung: this.mitBelegung,
            aufWarteliste: this.aufWarteliste,
        }, this.gueltigkeitToRest());
    }

    private gueltigkeitToRest(): {
        stichtag?: BackendLocalDate;
        gueltigAb?: BackendLocalDate;
        gueltigBis?: BackendLocalDate;
    } {
        if (!this.mitBelegung) {
            return {};
        }

        if (this.useDateRange) {
            return {
                gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb ?? DvbDateUtil.startOfMonth()) ?? undefined,
                gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis ?? DvbDateUtil.endOfMonth()) ?? undefined,
                stichtag: undefined,
            };
        }

        return {
            gueltigAb: undefined,
            gueltigBis: undefined,
            stichtag: DvbRestUtil.momentToLocalDate(this.stichtag ? this.stichtag : DvbDateUtil.today()) ?? undefined,
        };
    }
}
