/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestCache} from '@dv/kitadmin/models';
import {SmallInvoiceBankKonto, SmallInvoiceEinzahlungsschein, SmallInvoiceLayout} from '@dv/kitadmin/models';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class SmallInvoiceService {
    public static $inject: readonly string[] = [];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/faktura/smallinvoice`;

    public getBankKonten(
        mandantKonfigurationId: string,
        params?: Partial<RestCache>,
    ): angular.IPromise<SmallInvoiceBankKonto []> {
        const url = `${SmallInvoiceService.BASE_URL}/${encodeURIComponent(mandantKonfigurationId)}/bankkonten`;

        return DvbRestUtilAngularJS.getModelsArray(url, SmallInvoiceBankKonto, 'items', params);
    }

    public getEinzahlungsscheine(
        mandantKonfigurationId: string,
        params?: Partial<RestCache>,
    ): angular.IPromise<SmallInvoiceEinzahlungsschein[]> {
        const url = `${SmallInvoiceService.BASE_URL}/${encodeURIComponent(mandantKonfigurationId)}/einzahlungsscheine`;

        return DvbRestUtilAngularJS.getModelsArray(url, SmallInvoiceEinzahlungsschein, 'items', params);
    }

    public getLayouts(
        mandantKonfigurationId: string,
        params?: Partial<RestCache>,
    ): angular.IPromise<SmallInvoiceLayout[]> {
        const url = `${SmallInvoiceService.BASE_URL}/${encodeURIComponent(mandantKonfigurationId)}/layouts`;

        return DvbRestUtilAngularJS.getModelsArray(url, SmallInvoiceLayout, 'items', params);
    }
}
