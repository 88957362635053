<div class="dvb-maintenance-recalculate-mandant-rechnung">
    <h2 data-translate="ADMINISTRATION.MTNC_RECALCULATE_MANDANT_RECHNUNG"></h2>

    <form class="dvb-form"
          name="recalculateMandantRechnungForm"
          ng-submit="vm.recalculateMandantRechnungForm(recalculateMandantRechnungForm)"
          novalidate>
        <div class="row">
            <dvb-datepicker-text-field ng-model="vm.stichtag"
                                       dvb-begin-of-month
                                       ng-attr-placeholder="{{'COMMON.MONTH' | translate}}"
                                       required
                                       class="col-md-6">
            </dvb-datepicker-text-field>
        </div>

        <dvb-loading-button type="submit" is-loading="vm.isLoading">
            <span data-translate="ADMINISTRATION.MTNC_RECALCULATE_ASYNC"></span>
        </dvb-loading-button>
    </form>
</div>