<div class="dvb-stunden-kontingent-form">
    <form name="stundenKontingentForm" ng-submit="vm.onSubmit()" class="dvb-form" novalidate>
        <div class="history-columns">
            <div class="history-column-middle">
                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.workingCopy.gueltigAb"
                                                   required
                                                   ng-change="vm.initKontingente()"
                                                   dvb-begin-of-month
                                                   ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.workingCopy.gueltigBis"
                                                   required
                                                   dvb-end-of-month-parser
                                                   ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                </div>
                <div class="row" ng-if="vm.isNewEntity">
                    <div class="col-md-6">
                        <dvb-search-get-single-entity class="form-row"
                                                      as-col="true"
                                                      entity-to-search="KINDERORT"
                                                      ng-model="vm.kitaSearchResultEntry"
                                                      required
                                                      ng-change="vm.updateKinderOrt()"
                                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                        </dvb-search-get-single-entity>
                    </div>

                    <div class="col-md-6">
                        <div class="radio" ng-if="vm.subventioniertesKontingent">
                            <label>
                                <input type="radio"
                                       ng-value="vm.subventioniertesKontingent.id"
                                       ng-model="vm.workingCopy.kontingentId"
                                       name="kontingent"
                                       ng-disabled="!vm.isNewEntity">
                                <span data-translate="COMMON.SUBVENTIONIERT"></span>
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input type="radio"
                                       ng-value="null"
                                       ng-model="vm.workingCopy.kontingentId"
                                       name="kontingent"
                                       ng-disabled="!vm.isNewEntity">
                                <span data-translate="COMMON.PRIVAT"></span>
                            </label>
                        </div>
                        <div class="radio" ng-repeat="kontingent in vm.firmenKontingente">
                            <label>
                                <input type="radio"
                                       ng-value="kontingent.id"
                                       ng-model="vm.workingCopy.kontingentId"
                                       name="kontingent"
                                       ng-disabled="!vm.isNewEntity">
                                <span data-translate="COMMON.FIRMENKONTINGENT.TITLE"
                                      data-translate-value-name="{{kontingent.getDisplayName()}}"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <input type="number"
                               class="form-control"
                               ng-attr-placeholder="{{'KIND.STUNDENTKONTINGENTE_STUNDEN' | translate}}"
                               uib-tooltip="{{'KIND.STUNDENTKONTINGENTE_STUNDEN' | translate}}"
                               tooltip-placement="top-left"
                               min="0"
                               required
                               ng-model="vm.workingCopy.stunden">
                    </div>
                </div>
            </div>

        </div>

        <ul class="list-inline normal-abstand-top">
            <li>
                <dvb-loading-button type="submit"
                                    is-loading="stundenKontingentForm.$pending || vm.formContext.isLoading">
                    <span data-translate="COMMON.SPEICHERN"></span>
                </dvb-loading-button>
            </li>
            <li>
                <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.onCancel()"></a>
            </li>
        </ul>
    </form>
</div>
