<div class="dvb-kinderort-kapazitaeten">
    <!-- BEWILLIGTE PLAETZE / GÜLTIGKEIT -->
    <h2 data-translate="{{'KINDERORT.BEWILLIGTE_PLAETZE.' + vm.kinderOrt.dtype}}"></h2>
    <dvb-plaetze-verlauf kita="vm.kinderOrt"
                         plaetze-values="vm.kinderOrt.bewilligtePlaetze"
                         on-update-value="vm.updateBewilligtePlaetze(value)"
                         on-delete-value="vm.deleteBewilligtePlaetze(value)"
                         on-revert-value="vm.revertTerminateKita()"
                         plaetze-label="KINDERORT.BEWILLIGTE_PLAETZE_WITH_VALUE"
                         at-least-one="true"
                         min-value="1">
    </dvb-plaetze-verlauf>

    <!-- SUBVENTIONIERTE KAPAZITÄT -->
    <h2 data-translate="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.' + vm.kinderOrt.dtype + '.NAME'}}"
        class="big-abstand-top"
        ng-if="vm.kinderOrt.subventioniertesKontingent">
    </h2>
    <dvb-plaetze-verlauf kita="vm.kinderOrt"
                         plaetze-values="vm.kinderOrt.subventioniertesKontingent.values"
                         on-update-value="vm.updateSubventioniertesKontingentValue(value)"
                         on-delete-value="vm.deleteSubventioniertesKontingentValue(value)"
                         on-revert-value="vm.revertSubventioniertesKontingentValue(value)"
                         plaetze-label="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.' + vm.kinderOrt.dtype + '.WITH_VALUE'}}"
                         at-least-one="false">
    </dvb-plaetze-verlauf>

    <div class="big-abstand-top" ng-if="vm.neueKontingentValueErfassenKey">
        <form name="updatePlaetzeValueForm"
              ng-submit="vm.saveKontingentValue(updatePlaetzeValueForm, vm.neueKontingentValue)"
              novalidate>
            <h2 data-translate="{{vm.neueKontingentValueTitleKey}}"></h2>

            <div class="normal-abstand-top neue-kontingent-value">
                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.neueKontingentValue.gueltigAb"
                                                   required
                                                   ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                    <div class="col-md-6">
                        <input type="number"
                               ng-if="vm.neueKontingentValueEditable"
                               name="neueKontingentValuePlaetze"
                               class="form-control"
                               dvb-select-on-click
                               ng-attr-placeholder="{{vm.neueKontingentValueErfassenKey | translate}}"
                               uib-tooltip="{{vm.neueKontingentValueErfassenKey | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.neueKontingentValue.plaetze"
                               required
                               ng-pattern="/^\d+(\.\d[05]?)?$/"
                               min="0">
                    </div>
                </div>
            </div>

            <div ng-show="vm.neueKontingentValueErfassenKey" class="normal-abstand-top">
                <ul class="list-inline">
                    <li>
                        <button type="submit" class="btn btn-primary">
                            <span data-translate="COMMON.NEUE_KAPAZITAET_SPEICHERN"></span>
                        </button>
                    </li>
                    <li>
                        <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.resetNewKontingentValue()"></a>
                    </li>
                </ul>
            </div>
        </form>
    </div>

    <div class="main-actions"
         dvb-auth
         require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}">
        <ul class="list-inline" ng-hide="!!vm.neueKontingentValueErfassenKey">
            <li>
                <div class="btn-group" uib-dropdown data-keyboard-nav>
                    <button type="button" class="btn btn-primary dropdown-toggle" uib-dropdown-toggle>
                        <span data-translate="COMMON.NEUE_KAPAZITAET"></span> <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                        <li ng-click="vm.neueBewilligtePlaetze()">
                            <a href="" data-translate="{{'KINDERORT.BEWILLIGTE_PLAETZE.' + vm.kinderOrt.dtype}}"></a>
                        </li>
                        <li ng-click="vm.neuesSubventioniertesKontingentValue()">
                            <a href=""
                               data-translate="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.' + vm.kinderOrt.dtype + '.NAME'}}">
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div ng-if="!vm.kinderOrt.isBeendet()" class="form-section">
                    <a href=""
                       ng-click="vm.terminateKita()"
                       data-translate="KINDERORT.KITA_BEENDEN">
                    </a>
                </div>
            </li>
            <li>
                <div ng-if="vm.kinderOrt.subventioniertesKontingent" class="form-section">
                    <a href=""
                       ng-click="vm.terminateSubventioniertesKontingent()"
                       data-translate="{{'KINDERORT.SUBVENTIONIERTE_KAPAZITAET.' + vm.kinderOrt.dtype + '.BEENDEN'}}">
                    </a>
                </div>
            </li>
            <li>
                <div class="form-section">
                    <a href="" ng-click="vm.confirmDeleteKita(vm.kinderOrt)"
                       data-translate="KINDERORT.KITA_DELETE"></a>
                </div>
            </li>
        </ul>
    </div>
</div>
