<div class="dvb-kita-faktura-konfigurationen row">
    <div class="col-md-7">
        <h2 data-translate="COMMON.RECHNUNGS_KONFIGURATIONEN"></h2>

        <p ng-if="vm.kitaKonfigurationen.length === 0"
           class="normal-abstand-bottom"
           data-translate="KINDERORT.KEINE_KITA_RECHNUNGS_KONFIGURATION">
        </p>

        <div ng-repeat="konfiguration in vm.kitaKonfigurationen | toArray:false | orderBy:'gueltigAb':true">
            <dvb-collapsible-panel limited-entity="konfiguration"
                                   expand-ab="vm.today"
                                   expand-bis="vm.today"
                                   is-first="$first">
                <panel-header>
                    <dvb-kita-faktura-konfiguration-heading konfiguration="konfiguration"
                                                            mandant-konfigurationen="vm.mandantKonfigurationen">
                    </dvb-kita-faktura-konfiguration-heading>
                </panel-header>
                <panel-content>
                    <dvb-kita-faktura-konfiguration-content konfiguration="konfiguration"
                                                            mandant-konfigurationen="vm.mandantKonfigurationen"
                                                            konten="vm.konten">
                    </dvb-kita-faktura-konfiguration-content>
                </panel-content>
            </dvb-collapsible-panel>
        </div>

        <div class="big-abstand-top" dvb-auth require-permission="{{'kita:administrate:' + vm.kita.id}}">
            <ul class="list-inline" ng-if="vm.showNewButton">
                <li>
                    <div class="btn-group" uib-dropdown data-keyboard-nav>
                        <button type="button" class="btn btn-primary dropdown-toggle" uib-dropdown-toggle>
                            <span data-translate="COMMON.NEUE_KONFIGURATION"></span>
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                            <li ng-if="vm.mandantKonfigurationen.length === 0">
                                <a ui-sref="base.faktura.konfiguration"
                                   data-translate="KINDERORT.ZUERST_MANDANT_KONFIGURATION_ERSTELLEN"></a>
                            </li>

                            <li ng-click="vm.neueKonfiguration(konfiguration)"
                                ng-repeat="konfiguration in vm.mandantKonfigurationen">
                                <a href=""
                                   data-translate="KINDERORT.KITA_KONFIGURATION_NAME"
                                   data-translate-value-name="{{konfiguration.name}}"
                                   data-translate-value-type="{{'COMMON.' + konfiguration.dtype | translate}}"
                                   data-translate-value-deactivated="{{konfiguration.isDisabled ? 'active' : ''}}"></a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <div ng-if="vm.showNewForm">
                <h2 data-translate="COMMON.NEUE_KONFIGURATION_NAME"
                    data-translate-value-name="{{vm.konfiguration.mandantKonfiguration.name}}">
                </h2>
                <dvb-kita-faktura-konfiguration-form konfiguration="vm.konfiguration"
                                                     template-konfiguration="vm.templateKonfiguration"
                                                     on-submit="vm.onSubmit()"
                                                     on-cancel="vm.hideForm()"
                                                     is-loading="vm.isLoading"
                                                     konten="vm.konten">
                </dvb-kita-faktura-konfiguration-form>
            </div>
        </div>
    </div>
</div>
