/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Belegung, BelegungInterval, ExtraPlatz, GruppenBelegung, Kind, KinderOrt} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {BEGIN_OF_TIME, checkPresent, END_OF_TIME, HttpCodes, KindergartenBelegung} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {from, take, tap} from 'rxjs';
import type {BelegungsService} from '../../../common/service/rest/kind/belegungsService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {MonatsBelegungRestService} from '../../../common/service/rest/kind/monatsBelegungRestService';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import type {BetreuungsVerlaufService} from '../../service/betreuungsVerlaufService';
import {MONAT_ZUWEISEN_STATE} from '../../zuweisung/kind-zuweisen-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        expandAb: '<',
        expandBis: '<',
        verlaufAb: '<?',
        verlaufBis: '<?',
        showLineTop: '<?',
        isDateClickable: '<?',
        showBetreuungsVereinbarung: '<?',
        showAnmeldeDatum: '<?',
        showAbweichungenBearbeiten: '<?',
        showDelete: '<?',
        onDateSelected: '&',
        onEditBelegung: '&?',
        onBelegungRemoved: '&',
    },
    template: require('./dvb-betreuungsverlauf.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsverlauf implements angular.IController {
    public static $inject: readonly string[] = [
        'betreuungsVerlaufService',
        'errorService',
        'dialogService',
        'kindService',
        'fraktionService',
        'belegungsService',
        'monatsBelegungRestService',
        '$state',
    ];

    private static readonly EXTRA_PLATZ_DATE_FORMAT: string = 'YYYY-MM-DD';

    public kind!: Kind;
    public expandAb!: moment.Moment;
    public expandBis!: moment.Moment;
    public verlaufAb?: moment.Moment;
    public verlaufBis?: moment.Moment;
    public showLineTop: boolean = false;
    public isDateClickable: boolean = false;
    public showBetreuungsVereinbarung: boolean = true;
    public showAnmeldeDatum: boolean = true;
    public showAbweichungenBearbeiten: boolean = true;
    public showDelete: boolean = true;
    public onDateSelected!: (val: { date: moment.Moment }) => void;
    public onEditBelegung?: (val: { belegung: Belegung }) => void;
    public onBelegungRemoved!: () => angular.IPromise<any>;
    public isAusgetreten: boolean = false;
    public belegungIntervals: BelegungInterval[] = [];
    public extraPlaetze: { [key: string]: ExtraPlatz[] } = {};
    public kindergartenBelegung: typeof KindergartenBelegung = KindergartenBelegung;

    public constructor(
        private betreuungsVerlaufService: BetreuungsVerlaufService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private kindService: KindService,
        private fraktionService: FraktionService,
        private belegungsService: BelegungsService,
        private monatsBelegungRestService: MonatsBelegungRestService,
        private $state: StateService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (!changes.kind) {
            return;
        }

        this.isAusgetreten = changes.kind.currentValue.isAusgetreten();
        this.buildIntervals();
    }

    public onRevertAustritt(austrittsDatum: moment.Moment): void {
        this.kindService.revertAustritt(checkPresent(this.kind.id), austrittsDatum)
            .then(() => this.$state.reload())
            .catch(response => {
                if (response.status === HttpCodes.NOT_MODIFIED) {
                    this.errorService.addValidationError('ERRORS.ERR_REVERT_AUSTRITT');

                    return;
                }

                throw response;
            });
    }

    public confirmDeleteSingleBelegung(belegung: Belegung): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.BELEGUNG.SINGULAR',
            confirm: () => from(this.belegungsService.deleteBelegung(checkPresent(belegung.id)))
                .pipe(take(1), tap(() => {
                    this.kind.removeBelegung(belegung);

                    return this.afterRemovingBelegung();
                })),
        });
    }

    public confirmDeleteMonatsBeleguung(belegungInterval: BelegungInterval): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.BELEGUNG.SINGULAR',
            confirm: () => from(
                this.monatsBelegungRestService.deleteMonatsBelegung(checkPresent(belegungInterval.monatsBelegungId)))
                .pipe(take(1), tap(() => {
                    this.kind.removeMonatsBelegung(belegungInterval.belegungen);

                    return this.afterRemovingBelegung();
                })),
        });
    }

    public afterRemovingBelegung(): angular.IPromise<any> {
        this.isAusgetreten = this.kind.isAusgetreten();
        this.buildIntervals();

        return this.onBelegungRemoved();
    }

    public goToMonatsbelegung(belegungInterval: BelegungInterval): angular.IPromise<any> {

        const gruppenBelegungen = belegungInterval.belegungen
            .flatMap(b => b.gruppenBelegungen);
        const gruppenBelegungenWithPlaetze = gruppenBelegungen.filter(gb => gb.plaetze.length !== 0);

        return this.monatsBelegungRestService.getKinderOrt(checkPresent(belegungInterval.monatsBelegungId))
            .then(kita => {
                const fraktionId = this.determineFraktionId(
                    belegungInterval,
                    kita,
                    gruppenBelegungenWithPlaetze,
                    gruppenBelegungen);

                this.$state.go(MONAT_ZUWEISEN_STATE.name, {
                    kindId: this.kind.id,
                    kinderOrtId: checkPresent(kita).id,
                    gruppeId: fraktionId,
                    zuweisungAb: belegungInterval.gueltigAb.format(DvbBetreuungsverlauf.EXTRA_PLATZ_DATE_FORMAT),
                });
            });
    }

    private determineFraktionId(
        belegungInterval: BelegungInterval,
        kita: Persisted<KinderOrt>,
        gruppenBelegungenWithPlaetze: GruppenBelegung[],
        gruppenBelegungen: GruppenBelegung[],
    ): string | undefined {

        // use gruppe from belegungInterval which belongs to kita and has plaetze
        let fraktionId = Array.from(belegungInterval.targetFraktionIds)
            .filter(id => kita.gruppen?.some(g => g.id === id))
            .find(id => gruppenBelegungenWithPlaetze.some(gb => gb.gruppeId === id));

        // alternatively use fraktion from gruppenbelegung without plaetze.
        // or any fraktion from the kita as a last resort
        fraktionId ??= gruppenBelegungen.find(gb => kita.gruppen?.some(g => g.id === gb.gruppeId))?.gruppeId
            ?? kita.gruppen[0].id;

        return fraktionId;
    }

    private buildIntervals(): void {

        const includes = '(customFieldValues, ' +
            'gruppenBelegungen.fields(pensum,gruppeId,vertraglichePensen,' +
            'plaetze.fields(belegungsEinheitId,kontingentId)))';
        const params = {includes, gueltigAb: this.verlaufAb, gueltigBis: this.verlaufBis};
        this.kindService.getWochenBelegungen(this.kind.id!, params).then(wochenBelegungen => {

            // in case the verlauf is not shown entirely, only show Austritt when it happended before verlaufBis
            const isAusgetreten = this.kind.isAusgetreten(this.verlaufBis);

            this.belegungIntervals = this.betreuungsVerlaufService.buildBelegungIntervals(
                this.kind.belegungen,
                this.getExtraPlaetzeForVerlauf(),
                isAusgetreten,
                wochenBelegungen,
            );
        });
    }

    private getExtraPlaetzeForVerlauf(): ExtraPlatz[] {
        if (!this.verlaufAb && !this.verlaufBis) {
            return this.kind.extraPlaetze;
        }
        const start = this.verlaufAb ?? BEGIN_OF_TIME;
        const end = this.verlaufBis ?? END_OF_TIME;

        return this.kind.extraPlaetze.filter(e => e.affectedDay?.isBetween(start, end));
    }
}

componentConfig.controller = DvbBetreuungsverlauf;
angular.module('kitAdmin').component('dvbBetreuungsverlauf', componentConfig);
