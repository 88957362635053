<h2 data-translate="{{vm.translationKeys.pageHeader}}"></h2>

<div class="filter-list">
    <div class="filter">
        <div data-translate="COMMON.FILTER_STATUS" class="filter-label"></div>
        <ul class="filter-option-list" ng-class="{'hidden-print': !vm.filter.isFiltering()}">
            <li ng-repeat="status in vm.states" ng-class="{'hidden-print': !vm.filter.states[status]}">
                <button class="btn label label-default label-radio"
                        type="button"
                        ng-model="vm.filter.states[status]"
                        ng-click="vm.reloadItems()"
                        uib-tooltip="{{vm.translationKeys.stateTooltipPrefix + status | translate}}"
                        tooltip-placement="top-left"
                        uib-btn-checkbox
                        data-translate="{{vm.translationKeys.statePrefix + status}}">
                </button>
            </li>
        </ul>
    </div>

    <div ng-if="vm.isMandantAdmin" class="filter normal-abstand-left">
        <button class="btn label label-default label-radio"
                type="button"
                ng-model="vm.filter.showWithMissingInstitution"
                ng-click="vm.reloadItems()"
                uib-btn-checkbox
                data-translate="KIBON.OHNE_INSTITUTION">
        </button>
    </div>

    <div class="filter pull-right text-right">
        <dvb-filter-toggler is-collapsed="vm.collapsed"
                            show-reset="vm.filter.isFiltering()"
                            on-reset="vm.onReset()"
                            on-toggle="vm.collapsed = !vm.collapsed">
        </dvb-filter-toggler>
    </div>
</div>

<div class="filter-list small-abstand-top">
    <div class="filter">
        <ul class="filter-option-list">
            <li>
                <input type="text"
                       class="form-control input-sm no-margin"
                       ng-attr-placeholder="{{'KIBON.REFERENZ_NUMMER' | translate}}"
                       uib-tooltip="{{'KIBON.REFERENZ_NUMMER' | translate}}"
                       tooltip-placement="top-left"
                       maxlength="100"
                       ng-change="vm.reloadItems()"
                       ng-model-options="{debounce: 300}"
                       ng-model="vm.filter.refNr">
            </li>
        </ul>
    </div>

    <div class="filter" ng-class="{'hidden-print': !vm.filter.gueltigkeit.selected}">
        <div data-translate="KIBON.PERIODE" class="filter-label"></div>
        <ul class="filter-option-list">
            <li>
                <dvb-filter-option-popover filter-model="vm.filter.gueltigkeit"
                                           closeable="false"
                                           on-close="vm.filterOptionHelper.onClose(vm.filter.gueltigkeit)"
                                           on-toggle="vm.filterOptionHelper.onToggle(vm.filter.gueltigkeit, isSelected)"
                                           on-close-popover="vm.reloadItems()">
                </dvb-filter-option-popover>
            </li>
        </ul>
    </div>

    <div class="filter">
        <ul class="filter-option-list">
            <li>
                <input type="text"
                       class="form-control input-sm no-margin"
                       ng-attr-placeholder="{{'KIBON.KIND_NAME_FILTER' | translate}}"
                       uib-tooltip="{{'KIBON.KIND_NAME_FILTER' | translate}}"
                       tooltip-placement="top-left"
                       maxlength="100"
                       ng-change="vm.reloadItems()"
                       ng-model-options="{debounce: 300}"
                       ng-model="vm.filter.kindName">
            </li>
        </ul>
    </div>
</div>

<div class="filter-list filter-bar small-abstand-top">
    <div class="filter" ng-if="vm.isMandantAdmin && vm.filter.mandant.sticky">
        <div data-translate="COMMON.MANDANT.SINGULAR" class="filter-label"></div>
        <ul class="filter-option-list">
            <li>
                <dvb-filter-option label="vm.filter.mandant.name"
                                   is-selected="vm.filter.mandant.selected"
                                   closeable="true"
                                   on-close="vm.onMandantFilterClosed(vm.filter.mandant)"
                                   on-toggle="vm.filterOptionHelper.onToggle(vm.filter.mandant, isSelected)">
                </dvb-filter-option>
            </li>
        </ul>
    </div>

    <div class="filter" ng-if="(vm.filter.kinderOrte | filter:{sticky:true}).length > 0">
        <div data-translate="COMMON.KINDERORT.PLURAL" class="filter-label"></div>
        <ul class="filter-option-list">
            <li ng-repeat="filter in vm.filter.kinderOrte | filter:{sticky:true} | orderBy: 'name'">
                <dvb-filter-option label="filter.name"
                                   is-selected="filter.selected"
                                   closeable="true"
                                   on-close="vm.filterOptionHelper.remove(vm.filter.kinderOrte, filter)"
                                   on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                </dvb-filter-option>
            </li>
        </ul>
    </div>

    <div class="filter" ng-if="vm.allowKinderFilter && (vm.filter.kinder | filter:{sticky:true}).length > 0">
        <div data-translate="COMMON.KIND.PLURAL" class="filter-label"></div>
        <ul class="filter-option-list">
            <li ng-repeat="filter in vm.filter.kinder | filter:{sticky:true} | orderBy: 'name'">
                <dvb-filter-option label="filter.name"
                                   is-selected="filter.selected"
                                   closeable="true"
                                   on-close="vm.filterOptionHelper.remove(vm.filter.kinder, filter)"
                                   on-toggle="vm.filterOptionHelper.onToggle(filter, isSelected)">
                </dvb-filter-option>
            </li>
        </ul>
    </div>
</div>

<div uib-collapse="vm.collapsed">
    <div class="row small-abstand-top">
        <div class="col-md-4 col-md-offset-8" ng-if="vm.isMandantAdmin">
            <dvb-search-on-click-entity add-label-key="COMMON.MANDANT.AUSWAEHLEN"
                                        entity-to-search="MANDANT"
                                        on-select="vm.filter.mandant = vm.filterOptionHelper.createFromSearchResult(item); vm.onMandantChanged()"
                                        on-select-clear="true"
                                        mandant-filter="vm.mandantAllFilter"
                                        placeholder-key="COMMON.MANDANT.SINGULAR">
            </dvb-search-on-click-entity>
        </div>
        <div class="col-md-4 col-md-offset-8">
            <dvb-search-on-click-entity add-label-key="COMMON.KINDERORT.AUSWAEHLEN"
                                        entity-to-search="KINDERORT"
                                        on-select="vm.filterOptionHelper.addFromSearchResult(vm.filter.kinderOrte, item)"
                                        on-select-clear="true"
                                        mandant-filter="vm.mandantFilter"
                                        disabled-entries="vm.filter.kinderOrte"
                                        placeholder-key="COMMON.KINDERORT.SINGULAR">
            </dvb-search-on-click-entity>
        </div>
        <div class="col-md-4 col-md-offset-8" ng-if="vm.allowKinderFilter">
            <dvb-search-on-click-entity add-label-key="COMMON.KIND.AUSWAEHLEN"
                                        entity-to-search="KIND"
                                        on-select="vm.filterOptionHelper.addFromSearchResult(vm.filter.kinder, item)"
                                        on-select-clear="true"
                                        mandant-filter="vm.mandantFilter"
                                        disabled-entries="vm.filter.kinder"
                                        placeholder-key="COMMON.KIND.SINGULAR">
            </dvb-search-on-click-entity>
        </div>
    </div>
</div>

<div class="row big-abstand-top">
    <div class="col-md-7 big-abstand-bottom">
        <dvb-pages items="vm.pageContainer.items"
                   total-items="vm.pageContainer.count"
                   is-loading="vm.isLoading"
                   items-per-page="vm.itemsPerPage"
                   current-page="vm.currentPage"
                   entity-name="vm.translationKeys.entityNameMF"
                   on-update-page="vm.updatePage(page)"
                   on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
            <page-items dvb-accordions>

                <dvb-accordion ng-repeat="entity in vm.pageContainer.items" is-first="$first" remove-toggled-dom="true">

                    <panel-header class="full-width-block">
                        <div class="layout-row items-center row">
                            <div class="col-sm-4 hidden-xs">
                                <dvb-kibon-ref-nummer kibon-ref-nr-info="entity"
                                                      verfuegung-version="entity.verfuegungVersion">
                                </dvb-kibon-ref-nummer>
                            </div>
                            <div class="col-xs-5 col-sm-4">
                                <span class="badge"
                                      data-translate="{{vm.translationKeys.statePrefix + entity.state}}">
                                </span>
                            </div>
                            <div class="col-xs-7 col-sm-4">
                                <span ng-bind="entity.kind.getDisplayName()"></span>
                            </div>
                        </div>
                    </panel-header>

                    <panel-content>
                        <div class="row">
                            <div class="col-sm-4 normal-abstand-bottom">
                                <h3 class="truncate"
                                    ng-bind="entity.institution.name"
                                    uib-tooltip="{{entity.institution.name}}"
                                    tooltip-placement="top-left">
                                </h3>
                                <p data-translate="{{vm.translationKeys.kibonExportAm}}"
                                   data-translate-value-date="{{entity.kibonExportAm | amDateFormat:'D.M.YYYY HH:mm'}}">
                                </p>
                                <p data-translate="KIBON.IMPORTIERT_AM"
                                   data-translate-value-date="{{entity.timestampErstellt | amDateFormat:'D.M.YYYY HH:mm'}}">
                                </p>
                                <dvb-kibon-ref-nummer class="visible-xs"
                                                      kibon-ref-nr-info="entity">
                                </dvb-kibon-ref-nummer>
                            </div>
                            <div class="col-sm-4 normal-abstand-bottom">
                                <h3 data-translate="KIBON.VERFUEGUNGEN.GESUCHSTELLER"></h3>
                                <p ng-bind="entity.gesuchsteller.getDisplayName()"></p>
                                <p ng-if="entity.gesuchsteller.email"
                                   class="truncate"
                                   uib-tooltip="{{entity.gesuchsteller.email}}"
                                   tooltip-placement="top-left"
                                   ng-bind="entity.gesuchsteller.email">
                                </p>
                            </div>
                            <div class="col-sm-4 normal-abstand-bottom">
                                <h3 data-translate="COMMON.KIND.SINGULAR"></h3>
                                <p ng-bind="entity.kind.getDisplayName()"></p>
                                <p data-translate="COMMON.GEBURTSTAG_MIT_DATUM"
                                   data-translate-value-date="{{entity.kind.geburtsdatum | amDateFormat:'D.M.YYYY'}}">
                                </p>
                            </div>
                        </div>

                        <div ng-if="entity.betreuungsGutscheine">
                            <dvb-kitax-betreuungsgutscheine betreuungs-gutscheine="entity.betreuungsGutscheine"
                                                            schema-version="entity.schemaVersion">
                            </dvb-kitax-betreuungsgutscheine>
                        </div>
                    </panel-content>

                    <panel-footer>
                        <dvb-kibon-exchange-entity-actions entity="entity"
                                                           actions="vm.actions"
                                                           on-action="vm.confirmAction(entity, action)"
                                                           on-assign="vm.assign(entity, item)"
                                                           on-un-assign="vm.unAssign(entity)"
                                                           on-ignore="vm.ignore(entity)"
                                                           on-un-ignore="vm.unIgnore(entity)"
                                                           on-decline="vm.decline(entity)"
                                                           on-anmelden="vm.anmelden(entity)">
                        </dvb-kibon-exchange-entity-actions>
                    </panel-footer>

                </dvb-accordion>
            </page-items>
        </dvb-pages>
    </div>

    <div class="col-md-4 col-md-offset-1 hidden-print">
        <h2 ng-if="vm.translationKeys.matchingHeader" data-translate="{{vm.translationKeys.matchingHeader}}"></h2>
        <p ng-if="vm.translationKeys.matchingText" data-translate="{{vm.translationKeys.matchingText}}"></p>

        <div ng-transclude="rightColumn" ng-if="vm.isSlotFilled('rightColumn')"></div>
    </div>
</div>
