/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FilterOption} from '../shared/FilterOption';
import type {IPopoverFilterProperty} from './IPopoverFilterProperty';

export class PopoverFilterOption<T extends IPopoverFilterProperty<TModel, TPopoverModel>, TModel, TPopoverModel>
    extends FilterOption {

    public constructor(
        id: string,
        public popoverModel: T,
    ) {
        super(id, id, popoverModel.selected, popoverModel.selected);
    }
}
