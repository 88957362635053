/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaAbrechnungTarif, TarifType} from '@dv/kitadmin/models';
import {Tarif} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';

export class DvbKitaTarifeAuswahl {
    public abrechnungTarife!: KitaAbrechnungTarif[];

    private static getTranslationKey(abrechnungTarif: KitaAbrechnungTarif): { label: string; placeholder: string } {
        switch (abrechnungTarif.typ.name) {
            case 'PRIVAT':
                return {
                    label: 'KINDERORT.KITA_TITLE_TARIF_PRIVAT',
                    placeholder: 'KINDERORT.KITA_TITLE_TARIF_PRIVAT_AUSWAEHLEN',
                };
            case 'SUBVENTIONIERT':
                return {
                    label: 'KINDERORT.KITA_TITLE_TARIF_SUBVENTIONIERT',
                    placeholder: 'KINDERORT.KITA_TITLE_TARIF_SUBVENTIONIERT_AUSWAEHLEN',
                };
            case 'BETREUUNGSGUTSCHEIN':
                return {
                    label: 'KINDERORT.KITA_TITLE_TARIF_BETREUUNGSGUTSCHEIN',
                    placeholder: 'KINDERORT.KITA_TITLE_TARIF_BETREUUNGSGUTSCHEIN_AUSWAEHLEN',
                };
            case 'BETREUUNGS_UNABHAENGIG':
                return {
                    label: 'KINDERORT.KITA_TITLE_TARIF_BETREUUNGS_UNABHAENGIG',
                    placeholder: 'KINDERORT.KITA_TITLE_TARIF_BETREUUNGS_UNABHAENGIG_AUSWAEHLEN',
                };
            case 'PROVISORISCHER_BG':
                return {
                    label: 'KINDERORT.KITA_TITLE_TARIF_PROVISORISCHER_BG',
                    placeholder: 'KINDERORT.KITA_TITLE_TARIF_PROVISORISCHER_BG_AUSWAEHLEN',
                };
            default:
                throw new Error(`${abrechnungTarif.typ.name} is not recognized as type`);
        }
    }

    public updateTarif(abrechnungTarif: KitaAbrechnungTarif, entry?: SearchResultEntry): void {
        if (entry) {
            const tarif = new Tarif();
            tarif.name = entry.text;
            tarif.id = entry.id;
            abrechnungTarif.tarif = tarif;
            abrechnungTarif.tarifId = tarif.id;
        } else {
            abrechnungTarif.tarif = null;
            abrechnungTarif.tarifId = null;
        }
    }

    public getLabelTranslationKey(abrechnungTarif: KitaAbrechnungTarif): string {
        return DvbKitaTarifeAuswahl.getTranslationKey(abrechnungTarif).label;
    }

    public getPlaceholderTranslationKey(abrechnungTarif: KitaAbrechnungTarif): string {
        return DvbKitaTarifeAuswahl.getTranslationKey(abrechnungTarif).placeholder;
    }

    public filterTarif(tarifTypes: TarifType[], tarif: Tarif): boolean {
        return tarifTypes.includes(tarif.dtype);
    }
}

angular.module('kitAdmin').directive('dvbKitaTarifeAuswahl', dvbKitaTarifeAuswahl);

function dvbKitaTarifeAuswahl(): angular.IDirective {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            abrechnungTarife: '=',
        },
        template: require('./dvb-kita-tarife-auswahl.html'),
        controller: DvbKitaTarifeAuswahl,
        controllerAs: 'vm',
        bindToController: true,
    };
}
