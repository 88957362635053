<div ng-repeat="betreuungsGrund in vm.betreuungsgruende" class="checkbox">
    <label>
        <input type="checkbox"
               ng-checked="vm.isChecked(betreuungsGrund.id)"
               name="betreuungsgrund"
               ng-click="vm.valueChanged(betreuungsGrund)"
               dvb-auth
               require-permission="kita:manage:general"
               on-unauthorised-disable>
        <span ng-bind="betreuungsGrund.grund"></span>
    </label>
</div>
