/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Rechnungsempfaenger} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        rechnungsempfaenger: '<',
        onChange: '&',
    },
    template: require('./dvb-rechnungsempfaenger-value.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsempfaengerValue implements angular.IController {
    public rechnungsempfaenger!: Rechnungsempfaenger;
    public onChange!: FunctionType;

    public readonly rechnungAufteilungTypes: typeof Rechnungsempfaenger.RECHNUNG_AUFTEILUNG_TYPE
        = Rechnungsempfaenger.RECHNUNG_AUFTEILUNG_TYPE;

    public changeValue(): void {
        if (TypeUtil.isFunction(this.onChange)) {
            this.onChange();
        }
    }

}

componentConfig.controller = DvbRechnungsempfaengerValue;
angular.module('kitAdmin').component('dvbRechnungsempfaengerValue', componentConfig);
