/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        bankStatementEntry: '<',
    },
    template: require('./dvb-transaktion-zuweisen-header.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionZuweisenHeader {
    public static $inject: readonly string[] = ['$state'];

    public bankStatementEntry!: BankStatementEntry;

    public constructor(private $state: StateService) {
    }

    public assignRechnung(item: SearchResultEntry): void {
        this.$state.go('.confirm', {assigningRechnungId: item.id});
    }
}

componentConfig.controller = DvbTransaktionZuweisenHeader;
angular.module('kitAdmin').component('dvbTransaktionZuweisenHeader', componentConfig);
