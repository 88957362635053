/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout} from '@dv/kitadmin/models';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {DokumentlayoutService} from '../../service/dokumentlayoutService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        dokumentLayout: '<',
    },
    template: require('./dvb-dokumentlayout-content.html'),
    controllerAs: 'vm',
};

export class DvbDokumentlayoutContent implements angular.IController {

    public static $inject: readonly string[] = ['dokumentlayoutService'];

    public dokumentLayout!: DokumentLayout;

    public isLoading: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(private readonly dokumentlayoutService: DokumentlayoutService) {
    }

    public downloadPreview(event: Event): void {
        // avoid opening form (click2edit)
        event.stopPropagation();

        this.isLoading = true;
        this.dokumentlayoutService.createLayoutPreview(this.dokumentLayout)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbDokumentlayoutContent;
angular.module('kitAdmin').component('dvbDokumentlayoutContent', componentConfig);
