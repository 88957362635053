/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entity: '<',
    },
    template: require('./dvb-gueltigkeit.html'),
    controllerAs: 'vm',
};

export class DvbGueltigkeit implements angular.IController {
    public static $inject: readonly string[] = ['$translate'];

    public entity!: ILimited;

    public preposition: string | null = null;
    public active: boolean = false;
    public isBeginOfTime: boolean = false;

    public constructor(
            private $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onInit(): void {
        const today = DvbDateUtil.today();

        this.active = DvbDateUtil.isGueltigOn(this.entity, today);
        this.isBeginOfTime = DvbDateUtil.isBeginOfTime(this.entity.gueltigAb);

        const prepositionKey = DvbDateUtil.getTemporalPrepositionKey(this.entity, today);
        this.preposition = this.$translate.instant(checkPresent(prepositionKey));

        if (!this.active) {
            this.preposition = DvbUtil.capitalize(this.preposition);
        }
    }
}

componentConfig.controller = DvbGueltigkeit;
angular.module('kitAdmin').component('dvbGueltigkeit', componentConfig);
