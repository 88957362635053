/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    KinderOrt,
    KinderOrtId,
    RechnungsLauf,
    RechnungsLaufEntry,
    RechnungsLaufKontaktpersonEntry,
} from '@dv/kitadmin/models';
import {EmailVersandProperties, FinishRechnungsLauf, TempBlob} from '@dv/kitadmin/models';
import type {RechnungsUebermittlungsEinheitError} from '@dv/shared/code';
import {
    checkPresent,
    DvbError,
    DvbRestUtil,
    isPresent,
    isRechnungslaufError,
    RechnungsLaufError,
} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbDownload} from '../../../../../base/directive/dvb-download/dvb-download';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';
import type {KitaFakturaService} from '../../../../../common/service/rest/kinderort/kitaFakturaService';
import {RechnungenFilter} from '../../../../../filter/RechnungenFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        rechnungsLauf: '<',
        kita: '<',
    },
    template: require('./dvb-kita-rechnungslauf-finish.html'),
    controllerAs: 'vm',
};

export class DvbKitaRechnungslaufFinish implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        'fakturaService',
        'kitaFakturaService',
        'errorService',
        '$q',
    ];

    public rechnungsLauf!: RechnungsLauf;
    public kita!: KinderOrt;

    public isLoading: boolean = false;
    public selectedEntryIndex?: number = undefined;
    public ids: { [rechnungsUebermittlungsEinheitId: string]: boolean } = {};
    public createRechnungsLaufPdf: boolean = false;
    public emailVersandProperties: EmailVersandProperties = new EmailVersandProperties();
    public kinderOrtName: string = '';

    public showLimitToRechnungenWithDiff: boolean = false;
    public limitEmailsToRechnungenWithDiff: boolean = true;
    public limitPdfsToRechnungenWithDiff: boolean = true;

    private timeout?: angular.IDeferred<any> = undefined;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private $state: StateService,
        private fakturaService: FakturaService,
        private kitaFakturaService: KitaFakturaService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.selectAllEntries();
        this.emailVersandProperties.replyToAddress = this.kita.email;
        this.kinderOrtName = this.kita.getDisplayName();
        this.showLimitToRechnungenWithDiff = this.rechnungsLauf.hasEntryWithAbweichungToPrevious();
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public selectAllEntries(): void {
        this.rechnungsLauf.entries
            .filter(entry => entry.verrechenbar)
            .forEach(entry => {
                this.ids[checkPresent(entry.rechnungsUebermittlungsEinheitId)] = true;
            });
    }

    public unselectAllEntries(): void {
        this.ids = {};
    }

    public submit(form: angular.IFormController): void {
        const isValid = form.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.VALUE_REQUIRED');

        if (!isValid) {
            return;
        }

        const finishRechnungsLauf = new FinishRechnungsLauf();
        // transfer ids from checked list to this.finishRechnungsLauf
        finishRechnungsLauf.rechnungsUebermittlungsEinheitIds = Object.keys(this.ids).filter(id => this.ids[id]);
        finishRechnungsLauf.emailVersandProperties = this.emailVersandProperties;

        const selectedEntries = this.getSelectedEntries();
        const entriesWithAbweichungVormonat = selectedEntries.filter(entry => entry.hasAbweichungToPrevious());

        finishRechnungsLauf.rechnungsEmailVersandIds =
            this.showLimitToRechnungenWithDiff && this.limitEmailsToRechnungenWithDiff ?
                entriesWithAbweichungVormonat.map(e => checkPresent(e.rechnungsUebermittlungsEinheitId)) :
                finishRechnungsLauf.rechnungsUebermittlungsEinheitIds;

        this.isLoading = true;

        this.timeout = this.$q.defer();
        this.fakturaService.finishRechnungsLauf(finishRechnungsLauf, {timeout: this.timeout.promise})
            .then(response => {
                const tempBlob = TempBlob.apiResponseTransformer(response.data);
                if (tempBlob.href) {
                    this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
                }

                if (!this.createRechnungsLaufPdf) {
                    return this.goToOverview();
                }

                const entriesForPdf = this.showLimitToRechnungenWithDiff && this.limitPdfsToRechnungenWithDiff ?
                    entriesWithAbweichungVormonat :
                    selectedEntries;

                const downloadPromise = this.downloadPdf(checkPresent(this.rechnungsLauf.kitaId), entriesForPdf);

                return this.$q.all(downloadPromise)
                    .finally(() => this.goToOverview());
            })
            .catch(error => {
                if (DvbRestUtil.isRequestCancelled(error)) {
                    return this.$q.resolve();
                }
                if (error instanceof DvbError && isRechnungslaufError(error)) {
                    const fakturaError = RechnungsLaufError.apiResponseTransformer(error.args.fakturaError);
                    this.handleFakturaError(fakturaError);

                    return this.$q.resolve();
                }

                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.$state.go('base.kinderort.faktura.overview');
    }

    /**
     * Builds a string like so: Juli, 1.-31.8.2016
     */
    public getPeriode(): string {
        return this.rechnungsLauf.gueltigAb!.format('MMMM, ') +
            this.rechnungsLauf.gueltigAb!.format('D.-') +
            this.rechnungsLauf.gueltigBis!.format('D.MM.YYYY');
    }

    public selectEntry(index: number): void {
        this.selectedEntryIndex = this.selectedEntryIndex === index ?
            undefined :
            index;
    }

    private goToOverview(): angular.IPromise<any> {
        const filter = new RechnungenFilter();
        filter.gueltigkeit.gueltigAb = this.rechnungsLauf.gueltigAb;
        filter.gueltigkeit.gueltigBis = this.rechnungsLauf.gueltigBis;

        return this.$state.go('base.kinderort.faktura.overview', {filter: filter.toUriParam()});
    }

    private getRechnungIdsFromEntries(entries: RechnungsLaufEntry[]): string[] {
        return entries.flatMap(entry => entry.empfaenger)
            .flatMap(empfaenger => checkPresent(empfaenger.rechnung!.id));
    }

    private getSelectedEntries(): RechnungsLaufEntry[] {
        return this.rechnungsLauf.entries
            .filter(entry => isPresent(entry.rechnungsUebermittlungsEinheitId))
            .filter(entry => this.ids[entry.rechnungsUebermittlungsEinheitId!]);
    }

    private downloadPdf(kitaId: KinderOrtId, entries: RechnungsLaufEntry[]): angular.IPromise<unknown> {
        const rechnungIds = this.getRechnungIdsFromEntries(entries);

        return this.kitaFakturaService.getTempBlobForZipRechnungenPDF(kitaId, rechnungIds)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .catch(error => {
                console.error('Konnte das Rechnungslauf-PDF nicht herunterladen', error);

                return this.$q.reject(error);
            });
    }

    private handleFakturaError(error: RechnungsLaufError): void {
        if (this.createRechnungsLaufPdf) {
            // unselect failed ids from selectedIds
            error.failedRechnungsUebermittlungsEinheiten.forEach(uebermittlungsEinheitError => {
                this.ids[uebermittlungsEinheitError.rechnungsUebermittlungsEinheitId] = false;
            });

            const selectedEntries = this.getSelectedEntries();
            const entries = this.showLimitToRechnungenWithDiff && this.limitPdfsToRechnungenWithDiff ?
                selectedEntries.filter(entry => entry.hasAbweichungToPrevious()) :
                selectedEntries;

            if (entries.length > 0) {
                this.downloadPdf(checkPresent(this.rechnungsLauf.kitaId), entries);
            }
        }

        const erroneousEntries: RechnungsLaufEntry[] = [];

        error.failedRechnungsUebermittlungsEinheiten.forEach(uebermittlungsEinheitError => {
            this.rechnungsLauf.entries.forEach(entry => {
                if (entry.rechnungsUebermittlungsEinheitId
                    !== uebermittlungsEinheitError.rechnungsUebermittlungsEinheitId) {
                    return;
                }

                // Copy, damit die onChanges Events ausgelöst werden
                const copy = angular.copy(entry);
                this.addErrors(copy, uebermittlungsEinheitError);
                erroneousEntries.push(copy);
            });
        });

        this.rechnungsLauf.entries = erroneousEntries;
        this.unselectAllEntries();
        this.selectAllEntries();
    }

    private addErrors(
        entry: RechnungsLaufEntry,
        uebermittlungsEinheitError: RechnungsUebermittlungsEinheitError,
    ): void {
        entry.empfaenger.forEach(empfaenger => {
            this.addErrorToEmpfaenger(empfaenger, uebermittlungsEinheitError);
        });
    }

    private addErrorToEmpfaenger(
        empfaenger: RechnungsLaufKontaktpersonEntry,
        uebermittlungsEinheitError: RechnungsUebermittlungsEinheitError,
    ): void {
        const found = uebermittlungsEinheitError.causes
            .filter(cause => cause.rechnungsRevisionId === empfaenger.rechnung!.rechnungsRevisionId);
        if (found.length === 1) {
            empfaenger.error = found[0];
        }
    }
}

componentConfig.controller = DvbKitaRechnungslaufFinish;
angular.module('kitAdmin').component('dvbKitaRechnungslaufFinish', componentConfig);
