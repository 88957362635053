/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExtraPlatzCategory, ExtraPlatzType, Kontingente, ZeitraumFeld} from '@dv/kitadmin/models';
import {ZeitraumUtil} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import moment from 'moment';
import {WochenplanUtil} from '../common/service/wochenplanUtil';
import {TempExtraZeitraumFeld} from './TempExtraZeitraumFeld';

export class TempExtraPlatz {

    private extraPlatzCategoryUpdated: boolean = false;

    private felderInternal: TempExtraZeitraumFeld[];

    // shallowcopy of the felderInternal array. Used so the felder cannot be modified by accessing getFelder()
    private felder: TempExtraZeitraumFeld[] = [];
    private unremoved: TempExtraZeitraumFeld[] = [];
    private newPlatz: boolean = false;
    private removed: boolean = false;
    private modified: boolean = false;

    /**
     * @param fraktionId
     * @param affectedDay
     * @param extraPlatzCategory
     * @param extraPlatzType
     * @param disabled if true, may not be modified
     * @param showKontingent
     * @param felder
     */
    public constructor(
        public fraktionId: string,
        public affectedDay: moment.Moment,
        public extraPlatzCategory: ExtraPlatzCategory,
        public extraPlatzType: ExtraPlatzType,
        public disabled: boolean = false,
        public showKontingent: boolean = true,
        felder: TempExtraZeitraumFeld[] = [],
    ) {
        this.felderInternal = felder;
        this.initFelder();
    }

    /**
     * Copy constructor.
     * Used to trigger change detection when for example the felder have changed.
     */
    public static copy(platz: TempExtraPlatz): TempExtraPlatz {
        checkPresent(platz);

        return new TempExtraPlatz(
            platz.fraktionId,
            moment(platz.affectedDay),
            platz.extraPlatzCategory,
            platz.extraPlatzType,
            platz.disabled,
            platz.showKontingent,
            platz.felder.slice());
    }

    public isExtraPlatzCategoryUpdated(): boolean {
        return this.extraPlatzCategoryUpdated;
    }

    public setExtraPlatzCategoryUpdated(value: boolean): void {
        this.extraPlatzCategoryUpdated = value;
    }

    public addFeld(
        feld: ZeitraumFeld,
        existingPlatzId: string | null,
        kontingent: Kontingente | null,
    ): void {

        const existingFeld = this.felderInternal.find(f => f.equals(feld) && f.kontingentEquals(kontingent));

        if (existingFeld) {
            existingFeld.removed = false;

        } else {
            const tempFeld = new TempExtraZeitraumFeld(feld.zeitraum, feld.dayOfWeek);
            tempFeld.existingPlatzId = existingPlatzId;
            tempFeld.selected = true;
            ZeitraumUtil.setExtraPlatzSelection(tempFeld, this.extraPlatzType);
            if (this.showKontingent) {
                WochenplanUtil.setKontingent(tempFeld, kontingent);
            }

            this.felderInternal.push(tempFeld);
        }

        this.initFelder();
    }

    /**
     * @return true, if the feld was removed. False otherwise.
     */
    public removeFeld(zeitraumFeld: ZeitraumFeld): boolean {
        const index = this.felderInternal.findIndex(element => element.equals(zeitraumFeld) && !element.removed);
        const feld = this.felderInternal[index];
        if (!feld) {
            return false;
        }

        if (feld.existingPlatzId) {
            // pre existing feld, mark it for removal
            feld.removed = true;
        } else {
            this.felderInternal.splice(index, 1);
        }
        this.initFelder();

        return true;
    }

    public removeAllFelder(): void {
        this.felderInternal = this.felderInternal
            .map(feld => {
                feld.removed = true;

                return feld;
            })
            .filter(feld => feld.existingPlatzId);

        this.initFelder();
    }

    public getUnremoved(): TempExtraZeitraumFeld[] {
        return this.unremoved;
    }

    public isNewPlatz(): boolean {
        return this.newPlatz;
    }

    public isRemoved(): boolean {
        return this.removed;
    }

    public isModified(): boolean {
        return this.modified;
    }

    public getFelder(): TempExtraZeitraumFeld[] {
        return this.felder;
    }

    /**
     * @return true if the feld is already added and was marked as not removed.
     */
    public undoRemove(zeitraumFeld: ZeitraumFeld): boolean {
        const existingFeld = this.felderInternal.find(feld => feld.equals(zeitraumFeld));
        if (existingFeld) {
            existingFeld.removed = false;
            this.initFelder();

            return true;
        }

        return false;
    }

    /**
     * Call this whenever the felder change, so we do not have to re calculate these things for every single place that
     * needs it.
     */
    private initFelder(): void {

        this.felder = [...this.felderInternal];
        this.unremoved = [];
        this.modified = false;
        this.newPlatz = true;
        this.removed = false;

        this.felderInternal.forEach(feld => {
            if (!feld.removed) {
                this.unremoved.push(feld);
            }
            if (feld.existingPlatzId) {
                this.newPlatz = false;
            }
            if (!feld.existingPlatzId || !feld.removed) {
                this.removed = false;
            }
            if ((feld.existingPlatzId && feld.removed) ?? !feld.existingPlatzId) {
                this.modified = true;
            }
        });

        this.removed = this.unremoved.length === 0 && this.felderInternal.length > 0;
    }
}
