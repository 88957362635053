/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import {CONFIG} from '../../../config';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {

    public constructor(
        private readonly http: HttpClient,
        private readonly errorService: ErrorService,
    ) {
    }

    public downloadFileByUrl(blob: TempBlob): void {

        this.errorService.handleValidationError(!blob.virusFound, 'ERRORS.ERR_DOWNLOAD_MALWARE');
        if (blob.virusFound) {
            return;
        }

        this.http.get(blob.getDownloadUrl(CONFIG.restBackend), {
            responseType: 'blob',
        })
            .subscribe({
                next: downloadBlob => {
                    const a = document.createElement('a');
                    const objectUrl = URL.createObjectURL(downloadBlob);
                    a.href = objectUrl;
                    a.download = blob.filename ?? 'download';
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                },
            });
    }
}
