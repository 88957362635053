<div class="dvb-kind-tarife-betreuungsgutscheine-content">

    <div ng-if="!vm.editMode">

        <dvb-kind-tarife-betreuungsgutschein-header-kitax
            ng-if="!!vm.betreuungsGutscheinGroup.schemaVersion"
            betreuungs-gutschein-group="vm.betreuungsGutscheinGroup">
            <middle>
                <p data-translate="COMMON.ZUGEWIESEN_PER"
                   data-translate-value-date="{{vm.betreuungsGutscheinGroup.timestampErstellt | amDateFormat:'D.M.YYYY HH:mm'}}">
                </p>
            </middle>
            <bottom>
                <div class="row normal-abstand-top" ng-if="vm.betreuungsGutscheinGroup.betreuungsGutscheine.length > 0">
                    <div class="col-xs-12">
                        <dvb-kitax-betreuungsgutscheine
                            betreuungs-gutscheine="vm.betreuungsGutscheinGroup.betreuungsGutscheine"
                            schema-version="vm.betreuungsGutscheinGroup.schemaVersion">
                        </dvb-kitax-betreuungsgutscheine>
                    </div>
                </div>
            </bottom>
        </dvb-kind-tarife-betreuungsgutschein-header-kitax>

        <dvb-kind-tarife-betreuungsgutschein-header-manuell
            ng-if="!vm.betreuungsGutscheinGroup.schemaVersion"
            betreuungs-gutschein="vm.betreuungsGutschein"
            betreuungs-gutschein-group="vm.betreuungsGutscheinGroup">
            <div class="row">
                <div class="col-xs-6">
                    <p ng-bind="vm.kita.getDisplayName()"></p>
                </div>
                <div class="col-xs-6">
                    <p data-translate="KIND.BG_PENSUM_PROZENT_VALUE"
                       data-translate-value-value="{{vm.betreuungsGutschein.bgPensumProzent}}"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                    <p data-translate="KIND.BETRAG_VOLLKOSTEN_VALUE"
                       data-translate-value-value="{{vm.betreuungsGutschein.vollkosten}}"></p>
                </div>
                <div class="col-xs-6">
                    <p data-translate="KIND.BETRAG_GUTSCHEIN_VALUE"
                       data-translate-value-value="{{vm.betreuungsGutschein.verguenstigterBetrag}}"></p>
                </div>
            </div>
        </dvb-kind-tarife-betreuungsgutschein-header-manuell>

        <div dvb-auth require-permission="{{'kita:manage:' + vm.kita.id}}">
            <ul class="list-inline normal-abstand-top">
                <li ng-if="!vm.betreuungsGutscheinGroup.schemaVersion">
                    <a href=""
                       ng-click="vm.editMode = true">
                        <span data-translate="COMMON.BEARBEITEN"></span>
                    </a>
                </li>
                <li>
                    <a href="" ng-click="vm.onDelete({betreuungsGutschein: vm.betreuungsGutschein})">
                        <span data-translate="COMMON.LOESCHEN"></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div ng-if="vm.editMode">
        <dvb-kind-tarife-betreuungsgutscheine-form
            kita="vm.kita"
            original-entry="vm.betreuungsGutschein"
            on-update="vm.update(betreuungsGutschein)"
            on-cancel="vm.editMode = false">
        </dvb-kind-tarife-betreuungsgutscheine-form>
    </div>
</div>
