<h2 translate="PERSONAL.ANGESTELLTE_ZUWEISUNG.TITLE"></h2>
@if (store.angestellte(); as angestellte) {
    <div class="header" #header>
        <dv-personalplanung-filter class="self-baseline"
                                   [displayMode]="timelineStore.displayMode()"
                                   (displayModeChange)="timelineStore.displayMode.set($event)"
                                   [selectedDate]="timelineStore.selectedDate()"
                                   (selectedDateChange)="timelineStore.selectedDate.set($event)"
                                   [fraktionFilter]="[]"
                                   [showTerminFilter]="false"
                                   [filterBedarfsRelevanteTermine]="false">
        </dv-personalplanung-filter>
        <dvlib-spinner class="self-baseline" [show]="store.zuweisungenLoading()"/>
        <dv-zuweisbare-dienste [dienste]="store.dienste()" [readonly]="true"/>
    </div>
}

<dv-timeline-calendar [groups]="store.groups"
                      [startDate]="timelineStore.startDate()"
                      [endDate]="timelineStore.endDate()"
                      [layerConfig]="store.timelineLayerConfig()"
                      [readonly]="true"
                      [calendarTranslation]="calendarTranslations"
                      [stickyHeaderPos]="calendarStickyTopPos()"
                      [scrollBeforeSticky]="calendarScrollBeforeSticky()">
</dv-timeline-calendar>

