/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import {
    BewilligtePlaetze,
    KinderOrtType,
    Kita,
    KitaBetreuungsfaktorRegel,
    KitaTarifeZeitraum,
    TagesEltern,
} from '@dv/kitadmin/models';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../../common/service/dvbStateService';
import type {KinderOrtService} from '../../../../common/service/rest/kinderort/kinderOrtService';
import type {MandantConfigurationService} from '../../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        hasTarife: '<',
        hasBetreuungsfaktorRegeln: '<',
    },
    template: require('./dvb-kinderort-eroeffnen.html'),
    controllerAs: 'vm',
};

export class DvbKinderortEroeffnen implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        '$translate',
        '$state',
        'errorService',
        'dvbStateService',
        'kinderOrtService',
        'mandantConfigurationService',
    ];

    public hasTarife!: boolean;
    public hasBetreuungsfaktorRegeln!: boolean;

    public readonly neuerKinderortForm!: angular.IFormController;

    public kinderOrt: KinderOrt = new Kita();
    public subventioniertAnzahl: { anzahl: number | null } = {anzahl: null};
    public bewilligtePlaetze: BewilligtePlaetze = new BewilligtePlaetze();
    public tarifeZeitraum: KitaTarifeZeitraum = new KitaTarifeZeitraum();
    public kinderOrtBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel = new KitaBetreuungsfaktorRegel();

    public isLoading: boolean = false;
    public showTarifeAuswahl: boolean = false;
    public showKitaBetreuungsfaktorRegelAuswahl: boolean = false;

    public constructor(
        private $translate: angular.translate.ITranslateService,
        private $state: StateService,
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
        private kinderOrtService: KinderOrtService,
        private mandantConfigurationService: MandantConfigurationService,
    ) {

    }

    public $onInit(): void {
        this.updateDType();
    }

    public updateDType(): void {
        this.subventioniertAnzahl = {anzahl: null};
        this.bewilligtePlaetze = new BewilligtePlaetze();
        this.tarifeZeitraum = new KitaTarifeZeitraum();
        this.tarifeZeitraum.initMissingAbrechnungsTarife();

        switch (this.kinderOrt.dtype) {
            case KinderOrtType.KITA:
                this.kinderOrt = new Kita();
                this.kinderOrt.titel = this.$translate.instant('KINDERORT.DEFAULT_TITLE.KITA');
                break;
            case KinderOrtType.TAGES_ELTERN:
                this.kinderOrt = new TagesEltern();
                this.kinderOrt.titel = this.$translate.instant('KINDERORT.DEFAULT_TITLE.TAGES_ELTERN');
                break;
            default:
                throw new Error(`DType not implemented: ${JSON.stringify(this.kinderOrt.dtype)}`);
        }

        this.mandantConfigurationService.getDefaultCountry().then(country => {
            this.kinderOrt.adresse.land = country;
        });

        this.errorService.clearAll();
        if (this.neuerKinderortForm) {
            this.neuerKinderortForm.$setPristine();
        }
    }

    public validateAndSaveKita(formIsValid: boolean): void {

        this.errorService.handleValidationError(formIsValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!formIsValid) {
            return;
        }

        const tarifeZeitraum = this.getTarife();
        const betreuungsfaktorRegel = this.getBetreuungsfaktorRegel();
        this.isLoading = true;

        this.kinderOrtService.create(
            this.kinderOrt,
            this.bewilligtePlaetze,
            (this.subventioniertAnzahl || {anzahl: 0}).anzahl ?? 0,
            tarifeZeitraum,
            betreuungsfaktorRegel,
        )
            .then(response => this.$state.go('base.kinderort.profil',
                {id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response)}))
            .finally(() => {
                this.isLoading = false;
            });
    }

    public getTarife(): KitaTarifeZeitraum | null {
        if (!this.showTarifeAuswahl) {
            return null;
        }

        const tarifeZeitraum = this.tarifeZeitraum.getCleanCopy();
        tarifeZeitraum.gueltigAb = this.bewilligtePlaetze.gueltigAb;

        return tarifeZeitraum;
    }

    public getBetreuungsfaktorRegel(): KitaBetreuungsfaktorRegel | null {
        if (!this.showKitaBetreuungsfaktorRegelAuswahl) {
            return null;
        }

        const betreuungsfaktorRegel = angular.copy(this.kinderOrtBetreuungsfaktorRegel);
        betreuungsfaktorRegel.gueltigAb = this.bewilligtePlaetze.gueltigAb;

        return betreuungsfaktorRegel;
    }

    public goBack(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }
}

componentConfig.controller = DvbKinderortEroeffnen;
angular.module('kitAdmin').component('dvbKinderortEroeffnen', componentConfig);
