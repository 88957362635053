<div class="dvb-wochen-plaetze">
    <div class="row text-center">
        <div class="col-xs-4 platz">
            <div class="small-abstand-bottom">
                <span ng-bind="vm.wochenPlaetze.maxPlaetzeProzent / 100 | roundTo:2"></span>
                <span class="custom-icon custom-icon-policy"></span>
            </div>
            <div data-translate="COMMON.KAPAZITAET_MAX_PLAETZE"></div>
        </div>
        <div class="col-xs-4 platz">
            <div class="small-abstand-bottom">
                <span ng-bind="vm.wochenPlaetze.plaetzeProzent / 100 | roundTo:2"></span>
                <span class="custom-icon custom-icon-target"></span>
            </div>
            <div data-translate="COMMON.KAPAZITAET_PLAETZE"></div>
        </div>
        <div class="col-xs-4 platz" ng-if="vm.showFreiePlaetze">
            <div class="small-abstand-bottom">
                <span
                    ng-bind="(vm.wochenPlaetze.plaetzeProzent - vm.wochenPlaetze.belegungProzent) / 100 | roundTo:2"></span>
                <span class="custom-icon custom-icon-limited"></span>
            </div>
            <div data-translate="COMMON.KAPAZITAET_FREIE_PLAETZE"></div>
        </div>
        <div class="col-xs-4 platz" ng-if="vm.showBelegtePlaetze">
            <div class="small-abstand-bottom">
                <span ng-bind="vm.wochenPlaetze.belegungProzent / 100 | roundTo:2"></span>
                <span class="custom-icon custom-icon-limited"></span>
            </div>
            <div data-translate="COMMON.KAPAZITAET_BELEGTE_PLAETZE"></div>
        </div>
    </div>
</div>
