<div class="ausbildung-node-checkbox">
    <div class="checkbox">
        <label>
            <input type="checkbox"
                   [name]="'ausbildung-selected-' + ausbildung.id"
                   [(ngModel)]="ausbildung.selected" />
            {{ ausbildung.name }}
        </label>
    </div>
    <div class="children small-abstand-left">
        @for (child of ausbildung.children; track child.id) {
            <dv-ausbildung-node-checkbox [ausbildung]="child" />
        }
    </div>
</div>
