/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IKibonRefNrInfo} from '@dv/kitadmin/models';
import {DvbUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {RefNrService} from '../../service/refNrService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kibonRefNrInfo: '<',
        verfuegungVersion: '<?',
        referenzNummerIgnoriert: '<?',
    },
    template: require('./dvb-kibon-ref-nummer.html'),
    controllerAs: 'vm',
};

export class DvbKibonRefNummer implements angular.IController {
    public static $inject: readonly string[] = ['$translate', 'refNrService'];

    private static readonly INDEX_PERIODE: number = 0;
    private static readonly INDEX_FALL_NR: number = 1;
    private static readonly INDEX_GEMEINDE: number = 2;

    public kibonRefNrInfo!: IKibonRefNrInfo;
    public verfuegungVersion?: number;
    public referenzNummerIgnoriert?: boolean;

    public text: string = '';

    public constructor(
        private readonly $translate: angular.translate.ITranslateService,
        private readonly refNrService: RefNrService,
    ) {
    }

    public $onInit(): void {
        const refNrSplitted = this.kibonRefNrInfo.getRefNr().split('.');

        this.refNrService.getGemeindeName(refNrSplitted[DvbKibonRefNummer.INDEX_GEMEINDE])
            .then(gemeindeName => {
                this.text = this.getTooltip(refNrSplitted, gemeindeName);
            });
        this.text = this.getTooltip(refNrSplitted);
    }

    private getTooltip(refNrSplitted: string[], gemeinde?: string): string {
        const yearStart = refNrSplitted[DvbKibonRefNummer.INDEX_PERIODE];
        const yearEnd = String(Number(yearStart) + 1);
        const params = {
            dateAb: `20${yearStart}`,
            dateBis: yearEnd,
        };
        const fallNr = refNrSplitted[DvbKibonRefNummer.INDEX_FALL_NR];

        const result = [
            this.$translate.instant('KIBON.REFERENZ_NUMMER_INFO.PERIOD', params),
            this.$translate.instant('KIBON.REFERENZ_NUMMER_INFO.FALL_NR', {fallNr}),
        ];
        if (DvbUtil.isNotEmptyString(gemeinde)) {
            result.push(this.$translate.instant('KIBON.REFERENZ_NUMMER_INFO.GEMEINDE', {gemeinde}));
        }
        if (isPresent(this.verfuegungVersion)) {
            const interpolateParams = {version: this.verfuegungVersion};
            result.push(this.$translate.instant('KIBON.VERFUEGUNGEN.VERSION_MF', interpolateParams, 'messageformat'));
        }

        if (this.referenzNummerIgnoriert) {
            result.push(this.$translate.instant('KIBON.REFERENZ_NUMMER_INFO.IGNORIERT'));
        }

        return result.join('\n');
    }
}

componentConfig.controller = DvbKibonRefNummer;
angular.module('kitAdmin').component('dvbKibonRefNummer', componentConfig);
