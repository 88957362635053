/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsPersonWithStatus, MonatlicheStundenErfassungFilterStates} from '@dv/kitadmin/models';
import {AbstractPagesController, MONATLICHE_STUNDEN_ERFASSUNG_FILTER_STATES} from '@dv/kitadmin/models';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import {FilterOptionHelper} from '../../../filter/shared/FilterOptionHelper';
import {STUNDENERFASSUNG_STATE} from '../../anstellung/anstellung-states';
import {StundenBlaetterFilter} from '../../stundenblaetter/models/StundenBlaetterFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-stunden-blaetter.html'),
    controllerAs: 'vm',
};

export class DvbStundenBlaetter extends AbstractPagesController<BetreuungsPersonWithStatus>
    implements angular.IController {
    public static override $inject: readonly string[] = ['$q', 'fraktionService'];

    public filter: StundenBlaetterFilter = new StundenBlaetterFilter();
    public states: MonatlicheStundenErfassungFilterStates[] = MONATLICHE_STUNDEN_ERFASSUNG_FILTER_STATES.values;
    public collapsed: boolean = true;

    public filterOptionHelper: FilterOptionHelper = new FilterOptionHelper(this.reloadItems.bind(this));

    public stundenblattState = STUNDENERFASSUNG_STATE;

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly $q: angular.IQService,
        private readonly fraktionService: FraktionService,
    ) {
        super();
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        this.setPagionationValues(this.filter);

        const config = {timeout: this.timeout.promise, includes: '(kita,angestellteId)'};

        this.fraktionService.getStundenblaetterFraktionen(this.filter, config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }

    public onReset(): void {
        this.filter.reset();
        this.reloadItems();
    }
}

componentConfig.controller = DvbStundenBlaetter;
angular.module('kitAdmin').component('dvbStundenBlaetter', componentConfig);
