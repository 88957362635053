<div class="title normal-abstand-top">
    <h2>{{ 'MANDANT.CONFIG.TITLE' | translate }}</h2>
</div>
@if (config$ | async; as config) {
    <select class="form-control"
            [name]="'defaultWorkTimeModel'"
            [(ngModel)]="config.defaultWorkTimeModelId"
            (ngModelChange)="updateConfig(config)"
            [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.DEFAULT' | translate"
            [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.DEFAULT' | translate"
            dvlibRequireRole="ADMIN">
        <option [ngValue]="null">{{ 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NO_DEFAULT' | translate }}</option>
        @for (model of workTimeModels$ | async; track model.id) {
            <option [ngValue]="model.id">
                {{ model.name }}
            </option>
        }
    </select>
    <select class="form-control"
            [name]="'defaultTerminType'"
            [(ngModel)]="config.defaultTerminTypeId"
            (ngModelChange)="updateConfig(config)"
            [tooltip]="'PERSONAL.TERMIN.TYPE.DEFAULT' | translate"
            [attr.aria-label]="'PERSONAL.TERMIN.TYPE.DEFAULT' | translate"
            dvlibRequireRole="ADMIN">
        <option [ngValue]="null">{{ 'PERSONAL.TERMIN.TYPE.NO_DEFAULT' | translate }}</option>
        @for (type of terminTypes$ | async; track type.id) {
            <option [ngValue]="type.id">
                {{ type.name }}
            </option>
        }
    </select>
}
