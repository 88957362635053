/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Bewerbung, PlatzTypen, Wochenplan, ZeitraumFeld} from '@dv/kitadmin/models';
import {ZeitraumUtil} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {DayOfWeek, KindergartenBelegung, Persisted} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {DvbBewerbungsplanService} from '../../service/dvbBewerbungsplanService';
import type {BewerbungenService} from '../../service/rest/kind/bewerbungenService';
import type {WochenplanService} from '../../service/rest/wochenplanService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        anmeldedatum: '<',
        bewerbung: '<',
        wochenplan: '<?',
        hideActionHeader: '<',
        bemerkung: '@',
        onDateSelected: '&',
    },
    template: require('./dvb-betreuungswunsch.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungswunsch implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'dvbBewerbungsplanService',
        'bewerbungenService',
        'authStore',
        'wochenplanService',
    ];

    public anmeldedatum!: moment.Moment;
    public bewerbung!: Persisted<Bewerbung>;
    public wochenplan?: Wochenplan;
    public hideActionHeader!: boolean;
    public bemerkung!: string;
    public onDateSelected!: (val: { date: moment.Moment }) => void;

    public isCollapsed: boolean = true;
    public showPlatzTypen: boolean = false;
    public platzTypen?: PlatzTypen;
    public kindergartenBelegung?: KindergartenBelegung;
    public enabledDays: DayOfWeek[] = [];
    public zeitraumFelder: ZeitraumFeld[] = [];
    public politischeGemeinde: string | null = null;

    public constructor(
        private readonly dvbBewerbungsplanService: DvbBewerbungsplanService,
        private readonly bewerbungenService: BewerbungenService,
        private readonly authStore: AuthStore,
        private readonly wochenplanService: WochenplanService,
    ) {
    }

    public $onInit(): void {
        if (!this.bewerbung) {
            return;
        }

        // TODO wollen wir hier die Firmenwuensche auch nach Kontingenten Filtern?
        this.platzTypen = this.bewerbung.platzTypen();
        const permissions = this.bewerbung.kitasIds.map(id => PERMISSION.KITA.CONTROL + id);
        this.showPlatzTypen = this.authStore.hasAnyPermission(permissions);

        this.kindergartenBelegung = this.platzTypen.kindergarten;

        this.bewerbungenService.getAktuelleAdresse(this.bewerbung.id, {cache: true}).then(adresse => {
            this.politischeGemeinde = adresse.gemeinde;
        });

        if (this.bewerbung.wochenplanId === this.wochenplan?.id) {
            this.initWithWochenplan(this.wochenplan);
        } else {
            this.wochenplanService.getWochenplanBewerbung(this.bewerbung)
                .then(wochenplan => this.initWithWochenplan(wochenplan));
        }
    }

    private initWithWochenplan(wochenplan: Wochenplan): void {
        this.wochenplan = wochenplan;
        this.zeitraumFelder = angular.copy(wochenplan.zeitraumFelder);
        this.dvbBewerbungsplanService.setSelectionToZeitraumFelder(wochenplan, this.bewerbung.belegteEinheiten,
            this.zeitraumFelder);

        this.isCollapsed = false;

        this.enabledDays = ZeitraumUtil.getWeekDaysFromWochenplan(wochenplan);
    }
}

componentConfig.controller = DvbBetreuungswunsch;
angular.module('kitAdmin').component('dvbBetreuungswunsch', componentConfig);
