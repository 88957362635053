<span class="dvb-search-result-icon fa-fw">
    <dvb-pensum-icon ng-if="vm.entry.symbol"
                     value="vm.entry.symbol"
                     tooltip="vm.title"
                     tooltip-translated="true"
                     inverted="vm.entry.entity !== 'FIRMENKONTINGENT'">
    </dvb-pensum-icon>

    <i class="fa"
       ng-if="!vm.entry.symbol"
       ng-class="{
            'KIND':'fa-child',
            'ANMELDUNG_KIND':'fa-child',
            'KONTAKTPERSON':'fa-male',
            'KITA':'fa-home',
            'TAGES_ELTERN':'fa-home',
            'GRUPPE':'fa-cube',
            'BETREUUNGS_PERSON':'fa-female',
            'BENUTZER':'fa-user',
            'ANGESTELLTE':'fa-user-o',
            'TARIF':'fa-calculator',
            'RECHNUNG':'fa-file-text-o',
            'BETREUUNGSFAKTOR_REGEL':'fa-book',
            'DOKUMENT_LAYOUT':'fa-arrows',
            'SCHLIESSTAGE':'custom-icon custom-icon-palmtree'
        }[vm.entry.entity]"
       uib-tooltip="{{vm.title}}"
       tooltip-placement="top-left"
       tooltip-append-to-body="true">
    </i>
</span>
