/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateService, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import {ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-report-main-category.html'),
    controllerAs: 'vm',
};

export class DvbReportMainCategory implements angular.IController {
    public static $inject: readonly string[] = ['$state', '$uiRouterGlobals', 'reportService'];

    public constructor(
        private $state: StateService,
        private $uiRouterGlobals: UIRouterGlobals,
        private reportService: ReportService,
    ) {
    }

    public getReports(): readonly ReportState[] {
        if (this.$state.includes('base.report.category.betrieb')) {
            return Object.values(ReportState.BETRIEB);
        }
        if (this.$state.includes('base.report.category.controlling')) {
            return Object.values(ReportState.CONTROLLING);
        }
        if (this.$state.includes('base.report.category.faktura')) {
            return Object.values(ReportState.FAKTURA);
        }
        if (this.$state.includes('base.report.category.personal')) {
            return Object.values(ReportState.PERSONAL);
        }

        throw new Error(`No reports for state ${this.getCurrentStateName()}`);
    }

    public getCurrentStateName(): string | undefined {
        return this.$uiRouterGlobals.current.name;
    }

    public goTo(state: string): void {
        // reloading with state name only reloads that specific state (and children)
        const reload = this.getCurrentStateName() === state ? false : state;

        this.reportService.updateStateParams({}, state, reload);
    }
}

componentConfig.controller = DvbReportMainCategory;
angular.module('kitAdmin').component('dvbReportMainCategory', componentConfig);
