/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Wochenplan} from '@dv/kitadmin/models';
import {Translation, WochenplanExternalAnmeldung} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {checkPresent, displayableComparator, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {WochenplanService} from '../../../common/service/rest/wochenplanService';
import type {ExternalAnmeldungConfigService} from '../../../external/service/externalAnmeldungConfigService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenplaeneExternalAnmeldung: '<?',
        onChange: '&',
    },
    template: require('./dvb-voreinstellungen-external-anmeldung-wochenplan.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenExternalAnmeldungWochenplan implements angular.IController {
    public static $inject: readonly string[] = ['externalAnmeldungConfigService', 'wochenplanService'];

    public wochenplaeneExternalAnmeldung: WochenplanExternalAnmeldung[] = [];
    public onChange!: FunctionType;

    public showInput: boolean = false;
    public searchInput: string | null = '';
    public availableWochenplaene: Wochenplan[] = [];
    private wochenplaene: Wochenplan[] = [];

    public constructor(
        private readonly externalAnmeldungConfigService: ExternalAnmeldungConfigService,
        private readonly wochenplanService: WochenplanService,
    ) {
    }

    public $onInit(): void {
        this.wochenplanService.getAllWochenplaene().then(plaene => {
            this.wochenplaene = plaene;
            this.initAvailableWochenplaene();
        });
    }

    public $onChanges(): void {
        this.initAvailableWochenplaene();
    }

    public addWochenplan(plan: Wochenplan): void {
        const anmeldungPlan = new WochenplanExternalAnmeldung(
            null,
            plan,
            new Translation(),
            new Translation(),
            'ZEITRAUM_NAMES');
        checkPresent(anmeldungPlan.nameTranslation).texts.DE = checkPresent(plan.name);
        this.wochenplaeneExternalAnmeldung.push(anmeldungPlan);

        DvbUtil.removeFromArray(plan, this.availableWochenplaene);
        this.searchInput = '';
        this.onChange();
    }

    public remove(plan: WochenplanExternalAnmeldung): void {
        DvbUtil.removeFromArray(plan, this.wochenplaeneExternalAnmeldung);
        this.availableWochenplaene.push(checkPresent(plan.wochenplan));
        this.availableWochenplaene.sort(displayableComparator);
        this.onChange();
    }

    private initAvailableWochenplaene(): void {
        this.availableWochenplaene = this.wochenplaene
            .filter(p => !this.wochenplaeneExternalAnmeldung.some(wp => wp.wochenplan?.id === p.id))
            .sort(displayableComparator);
    }
}

componentConfig.controller = DvbVoreinstellungenExternalAnmeldungWochenplan;
angular.module('kitAdmin').component('dvbVoreinstellungenExternalAnmeldungWochenplan', componentConfig);
