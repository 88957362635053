<div class="dvb-kontaktperson-offene-posten">
    <div class="filter-bar normal-abstand-bottom hidden-print">
        <ul class="filter-list">
            <li>
                <button class="btn label label-default label-radio"
                        type="button"
                        uib-btn-checkbox
                        ng-model="vm.showAll"
                        ng-click="vm.loadOffenePosten();"
                        data-translate="KONTAKTPERSON.ABGEWICKELTE_POSTEN_ANZEIGEN">
                </button>
            </li>
        </ul>

        <ul class="filter-list small-abstand-top">
            <li>
                <div data-translate="COMMON.KINDERORT.PLURAL"
                     class="filter-label"
                     ng-show="vm.kinderOrte.length > 0">
                </div>
                <ul class="filter-option-list">
                    <li ng-repeat="kinderOrt in vm.kinderOrte">
                        <button class="btn label label-default label-radio"
                                type="button"
                                uib-btn-checkbox
                                ng-model="vm.selectedKinderOrte[kinderOrt.id]"
                                ng-click="vm.updateKitaFilter(kinderOrt);"
                                ng-bind="kinderOrt.getDisplayName()">
                        </button>
                    </li>
                </ul>
            </li>
        </ul>
    </div>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <dvb-kontaktperson-offene-posten-liste is-loading="vm.isLoading"
                                                   interactive="true"
                                                   offene-posten="vm.offenePosten"
                                                   selected-kontaktperson-zahlung-id="vm.selectedKontaktpersonZahlungId">
            </dvb-kontaktperson-offene-posten-liste>
        </div>

        <div class="col-md-4 col-md-offset-1 hidden-print" dvb-auth require-permission="kita:manage:general">
            <h2 data-translate="COMMON.ERFASSEN"></h2>

            <p>
                <a ui-sref="base.kontaktperson.vorauszahlung">
                    + <span data-translate="KONTAKTPERSON.VORAUSZAHLUNG_ERFASSEN"></span>
                </a>
            </p>
            <p>
                <a ui-sref="base.kontaktperson.rueckerstattung">
                    + <span data-translate="KONTAKTPERSON.RUECKERSTATTUNG_ERFASSEN_LINK"></span>
                </a>
            </p>
            <p>
                <a ui-sref="base.kontaktperson.gebuehr">
                    + <span data-translate="KONTAKTPERSON.GEBUEHR_ERFASSEN"></span>
                </a>
            </p>

            <h2 data-translate="COMMON.GEBUEHR.DEPOT" class="normal-abstand-top"></h2>
            <dv-kontaktperson-depots [kontaktperson]="vm.kontaktperson"></dv-kontaktperson-depots>
        </div>
    </div>

</div>
