<div class="dvb-kita-rechnungslauf">
    <dvb-kita-rechnungslauf-erstellen ng-if="!vm.showFinish"
                                      kita="vm.kita"
                                      mandant="vm.mandant"
                                      periode="vm.periode"
                                      on-submit="vm.onSubmit(rechnungsLauf)">
    </dvb-kita-rechnungslauf-erstellen>

    <dvb-kita-rechnungslauf-finish ng-if="vm.showFinish"
                                   rechnungs-lauf="vm.rechnungsLauf"
                                   kita="vm.kita">
    </dvb-kita-rechnungslauf-finish>
</div>
