/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxExternalAnmeldungCriticalConfig} from '@dv/shared/backend/model/jax-external-anmeldung-critical-config';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {PublicExternalAnmeldungService} from './service/publicExternalAnmeldungService';

/* eslint-disable sonarjs/no-duplicate-string */

export const EXTERNAL_BASE_STATE = {
    name: 'base.external',
    abstract: true,
    url: '/external',
    data: {
        permission: null,
    },
} satisfies Ng1StateDeclaration;

export const EXTERNAL_ANMELDUNG_STATE = {
    name: `${EXTERNAL_BASE_STATE.name}.anmeldung`,
    url: '/anmeldung/{id:[0-9]+}',
    component: 'dvbExternalAnmeldung',
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'EXTERNAL_ANMELDUNG.TITLE'),
        ],
        mandantId: [
            '$transition$', (
                $transition$: Transition,
            ): string =>
                $transition$.params().id,
        ],
        criticalConfig: [
            'publicExternalAnmeldungService', 'mandantId', (
                publicExternalAnmeldungService: PublicExternalAnmeldungService,
                mandantId: string,
            ): angular.IPromise<JaxExternalAnmeldungCriticalConfig> =>
                publicExternalAnmeldungService.getCriticalConfig(mandantId),
        ],
    },
} satisfies Ng1StateDeclaration;

export const EXTERNAL_STATES = {
    EXTERNAL_BASE_STATE,
    EXTERNAL_ANMELDUNG_STATE,
};
