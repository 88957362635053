/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {CustomFieldConfiguration, ReportState} from '../../models';
import {ERR_VALIDATION} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        gueltigAb: '<?',
    },
    template: require('./dvb-report-belegungsplan-day-based.html'),
    controllerAs: 'vm',
};

export class DvbReportBelegungsplanDayBased implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', 'authStore', '$q'];

    public readonly reportState!: ReportState;
    public kinderOrt: SearchResultEntry | null = null;
    public gueltigAb: moment.Moment | null = null;

    public defaultStichtag: moment.Moment = DvbDateUtil.today();
    public gruppeSearchResultEntry: SearchResultEntry | null = null;

    public reportTemplate: ReportTemplate | null = null;
    public customFieldConfiguration: CustomFieldConfiguration | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private authStore: AuthStore,
        private $q: angular.IQService,
    ) {
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateState(): void {
        this.reportService.updateStateParams({
            gueltigAb: this.gueltigAb,
        });
    }

    public onSubmit(form: angular.IFormController): angular.IPromise<TempBlob> {
        this.errorService.clearAll();
        const formValid = form.$valid;
        const searchResultValid = !!this.gruppeSearchResultEntry || !!this.kinderOrt;

        this.errorService.handleValidationError(searchResultValid, 'ERRORS.ERR_KITA_UND_GRUPPE');
        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!formValid || !searchResultValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    public getKinderOrtFraktionPlaceholderKey(): string {
        return this.authStore.hasPermission(PERMISSION.MODULE.TAGESELTERN) ?
            'COMMON.GRUPPE_ODER_BETREUUNGS_PERSON.AUSWAEHLEN' :
            'COMMON.GRUPPE.AUSWAEHLEN';
    }

    private getDownloadPromise(): angular.IPromise<TempBlob> {
        const firstOfWeek = moment(this.gueltigAb ?? this.defaultStichtag).startOf('isoWeek');

        if (this.kinderOrt) {
            return this.reportService.getTempBlobForBelegungsplanDayBased(
                this.kinderOrt.id,
                firstOfWeek,
                this.reportTemplate?.id,
                this.customFieldConfiguration?.id ?? null);
        }

        if (this.gruppeSearchResultEntry) {
            return this.reportService.getTempBlobForBelegungsplanDayBasedByFraktion(
                this.gruppeSearchResultEntry.id,
                firstOfWeek,
                this.reportTemplate?.id,
                this.customFieldConfiguration?.id ?? null);
        }

        return this.$q.reject(ERR_VALIDATION);
    }
}

componentConfig.controller = DvbReportBelegungsplanDayBased;
angular.module('kitAdmin').component('dvbReportBelegungsplanDayBased', componentConfig);
