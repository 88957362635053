/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import type { Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import type {Translator} from '@dv/shared/translator';
import moment from 'moment';
import type {CalendarEvent} from '../../../../../calendar/timeline/model/CalendarEvent';
import {calendarEventIdGenerator} from '../../../../../calendar/timeline/model/CalendarEvent';
import type {Anstellung} from '../../../../../personal/anstellung/models/Anstellung';
import type {DailyAnstellung} from '../../../../../personal/anstellung/models/DailyAnstellung';
import {LayerType} from '../LayerType';
import type {DisplayMode} from '../personalplanung-display-mode';

export function verfuegbarkeitToCalendarEvent(
    anstellungen: Anstellung[],
    kinderOrt: Persisted<KinderOrt>,
    datesInRange: moment.Moment[],
    displayMode: DisplayMode,
    translator: Translator,
): CalendarEvent[] {

    return datesInRange.flatMap(date => {
        const anstellung = DvbDateUtil.getEntityOn(anstellungen, date);
        if (!anstellung) {
            return [];
        }

        const dayOfWeek = DvbDateUtil.getDayOfWeek(date.isoWeekday());

        return anstellung.anstellungStandorte
            .filter(standort => standort.alleKinderOrte || standort.kinderOrtIds.includes(kinderOrt.id))
            .flatMap(as => as.dailyAnstellungen)
            .filter(dailyAnstellung => dailyAnstellung.dayOfWeek === dayOfWeek)
            .flatMap(dailyAnstellung => {
                if (dailyAnstellung.nichtVerfuegbar) {
                    const von = moment(date).startOf('day');
                    const bis = moment(date).endOf('day');

                    return [createBlockerEvent(date, von, bis, translator)];
                }

                return displayMode === 'day' ?
                    verfuegbarkeitCalendarEventsFromZuweisungZeiten(dailyAnstellung, date, translator) :
                    [];
            });
    });
}

function verfuegbarkeitCalendarEventsFromZuweisungZeiten(
    da: DailyAnstellung,
    date: moment.Moment,
    translator: Translator,
): CalendarEvent[] {
    return da.anstellungZeiten.reduce<CalendarEvent[]>((acc, az, index, array) => {
        const jaxVon = DvbRestUtil.localeHHMMTimeToMoment(az.von);
        const jaxBis = DvbRestUtil.localeHHMMTimeToMoment(az.bis);

        const range = {
            von: DvbDateUtil.setTime(moment(date), jaxVon!),
            bis: DvbDateUtil.setTime(moment(date), jaxBis!),
        };

        if (index === 0 && !jaxVon?.isSame(moment(date).startOf('day'))) {
            acc.push(createBlockerEvent(date, moment(date).startOf('day'), range.von, translator));
        }

        if (index === array.length - 1 && !jaxBis?.isSame(moment(date).endOf('day'))) {
            acc.push(createBlockerEvent(date, range.bis, moment(date).endOf('day'), translator));

            return acc;
        }

        acc.push(createBlockerEvent(
            date,
            range.bis,
            DvbRestUtil.localeHHMMTimeToMoment(array[index + 1].von),
            translator));

        return acc;
    }, []);
}

function createBlockerEvent(
    date: moment.Moment,
    von: moment.Moment | null,
    bis: moment.Moment | null,
    translator: Translator,
): CalendarEvent {
    return {
        id: calendarEventIdGenerator(),
        layer: LayerType.VERFUEGBARKEIT,
        backgroundColor: '',
        textColor: '',
        hasHighLuminance: false,
        gueltigAb: moment(date),
        gueltigBis: moment(date),
        von,
        bis,
        tooltip: translator.instant(
            'REPORT.PERSONALPLANUNG.EVENT_TOOLTIP_NOT_AVAILABLE',
            {von: von?.format('HH:mm'), bis: bis?.format('HH:mm')}),
        completeDuration: 0,
        spaceBefore: 0,
        spaceAfter: 0,
        getDisplayName: () => '',
        subEvents: [],
    };
}
