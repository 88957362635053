<div class="dvb-mandant-sprachauswahl">
    <div class="title normal-abstand-top">
        <h2 data-translate="MANDANT.LANGUAGES"></h2>
    </div>
    <div class="checkbox" ng-repeat="lang in vm.languages">
            <span class="fa smaller-abstand-right"
                  ng-class="{
                  'fa-star': lang === vm.mandant.defaultLanguage,
                  'fa-star-o': lang !== vm.mandant.defaultLanguage}"
                  ng-model="vm.mandant.defaultLanguage"
                  ng-change="vm.onUpdate()"
                  ng-disabled="!vm.canUpdate"
                  uib-tooltip="{{'MANDANT.DEFAULT_LANGUAGE' | translate}}"
                  tooltip-placement="top-left"
                  uib-btn-radio="lang">
            </span>
        <label uib-tooltip="{{'MANDANT.SUPPORTED_LANGUAGE' | translate}}"
               tooltip-placement="top-left">
            <input type="checkbox"
                   name="supportedLanguage"
                   ng-disabled="!vm.canUpdate || lang === vm.mandant.defaultLanguage"
                   ng-checked="lang === vm.mandant.defaultLanguage || vm.mandant.supportedLanguages.includes(lang)"
                   ng-click="vm.toggleLanguage(lang)">
            <span data-translate="{{'MANDANT.' + lang}}"></span>
        </label>
    </div>
</div>
