/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {Termin} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {LoadingState} from '@dv/shared/angular';
import type {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, isNullish, isPersisted} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {from, Subject, take, tap} from 'rxjs';
import type {Angestellte} from '../../anstellung/models/Angestellte';
import type {TerminService} from '../terminService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        angestellte: '<',
        kinderOrt: '<',
        terminTypes: '<',
    },
    template: require('./dvb-termine.html'),
    controllerAs: 'vm',
};

export class DvbTermine implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'terminService',
        'dialogService',
    ];

    public angestellte?: Persisted<Angestellte>;
    public kinderOrt?: Persisted<KinderOrt>;
    public terminTypes: JaxTerminType[] = [];

    public kinderOrte: { [terminId: string]: Persisted<KinderOrt>[] } = {};

    public isLoading: boolean = false;
    public year: moment.Moment = DvbDateUtil.startOfYear();
    public termine: Termin[] = [];

    public newTerminFormVisible: boolean = false;
    public newTermin!: Termin;
    private destroy$: Subject<void> = new Subject<void>();

    public editTermin?: Termin;
    public openDialog: boolean = false;

    public constructor(
        private $state: StateService,
        private terminService: TerminService,
        private dialogService: DialogService,
    ) {
    }

    public $onDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public $onChanges(): void {
        this.initNewTermin();
        this.loadTermine();
    }

    public loadTermine(): void {
        this.isLoading = true;
        const params = {
            gueltigAb: this.year,
            gueltigBis: DvbDateUtil.endOfYear(moment(this.year)),
        };

        const terminePromise = this.angestellte ?
            this.terminService.getForAngestellte(this.angestellte.id, params) :
            this.terminService.getForKinderOrt(this.kinderOrt!.id, params);

        terminePromise.then(termine => {
            this.termine = termine;
            this.initTerminKinderOrte();
        })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public initTerminKinderOrte(): void {
        this.kinderOrte = {};
        const kinderOrt = this.kinderOrt;

        if (kinderOrt) {
            this.termine.forEach(termin => {
                this.kinderOrte[termin.id!] = [kinderOrt];
            });
        }
    }

    public save(termin: Termin, context: LoadingState): void {
        context.startLoading();
        const promise = isPersisted(termin) ?
            this.terminService.update(termin) :
            this.terminService.create(termin);

        promise.then(() => this.$state.reload())
            .finally(() => context.finishLoading());
    }

    public remove(termin: Persisted<Termin>): void {

        this.dialogService.openDeleteDialog({
            entityText: 'PERSONAL.TERMIN.SINGULAR',
            confirm: () => from(this.terminService.delete(termin.id))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public isEditable(termin: Persisted<Termin>): boolean {
        if (isNullish(this.angestellte)) {
            return true;
        }

        return termin.angestellteIds?.includes(this.angestellte.id) ?? false;
    }

    public openTerminDialog(termin: Termin): void {
        this.editTermin = termin;
        this.openDialog = true;
    }

    private initNewTermin(): void {
        this.newTermin = new Termin(null);

        if (this.angestellte) {
            this.newTermin.alleAngestellte = false;
            this.newTermin.alleKinderOrte = true;
            this.newTermin.angestellteIds = [this.angestellte.id];
        } else if (this.kinderOrt) {
            this.newTermin.alleKinderOrte = false;
            this.newTermin.alleAngestellte = true;
            this.newTermin.kinderOrtIds = [this.kinderOrt.id];
        }
    }
}

componentConfig.controller = DvbTermine;
angular.module('kitAdmin').component('dvbTermine', componentConfig);
