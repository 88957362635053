<div class="dvb-communication-kind-sent">

    <div class="panel panel-list-container">
        <div class="panel-heading"
             data-translate="COMMUNICATION.SENT.MF"
             data-translate-value-count="{{vm.bringAbholZeitenAccess.length}}"
             data-translate-value-showcount="true"
             data-translate-interpolation="messageformat"></div>
        <div class="panel-subtitle">
            <div class="row">
                <div class="col-xs-3" data-translate="COMMON.KIND.SINGULAR"></div>
                <div class="col-xs-3" data-translate="COMMON.KONTAKT"></div>
                <div class="col-xs-3" data-translate="COMMUNICATION.DEADLINE"></div>
                <div class="col-xs-3" data-translate="COMMUNICATION.CONTACT_USER_MUTIERT"></div>
            </div>
        </div>
        <div class="panel-list scrollable with-header">
            <div class="list-item"
                 ng-repeat="access in vm.bringAbholZeitenAccess | orderBy:vm.accessComparator"
                 ng-init="kontaktperson = access.kind.findAnyHauptkontaktRelationship().kontaktperson">
                <div class="row">
                    <div class="col-xs-3">
                        <div class="checkbox floating-text">
                            <label>
                                <input type="checkbox"
                                       ng-model="vm.selected[access.kind.id]"
                                       ng-value="{{acces.kind}}"
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       on-unauthorised-disable>
                                <span ng-bind="access.kind.getDisplayName()"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-3">
                        <p>
                            <span ng-bind="access.kontaktperson.getDisplayName()"
                                  uib-tooltip="{{access.kontaktperson.email}}"
                                  tooltip-placement="top-left"
                                  tooltip-append-to-body="true">
                            </span>
                            <i class="fa fa-exclamation-circle"
                               ng-if="kontaktperson.id !== access.kontaktperson.id"
                               uib-tooltip="{{'COMMUNICATION.KONTAKT_MISMATCH' | translate}}"
                               tooltip-append-to-body="true">
                            </i>
                        </p>
                    </div>
                    <div class="col-xs-3">
                        <p ng-bind="vm.getDeadline(access)"></p>
                    </div>
                    <div class="col-xs-3">
                        <p ng-bind="access.contactUserTimestampMutiert | amDateFormat:'D.M.YYYY HH:mm'"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <ul dvb-auth require-permission="kita:manage:general"
            class="list-inline small-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectAll()"
                        data-translate="COMMON.ALLE_AUSWAEHLEN">
                </button>
            </li>
            <li>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.selectNone()"
                        data-translate="COMMON.KEINE_AUSWAEHLEN">
                </button>
            </li>
        </ul>
        <ul class="list-inline normal-abstand-top">
            <li dvb-auth require-permission="kita:manage:general">
                <button class="btn btn-primary"
                        ng-click="vm.resend()"
                        data-translate="COMMUNICATION.RESEND_EMAILS.MF"
                        data-translate-value-count="{{vm.getSelectionCount()}}"
                        data-translate-value-showcount="true"
                        data-translate-interpolation="messageformat">
                </button>
            </li>
            <li dvb-auth require-permission="kita:manage:general">
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.adjustDeadline()"
                        data-translate="KIND.MONATSBELEGUNG.ADJUST_DEADLINE">
                </button>
            </li>
            <li dvb-auth require-permission="kita:manage:general">
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.withdrawFromParents()"
                        data-translate="KIND.MONATSBELEGUNG.WITHDRAW">
                </button>
            </li>
        </ul>
    </div>

</div>
