/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Einzahlungsschein} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class DvbFakturaService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/faktura/dvb`;
    private static readonly EINZAHLUNGSSCHEINE_URL: string = `${DvbFakturaService.BASE_URL}/einzahlungsscheine`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public createEinzahlungsschein(einzahlungsschein: Einzahlungsschein): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(DvbFakturaService.EINZAHLUNGSSCHEINE_URL, einzahlungsschein.toRestObject());
    }

    public getAllEinzahlungsscheine(): angular.IPromise<Einzahlungsschein[]> {
        const url = DvbFakturaService.EINZAHLUNGSSCHEINE_URL;

        return DvbRestUtilAngularJS.getModelsArray(url, Einzahlungsschein, 'einzahlungsscheine');
    }

    public getEinzahlungsschein(
        id: string,
        params?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Einzahlungsschein | null> {
        const url = DvbFakturaService.EINZAHLUNGSSCHEINE_URL;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, Einzahlungsschein, id, params);
    }

    public updateEinzahlungsschein(einzahlungsschein: Einzahlungsschein): angular.IPromise<unknown> {
        const id = checkPresent(einzahlungsschein.id);

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${DvbFakturaService.EINZAHLUNGSSCHEINE_URL}/${encodeURIComponent(id)}`;

        return this.$http.put(url, einzahlungsschein.toRestObject());
    }

    public deleteEinzahlungsschein(id: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${DvbFakturaService.EINZAHLUNGSSCHEINE_URL}/${encodeURIComponent(id)}`);
    }
}
