<div class="dvb-kibon-betreuungs-anfragen">

    <dvb-kibon-entity-matching-overview translation-keys="vm.translations"
                                        action-handler="vm.actionHandler"
                                        actions="vm.actions"
                                        filter="vm.filter"
                                        states="vm.states">
        <right-column>
            <h3 class="normal-abstand-top" data-translate="KIBON.ANFRAGEN.ACHTUNG_HEADER"></h3>
            <p data-translate="KIBON.ANFRAGEN.ACHTUNG_TEXT"></p>

            <div class="big-abstand-top">
                <dvb-kibon-maintenance dvb-auth
                                       require-role="MANDANT_ADMIN"
                                       on-query-api="vm.queryApi(params, formContext)">
                </dvb-kibon-maintenance>
            </div>
        </right-column>
    </dvb-kibon-entity-matching-overview>
</div>
