/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AbstractKontingent} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

angular.module('kitAdmin').filter('sortKontingenteByStichtag', sortKontingenteByStichtag);

sortKontingenteByStichtag.$inject = ['$filter'];

function sortKontingenteByStichtag($filter: angular.IFilterService):
    (kontingente: AbstractKontingent<any, any>[], stichtag: moment.Moment) => (AbstractKontingent<any, any>[]) {

    return (kontingente, stichtag): AbstractKontingent<any, any>[] => {
        if (!kontingente) {
            return [];
        }

        const gueltigOnStichtagOrLater: AbstractKontingent<any, any>[] = [];
        const notGueltigOnStichtag: AbstractKontingent<any, any>[] = [];

        kontingente.forEach(fk => {
            const isGueltigOnStichtagOrLater = fk.values.some(
                val => DvbDateUtil.isGueltigOn(val, stichtag) || val.gueltigBis.isAfter(stichtag));

            if (isGueltigOnStichtagOrLater) {
                gueltigOnStichtagOrLater.push(fk);
            } else {
                notGueltigOnStichtag.push(fk);
            }
        });

        const notGueltigSorted = $filter('orderBy')(notGueltigOnStichtag, 'name', false);

        return $filter('orderBy')(gueltigOnStichtagOrLater, 'name', false).concat(notGueltigSorted);
    };
}
