<div class="dvb-kind-tarife dvb-form">
    <div ng-if="vm.tarifParameter.length > 0" class="row kind-tarif-parameter">
        <div class="col-md-7">
            <h2 data-translate="KIND.PARAMETER"></h2>

            <p ng-if="vm.kindParameter.entries.length === 0"
               class="normal-abstand-bottom"
               data-translate="KIND.KEINE_KIND_TARIF_PARAMETER_HISTORY_ENTRIES">
            </p>

            <dvb-kind-tarife-parameter on-update="vm.createParam(tarifParameterHistoryEntry)"
                                       on-delete="vm.confirmDeleteParam(tarifParameterHistoryEntry)"
                                       on-revert="vm.revertParam(tarifParameterHistoryEntry)"
                                       kind-parameter="vm.kindParameter"
                                       tarif-parameter="vm.tarifParameter">
            </dvb-kind-tarife-parameter>

            <div class="normal-abstand-top" dvb-auth require-permission="kita:manage:general">
                <ul class="list-inline" ng-if="!vm.newParamFormIsVisible">
                    <li>
                        <button type="button"
                                class="btn btn-primary"
                                data-translate="KIND.NEUE_PARAMETER_ERFASSEN"
                                ng-click="vm.newParamFormIsVisible = true">
                        </button>
                    </li>
                    <li>
                        <a href=""
                           ng-if="vm.kindParameter.entries.length > 0"
                           data-translate="KIND.LEISTUNGEN.KIND_PARAMETER_BEENDEN"
                           ng-click="vm.terminateKindParameterModal()">
                        </a>
                    </li>
                </ul>

                <div ng-if="vm.newParamFormIsVisible" class="big-abstand-top">
                    <h2 data-translate="KIND.NEUE_PARAMETER_ERFASSEN_TITLE"></h2>

                    <dvb-kind-tarife-parameter-form tarif-parameter="vm.tarifParameter"
                                                    relevant-parameter="vm.kindParameter.relevantParameterIds"
                                                    on-cancel="vm.cancelParam()"
                                                    on-update="vm.createParam(tarifParameterHistoryEntry)">
                    </dvb-kind-tarife-parameter-form>
                </div>
            </div>
        </div>
    </div>

    <div class="row" ng-class="{'big-abstand-top' : vm.tarifParameter.length > 0}">
        <div class="col-md-7">
            <div ng-if="!vm.hasBetreuungsGutscheine && !vm.newGutscheinFormIsVisible">
                <h2 data-translate="KIND.BETREUUNGSGUTSCHEINE"></h2>

                <p class="form-row" data-translate="KIND.KEINE_BETREUUNGSGUTSCHEINE"></p>
            </div>

            <div class="big-abstand-bottom normal-abstand-bottom"
                 ng-repeat="(id, betreuungsGutscheineGroups) in vm.betreuungsGutscheineByKinderOrt"
                 ng-init="kinderOrt = vm.kinderOrte[id]">
                <dvb-kind-tarife-betreuungsgutscheine on-update="vm.createGutschein(betreuungsGutschein)"
                                                      on-delete="vm.confirmDeleteGutschein(betreuungsGutscheinGroup)"
                                                      kita="kinderOrt"
                                                      betreuungs-gutscheine-groups="betreuungsGutscheineGroups">
                </dvb-kind-tarife-betreuungsgutscheine>
            </div>

            <div dvb-auth
                 require-permission="kita:manage:general"
                 require-condition="!vm.newGutscheinFormIsVisible"
                 class="normal-abstand-bottom">
                <ul class="list-inline">
                    <li>
                        <div class="btn-group" uib-dropdown data-keyboard-nav>
                            <button type="button" class="btn btn-primary dropdown-toggle" uib-dropdown-toggle>
                                <span data-translate="KIND.NEUER_GUTSCHEIN_ERFASSEN"></span>
                                <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                                <li>
                                    <a href=""
                                       data-translate="KIND.MANUELLER_BETREUUNGSGUTSCHEIN"
                                       ng-click="vm.newGutscheinFormIsVisible = true"></a>
                                </li>
                                <li>
                                    <a ui-sref="base.kind.tarife.kitax"
                                       data-translate="KIND.KITAX.KITAX_KIBON_VERFUEGUNG"></a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <div ng-if="vm.newGutscheinFormIsVisible">
                <h2 data-translate="KIND.NEUER_GUTSCHEIN_ERFASSEN_TITLE"></h2>

                <dvb-kind-tarife-betreuungsgutscheine-form on-cancel="vm.cancelGutschein()"
                                                           on-update="vm.createGutschein(betreuungsGutschein)">
                </dvb-kind-tarife-betreuungsgutscheine-form>
            </div>

        </div>
    </div>

    <div class="row big-abstand-top">
        <div class="col-md-7">
            <div ng-if="vm.stundenKontingente.length === 0 && !vm.newStundenKontingentFormIsVisible">
                <h2 data-translate="KIND.STUNDENKONTINGENT"></h2>

                <p class="form-row" data-translate="KIND.KEINE_STUNDENKONTINGENTE"></p>
            </div>

            <div class="big-abstand-bottom"
                 ng-repeat="(id, byPlatzTyp) in vm.stundenKontingenteByKinderOrt"
                 ng-if="byPlatzTyp.length > 0">

                <dvb-stunden-kontingent-history history="byPlatzTyp"
                                                kind="vm.kind"
                                                kinder-ort="vm.kinderOrte[id]"
                                                on-delete="vm.removeStundenKontingent(stundenKontingent, platzTypEntry)">
                </dvb-stunden-kontingent-history>
            </div>

            <div class="normal-abstand-top"
                 dvb-auth
                 require-permission="kita:manage:general"
                 require-condition="!vm.newStundenKontingentFormIsVisible">
                <ul class="list-inline">
                    <li>
                        <button type="button"
                                class="btn btn-primary"
                                data-translate="KIND.NEUE_STUNDENKONTINGENTE_ERFASSEN"
                                ng-click="vm.newStundenKontingentFormIsVisible = true">
                        </button>
                    </li>
                </ul>
            </div>

            <div dvb-auth
                 require-permission="kita:manage:general"
                 require-condition="vm.newStundenKontingentFormIsVisible"
                 ng-class="{'big-abstand-top': vm.stundenKontingente.length > 0}">
                <h2 data-translate="KIND.NEUE_STUNDENKONTINGENTE_ERFASSEN_TITLE"></h2>

                <dvb-stunden-kontingent-form tarif-parameter="vm.tarifParameter"
                                             on-cancel="vm.cancelStundenKontingent()"
                                             on-update="vm.createStundenKontingent(stundenKontingent, formContext)"
                                             kind="vm.kind">
                </dvb-stunden-kontingent-form>
            </div>
        </div>
    </div>

    <div class="row" ng-if="vm.showAnwesenheitsSoll">
        <div class="col-md-7">
            <dvb-anwesenheits-soll kind="vm.kind"></dvb-anwesenheits-soll>
        </div>
    </div>

</div>
