<div>
    <div class="modal-body">
        @if (anmeldung.assignedKind) {
            <h1 [translate]="'EXTERNAL_ANMELDUNG.DELETE.ZUGEWIESENE_KIND_TEXT'"></h1>
        }
        @if (!anmeldung.assignedKind) {
            <h1 [translate]="'COMMON.CONFIRM_DELETE_ENTITY'"
                [translateParams]="{entity: anmeldung.getDisplayName()}">
            </h1>
        }
    </div>
    <div class="modal-footer">
        <dvlib-button-list>
            @if (anmeldung.assignedKind) {
                <button type="button"
                        class="btn btn-primary"
                        (click)="submit(true)"
                        [translate]="'EXTERNAL_ANMELDUNG.DELETE.DELETE_CHILD'">
                </button>
            }
            @if (anmeldung.assignedKind) {
                <button type="button"
                        class="btn btn-primary"
                        (click)="submit(false)"
                        [translate]="'EXTERNAL_ANMELDUNG.DELETE.DELETE_ASSIGNMENT'">
                </button>
            }
            @if (!anmeldung.assignedKind) {
                <button type="button"
                        class="btn btn-primary"
                        (click)="submit(false)"
                        [translate]="'COMMON.BESTAETIGEN'">
                </button>
            }
            <button class="btn btn-link" [translate]="'COMMON.ABBRECHEN'" (click)="hide()"></button>
        </dvlib-button-list>
    </div>
</div>
