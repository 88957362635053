/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';

export class KinderOrtWocheService {
    public static $inject: readonly string[] = ['$q'];

    private httpRequestCanceller: angular.IDeferred<unknown>;
    private firstOfWeekByKitaId: { [index: string]: moment.Moment } = {};

    public constructor(
        private readonly $q: angular.IQService,
    ) {
        this.httpRequestCanceller = this.$q.defer<unknown>();
    }

    public getHttpRequestTimeoutPromise(): angular.IPromise<unknown> {
        return this.httpRequestCanceller.promise;
    }

    public cancelPendingHttpRequests(): void {
        DvbRestUtilAngularJS.cancelRequest(this.httpRequestCanceller);
        // setup a new request canceller
        this.httpRequestCanceller = this.$q.defer();
    }

    public getLastFirstOfWeek(kitaId: string): moment.Moment {
        return this.firstOfWeekByKitaId[kitaId] || DvbDateUtil.startOfWeek();
    }

    public setLastFirstOfWeek(lastFirstOfWeek: moment.Moment, kitaId: string): void {
        this.firstOfWeekByKitaId[kitaId] = lastFirstOfWeek;
    }
}
