<div class="dvb-rechnungs-konfiguration-form">
    <div class="row">
        <div class="col-md-8">
            <input type="text"
                   name="name"
                   class="form-control"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                   uib-tooltip="{{'COMMON.NAME' | translate}}"
                   tooltip-placement="top-left"
                   required
                   ng-model="vm.konfiguration.name">

            <div class="checkbox form-row" ng-if="!vm.isNew">
                <label>
                    <input type="checkbox" ng-model="vm.isDisabled" ng-change="vm.toggleDisabled()">
                    <span data-translate="MANDANT.DISABLED"></span>
                </label>
            </div>

            <dvb-small-invoice-mandant-konfiguration-form
                ng-if="vm.konfiguration.dtype === vm.konfigurationenTypen.SMALLINVOICE"
                konfiguration="vm.konfiguration.konfiguration">
            </dvb-small-invoice-mandant-konfiguration-form>

            <dvb-mandant-konfiguration-form
                ng-if="vm.konfiguration.dtype === vm.konfigurationenTypen.DVB"
                konfiguration="vm.konfiguration.konfiguration"
                konten="vm.konten"
                konto="vm.konfiguration.konto">
            </dvb-mandant-konfiguration-form>
        </div>
    </div>
</div>
