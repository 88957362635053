/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry, Rechnung} from '@dv/kitadmin/models';
import {AbstractPagesController, RechnungDisplayMode} from '@dv/kitadmin/models';
import type {MatrixParams} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {FakturaService} from '../../../../../common/service/rest/fakturaService';
import type {RechnungenFilter} from '../../../../../filter/RechnungenFilter';
import type {BankStatementService} from '../../../../service/bankStatementService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        bankStatementEntry: '<',
    },
    template: require('./dvb-transaktion-zuweisen-alle.html'),
    controllerAs: 'vm',
};

export class DvbTransaktionZuweisenAlle
    extends AbstractPagesController<Rechnung> implements angular.IController {

    public static override $inject: readonly string[] = ['fakturaService', 'bankStatementService', '$q'];

    public bankStatementEntry!: BankStatementEntry;

    public filter: RechnungenFilter | null = null;
    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.TRANSAKTION;

    private timeout?: angular.IDeferred<any> = undefined;

    public constructor(
        private readonly fakturaService: FakturaService,
        private readonly bankStatementService: BankStatementService,
        private readonly $q: angular.IQService,
    ) {
        super();
    }

    public override $onInit(): void {
        this.itemsPerPage = this.bankStatementService.getItemsPerPageAlle();
        this.filter = this.bankStatementService.getRechnungenFilter();
        this.filter.gueltigkeit.setCurrentMonthWhenUndefined();

        super.$onInit();
    }

    public override onUpdateItemsPerPage(itemsPerPage: number): void {
        this.bankStatementService.setItemsPerPageAlle(itemsPerPage);
        super.onUpdateItemsPerPage(itemsPerPage);
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const params: MatrixParams = {
            includes: '(nestedIncludes.fields(ausstehenderBetrag,emailVersandHistory))',
        };
        this.setPagionationValues(params);

        const config = {timeout: this.timeout.promise};

        this.fakturaService.getRechnungen(checkPresent(this.filter), params, config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }
}

componentConfig.controller = DvbTransaktionZuweisenAlle;
angular.module('kitAdmin').component('dvbTransaktionZuweisenAlle', componentConfig);
