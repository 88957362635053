/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TimelineCalculationDayBasedStrategy} from './TimelineCalculationDayBasedStrategy';
import {TimelineCalculationHourBasedStrategy} from './TimelineCalculationHourBasedStrategy';
import type {TimelineCalculationStrategy} from './TimelineCalculationStrategy';

export type TimelineCalculationStrategies = 'hour' | 'day';

export const TIMELINE_CALCULATION_STRATEGY_MAP: Record<TimelineCalculationStrategies, TimelineCalculationStrategy> = {
    hour: new TimelineCalculationHourBasedStrategy(),
    day: new TimelineCalculationDayBasedStrategy(),
};
