/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import {SchliesstagDateRange} from '../../models/SchliesstagDateRange';
import {Schliesstage} from '../../models/Schliesstage';
import * as schliesstageStates from '../../schliesstage-states';
import type {SchliesstageService} from '../../service/schliesstageService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-schliesstage-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbSchliesstageErstellen implements angular.IOnInit {
    public static $inject: readonly string[] = ['$state', 'errorService', 'schliesstageService', 'dvbStateService'];

    public isLoading: boolean = false;
    public schliesstage: Schliesstage = new Schliesstage();

    public constructor(
        private $state: StateService,
        private errorService: ErrorService,
        private schliesstageService: SchliesstageService,
        private dvbStateService: DvbStateService,
    ) {
    }

    public $onInit(): void {
        this.schliesstage.addSchliesstagDateRange(new SchliesstagDateRange());
    }

    public submit(): void {
        const isValid = this.schliesstage.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return;
        }

        this.isLoading = true;

        this.schliesstageService.createOrUpdateSchliesstag(this.schliesstage).then(response => {
            this.$state.go(
                schliesstageStates.BASE_STATE.name,
                {id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response)},
            );
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public cancel(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }
}

componentConfig.controller = DvbSchliesstageErstellen;
angular.module('kitAdmin').component('dvbSchliesstageErstellen', componentConfig);
