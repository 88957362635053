/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsPerson, Kind, KinderOrtFraktionId, KinderOrtId, TarifParameter} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {KorrekturType, Persisted} from '@dv/shared/code';
import {
    checkPresent,
    displayableComparator,
    DvbDateUtil,
    DvbRestUtil,
    DvbUtil,
    isNullish,
    TimeRange,
} from '@dv/shared/code';
import type {Transition} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {finalize, from, take, tap} from 'rxjs';
import type {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import type {Debouncer, DebounceService} from '../../../../common/service/debounceService';
import {DvbRestUtilAngularJS} from '../../../../common/service/rest/dvbRestUtilAngularJS';
import type {Angestellte} from '../../models/Angestellte';
import {
    BetreuungsPersonMonatlicheStundenErfassung,
} from '../../models/stundenerfassung/BetreuungsPersonMonatlicheStundenErfassung';
import {KindBetreuungErfassung} from '../../models/stundenerfassung/KindBetreuungErfassung';
import type {KindMonatlicheStundenErfassung} from '../../models/stundenerfassung/KindMonatlicheStundenErfassung';
import {MonatlicheStundenerfassungStatus} from '../../models/stundenerfassung/MonatlicheStundenerfassungStatus';
import type {KindBetreuungErfassungService} from '../../service/kindBetreuungErfassungService';
import {StundenErfassungUtil} from '../../service/StundenErfassungUtil';
import type {
    AngestellteStundenerfassungSummaryDialogModel,
} from '../angestellte-stundenerfassung-summary/angestellte-stundenerfassung-summary.component';
import {
    AngestellteStundenerfassungSummaryComponent,
} from '../angestellte-stundenerfassung-summary/angestellte-stundenerfassung-summary.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        $transition$: '<',
        angestellte: '<',
    },
    template: require('./dvb-angestellte-stundenerfassung.html'),
    controllerAs: 'vm',
};

/* eslint-disable max-lines */
export class DvbAngestellteStundenerfassung implements angular.IController {
    public static $inject: readonly string[] = [
        'kindBetreuungErfassungService',
        'dialogService',
        'authStore',
        'debounceService',
        '$q',
    ];

    public $transition$!: Transition;
    public angestellte!: Persisted<Angestellte>;

    public selectedStichtag?: moment.Moment;
    public selectedDate?: Date;
    public periode?: moment.Moment;
    public dateOptions?: angular.ui.bootstrap.IDatepickerConfig;

    public betreuungsPersonen: { [k in KinderOrtFraktionId]: BetreuungsPerson } = {};
    public monthlyData: BetreuungsPersonMonatlicheStundenErfassung = new BetreuungsPersonMonatlicheStundenErfassung();
    public availableChildren: Kind[] = [];

    // entries of the selectedDate
    public entries: KindMonatlicheStundenErfassung[] = [];

    // kinder with belegung during periode but not on selectedDate
    public additionalKinder: Kind[] = [];
    public additionalKindFraktionen: { [kindId: string]: string[] } = {};
    public selectedAdditionalKind: Persisted<Kind> | null = null;

    // defines if the input fields use 'current' or 'original' Korrektur values
    public korrekturChoice: KorrekturType = 'original';

    public isLoading: boolean = true;
    public childAddingMode: boolean = false;
    public readonly: { [k in KinderOrtFraktionId]: boolean } = {};
    public childAddingAllowed: boolean = false;

    private timeout?: angular.IDeferred<unknown>;
    private readonly debouncer: Debouncer;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly kindBetreuungErfassungService: KindBetreuungErfassungService,
        private readonly dialogService: DialogService,
        private readonly authStore: AuthStore,
        debounceService: DebounceService,
        private readonly $q: angular.IQService,
    ) {
        this.debouncer = debounceService.create();
    }

    public $onInit(): void {
        this.selectedStichtag = this.$transition$.params().datum ?? DvbDateUtil.today();
        this.selectedDate = this.selectedStichtag?.toDate();
        this.periode = DvbDateUtil.startOfMonth(moment(this.selectedStichtag));
        this.dateOptions = this.createDatePickerConfig(this.periode);
        this.onMonthChange();

        checkPresent(this.angestellte.betreuungsPersonen).forEach(b => {
            this.betreuungsPersonen[b.id] = b;
        });
    }

    public uiOnParamsChanged(newParams: any, _transition: Transition): void {
        if (!DvbDateUtil.isValidMoment(newParams.datum) || newParams.datum.isSame(this.selectedStichtag)) {
            return;
        }

        this.selectedDate = newParams.datum.toDate();
        if (newParams.datum.isSame(this.periode, 'month')) {
            this.onDateChange();
        } else {
            this.periode = DvbDateUtil.startOfMonth(moment(newParams.datum));
            this.onMonthChange();
        }
    }

    public onMonthChange(): void {
        const periode = this.periode;
        if (!periode || !this.dateOptions) {
            return;
        }

        this.updateSelectedDate(periode);

        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const config = {timeout: this.timeout.promise};

        this.kindBetreuungErfassungService.getMonatlicheStundenErfassung(this.angestellte.id, periode, config)
            .then(data => {
                this.monthlyData = data;
                this.monthlyData.kinder.sort(displayableComparator);
                this.dateOptions = this.createDatePickerConfig(periode);
                this.initPrivileges();
                this.onDateChange();
                this.isLoading = false;
            })
            .catch(err => {
                if (DvbRestUtil.isRequestCancelled(err)) {
                    return;
                }

                this.isLoading = false;
                throw err;
            });
    }

    public onDateChange(): void {
        if (!this.selectedDate) {
            this.selectedStichtag = undefined;
            this.entries = [];
        }

        const stichtag = moment(this.selectedDate).startOf('day');
        this.selectedStichtag = stichtag;
        const entries: KindMonatlicheStundenErfassung[] = [];
        const entriesIrrelevantForDay: KindMonatlicheStundenErfassung[] = [];
        this.monthlyData.kinder.forEach(k => {
            if (k.isAffected(stichtag)) {
                entries.push(k);
            } else {
                entriesIrrelevantForDay.push(k);
            }
        });

        this.entries = entries;
        this.initEntries();
        this.initAdditionalChildren(entriesIrrelevantForDay);
        this.$transition$.router.stateService.go('.', {datum: stichtag}, {location: 'replace'});
    }

    public updateStunden(
        entry: KindBetreuungErfassung,
        parent: KindMonatlicheStundenErfassung,
    ): void {
        const stunden = checkPresent(entry.vonBisZeiten[this.korrekturChoice])
            .map(k => k.toHours())
            .reduce((a, b) => a + b, 0);

        entry.stunden[this.korrekturChoice] = parseFloat(stunden.toFixed(2));
        this.updateTagesStunden(parent);
        this.update(entry, parent);
    }

    public updateTagesStunden(kindErfassung: KindMonatlicheStundenErfassung): void {
        kindErfassung.updateTagesStunden();
    }

    public updateBemerkung(entry: KindBetreuungErfassung, parent: KindMonatlicheStundenErfassung): void {
        this.update(entry, parent);
    }

    public update(entry: KindBetreuungErfassung, parent: KindMonatlicheStundenErfassung, tarifParamId?: string): void {
        this.debouncer.debounce(() => {
            this.correctCurrentValue(entry, tarifParamId);

            this.kindBetreuungErfassungService.saveKindBetreuungErfassung(this.angestellte.id, parent.kind.id, entry)
                .then(response => {
                    if (this.monthlyData.status === MonatlicheStundenerfassungStatus.BESTAETIGT) {
                        // updating an already bestaetigt stundenErfassung resets the status
                        this.monthlyData.status = MonatlicheStundenerfassungStatus.KORRIGIERT;
                    }

                    if (entry.id ?? parent.betreuungen.includes(entry)) {
                        return;
                    }

                    // If the entry had not been persisted before, we have to add it to our model.
                    // Otherwise the data will be lost after a date change, until monthlyData is reloaded
                    parent.betreuungen.push(entry);
                    entry.id = response.id;

                    // force change detection so the calendar updates customClasses
                    this.dateOptions = angular.copy(this.dateOptions);
                });
        });
    }

    public createNewVonBisZeit(entry: KindBetreuungErfassung): void {
        checkPresent(entry.vonBisZeiten[this.korrekturChoice]).push(new TimeRange());
    }

    public removeVonBisZeit(
        entry: KindBetreuungErfassung,
        parent: KindMonatlicheStundenErfassung,
        index: number,
    ): void {
        checkPresent(entry.vonBisZeiten[this.korrekturChoice]).splice(index, 1);
        this.updateStunden(entry, parent);
    }

    public addChild(kind: Persisted<Kind> | null): void {
        if (isNullish(kind)) {
            return;
        }

        // find the childs data
        const kindEntry = this.monthlyData.kinder.find(kindErfassung => kindErfassung.kind.id === kind.id)!;

        // initialize the tagesDaten with an entry for every BetreuungsPerson for which the child has a belegung
        kindEntry.tagesDaten = [];
        this.additionalKindFraktionen[kind.id].forEach(fraktionId => {
            const fraktionErfassung = new KindBetreuungErfassung(null, fraktionId, this.selectedStichtag!);
            fraktionErfassung.initSpesen(this.getTarifParameter(fraktionErfassung));
            kindEntry.betreuungen.push(fraktionErfassung);
        });

        // the data for the current date changed --> trigger initialization
        this.entries.push(kindEntry);
        this.initEntries();
        this.childAddingMode = false;
        this.additionalKinder.splice(this.additionalKinder.indexOf(kind), 1);
    }

    public downloadSummary(): void {
        this.isLoading = true;
        this.kindBetreuungErfassungService.downloadMonatlicheStundenErfassungSummaryPdf(
            this.angestellte.id,
            this.selectedStichtag!,
            this.dvbDownloadCtrl)
            .finally(() => {
                this.isLoading = false;
            });
    }

    public showSummary(): void {
        const dialogModel: AngestellteStundenerfassungSummaryDialogModel = {
            firstOfMonth: this.periode!,
            monthlyData: this.monthlyData,
            betreuungsPersonen: this.betreuungsPersonen,
            angestellteId: this.angestellte.id,
            dvbDownloadCtrl: this.dvbDownloadCtrl,
            kindBetreuungErfassungService: this.kindBetreuungErfassungService,
        };
        this.dialogService.openDialog(
            AngestellteStundenerfassungSummaryComponent,
            dialogModel,
            {class: 'full-width'});
    }

    public freigeben(): void {
        this.dialogService.openConfirmDialog({
            title: 'PERSONAL.STUNDENERFASSUNG.FREIGEBEN',
            subtitle: 'PERSONAL.STUNDENERFASSUNG.FREIGEBEN_CONFIRM',
            confirmActionText: 'PERSONAL.STUNDENERFASSUNG.FREIGEBEN',
            confirm: () => this.changeStatus$(MonatlicheStundenerfassungStatus.FREIGEGEBEN),
        });
    }

    public openStatus(): void {
        this.changeStatus(MonatlicheStundenerfassungStatus.OFFEN);
    }

    public korrigiert(): void {
        this.changeStatus(MonatlicheStundenerfassungStatus.KORRIGIERT);
    }

    public bestaetigen(): void {
        this.changeStatus(MonatlicheStundenerfassungStatus.BESTAETIGT);
    }

    private changeStatus(status: MonatlicheStundenerfassungStatus): void {
        this.changeStatus$(status).subscribe();
    }

    private changeStatus$(status: MonatlicheStundenerfassungStatus): Observable<unknown> {
        this.isLoading = true;

        return from(this.kindBetreuungErfassungService.changeStatus(this.angestellte.id, this.periode!, status)).pipe(
            take(1),
            tap(() => {
                // Falls der Benutzer die Berechtigungen nicht für alle KinderOrte hat, bleibt nach Bestätigen der
                // Status des entities auf KORRIGIERT - das divergiert hier, ist aber egel, da der Benutzer sowieso
                // nicht nochmals (vollständig) besätigen kann.
                this.monthlyData.status = status;
                this.initPrivileges();
            }),
            finalize(() => {
                this.isLoading = false;
            }));
    }

    private getKinderOrtId(entry: KindBetreuungErfassung): KinderOrtId {
        return this.betreuungsPersonen[entry.kinderOrtFraktionId].kinderOrtId!;
    }

    private initEntries(): void {
        this.entries.sort(displayableComparator);
        this.initTagesDaten(this.entries, this.selectedStichtag!);
        if (this.korrekturChoice === 'current') {
            this.writeOriginalValuesToCurrent(this.entries);
        }
    }

    private writeOriginalValuesToCurrent(kinder: KindMonatlicheStundenErfassung[]): void {
        kinder.forEach(kindErfassung => {
            kindErfassung.tagesDaten?.forEach(t => {
                t.stunden.fillCurrent();
                Object.values(t.spesenByTarifParamId).forEach(s => s.fillCurrent());
            });
        });
    }

    private initTagesDaten(kinder: KindMonatlicheStundenErfassung[], stichtag: moment.Moment): void {

        kinder.forEach(kindErfassung => {
            kindErfassung.tagesDaten = kindErfassung.getKindBetreuungErfassungen(stichtag);
            kindErfassung.tagesDaten.forEach(t => t.initSpesen(this.getTarifParameter(t)));
            this.updateTagesStunden(kindErfassung);
        });
    }

    /**
     * Initializes data for children that can be added as additions to their belegungs planned presence.
     *
     * @param kindErfassungen kindErfassungen that have a belegung during this.selectedDay but no platz on selectedDay.
     */
    private initAdditionalChildren(kindErfassungen: KindMonatlicheStundenErfassung[]): void {
        const kinder: Kind[] = [];
        this.additionalKindFraktionen = {};

        kindErfassungen.forEach(erfassung => {
            kinder.push(erfassung.kind);

            const existingFraktionIds = this.additionalKindFraktionen[erfassung.kind.id] || [];
            const additionalFraktionIds = erfassung.kindWochenBelegungen.flatMap(b => b.gruppenBelegungen)
                .map(gb => gb.gruppeId!);
            this.additionalKindFraktionen[erfassung.kind.id] =
                DvbUtil.uniqueArray(existingFraktionIds.concat(additionalFraktionIds));
        });

        this.additionalKinder = DvbUtil.uniqueArray(kinder, DvbUtil.mapToId);
        this.selectedAdditionalKind = null;
    }

    private initPrivileges(): void {

        this.readonly = {};
        const bpAccess = this.monthlyData.status === MonatlicheStundenerfassungStatus.OFFEN
            && this.authStore.hasPermission(PERMISSION.ANGESTELLTE.UPDATE + this.angestellte.id);
        this.childAddingAllowed = bpAccess;
        this.korrekturChoice = bpAccess ? 'original' : 'current';

        Object.values(this.betreuungsPersonen).forEach(bp => {
            const managePermission = this.authStore.hasPermission(PERMISSION.KITA.MANAGE + bp.kinderOrtId!);
            this.childAddingAllowed ||= managePermission;
            this.readonly[bp.id!] = bpAccess ? false : !managePermission;
        });
    }

    private getTarifParameter(entry: KindBetreuungErfassung): Persisted<TarifParameter>[] {
        return this.monthlyData.tarifParameterByKinderOrtId[this.getKinderOrtId(entry)];
    }

    private createDatePickerConfig(monat: moment.Moment): angular.ui.bootstrap.IDatepickerConfig {
        return {
            showWeeks: false,
            minMode: 'day',
            maxMode: 'day',
            datepickerMode: 'day',
            minDate: DvbDateUtil.startOfMonth(moment(monat)).toDate(),
            maxDate: DvbDateUtil.endOfMonth(moment(monat)).toDate(),
            customClass: this.getDateClass.bind(this),
        };
    }

    private getDateClass(args: angular.ui.bootstrap.IDatepickerCellArgs): string {
        const dateMoment = moment(args.date);
        const erfassungen = dateMoment.isSame(this.selectedDate) ? this.entries : this.monthlyData.kinder;

        return StundenErfassungUtil.getDateClass(dateMoment, this.periode!, this.monthlyData, erfassungen);
    }

    private updateSelectedDate(periode: moment.Moment): void {
        if (!this.selectedStichtag) {
            return;
        }

        const originalWeekDay = this.selectedStichtag.isoWeekday();
        const forwardDirection = periode.isSameOrAfter(this.selectedStichtag);

        const stichtag = forwardDirection ?
            this.getFirstWeekDayInMonth(periode, originalWeekDay) :
            this.getLastWeekDayInMonth(periode, originalWeekDay);

        this.selectedDate = stichtag.toDate();

    }

    private getFirstWeekDayInMonth(periode: moment.Moment, requestedWeekDay: number): moment.Moment {
        const startOfMonth = DvbDateUtil.startOfMonth(moment(periode));

        return startOfMonth.isoWeekday() <= requestedWeekDay ?
            startOfMonth.isoWeekday(requestedWeekDay) :
            startOfMonth.add(1, 'week').isoWeekday(requestedWeekDay);
    }

    private getLastWeekDayInMonth(periode: moment.Moment, requestedWeekDay: number): moment.Moment {
        const endOfMonth = DvbDateUtil.endOfMonth(moment(periode));

        return endOfMonth.isoWeekday() < requestedWeekDay ?
            endOfMonth.subtract(1, 'week').isoWeekday(requestedWeekDay) :
            endOfMonth.isoWeekday(requestedWeekDay);
    }

    /**
     * Fills the korrektur objects current value to ensure, that we do not display an empty input for a korrektur with
     * original value and null as its current value.
     */
    private correctCurrentValue(entry: KindBetreuungErfassung, tarifParamId?: string): void {
        if ('original' === this.korrekturChoice) {
            if (!entry.hasBemerkungOriginal()) {
                entry.bemerkung.original = null;
            }

            // do not modify the current value while we are in original value mode
            return;
        }

        if (!entry.hasBemerkungCurrent()) {
            entry.bemerkung.current = null;
        }

        if (tarifParamId) {
            entry.spesenByTarifParamId[tarifParamId].fillCurrent();
        } else {
            entry.stunden.fillCurrent();
        }
    }
}

componentConfig.controller = DvbAngestellteStundenerfassung;
angular.module('kitAdmin').component('dvbAngestellteStundenerfassung', componentConfig);
