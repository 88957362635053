/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BewerbungsplanError, Wochenplan, ZeitraumFeld} from '@dv/kitadmin/models';
import {INVALID_BELEGUNGS_EINHEIT, ZeitraumUtil} from '@dv/kitadmin/models';
import type {BetreuungsZeitraum, DayOfWeek, Persisted} from '@dv/shared/code';
import type angular from 'angular';

export abstract class AbstractBewerbungsplanController implements angular.IController {

    public wochenplan!: Persisted<Wochenplan>;

    // Bewerbung Wochenplan
    public enabledDays: DayOfWeek[] = [];
    public distinctZeitraeume: BetreuungsZeitraum[] = [];
    public zeitraumFelder: ZeitraumFeld[] = [];

    public hasInvalidBelegteEinheit = false;
    public invalidDay: DayOfWeek | null = null;

    protected constructor(
        protected readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        if (this.wochenplan) {
            this.initWithWochenplan(this.wochenplan);
        }
    }

    public handleZeitraumFeldToggle(zeitraumFeld: ZeitraumFeld): void {
        if (this.hasInvalidBelegteEinheit && zeitraumFeld.dayOfWeek !== this.invalidDay) {
            // Disable modification of other days
            zeitraumFeld.selected = !zeitraumFeld.selected;
        } else {
            // TODO eventuell validierung dynamisch machen, wie bei kind-betreuung.
            // Könnte dvbBewerbungsplanService.handleToggledZeitraumFeld aufrufen und schauen ob ein Fehler geworfen
            // wurde.
            this.clearBelegteEinheitenErrors(zeitraumFeld.dayOfWeek);
        }
    }

    protected initWithWochenplan(wochenplan: Persisted<Wochenplan>): void {
        this.enabledDays = ZeitraumUtil.getWeekDaysFromWochenplan(wochenplan);
        this.distinctZeitraeume = wochenplan.distinctZeitraeume;
        this.zeitraumFelder = ZeitraumUtil.createZeitraumFelderWithWochenplan(wochenplan);
    }

    protected addBelegteEinheitenError(error: BewerbungsplanError): void {
        this.errorService.addValidationError(error.msgKey, error.args);
        this.hasInvalidBelegteEinheit = true;
        this.invalidDay = error.args.dayOfWeek;
        this.toggleZeitraumFelderError(true, error.args.dayOfWeek);
    }

    protected clearBelegteEinheitenErrors(dayOfWeek: DayOfWeek): void {
        this.hasInvalidBelegteEinheit = false;
        this.invalidDay = null;
        this.errorService.clearErrorByMsgKey(INVALID_BELEGUNGS_EINHEIT);
        this.toggleZeitraumFelderError(false, dayOfWeek);
    }

    protected toggleZeitraumFelderError(hasError: boolean, dayOfWeek: DayOfWeek): void {
        this.zeitraumFelder.filter(feld => feld.dayOfWeek === dayOfWeek)
            .forEach(zf => {
                zf.hasError = hasError;
            });
    }

}
