/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldungKind} from '@dv/kitadmin/models';
import {Geschlecht} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    require: {
        form: '^form',
    },
    bindings: {
        kind: '<',
        index: '<',
    },
    template: require('./dvb-external-anmeldung-kind-stammdaten.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungKindStammdaten implements angular.IController {
    public static $inject: readonly string[] = [];

    public form!: angular.IFormController;
    public kind!: ExternalAnmeldungKind;
    public index!: number;

    public geschlechter: readonly Geschlecht[] = Object.values(Geschlecht);
}

componentConfig.controller = DvbExternalAnmeldungKindStammdaten;
angular.module('kitAdmin').component('dvbExternalAnmeldungKindStammdaten', componentConfig);
