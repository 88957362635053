<div class="institution-matching">
    <div class="row">
        <div class="col-md-9 col-lg-7">
            <h2 data-translate="KIBON.INSTITUTION_MATCHING.INSTITUTIONS"></h2>
            <ul class="filter-list small-abstand-bottom">
                <li>
                <div data-translate="KIBON.INSTITUTION_MATCHING.MATCHING_STATE" class="filter-label"></div>
                    <ul class="filter-option-list filter-toggle-buttons smaller-abstand-bottom">
                        <li>
                            <dvb-filter-option
                                label="'KIBON.INSTITUTION_MATCHING.MATCHING_STATE_OPEN' | translate"
                                is-selected="vm.filterSelected('OPEN')"
                                on-toggle="vm.toggleFilter('OPEN')">
                            </dvb-filter-option>
                        </li>
                        <li>
                            <dvb-filter-option
                                label="'KIBON.INSTITUTION_MATCHING.MATCHING_STATE_REMOVED' | translate"
                                is-selected="vm.filterSelected('REMOVED')"
                                on-toggle="vm.toggleFilter('REMOVED')">
                            </dvb-filter-option>
                        </li>
                    </ul>
                </li>
            </ul>

            <dvb-pages items="vm.filteredInstitutions"
                       total-items="vm.filteredInstitutions.length"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'KIBON.INSTITUTION_MATCHING.INSTITUTIONS_MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                <page-items dvb-accordions>

                    <dvb-accordion ng-repeat="institution in vm.filteredInstitutions | limitTo: vm.itemsPerPage : ((vm.currentPage - 1) * vm.itemsPerPage)"
                                   remove-toggled-dom="true"
                                   is-first="$first">

                        <panel-header class="full-width-block">
                            <div class="layout-row items-center row">
                                <div class="col-sm-4 col-xs-6">
                                    <span ng-bind="institution.name"></span>
                                </div>
                                <div class="col-sm-4 hidden-xs">
                                    <span ng-bind="institution.traegerschaft"></span>
                                </div>
                                <div class="col-sm-4 col-xs-6 text-right">
                                    <span ng-bind="'KIBON.INSTITUTION_MATCHING.MATCHING_STATE_' + institution.matchingState | translate"></span>
                                </div>
                            </div>
                        </panel-header>

                        <panel-content>
                            <div class="row">
                                <div class="col-md-6">
                                    <dvlib-adresse-display [adresse]="institution.adresse"
                                                           [display-type]="'long'">
                                    </dvlib-adresse-display>
                                </div>
                                <div class="col-md-6" ng-if="!vm.isAssigned(institution)">
                                    <dvb-search-get-single-entity
                                        class=floating-text
                                        entity-to-search="KINDERORT"
                                        ng-model="vm.kinderOrtMapping[institution.id]"
                                        placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                        mandant-filter="vm.mandantFilter">
                                    </dvb-search-get-single-entity>
                                    <p ng-bind="vm.kinderOrtMapping[institution.id].mandantName || ''"></p>
                                </div>
                                <div class="col-md-4 col-md-offset-2" ng-if="vm.isAssigned(institution)">
                                    <p data-translate="KIBON.INSTITUTION_MATCHING.ASSIGNED_KINDER_ORT"></p>
                                    <p ng-bind="institution.getKinderOrtAndMandantName()"></p>
                                </div>
                            </div>
                        </panel-content>

                        <panel-footer class="full-width-block">
                            <div class="row">
                                <div class="col-md-6">
                                    <dvb-loading-button
                                        ng-if="!vm.isAssigned(institution)"
                                        is-loading="!!vm.isLoading"
                                        on-click="vm.assign(institution)"
                                        ng-disabled="!vm.kinderOrtMapping[institution.id]"
                                        as-link="true"
                                        type="button">
                                        <span data-translate="COMMON.ZUWEISEN"></span>
                                    </dvb-loading-button>
                                    <dvb-loading-button
                                        ng-if="vm.isAssigned(institution)"
                                        is-loading="!!vm.isLoading"
                                        on-click="vm.removeAssignment(institution)"
                                        as-link="true"
                                        type="button">
                                        <span data-translate="COMMON.ZUWEISUNG_AUFHEBEN"></span>
                                    </dvb-loading-button>
                                </div>
                            </div>
                        </panel-footer>

                    </dvb-accordion>
                </page-items>
            </dvb-pages>
        </div>
    </div>

</div>
