/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {BENUTZER_STATES} from './benutzer-states';

angular.module('kitAdmin').config(benutzerConfig);

export * from './component/dvb-benutzer-form/dvb-benutzer-form';
export * from './component/dvb-benutzer-main/dvb-benutzer-main';
export * from './component/dvb-benutzer-profil/dvb-benutzer-profil';
export * from './component/dvb-benutzer-rechte/dvb-benutzer-rechte';
export * from './component/dvb-change-password/dvb-change-password';
export * from './component/dvb-granular-kinder-ort-permissions/dvb-granular-kinder-ort-permissions';
export * from './component/dvb-kita-rechte/dvb-kita-rechte';
export * from './component/dvb-neuer-benutzer/dvb-neuer-benutzer';
export * from './component/dvb-new-password/dvb-new-password';
export * from './component/dvb-standard-recht/dvb-standard-recht';
// Add New Component JS Above

benutzerConfig.$inject = ['$stateProvider'];

function benutzerConfig($stateProvider: StateProvider): void {
    Object.values(BENUTZER_STATES).forEach(state => $stateProvider.state(state));
}
