/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {ILimited} from '@dv/shared/code';
import {WeeklyFirmenKontingentControllingFigures} from './WeeklyFirmenKontingentControllingFigures';

export class WeeklyControllingFigures implements ILimited {
    public static $inject: readonly string[] = [];

    public constructor(
        public id: string,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public kitaBewilligtePlaetze: number,
        public kitaMaxPlaetze: number,
        public kitaPlaetze: number,
        public kitaBelegung: number,
        public kitaAuslastungPct: number,
        public subvKontingent: number,
        public subvBelegung: number,
        public subvAuslastungPct: number,
        public privateBelegung: number,
        public personalBedarf: number,
        public personalZugewiesen: number,
        public personalAngestellt: number,
        public firmenKontingente: WeeklyFirmenKontingentControllingFigures[],
    ) {
    }

    public static apiResponseTransformer(data: any): WeeklyControllingFigures {
        return new WeeklyControllingFigures(
            data.id,
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
            parseFloat(data.kitaBewilligtePlaetze),
            parseFloat(data.kitaMaxPlaetze),
            parseFloat(data.kitaPlaetze),
            parseFloat(data.kitaBelegung),
            parseFloat(data.kitaAuslastungPct),
            parseFloat(data.subvKontingent),
            parseFloat(data.subvBelegung),
            parseFloat(data.subvAuslastungPct),
            parseFloat(data.privateBelegung),
            parseFloat(data.personalBedarf),
            parseFloat(data.personalZugewiesen),
            parseFloat(data.personalAngestellt),
            data.firmenKontingente.map((fk: any) =>
                WeeklyFirmenKontingentControllingFigures.apiResponseTransformer(fk)),
        );
    }
}
