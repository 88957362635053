<h2 [translate]="dienst?.id ? 'PERSONAL.DIENSTKONFIGURATION.EDIT' : 'PERSONAL.DIENSTKONFIGURATION.NEW'"></h2>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="submitForm()">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   formControlName="name"
                   [maxlength]="255"
                   [placeholder]="'PERSONAL.DIENSTKONFIGURATION.NAME' | translate"
                   [tooltip]="'PERSONAL.DIENSTKONFIGURATION.NAME' | translate">
        </div>
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   formControlName="kuerzel"
                   [placeholder]="'PERSONAL.DIENSTKONFIGURATION.KUERZEL' | translate"
                   [maxlength]="100"
                   [tooltip]="'PERSONAL.DIENSTKONFIGURATION.KUERZEL' | translate">
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <input type="time"
                   class="form-control"
                   formControlName="von"
                   [placeholder]="'COMMON.VON' | translate"
                   [tooltip]="'COMMON.VON' | translate">
        </div>
        <div class="col-md-6">
            <input type="time"
                   class="form-control"
                   formControlName="bis"
                   [placeholder]="'COMMON.BIS' | translate"
                   [tooltip]="'COMMON.BIS' | translate">
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 layout-row flex-start items-center">
            <ngx-colors ngx-colors-trigger
                        formControlName="backgroundColor"
                        colorPickerControls="no-alpha"
                        format="hex"
                        tabindex="0"
                        [palette]="colorPalette"
                        [tooltip]="'PERSONAL.DIENSTKONFIGURATION.BACKGROUND_COLOR' | translate"
                        [acceptLabel]="'COMMON.AUSWAEHLEN' | translate"
                        [cancelLabel]="'COMMON.ABBRECHEN' | translate">
            </ngx-colors>
            <span class="smaller-abstand-left"
                  [ngClass]="{'error-message': ngForm.submitted && form.controls['backgroundColor'].invalid}"
                  [translate]="'PERSONAL.DIENSTKONFIGURATION.BACKGROUND_COLOR'"></span>
        </div>
        <div class="col-md-6 layout-row flex-start items-center">
            <ngx-colors ngx-colors-trigger
                        formControlName="textColor"
                        colorPickerControls="no-alpha"
                        format="hex"
                        tabindex="0"
                        [palette]="textColorPalette"
                        [tooltip]="'PERSONAL.DIENSTKONFIGURATION.TEXT_COLOR' | translate"
                        [acceptLabel]="'COMMON.AUSWAEHLEN' | translate"
                        [cancelLabel]="'COMMON.ABBRECHEN' | translate">
            </ngx-colors>
            <span class="smaller-abstand-left"
                  [ngClass]="{'error-message': ngForm.submitted && form.controls['textColor'].invalid}"
                  [translate]="'PERSONAL.DIENSTKONFIGURATION.TEXT_COLOR'"></span>
        </div>
    </div>
    <h3 class="normal-abstand-top" [translate]="'PERSONAL.DIENSTKONFIGURATION.PAUSE'"></h3>
    <div class="row">
        <div class="col-md-6">
            <input type="time"
                   class="form-control"
                   formControlName="pauseVon"
                   [placeholder]="'PERSONAL.DIENSTKONFIGURATION.PAUSE_VON' | translate"
                   [tooltip]="'PERSONAL.DIENSTKONFIGURATION.PAUSE_VON' | translate">
        </div>
        <div class="col-md-6">
            <input type="time"
                   class="form-control"
                   formControlName="pauseBis"
                   [placeholder]="'PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS' | translate"
                   [tooltip]="'PERSONAL.DIENSTKONFIGURATION.PAUSE_BIS' | translate">
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading"
                              (cancel)="handleCancel()">
    </dv-submit-cancel-buttons>
</form>
