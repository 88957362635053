<div class="row dvb-form kind-neue-kontaktperson">
    <div class="col-md-6 col-md-offset-3">

        <form name="neueKontaktpersonForm"
              ng-submit="!neueKontaktpersonForm.$pending && vm.validateAndSaveKontaktperson(neueKontaktpersonForm.$valid)"
              novalidate>

            <dvb-kind-kontakt-erfassen kontakt="vm.kontakt"
                                       adress-datum="vm.adressDatum">
            </dvb-kind-kontakt-erfassen>

            <dvb-submit-cancel-buttons is-loading="neueKontaktpersonForm.$pending || vm.isLoading"
                                       submit-label="KIND.KONTAKTPERSON_ERFASSEN"
                                       on-cancel="vm.goToKindKontakte()">
            </dvb-submit-cancel-buttons>
        </form>
    </div>
</div>
