/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Betreuungsfaktor, Kind} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {KindService} from '../common/service/rest/kind/kindService';

// Wird in sub-views verwendet
interface KindMainScope extends angular.IScope {
    kind: Persisted<Kind>;
    betreuungsfaktoren: Betreuungsfaktor[];
    isBetreuungsfaktorLoading: boolean;
}

export class KindMainController {
    public static $inject: readonly string[] = ['$scope', 'kind', 'kindService'];

    public constructor(private $scope: KindMainScope, private kind: Persisted<Kind>, private kindService: KindService) {
        this.$scope.kind = this.kind;
        this.$scope.betreuungsfaktoren = [];
        this.$scope.isBetreuungsfaktorLoading = false;
        this.$scope.$on('betreuungsfaktorChange', () => this.loadBetreuungsfaktoren());

        this.loadBetreuungsfaktoren();
    }

    public loadBetreuungsfaktoren(): void {
        this.$scope.isBetreuungsfaktorLoading = true;

        const stichtag = DvbDateUtil.today();

        const params = {stichtag, includes: '(kitas)'};
        this.kindService.getBetreuungsfaktoren(this.$scope.kind.id, params).then(result => {
            this.$scope.betreuungsfaktoren = result;
            this.$scope.betreuungsfaktoren.forEach(betreuungsfaktor => {
                betreuungsfaktor.setSpezifischFromKind(this.$scope.kind, stichtag);
            });
        }).finally(() => {
            this.$scope.isBetreuungsfaktorLoading = false;
        });
    }
}

angular.module('kitAdmin').controller('KindMainCtrl', KindMainController);
