<div class="dvb-kita-or-fraktionen-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.bsvPraesenzReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT"
                                      ng-change="vm.updateKinderOrt()"
                                      ng-model="vm.kinderOrt"
                                      ng-required="!vm.hasFraktionen()"
                                      ng-show="!vm.hasFraktionen()"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
        </dvb-search-get-single-entity>

        <dvb-search-list as-col="true"
                         entity-to-search="KINDERORT_FRAKTION"
                         ng-model="vm.kinderOrtFraktionen"
                         ng-required="!vm.kinderOrt"
                         ng-show="!vm.kinderOrt"
                         placeholder-key="COMMON.GRUPPE.AUSWAEHLEN"
                         required>
        </dvb-search-list>

        <div class="row form-row">
            <div class="col-xs-12">
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.gueltigAb"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
                <dvb-datepicker-text-field ng-change="vm.updateState()"
                                           ng-model="vm.gueltigBis"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div class="row form-row" ng-if="vm.showHours">
            <div class="col-xs-12">
                <input class="form-control"
                       type="number"
                       step="1"
                       dvb-integer
                       max="24"
                       min="1"
                       ng-model="vm.hoursFullDayBetreuung"
                       ng-attr-placeholder="{{'REPORT.BSV_PRAESENZKONTROLLE.FULL_DAY_BETREUUNG_HOURS' | translate}}"
                       tooltip-placement="top-left"
                       uib-tooltip="{{'REPORT.BSV_PRAESENZKONTROLLE.FULL_DAY_BETREUUNG_HOURS' | translate}}">
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>

