/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {PageContainer, Persisted} from '@dv/shared/code';
import {checkPersisted, DvbUtil, isPersisted} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import type {AuthService} from '../../authentication/service/authService';
import {EmailTemplate} from '../models/EmailTemplate';

export class EmailTemplateService {
    public static $inject: readonly string[] = ['$http', 'authService', 'authStore'];

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/communication/email-template`;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly authService: AuthService,
        private readonly authStore: AuthStore,
    ) {
    }

    public getAll(): angular.IPromise<PageContainer<EmailTemplate>> {
        return DvbRestUtilAngularJS.getPagedItems(EmailTemplateService.baseUrl, EmailTemplate);
    }

    public get(id: EntityId): angular.IPromise<Persisted<EmailTemplate>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(EmailTemplateService.baseUrl, EmailTemplate, id)
            .then(checkPersisted);
    }

    public getAllAllowed(
        kinderOrtIds: EntityId[] = [],
        fraktionIds: EntityId[] = [],
    ): angular.IPromise<PageContainer<EmailTemplate>> {
        const data = {
            kinderOrtIds: kinderOrtIds || [],
            fraktionIds: fraktionIds || [],
        };

        return DvbRestUtilAngularJS.postAndGetPagedItems(`${EmailTemplateService.baseUrl}/allowed`,
            data,
            EmailTemplate);
    }

    public create(emailTemplate: EmailTemplate): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(EmailTemplateService.baseUrl, emailTemplate.toRestObject());
    }

    public update(emailTemplate: Persisted<EmailTemplate>): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.put(`${EmailTemplateService.baseUrl}/${encodeURIComponent(emailTemplate.id)}`,
            emailTemplate.toRestObject());
    }

    public delete(id: string): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${EmailTemplateService.baseUrl}/${encodeURIComponent(id)}`);
    }

    public hasPermissionToModify(emailTemplate: EmailTemplate): boolean {
        if (this.isUserErstellt(emailTemplate)) {
            return true;
        }

        if (emailTemplate.alleKinderOrte) {
            return this.hasPermissionToModifyForAlleKinderOrte();
        }

        const kinderOrtPermissions = emailTemplate.kinderOrte
            .map(k => PERMISSION.KITA.MANAGE + DvbUtil.mapToIdChecked(k));

        return this.authStore.hasAnyPermission(kinderOrtPermissions);
    }

    public hasPermissionToModifyForAlleKinderOrte(): boolean {
        return this.authStore.hasPermission(PERMISSION.KITA.MANAGE + WILDCARD_TOKEN);
    }

    public isUserErstellt(emailTemplate: EmailTemplate): boolean {
        if (!isPersisted(emailTemplate)) {
            return true;
        }

        return this.authService.getPrincipal().userId === emailTemplate.userErstelltId;
    }

    public allowAlleKinderOrte(emailTemplate: EmailTemplate): boolean {
        return this.isUserErstellt(emailTemplate) || this.hasPermissionToModifyForAlleKinderOrte();
    }
}
