/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gebuehr, Language, RelationshipWithKind, RestCache} from '@dv/kitadmin/models';
import {
    Kind,
    Kontaktperson,
    LimitedAdresse,
    OffenerPosten,
    Rechnung,
    Rueckerstattung,
    Vorauszahlung,
} from '@dv/kitadmin/models';
import type {JaxKontaktpersonNew} from '@dv/shared/backend/model/jax-kontaktperson-new';
import type {
    Geschlecht,
    PageContainer,
    Persisted,
    RestInclude,
    RestLimitedMatrixParams,
    RestPaginated,
} from '@dv/shared/code';
import {checkPersisted, checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import type {UserSettingsStore} from '../../../cache/service/cache/userSettingsStore';
import {KontaktKorrespondenz} from '../../../communication/models/KontaktKorrespondenz';
import type {KontaktKorrespondezFilterType} from '../../../communication/models/KontaktKorrespondenzFilterType';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class KontaktpersonService {
    public static $inject: readonly string[] = ['userSettingsStore', '$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kontaktpersonen`;
    private static readonly EMAILS_PER_PAGE: number = 10;

    public constructor(
        private userSettingsStore: UserSettingsStore,
        private $http: angular.IHttpService,
    ) {
    }

    /**
     * Get Kontaktperson by id.
     */
    public get(kontaktPersonId: string, params?: RestCache & RestInclude): angular.IPromise<Persisted<Kontaktperson>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(
            KontaktpersonService.BASE_URL, Kontaktperson, kontaktPersonId, params,
        ).then(checkPersisted);
    }

    /**
     * Get all Kontaktpersonen.
     */
    public getAll(params?: RestInclude): angular.IPromise<Kontaktperson[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const url = KontaktpersonService.BASE_URL;

        return DvbRestUtilAngularJS.getModelsArray(url, Kontaktperson, 'kontaktpersonen', matrixParams);
    }

    public update(kontaktperson: Kontaktperson): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(checkPresent(kontaktperson.id))}`;

        return this.$http.put(url, kontaktperson.toRestObject());
    }

    public deleteKontaktperson(kontaktpersonId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}`);
    }

    public deleteAdresse(kontaktpersonId: string, adresseId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/adressen/` +
            `${encodeURIComponent(adresseId)}`;

        return this.$http.delete(url);
    }

    public getKontaktAdressen(kontaktpersonId: string): angular.IPromise<LimitedAdresse[]> {
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/adressen`;

        return DvbRestUtilAngularJS.getModelsArray(url, LimitedAdresse, 'limitedAdressen');
    }

    public getKinder(kontaktpersonId: string, params?: RestInclude): angular.IPromise<Kind[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/kinder`;

        return DvbRestUtilAngularJS.getModelsArray(url, Kind, 'kinder', matrixParams);
    }

    public create(kontaktperson: Kontaktperson, relationshipWithKind: RelationshipWithKind): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const kontaktpersonNewParam: JaxKontaktpersonNew = {
            kontaktperson: kontaktperson.toRestObject(),
            relationshipWithKind: relationshipWithKind.toRestObject(),
        };
        const adressen = kontaktperson.adressen.length === 1 && kontaktperson.adressen[0].adresse.isValid() ?
            [kontaktperson.adressen[0].toRestObject()] :
            undefined;
        kontaktpersonNewParam.kontaktperson.adressen = adressen;

        return this.$http.post(KontaktpersonService.BASE_URL, kontaktpersonNewParam);
    }

    /**
     * gueltigAb & gueltigBis are optional. When both are set, then only Rechnungen within gueltigAb & gueltigBis
     * are returned.
     */
    public getRechnungen(
        kontaktpersonId: string,
        params?: RestInclude & RestLimitedMatrixParams,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Persisted<Rechnung>[]> {
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/rechnungen`;

        return DvbRestUtilAngularJS.getModelsArray(url, Rechnung, 'items', params, false, config);
    }

    public updateKorrespondenzsprache(
        kontaktpersonId: string,
        korrespondenzsprache: Language,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/korrespondenzsprache/` +
            `${korrespondenzsprache.toRestObject()}`;

        return this.$http.put(url, {});
    }

    public deleteKorrespondenzsprache(kontaktpersonId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/korrespondenzsprache`;

        return this.$http.delete(url);
    }

    public updateGeschlecht(kontaktpersonId: string, geschlecht: Geschlecht): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        // noinspection JSCheckpublicSignatures
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/geschlecht/` +
            `${encodeURIComponent(geschlecht)}`;

        return this.$http.put(url, {});
    }

    public createVorauszahlung(
        kontaktpersonId: string,
        kontaktpersonZahlung: Vorauszahlung,
    ): angular.IPromise<Vorauszahlung> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/vorauszahlungen`;

        return DvbRestUtilAngularJS.postAndGetModel(url, kontaktpersonZahlung.toRestObject(), Vorauszahlung);
    }

    public createRueckerstattung(
        kontaktpersonId: string,
        rueckerstattung: Rueckerstattung,
    ): angular.IPromise<Rueckerstattung> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/rueckerstattungen`;

        return DvbRestUtilAngularJS.postAndGetModel(url, rueckerstattung.toRestObject(), Rueckerstattung);
    }

    /**
     * @param params.kitaIds list of kitaIds to filter
     */
    public getAllOffenePosten(
        this: void,
        kontaktpersonId: string,
        params?: RestInclude & { kitaIds: string[] },
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<OffenerPosten[]> {
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/offeneposten/all`;

        return DvbRestUtilAngularJS.getModelsArray(url, OffenerPosten, 'offenePosten', params, false, config);
    }

    /**
     * @param params.kitaIds list of kitaIds to filter
     */
    public getUnsettledOffenePosten(
        this: void,
        kontaktpersonId: string,
        params?: RestInclude & { kitaIds: string[] },
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<OffenerPosten[]> {
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/offeneposten/unsettled`;

        return DvbRestUtilAngularJS.getModelsArray(url, OffenerPosten, 'offenePosten', params, false, config);
    }

    public setEmailsPerPage(emailsPerPage: number): void {
        this.userSettingsStore.put('KONTAKTPERSON_EMAILS_PER_PAGE', emailsPerPage);
    }

    public getEmailsPerPage(): number {
        const count = this.userSettingsStore.get('KONTAKTPERSON_EMAILS_PER_PAGE');

        if (count !== undefined) {
            return count;
        }

        this.setEmailsPerPage(KontaktpersonService.EMAILS_PER_PAGE);

        return KontaktpersonService.EMAILS_PER_PAGE;
    }

    public getKorrespondenz(
        kontaktpersonId: string,
        types: KontaktKorrespondezFilterType[],
        params?: RestPaginated,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<KontaktKorrespondenz>> {
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/korrespondenz`;
        const matrixParams = {...params, types};

        return DvbRestUtilAngularJS.getPagedItems(url, KontaktKorrespondenz, matrixParams, config);
    }

    public createKontaktpersonGebuehr(kontaktpersonId: string, gebuehr: Gebuehr): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KontaktpersonService.BASE_URL}/${encodeURIComponent(kontaktpersonId)}/gebuehren`;

        return this.$http.post(url, gebuehr.toRestObject());
    }
}
