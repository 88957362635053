/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ZahlungType = 'BANKZAHLUNG' | 'SKONTO' | 'RUNDUNGSDIFFERENZ' | 'ABSCHREIBUNG' | 'AUSGLEICHSZAHLUNG';

export const ZahlungType = {
    BANKZAHLUNG: 'BANKZAHLUNG' as ZahlungType,
    SKONTO: 'SKONTO' as ZahlungType,
    RUNDUNGSDIFFERENZ: 'RUNDUNGSDIFFERENZ' as ZahlungType,
    ABSCHREIBUNG: 'ABSCHREIBUNG' as ZahlungType,
    AUSGLEICHSZAHLUNG: 'AUSGLEICHSZAHLUNG' as ZahlungType
};

