/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ParameterKinderOrte} from '../../models';
import {ERR_VALIDATION, ParameterKinderkontaktliste, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<?',
        stichtagAdresse: '<?',
    },
    template: require('./dvb-kinderkontaktliste-report.html'),
    controllerAs: 'vm',
};

export class DvbKinderkontaktlisteReport implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public parameterKinderOrte?: ParameterKinderOrte;
    public stichtagAdresse?: moment.Moment;

    public parameterKinderkontaktliste: ParameterKinderkontaktliste = new ParameterKinderkontaktliste();
    public kinderkontaktlisteReportForm?: angular.IFormController;
    public defaultStichtag: moment.Moment = DvbDateUtil.today();

    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (!this.parameterKinderOrte) {
            return;
        }

        angular.extend(this.parameterKinderkontaktliste, this.parameterKinderOrte);
        this.parameterKinderkontaktliste.stichtagAdresse = this.stichtagAdresse;
    }

    public updateState(): void {
        this.reportService.updateStateParams(this.parameterKinderkontaktliste);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.kinderkontaktlisteReportForm!.$valid && this.parameterKinderkontaktliste.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    public getDownloadPromise(): angular.IPromise<TempBlob> {
        switch (this.reportState) {
            case ReportState.BETRIEB.ADRESSLISTE_KINDER_KONTAKTE:
                return this.reportService.getTempBlobForAdresslisteKinderKontakteReport(
                    this.parameterKinderkontaktliste, {reportTemplate: this.reportTemplate});
            case ReportState.BETRIEB.TELEFON_EMAIL_ADRESSLISTE:
                return this.reportService.getTempBlobForTelefonlisteKinderKontakteReport(
                    this.parameterKinderkontaktliste, {reportTemplate: this.reportTemplate});
            default:
                throw new Error(`Report not implemented: ${JSON.stringify(this.reportState)}`);
        }
    }
}

componentConfig.controller = DvbKinderkontaktlisteReport;
angular.module('kitAdmin').component('dvbKinderkontaktlisteReport', componentConfig);
