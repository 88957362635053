<div class="row">
    <div class="col-md-7">
        <dv-crud-accordion
            [items]="store.entities()"
            [title]="'PERSONAL.TERMIN.TYPE.TITLE' | translate"
            [isEmpty]="store.isEmpty()"
            [emptyText]="'PERSONAL.TERMIN.TYPE.NONE' | translate"
            [createMode]="store.showCreateMode()"
            [createText]="'PERSONAL.TERMIN.TYPE.NEW' | translate"
            [showDeleteDialog]="store.showDeleteDialog()"
            [entityName]="'PERSONAL.TERMIN.TYPE.SINGULAR' | translate"
            [isLoading]="store.isLoadingOrCreating()"
            [headerTemplate]="headerTemplate"
            [editModeTemplate]="editModeTemplate"
            [createModeTemplate]="createModeTemplate"
            [readonlyModeTemplate]="readonlyModeTemplate"
            (toggleExpanded)="store.toggleExpanded($event.id)"
            (confirmDelete)="store.confirmDelete()"
            (hideDeleteDialog)="store.hideDeleteDialog()"
            (showCreateMode)="store.enableCreateMode()">
        </dv-crud-accordion>
    </div>
</div>

<ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #headerTemplate>
    <div class="full-width-block">
        <div class="row item-header">
            <div class="col-xs-6">
                <span>{{ item.entity.name }}</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #readonlyModeTemplate>
    <h3 translate="PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE_TITLE"></h3>
    <p [translate]="'PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE.' + item.entity.workTimeType"></p>
    @if (item.entity.deductFromBedarfsrechnung) {
        <h3 class="small-abstand-top" translate="PERSONAL.TERMIN.TYPE.BEDARFSRECHNUNG_TITLE"></h3>
        <p translate="PERSONAL.TERMIN.TYPE.DEDUCT_FROM_BEDARFSRECHNUNG"></p>
    }

    <dvlib-button-list class="small-abstand-top">
        <button type="button"
                class="btn btn-link"
                (click)="store.setEditMode(item.id)"
                translate="COMMON.BEARBEITEN">
        </button>
        <button type="button"
                class="btn btn-link"
                (click)="store.setShowDeleteDialog(item.id)"
                translate="COMMON.LOESCHEN">
        </button>
        <dvlib-spinner [show]="item.isLoading"/>
    </dvlib-button-list>
</ng-template>

<ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #editModeTemplate>
    <dv-termin-type-form
        [terminType]="item.entity"
        [isLoading]="item.isLoading"
        (save)="store.updateType($event)"
        (cancel)="store.setReadonlyMode(item.id)">
    </dv-termin-type-form>
</ng-template>

<ng-template #createModeTemplate>
    <dv-termin-type-form
        [isLoading]="store.isCreating()"
        (save)="store.createType($event)"
        (cancel)="store.disableCreateMode()">
    </dv-termin-type-form>
</ng-template>
