/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KitaTarifeZeitraum} from '@dv/kitadmin/models';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        kitaTarifeZeitraum: '<',
        onSubmit: '&',
        isLoading: '<',
    },
    template: require('./dvb-kita-tarif-zuweisen.html'),
    controllerAs: 'vm',
};

export class DvbKitaTarifZuweisen implements angular.IController {
    public static $inject: readonly string[] = ['errorService'];

    public kitaTarifeZeitraum!: KitaTarifeZeitraum | null;
    public onSubmit!: (params: { kitaTarifeZeitraum: KitaTarifeZeitraum }) => any;
    public isLoading: boolean = false;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public abbrechen(): void {
        this.kitaTarifeZeitraum = null;
    }

    public submit(isValid: boolean): void {
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid || !this.kitaTarifeZeitraum) {
            return;
        }

        if (TypeUtil.isFunction(this.onSubmit)) {
            this.onSubmit({kitaTarifeZeitraum: this.kitaTarifeZeitraum.getCleanCopy()});
        }
    }
}

componentConfig.controller = DvbKitaTarifZuweisen;
angular.module('kitAdmin').component('dvbKitaTarifZuweisen', componentConfig);
