/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IconComponent} from '@dv/shared/angular';
import {DvbDateUtil, ILimited} from '@dv/shared/code';
import moment from 'moment';

@Component({
    selector: 'dv-collapsible-panel',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './collapsible-panel.component.html',
    styleUrls: ['./collapsible-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsiblePanelComponent implements OnChanges {

    @Input() public isFirst: boolean = false;
    @Input() public isLast?: boolean = false;
    @Input() public isCollapsed?: boolean = true;
    @Input() public limitedEntity?: ILimited;
    @Input() public expandAb?: moment.Moment;
    @Input() public expandBis?: moment.Moment;
    @Input() public compact?: boolean = false;
    @Input() public isDisabled?: boolean = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.expandAb && !changes.expandAb.isFirstChange() ||
            changes.expandBis && !changes.expandBis.isFirstChange() ||
            changes.limitedEntity && !changes.limitedEntity.isFirstChange()) {

            this.setCollapsed();
        }
    }

    public setCollapsed(): void {
        if (!DvbDateUtil.hasValidMoments(this.limitedEntity) ||
            !DvbDateUtil.isValidMoment(this.expandAb) ||
            !DvbDateUtil.isValidMoment(this.expandBis)) {

            return;
        }

        const gueltigkeit = {gueltigAb: this.expandAb, gueltigBis: this.expandBis};

        // falls die Gueltigkeit des LimitedEntities diese Woche betrifft, so soll das Panel automatisch
        // ausgeklappt werden
        if (this.isCollapsed && DvbDateUtil.getOverlap(gueltigkeit, this.limitedEntity)) {
            this.isCollapsed = false;
        }
    }

}
