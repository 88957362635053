/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeInstitution} from '@dv/kitadmin/models';
import {assignmentName} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {MatchingState} from '@dv/shared/backend/model/matching-state';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import type {MandantSearchFilter} from '../../../search/model/MandantSearchFilter';
import {ANY_MANDANT} from '../../../search/model/MandantSearchFilter';
import type {KibonInstitutionenService} from '../../service/kibonInstitutionenService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-institution-matching.html'),
    controllerAs: 'vm',
};

export class DvbInstitutionMatching implements angular.IController {
    public static $inject: readonly string[] = [
        'kibonInstitutionenService',
        'dialogService',
        '$translate',
    ];

    public institutions: KibonExchangeInstitution[] = [];
    public filteredInstitutions: KibonExchangeInstitution[] = [];

    public currentPage: number = 1;
    public isLoading: boolean = false;
    public itemsPerPage: number = 10;

    public kinderOrtMapping: { [institutionId: string]: SearchResultEntry } = {};
    public matchingStateFilter: Set<MatchingState> = new Set();

    public mandantFilter: MandantSearchFilter = ANY_MANDANT;

    public constructor(
        private readonly kibonInstitutionenService: KibonInstitutionenService,
        private readonly dialogService: DialogService,
        private readonly $translate: angular.translate.ITranslateService,
    ) {
        this.loadInstitutions();

        // default filter
        this.matchingStateFilter.add(MatchingState.OPEN);
        this.matchingStateFilter.add(MatchingState.REMOVED);
    }

    public updatePage(page: number): void {
        this.currentPage = page;
    }

    public onUpdateItemsPerPage(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;
    }

    public assign(institution: KibonExchangeInstitution): void {
        const institutionId = checkPresent(institution.id);
        const searchResult = this.kinderOrtMapping[institutionId];
        const mandantName = checkPresent(searchResult.mandantName);
        const title = this.$translate.instant(
            'KIBON.INSTITUTION_MATCHING.ZUWEISEN',
            {
                institution: institution.getDisplayName(),
                kinderOrt: assignmentName(searchResult.text, mandantName),
            });

        const confirm = (): Observable<unknown> => from(this.kibonInstitutionenService.assignKibonInstitution(
            institutionId,
            checkPresent(searchResult.id))
            .then(() => this.loadInstitutions()));

        this.dialogService.openConfirmDialog({
            title,
            confirm,
        });
    }

    public removeAssignment(institution: KibonExchangeInstitution): void {
        const institutionId = checkPresent(institution.id);

        const title = this.$translate.instant('KIBON.INSTITUTION_MATCHING.ZUWEISUNG_ENTFERNEN',
            {institution: institution.getDisplayName(), kinderOrt: institution.getKinderOrtAndMandantName()});

        this.dialogService.openConfirmDialog({
            title,
            confirm: () => from(this.kibonInstitutionenService.unassignKibonInstitution(institutionId)
                .then(() => this.loadInstitutions())),
        });
    }

    public toggleFilter(name: MatchingState): void {
        if (this.matchingStateFilter.has(name)) {
            this.matchingStateFilter.delete(name);
        } else {
            this.matchingStateFilter.add(name);
        }

        this.filterInstitutions();
    }

    public filterSelected(name: MatchingState): boolean {
        return this.matchingStateFilter.has(name);
    }

    public isAssigned(institution: KibonExchangeInstitution): boolean {
        switch (institution.matchingState) {
            case MatchingState.MATCHED:
            case MatchingState.MANUALLY_ASSIGNED:
                return true;
            default:
                return false;
        }
    }

    private loadInstitutions(): void {
        this.isLoading = true;
        this.kibonInstitutionenService.getInstitutionen({
            includes: '(kinderOrt.fields(mandant))',
        }).then(institutions => {
            this.institutions = institutions;
            this.filterInstitutions();
            this.isLoading = false;
        });
    }

    private filterInstitutions(): void {
        this.filteredInstitutions = this.institutions.filter(institution =>
            this.matchingStateFilter.size === 0 || this.filterSelected(institution.matchingState!),
        );
    }
}

componentConfig.controller = DvbInstitutionMatching;
angular.module('kitAdmin').component('dvbInstitutionMatching', componentConfig);
