<div class="dvb-tasks-badge">

    <script type="text/ng-template" id="tasksBadgeTooltipTemplate.html">
        <ul class="popover-list" ng-if="vm.tasks.length > 0">
            <li ng-repeat="task in vm.tasks">
                <dv-display-task [task]="task"></dv-display-task>
            </li>
        </ul>
    </script>

    <span class="badge"
          ng-class="{'warning': vm.warning}"
          uib-popover-template="'tasksBadgeTooltipTemplate.html'"
          popover-enable="{{!vm.popoverDisable}}"
          popover-title="{{vm.popoverTitle}}"
          popover-trigger="'mouseenter focus'"
          popover-placement="bottom"
          popover-append-to-body="true"
          data-translate="{{vm.label}}"></span>
</div>
