/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {RestTimeout} from '@dv/kitadmin/models';
import {TempBlob} from '@dv/kitadmin/models';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../../config';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import {
    BetreuungsPersonMonatlicheStundenErfassung,
} from '../models/stundenerfassung/BetreuungsPersonMonatlicheStundenErfassung';
import {KindBetreuungErfassung} from '../models/stundenerfassung/KindBetreuungErfassung';
import type {MonatlicheStundenerfassungStatus} from '../models/stundenerfassung/MonatlicheStundenerfassungStatus';

export class KindBetreuungErfassungService {
    public static $inject: readonly string[] = ['$http', 'errorService', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kindBetreuungErfassung`;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly errorService: ErrorService,
        private readonly $q: angular.IQService,
    ) {
    }

    public getMonatlicheStundenErfassung(
        angestellteId: string,
        stichtag: moment.Moment,
        config: RestTimeout,
    ): angular.IPromise<BetreuungsPersonMonatlicheStundenErfassung> {
        const url = `${KindBetreuungErfassungService.BASE_URL}/angestellte/${encodeURIComponent(angestellteId)}`;
        const matrixParams = {stichtag: DvbRestUtil.momentToLocalDate(stichtag)};

        return DvbRestUtilAngularJS.getModelByUrlAndParams(
            url, BetreuungsPersonMonatlicheStundenErfassung, matrixParams, config,
        ).then(checkPresent);
    }

    public saveKindBetreuungErfassung(
        angestellteId: string,
        kindId: string,
        entry: KindBetreuungErfassung,
    ): angular.IPromise<KindBetreuungErfassung> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindBetreuungErfassungService.BASE_URL}/angestellte/${encodeURIComponent(angestellteId)}/kind/${
            encodeURIComponent(kindId)}`;

        return DvbRestUtilAngularJS.postAndGetModel(url, entry.toRestObject(), KindBetreuungErfassung);
    }

    public changeStatus(
        angestellteId: string,
        stichtag: moment.Moment,
        status: MonatlicheStundenerfassungStatus,
    ): angular.IPromise<unknown> {
        const matrixParams = {stichtag: DvbRestUtil.momentToLocalDate(stichtag)};
        const url = `${KindBetreuungErfassungService.BASE_URL}/angestellte/${
            encodeURIComponent(angestellteId)}/status/${status}${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.put(url, {});
    }

    public downloadMonatlicheStundenErfassungSummaryPdf(
        angestellteId: string,
        stichtag: moment.Moment,
        dvbDownloadCtrl: DvbDownload,
    ): angular.IPromise<unknown> {
        const matrixParams = {stichtag: DvbRestUtil.momentToLocalDate(stichtag)};
        const url = `${KindBetreuungErfassungService.BASE_URL}/angestellte/${
            encodeURIComponent(angestellteId)}/summary${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        return this.$http.get(url)
            .then(response => DvbRestUtilAngularJS.mapIfData(response,
                r => TempBlob.apiResponseTransformer(r.data)))
            .then(tempBlob => dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .catch(error => {
                if (DvbRestUtil.hasNoData(error)) {
                    this.errorService.addValidationError('PERSONAL.STUNDENERFASSUNG.NO_DOWNLOAD_DATA');

                    return;
                }

                return this.$q.reject(error);
            });
    }
}
