/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout} from '@dv/kitadmin/models';
import angular from 'angular';
import type {DokumentlayoutService} from '../../../dokumentlayout/service/dokumentlayoutService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        dokumentLayout: '<?',
        onChange: '&',
    },
    template: require('./dvb-dokument-layout.html'),
    controllerAs: 'vm',
};

export class DvbDokumentLayout implements angular.IController {
    public static $inject: readonly string[] = ['dokumentlayoutService'];

    public onChange?: (value: { dokumentLayout: DokumentLayout }) => any;

    public dokumentLayout?: DokumentLayout | null = null;
    public loadingLayouts: boolean = false;
    public layouts: DokumentLayout[] = [];

    public constructor(private readonly dokumentlayoutService: DokumentlayoutService) {
    }

    public $onInit(): void {
        this.loadingLayouts = true;
        this.dokumentlayoutService.getAllDokumentLayouts()
            .then(layouts => {
                this.layouts = layouts;
            })
            .finally(() => {
                this.loadingLayouts = false;
            });
    }
}

componentConfig.controller = DvbDokumentLayout;
angular.module('kitAdmin').component('dvbDokumentLayout', componentConfig);
