/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        isCollapsed: '<',
        showReset: '<?',
        collapsible: '<?',
        onReset: '&',
        onToggle: '&',
    },
    template: require('./dvb-filter-toggler.html'),
    controllerAs: 'vm',
};

export class DvbFilterToggler {
    public static $inject: readonly string[] = [];

    public isCollapsed!: boolean;
    public showReset?: boolean;
    public collapsible: boolean = true;
    public onReset!: () => void;
    public onToggle!: () => void;
}

componentConfig.controller = DvbFilterToggler;
angular.module('kitAdmin').component('dvbFilterToggler', componentConfig);
