<div class="dvb-administration-mandant-rechnung-heading row">
    <div class="col-xs-7">
        <p class="truncate"
           ng-bind="vm.rechnung.mandant.name"
           uib-tooltip="{{vm.rechnung.mandant.name}}"
           tooltip-placement="top-left">
        </p>
    </div>
    <div class="col-xs-2">
        <p>
            <span class="badge"
                  uib-popover-template="'showModules.html'"
                  popover-enable="true"
                  popover-title="{{'MANDANT.MODULES' | translate}}"
                  popover-trigger="'mouseenter focus'"
                  popover-placement="bottom"
                  popover-append-to-body="true"
                  data-translate="{{'MANDANT.SUBSCRIPTION_MODEL.' + vm.rechnung.subscriptionModel}}">
            </span>
        </p>
    </div>
    <div class="col-xs-3 text-right">
        <p class="truncate"
           data-translate="COMMON.BETRAG_VALUE"
           data-translate-value-betrag="{{vm.rechnung.summe | number: 2}}">
        </p>
    </div>
</div>

<script type="text/ng-template" id="showModules.html">
    <dvb-module-list modules="vm.rechnung.modules"></dvb-module-list>
</script>
