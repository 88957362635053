<div class="dvb-kita-mahnlauf row big-abstand-top">
    <div class="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
        <form name="kitaMahnlaufForm"
              ng-submit="vm.submit(kitaMahnlaufForm)"
              novalidate>

            <h2 data-translate="KINDERORT.MAHNLAUF_STATUS_ERSTELLEN"
                data-translate-value-status="{{vm.status.msgKey | translate}}">
            </h2>

            <dvb-rechnungen is-loading="vm.isLoading"
                            rechnungen="vm.rechnungen"
                            mode="vm.mode"
                            selection="vm.selectedRechnungen"
                            errors="vm.errors"
                            show-versandart-badge="true">
            </dvb-rechnungen>

            <div class="row big-abstand-top">
                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.zahlungsFrist"
                                               required
                                               is-disabled="vm.isSubmitting"
                                               ng-attr-placeholder="{{'COMMON.ZAHLUNGSFRIST_PLACEHOLDER' | translate}}">
                    </dvb-datepicker-text-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"
                     uib-tooltip="{{vm.mahngebuehrLocked ? ('KINDERORT.MAHNGEBUEHR_LOCKED' | translate: {date: vm.kita.rechnungsLaufLockedBefore.format('D.M.YYYY')}) : ''}}"
                     tooltip-placement="top-left">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox"
                                   ng-model="vm.showGebuehr"
                                   ng-change="vm.resetGebuehr()"
                                   ng-disabled="vm.isSubmitting || vm.mahngebuehrLocked">
                            <span data-translate="KINDERORT.MAHNGEBUEHR"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row" uib-collapse="!vm.showGebuehr">
                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           ng-model="vm.gebuehrText"
                           ng-required="vm.showGebuehr"
                           ng-disabled="vm.isSubmitting"
                           ng-attr-placeholder="{{'COMMON.BEZEICHNUNG' | translate}}"
                           uib-tooltip="{{'COMMON.BEZEICHNUNG' | translate}}"
                           tooltip-placement="top-left">
                </div>
                <div class="col-md-6">
                    <input type="number"
                           class="form-control"
                           ng-model="vm.gebuehrBetrag"
                           ng-required="vm.showGebuehr"
                           ng-disabled="vm.isSubmitting"
                           ng-attr-placeholder="{{'COMMON.BETRAG_CHF' | translate}}"
                           uib-tooltip="{{'COMMON.BETRAG_CHF' | translate}}"
                           tooltip-placement="top-left"
                           min="0">
                </div>
            </div>

            <dvb-email-versand-form email-versand-properties="vm.emailVersandProperties"
                                    is-disabled="vm.isLoading"
                                    ng-if="vm.rechnungen.length > 0">
            </dvb-email-versand-form>

            <div class="row">
                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" ng-model="vm.activateDownload">
                            <span data-translate="KINDERORT.MAHNUNGEN_HERUNTERLADEN"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="normal-abstand-top">
                <ul class="list-inline">
                    <li>
                        <dvb-loading-button is-loading="vm.isLoading || vm.isSubmitting" type="submit">
                            <span data-translate="KINDERORT.MAHNLAUF_STATUS_ERSTELLEN"
                                  data-translate-value-status="{{vm.status.msgKey | translate}}">
                            </span>
                        </dvb-loading-button>
                    </li>
                    <li>
                        <a href=""
                           data-translate="COMMON.ABBRECHEN"
                           ng-click="vm.cancel()"
                           ng-show="!vm.isSubmitting"></a>
                    </li>
                </ul>
            </div>

        </form>
    </div>
</div>
