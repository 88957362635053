<span class="dvb-belegungs-zustand-icon" ng-switch="vm.belegungsZustand">
    <span ng-switch-when="BELEGT"
          class="custom-icon custom-icon-zugewiesen"
          uib-tooltip="{{'COMMON.ZUWEISUNG_IST_ZUGEWIESEN' | translate}}"
          tooltip-placement="top-left">
    </span>
    <span ng-switch-when="PROVISORISCH"
          class="custom-icon custom-icon-prov-zugewiesen"
          uib-tooltip="{{'COMMON.ZUWEISUNG_IST_PROVISORISCH' | translate}}"
          tooltip-placement="top-left">
    </span>
    <span ng-switch-when="ANGEBOT_ERSTELLT"
          class="custom-icon custom-icon-angebot"
          uib-tooltip="{{'COMMON.ZUWEISUNG_IST_ANGEBOT' | translate}}"
          tooltip-placement="top-left">
    </span>
</span>
