/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';
import {CONFIG} from '../../../config';
import type {PageContainer, MatrixParams, RestLimited} from '@dv/shared/code';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {DvbRestUtil} from '@dv/shared/code';

export class MutationBadgeInfoService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kibon/mutationbadges`;

    public constructor(private readonly $http: angular.IHttpService) {
    }

    public deleteForLeistungsrechnung(leistungsrechnungId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${MutationBadgeInfoService.BASE_URL}/leistungsrechnung/${encodeURIComponent(leistungsrechnungId)}`;

        return this.$http.delete(url);
    }

    public deleteForKind(
        kindId: string,
        refNr: string,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const matrixParams: MatrixParams = {refNr};

        const url = `${MutationBadgeInfoService.BASE_URL}/kind/${encodeURIComponent(kindId)}`;

        return this.$http.delete(url + DvbRestUtil.encodeMatrixParams(matrixParams));
    }

    public getForKind(
        kindId: string,
        params?: RestLimited,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<string[]> {
        const matrixParams: MatrixParams = {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${MutationBadgeInfoService.BASE_URL}/kind/${encodeURIComponent(kindId)}`;

        return this.$http.get<PageContainer<string>>(url + DvbRestUtil.encodeMatrixParams(matrixParams), config)
            .then(response => response.data.items);
    }
}
