/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// eslint-disable-next-line max-classes-per-file
import type {KibonExchangeEntity} from '@dv/kitadmin/models';
import type {RawParams} from '@uirouter/core/lib/params/interface';
import type angular from 'angular';

export type KibonExchangeEntityActionTranslations = { button: string; confirmation?: string };

export enum ActionType {
    BUTTON = 'BUTTON',
    NAVIGATION = 'NAVIGATION',
}

export interface KibonExchangeEntityAction<T extends KibonExchangeEntity> {
    actionType: ActionType;
    show: (entity: T) => boolean;
    translationKeys: (entity: T) => KibonExchangeEntityActionTranslations;
}

export class KibonExchangeEntityButtonAction<T extends KibonExchangeEntity> implements KibonExchangeEntityAction<T> {

    public actionType = ActionType.BUTTON;

    public constructor(
        public action: (entity: T) => angular.IPromise<unknown>,
        public show: (entity: T) => boolean,
        public translationKeys: (entity: T) => KibonExchangeEntityActionTranslations,
        public confirmationRequired: boolean = true,
    ) {
    }
}

export class KibonExchangeNavigationAction<T extends KibonExchangeEntity> implements KibonExchangeEntityAction<T> {

    public actionType = ActionType.NAVIGATION;

    public constructor(
        public readonly state: string,
        public params: (entity: T) => RawParams | undefined,
        public show: (entity: T) => boolean,
        public translationKeys: (entity: T) => KibonExchangeEntityActionTranslations,
    ) {
    }
}
