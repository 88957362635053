/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BelegungZeitenByDayOfWeek, KinderOrtFraktion, ZeitraumFeld} from '@dv/kitadmin/models';
import {BelegungZeiten} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import type {DayOfWeek, Persisted} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        weekDays: '<',
        anwesenheiten: '<',
        zeitraumFelder: '<',
        fraktion: '<',
        anwesenheitFix: '<',
    },
    template: require('./dvb-zuweisen-zeiten.html'),
    controllerAs: 'vm',
};

export class DvbZuweisenZeiten implements angular.IController {
    public static $inject: readonly string[] = ['authStore'];

    public weekDays: DayOfWeek[] = [];
    public anwesenheiten: BelegungZeitenByDayOfWeek | null = null;
    public zeitraumFelder: ZeitraumFeld[] = [];
    public fraktion!: Persisted<KinderOrtFraktion>;
    public anwesenheitFix: boolean = false;

    public hasZeiten: boolean = false;

    public constructor(
        public readonly authStore: AuthStore,
    ) {
    }

    public $onChanges(_onChangesObj: angular.IOnChangesObject): void {
        this.hasZeiten = isPresent(this.anwesenheiten)
            && Object.values(this.anwesenheiten).some(zeiten => zeiten.length > 0);
    }

    public initZeiten(): void {
        this.hasZeiten = true;
    }

    public addVonBis(tag: DayOfWeek): void {
        if (!this.anwesenheiten) {
            return;
        }
        if (!this.anwesenheiten[tag]) {
            this.anwesenheiten[tag] = [];
        }
        this.anwesenheiten[tag].push(new BelegungZeiten(tag));
        this.hasZeiten = true;
    }

    /**
     * Ideally this would be initialized whenever selected zeitraumFelder change.
     * But unfortunately changig zeitraum felder do not trigger onChanges
     */
    public isDayBelegt(dayOfWeek: DayOfWeek): boolean {
        return this.zeitraumFelder?.some(feld => feld.dayOfWeek === dayOfWeek && feld.selected);
    }

    /**
     * Ideally this would be initialized whenever selected zeitraumFelder change.
     * But unfortunately changig zeitraum felder do not trigger onChanges
     */
    public hasBelegung(): boolean {
        return this.zeitraumFelder?.some(feld => feld.selected);
    }
}

componentConfig.controller = DvbZuweisenZeiten;
angular.module('kitAdmin').component('dvbZuweisenZeiten', componentConfig);
