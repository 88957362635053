/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaBetreuungsfaktorRegel} from '@dv/kitadmin/models';
import {DvbDateUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaBetreuungsfaktorRegeln: '<',
        onRemove: '&',
        onRevert: '&',
    },
    template: require('./dvb-kita-betreuungsfaktor-regel-verlauf.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsfaktorRegelVerlauf implements angular.IController {

    public static $inject: readonly string[] = [];

    public kitaBetreuungsfaktorRegeln: KitaBetreuungsfaktorRegel[] = [];
    public onRemove!: (params: { kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel }) => any;
    public onRevert!: (params: { kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel }) => any;

    public today: moment.Moment = DvbDateUtil.today();

    public isBetreuungsregelBeendet(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel): boolean {
        return !DvbDateUtil.isEndOfTime(kitaBetreuungsfaktorRegel.gueltigBis);
    }

    public revert(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel): void {
        if (TypeUtil.isFunction(this.onRevert)) {
            this.onRevert({kitaBetreuungsfaktorRegel});
        }
    }

    public remove(kitaBetreuungsfaktorRegel: KitaBetreuungsfaktorRegel): void {
        if (TypeUtil.isFunction(this.onRemove)) {
            this.onRemove({kitaBetreuungsfaktorRegel});
        }
    }
}

componentConfig.controller = DvbKitaBetreuungsfaktorRegelVerlauf;
angular.module('kitAdmin').component('dvbKitaBetreuungsfaktorRegelVerlauf', componentConfig);
