/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type BetreuungAnfrageState = 'PENDING' | 'ASSIGNED' | 'PREPARED' | 'COMPLETED' | 'IGNORED';

export const BetreuungAnfrageState = {
    PENDING: 'PENDING' as BetreuungAnfrageState,
    ASSIGNED: 'ASSIGNED' as BetreuungAnfrageState,
    PREPARED: 'PREPARED' as BetreuungAnfrageState,
    COMPLETED: 'COMPLETED' as BetreuungAnfrageState,
    IGNORED: 'IGNORED' as BetreuungAnfrageState
};

