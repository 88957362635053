/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DokumentLayoutDefaults} from '@dv/kitadmin/models';
import {
    DokumentLayout,
    DokumentLayoutLogo,
    DokumentLayoutPageMargin,
    DokumentLayoutTextBlock,
} from '@dv/kitadmin/models';
import {DOKUMENT_LAYOUT_MAX_MM} from '@dv/shared/code';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {DokumentlayoutService} from '../../service/dokumentlayoutService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
        form: '^form',
    },
    bindings: {
        dokumentLayout: '<',
    },
    template: require('./dvb-dokumentlayout-form.html'),
    controllerAs: 'vm',
};

export class DvbDokumentlayoutForm implements angular.IController {

    public static $inject: readonly string[] = [
        'errorService',
        'dokumentlayoutService',
    ];

    public dokumentLayout!: DokumentLayout;
    public form!: angular.IFormController;

    public defaults: DokumentLayoutDefaults = DokumentLayout.defaultValues;
    public readonly maxMM: number = DOKUMENT_LAYOUT_MAX_MM;
    public hasPageMargin: boolean = false;
    public hasLogo: boolean = false;
    public hasReceiverAddress: boolean = false;
    public hasSenderAddress: boolean = false;
    public hasFooter: boolean = false;
    public isLoading: boolean = false;
    public hasMassenversand: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly dokumentlayoutService: DokumentlayoutService,
    ) {
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    public $onInit(): void {
        this.hasPageMargin = !!this.dokumentLayout.pageMargin;
        this.hasLogo = !!this.dokumentLayout.logo;
        this.hasReceiverAddress = !!this.dokumentLayout.receiverAddress;
        this.hasSenderAddress = !!this.dokumentLayout.senderAddress;
        this.hasFooter = !!this.dokumentLayout.footer;
        this.hasMassenversand = !!this.dokumentLayout.massenversandText;
    }

    public updateModus(): void {
        this.defaults = this.dokumentLayout.isKompakt ?
            DokumentLayout.defaultCompactValues :
            DokumentLayout.defaultValues;
    }

    public updatePageMargin(): void {
        this.dokumentLayout.pageMargin = this.hasPageMargin ? new DokumentLayoutPageMargin() : null;
    }

    public updateLogo(): void {
        this.dokumentLayout.logo = this.hasLogo ? new DokumentLayoutLogo() : null;
    }

    public updateReceiverAddress(): void {
        this.dokumentLayout.receiverAddress = this.hasReceiverAddress ? new DokumentLayoutTextBlock() : null;
    }

    public updateSenderAddress(): void {
        this.dokumentLayout.senderAddress = this.hasSenderAddress ? new DokumentLayoutTextBlock() : null;
    }

    public updateFooter(): void {
        this.dokumentLayout.footer = this.hasFooter ? new DokumentLayoutTextBlock() : null;
    }

    public deleteMassenversand(): void {
        this.hasMassenversand = false;
        this.dokumentLayout.massenversandText = null;
    }

    public downloadPreview(): void {
        // Damit ein invalides Form auch als solches kenntlich gemacht wird triggern wir submit manuell
        this.form.$setSubmitted();

        const dokumentLayoutWithDefaults = angular.copy(this.dokumentLayout);
        dokumentLayoutWithDefaults.applyDefaults();

        const valid = this.form.$valid && dokumentLayoutWithDefaults.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        this.isLoading = true;
        this.dokumentlayoutService.createLayoutPreview(dokumentLayoutWithDefaults)
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbDokumentlayoutForm;
angular.module('kitAdmin').component('dvbDokumentlayoutForm', componentConfig);
