<div class="dvb-notiz-erstellen-form row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2 data-translate="NOTIZ.TITLE"
            data-translate-value-date="{{this.gueltigAb | amDateFormat:'YYYY'}}">
        </h2>

        <form class="dvb-form" name="notizErstellenForm" ng-submit="vm.submit(notizErstellenForm)" novalidate>
            <div class="form-section-minus-normal-abstand">

                <input class="form-control"
                       maxlength="255"
                       ng-attr-placeholder="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}"
                       ng-model="vm.notiz.subject"
                       required
                       tooltip-placement="top-left"
                       type="text"
                       uib-tooltip="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}">

                <textarea class="form-control"
                          maxlength="10000"
                          msd-elastic
                          ng-attr-placeholder="{{'NOTIZ.CONTENT' | translate}}"
                          ng-model="vm.notiz.content"
                          rows="6"
                          tooltip-placement="top-left"
                          uib-tooltip="{{'NOTIZ.CONTENT' | translate}}">
                </textarea>

                <div class="row">
                    <dvb-datepicker-text-field ng-model="vm.notizDate"
                                               ng-attr-placeholder="{{'COMMON.DATUM' | translate}}"
                                               ng-required="notizErstellenForm.notizTime.$modelValue"
                                               class="col-md-6">
                    </dvb-datepicker-text-field>

                    <div class="col-md-6">
                        <input type="text"
                               name="notizTime"
                               ng-model="vm.notizTime"
                               dvb-time-input
                               dvb-moment
                               maxlength="10"
                               class="form-control time-input"
                               ng-attr-placeholder="{{'COMMON.UHRZEIT' | translate}}"
                               uib-tooltip="{{'COMMON.UHRZEIT' | translate}}"
                               tooltip-placement="top-left">
                    </div>
                </div>

                <dvb-file-list allow-multiple="true"
                               files="vm.notiz.anhaenge"
                               label-file-upload="COMMON.DATEIEN_IMPORTIEREN"
                               label-no-files="KIND.KEINE_ANHAENGE"
                               on-add-file="vm.notiz.addAnhang(file)"
                               on-remove-file="vm.notiz.removeAnhang(file)"
                               on-upload-error="vm.handleUploadFailure(file)"
                               on-upload-success="vm.saveAnhang(file)">
                </dvb-file-list>

                <dvb-submit-cancel-buttons is-loading="notizErstellenForm.$pending || vm.isLoading"
                                           on-cancel="vm.goBack()"
                                           submit-label="{{vm.notiz.id ? 'NOTIZ.UPDATE_NOTIZ' : 'NOTIZ.CREATE_NOTIZ'}}">
                </dvb-submit-cancel-buttons>
            </div>
        </form>
    </div>
</div>
