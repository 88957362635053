<span class="dvb-offene-posten-bemerkung-icon">
    <span ng-if="vm.offenerPosten.bemerkung && vm.offenerPosten.bemerkung.length > 0">
        <i class="fa fa-comment-o"
           uib-popover-template="'showBemerkung.html'"
           popover-title="{{'COMMON.BEMERKUNG' | translate}}"
           popover-trigger="'mouseenter focus'"
           popover-placement="bottom"
           popover-append-to-body="true"></i>
    </span>
</span>
