<div class="dvb-zuweisen-header header">
    <div class="row header-row">
        <div class="col-md-5">
            <h1 data-translate="KIND.ZUWEISUNG.ZUWEISUNG" class="small-abstand-right"></h1>
        </div>
        <div class="col-md-7">
            <div class="kind-details pull-left">
                <h2 ng-bind="vm.kind.getDisplayName()"></h2>
                <ul class="list-inline" ng-show="!vm.isBetreuungsfaktorLoading">
                    <li class="betreuungsfaktor"
                        ng-repeat="faktor in vm.betreuungsfaktoren">
                        <dvb-betreuungsfaktor-icon faktor="faktor"
                                                   show-kinder-orte="vm.betreuungsfaktoren.length > 1">
                        </dvb-betreuungsfaktor-icon>
                    </li>
                    <li ng-show="vm.kind.hasGeschwister()">
                        <i class="fa fa-chain"
                           uib-tooltip="{{'COMMON.ZUWEISUNG_HAT_GESCHWISTER' | translate}}"
                           tooltip-placement="top-left">
                        </i>
                    </li>
                </ul>
                <ul class="list-inline" ng-show="vm.isBetreuungsfaktorLoading">
                    <li>
                        <dvlib-spinner></dvlib-spinner>
                    </li>
                </ul>
                <span class="geb-und-prio">
                    <span ng-show="vm.kind.geburtsTag"
                          data-translate="COMMON.KIND_GEBOREN"
                          data-translate-value-gebdatum="{{vm.kind.geburtsTag | amDateFormat: 'D.M.YYYY'}}">
                    </span>
                    <span ng-hide="vm.kind.geburtsTag"
                          data-translate="COMMON.KIND_GEBOREN"
                          data-translate-value-gebdatum="{{vm.kind.geburtsTermin | amDateFormat: 'D.M.YYYY'}}">
                    </span>
                </span>
            </div>
            <h2 class="pull-right">
                <i class="fa fa-long-arrow-right"></i>
                <span ng-if="vm.kinderOrte.length === 1">
                    <i class="fa fa-home"></i>
                    <span ng-bind="vm.kinderOrte[0].getDisplayName()"></span>
                </span>
                <span ng-if="vm.kinderOrte.length > 1"
                      uib-tooltip="{{vm.kinderOrtNames}}"
                      tooltip-placement="top-left">
                    <i class="fa fa-home"></i>
                    <span data-translate="COMMON.KINDERORT.MF"
                          data-translate-value-count="{{vm.kinderOrte.length}}"
                          data-translate-value-showcount="true"
                          data-translate-interpolation="messageformat">
                    </span>
                </span>
            </h2>
        </div>
    </div>
</div>
