<div class="rechnungsaufteilung">
    <form name="rechnungsaufteilungForm"
          #rechnungsaufteilungForm="ngForm"
          (ngSubmit)="onSave(!rechnungsaufteilungForm.invalid)"
          novalidate>
        <div class="modal-header">
            <h1 [translate]="'KIND.KINDKONTAKT_RECHNUNGSAUFTEILUNG'"></h1>
        </div>
        <div class="modal-body">

            @for (kontakt of kontaktModel(); track kontaktTrackBy($index, kontakt); let first = $first) {
                <div class="collapsible-panel-container" [ngClass]="{'first-container': first}">
                    <div class="row">
                        <div class="col-md-6">
                            <label>{{ kontakt.kontaktperson | displayName }}</label>
                        </div>
                        <div class="col-md-6">
                            <div class="radio">
                                <label>
                                    <input type="radio"
                                           [name]="'betragprozentradio_' + kontakt.kontaktperson.id"
                                           [(ngModel)]="kontakt.type"
                                           [value]="RECHNUNG_AUFTEILUNG_TYPE.PROZENT"
                                           (ngModelChange)="onTypeChange(kontakt)">
                                    <span [translate]="'KIND.KIND_RECHNUNGSAUFTEILUNG_PROZENT'"></span>
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio"
                                           [name]="'betragprozentradio_' + kontakt.kontaktperson.id"
                                           [(ngModel)]="kontakt.type"
                                           [value]="RECHNUNG_AUFTEILUNG_TYPE.FIXBETRAG"
                                           (ngModelChange)="onTypeChange(kontakt)">
                                    <span [translate]="'KIND.KIND_RECHNUNGSAUFTEILUNG_FIXBETRAG'"></span>
                                </label>
                            </div>
                            <input type="number"
                                   class="form-control small-abstand-top"
                                   [name]="'aufteilungValue_' + kontakt.kontaktperson.id"
                                   [(ngModel)]="kontakt.value"
                                   [placeholder]="'KIND.KIND_RECHNUNGSAUFTEILUNG_VALUE_PLACEHOLDER_' + kontakt.type | translate"
                                   [tooltip]="'KIND.KIND_RECHNUNGSAUFTEILUNG_VALUE_PLACEHOLDER_' + kontakt.type | translate"
                                   (ngModelChange)="onValueChange(kontakt)">
                        </div>
                    </div>
                </div>
            }

        </div>
        <div class="modal-footer big-abstand-top">
            <dvlib-button-list>
                <button type="submit"
                        class="btn btn-primary"
                        [translate]="'KIND.KINDKONTAKT_RECHNUNGSAUFTEILUNG_SPEICHERN'">
                </button>
                <button type="button"
                        class="btn btn-link"
                        [translate]="'COMMON.ABBRECHEN'"
                        (click)="onCancel()">
                </button>
            </dvlib-button-list>
        </div>
    </form>
</div>
