<div class="dvb-kita-betreuungs-vereinbarungs-konfiguration">

    <div class="dvb-form">

        <div class="row form-row">
            <div class="col-sm-6">
                <dvb-datepicker-text-field ng-model="vm.konfiguration.gueltigAb"
                                           click-to-edit="true"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}"
                                           on-submit="vm.save(vm.dokumentLayout)"
                                           required>
                </dvb-datepicker-text-field>
            </div>
            <div class="col-sm-6">
                <dvb-datepicker-text-field ng-model="vm.konfiguration.gueltigBis"
                                           click-to-edit="true"
                                           ng-attr-placeholder="{{'FAKTURA.GUELTIG_BIS_OPTIONAL' | translate}}"
                                           on-submit="vm.save(vm.dokumentLayout)">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div class="row form-section">
            <div class="col-md-6">
                <dvb-editable-contextual-adresse contextual-adresse="vm.konfiguration.contextualAdresse"
                                                 allow-none="false"
                                                 on-update="vm.saveContextualAdresse(contextualAdresse)"
                                                 uib-tooltip="{{'COMMUNICATION.EMAIL.ABSENDER_ADRESSE' | translate}}"
                                                 tooltip-placement="top-left"
                                                 dvb-auth
                                                 require-permission="{{'kita:manage:' + vm.kita.id}}"
                                                 on-unauthorised-disable>
                </dvb-editable-contextual-adresse>
            </div>
            <div class="col-md-6">
                <dvb-editable-select
                    options="vm.dokumentLayouts"
                    label="vm.dokumentLayout && vm.dokumentLayout.name || ('KINDERORT.KEIN_LAYOUT' | translate)"
                    on-update="vm.save(model)"
                    on-cancel="vm.updateDokumentLayout()"
                    uib-tooltip="{{'KINDERORT.LAYOUT' | translate}}"
                    tooltip-placement="top-left"
                    dvb-auth
                    require-permission="{{'kita:manage:' + vm.kita.id}}"
                    on-unauthorised-disable>
                </dvb-editable-select>
            </div>
        </div>

        <div class="form-section">
            <h2 data-translate="COMMON.BETREUUNGSVEREINBARUNG.ALTERNATIVE_NAME"></h2>

            <dvb-translatable-editable-label translation="vm.konfiguration.alternativeKinderOrtName"
                                             on-Submit="vm.save(vm.dokumentLayout)"
                                             max-length="255"
                                             dvb-auth
                                             require-permission="{{'kita:manage:' + vm.kita.id}}"
                                             require-condition="!vm.konfiguration.kinderOrtNameVerstecken"
                                             on-unauthorised-disable>
            </dvb-translatable-editable-label>
        </div>

        <div class="form-section">
            <h2 data-translate="KINDERORT.EINFUEHRUNG"></h2>

            <dvb-translatable-editable-text-area translation="vm.konfiguration.einfuehrung"
                                                 placeholder="'KINDERORT.EINFUEHRUNG' | translate"
                                                 on-submit="vm.save(vm.dokumentLayout)"
                                                 max-length="10000"
                                                 dvb-auth
                                                 require-permission="{{'kita:manage:' + vm.kita.id}}"
                                                 on-unauthorised-disable>
            </dvb-translatable-editable-text-area>
        </div>
        <div class="form-section">
            <h2 data-translate="COMMON.OPTIONEN"></h2>
            <div class="form-row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.gruppeVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_GRUPPE_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.platzartVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_PLATZART_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.pensumVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_PENSUM_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.showModuleNames"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.SHOW_MODULE_NAMES"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.faktorVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_FAKTOR_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.belegungZeitenVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_BELEGUNG_ZEITEN_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.kinderOrtNameVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_KINDERORT_NAME_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" ng-if="vm.showBetreuungBeiFamilie">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.betreuungBeiFamilie"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_BETREUUNG_BEI_FAMILIE"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <h3 data-translate="KINDERORT.PENSUM_DISPLAY_TYPE.TITLE"></h3>
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-value="'PCT'"
                               ng-model="vm.konfiguration.pensumDisplayType"
                               ng-change="vm.save(vm.dokumentLayout)">
                        <span data-translate="KINDERORT.PENSUM_DISPLAY_TYPE.PCT"></span>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-value="'TIME'"
                               ng-model="vm.konfiguration.pensumDisplayType"
                               ng-change="vm.save(vm.dokumentLayout)">
                        <span data-translate="KINDERORT.PENSUM_DISPLAY_TYPE.TIME"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-section">
            <h2 data-translate="KINDERORT.KONDITIONEN"></h2>

            <dvb-translatable-editable-text-area translation="vm.konfiguration.konditionen"
                                                 placeholder="'KINDERORT.KONDITIONEN' | translate"
                                                 on-submit="vm.save(vm.dokumentLayout)"
                                                 max-length="10000"
                                                 dvb-auth
                                                 require-permission="{{'kita:manage:' + vm.kita.id}}"
                                                 on-unauthorised-disable>
            </dvb-translatable-editable-text-area>
        </div>
        <div class="form-section">
            <h2 data-translate="KINDERORT.UNTERSCHRIFT_TITEL"></h2>
            <div class="form-row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label uib-tooltip="{{'KINDERORT.UNTERSCHRIFT_TITEL_VERSTECKEN' | translate}}"
                                   tooltip-placement="top-left">
                                <input type="checkbox"
                                       value="true"
                                       ng-model="vm.konfiguration.unterschriftTitleVerstecken"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.IS_UNTERSCHRIFT_TITLE_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label uib-tooltip="{{'KINDERORT.HAS_UNTERSCHRIFT1' | translate}}"
                                   tooltip-placement="top-left">
                                <input type="checkbox"
                                       value="true"
                                       ng-model="vm.konfiguration.unterschrift1"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.UNTERSCHRIFT1"></span>
                            </label>
                        </div>
                        <div ng-if="vm.konfiguration.unterschrift1" class="checkbox-indent">
                            <dvb-translatable-editable-label translation="vm.konfiguration.unterschrift1Str"
                                                             on-Submit="vm.save(vm.dokumentLayout)"
                                                             max-length="255"
                                                             dvb-auth
                                                             require-permission="{{'kita:manage:' + vm.kita.id}}"
                                                             require-condition="vm.konfiguration.unterschrift1"
                                                             on-unauthorised-disable>
                            </dvb-translatable-editable-label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="checkbox">
                            <label uib-tooltip="{{'KINDERORT.HAS_UNTERSCHRIFT2' | translate}}"
                                   tooltip-placement="top-left">
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.unterschrift2"
                                       ng-change="vm.save(vm.dokumentLayout)"
                                       dvb-auth
                                       require-permission="{{'kita:manage:' + vm.kita.id}}"
                                       require-condition="vm.konfiguration.unterschrift1"
                                       on-unauthorised-disable>
                                <span data-translate="KINDERORT.UNTERSCHRIFT2"></span>
                            </label>
                        </div>
                        <div ng-if="vm.konfiguration.unterschrift2" class="checkbox-indent">
                            <dvb-translatable-editable-label translation="vm.konfiguration.unterschrift2Str"
                                                             on-Submit="vm.save(vm.dokumentLayout)"
                                                             max-length="255"
                                                             dvb-auth
                                                             require-permission="{{'kita:manage:' + vm.kita.id}}"
                                                             on-unauthorised-disable
                                                             require-condition="vm.konfiguration.unterschrift1">
                            </dvb-translatable-editable-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-section">
            <h2 data-translate="DOKUMENT_LAYOUT.FUSSZEILE"></h2>
            <dvb-translatable-editable-text-area translation="vm.konfiguration.fusszeile"
                                                 placeholder="'DOKUMENT_LAYOUT.FUSSZEILE' | translate"
                                                 on-submit="vm.save(vm.dokumentLayout)"
                                                 max-length="1000"
                                                 dvb-auth
                                                 require-permission="{{'kita:manage:' + vm.kita.id}}"
                                                 on-unauthorised-disable>
            </dvb-translatable-editable-text-area>
        </div>
    </div>
</div>
