/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KibonExchangeVerfuegung} from '@dv/kitadmin/models';
import {MatchingState} from '@dv/shared/backend/model/matching-state';
import type {PageContainer} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../config';
import {DvbRestUtilAngularJS} from '../../common/service/rest/dvbRestUtilAngularJS';
import type {VerfuegungenFilter} from '../models/VerfuegungenFilter';
import type {KibonQueryApiService, QueryKibonExchangeApiQueryapiParameter} from './kibonQueryApiService';

export class KibonVerfuegungenService implements KibonQueryApiService {

    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/kibon/verfuegungen`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public queryExchangeService(params: Readonly<QueryKibonExchangeApiQueryapiParameter>): angular.IPromise<unknown> {
        const url = `${KibonVerfuegungenService.BASE_URL}/queryapi${DvbRestUtil.encodeMatrixParams(params)}`;

        return this.$http.get(url);
    }

    /**
     * Delivers any unmatched kibon verfuegungen.
     */
    public getUnmatchedVerfuegungen(): angular.IPromise<KibonExchangeVerfuegung[]> {
        const url = `${KibonVerfuegungenService.BASE_URL}/unmatched`;

        return DvbRestUtilAngularJS.getModelsArray(url, KibonExchangeVerfuegung, 'verfuegungen', {}, false);
    }

    public getFilteredVerfuegungen(
        filter?: VerfuegungenFilter,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<KibonExchangeVerfuegung>> {

        const url = `${KibonVerfuegungenService.BASE_URL}`;

        return DvbRestUtilAngularJS.getPagedItems(url, KibonExchangeVerfuegung, filter?.toRestObject(), config);
    }

    /**
     * Assigns a KibonExchange verfuegung to a kitAdmin kind and imports it.
     */
    public assignKibonVerfuegung(verfuegungId: string, kindId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KibonVerfuegungenService.BASE_URL}/` +
            `${encodeURIComponent(verfuegungId)}/assign/${encodeURIComponent(kindId)}`;

        return this.$http.put(url, {});
    }

    public bulkIgnoreVerfuegungen(
        filter: VerfuegungenFilter,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<angular.IHttpResponse<unknown>> {

        return this.bulkUpdateVerfuegungen(filter, MatchingState.IGNORED, config);
    }

    public bulkUnIgnoreVerfuegungen(
        filter: VerfuegungenFilter,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<angular.IHttpResponse<unknown>> {

        return this.bulkUpdateVerfuegungen(filter, MatchingState.OPEN, config);
    }

    public unIgnoreKibonVerfuegung(verfuegungId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KibonVerfuegungenService.BASE_URL}/` +
            `${encodeURIComponent(verfuegungId)}/ignore`;

        return this.$http.delete(url);
    }

    /**
     * Flags the verfuegung as ignored. Meaning it will not be matched.
     */
    public ignoreKibonVerfuegung(verfuegungId: string): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KibonVerfuegungenService.BASE_URL}/` +
            `${encodeURIComponent(verfuegungId)}/ignore`;

        return this.$http.put(url, {});
    }

    // noinspection JSMethodCanBeStatic
    private bulkUpdateVerfuegungen(
        filter: VerfuegungenFilter,
        desiredState: MatchingState,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<angular.IHttpResponse<unknown>> {
        const url = `${KibonVerfuegungenService.BASE_URL}/${desiredState}`;

        const data = filter.toRestObject();
        delete data.limit;
        delete data.top;

        return this.$http.put(url, data, config);
    }
}
