/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IValidable} from '@dv/shared/code';
import angular from 'angular';

/**
 * A directive to trigger model.isValid()
 */
const directive: angular.IDirective = {
    restrict: 'A',
    require: {ngModelCtrl: 'ngModel'},
    bindToController: {
        dependentControl: '<?',
    },
};

export class DvbIsValidController implements angular.IController {

    public static $inject: readonly string[] = ['$attrs', '$scope', '$parse'];

    private ngModelCtrl!: angular.INgModelController;
    private dependentControl?: angular.INgModelController;

    public constructor(
            private readonly $attrs: angular.IAttributes,
            private readonly $scope: angular.IScope,
            private readonly $parse: angular.IParseService,
    ) {
    }

    public $onInit(): void {
        const fn = this.$parse(this.$attrs.dvbIsValid);
        const validableModel: IValidable = fn(this.$scope);

        this.ngModelCtrl.$validators.dvbIsValid = (value: IValidable): boolean => {
            if (this.ngModelCtrl.$isEmpty(value)) {
                return true;
            }

            return (validableModel || value).isValid();
        };

        if (this.dependentControl) {
            this.ngModelCtrl.$viewChangeListeners.push(() => this.updateValidationOfDependentControl());
        }
    }

    private updateValidationOfDependentControl(): void {
        // since the form controls are dependend on each other, make sure to trigger validation on the dependent one
        this.dependentControl?.$validate();
    }
}

directive.controller = DvbIsValidController;
angular.module('kitAdmin').directive('dvbIsValid', () => directive);
