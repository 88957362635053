<form name="queryApi" ng-submit="vm.onSubmit(queryApi)" novalidate>
    <h2 data-translate="KIBON.MAINTENANCE.QUERY_API"></h2>

    <input type="text"
           class="form-control"
           ng-attr-placeholder="{{'KIBON.REFERENZ_NUMMER' | translate}}"
           uib-tooltip="{{'KIBON.REFERENZ_NUMMER' | translate}}"
           tooltip-placement="top-left"
           ng-model="vm.model.refnr"
           maxlength="17">

    <div class="input-group normal-abstand-bottom">
        <input type="text"
               autocomplete="off"
               ng-model="vm.selectedInstitution"
               class="form-control"
               ng-attr-placeholder="{{'KIBON.MAINTENANCE.INSTITUTION' | translate}}"
               uib-tooltip="{{'KIBON.MAINTENANCE.INSTITUTION' | translate}}"
               tooltip-placement="top-left"
               uib-typeahead="institution as institution.getDisplayName() for institution in vm.institutionen | filter: {$: $viewValue} | orderBy:'name' | limitTo:50"
               typeahead-focus-on-select="false"
               typeahead-on-select="vm.selectInstitution($item, $model, $label, $event)"
               typeahead-editable="false"
               typeahead-select-on-exact="true"
               typeahead-min-length="0"
               typeahead-select-on-blur="false"
               typeahead-focus-first="false">
        <div class="input-group-btn">
            <button class="btn btn-default"
                    type="button"
                    ng-click="vm.resetInstitution()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   ng-attr-placeholder="{{'KIBON.MAINTENANCE.LIMIT' | translate}}"
                   uib-tooltip="{{'KIBON.MAINTENANCE.LIMIT' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.model.limit"
                   min="0">
        </div>
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   ng-attr-placeholder="{{'KIBON.MAINTENANCE.AFTER_ID' | translate}}"
                   uib-tooltip="{{'KIBON.MAINTENANCE.AFTER_ID' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.model.afterId"
                   min="0">
        </div>
    </div>

    <dvb-loading-button type="submit" class="normal-abstand-top" is-loading="vm.formContext.isLoading">
        <span data-translate="COMMON.IMPORTIEREN"></span>
    </dvb-loading-button>
</form>
