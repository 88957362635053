import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchResultEntry} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {SearchResultIconComponent} from '../search-result-icon/search-result-icon.component';

@Component({
    selector: 'dv-search-entity-result',
    standalone: true,
    imports: [SearchResultIconComponent, TranslateModule],
    templateUrl: './search-entity-result.component.html',
    styleUrls: ['./search-entity-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchEntityResultComponent {

    @Input({required: true}) public searchResultEntry!: SearchResultEntry;
    @Input({required: true}) public showRemoveButton!: boolean;
    @Input({required: true}) public isDisabled!: boolean;
    @Output() public readonly remove = new EventEmitter<SearchResultEntry>();
}
