/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, Rechnung} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, DvbUtil, isPresent, LeistungsrechnungStatus} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {LeistungenService} from '../../../common/service/leistungenService';
import type {LeistungsrechnungenByMonth} from '../../../common/service/LeistungsrechnungenByMonth';
import type {KindService} from '../../../common/service/rest/kind/kindService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        selectedMonth: '<',
        leistungsrechnungenMonthly: '<',
        kitaNamen: '<',
    },
    template: require('./dvb-kind-leistungen-rechnungen.html'),
    controllerAs: 'vm',
};

export class DvbKindLeistungenRechnungen implements angular.IController {
    public static $inject: readonly string[] = [
        'kindService',
        'leistungenService',
        '$q',
    ];

    public kind!: Persisted<Kind>;
    public selectedMonth!: moment.Moment;
    public leistungsrechnungenMonthly: LeistungsrechnungenByMonth[] = [];
    public kitaNamen: { [kitaId: string]: string } = {};

    public kitaIds: string[] = [];
    public rechnungen: { [kitaId: string]: Rechnung[] } = {};
    public isRechnungenLoading: boolean = false;
    public hasRechnungen: boolean = false;

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        private readonly kindService: KindService,
        private readonly leistungenService: LeistungenService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.kitaIds = Object.keys(this.kitaNamen);
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.selectedMonth || changes.leistungsrechnungenMonthly) {
            this.loadRechnungen(this.selectedMonth);
        }

        if (changes.kitaNamen && !changes.kitaNamen.isFirstChange()) {
            this.kitaIds = Object.keys(changes.kitaNamen.currentValue);
        }
    }

    private loadRechnungen(startOfMonth: moment.Moment): void {
        this.isRechnungenLoading = false;
        this.rechnungen = {};
        this.hasRechnungen = false;

        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();

        if (startOfMonth === null) {
            return;
        }

        const leistungsrechnungMonthlyByMonat = this.leistungenService.getLeistungsrechnungMonthlyByMonat(
            this.leistungsrechnungenMonthly, startOfMonth);

        if (!leistungsrechnungMonthlyByMonat) {
            return;
        }

        const hasRechnungen = leistungsrechnungMonthlyByMonat.leistungsrechnungen.some(leistungsrechnung =>
            leistungsrechnung.status !== LeistungsrechnungStatus.VERRECHENBAR &&
            leistungsrechnung.status !== LeistungsrechnungStatus.NICHT_VERRECHENBAR);

        if (!hasRechnungen) {
            return;
        }

        const params = {
            gueltigAb: DvbRestUtil.momentToLocalDate(startOfMonth),
            gueltigBis: DvbRestUtil.momentToLocalDate(DvbDateUtil.endOfMonth(moment(startOfMonth))),
        };

        const config = {
            timeout: this.timeout.promise,
        };

        this.isRechnungenLoading = true;

        this.kindService.getRechnungen(this.kind.id, params, config)
            .then(response => {
                DvbUtil.uniqueArray(response.map(rechnung => rechnung.kitaId))
                    .filter(isPresent)
                    .forEach(id => this.rechnungen[id] = []);

                response.forEach(rechnung => {
                    if (rechnung.kitaId) {
                        this.rechnungen[rechnung.kitaId].push(rechnung);
                    }
                });
                this.hasRechnungen = Object.keys(this.rechnungen).length > 0;
            })
            .finally(() => {
                this.isRechnungenLoading = false;
            });
    }
}

componentConfig.controller = DvbKindLeistungenRechnungen;
angular.module('kitAdmin').component('dvbKindLeistungenRechnungen', componentConfig);
