/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

type Scope = angular.IScope & {
    maxlength: string;
    editableText: string;
    isValid: (data: { param: string }) => unknown;
    onSubmit: () => unknown;
};

function dvbEditableTextArea(): angular.IDirective<Scope> {
    //noinspection UnnecessaryLocalVariableJS
    const directive = {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        scope: {
            model: '=ngModel',
            placeholder: '@',
            onSubmit: '&',
            isValid: '&',
        },
        template: require('./dvb-editable-text-area.html'),
        link,
    };

    return directive;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function link(
        scope: Scope,
        element: JQuery,
        attrs: angular.IAttributes,
        ctrl: any,
    ): void {
        const ngModelCtrl: angular.INgModelController = ctrl;
        // Hack: No need to set max-length when attribute value is -1
        if (attrs.maxlength !== -1) {
            scope.maxlength = attrs.maxlength ?? '1000';
        }

        let isDisabled = false;
        const textArea = element.find('textarea');

        displayLabel();

        attrs.$observe('disabled', value => {
            isDisabled = !!value;
            textArea.attr('disabled', isDisabled as unknown as string);
        });

        function displayLabel(): void {
            element.removeClass('dvb-click2edit-open');
        }

        function displayInput(): void {
            element.addClass('dvb-click2edit-open');
        }

        function notifySubmit(): void {
            if (scope.editableText === ngModelCtrl.$modelValue) {
                // der Wert hat sich nicht geaendert -> label mode
                element.removeClass('has-error');
                displayLabel();
            } else if (isValid(scope.editableText)) {
                // der Wert hat geaendert und ist gültig -> submit
                ngModelCtrl.$setViewValue(scope.editableText);
                ngModelCtrl.$render();
                scope.onSubmit();
                element.removeClass('has-error');
                displayLabel();
            } else {
                // es hat noch ein Fehler
                element.addClass('has-error');
                textArea.trigger('focus');
            }
        }

        function isValid(newString: string): boolean {
            const valid = scope.isValid({param: newString});

            return typeof valid === 'boolean' ? valid : true;
        }

        // Click on Label triggers edit mode
        textArea.on('click', () => {
            if (isDisabled) {
                return;
            }

            if (angular.element('.dvb-click2edit-open').length === 0) {
                displayInput();
            }
        });

        textArea.on('keydown', event => {
            if (event.key === 'Escape') { // Reset on ESC
                ngModelCtrl.$setViewValue(ngModelCtrl.$modelValue);
                ngModelCtrl.$render();
                textArea.trigger('blur');
                scope.$apply();
            }
        });

        // Trigger Submit on blur
        textArea.on('blur', () => {
            notifySubmit();
        });

        ngModelCtrl.$formatters.push(modelValue => modelValue);

        ngModelCtrl.$render = () => {
            scope.editableText = ngModelCtrl.$viewValue;
        };

        ngModelCtrl.$parsers.push(viewValue => viewValue);
    }
}

angular.module('kitAdmin').directive('dvbEditableTextArea', dvbEditableTextArea);
