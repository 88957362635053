<div class="dvb-adresse-verlauf">
    <div
        ng-repeat="limitedAdresse in (sortedAdressen = (vm.limitedAdressen | toArray:false | orderBy:'gueltigAb':true))"
        ng-init="limitedAdresse.isEditMode = false">

        <dvb-collapsible-panel limited-entity="limitedAdresse"
                               is-first="$first"
                               expand-ab="vm.today" expand-bis="vm.today">
            <panel-header>
                <div>
                    <p>
                        <span data-translate="{{vm.getLimitedAdresseTranslationKey(limitedAdresse)}}"
                              data-translate-value-preposition="{{vm.temporalPrepositionKey(limitedAdresse) | translate}}"></span>
                        <span ng-bind="limitedAdresse.gueltigAb | amDateFormat:'D.M.YYYY'"></span>
                    </p>
                </div>
            </panel-header>
            <panel-content>
                <div>
                    <p>
                        <span data-translate="{{vm.getLimitedAdresseTranslationKey(limitedAdresse)}}"
                              data-translate-value-preposition="{{vm.temporalPrepositionKey(limitedAdresse) | translate}}"></span>
                        <span ng-bind="limitedAdresse.gueltigAb | amDateFormat: 'D.M.YYYY'"></span>
                    </p>
                </div>

                <dvb-adresse adresse="limitedAdresse.adresse"
                             display-type="long"
                             on-save="limitedAdresse.isEditMode = false; vm.onSaveAdresse(adresse, limitedAdresse)"
                             show-edit-mode="limitedAdresse.isEditMode">
                </dvb-adresse>

                <div class="row normal-abstand-top" ng-if="vm.mitbewohner[limitedAdresse.id].showMitbewohner">
                    <div class="col-md-12">
                        <h3 data-translate="COMMON.ADRESSE_MITBEWOHNER"></h3>
                        <ul class="list-unstyled small-abstand-top">
                            <li ng-repeat="kind in vm.mitbewohner[limitedAdresse.id].kinder">
                                <a ui-sref="base.kind.profil({kindId: kind.id})">
                                    <i class="fa fa-child fa-fw"></i>
                                    <span ng-bind="kind.getDisplayName()"></span>
                                </a>
                            </li>
                            <li ng-repeat="kontaktperson in vm.mitbewohner[limitedAdresse.id].kontaktpersonen">
                                <a ui-sref="base.kontaktperson.profil({kontaktpersonId: kontaktperson.id})">
                                    <i class="fa fa-male fa-fw"></i>
                                    <span ng-bind="kontaktperson.getDisplayName()"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <ul class="list-inline normal-abstand-top hidden-print"
                    dvb-auth
                    require-permission="kita:manage:general"
                    require-condition="!limitedAdresse.isEditMode">
                    <li>
                        <a href=""
                           ng-click="limitedAdresse.isEditMode = true"
                           data-translate="COMMON.BEARBEITEN">
                        </a>
                    </li>
                    <li>
                        <a href=""
                           ng-click="vm.showConfirmDeleteAdresseModal(limitedAdresse)"
                           data-translate="COMMON.LOESCHEN">
                        </a>
                    </li>
                </ul>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
