<div class="dvb-bulk-email-form">
    <h2 data-translate="COMMUNICATION.BULK_EMAILS.TITLE"></h2>

    <form name="bulkEmailForm" class="dvb-form" ng-submit="vm.submit(bulkEmailForm)" novalidate>

        <dvb-parameter-kinderkontaktliste default-stichtag="vm.defaultStichtag"
                                          parameter-kinderkontaktliste="vm.parameterKinderkontaktliste">
        </dvb-parameter-kinderkontaktliste>

        <ul class="list-inline big-abstand-top">
            <li>
                <dvb-loading-button type="submit" is-loading="vm.isLoading">
                    <span data-translate="COMMUNICATION.SELECT_RECIPIENTS"></span>
                </dvb-loading-button>
            </li>
            <li ng-if="vm.showCancelBtn">
                <button type="button"
                        class="btn btn-link cancel-button"
                        data-translate="COMMON.ABBRECHEN"
                        ng-click="vm.cancel()">
                </button>
            </li>
        </ul>
    </form>
</div>
