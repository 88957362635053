<div class="row">
    <div class="col-md-7">

        <h2 class="inline-block" [translate]="'CHECK_IN.TAGESINFO.SECTION.TAGESINFO'"></h2>
        <dvlib-date-switcher class="normal-abstand-left normal-abstand-bottom"
                             mode="day"
                             [(date)]="datum"
                             (debounceStarted)="service.setIsLoadingTrue()"
                             (debouncedDateChange)="changeSelectedDay($event)">
        </dvlib-date-switcher>

        <dv-crud-accordion [items]="items()"
                           [isEmpty]="isEmpty()"
                           [emptyText]="'CHECK_IN.TAGESINFO.LISTS.LAST_STATUS_CHANGES_EMPTY' | translate"
                           [createMode]="createMode()"
                           [createText]="nextStateText() | translate"
                           [showDeleteDialog]="showDeleteDialog()"
                           [entityName]="'CHECK_IN.EVENT' | translate"
                           [isLoading]="isLoading()"
                           [headerTemplate]="headerTemplate"
                           [readonlyModeTemplate]="readonlyModeTemplate"
                           [editModeTemplate]="editModeTemplate"
                           [createModeTemplate]="createModeTemplate"
                           (toggleExpanded)="service.toggleExpanded($event)"
                           (editEntity)="service.setEditMode($event)"
                           (confirmDelete)="service.confirmDeleteCheckIn$.next()"
                           (hideDeleteDialog)="service.hideDeleteDialog()"
                           (showCreateMode)="service.enableCreateMode()">
        </dv-crud-accordion>

        <ng-template [dvCrudAccordionTemplate]="items()" let-item #headerTemplate>
            <div class="full-width-block">
                <div class="row item-header">
                    <div class="col-xs-3">
                        <span [translate]="'CHECK_IN.STATUS.VALUES.' + item.entity.status"></span>
                    </div>
                    <div class="col-xs-6">
                        {{ item.entity.kinderOrtName }}
                    </div>
                    <div class="col-xs-3 tabular-nums text-right">
                        @if (item.entity.timestampGeloescht) {
                            <i class="fa fa-trash small-abstand-right"></i>
                        }
                        {{ item.entity.eventTimestamp | dvBackendLocalDateTimeFormat: 'HH:mm' }}
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template [dvCrudAccordionTemplate]="items()" let-item #readonlyModeTemplate>
            <dvlib-button-list>
                <button type="button"
                        class="btn btn-link"
                        (click)="service.setEditMode(item.entity)"
                        translate="COMMON.BEARBEITEN">
                </button>
                @if (item.entity.timestampGeloescht) {
                    <button type="button"
                            class="btn btn-link"
                            (click)="service.restoreCheckIn$.next(item.entity)"
                            [translate]="'COMMON.RESTORE'">
                    </button>
                } @else {
                    <button type="button"
                            class="btn btn-link"
                            (click)="service.deleteCheckIn$.next(item.entity.id)"
                            [translate]="'COMMON.LOESCHEN'">
                    </button>
                }
            </dvlib-button-list>
        </ng-template>

        <ng-template [dvCrudAccordionTemplate]="items()" let-item #editModeTemplate>
            <dv-tagesinfo-form [event]="item.entity"
                               [isLoading]="item.isLoading"
                               (save)="service.updateCheckIn$.next({event: item.entity, time: $event.time})"
                               (cancel)="service.setReadonlyMode(item.entity)">
            </dv-tagesinfo-form>
        </ng-template>

        <ng-template #createModeTemplate>
            <dv-tagesinfo-form [title]="nextStateText()"
                               (save)="service.createCheckIn$.next($event)"
                               (cancel)="service.disableCreateMode()">
            </dv-tagesinfo-form>
        </ng-template>

    </div>
</div>
