/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DialogService} from '@dv/kitadmin/ui';
import type {FunctionType, ILimited} from '@dv/shared/code';
import {FormContext, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import {from, take} from 'rxjs';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        entityForm: '?entityForm',
        entityReadOnly: 'entityReadOnly',
    },
    bindings: {
        entity: '<',
        enableEditMode: '<?',
        translationRoot: '<',
        enableActions: '<?',
        editMode: '<?',
        onSubmit: '&',
        onRemove: '&',
        onEdit: '&?',
    },
    template: require('./dvb-limited-entity-content.html'),
    controllerAs: 'vm',
};

export class DvbLimitedEntityContent {
    public static $inject: readonly string[] = ['errorService', 'dialogService', '$q'];

    public entity!: ILimited;
    public enableEditMode: boolean = true;
    public translationRoot!: string;
    public enableActions: boolean = true;
    public onRemove!: FunctionType;
    public onSubmit!: (data: { context: FormContext }) => unknown;
    public onEdit?: FunctionType;

    public formContext: FormContext = new FormContext();

    public constructor(
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly $q: angular.IQService,
    ) {
    }

    public edit(): void {
        if (this.enableEditMode) {
            this.formContext.enableEditMode();
        }

        if (TypeUtil.isFunction(this.onEdit)) {
            this.onEdit();
        }
    }

    public cancel(): void {
        this.formContext.disableEditMode();
    }

    public remove(): void {
        this.errorService.clearAll();
        this.formContext.disableEditMode();

        this.dialogService.openDeleteDialog({
            entityText: `${this.translationRoot}.ZUWEISUNG`,
            confirm: () => from(this.$q.when(this.onRemove()))
                .pipe(take(1)),
        });
    }

    public submit(): void {
        this.onSubmit({context: this.formContext});
    }
}

componentConfig.controller = DvbLimitedEntityContent;
angular.module('kitAdmin').component('dvbLimitedEntityContent', componentConfig);
