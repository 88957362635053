/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import angular from 'angular';
import type moment from 'moment';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import type {WeeklyControllingFigures} from '../../../../report/models/WeeklyControllingFigures';
import {ControllingReportComponent} from '../model/ControllingReportComponent';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        controllingReport: '<?',
        jahr: '<',
        firstOfWeek: '<',
        lastOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-uebersicht-betreung.html'),
    controllerAs: 'vm',
};

export class DvbKitaUebersichtBetreung extends ControllingReportComponent implements angular.IController {
    public static override $inject: readonly string[] = [];

    public kinderOrt!: KinderOrt;
    public lastOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public bewilligtePlaetze: number | null = null;
    public maximalePlaetze: number | null = null;
    public plaetze: number | null = null;
    public belegtePlaetze: number | null = null;
    public auslastungPct: number | null = null;
    public auslastungSubventioniertPct: number | null = null;
    public avgBewilligtePlaetze: number | null = null;
    public avgMaximalePlaetze: number | null = null;
    public avgPlaetze: number | null = null;
    public avgBelegtePlaetze: number | null = null;
    public avgAuslastungPct: number | null = null;
    public avgAuslastungSubventioniertPct: number | null = null;

    protected setWeeklyFigures(weeklyFigures: WeeklyControllingFigures): void {

        this.bewilligtePlaetze = weeklyFigures.kitaBewilligtePlaetze;
        this.maximalePlaetze = weeklyFigures.kitaMaxPlaetze;
        this.plaetze = weeklyFigures.kitaPlaetze;
        this.belegtePlaetze = weeklyFigures.kitaBelegung;
        this.auslastungPct = weeklyFigures.kitaAuslastungPct;
        this.auslastungSubventioniertPct = weeklyFigures.subvAuslastungPct;
    }

    protected resetWeeklyFigures(): void {
        this.bewilligtePlaetze = null;
        this.maximalePlaetze = null;
        this.plaetze = null;
        this.belegtePlaetze = null;
        this.auslastungPct = null;
        this.auslastungSubventioniertPct = null;
    }

    protected setAverageControllingFigures(controllingReport: ControllingReport): void {
        if (!controllingReport?.average) {
            this.resetAverageControllingFigures();

            return;
        }

        this.avgBewilligtePlaetze = controllingReport.average.kitaBewilligtePlaetze;
        this.avgMaximalePlaetze = controllingReport.average.kitaMaxPlaetze;
        this.avgPlaetze = controllingReport.average.kitaPlaetze;
        this.avgBelegtePlaetze = controllingReport.average.kitaBelegung;
        this.avgAuslastungPct = controllingReport.average.kitaAuslastungPct;
        this.avgAuslastungSubventioniertPct = controllingReport.average.subvAuslastungPct;
    }

    private resetAverageControllingFigures(): void {
        this.avgBewilligtePlaetze = null;
        this.avgMaximalePlaetze = null;
        this.avgPlaetze = null;
        this.avgBelegtePlaetze = null;
        this.avgAuslastungPct = null;
        this.avgAuslastungSubventioniertPct = null;
    }
}

componentConfig.controller = DvbKitaUebersichtBetreung;
angular.module('kitAdmin').component('dvbKitaUebersichtBetreung', componentConfig);
