/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {DvbDateUtil} from '@dv/shared/code';
import type {ParamTypeDefinition} from '@uirouter/core';

export class BackendLocalDateParameter implements ParamTypeDefinition {

    public pattern: RegExp = /\d{4}-\d{2}-\d{2}/;

    public constructor() {
        this.decode = this.decode.bind(this);
        this.encode = this.encode.bind(this);
        this.equals = this.equals.bind(this);
        this.is = this.is.bind(this);
    }

    public decode(val: string | null, _key?: string): BackendLocalDate | null {
        return val;
    }

    public encode(val: unknown, _key?: string): string {
        return typeof val === 'string' ? val : '';
    }

    public equals(a: unknown, b: unknown): boolean {
        return a === b;
    }

    public is(val: any, _key?: string): boolean {
        return DvbDateUtil.isValidBackendLocalDate(val);
    }
}
