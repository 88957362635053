/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MonatlicheStundenErfassungFilterStates} from '@dv/kitadmin/models';
import type {IRestModel, MatrixParams, RestPaginated} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import moment from 'moment';
import {FilterOption} from '../../../filter/shared/FilterOption';

export class StundenBlaetterFilter implements RestPaginated, IRestModel {

    public constructor(
        public kinderOrte: FilterOption[] = [],
        public states: { [k in MonatlicheStundenErfassungFilterStates]?: boolean } = {},
        public gueltigAb: moment.Moment = DvbDateUtil.startOfMonth(moment()),
        public top?: number,
        public skip?: number,
    ) {
    }

    public isFiltering(): boolean {
        return this.getSelectedStates().length > 0 || FilterOption.getSelectedIds(this.kinderOrte).length > 0;
    }

    public reset(): void {
        FilterOption.unselect(this.kinderOrte);
        this.states = {};
        this.top = undefined;
        this.skip = undefined;
    }

    public getSelectedStates(): MonatlicheStundenErfassungFilterStates[] {
        return DvbUtil.keys(this.states)
            .filter(filterState => this.states[filterState]);
    }

    public toRestObject(): MatrixParams {
        const selectedStates = this.getSelectedStates();

        return {
            top: this.top,
            skip: this.skip,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(DvbDateUtil.endOfMonth(moment(this.gueltigAb))),
            showWithoutEntry: selectedStates.includes('NONE'),
            states: selectedStates.filter(state => state !== 'NONE'),
            kinderOrtIds: FilterOption.getSelectedIds(this.kinderOrte),
        };
    }
}
