/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType, RechnungFilterStateName} from '@dv/shared/code';
import {DvbUtil, hasOwnPropertyGuarded, RECHNUNG_FILTER_STATES} from '@dv/shared/code';
import angular from 'angular';
import type {RechnungenFilter} from '../../../../filter/RechnungenFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        filter: '<',
        onUpdate: '&',
    },
    template: require('./dvb-kita-faktura-rechnungen-status-filter.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaRechnungenStatusFilter implements angular.IController {

    public filter!: RechnungenFilter;
    public onUpdate?: FunctionType;

    /**
     * all non mahnung states
     */
    public statesRow0: RechnungFilterStateName[] = [];
    /**
     * all mahnung states
     */
    public statesRow1: RechnungFilterStateName[] = [];

    public $onInit(): void {
        this.statesRow0 = DvbUtil.keys(RECHNUNG_FILTER_STATES)
            .filter(key => RECHNUNG_FILTER_STATES[key].rowIndex === 0)
            .map(key => RECHNUNG_FILTER_STATES[key].name);

        this.statesRow1 = DvbUtil.keys(RECHNUNG_FILTER_STATES)
            .filter(key => RECHNUNG_FILTER_STATES[key].rowIndex === 1)
            .map(key => RECHNUNG_FILTER_STATES[key].name);

        const states = this.statesRow0.concat(this.statesRow1);

        states.forEach(value => {
            if (!hasOwnPropertyGuarded(this.filter.states, value)) {
                this.filter.states[value] = false;
            }
        });
    }
}

componentConfig.controller = DvbKitaFakturaRechnungenStatusFilter;
angular.module('kitAdmin').component('dvbKitaFakturaRechnungenStatusFilter', componentConfig);
