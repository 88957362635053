/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BaseRechnungsPosition, MandantRechnung, MandantRechnungsPosition} from '@dv/kitadmin/models';
import {KinderOrtType, MandantCustomPosition} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {FunctionType} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, FormContext} from '@dv/shared/code';
import angular from 'angular';
import type {Moment} from 'moment';
import moment from 'moment';
import {from, take, tap} from 'rxjs';
import type {MandantRechnungCustomPositionService} from '../../service/mandantRechnungCustomPositionService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        stichtag: '<',
        reload: '&',
    },
    template: require('./dvb-administration-mandant-rechnung-detail.html'),
    controllerAs: 'vm',
};

export class DvbAdministrationMandantRechnungDetail {
    public static $inject: readonly string[] = ['mandantRechnungCustomPositionService', 'dialogService'];

    public rechnung!: MandantRechnung;
    public stichtag!: Moment;
    public reload!: FunctionType;
    public formContext: FormContext = new FormContext();

    public constructor(
        private readonly customPositionService: MandantRechnungCustomPositionService,
        private readonly dialogService: DialogService,
    ) {
    }

    public saveAndUpdatePositionen(customPositionen: BaseRechnungsPosition[]): void {
        const gueltigAb = DvbDateUtil.startOfMonth(moment(this.stichtag));
        const gueltigBis = DvbDateUtil.endOfMonth(moment(this.stichtag));
        const mandantId = checkPresent(this.rechnung.mandant?.id);
        this.formContext.startLoading();

        const mandantPositions = customPositionen.map(pos => {
            if (pos instanceof MandantCustomPosition) {
                return pos;
            }

            return new MandantCustomPosition(null,
                this.rechnung.mandant?.id,
                pos.text,
                pos.count,
                pos.cost,
                gueltigAb,
                gueltigBis);
        });

        this.customPositionService.overrideWithNewCustomPostionen(
            mandantId,
            gueltigAb,
            gueltigBis,
            mandantPositions)
            .then(() => this.reload())
            .finally(() => this.formContext.close());
    }

    public getVerrechenbareKita(): MandantRechnungsPosition[] {
        return this.rechnung.rechnungsPositionen
            .filter(position => position.type === KinderOrtType.KITA && position.count !== 0);
    }

    public getNichtVerrechenbareKita(): MandantRechnungsPosition[] {
        return this.rechnung.rechnungsPositionen
            .filter(position => position.type === KinderOrtType.KITA && position.count === 0 && position.cost !== 0);
    }

    public delete(customPosition: MandantCustomPosition): void {
        this.dialogService.openDeleteDialog({
            entityText: 'ADMINISTRATION.CUSTOM_RECHNUNG_POSITION',
            confirm: () => from(this.customPositionService.deleteMandantCustomPosition(checkPresent(customPosition.id)))
                .pipe(
                    take(1),
                    tap(() => this.reload()),
                ),
        });
    }
}

componentConfig.controller = DvbAdministrationMandantRechnungDetail;
angular.module('kitAdmin').component('dvbAdministrationMandantRechnungDetail', componentConfig);
