<div class="dvb-kinderort-kontakt">
    <div class="title">
        <h2 data-translate="COMMON.KONTAKT"></h2>
    </div>

    <dvb-editable-label ng-attr-placeholder="{{'COMMON.TITEL' | translate}}"
                        maxlength="100"
                        ng-model="vm.kinderOrt.titel"
                        ng-change="vm.saveBasisData()"
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                        on-unauthorised-disable>
    </dvb-editable-label>

    <dvb-editable-label ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                        maxlength="255"
                        ng-model="vm.kinderOrt.name"
                        ng-change="vm.saveBasisData()"
                        is-valid="vm.isNameValid(param)"
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                        on-unauthorised-disable>
    </dvb-editable-label>

    <dvb-adresse ng-click="vm.isAdresseEditMode = true"
                 adresse="vm.kinderOrt.adresse"
                 display-type="long"
                 on-save="vm.adresseSpeichern(adresse)"
                 click-to-edit="true"
                 show-edit-mode="vm.isAdresseEditMode"
                 dvb-auth
                 require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                 on-unauthorised-disable>
    </dvb-adresse>

    <dvb-editable-label ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                        maxlength="100"
                        ng-model="vm.kinderOrt.telefon"
                        ng-change="vm.saveBasisData()"
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                        on-unauthorised-disable>
    </dvb-editable-label>

    <dvb-editable-label ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                        type="email"
                        maxlength="255"
                        ng-model="vm.kinderOrt.email"
                        is-email="true"
                        ng-change="vm.saveBasisData()"
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                        on-unauthorised-disable>
    </dvb-editable-label>

    <dvb-editable-label ng-attr-placeholder="{{'COMMON.WEBSITE' | translate}}"
                        maxlength="255"
                        ng-model="vm.kinderOrt.website"
                        is-link="true"
                        ng-change="vm.saveBasisData()"
                        dvb-protocol-prepender
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                        on-unauthorised-disable>
    </dvb-editable-label>

    <dvb-editable-label ng-attr-placeholder="{{'KINDERORT.ACCOUNTING_ID' | translate}}"
                        maxlength="100"
                        ng-model="vm.kinderOrt.externalAccountingId"
                        ng-change="vm.saveBasisData()"
                        dvb-auth
                        require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}"
                        on-unauthorised-disable>
    </dvb-editable-label>
</div>
