/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersisted} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {RefreshJobStatus} from './RefreshJobStatus';

export class KibonExchangeBetreuungRefreshJob implements IPersisted, ILimited {
    public constructor(
            public id: string,
            public gueltigAb: moment.Moment,
            public gueltigBis: moment.Moment,
            public kinderOrtId: string,
            public status: RefreshJobStatus,
            public affectedKindCount: number,
            public completedKinderIds: string[],
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeBetreuungRefreshJob {
        return new KibonExchangeBetreuungRefreshJob(
                data.id,
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
                checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
                data.kinderOrtId,
                data.status,
                data.affectedKindCount,
                data.completedKinderIds,
        );
    }
}
