<div class="dvb-schliesstage-main dvb-form">
    <div class="header">
        <div class="row header-row">
            <i class="custom-icon custom-icon-palmtree header-icon"
               uib-tooltip="{{'COMMON.SCHLIESSTAGE.PLURAL' | translate}}"
               tooltip-placement="top-left">
            </i>
            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{vm.schliesstage.name}}"
                    tooltip-placement="top-left"
                    ng-bind="vm.schliesstage.name">
                </h1>
            </div>
        </div>
    </div>

    <div class="row form-row">
        <div class="col-md-7">
            <dvb-click2-edit edit-mode="vm.editAllowed && vm.editMode">
                <label-part>
                    <div class="form-control click2edit multi-line"
                         ng-click="vm.edit()"
                         dvb-auth
                         require-permission="kita:betreuung_admin:*"
                         on-unauthorised-disable>
                        <dvb-schliesstage schliesstage="vm.schliesstage"></dvb-schliesstage>
                    </div>
                </label-part>
                <content-part>
                    <dvb-schliesstage-form
                        schliesstage="vm.workingCopySchliesstage"
                        is-loading="vm.isLoading"
                        on-submit="vm.onSave()"
                        on-cancel="vm.onCancel()">
                    </dvb-schliesstage-form>
                </content-part>
            </dvb-click2-edit>
        </div>
    </div>
    <div class="big-abstand-top" dvb-auth require-permission="kita:betreuung_admin:*">
        <div class="form-section">
            <a href=""
               ng-click="vm.confirmDeleteSchliesstag()"
               data-translate="COMMON.SCHLIESSTAGE.SCHLIESSTAG_LOESCHEN"></a>
        </div>
    </div>
</div>

