/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {KONTAKTPERSON_STATES} from './kontaktperson-states';

export * from './component/dvb-depot-zuweisen-confirm/dvb-depot-zuweisen-confirm';
export * from './component/dvb-depot-zuweisen-vorgeschlagen/dvb-depot-zuweisen-vorgeschlagen';
export * from './component/dvb-kontaktperson-correspondence-timeline-details/dvb-kontaktperson-correspondence-timeline-details';
export * from './component/dvb-kontaktperson-correspondence/dvb-kontaktperson-correspondence';
export * from './component/dvb-kontaktperson-faktura-zahlung/dvb-kontaktperson-faktura-zahlung';
export * from './component/dvb-kontaktperson-faktura/dvb-kontaktperson-faktura';
export * from './component/dvb-kontaktperson-kontakt/dvb-kontaktperson-kontakt';
export * from './component/dvb-kontaktperson-main/dvb-kontaktperson-main';
export * from './component/dvb-kontaktperson-offene-posten/dvb-kontaktperson-offene-posten';
export * from './component/dvb-kontaktperson-overview/dvb-kontaktperson-overview';
export * from './component/dvb-kontaktperson-rechnungen/dvb-kontaktperson-rechnungen';
export * from './component/dvb-offener-posten-text/dvb-offener-posten-text';
// Add New Component JS Above

angular.module('kitAdmin').config(kontaktpersonConfig);

kontaktpersonConfig.$inject = ['$stateProvider'];

function kontaktpersonConfig($stateProvider: StateProvider): void {
    Object.values(KONTAKTPERSON_STATES).forEach(state => $stateProvider.state(state));
}
