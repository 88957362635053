/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../../config';
import {Ausbildung} from '../models/Ausbildung';

export class AusbildungService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/ausbildungen`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getAll(): angular.IPromise<Ausbildung[]> {
        const matrixParams = {includes: '(children)'};

        return DvbRestUtilAngularJS.getPagedItems(AusbildungService.BASE_URL, Ausbildung, matrixParams)
            .then(response => response.items);
    }

    public getFlat(): angular.IPromise<Ausbildung[]> {
        return DvbRestUtilAngularJS.getPagedItems(`${AusbildungService.BASE_URL}/flat`, Ausbildung)
            .then(response => response.items);
    }

    public saveAusbildungen(ausbildungen: Ausbildung[], deletedAusbildungen: string[]): angular.IHttpPromise<any> {
        DvbRestUtilAngularJS.clearHttpCache();
        const data = {ausbildungen: ausbildungen.map(ausbildung => ausbildung.toRestObject()), deletedAusbildungen};

        return this.$http.post(AusbildungService.BASE_URL, data);
    }
}
