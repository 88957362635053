<div class="dvb-global-search row">
    <div class="col-md-6 col-md-offset-3">
        <h1 data-translate="SEARCH.SUCHERGEBNISSE" data-translate-value-suchtext="{{vm.searchText}}"></h1>

        <div ng-show="vm.results.length > 0">
            <ul class="list-inline normal-abstand-bottom search-filters">
                <li>
                    <span class="label label-default label-radio"
                          ng-model="vm.checkAll"
                          data-ng-change="vm.changeCheckboxAll()"
                          data-translate="COMMON.ALLE"
                          uib-btn-checkbox>
                    </span>
                </li>
                <li ng-repeat="(entity, entityText) in vm.distinctEntities">
                    <span class="label label-default label-radio"
                          ng-change="vm.changeCheckboxEntity()"
                          ng-model="vm.checkedEntities[entity]"
                          ng-bind="entityText"
                          uib-btn-checkbox>
                    </span>
                </li>
            </ul>
        </div>

        <div class="normal-abstand-bottom">
            <h2 data-translate="SEARCH.ARCHIVE_MODE_WAEHLEN"></h2>
            <div class="radio" ng-repeat="mode in vm.archiveSearchModes">
                <label>
                    <input type="radio"
                           name="archivedMode"
                           value="{{mode}}"
                           ng-model="vm.archiveSearchMode"
                           ng-disabled="!vm.kindSelected()"
                           ng-click="vm.changeSearchMode(mode)">
                    <span data-translate="{{'SEARCH.ARCHIVE_MODE.' + mode}}"></span>
                </label>
            </div>
        </div>

        <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>

        <div ng-show="vm.results.length < 1 && !vm.isLoading" data-translate="SEARCH.KEINE_SUCHERGEBNISSE"></div>

        <ul class="search-results">
            <li ng-repeat="row in vm.results | filter:'unused':vm.selectionComparator.bind(vm)">
                <a ng-href="{{vm.getEntryHref(row.entity, row.id)}}">
                    <dvb-search-result-icon entry="row"></dvb-search-result-icon>
                    <span ng-bind="row.text"></span>
                    <span class="badge"
                          ng-if="row.additionalInformation"
                          title="{{row.additionalInformation}}"
                          ng-bind="row.additionalInformation">
                    </span>
                </a>
            </li>
        </ul>
    </div>
</div>
