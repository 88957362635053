<div class="header">
    <div class="row header-row">
        <div class="col-xs-12 header-box">
            <h1>
                <span data-translate="COMMUNICATION.TITLE"></span>
            </h1>
            <ul class="list-inline header-breadcrumb">
                <li ui-sref-active-eq="active">
                    <a ui-sref="base.communication.configuration"
                       data-translate="COMMON.NAV_UEBERSICHT">
                    </a>
                </li>
                <li ui-sref-active-eq="active">
                    <a ui-sref="base.communication.email-template.overview"
                       data-translate="COMMUNICATION.EMAIL_TEMPLATE.TITLE">
                    </a>
                </li>
                <li ui-sref-active-eq="active">
                    <a ui-sref="base.communication.bulk-email-history"
                       data-translate="COMMUNICATION.BULK_EMAILS.HISTORY.TITLE">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
