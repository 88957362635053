/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Favorit} from '@dv/kitadmin/models';
import type {IPersistable, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, isNamedEntityType, NamedEntityType, SEARCH_RESULT_TYPES} from '@dv/shared/code';
import angular from 'angular';
import {tap} from 'rxjs';
import type {SearchService} from 'src/app/search/service/searchService';
import {SubSink} from 'subsink';
import type {FavoritService} from '../../../common/service/rest/favoritService';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        onUpdate: '&',
    },
    template: require('./dvb-dashboard-favoriten.html'),
    controllerAs: 'vm',
};

type FavoritType = { entity: NamedEntityType; title: string };

export class DvbDashboardFavoriten implements angular.IController {
    public static $inject: readonly string[] = ['favoritService', 'searchService'];

    public onUpdate!: (val: { favoriten: readonly Favorit[] }) => void;

    public isLoading: boolean = false;
    public isAddFavoritFormVisible: boolean = false;
    public entities: IPersistable[] = [];
    public favoritTypes: readonly FavoritType[] = [
        {entity: NamedEntityType.KITA, title: 'COMMON.KINDERORT.PLURAL'},
        {entity: NamedEntityType.GRUPPE, title: 'COMMON.GRUPPE.PLURAL'},
        {entity: NamedEntityType.TAGES_ELTERN, title: 'COMMON.TAGES_ELTERN.PLURAL'},
        {entity: NamedEntityType.BETREUUNGS_PERSON, title: 'COMMON.BETREUUNGS_PERSON.PLURAL'},
        {entity: NamedEntityType.FIRMA, title: 'COMMON.FIRMA.PLURAL'},
        {entity: NamedEntityType.FIRMENKONTINGENT, title: 'COMMON.FIRMENKONTINGENT.PLURAL'},
        {entity: NamedEntityType.KIND, title: 'COMMON.KIND.PLURAL'},
        {entity: NamedEntityType.KONTAKTPERSON, title: 'COMMON.KONTAKTPERSON.PLURAL'},
        {entity: NamedEntityType.BENUTZER, title: 'COMMON.BENUTZER.PLURAL'},
        {entity: NamedEntityType.BETREUUNGSFAKTOR_REGEL, title: 'COMMON.BETREUUNGSFAKTOR_REGEL.PLURAL'},
        {entity: NamedEntityType.RECHNUNG, title: 'COMMON.RECHNUNG.PLURAL'},
        {entity: NamedEntityType.TARIF, title: 'COMMON.TARIF.PLURAL'},
        {entity: NamedEntityType.DOKUMENT_LAYOUT, title: 'COMMON.DOKUMENT_LAYOUT.PLURAL'},
    ];
    private _favoriten: readonly Favorit[] = [];

    private sink = new SubSink();

    public constructor(
        private favoritService: FavoritService,
        private searchService: SearchService,
    ) {
    }

    public get favoriten(): readonly Favorit[] {
        return this._favoriten;
    }

    public set favoriten(favoriten: readonly Favorit[]) {
        this._favoriten = favoriten;
        this.entities = favoriten.map(fav => ({id: fav.namedEntity.entityId}));
    }

    public $onInit(): void {
        this.sink.sink = this.favoritService.getAll$().pipe(
            tap(favoriten => {
                this.favoriten = favoriten;
                this.onUpdate({favoriten});
            }),
        ).subscribe();

        this.sink.sink = this.favoritService.isLoading$().pipe(
            tap(isLoading => {
                this.isLoading = isLoading;
            }),
        ).subscribe();
    }

    public $onDestroy(): void {
        this.sink.unsubscribe();
    }

    /**
     * @return returns all types for which an entity was added as favorite
     */
    public getSelectedFavoritTypes(): FavoritType[] {
        return this.favoritTypes
            .filter(type => this.favoriten
                .some(favorit => favorit.namedEntity.dtype === type.entity));
    }

    public onSelect(item: SearchResultEntry): void {
        if (!item) {
            // empty selection
            return;
        }

        const favorit = new Favorit();
        favorit.namedEntity.entityId = item.id;
        const entity = item.entity;
        favorit.namedEntity.dtype = isNamedEntityType(entity) ? entity : null;

        this.favoritService.create$.next(favorit);

        this.isAddFavoritFormVisible = false;
    }

    public getEntityHref(favorit: Favorit): string {
        return this.searchService.getEntityHref(
            SEARCH_RESULT_TYPES.check(checkPresent(favorit.namedEntity.dtype)),
            checkPresent(favorit.namedEntity.entityId));
    }

    public deleteFavorit(favorit: Favorit): void {
        this.favoritService.remove$.next(favorit);
    }
}

componentConfig.controller = DvbDashboardFavoriten;
angular.module('kitAdmin').component('dvbDashboardFavoriten', componentConfig);
