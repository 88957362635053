/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Mandant} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {SupportedLanguage} from '@dv/shared/code';
import {SUPPORTED_LANGUAGES} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        mandant: '<',
        onUpdate: '&',
    },
    template: require('./dvb-mandant-sprachauswahl.html'),
    controllerAs: 'vm',
};

export class DvbMandantSprachauswahl implements angular.IController {
    public static $inject: readonly string[] = ['authStore'];

    public languages: SupportedLanguage[] = SUPPORTED_LANGUAGES.values;
    public mandant!: Mandant;
    public onUpdate!: () => angular.IHttpPromise<void>;

    public canUpdate: boolean = false;

    public constructor(
        private authStore: AuthStore,
    ) {
    }

    public $onChanges(): void {
        const mandantId = this.mandant.id;
        this.canUpdate = !mandantId || this.authStore.hasPermission(PERMISSION.MANDANT.UPDATE + mandantId);
    }

    public toggleLanguage(language: SupportedLanguage): void {
        const langIndex = this.mandant.supportedLanguages.indexOf(language);
        if (langIndex >= 0) {
            this.mandant.supportedLanguages.splice(langIndex, 1);
        } else {
            this.mandant.supportedLanguages.push(language);
        }

        this.onUpdate();
    }
}

componentConfig.controller = DvbMandantSprachauswahl;
angular.module('kitAdmin').component('dvbMandantSprachauswahl', componentConfig);
