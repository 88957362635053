<p>
    <dvb-name-geburtstag-geschlecht person="vm.kontaktperson"></dvb-name-geburtstag-geschlecht>
</p>

<p ng-if="vm.kontaktperson.organisation" ng-bind="vm.kontaktperson.organisation"></p>

<p ng-if="vm.kontaktperson.telefon"
   data-translate="COMMON.TELEFON_MIT_NUM"
   data-translate-value-num="{{vm.kontaktperson.telefon}}"></p>

<p ng-if="vm.kontaktperson.mobile"
   data-translate="COMMON.MOBILE_MIT_NUM"
   data-translate-value-num="{{vm.kontaktperson.mobile}}"></p>

<p ng-if="vm.kontaktperson.geschaeft"
   data-translate="COMMON.GESCHAEFT_MIT_NUM"
   data-translate-value-num="{{vm.kontaktperson.geschaeft}}"></p>

<p ng-if="vm.kontaktperson.email">
    <a ng-href="{{'mailto:' + vm.kontaktperson.email}}" ng-bind="vm.kontaktperson.email"></a>
</p>
