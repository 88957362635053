<div class="dvb-einzahlungsscheine" dvb-accordions>
    <h2 data-translate="KINDERORT.EINZAHLUNGSSCHEINE"></h2>

    <p ng-if="vm.einzahlungsscheine.length === 0"
       class="normal-abstand-bottom"
       data-translate="FAKTURA.KEINE_EINZAHLUNGSSCHEINE">
    </p>

    <div ng-repeat="einzahlungsschein in vm.einzahlungsscheine | orderBy: 'name'"
         ng-init="workingCopy = (einzahlungsschein | copy)">
        <dvb-accordion enable-edit="true"
                       enable-delete="true"
                       show-content-disabled="true"
                       entity-name="'COMMON.EINZAHLUNGSSCHEIN' | translate"
                       on-submit="vm.onUpdate(workingCopy, einzahlungsschein, context, form)"
                       on-cancel="vm.onCancelUpdate(workingCopy, einzahlungsschein)"
                       on-delete="vm.onDelete(einzahlungsschein)"
                       is-first="$first"
                       dvb-auth
                       require-permission="kita:betreuung_admin:*"
                       on-unauthorised-disable>
            <panel-header class="full-width-block">
                <div class="row">
                    <div class="col-xs-12">
                        <p ng-bind="einzahlungsschein.name"></p>
                    </div>
                </div>
            </panel-header>
            <panel-content>
                <div class="row">
                    <div class="col-md-6">
                        <p ng-bind="einzahlungsschein.kontoNummer"></p>
                    </div>
                    <div class="col-md-6">
                        <p ng-bind="einzahlungsschein.auslieferungsnummer"></p>
                    </div>
                </div>

                <p data-translate="{{'COMMON.ADRESSE_' + einzahlungsschein.contextualAdresse.context}}"
                   ng-if="einzahlungsschein.contextualAdresse.context !== 'CUSTOM'">
                </p>

                <div class="normal-abstand-top normal-abstand-bottom"
                     ng-if="einzahlungsschein.contextualAdresse.context === 'CUSTOM'">
                    <dvlib-adresse-display [adresse]="einzahlungsschein.contextualAdresse.adresse"
                                           [display-type]="'short'">
                    </dvlib-adresse-display>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <p ng-bind="einzahlungsschein.bankName"></p>
                    </div>
                    <div class="col-md-6">
                        <p ng-bind="einzahlungsschein.bankAdresszeile"></p>
                    </div>
                </div>

                <h3 data-translate="FAKTURA.DRUCKPOSITIONIERUNG" class="normal-abstand-top"></h3>
                <div class="row small-abstand-top">
                    <div class="col-md-6">
                        <span
                            ng-bind="('FAKTURA.ABSTAND_LINKS' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: einzahlungsschein.offsetLeft})">
                        </span>
                    </div>
                    <div class="col-md-6">
                        <span
                            ng-bind="('FAKTURA.ABSTAND_OBEN' | translate) + ': ' + ('FAKTURA.MM_VALUE' | translate:{value: einzahlungsschein.offsetTop})">
                        </span>
                    </div>
                    <div class="col-md-12 small-abstand-top" ng-if="einzahlungsschein.einzahlungsscheinNotOnPageOne">
                        <span data-translate="FAKTURA.EINZAHLUNGSSCHEIN_PAGE_CONFIRM"></span>
                    </div>
                </div>
            </panel-content>
            <edit-content>
                <dvb-einzahlungsschein-form einzahlungsschein="workingCopy">
                </dvb-einzahlungsschein-form>
            </edit-content>
        </dvb-accordion>
    </div>

    <div class="big-abstand-top" dvb-auth require-permission="kita:betreuung_admin:*">
        <button type="button"
                class="btn btn-primary"
                ng-show="!vm.showNewForm"
                ng-click="vm.initNewForm()">
            <span data-translate="FAKTURA.NEW_EINZAHLUNGSSCHEIN"></span>
        </button>

        <div ng-if="vm.showNewForm">
            <h2 data-translate="FAKTURA.NEW_EINZAHLUNGSSCHEIN"></h2>

            <form name="einzahlungsscheinForm"
                  ng-submit="vm.createEinzahlungsschein(einzahlungsscheinForm)"
                  novalidate
                  autocomplete="off">

                <dvb-einzahlungsschein-form einzahlungsschein="vm.einzahlungsschein"></dvb-einzahlungsschein-form>

                <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                           on-cancel="vm.showNewForm = false">
                </dvb-submit-cancel-buttons>
            </form>
        </div>
    </div>
</div>
