<div class="dvb-adresse-form">
    <ng-form name="adresseForm">
        <div class="row">
            <div class="col-md-9">
                <input type="text"
                       class="form-control"
                       dvb-capitalize-first-letter
                       ng-change="vm.updateModel()"
                       ng-attr-placeholder="{{'COMMON.STRASSE' | translate}}"
                       uib-tooltip="{{'COMMON.STRASSE' | translate}}"
                       tooltip-placement="top-left"
                       ng-required="vm.isAddressRequired"
                       name="strasse"
                       maxlength="255"
                       ng-model-options="{debounce: 300}"
                       ng-model="vm.adresse.strasse"
                       autocomplete="address-line1">
            </div>
            <div class="col-md-3">
                <input type="text"
                       class="form-control"
                       ng-attr-placeholder="{{'COMMON.NUMMER' | translate}}"
                       uib-tooltip="{{'COMMON.NUMMER' | translate}}"
                       tooltip-placement="top-left"
                       name="nummer"
                       maxlength="100"
                       ng-change="vm.updateModel()"
                       ng-model-options="{debounce: 300}"
                       ng-model="vm.adresse.hausnummer"
                       autocomplete="address-line2">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <input type="text"
                       class="form-control"
                       ng-attr-placeholder="{{'COMMON.POSTFACH' | translate}}"
                       uib-tooltip="{{'COMMON.POSTFACH' | translate}}"
                       tooltip-placement="top-left"
                       name="postfach"
                       maxlength="100"
                       ng-change="vm.updateModel()"
                       ng-model-options="{debounce: 300}"
                       ng-model="vm.adresse.postfach"
                       autocomplete="address-line3">
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <input type="text"
                       class="form-control"
                       ng-attr-placeholder="{{'COMMON.PLZ' | translate}}"
                       uib-tooltip="{{'COMMON.PLZ' | translate}}"
                       tooltip-placement="top-left"
                       name="plz"
                       ng-required="vm.isAddressRequired"
                       maxlength="100"
                       ng-change="vm.updateModel()"
                       ng-model-options="{debounce: 100}"
                       ng-model="vm.adresse.plz"
                       autocomplete="postal-code">
            </div>
            <div class="col-md-9">
                <input type="text"
                       class="form-control"
                       dvb-capitalize-first-letter
                       ng-attr-placeholder="{{'COMMON.ORT' | translate}}"
                       uib-tooltip="{{'COMMON.ORT' | translate}}"
                       tooltip-placement="top-left"
                       name="ort"
                       ng-required="vm.isAddressRequired"
                       maxlength="100"
                       ng-change="vm.updateModel()"
                       ng-model-options="{debounce: 300}"
                       ng-model="vm.adresse.ort"
                       autocomplete="address-level2">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 normal-abstand-bottom">
                <p ng-bind="vm.adresse.getDisplayGemeinde(vm.$translate)"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dvb-iso-locale ng-model="vm.adresse.land"
                                ng-change="vm.updateModel()"
                                type="COUNTRY"
                                ng-required="vm.isAddressRequired"
                                ng-attr-placeholder="{{'COMMON.LAND' | translate}}">
                </dvb-iso-locale>
            </div>
        </div>
    </ng-form>
</div>
