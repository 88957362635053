/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Gueltigkeit} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type {PopoverFilterOption} from '../../filter/popover/PopoverFilterOption';
import type {PopoverFilterPropertyGueltigkeit} from '../../filter/popover/PopoverFilterPropertyGueltigkeit';

export class ExternalAnmeldungFilter {
    public constructor(
        public showAssigned: boolean = false,
        public showUnassigned: boolean = true,
        public erfassungsDatumFilter: PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>,
        public eintrittsDatumFilter: PopoverFilterOption<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>,
    ) {
    }

    public toRestObject(): Record<string, unknown> {

        const erfassung = this.erfassungsDatumFilter.selected ?
            this.erfassungsDatumFilter.popoverModel.gueltigkeit :
            null;
        const eintritt = this.eintrittsDatumFilter.selected ?
            this.eintrittsDatumFilter.popoverModel.gueltigkeit :
            null;

        return {
            showAssigned: this.showAssigned,
            showUnassigned: this.showUnassigned,
            erfassungGueltigAb: DvbRestUtil.momentToLocalDate(erfassung?.gueltigAb),
            erfassungGueltigBis: DvbRestUtil.momentToLocalDate(erfassung?.gueltigBis),
            eintrittGueltigAb: DvbRestUtil.momentToLocalDate(eintritt?.gueltigAb),
            eintrittGueltigBis: DvbRestUtil.momentToLocalDate(eintritt?.gueltigBis),
        };
    }
}
