<div class="dvb-dokumentlayout-erstellen">
    <div class="row dvb-form">
        <div class="col-md-6 col-md-offset-3">
            <h1 data-translate="COMMON.DOKUMENT_LAYOUT.ERSTELLEN"></h1>

            <form name="dokumentLayoutForm"
                  ng-submit="vm.submit(dokumentLayoutForm)"
                  novalidate>

                <dvb-dokumentlayout-form dokument-layout="vm.dokumentLayout"></dvb-dokumentlayout-form>

                <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                           on-cancel="vm.onCancel()">
                </dvb-submit-cancel-buttons>
            </form>
        </div>
    </div>
</div>
