/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Favorit} from '@dv/kitadmin/models';
import angular from 'angular';
import type {Benutzer} from '../../../benutzer/model/Benutzer';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        benutzer: '<',
    },
    template: require('./dvb-dashboard.html'),
    controllerAs: 'vm',
};

export class DvbDashboard implements angular.IController {
    public static $inject: readonly string[] = [];

    public benutzer!: Benutzer;
    public loadTasks: boolean = false;

    public favoriten: Favorit[] = [];

    public favoritenUpdated(favoriten: Favorit[]): void {
        this.loadTasks = true;
        this.favoriten = favoriten;
    }
}

componentConfig.controller = DvbDashboard;
angular.module('kitAdmin').component('dvbDashboard', componentConfig);
