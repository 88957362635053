<div class="row dvb-steuerbescheinigung-management">
    <div class="col-md-7">

        <div class="header">
            <div class="row header-row">
                <div class="col-xs-12 header-box">
                    <h1 data-translate="COMMUNICATION.STEUERBESCHEINIGUNG.TITLE"
                        data-translate-value-date="{{vm.gueltigAb | amDateFormat:'YYYY'}}"></h1>
                </div>
            </div>
        </div>

        <dvb-communication-post-recipients
            ng-if="vm.steuerbescheinigungState.emailMissing.length > 0"
            kontaktpersonen="vm.steuerbescheinigungState.emailMissing"
            on-submit="vm.download(list)"
            error="'ERRORS.ERR_EMAIL_FEHLENDE_KONTAKTPERSON_EMAIL_SHORT'">
        </dvb-communication-post-recipients>

        <dvb-communication-ready-to-send
            class="full-width-block"
            ng-class="{'big-abstand-top': vm.steuerbescheinigungState.emailMissing.length > 0}"
            ng-if="vm.steuerbescheinigungState.readyToSend.length > 0"
            kontaktpersonen="vm.steuerbescheinigungState.readyToSend"
            on-submit="vm.release(context, selected)"
            all-selected="false">
        </dvb-communication-ready-to-send>

        <dvb-communication-sent
            class="full-width-block"
            ng-class="{'big-abstand-top': vm.steuerbescheinigungState.emailMissing.length > 0
                || vm.steuerbescheinigungState.readyToSend.length > 0}"
            ng-if="vm.steuerbescheinigungState.withExistingEmails.length > 0"
            kontaktperson-last-sent-list="vm.steuerbescheinigungState.withExistingEmails"
            on-resend="vm.release(context, selected)">
        </dvb-communication-sent>

        <ul class="list-inline normal-abstand-top">
            <li>
                <button type="button"
                        class="btn btn-link cancel-button"
                        data-translate="COMMON.ABBRECHEN"
                        ng-click="vm.goBack()">
                </button>
            </li>
        </ul>

    </div>
</div>
