/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';

angular.module('kitAdmin').filter('dvbLimitedMonth', dvbLimitedMonth);

function dvbLimitedMonth(): (input?: ILimited) => string {
    return (input): string => {
        if (!input?.gueltigAb || !input.gueltigBis) {
            return '';
        }

        const {gueltigAb, gueltigBis} = input;

        if (limitedToOneMonth(gueltigAb, gueltigBis)) {
            if (isSingleDay(gueltigAb, gueltigBis)) {
                return `${gueltigAb.format('D. MMM YY')}`;
            }

            return isFullMonth(gueltigAb, gueltigBis) ?
                `${gueltigAb.format('MMM YY')}` :
                `${gueltigAb.date()}. - ${gueltigBis.date()}. ${gueltigAb.format('MMM YY')}`;
        }

        return `${gueltigAb.format('D.M.YY')} - ${gueltigBis.format('D.M.YY')}`;
    };
}

function limitedToOneMonth(gueltigAb: moment.Moment, gueltigBis: moment.Moment): boolean {
    return gueltigAb.isSame(gueltigBis, 'month');
}

function isSingleDay(gueltigAb: moment.Moment, gueltigBis: moment.Moment): boolean {
    return gueltigAb.isSame(gueltigBis, 'day');
}

function isFullMonth(gueltigAb: moment.Moment, gueltigBis: moment.Moment): boolean {
    return DvbDateUtil.startOfMonth(moment(gueltigAb)).isSame(gueltigAb, 'day') &&
        DvbDateUtil.endOfMonth(moment(gueltigBis)).isSame(gueltigBis, 'day');
}
