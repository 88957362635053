/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isPresent} from '@dv/shared/code';
import type angular from 'angular';

export class WizardStep {

    public constructor(
        public iconClass: string,
        public title: string,
        public active: boolean = false,
        public error: boolean = false,
        public complete: boolean = false,
        public form?: angular.IFormController,
    ) {
    }

    public validate(): boolean {
        this.complete = isPresent(this.form) && this.form.$valid;
        this.error = !isPresent(this.form) || !this.form.$valid;
        this.form?.$setSubmitted();

        return this.error;
    }
}
