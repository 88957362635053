/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeEntity, Kind} from '@dv/kitadmin/models';
import {AbstractPagesController} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import type {Persisted, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, TypeUtil} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {FilterOption} from '../../../filter/shared/FilterOption';
import {FilterOptionHelper} from '../../../filter/shared/FilterOptionHelper';
import type {MandantSearchFilter} from '../../../search/model/MandantSearchFilter';
import {ANY_MANDANT, mandantIdFilter} from '../../../search/model/MandantSearchFilter';
import type {AbstractKibonFilter} from '../../models/AbstractKibonFilter';
import type {KibonExchangeEntityAction, KibonExchangeEntityButtonAction} from '../../models/KibonExchangeEntityAction';
import type {
    KibonEntityMatchingDialogModel,
} from '../kibon-entity-matching-dialog/kibon-entity-matching-dialog.component';
import {
    KibonEntityMatchingDialogComponent,
} from '../kibon-entity-matching-dialog/kibon-entity-matching-dialog.component';
import type {KibonExchangeActionHandler} from './KibonEntityActionHandler';

export type TranslationKey =
    'pageHeader' |
    'statePrefix' |
    'stateTooltipPrefix' |
    'kibonExportAm' |
    'assignConfirmationHeader' |
    'assignEntityDataHeader' |
    'entityNameMF' |
    'matchingHeader' |
    'matchingText';

export type TranslationKeyMap = { [k in TranslationKey]: string };

const componentConfig: angular.IComponentOptions = {
    transclude: {
        rightColumn: '?rightColumn',
    },
    bindings: {
        translationKeys: '<',
        actionHandler: '<',
        actions: '<',
        filter: '<',
        states: '<',
        onEntityChanged: '&?',
        pageController: '&?',
    },
    template: require('./dvb-kibon-entity-matching-overview.html'),
    controllerAs: 'vm',
};

export class DvbKibonEntityMatchingOverview<T extends KibonExchangeEntity>
    extends AbstractPagesController<T> implements angular.IController {

    public static override $inject: readonly string[] = [
        '$q',
        'authStore',
        'kindService',
        'dialogService',
        '$transclude',
    ];

    public translationKeys!: TranslationKeyMap;
    public actionHandler!: KibonExchangeActionHandler<T, unknown>;
    public actions: KibonExchangeEntityAction<T>[] = [];
    public filter!: AbstractKibonFilter;
    public states: unknown[] = [];
    public onEntityChanged?: (params: { entity?: KibonExchangeEntity }) => unknown;
    public pageController?: (params: { pageController?: AbstractPagesController<T> }) => unknown;

    public collapsed: boolean = true;
    public filterOptionHelper: FilterOptionHelper = new FilterOptionHelper(this.reloadItems.bind(this));
    public mandantFilter?: MandantSearchFilter = undefined;
    public mandantAllFilter: MandantSearchFilter = ANY_MANDANT;
    public isMandantAdmin: boolean = false;

    public allowKinderFilter: boolean = false;

    private timeout?: angular.IDeferred<undefined> = undefined;

    private lastSelected?: KibonExchangeEntity;

    public constructor(
        private readonly $q: angular.IQService,
        private readonly authStore: AuthStore,
        private readonly kindService: KindService,
        private readonly dialogService: DialogService,
        private readonly $transclude: angular.ITranscludeFunction,
    ) {
        super();
        if (this.authStore.hasRole(UserRole.MANDANT_ADMIN)) {
            this.isMandantAdmin = true;
            this.mandantFilter = ANY_MANDANT;
        }
    }

    public setSelected(selected: boolean, entity: KibonExchangeEntity): void {
        if (!TypeUtil.isFunction(this.onEntityChanged)) {
            return;
        }

        if (!selected) {
            if (this.lastSelected === entity) {
                this.onEntityChanged({entity: undefined});
            }

            return;
        }

        this.lastSelected = entity;
        this.onEntityChanged({entity});
    }

    public isSlotFilled(name: string): boolean {
        return this.$transclude.isSlotFilled(name);
    }

    public $onChanges?(onChangesObj: IOnChangesObject): void {
        if (onChangesObj.filter) {
            this.allowKinderFilter = Array.isArray(onChangesObj.filter.currentValue.kinder);
        }

        if (!onChangesObj.actionHandler) {
            return;
        }
    }

    public override $onInit(): void {
        super.$onInit();

        if (TypeUtil.isFunction(this.pageController)) {
            // ugly: the parent component needs to be able to call reloadItems()
            this.pageController({pageController: this});
        }
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        this.setPagionationValues(this.filter);

        const config = {timeout: this.timeout.promise};

        this.actionHandler.getFiltered(config)
            .then(pageContainer => {
                this.pageContainer = pageContainer;
                this.isLoading = false;
            })
            .catch(err => {
                if (!DvbRestUtil.isRequestCancelled(err)) {
                    this.isLoading = false;
                }
            });
    }

    public onMandantFilterClosed(mandantFilter: FilterOption): void {
        this.filterOptionHelper.onClose(mandantFilter);
        this.filter.mandant = undefined;
    }

    public onMandantChanged(): void {
        this.mandantFilter = this.filter.mandant?.selected ?
            mandantIdFilter(checkPresent(this.filter.mandant.id)) :
            ANY_MANDANT;

        this.reloadItems();
    }

    public onReset(): void {
        this.filter.reset();
        this.reloadItems();
    }

    public assign(entity: T, item: SearchResultEntry): void {
        this.kindService.get(item.id, {includes: '(kontakte.fields(kontaktperson, relationship))'})
            .then(kind => this.showAssignConfirmation(entity, kind));
    }

    public confirmAction(entity: T, action: KibonExchangeEntityButtonAction<T>): void {
        const confirm = (): Observable<unknown> => from(action.action(entity))
            .pipe(tap(() => this.reloadItems()));

        if (action.confirmationRequired) {
            const title: string = checkPresent(action.translationKeys(entity).confirmation);
            this.dialogService.openConfirmDialog({title, confirm});

            return;
        }

        confirm().subscribe();
    }

    private showAssignConfirmation(entity: T, kind: Persisted<Kind>): void {
        if (!TypeUtil.isFunction(this.actionHandler.doAssign) || !TypeUtil.isFunction(this.actionHandler.onAssigned)) {
            return;
        }

        const dialogModel: KibonEntityMatchingDialogModel = {
            entity,
            kind,
            header: this.translationKeys.assignConfirmationHeader,
            entityDataHeader: this.translationKeys.assignEntityDataHeader,
            confirm: () => this.doAssign$(entity, kind),
        };
        this.dialogService.openDialog(KibonEntityMatchingDialogComponent, dialogModel);
    }

    private doAssign$(entity: T, kind: Persisted<Kind>): Observable<unknown> {
        return from(this.actionHandler.doAssign!(entity, kind)).pipe(
            take(1),
            tap(() => this.reloadItems()),
            tap((value: unknown) => this.actionHandler.onAssigned!(value)));
    }
}

componentConfig.controller = DvbKibonEntityMatchingOverview;
angular.module('kitAdmin').component('dvbKibonEntityMatchingOverview', componentConfig);
