/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbUtil, EntitySelection} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktpersonen: '<',
        onSubmit: '&',
        error: '<',
    },
    template: require('./dvb-communication-post-recipients.html'),
    controllerAs: 'vm',
};

export class DvbCommunicationPostRecipients extends EntitySelection<Kontaktperson> implements angular.IController {

    public static override $inject: readonly string[] = ['$state'];

    public kontaktpersonen!: Persisted<Kontaktperson>[];
    public onSubmit!: (val: { list: Kontaktperson[] }) => void;
    public error!: string;

    public isLoading: boolean = false;

    public constructor(private $state: StateService) {
        super();
    }

    public reload(): void {
        this.isLoading = true;
        this.$state.reload().then(() => {
            this.isLoading = false;
        });
    }

    public submit(): void {
        const selected = this.getSelected(this.kontaktpersonen);
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        this.onSubmit({
            list: this.getSelected(this.kontaktpersonen),
        });
    }
}

componentConfig.controller = DvbCommunicationPostRecipients;
angular.module('kitAdmin').component('dvbCommunicationPostRecipients', componentConfig);
