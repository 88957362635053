/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {PlaetzeByWeek, Wochenplan, ZeitraumFeld} from '@dv/kitadmin/models';
import {WochenKapazitaet, ZeitraumUtil} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {checkPresent, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {WochenplanService} from '../../../common/service/rest/wochenplanService';
import type {KapazitaetService} from '../../service/kapazitaetService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenKapazitaet: '<',
        wochenplan: '<',
        onWochenKapazitaetChange: '&',
        // Valid values are 'READ-ONLY-DATE', 'NO-DATE', 'EDITABLE-DATE', see displayMode
        displayMode: '@',
    },
    template: require('./dvb-fraktion-kapazitaet-form.html'),
    controllerAs: 'vm',
};

const errMaxKleinerAlsAnzahl = 'ERRORS.ERR_MAX_KLEINER_ALS_ANZAHL';
const errAnzEmptyMaxNot = 'ERRORS.ERR_ANZ_EMPTY_MAX_NOT';
const errMaxPlaetzeCalculationFailed = 'ERRORS.ERR_MAX_PLAETZE_CALCULATION_FAILED';
const errMaxPlaetzeValueUngueltig = 'ERRORS.ERR_MAX_PLAETZE_VALUE_UNGUELTIG';
const errAnzPlaetzeCalcuationFailed = 'ERRORS.ERR_ANZ_PLAETZE_CALCULATION_FAILED';
const errAnzPlaetzeValueUngueltig = 'ERRORS.ERR_ANZ_PLAETZE_VALUE_UNGUELTIG';

export class DvbFraktionKapazitaetForm implements angular.IController, angular.IOnChanges {
    public static $inject: readonly string[] = ['kapazitaetService', 'errorService', 'wochenplanService'];

    public wochenKapazitaet!: WochenKapazitaet;
    public wochenplan!: Wochenplan;
    public onWochenKapazitaetChange!: (data: { wochenKapazitaet: WochenKapazitaet }) => void;
    public displayMode!: 'NO-DATE' | 'READ-ONLY-DATE' | 'EDITABLE-DATE';

    public enabledDays: DayOfWeek[] = [];

    public backupAnzahlPlaetze: number | null = null;
    public hasMaximalePlaetze: boolean = false;
    public backupMaxPlaetze: number | null = null;
    public zeitraumFelder: ZeitraumFeld[] = [];
    public zeitraumFelderMax: ZeitraumFeld[] = [];

    public constructor(
            private kapazitaetService: KapazitaetService,
            private errorService: ErrorService,
            private wochenplanService: WochenplanService,
    ) {
    }

    private static isFloatingPoint(anzahl?: number | null): boolean {
        return anzahl !== null && anzahl !== undefined && anzahl % 1 !== 0;
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.wochenKapazitaet && !changes.wochenKapazitaet.currentValue) {
            this.wochenKapazitaet = new WochenKapazitaet();
        }

        if (!changes.wochenplan) {
            return;
        }

        if (!changes.wochenplan.isFirstChange()) {
            this.wochenKapazitaet = new WochenKapazitaet();
        }

        this.initWochenplan();
    }

    public initWochenplan(): void {
        this.zeitraumFelder = angular.copy(this.wochenplan.zeitraumFelder);
        this.zeitraumFelderMax = angular.copy(this.wochenplan.zeitraumFelder);

        const zeitraumFelderPlaetze = angular.copy(this.wochenplan.zeitraumFelder);
        this.kapazitaetService.setWochenKapazitaetToZeitraumFelder(zeitraumFelderPlaetze, this.wochenKapazitaet,
                false);
        this.zeitraumFelder = zeitraumFelderPlaetze;

        const zeitraumFelderMaxPlaetze = angular.copy(this.wochenplan.zeitraumFelder);
        this.kapazitaetService.setWochenKapazitaetToZeitraumFelder(zeitraumFelderMaxPlaetze, this.wochenKapazitaet,
                true);
        this.zeitraumFelderMax = zeitraumFelderMaxPlaetze;

        this.hasMaximalePlaetze = !this.kapazitaetService.hasEqualZeitraumFeldValues(zeitraumFelderPlaetze,
                zeitraumFelderMaxPlaetze);

        this.enabledDays = ZeitraumUtil.getWeekDaysFromWochenplan(this.wochenplan);

        this.updateZeitraumFelderErrorFlag();
    }

    /**
     * ueberprueft ob die value des Textfeldes geaendert hat.
     * wenn isMax wird die value des 'Max. Anzahl Plaetze' ueberprueft und sonst 'Anzahl Plaetze'
     * backupMaxPlaetze & backupAnzahlPlaetze werden 'on Focus' des Textfeldes gespeichert
     */
    public hasValueChanged(isMax: boolean): boolean {
        return (isMax && this.backupMaxPlaetze !== this.wochenKapazitaet.maxPlaetze) ||
                (!isMax && this.backupAnzahlPlaetze !== this.wochenKapazitaet.anzahlPlaetze);
    }

    public resetZeitraumFelder(zeitraumFelder: ZeitraumFeld[]): void {
        zeitraumFelder.forEach(zf => this.clearZeitraumFeld(zf));

        if (this.isMaxAnzahlZeitraumFelder(zeitraumFelder)) {
            this.errorService.clearErrorByMsgKey(errMaxKleinerAlsAnzahl);
            this.errorService.clearErrorByMsgKey(errAnzEmptyMaxNot);

            this.errorService.clearErrorByMsgKey(errMaxPlaetzeCalculationFailed);
            this.errorService.clearErrorByMsgKey(errMaxPlaetzeValueUngueltig);
        } else {
            this.errorService.clearErrorByMsgKey(errAnzPlaetzeCalcuationFailed);
            this.errorService.clearErrorByMsgKey(errAnzPlaetzeValueUngueltig);
        }
    }

    public resetMaxPlaetze(): void {
        this.wochenKapazitaet.maxPlaetze = null;
        this.wochenKapazitaet.kapazitaeten.forEach(k => {
            k.maxPlaetze = k.plaetze;
        });
        this.resetZeitraumFelder(this.zeitraumFelderMax);
    }

    public updateZeitraumFelderErrorFlag(): void {
        const zeitraumFelder = this.zeitraumFelder.concat(this.zeitraumFelderMax);
        let hasAnyError = false;
        zeitraumFelder.forEach(zf => {
            zf.hasError = this.kapazitaetService.hasError(zf);
            hasAnyError = hasAnyError || zf.hasError;
        });

        this.wochenKapazitaet.hasError = hasAnyError;

        if (TypeUtil.isFunction(this.onWochenKapazitaetChange)) {
            this.updateWochenKapazitaet();
            this.onWochenKapazitaetChange({wochenKapazitaet: this.wochenKapazitaet});
        }
    }

    public updateWochenKapazitaet(): void {
        const maxPlaetzeValues = this.hasMaximalePlaetze ? this.zeitraumFelderMax : this.zeitraumFelder;
        let kapazitaeten = this.kapazitaetService.createKapazitaetenFromZeitraumFelder(true, maxPlaetzeValues);
        kapazitaeten = this.kapazitaetService.createOrUpdateKapazitaeten(false, this.zeitraumFelder, kapazitaeten);
        this.wochenKapazitaet.kapazitaeten = kapazitaeten;
    }

    /**
     * ueberprueft, ob die zeitraumfelder-values valid sind
     * wenn isMax werden die 'Max-Zeitraum-Felder' ueberprueft und sonst die 'Anz-Zeitraum-Felder'
     */
    public validateZeitraumFelderValue(zeitraumFelder: ZeitraumFeld[]): boolean {
        let hasAllValid = true;

        zeitraumFelder.forEach(zeitraumFeld => {
            const isValid = this.kapazitaetService.isValidKapazitaetValue(zeitraumFeld.value);

            if (!isValid) {
                hasAllValid = false;
            }

            this.kapazitaetService.setKapazitaetValueError(!isValid, zeitraumFeld);
        });

        const errorMsg = zeitraumFelder === this.zeitraumFelderMax ?
                'ERRORS.ERR_MAX_PLAETZE_VALUE_UNGUELTIG' :
                'ERRORS.ERR_ANZ_PLAETZE_VALUE_UNGUELTIG';
        this.errorService.handleValidationError(hasAllValid, errorMsg);

        return hasAllValid;
    }

    /**
     * Validierung fuer ZeitraumFelder
     * Bei dieser Validierung koennen jeweils nur die Zeitraumfelder der Max-Platze Errors haben
     */
    public isAnzKleinerAlsMaxAndNotEmpty(): boolean {
        if (!this.hasMaximalePlaetze) {
            this.errorService.clearErrorByMsgKey(errMaxKleinerAlsAnzahl);
            this.errorService.clearErrorByMsgKey(errAnzEmptyMaxNot);

            return true;
        }

        const validation = this.kapazitaetService.validateAnzKleinerAlsMaxAndNotEmpty(this.zeitraumFelder,
                this.zeitraumFelderMax);
        this.errorService.handleValidationError(!validation.maxKleinerAlsAnzError, errMaxKleinerAlsAnzahl);
        this.errorService.handleValidationError(!validation.anzEmptyMaxNotError, errAnzEmptyMaxNot);

        return !validation.maxKleinerAlsAnzError || !validation.anzEmptyMaxNotError;
    }

    public blurOnEnter($event: KeyboardEvent): void {
        // Enter key
        if ($event.key === 'enter') {
            checkPresent($event.target as HTMLInputElement).blur();
        }
    }

    /**
     * onBlur des Textfeldes ('Max. Anzahl Plaetze' oder 'Anzahl Plaetze') wird die anzahl in jedes Zeitraum-
     * Feld des entsprechenden Wochenplanes gesetzt.
     */
    public setAnzahlToZeitraumFelder(anzahl: number | null | undefined, zeitraumFelder: ZeitraumFeld[]): void {

        // Wenn der Wert der Anzahl nicht geaendert hat und es eine Komma-Zahl ist, soll der Wert nicht in die
        // Textfelder uebernommen werden, da dies zur Folge haben wuerde, dass in den Zeitraum-Felder eine
        // Kommazahl stehen wuerde, obwohl der User vermutlich versehentlich ein Blur-Event ausgeloest hat.
        const isMax = this.isMaxAnzahlZeitraumFelder(zeitraumFelder);

        if (!this.hasValueChanged(isMax) && DvbFraktionKapazitaetForm.isFloatingPoint(anzahl)) {
            return;
        }

        if (anzahl === null || anzahl === undefined) {
            this.resetZeitraumFelder(zeitraumFelder);

            if (!isMax) {
                // Wann anzahl Plaetze geloescht werden, dann auch max Plaetze loeschen
                this.resetMaxPlaetze();
            }

            this.updateZeitraumFelderErrorFlag();

            return;
        }

        // Wenn der Wert der Anzahl keine Kommazahl ist und nicht geandert hat, soll dieser trotzdem in den
        // Zeitraumfelder uebernommen werden, weil die Mittage leer sein koennten und wieder gefuellt werden
        // sollten.
        zeitraumFelder.forEach(zeitraumFeld => {
            zeitraumFeld.value = String(anzahl);
            this.kapazitaetService.setInvalidPlaetzeByDay(false, zeitraumFeld);
        });

        if (this.validateZeitraumFelderValue(zeitraumFelder)) {
            // TODO immer validieren, oder nur wenn alle Values gueltig sind?
            this.isAnzKleinerAlsMaxAndNotEmpty();
        }

        this.updateZeitraumFelderErrorFlag();
    }

    /**
     * wenn die checkbox auf false gesetzt wird, wird die 'Max. Anzahl Plaetze' geleert -> reset
     */
    public onToggleHasMaxPlaetze(): void {
        if (!this.hasMaximalePlaetze) {
            this.resetMaxPlaetze();
        }
    }

    /**
     * nach dem Service-Aufruf, nachdem das plaetze-Array (Plaetze pro Tag) bereitsteht,
     * wird die GruppenWochenbelegung berechnet und bei einer fehlerhaften Berechnung (-1) entsprechend die
     * Errors gesetzt
     */
    public calculateGruppenWochenbelegung(zeitraumFelder: ZeitraumFeld[], plaetzeByWeek: PlaetzeByWeek): void {
        const hasError = plaetzeByWeek.hasError();

        zeitraumFelder.forEach(zf => {
            this.kapazitaetService.setInvalidPlaetzeByDay(plaetzeByWeek.hasErrorAtDayOfWeek(zf.dayOfWeek), zf);
        });

        this.updatePlaetzeCalculationValidationMessage(zeitraumFelder, !hasError);

        if (!hasError) {
            const hundred = 100;
            const precision = 3;

            const plaetzeProWoche = plaetzeByWeek.totalPlaetze === 0 ?
                    null :
                    parseFloat((checkPresent(plaetzeByWeek.totalPlaetze) / hundred).toPrecision(precision));

            this.setPlaetzeProWocheForZeitraumFelder(zeitraumFelder, plaetzeProWoche);
        }

        this.updateZeitraumFelderErrorFlag();
    }

    public isAnzahlZeitraumFelder(zeitraumFelder: ZeitraumFeld[]): boolean {
        return zeitraumFelder === this.zeitraumFelder;
    }

    public isMaxAnzahlZeitraumFelder(zeitraumFelder: ZeitraumFeld[]): boolean {
        return zeitraumFelder === this.zeitraumFelderMax;
    }

    public setPlaetzeProWocheForZeitraumFelder(zeitraumFelder: ZeitraumFeld[], plaetzeProWoche: number | null): void {
        if (this.isAnzahlZeitraumFelder(zeitraumFelder)) {
            this.wochenKapazitaet.anzahlPlaetze = plaetzeProWoche;
        }
        if (this.isMaxAnzahlZeitraumFelder(zeitraumFelder)) {
            this.wochenKapazitaet.maxPlaetze = plaetzeProWoche;
        }
    }

    public updatePlaetzeCalculationValidationMessage(zeitraumFelder: ZeitraumFeld[], isValid: boolean): void {
        if (this.isAnzahlZeitraumFelder(zeitraumFelder)) {
            this.errorService.handleValidationError(isValid, errAnzPlaetzeCalcuationFailed);
        }
        if (this.isMaxAnzahlZeitraumFelder(zeitraumFelder)) {
            this.errorService.handleValidationError(isValid, errMaxPlaetzeCalculationFailed);
        }
    }

    public deactivateMaxZeitraumFeld(zeitraumFeld: ZeitraumFeld): void {
        this.zeitraumFelderMax
                .filter(zeitraumFeldMax => zeitraumFeld.equals(zeitraumFeldMax))
                .forEach(zf => this.clearZeitraumFeld(zf));
    }

    /**
     * Wenn ein ZeitraumFeld bearbeitet wird, werden die entsprechenden Errors geloescht, die Kapazitaeten
     * aktualisiert, die Kapazitaeten werden erneut validiert (validateAnzKleinerAlsMaxAndNotEmpty) und die
     * GruppenWochenbelegung berechnet/validiert
     */
    public onZeitraumFeldEdited(zeitraumFeld: ZeitraumFeld): void {
        const isMax = this.zeitraumFelderMax.includes(zeitraumFeld);
        const zeitraumFelder = isMax ? this.zeitraumFelderMax : this.zeitraumFelder;
        if (!this.validateZeitraumFelderValue(zeitraumFelder)) {
            // ungueltiger Wert
            this.updateZeitraumFelderErrorFlag();

            return;
        }

        if (!isMax && !zeitraumFeld.value && this.hasMaximalePlaetze) {
            // Wenn ein 'Anzahl Plaetze'-ZeitraumFeld editiert wurde und keinen Wert mehr hat (''), muss das
            // dazugehoerige 'Max Anzahl Plaetze'-Zeitraumfeld auch geleert werden ('')
            this.deactivateMaxZeitraumFeld(zeitraumFeld);
            // Wochentotal der Max Anzahl aktualisieren
            this.calcWochenTotal(this.zeitraumFelderMax);
        }

        if (this.isAnzKleinerAlsMaxAndNotEmpty()) {
            // Alle ZF sind gueltig, neues Wochentotal berechnen
            this.calcWochenTotal(zeitraumFelder);
        }
        this.updateZeitraumFelderErrorFlag();
    }

    public calcWochenTotal(zeitraumFelder: ZeitraumFeld[]): void {
        const wochenKapazitaet = this.wochenKapazitaetFromZeitraumFelder(zeitraumFelder);
        const isMax = this.isMaxAnzahlZeitraumFelder(zeitraumFelder);

        if (wochenKapazitaet.kapazitaeten.length === 0) {
            if (isMax) {
                this.wochenKapazitaet.maxPlaetze = null;
            } else {
                this.wochenKapazitaet.anzahlPlaetze = null;
            }

            return;
        }

        const wochenplanId = checkPresent(this.wochenplan.id);

        const promise = isMax ?
                this.wochenplanService.calcPlaetze(wochenplanId, wochenKapazitaet, 'maxplaetze') :
                this.wochenplanService.calcPlaetze(wochenplanId, wochenKapazitaet, 'plaetze');

        promise.then(plaetzeByWeek => {
            this.calculateGruppenWochenbelegung(zeitraumFelder, plaetzeByWeek);
        });
    }

    public wochenKapazitaetFromZeitraumFelder(zeitraumFelder: ZeitraumFeld[]): WochenKapazitaet {
        // Es werden dieselben Values in plaetze & maxPlaetze geschrieben
        const kapazitaeten = this.kapazitaetService.createKapazitaetenFromZeitraumFelder(true, zeitraumFelder);
        const wochenKapazitaeten = new WochenKapazitaet();
        wochenKapazitaeten.kapazitaeten = this.kapazitaetService.createOrUpdateKapazitaeten(false, zeitraumFelder,
                kapazitaeten);

        return wochenKapazitaeten;
    }

    private clearZeitraumFeld(zf: ZeitraumFeld): void {
        zf.value = '';
        zf.hasError = false;
        this.kapazitaetService.clearErrors(zf);
    }
}

componentConfig.controller = DvbFraktionKapazitaetForm;
angular.module('kitAdmin').component('dvbFraktionKapazitaetForm', componentConfig);
