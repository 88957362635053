/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Language, MissingGeschlechtOrSprache} from '@dv/kitadmin/models';
import {checkPresent, DvbUtil, Geschlecht} from '@dv/shared/code';
import angular from 'angular';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktpersonEntries: '<',
    },
    template: require('./dvb-missing-geschlecht-or-sprache.html'),
    controllerAs: 'vm',
};

export class DvbMissingGeschlechtOrSprache implements angular.IController {

    public static $inject: readonly string[] = ['kontaktpersonService'];

    public kontaktpersonEntries: MissingGeschlechtOrSprache[] = [];

    public removeCompleteEntry: boolean = false;
    public currentPage: number = 1;
    public entriesPerPage: number = 10;
    public displayedKontaktperonEntries: MissingGeschlechtOrSprache[] = [];

    public constructor(private readonly kontaktpersonService: KontaktpersonService) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.kontaktpersonEntries) {
            this.currentPage = 1;
            this.setDisplayedKontaktpersonEntries(0);
        }
    }

    public onUpdatePage(): void {
        const start = (this.currentPage - 1) * this.entriesPerPage;
        this.setDisplayedKontaktpersonEntries(start);
    }

    public onUpdateGeschlecht(entry: MissingGeschlechtOrSprache, geschlecht: Geschlecht): void {
        this.kontaktpersonService.updateGeschlecht(checkPresent(entry.kontaktpersonId), geschlecht).then(() => {
            entry.geschlecht = geschlecht;
            this.removeCompleted(entry);
        });
    }

    public onUpdateSprache(entry: MissingGeschlechtOrSprache, sprache?: Language | null): void {
        if (sprache) {
            this.kontaktpersonService.updateKorrespondenzsprache(checkPresent(entry.kontaktpersonId), sprache)
                .then(() => {
                    entry.sprache = sprache;
                    this.removeCompleted(entry);
                });
        } else {
            this.kontaktpersonService.deleteKorrespondenzsprache(checkPresent(entry.kontaktpersonId)).then(() => {
                entry.sprache = null;
            });
        }
    }

    public onRemoveCompleted(): void {
        if (this.removeCompleteEntry) {
            this.displayedKontaktperonEntries.forEach(entry => this.removeCompleted(entry));
        }
    }

    private removeCompleted(entry: MissingGeschlechtOrSprache): void {
        if (!this.removeCompleteEntry) {
            return;
        }

        if (DvbUtil.isNotEmptyString(entry.geschlecht) &&
            entry.geschlecht !== Geschlecht.UNBEKANNT &&
            entry.sprache !== null) {

            DvbUtil.removeFromArray(entry, this.kontaktpersonEntries);
            this.onUpdatePage();
        }
    }

    private setDisplayedKontaktpersonEntries(start: number): void {
        this.displayedKontaktperonEntries = this.kontaktpersonEntries.slice(start, start + this.entriesPerPage);
    }
}

componentConfig.controller = DvbMissingGeschlechtOrSprache;
angular.module('kitAdmin').component('dvbMissingGeschlechtOrSprache', componentConfig);
