/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KitaOffenePosten} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {
    KitaFakturaService,
    OffenePostenIncludes,
} from '../../../../common/service/rest/kinderort/kitaFakturaService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
    },
    template: require('./dvb-kita-offene-posten.html'),
    controllerAs: 'vm',
};

export class DvbKitaOffenePosten implements angular.IController {

    public static $inject: readonly string[] = ['kitaFakturaService', '$q'];

    public kita!: Persisted<KinderOrt>;

    public isLoading: boolean = false;
    public kitaOffenePosten: KitaOffenePosten | null = null;
    public currentPage: number = 1;
    public maxNumberPerPage: number = 10;
    public zahlungenGueltigBis: moment.Moment | null = null;
    public periodeGueltigBis: moment.Moment | null = null;
    public selectedPosten: boolean[] = [];

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        private readonly kitaFakturaService: KitaFakturaService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.loadOffenePosten();
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public loadOffenePosten(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);

        this.isLoading = true;
        this.selectedPosten = [];
        this.kitaOffenePosten = null;

        this.timeout = this.$q.defer();

        const params: OffenePostenIncludes = {
            zahlungenGueltigBis: DvbRestUtil.momentToLocalDate(this.zahlungenGueltigBis) ?? undefined,
            periodeGueltigBis: DvbRestUtil.momentToLocalDate(this.periodeGueltigBis) ?? undefined,
        };

        const config = {timeout: this.timeout.promise};

        this.kitaFakturaService.getUnsettledOffenePosten(this.kita.id, params, config).then(kitaOffenePosten => {
            this.kitaOffenePosten = kitaOffenePosten;
            this.isLoading = false;
        }).catch(error => {
            if (!DvbRestUtil.isRequestCancelled(error)) {
                this.isLoading = false;

                return this.$q.reject(error);
            }

            return this.$q.resolve();
        });
    }

    public onAccordionUpdate(index: number, selected: boolean): void {
        this.selectedPosten[index] = selected;
    }
}

componentConfig.controller = DvbKitaOffenePosten;
angular.module('kitAdmin').component('dvbKitaOffenePosten', componentConfig);

