<div class="dvb-firmen-plaetze-verlauf">

    <div
        ng-repeat="firmenKontingentValue in (sortedFirmenKontingentValues = (vm.firmenKontingentValues | toArray:false | orderBy:'gueltigAb':true))">

        <!-- Beendeter firmenKontingentValue -->
        <dvb-collapsible-panel
            ng-show="($first || sortedFirmenKontingentValues[$index-1].gueltigAb.diff(firmenKontingentValue.gueltigBis, 'days') > 1) && vm.isFirmenKontingentValueBeendet(firmenKontingentValue)"
            is-first="$first"
            is-collapsed="true"
            dvb-auth
            require-permission="kita:betreuung_admin:*"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{firmenKontingentValue.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{firmenKontingentValue.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.revertFirmenKontingentValue(firmenKontingentValue)">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="firmenKontingentValue"
                               is-first="$first && !vm.isFirmenKontingentValueBeendet(firmenKontingentValue)"
                               expand-ab="vm.today"
                               expand-bis="vm.today">
            <panel-header>
                <dvb-firma-kontingent-value-header firmen-kontingent-value="firmenKontingentValue">
                </dvb-firma-kontingent-value-header>
            </panel-header>

            <panel-content>
                <dvb-firma-kontingent-value-content firmen-kontingent-value="firmenKontingentValue">
                </dvb-firma-kontingent-value-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
