/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil} from '@dv/shared/code';
import moment from 'moment';
import type {
    BetreuungsPersonMonatlicheStundenErfassung,
} from '../models/stundenerfassung/BetreuungsPersonMonatlicheStundenErfassung';
import {KindMonatlicheStundenErfassung} from '../models/stundenerfassung/KindMonatlicheStundenErfassung';

export class StundenErfassungUtil {

    public static getDateClass(
        dateMoment: moment.Moment,
        periode: moment.Moment,
        monthlyData: BetreuungsPersonMonatlicheStundenErfassung,
        erfassungen: KindMonatlicheStundenErfassung[],
    ): string {
        if (!monthlyData || monthlyData.kinder.length === 0) {
            return 'empty';
        }

        if (this.isOutsidePeriode(dateMoment, periode)) {
            return 'disabled';
        }

        return StundenErfassungUtil.getErfasstOrBelegtClass(dateMoment, erfassungen);
    }

    private static getErfasstOrBelegtClass(
        stichtag: moment.Moment,
        erfassungen: KindMonatlicheStundenErfassung[],
    ): string {
        const stichtagErfassungen = erfassungen
            .filter(value => KindMonatlicheStundenErfassung.hasKindBetreuungErfassungWithValue(value, stichtag));

        if (stichtagErfassungen.length > 0) {
            return 'erfasst';
        }

        const stichtagBelegungen = erfassungen
            .filter(value => KindMonatlicheStundenErfassung.hasBelegungPlatz(value, stichtag));

        return stichtagBelegungen.length > 0 ? 'belegt' : 'empty';
    }

    private static isOutsidePeriode(dateMoment: moment.Moment, periode: moment.Moment): boolean {
        return dateMoment.isBefore(DvbDateUtil.startOfMonth(moment(periode)), 'day')
            || dateMoment.isAfter(DvbDateUtil.endOfMonth(moment(periode)), 'day');
    }
}
