/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {EXTERNAL_STATES} from './external-states';

angular.module('kitAdmin').config(externalConfig);

export * from './component/dvb-external-anmeldung/dvb-external-anmeldung';
export * from './component/dvb-external-anmeldung-betreuung/dvb-external-anmeldung-betreuung';
export * from './component/dvb-external-anmeldung-conclusion/dvb-external-anmeldung-conclusion';
export * from './component/dvb-external-anmeldung-kind/dvb-external-anmeldung-kind';
export * from './component/dvb-external-anmeldung-kind-stammdaten/dvb-external-anmeldung-kind-stammdaten';
export * from './component/dvb-external-anmeldung-kinder/dvb-external-anmeldung-kinder';
export * from './component/dvb-external-anmeldung-kontakt-form/dvb-external-anmeldung-kontakt-form';
export * from './component/dvb-external-anmeldung-kontakte/dvb-external-anmeldung-kontakte';
export * from './component/dvb-external-anmeldung-privacy-confirmation/dvb-external-anmeldung-privacy-confirmation';
export * from './component/dvb-wizard/dvb-wizard';
// Add New Component TS Above

externalConfig.$inject = ['$stateProvider'];

function externalConfig($stateProvider: StateProvider): void {
    Object.values(EXTERNAL_STATES).forEach(state => $stateProvider.state(state));
}
