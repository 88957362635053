/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TempBlob} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import type {ITimelineController} from '../../models/ITimelineController';
import type {Notiz} from '../../models/Notiz';
import type {NotizService} from '../../service/notiz-service';

export class NotizTimelineController implements ITimelineController<Notiz> {

    public readonly iconHTML: string;
    public readonly iconTooltipText: string;

    public constructor(
        private readonly $translate: angular.translate.ITranslateService,
        private readonly notizService?: NotizService,
    ) {
        this.iconHTML = '<i class="fa fa-clipboard"></i>';
        this.iconTooltipText = this.$translate.instant('NOTIZ.NAME');
    }

    public getTimelineDate(entry: Notiz): string {
        const timestamp = entry.timestampTimeline ?? entry.timestampErstellt;

        return timestamp?.format('DD.MM.YYYY') ?? '';
    }

    public getTimelineTime(entry: Notiz): string {
        if (entry.hasTimelineTime()) {
            return entry.timestampTimeline?.format('HH:mm') ?? '';
        }

        return entry.timestampTimeline ? '' : entry.timestampErstellt?.format('HH:mm') ?? '';
    }

    public getAttachment(timelineEntryId: string, blobId: string): angular.IPromise<TempBlob> {
        return checkPresent(this.notizService).getAttachment(timelineEntryId, blobId);
    }

    public getIconHTML(): string {
        return this.iconHTML;
    }

    public getIconTooltipText(): string {
        return this.iconTooltipText;
    }

    public getTooltipText(entry: { userErstellt: string }): string {
        const interpolateParams = {createdBy: entry.userErstellt};

        return this.$translate.instant('NOTIZ.HEADER_INFO', interpolateParams);
    }
}
