<div class="header">
    <div class="row header-row">
        <div class="col-xs-12 header-box">
            <h1>
                <div ng-if="!vm.emailTemplate.id">
                    <span data-translate="COMMUNICATION.EMAIL_TEMPLATE.CREATE"></span>
                </div>
                <div ng-if="vm.emailTemplate.id">
                    <span data-translate="COMMUNICATION.EMAIL_TEMPLATE.EDIT"></span>
                </div>
            </h1>
        </div>
    </div>
</div>
<form ng-if="vm.emailTemplate"
      name="emailTemplateForm"
      ng-submit="vm.submit(emailTemplateForm)"
      novalidate
      class="dvb-form">
    <div class="row">
        <div class="col-md-7">
            <input type="text"
                   class="form-control"
                   ng-model="vm.emailTemplate.name"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMUNICATION.EMAIL_TEMPLATE.TEMPLATE_NAME' | translate}}"
                   uib-tooltip="{{'COMMUNICATION.EMAIL_TEMPLATE.TEMPLATE_NAME' | translate}}"
                   tooltip-placement="top-left"
                   required>
            <input type="text"
                   class="form-control"
                   ng-model="vm.emailTemplate.subject"
                   maxlength="255"
                   ng-attr-placeholder="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}"
                   uib-tooltip="{{'COMMUNICATION.EMAIL.SUBJECT' | translate}}"
                   tooltip-placement="top-left">

            <dv-wysiwyg-editor class="normal-abstand-top"
                               [(content)]="vm.emailTemplate.content"
                               [placeholder-str]="'{{'COMMUNICATION.EMAIL_TEMPLATE.CONTENT' | translate}}'">
            </dv-wysiwyg-editor>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <h2 data-translate="COMMON.KINDERORT.PLURAL" class="small-abstand-top"></h2>
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           ng-model="vm.emailTemplate.alleKinderOrte"
                           ng-disabled="vm.disableAlleKinderOrte">
                    <span data-translate="COMMON.ALLE_KINDERORTE"></span>
                </label>
            </div>
            <div ng-if="!vm.emailTemplate.alleKinderOrte">
                <div class="row selected-entity"
                     ng-repeat="kinderOrt in vm.emailTemplate.kinderOrte"
                     ng-init="searchResultEntry = kinderOrt.toSearchResultEntry()">
                    <div class="col-xs-9 entity">
                        <a ui-sref="base.kinderort.profil({id: kinderOrt.id})">
                            <dvb-search-result-icon entry="searchResultEntry"></dvb-search-result-icon>
                            <span ng-bind="kinderOrt.getDisplayName()"></span>
                        </a>
                    </div>
                    <div class="col-xs-3 edit">
                        <button type="button" class="btn btn-link"
                                ng-click="vm.removeKinderOrt(kinderOrt)"
                                data-translate="COMMON.LOESCHEN">
                        </button>
                    </div>
                </div>
                <dvb-search-on-click-entity on-select="vm.addKinderOrt(item)"
                                            on-select-clear="true"
                                            entity-to-search="KINDERORT"
                                            disabled-entries="vm.emailTemplate.kinderOrte"
                                            add-label-key="COMMON.KINDERORT.AUSWAEHLEN"
                                            placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
                </dvb-search-on-click-entity>
            </div>
        </div>
    </div>
    <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                               on-cancel="vm.goBack()">
    </dvb-submit-cancel-buttons>
</form>
