/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/* eslint-disable max-lines */

import type {
    Austritt,
    BelegungMaxGueltigkeit,
    BetreuungsGutschein,
    ConfidentialityLevel,
    MonatsBelegung,
    Rechnungsaufteilung,
    RestCache,
    TarifParameterHistoryEntry,
} from '@dv/kitadmin/models';
import {
    Belegung,
    BelegungenWithFraktionen,
    Betreuungsfaktor,
    BetreuungsGutscheinGroup,
    Bewerbung,
    Kind,
    Leistungsrechnung,
    LimitedAdresse,
    Rechnung,
    RelationshipWithKontaktperson,
    StundenKontingent,
    TarifParameterHistoryEntries,
    TempBlob,
} from '@dv/kitadmin/models';
import type {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {Persisted, RestInclude, RestLimited, RestLimitedMatrixParams} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, HttpCodes, isPresent} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {CONFIG} from '../../../../../config';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class KindService {
    public static $inject: readonly string[] = ['$http', '$q'];

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/kinder`;

    public constructor(private $http: angular.IHttpService, private $q: angular.IQService) {
    }

    public create(kind: Kind): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(KindService.baseUrl, kind.toRestObject(true));
    }

    public update(kind: Kind): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(checkPresent(kind.id))}`;

        return this.$http.put(url, kind.toRestObject(false));
    }

    public deleteKind(kindId: string, ignoreBetreuungsMeldungen?: boolean): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const matrixParam = DvbRestUtil.encodeMatrixParams({ignoreBetreuungsMeldungen});

        return this.$http.delete(`${KindService.baseUrl}/${encodeURIComponent(kindId)}${matrixParam}`);
    }

    public get(kindId: string, params?: RestInclude & RestCache & RestLimited): angular.IPromise<Persisted<Kind>> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(KindService.baseUrl, Kind, kindId, params)
            .then(checkPresent);
    }

    public getAll(params?: RestInclude): angular.IPromise<Persisted<Kind>[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(KindService.baseUrl, Kind, 'kinder', matrixParams);
    }

    public importAnhangFromTempBlob(
        kindId: string,
        tempBlobId: string,
        conflvl: ConfidentialityLevel,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const matrixParam = DvbRestUtil.encodeMatrixParams({conflvl});

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `anhaenge/import-temp-blob/${encodeURIComponent(tempBlobId)}` + `${matrixParam}`;

        return this.$http.post(url, null);
    }

    public getTempBlobForAnhang(kindId: string, anhangId: string): angular.IPromise<TempBlob> {
        const deferred = this.$q.defer<TempBlob>();

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/anhaenge/${encodeURIComponent(anhangId)}`;

        this.$http.get(url).then(response => {
            deferred.resolve(TempBlob.apiResponseTransformer(response.data));
        }).catch(error => {
            deferred.reject(error);
        });

        return deferred.promise;
    }

    public changeConfLevel(kindId: string, anhangId: string, conflvl: ConfidentialityLevel): angular.IPromise<unknown> {

        const matrixParam = DvbRestUtil.encodeMatrixParams({conflvl});

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/anhaenge/${encodeURIComponent(anhangId)}`
            + `${matrixParam}`;

        return this.$http.put(url, {});
    }

    public deleteAnhang(kindId: string, anhangId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/anhaenge/${encodeURIComponent(anhangId)}`;

        return this.$http.delete(url);
    }

    public deleteAdresse(kindId: string, adresseId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/adressen/${encodeURIComponent(adresseId)}`;

        return this.$http.delete(url);
    }

    public addGeschwisterToKind(kindId: string, geschwisterId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `geschwister/${encodeURIComponent(geschwisterId)}`;

        return this.$http.put(url, {});
    }

    public removeGeschwisterFromKind(kindId: string, geschwisterId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `geschwister/${encodeURIComponent(geschwisterId)}`;

        return this.$http.delete(url);
    }

    public getBetreuungsGruendeIds(kindId: string): angular.IPromise<string[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/betreuungsgruende`;

        return this.$http.get<{ ids: string[] }>(url).then(response => {
            if (Array.isArray(response.data.ids)) {
                return response.data.ids;
            }

            return this.$q.reject(response);
        });
    }

    public setBetreuungsGruendeByIds(kindId: string, betreuungsGruendeIds: string[]): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/betreuungsgruende`;

        return this.$http.post(url, betreuungsGruendeIds);
    }

    public createBewerbung(kindId: string, bewerbung: Bewerbung): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/bewerbungen`;

        return this.$http.post(url, bewerbung.toRestObject(true));
    }

    /**
     * @return Alle Bewerbungen des Kindes (auch geschlossene)
     */
    public getBewerbungen(kindId: string, params?: RestInclude): angular.IPromise<Bewerbung[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/bewerbungen`;

        return DvbRestUtilAngularJS.getModelsArray(url, Bewerbung, 'bewerbungen', matrixParams);
    }

    /**
     * @return die offenen Bewerbung des Kindes, falls sie existiert
     */
    public getOffeneBewerbung(kindId: string, params?: RestInclude): angular.IPromise<Bewerbung> {
        const deferred = this.$q.defer<Bewerbung>();
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `bewerbungen/offen${DvbRestUtil.encodeMatrixParams(matrixParams)}`;

        this.$http.get(url).then(response => {
            if (response.status === HttpCodes.NO_CONTENT) {
                deferred.reject(response);
            } else {
                deferred.resolve(Bewerbung.apiResponseTransformer(response.data));
            }
        }, response => {
            deferred.reject(response);
        });

        return deferred.promise;
    }

    public createBelegung(kindId: string, belegung: Belegung): angular.IHttpPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/belegungen`;

        return this.$http.post(url, belegung.toRestObject());
    }

    public validateBelegung(kindId: string, belegung: Belegung): angular.IPromise<unknown> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/validate/belegungen`;

        return this.$http.post(url, belegung.toRestObject());
    }

    public updateMonatsBelegung(kindId: string, monatsBelegung: MonatsBelegung): angular.IPromise<unknown> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/monatsbelegung`;

        return this.$http.post(url, monatsBelegung.toRestObject());
    }

    public getMaxGueltigBisForBelegung(kindId: string, belegung: Belegung): angular.IPromise<BelegungMaxGueltigkeit> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/belegungen/maxgueltigbis`;

        return this.$http.post<{
            maxGueltigBis: string;
            message: string;
        }>(url, belegung.toRestObject()).then(response => {
            const maxGueltigBis = DvbRestUtil.localDateToMoment(response.data.maxGueltigBis);
            const message = response.data.message;

            return {
                maxGueltigBis: checkPresent(maxGueltigBis),
                message,
            };
        });
    }

    public matchingKind(kind: Kind): angular.IPromise<Kind[]> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/match`;

        return this.$http.post<{
            kinder: any[];
        }>(url, kind.toRestObject(false)).then(response => {
            if (Array.isArray(response.data.kinder)) {
                return response.data.kinder.map(k => Kind.apiResponseTransformer(k));
            }

            return this.$q.reject(response);
        });
    }

    public getBelegungenWithFraktionen(
        kindId: string,
        params?: RestLimited & RestInclude,
    ): angular.IPromise<BelegungenWithFraktionen> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/belegungenwithfraktionen`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, BelegungenWithFraktionen, matrixParams)
            .then(checkPresent);
    }

    /**
     * @return OK wenn der Austritt erfolgreich durchgefuehrt wurde.
     */
    public austritt(kindId: string, austrittObj: Austritt): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/austritt`;

        return this.$http.post(url, austrittObj.toRestObject());
    }

    public revertAustritt(kindId: string, austrittsDatum: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const austrittsDatumUriComponent = DvbRestUtil.momentToLocalDateChecked(austrittsDatum);

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `austritt/${encodeURIComponent(austrittsDatumUriComponent)}`;

        return this.$http.delete(url);
    }

    public setRechnungsaufteilung(kindId: string, aufteilung: Rechnungsaufteilung): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/rechnungsaufteilung`;

        return this.$http.put(url, aufteilung.toRestObject());
    }

    /**
     * Create BetreuungsGutschein for Kind
     */
    public createBetreuungsGutschein(
        kindId: string,
        betreuungsGutschein: BetreuungsGutschein,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/betreuungsgutscheine`;

        return this.$http.post(url, betreuungsGutschein.toRestObject());
    }

    public getBetreuungsGutscheineGroups(kindId: string): angular.IPromise<BetreuungsGutscheinGroup[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/betreuungsgutscheine`;

        return DvbRestUtilAngularJS.getModelsArray(url, BetreuungsGutscheinGroup, 'groups', {}, true);
    }

    public createTarifParameterHistoryEntry(
        kindId: string,
        entry: TarifParameterHistoryEntry,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/tarife/parameter`;

        return this.$http.post(url, entry.toRestObject());
    }

    public extendTarifParameterHistoryEntry(kindId: string, entryId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `tarife/parameter/${encodeURIComponent(entryId)}/gueltigbis`;

        return this.$http.delete(url);
    }

    public getTarifParameterHistory(
        kindId: string,
        dtype?: TarifParameterType,
    ): angular.IPromise<TarifParameterHistoryEntries> {

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/tarife/parameter`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TarifParameterHistoryEntries, {dtype}, {cache: true})
            .then(checkPresent);
    }

    public deleteTarifParameterHistoryEntry(
        kindId: string,
        tarifParameterHistoryEntryId: string,
    ): angular.IPromise<unknown> {

        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `tarife/parameter/${encodeURIComponent(tarifParameterHistoryEntryId)}`;

        return this.$http.delete(url);
    }

    public getStundenKontingente(kindId: string): angular.IPromise<StundenKontingent[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/stundenkontingente`;

        return DvbRestUtilAngularJS.getModelsArray(url, StundenKontingent, 'stundenKontingente');
    }

    public getWohnsitze(kindId: string): angular.IPromise<LimitedAdresse[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/adressen`;

        return DvbRestUtilAngularJS.getModelsArray(url, LimitedAdresse, 'limitedAdressen');
    }

    public terminateKindParameter(kindId: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/tarife/parameter/enddate`;
        const data = {date: DvbRestUtil.momentToLocalDate(endDate)};

        return this.$http.put(url, data);
    }

    public getAllRelationshipsWithKontaktpersonen(
        kindId: string,
        params?: RestInclude & RestCache,
    ): angular.IPromise<RelationshipWithKontaktperson[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelsArray(this.kontakteBaseUrl(kindId), RelationshipWithKontaktperson,
            'relationshipsWithKontaktpersonen', matrixParams, !!params?.cache);
    }

    public getRelationshipWithKontaktperson(
        kindId: string,
        kontaktpersonId: string,
        params?: RestInclude & RestCache,
    ): angular.IPromise<RelationshipWithKontaktperson> {

        const url = `${this.kontakteBaseUrl(kindId)}/${encodeURIComponent(kontaktpersonId)}`;
        const matrixParams = params?.includes ? {includes: params.includes} : {};

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, RelationshipWithKontaktperson, matrixParams, params)
            .then(checkPresent);
    }

    public getLeistungsrechnungen(
        kindId: string,
        params?: RestInclude & RestLimited,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Leistungsrechnung[]> {

        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/leistungsrechnungen`;

        return DvbRestUtilAngularJS.getModelsArray(url,
            Leistungsrechnung, 'leistungsrechnungen', matrixParams, false, config);
    }

    /**
     * gueltigAb & gueltigBis are optional. When both are set, then only Rechnungen within gueltigAb & gueltigBis
     * are returned
     */
    public getRechnungen(
        kindId: string,
        params?: RestInclude & RestLimitedMatrixParams,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Persisted<Rechnung>[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/rechnungen`;

        return DvbRestUtilAngularJS.getModelsArray(url, Rechnung, 'items', params, false, config);
    }

    public getBetreuungsfaktor(kindId: string, kitaId: string, params: RestInclude & {
        stichtag: moment.Moment;
    }, config?: angular.IRequestShortcutConfig): angular.IPromise<Betreuungsfaktor> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/` +
            `betreuungsfaktoren/kita/${encodeURIComponent(kitaId)}`;

        const matrixParams = {
            stichtag: DvbRestUtil.momentToLocalDate(params.stichtag),
            includes: params.includes ?? undefined,
        };

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Betreuungsfaktor, matrixParams, config)
            .then(checkPresent);
    }

    public getBetreuungsfaktoren(kindId: string, params: RestInclude & {
        stichtag: moment.Moment;
    }, config?: angular.IRequestShortcutConfig): angular.IPromise<Betreuungsfaktor[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/betreuungsfaktoren`;

        const matrixParams = {
            stichtag: DvbRestUtil.momentToLocalDate(params.stichtag),
            includes: params.includes ?? undefined,
        };

        return DvbRestUtilAngularJS.getModelsArray(
            url,
            Betreuungsfaktor,
            'betreuungsfaktoren',
            matrixParams,
            false,
            config,
        );
    }

    public getWochenBelegungen(
        kindId: string,
        params: RestInclude & RestLimited,
    ): angular.IPromise<Belegung[]> {
        const url = `${KindService.baseUrl}/${encodeURIComponent(kindId)}/wochenbelegungen`;

        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return DvbRestUtilAngularJS.getModelsArray(
            url,
            {apiResponseTransformer: this.kindWochenBelegungTransformer},
            'items',
            matrixParams,
            false);
    }

    private kindWochenBelegungTransformer = (data: any): Belegung => Belegung.apiResponseTransformer(data.belegung);

    private kontakteBaseUrl(kindId: string): string {
        return `${KindService.baseUrl}/${encodeURIComponent(kindId)}/kontakte`;
    }
}
