/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {ChartData} from 'chart.js';
import type moment from 'moment';
import type {StyleVariableService} from '../../../../common/service/style-variable.service';
import type {ControllingReport} from '../../../../report/models/ControllingReport';
import {DataSet} from '../dvb-kita-chart/DataSet';
import type {ChartComponent} from '../service/ChartContext';
import {ChartContext} from '../service/ChartContext';
import {ChartUtil} from '../service/ChartUtil';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        controllingReport: '<',
        firstOfWeek: '<',
        onSetWeek: '&',
    },
    template: require('./dvb-kita-chart-personal.html'),
    controllerAs: 'vm',
};

export class DvbKitaChartPersonal implements angular.IController, ChartComponent {
    public static $inject: readonly string[] = [
        'styleVariableService',
        '$translate',
        '$element',
        '$scope',
        'authStore',
    ];

    private static readonly Y_AXIS_MARGIN: number = 2;

    public controllingReport!: ControllingReport;
    public firstOfWeek!: moment.Moment;
    public onSetWeek!: (param: { newFirstOfWeek: moment.Moment }) => any;

    public chart: Chart | null = null;
    public chartData?: ChartData = undefined;
    public maxValue: number = 0;
    public minValue: number = 0;

    public constructor(
        public styleVariableService: StyleVariableService,
        private $translate: angular.translate.ITranslateService,
        private $element: angular.IAugmentedJQuery,
        public $scope: angular.IScope,
        private authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        if (!this.authStore.hasPermission(PERMISSION.PERSONAL.VIEW_ANY)) {
            return;
        }

        this.populateDatasets();

        // timeout is used to avoid a timing issue, when displaying charts in tabs
        setTimeout(() => {
            this.initChart();
        });
    }

    private initChart(): void {
        const canvas = this.$element.find('#kita-chart-personal')[0];
        const chartContext = new ChartContext(this, this.chartData!, canvas);

        const options = ChartUtil.createOptions(
            chartContext,
            this.styleVariableService,
            this.maxValue,
            this.minValue,
            this.$translate);

        this.chart = ChartUtil.createChart(options, chartContext);
    }

    private populateDatasets(): void {

        const bedarf = new DataSet(
            this.$translate.instant('PERSONAL.CHART.BEDARF'),
            this.styleVariableService.getColorGuide());

        const assigned = new DataSet(
            this.$translate.instant('PERSONAL.CHART.ASSIGNED'),
            this.styleVariableService.getColorContrast());

        const employed = new DataSet(
            this.$translate.instant('PERSONAL.CHART.EMPLOYED'),
            this.styleVariableService.getColorMainNormal());

        let maxValue = 0;
        let minValue = 100;
        const mondays = DvbDateUtil.createMondaysForYear(this.firstOfWeek.isoWeekYear());

        // Fill sets with values
        mondays.forEach(aMoment => {
            const weeklyFigure = DvbDateUtil.getEntityOn(this.controllingReport.weeklyFigures, aMoment);

            if (!weeklyFigure) {
                return;
            }

            bedarf.data.push({x: aMoment, y: weeklyFigure.personalBedarf});
            assigned.data.push({x: aMoment, y: weeklyFigure.personalZugewiesen});
            employed.data.push({x: aMoment, y: weeklyFigure.personalAngestellt});

            maxValue = Math.max(
                maxValue,
                weeklyFigure.personalBedarf || 0,
                weeklyFigure.personalZugewiesen || 0,
                weeklyFigure.personalAngestellt || 0);
            minValue = Math.min(
                minValue,
                weeklyFigure.personalBedarf || 0,
                weeklyFigure.personalZugewiesen || 0,
                weeklyFigure.personalAngestellt || 0);
        });

        this.chartData = {};
        this.chartData.datasets = [];
        this.maxValue = Math.round(maxValue + DvbKitaChartPersonal.Y_AXIS_MARGIN);
        this.minValue = Math.round(Math.max(0, minValue - DvbKitaChartPersonal.Y_AXIS_MARGIN));

        this.chartData.datasets.push(bedarf);
        this.chartData.datasets.push(assigned);
        this.chartData.datasets.push(employed);
    }
}

componentConfig.controller = DvbKitaChartPersonal;
angular.module('kitAdmin').component('dvbKitaChartPersonal', componentConfig);
