/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KibonExchangeVerfuegung} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import {UserRole} from '@dv/shared/roles';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import type {UiRouterRedirection} from '../base/run/ui-router-redirection';
import {trans} from '../common/i18n/trans';
import type {KibonVerfuegungenService} from './service/kibonVerfuegungenService';

export const BASE_STATE = {
    name: 'base.kibon',
    url: '/kibon',
    abstract: true,
    template: '<dvb-kibon-main></dvb-kibon-main>',
    data: {
        permission: PERMISSION.KITA.CONTROL_ANY,
    },
} satisfies Ng1StateDeclaration;

export const VERFUEGUNGEN_OVERVIEW_RELOADED_STATE = {
    name: `${BASE_STATE.name}.verfuegungen-reloaded`,
    url: '/verfuegungen-reloaded',
    component: 'dvbKibonVerfuegung',
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },
} satisfies Ng1StateDeclaration;

export const VERFUEGUNGEN_BASE_STATE = {
    name: `${BASE_STATE.name}.verfuegungen`,
    url: '/verfuegungen',
    abstract: true,
    template: '<ui-view></ui-view>',
    resolve: {
        verfuegungen: [
            'kibonVerfuegungenService', (
                kibonVerfuegungenService: KibonVerfuegungenService,
            ): angular.IPromise<KibonExchangeVerfuegung[] | null> =>
                kibonVerfuegungenService.getUnmatchedVerfuegungen(),
        ],
    },
} satisfies Ng1StateDeclaration;

export const VERFUEGUNG_OVERVIEW_STATE = {
    name: `${VERFUEGUNGEN_BASE_STATE.name}.overview`,
    url: '',
    template: '<dvb-kibon-verfuegung-overview verfuegungen="$resolve.verfuegungen">' +
        '</dvb-kibon-verfuegung-overview>',
} satisfies Ng1StateDeclaration;

export const VERFUEGUNG_ASSIGNMENT_STATE = {
    name: `${VERFUEGUNGEN_BASE_STATE.name}.assign`,
    url: '/zuweisen/{id:[0-9]+}',
    template: '<dvb-kibon-verfuegung-matching verfuegung="$resolve.verfuegung">' +
        '</dvb-kibon-verfuegung-matching>',
    resolve: {
        verfuegung: [
            'verfuegungen', '$transition$', '$q', (
                verfuegungen: KibonExchangeVerfuegung[], $transition$: Transition, $q: angular.IQService,
            ): KibonExchangeVerfuegung | angular.IPromise<UiRouterRedirection> => {
                const found = verfuegungen.find(v => v.id === $transition$.params().id);
                if (!found) {
                    const target = $transition$.router.stateService.target(VERFUEGUNG_OVERVIEW_STATE.name);

                    return $q.reject({redirectTo: target});
                }

                return found;
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const INSTITUTION_STATE = {
    name: `${BASE_STATE.name}.institutionen`,
    url: '/institutionen',
    template: '<dvb-institution-matching></dvb-institution-matching>',
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'KIBON.NAV_INSTITUTIONEN'),
        ],
    },
    data: {
        role: UserRole.MANDANT_ADMIN,
    },
} satisfies Ng1StateDeclaration;

export const ANFRAGEN_STATE = {
    name: `${BASE_STATE.name}.anfragen`,
    url: '/anfragen',
    component: 'dvbKibonBetreuungsAnfragen',
} satisfies Ng1StateDeclaration;

export const TAGESSCHULE_ANMELDUNG_STATE = {
    name: `${BASE_STATE.name}.tagesschuleAnmeldungen`,
    url: '/tagesschuleAnmeldungen',
    component: 'dvbKibonTagesschuleAnmeldungen',
} satisfies Ng1StateDeclaration;

export const KIBON_STATES = {
    BASE_STATE,
    VERFUEGUNGEN_OVERVIEW_RELOADED_STATE,
    VERFUEGUNGEN_BASE_STATE,
    VERFUEGUNG_OVERVIEW_STATE,
    VERFUEGUNG_ASSIGNMENT_STATE,
    INSTITUTION_STATE,
    ANFRAGEN_STATE,
    TAGESSCHULE_ANMELDUNG_STATE,
};
