/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {DASHBOARD_BASE_STATE, DASHBOARD_STATE, EMPTY_DASHBOARD_STATE} from './dashboard-state';

angular.module('kitAdmin').config(dashboardConfig);

export * from './component/dvb-dashboard/dvb-dashboard';
export * from './component/dvb-dashboard-greeting/dvb-dashboard-greeting';
export * from './component/dvb-dashboard-favoriten/dvb-dashboard-favoriten';
export * from './component/dvb-dashboard-favoriten-suche/dvb-dashboard-favoriten-suche';
export * from './component/dvb-dashboard-tasks/dvb-dashboard-tasks';
export * from './component/dvb-dashboard-empty/dvb-dashboard-empty';
// Add New Component JS Above

dashboardConfig.$inject = ['$stateProvider'];

function dashboardConfig($stateProvider: StateProvider): void {

    $stateProvider.state(DASHBOARD_BASE_STATE);
    $stateProvider.state(DASHBOARD_STATE);
    $stateProvider.state(EMPTY_DASHBOARD_STATE);
    /* Add New States Above */
}
