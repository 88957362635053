<div class="dvb-kind-tarife-betreuungsgutscheine">
    <h2 data-translate="KIND.BETREUUNGSGUTSCHEINE"
        data-translate-value-kitaname="{{vm.kita.getDisplayName()}}"></h2>
    <div
        ng-repeat="betreuungsGutscheinGroup in (sortedEntries = (vm.betreuungsGutscheineGroups | toArray:false | orderBy: 'gueltigAb' : true))">
        <dvb-collapsible-panel limited-entity="betreuungsGutscheinGroup"
                               is-first="$first"
                               expand-ab="vm.today"
                               expand-bis="vm.today">
            <panel-header>
                <dvb-kind-tarife-betreuungsgutscheine-header
                    betreuungs-gutschein-group="betreuungsGutscheinGroup">
                </dvb-kind-tarife-betreuungsgutscheine-header>
            </panel-header>
            <panel-content>
                <dvb-kind-tarife-betreuungsgutscheine-content
                    kita="vm.kita"
                    on-update="vm.onUpdate({betreuungsGutschein: betreuungsGutschein})"
                    on-delete="vm.onDelete({betreuungsGutscheinGroup: betreuungsGutscheinGroup})"
                    betreuungs-gutschein-group="betreuungsGutscheinGroup">
                </dvb-kind-tarife-betreuungsgutscheine-content>
            </panel-content>
        </dvb-collapsible-panel>

    </div>
</div>
