<div class="dvb-umzug">
    <h2 data-translate="COMMON.TITLE_UMZUG"></h2>

    <form name="umzugForm" ng-submit="umzugForm.$valid && !umzugForm.$pending && vm.saveUmzug()" novalidate>
        <div class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.umzug.limitedAdresse.gueltigAb"
                                           required
                                           ng-change="vm.loadReleatedPersonen()"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dvb-adresse-form ng-model="vm.umzug.limitedAdresse.adresse"></dvb-adresse-form>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h3 data-translate="COMMON.MITZUEGLER"></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12" ng-if="!vm.umzug.limitedAdresse || !vm.umzug.limitedAdresse.gueltigAb">
                <p data-translate="COMMON.BITTE_UMZUGSDATUM_ANGEBEN"></p>
            </div>
            <div class="col-xs-12">
                <div class="checkbox"
                     ng-repeat="kind in vm.mitzuegler.kinder | orderBy:'person.getDisplayName()'"
                     ng-init="isSelected = vm.isSelectedKind(kind.person)">
                    <label>
                        <input type="checkbox"
                               value="kind.person.id"
                               ng-model="isSelected"
                               ng-change="vm.toggleKindMitzuegler(kind.person)">
                        <i class="fa fa-child"></i>
                        <span ng-bind="kind.person.getDisplayName()"></span>
                    </label>
                </div>
                <div class="checkbox"
                     ng-repeat="kontaktperson in vm.mitzuegler.kontaktpersonen | orderBy:'person.getDisplayName()'"
                     ng-init="isSelected = vm.isSelectedKontaktperson(kontaktperson.person)">
                    <label>
                        <input type="checkbox"
                               value="kontaktperson.person.id"
                               ng-model="isSelected"
                               ng-change="vm.toggleKontaktpersonMitzuegler(kontaktperson.person)">
                        <i class="fa fa-male"></i> <span ng-bind="kontaktperson.person.getDisplayName()"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row normal-abstand-top hidden-print">
            <div class="col-md-12">
                <ul class="list-inline">
                    <li>
                        <dvb-loading-button type="submit"
                                            is-loading="umzugForm.$pending || vm.loadingContext.isLoading">
                            <span data-translate="COMMON.SPEICHERN"></span>
                        </dvb-loading-button>
                    </li>
                    <li>
                        <a href="" ng-click="vm.onCancel()">
                            <span data-translate="COMMON.ABBRECHEN"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </form>
</div>
