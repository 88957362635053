<div class="dvb-tarif-verlauf">

    <div ng-repeat="tarifValue in (sortedTarifValues = (vm.tarifValues | toArray:false | orderBy:'gueltigAb':true))">
        <!-- Beendeter tarifValue -->
        <dvb-collapsible-panel
            ng-show="sortedTarifValues[$index-1].gueltigAb.diff(tarifValue.gueltigBis, 'days') > 1  || $first && vm.isTarifValueBeendet(tarifValue)"
            is-first="$first"
            is-collapsed="true">
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{tarifValue.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{tarifValue.gueltigBis | amDateFormat: 'D.M.YYYY'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.onRevert({tarifValue: tarifValue})">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="tarifValue"
                               is-first="$first && !vm.isTarifValueBeendet(tarifValue)"
                               expand-ab="vm.today"
                               expand-bis="vm.today"
                               dvb-auth
                               require-permission="kita:betreuung_admin:*"
                               on-unauthorised-disable>
            <panel-header class="tarif-value">
                <dvb-tarif-verlauf-header tarif-value="tarifValue" tarif-id="vm.tarifId">
                </dvb-tarif-verlauf-header>
            </panel-header>
            <panel-content>
                <dvb-tarif-verlauf-content tarif-id="vm.tarifId"
                                           tarif-value="tarifValue"
                                           delete-allowed="vm.isDeleteAllowed()"
                                           on-update="vm.onUpdate({tarifValue: tarifValue})"
                                           on-delete="vm.onDelete({tarifValue: tarifValue})">
                </dvb-tarif-verlauf-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
