/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Language, MissingGeschlechtOrSprache} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {Geschlecht} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entry: '<',
        onUpdateGeschlecht: '&',
        onUpdateSprache: '&',
    },
    template: require('./dvb-update-geschlecht-or-sprache.html'),
    controllerAs: 'vm',
};

export class DvbUpdateGeschlechtOrSprache implements angular.IController {

    public static $inject: readonly string[] = [];

    public entry!: MissingGeschlechtOrSprache;
    public onUpdateGeschlecht?: FunctionType;
    public onUpdateSprache?: FunctionType;

    public sprache: Language | null = null;
    public geschlecht: Geschlecht | null = null;
    public geschlechte: readonly Geschlecht[] = [
        Geschlecht.WEIBLICH,
        Geschlecht.MAENNLICH,
        Geschlecht.DIVERS,
        Geschlecht.UNBEKANNT,
    ];

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.entry) {
            this.sprache = angular.copy(changes.entry.currentValue.sprache);
            this.geschlecht = angular.copy(changes.entry.currentValue.geschlecht);
        }
    }
}

componentConfig.controller = DvbUpdateGeschlechtOrSprache;
angular.module('kitAdmin').component('dvbUpdateGeschlechtOrSprache', componentConfig);
