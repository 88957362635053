/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import {DEBOUNCE_TIME, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {unitOfTime} from 'moment';
import moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        firstDateOfMode: '<ngModel',
        gueltigAb: '<?',
        gueltigBis: '<?',
        mode: '@',
        displayFormat: '@?',
        isDisabled: '<?',
        debounce: '<?',
    },
    template: require('./dvb-date-switcher.html'),
    controllerAs: 'vm',
};

export enum DateSwitcherType {
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    DAY = 'day',
}

export class DvbDateSwitcher implements angular.IController {
    public static $inject: readonly string[] = ['$timeout'];

    public ngModelCtrl!: angular.INgModelController;

    public firstDateOfMode!: moment.Moment;
    public gueltigAb?: moment.Moment;
    public gueltigBis?: moment.Moment;
    public mode!: DateSwitcherType;
    public displayFormat?: string;
    public isDisabled?: boolean;
    public debounce?: number;

    public datePicker: { date: Date; isOpen: boolean } = {
        date: moment().toDate(),
        isOpen: false,
    };

    public datePickerOptions: angular.ui.bootstrap.IDatepickerConfig = {
        startingDay: 1,
        showWeeks: false,
        minDate: null,
        maxDate: null,
        datepickerMode: 'day',
        minMode: 'day',
        maxMode: 'year',
    };

    private config: {
        offsetType: unitOfTime.DurationConstructor | null;
        startOfType: unitOfTime.StartOf | null;
    } = {
        offsetType: null,
        startOfType: null,
    };

    private lastChangedTimestamp: moment.Moment | number = 0;

    private switchPromise: angular.IPromise<unknown> | null = null;

    public constructor(
        private $timeout: angular.ITimeoutService,
    ) {
    }

    public $onInit(): void {
        if (!Object.values(DateSwitcherType).includes(this.mode)) {
            throw new Error(`unsupported mode: ${this.mode}`);
        }

        this.ngModelCtrl.$formatters.push(modelValue => {
            return this.toFirstDateOfMode(modelValue);
        });

        if (!angular.isNumber(this.debounce)) {
            this.debounce = DEBOUNCE_TIME;
        }
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.firstOfWeek && DvbDateUtil.isValidMoment(changes.firstOfWeek.currentValue)) {
            this.datePicker.date = changes.firstOfWeek.currentValue.toDate();
        }
        if (changes.gueltigAb && DvbDateUtil.isValidMoment(changes.gueltigAb.currentValue)) {
            this.datePickerOptions.minDate = changes.gueltigAb.currentValue.toDate();
        }
        if (changes.gueltigBis && DvbDateUtil.isValidMoment(changes.gueltigBis.currentValue)) {
            this.datePickerOptions.maxDate = changes.gueltigBis.currentValue.toDate();
        }
        if (changes.mode) {
            this.onModeChange(changes.mode.currentValue);
        }
    }

    public getLastOfWeek(): moment.Moment {
        return DvbDateUtil.endOfWeek(moment(this.ngModelCtrl.$viewValue));
    }

    public onDateChanged(): void {
        const newFirstDateOfMode = this.toFirstDateOfMode(this.datePicker.date);
        if (!newFirstDateOfMode.isSame(this.firstDateOfMode)) {
            this.debounceFn(() => {
                this.ngModelCtrl.$setViewValue(newFirstDateOfMode);
            });
        }
    }

    public setPrevious(): void {
        if (this.isDisabled) {
            return;
        }

        this.ngModelCtrl.$viewValue = this.shiftDate(-1);
        this.datePicker.date = this.ngModelCtrl.$viewValue.toDate();
        this.onDateChanged();
    }

    public hasPrevious(): boolean {
        return !DvbDateUtil.isValidMoment(this.gueltigAb) ||
            this.toFirstDateOfMode(this.gueltigAb)
                .isBefore(this.toFirstDateOfMode(this.ngModelCtrl.$viewValue), this.config.startOfType);
    }

    public setNext(): void {
        if (this.isDisabled) {
            return;
        }

        this.ngModelCtrl.$viewValue = this.shiftDate(1);
        this.datePicker.date = this.ngModelCtrl.$viewValue.toDate();
        this.onDateChanged();
    }

    public hasNext(): boolean {
        return !DvbDateUtil.isValidMoment(this.gueltigBis) ||
            this.toFirstDateOfMode(this.gueltigBis)
                .isAfter(this.toFirstDateOfMode(this.ngModelCtrl.$viewValue), this.config.startOfType);
    }

    public toggleDatePicker(): void {
        if (this.isDisabled) {
            return;
        }
        this.datePicker.isOpen = !this.datePicker.isOpen;
    }

    /**
     * @param {string} mode
     */
    private onModeChange(mode: DateSwitcherType): void {
        switch (mode) {
            case DateSwitcherType.YEAR:
                this.initYearMode();
                break;
            case DateSwitcherType.WEEK:
                this.initWeekMode();
                break;
            case DateSwitcherType.MONTH:
                this.initMonthMode();
                break;
            case DateSwitcherType.DAY:
                this.initDayMode();
                break;
            default:
                throw new Error(`Not supported: ${mode}`);
        }
    }

    private initYearMode(): void {
        this.datePickerOptions.minMode = 'year';
        this.datePickerOptions.maxMode = 'year';
        this.datePickerOptions.datepickerMode = 'year';
        this.config.offsetType = 'years';
        this.config.startOfType = 'year';
    }

    private initWeekMode(): void {
        this.datePickerOptions.minMode = 'day';
        this.datePickerOptions.maxMode = 'year';
        this.datePickerOptions.datepickerMode = 'day';
        this.config.offsetType = 'weeks';
        this.config.startOfType = 'isoWeek';
    }

    private initMonthMode(): void {
        this.datePickerOptions.minMode = 'month';
        this.datePickerOptions.maxMode = 'year';
        this.datePickerOptions.datepickerMode = 'day';
        this.config.offsetType = 'months';
        this.config.startOfType = 'month';
    }

    private initDayMode(): void {
        this.datePickerOptions.minMode = 'day';
        this.datePickerOptions.maxMode = 'year';
        this.datePickerOptions.datepickerMode = 'day';
        this.config.offsetType = 'days';
        this.config.startOfType = 'day';
    }

    /**
     * Debouncing the date switcher:
     * 1. when the user clicks the first time, the XHR action is executed immediately
     * 2. when the user clicks within 1 second again, the XHR is issued after a timeout of 1 second
     * 3. succession clicks will bump the timeout to 1 second again, meaning the XHR action is only executed
     * after the user gives the mouse a break for 1 second
     */
    private debounceFn(doSwitch: FunctionType): void {
        if (moment().diff(this.lastChangedTimestamp) > this.debounce!) {
            doSwitch();
        } else {
            if (this.switchPromise) {
                this.$timeout.cancel(this.switchPromise);
            }
            this.switchPromise = this.$timeout(doSwitch, this.debounce);
        }

        this.lastChangedTimestamp = moment();
    }

    private shiftDate(offset: number): moment.Moment {
        const aMoment = moment(this.ngModelCtrl.$viewValue as Date).add(offset, this.config.offsetType!);

        return this.toFirstDateOfMode(aMoment);
    }

    /**
     * Converts the input depending on the display mode to the 'firstDateOfMode' date.
     * For mode 'week' this returns startOf isoWeek
     * For mode 'year' this returns the first of January
     */
    private toFirstDateOfMode(aMoment: moment.Moment | Date): moment.Moment {
        return moment(aMoment).startOf(this.config.startOfType).startOf('day');
    }
}

componentConfig.controller = DvbDateSwitcher;
angular.module('kitAdmin').component('dvbDateSwitcher', componentConfig);
