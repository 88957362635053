<div class="dvb-file-upload">
    <div ng-show="vm.ngModelCtrl.$modelValue">
        <div class="row selected-entity">

            <div class="col-xs-8 entity">
                <span ng-bind="vm.ngModelCtrl.$modelValue.filename"></span>
                <uib-progressbar max="100"
                                 value="vm.ngModelCtrl.$modelValue.progress"
                                 type="success"
                                 ng-show="vm.ngModelCtrl.$modelValue.isInProgress()">
                </uib-progressbar>
            </div>
            <div class="col-xs-4 edit">
                <a href="" ng-click="vm.reset()" data-translate="COMMON.LOESCHEN"></a>
            </div>

        </div>
    </div>

    <a href=""
       ngf-select="vm.upload(file, $invalidFiles)"
       ngf-drop="vm.upload(file, $invalidFiles)"
       ng-model="file"
       ngf-max-size="20MB"
       ng-if="!vm.ngModelCtrl.$modelValue">
        + <span data-translate="{{vm.labelUpload}}"></span>
    </a>
</div>
