<div class="dvb-rechnung-zahlung-erfassen row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2 data-translate="FAKTURA.ZAHLUNG_ERFASSEN"></h2>

        <form name="zahlungErfassenForm"
              ng-submit="vm.submit(zahlungErfassenForm)"
              novalidate>

            <div class="row">
                <div class="col-md-6">
                    <select uib-tooltip="{{'FAKTURA.ZAHLUNG_TYP' | translate}}"
                            tooltip-placement="top-left"
                            ng-options="type as ('FAKTURA.' + type | translate) for type in vm.types"
                            ng-model="vm.zahlung.type"
                            class="form-control"
                            required>
                        <option value="" class="hide" disabled selected data-translate="FAKTURA.ZAHLUNG_TYP"></option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.zahlung.datum"
                                               required
                                               ng-attr-placeholder="{{'COMMON.DATUM' | translate}}">
                    </dvb-datepicker-text-field>
                </div>
                <div class="col-md-6">
                    <input name="betrag"
                           type="number"
                           class="form-control"
                           ng-attr-placeholder="{{'COMMON.BETRAG_CHF' | translate}}"
                           uib-tooltip="{{'COMMON.BETRAG_CHF' | translate}}"
                           tooltip-placement="top-left"
                           dvb-currency-value
                           dvb-min-exclusive="0"
                           ng-model="vm.zahlung.betrag"
                           required>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                     <textarea ng-model="vm.zahlung.bemerkung"
                               ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                               uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                               tooltip-placement="top-left"
                               class="form-control"
                               msd-elastic
                               maxlength="1000"
                               rows="1"></textarea>
                </div>
            </div>

            <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                       on-cancel="vm.goBack()">
            </dvb-submit-cancel-buttons>
        </form>
    </div>
</div>
