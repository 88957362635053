/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, KinderOrt, StundenKontingent} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {FormContext} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {finalize, from, take, tap} from 'rxjs';
import type {StundenKontingenteByPlatzTyp} from '../../../kind/tarife/dvb-kind-tarife/dvb-kind-tarife';
import type {StundenKontingentService} from '../../service/stundenKontingentService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        history: '<',
        kind: '<',
        kinderOrt: '<',
        onDelete: '&',
    },
    template: require('./dvb-stunden-kontingent-history.html'),
    controllerAs: 'vm',
};

export class DvbStundenKontingentHistory implements angular.IController {
    public static $inject: readonly string[] = ['stundenKontingentService', 'dialogService'];

    public history: StundenKontingenteByPlatzTyp[] = [];
    public kind!: Kind;
    public kinderOrt!: KinderOrt;
    public onDelete!: (data: { stundenKontingent: StundenKontingent; platzTypEntry: StundenKontingenteByPlatzTyp })
        => void;

    public today: moment.Moment = DvbDateUtil.today();

    public constructor(
        private readonly stundenKontingentService: StundenKontingentService,
        private readonly dialogService: DialogService,
    ) {
    }

    public update(update: StundenKontingent, orig: StundenKontingent, formContext: FormContext): void {
        formContext.startLoading();

        this.stundenKontingentService.update(update)
            .then(() => {
                angular.copy(update, orig);
                formContext.close();
            })
            .finally(() => {
                formContext.finishLoading();
            });
    }

    public confirmDeleteStundenKontingent(
        stundenKontingent: StundenKontingent,
        platzTypEntry: StundenKontingenteByPlatzTyp,
        formContext: FormContext,
    ): void {
        this.dialogService.openDeleteDialog({
            entityText: 'KIND.STUNDENKONTINGENT',
            confirm: () =>
                from(this.stundenKontingentService.delete(checkPresent(stundenKontingent.id)))
                    .pipe(
                        take(1),
                        tap(() => {
                            DvbUtil.removeFromArray(stundenKontingent, platzTypEntry.stundenKontingente);
                            this.onDelete({stundenKontingent, platzTypEntry});
                        }),
                        finalize(() => {
                            formContext.finishLoading();
                        }),
                    ),
        });
    }

    private deleteStundenKontingent(
        stundenKontingent: StundenKontingent,
        platzTypEntry: StundenKontingenteByPlatzTyp,
        formContext: FormContext,
    ): angular.IPromise<void> {
        formContext.startLoading();

        return this.stundenKontingentService.delete(checkPresent(stundenKontingent.id))
            .then(() => {
                DvbUtil.removeFromArray(stundenKontingent, platzTypEntry.stundenKontingente);
                this.onDelete({stundenKontingent, platzTypEntry});
            })
            .finally(() => {
                formContext.finishLoading();
            });
    }
}

componentConfig.controller = DvbStundenKontingentHistory;
angular.module('kitAdmin').component('dvbStundenKontingentHistory', componentConfig);
