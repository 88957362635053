import {Injectable} from '@angular/core';
import type {
    PersonalKonfigurationServiceTerminateRequestParams,
} from '@dv/shared/backend/api/personal-konfiguration.service';
import {PersonalKonfigurationService} from '@dv/shared/backend/api/personal-konfiguration.service';
import type {Persisted, TerminationMode} from '@dv/shared/code';
import {DvbRestUtil, TimeRange} from '@dv/shared/code';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {PersonalKonfiguration} from './PersonalKonfiguration';

@Injectable({
    providedIn: 'root',
})
export class PersonalKonfigurationApiService {

    public constructor(
        private readonly api: PersonalKonfigurationService,
    ) {
    }

    public getForMandant$(): Observable<PersonalKonfiguration[]> {
        return this.api.getForMandant$().pipe(
            map(values => DvbRestUtil.transformArray(values.items, PersonalKonfiguration)),
        );
    }

    public getForKinderOrt$(kinderOrtId: string): Observable<PersonalKonfiguration[]> {
        return this.api.getForKinderOrt$({id: kinderOrtId, idMatrix: {}}).pipe(
            map(values => DvbRestUtil.transformArray(values.items, PersonalKonfiguration)),
        );
    }

    public getArbeitszeitForKinderOrtAndDate$(
        kinderOrtId: string,
        date: moment.Moment,
    ): Observable<TimeRange> {
        return this.api.getArbeitszeitForKinderOrtAndDate$({
            id: kinderOrtId,
            idMatrix: {stichtag: DvbRestUtil.momentToLocalDateChecked(date)},
        }).pipe(map(value => TimeRange.apiResponseTransformer(value)));
    }

    public create$(konfiguration: PersonalKonfiguration): Observable<PersonalKonfiguration> {
        return this.api.create$({jaxPersonalKonfiguration: konfiguration.toRestObject()}).pipe(
            map(value => PersonalKonfiguration.apiResponseTransformer(value)),
        );
    }

    public terminate$(
        endDate: moment.Moment,
        mode: TerminationMode,
        kinderOrtId?: string,
    ): Observable<unknown> {
        const params: PersonalKonfigurationServiceTerminateRequestParams = {
            enddate: {mode, kinderOrtId}, jaxLocalDate: {date: DvbRestUtil.momentToLocalDateChecked(endDate)},
        };

        return this.api.terminate$(params);
    }

    public extend$(konfiguration: Persisted<PersonalKonfiguration>): Observable<unknown> {
        return this.api.extend$({konfigurationId: konfiguration.id});
    }

    public delete$(konfiguration: Persisted<PersonalKonfiguration>): Observable<unknown> {
        // eslint-disable-next-line no-underscore-dangle
        return this.api._delete$({konfigurationId: konfiguration.id});
    }
}
