/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {UserRole} from '@dv/shared/roles';
import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import type {IPromise} from 'angular';
import angular from 'angular';
import {trans} from '../common/i18n/trans';

angular.module('kitAdmin').config(mandantConfig);

export * from './component/dvb-kitadmin-module/dvb-kitadmin-module';
export * from './component/dvb-mandant-erstellen/dvb-mandant-erstellen';
export * from './component/dvb-mandant-profil/dvb-mandant-profil';
export * from './component/dvb-mandant-rechnung-config/dvb-mandant-rechnung-config';
export * from './component/dvb-mandant-sprachauswahl/dvb-mandant-sprachauswahl';
export * from './component/dvb-subscription-model/dvb-subscription-model';
export * from './component/faktura/dvb-mandant-konfiguration-form/dvb-mandant-konfiguration-form';
export * from './component/faktura/dvb-rechnungs-konfiguration-form/dvb-rechnungs-konfiguration-form';
export * from './component/faktura/dvb-rechnungs-konfigurationen/dvb-rechnungs-konfigurationen';
export * from './component/faktura/dvb-small-invoice-mandant-konfiguration-form/dvb-small-invoice-mandant-konfiguration-form';
// Add New Component JS Above

mandantConfig.$inject = ['$stateProvider'];

function mandantConfig($stateProvider: StateProvider): void {
    $stateProvider.state('base.mandant-erstellen', {
        url: '/neuer-mandant',
        template: '<dvb-mandant-erstellen></dvb-mandant-erstellen>',
        data: {
            role: UserRole.MANDANT_ADMIN,
        },
        resolve: {
            $title: [
                '$translate', ($translate: angular.translate.ITranslateService): IPromise<string> =>
                    trans($translate, 'COMMON.MANDANT.ERSTELLEN'),
            ],
        },
    });
    /* Add New States Above */

}
