/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRechnungsKonfiguration} from '@dv/kitadmin/models';
import {
    FakturaStatuses,
    KitaOffenePosten,
    KitaRechnungen,
    KitaRechnungsKonfiguration,
    KitaRechnungsKonfigurationEditStats,
    RechnungsLaufPdf,
    TempBlob,
} from '@dv/kitadmin/models';
import type {ApiResponseTransformer, RestInclude, RestLimited, RestPaginated} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, isPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../../config';
import type {UserSettingsMemoizer} from '../../../../cache/service/cache/UserSettingsMemoizer';
import type {UserSettingsStore} from '../../../../cache/service/cache/userSettingsStore';
import {RechnungenFilter} from '../../../../filter/RechnungenFilter';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export type OffenePostenIncludes = {
    zahlungenGueltigBis?: string;
    periodeGueltigBis?: string;
};

export class KitaFakturaService {
    public static $inject: readonly string[] = ['$http', 'userSettingsStore'];

    private static itemsPerPage: Readonly<number> = 10;
    private static kitaBaseUrl: Readonly<string> = `${CONFIG.restBackend}/api/v1/kinderorte`;

    private kitaRechnungenFilter: UserSettingsMemoizer<'KITA_FAKTURA_RECHNUNGEN_FILTER'>;

    public constructor(
        private $http: angular.IHttpService,
        private userSettingsStore: UserSettingsStore,
    ) {
        this.kitaRechnungenFilter = this.userSettingsStore.initMemoizer('KITA_FAKTURA_RECHNUNGEN_FILTER',
            () => new RechnungenFilter());
    }

    public getRechnungenFilter(kitaId: string): RechnungenFilter {
        return this.kitaRechnungenFilter.get(kitaId);
    }

    public setRechnungenFilter(filter: RechnungenFilter, kitaId: string): void {
        this.kitaRechnungenFilter.set(kitaId, filter);
    }

    public setRechnungenProSeite(rechnungenProSeite: number): void {
        this.userSettingsStore.put('KITA_FAKTURA_ITEMS_PER_PAGE', rechnungenProSeite);
    }

    public getRechnungenProSeite(): number {
        const count = this.userSettingsStore.get('KITA_FAKTURA_ITEMS_PER_PAGE');
        if (count !== undefined) {
            return count;
        }

        this.setRechnungenProSeite(KitaFakturaService.itemsPerPage);

        return KitaFakturaService.itemsPerPage;
    }

    /**
     * Get all KitaRechnungsKonfiguration.
     */
    public getRechnungsKonfigurationen<T extends IRechnungsKonfiguration>(
        kitaId: string,
    ): angular.IPromise<KitaRechnungsKonfiguration<T>[]> {

        const transformer: ApiResponseTransformer<KitaRechnungsKonfiguration<T>> = {
            apiResponseTransformer(data: any): KitaRechnungsKonfiguration<T> {
                return KitaRechnungsKonfiguration.apiResponseTransformer<T>(data);
            },
        };

        return DvbRestUtilAngularJS.getModelsArray(
            `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/rechnungskonfigurationen`,
            transformer,
            'konfigurationen');
    }

    public createRechnungsKonfiguration<T extends IRechnungsKonfiguration>(
        rechnungsKonfiguration: KitaRechnungsKonfiguration<T>,
    ): angular.IPromise<unknown> {

        const kitaId = checkPresent(rechnungsKonfiguration.kitaId);
        const dtype = checkPresent(rechnungsKonfiguration.dtype);

        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/` +
            `rechnungskonfigurationen/${dtype.toLowerCase()}`;

        return this.$http.post(url, rechnungsKonfiguration.toRestObject());
    }

    public updateRechnungsKonfiguration<T extends IRechnungsKonfiguration>(
        rechnungsKonfiguration: KitaRechnungsKonfiguration<T>): angular.IPromise<unknown> {

        const dtype = checkPresent(rechnungsKonfiguration.dtype);
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KitaFakturaService.kitaBaseUrl}/rechnungskonfigurationen/` +
            `${rechnungsKonfiguration.id}/${dtype.toLowerCase()}`;

        return this.$http.put(url, rechnungsKonfiguration.toRestObject());
    }

    public deleteKitaRechnungsKonfiguration(rechnungsKonfigurationId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${KitaFakturaService.kitaBaseUrl}/rechnungskonfigurationen/` +
            `${encodeURIComponent(rechnungsKonfigurationId)}`;

        return this.$http.delete(url);
    }

    public getAffectedRechnungenByRechnungsKonfiguration<T extends IRechnungsKonfiguration>(
        rechnungsKonfiguration: KitaRechnungsKonfiguration<T>,
    ): angular.IPromise<KitaRechnungsKonfigurationEditStats> {
        const url = `${KitaFakturaService.kitaBaseUrl}/rechnungskonfigurationen/${rechnungsKonfiguration.id}/affected`;

        const restObject = rechnungsKonfiguration.toRestObject();

        return DvbRestUtilAngularJS.postAndGetModel(url, restObject, KitaRechnungsKonfigurationEditStats);
    }

    /**
     * gueltigAb & gueltigBis are optional. When both are set, then only Rechnungen within gueltigAb & gueltigBis
     * are returned.
     */
    public getRechnungen(
        kitaId: string,
        params?: RestInclude & RestLimited & RestPaginated & { states?: string[] },
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<KitaRechnungen> {

        const url = `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/rechnungen`;
        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KitaRechnungen, matrixParams, config)
            .then(checkPresent);
    }

    public getTempBlobForZipRechnungenPDF(kitaId: string, rechnungsIds: string[]): angular.IPromise<TempBlob> {
        const rechnungsLaufPdf = new RechnungsLaufPdf();
        rechnungsLaufPdf.rechnungsIds = rechnungsIds;
        const url = `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/rechnungen/pdf`;

        return DvbRestUtilAngularJS.postAndGetModel(url, rechnungsLaufPdf, TempBlob);
    }

    /**
     * gueltigAb & gueltigBis are optional. When both are set, then only Rechnungen within gueltigAb & gueltigBis
     * are returned.
     */
    public getTempBlobForMergedRechnungenPDFFilter(kitaId: string, params?: RestLimited & {
        states?: string[];
        kitaIds?: string[];
        kontaktpersonenIds?: string[];
        abgelaufen?: boolean;
    }): angular.IPromise<TempBlob> {

        const url = `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/rechnungen/pdf/filter`;
        const matrixParams = isPresent(params) ? DvbRestUtil.toMatrixParams(params) : {};

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, TempBlob, matrixParams)
            .then(checkPresent);
    }

    public getFakturaStatuses(kitaId: string, params?: RestLimited): angular.IPromise<FakturaStatuses> {
        const url = `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/faktura`;
        const matrixParams = DvbRestUtil.setGueltigkeitParams({}, params);

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, FakturaStatuses, matrixParams)
            .then(checkPresent);
    }

    public getUnsettledOffenePosten(
        kitaId: string,
        params?: OffenePostenIncludes,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<KitaOffenePosten> {
        const url = `${KitaFakturaService.kitaBaseUrl}/${encodeURIComponent(kitaId)}/offeneposten/unsettled`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KitaOffenePosten, params, config)
            .then(checkPresent);
    }
}
