/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entry: '<',
    },
    template: require('./dvb-bank-statement-entry-content.html'),
    controllerAs: 'vm',
};

export class DvbBankStatementEntryContent implements angular.IController {

    public static $inject: readonly string[] = ['$translate'];

    public entry!: BankStatementEntry;

    public constructor(private readonly $translate: angular.translate.ITranslateService) {
    }

    public getAssignmentTooltipText(): string {
        const key = this.entry.automaticAssignment ?
            'FAKTURA.KONTENABGLEICH.AUTOMATISCH_ZUGEWIESEN_DURCH' :
            'FAKTURA.KONTENABGLEICH.MANUELL_ZUGEWIESEN_DURCH';

        return this.$translate.instant(key, {benutzer: this.entry.benutzerDisplayName});
    }
}

componentConfig.controller = DvbBankStatementEntryContent;
angular.module('kitAdmin').component('dvbBankStatementEntryContent', componentConfig);
