/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt} from '@dv/kitadmin/models';
import {KinderOrtTransformer} from '@dv/kitadmin/models';
import type {IPersistable, Persisted} from '@dv/shared/code';
import {DvbRestUtil, DvbUtil} from '@dv/shared/code';

export class EmailTemplate implements IPersistable {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public content: string | null = null,
        public subject: string = '',
        public userErstelltId: string | null = null,
        public alleKinderOrte: boolean = true,
        public kinderOrte: Persisted<KinderOrt>[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): EmailTemplate {
        return new EmailTemplate(
            data.id,
            data.name,
            data.content,
            data.subject,
            data.userErstelltId,
            data.alleKinderOrte,
            DvbRestUtil.transformArray(data.kinderOrte, KinderOrtTransformer.create()),
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            content: this.content,
            subject: this.subject,
            alleKinderOrte: this.alleKinderOrte,
            kinderOrtIds: this.alleKinderOrte ? [] : this.kinderOrte.map(DvbUtil.mapToIdChecked),
        };
    }
}
