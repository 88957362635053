<div class="dvb-fruehspaetdienst-report">
    <h2 data-translate="REPORT.FRUEH_SPAET_DIENST.TITLE"></h2>

    <form name="vm.fruehSpaetDienstReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                      ng-model="vm.kinderOrt"
                                      ng-change="vm.updateKinderOrt()"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                      required>
        </dvb-search-get-single-entity>

        <dvb-datepicker-text-field ng-model="vm.params.stichtag"
                                   ng-change="vm.updateStichtag()"
                                   ng-attr-placeholder="{{'COMMON.DATUM' | translate}}: {{vm.params.stichtag || vm.defaultDate | amDateFormat: 'DD.MM.YYYY'}}">
        </dvb-datepicker-text-field>

        <div class="row form-row">
            <div class="col-xs-12">
                <input type="text"
                       ng-model="vm.params.fruehDienstBis"
                       dvb-select-on-click
                       dvb-time-input
                       maxlength="10"
                       class="form-control no-margin"
                       uib-tooltip="{{'REPORT.FRUEH_SPAET_DIENST.FRUEH_BIS' | translate}}: {{vm.params.fruehDienstBis || vm.defaultFruehBis | amDateFormat: 'HH:mm'}}"
                       ng-attr-placeholder="{{'REPORT.FRUEH_SPAET_DIENST.FRUEH_BIS' | translate}}: {{vm.defaultFruehBis | amDateFormat: 'HH:mm'}}"
                       tooltip-placement="top-left">
            </div>
        </div>

        <div class="row form-row">
            <div class="col-xs-12">
                <input type="text"
                       ng-model="vm.params.spaetDienstVon"
                       dvb-select-on-click
                       dvb-time-input
                       maxlength="10"
                       class="form-control no-margin"
                       uib-tooltip="{{'REPORT.FRUEH_SPAET_DIENST.SPAET_VON' | translate}}: {{vm.params.spaetDienstVon || vm.defaultSpaetVon | amDateFormat: 'HH:mm'}}"
                       ng-attr-placeholder="{{'REPORT.FRUEH_SPAET_DIENST.SPAET_VON' | translate}}: {{vm.defaultSpaetVon | amDateFormat: 'HH:mm'}}"
                       tooltip-placement="top-left">
            </div>
        </div>

        <dvb-report-download on-submit="vm.submit()"></dvb-report-download>
    </form>
</div>
