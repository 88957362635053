/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

angular.module('kitAdmin').directive('layout', layout);

function layout(): angular.IDirective {
    return {
        restrict: 'A',
        link: (_scope, _element, attrs): void => {
            switch (attrs.layout) {
                case 'column':
                    attrs.$addClass('layout-column');
                    break;
                case 'row':
                    attrs.$addClass('layout-row');
                    break;
                default:
                    throw new Error(`${JSON.stringify(attrs.layout)} is not a valid layout`);
            }
        },
    };
}
