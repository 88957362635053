/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DokumentLayout} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import type {SteuerbescheinigungStructure} from '../../../report/models/ParameterSteuerbescheinigungen';
import * as states from '../../communication-states';
import type {CommunicationStateService} from '../../service/communication-state-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrtSearchResult: '<',
        gueltigAb: '<',
        dokumentLayout: '<',
    },
    template: require('./dvb-steuerbescheinigung-form.html'),
    controllerAs: 'vm',
};

export class DvbSteuerbescheinigungForm implements angular.IController {
    public static $inject: readonly string[] = ['$state', 'communicationStateService', 'errorService'];

    public kinderOrtSearchResult: SearchResultEntry | null = null;
    public gueltigAb: moment.Moment | null = null;
    public dokumentLayout: DokumentLayout | null = null;

    public structure: SteuerbescheinigungStructure = 'MONTHLY';
    public defaultDate: moment.Moment = DvbDateUtil.startOfYear(moment());
    public isLoading: boolean = false;

    public constructor(
        private $state: StateService,
        private stateService: CommunicationStateService,
        private errorService: ErrorService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.gueltigAb && DvbDateUtil.isValidMoment(changes.gueltigAb.currentValue)) {
            // make sure gueltigAb is always set to start of month
            this.gueltigAb = DvbDateUtil.startOfMonth(changes.gueltigAb.currentValue);
        }
    }

    public submit(valid: boolean): void {
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        this.isLoading = true;
        this.$state.go(states.STEUERBESCHEINIGUNG_MANAGEMENT_STATE.name, {
            kinderOrtId: this.kinderOrtSearchResult!.id,
            gueltigAb: this.gueltigAb ? this.gueltigAb : this.defaultDate,
            dokumentLayoutId: this.dokumentLayout?.id ?? null,
            structure: this.structure,
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public updateState(): void {
        this.stateService.updateStateParams(this.kinderOrtSearchResult,
            this.gueltigAb,
            this.dokumentLayout);
    }

    public updateDokumentLayout(dokumentLayout: DokumentLayout): void {
        this.dokumentLayout = dokumentLayout;
        this.stateService.updateStateParams(this.kinderOrtSearchResult,
            this.gueltigAb,
            dokumentLayout);
    }
}

componentConfig.controller = DvbSteuerbescheinigungForm;
angular.module('kitAdmin').component('dvbSteuerbescheinigungForm', componentConfig);
