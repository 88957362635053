/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export interface OnChangeListener {
    onFilterChanged: () => void;
}

export class FilterModelChangeHandler {

    public constructor(
        public readonly listeners: OnChangeListener[] = [],
        public hasPendingNotification: boolean = false,
    ) {
    }

    public scheduleChangeNotification(): void {
        this.hasPendingNotification = true;
    }

    public notify(): void {
        this.listeners.forEach(l => l.onFilterChanged());
        this.hasPendingNotification = false;
    }
}
