/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntitySearchType} from '@dv/shared/code';
import {DvbUtil, SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import type {MandantSearchFilter} from '../../model/MandantSearchFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        entries: '<ngModel',
        orderByText: '<?',
        entityToSearch: '@',
        placeholderKey: '@',
        entitiesToSearchFrom: '<?',
        mandantFilter: '<?',
        onAdded: '&',
        onRemoved: '&',
    },
    template: require('./dvb-search-list.html'),
    controllerAs: 'vm',
};

export class DvbSearchList implements angular.IController {
    public static $inject: readonly string[] = [];

    public placeholderKey: string = '';
    public entityToSearch!: EntitySearchType;
    public entries: SearchResultEntry[] = [];
    public entitiesToSearchFrom?: SearchResultEntry[];
    public orderByText?: boolean = false;
    public mandantFilter?: MandantSearchFilter;

    public onAdded!: (props: { item: SearchResultEntry }) => void;
    public onRemoved!: (props: { item: SearchResultEntry }) => void;

    public readonly ngModelCtrl!: angular.INgModelController;

    public $onInit(): void {
        // an empty array is considered empty, such that required forces at least 1 entry
        this.ngModelCtrl.$isEmpty = (value): boolean => !DvbUtil.isNotEmptyArray(SearchResultEntry.getEnabled(value));

        // allowInvalid, such that the input array does not get set to undefined
        this.ngModelCtrl.$overrideModelOptions({allowInvalid: true});
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.entries) {
            this.sortEntriesIfRequired();
        }
    }

    public onSelect(item: SearchResultEntry): void {
        if (this.entries.some(a => a.id === item.id)) {
            return;
        }

        this.entries.push(item);
        this.sortEntriesIfRequired();
        this.ngModelCtrl.$validate();
        this.onAdded({item});
    }

    public onRemove(item: SearchResultEntry): void {
        DvbUtil.removeFromArray(item, this.entries);
        this.ngModelCtrl.$validate();
        this.onRemoved({item});
    }

    private sortEntriesIfRequired(): void {
        if (this.orderByText) {
            this.entries.sort((a, b) => a.text.localeCompare(b.text));
        }
    }
}

componentConfig.controller = DvbSearchList;
angular.module('kitAdmin').component('dvbSearchList', componentConfig);
