<div class="dvb-firmen-kontakt-erstellen">
    <form name="createFirmenKontaktForm" ng-submit="vm.submit(createFirmenKontaktForm)" novalidate>
        <h2 data-translate="COMMON.KONTAKT"></h2>
        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       name="kontaktFamilienName"
                       maxlength="255"
                       ng-model="vm.firmenKontakt.familienName"
                       ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                       uib-tooltip="{{'COMMON.NAME' | translate}}"
                       tooltip-placement="top-left">
            </div>
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       name="kontaktVorName"
                       maxlength="255"
                       ng-model="vm.firmenKontakt.vorName"
                       ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                       uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                       tooltip-placement="top-left">
            </div>
        </div>

        <dvb-adresse-form ng-model="vm.firmenKontakt.adresse"></dvb-adresse-form>

        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       name="kontaktTelefon"
                       maxlength="100"
                       ng-model="vm.firmenKontakt.telefon"
                       ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                       uib-tooltip="{{'COMMON.TELEFON' | translate}}"
                       tooltip-placement="top-left"
                       required
                       autocomplete="tel">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input type="email"
                       class="form-control"
                       name="kontaktEmail"
                       maxlength="255"
                       ng-model="vm.firmenKontakt.email"
                       ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                       uib-tooltip="{{'COMMON.E-MAIL' | translate}}"
                       tooltip-placement="top-left"
                       autocomplete="email">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       class="form-control"
                       name="kontaktWebsite"
                       maxlength="255"
                       ng-model="vm.firmenKontakt.website"
                       dvb-protocol-prepender
                       ng-attr-placeholder="{{'COMMON.WEBSITE' | translate}}"
                       uib-tooltip="{{'COMMON.WEBSITE' | translate}}"
                       tooltip-placement="top-left"
                       autocomplete="url">
            </div>
        </div>
        <div class="big-abstand-top">
            <dvb-submit-cancel-buttons on-cancel="vm.onCancel()">
            </dvb-submit-cancel-buttons>
        </div>
    </form>
</div>
