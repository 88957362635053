/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {VerpflegungsTyp} from '@dv/shared/backend/model/verpflegungs-typ';
import type {BetreuungsZeitraum, FunctionType, IZeitraum} from '@dv/shared/code';
import {FormContext, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsZeitraum: '<',
        showVerpflegungen: '<?',
        onCancel: '&?',
        onSubmit: '&?',
    },
    template: require('./dvb-zeitraum-form.html'),
    controllerAs: 'vm',
};

export class DvbZeitraumForm implements angular.IController {
    public static $inject: readonly string[] = ['errorService'];

    public betreuungsZeitraum!: IZeitraum;
    public showVerpflegungen: boolean = true;
    public onCancel?: FunctionType;
    public onSubmit?: (params: { zeitraum: IZeitraum; context: FormContext }) => any;

    public workingCopy!: BetreuungsZeitraum;
    public verpflegungTypes: typeof VerpflegungsTyp = VerpflegungsTyp;
    public readonly formContext: FormContext = new FormContext();

    public constructor(private readonly errorService: ErrorService) {
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.betreuungsZeitraum) {
            this.workingCopy = angular.copy(onChangesObj.betreuungsZeitraum.currentValue);
        }
    }

    public submit(form: angular.IFormController): void {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!form.$valid) {
            return;
        }

        if (angular.equals(this.workingCopy, this.betreuungsZeitraum)) {
            if (TypeUtil.isFunction(this.onCancel)) {
                this.onCancel();
            }

            return;
        }

        if (TypeUtil.isFunction(this.onSubmit)) {
            this.onSubmit({zeitraum: this.workingCopy, context: this.formContext});
        }
    }
}

componentConfig.controller = DvbZeitraumForm;
angular.module('kitAdmin').component('dvbZeitraumForm', componentConfig);
