<div class="dvb-timeline-entry-details">
    <div class="date-time">
        <span ng-bind="vm.timelineDate"></span>
        <span class="small-abstand-left" ng-bind="vm.timelineTime"></span>
        <i class="fa fa-ellipsis-h smaller-abstand-left" uib-tooltip="{{vm.tootlipText}}"></i>
        <dvb-attachments-download ng-if="vm.entry.blobInfos.length > 0"
                                  entry="vm.entry"
                                  timeline-controller="vm.timelineController"
                                  class="smaller-abstand-left">
        </dvb-attachments-download>
    </div>

    <ng-transclude></ng-transclude>
</div>
