<div class="dvb-external-anmeldung-kinder">

    <div ng-repeat="kind in vm.kinder">
        <dvb-external-anmeldung-kind kind="kind" index="$index">
        </dvb-external-anmeldung-kind>

        <dvb-external-anmeldung-betreuung kind="kind"
                                          selected-wochenplan="kind.selectedWochenplan"
                                          setup="vm.setup"
                                          on-kinder-orte-changed="vm.kinderOrteChanged(kind)">
        </dvb-external-anmeldung-betreuung>

        <button type="button"
                class="btn btn-link big-abstand-bottom"
                ng-disabled="vm.kinder.length <= 1"
                ng-click="vm.removeChild(kind)"
                data-translate="EXTERNAL_ANMELDUNG.REMOVE_CHILD"
                data-translate-value-name="{{kind.getDisplayName()}}">
        </button>
    </div>
    <button type="button"
            class="btn btn-link big-abstand-bottom"
            data-translate="EXTERNAL_ANMELDUNG.ADD_CHILD"
            ng-click="vm.onAddChild()">
    </button>
</div>
