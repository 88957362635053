/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {FunctionType} from '@dv/shared/code';
import {Ausbildung} from '../../../anstellung/models/Ausbildung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        ausbildung: '<',
        onDelete: '&',
        onDeleted: '&',
    },
    template: require('./dvb-ausbildung-node.html'),
    controllerAs: 'vm',
};

export class DvbAusbildungNode implements angular.IController {
    public static $inject: readonly string[] = ['angestellteService', '$state'];
    public ausbildung!: Ausbildung;
    public onDelete!: FunctionType;
    public onDeleted!: (data: { ausbildungIds: string[] }) => void;

    public new(): void {
        this.ausbildung.children.push(new Ausbildung(null, null, this.ausbildung.id));
    }

    public delete(child: Ausbildung): void {
        this.ausbildung.removeChild(child);
        this.onDeleted({ausbildungIds: child.findChildIds()});
    }
}

componentConfig.controller = DvbAusbildungNode;
angular.module('kitAdmin').component('dvbAusbildungNode', componentConfig);
