/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {BetreuungsSchluessel} from '../../models/BetreuungsSchluessel';
import type {FraktionBetreuungsSchluessel} from '../../models/FraktionBetreuungsSchluessel';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entity: '<',
        betreuungsSchluessel: '<',
        disableGueltigkeit: '<?',
    },
    template: require('./dvb-betreuungs-schluessel-assignment-form.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsSchluesselAssignmentForm implements angular.IController {
    public static $inject: readonly string[] = [];

    public entity!: FraktionBetreuungsSchluessel;
    public betreuungsSchluessel: BetreuungsSchluessel[] = [];
    public disableGueltigkeit: boolean = false;
}

componentConfig.controller = DvbBetreuungsSchluesselAssignmentForm;
angular.module('kitAdmin').component('dvbBetreuungsSchluesselAssignmentForm', componentConfig);
