<div class="dvb-gruppe-main">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-cube header-icon"
               uib-tooltip="{{'COMMON.GRUPPE.SINGULAR' | translate}}"
               tooltip-placement="top-left">
            </i>

            <div class="col-xs-12 header-box">
                <dvb-gruppe-header gruppe="vm.gruppe"
                                   aktuelle-wochen-kapazitaet="vm.aktuelleWochenKapazitaet">
                </dvb-gruppe-header>
            </div>
        </div>
    </div>

    <ui-view></ui-view>
</div>
