/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsGutschein, BetreuungsGutscheinGroup, KinderOrt} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        betreuungsGutscheineGroups: '<',
        onUpdate: '&',
        onDelete: '&',
    },
    template: require('./dvb-kind-tarife-betreuungsgutscheine.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeBetreuungsgutscheine implements angular.IController {

    public kita!: Persisted<KinderOrt>;
    public betreuungsGutscheineGroups: BetreuungsGutscheinGroup[] = [];
    public onUpdate!: (data: { betreuungsGutschein: BetreuungsGutschein }) => unknown;
    public onDelete!: (data: { betreuungsGutscheinGroup: BetreuungsGutscheinGroup }) => unknown;

    public today = DvbDateUtil.today();
}

componentConfig.controller = DvbKindTarifeBetreuungsgutscheine;
angular.module('kitAdmin').component('dvbKindTarifeBetreuungsgutscheine', componentConfig);
