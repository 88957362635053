/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {ANSTELLUNG_STATES} from './anstellung/anstellung-states';
import {PERSONAL_STATES} from './personal-states';

angular.module('kitAdmin').config(personalConfig);

export * from './anstellung/component/dvb-angestellte-anstellung-list/dvb-angestellte-anstellung-list';
export * from './anstellung/component/dvb-angestellte-anstellung/dvb-angestellte-anstellung';
export * from './anstellung/component/dvb-angestellte-erstellen/dvb-angestellte-erstellen';
export * from './anstellung/component/dvb-angestellte-main/dvb-angestellte-main';
export * from './anstellung/component/dvb-angestellte-profil/dvb-angestellte-profil';
export * from './anstellung/component/dvb-angestellte-stundenerfassung/dvb-angestellte-stundenerfassung';
export * from './anstellung/component/dvb-anstellung-verlauf-item/dvb-anstellung-verlauf-item';
export * from './anstellung/models/Angestellte';
export * from './anstellung/models/Anstellung';
export * from './anstellung/models/Ausbildung';
export * from './anstellung/models/DailyAnstellung';
export * from './anstellung/service/angestellteService';
export * from './anstellung/service/anstellungService';
export * from './anstellung/service/ausbildungService';
export * from './anstellung/service/kindBetreuungErfassungService';

export * from './ausbildungen/component/dvb-ausbildungen-main/dvb-ausbildungen-main';
export * from './ausbildungen/component/dvb-ausbildung-node/dvb-ausbildung-node';

export * from './bedarf/models/DienstBedarf';
export * from './bedarf/models/FraktionsBedarf';
export * from './bedarf/models/KinderOrtBedarf';
export * from './bedarf/service/personalBedarfService';

export * from './betreuungs-schluessel/component/dvb-betreuungs-schluessel-assignment/dvb-betreuungs-schluessel-assignment';
export * from './betreuungs-schluessel/component/dvb-betreuungs-schluessel-assignment-form/dvb-betreuungs-schluessel-assignment-form';
export * from './betreuungs-schluessel/models/AusbildungsPersonalBedarf';
export * from './betreuungs-schluessel/models/BetreuungsSchluessel';
export * from './betreuungs-schluessel/models/BetreuungsSchluesselRegel';
export * from './betreuungs-schluessel/models/BetreuungsSchluesselRegelBedarf';
export * from './betreuungs-schluessel/service/betreuungsSchluesselService';

export * from './component/dvb-ausbildungen/dvb-ausbildungen';
export * from './component/dvb-betreuungs-schluessel-overview/dvb-betreuungs-schluessel-overview';
export * from './component/dvb-betreuungs-schluessel-regel/dvb-betreuungs-schluessel-regel';
export * from './component/dvb-betreuungs-schluessel/dvb-betreuungs-schluessel';
export * from './component/dvb-personal-main/dvb-personal-main';
export * from './component/dvb-stunden-blaetter/dvb-stunden-blaetter';
export * from './component/konfiguration/dvb-personal-konfiguration/dvb-personal-konfiguration';
export * from './component/konfiguration/dvb-personal-konfiguration-form/dvb-personal-konfiguration-form';
export * from './component/konfiguration/dvb-personal-konfiguration-header/dvb-personal-konfiguration-header';
export * from './component/konfiguration/dvb-personal-konfiguration-main/dvb-personal-konfiguration-main';

export * from './konfiguration/PersonalKonfiguration';

export * from './termin/dvb-termin-header/dvb-termin-header';
export * from './termin/dvb-termine/dvb-termine';
export * from './termin/terminService';
// Add New Component JS Above

personalConfig.$inject = ['$stateProvider'];

function personalConfig($stateProvider: StateProvider): void {
    Object.values(PERSONAL_STATES).forEach(state => $stateProvider.state(state));
    Object.values(ANSTELLUNG_STATES).forEach(state => $stateProvider.state(state));
}
