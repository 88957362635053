/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type angular from 'angular';

export abstract class AbstractFilterRepository {

    protected filterChanged: boolean = false;

    protected constructor(
        protected $rootScope: angular.IRootScopeService,
    ) {
    }

    public resetFilter(): void {
        this.filterModelChanged();
    }

    protected filterModelChanged(): void {
        if (this.filterChanged) {
            return;
        }

        this.filterChanged = true;
        this.$rootScope.$evalAsync(() => {
            this.$rootScope.$broadcast(this.getFilterChangedEventName());
            this.filterChanged = false;
        });
    }

    protected abstract getFilterChangedEventName(): string;
}
