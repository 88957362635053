<dvb-accordion is-first="false">
    <panel-header class="full-width-block">
        <div class="row">
            <div class="col-xs-6">
                <p data-translate="EXTERNAL_ANMELDUNG.CUSTOM_FIELDS.TITLE"></p>
            </div>
            <div class="col-xs-6 text-right">
                <p data-translate="COMMON.CUSTOM_FIELD.MF"
                   data-translate-value-count="{{vm.customFields.length || 0}}"
                   data-translate-value-showcount="true"
                   data-translate-interpolation="messageformat">
                </p>
            </div>
        </div>
    </panel-header>
    <panel-content>
        <form name="customFieldsForm" novalidate ng-submit="vm.onSubmit(customFieldsForm)">
            <h3 data-translate="EXTERNAL_ANMELDUNG.CUSTOM_FIELDS.TITLE" class="small-abstand-bottom"></h3>
            <div class="form-row row" ng-repeat="field in vm.customFields">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-7">
                            <dvb-voreinstellungen-custom-field-form custom-field="field"
                                                                    field-types="vm.fieldTypes">
                            </dvb-voreinstellungen-custom-field-form>
                        </div>
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <select class="form-control"
                                            uib-tooltip="{{'EXTERNAL_ANMELDUNG.CUSTOM_FIELDS.SELECT_NAMED_ENTITY_TYPE' | translate}}"
                                            tooltip-placement="top-left"
                                            ng-model="field.namedEntityType"
                                            ng-options="type as ('EXTERNAL_ANMELDUNG.CUSTOM_FIELDS.NAMED_ENTITY_TYPE.' + type | translate) for type in vm.namedEntityTypes"
                                            ng-disabled="field.id"
                                            required>
                                        <option value=""
                                                class="hide"
                                                disabled
                                                selected
                                                data-translate="EXTERNAL_ANMELDUNG.CUSTOM_FIELDS.SELECT_NAMED_ENTITY_TYPE">
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 edit form-linker">
                            <button class="btn btn-link"
                                    type="button"
                                    ng-click="vm.onDelete(field)"
                                    data-translate="COMMON.LOESCHEN">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row normal-abstand-top big-abstand-bottom">
                <div class="col-md-6">
                    <button class="btn btn-link"
                            type="button"
                            ng-click="vm.onAddField(namedEntityType)">
                        + <span data-translate="CUSTOM_FIELD.HINZUFUEGEN"></span>
                    </button>
                </div>
            </div>
            <dv-custom-field-sort-item [state]="vm.sortState"
                                       (closed)="vm.showSortDialog$.next(false)">
            </dv-custom-field-sort-item>
            <ul class="list-inline normal-abstand-top">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="{{vm.submitLabel || 'COMMON.SPEICHERN'}}"></span>
                    </dvb-loading-button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.sortFields(customFieldsForm)"
                            data-translate="CUSTOM_FIELD.SORTIEREN"
                            ng-disabled="vm.isLoading || vm.customFields.length < 2">
                    </button>
                </li>
            </ul>
        </form>
    </panel-content>
</dvb-accordion>
