<div class="dvb-voreinstellungen-customfields-form row">
    <div ng-class="{'col-md-4': vm.showMandatory, 'col-md-6': !vm.showMandatory}">
        <input type="text"
               class="form-control"
               ng-attr-placeholder="{{'CUSTOM_FIELD.BEZEICHNUNG' | translate}}"
               uib-tooltip="{{'CUSTOM_FIELD.BEZEICHNUNG' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.customField.name"
               required>
    </div>
    <div ng-class="{'col-md-4': vm.showMandatory, 'col-md-6': !vm.showMandatory}">
        <select class="form-control"
                uib-tooltip="{{'CUSTOM_FIELD.TYP_AUSWAEHLEN' | translate}}"
                tooltip-placement="top-left"
                ng-model="vm.customField.fieldType"
                ng-options="type as ('CUSTOM_FIELD.TYPE.' + type | translate) for type in vm.fieldTypes"
                ng-disabled="vm.customField.id"
                required>
            <option value=""
                    class="hide"
                    disabled
                    selected
                    data-translate="CUSTOM_FIELD.TYP_AUSWAEHLEN">
            </option>
        </select>
    </div>
    <div ng-if="vm.showMandatory" class="col-md-4">
        <div class="checkbox">
            <label>
                <input type="checkbox"
                       ng-model="vm.customField.mandatory">
                <span data-translate="CUSTOM_FIELD.MANDATORY"></span>
            </label>
        </div>
    </div>

    <div ng-if="vm.customField.kindCustomField" class="normal-abstand-bottom"
         ng-class="{'col-md-10': vm.showMandatory, 'col-md-12': !vm.showMandatory}">
        <span data-translate="CHECK_IN.CUSTOM_FIELD.LINKED_TO"
              data-translate-value-name="{{vm.customField.kindCustomField.name}}">
        </span>
    </div>

    <div ng-if="vm.customField.checkInCustomField" class="normal-abstand-bottom"
         ng-class="{'col-md-10': vm.showMandatory, 'col-md-12': !vm.showMandatory}">
        <span data-translate="CHECK_IN.CUSTOM_FIELD.LINKED_TO"
              data-translate-value-name="{{vm.customField.checkInCustomField.name}}">
        </span>
    </div>

    <div ng-if="vm.customField.fieldType === 'SELECT'"
         ng-class="{'col-md-10': vm.showMandatory, 'col-md-12': !vm.showMandatory}">
        <input type="text"
               class="form-control"
               ng-attr-placeholder="{{'CUSTOM_FIELD.SELECT_VALUES' | translate}}"
               uib-tooltip="{{'CUSTOM_FIELD.SELECT_VALUES' | translate}}"
               tooltip-placement="top-left"
               ng-model="vm.customField.selectValues"
               ng-required="vm.customField.fieldType === 'SELECT'">
    </div>
</div>
