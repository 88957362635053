/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type angular from 'angular';
import {CONFIG} from '../../../../config';

export const DIRECTIVE: angular.IDirective = {
    restrict: 'EA',
    transclude: true,
    replace: true,
    scope: true,
    template: '<div>'
        + '<a id="download-link" target="_self" style="position:fixed;display:none;top:-1px;left:-1px;" download></a>'
        + '<ng-transclude></ng-transclude></div>',
    controllerAs: 'vm',
};

export class DvbDownload implements angular.IController {
    public static $inject: readonly string[] = [
        '$element',
        '$scope',
        'errorService',
    ];

    private aElement!: JQLite;

    public constructor(
        private readonly $element: JQLite,
        private readonly $scope: angular.IScope,
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {

        this.aElement = this.$element.find('a');

        this.$scope.$on('downloadFile', (_event, url) => {
            this.downloadFileByUrl(url);
        });
    }

    public downloadFileByUrl(blob: TempBlob): void {
        this.errorService.handleValidationError(!blob.virusFound, 'ERRORS.ERR_DOWNLOAD_MALWARE');
        if (blob.virusFound) {
            return;
        }

        this.aElement.attr('href', blob.getDownloadUrl(CONFIG.restBackend));
        setTimeout(() => {
            this.aElement[0].click();
        }, 0);
    }
}

DIRECTIVE.controller = DvbDownload;
