<div class="dvb-transaktionen-import row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">

        <h2 data-translate="FAKTURA.KONTENABGLEICH.DATEIEN_HOCHLADEN" ng-if="vm.isModeUpload"></h2>
        <h2 data-translate="FAKTURA.KONTENABGLEICH.KONTOAUSZUG_ERFOLGREICH_HOCHGELADEN" ng-if="!vm.isModeUpload"></h2>

        <form ng-if="vm.isModeUpload"
              ng-submit="vm.onSubmit(uploadKontenauszugForm)"
              name="uploadKontenauszugForm"
              novalidate
              class="dvb-form">
            <div class="form-row row">
                <div class="col-xs-12">
                    <dvb-multiple-files-upload label-upload="COMMON.DATEIEN_IMPORTIEREN"
                                               ng-model="vm.files"
                                               allow-multiple="true">
                    </dvb-multiple-files-upload>
                </div>
            </div>

            <div class="form-row row big-abstand-top">
                <div class="col-xs-12">
                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit"
                                                is-loading="vm.isLoading"
                                                ng-disabled="vm.files.length < 1">
                                <span data-translate="FAKTURA.KONTENABGLEICH.DATEIEN_HOCHLADEN"></span>
                            </dvb-loading-button>
                        </li>
                        <li>
                            <a ui-sref="base.kontenabgleich.transaktionen" data-translate="COMMON.ABBRECHEN"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </form>

        <div ng-if="!vm.isModeUpload">

            <!-- Gueltigkeit -->
            <p>
                <strong ng-bind="vm.gueltigAbMin | amDateFormat:'D.M.YYYY'"></strong>
                -
                <strong ng-bind="vm.gueltigBisMax | amDateFormat:'D.M.YYYY'"></strong>
            </p>

            <table class="table table-hover normal-abstand-top">
                <tbody>
                <tr>
                    <td data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_AUTOMATISCH_ZUGEWIESEN"></td>
                    <td class="text-right" ng-bind="vm.successfulAssignedImports"></td>
                </tr>
                <tr>
                    <td data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_NICHT_ZUGEWIESEN"></td>
                    <td class="text-right" ng-bind="vm.notAssignedImports"></td>
                </tr>
                <tr>
                    <td data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_DUPLIKATE"></td>
                    <td class="text-right" ng-bind="vm.duplicates.length"></td>
                </tr>
                <tr>
                    <td data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_IGNORIERT"></td>
                    <td class="text-right" ng-bind="vm.ignoredImports"></td>
                </tr>
                <tr>
                    <td><strong data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_TOTAL"></strong></td>
                    <td class="text-right">
                        <strong ng-bind="vm.totalEntries"></strong>
                    </td>
                </tr>
                <tr>
                    <td><strong data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_SUMME"></strong></td>
                    <td class="text-right">
                        <strong data-translate="COMMON.BETRAG_VALUE"
                                data-translate-value-betrag="{{vm.totalBetrag | number:2}}">
                        </strong>
                    </td>
                </tr>
                </tbody>
            </table>

            <div ng-if="vm.duplicates.length > 0" class="normal-abstand-top">
                <p class="alert alert-danger normal-abstand-bottom">
                    <strong data-translate="FAKTURA.KONTENABGLEICH.MF_BANK_STATEMENT_DUPLIKATE_ERKLAERUNG"
                            data-translate-value-duplicate-count="{{vm.duplicates.length}}"
                            data-translate-interpolation="messageformat">
                    </strong>
                </p>

                <h3 data-translate="FAKTURA.KONTENABGLEICH.BANK_STATEMENT_DUPLIKATE"></h3>

                <table class="table table-hover normal-abstand-top duplicates">
                    <tbody>
                    <tr ng-repeat="duplicate in vm.duplicates">
                        <td>
                            <input type="checkbox"
                                   ng-model="vm.selectedDuplicateIndices[$index]"
                                   ng-disabled="vm.isDuplicateImportLoading"
                                   uib-tooltip="{{'COMMON.IMPORTIEREN' | translate}}"
                                   tooltip-placement="top-left">
                        </td>
                        <td ng-bind="duplicate.localDateTime | amDateFormat: 'DD.MM.YYYY'"></td>
                        <td data-translate="COMMON.BETRAG_VALUE"
                            data-translate-value-betrag="{{duplicate.betrag | number: 2}}"></td>
                        <td ng-bind="duplicate.referenzNummer | esr"></td>
                    </tr>
                    </tbody>
                </table>

                <div class="row">
                    <div class="col-md-12">
                        <ul class="list-inline">
                            <li>
                                <dvb-loading-button type="button"
                                                    as-link="true"
                                                    is-loading="vm.isDuplicateImportLoading"
                                                    on-click="vm.importSelectedDuplicates()"
                                                    class="normal-abstand-top">
                                    <span data-translate="FAKTURA.KONTENABGLEICH.MARKIERTE_DUPLIKATE_IMPORTIEREN"></span>
                                </dvb-loading-button>
                            </li>
                            <li><a data-translate="COMMON.ABBRECHEN" href="" ng-click="vm.goBack()"></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <a ui-sref="base.kontenabgleich.transaktionen({
                bankStatementIds: vm.getBankStatementIds(),
                states: [vm.totalEntries === vm.successfulAssignedImports ? 'ZUGEWIESEN' : 'NICHT_ZUGEWIESEN'],
                gueltigAb: vm.gueltigAbMin.format('YYYY-MM-DD'),
                gueltigBis: vm.gueltigBisMax.format('YYYY-MM-DD')
               })"
               class="btn btn-primary big-abstand-top"
               data-translate="{{vm.totalEntries === vm.successfulAssignedImports ?
                 'FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_ANSEHEN' :
                 'FAKTURA.KONTENABGLEICH.NICHT_ZUGEWIESENE_ZUWEISEN'}}"
               ng-hide="vm.duplicates.length >0 && vm.totalEntries === 0">
            </a>
        </div>
    </div>
</div>
