<div class="dvb-kind-tarife-parameter-header history-columns">
    <div class="history-column-left">
        <p>
            <dvb-gueltigkeit entity="vm.tarifParameterHistoryEntry"></dvb-gueltigkeit>
        </p>
    </div>

    <div class="history-column-middle">
        <p ng-bind="vm.getValueList()"></p>
    </div>
</div>
