/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {SearchResultEntry} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {AuthService} from '../../../authentication/service/authService';
import type {AdministrationService} from '../../service/administrationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-email-test.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceEmailTest {

    public static $inject: readonly string[] = ['administrationService', 'errorService', 'authService'];

    public isLoading: boolean = false;
    public subject: string = 'Testing Email Sending Capability';
    public recipient: string | null = null;
    public rechnungSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
        private readonly administrationService: AdministrationService,
        private readonly errorService: ErrorService,
        private readonly authService: AuthService,
    ) {

    }

    public $onInit(): void {
        this.recipient = this.authService.getPrincipal().username;
    }

    public sendTestEmail(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return;
        }

        const rechnungId = this.rechnungSearchResultEntry?.id;

        this.isLoading = true;
        this.administrationService.sendEmailTest(checkPresent(this.recipient), this.subject, rechnungId).finally(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbMaintenanceEmailTest;
angular.module('kitAdmin').component('dvbMaintenanceEmailTest', componentConfig);
