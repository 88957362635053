/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestTimeout} from '@dv/kitadmin/models';
import type {ApiResponseTransformer, AsyncResponse} from '@dv/shared/code';
import {DvbUtil, isPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';

const transactionTimeoutMinutes = 20;
const secondsPerMin = 60;
const milliesPerSecond = 1000;
const maxDurationInMillies = transactionTimeoutMinutes * secondsPerMin * milliesPerSecond;

export class AsyncResponseService {
    public static $inject: readonly string[] = ['$interval', '$q', '$rootScope', '$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/asyncresponse`;

    private static readonly POLLING_INTERVAL_MS: number = 3000;
    private static readonly POLLING_INTERVAL_COUNT: number =
        maxDurationInMillies / AsyncResponseService.POLLING_INTERVAL_MS;

    public constructor(
        private $interval: angular.IIntervalService,
        private $q: angular.IQService,
        private $rootScope: angular.IRootScopeService,
        private $http: angular.IHttpService,
    ) {
    }

    public startPollingForModel<T>(
        asyncResponseId: string,
        transformer: ApiResponseTransformer<T>,
        params?: RestTimeout,
    ): angular.IPromise<T> {

        return this.startPollingInternal(asyncResponseId, transformer, null, params);
    }

    public startPollingForModelArray<T>(
        asyncResponseId: string,
        transformer: ApiResponseTransformer<T>,
        key: string,
        params?: RestTimeout,
    ): angular.IPromise<[T]> {

        return this.startPollingInternal(asyncResponseId, transformer, key, params);
    }

    public startPolling(
        asyncResponseId: string,
        params?: RestTimeout,
    ): angular.IPromise<unknown> {
        return this.startPollingInternal(asyncResponseId, null, null, params);
    }

    private startPollingInternal<T1, T2>(
        asyncResponseId: string,
        transformer: ApiResponseTransformer<T1> | null,
        key: string | null = null,
        params?: RestTimeout,
    ): angular.IPromise<T2> {

        const url = `${AsyncResponseService.BASE_URL}/${encodeURIComponent(asyncResponseId)}`;
        const deferred = this.$q.defer<T2>();

        const timeout = params?.timeout;
        const maxDelay = DvbUtil.isRequestTimeoutInMs(timeout) ? timeout : AsyncResponseService.POLLING_INTERVAL_MS;

        const pollingFunction = (intervalCount: number): angular.IPromise<void> =>
            this.$http.get<AsyncResponse>(url)
                .then(response => this.handleResponse(
                    response,
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    interval,
                    deferred,
                    intervalCount,
                    transformer,
                    key))
                .catch(err => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    this.$interval.cancel(interval);
                    deferred.reject(err);
                });

        // $interval starts the first execution after the specified delay --> run one execution immediately
        pollingFunction(0);

        const interval = this.$interval(pollingFunction, maxDelay, AsyncResponseService.POLLING_INTERVAL_COUNT);

        if (DvbUtil.isRequestTimeoutPromise(timeout)) {
            timeout.then(() => this.$interval.cancel(interval));
        }

        // kill the timer
        this.$rootScope.$on('$destroy', () => {
            this.$interval.cancel(interval);
        });

        return deferred.promise;
    }

    private handleResponse<T>(
        response: angular.IHttpResponse<AsyncResponse>,
        interval: angular.IPromise<any>,
        deferred: angular.IDeferred<any>,
        intervalCount: number,
        transformer: ApiResponseTransformer<T> | null,
        key: string | null,
    ): void {
        switch (response.data.status) {
            case 'ERROR':
                this.$interval.cancel(interval);
                deferred.reject(response.data.errorMessage);
                break;
            case 'FINISHED':
                this.$interval.cancel(interval);
                if (isPresent(key) && isPresent(transformer)) {
                    if (!Array.isArray(response.data.result[key])) {
                        deferred.reject(response);
                        break;
                    }

                    const transformedResult = response.data.result[key]
                        .map((entry: any) => transformer.apiResponseTransformer(entry));
                    deferred.resolve(transformedResult);
                    break;
                }

                if (isPresent(transformer)) {
                    deferred.resolve(transformer.apiResponseTransformer(response.data.result));
                }

                deferred.resolve();
                break;
            case 'RUNNING':
                if (intervalCount === AsyncResponseService.POLLING_INTERVAL_COUNT) {
                    deferred.reject();
                }
                // nop
                break;
            default:
                throw new Error(`unknown response state ${JSON.stringify(response.data.status)}`);
        }
    }
}
