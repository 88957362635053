/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpResponse} from '@angular/common/http';
import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Mandant} from '@dv/kitadmin/models';
import {BenutzerType} from '@dv/kitadmin/models';
import {idFromLocation} from '@dv/shared/angular';
import type {BenutzerService} from '@dv/shared/backend/api/benutzer.service';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {finalize, map, mergeMap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {BenutzerFormModel} from '../dvb-benutzer-form/benutzer-form.model';
import {
    benutzerFormModelToJaxBenutzer,
    benutzerFormModelToJaxNewGroupAdminBenutzer,
    createBenutzerFormModel,
} from '../dvb-benutzer-form/benutzer-form.util';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        mandant: '<',
    },
    template: require('./dvb-neuer-benutzer.html'),
    controllerAs: 'vm',
};

class DvbNeuerBenutzer implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'benutzerApiService', 'errorService', 'dvbStateService'];

    public mandant!: Mandant;
    public benutzerType!: BenutzerType;
    public model: BenutzerFormModel = createBenutzerFormModel();
    public isLoading: boolean = false;
    public allowPasswordChange = false;

    public constructor(
        private $state: StateService,
        private benutzerService: BenutzerService,
        private errorService: ErrorService,
        private dvbStateService: DvbStateService,
    ) {
    }

    public $onInit(): void {
        this.allowPasswordChange = !this.mandant.oidcUserManagement;
        this.benutzerType = this.mandant.oidcUserManagement ? BenutzerType.OIDC_USER : BenutzerType.MANDANT_USER;
    }

    public validateAndSaveBenutzer(form: angular.IFormController): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (BenutzerType.MANDANT_USER === this.benutzerType) {
            const validPasswordConfirmation = !form.$error.passwordConfirmation;
            this.errorService.handleValidationError(validPasswordConfirmation, 'ERRORS.ERR_DIFFERENT_PASSWORD');

            const validPasswordStrength = !form.$error.passwordStrength;
            this.errorService.handleValidationError(validPasswordStrength, 'ERRORS.ERR_STRONG_PASSWORD');

            if (!validPasswordConfirmation || !validPasswordStrength) {
                return;
            }
        }

        if (!form.$valid) {
            return;
        }

        this.isLoading = true;

        this.createRequest(this.model).pipe(
            map(idFromLocation),
            mergeMap(id => this.$state.go('base.benutzer.profil', {id})),
            finalize(() => {
                this.isLoading = false;
            }),
        ).subscribe();
    }

    public goBack(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    private createRequest(model: BenutzerFormModel): Observable<HttpResponse<unknown>> {
        if (model.groupAdminUser) {
            return this.benutzerService.createGroupAdminBenutzer$({
                jaxNewGroupAdminBenutzer: benutzerFormModelToJaxNewGroupAdminBenutzer(model),
            }, 'response');
        }

        return this.benutzerService.createBenutzer$({
            jaxUpdateBenutzer: benutzerFormModelToJaxBenutzer(model),
        }, 'response');
    }
}

componentConfig.controller = DvbNeuerBenutzer;
angular.module('kitAdmin').component('dvbNeuerBenutzer', componentConfig);
