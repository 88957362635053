<div class="dvb-display-controlling-change" ng-switch="vm.change.kindChangeType">
    <div class="row">
        <div class="col-xs-3">
            <span ng-bind="vm.change.aenderungsDatum | amDateFormat:'D.M.YYYY'"></span>
        </div>
        <div class="col-xs-9">
            <span data-translate="{{'KINDERORT.CONTROLLING_CHANGE_' + vm.change.kindChangeType}}"
                  data-translate-value-kind="{{vm.change.getKindDisplayName()}}"
                  data-translate-value-args="{{vm.change.translateArgs}}"></span>
        </div>
    </div>
</div>
