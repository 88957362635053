/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ExternalAnmeldungConfig} from '@dv/kitadmin/models';
import {isPersisted, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {ExternalAnmeldungConfigService} from '../../../external/service/externalAnmeldungConfigService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        config: '<?',
    },
    template: require('./dvb-voreinstellungen-external-anmeldung.html'),
    controllerAs: 'vm',
};

export class DvbVoreinstellungenExternalAnmeldung implements angular.IController {
    public static $inject: readonly string[] = ['externalAnmeldungConfigService'];

    public config?: ExternalAnmeldungConfig;
    public workingCopy: ExternalAnmeldungConfig = new ExternalAnmeldungConfig();

    public constructor(
        private readonly externalAnmeldungConfigService: ExternalAnmeldungConfigService,
    ) {
    }

    public $onChanges(): void {
        if (isPresent(this.config)) {
            this.workingCopy = angular.copy(this.config);
        }
    }

    public save(): void {
        const promise = isPersisted(this.workingCopy) ?
            this.externalAnmeldungConfigService.updateConfig(this.workingCopy) :
            this.externalAnmeldungConfigService.createConfig(this.workingCopy);

        promise.then(response => {
            this.workingCopy = response;
        });
    }
}

componentConfig.controller = DvbVoreinstellungenExternalAnmeldung;
angular.module('kitAdmin').component('dvbVoreinstellungenExternalAnmeldung', componentConfig);
