<div class="dvb-leistungsrechnung-warnings-badge dvb-tasks-badge">

    <script type="text/ng-template" id="warningBadgeTooltipTemplate.html">
        <ul class="popover-list">
            <li ng-repeat="warning in vm.warnings"
                data-translate="{{'COMMON.LEISTUNGSRECHNUNG_WARNINGS.' + warning.leistungsrechnungWarning + '.LONG_MF'}}"
                data-translate-interpolation="messageformat"
                data-translate-value-implicitly-known="{{vm.implicitlyKnown}}"
                data-translate-value-kinder-ort-name="{{warning.kinderOrtName}}"
                data-translate-value-kind-name="{{warning.kindName}}">
            </li>
        </ul>
    </script>

    <span ng-if="vm.label != null"
          class="badge warning"
          uib-popover-template="'warningBadgeTooltipTemplate.html'"
          popover-title="{{'FAKTURA.ACHTUNG' | translate}}"
          popover-enable="true"
          popover-trigger="'mouseenter focus'"
          popover-placement="bottom"
          popover-append-to-body="true">
        <span ng-bind="vm.label"></span>
        <i ng-if="vm.enableDeletion"
           class="fa fa-times smaller-abstand-left"
           ng-click="vm.onDelete(); $event.preventDefault(); $event.stopPropagation()"></i>
    </span>
</div>
