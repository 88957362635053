/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export interface IOnSelected {
    selected: boolean;
    context: AccordionContext;
}

export class AccordionContext {
    public constructor(
        public isLoading: boolean = false,
        public isCollapsed: boolean = true,
        public collapseComplete: boolean = true,
        public isEditMode: boolean = false,
        public onSelect?: (info: IOnSelected) => void,
    ) {
    }

    public completeSubmission(): void {
        this.isLoading = false;
        this.isEditMode = false;
        this.isCollapsed = true;
    }

    public cancelSubmission(): void {
        this.isLoading = false;
    }
}
