/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import {LOGIN_STATE} from '../../../authentication/authentication-states';
import type {AuthService} from '../../../authentication/service/authService';
import {IS_REQUIRING_LOGIN} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

export function authenticationHook($transitions: TransitionService): void {
    $transitions.onBefore(IS_REQUIRING_LOGIN, checkAuthentication, {priority: OnBeforePriorities.AUTHENTICATION});
}

function checkAuthentication(transition: Transition): HookResult {
    const authService = transition.injector().get<AuthService>('authService');

    // Authentication
    if (authService.isAuthenticated()) {
        return undefined;
    }

    // redirect to login page
    return transition.router.stateService.target(LOGIN_STATE.name, {
        toState: transition.to(),
        toParams: transition.targetState().params(),
    });
}
