/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import moment from 'moment';
import {DvbDateUtil} from '@dv/shared/code';

angular.module('kitAdmin').directive('dvbEndOfPrevMonth', dvbEndOfPrevMonth);

function dvbEndOfPrevMonth(): angular.IDirective {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (_scope, _element, _attrs, ngModel: any): void => {
            ngModel.$parsers.unshift(adjustToLastOfPreviousMonth);
        },
    };

    function adjustToLastOfPreviousMonth(viewValue: moment.Moment): moment.Moment {
        if (DvbDateUtil.isValidMoment(viewValue)) {
            const endOfMonth = DvbDateUtil.endOfMonth(moment(viewValue));
            if (endOfMonth.isSame(viewValue)) {
                return viewValue;
            }

            DvbDateUtil.startOfMonth(viewValue);
            viewValue.subtract(1, 'day');
        }

        return viewValue;
    }
}
