<div class="dvb-external-anmeldung-conclusion">

    <h2 data-translate="EXTERNAL_ANMELDUNG.WIZARD.SUMMARY"></h2>
    <div class="row normal-abstand-bottom">
        <div class="col-md-6">
            <p>
                <strong data-translate="COMMON.KIND.MF"
                        data-translate-value-count="{{vm.kinder.length}}"
                        data-translate-interpolation="messageformat">
                </strong>
            </p>
            <p ng-repeat="kind in vm.kinder" ng-init="birthday = kind.geburtsTag || kind.geburtsTermin">
                <span ng-bind="kind.familienName  || '-'"></span>
                <span ng-bind="kind.vorName || '-'"></span>
                <span ng-bind="birthday.format('D.M.YYYY')"></span>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <strong data-translate="COMMON.KONTAKTPERSON.MF"
                        data-translate-value-count="{{vm.anmeldung.externalKontaktpersonen.length}}"
                        data-translate-interpolation="messageformat">
                </strong>
            </p>
            <p ng-repeat="kontakt in vm.anmeldung.externalKontaktpersonen">
                <span ng-bind="kontakt.familienName  || '-'"></span>
                <span ng-bind="kontakt.vorName || '-'"></span>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <textarea ng-model="vm.anmeldung.bemerkung"
                      class="form-control"
                      msd-elastic
                      ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                      uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                      tooltip-placement="top-left"
                      rows="6">
            </textarea>
        </div>
    </div>

    <div ng-if="vm.setup.config.bemerkungTranslation.hasTranslations()" class="normal-abstand-bottom">
        <p class="nl2br" ng-bind="vm.setup.config.bemerkungTranslation | dvbI18n"></p>
    </div>

    <div ng-if="vm.setup.config.agbUrl" class="row">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.anmeldung.agbAccepted" ng-required="true">
                    <span data-translate="EXTERNAL_ANMELDUNG.ACCEPT_AGB"></span>
                    <a ng-href="{{vm.setup.config.agbUrl}}"
                       target="_blank"
                       rel="noopener"
                       data-translate="EXTERNAL_ANMELDUNG.ACCEPT_AGB_LINK_PART"
                       ng-bind="vm.setup.config.agbUrl">
                    </a>
                </label>
            </div>
            <span class="error-message"
                  ng-show="!vm.anmeldung.agbAccepted"
                  data-translate="ERRORS.ERR_ACCEPT_AGB">
            </span>
        </div>
    </div>

    <div ng-if="vm.showErrorLink" class="error-message">
        <span data-translate="EXTERNAL_ANMELDUNG.WIZARD.NAV_TO_ERROR"></span>
        <button
            type="button"
            class="btn btn-link error-message"
            data-translate="EXTERNAL_ANMELDUNG.WIZARD.NAV_TO_ERROR_BTN"
            ng-click="vm.navigateToError()">
        </button>
    </div>

</div>
