<div class="dvb-rechnungsempfaenger-value">
    <div class="row">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   min="1"
                   ng-model="vm.rechnungsempfaenger.value"
                   ng-change="vm.changeValue()"
                   ng-attr-placeholder="{{'KIND.RECHNUNGSANTEIL' | translate}}"
                   uib-tooltip="{{'KIND.RECHNUNGSANTEIL' | translate}}"
                   tooltip-placement="top-left">
        </div>
        <div class="col-md-3">
            <div class="radio">
                <label>
                    <input type="radio"
                           ng-model="vm.rechnungsempfaenger.type"
                           ng-change="vm.changeValue()"
                           ng-value="vm.rechnungAufteilungTypes.PROZENT">
                    <span data-translate="KIND.RECHNUNGSAUFTEILUNG_PROZENT"></span>
                </label>
            </div>
        </div>
        <div class="col-md-3">
            <div class="radio">
                <label>
                    <input type="radio"
                           ng-model="vm.rechnungsempfaenger.type"
                           ng-change="vm.changeValue()"
                           ng-value="vm.rechnungAufteilungTypes.FIXBETRAG">
                    <span data-translate="KIND.RECHNUNGSAUFTEILUNG_FIXBETRAG"></span>
                </label>
            </div>
        </div>
    </div>
</div>
