<div class="dvb-report-main-category dvb-form">
    <div class="row">
        <div class="col-md-7 big-abstand-bottom" dvb-accordions>
            <h2 data-translate="REPORT.REPORT_AUSWAEHLEN"></h2>

            <dvb-accordion ng-repeat="report in vm.getReports()"
                           on-select="selected && vm.goTo(report.state)"
                           is-selected="report.state === vm.getCurrentStateName()"
                           is-first="$first"
                           dvb-auth
                           require-permission="{{report.permission}}">
                <panel-header>
                    <p data-translate="{{report.titleKey}}"></p>
                </panel-header>
                <panel-content>
                    <div class="floating-text-list">
                        <h3 data-translate="REPORT.PURPOSE"></h3>
                        <div class="small-abstand-top" data-translate="{{report.purposeKey}}"></div>
                    </div>

                    <div class="normal-abstand-top floating-text-list">
                        <h3 data-translate="REPORT.STRUCTURE"></h3>
                        <div class="small-abstand-top" data-translate="{{report.structureKey}}"></div>
                    </div>
                </panel-content>
            </dvb-accordion>
        </div>
        <div class="col-md-4 col-md-offset-1">
            <ui-view></ui-view>
        </div>
    </div>
</div>
