/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrtFraktion} from '@dv/kitadmin/models';
import {WochenKapazitaet} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {StateObject, StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, take} from 'rxjs';
import type {FraktionService} from '../../../../common/service/rest/kinderort/fraktionService';
import {DASHBOARD_STATE} from '../../../../dashboard/dashboard-state';
import {KapazitaetAenderung} from '../../../models/KapazitaetAenderung';
import type {KapazitaetService} from '../../../service/kapazitaetService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        fraktion: '<',
    },
    template: require('./dvb-fraktion-kapazitaet.html'),
    controllerAs: 'vm',
};

export class DvbFraktionKapazitaet implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [
        'fraktionService',
        'kapazitaetService',
        'dialogService',
        'errorService',
        '$translate',
        '$state',
        '$q',
    ];

    public fraktion!: KinderOrtFraktion;

    public kapazitaetsAenderungen: KapazitaetAenderung[] = [];
    public activeView: 'main' | 'neueWochenKapazitaet' = 'main';
    public today: moment.Moment = DvbDateUtil.today();
    public copyOfLatestWochenKapazitaet: WochenKapazitaet | null = null;
    public isLoading: boolean = false;

    public constructor(
        private fraktionService: FraktionService,
        private kapazitaetService: KapazitaetService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private $translate: angular.translate.ITranslateService,
        private $state: StateService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.kapazitaetsAenderungen = [];
        const orderedWochenKapazitaeten = DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(
            this.fraktion.wochenKapazitaeten);

        orderedWochenKapazitaeten.forEach(wochenKapazitaet => {
            const aenderung = new KapazitaetAenderung();
            aenderung.wochenKapazitaet = wochenKapazitaet;
            aenderung.editableWochenKapazitaet = angular.copy(wochenKapazitaet);

            const wochenplan = checkPresent(this.fraktion.wochenplan);

            const zeitraumFelderPlaetze = angular.copy(wochenplan.zeitraumFelder);
            this.kapazitaetService.setWochenKapazitaetToZeitraumFelder(zeitraumFelderPlaetze, wochenKapazitaet, false);
            aenderung.zeitraumFelderPlaetze = zeitraumFelderPlaetze;

            const zeitraumFelderMaxPlaetze = angular.copy(wochenplan.zeitraumFelder);
            this.kapazitaetService.setWochenKapazitaetToZeitraumFelder(
                zeitraumFelderMaxPlaetze, wochenKapazitaet, true);
            aenderung.zeitraumFelderMaxPlaetze = zeitraumFelderMaxPlaetze;

            this.kapazitaetsAenderungen.push(aenderung);
        });

        this.initActiveOrLastWochenKapazitaet();
    }

    public confirmDeleteFraktion(): void {
        this.fraktionService.validateFraktionDelete(checkPresent(this.fraktion.id))
            .then(() => this.showConfirmDeleteFraktionModal());
    }

    public terminateFraktion(): void {
        const id = checkPresent(this.fraktion.id);
        const fraktionName = this.fraktion.getDisplayName();
        const title = this.$translate.instant('FRAKTION.BEENDEN_HEADING', {name: fraktionName});

        this.dialogService.openDateDialog({
            title,
            confirm: endeDatum =>
                from(this.fraktionService.terminateFraktion(id, endeDatum)
                    .then(() => this.postUpdateState())).pipe(take(1)),
        });
    }

    public revertBeenden(wochenKapazitaet: WochenKapazitaet): angular.IPromise<unknown> {
        const wochenKapazitaetId = checkPresent(wochenKapazitaet.id);

        return this.fraktionService.extendWochenKapazitaet(wochenKapazitaetId)
            .then(() => this.$state.reload());
    }

    public resetUpdateWochenKapazitaet(kapazitaetsAenderung: KapazitaetAenderung): void {
        this.errorService.clearAll();
        kapazitaetsAenderung.isEditMode = false;
        kapazitaetsAenderung.editableWochenKapazitaet = angular.copy(kapazitaetsAenderung.wochenKapazitaet);
    }

    public resetNewKontingentValue(): void {
        this.activeView = 'main';
        this.errorService.clearErrorByMsgKey('ERRORS.VALUE_REQUIRED');
    }

    public deleteWochenKapazitaet(wochenKapazitaet: WochenKapazitaet): void {
        const wochenKapazitaetId = checkPresent(wochenKapazitaet.id);

        const confirm = (): Observable<unknown> => from(this.fraktionService.deleteWochenKapazitaet(wochenKapazitaetId)
            .then(() => this.postUpdateState()));

        this.dialogService.openConfirmDialog({
            title: 'COMMON.CONFIRM_DELETE',
            confirm,
        });
    }

    public updateWochenKapazitaet(
        form: angular.IFormController,
        kapazitaetsAenderung: KapazitaetAenderung,
    ): angular.IPromise<unknown> {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid) {
            return this.$q.resolve();
        }

        if (!kapazitaetsAenderung?.editableWochenKapazitaet) {
            kapazitaetsAenderung.isEditMode = false;

            return this.$q.resolve();
        }

        if (!this.isWochenKapazitaetValid(kapazitaetsAenderung.editableWochenKapazitaet)) {
            return this.$q.resolve();
        }

        // TODO die Wochenkapzitaet muesste nur gespeichert werden, wenn sich ihre Kapazitaeten geandert haben.
        // Dies koennte man hier noch ueberpruefen.
        return this.saveWochenKapazitaet(form, kapazitaetsAenderung.editableWochenKapazitaet).finally(() => {
            kapazitaetsAenderung.isEditMode = false;
        });
    }

    public saveWochenKapazitaet(
        form: angular.IFormController,
        wochenKapazitaet: WochenKapazitaet,
    ): angular.IPromise<unknown> {
        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.$valid || !this.isWochenKapazitaetValid(wochenKapazitaet)) {
            return this.$q.resolve();
        }

        const id = checkPresent(this.fraktion.id);

        this.isLoading = true;

        return this.fraktionService.saveWochenKapazitaet(id, wochenKapazitaet)
            .then(() => this.postUpdateState())
            .finally(() => {
                this.isLoading = false;
            });
    }

    private showConfirmDeleteFraktionModal(): void {
        const confirm = (): Observable<unknown> =>
            from(this.fraktionService.deleteFraktion(checkPresent(this.fraktion.id))
                .then(() => this.$state.go(DASHBOARD_STATE.name)));

        this.dialogService.openDeleteDialog({
            entityText: this.fraktion.getDisplayName(),
            confirm,
        });
    }

    private postUpdateState(): angular.IPromise<StateObject> {
        this.kapazitaetService.clearAllErrors();
        this.activeView = 'main';

        return this.$state.reload();
    }

    private initActiveOrLastWochenKapazitaet(): void {
        let wochenKapazitaet = DvbDateUtil.getEntityOn(this.fraktion.wochenKapazitaeten, DvbDateUtil.today());

        if (!wochenKapazitaet && this.kapazitaetsAenderungen.length > 0) {
            wochenKapazitaet = this.kapazitaetsAenderungen[0].wochenKapazitaet;
        }

        this.copyOfLatestWochenKapazitaet = new WochenKapazitaet();

        if (!wochenKapazitaet) {
            return;
        }

        this.copyOfLatestWochenKapazitaet.anzahlPlaetze = angular.copy(wochenKapazitaet.anzahlPlaetze);
        this.copyOfLatestWochenKapazitaet.maxPlaetze = angular.copy(wochenKapazitaet.maxPlaetze);
        this.copyOfLatestWochenKapazitaet.kapazitaeten = angular.copy(wochenKapazitaet.kapazitaeten);
    }

    private isWochenKapazitaetValid(wochenKapazitaet: WochenKapazitaet): boolean {
        const valid = wochenKapazitaet?.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }
}

componentConfig.controller = DvbFraktionKapazitaet;
angular.module('kitAdmin').component('dvbFraktionKapazitaet', componentConfig);
