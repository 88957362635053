<div class="dvb-gruppe-header header-box">
    <h1 uib-tooltip="{{vm.gruppe.getDisplayName()}}"
        tooltip-placement="top-left"
        ng-bind="vm.gruppe.getDisplayName()">
    </h1>

    <ul class="list-inline header-icons" ng-if="vm.aktuelleWochenKapazitaet">
        <li>
            <span class="custom-icon custom-icon-target"
                  uib-tooltip="{{'COMMON.KAPAZITAET_PLAETZE_WITH_VALUE' | translate:{plaetze: vm.aktuelleWochenKapazitaet.anzahlPlaetze} }}"
                  tooltip-placement="top-left">
            </span>
            <span ng-bind="vm.aktuelleWochenKapazitaet.anzahlPlaetze"></span>
        </li>
        <li>
            <span class="custom-icon custom-icon-policy"
                  uib-tooltip="{{'COMMON.KAPAZITAET_MAX_PLAETZE_WITH_VALUE' | translate:{plaetze: vm.aktuelleWochenKapazitaet.maxPlaetze} }}"
                  tooltip-placement="top-left">
            </span>
            <span ng-bind="vm.aktuelleWochenKapazitaet.maxPlaetze"></span>
        </li>
    </ul>

    <span class="layout-spacer"></span>

    <!-- ng-if is placed here since the whole nav is superflouus if there is only one nav item -->
    <ul class="list-inline header-breadcrumb" ng-if="vm.gruppe.kita.hasMonatsBlatt || vm.hasPersonalPermission">
        <li ui-sref-active-eq="active">
            <a ui-sref="base.fraktion.gruppe.profil" data-translate="FRAKTION.NAV.PROFIL"></a>
        </li>
        <li dvb-auth
            require-permission="{{'kita:view:' + vm.gruppe.kinderOrtId}}"
            require-condition="vm.gruppe.kita.hasMonatsBlatt"
            ui-sref-active-eq="active">
            <a ui-sref="base.fraktion.gruppe.monatsblatt" data-translate="FRAKTION.NAV.MONATSBLATT"></a>
        </li>
        <li ng-if="vm.hasPersonalPermission"
            ui-sref-active-eq="active">
            <a ui-sref="base.fraktion.gruppe.betreuungsschluessel" data-translate="FRAKTION.NAV.BETREUUNGS_SCHLUESSEL"></a>
        </li>
    </ul>
</div>
