<div class="dvb-kitas-fraktions-selection">
    <form name="kitasFraktionsSelectionForm" novalidate>
        <div class="row">
            <div class="col-xs-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               ng-model="vm.parameterKinderOrteFraktionen.alleKinderOrte">
                        <span data-translate="COMMON.ALLE_KINDERORTE"></span>
                    </label>
                </div>
                <dvb-multi-select ng-if="!vm.parameterKinderOrteFraktionen.alleKinderOrte"
                                  ng-model="vm.selectedKinderOrte"
                                  options="vm.availableKinderOrtDisplayables"
                                  on-change="vm.kinderOrtSelectionChanged()"
                                  placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                  ng-required="vm.isSelectionRequired">
                </dvb-multi-select>
            </div>
        </div>
        <div class="row">

            <div ng-if="!vm.parameterKinderOrteFraktionen.alleKinderOrte" class="col-xs-12">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               ng-model="vm.parameterKinderOrteFraktionen.alleFraktionen">
                        <span data-translate="COMMON.ALLE_GRUPPEN"></span>
                    </label>
                </div>
                <dvb-multi-select ng-if="!vm.parameterKinderOrteFraktionen.alleFraktionen"
                                  ng-model="vm.selectedFraktionen"
                                  options="vm.availableFraktionDisplayables"
                                  on-change="vm.fraktionSelectionChanged()"
                                  placeholder-key="COMMON.KINDERORT_FRAKTION.AUSWAEHLEN"
                                  ng-required="vm.isSelectionRequired">
                </dvb-multi-select>
            </div>
        </div>
    </form>
</div>
