/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AccordionState} from '@dv/kitadmin/ui';
import {createAccordionAdapter, createAccordionState} from '@dv/kitadmin/ui';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {CheckInStatus} from '@dv/shared/backend/model/check-in-status';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxKindCheckInEvent} from '@dv/shared/backend/model/jax-kind-check-in-event';
import {DvbDateUtil, isNullish, type Persisted} from '@dv/shared/code';
import {createAdapter, joinAdapters} from '@state-adapt/core';

export type TagesInfoState = {
    kindId?: EntityId;
    datum: BackendLocalDate;
    items: AccordionState<Persisted<JaxKindCheckInEvent>>;
};

export const initialState = (): TagesInfoState => ({
    datum: DvbDateUtil.todayAsLocalDate(),
    items: createAccordionState(),
});

const nonDeleted = (event: JaxKindCheckInEvent): boolean => isNullish(event.timestampGeloescht);
const angekommen = (s: CheckInStatus): s is 'ANGEKOMMEN' => s === CheckInStatus.ANGEKOMMEN;
const abgeholt = (s: CheckInStatus): s is 'ABGEHOLT' => s === CheckInStatus.ABGEHOLT;
const relevantStatus = (s: CheckInStatus): s is 'ABGEHOLT' | 'ANGEKOMMEN' => abgeholt(s) || angekommen(s);

export const tagesInfoAdapter = joinAdapters<TagesInfoState>()({
    kindId: createAdapter<EntityId | undefined>()({}),
    datum: createAdapter<BackendLocalDate>()({}),
    items: createAccordionAdapter<Persisted<JaxKindCheckInEvent>>(),
})({
    nonDeletedEvents: s => s.itemsEntities.filter(nonDeleted),
})({
    lastState: s => s.nonDeletedEvents.map(event => event.status).find(relevantStatus) ?? 'ABGEHOLT',
})({
    nextState: s => s.lastState === 'ANGEKOMMEN' ? 'ABGEHOLT' : 'ANGEKOMMEN',
})(([_selectors, _reactions]) => ({
    init: (state, params: { kindId: EntityId; datum: BackendLocalDate }) => ({...state, ...params}),
}))();
