/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {ITimeRange} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';

export class ZeitraumFeldSection implements ITimeRange {

    public durationInMinutes: number = 0;

    public constructor(
        public von: moment.Moment | null,
        public bis: moment.Moment | null,
        public color: string | null = null,
    ) {
        this.durationInMinutes = bis && von ? DvbDateUtil.getTimeDiff(von, bis) : 0;
    }
}
