/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, UploadTempBlob} from '@dv/kitadmin/models';
import {ConfidentialityLevel} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {DvbError, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, Geschlecht, WARNING_RULE_NAME} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {catchError, from, of, switchMap, take} from 'rxjs';
import type {DvbDownload} from '../../base/directive/dvb-download/dvb-download';
import type {KindService} from '../../common/service/rest/kind/kindService';
import {DASHBOARD_STATE} from '../../dashboard/dashboard-state';

const componentConfig: angular.IComponentOptions = {
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        kind: '<',
    },
    template: require('./dvb-kind-profil.html'),
    controllerAs: 'vm',
};

class DvbKindProfil implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'kindService',
        'errorService',
        'dialogService',
        '$scope',
        '$translate',
    ];

    private static readonly HUNDRED_PERCENT_PROGRESS: number = 100;

    public kind!: Kind;
    public geschlecht: readonly Geschlecht[] = Object.values(Geschlecht);
    public confLevelVertraulich: ConfidentialityLevel = ConfidentialityLevel.VERTRAULICH;
    public confLevelAllgemein: ConfidentialityLevel = ConfidentialityLevel.ALLGEMEIN;

    private readonly dvbDownloadCtrl!: DvbDownload;
    private lastValidKind?: Kind;

    public constructor(
        private stateService: StateService,
        private kindService: KindService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private $scope: angular.IScope,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onInit(): void {
        this.lastValidKind = angular.copy(this.kind);
    }

    public isValidName(param: any): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_FAMILIENNAME');

        return valid;
    }

    public isValidGeburtsTermin(param: any): boolean {
        const valid = DvbDateUtil.isValidMoment(param) || DvbDateUtil.isValidMoment(this.kind.geburtsTag);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_BIRTHDAY');

        return valid;
    }

    public isValidGeburtsTag(param: any): boolean {
        const valid = DvbDateUtil.isValidMoment(param)
            || DvbDateUtil.isValidMoment(this.kind.geburtsTermin);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_BIRTHDAY');

        return valid;
    }

    public isValidAnmeldeDatum(param: any): boolean {
        const valid = DvbDateUtil.isValidMoment(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_ANMELDEDATUM');

        return valid;
    }

    public saveBasisData(): void {
        if (!this.kind.hasValidBasisData()) {
            // Dies sollte nie passieren...
            console.error('Ungültiges Kind respektive Kind nicht geändert');

            return;
        }

        this.kindService.update(this.kind)
            .then(() => {
                this.$scope.$emit('betreuungsfaktorChange');
                this.onUpdateSuccess();
            })
            .catch(() => this.onUpdateError());

    }

    public addGeschwister(kindItem: SearchResultEntry): void {
        // Es ist nötig das Geschwister zu laden, ausser wir fügen das Geburtsdatum zum DisplayName hinzu
        this.kindService.get(kindItem.id).then(geschwister => {
            if (!this.kind.addGeschwister(geschwister)) {
                return;
            }

            this.kindService.addGeschwisterToKind(this.kind.id!, geschwister.id)
                .then(() => this.onUpdateSuccess())
                .catch(() => this.onUpdateError());
        });
    }

    public removeGeschwister(geschwister: Kind): void {
        this.kindService.removeGeschwisterFromKind(this.kind.id!, checkPresent(geschwister.id))
            .then(() => {
                this.kind.removeGeschwister(geschwister);
                this.onUpdateSuccess();
            })
            .catch(() => this.onUpdateError());
    }

    public filterGeschwister(kind: Kind): boolean {
        return kind.id !== this.kind.id;
    }

    public removeAnhang(anhang: UploadTempBlob): void {
        if (anhang.progress < DvbKindProfil.HUNDRED_PERCENT_PROGRESS) {
            this.kind.removeAnhang(anhang);

            return;
        }

        this.kindService.deleteAnhang(this.kind.id!, checkPresent(anhang.id))
            .then(() => {
                this.kind.removeAnhang(anhang);
                this.onUpdateSuccess();
            })
            .catch(() => this.onUpdateError());
    }

    public downloadAnhang(anhang: UploadTempBlob): void {
        this.kindService.getTempBlobForAnhang(this.kind.id!, checkPresent(anhang.id))
            .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob));
    }

    public saveAnhang(anhang: UploadTempBlob, confLvl: ConfidentialityLevel): void {
        this.kindService.importAnhangFromTempBlob(this.kind.id!, checkPresent(anhang.id), confLvl).then(() => {
            const params = {
                includes: confLvl === ConfidentialityLevel.ALLGEMEIN ? '(anhaengeAllgemein)' : '(anhaengeVertraulich)',
            };
            this.kindService.get(this.kind.id!, params)
                .then(kind => {
                    if (ConfidentialityLevel.ALLGEMEIN === confLvl) {
                        this.kind.anhaengeAllgemein = kind.anhaengeAllgemein;
                    } else if (ConfidentialityLevel.VERTRAULICH === confLvl) {
                        this.kind.anhaengeVertraulich = kind.anhaengeVertraulich;
                    }
                    this.onUpdateSuccess();
                })
                .catch(() => this.onUpdateError());
        });
    }

    public handleUploadFailure(anhang: UploadTempBlob): void {
        this.errorService.clearErrorByMsgKey('ERRORS.ERR_BADREQUEST');
        this.errorService.addValidationError(checkPresent(anhang.errorMessage));
        this.kind.removeAnhang(anhang);
        this.onUpdateError();
    }

    public confirmDeleteKind(kind: Kind): void {
        const valid = kind.belegungen.length === 0;
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_BELEGUNG_NOT_EMTPY');
        if (valid) {
            this.showConfirmDeleteKindModal(kind);
        }
    }

    public updateAnhang(anhangId: string, confLvl: ConfidentialityLevel): void {
        const fromAnhaenge =
            ConfidentialityLevel.ALLGEMEIN === confLvl ? this.kind.anhaengeVertraulich : this.kind.anhaengeAllgemein;
        const toAnhaenge =
            ConfidentialityLevel.ALLGEMEIN === confLvl ? this.kind.anhaengeAllgemein : this.kind.anhaengeVertraulich;
        if (toAnhaenge.some(a => a.id === anhangId)) {
            return;
        }

        this.kindService.changeConfLevel(this.kind.id!, anhangId, confLvl).then(() => {
            toAnhaenge.push(...fromAnhaenge.splice(fromAnhaenge.findIndex(a => a.id === anhangId), 1));
        });
    }

    private showConfirmDeleteKindModal(kind: Kind): void {
        this.dialogService.openDeleteDialog({
            entityText: kind.getDisplayName(),
            confirm: () => this.deleteKind$(kind),
        });
    }

    private deleteKind$(kind: Kind, ignoreBetreuungsMeldungen?: boolean): Observable<unknown> {

        return from(this.kindService.deleteKind(checkPresent(kind.id), ignoreBetreuungsMeldungen)).pipe(
            take(1),
            switchMap(() => from(this.stateService.go(DASHBOARD_STATE.name)).pipe(take(1))),
            catchError((errors: DvbError) => {
                if (WARNING_RULE_NAME.guard(errors.args.ruleName)) {
                    this.showConfirmDeleteKindBetreuungsMeldungen(kind);

                    return of(null);
                }
                throw errors;
            }));
    }

    private showConfirmDeleteKindBetreuungsMeldungen(kind: Kind): void {
        const title = this.$translate.instant('COMMON.CONFIRM_DELETE_ENTITY',
            {entity: kind.getDisplayName()});
        this.dialogService.openConfirmDialog({
            title,
            subtitle: this.$translate.instant('KIND.DELETE_KIBON_DATEN'),
            confirmActionText: this.$translate.instant('COMMON.LOESCHEN'),
            confirm: () => this.deleteKind$(kind, true),
        });
    }

    private onUpdateSuccess(): void {
        this.lastValidKind = angular.copy(this.kind);
        this.errorService.clearAll();
    }

    private onUpdateError(): void {
        this.kind = angular.copy(checkPresent(this.lastValidKind));
    }
}

componentConfig.controller = DvbKindProfil;
angular.module('kitAdmin').component('dvbKindProfil', componentConfig);
