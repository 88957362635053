/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ZeitabschnittComparison} from '@dv/shared/backend/model/zeitabschnitt-comparison';
import angular from 'angular';
import type {KibonExchangeBetreuung} from '../../models/KibonExchangeBetreuung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuung: '<',
    },
    template: require('./dvb-kibon-betreuung-detail.html'),
    controllerAs: 'vm',
};

export class DvbKibonBetreuungDetail implements angular.IController {
    public static $inject: readonly string[] = [];

    public betreuung!: KibonExchangeBetreuung;

    public comparison: typeof ZeitabschnittComparison = ZeitabschnittComparison;
    public hasMahlzeiten: boolean = false;
    public hasMonthly: boolean = false;
    public hasEingewoehnung: boolean = false;

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const betreuung: angular.IChangesObject<KibonExchangeBetreuung> = onChangesObj.betreuung;
        const zeitabschnitte = betreuung.currentValue?.zeitabschnitte;

        if (zeitabschnitte) {
            this.hasMahlzeiten = zeitabschnitte.some(z => z.hasMahlzeiten);
            this.hasMonthly = zeitabschnitte.some(z => z.monthly);
            this.hasEingewoehnung = zeitabschnitte.some(z => z.kibonExchangeEingewoehnungZeitabschnitt !== null);
        } else {
            this.hasMahlzeiten = false;
            this.hasMonthly = false;
            this.hasEingewoehnung = false;
        }
    }
}

componentConfig.controller = DvbKibonBetreuungDetail;
angular.module('kitAdmin').component('dvbKibonBetreuungDetail', componentConfig);
