/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Bewerbung, RestCache, WochenKapazitaet} from '@dv/kitadmin/models';
import {BelegungsEinheit, PlaetzeByWeek, Wochenplan} from '@dv/kitadmin/models';
import type {PageContainer, Persisted, RestInclude, RestLimited, RestPaginated} from '@dv/shared/code';
import {BetreuungsZeitraum, checkPersisted, checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

type WochenplanRestParamType = RestLimited & RestInclude & RestCache;

export class WochenplanService {
    public static $inject: readonly string[] = ['$http'];

    public static readonly WOCHENPLAN_INCLUDES_DEEP: string =
        '(tagesplaene.fields(belegungsEinheiten.fields(zeitraumIds)),zeitraeume)';

    private static readonly BASE_URL: string =
        `${CONFIG.restBackend}/api/v1/wochenplaene`;
    private static readonly BASE_URL_BELEGUNGS_EINHEITEN: string =
        `${CONFIG.restBackend}/api/v1/belegungseinheiten`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getWochenplanBewerbung(bewerbung: Bewerbung): angular.IPromise<Persisted<Wochenplan>> {
        return this.getWochenplanDeep(checkPresent(bewerbung.wochenplanId), {cache: true});
    }

    /**
     * Liefert den Standard-Wochenplan fuer Anmeldungen
     */
    public getWochenplanAnmeldung(params?: WochenplanRestParamType): angular.IPromise<Wochenplan> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${WochenplanService.BASE_URL}/anmeldung`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Wochenplan, matrixParams, params)
            .then(checkPresent);
    }

    /**
     * Convenience: {@link getWochenplanAnmeldung} mit allen Belegungs-Dependencies
     */
    public getWochenplanAnmeldungDeep(params?: RestCache): angular.IPromise<Wochenplan> {
        return this.getWochenplanAnmeldung({
            includes: WochenplanService.WOCHENPLAN_INCLUDES_DEEP,
            cache: !!params?.cache,
        });
    }

    /**
     * Get all.
     */
    public getAllWochenplaene(params?: WochenplanRestParamType): angular.IPromise<Wochenplan[]> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        return DvbRestUtilAngularJS.getModelsArray(
            WochenplanService.BASE_URL,
            Wochenplan,
            'wochenplaene',
            matrixParams,
        );
    }

    /**
     * Get all with all includes
     */
    public getAllWochenplaeneDeep(params?: RestCache): angular.IPromise<Wochenplan[]> {
        return this.getAllWochenplaene({
            includes: WochenplanService.WOCHENPLAN_INCLUDES_DEEP,
            cache: !!params?.cache,
        });
    }

    /**
     * Get Wochenplan by id
     */
    public getWochenplan(id: string, params?: WochenplanRestParamType): angular.IPromise<Wochenplan> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(WochenplanService.BASE_URL, Wochenplan, id, params)
            .then(checkPresent);
    }

    /**
     * Get Wochenplan with all includes
     */
    public getWochenplanDeep(id: string, params?: RestCache): angular.IPromise<Persisted<Wochenplan>> {
        const p = {
            includes: WochenplanService.WOCHENPLAN_INCLUDES_DEEP,
            cache: !!params?.cache,
        };

        return DvbRestUtilAngularJS.getModelByIdAndParams(WochenplanService.BASE_URL, Wochenplan, id, p)
            .then(checkPersisted);
    }

    public update(wochenplan: Persisted<Wochenplan>): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${WochenplanService.BASE_URL}/${encodeURIComponent(wochenplan.id)}`;

        return this.$http.put(url, wochenplan.toRestObject());
    }

    public createBelegungseinheit(
        wochenplanId: string,
        belegungsEinheit: BelegungsEinheit,
    ): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${WochenplanService.BASE_URL}/${wochenplanId}/belegungseinheit`;

        return this.$http.post(url, belegungsEinheit.toRestObject());
    }

    public updateBelegungseinheit(
        wochenplanId: string,
        belegungsEinheit: BelegungsEinheit,
    ): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${WochenplanService.BASE_URL}/${wochenplanId}/belegungseinheit/${encodeURIComponent(
            belegungsEinheit.id!)}`;

        return this.$http.put(url, belegungsEinheit.toRestObject());
    }

    public deleteBelegungseinheit(belegungsEinheit: BelegungsEinheit): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${WochenplanService.BASE_URL}/belegungseinheit/${encodeURIComponent(
            belegungsEinheit.id!)}`;

        return this.$http.delete(url);
    }

    public getBelegugsEinheitenByWocheplanId(
        wochenplanId: string,
        params?: RestInclude & RestPaginated,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<BelegungsEinheit>> {
        const url = `${WochenplanService.BASE_URL}/${encodeURIComponent(wochenplanId)}/belegungseinheiten`;

        return DvbRestUtilAngularJS.getPagedItems(url, BelegungsEinheit, {...params}, config);
    }

    public getBetreuungsZeitraeumeByBelegungsEinheitId(
        belegungsEinheitId: string,
        params?: RestCache,
    ): angular.IPromise<BetreuungsZeitraum[]> {
        const url = `${WochenplanService.BASE_URL_BELEGUNGS_EINHEITEN}/` +
            `${encodeURIComponent(belegungsEinheitId)}/betreuungszeitraeume`;
        const cache = !!params?.cache;

        return DvbRestUtilAngularJS.getModelsArray(url, BetreuungsZeitraum, 'betreuungsZeitraeume', {}, cache);
    }

    public calcPlaetze(
        wochenplanId: string,
        wochenkapazitaet: WochenKapazitaet,
        calctype: 'plaetze' | 'maxplaetze' = 'plaetze',
    ): angular.IPromise<PlaetzeByWeek> {
        const url = `${WochenplanService.BASE_URL}/${encodeURIComponent(wochenplanId)}/calc/${calctype}`;

        return this.$http.post(url, wochenkapazitaet.toRestObject()).then(
            response => PlaetzeByWeek.apiResponseTransformer(response.data));
    }

    public delete(wochenplanId: string): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${WochenplanService.BASE_URL}/${encodeURIComponent(wochenplanId)}`;

        return this.$http.delete(url);
    }
}
