<div class="row kita-tarife dvb-form">

    <div class="col-md-7">
        <h2 data-translate="KINDERORT.TARIFE_ZUGEWIESEN"></h2>

        <p ng-if="vm.tarifeZuweisungen.length === 0"
           class="normal-abstand-bottom"
           data-translate="KINDERORT.KEINE_TARIFE_ZUGEWIESEN">
        </p>

        <dvb-kita-tarife-verlauf kita-id="kita.id"
                                 tarife-zuweisungen="vm.tarifeZuweisungen"
                                 on-delete="vm.showConfirmDeleteKitaTarifeModal(kitaTarifeZeitraum)"
                                 on-revert="vm.revertKitaTarifeZeitraum(kitaTarifeZeitraum)">
        </dvb-kita-tarife-verlauf>

        <dvb-kita-tarif-zuweisen class="big-abstand-top inline-block"
                                 ng-if="vm.kitaTarifeZeitraum"
                                 kita-tarife-zeitraum="vm.kitaTarifeZeitraum"
                                 is-loading="vm.isLoading"
                                 on-submit="vm.saveKitaTarifeZeitraum(kitaTarifeZeitraum)">
        </dvb-kita-tarif-zuweisen>

        <div class="big-abstand-top"
             dvb-auth
             require-permission="{{'kita:administrate:' + kita.id}}"
             require-condition="!vm.kitaTarifeZeitraum">
            <ul class="list-inline">
                <li>
                    <button ng-click="vm.neueTarifeDefinieren()"
                            class="btn btn-primary"
                            type="button">
                        <span data-translate="KINDERORT.NEUE_TARIF_ZUWEISUNG"></span>
                    </button>
                </li>
                <li>
                    <div ng-if="vm.tarifeZuweisungen.length > 0">
                        <a href=""
                           ng-click="vm.terminateTarifZuweisung()"
                           data-translate="KINDERORT.TARIF_ZUWEISUNG_BEENDEN">
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
