<div class="dvb-anmeldung-kind">
    <dvb-anmeldung-kind-form class="form-section-minus-normal-abstand"
                             default-anmelde-datum="vm.defaultAnmeldeDatum"
                             kind="vm.kind">
    </dvb-anmeldung-kind-form>

    <div class="form-section">

        <h2 data-translate="KIND.GESCHWISTER"></h2>

        <div class="form-row" ng-show="vm.kind.geschwister && vm.kind.geschwister.length > 0">
            <div class="row selected-entity" ng-repeat="geschwister in vm.kind.geschwister">
                <div class="col-xs-8">
                    <span class="entity" ng-bind="geschwister.getDisplayName()"></span>,
                    <span ng-bind="geschwister.getDisplayGeburtsdatum()"></span>
                </div>
                <div class="col-xs-4 edit">
                    <a data-translate="COMMON.LOESCHEN"
                       href=""
                       ng-click="vm.kind.removeGeschwister(geschwister)"></a>
                </div>
            </div>
        </div>
        <div>
            <dvb-search-on-click-entity add-label-key="KIND.GESCHWISTER_HINZUFUEGEN"
                                        disabled-entries="vm.kind.geschwister"
                                        entity-to-search="KIND"
                                        on-select="vm.addGeschwister(item)"
                                        on-select-clear="true"
                                        placeholder-key="KIND.GESCHWISTER_AUSWAEHLEN">
            </dvb-search-on-click-entity>
        </div>
    </div>

    <dvb-anmeldung-kontakte adress-datum="vm.defaultAnmeldeDatum"
                            uneditable-adresse="vm.uneditableAdresse"
                            kontakte="vm.kind.kontakte"
                            on-add-kontakt="vm.addKontakt(kontakt)"
                            on-remove-kontakt="vm.kind.removeKontakt(kontakt)">
    </dvb-anmeldung-kontakte>

    <div class="form-section">

        <h2 data-translate="KIND.WOHNADRESSE_KIND"></h2>

        <dvb-adresse-form ng-if="!vm.wohnsitzWieHauptkontakt"
                          ng-model="vm.kind.adressen[0].adresse">
        </dvb-adresse-form>

        <div class="row form-row">
            <div class="col-md-12">
                <div class="checkbox">
                    <label>
                        <input ng-change="vm.changeWohnsitz()"
                               ng-model="vm.wohnsitzWieHauptkontakt"
                               type="checkbox">
                        <span data-translate="KIND.KIND_WOHNADRESSE_GLEICH_WIE_HAUPTKONTAKT"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="form-section">

        <h2 data-translate="KIND.ANHAENGE"></h2>
        <dvb-draggable-dropzone
            icon="fa-unlock"
            label-tooltip="KIND.ANHAENGE_ALLGEMEIN"
            on-drag-success="vm.updateAnhang(id, vm.confLevelAllgemein)">
            <dvb-file-list allow-multiple="true"
                           files="vm.kind.anhaengeAllgemein"
                           disable-download="true"
                           label-file-upload="KIND.ANHANG_HOCHLADEN"
                           label-no-files="KIND.KEINE_ANHAENGE"
                           on-add-file="vm.kind.addAnhang(file, vm.confLevelAllgemein)"
                           on-remove-file="vm.kind.removeAnhang(file)">
            </dvb-file-list>
        </dvb-draggable-dropzone>
        <dvb-draggable-dropzone
            icon="fa-lock"
            label-tooltip="KIND.ANHAENGE_VERTRAULICH"
            on-drag-success="vm.updateAnhang(id, vm.confLevelVertraulich)">
            <dvb-file-list allow-multiple="true"
                           files="vm.kind.anhaengeVertraulich"
                           disable-download="true"
                           label-file-upload="KIND.ANHANG_HOCHLADEN"
                           label-no-files="KIND.KEINE_ANHAENGE"
                           on-add-file="vm.kind.addAnhang(file, vm.confLevelVertraulich)"
                           on-remove-file="vm.kind.removeAnhang(file)">
            </dvb-file-list>
        </dvb-draggable-dropzone>
    </div>
</div>
