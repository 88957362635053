/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {ORGANISATION_STATES} from './organisation-states';

angular.module('kitAdmin').config(organisationConfig);

export * from './component/dvb-organisation-main/dvb-organisation-main';
// Add New Component JS Above

organisationConfig.$inject = ['$stateProvider'];

function organisationConfig($stateProvider: StateProvider): void {
    Object.values(ORGANISATION_STATES).forEach(state => $stateProvider.state(state));
}
