<div class="zuweisbare-dienste">
    <div class="dienste-container">
        @if (dienste.length > 0 && !readonly) {
            <span class="small-abstand-right dienste-label"
                  [translate]="'PERSONAL.ZUWEISUNG.ZUWEISBARE_DIENSTE'">
            </span>
        }
        @if (dienste.length === 0) {
            <span [translate]="'PERSONAL.DIENSTKONFIGURATION.NONE'"></span>
        }
        <div class="dienste">
            @for (dienst of dienste; track dienst.id) {
                <div class="dienst"
                     [ngClass]="{readonly}"
                     [draggable]="!readonly"
                     (dragstart)="dragStart($event, dienst.id)"
                     (dragend)="dragActive.emit(false)"
                     [ngStyle]="{background: dienst.backgroundColor}"
                     [tooltip]="'PERSONAL.ZUWEISUNG.DIENST_BESCHREIBUNG' | translate
                            : {
                              name: dienst.getDisplayName(),
                              von: dienst.von?.format('HH:mm'),
                              bis: dienst.bis?.format('HH:mm')
                            }">
                    <p [ngStyle]="{color: dienst.textColor}">{{ dienst.kuerzel }}</p>
                </div>
            }
        </div>
    </div>

    @if (!readonly) {
        <div class="events">
            <div class="event termin"
                 draggable="true"
                 (dragstart)="dragStart($event, terminDragData)"
                 (dragend)="dragActive.emit(false)">
                <span [translate]="'PERSONAL.TERMIN.SINGULAR'"></span>
                <span class="color" [ngStyle]="{background: defaultTerminColor}"></span>
            </div>
        </div>
    }
</div>
