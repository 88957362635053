<div class="dvb-rechnung-heading-attribute">
    <dvb-rechnung-status ng-if="vm.showStatus"
                         status="vm.rechnung.externalStatus">
    </dvb-rechnung-status>

    <div ng-if="vm.rechnung.revisionCount > 1">
        <i class="fa fa-files-o"
           uib-tooltip="{{'FAKTURA.REVISIONEN_ANZAHL' | translate:'{anzahl: \'' + vm.rechnung.revisionCount + '\'}'}}"
           tooltip-placement="top-left">
        </i>
    </div>

    <div ng-if="(!vm.errors || vm.errors.length === 0) &&
                (vm.rechnung.rechnungsdifferenz ||
                 vm.rechnung.rechnungsUeberschuss ||
                 vm.rechnung.disabledRechnungsKonfiguration ||
                 vm.rechnung.deliveryFailure)">
        <dvb-rechnung-task-icon rechnung="vm.rechnung"></dvb-rechnung-task-icon>
    </div>

    <div ng-if="!!vm.rechnung.bemerkung">
        <i class="fa fa-comment-o"
           uib-tooltip="{{vm.rechnung.bemerkung}}"
           tooltip-placement="top-left">
        </i>
    </div>

    <dvb-rechnung-heading-payment-due-icon rechnung="vm.rechnung"></dvb-rechnung-heading-payment-due-icon>

    <div ng-if="vm.errors && vm.errors.length > 0">
        <dvb-rechnungs-revision-error errors="vm.errors"></dvb-rechnungs-revision-error>
    </div>
</div>
