/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    BelegungsEinheit,
    ExtraPlatz,
    ExtraPlatzType,
    FirmenKontingent,
    GruppenBelegung,
    Kontingente,
    Platz,
    SubventioniertesKontingent,
    Wochenplan,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import {KontingentTransformer, KontingentType, ZeitraumUtil} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, ZeitraumFeldIcon} from '@dv/shared/code';
import moment from 'moment';

export class WochenplanUtil {

    /**
     * Sets the kontingent based on its type.
     */
    public static setKontingent(zeitraumFeld: ZeitraumFeld, kontingent: Kontingente | null): void {
        if (kontingent) {
            switch (kontingent.type) {
                case KontingentType.FIRMA:
                    this.setFirma(zeitraumFeld, kontingent as FirmenKontingent);

                    return;
                case KontingentType.SUBVENTIONIERT:
                    this.setSubventioniert(zeitraumFeld, kontingent as SubventioniertesKontingent);

                    return;
                default:
                    throw new Error(`kontingent type not implemented: ${JSON.stringify(kontingent.type)}`);
            }
        }

        this.setPrivat(zeitraumFeld);
    }

    /**
     * Setzt den BelegungsZustand des ZeitraumFeldes (value & titel) auf Privat
     */
    public static setPrivat(zeitraumFeld: ZeitraumFeld): void {
        zeitraumFeld.value = KontingentTransformer.getKontingentIcon(null);
        zeitraumFeld.kontingent = null;
    }

    /**
     * Setzt den BelegungsZustand des ZeitraumFeldes (value, titel & kontingent) auf das Firmenkontingent.
     */
    public static setFirma(zeitraumFeld: ZeitraumFeld, firmenKontingent: FirmenKontingent): void {
        zeitraumFeld.value = KontingentTransformer.getKontingentIcon(firmenKontingent);
        zeitraumFeld.kontingent = firmenKontingent;
    }

    /**
     * Setzt den BelegungsZustand des ZeitraumFeldes (value, titel & kontingent) auf das subventionierte Kontingent.
     */
    public static setSubventioniert(
        zeitraumFeld: ZeitraumFeld,
        subventioniertesKontingent: SubventioniertesKontingent,
    ): void {
        zeitraumFeld.value = KontingentTransformer.getKontingentIcon(subventioniertesKontingent);
        zeitraumFeld.kontingent = subventioniertesKontingent;
    }

    /**
     * Setzt die aus der GruppenBelegung definierte Belegung auf die ZeitraumFelder des Wochenplans
     *
     * @param wochenplan
     * @param gruppenBelegung
     * @param kontingente
     * @param showKontingent falls angegeben wird zuesaetzlich diese Icon angezeigt
     * @param icon falls angegeben wird zuesaetzlich diese Icon angezeigt
     * @return ein Array aller ZeitraumFelder, welche eine Belegung haben, bzw. welche modifiziert wurden.
     */
    public static setGruppenBelegungToWochenplan(
        wochenplan: Wochenplan,
        gruppenBelegung: GruppenBelegung,
        kontingente: Kontingente[],
        showKontingent: boolean,
        icon?: ZeitraumFeldIcon,
    ): ZeitraumFeld[] {

        return gruppenBelegung.plaetze
            .flatMap(p => this.setPlatzToWochenplan(wochenplan, p, showKontingent, kontingente, icon));
    }

    /**
     * Marks the extra plaetze as belegt on the wochenplan (with ZeitraumFeldIcon.BELEGT)
     */
    public static setExtraPlaetzeToWochenplan(
        wochenplan: Wochenplan,
        extraPlaetze: ExtraPlatz[],
        kontingente: Kontingente[],
        showKontingente: boolean,
    ): ZeitraumFeld[] {

        return extraPlaetze.flatMap(p => {
            const felder = this.setPlatzToWochenplan(wochenplan,
                p,
                showKontingente,
                kontingente,
                ZeitraumFeldIcon.BELEGT);

            return this.markExtraPlaetze(p.extraPlatzType!, felder);
        });
    }

    /**
     * Marks the extra plaetze with a border on the wochenplan.
     */
    public static markExtraPlaetzeOnWochenplan(
        wochenplan: Wochenplan,
        extraPlaetze: ExtraPlatz[],
        kontingente: Kontingente[],
        showKontingent: boolean,
    ): ZeitraumFeld[] {

        return extraPlaetze.flatMap(p => {
            const felder = this.setPlatzToWochenplan(wochenplan, p, showKontingent, kontingente);

            return this.markExtraPlaetze(p.extraPlatzType!, felder);
        });
    }

    public static setKontingentToZeitraumFeld(
        platz: Platz,
        zeitraumFeld: ZeitraumFeld,
        kontingente: Kontingente[],
    ): void {
        if (!platz.hasKontingent()) {
            this.setPrivat(zeitraumFeld);

            return;
        }

        const kontingenteById = kontingente.filter(k => k.id === platz.kontingentId);
        if (kontingenteById.length <= 0) {
            console.warn('WARNING: Das gesuchte Kontingent wurde nicht gefunden', platz.kontingentId);

            return;
        }

        const kontingent = kontingenteById[0];
        if (kontingent.isFirmenKontingent()) {
            this.setFirma(zeitraumFeld, kontingent);
        } else if (kontingent.isSubventioniertesKontingent()) {
            this.setSubventioniert(zeitraumFeld, kontingent);
        }
    }

    /**
     * @param wochenplan der Wochenplan der Gruppe
     * @param gruppenBelegung die Belegung einer Gruppe
     */
    public static calcPensum(wochenplan: Wochenplan, gruppenBelegung: GruppenBelegung): number {
        let pensum = 0;
        gruppenBelegung.plaetze.forEach(platz => {
            const belegungsEinheit = this.getBelegungsEinheitWithId(
                wochenplan,
                platz.wochentag,
                platz.belegungsEinheitId);

            if (belegungsEinheit?.prozentPunkte) {
                pensum += belegungsEinheit.prozentPunkte;
            }
        });

        return pensum;
    }

    public static isPlatzTypEqual(kontingent1: Kontingente | null, kontingent2: Kontingente | null): boolean {
        if (kontingent1 === kontingent2) {
            return true;
        }

        if (!kontingent1 || !kontingent2) {
            return false;
        }

        return kontingent1.id === kontingent2.id;
    }

    public static getBelegungsEinheit(wochenplan: Wochenplan, belegungsEinheitId: string): BelegungsEinheit | null {
        for (let i = 0, len = wochenplan.tagesplaene.length; i < len; i++) {
            const be = this.getBelegungsEinheitWithId(
                wochenplan,
                wochenplan.tagesplaene[i].wochentag,
                belegungsEinheitId);

            if (be) {
                return be;
            }
        }

        return null;
    }

    /**
     * @return Anzahl freie subventionierte Prozentpunkte der aktuellen Woche
     */
    public static calcFreieSubventionierteProzent(
        prozentPunkteProJahr: number,
        belegteProzentPunkteProJahr: number,
        stichtag: moment.Moment,
    ): number | null {

        if (!DvbDateUtil.isValidMoment(stichtag)) {
            return null;
        }

        const firstOfWeek = DvbDateUtil.startOfWeek(moment(stichtag));
        const daysInWeekMinusOne = 6;
        const isOverlappingTwoYears = moment(firstOfWeek).add(daysInWeekMinusOne, 'days').year() >
            moment(firstOfWeek).year();
        const endOfWeek = isOverlappingTwoYears ?
            moment(firstOfWeek).add(daysInWeekMinusOne, 'days') :
            moment(firstOfWeek).endOf('year');

        const freieProzentPunkte = prozentPunkteProJahr - belegteProzentPunkteProJahr;
        const daysInWeek = 7;
        const remainingWeeks = (moment(endOfWeek).diff(firstOfWeek, 'days') + 1) / daysInWeek;

        return freieProzentPunkte / remainingWeeks;
    }

    private static getBelegungsEinheitWithId(
        wochenplan: Wochenplan,
        dayOfWeek: DayOfWeek | null,
        belegungsEinheitId: string | null,
    ): BelegungsEinheit | null {
        const filtered = wochenplan.tagesplaene.filter(t => t.wochentag === dayOfWeek);
        if (filtered.length === 0) {
            return null;
        }

        const einheit = filtered[0].belegungsEinheiten.filter(b => b.id === belegungsEinheitId);

        return einheit.length > 0 ? einheit[0] : null;
    }

    private static setPlatzToWochenplan(
        wochenplan: Wochenplan,
        platz: Platz,
        showKontingent: boolean,
        kontingente: Kontingente[],
        icon?: ZeitraumFeldIcon,
    ): ZeitraumFeld[] {
        const zeitraumFelder = ZeitraumUtil.findZeitraumFelderFromWochenplan(
            wochenplan,
            checkPresent(platz.wochentag),
            checkPresent(platz.belegungsEinheitId));

        zeitraumFelder.forEach(zf => {
            zf.selected = true;
            if (icon) {
                zf.icon = icon;
            }

            if (showKontingent) {
                this.setKontingentToZeitraumFeld(platz, zf, kontingente);
            }
        });

        return zeitraumFelder;
    }

    private static markExtraPlaetze(type: ExtraPlatzType, zeitraumFelder: ZeitraumFeld[]): ZeitraumFeld[] {
        return zeitraumFelder.map(zf => {
            ZeitraumUtil.setExtraPlatzSelection(zf, type);

            return zf;
        });
    }
}
