/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {TimeRangeBedarf} from './TimeRangeBedarf';

export class FraktionsTimeRangeBedarf {

    public constructor(
        public fraktionsId: string | null = null,
        public date: moment.Moment | null = null,
        public dailyBedarf: TimeRangeBedarf[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): FraktionsTimeRangeBedarf {
        const result = new FraktionsTimeRangeBedarf(
            data.fraktionsId,
            DvbRestUtil.localDateToMoment(data.date));

        if (Array.isArray(data.dailyBedarf)) {
            result.dailyBedarf = data.dailyBedarf.map((dailyBedarf: any) =>
                TimeRangeBedarf.apiResponseTransformer(dailyBedarf));
        }

        return result;
    }
}
