/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable, signal} from '@angular/core';
import type {AngestellteZuweisungServiceCopyRequestParams} from '@dv/shared/backend/api/angestellte-zuweisung.service';
import {AngestellteZuweisungService} from '@dv/shared/backend/api/angestellte-zuweisung.service';
import {shareState} from '@dv/shared/rxjs-utils';
import type {Observable} from 'rxjs';
import {catchError, concatMap, EMPTY, finalize, of, Subject, switchMap, take, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import type {CopyEvent} from './copy-event.type';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class PersonalplanungCopyStore {

    private api = inject(AngestellteZuweisungService);

    public copySource$ = new Subject<CopyEvent>();
    public copyConflictSource$ =
        new Subject<Pick<AngestellteZuweisungServiceCopyRequestParams['copy'], 'deleteExisting'>>();

    public copyDialogOpen = signal(false);
    public copyConflictDialogOpen = signal(false);
    public copyLoading = signal(false);

    private copyConflictChecked$: Observable<AngestellteZuweisungServiceCopyRequestParams> = this.copySource$.pipe(
        tap(() => this.copyLoading.set(true)),
        switchMap(payload => this.hasExistingZuweisungen(payload).pipe(
            tap(() => this.copyLoading.set(false)),
            switchMap(hasConflict => this.resolveConflict$(hasConflict, {
                kinderOrtId: payload.kinderOrtId,
                copy: {...payload.range, deleteExisting: false},
            })),
            catchError(() => EMPTY),
        )),
        shareState(),
    );

    public readonly copy$: Observable<AngestellteZuweisungServiceCopyRequestParams> = this.copyConflictChecked$.pipe(
        tap(() => this.copyLoading.set(true)),
        concatMap(payload => this.api.copy$(payload).pipe(
            catchError(() => EMPTY),
            finalize(() => {
                this.copyLoading.set(false);
            }),
        )),
        tap(() => {
            this.copyDialogOpen.set(false);
            this.copyConflictDialogOpen.set(false);
            this.copyLoading.set(false);
        }),
        shareState(),
    );

    private hasExistingZuweisungen(params: CopyEvent): Observable<boolean> {
        return this.api.zuweisungDates$({
            kinderOrtId: params.kinderOrtId,
            zuweisungdates: {
                gueltigAb: params.range.targetVon,
                gueltigBis: params.range.targetBis,
            },
        }).pipe(
            map(zuweisungDates => zuweisungDates.items.length > 0),
        );
    }

    private resolveConflict$(
        hasConflict: boolean,
        copyParams: AngestellteZuweisungServiceCopyRequestParams,
    ): Observable<AngestellteZuweisungServiceCopyRequestParams> {
        if (hasConflict) {
            this.copyDialogOpen.set(false);
            this.copyConflictDialogOpen.set(true);

            return this.copyConflictSource$.pipe(
                take(1),
                map(choice => {
                    copyParams.copy.deleteExisting = choice.deleteExisting;

                    return copyParams;
                }),
            );
        }

        return of(copyParams);
    }
}
