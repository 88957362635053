/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {Pensum} from '@dv/kitadmin/models';
import type {Nullish} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import TriggeredEvent = JQuery.TriggeredEvent;

angular.module('kitAdmin').directive('dvbEditablePensum', dvbEditablePensum);

dvbEditablePensum.$inject = ['errorService', '$document', '$timeout'];

function dvbEditablePensum(
    errorService: ErrorService,
    $document: angular.IDocumentService,
    $timeout: angular.ITimeoutService,
): angular.IDirective {
    //noinspection UnnecessaryLocalVariableJS
    const directive = {
        restrict: 'E',
        replace: true,
        require: {ngModel: 'ngModel', dvbEditablePensum: 'dvbEditablePensum'},
        scope: {
            pensum: '=ngModel',
            onSubmit: '&',
        },
        template: require('./dvb-editable-pensum.html'),
        link,
        controllerAs: 'vm',
        bindToController: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        controller() {
        },
    };

    return directive;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function link(scope: angular.IScope, element: JQuery, attrs: angular.IAttributes, ctrls: any): void {
        const label = element.find('label');
        const editDiv = element.find('div.edit-fields');
        const textFields: any = element.find('input[type=number]') as unknown as JQuery[];
        let isDisabled = false;
        const componentCtrl = ctrls.dvbEditablePensum;
        const ngModelCtrl = ctrls.ngModel;

        attrs.$observe('disabled', value => {
            isDisabled = !!value;
        });

        function displayLabel(): void {
            element.removeClass('dvb-click2edit-open');
            editDiv.hide();
            label.show();
        }

        function displayInput(): void {
            element.addClass('dvb-click2edit-open');
            editDiv.show();
            label.hide();
            textFields[0].focus();
            textFields[0].select();
        }

        function notifySubmit(): void {
            // nur wenn Wert geaendert hat -->submit
            if (ngModelCtrl.$viewValue.von !== ngModelCtrl.$modelValue.von ||
                ngModelCtrl.$viewValue.bis !== ngModelCtrl.$modelValue.bis) {
                const newPensum = new Pensum(
                    ngModelCtrl.$viewValue.von,
                    ngModelCtrl.$viewValue.bis,
                    ngModelCtrl.$viewValue.type);
                if (isValid(newPensum)) {
                    ngModelCtrl.$setViewValue(newPensum);
                    ngModelCtrl.$render();
                    componentCtrl.onSubmit();
                    removeErrorsAndDisplayAsLabel();
                } else {
                    element.addClass('has-error');
                    scope.$evalAsync(() => {
                        errorService.addValidationError('ERRORS.ERR_REQUIRED_PENSUM');
                    });
                    if (isPositiveInteger(newPensum.bis)) {
                        textFields[0].focus();
                        textFields[0].select();
                    } else {
                        textFields[1].focus();
                        textFields[1].select();
                    }
                }
            } else {
                removeErrorsAndDisplayAsLabel();
            }
        }

        function removeErrorsAndDisplayAsLabel(): void {
            element.removeClass('has-error');
            scope.$evalAsync(() => {
                errorService.clearErrorByMsgKey('ERRORS.ERR_REQUIRED_PENSUM');
            });
            displayLabel();
        }

        function isValid(pensum: Pensum): boolean {
            return isPositiveInteger(pensum.von) && isPositiveInteger(pensum.bis) && pensum.von <= pensum.bis;
        }

        function isPositiveInteger(n: unknown): n is number {
            // eslint-disable-next-line no-bitwise
            return TypeUtil.isNumber(n) && n >>> 0 === parseFloat(String(n));
        }

        // Click on Label triggers edit mode
        label.on('click', () => {
            if (angular.element('.dvb-click2edit-open').length === 0 && !isDisabled) {
                displayInput();
            }
        });

        textFields.on('keydown', (event: TriggeredEvent) => {
            if (event.key === 'Escape') { // Reset on ESC
                ngModelCtrl.$setViewValue(ngModelCtrl.$modelValue);
                ngModelCtrl.$render();
                displayLabel();
            } else if (event.key === 'Enter') { // Trigger Submit on Enter
                textFields.blur();
            }
        });

        // Trigger Submit on blur
        textFields.on('blur', () => notifySubmitIfhasLeftEditablePensumTextFields());

        function notifySubmitIfhasLeftEditablePensumTextFields(): void {
            // Each browser handles relatedElement & targetElement a bit different. The only solution that seems
            // to work across browsers is to check which event received focus (=activeElement). However,
            // the activeElement might not be updated directly after the blur event. We thus need to query
            // for the activeElement through a new event loop.
            $timeout(() => {
                const activeElement = ($document[0] as any).activeElement;
                if (activeElement !== textFields[0] && activeElement !== textFields[1]) {
                    notifySubmit();
                }
            });
        }

        displayLabel();

        ngModelCtrl.$formatters.push((modelValue: Pensum | Nullish) => {
            if (!modelValue) {
                return new Pensum();
            }

            return new Pensum(modelValue.von, modelValue.bis, modelValue.type);
        });

        ngModelCtrl.$parsers.push((viewValue: Pensum) => {
            const pensum = angular.copy(ngModelCtrl.$modelValue);
            pensum.von = angular.isNumber(viewValue.von) ? viewValue.von : null;
            pensum.bis = angular.isNumber(viewValue.bis) ? viewValue.bis : null;

            return pensum;
        });
    }
}
