/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FirmenKontingent, KinderOrt, Tarif, TarifParameter, TarifValue, TempBlob} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {TarifService} from '../../../common/service/rest/tarifService';
import {DASHBOARD_STATE} from '../../../dashboard/dashboard-state';
import {TARIFZUWEISUNG_STATE} from '../../../kinderort/kinderort-settings-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        tarif: '<',
        kinderOrte: '<?',
        firmenKontingente: '<?',
    },
    template: require('./dvb-tarif-main.html'),
    controllerAs: 'vm',
};

export class DvbTarifMain implements angular.IController {
    public static $inject: readonly string[] = [
        'errorService',
        'tarifService',
        '$state',
        'dialogService',
        '$translate',
    ];

    public readonly tarif!: Tarif;
    public readonly kinderOrte: KinderOrt[] = [];
    public readonly firmenKontingente: FirmenKontingent[] = [];
    public loadingTemplate: boolean = false;
    public showAddTarifValueForm: boolean = false;
    public paramTypes: readonly TarifParameterType[] = Object.values(TarifParameterType);
    public tarifzuweisungState = TARIFZUWEISUNG_STATE;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private errorService: ErrorService,
        private tarifService: TarifService,
        private $state: StateService,
        private dialogService: DialogService,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public isValidName(param: any): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_NAME');

        return valid;
    }

    public save(): void {
        this.tarifService.updateTarif(this.tarif);
    }

    public saveBasisData(): void {
        if (!this.tarif.isValid()) {
            throw new Error('Ungültiger Tarif respektive Tarif nicht geändert');
        }

        this.errorService.clearAll();
        this.tarifService.updateTarif(this.tarif);
    }

    public updateTarifValue(tarifValue: TarifValue): void {
        this.errorService.clearAll();
        this.tarifService.createTarifValue(checkPresent(this.tarif.id), tarifValue).then(() => {
            this.$state.reload();
        });
    }

    public deleteTarifValue(tarifValue: TarifValue): void {
        this.errorService.clearAll();
        this.dialogService.openDeleteDialog({
            entityText: 'TARIF.TARIF_VALUE',
            confirm: () => {
                const valueId = checkPresent(tarifValue.id);
                const tarifId = checkPresent(this.tarif.id);

                return from(this.tarifService.deleteTarifValue(tarifId, valueId))
                    .pipe(take(1), tap(() => this.$state.reload()));
            },
        });
    }

    public revertTarifValue(tarifValue: TarifValue): void {
        this.errorService.clearAll();
        const tarifId = checkPresent(this.tarif.id);
        const valueId = checkPresent(tarifValue.id);
        this.tarifService.extendTarifValue(tarifId, valueId)
            .then(() => this.$state.reload());
    }

    public saveNewTarifValue(tarifValue: TarifValue): void {
        if (this.tarif.nullTarif) {
            return;
        }
        const isValid = tarifValue.isValid();

        this.errorService.handleValidationError(isValid, 'TARIF.ERRORS.ERR_INCOMPLETE_TARIF');

        if (!isValid) {
            return;
        }

        this.showAddTarifValueForm = false;
        this.tarifService.createTarifValue(checkPresent(this.tarif.id), tarifValue).then(() => {
            this.errorService.clearAll();

            return this.$state.reload();
        });
    }

    public confirmDeleteTarif(): void {
        this.tarifService.validateTarifDelete(checkPresent(this.tarif.id)).then(() => {
            this.showConfirmDeleteTarifModal(this.tarif);
        });
    }

    public showConfirmDeleteTarifModal(tarif: Tarif): void {
        const entityText = this.$translate.instant('FIRMA.TITLE_TARIF', {tarifname: tarif.name});
        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(this.tarifService.deleteTarif(checkPresent(tarif.id)))
                .pipe(take(1), tap(() => this.$state.go(DASHBOARD_STATE.name))),
        });
    }

    public downloadTarifVorlage(): void {
        this.loadingTemplate = true;
        const paramNames = this.tarif.parameters
            .filter(param => !!param.name)
            .map(param => param.name!);

        const tarifName = checkPresent(this.tarif.name);

        this.tarifService.getTempBlobForTarif(this.tarif.dtype, tarifName, paramNames)
            .then((tempBlob: TempBlob) => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
            .finally(() => {
                this.loadingTemplate = false;
            });
    }

    public getParameterOfType(type: TarifParameterType): TarifParameter[] {
        return this.tarif.parameters
            .filter(param => param.dtype === type);
    }
}

componentConfig.controller = DvbTarifMain;
angular.module('kitAdmin').component('dvbTarifMain', componentConfig);
