<svg class="dvb-pensum-icon"
	 ng-if="!vm.hide"
	 viewBox="0 0 512 512"
     xmlns="http://www.w3.org/2000/svg"
     ng-attr-width="{{vm.fontSize}}"
     ng-attr-height="{{vm.fontSize}}"
     ng-class="{'inverted': vm.inverted}"
     uib-tooltip="{{vm.getTooltipText()}}"
     tooltip-placement="{{vm.tooltipPlacement}}"
     tooltip-append-to-body="true">
    <circle class="background-circle"
            cx="256"
            cy="256"
            r="256"
            ng-attr-fill="{{vm.inverted ? 'transparent' : vm.color}}"
            ng-attr-stroke="{{vm.inverted ? vm.color : 'transparent'}}">
    </circle>
     <text x="50%"
          y="50%"
          font-size="360"
          ng-attr-fill="{{vm.inverted ? vm.color : 'white'}}"
          alignment-baseline="central"
          text-anchor="middle"
          font-weight="bold"
          ng-bind="vm.value">
    </text>
</svg>
