/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DokumentLayout, KinderOrt, Kontaktperson} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Nullish, Persisted, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import moment from 'moment';
import {UrlParameterTypes} from '../base/config/url-parameter-types/urlParameterTypes';
import type {KinderOrtService} from '../common/service/rest/kinderort/kinderOrtService';
import type {DokumentlayoutService} from '../dokumentlayout/service/dokumentlayoutService';
import type {BringAbholZeitenState} from './models/bring-abhol-zeiten/BringAbholZeitenState';
import type {EmailTemplate} from './models/EmailTemplate';
import type {SteuerbescheinigungenState} from './models/steuerbescheinigungen/SteuerbescheinigungenState';
import type {BringAbholZeitenService} from './service/bring-abhol-zeiten-service';
import type {EmailTemplateService} from './service/email-template-service';
import type {SteuerbescheinigungenService} from './service/steuerbescheinigungen-service';

/* eslint-disable sonarjs/no-duplicate-string */

export const BRING_ABHOLZEITEN = 'bring-abhol-zeiten';
export const STEUERBESCHEINIGUNG = 'steuerbescheinigungen';
export const BULK_EMAILS = 'bulk-emails';
export const MANAGEMENT_STATE_URL_SUFFIX = 'versenden';
export const EMAIL_TEMPLATE = 'email-template';

export const BASE_STATE = {
    name: 'base.communication',
    url: `/kommunikation?:{gueltigAb:${UrlParameterTypes.MOMENT_DATE}}:kinderOrtId:dokumentLayoutId`,
    abstract: true,
    views: {
        'header@base.communication': {
            // Defining default page header
            component: 'dvbCommunicationHeader',
        },
        '': {
            template: '<ui-view name="header"></ui-view><ui-view></ui-view>',
        },
    },
    params: {
        kinderOrtSearchResult: null,
        dokumentLayout: null,
        kontaktpersonen: {
            dynamic: true,
            array: true,
            value: [],
        },
        allSelected: false,
    },
    resolve: {
        kinderOrtSearchResult: [
            '$transition$',
            ($transition$: Transition): SearchResultEntry | null => $transition$.params().kinderOrtSearchResult ?? null,
        ],
        gueltigAb: [
            '$transition$', ($transition$: Transition): moment.Moment | null => $transition$.params().gueltigAb ?? null,
        ],
        dokumentLayout: [
            '$transition$',
            ($transition$: Transition): DokumentLayout | null => $transition$.params().dokumentLayout ?? null,
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.KOMMUNIKATION, PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng1StateDeclaration;

// base state for communication forms
export const BASE_CONFIGURATION_STATE = {
    name: `${BASE_STATE.name}.configuration`,
    url: '',
    template: '<dvb-communication></dvb-communication>',
} satisfies Ng1StateDeclaration;

// base state for managing a specific communication
export const BASE_MANAGEMENT_STATE = {
    name: `${BASE_STATE.name}.management`,
    url: '',
    abstract: true,
    views: {
        'header@base.communication': {},
    },
    resolve: {
        kinderOrt: [
            'kinderOrtService', '$transition$',
            (
                kinderOrtService: KinderOrtService,
                $transition$: Transition,
            ): angular.IPromise<KinderOrt> | null => {
                if ($transition$.params().kinderOrtId) {
                    return kinderOrtService.get(checkPresent($transition$.params().kinderOrtId), {cache: true});
                }

                return null;
            },
        ],
        gueltigAb: [
            '$transition$', ($transition$: Transition): moment.Moment => $transition$.params().gueltigAb ?? null,
        ],
        dokumentLayout: [
            'dokumentlayoutService', '$transition$',
            (
                dokumentlayoutService: DokumentlayoutService,
                $transition$: Transition,
            ): angular.IPromise<DokumentLayout> | null => {
                const id = $transition$.params().dokumentLayoutId;
                if (id) {
                    return dokumentlayoutService.getDokumentLayout(id, {cache: true});
                }

                return null;
            },
        ],
    },
} satisfies Ng1StateDeclaration;

//
// states for individual communication forms and management pages
//

export const BRING_ABHOLZEITEN_STATE = {
    name: `${BASE_CONFIGURATION_STATE.name}.${BRING_ABHOLZEITEN}`,
    url: `/${BRING_ABHOLZEITEN}`,
    template: '<dvb-bring-abhol-zeiten-form ' +
        'gueltig-ab="$resolve.gueltigAb" ' +
        'kinder-ort-search-result="$resolve.kinderOrtSearchResult"></dvb-bring-abhol-zeiten-form>',
    data: {
        permission: [PERMISSION.MODULE.KOMMUNIKATION, PERMISSION.MODULE.ANWESENHEIT, PERMISSION.KITA.CONTROL_ANY],
    },
} satisfies Ng1StateDeclaration;

export const BRING_ABHOLZEITEN_MANAGEMENT_STATE = {
    name: `${BASE_MANAGEMENT_STATE.name}.${BRING_ABHOLZEITEN}`,
    url: `/${BRING_ABHOLZEITEN}/${MANAGEMENT_STATE_URL_SUFFIX}`,
    template: '<dvb-bring-abhol-zeiten-management ' +
        'kinder-ort="$resolve.kinderOrt" ' +
        'gueltig-ab="$resolve.gueltigAb" ' +
        'bring-abhol-state="$resolve.bringAbholState"></dvb-bring-abhol-zeiten-management>',
    resolve: {
        bringAbholState: [
            'kinderOrt', 'bringAbholZeitenService', '$transition$',
            (
                kinderOrt: KinderOrt,
                bringAbholZeitenService: BringAbholZeitenService,
                $transition$: Transition,
            ): angular.IPromise<BringAbholZeitenState> => bringAbholZeitenService.getBringAbholZeitenManagement(
                kinderOrt.id!,
                $transition$.params().gueltigAb,
                DvbDateUtil.endOfMonth(moment($transition$.params().gueltigAb))),
        ],
    },
    data: {
        permission: [PERMISSION.MODULE.KOMMUNIKATION, PERMISSION.MODULE.ANWESENHEIT, PERMISSION.KITA.CONTROL_ANY],
    },
} satisfies Ng1StateDeclaration;

export const STEUERBESCHEINIGUNG_STATE = {
    name: `${BASE_CONFIGURATION_STATE.name}.${STEUERBESCHEINIGUNG}`,
    url: `/${STEUERBESCHEINIGUNG}`,
    template: '<dvb-steuerbescheinigung-form ' +
        'gueltig-ab="$resolve.gueltigAb" ' +
        'kinder-ort-search-result="$resolve.kinderOrtSearchResult" ' +
        'dokument-layout="$resolve.dokumentLayout"></dvb-steuerbescheinigung-form>',
} satisfies Ng1StateDeclaration;

export const STEUERBESCHEINIGUNG_MANAGEMENT_STATE = {
    name: `${BASE_MANAGEMENT_STATE.name}.${STEUERBESCHEINIGUNG}`,
    url: `/${STEUERBESCHEINIGUNG}/${MANAGEMENT_STATE_URL_SUFFIX}?:structure`,
    component: 'dvbSteuerbescheinigungManagement',
    params: {
        structure: 'MONTHLY',
    },
    resolve: {
        steuerbescheinigungState: [
            'kinderOrt', 'dokumentLayout', 'steuerbescheinigungenService', '$transition$',
            (
                kinderOrt: KinderOrt,
                dokumentLayout: DokumentLayout,
                steuerbescheinigungenService: SteuerbescheinigungenService,
                $transition$: Transition,
            ): angular.IPromise<SteuerbescheinigungenState> =>
                steuerbescheinigungenService.getSteuerbescheinigungManagement(
                    kinderOrt.id!,
                    moment($transition$.params().gueltigAb).year(),
                    dokumentLayout ? dokumentLayout.id : null,
                ),
        ],
        structure: [
            '$transition$',
            ($transition$: Transition): angular.IPromise<DokumentLayout> | null => {
                return $transition$.params().structure;
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const BULK_EMAIL_STATE = {
    name: `${BASE_CONFIGURATION_STATE.name}.${BULK_EMAILS}`,
    url: '/massenmailversand',
    template: '<dvb-bulk-email-form></dvb-bulk-email-form>',
} satisfies Ng1StateDeclaration;

export const BULK_EMAIL_MANAGEMENT_STATE = {
    name: `${BASE_MANAGEMENT_STATE.name}.${BULK_EMAILS}`,
    url: `/massenmailversand/${MANAGEMENT_STATE_URL_SUFFIX}`,
    params: {
        kontaktpersonen: null,
        kinderOrtIds: null,
        fraktionIds: null,
        bulkEmailInfo: null,
    },
    component: 'dvbBulkEmailManagement',
    resolve: {
        allSelected: [
            '$transition$', ($transition$: Transition): boolean => $transition$.params().allSelected,
        ],
        kontaktpersonen: [
            '$transition$',
            ($transition$: Transition): Kontaktperson[] | Nullish => $transition$.params().kontaktpersonen,
        ],
        kinderOrtIds: [
            '$transition$',
            ($transition$: Transition): EntityId[] | Nullish => $transition$.params().kinderOrtIds,
        ],
        fraktionIds: [
            '$transition$',
            ($transition$: Transition): EntityId[] | Nullish => $transition$.params().fraktionIds,
        ],
        bulkEmailInfo: [
            '$transition$',
            ($transition$: Transition): EntityId[] | Nullish => $transition$.params().bulkEmailInfo,
        ],
    },
} satisfies Ng1StateDeclaration;

export const BULK_EMAIL_HISTORY_STATE = {
    name: `${BASE_STATE.name}.bulk-email-history`,
    url: '/massenmailverlauf',
    component: 'dvbBulkEmailHistory',
} satisfies Ng1StateDeclaration;

export const EMAIL_TEMPLATE_BASE_STATE = {
    name: `${BASE_STATE.name}.${EMAIL_TEMPLATE}`,
    url: '/email-template',
    abstract: true,
} satisfies Ng1StateDeclaration;

export const EMAIL_TEMPLATE_OVERVIEW = {
    name: `${EMAIL_TEMPLATE_BASE_STATE.name}.overview`,
    url: '',
    component: 'dvbEmailTemplate',
} satisfies Ng1StateDeclaration;

export const EMAIL_TEMPLATE_NEW = {
    name: `${EMAIL_TEMPLATE_BASE_STATE.name}.new`,
    url: '/erstellen',
    views: {
        'header@base.communication': {},
        '': {
            component: 'dvbEmailTemplateForm',
        },
    },
} satisfies Ng1StateDeclaration;

export const EMAIL_TEMPLATE_EDIT = {
    name: `${EMAIL_TEMPLATE_BASE_STATE.name}.edit`,
    url: '/{id:[0-9]+}',
    views: {
        'header@base.communication': {},
        '': {
            component: 'dvbEmailTemplateForm',
        },
    },
    params: {
        emailTemplate: null,
    },
    resolve: {
        emailTemplate: [
            '$transition$',
            'emailTemplateService',
            (
                $transition$: Transition,
                emailTemplateService: EmailTemplateService,
            ): Persisted<EmailTemplate> | angular.IPromise<Persisted<EmailTemplate>> => {
                const paramsTemplate = $transition$.params().emailTemplate;
                if (paramsTemplate && $transition$.params().id === paramsTemplate.id) {
                    return paramsTemplate;
                }

                return emailTemplateService.get($transition$.params().id);
            },
        ],
    },
} satisfies Ng1StateDeclaration;

export const COMMUNICATION_STATES = {
    BASE_STATE,
    BASE_CONFIGURATION_STATE,
    BASE_MANAGEMENT_STATE,
    BRING_ABHOLZEITEN_STATE,
    BRING_ABHOLZEITEN_MANAGEMENT_STATE,
    STEUERBESCHEINIGUNG_STATE,
    STEUERBESCHEINIGUNG_MANAGEMENT_STATE,
    BULK_EMAIL_STATE,
    BULK_EMAIL_HISTORY_STATE,
    BULK_EMAIL_MANAGEMENT_STATE,
    EMAIL_TEMPLATE_BASE_STATE,
    EMAIL_TEMPLATE_OVERVIEW,
    EMAIL_TEMPLATE_NEW,
    EMAIL_TEMPLATE_EDIT,
};
