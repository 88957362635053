/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IKibonRefNrInfo, KinderOrt} from '@dv/kitadmin/models';
import {Comments, DisplayTask, Kind, KinderOrtTransformer} from '@dv/kitadmin/models';
import {KibonExchangeBetreuungStatus} from '@dv/shared/backend/model/kibon-exchange-betreuung-status';
import type {KibonExchangeBetreuungTrigger} from '@dv/shared/backend/model/kibon-exchange-betreuung-trigger';
import type {ILimited, IPersisted, Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {KibonExchangeBetreuungZeitabschnitt} from './KibonExchangeBetreuungZeitabschnitt';

export class KibonExchangeBetreuung implements IPersisted, ILimited, IKibonRefNrInfo {

    public constructor(
        public id: string,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public referenzNummer: string,
        public trigger: KibonExchangeBetreuungTrigger,
        public zeitabschnitte: KibonExchangeBetreuungZeitabschnitt[],
        public ausserordentlicherBetreuungsaufwand: boolean,
        public eingewoehnungInPeriode: boolean,
        public austrittProvisorisch: boolean,
        public belegungProvisorisch: boolean,
        public kind: Persisted<Kind> | null,
        public kinderOrt: Persisted<KinderOrt> | null,
        public uebermittlungsTimestamp: moment.Moment | null,
        public benutzerDisplayName: string | null,
        public username: string | null,
        public status: KibonExchangeBetreuungStatus | null,
        public timestampMutiert: moment.Moment | null,
        public tasks: DisplayTask[] = [],
        public comments: Comments[] = [],
        public referenzNummerIgnoriert: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeBetreuung {
        const zeitabschnitte = data.zeitabschnitte
            .map((z: any) => KibonExchangeBetreuungZeitabschnitt.apiResponseTransformer(z));

        const transformer = KinderOrtTransformer.create<KinderOrt>();

        const betreuung = new KibonExchangeBetreuung(
            data.id,
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
            data.referenzNummer,
            data.trigger,
            zeitabschnitte,
            data.ausserordentlicherBetreuungsaufwand,
            data.eingewoehnungInPeriode,
            data.austrittProvisorisch,
            data.belegungProvisorisch,
            data.kind ? Kind.apiResponseTransformer(data.kind) : null,
            data.kinderOrt ? checkPersisted(transformer.apiResponseTransformer(data.kinderOrt)) : null,
            data.uebermittlungsTimestamp ? DvbRestUtil.localDateTimeToMoment(data.uebermittlungsTimestamp) : null,
            data.benutzerDisplayName,
            data.username,
            data.status,
            data.timestampMutiert ? DvbRestUtil.localDateTimeToMoment(data.timestampMutiert) : null,
        );

        if (Array.isArray(data.leistungsrechnungTasks)) {
            betreuung.tasks = data.leistungsrechnungTasks.map((g: any) => DisplayTask.apiResponseTransformer(g));
        }

        if (Array.isArray(data.comments)) {
            betreuung.comments = data.comments.map((c: any) => Comments.apiResponseTransformer(c));
        }

        betreuung.referenzNummerIgnoriert = data.referenzNummerIgnoriert;

        return betreuung;
    }

    public isMutierbar(): boolean {
        return this.status === KibonExchangeBetreuungStatus.MUTIERBAR;
    }

    public isSent(): boolean {
        return this.uebermittlungsTimestamp !== null;
    }

    public isIgnored(): boolean {
        return this.status === KibonExchangeBetreuungStatus.IGNORIERT;
    }

    public isPending(): boolean {
        return !this.isSent() && !this.isIgnored();
    }

    public getRefNr(): string {
        return this.referenzNummer;
    }

    public isReferenzNummerIgnoriert(): boolean {
        return this.referenzNummerIgnoriert;
    }
}
