<div class="dvb-transaktion-zuweisen-header row">
    <div class="col-md-9 col-lg-7">
        <ul class="list-inline no-margin">
            <li class="normal-abstand-right">
                <strong ng-bind="vm.bankStatementEntry.localDateTime | amDateFormat:'D. MMMM YYYY'"></strong>
            </li>
            <li class="normal-abstand-right">
                <strong ng-bind="vm.bankStatementEntry.referenzNummer | esr"></strong>
            </li>
            <li>
                <strong data-translate="COMMON.BETRAG_VALUE"
                        data-translate-value-betrag="{{vm.bankStatementEntry.betrag | number:2}}">
                </strong>
            </li>
        </ul>
    </div>
    <div class="col-md-3 col-lg-offset-1 col-lg-4 text-right">
        <dvb-search-on-click-entity add-label-key="FAKTURA.KONTENABGLEICH.TRANSAKTION_DIREKT_ZUWEISEN"
                                    entity-to-search="RECHNUNG"
                                    on-select="vm.assignRechnung(item)"
                                    on-select-clear="true"
                                    placeholder-key="FAKTURA.RECHNUNG_SEARCH_RESULT">
        </dvb-search-on-click-entity>
    </div>
</div>
