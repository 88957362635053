/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExtraPlatzWochenBelegung} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entry: '<',
        kindId: '<',
        showAbweichungenBearbeiten: '<?',
    },
    template: require('./dvb-betreuungsverlauf-extra-plaetze.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsverlaufExtraPlaetze implements angular.IController {
    public static $inject: readonly string[] = [];

    public entry!: ExtraPlatzWochenBelegung;
    public kindId: string = '';
    public showAbweichungenBearbeiten: boolean = true;
}

componentConfig.controller = DvbBetreuungsverlaufExtraPlaetze;
angular.module('kitAdmin').component('dvbBetreuungsverlaufExtraPlaetze', componentConfig);
