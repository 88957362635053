/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RestCache} from '@dv/kitadmin/models';
import type {RestInclude} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';
import {Schliesstage} from '../models/Schliesstage';

export class SchliesstageService {
    public static $inject: readonly string[] = ['$http'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/schliesstage`;

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    // create or update depends on if the Id in Schliesstag is set.
    public createOrUpdateSchliesstag(schliesstage: Schliesstage): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(SchliesstageService.BASE_URL, schliesstage.toRestObject());
    }

    public get(schliesstageId: string, params?: RestInclude & RestCache): angular.IPromise<Schliesstage | null> {
        return DvbRestUtilAngularJS.getModelByIdAndParams(
            SchliesstageService.BASE_URL,
            Schliesstage,
            schliesstageId,
            params,
        );
    }

    public getAll(): angular.IPromise<Schliesstage[]> {
        return DvbRestUtilAngularJS.getModelsArray(SchliesstageService.BASE_URL, Schliesstage, 'items');
    }

    public delete(schliesstageId: string): angular.IHttpPromise<void> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${SchliesstageService.BASE_URL}/${encodeURIComponent(schliesstageId)}`;

        return this.$http.delete(url);
    }
}
