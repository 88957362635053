/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Signal, WritableSignal} from '@angular/core';
import {computed, Injectable, signal} from '@angular/core';
import {checkPresent, DvbDateUtil, isNullish, isPresent} from '@dv/shared/code';
import moment from 'moment';
import type {CalendarDayInfo} from '../model/CalendarDayInfo';
import type {CalendarEvent} from '../model/CalendarEvent';
import type {CalendarGroup} from '../model/CalendarGroup';
import type {CalendarResizeEvent} from '../model/CalendarResizeEvent';
import type {EventMap} from '../model/EventMap';
import type {GridEntry} from '../model/GridEntry';
import type {LayerConfigByLayerIndex} from '../model/LayerConfig';
import type {ResizeType} from '../model/ResizeType';
import type {TimelineCalculationStrategy} from './TimelineCalculationStrategy';
import {TIMELINE_CALCULATION_STRATEGY_MAP} from './TimelineCalculationStrategyMap';

/**
 * start hour of the visible timeline
 * min: 0
 */
export const DEFAULT_START_TIME = 6;
/*
 * end hour of the visible timeline
 * max: 23
 */
export const DEFAULT_END_TIME = 19;
export const DEFAULT_RESIZE_STEPS = 5;
export const ROW_HEIGHT = 29;

const daysPerWeek = 7;

const equalMoments = {equal: DvbDateUtil.isMomentEquals};

type ResizeState = {
    resizeType: ResizeType;
    originalEvent: CalendarEvent;
    eventIndex: number;
    groupId: string;
    resourceId: string;
    layer: number;
};

@Injectable({
    providedIn: 'root',
})
export class TimelineCalendarService {

    public startDate: WritableSignal<moment.Moment> = signal(DvbDateUtil.today(), equalMoments);
    public endDate: WritableSignal<moment.Moment> = signal(DvbDateUtil.today(), equalMoments);
    public startHour: WritableSignal<number> = signal(DEFAULT_START_TIME);
    public endHour: WritableSignal<number> = signal(DEFAULT_END_TIME);
    public selectedDate: WritableSignal<moment.Moment> = signal(DvbDateUtil.today(), equalMoments);
    public resizeSteps: WritableSignal<number> = signal(DEFAULT_RESIZE_STEPS);
    public groups: Signal<CalendarGroup[]> = signal([]);
    public layerConfig: WritableSignal<LayerConfigByLayerIndex> = signal(new Map());
    public dayInfo: WritableSignal<CalendarDayInfo[]> = signal([]);

    public layers = computed(() => Array.from(this.layerConfig().keys()).sort((a, b) => a - b));

    private currentGrouping: EventMap = {};

    public adjustedEndHour: Signal<number> = computed(() => this.endHour() - 1);

    public eventsByGroup: Signal<EventMap> = computed(() => {
        const layers = this.layers();
        const groups = this.groups();
        const layerConfig = this.layerConfig();
        const grouping: EventMap = {};

        if (isPresent(this.resizeState())) {
            this.recalculateEventsByGroupForResizedElement();
        } else {
            groups.forEach(group => {
                grouping[group.id] = {};
                group.resources.forEach(resource => {
                    grouping[group.id][resource.id] = {};

                    const limitedEvents = this.timelineCalculationStrategy().limitEvents(
                        resource.events,
                        this.startHour() * DvbDateUtil.MINUTES_PER_HOUR,
                        this.adjustedEndHour() * DvbDateUtil.MINUTES_PER_HOUR,
                        this.startDate(),
                        this.endDate(),
                        this.layerConfig(),
                    );
                    const rowEvents = this.timelineCalculationStrategy().splitIntoRows(limitedEvents, layerConfig);

                    layers.forEach(layer => {
                        const config = layerConfig.get(layer);
                        const layerEvents = rowEvents.map(value => value.filter(ev => ev.layer === layer));
                        const eventsByRow = config?.noRowSplitting === true ? [layerEvents.flat()] : layerEvents;
                        grouping[group.id][resource.id][layer] = {};

                        this.calculateEventSpaceholder(eventsByRow, grouping[group.id][resource.id][layer]);
                    });
                });
            });

            this.currentGrouping = grouping;
        }

        return this.currentGrouping;
    });

    public rowsByGroup: Signal<Record<string, Record<string, number>>> = computed(() => {
        const eventsByGroup = this.eventsByGroup();
        const result: Record<string, Record<string, number>> = {};

        Object.entries(eventsByGroup).forEach(([groupId, byResource]) => {
            result[groupId] = {};
            Object.entries(byResource).forEach(([resourceId, byLayer]) => {
                result[groupId][resourceId] = Math.max(...Object.values(byLayer).map(x => Object.keys(x).length), 1);
            });
        });

        return result;
    });

    /**
     * When true, events are displayed at full width on their date, instead of having a width based on duration.
     */
    public fullDayBlocks: Signal<boolean> = computed(() => !this.startDate().isSame(this.endDate(), 'day'));

    public timelineCalculationStrategy: Signal<TimelineCalculationStrategy> = computed(() => {
        const strategy = this.fullDayBlocks() ? 'day' : 'hour';

        return TIMELINE_CALCULATION_STRATEGY_MAP[strategy];
    });

    public durationMinutes: Signal<number> = computed(() => {
        const startDateTime = moment(this.startDate()).set('hour', this.startHour()).set('minutes', 0);
        const endDateTime = moment(this.endDate()).set('hour', this.adjustedEndHour() + 1).set('minutes', 0);

        return DvbDateUtil.diff(startDateTime, endDateTime);
    });
    public dayDiff: Signal<number> = computed(() => DvbDateUtil.dayDiff(this.startDate(), this.endDate()));
    public isWeekView: Signal<boolean> = computed(() => 1 < this.dayDiff() && this.dayDiff() <= daysPerWeek);

    public grid: Signal<GridEntry[]> = computed(() => this.initGrid());

    private resizeState: WritableSignal<ResizeState | undefined> = signal(undefined);
    public resizeActive: Signal<boolean> = computed(() => {
        const resizeType = this.resizeState()?.resizeType;

        return resizeType === 'start' || resizeType === 'end';
    });

    public resize(
        resizeEvent: CalendarResizeEvent,
        minutes: number,
    ): boolean {
        const calendarEvent = resizeEvent.event;
        const resizeType = resizeEvent.resizeEvent.type;
        const layerEvents = resizeEvent.events;

        if (isNullish(this.resizeState())) {
            this.resizeState.set({
                resizeType,
                originalEvent: {...calendarEvent},
                eventIndex: resizeEvent.eventIndex,
                groupId: checkPresent(resizeEvent.groupId),
                resourceId: checkPresent(resizeEvent.resourceId),
                layer: checkPresent(resizeEvent.layer),
            });
        }
        const state = this.resizeState()!;

        let resizeApplied = false;

        switch (resizeType) {
            case 'start':
                resizeApplied = this.applyResizeVon(state, calendarEvent, layerEvents, minutes);
                break;

            case 'end':
                resizeApplied = this.applyResizeBis(state, calendarEvent, layerEvents, minutes);
                break;

            case 'move':
                resizeApplied = this.applyMove(state, calendarEvent, layerEvents, minutes);
                break;
        }

        return resizeApplied;
    }

    public getOriginalEvent(): CalendarEvent | undefined {
        return this.resizeState()?.originalEvent;
    }

    public resizeComplete(): void {
        this.resizeState.set(undefined);
    }

    private calculateEventSpaceholder(eventsByRow: CalendarEvent[][], grouping: {
        [row: string | symbol]: CalendarEvent[];
    }): void {
        const events = this.timelineCalculationStrategy().calculateSpace(
            eventsByRow,
            this.startHour() * DvbDateUtil.MINUTES_PER_HOUR,
            this.adjustedEndHour() * DvbDateUtil.MINUTES_PER_HOUR,
            this.startDate(),
            this.endDate(),
        );

        events.forEach((event, index) => {
            grouping[index] = event;
        });
    }

    private initGrid(): GridEntry[] {

        const dayInfo = this.dayInfo();
        const grid: GridEntry[] = [];

        if (this.fullDayBlocks()) {
            const dayDiff = this.dayDiff();
            for (let i = 0; i <= dayDiff; i++) {
                const date = moment(this.startDate()).add(i, 'days');
                const currentDayInfo = dayInfo.find(info => info.date!.isSame(date, 'day'));
                grid.push({
                    time: date,
                    durationToNext: 1,
                    dayInfo: currentDayInfo,
                });
            }
        } else {
            for (let i = this.startHour(); i <= this.adjustedEndHour(); i++) {
                grid.push({
                    time: moment(this.startDate()).hour(i),
                    durationToNext: DvbDateUtil.MINUTES_PER_HOUR,
                });
            }
        }

        return grid;
    }

    private applyResizeVon(
        state: ResizeState,
        calendarEvent: CalendarEvent,
        layerEvents: CalendarEvent[],
        minutes: number,
    ): boolean {
        const limitedMinutesVon = this.calculateEventVonResizeMinutes(state, calendarEvent, layerEvents, minutes);
        const newValueVon = moment(state.originalEvent.von).add(limitedMinutesVon, 'minutes');
        const minuteDiffVon = calendarEvent.von!.diff(newValueVon, 'minutes');
        if (minuteDiffVon !== 0) {
            this.resizeCalendarEventStart(calendarEvent, minuteDiffVon, newValueVon);

            return true;
        }

        return false;
    }

    private applyResizeBis(
        state: ResizeState,
        calendarEvent: CalendarEvent,
        layerEvents: CalendarEvent[],
        minutes: number,
    ): boolean {
        const limitedMinutesBis = this.calculateEventBisResizeMinutes(state, calendarEvent, layerEvents, minutes);
        const newValueBis = moment(state.originalEvent.bis).add(limitedMinutesBis, 'minutes');
        const minuteDiffBis = calendarEvent.bis!.diff(newValueBis, 'minutes');
        if (minuteDiffBis !== 0) {
            this.resizeCalendarEventEnd(calendarEvent, minuteDiffBis, newValueBis);

            return true;
        }

        return false;
    }

    private applyMove(
        state: ResizeState,
        calendarEvent: CalendarEvent,
        layerEvents: CalendarEvent[],
        minutes: number,
    ): boolean {
        const originalDiff = state.originalEvent.bis!.diff(state.originalEvent.von, 'minutes');
        const limitedMinutesVon = this.calculateEventVonResizeMinutes(state, calendarEvent, layerEvents, minutes);
        const limitedMinutesBis = this.calculateEventBisResizeMinutes(state, calendarEvent, layerEvents, minutes);
        let newValue: moment.Moment;
        let resizeApplied = false;

        if (limitedMinutesVon <= 0) {
            newValue = moment(state.originalEvent.von).add(limitedMinutesVon, 'minutes');
            if (calendarEvent.von!.diff(newValue, 'minutes') !== 0) {
                calendarEvent.von = newValue;
                calendarEvent.bis = moment(newValue).add(originalDiff, 'minutes');
                resizeApplied = true;
            }
        }
        if (limitedMinutesBis >= 0) {
            newValue = moment(state.originalEvent.bis).add(limitedMinutesBis, 'minutes');
            if (calendarEvent.bis!.diff(newValue, 'minutes') !== 0) {
                calendarEvent.bis = newValue;
                calendarEvent.von = moment(newValue).subtract(originalDiff, 'minutes');
                resizeApplied = true;
            }
        }

        return resizeApplied;
    }

    /**
     * Determines the amount of minutes to add to the events von time.
     * Ensures that event.von with the returned minutes added is not before this.startHour, not after event.bis and
     * intersecting any adjacent event.
     */
    private calculateEventVonResizeMinutes(
        state: ResizeState,
        calendarEvent: CalendarEvent,
        layerEvents: CalendarEvent[],
        minutes: number,
    ): number {
        const originalEvent = state.originalEvent;
        const originalTime = moment(state.resizeType === 'start' ? originalEvent.von : originalEvent.bis);
        const limitMin = state.eventIndex > 0 ?
            DvbDateUtil.diff(originalTime, layerEvents[state.eventIndex - 1].bis!) :
            -originalEvent.spaceBefore;
        const limitMax = DvbDateUtil.diff(originalTime, calendarEvent.bis!);

        return this.limitMinutes(minutes, limitMin, limitMax);
    }

    /**
     * Determines the amount of minutes to add to the events bis time.
     * Ensures that event.bis with the returned minutes added is not before event.von, not after this.endHour, and not
     * intersecting any adjacent events.
     */
    private calculateEventBisResizeMinutes(
        state: ResizeState,
        calendarEvent: CalendarEvent,
        layerEvents: CalendarEvent[],
        minutes: number,
    ): number {
        const originalEvent = state.originalEvent;
        const originalTime = moment(state.resizeType === 'start' ? originalEvent.von : originalEvent.bis);
        const limitMin = DvbDateUtil.diff(originalTime, calendarEvent.von!);
        const limitMax = state.eventIndex < layerEvents.length - 1 ?
            DvbDateUtil.diff(originalTime, layerEvents[state.eventIndex + 1].von!) :
            originalEvent.spaceAfter;

        return this.limitMinutes(minutes, limitMin, limitMax);
    }

    /**
     * Utility function for getting a minutes value that is limmitted by the given min and max values.
     */
    private limitMinutes(minutes: number, limitMin: number, limitMax: number): number {
        const limitted = Math.min(Math.max(minutes, limitMin), limitMax);

        return Math.round(limitted / this.resizeSteps()) * this.resizeSteps();
    }

    private recalculateEventsByGroupForResizedElement(): void {
        const resizeState = this.resizeState();
        if (isNullish(resizeState)) {
            return;
        }

        const groupId = resizeState.groupId;
        const resourceId = resizeState.resourceId;
        const layer = resizeState.layer;

        const resizeEvents = this.currentGrouping[groupId][resizeState.resourceId][resizeState.layer];
        const eventsByRow = Object.values(resizeEvents);
        this.currentGrouping[groupId][resourceId][layer] = {};
        this.calculateEventSpaceholder(eventsByRow, this.currentGrouping[groupId][resourceId][layer]);
    }

    private resizeCalendarEventStart(calendarEvent: CalendarEvent, minuteDiff: number, newValue: moment.Moment): void {
        calendarEvent.subEvents = calendarEvent.subEvents.map(subEvent => {
            subEvent.spaceBefore -= minuteDiff;
            subEvent.spaceAfter += minuteDiff;

            return subEvent;
        });
        calendarEvent.von = newValue;
    }

    private resizeCalendarEventEnd(calendarEvent: CalendarEvent, minuteDiff: number, newValue: moment.Moment): void {
        calendarEvent.subEvents = calendarEvent.subEvents.map(subEvent => {
            subEvent.spaceBefore += minuteDiff;
            subEvent.spaceAfter -= minuteDiff;

            return subEvent;
        });
        calendarEvent.bis = newValue;
    }
}
