<form name="zuweisenForm" class="dvb-zuweisen-form" novalidate>
    <h2 data-translate="KIND.ZUWEISUNG.OPTIONEN"></h2>

    <div class="row form-row">
        <div class="col-md-6">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.formModel.hasKindergarten">
                    <span data-translate="COMMON.KINDERGARTEN"></span>
                </label>
            </div>
        </div>
    </div>

    <div uib-collapse="!vm.formModel.hasKindergarten">
        <div class="row">
            <div class="col-md-6">
                <div class="radio">
                    <div class="radio" ng-repeat="type in vm.kindergartenTypes">
                        <label uib-tooltip="{{('KIND.ZUWEISUNG.KINDERGARTEN.' + type) | translate}}"
                               tooltip-placement="top-left">
                            <input type="radio"
                                   ng-required="vm.formModel.hasKindergarten"
                                   value="{{type}}"
                                   name="kgType"
                                   ng-model="vm.formModel.kgType">
                            <span data-translate="{{'KIND.ZUWEISUNG.KINDERGARTEN.' + type + '_SHORT'}}"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-required="vm.formModel.hasKindergarten"
                               value="intern"
                               name="kgLocation"
                               ng-model="vm.formModel.kgLocation">
                        <span data-translate="KIND.ZUWEISUNG.KINDERGARTEN.INTERN"></span>
                    </label>
                </div>

                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-required="vm.formModel.hasKindergarten"
                               value="extern"
                               name="kgLocation"
                               ng-model="vm.formModel.kgLocation">
                        <span data-translate="KIND.ZUWEISUNG.KINDERGARTEN.EXTERN"></span>
                    </label>
                </div>
            </div>
        </div>

        <div ng-if="vm.formModel.kgType === 'SCHULE'" class="row form-row">
            <div class="col-md-6">
                <input type="number"
                       class="form-control"
                       name="schulStufe"
                       ng-model="vm.formModel.schulStufe"
                       min="0"
                       ng-attr-placeholder="{{'KIND.ZUWEISUNG.KINDERGARTEN.SCHULSTUFE' | translate}}"
                       tooltip-placement="top-left"
                       uib-tooltip="{{'KIND.ZUWEISUNG.KINDERGARTEN.SCHULSTUFE' | translate}}">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <select class="form-control"
                        uib-tooltip="{{'KIND.ZUWEISUNG.KINDERGARTEN.BETREUUNG_IN_FERIENZEIT_AUSWAEHLEN' | translate}}"
                        tooltip-placement="top-left"
                        ng-model="vm.formModel.betreuungInFerienzeit"
                        ng-options="zeit as ('KIND.ZUWEISUNG.KINDERGARTEN.BETREUUNG_IN_FERIENZEIT.' + zeit | translate) for zeit in vm.betreuungInFerienzeit">
                </select>
            </div>
        </div>
    </div>

    <div class="row form-row">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.formModel.hasBetreuungsfaktor">
                    <span data-translate="KIND.ZUWEISUNG.SPEZ_BETREUUNGSFAKTOR"></span>
                </label>
            </div>
        </div>
    </div>

    <div class="row" uib-collapse="!vm.formModel.hasBetreuungsfaktor">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   name="betreuungsfaktor"
                   ng-model="vm.formModel.betreuungsfaktor"
                   ng-pattern="/^\d*(\.\d{0,2})?$/"
                   min="0.01"
                   ng-attr-placeholder="{{'KIND.ZUWEISUNG.SPEZ_BETREUUNGSFAKTOR' | translate}}"
                   uib-tooltip="{{'KIND.ZUWEISUNG.SPEZ_BETREUUNGSFAKTOR' | translate}}"
                   tooltip-placement="top-left">
        </div>
    </div>

    <div class="row form-row">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.formModel.eingewoehnungPhase">
                    <span data-translate="KIND.ZUWEISUNG.EINGEWOEHNUNG_PHASE"></span>
                </label>
            </div>
        </div>
    </div>

    <div class="row form-row" uib-collapse="!vm.formModel.eingewoehnungPhase">
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.formModel.eingewoehnungRange.gueltigAb"
                                       required
                                       name="eingewoehnungVon"
                                       dvb-is-valid="vm.formModel.eingewoehnungRange"
                                       dependent-control="zuweisenForm.eingewoehnungBis"
                                       ng-model-options="{ allowInvalid: true }"
                                       ng-attr-placeholder="{{'COMMON.VON' | translate}}">
            </dvb-datepicker-text-field>
        </div>
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.formModel.eingewoehnungRange.gueltigBis"
                                       required
                                       name="eingewoehnungBis"
                                       dvb-is-valid="vm.formModel.eingewoehnungRange"
                                       dependent-control="zuweisenForm.eingewoehnungVon"
                                       ng-model-options="{ allowInvalid: true }"
                                       ng-attr-placeholder="{{'COMMON.BIS' | translate}}">
            </dvb-datepicker-text-field>
        </div>
    </div>

    <div class="row form-row" dvb-auth require-permission="module:anwesenheit">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.formModel.noFlexiblePlaetze">
                    <span data-translate="KIND.ZUWEISUNG.NO_FLEXIBLE_PLAETZE"></span>
                </label>
            </div>
        </div>
    </div>

    <dvb-custom-field-values custom-field-values="vm.formModel.customFieldValues"
                             auto-save="false"
                             ng-if="vm.formModel.customFieldValues">
    </dvb-custom-field-values>

    <div dvb-auth require-permission="module:personalplanung" class="row form-row normal-abstand-top dvb-form">
        <div class="col-md-6">
            <h2 data-translate="PERSONAL.BEZUGSPERSON.PLURAL"></h2>

            <dvb-search-list entity-to-search="ANGESTELLTE"
                             ng-model="vm.formModel.bezugsPersonen"
                             placeholder-key="PERSONAL.BEZUGSPERSON.SEARCH">
            </dvb-search-list>
        </div>
    </div>

    <div class="row form-row normal-abstand-top">
        <div class="col-md-12">
            <h2 data-translate="COMMON.BEMERKUNG"></h2>
            <dvb-editable-text-area ng-attr-placeholder="{{'KIND.ZUWEISUNG.VERTRAGLICHE_BEMERKUNG' | translate}}"
                                    ng-model="vm.formModel.bemerkung"
                                    maxlength="5000">
            </dvb-editable-text-area>
        </div>
    </div>

    <div ng-if="vm.showMonatsBelegungStandard" class="row form-row normal-abstand-top">
        <div class="col-md-12">
            <h2 data-translate="KIND.MONATSBELEGUNG.PARENT_DEFAULT_VALUES"></h2>
            <button type="button"
                    tabindex="1"
                    class="btn btn-link btn-margin-sm feld"
                    ng-click="vm.openPopover($event, vm.formModel.standardPlatzTypen)">
                <dvb-platz-typen class="inline-icons no-margin"
                                 platz-typen="vm.formModel.standardPlatzTypen">
                </dvb-platz-typen>
            </button>
            <input type="number"
                   ng-model="vm.formModel.standardVertraglichesPensum"
                   min="0"
                   ng-attr-placeholder="{{'KIND.MONATSBELEGUNG.PARENT_DEFAULT_VALUES_VERTRAGLICHES_PENSUM' | translate}}"
                   uib-tooltip="{{'KIND.MONATSBELEGUNG.PARENT_DEFAULT_VALUES_VERTRAGLICHES_PENSUM' | translate}}"
                   tooltip-placement="top-left"
                   class="form-control normal-abstand-top"/>
        </div>
    </div>

    <!-- Buttons Zuweisen/Abbrechen -->
    <ul class="list-inline big-abstand-top">
        <li>
            <div class="btn-group" uib-dropdown data-keyboard-nav>
                <dvb-loading-button type="button"
                                    name="zuweisen-button"
                                    is-loading="vm.isLoading"
                                    dropdown="true"
                                    uib-dropdown-toggle>
                    <span data-translate="COMMON.KIND_ZUWEISEN"></span> <span class="caret"></span>
                </dvb-loading-button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                    <li ng-click="vm.zuweisen(vm.belegungsZustand.PROVISORISCH, zuweisenForm)">
                        <a href="" data-translate="KIND.ZUWEISUNG.ZUWEISEN_PROVISORISCH"></a>
                    </li>
                    <li ng-click="vm.zuweisen(vm.belegungsZustand.ANGEBOT_ERSTELLT, zuweisenForm)">
                        <a href="" data-translate="KIND.ZUWEISUNG.ZUWEISEN_ANGEBOT_ERSTELLEN"></a>
                    </li>
                    <li ng-click="vm.zuweisen(vm.belegungsZustand.BELEGT, zuweisenForm)">
                        <a href="" data-translate="KIND.ZUWEISUNG.ZUWEISEN_DEFINITIV"></a>
                    </li>
                </ul>
            </div>
        </li>
        <li><a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.onCancel()"></a></li>
    </ul>
</form>

<dvb-zuweisen-popover ng-if="!vm.parentView && vm.showMonatsBelegungStandard"
                      element-id="'parentDefaultPlatzart'"
                      kita="vm.kinderOrte[0]"
                      zuweisung-ab="vm.zuweisungAb"
                      set-subventioniert="vm.formModel.standardPlatzTypen.setSubventioniert(subventioniertesKontingent)"
                      set-privat="vm.formModel.standardPlatzTypen.setPrivat()"
                      set-firma="vm.formModel.standardPlatzTypen.setFirma(firmenKontingent)">
</dvb-zuweisen-popover>
