/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind, Rechnungsempfaenger} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';

export class KindValidationService {
    public static $inject: readonly string[] = [];

    public hasValidRechnungsempfaenger(kind: Kind): boolean {
        const rechnungsempfaenger = kind.findRechnungsempfaenger();

        if (!Array.isArray(rechnungsempfaenger) || !this.hasOnlyPositiveValues(rechnungsempfaenger)) {
            return false;
        }

        const totalPercentage = rechnungsempfaenger
                .filter(r => r.isProzent())
                .map(r => checkPresent(r.value))
                .reduce((a, b) => a + b, 0);

        const hundred = 100;

        return totalPercentage === hundred;
    }

    public hasValidErziehungsberechtigte(kind: Kind): boolean {
        const erziehungsberechtigte = kind.findErziehungsberechtigte();

        if (!Array.isArray(erziehungsberechtigte)) {
            return false;
        }

        return 0 <= erziehungsberechtigte.length && erziehungsberechtigte.length <= 2;
    }

    private hasOnlyPositiveValues(rechnungsempfaenger: Rechnungsempfaenger[]): boolean {
        return rechnungsempfaenger.length === rechnungsempfaenger
                .filter(r => r.value !== null)
                .map(r => checkPresent(r.value))
                .filter(r => r > 0).length;
    }
}
