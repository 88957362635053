/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbUtil, EntitySelection} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {KindBringAbholZeitenAccess} from '../../models/bring-abhol-zeiten/KindBringAbholZeitenAccess';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        bringAbholZeitenAccess: '<',
        onResend: '&',
        onAdjustDeadline: '&',
        onWithdrawFromParents: '&',
    },
    template: require('./dvb-communication-kind-sent.html'),
    controllerAs: 'vm',
};

export class DvbCommunicationKindSent extends EntitySelection<Kind> implements angular.IController {
    public static override $inject: readonly string[] = ['$translate'];

    public bringAbholZeitenAccess!: KindBringAbholZeitenAccess[];

    public onResend!: (val: { kinder: Kind[] }) => void;
    public onAdjustDeadline!: (val: { kinder: Kind[] }) => void;
    public onWithdrawFromParents!: (val: { kinder: Kind[] }) => void;

    private readonly expiredText: string;

    public constructor(
        $translate: angular.translate.ITranslateService,
    ) {
        super();
        this.expiredText = $translate.instant('COMMUNICATION.EXPIRED');
    }

    public resend(): void {
        const selected = this.getSelected(this.getKinder());
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        this.onResend({
            kinder: selected,
        });
    }

    public adjustDeadline(): void {
        const selected = this.getSelected(this.getKinder());
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        this.onAdjustDeadline({
            kinder: selected,
        });
    }

    public withdrawFromParents(): void {
        const selected = this.getSelected(this.getKinder());
        if (!DvbUtil.isNotEmptyArray(selected)) {
            return;
        }

        this.onWithdrawFromParents({
            kinder: selected,
        });
    }

    public getDeadline(access: KindBringAbholZeitenAccess): string {
        if (access.writeAccessUntil?.isSameOrAfter(moment())) {
            return access.writeAccessUntil.format('DD.MM.YYYY');
        }

        return this.expiredText;
    }

    public override selectAll(): void {
        super.selectAll(this.getKinder());
    }

    public accessComparator(access: KindBringAbholZeitenAccess): string {
        return access.kind.getDisplayName();
    }

    private getKinder(): Persisted<Kind>[] {
        return this.bringAbholZeitenAccess.map(access => access.kind);
    }
}

componentConfig.controller = DvbCommunicationKindSent;
angular.module('kitAdmin').component('dvbCommunicationKindSent', componentConfig);
