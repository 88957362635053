/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DialogService} from '@dv/kitadmin/ui';
import type {DeployedVersionsService, UserLanguageService} from '@dv/shared/angular';
import type {DeployedVersions, SupportedLanguage} from '@dv/shared/code';
import {LogFactory} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {from} from 'rxjs';
import {directiveSubSink} from '../../../common/directive/directive-scope-subsink';

const LOG = LogFactory.createLog('DvbVersion');

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-version.html'),
    controllerAs: 'vm',
};

export class DvbVersion implements angular.IController {

    public static $inject: readonly string[] = [
        '$scope',
        'dialogService',
        '$window',
        '$q',
        '$translate',
        'userLanguageService',
        'deployedVersionsService',
    ];

    public currentYear: number = moment().year();
    public deployedVersions?: DeployedVersions;
    public hasVersionMismatch: boolean = false;
    public language: SupportedLanguage | null = null;

    private isModalOpen: boolean = false;

    public constructor(
        private readonly $scope: angular.IScope,
        private readonly dialogService: DialogService,
        private readonly $window: angular.IWindowService,
        private readonly $q: angular.IQService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly userLanguageService: UserLanguageService,
        private readonly deployedVersionsService: DeployedVersionsService,
    ) {
    }

    public $onInit(): void {
        const subSink = directiveSubSink(this.$scope);

        subSink.sink = this.deployedVersionsService.getDeployedVersions$().subscribe({
            next: deployedVersions => {
                this.deployedVersions = deployedVersions;
                this.hasVersionMismatch = deployedVersions.versionMismatch();
                if (this.hasVersionMismatch) {
                    this.handleMismatch();
                }
            },
            error: (err: unknown) => LOG.error(err),
        });

        subSink.sink = this.userLanguageService.userLanguage$
            .subscribe({
                next: language => {
                    this.language = language;
                },
                error: (err: unknown) => LOG.error(err),
            });
    }

    public switchLanguage(language: SupportedLanguage): void {
        this.userLanguageService.changeLanguage(language);
    }

    private handleMismatch(): void {
        if (this.isModalOpen) {
            return;
        }

        this.isModalOpen = true;

        this.$translate.onReady().then(() => this.showDialog());
    }

    private showDialog(): void {
        const version = this.deployedVersions?.backendVersion;
        this.dialogService.openConfirmDialog({
            // Providing backendVersion as it's the version we want the frontend to match
            title: this.$translate.instant('VERSION.VERSION_MISMATCH', {version}),
            subtitle: this.$translate.instant('VERSION.DO_UPDATE'),
            confirmActionText: this.$translate.instant('VERSION.AKTUALISIEREN'),
            confirm: () => from(this.reloadApp()),
        });
    }

    private reloadApp(): angular.IPromise<void> {
        this.$window.location.reload();

        return this.$q.resolve();
    }
}

componentConfig.controller = DvbVersion;
angular.module('kitAdmin').component('dvbVersion', componentConfig);
