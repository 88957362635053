/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AnmeldungKindKontaktperson} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
    },
    template: require('./dvb-anmeldung-kind-kontaktperson-display.html'),
    controllerAs: 'vm',
};

export class DvbAnmeldungKindKontaktpersonDisplay implements angular.IController {

    public kontaktperson?: AnmeldungKindKontaktperson;

}

componentConfig.controller = DvbAnmeldungKindKontaktpersonDisplay;
angular.module('kitAdmin').component('dvbAnmeldungKindKontaktpersonDisplay', componentConfig);
