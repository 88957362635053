/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Wochenplan} from '@dv/kitadmin/models';
import {AbstractPagesController, BelegungsEinheit, ZeitraumUtil} from '@dv/kitadmin/models';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import type {DayOfWeek, RestInclude, RestPaginated} from '@dv/shared/code';
import {DvbDateUtil, zeitraumComparator} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {DvbRestUtilAngularJS} from '../../../common/service/rest/dvbRestUtilAngularJS';
import type {WochenplanService} from '../../../common/service/rest/wochenplanService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        wochenplan: '<',
    },
    template: require('./dvb-belegungs-einheiten.html'),
    controllerAs: 'vm',
};

export class DvbBelegungsEinheiten extends AbstractPagesController<BelegungsEinheit> implements angular.IController {
    public static override $inject: readonly string[] = ['wochenplanService', '$q', 'errorService', '$state'];

    public wochenplan!: Wochenplan;

    public readonly maxNumberPerPage: number = 10;
    public zeitraeumeByBelegungsEinheit: Map<string, string> = new Map<string, string>();
    public wochentageByBelegungsEinheit: Map<string, string> = new Map<string, string>();
    public showCreateForm = false;
    public newBelegungsEinheit = new BelegungsEinheit();

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly wochenplanService: WochenplanService,
        private readonly $q: angular.IQService,
        private readonly errorService: ErrorService,
        private $state: StateService,
    ) {
        super();
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const params: RestPaginated & RestInclude = {
            includes: '(zeitraumIds)',
        };
        this.setPagionationValues(params);
        const config = {timeout: this.timeout.promise};

        this.wochenplanService.getBelegugsEinheitenByWocheplanId(this.wochenplan.id!, params, config)
            .then(container => {
                this.pageContainer = container;
                this.isLoading = false;
                this.pageContainer?.items.forEach(belegungsEinheit => {
                    this.initWochentage(belegungsEinheit);
                    this.initBelegungsZeitraeume(belegungsEinheit);
                });
            });
    }

    public create(form: angular.IFormController): void {
        this.errorService.clearAll();

        const isValid = form.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!isValid) {
            return;
        }

        this.isLoading = true;
        this.wochenplanService.createBelegungseinheit(this.wochenplan.id!, this.newBelegungsEinheit)
            .then(() => {
                this.newBelegungsEinheit = new BelegungsEinheit();
                this.showCreateForm = false;
                this.isLoading = false;
                this.$state.reload();
            });
    }

    public update(context: AccordionContext, belegungsEinheit: BelegungsEinheit): void {
        this.errorService.clearAll();
        this.wochenplanService.updateBelegungseinheit(this.wochenplan.id!, belegungsEinheit)
            .then(() => {
                context.completeSubmission();
                this.reloadItems();
            })
            .catch(() => {
                context.cancelSubmission();
            });
    }

    public delete(context: AccordionContext, belegungsEinheit: BelegungsEinheit): void {
        this.errorService.clearAll();
        this.wochenplanService.deleteBelegungseinheit(belegungsEinheit)
            .then(() => {
                context.completeSubmission();
                this.reloadItems();
            })
            .catch(() => {
                context.cancelSubmission();
            });
    }

    private initBelegungsZeitraeume(belegungsEinheit: BelegungsEinheit): void {

        const zeitraumNames = belegungsEinheit.zeitraumIds.map(zeitraumId =>
            this.wochenplan.distinctZeitraeume.find(belegungsZeitraum => belegungsZeitraum.id === zeitraumId))
            .sort((a, b) => zeitraumComparator(a!, b!))
            .map(zeitraum => zeitraum!.getDisplayName())
            .join(', ');

        this.zeitraeumeByBelegungsEinheit.set(belegungsEinheit.id!, zeitraumNames);
    }

    private initWochentage(belegungsEinheit: BelegungsEinheit): void {
        const weekDays: DayOfWeek[] = [];
        this.wochenplan.tagesplaene.filter(tagesplan =>
            tagesplan.belegungsEinheiten.some(be => be.id === belegungsEinheit.id),
        ).forEach(tagesplan => {
            weekDays.push(tagesplan.wochentag);
        });
        this.wochentageByBelegungsEinheit.set(belegungsEinheit.id!, weekDays.sort(ZeitraumUtil.DAY_OF_WEEK_COMPARATOR)
            .map(day => DvbDateUtil.getDayOfWeekMoment(day, moment())
                .format('dd')).join(', '));
    }

}

componentConfig.controller = DvbBelegungsEinheiten;
angular.module('kitAdmin').component('dvbBelegungsEinheiten', componentConfig);
