/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ParamDeclaration, StateDeclaration, StateService} from '@uirouter/core';
import angular from 'angular';

export class DvbStateService {
    public static $inject: readonly string[] = ['$state', '$window'];

    private previousState: { state?: StateDeclaration; params?: { [key: string]: ParamDeclaration } } = {};

    public constructor(
        private $state: StateService,
        private $window: angular.IWindowService,
    ) {
    }

    /**
     * @param next if true, set previous state as next state
     */
    public goToPreviousState(next: boolean = false): Promise<unknown> {
        if (!next || !this.previousState.state?.name) {
            this.$window.history.back();

            return Promise.resolve();
        }

        return this.$state.go(this.previousState.state, this.previousState.params);
    }

    public updatePreviousState(
        fromState: StateDeclaration,
        fromParams?: { [key: string]: ParamDeclaration },
    ): void {
        if (this.previousState.state &&
            this.previousState.state.name === fromState.name &&
            angular.equals(this.previousState.params, fromParams)) {

            return;
        }

        this.previousState.state = fromState;
        this.previousState.params = fromParams;
    }
}
