/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    Betreuungsfaktor,
    FirmenKontingent,
    KinderOrt,
    KinderOrtFraktion,
    KinderOrtFraktionId,
    Kontingente,
    SubventioniertesKontingent,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import type {FunctionType, Persisted} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import {ZeitraumFeldClickModifier} from '../../../common/directive/dvb-wochenplan/ZeitraumFeldClickModifier';
import {WochenplanUtil} from '../../../common/service/wochenplanUtil';
import type {KindZuweisenService} from '../../service/kindZuweisenService';
import type {KindKinderOrtZuweisung} from '../dvb-kind-zuweisen/KindKinderOrtZuweisung';
import {ZuweisungPopoverHelper} from '../zuweisung-popover-helper';
import ClickEvent = JQuery.ClickEvent;

const missingKapazitaet = 'KINDERORT.ZEITRAUMFELD_MISSING_KAPAZITET';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        model: '<',
        showKinderOrtName: '<',
        isFullyCollapsible: '<',
        showAnwesenheiten: '<?',
        goToMonat: '&',
    },
    template: require('./dvb-kind-kita-zuweisen.html'),
    controllerAs: 'vm',
};

export class DvbKindKitaZuweisen implements angular.IController {
    public static $inject: readonly string[] = [
        'kindZuweisenService',
        'errorService',
        '$document',
        '$scope',
        'authStore',
    ];

    public model!: KindKinderOrtZuweisung;
    public kinderOrt!: Persisted<KinderOrt>;
    public showKinderOrtName: boolean = false;
    public isFullyCollapsible: boolean = true;
    public showAnwesenheiten: boolean = false;
    public goToMonat!: FunctionType;

    public kontingente: Kontingente[] = [];
    public betreuungsfaktor: Betreuungsfaktor | null = null;
    public popoverHelper!: ZuweisungPopoverHelper;

    public fullyCollapsedGroups: { [k in KinderOrtFraktionId]?: boolean } = {};

    private lastSelectedKontingent: Kontingente | null = null;

    public constructor(
        private readonly kindZuweisenService: KindZuweisenService,
        private readonly errorService: ErrorService,
        private readonly $document: angular.IDocumentService,
        private readonly $scope: angular.IScope,
    ) {
    }

    public $onInit(): void {
        this.showAnwesenheiten = this.showAnwesenheiten || false;
        this.kinderOrt = this.model.kinderOrtZuweisung.kinderOrt;
        this.kontingente = this.model.kinderOrtZuweisung.kontingente;

        this.popoverHelper = new ZuweisungPopoverHelper(this.$document, this.$scope, this.kinderOrt.id);
        this.popoverHelper.init();

    }

    public $onDestroy(): void {
        this.popoverHelper.destroy();
    }

    public setPrivat(): void {
        const zeitraumFeld = this.zeitraumFeldZuweisen(null);
        WochenplanUtil.setPrivat(zeitraumFeld);
        const fraktion = checkPresent(this.popoverHelper.popoverZuweisungContent.gruppe);
        this.kindZuweisenService.addFieldsToGruppenBelegung(fraktion, null, this.model);
    }

    public setSubventioniert(kontingent: SubventioniertesKontingent): void {
        WochenplanUtil.setSubventioniert(this.zeitraumFeldZuweisen(kontingent), kontingent);
        const fraktion = checkPresent(this.popoverHelper.popoverZuweisungContent.gruppe);
        this.kindZuweisenService.addFieldsToGruppenBelegung(fraktion, kontingent.id, this.model);
    }

    public setFirma(kontingent: FirmenKontingent): void {
        WochenplanUtil.setFirma(this.zeitraumFeldZuweisen(kontingent), kontingent);
        const fraktion = checkPresent(this.popoverHelper.popoverZuweisungContent.gruppe);
        this.kindZuweisenService.addFieldsToGruppenBelegung(fraktion, kontingent.id, this.model);
    }

    public setFullyCollapsed(isCollapsed: boolean, fraktionId: KinderOrtFraktionId): void {
        this.fullyCollapsedGroups[fraktionId] = isCollapsed;
    }

    public onZeitraumFeldClicked(
        zeitraumFeld: ZeitraumFeld,
        event: ClickEvent,
        modifier: ZeitraumFeldClickModifier,
        fraktion: KinderOrtFraktion,
    ): void {
        if (modifier === ZeitraumFeldClickModifier.LEFT) {
            this.updateZuweisung(zeitraumFeld, event, fraktion);
        } else {
            this.repeatZuweisung(zeitraumFeld, event, fraktion);
        }
    }

    /**
     * @param {ZeitraumFeld} zeitraumFeld
     * @param {ClickEvent} event
     * @param {KinderOrtFraktion} fraktion the ZeitraumFeld belongs to this KinderOrtFraktion
     */
    private updateZuweisung(
        zeitraumFeld: ZeitraumFeld,
        event: ClickEvent,
        fraktion: KinderOrtFraktion,
    ): void {
        // Disable automatic selection
        zeitraumFeld.selected = !zeitraumFeld.selected;

        if (zeitraumFeld.selected) {
            this.kindZuweisenService.undoZuweisung(zeitraumFeld, fraktion, this.model);

            return;
        }

        if (this.kindZuweisenService.isZuweisungAllowed(zeitraumFeld, fraktion, this.model)) {
            this.errorService.clearErrorByMsgKey(missingKapazitaet);
            this.showAddBelegungPopup(zeitraumFeld, event, fraktion);
        } else {
            this.errorService.addValidationError(missingKapazitaet);
        }
    }

    private repeatZuweisung(
        zeitraumFeld: ZeitraumFeld,
        event: ClickEvent,
        fraktion: KinderOrtFraktion,
    ): void {
        // Disable automatic selection
        zeitraumFeld.selected = !zeitraumFeld.selected;
        event.preventDefault();

        if (!this.kindZuweisenService.isZuweisungAllowed(zeitraumFeld, fraktion, this.model)) {
            this.errorService.addValidationError(missingKapazitaet);

            return;
        }
        this.errorService.clearErrorByMsgKey(missingKapazitaet);

        if (zeitraumFeld.selected) {
            return;
        }

        this.popoverHelper.popoverZuweisungContent.zeitraumFeld = zeitraumFeld;
        this.popoverHelper.popoverZuweisungContent.gruppe = fraktion;
        this.zeitraumFeldZuweisen(this.lastSelectedKontingent);
        if (this.lastSelectedKontingent) {
            if (this.lastSelectedKontingent.isSubventioniertesKontingent()) {
                WochenplanUtil.setSubventioniert(zeitraumFeld, this.lastSelectedKontingent);
            } else {
                WochenplanUtil.setFirma(zeitraumFeld, this.lastSelectedKontingent);
            }
            this.kindZuweisenService.addFieldsToGruppenBelegung(fraktion, this.lastSelectedKontingent.id, this.model);
        } else {
            WochenplanUtil.setPrivat(zeitraumFeld);
            this.kindZuweisenService.addFieldsToGruppenBelegung(fraktion, null, this.model);
        }
    }

    private zeitraumFeldZuweisen(kontingent: Kontingente | null): ZeitraumFeld {
        this.lastSelectedKontingent = kontingent;
        const zeitraumFeld = checkPresent(this.popoverHelper.popoverZuweisungContent.zeitraumFeld);
        if (zeitraumFeld.backup) {
            // Icon entfernen wenn platzTyp not equal
            zeitraumFeld.icon = WochenplanUtil.isPlatzTypEqual(zeitraumFeld.backup.kontingent, kontingent) ?
                zeitraumFeld.backup.icon :
                null;
        } else {
            zeitraumFeld.backup = angular.copy(zeitraumFeld);
        }
        zeitraumFeld.selected = true;
        zeitraumFeld.kontingent = kontingent;
        this.popoverHelper.cancelPopover();
        // increase belegte plaetze
        const fraktion = this.popoverHelper.popoverZuweisungContent.gruppe!;
        this.kindZuweisenService.addZeitraumFeldZuweisung(zeitraumFeld, fraktion, this.model);

        return zeitraumFeld;
    }

    private showAddBelegungPopup(zeitraumFeld: ZeitraumFeld, event: ClickEvent, fraktion: KinderOrtFraktion): void {
        event.stopPropagation();
        const previousZeitraumFeld = this.popoverHelper.popoverZuweisungContent.zeitraumFeld;
        const isSameAsLastTime = angular.equals(zeitraumFeld, previousZeitraumFeld);

        if (previousZeitraumFeld && !isSameAsLastTime) {
            // Undo active marking
            previousZeitraumFeld.active = false;
        }

        if (isSameAsLastTime && this.popoverHelper.isOpen()) {
            // close popup
            this.popoverHelper.cancelPopover();

            return;
        }

        // Mark field as active
        zeitraumFeld.active = true;
        this.popoverHelper.popoverZuweisungContent.zeitraumFeld = zeitraumFeld;
        this.popoverHelper.popoverZuweisungContent.gruppe = fraktion;

        // Update the position

        // sicherstellen, dass wir ein ZeitraumFeld als Parent haben
        const parentElement = angular.element(event.target).closest('.feld');
        this.popoverHelper.open(parentElement);
    }
}

componentConfig.controller = DvbKindKitaZuweisen;
angular.module('kitAdmin').component('dvbKindKitaZuweisen', componentConfig);
