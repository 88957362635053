<ul class="list-inline list-inline-row">
    <li ng-if="vm.entry.rechnung">
        <a ui-sref="base.rechnung.uebersicht({rechnungId: vm.entry.rechnung.id})"
           data-translate="FAKTURA.RECHNUNG_ANSEHEN">
        </a>
    </li>
    <li ng-if="vm.entry.status === 'NICHT_ZUGEWIESEN' || vm.entry.status === 'IGNORIERT'">
        <a ui-sref="base.kontenabgleich.zuweisen.vorgeschlagen({id: vm.entry.id})"
           data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTION_ZUWEISEN">
        </a>
    </li>
    <li ng-if="vm.entry.status === 'NICHT_ZUGEWIESEN'">
        <button type="button"
                class="btn btn-link"
                data-translate="COMMON.IGNORIEREN"
                ng-click="vm.ignorieren()">
        </button>
    </li>
    <li ng-if="vm.entry.status === 'ZUGEWIESEN'">
        <button type="button"
                class="btn btn-link"
                data-translate="FAKTURA.KONTENABGLEICH.ZUWEISUNG_LOESCHEN"
                ng-click="vm.zuweisungLoeschen()">
        </button>
    </li>
    <li ng-if="vm.entry.status === 'IGNORIERT'">
        <button type="button"
                class="btn btn-link"
                data-translate="FAKTURA.KONTENABGLEICH.NICHT_IGNORIEREN"
                ng-click="vm.resetIgnore()">
        </button>
    </li>
</ul>
