/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {FilterModelController} from '../shared/FilterModelController';
import type {IsFilterActive} from '../shared/MicroFilterAttribute';
import {MicroFilterAttribute} from '../shared/MicroFilterAttribute';
import type {IPopoverFilterProperty} from './IPopoverFilterProperty';

export class PopoverFilterController<T extends IPopoverFilterProperty<TModel, TPopoverModel>, TModel, TPopoverModel>
    extends FilterModelController<T, TModel> {

    public constructor(
        model: T,
        filter: IsFilterActive<T> = filterModel => filterModel.selected,
    ) {
        super(new MicroFilterAttribute(model, filter));
    }

    public hasChanged(selected: boolean): void {
        this.model.filterModel.selected = selected;
        this.notifyChangeListeners();
    }

    public reset(): void {
        this.model.filterModel.selected = false;
        this.filterModelChanged();
    }

    protected filterAcceptsUserModel(userModel?: TModel): boolean {
        return this.model.filterModel.acceptUserModel(userModel);
    }
}
