/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';
import {DvbRestUtil} from '@dv/shared/code';

export class BringAbholZeitenAccess {

    public constructor(
        public readAccessUntil: moment.Moment | null = null,
        public writeAccessUntil: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): BringAbholZeitenAccess {
        return new BringAbholZeitenAccess(
            DvbRestUtil.localDateTimeToMoment(data.readAccessUntil),
            DvbRestUtil.localDateTimeToMoment(data.writeAccessUntil),
        );
    }

    public hasWriteAccess(): boolean {
        return this.writeAccessUntil !== null && moment().isSameOrBefore(this.writeAccessUntil);
    }

    public hasReadAccess(): boolean {
        return this.readAccessUntil !== null && moment().isSameOrBefore(this.readAccessUntil);
    }
}
