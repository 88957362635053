<div class="dvb-betreuungsperson-umzug">
    <h2 data-translate="COMMON.TITLE_UMZUG"></h2>

    <form name="umzugForm"
          ng-submit="vm.submit(umzugForm)"
          novalidate>
        <div class="row">
            <div class="col-md-6">
                <dvb-datepicker-text-field ng-model="vm.limitedAdresse.gueltigAb"
                                           required
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dvb-adresse-form ng-model="vm.limitedAdresse.adresse"></dvb-adresse-form>
            </div>
        </div>

        <div class="row normal-abstand-top">
            <div class="col-md-12">
                <ul class="list-inline">
                    <li>
                        <dvb-loading-button type="submit" is-loading="umzugForm.$pending || vm.isLoading">
                            <span data-translate="COMMON.SPEICHERN"></span>
                        </dvb-loading-button>
                    </li>
                    <li>
                        <a href="" ng-click="vm.onCancel()">
                            <span data-translate="COMMON.ABBRECHEN"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </form>
</div>
