/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnDestroy} from '@angular/core';
import {Injectable} from '@angular/core';
import type {Nullish} from '@dv/shared/code';
import {isNullish, isPresent} from '@dv/shared/code';

/**
 * Service for loading custom css styles.
 */
@Injectable({
    providedIn: 'root',
})
export class CustomStyleService implements OnDestroy {

    private styleElem: HTMLStyleElement | null = null;

    public ngOnDestroy(): void {
        this.removeStyle();
    }

    public addStyle(style: string | Nullish): void {
        this.addStyleInternal(style);
    }

    public removeStyle(): void {
        if (isPresent(this.styleElem)) {
            this.styleElem.remove();
            this.styleElem = null;
        }
    }

    private addStyleInternal(style: string | Nullish): void {
        this.removeStyle();
        if (isNullish(style)) {
            return;
        }

        this.styleElem = document.createElement('style');
        document.head.appendChild(this.styleElem);
        this.styleElem.appendChild(document.createTextNode(style));
    }
}
