/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Password, RestCache} from '@dv/kitadmin/models';
import {TempBlob} from '@dv/kitadmin/models';
import {mapIfData} from '@dv/shared/angular';
import type {BenutzerService as Api} from '@dv/shared/backend/api/benutzer.service';
import type {Persisted, RestInclude} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import {firstValueFrom, map} from 'rxjs';
import {Benutzer} from '../../../../benutzer/model/Benutzer';

export class BenutzerService {
    public static $inject: readonly string[] = ['benutzerApiService'];

    public constructor(
        private benutzerApiService: Api,
    ) {
    }

    /**
     * Get Benutzer by Id
     */
    public get(benutzerId: string, params?: RestInclude): Promise<Persisted<Benutzer>> {
        return firstValueFrom(this.benutzerApiService.getBenutzerById$({
            benutzerId,
            benutzerIdMatrix: DvbRestUtil.toIncludes(params),
        }).pipe(
            map(Benutzer.apiResponseTransformer),
            map(checkPersisted),
        ));
    }

    /**
     * Get principal Benutzer
     */
    public getCurrent(params?: RestInclude): Promise<Persisted<Benutzer>> {
        return firstValueFrom(this.benutzerApiService.getCurrent$({current: DvbRestUtil.toIncludes(params)}).pipe(
            map(Benutzer.apiResponseTransformer),
            map(checkPersisted),
        ));
    }

    /**
     * Get All Benutzer which are active.
     */
    public getAllActive(params?: RestCache & RestInclude): Promise<Benutzer[]> {
        return firstValueFrom(
            this.benutzerApiService.getAllActive$({benutzer: DvbRestUtil.toIncludes(params)}).pipe(
                map(data => DvbRestUtil.transformArray(data.benutzer, Benutzer)),
                map(benutzer => benutzer.map(checkPersisted)),
            ),
        );
    }

    public update(benutzer: Persisted<Benutzer>): Promise<unknown> {
        return firstValueFrom(this.benutzerApiService.updateBenutzer$({
            benutzerId: benutzer.id,
            jaxUpdateBenutzer: benutzer.toRestObject(),
        }));
    }

    public updatePassword(benutzer: Persisted<Benutzer>, password: Password): Promise<unknown> {
        return firstValueFrom(this.benutzerApiService.updatePassword$({
            benutzerId: benutzer.id,
            jaxPassword: password.toRestObject(),
        }));
    }

    public benutzerLoeschen(benutzerId: string): Promise<unknown> {
        return firstValueFrom(this.benutzerApiService.deleteBenutzer$({benutzerId}));
    }

    public sendPrivacyConsent(benutzerId: string): Promise<unknown> {
        return firstValueFrom(this.benutzerApiService.consentToPrivacy$({benutzerId}));
    }

    public getCSVExport(): Promise<TempBlob> {
        return firstValueFrom(this.benutzerApiService.getCSVExport$('response').pipe(
            mapIfData(r => TempBlob.apiResponseTransformer(r.body)),
        ));
    }

    public getRelevantKinderOrte(benutzerId: string): Promise<string[]> {
        return firstValueFrom(this.benutzerApiService.getRelevantKinderortIds$({benutzerId})).then(response => {
            if (Array.isArray(response.ids)) {
                return response.ids;
            }

            return Promise.reject(response);
        });
    }

    public createPasskey(benutzerId: string): Promise<unknown> {
        return firstValueFrom(this.benutzerApiService.createPasskey$({benutzerId}));
    }
}
