/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        visible: '<?',
        onAccept: '&?',
        onDecline: '&?',
    },
    template: require('./dvb-external-anmeldung-privacy-confirmation.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungPrivacyConfirmation implements angular.IController {
    public static $inject: readonly string[] = [];

    public visible: boolean = true;

    public onAccept?: FunctionType;
    public onDecline?: FunctionType;

    public accept(): void {
        this.visible = false;

        if (TypeUtil.isFunction(this.onAccept)) {
            this.onAccept();
        }
    }

    public decline(): void {
        this.visible = false;

        if (TypeUtil.isFunction(this.onDecline)) {
            this.onDecline();
        }
    }
}

componentConfig.controller = DvbExternalAnmeldungPrivacyConfirmation;
angular.module('kitAdmin').component('dvbExternalAnmeldungPrivacyConfirmation', componentConfig);
