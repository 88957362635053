<div class="dvb-gruppe-erstellen">
    <div class="form-section">
        <div class="row">
            <div class="col-md-6">
                <input type="text"
                       name="gruppenName"
                       class="form-control"
                       maxlength="255"
                       ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                       uib-tooltip="{{'COMMON.NAME' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.fraktion.name"
                       required>
            </div>
            <div class="col-md-6">
                <select class="form-control"
                        uib-tooltip="{{'BETREUUNGS_ANGEBOT.NAME' | translate}}"
                        tooltip-placement="top-left"
                        ng-model="vm.fraktion.betreuungsAngebotType"
                        ng-options="type as ('BETREUUNGS_ANGEBOT.' + type | translate) for type in vm.betreuungsAngebotTypes">
                    <option ng-value="null" data-translate="BETREUUNGS_ANGEBOT.NONE"></option>
                </select>
            </div>
        </div>
    </div>
</div>
