/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DisplayableWithBadge, Selectable} from '@dv/shared/code';
import {DvbUtil, TypeUtil} from '@dv/shared/code';
import type {IOnChangesObject} from 'angular';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        selected: '<ngModel',
        options: '<',
        placeholderKey: '@',
        onSelectClear: '<?',
        onChange: '&?',
    },
    template: require('./dvb-multi-select.html'),
    controllerAs: 'vm',
};

export class DvbMultiSelect<T extends Selectable<DisplayableWithBadge>> implements angular.IController {
    public static $inject: readonly string[] = [];

    public selected: T[] = [];
    public options: T[] = [];
    public placeholderKey!: string;
    public onSelectClear: boolean = true;
    public onChange?: () => void;

    public selectInput?: string | T;

    public readonly ngModelCtrl!: angular.INgModelController;

    public $onChanges(onChangesObj: IOnChangesObject): void {
        if (!onChangesObj.options) {
            return;
        }
        // an empty array is considered empty, such that required forces at least 1 entry
        this.ngModelCtrl.$isEmpty = (value): boolean => !DvbUtil.isNotEmptyArray(value);

        // allowInvalid, such that the input array does not get set to undefined
        this.ngModelCtrl.$overrideModelOptions({allowInvalid: true});

        const ids = this.selected.map(s => s.id);
        this.options.forEach(o => o.selected = ids.includes(o.id));
    }

    public select($item?: T): void {
        if (!$item) {
            return;
        }

        if (this.onSelectClear) {
            this.selectInput = '';
        }

        if (this.selected.some(a => a.id === $item.id)) {
            return;
        }

        $item.selected = true;
        this.selected.push($item);
        this.ngModelCtrl.$validate();

        if (TypeUtil.isFunction(this.onChange)) {
            this.onChange();
        }
    }

    public remove(option: T): void {
        DvbUtil.removeFromArray(option, this.selected);
        option.selected = false;
        this.ngModelCtrl.$validate();

        if (TypeUtil.isFunction(this.onChange)) {
            this.onChange();
        }
    }
}

componentConfig.controller = DvbMultiSelect;
angular.module('kitAdmin').component('dvbMultiSelect', componentConfig);
