<div class="dvb-iso-locale"
     uib-tooltip="{{vm.placeholder}}"
     tooltip-placement="top-left">
    <script type="text/ng-template" id="searchPreviewTemplate2.html">
        <a>
            <span ng-bind-html="match.model.displayName"></span>
        </a>
    </script>
    <input type="text"
           autocomplete="off"
           ng-keyup="vm.cancel($event)"
           ng-focus="vm.onFocus($event)"
           ng-model="vm.isoObject"
           ng-change="vm.onChange()"
           maxlength="100"
           class="form-control"
           ng-attr-placeholder="{{vm.placeholder}}"
           uib-typeahead="isoObj as isoObj.displayName for isoObj in vm.isoObjects | filter:{displayName:$viewValue} | orderBy : 'displayName' | limitTo:10"
           typeahead-focus-on-select="false"
           typeahead-on-select="vm.onSelect($item, $model, $label, $event)"
           typeahead-template-url="searchPreviewTemplate2.html"
           typeahead-editable="true"
           typeahead-select-on-exact="true"
           typeahead-min-length="1"
           typeahead-select-on-blur="false"
           typeahead-focus-first="true">
</div>
