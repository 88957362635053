<div class="dvb-kitas-report">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="vm.kitasReportForm" novalidate>

        <dvb-report-template-selector
            report-state="vm.reportState"
            on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-kitas-selection parameter-kinder-orte="vm.parameterKinderOrte"
                             on-change="vm.updateState()">
        </dvb-kitas-selection>

        <div class="row form-row" ng-show="vm.showYear">
            <div class="col-xs-12">
                <input type="number"
                       ng-model="vm.parameterKinderOrte.year"
                       ng-change="vm.updateState()"
                       min="2000"
                       max="3000"
                       step="1"
                       class="form-control"
                       dvb-integer
                       ng-attr-placeholder="{{'COMMON.YEAR' | translate}}: {{vm.defaultYear}}"
                       uib-tooltip="{{'COMMON.YEAR' | translate}}: {{vm.parameterKinderOrte.year || vm.defaultYear}}"
                       tooltip-placement="top-left">
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
