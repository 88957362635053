<div class="dvb-custom-field-configuration dvb-form">
    <div class="row form-row">
        <div class="col-md-7">
            <h2 data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.TITLE"></h2>

            <form ng-submit="vm.submit()"
                  name="vm.submitCustomFieldConfigurationForm">
                <div class="row">
                    <div class="col-md-12">
                        <input type="text"
                               name="configName"
                               dvb-capitalize-first-letter
                               class="form-control"
                               maxlength="255"
                               ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                               uib-tooltip="{{'COMMON.NAME' | translate}}"
                               tooltip-placement="top-left"
                               ng-model="vm.customFieldConfiguration.name"
                               required>
                    </div>
                </div>

                <!-- Kind -->
                <div class="row form-row">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" ng-model="vm.customFieldConfiguration.allKindFields">
                                <span data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.KIND"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div ng-show="!vm.customFieldConfiguration.allKindFields" class="form-row">
                    <div class="row" ng-repeat="customField in vm.customFieldsByNamedEntityType['KIND']">
                        <div class="col-md-12">
                            <div class="checkbox">
                                <label class="small-abstand-left">
                                    <input type="checkbox"
                                           ng-model="customField.selected">
                                    <span ng-bind="customField.name"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Belegungen -->
                <div class="row form-row">
                    <div class="col-md-12">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" ng-model="vm.customFieldConfiguration.allBelegungFields">
                                <span data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.BELEGUNG"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div ng-show="!vm.customFieldConfiguration.allBelegungFields" class="form-row">
                    <div class="row" ng-repeat="customField in vm.customFieldsByNamedEntityType['BELEGUNG']">
                        <div class="col-md-12">
                            <div class="checkbox">
                                <label class="small-abstand-left">
                                    <input type="checkbox"
                                           ng-model="customField.selected">
                                    <span ng-bind="customField.name"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row normal-abstand-top">
                    <div class="col-md-12">
                        <textarea class="form-control"
                                  ng-model="vm.customFieldConfiguration.bemerkung"
                                  ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                  uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                                  tooltip-placement="top-left"
                                  maxlength="5000"
                                  rows="6"></textarea>
                    </div>
                </div>

                <dvb-submit-cancel-buttons is-loading="vm.isLoading" on-cancel="vm.cancel()">
                </dvb-submit-cancel-buttons>
            </form>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <h2 data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.ERSTELLT"></h2>
            <p ng-if="vm.customFieldConfigurations.length === 0"
               data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.KEINE"></p>
            <dvb-collapsible-panel ng-repeat="customFieldConfig in vm.customFieldConfigurations | orderBy: 'name'"
                                   is-collapsed="true">
                <panel-header>
                    <p>
                        <span ng-bind="customFieldConfig.name"></span>
                    </p>
                </panel-header>
                <panel-content>
                    <p>
                        <span ng-bind="customFieldConfig.name"></span>
                    </p>

                    <p class="nl2br" ng-bind="customFieldConfig.bemerkung"></p>

                    <p ng-if="customFieldConfig.allBelegungFields"
                       data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.BELEGUNG">
                    </p>
                    <p ng-if="customFieldConfig.allKindFields"
                       data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.KIND">
                    </p>

                    <div ng-repeat="customField in customFieldConfig.customFieldConfigEntries | orderBy: ['entityType', 'name']"
                         class="small-abstand-left">
                        <p ng-bind="customField.name"></p>
                    </div>

                    <button type="button"
                            class="btn btn-link normal-abstand-top"
                            ng-click="vm.delete(customFieldConfig)"
                            data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.LOESCHEN">
                    </button>
                </panel-content>
            </dvb-collapsible-panel>

        </div>
    </div>
</div>
