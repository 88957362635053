<div class="dvb-editable-text-area click2edit">
    <textarea class="form-control"
              msd-elastic
              ng-model="editableText"
              ng-attr-placeholder="{{placeholder}}"
              uib-tooltip="{{placeholder}}"
              tooltip-placement="top-left"
              rows="1"
              maxlength="{{maxlength}}">
    </textarea>
</div>
