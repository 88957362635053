<div class="dvb-kontaktperson-kontakt row">
    <div class="col-md-7 big-abstand-bottom dvb-form">
        <div class="form-section-minus-normal-abstand">
            <h2 data-translate="KONTAKTPERSON.WOHNADRESSE"></h2>
            <div ng-if="!!vm.newLimitedAdresse">
                <form name="addAddressForm"
                      ng-submit="addAddressForm.$valid && vm.addAddress()"
                      novalidate>
                    <dvb-adresse-form ng-model="vm.newLimitedAdresse.adresse">
                    </dvb-adresse-form>
                    <div class="row normal-abstand-top">
                        <div class="col-md-12">
                            <ul class="list-inline">
                                <li>
                                    <button type="submit" class="btn btn-primary">
                                        <span data-translate="COMMON.SPEICHERN"></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
            <dvb-adresse-verlauf ng-if="!vm.newLimitedAdresse"
                                 limited-adressen="vm.kontaktperson.adressen"
                                 kontaktperson-id="vm.kontaktperson.id"
                                 on-update-limited-adresse="vm.adresseKorrigieren(limitedAdresse, adresse)"
                                 on-delete-limited-adresse="vm.deleteLimitedAdresse(limitedAdresse)">
            </dvb-adresse-verlauf>

            <div class="big-abstand-top" ng-if="vm.showUmzug">
                <dvb-umzug kontaktperson-id="vm.kontaktperson.id"
                           on-save="vm.saveUmzug(umzug, context)"
                           on-cancel="vm.cancelUmzug()">
                </dvb-umzug>
            </div>

            <!-- main menu bar-->
            <div class="main-actions hidden-print"
                 dvb-auth
                 require-permission="kita:manage:general"
                 require-condition="!vm.showUmzug"
                 ng-if="!vm.newLimitedAdresse">
                <ul class="list-inline">
                    <li>
                        <button type="button" class="btn btn-primary" ng-click="vm.showUmzug = true">
                            <span data-translate="COMMON.UMZUG_ERFASSEN"></span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
