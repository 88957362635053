<div class="dvb-filter-option-popover">
    <div class="btn label label-radio label-default"
         ng-model="vm.filterModel.selected"
         ng-change="vm.toggle()"
         uib-btn-checkbox>

        <span ng-bind="vm.filterModel.popoverModel.getLabel()"></span>

        <script ng-if="vm.editable && vm.filterModel.popoverModel.type === 'vonBis'"
                type="text/ng-template"
                id="vonBis.html">
            <form class="popover-filter" ng-submit="vm.apply()" novalidate>
                <div class="row text-left">
                    <dvb-datepicker-text-field ng-model="vm.workingCopy.gueltigAb"
                                               small-inputs="true"
                                               ng-attr-placeholder="{{'COMMON.VON' | translate}}"
                                               class="col-md-6">
                    </dvb-datepicker-text-field>
                    <dvb-datepicker-text-field ng-model="vm.workingCopy.gueltigBis"
                                               small-inputs="true"
                                               ng-attr-placeholder="{{'COMMON.BIS' | translate}}"
                                               class="col-md-6">
                    </dvb-datepicker-text-field>
                </div>
                <button class="btn btn-sm btn-link small-abstand-top"
                        type="submit"
                        data-translate="COMMON.FILTER_APPLY">
                </button>
            </form>
        </script>

        <script ng-if="vm.editable && vm.filterModel.name === 'pensum'" type="text/ng-template" id="pensum.html">
            <form class="popover-filter pensum" ng-submit="vm.apply()" novalidate>
                <dvb-pensum pensum="vm.workingCopy"
                            validate="false"
                            small-inputs="true">
                </dvb-pensum>
                <button class="btn btn-sm btn-link small-abstand-top"
                        type="submit"
                        data-translate="COMMON.FILTER_APPLY">
                </button>
            </form>
        </script>

        <i ng-if="vm.editable"
           uib-popover-template="'{{vm.filterModel.popoverModel.type}}.html'"
           popover-append-to-body="true"
           popover-is-open="vm.isOpen"
           popover-trigger="'outsideClick'"
           ng-click="$event.stopPropagation()"
           class="fa fa-pencil small-abstand-left">
        </i>

        <i ng-if="vm.closeable"
           ng-click="vm.onClose(); $event.stopPropagation()"
           class="fa fa-times small-abstand-left hidden-print">
        </i>
    </div>
</div>
