<div class="dvb-parameter-kinderkontaktliste">

    <div class="form-section-minus-normal-abstand">
        <p class="error-message"
           ng-if="!vm.parameterKinderkontaktliste.hasValidKinderOrtOrGruppe()"
           data-translate="ERRORS.ERR_KITA_ODER_GRUPPE">
        </p>

        <p class="error-message"
           ng-if="!vm.parameterKinderkontaktliste.hasValidWartelisteConstelation()"
           data-translate="ERRORS.ERR_WARTELISTE_MIT_GRUPPEN">
        </p>

        <div class="checkbox">
            <label>
                <input type="checkbox"
                       ng-model="vm.parameterKinderkontaktliste.alleKinderOrte"
                       ng-change="vm.onChange()">
                <span data-translate="COMMON.ALLE_KINDERORTE"></span>
            </label>
        </div>

        <div ng-if="!vm.parameterKinderkontaktliste.alleKinderOrte">
            <dvb-search-list entity-to-search="KINDERORT"
                             ng-if="!vm.parameterKinderkontaktliste.hasGruppen()"
                             ng-model="vm.parameterKinderkontaktliste.kinderOrte"
                             on-added="vm.onChange()"
                             on-removed="vm.onChange()"
                             placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                             required>
            </dvb-search-list>

            <dvb-search-list entity-to-search="KINDERORT_FRAKTION"
                             ng-if="!vm.parameterKinderkontaktliste.hasKinderOrte()"
                             ng-model="vm.parameterKinderkontaktliste.gruppen"
                             placeholder-key="COMMON.GRUPPE_ODER_BETREUUNGS_PERSON.AUSWAEHLEN"
                             required>
            </dvb-search-list>
        </div>
    </div>

    <div class="form-section-minus-normal-abstand">
        <p class="error-message"
           ng-if="!vm.parameterKinderkontaktliste.hasValidPlaetze()"
           data-translate="ERRORS.ERR_PLATZ">
        </p>

        <div class="form-row" dvb-auth require-permission="kita:control:general">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.parameterKinderkontaktliste.allePlaetze">
                    <span data-translate="REPORT.ALLE_PLAETZE"></span>
                </label>
            </div>
        </div>

        <div ng-if="!vm.parameterKinderkontaktliste.allePlaetze" class="form-section-minus-normal-abstand">
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           value="true"
                           ng-model="vm.parameterKinderkontaktliste.subventionierterPlatz">
                    <span data-translate="COMMON.SUBVENTIONIERTER_PLATZ"></span>
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           value="true"
                           ng-model="vm.parameterKinderkontaktliste.privaterPlatz">
                    <span data-translate="COMMON.PRIVATER_PLATZ"></span>
                </label>
            </div>
            <div class="checkbox" ng-repeat="firma in vm.parameterKinderkontaktliste.firmen">
                <label>
                    <input type="checkbox"
                           value="firma.id"
                           ng-model="firma.isDisabled"
                           ng-true-value="false"
                           ng-false-value="true">
                    <span data-translate="COMMON.PLATZART.TITLE"
                          data-translate-value-name="{{firma.text}}"></span>
                </label>
            </div>
            <dvb-search-on-click-entity entity-to-search="FIRMA"
                                        on-select="vm.addFirma(item)"
                                        on-select-clear="true"
                                        disabled-entries="vm.parameterKinderkontaktliste.firmen"
                                        add-label-key="COMMON.PLATZART.HINZUFUEGEN"
                                        placeholder-key="COMMON.PLATZART.AUSWAEHLEN">
            </dvb-search-on-click-entity>
        </div>
    </div>

    <div class="form-section-minus-normal-abstand">
        <p class="error-message"
           ng-if="!vm.parameterKinderkontaktliste.hasValidKontaktTypes()"
           data-translate="ERRORS.ERR_ONE_KONTAKT_REQUIRED">
        </p>

        <div class="form-row">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.parameterKinderkontaktliste.alleKontakte">
                    <span data-translate="REPORT.ALLE_KONTAKTE"></span>
                </label>
            </div>
        </div>

        <div ng-show="!vm.parameterKinderkontaktliste.alleKontakte">
            <div ng-repeat="kontaktType in vm.kontaktTypes">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               ng-model="kontaktType.selected"
                               ng-change="vm.toggleKontaktType(kontaktType)">
                        <span data-translate="{{'KONTAKTPERSON.' + kontaktType.value}}"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="form-section-minus-normal-abstand">
        <p class="error-message"
           ng-if="!vm.parameterKinderkontaktliste.hasValidAufWartelisteAndMitBelegung()"
           data-translate="ERRORS.ERR_WARTELISTE_BELEGUNG">
        </p>

        <div class="row">
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" ng-model="vm.parameterKinderkontaktliste.mitBelegung">
                        <span data-translate="REPORT.MIT_BELEGUNG"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" ng-model="vm.parameterKinderkontaktliste.aufWarteliste">
                        <span data-translate="REPORT.AUF_WARTELISTE"></span>
                    </label>
                </div>
            </div>
        </div>

        <div ng-if="vm.parameterKinderkontaktliste.mitBelegung">
            <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="vm.parameterKinderkontaktliste.useDateRange">
                    <span data-translate="COMMON.DATE_RANGE"></span>
                </label>
            </div>

            <div class="row" ng-if="vm.parameterKinderkontaktliste.useDateRange">
                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.parameterKinderkontaktliste.gueltigAb"
                                               ng-change="vm.onChange()"
                                               ng-attr-placeholder="{{'COMMON.VON' | translate}}: {{vm.parameterKinderkontaktliste.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}">
                    </dvb-datepicker-text-field>
                </div>

                <div class="col-md-6">
                    <dvb-datepicker-text-field ng-model="vm.parameterKinderkontaktliste.gueltigBis"
                                               ng-change="vm.onChange()"
                                               ng-attr-placeholder="{{'COMMON.BIS' | translate}}: {{vm.parameterKinderkontaktliste.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}">
                    </dvb-datepicker-text-field>
                </div>
            </div>

            <div class="row" ng-if="!vm.parameterKinderkontaktliste.useDateRange">
                <div class="col-md-12">
                    <dvb-datepicker-text-field ng-model="vm.parameterKinderkontaktliste.stichtag"
                                               ng-change="vm.onChange()"
                                               ng-attr-placeholder="{{'REPORT.STICHTAGBELEGUNG' | translate}}: {{vm.parameterKinderkontaktliste.stichtag || vm.defaultStichtag | amDateFormat: 'DD.MM.YYYY'}}">
                    </dvb-datepicker-text-field>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <dvb-datepicker-text-field ng-model="vm.parameterKinderkontaktliste.stichtagAdresse"
                                       ng-change="vm.onChange()"
                                       ng-attr-placeholder="{{'REPORT.STICHTAGADRESSE' | translate}}: {{vm.parameterKinderkontaktliste.stichtagAdresse || vm.defaultStichtag | amDateFormat: 'DD.MM.YYYY'}}">
            </dvb-datepicker-text-field>
        </div>
    </div>
</div>
