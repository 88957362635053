/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import {CONFIG} from '../../../../config';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-timeout-test.html'),
    controllerAs: 'vm',
};

/* eslint-disable @typescript-eslint/no-magic-numbers */
export class DvbMaintenanceTimeoutTest implements angular.IController {
    public static $inject: readonly string[] = ['$http', '$q'];

    public isLoading: boolean = false;
    public seconds: number = 4;
    public timeoutType: 'PROMISE' | 'SECONDS' = 'PROMISE';
    public timeoutSeconds: number = 5;

    private deferred: angular.IDeferred<unknown> | null = null;

    public constructor(
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) {
    }

    public testTimeout(form: angular.IFormController): void {
        if (form.$invalid) {
            return;
        }

        this.isLoading = true;
        const url = `${CONFIG.restBackend}/api/v1/auth/login/admin/timeout/wait/` +
            `${encodeURIComponent(String(this.seconds))}`;

        const config: angular.IRequestShortcutConfig = {};

        if (this.timeoutType === 'PROMISE') {
            this.deferred = this.$q.defer();
            config.timeout = this.deferred.promise;
        } else if (this.timeoutType === 'SECONDS') {
            config.timeout = this.timeoutSeconds * 1000;
        }

        this.$http.get(url, config).catch(rejectReason => {
            console.warn(rejectReason);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public abortRequest(): void {
        if (!this.deferred) {
            return;
        }

        this.deferred.resolve();
    }
}

componentConfig.controller = DvbMaintenanceTimeoutTest;
angular.module('kitAdmin').component('dvbMaintenanceTimeoutTest', componentConfig);
