/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Persisted} from '@dv/shared/code';
import angular from 'angular';
import type {PersonalKonfiguration} from '../../../../personal/konfiguration/PersonalKonfiguration';
import {PERSONAL_STATES} from '../../../../personal/personal-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfigurationen: '<',
        newKonfiguration: '<',
    },
    template: require('./dvb-kinderort-personal-konfiguration.html'),
    controllerAs: 'vm',
};

export class DvbKinderortPersonalKonfiguration implements angular.IController {
    public static $inject: readonly string[] = [];

    public konfigurationen: Persisted<PersonalKonfiguration>[] = [];
    public newKonfiguration!: PersonalKonfiguration;

    public personalStates: typeof PERSONAL_STATES = PERSONAL_STATES;
}

componentConfig.controller = DvbKinderortPersonalKonfiguration;
angular.module('kitAdmin').component('dvbKinderortPersonalKonfiguration', componentConfig);
