/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPrincipal} from '@dv/shared/authentication/model';
import * as sentry from '@sentry/angular-ivy';
import type {BrowserOptions} from '@sentry/browser';
import type {CaptureContext} from '@sentry/types';
import type {ErrorEvent} from '@sentry/types/types/event';
import type {Integration} from '@sentry/types/types/integration';

export type SentryConfig = {
    dsn: string;
    environment: string;
    version: string;
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: number;
    beforeSend?: BrowserOptions['beforeSend'];
    beforeSendTransaction?: BrowserOptions['beforeSendTransaction'];
    integrations?: Integration[];
};

export function initSentry(config: SentryConfig): void {
    sentry.init(sentryOptions(config));
}

export function clearSentryUser(): void {
    sentry.setUser(null);
}

export function setSentryUser(principal: IPrincipal): void {
    sentry.setUser({
        id: principal?.userId ?? undefined,
        username: principal.username ?? undefined,
        mandant: principal.mandantName,
    });
}

let sentryEnabled = (window as any)?.env?.SENTRY_ENABLED ?? false;

export function isSentryEnabled(): boolean {
    return sentryEnabled;
}

export function enableSentry(): void {
    sentryEnabled = true;
    const options = sentry.getClient()?.getOptions();
    if (options) {
        options.enabled = true;
    }
}

export function disableSentry(): void {
    sentryEnabled = false;
    const options = sentry.getClient()?.getOptions();
    if (options) {
        options.enabled = false;
    }
}

export function errorToSentry(exception: Error, captureContext?: CaptureContext): void {
    sentry.captureException(exception, captureContext);
}

function sentryOptions(config: SentryConfig): BrowserOptions {
    const dsn = parseDsn(config);
    const integrations = mergeIntegrations(config);
    const beforeSend = parseBeforeSend(config);
    const enabled = isSentryEnabled();

    return {
        dsn,
        release: config.version,
        environment: config.environment,
        enabled,
        integrations,
        tracePropagationTargets: ['localhost', new RegExp(/\/kitadmin\/api\/.+/)],
        tracesSampleRate: config.tracesSampleRate,
        beforeSend,
    };
}

function parseDsn(config: SentryConfig): string {
    const [usr, prj] = config.dsn.split('/');
    const host = window.location.host;
    if (!usr || !prj) {
        return '';
    }

    return `https://${usr}@${host}/sentry/${prj}`;
}

function mergeIntegrations(config: SentryConfig): Integration[] {
    const integrations: Integration[] = [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        sentry.browserTracingIntegration(),
    ];
    if (config.integrations) {
        integrations.push(...config.integrations);
    }

    return integrations;
}

function parseBeforeSend(config: SentryConfig): SentryConfig['beforeSend'] {
    if (config.beforeSend) {
        return config.beforeSend;
    }

    return (data: ErrorEvent) => sentryEnabled ? data : null;
}
