<div class="dvb-external-anmeldung-kontakt-form">

    <div class="row">
        <div class="col-md-12">
            <div class="row small-abstand-bottom">
                <div class="col-md-6">
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   name="isOrganisation"
                                   ng-value="false"
                                   ng-change="vm.kontaktperson.updateOrganisation()"
                                   ng-model="vm.kontaktperson.isOrganisation">
                            <span data-translate="COMMON.PRIVAT"></span>
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   name="isOrganisation"
                                   ng-change="vm.kontaktperson.updateOrganisation()"
                                   ng-value="true"
                                   ng-model="vm.kontaktperson.isOrganisation">
                            <span data-translate="COMMON.FIRMA.SINGULAR"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-6" ng-if="vm.kontaktperson.isOrganisation">
                    <input type="text"
                           class="form-control no-margin"
                           dvb-capitalize-first-letter
                           maxlength="255"
                           ng-model="vm.kontaktperson.organisation"
                           ng-attr-placeholder="{{'COMMON.ORGANISATION' | translate}}"
                           uib-tooltip="{{'COMMON.ORGANISATION' | translate}}"
                           tooltip-placement="top-left"
                           ng-required="vm.kontaktperson.isOrganisationRequired()">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           dvb-capitalize-first-letter
                           ng-model="vm.kontaktperson.familienName"
                           name="familienName"
                           maxlength="255"
                           ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                           uib-tooltip="{{'COMMON.NAME' | translate}}"
                           tooltip-placement="top-left"
                           ng-required="vm.kontaktperson.isNameRequired()">
                </div>
                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           dvb-capitalize-first-letter
                           ng-model="vm.kontaktperson.vorName"
                           name="vorName"
                           maxlength="255"
                           ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                           uib-tooltip="{{'COMMON.VORNAME' | translate}}"
                           tooltip-placement="top-left"
                           ng-required="vm.kontaktperson.isNameRequired()">
                </div>
            </div>
            <div class="row form-row">
                <div ng-class="{'col-md-6': vm.form.$submitted, 'col-md-12': !vm.form.$submitted}">
                    <div class="radio" ng-repeat="geschlecht in vm.geschlechter">
                        <label>
                            <input type="radio"
                                   name="geschlecht_{{$id}}"
                                   value="{{geschlecht}}"
                                   ng-model="vm.kontaktperson.geschlecht"
                                   required>
                            <span data-translate="EXTERNAL_ANMELDUNG.GESCHLECHT.{{geschlecht}}"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-6" ng-show="vm.form.$submitted && !vm.kontaktperson.geschlecht">
                    <p class="error-message"
                       data-translate="ERRORS.ERR_REQUIRED_GESCHLECHT"></p>
                </div>
            </div>
            <dvb-adresse-form ng-if="vm.kontaktperson.addressMode === 'OWN'"
                              ng-model="vm.kontaktperson.adresse"
                              is-address-required="true">
            </dvb-adresse-form>

            <div class="row form-row" ng-if="!vm.kontaktperson.hauptkontakt">
                <div class="col-md-12">
                    <div class="radio" ng-repeat="addressMode in vm.addressModes">
                        <label>
                            <input type="radio"
                                   name="addressMode_{{$id}}"
                                   value="{{addressMode}}"
                                   ng-model="vm.kontaktperson.addressMode"
                                   ng-change="vm.changeKontaktAdresse()"
                                   required>
                            <span data-translate="COMMON.ADDRESS_MODE.{{addressMode}}"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           ng-model="vm.kontaktperson.telefon"
                           maxlength="100"
                           ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                           uib-tooltip="{{'COMMON.TELEFON' | translate}}"
                           tooltip-placement="top-left"
                           ng-required="vm.kontaktperson.hauptkontakt && !vm.kontaktperson.mobile">
                </div>
                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           ng-model="vm.kontaktperson.mobile"
                           maxlength="100"
                           ng-attr-placeholder="{{'COMMON.MOBILE_NR' | translate}}"
                           uib-tooltip="{{'COMMON.MOBILE_NR' | translate}}"
                           tooltip-placement="top-left"
                           ng-required="vm.kontaktperson.hauptkontakt && !vm.kontaktperson.telefon">
                </div>
                <div class="col-md-6">
                    <input type="text"
                           class="form-control"
                           maxlength="100"
                           ng-model="vm.kontaktperson.geschaeft"
                           ng-attr-placeholder="{{'COMMON.GESCHAEFT_NR' | translate}}"
                           uib-tooltip="{{'COMMON.GESCHAEFT_NR' | translate}}"
                           tooltip-placement="top-left">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <input type="email"
                           class="form-control"
                           maxlength="255"
                           ng-model="vm.kontaktperson.email"
                           ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                           uib-tooltip="{{'COMMON.E-MAIL' | translate}}"
                           tooltip-placement="top-left">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <dvb-iso-locale ng-model="vm.kontaktperson.korrespondenzsprache"
                                    type="LANGUAGE"
                                    ng-attr-placeholder="{{'COMMON.KORRESPONDENZSPRACHE' | translate}}">
                    </dvb-iso-locale>
                </div>
            </div>
        </div>
    </div>

    <div ng-if="vm.kontaktperson.customFieldValues.length > 0" class="normal-abstand-bottom">
        <dvb-custom-field-values custom-field-values="vm.kontaktperson.customFieldValues"
                                 click-to-edit="false">
        </dvb-custom-field-values>
    </div>

    <div class="relationship-flags row">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           name="erziehungsberechtigter"
                           ng-model="vm.kontaktperson.erziehungsberechtigt">
                    <span data-translate="KONTAKTPERSON.ERZIEHUNGSBERECHTIGTER"></span>
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                           name="abholberechtigt"
                           ng-model="vm.kontaktperson.abholberechtigt">
                    <span data-translate="KONTAKTPERSON.ABHOLBERECHTIGT"></span>
                </label>
            </div>
        </div>
    </div>

    <div ng-if="!vm.kontaktperson.hauptkontakt" class="kontakt-actions normal-abstand-top">
        <ul class="list-unstyled">
            <li>
                <a href=""
                   ng-click="vm.removeKontaktperson()"
                   data-translate="KIND.KONTAKT_ENTFERNEN"></a>
            </li>
        </ul>
    </div>
</div>
