<div class="dvb-benutzer-profil row">
    <div class="col-md-7">
        <form name="benutzerForm" class="dvb-form" novalidate>
            <h2 data-translate="BENUTZER.STAMMDATEN_BENUTZER"></h2>

            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isRequired(param)"
                                        ng-model="vm.workingCopyBenutzer.familienName"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="shared:manage_users:general"
                                        require-condition="vm.editAllowed"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isRequired(param)"
                                        ng-model="vm.workingCopyBenutzer.vorName"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                        ng-change="vm.saveBasisData()"
                                        dvb-auth
                                        require-permission="shared:manage_users:general"
                                        require-condition="vm.editAllowed"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row small-abstand-bottom">
                <div class="col-md-12">
                    <dvb-editable-label is-valid="vm.isRequired(param)"
                                        ng-model="vm.workingCopyBenutzer.username"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'BENUTZER.USERNAME' | translate}}"
                                        is-email="true"
                                        type="email"
                                        ng-change="vm.updateUsername()"
                                        dvb-auth
                                        require-permission="shared:manage_users:general"
                                        require-condition="vm.editAllowed && vm.canChangeUsernameAndPassword"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>

            <div class="row" ng-if="vm.editAllowed && vm.canChangeUsernameAndPassword">
                <div class="col-md-12">
                    <dvb-change-password on-submit="vm.updatePassword(password, formContext)"
                                         uib-tooltip="{{'BENUTZER.PASSWORD_AENDERN' | translate}}"
                                         tooltip-placement="top-left">
                    </dvb-change-password>
                </div>
            </div>

            <div class="row big-abstand-top" ng-if="vm.deleteAllowed">
                <div class="col-md-12">
                    <ul class="list-inline">
                        <li>
                            <a href=""
                               data-translate="COMMON.LOESCHEN"
                               ng-click="vm.showConfirmDeleteBenutzerModal()">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>

    <div class="col-md-4 col-md-offset-1 dvb-form"
         dvb-auth
         require-permission="{{ {oneOf: ['feature:stundenerfassung_tfo:general', 'personal:view:general']} }}">

        <div dvb-auth
             require-permission="{{vm.anstellungStates.ANGESTELLTE_CREATE_STATE.data.permission}}"
             require-condition="!vm.benutzer.angestellteId">
            <h2 data-translate="COMMON.AKTIONEN"></h2>

            <p>
                <a ui-sref="{{vm.anstellungStates.ANGESTELLTE_CREATE_STATE.name + '({benutzerId: ' + vm.benutzer.id + '})'}}">
                    + <span data-translate="PERSONAL.ANGESTELLTE.CREATE"></span></a>
            </p>
            <p>
                <dvb-loading-button is-loading="vm.isPasskeyLoading"
                                    spinner-right="true"
                                    as-link="true"
                                    on-click="vm.createPasskey()">
                    + <span data-translate="COMMON.BENUTZER.PASSKEY_ERSTELLEN"></span>
                </dvb-loading-button>
            </p>
        </div>

        <div ng-if="vm.benutzer.angestellte">
            <div dvb-auth
                 require-permission="{{ vm.anstellungStates.ANGESTELLTE_PROFIL_STATE.data.permission({id: vm.benutzer.angestellte.id}) }}"
                 class="form-section">
                <h2 data-translate="PERSONAL.ANGESTELLTE.SINGULAR"></h2>

                <div class="form-row">
                    <div class="row selected-entity">
                        <div class="col-md-9">
                            <a class="entity"
                               ui-sref="{{vm.anstellungStates.ANGESTELLTE_PROFIL_STATE.name + '({id: ' + vm.benutzer.angestellte.id + '})'}}"
                               ng-bind="vm.benutzer.angestellte.getDisplayName()">
                            </a>
                        </div>
                        <div class="col-md-3 edit hidden-print"
                             dvb-auth
                             require-permission="shared:manage_users:general">
                            <button class="btn btn-link"
                                    data-translate="COMMON.LOESCHEN"
                                    ng-click="vm.removeAngestellte()">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
