/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsGutscheinGroup} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        middle: '?middle',
        bottom: '?bottom',
    },
    bindings: {
        betreuungsGutscheinGroup: '<',
    },
    template: require('./dvb-kind-tarife-betreuungsgutschein-header-kitax.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeBetreuungsgutscheinHeaderKitax implements angular.IController {
    public static $inject: readonly string[] = [];

    public betreuungsGutscheinGroup!: BetreuungsGutscheinGroup;
}

componentConfig.controller = DvbKindTarifeBetreuungsgutscheinHeaderKitax;
angular.module('kitAdmin').component('dvbKindTarifeBetreuungsgutscheinHeaderKitax', componentConfig);
