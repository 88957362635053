<div class="dvb-limited-entity-assignment-controls">

    <div class="big-abstand-top" ng-if="vm.formContext.editMode">
        <h2 data-translate="{{vm.translationRoot + '.NEUE_ZUWEISUNG'}}"></h2>

        <dvb-limited-entity-form translation-root="vm.translationRoot"
                                 is-loading="vm.loadingContext.isLoading"
                                 on-submit="vm.save()"
                                 on-cancel="vm.formContext.close()">
            <entity-form ng-transclude="entityForm">
            </entity-form>
        </dvb-limited-entity-form>
    </div>

    <div class="big-abstand-top" ng-if="!vm.formContext.editMode">
        <ul class="list-inline">
            <li>
                <button type="button"
                        class="btn btn-primary"
                        ng-click="vm.defineNew()"
                        data-translate="{{vm.translationRoot + '.NEUE_ZUWEISUNG'}}">
                </button>
            </li>
            <li ng-if="vm.entities.length > 0 && !vm.hideTermination">
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.terminate()"
                        data-translate="{{vm.translationRoot + '.ZUWEISUNG_BEENDEN'}}">
                </button>
            </li>
        </ul>
    </div>

</div>
