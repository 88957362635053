/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isEsrKontoLike, isQrIbanLike} from '@dv/kitadmin/models';
import angular from 'angular';

angular.module('kitAdmin').directive('dvbEinzahlungsscheinKonto', dvbEinzahlungsscheinKonto);

function dvbEinzahlungsscheinKonto(): angular.IDirective {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: (_scope, _element, _attrs, ctrl: any): void => {
            const ngModel: angular.INgModelController = ctrl;

            ngModel.$validators.kontoNummer = validator;

            function validator(_modelValue: any, viewValue: any): boolean {
                return ngModel.$isEmpty(viewValue) ? true : isEsrKontoLike(viewValue) || isQrIbanLike(viewValue);
            }
        },
    };
}
