/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BetreuungsPerson} from '@dv/kitadmin/models';
import {DvbUtil, Geschlecht, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {FraktionService} from '../../../../../common/service/rest/kinderort/fraktionService';
import {ANSTELLUNG_STATES} from '../../../../../personal/anstellung/anstellung-states';
import type {AngestellteService} from '../../../../../personal/anstellung/service/angestellteService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsPerson: '<',
    },
    template: require('./dvb-betreuungsperson-profil.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungspersonProfil implements angular.IController {
    public static $inject: readonly string[] = ['fraktionService', 'errorService', 'angestellteService'];

    public betreuungsPerson!: BetreuungsPerson;
    public geschlechter: readonly Geschlecht[] = [Geschlecht.WEIBLICH, Geschlecht.MAENNLICH, Geschlecht.DIVERS];
    public angestellteDisplay: string | undefined;

    public anstellungStates: typeof ANSTELLUNG_STATES = ANSTELLUNG_STATES;

    public constructor(
        private fraktionService: FraktionService,
        private errorService: ErrorService,
        private angestellteService: AngestellteService,
    ) {
    }

    public save(): angular.IPromise<void> {
        if (!this.betreuungsPerson.isValid()) {
            throw new Error('tried to update invalid betreuungsPerson');
        }

        return this.fraktionService.update(this.betreuungsPerson)
            .then(() => this.fraktionService.get<BetreuungsPerson>(this.betreuungsPerson.id!))
            .then(betreuungsPerson => {
                // update phone numbers with formatting of the backend
                this.betreuungsPerson.telefon = betreuungsPerson.telefon;
                this.betreuungsPerson.mobile = betreuungsPerson.mobile;
                this.betreuungsPerson.geschaeft = betreuungsPerson.geschaeft;
            });
    }

    public $onChanges(): void {
        this.getDisplayAngestellte();
    }

    public isValidName(param?: string): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        return valid;
    }

    private getDisplayAngestellte(): void {
        if (isPresent(this.betreuungsPerson.angestellteId)) {
            this.angestellteService.getAsDisplayable(this.betreuungsPerson.angestellteId)
                .then(result => this.angestellteDisplay = result.getDisplayName());
        }
    }
}

componentConfig.controller = DvbBetreuungspersonProfil;
angular.module('kitAdmin').component('dvbBetreuungspersonProfil', componentConfig);
