<div class="dvb-data-integrity">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 uib-tooltip="{{'DATA-INTEGRITY.DATA_INTEGRITY' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="DATA-INTEGRITY.DATA_INTEGRITY">
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="{{vm.states.DATA_INTEGRITY_GESCHLECHT_SPRACHE_STATE.name}}"
                           data-translate="DATA-INTEGRITY.NAV_MISSING_GESCHLECHT_OR_SPRACHE"></a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="{{vm.states.DATA_INTEGRITY_PHONE_NUMBERS_STATE.name}}"
                           data-translate="DATA-INTEGRITY.NAV_MALFORMED_PHONE_NUMBERS"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
