/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatement, BankStatementEntryFilter} from '@dv/kitadmin/models';
import {AbstractPagesController} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {from, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbDownload} from '../../../../base/directive/dvb-download/dvb-download';
import type {BankStatementService} from '../../../service/bankStatementService';

const componentConfig: angular.IComponentOptions = {
    require: {dvbDownloadCtrl: '^dvbDownload'},
    transclude: false,
    bindings: {},
    template: require('./dvb-konto-auszuege.html'),
    controllerAs: 'vm',
};

export class DvbKontoAuszuege
    extends AbstractPagesController<BankStatement> implements angular.IController {
    public static override $inject: readonly string[] = ['bankStatementService', 'dialogService', '$q'];

    public filter: BankStatementEntryFilter | null = null;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment | null = null;
    public loadingDownload: boolean = false;

    private timeout?: angular.IDeferred<unknown>;
    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly bankStatementService: BankStatementService,
        private readonly dialogService: DialogService,
        private readonly $q: angular.IQService,
    ) {
        super();
    }

    public override $onInit(): void {
        this.filter = this.bankStatementService.getBankStatementEntryFilter();
        this.filter.gueltigkeit.setCurrentMonthWhenUndefined();
        this.itemsPerPage = this.bankStatementService.getItemsPerPage();

        super.$onInit();
    }

    public getTransaktionenStateParams(bankStatement: BankStatement): {
        bankStatementIds: string[];
        states: BankStatementEntryStatus[];
        gueltigAb: string | null;
        gueltigBis: string | null;
    } {
        const gueltigkeit = checkPresent(this.filter).gueltigkeit;
        const abMoment = moment.min(checkPresent(gueltigkeit.gueltigAb), checkPresent(bankStatement.gueltigAb));
        const bisMoment =
            moment.max(checkPresent(gueltigkeit.gueltigBis), checkPresent(bankStatement.gueltigBis));

        return {
            bankStatementIds: [checkPresent(bankStatement.id)],
            states: [],
            gueltigAb: DvbRestUtil.momentToLocalDate(abMoment),
            gueltigBis: DvbRestUtil.momentToLocalDate(bisMoment),
        };
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;

        const filter = checkPresent(this.filter);
        const params = {
            gueltigAb: filter.gueltigkeit.gueltigAb ?? undefined,
            gueltigBis: filter.gueltigkeit.gueltigBis ?? undefined,
            top: this.itemsPerPage ?? undefined,
            skip: (this.currentPage - 1) * checkPresent(this.itemsPerPage),
        };

        const config = {
            timeout: this.timeout.promise,
        };

        this.bankStatementService.getBankStatements(params, config).then(pageContainer => {
            this.pageContainer = pageContainer;
            this.isLoading = false;
        }).catch(err => {
            if (!DvbRestUtil.isRequestCancelled(err)) {
                this.isLoading = false;
            }
        });
    }

    public downloadBankStatement(bankStatement: BankStatement): void {
        this.loadingDownload = true;

        this.bankStatementService.downloadBankStatement(checkPresent(bankStatement.id)).then(tempBlob => {
            this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
        }).finally(() => {
            this.loadingDownload = false;
        });
    }

    public deleteBankStatement(bankStatement: BankStatement): void {
        this.dialogService.openDeleteDialog({
            entityText: 'FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_LOESCHEN',
            confirm: () => from(this.bankStatementService.deleteBankStatement(checkPresent(bankStatement.id)))
                .pipe(take(1), tap(() => this.reloadItems())),
        });
    }
}

componentConfig.controller = DvbKontoAuszuege;
angular.module('kitAdmin').component('dvbKontoAuszuege', componentConfig);
