/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Belegung,
    KinderOrt,
    KinderOrtFraktion,
    LimitedAdresse,
    RestCache,
    WochenKapazitaet,
} from '@dv/kitadmin/models';
import {
    BetreuungsPersonWithStatus,
    ControllingChange,
    GruppenWochenBelegung,
    KinderOrtFraktionTransformer,
    KinderOrtTransformer,
    WochenPlaetze,
} from '@dv/kitadmin/models';
import type {
    ApiResponseTransformer,
    PageContainer,
    Persisted,
    RestInclude,
    RestLimited,
    RestPaginated,
} from '@dv/shared/code';
import {checkPersisted, checkPresent, Displayable, DvbDateUtil, DvbRestUtil, DvbUtil, isPresent} from '@dv/shared/code';
import type angular from 'angular';
import moment from 'moment';
import {CONFIG} from '../../../../../config';
import type {StundenBlaetterFilter} from '../../../../personal/stundenblaetter/models/StundenBlaetterFilter';
import {DvbRestUtilAngularJS} from '../dvbRestUtilAngularJS';

export class FraktionService {
    public static $inject: readonly string[] = ['$http', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/fraktionen`;
    private static readonly WOCHEN_KAPAZITAET_URL: string = `${CONFIG.restBackend}/api/v1/wochenkapazitaeten`;

    private static readonly TRANSFORMER: ApiResponseTransformer<KinderOrtFraktion> =
        KinderOrtFraktionTransformer.create();

    public constructor(
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) {
    }

    public get<T extends KinderOrtFraktion>(
        id: string,
        params?: RestLimited & RestInclude & RestCache,
    ): angular.IPromise<Persisted<T>> {

        const modelAPI = KinderOrtFraktionTransformer.create<T>();

        return DvbRestUtilAngularJS.getModelByIdAndParams<T>(FraktionService.BASE_URL, modelAPI, id, params)
            .then(checkPersisted);
    }

    public fetchForMonatsBelegungInputRow(
        inputRowDate: moment.Moment,
        gruppeId: string,
        fraktionen: KinderOrtFraktion[],
    ): angular.IPromise<KinderOrtFraktion | null> {
        const fraktion = fraktionen.find(f => f.id === gruppeId);
        if (isPresent(fraktion)) {
            return this.$q.resolve(fraktion);
        }

        const params = {
            cache: true,
            includes: '(wochenplan.fields(tagesplaene.fields(belegungsEinheiten.fields(zeitraumIds)), zeitraeume),' +
                'gruppenWochenKapazitaeten)',
            gueltigAb: DvbDateUtil.startOfMonth(moment(inputRowDate)),
            gueltigBis: DvbDateUtil.endOfMonth(moment(inputRowDate)),
        };

        return this.get(gruppeId, params);
    }

    /**
     * Get simplified gruppe by id and include wochenplan.
     * Allowed with kita:view:general while normal {@link FraktionService#get} is only allowed with kita permission.
     */
    public getWithWochenplan(id: string, params?: RestCache): angular.IPromise<Persisted<KinderOrtFraktion>> {
        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/wochenplan`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, FraktionService.TRANSFORMER, {}, params)
            .then(checkPersisted);
    }

    public update(fraktion: KinderOrtFraktion): angular.IPromise<unknown> {
        const id = checkPresent(fraktion.id);
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/${fraktion.dtype.toLowerCase()}`;

        return this.$http.put(url, fraktion.toRestObject());
    }

    public deleteFraktion(id: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${FraktionService.BASE_URL}/${encodeURIComponent(id)}`);
    }

    public validateFraktionDelete(id: string): angular.IPromise<unknown> {
        return this.$http.get(`${FraktionService.BASE_URL}/${encodeURIComponent(id)}/validate/delete`);
    }

    public terminateFraktion(id: string, endDate: moment.Moment): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/enddate`;

        return this.$http.put(url, {date: DvbRestUtil.momentToLocalDate(endDate)});
    }

    public saveWochenKapazitaet(id: string, wochenKapazitaet: WochenKapazitaet): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/wochenkapazitaeten`;

        return this.$http.post(url, wochenKapazitaet.toRestObject());
    }

    public deleteWochenKapazitaet(wochenKapazitaetId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${FraktionService.WOCHEN_KAPAZITAET_URL}/${encodeURIComponent(wochenKapazitaetId)}`);
    }

    /**
     * Verlaengert die Gueltigkeit der Wochenkapazitaet bis zur naechst gueltigen Wochenkapazitaet oder bis
     * END_OF_TIME
     */
    public extendWochenKapazitaet(wochenKapazitaetId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${FraktionService.BASE_URL}/${encodeURIComponent(wochenKapazitaetId)}/enddate`);
    }

    /**
     * Allowed with kita:view:general
     */
    public getKinderOrt(
        id: string,
        params?: RestLimited & RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<Persisted<KinderOrt>> {
        const matrixParams = params?.includes ? {includes: params.includes} : {};
        DvbRestUtil.setGueltigkeitParams(matrixParams, params);

        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/kinderort`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KinderOrtTransformer.create(), matrixParams, params)
            .then(checkPersisted);
    }

    public getGruppenWochenBelegung(
        id: string,
        firstOfWeek: moment.Moment,
        params?: RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<GruppenWochenBelegung> {

        const matrixParams = {
            firstOfWeek: DvbRestUtil.momentToLocalDate(firstOfWeek),
            inclues: params?.includes ?? undefined,
        };

        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/belegung/gruppenwochenbelegung`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, GruppenWochenBelegung, matrixParams, params)
            .then(checkPresent);
    }

    public getWochenPlaetze(
        id: string,
        firstOfWeek: moment.Moment,
        params?: RestCache,
    ): angular.IPromise<WochenPlaetze> {
        const matrixParams = {firstOfWeek: DvbRestUtil.momentToLocalDate(firstOfWeek)};

        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/wochenplaetze`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, WochenPlaetze, matrixParams, params)
            .then(checkPresent);
    }

    public getKinderAenderungen(
        id: string,
        gueltigAb: moment.Moment,
        params?: Partial<RestPaginated>,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<ControllingChange[]> {

        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(id)}/kinderaenderungen/` +
            `${DvbRestUtil.momentToLocalDate(gueltigAb)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, ControllingChange, 'changes', params, true, config);
    }

    public deleteBetreuungsPersonAdresse(
        betreuungsPersonId: string,
        limitedAdresse: LimitedAdresse,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionService.BASE_URL}/${betreuungsPersonId}/betreuungs_person/adressen/${limitedAdresse.id}`;

        return this.$http.delete(url);
    }

    public createBetreuungsPersonAdresse(
        betreuungsPersonId: string,
        adresse: LimitedAdresse,
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FraktionService.BASE_URL}/${betreuungsPersonId}/betreuungs_person/adressen`;

        return this.$http.post(url, adresse.toRestObject());
    }

    public fetchKinderOrteForBelegung(belegung: Belegung): angular.IPromise<KinderOrt[]> {
        const gruppenIds = this.getFraktionIdsForBelegung(belegung);

        return this.fetchKinderOrteForGruppen(gruppenIds);
    }

    public getFraktionIdsForBelegung(belegung: Belegung): string[] {
        const fraktionsIds: string[] = [];
        belegung.gruppenBelegungen.forEach(gruppenBelegung => {
            fraktionsIds.push(gruppenBelegung.gruppeId!);
        });

        return fraktionsIds;
    }

    public fetchKinderOrteForBelegungen(belegungen: Belegung[]): angular.IPromise<KinderOrt[]> {
        const gruppenIds: string[] = [];
        belegungen.forEach(belegung => {
            belegung.gruppenBelegungen.forEach(gruppenBelegung => {
                gruppenIds.push(gruppenBelegung.gruppeId!);
            });
        });

        return this.fetchKinderOrteForGruppen(gruppenIds);
    }

    public getStundenblaetterFraktionen(
        filter: StundenBlaetterFilter,
        config: RestInclude & angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<BetreuungsPersonWithStatus>> {

        const matrixParams = filter.toRestObject();
        matrixParams.includes = config?.includes ?? undefined;

        return DvbRestUtilAngularJS.getPagedItems(
            `${FraktionService.BASE_URL}/stundenblaetter`,
            BetreuungsPersonWithStatus,
            matrixParams,
            config);
    }

    public getKinderOrtAsDisplayable(
        fraktionId: string,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<Displayable> {
        const url = `${FraktionService.BASE_URL}/${encodeURIComponent(fraktionId)}/kinderort/displayable`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, Displayable, {}, config)
            .then(checkPresent);
    }

    private fetchKinderOrteForGruppen(gruppenIds: string[]): angular.IPromise<KinderOrt[]> {
        const uniqueGruppenIds = DvbUtil.uniqueArray(gruppenIds);
        const params = {cache: true};

        return this.$q.all(uniqueGruppenIds.map(id => this.getKinderOrt(id, params)))
            .then(kitas => DvbUtil.uniqueArray(kitas, DvbUtil.mapToId));
    }
}
