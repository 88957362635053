<div class="dvb-rechnungsempfaenger-input">
    <div class="checkbox">
        <label>
            <input type="checkbox"
                   name="rechnungsempfaenger"
                   ng-model="vm.isRechnungsempfaenger"
                   ng-change="vm.changeRechnungsempfaenger()"
                   dvb-auth
                   require-permission="kita:manage:general"
                   on-unauthorised-disable>
            <span data-translate="KONTAKTPERSON.RECHNUNGSEMPFAENGER"></span>
            <span><ng-transclude></ng-transclude></span>
        </label>
    </div>
</div>
