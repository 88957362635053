/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Bewerbung} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        prioritaet: '<',
        onChange: '&',
    },
    template: require('./dvb-bewerbung-prioritaet.html'),
    controllerAs: 'vm',
};

export class DvbBewerbungPrioritaet {
    public static $inject: readonly string[] = [];

    public prioritaet!: number;
    public onChange!: (val: { prioritaet: number }) => void;

    public prioritaeten: readonly number[] = Bewerbung.prioritaeten;
}

componentConfig.controller = DvbBewerbungPrioritaet;
angular.module('kitAdmin').component('dvbBewerbungPrioritaet', componentConfig);
