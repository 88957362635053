<div class="dvb-small-invoice-mandant-konfiguration-form dvb-form">
    <ng-form name="smallInvoiceKonfigurationForm" novalidate>
        <input type="text"
               name="token"
               class="form-control"
               minlength="32"
               maxlength="32"
               ng-attr-placeholder="{{vm.isNew ? 'MANDANT.API_TOKEN' : 'MANDANT.API_TOKEN_LEER_LASSEN' | translate}}"
               uib-tooltip="{{vm.isNew ? 'MANDANT.API_TOKEN' : 'MANDANT.API_TOKEN_LEER_LASSEN' | translate}}"
               tooltip-placement="top-left"
               ng-required="vm.isNew"
               ng-model="vm.konfiguration.token">
    </ng-form>
</div>
