/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsPosition} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        position: '<',
    },
    template: require('./dvb-rechnung-position.html'),
    controllerAs: 'vm',
};

export class DvbRechnungPosition implements angular.IController {

    public position!: RechnungsPosition;
}

componentConfig.controller = DvbRechnungPosition;
angular.module('kitAdmin').component('dvbRechnungPosition', componentConfig);
