/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {KIBON_STATES} from './kibon-states';

angular.module('kitAdmin')
    .config(kibonConfig)
    .run(updateTemplates);

export * from './component/dvb-institution-matching/dvb-institution-matching';
export * from './component/dvb-kibon-betreuung-comments/dvb-kibon-betreuung-comments';
export * from './component/dvb-kibon-betreuung-detail/dvb-kibon-betreuung-detail';
export * from './component/dvb-kibon-betreuungen-kind/dvb-kibon-betreuungen-kind';
export * from './component/dvb-kibon-betreuungs-anfragen/dvb-kibon-betreuungs-anfragen';
export * from './component/dvb-kibon-entity-info/dvb-kibon-entity-info';
export * from './component/dvb-kibon-entity-matching-overview/dvb-kibon-entity-matching-overview';
export * from './component/dvb-kibon-exchange-entity-actions/dvb-kibon-exchange-entity-actions';
export * from './component/dvb-kibon-main/dvb-kibon-main';
export * from './component/dvb-kibon-maintenance/dvb-kibon-maintenance';
export * from './component/dvb-kibon-mutationslauf/dvb-kibon-mutationslauf';
export * from './component/dvb-kibon-ref-nummer/dvb-kibon-ref-nummer';
export * from './component/dvb-kibon-tagesschule-anmeldungen/dvb-kibon-tagesschule-anmeldungen';
export * from './component/dvb-kibon-verfuegung/dvb-kibon-verfuegung';
export * from './component/dvb-kibon-verfuegung/dvb-kibon-verfuegung-matching/dvb-kibon-verfuegung-matching';
export * from './component/dvb-kibon-verfuegung/dvb-kibon-verfuegung-overview/dvb-kibon-verfuegung-overview';
export * from './component/dvb-kind-kibon/dvb-kind-kibon';

// Add New Component JS Above

kibonConfig.$inject = ['$stateProvider'];

function kibonConfig($stateProvider: StateProvider): void {
    Object.values(KIBON_STATES).forEach(state => $stateProvider.state(state));
}

updateTemplates.$inject = ['$templateCache'];

function updateTemplates($templateCache: angular.ITemplateCacheService): void {
    $templateCache.put('kibonBetreuungCommentsPopoverTemplate.html', `
<ul class="popover-list" ng-if="vm.comments.length > 0">
    <li ng-repeat="comment in vm.comments | orderBy:key" ng-bind="comment.translation | dvbI18n"></li>
</ul>`);
}
