<div class="dvb-betreuungsfaktor-regel-form dvb-form">
    <form name="betreuungsfaktorRegelForm"
          ng-submit="vm.submit(betreuungsfaktorRegelForm)"
          novalidate>

        <div class="row">
            <div class="col-md-12 small-abstand-bottom">
                <h3 data-translate="BETREUUNGSFAKTORREGEL.TITLE_KINDERGARTENBEDINGTER_BETREUUNGSFAKTOR"></h3>
            </div>
        </div>

        <div class="row form-row" ng-hide="vm.showKindergartenBetreuungsfaktor">
            <div class="col-xs-12">
                <a href="" ng-click="vm.addKindergartenBetreuungsfaktor()">
                    + <span data-translate="BETREUUNGSFAKTORREGEL.KINDERGARTEN_BETREUUNGSFAKTOR_HINZUFUEGEN"></span>
                </a>
            </div>
        </div>

        <div class="row" ng-if="vm.showKindergartenBetreuungsfaktor">
            <div class="col-xs-5">
                <input type="number"
                       name="kindergarten1Betreuungsfaktor"
                       class="form-control"
                       min="0.01"
                       step=".01"
                       ng-attr-placeholder="{{'BETREUUNGSFAKTORREGEL.KINDERGARTEN_1_FAKTOR' | translate}}"
                       uib-tooltip="{{'BETREUUNGSFAKTORREGEL.KINDERGARTEN_1_FAKTOR' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor"
                       ng-pattern="/^\d*(\.\d{0,2})?$/"
                       required>
            </div>
            <div class="col-xs-5">
                <input type="number"
                       name="kindergarten2Betreuungsfaktor"
                       class="form-control"
                       min="0.01"
                       step=".01"
                       ng-attr-placeholder="{{'BETREUUNGSFAKTORREGEL.KINDERGARTEN_2_FAKTOR' | translate}}"
                       uib-tooltip="{{'BETREUUNGSFAKTORREGEL.KINDERGARTEN_2_FAKTOR' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor"
                       ng-pattern="/^\d*(\.\d{0,2})?$/"
                       required>
            </div>
            <div class="col-xs-2">
                <p class="form-linker">
                    <a href=""
                       ng-click="vm.removeKindergartenBetreuungsfaktor()"
                       data-translate="COMMON.LOESCHEN">
                    </a>
                </p>
            </div>
            <div class="col-xs-5">
                <input type="number"
                       name="schuleBetreuungsfaktor"
                       class="form-control"
                       min="0.01"
                       step=".01"
                       ng-attr-placeholder="{{'BETREUUNGSFAKTORREGEL.SCHULE_FAKTOR' | translate}}"
                       uib-tooltip="{{'BETREUUNGSFAKTORREGEL.SCHULE_FAKTOR' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.betreuungsfaktorRegel.schuleBetreuungsfaktor"
                       ng-pattern="/^\d*(\.\d{0,2})?$/"
                       required>
            </div>
        </div>

        <div class="row small-abstand-bottom">
            <div class="col-md-12">
                <h3 data-translate="BETREUUNGSFAKTORREGEL.TITLE_ALTERSBEDINGTE_BETREUUNGSFAKTOREN"></h3>
            </div>
        </div>

        <div class="row"
             ng-repeat="altersBedingterBetreuungsfaktor in vm.betreuungsfaktorRegel.altersBedingteBetreuungsfaktoren">
            <div class="col-xs-10">
                <dvb-betreuungsfaktor-altersbedingt
                    alters-bedingter-betreuungsfaktor="altersBedingterBetreuungsfaktor">
                </dvb-betreuungsfaktor-altersbedingt>
            </div>
            <div class="col-xs-2">
                <p class="form-linker">
                    <a href=""
                       ng-if="vm.isRemovableAltersBedingterBetreuungsfaktor(altersBedingterBetreuungsfaktor)"
                       ng-click="vm.removeAltersBedingterBetreuungsfaktor(altersBedingterBetreuungsfaktor)"
                       data-translate="COMMON.LOESCHEN">
                    </a>
                </p>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-12">
                <a href="" ng-click="vm.addAltersBedingterBetreuungsfaktor()">
                    + <span data-translate="BETREUUNGSFAKTORREGEL.ALTERS_BEDINGTER_BETREUUNGSFAKTOR_HINZUFUEGEN"></span>
                </a>
            </div>
        </div>

        <div class="form-section-minus-normal-abstand">
            <div class="row">
                <div class="col-md-12 small-abstand-bottom">
                    <h3 data-translate="KIBON_ABZUG.TITLE"></h3>

                    <p class="nl2br" data-translate="KIBON_ABZUG.INFO_BETREUUNGSFAKTOR_REGEL"></p>
                </div>
            </div>

            <div class="row big-abstand-bottom" ng-hide="vm.showKibonAbzugProTag">
                <div class="col-xs-12">
                    <a href="" ng-click="vm.addKibonAbzugProTag()">
                        + <span data-translate="KIBON_ABZUG.HINZUFUEGEN"></span>
                    </a>
                </div>
            </div>

            <div class="row normal-abstand-bottom" ng-if="vm.showKibonAbzugProTag">
                <div class="col-xs-3">
                    <input type="number"
                           name="kibonAbzugProTag"
                           class="form-control"
                           min="0.01"
                           step=".01"
                           max="20.00"
                           ng-attr-placeholder="{{'KIBON_ABZUG.KIBON_ABZUG_PRO_TAG' | translate}}"
                           uib-tooltip="{{'KIBON_ABZUG.KIBON_ABZUG_PRO_TAG' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.betreuungsfaktorRegel.kibonAbzugProTag"
                           ng-pattern="/^\d*(\.\d{0,2})?$/"
                           required>
                </div>
                <div class="col-xs-2">
                    <p class="form-linker">
                        <a href=""
                           ng-click="vm.removeKibonAbzugProTag()"
                           data-translate="COMMON.LOESCHEN">
                        </a>
                    </p>
                </div>
                <div class="col-xs-7" ng-if="vm.showKibonAbzugProTag">
                    <div class="checkbox">
                        <label>
                            <input ng-disabled="!vm.betreuungsfaktorRegel.kibonAbzugProTag || vm.betreuungsfaktorRegel.kibonAbzugProTag == 0"
                                   ng-model="vm.betreuungsfaktorRegel.nachmittagsKibonAbzug"
                                   type="checkbox">
                            <span data-translate="KIBON_ABZUG.NACHMITTAGS_ABZUG"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 small-abstand-bottom">
                    <h3 data-translate="KIBON_ABZUG.KIBON_INTERN_KG_PAUSCHAL_ABZUG"></h3>

                    <p class="nl2br" data-translate="KIBON_ABZUG.INFO_PAUSCHAL_ABZUG"></p>
                </div>
            </div>

            <div class="row big-abstand-bottom" ng-hide="vm.showKibonPauschalAbzug">
                <div class="col-xs-12">
                    <a href="" ng-click="vm.addKibonInternKgPauschalAbzug()">
                        + <span data-translate="KIBON_ABZUG.HINZUFUEGEN"></span>
                    </a>
                </div>
            </div>
            <div class="row" ng-if="vm.showKibonPauschalAbzug">
                <div class="col-xs-5">
                    <input type="number"
                           name="kibonInternKgPauschalAbzug"
                           class="form-control"
                           min="0.01"
                           max="100.00"
                           ng-attr-placeholder="{{'KIBON_ABZUG.KIBON_INTERN_KG_PAUSCHAL_ABZUG' | translate}}"
                           uib-tooltip="{{'KIBON_ABZUG.KIBON_INTERN_KG_PAUSCHAL_ABZUG' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="vm.betreuungsfaktorRegel.kibonInternKgPauschalAbzug"
                           required>
                </div>
                <div class="col-xs-2">
                    <p class="form-linker">
                        <a href=""
                           ng-click="vm.removeKibonInternKgPauschalAbzug()"
                           data-translate="COMMON.LOESCHEN">
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   on-cancel="vm.onCancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
