/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {TarifValue} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {isNullish, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        originalTarifValue: '=?tarifValue',
        withGueltigAb: '<',
        onUpdate: '&',
        onCancel: '&',
    },
    template: require('./dvb-tarif-value-form.html'),
    controllerAs: 'vm',
};

export class DvbTarifValueForm implements angular.IController {

    public static $inject: readonly string[] = ['errorService'];

    public originalTarifValue?: TarifValue;
    public withGueltigAb: boolean = false;
    public onUpdate?: (param: { tarifValue: TarifValue }) => unknown;
    public onCancel?: FunctionType;

    public tarifValue?: TarifValue;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.withGueltigAb = this.withGueltigAb || false;
        this.originalTarifValue = this.originalTarifValue ?? new TarifValue();

        this.tarifValue = angular.copy(this.originalTarifValue);
    }

    public cancel(): void {
        this.tarifValue = angular.copy(this.originalTarifValue);
        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }

    public updateTarifValue(): void {
        const tarifValue = this.tarifValue;
        const isValid = tarifValue?.isValid() ?? false;

        this.errorService.handleValidationError(isValid, 'TARIF.ERRORS.ERR_INCOMPLETE_TARIF');

        if (!isValid || isNullish(tarifValue)) {
            return;
        }

        if (angular.equals(tarifValue, this.originalTarifValue)) {
            this.cancel();

            return;
        }

        if (this.originalTarifValue?.template?.id === tarifValue.template?.id) {
            tarifValue.template = null;
        }

        if (TypeUtil.isFunction(this.onUpdate)) {
            this.onUpdate({tarifValue});
        }
    }
}

componentConfig.controller = DvbTarifValueForm;
angular.module('kitAdmin').component('dvbTarifValueForm', componentConfig);
