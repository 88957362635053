<div class="dvb-personal-konfiguration-form">

    <div ng-if="!vm.disableGueltigkeit" class="row">
        <div class="col-md-6">
            <dvb-datepicker-text-field ng-model="vm.entity.gueltigAb"
                                       required
                                       ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}">
            </dvb-datepicker-text-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   ng-attr-placeholder="{{'PERSONAL.KONFIGURATION.WEEKLY_HOURS' | translate}}"
                   uib-tooltip="{{'PERSONAL.KONFIGURATION.WEEKLY_HOURS' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.entity.weeklyHours"
                   min="0"
                   max="50"
                   step="0.01"
                   required>
        </div>
        <div class="col-md-6">
            <input type="number"
                   class="form-control"
                   ng-attr-placeholder="{{'PERSONAL.KONFIGURATION.FTE_HOURS' | translate}}"
                   uib-tooltip="{{'PERSONAL.KONFIGURATION.FTE_HOURS' | translate}}"
                   tooltip-placement="top-left"
                   ng-model="vm.entity.fteHours"
                   min="0"
                   max="50"
                   step="0.01"
                   required>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control no-margin"
                   ng-model="vm.entity.arbeitszeitVon"
                   dvb-select-on-click
                   dvb-time-input
                   maxlength="10"
                   ng-attr-placeholder="{{'PERSONAL.KONFIGURATION.ARBEITSZEIT.VON' | translate}}"
                   uib-tooltip="{{'PERSONAL.KONFIGURATION.ARBEITSZEIT.VON' | translate}}: {{vm.entity.arbeitszeitVon | amDateFormat: 'HH:mm'}}"
                   tooltip-placement="top-left"
                   required>
        </div>
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   ng-model="vm.entity.arbeitszeitBis"
                   dvb-select-on-click
                   dvb-time-input
                   maxlength="10"
                   ng-attr-placeholder="{{'PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS' | translate}}"
                   uib-tooltip="{{'PERSONAL.KONFIGURATION.ARBEITSZEIT.BIS' | translate}}: {{vm.entity.arbeitszeitBis | amDateFormat: 'HH:mm'}}"
                   tooltip-placement="top-left"
                   required>
        </div>
    </div>
</div>
