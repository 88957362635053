import type {MonoTypeOperatorFunction} from 'rxjs';
import {merge, NEVER, ReplaySubject, share} from 'rxjs';

/**
 * Shortcut for sharing the last value of an observable across multiple subscribers.
 * The observable resets when there are no subscribers left.
 */
export function shareState<T>(): MonoTypeOperatorFunction<T> {
    return source$ => merge(source$, NEVER).pipe(
        share({
            resetOnRefCountZero: true,
            connector: () => new ReplaySubject<T>(1),
        }),
    );
}
