/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Mandant} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {isPersisted} from '@dv/shared/code';
import angular from 'angular';
import type {MandantSearchFilter} from '../../../search/model/MandantSearchFilter';
import {ANY_MANDANT} from '../../../search/model/MandantSearchFilter';
import type {AdministrationService} from '../../service/administrationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-kontakt-iban-migration.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceKontaktIbanMigration {
    public static $inject: readonly string[] = [
        'administrationService',
        'errorService',
    ];

    public mandant: Persisted<Mandant> | null = null;
    public customFieldName: string | null = null;

    public mandantFilter: MandantSearchFilter = ANY_MANDANT;
    public isLoading: boolean = false;

    public constructor(
        private readonly adminService: AdministrationService,
        private readonly errorService: ErrorService,
    ) {
    }

    public migrate(form: angular.IFormController): void {
        this.errorService.clearAll();

        const valid = form.$valid && isPersisted(this.mandant);

        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!valid) {
            return;
        }

        this.isLoading = true;
        this.adminService.migrateKontaktIban(this.mandant!, this.customFieldName!)
            .finally(() => {
                this.isLoading = false;
            });
    }

}

componentConfig.controller = DvbMaintenanceKontaktIbanMigration;
angular.module('kitAdmin').component('dvbMaintenanceKontaktIbanMigration', componentConfig);
