<div class="dvb-email-versand-form">
    <div class="checkbox">
        <label>
            <input ng-disabled="vm.isDisabled"
                   ng-model="vm.emailVersandProperties.sendEmails"
                   type="checkbox">
            <span data-translate="COMMON.RECHNUNG_PER_EMAIL_SENDEN"></span>
        </label>
    </div>

    <div ng-if="vm.showDiffCheckbox">
        <div class="row" uib-collapse="!vm.emailVersandProperties.sendEmails">
            <div class="col-md-6">
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-disabled="vm.isDisabled"
                               ng-required="vm.emailVersandProperties.sendEmails"
                               name="sendToAll"
                               ng-value="false"
                               ng-model="vm.limitEmailsToRechnungenWithDiff"
                               ng-change="vm.onUpdateLimitEmailsToRechnungenWithDiff({limitEmailsToRechnungenWithDiff: vm.limitEmailsToRechnungenWithDiff})">
                        <span data-translate="KINDERORT.RECHNUNGSLAUF_SEND_ALL_EMAILS"></span>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-disabled="vm.isDisabled"
                               ng-required="vm.emailVersandProperties.sendEmails"
                               name="sendForDiff"
                               ng-value="true"
                               ng-model="vm.limitEmailsToRechnungenWithDiff"
                               ng-change="vm.onUpdateLimitEmailsToRechnungenWithDiff({limitEmailsToRechnungenWithDiff: vm.limitEmailsToRechnungenWithDiff})">
                        <span data-translate="KINDERORT.RECHNUNG_WITH_DIFF_PER_EMAIL_SENDEN"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="row" uib-collapse="!vm.emailVersandProperties.sendEmails" ng-if="vm.emailVersandProperties.sendEmails">
        <div class="col-md-6 layout-row">
            <input class="form-control"
                   ng-disabled="vm.isDisabled"
                   ng-model="vm.emailVersandProperties.replyToAddress"
                   ng-model-options="{debounce: 300}"
                   ng-required="vm.emailVersandProperties.sendEmails"
                   ng-attr-placeholder="{{'COMMON.ANTWORT_ADRESSE' | translate}}"
                   tooltip-placement="top-left"
                   type="email"
                   uib-tooltip="{{'COMMON.ANTWORT_ADRESSE' | translate}}">
            <dv-spf-info [reply-to-address]="vm.emailVersandProperties.replyToAddress">
            </dv-spf-info>
        </div>
    </div>
</div>
