<div class="dvb-betreuungsperson-kinder-list">
    <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>

    <h2 data-translate="FRAKTION.BETREUUNGS_PERSON.KINDER_MF"
        data-translate-value-count="{{vm.kinderList.length}}"
        data-translate-interpolation="messageformat"
        ng-if="!vm.isLoading">
    </h2>
    <div class="form-row">
        <div class="row selected-entity"
             ng-repeat="kind in vm.kinderList | orderBy: 'name' | orderBy: 'familienName'">
            <div class="col-md-12 entity normal-abstand-bottom">
                <a ui-sref="base.kind.profil({kindId: kind.id})"
                   ng-bind="kind.getDisplayName()">
                </a>,
                <span ng-bind="kind.getDisplayGeburtsdatum()"></span>
            </div>
        </div>
    </div>
</div>
