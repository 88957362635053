/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import * as states from './schliesstage-states';

angular.module('kitAdmin').config(schliesstageConfig);

export * from './component/dvb-schliesstage/dvb-schliesstage';
export * from './component/dvb-schliesstage-main/dvb-schliesstage-main';
export * from './component/dvb-schliesstage-erstellen/dvb-schliesstage-erstellen';
export * from './component/dvb-schliesstage-form/dvb-schliesstage-form';
// Add New Component JS Above

schliesstageConfig.$inject = ['$stateProvider', '$uiRouterProvider'];

function schliesstageConfig($stateProvider: StateProvider): void {
    $stateProvider.state(states.BASE_STATE);
    $stateProvider.state(states.BASE_STATE_CREATE);
}
