<div class="dvb-incomplete-recipients">

    <div class="panel panel-list-container">
        <div class="panel-heading"
             data-translate="COMMUNICATION.INCOMPLETE_RECIPIENTS"
             data-translate-value-count="{{vm.kinder.length}}"></div>
        <div class="panel-subtitle">
            <div class="row">
                <div class="col-xs-4" data-translate="COMMON.KIND.SINGULAR"></div>
                <div class="col-xs-4" data-translate="KONTAKTPERSON.HAUPTKONTAKT"></div>
                <div class="col-xs-4" data-translate="COMMUNICATION.PROBLEM"></div>
            </div>
        </div>
        <div class="panel-list scrollable with-header">
            <div class="list-item"
                 ng-repeat="kind in vm.kinder | orderBy:['familienName', 'vorName']"
                 ng-init="kontaktperson = kind.findAnyHauptkontaktRelationship().kontaktperson">
                <div class="row floating-text">
                    <div class="col-xs-4" ng-bind="kind.getDisplayName()"></div>
                    <div class="col-xs-4" ng-bind="kontaktperson.getDisplayName()"></div>
                    <div class="col-xs-4">
                        <a ui-sref="base.kontaktperson.profil({kontaktpersonId: kontaktperson.id})"
                           data-translate="{{vm.error}}">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <ul class="list-inline normal-abstand-top">
            <li>
                <dvb-loading-button type="button" is-loading="vm.isLoading" on-click="vm.reload()">
                    <span data-translate="COMMUNICATION.CHECK_COMPLETENESS"></span>
                </dvb-loading-button>
            </li>
        </ul>
    </div>

</div>
