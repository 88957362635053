/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type moment from 'moment';
import type {Bewerbung} from '@dv/kitadmin/models';

const directive: angular.IDirective = {
    restrict: 'E',
    replace: true,
    scope: {
        bewerbung: '=',
        onDateSelected: '&',
    },
    controllerAs: 'vm',
    bindToController: true,
    template: require('./dvb-betreuungswunsch-header.html'),
};

export class DvbBetreuungswunschHeader {
    public static $inject: readonly string[] = [];

    public bewerbung!: Bewerbung;
    public onDateSelected!: (val: { date: moment.Moment }) => void;
}

directive.controller = DvbBetreuungswunschHeader;
angular.module('kitAdmin').directive('dvbBetreuungswunschHeader', () => directive);
