angular.module('ui.bootstrap.buttons', []).constant('uibButtonConfig', {
  activeClass: 'active',
  toggleEvent: 'click'
}).controller('UibButtonsController', ['uibButtonConfig', function (buttonConfig) {
  this.activeClass = buttonConfig.activeClass || 'active';
  this.toggleEvent = buttonConfig.toggleEvent || 'click';
}]).directive('uibBtnRadio', ['$parse', function ($parse) {
  return {
    require: ['uibBtnRadio', 'ngModel'],
    controller: 'UibButtonsController',
    controllerAs: 'buttons',
    link: function (scope, element, attrs, ctrls) {
      var buttonsCtrl = ctrls[0],
        ngModelCtrl = ctrls[1];
      var uncheckableExpr = $parse(attrs.uibUncheckable);
      element.find('input').css({
        display: 'none'
      });

      //model -> UI
      ngModelCtrl.$render = function () {
        element.toggleClass(buttonsCtrl.activeClass, angular.equals(ngModelCtrl.$modelValue, scope.$eval(attrs.uibBtnRadio)));
      };

      //ui->model
      element.on(buttonsCtrl.toggleEvent, function () {
        if (attrs.disabled) {
          return;
        }
        var isActive = element.hasClass(buttonsCtrl.activeClass);
        if (!isActive || angular.isDefined(attrs.uncheckable)) {
          scope.$apply(function () {
            ngModelCtrl.$setViewValue(isActive ? null : scope.$eval(attrs.uibBtnRadio));
            ngModelCtrl.$render();
          });
        }
      });
      if (attrs.uibUncheckable) {
        scope.$watch(uncheckableExpr, function (uncheckable) {
          attrs.$set('uncheckable', uncheckable ? '' : undefined);
        });
      }
    }
  };
}]).directive('uibBtnCheckbox', function () {
  return {
    require: ['uibBtnCheckbox', 'ngModel'],
    controller: 'UibButtonsController',
    controllerAs: 'button',
    link: function (scope, element, attrs, ctrls) {
      var buttonsCtrl = ctrls[0],
        ngModelCtrl = ctrls[1];
      element.find('input').css({
        display: 'none'
      });
      function getTrueValue() {
        return getCheckboxValue(attrs.btnCheckboxTrue, true);
      }
      function getFalseValue() {
        return getCheckboxValue(attrs.btnCheckboxFalse, false);
      }
      function getCheckboxValue(attribute, defaultValue) {
        return angular.isDefined(attribute) ? scope.$eval(attribute) : defaultValue;
      }

      //model -> UI
      ngModelCtrl.$render = function () {
        element.toggleClass(buttonsCtrl.activeClass, angular.equals(ngModelCtrl.$modelValue, getTrueValue()));
      };

      //ui->model
      element.on(buttonsCtrl.toggleEvent, function () {
        if (attrs.disabled) {
          return;
        }
        scope.$apply(function () {
          ngModelCtrl.$setViewValue(element.hasClass(buttonsCtrl.activeClass) ? getFalseValue() : getTrueValue());
          ngModelCtrl.$render();
        });
      });
    }
  };
});