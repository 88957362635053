/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {ParameterKinderOrte} from '../../models';
import {ERR_VALIDATION, ParameterKinderOrteWithGueltigkeit, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<?',
        gueltigAb: '<?',
        gueltigBis: '<?',
        dateOptions: '<?',
        dateFormat: '<?',
    },
    template: require('./dvb-kitas-with-gueltigkeit-report.html'),
    controllerAs: 'vm',
};

export class DvbKitasWithGueltigkeitReport implements angular.IOnInit {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public readonly gueltigAb: moment.Moment | null = null;
    public readonly gueltigBis: moment.Moment | null = null;
    public readonly parameterKinderOrte?: ParameterKinderOrte;
    public readonly dateOptions: angular.ui.bootstrap.IDatepickerConfig | null = null;
    public readonly dateFormat: string | null = null;
    public parameterKinderOrteWithGueltigkeit: ParameterKinderOrteWithGueltigkeit =
        new ParameterKinderOrteWithGueltigkeit();
    public reportTemplate: ReportTemplate | null = null;

    public kitasWithGueltigkeitForm!: angular.IFormController;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        angular.extend(this.parameterKinderOrteWithGueltigkeit, this.parameterKinderOrte);
        this.parameterKinderOrteWithGueltigkeit.gueltigAb = this.gueltigAb;
        this.parameterKinderOrteWithGueltigkeit.gueltigBis = this.gueltigBis;
    }

    public updateState(): void {
        this.reportService.updateStateParams(this.parameterKinderOrteWithGueltigkeit);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        this.errorService.clearAll();
        let isValid = this.kitasWithGueltigkeitForm.$valid && this.parameterKinderOrteWithGueltigkeit.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (isValid && ReportState.CONTROLLING.CORONA_REFUNDS === this.reportState) {
            const validDates = this.parameterKinderOrteWithGueltigkeit.gueltigAb!.isSame(
                this.parameterKinderOrteWithGueltigkeit.gueltigBis,
                'month');
            this.errorService.handleValidationError(validDates, 'ERRORS.ERR_VON_BIS_SAME_MONTH');
            isValid = validDates ? isValid : false;
        }

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    private getDownloadPromise(): angular.IPromise<TempBlob> {
        const params = angular.copy(this.parameterKinderOrteWithGueltigkeit);

        switch (this.reportState) {
            case ReportState.FAKTURA.ZAHLUNGS_EINGAENGE:
                return this.reportService.getTempBlobForZahlungseingaengeReport(params,
                    {reportTemplate: this.reportTemplate});
            case ReportState.FAKTURA.RECHNUNGS_GEBUEHREN:
                return this.reportService.getTempBlobForRechnungsGebuehrenReport(params,
                    {reportTemplate: this.reportTemplate});
            case ReportState.CONTROLLING.BETREUUNGSGUTSCHEINE:
                return this.reportService.getTempBlobForBetreuungsgutscheineReport(params,
                    {reportTemplate: this.reportTemplate});
            default:
                throw new Error(`Report not implemented: ${JSON.stringify(this.reportState)}`);
        }
    }
}

componentConfig.controller = DvbKitasWithGueltigkeitReport;
angular.module('kitAdmin').component('dvbKitasWithGueltigkeitReport', componentConfig);
