/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Environment} from '@dv/kitadmin/models';
import {LogSeverity} from '@dv/shared/code';
// eslint-disable-next-line @nx/enforce-module-boundaries
import pkg from '../../../../package.json';

const env = (window as any)?.env;

export const ENVIRONMENT: Environment = {
    production: true,
    VERSION: pkg.version,
    SENTRY_DSN: env?.SENTRY_DSN ?? '',
    SENTRY_ENVIRONMENT: env?.SENTRY_ENVIRONMENT ?? 'unknown',
    SENTRY_TRACES_SAMPLE_RATE: env?.SENTRY_TRACES_SAMPLE_RATE ?? 0.1,
    DEBUG_INFO_ENABLED: false,
    minLogSeverity: LogSeverity.WARN,
    uiRouterTracing: [],
};
