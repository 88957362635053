/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaRechnungsKonfiguration, RechnungsKonfiguration} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '<',
        mandantKonfigurationen: '<',
    },
    template: require('./dvb-kita-faktura-konfiguration-heading.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaKonfigurationHeading implements angular.IController {

    public konfiguration!: Persisted<KitaRechnungsKonfiguration>;
    public mandantKonfigurationen: Persisted<RechnungsKonfiguration>[] = [];

    public isBeginOfTime: boolean = false;
    public isTillEndOfTime: boolean = false;
    public mandantKonfiguration?: RechnungsKonfiguration | null = null;

    public $onInit(): void {
        this.mandantKonfiguration = this.mandantKonfigurationen
            .find(m => m.id === this.konfiguration.rechnungsKonfigurationId);

        this.isBeginOfTime = DvbDateUtil.isBeginOfTime(this.konfiguration.gueltigAb);
        this.isTillEndOfTime = DvbDateUtil.isEndOfTime(this.konfiguration.gueltigBis);
    }
}

componentConfig.controller = DvbKitaFakturaKonfigurationHeading;
angular.module('kitAdmin').component('dvbKitaFakturaKonfigurationHeading', componentConfig);
