/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {EmailVersandProperties} from '@dv/kitadmin/models';
import type {JaxBringAbholZeitenParams} from '@dv/shared/backend/model/jax-bring-abhol-zeiten-params';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class BringAbholZeitenParams extends EmailVersandProperties {

    public constructor(
        public periodFrom: moment.Moment,
        public periodTo: moment.Moment,
        public kinderOrtId: string,
        public kinderOrtExclusive: boolean,
        public kindIds: string[] = [],
        public customMailText: string = '',
    ) {
        super(null, null, false);
    }

    public static copy(other: BringAbholZeitenParams): BringAbholZeitenParams {
        const result = new BringAbholZeitenParams(
            other.periodFrom,
            other.periodTo,
            other.kinderOrtId,
            other.kinderOrtExclusive,
            other.kindIds,
            other.customMailText);

        result.sendEmails = other.sendEmails;
        result.replyToAddress = other.replyToAddress;
        result.bccAddress = other.bccAddress;

        return result;
    }

    public override toRestObject(): JaxBringAbholZeitenParams {
        return {
            ...super.toRestObject(false),
            periodFrom: DvbRestUtil.momentToLocalDateChecked(this.periodFrom),
            periodTo: DvbRestUtil.momentToLocalDateChecked(this.periodTo),
            kinderOrtId: this.kinderOrtId,
            kinderOrtExclusive: this.kinderOrtExclusive,
            kindIds: this.kindIds,
            customMailText: this.customMailText,
        };
    }
}
