/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {AuthEvent} from '@dv/shared/authentication/model';
import {AuthEventType} from '@dv/shared/authentication/model';
import type {Observable} from 'rxjs';
import {distinctUntilChanged, filter, ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthEventService {

    private readonly subject$: ReplaySubject<AuthEvent> = new ReplaySubject(1);
    public readonly authEvent$: Observable<AuthEvent> = this.subject$.asObservable().pipe(
        distinctUntilChanged(),
    );
    public readonly loginSuccess$ = this.authEvent$.pipe(
        filter(withType(AuthEventType.loginSuccess)),
    );
    public readonly authenticated$ = this.authEvent$.pipe(
        filter(withTypes([AuthEventType.loginRestore, AuthEventType.loginSuccess])),
    );
    public readonly logoutSuccess$ = this.authEvent$.pipe(
        filter(withType(AuthEventType.logoutSuccess)),
    );
    public readonly notAuthenticated$ = this.authEvent$.pipe(
        filter(withType(AuthEventType.notAuthenticated)),
    );
    public readonly notAuthorised$ = this.authEvent$.pipe(
        filter(withType(AuthEventType.notAuthorised)),
    );

    public sendEvent(event: AuthEvent): void {
        this.subject$.next(event);
    }
}

export function withType(type: AuthEventType): (event: AuthEvent) => boolean {
    return event => event.type === type;
}

export function withTypes(types: AuthEventType[]): (event: AuthEvent) => boolean {
    return event => types.includes(event.type);
}
