/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../../config';
import {KinderOrtBedarf} from '../models/KinderOrtBedarf';

export class PersonalBedarfService {
    public static $inject: readonly string[] = [];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/personalbedarf`;

    public getForKinderOrt(
            kinderOrtId: string,
            firstOfWeek: moment.Moment,
            params?: angular.IRequestShortcutConfig,
    ): angular.IPromise<KinderOrtBedarf> {

        const matrixParams = {
            firstOfWeek: DvbRestUtil.momentToLocalDate(firstOfWeek),
        };
        const url = `${PersonalBedarfService.BASE_URL}/kinderOrt/${encodeURIComponent(kinderOrtId)}`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, KinderOrtBedarf, matrixParams, params)
                .then(checkPresent);
    }
}
