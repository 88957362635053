/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type MatchingState = 'OPEN' | 'MATCHED' | 'SKIPPED' | 'MANUALLY_ASSIGNED' | 'REMOVED' | 'IGNORED';

export const MatchingState = {
    OPEN: 'OPEN' as MatchingState,
    MATCHED: 'MATCHED' as MatchingState,
    SKIPPED: 'SKIPPED' as MatchingState,
    MANUALLY_ASSIGNED: 'MANUALLY_ASSIGNED' as MatchingState,
    REMOVED: 'REMOVED' as MatchingState,
    IGNORED: 'IGNORED' as MatchingState
};

