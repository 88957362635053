/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {CalendarEventId, CalendarSubEvent} from '../../../../../calendar/timeline/model/CalendarEvent';
import {calendarEventIdGenerator} from '../../../../../calendar/timeline/model/CalendarEvent';
import type {PauseZeit} from '../../../../../personal/model/PauseZeit';
import {LayerType} from '../LayerType';
import type {EventGueltigkeitService} from './event-gueltigkeit.service';

export function pauseZeitToCalendarSubEvent(
    pauseZeit: PauseZeit,
    parent: CalendarEventId,
    eventGueltigkeitService: EventGueltigkeitService,
    selectedDate?: moment.Moment,
): CalendarSubEvent {
    const eventRange = eventGueltigkeitService.toEventRange(pauseZeit, selectedDate);

    return {
        id: calendarEventIdGenerator(),
        parent,
        ...eventRange.gueltigkeit,
        completeDuration: 0,
        spaceBefore: 0,
        spaceAfter: 0,
        backgroundColor: '#ffffffbf',
        textColor: '#FFFFFF',
        hasHighLuminance: false,
        getDisplayName: () => '',
        layer: LayerType.PAUSEZEIT,
        tooltip: eventRange.gueltigkeitTxt,
        subEvents: [],
    };
}
