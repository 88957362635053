import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {DisplayNamePipe, IconComponent, trackByIdentity} from '@dv/shared/angular';
import type {IDisplayable, IPersistable} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ResourceHoverDirective} from '../../directives/resource-hover.directive';
import {CalendarGroup} from '../../model/CalendarGroup';
import type {CalendarGroupResourceAddEvent} from '../../model/CalendarGroupResourceAddEvent';
import type {CalendarGroupResourceRemoveEvent} from '../../model/CalendarGroupResourceRemoveEvent';
import {CalendarTranslation} from '../../model/CalendarTranslation';
import {ROW_HEIGHT, TimelineCalendarService} from '../../service/timeline-calendar.service';
import {TimelineGroupAddResourceComponent} from '../timeline-group-add-resource/timeline-group-add-resource.component';

@Component({
    selector: 'dv-timeline-calendar-group-header',
    standalone: true,
    imports: [
        CommonModule,
        DisplayNamePipe,
        IconComponent,
        TimelineGroupAddResourceComponent,
        TooltipModule,
        TranslateModule,
        UIRouterModule,
        ResourceHoverDirective,
    ],
    templateUrl: './timeline-calendar-group-header.component.html',
    styleUrls: ['./timeline-calendar-group-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineCalendarGroupHeaderComponent {

    @Input({required: true}) public group!: CalendarGroup;
    @Input() public first = false;
    @Input() public readonly: boolean = false;
    @Input({required: true}) public calendarTranslation!: CalendarTranslation;
    @Input() public availableResources: (IPersistable & IDisplayable)[] = [];

    @Output() public readonly sortGroup: EventEmitter<void> = new EventEmitter();
    @Output() public readonly resourceRemove: EventEmitter<CalendarGroupResourceRemoveEvent> = new EventEmitter();
    @Output() public readonly resourceAdd: EventEmitter<CalendarGroupResourceAddEvent> = new EventEmitter();

    protected readonly trackByIdentity = trackByIdentity;
    protected readonly rowHeight = ROW_HEIGHT;

    public constructor(
        public readonly timelineCalendarService: TimelineCalendarService,
    ) {
    }
}
