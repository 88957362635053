/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KindergartenBelegung} from '@dv/shared/code';
import {KGB} from '@dv/shared/code';

export class KindergartenBelegungService {
    public static $inject: readonly string[] = [];

    // noinspection JSMethodCanBeStatic
    public isInternal(kb: KindergartenBelegung): boolean {
        return KGB.isInternal(kb);
    }

    // noinspection JSMethodCanBeStatic
    public isExternal(kb: KindergartenBelegung): boolean {
        return KGB.isExternal(kb);
    }

    // noinspection JSMethodCanBeStatic
    public isKG1(kb: KindergartenBelegung): boolean {
        return KGB.isKG1(kb);
    }

    // noinspection JSMethodCanBeStatic
    public isKG2(kb: KindergartenBelegung): boolean {
        return KGB.isKG2(kb);
    }
}
