/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export class BetreuungZeitabschnittValues {

    public constructor(
        public betreuungskosten: number,
        public betreuungspensum: number,
        public anzahlHauptmahlzeiten: number,
        public anzahlNebenmahlzeiten: number,
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungZeitabschnittValues {
        return new BetreuungZeitabschnittValues(
            parseFloat(data.betreuungskosten),
            parseFloat(data.betreuungspensum),
            parseFloat(data.anzahlHauptmahlzeiten),
            parseFloat(data.anzahlNebenmahlzeiten),
        );
    }
}
