<div class="dvb-kinderort-profil row dvb-form">
    <div class="col-md-7 big-abstand-bottom">
        <dvb-kinderort-kapazitaeten kinder-ort="vm.kinderOrt"></dvb-kinderort-kapazitaeten>
    </div>

    <div class="col-md-4 col-md-offset-1">
        <dvb-kinderort-kontakt kinder-ort="vm.kinderOrt"></dvb-kinderort-kontakt>
        <dvb-kinderort-fraktionen kinder-ort="vm.kinderOrt"></dvb-kinderort-fraktionen>
        <dvb-kinderort-firmen dvb-auth
                              require-permission="{{'kita:view:' + vm.kita.id}}"
                              kinder-ort="vm.kinderOrt">
        </dvb-kinderort-firmen>
    </div>
</div>
