/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    EingewoehnungPosition,
    FixPosition,
    Kind,
    Leistungsrechnung,
    TarifrechnungResult,
} from '@dv/kitadmin/models';
import {isDeletableWarning, LeistungsrechnungWarningElement} from '@dv/kitadmin/models';
import {checkPresent, LeistungsrechnungStatus} from '@dv/shared/code';
import angular from 'angular';
import Comparators from 'comparators';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        startOfMonth: '<',
        selectedMonth: '<',
        kitaNamen: '<',
        leistungsrechnungen: '<',
        showDownload: '<',
        onSelect: '&',
        onDeleteFixPosition: '&',
        onDeleteMutationBadge: '&',
        onDeleteEingewoehnungPosition: '&',
    },
    template: require('./dvb-kind-leistungen-pro-monat.html'),
    controllerAs: 'vm',
};

export class DvbKindLeistungenProMonat implements angular.IController {

    public static $inject: readonly string[] = [];

    public kind!: Kind;
    public startOfMonth!: moment.Moment;
    public selectedMonth!: moment.Moment;
    public kitaNamen: { [kinderOrtId: string]: string } = {};
    public leistungsrechnungen: Leistungsrechnung[] = [];
    public showDownload: boolean = false;

    public onSelect?: (param: { startOfMonth: moment.Moment }) => any;
    public onDeleteFixPosition?: (param: { fixPosition: FixPosition }) => any;
    public onDeleteMutationBadge?: (param: { leistungsrechnungId: string }) => any;
    public onDeleteEingewoehnungPosition?: (param: { eingewoehnungPosition: EingewoehnungPosition }) => any;

    public leistungsrechnungenWithResults: Leistungsrechnung[] = [];
    public activeLeistungsrechnungen: number[] = [];
    public totalLeistungen: number | null = null;

    public leistungsrechnungWarnings: {
        [leistungsrechnungId: string]: {
            warnings: LeistungsrechnungWarningElement[];
            enableDeletion?: boolean;
        };
    } = {};

    public statusMap: { [k in LeistungsrechnungStatus]: string } = {
        [LeistungsrechnungStatus.NICHT_VERRECHENBAR]: 'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_NICHT_VERRECHENBAR_TOOLTIP',
        [LeistungsrechnungStatus.VERRECHENBAR]: 'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_VERRECHENBAR_TOOLTIP',
        [LeistungsrechnungStatus.VERRECHNET]: 'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_VERRECHNET_TOOLTIP',
        [LeistungsrechnungStatus.STORNIERT]: 'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_STORNIERT_TOOLTIP',
        [LeistungsrechnungStatus.RECHNUNGSDIFFERENZ]: 'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_RECHNUNGSDIFFERENZ_TOOLTIP',
        [LeistungsrechnungStatus.GELOESCHT]: 'KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_GELOESCHT_TOOLTIP',
    };

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (!changes.leistungsrechnungen) {
            return;
        }

        this.leistungsrechnungenWithResults =
            this.getLeistungsrechnungenWithResults(changes.leistungsrechnungen.currentValue);
        this.activeLeistungsrechnungen = this.getHasPositionenMap(this.leistungsrechnungenWithResults);
        this.totalLeistungen = this.getTotalLeistungen();

        this.initLeistungsrechnungWarnings();
    }

    public getKitaNameForLeistungsrechnung(leistungsrechnung: Leistungsrechnung): string {
        return this.kitaNamen[checkPresent(leistungsrechnung.kitaId)];
    }

    public showNichtVorhanden(): boolean {
        return this.leistungsrechnungenWithResults.length === 0 ||
            (this.activeLeistungsrechnungen.length === 0 && !this.showDownload);
    }

    private getTotalLeistungen(): number | null {
        if (this.leistungsrechnungen.length === 0) {
            return null;
        }

        return this.leistungsrechnungen
            .map(leistungsrechnung => checkPresent(leistungsrechnung.summeResults))
            .reduce((a, b) => a + b, 0);
    }

    private getLeistungsrechnungenWithResults(leistungsrechnungen: Leistungsrechnung[]): Leistungsrechnung[] {
        return leistungsrechnungen
            .filter(leistungsrechnung => leistungsrechnung.hasResults())
            .sort(Comparators.comparing(l => this.getKitaNameForLeistungsrechnung(l)));
    }

    private getHasPositionenMap(leistungsrechnungen: Leistungsrechnung[]): number[] {
        const active: number[] = [];

        leistungsrechnungen.forEach((leistungsrechnung, index) => {
            if (this.showLeistungsrechnung(leistungsrechnung)) {
                active.push(index);
            }
        });

        return active;
    }

    private showLeistungsrechnung(leistungsrechnung: Leistungsrechnung): boolean {
        const hasTarifrechnungResults = leistungsrechnung.tarifrechnungResults
            .map(tr => this.showTarifrechnungResultWithoutPositionen(tr))
            .reduce((prevVal, currentVal): boolean => prevVal || currentVal, false);

        const hasFixPositionen = leistungsrechnung.fixPositionen.length > 0;

        return hasTarifrechnungResults || hasFixPositionen;
    }

    private showTarifrechnungResultWithoutPositionen(tarifrechnungResult: TarifrechnungResult): boolean {
        return tarifrechnungResult.tarifrechnungPositionen.length > 0 || this.showDownload;
    }

    private initLeistungsrechnungWarnings(): void {
        const kindName = this.kind.getDisplayName();
        this.leistungsrechnungen.forEach(leistungsrechnung => {
            if (!leistungsrechnung.warnings) {
                this.leistungsrechnungWarnings[leistungsrechnung.id!] = {
                    warnings: [],
                };
            }

            const kinderOrtName = this.getKitaNameForLeistungsrechnung(leistungsrechnung);
            const warnings = leistungsrechnung.warnings
                .map(warning => new LeistungsrechnungWarningElement(warning, kindName, kinderOrtName));
            const enableDeletion = leistungsrechnung.warnings.some(isDeletableWarning);
            this.leistungsrechnungWarnings[leistungsrechnung.id!] = {
                warnings,
                enableDeletion,
            };
        });
    }
}

componentConfig.controller = DvbKindLeistungenProMonat;
angular.module('kitAdmin').component('dvbKindLeistungenProMonat', componentConfig);
