/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KinderOrtId, Kontaktperson, OffenerPosten} from '@dv/kitadmin/models';
import {Kita} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
        selectedKontaktpersonZahlungId: '<?',
        showAll: '<?',
    },
    template: require('./dvb-kontaktperson-offene-posten.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonOffenePosten implements angular.IController {

    public static $inject: readonly string[] = ['kontaktpersonService', '$q'];

    public kontaktperson!: Persisted<Kontaktperson>;
    public selectedKontaktpersonZahlungId?: string;
    public showAll?: boolean;

    public offenePosten: OffenerPosten[] = [];
    public isLoading: boolean = true;
    public selectedKinderOrte: Record<KinderOrtId, boolean> = {};
    public kinderOrte: Persisted<KinderOrt>[] = [];

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        private readonly kontaktpersonService: KontaktpersonService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.showAll ??= false;

        this.loadOffenePosten();
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public loadOffenePosten(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);

        this.offenePosten = [];
        this.isLoading = true;

        const kitaIds = Object.keys(this.selectedKinderOrte)
            .filter(key => this.selectedKinderOrte[key]);

        this.timeout = this.$q.defer();

        const params = {kitaIds};
        const config = {timeout: this.timeout.promise};

        const loadPromise = this.showAll ?
            this.kontaktpersonService.getAllOffenePosten(this.kontaktperson.id, params, config) :
            this.kontaktpersonService.getUnsettledOffenePosten(this.kontaktperson.id, params, config);

        loadPromise
            .then(offenePosten => {
                this.offenePosten = offenePosten;
                this.populateKinderOrtArray();
                this.isLoading = false;
            })
            .catch(error => {
                if (!DvbRestUtil.isRequestCancelled(error)) {
                    this.isLoading = false;

                    return this.$q.reject(error);
                }

                return this.$q.resolve();
            });
    }

    public updateKitaFilter(kita: Persisted<KinderOrt>): void {
        const isSelected = this.selectedKinderOrte[kita.id];
        if (isSelected) {
            this.selectedKinderOrte = {};
            this.selectedKinderOrte[kita.id] = true;
        }

        if (this.kinderOrte.length <= 1) {
            return;
        }

        this.loadOffenePosten();
    }

    private populateKinderOrtArray(): void {
        this.offenePosten
            .filter(offenerPosten => offenerPosten.kitaId)
            .forEach(offenerPosten => this.addKinderOrt(offenerPosten));
    }

    private addKinderOrt(offenerPosten: OffenerPosten): void {
        const kitaAdded = this.kinderOrte.some(kita => kita.id === offenerPosten.kitaId);

        if (kitaAdded) {
            return;
        }

        const k = new Kita();
        k.id = offenerPosten.kitaId;
        k.name = offenerPosten.kitaName;
        this.kinderOrte.push(checkPersisted(k));
    }
}

componentConfig.controller = DvbKontaktpersonOffenePosten;
angular.module('kitAdmin').component('dvbKontaktpersonOffenePosten', componentConfig);
