<dvb-collapsible-panel
    is-first="vm.showLineTop"
    is-collapsed="true"
    dvb-auth
    require-permission="kita:manage:general"
    on-unauthorised-disable>
    <panel-header>
        <p>
            <span data-translate="{{vm.titleKey}}"></span>
            <a href="" ng-if="vm.isDateClickable"
               ng-click="vm.onDateSelected({date: vm.belegung.gueltigBis}); $event.stopPropagation();"
               ng-bind="vm.belegung.gueltigBis | amDateFormat: 'D.M.YYYY'"></a>
            <span ng-if="!vm.isDateClickable" ng-bind="vm.belegung.gueltigBis | amDateFormat: 'D.M.YYYY'"></span>
        </p>
    </panel-header>
    <panel-content>
        <p>
            <span data-translate="{{vm.titleKey}}"></span>
            <a href=""
               ng-if="vm.isDateClickable"
               ng-click="vm.onDateSelected({date: vm.belegung.gueltigBis}); $event.stopPropagation();"
               ng-bind="vm.belegung.gueltigBis | amDateFormat: 'D.M.YYYY'">
            </a>
            <span ng-if="!vm.isDateClickable" ng-bind="vm.belegung.gueltigBis | amDateFormat: 'D.M.YYYY'"></span>
        </p>
        <ul class="list-inline normal-abstand-top"
            dvb-auth
            require-permission="kita:manage:general"
            require-condition="vm.belegung.austrittProvisorisch || vm.belegung.monatsBelegungId === null">
            <li ng-if="vm.belegung.monatsBelegungId === null">
                <p>
                    <a href=""
                       data-translate="KIND.KIND_AUSTRITT_REVERT"
                       ng-click="vm.revertAustritt(vm.belegung.gueltigBis)"></a>
                </p>
            </li>
            <li ng-if="vm.belegung.austrittProvisorisch">
                <p>
                    <a href=""
                       data-translate="KIND.KIND_AUSTRITT_DEFINITIV"
                       ng-click="vm.resetAustrittProvisorisch(vm.belegung)"></a>
                </p>
            </li>
        </ul>
        <p ng-if="vm.belegung.monatsBelegungId !== null"
           class="normal-abstand-top"
           data-translate="KIND.AUSTRITT_MONATSBELEGUNG">
        </p>
    </panel-content>
</dvb-collapsible-panel>
