<div class="dvb-belegungs-einheiten">
    <div class="row">
        <div class="col-md-7">

            <dvb-pages items="vm.pageContainer.items"
                       total-items="vm.pageContainer.count"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'COMMON.BELEGUNGS_EINHEIT.MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">

                <page-items class="big-abstand-bottom" dvb-accordions>
                    <dvb-accordion ng-repeat="belegungseinheit in vm.pageContainer.items"
                                   ng-init="workingCopy = (belegungseinheit | copy)"
                                   is-first="$first"
                                   enable-edit="true"
                                   enable-delete="true"
                                   entity-name="'COMMON.BELEGUNGS_EINHEIT.SINGULAR'"
                                   on-submit="vm.update(context, workingCopy)"
                                   on-delete="vm.delete(context, belegungseinheit)">
                        <panel-header class="full-width-block">
                            <div class="items-center row">
                                <div class="col-xs-8">
                                    <span ng-bind="belegungseinheit.name"></span>
                                </div>
                                <div class="col-xs-2">
                                <span data-translate="WOCHENPLAN.PRIO"
                                      data-translate-value-prio="{{belegungseinheit.prio}}">
                                </span>
                                </div>
                                <div class="col-xs-2">
                                <span data-translate="WOCHENPLAN.PROZENTPUNKTE"
                                      data-translate-value-prozent="{{belegungseinheit.prozentPunkte}}">
                                </span>
                                </div>
                            </div>
                        </panel-header>
                        <panel-content>
                            <div>
                                <div class=" row">
                                    <div class="col-sm-3 col-xs-5">
                                        <span data-translate="WOCHENPLAN.WOCHENTAGE"></span>
                                    </div>
                                    <div class="col-sm-9 col-xs-7">
                                        <span ng-bind="vm.wochentageByBelegungsEinheit.get(belegungseinheit.id)"></span>
                                    </div>
                                </div>
                                <div class="row small-abstand-bottom">
                                    <div class="col-sm-3 col-xs-5">
                                        <span data-translate="WOCHENPLAN.BETREUUNGSZEITRAEUME"></span>
                                    </div>
                                    <div class="col-sm-9 col-xs-7">
                                        <span ng-bind="vm.zeitraeumeByBelegungsEinheit.get(belegungseinheit.id)"></span>
                                    </div>
                                </div>
                            </div>
                        </panel-content>
                        <edit-content>
                            <dvb-belegungs-einheit-form einheit="workingCopy" wochenplan="vm.wochenplan">
                            </dvb-belegungs-einheit-form>
                        </edit-content>
                    </dvb-accordion>
                </page-items>
            </dvb-pages>

            <div ng-if="vm.showCreateForm" class="big-abstand-top">
                <h2 data-translate="WOCHENPLAN.CREATE_BELEGUNGS_EINHEIT"></h2>
                <form name="newEinheitForm" ng-submit="vm.create(newEinheitForm)" novalidate>
                    <dvb-belegungs-einheit-form einheit="vm.newBelegungsEinheit" wochenplan="vm.wochenplan">
                    </dvb-belegungs-einheit-form>
                    <dvb-submit-cancel-buttons on-cancel="vm.showCreateForm = false"></dvb-submit-cancel-buttons>
                </form>
            </div>

            <ul ng-if="!vm.showCreateForm" class="list-inline big-abstand-top">
                <li>
                    <button class="btn btn-primary"
                            data-translate="WOCHENPLAN.CREATE_BELEGUNGS_EINHEIT"
                            ng-click="vm.showCreateForm = true">
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
