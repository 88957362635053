/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Firma, FirmenKontingent, KinderOrt} from '@dv/kitadmin/models';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

type FirmenKontingentByFirma = {
    firma: Firma;
    firmenKontingente: FirmenKontingent[];
};

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
    },
    template: require('./dvb-kinderort-firmen.html'),
    controllerAs: 'vm',
};

export class DvbKinderortFirmen implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [];

    public kinderOrt!: KinderOrt;

    public today: moment.Moment = DvbDateUtil.today();
    public showInaktiveFirmenKontingente: boolean = false;
    public firmenMitAktivenKontingenten: FirmenKontingentByFirma[] = [];
    public firmenMitInaktivenKontingenten: FirmenKontingentByFirma[] = [];

    public $onInit(): void {
        this.splitFirmenkontingenteToFirmen();
    }

    /**
     * Teilt die firmenkontingente in aktive und inaktive auf und macht daraus Listen mit Firmen und deren aktiven /
     * inaktiven Kontingenten.
     */
    public splitFirmenkontingenteToFirmen(): void {
        const aktiveFirmenKontingente: FirmenKontingent[] = [];
        const inaktiveFirmenKontingente: FirmenKontingent[] = [];

        this.kinderOrt.firmenKontingente.forEach(firmenKontingent => {
            const aktiveValues = firmenKontingent.values.some(val => !val.gueltigBis.isBefore(this.today));

            if (aktiveValues) {
                aktiveFirmenKontingente.push(firmenKontingent);

                return;
            }

            const inaktiveValues = firmenKontingent.values.some(val => val.gueltigBis.isBefore(this.today));

            if (inaktiveValues) {
                inaktiveFirmenKontingente.push(firmenKontingent);
            }
        });

        this.firmenMitInaktivenKontingenten = this.createFirmenMitKontingenten(inaktiveFirmenKontingente);
        this.firmenMitAktivenKontingenten = this.createFirmenMitKontingenten(aktiveFirmenKontingente);
    }

    public createFirmenMitKontingenten(firmenkontingente: FirmenKontingent[]): FirmenKontingentByFirma[] {
        const firmenMitKontingenten: FirmenKontingentByFirma[] = [];

        firmenkontingente.forEach(firmenkontingent => {
            const foundDtos = firmenMitKontingenten.filter(f => f.firma === firmenkontingent.firma);

            let firmaAndKontingente;
            if (foundDtos.length === 0) {
                firmaAndKontingente = this.createFirmenKontingentByFirma(checkPresent(firmenkontingent.firma));
                firmenMitKontingenten.push(firmaAndKontingente);
            } else {
                firmaAndKontingente = foundDtos[0];
            }
            firmaAndKontingente.firmenKontingente.push(firmenkontingent);
        });

        return firmenMitKontingenten;
    }

    public createFirmenKontingentByFirma(firma: Firma): FirmenKontingentByFirma {
        return {
            firma,
            firmenKontingente: [],
        };
    }

    public getKontingenteAsText(firmaAndKontingente: {
        firma: Firma;
        firmenKontingente: FirmenKontingent[];
    }): string {
        if (firmaAndKontingente.firmenKontingente.length === 1) {
            return checkPresent(firmaAndKontingente.firmenKontingente[0].name);
        }

        return firmaAndKontingente.firmenKontingente.map(k => k.name).join(', ');
    }

    public getKontingentePrefixKey(firmaAndKontingente: {
        firma: Firma;
        firmenKontingente: FirmenKontingent[];
    }): string {
        if (firmaAndKontingente.firmenKontingente.length === 1) {
            return 'COMMON.KONTINGENT';
        }

        return 'COMMON.KONTINGENTE';
    }
}

componentConfig.controller = DvbKinderortFirmen;
angular.module('kitAdmin').component('dvbKinderortFirmen', componentConfig);
