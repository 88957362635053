<div class="dv-spf-info"
     (mouseleave)="showPopoverSource.set(false)"
     (mouseenter)="showPopoverSource.set(true)">

    <ng-template #spfSupportInfo>
        <p [innerHTML]="'ERRORS.SPF.TEXT' | translate"></p>
        <p>
            <a [attr.href]="'ERRORS.SPF.LINK_URL' | translate"
               target="_blank"
               rel="noopener"
               [translate]="'ERRORS.SPF.LINK_TEXT'">
            </a>
        </p>
    </ng-template>

    @if (spfError()) {
        <span class="fa fa-exclamation-triangle"
              [popover]="spfSupportInfo"
              [popoverTitle]="'ERRORS.SPF.TITLE' | translate"
              [isOpen]="showPopover()"
              placement="top">
        </span>
    }
</div>
