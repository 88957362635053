<div class="dvb-kita-faktura-konfiguration-heading">
    <div class="history-columns">
        <p class="history-column-left">
            <span ng-if="!vm.isTillEndOfTime"
                  data-translate="COMMON.GUELTIGKEIT_VON_BIS"
                  data-translate-value-date-ab="{{vm.konfiguration.gueltigAb | amDateFormat:'D.M.YYYY'}}"
                  data-translate-value-date-bis="{{vm.konfiguration.gueltigBis | amDateFormat:'D.M.YYYY'}}">
            </span>
            <span ng-if="vm.isTillEndOfTime"
                  data-translate="COMMON.PREPOSITION_DATUM_MF"
                  data-translate-value-preposition="{{'COMMON.AB' | translate | capitalize}}"
                  data-translate-value-is-begin-of-time="{{vm.isBeginOfTime}}"
                  data-translate-value-date="{{vm.konfiguration.gueltigAb | amDateFormat:'D.M.YYYY'}}"
                  data-translate-interpolation="messageformat">
            </span>
        </p>
        <p class="history-column-middle"
           data-translate="KINDERORT.KITA_KONFIGURATION_NAME"
           data-translate-value-name="{{vm.mandantKonfiguration.name}}"
           data-translate-value-type="{{'COMMON.' + vm.mandantKonfiguration.dtype | translate}}"
           data-translate-value-deactivated="{{vm.mandantKonfiguration.isDisabled ? 'active' : ''}}">
        </p>
    </div>
</div>
