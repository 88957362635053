<div class="dvb-abholberechtigt-input">
    <div class="checkbox">
        <label>
            <input type="checkbox"
                   ng-model="vm.isAbholberechtigt"
                   name="abholberechtigt"
                   ng-click="vm.changeAbholberechtigt()"
                   dvb-auth
                   require-permission="kita:manage:general"
                   on-unauthorised-disable>
            <span data-translate="KONTAKTPERSON.ABHOLBERECHTIGT"></span>
        </label>
    </div>
</div>
