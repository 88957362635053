/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {BankStatementEntryStatus} from '@dv/shared/backend/model/bank-statement-entry-status';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {from, switchMap, take} from 'rxjs';
import type {BankStatementService} from '../../../service/bankStatementService';
import type {
    BankStatementEntryZuweisungLoeschenDialogModel,
} from '../../bank-statement-entry-zuweisung-loeschen/bank-statement-entry-zuweisung-loeschen.component';
import {
    BankStatementEntryZuweisungLoeschenComponent,
} from '../../bank-statement-entry-zuweisung-loeschen/bank-statement-entry-zuweisung-loeschen.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entry: '<',
    },
    template: require('./dvb-bank-statement-entry-footer.html'),
    controllerAs: 'vm',
};

export class DvbBankStatementEntryFooter implements angular.IController {

    public static $inject: readonly string[] = ['dialogService', 'bankStatementService', '$state'];
    public entry!: BankStatementEntry;

    public constructor(
        private readonly dialogService: DialogService,
        private readonly bankStatementService: BankStatementService,
        private readonly $state: StateService,
    ) {
    }

    public zuweisungLoeschen(): void {

        const dialogModel: BankStatementEntryZuweisungLoeschenDialogModel = {
            entry: this.entry,
            confirm: (status: BankStatementEntryStatus): Observable<unknown> => {
                if (status !== BankStatementEntryStatus.NICHT_ZUGEWIESEN
                    && status !== BankStatementEntryStatus.IGNORIERT) {
                    throw new Error('unknown status');
                }

                const result$ = status === BankStatementEntryStatus.NICHT_ZUGEWIESEN ?
                    from(this.bankStatementService.resetZahlung(checkPresent(this.entry.id))) :
                    from(this.bankStatementService.ignoreZahlung(checkPresent(this.entry.id)));

                return result$.pipe(take(1), switchMap(() => {
                    return this.$state.reload();
                }));
            },
        };

        this.dialogService.openDialog(BankStatementEntryZuweisungLoeschenComponent, dialogModel);
    }

    public ignorieren(): void {
        const id = checkPresent(this.entry.id);
        this.dialogService.openConfirmDialog({
            title: 'FAKTURA.KONTENABGLEICH.CONFIRM_TRANSAKTION_IGNORIEREN',
            confirm: () => from(this.bankStatementService.ignoreZahlung(id)
                .then(() => this.$state.reload())),
        });
    }

    public resetIgnore(): void {
        const id = checkPresent(this.entry.id);
        this.dialogService.openConfirmDialog({
            title: 'FAKTURA.KONTENABGLEICH.CONFIRM_TRANSAKTION_NICHT_IGNORIEREN',
            confirm: () => from(this.bankStatementService.resetZahlung(id)
                .then(() => this.$state.reload())),
        });
    }
}

componentConfig.controller = DvbBankStatementEntryFooter;
angular.module('kitAdmin').component('dvbBankStatementEntryFooter', componentConfig);
