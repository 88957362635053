<div class="dvb-attachments-download inline-block">
    <div class="btn-group"
         data-keyboard-nav
         uib-dropdown
         ng-if="vm.entry.blobInfos.length > 0">
        <dvb-loading-button as-link="true"
                            dropdown="true"
                            is-loading="vm.isLoading"
                            margin="false"
                            type="button"
                            spinner-right="true"
                            uib-dropdown-toggle>
            <i class="fa fa-paperclip attachment-dropdown"></i>
            <span class="caret"></span>
        </dvb-loading-button>
        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
            <li ng-repeat="blobInfo in vm.entry.blobInfos"
                class="attachment-download"
                ng-class="{'smaller-abstand-bottom': !$last}">
                <a href=""
                   ng-bind="blobInfo.filename"
                   ng-click="vm.downloadAttachment(blobInfo)">
                </a>
            </li>
        </ul>
    </div>
</div>
