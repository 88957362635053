/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type BetreuungsAngebotType = 'HORT' | 'KRIPPE' | 'HORT_AND_KRIPPE';

export const BetreuungsAngebotType = {
    HORT: 'HORT' as BetreuungsAngebotType,
    KRIPPE: 'KRIPPE' as BetreuungsAngebotType,
    HORT_AND_KRIPPE: 'HORT_AND_KRIPPE' as BetreuungsAngebotType
};

