<div class="dvb-tarif-verlauf-content">
    <dvb-tarif-verlauf-header tarif-id="vm.tarifId" tarif-value="vm.tarifValue"></dvb-tarif-verlauf-header>

    <div ng-if="vm.tarifValue.bemerkung && !vm.isEditMode" class="normal-abstand-top">
        <strong data-translate="COMMON.BEMERKUNG"></strong>
        <p ng-bind="vm.tarifValue.bemerkung" class="nl2br"></p>
    </div>

    <div ng-if="!vm.isEditMode">
        <ul class="list-inline normal-abstand-top">
            <li ng-if="vm.tarifValue.template">
                <a href="" ng-click="vm.isEditMode = true">
                    <span data-translate="COMMON.BEARBEITEN"></span>
                </a>
            </li>
            <li>
                <a href="" ng-show="vm.deleteAllowed" ng-click="vm.onDelete({tarifValue: vm.tarifValue})">
                    <span data-translate="COMMON.LOESCHEN"></span>
                </a>
            </li>
        </ul>
    </div>

    <dvb-tarif-value-form ng-if="vm.isEditMode"
                          class="normal-abstand-top"
                          tarif-value="vm.tarifValue"
                          on-update="vm.onUpdate({tarifValue: tarifValue}); vm.isEditMode = false"
                          with-gueltig-ab="false"
                          on-cancel="vm.isEditMode = false">
    </dvb-tarif-value-form>
</div>
