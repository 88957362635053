<div class="row">
    <dvb-datepicker-text-field ng-model="vm.anwesenheitsSoll.gueltigAb"
                               name="limitedEntityForm.frueh"
                               dvb-begin-of-month
                               ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}"
                               required
                               class="col-md-6">
    </dvb-datepicker-text-field>
    <dvb-datepicker-text-field ng-model="vm.anwesenheitsSoll.gueltigBis"
                               dvb-end-of-month-parser
                               ng-attr-placeholder="{{'COMMON.GUELTIG_BIS' | translate}}"
                               required
                               class="col-md-6">
    </dvb-datepicker-text-field>
</div>
<div class="row">
    <div class="col-md-6">
        <input type="number"
               class="form-control"
               ng-model="vm.anwesenheitsSoll.tage"
               step="1"
               ng-attr-placeholder="{{'KIND.ANWESENHEITS_SOLL.TAGE' | translate}}"
               uib-tooltip="{{'KIND.ANWESENHEITS_SOLL.TAGE' | translate}}"
               tooltip-placement="top-left">
    </div>
    <div class="col-md-6">
        <input type="number"
               class="form-control"
               ng-model="vm.anwesenheitsSoll.halbtage"
               step="1"
               ng-attr-placeholder="{{'KIND.ANWESENHEITS_SOLL.HALBTAGE' | translate}}"
               uib-tooltip="{{'KIND.ANWESENHEITS_SOLL.HALBTAGE' | translate}}"
               tooltip-placement="top-left">
    </div>
    <div class="col-md-6">
        <input type="number"
               class="form-control"
               ng-model="vm.anwesenheitsSoll.fruehbetreuungen"
               step="1"
               ng-attr-placeholder="{{'KIND.ANWESENHEITS_SOLL.FRUEHBETREUUNGEN' | translate}}"
               uib-tooltip="{{'KIND.ANWESENHEITS_SOLL.FRUEHBETREUUNGEN' | translate}}"
               tooltip-placement="top-left">
    </div>
    <div class="col-md-6">
        <input type="number"
               class="form-control"
               ng-model="vm.anwesenheitsSoll.mittage"
               step="1"
               ng-attr-placeholder="{{'KIND.ANWESENHEITS_SOLL.MITTAGE' | translate}}"
               uib-tooltip="{{'KIND.ANWESENHEITS_SOLL.MITTAGE' | translate}}"
               tooltip-placement="top-left">
    </div>
    <div class="col-md-6">
        <input type="number"
               class="form-control"
               ng-model="vm.anwesenheitsSoll.halbeNachmittage"
               step="1"
               ng-attr-placeholder="{{'KIND.ANWESENHEITS_SOLL.NACHMITTAGE' | translate}}"
               uib-tooltip="{{'KIND.ANWESENHEITS_SOLL.NACHMITTAGE' | translate}}"
               tooltip-placement="top-left">
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <textarea class="form-control"
                  ng-model="vm.anwesenheitsSoll.bemerkung"
                  msd-elastic
                  ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                  uib-tooltip="{{'COMMON.BEMERKUNG' | translate}}"
                  tooltip-placement="top-left"
                  maxlength="10000">
        </textarea>
    </div>
</div>
