<div class="dvb-kind-monat-zuweisen">
    <div class="filter-bar normal-abstand-bottom"
         ng-class="{'access-control-visible': vm.bringAbholZeitenAccess.hasReadAccess()}">
        <ul class="list-inline">
            <li>
                <span data-translate="KIND.MONATSBELEGUNG.MONATSPLAN"></span>
            </li>
            <li>
                <dvlib-date-switcher mode="month"
                                     class="small-abstand-left small-abstand-right normal-abstand-bottom"
                                     [(date)]="vm.zuweisungAb"
                                     (debounced-date-change)="vm.changeMonth()"
                                     [is-disabled]="vm.isLoading">
                </dvlib-date-switcher>
            </li>
            <li>
                <i class="fa fa-cube"
                   uib-tooltip="{{'COMMON.GRUPPE.SINGULAR' | translate}}"
                   tooltip-placement="top-left">
                </i>
                <span ng-bind="vm.gruppe.getDisplayName()"></span>
            </li>
            <li ng-if="!vm.bringAbholZeitenAccess.hasReadAccess() && vm.releaseForParentAllowed" class="pull-right">
                <dvlib-spinner [show]="vm.isLoading"></dvlib-spinner>
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.releaseForParent()"
                        ng-show="!vm.isLoading"
                        data-translate="KIND.MONATSBELEGUNG.RELEASE_FOR_MAIN_CONTACT">
                </button>
            </li>
        </ul>

        <button type="button"
                class="btn btn-link"
                ng-class="{
                    'normal-abstand-bottom': vm.bringAbholZeitenAccess.hasReadAccess(),
                    'small-abstand-bottom': !vm.bringAbholZeitenAccess.hasReadAccess()
                }"
                data-translate="KIND.MONATSBELEGUNG.ZEITEN_LEEREN"
                ng-click="vm.doEmptyAnwesenheitsZeiten()">
        </button>

        <div ng-if="vm.bringAbholZeitenAccess.hasReadAccess()" class="access-control layout-row">
            <div data-translate="{{vm.accessControlText}}"
                 data-translate-value-date="{{vm.accessControlDate | amDateFormat: 'DD.MM.YYYY'}}">
            </div>
            <ul class="list-inline no-margin"
                dvb-auth
                require-permission="kita:manage:general">
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.resendForParent()"
                            data-translate="KIND.MONATSBELEGUNG.RESEND">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.adjustParentDeadline()"
                            data-translate="KIND.MONATSBELEGUNG.ADJUST_DEADLINE">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.withdrawFromParent()"
                            data-translate="KIND.MONATSBELEGUNG.WITHDRAW">
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7">
            <dvb-monats-belegung kita="vm.kita"
                                 zuweisung-ab="vm.zuweisungAb"
                                 input-rows="vm.inputRows"
                                 on-empty-anwesenheits-zeiten="vm.doEmptyAnwesenheitsZeiten()">
            </dvb-monats-belegung>
        </div>
        <div class="col-md-4 col-md-offset-1">

            <div ng-if="vm.anwesenheitsSollVerbrauch && vm.anwesenheitsSollVerbrauch.hasSoll"
                 class="big-abstand-bottom">
                <dvb-anwesenheits-soll-verbrauch anwesenheits-soll-verbrauch="vm.anwesenheitsSollVerbrauch">
                </dvb-anwesenheits-soll-verbrauch>
            </div>

            <h2 data-translate="COMMON.BETREUUNGSWUNSCH" class="small-abstand-bottom"></h2>

            <div ng-if="vm.kind.bewerbung">
                <p data-translate="KIND.ZUWEISUNG.AB_DATEVALUE"
                   data-translate-value-date="{{vm.kind.bewerbung.gewuenschteBetreuungAb | amDateFormat: 'D.M.YYYY'}}"
                   class="normal-abstand-bottom">
                </p>

                <dvb-betreuungswunsch anmeldedatum="vm.kind.anmeldeDatum"
                                      bewerbung="vm.kind.bewerbung"
                                      bemerkung="{{vm.kind.bemerkung}}"
                                      on-date-selected="vm.switchToClickableDate(date)"
                                      hide-action-header="true">
                </dvb-betreuungswunsch>
            </div>

            <div ng-if="!vm.kind.bewerbung">
                <p data-translate="COMMON.KEIN_WUNSCH_OFFEN"></p>
            </div>

            <div class="big-abstand-top">
                <dvb-zuweisen-form is-loading="vm.isLoading"
                                   form-model="vm.zuweisenFormModel"
                                   on-zuweisen="vm.zuweisen()"
                                   on-cancel="vm.goBack()"
                                   kinder-orte="[vm.kita]"
                                   zuweisung-ab="vm.zuweisungAb"
                                   show-monats-belegung-standard="true">
                </dvb-zuweisen-form>
            </div>
        </div>
    </div>
</div>
