/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    BetreuungsVereinbarungsKonfiguration,
    ContextualAdresse,
    DokumentLayout,
    KinderOrt,
} from '@dv/kitadmin/models';
import {KinderOrtType} from '@dv/kitadmin/models';
import {DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {
    BetreuungsVereinbarungsKonfigurationService,
} from '../../../common/service/rest/kinderort/betreuungsVereinbarungsKonfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        konfiguration: '<',
        dokumentLayouts: '<',
    },
    template: require('./dvb-kita-betreuungs-vereinbarungs-konfiguration.html'),
    controllerAs: 'vm',
};

export class DvbKitaBetreuungsVereinbarungsKonfiguration implements angular.IController {

    public static $inject: readonly string[] = ['betreuungsVereinbarungsKonfigurationService', 'errorService'];

    public kinderOrt!: KinderOrt;
    public konfiguration!: BetreuungsVereinbarungsKonfiguration;
    public dokumentLayouts!: DokumentLayout[];
    public showBetreuungBeiFamilie: boolean = false;

    public dokumentLayout: DokumentLayout | null = null;

    public constructor(
        private betreuungsVereinbarungsKonfigurationService: BetreuungsVereinbarungsKonfigurationService,
        private errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.updateDokumentLayout();
        if (DvbDateUtil.isEndOfTime(this.konfiguration.gueltigBis)) {
            this.konfiguration.gueltigBis = null;
        }
        if (this.kinderOrt.dtype === KinderOrtType.TAGES_ELTERN) {
            this.showBetreuungBeiFamilie = true;
        }
    }

    public updateDokumentLayout(): void {
        this.dokumentLayout =
            DvbUtil.findFirst(this.dokumentLayouts, layout => layout.id === this.konfiguration.dokumentLayoutId);
    }

    public save(dokumentLayout: DokumentLayout | null): void {
        this.errorService.clearAll();
        this.konfiguration.applyUnterschriftState();
        this.konfiguration.dokumentLayoutId = dokumentLayout?.id ?? null;
        this.updateDokumentLayout();

        this.betreuungsVereinbarungsKonfigurationService.update(this.konfiguration);
    }

    public saveContextualAdresse(contextualAdresse: ContextualAdresse): void {
        this.konfiguration.contextualAdresse = contextualAdresse;
        this.save(this.dokumentLayout);
    }
}

componentConfig.controller = DvbKitaBetreuungsVereinbarungsKonfiguration;
angular.module('kitAdmin').component('dvbKitaBetreuungsVereinbarungsKonfiguration', componentConfig);
