/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BlobInfo} from '@dv/kitadmin/models';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {ITimelineEntry} from '../../../common/timeline/ITimelineEntry';
import type {ITimelineController} from '../../models/ITimelineController';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {
        dvbDownloadCtrl: '^dvbDownload',
    },
    bindings: {
        entry: '<',
        timelineController: '<',
    },
    template: require('./dvb-attachments-download.html'),
    controllerAs: 'vm',
};

export class DvbAttachmentsDownload implements angular.IController {
    public static $inject: readonly string[] = [];

    public entry!: ITimelineEntry;
    public timelineController!: ITimelineController<ITimelineEntry>;

    public isLoading: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public downloadAttachment(blobInfo: BlobInfo): void {
        this.isLoading = true;

        this.timelineController.getAttachment(checkPresent(this.entry.id), blobInfo.id)
                .then(tempBlob => this.dvbDownloadCtrl.downloadFileByUrl(tempBlob))
                .finally(() => {
                    this.isLoading = false;
                });
    }
}

componentConfig.controller = DvbAttachmentsDownload;
angular.module('kitAdmin').component('dvbAttachmentsDownload', componentConfig);
