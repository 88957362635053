<div class="angestellte-anstellung row">

    <div class="col-md-7 big-abstand-bottom">
        <h2 data-translate="PERSONAL.ANSTELLUNG.VERLAUF"></h2>

        <div ng-if="vm.anstellungen.length === 0"
             class="collapsible-panel-container first-container">
            <p data-translate="PERSONAL.ANSTELLUNG.NO_ANSTELLUNG"></p>
        </div>

        <div ng-repeat="entity in vm.sortedAnstellungen">
            <dvb-limited-entity-verlauf entity="entity"
                                        previous-entity="vm.sortedAnstellungen[$index - 1]"
                                        enable-edit-mode="false"
                                        first="$first"
                                        translation-root="'PERSONAL.ANSTELLUNG'"
                                        enable-actions="vm.hasEditPrivileges"
                                        on-remove="vm.deleteAnstellung(entity)"
                                        on-revert="vm.revert(entity)"
                                        on-edit="vm.goToEditAnstellung(entity)">
                <entity-heading>
                    <p data-translate="PERSONAL.ANSTELLUNG.FROM"
                       data-translate-value-date="{{entity.gueltigAb | amDateFormat: 'D.M.YYYY'}}">
                    </p>
                </entity-heading>
                <entity-read-only>
                    <dvb-anstellung-verlauf-item anstellung="entity">
                    </dvb-anstellung-verlauf-item>
                </entity-read-only>
            </dvb-limited-entity-verlauf>
        </div>

        <dvb-limited-entity-assignment-controls ng-if="vm.hasEditPrivileges"
                                                entities="vm.sortedAnstellungen"
                                                assigned-entity="vm.angestellte"
                                                translation-root="'PERSONAL.ANSTELLUNG'"
                                                disable-new-form="true"
                                                on-new="vm.goToCreateAnstellung()"
                                                on-terminate="vm.terminate(endDate, mode)">
        </dvb-limited-entity-assignment-controls>
    </div>
</div>
