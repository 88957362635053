<ul class="dvb-version list-inline no-margin hidden-print">
    <li>
        <i class="fa fa-copyright"></i>
        DV Bern AG
        <span ng-bind="vm.currentYear"></span>
    </li>
    <li ng-if="!vm.hasVersionMismatch" class="small">
        <a href="https://blog.kitadmin.ch"
           target="_blank"
           rel="noopener"
           data-translate="VERSION.VERSION_INFO_SINGLE"
           data-translate-value-version="{{vm.deployedVersions.frontendVersion}}">
        </a>
    </li>
    <li ng-if="vm.hasVersionMismatch"
        class="small"
        data-translate="VERSION.VERSION_INFO"
        data-translate-value-frontend-version="{{vm.deployedVersions.frontendVersion}}"
        data-translate-value-backend-version="{{vm.deployedVersions.backendVersion}}"
        uib-tooltip="{{'VERSION.VERSION_TOOLTIP' | translate }}">
    </li>
    <li class="language">
        <button type="button"
                class="btn btn-link small"
                ng-class="{'active': vm.language === 'DE'}"
                ng-click="vm.switchLanguage('DE')"
                data-translate="LANGUAGE.DE">
        </button>
        <button type="button"
                class="btn btn-link small"
                ng-class="{'active': vm.language === 'FR'}"
                ng-click="vm.switchLanguage('FR')">
            <span data-translate="LANGUAGE.FR"></span>
            <i class="wip fa fa-info-circle" uib-tooltip="{{'LANGUAGE.WIP' | translate}}"></i>
        </button>
    </li>
</ul>
