/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {KIND_STATES} from './kind-states';
import {KIND_ZUWEISEN_STATES} from './zuweisung';

export * from './anmeldung';
export * from './anwesenheitssoll';
export * from './betreuung';
export * from './dvb-kind-header/dvb-kind-header';
export * from './dvb-kind-profil/dvb-kind-profil';
export * from './extra-plaetze';
export * from './KindMainCtrl';
export * from './kontakte';
export * from './leistungen';
export * from './tarife';
export * from './zuweisung';

// Add New Component JS Above

angular.module('kitAdmin').config(kindConfig);

kindConfig.$inject = ['$stateProvider'];

/* eslint-disable sonarjs/no-duplicate-string */
function kindConfig($stateProvider: StateProvider): void {
    Object.values(KIND_STATES).forEach(state => $stateProvider.state(state));
    Object.values(KIND_ZUWEISEN_STATES).forEach(state => $stateProvider.state(state));
}
