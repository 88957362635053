<div class="dvb-dashboard-greeting">
    <div class="kitadmin-content container">
        <div class="row">
            <div class="col-xs-9">
                <h1 ng-bind="vm.getGreeting()"></h1>
            </div>
            <div class="col-xs-1">
                <ul class="date">
                    <li ng-bind="vm.date.weekday"></li>
                    <li class="divider"></li>
                    <li ng-bind="vm.date.day"></li>
                    <li ng-bind="vm.date.month"></li>
                </ul>
            </div>
        </div>
    </div>
</div>
