<div class="dvb-kind-tarife-betreuungsgutschein-header-manuell history-columns">
    <p class="history-column-left"
       data-translate="COMMON.GUELTIGKEIT_VON_BIS"
       data-translate-value-date-ab="{{vm.betreuungsGutscheinGroup.gueltigAb | amDateFormat:'D.M.YYYY'}}"
       data-translate-value-date-bis="{{vm.betreuungsGutscheinGroup.gueltigBis | amDateFormat:'D.M.YYYY'}}">
    </p>
    <div class="history-column-middle">
        <p ng-bind="vm.betreuungsGutschein.bemerkung"></p>
        <ng-transclude></ng-transclude>
    </div>
</div>
