/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FirmenKontingent, KinderOrt, SubventioniertesKontingent} from '@dv/kitadmin/models';
import {checkPresent, displayableComparator, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        zuweisungAb: '<',
        elementId: '<?',
        setSubventioniert: '&',
        setPrivat: '&',
        setFirma: '&',
        deleteBelegung: '&',
    },
    template: require('./dvb-zuweisen-popover.html'),
    controllerAs: 'vm',
};

export class DvbZuweisenPopover implements angular.IOnChanges, angular.IController {
    public static $inject: readonly string[] = [];

    public kita!: KinderOrt;
    public zuweisungAb: moment.Moment = moment();
    public elementId: string | null = null;

    public setSubventioniert?: (subventioniertesKontingent: SubventioniertesKontingent) => void;
    public setPrivat?: () => void;
    public setFirma?: (firmenKontingent: FirmenKontingent) => void;

    public firmenKontingente: FirmenKontingent[] = [];

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (!changes.zuweisungAb && !changes.kita) {
            return;
        }

        const firstOfWeek = DvbDateUtil.startOfWeek(moment(this.zuweisungAb));
        this.firmenKontingente = this.kita.firmenKontingente
                .filter(kontingent => kontingent.isGueltigOnWithKitaId(firstOfWeek, checkPresent(this.kita.id)));
        this.firmenKontingente.sort(displayableComparator);
    }
}

componentConfig.controller = DvbZuweisenPopover;
angular.module('kitAdmin').component('dvbZuweisenPopover', componentConfig);
