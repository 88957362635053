<div class="dvb-tarif-main">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <i class="fa fa-calculator header-icon"
                   uib-tooltip="{{'COMMON.TARIF.SINGULAR' | translate}}"
                   tooltip-placement="top-left">
                </i>
                <h1 uib-tooltip="{{vm.tarif.name}}"
                    tooltip-placement="top-left"
                    ng-bind="vm.tarif.name">
                </h1>
                <span class="layout-spacer"></span>
            </div>
        </div>
    </div>

    <div class="row tarif">

        <div class="col-md-7 big-abstand-bottom dvb-form">
            <dvb-tarif-verlauf tarif-values="vm.tarif.values"
                               tarif-id="vm.tarif.id"
                               at-least-one="true"
                               on-update="vm.updateTarifValue(tarifValue)"
                               on-delete="vm.deleteTarifValue(tarifValue)"
                               on-revert="vm.revertTarifValue(tarifValue)">
            </dvb-tarif-verlauf>

            <!-- TarifValues Footer -->
            <div class="normal-abstand-top" dvb-auth require-permission="kita:betreuung_admin:*">
                <div ng-if="vm.showAddTarifValueForm">
                    <h2 data-translate="TARIF.TARIF_NEUER_VALUE"></h2>

                    <dvb-tarif-value-form on-update="vm.saveNewTarifValue(tarifValue)"
                                          on-cancel="vm.showAddTarifValueForm = false"
                                          with-gueltig-ab="true">
                    </dvb-tarif-value-form>
                </div>

                <!-- generelle Tarif actions-->
                <div class="main-actions normal-abstand-top" ng-hide="vm.showAddTarifValueForm">
                    <ul class="list-inline">
                        <li ng-if="!vm.tarif.nullTarif">
                            <button type="button"
                                    class="btn btn-primary"
                                    data-translate="TARIF.TARIF_NEUER_VALUE"
                                    ng-click="vm.showAddTarifValueForm = true">
                            </button>
                        </li>
                        <li ng-if="!vm.tarif.timestampGeloescht">
                            <a href="" ng-click="vm.confirmDeleteTarif()" data-translate="TARIF.TARIF_DELETE"></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row big-abstand-top">
                <div class="col-xs-12">
                    <h2 data-translate="COMMON.BEMERKUNG"></h2>
                    <dvb-editable-text-area ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                            ng-model="vm.tarif.bemerkung"
                                            maxlength="1000"
                                            on-submit="vm.save()"
                                            dvb-auth
                                            require-permission="kita:betreuung_admin:*"
                                            on-unauthorised-disable>
                    </dvb-editable-text-area>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <!-- STAMMDATEN -->
            <h2 data-translate="TARIF.TARIF_STAMMDATEN"></h2>

            <div class="row form-row">
                <div class="col-md-12">
                    <dvb-editable-label ng-model="vm.tarif.name"
                                        ng-change="vm.saveBasisData()"
                                        is-valid="vm.isValidName(param)"
                                        maxlength="255"
                                        ng-attr-placeholder="{{'COMMON.TARIF.SINGULAR' | translate}}"
                                        dvb-auth
                                        require-permission="kita:betreuung_admin:*"
                                        on-unauthorised-disable>
                    </dvb-editable-label>
                </div>
            </div>

            <div class="row form-row small-abstand-top">
                <div class="col-xs-12">
                    <p uib-tooltip="{{'TARIF.TYPE.UNMODIFIABLE' | translate}}"
                       tooltip-placement="top-left"
                       ng-model="vm.tarif.dtype"
                       data-translate="TARIF.TARIF_TYP"
                       data-translate-value-type="{{'TARIF.TYPE.' + vm.tarif.dtype | translate}}">
                    </p>
                </div>
            </div>

            <h2 data-translate="COMMON.PARAMETERS"
                class="big-abstand-top"
                ng-show="vm.tarif.parameters.length > 0">
            </h2>

            <div class="normal-abstand-bottom" ng-repeat="type in vm.paramTypes">
                <div ng-if="vm.getParameterOfType(type).length > 0">
                    <h3 data-translate="{{'TARIF.PARAM_TYPE.' + type}}"></h3>
                    <p ng-repeat="parameter in vm.getParameterOfType(type) | orderBy:'orderValue'"
                       ng-bind="parameter.name">
                    </p>
                </div>
            </div>

            <div dvb-auth require-permission="kita:betreuung_admin:*" require-condition="!vm.tarif.nullTarif">
                <h2 data-translate="TARIF.VORLAGEN" class="big-abstand-top"></h2>

                <div class="row">
                    <div class="col-md-12">
                        <p>
                            <a href="" data-translate="TARIF.TARIF_VORLAGE_LABEL"
                               ng-click="vm.downloadTarifVorlage()"></a>
                            <dvlib-spinner [show]="vm.loadingTemplate"></dvlib-spinner>
                        </p>
                    </div>
                </div>
            </div>

            <div ng-if="vm.kinderOrte.length > 0 || vm.firmenKontingente.length > 0" class="usage">
                <h2 data-translate="TARIF.USED_BY" class="big-abstand-top"></h2>
                <ul class="list-unstyled">
                    <li ng-repeat="kinderOrt in vm.kinderOrte">
                        <i class="fa fa-home"></i>
                        <a ui-sref="{{vm.tarifzuweisungState.name + '({id: ' + kinderOrt.id + ' })'}}"
                           ng-bind="kinderOrt.getDisplayName()">
                        </a>
                    </li>
                    <li ng-repeat="kontingent in vm.firmenKontingente">
                        <i class="fa fa-building"></i>
                        <a ui-sref="base.firmenkontingent({id: {{kontingent.id}} })"
                           ng-bind="kontingent.getDisplayName()"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
