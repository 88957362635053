/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AbstractPagesController} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {DvbRestUtil} from '@dv/shared/code';
import angular from 'angular';
import {from, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {EMAIL_TEMPLATE_EDIT, EMAIL_TEMPLATE_NEW} from '../../communication-states';
import type {EmailTemplate} from '../../models/EmailTemplate';
import type {EmailTemplateService} from '../../service/email-template-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    template: require('./dvb-email-template.html'),
    controllerAs: 'vm',
};

export class DvbEmailTemplate extends AbstractPagesController<EmailTemplate> implements angular.IController {
    public static override $inject: readonly string[] = [
        'emailTemplateService',
        '$q',
        'dialogService',
    ];

    public emailTemplateNewState = EMAIL_TEMPLATE_NEW;
    public emailTemplateEditState = EMAIL_TEMPLATE_EDIT;

    private timeout?: angular.IDeferred<undefined> = undefined;

    public constructor(
        private readonly emailTemplateService: EmailTemplateService,
        private readonly $q: angular.IQService,
        private readonly dialogService: DialogService,
    ) {
        super();
    }

    public override $onInit(): void {
        this.reloadItems();
    }

    public reloadItems(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();
        this.isLoading = true;
        this.emailTemplateService.getAllAllowed().then(pageContainer => {
            this.pageContainer = pageContainer;
            this.isLoading = false;
        }).catch(err => {
            if (!DvbRestUtil.isRequestCancelled(err)) {
                this.isLoading = false;
            }
        });
    }

    public delete(emailTemplateId: string): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMUNICATION.EMAIL_TEMPLATE.SINGULAR',
            confirm: () => from(this.emailTemplateService.delete(emailTemplateId))
                .pipe(take(1), tap(() => this.reloadItems())),
        });
    }

    public hasPermissionToModify(emailTemplate: EmailTemplate): boolean {
        return this.emailTemplateService.hasPermissionToModify(emailTemplate);
    }

    public getKinderOrtNames(template: EmailTemplate): string {
        return template.kinderOrte.map(k => k.getDisplayName())
            .join('\n');
    }
}

componentConfig.controller = DvbEmailTemplate;
angular.module('kitAdmin').component('dvbEmailTemplate', componentConfig);
