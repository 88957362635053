<h3 data-translate="COMMON.KIND.SINGULAR"></h3>
<p>
    <dvb-name-geburtstag-geschlecht person="vm.anmeldung.kind"></dvb-name-geburtstag-geschlecht>
</p>
<p ng-repeat="customFieldVal in vm.anmeldung.kind.customFieldValues"
   ng-init="name = customFieldVal.customField.getFieldName(); value = customFieldVal.value.getDisplayValue(vm.$translate);">
    <span ng-bind="name"></span>: <span ng-bind="value"></span>
</p>

<h3 data-translate="KIBON.VERFUEGUNGEN.GESUCHSTELLER" class="normal-abstand-top"></h3>
<div ng-repeat="gesuchsteller in vm.anmeldung.gesuchsteller">
    <dvb-anmeldung-kind-kontaktperson-display kontaktperson="gesuchsteller">
    </dvb-anmeldung-kind-kontaktperson-display>

    <div class="normal-abstand-top">
        <dvlib-adresse-display [adresse]="gesuchsteller.address" [display-type]="'short'"></dvlib-adresse-display>
    </div>

    <p ng-repeat="customFieldVal in gesuchsteller.customFieldValues"
       class="normal-abstand-top"
       ng-init="name = customFieldVal.customField.getFieldName(); value = customFieldVal.value.getDisplayValue(vm.$translate);">
        <span ng-bind="name"></span>: <span ng-bind="value"></span>
    </p>
</div>
