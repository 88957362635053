/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DokumentLayout, TempBlob} from '@dv/kitadmin/models';
import type {SearchResultEntry} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {ReportState, SteuerbescheinigungStructure} from '../../models';
import {ERR_VALIDATION, ParameterSteuerbescheinigungen} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        kontaktperson: '<?',
        year: '<?',
    },
    template: require('./dvb-report-steuerbescheinigung.html'),
    controllerAs: 'vm',
};

export class DvbReportSteuerbescheinigung implements angular.IController {

    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public kinderOrt?: SearchResultEntry;
    public kontaktperson?: SearchResultEntry;
    public year?: number;

    public readonly steuerbescheinigungReportForm?: angular.IFormController;
    public readonly defaultYear: number = moment().year();

    public layout?: DokumentLayout;

    public structure: SteuerbescheinigungStructure = 'MONTHLY';

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public updateState(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
        this.reportService.updateStateParams({year: this.year});
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.steuerbescheinigungReportForm!.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        const params = new ParameterSteuerbescheinigungen(
            this.kontaktperson ? [this.kontaktperson.id] : [],
            this.kinderOrt ? this.kinderOrt.id : null,
            this.layout ? this.layout.id : null,
            this.year ? this.year : this.defaultYear,
            this.structure,
        );

        return this.reportService.getTempBlobForSteuerbescheinigungen(params);
    }
}

componentConfig.controller = DvbReportSteuerbescheinigung;
angular.module('kitAdmin').component('dvbReportSteuerbescheinigung', componentConfig);
