<div class="dvb-kontaktperson-rechnungen">

    <div class="row">
        <div class="col-md-12">
            <h2 data-translate="KONTAKTPERSON.RECHNUNGSUEBERSICHT"></h2>

            <dvlib-date-switcher mode="year"
                                 class="normal-abstand-left"
                                 [(date)]="vm.year"
                                 (debounced-date-change)="vm.updateYear()">
            </dvlib-date-switcher>
        </div>
    </div>

    <dvb-rechnungen rechnungen="vm.rechnungen"
                    is-loading="vm.isLoading"
                    pre-selected-rechnung-id="vm.preSelectedRechnungId"
                    mode="vm.mode">
    </dvb-rechnungen>
</div>
