<div id="{{vm.elementId ? vm.elementId : ''}}" class="dvb-zuweisen-popover popover popover-zuweisung">
    <div class="arrow"></div>
    <div class="popover-content">
        <ul class="list-unstyled text-left no-margin">
            <li ng-if="vm.kita.getSubventioniertesKontingent(vm.zuweisungAb)">
                <a href=""
                   ng-click="vm.setSubventioniert({subventioniertesKontingent: vm.kita.getSubventioniertesKontingent(vm.zuweisungAb)})"
                   data-translate="COMMON.SUBVENTIONIERTER_PLATZ">
                </a>
            </li>
            <li><a href="" ng-click="vm.setPrivat()" data-translate="COMMON.PRIVATER_PLATZ"></a></li>
            <li class="divider" ng-if="vm.firmenKontingente.length > 0"></li>
            <li ng-repeat="firmenKontingent in vm.firmenKontingente">
                <a href=""
                   ng-click="vm.setFirma({firmenKontingent: firmenKontingent})"
                   ng-bind="firmenKontingent.getDisplayName()">
                </a>
            </li>
        </ul>
    </div>
</div>
