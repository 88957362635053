<div class="dvb-kinderort-firmen">
    <h2 data-translate="COMMON.PLATZART.PLURAL"
        class="big-abstand-top"
        ng-if="vm.firmenMitAktivenKontingenten.length > 0 || vm.firmenMitInaktivenKontingenten.length > 0">
    </h2>

    <div class="form-row">
        <div class="row normal-abstand-top" ng-repeat="firmaAndKontingente in vm.firmenMitAktivenKontingenten">
            <div class="col-md-12">
                <a ui-sref="base.firma.profil({id: firmaAndKontingente.firma.id})"
                   ng-bind="firmaAndKontingente.firma.name"></a>

                <div data-translate="{{vm.getKontingentePrefixKey(firmaAndKontingente)}}"
                     data-translate-value-kontingente="{{vm.getKontingenteAsText(firmaAndKontingente)}}"
                     class="floating-text">
                </div>
            </div>
        </div>
    </div>

    <ul class="list-unstyled">
        <li ng-show="!vm.showInaktiveFirmenKontingente && vm.firmenMitInaktivenKontingenten.length > 0">
            <p>
                <a href=""
                   ng-click="vm.showInaktiveFirmenKontingente = true"
                   ng-bind="'KINDERORT.ARCHIVIERTE_KONTINGENTE_ANZEIGEN' | translate">
                </a>
            </p>
        </li>
        <li ng-show="vm.showInaktiveFirmenKontingente">
            <p>
                <a href=""
                   ng-click="vm.showInaktiveFirmenKontingente = false"
                   ng-bind="'KINDERORT.ARCHIVIERTE_KONTINGENTE_AUSBLENDEN' | translate">
                </a>
            </p>
        </li>
    </ul>

    <div class="form-row" ng-show="vm.showInaktiveFirmenKontingente">
        <div class="row small-abstand-top" ng-repeat="firmaAndKontingente in vm.firmenMitInaktivenKontingenten">
            <div class="col-md-12">
                <a ui-sref="base.firma.profil({id: firmaAndKontingente.firma.id})"
                   ng-bind="firmaAndKontingente.firma.name">
                </a>

                <div data-translate="{{vm.getKontingentePrefixKey(firmaAndKontingente)}}"
                     data-translate-value-kontingente="{{vm.getKontingenteAsText(firmaAndKontingente)}}"
                     class="floating-text">
                </div>
            </div>
        </div>
    </div>
</div>
