import {PERMISSION} from '@dv/shared/authentication/model';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import {UrlParameterTypes} from '../base/config/url-parameter-types/urlParameterTypes';
import {
    KitaWorkTimeControllingComponent,
} from './component/kita-work-time-controlling/kita-work-time-controlling.component';

export const KINDERORT_WORK_TIME_CONTROLLING_STATE = {
    name: 'base.kinderort.worktimecontrolling',
    url: '/arbeitszeitkontrolle?:date',
    component: KitaWorkTimeControllingComponent,
    data: {
        subtitle: 'COMMON.NAV_WORK_TIME_CONTROLLING',
        permission: PERMISSION.MODULE.WORK_TIME_CONTROLLING,
    },
    params: {
        date: {
            type: UrlParameterTypes.MOMENT_DATE,
            dynamic: true,
        },
    },
} satisfies Ng2StateDeclaration;

export const KINDERORT_WORK_TIME_CONTROLLING_STATES = {
    KINDERORT_WORK_TIME_CONTROLLING_STATE,
};
