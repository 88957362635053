<div class="dvb-kind-kontakt-erfassen">
    <h2 data-translate="{{vm.kontakt.relationship.isHauptkontakt() ?
        'KONTAKTPERSON.HAUPTKONTAKT' :
        'COMMON.KONTAKT'}}">
    </h2>

    <div class="form-row">
        <dvb-kontaktperson-stammdaten-display ng-if="!vm.kontakt.isNewKontaktperson()"
                                              kontaktperson="vm.kontakt.kontaktperson"
                                              adress-datum="vm.adressDatum">
        </dvb-kontaktperson-stammdaten-display>

        <div ng-if="vm.kontakt.isNewKontaktperson()">
            <!-- neue Kontaktperson -->
            <dvb-kontaktperson-stammdaten-form kontakt="vm.kontakt"
                                               uneditable-adresse="vm.uneditableAdresse"
                                               adress-datum="vm.adressDatum">
            </dvb-kontaktperson-stammdaten-form>
        </div>

        <div class="relationship-flags row" ng-class="{'small-abstand-top':!vm.kontakt.isNewKontaktperson()}">
            <div class="col-md-12">
                <dvb-erziehungsberechtigter-input has-adresse="vm.kontakt.hasAdresse() || vm.kontakt.isNewKontaktperson()"
                                                  kind-kontakt-relationship="vm.kontakt.relationship"
                                                  on-create-erziehungsberechtigter="vm.setAbholberechtigt()">
                </dvb-erziehungsberechtigter-input>

                <dvb-abholberechtigt-input kind-kontakt-relationship="vm.kontakt.relationship">
                </dvb-abholberechtigt-input>

                <dvb-rechnungsempfaenger-input has-adresse="vm.kontakt.hasAdresse() || vm.kontakt.isNewKontaktperson()"
                                               kind-kontakt-relationship="vm.kontakt.relationship"
                                               on-change="vm.changeRechnungsempfaenger()">
                </dvb-rechnungsempfaenger-input>
                <dvb-rechnungsempfaenger-value class="small-abstand-top"
                                               ng-if="vm.showRechnungsempfaengerForm && vm.kontakt.relationship.isRechnungsempfaenger()"
                                               rechnungsempfaenger="vm.kontakt.relationship.rechnungsempfaenger"
                                               on-change="vm.changeRechnungsempfaengerValue()">
                </dvb-rechnungsempfaenger-value>
            </div>
        </div>
    </div>

    <div class="row kontakt-actions">
        <div class="col-md-12">
            <ul class="list-unstyled">
                <li>
                    <a href=""
                       ng-click="vm.onRemoveKontakt({kontakt: vm.kontakt})"
                       data-translate="KIND.KONTAKT_ENTFERNEN"></a>
                </li>
                <li ng-if="!vm.kontakt.relationship.isHauptkontakt()">
                    <a href=""
                       ng-click="vm.onSetAsHauptkontakt({kontakt: vm.kontakt})"
                       data-translate="KIND.DIESEN_KONTAKT_ALS_HAUPTKONTAKT_DEFINIEREN"></a>
                </li>
            </ul>
        </div>
    </div>
</div>
