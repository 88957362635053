<div class="dvb-kind-zuweisen">
    <div class="row">
        <!-- GRUPPPEN -->
        <div class="col-md-4">

            <div class="normal-abstand-bottom">
                <div class="no-bottom-margin" ng-show="vm.showDatepicker">
                    <label>
                        <span class="floating-text" data-translate="KIND.ZUWEISUNG.ZUWEISUNG_AB"></span>
                        <dvb-datepicker-text-field ng-model="vm.zuweisungAb"
                                                   required
                                                   ng-change="vm.onDateChange()"
                                                   ng-attr-placeholder="{{'KIND.ZUWEISUNG.ZUWEISUNGSDATUM' | translate}}">
                        </dvb-datepicker-text-field>
                    </label>
                </div>
                <div ng-hide="vm.showDatepicker">
                    <h2 data-translate="KIND.ZUWEISUNG.AB_DATEVALUE"
                        data-translate-value-date="{{vm.zuweisungAb.format('D. MMMM YYYY')}}">
                    </h2>
                    <p>
                        <a href="" ng-click="vm.showDatepicker = true">
                            <span data-translate="KIND.ZUWEISUNG.NEUE_BETREUUNG_FESTLEGEN"></span>
                        </a>
                    </p>
                </div>
            </div>

            <div ng-repeat="kindKinderOrtZuweisung in vm.kindKinderOrtZuweisungen"
                 class="big-abstand-bottom">
                <dvb-kind-kita-zuweisen model="kindKinderOrtZuweisung"
                                        show-kinder-ort-name="vm.kindKinderOrtZuweisungen.length > 1"
                                        is-fully-collapsible="vm.isFullyCollapsible"
                                        show-anwesenheiten="vm.zuweisenFormModel.noFlexiblePlaetze"
                                        go-to-monat="vm.goToZuweisenMonat(kinderOrtId, fraktionId)">
                </dvb-kind-kita-zuweisen>
            </div>

        </div>

        <!-- KIND HEADER -->
        <div class="col-md-7 col-md-offset-1">

            <div ng-if="!vm.tagesschulAnmeldung">
                <!-- BETREUUNGSWUNSCH -->
                <dvb-collapsible-panel ng-if="vm.kind.bewerbung" is-collapsed="false" class="border-top">
                    <panel-header>
                        <dvb-betreuungswunsch-header bewerbung="vm.kind.bewerbung"
                                                     on-date-selected="vm.switchToClickableDate(date)">
                        </dvb-betreuungswunsch-header>
                    </panel-header>
                    <panel-content>
                        <dvb-betreuungswunsch anmeldedatum="vm.kind.anmeldeDatum"
                                              bewerbung="vm.kind.bewerbung"
                                              bemerkung="{{vm.kind.bemerkung}}"
                                              on-date-selected="vm.switchToClickableDate(date)">
                        </dvb-betreuungswunsch>
                    </panel-content>
                </dvb-collapsible-panel>

                <!-- keine Bewerbung -->
                <div ng-if="!vm.kind.bewerbung" class="collapsible-panel-container border-top">
                    <p class="collapsible-panel-body" data-translate="COMMON.KEIN_WUNSCH_OFFEN"></p>
                </div>

                <!-- BELEGUNGEN -->
                <dvb-betreuungsverlauf kind="vm.kind"
                                       is-date-clickable="true"
                                       expand-ab="vm.zuweisungAb"
                                       expand-bis="vm.zuweisungAb"
                                       on-date-selected="vm.switchToClickableDate(date)"
                                       on-belegung-removed="vm.initZeitraumFelder()">
                </dvb-betreuungsverlauf>
            </div>

            <div ng-if="vm.tagesschulAnmeldung">
                <dvb-tagesschul-anmeldung-confirmation anmeldung="vm.tagesschulAnmeldung">
                </dvb-tagesschul-anmeldung-confirmation>
            </div>

            <div class="big-abstand-top">
                <dvb-zuweisen-form is-loading="vm.isLoading"
                                   form-model="vm.zuweisenFormModel"
                                   on-zuweisen="vm.zuweisen()"
                                   on-cancel="vm.goBack()">
                </dvb-zuweisen-form>
            </div>
        </div>
    </div>
</div>
