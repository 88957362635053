/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Belegung,
    BelegungZeitenByDayOfWeek,
    GruppenWochenBelegung,
    KinderOrtFraktion,
    KinderOrtFraktionId,
    PlatzTyp,
    VertraglichesPensum,
    ZeitraumFeld,
} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import type moment from 'moment';
import type {KinderOrtZuweisung} from './KinderOrtZuweisung';

/**
 * Model für die Zuweisung eines Kindes in einem KinderOrt zu einer bestimmten Woche.
 */
export class KindKinderOrtZuweisung {
    public aktuelleGruppen: KinderOrtFraktion[] = [];
    public weekDays: DayOfWeek[] = [];

    public gruppenWochenBelegungByGruppe: { [k in KinderOrtFraktionId]: GruppenWochenBelegung } = {};
    public zeitraumFelderByGruppe: { [k in KinderOrtFraktionId]: ZeitraumFeld[] } = {};
    public anwesenheitenByGruppe: { [k in KinderOrtFraktionId]: BelegungZeitenByDayOfWeek } = {};
    public bemerkungProGruppe: { [k in KinderOrtFraktionId]?: string } = {};
    public vertraglichePensenProGruppe: { [k in KinderOrtFraktionId]?: VertraglichesPensum[] } = {};
    public stundenbasierteTarifeOffer: Map<PlatzTyp, boolean> = new Map<PlatzTyp, boolean>();

    public constructor(
        public readonly zuweisungAb: moment.Moment,
        public readonly firstOfWeek: moment.Moment,
        public readonly aktuelleBelegung: Belegung | null,
        public readonly kinderOrtZuweisung: KinderOrtZuweisung,
    ) {
    }

    public getGruppenWithSelectedZeitraumFeld(): KinderOrtFraktionId[] {
        const belegteGruppenIds = Object.keys(this.zeitraumFelderByGruppe)
            .filter(gruppeId => this.zeitraumFelderByGruppe[gruppeId].some(feld => feld.selected));

        return DvbUtil.uniqueArray(belegteGruppenIds);
    }
}
