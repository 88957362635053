<div class="dvb-voreinstellungen-external-anmeldung-wochenplan">

    <h2 data-translate="EXTERNAL_ANMELDUNG.CONFIG.WOCHENPLAENE"></h2>
    <button ng-if="!vm.showInput" type="button" class="btn btn-link" ng-click="vm.showInput = true">+
        <span data-translate="EXTERNAL_ANMELDUNG.CONFIG.ADD_WOCHENPLAN"></span>
    </button>

    <script type="text/ng-template" id="wochenplanSelectTemplate.html">
        <a href="" ng-bind="match.label"></a>
    </script>

    <div ng-if="vm.showInput" class="input-group form-row dvb-search-entity">
        <input type="text" 7
               class="form-control"
               ng-model="vm.searchInput"
               ng-attr-placeholder="{{'EXTERNAL_ANMELDUNG.CONFIG.ADD_WOCHENPLAN' | translate}}"
               uib-tooltip="{{'EXTERNAL_ANMELDUNG.CONFIG.ADD_WOCHENPLAN' | translate}}"
               tooltip-placement="top-left"
               uib-typeahead="plan as plan.name for plan in vm.availableWochenplaene | filter:$viewValue"
               typeahead-on-select="vm.addWochenplan($item)"
               typeahead-template-url="wochenplanSelectTemplate.html"
               typeahead-min-length="0">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
    </div>
    <button ng-if="vm.showInput" type="button" class="btn btn-link small-abstand-top" ng-click="vm.showInput = false">
        <span data-translate="COMMON.ABBRECHEN"></span>
    </button>

    <div ng-repeat="plan in vm.wochenplaeneExternalAnmeldung track by plan.id" class="normal-abstand-top">
        <h3 ng-bind="plan.wochenplan.name"></h3>
        <label class="small-abstand-top" data-translate="EXTERNAL_ANMELDUNG.CONFIG.WOCHENPLAN_NAME"></label>
        <dvb-translatable-editable-label translation="plan.nameTranslation"
                                         on-submit="vm.onChange()">
        </dvb-translatable-editable-label>
        <label class="small-abstand-top" data-translate="EXTERNAL_ANMELDUNG.CONFIG.WOCHENPLAN_BEMERKUNG"></label>
        <dvb-translatable-editable-label translation="plan.bemerkungTranslation"
                                         on-submit="vm.onChange()">
        </dvb-translatable-editable-label>

        <div class="radio">
            <label>
                <input type="radio"
                       name="{{'displayType' + plan.wochenplan.id}}"
                       ng-value="'ZEITRAUM_NAMES'"
                       ng-model="plan.displayType"
                       ng-change="vm.onChange()">
                <span data-translate="EXTERNAL_ANMELDUNG.CONFIG.WOCHENPLAN_DISPLAY_TYPE.NAMES"></span>
            </label>
        </div>
        <div class="radio">
            <label>
                <input type="radio"
                       name="displayType"
                       ng-value="'ZEITRAUM_TIMES'"
                       ng-model="plan.displayType"
                       ng-change="vm.onChange()">
                <span data-translate="EXTERNAL_ANMELDUNG.CONFIG.WOCHENPLAN_DISPLAY_TYPE.TIMES"></span>
            </label>
        </div>

        <button type="button"
                class="btn btn-link small-abstand-top"
                data-translate="COMMON.LOESCHEN"
                ng-click="vm.remove(plan)">
        </button>
    </div>
</div>
