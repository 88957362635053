/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek} from '@dv/shared/code';
import type {KibonExchangeModulIntervall} from './KibonExchangeModul';

export class KibonExchangeModulAuswahl {

    public constructor(
        public modulId: string,
        public fremdId: string | null,
        public wochentag: DayOfWeek,
        public intervall: KibonExchangeModulIntervall,
    ) {
    }

    public static apiResponseTransformer(data: any): KibonExchangeModulAuswahl {
        return new KibonExchangeModulAuswahl(data.modulId, data.fremdId, data.wochentag, data.intervall);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            modulId: this.modulId,
            wochentag: this.wochentag,
            intervall: this.intervall,
        };
    }
}
