<h2 data-translate="PERSONAL.KONFIGURATION.PLURAL"></h2>

<p ng-if="vm.konfigurationen.length === 0"
   class="normal-abstand-bottom"
   data-translate="PERSONAL.KONFIGURATION.KEINE">
</p>

<div ng-repeat="konfiguration in vm.konfigurationen"
     ng-init="workingCopy = (konfiguration | copy)">

    <dvb-limited-entity-verlauf entity="konfiguration"
                                previous-entity="vm.konfigurationen[$index - 1]"
                                first="$first"
                                translation-root="'PERSONAL.KONFIGURATION'"
                                on-remove="vm.remove(konfiguration)"
                                on-submit="vm.save(workingCopy)"
                                on-revert="vm.revert(konfiguration)"
                                enable-edit-mode="vm.hasEditPrivileges"
                                enable-actions="vm.hasEditPrivileges">
        <entity-form>
            <dvb-personal-konfiguration-form entity="workingCopy" disable-gueltigkeit="true">
            </dvb-personal-konfiguration-form>
        </entity-form>
        <entity-heading>
            <dvb-limited-entity-heading entity="konfiguration">
                <entity-heading>
                    <dvb-personal-konfiguration-header konfiguration="konfiguration">
                    </dvb-personal-konfiguration-header>
                </entity-heading>
            </dvb-limited-entity-heading>
        </entity-heading>
        <entity-read-only>
            <dvb-limited-entity-heading entity="konfiguration">
                <entity-heading>
                    <dvb-personal-konfiguration-header konfiguration="konfiguration">
                    </dvb-personal-konfiguration-header>
                </entity-heading>
            </dvb-limited-entity-heading>
        </entity-read-only>
    </dvb-limited-entity-verlauf>
</div>

<dvb-limited-entity-assignment-controls ng-if="vm.hasEditPrivileges"
                                        entities="vm.konfigurationen"
                                        translation-root="'PERSONAL.KONFIGURATION'"
                                        on-save="vm.save(vm.newKonfiguration, context)"
                                        on-terminate="vm.terminate(endDate, mode)">
    <entity-form>
        <dvb-personal-konfiguration-form entity="vm.newKonfiguration">
        </dvb-personal-konfiguration-form>
    </entity-form>
</dvb-limited-entity-assignment-controls>
