/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {GranularKinderOrtPermission} from '@dv/kitadmin/models';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, DvbUtil, SearchResultEntry} from '@dv/shared/code';
import {GranularKinderOrtPermissionType} from '@dv/shared/roles';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {
    GranularKinderOrtPermissionService,
} from '../../../common/service/rest/benutzer/granularKinderOrtPermissionService';
import type {Benutzer} from '../../model/Benutzer';

type SelectablePermission = { text: string; type: GranularKinderOrtPermissionType };

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        benutzer: '<',
        permissions: '<',
        readOnly: '<',
    },
    template: require('./dvb-granular-kinder-ort-permissions.html'),
    controllerAs: 'vm',
};

class DvbGranularKinderOrtPermissions implements angular.IController {

    public static $inject: readonly string[] = ['granularKinderOrtPermissionService', '$state', '$translate'];
    public static readonly KINDERORT_NAMES_MAX_LENGTH = 56;

    public benutzer!: Persisted<Benutzer>;
    public permissions: Persisted<GranularKinderOrtPermission>[] = [];
    public readOnly: boolean = true;

    public newPermissionType: SelectablePermission | null = null;
    public newPermissionVisible: boolean = false;

    public availablePermissions: SelectablePermission[] = [];

    public constructor(
        private granularKinderOrtPermissionService: GranularKinderOrtPermissionService,
        private $state: StateService,
        private $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(): void {

        this.availablePermissions = Object.values(GranularKinderOrtPermissionType)
            .filter(type => this.permissions.filter(permission => permission.permission === type).length === 0)
            .map(type => {
                return {
                    text: this.$translate.instant(`BENUTZER.GRANULAR_PERMISSIONS.TYPE.${type}`),
                    type,
                };
            });
    }

    public addPermission(): void {
        this.newPermissionVisible = false;
        this.granularKinderOrtPermissionService.create(
            new GranularKinderOrtPermission(
                null,
                this.benutzer.id,
                [],
                checkPresent(this.newPermissionType).type))
            .then(() => this.$state.reload())
            .finally(() => {
                this.newPermissionType = null;
            });
    }

    public createSearchResults(permission: GranularKinderOrtPermission): SearchResultEntry[] {
        return permission.kinderOrtNamesById.map(entry => new SearchResultEntry('KITA', entry.id, entry.text));
    }

    public update(
        permission: GranularKinderOrtPermission,
        kinderOrte: SearchResultEntry[],
        context: AccordionContext,
    ): angular.IPromise<unknown> {

        const workingCopy = new GranularKinderOrtPermission(
            permission.id,
            permission.benutzerId,
            kinderOrte,
            permission.permission);

        return this.granularKinderOrtPermissionService.update(workingCopy)
            .then(() => this.$state.reload())
            .catch(() => context.cancelSubmission());
    }

    public delete(
        permission: Persisted<GranularKinderOrtPermission>,
        context: AccordionContext,
    ): angular.IPromise<unknown> {
        return this.granularKinderOrtPermissionService.delete(permission.id)
            .then(() => this.$state.reload())
            .catch(() => context.cancelSubmission());
    }

    public getKinderOrtNamesTruncated(permission: GranularKinderOrtPermission): string {
        return checkPresent(DvbUtil.truncate(this.getKinderOrtNames(permission),
            DvbGranularKinderOrtPermissions.KINDERORT_NAMES_MAX_LENGTH));
    }

    public getKinderOrtNamesTooltip(permission: GranularKinderOrtPermission): string {
        return this.getKinderOrtNames(permission);
    }

    private getKinderOrtNames(permission: GranularKinderOrtPermission): string {
        return permission.kinderOrtNamesById.map(k => k.text).join(', ');
    }
}

componentConfig.controller = DvbGranularKinderOrtPermissions;
angular.module('kitAdmin').component('dvbGranularKinderOrtPermissions', componentConfig);
