/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {COLOR_MODE} from '@dv/kitadmin/models';
import type {ColorMode} from '@dv/kitadmin/models';
import angular from 'angular';
import type {StyleVariableService} from '../../service/style-variable.service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        value: '<',
        colorMode: '<?',
        tooltip: '<?',
        tooltipTranslated: '<?',
        inverted: '<?',
        tooltipPlacement: '<?',
        hide: '<?',
    },
    template: require('./dvb-pensum-icon.html'),
    controllerAs: 'vm',
};

export class DvbPensumIcon implements angular.IController {
    public static $inject: readonly string[] = ['$element', '$translate', 'styleVariableService'];

    public value!: string;
    public tooltip: string | null = null;
    public inverted: boolean = false;
    public tooltipTranslated: boolean = false;
    public tooltipPlacement: string = 'top-left';
    public hide!: boolean;

    public color: string = '';
    public fontSize: string = '';

    private readonly colorMap: { [k in ColorMode]: string };

    public constructor(
        $element: JQLite,
        private $translate: angular.translate.ITranslateService,
        private styleVariableService: StyleVariableService,
    ) {
        this.colorMap = {
            normal: this.styleVariableService.getColorMainNormal(),
            bright: this.styleVariableService.getColorMainBright(),
            contrast: this.styleVariableService.getColorContrastNormal(),
        };
        // make css properties available for svg styling (color -> stroke, font-size -> width/height)
        this.color = $element.css('color');
        this.fontSize = $element.css('font-size');
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.colorMode?.currentValue) {
            this.color = this.colorMap[COLOR_MODE.check(onChangesObj.colorMode.currentValue)];
        }
    }

    public getTooltipText(): string | null {
        if (!this.tooltip || this.tooltipTranslated) {
            return this.tooltip;
        }

        return this.$translate.instant(this.tooltip);
    }
}

componentConfig.controller = DvbPensumIcon;
angular.module('kitAdmin').component('dvbPensumIcon', componentConfig);
