import type {CalendarGroup} from '../../../../../calendar/timeline/model/CalendarGroup';
import {AngestellteZuweisungen} from '../../../../../personal/model/AngestellteZuweisungen';
import type {FraktionZuweisungen} from '../../../../../personal/model/FraktionZuweisungen';

export function resourceMultipleGroups(
    groups: (CalendarGroup | FraktionZuweisungen)[],
): (CalendarGroup | FraktionZuweisungen)[] {
    return groups.map((group, groupIndex) => {
        const resources = group.resources.map(resource => {
            if (!(resource instanceof AngestellteZuweisungen)) {

                return resource;
            }

            // to exclude current group
            const otherGroups = groups.reduce<(CalendarGroup | FraktionZuweisungen)[]>((acc, g, gi) => {
                if (gi !== groupIndex) {
                    acc.push(g);
                }

                return acc;
            }, []);

            const result = otherGroups.find(g => g.resources.some(r => r.id === resource.id));

            if (result) {
                resource.inMultipleGroups = result.resources.some(r => r.id === resource.id);
            }

            return resource;
        });

        return {...group, id: group.id, getDisplayName: group.getDisplayName, resources};
    });
}
