/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AbstractControl, ValidationErrors} from '@angular/forms';
import type {FormGroupSpec} from '@dv/shared/angular';
import {DvbUtil} from '@dv/shared/code';
import type {TerminFormModel} from './termin-form-model';

export function terminFormSelectionValidator(control: AbstractControl): ValidationErrors | null {
    const {
        alleAngestellte,
        alleKinderOrte,
        selectedAngestellte,
        selectedKinderOrte,
    } = (control as FormGroupSpec<TerminFormModel>).value;

    if ((alleAngestellte ?? false)
        || (alleKinderOrte ?? false)
        || DvbUtil.isNotEmptyArray(selectedAngestellte)
        || DvbUtil.isNotEmptyArray(selectedKinderOrte)
    ) {
        return null;
    }

    return {
        selection: {
            alleAngestellte,
            alleKinderOrte,
            selectedAngestellte,
            selectedKinderOrte,
        },
    };
}
