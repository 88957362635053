<div class="dvb-anstellung-verlauf-item">
    <div class="row">
        <div class="col-md-4">
            <p>
                <span data-translate="PERSONAL.ANSTELLUNG.FROM"
                      data-translate-value-date="{{vm.anstellung.gueltigAb | amDateFormat: 'D.M.YYYY'}}">
                </span>
            </p>
        </div>
        <div class="col-md-4">
            <p ng-repeat="ausbildung in vm.anstellung.ausbildungen" ng-bind="ausbildung.name">
            </p>
        </div>
    </div>
    <div class="row" ng-repeat="standort in vm.anstellung.anstellungStandorte">
        <div class="col-md-4">
            <p>
                <i ng-if="standort.vertretungsperson"
                   class="custom-icon custom-icon-chess-knight"
                   uib-tooltip="{{'PERSONAL.ANSTELLUNG.VERTRETUNGSPERSON' | translate}}"
                   tooltip-placement="top-left">
                </i>
            </p>
        </div>

        <div class="col-md-4">
            <div ng-if="standort.kinderOrte.length > 0" class="small-abstand-bottom">
                <p ng-repeat="kinderOrt in standort.kinderOrte" ng-bind="kinderOrt.getDisplayName()"></p>
            </div>
            <p ng-if="standort.weeklyHours | isNumber"
               class="small-abstand-bottom"
               data-translate="PERSONAL.ANSTELLUNG.WEEKLY_HOURS_COUNT"
               data-translate-value-hours="{{standort.weeklyHours}}">
            </p>
            <p ng-if="standort.arbeitspensumProzent | isNumber"
               class="small-abstand-bottom"
               data-translate="PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT_COUNT"
               data-translate-value-prozent="{{standort.arbeitspensumProzent}}">
            </p>
        </div>

        <div class="col-md-4">
            <dvb-wochen-zeitplan ng-if="standort.dailyAnstellungen.length > 0"
                                 small="true"
                                 week-days="vm.weekDays"
                                 zeitraum-felder="vm.anstellungZeitraumFelder[standort.id]">
            </dvb-wochen-zeitplan>
            <p ng-if="standort.dailyAnstellungen.length === 0"
               data-translate="PERSONAL.ANSTELLUNG.NO_DAILY_ANSTELLUNG">
            </p>
        </div>
    </div>
</div>
