/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldung, ExternalAnmeldungKind, ExternalAnmeldungSetup} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        anmeldung: '<',
        kinder: '<',
        setup: '<',
        onAddChild: '&',
        onKinderOrteChanged: '&',
    },
    template: require('./dvb-external-anmeldung-kinder.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungKinder implements angular.IController {

    public static $inject: readonly string[] = [];

    public anmeldung!: ExternalAnmeldung;
    public kinder: ExternalAnmeldungKind[] = [];
    public setup?: ExternalAnmeldungSetup;
    public onAddChild!: FunctionType;
    public onKinderOrteChanged!: (params: { kind: ExternalAnmeldungKind }) => unknown;

    public removeChild(kind: ExternalAnmeldungKind): void {
        DvbUtil.removeFromArray(kind, this.kinder);
    }

    public kinderOrteChanged(kind: ExternalAnmeldungKind): void {
        this.onKinderOrteChanged({kind});
    }
}

componentConfig.controller = DvbExternalAnmeldungKinder;
angular.module('kitAdmin').component('dvbExternalAnmeldungKinder', componentConfig);
