<ul class="list-inline normal-abstand-top">
    <li>
        <dvb-loading-button type="submit" is-loading="vm.isLoading">
            <span data-translate="{{vm.submitLabel || 'COMMON.SPEICHERN'}}"></span>
        </dvb-loading-button>
    </li>
    <li>
        <button type="button"
                class="btn btn-link"
                ng-click="vm.onCancel()"
                data-translate="{{vm.cancelLabel || 'COMMON.ABBRECHEN'}}"
                ng-disabled="vm.isLoading">
        </button>
    </li>
</ul>
