/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil, END_OF_TIME} from '@dv/shared/code';
import type moment from 'moment';
import {BetreuungsSchluessel} from './BetreuungsSchluessel';

export class KinderOrtBetreuungsSchluessel implements IPersistable, IRestModel, ILimited, IDisplayable {

    public constructor(
        public id: string | null = null,
        public kinderOrtId: string = '',
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment = END_OF_TIME,
        public betreuungsSchluessel: BetreuungsSchluessel | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KinderOrtBetreuungsSchluessel {
        const gueltigBis = DvbRestUtil.localDateToMoment(data.gueltigBis);
        const betreuungsSchluessel = data.schluessel ?
            BetreuungsSchluessel.apiResponseTransformer(data.schluessel) :
            null;

        return new KinderOrtBetreuungsSchluessel(
            data.id,
            data.kinderOrtId,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            gueltigBis ?? END_OF_TIME,
            betreuungsSchluessel,
        );
    }

    public getDisplayName(): string {
        return this.betreuungsSchluessel?.name ?? '';
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            kinderOrtId: this.kinderOrtId,
            betreuungsSchluesselId: this.betreuungsSchluessel?.id ?? null,
        };
    }
}
