/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {stringUnion} from '@dv/shared/code';

export const STYLE_VARS = stringUnion(
    'color-main-normal',
    'color-main-bright',
    'color-guide-hover',
    'color-guide-bright',
    'color-contrast-hover',
    'color-contrast-normal',
    'color-contrast-alt-normal',
    'text-color',
);

export type ExposedStyleVariable = typeof STYLE_VARS.type;

@Injectable({
    providedIn: 'root',
})
export class StyleVariableService {

    private styles?: { [k in ExposedStyleVariable]: string };

    public getColorContrast(): string {
        return this.getCssVar('color-contrast-hover');
    }

    public getColorContrastNormal(): string {
        return this.getCssVar('color-contrast-normal');
    }

    public getColorContrastAltNormal(): string {
        return this.getCssVar('color-contrast-alt-normal');
    }

    public getColorGuide(): string {
        return this.getCssVar('color-guide-hover');
    }

    public getColorGuideBright(): string {
        return this.getCssVar('color-guide-bright');
    }

    public getColorMainNormal(): string {
        return this.getCssVar('color-main-normal');
    }

    public getColorMainBright(): string {
        return this.getCssVar('color-main-bright');
    }

    public getColorText(): string {
        return this.getCssVar('text-color');
    }

    private getCssVar(varName: ExposedStyleVariable): string {
        if (!this.styles) {
            this.extractCssVars();
        }

        return this.styles![varName];
    }

    private extractCssVars(): void {
        const styles = window.getComputedStyle(window.document.body);
        const extractedStyles: { [k in ExposedStyleVariable]: string } = {} as any;
        STYLE_VARS.values.forEach(value => {
            extractedStyles[value] = styles.getPropertyValue(`--ka-${value}`);
        });
        this.styles = extractedStyles;
    }
}
