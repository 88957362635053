<div class="dvb-external-anmeldung-kontakte">

    <div class="form-section normal-abstand-bottom" ng-repeat="kontakt in vm.kontakte">

        <div class="row" ng-click="kontakt.collapsed = !kontakt.collapsed">
            <div class="col-md-12">
                <a href="" class="pull-left smaller-abstand-right">
                    <i class="fa"
                       ng-class="{'fa-chevron-right': kontakt.collapsed, 'fa-chevron-down': !kontakt.collapsed}">
                    </i>
                </a>
                <h2 class="pull-left" ng-bind="kontakt.getDisplayName()"></h2>
            </div>
        </div>

        <div uib-collapse="kontakt.collapsed">
            <dvb-external-anmeldung-kontakt-form kontaktperson="kontakt"
                                                 on-remove-kontakt="vm.onRemoveKontakt({kontakt})">
            </dvb-external-anmeldung-kontakt-form>
        </div>
    </div>

    <a href="" ng-click="vm.onAddKontakt()">
        + <span data-translate="EXTERNAL_ANMELDUNG.ADD_KONTAKT"></span>
    </a>
</div>
