<div class="dvb-betreuungsperson-profil">
    <h2 data-translate="BETREUUNGS_PERSON.STAMMDATEN"></h2>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isValidName(param)"
                                        maxlength="255"
                                        ng-change="vm.save()"
                                        ng-model="vm.betreuungsPerson.familienName"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                        dvb-auth
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label is-valid="vm.isValidName(param)"
                                        maxlength="255"
                                        ng-change="vm.save()"
                                        ng-model="vm.betreuungsPerson.vorName"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.VORNAME' | translate}}"
                                        dvb-auth
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-datepicker-text-field
                        click-to-edit="true"
                        ng-model="vm.betreuungsPerson.geburtsTag"
                        on-submit="vm.save()"
                        on-unauthorised-disable
                        ng-attr-placeholder="{{'COMMON.GEBURTSTAG' | translate}}"
                        dvb-auth
                        require-permission="kita:manage:general">
                    </dvb-datepicker-text-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label maxlength="100"
                                        ng-change="vm.save()"
                                        ng-model="vm.betreuungsPerson.telefon"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.TELEFON' | translate}}"
                                        dvb-auth
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
                <div class="col-md-6">
                    <dvb-editable-label maxlength="100"
                                        ng-change="vm.save()"
                                        ng-model="vm.betreuungsPerson.mobile"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.MOBILE_NR' | translate}}"
                                        dvb-auth
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label maxlength="100"
                                        ng-change="vm.save()"
                                        ng-model="vm.betreuungsPerson.geschaeft"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.GESCHAEFT_NR' | translate}}"
                                        dvb-auth
                                        require-permission="kita:manage:general">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-editable-label maxlength="255"
                                        ng-change="vm.save()"
                                        ng-model="vm.betreuungsPerson.email"
                                        on-unauthorised-disable
                                        ng-attr-placeholder="{{'COMMON.E-MAIL' | translate}}"
                                        dvb-auth
                                        require-permission="kita:manage:general"
                                        is-email="true"
                                        type="email">
                    </dvb-editable-label>
                </div>
            </div>
            <div class="form-section-minus-normal-abstand">
                <div class="row form-row">
                    <div class="col-md-4">
                        <div class="radio"
                             ng-repeat="geschlecht in vm.geschlechter">
                            <label>
                                <input name="geschlecht"
                                       ng-change="vm.save()"
                                       ng-model="vm.betreuungsPerson.geschlecht"
                                       on-unauthorised-disable
                                       dvb-auth
                                       require-permission="kita:manage:general"
                                       type="radio"
                                       value="{{geschlecht}}">
                                <span data-translate="{{'COMMON.' + geschlecht }}"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <dvb-iso-locale dvb-click2edit-iso-locale
                                    ng-model="vm.betreuungsPerson.nationalitaet"
                                    on-submit="vm.betreuungsPerson.nationalitaet = isoObject; vm.save()"
                                    on-unauthorised-disable
                                    ng-attr-placeholder="{{'COMMON.NATIONALITAET' | translate}}"
                                    dvb-auth
                                    require-permission="kita:manage:general"
                                    type="COUNTRY">
                    </dvb-iso-locale>
                </div>
                <div class="col-md-6">
                    <dvb-iso-locale dvb-click2edit-iso-locale
                                    ng-model="vm.betreuungsPerson.language"
                                    on-submit="vm.betreuungsperson.language = isoObject; vm.save()"
                                    on-unauthorised-disable
                                    ng-attr-placeholder="{{'COMMON.KORRESPONDENZSPRACHE' | translate}}"
                                    dvb-auth
                                    require-permission="kita:manage:general"
                                    type="LANGUAGE">
                    </dvb-iso-locale>
                </div>
            </div>
            <dvb-custom-field-values custom-field-values="vm.betreuungsPerson.customFieldValues">
            </dvb-custom-field-values>
            <div class="row big-abstand-top">
                <div class="form-section-normal-abstand" dvb-auth require-permission="kita:control:general">
                    <h2 data-translate="COMMON.BEMERKUNG"></h2>
                    <dvb-editable-text-area maxlength="5000"
                                            ng-model="vm.betreuungsPerson.bemerkung"
                                            on-submit="vm.save()"
                                            on-unauthorised-disable
                                            ng-attr-placeholder="{{'COMMON.BEMERKUNG' | translate}}"
                                            dvb-auth
                                            require-permission="kita:manage:general">
                    </dvb-editable-text-area>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-md-offset-1">
            <div class="row big-abstand-bottom">
                <div class="col-md-12">
                    <h2 data-translate="COMMON.TAGES_ELTERN.SINGULAR"></h2>
                    <a ng-bind="vm.betreuungsPerson.kita.getDisplayName()"
                       ui-sref="base.kinderort.profil({id: vm.betreuungsPerson.kita.id})">
                    </a>
                </div>
            </div>
            <div class="row big-abstand-bottom" ng-if="vm.betreuungsPerson.angestellteId">
                <div class="col-md-12">
                    <h2 data-translate="PERSONAL.ANGESTELLTE.SINGULAR"></h2>
                    <a ng-bind="vm.angestellteDisplay"
                       ui-sref="{{vm.anstellungStates.ANGESTELLTE_PROFIL_STATE.name + '({id: ' + vm.betreuungsPerson.angestellteId + '})'}}">
                    </a>
                </div>
            </div>
            <dvb-betreuungsperson-kinder-list betreuungs-person="vm.betreuungsPerson"
                                              dvb-auth
                                              require-permission="kita:manage:general">
            </dvb-betreuungsperson-kinder-list>
        </div>
    </div>
</div>
