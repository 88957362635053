/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import type {SupportedLanguage} from '@dv/shared/code';
import type {MandantService} from '../../service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {ngModelCtrl: 'ngModel'},
    bindings: {
        ngModel: '<',
        supportedLanguages: '<?',
    },
    template: require('./dvb-translatable-language.html'),
    controllerAs: 'vm',
};

export class DvbTranslatableLanguage implements angular.IController {
    public static $inject: readonly string[] = ['mandantService'];

    public ngModelCtrl!: angular.INgModelController;

    public supportedLanguages: SupportedLanguage[] = [];

    public constructor(private readonly mandantService: MandantService) {
    }

    public switchLanguage(lang: SupportedLanguage): void {
        this.ngModelCtrl.$setViewValue(lang);
    }

    public $onInit(): void {
        if (this.supportedLanguages.length !== 0) {
            return;
        }

        this.mandantService.getMandantLanguges().then(languages => {
            this.supportedLanguages = languages;
        });
    }
}

componentConfig.controller = DvbTranslatableLanguage;
angular.module('kitAdmin').component('dvbTranslatableLanguage', componentConfig);
