/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {Gueltigkeit, ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {ERR_VALIDATION, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

export type PersonalplanungReportParams = {
    kinderOrtId?: string;
    fraktionId?: string;
    reportTemplate: ReportTemplate | null;
    gueltigAb: moment.Moment;
    gueltigBis: moment.Moment;
    mitTermine: boolean;
    mitKinderOrtSchluessel: boolean;
    mitTagesinfos: boolean;
    mitPausen: boolean;
};

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        kinderOrt: '<?',
        gueltigAb: '<?',
        gueltigBis: '<?',
        defaultGueltigkeit: '<?',
    },
    template: require('./dvb-personalplanung-report.html'),
    controllerAs: 'vm',
};

export class DvbPersonalplanungReport implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public kinderOrt?: SearchResultEntry;
    public gueltigAb?: moment.Moment;
    public gueltigBis?: moment.Moment;
    public defaultGueltigkeit?: Gueltigkeit;
    public monthly: boolean = false;

    public readonly personalPlanungReportForm?: angular.IFormController;
    public defaultGueltigAb: moment.Moment = DvbDateUtil.startOfWeek();
    public defaultGueltigBis: moment.Moment = DvbDateUtil.today().add(1, 'years').add(-1, 'weeks').endOf('isoWeek');
    public gruppeSearchResultEntry?: SearchResultEntry;
    public mitTermine: boolean = true;
    public mitKinderOrtSchluessel: boolean = true;
    public mitTagesinfos: boolean = true;
    public mitPausen: boolean = false;

    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.defaultGueltigkeit) {
            this.defaultGueltigAb = this.defaultGueltigkeit!.gueltigAb!;
            this.defaultGueltigBis = this.defaultGueltigkeit!.gueltigBis!;
        }

        this.monthly = this.reportState === ReportState.PERSONAL.PERSONALPLANUNG_MONTHLY;
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateState(): void {
        this.reportService.updateStateParams({
            gueltigAb: this.gueltigAb,
            gueltigBis: this.gueltigBis,
        });
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const params = this.buildParams();

        this.errorService.clearAll();
        const isValid = this.personalPlanungReportForm!.$valid
            && (isPresent(params.fraktionId) || isPresent(params.kinderOrtId));
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_KITA_UND_GRUPPE');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        switch (this.reportState) {
            case ReportState.PERSONAL.PERSONALPLANUNG:
                return this.reportService.getTempBlobForPersonalReport(params);
            case ReportState.PERSONAL.PERSONALPLANUNG_MONTHLY:
                return this.reportService.getTempBlobForPersonalReportMonthly(params);
            default:
                throw new Error(`Report download not implemented: ${JSON.stringify(this.reportState)}`);
        }

    }

    private buildParams(): PersonalplanungReportParams {
        return {
            kinderOrtId: this.kinderOrt?.id,
            fraktionId: this.gruppeSearchResultEntry?.id,
            reportTemplate: this.reportTemplate,
            gueltigAb: this.gueltigAb ?? this.defaultGueltigAb,
            gueltigBis: this.gueltigBis ?? this.defaultGueltigBis,
            mitTermine: this.mitTermine,
            mitKinderOrtSchluessel: this.mitKinderOrtSchluessel,
            mitTagesinfos: this.mitTagesinfos,
            mitPausen: this.mitPausen,
        };
    }
}

componentConfig.controller = DvbPersonalplanungReport;
angular.module('kitAdmin').component('dvbPersonalplanungReport', componentConfig);
