/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KindergartenBelegung} from '@dv/shared/code';
import {KGB} from '@dv/shared/code';
import angular from 'angular';
import type {KindergartenBelegungService} from '../../service/kindergartenBelegungService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kindergartenBelegung: '@',
    },
    template: require('./dvb-kindergarten-icon.html'),
    controllerAs: 'vm',
};

export class DvbKindergartenIcon implements angular.IController, angular.IOnInit {

    public static $inject: readonly string[] = [
        '$translate',
        'kindergartenBelegungService',
    ];

    public kindergartenBelegung!: KindergartenBelegung;

    public kgType: string = '';
    public kgLocation: string = '';

    /**
     * @property {string.<KINDERGARTEN_BELEGUNG>} kindergartenBelegung
     * @property {string} kgType
     * @property {string} kgLocation
     */

    public constructor(
        private readonly $translate: angular.translate.ITranslateService,
        private readonly kindergartenBelegungService: KindergartenBelegungService,
    ) {
    }

    public $onInit(): void {
        this.kgType = this.getType(this.kindergartenBelegung);
        const location = this.getLocation(this.kindergartenBelegung);
        this.kgLocation = location ? this.$translate.instant(location) : '';
    }

    private getType(kb: KindergartenBelegung): string {
        if (KGB.isKG1(kb)) {
            return '1';
        }
        if (KGB.isKG2(kb)) {
            return '2';
        }
        if (KGB.isSchule(kb)) {
            return '3';
        }

        return '';
    }

    private getLocation(kb: KindergartenBelegung): string {
        if (this.kindergartenBelegungService.isInternal(kb)) {
            return 'KIND.ZUWEISUNG.KINDERGARTEN.INTERN';
        }
        if (this.kindergartenBelegungService.isExternal(kb)) {
            return 'KIND.ZUWEISUNG.KINDERGARTEN.EXTERN';
        }

        return '';
    }
}

componentConfig.controller = DvbKindergartenIcon;
angular.module('kitAdmin').component('dvbKindergartenIcon', componentConfig);
