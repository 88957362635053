<div class="dvb-organisation-main">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">

                <h1 data-translate="ORGANISATION.ORGANISATION"
                    uib-tooltip="{{'ORGANISATION.ORGANISATION' | translate}}"
                    tooltip-placement="top-left">
                </h1>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active">
                        <a ui-sref="base.organisation.mandant({currentPage: 1})"
                           data-translate="COMMON.MANDANT.SINGULAR">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{vm.organisationStates.BENUTZER_STATE.data.permission}}">
                        <a ui-sref="{{vm.organisationStates.BENUTZER_STATE.name + '({currentPage: 1})'}}"
                           data-translate="COMMON.BENUTZER.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{vm.organisationStates.KINDER_ORTE_STATE.data.permission}}">
                        <a ui-sref="{{vm.organisationStates.KINDER_ORTE_STATE.name + '({currentPage: 1})'}}"
                           data-translate="COMMON.KINDERORT.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active" dvb-auth require-permission="kita:view:general">
                        <a ui-sref="{{vm.organisationStates.PLATZARTEN_STATE.name + '({currentPage: 1})'}}"
                           data-translate="COMMON.PLATZART.PLURAL">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
