/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import {BetreuungsGutschein} from '@dv/kitadmin/models';
import type {FunctionType, Persisted, SearchResultEntry} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<?',
        originalEntry: '<?',
        onUpdate: '&',
        onCancel: '&',
        inline: '<?',
    },
    template: require('./dvb-kind-tarife-betreuungsgutscheine-form.html'),
    controllerAs: 'vm',
};

export class DvbKindTarifeBetreuungsgutscheineForm implements angular.IController {

    public static $inject: readonly string[] = ['errorService'];

    public kita?: Persisted<KinderOrt>;
    public originalEntry: BetreuungsGutschein = new BetreuungsGutschein();
    public onUpdate!: (data: { betreuungsGutschein: BetreuungsGutschein }) => unknown;
    public onCancel!: FunctionType;
    public inline?: boolean;

    public betreuungsGutschein: BetreuungsGutschein = new BetreuungsGutschein();
    public kitaSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.betreuungsGutschein = angular.copy(this.originalEntry);
        this.kitaSearchResultEntry = this.kita ? this.kita.toSearchResultEntry() : null;
    }

    // eslint-disable-next-line complexity
    public onSubmit(form: angular.IFormController): void {
        const isValidVerguenstigterBetrag = form.verguenstigterBetrag?.$error &&
            !form.verguenstigterBetrag.$error.required &&
            !form.verguenstigterBetrag.$error.currencyValue &&
            !form.verguenstigterBetrag.$error.dvbMinExclusive;
        this.errorService.handleValidationError(isValidVerguenstigterBetrag,
            'TARIF.ERRORS.ERR_BETREUUNGSGUTSCHEIN_VERGUENSTIGTER_BETRAG');

        const isValidVollkosten = form.vollkosten?.$error &&
            !form.vollkosten.$error.required &&
            !form.vollkosten.$error.currencyValue &&
            !form.vollkosten.$error.dvbMinExclusive;
        this.errorService.handleValidationError(isValidVollkosten,
            'TARIF.ERRORS.ERR_BETREUUNGSGUTSCHEIN_VOLLKOSTEN');

        const isValidBgPensum = form.bgPensumProzent?.$error &&
            !form.bgPensumProzent.$error.betreuungsGutscheinBgPensum;
        this.errorService.handleValidationError(isValidBgPensum,
            'TARIF.ERRORS.ERR_BETREUUNGSGUTSCHEIN_BG_PENSUM_PROZENT');

        this.errorService.handleValidationError(this.isValidGueltigkeit(form), 'ERRORS.ERR_INVALID_DATE');
        const isValidDates = moment(form.gueltigAb.$modelValue)
            .isSameOrBefore(moment(form.gueltigBis.$modelValue));
        this.errorService.handleValidationError(isValidDates, 'ERRORS.ERR_INVALID_DATES');

        const isValidKita = this.betreuungsGutschein.kitaId !== null;
        this.errorService.handleValidationError(isValidKita, 'TARIF.ERRORS.ERR_BETREUUNGSGUTSCHEIN_KITA');

        const isValidWerte = form.vollkosten >= form.verguenstigterBetrag;
        this.errorService.handleValidationError(isValidWerte, 'TARIF.ERRORS.ERR_BETREUUNGSGUTSCHEIN_WERTE');

        const isGutscheinLargerVollkosten = (this.betreuungsGutschein.vollkosten ?? 0)
            < (this.betreuungsGutschein.verguenstigterBetrag ?? 0);
        this.errorService.handleValidationError(!isGutscheinLargerVollkosten,
            'TARIF.ERRORS.ERR_BETREUUNGSGUTSCHEIN_GUTSCHEIN_LARGER_VOLLKOSTEN');

        if (!form.$valid || !isValidDates || !isValidKita || !isValidWerte || isGutscheinLargerVollkosten) {
            return;
        }

        if (angular.equals(this.betreuungsGutschein, this.originalEntry)) {
            this.onCancel();

            return;
        }

        if (TypeUtil.isFunction(this.onUpdate)) {
            this.onUpdate({betreuungsGutschein: this.betreuungsGutschein});
        }
    }

    public cancel(): void {
        this.errorService.clearAll();
        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }

    public updateKita(): void {
        this.betreuungsGutschein.kitaId = this.kitaSearchResultEntry ? this.kitaSearchResultEntry.id : null;
    }

    private isValidGueltigkeit(form: angular.IFormController): boolean {
        const isValidGueltigAb = form.gueltigAb?.$valid;
        const isValidGueltigBis = form.gueltigBis?.$valid;

        return isValidGueltigAb && isValidGueltigBis;
    }
}

componentConfig.controller = DvbKindTarifeBetreuungsgutscheineForm;
angular.module('kitAdmin').component('dvbKindTarifeBetreuungsgutscheineForm', componentConfig);
