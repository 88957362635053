<div ng-repeat="modulPreise in vm.preise"
     ng-init="defaultPreise = vm.mandant.defaultPreise.get(modulPreise.modul)">
    <div class="checkbox">
        <label>
            <input ng-change="vm.update(modulPreise)"
                   ng-disabled="vm.isDisabled || modulPreise.modul === 'BASIC'"
                   ng-model="vm.modules[modulPreise.modul]"
                   type="checkbox">
            <span data-translate="{{'MANDANT.MODULE.' + modulPreise.modul}}"></span>
        </label>
    </div>
    <div class="row" require-condition="vm.modules[modulPreise.modul]" dvb-auth require-role="MANDANT_ADMIN">
        <div class="col-xs-12">
            <input class="form-control"
                   dvb-currency-value
                   ng-change="vm.update(modulPreise)"
                   ng-disabled="vm.isDisabled"
                   ng-model="modulPreise.preisKita"
                   ng-model-options="{ updateOn: 'blur' }"
                   ng-attr-placeholder="{{'COMMON.BETRAG_VALUE' | translate: {betrag: defaultPreise.preisKita} }}"
                   tooltip-placement="top-left"
                   type="number"
                   uib-tooltip="{{'MANDANT.PREISE.KITA' | translate}}">
        </div>
        <div class="col-xs-12">
            <input class="form-control"
                   dvb-currency-value
                   ng-change="vm.update(modulPreise)"
                   ng-disabled="vm.isDisabled"
                   ng-model="modulPreise.preisBetreuungsPerson"
                   ng-model-options="{ updateOn: 'blur' }"
                   ng-attr-placeholder="{{'COMMON.BETRAG_VALUE' | translate: {betrag: defaultPreise.preisBetreuungsPerson} }}"
                   tooltip-placement="top-left"
                   type="number"
                   uib-tooltip="{{'MANDANT.PREISE.BETREUUNGS_PERSON' | translate}}">
        </div>
    </div>
</div>
