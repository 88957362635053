/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges, SimpleChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IconComponent} from '@dv/shared/angular';
import {TranslateModule} from '@ngx-translate/core';
import {CollapsiblePanelComponent} from '../../../../common/component/collapsible-panel/collapsible-panel.component';
import {AngestellteKorrespondenz} from '../../../../communication/models/AngestellteKorrespondenz';

@Component({
    selector: 'dv-angestellte-korrespondenz-timeline-details',
    standalone: true,
    imports: [TranslateModule, IconComponent, CollapsiblePanelComponent],
    templateUrl: './angestellte-korrespondenz-timeline-details.component.html',
    styleUrls: ['./angestellte-korrespondenz-timeline-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AngestellteKorrespondenzTimelineDetailsComponent implements OnChanges {
    @Input({required: true}) public korrespondenz!: AngestellteKorrespondenz;

    public showEmailContentPlainText: boolean = false;
    public showEmailContentHTML: boolean = false;

    public ngOnChanges(changes: SimpleChanges): void {
        const entry = changes.korrespondenz?.currentValue;
        if (!entry) {
            return;
        }

        this.showEmailContentPlainText = !entry.emailContentHTML && entry.emailContentPlainText;
        this.showEmailContentHTML = entry.emailContentHTML;
    }
}
