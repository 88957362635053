/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {FunctionType} from '@dv/shared/code';
import ClickEvent = JQuery.ClickEvent;
import FocusEvent = JQuery.FocusEvent;

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        label: '<',
        options: '<',
        onUpdate: '&',
        onEdit: '&?',
        onCancel: '&?',
    },
    template: require('./dvb-editable-select.html'),
    controllerAs: 'vm',
};

/* eslint-disable @typescript-eslint/no-magic-numbers */
export class DvbEditableSelect<T> implements angular.IController {
    public static $inject: readonly string[] = [
        '$attrs',
        '$element',
        '$scope',
        '$document',
        '$timeout',
    ];

    public label!: string;
    public options: T[] = [];
    public onUpdate!: (val: { model: T | null }) => void;
    public onEdit?: FunctionType;
    public onCancel?: FunctionType;

    public editMode: boolean = false;
    public isDisabled: boolean = false;

    public workingCopy?: T;

    public constructor(
        private readonly $attrs: angular.IAttributes,
        private readonly $element: angular.IAugmentedJQuery,
        private readonly $scope: angular.IScope,
        private readonly $document: angular.IDocumentService,
        private readonly $timeout: angular.ITimeoutService,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });

        this.$element.on('keydown', 'input', event => {
            // Reset on ESC, Tab or accesskey shortcut
            if (event.key === 'Escape' || (event.altKey && event.shiftKey)) {
                this.displayLabel();
            }
        });
    }

    public $onDestroy(): void {
        // remove event handler
        this.removeEventListeners();
    }

    public edit(): void {
        if (this.isDisabled || angular.element('.dvb-click2edit-open').length > 0) {
            return;
        }

        this.editMode = true;
        this.$element.addClass('dvb-click2edit-open');

        // add the listener in the next cycle, otherwise it will be triggered immediately and thus hide the
        // input directly
        this.$timeout(() => {
            // add click handler
            this.$document.on('click', event => this.clickHandler(event));
        });

        if (TypeUtil.isFunction(this.onEdit)) {
            this.onEdit();
        }
    }

    public cancelEdit(event: KeyboardEvent): void {
        if (event.key === 'Escape' && TypeUtil.isFunction(this.onCancel)) {
            // Reset on ESC
            this.displayLabel();

            this.onCancel();
        } else if (event.key === 'Enter') {
            // Trigger Submit on Enter
            this.updateModel(this.workingCopy);
        }
    }

    public onSelect(_$item: T, $model: T): void {
        this.updateModel($model);
    }

    public clear(): void {
        this.updateModel(null);
    }

    public onFocus(e: FocusEvent): void {
        // Wenn man in das Textfeld klick gleicht den ganzen Text auswaehlen
        e.target.select();
    }

    private updateModel(model: T | null | undefined): void {
        if (model === undefined) {
            // model is invalid -> don't update
            this.$scope.$evalAsync(() => {
                const inputElement = this.$element.find('input');
                this.$element.addClass('has-error');
                inputElement.trigger('focus');
                inputElement.trigger('select');
            });

            return;
        }

        this.workingCopy = undefined;
        this.displayLabel();
        if (TypeUtil.isFunction(this.onUpdate)) {
            this.onUpdate({model});
        }
    }

    private displayLabel(): void {
        this.$timeout(() => {
            this.editMode = false;
            this.removeEventListeners();
            this.$element.removeClass('dvb-click2edit-open');
            this.$element.removeClass('has-error');
        });
    }

    private removeEventListeners(): void {
        // remove event handler
        this.$document.off('click');
    }

    private clickHandler(event: ClickEvent): void {
        const elem = this.$element.get(0);
        if (!elem || elem.contains(event.target)) {
            // target is descendant, don't do anything
            return;
        }

        // target is not descendant, thus the user defocused the component -> hide it
        this.updateModel(this.workingCopy);
    }
}

componentConfig.controller = DvbEditableSelect;
angular.module('kitAdmin').component('dvbEditableSelect', componentConfig);
