/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {IRechnungsKonfiguration} from '@dv/kitadmin/models';
import {RechnungsKonfiguration, RechnungsKonfigurationStatus} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AccordionContext} from '@dv/kitadmin/ui/ajs';
import type {KontoService} from '@dv/shared/backend/api/konto.service';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {finalize, from, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {MandantService} from '../../../../common/service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfigurationen: '<',
    },
    template: require('./dvb-rechnungs-konfigurationen.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsKonfigurationen implements angular.IController {

    public static $inject: readonly string[] = ['mandantService', 'errorService', 'dialogService', 'kontoService'];

    public konfigurationen: RechnungsKonfiguration<IRechnungsKonfiguration>[] = [];

    public showNewForm: boolean = false;
    public isLoading: boolean = false;
    public konfiguration?: RechnungsKonfiguration<IRechnungsKonfiguration>;
    public konfigurationenTypen: typeof RechnungsKonfigurationType = RechnungsKonfigurationType;
    public statusTypen: typeof RechnungsKonfigurationStatus = RechnungsKonfiguration.STATUS;
    public konten: JaxKonto[] = [];

    public constructor(
        private readonly mandantService: MandantService,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly kontoService: KontoService,
    ) {
    }

    public $onInit(): void {
        this.kontoService.getAll$().subscribe({
            next: data => {
                this.konten = data.items;
            },
        });
    }

    public onUpdate<T extends IRechnungsKonfiguration>(
        workingCopyKonfiguration: RechnungsKonfiguration<T>,
        konfiguration: RechnungsKonfiguration<T>,
        context: AccordionContext,
        form: angular.IFormController,
    ): void {
        if (!this.isValidRechnungsKonfiguration(form, workingCopyKonfiguration)) {
            context.cancelSubmission();

            return;
        }

        if (konfiguration.status !== RechnungsKonfigurationStatus.DISABLED &&
            workingCopyKonfiguration.status === RechnungsKonfigurationStatus.DISABLED) {

            this.dialogService.openConfirmDialog({
                title: 'MANDANT.CONFIRM_DEACTIVATE_RECHNUNGS_KONFIGURATION',
                confirm: () => this.updateRechnungsKonfiguration$(workingCopyKonfiguration, konfiguration, context),
                cancel: () => context.cancelSubmission(),
            });

            return;
        }

        this.updateRechnungsKonfiguration$(workingCopyKonfiguration, konfiguration, context).subscribe();
    }

    // noinspection JSMethodCanBeStatic
    public onCancelUpdate<T extends IRechnungsKonfiguration>(
        workingCopyKonfiguration: RechnungsKonfiguration<T>,
        konfiguration: RechnungsKonfiguration<T>,
    ): void {
        angular.extend(workingCopyKonfiguration, konfiguration);
    }

    public onDelete<T extends IRechnungsKonfiguration>(konfiguration: RechnungsKonfiguration<T>):
        angular.IPromise<any> {

        return this.mandantService.deleteRechnungsKonfiguration(checkPresent(konfiguration.id)).then(() => {
            DvbUtil.removeFromArray(konfiguration, this.konfigurationen);
        });
    }

    public neueKonfiguration(konfigurationType: RechnungsKonfigurationType): void {
        this.konfiguration = new RechnungsKonfiguration();
        this.konfiguration.dtype = konfigurationType;
        this.showNewForm = true;
    }

    public onCreate(form: angular.IFormController): void {
        const rechnungsKonfiguration = this.konfiguration;
        if (!this.isValidRechnungsKonfiguration(form, rechnungsKonfiguration)) {
            return;
        }

        this.isLoading = true;

        this.mandantService.createRechnungsKonfiguration(rechnungsKonfiguration)
            .then(response => {
                if (rechnungsKonfiguration.isSmallInvoiceRechnungsKonfiguration()) {
                    // clear, so it's not visible anymore
                    checkPresent(rechnungsKonfiguration.konfiguration).token = null;
                }
                rechnungsKonfiguration.id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);

                this.konfigurationen.push(rechnungsKonfiguration);
                this.showNewForm = false;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private updateRechnungsKonfiguration$<T extends IRechnungsKonfiguration>(
        workingCopyKonfiguration: RechnungsKonfiguration<T>,
        konfiguration: RechnungsKonfiguration<T>,
        context: AccordionContext,
    ): Observable<any> {
        return from(this.mandantService.updateRechnungsKonfiguration(workingCopyKonfiguration)).pipe(
            take(1),
            tap(() => angular.extend(konfiguration, workingCopyKonfiguration)),
            tap(() => context.completeSubmission()),
            finalize(() => context.cancelSubmission()));
    }

    private isValidRechnungsKonfiguration(
        form: angular.IFormController,
        konfiguration?: RechnungsKonfiguration<IRechnungsKonfiguration>,
    ): konfiguration is RechnungsKonfiguration<IRechnungsKonfiguration> {

        const valid = form.$valid && !!konfiguration && konfiguration.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!konfiguration) {
            return false;
        }

        if (konfiguration.isDvbRechnungsKonfiguration()) {
            const dvbValid = checkPresent(konfiguration.konfiguration).isValid();
            this.errorService.handleValidationError(dvbValid, 'ERRORS.ERR_INCOMPLETE_FORM');

            return valid && dvbValid;
        }

        if (konfiguration.isSmallInvoiceRechnungsKonfiguration()) {
            const siKonfiguration = checkPresent(konfiguration.konfiguration);

            if (DvbUtil.isEmptyString(siKonfiguration.token)) {
                siKonfiguration.token = null;
            }

            const smallInvoiceValid = siKonfiguration.isValid(false);
            this.errorService.handleValidationError(smallInvoiceValid, 'ERRORS.ERR_SMALLINVOICE_FORM');

            return valid && smallInvoiceValid;
        }

        throw new Error(`Konfiguration Type not implementend: ${konfiguration.dtype}`);
    }
}

componentConfig.controller = DvbRechnungsKonfigurationen;
angular.module('kitAdmin').component('dvbRechnungsKonfigurationen', componentConfig);
