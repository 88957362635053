<div class="dvb-extra-platz-creation row">

    <div class="col-md-4 big-abstand-bottom dvb-form">
        <h2 data-translate="KIND.EXTRA_DAY.EDIT_MF"
            data-translate-value-count="vm.kind.extraPlaetze.length"
            data-translate-interpolation="messageformat">
        </h2>

        <dvlib-date-switcher mode="week"
                             class="normal-abstand-bottom"
                             [(date)]="vm.firstOfWeek"
                             (debounced-date-change)="vm.initFraktionenAndBelegung()">
        </dvlib-date-switcher>

        <div class="row">
            <div class="col-md-12">
                <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                              ng-model="vm.kinderOrtSearchResult"
                                              ng-change="vm.kinderOrtChanged()"
                                              placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                              as-col="true">
                </dvb-search-get-single-entity>

                <p ng-if="!vm.hasPermissionsForKinderOrt"
                   class="warning small-abstand-bottom"
                   data-translate="KIND.EXTRA_DAY.NO_PERMISSION_FOR_KINDER_ORT">
                </p>
            </div>
        </div>

        <dvlib-spinner ng-if="vm.isLoading" class="centered-spinner"></dvlib-spinner>

        <div ng-if="vm.currentOrtFraktionen"
             ng-repeat="fraktion in vm.currentOrtFraktionen"
             class="big-abstand-bottom">

            <dvb-wochen-belegung
                name="{{fraktion.getDisplayName()}}"
                gruppe="fraktion"
                first-of-week="vm.firstOfWeek"
                wochen-plaetze="fraktion.gruppenWochenBelegungen[0].plaetze"
                show-freie-plaetze="false"
                show-belegte-plaetze="true"
                interactive="vm.hasPermissionsForKinderOrt"
                distinct-betreuungs-zeitraeume="fraktion.wochenplan.distinctZeitraeume"
                week-days="vm.weekDays"
                on-zeitraum-feld-clicked="vm.onFeldClicked(zeitraumFeld, event, modifier, fraktion)"
                zeitraum-felder="fraktion.wochenplan.zeitraumFelder">
            </dvb-wochen-belegung>
        </div>

        <dvb-zuweisen-popover ng-if="vm.kinderOrt"
                              kita="vm.kinderOrt"
                              zuweisung-ab="vm.firstOfWeek"
                              set-subventioniert="vm.setSubventioniert(subventioniertesKontingent)"
                              set-privat="vm.setPrivat()"
                              set-firma="vm.setFirma(firmenKontingent)">
        </dvb-zuweisen-popover>
    </div>

    <div class="col-md-7 col-md-offset-1">
        <div ng-if="vm.meldung.bemerkung" class="normal-abstand-bottom">
            <h2 data-translate="KINDERORT.KIND_ABWEICHUNGS_MELDUNGEN.SINGULAR"></h2>
            <p ng-bind="vm.meldung.bemerkung"></p>
        </div>

        <h2 data-translate="KIND.EXTRA_DAY.CREATED_DAYS"></h2>

        <dvb-period-selector ng-model="vm.tempExtraPlatzBuilder.gueltigkeit"
                             ng-change="vm.initFilteredTempPlaetze()">
        </dvb-period-selector>

        <form name="extraPlatzForm" ng-submit="vm.create()" novalidate ng-hide="vm.isLoading">
            <dvb-temp-extra-plaetze plaetze="vm.tempExtraPlatzBuilder.filteredTempPlaetze"
                                    fraktionen="vm.currentPlaetzeFraktionen"
                                    is-loading="vm.isLoading"
                                    remove-platz="vm.removePlatz(platz)"
                                    extra-platz-updated="vm.flagPlatzUpdated(platz)">
            </dvb-temp-extra-plaetze>

            <dvb-submit-cancel-buttons is-loading="vm.isSaving"
                                       submit-label="COMMON.SPEICHERN"
                                       on-cancel="vm.cancel()">
            </dvb-submit-cancel-buttons>
        </form>
    </div>
</div>
