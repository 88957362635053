/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Pensum} from '@dv/kitadmin/models';
import {Bewerbung, PensumType} from '@dv/kitadmin/models';
import type {BetreuungsZeitraum, ILimited} from '@dv/shared/code';
import {DvbDateUtil, END_OF_TIME, Gueltigkeit, isLimited, isPresent, KGB} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {UserSettingsStore} from '../../cache/service/cache/userSettingsStore';
import {PopoverFilterController} from '../../filter/popover/PopoverFilterController';
import {PopoverFilterPropertyGueltigkeit} from '../../filter/popover/PopoverFilterPropertyGueltigkeit';
import {PopoverFilterPropertyPensum} from '../../filter/popover/PopoverFilterPropertyPensum';
import type {OnChangeListener} from '../../filter/shared/FilterModelChangeHandler';
import {AbstractFilterRepository} from '../../filter/zeitraum/AbstractFilterRepository';
import {ZeitraumFilter} from '../../filter/zeitraum/ZeitraumFilter';
import {ZeitraumFilterController} from '../../filter/zeitraum/ZeitraumFilterController';
import type {BetreuungsZeitraumService} from '../../wochenplan/service/betreuungsZeitraumService';
import {AgeFilterOption} from './kinderFilter-models/AgeFilterOption';
import type {
    BOOLEAN_FILTER_PROPS,
    GUELTIGKEIT_FILTER_PROPS,
    ID_ARRAY_FILTER_PROPS,
    MIN_MAX_FILTER_PROPS,
} from './kinderFilter-models/FilterProperties';
import {FilterProperties} from './kinderFilter-models/FilterProperties';
import type {KindFilterModel} from './kinderFilter-models/KindFilterModel';
import {MinMaxFilterProperty} from './kinderFilter-models/MinMaxFilterProperty';
import {NumberFilterOption} from './kinderFilter-models/NumberFilterOption';

export class KinderFilterService extends AbstractFilterRepository implements OnChangeListener {

    public static override $inject: readonly string[] = [
        '$rootScope',
        'betreuungsZeitraumService',
        '$q',
        'userSettingsStore',
    ];

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    // noinspection MagicNumberJS
    private static readonly AVAILABLE_AGE_FILTERS: AgeFilterOption[] = [
        new AgeFilterOption('0', '0', 0, 1),
        new AgeFilterOption('1', '1', 1, 2),
        new AgeFilterOption('2', '2', 2, 3),
        new AgeFilterOption('3', '3', 3, 4),
        new AgeFilterOption('4', '4', 4, 5),
        new AgeFilterOption('5', '>4', 5, 100),
    ];
    /* eslint-enable @typescript-eslint/no-magic-numbers */

    private static readonly AVAILABLE_PRIO_FILTERS: NumberFilterOption[] = Bewerbung.prioritaeten.map(prio =>
        new NumberFilterOption(String(prio), String(prio), false, false, prio));

    // typedef gets auto type signature from TS inference and is assigned in constructor
    public filterControllers;

    public constructor(
        $rootScope: angular.IRootScopeService,
        private betreuungsZeitraumService: BetreuungsZeitraumService,
        private $q: angular.IQService,
        private userSettingsStore: UserSettingsStore,
    ) {
        super($rootScope);

        const wochenplanZeitraeumeCtrl =
            new ZeitraumFilterController<BetreuungsZeitraum>(
                new ZeitraumFilter<BetreuungsZeitraum>($q, id => betreuungsZeitraumService.get(id, {cache: true})));
        wochenplanZeitraeumeCtrl.addChangeListener(this);

        const pensum = new PopoverFilterController<PopoverFilterPropertyPensum, MinMaxFilterProperty, Pensum>(
            new PopoverFilterPropertyPensum(PensumType.PERCENTAGE),
        );

        const anmeldeDatum = new PopoverFilterController<PopoverFilterPropertyGueltigkeit, Gueltigkeit, Gueltigkeit>(
            new PopoverFilterPropertyGueltigkeit(
                new Gueltigkeit(DvbDateUtil.today().subtract(1, 'month'), END_OF_TIME)));

        this.filterControllers = {
            wochenplanZeitraeumeCtrl,
            pensum,
            anmeldeDatum,
        };
    }

    public override resetFilter(): void {
        this.filterControllers.wochenplanZeitraeumeCtrl.reset();
        this.initFilterProperties();
        super.resetFilter();
    }

    public onFilterChanged(): void {
        // quick-hack, to sync fraktionIds filter to ZeitraumFilter -> use fraktionId specific OnChangeListener
        this.filterControllers.wochenplanZeitraeumeCtrl.setRelevantFraktionIds(this.getGruppenIds());
        this.filterModelChanged();
    }

    /**
     * @param {boolean|null} isSubventioniert mit NULL kann der Subventions-Filter deaktiviert werden
     */
    public setSubventioniert(isSubventioniert: any): void {
        this.setBooleanFilterProperty('subventionierterPlatz', isSubventioniert);
    }

    public isSubventioniert(): boolean {
        return !!this.getFilterProperties().subventionierterPlatz;
    }

    /**
     * @param {boolean|null} isPrivaterPlatz mit NULL kann der Privater-Platz-Filter deaktiviert werden
     */
    public setPrivaterPlatz(isPrivaterPlatz: any): void {
        this.setBooleanFilterProperty('privaterPlatz', isPrivaterPlatz);
    }

    public isPrivaterPlatz(): boolean {
        return !!this.getFilterProperties().privaterPlatz;
    }

    public setGruppenIds(gruppenIds: string[]): void {
        this.setArrayFilterProperty('gruppenIds', gruppenIds);
    }

    public getGruppenIds(): string[] {
        return angular.copy(this.getFilterProperties().gruppenIds);
    }

    public setBelegungCustomFieldIds(customFieldIds: string[]): void {
        if (!Array.isArray(customFieldIds)) {
            return;
        }

        this.setFilterProperty('belegungCustomFields', customFieldIds);
    }

    public getBelegungCustomFieldIds(): string[] {
        return angular.copy(this.getFilterProperties().belegungCustomFields);
    }

    public getAvailableAgeFilters(): AgeFilterOption[] {
        return angular.copy(KinderFilterService.AVAILABLE_AGE_FILTERS);
    }

    public setAgeFilterIds(ageFilterIds: string[]): void {
        this.setArrayFilterProperty('ageFilterIds', ageFilterIds);
    }

    public getAgeFilterIds(): string[] {
        return angular.copy(this.getFilterProperties().ageFilterIds);
    }

    public getAvailablePrioritaetFilters(): NumberFilterOption[] {
        return angular.copy(KinderFilterService.AVAILABLE_PRIO_FILTERS);
    }

    public setPrioritaetFilterIds(prioritaetFilterIds: string[]): void {
        this.setArrayFilterProperty('prioritaetFilterIds', prioritaetFilterIds);
    }

    public getPrioritaetFilterIds(): string[] {
        return angular.copy(this.getFilterProperties().prioritaetFilterIds);
    }

    public setFirmenKontingentIds(firmenKontingentIds: string[]): void {
        this.setArrayFilterProperty('firmenKontingentIds', firmenKontingentIds);
    }

    public getFirmenKontingentIds(): string[] {
        return angular.copy(this.getFilterProperties().firmenKontingentIds);
    }

    /**
     * @param {boolean} isVerfuegbarAb falls true werden nur verfügbare Wünsche angezeigt
     */
    public setVerfuegbarAb(isVerfuegbarAb: any): void {
        this.setBooleanFilterProperty('isVerfuegbarAb', isVerfuegbarAb);
    }

    public isVerfuegbarAb(): boolean {
        return this.getFilterProperties().isVerfuegbarAb;
    }

    /**
     * @return {boolean} TRUE, when a filter is set.
     */
    public isFilterSet(kinderOrtId?: string): boolean {
        const filterProperties = this.getFilterProperties();
        this.filterControllers.wochenplanZeitraeumeCtrl.setRelevantKinderOrtId(kinderOrtId);

        return filterProperties.isSet() || this.filterControllers.wochenplanZeitraeumeCtrl.isActive;
    }

    /**
     * @param key Object Key eines property von FilterProperties mit boolean filtering
     * @param {boolean|null} value mit NULL kann der Filter deaktiviert werden
     */
    public setBooleanFilterProperty(key: typeof BOOLEAN_FILTER_PROPS.type, value: any): void {
        if (value === null || typeof value === 'boolean') {
            this.setFilterProperty(key, value);
        }
    }

    public getBooleanFilterProperty(key: typeof BOOLEAN_FILTER_PROPS.type): boolean {
        return !!this.getFilterProperties()[key];
    }

    public getMinMaxFilterProperty(key: typeof MIN_MAX_FILTER_PROPS.type): MinMaxFilterProperty | null {
        return this.getFilterProperties()[key];
    }

    public setMinMaxFilterProperty(key: typeof MIN_MAX_FILTER_PROPS.type, value: any): void {
        if (value === null || value instanceof MinMaxFilterProperty) {
            this.setFilterProperty(key, value);
        }
    }

    public getGueltigkeitFilterProperty(key: typeof GUELTIGKEIT_FILTER_PROPS.type): Gueltigkeit | null {
        return this.getFilterProperties()[key];
    }

    public setGueltigkeitFilterProperty(key: typeof GUELTIGKEIT_FILTER_PROPS.type, value: any): void {
        if (value === null || isLimited(value)) {
            this.setFilterProperty(key, value);
        }
    }

    /**
     * @param {KindFilterModel} kindFilterModel
     * @param {Object} params
     * @param {moment.Moment} params.stichtag fuer Datums-relevante Filterung, wie z.B: Alter der Kinder am Stichtag
     * @param {string} params.kitaId
     * @param {Array.<string>} params.gruppenIds
     * @param {boolean} params.geschwister
     * @return {angular.IPromise.<boolean>}
     */
    // eslint-disable-next-line complexity
    public isVisible(kindFilterModel: KindFilterModel, params: any): angular.IPromise<boolean> {
        const filterProperties = this.getFilterProperties();

        if (this.filterByBoolean(kindFilterModel.subventioniert, filterProperties.subventionierterPlatz) &&
            this.filterByBoolean(kindFilterModel.privat, filterProperties.privaterPlatz) &&
            this.filterByIds(kindFilterModel.gruppenIds, filterProperties.gruppenIds) &&
            this.filterByIds(kindFilterModel.firmenKontingentIds, filterProperties.firmenKontingentIds) &&
            this.filterByStatus(kindFilterModel, filterProperties) &&
            this.filterByAge(kindFilterModel.geburtsTagOrTermin!, params.stichtag) &&
            this.filterByVerfuegbarkeit(kindFilterModel.gewuenschteBetreuungAb!, params.stichtag) &&
            this.filterByKindergarten(kindFilterModel, filterProperties) &&
            this.filterByIds(kindFilterModel.belegungCustomFieldIds, filterProperties.belegungCustomFields) &&
            this.filterByBoolean(kindFilterModel.geschwister, filterProperties.geschwister) &&
            this.filterByMinMax(kindFilterModel.pensum, filterProperties.pensum) &&
            this.filterByGueltigkeit(kindFilterModel.anmeldeDatum!, filterProperties.anmeldeDatum) &&
            this.filterByPrioritaet(kindFilterModel.prioritaet)) {

            return this.$q.when(this.filterControllers.wochenplanZeitraeumeCtrl.isVisible(
                kindFilterModel.betreuungsZeitraeumeIds || {}));
        }

        return this.$q.reject(false);
    }

    public getFilterModelCopy(): FilterProperties {
        // Momentan soll die Filter properties nur ueber diesen Service aktualisiert werden konnen.
        return angular.copy(this.getFilterProperties());
    }

    public getFilterProperties(): FilterProperties {
        const filterProperties = this.userSettingsStore.get('KITA_KINDER_FILTER');

        if (isPresent(filterProperties)) {
            return filterProperties;
        }

        return this.initFilterProperties();
    }

    protected initFilterProperties(): FilterProperties {
        const filterProperties = new FilterProperties();
        this.userSettingsStore.put('KITA_KINDER_FILTER', filterProperties);

        return filterProperties;
    }

    /**
     * Only applies the change when it is actually a change
     */
    protected setFilterProperty(key: keyof FilterProperties, value: any): void {
        const filterProperties = this.getFilterProperties();
        if (angular.equals(filterProperties[key], value)) {
            return;
        }
        filterProperties[key] = value;
        this.filterModelChanged();
    }

    protected getFilterChangedEventName(): string {
        return 'filterModelChanged';
    }

    protected fetchZeitraeume(zeitraeumeIds: string[]): angular.IPromise<any> {
        const promises = zeitraeumeIds.map(id => this.betreuungsZeitraumService.get(id, {cache: true}));

        return this.$q.all(promises);
    }

    private setArrayFilterProperty(key: typeof ID_ARRAY_FILTER_PROPS.type, value: any): void {
        if (Array.isArray(value)) {
            this.setFilterProperty(key, value);
        }
    }

    private filterByBooleanOr(modelBoolean: any, filterBoolean: any): boolean {
        if (typeof filterBoolean === 'boolean') {
            return modelBoolean === filterBoolean;
        }

        return false;
    }

    private filterByBoolean(modelBoolean: any, filterBoolean: any): boolean {
        if (typeof filterBoolean === 'boolean') {
            return modelBoolean === filterBoolean;
        }

        return true;
    }

    private filterByGueltigkeit(modelValue: moment.Moment, filterProperty: ILimited | null): boolean {
        if (filterProperty) {
            return DvbDateUtil.isGueltigOn(filterProperty, modelValue);
        }

        return true;
    }

    private filterByMinMax(modelValue: MinMaxFilterProperty, filterProperty: MinMaxFilterProperty | null): boolean {
        if (filterProperty) {
            return modelValue.min <= filterProperty.max && modelValue.max >= filterProperty.min;
        }

        return true;
    }

    private filterByAge(geburtsTagOrTermin: moment.Moment, stichtag: moment.Moment): boolean {
        const filterProperties = this.getFilterProperties();

        if (!geburtsTagOrTermin ||
            !Array.isArray(filterProperties.ageFilterIds) ||
            filterProperties.ageFilterIds.length === 0) {

            return true;
        }
        const selectedAgeFilters = KinderFilterService.AVAILABLE_AGE_FILTERS
            .filter(ageFilter => filterProperties.ageFilterIds.includes(ageFilter.id!));

        if (selectedAgeFilters.length === 0) {
            return true;
        }

        const age = stichtag.diff(geburtsTagOrTermin, 'years');

        return selectedAgeFilters.some(filter => age >= filter.from && age < filter.to);
    }

    private filterByPrioritaet(prioritaet: number | null): boolean {
        const prioritaetFilterIds = this.getFilterProperties().prioritaetFilterIds;

        if (!prioritaet || prioritaetFilterIds.length === 0) {
            return true;
        }

        const selectedPrioFilter = KinderFilterService.AVAILABLE_PRIO_FILTERS
            .filter(prioFilter => prioritaetFilterIds.includes(prioFilter.id));

        if (selectedPrioFilter.length === 0) {
            return true;
        }

        return selectedPrioFilter.some(filter => prioritaet === filter.value);
    }

    private filterByVerfuegbarkeit(verfuegbarAb: moment.Moment, stichtag: moment.Moment): boolean {
        const filterProperties = this.getFilterProperties();

        return !filterProperties.isVerfuegbarAb || (filterProperties.isVerfuegbarAb &&
            verfuegbarAb &&
            !stichtag.isBefore(verfuegbarAb)
        );
    }

    // eslint-disable-next-line complexity
    private filterByKindergarten(kindFilterModel: KindFilterModel, filterProperties: FilterProperties): boolean {
        if (!filterProperties.kg1 &&
            !filterProperties.kg2 &&
            !filterProperties.schule &&
            !filterProperties.kgIntern &&
            !filterProperties.kgExtern) {
            return true;
        }

        if (kindFilterModel.kindergartenBelegung === null) {
            return false;
        }

        return (KGB.isKG1(kindFilterModel.kindergartenBelegung) && !!filterProperties.kg1) ||
            (KGB.isKG2(kindFilterModel.kindergartenBelegung) && !!filterProperties.kg2) ||
            (KGB.isSchule(kindFilterModel.kindergartenBelegung) && !!filterProperties.schule) ||
            (KGB.isInternal(kindFilterModel.kindergartenBelegung) && !!filterProperties.kgIntern) ||
            (KGB.isExternal(kindFilterModel.kindergartenBelegung) && !!filterProperties.kgExtern);
    }

    private filterByStatus(kindFilterModel: KindFilterModel, filterProperties: FilterProperties): boolean {
        if (filterProperties.provisorisch === null &&
            filterProperties.angebot === null &&
            filterProperties.definitiv === null &&
            filterProperties.keineZuweisung === null) {
            return true;
        }

        return this.filterByBooleanOr(kindFilterModel.provisorisch, filterProperties.provisorisch) ||
            this.filterByBooleanOr(kindFilterModel.angebot, filterProperties.angebot) ||
            this.filterByBooleanOr(kindFilterModel.belegt, filterProperties.definitiv) ||
            this.filterByBooleanOr(kindFilterModel.keineZuweisung, filterProperties.keineZuweisung);
    }

    private filterByIds(modelIds: string[], filterIds: string[]): boolean {
        if (!Array.isArray(filterIds) || filterIds.length === 0) {
            return true;
        }

        if (!Array.isArray(modelIds) || modelIds.length === 0) {
            return false;
        }

        return !filterIds.some(filterId => !modelIds.includes(filterId));
    }
}
