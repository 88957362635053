<div class="dvb-report-main dvb-form">

    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <h1 data-translate="REPORT.TITLE"></h1>

                <h2 ng-if="vm.$uiRouterGlobals.current.data.subtitle && vm.$uiRouterGlobals.current.data.subtitle.length > 0"
                    class="subtitle"
                    data-translate="{{vm.$uiRouterGlobals.current.data.subtitle}}">
                </h2>

                <div class="header-dropdown" dvb-auth require-permission="{{ {oneOf: ['kita:administrate:general', 'personal:administrate:general']} }}" uib-dropdown>
                    <a href="" class="header-box-attachment dropdown-toggle" uib-dropdown-toggle>
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="{{vm.reportStates.REPORT_CONFIG_TEMPLATE_STATE.name}}"
                               data-translate="REPORT.TEMPLATE.KONFIGURATION"></a>
                        </li>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="{{vm.reportStates.REPORT_CONFIG_CUSTOM_FIELD_STATE.name}}"
                               data-translate="REPORT.CUSTOM_FIELD_CONFIGURATION.TITLE"></a>
                        </li>
                    </ul>
                </div>

                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active="active" dvb-auth require-permission="kita:view:general">
                        <a ui-sref="{{vm.reportStates.REPORT_BETRIEB_STATE.name}}"
                           data-translate="REPORT.BETRIEB.TITLE">
                        </a>
                    </li>
                    <li ui-sref-active="active" dvb-auth require-permission="kita:control:general">
                        <a ui-sref="{{vm.reportStates.REPORT_CONTROLLING_BASE_STATE.name}}"
                           data-translate="REPORT.CONTROLLING.TITLE">
                        </a>
                    </li>
                    <li ui-sref-active="active"
                        dvb-auth
                        require-permission="{{['module:faktura', 'kita:control:general']}}">
                        <a ui-sref="{{vm.reportStates.REPORT_FAKTURA_STATE.name}}"
                           data-translate="REPORT.FAKTURA.TITLE">
                        </a>
                    </li>
                    <li ui-sref-active="active"
                        dvb-auth
                        require-permission="{{ ['module:personalplanung', 'personal:view:general'] }}">
                        <a ui-sref="{{vm.reportStates.REPORT_PERSONAL_BASE_STATE.name}}"
                           data-translate="REPORT.PERSONAL.TITLE">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <ui-view></ui-view>
</div>
