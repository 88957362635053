/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AnwesenheitsZeitConstraint, KinderOrt, KinderOrtFraktion} from '@dv/kitadmin/models';
import {PlatzTypen} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import {ZuweisungPopoverHelper} from '../zuweisung-popover-helper';
import type {MonatsBelegungInputRow} from './MonatsBelegungInputRow';
import type {MonatsBelegungZeitInput} from './MonatsBelegungZeitInput';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kita: '<',
        zuweisungAb: '<',
        inputRows: '<',
        parentView: '<?',
        readonly: '<?',
        onEmptyAnwesenheitsZeiten: '&',
    },
    template: require('./dvb-monats-belegung.html'),
    controllerAs: 'vm',
};

export class DvbMonatsBelegung implements angular.IOnInit, angular.IOnDestroy, angular.IController {
    public static $inject: readonly string[] = ['$document', '$scope'];

    public kita!: KinderOrt;
    public zuweisungAb!: moment.Moment;
    public inputRows!: readonly MonatsBelegungInputRow[];
    // true, when used to set bringAbholZeit as CONTACT Benutzer
    public parentView: boolean = false;
    public readonly: boolean = false;
    public onEmptyAnwesenheitsZeiten!: FunctionType;

    public popoverHelper!: ZuweisungPopoverHelper;

    private constraintsByWeekDay: { [wochentag: string]: { [type: string]: AnwesenheitsZeitConstraint[] } } = {};
    private constraintStringsByWeekDay: { [wochentag: string]: { [type: string]: string } } = {};

    /**
     * The last clicked Platztyp icon reference. When opening the popover, this variable will point to the Platztyp
     * object of the clicked AnwesenheitsZeit. See ${@link openPopover}
     */
    private lastSelectedPlatzTypAnwesenheitsZeit: PlatzTypen = PlatzTypen.createPrivat();

    private lastSelectedGruppe?: KinderOrtFraktion;

    public constructor(private $document: angular.IDocumentService, private $scope: angular.IScope) {
    }

    public $onInit(): void {
        this.popoverHelper = new ZuweisungPopoverHelper(this.$document, this.$scope, 'anwesenheitsformPopover');
        this.popoverHelper.init();
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.kita) {
            this.initConstraintsByWeekDay();
        }
    }

    public $onDestroy(): void {
        this.popoverHelper.destroy();
    }

    public openPopover($event: JQuery.TriggeredEvent, platzTypen: PlatzTypen): void {
        $event.stopPropagation();

        this.lastSelectedPlatzTypAnwesenheitsZeit = platzTypen;
        this.popoverHelper.open(angular.element($event.target).closest('button'));
    }

    public repeatZuweisung($event: JQuery.TriggeredEvent, platzTypen: PlatzTypen): void {
        $event.stopPropagation();
        // don't open the context menu
        $event.preventDefault();

        if (platzTypen === this.lastSelectedPlatzTypAnwesenheitsZeit) {
            return;
        }

        angular.copy(this.lastSelectedPlatzTypAnwesenheitsZeit, platzTypen);
    }

    public selectGruppe(
        gruppe: KinderOrtFraktion,
        zeitInput: MonatsBelegungZeitInput,
        row: MonatsBelegungInputRow,
    ): void {
        this.lastSelectedGruppe = gruppe;
        row.changeGruppe(zeitInput, gruppe);
    }

    public repeatGruppe(
        $event: JQuery.TriggeredEvent,
        zeitInput: MonatsBelegungZeitInput,
        row: MonatsBelegungInputRow,
    ): void {
        $event.stopPropagation();
        // don't open context menu
        $event.preventDefault();

        if (this.lastSelectedGruppe) {
            row.changeGruppe(zeitInput, this.lastSelectedGruppe);
        }
    }

    public doEmptyAnwesenheitsZeiten(): void {
        this.inputRows.forEach(row => {
            row.inputsByZeitraumId.forEach(zeitInput => {
                zeitInput.anwesenheit.von = null;
                zeitInput.anwesenheit.bis = null;
            });
        });
    }

    public onChangeZeit(row: MonatsBelegungInputRow): void {
        row.updateAnwesenheitsZeitValidation();
    }

    private initConstraintsByWeekDay(): void {
        this.constraintsByWeekDay = {};
        this.constraintStringsByWeekDay = {};

        this.kita.anwesenheitsZeitConstraints.forEach(constraint => {
            if (!this.constraintsByWeekDay[constraint.dayOfWeek]) {
                this.constraintsByWeekDay[constraint.dayOfWeek] = {
                    BRING_ZEITEN: [],
                    ABHOL_ZEITEN: [],
                };
                this.constraintStringsByWeekDay[constraint.dayOfWeek] = {
                    BRING_ZEITEN: '',
                    ABHOL_ZEITEN: '',
                };
            }

            this.constraintsByWeekDay[constraint.dayOfWeek][constraint.type].push(constraint);
        });

        Object.entries(this.constraintsByWeekDay).forEach(([dayOfWeek, types]) => {
            Object.entries(types).forEach(([type, constraints]) => {
                this.constraintStringsByWeekDay[dayOfWeek][type] =
                    constraints.map(constraint => constraint.getDisplayName()).join(',\n');
            });
        });
    }
}

componentConfig.controller = DvbMonatsBelegung;

angular.module('kitAdmin').component('dvbMonatsBelegung', componentConfig);
