/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {NOTIZ_STATES} from './notiz-states';

angular.module('kitAdmin').config(notizConfig);

export * from './component/dvb-attachments-download/dvb-attachments-download';
export * from './component/dvb-named-entity-notizen/dvb-named-entity-notizen';
export * from './component/dvb-notiz-erstellen-form/dvb-notiz-erstellen-form';
export * from './component/dvb-notiz-timeline-details/dvb-notiz-timeline-details';
export * from './component/dvb-timeline-entry-details/dvb-timeline-entry-details';
export * from './component/dvb-timeline-entry-icon/dvb-timeline-entry-icon';
// Add New Component JS Above

notizConfig.$inject = ['$stateProvider'];

function notizConfig($stateProvider: StateProvider): void {
    Object.values(NOTIZ_STATES).forEach(state => $stateProvider.state(state));
}
