<div class="dvb-kita-faktura-small-invoice-content">
    <p>
        <strong data-translate="KINDERORT.ZAHLUNGSKONTO"></strong>:
        <span ng-bind="vm.konfiguration.bankKontoName || ('KINDERORT.KEIN_KONTO' | translate)"></span>
    </p>

    <p>
        <strong data-translate="COMMON.EINZAHLUNGSSCHEIN"></strong>:
        <span ng-bind="vm.konfiguration.esrName || ('KINDERORT.KEIN_EINZAHLUNGSSCHEIN' | translate)"></span>
    </p>

    <p>
        <strong data-translate="KINDERORT.LAYOUT"></strong>:
        <span ng-bind="vm.konfiguration.layoutName || ('KINDERORT.KEIN_LAYOUT' | translate)"></span>
    </p>

    <ul class="list-inline small-abstand-top" ng-if="vm.konfiguration.massenversandText">
        <li>
            <strong data-translate="KINDERORT.MASSENVERSAND"></strong>:
            <span ng-bind="vm.konfiguration.massenversandText"></span>
        </li>
        <li>
            <strong data-translate="KINDERORT.ABSTAND_LINKS_SHORT"></strong>:
            <span data-translate="KINDERORT.ABSTAND_LINKS_ZAHL_MM"
                  data-translate-value-breite="{{vm.konfiguration.massenversandLinks}}"></span>
        </li>
        <li>
            <strong data-translate="KINDERORT.ABSTAND_OBEN_SHORT"></strong>:
            <span data-translate="KINDERORT.ABSTAND_OBEN_ZAHL_MM"
                  data-translate-value-hoehe="{{vm.konfiguration.massenversandOben}}"></span>
        </li>
    </ul>

    <dvb-kita-faktura-texte-content konfiguration="vm.konfiguration">
    </dvb-kita-faktura-texte-content>
</div>
