/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {MissingGeschlechtOrSprache} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {DataIntegrityService} from '../common/service/rest/dataIntegrityService';
import {VERFUEGUNG_ASSIGNMENT_STATE, VERFUEGUNG_OVERVIEW_STATE} from '../kibon/kibon-states';

export const DATA_INTEGRITY_BASE_STATE = {
    name: 'base.dataIntegrity',
    url: '/datenkontrolle',
    abstract: true,
    template: '<dvb-data-integrity></dvb-data-integrity>',
    data: {
        permission: PERMISSION.KITA.MANAGE_ANY,
    },
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'DATA-INTEGRITY.DATA_INTEGRITY'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const DATA_INTEGRITY_PHONE_NUMBERS_STATE = {
    name: `${DATA_INTEGRITY_BASE_STATE.name}.phoneNumbers`,
    url: '',
    template: '<dvb-malformed-phone-numbers></dvb-malformed-phone-numbers>',
} satisfies Ng1StateDeclaration;

export const DATA_INTEGRITY_GESCHLECHT_SPRACHE_STATE = {
    name: `${DATA_INTEGRITY_BASE_STATE.name}.geschlechtUndSprache`,
    url: '/geschlecht-und-sprache',
    template: '<dvb-missing-geschlecht-or-sprache kontaktperson-entries="$resolve.entries">' +
        '</dvb-missing-geschlecht-or-sprache>',
    resolve: {
        entries: [
            'dataIntegrityService',
            (dataIntegrityService: DataIntegrityService): angular.IPromise<MissingGeschlechtOrSprache[]> =>
                dataIntegrityService.getKontaktpersonenMissingGeschlechtOrSprache(),
        ],
    },
} satisfies Ng1StateDeclaration;

export const DATA_INTEGRITY_KIBON_VERFUEGUNG_STATE = {
    name: `${DATA_INTEGRITY_BASE_STATE.name}.kibonVerfuegungen`,
    url: '/kibon-verfuegungen',
    redirectTo: VERFUEGUNG_OVERVIEW_STATE.name,
} satisfies Ng1StateDeclaration;

export const DATA_INTEGRITY_KIBON_VERFUEGUNG_ASSIGN_STATE = {
    name: `${DATA_INTEGRITY_BASE_STATE.name}.kibonVerfuegungen.assign`,
    url: '/zuweisen/{id:[0-9]+}',
    redirectTo: VERFUEGUNG_ASSIGNMENT_STATE.name,
} satisfies Ng1StateDeclaration;

export const DATA_INTEGRITY_STATES = {
    DATA_INTEGRITY_BASE_STATE,
    DATA_INTEGRITY_PHONE_NUMBERS_STATE,
    DATA_INTEGRITY_GESCHLECHT_SPRACHE_STATE,
    DATA_INTEGRITY_KIBON_VERFUEGUNG_STATE,
    DATA_INTEGRITY_KIBON_VERFUEGUNG_ASSIGN_STATE,
};
