/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {StateProvider} from '@uirouter/angularjs/lib/stateProvider';
import angular from 'angular';
import {WOCHENPLAN_STATES} from './wochenplan-states';

angular.module('kitAdmin').config(wochenplanConfig);

export * from './component/dvb-zeitraum-form/dvb-zeitraum-form';
export * from './component/dvb-wochenplan-profil/dvb-wochenplan-profil';
export * from './component/dvb-wochenplan-main/dvb-wochenplan-main';
export * from './component/dvb-belegungs-einheiten/dvb-belegungs-einheiten';
export * from './component/dvb-belegungs-einheit-form/dvb-belegungs-einheit-form';
export * from './service/betreuungsZeitraumService';
// Add New Component JS Above

wochenplanConfig.$inject = ['$stateProvider'];

function wochenplanConfig($stateProvider: StateProvider): void {
    Object.values(WOCHENPLAN_STATES).forEach(state => {
        $stateProvider.state(state);
    });
}
