/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Ng2StateDeclaration} from '@uirouter/angular';
import {GROUP_ADMIN_EINSTELLUNGEN} from './group-admin-einstellungen.states';

export const GROUP_ADMIN_EINSTELLUNGEN_FEATURE = {
    name: `${GROUP_ADMIN_EINSTELLUNGEN.name}.**`,
    url: GROUP_ADMIN_EINSTELLUNGEN.url,
    loadChildren: () => import('./group-admin-einstellungen.module').then(m => m.GroupAdminEinstellungenModule),
} satisfies Ng2StateDeclaration;
