<dvlib-sort-list [items]="items" [itemRef]="tarifParam" (itemsChange)="itemsChange.emit($event)"/>

<ng-template [dvlibSortListTemplate]="items" #tarifParam let-tarifParam>
    <span>{{ tarifParam | displayName }}</span>
    <span class="badge pull-right"
          [tooltip]="tarifParam | tarifParameterBadge"
          [translate]="'COMMON.TARIF.MF'"
          [translateParams]="{count: tarifParam.tarife.length, showcount: true}">
    </span>
</ng-template>
