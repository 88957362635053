<div class="dvb-tarif-verlauf-header">
    <div class="gueltigkeit">
        <p>
            <span data-translate="{{vm.getTitle()}}"
                  data-translate-value-preposition="{{vm.getTemporalPrepositionKey() | translate}}"></span>
            <span ng-if="!vm.isEroeffnung"
                  ng-bind="vm.tarifValue.gueltigAb | amDateFormat: 'D.M.YYYY'"></span>
            <span ng-if="vm.isEroeffnung"
                  data-translate="TARIF.EROEFFNUNG"></span>
        </p>
    </div>
    <div class="template">
        <p>
            <a ng-if="vm.tarifValue.template"
               href=""
               data-translate="TARIF.TARIF_DOWNLOAD_LABEL"
               ng-click="vm.downloadTarifFile()"></a>
            <dvlib-spinner [show]="vm.loadingTemplate"></dvlib-spinner>
            <span class="pull-right">
                <span ng-if="vm.tarifValue.template"
                      class="badge"
                      data-translate="TARIF.TARIF_VALUE_VERSION"
                      data-translate-value-version="{{vm.tarifValue.template.schemaVersion}}">
                </span>
            </span>
        </p>
    </div>
</div>
