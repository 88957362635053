/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BelegungInterval} from '@dv/kitadmin/models';
import {BelegungsZustand, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        belegungInterval: '<',
        isDateClickable: '<?',
        noDate: '<?',
        titleKey: '<?',
        onDateSelected: '&',
    },
    template: require('./dvb-belegung-interval-header.html'),
    controllerAs: 'vm',
};

export class DvbBelegungIntervalHeader implements angular.IController {
    public static $inject: readonly string[] = [
        '$translate',
    ];

    public belegungInterval!: BelegungInterval;
    public isDateClickable?: boolean;
    public noDate?: boolean;
    public titleKey?: string;
    public onDateSelected?: (val: { date: moment.Moment }) => void;

    public belegungTitle = '';

    private today: moment.Moment = DvbDateUtil.today();

    public constructor(
        public readonly $translate: angular.translate.ITranslateService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.belegungInterval) {
            const currentValue = changes.belegungInterval.currentValue;
            const prepositionKey = DvbDateUtil.getTemporalPrepositionKey(currentValue, this.today);
            const preposition = prepositionKey ? this.$translate.instant(prepositionKey) : '';
            this.belegungTitle = this.$translate.instant(
                this.getBelegungTitleKey(),
                {
                    preposition,
                    von: currentValue.gueltigAb ? currentValue.gueltigAb.format('D.M.YYYY') : null,
                    bis: currentValue.gueltigBis ? currentValue.gueltigBis.format('D.M.YYYY') : null,
                });
        }
    }

    /**
     * @return {string}
     */
    private getBelegungTitleKey(): string {
        if (this.titleKey) {
            return this.titleKey;
        }

        switch (this.belegungInterval.belegungsZustand) {
            case BelegungsZustand.PROVISORISCH:
                return 'COMMON.PROVISORISCHE_BETREUUNG_MIT_DATUMPREP';
            case BelegungsZustand.ANGEBOT_ERSTELLT:
                return 'COMMON.ANGEBOT_AB';
            default:
            // nop
        }

        if (this.belegungInterval.belegungsZustand !== BelegungsZustand.BELEGT) {
            if (this.belegungInterval.extraPlatzWochenBelegungen
                && this.belegungInterval.extraPlatzWochenBelegungen.length > 0) {
                return 'KIND.EXTRA_DAY.WITH_RANGE';
            }

            // falls kein gueltiger BelegungsZustand gesetzt ist
            return '';
        }

        return DvbDateUtil.isGueltigOn(this.belegungInterval, this.today) ?
            'COMMON.AKTUELLE_BETREUUNG_MIT_DATUMPREP' :
            'COMMON.BETREUUNG_MIT_DATUMPREP';
    }
}

componentConfig.controller = DvbBelegungIntervalHeader;
angular.module('kitAdmin').component('dvbBelegungIntervalHeader', componentConfig);
