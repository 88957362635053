<div class="dvb-test-email normal-abstand-top">
    <input type="email"
           class="form-control"
           ng-model="vm.empfaengerEmailAdresse"
           ng-attr-placeholder="{{'COMMUNICATION.EMAIL.TEST_RECIPIENT' | translate}}"
           uib-tooltip="{{'COMMUNICATION.EMAIL.TEST_RECIPIENT' | translate}}"
           tooltip-placement="top-left">
    <button type="submit"
            class="btn btn-link"
            ng-click="vm.submit()"
            data-translate="COMMUNICATION.EMAIL.SEND_TEST_EMAIL">
    </button>
</div>
