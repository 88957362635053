/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KinderOrtFraktion} from '@dv/kitadmin/models';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrt: '<',
        selectedFraktion: '<?',
    },
    template: require('./dvb-kinderort-fraktionen.html'),
    controllerAs: 'vm',
};

export class DvbKinderortFraktionen implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = [];

    public kinderOrt!: KinderOrt;
    public selectedFraktion?: KinderOrtFraktion;

    public today: moment.Moment = DvbDateUtil.today();
    public otherFraktionen: KinderOrtFraktion[] = [];
    public aktiveFraktionen: KinderOrtFraktion[] = [];
    public inaktiveFraktionen: KinderOrtFraktion[] = [];
    public showInaktiveFraktionen: boolean = false;

    public $onInit(): void {
        this.otherFraktionen = this.selectedFraktion
            ? this.kinderOrt.gruppen.filter(gruppe => gruppe.id !== this.selectedFraktion!.id)
            : this.kinderOrt.gruppen;

        this.aktiveFraktionen = this.otherFraktionen
            .filter(gruppe => !gruppe.gueltigBis.isBefore(this.today));
        this.inaktiveFraktionen = this.otherFraktionen
            .filter(gruppe => gruppe.gueltigBis.isBefore(this.today));
    }
}

componentConfig.controller = DvbKinderortFraktionen;
angular.module('kitAdmin').component('dvbKinderortFraktionen', componentConfig);
