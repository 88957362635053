<div class="dvb-rechnung-preview">
    <dvb-rechnung-status status="vm.rechnung.externalStatus"
                         ng-if="vm.rechnung.hasExternal">
    </dvb-rechnung-status>
    <div class="small-abstand-left content" ng-class="{'limited-width': !vm.showEsr}">
        <a ui-sref="base.rechnung.uebersicht({ rechnungId: vm.rechnung.id })"
           class="rechnung-link"
           data-translate="COMMON.RECHNUNG_NR"
           data-translate-value-nummer="{{vm.rechnung.rechnungsNummer}}"></a>

        <div class="pull-right text-right">
            <p ng-if="vm.showEsr" ng-bind="vm.rechnung.esrGenerated | esr"></p>
            <strong data-translate="COMMON.BETRAG_VALUE"
                    data-translate-value-betrag="{{vm.rechnung.summe | number:2}}">
            </strong>
        </div>

        <br>
        <span ng-bind="vm.rechnung.kontaktpersonDisplayName"></span>
    </div>
</div>
