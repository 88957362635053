<div class="dvb-kita-faktura-rechnungslauf">
    <div class="big-abstand-bottom">

        <h2 data-translate="KINDERORT.RECHNUNGSLAEUFE"></h2>

        <div class="hidden-print" dvb-auth require-permission="{{'kita:manage:' + vm.kita.id}}">
            <p ng-if="vm.hasKitaRechnungskonfiguration">
                <a ui-sref="base.kinderort.faktura.rechnungslauf({periode: vm.gueltigAb})"
                   data-translate="KINDERORT.RECHNUNGSLAUF_ERSTELLEN">
                </a>
            </p>
            <p ng-if="!vm.hasKitaRechnungskonfiguration"
               data-translate="KINDERORT.ERSTELLEN_SIE_EINE_KITA_RECHNUNGSKONFIGURATION"></p>
        </div>

        <div class="normal-abstand-top" ng-if="vm.hasKitaRechnungskonfiguration">
            <dvlib-spinner [show]="vm.isFakturaStatusesLoading"></dvlib-spinner>

            <div ng-repeat="statusItem in vm.fakturaStatuses"
                 class="row small-abstand-top">
                <div class="col-xs-5 col-lg-4" ng-bind="statusItem.datum | amDateFormat:'MMMM YYYY'"></div>
                <div class="col-xs-7 col-lg-8">
                    <span class="badge"
                          ng-class="{
                            'warning': statusItem.status === vm.fakturaStatus.NICHT_FAKTURIERT || statusItem.status === vm.fakturaStatus.TEILWEISE_FAKTURIERT,
                            'inactive': statusItem.status === vm.fakturaStatus.KEINE_LEISTUNGEN
                          }"
                          data-translate="KINDERORT.FAKTURA_STATUS.{{statusItem.status}}">
                    </span>
                </div>
            </div>

        </div>
    </div>
</div>
