/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifrechnungResult} from '@dv/kitadmin/models';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {LeistungsrechnungService} from '../../../common/service/rest/kind/leistungsrechnungService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        tarifrechnungResult: '<',
    },
    template: require('./dvb-kind-leistungen-download.html'),
    controllerAs: 'vm',
};

export class DvbKindLeistungenDownload implements angular.IController {

    public static $inject: readonly string[] = ['leistungsrechnungService'];

    public tarifrechnungResult!: TarifrechnungResult;

    public loadingTarifrechnungResult: boolean = false;
    public loadingPlatzConfirmation: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(private leistungsrechnungService: LeistungsrechnungService) {
    }

    public downloadTarifrechnungResult(): void {
        this.loadingTarifrechnungResult = true;

        this.leistungsrechnungService.getTempBlobForTarifrechnungResult(this.tarifrechnungResult.id!)
            .then(tempBlob => {
                this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
            })
            .catch(error => {
                console.error('Konnte TarifrechnungResult nicht herunterladen', error);
            })
            .finally(() => {
                this.loadingTarifrechnungResult = false;
            });
    }

    public downloadPlatzConfirmation(): void {
        this.loadingPlatzConfirmation = true;

        this.leistungsrechnungService.getPlatzConfirmationTempBlob(this.tarifrechnungResult.id!)
            .finally(() => {
                this.loadingPlatzConfirmation = false;
            });
    }
}

componentConfig.controller = DvbKindLeistungenDownload;
angular.module('kitAdmin').component('dvbKindLeistungenDownload', componentConfig);
