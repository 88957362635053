/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CustomField} from '@dv/kitadmin/models';
import type {IPersistable, IRestModel, IValidable} from '@dv/shared/code';
import {DvbUtil} from '@dv/shared/code';

export class CustomFieldConfiguration implements IPersistable, IRestModel, IValidable {
    public static $inject: readonly string[] = [];

    public constructor(
        public id: string | null = null,
        public name: string = '',
        public bemerkung: string = '',
        public customFieldConfigEntries: CustomField[] = [],
        public customFieldConfigEntryIds: string[] = [],
        public allBelegungFields: boolean = false,
        public allKindFields: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): CustomFieldConfiguration {
        const result = new CustomFieldConfiguration(
            data.id,
            data.name,
            data.bemerkung,
        );

        Object.values(data.customFieldConfigEntries)
            .forEach(value => result.customFieldConfigEntries.push(CustomField.apiResponseTransformer(value)));

        result.allBelegungFields = data.allBelegungFields;
        result.allKindFields = data.allKindFields;

        return result;
    }

    public isValid(): boolean {
        return this.hasName();
    }

    public hasName(): boolean {
        return DvbUtil.isNotEmptyString(this.name);
    }

    public toRestObject(): Record<string, unknown> {
        const entries = this.customFieldConfigEntries.map(value => value.id);

        return {
            id: this.id,
            name: this.name,
            bemerkung: this.bemerkung,
            customFieldConfigEntryIds: entries,
            allBelegungFields: this.allBelegungFields,
            allKindFields: this.allKindFields,
        };
    }
}
