/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxTimeRangeBedarf} from '@dv/shared/backend/model/jax-time-range-bedarf';
import {checkPresent, DvbRestUtil, isPresent, TimeRange, TimeRangeUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AusbildungsPersonalBedarf} from '../../betreuungs-schluessel/models/AusbildungsPersonalBedarf';

export class TimeRangeBedarf {

    public constructor(
        public date: moment.Moment,
        public timeRange: TimeRange,
        public ausbildungsBedarf: AusbildungsPersonalBedarf[] = [],
        public maxBelegtePlaetze: number | null = null,
        public maxConcurrentChildCount: number | null = null,
        public totalChildCount: number | null = null,
        public bezugsPersonIds: string[] = [],
        public fromKinderOrtRegel: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: JaxTimeRangeBedarf): TimeRangeBedarf {

        const timeRange = TimeRange.apiResponseTransformer(data.timeRange!);
        const date = checkPresent(DvbRestUtil.localDateToMoment(data.date));
        const result = new TimeRangeBedarf(date, timeRange);

        result.maxBelegtePlaetze = isPresent(data.maxBelegtePlaetze) ? data.maxBelegtePlaetze : null;
        result.maxConcurrentChildCount = isPresent(data.maxConcurrentChildCount) ? data.maxConcurrentChildCount : null;
        result.totalChildCount = isPresent(data.totalChildCount) ? data.totalChildCount : null;
        result.bezugsPersonIds = data.bezugsPersonIds || [];
        result.fromKinderOrtRegel = isPresent(data.fromKinderOrtRegel) ? data.fromKinderOrtRegel : false;

        result.ausbildungsBedarf = DvbRestUtil.transformArray(data.ausbildungsBedarf, AusbildungsPersonalBedarf);

        return result;
    }

    public affects(timeRange: TimeRange): boolean {
        return TimeRangeUtil.isOverlapping(this.timeRange, timeRange);
    }
}
