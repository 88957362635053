/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Einzahlungsschein} from '@dv/kitadmin/models';
import {DOKUMENT_LAYOUT_MAX_MM, DvbUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        einzahlungsschein: '<',
    },
    template: require('./dvb-einzahlungsschein-form.html'),
    controllerAs: 'vm',
};

export class DvbEinzahlungsscheinForm implements angular.IOnInit {
    public static $inject: readonly string[] = [];

    public einzahlungsschein!: Einzahlungsschein;

    public showBank: boolean = false;
    public readonly dokumentLayoutMaxMM: number = DOKUMENT_LAYOUT_MAX_MM;

    public $onInit(): void {
        this.showBank = DvbUtil.isNotEmptyString(this.einzahlungsschein.bankName);
    }
}

componentConfig.controller = DvbEinzahlungsscheinForm;
angular.module('kitAdmin').component('dvbEinzahlungsscheinForm', componentConfig);
