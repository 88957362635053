<div class="dvb-accordion" ng-class="{'first-container':vm.isFirst}">

    <div class="accordion-header"
         ng-click="vm.select()"
         ng-focus="vm.select()"
         ng-class="{
             'selected-accordion': !vm.context.isCollapsed,
             'accordion-read-only': !vm.showContentDisabled && vm.isDisabled
         }"
         ng-transclude="header"
         tabindex="0">
    </div>

    <div uib-collapse="vm.context.isCollapsed"
         collapsed="vm.context.collapseComplete = true"
         expanding="vm.context.collapseComplete = false">

        <div class="accordion-detail"
             ng-class="{'accordion-footer': !vm.isSlotFilled('content')}"
             ng-if="vm.enableContent && !vm.context.isEditMode && (!vm.removeToggledDom || !vm.context.collapseComplete)">

            <div ng-transclude="content" ng-if="vm.isSlotFilled('content')"></div>

            <ul class="list-inline list-inline-row"
                ng-class="{'normal-abstand-top': vm.isSlotFilled('content')}"
                ng-if="!vm.isDisabled && (vm.enableEdit || vm.enableDelete)">

                <li ng-if="vm.enableEdit">
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.context.isEditMode = true"
                            data-translate="COMMON.BEARBEITEN">
                    </button>
                </li>
                <li ng-if="vm.enableDelete">
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.deleteEntity()"
                            data-translate="COMMON.LOESCHEN">
                    </button>
                </li>
            </ul>
        </div>

        <div class="accordion-detail" ng-if="vm.context.isEditMode">
            <form name="accordionForm"
                  ng-submit="!accordionForm.$pending && vm.submit(accordionForm)"
                  novalidate>

                <div ng-transclude="edit"
                     class="normal-abstand-bottom"
                     ng-if="vm.isSlotFilled('edit')">
                </div>

                <dvb-submit-cancel-buttons is-loading="vm.context.isLoading || accordionForm.$pending"
                                           on-cancel="vm.cancel()">
                </dvb-submit-cancel-buttons>
            </form>
        </div>

        <div class="accordion-footer"
             ng-transclude="footer"
             ng-if="vm.isSlotFilled('footer')">
        </div>
    </div>

</div>
