/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {SearchResultEntry} from '@dv/shared/code';
import {DvbDateUtil, isPresent} from '@dv/shared/code';
import type {StateService, UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {finalize, from, Subject, switchMap, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {KindValidationService} from '../../service/kindValidationService';
import {ZUWEISEN_MULTIPLE_SIMPLE_STATE} from '../kind-zuweisen-states';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-direktzuweisung.html'),
    controllerAs: 'vm',
};

export class DvbDirektzuweisung implements angular.IController {

    public static $inject: readonly string[] = [
        '$state',
        '$uiRouterGlobals',
        'kindService',
        'errorService',
        'kindValidationService',
        'dvbStateService',
        'dialogService',
    ];

    public kind: Kind | null = null;
    public geschwisterId!: string | null;
    public defaultAnmeldeDatum: moment.Moment = DvbDateUtil.today();
    public isLoading: boolean = false;
    public kitaSearchResultEntry: SearchResultEntry | null = null;

    public constructor(
        private readonly $state: StateService,
        private readonly $uiRouterGlobals: UIRouterGlobals,
        private readonly kindService: KindService,
        private readonly errorService: ErrorService,
        private readonly kindValidationService: KindValidationService,
        private readonly dvbStateService: DvbStateService,
        private readonly dialogService: DialogService,
    ) {
        this.geschwisterId = this.$uiRouterGlobals.params.geschwisterId ?? null;
    }

    public onKindCreated(kind: Kind): void {
        this.kind = kind;
    }

    public validateAndSaveKind(formIsValid: boolean): void {
        const isValidKind = formIsValid && this.kind!.isValid() && isPresent(this.kitaSearchResultEntry);
        const isValidRechnungsAufteilung = this.kindValidationService.hasValidRechnungsempfaenger(this.kind!);
        const hasValidErziehungsberechtigte = this.kindValidationService.hasValidErziehungsberechtigte(this.kind!);

        this.errorService.handleValidationError(isValidKind, 'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(isValidRechnungsAufteilung, 'ERRORS.ERR_INVALID_RECHNUNGSAUFTEILUNG');
        this.errorService.handleValidationError(hasValidErziehungsberechtigte,
            'ERRORS.ERR_INVALID_ERZIEHUNGSBERECHTIGTE');

        if (isValidKind && hasValidErziehungsberechtigte && isValidRechnungsAufteilung) {
            this.isLoading = true;
            from(this.kindService.matchingKind(this.kind!)).pipe(
                switchMap(kinder => kinder.length > 0 ? this.showConfirmSaveKindModal$() : this.saveKind$()),
                finalize(() => {
                    this.isLoading = false;
                }),
            ).subscribe();
        }
    }

    public goBack(): angular.IPromise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }

    private saveKind$(): Observable<any> {
        this.kind!.anmeldeDatum = this.kind!.anmeldeDatum ?? this.defaultAnmeldeDatum;

        return from(this.kindService.create(this.kind!)).pipe(
            take(1),
            switchMap(response => {
                const id = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);

                return this.$state.go(ZUWEISEN_MULTIPLE_SIMPLE_STATE.name, {
                    kinderOrtIds: [this.kitaSearchResultEntry!.id],
                    kindId: id,
                    direktzuweisung: true,
                });
            }));
    }

    private showConfirmSaveKindModal$(): Observable<any> {

        const dialog$ = new Subject<void>();
        this.dialogService.openConfirmDialog({
            title: 'KIND.KIND_WURDE_BEREITS_EINMAL_ERFASST',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm: () => this.saveKind$().pipe(tap(() => dialog$.next())),
            cancel: () => dialog$.next(),
        });

        return dialog$.pipe(take(1));
    }
}

componentConfig.controller = DvbDirektzuweisung;
angular.module('kitAdmin').component('dvbDirektzuweisung', componentConfig);

