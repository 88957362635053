<div class="dvb-betreuungsfaktorregel-main dvb-form">
    <div class="header">
        <div class="row header-row">
            <i class="fa fa-book header-icon"
               uib-tooltip="{{'COMMON.BETREUUNGSFAKTOR_REGEL.PLURAL' | translate}}"
               tooltip-placement="top-left">
            </i>
            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{vm.betreuungsfaktorRegel.name}}"
                    tooltip-placement="top-left"
                    ng-bind="vm.betreuungsfaktorRegel.name">
                </h1>
            </div>
        </div>
    </div>

    <div class="row form-row">
        <div class="col-md-7">
            <dvb-editable-label ng-attr-placeholder="{{'COMMON.NAME' | translate}}"
                                ng-model="vm.workingCopyBetreuungsfaktorRegel.name"
                                ng-change="vm.onSave()"
                                is-valid="vm.isValidName(param)"
                                dvb-auth
                                require-permission="kita:betreuung_admin:*"
                                on-unauthorised-disable>
            </dvb-editable-label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7">
            <dvb-click2-edit edit-mode="vm.editMode">
                <label-part>
                    <div class="form-control click2edit multi-line"
                         ng-click="vm.edit()"
                         dvb-auth
                         require-permission="kita:betreuung_admin:*"
                         on-unauthorised-disable>
                        <dvb-betreuungsfaktor-regel betreuungsfaktor-regel="vm.betreuungsfaktorRegel">
                        </dvb-betreuungsfaktor-regel>
                    </div>
                </label-part>
                <content-part>
                    <dvb-betreuungsfaktor-regel-form
                        betreuungsfaktor-regel="vm.workingCopyBetreuungsfaktorRegel"
                        on-submit="vm.onSave()"
                        on-cancel="vm.onCancel()">
                    </dvb-betreuungsfaktor-regel-form>
                </content-part>
            </dvb-click2-edit>
        </div>
    </div>
    <div class="big-abstand-top" require-permission="kita:betreuung_admin:*" dvb-auth>
        <div class="form-section">
            <a href=""
               ng-click="vm.confirmDeleteBetreuungsfaktorRegel()"
               data-translate="BETREUUNGSFAKTORREGEL.BETREUUNGSFAKTOR_REGEL_LOESCHEN"></a>
        </div>
    </div>
</div>
