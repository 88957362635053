/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {BetreuungAnfrageState} from '@dv/shared/backend/model/betreuung-anfrage-state';
import type {FormContext} from '@dv/shared/code';
import {isNullish, isPresent} from '@dv/shared/code';
import angular from 'angular';
import {BetreuungAnfrageFilter} from '../../models/BetreuungAnfrageFilter';
import type {BetreuungAnfrageProcessingResult} from '../../models/BetreuungAnfrageProcessingResult';
import type {KibonExchangeBetreuungAnfrage} from '../../models/KibonExchangeBetreuungAnfrage';
import type {KibonExchangeEntityAction} from '../../models/KibonExchangeEntityAction';
import {KibonExchangeEntityButtonAction, KibonExchangeNavigationAction} from '../../models/KibonExchangeEntityAction';
import type {BetreuungAnfrageService} from '../../service/betreuungAnfrageService';
import type {QueryKibonExchangeApiQueryapiParameter} from '../../service/kibonQueryApiService';
import type {TranslationKeyMap} from '../dvb-kibon-entity-matching-overview/dvb-kibon-entity-matching-overview';
import type {KibonExchangeActionHandler} from '../dvb-kibon-entity-matching-overview/KibonEntityActionHandler';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-kibon-betreuungs-anfragen.html'),
    controllerAs: 'vm',
};

export class DvbKibonBetreuungsAnfragen implements angular.IController {

    public static $inject: readonly string[] = [
        'betreuungAnfrageService',
        'errorService',
    ];

    public actionHandler: KibonExchangeActionHandler<KibonExchangeBetreuungAnfrage, BetreuungAnfrageProcessingResult>;
    public filter: BetreuungAnfrageFilter = new BetreuungAnfrageFilter();
    public states: BetreuungAnfrageState[] = Object.values(BetreuungAnfrageState);
    public translations: TranslationKeyMap = {
        pageHeader: 'KIBON.ANFRAGEN.HEADER',
        statePrefix: 'KIBON.ANFRAGEN.STATE.',
        stateTooltipPrefix: 'KIBON.ANFRAGEN.STATE_INFO.',
        kibonExportAm: 'KIBON.ANFRAGEN.ANFRAGE_VOM',
        assignConfirmationHeader: 'KIBON.ANFRAGEN.ASSIGN_CONFIRMATION_HEADER',
        assignEntityDataHeader: 'KIBON.ANFRAGEN.ASSIGN_DATEN',
        entityNameMF: 'KIBON.ANFRAGEN.MF',
        matchingHeader: 'KIBON.ANFRAGEN.MATCHING_HEADER',
        matchingText: 'KIBON.ANFRAGEN.MATCHING_TEXT',
    };

    public actions: KibonExchangeEntityAction<KibonExchangeBetreuungAnfrage>[] = [
        new KibonExchangeEntityButtonAction(
            entity => this.betreuungAnfrageService.unAssign(entity.id),
            entity => entity.canUnAssign(),
            _entity => ({button: 'COMMON.ZUWEISUNG_AUFHEBEN', confirmation: 'KIBON.ANFRAGEN.ZUWEISUNG_AUFHEBEN'}),
        ),
        new KibonExchangeEntityButtonAction(
            entity => this.betreuungAnfrageService.ignore(entity.id),
            entity => entity.canIgnore(),
            _entity => ({button: 'COMMON.IGNORIEREN', confirmation: 'KIBON.ANFRAGEN.IGNORIEREN'}),
        ),
        new KibonExchangeEntityButtonAction(
            entity => this.betreuungAnfrageService.unIgnore(entity.id),
            entity => entity.canUnIgnore(),
            _entity => ({button: 'COMMON.UNIGNORIEREN', confirmation: 'KIBON.ANFRAGEN.NICHT_IGNORIEREN'}),
        ),
        new KibonExchangeNavigationAction(
            'base.kind.kibon',
            entity => ({kindId: entity.assignedKindId}),
            entity => isPresent(entity.assignedKindId),
            _entity => ({button: 'COMMON.KIND.ANSEHEN'}),
        ),
    ];

    public constructor(
        private readonly betreuungAnfrageService: BetreuungAnfrageService,
        private readonly errorService: ErrorService,
    ) {

        const onAssigned = (processingResult: BetreuungAnfrageProcessingResult): void => {
            if (isNullish(processingResult.generationResult)) {
                this.errorService.handleSuccess('KIBON.MUTATION.BETREUUNGSMELDUNG_IGNORIERT');
            } else if (processingResult.generationResult.isFailed()) {
                this.errorService.handleError(processingResult.generationResult.toDvbError());
            } else {
                this.errorService.handleSuccess('KIBON.MUTATION.BETREUUNGSMELDUNG_GENERIERT');
            }
        };

        this.actionHandler = {
            doAssign: (entity, kind) => this.betreuungAnfrageService.assign(entity.id, kind.id),
            onAssigned,
            getFiltered: config => this.betreuungAnfrageService.getFiltered(this.filter, config),
        };
    }

    public queryApi(params: QueryKibonExchangeApiQueryapiParameter, formContext: FormContext): void {
        formContext.startLoading();
        this.betreuungAnfrageService.queryExchangeService(params)
            .finally(() => formContext.finishLoading());
    }
}

componentConfig.controller = DvbKibonBetreuungsAnfragen;
angular.module('kitAdmin').component('dvbKibonBetreuungsAnfragen', componentConfig);
