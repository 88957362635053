<div class="dvb-report-belegungsplan-day-based">
    <h2 data-translate="{{vm.reportState.titleKey}}"></h2>

    <form name="belegungsplanReportForm" novalidate>

        <dvb-report-template-selector report-state="vm.reportState"
                                      on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <dvb-custom-field-configuration-selector on-selection-change="vm.customFieldConfiguration = customFieldConfiguration">
        </dvb-custom-field-configuration-selector>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT"
                                      ng-change="vm.updateKinderOrt()"
                                      ng-model="vm.kinderOrt"
                                      ng-required="!vm.gruppeSearchResultEntry.id"
                                      ng-show="!vm.gruppeSearchResultEntry.id"
                                      placeholder-key="COMMON.KINDERORT.AUSWAEHLEN">
        </dvb-search-get-single-entity>

        <dvb-search-get-single-entity as-col="true"
                                      entity-to-search="KINDERORT_FRAKTION"
                                      ng-model="vm.gruppeSearchResultEntry"
                                      ng-required="!vm.kinderOrt"
                                      ng-show="!vm.kinderOrt"
                                      placeholder-key="{{vm.getKinderOrtFraktionPlaceholderKey()}}"
                                      required>
        </dvb-search-get-single-entity>

        <div>
            <dvb-datepicker-text-field ng-change="vm.updateState()"
                                       ng-model="vm.gueltigAb"
                                       custom-options=""
                                       ng-attr-placeholder="{{'REPORT.BELEGUNGSPLAN_DAY_BASED.WEEK_OF' | translate}}: {{vm.gueltigAb || vm.defaultStichtag | amDateFormat: 'DD.MM.YYYY'}}">
            </dvb-datepicker-text-field>
        </div>

        <dvb-report-download on-submit="vm.onSubmit(belegungsplanReportForm)"></dvb-report-download>
    </form>
</div>
