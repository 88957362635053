/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifValue} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {BEGIN_OF_TIME, checkPresent, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {TarifService} from '../../../common/service/rest/tarifService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        tarifValue: '<',
        tarifId: '<',
    },
    template: require('./dvb-tarif-verlauf-header.html'),
    controllerAs: 'vm',
};

export class DvbTarifVerlaufHeader implements angular.IController {

    public static $inject: readonly string[] = ['tarifService'];

    public tarifValue!: Persisted<TarifValue>;
    public tarifId!: string;

    public loadingTemplate = false;
    public isEroeffnung = false;

    private readonly dvbDownloadCtrl!: DvbDownload;
    private today = DvbDateUtil.today();

    public constructor(
        private readonly tarifService: TarifService,
    ) {
    }

    public $onInit(): void {
        this.isEroeffnung = checkPresent(this.tarifValue.gueltigAb).isSame(BEGIN_OF_TIME);
    }

    public getTemporalPrepositionKey(): string | null {
        return DvbDateUtil.getTemporalPrepositionKey(this.tarifValue, this.today);
    }

    public getTitle(): string {
        return DvbDateUtil.isGueltigOn(this.tarifValue, this.today) ?
            'TARIF.AKTUELLER_TARIF_VALUE_MIT_DATUMPREP' :
            'TARIF.TARIF_VALUE_MIT_DATUMPREP';
    }

    public downloadTarifFile(): void {
        this.loadingTemplate = true;
        this.tarifService.getTempBlobForTarifValue(this.tarifId, this.tarifValue.id).then(tempBlob => {
            this.dvbDownloadCtrl.downloadFileByUrl(tempBlob);
        }).finally(() => {
            this.loadingTemplate = false;
        });
    }
}

componentConfig.controller = DvbTarifVerlaufHeader;
angular.module('kitAdmin').component('dvbTarifVerlaufHeader', componentConfig);
