<fieldset class="form-section">
    <legend>
        <h3 [translate]="'COMMON.STANDORT.AUSWAEHLEN'"></h3>
    </legend>

    <div class="row">
        <div class="col-md-6">
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="alleKinderOrte"
                        [(ngModel)]="anstellungStandort.alleKinderOrte"/>
                    {{ "COMMON.ALLE_KINDERORTE" | translate }}
                </label>
            </div>
        </div>
        @if (!anstellungStandort.alleKinderOrte) {
            <div class="col-md-6">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            name="alleFraktionen"
                            [(ngModel)]="anstellungStandort.alleFraktionen"/>
                        {{ "COMMON.ALLE_GRUPPEN" | translate }}</label>
                </div>
            </div>
        }
    </div>
    <div class="row">
        @if (!anstellungStandort.alleKinderOrte) {
            <div class="col-md-6">
                <dv-search-list
                    entityToSearch="KITA"
                    name="selectedKinderOrte"
                    [(ngModel)]="anstellungStandort.selectedKinderOrte"
                    [orderByText]="true"
                    [placeholder]="'COMMON.KINDERORT.AUSWAEHLEN' | translate"
                    [required]="!anstellungStandort.alleKinderOrte"/>
            </div>
        }
        @if (!anstellungStandort.alleKinderOrte && !anstellungStandort.alleFraktionen) {
            <div class="col-md-6">
                <dv-search-list
                    entityToSearch="GRUPPE"
                    name="selectedFraktionen"
                    [(ngModel)]="anstellungStandort.selectedFraktionen"
                    [orderByText]="true"
                    [placeholder]="'COMMON.KINDERORT_FRAKTION.AUSWAEHLEN' | translate"
                    [required]="!anstellungStandort.alleFraktionen"/>
            </div>
        }
    </div>

    <div class="row">
        <div class="checkbox col-md-6">
            <label>
                <input
                    type="checkbox"
                    name="vertretungsperson"
                    [(ngModel)]="anstellungStandort.vertretungsperson"/>
                <span [translate]="'PERSONAL.ANSTELLUNG.VERTRETUNGSPERSON'"></span>
            </label>
        </div>
    </div>

    <div class="normal-abstand-top" *dvlibRequirePermission="'module:work_time_controlling'">
        <div class="row">
            <div class="col-md-6">
                <div class="normal-abstand-bottom">
                    <h3 [translate]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE'"></h3>
                </div>
                <select class="form-control"
                        [name]="'workTimeModel'"
                        [(ngModel)]="anstellungStandort.workTimeModelId"
                        [disabled]="workTimeModels.length < 1"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.SELECT' | translate"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE' | translate"
                        (ngModelChange)="selectedWorkTimeModelId.set($event)">
                    <option [ngValue]="null">
                        {{ 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WITHOUT' | translate }}
                    </option>
                    @for (model of workTimeModels; track model.id) {
                        <option [ngValue]="model.id">{{ model.name }}</option>
                    }
                </select>
            </div>
            <div class="col-md-6">
                <div class="normal-abstand-bottom">
                    <h3 [translate]="'PERSONAL.ANSTELLUNG.PENSUM'"></h3>
                </div>
                <div>
                    <input
                        type="number"
                        name="pensumProzent"
                        class="form-control"
                        min="0"
                        max="100"
                        [(ngModel)]="anstellungStandort.arbeitspensumProzent"
                        (ngModelChange)="arbeitspensumProzent.set($event)"
                        [placeholder]="'PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT' | translate"
                        [attr.aria-label]="'PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT' | translate"
                        [tooltip]="'PERSONAL.ANSTELLUNG.ARBEITSPENSUM_PROZENT' | translate"/>
                </div>
            </div>
        </div>
        @if (workTimeModelSollzeitSumme() !== null) {
            <div class="row">
                <div class="col-md-12" translate="PERSONAL.ANSTELLUNG.SOLLZEIT_SUM"
                     [translateParams]="{sum: workTimeModelSollzeitSumme() | number: '1.0-2'}">
                </div>
            </div>
        }
    </div>

    @if (anstellungStandort.dailyAnstellungen) {
        <div *dvlibRequirePermission="'module:work_time_controlling'">
            <div class="normal-abstand-bottom normal-abstand-top">
                <h3 [translate]="'PERSONAL.ANSTELLUNG.DAILY_SOLLZEIT'"></h3>
            </div>
            <dv-angestellte-anstellung-sollzeit
                [dailyAnstellungen]="anstellungStandort.dailyAnstellungen"
                [ngModelGroup]="'sollzeit'">
            </dv-angestellte-anstellung-sollzeit>
        </div>
        <div>
            <div class="normal-abstand-bottom normal-abstand-top">
                <h3 [translate]="'PERSONAL.ANSTELLUNG.DAILY_AVAILABILITY'"></h3>
            </div>
            <dv-angestellte-anstellung-zeiten
                [dailyAnstellungen]="anstellungStandort.dailyAnstellungen"
                [ngModelGroup]="'anstellungZeiten'">
            </dv-angestellte-anstellung-zeiten>
        </div>
    }
</fieldset>
