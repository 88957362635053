/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BetreuungsfaktorRegel} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {finalize, from, take, tap} from 'rxjs';
import type {MandantService} from '../../../common/service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsfaktorRegel: '<',
    },
    template: require('./dvb-betreuungsfaktorregel-main.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsfaktorregelMain implements angular.IOnInit, angular.IOnChanges {
    public static $inject: readonly string[] = [
        '$attrs',
        '$state',
        'mandantService',
        'errorService',
        'dialogService',
    ];

    public betreuungsfaktorRegel!: BetreuungsfaktorRegel;

    public workingCopyBetreuungsfaktorRegel: BetreuungsfaktorRegel | null = null;
    public isDisabled: boolean = false;
    public editMode: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private $attrs: angular.IAttributes,
        private $state: StateService,
        private mandantService: MandantService,
        private errorService: ErrorService,
        private dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        this.$attrs.$observe('disabled', value => {
            this.isDisabled = !!value;
        });
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.betreuungsfaktorRegel) {
            this.workingCopyBetreuungsfaktorRegel = angular.copy(this.betreuungsfaktorRegel);
        }
    }

    public edit(): void {
        if (this.isDisabled) {
            return;
        }

        this.workingCopyBetreuungsfaktorRegel = angular.copy(this.betreuungsfaktorRegel);
        this.editMode = true;
    }

    public onSave(): void {
        const regel = checkPresent(this.workingCopyBetreuungsfaktorRegel);
        this.isLoading = true;

        this.mandantService.createBetreuungsfaktorRegel(regel).then(() => {
            this.editMode = false;
            this.errorService.clearAll();
            this.betreuungsfaktorRegel = regel;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public onCancel(): void {
        this.editMode = false;
        this.workingCopyBetreuungsfaktorRegel = this.betreuungsfaktorRegel;
    }

    public isValidName(param: any): boolean {
        const valid = DvbUtil.isNotEmptyString(param);
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_REQUIRED_NAME');

        return valid;
    }

    public confirmDeleteBetreuungsfaktorRegel(): void {
        this.dialogService.openDeleteDialog({
            entityText: 'COMMON.BETREUUNGSFAKTOR_REGEL.SINGULAR',
            confirm: () =>
                from(this.mandantService.deleteBetreuungsfaktorRegel(checkPresent(this.betreuungsfaktorRegel.id)))
                    .pipe(
                        take(1),
                        tap(() => this.$state.go('base.voreinstellungen.betreuungsfaktorregeln')),
                        finalize(() => {
                            this.isLoading = false;
                            this.editMode = false;
                        }),
                    ),
        });
    }
}

componentConfig.controller = DvbBetreuungsfaktorregelMain;
angular.module('kitAdmin').component('dvbBetreuungsfaktorregelMain', componentConfig);
