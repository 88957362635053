/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DialogService} from '@dv/kitadmin/ui';
import type {NamedEntityType} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import {from, take, tap} from 'rxjs';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import {Notiz} from '../../models/Notiz';
import type {NotizService} from '../../service/notiz-service';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entityId: '<?',
        entityType: '<?',
        existingNotiz: '<?',
    },
    template: require('./dvb-notiz-erstellen-form.html'),
    controllerAs: 'vm',
};

export class DvbNotizErstellenForm implements angular.IController {
    public static $inject: readonly string[] = [
        'dvbStateService',
        'notizService',
        'errorService',
        'dialogService',
    ];

    public entityId?: string;
    public entityType?: NamedEntityType;
    public existingNotiz?: Notiz;

    public notiz!: Notiz;
    public isLoading: boolean = false;
    public notizDate?: moment.Moment | null;
    public notizTime?: moment.Moment | null;

    public constructor(
        private dvbStateService: DvbStateService,
        private notizService: NotizService,
        private errorService: ErrorService,
        private dialogService: DialogService,
    ) {
    }

    public $onInit(): void {
        if (this.existingNotiz) {
            this.notiz = this.existingNotiz;
            if (DvbDateUtil.isValidMoment(this.existingNotiz.timestampTimeline)) {
                this.notizDate = moment(this.existingNotiz.timestampTimeline);
                const time = this.existingNotiz.hasTimelineTime() ? moment(this.existingNotiz.timestampTimeline) : null;
                this.notizTime = time;
            }

            return;
        }

        this.notiz = new Notiz();
        this.notiz.entityId = this.entityId ?? '';
        this.notiz.entityType = this.entityType ?? null;
    }

    public submit(form: angular.IFormController): void {
        if (!this.notiz.entityId || !this.notiz.entityType) {
            return;
        }

        const isNotizValid = form.$valid && DvbUtil.isNotEmptyString(this.notiz.subject)
            && DvbUtil.isNotEmptyString(this.notiz.content);

        this.errorService.handleValidationError(isNotizValid, 'ERRORS.ERR_NOTIZ_REQUIRES_SUBJECT_AND_CONTENT');

        if (!isNotizValid) {
            return;
        }

        if (DvbDateUtil.isValidMoment(this.notizTime) && DvbDateUtil.isValidMoment(this.notizDate)) {
            const dateTime = `${this.notizDate.format('YYYY-MM-DD')}T${this.notizTime.format('HH:mm')}:00.000`;
            this.notiz.timestampTimeline = moment(dateTime, moment.ISO_8601, true);
        } else if (DvbDateUtil.isValidMoment(this.notizDate)) {
            this.notiz.timestampTimeline = moment(this.notizDate).startOf('day');
        } else {
            this.notiz.timestampTimeline = null;
        }

        this.openDialog();
    }

    public goBack(): void {
        this.dvbStateService.goToPreviousState();
    }

    private openDialog(): void {
        this.isLoading = true;

        this.dialogService.openConfirmDialog({
            title: this.notiz.id ? 'NOTIZ.UPDATE_NOTIZ_CONFIRM' : 'NOTIZ.CREATE_NOTIZ_CONFIRM',
            confirmActionText: 'COMMON.BESTAETIGEN',
            confirm: () => {
                const savePromise = this.notiz.id ?
                    this.notizService.update(this.notiz) :
                    this.notizService.create(this.notiz);

                return from(savePromise).pipe(
                    take(1),
                    tap(() => {
                        this.goBack();
                        this.isLoading = false;
                    }));
            },
            cancel: () => {
                this.isLoading = false;
            },
        });
    }
}

componentConfig.controller = DvbNotizErstellenForm;
angular.module('kitAdmin').component('dvbNotizErstellenForm', componentConfig);
