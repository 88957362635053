/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AbstractKontaktpersonZahlung,
    Gebuehr,
    OffenerPosten,
    Rechnung,
    Rueckerstattung,
    TempBlob,
    Vorauszahlung,
} from '@dv/kitadmin/models';
import type {CompletableDialogModel, DialogService} from '@dv/kitadmin/ui';
import {OffenerPostenType} from '@dv/shared/backend/model/offener-posten-type';
import type {NoContent, Persisted} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Observable} from 'rxjs';
import {finalize, Subject, switchMap, take, tap} from 'rxjs';
import type {DvbDownload} from '../../../base/directive/dvb-download/dvb-download';
import type {KontaktpersonenZahlungService} from '../../service/rest/kontaktpersonen-zahlung.service';
import type {
    ConfirmAusgleichZahlungDialogModel,
} from '../confirm-ausgleich-zahlung/confirm-ausgleich-zahlung.component';
import {ConfirmAusgleichZahlungComponent} from '../confirm-ausgleich-zahlung/confirm-ausgleich-zahlung.component';
import type {
    KontaktpersonRueckerstattungDialogResult,
} from './kontaktperson-rueckerstattung/kontaktperson-rueckerstattung-dialog.component';
import {
    KontaktpersonRueckerstattungDialogComponent,
} from './kontaktperson-rueckerstattung/kontaktperson-rueckerstattung-dialog.component';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    require: {dvbDownloadCtrl: '^dvbDownload'},
    bindings: {
        offenePosten: '<',
        isLoading: '<',
        interactive: '<',
        selectedKontaktpersonZahlungId: '<?',
    },
    template: require('./dvb-kontaktperson-offene-posten-liste.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonOffenePostenListe implements angular.IController {
    public static $inject: readonly string[] = [
        'dialogService',
        '$translate',
        'kontaktpersonenZahlungService',
        '$state',
        '$filter',
    ];

    public offenePosten: OffenerPosten[] = [];
    public isLoading!: boolean;
    public interactive!: boolean;
    public selectedKontaktpersonZahlungId?: string;

    public selectedPosten: number | null = null;
    public isPostenOptionLoading: boolean = false;

    private readonly dvbDownloadCtrl!: DvbDownload;

    public constructor(
        private readonly dialogService: DialogService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly kontaktpersonenZahlungService: KontaktpersonenZahlungService,
        private readonly $state: StateService,
        private readonly $filter: angular.IFilterService,
    ) {
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.offenePosten) {
            this.selectedPosten = null;
            this.preSelectKontaktpersonZahlung();
        } else if (changes.selectedKontaktpersonZahlungId) {
            this.preSelectKontaktpersonZahlung();
        }
    }

    public selectPosten(offenerPosten: OffenerPosten, index: number): void {
        if (!this.hasPostenOptions(offenerPosten)) {
            return;
        }

        if (this.selectedPosten === index) {
            this.selectedPosten = null;

            return;
        }

        this.selectedPosten = index;
    }

    public hasPostenOptions(offenerPosten: OffenerPosten): boolean {
        if (!this.interactive) {
            return false;
        }

        const validDType = offenerPosten.dtype === OffenerPostenType.VORAUSZAHLUNG ||
            offenerPosten.dtype === OffenerPostenType.RUECKERSTATTUNG ||
            offenerPosten.dtype === OffenerPostenType.GEBUEHR;

        if (!validDType) {
            return false;
        }

        if (offenerPosten.dtype === OffenerPostenType.VORAUSZAHLUNG) {
            return checkPresent(offenerPosten.vorauszahlung).rechnung === null;
        }

        if (offenerPosten.dtype === OffenerPostenType.GEBUEHR) {
            return offenerPosten.isDeletableGebuehr() || offenerPosten.isRueckerstattbareGebuehr();
        }

        return offenerPosten.dtype === OffenerPostenType.RUECKERSTATTUNG;
    }

    public deleteVorauszahlung(vorauszahlung: Persisted<Vorauszahlung>): void {
        this.isPostenOptionLoading = true;
        const id = vorauszahlung.id;

        this.kontaktpersonenZahlungService.getVorauszahlungRechnung$(id)
            .pipe(
                switchMap(rechnung => {
                    const vorauszahlungText = this.getVorauszahlungText(vorauszahlung);
                    const action: () => Observable<unknown> =
                        () => this.kontaktpersonenZahlungService.deleteVorauszahlung$(id)
                            .pipe(tap(() => this.$state.reload()));

                    return this.confirmDeleteKontaktpersonZahlung$(action, rechnung, vorauszahlungText);
                }),
                finalize(() => {
                    this.isPostenOptionLoading = false;
                }),
            )
            .subscribe();
    }

    public deleteRueckerstattung(rueckerstattung: Persisted<Rueckerstattung>): void {
        this.isPostenOptionLoading = true;
        const id = rueckerstattung.id;

        this.kontaktpersonenZahlungService.getRueckerstattungRechnung$(id)
            .pipe(
                switchMap(rechnung => {
                    const rueckerstattungText = this.getRueckerstattungText(rueckerstattung);
                    const action: () => Observable<unknown> =
                        () => this.kontaktpersonenZahlungService.deleteRueckerstattung$(id)
                            .pipe(take(1), tap(() => this.$state.reload()));

                    return this.confirmDeleteKontaktpersonZahlung$(action, rechnung, rueckerstattungText);
                }),
                finalize(() => {
                    this.isPostenOptionLoading = false;
                }),
            ).subscribe();
    }

    public deleteGebuehr(gebuehr: Persisted<Gebuehr>): void {
        this.isPostenOptionLoading = true;
        const id = gebuehr.id;

        const gebuehrText = this.getGebuehrText(gebuehr);
        const action: () => Observable<unknown> = () => this.kontaktpersonenZahlungService.deleteGebuehr$(id)
            .pipe(
                tap(() => this.$state.reload()),
                finalize(() => {
                    this.isPostenOptionLoading = false;
                }),
            );

        this.confirmDeleteKontaktpersonZahlung$(action, null, gebuehrText).subscribe();
    }

    public rueckerstattungAuszahlen(rueckerstattung: Persisted<Rueckerstattung>): void {
        const options: CompletableDialogModel<KontaktpersonRueckerstattungDialogResult> = {
            confirm: (data: KontaktpersonRueckerstattungDialogResult) =>
                this.kontaktpersonenZahlungService.rueckerstattungAuszahlen$(
                    rueckerstattung.id,
                    data.auszahlungsDatum,
                    data.generatePainFile,
                    data.zahlungszweck)
                    .pipe(
                        tap((response: TempBlob | NoContent) => {
                            if (response) {
                                this.dvbDownloadCtrl.downloadFileByUrl(response);
                            }
                            this.$state.reload();
                        }),
                    ),
        };
        this.dialogService.openDialog(KontaktpersonRueckerstattungDialogComponent, options);
    }

    public canPayoutRueckerstattung(offenerPosten: OffenerPosten): boolean {
        return offenerPosten.isRueckerstattung()
            && checkPresent(offenerPosten.rueckerstattung).auszahlungsDatum === null;
    }

    public getRueckerstattungLoeschenText(offenerPosten: OffenerPosten): string {
        return checkPresent(offenerPosten.rueckerstattung).auszahlungsDatum === null ?
            'COMMON.LOESCHEN' :
            'COMMON.RUECKERSTATTUNG_UND_AUSZAHLUNG_LOESCHEN';
    }

    private preSelectKontaktpersonZahlung(): void {
        if (!this.selectedKontaktpersonZahlungId) {
            return;
        }

        for (let i = 0; i < this.offenePosten.length; i++) {
            const current = this.offenePosten[i];

            if (current.dtype === OffenerPostenType.RUECKERSTATTUNG_AUSBEZAHLT ||
                current.dtype === OffenerPostenType.VORAUSZAHLUNG_VERBUCHT) {
                // ignore this match, because it does not provide user interaction (e.g. cancellation)
                continue;
            }

            if ((current.rueckerstattung ?? current.vorauszahlung)?.id === this.selectedKontaktpersonZahlungId) {

                // found it -> set selected
                this.selectedPosten = i;

                return;
            }
        }
    }

    private confirmDeleteKontaktpersonZahlung$(
        confirm: () => Observable<unknown>,
        rechnung: Rechnung | null,
        text: string,
    ): Observable<boolean> {

        const dialogSubject$ = new Subject<boolean>();
        const dialogConfirm = (): Observable<unknown> => confirm().pipe(tap(() => dialogSubject$.next(true)));

        if (!rechnung) {
            // there is no rechnung for this kontaktpersonZahlung -> confirm normally
            this.dialogService.openDeleteDialog({
                entityText: text,
                confirm: dialogConfirm,
                cancel: () => dialogSubject$.next(false),
            });

            return dialogSubject$.pipe(take(1));
        }

        const model: ConfirmAusgleichZahlungDialogModel = {
            rechnung,
            text,
            confirm: dialogConfirm,
            cancel: () => dialogSubject$.next(false),
        };

        this.dialogService.openDialog(ConfirmAusgleichZahlungComponent, model);

        return dialogSubject$.pipe(take(1));
    }

    private getVorauszahlungText(vorauszahlung: Vorauszahlung): string {
        return this.getZahlungsText(this.$translate.instant('COMMON.VORAUSZAHLUNG'), vorauszahlung);
    }

    private getRueckerstattungText(rueckerstattung: Rueckerstattung): string {
        return this.getZahlungsText(this.$translate.instant('COMMON.RUECKERSTATTUNG'), rueckerstattung);
    }

    private getGebuehrText(gebuehr: Gebuehr): string {
        const gebuehrText = this.$translate.instant(`COMMON.GEBUEHR.${gebuehr.gebuehrType}`);

        return this.getZahlungsText(gebuehrText, gebuehr);
    }

    private getZahlungsText(typeText: string, kontaktpersonZahlung: AbstractKontaktpersonZahlung): string {
        return this.$translate.instant('COMMON.ZAHLUNG_TEXT', {
            dtype: typeText,
            datum: kontaktpersonZahlung.datum?.format('D.M.YYYY'),
            betrag: this.$filter('number')(kontaktpersonZahlung.betrag!, 2),
        });
    }
}

componentConfig.controller = DvbKontaktpersonOffenePostenListe;
angular.module('kitAdmin').component('dvbKontaktpersonOffenePostenListe', componentConfig);
