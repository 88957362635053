<div class="dvb-kinderort-fraktionen">
    <h2 data-translate="{{'COMMON.FRAKTIONEN.' + vm.kinderOrt.dtype + (vm.selectedFraktion ? '.WEITERE' : '.PLURAL')}}"
        class="big-abstand-top"
        ng-if="!vm.selectedFraktion || vm.otherFraktionen.length > 0">
    </h2>

    <div class="form-row">
        <div class="row selected-entity"
             ng-repeat="fraktion in vm.aktiveFraktionen | orderBy: 'name' | orderBy: 'familienName'">
            <div class="col-md-12 entity">
                <a ui-state="'base.fraktion.' + fraktion.dtype.toLowerCase() + '.profil'"
                   ui-state-params="{id: fraktion.id}"
                   ng-bind="fraktion.getDisplayName()">
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ul class="list-unstyled">
                <li dvb-auth require-permission="{{'kita:administrate:' + vm.kinderOrt.id}}">
                    <p ng-init="linkText = ('COMMON.FRAKTIONEN.' + vm.kinderOrt.dtype + '.ERSTELLEN' | translate)">
                        <a ui-sref="base.fraktion-erstellen.gruppe({kitaId: vm.kinderOrt.id})"
                           ng-if="vm.kinderOrt.dtype === 'KITA'">
                            + <span ng-bind="linkText"></span>
                        </a>
                        <a ui-sref="base.fraktion-erstellen.betreuungs_person({kitaId: vm.kinderOrt.id})"
                           ng-if="vm.kinderOrt.dtype === 'TAGES_ELTERN'">
                            + <span ng-bind="linkText"></span>
                        </a>
                    </p>
                </li>
                <li ng-show="!vm.showInaktiveFraktionen && vm.inaktiveFraktionen.length > 0">
                    <p>
                        <a href=""
                           ng-click="vm.showInaktiveFraktionen = true"
                           data-translate="{{'COMMON.FRAKTIONEN.' + vm.kinderOrt.dtype + '.ARCHIVIERTE_ANZEIGEN'}}">
                        </a>
                    </p>
                </li>
                <li ng-show="vm.showInaktiveFraktionen">
                    <p>
                        <a href=""
                           ng-click="vm.showInaktiveFraktionen = false"
                           data-translate="{{'COMMON.FRAKTIONEN.' + vm.kinderOrt.dtype + '.ARCHIVIERTE_AUSBLENDEN'}}">
                        </a>
                    </p>
                </li>
            </ul>
        </div>
    </div>

    <div class="form-row" ng-show="vm.showInaktiveFraktionen">
        <div class="row selected-entity" ng-repeat="fraktion in vm.inaktiveFraktionen | orderBy: 'name'">
            <div class="col-md-12 entity" ng-if="!vm.selectedFraktion || fraktion.id != vm.selectedFraktion.id">
                <a ui-state="'base.fraktion.' + fraktion.dtype.toLowerCase() + '.profil'"
                   ui-state-params="{id: fraktion.id}"
                   ng-bind="fraktion.getDisplayName()">
                </a>
            </div>
        </div>
    </div>
</div>
