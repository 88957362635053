/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {BetreuungsPerson, KinderOrtFraktionId, KinderOrtId, TarifParameter} from '@dv/kitadmin/models';
import {Kind} from '@dv/kitadmin/models';
import {DisplayNamePipe, trackById, trackByIdentity} from '@dv/shared/angular';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbUtil} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import type {Comparator} from 'comparators';
import Comparators from 'comparators';
import moment from 'moment';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {KindBetreuungErfassung} from '../../models/stundenerfassung/KindBetreuungErfassung';

@Component({
    selector: 'dv-angestellte-stundenerfassung-summary-entry',
    templateUrl: './angestellte-stundenerfassung-summary-entry.component.html',
    styleUrls: ['./angestellte-stundenerfassung-summary-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        TooltipModule,
        DisplayNamePipe,
    ],
})
export class AngestellteStundenerfassungSummaryEntryComponent implements OnChanges {

    public static readonly COMPARATOR: Comparator<TarifParameter> = Comparators
        .comparing(tarifParameter => tarifParameter.orderValue);

    @Input() public kind!: Kind;
    @Input() public betreuungen: KindBetreuungErfassung[] = [];
    @Input() public tarifParamsByKinderOrt: { [k in KinderOrtId]: Persisted<TarifParameter>[] } = {};
    @Input() public betreuungsPersonen: { [k in KinderOrtFraktionId]: BetreuungsPerson } = {};
    @Input() public firstOfMonth!: moment.Moment;
    @Input() public vertraglicheStunden: number | null = null;

    public tarifParams: Persisted<TarifParameter>[] = [];
    public cleanedBetreuungen: KindBetreuungErfassung[] = [];
    public betreuungWithBemerkung: KindBetreuungErfassung[] = [];
    public paramSums: { [paramId: string]: number } = {};
    public hourSum: number = 0;

    public trackByIdentity = trackByIdentity;
    public trackById = trackById;

    public ngOnChanges(): void {
        this.initParams();

        this.cleanedBetreuungen = DvbDateUtil.sortByStichtagAsc(
            this.betreuungen.filter(betreuung => betreuung.hasValue()),
            (betreuung: KindBetreuungErfassung) => betreuung.stichtag);

        this.betreuungWithBemerkung = DvbDateUtil.sortByStichtagAsc(
            this.betreuungen.filter(betreuung => betreuung.hasBemerkungOriginal() || betreuung.hasBemerkungCurrent()),
            (betreuung: KindBetreuungErfassung) => betreuung.stichtag);

        this.hourSum = this.cleanedBetreuungen.map(betreuung => betreuung.stunden.getValue())
            .reduce((a, b) => a! + b!, 0) ?? 0;
    }

    /**
     * Determine parameters for which there are values.
     * Also creates the sum of each params value.
     */
    private initParams(): void {
        const params = this.betreuungen.flatMap(b => {
            const kinderOrtId = this.betreuungsPersonen[b.kinderOrtFraktionId].kinderOrtId!;

            return Object.entries(b.spesenByTarifParamId)
                .filter(([paramId, spesen]) => {
                    const hasValue = spesen.hasValue();
                    if (hasValue) {
                        this.paramSums[paramId] = (this.paramSums[paramId] || 0) + spesen.getValue()!;
                    }

                    return hasValue;
                })
                .map(([paramId, _spesen]) =>
                    this.tarifParamsByKinderOrt[kinderOrtId].find(param => param.id === paramId)!);
        });
        this.tarifParams = DvbUtil.uniqueArray(params)
            .sort(AngestellteStundenerfassungSummaryEntryComponent.COMPARATOR);
    }
}
