/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {DayOfWeek, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbDateUtil, TimeRange} from '@dv/shared/code';
import {BetreuungsSchluesselRegelBedarf} from './BetreuungsSchluesselRegelBedarf';

function byPlaetzeThenByKinder(a: BetreuungsSchluesselRegelBedarf, b: BetreuungsSchluesselRegelBedarf): number {
    const plaetze = (a.fromPlaetze ?? 0) - (b.fromPlaetze ?? 0);

    if (plaetze === 0) {
        return (a.fromKinder ?? 0) - (b.fromPlaetze ?? 0);
    }

    return plaetze;
}

export class BetreuungsSchluesselRegel implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public dienstId: string | null = null,
        public dienstName: string | null = null,
        public vonBis: TimeRange = new TimeRange(),
        public dayOfWeek: DayOfWeek | null = null,
        public betreuungsSchluesselRegelBedarf: BetreuungsSchluesselRegelBedarf[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): BetreuungsSchluesselRegel {
        const vonBis = TimeRange.apiResponseTransformer(data.vonBis);
        const result = new BetreuungsSchluesselRegel(data.id, data.dienstId, data.dienstName, vonBis, data.dayOfWeek);

        if (Array.isArray(data.betreuungsSchluesselRegelBedarf)) {
            result.betreuungsSchluesselRegelBedarf = data.betreuungsSchluesselRegelBedarf.map((bedarf: any) =>
                BetreuungsSchluesselRegelBedarf.apiResponseTransformer(bedarf));
        }

        return result;
    }

    public sortRegeln(): void {
        this.betreuungsSchluesselRegelBedarf.sort(byPlaetzeThenByKinder);
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            dienstId: this.dienstId ?? null,
            vonBis: TimeRange.toApiTransformer(this.vonBis),
            dayOfWeek: this.dayOfWeek,
            betreuungsSchluesselRegelBedarf: this.betreuungsSchluesselRegelBedarf.map(bedarf => bedarf.toRestObject()),
        };
    }

    public isValid(): boolean {
        return this.betreuungsSchluesselRegelBedarf.length > 0 &&
            this.betreuungsSchluesselRegelBedarf.every(regelBedarf => regelBedarf.isValid());
    }

    public getDayOfWeekString(dayOfWeek: DayOfWeek): string {
        return DvbDateUtil.getDayOfWeekLocalized(dayOfWeek);
    }
}
