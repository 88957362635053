<dvb-accordion is-first="false">
    <panel-header class="full-width-block">
        <div class="row">
            <div class="col-xs-6">
                <p data-translate="CHECK_IN.CUSTOM_FIELD.CHECK-IN"></p>
            </div>
            <div class="col-xs-6 text-right">
                <p data-translate="COMMON.CUSTOM_FIELD.MF"
                   data-translate-value-count="{{vm.customFields.length || 0}}"
                   data-translate-value-showcount="true"
                   data-translate-interpolation="messageformat">
                </p>
            </div>
        </div>
    </panel-header>
    <panel-content>
        <form name="vm.customFieldsForm" novalidate ng-submit="vm.onSubmit()">
            <h3 data-translate="CHECK_IN.CUSTOM_FIELD.CHECK-IN" class="small-abstand-bottom"></h3>
            <div class="form-row row" ng-repeat="field in vm.customFields | orderBy: 'orderValue'">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-7">
                            <dvb-voreinstellungen-custom-field-form custom-field="field"
                                                                    field-types="vm.fieldTypes">
                            </dvb-voreinstellungen-custom-field-form>
                        </div>
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12 checkbox">
                                    <label>
                                        <input type="checkbox"
                                               ng-model="field.valuePerDay"
                                               ng-disabled="field.id">
                                        <span data-translate="CHECK_IN.CUSTOM_FIELD.DAILY"></span>
                                    </label>
                                </div>

                                <div class="col-md-12 checkbox">
                                    <label>
                                        <input type="checkbox"
                                               ng-model="field.internOnly">
                                        <span data-translate="CHECK_IN.CUSTOM_FIELD.INTERN_ONLY"></span>
                                    </label>
                                </div>

                                <div class="col-md-12 checkbox">
                                    <label>
                                        <input type="checkbox"
                                               ng-model="field.editable">
                                        <span data-translate="CHECK_IN.CUSTOM_FIELD.EDITABLE"></span>
                                    </label>
                                </div>

                                <div class="col-md-12 checkbox">
                                    <label>
                                        <input type="checkbox"
                                               ng-model="field.displayedOnOverview">
                                        <span data-translate="CHECK_IN.CUSTOM_FIELD.DISPLAYED_ON_OVERVIEW"></span>
                                    </label>
                                </div>

                                <div class="col-md-12 checkbox">
                                    <label>
                                        <input type="checkbox"
                                               ng-model="field.displayedOnDayOverview">
                                        <span data-translate="CHECK_IN.CUSTOM_FIELD.DISPLAYED_ON_DAY_OVERVIEW"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 edit form-linker">
                            <button class="btn btn-link"
                                    type="button"
                                    ng-click="vm.onDelete(field)"
                                    data-translate="COMMON.LOESCHEN">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row normal-abstand-top big-abstand-bottom">
                <div class="col-md-12">
                    <ul class="list list-inline">
                        <li>
                            <button class="btn btn-link"
                                    type="button"
                                    ng-click="vm.onAddField(namedEntityType)">
                                + <span data-translate="CUSTOM_FIELD.HINZUFUEGEN"></span>
                            </button>
                        </li>
                        <li>
                            <button class="btn btn-link"
                                    type="button"
                                    ng-click="vm.addLinkedField()">
                                + <span data-translate="CHECK_IN.CUSTOM_FIELD.LINK"></span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <dv-custom-field-sort-item [state]="vm.sortState"
                                       (closed)="vm.showSortDialog$.next(false)">
            </dv-custom-field-sort-item>
            <ul class="list-inline normal-abstand-top">
                <li>
                    <dvb-loading-button type="submit" is-loading="vm.isLoading">
                        <span data-translate="{{vm.submitLabel || 'COMMON.SPEICHERN'}}"></span>
                    </dvb-loading-button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.sortFields()"
                            data-translate="CUSTOM_FIELD.SORTIEREN"
                            ng-disabled="vm.isLoading || vm.customFields.length < 2">
                    </button>
                </li>
            </ul>
        </form>
    </panel-content>
</dvb-accordion>
