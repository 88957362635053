/*
 * Copyright © 2017 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {Rechnung} from '@dv/kitadmin/models';
import {CustomRechnungsPosition} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {
    AuthStore,
    ButtonListComponent,
    LetDirective,
    LoadingButtonComponent,
    trackByIdentity,
} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {GebuehrType} from '@dv/shared/backend/model/gebuehr-type';
import {Persisted, RechnungsPositionType} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import angular from 'angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

export interface RechnungGebuehrenBearbeitenDialogModel extends CompletableDialogModel<CustomRechnungsPosition[]> {
    rechnung: Persisted<Rechnung>;
}

@Component({
    selector: 'dv-rechnung-gebuehren-bearbeiten',
    templateUrl: './rechnung-gebuehren-bearbeiten.component.html',
    styleUrls: ['./rechnung-gebuehren-bearbeiten.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        TooltipModule,
        LoadingButtonComponent,
        LetDirective,
        ButtonListComponent,
    ],
})
export class RechnungGebuehrenBearbeitenComponent extends CompletableDialogBase<CustomRechnungsPosition[]>
    implements RechnungGebuehrenBearbeitenDialogModel, OnInit {

    @Input() public rechnung!: Persisted<Rechnung>;

    public customPositions: CustomRechnungsPosition[] = [];
    public editable: boolean = false;
    public readonly depotType = GebuehrType.DEPOT;

    public trackByIdentity = trackByIdentity;

    public constructor(
        private readonly errorService: ErrorService,
        private readonly authStore: AuthStore,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.editable = this.mayEditRechnung();

        this.customPositions = this.rechnung.customPositionen
            .filter(pos => pos.positionType === RechnungsPositionType.BETRAG)
            .map(pos => angular.copy(pos));
    }

    public submitForm(form: NgForm): void {
        this.errorService.handleValidationError(!form.invalid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (form.invalid) {
            return;
        }

        this.submit(this.customPositions);
    }

    public addGebuehr(): void {
        const gebuehr = new CustomRechnungsPosition(GebuehrType.GEBUEHR);
        gebuehr.positionType = RechnungsPositionType.BETRAG;
        gebuehr.anzahl = 1;

        this.customPositions.push(gebuehr);
    }

    public removeGebuehr(position: CustomRechnungsPosition): void {
        const i = this.customPositions.indexOf(position);
        this.customPositions.splice(i, 1);
    }

    private mayEditRechnung(): boolean {
        if (!this.rechnung.beforeRechnungsLaufLock) {
            return true;
        }

        const permission = `${PERMISSION.GRANULAR_KINDER_ORT.FAKTURA_RECHNUNGSLAUF_LOCK_OVERRIDE}${this.rechnung.kitaId}`;

        return this.authStore.hasPermission(permission);
    }
}
