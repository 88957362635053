<div class="dvb-administration-mandant-rechnungsposition row">
    <div class="col-xs-6">
        <span ng-bind="vm.rechnungsPosition.text"></span>
        <button class="btn btn-link pull-right"
                ng-click="vm.onDelete()"
                ng-if="vm.isDeletable">
            <i class="fa fa-window-close"></i>
        </button>
    </div>
    <div class="col-xs-2 text-right text-nowrap"
         data-translate="ADMINISTRATION.VALUE_X"
         data-translate-value-count="{{vm.rechnungsPosition.count}}">
    </div>
    <div class="col-xs-2 text-right text-nowrap"
         data-translate="COMMON.BETRAG_VALUE"
         data-translate-value-betrag="{{(vm.rechnungsPosition.cost) | number: 2}}">
    </div>
    <div class="col-xs-2 text-right text-nowrap"
         data-translate="COMMON.BETRAG_VALUE"
         data-translate-value-betrag="{{(vm.rechnungsPosition.cost * vm.rechnungsPosition.count) | number: 2}}">
    </div>
</div>
