/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {BetreuungsfaktorRegel} from '@dv/kitadmin/models';
import {AltersBedingterBetreuungsfaktor} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        betreuungsfaktorRegel: '<',
        isLoading: '<',
        onSubmit: '&',
        onCancel: '&',
    },
    template: require('./dvb-betreuungsfaktor-regel-form.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsfaktorRegelForm implements angular.IOnInit {
    public static $inject: readonly string[] = ['errorService'];

    public betreuungsfaktorRegel!: BetreuungsfaktorRegel;
    public isLoading!: boolean;
    public onSubmit!: () => void;
    public onCancel!: () => void;

    public showKindergartenBetreuungsfaktor: boolean = false;
    public showKibonAbzugProTag: boolean = false;
    public showKibonPauschalAbzug: boolean = false;

    public constructor(private errorService: ErrorService) {
    }

    /**
     * @return
     * - `< 0` wenn a.vonMonate < b.vonMonate
     * - `== 0` wenn b.vonMonate == a.vonMonate
     * - `> 0` wenn a.vonMonate > b.vonMonate
     */
    public static vonMonateComparatorDesc(
        a: AltersBedingterBetreuungsfaktor, b: AltersBedingterBetreuungsfaktor): number {

        return (a.vonMonate ?? 0) - (b.vonMonate ?? 0);
    }

    private static isAltersBedingteBetreuungsfaktorenValid(
        altersBedingteBetreuungsfaktoren: AltersBedingterBetreuungsfaktor[]): boolean {

        if (altersBedingteBetreuungsfaktoren.length === 0) {
            return true;
        }

        let isZeroVorhanden = false;

        for (const altersBedingteBetreuungsfaktor of altersBedingteBetreuungsfaktoren) {
            if (!altersBedingteBetreuungsfaktor.isValid()) {
                return false;
            }

            if (altersBedingteBetreuungsfaktor.vonMonate === 0) {
                isZeroVorhanden = true;
                break;
            }
        }

        return isZeroVorhanden;
    }

    public $onInit(): void {
        this.showKindergartenBetreuungsfaktor = !!this.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor
            || !!this.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor;
        this.betreuungsfaktorRegel.altersBedingteBetreuungsfaktoren
            .sort((a, b) => DvbBetreuungsfaktorRegelForm.vonMonateComparatorDesc(a, b));
        this.showKibonAbzugProTag = !!this.betreuungsfaktorRegel.kibonAbzugProTag;
        this.showKibonPauschalAbzug = !!this.betreuungsfaktorRegel.kibonInternKgPauschalAbzug;
    }

    public addAltersBedingterBetreuungsfaktor(): void {
        const faktoren = angular.copy(this.betreuungsfaktorRegel.altersBedingteBetreuungsfaktoren);
        faktoren.sort((a, b) => DvbBetreuungsfaktorRegelForm.vonMonateComparatorDesc(a, b));

        const altersBedingterBetreuungsfaktor = new AltersBedingterBetreuungsfaktor();

        // Mit 0 initialisieren, falls erster!
        if (faktoren.length === 0) {
            altersBedingterBetreuungsfaktor.vonMonate = 0;
        }
        this.betreuungsfaktorRegel.addAltersBedingterBetreuungsfaktor(altersBedingterBetreuungsfaktor);
    }

    public removeAltersBedingterBetreuungsfaktor(
        altersBedingterBetreuungsfaktor: AltersBedingterBetreuungsfaktor): void {

        this.betreuungsfaktorRegel.removeAltersBedingterBetreuungsfaktor(altersBedingterBetreuungsfaktor);
    }

    public isRemovableAltersBedingterBetreuungsfaktor(
        altersBedingterBetreuungsfaktor: AltersBedingterBetreuungsfaktor): boolean {

        return altersBedingterBetreuungsfaktor.vonMonate !== 0 ||
            this.betreuungsfaktorRegel.altersBedingteBetreuungsfaktoren.length === 1;
    }

    public submit(form: angular.IFormController): void {
        if (!this.validateRegel(form)) {
            return;
        }

        this.onSubmit();
    }

    /**
     * @return is valid
     */
    public validateRegel(form: angular.IFormController): boolean {
        const regel = this.betreuungsfaktorRegel;

        this.errorService.handleValidationError(form.$valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const isAnyBetreuungsfaktorSet = regel.altersBedingteBetreuungsfaktoren.length !== 0
            || regel.kindergarten1Betreuungsfaktor !== null
            || regel.kindergarten2Betreuungsfaktor !== null;
        this.errorService.handleValidationError(isAnyBetreuungsfaktorSet, 'ERRORS.ERR_NO_BETREUUNGSFAKTOR_SET');

        if (!isAnyBetreuungsfaktorSet) {
            return false;
        }

        const validKindergartenFaktor = !regel.isValid() ||
            !form.$error?.pattern ||
            (form.$error.pattern as any).$name !== 'kindergarten1Betreuungsfaktor' ||
            (form.$error.pattern as any).$name !== 'kindergarten2Betreuungsfaktor';

        this.errorService.handleValidationError(validKindergartenFaktor, 'ERRORS.ERR_KINDERGARTENFAKTOR_FORM');

        const validAltersBedingteBetreuungsfaktoren = DvbBetreuungsfaktorRegelForm
            .isAltersBedingteBetreuungsfaktorenValid(regel.altersBedingteBetreuungsfaktoren);
        this.errorService.handleValidationError(validAltersBedingteBetreuungsfaktoren,
            'ERRORS.ERR_ALTERS_BEDINGTE_BETREUUNGSFAKTOREN_FORM');

        return form.$valid &&
            validAltersBedingteBetreuungsfaktoren &&
            validKindergartenFaktor;
    }

    public removeKindergartenBetreuungsfaktor(): void {
        this.showKindergartenBetreuungsfaktor = false;
        this.betreuungsfaktorRegel.kindergarten1Betreuungsfaktor = null;
        this.betreuungsfaktorRegel.kindergarten2Betreuungsfaktor = null;
    }

    public addKindergartenBetreuungsfaktor(): void {
        this.showKindergartenBetreuungsfaktor = true;
    }

    public addKibonAbzugProTag(): void {
        this.showKibonAbzugProTag = true;
    }

    public removeKibonAbzugProTag(): void {
        this.betreuungsfaktorRegel.kibonAbzugProTag = null;
        this.showKibonAbzugProTag = false;
    }

    public addKibonInternKgPauschalAbzug(): void {
        this.showKibonPauschalAbzug = true;
    }

    public removeKibonInternKgPauschalAbzug(): void {
        this.betreuungsfaktorRegel.kibonInternKgPauschalAbzug = null;
        this.showKibonPauschalAbzug = false;
    }
}

componentConfig.controller = DvbBetreuungsfaktorRegelForm;
angular.module('kitAdmin').component('dvbBetreuungsfaktorRegelForm', componentConfig);
