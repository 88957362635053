<div class="dvb-limited-entity-heading">
    <div class="history-columns">
        <div class="history-column-left">
            <p>
                <dvb-gueltigkeit entity="vm.entity"></dvb-gueltigkeit>
            </p>
        </div>

        <div class="history-column-middle" ng-transclude="entityHeading"></div>
    </div>
</div>
