/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type moment from 'moment';
import type {IRestModel, IValidable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';

export class ParameterFruehSpaetDienstReport implements IRestModel, IValidable {

    public constructor(
        public kinderOrtId: string | null = null,
        public stichtag: moment.Moment | null = null,
        public fruehDienstBis: moment.Moment | null = null,
        public spaetDienstVon: moment.Moment | null = null,
    ) {
    }

    public toRestObject(): Record<string, unknown> {
        return {
            kitaId: this.kinderOrtId,
            date: DvbRestUtil.momentToLocalDate(this.stichtag),
            fruehDienstBis: DvbRestUtil.momentTolocaleHHMMTime(this.fruehDienstBis),
            spaetDienstVon: DvbRestUtil.momentTolocaleHHMMTime(this.spaetDienstVon),
        };
    }

    public isValid(): boolean {
        return !!this.kinderOrtId &&
            DvbDateUtil.isValidMoment(this.stichtag) &&
            DvbDateUtil.isValidMoment(this.fruehDienstBis) &&
            DvbDateUtil.isValidMoment(this.spaetDienstVon) &&
            DvbDateUtil.isTimeBefore(this.fruehDienstBis, this.spaetDienstVon);
    }
}
