/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RechnungsStatusUpdate, StateHistory} from '@dv/kitadmin/models';
import {momentComparator} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        stateHistory: '<',
    },
    template: require('./dvb-rechnungs-status-history.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsStatusHistory implements angular.IController {

    public stateHistory!: StateHistory;

    public history: RechnungsStatusUpdate[] = [];

    public $onInit(): void {
        this.stateHistory.updates.forEach(update => {
            if (this.isUnique(update)) {
                this.history.push(update);
            }
        });

        this.history.sort((a, b) => momentComparator(a.time, b.time));
    }

    /**
     * Eigentlich nur nötig, weil bei der Migration allen Revisionen der Rechnung eine externes StatusUpdate
     * eingefügt wurde.
     */
    private isUnique(candidate: RechnungsStatusUpdate): boolean {
        return !this.history.some(added => added.username === candidate.username &&
            added.trigger === candidate.trigger &&
            added.externalStatus === candidate.externalStatus &&
            added.time.isSame(candidate.time));
    }
}

componentConfig.controller = DvbRechnungsStatusHistory;
angular.module('kitAdmin').component('dvbRechnungsStatusHistory', componentConfig);
