/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import type angular from 'angular';
import type {KontaktpersonService} from '../../common/service/rest/kontaktpersonService';
import type {KontaktKorrespondenz} from '../../communication/models/KontaktKorrespondenz';
import type {KontaktKorrespondenzFilter} from '../../communication/models/KontaktKorrespondenzFilter';

/**
 * DataSource for loading KontaktKorrespondenz for the ui-scroll directive.
 * Requires the start-index of ui-scroll to be set to 0.
 */
export class KontaktKorrespondenzScrollDataSource
    implements angular.ui.IScrollDatasource<KontaktKorrespondenz> {

    public maxIndex: number | null = null;
    public minIndex: number = 0;

    public constructor(
        private kontaktpersonService: KontaktpersonService,
        public kontaktperson: Kontaktperson,
        public filter: KontaktKorrespondenzFilter,
    ) {
    }

    public get(index: number, count: number, success: (korrespondenz: KontaktKorrespondenz[]) => any): void {
        // Even with a ui-scroll start-index of 0, scrolling up will attempt to fetch records with negative index
        // --> ensure a positive index and adjust the count accordingly
        const realIndex = Math.max(0, index);
        const realCount = index < 0 ? index + count : count;

        if (realCount <= 0) {
            success([]);

            return;
        }

        const params = {
            top: realCount,
            skip: realIndex,
            includes: '(email_content,rechnungs_id,user_erstellt,blob_infos)',
        };

        this.kontaktpersonService.getKorrespondenz(this.kontaktperson.id!, this.filter.getStateArray(), params)
            .then(correspondenz => {
                success(correspondenz.items);
                this.maxIndex = correspondenz.count;
            });
    }
}
