<div class="dvb-kita-betreuungs-vereinbarungs-konfiguration-creation row">
    <form class="col-md-7 dvb-form"
          name="vereinbarungsConfigForm"
          ng-submit="!vereinbarungsConfigForm.$pending && vm.save(vereinbarungsConfigForm)"
          novalidate>

        <h2 data-translate="COMMON.BETREUUNGSVEREINBARUNG.CREATE_CONFIG" class="big-abstand-bottom"></h2>

        <div class="row form-row">
            <div class="col-sm-6">
                <dvb-datepicker-text-field ng-model="vm.konfiguration.gueltigAb"
                                           ng-attr-placeholder="{{'COMMON.GUELTIG_AB' | translate}}"
                                           required>
                </dvb-datepicker-text-field>
            </div>
            <div class="col-sm-6">
                <dvb-datepicker-text-field ng-model="vm.konfiguration.gueltigBis"
                                           ng-attr-placeholder="{{'FAKTURA.GUELTIG_BIS_OPTIONAL' | translate}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <div class="row form-section">
            <div class="col-md-6">
                <dvb-contextual-adresse contextual-adresse="vm.konfiguration.contextualAdresse">
                </dvb-contextual-adresse>
            </div>
            <div class="col-md-6">
                <dvb-dokument-layout on-change="vm.dokumentLayout = dokumentLayout">
                </dvb-dokument-layout>
            </div>
        </div>

        <div class="form-section">
            <h2 data-translate="COMMON.BETREUUNGSVEREINBARUNG.ALTERNATIVE_NAME"></h2>

            <dvb-translatable>
                <input type="text"
                       class="form-control"
                       ng-model="vm.konfiguration.alternativeKinderOrtName.texts[$dvbLang]"
                       dvb-translatable-input
                       placeholder="{{'LANGUAGE.NO_TEXT_DEFINED' | translate}}"
                       uib-tooltip="{{$dvbLangTranslated}}"
                       tooltip-placement="top-left"
                       maxlength="255"
                       ng-disabled="vm.konfiguration.kinderOrtNameVerstecken">
            </dvb-translatable>
        </div>

        <div class="form-section">
            <h2 data-translate="KINDERORT.EINFUEHRUNG"></h2>

            <dvb-translatable>
                 <textarea ng-model="vm.konfiguration.einfuehrung.texts[$dvbLang]"
                           dvb-translatable-input
                           class="form-control"
                           msd-elastic
                           maxlength="10000"
                           ng-attr-placeholder="{{'KINDERORT.EINFUEHRUNG' | translate}}"
                           uib-tooltip="{{$dvbLangTranslated}}"
                           tooltip-placement="top-left">
                 </textarea>
            </dvb-translatable>
        </div>
        <div class="form-section">
            <h2 data-translate="COMMON.OPTIONEN"></h2>
            <div class="form-row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.gruppeVerstecken">
                                <span data-translate="KINDERORT.IS_GRUPPE_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.platzartVerstecken">
                                <span data-translate="KINDERORT.IS_PLATZART_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.pensumVerstecken">
                                <span data-translate="KINDERORT.IS_PENSUM_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.showModuleNames">
                                <span data-translate="KINDERORT.SHOW_MODULE_NAMES"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.faktorVerstecken">
                                <span data-translate="KINDERORT.IS_FAKTOR_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.belegungZeitenVerstecken">
                                <span data-translate="KINDERORT.IS_BELEGUNG_ZEITEN_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.kinderOrtNameVerstecken">
                                <span data-translate="KINDERORT.IS_KINDERORT_NAME_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" ng-if="vm.showBetreuungBeiFamilie">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.betreuungBeiFamilie">
                                <span data-translate="KINDERORT.IS_BETREUUNG_BEI_FAMILIE"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <h3 data-translate="KINDERORT.PENSUM_DISPLAY_TYPE.TITLE"></h3>
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-value="'PCT'"
                               ng-model="vm.konfiguration.pensumDisplayType">
                        <span data-translate="KINDERORT.PENSUM_DISPLAY_TYPE.PCT"></span>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               ng-value="'TIME'"
                               ng-model="vm.konfiguration.pensumDisplayType">
                        <span data-translate="KINDERORT.PENSUM_DISPLAY_TYPE.TIME"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-section">
            <h2 data-translate="KINDERORT.KONDITIONEN"></h2>

            <dvb-translatable>
                 <textarea ng-model="vm.konfiguration.konditionen.texts[$dvbLang]"
                           dvb-translatable-input
                           class="form-control"
                           msd-elastic
                           maxlength="10000"
                           ng-attr-placeholder="{{'KINDERORT.KONDITIONEN' | translate}}"
                           uib-tooltip="{{$dvbLangTranslated}}"
                           tooltip-placement="top-left">
                 </textarea>
            </dvb-translatable>

        </div>
        <div class="form-section">
            <h2 data-translate="KINDERORT.UNTERSCHRIFT_TITEL"></h2>
            <div class="form-row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label uib-tooltip="{{'KINDERORT.UNTERSCHRIFT_TITEL_VERSTECKEN' | translate}}"
                                   tooltip-placement="top-left">
                                <input type="checkbox"
                                       value="true"
                                       ng-model="vm.konfiguration.unterschriftTitleVerstecken">
                                <span data-translate="KINDERORT.IS_UNTERSCHRIFT_TITLE_VERSTECKEN"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="checkbox">
                            <label uib-tooltip="{{'KINDERORT.HAS_UNTERSCHRIFT1' | translate}}"
                                   tooltip-placement="top-left">
                                <input type="checkbox"
                                       value="true"
                                       ng-required="vm.konfiguration.unterschrift2"
                                       ng-model="vm.konfiguration.unterschrift1"
                                       ng-disabled="vm.konfiguration.unterschrift1 && vm.konfiguration.unterschrift2">
                                <span data-translate="KINDERORT.UNTERSCHRIFT1"></span>
                            </label>
                        </div>
                        <dvb-translatable ng-if="vm.konfiguration.unterschrift1">
                            <input type="text"
                                   class="form-control"
                                   ng-model="vm.konfiguration.unterschrift1Str.texts[$dvbLang]"
                                   dvb-translatable-input
                                   placeholder="{{'LANGUAGE.NO_TEXT_DEFINED' | translate}}"
                                   uib-tooltip="{{$dvbLangTranslated}}"
                                   tooltip-placement="top-left"
                                   maxlength="255">
                        </dvb-translatable>
                    </div>
                    <div class="col-sm-6">
                        <div class="checkbox">
                            <label uib-tooltip="{{'KINDERORT.HAS_UNTERSCHRIFT2' | translate}}"
                                   tooltip-placement="top-left">
                                <input type="checkbox"
                                       value="false"
                                       ng-model="vm.konfiguration.unterschrift2"
                                       ng-disabled="!vm.konfiguration.unterschrift1 && !vm.konfiguration.unterschrift2">
                                <span data-translate="KINDERORT.UNTERSCHRIFT2"></span>
                            </label>
                        </div>
                        <div ng-if="vm.konfiguration.unterschrift2">
                            <dvb-translatable>
                                <input type="text"
                                       class="form-control"
                                       ng-model="vm.konfiguration.unterschrift2Str.texts[$dvbLang]"
                                       dvb-translatable-input
                                       placeholder="{{'LANGUAGE.NO_TEXT_DEFINED' | translate}}"
                                       uib-tooltip="{{$dvbLangTranslated}}"
                                       tooltip-placement="top-left"
                                       maxlength="255">
                            </dvb-translatable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-section">
            <h2 data-translate="DOKUMENT_LAYOUT.FUSSZEILE"></h2>

            <dvb-translatable>
                 <textarea ng-model="vm.konfiguration.fusszeile.texts[$dvbLang]"
                           dvb-translatable-input
                           class="form-control"
                           msd-elastic
                           maxlength="1000"
                           ng-attr-placeholder="{{'DOKUMENT_LAYOUT.FUSSZEILE' | translate}}"
                           uib-tooltip="{{$dvbLangTranslated}}"
                           tooltip-placement="top-left">
                 </textarea>
            </dvb-translatable>
        </div>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   on-cancel="vm.cancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>

