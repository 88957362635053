<div class="dvb-personal-main">

    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{'PERSONAL.PERSONAL_SETTINGS' | translate}}"
                    tooltip-placement="top-left"
                    data-translate="PERSONAL.PERSONAL_SETTINGS"></h1>
                <div class="header-dropdown" dvb-auth require-prmission="personal:administrate:general" uib-dropdown>
                    <a href="" class="header-box-attachment dropdown-toggle" uib-dropdown-toggle>
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu" uib-dropdown-menu>
                        <li ui-sref-active-eq="active" dvb-auth require-role="SUPPORT_ADMIN">
                            <a ui-sref="{{vm.personalStates.AUSBILDUNGEN_STATE.name}}"
                               data-translate="PERSONAL.AUSBILDUNGEN.PLURAL">
                            </a>
                        </li>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="{{vm.personalStates.KONFIGURATION_STATE.name}}"
                               data-translate="PERSONAL.KONFIGURATION.SINGULAR">
                            </a>
                        </li>
                        <li ui-sref-active-eq="active">
                            <a ui-sref="{{vm.personalStates.TERMIN_TYPES_STATE.name}}"
                               data-translate="PERSONAL.TERMIN.TYPE.TITLE">
                            </a>
                        </li>
                    </ul>
                </div>
                <ul class="list-inline header-breadcrumb">
                    <li ui-sref-active-eq="active"
                        dvb-auth
                        require-permission="{{['feature:stundenerfassung_tfo:general', 'kita:manage:general']}}">
                        <a ui-sref="{{vm.personalStates.STUNDENBLAETTER_STATE.name}}"
                           data-translate="PERSONAL.STUNDENBLAETTER.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active">
                        <a ui-sref="{{vm.personalStates.ANGESTELLTE_LIST_STATE.name}}"
                           data-translate="PERSONAL.ANGESTELLTE.PLURAL">
                        </a>
                    </li>
                    <li ui-sref-active-eq="active" dvb-auth require-permission="personal:manage:general">
                        <a ui-sref="{{vm.personalStates.BETREUUNGS_SCHLUESSEL_LIST_STATE.name}}"
                           data-translate="PERSONAL.BETREUUNGS_SCHLUESSEL.PLURAL">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- CONTENT from sub-states -->
    <div ui-view></div>

</div>
