<div class="collapsible-panel-container"
     ng-class="{'first-container':vm.isFirst, 'last-container': vm.isLast, 'compact-panel': vm.compact}">
    <div class="folding-icon floating-text" ng-hide="vm.isDisabled">
        <a href="" ng-click="vm.isCollapsed = !vm.isCollapsed">
            <i class="fa"
               ng-class="{'fa-chevron-right': vm.isCollapsed, 'fa-chevron-down': !vm.isCollapsed}"></i>
        </a>
    </div>
    <div class="header-and-content">
        <div ng-if="vm.isCollapsed || vm.isDisabled" ng-transclude="header"></div>
        <div ng-if="!vm.isCollapsed && !vm.isDisabled" ng-transclude="content"></div>
    </div>
</div>
