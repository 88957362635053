/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import {GENERAL_SUFFIX, UserRole} from '@dv/shared/roles';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {firstValueFrom} from 'rxjs';
import type {ListType} from '../common/component/dvb-entity-list/dvb-entity-list';
import {trans} from '../common/i18n/trans';
import {ANSTELLUNG_STATES} from './anstellung/anstellung-states';
import type {Ausbildung} from './anstellung/models/Ausbildung';
import type {AusbildungService} from './anstellung/service/ausbildungService';
import {BetreuungsSchluessel} from './betreuungs-schluessel/models/BetreuungsSchluessel';
import type {BetreuungsSchluesselService} from './betreuungs-schluessel/service/betreuungsSchluesselService';
import type {PersonalKonfigurationApiService} from './konfiguration/personal-konfiguration-api.service';
import {PersonalKonfiguration} from './konfiguration/PersonalKonfiguration';
import {TerminTypesComponent} from './termin/termin-type/termin-types/termin-types.component';

/* eslint-disable sonarjs/no-duplicate-string */
export const PERSONAL_BASE_STATE = {
    name: 'base.personal',
    url: '/personal',
    abstract: true,
    data: {
        permission: PERMISSION.MODULE.PERSONALPLANUNG,
    },
} satisfies Ng1StateDeclaration;

export const PERSONAL_MAIN_STATE = {
    name: `${PERSONAL_BASE_STATE.name}.einstellungen`,
    url: '/einstellungen?currentPage&itemsPerPage',
    params: {
        currentPage: {value: '1', squash: true},
        itemsPerPage: {value: '10', squash: true},
    },
    abstract: true,
    component: 'dvbPersonalMain',
    resolve: {
        currentPage: ['$transition$', ($transition$: Transition): string => $transition$.params().currentPage],
        itemsPerPage: ['$transition$', ($transition$: Transition): string => $transition$.params().itemsPerPage],
    },
} satisfies Ng1StateDeclaration;

export const ANGESTELLTE_LIST_STATE = {
    name: `${PERSONAL_MAIN_STATE.name}.angestellte-list`,
    url: '/angestellte',
    component: 'dvbEntityList',
    resolve: {
        type: (): ListType => 'angestellte',
        titleKey: () => 'PERSONAL.ANGESTELLTE.MF',
        baseState: () => ANSTELLUNG_STATES.ANGESTELLTE_PROFIL_STATE.name,
        baseStateCreate: () => ANSTELLUNG_STATES.ANGESTELLTE_CREATE_STATE.name,
        createLinkTitleKey: () => 'PERSONAL.ANGESTELLTE.CREATE',
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'PERSONAL.ANGESTELLTE.PLURAL'),
        ],
    },
    data: {
        permission: {oneOf: [['feature:stundenerfassung_tfo:general', 'kita:view:general'], 'personal:view:general']},
    },
} satisfies Ng1StateDeclaration;

export const BETREUUNGS_SCHLUESSEL_FORM_STATE = {
    name: `${PERSONAL_BASE_STATE.name}.betreuungsSchluesselForm`,
    url: '/betreuungsschluessel/bearbeiten/:id',
    params: {
        id: '',
    },
    template: '<dvb-betreuungs-schluessel betreuungs-schluessel="$resolve.betreuungsSchluessel" ' +
        'ausbildungen="$resolve.ausbildungen"></dvb-betreuungs-schluessel>',
    resolve: {
        betreuungsSchluessel: [
            'betreuungsSchluesselService', '$transition$', '$q', (
                betreuungsSchluesselService: BetreuungsSchluesselService,
                $transition$: Transition,
                $q: angular.IQService,
            ): angular.IPromise<BetreuungsSchluessel> => {
                if ($transition$.params().id) {
                    return betreuungsSchluesselService.get($transition$.params().id, {includes: '(regeln)'});
                }

                return $q.resolve(new BetreuungsSchluessel());
            },
        ],
        ausbildungen: [
            'ausbildungService', (
                ausbildungService: AusbildungService,
            ): angular.IPromise<Ausbildung[]> => ausbildungService.getAll(),
        ],
        $title: [
            'betreuungsSchluessel', (betreuungsSchluessel: BetreuungsSchluessel): string =>
                betreuungsSchluessel.getDisplayName(),
        ],
    },
    data: {
        permission: PERMISSION.PERSONAL.ADMINISTRATE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const BETREUUNGS_SCHLUESSEL_STATE = {
    name: `${PERSONAL_BASE_STATE.name}.betreuungs-schluessel-detail`,
    url: '/betreuungsschluessel/{id:[0-9]+}',
    component: 'dvbBetreuungsSchluesselOverview',
    resolve: {
        betreuungsSchluessel: [
            'betreuungsSchluesselService', '$transition$', (
                betreuungsSchluesselService: BetreuungsSchluesselService,
                $transition$: Transition,
            ): angular.IPromise<BetreuungsSchluessel> =>
                betreuungsSchluesselService.get($transition$.params().id, {includes: '(regeln, names, editable)'}),
        ],
    },
    data: {
        permission: PERMISSION.PERSONAL.MANAGE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const BETREUUNGS_SCHLUESSEL_LIST_STATE = {
    name: `${PERSONAL_MAIN_STATE.name}.betreuungsschluessel`,
    url: '/betreuungsschluessel',
    component: 'dvbEntityList',
    resolve: {
        type: (): ListType => 'betreuungsSchluessel',
        titleKey: () => 'PERSONAL.BETREUUNGS_SCHLUESSEL.MF',
        baseState: () => BETREUUNGS_SCHLUESSEL_STATE.name,
        baseStateCreate: () => BETREUUNGS_SCHLUESSEL_FORM_STATE.name,
        createLinkTitleKey: () => 'PERSONAL.BETREUUNGS_SCHLUESSEL.CREATE',
        createPermission: () => BETREUUNGS_SCHLUESSEL_FORM_STATE.data.permission,
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'PERSONAL.BETREUUNGS_SCHLUESSEL.PLURAL'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const STUNDENBLAETTER_STATE = {
    name: `${PERSONAL_MAIN_STATE.name}.stundenblaetter`,
    url: '/stundenblaetter',
    component: 'dvbStundenBlaetter',
    data: {
        permission: [PERMISSION.FEATURE.STUNDENERFASSUNG_TFO + GENERAL_SUFFIX, PERMISSION.KITA.MANAGE_ANY],
    },
} satisfies Ng1StateDeclaration;

export const AUSBILDUNGEN_STATE = {
    name: `${PERSONAL_MAIN_STATE.name}.ausbildungen`,
    url: '/ausbildungen',
    component: 'dvbAusbildungenMain',
    resolve: {
        ausbildungen: [
            'ausbildungService', (
                ausbildungService: AusbildungService,
            ): angular.IPromise<Ausbildung[]> => ausbildungService.getAll(),
        ],
    },
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },
} satisfies Ng1StateDeclaration;

export const KONFIGURATION_STATE = {
    name: `${PERSONAL_MAIN_STATE.name}.konfiguration`,
    url: '/konfiguration',
    component: 'dvbPersonalKonfigurationMain',
    resolve: {
        konfigurationen: [
            'personalKonfigurationApiService', (
                personalKonfigurationService: PersonalKonfigurationApiService,
            ): angular.IPromise<PersonalKonfiguration[]> =>
                firstValueFrom(personalKonfigurationService.getForMandant$()),
        ],
        newKonfiguration: [() => new PersonalKonfiguration()],
    },
    data: {
        subtitle: 'PERSONAL.KONFIGURATION.SINGULAR',
        permission: PERMISSION.PERSONAL.ADMINISTRATE_ANY,
    },
} satisfies Ng1StateDeclaration;

export const TERMIN_TYPES_STATE = {
    name: `${PERSONAL_MAIN_STATE.name}.termintypes`,
    url: '/termintypes',
    component: TerminTypesComponent,
    data: {
        subtitle: 'PERSONAL.TERMIN_TYPE.SINGULAR',
        permission: PERMISSION.PERSONAL.MANAGE_ANY,
    },
} satisfies Ng2StateDeclaration;

export const PERSONAL_STATES = {
    PERSONAL_BASE_STATE,
    PERSONAL_MAIN_STATE,
    ANGESTELLTE_LIST_STATE,
    BETREUUNGS_SCHLUESSEL_FORM_STATE,
    BETREUUNGS_SCHLUESSEL_STATE,
    BETREUUNGS_SCHLUESSEL_LIST_STATE,
    STUNDENBLAETTER_STATE,
    AUSBILDUNGEN_STATE,
    KONFIGURATION_STATE,
    TERMIN_TYPES_STATE,
};
