/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitaTarifeZeitraum} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kitaId: '<',
        tarifeZuweisungen: '<',
        onDelete: '&',
        onRevert: '&',
    },
    template: require('./dvb-kita-tarife-verlauf.html'),
    controllerAs: 'vm',
};

export class DvbKitaTarifeVerlauf implements angular.IController {

    public kitaId!: EntityId;
    public tarifeZuweisungen: Persisted<KitaTarifeZeitraum>[] = [];
    public onDelete?: (data: { kitaTarifeZeitraum: Persisted<KitaTarifeZeitraum> }) => unknown;
    public onRevert?: (data: { kitaTarifeZeitraum: Persisted<KitaTarifeZeitraum> }) => unknown;

    public today = DvbDateUtil.today();

    public isTarifeZeitraumBeendet(tarifeZeitraum: Persisted<KitaTarifeZeitraum>): boolean {
        return !DvbDateUtil.isEndOfTime(tarifeZeitraum.gueltigBis);
    }

    public revertKitaTarifeZeitraum(tarifeZeitraum: Persisted<KitaTarifeZeitraum>): void {
        if (TypeUtil.isFunction(this.onRevert)) {
            this.onRevert({kitaTarifeZeitraum: tarifeZeitraum});
        }
    }
}

componentConfig.controller = DvbKitaTarifeVerlauf;
angular.module('kitAdmin').component('dvbKitaTarifeVerlauf', componentConfig);
