<div class="dvb-kibon-betreuungen-kind">
    <dvb-page items="vm.dummyPageItems" is-loading="vm.isLoading">
        <h2 data-translate="KIBON.MUTATION.PENDENTE_MELDUNGEN"></h2>

        <form name="mutationslaufForm" ng-submit="vm.submit()" novalidate dvb-accordions>
            <p ng-if="vm.betreuungenPending.length === 0"
               class="normal-abstand-bottom"
               data-translate="KIBON.MUTATION.KEINE_PENDENTE_MELDUNGEN">
            </p>

            <div ng-repeat="betreuung in vm.betreuungenPending">
                <dvb-accordion is-first="$first" allow-multiple="true" remove-toggled-dom="true">
                    <panel-header class="full-width-block">
                        <div class="layout-row items-center row">
                            <div class="col-xs-5">
                                <div class="checkbox">
                                    <label ng-click="$event.stopPropagation();">
                                        <input type="checkbox"
                                               class="smaller-abstand-right"
                                               ng-disabled="!betreuung.isMutierbar() || betreuung.referenzNummerIgnoriert || !vm.hasKitaManagePrivileges"
                                               ng-model="vm.selected[betreuung.id]">
                                        <strong ng-bind="betreuung.kinderOrt.getDisplayName()"
                                                ng-focus="$event.stopPropagation()"
                                                uib-tooltip="{{betreuung.kinderOrt.getDisplayName()}}"
                                                tooltip-placement="top-left">
                                        </strong>
                                        <i class="fa fa-exclamation-circle"
                                           ng-if="betreuung.ausserordentlicherBetreuungsaufwand"
                                           uib-tooltip="{{'KIBON.MUTATION.AUSSERORDENTLICHER_BETREUUNGSAUFWAND' | translate}}"
                                           tooltip-append-to-body="true">
                                        </i>
                                    </label>
                                </div>
                            </div>
                            <div class="col-xs-4">
                                <dvb-tasks-badge label="{{'KIBON.MUTATION.TRIGGER.' + betreuung.trigger}}"
                                                 popover-title="{{'KIBON.MUTATION.STATUS_TOOLTIP_MF' | translate:{status: betreuung.status, provisorisch: betreuung.austrittProvisorisch || betreuung.belegungProvisorisch}:'messageformat'}}"
                                                 tasks="betreuung.tasks"
                                                 warning="!betreuung.isMutierbar()">
                                </dvb-tasks-badge>
                            </div>
                            <div class="col-xs-3 text-right">
                                <dvb-kibon-ref-nummer kibon-ref-nr-info="betreuung"
                                                      referenz-nummer-ignoriert="betreuung.referenzNummerIgnoriert">
                                </dvb-kibon-ref-nummer>
                            </div>
                        </div>
                    </panel-header>
                    <panel-content>
                        <dvb-kibon-betreuung-detail betreuung="betreuung"></dvb-kibon-betreuung-detail>
                    </panel-content>
                    <panel-footer>
                        <button dvb-auth
                                require-permission="kita:manage:general"
                                on-unauthorised-disable
                                class="btn btn-link"
                                type="button"
                                ng-click="vm.ignore(betreuung)"
                                data-translate="KIBON.MUTATION.IGNORIEREN">
                        </button>
                    </panel-footer>
                </dvb-accordion>
            </div>

            <ul dvb-auth
                require-permission="kita:manage:general"
                require-condition="vm.betreuungenPending.length > 0"
                class="list-inline normal-abstand-top">
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.selectAll()"
                            ng-disabled=" vm.isLoading"
                            data-translate="COMMON.ALLE_AUSWAEHLEN">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.selectNone()"
                            ng-disabled=" vm.isLoading"
                            data-translate="COMMON.KEINE_AUSWAEHLEN">
                    </button>
                </li>
            </ul>

            <ul dvb-auth
                require-permission="kita:manage:general"
                on-unauthorised-disable
                class="list-inline big-abstand-top">
                <li ng-if="vm.betreuungenPending.length > 0">
                    <dvb-loading-button type="submit"
                                        ng-disabled="vm.getSelectionCount() === 0 || vm.isLoading">
                        <span data-translate="KIBON.MUTATION.MELDEN"></span>
                    </dvb-loading-button>
                </li>
            </ul>
        </form>

        <div dvb-accordions allow-multiple="true">
            <h2 data-translate="KIBON.MUTATION.GESENDETE_MELDUNGEN" class="big-abstand-top"></h2>

            <p ng-if="vm.betreuungenSent.length === 0"
               class="normal-abstand-bottom"
               data-translate="KIBON.MUTATION.KEINE_GESENDETE_MELDUNGEN">
            </p>

            <div ng-repeat="betreuung in vm.betreuungenSent">
                <dvb-accordion is-first="$first" remove-toggled-dom="true">
                    <panel-header class="full-width-block">
                        <div class="layout-row items-center row">
                            <div class="col-xs-9">
                                <strong data-translate="KIBON.MUTATION.GESENDET_UM"
                                        data-translate-value-time="{{betreuung.uebermittlungsTimestamp | amDateFormat:'D.M.YYYY HH:mm:ss'}}"
                                        data-translate-value-benutzer="{{betreuung.benutzerDisplayName}}"
                                        uib-tooltip="{{betreuung.kinderOrt.getDisplayName()}}"
                                        tooltip-placement="top-left">
                                </strong>
                                <i class="fa fa-exclamation-circle"
                                   ng-if="betreuung.ausserordentlicherBetreuungsaufwand"
                                   uib-tooltip="{{'KIBON.MUTATION.AUSSERORDENTLICHER_BETREUUNGSAUFWAND' | translate}}"
                                   tooltip-append-to-body="true">
                                </i>
                            </div>
                            <div class="col-xs-3 text-right">
                                <dvb-kibon-ref-nummer kibon-ref-nr-info="betreuung"
                                                      referenz-nummer-ignoriert="betreuung.referenzNummerIgnoriert">
                                </dvb-kibon-ref-nummer>
                            </div>
                        </div>
                    </panel-header>
                    <panel-content>
                        <dvb-kibon-betreuung-detail betreuung="betreuung"></dvb-kibon-betreuung-detail>
                    </panel-content>
                </dvb-accordion>
            </div>
            <div dvb-accordions allow-multiple="true">
                <h2 data-translate="KIBON.MUTATION.IGNORIERTE_MELDUNGEN" class="big-abstand-top"></h2>

                <p ng-if="vm.betreuungenIgnored.length === 0"
                   class="normal-abstand-bottom"
                   data-translate="KIBON.MUTATION.KEINE_IGNORIERTE_MELDUNGEN">
                </p>

                <div ng-repeat="betreuung in vm.betreuungenIgnored">
                    <dvb-accordion is-first="$first" remove-toggled-dom="true">
                        <panel-header class="full-width-block">
                            <div class="layout-row items-center row">
                                <div class="col-xs-9">
                                    <strong ng-bind="betreuung.kinderOrt.getDisplayName()"
                                            data-translate="KIBON.MUTATION.IGNORED_UM"
                                            data-translate-value-time="{{betreuung.timestampMutiert | amDateFormat:'D.M.YYYY HH:mm:ss'}}"
                                            data-translate-value-benutzer="{{betreuung.benutzerDisplayName}}"
                                            uib-tooltip="{{betreuung.kinderOrt.getDisplayName()}}"
                                            tooltip-placement="top-left">
                                    </strong>
                                    <i class="fa fa-exclamation-circle"
                                       ng-if="betreuung.ausserordentlicherBetreuungsaufwand"
                                       uib-tooltip="{{'KIBON.MUTATION.AUSSERORDENTLICHER_BETREUUNGSAUFWAND' | translate}}"
                                       tooltip-append-to-body="true">
                                    </i>
                                </div>
                                <div class="col-xs-3 text-right">
                                    <dvb-kibon-ref-nummer kibon-ref-nr-info="betreuung"
                                                          referenz-nummer-ignoriert="betreuung.referenzNummerIgnoriert">
                                    </dvb-kibon-ref-nummer>
                                </div>
                            </div>
                        </panel-header>
                        <panel-content>
                            <dvb-kibon-betreuung-detail betreuung="betreuung"></dvb-kibon-betreuung-detail>
                        </panel-content>
                        <panel-footer>
                            <button dvb-auth
                                    require-permission="kita:manage:general"
                                    on-unauthorised-disable
                                    class="btn btn-link"
                                    type="button"
                                    ng-click="vm.unIgnore(betreuung)"
                                    data-translate="COMMON.UNIGNORIEREN">
                            </button>
                        </panel-footer>
                    </dvb-accordion>
                </div>
            </div>
        </div>
    </dvb-page>
</div>
