<div class="dvb-monats-belegung-select-gruppe btn-group"
     uib-dropdown
     data-keyboard-nav
     ng-init="hasOtherChoice = (vm.gruppen | filter: {id: '!' + vm.currentGruppe.id}).length > 0">
    <button type="button"
            tabindex="1"
            class="btn btn-link btn-link-dropdown dropdown-toggle btn-margin-sm"
            ng-class="{'inactive-row-button': !vm.active}"
            uib-dropdown-toggle>
        <span ng-bind="vm.currentGruppe.getDisplayName()"></span>
        <span class="caret" ng-if="hasOtherChoice"></span>
    </button>
    <ul class="dropdown-menu"
        uib-dropdown-menu
        role="menu"
        ng-if="hasOtherChoice">
        <li ng-repeat="gruppe in vm.gruppen | filter: {id: '!' + vm.currentGruppe.id} | orderBy: 'name' | orderBy: 'familienName'">
            <a href="" ng-click="vm.selectGruppe({gruppe: gruppe})" ng-bind="gruppe.getDisplayName()"></a>
        </li>
    </ul>
</div>
