<div>
    <div class="modal-body">
        <h1 [translate]="'FAKTURA.KONTENABGLEICH.TRANSAKTION_ZUWEISUNG_LOESCHEN'"></h1>
    </div>
    <div class="modal-footer">
        <div>
            <dvlib-button-list>
                <div class="btn-group" dropdown>
                    <div dropdownToggle>
                        <dvlib-loading-button [type]="'button'" [isLoading]="isLoading$ | async">
                            <span [translate]="'COMMON.LOESCHEN'"></span>&nbsp;<span class="caret"></span>
                        </dvlib-loading-button>
                    </div>
                    <ul class="dropdown-menu" *dropdownMenu>
                        <li>
                            <button class="btn btn-link btn-link-dropdown"
                                    type="button"
                                    [translate]="'FAKTURA.KONTENABGLEICH.ZUWEISUNG_LOESCHEN'"
                                    (click)="submit(bankStatementEntryStatus.NICHT_ZUGEWIESEN)">
                            </button>
                        </li>
                        <li>
                            <button class="btn btn-link btn-link-dropdown"
                                    type="button"
                                    [translate]="'FAKTURA.KONTENABGLEICH.TRANSAKTION_LOESCHEN_IGNORIEREN'"
                                    (click)="submit(bankStatementEntryStatus.IGNORIERT)">
                            </button>
                        </li>
                    </ul>
                </div>
                <button class="btn btn-link" [translate]="'COMMON.ABBRECHEN'" (click)="hide()"></button>
            </dvlib-button-list>
        </div>
    </div>
</div>
