/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IRechnungsKonfiguration, RechnungsKonfiguration} from '@dv/kitadmin/models';
import {RechnungsKonfigurationStatus} from '@dv/kitadmin/models';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '<',
        isNew: '<',
        konten: '<',
    },
    template: require('./dvb-rechnungs-konfiguration-form.html'),
    controllerAs: 'vm',
};

export class DvbRechnungsKonfigurationForm implements angular.IController {

    public static $inject: readonly string[] = [];

    public konfiguration?: RechnungsKonfiguration<IRechnungsKonfiguration>;
    public isNew: boolean = false;
    public konten: JaxKonto[] = [];

    public isDisabled: boolean = false;
    public konfigurationenTypen: typeof RechnungsKonfigurationType = RechnungsKonfigurationType;

    public $onInit(): void {
        this.isDisabled = checkPresent(this.konfiguration).status === RechnungsKonfigurationStatus.DISABLED;
    }

    public toggleDisabled(): void {
        checkPresent(this.konfiguration).status = this.isDisabled ?
            RechnungsKonfigurationStatus.DISABLED :
            RechnungsKonfigurationStatus.ACTIVE;
    }
}

componentConfig.controller = DvbRechnungsKonfigurationForm;
angular.module('kitAdmin').component('dvbRechnungsKonfigurationForm', componentConfig);
