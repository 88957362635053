/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BankStatementEntry, Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {BankStatementService} from '../../../service/bankStatementService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        entry: '<',
    },
    template: require('./dvb-bank-statement-entry-assing.html'),
    controllerAs: 'vm',
};

// TODO: Migrate to Dialog, once the template can be migrated to Angular
export class DvbBankStatementEntryAssign implements angular.IController {

    public static $inject: readonly string[] = ['$state', 'bankStatementService'];

    public readonly rechnung!: Persisted<Rechnung>;
    public readonly entry!: Persisted<BankStatementEntry>;

    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.TRANSAKTION;
    public ausstehenderBetrag: number | null = null;
    public showWarning: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private readonly $state: StateService,
        private readonly bankStatementService: BankStatementService,
    ) {
    }

    public $onInit(): void {
        this.ausstehenderBetrag = this.bankStatementService.calcAusstehenderBetrag(this.entry, this.rechnung);
        this.showWarning = !this.bankStatementService.checkZuweisung(this.entry, this.rechnung);
    }

    public submit(): void {
        this.bankStatementService.assignZahlung(this.entry.id, this.rechnung.id).then(() => {
            this.$state.go('base.kontenabgleich.transaktionen');
        });

    }

    public goBack(): void {
        this.$state.go('^');
    }
}

componentConfig.controller = DvbBankStatementEntryAssign;
angular.module('kitAdmin').component('dvbBankStatementEntryAssign', componentConfig);
