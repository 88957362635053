<div class="dvb-image-upload">
    <div class="form-row" ng-show="vm.ngModelCtrl.$modelValue">
        <div class="row selected-entity">

            <div class="col-xs-8 entity">
                <!-- Vorschau, vor dem Speichern -->
                <div ng-if="vm.tempBlob.file">
                    <img alt=""
                         ngf-src="vm.tempBlob.file"
                         ngf-resize="{width: 250, height: 250, quality: 1}"
                         ngf-resize-if="$width > 250 || height > 250}">
                </div>

                <!-- Vorschau eines gespeicherten Bildes -->
                <dvb-image-blob-preview ng-if="!vm.tempBlob.file"
                                        image-blob="vm.ngModelCtrl.$modelValue">
                </dvb-image-blob-preview>

                <uib-progressbar max="100"
                                 value="vm.ngModelCtrl.$modelValue.progress"
                                 type="success"
                                 ng-show="vm.ngModelCtrl.$modelValue.isInProgress()">
                </uib-progressbar>
            </div>
            <div class="col-xs-4 edit">
                <a href="" ng-click="vm.reset()" data-translate="COMMON.LOESCHEN"></a>
            </div>

        </div>
    </div>

    <!-- TODO Parameter von aussen konfigurierbar machen: vielleicht wie dvb-editable-label? -->
    <a href=""
       ngf-select="vm.upload(file, $invalidFiles, {width: 1650, height: 1650})"
       ngf-drop="vm.upload(file, $invalidFiles)"
       ng-model="file"
       ngf-pattern="'.jpg,.jpeg,.gif,.png'"
       ngf-accept="'image/*'"
       ngf-max-size="10MB"
       ngf-min-height="50"
       ngf-min-width="50"
       ngf-min-ratio="1:20"
       ngf-max-ratio="20:1"
       ngf-resize="{}"
       ng-if="!vm.ngModelCtrl.$modelValue"
       data-translate="{{vm.uploadLabel || 'COMMON.BILD_HOCHLADEN'}}">
    </a>
</div>
