<div class="dvb-zahlung-ausgleichen row big-abstand-top">
    <div class="col-md-6 col-md-offset-3">
        <h2 data-translate="FAKTURA.RECHNUNG_AUSGLEICHEN"
            data-translate-value-betrag="{{-vm.ausstehenderBetrag | number: 2}}"
            data-translate-value-type="{{'COMMON.' + vm.zahlungType | translate}}">
        </h2>

        <form name="ueberschussAusgleichenForm"
              ng-submit="!ueberschussAusgleichenForm.$pending && ueberschussAusgleichenForm.$valid && vm.submit()"
              class="dvb-form"
              novalidate>
            <div class="form-section-minus-normal-abstand">
                <div class="row">
                    <div class="col-md-6">
                        <dvb-datepicker-text-field ng-model="vm.date"
                                                   required
                                                   ng-attr-placeholder="{{'COMMON.DATUM' | translate}}">
                        </dvb-datepicker-text-field>
                    </div>
                </div>
            </div>

            <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                       submit-label="FAKTURA.AUSGLEICHEN"
                                       on-cancel="vm.goBack()">
            </dvb-submit-cancel-buttons>
        </form>

    </div>
</div>
