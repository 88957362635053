<div class="dvb-multiple-files-upload">
    <div ng-if="vm.allowMultiple">
        <div ng-show="vm.ngModelCtrl.$modelValue.length">
            <div class="row selected-entity" ng-repeat="file in vm.ngModelCtrl.$modelValue | orderBy: 'filename'">
                <div class="col-xs-8 entity">
                    <span ng-class="{'virus-found' : file.errorMessage === 'ERRORS.ERR_UPLOAD_FAILED'}"
                          ng-bind="file.filename"></span>
                    <uib-progressbar max="100"
                                     value="file.progress"
                                     type="success"
                                     ng-show="file.isInProgress()">
                    </uib-progressbar>
                </div>
                <div class="col-xs-4 edit">
                    <a href="" ng-click="vm.onRemoveFile(file)" data-translate="COMMON.LOESCHEN"></a>
                </div>
            </div>
        </div>
    </div>


    <a href=""
       ngf-select="vm.uploadFiles(files, $invalidFiles)"
       ngf-drop="vm.uploadFiles(files, $invalidFiles)"
       ng-model="files"
       data-ngf-multiple="{{vm.allowMultiple}}"
       ngf-max-size="vm.maxSize"
       ngf-max-total-size="vm.maxTotalSize"
       ngf-max-files="10"
       ngf-pattern="vm.allowedExtensions"
       ng-if="vm.allowMultiple || vm.ngModelCtrl.$modelValue === null">
        + <span data-translate="{{vm.labelUpload}}"></span>
    </a>
</div>
