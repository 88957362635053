<div class="dvb-dashboard-favoriten-suche">
    <div class="favorit-search-input">
        <dvb-search-entity placeholder-key="DASHBOARD.FAVORIT_SUCHEN"
                           on-select-clear="true"
                           only-named="true"
                           on-select="vm.onSelect({item: item})"
                           disabled-entries="vm.favoriteEntities"
                           focus="vm.focus">
        </dvb-search-entity>
    </div>
    <div class="cancel-button small-abstand-left">
        <a href="" data-translate="COMMON.ABBRECHEN" ng-click="vm.onCancel()"></a>
    </div>
</div>
