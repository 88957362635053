<div class="dvb-konto-auszuege">

    <div class="filter-bar big-abstand-bottom">
        <ul class="list-inline small-abstand-bottom">
            <li>
                <dvb-period-selector ng-model="vm.filter.gueltigkeit"
                                     ng-change="vm.updatePage(1)">
                </dvb-period-selector>
            </li>
            <li class="pull-right text-right" dvb-auth require-permission="kita:manage:general">
                <a ui-sref="base.kontenabgleich.import"
                   data-translate="FAKTURA.KONTENABGLEICH.DATEIEN_HOCHLADEN"></a>
            </li>
        </ul>
    </div>

    <div class="row">
        <div class="col-md-9 col-lg-7 big-abstand-bottom">

            <dvb-pages items="vm.pageContainer.items"
                       total-items="vm.pageContainer.count"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'FAKTURA.KONTENABGLEICH.KONTOAUSZUEGE_MF'"
                       on-update-page="vm.updatePage(page)"
                       on-update-items-per-page="vm.onUpdateItemsPerPage(itemsPerPage)">
                <page-items dvb-accordions ng-if="vm.pageContainer.count > 0">

                    <div class="table-header">
                        <div class="row">
                            <div class="col-xs-4" data-translate="FAKTURA.KONTENABGLEICH.ZEITRAUM"></div>
                            <div class="col-xs-3" data-translate="FAKTURA.KONTENABGLEICH.EINLESEDATUM"></div>
                            <div class="col-xs-3" data-translate="FAKTURA.KONTENABGLEICH.DATEINAME"></div>
                            <div class="col-xs-2" data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTIONEN"></div>
                        </div>
                    </div>

                    <dvb-accordion ng-repeat="bankStatement in vm.pageContainer.items"
                                   enable-content="false"
                                   is-first="$first">
                        <panel-header class="full-width-block">
                            <div class="row">
                                <div class="col-xs-4">
                                    <span ng-bind="bankStatement.gueltigAb | amDateFormat:'DD.MM.YYYY'"></span>
                                    -
                                    <span ng-bind="bankStatement.gueltigBis | amDateFormat:'DD.MM.YYYY'"></span>
                                </div>
                                <div class="col-xs-3"
                                     ng-bind="bankStatement.einleseDatum | amDateFormat:'DD. MMMM YYYY'">
                                </div>
                                <div class="col-xs-3 truncate"
                                     ng-bind="bankStatement.filename"
                                     title="{{bankStatement.filename}}">
                                </div>
                                <div class="col-xs-2" ng-bind="bankStatement.entryCount"></div>
                            </div>
                        </panel-header>
                        <panel-footer class="full-width-block">
                            <ul class="list-inline list-inline-row">
                                <li>
                                    <a ui-sref="base.kontenabgleich.transaktionen(vm.getTransaktionenStateParams(bankStatement))"
                                       data-translate="FAKTURA.KONTENABGLEICH.TRANSAKTIONEN_ANSEHEN">
                                    </a>
                                </li>
                                <li dvb-auth require-permission="kita:betreuung_admin:*">
                                    <button class="btn btn-link"
                                            ng-click="vm.deleteBankStatement(bankStatement)"
                                            data-translate="COMMON.LOESCHEN">
                                    </button>
                                </li>
                                <li ng-if="vm.isShowingDownloads">
                                    <dvb-loading-button type="button"
                                                        is-loading="vm.loadingDownload"
                                                        as-link="true"
                                                        spinner-right="true"
                                                        on-click="vm.downloadBankStatement(bankStatement)">
                                        <span data-translate="FAKTURA.KONTENABGLEICH.HERUNTERLADEN"></span>
                                    </dvb-loading-button>
                                </li>
                            </ul>
                        </panel-footer>
                    </dvb-accordion>
                </page-items>
            </dvb-pages>
        </div>
    </div>

    <!-- This is a hidden toggle for the download links -->
    <a accesskey="r" ng-click="vm.isShowingDownloads = !vm.isShowingDownloads"></a>
</div>
