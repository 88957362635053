<div class="dvb-schliesstage-erstellen">
    <div class="row dvb-form">
        <div class="col-md-6 col-md-offset-3">
            <h1 data-translate="COMMON.SCHLIESSTAGE.ERSTELLEN"></h1>

            <form name="erstellenForm" ng-submit="vm.submit()" novalidate>
                <div class="form-section-minus-normal-abstand">
                    <dvb-schliesstage-form schliesstage="vm.schliesstage"
                                          on-submit="vm.submit()"
                                          on-cancel="vm.cancel()"
                                          is-loading="vm.isloading">
                    </dvb-schliesstage-form>
                </div>
            </form>
        </div>
    </div>
</div>
