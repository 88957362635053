/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kontaktperson, RelationshipWithKontaktperson} from '@dv/kitadmin/models';
import {KindKontakteUtil} from '@dv/kitadmin/models';
import type {FunctionType} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontakte: '<',
        uneditableAdresse: '<?',
        adressDatum: '<',
        onAddKontakt: '&',
        onRemoveKontakt: '&',
    },
    template: require('./dvb-anmeldung-kontakte.html'),
    controllerAs: 'vm',
};

export class DvbAnmeldungKontakte implements angular.IController, angular.IOnChanges {
    public static $inject: readonly string[] = ['$scope', 'errorService', 'mandantConfigurationService'];

    public kontakte!: RelationshipWithKontaktperson[];
    public uneditableAdresse?: boolean;
    public adressDatum!: moment.Moment;

    public onAddKontakt!: FunctionType;
    public onRemoveKontakt!: FunctionType;

    public hauptkontakt: RelationshipWithKontaktperson | null = null;
    public kontaktpersonen: Kontaktperson[] = [];

    public constructor(
        private readonly $scope: angular.IScope,
        private readonly errorService: ErrorService,
        private readonly mandantConfigurationService: MandantConfigurationService,
    ) {
        this.$scope.$watchCollection('vm.kontakte', () => {
            this.initHauptkontakt();
        });
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.kontakte) {
            this.initHauptkontakt();
            this.updateKontaktpersonen();
        }
    }

    public addKontakt(kontakt: RelationshipWithKontaktperson): void {
        this.onAddKontakt({kontakt});
        this.updateKontaktpersonen();
    }

    public isNotHauptkontakt(kontakt: RelationshipWithKontaktperson): boolean {
        return !kontakt.relationship.isHauptkontakt();
    }

    public showRechnungsAufteilungForm(): boolean {
        return KindKontakteUtil.findRechnungsempfaenger(this.kontakte).length > 1;
    }

    public initHauptkontakt(): void {
        const hauptkontaktRelationships = KindKontakteUtil.findHauptkontaktRelationshipsWithKontaktperson(
            this.kontakte);
        if (hauptkontaktRelationships.length === 1) {
            this.hauptkontakt = hauptkontaktRelationships[0];
        }
    }

    public updateKontaktpersonen(): void {
        this.kontaktpersonen = this.kontakte.map(kontakt => {
            return checkPresent(kontakt.kontaktperson);
        });
    }

    public setAsHauptkontakt(kontakt: RelationshipWithKontaktperson): void {
        if (!kontakt.hasAdresse()) {
            if (kontakt.isNewKontaktperson()) {
                if (this.hauptkontakt?.isNewKontaktperson()) {
                    // Adresse des Hauptkontaktes hierhin setzen
                    kontakt.kontaktperson!.adressen.push(this.hauptkontakt.kontaktperson!.adressen[0]);
                    this.hauptkontakt.kontaktperson!.adressen.splice(0);
                } else {
                    this.mandantConfigurationService.createDefaultLimitedAdresse(this.adressDatum)
                        .then(adresse => {
                            kontakt.kontaktperson!.adressen.push(adresse);
                        });
                }
            } else {
                this.errorService.addValidationError('ERRORS.ERR_HAUPTKONTAKT_REQUIRES_ADDRESS');

                return;
            }
        }

        if (this.hauptkontakt) {
            this.hauptkontakt.relationship.deleteHauptkontakt();
        }
        kontakt.relationship.createHauptkontakt();
        this.hauptkontakt = kontakt;
    }

    public removeHauptkontakt(): void {
        this.onRemoveKontakt({kontakt: this.hauptkontakt});

        if (this.kontakte.length > 0) {
            this.hauptkontakt = this.kontakte[0];
            this.hauptkontakt.relationship.createHauptkontakt();
        } else {
            this.hauptkontakt = null;
        }

        this.updateKontaktpersonen();
    }

    public addKontaktAsHauptkontakt(kontakt: RelationshipWithKontaktperson): void {
        if (!kontakt.isNewKontaktperson() && kontakt.kontaktperson!.adressen.length === 0) {
            this.errorService.addValidationError('ERRORS.ERR_HAUPTKONTAKT_REQUIRES_ADDRESS');

            return;
        }

        kontakt.relationship.createHauptkontakt();
        kontakt.relationship.createErziehungsberechtigter();
        kontakt.relationship.createRechnungsempfaenger();
        kontakt.relationship.createAbholberechtigt();
        if (kontakt.isNewKontaktperson()) {
            this.mandantConfigurationService.createDefaultLimitedAdresse(this.adressDatum).then(adresse => {
                kontakt.kontaktperson!.adressen.push(adresse);
            });
        }

        this.addKontakt(kontakt);
        this.initRechnungsempfaenger(kontakt);
        this.hauptkontakt = kontakt;
        this.updateKontaktpersonen();
    }

    public createRechnungsempfaenger(kontakt: RelationshipWithKontaktperson): void {
        kontakt.relationship.createRechnungsempfaenger();
        kontakt.relationship.rechnungsempfaenger!.value = 0;
        this.initRechnungsempfaenger(kontakt);
    }

    public deleteRechnungsempfaenger(kontakt: RelationshipWithKontaktperson): void {
        kontakt.relationship.deleteRechnungsempfaenger();
        this.initRechnungsempfaenger(kontakt);
    }

    public changeRechnungsempfaengerValue(kontakt: RelationshipWithKontaktperson): void {
        this.initRechnungsempfaenger(kontakt);
    }

    private initRechnungsempfaenger(kontakt: RelationshipWithKontaktperson): void {
        KindKontakteUtil.recalculateProzent(this.kontakte, kontakt);
    }
}

componentConfig.controller = DvbAnmeldungKontakte;

angular.module('kitAdmin').component('dvbAnmeldungKontakte', componentConfig);
