<div class="dvb-administration-custom-rechnung-position-form">
    <form class="dvb-form"
          name="customRechnungPositionForm"
          ng-submit="vm.submit(customRechnungPositionForm)"
          novalidate>
        <div class="row" ng-repeat="position in vm.customPositionen">
            <div class="col-xs-4">
                <input type="text"
                       class="form-control"
                       name="customPositionText"
                       maxlength="255"
                       ng-attr-placeholder="{{'COMMON.POSITION' | translate}}"
                       ng-model="position.text"
                       tooltip-placement="top-left"
                       uib-tooltip="{{'COMMON.POSITION' | translate}}"
                       ng-disabled="position.type && position.type !== 'MANUAL'"
                       required>
            </div>
            <div class="col-xs-2 text-right text-nowrap">
                <input type="number"
                       class="form-control"
                       name="customPositionCount"
                       ng-attr-placeholder="0.00"
                       ng-change="vm.calculateTotal(position)"
                       ng-model="position.count"
                       ng-pattern="/^-{0,1}\d*(\.\d{0,2})?$/"
                       tooltip-placement="top-left"
                       uib-tooltip="{{'COMMON.ANZAHL' | translate}}"
                       ng-disabled="position.type && position.type !== 'MANUAL'"
                       required>
            </div>
            <div class="col-xs-2 text-right text-nowrap">
                <input type="number"
                       class="form-control"
                       name="customPositionCost"
                       ng-attr-placeholder="0.00"
                       ng-change="vm.calculateTotal(position)"
                       ng-model="position.cost"
                       ng-pattern="/^-{0,1}\d*(\.\d{0,2})?$/"
                       tooltip-placement="top-left"
                       uib-tooltip="{{'COMMON.BETRAG' | translate}}"
                       ng-disabled="position.type && position.type !== 'MANUAL'"
                       required>
            </div>
            <div class="col-xs-2 text-right text-nowrap">
                <input type="number"
                       class="form-control"
                       name="customPositionTotal"
                       ng-attr-placeholder="0.00"
                       ng-value="vm.totalByPosition.get(position)"
                       tooltip-placement="top-left"
                       uib-tooltip="{{'COMMON.TOTAL' | translate}}"
                       disabled>
            </div>
            <div class="col-xs-2 form-linker">
                <button type="button"
                        class="btn btn-link"
                        ng-click="vm.removePosition(position)"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </div>
        </div>
        <div class="row normal-abstand-top big-abstand-bottom">
            <div class="col-md-6">
                <a href=""
                   ng-click="vm.addPosition()">
                    + <span data-translate="ADMINISTRATION.CUSTOM_RECHNUNG_POSITION_HINZUFUEGEN"></span>
                </a>
            </div>
        </div>
        <dvb-submit-cancel-buttons is-loading="vm.formContext.isLoading"
                                   on-cancel="vm.onCancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
