<div class="dvb-ausbildungen">
    <div>
        <label>
            <span ng-bind="vm.ausbildung.name"></span>
        </label>
    </div>
    <div class="children small-abstand-left">
        <dvb-ausbildungen ng-repeat="child in vm.ausbildung.children" ausbildung="child"></dvb-ausbildungen>
    </div>
</div>
