/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isPresent} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        items: '<',
        isLoading: '<',
        entityName: '<?',
    },
    template: require('./dvb-page.html'),
    controllerAs: 'vm',
};

export class DvbPage implements angular.IController {

    public static $inject: readonly string[] = [];

    public items: unknown[] = [];
    public isLoading: boolean = false;
    public entityName?: string;

    public hasItems: boolean = false;

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.items) {
            this.hasItems = Array.isArray(changes.items.currentValue) && changes.items.currentValue.length > 0;
        }

        if (isPresent(changes.entityName) && !changes.entityName.currentValue.endsWith('MF')) {
            throw Error(`entityName name must be of messageformat type and as such end with "MF". Incorrect value: ${
                this.entityName}`);
        }
    }
}

componentConfig.controller = DvbPage;
angular.module('kitAdmin').component('dvbPage', componentConfig);
