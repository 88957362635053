<div class="dvb-kita-faktura-massenaktionen big-abstand-bottom">
    <h2 data-translate="FAKTURA.MASSENAKTIONEN.TITLE"></h2>
    <p ng-if="vm.kitaRechnungen.rechnungen.length < 1" data-translate="KINDERORT.KEINE_RECHNUNGEN_AUSGEWAEHLT"></p>
    <div ng-if="vm.kitaRechnungen.rechnungen.length > 0"  class="btn-group" uib-dropdown>
        <button type="button" class="btn btn-link btn-link-dropdown dropdown-toggle" uib-dropdown-toggle>
            <span data-translate="COMMON.AKTIONEN"></span>
            <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
            <li>
                <a href=""
                   data-translate="FAKTURA.MASSENAKTIONEN.EMAILS_VERSENDEN"
                   ng-click="vm.sendRechnungEmail()">
                </a>
                <a href=""
                   data-translate="FAKTURA.MASSENAKTIONEN.STORNIEREN"
                   ng-click="vm.stornieren()">
                </a>
            </li>
        </ul>
    </div>
</div>
