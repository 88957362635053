/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ITimeRange, Persisted, TimeRange} from '@dv/shared/code';
import {DvbDateUtil, isNullish, isPresent, TimeRangeUtil} from '@dv/shared/code';
import type {Dienst} from '../../../../personal/konfiguration/Dienst';
import type {ZuweisungZeit} from '../../../../personal/model/ZuweisungZeit';

export function findMatchingDienst(
    dienste: Persisted<Dienst>[],
    range: ITimeRange,
    pauseZeiten: ITimeRange[],
): Persisted<Dienst> | undefined {

    const pausen = pauseZeiten.filter(pause => TimeRangeUtil.contains(range, pause));

    return dienste.find(dienst => TimeRangeUtil.isSame(dienst, range) && matchesPause(dienst, pausen));
}

function matchesPause(dienst: Persisted<Dienst>, pausen: ITimeRange[]): boolean {
    if (isNullish(dienst.pauseVon) && isNullish(dienst.pauseBis)) {
        return pausen.length === 0;
    }

    const dienstPauseDuration = DvbDateUtil.getTimeDiff(dienst.pauseVon!, dienst.pauseBis!);

    return pausen.some(pause => dienstPauseDuration === DvbDateUtil.getTimeDiff(pause.von!, pause.bis!));
}

export function findDienst(
    zuweisungZeit: ZuweisungZeit,
    pauseZeiten: TimeRange[],
    dienste: Persisted<Dienst>[],
    otherFraktionZeiten: ITimeRange[],
    otherFraktionPausen: ITimeRange[],
): Persisted<Dienst> | undefined {
    const dienst = findMatchingDienst(dienste, zuweisungZeit, pauseZeiten);
    if (isPresent(dienst)) {
        return dienst;
    }

    // attempt to use dienst from otherFraktionZeiten
    const fraktionZeit = otherFraktionZeiten.find(zeit => TimeRangeUtil.contains(zeit, zuweisungZeit));

    return isPresent(fraktionZeit) ? findMatchingDienst(dienste, fraktionZeit, otherFraktionPausen) : undefined;
}
