<div class="dvb-belegung">
    <div class="action-header">
        <dvb-belegung-interval-header belegung-interval="vm.belegung"
                                      class="flex-grow-1"
                                      is-date-clickable="vm.isDateClickable"
                                      no-date="vm.noDate"
                                      title-key="vm.titleKey"
                                      on-date-selected="vm.onDateSelected({date: vm.belegung.gueltigAb}); $event.stopPropagation();">
        </dvb-belegung-interval-header>
        <div class="actions">
            <ng-transclude></ng-transclude>
        </div>
    </div>
    <div class="group-container">

        <p data-translate="KIND.KEINE_BELEGUNG"
           ng-if="vm.belegung.gruppenBelegungen.length === 0">
        </p>

        <div class="container-item"
             ng-class="{'normal-abstand-bottom':!$last}"
             ng-repeat="gruppenBelegung in vm.belegung.gruppenBelegungen">
            <div class="description">
                <p ng-bind="vm.gruppen[gruppenBelegung.gruppeId].getDisplayName()"></p>
                <p ng-bind="vm.kitas[gruppenBelegung.gruppeId].getDisplayName()"></p>

                <div class="pensum">
                    <div ng-bind="(gruppenBelegung.pensum | number: 0) + '%'"></div>
                </div>
                <div class="vertragliches-pensum"
                     ng-repeat="vertraglichesPensum in gruppenBelegung.vertraglichePensen">
                    <span ng-bind="vm.getVertraglichesPensumSuffix(vertraglichesPensum)"
                          uib-tooltip="{{vertraglichesPensum.type === 'PERCENTAGE' ? ('KIND.ZUWEISUNG.VERTRAGLICHE_STUNDEN.PERCENTAGE.TITLE' | translate) : undefined}}"
                          tooltip-placement="top-left">
                    </span>
                </div>
                <p class="truncate"
                   ng-bind="gruppenBelegung.bemerkung"
                   ng-if="vm.showBemerkungen && gruppenBelegung.bemerkung"
                   uib-tooltip="{{gruppenBelegung.bemerkung}}"
                   tooltip-placement="top-left">
                </p>
            </div>

            <div class="wochenplan-wrapper">
                <dvb-wochenplan class="small"
                                distinct-betreuungs-zeitraeume="vm.gruppen[gruppenBelegung.gruppeId].wochenplan.distinctZeitraeume"
                                interactive="false"
                                show-null-value-as-plain="true"
                                week-days="vm.enabledDays"
                                zeitraum-felder="vm.gruppen[gruppenBelegung.gruppeId].wochenplan.zeitraumFelder"
                                show-betreuungs-zeitraum-name="vm.gruppen[gruppenBelegung.gruppeId].wochenplan.showBetreuungsZeitraumName">
                </dvb-wochenplan>

                <dvb-belegung-zeiten class="small-abstand-top"
                                     week-days="vm.enabledDays"
                                     belegung-zeiten="gruppenBelegung.belegungZeiten">
                </dvb-belegung-zeiten>
            </div>
        </div>

        <div class="container-item normal-abstand-top" ng-if="vm.hasCustomFieldValue">
            <dvb-custom-field-values class="description"
                                     custom-field-values="vm.belegung.customFieldValues"
                                     ng-disabled="true">
            </dvb-custom-field-values>
        </div>

        <p class="truncate small-abstand-top"
           ng-bind="vm.belegung.bemerkung"
           ng-if="vm.showBemerkungen && vm.belegung.bemerkung"
           uib-tooltip="{{vm.belegung.bemerkung}}"
           tooltip-placement="top-left">
        </p>

        <div class="container-item normal-abstand-top"
             ng-if="(!vm.hideIcons && (vm.showKindergarten() || vm.betreuungsfaktor || vm.belegung.noFlexiblePlaetze)) || vm.showBetreuungsVereinbarung">
            <ul class="inline-icons full-width-inline-list">
                <li ng-if="!vm.hideIcons && vm.showKindergarten()">
                    <dvb-kindergarten-icon kindergarten-belegung="{{vm.belegung.kindergartenBelegung}}">
                    </dvb-kindergarten-icon>
                </li>
                <li ng-if="!vm.hideIcons && vm.betreuungsfaktor">
                    <dvb-betreuungsfaktor-icon faktor="vm.betreuungsfaktor"></dvb-betreuungsfaktor-icon>
                </li>
                <li ng-if="!vm.hideIcons && vm.belegung.noFlexiblePlaetze">
                    <i class="fa fa-calendar-times-o"
                       uib-tooltip="{{'KIND.ZUWEISUNG.NO_FLEXIBLE_PLAETZE' | translate}}">
                    </i>
                </li>
                <li class="align-right hidden-print" ng-if="vm.showBetreuungsVereinbarung">
                    <div class="btn-group" data-keyboard-nav ng-if="vm.getKitas().length > 1" uib-dropdown>
                        <dvb-loading-button as-link="true"
                                            dropdown="true"
                                            is-loading="vm.isLoadingBetreuungsVereinbarung"
                                            margin="false"
                                            type="button"
                                            uib-dropdown-toggle>
                            <span data-translate="COMMON.BETREUUNGSVEREINBARUNG.DOWNLOAD"></span>
                            <span class="caret"></span>
                        </dvb-loading-button>
                        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                            <li ng-repeat="kita in vm.getKitas()">
                                <a href=""
                                   ng-bind="kita.getDisplayName()"
                                   ng-click="vm.getBetreuungsVereinbarungType(kita.id)">
                                </a>
                            </li>
                        </ul>
                    </div>
                    <dvb-loading-button as-link="true"
                                        dropdown="false"
                                        is-loading="vm.isLoadingBetreuungsVereinbarung"
                                        margin="false"
                                        ng-if="vm.getKitas().length === 1"
                                        on-click="vm.getBetreuungsVereinbarungType(vm.getKitas()[0].id)"
                                        type="button">
                        <span data-translate="COMMON.BETREUUNGSVEREINBARUNG.DOWNLOAD"></span>
                    </dvb-loading-button>
                </li>
            </ul>
        </div>
    </div>
</div>
