<div class="dvb-geburtstage-report">
    <h2 data-translate="REPORT.GEBURTSTAGSLISTE_KINDER.TITLE"></h2>

    <form name="vm.geburtstageReportForm" novalidate>

        <dvb-report-template-selector report-state="vm.reportState"
                                      on-selection-change="vm.reportTemplate = reportTemplate">
        </dvb-report-template-selector>

        <div ng-show="!vm.gruppeSearchResultEntry.gruppeId">
            <div class="row">
                <div class="col-xs-12">
                    <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                                  ng-model="vm.kinderOrt"
                                                  ng-change="vm.updateKinderOrt()"
                                                  ng-required="!vm.gruppeSearchResultEntry"
                                                  placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                                  as-col="true">
                    </dvb-search-get-single-entity>
                </div>
            </div>
        </div>
        <div ng-show="!vm.kinderOrt.kinderOrtId">
            <div class="row">
                <div class="col-xs-12">
                    <dvb-search-get-single-entity entity-to-search="KINDERORT_FRAKTION"
                                                  ng-model="vm.gruppeSearchResultEntry"
                                                  ng-change="vm.updateFraktion()"
                                                  ng-required="!vm.kinderOrt"
                                                  display-label="COMMON.GRUPPE.SINGULAR"
                                                  placeholder-key="COMMON.GRUPPE.AUSWAEHLEN"
                                                  as-col="true">
                    </dvb-search-get-single-entity>
                </div>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-12 small-abstand-bottom">
                <label>
                    <input type="checkbox" ng-model="vm.withDateRange">
                    <span data-translate="COMMON.DATE_RANGE"></span>
                </label>
            </div>
            <div ng-if="vm.withDateRange">
                <dvb-datepicker-text-field ng-model="vm.gueltigAb"
                                           ng-attr-placeholder="{{'COMMON.VON' | translate}}: {{vm.gueltigAb || vm.defaultGueltigAb | amDateFormat: 'DD.MM.YYYY'}}"
                                           class="col-xs-6">
                </dvb-datepicker-text-field>
                <dvb-datepicker-text-field ng-model="vm.gueltigBis"
                                           ng-attr-placeholder="{{'COMMON.VON' | translate}}: {{vm.gueltigBis || vm.defaultGueltigBis | amDateFormat: 'DD.MM.YYYY'}}"
                                           class="col-xs-6">
                </dvb-datepicker-text-field>

            </div>

            <div ng-if="!vm.withDateRange" class="col-xs-12">
                <dvb-datepicker-text-field ng-model="vm.stichtag"
                                           ng-change="vm.updateStichtag()"
                                           ng-attr-placeholder="{{'REPORT.STICHTAG' | translate}}: {{vm.stichtag || vm.defaultStichtag | amDateFormat: 'DD.MM.YYYY'}}">
                </dvb-datepicker-text-field>
            </div>
        </div>

        <dvb-report-download on-submit="vm.onSubmit()"></dvb-report-download>
    </form>
</div>
