/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxKinderOrtZuweisungen} from '@dv/shared/backend/model/jax-kinder-ort-zuweisungen';
import type {JaxPersonalSortOrder} from '@dv/shared/backend/model/jax-personal-sort-order';
import {checkPersisted, checkPresent, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import {Angestellte} from '../anstellung/models/Angestellte';
import {AngestellteZuweisungen} from './AngestellteZuweisungen';
import {FraktionZuweisungen} from './FraktionZuweisungen';

export class KinderOrtZuweisungen {

    public constructor(
        public angestellte: { [id: string]: Angestellte } = {},
        public fraktionZuweisungen: FraktionZuweisungen[] = [],
        public kinderOrtZuweisungen: AngestellteZuweisungen[] = [],
        public angestellteWithoutZuweisung: AngestellteZuweisungen[] = [],
        public sortOrder: JaxPersonalSortOrder[] = [],
    ) {
    }

    public static apiResponseTransformer(data: JaxKinderOrtZuweisungen): KinderOrtZuweisungen {
        const angestellte = DvbRestUtil.transformArray(data.angestellte, Angestellte)
            .map(checkPersisted);
        const angestellteMap = DvbUtil.mapById(angestellte);

        return new KinderOrtZuweisungen(
            angestellteMap,
            data.fraktionZuweisungen.map(jax => FraktionZuweisungen.apiResponseTransformer(jax, angestellteMap)),
            data.kinderOrtZuweisungen.map(jax =>
                AngestellteZuweisungen.apiResponseTransformer(jax, checkPresent(angestellteMap[jax.angestellteId]))),
            data.angestellteWithoutZuweisung.map(jax =>
                AngestellteZuweisungen.apiResponseTransformer(jax, checkPresent(angestellteMap[jax.angestellteId]))),
            data.sortOrder,
        );
    }
}
