<div class="dvb-gruppe-profil row dvb-form">
    <!-- LEFT column -->
    <div class="col-md-7 big-abstand-bottom">
        <dvb-fraktion-kapazitaet fraktion="vm.gruppe"></dvb-fraktion-kapazitaet>
    </div>

    <!-- RIGHT Column -->
    <div class="col-md-4 col-md-offset-1">
        <!-- STAMMDATEN -->
        <dvb-gruppe-stammdaten gruppe="vm.gruppe"></dvb-gruppe-stammdaten>
    </div>
</div>
