/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {DokumentLayout, TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate, SearchResultEntry} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import moment from 'moment';
import type {CustomFieldConfiguration} from '../../models';
import {ERR_VALIDATION, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        reportState: '<',
        showReportTemplate: '<?',
        showStichtag: '<?',
        showYear: '<?',
        year: '<?',
        showDateRange: '<?',
        showLayout: '<?',
        initSchoolYear: '<?',
        limitDateRangeToMonths: '<?',
        kinderOrt: '<?',
        preSelectableKinderOrt: '<?',
        stichtag: '<?',
        layout: '<?',
        gueltigAb: '<?',
        gueltigBis: '<?',
        params: '<?',
        showIncludeExtraPlaetze: '<?',
        showCustomFieldConfiguration: '<?',
    },
    template: require('./dvb-kita-report.html'),
    controllerAs: 'vm',
};

export class DvbKitaReport implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public readonly showReportTemplate: boolean = true;
    public readonly showStichtag: boolean | null = null;
    public readonly showLayout: boolean | null = null;
    public readonly showYear: boolean | null = null;
    public readonly showDateRange: boolean | null = null;
    public readonly showCustomFieldConfiguration: boolean | null = null;
    public readonly initSchoolYear: boolean = false;
    public readonly limitDateRangeToMonths: boolean = false;
    public kinderOrt: SearchResultEntry | null = null;
    public preSelectableKinderOrt: SearchResultEntry | null = null;
    public layout: DokumentLayout | null = null;
    public stichtag: moment.Moment | null = null;
    public year: number | null = null;
    public gueltigAb: moment.Moment | null = null;
    public gueltigBis: moment.Moment | null = null;
    public params: any = null;
    public reportTemplate: ReportTemplate | null = null;
    public customFieldConfiguration: CustomFieldConfiguration | null = null;
    public showIncludeExtraPlaetze: boolean = false;

    public readonly kitaReportForm!: angular.IFormController;

    public defaultStichtag: moment.Moment = DvbDateUtil.today();
    public defaultGueltigAb: moment.Moment = DvbDateUtil.today();
    public defaultGueltigBis: moment.Moment = DvbDateUtil.today();
    public includeExtraPlaetze: boolean = true;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        if (this.preSelectableKinderOrt && !this.kinderOrt) {
            this.kinderOrt = this.preSelectableKinderOrt;
        }

        if (this.limitDateRangeToMonths && !this.initSchoolYear) {
            this.defaultGueltigAb = DvbDateUtil.startOfMonth(moment());
            this.defaultGueltigBis = DvbDateUtil.endOfMonth(moment());
            this.gueltigAb = this.gueltigAb ? DvbDateUtil.startOfMonth(this.gueltigAb) : null;
            this.gueltigBis = this.gueltigBis ? DvbDateUtil.endOfMonth(this.gueltigBis) : null;
        }

        if (!this.initSchoolYear) {
            return;
        }

        const schoolStartMonth = 7;
        if (moment().month() >= schoolStartMonth) {
            this.defaultGueltigAb = DvbDateUtil.startOfMonth(moment().month(schoolStartMonth));
            this.defaultGueltigBis = DvbDateUtil.endOfMonth(moment().add(1, 'year').month(schoolStartMonth - 1));
        } else {
            this.defaultGueltigAb = DvbDateUtil.startOfMonth(moment().subtract(1, 'year').month(schoolStartMonth));
            this.defaultGueltigBis = DvbDateUtil.endOfMonth(moment().month(schoolStartMonth - 1));
        }
    }

    public updateKinderOrt(): void {
        this.reportService.updateParameterKinderOrt(this.kinderOrt);
    }

    public updateState(): void {
        const params: { [key: string]: any } = {};

        if (this.showStichtag) {
            params.stichtag = this.stichtag;
        }

        if (this.showDateRange) {
            params.gueltigAb = this.gueltigAb;
            params.gueltigBis = this.gueltigBis;
        }

        if (this.showYear) {
            params.year = this.year;
        }

        this.reportService.updateStateParams(params);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.kitaReportForm.$valid;
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    // eslint-disable-next-line complexity
    public getDownloadPromise(): angular.IPromise<TempBlob> {
        const params = this.getParamsObject();

        switch (this.reportState) {
            case ReportState.CONTROLLING.BELEGUNGSPLAN_PLATZART:
                return this.reportService.getTempBlobForBelegungsplanPlatzartReport(params);
            case ReportState.CONTROLLING.CONTROLLING:
                return this.reportService.getTempBlobForControllingReport(params);
            case ReportState.BETRIEB.AKTUELLE_AENDERUNGEN:
                return this.reportService.getTempBlobForAktuelleAenderungenReport(params);
            case ReportState.CONTROLLING.MONATSBLATT_AUSZUG:
                return this.reportService.getTempBlobForMonatsblattAuszugReport(params);
            case ReportState.CONTROLLING.LEISTUNGEN_KALKULIERT:
                return this.reportService.getTempBlobForLeistungenKalkuliertReport(params);
            case ReportState.CONTROLLING.BELEGTE_PROZENT_PUNKTE:
                return this.reportService.getTempBlobForBelegteProzentPunkteReport(params);
            case ReportState.CONTROLLING.TARIFRECHNUNG_REPORTING_KALKULIERT:
                return this.reportService.getTempBlobForTarifrechnungReportingKalkuliertReport(params);
            case ReportState.CONTROLLING.STUNDENBLATT:
                return this.reportService.getTempBlobForStundenblattReport(params);
            case ReportState.CONTROLLING.BSV_PRAESENZKONTROLLE:
                return this.reportService.getTempBlobForBsvPraesenzkontrolleReport(params);
            case ReportState.CONTROLLING.ANWESENHEITSSOLL_VERBRAUCH:
                return this.reportService.getTempBlobForAnwesenheitssoll(params);
            case ReportState.CONTROLLING.STUNDENBASIERTES_CONTROLLING:
                return this.reportService.getTempBlobForStundenbasiertesControlling(params);
            case ReportState.CONTROLLING.BETREUUNGS_STUNDEN_KONTROLLE:
                return this.reportService.getTempBlobForBetreuungsStundenKontrolle(params);
            default:
                throw new Error(`Report download not implemented: ${JSON.stringify(this.reportState)}`);
        }
    }

    private getParamsObject(): any {
        const params = this.params ?? {};

        this.applyStichtag(params);
        this.applyYear(params);
        this.applyGueltigkeit(params);
        this.applyLayout(params);
        this.applyIncludeExtraPlaetze(params);
        this.applyCustomFieldConfiguration(params);

        params.kinderOrtId = checkPresent(this.kinderOrt).id;
        params.reportTemplate = this.reportTemplate;

        return params;
    }

    private applyLayout(params: { [index: string]: any }): void {
        if (this.showLayout) {
            params.layoutId = this.layout ? this.layout.id : '';
        }
    }

    private applyStichtag(params: { [index: string]: any }): void {
        if (this.showStichtag) {
            params.stichtag = this.stichtag ?? this.defaultStichtag;
        }
    }

    private applyYear(params: { [index: string]: any }): void {
        if (this.showYear) {
            params.year = this.year ?? this.defaultStichtag.year();
        }
    }

    private applyGueltigkeit(params: { [index: string]: any }): void {
        if (this.showDateRange) {
            params.gueltigAb = this.gueltigAb ?? this.defaultGueltigAb;
            params.gueltigBis = this.gueltigBis ?? this.defaultGueltigBis;
        }
    }

    private applyIncludeExtraPlaetze(params: { [index: string]: any }): void {
        if (this.showIncludeExtraPlaetze) {
            params.includeExtraPlaetze = this.includeExtraPlaetze;
        }
    }

    private applyCustomFieldConfiguration(params: { [index: string]: any }): void {
        if (this.showCustomFieldConfiguration) {
            params.customFieldConfiguration = this.customFieldConfiguration;
        }
    }
}

componentConfig.controller = DvbKitaReport;
angular.module('kitAdmin').component('dvbKitaReport', componentConfig);
