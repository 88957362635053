/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Pensum} from '@dv/kitadmin/models';
import {PensumType} from '@dv/kitadmin/models';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        pensum: '<',
        pensumType: '<?',
        validate: '<?',
        smallInputs: '<?',
    },
    template: require('./dvb-pensum.html'),
    controllerAs: 'vm',
};

export class DvbPensum implements angular.IController {
    public static $inject: readonly string[] = [];

    public pensum!: Pensum;
    public pensumType: PensumType = PensumType.PERCENTAGE;
    public validate: boolean = true;
    public smallInputs: boolean = false;

    public minVal?: number;

    public $onChanges(): void {
        this.minVal = this.validate ? 1 : undefined;
    }
}

componentConfig.controller = DvbPensum;
angular.module('kitAdmin').component('dvbPensum', componentConfig);
