/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {BetreuungsfaktorRegel} from '@dv/kitadmin/models';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {MandantService} from '../../../common/service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-betreuungsfaktorregel-erstellen.html'),
    controllerAs: 'vm',
};

export class DvbBetreuungsfaktorregelErstellen {
    public static $inject: readonly string[] = ['$state', 'errorService', 'mandantService', 'dvbStateService'];

    public isLoading: boolean = false;
    public betreuungsfaktorRegel: BetreuungsfaktorRegel = new BetreuungsfaktorRegel();

    public constructor(
        private $state: StateService,
        private errorService: ErrorService,
        private mandantService: MandantService,
        private dvbStateService: DvbStateService,
    ) {
    }

    public submit(): void {
        const isValid = this.betreuungsfaktorRegel.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return;
        }

        this.isLoading = true;

        this.mandantService.createBetreuungsfaktorRegel(this.betreuungsfaktorRegel).then(response => {
            this.$state.go('base.betreuungsfaktorregel', {id: DvbRestUtilAngularJS.parseEntityIdFromResponse(response)});
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public cancel(): Promise<unknown> {
        return this.dvbStateService.goToPreviousState();
    }
}

componentConfig.controller = DvbBetreuungsfaktorregelErstellen;
angular.module('kitAdmin').component('dvbBetreuungsfaktorregelErstellen', componentConfig);
