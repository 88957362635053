/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, RestCache} from '@dv/kitadmin/models';
import {Firma, FirmenKontingent} from '@dv/kitadmin/models';
import type {RestInclude} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';
import type {KinderOrtService} from './kinderort/kinderOrtService';

export class FirmaService {
    public static $inject: readonly string[] = ['kinderOrtService', '$http', '$q'];

    private static readonly BASE_URL: string = `${CONFIG.restBackend}/api/v1/firmen`;
    private static readonly FIRMEN_KONTINTINGENT_BASE_URL: string = `${CONFIG.restBackend}/api/v1/firmenkontingente`;

    public constructor(
        private kinderOrtService: KinderOrtService,
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) {
    }

    /**
     * Get a Firma by id.
     */
    public get(firmaId: string, params?: RestCache): angular.IPromise<Firma | null> {
        const url = FirmaService.BASE_URL;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, Firma, firmaId, {cache: !!params?.cache});
    }

    public getAll(): angular.IPromise<Firma[]> {
        return DvbRestUtilAngularJS.getModelsArray(FirmaService.BASE_URL, Firma, 'firmen');
    }

    /**
     * Creates a Firma, but does not include Kontingente.
     */
    public create(firma: Firma, firmenKontingent?: FirmenKontingent): angular.IHttpPromise<any> {
        const firmaNew = {
            firma: firma.toRestObject(),
            kontingent: firmenKontingent ? firmenKontingent.toRestObject() : undefined,
        };
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.post(FirmaService.BASE_URL, firmaNew);
    }

    /**
     * Updates a firma.
     */
    public update(firma: Firma): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${FirmaService.BASE_URL}/${encodeURIComponent(checkPresent(firma.id))}`;

        return this.$http.put(url, firma.toRestObject());
    }

    public deleteFirma(firmaId: string): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        return this.$http.delete(`${FirmaService.BASE_URL}/${encodeURIComponent(firmaId)}`);
    }

    public validateFirmaDelete(firmaId: string): angular.IPromise<unknown> {
        return this.$http.get(`${FirmaService.BASE_URL}/${encodeURIComponent(firmaId)}/validate/delete`);
    }

    /**
     * Creates a FirmenKontingent.
     */
    public createFirmenKontingent(firmaId: string, firmenKontingent: FirmenKontingent): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FirmaService.BASE_URL}/${encodeURIComponent(firmaId)}/kontingente/`;

        return this.$http.post(url, firmenKontingent.toRestObject());
    }

    /**
     * Get all FirmenKontingente.
     */
    public getFirmenKontingente(
        firmaId: string,
        params?: RestCache & RestInclude,
    ): angular.IPromise<FirmenKontingent[]> {
        const matrixParams = {includes: params?.includes ?? '(firma,values)'};
        const url = `${FirmaService.BASE_URL}/${encodeURIComponent(firmaId)}/kontingente`;
        const cache = !!params?.cache;

        return DvbRestUtilAngularJS.getModelsArray(url, FirmenKontingent, 'firmenKontingente', matrixParams, cache);
    }

    /**
     * Get all FirmenKontingent with Kitas.
     */
    public getFirmenKontingenteWithKitas(
        firmaId: string,
        params?: RestCache & RestInclude,
    ): angular.IPromise<FirmenKontingent[]> {
        return this.getFirmenKontingente(firmaId, params)
            .then(firmenKontingente => {
                const promises: angular.IPromise<KinderOrt | null>[] = [];

                firmenKontingente.forEach(firmenKontingent => {
                    firmenKontingent.values.forEach(firmenKontingentValue => {
                        firmenKontingentValue.kitasIds.forEach(kitaId => {
                            const kitaPromise = this.kinderOrtService.get(kitaId, {cache: true});
                            promises.push(kitaPromise);
                            kitaPromise.then(k => {
                                firmenKontingentValue.kitas.push(checkPresent(k));
                            });
                        });
                    });
                });

                return this.$q.all(promises).then(() => firmenKontingente);
            });
    }

    /**
     * Updates a firmenKontingent.
     */
    public updateFirmenKontingent(firmenKontingent: FirmenKontingent): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();
        const url = `${FirmaService.FIRMEN_KONTINTINGENT_BASE_URL}/` +
            `${encodeURIComponent(checkPresent(firmenKontingent.id))}`;

        return this.$http.put(url, firmenKontingent.toRestObject());
    }

    public getFirmenKontingent(
        firmenKontingentId: string,
        params?: RestInclude & RestCache,
    ): angular.IPromise<FirmenKontingent> {
        const url = FirmaService.FIRMEN_KONTINTINGENT_BASE_URL;

        return DvbRestUtilAngularJS.getModelByIdAndParams(url, FirmenKontingent, firmenKontingentId, params)
            .then(checkPresent);
    }
}
