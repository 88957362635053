<div class="dvb-kibon-mutationslauf row">
    <div class="col-md-7">
        <h2 data-translate="KIBON.MUTATION.PENDENTE_MELDUNGEN"></h2>

        <form name="mutationslaufForm" ng-submit="vm.submit()" novalidate dvb-accordions>

            <p ng-if="vm.betreuungen.length === 0 && !vm.isKinderOrtRefreshing && !vm.isLoading"
               class="normal-abstand-bottom"
               data-translate="KIBON.MUTATION.KEINE_PENDENTE_MELDUNGEN">
            </p>

            <dvb-pages items="vm.betreuungen"
                       total-items="vm.betreuungen.length"
                       is-loading="vm.isLoading"
                       items-per-page="vm.itemsPerPage"
                       current-page="vm.currentPage"
                       entity-name="'KIBON.MUTATION.PENDENTE_MELDUNGEN_MF'"
                       on-update-page="vm.currentPage = page"
                       on-update-items-per-page="vm.itemsPerPage = itemsPerPage">
                <page-items>

                    <div ng-repeat="betreuung in vm.betreuungen | limitTo: vm.itemsPerPage : ((vm.currentPage - 1) * vm.itemsPerPage)">
                        <dvb-accordion is-first="$first" remove-toggled-dom="true">
                            <panel-header class="full-width-block">
                                <div class="layout-row items-center row">
                                    <div class="col-xs-5">
                                        <div class="checkbox">
                                            <label ng-click="$event.stopPropagation();">
                                                <input type="checkbox"
                                                       class="smaller-abstand-right"
                                                       ng-disabled="!betreuung.isMutierbar()"
                                                       ng-model="vm.selected[betreuung.id]">
                                                <strong ng-bind="betreuung.kind.getDisplayName()"
                                                        ng-focus="$event.stopPropagation()"
                                                        uib-tooltip="{{betreuung.kind.getDisplayName()}}"
                                                        tooltip-placement="top-left">
                                                </strong>
                                                <i class="fa fa-exclamation-circle"
                                                   ng-if="betreuung.ausserordentlicherBetreuungsaufwand"
                                                   uib-tooltip="{{'KIBON.MUTATION.AUSSERORDENTLICHER_BETREUUNGSAUFWAND' | translate}}"
                                                   tooltip-append-to-body="true">
                                                </i>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xs-4">
                                        <dvb-loading-button as-link="true"
                                                            class="refresh-button inline-block small-abstand-right"
                                                            is-loading="!!vm.isRefreshing[betreuung.kind.id]"
                                                            on-click="event.stopPropagation(); vm.refreshKind(betreuung.kind.id)"
                                                            tooltip-placement="top-left"
                                                            type="button"
                                                            uib-tooltip="{{'KIBON.MUTATION.NEU_GENERIEREN' | translate}}">
                                            <i class="fa fa-refresh"></i>
                                        </dvb-loading-button>
                                        <dvb-tasks-badge label="{{'KIBON.MUTATION.TRIGGER.' + betreuung.trigger}}"
                                                         popover-title="{{'KIBON.MUTATION.STATUS_TOOLTIP_MF' | translate:{status: betreuung.status, provisorisch: (betreuung.austrittProvisorisch || betreuung.belegungProvisorisch)}:'messageformat'}}"
                                                         class="task-badge"
                                                         tasks="betreuung.tasks"
                                                         warning="!betreuung.isMutierbar()">
                                        </dvb-tasks-badge>
                                    </div>
                                    <div class="col-xs-3 text-right">
                                        <dvb-kibon-ref-nummer kibon-ref-nr-info="betreuung">
                                        </dvb-kibon-ref-nummer>
                                    </div>
                                </div>
                            </panel-header>
                            <panel-content>
                                <dvb-kibon-betreuung-detail betreuung="betreuung"></dvb-kibon-betreuung-detail>
                            </panel-content>
                            <panel-footer>
                                <dvb-loading-button as-link="true"
                                                    is-loading="vm.isLoading"
                                                    on-click="vm.ignore(betreuung)"
                                                    spinner-right="true"
                                                    type="button">
                                    <span data-translate="KIBON.MUTATION.IGNORIEREN"></span>
                                </dvb-loading-button>
                            </panel-footer>
                        </dvb-accordion>
                    </div>

                </page-items>
                <total-summary>
                    <dvb-list-summary>
                        <div class="floating-text">
                            <strong data-translate="KIBON.MUTATION.MF"
                                    data-translate-value-count="{{vm.betreuungen.length}}"
                                    data-translate-value-showcount="true"
                                    data-translate-interpolation="messageformat">
                            </strong>
                        </div>
                    </dvb-list-summary>
                </total-summary>
            </dvb-pages>

            <ul dvb-auth
                require-permission="kita:manage:general"
                require-condition="vm.betreuungen.length > 0"
                class="list-inline normal-abstand-top">
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.selectAll()"
                            data-translate="COMMON.ALLE_AUSWAEHLEN">
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="btn btn-link"
                            ng-click="vm.selectNone()"
                            data-translate="COMMON.KEINE_AUSWAEHLEN">
                    </button>
                </li>
            </ul>

            <ul class="list-inline big-abstand-top">
                <li ng-if="vm.betreuungen.length > 0">
                    <dvb-loading-button type="submit"
                                        ng-disabled="vm.getSelectionCount() === 0">
                        <span data-translate="KIBON.MUTATION.MELDEN"></span>
                    </dvb-loading-button>
                </li>
            </ul>
        </form>
    </div>

    <div class="col-md-4 col-md-offset-1 hidden-print" dvb-auth require-permission="kita:manage:general">
        <h2 data-translate="COMMON.AKTIONEN"></h2>
        <dvb-loading-button as-link="true"
                            is-loading="vm.isKinderOrtRefreshing"
                            class="floating-text"
                            on-click="vm.refresh()"
                            tooltip-placement="top-left"
                            type="button">
            <span data-translate="KIBON.MUTATION.NEU_GENERIEREN"></span>
        </dvb-loading-button>
        <uib-progressbar ng-if="vm.isKinderOrtRefreshing" value="vm.progressValue" type="success"></uib-progressbar>
    </div>
</div>


