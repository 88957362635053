<div class="dvb-personal-konfiguration-header row">
    <div class="col-md-3">
        <p>
            <span data-translate="PERSONAL.KONFIGURATION.WEEKLY_HOURS_VAL"
                  data-translate-value-hours="{{vm.konfiguration.weeklyHours}}"
                  uib-tooltip="{{'PERSONAL.KONFIGURATION.WEEKLY_HOURS' | translate}}"
                  toolip-placement="top-left">
            </span>
        </p>
    </div>
    <div class="col-md-4">
        <p>
            <span data-translate="PERSONAL.KONFIGURATION.FTE_HOURS_VAL"
                  data-translate-value-hours="{{vm.konfiguration.fteHours}}"
                  uib-tooltip="{{'PERSONAL.KONFIGURATION.FTE_HOURS' | translate}}"
                  toolip-placement="top-left">
            </span>
        </p>
    </div>
    <div class="col-md-5">
        <p>
            <span data-translate="PERSONAL.KONFIGURATION.ARBEITSZEIT.VON_BIS"
                  data-translate-value-von="{{vm.konfiguration.arbeitszeitVon | amDateFormat: 'HH:mm'}}"
                  data-translate-value-bis="{{vm.konfiguration.arbeitszeitBis | amDateFormat: 'HH:mm'}}"
                  uib-tooltip="{{'PERSONAL.KONFIGURATION.FTE_HOURS' | translate}}"
                  toolip-placement="top-left">
            </span>
        </p>
    </div>
</div>
