/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TempBlob} from '@dv/kitadmin/models';
import type {ReportTemplate} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type {ParameterKinderOrte} from '../../models';
import {ERR_VALIDATION, ReportState} from '../../models';
import type {ReportService} from '../../service/reportService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        reportState: '<',
        parameterKinderOrte: '<',
        showYear: '<?',
    },
    template: require('./dvb-kitas-report.html'),
    controllerAs: 'vm',
};

export class DvbKitasReport implements angular.IController {
    public static $inject: readonly string[] = ['reportService', 'errorService', '$q'];

    public readonly reportState!: ReportState;
    public parameterKinderOrte!: ParameterKinderOrte;
    public readonly showYear: boolean = false;

    public readonly defaultYear: number = DvbDateUtil.today().year();
    public readonly kitasReportForm?: angular.IFormController;

    public reportTemplate: ReportTemplate | null = null;

    public constructor(
        private reportService: ReportService,
        private errorService: ErrorService,
        private $q: angular.IQService,
    ) {
    }

    public updateState(): void {
        this.reportService.updateStateParams(this.parameterKinderOrte);
    }

    public onSubmit(): angular.IPromise<TempBlob> {
        const isValid = this.kitasReportForm!.$valid && this.parameterKinderOrte.isValid();
        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!isValid) {
            return this.$q.reject(ERR_VALIDATION);
        }

        return this.getDownloadPromise();
    }

    public getDownloadPromise(): angular.IPromise<TempBlob> {
        const params = angular.copy(this.parameterKinderOrte);
        if (this.showYear) {
            params.year = params.year ?? this.defaultYear;
        }
        switch (this.reportState) {
            case ReportState.CONTROLLING.WARTELISTE:
                return this.reportService.getTempBlobForWarteliste(params,
                    {reportTemplate: this.reportTemplate});
            case ReportState.CONTROLLING.FINANZLASTENAUSGLEICH:
                return this.reportService.getTempBlobForFinanzlastenausgleich(params,
                    {reportTemplate: this.reportTemplate});
            case ReportState.CONTROLLING.KIBON_REPORT:
                return this.reportService.getTempBlobForKibonReport(params,
                    {reportTemplate: this.reportTemplate});
            case ReportState.FAKTURA.LEISTUNGEN_FAKTURIERT:
                return this.reportService.getTempBlobForLeistungenFakturiertReport(params,
                    {reportTemplate: this.reportTemplate});
            case ReportState.CONTROLLING.CONTROLLING_MONTHLY:
                return this.reportService.getTempBlobForControllingMonthlyReport(params,
                    {reportTemplate: this.reportTemplate});
            default:
                throw new Error(`Report not implemented: ${JSON.stringify(this.reportState)}`);
        }
    }
}

componentConfig.controller = DvbKitasReport;
angular.module('kitAdmin').component('dvbKitasReport', componentConfig);
