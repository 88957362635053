/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kind} from '@dv/kitadmin/models';
import type {StateService} from '@uirouter/core';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinder: '<',
        error: '<',
    },
    template: require('./dvb-communication-kind-incomplete-recipients.html'),
    controllerAs: 'vm',
};

export class DvbCommunicationKindIncompleteRecipients implements angular.IController {
    public static $inject: readonly string[] = ['$state', 'dvbStateService'];

    public kinder!: Kind[];
    public error!: string;

    public isLoading: boolean = false;

    public constructor(private $state: StateService) {
    }

    public reload(): void {
        this.isLoading = true;
        this.$state.reload().then(() => {
            this.isLoading = false;
        });
    }
}

componentConfig.controller = DvbCommunicationKindIncompleteRecipients;
angular.module('kitAdmin').component('dvbCommunicationKindIncompleteRecipients', componentConfig);
