<div class="dvb-kind-leistungen-pro-monat">

    <div class="leistungsrechnung-monat row"
         ng-click="vm.onSelect({startOfMonth: vm.startOfMonth})"
         ng-class="{'selected-leistungsrechnung-monat': vm.startOfMonth.isSame(vm.selectedMonth)}">
        <div class="col-xs-5">
            <span class="leistungsmonat-heading" ng-bind="vm.startOfMonth | amDateFormat: 'MMMM YYYY'"></span>
        </div>
        <div class="col-xs-4 col-sm-5">
            <div ng-repeat="leistungsrechnung in vm.leistungsrechnungen"
                 ng-init="kinderOrtName = vm.getKitaNameForLeistungsrechnung(leistungsrechnung)"
                 class="task-badge hidden-print"
                 data-testid="{{kinderOrtName}}">
                <dvb-tasks-badge label="COMMON.{{leistungsrechnung.status}}"
                                 popover-title="{{vm.statusMap[leistungsrechnung.status] | translate:{kitaName: kinderOrtName} }}"
                                 warning="!leistungsrechnung.isVerrechenbar()"
                                 tasks="leistungsrechnung.tasks">
                </dvb-tasks-badge>

                <dvb-leistungsrechnung-warnings-badge warnings="vm.leistungsrechnungWarnings[leistungsrechnung.id].warnings"
                                                      enable-deletion="vm.leistungsrechnungWarnings[leistungsrechnung.id].enableDeletion"
                                                      on-delete="vm.onDeleteMutationBadge({leistungsrechnungId: leistungsrechnung.id})"
                                                      implicitly-known="KIND">
                </dvb-leistungsrechnung-warnings-badge>
            </div>
        </div>
        <div class="col-xs-3 col-sm-2 text-right rechnungs-total" ng-if="vm.totalLeistungen !== null">
            <p>
                <strong data-translate="COMMON.BETRAG_VALUE"
                        data-translate-value-betrag="{{vm.totalLeistungen | number:2}}">
                </strong>
            </p>
        </div>
    </div>
    <div uib-collapse="!vm.startOfMonth.isSame(vm.selectedMonth)">
        <div class="detail">
            <div class="row" ng-if="vm.showNichtVorhanden()">
                <div class="col-md-12">
                    <p data-translate="KIND.LEISTUNGEN.LEISTUNGSRECHNUNG_NICHT_VORHANDEN"></p>
                </div>
            </div>

            <div class="leistungsrechnung"
                 ng-repeat="leistungsrechnung in vm.leistungsrechnungenWithResults"
                 data-ee="{{vm.kitaNamen[leistungsrechnung.kitaId]}}"
                 ng-if="vm.activeLeistungsrechnungen.indexOf($index) >= 0 || vm.showDownload">
                <div>
                    <div ng-repeat="tarifrechnungResult in leistungsrechnung.tarifrechnungResults"
                         ng-if="tarifrechnungResult.tarifrechnungPositionen.length > 0 || vm.showDownload">
                        <div class="row">

                            <div class="col-xs-4 tarifrechnung-result-title">
                                <span ng-bind="tarifrechnungResult.titel"></span>
                                <br>
                                <span ng-if="!tarifrechnungResult.gueltigAb.isSame(tarifrechnungResult.gueltigBis)"
                                      ng-bind="tarifrechnungResult.gueltigAb | amDateFormat:  'DD.MM.' + ' -' "></span>
                                <span ng-bind="tarifrechnungResult.gueltigBis| amDateFormat:  'DD.MM.'"></span>
                                <br>
                                <span ng-if="!tarifrechnungResult.kinderOrtFraktion"
                                      ng-bind="vm.kitaNamen[leistungsrechnung.kitaId]"></span>
                                <span ng-if="tarifrechnungResult.kinderOrtFraktion"
                                      ng-bind="tarifrechnungResult.kinderOrtFraktion"></span>
                            </div>
                            <div class="col-xs-8">
                                <dv-kind-leistungen-positionen [tarifrechnung-positionen]="tarifrechnungResult.tarifrechnungPositionen">
                                </dv-kind-leistungen-positionen>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 small-abstand-bottom">
                                <dvb-kind-leistungen-download tarifrechnung-result="tarifrechnungResult"
                                                              ng-if="vm.showDownload">
                                </dvb-kind-leistungen-download>
                            </div>
                        </div>
                    </div>

                    <div ng-repeat="fixPosition in leistungsrechnung.fixPositionen">
                        <dvb-fixposition fix-position="fixPosition"
                                         kita-name="{{vm.kitaNamen[fixPosition.kitaId]}}"
                                         on-delete="vm.onDeleteFixPosition({fixPosition: fixPosition})">
                        </dvb-fixposition>
                    </div>

                    <div ng-if="leistungsrechnung.eingewoehnungPosition">
                        <dv-eingewoehnungposition [eingewoehnung-position]="leistungsrechnung.eingewoehnungPosition"
                                                  [kita-name]="vm.kitaNamen[leistungsrechnung.eingewoehnungPosition.kinderOrtId]"
                                                  (delete-event)="vm.onDeleteEingewoehnungPosition({eingewoehnungPosition: leistungsrechnung.eingewoehnungPosition})">
                        </dv-eingewoehnungposition>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail leistungsrechnung-summe">
            <div class="row"
                 ng-if="vm.leistungsrechnungenWithResults.length > 0 && vm.activeLeistungsrechnungen.length > 0 || vm.showDownload">
                <div class="col-xs-12">
                    <div class="pull-left" data-translate="KIND.LEISTUNGEN.LEISTUNGEN_TOTAL"></div>
                    <div class="pull-right"
                         data-translate="COMMON.BETRAG_VALUE"
                         data-translate-value-betrag="{{vm.totalLeistungen | number:2}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
