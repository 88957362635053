/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type { ILimited} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class MonthlyControllingFigures implements ILimited {

    public constructor(
        public id: string,
        public gueltigAb: moment.Moment,
        public gueltigBis: moment.Moment,
        public sollStunden: number,
        public effektivStunden: number,
        public forecast: number | null,
    ) {
    }

    public static apiResponseTransformer(data: any): MonthlyControllingFigures {
        return new MonthlyControllingFigures(
            data.id,
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigAb)),
            checkPresent(DvbRestUtil.localDateToMoment(data.gueltigBis)),
            parseFloat(data.sollStunden),
            parseFloat(data.effektivStunden),
            data.forecast ? parseFloat(data.forecast) : null,
        );
    }
}
