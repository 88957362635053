/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Translation} from '@dv/kitadmin/models';
import type {FunctionType, SupportedLanguage} from '@dv/shared/code';
import angular from 'angular';
import type {MandantService} from '../../service/rest/mandantService';

const componentConfig: angular.IComponentOptions = {
    bindings: {
        translation: '<',
        maxLength: '<',
        onSubmit: '&',
        disabled: '<?',
    },
    template: require('./dvb-translatable-editable-label.html'),
    controllerAs: 'vm',
};

export class DvbTranslatableEditableLabel implements angular.IController {
    public static $inject: readonly string[] = ['mandantService', '$attrs'];

    public translation!: Translation;
    public maxLength!: number;
    public onSubmit!: FunctionType;
    public disabled: boolean = false;

    public supportedLanguages: SupportedLanguage[] = [];

    public constructor(
        private readonly mandantService: MandantService,
        private readonly $attrs: angular.IAttributes,
    ) {
    }

    public $onInit(): void {
        this.mandantService.getMandantLanguges().then(languages => {
            this.supportedLanguages = languages;
        });

        this.$attrs.$observe('disabled', value => {
            this.disabled = !!value;
        });
    }
}

componentConfig.controller = DvbTranslatableEditableLabel;
angular.module('kitAdmin').component('dvbTranslatableEditableLabel', componentConfig);
