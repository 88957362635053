/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson, Rechnung} from '@dv/kitadmin/models';
import {RechnungDisplayMode} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
        preSelectedRechnungId: '<?',
        initYear: '<?year',
    },
    template: require('./dvb-kontaktperson-rechnungen.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonRechnungen implements angular.IController {
    public static $inject: readonly string[] = [
        'kontaktpersonService',
        '$state',
        '$q',
    ];

    public kontaktperson!: Persisted<Kontaktperson>;
    public preSelectedRechnungId?: string;
    public initYear?: number;

    public mode: RechnungDisplayMode = RechnungDisplayMode.modes.KONTAKTPERSON;
    public isLoading: boolean = false;
    public rechnungen: Persisted<Rechnung>[] = [];
    public year?: moment.Moment;

    private timeout?: angular.IDeferred<unknown>;

    public constructor(
        private readonly kontaktpersonService: KontaktpersonService,
        private readonly $state: StateService,
        private readonly $q: angular.IQService,
    ) {
    }

    public $onInit(): void {
        this.year ??= DvbDateUtil.startOfYear();
        this.loadRechnungen();
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.initYear?.currentValue) {
            this.year = moment(changes.initYear.currentValue, 'YYYY');
            if (!changes.initYear.isFirstChange()) {
                this.loadRechnungen();
            }
        }

        if (changes.kontaktperson && !changes.kontaktperson.isFirstChange()) {
            this.loadRechnungen();
        }
    }

    public $onDestroy(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
    }

    public updateYear(): void {
        this.$state.go('base.kontaktperson.faktura', {
            kontaktpersonId: this.kontaktperson.id,
            year: this.year?.year(),
        });
    }

    private loadRechnungen(): void {
        DvbRestUtilAngularJS.cancelRequest(this.timeout);
        this.timeout = this.$q.defer();

        this.isLoading = true;

        const params = {
            gueltigAb: DvbRestUtil.momentToLocalDate(this.year),
            gueltigBis: DvbRestUtil.momentToLocalDate(DvbDateUtil.endOfYear(moment(this.year))),
            includes: '(nestedIncludes.fields(emailVersandHistory, ausstehenderBetrag))',
        };
        const config = {timeout: this.timeout.promise};

        this.kontaktpersonService.getRechnungen(this.kontaktperson.id, params, config)
            .then(rechnungen => {
                this.rechnungen = rechnungen;
                this.isLoading = false;
            })
            .catch(error => {
                if (DvbRestUtil.isRequestCancelled(error)) {
                    return this.$q.resolve();
                }
                this.isLoading = false;

                return this.$q.reject(error);
            });
    }
}

componentConfig.controller = DvbKontaktpersonRechnungen;
angular.module('kitAdmin').component('dvbKontaktpersonRechnungen', componentConfig);
