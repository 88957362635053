/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {BaseRechnungsPosition} from '@dv/kitadmin/models';
import {DvbUtil, FormContext, isNullish, isPresent} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        formContext: '<?',
        existingCustomPositionen: '<?',
        onUpdate: '&',
        onCancel: '&',
    },
    template: require('./dvb-administration-custom-rechnung-position-form.html'),
    controllerAs: 'vm',
};

export class DvbAdministrationCustomRechnungPositionForm implements angular.IController {
    public static $inject: readonly string[] = ['errorService'];

    public formContext!: FormContext;
    public existingCustomPositionen: BaseRechnungsPosition[] = [];
    public onUpdate!: (val: { customPositionen: BaseRechnungsPosition[]; formContext: FormContext }) => void;
    public onCancel!: () => void;

    public totalByPosition: Map<BaseRechnungsPosition, number> = new Map<BaseRechnungsPosition, number>();
    public customPositionen: BaseRechnungsPosition[] = [];

    public constructor(private errorService: ErrorService) {
    }

    public $onInit(): void {
        this.formContext = this.formContext || new FormContext();
        this.customPositionen = angular.copy(this.existingCustomPositionen);

        this.customPositionen.forEach(p => this.calculateTotal(p));
    }

    public submit(form: angular.IFormController): void {
        const isValid = isPresent(this.customPositionen)
            && this.customPositionen.every(p => p.isValid())
            && form.$valid;

        this.errorService.handleValidationError(isValid, 'ADMINISTRATION.ERR_INVALID_CUSTOM_POSITION');

        if (!isValid) {
            return;
        }

        if (angular.equals(this.customPositionen, this.existingCustomPositionen)) {
            this.onCancel();

            return;
        }

        this.onUpdate({customPositionen: this.customPositionen, formContext: this.formContext});
    }

    public calculateTotal(customPosition: BaseRechnungsPosition): void {
        if (isNullish(customPosition) ||
            isNullish(customPosition.cost) ||
            isNullish(customPosition.count)) {
            this.totalByPosition.set(customPosition, 0);

            return;
        }

        this.totalByPosition.set(customPosition, customPosition.cost * customPosition.count);
    }

    public removePosition(position: BaseRechnungsPosition): void {
        DvbUtil.removeFromArray(position, this.customPositionen);
        this.totalByPosition.delete(position);
    }

    public addPosition(): void {
        const position = new BaseRechnungsPosition();
        this.calculateTotal(position);
        this.customPositionen.push(position);
    }
}

componentConfig.controller = DvbAdministrationCustomRechnungPositionForm;
angular.module('kitAdmin').component('dvbAdministrationCustomRechnungPositionForm', componentConfig);
