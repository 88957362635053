/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import {TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {Filter} from '../../../filter/shared/Filter';
import type {FilterOption} from '../../../filter/shared/FilterOption';
import type {OptionFilter} from './OptionFilter';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        filter: '<',
        optionFilter: '<?',
        onSticky: '&?',
    },
    template: require('./dvb-filter-list.html'),
    controllerAs: 'vm',
};

export class DvbFilterList implements angular.IController {
    public static $inject: readonly string[] = [];

    public filter: Filter[] = [];
    public optionFilter: OptionFilter = 'all';

    public onSticky?: FunctionType;

    public onFilterChange(filter: Filter, option: FilterOption, isSelected: boolean): void {
        option.selected = isSelected;
        if (this.optionFilter === 'nonSticky') {
            option.sticky = isSelected;

            if (isSelected && TypeUtil.isFunction(this.onSticky)) {
                this.onSticky();
            }
        }
        filter.onChange(option);
    }

    public closeOption(option: FilterOption, filter: Filter): void {
        option.sticky = false;
        option.selected = false;
        filter.initStickyFlags();
        filter.onChange(option);
    }
}

componentConfig.controller = DvbFilterList;
angular.module('kitAdmin').component('dvbFilterList', componentConfig);
