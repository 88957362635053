/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, KinderOrt, KinderOrtFraktionId, KinderOrtId} from '@dv/kitadmin/models';
import {Belegung, CustomFieldValue} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {Persisted} from '@dv/shared/code';
import {
    BelegungsZustand,
    checkPresent,
    DvbDateUtil,
    DvbRestUtil,
    DvbUtil,
    ERROR_CODE,
    NamedEntityType,
    presentCustomFieldValues,
    WARNING_RULE_NAME,
} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {catchError, finalize, from, lastValueFrom, Subject, switchMap, take, tap} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {DvbStateService} from '../../../common/service/dvbStateService';
import type {CustomFieldService} from '../../../common/service/rest/customFieldService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {KibonExchangeTagesschuleAnmeldung} from '../../../kibon/models/KibonExchangeTagesschuleAnmeldung';
import type {KinderOrtWocheService} from '../../../kinderort/service/kinderOrtWocheService';
import type {AngestellteService} from '../../../personal/anstellung/service/angestellteService';
import type {KindZuweisenService} from '../../service/kindZuweisenService';
import type {ZuweisenUtil} from '../../service/zuweisenUtil';
import {ZuweisenFormModel} from '../dvb-zuweisen-form/ZuweisenFormModel';
import {MONAT_ZUWEISEN_STATE} from '../kind-zuweisen-states';
import {KinderOrtZuweisung} from './KinderOrtZuweisung';
import {KindKinderOrtZuweisung} from './KindKinderOrtZuweisung';
import {Zuweisung} from './Zuweisung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        kinderOrte: '<',
        zuweisungAb: '<?',
        direktzuweisung: '<?',
        tagesschulAnmeldung: '<?',
    },
    template: require('./dvb-kind-zuweisen.html'),
    controllerAs: 'vm',
};

const defaultCollapseThreshhold = 6;

export class DvbKindZuweisen implements angular.IController {
    public static $inject: readonly string[] = [
        'kindZuweisenService',
        'kindService',
        'kinderOrtWocheService',
        'errorService',
        'dialogService',
        'zuweisenUtil',
        'dvbStateService',
        'customFieldService',
        '$state',
        '$q',
        '$translate',
        'angestellteService',
    ];

    public kind!: Persisted<Kind>;
    public kinderOrte: Persisted<KinderOrt>[] = [];
    public kindKinderOrtZuweisungen: KindKinderOrtZuweisung[] = [];
    public zuweisungAb?: moment.Moment;
    public direktzuweisung?: boolean;

    public tagesschulAnmeldung?: KibonExchangeTagesschuleAnmeldung;

    public aktuelleBelegung: Belegung | null = null;
    public showDatepicker: boolean = false;
    public isLoading: boolean = false;
    public zuweisenFormModel: ZuweisenFormModel = new ZuweisenFormModel();

    public isFullyCollapsible: boolean = true;

    private fullCollapseThreshhold: number = defaultCollapseThreshhold;

    private kinderOrtZuweisungen: KinderOrtZuweisung[] = [];

    public constructor(
        private readonly kindZuweisenService: KindZuweisenService,
        private readonly kindService: KindService,
        private readonly kinderOrtWocheService: KinderOrtWocheService,
        private readonly errorService: ErrorService,
        private readonly dialogService: DialogService,
        private readonly zuweisenUtil: ZuweisenUtil,
        private readonly dvbStateService: DvbStateService,
        private readonly customFieldService: CustomFieldService,
        private readonly $state: StateService,
        private readonly $q: angular.IQService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly angestellteService: AngestellteService,
    ) {
    }

    public $onInit(): void {
        if (this.tagesschulAnmeldung) {
            this.zuweisungAb = this.tagesschulAnmeldung.eintrittsdatum!;
        }

        this.showDatepicker = !this.hasBelegungAtZuweisungsDate(this.kind.belegungen, this.zuweisungAb);

        this.kinderOrte.forEach(kinderOrt => {
            // make sure back references are present
            kinderOrt.gruppen.forEach(gruppe => {
                gruppe.kita = kinderOrt;
            });
        });
        const zuweisung = new Zuweisung(this.kinderOrte, this.kind);
        this.kinderOrtZuweisungen = DvbUtil.orderByDisplayName(this.kinderOrte)
            .map(kinderOrt => new KinderOrtZuweisung(kinderOrt, zuweisung));
        this.initializeWeek();
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.kind) {
            this.kind.belegungen = DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(this.kind.belegungen);
        }
    }

    public switchToClickableDate(aMoment: moment.Moment): void {
        this.zuweisungAb = aMoment;
        this.showDatepicker = false;
        this.onDateChange();
    }

    public onDateChange(): void {
        this.updateLocation();
        this.initializeWeek();
    }

    public goToZuweisenMonat(kinderOrtId: KinderOrtId, gruppeId: KinderOrtFraktionId): angular.IPromise<unknown> {
        return this.updateLocation().then(() => {
            const params = {kinderOrtId, gruppeId};

            // wenn man reload nicht auf true setzt, geht die History von State zuweisen.simple verloren!?
            return this.$state.go(MONAT_ZUWEISEN_STATE.name, params, {reload: true});
        });
    }

    public zuweisen(): void {
        this.zuweisenUtil.validateZuweisung(this.zuweisungAb, this.kind, () => this.buildBelegung())
            .then(belegung => {
                if (belegung.gruppenBelegungen.length > 0) {
                    this.updateGueltigkeitForBelegung(belegung);
                } else {
                    this.errorService.addValidationError('ERRORS.ERR_REQUIRED_BELEGUNGSEINHEIT');
                }
            });
    }

    public goBack(): angular.IPromise<unknown> {
        return this.updateLocation().then(() => {
            if (this.direktzuweisung) {
                // Bei einer Direktzuweisung zum Kindprofil gehen
                return this.$state.go('base.kind.profil', {kindId: this.kind.id});
            }

            return this.dvbStateService.goToPreviousState();
        });
    }

    public revertAustritt(): void {
        this.$state.reload();
    }

    public resetAustrittProvisorisch(): void {
        this.$state.reload();
    }

    private hasBelegungAtZuweisungsDate(belegungen: Belegung[], date?: moment.Moment): boolean {
        if (!DvbDateUtil.isValidMoment(date) || !Array.isArray(belegungen)) {
            return false;
        }

        return belegungen.some(b => date.isSame(b.gueltigAb));
    }

    private updateLocation(): angular.IPromise<unknown> {
        const params = {zuweisungAb: DvbRestUtil.momentToLocalDate(this.zuweisungAb)};

        return this.$state.go('.', params, {location: 'replace', notify: true});
    }

    private buildBelegung(): Belegung {
        const gueltigAb = checkPresent(this.zuweisungAb);
        const belegung = new Belegung();
        belegung.gueltigAb = gueltigAb;
        belegung.gueltigBis = DvbDateUtil.findGueltigBis(this.kind.belegungen, gueltigAb);
        belegung.gruppenBelegungen = this.kindKinderOrtZuweisungen
            .flatMap(model => this.kindZuweisenService.buildGruppenBelegungen(model));
        this.zuweisenFormModel.applyToBelegung(belegung);

        return belegung;
    }

    private updateGueltigkeitForBelegung(belegung: Belegung): angular.IPromise<Belegung> {
        this.isLoading = true;

        return this.kindService.getMaxGueltigBisForBelegung(checkPresent(this.kind.id), belegung)
            .then(belegungMaxGueltigkeit => {
                if (DvbDateUtil.isMomentEquals(belegung.gueltigBis, belegungMaxGueltigkeit.maxGueltigBis)) {
                    // keine Aenderung des belegung.gueltigBis
                    return lastValueFrom(this.validateAndCreateBelegung$(belegung));
                }

                const title = this.$translate.instant('KIND.ZUWEISUNG.ZUWEISUNG_CONFIRM_ADJUSTED_GUELTIGKEIT', {
                    gueltigbis: belegungMaxGueltigkeit.maxGueltigBis.format('D.M.YYYY'),
                });

                const dialog$: Subject<boolean> = new Subject();
                this.dialogService.openConfirmDialog({
                    title,
                    subtitle: belegungMaxGueltigkeit.message,
                    confirm: () => {
                        belegung.gueltigBis = belegungMaxGueltigkeit.maxGueltigBis;

                        return this.validateAndCreateBelegung$(belegung)
                            .pipe(tap(() => dialog$.next(true)));
                    },
                    cancel: () => dialog$.next(false),
                });

                return lastValueFrom(dialog$.pipe(take(1)));
            }).finally(() => {
                this.isLoading = false;
            });
    }

    private validateAndCreateBelegung$(belegung: Belegung): Observable<any> {
        this.isLoading = true;

        return from(this.kindService.validateBelegung(checkPresent(this.kind.id), belegung)).pipe(
            switchMap(() => this.createBelegung$(belegung)),
            catchError(error => {
                if (error.errorCode !== ERROR_CODE.RULE_VIOLATION ||
                    !WARNING_RULE_NAME.guard(error.args.ruleName)) {
                    return from(this.$q.reject(error));
                }

                this.errorService.clearError(error);

                const dialog$: Subject<boolean> = new Subject();
                this.dialogService.openConfirmDialog({
                    title: 'KIND.ZUWEISUNG.ZUWEISUNG_MAX_PLAETZE_WARNING',
                    inverseButtons: true,
                    confirm: () => this.createBelegung$(belegung)
                        .pipe(tap(() => dialog$.next(true))),
                    cancel: () => dialog$.next(false),
                });

                return dialog$.pipe(take(1));
            }),
        );
    }

    private createBelegung$(belegung: Belegung): Observable<any> {
        return from(this.kindService.createBelegung(checkPresent(this.kind.id), belegung)).pipe(
            take(1),
            switchMap(response => {
                const belegungId = DvbRestUtilAngularJS.parseEntityIdFromResponse(response);

                return from(this.saveCustomFieldValues(belegungId)).pipe(
                    take(1),
                    switchMap(() => this.$state.reload()),
                    switchMap(() => belegung.belegungsZustand === BelegungsZustand.BELEGT && !!this.kind.bewerbung ?
                        this.zuweisenUtil.showSuccessDeleteBewerbungModal$(this.kind)
                            .pipe(finalize(() => this.goBack())) : this.goBack()));

            }),
        );
    }

    private saveCustomFieldValues(belegungId: string): angular.IPromise<any> {
        const fieldsToSave = presentCustomFieldValues(this.zuweisenFormModel.customFieldValues);

        if (fieldsToSave.length === 0) {
            return this.$q.resolve();
        }

        fieldsToSave.forEach(cfv => {
            cfv.entityId = belegungId;
        });

        return this.customFieldService.saveValues(fieldsToSave);
    }

    private initializeWeek(): void {
        const zuweisungAb = this.zuweisungAb;
        this.aktuelleBelegung = zuweisungAb ? DvbDateUtil.getEntityOn(this.kind.belegungen, zuweisungAb) : null;

        if (!DvbDateUtil.isValidMoment(zuweisungAb)) {
            this.isLoading = false;

            return;
        }

        const firstOfWeek = DvbDateUtil.startOfWeek(moment(zuweisungAb));

        this.kinderOrtZuweisungen.forEach(zuweisung => this.updateKitaWoche(firstOfWeek, zuweisung.kinderOrt));

        this.kindKinderOrtZuweisungen = this.kinderOrtZuweisungen
            .map(zuweisung => new KindKinderOrtZuweisung(zuweisungAb, firstOfWeek, this.aktuelleBelegung, zuweisung));

        const promises = this.kindKinderOrtZuweisungen
            .map(model => this.kindZuweisenService.init(model));
        promises.push(this.initBelegungProperties(this.aktuelleBelegung));

        this.isLoading = true;
        this.$q.all(promises)
            .finally(() => {
                this.isLoading = false;

                const totalGruppen = this.kindKinderOrtZuweisungen
                    .map(z => z.aktuelleGruppen.length)
                    .reduce((prev, curr) => prev + curr, 0);
                this.isFullyCollapsible = totalGruppen > this.fullCollapseThreshhold;
            });
    }

    private initBelegungProperties(aktuelleBelegung: Belegung | null): angular.IPromise<void> {
        if (aktuelleBelegung) {
            this.zuweisenFormModel = ZuweisenFormModel.from(aktuelleBelegung, this.angestellteService);

            return this.$q.resolve();
        }

        this.zuweisenFormModel = new ZuweisenFormModel();

        return this.customFieldService.getAll(NamedEntityType.BELEGUNG, {cache: true})
            .then(customFields => {
                this.zuweisenFormModel.customFieldValues = CustomFieldValue.fromCustomFields(customFields);
            });
    }

    private updateKitaWoche(firstOfWeek: moment.Moment, kinderOrt: Persisted<KinderOrt>): void {
        const kitaGueltigkeit = {
            gueltigAb: kinderOrt.getGueltigAb(),
            gueltigBis: kinderOrt.getGueltigBis(),
        };

        if (DvbDateUtil.isGueltigOn(kitaGueltigkeit, firstOfWeek)) {
            this.kinderOrtWocheService.setLastFirstOfWeek(firstOfWeek, kinderOrt.id);
        }
    }
}

componentConfig.controller = DvbKindZuweisen;
angular.module('kitAdmin').component('dvbKindZuweisen', componentConfig);
