/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Rechnung} from '@dv/kitadmin/models';
import type {RechnungsRevisionError} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        rechnung: '<',
        showStatus: '<?',
        errors: '<?',
    },
    template: require('./dvb-rechnung-heading-attribute.html'),
    controllerAs: 'vm',
};

export class DvbRechnungHeadingAttribute {
    public static $inject: readonly string[] = [];

    public rechnung!: Rechnung;
    public showStatus?: boolean;
    public errors?: RechnungsRevisionError[] = [];
}

componentConfig.controller = DvbRechnungHeadingAttribute;
angular.module('kitAdmin').component('dvbRechnungHeadingAttribute', componentConfig);
