<div class="dvb-kita-rechte">
    <div>
        <h2 data-translate="BENUTZER.RECHTE_ZUSAETZLICHE_KINDERORTE"></h2>
    </div>

    <div class="big-abstand-bottom" dvb-accordions>
        <p ng-if="vm.kitaPermissions.length === 0" data-translate="BENUTZER.KEINE_KITA_RECHTE_VORHANDEN"></p>
        <div ng-repeat="kitaPermission in vm.kitaPermissions | orderBy:'kitaName'"
             ng-init="workingCopy = vm.createWorkingCopy(kitaPermission)">
            <dvb-accordion on-submit="vm.update(workingCopy, kitaPermission, context)"
                           on-cancel="vm.cancelUpdate(workingCopy, kitaPermission)"
                           on-delete="vm.deleteKitaPermission(kitaPermission)"
                           enable-delete="true"
                           enable-edit="true"
                           ng-disabled="vm.readOnly"
                           is-first="$first"
                           entity-name="'BENUTZER.KINDERORT_PERMISSION' | translate">
                <panel-header class="full-width-block">
                    <div class="row">
                        <div class="col-xs-6">
                            <p ng-bind="kitaPermission.kitaName"></p>
                        </div>
                        <div class="col-xs-6 text-right">
                            <p>
                                <span ng-if="kitaPermission.kitaPermission"
                                      class="badge"
                                      data-translate="{{'BENUTZER.KITA_PERMISSION.' + kitaPermission.kitaPermission}}">
                                </span>
                                <span ng-if="kitaPermission.personalPermission"
                                      class="badge"
                                      data-translate="{{'BENUTZER.PERSONAL_PERMISSION.' + kitaPermission.personalPermission}}">
                                </span>
                            </p>
                        </div>
                    </div>
                </panel-header>
                <edit-content>
                    <div class="row form-row">
                        <div class="col-md-6">
                            <select class="form-control"
                                    uib-tooltip="{{'BENUTZER.KITA_PERMISSION_AUSWAEHLEN' | translate}}"
                                    tooltip-placement="top-left"
                                    ng-model="workingCopy.kitaPermission"
                                    ng-options="permission as ('BENUTZER.KITA_PERMISSION.' + permission | translate) for permission in vm.benutzer.role.userRole ? vm.availablePermissionsByRole[vm.benutzer.role.userRole].kita : vm.availableKitaPermissions.get(vm.benutzer.role.kitaPermission)">
                                <option ng-value="null" data-translate="BENUTZER.KITA_PERMISSION.KEINE"></option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <select class="form-control"
                                    uib-tooltip="{{'BENUTZER.PERSONAL_PERMISSION_AUSWAEHLEN' | translate}}"
                                    tooltip-placement="top-left"
                                    ng-model="workingCopy.personalPermission"
                                    ng-options="permission as ('BENUTZER.PERSONAL_PERMISSION.' + permission | translate) for permission in vm.benutzer.role.userRole ? vm.availablePermissionsByRole[vm.benutzer.role.userRole].personal : vm.availablePersonalPermissions.get(vm.benutzer.role.personalPermission)">
                                <option ng-value="null"
                                        data-translate="BENUTZER.PERSONAL_PERMISSION.KEINE"></option>
                            </select>
                        </div>
                    </div>
                </edit-content>
            </dvb-accordion>
        </div>
    </div>

    <div ng-show="!vm.showNewKitaPermissionForm && !vm.readOnly">
        <button class="btn btn-primary"
                type="button"
                data-translate="BENUTZER.NEUES_RECHT_HINZUFUEGEN"
                ng-click="vm.showAddKitaPermission()">
        </button>
    </div>

    <form ng-submit="vm.addKitaPermission(addKitaPermissionForm)"
          name="addKitaPermissionForm"
          class="dvb-form"
          novalidate
          ng-if="vm.showNewKitaPermissionForm">

        <h2 data-translate="BENUTZER.NEUES_KINDERORT_RECHT"></h2>

        <div class="row">
            <div class="col-xs-6">
                <dvb-search-get-single-entity entity-to-search="KINDERORT"
                                              ng-model="vm.kitaSearchResultEntry"
                                              required
                                              ng-change="vm.updateKita()"
                                              display-label="COMMON.KINDERORT.SINGULAR"
                                              placeholder-key="COMMON.KINDERORT.AUSWAEHLEN"
                                              disabled-entries="vm.kinderOrte">
                </dvb-search-get-single-entity>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-6">
                <select class="form-control"
                        uib-tooltip="{{'BENUTZER.KITA_PERMISSION_AUSWAEHLEN' | translate}}"
                        tooltip-placement="top-left"
                        ng-model="vm.newKitaPermission.kitaPermission"
                        ng-options="permission as ('BENUTZER.KITA_PERMISSION.' + permission | translate) for permission in vm.benutzer.role.userRole ? vm.availablePermissionsByRole[vm.benutzer.role.userRole].kita : vm.availableKitaPermissions.get(vm.benutzer.role.kitaPermission)"
                        ng-required="!vm.newKitaPermission.personalPermission">
                    <option ng-value="null" selected data-translate="BENUTZER.KITA_PERMISSION.KEINE"></option>
                </select>
            </div>
            <div class="col-md-6">
                <select class="form-control"
                        uib-tooltip="{{'BENUTZER.PERSONAL_PERMISSION_AUSWAEHLEN' | translate}}"
                        tooltip-placement="top-left"
                        ng-model="vm.newKitaPermission.personalPermission"
                        ng-options="permission as ('BENUTZER.PERSONAL_PERMISSION.' + permission | translate) for permission in vm.benutzer.role.userRole ? vm.availablePermissionsByRole[vm.benutzer.role.userRole].personal : vm.availablePersonalPermissions.get(vm.benutzer.role.personalPermission)"
                        ng-required="!vm.newKitaPermission.kitaPermission">
                    <option ng-value="null" selected data-translate="BENUTZER.PERSONAL_PERMISSION.KEINE"></option>
                </select>
            </div>
        </div>

        <dvb-submit-cancel-buttons is-loading="vm.isAddKitaPermissionLoading"
                                   on-cancel="vm.cancel()">
        </dvb-submit-cancel-buttons>
    </form>
</div>
