/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {TarifParameter} from '@dv/kitadmin/models';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import {checkPresent, orderValueComparator} from '@dv/shared/code';
import angular from 'angular';
import type {TarifService} from '../../../common/service/rest/tarifService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-tarif-parameter.html'),
    controllerAs: 'vm',
};

export class DvbTarifParameter implements angular.IController, angular.IOnInit {
    public static $inject: readonly string[] = ['tarifService'];

    public paramTypes: readonly TarifParameterType[] = Object.values(TarifParameterType);
    public isLoading: boolean = false;
    public tarifParameters: { [index in TarifParameterType]: TarifParameter[] } = {
        GLOBAL_PARAM: [],
        MONATS_BASIERT: [],
    };

    public constructor(
        private tarifService: TarifService,
    ) {
    }

    public $onInit(): void {
        this.isLoading = true;

        this.tarifService.getAllTarife({includes: '(parameters)'}).then(tarife => {
            const tarifParameters: { [index in TarifParameterType]: TarifParameter[] } = {
                GLOBAL_PARAM: [],
                MONATS_BASIERT: [],
            };

            tarife.forEach(tarif => {
                tarif.parameters.forEach(param => {
                    const existing = tarifParameters[param.dtype].filter(p => p.id === param.id);

                    let addedParam = param;

                    if (existing.length > 0) {
                        addedParam = existing[0];
                    } else {
                        tarifParameters[param.dtype].push(addedParam);
                    }

                    addedParam.tarife.push(tarif);
                });
            });

            return tarifParameters;
        }).then((tarifParameters: { [index in TarifParameterType]: TarifParameter[] }) => {
            Object.values(tarifParameters)
                .forEach(value => value.sort(orderValueComparator));
            this.tarifParameters = tarifParameters;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public save(params: TarifParameter[]): void {
        params.forEach((value, index) => {
            value.orderValue = index;
        });
        this.tarifService.updateParameterOrder({
            items: params.map(param => ({
                id: checkPresent(param.id),
                orderValue: checkPresent(param.orderValue),
            })),
        });
    }
}

componentConfig.controller = DvbTarifParameter;
angular.module('kitAdmin').component('dvbTarifParameter', componentConfig);
