/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        entityForm: 'entityForm',
    },
    bindings: {
        translationRoot: '<',
        isLoading: '<',
        useButtons: '<?',
        onSubmit: '&',
        onCancel: '&',
    },
    template: require('./dvb-limited-entity-form.html'),
    controllerAs: 'vm',
};

export class DvbLimitedEntityForm {
    public static $inject: readonly string[] = ['errorService'];

    public translationRoot!: string;
    public isLoading!: boolean;
    public onSubmit!: FunctionType;
    public onCancel!: FunctionType;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public cancel(): void {
        this.errorService.clearAll();

        this.onCancel();
    }

    public submit(formValid: boolean): void {
        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!formValid) {
            return;
        }

        this.onSubmit();
    }
}

componentConfig.controller = DvbLimitedEntityForm;
angular.module('kitAdmin').component('dvbLimitedEntityForm', componentConfig);
