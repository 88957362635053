/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {LoadingContext, Persisted, TerminationMode} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import {firstValueFrom} from 'rxjs';
import type {PersonalKonfigurationApiService} from '../../../konfiguration/personal-konfiguration-api.service';
import type {PersonalKonfiguration} from '../../../konfiguration/PersonalKonfiguration';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfigurationen: '<',
        newKonfiguration: '<',
    },
    template: require('./dvb-personal-konfiguration.html'),
    controllerAs: 'vm',
};

export class DvbPersonalKonfiguration implements angular.IController {
    public static $inject: readonly string[] = [
        '$state',
        'errorService',
        'personalKonfigurationApiService',
        'authStore',
    ];

    public konfigurationen: Persisted<PersonalKonfiguration>[] = [];
    public newKonfiguration!: PersonalKonfiguration;

    public hasEditPrivileges: boolean = false;

    public constructor(
        private readonly $state: StateService,
        private readonly errorService: ErrorService,
        private readonly personalKonfigurationService: PersonalKonfigurationApiService,
        private readonly authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(this.konfigurationen);
        this.hasEditPrivileges = this.authStore.hasPermission(
            PERMISSION.PERSONAL.ADMINISTRATE + (this.newKonfiguration.kinderOrtId ?? WILDCARD_TOKEN));
    }

    public save(konfiguration: PersonalKonfiguration, context?: LoadingContext): void {

        const valid = konfiguration.isValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(konfiguration.isArbeitszeitValid(), 'ERRORS.ERR_INVALID_ARBEITSZEIT');
        if (!valid) {
            context?.finishLoading();

            return;
        }

        firstValueFrom(this.personalKonfigurationService.create$(konfiguration))
            .then(() => this.$state.reload());
    }

    public terminate(endDate: moment.Moment, mode: TerminationMode): angular.IPromise<unknown> {
        return firstValueFrom(
            this.personalKonfigurationService.terminate$(endDate, mode, this.newKonfiguration.kinderOrtId!))
            .then(() => this.$state.reload());
    }

    public revert(konfiguration: Persisted<PersonalKonfiguration>): void {
        firstValueFrom(this.personalKonfigurationService.extend$(konfiguration))
            .then(() => this.$state.reload());
    }

    public remove(konfiguration: Persisted<PersonalKonfiguration>): angular.IPromise<unknown> {
        return firstValueFrom(this.personalKonfigurationService.delete$(konfiguration))
            .then(() => this.$state.reload());
    }
}

componentConfig.controller = DvbPersonalKonfiguration;
angular.module('kitAdmin').component('dvbPersonalKonfiguration', componentConfig);
