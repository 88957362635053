/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import angular from 'angular';
import type {WizardStep} from '../../model/WizardStep';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        step0: '?step0',
        step1: '?step1',
        step2: '?step2',
    },
    bindings: {
        steps: '<',
        onSubmit: '&',
        onStepChanged: '&',
    },
    require: {
        parentForm: '^form',
    },
    template: require('./dvb-wizard.html'),
    controllerAs: 'vm',
};

export class DvbWizard implements angular.IController {

    public static $inject: readonly string[] = [];

    public steps: WizardStep[] = [];
    public onSubmit!: FunctionType;
    public onStepChanged!: (params: { step: WizardStep }) => unknown;

    public parentForm?: angular.IFormController;

    public $onInit(): void {
        for (let i = 0; i < this.steps.length; i++) {
            this.steps[i].active = i === 0;
        }
    }

    public navigateTo(step: WizardStep): void {
        if (step.active) {
            return;
        }

        this.steps.forEach(s => {
            if (s.active) {
                // validate previous step
                s.validate();
            }

            s.active = s === step;
            this.setFormSubmittedIfNeeded(s);
        });

        this.onStepChanged({step});
        window.scrollTo(0, 0);
    }

    public next(): void {
        const activeIndex = this.findActiveIndex();
        if (activeIndex === this.steps.length - 1) {
            return;
        }

        this.navigateTo(this.steps[activeIndex + 1]);
    }

    public back(): void {
        const activeIndex = this.findActiveIndex();
        if (activeIndex === 0) {
            return;
        }

        this.navigateTo(this.steps[activeIndex - 1]);
    }

    private findActiveIndex(): number {
        return this.steps.findIndex(step => step.active);
    }

    /**
     * Sets the form to submitted if the step is active and has errors. If the step is active and without errors, sets
     * the form to pristine.
     */
    private setFormSubmittedIfNeeded(s: WizardStep): void {
        if (s.active && s.error && !this.parentForm?.$submitted) {
            // the step has already been marked as having errors ->
            // set the form to submitted, so that validation errors show up
            this.parentForm?.$setSubmitted();
        } else if (s.active && !s.error && this.parentForm?.$submitted) {
            // make sure validation errors are not displayed
            this.parentForm?.$setPristine();
        }
    }
}

componentConfig.controller = DvbWizard;
angular.module('kitAdmin').component('dvbWizard', componentConfig);
