/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {Kind, Kontaktperson} from '@dv/kitadmin/models';
import {VersandartRechnung} from '@dv/shared/backend/model/versandart-rechnung';
import type {Persisted} from '@dv/shared/code';
import {DvbUtil, Geschlecht} from '@dv/shared/code';
import angular from 'angular';
import type {KontaktpersonService} from '../../../common/service/rest/kontaktpersonService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
        kinder: '<',
    },
    template: require('./dvb-kontaktperson-overview.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonOverview implements angular.IController {
    public static $inject: readonly string[] = [
        'kontaktpersonService',
        'errorService',
    ];

    public kontaktperson!: Persisted<Kontaktperson>;
    public kinder: Persisted<Kind>[] = [];

    public geschlechte: Geschlecht[] = Object.values(Geschlecht);
    public versandartEmail: boolean = false;

    private lastValidKontaktperson!: Persisted<Kontaktperson>;

    public constructor(
        public readonly kontaktpersonService: KontaktpersonService,
        public readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        this.lastValidKontaktperson = angular.copy(this.kontaktperson);
        this.versandartEmail = this.kontaktperson.versandartRechnung === VersandartRechnung.EMAIL;
    }

    public isRequiredForHauptkontaktMobile(param: string): boolean {
        return this.kontaktperson.isHauptkontakt() && !this.kontaktperson.telefon ? this.isRequired(param) : true;
    }

    public isNameValid(param: string): boolean {
        this.errorService.handleValidationError(true, 'ERRORS.VALUE_REQUIRED');

        return this.kontaktperson.isFirma && DvbUtil.isNotEmptyString(this.kontaktperson.organisation) ||
            this.isRequired(param);
    }

    public isOrgValid(param: string): boolean {
        const namesValid = DvbUtil.isNotEmptyString(this.kontaktperson.vorName) &&
            DvbUtil.isNotEmptyString(this.kontaktperson.familienName);

        return this.kontaktperson.isFirma && (namesValid || DvbUtil.isNotEmptyString(param));
    }

    public isRequiredForHauptkontakt(param: string): boolean {
        return this.kontaktperson.isHauptkontakt() && !this.kontaktperson.mobile ? this.isRequired(param) : true;
    }

    public isRequired(param: string): boolean {
        const valid = param !== '';
        this.errorService.handleValidationError(valid, 'ERRORS.VALUE_REQUIRED');

        return valid;
    }

    public saveBasisData(): void {
        if (this.kontaktperson.isValid()) {
            this.kontaktpersonService.update(this.kontaktperson).then(() => {
                return this.kontaktpersonService.get(this.kontaktperson.id).then(updatedKontaktperson => {
                    this.kontaktperson.telefon = updatedKontaktperson.telefon;
                    this.kontaktperson.mobile = updatedKontaktperson.mobile;
                    this.kontaktperson.geschaeft = updatedKontaktperson.geschaeft;
                    this.lastValidKontaktperson = angular.copy(this.kontaktperson);
                });
            }).catch(error => {
                console.error('Kontaktperson kann nicht gespeichert werden', error);
                this.kontaktperson = angular.copy(this.lastValidKontaktperson);
            });
        } else {
            // Dies sollte nie passieren...
            console.error('Ungültige Kontaktperson respektive Kontaktperson nicht geändert');
        }
    }

    public updateEmail(): void {
        if (!this.kontaktperson.email || this.kontaktperson.email === '') {
            this.versandartEmail = false;
            this.updateVersandart();

            return;
        }

        this.saveBasisData();
    }

    public updateVersandart(): void {
        this.kontaktperson.versandartRechnung = this.versandartEmail ?
            VersandartRechnung.EMAIL :
            VersandartRechnung.POST;

        this.saveBasisData();
    }
}

componentConfig.controller = DvbKontaktpersonOverview;
angular.module('kitAdmin').component('dvbKontaktpersonOverview', componentConfig);
