<div class="dvb-editable-contextual-adresse">
    <dvb-click2-edit edit-mode="vm.editMode">
        <label-part>
            <label class="form-control click2edit" ng-click="vm.edit()">
                <span data-translate="{{vm.translationKey}}"></span>
                <dvlib-adresse-display ng-if="vm.contextualAdresse.context === 'CUSTOM'"
                                       [adresse]="vm.contextualAdresse.adresse"
                                       [display-type]="'long'">
                </dvlib-adresse-display>
            </label>
        </label-part>
        <content-part>
            <form novalidate name="editableContextualAdresseForm" ng-submit="vm.update(editableContextualAdresseForm)">
                <dvb-contextual-adresse contextual-adresse="vm.workingCopyContextualAdresse"
                                        allow-none="vm.allowNone">
                </dvb-contextual-adresse>
                <dvb-submit-cancel-buttons is-loading="editableContextualAdresseForm.$pending"
                                           submit-label="COMMON.SPEICHERN"
                                           on-cancel="vm.cancel()">
                </dvb-submit-cancel-buttons>
            </form>
        </content-part>
    </dvb-click2-edit>
</div>
