/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Wochenplan} from '@dv/kitadmin/models';
import type {Persisted} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type {Transition} from '@uirouter/core';
import type angular from 'angular';
import {trans} from '../common/i18n/trans';
import type {WochenplanService} from '../common/service/rest/wochenplanService';

const $transitionToken = '$transition$';

const WOCHENPLAN_BASE_STATE = {
    name: 'base.wochenplan',
    url: '/wochenplan/{id:[0-9]+}',
    redirectTo: 'base.wochenplan.profil',
    component: 'dvbWochenplanMain',
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },
    resolve: {
        wochenplan: [
            'wochenplanService', $transitionToken, (
                wochenplanService: WochenplanService,
                $transition$: Transition,
            ): angular.IPromise<Persisted<Wochenplan>> =>
                wochenplanService.getWochenplanDeep($transition$.params().id),
        ],
        $title: ['wochenplan', (wochenplan: Wochenplan): string => wochenplan.getDisplayName()],
    },
} satisfies Ng1StateDeclaration;

const WOCHENPLAN_PROFIL_STATE = {
    name: 'base.wochenplan.profil',
    url: '',
    component: 'dvbWochenplanProfil',
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },
    resolve: {
        wochenplan: [
            'wochenplanService', $transitionToken, (
                wochenplanService: WochenplanService,
                $transition$: Transition,
            ): angular.IPromise<Persisted<Wochenplan>> =>
                wochenplanService.getWochenplanDeep($transition$.params().id),
        ],
        $title: ['wochenplan', (wochenplan: Wochenplan): string => wochenplan.getDisplayName()],
    },
} satisfies Ng1StateDeclaration;

export const WOCHENPLAN_BELEGUNGS_EINHEITEN_STATE = {
    name: 'base.wochenplan.belegungseinheiten',
    url: '/belegungseinheiten',
    component: 'dvbBelegungsEinheiten',
    data: {
        role: UserRole.SUPPORT_ADMIN,
    },

    resolve: {
        wochenplan: [
            'wochenplanService', $transitionToken, (
                wochenplanService: WochenplanService,
                $transition$: Transition,
            ): angular.IPromise<Persisted<Wochenplan>> =>
                wochenplanService.getWochenplanDeep($transition$.params().id),
        ],
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'COMMON.BELEGUNGS_EINHEIT.PLURAL'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const WOCHENPLAN_STATES = {
    WOCHENPLAN_BASE_STATE,
    WOCHENPLAN_PROFIL_STATE,
    WOCHENPLAN_BELEGUNGS_EINHEITEN_STATE,
};
