/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalAnmeldung} from '@dv/kitadmin/models';
import {ExternalAnmeldungSetup, WochenplanExternalAnmeldung} from '@dv/kitadmin/models';
import type {JaxExternalAnmeldungCriticalConfig} from '@dv/shared/backend/model/jax-external-anmeldung-critical-config';
import type {Displayable, Selectable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import type angular from 'angular';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import {CONFIG} from '../../../config';

function baseUrl(mandantId: string): string {
    return `${CONFIG.restBackend}/api/v1/public/mandant/${encodeURIComponent(mandantId)}/anmeldung`;
}

export class PublicExternalAnmeldungService {
    public static $inject: readonly string[] = ['$http'];

    public constructor(
        private readonly $http: angular.IHttpService,
    ) {
    }

    public getCriticalConfig(mandantId: string): angular.IPromise<JaxExternalAnmeldungCriticalConfig> {
        const url = `${baseUrl(mandantId)}/critical`;

        return this.$http.get<JaxExternalAnmeldungCriticalConfig>(url).then(response => response.data);
    }

    public getSetup(mandantId: string): angular.IPromise<ExternalAnmeldungSetup> {
        const url = `${baseUrl(mandantId)}/setup`;

        return DvbRestUtilAngularJS.getModelByUrlAndParams(url, ExternalAnmeldungSetup)
            .then(checkPresent);
    }

    public createExternalAnmeldungen(
        mandantId: string,
        externalAnmeldungen: ExternalAnmeldung[],
    ): angular.IPromise<unknown> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${baseUrl(mandantId)}`;

        return this.$http.post(url, externalAnmeldungen.map(entry => entry.toRestObject()));
    }

    public getWochenplaene(
        mandantId: string,
        selectedKinderOrte: Selectable<Displayable>[],
    ): angular.IPromise<WochenplanExternalAnmeldung[]> {
        const params = {kinderOrtIds: selectedKinderOrte.map(DvbUtil.mapToIdChecked)};
        const url = `${baseUrl(mandantId)}/wochenplaene${DvbRestUtil.encodeMatrixParams(params)}`;

        return DvbRestUtilAngularJS.getModelsArray(url, WochenplanExternalAnmeldung, 'items');
    }
}
