/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KinderOrtFraktion} from '@dv/kitadmin/models';
import type {Persisted, Selectable} from '@dv/shared/code';
import {checkPersisted, checkPresent, DisplayableWithBadge, DvbUtil, isPresent} from '@dv/shared/code';
import angular from 'angular';
import type {ParameterKinderOrteFraktionen} from '../../../report/models/ParameterKinderOrteFraktionen';
import type {KinderOrtService} from '../../service/rest/kinderort/kinderOrtService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        parameterKinderOrteFraktionen: '<',
        isSelectionRequired: '<?',
    },
    template: require('./dvb-kitas-fraktions-selection.html'),
    controllerAs: 'vm',
};

export class DvbKitasFraktionsSelection implements angular.IController {
    public static $inject: readonly string[] = ['kinderOrtService'];

    public readonly parameterKinderOrteFraktionen!: ParameterKinderOrteFraktionen;
    public readonly isSelectionRequired: boolean = true;

    public availableKinderOrte: { [id: string]: Persisted<KinderOrt> } = {};
    public availableKinderOrtDisplayables: Selectable<DisplayableWithBadge>[] = [];
    public selectedKinderOrte: Selectable<DisplayableWithBadge>[] = [];
    public allFraktionen: Persisted<KinderOrtFraktion>[] = [];
    public availableFraktionen: { [id: string]: Persisted<KinderOrtFraktion> } = {};
    public availableFraktionDisplayables: Selectable<DisplayableWithBadge>[] = [];
    public selectedFraktionen: Selectable<DisplayableWithBadge>[] = [];

    public constructor(
        private kinderOrtService: KinderOrtService,
    ) {
    }

    public $onInit(): void {
        this.selectedKinderOrte =
            this.parameterKinderOrteFraktionen.kinderOrte.map(k => new DisplayableWithBadge(k.id, k.text));
        this.selectedFraktionen =
            this.parameterKinderOrteFraktionen.fraktionen.map(f => new DisplayableWithBadge(f.id, f.text));

        this.kinderOrtService.getAll({cache: true, includes: '(gruppen)'})
            .then(k => k.map(checkPersisted))
            .then(kinderOrte => {
                this.availableKinderOrte = DvbUtil.mapById(kinderOrte);
                this.availableKinderOrtDisplayables = kinderOrte
                    .map(k => new DisplayableWithBadge(k.id, k.getDisplayName()));
                this.allFraktionen = kinderOrte.flatMap(kinderOrt => kinderOrt.gruppen);
                this.initAvailableFraktionen();
            });
    }

    public kinderOrtSelectionChanged(): void {
        this.parameterKinderOrteFraktionen.kinderOrte = this.selectedKinderOrte.map(displayable =>
            this.availableKinderOrte[displayable.id].toSearchResultEntry());

        this.selectedFraktionen = this.selectedFraktionen
            .filter(fraktionDisplayable => this.isFraktionKinderOrtSelected(fraktionDisplayable));
        this.updateParameterFraktionen();
    }

    public fraktionSelectionChanged(): void {
        this.updateParameterFraktionen();
        this.ensureSelectedFraktionKinderOrteAreSelected();
    }

    private isFraktionKinderOrtSelected(fraktionDisplayable: Selectable<DisplayableWithBadge>): boolean {
        const selectedFraktion = checkPresent(this.allFraktionen.find(
            fraktion => fraktion.id === fraktionDisplayable.id));

        return isPresent(this.selectedKinderOrte.find(k => selectedFraktion.kinderOrtId === k.id));
    }

    private initAvailableFraktionen(): void {
        this.availableFraktionen = DvbUtil.mapById(this.allFraktionen);
        this.availableFraktionDisplayables = this.allFraktionen.map(f => {
            const badge = this.availableKinderOrte[checkPresent(f.kinderOrtId)]?.getDisplayName();

            return new DisplayableWithBadge(checkPresent(f.id), f.getDisplayName(), badge);
        });
    }

    private ensureSelectedFraktionKinderOrteAreSelected(): void {
        this.selectedFraktionen.forEach(fraktionDisplayable => {
            const fraktion = checkPresent(this.allFraktionen.find(f => f.id === fraktionDisplayable.id));
            const isKinderOrtSelected =
                isPresent(this.selectedKinderOrte.find(k => fraktion.kinderOrtId === k.id));
            if (!isKinderOrtSelected) {
                const kinderOrt =
                    checkPresent(this.availableKinderOrtDisplayables.find(k => k.id === fraktion.kinderOrtId!));
                this.selectedKinderOrte.push(kinderOrt);
                this.addToParameterKinderOrte(kinderOrt);
            }
        });
    }

    private addToParameterKinderOrte(kinderOrt: DisplayableWithBadge): void {
        this.parameterKinderOrteFraktionen.kinderOrte.push(
            this.availableKinderOrte[kinderOrt.id].toSearchResultEntry());
    }

    private updateParameterFraktionen(): void {
        this.parameterKinderOrteFraktionen.fraktionen = this.selectedFraktionen.map(displayable =>
            checkPresent(this.allFraktionen.find(fraktion => fraktion.id === displayable.id))
                .toSearchResultEntry());
    }
}

componentConfig.controller = DvbKitasFraktionsSelection;
angular.module('kitAdmin').component('dvbKitasFraktionsSelection', componentConfig);
