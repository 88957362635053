/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HttpInterceptorFn} from '@angular/common/http';
import {HttpContextToken, HttpErrorResponse} from '@angular/common/http';
import {inject} from '@angular/core';
import {BackendErrorHandler} from '@dv/shared/angular';
import {OidcService} from '@dv/shared/authentication/oidc';
import {ErrorLevel, ErrorType, HttpCodes, HttpErrorUtil} from '@dv/shared/code';
import {tap} from 'rxjs';

export const PASS_THROUGH_DVB_ERRORS = new HttpContextToken(() => false);

export const HTTP_ERROR_INTERCEPTOR: HttpInterceptorFn = (request, next) => {
    const backendErrorHandler = inject(BackendErrorHandler);
    const oidcService = inject(OidcService);

    function httpErrorHandler(response: HttpErrorResponse): void {
        if (oidcService.isUnrecoverableIdpError(response)) {
            window.location.reload();
        }

        function handle(errorType: ErrorType, msgKey: string): void {
            const error = HttpErrorUtil.toDvbError(errorType, ErrorLevel.SEVERE, msgKey, response.error);

            if (request.context.get(PASS_THROUGH_DVB_ERRORS)) {
                throw error;
            }

            backendErrorHandler.handleError(error);
        }

        switch (response.status) {
            case HttpCodes.BAD_REQUEST:
                handle(ErrorType.BADREQUEST, 'ERRORS.ERR_BADREQUEST');
                break;
            case HttpCodes.NOT_FOUND:
                handle(ErrorType.INTERNAL, 'ERRORS.ERR_BADREQUEST');
                break;
            case HttpCodes.INTERNAL_SERVER_ERROR:
                handle(ErrorType.INTERNAL, 'ERRORS.ERR_INTERNAL');
                break;
            case HttpCodes.SERVICE_UNAVAILABLE:
                handle(ErrorType.INTERNAL, 'ERRORS.ERR_SERVICE_UNAVAILABLE');
                break;
            case HttpCodes.CONFLICT:
                // Momentan haben wir noch kein intelligentes Handling von Backend Fehlern.
                handle(ErrorType.INTERNAL, 'ERRORS.ERR_INTERNAL');
                break;
            case HttpCodes.UNKOWN:
            case HttpCodes.BAD_GATEWAY:
            case HttpCodes.GATEWAY_TIMEOUT:
                handle(ErrorType.BADREQUEST, 'ERRORS.ERR_REQUEST_ABORTED');
                break;
            default:
            // nop;
        }
    }

    return next(request).pipe(
        tap({
            error: (err: unknown) => {
                if (err instanceof HttpErrorResponse) {
                    httpErrorHandler(err);
                }
            },
        }),
    );
};
