<div class="dvb-zuweisen-zeiten">

    <div class="wochenplan small-abstand-top">
        <div class="tag" ng-repeat="dayOfWeek in vm.weekDays">
            <div ng-if="vm.isDayBelegt(dayOfWeek)">
                <div ng-repeat="belegungZeiten in vm.anwesenheiten[dayOfWeek]">
                    <input type="text"
                           class="form-control input-sm time-input"
                           placeholder="{{'COMMON.VON' | translate | lowercase}}"
                           uib-tooltip="{{'KIND.ZUWEISUNG.BELEGUNG_ZEIT_FROM' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="belegungZeiten.von"
                           dvb-time-input
                           dvb-select-on-click
                           maxlength="10"
                           ng-required="belegungZeiten.bis"
                           dvb-moment>
                    <input type="text"
                           class="form-control input-sm time-input"
                           placeholder="{{'COMMON.BIS' | translate | lowercase}}"
                           uib-tooltip="{{'KIND.ZUWEISUNG.BELEGUNG_ZEIT_TO' | translate}}"
                           tooltip-placement="top-left"
                           ng-model="belegungZeiten.bis"
                           dvb-time-input
                           dvb-select-on-click
                           maxlength="10"
                           ng-required="belegungZeiten.von"
                           dvb-moment>
                </div>
                <button ng-if="vm.hasZeiten && (vm.anwesenheitFix ? !vm.anwesenheiten[dayOfWeek] || vm.anwesenheiten[dayOfWeek].length == 0 : true)"
                        class="btn btn-link floating-text"
                        uib-tooltip="{{'KIND.ZUWEISUNG.BELEGUNG_ZEIT_MORE' | translate}}"
                        tooltip-placement="top-left"
                        ng-click="vm.addVonBis(dayOfWeek)">+
                </button>
            </div>
        </div>
    </div>

    <button ng-if="!vm.hasZeiten && vm.hasBelegung()"
            class="btn btn-link floating-text"
            ng-click="vm.initZeiten()">+
        <span data-translate="KIND.ZUWEISUNG.BELEGUNG_ZEIT_ADD"></span>
    </button>
</div>
