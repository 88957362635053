<div class="dvb-firma-main">
    <!-- HEADER -->
    <div class="header">
        <div class="row header-row">
            <dvb-pensum-icon value="vm.firma.symbol"
                             class="header-icon"
                             tooltip="'COMMON.PLATZART.SINGULAR'"
                             inverted="true">
            </dvb-pensum-icon>

            <div class="col-xs-12 header-box">
                <h1 uib-tooltip="{{vm.firma.name}}" tooltip-placement="top-left" ng-bind="vm.firma.name"></h1>
            </div>
        </div>
    </div>
    <!-- CONTENT from sub-states -->
    <div ui-view></div>
</div>
