/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {IKitaVendorKonfiguration, KitaRechnungsKonfiguration} from '@dv/kitadmin/models';
import type {JaxKonto} from '@dv/shared/backend/model/jax-konto';
import {RechnungsKonfigurationType} from '@dv/shared/backend/model/rechnungs-konfiguration-type';
import type {FunctionType, Persisted} from '@dv/shared/code';
import {END_OF_TIME, TypeUtil} from '@dv/shared/code';
import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '=',
        editMode: '<',
        konten: '<',
        isLoading: '<',
        templateKonfiguration: '<?',
        onSubmit: '&',
        onCancel: '&',
    },
    template: require('./dvb-kita-faktura-konfiguration-form.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaKonfigurationForm implements angular.IController {

    public static $inject: readonly string[] = ['errorService'];

    public konfiguration!: Persisted<KitaRechnungsKonfiguration>;
    public editMode: boolean = false;
    public konten: JaxKonto[] = [];
    public isLoading: boolean = false;
    public templateKonfiguration?: IKitaVendorKonfiguration;
    public onSubmit?: FunctionType;
    public onCancel?: FunctionType;

    public readonly konfigurationenTypen = RechnungsKonfigurationType;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
    }

    public $onInit(): void {
        if (this.konfiguration.gueltigBis?.isSame(END_OF_TIME)) {
            this.konfiguration.gueltigBis = null;
        }
    }

    public submit(isValid: boolean): void {
        this.errorService.clearAll();

        const gueltigkeitValid = (this.konfiguration.gueltigBis ?? END_OF_TIME).isAfter(this.konfiguration.gueltigAb);
        this.errorService.handleValidationError(gueltigkeitValid,
            'ERRORS.ERR_KITA_RECHNUNGSKONFIGURATION_GUELTIG_BIS_NACH_GUELTIG_AB');

        if (!isValid || !gueltigkeitValid || !this.konfiguration.isValid()) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        if (TypeUtil.isFunction(this.onSubmit)) {
            this.onSubmit();
        }
    }

    public cancel(): void {
        this.errorService.clearAll();

        if (TypeUtil.isFunction(this.onCancel)) {
            this.onCancel();
        }
    }
}

componentConfig.controller = DvbKitaFakturaKonfigurationForm;
angular.module('kitAdmin').component('dvbKitaFakturaKonfigurationForm', componentConfig);
