/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IPersistable, IRestModel} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import type {Ausbildung} from '../../anstellung/models/Ausbildung';

export class AusbildungsPersonalBedarf implements IPersistable, IRestModel {

    public constructor(
        public id: string | null = null,
        public ausbildungsId: string | null = null,
        public bedarfCount: number | null = null,
        public assignedAngestellte: { [angestellteId: string]: number } = {},
        public ausbildung: Ausbildung | null = null,
        public ausbildungsName: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AusbildungsPersonalBedarf {
        const result = new AusbildungsPersonalBedarf(data.id, data.ausbildungsId, data.count, data.assigned);
        result.ausbildungsName = data.ausbildungsName;

        return result;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            ausbildungsId: this.ausbildung?.id ?? null,
            count: this.bedarfCount,
        };
    }

    public isValid(): boolean {
        return isPresent(this.ausbildung) && isPresent(this.bedarfCount);
    }
}
