<div class="dvb-angestellte-correspondence">
    <div class="row">

        <div class="col-md-7">
            <h2 [translate]="'PERSONAL.KORRESPONDENZ.PAST'"></h2>

            @if (dataSource) {
                <div class="dvb-timeline scroll-wrapper small-abstand-top">
                    <div *uiScroll="let entry of dataSource; index as index; last as last" class="timeline-entry">
                        <dv-timeline-icon [isLast]="dataSource.settings.maxIndex === index + 1"
                                          [timelineService]="timelineService" [timelineEntry]="entry"/>
                        <dv-timeline-detail [timelineService]="timelineService" [timelineEntry]="entry">
                            <dv-angestellte-korrespondenz-timeline-details [korrespondenz]="entry"/>
                        </dv-timeline-detail>
                    </div>
                </div>
            }
        </div>

    </div>
</div>
