/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';
import type {Ng1StateDeclaration} from '@uirouter/angularjs';

/* eslint-disable sonarjs/no-duplicate-string */

export const EXTERNAL_ANMELDUNGEN_STATE = {
    name: 'base.kinderort.external-anmeldung',
    url: '/external-anmeldungen',
    component: 'dvbKinderOrtExternalAnmeldungen',
    data: {
        subtitle: 'KINDERORT.NAV_EXTERNAL_ANMELDUNGEN',
        permission(toParams: { id: string }): RequiredPermission {
            return [
                PERMISSION.KITA.MANAGE + toParams.id,
                `${PERMISSION.FEATURE.EXTERNAL_ANMELDUNG}:${toParams.id}`,
            ];
        },
    },
} satisfies Ng1StateDeclaration;

export const KINDERORT_EXTERNAL_ANMELDUNG_STATES = {
    EXTERNAL_ANMELDUNGEN_STATE,
};
