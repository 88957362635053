/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {getPrivilegedRoleWeight} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {IEntityList} from '@dv/shared/code';
import {checkPresent, displayableComparator, stringUnion} from '@dv/shared/code';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type {Benutzer} from '../../../benutzer/model/Benutzer';
import type {DokumentlayoutService} from '../../../dokumentlayout/service/dokumentlayoutService';
import type {AngestellteService} from '../../../personal/anstellung/service/angestellteService';
import type {
    BetreuungsSchluesselService,
} from '../../../personal/betreuungs-schluessel/service/betreuungsSchluesselService';
import type {SchliesstageService} from '../../../schliesstage/service/schliesstageService';
import type {BenutzerService} from '../../service/rest/benutzer/benutzerService';
import type {FirmaService} from '../../service/rest/firmaService';
import type {KinderOrtService} from '../../service/rest/kinderort/kinderOrtService';
import type {MandantService} from '../../service/rest/mandantService';
import type {TarifService} from '../../service/rest/tarifService';
import type {WochenplanService} from '../../service/rest/wochenplanService';

const componentConfig: angular.IComponentOptions = {
    transclude: {
        aside: '?aside',
    },
    bindings: {
        type: '@',
        titleKey: '@',
        baseState: '@',
        baseStateCreate: '@?',
        createLinkTitleKey: '@?',
        createPermission: '@?',
        currentPage: '<',
        itemsPerPage: '<',
        showArchived: '<',
    },
    template: require('./dvb-entity-list.html'),
    controllerAs: 'vm',
};

export const LIST_TYPE = stringUnion(
    'kita',
    'platzart',
    'tarif',
    'betreuungsfaktor',
    'benutzer',
    'dokumentlayout',
    'wochenplan',
    'schliesstage',
    'betreuungsSchluessel',
    'angestellte',
);

export type ListType = typeof LIST_TYPE.type;

export class DvbEntityList implements angular.IOnInit, angular.IOnChanges {
    public static $inject: readonly string[] = [
        '$state',
        '$transclude',
        'kinderOrtService',
        'firmaService',
        'tarifService',
        'mandantService',
        'benutzerService',
        'dokumentlayoutService',
        'wochenplanService',
        'schliesstageService',
        'betreuungsSchluesselService',
        'angestellteService',
    ];

    private static readonly SERVICE_MAPPING: {
        [index in ListType]:
        (ctrl: DvbEntityList) => angular.IPromise<IEntityList[]>
    } = {
        kita: ctrl => ctrl.kinderOrtService.getAll(),
        platzart: ctrl => ctrl.firmaService.getAll(),
        tarif: ctrl => ctrl.tarifService.getAllTarife({includes: '(currentSchemaVersion)'}),
        betreuungsfaktor: ctrl => ctrl.mandantService.getAllBetreuungsfaktorRegeln(),
        benutzer: ctrl => ctrl.benutzerService.getAllActive({includes: '(kitaPermissions)'}),
        dokumentlayout: ctrl => ctrl.dokumentlayoutService.getAllDokumentLayouts(),
        wochenplan: ctrl => ctrl.wochenplanService.getAllWochenplaene(),
        schliesstage: ctrl => ctrl.schliesstageService.getAll(),
        betreuungsSchluessel: ctrl => ctrl.betreuungsSchluesselService.getAll(),
        angestellte: ctrl => ctrl.angestellteService.getAll(),
    };

    private static readonly ARCHIVED_SERVICE_MAPPING: {
        [index in ListType]:
        (ctrl: DvbEntityList) => angular.IPromise<IEntityList[]>
    } = {
        tarif: ctrl => ctrl.tarifService.getAllArchivedTarife({includes: '(currentSchemaVersion)'}),
        // Return same entities untill archive is implemented for remaining entities
        kita: ctrl => ctrl.kinderOrtService.getAll(),
        platzart: ctrl => ctrl.firmaService.getAll(),
        betreuungsfaktor: ctrl => ctrl.mandantService.getAllBetreuungsfaktorRegeln(),
        benutzer: ctrl => ctrl.benutzerService.getAllActive(),
        dokumentlayout: ctrl => ctrl.dokumentlayoutService.getAllDokumentLayouts(),
        wochenplan: ctrl => ctrl.wochenplanService.getAllWochenplaene(),
        schliesstage: ctrl => ctrl.schliesstageService.getAll(),
        betreuungsSchluessel: ctrl => ctrl.betreuungsSchluesselService.getAll(),
        angestellte: ctrl => ctrl.angestellteService.getAll(),
    };

    public type?: ListType;
    public titleKey?: string;
    public baseState?: string;
    public baseStateCreate?: string;
    public createLinkTitleKey?: string;
    public createPermission?: string = PERMISSION.KITA.BETREUUNG_ADMIN + WILDCARD_TOKEN;
    public currentPage!: number;
    public itemsPerPage!: number;
    public showArchived: boolean = false;

    public items: IEntityList[] = [];
    public isLoading: boolean = true;

    public constructor(
        private $state: StateService,
        private $transclude: angular.ITranscludeFunction,
        private kinderOrtService: KinderOrtService,
        private firmaService: FirmaService,
        private tarifService: TarifService,
        private mandantService: MandantService,
        private benutzerService: BenutzerService,
        private dokumentlayoutService: DokumentlayoutService,
        private wochenplanService: WochenplanService,
        private schliesstageService: SchliesstageService,
        private betreuungsSchluesselService: BetreuungsSchluesselService,
        private angestellteService: AngestellteService,
    ) {
    }

    public $onInit(): void {
        this.getItemPromise().then(items => {
            this.sortItems(items);
            this.items = items;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.currentPage) {
            this.currentPage = parseInt(changes.currentPage.currentValue, 10);
        }
        if (changes.itemsPerPage) {
            this.itemsPerPage = parseInt(changes.itemsPerPage.currentValue, 10);
        }
    }

    public isSlotFilled(name: string): boolean {
        return this.$transclude.isSlotFilled(name);
    }

    public updatePage(page: number): void {
        this.currentPage = page;
        this.updateQueryParams();
    }

    public onUpdateItemsPerPage(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;
        this.updateQueryParams();
    }

    private getItemPromise(): angular.IPromise<IEntityList[]> {
        if (this.showArchived) {
            return DvbEntityList.ARCHIVED_SERVICE_MAPPING[checkPresent(this.type)](this);
        }

        return DvbEntityList.SERVICE_MAPPING[checkPresent(this.type)](this);
    }

    private sortItems(items: IEntityList[]): void {
        if (this.type === 'benutzer') {
            (items as Benutzer[]).sort((a, b) => this.sortBenutzer(a, b));
        } else {
            items.sort(displayableComparator);
        }
    }

    private sortBenutzer(a: Benutzer, b: Benutzer): number {
        const cmp = getPrivilegedRoleWeight(b.role) - getPrivilegedRoleWeight(a.role);

        if (cmp === 0) {
            return displayableComparator(a, b);
        }

        return cmp;
    }

    private updateQueryParams(): void {
        this.$state.go('.', {
            currentPage: this.currentPage,
            itemsPerPage: this.itemsPerPage,
        }, {notify: false});
    }
}

componentConfig.controller = DvbEntityList;
angular.module('kitAdmin').component('dvbEntityList', componentConfig);
