<div class="dvb-login" ng-class="{'login-state': vm.isLoginState}">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <h2 data-translate="AUTHENTICATION.LOGIN_HEADER"></h2>

                <form name="loginForm" ng-submit="vm.loginAction(loginForm.$valid)" class="login" novalidate>
                    <input type="email"
                           class="form-control input-large"
                           name="email"
                           ng-attr-placeholder="{{'AUTHENTICATION.LOGIN_EMAIL' | translate}}"
                           aria-label="{{'AUTHENTICATION.LOGIN_EMAIL' | translate}}"
                           maxlength="255"
                           ng-model="vm.userCredentials.username"
                           required>
                    <input type="password"
                           class="form-control input-large"
                           name="password"
                           maxlength="255"
                           ng-model="vm.userCredentials.password"
                           ng-attr-placeholder="{{'AUTHENTICATION.LOGIN_PASSWORD' | translate}}"
                           aria-label="{{'AUTHENTICATION.LOGIN_PASSWORD' | translate}}"
                           required>

                    <ul class="list-inline">
                        <li>
                            <dvb-loading-button type="submit" is-loading="vm.isLoading">
                                <span data-translate="AUTHENTICATION.LOGIN_LOGIN"></span>
                            </dvb-loading-button>
                        </li>
                        <li ng-if="vm.showIdpLogin()">
                            <button class="btn btn-link"
                                    type="button"
                                    ng-click="vm.loginIdp()">
                                <span data-translate="AUTHENTICATION.LOGIN_IDP"></span>
                            </button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    </div>
</div>
