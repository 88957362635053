<div class="dvb-bank-statement-entry-heading row">
    <div class="col-xs-1">
        <i class="fa"
           ng-class="{
             'fa-check': vm.entry.status === 'ZUGEWIESEN',
             'fa-question-circle': vm.entry.status === 'NICHT_ZUGEWIESEN',
             'fa-ban': vm.entry.status === 'IGNORIERT'
           }"
           uib-tooltip="{{'FAKTURA.KONTENABGLEICH.' + vm.entry.status | translate}}"
           tooltip-placement="top-left">
        </i>
    </div>
    <div class="col-xs-3">
        <span ng-bind="vm.entry.localDateTime | amDateFormat:'DD. MMM YYYY'"></span>
    </div>
    <div class="col-xs-5 text-right">
        <span ng-bind="vm.entry.referenzNummer | esr"></span>
    </div>
    <div class="col-xs-3 text-right">
        <span data-translate="COMMON.BETRAG_VALUE"
              data-translate-value-betrag="{{vm.entry.betrag | number:2}}">
        </span>
    </div>
</div>
