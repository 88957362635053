/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Kontaktperson} from '@dv/kitadmin/models';
import angular from 'angular';
import moment from 'moment';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kontaktperson: '<',
        rechnungId: '<?',
        year: '<?',
    },
    template: require('./dvb-kontaktperson-faktura.html'),
    controllerAs: 'vm',
};

export class DvbKontaktpersonFaktura implements angular.IController {
    public static $inject: readonly string[] = [];

    public kontaktperson!: Kontaktperson;
    public rechnungId?: string;
    public year?: number;

    public stichtag?: moment.Moment;

    public $onInit(): void {
        if (angular.isNumber(this.year)) {
            this.stichtag = moment().startOf('day').year(this.year);
        }
    }
}

componentConfig.controller = DvbKontaktpersonFaktura;
angular.module('kitAdmin').component('dvbKontaktpersonFaktura', componentConfig);
