<div class="custom-icon"
     ng-class="{'custom-icon-kindergarten-1': vm.kgType === '1',
        'custom-icon-kindergarten-2': vm.kgType === '2',
        'custom-icon-kindergarten': vm.kgType === '' }"
     uib-tooltip="{{'COMMON.KINDERGARTEN_TITLE' | translate: { kgType: vm.kgType, kgLocation: vm.kgLocation} }}"
     tooltip-placement="top-left">
</div>
<i ng-if="vm.kgType === '3'"
   class="fa fa-graduation-cap"
   uib-tooltip="{{'COMMON.SCHULE_TITLE' | translate: {kgLocation: vm.kgLocation} }}"
   tooltip-placement="top-left">
</i>
