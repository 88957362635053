/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {HookResult, Transition, TransitionService} from '@uirouter/core';
import {firstValueFrom, map} from 'rxjs';
import type {AuthService} from '../../../authentication/service/authService';
import {IS_REQUIRING_LOGIN} from './hook-match-criteria';
import {OnBeforePriorities} from './onBeforePriorities';

export function privacyConsentHook($transitions: TransitionService): void {
    $transitions.onBefore(IS_REQUIRING_LOGIN, requirePrivacyConsent, {priority: OnBeforePriorities.PRIVACY_CONSENT});
}

function requirePrivacyConsent(transition: Transition): HookResult {
    const authService = transition.injector().get<AuthService>('authService');

    return firstValueFrom(authService.requirePrivacyConsent$().pipe(
        map(result => {
            if (result) {
                return undefined;
            }

            // privacy consent not given -> logout and redirect to login page
            return authService.logoutAndRedirectToLoginPage();
        }),
    )) as HookResult;
}
