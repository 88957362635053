/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {FunctionType} from '@dv/shared/code';
import {DvbDateUtil} from '@dv/shared/code';
import angular from 'angular';
import type moment from 'moment';
import type {KinderOrtSchliesstage} from '../../../../schliesstage/models/KinderOrtSchliesstage';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kinderOrtSchliesstage: '<',
        onRemove: '&',
        onRevert: '&',
    },
    template: require('./dvb-kinder-ort-schliesstage-verlauf.html'),
    controllerAs: 'vm',
};

export class DvbKinderOrtSchliesstageVerlauf {
    public static $inject: readonly string[] = [];

    public kinderOrtSchliesstage!: KinderOrtSchliesstage[];
    public onRemove!: FunctionType;
    public onRevert!: FunctionType;

    public today: moment.Moment = DvbDateUtil.today();

    public isSchliesstageBeendet(kinderOrtSchliesstage: KinderOrtSchliesstage): boolean {
        return !DvbDateUtil.isEndOfTime(kinderOrtSchliesstage.gueltigBis);
    }

    public revert(kinderOrtSchliesstage: KinderOrtSchliesstage): void {
        this.onRevert({kinderOrtSchliesstage});
    }

    public remove(kinderOrtSchliesstage: KinderOrtSchliesstage): void {
        this.onRemove({kinderOrtSchliesstage});
    }
}

componentConfig.controller = DvbKinderOrtSchliesstageVerlauf;
angular.module('kitAdmin').component('dvbKinderOrtSchliesstageVerlauf', componentConfig);
