<form name="fristAendernForm" #fristAendernForm="ngForm" (ngSubmit)="submitForm(fristAendernForm)" novalidate>
    <div class="modal-body">
        <h1 [translate]="'FAKTURA.ZAHLUNGSFRIST_AENDERN'"></h1>
        <div class="row big-abstand-bottom">
            <div class="col-md-6 col-md-offset-3">
                <dvlib-datepicker-textfield [(ngModel)]="zahlungsFrist"
                                            name="zahlungsFrist"
                                            required
                                            [placeholder]="'COMMON.ZAHLUNGSFRIST_PLACEHOLDER' | translate"
                                            autofocus>
                </dvlib-datepicker-textfield>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <dvlib-button-list center>
            <dvlib-loading-button [isLoading]="isLoading$ | async" type="submit">
                <span [translate]="'COMMON.SPEICHERN'"></span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    (click)="hide()"
                    [translate]="'COMMON.ABBRECHEN'">
            </button>
        </dvlib-button-list>
    </div>
</form>
