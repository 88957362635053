/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SmallInvoiceBankKonto, SmallInvoiceEinzahlungsschein, SmallInvoiceLayout} from '@dv/kitadmin/models';
import {SmallInvoiceKitaKonfiguration} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Persisted} from '@dv/shared/code';
import angular from 'angular';
import type {SmallInvoiceService} from '../../../../../common/service/rest/smallInvoiceService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        konfiguration: '=',
        mandantKonfigurationId: '<',
        templateKonfiguration: '<?',
        editMode: '<',
    },
    template: require('./dvb-kita-faktura-small-invoice-konfiguration-form.html'),
    controllerAs: 'vm',
};

export class DvbKitaFakturaSmallInvoiceKonfigurationForm implements angular.IController {

    public static $inject: readonly string[] = ['smallInvoiceService'];

    public konfiguration!: SmallInvoiceKitaKonfiguration;
    public mandantKonfigurationId!: EntityId;
    public templateKonfiguration?: Persisted<SmallInvoiceKitaKonfiguration>;
    public editMode: boolean = false;

    public bankKonten: SmallInvoiceBankKonto[] | null = null;
    public einzahlungsscheine: SmallInvoiceEinzahlungsschein[] | null = null;
    public layouts: SmallInvoiceLayout[] | null = null;
    public loadingKonten = false;
    public loadingEinzahlungsscheine = false;
    public loadingLayouts = false;
    public showMassenversand = false;

    public constructor(
        private readonly smallInvoiceService: SmallInvoiceService,
    ) {
    }

    public $onInit(): void {
        if (!this.konfiguration) {
            this.konfiguration = new SmallInvoiceKitaKonfiguration();

            if (this.templateKonfiguration) {
                this.konfiguration.massenversandText = this.templateKonfiguration.massenversandText;
                this.konfiguration.massenversandOben = this.templateKonfiguration.massenversandOben;
                this.konfiguration.massenversandLinks = this.templateKonfiguration.massenversandLinks;
                this.konfiguration.texte = this.templateKonfiguration.texte;
            }
        }

        this.showMassenversand = !!this.konfiguration.massenversandText;
    }

    public $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.mandantKonfigurationId.currentValue) {
            this.reloadKonten();
            this.reloadEinzahlungsscheine();
            this.reloadLayouts();
        }
    }

    public reloadKonten(): void {
        this.loadingKonten = true;
        this.smallInvoiceService.getBankKonten(this.mandantKonfigurationId).then(konten => {
            this.bankKonten = konten;
        }).finally(() => {
            this.loadingKonten = false;
        });
    }

    public reloadEinzahlungsscheine(): void {
        this.loadingEinzahlungsscheine = true;
        this.smallInvoiceService.getEinzahlungsscheine(this.mandantKonfigurationId).then(einzahlungsscheine => {
            this.einzahlungsscheine = einzahlungsscheine;
        }).finally(() => {
            this.loadingEinzahlungsscheine = false;
        });
    }

    public reloadLayouts(): void {
        this.loadingLayouts = true;
        this.smallInvoiceService.getLayouts(this.mandantKonfigurationId).then(layouts => {
            this.layouts = layouts;
        }).finally(() => {
            this.loadingLayouts = false;
        });
    }

    public deleteMassenversand(): void {
        this.showMassenversand = false;
        this.konfiguration.massenversandText = null;
        this.konfiguration.massenversandOben = null;
        this.konfiguration.massenversandLinks = null;
    }
}

componentConfig.controller = DvbKitaFakturaSmallInvoiceKonfigurationForm;
angular.module('kitAdmin').component('dvbKitaFakturaSmallInvoiceKonfigurationForm', componentConfig);
