/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ExternalKontaktperson} from '@dv/kitadmin/models';
import {RechnungsAufteilungType} from '@dv/kitadmin/models';
import {AddressMode} from '@dv/shared/backend/model/address-mode';
import {Geschlecht, TypeUtil} from '@dv/shared/code';
import angular from 'angular';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    require: {
        form: '^form',
    },
    bindings: {
        kontaktperson: '<',
        onRemoveKontakt: '&',
    },
    template: require('./dvb-external-anmeldung-kontakt-form.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldungKontaktForm implements angular.IController {
    public static $inject: readonly string[] = ['mandantConfigurationService'];

    public form!: angular.IFormController;
    public kontaktperson!: ExternalKontaktperson;
    public onRemoveKontakt!: (val: { kontakt: ExternalKontaktperson }) => void;

    public geschlechter: readonly Geschlecht[] = Object.values(Geschlecht);
    public addressModes: readonly AddressMode[] = Object.values(AddressMode);
    public rechnungsAufteilungType: typeof RechnungsAufteilungType = RechnungsAufteilungType;

    public constructor(
        private readonly mandantConfigurationService: MandantConfigurationService,
    ) {
    }

    public removeKontaktperson(): void {
        if (TypeUtil.isFunction(this.onRemoveKontakt)) {
            this.onRemoveKontakt({kontakt: this.kontaktperson});
        }
    }

    public changeKontaktAdresse(): void {
        if (this.kontaktperson.addressMode === AddressMode.OWN) {
            this.mandantConfigurationService.createDefaultAdresse()
                .then(adresse => {
                    this.kontaktperson.adresse = adresse;
                });

            return;
        }

        this.kontaktperson.adresse = null;
    }
}

componentConfig.controller = DvbExternalAnmeldungKontaktForm;
angular.module('kitAdmin').component('dvbExternalAnmeldungKontaktForm', componentConfig);
