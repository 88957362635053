/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        isLoading: '<?',
        onCancel: '&?',
        submitLabel: '@?',
        cancelLabel: '@?',
    },
    template: require('./dvb-submit-cancel-buttons.html'),
    controllerAs: 'vm',
};

export class DvbSubmitCancelButtons {
    public static $inject: readonly string[] = [];

    public isLoading?: boolean;
    public onCancel?: () => void;
    public submitLabel?: string;
    public cancelLabel?: string;
}

componentConfig.controller = DvbSubmitCancelButtons;
angular.module('kitAdmin').component('dvbSubmitCancelButtons', componentConfig);
