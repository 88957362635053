/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ZeitraumFeld} from '@dv/kitadmin/models';
import {DayOfWeek} from '@dv/shared/code';
import angular from 'angular';
import {AngestellteZuweisungUtil} from '../../../service/AngestellteZuweisungUtil';
import type {Anstellung} from '../../models/Anstellung';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        anstellung: '<',
    },
    template: require('./dvb-anstellung-verlauf-item.html'),
    controllerAs: 'vm',
};

export class DvbAnstellungVerlaufItem implements angular.IController, angular.IOnChanges{
    public static $inject: readonly string[] = [];

    public anstellung!: Anstellung;

    public anstellungZeitraumFelder: { [standortId: string]: {[day: string]: ZeitraumFeld[]} } = {};
    public weekDays: DayOfWeek[] = Object.values(DayOfWeek);

    public $onChanges(): void {
        this.anstellung.anstellungStandorte.forEach(standort => {
            this.anstellungZeitraumFelder[standort.id!] = AngestellteZuweisungUtil.anstellungZeitenToZeitraumFelder(
                this.anstellung.anstellungStandorte.find(anstellungStandort =>
                    anstellungStandort.id === standort.id!)!.dailyAnstellungen);
        });
    }
}

componentConfig.controller = DvbAnstellungVerlaufItem;
angular.module('kitAdmin').component('dvbAnstellungVerlaufItem', componentConfig);
